'use strict';

const { getMinChargeAmountInCents: getLawpayMinChargeAmountInCents } = require('./lawpay');
const { getMinChargeAmountInCents: getStripeMinChargeAmountInCents } = require('./stripe');
const { getMinChargeAmountInCents: getEzyCollectMinChargeAmountInCents } = require('./ezy-collect');
const { getMinChargeAmountInCents: getFeeWiseMinChargeAmountInCents } = require('./fee-wise');
const { providers } = require('../entities/constants');

const providerSpecificMinChargeAmountFns = {
  [providers.LAWPAY]: getLawpayMinChargeAmountInCents,
  [providers.STRIPE]: getStripeMinChargeAmountInCents,
  [providers.EZY_COLLECT]: getEzyCollectMinChargeAmountInCents,
  [providers.FEE_WISE]: getFeeWiseMinChargeAmountInCents,
};

/**
 * getMinChargeAmountInCents
 *
 * Get minimal charge amount allowed by payment provider. This may depend on region.
 *
 * @param {Object} params
 * @param {string} params.providerType The provider type that was used to create the charge data.
 * @param {Object} params.region
 *
 * @returns {string} The payment source
 */
const getMinChargeAmountInCents = ({ providerType, region }) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!region) {
    throw new Error('region must be provided');
  }

  const providerSpecificMinChargeAmountFn = providerSpecificMinChargeAmountFns[providerType];
  if (!providerSpecificMinChargeAmountFn) {
    throw new Error(`Failed to retrieve minimal charge amount for unsupported provider type '${providerType}'`);
  }

  return providerSpecificMinChargeAmountFn({ region });
};

module.exports = {
  getMinChargeAmountInCents,
};
