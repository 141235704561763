import PropTypes from 'prop-types';

import { withLinkHandler } from '@sb-itops/react/hoc';
import composeHooks from '@sb-itops/react-hooks-compose';
import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';

import { InvoiceTable } from './InvoiceTable';

/**
 * Accumulates all the invoice total related column values on the current page to be displayed at the bottom
 *
 * @param {object} params
 * @param {object} params.invoices
 * @returns {object}
 */
function accumulateInvoiceTotals({ invoices }) {
  const accumulatedInvoiceTotals = invoices.reduce(
    (acc, invoice) => {
      const { totals } = invoice;

      if (!totals) {
        return acc;
      }

      acc.totalExcInterest += totals.totalExcInterest;
      acc.interest += totals.interest;
      acc.paid += totals.paid;
      acc.unpaid += totals.unpaid;
      acc.paidByCredit += totals.paidByCredit;
      return acc;
    },
    {
      totalExcInterest: 0,
      interest: 0,
      paid: 0,
      unpaid: 0,
      paidByCredit: 0,
    },
  );

  return accumulatedInvoiceTotals;
}

const hooks = () => ({
  useInvoiceTotalsSummary: ({ invoices }) => ({
    summary: accumulateInvoiceTotals({ invoices }),
  }),
  useInvoiceStatementColumn: ({ showStatement, invoices, isLoading }) => {
    const hasNonNullStatementId = invoices.some((invoice) => !!invoice.invoiceStatement?.id);
    return {
      showStatementColumn: showStatement && (isLoading || hasNonNullStatementId),
    };
  },
});

export const InvoiceTableContainer = composeHooks(hooks)(
  withLinkHandler('invoice')(withLinkHandler('invoiceProofOfPayment')(InvoiceTable)),
);

InvoiceTableContainer.propTypes = {
  displayMode: PropTypes.string,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoiceStatuses: PropTypes.arrayOf(
    PropTypes.oneOf([
      invoiceStatusesMap.DRAFT,
      invoiceStatusesMap.FINAL,
      invoiceStatusesMap.PAID,
      invoiceStatusesMap.VOID,
      'OVERDUE', // Pseudo status based on "FINAL" invoices and their due dates
    ]),
  ).isRequired,
  selectedInvoices: PropTypes.object,
  sendEmail: PropTypes.func,
  showDebtor: PropTypes.bool,
  showMatter: PropTypes.bool,
  showStatement: PropTypes.bool,
  sort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  summary: PropTypes.object,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onMarkInvoiceAsSent: PropTypes.func.isRequired,
  onSendInvoiceCommunicate: PropTypes.func.isRequired,
  onSendInvoiceEmail: PropTypes.func.isRequired,
  onToggleInvoices: PropTypes.func.isRequired,
};

InvoiceTableContainer.defaultProps = {
  displayMode: undefined,
  selectedInvoices: undefined,
  showDebtor: undefined,
  showMatter: undefined,
  showStatement: undefined,
  sort: undefined,
  sortBy: undefined,
  sortDirection: undefined,
};

InvoiceTableContainer.displayName = 'InvoiceTableContainer';
