'use strict';

/**
 * @typedef MatterItem A matter item, can be of arbitrary shape, but always has an id
 * @property {string} params.id UUID of item
 */

/**
 * Get roles in the order they are presented in matter items
 * This is used to decide how each role entity will be updated
 * in order to maintain the correct ordering for clients and other sides
 * @param {Object} params
 * @param {Object[]} params.items matter items
 * @param {Object[]} params.roles roles of a particular type where ordering is important, e.g. clients
 */
function getRolesInItemsOrder({ items, roles }) {
  const { roleIds, roleMap } = roles.reduce(
    (acc, currentRole) => {
      acc.roleIds.push(currentRole.id);
      acc.roleMap[currentRole.id] = currentRole;
      return acc;
    },
    {
      roleIds: [],
      roleMap: {},
    },
  );

  const orderedRoles = items.reduce((acc, currentItem) => {
    if (roleIds.includes(currentItem.id)) {
      const roleForItem = roleMap[currentItem.id]; // this should always be truthy if there's no data issues
      if (roleForItem) {
        acc.push(roleForItem);
      }
    }
    return acc;
  }, []);

  return orderedRoles;
}

module.exports = {
  getRolesInItemsOrder,
};
