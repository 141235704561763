import { SET_SELECTED_TAB, CLEAR_SELECTION_TAB } from './types';

const initialState = {};

export const reducer = (currentState, action) => {
  const state = currentState || initialState;

  switch (action.type) {
    case SET_SELECTED_TAB: {
      return {
        selected: action.payload.tab,
      };
    }

    case CLEAR_SELECTION_TAB: {
      return {
        selected: undefined,
      };
    }

    default: {
      return state;
    }
  }
};

// reducer for this state tree
export default reducer;
