angular.module('sb.billing.webapp').config(function ($stateProvider) {
  $stateProvider
    .state('home.billing.finalise-pay-invoices', {
      url: '/finalise-pay-invoices/',
      template: '<sbb-finalise-pay-invoices invoice-ids="$ctrl.invoiceIds" on-click-link="$ctrl.onClickLink" on-finalise="$ctrl.onFinaliseInvoicesP" />',
      controllerAs: '$ctrl',
      controller: 'FinalisePayInvoicesController',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills'],
        tab: { type: 'finalize-invoice-payments' },
      },
      params: {
        invoiceIds: [],
      },
    });
});

/*angular.module('sb.billing.webapp').config(function ($stateProvider) {
  $stateProvider
    .state('home.billing.finalise-pay-invoices', {
      url: '/finalise-pay-invoices/',
      templateUrl: 'ng-routes/home/billing/finalise-pay-invoices/finalise-pay-invoices.html',
      controllerAs: '$ctrl',
      controller: 'SbPartialStateController',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills'],
        tab: { type: 'finalize-invoice-payments' },
      },
      params: {
        invoiceIds: [
          'f1c30f23-7dbf-4c80-8407-dffe2e896a16',
          'fbd5192f-1ca4-4b80-992e-635428662e7d',
          'ddd8dcf0-c5f7-4cb0-99d2-1d5459380622',
          '9139e8e2-35b1-4b12-8db4-b73928431a6e',
          '61eaff34-17d9-4639-b422-56606f7b806b',
          '52abd420-f502-47e6-9a50-640c01a58978',
        ],
      },
    });
});*/
