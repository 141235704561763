// LOD compatible version can be found 'smokeball-billing-web/src/components/draft-invoice-delete-proceed-modal'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withReduxStore } from '@sb-itops/react';
import { isModalVisible } from '@sb-itops/redux/modal-dialog';
import { deleteInvoices } from '@sb-billing/redux/invoices/delete-invoices';
import uuid from '@sb-itops/uuid';

import DeleteInvoicesProceedModalDialog from './DeleteInvoicesProceedModalDialog';

const mapStateToProps = (state, { confirmationModalId }) => ({
  modalId: confirmationModalId,
});

const mapDispatchToProps = (dispatch, { confirmationModalId, selectedInvoicesIds }) => ({
  onProceedClick: () => {
    if (isModalVisible({ modalId: confirmationModalId })) {
      deleteInvoices(
        selectedInvoicesIds.map((invoiceId) => ({
          invoiceId,
          versionId: uuid(),
        })),
      );
    }
  },
});

const DeleteInvoicesProceedModalDialogContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(DeleteInvoicesProceedModalDialog),
);

DeleteInvoicesProceedModalDialogContainer.displayName = 'DeleteInvoicesProceedModalDialogContainer';

DeleteInvoicesProceedModalDialogContainer.propTypes = {
  selectedInvoicesIds: PropTypes.arrayOf(PropTypes.string),
  confirmationModalId: PropTypes.string.isRequired,
};

DeleteInvoicesProceedModalDialogContainer.defaultProps = {
  selectedInvoicesIds: [],
};

export default DeleteInvoicesProceedModalDialogContainer;
