'use strict';

const defaultPreProcess = (property, caseSensitive = true) => {
  if (caseSensitive) {
    return property;
  }

  return (property && typeof property === 'string') ? property.toUpperCase() : property;
}

const fallbackValuePreProcess = (fallbackValue) => (property, caseSensitive = true) => defaultPreProcess([undefined, null].includes(property) ? fallbackValue : property, caseSensitive)

const createComparer = (property, direction, preProcessor = defaultPreProcess, caseSensitive = true) => (a, b) => {
  const left = preProcessor(a[property], caseSensitive);
  const right = preProcessor(b[property], caseSensitive);

  if (left === right) {
    return 0;
  }
  
  if (direction === 'asc') {
    return left < right ? -1 : 1;
  } else {
    return left > right ? -1 : 1; 
  }
};

const sortByProperty = (array, property, direction = 'asc', caseSensitive = true, fallbackValue = undefined) => {
  const comparerFn = createComparer(property, direction, fallbackValuePreProcess(fallbackValue), caseSensitive);
  return array.sort(comparerFn);
}

const sortByOrder = (array, properties, directions = ['asc'], caseSensitive = true, fallbackValue = undefined) => {
  const preProcessor = fallbackValuePreProcess(fallbackValue);
  return array.sort((a, b) => {
    for (const [index, property] of properties.entries()) {
      let left;
      let right;
      if (typeof property === 'function') {
        left = preProcessor(property(a), caseSensitive);
        right = preProcessor(property(b), caseSensitive);
      } else {
        left = preProcessor(a[property], caseSensitive);
        right = preProcessor(b[property], caseSensitive);
      }
      if (left === right) {
        continue;
      }
      
      if (directions[index] === 'asc' || directions[index] === 'ASC') {
        return left < right ? -1 : 1;
      } else {
        return left > right ? -1 : 1; 
      }
    }
  });
}

module.exports = {
  createComparer,
  sortByProperty,
  sortByOrder,
};
  