/**
 * A redux middleware that assigns `sbPath` to the action, which is the `path` of that the action was called from.
 * The `path` is the URL post `/#/`, with the guids removed.
 */
export const sbPathMiddleware = () => (next) => (action) =>
  next({
    ...action,
    sbPath: getPath(),
  });

const PATH_REGEX = /\/[^/]*#\/(.*)(:?\?*)+/;
// regex for a GUID with a trailing slash
const GUID_SLASH_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\//;

/**
 * Returns the `path`, based off of the current window URL.
 */
function getPath() {
  const path = window.location.href.match(PATH_REGEX) ? window.location.href.match(PATH_REGEX)[1] : '';

  return path.replace(GUID_SLASH_REGEX, '');
}
