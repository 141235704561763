'use strict';

const { capitalizeAllWords } = require('@sb-itops/nodash');

/**
 * Get cheque amount in words with localised units
 * @param {Object} params
 * @returns {number} params.amount
 * @returns {function} params.numberToWords
 * @returns {function} params.t
 */
function getAmountInWords({ amount, numberToWords, t }) {
  if (typeof numberToWords !== 'function' || typeof t !== 'function') {
    throw new Error(`The passed 'numberToWords'(${typeof numberToWords}) and 't'(${typeof t}) must be functions`);
  }

  const dollars = Math.floor(amount / 100);
  const cents = amount % 100;

  const amountWords = [
    // get singular/plural unit directly from dictionary
    getNumberInWords({ amount: dollars, numberToWords, unitLabel: t('dollar', { count: dollars }) }),
    getNumberInWords({ amount: cents, numberToWords, unitLabel: t('cent', { count: cents }) }),
  ]
    .filter((str) => str)
    .join(' and ');

  return `${amountWords} ONLY`;
}

function getNumberInWords({ amount, numberToWords, unitLabel }) {
  if (amount === 0) {
    return '';
  }

  const words = numberToWords(amount);

  return capitalizeAllWords(`${words} ${unitLabel}`);
}

module.exports = {
  getAmountInWords,
};
