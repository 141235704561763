/** 
* @param {string} html
* @return {string} html cleaned of all tags and trimmed
*/
function getTextContent(html) {
  if (!html) {
    return ''
  }
  // For some HTML fields the "empty" state is <p><br /></p>
  // This regex removes all tags and trims the field to retrieve only text content
  return html.replace(/<(.|\n)*?>/g, '').trim()
}

module.exports = {
  getTextContent,
};