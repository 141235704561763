import { sort as sortItems } from '@sb-itops/sort';

const sortFieldMap = {
  paidDate:      'currentVersion.paidDate',
  validFrom:     'currentVersion.validFrom',
  dueDate:       'currentVersion.dueDate',
  name:          'user.name',
  issuedDate:    'currentVersion.issuedDate',
  invoiceNumber: 'currentVersion.invoiceNumber',
  matter:        'matterDisplay',
  debtor:        'debtorDisplay',
  status:        'pseudoStatus',
};

// returns a function that lodash uses to compare & sort with
function getSortIteree (sortBy) {
  // some sort fields are nested and they are nested in different objects, so i've hard coded their path
  const sortField = sortFieldMap[sortBy] ? sortFieldMap[sortBy] : sortBy;

  return (invoice) => _.get(invoice, sortField);
}

export const sortInvoices = ({invoices, sortBy, sortDirection}) => {
  return sortItems(invoices, [getSortIteree(sortBy), 'validFrom'], [sortDirection, sortDirection]);
};
