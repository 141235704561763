/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export const creditNoteFormSchema = Yup.object().shape({
  amount: Yup.number().min(1).required(),
  selectedDate: Yup.string().required(),
  selectedMatterId: Yup.string().required(),
  selectedContactId: Yup.string().required(),
  description: Yup.string(),
});
