angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-bill/entries';

  $stateProvider

  .state('home.billing.view-bill.entries', {
    url: '/entries',
    templateUrl: BASE_DIRECTORY + '/view-bill-entries.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'bills'],
      tab: { type: 'invoice' }
    },
    controller: 'sbViewBillEntriesController',
    controllerAs: '$ctrl',
  });

});
