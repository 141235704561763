import { getRegion } from '@sb-itops/region';
import { store } from '@sb-itops/redux';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getUserRegion } from 'web/services/user-session-management';
import * as appState from 'web/redux/features/application-state';

export const authoriseCurrentUserP = async ({ log, defaultTeardown }) => {
  try {
    // Locally, a developer might be running different backend (nodejs) and front-end regions.
    // In that case, it's possible for them to login to the backend region using the front-end from another region.
    // That will generally be an accident, so we detect it here for the developer and let them know their mistake.
    if (getUserRegion() !== getRegion()) {
      throw new Error(
        `Attempt to authorise as user from ${getUserRegion()} with frontend region ${getRegion()} is not allowed`,
      );
    }

    const results = await fetchGetP({ path: '/billing/staff-permissions/authorise-user/:accountId/' });
    store.dispatch(appState.actions.setAuthorisationResult({ successful: true, userPermissions: results.body.userPermissions }));
  } catch (err) {
    const { authorisationFailures } = appState.types;

    const failureType =
      !err.payload || err.payload.status !== 403
        ? authorisationFailures.UNKNOWN_FAILURE
        : authorisationFailures.NO_ACCESS;
    failureType === authorisationFailures.NO_ACCESS ? log.warn(err) : log.error(err);

    store.dispatch(appState.actions.setAuthorisationResult({ successful: false, failureType }));
    throw new Error('Failed to authorise current user');
  }

  return defaultTeardown;
};
