/* eslint-env browser */
/*eslint no-console: ["error", { allow: ["debug", "info", "warn", "error"] }] */
'use strict';

const _ = require('lodash');

const logManager = () => {
  const LEVEL = Object.freeze({
    debug: 1,
    info: 2,
    warn: 3,
    error: 4
  });

  const event = Object.freeze({
    LOG_ERROR : 'log-error'
  });

  const eventHandlers = Object.freeze({
    'log-error' : []
  });
  
  let defaultLogLevel = LEVEL.warn;
  
  const loggerLevels = { sbLoggerService: defaultLogLevel };

  // Keep a history of errors for extraction
  const consoleErrors = [];

  let _logger = {
    debug : console.debug,
    info : console.info,
    warn :console.warn,
    error : (...args) => {
      // keep first ten errors as initial failures often cause subsequent ones
      let errorLine = format.apply(undefined, args)
      if (consoleErrors.length >= 100) {
        consoleErrors.splice(10, 1, '... (items omitted)');
        consoleErrors.splice(11, 1);
        consoleErrors.push(errorLine);
      } else {
        consoleErrors.push(errorLine);
      }
      console.error(...args)
    }
  };

  const isLogEnabled = (name, level) => loggerLevels[name] === LEVEL[level];

  const validName = (name) => _.isString(name) && name.trim().length > 0;

  const validLevel = (level) => _.isNumber(LEVEL[level]);

  const loggers = () => _.clone(loggerLevels);

  const setLogger = (logger) => _logger = logger; 

  const on = (event, handler) => eventHandlers[event].push(handler);

  const logConsole = (type, name, id) => {
    return function(...args) {
      if (args[0] && _.isString(args[0])) {
        args[0] = name + (id ? '-' + id : '') + ' ' + args[0]; // if 1st arg is string concat name to preserve string substitution
      } else {
        args.unshift(name + id);
      }

      if (isLogEnabled('sbLoggerService', 'debug')) {
        _logger.debug('sbLoggerService logConsole, name:', name, 'id', id, 'type:', type, 'arguments:', arguments, 'args:', args);
      }

      if (LEVEL[type] >= loggerLevels[name]) {
        _logger[type].apply(undefined, args);
      }

      if (LEVEL[type] === LEVEL.error) {
        _logger.info('sbLoggerService broadcasting error');
        eventHandlers[event.LOG_ERROR].forEach(h => h({ message: format.apply(undefined, args) }))
      }
    };
  }

  const setLoggerLevel = (level, name) => {
    if (validName(name)) {
      _logger.info('sbLoggerService set log level: %s, logger: %s', level, name);
      loggerLevels[name] = LEVEL[level];
    } else {
      _logger.info('sbLoggerService set log level: %s, all loggers', level);
      _.each(_.keys(loggerLevels), function (logger) {
        loggerLevels[logger] = LEVEL[level];
      });
    }
  }

  const setLogLevel = (level, name) => {
    if (validLevel(level)) {
      if (_.isArray(name)) {
        _.each(name, function (logger) {
          if (validName(logger)) {
            setLoggerLevel(level, logger);
          }
        });
      } else {
        setLoggerLevel(level, name);
      }
    }
  }

  const setDefaultLevel = (level) => {
    if (validLevel(level)) {
      console.log('sbLoggerService set default log level for new loggers:', level); // eslint-disable-line
      defaultLogLevel = LEVEL[level];
    } else {
      throw new Error('invalid default log level set: ' + level);
    }
  }



  const getLogger = (name, id = '') => {
    if (!validName(name)) {
      throw new Error(`sbLoggerService attempt to create a logger with an invalid name: ${name}`)
    }

    if (isLogEnabled(name, 'debug')){
      _logger.info('sbLoggerService create logger', name);
    }

    loggerLevels[name] = loggerLevels[name] || defaultLogLevel;

    return {
      debug: logConsole('debug', name, id),
      info: logConsole('info', name, id),
      warn: logConsole('warn', name, id),
      error: logConsole('error', name, id),
      setLogLevel: function (level) {
        setLogLevel(level, name);
      }
    };
  }

  // eslint-disable-next-line no-undef
  const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

  if (isBrowser) {
    window.Smokeball = window.Smokeball || {};

    if (!window.Smokeball.logger) {
      window.Smokeball.logger = {
        setLogLevel: setLogLevel,
        setDefaultLevel: setDefaultLevel,
        loggers: loggers
      };
    }
  }

  return {
    getLogger,
    setLogger,
    setDefaultLevel,
    on,
    event,
    consoleErrors
  }
};

module.exports = logManager();

/**
 * simple interpolation using %s, stringifies objects, preserves additional arguments
 * @returns {string}
 */
function format(...args) {
  function hasToken(s){
    return s.indexOf('%s') > -1 && args.length > 1;
  }

  function strValue(v) {
    return _.isObject(v) ? JSON.stringify(v) : v;
  }

  function replaceToken() {
    const value = args.splice(1,1)[0];
    return args[0].replace('%s', strValue(value));
  }

  if (args.length === 0) {
    return 'empty';
  }

  if ( _.isString(args[0]) ) {
    while (hasToken(args[0])) {
      args[0] = replaceToken();
    }
  }

  for (let i = 0; i < args.length; i++) {
    args[i] = strValue(args[i]);
  }

  return args.join(' ');
}
