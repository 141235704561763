import moment from 'moment';
import { dateRangeTypes } from '@sb-itops/date';

export const getFilterDatesForQuery = ({ dateRangeType, fromInt, toInt }) => {
  let fromDate;
  let toDate;

  switch (dateRangeType) {
    case dateRangeTypes.THIS_MONTH:
      fromDate = moment().startOf('month');
      toDate = moment().endOf('month');
      break;
    case dateRangeTypes.LAST_MONTH:
      fromDate = moment().subtract(1, 'months').startOf('month');
      toDate = moment().subtract(1, 'months').endOf('month');
      break;
    case dateRangeTypes.CUSTOM:
      fromDate = moment(fromInt, 'YYYYMMDD').startOf('day').toDate();
      toDate = moment(toInt, 'YYYYMMDD').endOf('day').toDate();
      break;
    default:
      fromDate = undefined;
      toDate = undefined;
  }

  const fromISOString = !fromDate ? fromDate : fromDate.toISOString();
  const toISOString = !toDate ? toDate : toDate.toISOString();

  return { from: fromISOString, to: toISOString };
};
