import PropTypes from 'prop-types';
import React from 'react';
import stripeLogo from 'web/assets/integration-stripe.png';
import { StripeGetStartedCard } from './sections';
import Styles from './StripeSettings.module.scss';
import { StripeSettingsForm } from '../stripe-settings-form.2';

export const StripeSettings = ({ showSettingsForm, onGetStarted, isLoading, onSaveSettings, isSaving }) =>
  showSettingsForm ? (
    <StripeSettingsForm onSaveSettings={onSaveSettings} isSaving={isSaving} />
  ) : (
    <div className={Styles.stripeSection}>
      <div className={Styles.stripeLogoContainer}>
        <img className={Styles.logo} src={stripeLogo} alt="Stripe" />
        <span className={Styles.logoText}>Payments Integration</span>
      </div>
      <StripeGetStartedCard onPrimaryClick={() => onGetStarted()} isLoading={isLoading} />
    </div>
  );

StripeSettings.displayName = 'StripeSettings';

StripeSettings.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showSettingsForm: PropTypes.bool.isRequired,
  onGetStarted: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};

StripeSettings.defaultProps = {};
