'use strict';

/**
 * @typedef {'billingWebApp'|'paymentPortal'} ApplicationType
 */

// Applications need to provide their own styling to the hosted fields used within the StripePaymentForm.
// This sucks, but unfortunately there isn't a great way around it. We deal with it here as it seems like
// the most obvious and consistent place, the down sides are that it could be difficult to find this styling
// and we have granted application knowledge to business logic.
// See: https://stripe.com/docs/js/appendix/style?type=card
const hostedFieldStyles = {
  billingWebApp: {
    // old way
    base: {
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
      fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    },
    invalid: {
      iconColor: 'rgb(169, 68, 66)',
      color: 'rgb(169, 68, 66)',
    },
    // new way - appearence API
    appearance: {
      variables: {
        fontSizeBase: '14px',
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        borderRadius: '4px',
        colorDanger: 'rgb(169, 68, 66)',
        spacingGridRow: '13px',
        colorText: 'rgb(51, 51, 51)',
      },
      rules: {
        '.Label': {
          textTransform: 'uppercase',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '20px',
        },
        '.Input': {
          padding: '7px 8px 7px 8px',
          boxShadow: 'none',
          borderColor: 'rgb(217, 217, 217)',
        },
        '.Input:focus': {
          borderColor: 'rgb(102, 175, 233)',
          outline: 'none',
          boxShadow: 'none',
        },
        '.Input--invalid': {
          boxShadow: 'none',
        },
        '.TermsText': {
          fontSize: '0',
        },
      },
    },
  },
  paymentPortal: {
    // old way
    base: {
      color: 'rgb(85, 85, 85)',
      fontSize: '16px',
      fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    },
    invalid: {
      iconColor: 'rgb(169, 68, 66)',
      color: 'rgb(169, 68, 66)',
    },
    // new way - appearence API
    appearance: {
      variables: {
        fontSizeBase: '16px',
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        borderRadius: '4px',
        colorDanger: 'rgb(169, 68, 66)',
        spacingGridRow: '16px',
        colorText: 'rgb(85, 85, 85)',
      },
      rules: {
        '.Label': {
          textTransform: 'uppercase',
          fontWeight: '500',
          fontSize: '13px',
          color: 'rgb(41,43,44)',
          lineHeight: '19.5px',
        },
        '.Input': {
          padding: '7px 8px 7px 8px',
          boxShadow: 'none',
          borderColor: 'rgb(217, 217, 217)',
        },
        '.Input:focus': {
          borderColor: 'rgb(102, 175, 233)',
          outline: 'none',
          boxShadow: 'none',
        },
        '.Input--invalid': {
          boxShadow: 'none',
        },
        '.TermsText': {
          fontSize: '0',
        },
      },
    },
  },
};

/**
 * getPaymentFormConfiguration
 *
 * Returns the information required to display a payment form (i.e. charge UI) for Stripe.
 *
 * @param {Object} params
 * @param {Object} params.formattedProviderSpecificSettings Current Stripe settings
 * @param {Object} [params.providerSpecificFields] Provider specific fields
 * @param {ApplicationType} [params.providerSpecificFields.application] The application requesting the configuration. This allows provider specific
 *                                                                      implementations to optionally configure by UI differently by application.
 *
 * @returns {Object} The Stripe specific details.
 */
const getPaymentFormConfiguration = ({ formattedProviderSpecificSettings, providerSpecificFields }) => {
  const { application } = providerSpecificFields || {};

  return {
    publicKey: formattedProviderSpecificSettings.publicKey,
    hostedFieldsStyle: hostedFieldStyles[application] || hostedFieldStyles.billingWebApp,
    supportsFeeDetails: true,
  };
};

module.exports = {
  getPaymentFormConfiguration,
};
