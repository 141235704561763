import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'bank-account-balances',
  keyPath: 'id',
  ngCacheName: 'sbBankAccountBalancesService',
  syncType: syncTypes.ALL,
  changesetProcessor: ({ entities }) =>
    entities.map((accountBalance) => {
      // eslint-disable-next-line no-param-reassign
      accountBalance.contactBalances = accountBalance.contactBalances
        ? accountBalance.contactBalances.filter((cb) => cb.contactId)
        : [];

      return accountBalance;
    }),
  immutable: false,
});

export * from './bank-account-balances-opdates';

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

/**
 * @param {string} bankAccountId
 * @returns {BankAccount}
 */
export const getTemplateBankAccount = (bankAccountId) => ({
  isDeleted: false,
  isInactive: false,
  id: bankAccountId,
  balance: 0,
  contactBalances: [],
  matterBalances: [],
});
