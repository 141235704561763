import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { getUserId, getAccountId } from 'web/services/user-session-management';
import uuid from '@sb-itops/uuid';
import moment from 'moment';
import { sendMetric } from 'web/services/metrics';
import { getBankAccountBalanceProtectionOpdate } from '../bank-account-balances.2/opdates';
import { getSettings } from '../bank-account-settings';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBalanceProtectionService',
  keyPath: 'id',
});

const { opdateCache: opdateBalancesCache, rollbackOpdateCache: rollbackOpdateBalancesCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountBalancesService',
  keyPath: 'id',
});

export const protectBalance = async (protectionDetails) => {
  // array of requests which can be used as opdates
  const protectionRequests = buildOpdates(protectionDetails);

  sendMetric('TrustFundsProtected', {
    amount: protectionRequests.reduce((a, b) => a + b.amount || 0, 0),
  });

  const bankAccountSetting = getSettings();
  const bankBalanceType = bankAccountSetting && bankAccountSetting.bankBalanceType;
  const accountBalanceOpdates = getBankAccountBalanceProtectionOpdate({
    protectionDetails,
    bankBalanceType,
  });
  try {
    opdateCache({ optimisticEntities: protectionRequests });
    opdateBalancesCache({ optimisticEntities: [accountBalanceOpdates] });
    const path = `/billing/balance-protection/save/:accountId/`;
    const fetchOptions = { body: JSON.stringify({ requests: protectionRequests }) };
    await fetchPostP({ path, fetchOptions });
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: protectionRequests });
    rollbackOpdateBalancesCache({ optimisticEntities: [accountBalanceOpdates] });
    throw err;
  }
};

function buildOpdates(protectionDetails) {
  const { matterId, contactId, bankAccountId, protectItems } = protectionDetails;

  return protectItems.map((item) => ({
    id: uuid(),
    accountId: getAccountId(),
    userId: getUserId(),
    bankAccountId,
    matterId,
    contactId,
    reason: item.reason,
    amount: item.amount,
    effectiveDate: moment().format('YYYYMMDD'),
    timestamp: moment().toISOString(), // for opdate only
    isProtected: true, // for opdate only
  }));
}
