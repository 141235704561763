import { gql } from '@apollo/client';
import { sortByOrder } from '@sb-itops/nodash';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query FirmFeeEntriesRouteData($feeFilter: FeeFilter, $offset: Int, $limit: Int, $sort: ListSort) {
    feeList(filter: $feeFilter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      sortOrder {
        fieldNames
        directions
      }
      results {
        id
        activityCode
        amountIncludesTax
        createdByAutoTime
        description
        billableDuration
        duration
        durationWorked
        feeDate
        feeEarnerStaff {
          id
          initials
          isFormerStaff
        }
        feeType
        finalized
        isBillable
        isDeleted
        isInvoicedExternally
        invoice {
          id
          invoiceNumber
        }
        isTaxExempt
        matter {
          id
          matterNumber
          clientDisplay
          otherSideDisplay
          matterType {
            matterTypeId
            name
          }
        }
        rate
        tax
        utbmsTaskCode
        waived
      }
    }
  }
`;

const notificationIds = [
  'WebQueryFeesNotifications.AccountFeesUpdated',
  'WebQueryInvoicingNotifications.InvoiceUpdated',
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
];

const onOpdateQuery = ({ queryData, queryVariables, entityOpdateEvent }) => {
  const { eventType, typeName, optimisticEntity } = entityOpdateEvent;

  // Ignore irrelevant entity types / operations.
  if (`${eventType}-${typeName}` !== 'ENTITY_UPDATED-Fee') {
    return undefined;
  }

  if (!queryVariables.feeFilter.includeStatus.deleted && optimisticEntity.removed) {
    return undefined;
  }

  // Ignore optimistic entities which do not sit within the currently viewed page of data.
  const { fieldNames: sortFields, directions: sortOrders } = queryVariables.sort;
  const updatedListItems = queryData.feeList.results.map((result) => {
    const { id: existingId } = result;
    const { id: optimisticId } = optimisticEntity;

    return existingId === optimisticId ? { ...result, ...optimisticEntity } : result;
  });

  const sortedListItems = sortByOrder(updatedListItems, sortFields, sortOrders, false /* case insensitive */);

  // Return the new opdated data for the query.
  return {
    ...queryData,
    feeList: {
      ...queryData.feeList,
      results: sortedListItems,
    },
  };
};

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FeeTableData = {
  query,
  notificationIds,
  onOpdateQuery,
};
