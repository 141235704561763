'use strict';

/**
 * getMinChargeAmountInCents
 *
 * @param {Object} params
 * @returns {Number} Minimal allowed charge amount in cents.
 */
const getMinChargeAmountInCents = () => 0;

module.exports = {
  getMinChargeAmountInCents,
};
