import { useState, useEffect } from 'react';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { fetchById as fetchFullCorrespondenceHistoryById } from '@sb-billing/redux/correspondence-history-full';
import { getLogger } from '@sb-itops/fe-logger';
import { useIsMounted } from '@sb-itops/react';

const log = getLogger('useReminderCorrespondenceHistory.js');

export const useReminderCorrespondenceHistory = ({ lastCorrespondenceHistory }) => {
  const isMounted = useIsMounted();
  const [latestFullCorrespondenceHistory, setLatestFullCorrespondenceHistory] = useState(undefined);

  // eslint-disable-next-line consistent-return
  const fetchCorrespondenceDetails = async () => {
    if (!lastCorrespondenceHistory) {
      return {
        loading: false,
        latestFullCorrespondenceHistory: undefined,
      };
    }
    let fullCorrespondenceHistory;
    try {
      fullCorrespondenceHistory = await fetchFullCorrespondenceHistoryById(lastCorrespondenceHistory.id);
    } catch (err) {
      log.warn('Error occurred getting full correspondence history', err);
    }
    // If the full history request failed, we use current summary so we can at least present some details
    const correspondenceHistory = fullCorrespondenceHistory || lastCorrespondenceHistory;
    const displayName = getPersonByUserId(correspondenceHistory.userId) || {};
    const correspondenceDetails = {
      ...correspondenceHistory,
      contactDisplayName: getContactDisplay(correspondenceHistory.contactIds[0]),
      userDisplayName: displayName.name,
    };

    if (
      // Cannot useState on an unmounted component
      isMounted &&
      isMounted.current &&
      // Since this hook is asynchronous and useEffect is not, we need to use useState to get result.
      // We don't want to set the state every time useEffect runs as we are not guaranteed props.correspondenceHistory
      // is same object which could cause infinite loop.
      // Therefore, we set the state only when current correspondence history doesn't match the one we have in state.
      (!latestFullCorrespondenceHistory || latestFullCorrespondenceHistory.id !== lastCorrespondenceHistory.id)
    ) {
      setLatestFullCorrespondenceHistory(correspondenceDetails);
    }
  };

  useEffect(() => {
    fetchCorrespondenceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCorrespondenceHistory]);

  return {
    loading: latestFullCorrespondenceHistory === undefined && lastCorrespondenceHistory !== undefined,
    latestFullCorrespondenceHistory: latestFullCorrespondenceHistory || lastCorrespondenceHistory,
  };
};
