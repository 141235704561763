import * as types from './types';

export const initState = {
  tempSettings: {},
  settings: {
    'default-billable-hours': {
      id: 'default-billable-hours',
      position: { x: 0, y: 0 },
      dashboard: 'default',
      type: 'BillableHours',
      colour: '#b5b5b5',
      title: 'Hours',
      size: { x: 3, y: 4 },
      minY: 2,
      minX: 2,
    },
    'default-recently-viewed': {
      id: 'default-recently-viewed',
      position: { x: 0, y: 4 },
      size: { x: 3, y: 6 },
      minY: 2,
      dashboard: 'default',
      type: 'RecentlyViewed',
      colour: '#b5b5b5',
      title: 'My Recently Viewed',
    },
    'default-daily-digest': {
      id: 'default-daily-digest',
      position: { x: 4, y: 0 },
      size: { x: 7, y: 5 },
      dashboard: 'default',
      type: 'DailyDigest',
      colour: '#b5b5b5',
      title: 'Daily Digest',
      minX: 4,
      minY: 3,
    },
    'default-billable-graph': {
      id: 'default-billable-graph',
      position: { x: 4, y: 5 },
      size: { x: 7, y: 5 },
      dashboard: 'default',
      minY: 3,
      minX: 4,
      type: 'BillableGraph',
      colour: '#b5b5b5',
      title: 'Billable Activity',
    },
  },
  currentDashboard: 'default',
  dashboards: { default: { id: 'default', name: 'Your dashboard' } },
};

export const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case types.SET_DASHBOARDS: {
      const newState = {
        ...state,
        dashboards: action.payload.dashboards,
      };
      return newState;
    }
    case types.SET_DASHBOARD_SETTINGS: {
      const newState = {
        ...state,
        settings: action.payload.settings,
      };
      return newState;
    }
    case types.SET_TEMP_SETTINGS: {
      const newState = {
        ...state,
        tempSettings: { ...(state.tempSettings || {}), ...action.payload.tempSettings },
      };
      return newState;
    }
    case types.SET_CURRENT_DASHBOARD: {
      const newState = {
        ...state,
        currentDashboard: action.payload.currentDashboard,
      };
      return newState;
    }
    default:
      return state;
  }
};
