angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/account';

  $stateProvider
    .state('home.billing.support.account', {
      url: '/account',
      templateUrl: BASE_DIRECTORY + '/account.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});
