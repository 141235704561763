angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('home.billing.vendor-proof-of-payment', {
      url: '/vendor-proof-of-payment/:transactionId/:paymentId/:chequeId',
      template: `<sb-vendor-proof-of-payment-view style="width:100%"></sb-vendor-proof-of-payment-view>`,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'payment', 'receipt'],
        tab: { type: 'vendor-proof-of-payment' }
      }
    });

});
