'use strict';

/**
 * @typedef {object} AutoNumberingComponent
 * @property {number} type
 * @property {Array.<string>} usedPrefixes
 */

/**
 * @param {object} params
 * @param {number} params.prefixType
 * @param {Array.<AutoNumberingComponent>} params.components
 * @returns {Array.<string>} used prefixes
 */
const getUsedPrefixesByType = ({ type, components = [] }) => {
  if (!Object.values(prefixType).includes(type)) {
    throw new Error(`Invalid prefixType: ${type}`);
  }

  const prefixComponent = components.find((component) => component.type === type);
  return prefixComponent ? prefixComponent.usedPrefixes : [];
};

/**
 * @param {object} params
 * @param {string}  params.prefix the value to test for
 * @param {number} params.prefixType the type of the prefix we are testing for
 * @param {Array.<AutoNumberingComponent>} params.components
 */
const isPreviouslyUsedPrefix = ({ prefix = '', type, components = [] }) => {
  const prefixTypeValues = Object.values(prefixType);

  if (!prefixTypeValues.includes(type)) {
    throw new Error(`Invalid prefixType: ${type}`);
  }

  const typesToInclude = prefixTypeValues.filter((prefixType) => prefixType !== type);

  return typesToInclude.some((prefixType) => getUsedPrefixesByType({ type: prefixType, components }).includes(prefix));
};

/**
 * @readonly
 * @enum {number} PrefixType
 */
const prefixType = Object.freeze({
  NONE: 0,
  RECEIPT: 1,
  TRANSFER_OF_FUNDS: 2,
  ELECTRONIC_PAYMENT: 3,
  TRUST_TO_OFFICE: 4,
});

module.exports = {
  getUsedPrefixesByType,
  isPreviouslyUsedPrefix,
  prefixType,
};
