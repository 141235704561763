import { cacheFactory, syncTypes, store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getAccountId } from 'web/services/user-session-management';
import uuid from '@sb-itops/uuid';
import moment from 'moment';
import { hasFacet, facets } from '@sb-itops/region-facets';

import domain from '../domain';
import { calculateBankAccountBalanceForDate } from '../transactions';

const api = cacheFactory({
  domain,
  name: 'bank-reconciliation-setup-2',
  keyPath: 'bankAccountId',
  ngCacheName: 'sbBankReconciliationSetupService2',
  syncType: syncTypes.SINCE,
  immutable: false,
  changesetProcessor: ({ entities }) =>
    entities.map((e) => {
      if (!e.bankAccountId) {
        e.bankAccountId = `${e.accountId}/Trust`;
      }
      return e;
    }),
});

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankReconciliationSetupService2',
  keyPath: 'bankAccountId',
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated, clearCache } = api;

export const getSettings = (trustAccountId) =>
  getList().find((setup) => setup.bankAccountId === trustAccountId && !setup.isRemoved);

// This is needed for new cache as legacy setup cache just calls clearCache() on remove opdate
export const removeOpdateCache = (trustAccountId) => {
  const currentSetup = getById(trustAccountId);
  const removedSetup = {
    ...currentSetup,
    isRemoved: true, // Cancelled
  };
  opdateCache({ optimisticEntities: [removedSetup] });
};

export const removeReconSetup = (bankAccountId) =>
  store.dispatch(async () => {
    removeOpdateCache(bankAccountId);

    const path = `/billing/bank-reconciliation-setup/remove/${getAccountId()}/`;
    const body = {
      bankAccountId,
    };
    const fetchOptions = {
      body: JSON.stringify(body),
    };

    const res = await fetchPostP({ path, fetchOptions });
    return res;
  });

export const saveReconSetup = (setup, bankAccountId) =>
  store.dispatch(async () => {
    const bankReconSetupOpdate = getBankReconSetupOpdate(setup, bankAccountId);
    try {
      opdateCache({ optimisticEntities: [{ ...bankReconSetupOpdate, bankAccountId }] });

      const path = `/billing/bank-reconciliation-setup/save/${getAccountId()}/`;
      const body = {
        versionId: uuid(),
        ...setup,
        bankAccountId,
      };
      const fetchOptions = {
        body: JSON.stringify(body),
      };

      const res = await fetchPostP({ path, fetchOptions });
      return res;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [{ ...bankReconSetupOpdate, bankAccountId }] });
      throw err;
    }
  });

const getBankReconSetupOpdate = (setup, bankAccountId) => {
  const yyyymmdd = +moment(`${setup.reconciliationStartDate}`, 'YYYYMMDD').format('YYYYMMDD');
  const initialLedgerBalance = calculateBankAccountBalanceForDate({
    bankAccountId,
    yyyymmdd,
    byEnteredDate: hasFacet(facets.transactionsByEnteredDate),
  });
  return {
    ...setup,
    initialLedgerBalance,
  };
};
