// We run this is preAuthenticationBootstrapP so it runs only once

export const initialiseBrainfish = ({ log }) => {
  const brainfishWidgetKey = 'bf_search_widget_6Gd9RkAiaLRgbwy2Een8A5a4oyMttTn6Da0xZF';
  const brainfishScriptUrl = 'https://cdn.jsdelivr.net/npm/@brainfish-ai/widgets-initiator@latest/dist/web.js';

  try {
      var brainfishScript = document.createElement('script');
      brainfishScript.type = 'module';
      brainfishScript.text = `import Brainfish from "${brainfishScriptUrl}";
        Brainfish.Widgets.init({ widgetKey: "${brainfishWidgetKey}" });`;
    
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(brainfishScript, s);

  } catch (err) {
    // We don't stop the user from using billing if brainfish fails, just log it.
    log.warn('Failed to initialise Brainfish', err);
  }
};