import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setModalDialogVisible, setModalDialogHidden, isModalVisible } from '@sb-itops/redux/modal-dialog';
import { withReduxStore } from '../with-redux-store';
import ModalDialog, { ButtonPropTypes } from './ModalDialog';

const mapStateToProps = (state, { body, title, modalId, buttons, visible }) => ({
  title,
  body,
  visible: visible || isModalVisible({ modalId }),
  buttons: buttons && {
    rightAlign: buttons && buttons.rightAlign,
    primary: {
      show: buttons.primary && buttons.primary.show,
      text: buttons.primary && buttons.primary.text,
      props: buttons.primary && buttons.primary.props,
      size: buttons.primary && buttons.primary.size,
      submitting: buttons.primary && buttons.primary.submitting,
    },
    secondary: {
      show: buttons.secondary && buttons.secondary.show,
      text: buttons.secondary && buttons.secondary.text,
      props: buttons.secondary && buttons.secondary.props,
      size: buttons.secondary && buttons.secondary.size,
      submitting: buttons.secondary && buttons.secondary.submitting,
    },
    customList: buttons.customList,
  },
});

const mapDispatchToProps = (dispatch, { buttons, modalId, onPrimaryClick, onSecondaryClick, onCloseClick }) => {
  const actions = {};

  if (
    // if the user hasnt specified any buttons
    !buttons ||
    // or they havent specfied the primary button
    (buttons && !buttons.primary) ||
    // or they have specifed the primary button, but in a misformed way
    (buttons && buttons.primary && (buttons.primary.show === true || buttons.primary.show === undefined))
  ) {
    // if the user doesnt want a form to control the primary button
    if (buttons && buttons.primary && buttons.primary.props && !buttons.primary.props.form) {
      // imbue the primary button callback with the modal visibility calls
      actions.onPrimaryClick = () => {
        setModalDialogVisible({ modalId });
        if (onPrimaryClick) {
          onPrimaryClick();
        }
        setModalDialogHidden({ modalId });
      };
    }
  }

  if (
    !buttons ||
    (buttons && !buttons.secondary) ||
    (buttons && buttons.secondary && (buttons.secondary.show === true || buttons.secondary.show === undefined))
  ) {
    actions.onSecondaryClick = () => {
      setModalDialogHidden({ modalId });
      if (onSecondaryClick) {
        onSecondaryClick();
      }
    };
  }

  actions.onCloseClick = () => {
    setModalDialogHidden({ modalId });
    if (onCloseClick) {
      onCloseClick();
    }
  };

  return actions;
};

/**
 * @deprecated
 * Use the Modal component directly with your own redux state to determine whether or not the modal should display.
 */
const ModalDialogContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(ModalDialog));

ModalDialogContainer.displayName = 'ModalDialogContainer';

ModalDialogContainer.propTypes = {
  modalId: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.shape({
    rightAlign: PropTypes.bool,
    primary: ButtonPropTypes,
    secondary: ButtonPropTypes,
    customList: PropTypes.arrayOf(ButtonPropTypes),
  }),
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  onCloseClick: PropTypes.func,
};

ModalDialogContainer.defaultProps = {
  buttons: undefined,
  onPrimaryClick: undefined,
  onSecondaryClick: undefined,
  onCloseClick: undefined,
};

export default ModalDialogContainer;
