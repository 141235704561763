import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { getUserId } from 'web/services/user-session-management';
import { sendMetric } from 'web/services/metrics';
import { getBankAccountBalanceProtectionOpdate } from '../bank-account-balances.2/opdates';
import { getSettings } from '../bank-account-settings';
// eslint-disable-next-line import/no-cycle
import { getById as getProtectedFundsById } from '.';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBalanceProtectionService',
  keyPath: 'id',
});

const { opdateCache: opdateBalancesCache, rollbackOpdateCache: rollbackOpdateBalancesCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountBalancesService',
  keyPath: 'id',
});

export const unprotectBalance = async (protectionId) => {
  const existingProtection = getProtectedFundsById(protectionId) || {};

  const updatedProtection = { ...existingProtection, isProtected: false };
  opdateCache({ optimisticEntities: [updatedProtection] });
  sendMetric('TrustFundsUnprotected', { amount: existingProtection.amount });

  const bankAccountSetting = getSettings();
  const bankBalanceType = bankAccountSetting && bankAccountSetting.bankBalanceType;
  const accountBalanceOpdates = getBankAccountBalanceProtectionOpdate({
    protectionDetails: {
      bankAccountId: existingProtection.bankAccountId,
      matterId: existingProtection.matterId,
      contactId: existingProtection.contactId,
      protectItems: [{ amount: existingProtection.amount }],
    },
    bankBalanceType,
    unprotect: true,
  });

  opdateBalancesCache({ optimisticEntities: [accountBalanceOpdates] });

  const payload = {
    userId: getUserId(),
    bankAccountId: existingProtection.bankAccountId,
  };

  try {
    const path = `/billing/balance-protection/unprotect/:accountId/${protectionId}/`;
    const fetchOptions = { body: JSON.stringify(payload) };
    await fetchPostP({ path, fetchOptions });
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: [updatedProtection] });
    rollbackOpdateBalancesCache({ optimisticEntities: [accountBalanceOpdates] });
    throw err;
  }
};
