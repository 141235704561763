import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setModalDialogVisible, setModalDialogHidden, isModalVisible } from '@sb-itops/redux/modal-dialog';
import composeHooks from '@sb-itops/react-hooks-compose';
import DebtorsWithPaymentPlan from './DebtorsWithPaymentPlan';

const PAYMENT_PLAN_MODAL_ID = 'payment-plan-modal';

const hooks = ({
  contactCount,
  dataLoading,
  debtorsWithPaymentPlans,
  disableCreatePaymentPlan,
  sortBy,
  sortDirection,
  onClickLink,
  onPaymentPlanSort,
}) => ({
  useDialogs: () => ({
    onShowAddPaymentPlanModalDialog: () => setModalDialogVisible({ modalId: PAYMENT_PLAN_MODAL_ID }),
    onCloseAddPaymentPlanModalDialog: () => setModalDialogHidden({ modalId: PAYMENT_PLAN_MODAL_ID }),
  }),
  useData: () => {
    // useSelector is required to trigger a render if the modal becomes visible
    const isPaymentPlanModalVisible = useSelector(() => isModalVisible({ modalId: PAYMENT_PLAN_MODAL_ID }));

    return {
      contactCount,
      dataLoading,
      debtorsWithPaymentPlans,
      disableCreatePaymentPlan,
      isPaymentPlanModalVisible,
      paymentPlanModalId: PAYMENT_PLAN_MODAL_ID,
      sortBy,
      sortDirection,
      onPaymentPlanSort,
      onRowClick: (contactId) => {
        onClickLink({ type: 'paymentPlan', id: contactId });
      },
    };
  },
});

const DebtorsWithPaymentPlanPanelContainer = composeHooks(hooks)(DebtorsWithPaymentPlan);

DebtorsWithPaymentPlanPanelContainer.displayName = 'DebtorsWithPaymentPlanPanelContainer';

DebtorsWithPaymentPlanPanelContainer.propTypes = {
  contactCount: PropTypes.number.isRequired,
  dataLoading: PropTypes.bool,
  debtorsWithPaymentPlans: PropTypes.array,
  disableCreatePaymentPlan: PropTypes.bool.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  onPaymentPlanSort: PropTypes.func.isRequired,
};

DebtorsWithPaymentPlanPanelContainer.defaultProps = {
  dataLoading: false,
  disableCreatePaymentPlan: false,
  sortBy: 'displayName',
  sortDirection: 'asc',
};

export default DebtorsWithPaymentPlanPanelContainer;
