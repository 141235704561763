import lhJustifiedLeft from '../../assets/lh-justified-left.svg'
import lhJustifiedRight from '../../assets/lh-justified-right.svg'
import lhHoriStackLeft from '../../assets/lh-hori-stack-left.svg'
import lhHoriStackRight from '../../assets/lh-hori-stack-right.svg'
import lhVertStackLeft from '../../assets/lh-vert-stack-left.svg'
import lhVertStackRight from '../../assets/lh-vert-stack-right.svg'
import lhVertStackCenter from '../../assets/lh-vert-stack-center.svg'

angular.module('sb.billing.webapp').component('sbLetterHeadLayout', {
  templateUrl: 'ng-components/invoice-settings/letter-head-layout.html',
  bindings: { selectedLayout: '<', syncUpdate: '&' },
  controller: function () {
    const ctrl = this;
    ctrl.lhJustifiedLeft = lhJustifiedLeft;
    ctrl.lhJustifiedRight = lhJustifiedRight;
    ctrl.lhHoriStackLeft = lhHoriStackLeft;
    ctrl.lhHoriStackRight = lhHoriStackRight;
    ctrl.lhVertStackLeft = lhVertStackLeft;
    ctrl.lhVertStackRight = lhVertStackRight;
    ctrl.lhVertStackCenter = lhVertStackCenter;

    ctrl.setActiveLayout = setActiveLayout;

    ctrl.$onChanges = (changes) => {
      if (changes.selectedLayout) {
        setActiveLayout(changes.selectedLayout.currentValue);
      }
    }

    function setActiveLayout(selectedLayout) {
      ctrl.selectedLayout = selectedLayout;
      ctrl.syncUpdate({selectedLayout});
    }

  }
});
