angular.module('sb.billing.webapp').component('sbDataBoostInfo', {
  require: {sbComposeCtrl: '^sbCompose'},
  bindings: {composeKey: '@'},
  controller: function (sbBuildVersion, sbBuildVersionService) {

    this.$onInit = () => {
      if (_.isEmpty(this.composeKey)) {
        throw new Error('Cannot create sbDataBoostInfo with invalid compose key');
      }

      // async data load is OK here...just for support
      sbBuildVersionService.buildVersionP()
        .then((serverVersion) => {
          const appData = { version: sbBuildVersion.SB_BUILD_VERSION, serverVersion };
          this.sbComposeCtrl.setComposeData(appData, this.composeKey);
        })
        .catch((error) => {
          const appData = {
            version: sbBuildVersion.SB_BUILD_VERSION,
            serverVersion: 'ERROR getting server version',
          };
          this.sbComposeCtrl.setComposeData(appData, this.composeKey);
          throw error;
        })
      ;
    };

  }
}
);
