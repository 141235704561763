import { utilsFactory, store } from '@sb-itops/redux';
import { domain } from '../../domain';

const { createActionName, registerReducer, getState } = utilsFactory({ domain, name: 'home.settings.invoice' });
export const initState = {};

// actions
export const SELECT_TEMPLATE = createActionName('SELECT_TEMPLATE');
export const ADD_NEW_TEMPLATE = createActionName('ADD_NEW_TEMPLATE');

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SELECT_TEMPLATE:
      return { ...state, isSelected: action.payload };
    case ADD_NEW_TEMPLATE:
      return {
        ...state,
        [action.payload.id]: {
          name: action.payload.name,
          isDeleted: false,
        },
      };
    default:
      return state;
  }
};

registerReducer(reducer);

// selectors
export const getSelectedTemplateId = () => {
  if (getState()) {
    return getState().isSelected;
  }

  return null;
};

export const getTemplateById = (id) => {
  if (id in getState()) {
    return getState()[id];
  }

  return null;
};

export const getUIState = () => getState();

// action creators
export const selectTemplate = (payload) => store.dispatch({ type: SELECT_TEMPLATE, payload });
export const addNewTemplate = (payload) => store.dispatch({ type: ADD_NEW_TEMPLATE, payload });
