import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPostP } from '@sb-itops/redux/fetch';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbMatterHourlyRateService',
  keyPath: 'id',
});

export const saveMatterHourlyRate = ({
  matterId,
  accountId,
  userId,
  matterHourlyRateToSave,
  savedFromCreateMatter,
}) => {
  const saveMatterHourlyRateThunk = async () => {
    const matterHourlyRate = {
      matterId,
      accountId,
      userId,
      ...matterHourlyRateToSave,
      savedFromCreateMatter,
    };
    // minimal opdate entity to provide user with instant feedback on UI
    opdateCache({ optimisticEntities: [matterHourlyRate] });

    try {
      const path = `/billing/matter-hourly-rate/:accountId/matter/${matterId}`;
      const fetchOptions = { body: JSON.stringify(matterHourlyRate) };
      await fetchPostP({ path, fetchOptions });
      return matterHourlyRate;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [matterHourlyRate] });
      throw err;
    }
  };

  return store.dispatch(saveMatterHourlyRateThunk);
};
