angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/bank-reconciliation-setup';

  $stateProvider
    .state('home.billing.bank-reconciliation-setup', {
      url: '/bank-reconciliation/setup',
      templateUrl: BASE_DIRECTORY + '/bank-reconciliation-setup.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      // abstract: 'true',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation'],
        accountType: 'TRUST',
        tab: { type: 'bank-reconciliation-setup' }
      }
    })
    .state('home.billing.bank-reconciliation-setup.account', {
      url: '/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/bank-reconciliation-setup.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      // abstract: 'true',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation'],
        accountType: 'TRUST',
        tab: { type: 'bank-reconciliation-setup' }
      }
    });
});
