import { featureActive } from '@sb-itops/feature';
import { themes, setTheme } from 'web/services/theme';

// We don't want to change the user's theme as they are working in the app,
// so we only apply the new theme during bootstrap. The login page theme
// is the same regardless of theme.
export const initialiseTheme = async ({ log, defaultTeardown }) => {
  try {
    if (featureActive('BB-13329')) {
      // Default to v3 theme if the switch is enabled
      const themeName = themes.v3;

      if (themeName) {
        setTheme(themeName)

        return () => {
          setTheme(undefined);
        };
      }
    }

    return defaultTeardown;
  } catch (err) {
    // If theme initialisation fails we do not want to prevent login,
    // instead fall back to the default theme.
    log.warn('Failed to initialise theme', err);
  }
};
