import { fetchGetP } from '@sb-itops/redux/fetch';

const cache: Record<string, string> = {};

export const getIntakeUrl = async (mode: string) => {
  if (!mode) {
    throw new Error(`unknown mode ${mode}`);
  }

  if (cache[mode]) {
    return cache[mode];
  }

  const response = await fetchGetP({ path: `/integration/endpoint-locator/intake/:accountId/${mode}` });

  if (response.status === 200) {
    const result = response.body;
    const url = result.url;

    cache[mode] = url;

    return url;
  }

  throw new Error(`unexpected status: ${response.status}`);
};
