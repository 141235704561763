/* eslint-disable max-classes-per-file */

'use strict';

const { smokeballFailureCodes, smokeballFailureMessages } = require('./constants');

const chargeErrorNames = {
  ChargeError: 'ChargeError',
  ProviderApiChargeError: 'ProviderApiChargeError',
  CardAndAddressCountryMismatch: 'CardAndAddressCountryMismatch',
  InternationalCardsNotSupported: 'InternationalCardsNotSupported',
  ZeroBalanceOwingChargeError: 'ZeroBalanceOwingChargeError',
  ServerError: 'ServerError',
};

const preChargeErrorNames = {
  PreChargeError: 'PreChargeError',
};

// Please note:
// All the errors used to have name defined as `this.name = this.constructor.name;` but this was changed to hardcoded string.
// The reason is in isErrorSmokeballChargeError and isErrorSmokeballPreChargeError we test for their specific name which works in
// our EPs, but not in our front-end (works locally, but not RC/QA/PROD) as these error class names are optimalised and renamed.
// Please check is-smokeball-error.js for more info why we have isErrorSmokeballChargeError and isErrorSmokeballPreChargeError.

class ChargeError extends Error {
  constructor({ chargeId, message, details, isSmokeballError, isPostChargeFailure = false }) {
    super(message || `Failed to process charge with id - ${chargeId}`);
    this.name = chargeErrorNames.ChargeError;
    this.chargeId = chargeId;
    this.isSmokeballError = isSmokeballError;
    this.isProviderError = !isSmokeballError;
    this.details = details;
    this.isPostChargeFailure = isPostChargeFailure;
  }
}

class ProviderApiChargeError extends ChargeError {
  constructor({ chargeId, message, details, apiError }) {
    super({
      chargeId,
      message,
      details,
      isSmokeballError: false,
      isPostChargeFailure: false,
    });

    this.name = chargeErrorNames.ProviderApiChargeError;
    this.underlyingApiError = apiError;

    const messageLines = (this.message.match(/\n/g) || []).length + 1;
    this.stack = this.stack
      .split('\n')
      .slice(0, messageLines + 1)
      .join('\n');

    this.stack = `${this.stack}\n${apiError.stack}`;
  }
}

class CardAndAddressCountryMismatch extends ChargeError {
  constructor({ chargeId }) {
    const details = {
      failure: {
        code: smokeballFailureCodes.CARD_AND_ADDRESS_COUNTRY_MISMATCH,
        message: smokeballFailureMessages[smokeballFailureCodes.CARD_AND_ADDRESS_COUNTRY_MISMATCH],
        firmCanAssistWithError: false,
      },
    };

    const message = `Failed to process charge '${chargeId}' with card and address country mismatch`;

    super({ chargeId, message, details, isSmokeballError: true });
    this.name = chargeErrorNames.CardAndAddressCountryMismatch;
  }
}

class InternationalCardsNotSupported extends ChargeError {
  constructor({ chargeId }) {
    const details = {
      failure: {
        code: smokeballFailureCodes.INTERNATIONAL_CARDS_NOT_SUPPORTED,
        message: smokeballFailureMessages[smokeballFailureCodes.INTERNATIONAL_CARDS_NOT_SUPPORTED],
        firmCanAssistWithError: false,
      },
    };

    const message = `Failed to process charge '${chargeId}' with international credit card`;

    super({ chargeId, message, details, isSmokeballError: true });
    this.name = chargeErrorNames.InternationalCardsNotSupported;
  }
}

class ZeroBalanceOwingChargeError extends ChargeError {
  constructor({ chargeId, debtorId }) {
    const details = {
      failure: {
        code: smokeballFailureCodes.ZERO_BALANCE_OWING,
        message: smokeballFailureMessages[smokeballFailureCodes.ZERO_BALANCE_OWING],
        firmCanAssistWithError: false,
      },
    };

    const message = `Failed to process charge '${chargeId}' with zero owing balance for debtor '${debtorId}'`;

    super({ chargeId, message, details, isSmokeballError: true });
    this.name = chargeErrorNames.ZeroBalanceOwingChargeError;
  }
}

class ServerError extends ChargeError {
  constructor({ chargeId, message = '', isPostChargeFailure = false }) {
    const details = {
      failure: {
        code: smokeballFailureCodes.SERVER_ERROR,
        message: smokeballFailureMessages[smokeballFailureCodes.SERVER_ERROR],
        firmCanAssistWithError: true,
      },
    };

    super({ chargeId, message, details, isSmokeballError: true, isPostChargeFailure });
    this.name = chargeErrorNames.ServerError;
  }
}

class PreChargeError extends Error {
  constructor(message) {
    super(message);
    this.name = preChargeErrorNames.PreChargeError;
    this.isSmokeballError = false;
  }
}

module.exports = {
  ChargeError,
  ProviderApiChargeError,
  PreChargeError,
  ZeroBalanceOwingChargeError,
  InternationalCardsNotSupported,
  CardAndAddressCountryMismatch,
  ServerError,
  chargeErrorNames,
  preChargeErrorNames,
};
