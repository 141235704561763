import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DatePicker, forms2PropTypes } from '@sb-itops/react';

import Styles from '../../AdjustmentForm.module.scss';

export const AdjustmentDate = ({ date, hasError, onDateChange }) => (
  <div className={classNames('col-lg-6', Styles.adjustmentField)}>
    <label>DATE</label>
    <DatePicker value={date?.value ? new Date(date.value) : undefined} onSelect={onDateChange} hasError={hasError} />
  </div>
);

const { Forms2Field } = forms2PropTypes;

AdjustmentDate.displayName = 'AdjustmentDate';

AdjustmentDate.propTypes = {
  date: Forms2Field,
  onDateChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

AdjustmentDate.defaultProps = {
  date: undefined,
};
