import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CurrencyInputToggled2, forms2PropTypes } from '@sb-itops/react';

import Styles from '../../AdjustmentForm.module.scss';

export const AdjustmentAmount = ({ amount, hasError, onAmountChange }) => (
  <div className={classNames('col-lg-6', Styles.adjustmentField)}>
    <label>AMOUNT</label>
    <CurrencyInputToggled2
      value={amount ? amount.value : undefined}
      onChange={onAmountChange}
      min={0}
      hasError={hasError}
    />
  </div>
);

const { Forms2Field } = forms2PropTypes;

AdjustmentAmount.displayName = 'AdjustmentAmount';

AdjustmentAmount.propTypes = {
  amount: Forms2Field,
  onAmountChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

AdjustmentAmount.defaultProps = {
  amount: undefined,
};
