import { fetchPostP } from '@sb-itops/redux/fetch';
import { getById as getSubscriptionByAccountId, opdateCache as opdateSubscription } from './index';

export const setDefaultPaymentMethod = async ({ accountId, setupIntentId }) => {
  if (!accountId) {
    throw new Error('accountId must be a non-empty string');
  }

  if (!setupIntentId) {
    throw new Error('setupIntentId must be a non-empty string');
  }

  // @ts-ignore
  const response = await fetchPostP({
    path: `/finance/stripe/set-default-payment-method/:accountId/for/${setupIntentId}`,
  });

  const { defaultPaymentMethod } = response;
  const subscription = getSubscriptionByAccountId(accountId);
  const subscriptionForOpdate = { ...subscription };

  subscriptionForOpdate.externalPaymentMethodIds = [defaultPaymentMethod];
  opdateSubscription({ optimisticEntities: [subscriptionForOpdate] });

  return response;
};
