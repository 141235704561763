import * as Yup from 'yup';
import { PrintManually } from '@sb-billing/business-logic/cheques';

// Context which should be passed:
// isMatterContactBalance
// supportsReasonField
// showBankDetails
// showBpay
// supportsElectronicPayment
// supportsTrustPaymentBankTransferType
// isCMAElectronicPaymentEnabled
// canOverdrawBalance
// availableBalance
// hasProtectedFunds
// trustChequesIsEnabled
// lastChequeNumber
// chequeNumberIsUsed
// dateIsReconciled
// t

export const VendorPaymentEntrySplitPayorsSchema = Yup.object().shape({
  bankAccountId: Yup.string().required(),
  matterId: Yup.string().required(),
  paidToId: Yup.string().required(),
  effectiveDate: Yup.string().test('effectiveDate', 'effectiveDate', (effectiveDate, { createError, options }) => {
    const { dateIsReconciled } = options.context || {};

    if (!effectiveDate) {
      return false;
    }

    if (effectiveDate && dateIsReconciled) {
      return createError({ message: 'Warning: the date selected has already been reconciled' });
    }

    return true;
  }),
  amount: Yup.string().test('amount', 'amount', (_amount, { createError, options, parent }) => {
    const { canOverdrawBalance, isMatterContactBalance, availableBalance, hasProtectedFunds } = options.context || {};
    const paymentSource = parent.paymentSource; // fieldValues.paymentSource

    const amount = Math.abs(Math.trunc(_amount));
    const amountNotValid = !amount || amount < 0;

    if (!canOverdrawBalance) {
      const overdrawsAvailableBalance = isMatterContactBalance
        ? amount > (paymentSource?.balance || 0)
        : amount > availableBalance;

      if (amountNotValid || overdrawsAvailableBalance) {
        if (!hasProtectedFunds && overdrawsAvailableBalance) {
          return createError({ message: 'Amount exceeds available balance' }); // overdrawsAvailableBalance
        }

        return false;
      }
    }

    if (canOverdrawBalance && (amountNotValid || Number.isNaN(amount))) {
      return false;
    }

    return true;
  }),
  reference: Yup.string().test('reference', 'reference', (reference, { createError, options, parent }) => {
    const { isCMAElectronicPaymentEnabled, trustChequesIsEnabled, chequeNumberIsUsed, lastChequeNumber, t } =
      options.context || {};

    const bankAccountType = parent.bankAccountType; // fieldValues.bankAccountType

    if (bankAccountType === 'CONTROLLEDMONEY') {
      // if auto numbering is not enabled for CMA, reference is required for both Bank Transfer and Bank Cheque
      const referenceRequired = !isCMAElectronicPaymentEnabled;
      if (referenceRequired && !reference) {
        return createError({ message: 'Reference is required' }); // ref empty
      }
    }

    if (bankAccountType === 'TRUST' && trustChequesIsEnabled) {
      const sourceType = parent.sourceType; // fieldValues.sourceType
      const printingMethod = parent.printingMethod; // fieldValues.printingMethod

      const referenceRequired = sourceType?.endsWith('Check') && printingMethod === PrintManually;
      const referenceEmpty = !reference;

      if (referenceRequired && referenceEmpty) {
        return createError({ message: 'Reference is required' }); // ref empty
      }

      if (referenceRequired && !referenceEmpty && !Number.isFinite(+reference)) {
        return createError({ message: `Warning: ${t('capitalize', { val: 'cheque' })} reference must be numeric` }); // ref number
      }

      if (!referenceEmpty && chequeNumberIsUsed) {
        return createError({
          message: `Warning: ${t('capitalize', { val: 'cheque' })} reference is already in use. Last ${t(
            'cheque',
          )} reference printed was ${lastChequeNumber}`,
        });
      }
    }

    return true;
  }),
  bankAccountName: Yup.string().test('bankAccountName', 'bankAccountName', (bankAccountName, { options }) => {
    const { showBankDetails, showBpay } = options.context || {};
    if (showBankDetails && !showBpay) {
      return !!bankAccountName;
    }
    return true;
  }),
  bankBranchNumber: Yup.string().test('bankBranchNumber', 'bankAccountName', (bankBranchNumber, { options }) => {
    const { showBankDetails, showBpay } = options.context || {};
    if (showBankDetails && !showBpay) {
      return !!bankBranchNumber;
    }
    return true;
  }),
  bankAccountNumber: Yup.string().test('bankAccountNumber', 'bankAccountName', (bankAccountNumber, { options }) => {
    const { showBankDetails, showBpay } = options.context || {};
    if (showBankDetails && !showBpay) {
      return !!bankAccountNumber;
    }
    return true;
  }),
  bankName: Yup.string().test('bankName', 'bankName', (bankName, { options, parent }) => {
    const { supportsElectronicPayment } = options.context || {};

    const sourceType = parent.sourceType; // fieldValues.sourceType
    const chequePayableToBank = parent.chequePayableToBank; // fieldValues.chequePayableToBank

    if (supportsElectronicPayment && sourceType?.endsWith('Check') && chequePayableToBank) {
      return !!bankName;
    }

    return true;
  }),
  bankBranch: Yup.string().test('bankBranch', 'bankBranch', (bankBranch, { options, parent }) => {
    const { supportsElectronicPayment } = options.context || {};

    const sourceType = parent.sourceType; // fieldValues.sourceType
    const chequePayableToBank = parent.chequePayableToBank; // fieldValues.chequePayableToBank

    if (supportsElectronicPayment && sourceType?.endsWith('Check') && chequePayableToBank) {
      return !!bankBranch;
    }

    return true;
  }),
  payeeName: Yup.string().test('payeeName', 'payeeName', (payeeName, { options, parent }) => {
    const { supportsElectronicPayment } = options.context || {};

    const sourceType = parent.sourceType; // fieldValues.sourceType
    const chequePayableToBank = parent.chequePayableToBank; // fieldValues.chequePayableToBank

    if (supportsElectronicPayment && sourceType?.endsWith('Check') && chequePayableToBank) {
      return !!payeeName;
    }

    return true;
  }),
  transferType: Yup.string().test('transferType', 'transferType', (transferType, { options, parent }) => {
    const { supportsTrustPaymentBankTransferType } = options.context || {};

    const sourceType = parent.sourceType; // fieldValues.sourceType
    const bankAccountType = parent.bankAccountType; // fieldValues.bankAccountType

    if (supportsTrustPaymentBankTransferType && sourceType?.endsWith('Bank Transfer') && bankAccountType === 'TRUST') {
      return !!transferType;
    }

    return true;
  }),
  orgCompanyName: Yup.string().test('orgCompanyName', 'orgCompanyName', (orgCompanyName, { options }) => {
    const { showBankDetails, showBpay } = options.context || {};
    if (showBankDetails && showBpay) {
      return !!orgCompanyName;
    }
    return true;
  }),
  billerCode: Yup.string().test('billerCode', 'billerCode', (billerCode, { createError, options }) => {
    const { showBankDetails, showBpay } = options.context || {};

    if (showBankDetails && showBpay && !/^[0-9]{3,10}$/.test(billerCode)) {
      return createError({ message: 'Biller code must be 3 to 10 numeric digits' });
    }

    return true;
  }),
  bpayReference: Yup.string().test('bpayReference', 'bpayReference', (bpayReference, { createError, options }) => {
    const { showBankDetails, showBpay } = options.context || {};

    if (showBankDetails && showBpay && !/^[0-9]{2,20}$/.test(bpayReference)) {
      return createError({ message: 'BPAY Reference must be 2 to 20 numeric digits' });
    }

    return true;
  }),
  reason: Yup.string().when('$supportsReasonField', {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().optional(),
  }),
});
