import { dateToInteger } from '@sb-itops/date';

import { SELECT_DATE, SELECT_STAFF_ID } from './types';

const initialState = null;

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SELECT_DATE: {
      return {
        ...state,
        selectedDate: dateToInteger(action.payload.date), // required due to immu
      };
    }
    case SELECT_STAFF_ID: {
      return {
        ...state,
        selectedStaffId: action.payload.staffId,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
