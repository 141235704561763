'use strict';

const moment = require('moment');
const { paymentPlanPositionDisplayStatus } = require('../entities').constants;

const calculatePaymentPlanPositionStatus = ({ behindOnDate, onTrackOnDate, paymentPlanStatusAsOfDate }) => {
  // behindOnDate and onTrackOnDate can be a bit hard to understand, here's how they are set in .Net at the time of writing this
  // https://bitbucket.org/smokeballdev/billing/src/5d8d24b1c35479bff32899868bd03e4928a219c4/ui/debtors/WebQueryHandlers/Handlers/PaymentPlanUpdatedHandler.cs#lines-100

  const paymentPlanStatusAsOfDateString = `${paymentPlanStatusAsOfDate}`;
  const isPaymentPlanStatusAsOfDateValid =
    paymentPlanStatusAsOfDateString &&
    paymentPlanStatusAsOfDateString.length === 8 &&
    moment(paymentPlanStatusAsOfDateString).isValid();

  if (isPaymentPlanStatusAsOfDateValid) {
    if (behindOnDate && paymentPlanStatusAsOfDate >= behindOnDate) {
      return paymentPlanPositionDisplayStatus.BEHIND;
    }
    if (onTrackOnDate && paymentPlanStatusAsOfDate >= onTrackOnDate) {
      return paymentPlanPositionDisplayStatus.ON_TRACK;
    }
    return paymentPlanPositionDisplayStatus.AHEAD;
  }
  return null;
};

module.exports = {
  calculatePaymentPlanPositionStatus,
};
