angular.module('sb.billing.webapp').service('sbUiScrollDsService', function() {
  'use strict';

  this.getDatasource = function () {
    return {
      data: [],

      update: function(newData) {
        this.data = newData;
      },

      size: function() {
        return _.size(this.data);
      },

      get: function (index, count, success) {
        var that = this;

        if(!_.isEmpty(that.data)) {
            index--; //index is not ZERO based so make it so
            var start = index < 0 ? 0 : index;
            var end = index + count;
          var ds = _.slice(that.data, start, end);

            success(ds);
        } else {
          success([]);
          }
      }
    };
  };

});
