import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoginForm } from './LoginForm';

function isValidEmail(email) {
  // Using the Angular 1.5 email regex for backwards-compatibility
  // as it is more permissive than the itops/modules/email/validate.js
  // `validateSingle` implementation
  // Source: https://github.com/angular/angular.js/blob/v1.5.x/src/ng/directive/input.js#L27

  const emailRegex =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

  return emailRegex.test(email);
}

const defaultPrivateComputerTooltip =
  'Check to save all data on private computers when logging out. This will help speed up log in time.';

function validateForm({ username, password }) {
  const errors = [];

  if (!username || username.trim() === '') {
    errors.push({ message: 'Please enter your email address', isVerbose: false });
  } else if (!isValidEmail(username)) {
    errors.push({ message: 'Email address is invalid', isVerbose: false });
  }

  if (!password || password.trim() === '') {
    errors.push({ message: 'Please enter your password', isVerbose: false });
  }

  return errors;
}

export const LoginFormContainer = (props) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [loginAttempted, setLoginAttempted] = useState(false);

  // If the username is provided via props, it means it is a previously logged-in user
  const disableUserField = !!props.username;
  const enableUserSwitching = !!props.username;

  const defaultFormValues = {
    username: props.username,
    password: '',
    isPrivateComputer: props.isPrivateComputer,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const onFieldValueUpdated = (field, value) => {
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);

    // If login has already been attempted once, update the error messages on every form change.
    if (loginAttempted) {
      const errors = validateForm(newFormValues);
      setErrorMessages(errors);
    }
  };

  const onSwitchUser = () => {
    setFormValues({ ...defaultFormValues, username: '' });
    props.onSwitchUser();
  };

  const onValidateAndLogin = ({ username, password, isPrivateComputer }) => {
    // Setting this flag causes validation to start getting updated on every form update event.
    setLoginAttempted(true);

    // Set form values - form may have been pre-filled by a password manager
    // bypassing the onChange event
    setFormValues({ ...formValues, username, password, isPrivateComputer });

    const errors = validateForm(formValues);
    setErrorMessages(errors); // This happens outside the below if statement to ensure errors are cleared during valid login.

    if (errors.length > 0) {
      return;
    }

    props.onLogin({ username, password, isPrivateComputer });
  };

  return (
    <LoginForm
      username={formValues.username}
      password={formValues.password}
      isPrivateComputer={formValues.isPrivateComputer}
      privateComputerTooltip={props.privateComputerTooltip}
      onFieldValueUpdated={onFieldValueUpdated}
      errorMessages={errorMessages.length ? errorMessages : props.errorMessages}
      disableUserField={disableUserField}
      enableUserSwitching={enableUserSwitching}
      formDisabled={props.formDisabled}
      loginInProgress={props.loginInProgress}
      forgotPasswordUrl={props.forgotPasswordUrl}
      showUpdatePaymentMethod={props.showUpdatePaymentMethod}
      userSwitchInProgress={props.userSwitchInProgress}
      onLogin={onValidateAndLogin}
      onSwitchUser={onSwitchUser}
      onUpdatePaymentMethod={props.onUpdatePaymentMethod}
    />
  );
};

LoginFormContainer.displayName = 'LoginFormContainer';

LoginFormContainer.propTypes = {
  username: PropTypes.string,
  isPrivateComputer: PropTypes.bool,
  privateComputerTooltip: PropTypes.string,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      isVerbose: PropTypes.bool,
    }),
  ),
  formDisabled: PropTypes.bool,
  loginInProgress: PropTypes.bool,
  forgotPasswordUrl: PropTypes.string.isRequired,
  showUpdatePaymentMethod: PropTypes.bool.isRequired,
  userSwitchInProgress: PropTypes.bool,
  onSwitchUser: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onUpdatePaymentMethod: PropTypes.func,
};

LoginFormContainer.defaultProps = {
  username: '',
  isPrivateComputer: false,
  privateComputerTooltip: defaultPrivateComputerTooltip,
  formDisabled: false,
  loginInProgress: false,
  userSwitchInProgress: false,
  errorMessages: undefined,
  onUpdatePaymentMethod: () => {},
};
