import { gql } from '@apollo/client';

const query = gql`
  query MatterBalanceTrustAsOfDate($matterId: ID!, $balanceAsOfDate: Int) {
    matterBalanceTrust(id: $matterId, balanceAsOfDate: $balanceAsOfDate) {
      matterId
      # adjustment
      availableBalance
      balance
      # isOverdrawn
      protectedBalance
      # unpresentedChequesBalance
      bankAccountId
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterBalanceTrustAsOfDate = {
  query,
  notificationIds,
};
