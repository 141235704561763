import { sort } from '@sb-itops/sort';
import { allocators } from '@sb-billing/allocation';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';

const { targetsFromSources: allocateTargetsFromSources } = allocators;

/**
 * Allocate invoice payments for specified matter from trust
 * @param {Matter} matter - { matterId, matterTrustBalance, invoices }
 * @returns a map with key: invoiceId, value: amount in cents
 */
function autoAllocateInvoicePaymentsForMatter({ matterId, matterTrustBalance, invoices }) {
  // invoices are assumed to be sorted already
  if (!invoices || !invoices.length) {
    return {};
  }

  const allocations = {};
  allocateTargetsFromSources({
    targets: invoices.map((invoice) => ({
      targetId: invoice.invoiceId,
      amount: invoice.totalDue || 0,
    })),
    sources: [{ sourceId: matterId, amount: matterTrustBalance }],
    zeroAllocate: true,
    accumulate: (allocation) => {
      // allocation has the following shape
      // {
      //   sourceId,
      //   remainingOnTarget,
      //   remainingOnSource,
      //   targetId,
      //   amount: // amount to allocate from source
      // };
      const invoiceId = allocation.targetId;
      allocations[invoiceId] = allocation.amount;
    },
  });

  return allocations;
}

/**
 * Auto allocate invoice payments based on user selection and other screen settings
 * @returns a map with key: invoiceId, value: amount in cents
 */
function autoAllocateInvoicePayments({
  invoices,
  selectedInvoiceIdsMap,
  autoAllocationsMap, // map of matter ids
  matterBalancesMap,
  paymentsMap,
}) {
  // build invoices map for quick lookup
  const invoicesMap = invoices.reduce((acc, invoice) => {
    acc[invoice.invoiceId] = invoice;
    return acc;
  }, {});

  // group invoices by matter
  const matterIdToInvoices = invoices.reduce((matterMap, invoice) => {
    if (matterMap[invoice.matterId]) {
      matterMap[invoice.matterId].push(invoice);
    } else {
      // eslint-disable-next-line no-param-reassign
      matterMap[invoice.matterId] = [invoice];
    }
    return matterMap;
  }, {});

  // for each matter, auto allocate payments for selected invoice if auto allocate toggle is on
  const autoAllocatedPayments = Object.keys(matterIdToInvoices).reduce((autoPayments, matterId) => {
    const matterBalance = matterBalancesMap[matterId];

    // matters are assumed to be auto-allocated by default, so undefined signifies the matter should be auto allocated
    const isAutoAllocated = autoAllocationsMap[matterId] === undefined || autoAllocationsMap[matterId];

    if (!isAutoAllocated || !matterBalance) {
      return autoPayments;
    }

    // auto allocate only selected invoices, make sure they are sorted by ascending
    // issue date as oldest issued invoice should be allocated and paid first
    const sortedInvoices = sort(matterIdToInvoices[matterId], ['issuedDate', 'validFrom']).reduce((acc, invoice) => {
      const invoiceId = invoice.invoiceId;
      if (selectedInvoiceIdsMap[invoiceId]) {
        acc.push({
          invoiceId,
          totalDue: invoicesMap[invoiceId].totalDue,
        });
      }
      return acc;
    }, []);

    // allocate invoice payments
    const newAllocations = autoAllocateInvoicePaymentsForMatter({
      matterId,
      matterTrustBalance: matterBalance[balanceTypes.AVAILABLE],
      invoices: sortedInvoices,
    });

    // using a loop to accumulate allocations as spread operator
    // has performance issues when dealing with a lot of invoices and matters
    Object.entries(newAllocations).forEach(([invoiceId, amount]) => {
      // eslint-disable-next-line no-param-reassign
      autoPayments[invoiceId] = amount;
    });

    return autoPayments;
  }, {});

  return {
    ...paymentsMap,
    ...autoAllocatedPayments,
  };
}

export { autoAllocateInvoicePayments, autoAllocateInvoicePaymentsForMatter };
