import * as Yup from 'yup';
import { validateMultiple as isValidEmailList } from '@sb-itops/email';
import { minimumSurchargeValues, surchargeTypeValues } from '@sb-billing/business-logic/invoice-surcharge';
import { billingUnitOptionValues } from '@sb-billing/business-logic/matters/billing-config';
import { matterFieldsRegionStrategy } from './matter-fields-region-strategy';

export const trustRetainerReplenishAmountErrorMessage =
  'Replenish amount must be greater than or equal to the threshold amount.';

const minFixedSurchargeValueInDollars = minimumSurchargeValues.FIXED / 100;

export const createAddEditMatterSchema = () =>
  Yup.object().shape({
    // matter details
    matterTypeId: Yup.string().required(),
    matterNumber: matterFieldsRegionStrategy.matterNumberRequired ? Yup.string().required() : Yup.string().nullable(),
    status: Yup.string().required(),
    description: matterFieldsRegionStrategy.matterDescriptionRequired
      ? Yup.string().required()
      : Yup.string().nullable(),
    clientRole: Yup.string().required(),

    // matter staff
    attorneyResponsibleId: Yup.string().nullable(), // this field cannot be required in practice as there are existing records with null values

    // matter contacts
    // not used here as the ContactMultiSelect component must handle the scenario
    // where the contact is cleared, in this scenario we need to have a placeholder
    // for the cleared contact so user can select another contact as the next step
    // these are handled as custom validations instead

    // billing fees and rates
    contingencyAmount: Yup.number().when('billingType', {
      is: (billingType) => billingType === 'Contingency (%)',
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().nullable(),
    }),
    amount: Yup.number().when('billingType', {
      is: (billingType) => !['Contingency (%)', '', 'Not Billable', null, undefined].includes(billingType),
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().nullable(),
    }),
    // houly rate in fees and rates section not used here as this cannot easily handle a special scenario where
    // 1) selected "Override rate for all staff"
    // 2) cleared rate for all staff
    // 3) selected "Do not override rate"
    // in this scenario we needed a yup validation that allows the empty rate field
    // which appeared simple at first https://github.com/jquense/yup/issues/298#issuecomment-559017330
    customUnit: Yup.number().when('billingUnitOption', {
      is: (billingUnitOption) => billingUnitOption === billingUnitOptionValues.CUSTOM,
      then: Yup.number().integer().min(1).max(60).notRequired(),
      otherwise: Yup.number().nullable().notRequired(),
    }),

    // trust retainer
    minimumTrustRetainerAmount: Yup.number().nullable().when('minimumTrustRetainerActive', {
      is: true,
      then: Yup.number().positive().required(),
    }),
    trustRetainerReplenishAmount: Yup.number().nullable().when('minimumTrustRetainerActive', {
      is: true,
      then: Yup.number().positive().required(),
    }),

    // late payment - interest settings rendering schema
    interestSettings: Yup.object().shape({
      hasGracePeriod: Yup.boolean(),
      hasInterestRate: Yup.boolean(),
      interestApplyMode: Yup.mixed().oneOf(['AfterDueDate', 'AfterGracePeriod']),
      applyInterestToUnpaidInvoices: Yup.boolean(),
      ratePa: Yup.number().integer().when('hasInterestRate', {
        is: true,
        then: Yup.number().required().positive(),
      }),
      gracePeriodDays: Yup.number().integer().when('hasGracePeriod', {
        is: true,
        then: Yup.number().required().positive(),
      }),
    }),

    // utbms settings
    ledesFirmId: Yup.string().nullable().when('isUtbmsEnabled', {
      is: true,
      then: Yup.string().required(),
    }),
    ledesMatterId: Yup.string().nullable().when('isUtbmsEnabled', {
      is: true,
      then: Yup.string().required(),
    }),

    // email settings
    ccAddresses: Yup.string().test(
      'is-email-list',
      // ${path} is interpolated by YUP
      // eslint-disable-next-line no-template-curly-in-string
      '${path} is not a valid email list',
      (value) => isValidEmailList(value) || value === '',
    ),
    bccAddresses: Yup.string().test(
      'is-email-list',
      // ${path} is interpolated by YUP
      // eslint-disable-next-line no-template-curly-in-string
      '${path} is not a valid email list',
      (value) => isValidEmailList(value) || value === '',
    ),

    // Surcharge
    surchargeAmount: Yup.number().when(['surchargeOverridden', 'surchargeType'], {
      is: (surchargeOverridden, surchargeType) => surchargeOverridden && surchargeType === surchargeTypeValues.FIXED,
      then: Yup.number().min(minFixedSurchargeValueInDollars).required(),
    }),
    surchargePercentage: Yup.number().when(['surchargeOverridden', 'surchargeType'], {
      is: (surchargeOverridden, surchargeType) =>
        surchargeOverridden && surchargeType === surchargeTypeValues.PERCENTAGE,
      then: Yup.number().min(minimumSurchargeValues.PERCENTAGE_BP).required(),
    }),
  });
