import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';

export const TrustTooltipSummary = memo(({ totals }) => {
  const { t } = useTranslation();
  return (
    <div className="left-position" onClick={(e) => e.stopPropagation()}>
      <div className="column">
        <div>Protected Funds:</div>
        <div>Available Funds:</div>
        <div>Total Funds:</div>
      </div>
      <div className="column money-column">
        <div>{t('cents', { val: totals.protected })}</div>
        <div>{t('cents', { val: totals.available })}</div>
        <div>{t('cents', { val: totals.total })}</div>
      </div>
      <i />
    </div>
  );
});

TrustTooltipSummary.displayName = 'TrustTooltipSummary';

TrustTooltipSummary.propTypes = {
  totals: PropTypes.object.isRequired,
};
