import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getList,
  getById
} from '@sb-billing/redux/activities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

angular
  .module('@sb-billing/services')
  .service('sbBillingActivityService', function(
    sbLoggerService,
    sbGenericCacheService,
    sbUuidService,
    sbGenericEndpointService,
    sbEndpointType,
    $rootScope
  ) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbBillingActivityService');
    const activityEndpoint = 'billing/activity';

    sbGenericCacheService.setupCache({
      name: 'sbBillingActivityService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/activities' },
        poll: 60,
        subscriptions: 'notifier.BillingActivitiesNotifications'
      },
      updateRedux,
      clearRedux,
    });

    that.activityTypes = { Fee: 0, Time: 1, Expense: 2 }; // matches .net code for enumeration
    that.activityTypesInverted = _.invert(that.activityTypes);

    that.saveP = saveP;
    that.deleteActivityP = deleteActivityP;
    that.getAllActivites = getList;
    that.getActivities = getActivities;
    that.getCodes = getCodes;
    that.getById = getById;

    function getActivities() {
      return getList().filter(function(v) {
        return !v.isDeleted;
      });
    }

    function getCodes() {
      return _.pluck(getActivities(), 'code');
    }
    
    async function saveP(activity) {
      if (!activity) {
        return;
      }

      try {
        activity.activityId = activity.activityId || sbUuidService.get();
        activity.versionId = sbUuidService.get();
        log.info('save activity', activity);

        await dispatchCommand({ type: 'Billing.Activities.Messages.SaveActivity', message: activity });
        $rootScope.$broadcast('opdate-posted', { sbBillingActivityService: [activity] });

      } catch (err) {
        log.error('Failed to save activity', err);
      }
    }

    function deleteActivityP(activityId) {
      const activity = getById(activityId);
      if (activity) {
        log.info('delete activity', activity);
        activity.isDeleted = true;
        return sbGenericEndpointService.deletePayloadP(activityEndpoint, ['activity', activity.activityId], activity, {
          changeset: { sbBillingActivityService: [activity] }
        });
      } else {
        log.warn("sbBillingActivityService can't delete activityId", activityId);
      }
    }
  });
