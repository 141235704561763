import { isEmpty } from 'lodash';

/**
 * Theoretically makes it easier for users to send a message to the message display service.
 * Provides a fluid interface which abstracts away the MessageDisplayService options object.
 *
 * @constructor
 */
export default function MessageBuilder() {
  const that = this;
  const options = {
    msgText: '',
  };

  that.text = text;
  that.conditionalText = conditionalText;
  that.getOptions = getOptions;

  // Imbue MessageBuilder with methods for settings simple options.
  // Simple options are in the form options[key] = val;
  const messageBuilderMethods = ['title', 'group', 'timeout', 'custom', 'action', 'actionText'];
  messageBuilderMethods.forEach((optionName) => {
    that[optionName] = (val) => {
      options[optionName] = val;
      return this;
    };
  });

  /**
   * Appends some text to the message which will be displayed by the message display service.
   *
   * @param format The string which will be interpolated with the passed values
   * @param {...*} [values] Values to be interpolated into the format string
   * @returns {MessageBuilder} For chaining
   */
  function text(...args) {
    options.msgText += formatString(...args);
    return that;
  }

  /**
   * Does the same as MessageBuilder.prototype.text with the exception that if any of the values
   * for interpolation resolve to _.isEmpty === true, then no change to the underlying text is made.
   * @returns {MessageBuilder}
   */
  function conditionalText(...args) {
    const formatStrings = args.shift();

    // Check all of the values to be interpolated to make sure they are non empty.
    const conditionPassed = args.every((valueToInterpolate) => !isEmpty(valueToInterpolate));

    if (conditionPassed) {
      args.unshift(formatStrings);
      text.apply(that, args);
    }

    return that;
  }

  /**
   * Returns an options object which can be used within the message display service.
   */
  function getOptions() {
    return options;
  }
}

/**
 * Since we are not yet on ES6 and have no built in String.Format
 * and don't want to introduce a new lib for a few lines of code,
 * we use a custom string formatter in this service. Temporarily hopefully.
 *
 * Example usage:
 * formatString("{0} is dead, but {1} is alive! {0} {2}", "Java", "JavaScript");
 * returns "Java is dead, but JavaScript is alive! Java {2}"
 *
 * Shamelessly ripped off from http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
 */
function formatString(format, ...args) {
  return format.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
}
