import { isEmpty, get } from 'lodash';
import { sort as sortItems } from '@sb-itops/sort';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  getAttorneyResponsibleFilter,
  getStatusFilter,
  getMatterTypeFilter,
  getDebtorFilter
} from '@sb-matter-management/business-logic/matters/services';

import { hasBillingAccess } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').component('sbMatterList', {
  templateUrl: 'ng-components/matter-list/matter-list.html',
  bindings: { onSelect: '&', filters: '<', matters: '<', isSearchResult: '<'},
  controller: function (sbUnsavedChangesService, sbLinkService) {
    const that = this;

    let displayMode;
    let initialised = false;

    that.allowTrustOverdraw = hasFacet(facets.allowOverdraw);
    that.activeFilters = {
      matterStatus: 'Open',
      matterType: [],
      attorneyResponsible: []
    };

    that.onClickLink = sbLinkService.onClickLink;

    that.$onInit = () => {
      if (that.filters) {
        that.activeFilters = that.filters;
        if (!isEmpty(that.activeFilters.debtor)) {
          displayMode = 'debtor';
        }
      }
      else if (that.isSearchResult) {
        displayMode = 'searchResult';
      }

      const { sortState = {} } = sbUnsavedChangesService.loadMemory(`sbMatterList-${displayMode}-filters`) || {};

      that.viewState = {
        matters: [],
        sort,
        showStatus: true,
        sortBy: sortState.sortBy || 'clientDisplay',
        sortDirection: sortState.sortDirection || 'asc',
        showFinancials: hasBillingAccess() && that.displayMode !== 'debtor',
        hideDescription: false,
        hideClient: that.displayMode == 'debtor',
      };

      filterMatters(that.matters);
      initialised = true;
    };

    that.$onChanges = () => {
      if (initialised) {
        filterMatters(that.matters);
      }
    };

    // save filters when controller goes away
    that.$onDestroy = () => {
      sbUnsavedChangesService.saveMemory(`sbMatterList-${displayMode}-filters`, {
        sortState: {
          sortBy: that.viewState.sortBy,
          sortDirection: that.viewState.sortDirection,
        },
      });
    };

    function filterMatters(matters = []) {
      const filters = [];

      if (that.activeFilters.matterStatus !== 'All' && !that.isSearchResult){
        filters.push(getStatusFilter([that.activeFilters.matterStatus]));
      }

      if (that.activeFilters.matterType && that.activeFilters.matterType.length) {
        filters.push(getMatterTypeFilter(that.activeFilters.matterType));
      }

      if (that.activeFilters.attorneyResponsible && that.activeFilters.attorneyResponsible.length) {
        filters.push(getAttorneyResponsibleFilter(that.activeFilters.attorneyResponsible));
      }

      if (that.activeFilters.debtor) {
        filters.push(getDebtorFilter([that.activeFilters.debtor]));
      }

      const filteredMatters = filters.length ? matters.filter((matter) => filters.some((filter) => filter(matter))) : matters;

      that.viewState.matters = filteredMatters;
      sort(that.viewState);
    }

    function sort({ sortDirection, sortBy }) {
      const newDirection = sortDirection.toLowerCase();
      const sortDirections = [newDirection];
      const sortValues = [];

      // Add secondary sort fields for unbilled and unpaid.
      if (['unbilled', 'unpaidExcInterest'].includes(sortBy)) {
        sortValues.push((matter) => get(matter.totals, sortBy, 0), 'clientDisplay', 'matterNumber');
        sortDirections.push('asc', 'asc');
      } else {
        sortValues.push(sortBy);
      }

      that.viewState = {
        ...that.viewState,
        sortBy,
        sortDirection: newDirection,
        matters: sortItems(that.viewState.matters, sortValues, sortDirections),
      };
    }

  }
});
