import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import * as Styles from './Tooltip.module.scss';

export const CreditTooltipSummary = ({ creditContactBalances }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.content} onClick={(e) => e.stopPropagation()}>
      <div>
        {creditContactBalances.map((contactBalance) => (
          <div key={contactBalance.contactId}>{contactBalance.contactName}</div>
        ))}
      </div>
      <div className={Styles.moneyColumn}>
        {creditContactBalances.map((contactBalance) => (
          <div key={contactBalance.contactId}>{t('cents', { val: contactBalance.availableBalance })}</div>
        ))}
      </div>
    </div>
  );
};

CreditTooltipSummary.displayName = 'CreditTooltipSummary';

CreditTooltipSummary.propTypes = {
  creditContactBalances: PropTypes.array.isRequired,
};
