import { filterTrustAccounts, filterTrustAccountsByContact, filterTrustAccountsByMatter } from "web/redux/selectors/filter-trust-accounts";

angular.module('sb.billing.webapp').component('sbDataTrustAccounts', {
  require: { composer: '^sbCompose' },
  bindings: { contactId: '<?', matterId: '<?', composeKey: '<' },
  controller: function ($scope, sbLoggerService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataTrustAccounts');


    ctrl.$onInit = () => {
      log.info({
        composeKey: ctrl.composeKey,
        contactId: ctrl.contactId,
        matterId: ctrl.matterId,
      });
      updateTrustAccounts();
    };

    ctrl.$onChanges = () => {
      updateTrustAccounts();
    };

    
    const unregister = $scope.$on('smokeball-data-update-sbBankAccountService', () => {
      log.info('saw data update');
      updateTrustAccounts();
    });

    ctrl.$onDestroy = () => unregister();

    function updateTrustAccounts() {
      let data;
      if (ctrl.contactId) {
        data = filterTrustAccountsByContact(ctrl.contactId);
      } else if (ctrl.matterId) {
        data = filterTrustAccountsByMatter(ctrl.matterId);
      } else {
        data = filterTrustAccounts();
      }
      log.info(data);
      ctrl.composer.setComposeData(data, ctrl.composeKey);
    }
  }
});
