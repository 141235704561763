'use strict';

const { getTypeAsMaps } = require('@sb-itops/enum-helpers');

/**
 * @typedef {object} operationTypes
 * @property {number} UNKNOWN 0
 * @property {number} INVOICE 1
 * @property {number} RETAINER_REQUEST 2
 * @property {number} TRUST_STATEMENT 3
 * @property {number} CREDIT_CARD_DEPOSIT_REQUEST 4
 * @property {number} CREDIT_CARD_DETAILS_REQUEST 5
 * @property {number} BANK_ACCOUNT_DETAILS_UPDATED 6
 * @property {number} INVOICE_REMINDER 7
 * @property {number} USER_DETAILS_UPDATED 8
 * @property {number} FEEWISE_ACCOUNT_APPROVED 9
 * @property {number} FEEWISE_REFUND_REQUESTED 10
 */
const operationTypes = Object.freeze({
  UNKNOWN: 0,
  INVOICE: 1,
  RETAINER_REQUEST: 2,
  TRUST_STATEMENT: 3,
  CREDIT_CARD_DEPOSIT_REQUEST: 4,
  CREDIT_CARD_DETAILS_REQUEST: 5,
  BANK_ACCOUNT_DETAILS_UPDATED: 6,
  INVOICE_REMINDER: 7,
  USER_DETAILS_UPDATED: 8,
  FEEWISE_ACCOUNT_APPROVED: 9,
  FEEWISE_REFUND_REQUESTED: 10,
  INVOICE_STATEMENT: 11,
});

const operationTypeLabels = Object.freeze({
  UNKNOWN: 'Unknown',
  INVOICE: 'Invoice',
  CREDIT_CARD_DEPOSIT_REQUEST: 'Deposit Request',
  INVOICE_REMINDER: 'Reminder',
  RETAINER_REQUEST: 'Retainer Request',
  TRUST_STATEMENT: 'Trust Statement',
  CREDIT_CARD_DETAILS_REQUEST: 'Credit Card Detail Request',
  BANK_ACCOUNT_DETAILS_UPDATED: 'Bank Account Detail Updated',
  USER_DETAILS_UPDATED: 'User Details Updated',
  FEEWISE_ACCOUNT_APPROVED: 'FeeWise Account Approved',
  FEEWISE_REFUND_REQUESTED: 'FeeWise Refund Requested',
  INVOICE_STATEMENT: 'Invoice Statement',
});

const [operationTypeMap, operationTypeLabelToValueMap] = getTypeAsMaps({
  typeLabels: operationTypeLabels,
  typeValues: operationTypes,
});

module.exports = { operationTypes, operationTypeLabels, operationTypeMap, operationTypeLabelToValueMap };
