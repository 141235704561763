angular.module('sb.billing.webapp').component('sbNavTabs', {
  'templateUrl': 'ng-components/nav-tabs/nav-tabs.html',
  'controller': function (sbTabService) {
    'use strict';

    const ctrl = this;

    ctrl.$onInit = function () {
      sbTabService.onUpdate(initTabs);
      // restore tabs from sbTabService
      initTabs(sbTabService.getTabs());
    };

    function initTabs(tabs) {
      ctrl.tabs = tabs || [];
    }

    ctrl.goto = sbTabService.goto;
    ctrl.close = sbTabService.close;
    ctrl.closeAll = sbTabService.closeAll;
  }
});
