import { gql } from '@apollo/client';

const query = gql`
  query DashboardMatterData($matterId: ID!) {
    matter(id: $matterId) {
      id
      matterStarted
      matterType {
        matterTypeId
        name
      }
      matterTotals {
        id
        unbilled
        unpaidExcInterest
      }
      matterBalanceTrustSummary {
        matterId
        balance
        availableBalance
        protectedBalance
      }
      matterBalanceControlledMoneySummary {
        matterId
        balance
      }
      matterBalanceCredit {
        matterId
        balance
      }
      matterBalanceOperating {
        matterId
        balance
      }
    }
  }
`;

const notificationIds = [
  'BrowseMattersNotifications.MatterUpdated',
  'AccountsNotifications.AccountBalanceUpdated',
  'AccountsNotifications.BankAccountUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DashboardMatterData = {
  query,
  notificationIds,
};
