import React from 'react';
import { Button } from '@sb-itops/react';
import { getSbSiteParams } from '@sb-itops/environment-config';
import Styles from './DataBackup.module.scss';

const { sbDomain } = getSbSiteParams();
const SUPPORT_EMAIL = `support@${sbDomain}`;

interface IDataBackup {
  onRequestBackup: () => void;
  saveDisabled: boolean;
  loading: boolean;
}

export const DataBackup = (props: IDataBackup) => {
  const { onRequestBackup, saveDisabled, loading } = props;

  return (
    <div className={Styles.container}>
      <h3>Data Backup</h3>
      <p>
        Your Matter, Client and Document data is securely stored and backed up on our servers. This data is your data
        and the ownership remains with you at all times.
      </p>
      <h4>Matter & Client Data</h4>
      <p>
        If you would like to download a copy of this data to keep locally as a backup, this can be done at any time by
        pressing the “Create data backup” button below.
      </p>
      <p>
        This will initiate a backup of your data, and you will be emailed instructions on how to download that data
        shortly after the back up is complete. This process will take somewhere between 1 minute and possibly up to 1
        hour, depending on the size of your data.
      </p>

      <Button locked={loading} disabled={saveDisabled} className={Styles.backupButton} onClick={onRequestBackup}>
        Create data backup
      </Button>
      <h4>Documents</h4>
      <p>
        If you would like a full backup of your documents for all matters, we can deliver that to you for a fee. As this
        is most likely a very large amount of data it is something that we will initiate on our server then send you a
        link and instructions for download. If you would like to request a full backup of your data please email your
        request to <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </p>
    </div>
  );
};
