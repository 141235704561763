import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getRegion } from '@sb-itops/region';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import { getLawPayClientId, getLawPayRedirectUrl } from '@sb-itops/environment-config';
import {
  providersByRegion,
  defaultProviderByRegion,
  providerNames,
  providers,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import { activateProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { PaymentProviderActivationForm } from './PaymentProviderActivationForm';

const logger = getLogger('payment-provider-activation-form-container');

const mapStateToProps = () => {
  const providersForRegion = providersByRegion[getRegion()] || {};
  const defaultProviderType = defaultProviderByRegion[getRegion()];

  const providerOptions = Object.keys(providersForRegion).reduce((acc, providerType) => {
    if (
      (providerType === providers.EZY_COLLECT && !featureActive('BB-10108')) ||
      (providerType === providers.FEE_WISE && !featureActive('BB-12038'))
    ) {
      return acc;
    }

    const providerOption = {
      label: providerNames[providerType],
      value: providerType,
    };

    if (providerType === defaultProviderType) {
      // Force regional default provider to show first in drop down.
      acc.unshift(providerOption);
    } else {
      acc.push(providerOption);
    }

    return acc;
  }, []);

  return {
    providerOptions,
  };
};

const mapDispatchToProps = () => ({});

const performActivationActions = (providerType) => {
  if (providerType === providers.LAWPAY) {
    const lawpayHost = 'https://secure.lawpay.com';
    const lawpayConnectUrl = `${lawpayHost}/oauth/authorize?response_type=code&scope=chargeio&redirect_uri=${getLawPayRedirectUrl()}&client_id=${getLawPayClientId()}`;
    window.open(lawpayConnectUrl);
  }
};

export const PaymentProviderActivationFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const { providerOptions } = props;

    const [paymentProvider, setPaymentProvider] = useState(providerOptions[0]);
    const [isActivating, setIsActivating] = useState(false);

    const onActivate = async (providerType) => {
      try {
        setIsActivating(true);
        await activateProvider({ fetchPostP, providerType });

        if (featureActive('BB-13244')) {
          window?.ChurnZero?.push(['setAttribute', 'account', 'Payment Processor', providerType]);
        }

        // Some payment providers require opening a new tab on activation (e.g. LawPay).
        // It's not easy to trigger the new tab opening in the provider specific settings form without
        // introducing a user click event, which will lead to a janky "click twice" user experience.
        // To overcome that issue, we add the capability here for on-activation actions, however, this
        // should be used sparingly. The preferred approach is for all provider specific logic to sit
        // within the relevant provider specific settings container.
        performActivationActions(providerType);
      } catch (err) {
        logger.error(`Failed to activate provider '${providerType}'`, err);
        displayErrorToUser(`
          Failed to connect to ${paymentProvider?.label || 'payment provider'}, please try again later.
        `);
      }
    };

    return (
      <PaymentProviderActivationForm
        paymentProvider={paymentProvider}
        isActivating={isActivating}
        onPaymentProviderChanged={setPaymentProvider}
        onActivate={onActivate}
        {...props}
      />
    );
  }),
);

PaymentProviderActivationFormContainer.displayName = 'PaymentProviderActivationFormContainer';

PaymentProviderActivationFormContainer.propTypes = {};

PaymentProviderActivationFormContainer.defaultProps = {};
