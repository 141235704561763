'use strict';

/**
 * @typedef { import('../types.js').FeeWiseChargeFormData } FeeWiseChargeFormData
 */

const { capitalize, capitalizeAllWords } = require('@sb-itops/nodash');
const { providerNames } = require('../../../entities/constants');
const { paymentMethods } = require('./payment-methods');

/**
 * getChargeDescription
 *
 * @param {Object} params
 * @param {number} params.amountInCents The amount for the charge.
 * @param {string} params.firmName The name of the firm to which the charge for the description belongs.
 * @param {string} [params.staffName] Staff name to be used in the staff details portion of description.
 * @param {FeeWiseChargeFormData} params.providerSpecificFields The data received from the FeeWise charge form
 * @param {function} params.t Translate function

 * @returns {string} A human readable description of the charge.
 */
const getChargeDescription = ({ amountInCents, firmName, staffName, providerSpecificFields, t }) => {
  const { billingName, paymentMethod, paymentMethodDetails = {} } = providerSpecificFields;

  if (!paymentMethod) {
    throw new Error('missing paymentMethod from providerSpecificChargeData');
  }

  const dollarAmountWithSymbol = t('cents', { val: amountInCents });

  let firmNamePrefix = '';
  if (firmName) {
    // Just in case some firm name has been entered with weird casing, we normalise so the description doesn't look bizzare
    // e.g. luke's Cool firm: Amex credit card charge from Luke Marsden.....
    firmNamePrefix = `${capitalizeAllWords(firmName)}: `;
  }

  const staffDetails = staffName ? ` (Processed by ${staffName})` : '';

  switch (paymentMethod) {
    case paymentMethods.Card: {
      const { scheme, cardholderName, schemePartialNumber } = paymentMethodDetails.card || {};

      const cardType = scheme;
      const cardholderDisplayName = cardholderName || '';
      const cardNumberTruncated = schemePartialNumber;
      const creditCardLabel = cardType ? `${capitalize(cardType)} credit card` : 'Credit card'; // Amex, visa, mastercard etc.

      return `${firmNamePrefix}${creditCardLabel} charge from ${cardholderDisplayName} (***${cardNumberTruncated}) of ${dollarAmountWithSymbol}${staffDetails} via ${providerNames.FEE_WISE}`;
    }

    case paymentMethods.DirectDebit: {
      const { accountPartialNumber, bankName } = paymentMethodDetails.debit || {};
      const accountHolderName = billingName || '';
      return `${firmNamePrefix}eCheck charge from ${accountHolderName} (${bankName} account ***${accountPartialNumber}) of ${dollarAmountWithSymbol}${staffDetails} via ${providerNames.FEE_WISE}`;
    }

    default:
      throw new Error(`Failed to build charge description due to unsupported payment method of ${paymentMethod}`);
  }
};

module.exports = {
  getChargeDescription,
};
