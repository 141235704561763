/* eslint-disable import/no-cycle */
import uuid from '@sb-itops/uuid';
// import { fetchPostP } from '@sb-itops/redux/fetch';
import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import { dateToInteger } from '@sb-itops/date';
import {
  buildPaymentPlanOpdates,
  opdateCache as opdatePaymentPlanCache,
  rollbackOpdateCache as rollbackPaymentPlanOpdateCache,
} from './payment-plan-opdates';

const buildSavePaymentPlanMessage = ({
  planId,
  debtorId,
  matterIds,
  paymentMethodId,
  nextInstallmentDate,
  installmentFrequency,
  installmentAmount,
} = {}) => ({
  planId,
  debtorId,
  matterIds,
  paymentMethodId: paymentMethodId === 'None' ? undefined : paymentMethodId,
  autoCharge: paymentMethodId !== 'None',
  nextInstallmentDate,
  installmentFrequency,
  installmentAmount,
  // each time we send a save command we need to send the effective change date/ It is a requirement by .NET.
  changeEffectiveDate: dateToInteger(new Date()),
});

// validate the entity before hitting the endpoint
const validatePaymentPlanMessage = ({
  planId,
  debtorId,
  matterIds,
  nextInstallmentDate,
  installmentFrequency,
} = {}) => {
  if (!planId && typeof planId !== 'string') {
    throw new Error(`planId need to be present`);
  }
  if (!debtorId && typeof planId !== 'string') {
    throw new Error(`debtorId need to be present`);
  }
  if (!matterIds || (matterIds && matterIds.length === 0)) {
    throw new Error(`matterIds need to be present and with something`);
  }
  if (!nextInstallmentDate || typeof nextInstallmentDate !== 'number') {
    throw new Error(`nextInstallmentDate need to be present`);
  }
  if (!installmentFrequency || !['Weekly', 'BiWeekly', 'Monthly', 'BiMonthly'].includes(installmentFrequency)) {
    throw new Error(`installmentFrequency need to be present`);
  }
  return true;
};

const path = `/billing/payment-plan/:accountId/`;

/**
 * @typedef {PaymentPlan} - a payment plan.
 * @property {string} id - uuid for the payment plan.
 * @property {string} debtorId - uuid for the debtor.
 * @property {Array.<string>} matterIds - uuid collection of matter ids.
 * @property {string} nextInstallmentDate - ISO date for the next installment.
 * @property {'Weekly'|'BiWeekly'|'Monthly'|'BiMonthly'} installmentFrequency - the installment frequency
 */

/**
 * POST a message to the endpoint to save the payment plan
 * @param {PaymentPlan} -  a payment plan
 * @returns {Promise} returns a promise that will be resolved when the post happens.
 * @throws if the payment plan message is not well formed.
 */
export const savePaymentPlan = (paymentPlan) => {
  const planId = paymentPlan.id || uuid();
  const paymentPlanToSaveMessage = buildSavePaymentPlanMessage({
    planId,
    ...paymentPlan,
  });

  if (!validatePaymentPlanMessage(paymentPlanToSaveMessage)) {
    return undefined;
  }

  const paymentPlanOpdate = buildPaymentPlanOpdates({
    ...paymentPlan,
    paymentMethodId: paymentPlan.paymentMethodId === 'None' ? undefined : paymentPlan.paymentMethodId,
    autoCharge: paymentPlan.paymentMethodId !== 'None',
    id: planId,
  });

  const opdateCaches = () => {
    opdatePaymentPlanCache({ optimisticEntities: [paymentPlanOpdate] });

    return () => {
      rollbackPaymentPlanOpdateCache({ optimisticEntities: [paymentPlanOpdate] });
    };
  };

  fetchPostThunkFactory({
    path,
    opdateCaches,
  })(paymentPlanToSaveMessage);

  return paymentPlanOpdate;
};
