import * as Yup from 'yup';

export const LawpayCreditCardFormSchema = Yup.object().shape({
  cardholderName: Yup.string().required(),
  expirationMonth: Yup.number().required(),
  expirationYear: Yup.number().required(),
  addressLine1: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  postalCode: Yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/)
    .required(),
  email: Yup.string().email(),
  creditCardNumberStatus: Yup.boolean().oneOf([true]).required(),
  securityCodeStatus: Yup.boolean().oneOf([true]).required(),
});
