import { featureActive } from '@sb-itops/feature';
import React from 'react';
import PropTypes from 'prop-types';
import PaymentPlanModalDialogWithGQL from './PaymentPlanModalDialogWithGQL.container';
import PaymentPlanModalDialogWithReduxEntities from './PaymentPlanModalDialogWithReduxEntities.container';

const PaymentPlanModalDialogContainer = (props) => {
  if (featureActive('BB-9674')) {
    return <PaymentPlanModalDialogWithGQL {...props} />;
  }
  return <PaymentPlanModalDialogWithReduxEntities {...props} />;
};

PaymentPlanModalDialogContainer.propTypes = {
  selectedPaymentPlanId: PropTypes.string,
  paymentPlanModalId: PropTypes.string.isRequired,
  modalDialogTitle: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  includedMatterIds: PropTypes.arrayOf(PropTypes.string),
  // callbacks
  onNewPaymentPlanCreated: PropTypes.func.isRequired,
};

PaymentPlanModalDialogContainer.defaultProps = {
  selectedPaymentPlanId: undefined,
  contactId: undefined,
  includedMatterIds: [],
};

export default PaymentPlanModalDialogContainer;
