'use strict';
const { Cent } = require('@sb-itops/money');

/**
 * Format cents to region-specific currency value for display purposes
 *
 * @param {Number} value Amount in cents
 * @param {String} lng Language used (such as en-AU)
 * @param {Object} [options]
 * @param {Boolean} options.currencyCode Currency code used
 * @param {Boolean} [options.hideCurrencySymbol] Hide currency symbol
 * @param {Boolean} [options.absolute] Use absolute value
 * @param {Boolean} [options.hideSeparator] Hide separator between thousands
 * @returns {String} Formatted currency value
 */
module.exports = (value, lng, options = { hideCurrencySymbol: false, absolute: false, hideSeparator: false }) => {
  if (!Number.isFinite(value)) {
    console.error(`Value needs to be of type number - ${value}`);
    return value;
  }
  if (!options.currencyCode) {
    console.error(`Currency code needs to be specified`);
    return value;
  }

  const centValue = options.absolute ? Math.abs(value) : value;
  const dollarValue = new Cent(centValue).dollars();
  if (dollarValue === undefined) {
    console.error(`Problem with converting cents`);
    return value;
  }

  const formatterOptions = {
    style: 'currency',
    currency: options.currencyCode,
  }
  
  if (options.hideSeparator) {
    formatterOptions.useGrouping = false;
  }

  const formatter = new Intl.NumberFormat(lng, formatterOptions);

  if (options.hideCurrencySymbol) {
    const formattedCurrencyValue = formatter.formatToParts(dollarValue).reduce((formattedValue, part) => {
      if (part.type === 'literal' || part.type === 'currency') {
        return formattedValue;
      }
      formattedValue += part.value;
      return formattedValue;
    }, '');

    return formattedCurrencyValue;
  }

  return formatter.format(dollarValue);
};
