import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Icon.module.scss';

/**
 *
 * @param {Object} params
 * @param {string} params.type The icon type to display. Can be FA or SB icon.
 * @param {string} [params.tooltip] The icon hover tooltip to display.
 * @param {string} [params.color] The colour of the icon.
 * @param {string} [params.className] The css class to be applied to the icon container element.
 * @param {React.MouseEventHandler} params.onClick
 * @param {boolean} [params.isFA] Must be set to true if you are using a font-awesome icon. Defaults to false.
 * @param {boolean} [params.isRelatable]  Set to true if you need blur outside this element to detect this
 *                                        element as relatable element.
 *                                        I.e. onBlur((otherElementEvt) => otherElementEvt.relatedElement !== null)
 *
 * @returns {React.ReactElement}
 */
const Icon = ({ type, tooltip, color, className, onClick, isFA, isRelatable }) => (
  <div tabIndex={isRelatable ? -1 : undefined} className={className || Styles.container} onClick={onClick}>
    <i
      title={tooltip}
      style={{ color }}
      className={classNames(isFA ? 'fa' : 'icon', `${isFA ? 'fa' : 'icon'}-${type}`, Styles.icon)}
    />
  </div>
);

Icon.displayName = 'Icon';

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  isRelatable: PropTypes.bool,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  isFA: PropTypes.bool,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  isRelatable: false,
  tooltip: undefined,
  color: undefined,
  className: undefined,
  isFA: false,
  onClick: () => {},
};

export default memo(Icon);
