import React, { useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  SlidingToggle,
  Spinner,
  TemplateMacroSelector,
  TextEditor,
  forms2PropTypes,
  PreviewPdf,
} from '@sb-itops/react';
import Styles from './InvoiceReminderEmailSettings.module.scss';

export const InvoiceReminderEmailSettings = memo(
  ({
    isSaving,
    onSaveButtonClick,
    getInvoiceReminderEmailMacros,
    operatingPaymentProviderConnected,
    onRefeshPdf,
    previewUrl,
    // fields
    emailSubject,
    emailBody,
    notes,
    sendCopyToUser,
    showAccountSummary,
    showTransactionHistory,
    // form
    formInitialised,
    formDirty,
    onFormDataUpdated,
  }) => {
    const emailSubjectRef = useRef();
    let emailBodyReactQuillRef;
    const [subjectSelectedMacro, setSubjectSelectedMacro] = useState('');
    const [bodySelectedMacro, setBodySelectedMacro] = useState('');
    const [bodyEditorCursorPosition, setBodyEditorCursorPosition] = useState(0);

    if (!formInitialised) {
      return null;
    }

    const onSliderChange = ({ key, value }) => {
      onFormDataUpdated({ key, value });
      onRefeshPdf();
    };

    return (
      <div className={Styles.invoiceReminderEmailSettings}>
        <div className={Styles.invoiceReminderEmailSettingsForm}>
          <div className="form-group">
            <label>Email Subject</label>
            <input
              className={classnames({ 'form-control': true, [Styles.hasError]: emailSubject.isInvalid })}
              value={emailSubject.value}
              maxLength="120"
              onChange={(e) => onFormDataUpdated({ key: emailSubject.key, value: e.target.value })}
              ref={emailSubjectRef}
            />
            <TemplateMacroSelector
              selectedMacroName={subjectSelectedMacro}
              macros={getInvoiceReminderEmailMacros({
                isForEmailSubject: true,
                inclPayNowButton: operatingPaymentProviderConnected,
              })}
              onMacroChanged={setSubjectSelectedMacro}
              onMacroInserted={(macroName) => {
                if (!emailSubjectRef) return;
                const selectionStart = emailSubjectRef.current.selectionStart;
                const selectionEnd = emailSubjectRef.current.selectionEnd;
                // insert the macroName
                const value = emailSubject.value;
                const newValue =
                  value.substring(0, selectionStart) + macroName + value.substring(selectionEnd, value.length);
                onFormDataUpdated({ key: emailSubject.key, value: newValue });
              }}
            />
          </div>
          <div className="form-group">
            <label>Email Body</label>
            <TextEditor
              className={classnames({
                'invoice-email-template-body-editor': true,
                [Styles.hasError]: emailBody.isInvalid,
              })}
              value={emailBody.value}
              onChange={(value) => onFormDataUpdated({ key: emailBody.key, value })}
              onBlur={(range) => {
                setBodyEditorCursorPosition(range.index);
              }}
              // eslint-disable-next-line no-return-assign
              reactQuillRef={(rqRef) => (emailBodyReactQuillRef = rqRef)}
            />
            <TemplateMacroSelector
              selectedMacroName={bodySelectedMacro}
              macros={getInvoiceReminderEmailMacros({
                isForEmailSubject: false,
                inclPayNowButton: operatingPaymentProviderConnected,
              })}
              onMacroChanged={setBodySelectedMacro}
              onMacroInserted={(macroName) => {
                if (!emailBodyReactQuillRef || typeof emailBodyReactQuillRef.getEditor !== 'function') return;

                const quill = emailBodyReactQuillRef.getEditor();
                // If quill didn't have focus/selection immediately before this function is triggered, quill.getSelection() returns null
                const cursorPosition = quill.getSelection()
                  ? quill.getSelection().index
                  : bodyEditorCursorPosition || 0;
                quill.insertText(cursorPosition, macroName);
                setBodyEditorCursorPosition(cursorPosition + macroName.length);
              }}
            />
          </div>
          <div className="form-group">
            <label>Notes</label>
            <TextEditor
              className="invoice-email-template-body-editor"
              value={notes.value}
              onChange={(value) => onFormDataUpdated({ key: notes.key, value })}
              onBlur={() => onRefeshPdf()}
            />
          </div>
          <div className={classnames('form-group', Styles.switches)}>
            <div className={Styles.switchItem}>
              <label className={Styles.toggleLabel} htmlFor="showAccountSummarySlider">
                <SlidingToggle
                  name="showAccountSummarySlider"
                  scope="invoiceReminderSettings"
                  selected={showAccountSummary.value}
                  onChange={() => onSliderChange({ key: showAccountSummary.key, value: !showAccountSummary.value })}
                />
                <div className={Styles.labelText}>Show an account summary on the invoice</div>
              </label>
            </div>
            {!!showAccountSummary.value && (
              <div className={Styles.switchItem}>
                <label className={Styles.toggleLabel} htmlFor="showTransactionHistorySlider">
                  <SlidingToggle
                    name="showTransactionHistorySlider"
                    scope="invoiceReminderSettings"
                    selected={showTransactionHistory.value}
                    onChange={() =>
                      onSliderChange({ key: showTransactionHistory.key, value: !showTransactionHistory.value })
                    }
                  />
                  <div className={Styles.labelText}>Show the transaction history for each account</div>
                </label>
              </div>
            )}
            <div className={Styles.switchItem}>
              <label className={Styles.toggleLabel} htmlFor="sendCopySlider">
                <SlidingToggle
                  name="sendCopySlider"
                  scope="invoiceReminderSettings"
                  selected={sendCopyToUser.value}
                  onChange={() => onSliderChange({ key: sendCopyToUser.key, value: !sendCopyToUser.value })}
                />
                <div className={Styles.labelText}>Send a Copy of the Email to the User</div>
              </label>
            </div>
          </div>
          <div className="form-group">
            <Button onClick={onSaveButtonClick} disabled={isSaving || !formDirty}>
              {isSaving && <Spinner small />} Save
            </Button>
          </div>
        </div>
        <div className={Styles.pdfPreview}>
          <div className={Styles.inner}>
            {!previewUrl && <Spinner message="Loading PDF..." className={Styles.loadingIndicator} />}
            {previewUrl && <PreviewPdf url={previewUrl} />}
          </div>
        </div>
      </div>
    );
  },
);

InvoiceReminderEmailSettings.displayName = 'InvoiceReminderEmailSettings';

const { Forms2Field } = forms2PropTypes;
InvoiceReminderEmailSettings.propTypes = {
  isSaving: PropTypes.bool,
  onSaveButtonClick: PropTypes.func.isRequired,
  onRefeshPdf: PropTypes.func.isRequired,
  getInvoiceReminderEmailMacros: PropTypes.func.isRequired,
  operatingPaymentProviderConnected: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string,
  // fields
  emailSubject: Forms2Field,
  emailBody: Forms2Field,
  notes: Forms2Field,
  sendCopyToUser: Forms2Field,
  showAccountSummary: Forms2Field,
  showTransactionHistory: Forms2Field,
  // form
  formDirty: PropTypes.bool,
  formInitialised: PropTypes.bool,
  onFormDataUpdated: PropTypes.func.isRequired,
};

InvoiceReminderEmailSettings.defaultProps = {
  isSaving: false,
  previewUrl: undefined,
  // fields
  emailSubject: undefined,
  emailBody: undefined,
  notes: undefined,
  sendCopyToUser: undefined,
  showAccountSummary: undefined,
  showTransactionHistory: undefined,
  // form
  formDirty: false,
  formInitialised: false,
};
