import React, { useState, useEffect } from 'react';
import { t } from '@sb-itops/localisation-web';
import { DateRangePicker, SlidingToggle } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
import { MatterTypeahead } from 'web/react-redux/components/matter-typeahead';
import { featureActive } from '@sb-itops/feature';

import Styles from '../ReportFilter.module.scss';

const TimeFeesExpensesFilter = ({
  onSelectPeriod,
  onEndSelect,
  onStartSelect,
  onToggleOption,
  onSelectOption,
  onMatterSelected,
  onShowInvoicesFor,
  filters,
  staff,
  areasOfLaw,
  periodOptions,
  entryTypes,
}) => {
  const {
    periodSelected,
    startDate,
    endDate,
    includeFirmHeader,
    includeFirmLogo,
    includeFirmName,
    includeLeadMatters,
    excludeExternallyBilled,
    showInvoicesFor,
    showEntriesList,
    showDescriptionField,
    attorneyResponsible,
    areaOfLaw,
    entryType,
    matter,
    errors,
  } = filters || {};

  const [matterFocus, setMatterFocus] = useState(false);

  useEffect(() => {
    setMatterFocus(showInvoicesFor === 'matter');
  }, [showInvoicesFor]);

  if (!filters) {
    return null;
  }

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.period}>
        <label>Report period</label>
        <DateRangePicker
          onSelect={onSelectPeriod}
          onEndSelect={onEndSelect}
          onStartSelect={onStartSelect}
          options={periodOptions}
          selected={periodSelected}
          endDate={endDate}
          endHasError={errors.endDate}
          startDate={startDate}
          startHasError={errors.startDate}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>{t('capitalizeAll', { val: 'personResponsibleTimekeeper' })}</label>
        <div className={Styles.options}>
          <input
            type="radio"
            onChange={() => onShowInvoicesFor('staff')}
            checked={showInvoicesFor === 'staff'}
            className={Styles.radioOption}
          />
          <StatelessSelect
            className={Styles.attorneySelect}
            selectedOption={attorneyResponsible}
            isClearable={false}
            options={staff}
            onValueChange={(option) => onSelectOption({ key: 'attorneyResponsible', option })}
          />
        </div>
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Matter</label>
        <div className={Styles.options}>
          <input
            type="radio"
            onChange={() => onShowInvoicesFor('matter')}
            checked={showInvoicesFor === 'matter'}
            className={Styles.radioOption}
          />
          <MatterTypeahead
            disabled={showInvoicesFor !== 'matter'}
            onValueChange={onMatterSelected}
            className={Styles.typeahead}
            onClear={onMatterSelected}
            hasError={errors.matter}
            initialId={matter ? matter.id : undefined}
            focus={matterFocus}
            filter="all"
          />
        </div>
      </div>
      <div className={Styles.separator} />
      <div className={Styles.attorney}>
        <label>Area of law</label>
        <StatelessSelect
          selectedOption={areaOfLaw}
          isClearable={false}
          options={areasOfLaw}
          onValueChange={(option) => onSelectOption({ key: 'areaOfLaw', option })}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.attorney}>
        <label>Type of Entries</label>
        <StatelessSelect
          selectedOption={entryType}
          isClearable={false}
          options={entryTypes}
          onValueChange={(option) => onSelectOption({ key: 'entryType', option })}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.printOptions}>
        <label>View options</label>
        <div className={Styles.options}>
          <span className={Styles.option} data-name="showEntriesList" onClick={toggleOption}>
            Show entries list
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showEntriesList" name="showEntriesList" selected={showEntriesList} />
            </div>
          </span>
          <span
            className={Styles.option}
            data-name="showDescriptionField"
            onClick={(e) => {
              if (showEntriesList && entryType.value !== 'expenses') {
                toggleOption(e);
              }
            }}
          >
            Show description field
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="showDescriptionField"
                name="showDescriptionField"
                selected={showDescriptionField}
                disabled={!showEntriesList || entryType.value === 'expenses'}
              />
            </div>
          </span>
          {/* Show include lead matters filter only when BB-6595 is switched off, this is being removed for consistency
              across all the reports. In the future, feature switch BB-6595 and BB-4470 should be cleaned up together */}
          {featureActive('BB-4470') && !featureActive('BB-6595') && (
            <span
              className={Styles.option}
              data-name="includeLeadMatters"
              onClick={(e) => {
                if (showInvoicesFor !== 'matter') {
                  toggleOption(e);
                }
              }}
            >
              Include lead matters
              <div className={Styles.toggleContainer}>
                <SlidingToggle
                  scope="includeLeadMatters"
                  name="includeLeadMatters"
                  selected={includeLeadMatters}
                  disabled={showInvoicesFor === 'matter'}
                />
              </div>
            </span>
          )}
          <span data-name="excludeExternallyBilled" onClick={toggleOption} className={Styles.option}>
            Exclude externally billed
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="excludeExternallyBilled"
                name="excludeExternallyBilled"
                selected={excludeExternallyBilled}
              />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.separator} />
    </div>
  );
};

TimeFeesExpensesFilter.displayName = 'TimeFeesExpensesFilter';

export default TimeFeesExpensesFilter;
