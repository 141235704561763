'use strict';

const { PreChargeError } = require('../../../entities/custom-errors');

function extractChargeFields({ paymentFormData }) {
  const { providerFormData } = paymentFormData;

  if (!providerFormData.paymentMethodDetails || !providerFormData.paymentMethodDetails.paymentToken) {
    const preChargeErrorDetails = providerFormData.preChargeErrorDetails;
    if (preChargeErrorDetails && preChargeErrorDetails.message) {
      throw new PreChargeError(preChargeErrorDetails.message);
    } else {
      throw new Error('Failed to extract FeeWise specific charge fields - payment token is required');
    }
  }

  if (!providerFormData.paymentMethod) {
    throw new Error('Failed to extract FeeWise specific charge fields - payment method is required');
  }

  return { paymentMethodDetails: providerFormData.paymentMethodDetails, paymentMethod: providerFormData.paymentMethod };
}

module.exports = {
  extractChargeFields,
};
