import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, FormLabel, forms2PropTypes } from '@sb-itops/react';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import classnames from 'classnames';
import Styles from './CloseReopenControlledMoneyAccountForm.module.scss';

import { MatterTypeahead } from '../matter-typeahead';

const CloseReopenControlledMoneyAccountForm = ({
  scope,
  isCloseAccount,
  lastOpenDate,

  // form fields
  stateChangeDate,
  matterId,
  reason,
  internalNote,

  // form
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  formInitialised,
}) => {
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
  const onMatterSelected = (selectedMatter) => {
    onFieldValueUpdated({ [matterId.key]: selectedMatter && selectedMatter.id });
  };
  return (
    <div className={Styles.container} id={scope}>
      <fieldset>
        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel
              label={isCloseAccount ? 'Date Closed' : 'Date Reopened'}
              field={stateChangeDate}
              submitFailed={submitFailed}
            />
            <DatePicker
              onSelect={(date) => {
                onFieldValueUpdated({ stateChangeDate: date ? dateToInteger(date) : undefined });
                validateForm();
              }}
              value={stateChangeDate?.value && integerToDate(stateChangeDate.value)}
              hasError={stateChangeDate?.isInvalid}
              minDate={integerToDate(lastOpenDate)}
              format="DD/MM/YYYY"
              disabled={formDisabled}
            />
            {stateChangeDate.isInvalid && <span className={Styles.errorMessage}>{stateChangeDate.invalidReason}</span>}
          </div>

          <div className="col-xs-6 form-group">
            <FormLabel label="Matter" field={matterId} submitFailed={submitFailed} />
            <MatterTypeahead
              onValueChange={onMatterSelected}
              onClear={onMatterSelected}
              initialId={matterId?.value}
              filter="all"
              disabled
              onBlur={validateForm}
              hasError={matterId && matterId.isInvalid}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Reason" field={reason} submitFailed={submitFailed} />
            <div>
              <textarea
                className={classnames('form-control', isErrorClassnames(reason))}
                rows="5"
                value={reason?.value || ''}
                disabled={formDisabled}
                onChange={(e) => {
                  onFieldValueUpdated({ reason: e.target.value });
                }}
                onBlur={validateForm}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Internal Note" field={internalNote} submitFailed={submitFailed} />
            <div>
              <textarea
                className={classnames('form-control', isErrorClassnames(internalNote))}
                rows="5"
                value={internalNote?.value || ''}
                disabled={formDisabled}
                onChange={(e) => {
                  onFieldValueUpdated({ internalNote: e.target.value });
                }}
                onBlur={validateForm}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

CloseReopenControlledMoneyAccountForm.displayName = 'CloseReopenControlledMoneyAccountForm';
CloseReopenControlledMoneyAccountForm.propTypes = {
  scope: PropTypes.string.isRequired,
  lastOpenDate: PropTypes.number.isRequired,
  isCloseAccount: PropTypes.bool.isRequired,
  // fields
  stateChangeDate: Forms2Field,
  matterId: Forms2Field,
  reason: Forms2Field,
  internalNote: Forms2Field,
  // form
  onFieldValueUpdated: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

CloseReopenControlledMoneyAccountForm.defaultProps = {
  stateChangeDate: undefined,
  matterId: undefined,
  reason: undefined,
  internalNote: undefined,
};

export default CloseReopenControlledMoneyAccountForm;
