import { gql } from '@apollo/client';
import { contactTableFragment } from '../fragments/contactTableFragment.graphql';

const query = gql`
  query SbClientSdkContactsData($contactFilter: ContactFilter, $offset: Int, $limit: Int) {
    contactList(filter: $contactFilter, offset: $offset, limit: $limit) {
      results {
        ...contactTableFragment
        lastUpdated

        residentialAddress {
          city
          state
          zipCode
          country
        }
        businessAddress {
          city
          state
          zipCode
          country
        }
        mailingAddress {
          city
          state
          zipCode
          country
        }
      }
    }
  }

  ${contactTableFragment}
`;

const notificationIds = ['CustomerManagementWebQuery.EntityUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SbClientSdkContactsData = {
  query,
  notificationIds,
};
