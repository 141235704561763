'use strict';

/**
 * Composable data component which presents a standard way for list components
 * to interact with a collection of data.
 */
angular.module('sb.billing.webapp').component('sbDataListProvider', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: { composeKey: '@', sourceKey: '@' },
  controller: function (sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataListProvider');

    // log.setLogLevel('info');

    /**
     * Initialisation.
     */
    ctrl.$onInit = () => {
      log.info('composeKey', ctrl.composeKey);
      log.info('sourceKey', ctrl.sourceKey);

      const provider = dataProvider(sbLoggerService);

      // Handle updates made to the underlying data source.
      ctrl.sbComposeCtrl.onUpdateData(ctrl.sourceKey, provider.updateData);

      // We don't want to expose the entire controller via sbCompose.
      // Instead a limited set is provided.
      const exposedApi = {
        setSorter: provider.setSorter,
        getData: provider.getData,
        getCount: provider.getCount,
        setFilter: provider.setFilter,
        setPostProcessor: provider.setPostProcessor,
        refreshData: provider.refreshData,
        startRefreshingData: provider.startRefreshingData,
        onUpdateData: provider.onUpdateData,
      };

      ctrl.sbComposeCtrl.setComposeData(exposedApi, ctrl.composeKey);
    };
  }
});

function dataProvider (sbLoggerService) {
  const log = sbLoggerService.getLogger('sbDataListProviderModule');

  const updateHandlers = [];
  let refreshingEnabled = false;

  let data = [];
  let unprocessedData = [];

  // Apply default filter, post process, sort.
  let filterFn = returnDataUnchanged;
  let postProcessFn = returnDataUnchanged;
  let sortFn = returnDataUnchanged;

  return {
    setSorter,
    getData,
    getCount,
    setFilter,
    setPostProcessor,
    refreshData,
    startRefreshingData,
    onUpdateData,
    updateData,
  };

  /**
   * Processes a data change event from the bound data source, i.e. the sbCompose data component feeding into
   * this list provider.
   * @param newData
   */
  function updateData(newData) {
    log.info(`Received new data with ${newData.length} elements`);
    unprocessedData = (newData && newData.data) || newData;
    refreshData();
  }

  /**
   * Allows registration of a function to be called whenever the visible data changes, i.e. ctrl.data
   * @param handler
   */
  function onUpdateData(handler) {
    updateHandlers.push(handler);
  }

  function startRefreshingData() {
    refreshingEnabled = true;
    refreshData();
  }

  /**
   * Triggers a re-filter/resort of the current unprocessed data set and calls any registered update handlers.
   */
  function refreshData() {
    if (refreshingEnabled) {
      data = sortFn(postProcessFn(filterFn(unprocessedData)));
      callUpdateHandlers(data);
    }
  }

  /**
   * Returns an object which provides an interface suitable for consumption by a ui-scroll compatible list.
   */
  function getInfiniteData() {
    return {
      get: (index, count, success) => {
        if (!_.isEmpty(data)) {
          index--; //index is not ZERO based so make it so, said Capt. Picard.
          const start = index < 0 ? 0 : index;
          const end = Math.min(index + count, data.length);
          const ds = _.slice(data, start, end);
          success(ds);
        } else {
          success([]);
        }
      }
    };
  }

  /**
   * Retruns the currently processed data for consum
   * @returns {*}
   */
  function getData(type = 'static') {
    switch (type) {
      case 'infinite':
        return getInfiniteData();

      case 'static':
        return data;

      default:
        throw new Error('Failed to get data for unexpected type \'' + type + '\'');
    }
  }

  /**
   * Returns the current length of the processed data.
   * @returns {*}
   */
  function getCount() {
    return data.length;
  }

  /**
   * Allows clients of this list provider to apply a sorting function.
   * @param sortFn
   */
  function setSorter(sortFunc) {
    sortFn = _.isFunction(sortFunc) ? sortFunc : returnDataUnchanged;
    refreshData();
  }

  /**
   * Allows clients of this list provider to apply a filter function.
   * @param filterFn
   */
  function setFilter(filterFunc) {
    filterFn = _.isFunction(filterFunc) ? filterFunc : returnDataUnchanged;
    refreshData();
  }

  /**
   * Allows clients of this list provider to apply post processing whenever changes to the data occur.
   * @param postProcessFn
   */
  function setPostProcessor(postProcessFunc) {
    postProcessFn = _.isFunction(postProcessFunc) ? postProcessFunc : returnDataUnchanged;
    refreshData();
  }

  /**
   * Executes all of the registered update handlers.
   */
  function callUpdateHandlers(fromSort) {
    _.each(updateHandlers, (handler) => {
      if (_.isFunction(handler)) {
        handler(fromSort);
      }
    });
  }

  function returnDataUnchanged(data) {
    return data;
  }
}
