import { gql } from '@apollo/client';
import { sortByOrder } from '@sb-itops/nodash';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query ExpenseTableData($expenseFilter: ExpenseListFilter, $offset: Int, $limit: Int, $sort: ListSort) {
    expenseList(filter: $expenseFilter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      sortOrder {
        fieldNames
        directions
      }
      results {
        id
        activityCode
        amountExcTax
        amountIncludesTax
        description
        expenseDate
        expenseEarnerStaff {
          id
          initials
          isFormerStaff
        }
        expensePaymentDetails {
          id
          paymentDue
          isPaid
        }
        finalized
        invoice {
          id
          invoiceNumber
          status
        }
        isAnticipated
        isBillable
        isDeleted
        isInvoicedExternally
        isTaxOverridden
        matter {
          id
          clientDisplay
          matterNumber
          description
          matterType {
            matterTypeId
            name
          }
          attorneyResponsible {
            initials
          }
        }
        operatingCheque {
          id
          isPrinted
          reversed
        }
        price
        quantity
        tax
        utbmsTaskCode
        waived
      }
    }
  }
`;

const notificationIds = [
  // Deleted expenses are notified through ExpenseUpdated in WebQuery
  'WebQueryExpensesNotifications.ExpenseUpdated',
  'WebQueryExpensesNotifications.ExpensePaymentDetailsUpdated',
  'WebQueryInvoicingNotifications.InvoiceUpdated',
  'WebQueryAccountsNotifications.OperatingChequeUpdated',
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
];

const onOpdateQuery = ({ queryData, queryVariables, entityOpdateEvent }) => {
  const { eventType, typeName, optimisticEntity } = entityOpdateEvent;

  // Ignore irrelevant entity types / operations.
  if (`${eventType}-${typeName}` !== 'ENTITY_UPDATED-Expense') {
    return undefined;
  }

  if (!queryVariables.expenseFilter.includeStatus.deleted && optimisticEntity.removed) {
    return undefined;
  }

  // Ignore optimistic entities which do not sit within the currently viewed page of data.
  const { fieldNames: sortFields, directions: sortOrders } = queryVariables.sort;
  const updatedListItems = queryData.expenseList.results.map((result) => {
    const { id: existingId } = result;
    const { id: optimisticId } = optimisticEntity;

    return existingId === optimisticId ? { ...result, ...optimisticEntity } : result;
  });

  const sortedListItems = sortByOrder(updatedListItems, sortFields, sortOrders, false /* case insensitive */);

  // Return the new opdated data for the query.
  return {
    ...queryData,
    expenseList: {
      ...queryData.expenseList,
      results: sortedListItems,
    },
  };
};

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ExpenseTableData = {
  query,
  notificationIds,
  onOpdateQuery,
};
