'use strict';

const requireAll = require('@sb-itops/webpack-require-all');

window.jQuery = require('jquery');
require('angular');
require('lodash');
require('moment');
require('@sb-itops/services');

angular.module('@sb-billing/services', ['@sb-itops/services']);
requireAll(require.context('./', true, /^((?!demo|spec).)*\.js$/));
