import { authenticationResultTypes } from '@sb-itops/business-logic/authentication/entities/constants';
import { AuthenticationFailureError } from '@sb-itops/business-logic/authentication/entities/custom-errors';
import * as actions from './actions';
import * as selectors from './selectors';

// Past experience has taught us that devs will have their way with auth state if it isn't
// strictly protected against misuse. These operations are quite strict on the state pre-conditions
// and will throw if they are not met.

/**
 * loginP
 * Initiates a new login and updates the auth redux state accordingly
 * @param {Object} params
 * @param {Function} params.loginFnP Function which executes the underlying login.
 * @returns {Function} Function returning a promise which indicates success or failure of the operation.
 */
export const loginP =
  ({ loginFnP }) =>
  async (dispatch, getState) => {
    let authenticationResult;

    try {
      if (selectors.isLoginInProgress(getState())) {
        throw new Error('Cannot login with login already in progress.');
      }

      if (selectors.isAuthenticated(getState())) {
        throw new Error('Cannot login when already authenticated.');
      }

      dispatch(actions.loginInitiated());
      authenticationResult = await loginFnP();

      // If the response of a login is a challenge, store the challenge and return.
      // A caller will need to call respondToChallenge() later to complete the login.
      if (authenticationResult.type === authenticationResultTypes.CHALLENGE) {
        dispatch(actions.loginChallenged({ challenge: authenticationResult.challenge }));
        return;
      }

      // If the response of the login is not a challenge, we can assume it is a valid user identity.
      dispatch(actions.loginSuccess({ userIdentity: authenticationResult.userIdentity }));
    } catch (err) {
      if (err instanceof AuthenticationFailureError) {
        dispatch(actions.loginFailure({ failureDetails: err.failureDetails }));
        throw err;
      }

      dispatch(actions.loginFailure());
      throw err;
    }
  };

/**
 * respondToChallengeP
 * Responds to a login challenge and updates the auth redux state accordingly
 * @param {Object} params
 * @param {Function} params.respondToChallengeFnP Function which executes the underlying challenge response.
 * @returns {Function} Function returning a promise which indicates success or failure of the operation.
 */
export const respondToChallengeP =
  ({ respondToChallengeFnP }) =>
  async (dispatch, getState) => {
    try {
      if (!selectors.isLoginInProgress(getState())) {
        throw new Error('A challenge response can only be issued while a login is in progress');
      }

      if (!selectors.isLoginChallenged(getState())) {
        throw new Error('A challenge response can only be issued while a challenge is pending');
      }

      if (selectors.isChallengeResponseInProgress(getState())) {
        throw new Error('A challenge response cannot be issued while a challenge response is in progress');
      }

      dispatch(actions.challengeResponseInitiated());

      const challenge = selectors.getChallenge(getState());
      const challengeResponse = await respondToChallengeFnP({ challenge });
      dispatch(actions.loginSuccess({ userIdentity: challengeResponse.userIdentity }));
    } catch (err) {
      dispatch(actions.challengeResponseFailure());
      throw err;
    }
  };

/**
 * refreshTokenP
 * Initiates a new token refresh and updates the auth redux state accordingly
 * @param {Object} params
 * @param {Function} params.refreshTokenFnP Function which executes the underlying token refresh.
 * @returns {Function} Function returning a promise which indicates success or failure of the operation.
 */
export const refreshTokenP =
  ({ refreshTokenFnP }) =>
  async (dispatch, getState) => {
    try {
      if (selectors.isLoginInProgress(getState()) || selectors.isLogoutInProgress(getState())) {
        throw new Error('Cannot refresh token while login or logout in progress');
      }

      if (selectors.isAuthRefreshInProgress(getState())) {
        return;
      }

      dispatch(actions.refreshTokenInitiated());
      const userIdentity = selectors.getUserIdentity(getState());
      const newUserIdentity = await refreshTokenFnP({ userIdentity });
      dispatch(actions.refreshTokenSuccess({ userIdentity: newUserIdentity }));
    } catch (err) {
      dispatch(actions.refreshTokenFailure());
      throw err;
    }
  };

/**
 * logoutP
 * Initiates a logout and updates the auth redux state accordingly
 * @param {Object} params
 * @param {Function} params.logoutFnP Function which executes the underlying logout.
 * @returns {Function} Function returning a promise which indicates success or failure of the operation.
 */
export const logoutP =
  ({ logoutFnP }) =>
  async (dispatch, getState) => {
    try {
      if (!selectors.isAuthenticated(getState())) {
        throw new Error('Cannot logout while not authenticated');
      }

      if (selectors.isLogoutInProgress(getState())) {
        return;
      }

      dispatch(actions.logoutInitiated());
      const userIdentity = selectors.getUserIdentity(getState());
      await logoutFnP({ userIdentity });
      dispatch(actions.logoutSuccess());
    } catch (err) {
      dispatch(actions.logoutFailure());
      throw err;
    }
  };
