import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './DateRangePicker.module.scss';
import { DatePicker } from '../date-picker.2';
import { StatelessSelect } from '../select';

/**
 * @typedef {object} Option
 * @property {string} value
 * @property {string} label
 */

/**
 * @callback onSelectCb
 * @param {string} value selected option value
 */

/**
 * @callback onDateSelectCb
 * @param {object} date js Date
 */

/**
 * @callback onEndSelectCb
 * @param {object} date js Date
 */

/**
 * @param {object} props
 * @param {Array.<Option>} props.options
 * @param {Option} props.selected
 * @param {onSelectCb} props.onSelect
 * @param {onDateSelectCb} props.onStartSelect
 * @param {object} props.startDate js Date
 * @param {boolean} props.startHasError
 * @param {boolean} props.startDisabled
 * @param {onDateSelectCb} props.onEndSelect
 * @param {object} props.startDate js Date
 * @param {boolean} props.startHasError
 * @param {boolean} props.startDisabled
 * @param {string} props.region
 * @param {string} props.format
 * @param {boolean} props.disableSelect
 */

const DatePickerLabelWrapper = ({ hasLabel, labelName, className, children }) => {
  if (hasLabel) {
    return (
      <div className={className}>
        <label>{labelName}</label>
        {children}
      </div>
    );
  }
  return children;
};

const DateRangePicker = ({
  options,
  selected,
  onSelect,
  onStartSelect,
  startDate,
  startHasError,
  startDisabled,
  onEndSelect,
  endDate,
  endHasError,
  endDisabled,
  format,
  disableSelect,
  hasLabels,
  maxDate,
}) => (
  <div className={classnames(Styles.container)}>
    {!disableSelect && (
      <StatelessSelect
        className={Styles.select}
        selectedOption={selected}
        isClearable={false}
        options={options}
        onValueChange={onSelect}
      />
    )}
    {!disableSelect && <div style={{ width: '5px' }} />}
    <DatePickerLabelWrapper hasLabel={hasLabels} labelName="FROM">
      <DatePicker
        onSelect={onStartSelect}
        value={startDate}
        hasError={startHasError}
        disabled={startDisabled}
        format={format}
      />
    </DatePickerLabelWrapper>

    {!hasLabels && <div style={{ width: '5px' }} />}
    <DatePickerLabelWrapper hasLabel={hasLabels} labelName="TO" className={Styles.toDate}>
      <DatePicker
        onSelect={onEndSelect}
        value={endDate}
        hasError={endHasError}
        disabled={endDisabled}
        format={format}
        maxDate={maxDate}
      />
    </DatePickerLabelWrapper>
  </div>
);

DateRangePicker.displayName = 'DateRangePicker';

DateRangePicker.propTypes = {
  // period options
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onSelect: PropTypes.func,
  // start date props
  onStartSelect: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  startHasError: PropTypes.bool,
  startDisabled: PropTypes.bool,
  // end date props
  onEndSelect: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  endHasError: PropTypes.bool,
  endDisabled: PropTypes.bool,
  // global props
  format: PropTypes.string, // one of supportedFormats
  disableSelect: PropTypes.bool,
  hasLabels: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
};

DateRangePicker.defaultProps = {
  options: [],
  selected: undefined,
  onSelect: undefined,
  startDate: undefined,
  startHasError: false,
  startDisabled: false,
  endDate: undefined,
  endHasError: false,
  endDisabled: false,
  format: 'DD/MM/YYYY',
  disableSelect: false,
  hasLabels: false,
  maxDate: undefined,
};

export default DateRangePicker;
