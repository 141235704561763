import { createSelector } from 'reselect';

import { buildTrustChequesTable } from '@sb-billing/business-logic/cheques';
import { getList as getTrustCheques } from '@sb-billing/redux/trust-cheques';
import { getList as getTransactions } from '@sb-billing/redux/transactions';
import { getMap as getMatters, getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getMap as getContacts, getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getMap as getVendorPayments } from '@sb-billing/redux/vendor-payments';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { defaultPath } from './default-path';

/**
 * DISCUSS: This should probably be moved out into BillingAccountsTransactionsTrustCheques.container.jsx
 * as it doesn't work well with our redux feature pattern. Our feature pattern has an inherent
 * assumption that 'state' passed into any selectors is a slice of the full redux state for that 'feature'
 */
const trustChequesDataSelector = createSelector(
  () => getTrustCheques(),
  (_, t) => t,
  (state) => getState(state[defaultPath]), // get feature state
  () => getTransactions(),
  () => getVendorPayments(),
  () => getMatters(),
  () => getContacts(),
  (trustCheques, t, { sortBy, sortDirection }, transactions, vendorPayments) =>
    buildTrustChequesTable({
      transactions,
      trustCheques,
      vendorPayments,
      t,
      sortBy,
      sortDirection,
      getMatterDisplay: getMatterDisplayById,
      getContactDisplay,
      getPaymentById,
      getInvoiceNumberById,
    }),
);

function getState(state) {
  return state.trustCheques;
}

/**
 * @param {Object} state full redux state, this can't be the scoped feature state for now until trustChequesDataSelector is moved out of this feature
 */
function getTrustChequeData(state, t) {
  return trustChequesDataSelector(state, t);
}

export { getState, getTrustChequeData };
