import { updateCache as updateRedux } from '@sb-billing/redux/bank-account-settings';

angular
  .module('@sb-billing/services')
  .service('sbBankAccountSettingsService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    sbGenericCacheService.setupCache({
      name: 'sbBankAccountSettingsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'billing/bank-account-settings' },
        poll: 60,
        subscriptions: 'notifier.AccountsNotifications.BankAccountSettingsUpdated'
      },
      updateRedux
    });
  });
