module.exports = rtfToHTML;

let previousChunk = { style: {} };
let previousSpan = { style: {} };

function rtfToHTML(doc, options) {
  const defaults = {
    // Fonts arent very useful on the web, but we can try to preserve the styles for the backend later
    font: doc.style.font || { name: 'Times', family: 'roman' },
    fontSize: doc.style.fontSize || 24,
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
    foreground: { red: 0, blue: 0, green: 0 },
    background: { red: 255, blue: 255, green: 255 },
    firstLineIndent: doc.style.firstLineIndent || 0,
    indent: 0,
    align: 'left',
    valign: 'normal',
    list: false,
    paraBreaks: '',
    paraTag: 'span',
    ...(options || {}),
  };
  const content = doc.content
    .map((para, i) => (para.content ? renderPara(para, defaults, doc.content[i + 1]) : renderSpan(para, defaults)))
    .filter((html) => html != null)
    .join(defaults.paraBreaks);
  return content;
}

function font(ft) {
  const name = ft.name.replace(/-\w+$/, '');
  const family = genericFontMap[ft.family];
  const font = name + (family ? `, ${family}` : '');
  return font ? `font-family: ${font}` : '';
}

const genericFontMap = {
  roman: 'serif',
  swiss: 'sans-serif',
  script: 'cursive',
  decor: 'fantasy',
  modern: 'sans-serif',
  tech: 'monospace',
  bidi: 'serif',
};

function colorEq(aa, bb) {
  return aa.red === bb.red && aa.blue === bb.blue && aa.green === bb.green;
}

function styleTags(chunk, defaults) {
  let open = '';
  let close = '';
  let inlineCSS = '';
  let blockCSS = '';
  if (chunk.style.foreground && !colorEq(chunk.style.foreground, defaults.foreground)) {
    inlineCSS += `color: rgb(${chunk.style.foreground.red}, ${chunk.style.foreground.green}, ${chunk.style.foreground.blue});`;
  }
  if (chunk.style.background && !colorEq(chunk.style.background, defaults.background)) {
    inlineCSS += `background-color: rgb(${chunk.style.background.red}, ${chunk.style.background.green}, ${chunk.style.background.blue});`;
  }
  if (
    chunk.style.firstLineIndent &&
    chunk.style.firstLineIndent > 0 &&
    chunk.style.firstLineIndent !== defaults.firstLineIndent
  ) {
    blockCSS += `margin-left: ${((96 / 72) * chunk.style.firstLineIndent) / 20}px;`;
  }
  if (chunk.style.indent && chunk.style.indent !== defaults.indent) {
    blockCSS += `padding-left: ${((96 / 72) * chunk.style.indent) / 20}px;`;
  }
  if (chunk.style.align && chunk.style.align !== defaults.align) {
    blockCSS += `text-align: ${chunk.style.align};`;
  }
  if (chunk.style.fontSize && chunk.style.fontSize !== defaults.fontSize) {
    inlineCSS += `font-size: ${((96 / 72) * chunk.style.fontSize) / 2}px;`;
  }
  if (!defaults.disableFonts && chunk.style.font && chunk.style.font.name !== defaults.font.name) {
    inlineCSS += font(chunk.style.font);
  }
  if (blockCSS) {
    open += `<div style="${blockCSS}">`;
    close = `</div>${close}`;
  }
  if (inlineCSS) {
    open += `<span style="${inlineCSS}">`;
    close = `</span>${close}`;
  }
  if (chunk.style.italic && chunk.style.italic !== defaults.italic) {
    open += '<em>';
    close = `</em>${close}`;
  }
  if (chunk.style.bold && chunk.style.bold !== defaults.bold) {
    open += '<strong>';
    close = `</strong>${close}`;
  }
  if (chunk.style.strikethrough && chunk.style.strikethrough !== defaults.strikethrough) {
    open += '<s>';
    close = `</s>${close}`;
  }
  if (chunk.style.underline && chunk.style.underline !== defaults.underline) {
    open += '<u>';
    close = `</u>${close}`;
  }
  if (chunk.style.valign && chunk.style.valign !== defaults.valign) {
    if (chunk.style.valign === 'super') {
      open += '<sup>';
      close = `</sup>${close}`;
    } else if (chunk.style.valign === 'sub') {
      open += '<sup>';
      close = `</sup>${close}`;
    }
  }
  return { open, close };
}

function renderPara(para, defaults, peek) {
  if (!para.content || para.content.length === 0) return;
  if (para.calculatedStyle) {
    para.style = para.calculatedStyle;
  }
  // shortcut for empty paragraphs, useful since we dont want them messing with bullet/numbering tracking
  if (para.content.length === 1 && para.content[0].value === '\x0A') return '<br />';
  const tags = styleTags(para, defaults);
  const pdefaults = { ...defaults };
  for (const item of Object.keys(para.style)) {
    if (para.style[item] != null) pdefaults[item] = para.style[item];
  }
  let paraTag = defaults.paraTag;
  let listTagOpen = '';
  let listTagClose = '';

  if (para.style.list) {
    paraTag = 'li';
  }

  if (previousChunk.style.list !== para.style.list && para.style.list) {
    listTagOpen = `<${para.style.list}>`;
  }

  if (para.style.list && (!peek || peek.style.list !== para.style.list)) {
    listTagClose = `</${para.style.list}>`;
  }

  previousChunk = para;

  const content = para.content
    .map((span, i) => {
      if (span.content) {
        // nested content should use spans instead
        return renderPara(span, { ...defaults, paraTag: 'span' }, para.content[i + 1]);
      }
      return renderSpan(span, pdefaults);
    })
    .join('');

  return `${listTagOpen}<${paraTag}>${tags.open}${content}${tags.close}</${paraTag}>${listTagClose}`;
}

function renderSpan(span, defaults) {
  if (span.calculatedStyle) {
    span.style = span.calculatedStyle;
  }
  const tags = styleTags(span, defaults);
  if (span.value === '\x0A') {
    if (
      previousSpan.style.firstLineIndent ||
      (previousSpan.style.align && previousSpan.style.align !== defaults.align)
    ) {
      // remove last break since we need to use divs for block only CSS
      return '';
    }
    previousSpan = span;
    return '<br />';
  }

  previousSpan = span;
  return `${tags.open}${span.value}${tags.close}`;
}
