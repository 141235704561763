'use strict';

// Only files from interface should be exported here. Common files are used across the interface implementations and should be kept private.
const { getChargeDescription } = require('./interface/get-charge-description');
const { getPaymentFormConfiguration } = require('./interface/get-payment-form-configuration');
const { isPaymentProviderEnabledForBankAccount } = require('./interface/is-payment-provider-enabled-for-bank-account');
const { getPaymentSource } = require('./interface/get-payment-source');
const { extractFeeSchedule } = require('./interface/extract-fee-schedule');
const { firmStatuses } = require('./interface/firm-statuses');
const { paymentMethods } = require('./interface/payment-methods');
const { applicationStatuses } = require('./interface/application-statuses');
const { legalStructuresOptions, legalStructures } = require('./interface/legal-structures-options');
const { accountTypeOptions } = require('./interface/account-types');
const { getChargeErrorMessage } = require('./interface/get-charge-error-message');
const {
  isClientCardSavingEnabledForBankAccount,
} = require('./interface/is-client-card-saving-enabled-for-bank-account');
const { isFirmCardSavingEnabledForBankAccount } = require('./interface/is-firm-card-saving-enabled-for-bank-account');
const { getMinChargeAmountInCents } = require('./interface/get-min-charge-amount-in-cents');
const { extractChargeFields } = require('./interface/extract-charge-fields');
const { convertSettingsForGQL, convertSettingsFromGQL } = require('./interface/convert-settings-gql');

const strategies = require('./strategies');

module.exports = {
  getChargeDescription,
  getPaymentFormConfiguration,
  isPaymentProviderEnabledForBankAccount,
  getPaymentSource,
  extractFeeSchedule,
  firmStatuses,
  paymentMethods,
  applicationStatuses,
  legalStructuresOptions,
  legalStructures,
  accountTypeOptions,
  extractChargeFields,
  getChargeErrorMessage,
  isClientCardSavingEnabledForBankAccount,
  isFirmCardSavingEnabledForBankAccount,
  getMinChargeAmountInCents,
  convertSettingsForGQL,
  convertSettingsFromGQL,
  strategies,
};
