import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

export const bankAccountName = ({ bankAccount, feeWiseBankAccount, t }) => {
  if (!bankAccount) {
    if (!feeWiseBankAccount) {
      return null;
    }
    // Payouts from FW API has only FeeWise bank account id and we use currently linked FW-SB bank accounts to determine SB bank account.
    // Therefore, not always we have "bankAccount" details as it may be not linked anymore.
    // In such case, we at least try to display FW account info.
    if (feeWiseBankAccount.accountType === 'Office') {
      return t('operating');
    }

    return feeWiseBankAccount.accountName;
  }

  if (bankAccount && bankAccount.accountType === bankAccountTypeEnum.OPERATING) {
    return t('operating');
  }

  return getBankAccountName(bankAccount, t);
};
