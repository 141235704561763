import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getById,
  getList,
  isEditable,
} from '@sb-matter-management/redux/matters';

angular.module('@sb-matter-management/services').service('sbMattersMbService', function(sbGenericCacheService, sbEndpointType) {
  const that = this;
  sbGenericCacheService.setupCache({
    name: 'sbMattersMbService',
    sync: {
      endpoint: {
        type: sbEndpointType.SYNC_SINCE,
        stub: 'matter-management'
      },
      poll: 60,
      subscriptions: 'notifier.BrowseMattersNotifications.MatterUpdated'
    },
    updateRedux,
    clearRedux,
  });

  that.getAll = getList;
  that.getById = getById;
  that.isEditableMatter = isEditable;
});
