'use strict';

const _ = require('lodash');

module.exports = {
  search,
};

/**
 * Search an array/set of keyed objects, matching its string values against a search string.
 *
 * The search string will be broken into terms, then each term will be matched against each object property listed
 * in colKeys. If every term derived from the searchString is matched to at least one object property then the keyed
 * id for that object will be returned in the array.
 *
 * @param data the object to search
 * @param idKey the name of the key on each object which identifies it uniquely within the array.
 * @param colKeys the names of the object properties to match against the search string.
 * @param searchString the search sting to use for matching, this will be broken into terms.
 * @returns {Array} of keyed id's of the objects.
 */
function search (data, idKey, colKeys, searchString) {
  if (!data) {
    return [];
  }

  // split search string into non-case-sensitive regexes
  const reTerms = _.map(_.compact(_.words(searchString)), (term) => new RegExp(term, 'i'));
  // map aforementioned regexes into a list of matches
  const searchMatches = reTerms.map((reTerm) => mapSearchTermToMatches(reTerm, data, colKeys, idKey));

  //keep the matches that match ALL the search terms
  return _.intersection(...searchMatches);
}

// maps regex -> list of objects that match regex
function mapSearchTermToMatches (reTerm, rows, keys, idKey) {
  return Object.values(rows)
    .filter((row) => filterRow(row, keys, reTerm))
    .map((row) => row[idKey]);
}

// an object passes if it has a property value that matches the regex term reTerm
function filterRow (obj, keys, reTerm) {
  return keys
    .some((key) => reTerm.test(obj[key]));
}
