angular.module('@sb-billing/services').service('sbVoidInvoiceCommand', function (sbLoggerService, sbUuidService, sbInvoicingCacheManager, sbInvoiceEntriesService, sbInvoicingService) {
  'use strict';
  const that = this;

  const log = sbLoggerService.getLogger('sbVoidInvoiceCommand');

  that.executeP = executeP;

  function getOpdates (id, versionId) {
    const cacheInvoice = _.cloneDeep(sbInvoicingCacheManager.getById(id));
    cacheInvoice.currentVersion.versionId = versionId;
    cacheInvoice.versionIds = [versionId];
    cacheInvoice.currentVersion.status = 'VOID';
    cacheInvoice.optimistic = true;

    //update the entries
    const entriesChanges = sbInvoiceEntriesService.getInvoiceEntriesChangeset(cacheInvoice.currentVersion.entries, id);

    return {
      ...entriesChanges,
      sbInvoicingService: [cacheInvoice],
    };
  }

  function executeP (id, matterId) {
    log.info('executing void invoice command');
    const versionId = sbUuidService.get();
    return sbInvoicingService.voidInvoiceP(id, versionId, matterId, getOpdates(id, versionId));
  }

});
