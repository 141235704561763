import { subscriptionStatusByType } from '@sb-finance/business-logic/subscription/entities/constants';
import { getAccountId, getIsMissingProductClaim, getIsFirmOwner } from 'web/services/user-session-management';
import { fetchSmokeballSubscriptionRecord } from 'web/services/subscription';
import { initialiseLocalisationP } from './initialise-localisation';

export const checkForSubscriptionPaymentMethodUpdate = async ({ log, defaultTeardown }) => {
  log.info('Entered checkForSubscriptionPaymentMethodUpdate');
  const updatePaymentMethodRoute = 'update-payment-method';
  const urlParams = new URLSearchParams(window.location.search.replace('/', ''));

  // clear any url params used to force browser refresh (related to actions on update payment method form)
  const urlParamsToForceRefresh = ['payment-method-updated'];
  if (urlParamsToForceRefresh.some((urlParam) => urlParams.has(urlParam))) {
    urlParamsToForceRefresh.forEach((urlParam) => urlParams.delete(urlParam));
    window.location.href = `?${urlParams.toString()}/${window.location.hash}`;
  }

  // user has been redirected to #update-payment-method by logic below this clause
  if (window.location.hash === `#/${updatePaymentMethodRoute}`) {
    // Only allow the firm owner to updat the payment method.
    if (!getIsFirmOwner()) {
      urlParams.delete(updatePaymentMethodRoute); // delete it in case it's set
      window.location.href = `?${urlParams.toString()}/#/logout`;
  
      // required for the brief moment just before url redirection
      return { skipRouteRedirection: true, skipAuthorisation: true };
    }

    // If we get to here, we are going tp redirect the user to the update payment page, skipping
    // the rest of the bootstrap process. The l10n bootstrapping logic is required by the
    // update payment page, so we need to run it here outside the usual bootstrapper as we are
    // going to skip subsequent bootstrap steps.
    await initialiseLocalisationP({ log, defaultTeardown });

    // don't proceed with authorisation steps when user has been redirected to #/update-payment-method
    // route. i.e. user is authenticated (has a valid login) but their trial subscription may have
    // expired (status = past_due), so athough they are not authorised to use the app, they are allowed to
    // update their credit card payment method in order to activate their subscription
    return { skipRouteRedirection: false, skipAuthorisation: true };
  }

  // User has clicked on update-payment-method link in Stripe email, this could happen even with an active subscription
  // e.g. credit card expiry notification, change of credit card etc.
  if (urlParams.has('update-payment-method')) {
    // Only allow the firm owner to update the payment method.
    if (!getIsFirmOwner()) {
      urlParams.delete(updatePaymentMethodRoute); // delete it in case it's set
      window.location.href = `?${urlParams.toString()}/#/logout`;
  
      // required for the brief moment just before url redirection
      return { skipRouteRedirection: true, skipAuthorisation: true };
    }

    // redirected user to #update-payment-method route and make sure &update-payment-method
    // url param is removed so user is not redirected again
    urlParams.delete('update-payment-method');
    window.location.href = `?${urlParams.toString()}/#/${updatePaymentMethodRoute}`;

    // required for the brief moment just before url redirection
    return { skipRouteRedirection: true, skipAuthorisation: true };
  }

  // As part of BB-8650 to embed update CC payment method within the web app, we must allow
  // user to login even if their trial has expired (i.e. MISSING_PRODUCT_CLAIM).
  //
  // Previously we prevented login in that situation, instead we now use this block of code as a kind
  // of authorisation.
  //
  // To minimise the risk of blocking application access due to network failures, i.e. a call to the
  // subscription lambda failing, we only perform the authorisation check if no product claim is present
  // on the user token.
  if (!getIsMissingProductClaim()) {
    return { skipRouteRedirection: false, skipAuthorisation: false };
  }

  const subscription = await fetchSmokeballSubscriptionRecord({ accountId: getAccountId() });

  // if no subscription record, user did not come through self sign-up, no need to continue checking
  if (!subscription) {
    return { skipRouteRedirection: false, skipAuthorisation: false };
  }

  // log user out if subscription is not one of (trialing, active, past_due or unpaid)
  // This is the authorisation step which prevents application access if the subscription is not
  // in a supported status.
  const { TRIALING, ACTIVE, PAST_DUE, UNPAID } = subscriptionStatusByType;

  if (![TRIALING, ACTIVE, PAST_DUE, UNPAID].includes(subscription.status)) {
    urlParams.delete(updatePaymentMethodRoute); // delete it in case it's set
    window.location.href = `?${urlParams.toString()}/#/logout`;

    // required for the brief moment just before url redirection
    return { skipRouteRedirection: true, skipAuthorisation: true };
  }

  // If there is no product claim because the subscription is past due, redirect user to the update payment method page.
  if (subscription.status === subscriptionStatusByType.PAST_DUE) {
    // redirected user to #update-payment-method route and make sure &update-payment-method
    // url param is removed so user is not redirected again
    urlParams.delete(updatePaymentMethodRoute);
    window.location.href = `?${urlParams.toString()}/#/${getIsFirmOwner() ? updatePaymentMethodRoute : 'logout'}`;

    // required for the brief moment just before url redirection
    return { skipRouteRedirection: true, skipAuthorisation: true };
  }

  // We should have returned by now, but just for safety if we get this far, we will assume a valid
  // login. We probably should throw here but I'm leaving the code as is because I'm unsure if it
  // this scenario would ever legitimately get hit.
  return { skipRouteRedirection: false, skipAuthorisation: false };
};
