'use strict';

const { isPaymentProviderEnabledForBankAccount } = require('./is-payment-provider-enabled-for-bank-account');

/**
 * isFirmCardSavingEnabledForBankAccount
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings FeeWise settings as configured in firm settings
 * @param {string} params.bankAccountId The bank account ID to check for FeeWise card saving availability.
 * @returns {boolean}
 */
const isFirmCardSavingEnabledForBankAccount = ({ formattedProviderSpecificSettings, bankAccountId }) =>
  isPaymentProviderEnabledForBankAccount({ formattedProviderSpecificSettings, bankAccountId });

module.exports = {
  isFirmCardSavingEnabledForBankAccount,
};
