import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { Spinner } from '@sb-itops/react';

import { SubscriptionPaymentFormContainer } from './SubscriptionPaymentForm.container';

export const SubscriptionPaymentFormInStripeElements = ({
  region,
  setupIntent,
  stripePromise,
  stripeOptions,
  stripeProductId,
  subscriptionRecord,
  isUpgradeFromFreeTier,
  isBarBillFreeBoostTrial,
  priceInformation,
  onPaymentMethodUpdated,
  onPaymentMethodUpdateAbort,
}) => {
  const spinnerStyles = {
    display: 'flex',
    flex: 1,
    'margin-top': '10%',
    position: 'relative',
  };

  if (!stripePromise || !stripeOptions || !priceInformation) {
    return (
      <div style={spinnerStyles}>
        <Spinner message="Loading billing information" width={300} height={250} />
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <SubscriptionPaymentFormContainer
        region={region}
        setupIntent={setupIntent}
        stripeProductId={stripeProductId}
        subscriptionRecord={subscriptionRecord}
        isUpgradeFromFreeTier={isUpgradeFromFreeTier}
        isBarBillFreeBoostTrial={isBarBillFreeBoostTrial}
        priceInformation={priceInformation}
        onPaymentMethodUpdated={onPaymentMethodUpdated}
        onPaymentMethodUpdateAbort={onPaymentMethodUpdateAbort}
      />
    </Elements>
  );
};

SubscriptionPaymentFormInStripeElements.displayName = 'SubscriptionPaymentFormInStripeElements';

SubscriptionPaymentFormInStripeElements.propTypes = {
  region: PropTypes.string.isRequired,
  setupIntent: PropTypes.object,
  stripePromise: PropTypes.object,
  stripeOptions: PropTypes.object,
  subscriptionRecord: PropTypes.object,
  stripeProductId: PropTypes.string.isRequired,
  priceInformation: PropTypes.object,
  isUpgradeFromFreeTier: PropTypes.bool.isRequired,
  isBarBillFreeBoostTrial: PropTypes.bool.isRequired,
  isStripeSetupComplete: PropTypes.bool.isRequired,
  onPaymentMethodUpdated: PropTypes.func.isRequired,
  onPaymentMethodUpdateAbort: PropTypes.func.isRequired,
};

SubscriptionPaymentFormInStripeElements.defaultProps = {
  setupIntent: undefined,
  stripePromise: undefined,
  stripeOptions: undefined,
  subscriptionRecord: undefined,
  priceInformation: undefined,
};
