import { fetchGetP } from '@sb-itops/redux/fetch';
import { mapRelationship } from '../mappers';

export const clientSdkGetRelationships = async (matterId: string, roleId: string) => {
  const path = `/matter-management/matter-roles/relationships/:accountId/${matterId}/${roleId}`;

  const responseData = await fetchGetP({ path });

  const result = {
    versionId: responseData.body.versionId,
    relationships: responseData.body.relationships.map(mapRelationship),
  };

  return result;
};
