angular.module('sb.billing.webapp').config(function ($stateProvider) {
  $stateProvider
    .state('home.billing.accounts.evergreen-requests', {
      url: '/evergreen-requests?request&unpaid&hideMattersAboveMinimumThreshold&enableRequestOlderThanFilter&hideIfLastRequestOlderThanDays&enableReplenishAmountFilter&showIfReplenishAmountGreaterThanAmount',
      template: `
        <div style="width: 100%">
          <sb-evergreen-requests-view style="flex: 1"></sb-evergreen-requests-view>
        </div>
      `,
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
      },
    });
});
