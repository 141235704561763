import { cacheFactory, syncTypes } from '@sb-itops/redux';

import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'customer-billing-configuration',
  keyPath: 'entityId',
  ngCacheName: 'sbCustomerBillingConfigurationService',
  syncType: syncTypes.SINCE,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated, clearCache } = api;

export const getContactPaymentMethodById = ({ contactId, paymentMethodId }) =>
  (getById(contactId)?.paymentMethods || []).find((paymentMethod) => paymentMethodId === paymentMethod.token);

// eslint-disable-next-line import/no-cycle
export * from './save-customer-billing-configuration';
