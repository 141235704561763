import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

const onCreateEditMemo =
  (eventType) =>
  ({ commandMessage }) => {
    const optimisticMemo = {
      __typename: 'Memo',
      accountId: '',
      ...commandMessage,
    };

    const apolloClient = getApolloClient();

    const memoIdentifier = apolloClient.cache.identify(optimisticMemo);

    apolloClient.writeFragment({
      id: memoIdentifier,
      fragment: gql`
      fragment OpdateMemo on Memo {
        ${Object.keys(optimisticMemo).join('\n')}
      }
    `,
      data: {
        ...optimisticMemo,
      },
    });

    return {
      eventType,
      typeName: 'Memo',
      optimisticEntity: optimisticMemo,
    };
  };

export const onCreateMemo = onCreateEditMemo(apolloEntityOpdateEventTypes.ENTITY_ADDED);
export const onEditMemo = onCreateEditMemo(apolloEntityOpdateEventTypes.ENTITY_UPDATED);
