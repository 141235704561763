import { gql } from '@apollo/client';

const query = gql`
  query WebQueryContacts($contactIds: [ID]) {
    contactsWq(contactIds: $contactIds) {
      id
      type
      firstName
      lastName
      name
      cell
      email
    }
  }
`;

const notificationIds = ['CustomerManagementWebQuery'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const WebQueryContacts = {
  query,
  notificationIds,
};
