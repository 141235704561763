/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { rrulestr } from 'rrule';
import { capitalize } from '@sb-itops/nodash';
import { useTranslation } from '@sb-itops/react';
import { cleanHtml } from '@sb-itops/html';
import Styles from './AppointmentComponent.module.scss';

const el = document.createElement('div');
const Portal = ({ children }) => {
  const mount = document.getElementById('portal-root');

  useEffect(() => {
    if (!mount) {
      return () => {};
    }
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [mount]);

  return createPortal(children, el);
};

export const AppointmentComponent = ({ width = 300, space = 16, data }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<any>({ visible: false });
  const ref = useRef<HTMLDivElement>(null);
  const showTooltip = () => {
    if (!ref.current) {
      return;
    }
    // some math to align the tooltip with whatever you just hovered over (the 'target')
    const style: any = { width }; // this style object will be passed as the tooltip's 'style' prop
    const dimensions = ref.current.getBoundingClientRect(); // where on the screen is the target

    // center align the tooltip by taking both the target and tooltip widths into account
    style.left = dimensions.left;
    style.left = Math.max(space, style.left); // make sure it doesn't poke off the left side of the page
    style.left = Math.min(style.left, document.body.clientWidth - width - space); // or off the right

    if (dimensions.top < window.innerHeight / 2) {
      // when on the top half of the page, position the top of the tooltip just below the target (it will stretch downwards)
      style.top = dimensions.top + dimensions.height + space;
    } else {
      // when on the bottom half, set the bottom of the tooltip just above the top of the target (it will stretch upwards)
      style.bottom = window.innerHeight - dimensions.top + space;
    }

    setState({
      visible: true,
      style,
    });
  };

  const hideTooltip = () => {
    setState({ visible: false });
  };

  const { subject, matterDisplay, location, staff, startDate, endDate, description, rRULE, attendeeEntities } =
    data.targetedAppointmentData || data.appointmentData || {};

  let display = subject;
  if (matterDisplay) {
    display += ` (${matterDisplay})`;
  }
  if (location) {
    display += ` (${location})`;
  }

  return (
    <div
      onMouseOver={showTooltip}
      onFocus={showTooltip}
      onMouseOut={hideTooltip}
      onBlur={hideTooltip}
      className={`${Styles.tooltipTriggerText} tooltip-trigger-text`}
    >
      {staff.map(
        (s) =>
          s && (
            <div
              key={s.id}
              style={{ backgroundColor: `${s.color || s.colorFill || '#4990e6'}` }}
              className={Styles.boxShadow}
            />
          ),
      )}
      <span className={Styles.textOverflow} ref={ref}>
        {display}
      </span>
      {state.visible && (
        <Portal>
          <div className={Styles.tooltip} style={state.style}>
            <div className={Styles.row}>
              <div className={Styles.title}>Subject:</div>
              <div>{subject}</div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.title}>Location:</div>
              <div>{location}</div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.title}>Matter:</div>
              <div>{matterDisplay}</div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.title}>Start:</div>
              <div>{t('date', { date: startDate, format: 'ddd DD/MM/YYYY hhmm A' })}</div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.title}>End:</div>
              <div>{t('date', { date: endDate, format: 'ddd DD/MM/YYYY hhmm A' })}</div>
            </div>
            {rRULE && (
              <div className={Styles.row}>
                <div className={Styles.title}>Recurrence:</div>
                <div>{capitalize(rrulestr(rRULE).toText())}</div>
              </div>
            )}
            <div className={classnames(Styles.row)}>
              <div className={Styles.title}>Staff:</div>
              <div className={Styles.staffMembers}>{staff.map((i) => i?.name).join('\n')}</div>
            </div>
            {!!attendeeEntities?.length && (
              <div className={classnames(Styles.row)}>
                <div className={Styles.title}>Parties:</div>
                <div className={Styles.staffMembers}>{attendeeEntities.map((i) => i?.displayName).join('\n')}</div>
              </div>
            )}
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: cleanHtml({ dirtyHtml: description }) }}
            />
          </div>
        </Portal>
      )}
    </div>
  );
};
