import { getTotalsCents } from '@sb-billing/redux/invoice-totals';
import { getInvoiceSummariesByFilter } from '@sb-billing/redux/invoices';

angular.module('sb.billing.webapp').component('sbDataInvoiceSummariesByTypeAndId',
  {
    require: {
      sbComposeCtrl: '^sbCompose'
    },
    bindings: {entityId: '<', entityType: '@', invoiceSummariesDataKey: '@', invoiceSummariesDataKeyIds: '@', invoiceSummariesDataKeyNoFiltersIds: '@', dateTo: '<', dateFrom:'<'},
    controller: function ($scope, sbLoggerService) {
      const that = this;
      const log = sbLoggerService.getLogger('sbDataInvoiceSummariesByTypeAndId');
      that.invoiceSummariesDataKey = that.invoiceSummariesDataKey || 'invoiceSummaries';
      that.invoiceSummariesDataKeyIds = that.invoiceSummariesDataKeyIds || 'invoiceSummariesIds';
      that.invoiceSummariesDataKeyNoFiltersIds = that.invoiceSummariesDataKeyNoFiltersIds || 'invoiceSummariesNoFiltersIds';

      $scope.$on('smokeball-data-update-sbInvoicingService', updateData);
      $scope.$on('smokeball-data-update-sbInvoiceTotalsService', updateData);
      $scope.$on('smokeball-saved-invoices', updateData);

      that.$onChanges = () => {
        updateData();
      };

      function updateData() {
        if (isValidBindings()) {
          log.info('fetching invoice summaries for entity type : ' + that.entityType + ' and entityId ' + that.entityId + ' and status : ' + that.selectedInvoiceStatus);
          fetchInvoiceSummaries();
        }
      }

      function isValidBindings() {
        if (!_.isEmpty(that.entityType) && _.isEmpty(that.entityId)) {
          log.error('failed to fetch invoice summaries data, entity ID is required when entity type is supplied. ');
          return false;
        }
        if (!_.isEmpty(that.entityType) && !_.includes(['debtor', 'matter'], that.entityType)) {
          log.error('failed to fetch invoice summaries data, entity type is invalid,  [ none, debtor, matter ]');
          return false;
        }
        return true;
      }

      function fetchInvoiceSummaries() {
        const options = {
          matterId: that.entityType === 'matter' ? that.entityId : undefined,
          debtorId: that.entityType === 'debtor' ? that.entityId : undefined,
          startDate: that.dateFrom ? +that.dateFrom : undefined,
          endDate: that.dateTo ? +that.dateTo : undefined,
        };

        const invoiceSummaries = getInvoiceSummariesByFilter(options);
        setResultIds(that.invoiceSummariesDataKeyNoFiltersIds, invoiceSummaries);

        const summariesWithTotals = invoiceSummaries.map((invoiceSummary) => ({
          ...invoiceSummary,
          ...getTotalsCents(invoiceSummary.invoiceId),
        }));

        setResults(that.invoiceSummariesDataKey, summariesWithTotals);
        setResultIds(that.invoiceSummariesDataKeyIds, summariesWithTotals);
      }

      function setResults(key, invoiceSummaries) {
        log.info('invoiceSummaries : ', invoiceSummaries);
        log.info('set for key : ' + that.invoiceSummariesDataKey);
        that.sbComposeCtrl.setComposeData({
          dataType: key,
          data: invoiceSummaries
        }, key);
      }

      function setResultIds(key, invoiceSummaries){
        //We also want to record an array of just the invoice ID's
        //this is used to filter payments related to the invoice
        that.sbComposeCtrl.setComposeData({
          dataType: key,
          data: _.map(invoiceSummaries, 'invoiceId')
        }, key);
      }

    }

  }
);
