'use strict';

import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { fetchById as fetchContactById } from '@sb-customer-management/redux/contacts';

angular.module('sb.billing.webapp').component('sbDataContactById', {
    require: { sbComposeCtrl: '^sbCompose' },
    bindings: {contactId: '<', composeDataKey: '<?', fetchRelatedEntities: '@'},
    controller: function ($scope, sbLoggerService, sbContactsMbService) {
      const that = this;
      const log = sbLoggerService.getLogger('sbDataContactById');

      that.$onChanges = async (changesObj) => {
        that.contactId = changesObj.contactId && changesObj.contactId.currentValue;
        that.composeDataKey = changesObj.composeDataKey && changesObj.composeDataKey.currentValue;
        that.fetchRelatedEntities = changesObj.fetchRelatedEntities && changesObj.fetchRelatedEntities.currentValue;
        if(that.contactId){
          update();
          await updateRelatedEntities();
        }
      };

      function update() {
        log.info(`fetching contact data for id : ${that.contactId}`);
        const contact = that.contactId && sbContactsMbService.getById(that.contactId);

        if(contact){
          that.representativeOf = contact.representativeOf;// keep both for backwards compatability
          that.representativeOfs = contact.representativeOfs;
          log.info('fetched contact : ', contact);
          that.sbComposeCtrl.setComposeData({ dataType: 'contact', data: { ...contact, displayName: getContactDisplay(contact.entityId) }}, that.composeDataKey || 'contact');
        }
      }

      $scope.$on('smokeball-data-update-sbContactsMbService', () => {
        update();
      });
      $scope.$on('smokeball-data-update-sbSimpleContactMbService', () => {
        updateRelatedEntities();
      });

      async function updateRelatedEntities() {
        if (!_.isEmpty(that.fetchRelatedEntities) && Boolean(that.fetchRelatedEntities)) {
          const contactDetailsEntity = await fetchContactById(that.contactId);
          const relatedEntities = {
            company: getDetailsEntity(contactDetailsEntity, 'company'),
            organisation: getDetailsEntity(contactDetailsEntity, 'organisation'),
            groupOfPeople: getDetailsEntity(contactDetailsEntity, 'company'),
            partnership: getDetailsEntity(contactDetailsEntity, 'partnership'),
            person: getDetailsEntity(contactDetailsEntity, 'person'),
            soleProprietor: getDetailsEntity(contactDetailsEntity, 'soleProprietor'),
            trust: getDetailsEntity(contactDetailsEntity, 'trust'),
            people: getDetailsEntity(contactDetailsEntity, 'people'),
            organizations: await getOrganisationEntities(),
          };
          that.sbComposeCtrl.setComposeData({
            dataType: 'contactRelatedEntities',
            data: relatedEntities
          }, getRelatedEntitiesKey());
        }
      }

      function getDetailsEntity(contactDetailsEntity, key){
        if (!_.isEmpty(contactDetailsEntity) && !_.isEmpty(contactDetailsEntity[key])) {
          return contactDetailsEntity[key];
        }
      }

      async function getOrganisationEntities() {
        let contactOrganizationEntities = [];

        //check both for backwards compatability 
        if (that.representativeOfs && that.representativeOfs.length) {
          contactOrganizationEntities = that.representativeOfs.map(fetchContactById);
        } else {
          if (that.representativeOf) {
            contactOrganizationEntities.push(fetchContactById(that.representativeOf));
          }
        }
        log.info('fetched organization contact entities: ', contactOrganizationEntities);
        return Promise.all(contactOrganizationEntities);
      }

      function getRelatedEntitiesKey() {
        return that.composeDataKey ? `${that.composeDataKey}RelatedEntities` :  'contactRelatedEntities';
      }

    }
  }
);
