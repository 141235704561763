'use strict';

const { buttonColours, buttonStyles, createButton } = require('@sb-itops/email');

const createViewInvoiceButton = ({
  url,
  label,
  colour = buttonColours.GREEN,
  style = buttonStyles.SOLID,
  invoiceCount = 1,
}) => {
  const finalLabel = label || getDefaultLabel(invoiceCount);

  const viewInvoiceButton = createButton({
    url,
    label: finalLabel,
    colour,
    style,
  });
  return viewInvoiceButton;
};

const getDefaultLabel = (invoiceCount) => `VIEW INVOICE${invoiceCount !== 1 ? 'S' : ''}`;

module.exports = {
  createViewInvoiceButton,
};
