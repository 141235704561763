import { regionType, getRegion } from '@sb-itops/region';

const regionStrategy = {
  [regionType.AU]: {
    matterNumberRequired: true,
    matterDescriptionRequired: true,
  },
  [regionType.GB]: {
    matterNumberRequired: true,
    matterDescriptionRequired: true,
  },
  [regionType.US]: {
    matterNumberRequired: false,
    matterDescriptionRequired: false,
  },
};

export const matterFieldsRegionStrategy = regionStrategy[getRegion()];
