import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { todayAsInteger } from '@sb-itops/date';
import { connect } from 'react-redux';

import CloseReopenControlledMoneyAccountForm from './CloseReopenControlledMoneyAccountForm';

const mapStateToProps = (state, { scope }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formSubmitting, submitFailed } = formSelectors.getFormState(state);
  const { stateChangeDate, matterId, reason, internalNote } = formFields;

  return {
    scope,
    stateChangeDate,
    matterId,
    reason,
    internalNote,
    formDisabled: formSubmitting,
    formInitialised,
    submitFailed,
  };
};

const mapDispatchToProps = (dispatch, { scope, matterId }) => {
  const { actions: formActions } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const fieldValues = {
        stateChangeDate: todayAsInteger(),
        matterId,
        reason: undefined,
        internalNote: undefined,
      };
      dispatch(formActions.initialiseForm({ fieldValues }));

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
    },
  };
};

const CloseReopenControlledMoneyAccountFormContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(CloseReopenControlledMoneyAccountForm)),
);

CloseReopenControlledMoneyAccountFormContainer.displayName = 'CloseReopenControlledMoneyAccountFormContainer';

CloseReopenControlledMoneyAccountFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  lastOpenDate: PropTypes.number.isRequired,
  matterId: PropTypes.string.isRequired,
  validateForm: PropTypes.func.isRequired,
  isCloseAccount: PropTypes.bool.isRequired,
};

export default CloseReopenControlledMoneyAccountFormContainer;
