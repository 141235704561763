import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getList as getTransactions } from '@sb-billing/redux/transactions';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortDirectionFeature from '@sb-itops/redux/sort';

import * as billingAccountsTransactions from 'web/redux/route/home-billing-accounts-transactions';

import { FirmCreditNoteLedger } from './FirmCreditNoteLedger';

const findCreditTransactions = (showDeletedTransactions) => {
  const creditTransactions = getTransactions().filter((transaction) => {
    const bankAccount = getBankAccountById(transaction.bankAccountId);

    if (showDeletedTransactions) {
      return bankAccount && bankAccount.accountType === 'CREDIT';
    }

    return bankAccount && bankAccount.accountType === 'CREDIT' && !transaction.isHidden;
  });
  return creditTransactions;
};

const getFirmCreditNoteLedgerFeature = (state) => {
  const FEATURE_SCOPE = 'firm-credit-note-ledger';
  return withScopedFeatures({ state, scope: FEATURE_SCOPE })({
    sortDirection: sortDirectionFeature,
  });
};

const mapStateToProps = (state, { onClickLink }) => {
  const { sortDirection: firmCreditNotesSortDirection } = getFirmCreditNoteLedgerFeature(state);
  const sortBy = firmCreditNotesSortDirection.selectors.getSortBy() || undefined;
  const sortDirection = firmCreditNotesSortDirection.selectors.getSortDirection() || undefined;
  const { showHidden } = billingAccountsTransactions.selectors.getFilters(state);
  const showDeletedTransactions = showHidden;

  const transactions = findCreditTransactions(showDeletedTransactions);
  return {
    transactions,
    showMatterColumn: true,
    showContactColumn: true,
    sortDirection,
    sortBy,
    onClickLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { sortDirection: firmCreditNotesSortDirection } = getFirmCreditNoteLedgerFeature();
  return {
    onSort: ({ sortDirection, sortBy }) => {
      dispatch(firmCreditNotesSortDirection.actions.setSortDirection({ sortDirection }));
      dispatch(firmCreditNotesSortDirection.actions.setSortBy({ sortBy }));
    },
  };
};

export const FirmCreditNoteLedgerContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(FirmCreditNoteLedger),
);

FirmCreditNoteLedgerContainer.displayName = 'FirmCreditNoteLedgerContainer';

FirmCreditNoteLedgerContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};

FirmCreditNoteLedgerContainer.defaultProps = {};
