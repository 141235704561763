import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { CreditNotePaymentDetailsModal } from '../credit-note-details';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

class PaymentTable extends React.Component {
  constructor(props) {
    super(props);
    this.payorCellRenderer = this.payorCellRenderer.bind(this);
    this.state = {
      showCreditPaymentDetailsModal: false,
      paymentId: '',
    };
  }

  payorCellRenderer({ rowData }) {
    const { payor } = rowData;
    const { onClickLink } = this.props;
    return payor && <ContactDisplay contactId={payor.entityId} onClickLink={onClickLink} asLink />;
  }

  render() {
    const { payments, onRowClick, onClickLink, showReferenceColumn } = this.props;

    const onCloseCreditPaymentDetailsModal = () =>
      this.setState({ showCreditPaymentDetailsModal: false, paymentId: '' });

    const onClickRow = ({ rowData }) => {
      if (rowData.source === 'Credit') {
        this.setState({ showCreditPaymentDetailsModal: true });
        this.setState({ paymentId: rowData.paymentId });
      } else {
        onRowClick({ rowData });
      }
    };

    return (
      <>
        {this.state.showCreditPaymentDetailsModal && this.state.paymentId && (
          <CreditNotePaymentDetailsModal
            paymentId={this.state.paymentId}
            onClose={onCloseCreditPaymentDetailsModal}
            onClickLink={onClickLink}
            isVisible={this.state.showCreditPaymentDetailsModal}
          />
        )}
        <Table className="payment-table" onRowClick={onClickRow} list={payments}>
          <Column
            dataKey="effectiveDate"
            width={100}
            label="Date"
            cellRenderer={yyyymmddLocalisedRenderer}
            disableSort
          />
          {showReferenceColumn && <Column dataKey="reference" label="Reference" flexGrow={1} disableSort />}
          <Column className="note" dataKey="note" label="Note" flexGrow={3} disableSort />
          <Column dataKey="payor" label="Paid By" cellRenderer={this.payorCellRenderer} flexGrow={3} disableSort />
          <Column className="source" dataKey="source" label="Source" flexGrow={1} disableSort />
          <Column
            className="right-align"
            dataKey="amount"
            label="Amount"
            cellRenderer={balanceCellLocalisedRenderer}
            flexGrow={1}
            disableSort
            width={100}
          />
          <Column
            className="right-align"
            dataKey="balance"
            label="Balance"
            cellRenderer={balanceCellLocalisedRenderer}
            flexGrow={1}
            disableSort
            width={100}
          />
        </Table>
      </>
    );
  }
}

PaymentTable.defaultProps = {
  payments: [],
  onRowClick: undefined,
};

PaymentTable.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  payments: PropTypes.array,
  onRowClick: PropTypes.func,
  showReferenceColumn: PropTypes.bool.isRequired,
};

export default PaymentTable;
