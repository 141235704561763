const uuid = require('@sb-itops/uuid');

let subscriptions = [];

const eventTypes = Object.freeze({
  ENTITY_ADDED: 'ENTITY_ADDED',
  ENTITY_UPDATED: 'ENTITY_UPDATED',
  ENTITY_REMOVED: 'ENTITY_REMOVED',
});

/**
 * Subscribes the passed function to optimistic entity updates made within the apollo cache.
 * Registered subscription functions will be called in the order which they are registered.
 *
 * @param {Object} params
 * @param {function} params.onEntityOpdated
 * @return {function} Function which, when called, will unsubscribe the event handler.
 */
const subscribeToApolloEntityOpdateEvents = ({ onEntityOpdated }) => {
  if (typeof onEntityOpdated !== 'function') {
    throw new Error('Failed to create subscription: the passed onEntityOpdated parameter must be a valid function');
  }

  const subscriptionId = uuid();

  subscriptions.push({
    id: subscriptionId,
    onEntityOpdated,
  });

  const unsubscribe = () => {
    const subscriptionIndex = subscriptions.findIndex((subscription) => subscription.id === subscriptionId);
    subscriptions.splice(subscriptionIndex, 1);
  };

  return unsubscribe;
};

/**
 * Broadcasts an apollo entity opdate event to all subscribed listeners.
 *
 * @param {Object} params
 * @param {string} params.eventType The type of entity event that occurred.
 * @param {string} params.typeName The __typename value for the entity which was opdated.
 * @param {Object} params.optimisticEntity The entity that was optimistically updated in the apollo cache.
 */
const broadcastApolloEntityOpdateEvent = ({ eventType, typeName, optimisticEntity }) => {
  if (!eventType || typeof eventType !== 'string') {
    throw new Error(
      'Failed to broadcast apollo entity opdate event: the passed eventType must be a non-zero length string',
    );
  }

  if (!eventTypes[eventType]) {
    throw new Error(
      `Failed to broadcast apollo entity opdate event: the passed eventType must be one of [${Object.keys(
        eventTypes,
      ).join(',')}], received ${eventType}`,
    );
  }

  if (!typeName || typeof typeName !== 'string') {
    throw new Error(
      'Failed to broadcast apollo entity opdate event: the passed typeName must be a non-zero length string',
    );
  }

  if (!optimisticEntity || typeof optimisticEntity !== 'object') {
    throw new Error(
      'ailed to broadcast apollo entity opdate event: the passed optimisticEntity must be a valid object',
    );
  }

  subscriptions.forEach((subscription) => {
    subscription.onEntityOpdated({ eventType, typeName, optimisticEntity });
  });
};

/**
 * Unsubscribes all subscribed listeners.
 */
const clearAllApolloEntityOpdateSubscriptions = () => {
  subscriptions = [];
};

module.exports = {
  apolloEntityOpdateEventTypes: eventTypes,
  subscribeToApolloEntityOpdateEvents,
  broadcastApolloEntityOpdateEvent,
  clearAllApolloEntityOpdateSubscriptions,
};
