import React from 'react';
import PropTypes from 'prop-types';
import triConveyIcon from './triconvey-icon.svg';
import Styles from './TriConveySpinner.module.scss';

export const TriConveySpinner = ({ message }) => (
  <div className={Styles.triConveySpinner}>
    <div className={Styles.iconContainer}>
      <img className={Styles.logoImage} src={triConveyIcon} alt="TriConvey" />
    </div>
    <div className={Styles.messageContainer}>{message}</div>
  </div>
);

TriConveySpinner.displayName = 'TriConveySpinner';

TriConveySpinner.propTypes = {
  message: PropTypes.string,
};

TriConveySpinner.defaultProps = {
  message: undefined,
};

export default TriConveySpinner;
