'use strict';

/**
 * build the files array in Communicate notification for attachments of the sending message
 * @param {Object} params
 * @param {String} params.uploadId this will be the filename in Communicate tmp bucket, Communicate will use this to lookup the file
 * @param {String} params.fileName this is the filename will be displayed on the Communicate message attachment
 * @param {String} params.fileSize Communicate use this filesize to determine if a preview thumbnail will be displayed for the attachment
 * @returns {Array} array of object for the files which will be attached on the Communicate message
 */
function buildFilesForCommunicateMessageAttachments({ uploadId, fileName, fileSize }) {
  return [
    {
      uploadId,
      originId: 'GEN',
      name: fileName,
      size: fileSize,
    },
  ];
}

module.exports = {
  buildFilesForCommunicateMessageAttachments,
};
