angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/entries';

  $stateProvider

  .state('home.billing.entries', {
    url: '/entries',
    templateUrl: BASE_DIRECTORY + '/entries.html',
    controller: 'EntriesController',
    controllerAs: 'entries',
    abstract: true,
    data: {
      authorized: true,
      navHighlights: ['billing', 'entries']
    }
  });

});
