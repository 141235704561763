import React from 'react';
import PropTypes from 'prop-types';
import RemindersGeneralFilter from './RemindersGeneralFilter';

class RemindersGeneralFilterBridge extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    reset: this.props.reset,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.reset !== prevState.reset && nextProps.reset === true) {
      return { reset: true };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.reset === true) {
      this.resetState();
    }
  }

  resetState() {
    this.setState({ reset: false }, () => {
      this.filterRef.current.reset();
      if (this.props.onResetApplied) {
        this.props.onResetApplied();
      }
    });
  }

  filterRef = React.createRef();

  render() {
    return <RemindersGeneralFilter ref={this.filterRef} {...this.props} />;
  }
}

RemindersGeneralFilterBridge.propTypes = {
  overdueAmount: PropTypes.number,
  overdueAmountChecked: PropTypes.bool,
  reset: PropTypes.bool,
  sentRemindersOnly: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
  onResetApplied: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
};

RemindersGeneralFilterBridge.defaultProps = {
  overdueAmount: undefined,
  overdueAmountChecked: false,
  reset: false,
  sentRemindersOnly: undefined,
  onResetApplied: undefined,
};

export default RemindersGeneralFilterBridge;
