import React from 'react';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';

import { connect } from 'react-redux';
import store from '@sb-itops/redux/store';
import { withReduxStore } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getRegion } from '@sb-itops/region';
import { getSchemeValue } from '@sb-itops/region-schemes';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { getCurrentContacts } from '@sb-customer-management/redux/contacts-summary';
import { fetchById as fetchContactById } from '@sb-customer-management/redux/contacts';
import { getById as getLedesClientIdByCustomerId } from '@sb-billing/redux/customer-billing-configuration';
import { mapAddressFormFields } from '@sb-customer-management/business-logic/contacts/services/address-search';
import moment from 'moment';
import { featureActive } from '@sb-itops/feature';
import { CreateEditContactSchema } from './CreateEditContactSchema';
import CreateEditContactForm from './CreateEditContactForm';

const region = getRegion();
const log = getLogger('CreateEditContactFormContainer');

const mapStateToProps = (state, { scope }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formSubmitting, submitFailed } = formSelectors.getFormState(state);

  const { contactType, personFields, addressFields, companyFields, trustFields, bankAccountFields } = formFields;

  const allContacts = getCurrentContacts();
  const peopleOptions = allContacts
    .filter((contact) => contact.type === 'Person')
    .map((contact) => ({
      value: contact,
      label: contact.displayName + (contact.email ? ` (${contact.email})` : ''),
    }))
    .sort((a, b) => (a?.label || '').localeCompare(b?.label));
  const companyOptions = allContacts
    .filter((contact) => contact.type === 'Organisation')
    .map((contact) => ({
      value: contact.entityId,
      label: contact.displayName,
    }))
    .sort((a, b) => (a?.label || '').localeCompare(b?.label));

  return {
    scope,
    contactType,
    formDisabled: formSubmitting,
    formInitialised,
    region,
    personFields,
    addressFields,
    companyFields,
    trustFields,
    bankAccountFields,
    submitFailed,
    companyOptions,
    peopleOptions,
  };
};

const mapContactToForm = (contactDetails, contactType, initialOrg) => {
  let entity;
  let addressEntity;

  switch (contactType) {
    case contactTypes.PERSON:
      entity = contactDetails?.person;
      addressEntity = entity?.residentialAddress;
      break;
    case contactTypes.COMPANY:
      entity = contactDetails?.company || contactDetails?.organisation;
      addressEntity = entity?.businessAddress;
      break;
    case contactTypes.TRUST:
      entity = contactDetails?.trust;
      addressEntity = entity?.address;
      break;
    default:
      throw new Error(`Unable to find matching contact Type. contactType:${contactType}`);
  }

  return {
    bankAccountFields: {
      accountName: '',
      accountNumber: '',
      bankBranchNumber: '',
      bankName: '',
    },
    personFields: {
      title: entity?.title || '',
      firstName: entity?.firstName || '',
      lastName: entity?.lastName || '',
      phoneAreaCode: entity?.phone?.areaCode || '',
      phoneNumber: entity?.phone?.localNumber || '',
      email: entity?.email || '',
      cellAreaCode: entity?.cell?.areaCode || '',
      cellNumber: entity?.cell?.localNumber || '',
      birthDate: featureActive('NUCWEB-675') ? entity?.birthDate || '' : undefined,
      linkedCompany: entity?.assosciatedOrganizations?.[0]?.organizationId || initialOrg || undefined,
      ledesClientId: getLedesClientIdByCustomerId(contactDetails?.id)?.ledesClientId || '',
    },
    companyFields: {
      companyName: entity?.name || '',
      companyType: entity?.type || '',
      companyPhoneAreaCode: entity?.phone?.areaCode || '',
      companyPhoneNumber: entity?.phone?.localNumber || '',
      companyEmail: entity?.email || '',
      companyFax: entity?.fax?.localNumber || '',
      companyFaxAreaCode: entity?.fax?.areaCode || '',
      companyLedesClientId: getLedesClientIdByCustomerId(contactDetails?.id)?.ledesClientId || '',
    },
    trustFields: {
      trustName: entity?.name || '',
      trustStatus: entity?.status || '',
      trustPhoneAreaCode: entity?.phone?.areaCode || '',
      trustPhoneNumber: entity?.phone?.localNumber || '',
      trustNumber: entity?.trustNumber || '',
      trustDate: (entity?.trustDate && moment(entity?.trustDate).toDate()) || '',
      trustees: (entity?.trustees?.length && entity?.trustees) || [{ name: '', type: '' }],
      trustFax: entity?.fax?.localNumber || '',
      trustFaxAreaCode: entity?.fax?.areaCode || '',
      trustLedesClientId: getLedesClientIdByCustomerId(contactDetails?.id)?.ledesClientId || '',
    },
    addressFields: {
      buildingLevel: addressEntity?.detailedStreetAddress?.buildingLevel || '',
      unitNumber: addressEntity?.detailedStreetAddress?.unitNumber || '',
      unitType: addressEntity?.detailedStreetAddress?.unitType || '',
      streetNumber: addressEntity?.detailedStreetAddress?.streetNumber || '',
      streetName: addressEntity?.detailedStreetAddress?.streetName || '',
      streetType: addressEntity?.detailedStreetAddress?.streetType || '',
      addressLine1: addressEntity?.addressLine1 || '',
      addressLine2: addressEntity?.addressLine2 || '',
      city: addressEntity?.city || '',
      state: addressEntity?.state || '',
      zipCode: addressEntity?.zipCode || '',
      county: addressEntity?.county || '',
      locality: addressEntity?.locality || '',
      country: addressEntity?.country || '',
    },
  };
};

const mapDispatchToProps = (dispatch, { scope, contactId, initialOrg }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      // If no contactId is supplied this is a create form so simply initialise with empty strings
      const fetchContactP = contactId ? fetchContactById(contactId) : Promise.resolve();
      fetchContactP
        .then((contact) => {
          let contactType = contactTypes.PERSON;
          if (contact?.company || contact?.organisation) {
            contactType = contactTypes.COMPANY;
          } else if (contact?.trust) {
            contactType = contactTypes.TRUST;
          }
          const contactFormValues = mapContactToForm(contact, contactType, initialOrg);
          dispatch(
            formActions.initialiseForm({
              fieldValues: {
                region,
                contactType,
                ...contactFormValues,
              },
            }),
          );
          return undefined;
        })
        .catch((err) => log.error(err));

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
    },
    onFieldValueSet: (field, value) => {
      dispatch(formActions.setFieldValue({ field, value }));
    },
    deleteField: (field) => {
      dispatch(formActions.deleteField({ field }));
    },
    validateForm: () => {
      dispatch(formOperations.validateSchema({ schema: CreateEditContactSchema(getRegion()) }));
    },
    onRadioChange: (clickedButton) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { contactType: clickedButton.id } }));
    },
    onAddressAutocompleteSelected: (addressInfo) => {
      const addressFields = mapAddressFormFields(addressInfo, getSchemeValue('addressScheme'));
      dispatch(formActions.updateFieldValues({ fieldValues: { addressFields } }));
    },
  };
};

const CreateEditContactFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(
      ({
        onFieldValueUpdated,
        formDisabled,
        onRadioChange,
        contactType,
        submitFailed,
        formInitialised,
        validateForm,
        deleteField,
        ...props
      }) => (
        <CreateEditContactForm
          {...props}
          phoneNumberScheme={getSchemeValue('phoneNumberScheme')}
          addressScheme={getSchemeValue('addressScheme')}
          contactType={contactType?.value}
          onFieldValueUpdated={onFieldValueUpdated}
          deleteField={deleteField}
          formDisabled={formDisabled}
          onRadioChange={onRadioChange}
          submitFailed={submitFailed}
          formInitialised={formInitialised}
          validateForm={validateForm}
        />
      ),
    ),
  ),
);
export const formDisabled = ({ scope }) => {
  const { selectors } = withScopedFeature({ scope })(forms);
  const { formSubmitting } = selectors.getFormState(store.getState());

  return formSubmitting;
};
CreateEditContactFormContainer.displayName = 'CreateEditContactFormContainer';

CreateEditContactFormContainer.propTypes = {
  contactId: PropTypes.string,
  initialOrg: PropTypes.string,
  isUtbmsEnabled: PropTypes.bool,
  showBankDetailsTab: PropTypes.bool,
  isNewUI: PropTypes.bool,
};

CreateEditContactFormContainer.defaultProps = {
  contactId: undefined,
  initialOrg: undefined,
  isUtbmsEnabled: undefined,
  showBankDetailsTab: undefined,
  isNewUI: undefined,
};

export default CreateEditContactFormContainer;
