import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { sort as sortItems } from '@sb-itops/sort';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { PaymentInvoicesTable } from './PaymentInvoicesTable';

const mapStateToProps = (state, { paymentIds, onClickLink, t }) => {
  const unsortedInvoicePayments = paymentIds.reduce((acc, paymentId) => {
    const payment = getPaymentById(paymentId);

    if (!payment || !payment.invoices.length > 0) {
      return acc;
    }

    payment.invoices.forEach(({ invoiceId, amount }) => {
      const bankAccount = getBankAccountById(payment.sourceAccountId);

      acc.push({
        paymentId,
        matterId: payment.matterId,
        payorId: payment.payorId,
        invoiceId,
        invoiceNumber: getInvoiceNumberById(invoiceId),
        amount,
        source: getBankAccountName(bankAccount, t),
      });
    });

    return acc;
  }, []);

  const invoicePayments = sortItems(unsortedInvoicePayments, ['invoiceNumber'], ['ASC']);

  return {
    invoicePayments,
    showPayor: isMatterContactBalanceType(),
    onClickLink,
  };
};

export const PaymentInvoicesTableContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps)(PaymentInvoicesTable)),
);

PaymentInvoicesTableContainer.displayName = 'PaymentInvoicesTableContainer';

PaymentInvoicesTableContainer.propTypes = {
  paymentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickLink: PropTypes.func,
  showMatter: PropTypes.bool,
  showSource: PropTypes.bool,
};

PaymentInvoicesTableContainer.defaultProps = {
  onClickLink: () => {},
  showMatter: true,
  showSource: false,
};
