import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { ContactMattersData } from 'web/graphql/queries';
import { usePagination, useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { hasBillingAccess } from 'web/services/user-session-management';

import { BillingContactMattersRoute } from './BillingContactMattersRoute';

const SCOPE = 'BillingContactMattersRoute';
const SCOPE_SORTING = `${SCOPE}/sorting`;
const SCOPE_PAGINATION = `${SCOPE}/pagination`;

const FETCH_LIMIT = 50;

// TODO
// matterTrustBalance.balance and matterTrustBalance.isOverdrawn should be sum of ALL trust account balances for a matter
// Currently, it returns only legacy trust account balance

// The legacy code (using redux) would apply further values to sort by for certain columns.
// In order to make things more consistent, those additional sort values are added where necessary.
function getAdditionalSortSetting({ sortBy }) {
  const sortSetting = {
    values: [],
    directions: [],
  };

  if (sortBy === 'unbilled' || sortBy === 'unpaidExcInterest') {
    sortSetting.values.push('clientDisplayName', 'matterNumber');
    sortSetting.directions.push('ASC', 'ASC');
  }

  return sortSetting;
}

const hooks = () => ({
  useSorting: () => {
    const { sortBy, sortDirection, setSortBy, setSortDirection } = useSort({
      scope: SCOPE_SORTING,
      initialSortBy: featureActive('BB-14072') ? 'matterNumber' : 'clientDisplayName',
      initialSortDirection: featureActive('BB-14072') ? 'desc' : 'asc',
    });

    const onSort = (args) => {
      setSortBy(args.sortBy);
      setSortDirection(args.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
  usePagination: () => {
    const { currentPage, setPageNumber, getPagination } = usePagination({
      scope: SCOPE_PAGINATION,
      fetchLimit: FETCH_LIMIT,
    });

    const onPageChange = ({ selected }) => setPageNumber(selected);

    return {
      selectedPage: currentPage,
      getPagination,
      onPageChange,
    };
  },
  usePermissions: () => ({
    hasBillingAccess: hasBillingAccess(),
  }),
  useProps: () => {
    const allowTrustOverdraw = hasFacet(facets.allowOverdraw);
    return {
      allowTrustOverdraw,
    };
  },
});

const queryHooks = ({ debtorId, sortBy, sortDirection, getPagination, selectedPage }) => ({
  useContactMattersTableData: () => {
    const { values: additionalSortValues, directions: additionalSortDirections } = getAdditionalSortSetting({ sortBy });

    const { data, error, loading } = useSubscribedQuery(ContactMattersData, {
      variables: {
        matterListFilter: {
          excludeBillableLeadMatters: !featureActive('BB-6595'),
          debtorIds: [debtorId],
          clientIds: [debtorId], // Also fetch matters where contact is a client
          includeDeleted: true,
        },
        // Matter balances are used only to display the Overdrawn Trust Account icon
        // There is no Trust Balance filtering in this component, so we can skip loading balances if region doesn't allow overdraw
        includeIsOverdrawn: hasFacet(facets.allowOverdraw),
        sort: {
          fieldNames: [sortBy, ...additionalSortValues],
          directions: [sortDirection, ...additionalSortDirections].map((d) => d.toUpperCase()),
        },
        limit: FETCH_LIMIT,
        offset: selectedPage * FETCH_LIMIT,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const matters = data?.matterList?.results || [];
    const totalCount = data?.matterList?.totalCount || 0;

    const { hidePagination, totalNumberOfPages } = getPagination({
      totalCount,
      loading,
    });

    return {
      matters,
      loading,
      error,
      hidePagination,
      totalNumberOfPages,
    };
  },
});

export const BillingContactMattersRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(BillingContactMattersRoute))),
);

BillingContactMattersRouteContainer.displayName = 'BillingContactMattersRouteContainer';

BillingContactMattersRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  debtorId: PropTypes.string.isRequired,
};

BillingContactMattersRouteContainer.defaultProps = {};
