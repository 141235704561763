import { useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { PaymentProviderActivationPanel } from './PaymentProviderActivationPanel';

const hooks = ({ onButtonClick, activeProviderType, providerType }) => ({
  useActivationPanel: () => {
    const [inProgress, setInProgress] = useState(false);

    return {
      isActiveProvider: activeProviderType === providerType,
      inProgress,
      onButtonClick: async () => {
        try {
          setInProgress(true);
          await onButtonClick();
        } finally {
          setInProgress(false);
        }
      },
    };
  },
});

export const PaymentProviderActivationPanelContainer = withReduxProvider(
  composeHooks(hooks)(PaymentProviderActivationPanel),
);

PaymentProviderActivationPanelContainer.displayName = 'PaymentProviderActivationPanelContainer';

PaymentProviderActivationPanelContainer.propTypes = {
  activeProviderType: PropTypes.string,
  onActivateToggle: PropTypes.func.isRequired,
  isActivateToggled: PropTypes.bool.isRequired,
  providerType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  showButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  activateTooltip: PropTypes.string,
};

PaymentProviderActivationPanelContainer.defaultProps = {
  activeProviderTyp: undefined,
  activateTooltip: undefined,
  disable: false,
  buttonLabel: 'Manage Account',
  showButton: false,
  onButtonClick: () => {},
};
