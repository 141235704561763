angular
  .module('sb.billing.webapp')
  .factory(
    'downloadLedesOp',
    (
      $http,
      sbInvoiceExportLedesService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
      sbDispatcherService,
      sbMessageDisplayService,
    ) => {
      return (invoiceIds) => {
        const log = sbLoggerService.getLogger('downloadLedesOp');
        const requestId = sbUuidService.get();
        const timeoutMs = invoiceIds.length * 30000;
        let invoicesWithProblemsInfo = [];
        const batchLedesExportErrorNotifications = 'BatchLedesExportErrorNotification'; // received message when individual file error
        const batchLedesExportFailedNotifications = 'BatchLedesExportFailedNotification'; // received message whole batch failed
        const ledesExportErrorNotifications = 'LedesExportErrorNotification';
        const ledesExportCriticalErrorNotifications= 'LedesExportCriticalErrorNotification';
        const ledes1998bExportNotificationHasNonUbtmsEntries= 'BatchLedesExportHasNonUbtmsEntriesNotification';

        // Object representing the current state of this operation.
        const operation = {
          label: `Exporting LEDES for ${invoiceIds.length} Invoices`,
          isComplete: false,
          error: null,
          fileData: null,
          nbInvoices: invoiceIds.length,
          progress: {
            nbSteps: 0,
            maxSteps: 1,
            percentage: null
          }
        };

        function unregisterSyncNotifiers(requestId) {
          sbDispatcherService.unregisterSyncAction(batchLedesExportErrorNotifications, requestId);
          sbDispatcherService.unregisterSyncAction(batchLedesExportFailedNotifications, requestId);
          sbDispatcherService.unregisterSyncAction(ledesExportErrorNotifications, requestId);
          sbDispatcherService.unregisterSyncAction(ledesExportCriticalErrorNotifications, requestId);
          sbDispatcherService.unregisterSyncAction(ledes1998bExportNotificationHasNonUbtmsEntries, requestId);
        }

        // Kick off the operattion
        sbNotifiedOperationP(startDownloadLedesP, {
          requestId,
          completionNotification: 'BatchLedesExportReadyNotification',
          progressNotification: 'BatchLedesExportProgressNotification',
          timeoutMs
        })
          .then(onDownloadLedesComplete, undefined, onDownloadLedesProgress)
          .catch(onError);

        sbDispatcherService.registerSyncAction(
          batchLedesExportErrorNotifications,
          requestId,
          problemFoundNotification,
          message => message.requestId === requestId
        );

        sbDispatcherService.registerSyncAction(
          batchLedesExportFailedNotifications,
          requestId,
          onDownloadLedesFailed,
          message => message.requestId === requestId
        );

        // /v2/billing/ledes/generate route will publish ledesExportErrorNotifications or
        // ledesExportCriticalErrorNotifications when an error is encountered exporting
        // ledes for an invoice. These notifications are handled and displayed as warnings
        // on the UI. Each invoice will get it own warning box as there can be multiple
        // errors for an invoice so we don't want to mix them together.
        sbDispatcherService.registerSyncAction(
          ledesExportErrorNotifications,
          requestId,
          onErrorReceivedForInvoice,
          message => message.requestId === requestId
        );

        sbDispatcherService.registerSyncAction(
          ledesExportCriticalErrorNotifications,
          requestId,
          onCriticalErrorReceivedForInvoice,
          message => message.requestId === requestId
        );

        sbDispatcherService.registerSyncAction(
          ledes1998bExportNotificationHasNonUbtmsEntries,
          requestId,
          onNonUbtmsTransactions,
          message => message.requestId === requestId
        );

        return operation;

        // Returns a promise for combining of invoices into a single PDF. Used by sbNotifiedOperation to begin processing.
        function startDownloadLedesP() {
          return sbInvoiceExportLedesService.batchExportLedes1998bP(requestId, invoiceIds);
        }

        function onNonUbtmsTransactions(message) {
          const { hasNonUtbmsEntries } = message.payload;
  
          if (hasNonUtbmsEntries) {
            sbMessageDisplayService.info('Some invoices has non-UTBMS entries.');
          }
        }

        function problemFoundNotification(msg) {
          log.info('error found in some invoices', msg.payload.invoices);
          invoicesWithProblemsInfo = invoicesWithProblemsInfo.concat(msg.payload.invoicesInfo);
        }

        function onErrorReceivedForInvoice(msg) {
          const { ledesErrors, invoiceNumber } = msg.payload;
          let warnText = `LEDES file generated with problems for invoice ${buildInvoiceDisplayWarningText({invoiceNumber})}. 
            `;
          warnText += ledesErrors.reduce(
            (acc, row) => `${acc} 
            ${row}
            `,
            ''
          );
          sbMessageDisplayService.warn(`${warnText}.`);
        }
  
        function onCriticalErrorReceivedForInvoice(msg) {
          const { ledesErrors, invoiceNumber } = msg.payload;
          let errorText = `LEDES file generated with problems for invoice ${buildInvoiceDisplayWarningText({invoiceNumber})}. 
            `;
          errorText += ledesErrors.reduce(
            (acc, row) => `${acc} 
            ${row}
            `,
            ''
          );
          sbMessageDisplayService.error(`${errorText}.`);
        }

        // Called by sbNotifiedOperation whenever some progress is made during the combine PDF operation.
        function onDownloadLedesProgress(msg) {
          const progress = operation.progress;
          ++progress.nbSteps;

          const newMaxProgress = _.get(msg, 'payload.maxProgress') || progress.maxSteps;
          progress.maxSteps = Math.max(newMaxProgress, progress.nbSteps);

          if (progress.maxSteps) {
            progress.percentage = Math.min((progress.nbSteps / progress.maxSteps) * 100, 99.5); // Don't go over 99.5 % for incomplete operation.
          }
        }

        function buildInvoiceDisplayWarningText(invoiceInfo) {
          if (invoiceInfo) {
            const { invoiceNumber } = invoiceInfo;
            return `#${invoiceNumber}`;
          }
          return `Unknown Invoice Number`;
        }

        function buildWarnMessage(invoicesWithProblemsInfo) {
            return invoicesWithProblemsInfo.length > 0
            ? `The ledes export for the following invoices could not be generated and have been removed from the zip file: ${
              invoicesWithProblemsInfo
                .map(invoiceInfo => buildInvoiceDisplayWarningText(invoiceInfo))
                .join(', ')}`
            : undefined; 
        }

        // Called by sbNotifiedOperation when the combine PDF operation completes.
        // Note that we still have a bit of work to do when the PDF combining completes, mainly downloading the document content as base64.
        function onDownloadLedesComplete(msg) {
          const payload = msg.payload;
          const request = {
            method: 'GET',
            url: payload.fileUrl,
            responseType: 'arraybuffer'
          };

          log.info('send request', request);
          return $http(request).then(() => {
            unregisterSyncNotifiers(requestId);
            const fileWarn = buildWarnMessage(invoicesWithProblemsInfo);

            if (fileWarn) {
              sbMessageDisplayService.warn(`${fileWarn}.`);
            }
            operation.isComplete = true;
            operation.progress.percentage = 100;
            operation.downloadUrl = payload.fileUrl;
            operation.fileWarn = buildWarnMessage(invoicesWithProblemsInfo);
          });
        }

        function onDownloadLedesFailed(msg) {
          const payload = msg.payload;
          unregisterSyncNotifiers(requestId);
          sbMessageDisplayService.error(`${payload.errorMessage}.`);
          operation.error = payload.errorMessage;
          operation.isComplete = true;
        }

        // The hopefully never called error handler.
        function onError(err) {
          unregisterSyncNotifiers(requestId);
          log.error(`Failed to export ledes with requestId: ${requestId}`, err);
          operation.error = err;
          operation.isComplete = true;
        }
      };
    }
  );
