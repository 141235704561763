'use strict';

const { convertHoursToUnits, convertUnitsToHours } = require('./fuzzy-time');
const { durationType: durationTypes } = require('../entities/duration-type');

/**
 * Given a duration in a specific duration type, this function converts the duration value to the value representing
 * the same duration in a different target duration type.
 *
 *
 * @param {Object} params
 * @param {string} params.duration The a number convertable string representing the duration to be converted into the target duration units
 * @param {string} params.durationType The duration type which the duration represents.
 * @param {string} params.targetDurationType The duration type to convert duration into.
 * @param {number} params.interval The minimum increments allowable for durations in minutes.
 * @returns {string|undefined} The converted duration. Returns duration unconverted when durationType === targetDurationType. Undefined when targetDurationType equals FIXED.
 */
const convertDurationToType = ({ duration, durationType, targetDurationType, interval }) => {
  if (durationType === durationTypes.FIXED || targetDurationType === durationTypes.FIXED) {
    return undefined;
  }

  if (durationType === targetDurationType) {
    return duration;
  }

  if (!Number.isFinite(+duration)) {
    throw new Error('The passed duration must be a number convertable string');
  }

  // Convert hours into units.
  if (durationType === durationTypes.HOURS && targetDurationType === durationTypes.UNITS) {
    return `${convertHoursToUnits({
      hrs: +duration,
      interval,
      withRounding: true,
    })}`;
  }

  // Convert units into hours.
  if (durationType === durationTypes.UNITS && targetDurationType === durationTypes.HOURS) {
    return `${convertUnitsToHours({
      units: +duration,
      interval,
    })}`;
  }

  throw new Error(`Cannot convert from duration type '${durationType}' to ${targetDurationType}`);
};

module.exports = {
  convertDurationToType,
};
