const capitalize = (string) => {
  if (!string) {
    return '';
  }
  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
};

const capitalizeAllWords = (string) => {
  if (!string) {
    return '';
  }
  const words = string.split(' ');
  const capitalizedWords = words.map(word => capitalize(word));
  return capitalizedWords.join(' ');
}

const convertStringCase = (string, caseEnum) => {
  const caseMap = {
    UPPER: (str) => str.toUpperCase(),
    LOWER: (str) => str.toLowerCase(),
    CAPITALIZED: (str) => capitalize(str),
    INVALID: (str) => str,
  }
  return caseMap[caseEnum](string);
}

const getStringCase = (string) => {
  if (!string) {
    return '';
  }
  if (isCapitalized(string)) {
   return 'CAPITALIZED';
  } else if (isLowerCase(string)) {
    return 'LOWER';
  } else if (isUpperCase(string)) {
    return 'UPPER';
  } 
  return 'INVALID'; 
}

const isUpperCase = (string) => {
  return string === string.toUpperCase();
}

const isLowerCase = (string) => {
  return string === string.toLowerCase();
}

const isCapitalized = (string) => {
  return string === capitalize(string);
}

module.exports = {
  capitalize,
  capitalizeAllWords,
  getStringCase,
  convertStringCase,
};
