'use strict';

// Any changes to these placeholders will need to be handled in interpolateCustomTextTitle function (monorepo/billing/business-logic/invoice-settings/interpolate-custom-text-title.js)
const getCustomTextTitleMacros = ({ t }) => {
  const macros = [
    { name: `[${t('matterNumberPlaceholder')}]`, tooltip: t('matterNumberPlaceholderTooltip') },
    { name: '[MATTER_CLIENT]', tooltip: `Client's last name or ${t('organisation').toLowerCase()}` },
    { name: '[MATTER_TYPE]', tooltip: 'Matter type' },
    { name: '[MATTER_DESCRIPTION]', tooltip: 'Matter description' },
  ];

  return macros;
};

module.exports = { getCustomTextTitleMacros };
