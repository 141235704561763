import { fetchPostP, fetchGetP, fetchDeleteP } from '@sb-itops/redux/fetch';
import { getAccountId } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').component('sbDuiDeduplicate', {
  templateUrl: 'ng-composable-components/data-ui/custom/deduplicate-uploads/dui-deduplicate-uploads.html',
  controllerAs: '$ctrl',
  controller: function (sbMessageDisplayService, sbEnvironmentConfigService) {
    'use strict';

    const isLive = sbEnvironmentConfigService.getFrontendEnv() === 'PRODUCTION';

    const ctrl = this;
    ctrl.deduplicate = deduplicate;
    ctrl.createDuplicates = createDuplicates;
    ctrl.cancel = cancel;
    ctrl.pollState = pollState;
    ctrl.isLive = isLive;

    ctrl.$onInit = () => {
      ctrl.model = {
        matterId: '',
        invoiceId: '',
        createMatterId: '',
        createInvoiceId: '',
        dryRun: true,
      };
      ctrl.view = {
        state: {},
      };
    };

    ctrl.$onDestroy = () => {
      if (ctrl.intervalId) {
        clearInterval(ctrl.intervalId);
      }
    };

    if (isLive) {
      return;
    }

    async function createDuplicates() {
      try {
        if (window.confirm('caution be damned')) {
          const body = {
          }
          if (ctrl.model.createInvoiceId) {
            body.invoiceId = ctrl.model.createInvoiceId;
          }
          if (ctrl.model.createMatterId) {
            body.matterId = ctrl.model.createMatterId;
          }
          
          await fetchPostP({
            path: `/billing/matter-files-qp/prepare-test/:accountId/`,
            fetchOptions: {
              body: JSON.stringify(body),
            },
          });
          sbMessageDisplayService.success(
            sbMessageDisplayService
              .builder()
              .text(`Request submitted`)
          );
        }
      } catch (e) {
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text(`Problem creating duplicates ${e.message}`)
        );
      }
    }

    async function deduplicate() {
      try {
        if (window.confirm('caution be damned')) {
          delete ctrl.model.response;

          const body = {
            dryRun: ctrl.model.dryRun,
            accountId: getAccountId(),
          }

          if (ctrl.model.invoiceId) {
            body.invoiceId = ctrl.model.invoiceId;
          }

          if (ctrl.model.matterId) {
            body.matterId = ctrl.model.matterId;
          }
          
          const response = await fetchPostP({
            path: `/billing/matter-files-qp/deduplicate/:accountId/`,
            fetchOptions: {
              body: JSON.stringify(body),
            },
          });
          ctrl.model.response = response.body;
        }
      } catch (e) {
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text(`Problem deduplicating ${e.message}`)
        );
      }
    }

    async function cancel() {
      try {
        if (window.confirm('caution be damned')) {
          await fetchDeleteP({
            path: `/billing/matter-files-qp/deduplicate/:accountId/`,
          });
          sbMessageDisplayService.success(
            sbMessageDisplayService
              .builder()
              .text(`Request cancelled`)
          );
        }
      } catch (e) {
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text(`Problem cancelling request ${e.message}`)
        );
      }
    }

    async function pollState() {
      if (ctrl.view.isPolling) {
        clearInterval(ctrl.intervalId);
        ctrl.view.isPolling = false;
      } else {
        ctrl.intervalId = setInterval(fetchState, 1000);
        ctrl.view.isPolling = true;
      }
    }

    async function fetchState() {
      try {
        const response = await fetchGetP({
          path: `/billing/matter-files-qp/deduplicate/:accountId/`,
        });
        ctrl.view.state = response.body;
        ctrl.view.state.ts = new Date();
        console.log(ctrl.view.state);
      } catch (e) {
        ctrl.view.state = undefined;
      }
    }
  }
});
