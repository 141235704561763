import { getAllStaff } from '@sb-firm-management/redux/firm-management';

angular.module('sb.billing.webapp').component('sbActivityFeeEntry',
  {
    'templateUrl': 'ng-components/activity-entry/activity-fee-entry.html',
    'bindings': {processing: '<', activity: '<', rateTypeRadio: '<', activityForm: '<', syncUpdate: '&', syncView: '&'},
    'controller': function ($scope, sbLocalisationService) {
      'use strict';

      const ctrl = this;
      ctrl.t = sbLocalisationService.t;
      ctrl.onSelectTaxExempt = onSelectTaxExempt;
      ctrl.onSelecAmountIncludesTax = onSelecAmountIncludesTax;
      ctrl.onStaffRateOverride = onStaffRateOverride;
      ctrl.onFocusNumberInput = onFocusNumberInput;

      const CUSTOM_RATE = 'Override rate for all staff';
      const OVERRIDE_PER_STAFF_RATE = 'Override default rate per staff member';

      ctrl.rateTypes = { CUSTOM_RATE, OVERRIDE_PER_STAFF_RATE };
      ctrl.view = {};

      $scope.$on('smokeball-data-update-sbFirmManagementMbService', loadStaffMembers);

      ctrl.$onInit = () => {
        $scope.$watch('$ctrl.activity', function(activity) {
          ctrl.syncUpdate({activity});
        });

        $scope.$watch('$ctrl.rateTypeRadio', function(rateTypeRadio) {
          ctrl.syncView({rateTypeRadio});
        });

        loadStaffMembers();
      };

      function loadStaffMembers() {
        // unlike activity time entry, activity fee entry is a fixed fee
        // and thus do not use staff hourly rate as default rate
        ctrl.view.staffMembers = getAllStaff();
      }

      function onSelectTaxExempt(newValue) {
        if (ctrl.activity.amountIncludesTax && newValue) {
          ctrl.activity.amountIncludesTax = !newValue;
        }
      }

      function onSelecAmountIncludesTax(newValue) {
        if (ctrl.activity.taxExempt && newValue) {
          ctrl.activity.taxExempt = !newValue;
        }
      }
      
      function onStaffRateOverride(overrides) {
        ctrl.activity.customActivityRatesPerStaff = overrides;
      }

      function onFocusNumberInput(event) {
        event.target.select();
      }
    }
  }
);

