import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withOnLoad, withReduxStore } from '@sb-itops/react';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getRegion } from '@sb-itops/region';
import * as forms from '@sb-itops/redux/forms2';
import { store } from '@sb-itops/redux';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';

import { marshallValues } from './helpers/marshall';
import { AdjustmentForm } from './AdjustmentForm';
import { adjustmentFormSchema } from './adjustment-form-schema';

const log = getLogger('AdjustmentFormContainer');

const REGION = getRegion();

const mapStateToProps = (state, { scope, formName }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { fields: formFields, submitFailed } = formSelectors.getFormState(state);
  const { reason, selectedDate, amount } = formFields;

  return {
    // Form
    formName,
    formSubmitFailed: submitFailed,
    // Form fields
    date: selectedDate,
    amount,
    reason,
    // Other
    region: REGION,
  };
};

const mapDispatchToProps = (dispatch, { scope, onSubmitHandler }) => {
  const {
    actions: formActions,
    operations: formOperations,
    selectors: formSelectors,
  } = withScopedFeature({ scope })(forms);
  const { formValid } = formSelectors.getFormState(store.getState());

  function onFieldValueUpdated(field) {
    dispatch(formActions.setFieldValue(field));
    dispatch(formOperations.validateSchema({ schema: adjustmentFormSchema }));
  }

  function onLoad() {
    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          amount: undefined,
          selectedDate: new Date().toISOString(),
          reason: '',
        },
      }),
    );
    const onUnload = () => dispatch(formActions.clearForm());
    return onUnload;
  }

  function onDateChange(date) {
    onFieldValueUpdated({ field: 'selectedDate', value: date?.toISOString() });
  }

  function onReasonChange(e) {
    e.preventDefault();
    onFieldValueUpdated({ field: 'reason', value: e.target.value });
  }

  function onAmountChange(e) {
    onFieldValueUpdated({ field: 'amount', value: e.target.value });
  }

  async function onSubmit(e) {
    dispatch(formOperations.validateSchema({ schema: adjustmentFormSchema }));

    try {
      e.preventDefault();
      await dispatch(
        formOperations.submitFormP({
          validateBeforeSubmit: true,
          submitFnP: async (arg) => {
            if (!formValid) {
              throw new Error('Adjustment form is invalid');
            }

            const { amount, selectedDate: date, reason } = arg;
            const adjustment = marshallValues({ amount, date: new Date(date), reason });

            onSubmitHandler(adjustment);
          },
        }),
      );
    } catch (error) {
      log.error(error);
      messageDisplay.error(messageDisplay.builder().text('Could not create adjustment'));
    }
  }

  return {
    onLoad,
    onDateChange,
    onReasonChange,
    onAmountChange,
    onSubmit,
  };
};

export const AdjustmentFormContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(AdjustmentForm)),
);

AdjustmentFormContainer.displayName = 'AdjustmentFormContainer';

AdjustmentFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};
