import { initWebP } from '@sb-itops/localisation-web';
import { getRestApiUrl } from '@sb-itops/environment-config';
import { httpGet } from '../network/http';

export const initialiseLocalisationP = async ({ log, defaultTeardown }) => {
  try {
    await initWebP({
      fetch: async ({ language }) =>
        httpGet({
          // to test chained backend
          // url: `${getRestApiUrl()}/resources/x${language}`,
          url: `${getRestApiUrl()}/resources/${language}`,
          throwOnFailure: true,
        }),
    });
  } catch (err) {
    // do something here
    log.warn(`Failed to fetch resources ${err}`);
    throw err;
  }

  return defaultTeardown;
};
