import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveProvider, getPaymentFormConfiguration } from '@sb-billing/redux/payment-provider-settings/selectors';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { paymentFormTypes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { preCharge as preChargeProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getById as getContactSummaryById } from '@sb-customer-management/redux/contacts-summary';
import { CreditCardSaveForm } from './CreditCardSaveForm';

export const CreditCardSaveFormContainer = withReduxProvider(({ onSubmit, contactId, initialValues, ...props }) => {
  const [description, setDescription] = useState(initialValues?.description || '');

  const providerType = useSelector(() => getActiveProvider());
  const paymentFormConfiguration = getPaymentFormConfiguration({
    bankAccountId: props.bankAccountId,
    providerType,
    providerSpecificFields: {
      paymentFormType: paymentFormTypes.SAVE_DETAILS, // Whether form is used for charging or saving details
      contactSummary: contactId ? getContactSummaryById(contactId) : undefined,
    },
  });

  // Lawpay may contain ECheque which we don't want
  if (
    paymentFormConfiguration?.enabledPaymentMethods?.includes(paymentMethods.CREDIT_CARD) &&
    paymentFormConfiguration?.enabledPaymentMethods?.length > 1
  ) {
    paymentFormConfiguration.enabledPaymentMethods = [paymentMethods.CREDIT_CARD];
  }

  const onHandlePreCharge = async ({ providerType: _providerType, providerSpecificFields }) =>
    preChargeProvider({ fetchPostP, providerType: _providerType, providerSpecificFields });

  return (
    <CreditCardSaveForm
      {...props}
      onSubmit={(formData) => onSubmit({ providerFormData: formData, description })}
      description={description}
      setDescription={setDescription}
      providerType={providerType}
      paymentFormConfiguration={paymentFormConfiguration}
      onPreCharge={onHandlePreCharge}
    />
  );
});

CreditCardSaveFormContainer.displayName = 'CreditCardSaveFormContainer';

CreditCardSaveFormContainer.propTypes = {
  initialValues: PropTypes.object,
  bankAccountId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  triggerSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
};

CreditCardSaveFormContainer.defaultProps = {};
