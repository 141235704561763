import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from '@sb-itops/react/modal-dialog';
import { useTranslation } from '@sb-itops/react';

const DeleteDraftInvoiceProceedModalDialog = memo(({ onProceedClick, confirmationModalId }) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      modalId={confirmationModalId}
      body={
        <div>
          <p>Are you sure you want to delete this draft invoice?</p>
          <p>
            {`If you proceed, all Time, Fee, and ${t('capitalizeAllWords', {
              val: 'expense',
            })} entries associated with the deleted draft invoice will be unassigned and you will be able to add them to another invoice.`}
          </p>
        </div>
      }
      title="Delete Draft Invoice"
      buttons={{
        rightAlign: true,
        primary: {
          text: 'Delete Draft',
          props: {
            type: 'warning',
          },
        },
        secondary: {
          text: 'Cancel',
        },
      }}
      onPrimaryClick={onProceedClick}
    />
  );
});

DeleteDraftInvoiceProceedModalDialog.displayName = 'DeleteInvoicesProceedModalDialog';

DeleteDraftInvoiceProceedModalDialog.propTypes = {
  onProceedClick: PropTypes.func.isRequired,
  confirmationModalId: PropTypes.string.isRequired,
};

export default DeleteDraftInvoiceProceedModalDialog;
