import { domain } from './domain';

export const RESET_ALL_REDUX_DATA = `${domain}/RESET_ALL_REDUX_DATA`;
export const RESET_NON_PERSISTED_REDUX_DATA = `${domain}/RESET_NON_PERSISTED_REDUX_DATA`;

export const addResetAllDataActionHandler = (reducer) => (state, action) => {
  if (action.type === RESET_ALL_REDUX_DATA) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
export const addResetNonPersistedDataActionHandler = (reducer) => (state, action) => {
  if (action.type === RESET_NON_PERSISTED_REDUX_DATA || action.type === RESET_ALL_REDUX_DATA) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
