import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsedAttorneyResponsibleIds } from '@sb-matter-management/redux/matters';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import { withReduxStore } from '@sb-itops/react';
import { isUndefined } from 'util';
import { AttorneyDropDown } from '@sb-billing/react/attorney-dropdown';

const mapResult = ({ id, name }) => ({
  value: id,
  label: name,
});

const noneOption = { id: undefined, name: 'None' };

const handleChangeAndMapResult = (executor) => (change) => {
  executor(change && change.value);
};

const mapStateToProps = (state, { onlyUsed, defaultEntityId, handleChange }) => {
  const restrict = getUsedAttorneyResponsibleIds();
  const summaries = getCurrentStaff();
  // TODO revisit all this filtering and mapping, it is wrong to do this computation here, too expensive.
  const allStaffs = [noneOption, ...summaries].map(mapResult);
  const options = !onlyUsed ? allStaffs : allStaffs.filter((member) => restrict.includes(member.value));
  const defaultValue = (defaultEntityId && options.find((op) => op.value === defaultEntityId)) || mapResult(noneOption);
  return {
    options,
    defaultValue,
    handleChange: handleChangeAndMapResult(handleChange),
  };
};

const AttorneyDropDownFieldWithOptions = connect(mapStateToProps)(AttorneyDropDown);
const AttorneyDropDownFieldWithReduxState = withReduxStore(AttorneyDropDownFieldWithOptions);

AttorneyDropDownFieldWithOptions.propTypes = {
  defaultEntityId: PropTypes.string,
  handleChange: PropTypes.func,
};

AttorneyDropDownFieldWithOptions.defaultProps = {
  defaultEntityId: undefined,
  handleChange: isUndefined,
};

export default AttorneyDropDownFieldWithReduxState;
