const { regionType } = require('@sb-itops/region');

const letterFormats = Object.freeze({
  FORMAL: 0,
  INFORMAL: 1,
  CUSTOM: 2,
});

/**
 * Builds the first part of the "To" field value
 *
 * E.g. The "Mr Test" part of: "Mr Test" <test@smokeball.com>
 *
 * Source: getPersonNameForLetter from monorepo/customer-management/redux/contacts/index.js (modifications were made to suit LOD)
 *
 * @param {object} params
 * @param {object} params.contact
 *
 * isAbbreviatedTitle and region are only relevant for FORMAL letter formats
 * @param {boolean} params.isAbbreviatedTitle The function provided in "monorepo/customer-management/business-logic/contacts/services/is-abbreviated-title.js" can be used to determine this if needed.
 * @param {string} params.region
 * @returns {string}
 */
function buildContactToAddressSalutation({ contact, isAbbreviatedTitle, region }) {
  if (!contact) {
    throw new Error(`Contact is required (given: ${contact})`);
  }

  // For CustomerManagement.ManageCustomers.Entities:
  //  * Contacts of type "staff" are under the "person" attribute
  //  * Therefore, while graphql gives us "staff" as the type, staff are considered a "person" type entity in this context
  if (!(contact.type === 'person' || contact.type === 'staff')) {
    throw new Error(`Contact type must be of "person" or "staff" (given: ${contact.type})`);
  }

  if (!region) {
    throw new Error(`Region is required (given: ${region})`);
  }

  switch (contact.letterNameFormat) {
    case letterFormats.FORMAL: {
      let title = contact.title;
      if (region === regionType.US && title && !title.endsWith('.') && isAbbreviatedTitle) {
        title += '.';
      }

      return `${title ? `${title} ` : ''}${!title ? `${contact.firstName} ` : ''}${contact.lastName}`;
    }
    case letterFormats.INFORMAL: {
      return contact.firstName;
    }
    case letterFormats.CUSTOM: {
      return contact.customLetterName;
    }
    default: {
      return contact.displayName || '';
    }
  }
}

module.exports = {
  buildContactToAddressSalutation,
  letterFormats,
};
