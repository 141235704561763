import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

// we are not going to have opdates so far on the cache. We will see if we need some sort of indicator at the end of the feature.
const api = cacheFactory({
  domain,
  name: 'payment-plan-events',
  keyPath: 'id',
  ngCacheName: 'sbPaymentPlanEventsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;
