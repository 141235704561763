/**
 * Takes any input and returns true if the input is a non-empty string.
 * This is particularly useful when asserting parsed paramters.
 *
 * @param {*} input Anything you want to validate.
 * @returns {boolean}
 */
 const isNonEmptyString = (input) => typeof input === 'string' && input.length > 0;

 module.exports = {
   isNonEmptyString,
 };
 