angular.module('sb.billing.webapp').service('sbStateHistoryService', function($timeout, $rootScope, $state) {
  'use strict';
  var svc = this;

  svc.getStateHistory = getStateHistory;

  var history = [];
  var MAX_STATES_STORED = 10;

  $timeout(function () {
    if ($state.current && $state.current.name && !$state.current.abstract) {
      history.push({state: $state.current, params: $state.params});
    }
  });

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
    history.unshift({state: toState, params: toParams});

    if (history.length > MAX_STATES_STORED) {
      history.pop();
    }
  });

  function getStateHistory() {
    return angular.copy(history);
  }
});
