import uuid from '@sb-itops/uuid';

import {
  TOGGLE_EXPAND,
  TOGGLE_ITEMS,
  SET_SHOW_PAYMENTS,
  SET_SHOW_RECEIPTS,
  SET_SEARCH_FILTER,
  TOGGLE_SHOW_URECONCILED,
  CLEAR_STATE,
  TOGGLE_SHOW_SUMMARY,
  SET_SHOW_DELETED,
  ADD_ADJUSTMENT,
  SET_ADJUSTMENTS,
  TOGGLE_RECONCILE_ADJUSTMENT,
  TOGGLE_REMOVE_ADJUSTMENT,
} from './types';

const initialState = {
  // transactionIds is not initialised to allow for its use in on load
  expandedIds: {},
  showUnreconciled: false,
  bankStatementBalance: undefined,
  showSummary: true,
  showPayments: true,
  showReceipts: true,
  showDeleted: false,
  searchFilter: '',
  adjustments: [],
  transactionIds: {},
  depositSlipIds: {},
  consolidatedIds: {},
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_EXPAND: {
      return {
        ...state,
        expandedIds: {
          ...state.expandedIds,
          [action.payload.id]: !state.expandedIds[action.payload.id],
        },
      };
    }

    case TOGGLE_ITEMS: {
      return {
        ...state,
        transactionIds: {
          ...(state.transactionIds || {}),
          ...action.payload.transactionIds.reduce((toggled, id) => {
            // eslint-disable-next-line no-param-reassign
            toggled[id] = !state.transactionIds[id];
            return toggled;
          }, {}),
        },
        depositSlipIds: {
          ...(state.depositSlipIds || {}),
          ...action.payload.depositSlipIds.reduce((toggled, id) => {
            // eslint-disable-next-line no-param-reassign
            toggled[id] = !state.depositSlipIds[id];
            return toggled;
          }, {}),
        },
        consolidatedIds: {
          ...(state.consolidatedIds || {}),
          ...action.payload.consolidatedIds.reduce((toggled, id) => {
            // eslint-disable-next-line no-param-reassign
            toggled[id] = !state.consolidatedIds[id];
            return toggled;
          }, {}),
        },
      };
    }

    case SET_SHOW_PAYMENTS: {
      return {
        ...state,
        showPayments: action.payload.option,
      };
    }

    case SET_SHOW_RECEIPTS: {
      return {
        ...state,
        showReceipts: action.payload.option,
      };
    }
    case SET_SHOW_DELETED: {
      return {
        ...state,
        showDeleted: action.payload.option,
      };
    }

    case SET_SEARCH_FILTER: {
      return {
        ...state,
        searchFilter: action.payload.option,
      };
    }

    case TOGGLE_SHOW_URECONCILED:
      return {
        ...state,
        showUnreconciled: !state.showUnreconciled,
      };

    case CLEAR_STATE:
      return initialState;

    case TOGGLE_SHOW_SUMMARY:
      return {
        ...state,
        showSummary: !state.showSummary,
      };

    case ADD_ADJUSTMENT:
      return {
        ...state,
        adjustments: [
          ...state.adjustments,
          { ...action.payload.adjustment, isDeleted: false, isNew: true, id: uuid() },
        ],
      };

    case SET_ADJUSTMENTS:
      return {
        ...state,
        adjustments: [...action.payload.adjustments],
      };

    case TOGGLE_REMOVE_ADJUSTMENT:
      return {
        ...state,
        adjustments: state.adjustments.map((adj) =>
          adj.id === action.payload.id ? { ...adj, isDeleted: !adj.isDeleted } : adj,
        ),
      };

    case TOGGLE_RECONCILE_ADJUSTMENT:
      return {
        ...state,
        adjustments: state.adjustments.map((adj) =>
          adj.id === action.payload.id ? { ...adj, isReconciled: !adj.isReconciled } : adj,
        ),
      };

    default: {
      return state;
    }
  }
};
