import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BillingFrequencyTreeSelect } from '@sb-billing/react';
import {
  CollapsibleHeader,
  CurrencyInput2,
  ToggleListFilter,
  SlidingToggle,
  Spinner,
  PanelExpander,
  useTranslation,
} from '@sb-itops/react';
import { StaffSelectorList } from '@sb-firm-management/react';
import { MatterTypeSelector } from 'web/containers';
import { DateListFilter } from 'web/react-redux';
import { featureActive } from '@sb-itops/feature';
import Styles from './InvoicesFilterPanel.module.scss';

const InvoicesFilterPanel = memo(
  ({
    invoiceStatusCounts,
    filterOptions,
    matterTypes,
    staffMembers,
    toggleHideFilters,
    // generated callbacks
    onFilterInvoiceStatus,
    onChangeIssueDateFilter,
    onChangeFinalizedOnFilter,
    onFilterSentStatus,
    onFilterSendPreference,
    onFilterBillingEvent,
    onFilterBillingType,
    onFilterBillingFrequencySubType,
    onFilterMatterStatus,
    onFilterMatterTypes,
    onFilterByRecent,
    onFilterUtbmsEnabled,
    contactMatterBalancesTitle,
    onFilterAttorneyResponsible,
    onToggleStaff,
    onResetFilters,
    onFilterTrustBalance,
    onFilterOperatingBalance,
    expanded,
    toggleShowFilters,
    hideFilters,
    filters,
    attorneysResponsibleMap,
    showSendPreferencesFilter,
    showBillingFrequencyFilter,
    showOperatingBalanceFilter,
    showUtbmsFilter,
    showAttorneyResponsibleFilter,
    showTrustBalanceFilter,
    showBillingTypesFilter,
    showMatterStatusesFilter,
    showMatterTypesFilter,
  }) => {
    const { t } = useTranslation();

    if (!filters || !hideFilters) {
      return null;
    }

    return (
      <div className={Styles.invoicesSidePanel}>
        <PanelExpander
          className="nav panel-filter"
          onResetFilters={onResetFilters}
          expanded={expanded}
          toggleExpanded={toggleShowFilters}
        >
          {/* Invoice Status Filter  */}
          <CollapsibleHeader
            text="Status"
            onClick={(e) => {
              toggleHideFilters(e);
            }}
            name="invoiceStatus"
            collapsed={hideFilters?.invoiceStatus}
          >
            {featureActive('BB-8047') && (
              <div className={Styles.recentInvoicesButton}>
                <div onClick={() => onFilterByRecent(!filters?.recent)} className={Styles.recentInvoicesLabel}>
                  Recently viewed
                </div>
                <SlidingToggle
                  scope="recent-invoices"
                  onChange={() => onFilterByRecent(!filters?.recent)}
                  selected={filters?.recent}
                />
              </div>
            )}
            <ToggleListFilter
              showAllLabel="All"
              optionCounts={invoiceStatusCounts}
              options={filterOptions?.invoiceStatus}
              selected={filters?.invoiceStatuses}
              onSelect={onFilterInvoiceStatus}
            />
          </CollapsibleHeader>

          {/* Issue Date Filter  */}
          <CollapsibleHeader
            text="Issue Date"
            onClick={toggleHideFilters}
            name="issueDate"
            collapsed={hideFilters?.issueDate}
          >
            <DateListFilter className="side-menu-list" onChange={onChangeIssueDateFilter} {...filters.issueDate} />
          </CollapsibleHeader>

          {/* Sent status filter */}
          {!filters?.invoiceStatus?.includes('DRAFT') && (
            <CollapsibleHeader
              text="Sent Status"
              onClick={toggleHideFilters}
              name="sentStatus"
              collapsed={hideFilters?.sentStatus}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={filterOptions?.sentStatus}
                selected={filters?.sentStatuses}
                onSelect={onFilterSentStatus}
              />
            </CollapsibleHeader>
          )}

          {/* Send preference filter */}
          {showSendPreferencesFilter && filterOptions?.sendPreferences && (
            <CollapsibleHeader
              text="Send Preference"
              onClick={toggleHideFilters}
              name="sendPreference"
              collapsed={hideFilters?.sendPreference}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={filterOptions?.sendPreferences}
                selected={filters?.sendPreferences}
                onSelect={onFilterSendPreference}
              />
            </CollapsibleHeader>
          )}

          {/* Online invoice filter */}
          {featureActive('BB-5725') && (
            <CollapsibleHeader
              text="Online Invoice"
              onClick={toggleHideFilters}
              name="billingEvents"
              collapsed={hideFilters?.billingEvents}
            >
              <ToggleListFilter
                hideShowAllOption
                options={filterOptions?.billingEventStatus}
                selected={filters?.billingEvents}
                onSelect={onFilterBillingEvent}
              />
            </CollapsibleHeader>
          )}

          {showBillingTypesFilter && (
            <>
              {/* Billing Type Filter */}
              <CollapsibleHeader
                text="Billing Type"
                onClick={toggleHideFilters}
                name="billingType"
                collapsed={hideFilters?.billingType}
              >
                <ToggleListFilter
                  showAllLabel="All"
                  options={filterOptions?.billingType}
                  selected={filters?.billingTypes?.selections}
                  onSelect={onFilterBillingType}
                />
              </CollapsibleHeader>
            </>
          )}

          {/* Billing Frequency Filter */}
          {featureActive('BB-11704') && showBillingFrequencyFilter && (
            <CollapsibleHeader
              text="Billing Frequency"
              onClick={toggleHideFilters}
              name="billingFrequency"
              collapsed={hideFilters?.billingFrequency}
            >
              <BillingFrequencyTreeSelect
                selectedBillingFrequencySubTypes={filters?.billingFrequencySubTypes}
                onSelect={onFilterBillingFrequencySubType}
              />
            </CollapsibleHeader>
          )}

          {(showMatterStatusesFilter || showMatterTypesFilter) && (
            <>
              {/* Matter Type Filter */}
              {showMatterTypesFilter && (
                <CollapsibleHeader
                  text="Matter Type"
                  onClick={toggleHideFilters}
                  name="matterType"
                  collapsed={hideFilters?.matterType}
                >
                  {matterTypes ? (
                    <MatterTypeSelector
                      matterTypes={matterTypes}
                      onSelectMatterType={onFilterMatterTypes}
                      selectedMatterTypeIds={filters?.matterTypes}
                    />
                  ) : (
                    <Spinner className={Styles.matterTypesLoading} small />
                  )}
                </CollapsibleHeader>
              )}

              {/* Matter Status Filter */}
              {showMatterStatusesFilter && (
                <CollapsibleHeader
                  text="Matter Status"
                  onClick={toggleHideFilters}
                  name="matterStatus"
                  collapsed={hideFilters?.matterStatus}
                >
                  <ToggleListFilter
                    showAllLabel="All"
                    options={filterOptions?.matterStatus}
                    selected={filters?.matterStatuses}
                    onSelect={onFilterMatterStatus}
                  />
                </CollapsibleHeader>
              )}

              {/* Attorney responsible filter */}
              {showAttorneyResponsibleFilter && (
                <div className={Styles.attorneyResponsibleContainer}>
                  <CollapsibleHeader
                    text={t('capitalizeAll', { val: 'personResponsible' })}
                    onClick={toggleHideFilters}
                    name="attorneyResponsible"
                    collapsed={hideFilters?.attorneyResponsible}
                  >
                    <StaffSelectorList
                      staff={staffMembers}
                      selectedStaffIds={attorneysResponsibleMap}
                      onToggleStaff={onToggleStaff}
                      onSelectStaff={onFilterAttorneyResponsible}
                      onDeselectAllStaff={() => onFilterAttorneyResponsible([])}
                      showNoneOption
                    />
                  </CollapsibleHeader>
                </div>
              )}

              {/* Debtors Balances Filter  */}
              <CollapsibleHeader
                text={contactMatterBalancesTitle}
                onClick={toggleHideFilters}
                name="debtorBalances"
                collapsed={hideFilters?.debtorBalances}
              >
                <div className={Styles.debtorBalances}>
                  {showTrustBalanceFilter && (
                    <div className={Styles.balanceType}>
                      <span className={Styles.balanceTypeLabel}>At least {t('currencySymbol')} </span>
                      <div className={Styles.inlineBlock}>
                        <CurrencyInput2
                          className={Styles.balanceValue}
                          value={filters?.minimumTrustBalance}
                          hideDollar
                          onChange={(e) => onFilterTrustBalance(Number(e.target.value))}
                        />
                      </div>
                      <span className={Styles.balanceTypeLabel}> in {t('trust')}</span>
                    </div>
                  )}
                  {showOperatingBalanceFilter && (
                    <div className={Styles.balanceType}>
                      <span className={Styles.balanceTypeLabel}>At least {t('currencySymbol')} </span>
                      <div className={Styles.inlineBlock}>
                        <CurrencyInput2
                          className={Styles.balanceValue}
                          value={filters?.minimumOperatingBalance}
                          hideDollar
                          onChange={(e) => onFilterOperatingBalance(Number(e.target.value))}
                        />
                      </div>
                      <span className={Styles.balanceTypeLabel}> in {t('operating')}</span>
                    </div>
                  )}
                </div>
              </CollapsibleHeader>

              {/* LEDES Billing Filter  */}
              {featureActive('BB-9243') && showUtbmsFilter && (
                <CollapsibleHeader
                  text="LEDES Billing"
                  onClick={(e) => {
                    toggleHideFilters(e);
                  }}
                  name="ledesBilling"
                  collapsed={hideFilters?.ledesBilling}
                >
                  <div className={Styles.utbmsEnabledToggle}>
                    <div
                      onClick={() => onFilterUtbmsEnabled(!filters?.utbmsEnabled)}
                      className={Styles.utbmsEnabledLabel}
                    >
                      UTBMS Enabled
                    </div>
                    <SlidingToggle
                      scope="utbmsEnabled"
                      onChange={() => onFilterUtbmsEnabled(!filters?.utbmsEnabled)}
                      selected={filters?.utbmsEnabled}
                    />
                  </div>
                </CollapsibleHeader>
              )}
            </>
          )}
          {/* Finalized On Filter  */}
          <div className={Styles.finalizedOn}>
            <CollapsibleHeader
              text={`${t('capitalize', { val: 'finalised' })} On`}
              onClick={toggleHideFilters}
              name="finalizedOn"
              collapsed={hideFilters?.finalizedOn}
            >
              <DateListFilter
                className="side-menu-list"
                onChange={onChangeFinalizedOnFilter}
                showToday
                {...filters.finalizedOn}
              />
            </CollapsibleHeader>
          </div>
        </PanelExpander>
      </div>
    );
  },
);

InvoicesFilterPanel.displayName = 'InvoicesFilterPanel';

InvoicesFilterPanel.propTypes = {
  attorneysResponsibleMap: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  hideFilters: PropTypes.object.isRequired,
  invoiceStatusCounts: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  staffMembers: PropTypes.arrayOf(PropTypes.object),
  toggleHideFilters: PropTypes.func.isRequired,
  matterTypes: PropTypes.arrayOf(PropTypes.object),
  onFilterInvoiceStatus: PropTypes.func.isRequired,
  onChangeIssueDateFilter: PropTypes.func.isRequired,
  onChangeFinalizedOnFilter: PropTypes.func.isRequired,
  onFilterSentStatus: PropTypes.func.isRequired,
  onFilterSendPreference: PropTypes.func,
  onFilterBillingEvent: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  contactMatterBalancesTitle: PropTypes.string,
  onFilterBillingType: PropTypes.func,
  onFilterBillingFrequencySubType: PropTypes.func,
  onFilterMatterStatus: PropTypes.func,
  onFilterMatterTypes: PropTypes.func,
  onFilterTrustBalance: PropTypes.func,
  onFilterOperatingBalance: PropTypes.func,
  onFilterAttorneyResponsible: PropTypes.func,
  onToggleStaff: PropTypes.func.isRequired,
  onFilterByRecent: PropTypes.func,
  onFilterUtbmsEnabled: PropTypes.func,
  showSendPreferencesFilter: PropTypes.bool,
  showBillingFrequencyFilter: PropTypes.bool,
  showOperatingBalanceFilter: PropTypes.bool,
  showBillingTypesFilter: PropTypes.bool,
  showMatterStatusesFilter: PropTypes.bool,
  showMatterTypesFilter: PropTypes.bool,
  showAttorneyResponsibleFilter: PropTypes.bool,
  showTrustBalanceFilter: PropTypes.bool,
  showUtbmsFilter: PropTypes.bool,
};

InvoicesFilterPanel.defaultProps = {
  matterTypes: undefined,
  contactMatterBalancesTitle: '',
  onFilterSendPreference: undefined,
  onFilterMatterStatus: undefined,
  onFilterBillingType: undefined,
  onFilterBillingFrequencySubType: undefined,
  onFilterMatterTypes: undefined,
  onFilterTrustBalance: undefined,
  onFilterOperatingBalance: undefined,
  onFilterAttorneyResponsible: undefined,
  onFilterByRecent: undefined,
  onFilterUtbmsEnabled: undefined,
  showSendPreferencesFilter: false,
  showBillingFrequencyFilter: false,
  showBillingTypesFilter: false,
  showMatterStatusesFilter: false,
  showMatterTypesFilter: false,
  showOperatingBalanceFilter: false,
  showUtbmsFilter: false,
  staffMembers: undefined,
  showAttorneyResponsibleFilter: undefined,
  showTrustBalanceFilter: undefined,
};

export default InvoicesFilterPanel;
