import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import PropTypes from 'prop-types';
import * as matterTransactionFilters from 'web/redux/route/home-billing-matter-transactions';
import { getControlledMoneyAccounts } from '@sb-billing/redux/bank-account';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import * as controlledMoneyAccountsFeature from 'web/redux/features/controlled-money-accounts';
import { sortByProperty } from '@sb-itops/nodash';

import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import MatterTransactionsFilterPanel from './MatterTransactionsFilterPanel';

const getControlledMoneyAccountsFeature = (state, matterId) => {
  const FEATURE_SCOPE = `controlled-money-accounts-${matterId}`;
  return withScopedFeature({ state, scope: FEATURE_SCOPE })(controlledMoneyAccountsFeature);
};

const mapStateToProps = (state, { trustAccountId, isStatutoryDepositMatter, cmaId, matterId, t }) => {
  const { getShowFilters, getFilters } = matterTransactionFilters.selectors;
  const expanded = getShowFilters(state);
  const { showHidden, dateListFilter, showMarkAsSentEntries } = getFilters(state);

  const controlledMoneyAccounts = getControlledMoneyAccountsFeature(state, matterId);
  const { getVisibleClosedIdMap } = controlledMoneyAccounts.selectors;
  const visibleClosedCmaIdMap = getVisibleClosedIdMap();

  const activeTrustAccounts = filterTrustAccountsByMatter(matterId).map((trust) => ({
    label: getBankAccountName(trust, t),
    id: trust.id,
  }));

  const selectedTrustAccountId = trustAccountId;

  const showTrustOverview = !!(activeTrustAccounts.length > 0);
  const showCreditNoteLedger = !isStatutoryDepositMatter;

  const rawCmaAccounts = getControlledMoneyAccounts().reduce((acc, account) => {
    // By default we only show open CMAs
    // ClosedCmaIdMap is for the accounts that have been closed but the user has directly clicked on
    // these closed accounts, in which case we want to then add them to the list of open accounts and
    // show the closed account so we can navigate to it
    if (
      account.associatedMatterId === matterId &&
      (account.state === BANK_ACCOUNT_STATE.OPEN || visibleClosedCmaIdMap[account.id])
    ) {
      acc.push({ id: account.id, label: account.accountName });
    }
    return acc;
  }, []);

  const cmaAccounts = sortByProperty(rawCmaAccounts, 'label', 'asc', false);

  const trustAccountsFilterPanelConfig = {
    overviewOnClicklinkParams: {
      id: 'trust',
      type: 'matterTransactions',
      params: [{ matterId, trustAccountId: 'all' }],
    },
    parentPageName: 'home.billing.view-matter.transactions',
    getSpecificOnClickLinkParams: (trustId) => ({
      type: 'matterTransactions',
      id: 'trust',
      params: [{ matterId, trustAccountId: trustId }],
    }),
    getProtectedFundsOnClickLinkParams: (trustId) => ({
      id: 'protected-funds',
      type: 'matterTransactions',
      params: [{ matterId, trustAccountId: trustId }],
    }),
    showProtectedFunds: true,
  };

  return {
    expanded,
    showHidden,
    dateListFilter,
    showMarkAsSentEntries,
    showTrustOverview,
    showLedgerAccount: !isStatutoryDepositMatter,
    showControlledMoneyAccount: featureActive('BB-6381') && hasFacet(facets.CMA),
    showOperatingAccount: hasFacet(facets.operatingAccount),
    showCreditNoteLedger,
    cmaId,
    cmaAccounts,
    visibleClosedCmaIdMap,
    trustAccounts: activeTrustAccounts,
    selectedTrustAccountId: selectedTrustAccountId || '',
    trustAccountsFilterPanelConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(matterTransactionFilters.actions.toggleShowFilters()),
  onDateListChange: (value) => dispatch(matterTransactionFilters.actions.setFilter('dateListFilter', value)),
  onDeletedTransactionsChange: (value) => dispatch(matterTransactionFilters.actions.setFilter('showHidden', value)),
  onClientCommunicationToggleChange: (field, value) =>
    dispatch(matterTransactionFilters.actions.setFilter('showMarkAsSentEntries', value)),
});

const MatterTransactionsFilterPanelContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MatterTransactionsFilterPanel)),
);

MatterTransactionsFilterPanelContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  isStatutoryDepositMatter: PropTypes.bool.isRequired,
  accountType: PropTypes.string,
  trustAccountId: PropTypes.string,
  matterId: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  cmaId: PropTypes.string,
  selectedTrustAccountId: PropTypes.string,
};

MatterTransactionsFilterPanelContainer.defaultProps = {
  accountType: undefined,
  cmaId: undefined,
  trustAccountId: undefined,
};

export default MatterTransactionsFilterPanelContainer;
