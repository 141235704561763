'use strict';

const { marshal: emailMarshal, validateMultiple: validateMultipleEmail } = require('@sb-itops/email');
const { getPersonInitials } = require('./get-person-initials');

/**
 * @typedef { import('../types').Person } Person
 */

/**
 * build the roles array in Communicate notification for debtor which is GroupOfPeople type
 * @param {Object} params
 * @param {Array<Person>} params.people a list of person details in a groupOfPeople
 * @param {String} params.debtorId the debtor id of this groupOfPeople
 * @param {String} params.debtorRoleDisplay the role to display on the title of this conversation for this person
 * @returns {Array} array of people in this group with format which Communicate expecting
 */
function buildRolesForGroupOfPeopleDebtor({ people, debtorId, debtorRoleDisplay = '' }) {
  return people.reduce((roles, person) => {
    if (person.email) {
      const firstName = person.firstName || '';
      const lastName = person.lastName || '';
      const initials = getPersonInitials({ firstName, lastName });
      const contactEmails = emailMarshal(person.email, { showDisplayName: false });
      const email = validateMultipleEmail(contactEmails, { allowDisplayName: false })
        ? contactEmails.split(', ')[0]
        : contactEmails;
      roles.push({
        externalRef: `smokeball:contact:${debtorId}`,
        email,
        firstName, // Communicate requires this field, it accepts empty string
        lastName, // Communicate requires this field, it accepts empty string
        initials,
        roleDisplay: debtorRoleDisplay, // Communicate requires this field, it accepts empty string
      });
    }
    return roles;
  }, []);
}

/**
 * build the roles array in Communicate notification for debtor with any type except GroupOfPeople
 * @param {Object} params
 * @param {String} params.debtorId if of this debtor
 * @param {String} params.to email address(with or without name) of this debtor
 * @param {String} params.debtorFirstName
 * @param {String} params.debtorLastName
 * @param {String} params.debtorInitials
 * @param {String} params.debtorRoleDisplay the role to display on the title of this conversation for this debtor
 * @returns {Array} array of this one debtor details with format which Communicate expecting
 */
function buildRolesForNonGroupOfPeopleDebtor({
  debtorId,
  to,
  debtorFirstName = '',
  debtorLastName = '',
  debtorInitials,
  debtorRoleDisplay = '',
}) {
  return [
    {
      externalRef: `smokeball:contact:${debtorId}`,
      email: emailMarshal(to, { showDisplayName: false }),
      firstName: debtorFirstName, // Communicate requires this field, it accepts empty string
      lastName: debtorLastName, // Communicate requires this field, it accepts empty string
      initials: debtorInitials || undefined, // Communicate will generate initials, please keep it undefined if not provided
      roleDisplay: debtorRoleDisplay, // Communicate requires this field, it accepts empty string
    },
  ];
}

module.exports = {
  buildRolesForGroupOfPeopleDebtor,
  buildRolesForNonGroupOfPeopleDebtor,
};
