'use strict';

const createSummaryBox = (invoiceSummaryHtml) => {
  const summaryBox = `
  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3"
  role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
  <tbody>
    <tr>
      <td>
        <table align="left" border="0" cellpadding="0" cellspacing="0"
          class="row-content stack" role="presentation"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; color: #000000; width: 560px;"
          width="560">
          <tbody>
            <tr>
              <td class="column"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top;"
                width="100%">
                <table border="0" cellpadding="0" cellspacing="0"
                  role="presentation"
                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                  width="100%">
                  <tr>
                    <td colspan="3"
                      style="font-size:7px;height:20px;background-color:#FFFFFF"
                      width="100%"></td>
                  </tr>
                  <tr>
                    <td
                      style="border-bottom:0px solid #FFFFFF;padding-left:20px;padding-right:20px;padding-top:0px;padding-bottom:0px;width:540px;">

                      ${
                        invoiceSummaryHtml
                          ? `<table border="0" cellpadding="0" cellspacing="0"
                        class="icons_block" role="presentation"
                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                        width="100%">
                        <tr>
                          <td
                            style="color:#777777;text-align:left;font-family:inherit;font-size:14px;padding-top:20px;">
                            <table cellpadding="0" cellspacing="0"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td style="text-align:left;">
                                  <!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
                                  <!--[if !vml]><!-->
                                  <table cellpadding="0"
                                    cellspacing="0"
                                    class="icons-inner"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                    <!--<![endif]-->
                                    <tr>
                                      <td
                                        style="font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:14px;color:#777777;vertical-align:middle;letter-spacing:undefined;text-align:left;">
                                        INVOICE SUMMARY</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>

                      <table border="0" cellpadding="0" cellspacing="0"
                        class="text_block" role="presentation"
                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                        width="100%">
                        <tr>
                          <td
                            style="padding-bottom:10px;">
                            <div style="font-family: sans-serif">
                              <div
                                style="font-size: 14px; mso-line-height-alt: 21.6px; color: #393d47; line-height: 1.8; font-family: Helvetica Neue, Helvetica, Arial, sans-serif;">
                                <p
                                  style="margin: 0; text-align: left; mso-line-height-alt: 25.2px;">
                                  <span
                                    style="font-size:14px;">${invoiceSummaryHtml}</span></p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>`
                          : ''
                      }

                      <table border="0" cellpadding="0" cellspacing="0"
                        class="icons_block" role="presentation"
                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                        width="100%">
                        <tr>
                          <td
                            style="color:#777777;text-align:left;font-family:inherit;font-size:14px;padding-top:20px;">
                            <table cellpadding="0" cellspacing="0"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td style="text-align:left;">
                                  <!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
                                  <!--[if !vml]><!-->
                                  <table cellpadding="0"
                                    cellspacing="0"
                                    class="icons-inner"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                    <!--<![endif]-->
                                    <tr>
                                      <td
                                        style="font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-size:14px;color:#777777;vertical-align:middle;letter-spacing:undefined;text-align:left;">
                                        BALANCE DUE</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0"
                        class="text_block" role="presentation"
                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                        width="100%">
                        <tr>
                          <td
                            style="padding-bottom:20px;">
                            <div style="font-family: sans-serif">
                              <div
                                style="font-size: 12px; mso-line-height-alt: 21.6px; color: #393d47; line-height: 1.8; font-family: Helvetica Neue, Helvetica, Arial, sans-serif;">
                                <p
                                  style="margin: 0; text-align: left; mso-line-height-alt: 25.2px;">
                                  <span
                                    style="font-size:14px;">[AMOUNT_DUE]</span>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
  </table>
  <br />
  [PAY_NOW_BUTTON]
  [VIEW_INVOICE_BUTTON]
`;

  return summaryBox;
};

module.exports = {
  createSummaryBox,
};
