import * as Yup from 'yup';

export const editBillingUnitFormSchema = Yup.object().shape({
  selectedBillingUnit: Yup.string(),
  customUnit: Yup.number().when('selectedBillingUnit', {
    is: (selectedBillingUnit) => selectedBillingUnit === 'Custom',
    then: Yup.number().integer().min(1).max(60).required(),
    otherwise: Yup.number().nullable().notRequired(),
  }),
});
