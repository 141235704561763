import React from 'react';
import {
  DatePicker,
  FormLabel,
  SlidingToggle,
  TimePicker,
  MultiSelectDropdown,
  CheckboxLabelInline,
  StatelessSelect,
  TextEditor,
  Spinner,
} from '@sb-itops/react';
import classnames from 'classnames';
import { MatterTypeahead } from '@sb-matter-management/react/matter-typeahead';
import moment, { Moment } from 'moment';
import { FormData } from '@sb-itops/redux/forms2/use-form';
import { getDevExtremeResourceIdFromUserIds, humanise } from 'web/business-logic/calendar';
import { RRule, rrulestr } from 'rrule';
import { MatterSummary } from 'types';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { Party } from 'web/hooks/use-matter-parties';
import Styles from './AddEvent.module.scss';

export interface AppointmentFields {
  location?: string;
  subject?: string;
  patternAppointmentId?: string;
  recurrenceInfoId?: string;
  recurrenceInfo?: string;
  description?: string;
  matterId?: string;
  startTime?: Date;
  endTime?: Date;
  allDay?: boolean;
  resourceIds: string[];
  attendeeEntityIds: string[];
  resourceId?: string;
  rRULE?: string;
  originalId?: string;
  id?: string;
  //     Indicates the type of event, as defined by DevExpress.
  //        * 0 = non recurring event
  //        * 1 = recurring pattern series
  //        * 2 = single normal recurring occurrence
  //        * 3 = modified recurring occurrence
  //        * 4 = deleted recurring occurrence
  eventType?: number;
}

export interface IAddEventProps {
  fields: FormData<AppointmentFields>['formFields'];
  matterSummaries: MatterSummary[];
  attendees: { id: string; name: string }[];
  parties: Party[];
  partiesLoading: boolean;
  onUpdateFieldValues: FormData<AppointmentFields>['onUpdateFieldValues'];
  onFieldValueSet: FormData<AppointmentFields>['onFieldValueSet'];
  formValidation: FormData<AppointmentFields>['formValidation'];
  submitFailed: boolean;
  formSubmitting: boolean;
}

// Due to NUCWEB-807 there may be some broken matter parties. This will ensure they continue to work in the web
export const getContactIdFromBrokenParties = (id: string, parties: Party[]) =>
  parties.find((party) => party.id === id || party.matterItemId === id)?.id || '';

const nthWeekdayOfMonth = (d: Moment) => {
  const clonedDate = d.clone();
  for (let i = 1; i < 6; i += 1) {
    clonedDate.subtract(1, 'week');
    if (d.month() !== clonedDate.month()) {
      return i;
    }
  }
  // should next be reached
  return 4;
};

const NTH_DAY_OPTIONS = [
  { label: 'First', value: 1 },
  { label: 'Second', value: 2 },
  { label: 'Third', value: 3 },
  { label: 'Fourth', value: 4 },
  { label: 'Last', value: -1 },
];

const DAY_TYPE_OPTIONS = [
  { label: 'Monday', value: RRule.MO.weekday },
  { label: 'Tuesday', value: RRule.TU.weekday },
  { label: 'Wednesday', value: RRule.WE.weekday },
  { label: 'Thursday', value: RRule.TH.weekday },
  { label: 'Friday', value: RRule.FR.weekday },
  { label: 'Saturday', value: RRule.SA.weekday },
  { label: 'Sunday', value: RRule.SU.weekday },
  { label: 'Day', value: 8 },
  { label: 'Weekday', value: 9 },
  { label: 'Weekend day', value: 10 },
];

const MONTH_OPTIONS = [
  { label: 'January', value: 1 },
  { label: 'Feburary', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

const RESET_RRRULE_OPTIONS = {
  bymonth: undefined,
  byyearday: undefined,
  byweekno: undefined,
  byweekday: undefined,
};

const WORK_WEEK = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
const ALL_WEEK = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
const WEEKEND = [RRule.SA, RRule.SU];

const ContainsDay = (rRule, day) =>
  Array.isArray(rRule.options.byweekday) && rRule.options.byweekday.includes(day.weekday);

const isWorkWeek = (rRULE) => {
  if (Array.isArray(rRULE.options.byweekday)) {
    return (
      rRULE.options.byweekday.length === 5 &&
      !rRULE.options.byweekday.includes(RRule.SA.weekday) &&
      !rRULE.options.byweekday.includes(RRule.SU.weekday)
    );
  }
  return false;
};
const isWeek = (rRULE) => {
  if (Array.isArray(rRULE.options.byweekday)) {
    return rRULE.options.byweekday.length === 7;
  }
  return false;
};
const isWeekend = (rRULE) => {
  if (Array.isArray(rRULE.options.byweekday)) {
    return (
      rRULE.options.byweekday.length === 2 &&
      rRULE.options.byweekday.includes(RRule.SA.weekday) &&
      rRULE.options.byweekday.includes(RRule.SU.weekday)
    );
  }
  return false;
};

export const AddEvent = ({
  fields: {
    location,
    subject,
    description,
    allDay,
    endTime,
    startTime,
    matterId,
    resourceIds,
    eventType,
    rRULE,
    originalId,
    id,
    patternAppointmentId,
    attendeeEntityIds,
  },
  onUpdateFieldValues,
  onFieldValueSet,
  matterSummaries,
  attendees,
  parties,
  partiesLoading,
  formValidation,
  submitFailed,
  formSubmitting,
}: IAddEventProps) => {
  const rRULEParsed = rRULE?.value
    ? rrulestr(rRULE.value)
    : new RRule({
        freq: RRule.WEEKLY,
        count: 1,
        interval: 1,
        byweekday: moment(startTime?.value).isoWeekday() - 1,
      });

  let selectedDayType = rRULEParsed?.options?.byweekday?.[0] || moment(startTime?.value).isoWeekday() - 1;
  if (rRULEParsed?.options?.byweekday?.length > 1) {
    if (isWeek(rRULEParsed)) {
      selectedDayType = 8;
    }
    if (isWorkWeek(rRULEParsed)) {
      selectedDayType = 9;
    }
    if (isWeekend(rRULEParsed)) {
      selectedDayType = 10;
    }
  }

  const isModifiedRecurringAppointment = !!patternAppointmentId?.value || (!!originalId?.value && !id?.value);
  const isRecurringAppointment = !!eventType?.value;

  return (
    <div className={Styles.addEvent}>
      <fieldset className={Styles.formFieldset}>
        <div className="row">
          <div className={classnames('col-xs-12 form-group', subject?.isInvalid && submitFailed && 'has-error')}>
            <FormLabel label="Subject" field={subject} submitFailed={submitFailed} />
            <input
              className={classnames('form-control')}
              type="text"
              // Autofocus is desirable for modals so this eslint warning can be ignored
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={subject?.value || ''}
              onChange={(e) => {
                onUpdateFieldValues('subject', e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Location" optional field={location} />
            <input
              className={classnames('form-control')}
              type="text"
              value={location?.value || ''}
              onChange={(e) => {
                onUpdateFieldValues('location', e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classnames('row', Styles.timeEntryRow)}>
          <div className="col-xs-8 form-group">
            <FormLabel label="Start" field={startTime} />
            <DatePicker
              onSelect={(date) => {
                if (allDay?.value) {
                  onUpdateFieldValues('startTime', moment(date).startOf('day').toDate());
                  onUpdateFieldValues('endTime', moment(date).endOf('day').toDate());
                  return;
                }
                const newDate = moment(date).set({
                  hour: moment(startTime?.value || '').get('hour'),
                  minute: moment(startTime?.value || '').get('minute'),
                });
                const duration = moment(endTime?.value).diff(moment(startTime?.value));
                onUpdateFieldValues('endTime', moment(newDate).add(duration).toDate());
                onUpdateFieldValues('startTime', newDate.toDate());
              }}
              // minDate={moment().toDate()}
              value={startTime && moment(startTime.value).toDate()}
              hasError={startTime && startTime.isInvalid}
              format="DD/MM/YYYY"
              hideDelete
            />
          </div>
          <div className="col-xs-4 form-group">
            <TimePicker
              disabled={allDay?.value}
              value={startTime && moment(startTime.value).format('hh:mm A')}
              onSelect={(value) => {
                if (value) {
                  const newDate = moment(startTime?.value).set({
                    hour: moment(value, 'hh:mm A').get('hour'),
                    minute: moment(value, 'hh:mm A').get('minute'),
                  });
                  const duration = moment(endTime?.value).diff(moment(startTime?.value));
                  onUpdateFieldValues('endTime', moment(newDate).add(duration).toDate());
                  onUpdateFieldValues('startTime', newDate.toDate());
                }
              }}
            />
          </div>
        </div>
        <div className={classnames('row', Styles.timeEntryRow)}>
          <div className="col-xs-8 form-group">
            <FormLabel label="End" field={endTime} />
            <DatePicker
              onSelect={(date) => {
                if (allDay?.value) {
                  onUpdateFieldValues('startTime', moment(date).startOf('day').toDate());
                  onUpdateFieldValues('endTime', moment(date).endOf('day').toDate());
                  return;
                }
                const newDate = moment(date).set({
                  hour: moment(endTime?.value || '').get('hour'),
                  minute: moment(endTime?.value || '').get('minute'),
                });
                if (newDate.isBefore(moment(startTime?.value))) {
                  onUpdateFieldValues('startTime', newDate.toDate());
                }
                onUpdateFieldValues('endTime', newDate.toDate());
              }}
              // minDate={moment().toDate()}
              value={endTime && moment(endTime.value).toDate()}
              hasError={endTime && endTime.isInvalid}
              format="DD/MM/YYYY"
              hideDelete
            />
          </div>
          <div className="col-xs-4 form-group">
            <TimePicker
              disabled={allDay?.value}
              value={endTime && moment(endTime.value).format('hh:mm A')}
              onSelect={(value) => {
                if (value) {
                  const newDate = moment(endTime?.value).set({
                    hour: moment(value, 'hh:mm A').get('hour'),
                    minute: moment(value, 'hh:mm A').get('minute'),
                  });
                  if (newDate.isBefore(moment(startTime?.value))) {
                    onUpdateFieldValues('startTime', newDate.toDate());
                  }
                  onUpdateFieldValues('endTime', newDate.toDate());
                }
              }}
              showDuration={humanise(
                moment.duration(moment(endTime?.value || '').diff(moment(startTime?.value || ''))),
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className={classnames('col-xs-12 form-group', Styles.allDayRow)}>
            <div className={Styles.allDay}>Recurring?</div>
            <SlidingToggle
              scope="appointment-recurring"
              onChange={(key, value) => {
                onUpdateFieldValues('eventType', value ? 1 : 0);
                const newRule = new RRule({
                  ...rRULEParsed.origOptions,
                });
                if (value) {
                  onUpdateFieldValues('rRULE', newRule.toString());
                } else {
                  onUpdateFieldValues('rRULE', '');
                }
              }}
              disabled={isModifiedRecurringAppointment}
              selected={isRecurringAppointment}
            />
            <div className={Styles.allDay}>All Day?</div>
            <SlidingToggle
              scope="appointment-all-day"
              onChange={(key, value) => {
                onUpdateFieldValues('allDay', value);
                if (value) {
                  onUpdateFieldValues('startTime', moment(startTime?.value).startOf('day').toDate());
                  onUpdateFieldValues('endTime', moment(startTime?.value).endOf('day').toDate());
                } else {
                  // Set time to nearest 30 minute interval from now, but on the day of the appointment
                  const remainder = 30 - (moment().minute() % 30);
                  const start = moment(startTime?.value).startOf('day');
                  start.hour(moment().get('hour'));
                  start.minute(moment().get('minutes')).add(remainder, 'minutes');
                  const end = moment(start).add(30, 'minutes');
                  onUpdateFieldValues('startTime', start.toDate());
                  onUpdateFieldValues('endTime', end.toDate());
                }
              }}
              selected={allDay?.value}
            />
          </div>
        </div>
        {isRecurringAppointment && !isModifiedRecurringAppointment && (
          <>
            <div className="row">
              <div className="col-xs-12">
                <FormLabel label="Recurrence Pattern" />
              </div>
              <div className={classnames('col-xs-3 form-group')}>
                <div className={classnames('radio')}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      value={RRule.DAILY}
                      checked={rRULEParsed.options.freq === RRule.DAILY}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          ...RESET_RRRULE_OPTIONS,
                          freq: RRule.DAILY,
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    Daily
                  </label>
                </div>
                <div className={classnames('radio')}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      value={RRule.WEEKLY}
                      checked={rRULEParsed.options.freq === RRule.WEEKLY}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          ...RESET_RRRULE_OPTIONS,
                          freq: RRule.WEEKLY,
                          byweekday: moment(startTime?.value).isoWeekday() - 1,
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    Weekly
                  </label>
                </div>
                <div className={classnames('radio')}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      value={RRule.MONTHLY}
                      checked={rRULEParsed.options.freq === RRule.MONTHLY}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          ...RESET_RRRULE_OPTIONS,
                          freq: RRule.MONTHLY,
                          bymonthday: moment(startTime?.value).date(),
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    Monthly
                  </label>
                </div>
                <div className={classnames('radio')}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      value={RRule.YEARLY}
                      checked={rRULEParsed.options.freq === RRule.YEARLY}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          ...RESET_RRRULE_OPTIONS,
                          freq: RRule.YEARLY,
                          bymonthday: moment(startTime?.value).date(),
                          bymonth: moment(startTime?.value).month() + 1,
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    Yearly
                  </label>
                </div>
              </div>
              <div className={classnames('col-xs-9 form-group')}>
                {rRULEParsed.options.freq === RRule.DAILY && (
                  <>
                    <div className={classnames('radio')}>
                      <label className={Styles.rateTypeOption}>
                        <input
                          type="radio"
                          checked={!isWorkWeek(rRULEParsed)}
                          onChange={() => {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              interval: 1,
                              byweekday: undefined,
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }}
                        />
                        Every{' '}
                      </label>
                      <input
                        type="number"
                        className={Styles.radioInput}
                        value={rRULEParsed.options.interval}
                        onChange={(e) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            byweekday: undefined,
                            interval: Number(e.target.value || 1),
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      day/s
                    </div>
                    <div className={classnames('radio')}>
                      <label className={Styles.rateTypeOption}>
                        <input
                          type="radio"
                          checked={isWorkWeek(rRULEParsed)}
                          onChange={() => {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: WORK_WEEK,
                              interval: 1,
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }}
                        />
                        Every workday
                      </label>
                    </div>
                  </>
                )}
                {rRULEParsed.options.freq === RRule.WEEKLY && (
                  <>
                    Recur every{' '}
                    <input
                      type="number"
                      className={Styles.radioInput}
                      value={rRULEParsed.options.interval}
                      onChange={(e) => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          interval: Number(e.target.value || 1),
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    week/s on
                    <div>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.MO)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.MO.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.MO.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Monday
                      </CheckboxLabelInline>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.TU)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.TU.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.TU.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Tuesday
                      </CheckboxLabelInline>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.WE)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.WE.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.WE.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Wednesday
                      </CheckboxLabelInline>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.TH)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.TH.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.TH.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Thursday
                      </CheckboxLabelInline>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.FR)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.FR.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.FR.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Friday
                      </CheckboxLabelInline>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.SA)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.SA.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.SA.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Saturday
                      </CheckboxLabelInline>
                      <CheckboxLabelInline
                        checked={ContainsDay(rRULEParsed, RRule.SU)}
                        onChange={(checked) => {
                          if (checked) {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: [...(rRULEParsed.options.byweekday || []), RRule.SU.weekday],
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          } else {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              byweekday: rRULEParsed.options.byweekday.filter((i) => i !== RRule.SU.weekday),
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }
                        }}
                      >
                        Sunday
                      </CheckboxLabelInline>
                    </div>
                  </>
                )}
                {rRULEParsed.options.freq === RRule.MONTHLY && (
                  <>
                    <div className={classnames('radio')}>
                      <label className={Styles.rateTypeOption}>
                        <input
                          type="radio"
                          checked={rRULEParsed.options.bymonthday.length !== 0}
                          onChange={() => {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: moment(startTime?.value).date(),
                              bysetpos: undefined,
                              byweekday: undefined,
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }}
                        />
                        Day{' '}
                      </label>
                      <input
                        type="number"
                        className={Styles.radioInput}
                        value={rRULEParsed.options.bymonthday?.[0] || moment(startTime?.value).date()}
                        onChange={(e) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            byweekday: undefined,
                            bysetpos: undefined,
                            bymonthday: Number(e.target.value || 1),
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      of every
                      <input
                        type="number"
                        className={Styles.radioInput}
                        value={rRULEParsed.options.interval}
                        onChange={(e) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            interval: Number(e.target.value || 1),
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      month/s
                    </div>
                    <div className={classnames('radio', Styles.flexLine)}>
                      <label className={Styles.rateTypeOption}>
                        <input
                          type="radio"
                          checked={rRULEParsed.options.bymonthday.length === 0}
                          onChange={() => {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              bysetpos: nthWeekdayOfMonth(moment(startTime?.value)),
                              byweekday: moment(startTime?.value).isoWeekday() - 1,
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }}
                        />
                        The{' '}
                      </label>
                      <StatelessSelect
                        selectedOption={
                          rRULEParsed.options.bysetpos?.[0] || nthWeekdayOfMonth(moment(startTime?.value))
                        }
                        stateless
                        className={Styles.dropdownInput}
                        options={NTH_DAY_OPTIONS}
                        onChange={(opt) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            byweekday: rRULEParsed.options.byweekday || 1,
                            bymonthday: undefined,
                            bysetpos: opt.value,
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      <StatelessSelect
                        selectedOption={selectedDayType}
                        stateless
                        className={Styles.dropdownInput}
                        options={DAY_TYPE_OPTIONS}
                        onChange={(opt) => {
                          let newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            bymonthday: undefined,
                            byweekday: opt.value,
                          });
                          if (opt.value === 8) {
                            newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              byweekday: ALL_WEEK,
                            });
                          }
                          if (opt.value === 9) {
                            newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              byweekday: WORK_WEEK,
                            });
                          }
                          if (opt.value === 10) {
                            newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              byweekday: WEEKEND,
                            });
                          }
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      of every
                      <input
                        type="number"
                        className={Styles.radioInput}
                        value={rRULEParsed.options.interval}
                        onChange={(e) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            interval: Number(e.target.value || 1),
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      month/s
                    </div>
                  </>
                )}
                {rRULEParsed.options.freq === RRule.YEARLY && (
                  <>
                    <div className={classnames('radio', Styles.flexLine)}>
                      <label className={Styles.rateTypeOption}>
                        <input
                          type="radio"
                          checked={rRULEParsed.options.bymonthday.length !== 0}
                          onChange={() => {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: moment(startTime?.value).date(),
                              bymonth: moment(startTime?.value).month() + 1,
                              bysetpos: undefined,
                              byweekday: undefined,
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }}
                        />
                      </label>
                      <StatelessSelect
                        selectedOption={rRULEParsed.options.bymonth?.[0] || moment(startTime?.value).month() + 1}
                        stateless
                        className={Styles.dropdownInput}
                        options={MONTH_OPTIONS}
                        onChange={(opt) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            bysetpos: undefined,
                            byweekday: undefined,
                            bymonth: opt.value,
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      <input
                        type="number"
                        className={Styles.radioInput}
                        value={rRULEParsed.options.bymonthday?.[0] || moment(startTime?.value).date()}
                        onChange={(e) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            byweekday: undefined,
                            bysetpos: undefined,
                            bymonthday: Number(e.target.value || 1),
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                    </div>
                    <div className={classnames('radio', Styles.flexLine)}>
                      <label className={Styles.rateTypeOption}>
                        <input
                          type="radio"
                          checked={rRULEParsed.options.bymonthday.length === 0}
                          onChange={() => {
                            const newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonth: moment(startTime?.value).month() + 1,
                              bymonthday: undefined,
                              bysetpos: nthWeekdayOfMonth(moment(startTime?.value)),
                              byweekday: moment(startTime?.value).isoWeekday() - 1,
                            });
                            onUpdateFieldValues('rRULE', newRule.toString());
                          }}
                        />
                        The{' '}
                      </label>
                      <StatelessSelect
                        selectedOption={
                          rRULEParsed.options.bysetpos?.[0] || nthWeekdayOfMonth(moment(startTime?.value))
                        }
                        stateless
                        className={Styles.dropdownInput}
                        options={NTH_DAY_OPTIONS}
                        onChange={(opt) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            byweekday: rRULEParsed.options.byweekday || 1,
                            bymonth: rRULEParsed.options.bymonth || 1,
                            bymonthday: undefined,
                            bysetpos: opt.value,
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      <StatelessSelect
                        selectedOption={selectedDayType}
                        stateless
                        className={Styles.dropdownInput}
                        options={DAY_TYPE_OPTIONS}
                        onChange={(opt) => {
                          let newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            bymonthday: undefined,
                            byweekday: opt.value,
                          });
                          if (opt.value === 8) {
                            newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              byweekday: ALL_WEEK,
                            });
                          }
                          if (opt.value === 9) {
                            newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              byweekday: WORK_WEEK,
                            });
                          }
                          if (opt.value === 10) {
                            newRule = new RRule({
                              ...rRULEParsed.origOptions,
                              bymonthday: undefined,
                              byweekday: WEEKEND,
                            });
                          }
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                      of{' '}
                      <StatelessSelect
                        selectedOption={rRULEParsed.options.bymonth?.[0] || moment(startTime?.value).month() + 1}
                        stateless
                        className={Styles.dropdownInput}
                        options={MONTH_OPTIONS}
                        onChange={(opt) => {
                          const newRule = new RRule({
                            ...rRULEParsed.origOptions,
                            bymonthday: undefined,
                            byweekday: rRULEParsed.options.byweekday || 1,
                            bymonth: opt.value,
                          });
                          onUpdateFieldValues('rRULE', newRule.toString());
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <FormLabel label="Recurrence End" />
              </div>
              <div className="col-xs-12 form-group">
                <div className={classnames('radio')}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      checked={!rRULEParsed.options.count && !rRULEParsed.options.until}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          count: undefined,
                          until: undefined,
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    No end date
                  </label>
                </div>
                <div className={classnames('radio', Styles.flexLine)}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      checked={!!rRULEParsed.options.count}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          count: 10,
                          until: undefined,
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    End after{' '}
                  </label>
                  <input
                    type="number"
                    className={Styles.radioInput}
                    value={rRULEParsed.options.count || 10}
                    onChange={(e) => {
                      const newRule = new RRule({
                        ...rRULEParsed.origOptions,
                        until: undefined,
                        count: Number(e.target.value || 1),
                      });
                      onUpdateFieldValues('rRULE', newRule.toString());
                    }}
                  />
                  occurrences
                </div>
                <div className={classnames('radio', Styles.flexLine)}>
                  <label className={Styles.rateTypeOption}>
                    <input
                      type="radio"
                      checked={!!rRULEParsed.options.until}
                      onChange={() => {
                        const newRule = new RRule({
                          ...rRULEParsed.origOptions,
                          count: undefined,
                          until: new Date(),
                        });
                        onUpdateFieldValues('rRULE', newRule.toString());
                      }}
                    />
                    End by{' '}
                  </label>
                  <DatePicker
                    containerClassName={Styles.calendarInput}
                    onSelect={(date) => {
                      const newRule = new RRule({
                        ...rRULEParsed.origOptions,
                        count: undefined,
                        until: date,
                      });
                      onUpdateFieldValues('rRULE', newRule.toString());
                    }}
                    value={rRULEParsed.options.until || undefined}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Matter" field={matterId} optional />
            {/* Dumb workaround for mattertypeahead which cant be reset, this will reset it after the form is submitted so save and new works */}
            {formSubmitting ? (
              <MatterTypeahead
                key={1}
                onValueChange={(value) => onUpdateFieldValues('matterId', value?.id)}
                onClear={(value) => onUpdateFieldValues('matterId', value?.id)}
                initialId={matterId?.value}
                filter="all"
                matterSummaries={matterSummaries}
              />
            ) : (
              <MatterTypeahead
                key={2}
                onValueChange={(value) => onUpdateFieldValues('matterId', value?.id)}
                onClear={(value) => onUpdateFieldValues('matterId', value?.id)}
                initialId={matterId?.value}
                filter="all"
                matterSummaries={matterSummaries}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel
              label="Attendees"
              field={
                {
                  isInvalid: !!formValidation.resourceIds,
                  invalidReason: formValidation.resourceIds,
                } as Parameters<typeof FormLabel>[0]['field']
              }
              submitFailed={submitFailed}
            />
            <MultiSelectDropdown
              options={attendees.map((staff) => ({ label: staff.name, value: staff.id, searchText: staff.name }))}
              hasError={!!formValidation.resourceIds && submitFailed}
              onSelectedIdsChanged={(selectedStaffOptions) => {
                const selectedStaffIds = selectedStaffOptions.map((item) => item.value);
                // special format for devExtreme
                // The onSave function is also used by devExtreme components so this format must be compatible
                onFieldValueSet('resourceIds', selectedStaffIds);
                onFieldValueSet('resourceId', getDevExtremeResourceIdFromUserIds(selectedStaffIds));
              }}
              value={(resourceIds || []).map((resourceId) => resourceId.value)}
            />
          </div>
        </div>
        {matterId?.value && (
          <div className="row">
            <div className="col-xs-12 form-group">
              <FormLabel
                label="Parties"
                field={
                  {
                    isInvalid: !!formValidation.attendeeEntityIds,
                    invalidReason: formValidation.attendeeEntityIds,
                  } as Parameters<typeof FormLabel>[0]['field']
                }
                submitFailed={submitFailed}
                optional
              />
              <span className={Styles.infoIcon}>
                <i className={classnames('icon', 'icon-question-mark', Styles.icon)} />
                <span className={Styles.infoText}>
                  Invites to external parties will be sent via the Client Portal.{' '}
                  <a
                    className={Styles.link}
                    href={`${getSupportHubUrl()}/articles/11580202065559`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{' '}
                  to learn more.
                </span>
              </span>
              {partiesLoading ? (
                <div>
                  <Spinner small />
                </div>
              ) : (
                <MultiSelectDropdown
                  options={parties.map((party) => ({
                    label: party.displayName,
                    value: party.id,
                    searchText: party.displayName,
                    disabled: !party.email,
                    disabledText: 'Email not set',
                  }))}
                  hasError={!!formValidation.attendeeEntityIds && submitFailed}
                  onSelectedIdsChanged={(selectedPartiesOptions) => {
                    const selectedPartyIds = selectedPartiesOptions.map((item) => item.value);
                    onFieldValueSet('attendeeEntityIds', selectedPartyIds);
                  }}
                  isOptionDisabled={(option) => option.disabled}
                  value={(attendeeEntityIds || [])
                    .map((entityId) => getContactIdFromBrokenParties(entityId.value, parties))
                    .filter((entityId) => !!entityId)}
                />
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Comment" optional field={description} />
            <TextEditor
              value={description?.value || ''}
              hideToolBar
              fixedHeight
              onChange={(v, delta, source) => {
                if (source !== 'user') {
                  // Ignore any changes made by react-quill itself instead of user input
                  return;
                }
                onUpdateFieldValues('description', v);
              }}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};
