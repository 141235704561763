import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes } from '../button';
import { Modal } from '../modal';
import * as Styles from './ConfirmModal.module.scss';

export const ConfirmModal = ({ isVisible, title, children, message, confirmBtnText, onConfirm, onCancel }) => (
  <Modal
    title={title}
    body={
      children ? (
        <div>{children}</div>
      ) : (
        <div>
          <p>{message}</p>
        </div>
      )
    }
    footer={
      <div className={Styles.footerContainer}>
        <Button type={buttonTypes.warning} onClick={onConfirm}>
          {confirmBtnText}
        </Button>
        <Button type={buttonTypes.secondary} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    }
    onModalClose={onCancel}
    isVisible={isVisible}
    shouldCloseOnOverlayClick={false}
  />
);

ConfirmModal.displayName = 'ConfirmModal';

ConfirmModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
  confirmBtnText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  children: null,
  message: '',
  title: '',
  confirmBtnText: '',
};
