import * as types from './types';

/* State Shape
{
  itemSelectionState  : Object<String, Boolean> : Selection state of items currently being tracked. A value of true indicates selected, false deselected.
  allSelected : Boolean : Boolean which overrides item state to effectively set all items as selected.
}
*/

// Defines the initial values set in state.
const initialState = {
  itemSelectionState: {},
  allSelected: false,
};

// Action -> Handler function lookup.
const reducerLookup = {
  [types.SELECT_ITEMS]: selectItems,
  [types.DESELECT_ITEMS]: deselectItems,
  [types.TOGGLE_ITEMS]: toggleItems,
  [types.SELECT_ALL_ITEMS]: selectAllItems,
  [types.DESELECT_ALL_ITEMS]: deselectAllItems,
};

/**
 * The main reducer function for the feature.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggering a potential update to the store.
 * @return {object} The new store state.
 */
export const reducer = (state = initialState, action = {}) => {
  const reducerFn = reducerLookup[action.type];
  return reducerFn ? reducerFn(state, action) : state;
};

/**
 * Action handler for types.SELECT_ITEMS.
 * Used to mark multiple items as selected.
 */
function selectItems(state, action) {
  const { items } = action.payload;

  const newlySelectedItems = items.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});

  return {
    ...state,
    itemSelectionState: {
      ...state.itemSelectionState,
      ...newlySelectedItems,
    },
  };
}

/**
 * Action handler for types.DESELECT_ITEMS.
 * Used to mark multiple items as deselected.
 */
function deselectItems(state, action) {
  const { items } = action.payload;

  const newlyDeselectedItems = items.reduce((acc, item) => {
    acc[item] = false;
    return acc;
  }, {});

  return {
    ...state,
    allSelected: items.length !== 0,
    itemSelectionState: {
      ...state.itemSelectionState,
      ...newlyDeselectedItems,
    },
  };
}

/**
 * Action handler for types.TOGGLE_ITEMS.
 *
 * For each id passed in the ids payload property, if the id is selected, it become deselected,
 * if the item is deselected, it becomes selected.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function toggleItems(state, action) {
  const { items } = action.payload;
  const stateUpdates = items.reduce(
    (acc, item) => {
      acc.toggledItems[item] = !state.itemSelectionState[item];
      acc.deselectedCount += acc.toggledItems[item] ? 0 : 1; // increment if previously selected item now deselected.
      return acc;
    },
    {
      toggledItems: {},
      deselectedCount: 0,
    },
  );

  return {
    ...state,
    allSelected: state.allSelected && stateUpdates.deselectedCount === 0, // if at least one became deselected, we are no longer all selected.
    itemSelectionState: {
      ...state.itemSelectionState,
      ...stateUpdates.toggledItems,
    },
  };
}

/**
 * Action handler for types.SELECT_ALL_ITEMS.
 *
 * Sets all deselected items as selected.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function selectAllItems(state) {
  const itemSelectionState = Object.keys(state.itemSelectionState).reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});

  return {
    ...state,
    allSelected: true,
    itemSelectionState,
  };
}

/**
 * Action handler for types.DESELECT_ALL_ITEMS.
 *
 * Sets all selected items as deselected.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function deselectAllItems(state) {
  const itemSelectionState = Object.keys(state.itemSelectionState).reduce((acc, item) => {
    acc[item] = false;
    return acc;
  }, {});

  return {
    ...state,
    allSelected: false,
    itemSelectionState,
  };
}
