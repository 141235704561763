'use strict';

const { getRegion } = require('@sb-itops/region');

const region = getRegion();

/**
 * This function is required to transform data from graphql to a useable format for the app
 *  - From: the current region's fee schedule
 *  - To: mapped object (fee schedule by region)
 *
 * NB: the formatStripeFeeScheduleForGraphql function was used for the initial transformation
 *    - File: monorepo/integration/endpoints/webapp-graphql-server/resolvers/billing/payment-provider-settings.js
 *
 * @param {Object} params
 * @param {Array<Object>} params.formattedFeeScheduleFromGQL
 * @returns {Object}
 */
function mapStripeFeeScheduleByRegion({ formattedFeeScheduleFromGQL }) {
  const mappedResult = {
    [region]: formattedFeeScheduleFromGQL,
  };

  return mappedResult;
}

// We can't return a map from GQL so we use special formatter for and from GQL.
// TODO This should be solved on global level
function convertSettingsForGQL({ formattedSettings }) {
  return formattedSettings
    ? { ...formattedSettings, linkedAccounts: Object.values(formattedSettings.linkedAccounts || {}) }
    : {};
}

function convertSettingsFromGQL({ formattedSettingsFromGQL }) {
  if (!formattedSettingsFromGQL) {
    return {};
  }

  const linkedAccounts = (formattedSettingsFromGQL.linkedAccounts || []).reduce((acc, linkedAcc) => {
    acc[linkedAcc.bankAccountId] = linkedAcc;
    return acc;
  }, {});

  const feeSchedule = mapStripeFeeScheduleByRegion({
    formattedFeeScheduleFromGQL: formattedSettingsFromGQL.feeSchedule,
  });

  return { ...formattedSettingsFromGQL, linkedAccounts, feeSchedule };
}

module.exports = {
  convertSettingsForGQL,
  convertSettingsFromGQL,
};
