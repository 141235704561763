import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'einvoice-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbEInvoiceSettingsService',
  syncType: syncTypes.ALL,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;
