import { sort as sortItems } from '@sb-itops/sort';

angular.module('sb.billing.webapp').component('sbContactList', {
  bindings: { onSelect: '&?', data: '<' },
  templateUrl: 'ng-components/contact-list/contact-list.html',
  controller: function(sbUnsavedChangesService) {
    const ctrl = this;
    // Set up saveable state and load any saved state.
    const stateKeyPrefix = 'contact-list-';

    ctrl.sort = sort;
    ctrl.listId = ctrl.listId || 'contact-list'

    ctrl.$onInit = () => {
      loadViewState();
    };

    ctrl.$onDestroy = () => {
      saveState(ctrl.listId);
    };

    ctrl.$onChanges = () => {
      loadViewState();
    };

    function loadViewState(){
      const oldState = sbUnsavedChangesService.loadMemory(`${stateKeyPrefix}-${ctrl.listId}`) || {};
      ctrl.viewState = {
        data: [...ctrl.data],
        ...oldState
      };

      sort(oldState);
    }

    function saveState (listId) {
      const viewState = {
        sortBy: ctrl.viewState.sortBy,
        sortDirection: ctrl.viewState.sortDirection,
      }
      sbUnsavedChangesService.saveMemory(`${stateKeyPrefix}-${listId}`, viewState);
    }

    function getSortBy (sortBy) {
      if (sortBy === 'displayName') {
        return 'contactDisplay';
      }
      return sortBy;
    }

    function sort({ sortDirection = 'asc', sortBy = 'displayName' }) {
      const newDirection = sortDirection.toLowerCase();

      ctrl.viewState = {
        ...ctrl.viewState,
        sortBy,
        sortDirection: newDirection,
        data: sortItems([...ctrl.data], [getSortBy(sortBy)], [newDirection])
      };

      saveState(ctrl.listId);
    }
  }

});
