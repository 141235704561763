'use strict';

angular.module('sb.billing.webapp').component('sbBankReconciliationPdf', {
  templateUrl: 'ng-components/bank-reconciliation-pdf/bank-reconciliation-pdf.html',
  bindings: { bankReconciliationId: '<', waitingForNotification: '<' },
  controller: function ($state, sbLoggerService, sbBankReconciliationService, sbGenericEndpointService, 
    sbMessageDisplayService, sbNotifiedOperationP, sbUnsavedChangesService, sbLocalisationService ) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbBankReconciliationPdf');

    let isDestroyed = false;

    ctrl.isReloading = false;

    ctrl.$onDestroy = () => {
      isDestroyed = true;
      if (ctrl.bankReconciliationId && ctrl.pdfDataUrl) {
        sbUnsavedChangesService.saveMemory(`bank-recon-pdf_${ctrl.bankReconciliationId}`, { pdfDataUrl: ctrl.pdfDataUrl });
      }
    };

    ctrl.$onInit = () => {
      if (loadFromMemory()) {
        return;
      }

      ctrl.isReloading = true;

      if (ctrl.waitingForNotification) {
        refreshPdfOnNotification();
      } else {
        refreshPdf();
      }
    };

    function loadFromMemory () {
      const loadedMemory = sbUnsavedChangesService.loadMemory(`bank-recon-pdf_${ctrl.bankReconciliationId}`);
      if (!_.isEmpty(loadedMemory)) {
        ctrl.pdfDataUrl = loadedMemory.pdfDataUrl;
        return true;
      }
      return false;
    }

    function refreshPdfOnNotification () {
      log.info('refresh receipt PDF after notification');
      return waitForBankReconciliationNotificationP().then(redirectToPdf);
    }

    function waitForBankReconciliationNotificationP () {
      log.info('wait for bank reconciliation notification');

      return sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'ReconciliationPDFSyncNotification',
        completionFilterFn: messageFilter,
        timeoutMs: 20000
      }).catch(handleError);
    }

    function refreshPdf () {
      log.info('refreshing reconciliation PDF');

      downloadPdfP()
        .then(renderPdf)
        .catch(handleError)
        .finally(() => {
          ctrl.isReloading = false;
        });
    }

    function downloadPdfP () {
      const docStreamEndpoint = 'billing/document-stream';
      const docStreamConfig = {
        skipCamelCase: true
      };

      return sbGenericEndpointService.getPayloadP(docStreamEndpoint, `BankReconciliations/${ctrl.bankReconciliationId}`, docStreamConfig);
    }

    function messageFilter (message) {
      return message.bankReconciliationId === ctrl.bankReconciliationId;
    }

    // redirect to change url parameters so user can navigate
    // away from this tab and back again and avoid waiting for notifications
    function redirectToPdf () {
      $state.go('home.billing.bank-reconciliation-pdf', { bankReconciliationId: ctrl.bankReconciliationId, waitingForNotification: false });
    }

    function handleError (e) {
      if (!isDestroyed) {
        const bankRec = sbBankReconciliationService.getById(ctrl.bankReconciliationId);
        log.error(`Failed to download bank reconciliation pdf for bankReconciliationId: '${ctrl.bankReconciliationId}'`,e); //TODO printing e here prints [Object]
        sbMessageDisplayService.error(
          sbMessageDisplayService
          .builder()
          .text(getErrorMessage(bankRec))
        );
      }
    }

    function renderPdf (pdfB64) {
      log.info(`Loaded PDF data for transaction ID '${ctrl.bankReconciliationId}'`);
      ctrl.pdfDataUrl = `data:application/pdf;base64,${pdfB64}`;
    }

    function getErrorMessage (bankRec) {
      if (!bankRec) {
        return 'Failed to download bank reconciliation';
      }

      const { startDate, endDate } = bankRec;
      const t = sbLocalisationService.t;

      return `Failed to download Bank Reconciliation for time period ${t('date', {yyyymmdd: startDate})} - ${t('date', {yyyymmdd: endDate})}`;
    }
  }
});
