import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';

import { PaymentProviderSettingsForm } from './PaymentProviderSettingsForm';

const mapStateToProps = () => {
  const activeProvider = getActiveProvider();
  return {
    activeProvider,
  };
};

const mapDispatchToProps = () => ({});

export const PaymentProviderSettingsFormContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(PaymentProviderSettingsForm),
);

PaymentProviderSettingsFormContainer.displayName = 'PaymentProviderSettingsFormContainer';

PaymentProviderSettingsFormContainer.propTypes = {};

PaymentProviderSettingsFormContainer.defaultProps = {};
