'use strict';

import {
  updateCache as updateRedux,
}  from '@sb-billing/redux/einvoice-settings';


angular.module('@sb-billing/services').service('sbEInvoiceSettingsService', function (sbEndpointType, sbGenericCacheService) {
  sbGenericCacheService.setupCache({
    name: 'sbEInvoiceSettingsService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: 'billing/einvoice-settings'},
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.EInvoiceSettingsUpdated'
    },
    updateRedux
  });  
});
