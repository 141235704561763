import { gql } from '@apollo/client';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

const query = gql`
  query InitOperatingBankAccount {
    bankAccounts(filter: { accountTypes: [${bankAccountTypeEnum.OPERATING}] }) {
      id
      showOperatingDetailsOnInvoice
      branchNumber
      accountNumber
      accountName
    }
  }
`;

const notificationIds = ['AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InitOperatingBankAccount = {
  query,
  notificationIds,
};
