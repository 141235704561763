import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from '@sb-itops/react';
import Styles from './InvoiceViewedOnlineDetails.module.scss';

export const InvoiceViewedOnlineDetails = (props) => {
  const { t } = useTranslation();
  const { viewedPerContact } = props;

  if (!viewedPerContact.length) {
    return (
      <div className={classnames(Styles.invoiceViewedOnlineDetails, Styles.notViewed)}>
        <i
          className={classnames('icon icon-hide', Styles.viewedStatusIcon)}
          title="Invoice has not been viewed online"
        />{' '}
        <span>Invoice has not been viewed online</span>
      </div>
    );
  }

  return (
    <div className={Styles.invoiceViewedOnlineDetails}>
      {viewedPerContact.map(({ id, contactDisplayName, timestamp }) => (
        <div className={Styles.viewedStatus} key={id}>
          <i className={classnames('icon icon-preview-2', Styles.viewedStatusIcon)} title="Invoice viewed online" />{' '}
          <span>
            Viewed online by {contactDisplayName} on {t('date', { ts: timestamp })}
          </span>
        </div>
      ))}
    </div>
  );
};

InvoiceViewedOnlineDetails.displayName = 'InvoiceViewedOnlineDetails';

InvoiceViewedOnlineDetails.propTypes = {
  viewedPerContact: PropTypes.arrayOf(PropTypes.object).isRequired,
};

InvoiceViewedOnlineDetails.defaultProps = {};
