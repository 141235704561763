import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'report-config',
  keyPath: 'reportId',
  ngCacheName: 'sbReportConfigService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE } = api;

export { buildSubmitForm, getReportTypes, getPdfOptionsByReportType } from './report-service-selectors';
