// DEVEXTREME SHOULD NOT LEAVE THIS FOLDER

import composeHooks from '@sb-itops/react-hooks-compose';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import React, { useState, ComponentProps } from 'react';
import { Scheduler } from 'devextreme-react/scheduler';
import moment from 'moment';
import { MatterSummary, Appointment, Person } from 'types';
import { checkMatterPermissionsAndDisplayErrorPopupP } from 'web/services/matter-permissions';
import { Schedule } from './Schedule';

export interface IScheduleContainerProps {
  calendar: Appointment[];
  matterSummaries: MatterSummary[];
  attendees: { id: string; name: string }[];
  height?: number | string;
  loading?: boolean;
  editing: true;
  selectedDate?: Date;
  setSelectedCells: (selectedCells: any[]) => void;
  selectedView?: ComponentProps<typeof Scheduler>['defaultCurrentView'];
  setSelectedView: (string) => void;
  onSaveAppointment: (formValues: { appointmentData: any }) => Promise<void>;
  onDeleteAppointment: (options: { appointmentData: any }) => Promise<void>;
  loggedInStaff: Person;
  fetchMore: (date: moment.Moment, endDate: moment.Moment) => void;
}

interface PreWrappedContainer extends Omit<IScheduleContainerProps, 'calendar'> {
  dataSource: DataSource;
}

const hooks = ({ selectedView }: PreWrappedContainer) => ({
  useModalData: () => {
    const [editModelData, setEditModelData] = useState<any>(false);
    return {
      editModelData,
      onCloseModal: () => {
        setEditModelData(false);
      },
      onAppointmentOpen: (event: any) => {
        // eslint-disable-next-line no-param-reassign
        event.cancel = true;

        const replaceDevExtremeModal = async () => {
          const allowed = await checkMatterPermissionsAndDisplayErrorPopupP(
            event && event.appointmentData && event.appointmentData.matterId,
            {
              group: 'matter-permissions-events',
            },
          );

          const isNewAppointment = !event.appointmentData.id && !event.appointmentData.originalId;

          if (allowed) {
            if (selectedView === 'month' && isNewAppointment) {
              setEditModelData({
                ...event.appointmentData,
                allDay: true,
                startDate: moment(event.appointmentData.startDate).startOf('day').toDate(),
                // For the month view, new appointments will have the endDate as 12am the next day.
                // We change this to be compaitable with our API which needss the appointment to end at 11:59 pm
                endDate: moment(event.appointmentData.endDate).subtract(1, 'day').endOf('day').toDate(),
              });
            } else if (event.appointmentData.allDay && isNewAppointment) {
              setEditModelData({
                ...event.appointmentData,
                // week and day views intitialise all day appointments that last 0 seconds for some reason, this should be 11:59pm
                endDate: moment(event.appointmentData.endDate).endOf('day').toDate(),
              });
            } else {
              setEditModelData(event.appointmentData);
            }
          }
        };

        replaceDevExtremeModal();
      },
    };
  },
});

// Wraps the incoming data into a datasource for devexpress
const WrappedComponent = composeHooks(hooks)(Schedule);
export const ScheduleContainer = ({ calendar, ...props }: IScheduleContainerProps) => {
  const dataSource = new DataSource({
    paginate: false,
    store: new ArrayStore({
      data: calendar || [],
      key: 'id',
    }),
  });
  return <WrappedComponent {...props} dataSource={dataSource} />;
};

ScheduleContainer.displayName = 'ScheduleContainer.container';
