angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/support';

  $stateProvider

  .state('home.billing.support', {
    url: '/support',
    templateUrl: BASE_DIRECTORY + '/support.html',
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
    data: {
      authorized: true,
      tab: { type: 'support' },
    }
  });

});
