import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner, Button, buttonTypes } from '@sb-itops/react';
import Styles from './InvoiceCommunicateTabPerDebtorForm.module.scss';

import { InvoiceCommunicateForm } from '../../../components/invoice-communicate-form';

export const InvoiceCommunicateTabPerDebtorForm = React.memo((props) => {
  const { isLoading, selectedTabId, tabs, onSelectedDebtorChanged, formData, onFormDataUpdated, userOptions } = props;
  const { previewMessage, onPreviewToggled, isPreviewMode } = props;

  if (isLoading) {
    return (
      <div className={Styles.invoiceCommunicateTabPerDebtorForm}>
        <Spinner height={500} message="Loading" />
      </div>
    );
  }

  const onFieldValueUpdated = (field, value) => onFormDataUpdated(selectedTabId, field, value);

  const getTabIndicatorClasses = ({ tabValid, debtorIncluded }) => {
    if (!debtorIncluded) {
      return classnames('icon-comm-strike', Styles.tabIndicator);
    }

    if (!tabValid) {
      return classnames('icon-alert-1', Styles.tabIndicator, Styles.redText);
    }

    return classnames('icon-check-circle-2', Styles.tabIndicator, Styles.blueText);
  };

  return (
    <div className={Styles.invoiceCommunicateTabPerDebtorForm}>
      <div className={Styles.tabList}>
        {tabs.map(({ id, text, tabValid, debtorIncluded }) => (
          <Button
            key={id}
            type={buttonTypes.tab}
            highlight={selectedTabId === id}
            onClick={() => onSelectedDebtorChanged(id)}
          >
            <div className={getTabIndicatorClasses({ tabValid, debtorIncluded })} />
            <span title={text} className={Styles.tabText}>
              {text}
            </span>
          </Button>
        ))}
      </div>
      <div className={Styles.formFields}>
        <InvoiceCommunicateForm
          {...formData}
          onFieldValueUpdated={onFieldValueUpdated}
          previewMessage={previewMessage}
          onPreviewToggled={onPreviewToggled}
          isPreviewMode={isPreviewMode}
          selectedTabId={selectedTabId}
          userOptions={userOptions}
          showIncludeDebtor
          showPreviewButton
        />
      </div>
    </div>
  );
});

InvoiceCommunicateTabPerDebtorForm.displayName = 'InvoiceCommunicateTabPerDebtorForm';

InvoiceCommunicateTabPerDebtorForm.propTypes = {
  isLoading: PropTypes.bool,
  selectedTabId: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.object,
  isPreviewMode: PropTypes.bool,
  previewMessage: PropTypes.string,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onSelectedDebtorChanged: PropTypes.func,
  onFormDataUpdated: PropTypes.func,
  onPreviewToggled: PropTypes.func,
};

InvoiceCommunicateTabPerDebtorForm.defaultProps = {
  isLoading: false,
  selectedTabId: undefined,
  tabs: undefined,
  formData: undefined,
  isPreviewMode: false,
  previewMessage: '',
  userOptions: [],
  onSelectedDebtorChanged: () => {},
  onFormDataUpdated: () => {},
  onPreviewToggled: () => {},
};

export default InvoiceCommunicateTabPerDebtorForm;
