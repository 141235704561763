import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { diffLines } from 'diff';
import Styles from './DiffViewer.module.scss';

export const DiffViewer = ({ oldVersion, newVersion, className }) => {
  const diff = diffLines(oldVersion, newVersion);

  const nodes = diff.map((group, index) => {
    const { value, added, removed } = group;

    return (
      <span key={index} className={classnames(added && Styles.added, removed && Styles.removed)}>
        {value}
      </span>
    );
  });

  return <pre className={className}>{nodes}</pre>;
};

DiffViewer.displayName = 'DiffViewer';

DiffViewer.propTypes = {
  className: PropTypes.string,
  oldVersion: PropTypes.string,
  newVersion: PropTypes.string,
};

DiffViewer.defaultProps = {
  className: undefined,
  oldVersion: '',
  newVersion: '',
};
