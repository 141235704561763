import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getLogger } from '@sb-itops/fe-logger';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { withOnLoad } from '@sb-itops/react';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { creditCardSaveRequestDefaults } from '@sb-billing/business-logic/credit-card-add-request/entities';
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { CreditCardSaveRequestForm } from './CreditCardSaveRequestForm';
import { creditCardSaveRequestFormSchema } from './credit-card-save-request-form-schema';
import { getCreditCardSaveRequestEmailInterpolated } from './credit-card-save-request-interpolated';

const log = getLogger('CreditCardSaveRequestFormContainer');

const hooks = ({ contactId, scope }) => ({
  useCreditCardSaveRequestForm: () => {
    const [isPreviewMode, setIsPreviewMode] = useState(true);
    const [previewSubject, setPreviewSubject] = useState('');
    const [previewMessage, setPreviewMessage] = useState('');

    const form = useForm({
      scope,
      schema: creditCardSaveRequestFormSchema,
    });

    const preparePreviewInfo = async () => {
      const { subject, message } = await convertToEmailCreditCardSaveRequest({
        creditCardSaveRequestData: form.formValues,
        contactId,
        interpolate: true,
      });
      setPreviewSubject(subject);
      setPreviewMessage(message);
    };

    const {
      formFields,
      formSubmitting,
      formInitialised,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFieldValues,
      onValidateForm,
    } = form;

    const { to, from, subject, message, sendCopyToUser, staffAddress } = formFields;

    useEffect(() => {
      // Get preview info on load/when message changes instead of on toggle to enable defaulting to preview mode
      if (message) {
        preparePreviewInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return {
      isPreviewMode,
      previewSubject,
      previewMessage,
      // fields
      to,
      from,
      subject,
      message,
      sendCopyToUser,
      staffAddress,
      // form
      onUpdateFieldValues: (fieldName, value) => {
        onUpdateFieldValues(fieldName, value);
        onValidateForm();
      },

      formInitialised,
      submitFailed,
      formDisabled: formSubmitting,
      // callbacks
      onPreviewToggle: () => setIsPreviewMode(!isPreviewMode),
      onLoad: () => {
        getInitFormValuesP({ contactId })
          .then((initValues) => {
            onInitialiseForm(initValues);
            return undefined;
          })
          .catch((err) => log.error(err));

        return onClearForm;
      },
    };
  },
});

const getInitFormValuesP = async ({ contactId }) => {
  const people = await fetchPeopleP(contactId).catch(() => []);
  const to = formatContactsEmails(people);
  const { email: from } = getStaffEmailDetails();

  return {
    to,
    from,
    subject: creditCardSaveRequestDefaults.EMAIL_SUBJECT,
    message: creditCardSaveRequestDefaults.EMAIL_MESSAGE,
    sendCopyToUser: false,
    staffAddress: from,
  };
};

export const convertToEmailCreditCardSaveRequest = async ({
  creditCardSaveRequestData,
  contactId,
  interpolate = true,
}) => {
  let bcc;

  if (creditCardSaveRequestData.sendCopyToUser) {
    bcc = creditCardSaveRequestData.staffAddress;
  }

  const creditCardSaveRequest = {
    sendTo: creditCardSaveRequestData.to,
    from: creditCardSaveRequestData.from,
    // empty string would not pass endpoint validation so either email or undefined
    bcc: bcc || undefined,
    subject: creditCardSaveRequestData.subject,
    message: creditCardSaveRequestData.message,
    footer: '',
    contactId,
    providerType: getActiveProvider(),
    description: `Credit Card Details for ${getContactDisplay(contactId)}`,
  };

  if (interpolate) {
    const { message, subject } = await getCreditCardSaveRequestEmailInterpolated(creditCardSaveRequest);
    creditCardSaveRequest.subject = subject;
    creditCardSaveRequest.message = message;
  }

  return creditCardSaveRequest;
};

export const CreditCardSaveRequestFormContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(CreditCardSaveRequestForm)),
);
CreditCardSaveRequestFormContainer.displayName = 'CreditCardSaveRequestFormContainer';

CreditCardSaveRequestFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
};
