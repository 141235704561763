'use strict';

import { get } from 'lodash';
import {
  getList,
  updateCache as updateRedux,
} from '@sb-billing/redux/myob-integration';

angular
  .module('@sb-billing/services')
  .service('sbMyobIntegrationService', function(
    sbLoggerService,
    sbGenericEndpointService,
    sbGenericCacheService,
    sbEndpointType,
    sbEnvironmentConfigService,
    sbRegionType
  ) {

    const region = sbEnvironmentConfigService.getRegion();
    if (region === sbRegionType.US) {
      return;
    }

    const that = this;
    const log = sbLoggerService.getLogger('sbMyobIntegrationService');
    const ENDPOINT = 'billing/myob-integration';

    sbGenericCacheService.setupCache({
      name: 'sbMyobIntegrationService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: ENDPOINT },
        poll: 60,
        subscriptions: [
          // Must include Access token as when users press the wrong version of MYOB the system will never update businesses
          'notifier.MyobIntegration.MyobAccessTokenUpdated',
          'notifier.MyobIntegration.MyobBusinessesUpdated',
          'notifier.MyobIntegration.MyobAccessTokenDeleted'
        ]
      },
      updateRedux,
    });

    that.isAuthorized = () => !!getOne();
    that.disableP = disableP;
    that.refreshBusinesses = refreshBusinesses;
    that.getBusinesses = getBusinesses;
    that.getBusiness = getBusiness;
    that.getIntegrationVersion = getIntegrationVersion;

    function getOne() {
      const integration = getList()[0];
      return isAuthorized(integration) ? integration : undefined;
    }

    function getIntegrationVersion() {
      const integration = getOne();
      return integration ? integration.accessToken.apiVersion : -1;
    }

    function getBusinesses() {
      const integration = getOne();
      return integration ? integration.businesses : undefined;
    }

    function getBusiness(id) {
      const businessesList = getBusinesses();
      return get(businessesList.filter(business => business.id === id), '[0]');
    }

    function isAuthorized(value) {
      return !!get(value, 'accessToken', false);
    }

    function disableP() {
      log.info('diconnecting sbMyobIntegrationService');
      return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/disconnect`);
    }

    function refreshBusinesses() {
      log.info('loading businesses in sbMyobIntegrationService');
      return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/refresh-businesses`);
    }
  });
