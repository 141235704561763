angular.module('sb.billing.webapp').service('sbOverrideRedirectService', function () {


  // See BB-12096
  // We introduced remembering recent pages with MTAs
  // When we trigger onEnter in certain pages we "remember" and automatically navigate back to them when we go to the page again
  // But sometimes this is NOT desirable, e.g. when we want to go to a specific page, not the last remembered page
  // This service is used to facilitate that, and should be called before calling a specific onClickLink call
  // Calling it will override all redirects both remembering and single trust account redirects
  const that = this;

  that.override = false;
  that.overrideNext = overrideNext;
  that.resetOverride = resetOverride;

  function overrideNext() {
    that.override = true;
  }

  function resetOverride() {
    that.override = false;
  }
});
