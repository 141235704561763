import { InMemoryCache } from '@apollo/client';
import { customOffsetLimitPagination } from './custom-offset-limit-pagination';
import { readValueFromAnotherField } from './read-value-from-another-field';

let apolloCache;

export const getApolloCache = () => {
  if (!apolloCache) {
    apolloCache = new InMemoryCache(cacheConfig);
  }

  return apolloCache;
};

// When adding custom keyFields, check if they need to be added to ID_FIELDS
// in monorepo/integration/endpoints/webapp-graphql-server/utils.js
// for use in getIdFieldIfOnlyFieldRequested to avoid fetching data from
// databases unnecessarily
const cacheConfig = {
  typePolicies: {
    AutoNumberDefinition: {
      fields: {
        fields: {
          // this looks wrong but isn't. There is a field called "fields"
          merge: false, // Always overwrite with incoming data
        },
      },
    },
    Contact: {
      fields: {
        roles: {
          merge: false, // Always overwrite with incoming data
        },
      },
    },
    CorrespondenceHistoryRelatedItem: {
      keyFields: ['id', 'type'],
    },
    Matter: {
      fields: {
        // Where a field is an array, if an item is removed Apollo will display a warning
        // in console "Cache data may be lost when replacing the X field of a Y object."
        // when attempting to update the cache.
        // To avoid the warnings we must specify a merge policy
        // https://www.apollographql.com/docs/react/caching/cache-field-behavior/
        // Explanation: https://github.com/apollographql/apollo-client/pull/6372#issuecomment-670165700
        // NOTE: This will not remove the previous entity from the cache,
        // it simply updates the contents of this field
        clients: {
          merge: false, // Always overwrite with incoming data
        },
        // clientDisplayName and otherSideDisplayName are client-only fields
        // meaning in the query they need to be suffixed with `@client` directive.
        // Their value is derived by combining the `displayName` field of
        // the relevant Contact entities
        clientDisplayName: {
          read: readValueFromAnotherField({ fieldName: 'clients', nestedFieldName: 'displayName' }),
        },
        otherSide: {
          merge: false, // Always overwrite with incoming data
        },
        otherSideDisplayName: {
          read: readValueFromAnotherField({ fieldName: 'otherSide', nestedFieldName: 'displayName' }),
        },
      },
    },
    BankAccountBalances: {
      // See note in BankAccountBalancesMatter below
      keyFields: false,
    },
    BankAccountBalancesContact: {
      // See note in BankAccountBalancesMatter below
      keyFields: false,
    },
    BankAccountBalancesMatter: {
      // As matters can have balances in multiple bank accounts, the keyFields
      // should be a composite key of 'bankAccountId' and 'matterId', however
      // the results can also be filtered by `balanceAsOfDate`, therefore a
      // normalised cache would store the entity as per the most recent query.
      // In order to return the correct values every time, we have to disable
      // normalisation for this entity. The drawback of this solution is that
      // the properties of this entity can only be read/modified (opdated) via
      // the parent entity.
      keyFields: false,
    },
    MatterType: {
      keyFields: ['matterTypeId'], // The `id` field on this entity is the entities version id, hence the mapping here.
    },
    PaymentPlan: {
      fields: {
        matters: {
          merge: false, // Always overwrite with incoming data
        },
      },
    },

    Query: {
      fields: {
        eventList: customOffsetLimitPagination(['filter', ['matterId', 'min', 'sortBy', 'sortDirection']]),
        invoiceSettingsTemplateSearch: customOffsetLimitPagination(['searchText']),
        matterSearch: customOffsetLimitPagination(['searchText']),
        contactSearch: customOffsetLimitPagination(['searchText']),
        memos: {
          merge: false,
        },
        timers: {
          merge: false,
        },
      },
    },
    Invoice: {
      fields: {
        listItemProperties: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        }
      }
    }
  },
};
