import React from 'react';
import PropTypes from 'prop-types';
import { LawpaySavedCardForm } from '@sb-billing/lawpay-payment-form';
import { FeeWiseSavedCardForm } from '@sb-billing/fee-wise-payment-form';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import Styles from './SavedCardChargeForm.module.scss';

export const SavedCardChargeForm = ({
  savedCard,
  providerType,
  triggerSubmit,
  isSubmitting,
  onReadyForSubmit,
  onSubmit,
}) => (
  <div className={Styles.savedCardChargeForm}>
    {providerType === providers.LAWPAY && (
      <LawpaySavedCardForm
        triggerSubmit={triggerSubmit}
        isSubmitting={isSubmitting}
        onReadyForSubmit={onReadyForSubmit}
        onSubmit={onSubmit}
        savedCard={savedCard}
      />
    )}
    {providerType === providers.FEE_WISE && (
      <FeeWiseSavedCardForm
        triggerSubmit={triggerSubmit}
        isSubmitting={isSubmitting}
        onReadyForSubmit={onReadyForSubmit}
        onSubmit={onSubmit}
        savedCard={savedCard}
      />
    )}
  </div>
);

SavedCardChargeForm.displayName = 'SavedCardChargeForm';

SavedCardChargeForm.propTypes = {
  providerType: PropTypes.string.isRequired,
  savedCard: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
};

SavedCardChargeForm.defaultProps = {};
