import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Translation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { externalSentViaTypes, getSentViaText } from '@sb-billing/business-logic/correspondence-history';
import { Spinner } from '@sb-itops/react/spinner';
import Styles from './MultiContactCorrespondenceDetailsInline.module.scss';

export const MultiContactCorrespondenceDetailsInline = (props) => {
  const { latestCorrespondencePerContact, loading } = props;

  if (!loading && !latestCorrespondencePerContact?.length) {
    return <div className={Styles.multiContactCorrespondenceDetailsInline}>{renderNoHistory(props)}</div>;
  }

  return (
    <div className={Styles.multiContactCorrespondenceDetailsInline}>
      {loading ? (
        <Spinner small />
      ) : (
        latestCorrespondencePerContact.map((correspondenceHistory) => {
          const { status } = correspondenceHistory;

          switch (status) {
            case 0: // In progress
              return renderInProgress(correspondenceHistory, props);
            case 1: // Success
              return renderSuccess(correspondenceHistory, props);
            case 2: // Error
              return renderFailure(correspondenceHistory, props);
            default:
              throw new Error(`Unexpected correspondence status ${status}`);
          }
        })
      )}
    </div>
  );
};

const renderNoHistory = () => (
  <div className={Styles.statusItem}>
    <div className={Styles.statusIcon}>
      <i className={classnames('icon-mail-1', Styles.greyText)} />
    </div>
    <div className={Styles.statusDescription}>
      <span>This invoice has not yet been sent</span>
    </div>
  </div>
);

const renderInProgress = (correspondenceHistory) => {
  const { id, userDisplayName, lastUpdated, contactDisplayName, sentVia } = correspondenceHistory;
  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });

  return (
    <div className={Styles.statusItem} key={id}>
      <div className={Styles.statusIcon}>
        <i className={classnames('icon-sync-2', Styles.blueText)} />
      </div>
      <div className={Styles.statusDescription}>
        <span>
          Last sent{sentViaText} by {userDisplayName} to {contactDisplayName} on{' '}
          <Translation>{(t) => t('date', { ts: lastUpdated })}</Translation> (sending in progress).
        </span>
      </div>
    </div>
  );
};

const renderSuccess = (correspondenceHistory) => {
  const { id, contactDisplayName, to, userDisplayName, lastUpdated, sentVia } = correspondenceHistory;
  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });
  const isManuallySent = Object.values(externalSentViaTypes).includes(sentVia);

  return (
    <div className={Styles.statusItem} key={id}>
      <div className={Styles.statusIcon}>
        <i className={classnames('icon-check-circle-2', Styles.greenText)} />
      </div>

      <div className={Styles.statusDescription}>
        <span>
          Last sent{sentViaText} by {userDisplayName}
          {!isManuallySent ? ` to ${contactDisplayName} (${to})` : ''} on{' '}
          <Translation>{(t) => t('date', { ts: lastUpdated })}</Translation>.
        </span>
      </div>
    </div>
  );
};

const renderFailure = (correspondenceHistory) => {
  const { id, contactDisplayName, userDisplayName, lastUpdated, errorMessage, sentVia } = correspondenceHistory;
  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });

  return (
    <div className={Styles.statusItem} key={id}>
      <div className={Styles.statusIcon}>
        <i className={classnames('icon-alert-2', Styles.redText)} />
      </div>
      <div className={Styles.statusDescription}>
        <span>
          Failed to send{sentViaText} by {userDisplayName} to {contactDisplayName} on{' '}
          <Translation>{(t) => t('date', { ts: lastUpdated })}</Translation>. Reason: {errorMessage}.
        </span>
      </div>
    </div>
  );
};

MultiContactCorrespondenceDetailsInline.displayName = 'MultiContactCorrespondenceDetailsInline';

MultiContactCorrespondenceDetailsInline.propTypes = {
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
};

MultiContactCorrespondenceDetailsInline.defaultProps = {};
