import { updateFeatures } from '@sb-itops/feature';
import {
  initialiseP as initialiseLaunchDarklyP,
  uninitialise as uninitialiseLaunchDarklyP,
} from '@sb-itops/launch-darkly-client-side';
import { getLaunchDarklyKey } from '@sb-itops/launch-darkly-client-config';

import { getAccountId, getUserRegion, getOwningCompany } from 'web/services/user-session-management';

export const initialiseLaunchDarkly = async ({ log, firmDetails }) => {
  let firmCreationDate;
  try {
    firmCreationDate = firmDetails && firmDetails.creationDate
      ? moment(firmDetails && firmDetails.creationDate).valueOf() // milliseconds since unix epoch
      : null;
  } catch (err) {
    log.warn('Failed to get firm creation date (ms since unix epoch) for launch darkly initialisation', err);
  }

  try {
    const featureFlags = await initialiseLaunchDarklyP({
      clientSideId: getLaunchDarklyKey(),
      context: {
        kind: 'user',
        key: getAccountId(),
        country: getUserRegion(),
        name: firmDetails && firmDetails.firmName,
        creationDate: firmCreationDate || undefined,
        users: firmDetails && firmDetails.currentUserCount,
        product: getOwningCompany(),
      },
      onFeatureUpdates: (changes) => {
        const featureFlagUpdates = Object.entries(changes).reduce((acc, [featureCode, change]) => {
          acc[featureCode] = change.current;
          return acc;
        }, {});

        updateFeatures(featureFlagUpdates);
      },
    });

    if (featureFlags) {
      updateFeatures(featureFlags);
    }
  } catch (err) {
    // If launch darkly initialisation fails, we fall back on the hard coded feature switch values
    // and allow the user to continue working.
    log.warn('Failed to initialise launch darkly', err);
  }

  return uninitialiseLaunchDarklyP;
};
