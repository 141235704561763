import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { useDispatch, useSelector } from 'react-redux';
import * as formsFeature from '@sb-itops/redux/forms2';
import { getLogger } from '@sb-itops/fe-logger';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as messageDisplay from '@sb-itops/message-display';
import { getByType as getBankAccountByType, saveBankAccount, ACCOUNT_TYPE } from '@sb-billing/redux/bank-account';
import { bankAccountSaveAction } from '@sb-billing/business-logic/bank-account/entities/constants';
import { featureActive } from '@sb-itops/feature';
import { fetchPermissionsFromWebP } from 'web/services/permissions';
import { getRegion } from '@sb-itops/region';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { getSettings as getBankAccountSettingsFromCache } from '@sb-billing/redux/bank-account-settings';
import { resourceIds } from '@sb-itops/business-logic/authorisation';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import composeHooks from '@sb-itops/react-hooks-compose';
import { needsAuthorisationToChangeBankAccount } from '@sb-billing/business-logic/bank-account/services/need-authorisation-change-bank-account';
import { isBankAccountDetailsEmpty } from '@sb-billing/business-logic/bank-account/services/is-bank-account-details-empty';
import { saveSettings as saveBankAccountSettings } from '@sb-billing/redux/bank-account-settings/save-settings';
import { OperatingAccountDetailsSettings } from './OperatingAccountDetailsSettings';

const logger = getLogger('web/react-redux/OperatingAccountDetailsSettingsContainer');
const saveOperatingAccountDetailsModalId = 'save-operating-account-modal-id';

// validation schema
const operatingAccountDetailsSettingsSchema = ({ bankBranchNumberRegex }) =>
  Yup.object().shape({
    operatingAccountName: Yup.string().required(),
    BSB: Yup.string()
      .required()
      // eslint-disable-next-line no-template-curly-in-string
      .test('is-valid-bsb', '${path} is not valid BSB', (value) => bankBranchNumberRegex.test(value)),
    accountNumber: Yup.string().required(),
    paymentDetailsOnInvoice: Yup.bool(),
    allowOnlyFirmOwnerEditOperatingAccountDetails: Yup.bool(),
  });

const scope = 'operating-account-details-settings-form';
const hooks = () => ({
  useFeatures: () => ({
    supportsPaymentDetailsOnInvoice:
      featureActive('BB-12792') &&
      getBankAccountStrategy(getRegion(), bankAccountStrategies.PAYMENT_DETAILS_ON_INVOICES),
    supportsFirmOwnerBankDetailManagement:
      featureActive('BB-12792') &&
      getBankAccountStrategy(getRegion(), bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES),
  }),
  useForms: () => {
    const {
      selectors: formSelectors,
      actions: { initialiseForm, clearForm, resetForm, updateFieldValues },
      operations: { validateSchema, submitFormP },
    } = useScopedFeature(formsFeature, scope);
    const { t } = useTranslation();

    const {
      fields: formFields,
      formInitialised,
      formDirty,
      formValid,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const dispatch = useDispatch();

    const existingBankAccountSettings = getBankAccountSettingsFromCache() || {};
    const {
      // default the restrictTrustAccountEdit option to false if its in the US, true if its in AU/UK
      restrictOperatingAccountEdit = getBankAccountStrategy(
        getRegion(),
        bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES,
      ),
    } = existingBankAccountSettings;
    const bankBranchNumberRegex = getBankAccountStrategy(getRegion(), bankAccountStrategies.BRANCH_NUMBER_REGEX);

    const operatingAccounts = getBankAccountByType('Operating');
    const bankAccountDetails = operatingAccounts && JSON.parse(JSON.stringify(operatingAccounts[0]));
    const bankAccountIsEmpty = isBankAccountDetailsEmpty({ bankAccount: bankAccountDetails });
    const shouldShowConfirmation =
      bankAccountIsEmpty &&
      featureActive('BB-12792') &&
      getBankAccountStrategy(getRegion(), bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES);

    const [canEditManageSetting, setCanEditManageSetting] = useState(true);
    const [isAuthorised, setIsAuthorised] = useState(true);
    useEffect(() => {
      const fetchFirmOwnerPerms = async () => {
        const permissions = await fetchPermissionsFromWebP([resourceIds.BILLING_BANK_ACCOUNT]);
        setCanEditManageSetting(permissions[resourceIds.BILLING_BANK_ACCOUNT]);
        if (
          needsAuthorisationToChangeBankAccount(getRegion())({
            bankAccount: bankAccountDetails,
            restrictOperating: restrictOperatingAccountEdit,
          })
        ) {
          setIsAuthorised(permissions[resourceIds.BILLING_BANK_ACCOUNT]);
        }
      };
      if (featureActive('BB-12792')) {
        fetchFirmOwnerPerms();
      }
    }, [bankAccountDetails, restrictOperatingAccountEdit]);

    const getInitialFieldValues = () => ({
      operatingAccountName: bankAccountDetails.accountName,
      BSB: bankAccountDetails.branchNumber,
      accountNumber: bankAccountDetails.accountNumber,
      paymentDetailsOnInvoice: bankAccountDetails.showOperatingDetailsOnInvoice || false,
      allowOnlyFirmOwnerEditOperatingAccountDetails: restrictOperatingAccountEdit,
    });

    return {
      isAuthorised,
      saveOperatingAccountDetailsModalId,
      formInitialised,
      formDirty,
      formValid,
      formSubmitting,
      operatingAccountName: formFields.operatingAccountName,
      BSB: formFields.BSB,
      accountNumber: formFields.accountNumber,
      paymentDetailsOnInvoice: formFields.paymentDetailsOnInvoice,
      allowOnlyFirmOwnerEditOperatingAccountDetails: formFields.allowOnlyFirmOwnerEditOperatingAccountDetails,
      canEditManageSetting,
      bankBranchNumberRegex,
      hasChanges: hasFormChanged(formFields),
      onLoad: () => {
        dispatch(
          initialiseForm({
            fieldValues: getInitialFieldValues(),
          }),
        );
        return () => {
          dispatch(clearForm());
        };
      },
      shouldShowConfirmation,
      showConfirmation: () => {
        setModalDialogVisible({ modalId: saveOperatingAccountDetailsModalId });
      },
      onChange: (updatedFields) => {
        dispatch(updateFieldValues({ fieldValues: updatedFields }));
        dispatch(validateSchema({ schema: operatingAccountDetailsSettingsSchema({ bankBranchNumberRegex }) }));
      },
      onSave: async () => {
        try {
          await dispatch(
            submitFormP({
              submitFnP: async (formData) => {
                await saveBankAccount({
                  id: bankAccountDetails.id,
                  accountType: ACCOUNT_TYPE.operating.toUpperCase(),
                  accountName: formData.operatingAccountName,
                  branchNumber: formData.BSB,
                  accountNumber: formData.accountNumber,
                  showOperatingDetailsOnInvoice: formData.paymentDetailsOnInvoice,
                  action: bankAccountSaveAction.UPDATE,
                });

                await dispatch(
                  saveBankAccountSettings({
                    restrictOperatingAccountEdit: formData.allowOnlyFirmOwnerEditOperatingAccountDetails,
                  }),
                );

                // Needed to reset the originalValue of the form so the hasChanged will work correctly
                dispatch(
                  resetForm({
                    fieldValues: {
                      operatingAccountName: formData.operatingAccountName,
                      BSB: formData.BSB,
                      accountNumber: formData.accountNumber,
                      paymentDetailsOnInvoice: formData.paymentDetailsOnInvoice,
                      allowOnlyFirmOwnerEditOperatingAccountDetails:
                        formData.allowOnlyFirmOwnerEditOperatingAccountDetails,
                    },
                  }),
                );
              },
            }),
          );
          messageDisplay.success(`${t('capitalize', { val: 'operatingAccount' })} detail saved successfully.`);
        } catch (err) {
          logger.error('Error:', err);
          messageDisplay.error(`Failed to save the ${t('operatingAccount').toLowerCase()} detail.`);
        }
      },
    };
  },
});

function hasFormChanged(formFields) {
  return Object.values(formFields).some((field) => field.originalValue !== field.value);
}

export const OperatingAccountDetailsSettingsContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(OperatingAccountDetailsSettings)),
);
