angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/advanced-search/leads';

  $stateProvider
    .state('home.billing.advanced-search.leads', {
      url: '/leads?searchTerm',
      templateUrl: BASE_DIRECTORY + '/leads.html',
      data: {
        authorized: true,
      },
      controller: 'SbAdvancedSearchLeadsCtrl',
      controllerAs: '$ctrl'
    });

});
