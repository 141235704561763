import React from 'react';
import PropTypes from 'prop-types';
import { forms2PropTypes, CurrencyInput2, FormLabel, DropDownList, useTranslation } from '@sb-itops/react';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';

import { includeErrorClassnameIfInvalid, shouldShowFieldError } from '../include-error-classname-if-invalid';
import { trustRetainerReplenishAmountErrorMessage } from '../AddEditMatterForm.schema';

import TrustRetainerStyles from './MatterTrustSettings.module.scss';
import Styles from '../AddEditMatterForm.module.scss';

export const MatterTrustSettings = ({
  defaultTrustAccountOptions,
  defaultTrustAccountLabel,
  showRetainerOnInvoiceOptions,
  isMinimumTrustRetainerEnabledForFirm,
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields
  minimumTrustRetainerActive,
  minimumTrustRetainerAmount,
  trustRetainerReplenishAmount,
  defaultTrustAccountId,
  showRetainerOption,
  // callbacks
  onMinimumTrustRetainerActiveUpdated,
  onMinimumTrustRetainerAmountUpdated,
  onTrustRetainerReplenishAmountUpdated,
  onDefaultTrustAccountUpdated,
  onShowRetainerOptionUpdated,
}) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      {isMinimumTrustRetainerEnabledForFirm && (
        <>
          <div className="row">
            <div className="col-lg-12 form-group">
              <label>
                <input
                  type="checkbox"
                  checked={minimumTrustRetainerActive.value}
                  onChange={(trustRetainerOption) =>
                    onMinimumTrustRetainerActiveUpdated(trustRetainerOption.target.checked)
                  }
                />
                <span className={TrustRetainerStyles.checkboxLabel}>{`Activate Minimum ${t('trust')} Retainer`}</span>
              </label>
            </div>
          </div>
          <div className={classnames('row', TrustRetainerStyles.retainerSettingsRow)}>
            <div className="col-sm-6 form-group">
              <FormLabel
                label="Minimum Threshold"
                field={minimumTrustRetainerAmount}
                submitFailed={submitFailed}
                optional={!minimumTrustRetainerActive.value}
              />
              <div>
                <CurrencyInput2
                  className={classnames(includeErrorClassnameIfInvalid(minimumTrustRetainerAmount, submitFailed))}
                  value={minimumTrustRetainerAmount.value}
                  onChange={(trustRetainerOption) =>
                    onMinimumTrustRetainerAmountUpdated(trustRetainerOption.target.value)
                  }
                  disabled={!minimumTrustRetainerActive.value}
                />
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <FormLabel
                label="Replenish Up To"
                field={trustRetainerReplenishAmount}
                submitFailed={submitFailed}
                optional={!minimumTrustRetainerActive.value}
              />
              <div>
                <CurrencyInput2
                  className={classnames(includeErrorClassnameIfInvalid(trustRetainerReplenishAmount, submitFailed))}
                  value={trustRetainerReplenishAmount.value}
                  onChange={(trustRetainerOption) =>
                    onTrustRetainerReplenishAmountUpdated(trustRetainerOption.target.value)
                  }
                  disabled={!minimumTrustRetainerActive.value}
                />
              </div>
            </div>
          </div>
          {trustRetainerReplenishAmount.invalidReason === trustRetainerReplenishAmountErrorMessage && (
            <div className={classnames('row', TrustRetainerStyles.retainerSettingsRow)}>
              <div className="col-sm-12 form-group">
                <span className={Styles.errorMessage}>{trustRetainerReplenishAmount.invalidReason}</span>
              </div>
            </div>
          )}
          {featureActive('BB-6908') && (
            <div
              className={classnames(
                'row',
                TrustRetainerStyles.retainerSettingsRow,
                TrustRetainerStyles.defaultAccountRow,
              )}
            >
              <div className="col-sm-12 form-group">
                <FormLabel
                  label="Show Evergreen Retainer Request on Invoice"
                  field={showRetainerOption}
                  submitFailed={submitFailed}
                  optional
                />
                <DropDownList
                  hasError={shouldShowFieldError(showRetainerOption, submitFailed)}
                  placeholder={showRetainerOption.label}
                  onValueChange={(option) => onShowRetainerOptionUpdated(option?.value)}
                  stateless
                  options={showRetainerOnInvoiceOptions}
                  selectedOption={showRetainerOnInvoiceOptions.find((o) => o.value === showRetainerOption.value)}
                  disabled={!minimumTrustRetainerActive.value}
                />
              </div>
            </div>
          )}
        </>
      )}

      <div className={classnames('row', TrustRetainerStyles.defaultAccountRow)}>
        <div className="col-sm-12 form-group">
          <FormLabel
            label={`Default Account For ${t('trust')} Payments`}
            field={defaultTrustAccountId}
            submitFailed={submitFailed}
            optional
          />
          <DropDownList
            hasError={shouldShowFieldError(defaultTrustAccountId, submitFailed)}
            placeholder={`Use default ${t('trustAccount').toLowerCase()} (${defaultTrustAccountLabel})`}
            onValueChange={(option) => onDefaultTrustAccountUpdated(option?.value)}
            stateless
            options={defaultTrustAccountOptions}
            selectedOption={defaultTrustAccountOptions.find((o) => o.value === defaultTrustAccountId.value)}
            isClearable
          />
          {defaultTrustAccountId.isInvalid && (
            <div className={Styles.errorMessage}>{defaultTrustAccountId.invalidReason}</div>
          )}
        </div>
      </div>
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterTrustSettings.propTypes = {
  isMinimumTrustRetainerEnabledForFirm: PropTypes.bool.isRequired,
  defaultTrustAccountOptions: PropTypes.array.isRequired,
  defaultTrustAccountLabel: PropTypes.string.isRequired,
  showRetainerOnInvoiceOptions: PropTypes.array.isRequired,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  minimumTrustRetainerActive: Forms2Field,
  minimumTrustRetainerAmount: Forms2Field,
  trustRetainerReplenishAmount: Forms2Field,
  defaultTrustAccountId: Forms2Field,
  showRetainerOption: Forms2Field,

  // callbacks
  onMinimumTrustRetainerActiveUpdated: PropTypes.func.isRequired,
  onMinimumTrustRetainerAmountUpdated: PropTypes.func.isRequired,
  onTrustRetainerReplenishAmountUpdated: PropTypes.func.isRequired,
  onDefaultTrustAccountUpdated: PropTypes.func.isRequired,
  onShowRetainerOptionUpdated: PropTypes.func.isRequired,
};

MatterTrustSettings.defaultProps = {
  // form state
  formDisabled: undefined,
  submitFailed: undefined,
  // form fields
  minimumTrustRetainerActive: undefined,
  minimumTrustRetainerAmount: undefined,
  trustRetainerReplenishAmount: undefined,
  defaultTrustAccountId: undefined,
  showRetainerOption: undefined,
};

MatterTrustSettings.displayName = 'MatterTrustSettings';
