import { SbClientSdkContactDataDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';
import { mapContact } from '../mappers';

export const clientSdkGetContact = async (contactId: string, needsReload = false) => {
  const apolloClient = getApolloClient();
  const contactData = await apolloClient.query({
    query: SbClientSdkContactDataDocument,
    variables: { contactId },
    fetchPolicy: needsReload ? 'network-only' : 'cache-first',
  });
  const contact = contactData?.data?.contact;
  return mapContact(contact || {});
};
