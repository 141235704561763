/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation, CurrencyInput2, CurrencyDisplay } from '@sb-itops/react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ExpandCollapseToggler } from '@sb-itops/react/expand-collapse-toggler';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { InvoiceDisplay } from '@sb-billing/react/invoice-display';
import { SlidingToggle } from '@sb-itops/react/sliding-toggle';
import { Checkbox } from '@sb-itops/react/checkbox';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { MatterDisplay } from '@sb-matter-management/react';
import { featureActive } from '@sb-itops/feature';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { PaymentPlanIndicator, entityTypes } from 'web/react-redux/components/payment-plan-indicator';

import Styles from './TrustToOfficeTable.module.scss';

const REGION = getRegion();
const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

/**
 * @deprecated to be deprecated after BB-14037 is released, this is replaced by monorepo/apps/smokeball-billing-web/src/components/trust-to-office-table/TrustToOfficeTable.jsx
 */
const TrustToOfficeTable = memo((props) => {
  const { t } = useTranslation();
  return (
    <Table
      list={props.rows}
      rowClassName={({ rowData }) => (rowData && rowData.type === 'INVOICE' ? Styles.invoiceRow : 'matter-row')}
      summary={props.tableSummary}
      sort={props.sort}
      sortBy={props.sortBy}
      sortDirection={props.sortDirection}
      showFooter
      onRowClick={onRowClicked(props)}
    >
      <Column
        className="row-expander"
        dataKey="rowExpander"
        label=""
        width={25}
        headerRenderer={expandAllRowsRenderer(props)}
        cellRenderer={expanderCellRenderer(props)}
        disableSort
      />
      <Column
        dataKey="rowSelector"
        label=""
        width={34}
        headerRenderer={checkboxCellWrapperRenderer(toggleAllSelectionRenderer(props))}
        cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer(props))}
        disableSort
      />
      <Column
        dataKey="matterDisplay"
        label="Matter"
        cellRenderer={matterOrInvoiceNumberCellRenderer(props, t)}
        flexGrow={5}
      />
      <Column dataKey="debtor" label="Debtor(s)" cellRenderer={debtorCellRenderer(props)} flexGrow={5} disableSort />
      <Column
        dataKey="issuedDate"
        label="Issued Date"
        cellRenderer={yyyymmddLocalisedRenderer}
        width={100}
        disableSort
      />
      <Column dataKey="dueDate" label="Due Date" cellRenderer={yyyymmddLocalisedRenderer} width={100} disableSort />
      <Column
        className="right-align"
        dataKey="totalDue"
        label={getTotalDueLabel({ t })}
        flexGrow={2}
        maxWidth={180}
        cellRenderer={balanceCellLocalisedRenderer}
        footerRenderer={balanceCellLocalisedRenderer}
      />
      <Column
        className="right-align"
        dataKey="trustBalance"
        label={`${t('trust')} Balance`}
        width={100}
        flexGrow={1}
        cellRenderer={accountCellRenderer(t)}
        footerRenderer={balanceCellLocalisedRenderer}
      />
      <Column
        className={Styles.paymentAmount}
        dataKey="trustAmount"
        label={`${t('trust')} Transfer`}
        width={100}
        flexGrow={1}
        cellRenderer={paymentAmountCellRenderer(props, 'trust')}
        footerRenderer={balanceCellLocalisedRenderer}
        disableSort
      />
      <Column
        className={Styles.autoAllocate}
        dataKey="isAutoAllocated"
        label="Auto Allocate"
        cellRenderer={autoAllocateRenderer(props)}
        width={50}
        flexGrow={1}
      />
    </Table>
  );
});

function getTotalDueLabel({ t }) {
  if (hasFacet(facets.tax)) {
    return hasFacet(facets.interest) ? `Amount Due (Inc Interest/${t('tax')})` : `Amount Due (Inc ${t('tax')})`;
  }
  return hasFacet(facets.interest) ? `Amount Due (Inc Interest)` : `Amount Due`;
}

function expandAllRowsRenderer({ allExpanded, allMatterIds, onToggleAllMattersExpanded }) {
  return () => (
    <ExpandCollapseToggler
      className={Styles.headerExpandCollapseToggler}
      isExpanded={allExpanded}
      onToggled={() => onToggleAllMattersExpanded(allMatterIds, !allExpanded)}
    />
  );
}

function expanderCellRenderer({ onToggleMatterExpanded }) {
  return ({ rowData }) => {
    // Invoice rows do not need an expander, just the spacing for the column.
    if (rowData.type !== 'MATTER') {
      return null;
    }

    return (
      <ExpandCollapseToggler
        isExpanded={rowData.isExpanded}
        className={Styles.expandCollapseToggler}
        onToggled={() => onToggleMatterExpanded(rowData.matterId, !rowData.isExpanded)}
      />
    );
  };
}

function onRowClicked({ onToggleMatterExpanded }) {
  return ({ rowData }) => {
    // Invoice rows do not need an expander, just the spacing for the column.
    if (rowData.type !== 'MATTER') {
      return;
    }

    onToggleMatterExpanded(rowData.matterId, !rowData.isExpanded);
  };
}

function toggleAllSelectionRenderer({ allSelected, allInvoiceIds, onToggleSelectAll }) {
  return () => (
    <Checkbox
      className="row-checkbox"
      checked={allSelected}
      onChange={() => {
        onToggleSelectAll(allInvoiceIds, !allSelected);
      }}
    />
  );
}

function checkboxCellRenderer({ onToggleInvoiceSelected, onToggleMatterSelected }) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return (
        <Checkbox
          checked={rowData.isSelected}
          onChange={() => onToggleMatterSelected(rowData.invoiceIds, !rowData.isSelected)}
        />
      );
    }
    return (
      <Checkbox
        checked={rowData.isSelected}
        onChange={() => onToggleInvoiceSelected(rowData.invoiceId, !rowData.isSelected)}
      />
    );
  };
}

function matterOrInvoiceNumberCellRenderer({ onClickLink }, t) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return (
        <div className={Styles.matterRowContainer}>
          <MatterDisplay onClickLink={onClickLink} asLink matterId={rowData.matterId} />
          {rowData.isMultipleContactBalances && (
            <ContextMenu
              body={() =>
                'This matter has retainer funds from multiple contacts, funds will be taken from the contact ' +
                'with the lowest retainer balance first. To allocate by contact, ' +
                'please pay each invoice individually.'
              }
              timeout={0}
              showOnHover
            >
              <span className="icon icon-contacts-1" />
            </ContextMenu>
          )}
          {featureActive('BB-9573') && rowData?.hasUnpaidAD && (
            <div className={Styles.infoIcon}>
              <i
                title={`One or more invoices contains an anticipated ${t(
                  'expense',
                )} that has not yet been paid to the supplier.`}
                className="icon-alert-1"
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className={Styles.invoiceRowContainer}>
        <InvoiceDisplay invoiceId={rowData.invoiceId} onClickLink={onClickLink} asLink />
        {featureActive('BB-9573') && rowData?.hasUnpaidAD && (
          <div className={Styles.infoIcon}>
            <i
              title={`This invoice contains an anticipated ${t('expense')} that has not yet been paid to the supplier.`}
              className="icon-alert-1"
            />
          </div>
        )}
      </div>
    );
  };
}

function debtorCellRenderer({ onClickLink }) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return null;
    }

    return (
      rowData &&
      rowData.debtorIds && (
        <div className={Styles.invoiceDebtors}>
          <span className={Styles.debtorsDisplay}>
            {rowData.debtorIds
              .map((debtorId) => (
                <ContactDisplay
                  key={debtorId}
                  onClickLink={onClickLink}
                  asLink
                  contactId={debtorId}
                  inline
                  className={Styles.debtor}
                  showLastNameFirst
                />
              ))
              .reduce((acc, elem) => {
                if (acc === null) {
                  return [elem];
                }
                return [...acc, ' | ', elem];
              }, null)}
          </span>
          {rowData.debtorIds.length > 1 && (
            <span className={Styles.multiDebtorsIcon}>
              <i title={rowData.debtorDisplay} className={classnames('icon', 'icon-contacts-1')} />
            </span>
          )}
          <PaymentPlanIndicator
            entityId={rowData.paymentPlanId}
            entityType={entityTypes.PAYMENT_PLAN}
            entityTooltipName="invoice-debtor"
          />
        </div>
      )
    );
  };
}

function autoAllocateRenderer({ onToggleAutoAllocate }) {
  return ({ rowData }) => {
    if (rowData.type !== 'MATTER') {
      return null;
    }

    return (
      <label className={Styles.autoAllocateCell}>
        <SlidingToggle
          scope={rowData.matterId}
          name={rowData.matterId}
          selected={rowData.isAutoAllocated}
          onChange={() =>
            onToggleAutoAllocate({
              matterId: rowData.matterId,
              isAutoAllocated: !rowData.isAutoAllocated,
              matterTrustBalance: rowData.trustBalance,
              invoices: rowData.invoices,
            })
          }
        />
      </label>
    );
  };
}

function paymentAmountCellRenderer({ onChangePaymentAmount }, accountType) {
  return ({ rowData }) => {
    const paymentAmount = Math.max(0, rowData[`${accountType}Amount`]);

    if (rowData.type === 'MATTER') {
      return (
        <CurrencyDisplay
          amount={paymentAmount}
          asInput={false}
          styleClass={classnames(rowData[`${accountType}IsError`] && !rowData.isExpanded && Styles.errorText)}
          region={REGION}
        />
      );
    }

    const accountBalance = Math.max(0, rowData[`${accountType}Balance`]);
    const max = Math.min(rowData.totalDue, accountBalance);
    const isError = rowData[`${accountType}Error`];
    const isDisabled = isError
      ? false
      : rowData.isAutoAllocated || accountBalance === 0 || !rowData.isSelected || rowData.totalDue === 0;
    return (
      <CurrencyInput2
        className="currency-input"
        value={paymentAmount}
        min={0}
        max={max}
        disabled={isDisabled}
        onChange={(e) => onChangePaymentAmount(rowData.invoiceId, e.target.value)}
        hasError={isError}
        hideDollar
      />
    );
  };
}

function accountCellRenderer(t) {
  return ({ rowData }) => t('cents', { val: Math.max(0, rowData.trustBalance) });
}

TrustToOfficeTable.displayName = 'TrustToOfficeTable';

TrustToOfficeTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.oneOf(['MATTER']).isRequired,
        matterId: PropTypes.string.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        matterDisplay: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isMultipleContactBalances: PropTypes.bool.isRequired,
        trustIsError: PropTypes.bool.isRequired,
        isAutoAllocated: PropTypes.bool.isRequired,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string.isRequired,
            totalDue: PropTypes.number,
          }),
        ),
        invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        trustBalance: PropTypes.number.isRequired,
        totalDue: PropTypes.number.isRequired,
        trustAmount: PropTypes.number.isRequired,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf(['INVOICE']),
        invoiceId: PropTypes.string,
        matterId: PropTypes.string,
        accountId: PropTypes.string,
        debtorIds: PropTypes.arrayOf(PropTypes.string),
        issuedDate: PropTypes.number,
        dueDate: PropTypes.number,
        totalDue: PropTypes.number,
        isSelected: PropTypes.bool,
        trustAmount: PropTypes.number,
        trustBalance: PropTypes.number,
        trustError: PropTypes.bool,
        isAutoAllocated: PropTypes.bool,
      }),
    ]),
  ).isRequired,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  allMatterIds: PropTypes.array.isRequired,
  allInvoiceIds: PropTypes.array.isRequired,
  tableSummary: PropTypes.object.isRequired,
  onToggleAllMattersExpanded: PropTypes.func.isRequired,
  onToggleMatterExpanded: PropTypes.func.isRequired,
  onToggleInvoiceSelected: PropTypes.func.isRequired,
  onToggleMatterSelected: PropTypes.func.isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  onToggleAutoAllocate: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangePaymentAmount: PropTypes.func.isRequired,
};

TrustToOfficeTable.defaultProps = {};

export default TrustToOfficeTable;
