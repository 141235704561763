'use strict';

const { isFirmCardSavingEnabledForBankAccount } = require('./is-firm-card-saving-enabled-for-bank-account');
/**
 * isClientCardSavingEnabledForBankAccount
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings FeeWise settings as configured in firm settings
 * @param {string} params.bankAccountId The bank account ID to check for FeeWise card saving availability.
 * @returns {boolean}
 */
const isClientCardSavingEnabledForBankAccount = ({ formattedProviderSpecificSettings, bankAccountId }) =>
  isFirmCardSavingEnabledForBankAccount({ formattedProviderSpecificSettings, bankAccountId });

module.exports = {
  isClientCardSavingEnabledForBankAccount,
};
