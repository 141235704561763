import { Spinner, useTranslation } from '@sb-itops/react';
import { IconSelector } from 'web/components/documents-tab/icon-selector';
import React from 'react';
import * as messageDisplay from '@sb-itops/message-display';
import { fetchGetP } from '@sb-itops/redux/fetch';
import classNames from 'classnames';
import moment from 'moment';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './RecentDocumentsWidget.module.scss';

export const RecentDocumentsWidget = ({
  isEditing,
  documents,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  onRefresh,
  matterId,
}) => {
  const { t } = useTranslation();

  const getTimestampDisplay = (d) => {
    const diff = moment().startOf('day').diff(moment(d), 'days');

    if (diff === 0) {
      return <div className={WidgetStyles.bold}>Today</div>;
    }
    if (diff === 1) {
      return <div>Yesterday</div>;
    }
    if (diff > 1) {
      return <div>{t('date', { ts: d })}</div>;
    }
    return null;
  };

  return (
    <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
      <WidgetTitle
        isEditing={isEditing}
        onRefresh={onRefresh}
        onClick={() => onClickLink({ type: 'matterDocuments', id: matterId })}
        onChange={onChange}
        onRemove={onRemove}
        settings={settings}
      />
      {loading ? (
        <div className={WidgetStyles.loading}>
          <Spinner small />
        </div>
      ) : (
        <div className={WidgetStyles.content}>
          {documents.length === 0 && 'No recent documents'}
          {documents.map((doc) => (
            <div
              key={doc.id}
              onClick={async () => {
                messageDisplay.info('Your download will start shortly, please wait');
                const {
                  body: { downloadUrl },
                } = await fetchGetP({
                  path: `/matter-management/matter/file/:accountId/${doc.matter.id}/${doc.id}/download`,
                  fetchOptions: {},
                });
                const link = document.createElement('a');
                link.href = downloadUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              className={Styles.item}
            >
              <IconSelector isSolid extension={doc.fileExtension} />
              <div className={Styles.filename}>{`${doc.name}${doc.fileExtension}`}</div>
              <div className={Styles.dateModified}>{getTimestampDisplay(doc.dateAccessed)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
