import { getRegion } from '@sb-itops/region';
const REGION = getRegion();
angular.module('sb.billing.webapp').directive('sbAddRegion', function() {
  'use strict';

  return {
    link: function link(scope, element) {
      element.addClass(REGION);
    }
  };
});
