const { pick: getProperty } = require('dot-object');
const allocateTargetsFromSources = require('./allocate-targets-from-sources');

/**
 * Shape of bank accounts drawn upon for allocation.
 *
 * @typedef  {Object} BankAccount
 * @property {string} id      - The id of the bank account
 * @property {number} balance - The amount available to be drawn upon from this bank account.
 */

/**
 * Shape of allocations created by this allocator.
 *
 * @typedef  {Object} Allocation
 * @property {string} bankAccountId      - The id of the bank account drawn upon for this allocation.
 * @property {number} remainingOnAccount - The remaining balance of the bank account used for this allocation - i.e. running remaining balance for the bank account.
 * @property {BankAccount} bankAccount   - The bank account used for this allocation. Note that the bank account object is unchanged across all allocations.
 * @property {string} entityId           - The id of the entity targeted by this allocation.
 * @property {number} remainingOnEntity  - The amount remaining to be allocated for the target entity after this allocation has been applied - i.e. running remaining amount of the entity.
 * @property {Object} entity             - The entity targeted by this allocation. Note that the entity object is unchanged across all allocations.
 * @property {number} amount             - The amount allocated in this allocation.
 */

/**
 * allocateEntitiesFromBankAccounts
 *
 * Creates {@link Allocation}s against an array of entities by drawing upon an array of {@link BankAccount}s.
 * The order of allocation is dictated by the ordering of {@link bankAccounts} and {@link entities}.
 *
 * The 'id' and 'amount' of an entity is determined by the {@link entityId} and {@link entityProp} parameters respectively.
 * If the parameter is a string, it is used as a dot object notation reference to a property of the entity to be extracted as the id or amount.
 * If the parameter is a function, it will be passed each entity as it's processed and the return value will be used as the id or amount.
 *
 * The strategy behaves as follows;
 *
 * Loop over each entity in the order they are provided
 *   For each entity, loop over each bank account in the order they are provided
 *     For each bank account, draw the maximum amount possible to satisfy the entity amount (up to the remaining enitty amount).
 *
 * @param {BankAccount[]} bankAccounts   - The bankAccounts which will be drawn upon to create allocations for the entity amounts.
 * @param {Object[]} entities            - The entities to be targeted for allocation.
 * @param {string | Function} entityId   - Used to determine the id of each entity object. See above for more details.
 * @param {string | Function} entityProp - Used to determine the amount of each entity object. See above for more details.
 * @param {boolean}  zeroAllocate - Flag indicating whether sources will receive a 0 amount allocations once the current target amount has hit 0.
 *                                  True: All bank accounts will be present in the allocations for each entity, some may have 0 amount allocations.
 *                                  False: Some bank accounts may not be present in the allocations for each entity, all allocation amounts will be > 0.
 * @param {Function} transform    - Function which receives each allocation in order and returns a transformed allocation. Can be used by the caller to reshape the allocation objects.
 * @param {Function} accumulate   - Function which receives each allocation in order which can be used by the caller to efficiently calculate accumulation data across the allocations.
 *
 * @return {Allocation[]}         - The calculated allocations.
 */
module.exports = ({
  bankAccounts,
  entities,
  entityId,
  entityProp,
  zeroAllocate = false,
  transform = (allocation) => allocation,
  accumulate = () => {},
}) => {
  // Prepare the bank accounts as sources for the allocations.
  const { bankAccountsById, sources } = bankAccounts.reduce(
    (acc, bankAccount) => {
      acc.bankAccountsById[bankAccount.bankAccountId] = bankAccount;
      acc.remainingBalances = bankAccount.balance;
      acc.sources.push({
        sourceId: bankAccount.bankAccountId,
        amount: bankAccount.balance,
      });
      return acc;
    },
    {
      bankAccountsById: {},
      sources: [],
    },
  );

  // Prepare the entities as targets for the allocations.
  const getEntityId = typeof entityId === 'string' ? (entity) => getProperty(entityId, entity) : entityId;
  const getEntityAmount = typeof entityProp === 'string' ? (entity) => getProperty(entityProp, entity) : entityProp;

  const { entitiesById, targets } = entities.reduce(
    (acc, entity) => {
      const id = getEntityId(entity);
      acc.entitiesById[id] = entity;
      acc.targets.push({
        targetId: id,
        amount: getEntityAmount(entity),
      });

      return acc;
    },
    {
      entitiesById: {},
      targets: [],
    },
  );

  // Calculate the allocations.
  return allocateTargetsFromSources({
    sources,
    targets,
    zeroAllocate,
    transform: (rawAllocation) => {
      // Convert the raw allocation to the allocation format exposed by this allocator interface.
      const interfaceAllocation = {
        bankAccountId: rawAllocation.sourceId,
        remainingOnAccount: rawAllocation.remainingOnSource,
        bankAccount: bankAccountsById[rawAllocation.sourceId],
        entityId: rawAllocation.targetId,
        remainingOnEntity: rawAllocation.remainingOnTarget,
        entity: entitiesById[rawAllocation.targetId],
        amount: rawAllocation.amount,
      };

      // Now transform the allocation using the transform function provided by the caller,
      // and send it to the accumulator.
      const clientAllocation = transform(interfaceAllocation);
      accumulate(clientAllocation);
      return clientAllocation;
    },
  });
};
