import React from 'react';
import { colorTuples } from '@sb-firm-management/business-logic/firms';
import * as Styles from './StaffColorPicker.module.scss';
import { StaffColorBlock } from './StaffColorBlock';

export const StaffColorPicker = ({ colorFill, colorStroke, onChange }) => (
  <div className={Styles.container}>
    {colorTuples.map(([tupleFill, tupleStroke], i) => (
      <StaffColorBlock
        key={i}
        colorFill={tupleFill}
        colorStroke={tupleStroke}
        selected={tupleFill === colorFill && tupleStroke === colorStroke}
        onClick={() => onChange(tupleFill, tupleStroke)}
      />
    ))}
  </div>
);
