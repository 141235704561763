import { getOperatingAccount } from '../bank-account';
import { getDefaultOperatingChequePrintSettings } from './default-settings';
import { api } from './cache';

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getOperatingChequePrintSettings = () => {
  const bankAccountId = getOperatingAccount().id;
  return getById(bankAccountId);
};

export const isOperatingChequesEnabled = () => {
  const operatingChequeSettings = getOperatingChequePrintSettings() || getDefaultOperatingChequePrintSettings();
  return operatingChequeSettings.printingActive;
};

export { getDefaultOperatingChequePrintSettings };
export { saveOperatingChequePrintSettings } from './save-settings';
