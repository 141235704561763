const unmarshalGracePeriod = (storedInterestSettings) => {
  const gracePeriodData = {
    hasGracePeriod: !!storedInterestSettings.gracePeriodDays,
    gracePeriodDays: storedInterestSettings.gracePeriodDays || 0,
  };

  return gracePeriodData;
};

const unmarshalRatePA = (storedInterestSettings) => {
  const ratePAData = {
    hasInterestRate: !!storedInterestSettings.ratePA,
    ratePa: storedInterestSettings.ratePA || 0,
  };

  return ratePAData;
};

const unmarshalInterestApplyMode = (storedInterestSettings) => {
  let interestApplyMode = 'AfterDueDate';

  if (storedInterestSettings) {
    switch (storedInterestSettings.interestApplyMode) {
      case 1: {
        interestApplyMode = 'AfterDueDate';
        break;
      }
      case 0: {
        interestApplyMode = 'AfterGracePeriod';
        break;
      }
      default: {
        break;
      }
    }
  }

  return {
    interestApplyMode,
  };
};

export const unmarshalInterestSettings = (storedInterestSettings) => {
  const interestSettings = {};

  if (storedInterestSettings) {
    Object.assign(
      interestSettings,
      unmarshalRatePA(storedInterestSettings),
      unmarshalInterestApplyMode(storedInterestSettings),
      unmarshalGracePeriod(storedInterestSettings),
    );

    return interestSettings;
  }

  return interestSettings;
};
