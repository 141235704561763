import uuid from '@sb-itops/uuid';

/**
 * Handling standard notification subscriptions. When a notification arrives
 * we check if there are any related subscriptions registered, and execute the
 * associated callbacks.
 *
 * Subscriptions structure:
 *
 * Map {
 *   'SampleNotification' => Map {
 *     'subscriptionGuid1' => [ callback1 ],
 *     'subscriptionGuid2' => [ callback2, callback3 ],
 *   },
 *   'AnotherNotification' => Map {
 *     'subscriptionGuid1' => [ callback1 ],
 *   },
 * }
 */
const subscriptions = new Map();

/**
 * Register Notification Subscriptions
 *
 * @param {object} params
 * @param {string[]} params.notificationIds
 * @param {(...any) => void} params.callback
 * @returns function that clears the newly added notification subscriptions
 */
export const subscribeToNotifications = ({ notificationIds, callback }) => {
  const subscriptionId = uuid();

  notificationIds.forEach((notificationId) => {
    const currentSubsForNotificationId = subscriptions.get(notificationId);

    if (!currentSubsForNotificationId) {
      subscriptions.set(notificationId, new Map([[subscriptionId, callback]]));
    } else {
      currentSubsForNotificationId.set(subscriptionId, callback);
    }
  });

  return () => {
    notificationIds.forEach((notificationId) => {
      const currentSubsForNotificationId = subscriptions.get(notificationId);

      if (currentSubsForNotificationId) {
        currentSubsForNotificationId.delete(subscriptionId);
      }

      // Clean up notificationId map
      if (currentSubsForNotificationId.size === 0) {
        subscriptions.delete(notificationId);
      }
    });
  };
};

/**
 * Process Notification
 *
 * @param {Object} params
 * @param {string} params.provider
 * @param {string} params.action
 * @param {Object} params.payload
 * @returns
 */
export const processNotification = ({ notificationId, payload, action }) => {
  // subscribers to ProviderId
  const notificationSubscriptions = subscriptions.get(notificationId) || [];
  // subscribers to ProviderId.Action
  const actionNotificationSubscriptions = subscriptions.get(action ? `${notificationId}.${action}` : undefined) || [];

  const allNotifications = [...notificationSubscriptions.values(), ...actionNotificationSubscriptions.values()];
  if (!allNotifications.length) {
    return;
  }

  allNotifications.forEach((callback) => {
    if (typeof callback !== 'function') {
      return;
    }

    callback(payload);
  });
};
