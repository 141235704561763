'use strict';

function consolidateBulkDepositTransactions({ bulkDepositTransactionsMap, t }) {
  // bulkDepositTransactionsMap has the shape of
  // {
  //    pseudoTransactionId1: [transactions]
  //    pseudoTransactionId2: [transactions]
  //    ...
  // }
  const bulkDepositTransactions = Object.values(bulkDepositTransactionsMap);
  const txns = bulkDepositTransactions.map((transactions) =>
    transactions.reduce(
      (acc, txn) => {
        const isReversal = !!txn.reversedFromTransactionId;
        const bulkDepositLabel = `Bulk ${t((txn.bankAccountType || '').toLowerCase())} deposit`;

        return {
          id: transactions[0].bulkDepositId,
          amount: (acc.amount || 0) + txn.amount,
          isBulkDeposit: true,
          description: acc.description || `${isReversal ? 'Reversal: ' : ''}${bulkDepositLabel}`,
          accountId: txn.accountId,
          bankAccountId: txn.bankAccountId,
          bankAccountType: txn.bankAccountType,
          bankName: txn.bankName,
          bankBranchNumber: txn.bankBranchNumber,
          drawerBank: txn.drawerBank,
          effectiveDate: txn.effectiveDate,
          matterIds: acc.matterIds.includes(txn.matterId) ? acc.matterIds : [...acc.matterIds, txn.matterId],
          contactId: txn.contactId,
          note: !acc.note || acc.note === txn.note ? txn.note : `${acc.note}\n${txn.note}`,
          chequeId: txn.chequeId,
          source: txn.source,
          reason: !acc.reason || acc.reason === txn.reason ? txn.reason : `${acc.reason}\n${txn.reason}`,
          reference: txn.reference,
          reversed: txn.reversed,
          reversedFromTransactionIds: txn.reversedFromTransactionId
            ? [...acc.reversedFromTransactionIds, txn.reversedFromTransactionId]
            : acc.reversedFromTransactionIds,
          isHidden: txn.isHidden,
          isReversal,
          timestamp: transactions[0].timestamp,
          transactionIds: [...acc.transactionIds, txn.id],
          transactions: [...acc.transactions, txn],
          type: txn.type,
          userId: txn.userId,
        };
      },
      {
        matterIds: [],
        transactions: [],
        transactionIds: [],
        reversedFromTransactionIds: [],
      },
    ),
  );

  return txns;
}

module.exports = {
  consolidateBulkDepositTransactions,
};
