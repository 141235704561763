import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Tile.module.scss';

// for now we do not need it but in the future we can include areas and tile sizes
const Tile = ({ children }) => <div className={Styles.tile}>{children}</div>;

Tile.displayName = 'Tile';

Tile.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tile;
