import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation } from '@sb-itops/react';
import { AddEditControlledMoneyAccountForm } from '../controlled-money-account-add-edit';
import Styles from './ControlledMoneyAccountAddEditModal.module.scss';

export const ControlledMoneyAccountAddEditModal = React.memo(
  ({ onSave, onClose, onAccountClose, isSubmitDisabled, isVisible, scope, clientCustomerIds, accountId }) => {
    const { t } = useTranslation();

    if (!isVisible) {
      return null;
    }
    const isEdit = !!accountId;

    return (
      <Modal
        className={Styles.addEditModal}
        isVisible={isVisible}
        title={t('controlledMoneyAccount')}
        body={
          <AddEditControlledMoneyAccountForm
            scope={scope}
            clientCustomerIds={clientCustomerIds}
            accountId={accountId}
          />
        }
        footer={
          <div className={Styles.buttonContainer}>
            <Button
              className={Styles.openButton}
              onClick={onSave}
              disabled={isSubmitDisabled}
              form={scope}
              locked={isSubmitDisabled}
              size="full-width"
            >
              {isEdit ? 'Save' : 'Open Account'}
            </Button>
            {isEdit && (
              <Button
                className={Styles.closeAccount}
                onClick={onAccountClose}
                disabled={isSubmitDisabled}
                form={scope}
                locked={isSubmitDisabled}
                type="link"
              >
                CLOSE ACCOUNT
              </Button>
            )}
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

ControlledMoneyAccountAddEditModal.displayName = 'ControlledMoneyAccountAddEditModal';

ControlledMoneyAccountAddEditModal.propTypes = {
  clientCustomerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  scope: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccountClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  accountId: PropTypes.string,
};

ControlledMoneyAccountAddEditModal.defaultProps = {
  isSubmitDisabled: false,
  accountId: undefined,
};
