'use strict';

const resourceIds = {
  BILLING_DATA: 'billing/data',
  BILLING_BANK_ACCOUNT: 'billing/bank-account', // Doesn't actually exist - is just a check to see if user is firm owner
  BILLING_FIRM_OWNER: 'billing/firm-owner', // Doesn't actually exist, same as BILLING_BANK_ACCOUNT - permission framework returns true for any non-existent permission when user is firm owner
  BILLING_DATA_FIRM_ACCOUNTS: 'billing/data/firm/accounts',
  BILLING_DATA_FIRM_REPORTS: 'billing/data/firm/reports',
};

module.exports = {
  resourceIds,
};
