/**
 * Group matters by client and flatten to a list of client/matter each to be displayed as a row in the UI
 * @param {Object} params
 * @param {Object[]} params.matters  a list of matters sorted by first client name followed by any appropriate secondary sort
 * @param {Object} params.expandedClients a map of client id to boolean indicating whether the client is expanded on UI
 * @returns a list of client/matter rows to be displayed on UI
 */
const groupMattersByClient = ({ matters, expandedClients }) => {
  let clientMatters = [];
  let clientsMap = {};
  const updatedExpandedClients = { ...expandedClients };

  // group by client
  clientsMap = matters.reduce((acc, matter) => {
    if (matter.hasDebtor) {
      // for matter with multiple clients, display matter under the first client as per existing behaviour
      const client = matter.clients[0];
      if (!acc[client.id]) {
        acc[client.id] = {
          clientId: client.id,
          displayName: client.displayName,
          matters: {},
          hasSelected: false,
        };
      }
      if (!acc[client.id].matters[matter.matterId]) {
        acc[client.id].matters[matter.matterId] = matter;
      }

      // expand clients by default as per existing behaviour
      if (expandedClients[client.id] === undefined) {
        updatedExpandedClients[client.id] = true;
      }
    } else {
      // no client/debtor
      if (!acc.noDebtor) {
        acc.noDebtor = {
          clientId: 'noDebtor',
          displayName: '(No debtor set)',
          matters: {},
          hasSelected: false,
        };
      }
      clientMatters.push(acc.noDebtor);
      if (!acc.noDebtor.matters[matter.matterId]) {
        acc.noDebtor.matters[matter.matterId] = matter;
      }

      // expand clients by default as per existing behaviour
      if (expandedClients.noDebtor === undefined) {
        updatedExpandedClients.noDebtor = true;
      }
    }
    return acc;
  }, {});

  // flatten to clientMatters
  clientMatters = Object.values(clientsMap).reduce((acc, client) => {
    acc.push(client);
    if (updatedExpandedClients[client.clientId]) {
      Object.values(client.matters).forEach((matter) => {
        acc.push(matter);
      });
    }
    return acc;
  }, []);

  return { clientMatters, expandedClients: updatedExpandedClients };
};

export { groupMattersByClient };
