/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { useTranslation } from '@sb-itops/react';

export const TrustAccountsForFilterPanels = ({
  trustAccounts,
  getSpecificOnClickLinkParams,
  getProtectedFundsOnClickLinkParams,
  showProtectedFunds,
  onClickLink,
  onNavigateOverview,
  selectedTrustAccountId,
  selectedAccountType,
}) => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!trustAccounts.length && (
        <>
          <li
            onClick={onNavigateOverview}
            className={classnames({
              active:
                selectedAccountType === bankAccountTypeEnum.TRUST &&
                (selectedTrustAccountId === 'all' || !selectedTrustAccountId),
            })}
          >
            {t('trustAccounts')}
          </li>
          {trustAccounts.map((trust) => (
            <React.Fragment key={trust.id}>
              <li
                onClick={() => onClickLink(getSpecificOnClickLinkParams(trust.id))}
                className={classnames({
                  indent: true,
                  active: selectedAccountType === bankAccountTypeEnum.TRUST && selectedTrustAccountId === trust.id,
                })}
              >
                {trust.label}
              </li>
              {showProtectedFunds && featureActive('BB-8671') && (
                <li
                  onClick={() => onClickLink(getProtectedFundsOnClickLinkParams(trust.id))}
                  className={classnames({
                    indent2: true,
                    active: selectedAccountType === 'PROTECTED_FUNDS' && selectedTrustAccountId === trust.id,
                  })}
                >
                  - Protected Funds
                </li>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

TrustAccountsForFilterPanels.displayName = 'TrustAccountsForFilterPanels';
TrustAccountsForFilterPanels.propTypes = {
  trustAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onNavigateOverview: PropTypes.func.isRequired,
  getSpecificOnClickLinkParams: PropTypes.func.isRequired,
  getProtectedFundsOnClickLinkParams: PropTypes.func,
  onClickLink: PropTypes.func.isRequired,
  selectedTrustAccountId: PropTypes.string,
  selectedAccountType: PropTypes.string,
  showProtectedFunds: PropTypes.bool,
};

TrustAccountsForFilterPanels.defaultProps = {
  selectedTrustAccountId: undefined,
  selectedAccountType: undefined,
  showProtectedFunds: false,
  getProtectedFundsOnClickLinkParams: () => {},
};
