import { type UnionToIntersection } from 'type-fest';
import { JSX } from 'react';

import { schemeMappings } from '@sb-itops/region-schemes';

import { AddressFormGb } from './AddressFormGb';
import { AddressFormUs } from './AddressFormUs';
import { AddressFormAu } from './AddressFormAu';

const addressForms = {
  [schemeMappings.addressScheme.AU]: AddressFormAu,
  [schemeMappings.addressScheme.US]: AddressFormUs,
  [schemeMappings.addressScheme.GB]: AddressFormGb,
} as const;

type MergeProps<T extends Record<string, (props: any) => JSX.Element | null>> = UnionToIntersection<
  Parameters<T[keyof T]>[0]
>;

type AddressFormProps = MergeProps<typeof addressForms>;

export const AddressForm = (props: AddressFormProps) => addressForms[props.addressScheme](props);
