import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes } from '@sb-itops/react';
import classnames from 'classnames';
import { hasBillingAccess } from 'web/services/user-session-management';

const FinancialSummary = memo(({ children, responsive }) => {
  const [showBalance, setShowBalance] = useState(false);
  return hasBillingAccess() ? (
    <>
      {responsive && (
        <Button
          onClick={() => {
            setShowBalance(!showBalance);
          }}
          className={classnames({ 'financial-summary-container': true, 'low-res': true, active: showBalance })}
          type={buttonTypes.info}
        >
          <div className="view-balances-label">
            <div className="icon icon-currency-round" /> View Balances
          </div>
          {showBalance && (
            <div className="financial-summary-details">
              {children}
              <i onClick={() => setShowBalance(false)} className="close-button fa fa-times fa-fw" />
            </div>
          )}
        </Button>
      )}
      <div className={classnames({ 'financial-summary-container': true, 'high-res': responsive })}>
        <div className="financial-summary-details">{children}</div>
      </div>
    </>
  ) : null;
});

FinancialSummary.displayName = 'FinancialSummary';

FinancialSummary.propTypes = {
  children: PropTypes.element.isRequired,
  responsive: PropTypes.bool,
};

FinancialSummary.defaultProps = {
  responsive: true,
};

export default FinancialSummary;
