/* eslint-disable no-param-reassign */
import { dateToInteger, daysAgo, startOfThisMonth, endOfThisMonth } from '@sb-itops/date';
import { hasFacet, facets } from '@sb-itops/region-facets';

import {
  TOGGLE_SELECT_INVOICES,
  TOGGLE_EXPAND_MATTER,
  SET_PAYMENTS,
  TOGGLE_AUTO_ALLOCATE_MATTER,
  SELECT_ALL,
  EXPAND_ALL,
  CLEAR_STATE,
  SORT_ROWS,
  RESET_FILTERS,
  SET_FILTER,
  SET_FILTER_GROUP_VISIBILITY,
  TOGGLE_SHOW_FILTERS,
} from './types';

const MATTER_STATUS_FILTER_OPTIONS = [
  { id: 'Open', label: 'Open' },
  { id: 'Pending', label: 'Pending' },
  { id: 'Closed', label: 'Closed' },
];

const INITIAL_FILTER_STATE = {
  showFilters: true,
  filters: {
    billingTypes: {
      allSelected: true,
    },
    issueDate: {
      before: dateToInteger(daysAgo(7)),
      from: dateToInteger(startOfThisMonth()),
      to: dateToInteger(endOfThisMonth()),
      filterType: hasFacet(facets.ttoDefaultDateFilters) ? 'BEFORE' : 'ALL',
      startDate: undefined,
      endDate: hasFacet(facets.ttoDefaultDateFilters) ? dateToInteger(daysAgo(7)) : undefined,
    },
    matterTypes: [],
    matterStatuses: MATTER_STATUS_FILTER_OPTIONS.map((option) => option.id),
  },
  visibleFilterGroups: {
    billingTypes: true,
    matterStatuses: true,
    attorneysResponsible: true,
    matterBalances: true,
    matterTypes: true,
    issueDate: true,
  },
};

const INITIAL_TABLE_STATE = {
  selectedInvoices: {},
  selectedInvoicesMap: {},
  expandedMatterIds: {},
  autoAllocateMatterIds: {},
  payments: {},
  sort: {
    sortDirection: 'asc',
    sortBy: 'matterDisplay',
  },
};

const INITIAL_STATE = {
  ...INITIAL_FILTER_STATE,
  ...INITIAL_TABLE_STATE,
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    // TABLE STATE REDUCERS

    case CLEAR_STATE: {
      return {
        ...state,
        ...INITIAL_TABLE_STATE,
      };
    }

    // remove SELECT_ALL once BB-14037 - Load on Demand - Bulk Trust to Office is released and stable
    case SELECT_ALL: {
      return {
        ...state,
        selectedInvoices: action.payload.invoiceIds.reduce((selected, invoiceId) => {
          selected[invoiceId] = action.payload.selected;

          return selected;
        }, {}),
      };
    }

    case EXPAND_ALL: {
      return {
        ...state,
        expandedMatterIds: action.payload.matterIds.reduce((selected, matterId) => {
          selected[matterId] = action.payload.expanded;

          return selected;
        }, {}),
      };
    }

    case TOGGLE_SELECT_INVOICES: {
      const { selectedInvoices, selectedInvoicesMap } = action.payload.invoiceIds.reduce(
        (acc, invoiceId) => {
          // selectedInvoices is a list of invoice Ids
          acc.selectedInvoices[invoiceId] = action.payload.selected;

          // selectedInvoicesMap is used to keep track of the selected invoices when the user navigates between pages
          if (action.payload.invoicesMap) {
            acc.selectedInvoicesMap[invoiceId] = action.payload.invoicesMap[invoiceId];
          }

          return acc;
        },
        {
          selectedInvoices: { ...state.selectedInvoices },
          selectedInvoicesMap: { ...state.selectedInvoicesMap },
        },
      );
      return {
        ...state,
        selectedInvoices,
        selectedInvoicesMap,
      };
    }

    case TOGGLE_EXPAND_MATTER: {
      return {
        ...state,
        expandedMatterIds: {
          ...state.expandedMatterIds,
          [action.payload.matterId]: !state.expandedMatterIds[action.payload.matterId],
        },
      };
    }

    case SET_PAYMENTS: {
      return {
        ...state,
        payments: {
          ...state.payments,
          ...action.payload.paymentsToUpdateMap,
        },
      };
    }

    case TOGGLE_AUTO_ALLOCATE_MATTER: {
      return {
        ...state,
        autoAllocateMatterIds: {
          ...state.autoAllocateMatterIds,
          [action.payload.matterId]: action.payload.isAutoAllocated,
        },
      };
    }

    // FILTER STATE REDUCERS
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case RESET_FILTERS: {
      return {
        ...state,
        ...INITIAL_FILTER_STATE,
      };
    }

    case SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
    }

    case SET_FILTER_GROUP_VISIBILITY: {
      return {
        ...state,
        visibleFilterGroups: {
          ...state.visibleFilterGroups,
          [action.payload.filterGroupName]: action.payload.isVisible,
        },
      };
    }

    case SORT_ROWS: {
      const { sortDirection, sortBy } = action.payload;
      return {
        ...state,
        sort: {
          ...state.sort,
          sortDirection,
          sortBy,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
