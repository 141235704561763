/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import {
  validateMultiple as isValidEmailList,
  validateSingle as isValidEmail,
  validateMaxEmailsNumber as isUnderMaxEmailsNumber,
} from '@sb-itops/email';
import { hasFacet, facets } from '@sb-itops/region-facets';

export const createDepositRequestFormSchema = () =>
  Yup.object().shape({
    // amount in cents, match payment portal amount field validation
    amount: Yup.number().min(1).required(),
    to: Yup.string()
      .test('is-email-list', 'Email address is not a valid format', (value) => isValidEmailList(value))
      .test('is-over-max-emails-number', 'A maximum of five recipients are allowed', (value) =>
        isUnderMaxEmailsNumber(value),
      )
      .required('Email address is required'), // Must stay at last to avoid error message overwritten by above tests
    from: Yup.string()
      .required()
      .test('is-email', 'Email address is not a valid format', (value) => isValidEmail(value)),
    cc: Yup.string()
      .test('is-email-list', 'Email address is not a valid format', (value) => value === '' || isValidEmailList(value))
      .test(
        'is-over-max-emails-number',
        'A maximum of five recipients are allowed',
        (value) => value === '' || isUnderMaxEmailsNumber(value),
      ),
    bcc: Yup.string()
      .test('is-email-list', 'Email address is not a valid format', (value) => value === '' || isValidEmailList(value))
      .test(
        'is-over-max-emails-number',
        'A maximum of five recipients are allowed',
        (value) => value === '' || isUnderMaxEmailsNumber(value),
      ),
    subject: Yup.string().required(),
    message: Yup.string()
      .required()
      .test('is-non-empty', 'Message cannot be empty', (value) => value !== '' && value !== '<p><br></p>'),
    reason: hasFacet(facets.reasonField) ? Yup.string().required() : Yup.string().nullable(),
  });
