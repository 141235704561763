import * as Styles from './WidgetDefaults.module.scss';

export const getTextSize = ({ x, y }) => {
  const min = Math.min(x + 1, y);
  switch (true) {
    case min < 2:
      return Styles.lessThanTwo;
    case min < 4:
      return Styles.lessThanFour;
    case min < 6:
      return Styles.lessThanSix;
    default: {
      return Styles.greaterThanSix;
    }
  }
};

export const WIDGET_DEFAULTS = {
  LeadsNoActivity: {
    size: { x: 2, y: 3 },
    minX: 1,
    minY: 2,
    type: 'LeadsNoActivity',
    title: 'Leads with No Activity',
    styleOptions: [
      { label: 'List', value: 'list' },
      { label: 'Number', value: 'number' },
    ],
    timeFrameUnitsOptions: [
      {
        label: 'Month/s',
        value: 'month',
      },
      {
        label: 'Week/s',
        value: 'week',
      },
      {
        label: 'Day/s',
        value: 'day',
      },
    ],
    style: { label: 'List', value: 'list' },
    showSettings: true,
    timeFrameUnits: { label: 'Week/s', value: 'week' },
    timeFrame: 1,
  },
  Tasks: {
    type: 'Tasks',
    title: 'My Overdue Tasks',
    size: { x: 2, y: 3 },
    minY: 2,
  },
  DailyDigest: {
    type: 'DailyDigest',
    title: 'Daily Digest',
    size: { x: 5, y: 5 },
    minY: 4,
    minX: 4,
  },
  Events: {
    type: 'Events',
    title: 'My Upcoming Events',
    size: { x: 2, y: 3 },
    minY: 2,
  },
  RecentlyViewed: {
    type: 'RecentlyViewed',
    title: 'My Recently Viewed',
    size: { x: 2, y: 3 },
    minY: 2,
  },
  BillableHours: {
    type: 'BillableHours',
    title: 'Hours',
    size: { x: 2, y: 3 },
    minY: 2,
    minX: 2,
  },
  BillableGraph: {
    size: { x: 4, y: 4 },
    minY: 3,
    minX: 4,
    type: 'BillableGraph',
    title: 'Billable Activity',
  },
  RecentDocuments: {
    size: { x: 2, y: 3 },
    minX: 1,
    minY: 2,
    type: 'RecentDocuments',
    title: 'Recent Documents',
    showSettings: true,
    timeFrameUnitsOptions: [
      {
        label: 'Month/s',
        value: 'month',
      },
      {
        label: 'Week/s',
        value: 'week',
      },
      {
        label: 'Day/s',
        value: 'day',
      },
    ],
    timeFrameUnits: { label: 'Week/s', value: 'week' },
    timeFrame: 1,
  },
  MattersNoActivity: {
    size: { x: 2, y: 3 },
    minX: 1,
    minY: 2,
    type: 'MattersNoActivity',
    showSettings: true,
    title: 'Matters with No Activity',
    timeFrameUnitsOptions: [
      {
        label: 'Month/s',
        value: 'month',
      },
      {
        label: 'Week/s',
        value: 'week',
      },
      {
        label: 'Day/s',
        value: 'day',
      },
    ],
    styleOptions: [
      { label: 'List', value: 'list' },
      { label: 'Number', value: 'number' },
    ],
    style: { label: 'List', value: 'list' },
    timeFrameUnits: { label: 'Week/s', value: 'week' },
    timeFrame: 1,
  },
  NewLeads: {
    size: { x: 2, y: 3 },
    minX: 2,
    minY: 2,
    type: 'NewLeads',
    title: 'New Leads',
    showSettings: true,
    styleOptions: [
      { label: 'List', value: 'list' },
      { label: 'Number', value: 'number' },
    ],
    style: { label: 'List', value: 'list' },
    timeFrameUnitsOptions: [
      {
        label: 'Month/s',
        value: 'month',
      },
      {
        label: 'Week/s',
        value: 'week',
      },
      {
        label: 'Day/s',
        value: 'day',
      },
    ],
    timeFrameUnits: { label: 'Week/s', value: 'week' },
    timeFrame: 1,
  },
  NewMatters: {
    size: { x: 2, y: 3 },
    minX: 2,
    minY: 2,
    type: 'NewMatters',
    title: 'New Matters',
    showSettings: true,
    styleOptions: [
      { label: 'List', value: 'list' },
      { label: 'Number', value: 'number' },
    ],
    style: { label: 'List', value: 'list' },
    timeFrameUnitsOptions: [
      {
        label: 'Month/s',
        value: 'month',
      },
      {
        label: 'Week/s',
        value: 'week',
      },
      {
        label: 'Day/s',
        value: 'day',
      },
    ],
    timeFrameUnits: { label: 'Week/s', value: 'week' },
    timeFrame: 1,
    startDate: moment().startOf('day').subtract(1, 'month').toISOString(),
  },
};
