import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { getRegion, regionType } from '@sb-itops/region';
import { getFirmPhoneNumber, getFirmCurrentUsers } from '@sb-firm-management/business-logic/firm-details/services';
import { findFirmLocationIdForRegion } from '@sb-firm-management/business-logic/locations/services';
import { createSelector } from 'reselect';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPutP, fetchGetP } from '@sb-itops/redux/fetch';
import domain from '../domain';

export const { UPDATE_CACHE, getMap, getList, updateCache } = cacheFactory({
  domain,
  name: 'firmManagement',
  keyPath: 'details',
  ngCacheName: 'sbFirmManagementMbService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

const REGION = getRegion();
const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbFirmManagementMbService',
  keyPath: 'details',
});

export const getFirmDetails = () => getMap().details;

const getFormerUserMapSelector = createSelector(
  (state) => (state.details && state.details.formerUsers) || [],
  (formerUsers) =>
    formerUsers.reduce((acc, formerUser) => {
      if (formerUser) {
        acc[formerUser.userId] = formerUser;
      }

      return acc;
    }, {}),
);

export const getPersonByUserId = (userId) => {
  const firmDetails = getFirmDetails();
  if (!firmDetails) {
    return;
  }

  const currentUsers = firmDetails.currentUsers || {};
  const formerUsers = getFormerUserMapSelector(getMap());

  const user = currentUsers[userId] || formerUsers[userId];
  // eslint-disable-next-line consistent-return
  return user && firmDetails.people[user.personId];
};

export const getFirmBusinessAddress = () => {
  const firmDetails = getFirmDetails();
  if (!firmDetails) {
    return;
  }

  const businessAddress = firmDetails.businessAddress || {};
  // eslint-disable-next-line consistent-return
  return businessAddress;
};

export const getStaffByPersonId = (personId) => getFirmDetails() && getFirmDetails().people[personId];

export const getCurrentStaffByPersonId = (personId) => {
  const accountDetails = getFirmDetails();

  if (accountDetails && accountDetails.people[personId] && !accountDetails.people[personId].isFormerStaff) {
    return accountDetails.people[personId];
  }

  return undefined;
};

export const getCurrentUsers = () => {
  const firmDetails = getFirmDetails();
  return getFirmCurrentUsers({ firmDetails });
};

const defaultLocationIds = {
  AU: 'NSW',
  US: 'IL',
  GB: 'ENG',
};

export const getFirmLocationId = () => {
  const firmDetails = getFirmDetails();
  const locationId = findFirmLocationIdForRegion(firmDetails, REGION);
  return locationId || defaultLocationIds[REGION];
};

export const getFirmName = () => (getFirmDetails() && getFirmDetails().firmName) || '';
export const getFirmCreationDate = () => getFirmDetails() && getFirmDetails().creationDate;
export const getCurrentUsersCount = () =>
  (getFirmDetails() && Object.keys(getFirmDetails().currentUsers || {}).length) || 0;

export const getPhoneNumber = () => {
  const firmDetails = getFirmDetails();
  return getFirmPhoneNumber(firmDetails);
};

const regionFirmOpdateStrategy = {
  [regionType.AU]: (firm) => ({
    ...firm,
    businessAddressDetailed: {
      ...firm.businessAddress,
      postcode: firm.businessAddress.zipCode,
      suburbTown: firm.businessAddress.city,
    },
    poBoxAddress: firm.mailingAddress,
    details: 'details', // this is needed for the opdate to work
  }),
  [regionType.US]: (firm) => ({
    ...firm,
    details: 'details', // this is needed for the opdate to work
  }),
  [regionType.GB]: (firm) => ({
    ...firm,
    businessAddressDetailed: {
      ...firm.businessAddress,
      postcode: firm.businessAddress.zipCode,
      suburbTown: firm.businessAddress.city,
    },
    poBoxAddress: firm.mailingAddress,
    details: 'details', // this is needed for the opdate to work
  }),
};

export const generateFirmOpdate = (firm) => regionFirmOpdateStrategy[REGION](firm);

export const getFirmTimezone = async () => {
  const path = `/firm-management/firm/get-time-zone/:accountId/`;
  const res = await fetchGetP({ path, fetchOptions: {} });
  return res.body;
};

export const editFirm = async (firmEntity) => {
  // minimal opdate entity to provide user with instant feedback on UI
  const opdateEntity = generateFirmOpdate(firmEntity);
  opdateCache({ optimisticEntities: [opdateEntity] });

  try {
    const path = `/firm-management/firm/:accountId/`;
    const fetchOptions = { body: JSON.stringify(firmEntity) };
    const res = await fetchPutP({ path, fetchOptions });

    return res.body.id;
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: [opdateEntity] });
    throw err;
  }
};
