import { gql } from '@apollo/client';
import { getExcludingTaxAmount } from '@sb-billing/business-logic/expense/services';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onSaveExpense = ({ commandResult }) => {
  const { version } = commandResult;

  const optimisticExpensePaymentDetails = !version.expensePaymentDetails
    ? null
    : {
        __typename: 'ExpensePaymentDetails',
        id: commandResult.expenseId, // ExpensePaymentDetails shares the same ID as the expense
        ...version.expensePaymentDetails,
      };

  const optimisticExpenseVersion = !version || !version.expenseVersionId ? null : {
    activityCode: version.expenseActivityId || version.utbmsActivityCode,
    amountExcTax: getExcludingTaxAmount(version),
    amountIncludesTax: version.amountIncludesTax,
    attachmentFile: !version.attachmentFile
      ? null
      : {
          location: version.attachmentFile.location,
          fileName: version.attachmentFile.fileName,
        },
    createdByUserId: version.createdByUserId,
    description: version.description,
    expenseActivityId: version.expenseActivityId, // Actually a custom activity code, e.g. CT3
    expenseDate: version.expenseDate,
    expenseEarnerStaff: {
      id: version.expenseEarnerStaffId,
    },
    expensePaymentDetails: optimisticExpensePaymentDetails,
    expenseVersionId: version.expenseVersionId,
    invoice: !version.invoiceId
      ? null
      : {
          id: version.invoiceId,
        },
    isAnticipated: version.isAnticipated,
    isBillable: version.isBillable,
    isDeleted: version.isDeleted,
    isInvoicedExternally: null,
    isTaxOverridden: version.isTaxOverridden,
    matter: {
      id: version.matterId,
    },
    notes: version.notes || null,
    operatingCheque: version.operatingCheque || null,
    price: version.price,
    quantity: version.quantity,
    tax: version.tax || 0,
    utbmsActivityCode: version.utbmsActivityCode || null,
    utbmsTaskCode: version.utbmsTaskCode || null,
    validFrom: null,
    validTo: null,
    waived: version.waived,
  }

  const optimisticExpense = {
    __typename: 'Expense',
    id: commandResult.expenseId,
    ...optimisticExpenseVersion,
  };

  const apolloClient = getApolloClient();

  // Opdate ExpensePaymentDetails first to avoid "Cache data may be lost" error
  // which is caused by changing the expensePaymentDetails field from an object
  // to a reference when the query is re-fetched.
  if (optimisticExpensePaymentDetails) {
    const expensePaymentDetailsIdentifier = apolloClient.cache.identify(optimisticExpensePaymentDetails);

    apolloClient.writeFragment({
      id: expensePaymentDetailsIdentifier,
      fragment: gql`
        fragment OpdateExpensePaymentDetails on ExpensePaymentDetails {
          ${Object.keys(optimisticExpensePaymentDetails).join('\n')}
        }
      `,
      data: {
        ...optimisticExpensePaymentDetails,
      },
    });
  }

  const expenseIdentifier = apolloClient.cache.identify(optimisticExpense);

  apolloClient.writeFragment({
    id: expenseIdentifier,
    fragment: gql`
      fragment OpdateExpense on Expense {
        ${Object.keys(optimisticExpense).join('\n')}
      }
    `,
    data: {
      ...optimisticExpense,
    },
  });

  return {
    eventType: commandResult.isUpdate
      ? apolloEntityOpdateEventTypes.ENTITY_UPDATED
      : apolloEntityOpdateEventTypes.ENTITY_ADDED,
    typeName: 'Expense',
    optimisticEntity: optimisticExpense,
  };
};
