import { gql } from '@apollo/client';

const query = gql`
  query AutoNumberDefinition {
    autoNumberDefinition {
      id
      assignToLeads
      useAutoReference
      lastUpdated
      fields {
        id
        sbEntityType
        startFrom
        numCharacters
        text
      }
    }
  }
`;

const notificationIds = ['AutoNumberDefinitionUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const AutoNumberDefinition = {
  query,
  notificationIds,
};
