import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { surchargeTypeValues, isSurchargeValid } from '@sb-billing/business-logic/invoice-surcharge';

import { SurchargeOptions } from './SurchargeOptions';

const mapStateToProps = (state, { surcharge }) => ({
  surcharge,
  surchargeType: surcharge.type,
});

export const SurchargeOptionsContainer = withReduxStore(
  connect(
    mapStateToProps,
    null,
  )((props) => {
    const [fixedSurcharge, setFixedSurcharge] = useState(props.surcharge?.fixedSurcharge || 0);
    const [percentageBp, setPercentageBp] = useState(props.surcharge?.percentageBp || 0);
    const [surchargeType, setSurchargeType] = useState(props.surcharge?.type || surchargeTypeValues.FIXED);

    const disableSurchargeConfirm = !isSurchargeValid({
      type: surchargeType,
      fixedSurcharge,
      percentageBp,
    });

    const handleSurchargeTypeChange = (e, value) => {
      const newSurchargeType = value;
      setSurchargeType(newSurchargeType);
    };
    const handleFixedSurchargeChange = (e) => {
      setSurchargeType(surchargeTypeValues.FIXED);
      setFixedSurcharge(Number(e.target.value) || 0);
    };

    const handlePercentageBpChange = (newValue) => {
      setSurchargeType(surchargeTypeValues.PERCENTAGE);
      setPercentageBp(newValue || 0);
    };

    return (
      <SurchargeOptions
        surchargeType={surchargeType}
        fixedSurcharge={fixedSurcharge}
        percentageBp={percentageBp}
        onSurchargeTypeChange={handleSurchargeTypeChange}
        onFixedSurchargeChange={handleFixedSurchargeChange}
        onPercentageBpChange={handlePercentageBpChange}
        onConfirm={() => props.onConfirmSurcharge(surchargeType, fixedSurcharge, percentageBp)}
        onDiscard={props.onDiscardSurcharge}
        disableConfirm={disableSurchargeConfirm}
      />
    );
  }),
);

SurchargeOptionsContainer.displayName = 'SurchargeOptionsContainer';

SurchargeOptionsContainer.propTypes = {
  surcharge: PropTypes.shape({
    type: PropTypes.number,
    fixedSurcharge: PropTypes.number,
    percentageBp: PropTypes.number,
  }),
  maxValue: PropTypes.number,
  onConfirmSurcharge: PropTypes.func.isRequired,
  onDiscardSurcharge: PropTypes.func.isRequired,
};

SurchargeOptionsContainer.defaultProps = {
  surcharge: {
    type: surchargeTypeValues.NONE,
    fixedSurcharge: 0,
    percentageBp: 0,
  },
  maxValue: 0,
};
