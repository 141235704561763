import { isTrustAccountClosed } from '@sb-billing/redux/bank-account';

angular.module('sb.billing.webapp').directive('sbHideIfTrustAccount', function(sbBankAccountService) {
  'use strict';
  return {
    link: function link(scope, element, attrs) {
      const ACCOUNT_TYPE = 'TRUST';
      const hideIfEnabled = attrs.sbHideIfTrustAccount === 'enabled';
      const hideIfDisabled = attrs.sbHideIfTrustAccount === 'disabled';

      function isTrustAccountEnabled() {
        const trustAccounts = sbBankAccountService.getByType(ACCOUNT_TYPE).filter((trust) => !isTrustAccountClosed(trust));
        return trustAccounts.length > 0;
      }

      if (isTrustAccountEnabled() && hideIfEnabled) {
        element.hide();
      }

      if (!isTrustAccountEnabled() && hideIfDisabled) {
        element.hide();
      }

    }
  };
});
