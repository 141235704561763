import React from 'react';
import PropTypes from 'prop-types';

import Styles from './WarningPanel.module.scss';

/**
 * Warning Panel in place where warning is displayed at the center of the screen.
 *
 * This is intentionally generic to allow more specific warning components to be
 * created with the same styling, initially the styling employed is just to center
 * the warning display, but we could also choose to draw a border or apply different
 * background colour in the future.
 * @param {object} params
 * @param {React.ReactNode} params.children - The warning message/component to display
 * @returns {React.ReactNode} - The warning panel
 */
export const WarningPanel = ({ children }) => (
  <div className={Styles.warningContainer}>
    <div className={Styles.warningDisplayArea}>{children}</div>
  </div>
);

WarningPanel.displayName = 'WarningPanel';

WarningPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

WarningPanel.defaultProps = {};
