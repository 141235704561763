import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query FeeEntriesChartData($feeFilter: FeeFilter, $offset: Int, $limit: Int, $sort: ListSort) {
    feeList(filter: $feeFilter, offset: $offset, limit: $limit, sort: $sort) {
      results {
        id
        billableDuration
        duration
        feeDate
        feeType
        isBillable
        rate
        tax
      }
    }
  }
`;

const notificationIds = [
  'WebQueryFeesNotifications.AccountFeesUpdated',
  'WebQueryInvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FeeEntriesChartData = {
  query,
  notificationIds,
};
