'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/settings/invoice';
  $stateProvider
    .state('settings.invoice', {
      templateUrl: `${BASE_DIRECTORY}/invoice-settings.html`,
      url: '/invoice',
      data: {
        navHighlights: ['invoice-settings']
      }
    });

});
