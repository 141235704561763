import {
  split as splitDescription,
  interpolateValues as interpolateDescription,
} from '@sb-billing/interpolate-description';

// TODO move this part of the file to ng- world
export const interpolate = ({ sbInvoicingService, sbMattersMbService, sbContactsMbService, sbBankAccountService }) => {
  const getterMap = {
    account: sbBankAccountService.get,
    contact: sbContactsMbService.getById,
    invoice: sbInvoicingService.getInvoice,
    matter: sbMattersMbService.getById,
  };

  return (description) => interpolateDescription(splitDescription(description), (type, id) => getterMap[type](id));
};
