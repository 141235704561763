'use strict';

const { billingFrequency } = require('./billing-frequency');
const { billingFrequencySubType } = require('./billing-frequency-sub-type');

const validateBillingFrequency = ({
  billingFrequency: billingFrequencyToValidate,
  billingFrequencySubType: billingFrequencySubTypeToValidate,
}) => {
  switch (billingFrequencyToValidate) {
    case billingFrequency.MONTHLY:
      return (
        billingFrequencySubTypeToValidate === billingFrequencySubType.MONTHLY_ALL ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.MONTHLY_EVEN ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.MONTHLY_ODD
      );
    case billingFrequency.QUARTERLY:
      return (
        billingFrequencySubTypeToValidate === billingFrequencySubType.QUARTERLY_JAN ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.QUARTERLY_FEB ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.QUARTERLY_MAR
      );
    case billingFrequency.ANNUALLY:
      return (
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_JAN ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_FEB ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_MAR ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_APR ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_MAY ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_JUN ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_JUL ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_AUG ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_SEP ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_OCT ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_NOV ||
        billingFrequencySubTypeToValidate === billingFrequencySubType.ANNUALLY_DEC
      );
    case billingFrequency.HOLD:
      return billingFrequencySubTypeToValidate === billingFrequencySubType.HOLD;
    case billingFrequency.NONE:
      return billingFrequencySubTypeToValidate === billingFrequencySubType.NONE;
    case undefined:
      return billingFrequencySubTypeToValidate === undefined;
    default:
      return false;
  }
};

module.exports = {
  validateBillingFrequency,
};
