import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyInput2, useTranslation } from '@sb-itops/react';
import { Checkbox } from '@sb-itops/react/checkbox';
import { PaymentPlanIndicator, entityTypes } from 'web/react-redux/components/payment-plan-indicator';
import classnames from 'classnames';
import { hasFacet, facets } from '@sb-itops/region-facets';
import Styles from './SplitPaymentsList.module.scss';

export const SplitPaymentsList = ({
  showErrorWhenNoInvoices,
  anyPaymentWithPaymentPlan,
  payments,
  invoiceIds,
  amounts,
  totals,
  onSelectAll,
  onChange,
  allChecked,
  invoiceHasUnpaidAnticipatedDisbursements,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(Styles.splitPaymentsList, 'split-payments-table')}>
      <div className={classnames('sb-table-header sb-no-hover-style', Styles.tableHeader)}>
        <div className={classnames('sb-table-cell', Styles.dateDue)}>Date Due</div>
        <div className={classnames('sb-table-cell', Styles.invoiceNumber)} />
        <div className={classnames('sb-table-cell', Styles.iconsContainer)} />
        <div className={classnames('sb-table-cell', Styles.due)}>Amount</div>
        {hasFacet(facets.interest) && <div className={classnames('sb-table-cell', Styles.due)}>Interest</div>}
        <div className={classnames('sb-table-cell', Styles.paid)}>Payment</div>
        <div className={classnames('sb-table-cell amount-checkbox', Styles.amountCheckbox)}>
          <label>
            <Checkbox checked={allChecked} onChange={onSelectAll} />
          </label>
        </div>
      </div>

      <div className={classnames('sb-table-content', Styles.list)} style={{ maxHeight: '200px' }}>
        {invoiceIds.map((invoiceId) => (
          <div className={Styles.item} key={invoiceId}>
            <div className={classnames('sb-table-cell', Styles.dateDue)}>
              {t('date', { yyyymmdd: payments[invoiceId].dueDate })}
            </div>
            <div
              className={classnames('sb-table-cell', Styles.invoiceNumber, Styles.cell)}
              title={payments[invoiceId].invoiceNumber}
            >
              {invoiceHasUnpaidAnticipatedDisbursements(invoiceId) && (
                <i
                  className={classnames('icon-alert-1', Styles.iconUndapidAd)}
                  title={`This invoice contains an anticipated ${t(
                    'expense',
                  )} that has not yet been paid to the supplier.`}
                />
              )}
              {payments[invoiceId].invoiceNumber}
            </div>
            <div className={classnames('sb-table-cell', Styles.iconsContainer)}>
              {anyPaymentWithPaymentPlan && (
                <PaymentPlanIndicator
                  entityId={payments[invoiceId].paymentPlanId}
                  entityType={entityTypes.PAYMENT_PLAN}
                  entityTooltipName="invoice"
                  show-warnings={false}
                />
              )}
            </div>
            <div className={classnames('sb-table-cell', Styles.due)}>
              {t('cents', { val: payments[invoiceId].unpaidExcInterest })}
            </div>
            {hasFacet(facets.interest) && (
              <div className={classnames('sb-table-cell', Styles.due)}>
                {t('cents', { val: payments[invoiceId].interest })}
              </div>
            )}

            <div className={classnames('sb-table-cell', Styles.paid)}>
              <CurrencyInput2
                value={amounts[invoiceId] || 0}
                max={payments[invoiceId].due}
                min={0}
                onChange={(amount) => onChange(invoiceId, amount.target.value)}
              />
            </div>
            <div className={classnames('sb-table-cell', Styles.amountCheckbox)}>
              <label>
                <Checkbox
                  checked={amounts[invoiceId] === payments[invoiceId].due}
                  onChange={() => {
                    onChange(invoiceId, amounts[invoiceId] === payments[invoiceId].due ? 0 : payments[invoiceId].due);
                  }}
                />
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className={classnames('sb-table-footer', 'item')}>
        <div className={classnames('sb-table-cell', Styles.dateDue)} />
        <div className={classnames('sb-table-cell', Styles.invoiceNumber)} />
        <div className={classnames('sb-table-cell', Styles.iconsContainer)} />
        <div className={classnames('sb-table-cell', Styles.due)}> {t('cents', { val: totals.unpaidExcInterest })}</div>
        {hasFacet(facets.interest) && (
          <div className={classnames('sb-table-cell', Styles.due)}>{t('cents', { val: totals.interest })}</div>
        )}
        <div className={classnames('sb-table-cell paid', Styles.paid)}>{t('cents', { val: totals.paid })}</div>
        <div className={classnames('sb-table-cell', Styles.amountCheckbox)} />
      </div>
      {showErrorWhenNoInvoices && !invoiceIds?.length && (
        <div className={Styles.errorMsg}>There are no invoices to allocate funds to</div>
      )}
    </div>
  );
};

SplitPaymentsList.displayName = 'SplitPaymentsList';

SplitPaymentsList.propTypes = {
  showErrorWhenNoInvoices: PropTypes.bool.isRequired,
  anyPaymentWithPaymentPlan: PropTypes.bool.isRequired,
  payments: PropTypes.object.isRequired,
  invoiceIds: PropTypes.array.isRequired,
  amounts: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  allChecked: PropTypes.bool.isRequired,
  invoiceHasUnpaidAnticipatedDisbursements: PropTypes.func.isRequired,
};

SplitPaymentsList.defaultProps = {};
