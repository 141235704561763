import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { DEPOSIT_FUNDS_MODAL_ID } from 'web/react-redux';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { localiseBankAccountTypeDisplay } from '@sb-billing/business-logic/bank-account/services';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { matterHasProtectedFundsForBankAccountId } from '@sb-billing/redux/balance-protection';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getMap as getBankAccountBalancesState } from '@sb-billing/redux/bank-account-balances';
import { error as displayError } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { printMethodsByValue, PrintNow } from '@sb-billing/business-logic/cheques';
import { payToVendor } from '@sb-billing/redux/bank-account/pay-to-vendor';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { DEPOSIT_FUNDS_MODAL_ID as DEPOSIT_FUNDS_NEW_MODAL_ID } from 'web/components';
import { featureActive } from '@sb-itops/feature';
import {
  VendorPaymentEntrySplitPayorsSchema,
  validateForm,
  marshalData,
} from '../vendor-payment-entry-split-payors-form';
import { VendorPaymentEntrySplitPayorsModal } from './VendorPaymentEntrySplitPayorsModal';

export const VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID = 'vendor-payment-entry-split-payors-modal';
const VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_SCOPE = 'vendor-payment-entry-split-payors-form';
const { getMatterBalance } = selectors;
const log = getLogger('VendorPaymentEntrySplitPayorsModalContainer');

const hooks = () => ({
  useSelectors: ({
    matterId: defaultMatterId,
    bankAccountId: defaultBankAccountId,
    contactId,
    onClose,
    printCheques,
    onClickLinks,
  }) => {
    const { t } = useTranslation();
    const { formValues, formSubmitting, onSubmitFormWithValidation, onValidateForm } = useForm({
      scope: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_SCOPE,
      schema: VendorPaymentEntrySplitPayorsSchema,
    });

    const allowOverdraw = hasFacet(facets.allowOverdraw);

    const [hasInsufficientFunds, setHasInsufficientFunds] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [bankAccountId, setBankAccountId] = useState(defaultBankAccountId);
    const [matterId, setMatterId] = useState(defaultMatterId);

    // Decide if we want to show the form modal or modal with insufficient funds
    useEffect(() => {
      // Modal title
      const bankAccount = getBankAccountById(bankAccountId);
      setModalTitle(getModalTitle(bankAccount, t));

      if (!matterId) {
        return;
      }

      // Decide if we show payment modal of insufficient funds modal
      if (allowOverdraw && !matterHasProtectedFundsForBankAccountId(matterId, bankAccountId)) {
        // We allow matter and firm balances to be overdrawn in AU for compliance
        setHasInsufficientFunds(false);
        return;
      }

      const matterBalance =
        matterId && bankAccountId
          ? getMatterBalance(getBankAccountBalancesState(), {
              matterId,
              bankAccountId,
            })
          : 0;
      setHasInsufficientFunds(matterId && matterBalance <= 0);
    }, [t, allowOverdraw, bankAccountId, matterId]);

    return {
      onProcess: async () => {
        try {
          const supportsReasonField = hasFacet(facets.reasonField);
          const supportsElectronicPayment = hasFacet(facets.electronicPayment);
          const supportsTrustPaymentBankTransferType = hasFacet(facets.trustPaymentBankTransferType);
          const supportsBPAY = hasFacet(facets.BPAY);
          const supportsAllowOverdraw = hasFacet(facets.allowOverdraw);

          validateForm({
            scope: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_SCOPE,
            t,
            onValidateForm,
            supportsElectronicPayment,
            supportsReasonField,
            supportsTrustPaymentBankTransferType,
            supportsAllowOverdraw,
            supportsBPAY,
          });

          await onSubmitFormWithValidation({
            submitFnP: async (finalFormValues) => {
              const data = marshalData({
                formValues: finalFormValues,
                supportsElectronicPayment,
                supportsAllowOverdraw,
                supportsBPAY,
                supportsTrustPaymentBankTransferType,
              });

              payToVendor(data);

              if (data.shouldPrintCheques) {
                printCheques(data);
              }

              // We can only display the PDF if we don't print cheques as print cheques is old Angular servervice and
              // we are not able to await that modal... so we can either show print cheque modal, or open tabs with PDFs
              if (!data.shouldPrintCheques && data.printPaymentProof) {
                printVendorProofOfPayment({
                  marshalledData: data,
                  balanceIsMatterContactBalance: isMatterContactBalanceType(),
                  onClickLinks,
                });
              }
              onClose();
            },
          });
        } catch (err) {
          log.error(err);
          displayError('Failed to process vendor payment');
        }
      },
      onOpenDepositFundsModal: () => {
        onClose();
        if (featureActive('BB-14323')) {
          setModalDialogVisible({
            modalId: DEPOSIT_FUNDS_NEW_MODAL_ID,
            props: {
              scope: 'VendorPaymentEntrySplitPayors/deposit-funds-modal',
              bankAccountId: formValues?.bankAccountId || bankAccountId,
              matterId: formValues?.matterId || matterId,
              contactId,
            },
          });
        } else {
          // Non-LOD version - can de deleted once BB-14323 is fully tested and released
          setModalDialogVisible({
            modalId: DEPOSIT_FUNDS_MODAL_ID,
            props: {
              bankAccountId: formValues?.bankAccountId || bankAccountId,
              matterId: formValues?.matterId || matterId,
              contactId,
            },
          });
        }
      },
      scope: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_SCOPE,
      isSubmitLocked: formSubmitting,
      modalTitle,
      hasInsufficientFunds,
      onBankAccountIdChange: setBankAccountId,
      onMatterIdChange: setMatterId,
    };
  },
});

const getModalTitle = (bankAccount, t) => {
  if (!bankAccount) {
    return 'Payment';
  }

  if (bankAccount.accountType === bankAccountTypeEnum.OPERATING) {
    return `${t('operatingRetainer')} Payment`;
  }
  // Unfortunately, this returns "Operating" instead of "Operating Retainer" so we have to handle operating account title separately
  return `${localiseBankAccountTypeDisplay(bankAccount, t)} Payment`;
};

const printVendorProofOfPayment = async ({ marshalledData, balanceIsMatterContactBalance, onClickLinks }) => {
  // If we need the receipt to have the reference generated or not we need to force it in the endpoint.
  // I know this is wrong, but it is the only way we have right now to force the receipt to regenerate if the reference is not present.
  // this should have been handled by the UI but with the implementation how it is we can't. This will need to be reviewed.
  const withChequeNumbering = marshalledData.chequePrintMethod === printMethodsByValue[PrintNow].code;

  // For contact balances the paymentId and transaction Id are picked from the payors.
  if (balanceIsMatterContactBalance) {
    const tabsToOpen = [];
    for (let i = 0; i < marshalledData.payors.length; i += 1) {
      const payor = marshalledData.payors[i];
      tabsToOpen.push({
        type: 'vendorProofOfPayment',
        id: {
          transactionId: payor.transactionId,
          paymentId: payor.paymentId,
          // only pass down the chequeId when we need to wait for the reference
          chequeId: withChequeNumbering ? marshalledData.chequeId : undefined,
        },
      });
    }

    await onClickLinks(tabsToOpen);
    return;
  }

  onClickLinks([
    {
      type: 'vendorProofOfPayment',
      id: {
        transactionId: marshalledData.transactionId,
        paymentId: marshalledData.paymentId,
        // only pass down the chequeId when we need to wait for the reference
        chequeId: withChequeNumbering ? marshalledData.chequeId : undefined,
      },
    },
  ]);
};

export const VendorPaymentEntrySplitPayorsModalContainer = composeHooks(hooks)(VendorPaymentEntrySplitPayorsModal);

VendorPaymentEntrySplitPayorsModalContainer.displayName = 'VendorPaymentEntrySplitPayorsModalContainer';

VendorPaymentEntrySplitPayorsModalContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  onClose: PropTypes.func,
  printCheques: PropTypes.func,
  onClickLinks: PropTypes.func,
};

VendorPaymentEntrySplitPayorsModalContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  bankAccountId: undefined,
  onClose: undefined,
  printCheques: () => {},
  onClickLinks: () => {},
};
