import React from 'react';
import PropTypes from 'prop-types';
import { Cross, TriangleExclamation } from 'web/components/icons';
import { LinkableText } from '@sb-itops/react';
import Style from './PageBannerBoostTrial.module.scss';

export const PageBannerBoostTrial = ({ message, showBanner, onClickLink, onClose, showCloseButton }) => {
  if (!showBanner) {
    return null;
  }

  return (
    <div className={Style.pageBanner}>
      <div className={Style.bannerContainer}>
        <TriangleExclamation fill="#484004" />
        <div className={Style.bannerText}>{message}</div>
        <LinkableText
          text="Upgrade now"
          onClickLink={() => onClickLink({ type: 'manageSubscription' })}
          asLink
          inline
        />
      </div>
      {showCloseButton && (
        <div className={Style.close} onClick={onClose}>
          <Cross />
        </div>
      )}
    </div>
  );
};

PageBannerBoostTrial.propTypes = {
  showCloseButton: PropTypes.bool,
  onClickLink: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  showBanner: PropTypes.bool,
};

PageBannerBoostTrial.defaultProps = {
  message: null,
  showBanner: null,
  showCloseButton: null,
};

PageBannerBoostTrial.displayName = 'PageBannerBoostTrial';
