import { fetchPostP } from '@sb-itops/redux/fetch';

/* const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountService',
  keyPath: 'id',
}); */

export const saveNumberingSettingsP = async (newNumberingSettings) => {
  // Apply to save optimistically.
  // const feeVersionToSave = updatedFeeVersion || newFeeVersion;
  // opdateCache({ optimisticEntities: [feeVersionToSave] });

  // Apply the save in the backend.
  const postData = {
    bankAccountId: newNumberingSettings.bankAccountId,
    numberingSettings: {
      electronicPayments: newNumberingSettings.electronicPayments,
      receipts: newNumberingSettings.receipt,
      transferOfFunds: newNumberingSettings.transferOfFunds,
      trustToOffice: newNumberingSettings.trustToOffice,
    },
  };

  const path = `/billing/bank-account/save-numbering-settings/:accountId/`;
  const fetchOptions = { body: JSON.stringify(postData) };
  await fetchPostP({ path, fetchOptions });
};
