angular.module('sb.billing.webapp').directive('sbUpgrade', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'controller': 'SbUpgradeController',
    'controllerAs': '$ctrl',
    'templateUrl': 'ng-components/upgrade/upgrade.html'
  };
});
