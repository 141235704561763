import { updateCache as updateRedux } from '@sb-product-registration/redux/registered-users';

angular.module('@sb-product-registration/services').service('sbRegisteredUsersService', function (sbGenericCacheService, sbEndpointType) {
  const endpoint = 'product-registration/firm';
  const subscriptions = 'notifier.ProductRegistrationNotifications.ProductRegistrationUpdated';

  sbGenericCacheService.setupCache({
    name: 'sbRegisteredUsersService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: endpoint},
      poll: 60,
      subscriptions
    },
    updateRedux,
  });
});
