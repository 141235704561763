import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import * as transactionNumberingTabFeature from 'web/redux/features/transaction-numbering-settings-form';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { autoNumberingPrefixes } from '@sb-billing/business-logic/bank-account/services';
import { getMap as getAutoNumberingPrefixes } from '@sb-billing/redux/auto-numbering-prefixes';
import { TransactionNumberingSettings } from '../../components/transaction-numbering-settings';

const { prefixType, isPreviouslyUsedPrefix } = autoNumberingPrefixes;

const SCOPE = 'cma-transaction-numbering';

const getPrefixesWithWarnings = (formState, components) => {
  const { formInitialised, fields } = formState;

  return formInitialised
    ? {
        receipt: isPreviouslyUsedPrefix({
          prefix: fields.receipt.prefix.value,
          type: prefixType.RECEIPT,
          components,
        }),
        electronicPayments: isPreviouslyUsedPrefix({
          prefix: fields.electronicPayments.prefix.value,
          type: prefixType.ELECTRONIC_PAYMENT,
          components,
        }),
      }
    : {
        receipt: false,
        electronicPayments: false,
      };
};

const mapStateToProps = (state) => {
  const { selectors } = withScopedFeature({ state, scope: SCOPE })(transactionNumberingTabFeature);

  const components = (getAutoNumberingPrefixes().CMA && getAutoNumberingPrefixes().CMA.components) || [];

  return {
    formState: selectors.getFormState(state),
    exampleNumbers: selectors.getExampleNumbers(state),
    prefixesWithWarnings: getPrefixesWithWarnings(selectors.getFormState(state), components),
    config: {
      transferOfFunds: false,
      electronicPayments: true,
      trustToOffice: false,
    },
    scope: SCOPE,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { operations, actions } = withScopedFeature({ scope: SCOPE })(transactionNumberingTabFeature);

  return {
    onSave: () => dispatch(operations.saveCMAForm()),
    onChange: (updatedFields) => dispatch(operations.updateCMAForm({ updatedFields })),
    onLoad: () => {
      dispatch(operations.initialiseCMAForm({}));
      const onUnload = () => dispatch(actions.clearForm());
      return onUnload;
    },
  };
};

export const CMATransactionNumberingTabContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(TransactionNumberingSettings)),
);

CMATransactionNumberingTabContainer.propTypes = {};

CMATransactionNumberingTabContainer.defaultProps = {};
