export const getSelectedInvoices = (state) => state.selectedInvoices;

export const getSelectedInvoicesMap = (state) => state.selectedInvoicesMap;

export const getExpandedMatters = (state) => state.expandedMatterIds;

export const getPayments = (state) => state.payments;

export const getAutoAllocatedMatters = (state) => state.autoAllocateMatterIds;

export const getFilters = (state) => state.filters;

export const getVisibleFilterGroups = (state) => state.visibleFilterGroups;

export const getSort = (state) => state.sort;

export const getShowFilters = (state) => state.showFilters;
