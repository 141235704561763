import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { formatStaffMemberEmailAddress } from '@sb-billing/business-logic/invoice-emailing';
import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { getInvoiceStatementEmailInterpolated } from './get-invoice-statement-email-interpolated';
import { invoiceStatementEmailFormSchema } from './InvoiceStatementEmailForm.yup';
import { InvoiceStatementEmailModal } from './InvoiceStatementEmailModal';

const log = getLogger('InvoiceStatementEmailModal.forms.container');

const hooks = () => ({
  useInvoiceStatementEmailModalForm: ({
    debtorId,
    debtorName,
    loggedInStaffMember,
    invoiceStatementEmailSettings,
    invoiceStatementId,
    invoiceStatementLoading,
    invoiceStatementNumber,
    merchantPaymentReference,
    scope,
    // Callbacks
    onModalClose,
  }) => {
    const { t } = useTranslation();
    const invoiceStatementEmailForm = useForm({ scope, schema: invoiceStatementEmailFormSchema });
    const { formInitialised, formFields, formSubmitting, formValid } = invoiceStatementEmailForm;

    /**
     * Email preview
     */
    const [isLoadingPreview, setIsPreviewLoading] = React.useState(true);
    const [isPreviewMode, setIsPreviewMode] = React.useState(true);
    const [previewSubject, setPreviewSubject] = React.useState('');
    const [previewMessage, setPreviewMessage] = React.useState('');

    const onPreviewToggled = () => {
      const switchingToPreviewMode = !isPreviewMode;

      if (switchingToPreviewMode) {
        setPreviewValues({ formValues: invoiceStatementEmailForm.formValues });
      }

      setIsPreviewMode(!isPreviewMode);
    };

    async function setPreviewValues({ formValues }) {
      const previewValues = await getInvoiceStatementEmailInterpolated({
        debtorName,
        subject: formValues.subject,
        message: formValues.message,
      });
      setPreviewSubject(previewValues.subject);
      setPreviewMessage(previewValues.message);
    }

    useEffect(
      () => () => invoiceStatementEmailForm.onClearForm(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    /**
     * Form initialisation
     */
    const initialiseForm = !invoiceStatementLoading && !formInitialised;

    const getDefaultFormValues = async () => {
      const { emailBody, emailSubject, sendCopyToUser } = invoiceStatementEmailSettings;

      /** "From" fields */
      const formattedStaffMemberEmailAddress = formatStaffMemberEmailAddress({ staffMember: loggedInStaffMember });
      const people = await fetchPeopleP(debtorId).catch(() => []);
      const to = formatContactsEmails(people);

      const defaultValues = {
        bccAddress: '',
        ccAddress: '',
        fromAddress: formattedStaffMemberEmailAddress,
        message: emailBody,
        sendCopyToMe: sendCopyToUser,
        staffAddress: formattedStaffMemberEmailAddress,
        subject: emailSubject,
        toAddress: to,
      };

      return defaultValues;
    };

    useEffect(() => {
      (async () => {
        if (initialiseForm) {
          const defaultValues = await getDefaultFormValues();
          invoiceStatementEmailForm.onInitialiseForm(defaultValues);
          await setPreviewValues({ formValues: defaultValues });
          setIsPreviewLoading(false);

          invoiceStatementEmailForm.onValidateForm();
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialiseForm]);

    /**
     * Form updates
     */
    const [generateEmailPreview, setGenerateEmailPreview] = React.useState(true);

    const onUpdateFormData = ({ fieldUpdates }) => {
      invoiceStatementEmailForm.onUpdateFields(fieldUpdates);
      invoiceStatementEmailForm.onValidateForm();
    };

    const onFieldValueUpdated = (field, newValue) => {
      const newFieldValue = {
        [field]: newValue,
      };

      onUpdateFormData({ fieldUpdates: newFieldValue });
    };

    /**
     * Form submission
     */
    const onFormSubmit = async () => {
      try {
        invoiceStatementEmailForm.onValidateForm();

        const isValid = await invoiceStatementEmailForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            try {
              const invoiceStatementEmailRequest = await convertToInvoiceStatementEmailRequest({
                debtorId,
                debtorName,
                invoiceStatementId,
                formValues: formData,
                interpolate: true,
              });

              const finalRequest = {
                ...invoiceStatementEmailRequest,
                message: undefined,
                to: invoiceStatementEmailRequest.toAddress,
                body: invoiceStatementEmailRequest.message,
                invoiceStatementNumber,
                merchantPaymentReference,
              };
              await dispatchCommand({
                type: 'Integration.SendInvoiceStatement',
                message: finalRequest,
              });
              messageDisplay.success(`The ${t('clientStatement').toLowerCase()} email has been sent`);
              onModalClose();
            } catch (err) {
              if (
                (err.data && err.data.message === emailMessages.notAllowedToSendEmailsServer) ||
                (err.payload &&
                  err.payload.body &&
                  err.payload.body.message === emailMessages.notAllowedToSendEmailsServer)
              ) {
                messageDisplay.error(emailMessages.notAllowedToSendEmailsDisplay);
              } else {
                messageDisplay.error(
                  `Failed to send ${t('clientStatement').toLowerCase()} email, please try again later`,
                );
              }
              log.error(err);
            }
          },
        });

        if (!isValid) {
          log.warn('Invoice statement email modal form validation failed');
          return;
        }

        onModalClose();
      } catch (error) {
        log.error('Failed to send invoice email', error);
      }
    };

    return {
      bccAddress: formFields.bccAddress,
      ccAddress: formFields.ccAddress,
      debtorId,
      formInitialised,
      formSubmitting,
      fromAddress: formFields.fromAddress,
      message: formFields.message,
      sendCopyToMe: formFields.sendCopyToMe,
      staffAddress: formFields.staffAddress,
      subject: formFields.subject,
      toAddress: formFields.toAddress,
      generateEmailPreview,
      isLoading: isLoadingPreview || invoiceStatementLoading,
      isPreviewMode,
      isSubmitDisabled: !formValid || formSubmitting,
      previewMessage,
      previewSubject,
      onFieldValueUpdated,
      onPreviewToggled,
      onSend: onFormSubmit,
      onSetGenerateEmailPreview: setGenerateEmailPreview,
      onUpdateFormData,
    };
  },
});

/**
 * Transforms the form data in preparation to send it via email
 *
 * @param {object} params
 * @param {string} params.debtorId
 * @param {object} params.formValues
 * @returns {object[]}
 */
export const convertToInvoiceStatementEmailRequest = async ({
  debtorId,
  debtorName,
  invoiceStatementId,
  formValues,
  interpolate = true,
}) => {
  if (!formValues || !debtorId) {
    return [];
  }

  let bcc = formValues.bccAddress;
  if (formValues.sendCopyToMe) {
    bcc = formValues.bccAddress ? `${formValues.bccAddress}, ${formValues.staffAddress}` : formValues.staffAddress;
  }

  const invoiceStatementEmailRequest = {
    debtorId,
    invoiceStatementId,
    toAddress: formValues.toAddress,
    replyToAddress: formValues.fromAddress,
    from: formValues.fromAddress,
    bcc,
    cc: formValues.ccAddress,
    subject: formValues.subject,
    message: formValues.message,
  };

  if (interpolate) {
    const { subject, message } = await getInvoiceStatementEmailInterpolated({
      debtorName,
      subject: invoiceStatementEmailRequest.subject,
      message: invoiceStatementEmailRequest.message,
    });
    invoiceStatementEmailRequest.subject = subject;
    invoiceStatementEmailRequest.message = message;
  }

  return invoiceStatementEmailRequest;
};

export const InvoiceStatementEmailModalFormsContainer = composeHooks(hooks)(InvoiceStatementEmailModal);

InvoiceStatementEmailModalFormsContainer.displayName = 'InvoiceStatementEmailModalFormsContainer';

InvoiceStatementEmailModalFormsContainer.propTypes = {
  debtorId: PropTypes.string,
  loggedInStaffMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  invoiceStatementEmailSettings: PropTypes.shape({
    emailBody: PropTypes.string.isRequired,
    emailSubject: PropTypes.string.isRequired,
    sendCopyToUser: PropTypes.bool.isRequired,
  }).isRequired,
  invoiceStatementId: PropTypes.string.isRequired,
  invoiceStatementLoading: PropTypes.bool.isRequired,
  invoiceStatementNumber: PropTypes.string,
  merchantPaymentReference: PropTypes.string,
  scope: PropTypes.string.isRequired,
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
};

InvoiceStatementEmailModalFormsContainer.defaultProps = {
  debtorId: undefined,
  merchantPaymentReference: undefined,
  invoiceStatementNumber: undefined,
};
