import PropTypes from 'prop-types';
import React from 'react';
import smokeballPayLogo from 'web/assets/smokeball-logo.svg';
import { SettingsInfoCard } from '@sb-billing/react/settings-info-card';
import { Button, buttonTypes } from '@sb-itops/react';
import Styles from './FeeWiseSettings.module.scss';
import { FeeWiseSettingsForm } from '../fee-wise-settings-form';
import { FeeWiseApplicationForm } from '../fee-wise-application-form';
import { FeeWiseGetStartedCard } from './FeeWiseGetStartedCard';

export const FeeWiseSettings = (props) => (
  <>
    {getApplicationPage({
      ...props,
    })}
  </>
);

const getApplicationPage = ({
  applicationStatus,
  feeWiseApplicationStatuses,
  showApplicationForm,
  setShowApplicationForm,
  applicationFormData,
  isSaving,
  onSaveSettings,
  isNonProdAppEnv,
  onDisconnect,
  onUploadSampleDocument,
  isDisconnecting,
  isUploading,
  getStartedContextText,
}) => {
  switch (applicationStatus) {
    case feeWiseApplicationStatuses.NO_APPLICATION_OR_ACCOUNT:
      return showApplicationForm
        ? applicationPageContainer(<FeeWiseApplicationForm applicationFormData={applicationFormData} />)
        : applicationPageContainer(
            <FeeWiseGetStartedCard
              onApplyClick={() => setShowApplicationForm(true)}
              showLoading={applicationFormData === undefined}
              contentText={getStartedContextText}
            />,
          );
    case feeWiseApplicationStatuses.APPLICATION_SUBMITTED:
      return applicationPageContainer(
        <SettingsInfoCard
          iconName="SUCCESS"
          heading="Thank you for your application"
          subheading="Processing time is usually one to two business days, however can take longer in some cases"
        />,
      );
    case feeWiseApplicationStatuses.APPLICATION_PROCESSING:
      return applicationPageContainer(
        <>
          <SettingsInfoCard
            iconName="INFO"
            heading="Your application is processing"
            subheading="Processing time is usually one to two business days, however can take longer in some cases"
          />
          {isNonProdAppEnv && (
            <div className={Styles.nonProdButtonContainer}>
              <fieldset>
                <legend>Non-prod actions</legend>
                <Button onClick={onUploadSampleDocument} locked={isUploading} disabled={isUploading || isDisconnecting}>
                  Upload sample ID document
                </Button>
                <Button
                  onClick={onDisconnect}
                  locked={isDisconnecting}
                  disabled={isUploading || isDisconnecting}
                  className={Styles.disconnectButton}
                  type={buttonTypes.secondary}
                >
                  Disconnect & Go Back to Application Form
                </Button>
              </fieldset>
            </div>
          )}
        </>,
      );
    case feeWiseApplicationStatuses.APPLICATION_SUCCESSFUL:
      return (
        <div className={Styles.feeWiseSettingsForm}>
          <FeeWiseSettingsForm onSaveSettings={onSaveSettings} isSaving={isSaving} />
        </div>
      );
    case feeWiseApplicationStatuses.APPLICATION_UNSUCCESSFUL:
      return applicationPageContainer(
        <SettingsInfoCard
          iconName="ERROR"
          heading="There was a problem with your application"
          subheading="We will reach out to you to discuss further"
        />,
      );
    case null:
      return null;
    default:
      throw new Error('Invalid Application Status');
  }
};

const applicationPageContainer = (content) => (
  <div className={Styles.feeWiseApplicationForm}>
    <div className={Styles.paymentsLogoContainer}>
      <img className={Styles.logo} src={smokeballPayLogo} alt="Smokeball payments" />
      <span className={Styles.logoText}>Payments Application</span>
    </div>
    {content}
  </div>
);

FeeWiseSettings.displayName = 'FeeWiseSettings';

FeeWiseSettings.propTypes = {
  applicationStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  feeWiseApplicationStatuses: PropTypes.object.isRequired,
  showApplicationForm: PropTypes.bool.isRequired,
  setShowApplicationForm: PropTypes.func.isRequired,
  applicationFormData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.oneOf([undefined])]),
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
  isNonProdAppEnv: PropTypes.bool,
  onDisconnect: PropTypes.func,
  onUploadSampleDocument: PropTypes.func,
  isDisconnecting: PropTypes.bool,
  isUploading: PropTypes.bool,
  getStartedContextText: PropTypes.array.isRequired,
};

FeeWiseSettings.defaultProps = {
  isNonProdAppEnv: false,
  onDisconnect: () => {},
  onUploadSampleDocument: () => {},
  isDisconnecting: false,
  isUploading: false,
  applicationStatus: null,
  applicationFormData: undefined,
};
