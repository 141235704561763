'use strict';

/**
 * Determines if a matter is statutory deposit matter for any trust bank account.
 * @param {Object} param
 * @param {string} param.matterId
 * @param {Array.<Object>} param.trustBankAccounts Array of Bank Account entities containing statutoryDepositMatterId
 * @param {boolean} param.supportsStatutoryDepositMatter Whether the region supports statutory deposit matter
 * @returns {boolean}
 */
function isStatutoryDepositMatter({ matterId, trustBankAccounts, supportsStatutoryDepositMatter }) {
  if (!matterId || !Array.isArray(trustBankAccounts) || !supportsStatutoryDepositMatter) {
    return false;
  }

  return trustBankAccounts.some((trustAccount) => trustAccount.statutoryDepositMatterId === matterId);
}

module.exports = {
  isStatutoryDepositMatter,
};
