import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'controlled-money-account-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbControlledMoneyAccountSettingsService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const getSettings = () => getList()[0];

// eslint-disable-next-line import/no-cycle
export { saveControlledMoneyAccountSettings } from './save-controlled-money-account-settings';
