/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spinner } from '@sb-itops/react';
import React from 'react';
import classNames from 'classnames';
import { Contact } from 'types';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './ClientInfoWidget.module.scss';

const widgetAddressDisplay = (residentialAddress) => {
  let display = '';
  ['addressLine0', 'addressLine1', 'addressLine2'].forEach((key) => {
    if (residentialAddress[key]) {
      display = display ? `${display}, ${residentialAddress[key]}` : residentialAddress[key];
    }
  });
  return display;
};

interface TClientInfoWidget {
  isEditing: boolean;
  parties: (Contact & { isDebtor?: boolean; isOtherside?: boolean; cell: string })[];
  loading: boolean;
  onChange: () => void;
  onRemove: () => void;
  settings: any;
  onClickLink: (input: { type: string; id: string }) => void;
}

export const ClientInfoWidget = ({
  isEditing,
  parties,
  loading,
  onChange,
  onRemove,
  settings,
  onClickLink,
}: TClientInfoWidget) => (
  <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
    <WidgetTitle isEditing={isEditing} onChange={onChange} onRemove={onRemove} settings={settings} />
    {loading ? (
      <div className={WidgetStyles.loading}>
        <Spinner small />
      </div>
    ) : (
      <div className={classNames(Styles.content, WidgetStyles.content)}>
        {parties.map((client) => (
          <div key={client.id} className={Styles.client}>
            <div className={Styles.name}>
              <div className={Styles.text}>{client.displayNameFirstLast}</div>
              {client.isDebtor && <div className={Styles.debtor}>(DEBTOR)</div>}
              {client.isOtherside && <div className={Styles.debtor}>(OTHER SIDE)</div>}
            </div>
            {(client.phone || client.phone2) && (
              <div className={Styles.phone}>
                <i className="icon icon-call-1" />
                <a href={`tel:${client.phone || client.phone2}`}>{client.phone || client.phone2}</a>
              </div>
            )}
            {client.cell && (
              <div className={Styles.phone}>
                <i className="icon icon-smartphone" />
                <a href={`tel:${client.cell}`}>{client.cell}</a>
              </div>
            )}
            {client.email && (
              <div className={Styles.email}>
                <i className="icon icon-mail-1" />
                <a href={`mailto:${client.email}`}>{client.email}</a>
              </div>
            )}
            {widgetAddressDisplay(client.residentialAddress || {}) && (
              <div className={Styles.address}>
                <i className="icon icon-marker-2" />
                <a onClick={() => onClickLink({ type: 'contact', id: client.id })}>
                  {widgetAddressDisplay(client.residentialAddress || {})}
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
);
