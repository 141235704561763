'use strict';
import { featureActive } from '@sb-itops/feature'

angular.module('sb.billing.webapp').controller('FormBuilderController', function ($state, $scope) {
  const that = this;

  that.close = function () {
    if ($scope.previousState && $scope.previousState !== '' && !$scope.previousState.match(/^settings/)) {
      if (!_.isEmpty($scope.previousParams)) {
        $state.go($scope.previousState, $scope.previousParams);
      }
      else {
        $state.go($scope.previousState);
      }
    }
    else {
      if (featureActive('NUCWEB-123')) {
        $state.go('home.billing.dashboard');
      } else {
        $state.go('home.billing.matters');
      }
    }
  };
});
