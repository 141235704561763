/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export const DepositFundsFormSchema = Yup.object().shape({
  effectiveDate: Yup.number().required(),
  amount: Yup.number().min(0.1).required(),
  comment: Yup.string(),
  matterId: Yup.string().required(),
  contactId: Yup.string().required(),
  bankAccountId: Yup.string().required(),
  source: Yup.string().required(),
});
