import * as React from 'react';

import { ExpandCollapseButton } from '@sb-billing/react/expand-collapse-button';

import Styles from './FeePopOutEditor.module.scss';

export const CollapsedFeePopOutEditor = ({ onExpandFeePopOutEditor }) => (
  <div className={Styles.collapsedFeePopOutEditor}>
    <div className={Styles.labelContainer} onClick={onExpandFeePopOutEditor}>
      <h1 className={Styles.label}>Time Entry Review</h1>
    </div>
    <div className={Styles.expandCollapseButtonContainer}>
      <ExpandCollapseButton open={false} onExpandCollapseClick={onExpandFeePopOutEditor} />
    </div>
  </div>
);
