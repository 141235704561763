import { FORGET_STATE_PARAMS, REMEMBER_STATE_PARAMS } from './types';

const INITIAL_STATE = {};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case REMEMBER_STATE_PARAMS: {
      if (action.payload.entityId) {
        return {
          ...state,
          [action.payload.parentPageName]: {
            ...state[action.payload.parentPageName],
            [action.payload.entityId]: action.payload.stateGoParams,
          },
        };
      }
      return {
        ...state,
        [action.payload.parentPageName]: action.payload.stateGoParams,
      };
    }
    case FORGET_STATE_PARAMS: {
      // If keyed to matter or contact
      if (action.payload.entityId) {
        const currentState = {
          ...state,
          [action.payload.parentPageName]: {
            ...state[action.payload.parentPageName],
          },
        };
        if (
          action.payload.parentPageName in currentState &&
          action.payload.entityId in currentState[action.payload.parentPageName]
        ) {
          delete currentState[action.payload.parentPageName][action.payload.entityId];
        }
        return currentState;
      }

      // If global page
      const currentState = {
        ...state,
      };
      if (action.payload.parentPageName in currentState) {
        delete currentState[action.payload.parentPageName];
      }
      return currentState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
