/* eslint-disable import/no-cycle */
import { components as ReactSelectComponents } from 'react-select';
import React from 'react';
import MenuListVirtualized from '../virtualized-menu-list/MenuListVirtualized';
import ActionList from '../action-list/ActionList';
import { menuListItemHeight } from '../helpers/select-helpers';

const MenuList =
  ({ optionLabelHeight = menuListItemHeight, defaultValue, valueGetter, listItemClassName, actionList }) =>
  (props) => {
    const selectedValue = props.getValue() ? props.getValue()[0] : undefined;

    if (props.children && !props.children.length) {
      const noOptions = <ReactSelectComponents.NoOptionsMessage {...props.children.props} />;
      if (actionList && actionList.length) {
        return (
          <ActionList actionList={actionList} noOptions>
            {noOptions}
          </ActionList>
        );
      }
      return noOptions;
    }

    const menuListComponent = (
      <MenuListVirtualized
        {...props}
        optionLabelHeight={optionLabelHeight}
        selectedValue={selectedValue}
        defaultValue={defaultValue}
        valueGetter={valueGetter}
        listItemClassName={listItemClassName}
        actionListLength={actionList?.length || 0}
      />
    );

    if (actionList && actionList.length > 0) {
      return <ActionList actionList={actionList}>{menuListComponent}</ActionList>;
    }

    return menuListComponent;
  };

export default MenuList;
