import React, { memo } from 'react';
import { Icon } from '@sb-itops/react/icon';
import PropTypes from 'prop-types';
import Styles from './PaymentPlanIndicator.module.scss';

const indicatorsColors = {
  // Auto charge plan status
  behind: '#dd4442',
  expired: '#dd4442',
  failed: '#dd4442',
  expiryWarning: '#feb566',
  onTrack: '#408be5',
  // Manual plan status
  Behind: '#dd4442',
  Ahead: '#408be5',
  'On Track': '#408be5',
};
const paymentMethodStatusIcons = {
  expired: 'icon-fat-exclamation-circle-filled',
  failed: 'icon-fat-exclamation-circle-filled',
  expiryWarning: 'warning', // FA icon
  onTrack: '',
};

const PaymentPlanIndicator = memo(
  ({
    displayIndicator,
    tooltipMessage,
    status,
    autoCharge,
    paymentMethodStatusDisplay,
    paymentMethodStatus,
    showWarnings,
    onClickLink,
  }) => {
    const indicatorColor = indicatorsColors[status];
    const paymentStatusIndicatorColor = indicatorsColors[paymentMethodStatus];
    const paymentMethodStatusIcon = paymentMethodStatusIcons[paymentMethodStatus];

    return (
      displayIndicator && (
        <>
          <Icon
            className={Styles.icon}
            type={autoCharge ? 'calplan' : 'cal-currency'}
            tooltip={tooltipMessage}
            color={indicatorColor}
            onClick={() => onClickLink()}
          />
          {showWarnings && paymentMethodStatusIcon && (
            <Icon
              isFA={paymentMethodStatusIcon === 'warning'}
              type={paymentMethodStatusIcon}
              tooltip={paymentMethodStatusDisplay}
              color={paymentStatusIndicatorColor}
              onClick={onClickLink}
            />
          )}
        </>
      )
    );
  },
);

PaymentPlanIndicator.displayName = 'PaymentPlanIndicator';

PaymentPlanIndicator.propTypes = {
  displayIndicator: PropTypes.bool.isRequired,
  tooltipMessage: PropTypes.string,
  paymentMethodStatusDisplay: PropTypes.string,
  status: PropTypes.string,
  paymentMethodStatus: PropTypes.oneOf(['behind', 'expired', 'failed', 'expiryWarning', 'onTrack', '']),
  showWarnings: PropTypes.bool,
  autoCharge: PropTypes.bool,
  onClickLink: PropTypes.func,
};

PaymentPlanIndicator.defaultProps = {
  tooltipMessage: 'This is associated with a payment plan',
  paymentMethodStatusDisplay: '',
  paymentMethodStatus: 'onTrack',
  status: 'onTrack',
  showWarnings: true,
  autoCharge: false,
  onClickLink: () => {},
};

export default PaymentPlanIndicator;
