import React from 'react';
import PropTypes from 'prop-types';
import { LinkFilterButton } from '@sb-itops/react/link-filter-button';
import { CheckboxLabelInline, CurrencyInput2, Translation } from '@sb-itops/react';
import RemindersRecommendedFilterDisplay from './RemindersRecommendedFilterDisplay';

class RemindersRecommendedFilter extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    remindedInDaysChecked: !!this.props.remindedInDays,
    remindedInDays: this.props.remindedInDays || 14,
    invoicedInDaysChecked: !!this.props.invoicedInDays,
    invoicedInDays: this.props.invoicedInDays || 7,
    hideMatterDebtorWithNoOverdueInvoices: this.props.hideMatterDebtorWithNoOverdueInvoices,
  };

  onFilterChanged = ({ value, field }) => {
    this.setState(
      (state) => ({
        ...state,
        [field]: value,
      }),
      () => {
        if (field === 'remindedInDaysChecked') {
          this.props.onFilterChanged({
            value: value === false ? undefined : this.state.remindedInDays,
            field: 'remindedInDays',
          });
        } else if (field === 'invoicedInDaysChecked') {
          this.props.onFilterChanged({
            value: value === false ? undefined : this.state.invoicedInDays,
            field: 'invoicedInDays',
          });
        } else if (
          // field === 'hideMatterDebtorWithNoOverdueInvoices' ||
          (field === 'remindedInDays' && this.state.remindedInDaysChecked === true) ||
          (field === 'invoicedInDays' && this.state.invoicedInDaysChecked === true)
        ) {
          this.props.onFilterChanged({ value, field });
        }
      },
    );
  };

  propagateChanges = (newValue) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...newValue,
      }),
      () => {
        this.props.onFilterChanged({
          value: this.state.remindedInDaysChecked ? this.state.remindedInDays : undefined,
          field: 'remindedInDays',
        });
        this.props.onFilterChanged({
          value: this.state.invoicedInDaysChecked ? this.state.invoicedInDays : undefined,
          field: 'invoicedInDays',
        });
        // While we are not showing this option in the UI, we should not allow its value to change
        // this.props.onFilterChanged({
        //   value: this.state.hideMatterDebtorWithNoOverdueInvoices,
        //   field: 'hideMatterDebtorWithNoOverdueInvoices',
        // });
      },
    );
  };

  onSelectAll = () =>
    this.propagateChanges({
      remindedInDaysChecked: true,
      invoicedInDaysChecked: true,
      // hideMatterDebtorWithNoOverdueInvoices: true,
    });

  onClear = () =>
    this.propagateChanges({
      remindedInDaysChecked: false,
      invoicedInDaysChecked: false,
      // While we are not showing this option in the UI, we should not allow its value to change
      // hideMatterDebtorWithNoOverdueInvoices: false,
    });

  // this is only going to be exposed to the parent component
  // eslint-disable-next-line react/no-unused-class-component-methods
  reset = () =>
    this.propagateChanges({
      remindedInDaysChecked: true,
      remindedInDays: 14,
      invoicedInDaysChecked: true,
      invoicedInDays: 7,
      // hideMatterDebtorWithNoOverdueInvoices: true,
    });

  render() {
    return (
      <ul className="side-menu-list reminders-filters">
        <RemindersRecommendedFilterDisplay
          {...this.props}
          {...this.state}
          onFilterChanged={this.onFilterChanged}
          onClear={this.onClear}
        />
        <span className="links-recommended">
          <LinkFilterButton onClick={this.onSelectAll}>Select All</LinkFilterButton>
          <LinkFilterButton onClick={this.onClear}>Clear All</LinkFilterButton>
        </span>
        <li className="no-hover">
          <Translation>{(t) => `At least ${t('currencySymbol')} `}</Translation>
          <CurrencyInput2
            className="overdue-amount-input inline-block"
            value={this.props.overdueAmount}
            hideDollar
            onChange={(e) => {
              this.props.onChangeOverdueAmount(Number(e.target.value));
            }}
          />{' '}
          overdue
        </li>
        <li className="no-hover send-reminders-only-checkbox">
          <CheckboxLabelInline
            checked={this.props.sentRemindersOnly}
            onChange={(newValue) => this.props.setFilter('sentRemindersOnly', newValue)}
          >
            Only show invoices that have been reminded before
          </CheckboxLabelInline>
        </li>
      </ul>
    );
  }
}

RemindersRecommendedFilter.propTypes = {
  sentRemindersOnly: PropTypes.bool,
  visible: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  remindedInDays: PropTypes.number,
  onChangeOverdueAmount: PropTypes.func.isRequired,
  overdueAmount: PropTypes.number,
  invoicedInDays: PropTypes.number,
  hideMatterDebtorWithNoOverdueInvoices: PropTypes.bool,
};

RemindersRecommendedFilter.defaultProps = {
  visible: true,
  remindedInDays: undefined,
  overdueAmount: undefined,
  sentRemindersOnly: undefined,
  invoicedInDays: undefined,
  hideMatterDebtorWithNoOverdueInvoices: true,
};

export default RemindersRecommendedFilter;
