import {
  getList,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/interest-settings';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('@sb-billing/services').service('sbInterestSettingsService', function(sbGenericEndpointService, sbGenericCacheService, sbEndpointType) {
  if (!hasFacet(facets.interest)) {
    return;
  }

  const that = this;
  const serviceName = 'sbInterestSettingsService';
  const endpointMultiple = 'billing/interest-settings';
  const subscriptions = 'notifier.InvoicingNotifications.MatterInterestSettingsUpdated';

  sbGenericCacheService.setupCache({
    name: serviceName,
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpointMultiple },
      poll: 60,
      subscriptions
    },
    updateRedux,
    clearRedux,
  });

  that.filterBy = filterBy;
  that.get = getList;
  that.getById = getById;
  that.saveP = saveP;

  function filterBy(filters) {
    let jsonFilters = typeof filters === 'string' ? JSON.parse(filters) : filters;
    return _.filter(that.get(), jsonFilters);
  }

  function saveP(data) {
    return sbGenericEndpointService.postPayloadP(endpointMultiple, undefined, data, 'POST', { changeset: { [serviceName]: [data] } });
  }
});
