import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

/**
 * @enum {number} prefixType
 * @property {number} NONE 0
 * @property {number} RECEIPT 1
 * @property {number} TRANSFER_OF_FUNDS 2
 * @property {number} ELECTRONIC_PAYMENT 3
 */
export const prefixType = Object.freeze({
  NONE: 0,
  RECEIPT: 1,
  TRANSFER_OF_FUNDS: 2,
  ELECTRONIC_PAYMENT: 3,
  TRUST_TO_OFFICE: 4,
});

const api = cacheFactory({
  domain,
  name: 'auto-numbering-prefixes',
  keyPath: 'bankAccountId',
  ngCacheName: 'sbAutoNumberingPrefixesService',
  syncType: syncTypes.ALL,
  immutable: false,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;
