import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'recentMatters',
  keyPath: 'userId',
  ngCacheName: 'sbRecentMatterService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getRecentMatters = () => getList()?.[0]?.matterIds;
export const getRecentMattersMap = () =>
  getRecentMatters().reduce((acc, matterId) => {
    acc[matterId] = true;
    return acc;
  }, {});
