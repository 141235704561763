import { gql } from '@apollo/client';

const query = gql`
  query MatterTypes {
    matterTypes {
      matterTypeId
      matterTypeIdWithoutLocation
      name
      categories
    }
  }
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const MatterTypes = {
  query,
  notificationIds,
};
