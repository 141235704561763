import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useState } from 'react';
import uuid from '@sb-itops/uuid';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'web/redux/features/dashboard';
import * as messageDisplay from '@sb-itops/message-display';
import { getFirmDetails } from '@sb-firm-management/redux/firm-management';
import { sendMetric } from 'web/services/metrics';
import { Dashboard, HEIGHT, WIDTH } from './Dashboard';

const hooks = () => ({
  useTempState: () => {
    const settings = useSelector(selectors.getSettings);
    const currentDashboard = useSelector(selectors.getCurrentDashboard);
    const dashboards = useSelector(selectors.getDashboards);

    const dispatch = useDispatch();

    const [showAddWidget, setShowAddWidget] = useState(false);
    const [isEditing, setEditState] = useState(false);

    return {
      firmName: getFirmDetails()?.firmName,
      isEditing,
      setShowAddWidget,
      currentDashboard,
      setCurrentDashboard: (value) => {
        dispatch(actions.setCurrentDashboard(value));
      },
      setEditState,
      onExit: () => {
        setEditState(false);
      },
      dashboards,
      setDashboards: (value) => {
        dispatch(actions.setDashboards(value));
      },
      showAddWidget,
      onAddWidget: (value) => {
        const id = uuid();
        const newWidget = {
          id,
          dashboard: currentDashboard,
          ...value,
        };
        const filteredComponents = Object.values(settings).filter(
          (component) => component.dashboard === currentDashboard,
        );
        const componentMap = filteredComponents.reduce((cm, component) => {
          for (let x = component.position.x; x < component.position.x + component.size.x; x += 1) {
            for (let y = component.position.y; y < component.position.y + component.size.y; y += 1) {
              if (!cm[x]) {
                // eslint-disable-next-line no-param-reassign
                cm[x] = {};
              }
              // eslint-disable-next-line no-param-reassign
              cm[x][y] = true;
            }
          }
          return cm;
        }, {});
        const checkComponentForOverlap = (position, size) => {
          for (let x = position.x; x < position.x + size.x; x += 1) {
            for (let y = position.y; y < position.y + size.y; y += 1) {
              if (componentMap[x] && componentMap[x][y]) {
                return false;
              }
              if (x > WIDTH - 1 || y > HEIGHT - 1) {
                return false;
              }
            }
          }
          return true;
        };

        for (let x = 0; x <= WIDTH + 1 - newWidget.size.x; x += 1) {
          for (let y = 0; y <= HEIGHT + 1 - newWidget.size.y; y += 1) {
            if (checkComponentForOverlap({ x, y }, newWidget.size)) {
              newWidget.position = { x, y };
              break;
            }
          }
          if (newWidget.position) {
            break;
          }
        }
        if (!newWidget.position) {
          messageDisplay.error('There is no space on your dashboard to add this widget!');
          return;
        }
        setShowAddWidget(false);
        sendMetric('AddWidget', { widgetType: newWidget.type });
        dispatch(
          actions.setSettings({
            ...settings,
            [id]: newWidget,
          }),
        );
      },
      onAddDashboard: () => {
        if (Object.keys(dashboards).length > 4) {
          messageDisplay.error('You have reached the maximum of number of dashboards.');
          return;
        }
        sendMetric('AddDashboard');
        const id = uuid();
        dispatch(
          actions.setDashboards({
            ...dashboards,
            [id]: {
              id,
              name: 'New Dashboard',
            },
          }),
        );
        dispatch(actions.setCurrentDashboard(id));
      },
      settings,
      setSettings: (value) => {
        dispatch(actions.setSettings(value));
      },
      onChange: (id) => (change) =>
        dispatch(actions.setSettings({ ...settings, [id]: { ...settings[id], ...change } })),
      onRemove: (id) => () => {
        const newSettings = { ...settings };
        sendMetric('RemoveWidget', { widgetType: newSettings[id].type });
        delete newSettings[id];
        dispatch(actions.setSettings(newSettings));
      },
    };
  },
});

export const DashboardContainer = composeHooks(hooks)(Dashboard);

DashboardContainer.displayName = 'DashboardContainer';

DashboardContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  onClickLink: PropTypes.func,
  matterId: PropTypes.string,
};

DashboardContainer.defaultProps = {
  onClickLink: () => {},
  matterId: '',
};
