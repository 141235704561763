'use strict';

const { paymentPlanEventTypes } = require('../entities/constants');

const isPaymentEventType = (type) =>
  type === paymentPlanEventTypes.INVOICE_PAYMENT ||
  type === paymentPlanEventTypes.INVOICE_PAYMENT_REVERSAL ||
  type === paymentPlanEventTypes.INVOICE_PAYMENT_FAILED;

module.exports = {
  isPaymentEventType,
};
