import { store } from '@sb-itops/redux';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { selectors as filterSelectors } from 'web/redux/route/home-billing-accounts-transactions';
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions/credit-note-ledger';

  $stateProvider
    .state('home.billing.accounts.transactions.credit-note-ledger', {
      url: '/credit-note-ledger',
      templateUrl: BASE_DIRECTORY + '/firm-credit-note-ledger.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      params: {
        startDate: null,
        endDate: null,
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'CREDIT-NOTE-LEDGER',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        })
      },
      onEnter: function() {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.credit-note-ledger', {}], parentPageName: PARENT_PAGE_NAME }));
      }, 
    });
});
