import { useRef, useEffect } from 'react';

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useComparePrevious = (value, compare = (previous, next) => previous === next) => {
  const prev = usePrevious(value);
  return compare(prev, value);
};
