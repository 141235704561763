'use strict';

const { isPaymentProviderEnabledForBankAccount: isLawpayEnabledForBankAccount } = require('./lawpay');
const { isPaymentProviderEnabledForBankAccount: isStripeEnabledForBankAccount } = require('./stripe');
const { isPaymentProviderEnabledForBankAccount: isEzyCollectEnabledForBankAccount } = require('./ezy-collect');
const { isPaymentProviderEnabledForBankAccount: isFeeWiseEnabledForBankAccount } = require('./fee-wise');
const { providers } = require('../entities/constants');

const paymentProviderEnabledFns = {
  [providers.LAWPAY]: isLawpayEnabledForBankAccount,
  [providers.STRIPE]: isStripeEnabledForBankAccount,
  [providers.EZY_COLLECT]: isEzyCollectEnabledForBankAccount,
  [providers.FEE_WISE]: isFeeWiseEnabledForBankAccount,
};

/**
 * isPaymentProviderEnabledForBankAccount
 * Returns true if the payment provider is enabled for the specified bank account, false otherwise.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings The current provider settings for the provider type being checked.
 * @param {object} params.providerType The provider type to check for payment provider availability
 * @param {string} params.bankAccountId The bank account id to check for payment provider availability
 * @param {string} params.bankAccountType The bank account type to check for payment provider availability
 * @param {object} [params.providerSpecificFields] Provider specific fields
 * @returns {boolean}
 */
const isPaymentProviderEnabledForBankAccount = ({
  formattedProviderSpecificSettings,
  providerType,
  bankAccountId,
  bankAccountType,
  providerSpecificFields,
}) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!bankAccountId) {
    throw new Error('bankAccountId must be provided');
  }

  if (!formattedProviderSpecificSettings) {
    return false;
  }

  const paymentProviderEnabledForBankAccountFn = paymentProviderEnabledFns[providerType];
  if (!paymentProviderEnabledForBankAccountFn) {
    throw new Error(`Cannot determine if payment provider is enabled for provider type ${providerType}`);
  }

  return paymentProviderEnabledForBankAccountFn({
    formattedProviderSpecificSettings,
    bankAccountId,
    bankAccountType,
    providerSpecificFields,
  });
};

module.exports = {
  isPaymentProviderEnabledForBankAccount,
};
