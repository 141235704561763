'use strict';

import {
  updateCache as updateRedux,
}  from '@sb-billing/redux/payment-provider-settings';

angular.module('@sb-billing/services').service('sbPaymentProviderSettingsService', function (sbEndpointType, sbGenericCacheService) {
  sbGenericCacheService.setupCache({
    name: 'sbPaymentProviderSettingsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'billing/payment-provider/settings/' },
      poll: 60,
      subscriptions: [
        'notifier.PaymentProviderSettingsUpdated',
      ],
    },
    updateRedux
  });
});
