import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getMap as getBankAccountBalances } from '@sb-billing/redux/bank-account-balances';
import { selectors as bankAccountSelectors } from '@sb-billing/redux/bank-account-balances.2';
import { getDefaultCreditAccount, getOperatingAccount } from '@sb-billing/redux/bank-account';
import { findDefaultTrustAccountForMatter } from 'web/redux/selectors/find-trust-account';
import { getSettings, getPreferredBankAccountTypes } from '@sb-billing/redux/bulk-finalize-settings';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import InvoicePaymentAllocationModal from './InvoicePaymentAllocationModal';
import { createTableRows } from './create-table-rows';

const { getMatterContactBalances } = bankAccountSelectors;

const mapStateToProps = (state, { matterId, multiPayments, t }) => {
  // default trust account for matter or legacy trust if multitrust disabled
  const defaultTrustAccount = findDefaultTrustAccountForMatter(matterId);
  const defaultTrustAccountId = defaultTrustAccount?.id;

  const trustBalances =
    matterId && defaultTrustAccountId
      ? getMatterContactBalances(getBankAccountBalances(), { matterId, bankAccountId: defaultTrustAccountId })
          .map((cb) => ({
            type: 'Trust',
            bankAccountId: defaultTrustAccountId,
            bankAccountName: getBankAccountName(defaultTrustAccount, t),
            ...cb,
          }))
          .sort((a, b) => a[balanceTypes.AVAILABLE] - b[balanceTypes.AVAILABLE])
      : [];

  const operatingBalances = matterId
    ? getMatterContactBalances(getBankAccountBalances(), {
        matterId,
        bankAccountId: getOperatingAccount().id,
      })
        .map((cb) => ({
          type: 'Operating',
          bankAccountId: getOperatingAccount().id,
          ...cb,
        }))
        .sort((a, b) => a.balance - b.balance)
    : [];
  const creditBalances = matterId
    ? getMatterContactBalances(getBankAccountBalances(), {
        matterId,
        bankAccountId: getDefaultCreditAccount()?.id,
      })
        .map((cb) => ({
          type: 'Credit',
          bankAccountId: getDefaultCreditAccount()?.id,
          ...cb,
        }))
        .sort((a, b) => a.balance - b.balance)
    : [];

  const trustAllocations = createTableRows(
    trustBalances,
    multiPayments.reduce((acc, mp) => {
      if (mp.source === 'Trust') {
        acc[mp.payorId] = mp;
      }
      return acc;
    }, {}),
  );
  const operatingAllocations = createTableRows(
    operatingBalances,
    multiPayments.reduce((acc, mp) => {
      if (mp.source === 'Operating') {
        acc[mp.payorId] = mp;
      }
      return acc;
    }, {}),
  );

  const creditAllocations = createTableRows(
    creditBalances,
    multiPayments.reduce((acc, mp) => {
      if (mp.source === 'Credit') {
        acc[mp.payorId] = mp;
      }
      return acc;
    }, {}),
  );

  const settings = getSettings();
  const preferredBankAccountTypes = getPreferredBankAccountTypes(settings);

  return {
    trustAllocations,
    operatingAllocations,
    creditAllocations,
    preferredBankAccountTypes,
  };
};

const InvoicePaymentAllocationModalContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps)(InvoicePaymentAllocationModal)),
);

InvoicePaymentAllocationModalContainer.displayName = 'InvoicePaymentAllocationModalContainer';

InvoicePaymentAllocationModalContainer.propTypes = {
  onContactLinkClick: PropTypes.func.isRequired,
  onChangeAllocations: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  // we need to pass the invoiceTotals object in here (and not collecting it via id + selector), as the invoiceTotals for a draft invoice
  // doesn't exist in the redux store doesnt exist until its been saved
  invoiceTotals: PropTypes.object,
  multiPayments: PropTypes.array,
  matterId: PropTypes.string,
};

InvoicePaymentAllocationModalContainer.defaultProps = {
  matterId: undefined,
  invoiceTotals: undefined,
  multiPayments: [],
};

export default InvoicePaymentAllocationModalContainer;
