'use strict';

import { hasFacet } from '@sb-itops/region-facets';
import { regionType } from '@sb-itops/region';

angular.module('sb.billing.webapp').directive('sbRegionFacet', function (ngIfDirective) {
  const ngIf = ngIfDirective[0];

  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority - 2,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    $$tlb: true,
    link: function (scope, element, attrs) {
      const initialNgIf = attrs.ngIf;
      attrs.ngIf = () => hasFacet(attrs.sbRegionFacet) && (initialNgIf ? scope.$eval(initialNgIf) : true);
      ngIf.link.apply(ngIf, arguments);
    }
  };
});

angular.module('sb.billing.webapp').directive('sbAuRegion', function (sbEnvironmentConfigService) {
  return {
    link: function link(scope, element, attr) {
      if (sbEnvironmentConfigService.getRegion() !== regionType.AU) {
        if (attr.remove === 'true') {
          element.remove();
        } else {
          element.hide();
        }
      }
    }
  };
});

angular.module('sb.billing.webapp').directive('sbUsRegion', function(sbEnvironmentConfigService) {
  return {
    link: function link(scope, element, attr) {
      if (sbEnvironmentConfigService.getRegion() !== regionType.US) {
        if (attr.remove === 'true') {
          element.remove();
        } else {
          element.hide();
        }
      }
    }
  };
});

angular.module('sb.billing.webapp').directive('sbGbRegion', function(sbEnvironmentConfigService) {
  return {
    link: function link(scope, element, attr) {
      if (sbEnvironmentConfigService.getRegion() !== regionType.GB) {
        if (attr.remove === 'true') {
          element.remove();
        } else {
          element.hide();
        }
      }
    }
  };
});

angular.module('sb.billing.webapp').directive('sbRegionIf', function (sbEnvironmentConfigService, ngIfDirective) {
  const ngIf = ngIfDirective[0];

  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority - 2,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    $$tlb: true,
    link: function (scope, element, attrs) {
      const initialNgIf = attrs.ngIf;
      attrs.ngIf = () => sbEnvironmentConfigService.getRegion().toUpperCase() === attrs.sbRegionIf.toUpperCase() && (initialNgIf ? scope.$eval(initialNgIf) : true);
      ngIf.link.apply(ngIf, arguments);
    }
  };
});