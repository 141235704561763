import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { withOnUpdate } from '@sb-itops/react/hoc';
import { services } from '@sb-billing/business-logic/report';
import * as messageDisplay from '@sb-itops/message-display';
import { fetchGetP } from '@sb-itops/redux/fetch';
import ReportRunnerSSRS from './ReportRunnerSSRS';

const { makeSsrsReportUrl } = services;

const defaultReportState = {
  ssrsUrl: undefined,
  urlGenerationInProgress: false,
  awaitingSsrsContent: false,
};

const mapStateToProps = (state, { reportConfig, reportState: currentReportState }) => {
  const reportState = currentReportState || defaultReportState;
  return {
    reportId: reportConfig.reportId,
    ssrsUrl: reportState.ssrsUrl,
    showLoadingSpinner: reportState.urlGenerationInProgress || reportState.awaitingSsrsContent,
  };
};

const fetchFirmTimeZone = async () => {
  const path = `/firm-management/firm/get-time-zone/:accountId/`;
  const response = await fetchGetP({ path });
  const { windowsTimeZone } = response.body;
  return windowsTimeZone;
};

const generateSsrsUrl =
  ({ reportConfig, authToken, setReportState }) =>
  async (dispatch) => {
    dispatch(setReportState({ urlGenerationInProgress: true }));
    try {
      const windowsTimeZone = await fetchFirmTimeZone();
      const ssrsUrl = makeSsrsReportUrl({ reportConfig, authToken, windowsTimeZone });
      dispatch(setReportState({ ssrsUrl, urlGenerationInProgress: false, awaitingSsrsContent: true }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      messageDisplay.error('Failed to generate report');
    }
  };

const mapDispatchToProps = (dispatch, { reportConfig, authToken, setReportState }) => ({
  onUpdate: () => {
    // Auto-generate the report on load.
    dispatch(generateSsrsUrl({ reportConfig, authToken, setReportState }));

    // Clean-up state on unload.
    const onUnload = () => {
      dispatch(setReportState(defaultReportState));
    };

    return onUnload;
  },
  onSsrsContentReady: () => dispatch(setReportState({ awaitingSsrsContent: false })),
});

export const ReportRunnerSSRSContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnUpdate(['reportConfig'])(ReportRunnerSSRS)),
);

ReportRunnerSSRSContainer.displayName = 'ReportRunnerSSRSContainer';

ReportRunnerSSRSContainer.propTypes = {
  reportConfig: PropTypes.object.isRequired,
};

ReportRunnerSSRSContainer.defaultProps = {};

export default ReportRunnerSSRSContainer;
