import { featureActive } from '@sb-itops/feature';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { split as splitDescription, interpolate as interpolateDescription } from '@sb-billing/interpolate-description';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import {
  getById as getPaymentById,
  getPaymentsByMultiPaymentId
} from '@sb-billing/redux/payments';
import { getById as getTrustChequeById } from '@sb-billing/redux/trust-cheques';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { isClosedMatter } from '@sb-matter-management/business-logic/matters/services';

angular.module('sb.billing.webapp').component('sbPaymentViewEntry', {
  bindings: { features: '<?', preFill: '<?', onClose: '&?' },
  templateUrl: 'ng-components/payment-entry/view/payment-view-entry.html',
  controller: function (sbUuidService, sbDateService, sbBankAccountService, sbInvoicingCacheManager, sbFirmManagementMbService, sbLoggerService,
    sbTransactionService, sbPaymentService, sbMessageDisplayService, focusService, sbLocalisationService, sbEnvironmentConfigService, sbLinkService) {
    'use strict';

    
    const ctrl = this;
    const log = sbLoggerService.getLogger('SbPaymentViewEntryController');

    // config
    ctrl.allowOverdraw = hasFacet(facets.allowOverdraw);
    ctrl.showChequeMemo = hasFacet(facets.chequeMemo);
    ctrl.canDeletePayment = hasFacet(facets.deleteTransaction);

    ctrl.ctrlId = sbUuidService.get();
    ctrl.toggleReversalUI = toggleReversalUI;
    ctrl.processReversal = processReversal;
    ctrl.validate = validate;
    ctrl.isPaymentReversible = isPaymentReversible;
    ctrl.t = sbLocalisationService.t;
    ctrl.todayDate = new Date();
    ctrl.getCheckMemo = getCheckMemo;
    ctrl.isMatterContactBalanceType = isMatterContactBalanceType;
    ctrl.isShowPaymentReason = isShowPaymentReason;
    ctrl.isTransactionReversibleForMatter = isTransactionReversibleForMatter;

    ctrl.onClickLink = (...args) => {
      sbLinkService.onClickLink(...args);
      ctrl.onClose({ dismiss: true });
    }

    ctrl.$onInit = () => {
      ctrl.processing = false;
      ctrl.model = {};
      ctrl.view = {
        isReverseTransactionCollapsed: true,
        isMatterContactBalanceType: isMatterContactBalanceType(),
      };
      ctrl.errors = {};

      if (ctrl.preFill) {
        loadPrefill();
      }

      ctrl.view.isPaymentFromCheque  = !!ctrl.payment.chequeId;
    };

    function getCheckMemo() {
      if(ctrl.view.isPaymentFromCheque){
        const chequeId = ctrl.payment.chequeId;
        const trustCheque = getTrustChequeById(chequeId);
        return trustCheque && trustCheque.chequeMemo;
      }
      return undefined;
    }

    function loadPrefill() {
      let payment = ctrl.preFill;
      if (_.isObject(ctrl.preFill)) {
        // make copy to avoid overwriting preFill data
        payment = JSON.parse(JSON.stringify(ctrl.preFill));
      }

      if (_.isObject(payment)) {
        if (payment.sourceAccountId) {
          payment.sourceAccount = sbBankAccountService.get(payment.sourceAccountId);
        }

        if (payment.destinationAccountId) {
          const destAccount = sbBankAccountService.get(payment.destinationAccountId);
          payment.destAccountType = (sbLocalisationService.t(destAccount.accountType.toLowerCase())).toUpperCase();
        }

        const people = _.first(sbFirmManagementMbService.getByUserIds([payment.userId]));
        payment.userName = (people && people.name) || '';

        if (payment.invoices) {
          const invoiceCache = sbInvoicingCacheManager.getById(payment.invoices[0].invoiceId);
          payment.invoices[0].invoiceNumber = invoiceCache.currentVersion.invoiceNumber;
        }
        payment.effectiveDateDisplay = sbLocalisationService.t('date', {yyyymmdd: payment.effectiveDate});
        payment.source = sbPaymentService.decodePaymentSource(payment);
      }
      payment.reason = getReason(payment);

      ctrl.payment = payment;
      ctrl.total = ctrl.payment.amount;
      ctrl.title = getTitle();
      ctrl.showPaidBy = ctrl.payment.isTrustToOffice ? false : (ctrl.view.isMatterContactBalanceType || !ctrl.payment.sourceAccountId);

      if (ctrl.payment.isTrustToOffice) {
        // Payments
        // 1. Get all related payments by multiPaymentId
        // 2. Alternatively, get the related payment entities from the data-ledger Trust to Office object
        // 3. Fallback: Invoice payment list modal only receives the selected invoice in the payment object,
        //    to get all invoices on the payment we need to retrieve the whole payment entity
        const payments = ctrl.payment.multiPaymentId
          ? getPaymentsByMultiPaymentId(ctrl.payment.multiPaymentId)
          : (ctrl.payment.payments && ctrl.payment.payments.map(p => p.payment)) || [getPaymentById(ctrl.payment.paymentId)];

        // Add all payments to ctrl.payment.payments for later TTO reversal checking
        ctrl.payment.payments = payments;

        // Prepare data for the payment invoices table
        ctrl.view.paymentIds = payments.map(({ paymentId }) => paymentId);
      }

      if (!ctrl.view.paymentIds) {
        ctrl.view.paymentIds = [ctrl.payment.paymentId];
      }
    }

    function getReason(payment) {
      if (payment.reason && payment.reason.includes('#invoiceId')) {
        return interpolateDescription(splitDescription(payment.reason), (type, id) => `#${getInvoiceNumberById(id)}`);
      }
      return payment.reason;
    }

    function getTitle() {
      if ((!isLedgerState() && !isInvoiceStatementState()) || (ctrl.payment.invoices && ctrl.payment.invoices.length === 1)) {
        return `Invoice #${ctrl.payment.invoices[0].invoiceNumber}`;
      }

      return `Invoices ${(ctrl.payment.allInvoices || ctrl.payment.invoices).map(i => `#${i.invoiceNumber}`).join(', ')}`;
    }

    function isLedgerState() {
      const parts = ctrl.features.state.split('.');

      return parts[parts.length - 1] === 'ledger';
    }

    function isInvoiceStatementState() {
      const parts = ctrl.features.state.split('.');

      return parts[parts.length - 2] === 'view-invoice-statement';
    }

    function isShowPaymentReason() {
      const sourceBankAccountType = (ctrl.payment.sourceAccount && ctrl.payment.sourceAccount.accountType) || '';
      const isTrustPayment = sourceBankAccountType.toUpperCase() === 'TRUST';

      const isShowPaymentReason = (hasFacet(facets.reasonField) && featureActive('BB-5508')) || (hasFacet(facets.trustPaymentReasonField) && isTrustPayment);

      return isShowPaymentReason;
    }

    function isPaymentReversible() {
      const sourceBankAccountType = (ctrl.payment.sourceAccount && ctrl.payment.sourceAccount.accountType) || '';
      // reversal transaction will have effective date of TODAY to check that is valid
      let reversible = (sourceBankAccountType.toUpperCase() === 'TRUST') ?
        !isReconciled({ yyyymmdd: moment().format('YYYYMMDD'), trustAccountId: ctrl.payment.sourceAccountId }) : true;

      const paymentTransactions = getPaymentTransactions();
      reversible = reversible && _.every(paymentTransactions, txn => _.isEmpty(txn.reconciliationId));

      // For Trust to Office we combine payments that are linked via multiPaymentId and are created/reversed/deleted
      // together, here we check all the payments to make sure they are all still reversible
      if (ctrl.payment.payments) {
        return reversible && ctrl.payment.payments.every(payment => sbPaymentService.isReversiblePayment(payment.id || payment.paymentId));
      }

      return reversible && sbPaymentService.isReversiblePayment(ctrl.payment.paymentId);
    }

    function getPaymentTransactions() {
      return ctrl.payment.isTrustToOffice && ctrl.payment.payments
        ? ctrl.payment.payments
            .map(({ id, paymentId }) => sbTransactionService.getTransactionsByPaymentId(id || paymentId))
            .flat()
        : sbTransactionService.getTransactionsByPaymentId(ctrl.payment.paymentId);
    }

    function isTransactionReversibleForMatter() {
      const transactions = getPaymentTransactions();

      return transactions.every((transaction) => {
        const isTrust = transaction.bankAccountType && transaction.bankAccountType.toUpperCase() === bankAccountTypeEnum.TRUST;
        const matterId = transaction.matterId;
        // trust transactions in AU/GB are not reversible if matter is closed
        return isTrust && matterId && hasFacet(facets.blockTrustTransactionsOnClosedMatter)
          ? !isClosedMatter(getMatterById(matterId))
          : true;
      });
    }

    function isPaymentFromTrustOrOperatingAccount() {
      return ctrl.payment && ctrl.payment.sourceAccount && ctrl.payment.sourceAccount.accountType &&
        (ctrl.payment.sourceAccount.accountType.toUpperCase() === 'TRUST' ||
          ctrl.payment.sourceAccount.accountType.toUpperCase() === 'OPERATING');
    }

    function toggleReversalUI(opts) {
      ctrl.view.isReverseTransactionCollapsed = !ctrl.view.isReverseTransactionCollapsed;

      ctrl.model.deleteTx = !!(opts && opts.deleteTx);
      ctrl.view.showReverseWarning = 
      hasFacet(facets.transactionReverseWarning) && !(opts && opts.deleteTx) && isPaymentFromTrustOrOperatingAccount();

      if (!ctrl.view.isReverseTransactionCollapsed) {
        focusService.focusOn('reason-field');
      }
    }

    async function processReversal() {
      // The preFill.payments array is provided for Trust to Office payments. We can take any paymentId as
      // the payments are linked via multiPaymentId and will get reversed/deleted together in the back-end
      const paymentId = ctrl.preFill.paymentId || ((ctrl.preFill.payments && ctrl.preFill.payments[0].id) || (ctrl.preFill.payments && ctrl.preFill.payments[0].paymentId));
      const paymentTransactions = sbTransactionService.getTransactionsByPaymentId(paymentId);

      let tx = _.first(paymentTransactions);
      if (paymentTransactions.length > 1) {
        tx = paymentTransactions.find(transaction => (transaction.bankAccountType && transaction.bankAccountType.toUpperCase() === 'TRUST'));
      }

      if (!validate()) {
        return;
      }

      try {
        ctrl.processing = true;
        log.info(`process invoice payment reversal: payment ${paymentId} transaction ${tx && tx.id}, reason of ${ctrl.model.reason}`);
        await sbPaymentService.reverseInvoicePaymentP({
          paymentId,
          transactionId: tx && tx.id,
          reason: ctrl.model.reason,
          deleteTransaction: ctrl.model.deleteTx,
          allowOverdraw: ctrl.allowOverdraw,
        });
        ctrl.onClose({ dismiss: true });
        sbMessageDisplayService.success(
          sbMessageDisplayService
            .builder()
            .text(`Invoice payment ${ctrl.model.deleteTx ? 'deleted' : 'reversed'}`)
        );
      } catch (err) {
        ctrl.onClose({ dismiss: true });
        log.error('Problem processing reversal', err);

        // Poor workaround for multiple return conditions from 1 function
        switch (err.code) {
          case sbPaymentService.ERROR_CODE.insufficientFunds:
          case sbPaymentService.ERROR_CODE.paymentReversed:
          case sbPaymentService.ERROR_CODE.transactionNotFound:
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .title('Reversal not processsed')
                .text(`Failed to process reversal`)
            );
            break;

          default:
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .title('Reversal not processsed')
                .text('Failed to process reversal. Please check your connection and try again.')
            );
        }
      }
    }

    function validate() {
      ctrl.errors.reason = _.isEmpty(_.trim(ctrl.model.reason));

      const formIsValid = Object.keys(ctrl.errors).every(key => !ctrl.errors[key]);
      log.info('valid?', formIsValid);
      return formIsValid;
    }
  }
});
