import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner, Button } from '@sb-itops/react';
import { CashBookPaymentsFilter } from 'web/react-redux/components/report/cash-book-payments-filter';
import { ReceiptsCashBookFilter } from 'web/react-redux/components/report/receipts-cash-book-filter';
import { JournalTransfersFilter } from 'web/react-redux/components/report/journal-transfers-filter';
import { AccountStatementFilter } from 'web/react-redux/components/report/account-statement-filter';
import { TrustStatementFilter } from 'web/react-redux/components/report/trust-statement-filter';
import { AccountBalancesFilter } from 'web/react-redux/components/report/account-balances-filter';
import { AgingSummaryFilter } from 'web/react-redux/components/report/aging-summary-filter';
import { AuditLogsFilter } from 'web/react-redux/components/report/audit-logs-filter';
import { IncomeByAttorneyFilter } from 'web/react-redux/components/report/income-by-attorney-filter';
import { OverdrawTrustAccountFilter } from 'web/react-redux/components/report/overdraw-trust-account-filter';
import { TrialBalanceFilter } from 'web/react-redux/components/report/trial-balance-filter';
import { TrustReceiptsFilter } from 'web/react-redux/components/report/trust-receipts-filter';
import { AccountsReceivableFilter } from 'web/react-redux/components/report/accounts-receivable-filter';
import { InvoiceHistoryFilter } from 'web/react-redux/components/report/invoice-history-filter';
import { LedgerExportFilter } from 'web/react-redux/components/report/ledger-export-filter';
import { MatterBalancesFilter } from 'web/react-redux/components/report/matter-balances-filter';
import { TimeFeesExpensesFilter } from 'web/react-redux/components/report/time-fees-expenses-filter';
import { CreditHistoryFilter } from 'web/react-redux/components/report/credit-history-filter';
import { CmaListingFilter } from 'web/react-redux/components/report/cma-listing-filter';

import Styles from './ReportRunnerSmokeballBilling.module.scss';

const ReportRunnerSmokeballBilling = React.memo(
  ({
    reportConfig,
    filters,
    showLoadingSpinner,
    cachedHtml,
    onFiltersChanged,
    onGenerateReport,
    onDownloadCsv,
    onDownloadPdf,
    onEmailReport,
  }) => {
    const printIframe = () => {
      const id = 'report';
      const iframe = document.frames ? document.frames[id] : document.getElementById(id);

      if (iframe) {
        const iframeWindow = iframe.contentWindow || iframe;
        iframe.focus();
        iframeWindow.print();
      }

      return false;
    };

    const hasErrors = filters && Object.values(filters.errors || {}).some((e) => e);
    return (
      <div className={Styles.columnFlex}>
        {getFilter({ reportConfig, filters, onFiltersChanged })}
        {filters && (
          <div className="ribbon panel panel-primary">
            {/* always show buttons when not allowing email option */}
            {filters && !reportConfig.allowEmailReport && (
              <>
                <Button onClick={onGenerateReport} size="default" disabled={hasErrors}>
                  Generate
                </Button>
                <Button onClick={onDownloadCsv} size="default" disabled={!cachedHtml}>
                  CSV
                </Button>
                <Button onClick={onDownloadPdf} size="default" disabled={!cachedHtml}>
                  PDF
                </Button>
                <Button onClick={printIframe} size="default" disabled={!cachedHtml}>
                  Print
                </Button>
              </>
            )}
            {/* only show buttons when allowing email option and all matters not selected */}
            {filters && reportConfig && reportConfig.allowEmailReport && filters.showInvoicesFor !== 'allMatters' && (
              <>
                <Button onClick={onGenerateReport} size="default" disabled={hasErrors}>
                  Generate
                </Button>
                <Button onClick={onDownloadCsv} size="default" disabled={!cachedHtml}>
                  CSV
                </Button>
                <Button onClick={onDownloadPdf} size="default" disabled={!cachedHtml}>
                  PDF
                </Button>
                <Button onClick={printIframe} size="default" disabled={!cachedHtml}>
                  Print
                </Button>
              </>
            )}
            {/* only show email option when all matters selected */}
            {filters && filters.showInvoicesFor === 'allMatters' && reportConfig && reportConfig.allowEmailReport && (
              <>
                <Button
                  onClick={onEmailReport}
                  size="default"
                  disabled={showLoadingSpinner || !filters.sendTo || filters.errors.email}
                  title={reportConfig.emailbuttonTooltip}
                >
                  Email Report
                </Button>
                {!filters.sendTo && <div>Current user has no email address</div>}
                {filters.sendTo && filters.errors.email && (
                  <div>Current user has an invalid email address ({filters.sendTo})</div>
                )}
              </>
            )}
          </div>
        )}
        {/* <pre>{JSON.stringify(filters, null, 2)}</pre> */}
        <div className={Styles.columnFlex}>
          {showLoadingSpinner && <Spinner className={Styles.loadingIndicator} />}
          {cachedHtml && (
            <iframe
              id="report"
              title="smokeball-report-viewer"
              className={classnames(Styles.columnFlex, showLoadingSpinner ? Styles.hidden : '')}
              srcDoc={cachedHtml}
            />
          )}
        </div>
      </div>
    );
  },
);

const getFilter = ({ reportConfig, filters, onFiltersChanged }) => {
  switch (reportConfig.reportId) {
    case 'cash-book-payments':
      return <CashBookPaymentsFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'receipts-cash-book':
      return <ReceiptsCashBookFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'journal-transfers':
      return <JournalTransfersFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'account-balances':
      return <AccountBalancesFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'aging-summary':
      return <AgingSummaryFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'audit-logs':
      return <AuditLogsFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'income-by-attorney':
      return <IncomeByAttorneyFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'overdraw-trust-account':
      return <OverdrawTrustAccountFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'trial-balance':
      return <TrialBalanceFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'trust-receipts':
      return <TrustReceiptsFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'account-statement':
      return <AccountStatementFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'trust-statement':
      return <TrustStatementFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'accounts-receivable':
      return <AccountsReceivableFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'invoice-history':
      return <InvoiceHistoryFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'ledger-export':
      return <LedgerExportFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'matter-balances':
      return <MatterBalancesFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'time-fees-expenses':
      return <TimeFeesExpensesFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'credit-history':
      return <CreditHistoryFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    case 'cma-listing':
      return <CmaListingFilter filters={filters} onFiltersChanged={onFiltersChanged} />;
    default:
      return <div sytle="display: flex; flex: 1;">{/* <h3>TO DO</h3> */}</div>;
  }
};

ReportRunnerSmokeballBilling.displayName = 'ReportRunnerSmokeballBilling';

ReportRunnerSmokeballBilling.propTypes = {
  onFiltersChanged: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
  onDownloadPdf: PropTypes.func.isRequired,
  onEmailReport: PropTypes.func.isRequired,
  reportConfig: PropTypes.object.isRequired,
  filters: PropTypes.object,
  cachedHtml: PropTypes.string,
  showLoadingSpinner: PropTypes.bool,
};

ReportRunnerSmokeballBilling.defaultProps = {
  filters: undefined,
  cachedHtml: undefined,
  showLoadingSpinner: false,
};

export default ReportRunnerSmokeballBilling;
