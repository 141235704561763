'use strict';

/**
 * @typedef { import('../types').UtbmsTaskCode } Task
 * @typedef { import('../types').TaskFilter } TaskFilter
 */

const { constants } = require('../entities');

const { taskCategoryLabels } = constants;

/**
 * Given a flat array of Task objects, this function will return an object keyed by task type
 * labels with array values containing all of the tasks of the corresponding task type.
 *
 * NOTE: The expected Task structure is the GraphQL Task structure. Passing legacy cache
 * task entities will lead to unexpected behaviour.
 *
 * @param {Object} params
 * @param {Array<Task>} params.tasks
 * @param {TaskFilter} [params.filter]
 * @returns {Object<string, Array<Task>>}
 */
const groupTasksByCategoryLabel = ({ tasks, filter = {} }) => {
  if (!Array.isArray(tasks)) {
    throw new Error(`The passed 'tasks' must be an array`);
  }

  const taskCategoriesToInclude = new Set(filter.types || []);

  return tasks.reduce((acc, task) => {
    if (!task || !task.category) {
      throw new Error('Cannot group malformed task objects');
    }

    // Apply filters.
    if (taskCategoriesToInclude.size !== 0 && !taskCategoriesToInclude.has(task.category)) {
      return acc;
    }

    // Lookup the label and group accordingly.
    const taskCategoryLabel = taskCategoryLabels[task.category];
    if (!taskCategoryLabel) {
      throw new Error(`Cannot group unsupported task type '${task.category}'`);
    }

    if (!acc[taskCategoryLabel]) {
      acc[taskCategoryLabel] = [];
    }

    acc[taskCategoryLabel].push(task);
    return acc;
  }, {});
};

module.exports = {
  groupTasksByCategoryLabel,
};
