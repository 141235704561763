import { today, startOfThisMonth, endOfThisMonth, dateToInteger as toEffectiveDate } from '@sb-itops/date';
import {
  TOGGLE_SHOW_FILTERS,
  SET_FILTER,
  RESET_FILTER,
  TOGGLE_HIDE_FILTER,
  SET_EXPANDED_CLIENTS,
  SET_SELECTED_MATTERS,
  getLocalisedEntryTypeFilterOptions,
  matterStatusFilterOptions,
} from './types';

const INITIAL_FILTER_STATE = {
  showFilters: true,
  filters: {
    groupByClient: false,
    allMatters: true,
    sentMinValue: 0,
    minValue: 100000,
    dateListFilter: {
      selectedFilter: 'ALL',
      startDate: 0,
      endDate: 99991231,
      before: toEffectiveDate(today()),
      from: toEffectiveDate(startOfThisMonth()),
      to: toEffectiveDate(endOfThisMonth()),
    },
    selectedAttorneys: [],
    matterFilterStatusSelected: matterStatusFilterOptions.map(({ value }) => value),
    billingTypesSelected: undefined,
    entryTypesSelected: getLocalisedEntryTypeFilterOptions().map(({ value }) => value),
    billingFrequencySubTypesSelected: [],
    matterTypes: [],
    minTrustRetainerSelected: undefined,
    minOperatingRetainerSelected: undefined,
    mattersData: { count: 0, selected: {} },
    matterTypesDisplayed: undefined,
  },
  hideFilters: {},
  selectedMatters: {},
  expandedClients: {},
};

export const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case RESET_FILTER: {
      const newState = { ...INITIAL_FILTER_STATE };
      newState.filters.matterTypesDisplayed = state.filters.matterTypesDisplayed;
      newState.filters.mattersData = state.filters.mattersData;
      return newState;
    }
    case SET_FILTER: {
      const result = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
      result.filters.sentMinValue = result.filters.allMatters ? 0 : result.filters.minValue;
      return result;
    }
    case TOGGLE_HIDE_FILTER: {
      return {
        ...state,
        hideFilters: {
          ...state.hideFilters,
          [action.payload.filterName]: !state.hideFilters[action.payload.filterName],
        },
      };
    }
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case SET_SELECTED_MATTERS: {
      return {
        ...state,
        selectedMatters: action.payload,
      };
    }
    case SET_EXPANDED_CLIENTS: {
      return {
        ...state,
        expandedClients: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
