import React from 'react';
import classnames from 'classnames';
import { featureActive, featureData } from '@sb-itops/feature';

import Styles from './BillingNav.module.scss';

export const BillingNav = (props: {
  onGotoOrReset: (path: string) => void;
  navHighlights?: { [key: string]: boolean };
  trustAccounts: any[];
  commUnreads: number | string;
  buildVersion: string;
  serverVersion: string;
  hasBillingAccess: boolean;
  showGlobalAccounts: boolean;
  showGlobalReports: boolean;
  isTriConveyBranded: boolean;
}) => {
  const {
    onGotoOrReset,
    navHighlights,
    trustAccounts,
    commUnreads,
    buildVersion,
    serverVersion,
    hasBillingAccess,
    showGlobalAccounts,
    showGlobalReports,
    isTriConveyBranded,
  } = props;

  const activeStyle = (page) => (navHighlights?.[page] ? Styles.active : undefined);

  return (
    <div className={Styles.billingNav}>
      <ul className={Styles.navigation}>
        {featureActive('NUCWEB-123') && (
          <li className={classnames('icon-house-2', activeStyle('dashboard'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.dashboard')}>
              Dashboard
            </button>
          </li>
        )}

        <li className={classnames('icon-matter', activeStyle('matters'))}>
          <button type="button" onClick={() => onGotoOrReset('home.billing.matters')}>
            Matters
          </button>
        </li>

        <li className={classnames('icon-profile-1', activeStyle('contacts'))}>
          <button type="button" onClick={() => onGotoOrReset('home.billing.contacts.list')}>
            Contacts
          </button>
        </li>

        {featureActive('NUCWEB-82') && (
          <li className={classnames('icon-calendar-2', activeStyle('calendar'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.productivity.calendar')}>
              Calendar
            </button>
          </li>
        )}

        {featureActive('NUCWEB-179') && (
          <li className={classnames('icon-tasks', activeStyle('tasks'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.productivity.tasks')}>
              Tasks
            </button>
          </li>
        )}

        {hasBillingAccess && (
          <li className={classnames('icon-clock-2', activeStyle('entries'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.entries.time')}>
              Time/Fees
            </button>
          </li>
        )}

        {hasBillingAccess && (
          <li className={classnames('icon-fill-invoice', activeStyle('bills'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.bills.list')}>
              Invoices
            </button>
          </li>
        )}

        {hasBillingAccess && showGlobalAccounts && (
          <li className={classnames('icon-bank-2', activeStyle('accounts'))}>
            <button
              type="button"
              onClick={() =>
                onGotoOrReset(`home.billing.accounts.transactions.${trustAccounts.length ? 'trust' : 'operating'}`)
              }
            >
              Accounts
            </button>
          </li>
        )}

        {hasBillingAccess && showGlobalReports && (
          <li className={classnames('icon-business-chart-1', activeStyle('external-reports'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.external-reports')}>
              Reports
            </button>
          </li>
        )}

        {featureActive('NUCWEB-98') && (
          <li
            className={classnames('communicate-badge', 'icon-chat-bubble-1', activeStyle('communicate'))}
            data-count={commUnreads}
          >
            <button type="button" onClick={() => onGotoOrReset('home.billing.communicate')}>
              Messages
            </button>
          </li>
        )}
        {featureActive('NUCWEB-657') && featureData('NUCWEB-657') && (
          <li className={classnames('icon-icon-apphub-i', activeStyle('marketplace'))}>
            <button type="button" onClick={() => onGotoOrReset('home.billing.marketplace')}>
              Marketplace
            </button>
          </li>
        )}

        <li className={Styles.logoContainer}>
          {!isTriConveyBranded && (
            <div
              className={classnames('icon-smokeball', Styles.brandLogo)}
              title={`Smokeball Web: ${buildVersion} API: ${serverVersion}`}
            />
          )}
          {isTriConveyBranded && (
            <div
              className={Styles.triConveyLogoContainer}
              title={`triConvey Web: ${buildVersion} API: ${serverVersion}`}
            >
              <div className="tri-convey-logo" title={`triConvey Web: ${buildVersion} API: ${serverVersion}`} />
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};
