import { getAllConsolidatedTrustTransactions } from '@sb-billing/redux/transactions';
import { localiseDescription } from '@sb-billing/transaction-descriptions';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getTransactionReceiptById } from '@sb-billing/redux/transaction-receipts';

angular.module('sb.billing.webapp').component('sbDataTransactionsReactHandler',{
  require: { composer: '^sbCompose' },
  bindings: { showHidden: '<', composeKey: '@', matterId: '<?', contactId: '<?', trustAccountId: '<?' },
  controller: function ($scope, sbTrustChequeService, sbMattersMbService, sbContactsMbService, sbTransactionReceiptService, sbLocalisationService, sbUnsavedChangesService, sbInterpolatorService) {
    const ctrl = this;
    const entityDataKey = ctrl.composeKey || 'transactions';

    const listeners = [
      $scope.$on('smokeball-data-update-sbTransactionService', updateTransactions),
      $scope.$on('smokeball-data-update-sbMattersMbService', updateTransactions),
      $scope.$on('smokeball-data-update-sbContactsMbService', updateTransactions),
      $scope.$on('smokeball-data-update-sbTransactionReceiptService', updateTransactions),
    ];
    const react = {
      ...init(),
      txns: [],
    };

    ctrl.$onChanges = () => {
      updateTransactions();
    };

    ctrl.$onDestroy = () => {
      sbUnsavedChangesService.saveMemory('sbDataTransactionsReactHandler', {
        sortBy: react.sortBy,
        sortDirection: react.sortDirection,
      });

      listeners.forEach(unregisterListener => unregisterListener());
    };

    function init () {
      const {
        sortBy = 'effectiveDate',
        sortDirection = 'desc',
      } = sbUnsavedChangesService.loadMemory('sbDataTransactionsReactHandler') || {};

      return {
        sortBy,
        sortDirection,
      };
    }

    function addTxnBalances (txns) {
      let balance = 0;
      return _.sortByOrder(txns, ['effectiveDate', 'timestamp', 'id'], ['asc', 'asc', 'asc'])
        .map((txn) => {
          balance += txn.amount;
          return {
            ...txn,
            balance,
          };
        });
    }

    function updateTransactions() {
      // trust to office transfer/bulk trust payments is the first type of consolidated
      // transaction for trust accounts, all trust transaction list must now take this
      // into considerations.
      const consolidatedTrustTransactions = getAllConsolidatedTrustTransactions({
        showHidden: ctrl.showHidden,
        matterId: ctrl.matterId,
        contactId: ctrl.contactId,
        trustAccountId: ctrl.trustAccountId,
        t: sbLocalisationService.t,
      })

      const txns = consolidatedTrustTransactions
        .map((txn) => {
          // The next two variables are used for sorting on the txn reference which may have leading zeros and/or a leading prefix letter.
          // If the reference converts to integer, it has no leading letter, otherwise, use the first character of the reference as prefix.
          const referencePrefix = Number.isInteger(Number.parseInt(txn.reference)) ? '' : (txn.reference && txn.reference[0]) || '';
          // If the reference converts to integer, use it as reference number, otherwise convert to integer after removing prefix.
          const referenceNumber = Number.isInteger(Number.parseInt(txn.reference)) ? Number.parseInt(txn.reference) || '' : (txn.reference && Number.parseInt(txn.reference.slice(1))) || '';
          const isVendorPayment = txn.paymentId && txn.type === 'VendorPayment';
          const isMatterTransfer = txn.type === 'Transfer';
          const isTrustToOfficeTransfer = ((txn.paymentId && txn.type === 'InvoicePayment') || (txn.isTrustToOffice && txn.type !== 'InvoicePaymentReversal' && !txn.reversed)) && txn.amount <= 0;

          return {
            ...txn,
            matter: sbMattersMbService.getById(txn.matterId),
            matterDisplay: getMatterDisplay(txn),
            contact: sbContactsMbService.getById(txn.contactId),
            debit: txn.amount < 0 ? txn.amount : 0,
            credit: txn.amount > 0 ? txn.amount : 0,
            description: sbInterpolatorService.interpolate(
              localiseDescription(sbLocalisationService.t, txn.description)
              + (
                txn.reason
                ? (' - ' + txn.reason)
                : ''
              )
            ),
            hasReceipt: !!getTransactionReceiptById(txn.id),
            // trust cheques can be created under 3 scenarios
            // 1) vendor payment
            // 2) invoice payment 
            // 3) trust to office transfer - multiple invoice payments & their associated transactions
            //    collated into a single pseudo transaction for display purpose on the transaction list
            // All these transactions, including the pseudo transaction have an associated chequeId
            cheque: txn.chequeId ? sbTrustChequeService.getById(txn.chequeId) : undefined,
            referencePrefix,
            referenceNumber,
            isVendorPayment,
            isMatterTransfer,
            isTrustToOfficeTransfer,
            isBulkDeposit: txn.isBulkDeposit,
            lowercaseReferencePrefix: referencePrefix && referencePrefix.toLowerCase(),
          };
        });

      react.txns = _.sortByOrder(addTxnBalances(txns), [react.sortBy, 'timestamp', 'id'], [react.sortDirection, react.sortDirection, react.sortDirection]);

      const summary = summarise(react.txns);
      ctrl.composer.setComposeData(react.txns, entityDataKey);
      ctrl.composer.setComposeData(react.sortBy, 'sortBy');
      ctrl.composer.setComposeData(react.sortDirection, 'sortDirection');
      ctrl.composer.setComposeData(sort, 'sort');
      ctrl.composer.setComposeData(summary, 'summary');
    }

    function summarise (sortedList) {
      const summary = {
        balance: 0,
        credit: 0,
        debit: 0,
      };

      return sortedList.reduce((total, txn) => {
        return {
          balance: total.balance + txn.amount,
          credit: total.credit + txn.credit,
          debit: total.debit + Math.abs(txn.debit),
        };
      }, summary);
    }

    function sort ({sortDirection, sortBy}) {
      react.sortDirection = sortDirection.toLowerCase();
      react.sortBy = sortBy;

      if (sortBy === 'reference') {
        react.txns = _.sortByOrder(react.txns, ['lowercaseReferencePrefix', 'referenceNumber', 'timestamp', 'id'], [react.sortDirection, react.sortDirection, react.sortDirection, react.sortDirection]);
      } else {
        react.txns = _.sortByOrder(react.txns, [react.sortBy, 'timestamp', 'id'], [react.sortDirection, react.sortDirection, react.sortDirection]);
      }

      ctrl.composer.setComposeData(react.txns, entityDataKey);
      ctrl.composer.setComposeData(react.sortBy, 'sortBy');
      ctrl.composer.setComposeData(react.sortDirection, 'sortDirection');
    }

    function getMatterDisplay(txn) {
      if (txn.isBulkDeposit) {
        return `${txn.matterIds.length} matter(s)`;
      }

      return txn.matterIds && txn.matterIds.map(matterId => getMatterDisplayById(matterId)).join(' | ');

    }
  }
});
