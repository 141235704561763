/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* eslint-disable react/no-unused-state */
class Checkbox extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    onChange: () => {},
    checked: false,
    disabled: false,
    className: '',
    style: undefined,
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    checked: false,
  };

  static getDerivedStateFromProps(props = {}) {
    return props;
  }

  onChange = (e) => {
    const { onChange, disabled } = this.props;
    if (disabled) {
      e.stopPropagation();
      return;
    }
    const { checked } = e.target;

    this.setState(
      () => ({
        checked,
      }),
      () => {
        onChange(checked);
      },
    );

    e.stopPropagation();
  };

  render() {
    const { checked, disabled, className, style } = this.props;

    return (
      <input
        className={classNames('row-checkbox', className)}
        style={style}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={this.onChange}
        onClick={onClick}
      />
    );
  }
}

function onClick(e) {
  e.stopPropagation();
}
/* eslint-enable react/no-unused-state */

Checkbox.displayName = 'Checkbox';

export default Checkbox;
