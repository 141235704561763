/**
 * Used on a paremt element (form or div) will add key press event handlers to all form inputs
 * to trap ENTER key events to make enter behave like tab.
 *
 * Can also provide optional form submit handler function. If supplied CTRL+ENTER will submit form.
 */
angular.module('sb.billing.webapp').directive('sbEnterTab', function ($timeout, $parse) {
  'use strict';

  return {
    restrict: 'E',
    transclude: true,
    template: '<div class="enter-tab" ng-transclude></div>',
    link: function (scope, element, attrs) {

      //Passing in a watch expression allows the directive to
      //update its key bindings if required
      if(!_.isEmpty(attrs.watchExp)){
        scope.$watch(attrs.watchExp, function() {
          $timeout(update); //allow time for DOM to render
        });
      }

      update();

      function addEnterKeypressHandler(el, prev) {
        const elem = angular.element(el);
        const elprev = angular.element(prev);
        elem.on('keypress', function (e) {
          if (e.which === 13) {
            elprev.focus().select();
            e.preventDefault();
          }
        });
      }

      function addCtrlEnterKeypressHandler(el, submitHandler) {
        const elem = angular.element(el);
        elem.on('keypress', function (e) {
          if (e.which === 10) {
            submitHandler();
            e.preventDefault();
          }
        });
      }

      function update(){
        const inputs = element.find(':input');
        let submitHandler;

        if(attrs.formSubmitHandler){
          submitHandler = $parse(attrs.formSubmitHandler)(scope);
        }

        if (_.isEmpty(inputs)) {
          return;
        }

        const first = _.first(inputs);
        let prev;

        //NB process in REVERSE order
        _.eachRight(inputs, (i) => {
          prev = prev || first; // last input focus on the first
          addEnterKeypressHandler(i, prev);
          if(_.isFunction(submitHandler)){
            addCtrlEnterKeypressHandler(i, submitHandler);
          }
          prev = i;
        });
      }

    }
  };
});
