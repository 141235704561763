export const themes = Object.freeze({
  v3: 'v3',
});

let currentTheme;

// setTheme should only be called from the bootstrapper to avoid unexpected
// theme changes for the user when the feature switch changes
export const setTheme = (newTheme) => {
  // Reset theme
  if (newTheme === undefined) {
    document.body.classList.remove(`theme-${currentTheme}`);
    currentTheme = newTheme;
    return currentTheme;
  }

  // Set new theme
  if (themes[newTheme]) {
    document.body.classList.add(`theme-${newTheme}`);
    currentTheme = newTheme;
  }

  return currentTheme;
}

export const getTheme = () => {
  return currentTheme;
}

// Helper
export const isNewTheme = () => {
  return currentTheme === themes.v3;
}
