import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as expandCollapseFeature from './index';

export const useExpandCollapse = ({ scope }) => {
  const dispatch = useDispatch();
  const { selectors, actions } = useScopedFeature(expandCollapseFeature, scope);

  const collapsedItemsMap = useSelector(selectors.getCollapsedItemsMap);

  const toggleItem = (itemToToggle) => {
    dispatch(actions.toggleItem({ id: itemToToggle, expandByDefault: false }));
  };

  return {
    collapsedItemsMap,
    toggleItem,
  };
};
