import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LinkableText } from '../linkable-text';

const MessageList = React.memo(({ messages, onClickDismiss }) => {
  if (!messages || messages.length === 0) {
    return null;
  }

  return (
    <div className="sb-header-message-list">
      <ul className="list-group sb-message-list">
        {messages.map((message) => {
          const className = classnames('list-group-item', message.level);

          return (
            <li className={className} key={`message-list_${message.id}`}>
              <div className="close-button" onClick={() => onClickDismiss(message.id)}>
                <span className="fa fa-times" />
              </div>
              <div className="level-icon">{getIcon(message.level)}</div>
              <h4>{message.title}</h4>
              <p>{message.msgText}</p>
              {message.action && (
                <LinkableText className="action" text={message.actionText} onClickLink={message.action} asLink />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
});

function getIcon(level) {
  switch (level) {
    case 'success': {
      return <span className="fa fa-check" />;
    }
    case 'info': {
      return <span className="fa fa-info" />;
    }
    case 'warn': {
      return <span className="fa fa-warning" />;
    }
    case 'error': {
      return <span className="fa fa-exclamation-circle" />;
    }
    default: {
      return null;
    }
  }
}

MessageList.propTypes = {
  messages: PropTypes.array,
  onClickDismiss: PropTypes.func.isRequired,
};

MessageList.defaultProps = {
  messages: [],
};

MessageList.displayName = 'MessageList';

export default MessageList;
