'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  $stateProvider
    .state('data-migration', {
      url: '/data-migration',
      templateUrl: 'ng-routes/data-migration/container.html',
      controller: 'DataMigrationController',
      controllerAs: 'dataMigration',
      data: {
        authorized: true
      },
    });
});
