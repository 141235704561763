'use strict';

module.exports = {
  marshal,
};

/**
 * IF YOU ARE GOING TO CHANGE THESE REGEX, you may need to change them in `./marshal.js`.
 * The regexes have purposely been kept separate as they do *slightly* different things.
 * 
 * If you change the regex, you need to make sure that the tests still pass.
 */

/**
 * Identifies a "normal email" - `displayName13@smokeball.com`.
 * 
 * Used to verify that a given string is a "normal email".
 */
const plainEmailPattern = /^[\w-.]+@[\w-]+(?:\.[\w-]+){1,3}$/;

/**
 * Used to identify an email with display name - which must be in the form `"Display Name" <email>`.
 * `email` may refer to any number of ';|,' separated emails.
 * 
 * EX (valid): "Hunter Steele & Max, esq" <email1@smokeball.com;email2@smokeball.com;>
 *
 * EX (invalid): Hunter Steele & Max, esq <email1@smokeball.com;email2@smokeball.com;>
 */
const emailDisplayEmails = /"([^"]+)"\s*<([^<>]+)>/;

/**
 * Identifies (and captures) either of the aforementioned accepted emails.
 * 
 * The regex is similar to the previous, either with the groups removed or with non-capturing groups.
 * 
 * Note: this regex requires that each email has a trailing `;|,` which is added to the string pre-split.
 * This was done due to the complexity of having an inner separator WRT regexes
 */
const emailGroupsPattern = /("[^"]+"\s*<[^<>]+>|[\w-.]+@[\w-]+(?:\.[\w-]+){1,3})(?:\s*[;,]\s*)/;

/**
 * Used to split the email strings over (one the display name parts have been parsed). The regex checks for a character that is either a `;` or a `,`.
 **/ 
const separatorPattern = /[;,]/;

/**
 * @typedef MarshallOptions
 * @property {boolean} showDisplayName Whether to include the display name in
 * the output or strip it out.
 */

/**
 * Takes a string representing one or more emails and returns that string in a form that works for `.NET` and other services.
 * The email string is assumed to be valid (in that calling a `validate.js` with it would return `true`).
 * 
 * This function exists so we can take an arbitary string of emails (or a lazily built string of emails) and conver it into a form that is consistent.
 * This is partly due to the lack of any validation that the SB desktop app does on the email addresses at point of data entry.
 * 
 * Pass `showDisplayName` as `false` if you want to ensure that the emails will work with an external service (such as Lawpay or AWS SES).
 *
 * @param {string} email A string representing one or more `;|,` separated emails
 * @param {MarshallOptions} options Options for the return format.
 *
 * @return {undefined|string} A comma-separated list of the email addresses in a
 * form that .NET and other services can accept.  Or `undefined` if no email
 * addresses were provided.
 */
function marshal (email, {showDisplayName = true} = {}) {
  if (!email) {
    return;
  }

  if (showDisplayName) {
    return marshalWithDisplayName(email)
  }

  return marshalWithoutDisplayName(email);
}

/**
 * Takes a string of one or more emails and converts it to a string of plain emails _only_.
 * 
 * @param {string} email A string representing one or more `;|,` separated emails
 */
function marshalWithDisplayName (email) {
  // split the email string into groups of display name emails & plain emails
  return withEndingSeparator(email)
    .split(emailGroupsPattern)
    .map((str) => str && str.trim())
    .filter((str) => str)
    .map((emailBlock) => {
      // if the email is a single plain email, we dont need to change anything
      if (plainEmailPattern.test(emailBlock)) {
        return emailBlock;
      }

      // separate the display name from the emails & trim the strings of leading/trailing whitespace
      const [displayName, ...emails] = emailBlock
        .split(emailDisplayEmails)
        .map((str) => str && str.trim())
        .filter((str) => str);

      return emails.map((emails) => {
        // emails will be a `separatorPattern` separated string of plain emails
        // remove any of the whitespace (.NET can't handle it) and recreate into a display name 
        return emails.split(separatorPattern)
            .map((str) => str && str.trim())
            .filter((str) => str)
            .map((email) => `"${displayName}" <${email}>`)
            .join(', ');
      });
    })
    .join(', ');
}

/**
 * Takes a string of one or more emails and converts it to a string of only plain emails.
 * 
 * @param {string} email A string representing one or more `;|,` separated emails
 */
function marshalWithoutDisplayName (email) {
  // split the email string into groups of display name emails & plain emails
  return withEndingSeparator(email)
    .split(emailGroupsPattern)
    .map((str) => str && str.trim())
    .filter((str) => str)
    .map((emailBlock) => {
      // if the email is a single plain email, we dont need to change anything
      if (plainEmailPattern.test(emailBlock)) {
        return emailBlock;
      }

      // separate the display name from the emails & trim the strings of leading/trailing whitespace
      const [UNUSED, ...emails] = emailBlock.split(emailDisplayEmails) //eslint-disable-line no-unused-vars
        .map((str) => str && str.trim())
        .filter((str) => str);

      // build up the single email string by joining the individual emails from between the `<` and `>` of the displayname email
      return emails.map((emails) => {
        // emails will be a `separatorPattern` separated string of plain emails
        // remove any of the whitespace (.NET can't handle it) and combine the emails into a single string
        return emails
          .split(separatorPattern)
          .map((str) => str && str.trim())
          .filter((str) => str)
          .join(', ');
        });
    })
    .join(', ');
}

/**
 * Adds a `;` if the supplied string does not end with a `;|,`
 * 
 * @param {string} email  A string representing one or more `;|,` separated emails
 */
function withEndingSeparator (email) {
  return !email.match(/;$/) ? `${email};` : email;
}
