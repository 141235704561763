'use strict';

const { simpleFetch } = require('@sb-itops/fetch-wrapper-node');
const { regionType } = require('@sb-itops/region');

/**
 * get access authorisation url
 * @param {object} params
 * @param {'AU'|'US'|'GB'} params.region
 * @param {'LOCAL'|'DEV'|'RC'|'QA'|'LIVE'} params.appEnv
 * @param {'dev'|'staging'} [params.dataEnv] Only required in local env
 */
function getAccessAuthorisationUrl({ region, appEnv, dataEnv }) {
  if (!appEnv) {
    throw new Error('appEnv must be provided');
  }

  const authorisationPath = '/itops/authorization/domain/isauthorized';

  // Non-local environments have access to the private DNS meaning that we can
  // use http://alb.int.smokeball.com in all non-local environments and the URL
  // will resolve to the relevant alb. (This works for all deployed environments)
  if (appEnv !== 'LOCAL') {
    return `http://alb.int.smokeball.com${authorisationPath}`;
  }

  if (!dataEnv) {
    throw new Error('dataEnv must be provided');
  }

  // LOCAL environment may be using the dev or staging data environments and will use the url format
  // http://alb.<environment>.<region>.dn.smokeball.com/itops/authorization/domain/isauthorized
  // environment: dev, staging
  // region: aus, nova, uk
  const awsRegionMap = {
    [regionType.AU]: 'aus',
    [regionType.US]: 'nova',
    [regionType.GB]: 'lon',
  };

  const awsRegion = awsRegionMap[region];
  const authorisationUrl = `http://alb.${dataEnv.toLowerCase()}.${awsRegion}.dn.smokeball.com${authorisationPath}`;

  return authorisationUrl;
}

/**
 * fetch authorisations for given Smokeball resourceIds
 * @param {object} params
 * @param {string} params.accountId
 * @param {string} params.userId
 * @param {string[]} params.resourceIds
 * @param {'AU'|'US'|'GB'} params.region
 * @param {'LOCAL'|'DEV'|'RC'|'QA'|'LIVE'} params.appEnv
 * @param {'dev'|'staging'|'prod'} params.dataEnv
 * @returns {Promise<Permission[]>} array of auth response for each resourceId
 */
async function fetchAccessAuthorisations({ accountId, userId, resourceIds, region, appEnv, dataEnv }) {
  const accessAuthorisationUrl = getAccessAuthorisationUrl({ region, appEnv, dataEnv });
  const requests = resourceIds.map((resourceId) => ({ accountId, userId, resourceId }));
  const response = await simpleFetch({
    url: accessAuthorisationUrl,
    throwOnFailure: true,
    method: 'POST',
    body: { requests },
  });

  const accessAuthorisations = (response && response.body && response.body.responses) || [];
  return accessAuthorisations;
}

module.exports = {
  fetchAccessAuthorisations,
};
