const fetch = require('node-fetch');
const fetchWrapper = require('@sb-itops/fetch-wrapper');

/**
 * @typedef { import('@sb-itops/fetch-wrapper/types').FetchResponse } FetchResponse
 */

const { responseTypes } = fetchWrapper.constants;

/**
 * simpleFetch
 * 
 * Thin ease of use wrapper around a native fetch function.
 * 
 * @param {Object} params
 * @param {string} params.url The URL to direct the HTTP request toward.
 * @param {string} params.method The HTTP method, e.g. GET, POST etc.
 * @param {Object|undefined} [params.body] The body data to send in the HTTP request.
 * @param {Object} [params.headers] The headers to send in the HTTP request.
 * @param {string} [params.responseType] The type of response expected. See responseTypes in constants.js
 * @param {boolean} [params.throwOnFailure] Throw an error instead of returning FetchResponse if request fails.
 * @param {boolean} [params.skipStringify] Allows overiding auto body stringifying for json content types. Defaults to false.
 * @returns {Promise<FetchResponse>}
 */
const simpleFetch = async ({ url, method, headers = {}, body, responseType = responseTypes.json, throwOnFailure = false, skipStringify = false }) =>
  fetchWrapper.simpleFetch({ fetch, url, method, headers, body, responseType, throwOnFailure, skipStringify });

module.exports = {
  ...fetchWrapper,
  simpleFetch,
};

