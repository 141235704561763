'use strict';

const { bankAccountEntityTypeEnum } = require('../entities/constants');

/**
 * Get bank accounts (entity) by accountType
 *
 * This is for when the bank accounts have an accountType value of a number
 *  - NB: This can be the case when we retrieve bank accounts from the DB
 *
 * @param {Object} params
 * @param {Object} params.bankAccounts
 * @param {string} params.accountType bankAccountTypeEnum e.g. 'OPERATING' or 'TRUST'
 * @returns {Object[]} Bank accounts of the requested account type
 */
function getBankAccountsEntityByType({ bankAccounts, accountType }) {
  if (!bankAccounts || !accountType || typeof accountType !== 'string') {
    return [];
  }

  const accountTypeUpperCase = accountType.toUpperCase();
  const accountsList = Array.isArray(bankAccounts) ? bankAccounts : Object.values(bankAccounts);

  const selectedAccounts = accountsList.filter(
    (account) => !account.isDeleted && account.accountType === bankAccountEntityTypeEnum[accountTypeUpperCase],
  );

  return selectedAccounts;
}

module.exports = {
  getBankAccountsEntityByType,
};
