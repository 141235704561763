import PropTypes from 'prop-types';

const Forms2Value = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool,
  PropTypes.number,
  PropTypes.instanceOf(Date),
]);

const Forms2Field = PropTypes.shape({
  key: PropTypes.string,
  value: Forms2Value,
  originalValue: Forms2Value,
  isClean: PropTypes.bool,
  isDirty: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
});

const forms2PropTypes = {
  Forms2Field,
};

export { forms2PropTypes };
