/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Button, Modal, useTranslation } from '@sb-itops/react';
import { PaymentProviderChargeForm } from '@sb-billing/payment-provider-charge-form';
import Styles from './CreditCardDepositModal.module.scss';
import { CreditCardDepositForm } from '../credit-card-deposit-form';

export const CreditCardDepositModal = (props) => {
  const { t } = useTranslation();
  const { isVisible, readyForSubmit, isSubmitting, onTriggerSubmit, onClose, totalChargeAmount, clientIsCoveringFee } = props;

  if (!isVisible) {
    return null;
  }

  const isSubmitDisabled = !readyForSubmit || isSubmitting;

  return (
    <Modal
      className={Styles.CreditCardDepositModal}
      isVisible={isVisible}
      title="Credit Card Deposit"
      body={getModalBody(props)}
      footer={
        <div className={Styles.modalFooter}>
          {clientIsCoveringFee && totalChargeAmount && <div className={Styles.totalCharge}>
            Total charge <span className={Styles.amount}>{t('cents', { val: totalChargeAmount })}</span>
          </div>}
          <Button onClick={onTriggerSubmit} disabled={isSubmitDisabled}>
            Process {isSubmitting && <Spinner small />}
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  );
};

const getModalBody = (props) => (
  <div className={Styles.modalBody}>
    <CreditCardDepositForm
      providerType={props.providerType}
      isSubmitting={props.isSubmitting}
      defaultPayorId={props.payorId}
      defaultMatterId={props.matterId}
      defaultBankAccountId={props.bankAccountId}
      clientIsCoveringFee={props.clientIsCoveringFee}
      providerSpecificChargeData={props.chargeFormData}
      onChange={props.onDepositFormDataChange}
      onReadyForSubmit={props.onDepositFormReadyForSubmit}
    />

    <PaymentProviderChargeForm
      providerType={props.providerType}
      triggerSubmit={props.triggerChargeFormSubmit}
      isSubmitting={props.isSubmitting}
      paymentFormConfiguration={props.paymentFormConfiguration}
      onSubmit={props.onChargeFormSubmit}
      onReadyForSubmit={props.onChargeFormReadyForSubmit}
      onPreSubmitChange={props.onChargeFormDataChange}
      onPreCharge={props.onPreCharge}
    />
  </div>
);

CreditCardDepositModal.displayName = 'CreditCardDepositModal';

CreditCardDepositModal.propTypes = {
  providerType: PropTypes.string.isRequired,
  paymentFormConfiguration: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  readyForSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  triggerChargeFormSubmit: PropTypes.bool.isRequired,
  onPreCharge: PropTypes.func.isRequired,
  totalChargeAmount: PropTypes.number.isRequired,
  // Used in the credit card deposit form for fee calculations.
  chargeFormData: PropTypes.object,
  // for pre-selection of fields
  payorId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  clientIsCoveringFee: PropTypes.bool.isRequired,
  // callbacks & func
  onDepositFormDataChange: PropTypes.func.isRequired,
  onDepositFormReadyForSubmit: PropTypes.func.isRequired,
  onChargeFormReadyForSubmit: PropTypes.func.isRequired,
  onTriggerSubmit: PropTypes.func.isRequired,
  onChargeFormSubmit: PropTypes.func.isRequired,
  onChargeFormDataChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CreditCardDepositModal.defaultProps = {
  isVisible: false,
  isSubmitting: false,
  // for pre-selection of fields
  payorId: undefined,
  matterId: undefined,
  bankAccountId: undefined,
  chargeFormData: undefined,
};
