'use strict';

angular.module('sb.billing.webapp').component('sbGuidToLinkComponent', {
  bindings: { str: '<', isHidden: '<?'},
  templateUrl: 'ng-components/guid-to-link/guid-to-link-component.html',
  controller: function (sbGuidToLinkService) {
    const ctrl = this;

    ctrl.$onInit = () => {
      toSpans();
    };

    ctrl.$onChanges = () => {
      toSpans();
    };

    function toSpans() {
      const words = _.words(ctrl.str, /[^, ]+/g);
      const parts = [];
      const lineParts = [];

      _.each(words, (word) => {
        const updateData = sbGuidToLinkService.getUpdateData(word);
        if (updateData.match) {
          parts.push(updateData);
          lineParts.push(updateData.text);
        } else {
          if (word !== 'contactId:') { // matter balances hack - please dont judge me
            parts.push(word);
            lineParts.push(word);
          }
        }
      });
      const line = lineParts.join(' ');
      ctrl.title = `${(ctrl.isHidden ? '(Deleted) ' : '' )}${line}`;
      ctrl.parts = parts;
    }
  }
});
