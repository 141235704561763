'use strict';

angular.module('sb.billing.webapp').component('sbPreviewPdf', {
  templateUrl: 'ng-components/preview-pdf/preview-pdf.html',
  bindings: { onFailure: '&?', onGeneratePdfP: '<', pdfContext:"<"},
  controller: function ( sbLoggerService, sbMessageDisplayService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbPreviewPdf');

    ctrl.$onInit = () => {
      if(_.isFunction(ctrl.onGeneratePdfP) && ctrl.pdfContext){
        ctrl.invoiceB64Url = undefined;
        previewSingle(ctrl.onGeneratePdfP, ctrl.pdfContext);
      }
    };

    async function previewSingle(onGeneratePdfP, context) {
      const previewingTime = moment();
      ctrl.invoiceB64Url = undefined;
      try {
        const pdfPreviewBase64 =  await onGeneratePdfP(context);
        ctrl.invoiceB64Url = buildDataUrl(pdfPreviewBase64);
        log.info(`previewing pdf took : ${moment.duration(moment().diff(previewingTime)).asMilliseconds()}`);
      }
      catch(ex){
        onFailure(ex);
      }
    }

    function buildDataUrl(base64data) {
      return `data:application/pdf;base64,${base64data}`;
    }

    function onFailure(err) {
      log.error('Failed to generate generate PDF', err);
      sbMessageDisplayService.error('Failed to generate PDF, please try again later');

      if (_.isFunction(ctrl.onFailure)) {
        ctrl.onFailure(err);
      }
    }
  }
});
