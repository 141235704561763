'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getList,
  getById,
  getByPaymentId,
  getByAccountType,
  reverseDeposit as reverseDepositTransactions,
} from '@sb-billing/redux/transactions';
import  { transactionType }from '@sb-billing/business-logic/transactions/entities/constants';
import {
  getMap as getBankAccountBalanceState,
} from '@sb-billing/redux/bank-account-balances';
import { getBalanceType } from '@sb-billing/redux/bank-account-settings';
import { selectors, opdates } from '@sb-billing/redux/bank-account-balances.2';
import { hasFacet, facets } from '@sb-itops/region-facets';

const { getBankAccountBalanceById } = selectors;
const { getBankAccountBalanceOpdate, getTemplateBankAccount } = opdates;

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

angular.module('@sb-billing/services').service('sbTransactionService', function (
  sbLoggerService, sbGenericEndpointService, sbGenericCacheService,
  sbUuidService, sbEndpointType, sbBankReconciliationService,
) {
  const that = this;
  const log = sbLoggerService.getLogger('sbTransactionService');
  const depositEndpoint = 'billing/bank-account/deposit';
  const transactionEndpoint = 'billing/transactions';
  const addAdjustmentEndpoint = 'billing/adjustment-transactions/add';
  const reverseAdjustmentEndpoint = 'billing/adjustment-transactions/reverse';

  sbGenericCacheService.setupCache({
    name: 'sbTransactionService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: transactionEndpoint },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.AccountTransactionsRecorded',
    },
    updateRedux,
    clearRedux,
  });

  //log.setLogLevel('info');

  that.depositFundsP = depositFundsP;
  that.reverseDeposit = reverseDeposit;
  that.saveAdjustment = saveAdjustment;
  that.reverseAdjustment = reverseAdjustment;
  that.getTransactionTypes = () => transactionType;
  that.isReconciled = isReconciled;
  that.hasTrustTransactions = hasTrustTransactions;

  that.getById = getById;
  that.getByFilter = getByFilter;
  that.getTransactionsByAccountType = getByAccountType;
  that.getTransactionsByPaymentId = getByPaymentId;

  function buildTransaction(data) {
    const accountId = getAccountId();
    const userId = getUserId();

    const transaction = {
      accountId,
      userId,
      amount: data.amount,
      bankAccountId: data.bankAccountId,
      contactId: data.contactId,
      description: data.description,
      id: data.transactionId || sbUuidService.get(),
      matterId: data.matterId,
      note: data.note,
      reference: data.reference,
      source: data.source,
      timestamp: moment().toISOString(),
      effectiveDate: data.effectiveDate || +moment().format('YYYYMMDD'),
      type: data.type,
      isHidden: data.isHidden,
    }

    if (data.reason) {
      transaction.reason = data.reason;
    }

    return transaction;
  }

  function hasTrustTransactions() {
    return getList().some((t) => _.endsWith(t.bankAccountId, 'Trust'));
  }

  function getByFilter(filter) {
    return _.filter(getList(), filter);
  }

  function isReconciled(transactionId) {
    const transaction = getById(transactionId);

    if (!transaction || !transaction.reconciliationId) {
      return false;
    }

    const reconciliation = sbBankReconciliationService.getById(transaction.reconciliationId);

    //if it has a reconciliationid but no reconciliation, assume its been reconciled via the recon setup.
    return !reconciliation || reconciliation.status === 'Completed';
  }

  async function depositFundsP(funds) {
    log.info('deposit funds', funds);

    const transactionOpdate = buildTransaction({
      ...funds,
      contactId: funds.payorId,
      type: transactionType.Deposit,
    });

    const bankAccountId = funds.bankAccountId;
    const bankAccountBalance =
      (opdates.sbBankAccountBalancesService && opdates.sbBankAccountBalancesService.find((acct) => acct.id === bankAccountId))
      || getBankAccountBalanceById(getBankAccountBalanceState(), { bankAccountId })
      || getTemplateBankAccount();
    const bankAccountBalanceOpdate = getBankAccountBalancesServiceOpdate({ bankAccountBalance, funds, balanceType: getBalanceType() });

    const changeset = {
      sbTransactionService: [transactionOpdate],
      sbBankAccountBalancesService: [bankAccountBalanceOpdate],
    };

    return sbGenericEndpointService.postPayloadP(depositEndpoint, undefined, funds, 'POST', { changeset });
  }

  function getBankAccountBalancesServiceOpdate({ bankAccountBalance, funds, balanceType }) {
    const { amount, payorId: contactId, matterId } = funds;
    const allowOverdraw = hasFacet(facets.allowOverdraw);

    const opdate = getBankAccountBalanceOpdate({
      transaction: {
        cents: amount,
        contactId,
        matterId,
      },
      bankAccountBalance,
      balanceType,
      allowOverdraw
    });

    return opdate;
  }

  function reverseDeposit({ accountType, bankAccountId, transactionIds, reason, checkMatterBalance, deleteTransaction = false, allowOverdraw = false }) {
    return reverseDepositTransactions({ accountType, bankAccountId, transactionIds, reason, checkMatterBalance, deleteTransaction, allowOverdraw })
  }

  function saveAdjustment(data) {
    const newTx = buildTransaction({
      ...data,
      description: `Adjustment: ${data.reason}`,
      type: transactionType.MatterAdjustment
    });

    const opdates = { sbTransactionService: [newTx] };
    sbGenericEndpointService.postPayloadP(addAdjustmentEndpoint, undefined, { ...data, bankAccountId: newTx.bankAccountId }, 'POST', { changeset: opdates });
  }

  function reverseAdjustment({ accountType, transactionId, reason, deleteTransaction = false }) {
    const txToReverse = getById(transactionId);

    if (txToReverse.reversed) {
      log.error(`Transaction already reversed, id: ${transactionId}`);
      throw new Error('Transaction already reversed');
    }

    const newTx = buildTransaction({
      accountType,
      amount: -txToReverse.amount,
      description: `Reversal: ${txToReverse.description}`,
      matterId: txToReverse.matterId,
      note: reason,
      type: transactionType.MatterAdjustmentReversal,
      isHidden: deleteTransaction,
    });

    const reversalData = {
      bankAccountId: newTx.bankAccountId,
      newTransactionId: newTx.id,
      userId: newTx.userId,
      effectiveDate: newTx.effectiveDate,
      reason: newTx.note,
      transactionIdToReverse: transactionId,
      hideTransactions: deleteTransaction,
    };

    const opdates = {
      sbTransactionService: [newTx, { ...txToReverse, reversed: true, isHidden: deleteTransaction }],
    };

    return sbGenericEndpointService.postPayloadP(reverseAdjustmentEndpoint, undefined, reversalData, 'POST', { changeset: opdates });
  }

});
