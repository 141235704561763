import { graphql } from '../types';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

export const MatterSearchProductivityDocument = graphql(`
  query MatterSearch(
    $filter: MatterSearchProductivityInput
    $offset: Int
    $limit: Int
    $sort: SortDescriptorInput
    $searchText: String!
  ) {
    matterSearchProductivity(filter: $filter, offset: $offset, limit: $limit, sort: $sort, searchText: $searchText) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        id
        isLead
        status
        attorneyResponsible {
          id
        }
        matterType {
          matterTypeId
          name
        }
        matterNumber
        description
        descriptionAutomation
        clientDisplay
        otherSideDisplay
      }
    }
  }
`);
