import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

function formatPhoneNumber(areaCode, phoneNumber) {
  return `${areaCode ? `(${areaCode}) ` : ''}${phoneNumber || ''}`;
}

export const onCreateEditTrustContact = ({ commandMessage, commandResult }) => {
  const { trustFields, addressFields } = commandMessage.contactFields;

  const optimisticAddress = {
    addressLine1: addressFields.addressLine1,
    addressLine2: addressFields.addressLine2,
    city: addressFields.city,
    state: addressFields.state,
    zipCode: addressFields.zipCode,
    country: commandMessage.region,
  };

  const optimisticContact = {
    __typename: 'Contact',
    id: commandResult.contact.id,
    removed: false,
    type: 'trust',
    displayName: trustFields.trustName,
    trustNumber: trustFields.trustNumber,
    trustees: trustFields.trustees,
    status: trustFields.trustStatus,
    trustDate: trustFields.trustDate,
    email: '',
    cell: '',
    phone: formatPhoneNumber(trustFields.trustPhoneAreaCode, trustFields.trustPhoneNumber),
    phone2: formatPhoneNumber(trustFields.trustFaxAreaCode, trustFields.trustFax),
    representativeOfs: [],
    representativeOfsDisplayName: '',
    businessAddress: optimisticAddress,
    residentialAddress: optimisticAddress,
    roles: [],
    people: [],
  };

  const apolloClient = getApolloClient();
  const contactIdentifier = apolloClient.cache.identify(optimisticContact);

  apolloClient.writeFragment({
    id: contactIdentifier,
    fragment: gql`
      fragment OpdateContact on Contact {
        ${Object.keys(optimisticContact).join('\n')}
      }
    `,
    data: {
      ...optimisticContact,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_ADDED,
    typeName: 'Contact',
    optimisticEntity: optimisticContact,
  };
};
