import { getRegion } from '@sb-itops/region';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getSettings as getInvoceSettings } from '../invoice-settings';

const REGION = getRegion();
const getAccountId = () => authSelectors.getAccountId(store.getState());

// previewData needs to get the logo URL as it is a presigned url (and has a time limit)
export const getPreviewData = async (settings, log, supportsOperatingAccount) => {
  const logo = await getLogoUrlP(getAccountId(), log);
  const invoiceSettings = getInvoceSettings();

  return [
    {
      ...previewReminderData,
      notes: settings.notes,
      additionalOptions: {
        showAccountSummary: settings.showAccountSummary,
        showTransactionHistory: settings.showTransactionHistory,
      },
      firm: {
        letterhead: invoiceSettings.letterhead,
        letterheadLayout: invoiceSettings.letterHeadLayout,
        abn: 'XXXXXXXXX',
        acn: 'XXXXXXXXX',
      },
      client: {
        name: 'Smokeball',
        address: previewMailingAddresses[REGION],
      },
      logo,
      includeWatermark: true,
      printOnLetterhead: true,
      printFirmLogo: true,
      accountStatementData: getAccountStatementData(settings, supportsOperatingAccount),
      invoiceSettings,
      pageMargins: getPageMargins(invoiceSettings),
    },
  ];
};

const getLogoUrlP = async (accountId, log) => {
  const path = `/firm-management/upload-logo/${accountId}/pre-signed-url/`;
  try {
    const response = await fetchGetP({ path });
    if (response?.status === 200 && response?.body) {
      return response.body;
    }
  } catch (error) {
    log.error('Error occurred getting download url for file', error);
  }

  return '';
};

const getPageMargins = ({ pageMargins }) => {
  let margins = pageMargins;

  // margins can be null so we cannot initialize in the parameters
  if (!margins) margins = { left: 100, top: 100, right: 100, unit: 'in' };

  return {
    ...margins,
    left: margins.left,
    top: margins.top,
    right: margins.right,
    bottom: margins.bottom,
  };
};

function getAccountStatementData(settings, supportsOperatingAccount) {
  if (!settings.showAccountSummary) {
    return {};
  }

  return {
    ...accountStatementData,
    accountSummary: getAccountSummaryData(supportsOperatingAccount),
    txGroups: settings.showTransactionHistory ? getTransactionHistoryData(supportsOperatingAccount) : [],
  };
}

function getAccountSummaryData(supportsOperatingAccount) {
  const accounts = [accountSummaryTrust];
  if (supportsOperatingAccount) {
    accounts.unshift(accountSummaryOperating);
  }

  return {
    accounts,
    totals: {
      balance: accounts.reduce((total, account) => total + account.totals.balance, 0),
    },
  };
}

function getTransactionHistoryData(supportsOperatingAccount) {
  const data = [transactionHistoryTrust];
  if (supportsOperatingAccount) {
    data.unshift(transactionHistoryOperating);
  }

  return data;
}

const previewMailingAddresses = {
  US: {
    addressLine1: '200 W Adams Street',
    addressLine2: 'Suite 2700',
    city: 'Chicago',
    state: 'IL',
    zipCode: '60606',
  },
  AU: {
    addressLine1: 'Level 8, 20 Bridge Street',
    addressLine2: 'Sydney NSW 2000',
  },
  GB: {
    addressLine1: '27 Old Gloucester Street',
    city: 'London',
    zipCode: 'WC1N 3AX',
  },
};

const previewReminderData = {
  debtorGroups: [
    {
      debtorGroupId: 'bc3621b9-0c63-4c9d-bc79-cebaaf94c820',
      debtorGroupName: 'James Zhuo',
      debtorCount: 1,
      invoices: [
        {
          region: REGION,
          $sbEntityType: 'Billing.Invoicing.Entities.InvoiceVersion, Billing.Invoicing.Entities',
          versionId: '9a04275b-86f3-4f58-8a13-3a197db31434',
          matterId: 'b262ee1a-83b9-4420-8ae0-85456e57b2f4',
          debtors: [
            {
              $sbEntityType: 'Billing.Debtor, Billing.Shared',
              id: 'fe11e27b-2018-43a2-b5ec-86d5bba4e718',
              ratio: 1,
            },
          ],
          description: null,
          invoiceNumber: 815,
          issuedDate: 20180122,
          dueDate: 20180129,
          validTo: null,
          validFrom: '2018-01-22T03:33:05.4890000Z',
          entries: [
            {
              $sbEntityType: 'Billing.InvoicedMatterEntryItem, Billing.Shared',
              id: '7a8187c6-ee97-4a72-805a-9b615f6b2190',
              versionId: '55ed580f-f690-46c4-93af-faa153d051df',
              type: 1,
            },
          ],
          feesOverallSummary: null,
          expensesOverallSummary: null,
          feesTotalOverride: null,
          expensesTotalOverride: null,
          discount: null,
          layout: {
            $sbEntityType: 'Billing.Invoicing.Entities.InvoiceLayout, Billing.Invoicing.Entities',
            expenseLineItemConfiguration: 0,
            feeLineItemConfiguration: 0,
            showStaffNameOnEntries: false,
            showRateOnEntries: false,
            showDurationOnEntries: false,
            feeSummaryLineDescription: '',
            expenseSummaryLineDescription: '',
            showNonBillableFees: false,
            showNonBillableExpenses: false,
            showDescriptionForEntries: false,
            includeNonBillableItems: false,
          },
          additionalOptions: {
            $sbEntityType: 'Billing.Invoicing.Entities.AdditionalOptions, Billing.Invoicing.Entities',
            showAccountSummary: false,
            showTransactionHistory: false,
          },
          status: 1,
          paidDate: 0,
          userId: null,
          invoiceTitle: '2016-140 - ABC Hairdressing Salon - Business General',
          invoiceTitleLine2: 'Custom Subtitle',
          waived: false,
          merchantPaymentReference: '1f999b23-a939-42b3-afb8-bd5fa5f64220',
          feeTaxRate: 0,
          totals: {
            $sbEntityType: 'Billing.Matters.Entities.Views.InvoiceTotals, Billing.Matters.Entities',
            invoiceId: '00585497-b071-489b-90ae-aabe315d5103',
            accountId: 'b89b247d-1cd7-4e1c-b9b9-a38f8303ac87',
            debtorId: 'fe11e27b-2018-43a2-b5ec-86d5bba4e718',
            matterId: 'b262ee1a-83b9-4420-8ae0-85456e57b2f4',
            billed: 2000,
            paid: 0,
            unpaid: 2000,
            writtenOff: 0,
            waived: 0,
            discount: 0,
            total: 2000,
            tax: 200,
            interest: 0,
            unpaidExcInterest: 2000,
            lastUpdated: '2018-05-03T06:15:13.5190000Z',
          },
        },
      ],
    },
  ],
  notes: 'This is a note',
  totalUnpaidPastDue: 2000,
  totalInterest: 0,
  totalTax: 200,
  customFirmInfo: [
    {
      label: 'Payment Due On',
      value: 'Immediately',
    },
  ],
  matterDisplay: '2016-140 - ABC Hairdressing Salon - Business General',
  pastDueBalance: 2000,
  notOverdue: 0,
  '1To30': 0,
  '31To60': 0,
  '61To90': 0,
  above90: 2000,
  all: 2000,
  region: REGION,
  client: {
    // filled in programatically
  },
  firm: {
    // filled in programtically,
  },
  reportDisplay: 'Invoice Reminder',
  reportDate: '20180612',
};

const accountStatementData = {
  accountId: 'c7607801-19a1-4e2e-a6ad-6e4fb53c6076',
  reportDisplay: 'Account Statement',
  reportType: 'account-statement',
  isDataOnlyRequest: true,
  reportDate: '20180621',
  forWho: 'undefined',
  allTimeReport: true,
  printOptions: {
    paperFormat: 'A4',
    orientation: 'landscape',
  },
  showAccountSummary: true,
  showTransactionHistory: true,
  showTransactionHistoryWithInvoiceNumbers: true,
  contactId: '73dad840-7ca5-496f-9c0e-ee32da213b65',
  matterId: '1b016177-01e8-46f9-8a2a-cc30c86178f8',
  filters: {
    accountId: 'c7607801-19a1-4e2e-a6ad-6e4fb53c6076',
    contactId: '73dad840-7ca5-496f-9c0e-ee32da213b65',
    matterId: '1b016177-01e8-46f9-8a2a-cc30c86178f8',
    endOfTime: '22180621', // TODO what is this used for?
  },
  region: 'US',
  invoiceHistory: {
    invoices: [],
  },
  invoiceSummary: {},
  // txGroups: [],
  // accountSummary: {},
  reportDataFound: true,
  feeEntries: {
    fees: [],
  },
  expenseEntries: {
    expenses: [],
  },
};

const transactionHistoryOperating = {
  id: 'Operating',
  bankAccountDisplayText: 'Operating Retainer',
  history: [
    {
      effectiveDate: 20180122,
      description: 'Transaction Description',
      matter: '2016-140 - ABC Hairdressing Salon - Business General',
      debit: 0,
      credit: 100,
      runningBalance: 100,
    },
  ],
  totals: {
    debit: 0,
    credit: 100,
    balance: 100,
  },
};

const transactionHistoryTrust = {
  id: 'Trust',
  bankAccountDisplayText: 'Trust',
  history: [
    {
      effectiveDate: 20180122,
      description: 'Transaction Description',
      matter: '2017-141 - DEF Generic Mechanic - Business General',
      debit: 0,
      credit: 100,
      runningBalance: 100,
    },
  ],
  totals: {
    debit: 0,
    credit: 100,
    balance: 100,
  },
};

const accountSummaryOperating = {
  bankAccountDisplayText: 'Operating Retainer',
  totals: {
    balance: 100,
  },
};

const accountSummaryTrust = {
  bankAccountDisplayText: 'Trust',
  totals: {
    balance: 100,
  },
};
