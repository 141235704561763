import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';

angular.module('sb.billing.webapp').directive('sbDataMatterTypeById', function () {
  'use strict';

  return {
    'restrict': 'E',
    'require':  '^sbCompose',
    'scope' : { matterTypeId: '=', composeDataKey: '=?' },
    'controller': function () {},
    'link': function (scope, element, attrs, ctrl) {
      var mt;

      function update() {
        var newMt = scope.matterTypeId && getMatterTypeById(scope.matterTypeId);

        if (newMt !== mt) {
          mt = newMt;
          ctrl.setComposeData({dataType: 'mattertype', data: mt}, scope.composeDataKey || 'mattertype');
        }
      }

      scope.$on('smokeball-data-update-sbMatterTypesService', update);
      scope.$watch('matterTypeId', update);
    }
  };
});
