import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { TransferFundsForm } from '../transfer-funds-form';
import Styles from './TransferFundsModal.module.scss';

export const TransferFundsModal = React.memo(
  ({
    scope,
    isSubmitLocked,
    onClose,
    onTransferFunds,
    isVisible,
    contactId,
    matterId,
    bankAccountId,
    isTransferNumberingEnabled,
    localisationConfig,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.transferFundsModal}
        isVisible={isVisible}
        title="Transfer Funds"
        body={
          <TransferFundsForm
            contactId={contactId}
            matterId={matterId}
            bankAccountId={bankAccountId}
            scope={scope}
            localisationConfig={localisationConfig}
            isTransferNumberingEnabled={isTransferNumberingEnabled}
          />
        }
        footer={
          <div>
            <div className={Styles.buttonContainer}>
              <Button
                className={Styles.processButton}
                onClick={onTransferFunds}
                disabled={isSubmitLocked}
                form={scope}
                locked={isSubmitLocked}
                size="full-width"
              >
                Process
              </Button>
            </div>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

TransferFundsModal.displayName = 'TransferFundsModal';

TransferFundsModal.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  onTransferFunds: PropTypes.func.isRequired,
  isTransferNumberingEnabled: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isSubmitLocked: PropTypes.bool,
  localisationConfig: PropTypes.object.isRequired,
};

TransferFundsModal.defaultProps = {
  matterId: undefined,
  contactId: undefined,
  bankAccountId: undefined,
  isSubmitLocked: false,
  onClose: () => {},
};
