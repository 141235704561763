import uuid from '@sb-itops/uuid';
import moment from 'moment';
import { todayAsInteger } from '@sb-itops/date';
import { calculatePaymentPlanPosition } from '@sb-billing/business-logic/payment-plan/services';

const staticPaymentPlanInformationTiles = {
  PAID: {
    description: 'paid',
    id: uuid(),
    tooltip: 'Paid amount',
  },
  BEHIND_AHEAD: {
    id: uuid(),
  },
  DUE: {
    description: 'due to date',
    id: uuid(),
    tooltip: 'Due to date amount',
  },
  OUTSTANDING: {
    description: 'outstanding',
    id: uuid(),
    tooltip: 'Outstanding Amount',
  },
  PAYMENTS_LEFT: {
    description: 'payment(s) left (estimate)',
    id: uuid(),
    tooltip: 'Payment(s) left (estimate)',
  },
  DAYS_SINCE_PAYMENT: {
    description: 'day(s) since last payment',
    id: uuid(),
    tooltip: 'Day(s) since last payment',
  },
};

const DATE_FORMAT = 'YYYYMMDD';

/**
 * build the payment plan list data.
 * @param {object} params
 * @param {Array.[object]} params.paymentPlanPaymentEvents -  a list of payment plan events.
 * @param {object} params.paymentPlan -  a payment plan.
 * @param {object} params.paymentMethod -  payment method associated with the payment plan.
 * @returns {[{description: string, id: string, tooltip:string, amount:number, days:number}]} the latest pp for a given debtor
 * @throws if paymentPlan is falsy
 * @throws if paymentPlan.installments is falsy
 * @throws if paymentPlanPaymentEvents is falsy
 */
export const buildPaymentPlanInformationList = ({ paymentPlan, paymentPlanPaymentEvents, paymentMethod }) => {
  if (!paymentPlan) {
    throw new Error('paymentPlan cannot be falsy');
  }

  if (!paymentPlan.installments) {
    throw new Error('paymentPlan.installments cannot be falsy');
  }

  if (!paymentPlanPaymentEvents) {
    throw new Error('paymentPlanPaymentEvents cannot be falsy');
  }

  const {
    isBehind,
    aheadOrBehindAmount,
    installmentAmountDue,
    paidAmount,
    paymentMethod: paymentMethodStatus,
  } = calculatePaymentPlanPosition({
    paymentPlan,
    paymentPlanEvents: paymentPlanPaymentEvents,
    expiry: paymentMethod?.expiry,
  });

  const endDate = paymentPlan.endDate || todayAsInteger();

  const daysSinceLastPayment = paymentPlan.lastPaymentDate
    ? moment.duration(moment(endDate, DATE_FORMAT).diff(moment(paymentPlan.lastPaymentDate, DATE_FORMAT))).asDays()
    : 0;

  return [
    {
      ...staticPaymentPlanInformationTiles.PAID,
      amount: paidAmount,
      showError: false,
    },
    {
      ...staticPaymentPlanInformationTiles.OUTSTANDING,
      amount: paymentPlan.unpaidAmount,
      showError: false,
    },
    {
      ...staticPaymentPlanInformationTiles.BEHIND_AHEAD,
      amount: Math.abs(aheadOrBehindAmount),
      description: isBehind ? 'behind' : 'ahead',
      tooltip: isBehind ? 'Behind Amount' : 'Ahead amount',
      showError: isBehind,
    },
    {
      ...staticPaymentPlanInformationTiles.DUE,
      amount: installmentAmountDue,
      showError: false,
    },
    {
      ...staticPaymentPlanInformationTiles.PAYMENTS_LEFT,
      days: Math.ceil(paymentPlan.unpaidAmount / paymentPlan.installmentAmount),
      showError: false,
    },
    {
      ...staticPaymentPlanInformationTiles.DAYS_SINCE_PAYMENT,
      days: Math.ceil(daysSinceLastPayment),
      tooltip: daysSinceLastPayment < 0 ? 'future payment scheduled' : paymentMethodStatus.statusDisplay,
      showInfo: daysSinceLastPayment < 0 && !['expired', 'failed'].includes(paymentMethodStatus.status),
      showError: ['expired', 'failed'].includes(paymentMethodStatus.status),
      showWarning: ['expiredWarning'].includes(paymentMethodStatus.status),
    },
  ];
};
