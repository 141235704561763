import { updateState } from '../../../actions/generic/update-state';
import { domain } from '../../../domain';
import name from '../name';

const { updateStateAction, updateStateReducer, ACTION_TYPE } = updateState({
  actionName: `${domain}/${name}/UPDATE_SELECTED_INVOICE_TEMPLATE`,
});

const UPDATE_SELECTED_INVOICE_TEMPLATE = ACTION_TYPE;

export const updateSelectedInvoiceTemplate = (state, { uniqueId, templateId }) => {
  updateStateAction({ ...state, [uniqueId]: { ...state[uniqueId], selectedInvoiceTemplate: { templateId } } });
};

export const updateSelectedInvoiceTemplateReducerEntry = {
  [UPDATE_SELECTED_INVOICE_TEMPLATE]: updateStateReducer,
};
