import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { sentViaTypes, getSentViaOptions } from '@sb-billing/business-logic/correspondence-history';
import { featureActive } from '@sb-itops/feature';
import { forms2PropTypes, FormLabel, MultiSelectDropdown } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { includeErrorClassnameIfInvalid } from '../include-error-classname-if-invalid';

export const MatterCommunicationSettings = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // formSubmitting,
  // form fields
  bccAddresses,
  ccAddresses,
  documentSendingPreferences,
  eBillingSite,
  otherMethod,
  // others
  // callbacks
  onFieldValuesUpdated,
  onFieldValueSet,
}) => {
  if (!formInitialised) {
    return null;
  }

  const sendingPreferenceOptions = getSentViaOptions({
    isEBillingAvailable: hasFacet(facets.eBillingSendingPreference),
  });

  const isPreferenceIncludesEBilling =
    Array.isArray(documentSendingPreferences) &&
    documentSendingPreferences.some((item) => item.value === sentViaTypes.E_BILLING);
  const isPreferenceIncludesOther =
    Array.isArray(documentSendingPreferences) &&
    documentSendingPreferences.some((item) => item.value === sentViaTypes.OTHER);

  const additionalFieldsCount = isPreferenceIncludesEBilling + isPreferenceIncludesOther;

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel label="CC Email Addresses" field={ccAddresses} submitFailed={submitFailed} optional />
          <input
            className={classnames('form-control', includeErrorClassnameIfInvalid(ccAddresses, submitFailed))}
            type="text"
            disabled={formDisabled}
            value={(ccAddresses && ccAddresses.value) || ''}
            onChange={(e) => onFieldValuesUpdated({ ccAddresses: e.target.value })}
            placeholder="e.g. accounts@example.com, mail@example.com"
          />
        </div>

        <div className="col-lg-12 form-group">
          <FormLabel label="BCC Email Addresses" field={bccAddresses} submitFailed={submitFailed} optional />
          <input
            className={classnames('form-control', includeErrorClassnameIfInvalid(bccAddresses, submitFailed))}
            type="text"
            disabled={formDisabled}
            value={(bccAddresses && bccAddresses.value) || ''}
            onChange={(e) => onFieldValuesUpdated({ bccAddresses: e.target.value })}
            placeholder="e.g. accounts@example.com, mail@example.com"
          />
        </div>

        {featureActive('BB-11448') && (
          <>
            <div className="col-lg-12 form-group">
              <FormLabel
                label="Document Sending Preference"
                field={
                  // field of FormLabel is expecting a Forms2Field object
                  Array.isArray(documentSendingPreferences) ? documentSendingPreferences[0] : documentSendingPreferences
                }
                explainerText="Select a default method to be used when manually marking invoices and reminders as sent"
                submitFailed={submitFailed}
                optional
              />
              <MultiSelectDropdown
                options={sendingPreferenceOptions}
                onSelectedIdsChanged={(selectedOptions) => {
                  const selectedPreferences = selectedOptions.map((item) => item.value);
                  onFieldValueSet({
                    field: 'documentSendingPreferences',
                    value: selectedPreferences.length > 0 ? selectedPreferences : undefined,
                  });
                }}
                value={
                  Array.isArray(documentSendingPreferences)
                    ? documentSendingPreferences.map((documentSendingPreference) => documentSendingPreference.value)
                    : undefined
                }
                showCustomMenuList={false}
                disabled={formDisabled}
              />
            </div>
            {Array.isArray(documentSendingPreferences) && isPreferenceIncludesEBilling && (
              <div
                className={classnames(
                  'form-group',
                  additionalFieldsCount === 2 && 'col-lg-6',
                  additionalFieldsCount === 1 && 'col-lg-12',
                )}
              >
                <FormLabel
                  label="eBilling Site"
                  field={eBillingSite}
                  explainerText="Name of the site or services where you process your eBilling or LEDES matters"
                  submitFailed={submitFailed}
                  optional
                />
                <input
                  className="form-control"
                  type="text"
                  disabled={formDisabled}
                  value={(eBillingSite && eBillingSite.value) || ''}
                  onChange={(e) => onFieldValuesUpdated({ eBillingSite: e.target.value })}
                />
              </div>
            )}
            {Array.isArray(documentSendingPreferences) && isPreferenceIncludesOther && (
              <div
                className={classnames(
                  'form-group',
                  additionalFieldsCount === 2 && 'col-lg-6',
                  additionalFieldsCount === 1 && 'col-lg-12',
                )}
              >
                <FormLabel
                  label="Other Method"
                  field={otherMethod}
                  explainerText={`"Other" method you're using to send your invoices (optional)`}
                  submitFailed={submitFailed}
                  optional
                />
                <input
                  className="form-control"
                  type="text"
                  disabled={formDisabled}
                  value={(otherMethod && otherMethod.value) || ''}
                  onChange={(e) => onFieldValuesUpdated({ otherMethod: e.target.value })}
                />
              </div>
            )}
          </>
        )}
      </div>
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterCommunicationSettings.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  bccAddresses: Forms2Field,
  ccAddresses: Forms2Field,
  documentSendingPreferences: PropTypes.oneOfType([Forms2Field, PropTypes.arrayOf(Forms2Field)]),
  eBillingSite: Forms2Field,
  otherMethod: Forms2Field,
  // others
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onFieldValueSet: PropTypes.func.isRequired, // generic method for updating fields
};

MatterCommunicationSettings.defaultProps = {
  // form state
  formDisabled: undefined,
  submitFailed: undefined,
  // form fields
  bccAddresses: undefined,
  ccAddresses: undefined,
  documentSendingPreferences: undefined,
  eBillingSite: undefined,
  otherMethod: undefined,
};

MatterCommunicationSettings.displayName = 'MatterCommunicationSettings';
