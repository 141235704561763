'use strict';

const currencyFormatter = require('./currency-formatter');
const dateFormatter = require('./date-formatter');
const capitalizeFormatter = require('./capitalize-formatter');
const capitalizeAllWordsFormatter = require('./capitalize-all-words-formatter');
const capitalizeAllFormatter = require('./capitalize-all-formatter');

module.exports = (i18next) => {
  i18next.services.formatter.add('currencyFormatter', currencyFormatter);
  i18next.services.formatter.add('dateFormatter', dateFormatter);
  i18next.services.formatter.add('capitalizeFormatter', capitalizeFormatter);
  i18next.services.formatter.add('capitalizeAllWordsFormatter', capitalizeAllWordsFormatter);
  i18next.services.formatter.add('capitalizeAllFormatter', capitalizeAllFormatter);
};
