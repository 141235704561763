'use strict';

/**
 * @typedef {object} CORRESPONDENCE_STATUS
 * @property {number} inProgress 0
 * @property {number} succeeded 1
 * @property {number} failed 2
 */
const CORRESPONDENCE_STATUS = Object.freeze({
  inProgress: 0,
  succeeded: 1,
  failed: 2,
});

module.exports = { CORRESPONDENCE_STATUS };
