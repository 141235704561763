import useDeepCompareEffect from 'use-deep-compare-effect';
import { useQuery } from '@apollo/client';
import { getOperationName, getQueryDefinition } from '@apollo/client/utilities';
import { subscribeToApolloEntityOpdateEvents, subscribeToNotifications, getApolloClient } from 'web/services/apollo';
import { useEffect } from 'react';

/**
 * @typedef { import('../types.js').SubscribedQuery } SubscribedQuery
 */

/**
 * useSubscribedQuery
 *
 * @param {SubscribedQuery|*} queryOrSubscribedQuery
 * @param {Object} queryOptions
 * @param {Object} params
 * @param {String[]} [params.notificationIds] notificationIds override if using SubscribedQuery object
 * @param {String[]} [params.rootFieldsToEvict] Provide a subset of root fields to evict, otherwise all will be evicted
 * @param {Function} [params.onOpdateQuery] Function which is used to opdate the query when an apollo entity is opdated within the cache.
 * @param {Boolean} [params.clearCache] Clear cache before querying new data
 * @returns {Object} useQuery results, onClearListCache function
 */
export function useSubscribedQuery(queryOrSubscribedQuery, queryOptions, params = {}) {
  const query = queryOrSubscribedQuery?.query ? queryOrSubscribedQuery.query : queryOrSubscribedQuery;
  const notificationIds = params.notificationIds || queryOrSubscribedQuery.notificationIds;
  const rootFieldsToEvict = params.rootFieldsToEvict || queryOrSubscribedQuery.rootFieldsToEvict;
  const onOpdateQuery = params.onOpdateQuery || queryOrSubscribedQuery.onOpdateQuery;
  const { clearCache } = params;

  if (!Array.isArray(notificationIds) || !notificationIds.length) {
    throw new Error('useSubscribedQuery requires notificationIds to be provided');
  }

  const queryName = getOperationName(query);
  if (!queryName) {
    throw new Error('Must use named queries in useSubscribedQuery');
  }

  const queryDefinition = getQueryDefinition(query);

  // Unless specified via rootFieldsToEvict, parse the query and evict all root-level fields
  const fieldsToEvict =
    rootFieldsToEvict && rootFieldsToEvict.length
      ? rootFieldsToEvict
      : queryDefinition.operation === 'query' &&
        queryDefinition.selectionSet.selections.reduce((acc, selection) => {
          if (selection.kind === 'Field' && selection.name.kind === 'Name') {
            acc.push(selection.name.value);
          }
          return acc;
        }, []);

  const onClearListCache = () => {
    if (fieldsToEvict) {
      const apolloClient = getApolloClient();

      fieldsToEvict.forEach((fieldName) => {
        // This will evict any root-level fields matching the name, regardless
        // of which query variables were used. Broadcast is false to avoid
        // refetching the query while the component is being unmounted
        apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName, broadcast: false });

        // Ideally instead of evicting the query data, we could mark it as
        // stale so that the next time the component loads we could serve
        // stale data with query fetchPolicy 'cache-first' while the refetch
        // is in progress. Unfortunately that is not possible at this time:
        // https://github.com/apollographql/apollo-client/issues/9319
        // https://github.com/apollographql/apollo-client/issues/7060
        // apolloClient.cache.modify({
        //   id: 'ROOT_QUERY',
        //   fields: {
        //     [fieldName]: (queryData, { INVALIDATE }) => {
        //       return INVALIDATE;
        //     },
        //   },
        // });
      });
      apolloClient.cache.gc();
    }
  };

  if (clearCache) {
    onClearListCache();
  }

  const queryResults = useQuery(query, queryOptions);
  /**
   * We have to hold the query data in a variable
   * to ensure that in the time of a bulk update
   * the useEffect bellow passes the correct data to the opdate handler.
   */
  let queryData = queryResults.data;

  useEffect(() => {
    if (!onOpdateQuery) {
      return undefined;
    }

    const onEntityOpdated = (entityOpdateEvent) => {
      if (queryResults.loading) {
        return;
      }

      const updatedQueryData = onOpdateQuery({
        apolloClient: queryResults.client,
        queryData, // Data is saved on the variable above
        queryVariables: queryResults.variables,
        entityOpdateEvent,
      });

      // Give the ability to opt out of the opdate.
      if (updatedQueryData !== undefined) {
        queryResults.client.writeQuery({
          query,
          variables: queryResults.variables,
          data: updatedQueryData,
        });

        // Updates the variable with the result of the opdate handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
        queryData = updatedQueryData;
      }
    };

    const unsubscribeFromOpdateEvents = subscribeToApolloEntityOpdateEvents({ onEntityOpdated });
    return unsubscribeFromOpdateEvents;
  }, [queryResults]);

  useDeepCompareEffect(() => {
    const unsubscribeFromNotifications = subscribeToNotifications({
      notificationIds,
      rootFieldsToEvict: fieldsToEvict,
    });

    return () => {
      unsubscribeFromNotifications();
      onClearListCache();
    };
  }, [notificationIds, fieldsToEvict]);

  return {
    ...queryResults,
    onClearListCache,
  };
}
