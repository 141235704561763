import { REMEMBER_STATE_PARAMS, FORGET_STATE_PARAMS } from './types';

/**
 * Action to remember a page path in redux to be able to return to it
 * @param {Array} stateGoParams to be spread to $state.go() shape ['home.billing.view-contact.transactions.credit-note-ledger', { ...$stateParams }]
 * @param {string} parentPageName page name in ui-router, e.g. 'home.billing.view-contact.transactions.credit-note-ledger'
 * @param {string} entityId Defined whether the page is further keyed to an id, e.g. matterId or contactId
 * @returns
 */
export const rememberAccountStateParams = ({ stateGoParams, parentPageName, entityId }) => ({
  type: REMEMBER_STATE_PARAMS,
  payload: { stateGoParams, parentPageName, entityId },
});

/**
 * Action to forget a page path in redux for when we want to go to a specific page
 * @param {Object} payload
 * @param {string} payload.parentPageName page name in ui-router, e.g. 'home.billing.view-contact.transactions.credit-note-ledger'
 * @param {string} [payload.entityId] Defined whether the page is further keyed to an id, e.g. matterId or contactId
 * @returns
 */
export const forgetAccountStateParams = ({ parentPageName, entityId }) => ({
  type: FORGET_STATE_PARAMS,
  payload: { parentPageName, entityId },
});
