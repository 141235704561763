'use strict';

/**
 * @typedef { import('../types/index.js').FeeDetails } FeeDetails
 */

const { calculateFeeDetails: calculateStripeFeeDetails } = require('./stripe');
const { calculateFeeDetails: calculateEzyCollectFeeDetails } = require('./ezy-collect');
const { providers } = require('../entities/constants');

const providerSpecificFeeDetailsFns = {
  [providers.STRIPE]: calculateStripeFeeDetails,
  [providers.EZY_COLLECT]: calculateEzyCollectFeeDetails,
};

/**
 * calculateFeeDetails
 *
 * Returns the payment provider's fee details related to processing a charge of a given amount.
 *
 * This function can be used for several purposes.
 *
 * The most common purpose is to determine how much to charge a card to ensure a desired amount hits the destination account after fees are subtracted.
 * Another reason is to simply calculate the fee component of an amount to be charged.
 *
 * Note that one cannot simply use desired amount + fee component of desired amount to ensure that the desired amount hits the destination account. This
 * is because most payment providers will incrementally charge a higher fee as the charge amount increases. See {@link FeeDetails} for more information.
 *
 * Not all providers have support for calculating fee details implemented yet.
 *
 * @param {Object} params
 * @param {string} params.providerType The provider type that will be used to process the charge.
 * @param {number} params.desiredAmountInCents The amount in cents that the payor would like to hit the destination account (after fees).
 * @param {Object} params.feeSchedule Fee schedule information extracted by extractFeeSchedule()
 * @param {Object} [params.providerSpecificFields] The provider specific data that is used to calculate the processing fee.
 *
 * @returns {FeeDetails} The fee details for the desired amount in cents.
 */
const calculateFeeDetails = ({ providerType, desiredAmountInCents, feeSchedule, providerSpecificFields = {} }) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!Number.isInteger(desiredAmountInCents) || desiredAmountInCents < 0) {
    throw new Error('desiredAmountInCents must be a non-negative integer');
  }

  const providerSpecificFeeDetailsFn = providerSpecificFeeDetailsFns[providerType];
  if (!providerSpecificFeeDetailsFn) {
    throw new Error(`Failed to calculate fee details for unsupported provider type '${providerType}'`);
  }

  return providerSpecificFeeDetailsFn({
    desiredAmountInCents,
    feeSchedule,
    providerSpecificFields,
  });
};

module.exports = {
  calculateFeeDetails,
};
