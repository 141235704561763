'use strict';

import { hasBillingAccess } from 'web/services/user-session-management';

angular
  .module('sb.billing.webapp')
  .controller('EntriesController', function ($uibModal, sbLinkService, sbAsyncOperationsService, sbLocalisationService, sbOverrideRedirectService) {
    var that = this;
    that.onClickLink = sbLinkService.onClickLink;
    that.overrideRedirect = sbOverrideRedirectService.overrideNext;
    that.hasBillingAccess = hasBillingAccess;

    that.t = sbLocalisationService.t; // Needed for the Expense tab button
    that.onExportExpenseCsv = sbAsyncOperationsService.startExportExpenseCsv;
    that.onPrintCheque = (chequeId) => sbAsyncOperationsService.startOperatingChequeCreation([chequeId]);
  });
