import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query InvoiceTableData(
    $invoiceListFilter: InvoiceListFilter
    $offset: Int
    $limit: Int
    $sort: ListSort
    $showStatement: Boolean!
    $includeMatterUtbmsSettings: Boolean
    $paymentPlanStatusAsOfDate: Int
    $searchTerms: [String]
  ) {
    invoiceList(
      filter: $invoiceListFilter
      offset: $offset
      limit: $limit
      sort: $sort
      paymentPlanStatusAsOfDate: $paymentPlanStatusAsOfDate
      includeMatterUtbmsSettings: $includeMatterUtbmsSettings
      searchTerms: $searchTerms
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      sortOrder {
        fieldNames
        directions
      }
      results {
        id
        matter {
          id
          matterNumber
          # description
          clientDisplay
          otherSideDisplay
          matterType {
            matterTypeId
            name
          }
          billingConfiguration {
            id
            isUtbmsEnabled
          }
        }
        invoiceStatement @include(if: $showStatement) {
          id
          number
          status
        }
        invoiceNumber
        issuedDate
        finalizedTimestamp
        debtors {
          id
          # ratio
          contact {
            id
            displayName
          }
        }
        # status
        pseudoStatus
        dueDate
        paidDate
        showRetainer
        validFrom
        # userId
        user {
          id
          person {
            id
            initials
            name
          }
        }
        totals {
          id
          # billed
          interest
          paid
          paidByCredit
          unpaid
          totalExcInterest
          # writtenOff
        }
        listItemProperties {
          hasBeenViewedOnline
          hasUnpaidAnticipatedDisbursements
          activePaymentPlanDetails {
            id
            autoCharge
            behindOnDate
            debtorId
            installmentAmount
            installmentFrequency
            lastPaymentDate
            nextPaymentDate
            paymentPlanPosition {
              status
              paymentMethod {
                status
                statusDisplay
              }
            }
            status
          }
          sentStatus
        }
      }
    }
  }
`;

const notificationIds = [
  'WebQueryInvoicingNotifications.InvoiceUpdated',
  'WebQueryInvoicingNotifications.InvoiceStatementUpdated',
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
  'WebQueryAccountsNotifications.AccountBalanceUpdated',
  'WebQueryBillingSharedNotifications.CorrespondenceHistoryUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceTableData = {
  query,
  notificationIds,
};
