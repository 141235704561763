import { defaultPath } from './default-path';

export const ASSUME_USER_IS_AUTHENTICATED = `${defaultPath}/ASSUME_USER_IS_AUTHENTICATED`;
export const FORCE_USER_IDENTITY = `${defaultPath}/FORCE_USER_IDENTITY`;

export const LOGIN_INITIATED = `${defaultPath}/LOGIN_INITIATED`;
export const LOGIN_CHALLENGED = `${defaultPath}/LOGIN_CHALLENGED`;
export const LOGIN_SUCCESS = `${defaultPath}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${defaultPath}/LOGIN_FAILURE`;

export const REFRESH_TOKEN_INITIATED = `${defaultPath}/REFRESH_TOKEN_INITIATED`;
export const REFRESH_TOKEN_SUCCESS = `${defaultPath}/REFRESH_TOKEN_SUCCESS`;
export const REFRESH_TOKEN_FAILURE = `${defaultPath}/REFRESH_TOKEN_FAILURE`;

export const CHALLENGE_RESPONSE_INITIATED = `${defaultPath}/CHALLENGE_RESPONSE_INITIATED`;
export const CHALLENGE_RESPONSE_FAILURE = `${defaultPath}/CHALLENGE_RESPONSE_FAILURE`;

export const LOGOUT_INITIATED = `${defaultPath}/LOGOUT_INITIATED`;
export const LOGOUT_SUCCESS = `${defaultPath}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${defaultPath}/LOGOUT_FAILURE`;

export const authenticationStatuses = {
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
};
