import * as React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@sb-itops/react';

import Styles from './LoadingStatus.module.scss';

const LoadingStatus = ({ message }) => (
  <div className={Styles.loadingContainer}>
    <div>
      <Spinner small />
    </div>
    {message && <p>{message}</p>}
  </div>
);

LoadingStatus.propTypes = {
  message: PropTypes.string,
};

LoadingStatus.defaultProps = {
  message: '',
};

LoadingStatus.displayName = 'LoadingStatus';

export default LoadingStatus;
