import PropTypes from 'prop-types';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getById as getAutoNumberDefinitionByAccountId } from '@sb-matter-management/redux/auto-number-definition';
import { isDuplicateMatterNumber } from '@sb-matter-management/redux/matters';

import { getAccountId } from 'web/services/user-session-management';
import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterDetails } from './MatterDetails';

const hooks = ({ formInitialised, formFields, formDisabled, formSubmitting, submitFailed, matterId, t }) => ({
  useForm: () => {
    const { matterNumber, description, openedDate } = formFields;

    // get auto number definition settings for account
    const autoNumberDefinition = getAutoNumberDefinitionByAccountId(getAccountId());

    let matterNumberExistsWarning = '';
    if (isDuplicateMatterNumber(matterNumber.value, matterId)) {
      const matterNumberLabelForRegion = t('matterNumber');
      if (matterNumber.value === matterNumber.originalValue) {
        matterNumberExistsWarning = `This ${matterNumberLabelForRegion} is also used in another matter, please consider allocating a different ${matterNumberLabelForRegion} for each matter.`;
      } else {
        matterNumberExistsWarning = `The ${matterNumberLabelForRegion} already exists. This will create a duplicate ${matterNumberLabelForRegion}.`;
      }
    }

    return {
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields
      matterNumber,
      description,
      openedDate,
      // other
      autoNumberDefinition,
      matterNumberExistsWarning,
    };
  },
});

export const MatterDetailsContainer = withReduxStore(withTranslation()(composeHooks(hooks)(MatterDetails)));

MatterDetailsContainer.displayName = 'MatterDetailsContainer';

MatterDetailsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterDetailsContainer.defaultProps = {
  matterId: undefined,
};
