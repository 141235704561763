import {
  SET_TASK_VIEW,
  TOGGLE_EXPANDED,
  TOGGLE_ASSIGNEE_FILTER_EXPANDED,
  TOGGLE_CATEGORY_FILTER_EXPANDED,
  TOGGLE_VIEW_EXPANDED,
  SET_SORT,
  TaskViewTypes,
  SET_ASSIGNEE_IDS,
  SET_CATEGORY_IDS,
  SET_SHOW_DELETED,
} from './types';

export const setTaskView = (payload: { taskView: TaskViewTypes }) => ({
  type: SET_TASK_VIEW,
  payload,
});
export const setShowDeleted = (payload: { showDeleted: boolean }) => ({
  type: SET_SHOW_DELETED,
  payload,
});
export const setAssigneeIds = (payload: { assigneeIds: string[] }) => ({
  type: SET_ASSIGNEE_IDS,
  payload,
});

export const setCategoryIds = (payload: { categoryIds: string[] }) => ({
  type: SET_CATEGORY_IDS,
  payload,
});

export const toggleExpanded = () => ({
  type: TOGGLE_EXPANDED,
  payload: {},
});
export const toggleViewExpanded = () => ({
  type: TOGGLE_VIEW_EXPANDED,
  payload: {},
});
export const toggleAssigneeFilterExpanded = () => ({
  type: TOGGLE_ASSIGNEE_FILTER_EXPANDED,
  payload: {},
});
export const toggleCategoryFilterExpanded = () => ({
  type: TOGGLE_CATEGORY_FILTER_EXPANDED,
  payload: {},
});

export const setSort = (payload: { sortBy: string; sortDirection: 'asc' | 'desc' }) => ({
  type: SET_SORT,
  payload,
});
