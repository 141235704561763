import uuid from '@sb-itops/uuid';
import * as messageDisplay from '@sb-itops/message-display';
import { t } from '@sb-itops/localisation-web';
import {
  getNumberingSettings as getTransactionNumberingSettings,
  saveNumberingSettingsP,
  getDefaultElectronicPaymentNumberingSettings,
} from '@sb-billing/redux/bank-account';
import {
  getSettings as getCMASettings,
  saveControlledMoneyAccountSettings,
} from '@sb-billing/redux/controlled-money-account-settings';
import {
  getById as getTrustReceiptSettingsById,
  getDefaultReceiptSettings,
} from '@sb-billing/redux/trust-receipt-settings';
import { getLogger } from '@sb-itops/fe-logger';
import * as unscopedForms2 from '@sb-itops/redux/forms2/feature';
import { getNumberSettingPrefixes } from './selectors';

import { controlledMoneyNumberingSettingsSchema, trustNumberingSettingsSchema } from './numbering-settings-schema';

const log = getLogger('transaction-numbering-settings');

export const initialiseTrustForm =
  ({ bankAccountId }) =>
  (dispatch, getState) => {
    // Get the relvant settings objects from the cache.
    const trustAccountReceiptSettings = getTrustReceiptSettingsById(bankAccountId) || getDefaultReceiptSettings();
    const { transferNumberingSettings, trustToOfficeNumberingSettings, electronicPaymentNumberingSettings } =
      getTransactionNumberingSettings({
        bankAccountId,
        defaultIfEmpty: true,
      });

    const prefixes = getNumberSettingPrefixes(getState(), { bankAccountId });

    // Massage cache settings into shape expected by a transaction numbering settings form.
    const receipt = {
      nextNumber: trustAccountReceiptSettings.initialReceiptNumber,
      leadingZeros: trustAccountReceiptSettings.padding,
      prefix: trustAccountReceiptSettings.prefix || prefixes.receipt || '',
    };

    const transferOfFunds = {
      useManualNumbering: !!transferNumberingSettings.useManualNumbering,
      nextNumber: transferNumberingSettings.initialNumber,
      leadingZeros: transferNumberingSettings.padding,
      prefix: transferNumberingSettings.prefix || prefixes.transferOfFunds || '',
    };

    const electronicPayments = {
      useManualNumbering: !!electronicPaymentNumberingSettings.useManualNumbering,
      nextNumber: electronicPaymentNumberingSettings.initialNumber,
      leadingZeros: electronicPaymentNumberingSettings.padding,
      prefix: electronicPaymentNumberingSettings.prefix || prefixes.electronicPayments || '',
    };

    const trustToOffice = {
      useManualNumbering: !!trustToOfficeNumberingSettings.useManualNumbering,
      nextNumber: trustToOfficeNumberingSettings.initialNumber,
      leadingZeros: trustToOfficeNumberingSettings.padding,
      prefix: trustToOfficeNumberingSettings.prefix || prefixes.trustToOffice || '',
    };

    const fieldValues = {
      receipt,
      transferOfFunds,
      electronicPayments,
      trustToOffice,
    };

    const initialiseFormAction = unscopedForms2.actions.initialiseForm({ fieldValues });
    dispatch(initialiseFormAction);
  };

export const updateTrustForm =
  ({ updatedFields }) =>
  (dispatch) => {
    // Update the fields in the redux store.
    dispatch(unscopedForms2.actions.updateFieldValues({ fieldValues: updatedFields }));

    // Validate new form values.
    dispatch(unscopedForms2.operations.validateForm({ schema: trustNumberingSettingsSchema }));
  };

export const saveTrustForm =
  ({ bankAccountId }) =>
  async (dispatch, getState) => {
    const submitFnP = () => {
      // Marshal the values from the form.
      const fieldValues = unscopedForms2.selectors.getFieldValues(getState());

      // Life would be way too easy if we just needed to marshal the values from the form to send to the endpoint.
      // There are two 'extra' pieces of data that we require for posting to the endpoint;
      // 1) A new version ID and the existing createPDFReceiptOnDeposit property - the .NET command for saving numbering settings
      //    updates the transferOfFunds and vendorPayment settings for the bank account entity, but updates the ReceiptSettings entity
      //    for receipt numbering. It's a mess, but the result is that we need to send all fields for the ReceiptSettings.

      // Add the prefix data.
      const newNumberingSettings = Object.entries(fieldValues).reduce((acc, [fieldName, fieldValue]) => {
        acc[fieldName] = {
          ...fieldValue,
          prefix: fieldValue.prefix,
        };
        return acc;
      }, {});

      // Add the extra special fields for the receipt settings.
      const trustReceiptSettings = getTrustReceiptSettingsById(bankAccountId) || getDefaultReceiptSettings();
      newNumberingSettings.receipt.versionId = uuid();
      newNumberingSettings.receipt.createPDFReceiptOnDeposit = trustReceiptSettings.createPDFReceiptOnDeposit;

      return saveNumberingSettingsP({
        bankAccountId,
        ...newNumberingSettings,
      });
    };

    try {
      await dispatch(unscopedForms2.operations.submitFormP({ submitFnP }));
      messageDisplay.success('Transaction numbering settings saved successfully');
    } catch (err) {
      messageDisplay.error('Failed to save transaction numbering settings');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

export const initialiseCMAForm =
  ({ bankAccountId }) =>
  (dispatch, getState) => {
    const CMASettings = getCMASettings();

    // Get the relevant settings objects from the cache.
    const trustAccountReceiptSettings = CMASettings?.receiptSettings || getDefaultReceiptSettings();
    const electronicPaymentNumberingSettings =
      CMASettings?.electronicPaymentNumberingSettings || getDefaultElectronicPaymentNumberingSettings();

    const prefixes = getNumberSettingPrefixes(getState(), { bankAccountId });

    // Massage cache settings into shape expected by a transaction numbering settings form.
    const receipt = {
      nextNumber: trustAccountReceiptSettings.initialReceiptNumber,
      leadingZeros: trustAccountReceiptSettings.padding,
      prefix: trustAccountReceiptSettings.prefix || prefixes.receipt || '',
    };

    const electronicPayments = {
      useManualNumbering: !!electronicPaymentNumberingSettings.useManualNumbering,
      nextNumber: electronicPaymentNumberingSettings.initialNumber,
      leadingZeros: electronicPaymentNumberingSettings.padding,
      prefix: electronicPaymentNumberingSettings.prefix || '',
    };

    const fieldValues = {
      receipt,
      electronicPayments,
    };

    const initialiseFormAction = unscopedForms2.actions.initialiseForm({ fieldValues });
    dispatch(initialiseFormAction);
  };

export const updateCMAForm =
  ({ updatedFields }) =>
  (dispatch) => {
    // Update the fields in the redux store.
    dispatch(unscopedForms2.actions.updateFieldValues({ fieldValues: updatedFields }));

    // Validate new form values.
    dispatch(unscopedForms2.operations.validateForm({ schema: controlledMoneyNumberingSettingsSchema }));
  };

export const saveCMAForm = () => async (dispatch, getState) => {
  const submitFnP = () => {
    // Marshal the values from the form.
    const fieldValues = unscopedForms2.selectors.getFieldValues(getState());

    const newNumberingSettings = Object.entries(fieldValues).reduce((acc, [fieldName, fieldValue]) => {
      acc[fieldName] = {
        ...fieldValue,
        prefix: fieldValue.prefix,
      };
      return acc;
    }, {});

    return saveControlledMoneyAccountSettings({
      receipt: {
        initialReceiptNumber: newNumberingSettings.receipt.nextNumber,
        padding: newNumberingSettings.receipt.leadingZeros,
        prefix: newNumberingSettings.receipt.prefix || '',
      },
      electronicPayments: {
        useManualNumbering: !!newNumberingSettings.electronicPayments.useManualNumbering,
        initialNumber: newNumberingSettings.electronicPayments.nextNumber,
        padding: newNumberingSettings.electronicPayments.leadingZeros,
        prefix: newNumberingSettings.electronicPayments.prefix || '',
      },
    });
  };

  try {
    await dispatch(unscopedForms2.operations.submitFormP({ submitFnP }));
    messageDisplay.success(`${t('controlledMoney')} settings saved successfully`);
  } catch (err) {
    log.error(`Failed to save ${t('controlledMoney')} setting`, err);
    messageDisplay.error(`Failed to save ${t('controlledMoney')} settings`);
  }
};
