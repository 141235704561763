'use strict';

const moment = require('moment');
const { today } = require('@sb-itops/date');
const { sortByProperty } = require('@sb-itops/nodash');
const { calculateInstallmentAmountDue } = require('./calculate-installment-amount-due');
const { determinePaymentMethodExpiry } = require('./determine-payment-method-expiry');
const { displayPaymentMethodStatus } = require('./display-payment-method-status');
const { hasInstallmentDueOnDate } = require('./has-installment-due-on-date');
const { paymentPlanPositionDisplayStatus } = require('../entities/constants');

/**
 * calculates payment status for specified date
 * @param {*} paymentPlan payment plan with installments, totalAmount and unpaidAmount
 * @param {*} [date=today] date to calculate payment plan position for, defaults to today
 * @returns { status, installmentAmountDue, paidAmount, aheadOrBehindAmount, isBehind }
 */
const calculatePaymentPlanPosition = ({ paymentPlan, expiry, paymentPlanEvents }, date = today()) => {
  const { installments, totalAmount, unpaidAmount, $optimistic } = paymentPlan;
  if ($optimistic) {
    // defaults for when opdating
    return {
      status: '--',
      isBehind: false,
      aheadOrBehindAmount: 0,
      installmentAmountDue: 0,
      paidAmount: 0,
      paymentMethod: {
        type: paymentPlan.autoCharge ? 'Credit Card' : 'Manual',
        status: '',
        statusDisplay: '',
      },
    };
  }
  if (!installments) {
    throw new Error('paymentPlan with installments is required');
  }

  if (!Number.isFinite(totalAmount)) {
    throw new Error('paymentPlan with totalAmount is required');
  }

  if (!Number.isFinite(unpaidAmount)) {
    throw new Error('paymentPlan with unpaidAmount is required');
  }

  let status;
  let isBehind;
  let aheadOrBehindAmount;

  const newInstallmentDueOnDate = hasInstallmentDueOnDate({ installments }, date);
  const installmentAmountDue = calculateInstallmentAmountDue({ installments }, date);
  const paidAmount = totalAmount - unpaidAmount;

  if (newInstallmentDueOnDate) {
    // Special scenario when new installment is due on the specified date
    // 1) $amount ahead is calculated with respect to new installment due
    // 2) $amount behind is calculated with respect to the last installment due
    // 3) any paid amount >= last installment due but <= new installment due is considered $0 ahead
    const dayBefore = moment(date).add(-1, 'days').toDate();
    const installmentDueDayBefore = calculateInstallmentAmountDue({ installments }, dayBefore);
    if (paidAmount > installmentAmountDue) {
      status = paymentPlanPositionDisplayStatus.AHEAD;
      isBehind = false;
      aheadOrBehindAmount = paidAmount - installmentAmountDue;
    } else if (paidAmount < installmentDueDayBefore) {
      status = paymentPlanPositionDisplayStatus.BEHIND;
      isBehind = true;
      aheadOrBehindAmount = paidAmount - installmentDueDayBefore;
    } else {
      status = paymentPlanPositionDisplayStatus.ON_TRACK;
      isBehind = false;
      aheadOrBehindAmount = 0;
    }
  } else {
    // Sunny day scenario
    aheadOrBehindAmount = paidAmount - installmentAmountDue;
    if (paidAmount > installmentAmountDue) {
      status = paymentPlanPositionDisplayStatus.AHEAD;
      isBehind = false;
    } else if (paidAmount < installmentAmountDue) {
      status = paymentPlanPositionDisplayStatus.BEHIND;
      isBehind = true;
    } else {
      status = paymentPlanPositionDisplayStatus.ON_TRACK;
      isBehind = false;
    }
  }

  let paymentMethodStatus = '';
  if (paymentPlan.autoCharge) {
    const paymentMethodExpiry = determinePaymentMethodExpiry({ expiry });
    if (paymentMethodExpiry) {
      paymentMethodStatus = paymentMethodExpiry;
    }

    sortByProperty(paymentPlanEvents || [], 'timestamp', 'desc').some((event) => {
      // If the last payment failed, show a warning
      if (event.type === 'INVOICE_PAYMENT') {
        return true;
      }
      if (event.type === 'INVOICE_PAYMENT_FAILED') {
        paymentMethodStatus = 'failed';
        return true;
      }
      return false;
    });
  }

  return {
    status,
    paymentMethod: {
      type: paymentPlan.autoCharge ? 'Credit Card' : 'Manual',
      status: paymentMethodStatus,
      statusDisplay: displayPaymentMethodStatus(moment(expiry, 'MM/YYYY').format('MM/YYYY'))[paymentMethodStatus] || '',
    },
    installmentAmountDue,
    paidAmount,
    aheadOrBehindAmount,
    isBehind,
  };
};

module.exports = {
  calculatePaymentPlanPosition,
};
