import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { dateRangeTypes } from '@sb-itops/date';
import { featureActive } from '@sb-itops/feature';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { debounce, capitalize } from '@sb-itops/nodash';
import * as panelExpanderFeature from '@sb-itops/redux/panel-expander';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature, usePaginatedMultipleItemSelection } from '@sb-itops/redux/hooks';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { useDateRangeSelect } from '@sb-itops/redux/date-range-select/use-date-range-select';
import {
  MatterStatusCounts,
  RateSetsMatterTableData,
  MatterTypesFilter,
  StaffMembersList,
  RateSetOptions,
  ContactOptions,
} from 'web/graphql/queries';
import { usePagination, useSubscribedQuery, useSubscribedLazyQuery } from 'web/hooks';
import { useTranslation } from '@sb-itops/react';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { FirmRateSets } from './FirmRateSets';

const FETCH_LIMIT = 100;
const SCOPE = 'FirmRateSets';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_SORTING = `${SCOPE}/sorting`;
const SCOPE_FILTER_STATUS = `${SCOPE}/statusFilter`;
const SCOPE_FILTER_STAFF = `${SCOPE}/staffFilter`;
const SCOPE_FILTER_MATTER_TYPE = `${SCOPE}/matterType`;
const SCOPE_FILTER_CLIENT = `${SCOPE}/clientFilter`;
const SCOPE_FILTER_RATE_SET = `${SCOPE}/rateSetFilter`;
const SCOPE_FILTER_DATE_OPENED = `${SCOPE}/dateOpenedFilter`;

const hooks = () => ({
  useFilterTabs: () => {
    const { selectors, actions } = useScopedFeature(panelExpanderFeature, `${SCOPE}/mattersRoutePanel`);
    const expanded = useSelector(selectors.getExpanded);
    const dispatch = useDispatch();

    return {
      expanded,
      toggleExpanded: () => {
        dispatch(actions.toggleExpanded());
      },
    };
  },
  useMatterStatusFilter: () => {
    const statusFilter = useRef(false);
    const {
      selectedItems: mattersSelectedStatus,
      toggleItem: mattersOnToggleStatus,
      selectItems: mattersOnSelectStatus,
      deselectAllItems: mattersOnDeselectAllStatus,
    } = useMultipleItemSelection({
      scope: SCOPE_FILTER_STATUS,
    });

    if (!statusFilter.current) {
      mattersOnSelectStatus(['Open']);
      statusFilter.current = true;
    }

    const mattersSelectedStatusIds = Object.keys(mattersSelectedStatus).map((status) => status.toLowerCase());

    return {
      mattersSelectedStatusIds,
      mattersOnToggleStatus,
      mattersOnSelectStatus,
      mattersOnDeselectAllStatus,
    };
  },
  useStaffMemberFilter: () => {
    const {
      selectedItems: staffSelectedIds,
      toggleItem: staffOnToggle,
      selectItems: staffOnSelect,
      deselectAllItems: staffOnDeselectAll,
    } = useMultipleItemSelection({
      scope: SCOPE_FILTER_STAFF,
    });

    return { staffSelectedIds, staffOnToggle, staffOnSelect, staffOnDeselectAll };
  },
  useMatterTypeSelection: () => {
    const { selectedItems, selectItems: matterTypesOnSelect } = useMultipleItemSelection({
      scope: SCOPE_FILTER_MATTER_TYPE,
    });

    return { matterTypeSelectedIds: Object.keys(selectedItems), matterTypesOnSelect };
  },
  useClientFilter: () => {
    const { selectedItems, selectItems: clientsOnSelect } = useMultipleItemSelection({
      scope: SCOPE_FILTER_CLIENT,
    });

    const clientFilter = useRef(false);
    if (!clientFilter.current && Object.keys(selectedItems).length === 0) {
      clientsOnSelect([]);
      clientFilter.current = true;
    }

    return { clientSelectedIds: Object.keys(selectedItems), clientsOnSelect };
  },
  useRateSetFilter: () => {
    const { selectedItems, selectItems: rateSetsOnSelect } = useMultipleItemSelection({
      scope: SCOPE_FILTER_RATE_SET,
    });

    const rateSetFilter = useRef(false);
    if (!rateSetFilter.current && Object.keys(selectedItems).length === 0) {
      rateSetsOnSelect([]);
      rateSetFilter.current = true;
    }

    return { rateSetSelectedIds: Object.keys(selectedItems), rateSetsOnSelect };
  },
  useDateOpenedFilter: () => {
    const { fromDate, toDate, dateRangeType, selectDateRangeType, selectFromDate, selectToDate } = useDateRangeSelect({
      scope: SCOPE_FILTER_DATE_OPENED,
    });

    const onDateListChange = (dateFilter) => {
      const { filterType: dateOpenedFilterType, from, to } = dateFilter;
      selectDateRangeType({ dateRangeType: dateOpenedFilterType });
      if (dateOpenedFilterType !== dateRangeTypes.ALL) {
        selectFromDate({ from });
        selectToDate({ to });
      }
    };

    return { dateRangeType, fromDate, toDate, onDateListChange };
  },
  useRateSetBulkApplyModal: () => {
    const [isRateSetModalVisible, setIsRateSetModalVisible] = useState(false);

    return { isRateSetModalVisible, setIsRateSetModalVisible };
  },
  usePagination: () => {
    const {
      currentPage: mattersCurrentPage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: SCOPE_PAGINATION,
      fetchLimit: FETCH_LIMIT,
    });

    const mattersOnPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    return { mattersCurrentPage, mattersOnPageChange, getPagination };
  },
  useSorting: () => {
    const {
      sortBy: mattersSortBy,
      setSortBy,
      sortDirection: mattersSortDirection,
      setSortDirection,
    } = useSort({
      scope: SCOPE_SORTING,
      initialSortBy: featureActive('BB-14072') ? 'matterNumber' : 'clientDisplayName',
      initialSortDirection: featureActive('BB-14072') ? 'desc' : 'asc',
    });

    const mattersOnSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return { mattersOnSort, mattersSortBy, mattersSortDirection };
  },
  useScopes: () => ({
    matterFilterScope: `${SCOPE}/matterFilter`,
  }),
  useProps: () => {
    const { t } = useTranslation();
    return {
      excludeFormerStaff: true,
      excludeStaffWithoutMatter: true,
      includeDeleted: true,
      mattersStatusAllLabel: 'All',
      mattersStatusOptions: [
        { id: 'open', label: 'Open' },
        { id: 'pending', label: 'Pending' },
        { id: 'closed', label: 'Closed' },
        { id: 'deleted', label: 'Deleted' },
        { id: 'cancelled', label: capitalize(t('cancelled')) },
      ],
    };
  },
});

const queryHooks = ({
  excludeFormerStaff,
  excludeStaffWithoutMatter,
  getPagination,
  matterTypeSelectedIds,
  includeDeleted,
  mattersCurrentPage,
  mattersSelectedStatusIds,
  mattersSortBy,
  mattersSortDirection,
  mattersStatusOptions,
  staffSelectedIds,
  clientSelectedIds,
  rateSetSelectedIds,
  dateRangeType,
  fromDate,
  toDate,
}) => ({
  useMatterStatusFilterData: () => {
    const { data, error } = useSubscribedQuery(MatterStatusCounts, {
      variables: {
        filter: {
          matterStatus: mattersStatusOptions.map(({ id }) => id),
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const matterStatusCounts = (data?.matterStatusCounts || []).reduce((acc, { status, count }) => {
      acc[status] = count;
      return acc;
    }, {});

    return { matterStatusCounts };
  },
  useStaffMemberFilterData: () => {
    const { data, error } = useSubscribedQuery(
      StaffMembersList,
      {
        variables: { filter: { excludeFormerStaff, excludeStaffWithoutMatter } },
      },
      {
        notificationIds: [...StaffMembersList.notificationIds, 'MatterManagementWebQuery.MatterUpdated'],
      },
    );

    if (error) {
      throw new Error(error);
    }

    const staffMembers = data?.staffMembersList?.results || [];

    return { staffMembers };
  },
  useMatterTypesFilterData: () => {
    const matterTypes = useRef();
    const { data, error, loading } = useSubscribedQuery(MatterTypesFilter, {
      variables: {
        filter: {
          excludeMatterTypeWithoutMatter: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    if (!loading && data?.matterTypeList?.results) {
      matterTypes.current = data?.matterTypeList?.results;
    }

    return { matterTypes: matterTypes.current };
  },
  useClientsFilterData: () => {
    const [getClientOptions, clientOptionsResult] = useSubscribedLazyQuery(ContactOptions, {
      context: { skipRequestBatching: true },
      variables: {
        filter: {
          includeRoles: ['CLIENT'],
        },
        limit: 25,
        sort: {
          fieldNames: ['displayName'],
          directions: ['ASC'],
        },
      },
    });

    const results = clientOptionsResult.data?.contactSearch?.results;

    const clientOptions = useMemo(() => {
      const options = !results?.length
        ? []
        : results.map((contact) => ({
            value: contact.id,
            label: contact.displayName,
          }));

      return options;
    }, [results]);

    const getClientOptionsBySearchText = debounce(
      (searchText = '') => {
        getClientOptions({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchClientOptions = (searchText = '') => {
      // When the select component loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getClientOptionsBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreClientOptions = async () => {
      if (!clientOptionsResult.data?.contactSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await clientOptionsResult.fetchMore({
        variables: {
          offset: clientOptionsResult.data.contactSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      clientOptions,
      clientOptionsDataLoading: clientOptionsResult.loading,
      clientOptionsHasMore: clientOptionsResult.data?.contactSearch?.pageInfo?.hasNextPage || false,
      onFetchClientOptions,
      onFetchMoreClientOptions,
    };
  },
  useRateSetsFilterData: () => {
    const {
      data,
      error,
      loading: rateSetsLoading,
    } = useSubscribedQuery(RateSetOptions, {
      variables: {
        rateSetListFilter: {
          includeHidden: false,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const rateSetOptions = useMemo(() => {
      const options = (data?.rateSetList?.results || []).map(({ name: label, id: value }) => ({
        value,
        label,
        searchText: label.toLowerCase(),
      }));

      const noRateSetsOption = { value: 'none', label: 'No Rate Sets', searchText: 'no rate sets' };
      return [noRateSetsOption, ...options];
    }, [data?.rateSetList?.results]);

    return { rateSetsLoading, rateSetOptions };
  },
  useRateSetsMatterTableData: () => {
    const skipMattersQuery = mattersSelectedStatusIds.length === 0;

    const {
      data,
      error,
      loading: mattersLoading,
    } = useSubscribedQuery(RateSetsMatterTableData, {
      context: { skipRequestBatching: true },
      skip: skipMattersQuery,
      variables: {
        matterListFilter: {
          includeDeleted,
          matterStatus: mattersSelectedStatusIds,
          matterTypes: matterTypeSelectedIds,
          staffMembersIds: Object.keys(staffSelectedIds),
          clientIds: clientSelectedIds,
          rateSetIds: rateSetSelectedIds,
          ...(dateRangeType !== dateRangeTypes.ALL && {
            matterOpenedDate: {
              from: fromDate,
              to: toDate,
            },
          }),
        },
        offset: mattersCurrentPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !mattersSortBy
          ? undefined
          : { fieldNames: [mattersSortBy], directions: [`${mattersSortDirection || 'ASC'}`.toUpperCase()] },
        includeIsOverdrawn: hasFacet(facets.allowOverdraw),
      },
    });

    if (error) {
      throw new Error(error);
    }

    const matters = useMemo(() => data?.matterList?.results || [], [data?.matterList?.results]);
    const mattersTotalCount = data?.matterList?.totalCount || 0;
    const currentPageMatterIds = useMemo(() => matters.reduce((acc, matter) => [...acc, matter.id], []), [matters]);

    const { hidePagination: isPaginationHidden, totalNumberOfPages: mattersTotalNumberOfPages } = getPagination({
      totalCount: mattersTotalCount,
      loading: mattersLoading,
    });

    return {
      currentPageMatterIds,
      mattersLoading,
      matters,
      isPaginationHidden,
      mattersTotalCount,
      mattersTotalNumberOfPages,
    };
  },
});

export const FirmRateSetsContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(
      composeHooks(queryHooks)((props) => {
        const {
          matters,
          currentPageMatterIds,
          mattersCurrentPage,
          mattersSelectedStatusIds,
          matterTypeSelectedIds,
          staffSelectedIds,
          clientSelectedIds,
          rateSetSelectedIds,
          dateRangeType,
          fromDate,
          toDate,
          // functions & callbacks
          setIsRateSetModalVisible,
        } = props;

        const {
          isAllItemsSelected: isAllMattersMatchingFiltersSelected,
          selectedItems: selectedMatterIds,
          selectedPages,
          deselectAll: deselectAllMatters,
          selectAll: selectAllMatters,
          toggleSelectAllOnCurrentPage: toggleSelectAllMattersOnCurrentPage,
          toggleSelectItems: toggleSelectMatters,
        } = usePaginatedMultipleItemSelection({
          scope: SCOPE,
          currentPageItems: matters,
          currentPage: mattersCurrentPage,
        });

        const [selectedBulkApplyRateSetOption, setSelectedBulkApplyRateSetOption] = useState(undefined);
        const [isSubmitting, setIsSubmitting] = useState(false);

        const onRateSetBulkApplyOptionSelect = (rateSet) => {
          setSelectedBulkApplyRateSetOption(rateSet);
        };

        const onRateSetBulkApplySave = async () => {
          try {
            setIsSubmitting(true);
            const matterIdsForOpdating = Array.from(
              new Set([...Object.keys(selectedMatterIds), ...currentPageMatterIds]),
            );
            const bulkApplyRateSetFields = {
              filters: {
                matterStatus: mattersSelectedStatusIds,
                matterTypes: matterTypeSelectedIds,
                staffMembersIds: Object.keys(staffSelectedIds),
                clientIds: clientSelectedIds[0] === 'all' ? [] : clientSelectedIds,
                rateSetIds: rateSetSelectedIds[0] === 'any' ? [] : rateSetSelectedIds,
                ...(dateRangeType !== dateRangeTypes.ALL && {
                  matterOpenedDate: {
                    from: fromDate,
                    to: toDate,
                  },
                }),
              },
              applyToAllMattersMatchingFilters: isAllMattersMatchingFiltersSelected,
              // Send matterIdsForOpdating when applyToAllMattersMatchingFilters is true for GraphQL cache opdate
              matterIds: isAllMattersMatchingFiltersSelected ? matterIdsForOpdating : Object.keys(selectedMatterIds),
              rateSetId: selectedBulkApplyRateSetOption?.value,
              // Send rateSetName for GraphQL cache opdate
              rateSetName: selectedBulkApplyRateSetOption?.label,
            };

            await dispatchCommand({ type: 'Integration.BulkApplyRateSetToMatters', message: bulkApplyRateSetFields });

            setIsRateSetModalVisible(false);
            setSelectedBulkApplyRateSetOption(undefined);
            displaySuccessToUser('Rate set applied successfully');
            deselectAllMatters(); // when rate set applied successfully, clear selected matters
          } catch (err) {
            displayErrorToUser('Failed to apply rate set. Please try again later');
          } finally {
            setIsSubmitting(false);
          }
        };

        return (
          <FirmRateSets
            {...props}
            selectedBulkApplyRateSetOption={selectedBulkApplyRateSetOption}
            isSubmitting={isSubmitting}
            onRateSetBulkApplyOptionSelect={onRateSetBulkApplyOptionSelect}
            onRateSetBulkApplySave={onRateSetBulkApplySave}
            isAllMattersMatchingFiltersSelected={isAllMattersMatchingFiltersSelected}
            selectedMatterIds={selectedMatterIds}
            selectedPages={selectedPages}
            onClearSelection={deselectAllMatters}
            onSelectAllMattersMatchingFilters={selectAllMatters}
            onToggleSelectAllMattersOnPage={toggleSelectAllMattersOnCurrentPage}
            onToggleSelectMatters={toggleSelectMatters}
          />
        );
      }),
    ),
  ),
);

FirmRateSetsContainer.displayName = 'FirmRateSetsContainer';

FirmRateSetsContainer.propTypes = {
  onClickLink: PropTypes.func,
};

FirmRateSetsContainer.defaultProps = {
  onClickLink: () => {},
};
