/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PanelExpander } from '@sb-itops/react';
import { TrustAccountsForFilterPanels } from '@sb-billing/react/trust-accounts-filter-panel';

const DepositSlipsFilterPanel = memo(
  ({
    onClickLink,
    accountType,
    expanded,
    toggleExpanded,
    showOperatingAccount,
    trustAccounts,
    selectedTrustAccountId,
    panelHeader,
    trustAccountsFilterPanelConfig,
  }) => (
    <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
      <div className="side-menu-block">
        {/* <!-- US has access to both Trust & Operating Deposit Slips -->
          <!-- AU has access to only AU Deposit Slips --> */}
        <h3>{panelHeader}</h3>
        <ul className="side-menu-list capitalize">
          {!!trustAccounts?.length && (
            <TrustAccountsForFilterPanels
              trustAccounts={trustAccounts}
              onClickLink={onClickLink}
              selectedTrustAccountId={selectedTrustAccountId}
              selectedAccountType={accountType}
              {...trustAccountsFilterPanelConfig}
            />
          )}
          {showOperatingAccount && (
            <li
              onClick={() => onClickLink({ id: 'operating', type: 'depositSlips' })}
              className={classnames({ active: accountType === 'OPERATING' })}
            >
              Operating Retainer
            </li>
          )}
        </ul>
      </div>
    </PanelExpander>
  ),
);

DepositSlipsFilterPanel.displayName = 'DepositSlipsFilterPanel';

DepositSlipsFilterPanel.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  showOperatingAccount: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  panelHeader: PropTypes.string.isRequired,
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedTrustAccountId: PropTypes.string,
  trustAccountsFilterPanelConfig: PropTypes.shape({
    showProtectedFunds: PropTypes.bool.isRequired,
    overviewOnClicklinkParams: PropTypes.object.isRequired,
    getSpecificOnClickLinkParams: PropTypes.func.isRequired,
  }).isRequired,
};

DepositSlipsFilterPanel.defaultProps = {
  accountType: undefined,
  selectedTrustAccountId: undefined,
};

export default DepositSlipsFilterPanel;
