import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation } from '@sb-itops/react';
import { PaymentAddForm } from '../payment-add-form';
import Styles from './PaymentAddModal.module.scss';
import { ADDING_PAYMENT_STEP, TAKING_PAYMENT_STEP } from '../payment-add-form/PaymentAddForm';

export const PaymentAddModal = React.memo(
  ({
    scope,
    isSubmitDisabled,
    isSubmitLocked,
    onSave,
    onClose,
    onChargeFormSubmit,
    onChargeFormReadyForSubmit,
    onChargeFormDataChange,
    triggerChargeFormSubmit,
    isTakingPaymentNow,
    isVisible,
    currentStep,
    contactId,
    matterId,
    printCheques,
    errors,
    totalChargeAmount,
    clientIsCoveringFee,
  }) => {
    const { t } = useTranslation();

    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.addPaymentModal}
        isVisible={isVisible}
        title="Add a Payment"
        body={
          <PaymentAddForm
            contactId={contactId}
            matterId={matterId}
            printCheques={printCheques}
            scope={scope}
            currentStep={currentStep}
            onChargeFormSubmit={onChargeFormSubmit}
            onChargeFormReadyForSubmit={onChargeFormReadyForSubmit}
            onChargeFormDataChange={onChargeFormDataChange}
            triggerChargeFormSubmit={triggerChargeFormSubmit}
          />
        }
        footer={
          <div>
            {!!errors?.length && (
              <div className={Styles.errorContainer}>
                {errors.map((err) =>
                  typeof err === 'string' ? (
                    <div key={err} className={Styles.errorRow}>
                      {err}
                    </div>
                  ) : null,
                )}
              </div>
            )}
            {clientIsCoveringFee && currentStep.id === TAKING_PAYMENT_STEP.id && totalChargeAmount > 0 && (
              <div className={Styles.totalChargeContainer}>
                Total charge <span className={Styles.amount}>{t('cents', { val: totalChargeAmount })}</span>
              </div>
            )}
            <div className={Styles.buttonContainer}>
              <Button
                className={Styles.processButton}
                onClick={onSave}
                disabled={isSubmitDisabled}
                form={scope}
                locked={isSubmitLocked}
                size="full-width"
              >
                {getButtonLabel({ currentStep, isTakingPaymentNow })}
              </Button>
            </div>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

function getButtonLabel({ currentStep, isTakingPaymentNow }) {
  if (isTakingPaymentNow && currentStep.id === ADDING_PAYMENT_STEP.id) {
    return 'Enter Payment Details';
  }

  return 'Process';
}

PaymentAddModal.displayName = 'PaymentAddModal';

PaymentAddModal.propTypes = {
  currentStep: PropTypes.object.isRequired,
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  errors: PropTypes.array,
  printCheques: PropTypes.func.isRequired,

  isTakingPaymentNow: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onChargeFormSubmit: PropTypes.func,
  onChargeFormReadyForSubmit: PropTypes.func,
  onChargeFormDataChange: PropTypes.func.isRequired,
  triggerChargeFormSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isSubmitLocked: PropTypes.bool,
  totalChargeAmount: PropTypes.number,
  clientIsCoveringFee: PropTypes.bool,
};

PaymentAddModal.defaultProps = {
  matterId: undefined,
  contactId: undefined,
  isSubmitDisabled: false,
  isSubmitLocked: false,
  isTakingPaymentNow: false,
  errors: [],
  onChargeFormSubmit: () => {},
  onChargeFormReadyForSubmit: () => {},
  triggerChargeFormSubmit: false,
  totalChargeAmount: 0,
  clientIsCoveringFee: false,
};
