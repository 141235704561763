import { getActiveTrustAccounts } from "@sb-billing/redux/bank-account";
import { store } from '@sb-itops/redux';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { getRememberedParams } from "web/redux/features/transactions-recent-account-selection/selectors";

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/bank-reconciliations';
  const PARENT_PAGE_NAME = 'home.billing.accounts.bank-reconciliations.trust';

  $stateProvider
    .state('home.billing.accounts.bank-reconciliations', {
      url: '/bank-reconciliations',
      templateUrl: BASE_DIRECTORY + '/bank-reconciliations.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation'],
        accountType: 'TRUST'
      }
    })
    .state('home.billing.accounts.bank-reconciliations.trust', {
      url: '/trust',
      templateUrl: BASE_DIRECTORY + '/bank-reconciliations.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation', 'trust'],
        accountType: 'TRUST'
      },
      onEnter: function($timeout, $state) {
        // If entering trust overview and not from a child page of transactions
        // Load the previously remembered page if there is one
        // $state.current.name is the state we are coming from, $stateParams is the params of the state we are going to
        if (!$state.current.name.includes(PARENT_PAGE_NAME)) {
          const rememberedAccountParams = getRememberedParams(store.getState()['web/transactions-recent-account-selection'], { pageName: PARENT_PAGE_NAME });
          if (rememberedAccountParams) {
            $timeout(() => {
              $state.go(...rememberedAccountParams);
            })
          } else {
            // If account only has one trust account, go straight to the account page
            if (getActiveTrustAccounts().length === 1) {
              $timeout(function () {
                $state.go('home.billing.accounts.bank-reconciliations.trust.account', { trustAccountId: getActiveTrustAccounts()[0].id });
              })
            }
          }
        }
      },
    })
    .state('home.billing.accounts.bank-reconciliations.trust.account', {
      url: '/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/bank-reconciliations.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation', 'trust'],
        accountType: 'TRUST'
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.bank-reconciliations.trust.account', { ...$stateParams }], parentPageName: PARENT_PAGE_NAME }));
      }
    });
});
