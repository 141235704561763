import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query RateSetTableData($rateSetListFilter: RateSetListFilter, $sort: ListSort) {
    rateSetList(filter: $rateSetListFilter, sort: $sort) {
      totalCount
      results {
        id
        name
        isHidden
        versions {
          versionId
          validFrom
          validTo
          ratesPerStaff {
            staffId
            rate
          }
        }
      }
    }
  }
`;

const notificationIds = ['WebQueryFeesNotifications.RateSetUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const RateSetTableData = {
  query,
  notificationIds,
};
