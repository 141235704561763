'use strict';

const { periodOption } = require('../entities');

const validateDateRange = ({ periodSelected, startDate, endDate }) => {
  if (periodSelected.value === periodOption.ALL_TIME.value) {
    return {
      startDate: false,
      endDate: false,
    };
  }

  const endHasError = !endDate || (endDate && startDate ? endDate.getTime() < startDate.getTime() : false);
  const startHasError = !startDate || (endDate && startDate ? startDate.getTime() > endDate.getTime() : false);

  return {
    startDate: startHasError,
    endDate: endHasError,
  };
};

module.exports = {
  validateDateRange,
};
