import { TMappedRoles } from './map-role';

export type TMappedContact = ReturnType<typeof mapContact>;
export const mapContact = (c) => ({
  id: c.id,
  // Not supplied by our api
  // versionId: string,
  type: c.type,
  person:
    c.type === 'person'
      ? {
          firstName: c.firstName,
          lastName: c.lastName,
          phone: { number: c.phone },
          cell: { number: c.cell },
          email: c.email,
          residentialAddress: {
            city: c.residentialAddress?.city,
            state: c.residentialAddress?.state,
            zipCode: c.residentialAddress?.zipCode,
            country: c.residentialAddress?.country,
            // These are in the API but need to be mapped properly
            // county: ,
            // buildingLevel: ,
            // unitNumber: ,
            // unitType: ,
            // streetNumber: ,
            // streetName: ,
            // careOf: ,
            // poBoxType: ,
            // poxBoxNumber: ,
          },
          // title?: string,
          // middleName: ,
          // gender: ,
          // fax?: Phone;
          // notes: ,
          // birthdate?: Date;
          // birthPlace: ,
          // birthState: ,
          // birthCountry: ,
          // birthFirstName: ,
          // birthMiddleName: ,
          // birthLastName: ,
          // previousNames: ,
          // languageOfInterpreter: ,
        }
      : undefined,
  company:
    c.type !== 'person'
      ? {
          name: c.displayName,
          type: c.type,
          phone: { number: c.phone },
          // fax?: Phone;
          email: c.email,
          // notes: string,
          // abn: string,
          // companyNumberType: string,
          // companyNumber: string,
          businessAddress: {
            city: c.businessAddress?.city,
            state: c.businessAddress?.state,
            zipCode: c.businessAddress?.zipCode,
            country: c.businessAddress?.country,
            // These are in the API but need to be mapped properly
            // county: ,
            // buildingLevel: ,
            // unitNumber: ,
            // unitType: ,
            // streetNumber: ,
            // streetName: ,
            // careOf: ,
            // poBoxType: ,
            // poxBoxNumber: ,
            // dxAddress {
            //   number: string,
            //   exchange: string,
            //   state: string,
            // }
          },
          mailingAddress: {
            city: c.mailingAddress?.city,
            state: c.mailingAddress?.state,
            zipCode: c.mailingAddress?.zipCode,
            country: c.mailingAddress?.country,
            // These are in the API but need to be mapped properly
            // county: ,
            // buildingLevel: ,
            // unitNumber: ,
            // unitType: ,
            // streetNumber: ,
            // streetName: ,
            // careOf: ,
            // poBoxType: ,
            // poxBoxNumber: ,
          },
          // staffIds?: string[];
          // directorIds?: string[];
        }
      : undefined,
  lastUpdated: c.lastUpdated,
  isDeleted: c.removed,
});

/*

  accoring to .net code comm role looks like:

    public string ExternalRef { get; set; }
    public string Email { get; set; }
    public string FirstName { get; set; }
    public string LastName { get; set; }
    public string Initials { get; set; }
    public string RoleDisplay { get; set; }
    public string SmsPhoneNumber { get; set; }

*/

type TCommRole = {
  externalRef: string;
  email: string;
  firstName: string;
  lastName: string;
  initials: string;
  roleDisplay: string;
  smsPhoneNumber?: string;
};

export const mapContactToCommunicateRole = (contact: TMappedContact, roles: TMappedRoles): TCommRole => {
  const findRoleDisplay = (contactId: string): string => {
    const found = roles.find((r) => r.contactId === contactId);

    return found?.name || '';
  };

  if (contact.person) {
    return {
      externalRef: contact.id,
      email: contact.person.email,
      firstName: contact.person.firstName,
      lastName: contact.person.lastName,
      initials: '', // I could not find this in the contact entity and it works without it
      roleDisplay: findRoleDisplay(contact.id),
      smsPhoneNumber: contact.person.cell.number,
    };
  }
  if (contact.company) {
    return {
      externalRef: `smokeball:contact:${contact.id}`,
      email: contact.company.email,
      firstName: contact.company.name,
      lastName: '',
      initials: '', // I could not find this in the contact entity and it works without it
      roleDisplay: findRoleDisplay(contact.id),
    };
  }

  throw new Error(`don't know how to convert contact ${JSON.stringify(contact)}`);
};
