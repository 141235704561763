import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/payment-plan-events';

angular
  .module('@sb-billing/services')
  .service('sbPaymentPlanEventsService', function(sbGenericCacheService, sbEndpointType) {
    const endpoint = 'billing/payment-plan-event';

    sbGenericCacheService.setupCache({
      name: 'sbPaymentPlanEventsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
        poll: 60,
        subscriptions: 'notifier.DebtorsNotifications.PaymentPlanEventUpdated',
      },
      updateRedux,
      clearRedux,
    });
  });
