import * as React from 'react';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import {
  getDefaultCreditAccount,
  getActiveControlledMoneyAccounts,
  getOperatingAccount,
  getActiveTrustAccounts,
} from '@sb-billing/redux/bank-account';
import { getMap as getBankAccountBalanceMap } from '@sb-billing/redux/bank-account-balances';
import { getInvoiceSummariesByFilter } from '@sb-billing/redux/invoices';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import PropTypes from 'prop-types';
import { getList as getMatterTotalsList } from '@sb-billing/redux/matter-totals';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import * as billsListFilters from 'web/redux/route/home-billing-bills-list';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { rememberAccountStateParams } from 'web/redux/features/transactions-recent-account-selection/actions';
import { FinancialSummaryByFirm } from './FinancialSummaryByFirm';
import { TrustTooltipSummary } from './TrustTooltipSummary';

const { getFirmBalance, getBankAccountBalanceById } = selectors;

const mapStateToProps = () => {
  const creditBankAccountId = getDefaultCreditAccount()?.id;
  const operatingBankAccountId = getOperatingAccount().id;
  const trustAccounts = getActiveTrustAccounts();

  const firmTotals = getMatterTotalsList().reduce(
    (totals, matterTotals) => ({
      unpaidExcInterest: totals.unpaidExcInterest + matterTotals.unpaidExcInterest,
      unbilled: totals.unbilled + matterTotals.unbilled,
    }),
    { unpaidExcInterest: 0, unbilled: 0 },
  );

  const controlledMoniesAccounts = getActiveControlledMoneyAccounts();

  const showControlledMoneyAccount =
    featureActive('BB-6381') && hasFacet(facets.CMA) && !!controlledMoniesAccounts.length;

  // sum balance from all CMAs
  const controlledMoneyBalance =
    showControlledMoneyAccount &&
    controlledMoniesAccounts.reduce(
      (acc, item) =>
        acc + (getBankAccountBalanceById(getBankAccountBalanceMap(), { bankAccountId: item.id })?.balance || 0),
      0,
    );

  const trustBalance = trustAccounts.reduce(
    (acc, trust) => {
      acc.total +=
        getFirmBalance(getBankAccountBalanceMap(), {
          bankAccountId: trust.id,
          balanceType: balanceTypes.BALANCE,
        }) || 0;

      acc.protected +=
        getFirmBalance(getBankAccountBalanceMap(), {
          bankAccountId: trust.id,
          balanceType: balanceTypes.PROTECTED,
        }) || 0;

      acc.available +=
        getFirmBalance(getBankAccountBalanceMap(), {
          bankAccountId: trust.id,
          balanceType: balanceTypes.AVAILABLE,
        }) || 0;

      return acc;
    },
    {
      total: 0,
      protected: 0,
      available: 0,
    },
  );
  const trustTooltip = featureActive('BB-8671') ? <TrustTooltipSummary totals={trustBalance} /> : undefined;

  return {
    totals: {
      CREDIT: creditBankAccountId
        ? getFirmBalance(getBankAccountBalanceMap(), { bankAccountId: creditBankAccountId })
        : 0,
      TRUST: trustBalance.total,
      CONTROLLEDMONEY: controlledMoneyBalance || 0,
      OPERATING: getFirmBalance(getBankAccountBalanceMap(), { bankAccountId: operatingBankAccountId }),
      UNBILLED: firmTotals.unbilled,
      UNPAID: firmTotals.unpaidExcInterest,
      OVERDUE: getInvoiceSummariesByFilter({ overdueOnly: true }).reduce((total, v) => {
        const totals = getTotalsForInvoiceId(v.invoiceId);
        return totals ? totals.unpaidExcInterest + total : total;
      }, 0),
    },
    showControlledMoneyAccount,
    trustTooltip,
    showTrustAccount: !!trustAccounts.length,
  };
};

const mapDispatchToProps = (dispatch, { onClickLink }) => ({
  onFilterByRecent: (value) => {
    dispatch(billsListFilters.actions.onFilterByRecent(value));
  },
  onNavigateToTrustOverview: () => {
    dispatch(
      rememberAccountStateParams({
        stateGoParams: undefined,
        parentPageName: 'home.billing.accounts.transactions',
      }),
    );
    onClickLink({ type: 'transactions', id: 'trust', params: ['all'] });
  },
  onFilterInvoiceStatus: (status) => {
    dispatch(billsListFilters.actions.onFilterInvoiceStatus(status));
  },
});

const FinancialSummaryByFirmContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(FinancialSummaryByFirm),
);

FinancialSummaryByFirmContainer.displayName = 'FinancialSummaryByFirmContainer';

FinancialSummaryByFirmContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};
FinancialSummaryByFirmContainer.defaultProps = {};

export default FinancialSummaryByFirmContainer;
