angular.module('sb.billing.webapp').service('focusService', function ($rootScope, $timeout, sbLoggerService, sbReactHelpers) {
  'use strict';
  var that = this,
    log = sbLoggerService.getLogger('focusService');

  //log.setLogLevel('info');

  that._elementLookup = {};

  that.register = function (focusKey, element) {
    log.debug('register, focusKey', focusKey, 'element', element);

    if (validKey(focusKey) && !_.isEmpty(element)) {
      if (_.isEmpty(that._elementLookup[focusKey])){
        log.info('register', focusKey);
        that._elementLookup[focusKey] = {};
      }

      if (_.isEmpty(that._elementLookup[focusKey].elements)) {
        that._elementLookup[focusKey].elements = [];
      }
      that._elementLookup[focusKey].elements.push(element);
    }
  };

  that.unregister = function (focusKey, element) {
    log.debug('unregister, focusKey', focusKey, 'element', element);

    if ( validKey(focusKey) && !_.isEmpty(that._elementLookup[focusKey]) && !_.isEmpty(that._elementLookup[focusKey].elements)) {
      _.remove(that._elementLookup[focusKey].elements, function(regEle){
        return regEle === element;
      });

      if(_.isEmpty(that._elementLookup[focusKey].elements)) {
        delete that._elementLookup[focusKey];
      }
    }
  };

  that.focusOn = function (focusKey, select) {
    _.each(that._elementLookup[focusKey] && that._elementLookup[focusKey].elements, function(element){

      log.debug('focus on, focus key', focusKey, 'select', select, 'element', element);
      if (element) {
        $timeout(function () {

          // for react component bridges, broadcast event
          if (sbReactHelpers.isReactComponent(element[0])) {
            // The provided element is the mounted angular container. We want to find the provided focus key inside that
            const dynamicElement = element.querySelectorAll(`[focus-key="${focusKey}]"`);
            if (dynamicElement) {
              dynamicElement.focus();
            }
          }
          else if (select) {
            element.focus();
            element.select();
          } else {
            element.focus();
          }
        });
      }
    });
  };

  function validKey(focusKey) {
    return _.isString(focusKey) && !_.isEmpty(focusKey);
  }
});
