import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getMap as getMatterBalanceState } from '@sb-billing/redux/bank-account-balances';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { TrustToOfficeReversalWarning } from './TrustToOfficeReversalWarning';

const mapStateToProps = (state, { paymentIds }) => {
  if (!hasFacet(facets.allowOverdraw)) {
    return { warnings: [] };
  }

  const warnings = paymentIds.reduce((acc, paymentId) => {
    const payment = getPaymentById(paymentId);
    const bankAccount = payment.sourceAccountId ? getBankAccountById(payment.sourceAccountId) : {};
    const isTrustAccountInvoicePayment = bankAccount.accountType === bankAccountTypeEnum.TRUST;

    if (!isTrustAccountInvoicePayment || !payment || !payment.isTrustToOffice) {
      return acc;
    }

    const paidAmount = (payment && payment.amount) || 0;
    const matterTrustBalance = getMatterBalance(getMatterBalanceState(), {
      bankAccountId: bankAccount.id,
      matterId: payment.matterId,
    });
    const trustBalanceAfterReversal = matterTrustBalance + paidAmount; // Paid amount is negative.

    if (trustBalanceAfterReversal < 0) {
      acc.push({ matterId: payment.matterId, trustBalance: trustBalanceAfterReversal });
    }

    return acc;
  }, []);

  return { warnings };
};

export const TrustToOfficeReversalWarningContainer = withReduxStore(
  connect(mapStateToProps)(TrustToOfficeReversalWarning),
);

TrustToOfficeReversalWarningContainer.displayName = 'TrustToOfficeReversalWarningContainer';

TrustToOfficeReversalWarningContainer.propTypes = {
  paymentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TrustToOfficeReversalWarningContainer.defaultProps = {};
