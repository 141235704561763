//TODO sb? terrible - TH
angular.module('sb.billing.webapp').component('sbDataRadioListBankAccounts', {
    'require': {
      sbComposeCtrl: '^sbCompose'
    },
    'bindings': {optionsDataKey: '@'},
    'controller': function (sbLoggerService) {
      'use strict';

      var that = this, log = sbLoggerService.getLogger('sbDataRadioListBankAccounts');

      that.$onInit = function () {
        log.info('optionsDataKey', that.optionsDataKey);
        updateData();
      };

      function updateData(){
        var accounts = [{display: 'Operating', value: 'OPERATING' }, {display: 'Trust', value: 'TRUST'}];
        that.sbComposeCtrl.setComposeData(accounts, that.optionsDataKey);
      }
    }
  }
);
