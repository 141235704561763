import { getActiveTrustAccounts } from "@sb-billing/redux/bank-account";
import { store } from '@sb-itops/redux';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { getRememberedParams } from "web/redux/features/transactions-recent-account-selection/selectors";

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/deposit-slips';
  const PARENT_PAGE_NAME = 'home.billing.accounts.deposit-slips';

  $stateProvider

    .state('home.billing.accounts.deposit-slips', {
      url: '/deposit-slips',
      templateUrl: `${BASE_DIRECTORY}/deposit-slips.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'remember' feature
        remember: true,
        rememberParent: 'home.billing.accounts.deposit-slips'
      },
      onEnter: function($timeout, $state) {
        // If entering trust overview and not from a child page of deposit slips
        // Load the previously remembered page if there is one
        // $state.current.name is the state we are coming from, $stateParams is the params of the state we are going to
        if (!$state.current.name.includes(PARENT_PAGE_NAME)) {
          const rememberedAccountParams = getRememberedParams(store.getState()['web/transactions-recent-account-selection'], { pageName: PARENT_PAGE_NAME });
          if (rememberedAccountParams) {
            $timeout(() => {
              $state.go(...rememberedAccountParams);
            });
          } else {
            // If only one trust account default to that page
            if (getActiveTrustAccounts().length === 1) {
              $timeout(function(){
                $state.go('home.billing.accounts.deposit-slips.trust.account', { trustAccountId: getActiveTrustAccounts()[0].id });
              });
            }
          }
        }
      },
    })
    .state('home.billing.accounts.deposit-slips.trust', {
      url: '/trust',
      templateUrl: `${BASE_DIRECTORY}/deposit-slips.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'remember' feature
        remember: true,
        rememberParent: 'home.billing.accounts.deposit-slips'
      },
    })
    .state('home.billing.accounts.deposit-slips.trust.account', {
      url: '/:trustAccountId',
      templateUrl: `${BASE_DIRECTORY}/deposit-slips.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'remember' feature
        remember: true,
        rememberParent: 'home.billing.accounts.deposit-slips'
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.deposit-slips.trust.account', { ...$stateParams }], parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.accounts.deposit-slips.operating', {
      url: '/operating',
      templateUrl: `${BASE_DIRECTORY}/deposit-slips.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'OPERATING',
        // see SbPartialStateController for comments about 'remember' feature
        remember: true,
        rememberParent: 'home.billing.accounts.deposit-slips'
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.deposit-slips.operating', { ...$stateParams }], parentPageName: PARENT_PAGE_NAME }));
      },
    });
});
