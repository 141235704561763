import { gql } from '@apollo/client';

const query = gql`
  query BillingBulkActions($type: Int!, $bulkEntityIds: [ID], $contactIds: [ID]) {
    billingBulkActionList(filter: { type: $type, bulkEntityIds: $bulkEntityIds, contactIds: $contactIds }) {
      totalCount
    }
  }
`;

const notificationIds = ['WebQueryBillingSharedNotifications.BillingBulkActionFinished'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BillingBulkActions = {
  query,
  notificationIds,
};
