import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async/dynamic';
import { getLogger } from '@sb-itops/fe-logger';
import { processCacheNotification } from 'web/services/apollo/subscription-manager';

const log = getLogger('polling-manager');
log.setLogLevel('info');


/** @type {Map<string, Array<*>>} */
const polls = new Map();

/**
 * @param {import('./types').StartPollingInput} param
 */
const startPolling = ({ queryName, poll, isSubscribedToCacheNotification }) => {
  if (!poll) {
    throw new Error('Missing poll function');
  }
  if (!queryName) {
    throw new Error('Missing queryName');
  }
  if (polls.has(queryName)) {
    throw new Error(`Query ${queryName} is already registered for polling`);
  }

  const { intervalId, notificationId } = poll();

  if (!isSubscribedToCacheNotification({ notificationId, queryName })) {
    throw new Error(`Query ${queryName} is not subscribed to notification ${notificationId}`);
  }
  
  log.info(`start polling for ${queryName}`);
  polls.set(queryName, intervalId);
};

const stopPolling = () => {
  [...polls.values()].forEach((intervalId) => clearIntervalAsync(intervalId));
  polls.clear();
};

/**
 * @param {import('./types').CreatePolledSubscriptionInput} param
 * @returns {import('./types').CreatePolledSubscriptionOutput}
 */
const createPolledSubscription = ({ subscribedNotificationId, intervalMs }) => {
  return {
    notificationId: subscribedNotificationId,
    intervalId: setIntervalAsync(
      async () => processCacheNotification({ provider: subscribedNotificationId }),
      intervalMs,
    ),
  };
};

export {
  startPolling,
  stopPolling,
  createPolledSubscription,
};
