import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { bankAccountTypes } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getById as getBankAccountById } from '../bank-account';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'deposit-slip',
  keyPath: 'id',
  ngCacheName: 'sbDepositSlipService',
  syncType: syncTypes.SINCE,
  immutable: false,
  changesetProcessor: ({ entities = [] }) =>
    entities.map((ds) => {
      const bankAccount = getBankAccountById(ds.bankAccountId);
      const bankAccountType = bankAccountTypes[bankAccount?.accountType];
      return { ...ds, bankAccountType };
    }),
});

export const { getMap, getList, getById, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;
export const getByBankAccountId = (bankAccountId) => getList().filter((ds) => ds.bankAccountId === bankAccountId);
