import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes } from '@sb-itops/react/button';
import { Modal } from '@sb-itops/react/modal';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import * as Styles from './PaymentProviderDisconnectConfirmationModal.module.scss';

export const PaymentProviderDisconnectConfirmationModal = ({ isVisible, onConfirm, onClose, providerType }) => {
  const providerName = providerNames[providerType] || '';
  return (
    <Modal
      title={`Disconnect ${providerName}`}
      body={
        <div>
          <p>Disconnecting {providerName} will remove your payment account details from Smokeball.</p>
          <p>All saved client payment information will be lost and all payment plans will stop automatic charging.</p>
          <p>
            If you wish to temporarily turn {providerName} off, please use the “Activate {providerName}” switch instead.
          </p>
        </div>
      }
      footer={
        <div className={Styles.footer}>
          <Button className={Styles.footerBtn} onClick={onConfirm}>
            Disconnect
          </Button>
          <Button className={Styles.footerBtn} type={buttonTypes.secondary} onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
      isVisible={isVisible}
      onModalClose={onClose}
      shouldCloseOnOverlayClick={false}
    />
  );
};

PaymentProviderDisconnectConfirmationModal.displayName = 'PaymentProviderDisconnectConfirmationModal';

PaymentProviderDisconnectConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  providerType: PropTypes.string.isRequired,
};

PaymentProviderDisconnectConfirmationModal.defaultProps = {};
