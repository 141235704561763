import { addError } from 'web/services/apollo';

angular.module('sb.billing.webapp').service('sbRestErrorService', function(sbUuidService) {
  'use strict';
  const svc = this;

  const errorHistory = [];
  const MAX_ERROR_HISTORY = 50;

  svc.store = store;
  svc.getErrorSummaries = getErrorSummaries;
  svc.getErrorDetails = getErrorDetails;
  svc.getLogHistory = getLogHistory;

  function store(msg) {
    if (errorHistory.length > MAX_ERROR_HISTORY) {
      errorHistory.shift();// remove old messages from the front
    }
    msg.errorId = sbUuidService.get();
    errorHistory.push(msg);// add new messages at the end
    // Add to support logs
    addError(msg);
  }

  function getErrorSummaries() {
    return errorHistory.map(e => {
      return {
        errorId: e.errorId,
        correlationId: e.context.CORRELATION_ID,
        sessionId: e.context.SESSION_ID,
        userId: e.context.USER_ID,
        accountId: e.context.ACCOUNT_ID,
        url: e.context.URL,
        method: e.context.METHOD,
        errStatus: e.error.status,
        errMsg: e.error.message,
      };
    });
  }

  function getErrorDetails(errorId) {
    return _.omit(errorHistory.filter(e => e.errorId === errorId).pop(), 'logHistory'); 
  }

  function getLogHistory(errorId) {
    const errDetails = errorHistory.filter(e => e.errorId === errorId).pop();
    return errDetails && errDetails.logHistory &&
      errDetails.logHistory.filter(l => {
        return _.isString(l) && !l.includes('skipping authIdentSanitize');
      });
  }

});
