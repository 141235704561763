import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

// This is terrible, but it seems we need to do this for bridging to angular as
// passing in children isnt supported via the bridging.
// The label is passed as a prop from the angular bridge, this wrapper simply forwards it as the children
// for the underlying React button component.

/**
 * ButtonAngularCompatible
 *
 * Thin wrapper around the Button react component which allows it to be utilised via an angular bridge.
 *
 * @param {Object} props
 * @param {string} props.label Label for the button.
 * @param {function} props.onClick Callback function called when the button is clicked.
 * @param {boolean} [props.disabled] Sets whether or not the button is disabled. Defaults to false.
 * @param {string} [props.title] Text to display when the button is hovered. Defaults to empty string.
 * @param {boolean} [props.locked] Sets whether or not an action is currently occuring via the button. Defaults to false.
 * @returns {React.ReactElement}
 */
export const ButtonAngularCompatible = ({ label, onClick, disabled, title, locked }) => (
  <Button locked={locked} disabled={disabled} onClick={onClick} title={title}>
    {label}
  </Button>
);

ButtonAngularCompatible.displayName = 'ButtonAngularCompatible';

ButtonAngularCompatible.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
};

ButtonAngularCompatible.defaultProps = {
  locked: false,
  disabled: false,
  title: undefined,
};
