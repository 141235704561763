import { useState, useEffect } from 'react';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { fetchById as fetchFullCorrespondenceHistoryById } from '@sb-billing/redux/correspondence-history-full';
import { useIsMounted } from '@sb-itops/react';
import { sortByProperty } from '@sb-itops/nodash';

export const useCorrespondenceHistoryPerContact = ({ correspondenceHistory }) => {
  const isMounted = useIsMounted();
  const [latestCorrespondencePerContact, setLatestCorrespondencePerContact] = useState(undefined);

  const correspondencePerContact = !correspondenceHistory
    ? []
    : Object.entries(correspondenceHistory).map(([contactId, correspondenceHistoryForContact]) => ({
        ...correspondenceHistoryForContact,
        contactId,
      }));
  const latestCorrespondencePerContactSummary = sortByProperty(correspondencePerContact, 'lastUpdated', 'desc');

  useEffect(() => {
    // React doesnt allow async hooks
    (async () => {
      const allLatestCorrespondencePerContactP = latestCorrespondencePerContactSummary.map(
        async (contactCorrespondenceSummary) => {
          const fullCorrespondenceHistory = await fetchFullCorrespondenceHistoryById(contactCorrespondenceSummary.id);
          // If we can't get full history, we use current summary as it contains at least some details
          const correspondenceHistoryEntity = fullCorrespondenceHistory || contactCorrespondenceSummary;
          const displayName = getPersonByUserId(correspondenceHistoryEntity.userId) || {};
          return {
            ...correspondenceHistoryEntity,
            contactDisplayName: getContactDisplay(contactCorrespondenceSummary.contactId),
            userDisplayName: displayName.name,
          };
        },
      );

      const allLatestCorrespondencePerContact = await Promise.all(allLatestCorrespondencePerContactP);

      if (
        // Cannot useState on an unmounted component
        isMounted &&
        isMounted.current &&
        // Since this hook is asynchronous and useEffect is not, we need to use useState to get result.
        // We don't want to set the state everytime useEffect runs as we are not guaranteed props.correspondenceHistory
        // is same object which could cause infinite loop.
        // Therefore, we set the state only when current correspondence histry doesn't match the one we have in state.
        (!latestCorrespondencePerContact ||
          allLatestCorrespondencePerContact.length !== latestCorrespondencePerContact.length ||
          allLatestCorrespondencePerContact.some(
            (ch, idx) => ch.lastUpdated !== latestCorrespondencePerContact[idx].lastUpdated,
          ))
      ) {
        setLatestCorrespondencePerContact(allLatestCorrespondencePerContact);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correspondenceHistory]);

  return {
    loading: latestCorrespondencePerContact === undefined,
    latestCorrespondencePerContact: latestCorrespondencePerContact || latestCorrespondencePerContactSummary,
  };
};
