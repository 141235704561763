import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onDeleteTask = ({ commandMessage }) => {
  const optimisticTask = {
    __typename: 'Task',
    ...commandMessage,
    isRemoved: true,
  };

  const apolloClient = getApolloClient();

  const taskIdentifier = apolloClient.cache.identify(optimisticTask);

  apolloClient.writeFragment({
    id: taskIdentifier,
    fragment: gql`
      fragment OpdateRemoveTask on Task {
        ${Object.keys(optimisticTask).join('\n')}
      }
    `,
    data: {
      ...optimisticTask,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_REMOVED,
    typeName: 'Task',
    optimisticEntity: optimisticTask,
  };
};
