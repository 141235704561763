import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getRegion } from '@sb-itops/region';
import { getFeeInterval } from '@sb-billing/redux/fee-configuration';
import { getActiveRateSets, getById as getRateSetById } from '@sb-billing/redux/rate-sets';
import { getAllStaff } from '@sb-firm-management/redux/firm-management';
import { getById as getStaffHourlyRateById } from '@sb-billing/redux/staff-hourly-rate';
import {
  billingFrequencyOptions,
  billingUnitOptions,
  billingUnitOptionValues,
  getBillingFrequencySubTypeOptions,
} from '@sb-billing/business-logic/matters/billing-config';

import { MatterFeeAndRateSettings } from './MatterFeeAndRateSettings';
import { getBillingTypeOptions } from './billing-types';

const getBillingUnitOptions = () => {
  const firmBillingUnit = getFeeInterval();

  const billingUnitOptionsForMatterLevel = billingUnitOptions.map((option) => {
    if (option.value === billingUnitOptionValues.FIRM_DEFAULT) {
      return {
        value: option.value,
        label: `${option.label} (Currently ${firmBillingUnit} minute${parseInt(firmBillingUnit, 10) === 1 ? '' : 's'})`,
      };
    }
    return option;
  });

  return billingUnitOptionsForMatterLevel;
};

const mapStateToProps = (
  state,
  { formInitialised, formFieldValues, formFields, formDisabled, formSubmitting, submitFailed },
) => {
  const { customRatePerStaffMatterHourlyRates } = formFieldValues;

  const {
    billingType,
    contingencyAmount,
    amount,
    billingUnitOption,
    customUnit,
    rateType,
    rate,
    rateSetId,
    billingFrequency,
    billingFrequencySubType,
  } = formFields;

  const rateSets = getActiveRateSets();
  // make sure selected rate set is included in the list even if it's hidden
  if (rateSetId) {
    const selectedRateSet = getRateSetById(rateSetId.value);
    if (selectedRateSet && selectedRateSet.isHidden) {
      rateSets.unshift(selectedRateSet);
    }
  }

  const allStaffMembers = getAllStaff();

  return {
    allStaffMembers,
    billingTypeOptions: getBillingTypeOptions(getRegion()),
    billingUnitOptions: getBillingUnitOptions(),
    rateSets,
    billingFrequencyOptions,
    billingFrequencySubTypeOptions: getBillingFrequencySubTypeOptions({ billingFrequency: billingFrequency.value }),
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    submitFailed,
    // form fields
    billingType,
    contingencyAmount,
    amount,
    billingUnitOption,
    customUnit,
    rateType,
    rate,
    customRatePerStaffMatterHourlyRates, // form field values
    rateSetId,
    billingFrequency,
    billingFrequencySubType,
  };
};

const mapDispatchToProps = (dispatch, { onFieldValuesUpdated, onFieldValueSet }) => ({
  onHourlyRateChange: ({ rateType, rate, customRatePerStaff, rateSetId }) => {
    onFieldValuesUpdated({ rateType, rate, rateSetId });
    onFieldValueSet({ field: 'customRatePerStaffMatterHourlyRates', value: customRatePerStaff }); // Need to use onFieldValueSet on array fields
  },
});

export const MatterFeeAndRateSettingsContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const staffMembers = useMemo(() => {
      const staffMembersWithRate = props.allStaffMembers.map((staffMember) => {
        const staffHourlyRate = getStaffHourlyRateById(staffMember.id);
        return {
          ...staffMember,
          rate: staffHourlyRate?.rate,
        };
      });
      return staffMembersWithRate;
    }, [props.allStaffMembers]);
    return <MatterFeeAndRateSettings {...props} staffMembers={staffMembers} />;
  }),
);

MatterFeeAndRateSettingsContainer.displayName = 'MatterFeeAndRateSettingsContainer';

MatterFeeAndRateSettingsContainer.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFieldValues: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onFieldValueSet: PropTypes.func.isRequired, // generic method for set a field
};
