import React from 'react';
import PropTypes from 'prop-types';
import { Select, StatelessSelect } from '../select';

const DropDownList = React.memo((props) => {
  if (props.stateless) {
    return (
      <StatelessSelect
        {...props}
        selectedOption={props.selectedOption || props.defaultValue}
        className="ddl-container"
        optionLabelHeight={props.optionLabelHeight}
      />
    );
  }

  return <Select {...props} className="ddl-container" virtualizeList={false} />;
});

DropDownList.displayName = 'DropDownList';

DropDownList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    }),
  ),

  // DEPRECATED, use onValueChange
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,

  // DEPRECATED, use selectedOption
  defaultValue: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
  }),
  selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.array]),
  optionLabelHeight: PropTypes.number,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  stateless: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropDownList.defaultProps = {
  options: [],
  onValueChange: undefined,
  onChange: undefined,
  defaultValue: undefined,
  selectedOption: undefined,
  optionLabelHeight: undefined,
  isClearable: false,
  isSearchable: false,
  // this property for now it is only so it is going to know if we are rendering this component from angular or pure react.
  // for angular we need to keep the state in the select for being able to clear the value programaticaly without causing an infinite loop.
  // this is not the case for pure react.
  stateless: false,
  disabled: false,
};

export default DropDownList;
