angular.module('sb.billing.webapp').component('sbCbuiAddContacts',
  {
    templateUrl: 'ng-composable-components/callback-ui/add-contacts/cbui-add-contacts.html',
    bindings: {contactType: '<?', callbackFn: '&', onSaveContact: '&'},
    controller: function ($timeout, $scope, focusService) {
      'use strict';

      const ctrl = this;

      ctrl.$onInit = function () {
        ctrl.tabHighlights = {};
        ctrl.tabHighlights.person = true;
      };

      ctrl.setActive = function (contactType){
        ctrl.tabHighlights = {};
        ctrl.tabHighlights[contactType] = true;
      };

      ctrl.focusOn = function(key){
        focusService.focusOn(key);
      };
    }
  }
);
