import { store } from '@sb-itops/redux';

/**
 * Updates one or more properties in a leaf node in the redux tree.
 *
 * E.g. Take the example store:
 * {
 *   someCoolLeaf: {
 *      someString: '123',
 *      someInt: 1,
 *   }
 * }
 *
 * Then calling updateStateAction({someString: '456', someInt: 5}) will result in the store containing:
 * {
 *   someCoolLeaf: {
 *      someString: '456',
 *      someInt: 5,
 *   }
 * }
 */
export const updateState = ({ actionName = 'UPDATE_STATE' }) => {
  const ACTION_TYPE = actionName;

  const updateStateAction = (propsToUpdate) =>
    store.dispatch({
      type: ACTION_TYPE,
      payload: {
        propsToUpdate,
      },
    });

  const updateStateReducer = (state, action) => {
    const { propsToUpdate } = action.payload;
    return { ...state, ...propsToUpdate };
  };

  const updateStateHandler = {
    [ACTION_TYPE]: updateStateReducer,
  };

  return { updateStateAction, updateStateReducer, updateStateHandler, ACTION_TYPE };
};
