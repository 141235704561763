'use strict';

/**
 * @typedef {object} relatedItemType
 * @property {number} UNKNOWN 0
 * @property {number} INVOICE 1
 * @property {number} MATTER 2
 * @property {number} CONTACT 3
 * @property {number} BANK_ACCOUNT 4
 * @property {number} STAFF 5
 */
const relatedItemType = Object.freeze({
  UNKNOWN: 0,
  INVOICE: 1,
  MATTER: 2,
  CONTACT: 3,
  BANK_ACCOUNT: 4,
  STAFF: 5,
  TRUST_STATEMENT_REQUEST: 6,
  PAYMENT_PROVIDER_REFUND: 7,
});

module.exports = { relatedItemType };
