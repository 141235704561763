'use strict';

const activityCategories = Object.freeze({
  CUSTOM: 'custom',
  UTBMS: 'utbms',
});

const activityCategoryLabels = Object.freeze({
  [activityCategories.CUSTOM]: 'Activity Codes',
  [activityCategories.UTBMS]: 'Utbms Codes',
});

const taskCategories = Object.freeze({
  CUSTOM: 'utbmsCustomTaskCodes',
  STANDARD: 'utbmsTaskCodes',
});
const taskCategoryLabels = Object.freeze({
  [taskCategories.STANDARD]: 'UTBMS Codes',
  [taskCategories.CUSTOM]: 'Custom UTBMS Codes',
});

/**
 * @typedef {(0|1|2)} ActivityRateOverrideTypes
 */

/**
 * @enum {ActivityRateOverrideTypes}
 */
const activityRateOverrideTypes = Object.freeze({
  NO_OVERRIDE: 0,
  ALL_STAFF_OVERRIDE: 1,
  CUSTOM_STAFF_OVERRIDE: 2,
});

module.exports = {
  activityCategories,
  activityCategoryLabels,
  taskCategories,
  taskCategoryLabels,
  activityRateOverrideTypes,
};
