/**
 * Get the display name of a contact object of LOD.
 * @param {object} contact A lod contact object entity
 * @param {object} opts The options for configuring the returned display name
 * @param {boolean} [opts.showLastNameFirst] Represent the name in the form `lastName, firstName`
 * @returns {String|undefined} The display name of the lod contact object or undefined if there is no contact
 */
const getContactDisplay = (contact, { showLastNameFirst } = {}) => {
  if (!contact) {
    return '';
  }
  // Logic for construct display names base on contact types are done in contact data loaders.
  return showLastNameFirst ? contact.displayName : contact.displayNameFirstLast;
};

module.exports = {
  getContactDisplay,
};
