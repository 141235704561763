'use strict';

import { store } from '@sb-itops/redux';
import { getRegisteredUserIds } from '@sb-product-registration/redux/registered-users';
import { getLogger } from '@sb-itops/fe-logger';
import { warn as displayWarningToUser } from '@sb-itops/message-display';
import { parseQueryString } from '@sb-itops/region';
import { preAuthenticationBootstrapP } from 'web/services/bootstrapper';
import { getAuthToken, getUserRegion, getIsAuthenticated, getUserId, assumeUserIsAuthenticated, userSessionEvents } from 'web/services/user-session-management';
import { getRegion } from '@sb-itops/region';

angular.module('sb.billing.webapp').controller('sbPreAuthenticationController', function ($rootScope, $state, $indexedDB) {
  const log = getLogger('sbPreAuthenticationController');
  const ctrl = this;

  const qs = parseQueryString(window.location.search);
  ctrl.isTriConveyBranding = qs.OWNER && qs.OWNER.toLowerCase() === 'triconvey';

  log.setLogLevel('info');
  let bootstrapServicePreAuthComplete = false;

  const destroyListener = $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
    // Some routes may be loaded (other than pre-authentication and post-authentication) during the
    // overall bootstrap process (see routesAllowedDuringBootstrap below). These routes however are either
    // non-navigable by a user (e.g. post-authentication) or don't depend on redux state being loaded from
    // persistent storage.

    // The exception is the login page, which can be navigated to by a user, requires persisted state to be loaded and
    // can be accessed part way through a bootstrap. Login therefore needs some special handling to ensure that it doesn't
    // get directly loaded until after we have at least completed the bootstrap service's pre authentication steps.
    // The side effect of this is that a user that is logged in cannot navigate to the login page directly. They'll end up
    // getting redirected to the matters page, at which point they can logout if they wish.
    if (toState.name === 'login' && !bootstrapServicePreAuthComplete) {
      event.preventDefault();
      return;
    }

    // routes allowed during pre and post authentication bootstrap
    const routesAllowedDuringBootstrap = ['login', 'logout', 'post-authentication'];
    if (!routesAllowedDuringBootstrap.includes(toState.name)) {
      // We need to store the route that the user originally hit to load billing.
      // Once bootstrapping is done, we will return to them to that state.
      if (!$rootScope.returnToState && toState) {
        $rootScope.returnToState = toState;
        $rootScope.returnToStateParams = toParams;
      }

      // log info so we can better understand what unexpected routing is happening
      log.info('Detected attempt to route to ', toState, ' params:', toParams);

      // Ui-router triggers multiple attempts to route to the same page on start-up.
      // We use preventDefault() to ensure that no routing occurs until we are actually
      // ready for it during the bootstrap. If we don't do this, we will see weird flickers
      // between routes during bootstrapping.
      event.preventDefault();
    }
  });

  // pass route change prevention listener to post authentication so it can be use to free up routing after bootstrap
  $rootScope.destroyListenerBootstrapRouteHandling = destroyListener;


  // This IIFE function executes all of the pre authentication bootstrapping logic.
  // After this function executes, we will decide one of the following:
  //  * The user's state indicates they are authenticated, in which case we will move on to post authentication bootstrapping
  //  * The user's state indicates they are not authenticated, in which case we will redirect them to the login page.
  // Note: Even if the user's state indicates they are authenticated, it doesn't mean we will grant them access to everything.
  //       The loading page will check the user's "authorisation" to use the app, which includes a remote check of their access
  //       token. All we are doing at this point is checking the useris making a claim of authentication based on their
  //       state; state which can be easily manipulated by a sinister actor.
  (async function performPreAuthentication() {
    try {
      log.info('Initialising pre-authentication bootstrap');

      await preAuthenticationBootstrapP({ initialiseEntityDatabaseP });

      bootstrapServicePreAuthComplete = true;

      monitorForUnregisteredUsers();

      // Sets up handlers for events propagated by the user session manager.
      // This should be the only place that listens for 'failed-to-refresh-token'.
      userSessionEvents.addEventListener('failed-to-refresh-token', () => {
        displayWarningToUser('Your session has expired, please login again');
        $state.go('logout');
      });

      if (!getAuthToken()) {
        log.info('User is not making an identity claim, redirecting to login');

        // Remove the 'uid' parameter if it was passed by the desktop app, as it could
        // prevent the user from authenticating if the 'uid' value does not match the user's ID
        const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
        if (urlParams.has('uid')) {
          urlParams.delete('uid');
          // Force change window.location (page reload) because $state.go keeps on reinstating the 'uid' parameter if we attempt history.replaceState
          window.location.search = [...urlParams.keys()].length ? `?${urlParams.toString()}/` : '';
        }

        $state.go('login');
        return;
      }

      // For local development environment, ensure that the region of the currently logged in user
      // matches the region in which the site is running.
      if (getRegion() !== getUserRegion()) {
        log.warn(`User identity belongs to region ${getUserRegion()}, site is running in ${getRegion()}`);
        $state.go('logout');
        return;
      }

      log.info('User is making an identity claim');
      assumeUserIsAuthenticated();

      log.info('Redirecting to post-authentication bootstrap');
      $state.go('post-authentication');

    } catch (err) {
      bootstrapServicePreAuthComplete = true; // This kinda sucks, but we need to show the user something. Perhaps an "oops" page is more appropriate here.
      log.error('Error during pre-authentication bootstrap, redirecting to login', err);
      $state.go('login');
    }
  })();

  /**
   * initialiseEntityDatabaseP
   * 
   * Due to the crapness of the angular wrapper around indexedDB, we need to force
   * an opening of the database during pre-authentication to ensure that any database
   * version upgrades complete before anything else tries to access the entity store.
   * 
   * Without this function being called during pre-auth, the token migration step will
   * fail in post auth. This function is passed into the bootstrapper as the bootstrapper
   * does not have direct access to angular injections.
   */
  async function initialiseEntityDatabaseP() {
    try {
      await $indexedDB.initialiseDb();
    
    } catch (err) {
      log.error(err);
      throw new Error('Failed to initialise entity database');
    }
  }

  /**
   * monitorForUnregisteredUsers
   * 
   * Registers a redux store subscription which checks the current user against the list
   * of registered users for the firm.
   * 
   * If the user is not in the list of registered users, then the user is navigated to the logout
   * page.
   */
  function monitorForUnregisteredUsers() {
    store.subscribe(() => {
      if (!getIsAuthenticated()) {
        return;
      }

      if (!getRegisteredUserIds()) {
        return;
      }
      
      const userId = getUserId();
      const registeredUserIds = getRegisteredUserIds();

      if (!registeredUserIds.includes(userId)) {
        log.warn(`current user ID ${userId} is not in the list of registered users ${JSON.stringify(registeredUserIds)}. Logging out...`);
        $state.go('logout', { unregisteredUser: true });
      }
    });
  }
});
