import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ClickableIcon = memo((props) => (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <div
    id={props.id}
    className={classnames(props.className, 'clickable-icon')}
    role="button"
    title={props.title}
    onClick={props.onClick}
    tabIndex={0}
  />
));

ClickableIcon.displayName = 'ClickableIcon';

ClickableIcon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ClickableIcon.defaultProps = {
  id: '',
  className: '',
  onClick: () => {},
  title: '',
};

export default ClickableIcon;
