import * as React from 'react';
import PropTypes from 'prop-types';
import FinancialSummaryItem from './FinancialSummaryItem';
import FinancialSummary from './FinancialSummary';

export const FinancialSummaryForTrust = ({ totals }) => (
  <FinancialSummary responsive={false}>
    <div className="financial-summary-totals no-colour">
      <div className="totals-container">
        <FinancialSummaryItem name="Protected" total={totals.PROTECTED} />
        <FinancialSummaryItem name="Available" total={totals.AVAILABLE} />
        <FinancialSummaryItem name="Total" total={totals.TOTAL} />
      </div>
    </div>
  </FinancialSummary>
);

FinancialSummaryForTrust.displayName = 'FinancialSummaryForTrust';

FinancialSummaryForTrust.propTypes = {
  totals: PropTypes.object,
};
FinancialSummaryForTrust.defaultProps = {
  totals: {},
};
