import composeHooks from '@sb-itops/react-hooks-compose';
import { getRecentMatters } from '@sb-billing/redux/recent-matters';
import { useQuery } from '@apollo/client';
import { BillingDashboardRecentMatters } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import React from 'react';
import { RecentlyViewedWidget } from './RecentlyViewedWidget';

interface Props {
  isEditing: boolean;
  onChange: Function;
  onRemove: Function;
  onClickLink: React.MouseEventHandler;
  settings: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hooks = (props: Props) => ({
  useGraphQLforRecentMatters: () => {
    const matterIds = getRecentMatters() || [];

    const { data, loading, error } = useQuery(BillingDashboardRecentMatters, {
      variables: {
        matterIds,
        includeMatterBalance: false,
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
        },
      },
    });

    if (error) {
      throw error;
    }
    return {
      matters: data?.matters || [],
      loading,
    };
  },
});

export const RecentlyViewedWidgetContainer = withApolloClient(composeHooks(hooks)(RecentlyViewedWidget));
