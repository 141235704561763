import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';

import * as Styles from './DepositAddressIncompleteButton.module.scss';

export const DepositAddressIncompleteButton = ({
  label,
  buttonTitle,
  disabled,
  locked,
  modalId,
  contactsWithNoAddress,
  onButtonClick,
  onConfirmMissingAddressModal,
  t,
}) => (
  <>
    <Button onClick={onButtonClick} disabled={disabled} locked={locked} title={buttonTitle}>
      {label}
    </Button>
    {contactsWithNoAddress.length > 0 && (
      <ConfirmationModalDialog
        onConfirm={onConfirmMissingAddressModal}
        modalId={modalId}
        title="Address Incomplete"
        body={
          <>
            {contactsWithNoAddress.map((client, idx) => (
              <div key={`${client.id}+${idx}`} className={Styles.bottomMargin}>
                {client.entityName.trim()}&apos;s address needs to be completed before you can deposit funds into this{' '}
                {t('trustAccount').toLowerCase()}.
              </div>
            ))}
          </>
        }
        primaryButtonText={`Edit Contact${contactsWithNoAddress.length > 1 ? 's' : ''}`}
      />
    )}
  </>
);

DepositAddressIncompleteButton.displayName = 'DepositAddressIncompleteButton';

DepositAddressIncompleteButton.propTypes = {
  modalId: PropTypes.string.isRequired,
  matter: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  onAddressComplete: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  contactsWithNoAddress: PropTypes.array.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onConfirmMissingAddressModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
DepositAddressIncompleteButton.defaultProps = {
  locked: false,
  disabled: false,
  buttonTitle: undefined,
};
