'use strict';

/**
 * Interpolates placeholders in a given text with provided values.
 *
 * @param {Object} param - An object containing various placeholders and their values.
 * @param {Function} param.t - The translation function.
 * @param {string} param.text - The input text containing placeholders.
 * @param {string} [param.matterNumber] - The matter number to replace the placeholder.
 * @param {Array} [param.matterClientString] - A string of client's name(s) to replace the placeholder.
 * @param {string} [param.matterTypeName] - The matter type name to replace the placeholder.
 * @param {string} [param.matterDescription] - The matter description to replace the placeholder.
 * @returns {string} - The text with placeholders replaced by their corresponding values.
 */
const interpolateCustomTextTitle = ({
  t,
  text,
  matterNumber,
  matterClientString,
  matterTypeName,
  matterDescription,
}) => {
  if (!text) {
    return '';
  }

  const matterNumberPlaceholder = `\\[${t('matterNumberPlaceholder')}\\]`;
  const matterNumberRegex = new RegExp(matterNumberPlaceholder, 'g');

  const interpolatedText = text
    .replace(matterNumberRegex, matterNumber || '')
    .replace(/\[MATTER_CLIENT\]/g, matterClientString || '')
    .replace(/\[MATTER_TYPE\]/g, matterTypeName || '')
    .replace(/\[MATTER_DESCRIPTION\]/g, matterDescription || '');

  return interpolatedText;
};

module.exports = { interpolateCustomTextTitle };
