import { get, isObject, set, isString, isEmpty } from 'lodash';

angular.module('sb.billing.webapp').component('sbActivitySubjectTypeahead', {
  templateUrl: 'ng-components/activity-subject-typeahead/activity-subject-typeahead.html',
  bindings: {
    textField: '@',
    idField: '@',
    customClasses: '@?',
    focusKey: '<?',
    initialValue: '<?',
    activityList: '<?',
    onBlur: '&?',
    onSubjectOverrideChange: '<?',
    onValueChanged: '<?',
    onTextChanged: '<?',
    disabled: '@?'
  },
  controller: function(sbUuidService) {
    const ctrl = this;
    let lastSelection;

    ctrl.getTextField = getTextField;
    ctrl.valueSelected = valueSelected;
    ctrl.inputChanged = inputChanged;

    ctrl.$onInit = () => {
      ctrl.view = {
        typeaheadBind: ctrl.initialValue,
        list: ctrl.activityList
      };
      lastSelection = { ...ctrl.initialValue };
    };

    ctrl.$onChanges = changesObj => {
      // the only change that we are going to read is the initialValue
      if (ctrl.view && !isEmpty(changesObj.initialValue)) {
        const theChangeObject = changesObj.initialValue && changesObj.initialValue.currentValue;
        const viewIdField = get(ctrl.view.typeaheadBind, ctrl.idField);
        const changeIdField = get(theChangeObject, ctrl.idField);
        if (viewIdField != changeIdField) {
          lastSelection = theChangeObject ? { ...theChangeObject } : undefined;
          ctrl.view.typeaheadBind = theChangeObject ? { ...theChangeObject } : undefined;
        }
      }
    };

    function getTextField(item) {
      return get(item, ctrl.textField);
    }

    function inputChanged(value) {
      // when we change the selection by typing this event is going to be triggered with the text to display.
      // we will need to update the current value and send the change upwards
      if (isString(value)) {
        const valueChangedObject = lastSelection || {};
        set(valueChangedObject, ctrl.textField, value);
        set(valueChangedObject, ctrl.idField, get(valueChangedObject, ctrl.idField) || sbUuidService.get());
        ctrl.view.typeaheadBind = { ...valueChangedObject };
      }

      // we we have a text changed handler, the change functionality of the activity will be handled by the container
      if(ctrl.onTextChanged){
        ctrl.onTextChanged(get(ctrl.view.typeaheadBind, ctrl.textField))
      }
      if (ctrl.onSubjectOverrideChange) {
        ctrl.onSubjectOverrideChange(value)
      }
      valueSelected(ctrl.view.typeaheadBind, !!ctrl.onTextChanged);
    }

    function valueSelected(item, textUpdated) {
      const activitySelected = isObject(item) ? item : undefined;
      if (activitySelected) {
        lastSelection = { ...activitySelected };
      }
      if(!textUpdated && ctrl.onValueChanged){
        // send the event upwards
        ctrl.onValueChanged(activitySelected);
      }
    }
  }
});
