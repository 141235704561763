import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyInput2, useTranslation, LoadingBarInfinite } from '@sb-itops/react';
import { Checkbox } from '@sb-itops/react/checkbox';
import classnames from 'classnames';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { PaymentPlanIndicator } from 'web/components/payment-plan-indicator';
import Styles from './SplitPaymentsList.module.scss';

export const SplitPaymentsList = ({
  showErrorWhenNoInvoices,
  payments,
  invoices,
  totals,
  onSelectAll,
  onChange,
  allSelected,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(Styles.splitPaymentsList, 'split-payments-table')}>
      {isLoading && (
        <div className={Styles.loadingBar}>
          <LoadingBarInfinite loading={isLoading} />
        </div>
      )}
      <div className={classnames('sb-table-header sb-no-hover-style', Styles.tableHeader)}>
        <div className={classnames('sb-table-cell', Styles.dateDue)}>Date Due</div>
        <div className={classnames('sb-table-cell', Styles.invoiceNumber)} />
        <div className={classnames('sb-table-cell', Styles.iconsContainer)} />
        <div className={classnames('sb-table-cell', Styles.due)}>Amount</div>
        {hasFacet(facets.interest) && <div className={classnames('sb-table-cell', Styles.due)}>Interest</div>}
        <div className={classnames('sb-table-cell', Styles.paid)}>Payment</div>
        <div className={classnames('sb-table-cell amount-checkbox', Styles.amountCheckbox)}>
          <label>
            <Checkbox checked={allSelected} onChange={onSelectAll} />
          </label>
        </div>
      </div>

      <div className={classnames('sb-table-content', Styles.list)} style={{ maxHeight: '200px' }}>
        {invoices.map((invoice) => (
          <div className={Styles.item} key={invoice.invoiceId}>
            <div className={classnames('sb-table-cell', Styles.dateDue)}>
              {t('date', { yyyymmdd: invoice.dueDate })}
            </div>
            <div
              className={classnames('sb-table-cell', Styles.invoiceNumber, Styles.cell)}
              title={invoice.invoiceNumber}
            >
              {invoice.hasUnpaidAnticipatedDisbursements && (
                <i
                  className={classnames('icon-alert-1', Styles.iconUndapidAd)}
                  title={`This invoice contains an anticipated ${t(
                    'expense',
                  )} that has not yet been paid to the supplier.`}
                />
              )}
              {invoice.invoiceNumber}
            </div>
            <div className={classnames('sb-table-cell', Styles.iconsContainer)}>
              {invoice?.paymentPlan?.status === paymentPlanStatuses.ACTIVE && (
                <PaymentPlanIndicator paymentPlan={invoice.paymentPlan} onClickLink={() => null} />
              )}
            </div>
            <div className={classnames('sb-table-cell', Styles.due)}>
              {t('cents', { val: invoice.unpaidExcInterest })}
            </div>
            {hasFacet(facets.interest) && (
              <div className={classnames('sb-table-cell', Styles.due)}>{t('cents', { val: invoice.interest })}</div>
            )}

            <div className={classnames('sb-table-cell', Styles.paid)}>
              <CurrencyInput2
                value={payments[invoice.invoiceId] || 0}
                max={invoice.due}
                min={0}
                onChange={(amount) => onChange(invoice.invoiceId, amount.target.value)}
              />
            </div>
            <div className={classnames('sb-table-cell', Styles.amountCheckbox)}>
              <label>
                <Checkbox
                  checked={payments[invoice.invoiceId] === invoice.due}
                  onChange={() => {
                    onChange(invoice.invoiceId, payments[invoice.invoiceId] === invoice.due ? 0 : invoice.due);
                  }}
                />
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className={classnames('sb-table-footer', 'item')}>
        <div className={classnames('sb-table-cell', Styles.dateDue)} />
        <div className={classnames('sb-table-cell', Styles.invoiceNumber)} />
        <div className={classnames('sb-table-cell', Styles.iconsContainer)} />
        <div className={classnames('sb-table-cell', Styles.due)}> {t('cents', { val: totals.unpaidExcInterest })}</div>
        {hasFacet(facets.interest) && (
          <div className={classnames('sb-table-cell', Styles.due)}>{t('cents', { val: totals.interest })}</div>
        )}
        <div className={classnames('sb-table-cell paid', Styles.paid)}>{t('cents', { val: totals.paid })}</div>
        <div className={classnames('sb-table-cell', Styles.amountCheckbox)} />
      </div>
      {showErrorWhenNoInvoices && !invoices?.length && (
        <div className={Styles.errorMsg}>There are no invoices to allocate funds to</div>
      )}
    </div>
  );
};

SplitPaymentsList.displayName = 'SplitPaymentsList';

SplitPaymentsList.propTypes = {
  showErrorWhenNoInvoices: PropTypes.bool.isRequired,
  payments: PropTypes.object.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  totals: PropTypes.object.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

SplitPaymentsList.defaultProps = {};
