angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.quickbooks', {
      template: '<sb-quick-books-integration></sb-quick-books-integration>',
      url: '/quickbooks',
      data: {
        navHighlights: ['quickbooks']
      }
    })
    .state('settings.xero', {
      template: '<sb-xero-integration></sb-xero-integration>',
      url: '/xero',
      data: {
        navHighlights: ['xero']
      }
    })
    .state('settings.payment-provider', {
      template: '<div><sbb-payment-provider-integration></sbb-payment-provider-integration></div>',
      url: '/payment-provider',
      data: {
        navHighlights: ['payment-provider']
      }
    })
    .state('settings.myob', {
      template: '<sb-myob-integration></sb-myob-integration>',
      url: '/myob',
      data: {
        navHighlights: ['myob']
      }
    })
  ;

});
