import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import {
  getList as getInvoiceTemplates,
  getFirmDefault,
  getApplicationDefault,
} from '@sb-billing/redux/invoice-settings-template';
import { getSelectedTemplateId } from 'web/redux/route/home-settings-invoice';
import { sortByOrder } from 'lodash';

import ButtonGroup from './ButtonGroup';

// A magical button that looks like a template, acts like a template but is not a template because it's a button
// Basically there's if (selectedTemplateId === add-new2) do something else stuff all around invoice-settings-controller and outside
// Need to refactor this logic when rewriting
const addNewButton = {
  id: 'add-new2',
  unsavedChanges: false,
  value: 'Add New',
  deletable: false,
  editable: true,
  createNew: true,
  isDeleted: false,
};

const mapStateToProps = (state, props) => {
  const { onDiscardCancel, onDiscardConfirm, showDiscardDialog, type, onDelete, onSelect, onEdit, className } = props;

  const defaultTemplate = getFirmDefault() || getApplicationDefault();
  const selectedButtonId = getSelectedTemplateId();
  const defaultTemplateUnsavedChanges = selectedButtonId === defaultTemplate.id && props.unsavedChanges;
  const buttons = [];

  if (type === 'invoiceTemplates') {
    const invoiceTemplates = getInvoiceTemplates();
    let nonDefaultInvoiceTemplates = invoiceTemplates.filter((template) => !template.isDefault && !template.isDeleted);

    const iteratee = (sortBy) => (dataItem) => dataItem[sortBy].toLowerCase();
    nonDefaultInvoiceTemplates = sortByOrder(nonDefaultInvoiceTemplates, [iteratee('name')], ['asc']);

    nonDefaultInvoiceTemplates.forEach((template) => {
      if (!template.isDeleted) {
        const value = template.name && template.name.charAt(0).toUpperCase() + template.name.slice(1);
        const unsavedChanges = selectedButtonId === template.id && props.unsavedChanges;

        buttons.push({
          id: template.id,
          unsavedChanges,
          value,
          isDefault: template.isDefault,
          isDeleted: template.isDeleted,
          deletable: !template.isDefault,
          editable: false,
        });
      }
    });
  }

  // Add a default if there are no existing saved templates that have been designated default
  // Will add default on first load BEFORE FIRST AUTOMATIC SYNC
  if (!buttons.find((x) => x.isDefault)) {
    buttons.unshift({
      id: defaultTemplate.id,
      unsavedChanges: defaultTemplateUnsavedChanges,
      value: defaultTemplate.name,
      isDefault: true,
      isDeleted: false,
      deletable: false,
      editable: false,
    });
  }

  if (selectedButtonId === addNewButton.id) {
    addNewButton.editMode = true;
  } else {
    addNewButton.editMode = false;
  }

  buttons.push({ ...addNewButton });

  return {
    buttons,
    onDiscardCancel,
    onDiscardConfirm,
    showDiscardDialog,
    selectedButtonId,
    unsavedChanges: props.unsavedChanges,
    onSelect,
    onEdit,
    onDelete,
    className,
  };
};

const ButtonGroupContainer = withReduxStore(connect(mapStateToProps)(ButtonGroup));

ButtonGroupContainer.propTypes = {
  // Add new types when needed to this list
  onDiscardCancel: PropTypes.func,
  onDiscardConfirm: PropTypes.func,
  showDiscardDialog: PropTypes.bool,
  type: PropTypes.oneOf(['invoiceTemplates']).isRequired,
  unsavedChanges: PropTypes.bool,
  selectedButtonId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ButtonGroupContainer.defaultProps = {
  onDiscardCancel: () => {},
  onDiscardConfirm: () => {},
  selectedButtonId: undefined,
  showDiscardDialogOpen: false,
  unsavedChanges: false,
  className: undefined,
};

ButtonGroupContainer.displayName = 'ButtonGroupContainer';

export default ButtonGroupContainer;
