'use strict';

const validateTypeLabelsAndValues = ({ typeLabels, typeValues }) => {
  if (!typeLabels || typeof typeLabels !== 'object') {
    throw new Error('typeLabels must be an object with one or more keys');
  }

  if (!typeValues || typeof typeValues !== 'object') {
    throw new Error('typeValues must be an object with one or more keys');
  }

  const labelKeys = Object.keys(typeLabels);
  if (labelKeys.length === 0) {
    throw new Error('typeLabels should not be an empty object');
  }

  const valueKeys = Object.keys(typeValues);
  if (valueKeys.length === 0) {
    throw new Error('typeValues should not be an empty object');
  }

  if (labelKeys.length !== valueKeys.length) {
    throw new Error('count of labels and values must match');
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!labelKeys.every((labelKey) => typeValues.hasOwnProperty(labelKey))) {
    throw new Error('All keys for labels and values must match');
  }
};

module.exports = {
  validateTypeLabelsAndValues,
};
