import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { getInitCache } from 'web/services/apollo';

/**
 * useCacheQuery
 *
 * @param {*} query
 * @param {Object} queryOptions
 * @returns {Object} useQuery results
 */
export function useCacheQuery(query, queryOptions = {}) {
  const options = {
    ...queryOptions,
    fetchPolicy: 'cache-only',
  };

  const queryResults = useQuery(query, options);

  // The following section deals with the issue identified in BB-14495 (useCacheQuery
  // returning `undefined`) that happens when regular queries extend a cached entity's
  // dataset, then when that hook unmounts it clears the entire entity from cache,
  // forcing Apollo to refetch the initQuery.
  // We need to check specifically if the data is `undefined` meaning that it does
  // not exist in cache, as opposed to `null` which could be valid data. In that case
  // we return the value stored in sbInitCache which is a custom cache for init
  // queries that gets updated every time an init query is triggered (in bootstrap
  // and via refetch).
  // This ensures that the data coming from the `useCacheQuery` hook is always
  // available after bootstrap, as was originally intended.
  if (queryResults.data === undefined) {
    const queryName = getOperationName(query);
    const cachedResult = getInitCache({ queryName });

    return {
      ...queryResults,
      ...cachedResult,
    };
  }

  return queryResults;
}
