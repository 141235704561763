angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/view-matter/bills';

  $stateProvider
    .state('home.billing.view-matter.bills', {
      url: '/bills?selectedInvoiceStatus',
      templateUrl: `${BASE_DIRECTORY}/view-matter-bills.html`,
      controller: 'sbViewMatterBillsController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'bill-matter'],
        tab: { type: 'matter' }
      }
    });
});
