import { connect } from 'react-redux';
import { withReduxStore, withOnLoad, withTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import TrialBalanceFilter from './TrialBalanceFilter';

const { sortByProperty } = require('@sb-itops/nodash');

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const getBankAccountOptions = (t) => {
  const activeTrustAccounts = getActiveTrustAccounts();
  return sortByProperty(
    activeTrustAccounts.map((ba) => ({
      value: ba.id,
      label: getBankAccountName(ba, t),
    })),
    'label',
    'asc',
    false,
  );
};

const mapStateToProps = (state, { filters, t }) => ({
  bankAccountOptions: getBankAccountOptions(t),
  showFilterByEnteredDateToggle: hasFacet(facets.transactionsByEnteredDate),
  filters: filters
    ? {
        ...filters,
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters, t }) => ({
  onLoad: () => {
    if (!filters) {
      onFiltersChanged({
        endDate: dateToYYYYMMDD(new Date()),
        mattersWithNoBalance: false,
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        runByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
        errors: {},
        bankAccountId: getBankAccountOptions(t)?.[0]?.value,
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        endDate,
        errors: {
          ...filters.errors,
          endDate: !endDate,
        },
      });
    }
  },

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),

  onSelectAccount: (option) => onFiltersChanged({ ...filters, bankAccountId: option?.value }),
});

const TrialBalanceFilterContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(TrialBalanceFilter))),
);

TrialBalanceFilterContainer.displayName = `TrialBalanceFilterContainer`;

export default TrialBalanceFilterContainer;
