import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import BillingBankReconciliationsHome from './BillingBankReconciliationsHome';

const mapStateToProps = () => {
  const accountType = bankAccountTypeEnum.TRUST;
  return { accountType };
};

const BillingBankReconciliationsHomeContainer = withReduxStore(
  connect(mapStateToProps, null)(BillingBankReconciliationsHome),
);

BillingBankReconciliationsHomeContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string,
};

BillingBankReconciliationsHomeContainer.defaultProps = {
  trustAccountId: undefined,
};

export default BillingBankReconciliationsHomeContainer;
