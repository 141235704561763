import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { opdateCache, rollbackOpdateCache } from './index';
// Only to be used for Controlled Money Accounts and Trust account in GB
export const addBankFee = (feeDetails) => {
  if (!feeDetails?.accountType) {
    throw new Error('Account needs to have accountType specified.');
  }

  const addBankFeeThunk = async () => {
    const newFee = { ...feeDetails, versionId: uuid() };

    // Apply to save optimistically.
    const feeOpdate = buildOpdate(newFee);
    opdateCache({ optimisticEntities: [feeOpdate] });

    // Apply the save in the backend.
    try {
      const accountType = newFee.accountType.toUpperCase();
      const path = `/billing/bank-account/fee/:accountId/${accountType}`;
      const fetchOptions = { body: JSON.stringify(newFee) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [feeOpdate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(addBankFeeThunk);
};

function buildOpdate(feeDetails) {
  return {
    ...feeDetails,
    id: feeDetails.transactionId,
    type: transactionType.BankFees,
    amount: -feeDetails.amount,
    description: 'Bank Fees',
    note: feeDetails.internalNote,
    timestamp: new Date().toISOString(),
  };
}
