import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes, forms2PropTypes, TextEditor, SlidingToggle } from '@sb-itops/react';
import { Spinner } from '@sb-itops/react/spinner';
import Styles from './CreditCardSaveRequestForm.module.scss';

const { Forms2Field } = forms2PropTypes;

export const CreditCardSaveRequestForm = React.memo(
  ({
    isPreviewMode,
    previewSubject,
    previewMessage,
    // fields
    to,
    from,
    subject,
    message,
    sendCopyToUser,
    staffAddress,
    // form and callbacks
    onUpdateFieldValues,
    formInitialised,
    submitFailed,
    formDisabled,
    onPreviewToggle,
  }) => {
    if (!formInitialised) {
      return <Spinner />;
    }

    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
    const handleFieldChange = (fieldName) => (e) => {
      onUpdateFieldValues(fieldName, e.target.value);
    };
    const onChangeMessage = (value, delta, source) => {
      if (source !== 'user') {
        return;
      }
      onUpdateFieldValues('message', value);
    };

    return (
      <div className={Styles.creditCardSaveRequestForm}>
        <fieldset disabled={formDisabled}>
          <div className="row">
            <div className="col-lg-12 form-group">
              <label>To</label>
              <input
                className={classnames('form-control', isErrorClassnames(to))}
                type="text"
                value={to.value || ''}
                onChange={handleFieldChange('to')}
              />
              {isErrorClassnames(to) && <div className={Styles.errorMessage}>{to?.invalidReason}</div>}
            </div>
          </div>

          <div className={classnames('form-group', Styles.formGroupInline)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={Styles.sendCopyLabel}
              htmlFor="sendCopySlider"
              onClick={(e) => {
                e.preventDefault();
                onUpdateFieldValues('sendCopyToUser', !sendCopyToUser.value);
              }}
            >
              <SlidingToggle
                id="sendCopySlider"
                scope="sendCopySlider"
                selected={sendCopyToUser.value}
                disabled={formDisabled}
              />
              <div>Send a copy of the email to me ({staffAddress.value})</div>
            </label>
          </div>

          <div className="row">
            <div className="col-lg-12 form-group">
              <label>From</label>
              <input
                className={classnames('form-control', isErrorClassnames(from))}
                type="text"
                value={from.value || ''}
                onChange={handleFieldChange('from')}
              />
              {isErrorClassnames(from) && <div className={Styles.errorMessage}>{from?.invalidReason}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 form-group">
              <label>Subject</label>
              <input
                className={classnames('form-control', isErrorClassnames(subject))}
                type="text"
                value={isPreviewMode ? previewSubject : subject.value}
                disabled={isPreviewMode}
                onChange={handleFieldChange('subject')}
              />
            </div>
          </div>
          <div className="row">
            <div className={classnames('form-group', 'col-lg-12', Styles.textEditor)}>
              <label>Message</label>

              <TextEditor
                className={isErrorClassnames(message)}
                value={isPreviewMode ? previewMessage : message.value}
                disabled={isPreviewMode}
                onChange={onChangeMessage}
              />

              <div className={Styles.textEditorButtonPanel} title={isPreviewMode ? `Edit Email` : `Preview Email`}>
                <Button className={Styles.previewButton} type={buttonTypes.secondary} onClick={onPreviewToggle}>
                  {isPreviewMode ? `Edit Email` : `Preview Email`}
                </Button>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    );
  },
);

CreditCardSaveRequestForm.displayName = 'CreditCardSaveRequestForm';

CreditCardSaveRequestForm.propTypes = {
  isPreviewMode: PropTypes.bool.isRequired,
  previewSubject: PropTypes.string.isRequired,
  previewMessage: PropTypes.string.isRequired,
  // form fields
  to: Forms2Field,
  from: Forms2Field,
  subject: Forms2Field,
  message: Forms2Field,
  sendCopyToUser: Forms2Field,
  staffAddress: Forms2Field,
  // form & callbacks
  onUpdateFieldValues: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool,
  submitFailed: PropTypes.bool,
  formDisabled: PropTypes.bool,
  onPreviewToggle: PropTypes.func.isRequired,
};

CreditCardSaveRequestForm.defaultProps = {
  // form fields
  to: undefined,
  from: undefined,
  subject: undefined,
  message: undefined,
  sendCopyToUser: undefined,
  staffAddress: undefined,
  // form & callbacks
  formInitialised: false,
  submitFailed: false,
  formDisabled: false,
};
