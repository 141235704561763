import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { InvoiceEmailModal } from 'web/components';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { payButtonEnabledOptionValues } from '@sb-billing/business-logic/matters/invoice-settings';
import {
  formatStaffMemberEmailAddress,
  modifyEmailBody,
  modifyEmailSubject,
} from '@sb-billing/business-logic/invoice-emailing';
import { getLogger } from '@sb-itops/fe-logger';
import { buildContactToAddress } from '@sb-customer-management/business-logic/contacts/services';

import { regionType } from '@sb-itops/region';
import { invoiceEmailFormSchema } from './InvoiceEmailForm.yup';

const log = getLogger('InvoiceEmailModal.forms.container');

/**
 * Generates a readonly "toAddress"
 *
 * For cases such as: selecting multiple invoices with multiple debtors
 *
 * @param {object} params
 * @param {string[]} params.debtorIds
 * @param {string[]} params.invoiceIds
 * @returns string
 */
function buildReadOnlyToAddress({ debtorIds, invoiceIds }) {
  const invoicesCount = invoiceIds.length;
  const debtorsCount = debtorIds.length;
  const multipleDebtors = debtorsCount > 1;
  const multipleInvoices = invoicesCount > 1;

  if (!multipleDebtors || !multipleInvoices) {
    return undefined;
  }

  const readOnlyToAddress = `${invoicesCount} invoices will be emailed to ${debtorsCount} debtors`;
  return readOnlyToAddress;
}

/**
 * Transforms the form data in preparation to send it via email
 *
 * @param {object} params
 * @param {boolean} params.consolidate
 * @param {string[]} params.debtorIds
 * @param {object} params.formValues
 * @param {string[]} params.invoiceIds
 * @param {object} params.invoiceTotalDurationsByIdMap
 * @returns {object[]}
 */
function convertToInvoiceEmailRequests({
  consolidate,
  debtorIds,
  formValues,
  invoiceIds,
  invoiceTotalDurationsByIdMap,
}) {
  if (!formValues || !invoiceIds || !debtorIds) {
    return [];
  }

  let bcc = formValues.bccAddress;
  if (formValues.sendCopyToMe) {
    bcc = formValues.bccAddress ? `${formValues.bccAddress}, ${formValues.staffAddress}` : formValues.staffAddress;
  }

  const invoiceEmailRequests = [
    {
      invoiceIds,
      invoiceTotalDurationsByIdMap,
      debtorId: debtorIds.length === 1 ? debtorIds[0] : undefined,
      consolidate, // Optional, request that only a single email is sent even if N > 1 invoices.
      combine: formValues.combineAttachments, // All pdf attachments will be combined into single pdf
      useMatterCcBcc: formValues.readOnlyCcBcc, // when CC/BCC is readonly in UI (multiple matters and multiple debtors). We need to know as we want to replace CC/BCC with matter defaults.
      template: {
        toAddress: formValues.readOnlyToAddress ? '' : formValues.toAddress,
        replyToAddress: formValues.fromAddress,
        bcc,
        cc: formValues.ccAddress,
        subject: formValues.subject,
        message: formValues.message,
      },
    },
  ];

  return invoiceEmailRequests;
}

const hooks = () => ({
  useInvoiceEmailModalForm: ({
    anyMatterHasPayButtonEnabled,
    anyMatterHasPayButtonDisabled,
    bccAddresses,
    ccAddresses,
    debtorId,
    debtorIds,
    firmCanIncludePaymentLinks,
    firmInvoiceEmailSettings,
    invoiceEmailModalData,
    invoiceIds,
    invoiceTotalDurationsByIdMap,
    isConsolidatedMode,
    isLoading: isLoadingQuery,
    isReadOnlyToAddress,
    loggedInStaffMember,
    matterIds,
    region,
    scope,
    showPreviewButton,
    // Callbacks
    onModalClose,
    onPreview,
    onSendEmails,
  }) => {
    const invoiceEmailForm = useForm({ scope, schema: invoiceEmailFormSchema });
    const { formInitialised, formFields, formSubmitting, formValid } = invoiceEmailForm;

    // When sending multiple invoice emails and multiple matters are involved:
    //  * If any matter has ENABLED pay buttons:
    //    - Pay buttons are displayed on the invoice email modal message field
    //  * And if any matter has DISABLED pay buttons:
    //    - A message is displayed to the user, informing them that the "disabled pay button" matters will not show the pay button
    //
    // NB: In the actual invoice email, each matter's pay button option will be used to display or hide pay buttons
    const showDisabledPayButtonsMessage = anyMatterHasPayButtonEnabled && !!anyMatterHasPayButtonDisabled;

    /**
     * Email preview
     */
    // Previews are not available for multi invoice submissions
    const [isLoadingPreview, setIsPreviewLoading] = React.useState(showPreviewButton);
    const [isPreviewMode, setIsPreviewMode] = React.useState(showPreviewButton);
    const [previewSubject, setPreviewSubject] = React.useState('');
    const [previewMessage, setPreviewMessage] = React.useState('');

    function onPreviewToggled() {
      const switchingToPreviewMode = !isPreviewMode;

      if (switchingToPreviewMode) {
        setPreviewValues({ formValues: invoiceEmailForm.formValues });
      }

      setIsPreviewMode(!isPreviewMode);
    }

    async function setPreviewValues({ formValues }) {
      const invoiceEmailRequests = convertToInvoiceEmailRequests({
        consolidate: isConsolidatedMode,
        debtorIds,
        formValues,
        invoiceIds,
        invoiceTotalDurationsByIdMap,
      });

      // Interpolates the email subject and body, providing a preview of the email
      const previewValues = await onPreview({
        invoiceEmailRequest: invoiceEmailRequests[0], // A single email is previewed at a time
      });

      setPreviewSubject(previewValues.subject);
      setPreviewMessage(previewValues.message);
    }

    React.useEffect(
      () => () => invoiceEmailForm.onClearForm(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    /**
     * Form initialisation
     */
    const initialiseForm = !isLoadingQuery && !formInitialised && !!matterIds && !!debtorIds;

    function getDefaulFormValues() {
      const { emailBody, emailSubject, sendCopyToUser } = firmInvoiceEmailSettings;

      /** "CC" and "BCC" fields */
      const readOnlyCcBcc = matterIds.length > 1 && debtorIds.length > 1;

      /** "Send copy.." and "From" fields */
      const formattedStaffMemberEmailAddress = formatStaffMemberEmailAddress({ staffMember: loggedInStaffMember });

      /** "Message" field */
      // The pay button/link is shown/hidden based on:
      // 1. Firm settings
      // 2. Matter(s) settings
      const showPayButtons = firmCanIncludePaymentLinks && anyMatterHasPayButtonEnabled;

      /** "To" field */
      // While normally invoices with multiple debtors would use the multi-tab form, sometimes the "no tab" form can be used (e.g. for a single debtor)
      // Such a scenario can happen on the Contacts page > Invoices tab
      //  * Invoice emails are scoped to the contact (i.e. the contact whose page we are on)
      //  * In such cases, we pass that contact's debtorId to be used over the invoice's debtorIds
      const getRelevantContact = () => {
        const invoiceDebtors = invoiceEmailModalData.invoices[0].debtors;

        // Return the relevant contact (they may not be ordered first)
        if (debtorId) {
          const specifiedDebtor = invoiceDebtors.find((debtor) => debtor.id === debtorId);
          return specifiedDebtor.contact;
        }

        // Otherwise, return the single contact
        return invoiceDebtors[0].contact;
      };

      const defaultValues = {
        bccAddress: readOnlyCcBcc ? '' : bccAddresses.join(', '),
        ccAddress: readOnlyCcBcc ? '' : ccAddresses.join(', '),
        combineAttachments: hasFacet(facets.combineAttachment) && isConsolidatedMode,
        fromAddress: formattedStaffMemberEmailAddress,
        message: modifyEmailBody({ isConsolidatedMode, showPayButtons, emailBody }),
        originalValue: modifyEmailBody({ isConsolidatedMode, showPayButtons, emailBody }),
        readOnlyCcBcc,
        readOnlyToAddress: isReadOnlyToAddress ? buildReadOnlyToAddress({ debtorIds, invoiceIds }) : undefined,
        sendCopyToMe: sendCopyToUser,
        staffAddress: formattedStaffMemberEmailAddress,
        subject: modifyEmailSubject({ isConsolidatedMode, emailSubject }),
        toAddress: isReadOnlyToAddress ? undefined : buildContactToAddress({ contact: getRelevantContact(), region }),
      };

      return defaultValues;
    }

    React.useEffect(() => {
      (async () => {
        if (initialiseForm) {
          const defaultValues = getDefaulFormValues();
          invoiceEmailForm.onInitialiseForm(defaultValues);

          // Sometimes the preview mode is not available (skip if so)
          // E.g. Multi invoices
          if (showPreviewButton) {
            await setPreviewValues({ formValues: defaultValues });
            setIsPreviewLoading(false);
          }

          invoiceEmailForm.onValidateForm();
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialiseForm, showPreviewButton]);

    /**
     * Form updates
     */
    const [generateEmailPreview, setGenerateEmailPreview] = React.useState(true);

    function onUpdateFormData({ fieldUpdates }) {
      invoiceEmailForm.onUpdateFields(fieldUpdates);
      invoiceEmailForm.onValidateForm();
    }

    function onFieldValueUpdated(field, newValue) {
      const newFieldValue = {
        [field]: newValue,
      };

      onUpdateFormData({ fieldUpdates: newFieldValue });
    }

    /**
     * Form submission
     */
    async function onFormSubmit() {
      try {
        invoiceEmailForm.onValidateForm();

        const isValid = await invoiceEmailForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            const invoiceEmailRequests = convertToInvoiceEmailRequests({
              consolidate: isConsolidatedMode,
              debtorIds,
              formValues: formData,
              invoiceIds,
              invoiceTotalDurationsByIdMap,
            });

            onSendEmails({ invoiceEmailRequests });
          },
        });

        if (!isValid) {
          log.warn('Invoice email modal form validation failed');
          return;
        }

        onModalClose();
      } catch (error) {
        log.error('Failed to send invoice email', error);
      }
    }

    return {
      debtorIds,
      formInitialised,
      ...formFields,
      generateEmailPreview,
      isLoading: isLoadingQuery || isLoadingPreview,
      isPreviewMode,
      isSubmitDisabled: !formValid || formSubmitting,
      previewMessage,
      previewSubject,
      showCombineAttachments: hasFacet(facets.combineAttachment) && isConsolidatedMode,
      showDisabledPayButtonsMessage,
      onFieldValueUpdated,
      onPreviewToggled,
      onSend: onFormSubmit,
      onSetGenerateEmailPreview: setGenerateEmailPreview,
      onUpdateFormData,
    };
  },
});

const dependentHooks = () => ({});

export const InvoiceEmailModalFormsContainer = composeHooks(hooks)(composeHooks(dependentHooks)(InvoiceEmailModal));

InvoiceEmailModalFormsContainer.displayName = 'InvoiceEmailModalFormsContainer';

const DebtorEntityType = PropTypes.shape({
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customLetterName: PropTypes.string,
    displayNameFirstLast: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    letterNameFormat: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
});

const MatterEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  matterEmailSettings: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bccAddresses: PropTypes.arrayOf(PropTypes.string),
    ccAddresses: PropTypes.arrayOf(PropTypes.string),
  }),
  matterInvoiceSettings: PropTypes.shape({
    id: PropTypes.string.isRequired,
    payButtonEnabledOption: PropTypes.oneOf(Object.values(payButtonEnabledOptionValues)),
  }),
});

InvoiceEmailModalFormsContainer.propTypes = {
  anyMatterHasPayButtonEnabled: PropTypes.bool.isRequired,
  anyMatterHasPayButtonDisabled: PropTypes.bool,
  bccAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  ccAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  debtorId: PropTypes.string,
  debtorIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  firmCanIncludePaymentLinks: PropTypes.bool.isRequired,
  firmInvoiceEmailSettings: PropTypes.shape({
    emailBody: PropTypes.string.isRequired,
    emailSubject: PropTypes.string.isRequired,
    sendCopyToUser: PropTypes.bool.isRequired,
  }).isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  invoiceEmailModalData: PropTypes.shape({
    invoice: PropTypes.shape({
      id: PropTypes.string.isRequired,
      debtors: PropTypes.arrayOf(DebtorEntityType),
      matter: MatterEntityType,
    }),
  }), // Required, but is undefined while fetching data
  invoiceTotalDurationsByIdMap: PropTypes.object.isRequired,
  isConsolidatedMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isReadOnlyToAddress: PropTypes.bool.isRequired,
  loggedInStaffMember: PropTypes.object.isRequired,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  showIncludeDebtor: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  showPreviewButton: PropTypes.bool.isRequired,
  isMultiTabForm: PropTypes.bool.isRequired,
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSendEmails: PropTypes.func.isRequired,
};

InvoiceEmailModalFormsContainer.defaultProps = {
  anyMatterHasPayButtonDisabled: undefined,
  debtorId: undefined,
  invoiceEmailModalData: undefined,
};
