'use strict';

const { hasFacet, facets } = require('@sb-itops/region-facets');

const { CORRESPONDENCE_PSEUDO_STATUS, sentViaTypeLabels, sentViaTypes } = require('../correspondence-history');
const { billingEventPseudoType } = require('../shared/entities');

const filterOptions = {
  invoiceStatus: [
    { id: 'DRAFT', label: 'Draft' },
    { id: 'FINAL', label: 'Unpaid' },
    { id: 'OVERDUE', label: 'Overdue' },
    { id: 'PAID', label: 'Paid' },
    { id: 'VOID', label: 'Void' },
  ],
  sentStatus: [
    { id: CORRESPONDENCE_PSEUDO_STATUS.sent, label: 'Sent' },
    { id: CORRESPONDENCE_PSEUDO_STATUS.failed, label: 'Failed' },
    { id: CORRESPONDENCE_PSEUDO_STATUS.notSent, label: 'Not sent' },
  ],
  sendPreferences: [
    // id values are strings because the components and related logic expect them to be strings, pass them as number would lead to unexpected results.
    { id: String(sentViaTypes.EMAIL), label: sentViaTypeLabels.EMAIL },
    { id: String(sentViaTypes.COMMUNICATE), label: sentViaTypeLabels.COMMUNICATE },
    { id: String(sentViaTypes.MAIL), label: sentViaTypeLabels.MAIL },
    ...(hasFacet(facets.eBillingSendingPreference)
      ? [{ id: String(sentViaTypes.E_BILLING), label: sentViaTypeLabels.E_BILLING }]
      : []),
    { id: String(sentViaTypes.OTHER), label: sentViaTypeLabels.OTHER },
  ],
  billingEventStatus: [{ id: billingEventPseudoType.INVOICE_VIEWED_ONLINE, label: 'Viewed' }],
  billingType: [
    { id: 'time', label: 'Time Based' },
    { id: 'fixed', label: 'Fixed' },
    ...(hasFacet(facets.contingencyBillingType) ? [{ id: 'contingency', label: 'Contingency' }] : []),
  ],
  matterStatus: [
    { id: 'Open', label: 'Open' },
    { id: 'Pending', label: 'Pending' },
    { id: 'Closed', label: 'Closed' },
    { id: 'Cancelled', label: 'Cancelled' },
    { id: 'Deleted', label: 'Deleted' },
  ],
};

module.exports = {
  filterOptions,
};
