import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { SlidingToggle } from '@sb-itops/react';

import { TrustAccountsOverviewTable } from '@sb-billing/react';
import Styles from './TrustAccountsOverview.module.scss';

export const TrustAccountsOverview = ({
  showClosedAccounts,
  toggleClosedAccounts,
  trustAccounts,
  displayColumns,
  onClickRow,
  sortBy,
  sortDirection,
  onAccountsSort,
  onClickLink,
  supportsBsbAccountNumber,
}) => (
  <div className={classnames('panel-body', Styles.trustAccountsOverview)}>
    <div className={classnames('ribbon', 'panel', 'panel-primary', Styles.ribbon)}>
      <div className={Styles.toggleContainer}>
        <SlidingToggle
          scope="showClosedAccounts"
          name="showClosedAccounts"
          selected={showClosedAccounts}
          onChange={toggleClosedAccounts}
        />
        <div className={Styles.labelContainer}>Show Closed Accounts</div>
      </div>
    </div>
    <TrustAccountsOverviewTable
      trustAccounts={trustAccounts}
      displayColumns={displayColumns}
      onClickRow={onClickRow}
      onClickLink={onClickLink}
      onSort={onAccountsSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      supportsBsbAccountNumber={supportsBsbAccountNumber}
    />
  </div>
);

TrustAccountsOverview.displayName = 'TrustAccountsOverview';

TrustAccountsOverview.propTypes = {
  showClosedAccounts: PropTypes.bool,
  toggleClosedAccounts: PropTypes.func.isRequired,
  parentPage: PropTypes.string,
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      displayName: PropTypes.string,
      bankName: PropTypes.string,
      branch: PropTypes.string,
      bsb: PropTypes.string,
      accountNumber: PropTypes.string,
      status: PropTypes.number,
      balance: PropTypes.number,
    }),
  ),
  displayColumns: PropTypes.object,
  onClickRow: PropTypes.func.isRequired,
  onAccountsSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  supportsBsbAccountNumber: PropTypes.bool.isRequired,
};

TrustAccountsOverview.defaultProps = {
  sortDirection: undefined,
  sortBy: undefined,
  showClosedAccounts: false,
  trustAccounts: [],
  parentPage: '',
  displayColumns: undefined,
};
