import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

// TODO this should be written with something that will allow us to have a meaningful event (such as a checkbox)

/**
 * @param {*} props
 */
const ExpandCollapseToggler = (props) => {
  const { isExpanded, tabIndex, className } = props;
  return (
    <span
      className={classnames('expand-collapse-toggler', className)}
      onClick={(event) => {
        event.stopPropagation();
        props.onToggled();
      }}
      role="button"
      tabIndex={tabIndex}
    >
      <i className={isExpanded ? 'open-arrow' : 'closed-arrow'} />
    </span>
  );
};

ExpandCollapseToggler.displayName = 'ExpandCollapseToggler';

ExpandCollapseToggler.propTypes = {
  isExpanded: PropTypes.bool,
  onToggled: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
};

ExpandCollapseToggler.defaultProps = {
  tabIndex: undefined,
  isExpanded: false,
  className: undefined,
};

export default ExpandCollapseToggler;
