'use strict';

/**
 * Override template sectionOptions settings with final settings from draft invoice screen.
 *
 * @param {object} originalTemplateSectionOptions - Original invoice template sectionOptions.
 * @param {object} finalAdditionalOptions - Final additional options derived from saved draft invoice and user selection on draft invoice screen
 * @returns {object} - Updated invoice section options used for PDF generation purpose.
 */
const overrideTemplateSectionOptions = ({ originalTemplateSectionOptions, finalAdditionalOptions }) => {
  const {
    showSummaryForTimekeepers,
    showTimekeeperRole,
    hidePriorBalance,
    hidePaymentSummary,
    showInvoiceSummary,
    showAccountSummary,
    showTransactionHistory,
  } = finalAdditionalOptions;
  const updatedSectionOptions = JSON.parse(JSON.stringify(originalTemplateSectionOptions));

  // When the original supplementary tables setting is different from the finalAdditionalOptions, we overwrite the value.
  // For example, if users only set some tables as shown in Firm template settings, but they tick more on the draft invoice page,
  // we can treat it like page break = false for all of the new tables that were ticked.
  if (showSummaryForTimekeepers !== updatedSectionOptions.feeSummary.showSection) {
    updatedSectionOptions.feeSummary.showSection = showSummaryForTimekeepers;

    if (showSummaryForTimekeepers) {
      updatedSectionOptions.feeSummary.lineBreakBefore = false;
    }
  }
  updatedSectionOptions.feeSummary.showTimekeeperRole = showTimekeeperRole;

  if (!hidePriorBalance !== updatedSectionOptions.priorBalances.showSection) {
    updatedSectionOptions.priorBalances.showSection = !hidePriorBalance;

    if (!hidePriorBalance) {
      updatedSectionOptions.priorBalances.lineBreakBefore = false;
    }
  }

  if (!hidePaymentSummary !== updatedSectionOptions.paymentSummary.showSection) {
    updatedSectionOptions.paymentSummary.showSection = !hidePaymentSummary;

    if (!hidePaymentSummary) {
      updatedSectionOptions.paymentSummary.lineBreakBefore = false;
    }
  }

  if (showInvoiceSummary !== updatedSectionOptions.invoiceSummary.showSection) {
    updatedSectionOptions.invoiceSummary.showSection = showInvoiceSummary;

    if (showInvoiceSummary) {
      updatedSectionOptions.invoiceSummary.lineBreakBefore = false;
    }
  }

  if (showAccountSummary !== updatedSectionOptions.accountSummary.showSection) {
    updatedSectionOptions.accountSummary.showSection = showAccountSummary;

    if (showAccountSummary) {
      updatedSectionOptions.accountSummary.lineBreakBefore = false;
    }
  }

  if (showTransactionHistory !== updatedSectionOptions.transactionHistory.showSection) {
    updatedSectionOptions.transactionHistory.showSection = showTransactionHistory;

    if (showTransactionHistory) {
      updatedSectionOptions.transactionHistory.lineBreakBefore = false;
    }
  }

  return updatedSectionOptions;
};

module.exports = {
  overrideTemplateSectionOptions,
};
