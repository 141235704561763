import { gql } from '@apollo/client';

const query = gql`
  query StaffTargetGroupUniqueNameCheck($name: String!, $excludeStaffTargetGroupIds: [ID]) {
    staffTargetGroupUniqueNameCheck(name: $name, excludeStaffTargetGroupIds: $excludeStaffTargetGroupIds) {
      isUnique
    }
  }
`;

const notificationIds = ['WebQueryFeesNotifications.StaffTargetGroupUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const StaffTargetGroupUniqueNameCheck = {
  query,
  notificationIds,
};
