import PropTypes from 'prop-types';
import React from 'react';
import lawpayLogo from 'web/assets/lawpay-transparent-logo.png';
import Styles from './LawpaySettings.module.scss';
import { LawpaySettingsForm } from '../lawpay-settings-form.3';
import { LawpayGetStartedCard } from './LawpayGetStartedCard';

export const LawpaySettings = ({ showForm, onConnect, isSaving, onSaveSettings }) =>
  showForm ? (
    <div className={Styles.lawpaySettingsForm}>
      <LawpaySettingsForm isSaving={isSaving} onSaveSettings={onSaveSettings} />
    </div>
  ) : (
    <div className={Styles.lawpayApplicationForm}>
      <div className={Styles.paymentsLogoContainer}>
        <img className={Styles.logo} src={lawpayLogo} alt="LawPay" />
        <span className={Styles.logoText}>Payments Application</span>
      </div>
      <LawpayGetStartedCard onConnect={onConnect} />
    </div>
  );

LawpaySettings.displayName = 'LawpaySettings';

LawpaySettings.propTypes = {
  showForm: PropTypes.bool.isRequired,
  onConnect: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
