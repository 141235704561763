import { gql } from '@apollo/client';

const query = gql`
  query StaffTargetAssignments($staffMemberFilter: StaffMemberFilter) {
    staffMembers(filter: $staffMemberFilter) {
      id
      isFormerStaff
      name
      feeConfiguration {
        id
        staffTargetGroupId
      }
    }
  }
`;

const notificationIds = ['FeesNotifications.StaffFeeConfigurationUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const StaffTargetAssignments = {
  query,
  notificationIds,
};
