import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation, buttonTypes } from '@sb-itops/react';
import Styles from './DraftInvoiceDeleteProceedModal.module.scss';

function buildModalTitle({ isBulkDeletion }) {
  const modalTitle = `${isBulkDeletion ? 'Bulk ' : ''}Delete Draft Invoice${isBulkDeletion ? 's' : ''}`;
  return modalTitle;
}

function buildModalBody({ isBulkDeletion, t }) {
  return (
    <div>
      <p>{`Are you sure you want to ${isBulkDeletion ? 'batch' : ''} delete ${
        isBulkDeletion ? 'the selected' : 'this'
      } draft invoice${isBulkDeletion ? 's' : ''}?`}</p>
      <p>
        {`If you proceed, all Time, Fee, and ${t('capitalizeAllWords', {
          val: 'expense',
        })} entries associated with the deleted draft invoice${
          isBulkDeletion ? 's' : ''
        } will be unassigned and you will be able to add them to another invoice.`}
      </p>
    </div>
  );
}

const DraftInvoiceDeleteProceedModal = memo(
  ({
    isBulkDeletion,
    isVisible,
    // Callbacks
    onClose,
    onProceedClick,
  }) => {
    const { t } = useTranslation();

    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        isVisible={isVisible}
        title={buildModalTitle({ isBulkDeletion })}
        body={buildModalBody({ isBulkDeletion, t })}
        footer={
          <div className={Styles.buttonContainer}>
            <Button onClick={onProceedClick} type={buttonTypes.warning}>
              {`Delete Draft${isBulkDeletion ? 's' : ''}`}
            </Button>
            <Button onClick={onClose} type={buttonTypes.secondary}>
              Cancel
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

DraftInvoiceDeleteProceedModal.displayName = 'DraftInvoiceDeleteProceedModal';

DraftInvoiceDeleteProceedModal.propTypes = {
  isBulkDeletion: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  // Callbacks
  onClose: PropTypes.func.isRequired,
  onProceedClick: PropTypes.func.isRequired,
};

export default DraftInvoiceDeleteProceedModal;
