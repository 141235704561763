'use strict';

// const { Money } = require('@sb-itops/money');

/**
 * @typedef {object} TaxSplit
 * @property {Money} taxable
 * @property {Money} tax
 */

/**
 * Defines the interface for a tax strategy as well as providing the default behaviour of no tax.
 */
class TaxStrategy {
  /**
   * @param {Money} money
   * @throws if money is not of type Money
   * @throws if money is -ve
   * @returns {TaxSplit}
   */
  // eslint-disable-next-line class-methods-use-this
  calculate(money) {
    // if (!(money instanceof Money)) {
    //   throw new Error(`money must be of type Money: ${JSON.stringify(money)}`);
    // }

    if (money.cents() < 0) {
      // eslint-disable-next-line no-console
      console.error(`money cannot be negative: ${JSON.stringify(money)}`);
    }
  }
}

module.exports = { TaxStrategy };
