'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/bills/bulk-bill';

  $stateProvider
    .state('home.billing.bills.bulk-bill', {
      url: '/bulk-bill',
      templateUrl: BASE_DIRECTORY + '/bulk-bill.html',
      controller: 'BulkBillController',
      controllerAs: 'bulkBill',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills', 'create-bulk-bills']
      }
    });

});
