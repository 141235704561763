/**
 * A Higher-Order Reducer that will check the action for the `sbPath` property, only sending the action to the supplied reducer if
 * `sbPath` matches the supplied `reduxPath`.
 *
 * For use with conjunction with `sbPathMiddleware`.
 *
 * @param {String} reducerArea A string representing the location of the code that uses the section of the redux store.
 * @param {function()} reducer The reducer function in the form of (state, action) => newState.
 */
export const createSbPathReducer =
  ({ reduxPath, reducer }) =>
  (state, action) => {
    const { sbPath } = action;

    const isInitializationCall = state === undefined;

    if (sbPath !== reduxPath && !isInitializationCall) {
      return state;
    }

    return reducer(state, action);
  };
