'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/post-authentication';

  $stateProvider
    .state('post-authentication', {
      templateUrl: BASE_DIRECTORY + '/post-authentication.html',
      controller: 'sbPostAuthenticationController',
      controllerAs: '$ctrl'
    });
});
