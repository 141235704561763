import uuid from '@sb-itops/uuid';
import { getDefaultTaxRate } from '@sb-billing/business-logic/shared/services/tax';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { store, cacheFactory, syncTypes } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import domain from '../domain';

const getAccountId = () => authSelectors.getAccountId(store.getState());

const api = cacheFactory({
  domain,
  name: 'gst-tax-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbGstTaxSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
});

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbGstTaxSettingsService',
  keyPath: 'accountId',
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getTaxRate = () => (getMap()[getAccountId()] && getMap()[getAccountId()].taxRate) || 0;

export const getTaxSettings = () => getMap()[getAccountId()];

export const createTaxSetting = (gstTaxSettingsToSave) => {
  const gstTaxSettings = { ...gstTaxSettingsToSave };

  const createTaxSettingsThunk = async () => {
    opdateCache({ optimisticEntities: [gstTaxSettings] });
    try {
      const path = '/billing/gst-tax-settings/:accountId/';
      const fetchOptions = { body: JSON.stringify(gstTaxSettings) };
      const response = await fetchPostP({ path, fetchOptions });
      return response;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [{ ...gstTaxSettings }] });
      throw err;
    }
  };
  return store.dispatch(createTaxSettingsThunk);
};

export const createDefaultTaxSettings = () => {
  const firmGstTaxSettings = getTaxSettings();
  if (!firmGstTaxSettings) {
    const defaultGstTaxSettings = {
      accountId: getAccountId(),
      versionId: uuid(),
      taxRate: getDefaultTaxRate(), // in basis points
      registeredForGst: true,
      isDefaultSetting: true,
    };
    return createTaxSetting(defaultGstTaxSettings);
  }
  return firmGstTaxSettings;
};
