angular.module('sb.billing.webapp').directive('sbBankAccountTransactionDetails', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: { transactionId: '<', transaction: '<', matterId: '<', contactId: '<', showHidden: '<', onClose: '&?' },
    controller: 'SbBankAccountTransactionDetailsController',
    controllerAs: 'bankAccountTransactionDetails',
    templateUrl: 'ng-components/bank-account-transaction-details/bank-account-transaction-details.html'
  };
});
