const { isValidDepositSlipTransaction } = require('@sb-billing/business-logic/deposit-slips/services');
const { consolidateTrustTransactions, consolidateOperatingTransactions } = require('@sb-billing/business-logic/transactions/services');
const { bankAccountTypeEnum } = require('@sb-billing/business-logic/bank-account/entities/constants');
const { getById: getBulkDepositById } = require('@sb-billing/redux/bulk-deposit');
 
angular.module('sb.billing.webapp').component('sbDataDeposits', {
  require: { composer: '^sbCompose' },
  bindings: { filter: '<?', showReconciled: '<?', entityDataKey: '@', accountType: '<' },
  controller: function ($scope, sbLoggerService, sbTransactionService, sbLocalisationService) {
    'use strict';
    const that = this;
    const log = sbLoggerService.getLogger('sbDataDeposits');
    const entityDataKey = that.entityDataKey || 'deposits';

    // log.setLogLevel('info');

    that.$onChanges = (changes) => {
      log.info('bindings changed:', changes);
      updateTransactions();
    };

    $scope.$on('smokeball-data-update-sbTransactionService', () => {
      log.info('saw data update');
      updateTransactions();
    });

    $scope.$on('funds-deposited', () => {
      log.info('saw funds deposited');
      updateTransactions();
    });

    function updateTransactions () {
      if (that.filter) {
        const deposits = sbTransactionService.getByFilter((tx) => isValidDepositSlipTransaction(tx) && that.filter(tx)) || [];
        let consolidatedTransactions = deposits;
        if (that.accountType === bankAccountTypeEnum.TRUST) {
          consolidatedTransactions = consolidateTrustTransactions({
            transactions: deposits,
            getPaymentById: () => {},
            getInvoiceNumberById: () => {},
            getBulkDepositById,
            t: sbLocalisationService.t,
            consolidatedOnly: false,
          });
        } else if(that.accountType === bankAccountTypeEnum.OPERATING) {
          consolidatedTransactions = consolidateOperatingTransactions({
            transactions: deposits,
            t: sbLocalisationService.t,
          });
        }

        log.info('deposits: ', consolidatedTransactions);
        that.composer.setComposeData(consolidatedTransactions, entityDataKey);
      }
    }
  }
});
