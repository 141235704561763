import PropTypes from 'prop-types';
import React from 'react';
import { findPaymentEvents } from '@sb-billing/redux/payment-plan-events/selectors';
import { getContactPaymentMethodById } from '@sb-billing/redux/customer-billing-configuration';
import { buildPaymentPlanInformationList } from './build-payment-plan-information-list';
import PaymentPlanInformationBoxes from './PaymentPlanInformationBoxes';

const PaymentPlanInformationBoxesContainer = ({ paymentPlan, paymentPlanEvents }) => {
  const paymentPlanPaymentEvents = findPaymentEvents(paymentPlanEvents, { paymentPlanId: paymentPlan.id });
  const paymentMethod = getContactPaymentMethodById({
    contactId: paymentPlan.debtorId,
    paymentMethodId: paymentPlan.paymentMethodId,
  });
  return (
    <PaymentPlanInformationBoxes
      paymentPlanInformationList={buildPaymentPlanInformationList({
        paymentPlan,
        paymentPlanPaymentEvents,
        paymentMethod,
      })}
    />
  );
};
PaymentPlanInformationBoxesContainer.propTypes = {
  paymentPlan: PropTypes.object.isRequired,
  paymentPlanEvents: PropTypes.array.isRequired,
};

export default PaymentPlanInformationBoxesContainer;
