import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyInput2, StatelessSelect, useTranslation } from '@sb-itops/react';
import { surchargeTypeValues, surchargeApplyToOptions } from '@sb-billing/business-logic/invoice-surcharge';
import { BasisPointInput } from '../basis-point-input';

import Styles from './SurchargeSettings.module.scss';

export const SurchargeSettings = memo(
  ({
    // surcharge
    surchargeAmount,
    surchargeApplyTo,
    surchargeLabel,
    surchargePercentage,
    surchargeType,
    // validation
    isSurchargeValid,
    // callbacks
    onSurchargeAmountChange,
    onSurchargeApplyToChange,
    onSurchargeLabelChange,
    onSurchargePercentageChange,
    onSurchargeTypeChange,
  }) => {
    const { t } = useTranslation();
    // Use surchargeLabelValue instead of using surchargeLabel directly to avoid cursor moves to the end of the text while editing
    // This only happens when this component is embedded in an angular component invoice-settings-invoices.html
    // We can remove the useState and useEffect below once we migrate the firm invoice template page to React
    const [surchargeLabelValue, setSurchargeLabelValue] = React.useState(surchargeLabel);

    // Update surchargeLabel value when switching between templates in Firm Settings
    useEffect(() => {
      setSurchargeLabelValue(surchargeLabel);
    }, [surchargeLabel]);

    const surchargeApplyToOptionsLocalised = surchargeApplyToOptions.map((option) => ({
      ...option,
      label: localiseLabel(option.label, t),
    }));

    const onSurchargeTypeClickHandler = useCallback(
      (newSurchargeType) => {
        onSurchargeTypeChange(newSurchargeType);
      },
      [onSurchargeTypeChange],
    );

    const onSurchargePercentageChangeHandler = useCallback(
      (newSurcharge) => onSurchargePercentageChange(newSurcharge),
      [onSurchargePercentageChange],
    );

    const onSurchargeAmountChangeHandler = useCallback(
      (e) => onSurchargeAmountChange(e.target.value),
      [onSurchargeAmountChange],
    );

    const onSurchargeLabelChangeHandler = useCallback(
      (e) => {
        const target = e.currentTarget;
        onSurchargeLabelChange(target.value);
        setSurchargeLabelValue(target.value);
      },
      [onSurchargeLabelChange],
    );

    return (
      <div className={Styles.surchargeSettings}>
        <div className={Styles.surchargeTypes}>
          <div
            className={classnames(Styles.surchargeType, 'input-group')}
            role="button"
            tabIndex={0}
            onClick={() => onSurchargeTypeClickHandler(surchargeTypeValues.NONE)}
          >
            <input type="radio" checked={surchargeType === surchargeTypeValues.NONE} readOnly />
            <span className={Styles.radioLabel}>None</span>
          </div>
          <div
            className={Styles.surchargeType}
            role="button"
            tabIndex={0}
            onClick={() => onSurchargeTypeClickHandler(surchargeTypeValues.PERCENTAGE)}
          >
            <input type="radio" checked={surchargeType === surchargeTypeValues.PERCENTAGE} readOnly />
            <BasisPointInput
              basisPoints={surchargeType === surchargeTypeValues.PERCENTAGE ? surchargePercentage : undefined}
              disabled={surchargeType !== surchargeTypeValues.PERCENTAGE}
              isPerAnnum={false} // this percentage is stored as basis point in .net
              hasError={surchargeType === surchargeTypeValues.PERCENTAGE && !isSurchargeValid}
              onChange={onSurchargePercentageChangeHandler}
            />
          </div>
          <div
            className={Styles.surchargeType}
            role="button"
            tabIndex={0}
            onClick={() => onSurchargeTypeClickHandler(surchargeTypeValues.FIXED)}
          >
            <input type="radio" checked={surchargeType === surchargeTypeValues.FIXED} readOnly />
            <CurrencyInput2
              value={surchargeType === surchargeTypeValues.FIXED ? surchargeAmount : undefined}
              onChange={onSurchargeAmountChangeHandler}
              disabled={surchargeType !== surchargeTypeValues.FIXED}
              hasError={surchargeType === surchargeTypeValues.FIXED && !isSurchargeValid}
            />
          </div>
        </div>
        {[surchargeTypeValues.PERCENTAGE, surchargeTypeValues.FIXED].includes(surchargeType) && (
          <div className={Styles.newRow}>
            <div className={Styles.newColumn}>
              <label> Apply to </label>
              <StatelessSelect
                options={surchargeApplyToOptionsLocalised}
                selectedOption={surchargeApplyTo}
                onChange={(newOption) => {
                  onSurchargeApplyToChange(newOption.value);
                }}
              />
            </div>
            <div className={Styles.newColumn}>
              <label> Label on invoice </label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  value={surchargeLabelValue}
                  onChange={onSurchargeLabelChangeHandler}
                  maxLength={32}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

const localiseLabel = (label, t) => {
  const expensesLocalised = t('capitalizeAllWords', { val: 'expenses' });
  return label.replace(/Expenses/gi, expensesLocalised);
};

SurchargeSettings.displayName = 'SurchargeSettings';

SurchargeSettings.propTypes = {
  // surcharge
  surchargeAmount: PropTypes.number,
  surchargeApplyTo: PropTypes.number,
  surchargeLabel: PropTypes.string,
  surchargePercentage: PropTypes.number,
  surchargeType: PropTypes.number,
  // validation
  isSurchargeValid: PropTypes.bool.isRequired,
  // callbacks
  onSurchargeAmountChange: PropTypes.func.isRequired,
  onSurchargeApplyToChange: PropTypes.func.isRequired,
  onSurchargeLabelChange: PropTypes.func.isRequired,
  onSurchargePercentageChange: PropTypes.func.isRequired,
  onSurchargeTypeChange: PropTypes.func.isRequired,
};

SurchargeSettings.defaultProps = {
  surchargeAmount: 0,
  surchargeApplyTo: undefined,
  surchargeLabel: undefined,
  surchargePercentage: 0,
  surchargeType: undefined,
};
