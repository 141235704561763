const { cleanHtml, decodeAndClean64Html, cleanBase64Html, cleanAndEncode64Html } = require("./decode-and-clean");
const { getTextContent } = require("./get-text-content");

module.exports = {
  cleanHtml,
  decodeAndClean64Html,
  cleanBase64Html,
  cleanAndEncode64Html,
  getTextContent,
};

