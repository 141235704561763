import { get } from 'lodash';
import {
  getList,
  updateCache as updateRedux,
} from '@sb-billing/redux/myob-business';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { featureActive } from '@sb-itops/feature';

angular
  .module('@sb-billing/services')
  .service('sbMyobBusinessService', function(
    sbLoggerService,
    sbGenericEndpointService,
    sbGenericCacheService,
    sbEndpointType,
    sbEnvironmentConfigService,
    sbRegionType,
    FileSaver,
  ) {

    const region = sbEnvironmentConfigService.getRegion();
    if (region === sbRegionType.US) {
      return;
    }

    const that = this;
    const log = sbLoggerService.getLogger('sbMyobBusinessService');
    const ENDPOINT = 'billing/myob-business-settings';

    sbGenericCacheService.setupCache({
      name: 'sbMyobBusinessService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: ENDPOINT },
        poll: 60,
        subscriptions: [
          'notifier.MyobIntegration.MyobAccountsUpdated',
          'notifier.MyobIntegration.MyobAccessTokenUpdated',
          'notifier.MyobIntegration.MyobSettingsUpdated'
        ]
      },
      updateRedux,
    });

    that.get = getOne;
    that.getSettings = getSettings;
    that.saveSettingsP = saveSettingsP;
    that.refreshAccounts = refreshAccounts;
    that.getBankAccounts = getBankAccounts;
    that.downloadInvoiceReport = downloadInvoiceReport;
    that.generateOutstandingInvoiceReport = generateOutstandingInvoiceReport;
    that.getPreSignedUrlOfOutstandingInvoiceReport = getPreSignedUrlOfOutstandingInvoiceReport;

    function getOne() {
      return getList()[0] || {}; // Will be 1 settings only
    }

    function getSettings() {
      log.info('saving MYOB business settings');
      return getOne().settings;
    }

    function saveSettingsP(settings) {
      log.info(`saving MYOB business settings`, settings);
      return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/settings`, undefined, settings);
    }

    function generateOutstandingInvoiceReport ({ timestamp }) {
      const reportDate = moment(timestamp).format('YYYYMMDD');
      return sbGenericEndpointService.postPayloadP(`/billing/report-outstanding-invoices/generate`, undefined, { reportDate });
    }

    function getPreSignedUrlOfOutstandingInvoiceReport ({ accountId, timestamp }) {
      const reportDate = moment(timestamp).format('YYYYMMDD');
      return fetchGetP({ path: `/billing/report-outstanding-invoices/${accountId}/${reportDate}` });
    }

    async function downloadInvoiceReport ({ url, timestamp }) {
      let blob = await fetch(url).then(r => r.blob());
      FileSaver.saveAs(blob, `${moment(timestamp).format('YYYY-MM-DD')}-Outstanding-Invoices-Report.csv`);
    }

    function refreshAccounts(businessId) {
      log.info('refreshing MYOB business accounts');
      return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/refresh-accounts`, businessId);
    }

    function getAccountsByBusinessId(businessId) {
      return get(getOne().accounts.filter(account => account.businessId === businessId), [0]).accounts;
    }

    // typeLabel is used to group the bank accounts in the dropdown options by type
    function addTypeLabelForBankAccounts(bankAccounts) {
      return bankAccounts.reduce((acc, bankAccount) => {
        acc.push({
          ...bankAccount,
          typeLabel: bankAccount.type,
        });
        return acc;
      }, []);
    }

    function getBankAccounts(businessId, myobIntegrationVersion) {
      if (!getOne().accounts) {
        return {};
      }

      const sortedAccounts = _.sortBy(getAccountsByBusinessId(businessId), 'name');
      const incomeAndExpenseAccountsWithGroupLabel = addTypeLabelForBankAccounts(filterBankAccounts(sortedAccounts, 'Income', 'Expense'));
      const assetAccounts = filterBankAccounts(sortedAccounts, 'OtherCurrentAsset', 'FixedAsset', 'OtherAsset').map((bankAccount) => ({
        ...bankAccount,
        typeLabel: 'Asset',
      }))
      const incomeAccounts = filterBankAccounts(sortedAccounts, 'Income').map((bankAccount) => ({
        ...bankAccount,
        typeLabel: 'Income',
      }))
      const expenseAccounts = filterBankAccounts(sortedAccounts, 'Expense').map((bankAccount) => ({
        ...bankAccount,
        typeLabel: 'Expense',
      }))

      switch (myobIntegrationVersion) {
        // There are 10 firms with recently updated v1 tokens, but from the correlation, latest timestamp when they actually had data sent to the old myob essentials was late 2022, so we won't apply any hard/soft cost changes to v1
        case 0: // Essentials (v1)
          return {
            operatingBankAccounts: filterBankAccounts(sortedAccounts, 'Banking', 'Bank'),
            legalFeesAccounts: sortedAccounts,
            clientExpensesAccounts: sortedAccounts,
            clientCostLiabilityAccounts: filterBankAccounts(sortedAccounts, 'Current Liabilities'),
            badDebtAccounts: incomeAndExpenseAccountsWithGroupLabel,
            interestAccounts: incomeAndExpenseAccountsWithGroupLabel,
            surchargeAccounts: sortedAccounts,
          };
        
        case 1: // AccountRight (a.k.a. essentials V2)
          return {
            operatingBankAccounts: filterBankAccounts(sortedAccounts, 'Banking', 'Bank'),
            legalFeesAccounts: sortedAccounts,
            clientExpensesAccounts: featureActive('BB-13352') ? [...assetAccounts, ...incomeAccounts] : sortedAccounts,
            clientSoftCostExpensesAccounts: incomeAccounts,
            clientCostLiabilityAccounts: featureActive('BB-13352') ? [...assetAccounts, ...expenseAccounts] : sortedAccounts,
            clientSoftCostLiabilityAccounts: expenseAccounts,
            badDebtAccounts: incomeAndExpenseAccountsWithGroupLabel,
            interestAccounts: incomeAndExpenseAccountsWithGroupLabel,
            surchargeAccounts: sortedAccounts,
          };
        
          default:
          throw new Error(`Unexpected MYOB integration version: ${myobIntegrationVersion}`);
      }
    }

    function filterBankAccounts(bankAccounts, ...accountTypes) {
      log.info('filtering MYOB business bank accounts', bankAccounts, accountTypes);
      return bankAccounts.filter(bankAccount => accountTypes.some(accountType => bankAccount.type === accountType));
    }
  });
