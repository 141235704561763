angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/advanced-search/events';

  $stateProvider
    .state('home.billing.advanced-search.events', {
      url: '/events?searchTerm',
      templateUrl: BASE_DIRECTORY + '/events.html',
      data: {
        authorized: true,
        navHighlights: ['billing'],
      },
      reloadOnSearch: false,
    });
});
