import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes } from '../button';
import { ContextMenu } from '../context-menu';
import Styles from './NewPasswordRequiredForm.module.scss';

export const NewPasswordRequiredForm = ({
  passwordContentMessage,
  password,
  onPasswordUpdated,
  passwordCopy,
  onPasswordCopyUpdated,
  onConfirmNewPassword,
  passwordChangeInProgress,
  onCancelPasswordChange,
  errorMessages,
}) => (
  <div className={Styles.newPasswordRequiredForm}>
    <div className={Styles.messageText}>
      You have logged in with a temporary password. Please set a new password to continue.
    </div>
    <fieldset disabled={passwordChangeInProgress}>
      {/* Password field */}
      <div className={Styles.formGroup}>
        <span className={Styles.decorator}>
          <i className="fa fa-fw fa-lock" />
        </span>
        <input
          type="password"
          className="form-control"
          placeholder="Enter new password"
          value={password}
          onChange={(e) => onPasswordUpdated(e.target.value)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          maxLength={30}
          autoComplete="off"
        />
        <span className={Styles.actionDecorator} title={passwordContentMessage}>
          <ContextMenu
            // eslint-disable-next-line react/no-unstable-nested-components
            body={() => <div className={Styles.passwordContentToolTip}>{passwordContentMessage}</div>}
            position="bottom"
            tabIndex={0}
          >
            <i className="fa fa-question-circle" />
          </ContextMenu>
        </span>
      </div>

      {/* Password confirm field */}
      <div className={Styles.formGroup}>
        <span className={Styles.decorator}>
          <i className="fa fa-fw fa-lock" />
        </span>
        <input
          type="password"
          className="form-control"
          placeholder="Confirm new password"
          value={passwordCopy}
          onChange={(e) => onPasswordCopyUpdated(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && onConfirmNewPassword({ password, passwordCopy })}
          maxLength={30}
          autoComplete="off"
        />
      </div>

      {/* Error message display */}
      {errorMessages.length > 0 && (
        <div className={Styles.errors}>
          {errorMessages.map(({ message, isVerbose }, index) => (
            <div
              key={index}
              className={classnames('error-box', 'alert', 'alert-danger', !isVerbose && Styles.centreText)}
            >
              {message}
            </div>
          ))}
        </div>
      )}

      {/* Update password button */}
      <Button
        className={Styles.updatePasswordButton}
        onClick={() => onConfirmNewPassword({ password, passwordCopy })}
        disabled={passwordChangeInProgress}
        locked={passwordChangeInProgress}
      >
        {!passwordChangeInProgress && 'Update Password'}
      </Button>

      {/* Cancel verify link */}
      <Button className={Styles.cancelLoginButton} type={buttonTypes.link} onClick={() => onCancelPasswordChange()}>
        Cancel Login
      </Button>
    </fieldset>
  </div>
);

NewPasswordRequiredForm.displayName = 'NewPasswordRequiredForm';

NewPasswordRequiredForm.propTypes = {
  passwordContentMessage: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordCopy: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      isVerbose: PropTypes.bool,
    }),
  ),
  onPasswordUpdated: PropTypes.func.isRequired,
  onPasswordCopyUpdated: PropTypes.func.isRequired,
  onConfirmNewPassword: PropTypes.func.isRequired,
  passwordChangeInProgress: PropTypes.bool.isRequired,
  onCancelPasswordChange: PropTypes.func.isRequired,
};

NewPasswordRequiredForm.defaultProps = {
  errorMessages: [],
};
