import * as React from 'react';
import PropTypes from 'prop-types';

import { LoadingBarInfinite, useTranslation } from '@sb-itops/react';

import classNames from 'classnames';
import Styles from './FinalisedInvoiceEntries.module.scss';

export const FinalisedInvoiceEntries = ({
  expensesTableRows,
  expensesTableTotals,
  feesTableRows,
  feesTableTotals,
  invoiceHasExpenses,
  invoiceHasFees,
  loading,
  showTax,
  // Callbacks
  onExpenseRowClick,
  onFeeRowClick,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={Styles.loadingContainer}>
        <LoadingBarInfinite loading={loading} />
      </div>
    );
  }

  return (
    <div className={Styles.finalisedInvoiceEntriesContainer}>
      {invoiceHasFees && (
        <div className={Styles.panelContainer}>
          <h3 className={Styles.panelHeading}>Time and Fees</h3>
          <div className={Styles.panelBody}>
            <div className={classNames('sb-table', Styles.panelTable)}>
              {/* Table headers */}
              <div className={classNames('sb-table-header', Styles.tableHeadersContainer)}>
                <div className={classNames('sb-table-cell', Styles.entryDateColumn)}>Date</div>
                <div className={classNames('sb-table-cell', Styles.feeSubjectColumn)}>Subject</div>
                <div className={classNames('sb-table-cell', Styles.feeNameColumn)}>Name</div>
                <div className={classNames('sb-table-cell', Styles.feeHoursColumn)}>Hours</div>
                <div className={classNames('sb-table-cell', Styles.feeRateColumn)}>Rate</div>
                <div className={classNames('sb-table-cell', Styles.entryAmountColumn)}>Amount</div>
                {showTax && <div className={classNames('sb-table-cell', Styles.entryTaxColumn)}>{t('tax')}</div>}
                {showTax && <div className={classNames('sb-table-cell', Styles.entryTotalColumn)}>Total</div>}
              </div>
              {/* Table fee rows */}
              {feesTableRows.map((feeRow) => (
                <div
                  key={feeRow.id}
                  className={classNames('sb-table-row')}
                  onClick={() => onFeeRowClick({ feeId: feeRow.id })}
                >
                  {/* Date */}
                  <div className={classNames('sb-table-cell', Styles.entryDateColumn)}>
                    {t('date', { yyyymmdd: feeRow.date })}
                  </div>
                  {/* Subject */}
                  <div className={classNames('sb-table-cell', Styles.feeSubjectColumn)}>
                    <div className={Styles.feeSubject}>{feeRow.subject}</div>
                  </div>
                  {/* Name */}
                  <div className={classNames('sb-table-cell', Styles.feeNameColumn)}>
                    <div className={Styles.staffMemberName}>{feeRow.feeEarnerStaffName}</div>
                  </div>
                  {/* Hours */}
                  <div className={classNames('sb-table-cell', Styles.feeHoursColumn)}>{feeRow.hours}</div>
                  {/* Rate */}
                  <div className={classNames('sb-table-cell', Styles.feeRateColumn)}>
                    {feeRow.rate && t('cents', { val: feeRow.rate })}
                  </div>
                  {/* Amount */}
                  <div
                    className={classNames(
                      'sb-table-cell',
                      Styles.entryAmountColumn,
                      feeRow.waived && Styles.waivedEntry,
                    )}
                  >
                    {t('cents', { val: feeRow.amount })}
                  </div>
                  {/* Tax */}
                  {showTax && (
                    <div
                      className={classNames(
                        'sb-table-cell',
                        Styles.entryTaxColumn,
                        feeRow.waived && Styles.waivedEntry,
                      )}
                    >
                      {t('cents', { val: feeRow.tax })}
                    </div>
                  )}
                  {/* Total */}
                  {showTax && (
                    <div
                      className={classNames(
                        'sb-table-cell',
                        Styles.entryTotalColumn,
                        feeRow.waived && Styles.waivedEntry,
                      )}
                    >
                      {t('cents', { val: feeRow.total })}
                    </div>
                  )}
                </div>
              ))}
              {/* Table totals row */}
              <div className={classNames('sb-table-row', Styles.totalsRow)}>
                <div className={classNames('sb-table-cell', Styles.entryDateColumn)}>Total</div>
                <div className={classNames('sb-table-cell', Styles.feeSubjectColumn)} />
                <div className={classNames('sb-table-cell', Styles.feeNameColumn)} />
                {/* Hours */}
                <div className={classNames('sb-table-cell', Styles.feeHoursColumn)}>{feesTableTotals.hours}</div>
                <div className={classNames('sb-table-cell', Styles.feeRateColumn)} />
                {/* Amount */}
                <div className={classNames('sb-table-cell', Styles.entryAmountColumn)}>
                  {t('cents', { val: feesTableTotals.amount })}
                </div>
                {/* Tax */}
                {showTax && (
                  <div className={classNames('sb-table-cell', Styles.entryTaxColumn)}>
                    {t('cents', { val: feesTableTotals.tax })}
                  </div>
                )}
                {/* Total */}
                {showTax && (
                  <div className={classNames('sb-table-cell', Styles.entryTotalColumn)}>
                    {t('cents', { val: feesTableTotals.total })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {invoiceHasExpenses && (
        <div className={Styles.panelContainer}>
          <h3 className={Styles.panelHeading}>
            {t('capitalizeAllWords', {
              val: 'expenses',
            })}
          </h3>
          <div className={Styles.panelBody}>
            <div className={classNames('sb-table', Styles.panelTable)}>
              {/* Table headers */}
              <div className={classNames('sb-table-header', Styles.tableHeadersContainer)}>
                <div className={classNames('sb-table-cell', Styles.entryDateColumn)}>Date</div>
                <div className={classNames('sb-table-cell', Styles.expenseSubjectColumn)}>Subject</div>
                <div className={classNames('sb-table-cell', Styles.expensePriceColumn)}>Price</div>
                <div className={classNames('sb-table-cell', Styles.expenseQuantityColumn)}>Quantity</div>
                <div className={classNames('sb-table-cell', Styles.entryAmountColumn)}>Amount</div>
                {showTax && <div className={classNames('sb-table-cell', Styles.entryTaxColumn)}>{t('tax')}</div>}
                {showTax && <div className={classNames('sb-table-cell', Styles.entryTotalColumn)}>Total</div>}
              </div>
              {/* Table expense rows */}
              {expensesTableRows.map((expenseRow) => (
                <div
                  key={expenseRow.id}
                  className={classNames('sb-table-row')}
                  onClick={() => onExpenseRowClick({ expenseId: expenseRow.id })}
                >
                  {/* Date */}
                  <div className={classNames('sb-table-cell', Styles.entryDateColumn)}>
                    {t('date', { yyyymmdd: expenseRow.date })}
                  </div>
                  {/* Subject */}
                  <div className={classNames('sb-table-cell', Styles.expenseSubjectColumn)}>
                    <div className={Styles.expenseSubject}>{expenseRow.subject}</div>
                  </div>
                  {/* Price */}
                  <div className={classNames('sb-table-cell', Styles.expensePriceColumn)}>
                    {expenseRow.price !== undefined && t('cents', { val: expenseRow.price })}
                  </div>
                  {/* Quantity */}
                  <div className={classNames('sb-table-cell', Styles.expenseQuantityColumn)}>{expenseRow.quantity}</div>
                  {/* Amount */}
                  <div
                    className={classNames(
                      'sb-table-cell',
                      Styles.entryAmountColumn,
                      expenseRow.waived && Styles.waivedEntry,
                    )}
                  >
                    {t('cents', {
                      val: expenseRow.waived ? expenseRow.waivedAmount : expenseRow.amount,
                      absolute: true,
                    })}
                  </div>
                  {/* Tax */}
                  {showTax && (
                    <div
                      className={classNames(
                        'sb-table-cell',
                        Styles.entryTaxColumn,
                        expenseRow.waived && Styles.waivedEntry,
                      )}
                    >
                      {t('cents', { val: expenseRow.waived ? expenseRow.waivedTax : expenseRow.tax })}
                    </div>
                  )}
                  {/* Total */}
                  {showTax && (
                    <div
                      className={classNames(
                        'sb-table-cell',
                        Styles.entryTotalColumn,
                        expenseRow.waived && Styles.waivedEntry,
                      )}
                    >
                      {t('cents', {
                        val: expenseRow.waived ? expenseRow.waivedTotal : expenseRow.total,
                        absolute: true,
                      })}
                    </div>
                  )}
                </div>
              ))}
              {/* Table totals row */}
              <div className={classNames('sb-table-row', Styles.totalsRow)}>
                <div className={classNames('sb-table-cell', Styles.entryDateColumn)}>Total</div>
                <div className={classNames('sb-table-cell', Styles.expenseSubjectColumn)} />
                <div className={classNames('sb-table-cell', Styles.expensePriceColumn)} />
                <div className={classNames('sb-table-cell', Styles.expenseQuantityColumn)} />
                {/* Amount */}
                <div className={classNames('sb-table-cell', Styles.entryAmountColumn)}>
                  {t('cents', { val: expensesTableTotals.amount, absolute: true })}
                </div>
                {/* Tax */}
                {showTax && (
                  <div className={classNames('sb-table-cell', Styles.entryTaxColumn)}>
                    {t('cents', { val: expensesTableTotals.tax })}
                  </div>
                )}
                {/* Total */}
                {showTax && (
                  <div className={classNames('sb-table-cell', Styles.entryTotalColumn)}>
                    {t('cents', { val: expensesTableTotals.total, absolute: true })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FinalisedInvoiceEntries.displayName = 'FinalisedInvoiceEntries';

FinalisedInvoiceEntries.propTypes = {
  expensesTableRows: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      date: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      tax: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      waived: PropTypes.bool.isRequired,
      waivedAmount: PropTypes.number.isRequired,
      waivedTax: PropTypes.number.isRequired,
      waivedTotal: PropTypes.number.isRequired,
    }),
  ).isRequired,
  expensesTableTotals: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  feesTableRows: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      date: PropTypes.number.isRequired,
      feeEarnerStaffName: PropTypes.string.isRequired,
      hours: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      rate: PropTypes.number,
      subject: PropTypes.string.isRequired,
      tax: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      waived: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  feesTableTotals: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    hours: PropTypes.string.isRequired,
    tax: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  invoiceHasExpenses: PropTypes.bool.isRequired,
  invoiceHasFees: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showTax: PropTypes.bool.isRequired,
  // Callbacks
  onExpenseRowClick: PropTypes.func.isRequired,
  onFeeRowClick: PropTypes.func.isRequired,
};

FinalisedInvoiceEntries.defaultProps = {};
