import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import * as forms from '@sb-itops/redux/forms2';
import { getBalanceType, BANK_BALANCE_TYPE } from '@sb-billing/redux/bank-account-settings';
import { saveSettings } from '@sb-billing/redux/bank-account-settings/save-settings';
import { getLogger } from '@sb-itops/fe-logger';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import PropTypes from 'prop-types';
import { BankAccountSettingsFormSchema } from './BankAccountSettingsFormSchema';
import BankAccountSettings from './BankAccountSettings';

const log = getLogger('BankAccountSettingsContainer');

const mapStateToProps = (state, { scope }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const {
    formInitialised,
    fields: formFields,
    formDirty,
    formSubmitting,
    formValid,
  } = formSelectors.getFormState(state);
  const { isMatterBalanceType, confirmProceed } = formFields;
  return {
    scope,
    isMatterBalanceType,
    formSubmitting,
    formInitialised,
    formDirty,
    bankBalanceType: getBalanceType(),
    formValid,
    confirmProceed,
  };
};

const mapDispatchToProps = (dispatch, { scope }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const fieldValues = {
        isMatterBalanceType: getBalanceType() === BANK_BALANCE_TYPE.matter,
        confirmProceed: '',
      };

      dispatch(formActions.initialiseForm({ fieldValues }));

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: BankAccountSettingsFormSchema }));
    },
    onSave: async (isMatterBalanceType) => {
      const saveMatterBalanceType = isMatterBalanceType ? BANK_BALANCE_TYPE.matter : BANK_BALANCE_TYPE.matterContact;
      try {
        await dispatch(
          formOperations.submitFormP({
            submitFnP: () => dispatch(saveSettings({ bankBalanceType: saveMatterBalanceType })),
          }),
        );
      } catch (err) {
        log.error(err);
        throw err;
      }
    },
  };
};

const BankAccountSettingsContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(BankAccountSettings)),
);

BankAccountSettingsContainer.displayName = 'BankAccountSettings';
BankAccountSettingsContainer.propTypes = { scope: PropTypes.any };
BankAccountSettingsContainer.defaultProps = {};

export default BankAccountSettingsContainer;
