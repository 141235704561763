import React from 'react';
import PropTypes from 'prop-types';
import { getInvoiceLatestVersion } from '@sb-billing/redux/invoices';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { InvoiceAddPaymentButton } from './InvoiceAddPaymentButton';

const InvoiceAddPaymentButtonContainer = withReduxProvider((props) => {
  const invoice = useSelector(() => getInvoiceLatestVersion(props.invoiceId) || {});
  const hasUnpaidAD = hasUnpaidAnticipatedDisbursements({ invoice, getExpenseById, getExpensePaymentDetailsById });
  // We want to display button only for finalised invoice which hasn't been fully paid yet
  if (invoice.status !== 'FINAL') {
    return null;
  }

  return <InvoiceAddPaymentButton {...props} hasUnpaidAnticipatedDisbursements={hasUnpaidAD} />;
});

InvoiceAddPaymentButtonContainer.displayName = 'InvoiceAddPaymentButton';

InvoiceAddPaymentButtonContainer.propTypes = {
  // id uniquely identifies the button and modal
  // important when there are these buttons on same page
  id: PropTypes.string.isRequired,
  // onClick is really required but Angular may not provide it in first render
  onClick: PropTypes.func,
  invoiceId: PropTypes.string.isRequired,
  modalId: PropTypes.string,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  asLink: PropTypes.bool,
};

InvoiceAddPaymentButtonContainer.defaultProps = {
  locked: false,
  disabled: false,
  asLink: false,
  onClick: () => {},
};

export default InvoiceAddPaymentButtonContainer;
