'use strict';

angular.module('sb.billing.webapp').component('sbDuiStorageLoadTest', {
  templateUrl: 'ng-composable-components/data-ui/custom/storage-load-test/dui-storage-load-test.html',
  controllerAs: 'ctrl',
  controller: function (sbLoggerService, sbMessageDisplayService, sbUuidService, $indexedDB) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDuiStorageLoadTest');
    //log.setLogLevel('info');

    that.openStore = openStore;
    that.closeStore = closeStore;
    that.upsert = upsert;
    that.clear = clear;
    that.getAll = getAll;
    that.getAllByKeys = getAllByKeys;

    that.$onInit = () => {
      that.view = {
      };
      that.model = {
        storeName: '',
        openStoreInfo: null,
        upsertRepeatCount: 1,
        upsertData: ''
      };

      $indexedDB.databaseInfo()
        .then(info => {
          log.info('dbInfo', info);
          that.view.stores = _.sortBy(info.objectStores, 'name');
          that.model.store = that.view.stores[0];
        });
    };

    function openStore(storeInfo) {
      that.model.openStoreInfo = storeInfo;
      log.info(`Opening store: ${storeInfo.name}`);
    }

    function closeStore() {
      that.model.openStoreInfo = null;
    }

    function upsert() {
      const {openStoreInfo, upsertRepeatCount, upsertData} = that.model;

      try {
        const upsertObjects = [];
        for (let i = 0; i < upsertRepeatCount; ++i) {
          const processedData = upsertData.replace(/\{GUID\}/g, sbUuidService.get());
          const upsertObject = JSON.parse(processedData);
          upsertObjects.push(upsertObject);
        }

        const startTime = performance.now();

        $indexedDB.openStore(openStoreInfo.name, (store) => {
          store.upsert(upsertObjects).then(() => {
            const endTime = performance.now();
            sbMessageDisplayService.success(`${upsertObjects.length} objects upserted successfully in ${Math.round(endTime - startTime)}ms`);
          })
          .catch((e) => {
            log.error(e);
            sbMessageDisplayService.error(e.message);
          });
        });
      }
      catch (err) {
        sbMessageDisplayService.success(`Bad upsert data, check console for details`);
        log.error('Bad json data', err);
        return;
      }
    }

    function clear() {
      $indexedDB.openStore(that.model.openStoreInfo.name, (store) => {
        store.clear()
          .then(() => sbMessageDisplayService.success('Store cleared successfully'))
          .catch((e) => sbMessageDisplayService.error(e))
      });
    }

    function getAll() {
      that.model.entries = {};
      const keyPath = that.model.openStoreInfo.keyPath;

      $indexedDB.openStore(that.model.openStoreInfo.name, (store) => {
        const startTime = performance.now();
        store.getAll()
          .then((entities) => {
            entities.forEach(entity => {
              that.model.entries[entity[keyPath]] = entity;
            });

            const endTime = performance.now();
            sbMessageDisplayService.success(`${Object.keys(that.model.entries).length} objects loaded into memory in ${Math.floor(endTime - startTime)}ms using getAll() `);
          })
          .catch(err => {
            sbMessageDisplayService.error(err);
          });
      });
    }

    function getAllByKeys() {
      that.model.entries = {};
      const keyPath = that.model.openStoreInfo.keyPath;

      $indexedDB.openStore(that.model.openStoreInfo.name, (store) => {
        const startTime = performance.now();
        store.getAllKeys().then(cacheKeys => {
          return Promise.all(cacheKeys.map(cacheKey => {
            return store.find(cacheKey)
              .then(entity => {
                that.model.entries[entity[keyPath]] = entity;
              });
          }));
        })
        .then(() => {
          const endTime = performance.now();
          sbMessageDisplayService.success(`${Object.keys(that.model.entries).length} objects loaded into memory in ${Math.floor(endTime - startTime)}ms using getAllByKeys()`);
        })
        .catch(err => {
          sbMessageDisplayService.error(err);
        });
      });
    }
  }
});
