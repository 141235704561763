angular.module('sb.billing.webapp').controller('SbUpgradeController', function ($window, sbLoggerService) {
  'use strict';

  const that = this,
    log = sbLoggerService.getLogger('SbUpgradeController');

  that.upgrade = upgrade;

  function upgrade() {
    log.info(`upgrading...`);
    $window.location.reload();
  }
});
