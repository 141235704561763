angular.module('sb.billing.webapp').directive('sbDataRadioListBankAccounts', function() {
  'use strict';
  return {
    'restrict': 'A',
    'require': 'sbRadioList',
    'link': function (scope, element, attrs, ctrl) {
      ctrl.setOptions([{display: 'Trust', value: 'TRUST'}, {display: 'Operating', value: 'OPERATING' }]);
    }
  };
});
