import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { DepositRequestForm } from '../deposit-request-form';
import Styles from './DepositRequestModal.module.scss';

export const DepositRequestModal = React.memo(
  ({ onSubmit, onClose, isSubmitDisabled, isSubmitLocked, isVisible, scope, bankAccountId, debtorId, matterId }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.depositRequestModal}
        isVisible={isVisible}
        title="Credit Card Deposit Request"
        body={
          <DepositRequestForm
            scope={scope}
            defaultBankAccountId={bankAccountId}
            debtorId={debtorId}
            matterId={matterId}
          />
        }
        footer={
          <div className="row">
            <div className="col-lg-3">
              <Button
                onClick={onSubmit}
                disabled={isSubmitDisabled || isSubmitLocked}
                form={scope}
                locked={isSubmitLocked}
                size="full-width"
              >
                Send Now
              </Button>
            </div>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

DepositRequestModal.displayName = 'DepositRequestModal';

DepositRequestModal.propTypes = {
  scope: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  debtorId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isSubmitLocked: PropTypes.bool,
};

DepositRequestModal.defaultProps = {
  isSubmitDisabled: false,
  isSubmitLocked: false,
};
