'use strict';

const bankAccountTypeByValue = Object.freeze({
  0: 'OPERATING',
  1: 'TRUST',
  2: 'CREDIT',
  3: 'CONTROLLEDMONEY',
});

const bankAccountEntityTypeEnum = Object.freeze({
  OPERATING: 0,
  TRUST: 1,
  CREDIT: 2,
  CONTROLLEDMONEY: 3,
});

// TODO: When refactoring getBankAccountName to use t remove this object and replace with bankAccountTypeTranslationKeyByValue
const bankAccountTypeDisplayByValue = Object.freeze({
  0: 'Operating',
  1: 'Trust',
  2: 'Credit',
  3: 'Controlled Money',
});

// Translation key in localisation-resources, or the display name if none
const bankAccountTypeTranslationKeyByType = Object.freeze({
  OPERATING: 'operating',
  TRUST: 'trust',
  CREDIT: 'Credit',
  CONTROLLEDMONEY: 'controlledMoney',
});
// Unfortunatelly bankAccountTypeTranslationKeyByType can't be used
// if we want "accountType Account" format in all regions
// as "Account" is part of the returned phrase in GB but not in AU/US
const bankAccountTypeTranslationKeyByTypeWithAccount = Object.freeze({
  OPERATING: 'operatingAccount',
  TRUST: 'trustAccount',
  CREDIT: 'Credit Account',
  CONTROLLEDMONEY: 'controlledMoneyAccount',
});

const bankAccountTypeEnum = Object.freeze({
  OPERATING: 'OPERATING',
  TRUST: 'TRUST',
  CREDIT: 'CREDIT',
  CONTROLLEDMONEY: 'CONTROLLEDMONEY',
});

const bankAccountState = Object.freeze({
  OPEN: 0,
  CLOSED: 1,
});

const bankAccountStateByValue = Object.freeze({
  0: 'OPEN',
  1: 'CLOSED',
});

// reverse bankAccountTypeByValue key value pairs
const bankAccountValueByType = Object.freeze(
  Object.fromEntries(Object.entries(bankAccountTypeByValue).map((a) => a.reverse())),
);

// Used in reports such as trust statement
// Used in legacy in /AccountType suffix of bank account ID
const bankAccountTypes = Object.freeze({
  CONTROLLEDMONEY: 'ControlledMoney',
  OPERATING: 'Operating',
  TRUST: 'Trust',
  CREDIT: 'Credit',
});

// We use same endpoint for updating, reopening and closing bank account
// In case we need to distinguish between these, we can use action field with values below
const bankAccountSaveAction = Object.freeze({
  UPDATE: 'update',
  REOPEN: 'reopen',
  CLOSE: 'close',
});

module.exports = {
  bankAccountValueByType,
  bankAccountTypeByValue,
  bankAccountTypeEnum,
  bankAccountEntityTypeEnum,
  bankAccountTypeDisplayByValue,
  bankAccountState,
  bankAccountStateByValue,
  bankAccountTypes,
  bankAccountSaveAction,
  bankAccountTypeTranslationKeyByType,
  bankAccountTypeTranslationKeyByTypeWithAccount,
};
