import uuid from '@sb-itops/uuid';

export type TWidget = {
  label: string | { reference: string };
  widgetId: string;
  instanceId?: string;
  permanent?: boolean;
  sbEntityType: string;
  editableFields?: {
    name: string;
    labelDisplay: string;
    valueType: 'number' | 'text';
    stringValue: string;
    placeHolder?: string;
  }[];
};

export const getBaseWidgets = (): TWidget[] => [
  {
    label: 'Auto-0123',
    widgetId: 'autoCounter',
    sbEntityType: 'MatterManagement.Entities.AutoCounterField, MatterManagement.BrowseMatters.Entities',
    permanent: true,
    editableFields: [
      { name: 'counterStart', labelDisplay: 'Start from', valueType: 'number', stringValue: '1' },
      { name: 'digitCount', labelDisplay: 'Number of digits', valueType: 'number', stringValue: '4' },
    ],
  },
  {
    label: { reference: 'text' },
    widgetId: 'text',
    sbEntityType: 'MatterManagement.Entities.TextField, MatterManagement.BrowseMatters.Entities',
    editableFields: [{ name: 'text', labelDisplay: 'Text', valueType: 'text', stringValue: '', placeHolder: '[Text]' }],
  },
  {
    label: 'Month (MM)',
    widgetId: 'month',
    sbEntityType: 'MatterManagement.Entities.DateMMField, MatterManagement.BrowseMatters.Entities',
  },
  {
    label: 'Year (YY)',
    widgetId: 'shortYear',
    sbEntityType: 'MatterManagement.Entities.DateYYField, MatterManagement.BrowseMatters.Entities',
  },
  {
    label: 'Year (YYYY)',
    widgetId: 'longYear',
    sbEntityType: 'MatterManagement.Entities.DateYYYYField, MatterManagement.BrowseMatters.Entities',
  },
];

export const widgetFieldValidators: Record<string, Record<string, (input: string) => boolean>> = {
  autoCounter: {
    counterStart: (input) => {
      // allow empty
      if (input === '') {
        return true;
      }
      const val = +input;
      const strippedInput = input.replace(/^0*/, '');
      // allow many leading 0
      if (val === 0 && strippedInput === '') {
        return true;
      }
      if (!Number.isNaN(val) && `${val}` === strippedInput && val >= 0 && val < Number.MAX_SAFE_INTEGER / 2) {
        return true;
      }
      return false;
    },
    digitCount: (input) => {
      if (input === '') {
        return true;
      }
      const val = +input;
      const strippedInput = input.replace(/^0*/, '');
      // allow many leading 0
      if (val === 0 && strippedInput === '') {
        return true;
      }
      if (!Number.isNaN(val) && `${val}` === strippedInput && val >= 0 && val <= 100) {
        return true;
      }
      return false;
    },
  },
  text: { text: (input) => typeof input === 'string' && input.length < 11 },
};

export type TExampleParams = { date: Date; counter: number; label: string };

export const widgetRenderer = (widget: TWidget, exampleProps: TExampleParams): string => {
  switch (widget.widgetId) {
    case 'autoCounter': {
      const count = exampleProps.counter + +widget.editableFields![0].stringValue || 0;
      const digitCount = +widget.editableFields![1].stringValue || 0;

      return `${count}`.padStart(digitCount, '0');
    }
    case 'text': {
      return widget.editableFields![0].stringValue;
    }
    case 'month': {
      return `${exampleProps.date.getMonth() + 1}`.padStart(2, '0');
    }
    case 'longYear': {
      return `${exampleProps.date.getFullYear()}`.padStart(4, '0');
    }
    case 'shortYear': {
      return `${exampleProps.date.getFullYear() % 100}`.padStart(2, '0');
    }
    default:
      return 'UNKNOWN';
  }
};

export const clonify = <T extends {}>(thing: T): T => JSON.parse(JSON.stringify(thing));

export const cloneWidget = (widget: TWidget) => {
  const clone = clonify(widget);
  clone.instanceId = uuid();

  return clone;
};

export const getClonedWidgetFromSbEntityType = (sbEntityType: string) => {
  const found = getBaseWidgets().find((w) => w.sbEntityType.replace(/\s/g, '') === sbEntityType.replace(/\s/g, ''));

  return found ? cloneWidget(found) : null;
};

export type TAutoNumberDefinitionField = {
  fieldId: string;
  $type: string;
  text?: null | string;
  numCharacters?: null | number;
  startFrom?: null | number;
};

// gql data is a bit different from the db view: fieldId -> id and $type -> sbEntityType
export type TAutoNumberDefinitionFieldGql = Omit<TAutoNumberDefinitionField, 'fieldId' | '$type'> & {
  id: string;
  sbEntityType: string;
};

export const widgetToAutoNumberDefinitionField = (widget: TWidget) => {
  const digitCount = widget.editableFields?.find((f) => f.name === 'digitCount')?.stringValue;
  const counterStart = widget.editableFields?.find((f) => f.name === 'counterStart')?.stringValue;
  const text = widget.editableFields?.find((f) => f.name === 'text')?.stringValue;

  const field = {
    text,
    $type: widget.sbEntityType,
    fieldId: widget.instanceId || uuid(),
    numCharacters: digitCount ? +digitCount : undefined,
    startFrom: counterStart ? +counterStart : undefined,
  };

  return field;
};

export const autoNumberDefinitionFieldGqlToWidget = (gqlField: TAutoNumberDefinitionFieldGql) => {
  if (!gqlField.sbEntityType) {
    return null;
  }

  const clone = getClonedWidgetFromSbEntityType(gqlField.sbEntityType);

  if (!clone) {
    return clone;
  }

  if (gqlField.id) {
    clone.instanceId = gqlField.id;
  }

  if (clone.widgetId === 'text') {
    const textField = clone.editableFields!.find((f) => f.name === 'text')!;
    textField.stringValue = gqlField.text || '';
  }

  if (clone.widgetId === 'autoCounter') {
    const counterStartField = clone.editableFields!.find((f) => f.name === 'counterStart')!;
    counterStartField.stringValue = `${gqlField.startFrom || 0}`;
    const digitCountField = clone.editableFields!.find((f) => f.name === 'digitCount')!;
    digitCountField.stringValue = `${gqlField.numCharacters || 1}`;
  }

  return clone;
};

export const getAutoNumberingCounterStartValue = (widgets: TWidget[]) => {
  const autoCounterWidget = widgets.find((w) => w?.widgetId === 'autoCounter');

  if (!autoCounterWidget) {
    return null;
  }

  const counterStart = (autoCounterWidget.editableFields || []).find((f) => f.name === 'counterStart');

  if (!counterStart || counterStart.valueType !== 'number') {
    return null;
  }

  return +counterStart.stringValue;
};
