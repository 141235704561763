import { gql } from '@apollo/client';

const query = gql`
  query InvoiceViewedOnlineData($id: ID!) {
    invoiceViewedOnline(id: $id) {
      id
      contacts {
        id
        displayNameFirstLast
      }
    }
  }
`;

const notificationIds = ['WebQueryBillingSharedNotifications.BillingEventUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceViewedOnlineData = {
  query,
  notificationIds,
};
