import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import DiscountOptions from './DiscountOptions';

const mapStateToProps = (state, { discount, maxValue }) => ({
  discount,
  maxValue,
  discountType: discount.type,
});

const getAbsoluteOfPercentage = (percentage, maxValue) => (Math.min(percentage, 100) / 100) * maxValue;

export const DiscountOptionsContainer = withReduxStore(
  connect(
    mapStateToProps,
    null,
  )((props) => {
    const [absoluteDiscount, setAbsoluteDiscount] = useState(
      props.discount?.type === 0
        ? props.discount?.fixedDiscount
        : getAbsoluteOfPercentage(props.discount?.percentage, props.maxValue),
    );
    const [percentageDiscount, setPercentageDiscount] = useState(props.discount?.percentage || 0);
    const [discountType, setDiscountType] = useState(props.discount?.type || 0);
    const handleDiscountTypeChange = (e, value) => {
      // When user changes discount types, clear the other type box
      setDiscountType(Number(value));
      if (Number(value) === 0) {
        setPercentageDiscount(0);
      } else {
        setAbsoluteDiscount(0);
      }
    };
    const handleAbsoluteDiscountChange = (e) => setAbsoluteDiscount(e.target.value);
    const handlePercentageDiscountChange = (e) => {
      const validatedValue = Math.min(Math.round(e.target.value), 100);
      setPercentageDiscount(validatedValue);
      setAbsoluteDiscount(getAbsoluteOfPercentage(validatedValue, props.maxValue));
    };

    return (
      <DiscountOptions
        absoluteDiscount={absoluteDiscount}
        percentageDiscount={percentageDiscount}
        discountType={discountType}
        maxValue={props.maxValue}
        onDiscountTypeChange={handleDiscountTypeChange}
        onAbsoluteDiscountChange={handleAbsoluteDiscountChange}
        onPercentageDiscountChange={handlePercentageDiscountChange}
        onConfirm={() => props.onConfirmDiscount(discountType, absoluteDiscount, percentageDiscount)}
        onDiscard={props.onDiscardDiscount}
        disableConfirm={absoluteDiscount === 0 && percentageDiscount === 0}
      />
    );
  }),
);

DiscountOptionsContainer.displayName = 'DiscountOptionsContainer';

DiscountOptionsContainer.propTypes = {
  discount: PropTypes.shape({
    type: PropTypes.number,
    fixedDiscount: PropTypes.number,
    percentage: PropTypes.number,
    enabled: PropTypes.bool,
  }),
  maxValue: PropTypes.number,
  onConfirmDiscount: PropTypes.func.isRequired,
  onDiscardDiscount: PropTypes.func.isRequired,
};

DiscountOptionsContainer.defaultProps = {
  discount: {
    type: 0,
    fixedDiscount: 0,
    percentage: 0,
    enabled: false,
  },
  maxValue: 0,
};
