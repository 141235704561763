import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import {
  useTranslation,
  Checkbox,
  ContextMenu,
  CurrencyInput2,
  CurrencyDisplay,
  ExpandCollapseToggler,
  LinkableText,
  SlidingToggle,
} from '@sb-itops/react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { featureActive } from '@sb-itops/feature';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { PaymentPlanIndicator } from 'web/components/payment-plan-indicator';

import Styles from './TrustToOfficeTable.module.scss';

const REGION = getRegion();
const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

const TrustToOfficeTable = memo((props) => {
  const { t } = useTranslation();
  return (
    <Table
      list={props.rows}
      dataLoading={props.loading}
      rowClassName={({ rowData }) => (rowData && rowData.type === 'INVOICE' ? Styles.invoiceRow : 'matter-row')}
      summary={props.tableSummary}
      sort={props.sort}
      sortBy={props.sortBy}
      sortDirection={props.sortDirection}
      showFooter
      onRowClick={onRowClicked(props)}
    >
      <Column
        className="row-expander"
        dataKey="rowExpander"
        label=""
        width={25}
        headerRenderer={expandAllRowsRenderer(props)}
        cellRenderer={expanderCellRenderer(props)}
        disableSort
      />
      <Column
        dataKey="rowSelector"
        label=""
        width={34}
        headerRenderer={checkboxCellWrapperRenderer(toggleAllSelectionRenderer(props))}
        cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer(props))}
        disableSort
      />
      <Column
        dataKey="matterDisplay"
        label="Matter"
        cellRenderer={matterOrInvoiceNumberCellRenderer(props, t)}
        flexGrow={5}
      />
      <Column dataKey="debtor" label="Debtor(s)" cellRenderer={debtorCellRenderer(props)} flexGrow={5} disableSort />
      <Column
        dataKey="issuedDate"
        label="Issued Date"
        cellRenderer={yyyymmddLocalisedRenderer}
        width={100}
        disableSort
      />
      <Column dataKey="dueDate" label="Due Date" cellRenderer={yyyymmddLocalisedRenderer} width={100} disableSort />
      <Column
        className="right-align"
        dataKey="totalDue"
        label={getTotalDueLabel({ t })}
        flexGrow={2}
        maxWidth={180}
        cellRenderer={balanceCellLocalisedRenderer}
        footerRenderer={balanceCellLocalisedRenderer}
      />
      <Column
        className="right-align"
        dataKey="trustBalance"
        label={`${t('trust')} Balance`}
        width={100}
        flexGrow={1}
        cellRenderer={accountCellRenderer(t)}
        footerRenderer={balanceCellLocalisedRenderer}
      />
      <Column
        className={Styles.paymentAmount}
        dataKey="trustAmount"
        label={`${t('trust')} Transfer`}
        width={100}
        flexGrow={1}
        cellRenderer={paymentAmountCellRenderer(props, 'trust')}
        footerRenderer={balanceCellLocalisedRenderer}
        disableSort
      />
      <Column
        className={Styles.autoAllocate}
        dataKey="isAutoAllocated"
        label="Auto Allocate"
        cellRenderer={autoAllocateRenderer(props)}
        width={50}
        flexGrow={1}
      />
    </Table>
  );
});

function getTotalDueLabel({ t }) {
  if (hasFacet(facets.tax)) {
    return hasFacet(facets.interest) ? `Amount Due (Inc Interest/${t('tax')})` : `Amount Due (Inc ${t('tax')})`;
  }
  return hasFacet(facets.interest) ? `Amount Due (Inc Interest)` : `Amount Due`;
}

function expandAllRowsRenderer({ allExpanded, allMatterIds, onToggleAllMattersExpanded }) {
  return () => (
    <ExpandCollapseToggler
      className={Styles.headerExpandCollapseToggler}
      isExpanded={allExpanded}
      onToggled={() => onToggleAllMattersExpanded(allMatterIds, !allExpanded)}
    />
  );
}

function expanderCellRenderer({ onToggleMatterExpanded }) {
  return ({ rowData }) => {
    // Invoice rows do not need an expander, just the spacing for the column.
    if (rowData.type !== 'MATTER') {
      return null;
    }

    return (
      <ExpandCollapseToggler
        isExpanded={rowData.isExpanded}
        className={Styles.expandCollapseToggler}
        onToggled={() => onToggleMatterExpanded(rowData.matterId, !rowData.isExpanded)}
      />
    );
  };
}

function onRowClicked({ onToggleMatterExpanded }) {
  return ({ rowData }) => {
    // Invoice rows do not need an expander, just the spacing for the column.
    if (rowData.type !== 'MATTER') {
      return;
    }

    onToggleMatterExpanded(rowData.matterId, !rowData.isExpanded);
  };
}

function toggleAllSelectionRenderer({ allSelected, allInvoiceIds, onSelectInvoices }) {
  return () => (
    <Checkbox
      className="row-checkbox"
      checked={allSelected}
      onChange={() => {
        onSelectInvoices(allInvoiceIds, !allSelected);
      }}
    />
  );
}

function checkboxCellRenderer({ onSelectInvoices }) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return (
        <Checkbox
          checked={rowData.isSelected}
          onChange={() => onSelectInvoices(rowData.invoiceIds, !rowData.isSelected)}
        />
      );
    }
    return (
      <Checkbox
        checked={rowData.isSelected}
        onChange={() => onSelectInvoices([rowData.invoiceId], !rowData.isSelected)}
      />
    );
  };
}

function matterOrInvoiceNumberCellRenderer({ onClickLink }, t) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return (
        <div className={Styles.matterRowContainer}>
          <LinkableText
            key={rowData.matterId}
            className={Styles.matterDisplay}
            text={rowData.matterDisplay}
            onClickLink={() => onClickLink({ type: 'matter', id: rowData.matterId })}
            inline
            asLink
          />
          {rowData.isMultipleContactBalances && (
            <ContextMenu
              body={() =>
                'This matter has retainer funds from multiple contacts, funds will be taken from the contact ' +
                'with the lowest retainer balance first. To allocate by contact, ' +
                'please pay each invoice individually.'
              }
              timeout={0}
              showOnHover
            >
              <span className="icon icon-contacts-1" />
            </ContextMenu>
          )}
          {featureActive('BB-9573') && rowData?.hasUnpaidAD && (
            <div className={Styles.infoIcon}>
              <i
                title={`One or more invoices contains an anticipated ${t(
                  'expense',
                )} that has not yet been paid to the supplier.`}
                className="icon-alert-1"
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className={Styles.invoiceRowContainer}>
        <LinkableText
          key={rowData.invoiceId}
          text={`#${rowData.invoiceNumber}`}
          onClickLink={() => onClickLink({ type: 'invoice', id: rowData.invoiceId })}
          inline
          asLink
        />
        {featureActive('BB-9573') && rowData?.hasUnpaidAD && (
          <div className={Styles.infoIcon}>
            <i
              title={`This invoice contains an anticipated ${t('expense')} that has not yet been paid to the supplier.`}
              className="icon-alert-1"
            />
          </div>
        )}
      </div>
    );
  };
}

function debtorCellRenderer({ onClickLink }) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return null;
    }

    return (
      rowData &&
      rowData.debtors && (
        <div className={Styles.invoiceDebtors}>
          <span className={Styles.debtorsDisplay}>
            {rowData.debtors
              .map((debtor) => (
                <LinkableText
                  key={`${rowData.invoiceId}-${debtor.id}`}
                  className="client"
                  text={debtor?.contact?.displayName}
                  onClickLink={() => onClickLink({ type: 'contact', id: debtor.id })}
                  inline
                  asLink
                />
              ))
              .reduce((acc, elem) => {
                if (acc === null) {
                  return [elem];
                }
                return [...acc, ' | ', elem];
              }, null)}
          </span>
          {rowData.debtors.length > 1 && (
            <span className={Styles.multiDebtorsIcon}>
              <i
                title={rowData.debtors.map((debtor) => debtor?.contact?.displayName).join(' | ')}
                className={classnames('icon', 'icon-contacts-1')}
              />
            </span>
          )}
          {!!(rowData.paymentPlan?.status === paymentPlanStatuses.ACTIVE) && (
            <PaymentPlanIndicator paymentPlan={rowData.paymentPlan} onClickLink={onClickLink} />
          )}
        </div>
      )
    );
  };
}

function autoAllocateRenderer({ onToggleAutoAllocate }) {
  return ({ rowData }) => {
    if (rowData.type !== 'MATTER') {
      return null;
    }

    return (
      <label className={Styles.autoAllocateCell}>
        <SlidingToggle
          scope={rowData.matterId}
          name={rowData.matterId}
          selected={rowData.isAutoAllocated}
          onChange={() =>
            onToggleAutoAllocate({
              matterId: rowData.matterId,
              isAutoAllocated: !rowData.isAutoAllocated,
              matterTrustBalance: rowData.trustBalance,
              invoices: rowData.invoices,
            })
          }
        />
      </label>
    );
  };
}

function paymentAmountCellRenderer({ onChangePaymentAmount }, accountType) {
  return ({ rowData }) => {
    const paymentAmount = Math.max(0, rowData[`${accountType}Amount`]);

    if (rowData.type === 'MATTER') {
      return (
        <CurrencyDisplay
          amount={paymentAmount}
          asInput={false}
          styleClass={classnames(rowData[`${accountType}IsError`] && !rowData.isExpanded && Styles.errorText)}
          region={REGION}
        />
      );
    }

    const accountBalance = Math.max(0, rowData[`${accountType}Balance`]);
    const max = Math.min(rowData.totalDue, accountBalance);
    const isError = rowData[`${accountType}Error`];
    const isDisabled = isError
      ? false
      : rowData.isAutoAllocated || accountBalance === 0 || !rowData.isSelected || rowData.totalDue === 0;
    return (
      <CurrencyInput2
        className="currency-input"
        value={paymentAmount}
        min={0}
        max={max}
        disabled={isDisabled}
        onChange={(e) => onChangePaymentAmount(rowData.invoiceId, e.target.value)}
        hasError={isError}
        hideDollar
      />
    );
  };
}

function accountCellRenderer(t) {
  return ({ rowData }) => t('cents', { val: Math.max(0, rowData.trustBalance) });
}

TrustToOfficeTable.displayName = 'TrustToOfficeTable';

TrustToOfficeTable.propTypes = {
  loading: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.oneOf(['MATTER']).isRequired,
        matterId: PropTypes.string.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        matterDisplay: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isMultipleContactBalances: PropTypes.bool.isRequired,
        trustIsError: PropTypes.bool.isRequired,
        isAutoAllocated: PropTypes.bool.isRequired,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string.isRequired,
            totalDue: PropTypes.number,
          }),
        ),
        invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        trustBalance: PropTypes.number.isRequired,
        totalDue: PropTypes.number.isRequired,
        trustAmount: PropTypes.number.isRequired,
        hasUnpaidAD: PropTypes.bool.isRequired,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf(['INVOICE']).isRequired,
        invoiceId: PropTypes.string.isRequired,
        matterId: PropTypes.string,
        debtors: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            contact: PropTypes.shape({
              displayName: PropTypes.string,
            }),
          }),
        ),
        paymentPlan: PropTypes.object,
        issuedDate: PropTypes.number.isRequired,
        dueDate: PropTypes.number.isRequired,
        totalDue: PropTypes.number.isRequired,
        isSelected: PropTypes.bool,
        trustAmount: PropTypes.number.isRequired,
        trustBalance: PropTypes.number.isRequired,
        isAutoAllocated: PropTypes.bool.isRequired,
        hasUnpaidAD: PropTypes.bool.isRequired,
      }),
    ]),
  ).isRequired,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  allMatterIds: PropTypes.array.isRequired,
  allInvoiceIds: PropTypes.array.isRequired,
  tableSummary: PropTypes.object.isRequired,
  onSelectInvoices: PropTypes.func.isRequired,
  onToggleAllMattersExpanded: PropTypes.func.isRequired,
  onToggleMatterExpanded: PropTypes.func.isRequired,
  onToggleAutoAllocate: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangePaymentAmount: PropTypes.func.isRequired,
};

TrustToOfficeTable.defaultProps = {
  loading: false,
};

export default TrustToOfficeTable;
