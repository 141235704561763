import React from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@sb-itops/react/drop-down-list';

const AttorneyDropDownField = ({ handleChange, options, defaultValue }) => (
  <DropDownList onValueChange={handleChange} options={options} defaultValue={defaultValue} />
);

AttorneyDropDownField.propTypes = {
  options: PropTypes.array,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.object,
};

AttorneyDropDownField.defaultProps = {
  options: [],
  handleChange: undefined,
  defaultValue: undefined,
};

const AttorneyDropDownFieldMemo = React.memo(AttorneyDropDownField);

export default AttorneyDropDownFieldMemo;
