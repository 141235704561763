import { gql } from '@apollo/client';

const query = gql`
  query StaffTargetGroupEntities($ids: [ID]) {
    staffTargetGroups(ids: $ids) {
      id
      name
      targets {
        type
        frequency
        value
      }
      isInactive
    }
  }
`;

const notificationIds = ['FeesNotifications.StaffTargetGroupUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const StaffTargetGroupEntities = {
  query,
  notificationIds,
};
