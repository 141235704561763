import { defaultPath } from './default-path';

export const SET_SORT = `${defaultPath}/SET_SORT`;
export const SET_TASK_VIEW = `${defaultPath}/SET_TASK_VIEW`;
export const TOGGLE_EXPANDED = `${defaultPath}/TOGGLE_EXPANDED`;
export const TOGGLE_ASSIGNEE_FILTER_EXPANDED = `${defaultPath}/TOGGLE_ASSIGNEE_FILTER_EXPANDED`;
export const TOGGLE_CATEGORY_FILTER_EXPANDED = `${defaultPath}/TOGGLE_CATEGORY_FILTER_EXPANDED`;
export const TOGGLE_VIEW_EXPANDED = `${defaultPath}/TOGGLE_VIEW_EXPANDED`;
export const SET_SHOW_DELETED = `${defaultPath}/SET_SHOW_DELETED`;
export const SET_ASSIGNEE_IDS = `${defaultPath}/SET_ASSIGNEE_IDS`;
export const SET_CATEGORY_IDS = `${defaultPath}/SET_CATEGORY_IDS`;

export type TaskViewTypes =
  | 'ALL'
  | 'RECENTLY_COMPLETED'
  | 'DUE_TODAY'
  | 'DUE_THIS_WEEK'
  | 'OVERDUE'
  | 'DUE_LATER'
  | 'ALL_COMPLETED';
