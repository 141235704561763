import { gql } from '@apollo/client';
import { getApolloClient } from './index';

const dataSourceType = Object.freeze({
  TRUTH: 'TRUTH',
  CLIENT_OPTIMISTIC: 'CLIENT_OPTIMISTIC',
  SERVER_OPTIMISTIC: 'SERVER_OPTIMISTIC',
});

/**
 * Optimistic Update for Load on Demand
 *
 * @param {Object} param
 * @param {*} [param.client] Apollo client class
 * @param {Object} param.identifier GraphQL compatible object to identify the entity. Should at least contain __typename and the entity-specific ID field
 * @param {Object} param.fieldOpdates Updater functions where the object key is the field name, eg: { anArrayField: (oldValues) => [...oldValues, newValue] }
 * @param {string} param.rootQueryField Name of the field in ROOT_QUERY. Used to create link a new (optimistic) entity if one does not already exist
 * @returns {Object} containing the rollback and server success functions
 */
const optimisticUpdate = ({ client, identifier, fieldOpdates, rootQueryField }) => {
  if (!fieldOpdates || !Object.keys(fieldOpdates).length) {
    return;
  }

  const apolloClient = client === undefined ? getApolloClient() : client;

  const id = apolloClient.cache.identify(identifier);

  // We retrieve the existing cache data for the entity and return only the values
  // that are expected to be changed so that they can be used for rollback
  const previousEntity = apolloClient.cache.data.data[id];

  const { previousValues, nextValues } = Object.keys(fieldOpdates).reduce(
    (acc, field) => {
      // Store the field value from the existing entity
      acc.previousValues[field] = previousEntity && previousEntity[field];

      // Execute the updater function for the field by passing the previous value
      acc.nextValues[field] = fieldOpdates[field](previousEntity && previousEntity[field]);

      return acc;
    },
    { previousValues: {}, nextValues: {} },
  );

  // Mark the entity as optimistic
  previousValues.dataSourceType = previousEntity && previousEntity.dataSourceType;
  nextValues.dataSourceType = dataSourceType.CLIENT_OPTIMISTIC;

  // Update ROOT_QUERY with the reference to new entity in case the existing value is `null`
  const updateRootQuery = rootQueryField && !previousEntity;
  if (updateRootQuery) {
    apolloClient.cache.modify({
      fields: {
        [rootQueryField]: (existing, { toReference }) => toReference(identifier),
      },
      broadcast: false,
    });
  }

  // Update or create the entity
  apolloClient.writeFragment({
    id,
    fragment: gql`
      fragment Opdate${identifier.__typename} on ${identifier.__typename} {
        ${Object.keys(identifier).join('\n')}
        ${Object.keys(fieldOpdates).join('\n')}
        dataSourceType
      }
    `,
    data: {
      ...identifier, // Need to have the identifier values in case a new entity is created in cache
      ...nextValues,
    },
  });

  return {
    rollbackOpdate: () =>
      rollbackOptimisticUpdate({
        client,
        identifier,
        rollbackEntity: previousValues,
        rootQueryField,
        updateRootQuery,
      }),
    opdateServerSuccess: () =>
      apolloClient.cache.modify({
        id,
        fields: {
          dataSourceType: () => dataSourceType.SERVER_OPTIMISTIC,
        },
      }),
  };
};

/**
 * Optimistic Update Rollback for Load on Demand
 *
 * @param {Object} param
 * @param {*} [param.client] Apollo client class
 * @param {Object} param.identifier GraphQL compatible object to identify the entity. Should at least contain __typename and the entity-specific ID field
 * @param {Object} param.rollbackEntity Object containing only the opdated fields and their original values
 * @param {string} param.rootQueryField Name of the field in ROOT_QUERY. Used to remove the link to the new (optimistic) entity
 * @param {boolean} param.updateRootQuery Should revert the field in the ROOT_QUERY?
 * @returns {boolean} Rollback success
 */
const rollbackOptimisticUpdate = ({ client, identifier, rollbackEntity, rootQueryField, updateRootQuery }) => {
  const apolloClient = client === undefined ? getApolloClient() : client;

  const id = apolloClient.cache.identify(identifier);

  // Revert ROOT_QUERY changes and evict the entity if it was created in the opdate
  if (rootQueryField && updateRootQuery) {
    apolloClient.cache.evict({
      id,
      broadcast: false,
    });

    return apolloClient.cache.modify({
      fields: {
        [rootQueryField]: () => null,
      },
    });
  }

  // Revert all the updated fields to their original values
  const isRolledback = apolloClient.cache.modify({
    id,
    fields: Object.keys(rollbackEntity).reduce((acc, field) => {
      acc[field] = () => rollbackEntity[field];
      return acc;
    }, {}),
  });

  return isRolledback;
};

const generateUpdateObject = (targetOpdate) =>
  Object.keys(targetOpdate).reduce((acc, key) => {
    acc[key] = () => targetOpdate[key];
    return acc;
  }, {});

export { optimisticUpdate, generateUpdateObject };
