'use strict';

const { payButtonEnabledOptionValues: payButtonEnabledOptionValuesEnum } = require('./pay-button-enabled-option-types');

/**
 * Determines if the pay button should be enabled for a matter
 *
 * The pay button is enabled by default, unless explicitly set to false by the user.
 *
 * The payButtonEnabledOption may potentially be:
 *  1. Missing from the entity
 *  2. Assigned a .Net default value of 0
 *    - NB: Accepted values are 1 (disabled) or 2 (enabled)
 *
 * @param {object} params
 * @param {Object} params.matterInvoiceSettings entity
 *
 */
function isPayButtonEnabledForMatter({ matterInvoiceSettings = {} }) {
  const isAttributePresentInEntity = 'payButtonEnabledOption' in matterInvoiceSettings;
  const assignedDotNetDefaultValue = matterInvoiceSettings.payButtonEnabledOption === 0;

  if (!isAttributePresentInEntity || assignedDotNetDefaultValue) {
    return true;
  }

  const payButtonEnabled = matterInvoiceSettings.payButtonEnabledOption !== payButtonEnabledOptionValuesEnum.DISABLED;

  return payButtonEnabled;
}

module.exports = {
  isPayButtonEnabledForMatter,
};
