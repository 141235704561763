/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';

export const createTrustAccountAddEditFormSchema = ({ numberOfTrustAccounts, bankBranchNumberRegex }) =>
  Yup.object().shape({
    accountName: Yup.string().required(),
    accountNumber: hasFacet(facets.electronicPayment) ? Yup.string().required() : undefined,
    bankName: hasFacet(facets.bankNameAndBranchNameRequiredForTrustAccount) ? Yup.string().required() : undefined,
    branchName: hasFacet(facets.bankNameAndBranchNameRequiredForTrustAccount) ? Yup.string().required() : undefined,
    branchNumber: hasFacet(facets.electronicPayment)
      ? Yup.string().matches(bankBranchNumberRegex).required()
      : undefined,
    displayName: Yup.string().optional(),
    location: numberOfTrustAccounts <= 1 ? Yup.string().nullable() : Yup.string().required(),
    statutoryDepositMatterId: hasFacet(facets.statutoryDepositMatter)
      ? customYupValidators.uuid().optional()
      : undefined,
  });
