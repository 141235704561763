import * as Yup from 'yup';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';

// Context we need to pass when validating:
// t function
// minAmountAllowed

export const invoiceStatementAddPaymentFormSchema = Yup.object().shape({
  amount: Yup.number()
    .min(0.1)
    .required()
    .test('is-valid-amount', '', (amount, { createError, options, parent: fieldValues }) => {
      if (Object.keys(options.context || {}).length === 0) {
        return true; // skip validation if no context is provided
      }

      const { minAmountAllowed, t } = options.context || {};
      const { takePaymentNow, payments } = fieldValues;

      const totalPayments = Object.values(payments || {}).reduce(
        (runningTotal, invoicePayment) => runningTotal + invoicePayment,
        0,
      );

      let errorMessage;

      if (takePaymentNow && amount < minAmountAllowed) {
        errorMessage = `Minimal amount allowed by your payment processor is ${t('cents', {
          val: minAmountAllowed,
        })}`;
      } else if (takePaymentNow && (amount <= 0 || amount > totalPayments)) {
        errorMessage = 'Amount should be equal or less than the invoice totals selected';
      } else if (!takePaymentNow && amount !== totalPayments) {
        errorMessage = 'Amount should be equal to the invoice totals selected';
      }

      if (errorMessage) {
        return createError({
          path: 'amount',
          message: errorMessage,
        });
      }

      return true;
    }),

  paidById: customYupValidators.uuid().required(),
  effectiveDate: customYupValidators.integerDate().required(),
});
