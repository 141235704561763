import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as messageDisplay from '@sb-itops/message-display';
import { withReduxStore } from '@sb-itops/react';
import { withOnUpdate } from '@sb-itops/react/hoc';
import { services } from '@sb-billing/business-logic/report';
import { fetchPostP } from '@sb-itops/redux/fetch';
import ReportRunnerPeriscope from './ReportRunnerPeriscope';

const { makePeriscopeReportUrlP } = services;

const defaultReportState = {
  periscopeUrl: '',
  urlGenerationInProgress: false,
  awaitingPeriscopeContent: false,
};

const mapStateToProps = (state, { reportConfig, reportState: currentReportState }) => {
  const reportState = currentReportState || defaultReportState;
  return {
    reportId: reportConfig.reportId,
    periscopeUrl: reportState.periscopeUrl,
    showLoadingSpinner: reportState.urlGenerationInProgress || reportState.awaitingPeriscopeContent,
  };
};

const generatePeriscopeUrl =
  ({ reportConfig, setReportState }) =>
  async (dispatch) => {
    dispatch(setReportState({ urlGenerationInProgress: true }));
    try {
      const periscopeUrl = await makePeriscopeReportUrlP({ fetchPostP, reportConfig });
      dispatch(setReportState({ periscopeUrl, urlGenerationInProgress: false, awaitingPeriscopeContent: true }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      messageDisplay.error('Failed to generate report');
    }
  };

const mapDispatchToProps = (dispatch, { reportConfig, setReportState }) => ({
  onUpdate: () => {
    // Auto-generate the report on load.
    dispatch(generatePeriscopeUrl({ reportConfig, setReportState }));

    // Clean-up state on unload.
    const onUnload = () => {
      dispatch(setReportState(defaultReportState));
    };

    return onUnload;
  },
  onPeriscopeContentReady: () => dispatch(setReportState({ awaitingPeriscopeContent: false })),
});

export const ReportRunnerPeriscopeContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnUpdate(['reportConfig'])(ReportRunnerPeriscope)),
);

ReportRunnerPeriscopeContainer.displayName = 'ReportRunnerPeriscopeContainer';

ReportRunnerPeriscopeContainer.propTypes = {
  reportConfig: PropTypes.object.isRequired,
};

ReportRunnerPeriscopeContainer.defaultProps = {};

export default ReportRunnerPeriscopeContainer;
