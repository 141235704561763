'use strict';

const { sentViaTypes } = require('./sent-via-type');
const { indicatorStatuses } = require('./indicator-statuses');
const { sendStatuses } = require('./send-statuses');

// This function can be removed after BB-11448 turns on
/**
 * @deprecated This function figures out the overall status for the indicator icon
 * based on each of the individual email/communicate statuses for each debtor.
 * @param {array} debtorsCorrespondenceHistory
 * @param {number} numberOfContacts
 * @returns
 */
function determineIndicatorStatus(debtorsCorrespondenceHistory, debtorCount) {
  if (debtorsCorrespondenceHistory.length === 0) {
    return indicatorStatuses.NOT_SENT;
  }

  let overallStatus;
  let debtorIndex = 0;

  // Sort debtorsCorrespondenceHistory array by lastUpdated, latest one in the first.
  // If the overall status is success/partial success, the icon depends on the latest message's send method
  // (EG. If overall status is success, the last message was sent via email then it will show email success icon.)
  const debtorSendStatusesSortedBySentDate = debtorsCorrespondenceHistory.sort(
    (d1, d2) => new Date(d2.lastUpdated).getTime() - new Date(d1.lastUpdated).getTime(),
  );

  while (
    debtorIndex < debtorSendStatusesSortedBySentDate.length &&
    overallStatus !== indicatorStatuses.EMAIL_ERROR &&
    overallStatus !== indicatorStatuses.COMMUNICATE_ERROR
  ) {
    const { status, sentVia } = debtorSendStatusesSortedBySentDate[debtorIndex];
    const isSentViaCommunicate = sentVia === sentViaTypes.COMMUNICATE;

    switch (status) {
      case sendStatuses.ERROR:
        // If any of the statuses are an error, the overall is in error.
        // This will break the loop.
        overallStatus = isSentViaCommunicate ? indicatorStatuses.COMMUNICATE_ERROR : indicatorStatuses.EMAIL_ERROR;
        break;

      case sendStatuses.IN_PROGRESS:
        // If any of the statuses are in progress, the overall status is also in progress.
        // We cannot break the loop based on hitting this overall status, as an error status
        // might follow.
        overallStatus = indicatorStatuses.IN_PROGRESS;
        break;

      case sendStatuses.NOT_SENT:
        // If all of the statuses are not sent, then we treat the overall status as not sent.
        // If statuses until now have been successful, we have an overall status of partial success.
        if (!overallStatus) {
          overallStatus = indicatorStatuses.NOT_SENT;
        } else if (overallStatus === indicatorStatuses.EMAIL_SUCCESS) {
          overallStatus = indicatorStatuses.EMAIL_PARTIALLY_SENT;
        } else if (overallStatus === indicatorStatuses.COMMUNICATE_SUCCESS) {
          overallStatus = indicatorStatuses.COMMUNICATE_PARTIALLY_SENT;
        }
        break;

      case sendStatuses.SUCCESS:
        // Other statuses overwrite success, so it's safe to set success as the overall
        // status so long as no status has been set yet.
        if (!overallStatus) {
          overallStatus = isSentViaCommunicate
            ? indicatorStatuses.COMMUNICATE_SUCCESS
            : indicatorStatuses.EMAIL_SUCCESS;
        }
        break;

      default:
        throw new Error(`Unexpected email status ${status}`);
    }

    debtorIndex += 1;
  }

  if (overallStatus === indicatorStatuses.EMAIL_SUCCESS && debtorsCorrespondenceHistory.length !== debtorCount) {
    overallStatus = indicatorStatuses.EMAIL_PARTIALLY_SENT;
  } else if (
    overallStatus === indicatorStatuses.COMMUNICATE_SUCCESS &&
    debtorsCorrespondenceHistory.length !== debtorCount
  ) {
    overallStatus = indicatorStatuses.COMMUNICATE_PARTIALLY_SENT;
  }

  return overallStatus;
}

module.exports = {
  determineIndicatorStatus,
};
