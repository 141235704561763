import { getPaymentsByInvoiceId } from '@sb-billing/redux/payments';

angular.module('sb.billing.webapp').component('sbDataPaymentsByInvoiceId',
{
  'require': {
    composer: '^sbCompose'
  },
  'bindings': {invoiceId: '<', entityDataKey: '@'},
  'controller': function ($scope, sbLoggerService, sbInvoicingService) {
    'use strict';
    var ctrl = this,
      log = sbLoggerService.getLogger('sbDataPaymentsByInvoiceId'),
      entityDataKey = ctrl.entityDataKey || 'payments';

    //log.setLogLevel('info');

    ctrl.$onInit = function () {
      log.info('invoiceId', ctrl.invoiceId);
      log.info('entityDataKey', ctrl.entityDataKey);
      updatePayments();
    };

    $scope.$on('smokeball-data-update-sbPaymentService', function () {
      log.info('saw data update');
      updatePayments();
    });
    $scope.$on('smokeball-data-update-sbInvoicingService', function () {
      log.info('saw data update');
      updatePayments();
    });

    function getPayments(invoiceId) {
      var payments = getPaymentsByInvoiceId(invoiceId);
      _.each(payments, function(payment) {
        _.each(payment.invoices, function(invoice) {
          var fullInvoice = sbInvoicingService.getInvoice(invoice.invoiceId);
          invoice.invoiceNumber = fullInvoice && fullInvoice.invoiceNumber;
        });
      });
      return payments;
    }

    function updatePayments() {
      var payments = [];
      if (_.isArray(ctrl.invoiceId)) {
        _.each(ctrl.invoiceId, function(id) {
          var pp = getPayments(id);
          log.info('payments for invoice Id: ', id, pp);
          payments = payments.concat(pp);
        });
      } else {
        payments = payments.concat(getPayments(ctrl.invoiceId));
      }
      log.info('payments for invoice %s', ctrl.invoiceId, payments);
      ctrl.composer.setComposeData({dataType: entityDataKey, data: payments}, entityDataKey);
    }
  }
}
);
