angular.module('sb.billing.webapp').component('sbDataTrustRetainerByMatterId', {
  require: { compose: '^sbCompose' },
  bindings: { matterId: '<', composeDataKey: '=?' },
  controller: trustRetainerByMatterIdController
});

function trustRetainerByMatterIdController($scope, sbMattersMbService, sbBillingConfigurationService, sbDefaultMatterBillingConfigurationService) {
  const ctrl = this;
  const listeners = [
    $scope.$on('$destroy', () => {
      for (let unregister of listeners) unregister();
    }),
    $scope.$on('smokeball-data-update-sbMattersMbService', update),
    $scope.$on('smokeball-data-update-sbBillingConfigurationService', update),
    $scope.$on('smokeball-data-update-sbDefaultMatterBillingConfigurationService', update)
  ];

  ctrl.$onInit = () => {
    update();
  };

  ctrl.$onChanges = () => {
    update();
  }

  const getConfig = () => ctrl.matterId ? sbBillingConfigurationService.getByMatterId(ctrl.matterId) || {} : {};

  const getTrustRetainer = () => {
    const {
      minimumTrustRetainerActive,
      minimumTrustRetainerAmount,
      trustRetainerReplenishAmount,
      trustRetainerSettingsOverridden
    } = getConfig();

    return {
      minimumTrustRetainerActive,
      minimumTrustRetainerAmount,
      trustRetainerReplenishAmount,
      trustRetainerSettingsOverridden,
    };
  };

  function update() {
    const defaultMatterBillingConfig = sbDefaultMatterBillingConfigurationService.getConfig() || {};
    const trustRetainerConfig = getTrustRetainer();

    ctrl.compose.setComposeData({
      minimumTrustRetainerActive:      defaultMatterBillingConfig.minimumTrustRetainerActive && trustRetainerConfig.minimumTrustRetainerActive,
      minimumTrustRetainerAmount:      trustRetainerConfig.trustRetainerSettingsOverridden ? trustRetainerConfig.minimumTrustRetainerAmount : defaultMatterBillingConfig.minimumTrustRetainerAmount,
      trustRetainerReplenishAmount:    trustRetainerConfig.trustRetainerSettingsOverridden ? trustRetainerConfig.trustRetainerReplenishAmount : defaultMatterBillingConfig.trustRetainerReplenishAmount,
      trustRetainerSettingsOverridden: trustRetainerConfig.trustRetainerSettingsOverridden,
    }, ctrl.composeDataKey || 'trustRetainer');
  }
}

