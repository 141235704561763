'use strict';

const Big = require('big.js');

/**
 * Sort cheques by cheque number, this is needed as padded cheque number
 * like '000000002' > '00001' don't sort properly. Conversion to Big
 * is needed to deal with large cheque numbers out in the wild
 * @param {*} cheques
 * @param {*} sortDirection
 * @returns
 */
function sortChequesByChequeNumber(cheques, sortDirection) {
  const sortOrder = sortDirection === 'asc' ? 1 : -1;

  return cheques.sort((a, b) => {
    let chequeNumberA;
    let chequeNumberB;
    try {
      chequeNumberA = new Big(a.chequeNumber);
      chequeNumberB = new Big(b.chequeNumber);
    } catch (err) {
      if (a.chequeNumber === b.chequeNumber) {
        return a.systemDate.localeCompare(b.systemDate);
      }
      return a.chequeNumber.localeCompare(b.chequeNumber) * sortOrder;
    }

    if (chequeNumberA.gt(chequeNumberB)) {
      return sortOrder;
    }
    if (chequeNumberA.lt(chequeNumberB)) {
      return -sortOrder;
    }
    return a.systemDate.localeCompare(b.systemDate);
  });
}

module.exports = {
  sortChequesByChequeNumber,
};
