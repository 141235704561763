import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as sortDirectionFeature from './index';

/**
 * useSort - hook for managing sort state in redux
 *
 * @param {Object} param
 * @param {string} param.scope Scope ID for the feature
 * @param {string|string[]} [param.initialSortBy] Initial/default sort by
 * @param {string|string[]} [param.initialSortDirection] Initial/default sort direction
 * @returns {Object} Object containing the `sortBy` and `sortDirection` values and their updater functions
 */
export const useSort = ({ scope, initialSortBy, initialSortDirection }) => {
  const dispatch = useDispatch();
  const { selectors, actions } = useScopedFeature(sortDirectionFeature, scope);

  const sortBy = useSelector(selectors.getSortBy) || initialSortBy;
  const sortDirection = useSelector(selectors.getSortDirection) || initialSortDirection;

  if (initialSortBy && !sortBy) {
    dispatch(actions.setSortBy({ sortBy: initialSortBy }));
  }
  if (initialSortDirection && !sortDirection) {
    dispatch(actions.setSortDirection({ sortDirection: initialSortDirection }));
  }

  const setSortBy = (newSortBy) => dispatch(actions.setSortBy({ sortBy: newSortBy }));
  const setSortDirection = (newSortDirection) =>
    dispatch(actions.setSortDirection({ sortDirection: newSortDirection }));

  return {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  };
};
