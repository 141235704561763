import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { filterOptions } from '@sb-billing/business-logic/invoice-filters';
import { isUtbmsEnabled } from '@sb-billing/redux/utbms-settings';
import { withReduxStore } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';

import * as billsListFilters from 'web/redux/route/home-billing-bills-list';

import InvoicesFilterPanel from './InvoicesFilterPanel';

const mapStateToProps = (state) => {
  const { getShowFilters, getFilters, getHideFilters } = billsListFilters.selectors;
  const expanded = getShowFilters(state);
  const hideFilters = getHideFilters(state);
  const filters = getFilters(state);
  const isUtbmsEnabledForFirm = isUtbmsEnabled();

  return {
    expanded,
    filters,
    hideFilters,
    filterOptions,
    isUtbmsEnabledForFirm,
    showSendPreferenceFilter: true,
    showBillingFrequencyFilter: true,
    showOperatingAccountFilter: hasFacet(facets.operatingAccount),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(billsListFilters.actions, dispatch),
});

const InvoicesFilterPanelContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(InvoicesFilterPanel));

InvoicesFilterPanelContainer.propTypes = {
  invoiceListData: PropTypes.object.isRequired,
  contactMatterBalancesTitle: PropTypes.string,
  personResponsible: PropTypes.string,
  showUtbmsFilter: PropTypes.bool,
};

InvoicesFilterPanelContainer.defaultProps = {
  contactMatterBalancesTitle: '',
  personResponsible: '',
  showUtbmsFilter: false,
};

export default InvoicesFilterPanelContainer;
