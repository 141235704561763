/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CurrencyInput2 } from '@sb-itops/react/currency-input.2';
import { sort } from '@sb-itops/sort';
import classnames from 'classnames';
import { Add } from 'web/components/icons';
import styles from './StaffRateOverrider.module.scss';

const prepareStaffList = ({ staffMembers, overrides }) => {
  const overriderIds = overrides.map((override) => override.staffId);
  // Remove current overrides from selection
  const staffList = staffMembers.filter((staff) => !staff.isFormerStaff && !overriderIds.includes(staff.id));
  return sort(staffList, ['name'], ['asc']);
};

const StaffRateOverrider = ({ staffMembers, disabled, overrides, onUpdate, hasError }) => {
  const staffOverrides = useMemo(() => overrides || [], [overrides]); // this is needed to handle angular usage where a null is passed in
  const staffMemberMap = useMemo(() => {
    const newStaffMemberMap = staffMembers.reduce((acc, staff) => ({ ...acc, [staff.id]: staff }), {});
    return newStaffMemberMap;
  }, [staffMembers]);
  const [staffList, setStaffList] = useState(prepareStaffList({ staffMembers, overrides: staffOverrides }));
  const [selectedStaff, setSelectedStaff] = useState({ staffId: '', rate: undefined });

  // this useEffect is needed as this component manages its own state staffList
  // and thus when staffMembers changes, we should update staffList accordingly
  useEffect(() => {
    const newStaffList = prepareStaffList({ staffMembers, overrides: staffOverrides });
    setStaffList(newStaffList);
  }, [staffMembers, staffOverrides]);

  const onRemoveOverride = (staffId) => {
    const updatedOverrides = staffOverrides.filter((override) => override.staffId !== staffId);
    const staffRemoved = staffMemberMap[staffId];
    const updatedStaffList = sort([...staffList, staffRemoved], ['name'], ['asc']);
    onUpdate(updatedOverrides);
    setStaffList(updatedStaffList);
  };

  const onUpdateRate = (staffId, staffRate) => {
    const updatedOverrides = staffOverrides.map((staff) => {
      if (staff.staffId === staffId) {
        return { ...staff, rate: staffRate };
      }
      return staff;
    });
    onUpdate(updatedOverrides);
  };

  const onSelectedStaffChange = (staffId) => {
    const selectedStaffMember = staffMemberMap[staffId];
    const rate = selectedStaffMember ? selectedStaffMember.rate : undefined;
    setSelectedStaff({ staffId, rate });
  };

  const onSelectedStaffRateChange = (rate) => {
    setSelectedStaff({ ...selectedStaff, rate });
  };

  const onAddStaffOverride = () => {
    const staffRateAdded = { staffId: selectedStaff.staffId, rate: selectedStaff.rate || 0 };
    const updatedOverrides = [staffRateAdded, ...staffOverrides];
    onUpdate(updatedOverrides);
    setStaffList(staffList.filter((staff) => staff.id !== selectedStaff.staffId));
    setSelectedStaff({ staffId: '', rate: undefined });
  };

  return (
    <div className={classnames('sb-table', styles.staffRateOverrider, disabled && styles.staffRateOverriderDisabled)}>
      <fieldset disabled={disabled}>
        {/* Add new staff member */}
        <div className={classnames('sb-table-row sb-no-hover-style', styles.addStaffRate)}>
          <div className={classnames('sb-table-cell staff', styles.staff)}>
            <select
              name="staffSelect"
              id="staffSelect"
              className={classnames('form-control', hasError && styles.hasError)}
              value={selectedStaff.staffId}
              onChange={(e) => onSelectedStaffChange(e.target.value)}
            >
              <option key={-1} value={null}>
                Select Staff
              </option>
              {staffList.map((staff, index) => (
                <option key={index} value={staff.id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>
          <div className={classnames('sb-table-cell', 'rate', styles.rate)}>
            <CurrencyInput2
              name="staff-rate-entry"
              className={classnames('quick-entry-container', styles.rateField)}
              value={selectedStaff.rate || 0}
              hasError={hasError}
              onChange={(e) => onSelectedStaffRateChange(e.target.value || 0)}
            />
          </div>
          <div className={classnames('sb-table-cell', styles.addNew, styles.action)} disabled={disabled}>
            <Add onClick={() => selectedStaff.staffId && onAddStaffOverride()} />
          </div>
        </div>
        {/* Staff overrides header */}
        {staffOverrides.length > 0 && (
          <div className={classnames('sb-table-row sb-no-hover-style', styles.tableHeader)}>
            <div className={classnames('sb-table-cell', styles.name)}>Staff</div>
            <div className={classnames('sb-table-cell', styles.rate)}>Rate</div>
            <div className={classnames('sb-table-cell', styles.action)}>&nbsp;</div>
          </div>
        )}
        {/* Staff overrides */}
        {staffOverrides.map((staff, index) => {
          const staffMember = staffMemberMap[staff.staffId];
          const staffName = staffMember?.name || staffMember?.initials;
          return (
            <div key={index} className={classnames('sb-table-row sb-no-hover-style', styles.sbRowTableCell)}>
              <div className={classnames('sb-table-cell', styles.name)}>{staffName}</div>
              <div className={classnames('sb-table-cell', styles.rate)}>
                <CurrencyInput2
                  className={classnames('quick-entry-container', styles.rateField)}
                  value={staff.rate || 0}
                  placeholder="0.00 Per Hour"
                  onChange={(e) => onUpdateRate(staff.staffId, e.target.value || 0)}
                />
              </div>
              <div className={classnames('sb-table-cell', styles.action)}>
                <i
                  className={classnames('close-icon', styles.closeIcon)}
                  onClick={() => onRemoveOverride(staff.staffId)}
                />
              </div>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

StaffRateOverrider.propTypes = {
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      initials: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      name: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  overrides: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

StaffRateOverrider.defaultProps = {
  staffMembers: [],
  overrides: [],
  disabled: false,
  hasError: false,
};

export default StaffRateOverrider;
