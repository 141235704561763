import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { useCorrespondenceHistoryPerContact } from 'web/hooks/useCorrespondenceHistoryPerContact';
import { getInvoiceCorrespondenceHistoryGroupedByDebtor } from '@sb-billing/redux/correspondence-history';
import { MultiContactCorrespondenceDetailsInline } from './MultiContactCorrespondenceDetailsInline';

const hooks = ({ invoiceId }) => ({
  useCorrespondenceHistory: () => {
    const invoiceSummary = useSelector(() => getInvoiceById(invoiceId));
    const debtorIds = invoiceSummary.currentVersion.debtors.map(({ id }) => id);

    const correspondenceHistoryByDebtor = useSelector(() =>
      getInvoiceCorrespondenceHistoryGroupedByDebtor(invoiceId, debtorIds),
    );

    const { loading, latestCorrespondencePerContact } = useCorrespondenceHistoryPerContact({
      correspondenceHistory: correspondenceHistoryByDebtor,
    });

    return {
      loading,
      latestCorrespondencePerContact,
    };
  },
});

export const MultiContactCorrespondenceDetailsInlineContainer = withReduxProvider(
  composeHooks(hooks)(MultiContactCorrespondenceDetailsInline),
);

MultiContactCorrespondenceDetailsInlineContainer.displayName = 'MultiContactCorrespondenceDetailsInlineContainer';

MultiContactCorrespondenceDetailsInlineContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

MultiContactCorrespondenceDetailsInlineContainer.defaultProps = {};
