import React from 'react';

const composeHooks = (hooks) => (Component) => {
  if (!Component) {
    throw new Error('Component must be provided to compose');
  }

  if (!hooks) {
    return Component;
  }

  return (props) => {
    const hooksObject = typeof hooks === 'function' ? hooks(props) : hooks;

    // Flatten values from all hooks to a single object
    const hooksProps = Object.entries(hooksObject).reduce((acc, [hookKey, hook]) => {
      let hookValue = hook(props);

      if (Array.isArray(hookValue) || typeof hookValue !== 'object') {
        hookValue = { [hookKey]: hookValue };
      }

      Object.entries(hookValue).forEach(([key, value]) => {
        acc[key] = value;
      });

      return acc;
    }, {});

    // eslint-disable-next-line react/jsx-filename-extension
    return <Component {...props} {...hooksProps} />;
  };
};

export default composeHooks;
