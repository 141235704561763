import { SET_SELECTED_PAYMENT_PLAN } from './types';

/**
 * store the selected value in the redux state
 * @param {string} paymentPlanId -  the id of the payment plan to store
 * @returns {object} redux action.
 * @throws if value is falsy
 */
export const setSelectedPaymentPlan = ({ paymentPlanId }) => {
  if (!paymentPlanId) {
    throw new Error('string cannot be falsy');
  }

  return {
    type: SET_SELECTED_PAYMENT_PLAN,
    payload: paymentPlanId,
  };
};

/**
 * clear the redux state
 * @param {string} value -  the value to store
 * @returns {object} redux action.
 * @throws if value is falsy
 */
export const clearSelectedPaymentPlan = () => ({
  type: SET_SELECTED_PAYMENT_PLAN,
  payload: null,
});
