angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/advanced-search/contacts';

  $stateProvider

    .state('home.billing.advanced-search.contacts', {
      url: '/contacts?searchTerm',
      templateUrl: BASE_DIRECTORY + '/contacts.html',
      data: {
        authorized: true,
      },
      controller: 'SbAdvancedSearchContactsCtrl',
      controllerAs: '$ctrl'
    });

});
