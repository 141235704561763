import { getAccountPaymentSources } from 'web/redux/selectors/payment-source';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals/selectors';
import { getMap as getInvoicesTotalsMap } from '@sb-billing/redux/invoice-totals';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  findActivePaymentPlanByDebtorId,
} from '@sb-billing/redux/payment-plans/selectors';
import {
  getList as getPaymentPlansList,
} from '@sb-billing/redux/payment-plans';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getAccountId } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').component('sbFinalizedBillSummary', {
  templateUrl: 'ng-components/finalized-bill-summary/finalized-bill-summary.html',
  bindings: { invoiceId: '<' },
  controller: function ($scope, sbLoggerService, sbInvoicingService, sbLocalisationService, sbLinkService, sbOverrideRedirectService, sbExpenseService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbFinalizedBillSummary');

    ctrl.config = {
      tax: hasFacet(facets.tax),
      interest: hasFacet(facets.interest),
      showTrustBalance: hasFacet(facets.showTrustBalance),
      allowOverdraw: hasFacet(facets.allowOverdraw),
    }

    const PAYMENT_PLAN_MODAL_ID = 'payment-plan-modal';

    ctrl.disableLODInvoicePaymentModal = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-14091');

    ctrl.sbData = {};
    ctrl.$onInit = () => {
      // ensure that the modal is only added ot the dom once otherwise we get a duplicate initialised form error
      ctrl.showPaymentPlanModal = false;
      update(true);
    };

    ctrl.paymentPlanModalId = PAYMENT_PLAN_MODAL_ID;
    ctrl.t = sbLocalisationService.t;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.onHidePaymentPlanModalDialog = onHidePaymentPlanModalDialog;
    ctrl.onOpenPaymentPlanModalDialog = onOpenPaymentPlanModalDialog;
    ctrl.onViewHistoryClick= onViewHistoryClick;

    $scope.$on('smokeball-data-update-sbInvoiceTotalsService', loadInvoice);
    $scope.$on('smokeball-data-update-sbInvoicingService', loadInvoice);
    $scope.$on('smokeball-data-update-sbBankAccountBalancesService', loadBalances);
    $scope.$on('smokeball-data-update-sbPaymentPlansService', loadInvoice);
    
    function update() {
      if (ctrl.invoiceId) {
        log.info('loading finalized bill summary panel data for invoice ID : %s', ctrl.invoiceId);
        loadInvoice();
        loadBalances();
        log.info('data loaded : %o', ctrl);
      }
    }

    function onHidePaymentPlanModalDialog() {
      ctrl.showPaymentPlanModal = false;
      setModalDialogHidden({ modalId: PAYMENT_PLAN_MODAL_ID })
    }

    function onOpenPaymentPlanModalDialog() {
      ctrl.showPaymentPlanModal = true;
      setModalDialogVisible({ modalId: PAYMENT_PLAN_MODAL_ID })
    }

    async function loadInvoice() {
      log.info('loading data for invoice ID : %s', ctrl.invoiceId);
      const fullInvoice = sbInvoicingService.getFullInvoice(ctrl.invoiceId);
      ctrl.invoice = fullInvoice.currentVersion;
      ctrl.debtorId = ctrl.invoice && ctrl.invoice.debtors && ctrl.invoice.debtors[0].id;
      ctrl.debtorIds = ctrl.invoice && ctrl.invoice.debtors && ctrl.invoice.debtors.map(debtor => debtor.id);
      ctrl.matterId = ctrl.invoice && ctrl.invoice.matterId;
      ctrl.finalisedTimestamp = fullInvoice.finalizedTimestamp;
      const { unpaidExcInterest } = getTotalsForInvoiceId(getInvoicesTotalsMap(), { invoiceId: fullInvoice.currentVersion.invoiceId });
      
      if(featureActive('BB-14011') && fullInvoice.invoiceStatementId) {
        ctrl.invoiceStatementId = fullInvoice.invoiceStatementId;
        ctrl.invoiceStatement = await fetchInvoiceStatement({ accountId: getAccountId(), invoiceStatementId: ctrl.invoiceStatementId });
      }

      // can only create payment plan for single debtor invoices for now
      if (ctrl.debtorIds.length === 1) {
        const activePaymentPlan = ctrl.debtorId && findActivePaymentPlanByDebtorId(getPaymentPlansList(), {
          debtorId: ctrl.debtorId,
        });
        // can only create payment if
        // 1) no active payment plan
        // 2) there's outstanding unpaid amount (not including interest)
        // 3) invoice is in FINAL status
        ctrl.canSetupPaymentPlanForInvoice = !activePaymentPlan && unpaidExcInterest > 0 && ctrl.invoice.status === 'FINAL';
        ctrl.matterIdsToIncludeForPaymentPlan = [ctrl.matterId];
      } else {
        ctrl.canSetupPaymentPlanForInvoice = false;
      }

      ctrl.retainerRequestEnabled = featureActive('BB-6908') && ctrl.invoice.additionalOptions.showRetainer;
    }

    function loadBalances() {
      const sources = getAccountPaymentSources({matterId: ctrl.matterId, t: sbLocalisationService.t, allowTrustOverdraw: ctrl.config.allowOverdraw});
      ctrl.balances = sources.reduce((acc, paymentSource) => {
        acc[paymentSource.paymentType] = paymentSource;
        return acc;
      }, {});
    }

    function dataChangeFunction (key, doc) {
      if (doc && doc.data) {
        _.set($scope.sbData, key, doc.data);
      } else {
        _.set($scope.sbData, key, doc);
      }
    }

    function onViewHistoryClick() {
      // We need to call service below first to correctly redirect user to client communication tab
      // Please read comment in sbOverrideRedirectService for more info
      sbOverrideRedirectService.overrideNext();
      ctrl.onClickLink({ id: 'client-communications', type: 'matterTransactions', params: [{ matterId: ctrl.matterId }] })
    }

    async function fetchInvoiceStatement({ accountId, invoiceStatementId }) {
      const path = `/billing/invoice-statement/entity/${accountId}/${invoiceStatementId}`;
      try {
        const response = await fetchGetP({ path });
        return response && response.body;
      } catch (error) {
        log.error(`Error occurred fetching invoice statement: ${invoiceStatementId}`, error);
      }
    }
  }
});
