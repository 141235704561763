'use strict';

/**
 * Get Bank Accounts By Type
 *
 * @param {Object} bankAccounts
 * @param {string} accountType Trust or Operating
 * @returns Bank account of the requested account type
 */
function getBankAccountsByType(bankAccounts, accountType) {
  if (!bankAccounts || !accountType) {
    return [];
  }

  const accountTypeUpperCase = accountType.toUpperCase();
  const accountsList = Array.isArray(bankAccounts) ? bankAccounts : Object.values(bankAccounts);

  const selectedAccounts = accountsList.filter(
    (account) => !account.isDeleted && account.accountType === accountTypeUpperCase,
  );

  return selectedAccounts;
}

module.exports = {
  getBankAccountsByType,
};
