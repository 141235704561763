import { integerToDate as from, now, dateToInteger as to, today as nowDateOnly } from '@sb-itops/date';

angular.module('@sb-itops/services').service('sbDateService', function() {
  const that = this;

  that.from = from;
  that.to = to;
  that.now = now;
  that.nowDateOnly = nowDateOnly;
});
