/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { t } from '@sb-itops/localisation-web';
import { featureActive } from '@sb-itops/feature';
import { CollapsibleHeader, ToggleListFilter, PanelExpander } from '@sb-itops/react';
import { MatterTypeAccordian, StaffMultiSelect } from 'web/react-redux';
import { RemindersRecommendedFilterBridge } from '@sb-billing/react';
import { RemindersGeneralFilterBridge } from './reminders-general-filter';
import { RemindersHideDebtorFilterBridge } from './reminders-hide-debtor-filter';

const RemindersFilterPanel = memo(
  ({
    toggleHideFilters,
    reset,
    onResetApplied,
    invoicedInDays,
    remindedInDays,
    hideMatterDebtorWithNoOverdueInvoices,
    onFilterChanged,
    overdueAmount,
    setFilter,
    onChangeOverdueAmount,
    sentRemindersOnly,
    hideFilters,
    onSelectMatterStatusFilter,
    matterStatusFilterOptions,
    matterFilterStatusSelected,
    onSelectSendPreferencesFilter,
    sendPreferencesFilterOptions,
    sendPreferencesSelected,
    onSelectMatterTypeFilter,
    matterTypeFilterSelected,
    overdueByFilterSelected,
    overdueByFilterOptions,
    onOverdueByFilterChange,
    attorneys,
    resetFilters,
    expanded,
    toggleExpanded,
  }) => (
    <div className="account-reminders-side-panel">
      <PanelExpander
        className="nav panel-filter"
        onResetFilters={resetFilters}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      >
        {/* Once BB-11448 turns on the below filter will retire, we separate it into two filter sections - Hide Debtor and General */}
        {!featureActive('BB-11448') && (
          <>
            <CollapsibleHeader
              text="Filters"
              name="remindersRecommended"
              onClick={toggleHideFilters}
              collapsed={hideFilters.remindersRecommended}
            />
            {!hideFilters.remindersRecommended && (
              <div>
                <RemindersRecommendedFilterBridge
                  visible
                  reset={reset}
                  onResetApplied={onResetApplied}
                  invoicedInDays={invoicedInDays}
                  remindedInDays={remindedInDays}
                  hideMatterDebtorWithNoOverdueInvoices={hideMatterDebtorWithNoOverdueInvoices}
                  onFilterChanged={onFilterChanged}
                  overdueAmount={overdueAmount}
                  setFilter={setFilter}
                  onChangeOverdueAmount={onChangeOverdueAmount}
                  sentRemindersOnly={sentRemindersOnly}
                />
              </div>
            )}
          </>
        )}

        {featureActive('BB-11448') && (
          <>
            <CollapsibleHeader
              text="Hide Debtors"
              name="hideDebtor"
              onClick={toggleHideFilters}
              collapsed={hideFilters.hideDebtor}
            />
            {!hideFilters.hideDebtor && (
              <div>
                <RemindersHideDebtorFilterBridge
                  reset={reset}
                  onResetApplied={onResetApplied}
                  invoicedInDays={invoicedInDays}
                  remindedInDays={remindedInDays}
                  onFilterChanged={onFilterChanged}
                  setFilter={setFilter}
                />
              </div>
            )}
          </>
        )}

        <CollapsibleHeader
          text="Overdue By"
          name="overdueBy"
          onClick={toggleHideFilters}
          collapsed={hideFilters.overdueBy}
        />

        {!hideFilters.overdueBy && (
          <div>
            <ToggleListFilter
              className="overdue-filters"
              selected={overdueByFilterSelected}
              options={overdueByFilterOptions}
              onSelect={onOverdueByFilterChange}
              showAllLabel="All"
            />
          </div>
        )}

        {featureActive('BB-11448') && (
          <>
            <CollapsibleHeader
              text="General"
              name="general"
              onClick={toggleHideFilters}
              collapsed={hideFilters.general}
            />
            {!hideFilters.general && (
              <div>
                <RemindersGeneralFilterBridge
                  reset={reset}
                  onResetApplied={onResetApplied}
                  onFilterChanged={onFilterChanged}
                  overdueAmount={overdueAmount}
                  setFilter={setFilter}
                  onChangeOverdueAmount={onChangeOverdueAmount}
                  sentRemindersOnly={sentRemindersOnly}
                />
              </div>
            )}
          </>
        )}

        {/* <!-- Send preference filter--> */}
        {featureActive('BB-11448') && (
          <div>
            <CollapsibleHeader
              text="Send Preference"
              name="sendPreferences"
              onClick={toggleHideFilters}
              collapsed={hideFilters.sendPreferences}
            />
            {!hideFilters?.sendPreferences && (
              <ToggleListFilter
                showAllLabel="All"
                selected={sendPreferencesSelected}
                options={sendPreferencesFilterOptions}
                onSelect={onSelectSendPreferencesFilter}
              />
            )}
          </div>
        )}

        <CollapsibleHeader
          text="Matter Type"
          onClick={toggleHideFilters}
          name="matterType"
          collapsed={hideFilters?.matterType}
        />

        {!hideFilters?.matterType && (
          <MatterTypeAccordian selectedList={matterTypeFilterSelected} onSelect={onSelectMatterTypeFilter} />
        )}

        <CollapsibleHeader
          text="Matter Status"
          name="matterStatus"
          onClick={toggleHideFilters}
          collapsed={hideFilters.matterStatus}
        />

        {!hideFilters.matterStatus && (
          <div>
            <ToggleListFilter
              className="matters-filters"
              selected={matterFilterStatusSelected}
              options={matterStatusFilterOptions}
              onSelect={onSelectMatterStatusFilter}
            />
          </div>
        )}

        <CollapsibleHeader
          text={t('capitalizeAll', { val: 'personResponsible' })}
          name="personResponsible"
          onClick={toggleHideFilters}
          collapsed={hideFilters.personResponsible}
        />

        {!hideFilters.personResponsible && (
          <div>
            <StaffMultiSelect
              showUsedStaffOnly
              showNoneOption
              onStaffChange={(idList) => setFilter('attorneys', idList || [])}
              selectedStaffIds={attorneys || []}
            />
          </div>
        )}
      </PanelExpander>
    </div>
  ),
);

RemindersFilterPanel.displayName = 'RemindersFilterPanel';

RemindersFilterPanel.propTypes = {
  reset: PropTypes.bool,
  onResetApplied: PropTypes.func,
  onFilterChanged: PropTypes.func.isRequired,
  remindedInDays: PropTypes.number,
  invoicedInDays: PropTypes.number,
  hideMatterDebtorWithNoOverdueInvoices: PropTypes.bool,
  sentRemindersOnly: PropTypes.bool,
  setFilter: PropTypes.func.isRequired,
  onChangeOverdueAmount: PropTypes.func.isRequired,
  overdueAmount: PropTypes.number,
  toggleHideFilters: PropTypes.func.isRequired,
  hideFilters: PropTypes.object.isRequired,
  onSelectMatterStatusFilter: PropTypes.func.isRequired,
  matterStatusFilterOptions: PropTypes.array.isRequired,
  matterFilterStatusSelected: PropTypes.array,
  onSelectSendPreferencesFilter: PropTypes.func.isRequired,
  sendPreferencesFilterOptions: PropTypes.array.isRequired,
  sendPreferencesSelected: PropTypes.array,
  onSelectMatterTypeFilter: PropTypes.func.isRequired,
  matterTypeFilterSelected: PropTypes.array,
  overdueByFilterSelected: PropTypes.string,
  overdueByFilterOptions: PropTypes.array.isRequired,
  onOverdueByFilterChange: PropTypes.func.isRequired,
  attorneys: PropTypes.array.isRequired,
  resetFilters: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

RemindersFilterPanel.defaultProps = {
  reset: false,
  sentRemindersOnly: undefined,
  onResetApplied: undefined,
  matterFilterStatusSelected: undefined,
  sendPreferencesSelected: undefined,
  matterTypeFilterSelected: undefined,
  overdueByFilterSelected: undefined,
  overdueAmount: undefined,
  remindedInDays: undefined,
  invoicedInDays: undefined,
  hideMatterDebtorWithNoOverdueInvoices: true,
};

export default RemindersFilterPanel;
