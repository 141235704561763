import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { billingEventType } from '@sb-billing/business-logic/shared/entities';
import { getEarliestByRelatedIdAndTypeGroupedByDebtor } from '@sb-billing/redux/billing-events';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { InvoiceViewedOnlineStatusIndicator } from './InvoiceViewedOnlineStatusIndicator';

const mapStateToProps = (state, { invoiceId }) => {
  const { INVOICE_VIEWED_ONLINE } = billingEventType;
  const earliestViewedPerContact = getEarliestByRelatedIdAndTypeGroupedByDebtor(invoiceId, INVOICE_VIEWED_ONLINE) || {};
  const viewedPerContact = Object.values(earliestViewedPerContact).map((billingEvent) => ({
    ...billingEvent,
    contactDisplayName: getContactDisplay(billingEvent.contactId),
  }));

  const viewedPerContactMessage = viewedPerContact.length
    ? `Viewed online by: ${viewedPerContact.map((billingEvent) => billingEvent.contactDisplayName).join(', ')}`
    : '';

  return {
    viewedPerContactMessage,
  };
};

export const InvoiceViewedOnlineStatusIndicatorContainer = withReduxStore(
  connect(mapStateToProps)(InvoiceViewedOnlineStatusIndicator),
);

InvoiceViewedOnlineStatusIndicatorContainer.displayName = 'InvoiceViewedOnlineStatusIndicatorContainer';

InvoiceViewedOnlineStatusIndicatorContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

InvoiceViewedOnlineStatusIndicatorContainer.defaultProps = {};
