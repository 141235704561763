import React, { useEffect, useState } from 'react';
import { SbClientSdk } from 'web/react-redux/components/sb-client-sdk';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getDataMigrationUrl } from 'web/services/data-migration-urls';
import { sendMetric } from 'web/services/metrics';

const Container = (props) => {
  const [url, setUrl] = useState<string>();

  const { onClickLink } = props;

  useEffect(() => {
    sendMetric('OpenDataMigration');

    getDataMigrationUrl().then((v) => {
      setUrl(v);
    });
  }, []);

  return url ? <SbClientSdk sdkAppUrl={url} onClickLink={onClickLink} onClose={props.onClose} /> : null;
};

export const DataMigrationIframeContainer = withReduxProvider(Container);
