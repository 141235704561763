import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

// The invoiceList list resolver is specifically used here because the "listItemProperties" field is a web query only resolver

const query = gql`
  query FinaliseInvoiceConfirmationModalData($ids: [ID], $invoiceListFilter: InvoiceListFilter) {
    invoiceList(ids: $ids, filter: $invoiceListFilter) {
      results {
        id
        listItemProperties {
          hasUnpaidAnticipatedDisbursements
        }
        totals {
          id
          total
        }
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FinaliseInvoiceConfirmationModalData = {
  query,
  notificationIds,
};
