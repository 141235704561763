'use strict';

angular.module('sb.billing.webapp').config(function($stateProvider) {

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-contact/payment-plan';

  $stateProvider.state('home.billing.view-contact.payment-plan', {
    url: '/payment-plan',
    templateUrl: BASE_DIRECTORY + '/view-payment-plan-route.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'contacts', 'view-contact'],
      tab: { type: 'contact' },
    },
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
  });
});
