angular.module('sb.billing.webapp').controller('sbRestErrorModalController', function ($scope, $uibModalInstance, sbRestErrorModalConfig, sbRestErrorService) {
  'use strict';

  $scope.errorId = sbRestErrorModalConfig.errorId;
  $scope.errorDetails = sbRestErrorService.getErrorDetails($scope.errorId);

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };

  $scope.onClose = function (dismiss) {
    if (dismiss) {
      $uibModalInstance.dismiss();
    }
  };
});
