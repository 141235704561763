'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const billingFrequencyQuarterlySubType = Object.freeze({
  QUARTERLY_JAN: 31,
  QUARTERLY_FEB: 32,
  QUARTERLY_MAR: 33,
});

const billingFrequencyQuarterlySubTypeLabels = Object.freeze({
  QUARTERLY_JAN: 'Jan, Apr, Jul, Oct',
  QUARTERLY_FEB: 'Feb, May, Aug, Nov',
  QUARTERLY_MAR: 'Mar, Jun, Sep, Dec',
});

const billingFrequencyQuarterlySubTypeOptions = getTypeAsOptions({
  typeLabels: billingFrequencyQuarterlySubTypeLabels,
  typeValues: billingFrequencyQuarterlySubType,
});

module.exports = {
  billingFrequencyQuarterlySubType,
  billingFrequencyQuarterlySubTypeLabels,
  billingFrequencyQuarterlySubTypeOptions,
};
