import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedLazyQuery } from 'web/hooks';
import { InvoiceViewedOnlineData } from 'web/graphql/queries';

import { InvoiceViewedOnlineStatusIndicator } from './InvoiceViewedOnlineStatusIndicator';

function generateViewedPerContactMessage({ contacts }) {
  if (!contacts?.length) {
    return '';
  }

  const contactNamesJoined = contacts.map((contact) => contact.displayNameFirstLast).join(', ');
  const message = `Viewed online by: ${contactNamesJoined}`;
  return message;
}

const hooks = () => ({
  useInvoiceViewedOnlineQuery: ({ invoiceId }) => {
    // We only fetch the contacts that viewed the invoice to construct the "viewed online message" when the user clicks on the icon. Otherwise, we simply display just the "viewed online icon".
    const [getInvoiceViewedOnlineQueryData, invoiceViewedOnlineQueryData] = useSubscribedLazyQuery(
      InvoiceViewedOnlineData,
      {
        context: {
          skipRequestBatching: true,
        },
        variables: {
          id: invoiceId,
        },
      },
    );

    return {
      viewedPerContactMessage: generateViewedPerContactMessage({
        contacts: invoiceViewedOnlineQueryData?.data?.invoiceViewedOnline?.contacts,
      }),
      onClickInvoiceViewedOnlineIcon: getInvoiceViewedOnlineQueryData,
    };
  },
});

export const InvoiceViewedOnlineStatusIndicatorContainer = composeHooks(hooks)(InvoiceViewedOnlineStatusIndicator);

InvoiceViewedOnlineStatusIndicatorContainer.displayName = 'InvoiceViewedOnlineStatusIndicatorContainer';

InvoiceViewedOnlineStatusIndicatorContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  hasBeenViewedOnline: PropTypes.bool.isRequired,
};

InvoiceViewedOnlineStatusIndicatorContainer.defaultProps = {};
