angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
  .state('settings.trust', {
    template: `<sb-trust-settings></sb-trust-settings>`,
    url: '/trust',
    data: {
      navHighlights: ['trust-settings']
    }
  });

});
