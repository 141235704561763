/* eslint-disable import/no-cycle */
// import { createSelector } from 'reselect';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { store } from '@sb-itops/redux';
import {
  isPaymentProviderEnabledForBankAccount as isPaymentProviderEnabledForBankAccountFn,
  getPaymentFormConfiguration as getPaymentFormConfigurationFn,
  isFirmCardSavingEnabledForBankAccount as isFirmCardSavingEnabledForBankAccountFn,
  isClientCardSavingEnabledForBankAccount as isClientCardSavingEnabledForBankAccountFn,
} from '@sb-billing/business-logic/payment-provider/services';
import { getById as getBankAccountById, getOperatingAccount } from '../bank-account';
import { getMap } from './index';

export const getById = (id) => {
  const entity = getMap()[id];

  if (!entity || !entity.providers) {
    return entity;
  }

  // Convert all provider name keys to uppercase to match constants
  // @sb-billing/business-logic/payment-provider/entities/constants
  const providers = Object.entries(entity.providers).reduce((acc, [key, val]) => {
    acc[key.toUpperCase()] = val;
    return acc;
  }, {});

  const updatedEntity = {
    ...entity,
    providers,
  };

  return updatedEntity;
};

export const getAllSettings = () => {
  const accountId = authSelectors.getAccountId(store.getState());
  const settings = getById(accountId);

  return (
    settings || {
      accountId,
      providers: {},
    }
  );
};

/**
 * getProviderSettings
 * Returns settings for a specific payment provider
 *
 * @param {string} providerType Payment Provider type
 * @returns {object}
 */
export const getProviderSettings = (providerType) => {
  const formattedPaymentProviderSettings = getAllSettings();
  if (!formattedPaymentProviderSettings) {
    return undefined;
  }

  return formattedPaymentProviderSettings.providers[providerType];
};

/**
 * getActiveProvider
 * Returns the active payment provider for the firm
 *
 * @returns {string} The active payment provider type
 */
export const getActiveProvider = () => {
  const formattedPaymentProviderSettings = getAllSettings();
  if (!formattedPaymentProviderSettings) {
    return undefined;
  }

  return formattedPaymentProviderSettings.activeProvider;
};

/**
 * isPaymentProviderEnabledForBankAccount
 * Returns true if the passed payment provider type has payment processing enabled for the passed bank account id.
 * False otherwise.
 *
 * @param {object} params
 * @param {string} params.providerType The provider to check for payment processing availability.
 * @param {string} params.bankAccountId The bank account to check for payment processing availability.
 * @param {object} [params.providerSpecificFields] Provider specific fields
 * @returns {boolean}
 */
export const isPaymentProviderEnabledForBankAccount = ({ providerType, bankAccountId, providerSpecificFields }) => {
  if (!providerType) {
    return false;
  }

  const formattedPaymentProviderSettings = getAllSettings();
  if (!formattedPaymentProviderSettings || !formattedPaymentProviderSettings.activeProvider) {
    return false;
  }

  const activeProviderType = formattedPaymentProviderSettings.activeProvider;
  const formattedProviderSpecificSettings = formattedPaymentProviderSettings.providers[activeProviderType];

  const bankAccountType = getBankAccountById(bankAccountId).accountType;

  return isPaymentProviderEnabledForBankAccountFn({
    formattedProviderSpecificSettings,
    providerType,
    bankAccountId,
    bankAccountType,
    providerSpecificFields,
  });
};

/**
 * isFirmCardSavingEnabledForBankAccount
 * Returns true if the passed payment provider type has card saving enabled for firm for the passed bank account id.
 * False otherwise.
 *
 * @param {object} params
 * @param {string} params.providerType The provider to check for payment processing availability.
 * @param {string} params.bankAccountId The bank account to check for payment processing availability.
 * @returns {boolean}
 */
export const isFirmCardSavingEnabledForBankAccount = ({ providerType, bankAccountId }) => {
  if (!providerType) {
    return false;
  }

  const formattedPaymentProviderSettings = getAllSettings();
  if (!formattedPaymentProviderSettings || !formattedPaymentProviderSettings.activeProvider) {
    return false;
  }

  const activeProviderType = formattedPaymentProviderSettings.activeProvider;
  const formattedProviderSpecificSettings = formattedPaymentProviderSettings.providers[activeProviderType];

  const bankAccountType = getBankAccountById(bankAccountId).accountType;

  return isFirmCardSavingEnabledForBankAccountFn({
    formattedProviderSpecificSettings,
    providerType,
    bankAccountId,
    bankAccountType,
  });
};

/**
 * isClientCardSavingEnabledForBankAccount
 * Returns true if the passed payment provider type has card saving enabled for client for the passed bank account id.
 * False otherwise.
 *
 * @param {object} params
 * @param {string} params.providerType The provider to check for payment processing availability.
 * @param {string} params.bankAccountId The bank account to check for payment processing availability.
 * @returns {boolean}
 */
export const isClientCardSavingEnabledForBankAccount = ({ providerType, bankAccountId }) => {
  if (!providerType) {
    return false;
  }

  const formattedPaymentProviderSettings = getAllSettings();
  if (!formattedPaymentProviderSettings || !formattedPaymentProviderSettings.activeProvider) {
    return false;
  }

  const activeProviderType = formattedPaymentProviderSettings.activeProvider;
  const formattedProviderSpecificSettings = formattedPaymentProviderSettings.providers[activeProviderType];

  const bankAccountType = getBankAccountById(bankAccountId).accountType;

  return isClientCardSavingEnabledForBankAccountFn({
    formattedProviderSpecificSettings,
    providerType,
    bankAccountId,
    bankAccountType,
  });
};

/**
 * getPaymentFormConfiguration
 *
 * The different payment providers require different information to successfully display a payment form, i.e. the UI to make a charge.
 * This function builds and returns the payment form configuration object for the passed provider type.
 * It's assumed that such information is derived from the formatted provider specific settings per Smokeball Billing bank account.
 *
 * @param {Object} params
 * @param {string} params.providerType The provider type for which to retrieve payment form configuration
 * @param {string} params.bankAccountId The bank account id for which to retrieve payment form configuration
 * @param {object} [params.providerSpecificFields] Any provider specific fields which provider specific implementation may need
 *
 * @returns {Object} An object containing all of the configuration necessary to display a charge UI for the provider type specified.
 */
export const getPaymentFormConfiguration = ({ providerType, bankAccountId, providerSpecificFields }) => {
  if (!providerType) {
    throw new Error('providerType must be provided to retrieve payment form configuration');
  }

  if (!bankAccountId) {
    throw new Error('bankAccountId must be provided to retrieve payment form configuration');
  }

  const formattedPaymentProviderSettings = getAllSettings();
  if (!formattedPaymentProviderSettings || !formattedPaymentProviderSettings.providers[providerType]) {
    return {};
  }

  const bankAccountType = getBankAccountById(bankAccountId).accountType;

  const formattedProviderSpecificSettings = formattedPaymentProviderSettings.providers[providerType];
  return getPaymentFormConfigurationFn({
    providerType,
    bankAccountId,
    formattedProviderSpecificSettings,
    bankAccountType,
    providerSpecificFields,
  });
};

/**
 * canIncludePaymentLinks
 *
 * This function will perform the following checks to see if the firm can include payment links:
 *  1. Payment provider is connected
 *  2. Operating account is connected and ready for use
 *  3. Invoice payment options config:
 *    - Include payments on invoices is toggled on
 *
 * If any of the above criteria is not met, the function will return false.
 *
 * @returns {boolean}
 */
export function canIncludePaymentLinks() {
  const activePaymentProvider = getActiveProvider();
  const paymentProviderSettings = getProviderSettings(activePaymentProvider);
  const includePaymentLinkOnInvoices = !!paymentProviderSettings?.showInvoiceLink;

  const result =
    !!activePaymentProvider &&
    !!isPaymentProviderEnabledForBankAccount({
      providerType: activePaymentProvider,
      bankAccountId: getOperatingAccount().id,
    }) &&
    includePaymentLinkOnInvoices;
  return result;
}
