import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import AddLead from './AddLead';
import Styles from './AddLeadModal.module.scss';

export const AddLeadModal = React.memo(
  ({ onSubmit, onClose, disableSubmit, formSubmitting, scope, formProps, matterTypes }) => (
    <Modal
      className={Styles.addLeadModal}
      title="Add a New Lead"
      body={<AddLead matterTypes={matterTypes} {...formProps} />}
      footer={
        <div className={Styles.buttonContainer}>
          <Button
            className={Styles.saveButton}
            onClick={onSubmit}
            disabled={disableSubmit}
            form={scope}
            locked={formSubmitting}
          >
            Save
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  ),
);

AddLeadModal.displayName = 'AddLeadModal';

AddLeadModal.propTypes = {
  matterTypes: PropTypes.array,
  scope: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  formInitialised: PropTypes.bool,
  formProps: PropTypes.object.isRequired,
};

AddLeadModal.defaultProps = {
  matterTypes: [],
  disableSubmit: false,
  formSubmitting: false,
  formInitialised: false,
};
