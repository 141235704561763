import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import moment from 'moment';
import classnames from 'classnames';
import Styles from './TimePicker.module.scss';

const options = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
].map((item) => ({ value: item, label: item }));

const selectPortal = document.getElementById('select-portal');

/**
 * @callback onSelect
 * @param {object} native Date
 */

/**
 * @param {object} props
 * @param {onSelect} props.onSelect
 * @param {string} [props.value]
 * @param {string} [props.className]
 * @param {string} [props.showDuration] one of the date option values
 * @param {boolean} [props.hasError]
 * @param {boolean} [props.disabled]
 */
const TimePicker = ({ onSelect, value, hasError, disabled, className, showDuration }) => {
  const [text, setText] = useState(value || options[0].value);
  const [isFocused, setFocused] = useState(false);
  const selectRef = useRef(null);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      parseAndSelect();
    }
  };

  const onSelectOption = (option) => {
    if (option) {
      setText(option.value);
      onSelect(option.value);
    }
  };

  const parseAndSelect = () => {
    setFocused(false);
    const newDate = moment(text, 'hh:mm A').format('hh:mm A');
    setText(newDate);
    onSelect(newDate);
    setTimeout(() => {
      // Close after current click has been processed
      // @ts-ignore
      if (selectRef?.current?.onMenuClose) {
        // @ts-ignore
        selectRef.current.onMenuClose();
      }
    }, 150);
  };

  const displayValue = showDuration ? `${value} (${showDuration})` : value;

  const convertedValue = moment(text, 'hh:mm A');
  const remainder = 30 - (convertedValue.minute() % 30);
  const roundedValue = convertedValue.add(remainder === 30 ? 0 : remainder, 'minutes').format('hh:mm A');

  const selectedOption = options.find((opt) => opt.value === roundedValue);

  let menuPortalTarget;

  if (selectPortal) {
    menuPortalTarget = selectPortal;
  }

  return (
    <div className={classnames(className, Styles.inputRow)}>
      <input
        type="text"
        className={classnames(Styles.timeInput, hasError && Styles.inputError)}
        value={isFocused ? text : displayValue}
        onChange={(e) => setText(e.target.value)}
        onKeyPress={onEnter}
        onFocus={() => {
          setText(value || '');
          setFocused(true);
        }}
        onBlur={parseAndSelect}
        disabled={disabled}
        onClick={() => {
          // This modifies the internals of react select to re-enable scroll to selection behaviour as this does not work without the native input
          // Using props to control the menu state also breaks this workaround
          // @ts-ignore
          if (selectRef?.current?.openMenu) {
            // @ts-ignore
            selectRef.current.openMenu('first');
            setTimeout(() => {
              // @ts-ignore
              selectRef.current.openMenu('first');
            }, 50);
          }
        }}
      />
      <ReactSelect
        menuPortalTarget={menuPortalTarget}
        isDisabled={disabled}
        ref={selectRef}
        options={options}
        value={selectedOption}
        onChange={onSelectOption}
        styles={{
          control: (base) => ({
            ...base,
            height: 0,
            minHeight: 0,
            border: 0,
            opacity: 0,
          }),
        }}
      />
    </div>
  );
};

TimePicker.displayName = 'TimePicker';

TimePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  showDuration: PropTypes.string,
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  value: undefined,
  hasError: false,
  disabled: false,
  showDuration: undefined,
  className: undefined,
};

export default TimePicker;
