'use strict';

const createImage = ({ src, style, width = '', height = '', alt = '' }) => {
  const imageHtml = `<img style="${style}" width="${width}" height="${height}" alt="${alt}" src="${src}" />`;
  return imageHtml;
};

module.exports = {
  createImage,
};
