import * as Yup from 'yup';

const elevenNumericPattern = /^\d{11}$/;
export const nineNumericPattern = /^\d{9}$/;
const sixNumericPattern = /^\d{6}$/;
const fiveNumericPattern = /^\d{5}$/;
const fourNumericPattern = /^\d{4}$/;
const gbPostcodePattern = /^[a-zA-Z\d ]{1,8}$/;
const nationalInsuranceNumberPattern = /^[a-zA-Z]{2}\d{6}[a-zA-Z]{1}$/;

// Specific Schemas - deal with the actual business logic for particular fields in particular regions
export const zipCodeSchema = Yup.string().test('len', 'Must be exactly 5 numeric characters', (val) => {
  if (val) {
    return fiveNumericPattern.test(val);
  }
  return true;
});

export const postCodeSchema = Yup.string().test('len', 'Must be exactly 4 numeric characters', (val) => {
  if (val) {
    return fourNumericPattern.test(val);
  }
  return true;
});

export const gbPostCodeSchema = Yup.string().test('len', 'Invalid format', (val) => {
  if (val) {
    return gbPostcodePattern.test(val);
  }
  return true;
});

export const routingNumberSchema = Yup.string().test('len', 'Must be exactly 9 numeric characters', (val) => {
  if (val) {
    return nineNumericPattern.test(val);
  }
  return true;
});

export const bsbSchema = Yup.string().test('len', 'Must be exactly 6 numeric characters', (val) => {
  if (val) {
    return sixNumericPattern.test(val);
  }
  return true;
});

export const sortCodeSchema = Yup.string().test('len', 'Must be exactly 6 numeric characters', (val) => {
  if (val) {
    return sixNumericPattern.test(val);
  }
  return true;
});

export const driversLicenseOrTfnSchema = Yup.string().test(
  'len',
  'Must be exactly eleven numeric characters',
  (val) => {
    if (val) {
      return elevenNumericPattern.test(val);
    }
    return false;
  },
);

export const nationalInsuranceNumberSchema = Yup.string().test(
  'len',
  'Must be in the valid format, e.g. QQ123456B',
  (val) => {
    if (val) {
      return nationalInsuranceNumberPattern.test(val);
    }
    return false;
  },
);

export const last4SocialSecuritySchema = Yup.string().test('len', 'Must be exactly 4 numeric characters', (val) => {
  if (val) {
    return fourNumericPattern.test(val);
  }
  return true;
});

export const socialSecuritySchema = Yup.string().test('len', 'Must be exactly 9 numeric characters', (val) => {
  if (val) {
    return nineNumericPattern.test(val);
  }
  return true;
});

export const addressLineSchema = Yup.string().test('no PO Box', 'PO Boxes not allowed', (val) => {
  if (val) {
    return !val.trim().toLowerCase().startsWith('po box');
  }
  return true;
});

export const websiteSchema = Yup.string().test(
  'websites must start with https://',
  'Must be a valid URL and start with http:// or https://',
  (val) => {
    if (val) {
      return val.trim().toLowerCase().startsWith('https://') || val.trim().toLowerCase().startsWith('http://');
    }
    return true;
  },
);
