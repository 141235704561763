import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxLabelInline, DropDownList, forms2PropTypes, useTranslation } from '@sb-itops/react';
import { ChequePrintMethodsDropDown } from '@sb-billing/react';
import { BankAccountDropDown } from 'web/react-redux/components/bank-account-drop-down';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import Styles from './ChequeSettingsSection.module.scss';

const ChequeSettingsSection = React.memo(
  ({
    allowBankAccountChange,
    chequeStyleOptions,
    bankAccounts,
    activateLabel,
    bankAccountType,
    showIncludeVendorAddress,
    // form
    formDisabled,
    formInitialised,
    onFieldValueUpdated,
    onBankAccountChange,
    // form fields
    printMethod,
    chequeStyle,
    printingActive,
    includeVendorAddress,
    bankAccountId,
  }) => {
    const { t } = useTranslation();
    if (!formInitialised) return null;
    return (
      <div className={Styles.chequeSettingsSection}>
        {allowBankAccountChange && (
          <div className={Styles.bankAccountSelect}>
            <label>Select your account:</label>
            <BankAccountDropDown
              disabled={!bankAccounts.length || formDisabled}
              placeholder={`No open ${bankAccountType.toLowerCase()} accounts`}
              onValueChange={(bankAccount) => {
                onBankAccountChange(bankAccount?.value);
              }}
              options={bankAccounts}
              selectedOptionValue={bankAccountId?.value}
            />
          </div>
        )}
        <div className={classnames(Styles.printingActive, 'form-group')}>
          <CheckboxLabelInline
            disabled={(allowBankAccountChange && !bankAccounts.length) || formDisabled}
            checked={printingActive.value}
            onChange={(newVal) => onFieldValueUpdated({ printingActive: newVal })}
          >
            {activateLabel}
          </CheckboxLabelInline>
        </div>
        {featureActive('BB-7599') && showIncludeVendorAddress && includeVendorAddress && (
          <div className={classnames(Styles.printingActive, 'form-group')}>
            <CheckboxLabelInline
              disabled={!printingActive.value || formDisabled}
              checked={includeVendorAddress.value}
              onChange={(newVal) => onFieldValueUpdated({ includeVendorAddress: newVal })}
            >
              Include Vendor Address on Check
            </CheckboxLabelInline>
          </div>
        )}
        <div className={classnames(Styles.chequeStyle, 'form-group')}>
          <label>{`${t('capitalize', { val: 'cheque' })}`} Style</label>
          <DropDownList
            options={chequeStyleOptions}
            selectedOption={chequeStyleOptions.find((option) => option.value === chequeStyle.value)}
            onChange={(newStyle) => onFieldValueUpdated({ chequeStyle: newStyle?.value })}
            stateless
            disabled={!printingActive.value || formDisabled}
          />
        </div>
        <div className={classnames(Styles.printingMethod, 'form-group')}>
          <label>Printing Method</label>
          <ChequePrintMethodsDropDown
            selectedPrintMethod={printMethod.value}
            onChange={(newMethod) => onFieldValueUpdated({ printMethod: newMethod?.value })}
            disabled={!printingActive.value || formDisabled}
          />
        </div>
      </div>
    );
  },
);

ChequeSettingsSection.displayName = 'ChequeSettingsSection';
const { Forms2Field } = forms2PropTypes;
ChequeSettingsSection.propTypes = {
  allowBankAccountChange: PropTypes.bool.isRequired,
  chequeStyleOptions: PropTypes.array.isRequired,
  bankAccounts: PropTypes.array.isRequired,
  activateLabel: PropTypes.string.isRequired,
  bankAccountType: PropTypes.string.isRequired,
  showIncludeVendorAddress: PropTypes.bool.isRequired,
  // form
  formDisabled: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  onBankAccountChange: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  // form fields
  printMethod: Forms2Field,
  chequeStyle: Forms2Field,
  printingActive: Forms2Field,
  includeVendorAddress: Forms2Field,
  bankAccountId: Forms2Field,
};
ChequeSettingsSection.defaultProps = {
  printMethod: undefined,
  chequeStyle: undefined,
  printingActive: undefined,
  includeVendorAddress: undefined,
  bankAccountId: undefined,
};

export default ChequeSettingsSection;
