import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { filterOptions } from '@sb-billing/business-logic/invoice-filters';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxStore } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';

import InvoicesFilterPanel from './InvoicesFilterPanel';

const hooks = () => ({
  useFilterChangeHandlers: ({ invoiceListReduxState, staffMembers }) => {
    const expanded = useSelector(invoiceListReduxState.selectors.getShowFilters);
    const hideFilters = useSelector(invoiceListReduxState.selectors.getHideFilters);
    const filters = useSelector(invoiceListReduxState.selectors.getFilters);
    const filterActions = bindActionCreators(invoiceListReduxState.actions, useDispatch());

    const attorneysResponsible = filters?.attorneysResponsible || [];

    const attorneysResponsibleMap = attorneysResponsible.reduce((acc, staffId) => {
      acc[staffId] = true;
      return acc;
    }, {});

    const onToggleStaff = (staffId) => {
      const selectedStaff = { ...attorneysResponsibleMap };
      if (selectedStaff[staffId]) {
        delete selectedStaff[staffId];
      } else {
        selectedStaff[staffId] = true;
      }

      filterActions.onFilterAttorneyResponsible(Object.keys(selectedStaff));
    };

    return {
      ...filterActions,
      expanded,
      filters,
      hideFilters,
      filterOptions,
      attorneysResponsibleMap,
      // Certain filters are optional for certain invoice lists
      //  * E.g. The Firm invoice list generally shows all filters, but the Contact and Matter invoice lists hide certain filters
      //  * We can infer what filters are required by checking the invoiceListReduxState
      showAttorneyResponsibleFilter: staffMembers && Object.hasOwn(filters, 'attorneysResponsible'),
      showBillingFrequencyFilter: Object.hasOwn(filters, 'billingFrequencySubTypes'),
      showBillingTypesFilter: Object.hasOwn(filters, 'billingTypes'),
      showMatterStatusesFilter: Object.hasOwn(filters, 'matterStatuses'),
      showMatterTypesFilter: Object.hasOwn(filters, 'matterTypes'),
      showOperatingBalanceFilter:
        hasFacet(facets.operatingAccount) && Object.hasOwn(filters, 'minimumOperatingBalance'),
      showSendPreferencesFilter: Object.hasOwn(filters, 'sendPreferences'),
      showTrustBalanceFilter: Object.hasOwn(filters, 'minimumTrustBalance'),
      // Callbacks
      onToggleStaff,
    };
  },
});

const InvoicesFilterPanelContainer = withReduxStore(composeHooks(hooks)(InvoicesFilterPanel));

InvoicesFilterPanelContainer.propTypes = {
  contactMatterBalancesTitle: PropTypes.string,
  invoiceListReduxState: PropTypes.object.isRequired, // Each invoice list (e.g. firm, matter, contact) has its own redux state
  personResponsible: PropTypes.string,
  showUtbmsFilter: PropTypes.bool,
  staffMembers: PropTypes.arrayOf(PropTypes.object),
};

InvoicesFilterPanelContainer.defaultProps = {
  contactMatterBalancesTitle: '',
  personResponsible: '',
  showUtbmsFilter: false,
  staffMembers: undefined,
};

export default InvoicesFilterPanelContainer;
