import { PAYMENT_TYPE } from 'web/redux/selectors/payment-source';
import { matterHasProtectedFundsForBankAccountId } from '@sb-billing/redux/balance-protection';
import { isMatterContactBalanceType, isMatterBalanceType } from '@sb-billing/redux/bank-account-settings';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { PrintNotApplicable, PrintManually } from '@sb-billing/business-logic/cheques';
import { findLastChequeNumber } from 'web/services/cheques';
import { featureActive } from '@sb-itops/feature';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { capitalize } from '@sb-itops/nodash';
import { getNumberingSettings as getTransactionNumberingSettings } from '@sb-billing/redux/bank-account';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import {
  chequeExistsForBankAccount,
  getByBankAccountId as getTrustChequesByBankAccountId,
} from '@sb-billing/redux/trust-cheques';
import { hasFacet, facets } from '@sb-itops/region-facets';

const paymentTypes = {
  MATTER: 'MATTER',
  CLIENT: 'CLIENT',
};

export const getValidateFn =
  ({ t, minAmountAllowed }) =>
  (formFields) => {
    const isTakingPaymentNow = formFields.takePaymentNow === true;
    const isTrustPayment = formFields.source?.paymentType === PAYMENT_TYPE.trust;
    const totalPayments = (formFields.payments || []).reduce(
      (runningTotal, invoicePayment) => runningTotal + invoicePayment.amount,
      0,
    );
    const formErrors = {};

    // amount
    const validAmount = Number.isFinite(formFields.amount) && formFields.amount > 0;
    if (!validAmount) {
      formErrors.amount = true;
    } else if (isTakingPaymentNow && formFields.amount < minAmountAllowed) {
      formErrors.amount = true;
      formErrors.amount = `Minimal amount allowed by your payment processor is ${t('cents', {
        val: minAmountAllowed,
      })}`;
    } else if (isTakingPaymentNow && (formFields.amount <= 0 || formFields.amount > totalPayments)) {
      formErrors.amount = true;
      formErrors.amount = 'Amount should be equal or less than the invoice totals selected';
    } else if (!isTakingPaymentNow && formFields.amount !== totalPayments) {
      formErrors.amount = true;
      formErrors.amount = 'Amount should be equal to the invoice totals selected';
    }

    const overdrawsAccount = validAmount && amountExceedsBalance(formFields);
    if (overdrawsAccount) {
      formErrors.amount = true;
      formErrors.amount = 'Amount exceeds available balance';
    }

    // client
    if (formFields.paymentType === paymentTypes.CLIENT && !formFields.clientId) {
      formErrors.clientId = true;
    }

    // contact
    // the contact has an error if the contactId is not present AND:
    //   1. its matter balances AND from a direct source OR
    //   2. its contact balances from any non-combined source
    if (
      !formFields.contactId &&
      ((isMatterContactBalanceType() && !formFields.source?.isCombinedBalace) ||
        (isMatterBalanceType() && formFields.source?.paymentType === PAYMENT_TYPE.direct))
    ) {
      formErrors.contactId = true;
    }

    // matter
    if (formFields.paymentType === paymentTypes.MATTER && !formFields.matterId) {
      formErrors.matterId = true;
    }

    // effective date
    if (!formFields.effectiveDate) {
      formErrors.effectiveDate = true;
    }
    if (
      isTrustPayment &&
      formFields.effectiveDate &&
      formFields.source?.bankAccountId &&
      isReconciled({ yyyymmdd: formFields.effectiveDate, trustAccountId: formFields.source?.bankAccountId })
    ) {
      // reconciled error
      formErrors.effectiveDate = 'Warning: the date selected has already been reconciled';
    }

    // reference
    const referenceRequired = isReferenceRequired({
      isTrustPayment,
      printingMethod: formFields.printingMethod,
      trustAccountId: formFields.source?.bankAccountId,
    });
    const referenceEmpty = referenceRequired && !formFields.reference;
    const referenceNumber =
      isUsingTrustCheque({
        printingMethod: formFields.printingMethod,
        trustBankAccountId: formFields.source?.bankAccountId,
      }) &&
      referenceRequired &&
      !referenceEmpty &&
      !Number.isFinite(+formFields.reference);
    const chequeNumberInUse =
      !referenceEmpty &&
      isUsingTrustCheque({
        printingMethod: formFields.printingMethod,
        trustBankAccountId: formFields.source?.bankAccountId,
      }) &&
      chequeNumberUsed({
        reference: formFields.reference,
        printingMethod: formFields.printingMethod,
        isTrustPayment,
        trustBankAccountId: formFields.source?.bankAccountId,
      });
    if (referenceEmpty) {
      formErrors.reference = 'Reference is required';
    } else if (referenceNumber) {
      formErrors.reference = `Warning: ${capitalize(t('cheque'))} reference must be numeric`;
    } else if (chequeNumberInUse) {
      const lastChequeNumberFound = findLastChequeNumber(
        getTrustChequesByBankAccountId(formFields.source?.bankAccountId),
      );
      formErrors.reference = `Warning: ${capitalize(t('cheque'))} reference is already in use. Last ${t(
        'cheque',
      )} reference printed was ${lastChequeNumberFound}`;
    }

    // reason
    if (featureActive('BB-5508') && isTrustPayment && !formFields.reason) {
      formErrors.reason = true;
    }
    // Forms 2 expects errors to be reported as flattened dot object notation.
    return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
  };

function chequeNumberUsed({ reference, printingMethod, isTrustPayment, trustAccountId }) {
  if (printingMethod?.value === PrintManually && isTrustPayment) {
    const num = +reference;
    return Number.isFinite(num) && chequeExistsForBankAccount(num, trustAccountId);
  }

  return false;
}

function isUsingTrustCheque({ printingMethod, trustBankAccountId }) {
  const trustChequePrintSettings = getTrustChequePrintSettingsById(trustBankAccountId) || {};
  return trustChequePrintSettings?.printingActive && printingMethod?.value !== PrintNotApplicable;
}

// For trust payments, reference seems to be used for two separate purposes
// 1. When Trust Cheque is used for the invoice payment: Reference is treated like a cheque number,
//    and has to be numeric. This same reference number is used in the 1) cheque 2) payment & 3) trust
//    transaction records associated with this invoice payment.
//    a) In the PrintManually scenario, this reference field is required
//    b) In non-PrintManually scenarios, this reference number won’t be allocated until the cheque is printed.
// 2. When trust payment is made with bank transfer: i.e. either
//    a) trust cheque is turned off for the firm or
//    b) print method is “Not Applicable (Bank Transfer)”
//    Reference here can be non-numeric and is used as the reference number for 1) payment and 2) transaction
//    record associated with the invoice payment. This field is now required when auto numbering for
//    Trust to Office Transfer is turned OFF in AU.
//
// For non-trust payment types, it seems this is a manually allocated reference number and is not required.
//
// History (AU only)
// Before BB-8425 was implemented, transaction number used to use auto numbering created for Electronic Payments.
// It is now using auto numbering for Trust to Office Transfer. One notable difference with this change is that
// when Trust to Office Transfer auto numbering is turned OFF, the transaction reference field is now a required field.
//
// Technical Notes
// When making changes to reference or invoice payment in general, please consider both
// 1. Matter > Invoices > Add Payment (this file)
// 2. Invoice > Payments > Add Payment
function isReferenceRequired({ isTrustPayment, printingMethod, trustAccountId }) {
  return (
    isTrustPayment &&
    (isTrustChequePrintManually({ printingMethod, trustAccountId }) ||
      (hasFacet(facets.ttoNumbering) &&
        isTrustChequeNotApplicable({ printingMethod, trustAccountId }) &&
        !isTrustToOfficeNumberingEnabled(trustAccountId)))
  );
}

function isTrustToOfficeNumberingEnabled(trustAccountId) {
  const { trustToOfficeNumberingSettings } = getTransactionNumberingSettings({
    bankAccountId: trustAccountId,
  });

  return !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering;
}

// trust cheque printing is not applicable when
// 1. trust cheque is not enabled for firm
// 2. Not Applicable is selected as trust cheque print method
// 3. print method is not selected (for some reason, we defensively assume it's Not Applicable)
function isTrustChequeNotApplicable({ printingMethod, trustAccountId }) {
  const trustChequePrintSettings = getTrustChequePrintSettingsById(trustAccountId) || {};
  return (
    !trustChequePrintSettings?.printingActive || printingMethod?.value === PrintNotApplicable || !printingMethod?.value
  );
}

function isTrustChequePrintManually({ printingMethod, trustAccountId }) {
  const trustChequePrintSettings = getTrustChequePrintSettingsById(trustAccountId) || {};
  return trustChequePrintSettings?.printingActive && printingMethod?.value === PrintManually;
}

/**
 * Returns `true` if the source is an account (trust or operating) and the account has less the amount specified by the user.
 *
 * Returns `false` if the source is not an account or the amount specified by the user is less than the account amount.
 * */
function amountExceedsBalance({ source, matterId, amount }) {
  if (source?.paymentType === PAYMENT_TYPE.direct) {
    return false;
  }

  // we allow overdraw on the balance in AU for compliance.
  if (hasFacet(facets.allowOverdraw) && !matterHasProtectedFundsForBankAccountId(matterId, source.bankAccountId)) {
    return false;
  }

  return amount > source.balance;
}
