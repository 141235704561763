import { gql } from '@apollo/client';
import { createPolledSubscription } from 'web/services/apollo/polling-manager';
import { isNewTheme } from 'web/services/theme';

// Always include relevant ID fields to enable caching
const query = gql`
  query InitTimers {
    timers {
      timerId
      active
      creationIso
      description
      elapsedSeconds
      activeSinceUnix
      matterId
      matter {
        id
        isLead
        matterNumber
        clientNames
        clientDisplay
        otherSideDisplay
        matterStarted
        status
        matterType {
          matterTypeId
          name
        }
      }
    }
  }
`;
const TIMER_CACHE_POLL_NOTIFICATION = 'TIMER_CACHE_POLL_NOTIFICATION';
// register a notification id that we can use for polling
const notificationIds = [TIMER_CACHE_POLL_NOTIFICATION];
const ONE_MINUTE_IN_MS = 60 * 1000;

/** @type {import('./types').PollFunction} */
const poll = () => {
  if (!isNewTheme()) {
    return undefined;
  }

  return createPolledSubscription({
    subscribedNotificationId: notificationIds[0],
    intervalMs: ONE_MINUTE_IN_MS,
  });
};

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitTimers = {
  query,
  notificationIds,
  poll,
};
