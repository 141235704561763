import { getLogger } from '@sb-itops/fe-logger';
import {
  getMap as getState,
} from '@sb-billing/redux/bank-account-balances';
import {
  BANK_BALANCE_TYPE,
  getBalanceType,
} from '@sb-billing/redux/bank-account-settings';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { dateToInteger as getDateTo } from '@sb-itops/date';
import { selectors, opdates } from '@sb-billing/redux/bank-account-balances.2';
import { Cents } from '@sb-itops/money';
import { hasFacet, facets } from '@sb-itops/region-facets';

const { getMatterBalance, getMatterContactBalance, getBankAccountBalanceById } = selectors;
const { getBankAccountBalanceOpdate, getTemplateBankAccount } = opdates;

angular.module('@sb-billing/services').service('sbTransferFundsService',
  function (sbGenericEndpointService) {
    'use strict';

    const that = this;
    const log = getLogger('sbTransferFundsService');
    const matterToMatterEndpoint = 'billing/transfer-funds';

    that.transferMatterToMatterP = transferMatterToMatterP;

    //log.setLogLevel('info');

    const getBankAccountId = (transfer) => transfer.bankAccountId;

    const getBalance = (transfer, balanceType) => {
      const { sourceMatterId: matterId, contactId } = transfer;

      return balanceType  === BANK_BALANCE_TYPE.matterContact
        ? getMatterContactBalance(getState(), { bankAccountId: getBankAccountId(transfer), matterId, contactId }) 
        : getMatterBalance(getState(), { bankAccountId: getBankAccountId(transfer), matterId });
    }

    const validate = (transfer, balance) => {
      if (balance - transfer.amount < 0) {
        log.error(`Transfer failed! the balance: ${balance} - ${transfer.amount} < 0`);
        throw new Error(`Matter to matter transfer amount cannot exceed the source matter balance - $${new Cents(balance).toString()}`);
      }

      if (transfer.accountType.toUpperCase() === 'TRUST' && getBankAccountId(transfer) && isReconciled({ yyyymmdd: (transfer.effectiveDate), trustAccountId: getBankAccountId(transfer) })) {
        const msg = `Transfer failed! The current date has already been reconciled`;
        log.error(msg);
        throw msg;
      }
    }

    const getBankAccountBalanceServiceOpdate = ({ bankAccountBalance, transfer, balanceType }) => {
      const { amount, contactId } = transfer;
      const allowOverdraw = hasFacet(facets.allowOverdraw);

      let bankAccountBalanceOpdate;
      
      bankAccountBalanceOpdate = getBankAccountBalanceOpdate({ 
        transaction: {
          cents: -amount,
          contactId,
          matterId: transfer.sourceMatterId,
        },
        bankAccountBalance,
        balanceType,
        allowOverdraw
      });
      
      bankAccountBalanceOpdate = getBankAccountBalanceOpdate({
        transaction: {
          cents: amount,
          contactId,
          matterId: transfer.destinationMatterId,
        },
        bankAccountBalance: bankAccountBalanceOpdate,
        balanceType,
        allowOverdraw
      });

      return bankAccountBalanceOpdate;
    }

    async function transferMatterToMatterP(transfer) {
      log.info('matter to matter transfer', transfer);
      const balanceType = getBalanceType();
      const balance = getBalance(transfer, balanceType) || 0;
      validate(transfer, balance);
      //NB this must always be set to todays date. Can't be back dated.
      transfer.effectiveDate = getDateTo(new Date());
      const bankAccountBalance =
        getBankAccountBalanceById(getState(), { bankAccountId: getBankAccountId(transfer) })
        || getTemplateBankAccount();
      const opdate = getBankAccountBalanceServiceOpdate({ bankAccountBalance, transfer, balanceType: balanceType });
      const changeset = { sbBankAccountBalancesService: [opdate] };

      return sbGenericEndpointService.postPayloadP(matterToMatterEndpoint, 'matter-to-matter', transfer, 'POST', { changeset });
    }

  });
