import {
  selectors as supportDebugSelectors,
  actions as supportDebugActions
} from 'web/redux/route/billing-support-debug';
import { store } from '@sb-itops/redux';

angular.module('sb.billing.webapp').component('sbDuiCacheQuery', {
  templateUrl: 'ng-composable-components/data-ui/custom/cache-query/dui-cache-query.html',
  bindings: {
    caches: '<'
  },
  controllerAs: 'ctrl',
  controller: function ($window, sbLoggerService, sbGenericCacheService) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbDuiCacheQuery');
    const { query, getState } = sbGenericCacheService.cacheQuery;

    $window.Smokeball = $window.Smokeball || {};

    that.search = search;
    that.display = display;
    that.save = save;
    that.copy = copy;
    that.copyHistory = copyHistory;
    that.clearHistory = clearHistory;
    that.onSelect = onSelect;

    that.$onInit = () => {
      const debugEntity = supportDebugSelectors.getDebugEntity(store.getState());
      const debugCache = debugEntity && debugEntity.entityCacheName
        ? that.caches.find((cache) => cache.name === debugEntity.entityCacheName)
        : undefined;
      that.view = {
        genericCacheState: sbGenericCacheService.getState(),
        caches: that.caches,
        showMap: true,
        layout: 'map',
        isRemovedCache: isRemovedCache(debugEntity && debugEntity.entityCacheName),
        showViewDetails: debugEntity ? showLinkToDataExplorer(debugEntity) : false,
      };
      that.model = {
        cache: debugCache || that.view.caches[0], 
        key: debugEntity && debugEntity.entityId || undefined,
        subquery: undefined,
      };

      if (debugCache && debugEntity && debugEntity.entityId) {
        search();
      }
    };

    that.$onDestroy = () => {
      delete $window.Smokeball.cacheQueryResult;
    };

    function isRemovedCache(entityCacheName) {
      const removedCachesMap = {
        'sbFeeService': true,
      };

      return !!removedCachesMap[entityCacheName];
    }

    function display(layout) {
      that.view.layout = layout;
      switch (layout) {
        case 'state':
          that.view.showState = !that.view.showState;
          break;
        case 'cache-details':
          that.view.showCacheDetails = !that.view.showCacheDetails;
          break;
        case 'map':
          that.view.result = that.model.result;
          break;
        case 'values':
          that.view.result = _.values(that.model.result);
          break;
        case 'ids':
          that.view.result = _.keys(that.model.result);
          break;
        default:
          return;
      }
      $window.Smokeball.cacheQueryResult = that.view.result;
    }

    function showLinkToDataExplorer({ entityCacheName, entityId }) {
      const allowedCachesMap = {
        'sbExpenseService': true,
        'sbFeeService': true,
      };

      if (allowedCachesMap[entityCacheName] && entityId) {
        const debugEntity = supportDebugSelectors.getDebugEntity(store.getState());
        if (!debugEntity) {
          store.dispatch(supportDebugActions.setDebugEntity({ entityCacheName, entityId }));
        }

        return true;
      }

      return false;
    }

    function search (saveInHistory = false) {
      log.info(`name: ${that.model.cache.name}, key: ${that.model.key} subquery: ${that.model.subquery}`);

      // set selected debug entity
      const currentDebugEntity = { entityCacheName: that.model.cache.name, entityId: that.model.key };
      that.view.selectedDebugEntity = currentDebugEntity;
      that.view.showViewDetails = showLinkToDataExplorer(currentDebugEntity);
      that.view.isRemovedCache = isRemovedCache(currentDebugEntity && currentDebugEntity.entityCacheName);

      if (saveInHistory && Object.keys(that.view.result || {}).length > 0) {
        store.dispatch(supportDebugActions.setDebugEntity(currentDebugEntity));
      }

      that.model.result = query(that.model.cache.name, that.model.key, that.model.subquery);
      that.view.result = that.model.result;
      that.view.layout = 'map';
      that.view.name = that.model.cache.name;

      if (that.view.isRemovedCache) {
        return;
      }

      that.view.cacheState = getState(that.model.cache.name);
      $window.Smokeball.cacheQueryResult = that.view.result;
    }

    function save() {
      const blob = new Blob([JSON.stringify(that.view.result)], {type: 'text/json'});
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');

      a.download = `${that.view.name}-${that.view.layout}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);

    }

    function copy() {
      navigator.clipboard.writeText(JSON.stringify(that.view.result));
    }

    function copyHistory() {
      const debugEntityHistory = supportDebugSelectors.getDebugEntityHistory(store.getState());
      navigator.clipboard.writeText(JSON.stringify(debugEntityHistory));
    }

    function clearHistory() {
      store.dispatch(supportDebugActions.clearDebugEntityHistory());
    }

    function onSelect(debugEntity) {
      that.model.cache = debugEntity && debugEntity.entityCacheName ? that.caches.find((cache) => cache.name === debugEntity.entityCacheName) : undefined;
      that.model.key = debugEntity.entityId;
      if (!that.model.cache && debugEntity && debugEntity.entityCacheName) {
        that.model.cache = { display: `${debugEntity.entityCacheName} (removed)`, name: debugEntity.entityCacheName };
      }

      search();
    }
  }
});
