import { RESET_FILTER, SET_SORT_BY } from './types';

const INITIAL_FILTER_STATE = {
  sortBy: 'issuedDate',
  sortDirection: 'desc',
};

export const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case RESET_FILTER: {
      return {
        ...state,
        filters: INITIAL_FILTER_STATE.filters,
      };
    }
    case SET_SORT_BY: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
