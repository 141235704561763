import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { durationType as durationTypeEnum } from '@sb-billing/business-logic/shared/entities';
import { fuzzyTime } from '@sb-billing/business-logic/shared/services';
import { calculateFeeDescriptionMaxLength } from '@sb-billing/business-logic/fee/services';

import { featureActive } from '@sb-itops/feature';
import { FeeSourceItemEntry } from './FeeSourceItemEntry';

const { convertMinsToUnits, convertHoursToUnits, convertUnitsToHours, getMinutesFuzzy, roundToInterval } = fuzzyTime;

/**
 * mapDurationToMins
 *
 * Maps durations from hours/units to minutes
 *
 * @param {Object} params
 * @param {number} params.duration
 * @param {durationTypeEnum} params.durationType
 * @param {number} params.interval
 * @returns {number}
 */
function mapDurationToMins({ duration, durationType, interval }) {
  switch (durationType) {
    case durationTypeEnum.HOURS:
      return getMinutesFuzzy({ duration, interval, withRounding: true });
    case durationTypeEnum.UNITS:
      return getMinutesFuzzy({ duration: `${duration}u`, interval, withRounding: true });
    default:
      throw new Error(`Invalid duration type: ${durationType}`);
  }
}

/**
 * getMappedDuration
 *
 * Maps durations in minutes to either hours/units
 *
 * @param {Object} params
 * @param {number} params.durationInMins
 * @param {durationTypeEnum} params.durationType
 * @param {number} params.interval
 * @returns {number}
 */
function getMappedDuration({ durationInMins, durationType, interval }) {
  switch (durationType) {
    case durationTypeEnum.HOURS:
      return +(durationInMins / 60).toFixed(5);
    case durationTypeEnum.UNITS:
      return convertMinsToUnits({
        mins: durationInMins,
        interval,
      });
    default:
      throw new Error(`Invalid duration type: ${durationType}`);
  }
}

/**
 * generateNewFeeDuration
 *
 * This value is generated and displayed based on the user input.
 *
 * While the user types (i.e. onChange), we directly reflect their input on the fee duration billed/worked field. The fee duration value will be rounded on the onBlur event (once the user finishes), if required.
 *
 * NB: The other relevant fee fields (e.g. amounts, tax, time) will base their calculations on the rounded billable/non-billable minutes.
 *
 * @param {Object} params
 * @param {number} params.feeDuration
 * @param {number} params.newSourceItemDuration
 * @param {number} params.originalSourceItemDuration
 * @returns {string}
 */
function generateNewFeeDuration(args) {
  const { feeDuration, newSourceItemDuration, originalSourceItemDuration } = args;
  const calculation = feeDuration + newSourceItemDuration - originalSourceItemDuration;
  const newFeeDuration = parseFloat(calculation.toFixed(5)).toString();

  return newFeeDuration;
}

const hooks = (props) => ({
  useFeeSourceItem: () => {
    const {
      durationType,
      feeDurationBilled,
      feeDurationWorked,
      hasDurationTypeChanged,
      index,
      interval,
      sourceItem: sourceItemEntry,
      sourceItems,
      // Callbacks
      onSetShowModal,
      onSourceItemsChange,
    } = props;
    const { id } = sourceItemEntry;
    // This mapped duration billed is:
    // 1. Displayed to the user in hours/units
    // 2. Is editable by the user
    //
    // This duration will eventually be mapped back to minutes when making form updates
    const [sourceItemMappedDurationBilled, setSourceItemMappedDurationBilled] = React.useState(
      getMappedDuration({
        durationInMins: sourceItemEntry.durationBilled,
        durationType,
        interval,
      }),
    );
    const [sourceItemMappedDurationWorked, setSourceItemMappedDurationWorked] = React.useState(
      getMappedDuration({
        durationInMins: sourceItemEntry.durationWorked,
        durationType,
        interval,
      }),
    );

    React.useEffect(() => {
      // Only execute hook:
      // 1. When the user updates the duration type
      // 2. Post initial render (as sourceItemMappedDurationBilled is initialised via the useState hook)
      if (!hasDurationTypeChanged) {
        return;
      }

      let newMappedDurationBilled = '';

      if (durationType === durationTypeEnum.HOURS) {
        newMappedDurationBilled = convertUnitsToHours({
          units: sourceItemMappedDurationBilled,
          interval,
        });
      } else if (durationType === durationTypeEnum.UNITS) {
        newMappedDurationBilled = convertHoursToUnits({
          hrs: sourceItemMappedDurationBilled,
          interval,
          withRounding: true,
        });
      }

      setSourceItemMappedDurationBilled(newMappedDurationBilled);

      if (featureActive('BB-13563')) {
        let newMappedDurationWorked = '';
        if (durationType === durationTypeEnum.HOURS) {
          newMappedDurationWorked = convertUnitsToHours({
            units: sourceItemMappedDurationWorked,
            interval,
          });
        } else if (durationType === durationTypeEnum.UNITS) {
          newMappedDurationWorked = convertHoursToUnits({
            hrs: sourceItemMappedDurationWorked,
            interval,
            withRounding: true,
          });
        }
        setSourceItemMappedDurationWorked(newMappedDurationWorked);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDurationTypeChanged, durationType]);

    function onBillableChange({ isBillable }) {
      const updatedSourceItems = sourceItems.map((sourceItem) => {
        if (sourceItem.id === id) {
          return {
            ...sourceItem,
            billable: isBillable,
          };
        }

        return sourceItem;
      });
      onSourceItemsChange({ newSourceItems: updatedSourceItems });
    }

    function onChangeSourceItemDescription({ description }) {
      const updatedSourceItems = sourceItems.map((sourceItem) => {
        if (sourceItem.id === id) {
          return {
            ...sourceItem,
            description,
          };
        }

        return sourceItem;
      });
      onSourceItemsChange({ newSourceItems: updatedSourceItems });
    }

    function onRemoveSourceItem() {
      if (sourceItems.length === 1) {
        onSetShowModal(true);
      } else {
        const sourceItemsCopy = sourceItems.slice();
        sourceItemsCopy.splice(index, 1);
        onSourceItemsChange({ newSourceItems: sourceItemsCopy });
      }
    }

    function onSourceItemDurationBilledValueChange({ sourceItemDurationBilled }) {
      let newSourceItemDurationBilled = 0;

      switch (durationType) {
        case durationTypeEnum.HOURS:
          newSourceItemDurationBilled = parseFloat(sourceItemDurationBilled.toFixed(5));
          break;
        case durationTypeEnum.UNITS:
          // If decimals are inputted, round the duration billed up to an integer
          newSourceItemDurationBilled = Math.ceil(sourceItemDurationBilled);
          break;
        default:
          throw new Error(`Invalid duration type: ${durationType}`);
      }

      const updatedSourceItems = sourceItems.map((sourceItem) => {
        if (sourceItem.id === id) {
          const durationBilled = mapDurationToMins({ duration: newSourceItemDurationBilled, durationType, interval });

          return {
            ...sourceItem,
            durationBilled,
          };
        }

        return sourceItem;
      });

      const newFeeDurationBilled = generateNewFeeDuration({
        feeDuration: parseFloat(feeDurationBilled),
        newSourceItemDuration: newSourceItemDurationBilled,
        originalSourceItemDuration: sourceItemMappedDurationBilled,
      });

      // Update source items and fee duration billed
      onSourceItemsChange({ newSourceItems: updatedSourceItems, newFeeDurationBilled });
      // Update displayed source item duration
      setSourceItemMappedDurationBilled(newSourceItemDurationBilled);
    }

    function onSourceItemDurationWorkedValueChange({ sourceItemDurationWorked }) {
      let newSourceItemDurationWorked = 0;

      switch (durationType) {
        case durationTypeEnum.HOURS:
          newSourceItemDurationWorked = parseFloat(sourceItemDurationWorked.toFixed(5));
          break;
        case durationTypeEnum.UNITS:
          // If decimals are inputted, round the duration up to an integer
          newSourceItemDurationWorked = Math.ceil(sourceItemDurationWorked);
          break;
        default:
          throw new Error(`Invalid duration type: ${durationType}`);
      }

      const updatedSourceItems = sourceItems.map((sourceItem) => {
        if (sourceItem.id === id) {
          const durationWorked = mapDurationToMins({ duration: newSourceItemDurationWorked, durationType, interval });

          return {
            ...sourceItem,
            durationWorked,
          };
        }

        return sourceItem;
      });

      const newFeeDurationWorked = generateNewFeeDuration({
        feeDuration: parseFloat(feeDurationWorked),
        newSourceItemDuration: newSourceItemDurationWorked,
        originalSourceItemDuration: sourceItemMappedDurationWorked,
      });

      // Update source items and fee duration worked
      onSourceItemsChange({ newSourceItems: updatedSourceItems, newFeeDurationWorked });
      // Update displayed source item duration worked
      setSourceItemMappedDurationWorked(newSourceItemDurationWorked);
    }

    function onSourceItemDurationBilledBlur(event) {
      if (durationType === durationTypeEnum.HOURS) {
        const durationBilled = event.target.valueAsNumber;
        const mins = getMinutesFuzzy({ duration: durationBilled, interval, withRounding: true });
        const roundedSourceItemDurationBilled = parseFloat((roundToInterval({ mins, interval }) / 60).toFixed(5));

        const updatedSourceItems = sourceItems.map((sourceItem) => {
          if (sourceItem.id === id) {
            const convertedDuration = mapDurationToMins({
              duration: roundedSourceItemDurationBilled,
              durationType,
              interval,
            });

            return {
              ...sourceItem,
              durationBilled: convertedDuration,
            };
          }

          return sourceItem;
        });

        const newFeeDurationBilled = generateNewFeeDuration({
          feeDuration: parseFloat(feeDurationBilled),
          newSourceItemDuration: roundedSourceItemDurationBilled,
          originalSourceItemDuration: sourceItemMappedDurationBilled,
        });

        // Update source items and fee duration
        onSourceItemsChange({ newSourceItems: updatedSourceItems, newFeeDurationBilled });
        // Update displayed source item duration
        setSourceItemMappedDurationBilled(roundedSourceItemDurationBilled);
      }
    }

    function onSourceItemDurationWorkedBlur(event) {
      if (durationType === durationTypeEnum.HOURS) {
        const durationWorked = event.target.valueAsNumber;
        const mins = getMinutesFuzzy({ duration: durationWorked, interval, withRounding: true });
        const roundedSourceItemDurationWorked = parseFloat((roundToInterval({ mins, interval }) / 60).toFixed(5));

        const updatedSourceItems = sourceItems.map((sourceItem) => {
          if (sourceItem.id === id) {
            const convertedDuration = mapDurationToMins({
              duration: roundedSourceItemDurationWorked,
              durationType,
              interval,
            });

            return {
              ...sourceItem,
              durationWorked: convertedDuration,
            };
          }

          return sourceItem;
        });

        const newFeeDurationWorked = generateNewFeeDuration({
          feeDuration: parseFloat(feeDurationWorked),
          newSourceItemDuration: roundedSourceItemDurationWorked,
          originalSourceItemDuration: sourceItemMappedDurationWorked,
        });

        // Update source items and fee duration
        onSourceItemsChange({ newSourceItems: updatedSourceItems, newFeeDurationWorked });
        // Update displayed source item duration
        setSourceItemMappedDurationWorked(roundedSourceItemDurationWorked);
      }
    }

    const callbacks = {
      onBillableChange,
      onChangeSourceItemDescription,
      onRemoveSourceItem,
      onSourceItemDurationBilledBlur,
      onSourceItemDurationWorkedBlur,
      onSourceItemDurationBilledValueChange,
      onSourceItemDurationWorkedValueChange,
    };

    return {
      ...callbacks,
      index,
      sourceItem: sourceItemEntry,
      sourceItemMappedDurationBilled: sourceItemMappedDurationBilled.toString(),
      sourceItemMappedDurationWorked: sourceItemMappedDurationWorked.toString(),
    };
  },
  useFeeSourceItemDescriptionMaxLength: () => {
    const { sourceItem } = props;
    const descriptionFieldMaxLength = calculateFeeDescriptionMaxLength(sourceItem.description);

    return {
      descriptionFieldMaxLength,
    };
  },
});

export const FeeSourceItemEntryContainer = composeHooks(hooks)(FeeSourceItemEntry);

FeeSourceItemEntryContainer.displayName = 'FeeSourceItemEntryContainer';

const SourceItemType = PropTypes.shape({
  activityCount: PropTypes.number,
  activityRelatedId: PropTypes.string,
  activityType: PropTypes.number,
  billable: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  durationBilled: PropTypes.number.isRequired,
  durationWorked: PropTypes.number.isRequired,
  id: PropTypes.string, // Added (not part of entity)
  originalBillable: PropTypes.bool,
  sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

FeeSourceItemEntryContainer.propTypes = {
  durationType: PropTypes.oneOf(Object.keys(durationTypeEnum)),
  feeDurationBilled: PropTypes.string.isRequired,
  feeDurationWorked: PropTypes.string.isRequired,
  hasDurationTypeChanged: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  sourceItem: SourceItemType.isRequired,
  sourceItems: PropTypes.arrayOf(SourceItemType).isRequired,
  // Callbacks
  onSetShowModal: PropTypes.func.isRequired,
  onSourceItemsChange: PropTypes.func.isRequired,
};

FeeSourceItemEntryContainer.defaultProps = {};
