'use strict';

const Yup = require('yup');

const StaffSchema = Yup.object().shape({
  id: Yup.string(),
  userId: Yup.string(),
  title: Yup.string().nullable(true),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  initials: Yup.string(),
  phoneAreaCode: Yup.string().nullable(true),
  phoneNumber: Yup.string().nullable(true),
  cellAreaCode: Yup.string().nullable(true),
  cellNumber: Yup.string().nullable(true),
  email: Yup.string().email().required(),
  role: Yup.string().nullable(true),
  isFormerStaff: Yup.bool().nullable(true),
  enterTimeAsUnits: Yup.bool(),
  timekeeperClassification: Yup.string().nullable(true),
});

module.exports = {
  StaffSchema,
};
