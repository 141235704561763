import { Document } from 'types/Documents';

const MapFiles = (value: Document) => {
  if (value.type === 'file') {
    return {
      fileExtension: value.data.fileExtension,
      fileId: value.data.id,
      fileName: value.data.name,
      filePath: value.data.path,
      folderId: value.data.folder?.id,
      versionId: value.data.versionId,
    };
  }

  return null;
};

export default MapFiles;
