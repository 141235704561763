import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ClickableIcon } from '@sb-itops/react/clickable-icon';
// eslint-disable-next-line
import { DEPRECATED_ProceedDialog } from "@sb-itops/react/proceed-dialog";

class ButtonGroupButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModal: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  onClick(event) {
    event.stopPropagation();
    if (this.props.isSelected) {
      return;
    }
    this.props.onSelect(this.props.data.id);
  }

  onSave(event) {
    event.stopPropagation();
    this.props.onEdit(event.target.value);
  }

  onDelete(event) {
    event.stopPropagation();
    this.setState({ deleteModal: true });
  }

  onDeleteConfirm(event) {
    event.stopPropagation();
    this.setState({ deleteModal: false }, () => {
      this.props.onDelete(this.props.data.id);
    });
  }

  onModalClose(event) {
    event.stopPropagation();
    this.setState({ deleteModal: false });
  }

  render() {
    const { data, isSelected, unsavedChanges, editMode } = this.props;
    const cssClasses = classnames(
      'group-button',
      isSelected && 'selected',
      data.createNew && !editMode && 'add-new-button',
    );
    // eslint-disable-next-line jsx-a11y/no-autofocus
    const inputElement = <input type="text" autoFocus placeholder="template name" onChange={this.onSave} />;

    return (
      <div className={cssClasses} title={data.value} role="button" tabIndex={0} onClick={this.onClick}>
        {unsavedChanges && <span className="attention-mark">*</span>}
        {!editMode && <span className="button-element">{data.value}</span>}
        {data.deletable && <ClickableIcon className="icon-delete-1-1" onClick={this.onDelete} title="Delete" />}
        {editMode && inputElement}
        {this.state.deleteModal && (
          // eslint-disable-next-line
          <DEPRECATED_ProceedDialog
            title="Delete invoice settings template"
            bodyText={`Are you sure you want to delete template '${data.value}'?`}
            onYesClick={this.onDeleteConfirm}
            onNoClick={this.onModalClose}
          />
        )}
      </div>
    );
  }
}

ButtonGroupButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    createNew: PropTypes.bool,
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
  }).isRequired,
  isSelected: PropTypes.bool,
  unsavedChanges: PropTypes.bool,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  editMode: PropTypes.bool,
};

ButtonGroupButton.defaultProps = {
  isSelected: undefined,
  unsavedChanges: false,
  editMode: false,
  onSelect: () => {},
  onEdit: () => {},
  onDelete: () => {},
};

ButtonGroupButton.displayName = 'ButtonGroupButton';

export default ButtonGroupButton;
