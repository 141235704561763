import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getLawPayClientId, getLawPayRedirectUrl } from '@sb-itops/environment-config';
import { getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';

import { useSelector } from 'react-redux';
import { LawpaySettings } from './LawpaySettings';

const hooks = () => ({
  useLawpaySettings: () => {
    const lawpaySettings = useSelector(() => getProviderSettings(providers.LAWPAY) || {});

    return {
      showForm: !!lawpaySettings.publicKey,
      onConnect: () => {
        // Connect here is not actually calling payment-provider connect endpoint.
        // That endpoint is called later from lawpay-oauth-connect.js
        const lawpayHost = 'https://secure.lawpay.com';
        const lawpayConnectUrl = `${lawpayHost}/oauth/authorize?response_type=code&scope=chargeio&redirect_uri=${getLawPayRedirectUrl()}&client_id=${getLawPayClientId()}`;
        window.open(lawpayConnectUrl);
      },
    };
  },
});

export const LawpaySettingsContainer = withReduxProvider(composeHooks(hooks)(LawpaySettings));

LawpaySettingsContainer.displayName = 'LawPaySettingsContainer';
LawpaySettingsContainer.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
LawpaySettingsContainer.defaultProps = {};
