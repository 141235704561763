import * as React from 'react';
import PropTypes from 'prop-types';

import { featureActive } from '@sb-itops/feature';
import uuid from '@sb-itops/uuid';
import { durationType as durationTypeEnum } from '@sb-billing/business-logic/shared/entities';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fuzzyTime } from '@sb-billing/business-logic/shared/services';

import { FeeSourceItemsEntries } from './FeeSourceItemsEntries';

const { convertHoursToUnits, convertMinsToUnits, convertUnitsToHours, getMinutesFuzzy } = fuzzyTime;

const hooks = (props) => ({
  useFeeDeletionModal: () => {
    const {
      durationType,
      feeDurationBilled,
      hasDurationTypeChanged,
      interval,
      setHasDurationTypeChanged,
      sourceItems,
      onSourceItemsChange,
    } = props;

    const [showModal, setShowModal] = React.useState(false);

    function onAddSourceItemEntry() {
      const newSourceItem = {
        id: uuid(),
        billable: true,
        description: '',
        durationBilled: 0,
        durationWorked: featureActive('BB-13563') ? 0 : null,
        sourceActivityIds: [],
      };
      const sourceItemsWithNewEntry = [...sourceItems, newSourceItem];

      onSourceItemsChange({ newSourceItems: sourceItemsWithNewEntry });
    }

    /**
     * @deprecated to be deprecated after BB-13563 turns on - as the duration type will be controlled in fee modal body
     */
    function onSourceItemsDurationTypeChange({ newDurationType }) {
      if (newDurationType === durationType) {
        return;
      }

      let newFeeDurationBilled = '';
      let updatedSourceItems = [...sourceItems];
      const changingToHours = newDurationType === durationTypeEnum.HOURS;
      const changingToUnits = newDurationType === durationTypeEnum.UNITS;

      if (changingToHours) {
        newFeeDurationBilled = convertUnitsToHours({
          units: feeDurationBilled,
          interval,
        });
      } else if (changingToUnits) {
        newFeeDurationBilled = convertHoursToUnits({
          hrs: feeDurationBilled,
          interval,
          withRounding: true,
        });

        // When changing from hours to units,
        // Handle the case where the interval has changed since last save (e.g. 1m to 6m)
        // The duration billed (in mins) value needs to update accordingly to the new interval
        updatedSourceItems = sourceItems.map((sourceItem) => {
          const newDurationBilledInUnits = convertMinsToUnits({ mins: sourceItem.durationBilled, interval });
          const newDurationBilledInMins = getMinutesFuzzy({
            duration: `${newDurationBilledInUnits}u`,
            interval,
            withRounding: true,
          });

          return {
            ...sourceItem,
            durationBilled: newDurationBilledInMins,
          };
        });
      }

      onSourceItemsChange({
        newSourceItems: updatedSourceItems,
        newFeeDurationBilled: newFeeDurationBilled.toString(),
        newDurationType,
      });

      if (!hasDurationTypeChanged) {
        setHasDurationTypeChanged(true);
      }
    }

    return {
      hasDurationTypeChanged,
      showModal,
      onAddSourceItemEntry,
      onSetShowModal: (newValue) => setShowModal(newValue),
      onSourceItemsDurationTypeChange,
    };
  },
});

export const FeeSourceItemsEntriesContainer = composeHooks(hooks)((props) => {
  const { durationType } = props;

  const isTimeFee = durationType === durationTypeEnum.HOURS || durationType === durationTypeEnum.UNITS;

  if (!isTimeFee) {
    return null;
  }

  return <FeeSourceItemsEntries {...props} />;
});

FeeSourceItemsEntriesContainer.displayName = 'FeeSourceItemsEntriesContainer';

const SourceItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    activityCount: PropTypes.number,
    activityRelatedId: PropTypes.string,
    activityType: PropTypes.number,
    billable: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    durationBilled: PropTypes.number.isRequired,
    id: PropTypes.string, // Added (not part of entity)
    originalBillable: PropTypes.bool,
    sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
).isRequired;

FeeSourceItemsEntriesContainer.propTypes = {
  durationType: PropTypes.oneOf(Object.values(durationTypeEnum)).isRequired,
  feeDurationBilled: PropTypes.string.isRequired,
  hasDurationTypeChanged: PropTypes.bool.isRequired,
  initialValue: SourceItemsType,
  interval: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  sourceItems: SourceItemsType,
  onDeleteFee: PropTypes.func.isRequired,
  onSourceItemsChange: PropTypes.func.isRequired,
  setHasDurationTypeChanged: PropTypes.func.isRequired,
};

FeeSourceItemsEntriesContainer.defaultProps = {};
