import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, buttonTypes, LoadingBarInfinite, Modal } from '@sb-itops/react';

import { InvoiceEmailForm } from '../invoice-email-form';
import Styles from './InvoiceEmailModal.module.scss';

export const INVOICE_EMAIL_MODAL_ID = 'invoice-email-modal';

/**
 * Styling for multi tabs
 *
 * @param {object} params
 * @param {boolean} params.tabValid
 * @param {boolean} params.debtorIncluded
 * @returns string
 */
function getTabIndicatorClasses({ tabValid, debtorIncluded }) {
  if (!debtorIncluded) {
    return classnames('icon-mail-block', Styles.tabIndicator);
  }

  if (!tabValid) {
    return classnames('icon-alert-1', Styles.tabIndicator, Styles.redText);
  }

  return classnames('icon-check-circle-2', Styles.tabIndicator, Styles.blueText);
}

export const InvoiceEmailModal = ({
  /** Modal */
  showModal,
  onModalClose,
  /** Form */
  bccAddress,
  ccAddress,
  combineAttachments,
  formInitialised,
  fromAddress,
  includeDebtor,
  isLoading,
  isPreviewMode,
  isSubmitDisabled,
  message,
  onFieldValueUpdated,
  onPreviewToggled,
  onSend,
  previewMessage,
  previewSubject,
  readOnlyCcBcc,
  readOnlyToAddress,
  sendCopyToMe,
  showCombineAttachments,
  showDisabledPayButtonsMessage,
  showPreviewButton,
  staffAddress,
  subject,
  toAddress,
  // Multi tab
  onChangeTab,
  selectedTabId,
  showIncludeDebtor,
  tabs,
  isMultiTabForm,
}) => (
  <Modal
    title="Send Email"
    className={Styles.invoiceEmailModal}
    modalBodyClassName={Styles.invoiceEmailModalBody}
    body={
      isLoading || !formInitialised ? (
        <div className={Styles.loadingBarContainer}>
          <LoadingBarInfinite loading={isLoading} />
        </div>
      ) : (
        <div className={classnames(Styles.invoiceEmailFormContainer, isMultiTabForm && Styles.multiTabForm)}>
          {isMultiTabForm && (
            <div className={Styles.tabList}>
              {tabs.map(({ id, text, tabValid, debtorIncluded }) => (
                <Button
                  key={id}
                  type={buttonTypes.tab}
                  highlight={selectedTabId === id}
                  onClick={() => onChangeTab({ newlySelectedTabId: id })}
                >
                  <div className={getTabIndicatorClasses({ tabValid, debtorIncluded })} />
                  <span title={text} className={Styles.tabText}>
                    {text}
                  </span>
                </Button>
              ))}{' '}
            </div>
          )}
          <div className={Styles.invoiceEmailForm}>
            <InvoiceEmailForm
              bccAddress={bccAddress}
              ccAddress={ccAddress}
              combineAttachments={combineAttachments}
              fromAddress={fromAddress}
              includeDebtor={includeDebtor}
              isLoading={isLoading}
              isPreviewMode={isPreviewMode}
              message={message}
              onFieldValueUpdated={onFieldValueUpdated}
              onPreviewToggled={onPreviewToggled}
              previewMessage={previewMessage}
              previewSubject={previewSubject}
              readOnlyCcBcc={readOnlyCcBcc}
              readOnlyToAddress={readOnlyToAddress}
              selectedTabId={selectedTabId}
              sendCopyToMe={sendCopyToMe}
              showCombineAttachments={showCombineAttachments}
              showDisabledPayButtonsMessage={showDisabledPayButtonsMessage}
              showIncludeDebtor={showIncludeDebtor}
              showPreviewButton={showPreviewButton}
              staffAddress={staffAddress}
              subject={subject}
              toAddress={toAddress}
            />
          </div>
        </div>
      )
    }
    footer={
      <Button onClick={onSend} disabled={isSubmitDisabled}>
        Send Now
      </Button>
    }
    isVisible={showModal}
    onModalClose={onModalClose}
  />
);

InvoiceEmailModal.displayName = 'InvoiceEmailModal';

InvoiceEmailModal.propTypes = {
  /** Modal */
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  /** Form */
  bccAddress: PropTypes.object,
  ccAddress: PropTypes.object,
  combineAttachments: PropTypes.object,
  formInitialised: PropTypes.bool.isRequired,
  fromAddress: PropTypes.object,
  includeDebtor: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool.isRequired,
  message: PropTypes.object,
  onFieldValueUpdated: PropTypes.func,
  onPreviewToggled: PropTypes.func,
  onSend: PropTypes.func.isRequired,
  previewMessage: PropTypes.string,
  previewSubject: PropTypes.string,
  readOnlyCcBcc: PropTypes.object,
  readOnlyToAddress: PropTypes.object,
  sendCopyToMe: PropTypes.object,
  showCombineAttachments: PropTypes.bool,
  showDisabledPayButtonsMessage: PropTypes.bool,
  showIncludeDebtor: PropTypes.bool,
  showPreviewButton: PropTypes.bool,
  staffAddress: PropTypes.object,
  subject: PropTypes.object,
  toAddress: PropTypes.object,
  // Multi tab
  onChangeTab: PropTypes.func,
  selectedTabId: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  isMultiTabForm: PropTypes.bool.isRequired,
};

InvoiceEmailModal.defaultProps = {
  /** Form */
  bccAddress: undefined,
  ccAddress: undefined,
  combineAttachments: undefined,
  fromAddress: undefined,
  includeDebtor: undefined,
  isPreviewMode: undefined,
  message: undefined,
  onFieldValueUpdated: undefined,
  onPreviewToggled: undefined,
  previewMessage: undefined,
  previewSubject: undefined,
  readOnlyCcBcc: undefined,
  readOnlyToAddress: undefined,
  sendCopyToMe: undefined,
  showCombineAttachments: undefined,
  showDisabledPayButtonsMessage: undefined,
  showPreviewButton: undefined,
  staffAddress: undefined,
  subject: undefined,
  toAddress: undefined,
  // Multi tab
  onChangeTab: undefined,
  selectedTabId: undefined,
  showIncludeDebtor: undefined,
  tabs: undefined,
};
