'use strict';

const authentication = require('./authentication');
const infra = require('./infra');
const files = require('./files');

module.exports = {
  authentication,
  infra,
  files,
};
