import * as Yup from 'yup';
import { PersonSchema, OptionalAddressSchema, CompanySchema, TrustSchema } from '@sb-customer-management/validation';

export const CreateEditContactSchema = (region) => {
  if (!region) {
    throw new Error('CreateEditContactSchema requires a region to be injected');
  }

  return Yup.object().shape({
    contactType: Yup.string().oneOf(['person', 'company', 'trust']).required(),
    personFields: Yup.object().when('contactType', {
      is: (value) => value === 'person',
      then: PersonSchema,
    }),
    addressFields: OptionalAddressSchema(region),
    companyFields: Yup.object().when('contactType', {
      is: (value) => value === 'company',
      then: CompanySchema.shape({
        additionalContactProps: Yup.lazy((obj) =>
          Yup.object(
            Object.keys(obj || {}).reduce((acc, key) => {
              acc[key] = Yup.object().shape({
                title: Yup.string(),
                firstName: Yup.string().when('existing', {
                  is: (val) => !val,
                  then: Yup.string().required(),
                }),
                lastName: Yup.string().when('existing', {
                  is: (val) => !val,
                  then: Yup.string(),
                }),
                phoneAreaCode: Yup.string(),
                phoneNumber: Yup.string(),
                cellAreaCode: Yup.string(),
                cellNumber: Yup.string(),
                businessRole: Yup.string(),
                email: Yup.string().email(),
                existing: Yup.bool(),
              });
              return acc;
            }, {}),
          ),
        ),
      }),
    }),
    trustFields: Yup.object().when('contactType', {
      is: (value) => value === 'trust',
      then: TrustSchema.shape({
        additionalContactProps: Yup.lazy((obj) =>
          Yup.object(
            Object.keys(obj || {}).reduce((acc, key) => {
              acc[key] = Yup.object().shape({
                title: Yup.string(),
                firstName: Yup.string().when('existing', {
                  is: (val) => !val,
                  then: Yup.string().required(),
                }),
                lastName: Yup.string().when('existing', {
                  is: (val) => !val,
                  then: Yup.string(),
                }),
                phoneAreaCode: Yup.string(),
                phoneNumber: Yup.string(),
                cellAreaCode: Yup.string(),
                cellNumber: Yup.string(),
                businessRole: Yup.string(),
                email: Yup.string().email(),
                existing: Yup.bool(),
              });
              return acc;
            }, {}),
          ),
        ),
      }),
    }),
  });
};
