import { RECENTLY_VIEWED } from './types';

const INITIAL_FILTER_STATE = {
  recentlyViewed: false,
};

export const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case RECENTLY_VIEWED: {
      return {
        ...state,
        recentlyViewed: !state.recentlyViewed,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
