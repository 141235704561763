/* eslint-disable no-param-reassign */

'use strict';

/**
 * These fee totals are output totals for an invoice (used on the draft invoice screen)
 *
 * Logic here has been copied directly from previous generic function calculateEntryTotals which
 * has been split into two separate functions calculateFeeTotals and calculateExpenseTotals
 */
const calculateFeeTotals = (fees = []) =>
  fees.reduce(
    (total, { isBillable, amountIncludesTax, amount, tax = 0, billableTax = 0, waived, rate, sourceItems = [] }) => {
      if (isBillable) {
        const amountExclTax = amountIncludesTax ? amount - tax : amount;

        total.amountExclTax += waived ? 0 : amountExclTax;
        total.tax += waived ? 0 : tax;
        total.writtenOff += waived ? amountExclTax : 0;
      }
      if (isBillable === null && sourceItems.length) {
        const billableDuration = sourceItems.reduce((acc, item) => acc + (item.billable ? item.duration : 0), 0);
        const billableAmount = (billableDuration * rate) / 60;
        const amountExclTax = amountIncludesTax ? billableAmount - billableTax : billableAmount;

        total.amountExclTax += waived ? 0 : amountExclTax;
        total.tax += waived ? 0 : billableTax;
        total.writtenOff += waived ? amountExclTax : 0;
      }
      return total;
    },
    {
      amountExclTax: 0,
      writtenOff: 0,
      tax: 0,
    },
  );

module.exports = { calculateFeeTotals };
