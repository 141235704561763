import { createSelector } from 'reselect';
import { cacheFactory, indexTypes, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'billing-events',
  keyPath: 'id',
  ngCacheName: 'sbBillingEventService',
  syncType: syncTypes.SINCE,
  indexes: [
    {
      name: 'byRelatedEntityId',
      indexer: (entity) => entity.relatedEntityId,
      type: indexTypes.ONE_TO_MANY,
      reducer: (accumulator, entity) => {
        if (accumulator[entity.type]) {
          accumulator[entity.type].push(entity);
          return accumulator;
        }

        accumulator[entity.type] = [entity];
        return accumulator;
      },
    },
  ],
});

export const { getById, getList, getMap, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;

export const getByRelatedEntityIdAndEventType = (relatedEntityId, billingEventType) => {
  const eventByRelatedEntityId = api.getIndex('byRelatedEntityId');
  if (!eventByRelatedEntityId[relatedEntityId] || !eventByRelatedEntityId[relatedEntityId][billingEventType]) {
    return undefined;
  }

  const eventByRelatedEntityIdAndEventType = eventByRelatedEntityId[relatedEntityId][billingEventType];
  return eventByRelatedEntityIdAndEventType;
};

const getEarliestEntityGroupedByDebtorSelector = createSelector(
  (list) => list || [],
  (billingEvents) =>
    billingEvents.reduce((acc, billingEvent) => {
      if (
        billingEvent.contactId &&
        (!acc[billingEvent.contactId] || acc[billingEvent.contactId].timestamp > billingEvent.timestamp)
      ) {
        acc[billingEvent.contactId] = billingEvent;
      }

      return acc;
    }, {}),
);

export const getEarliestByRelatedIdAndTypeGroupedByDebtor = (relatedEntityId, billingEventType) => {
  const billingEvents = getByRelatedEntityIdAndEventType(relatedEntityId, billingEventType);
  if (!billingEvents) {
    return undefined;
  }

  const earliestEventByDebtor = getEarliestEntityGroupedByDebtorSelector(billingEvents);
  return earliestEventByDebtor;
};
