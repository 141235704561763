import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { StatelessSelect } from '@sb-itops/react/select';

const StaffDropdown = React.memo(
  ({
    onSelectionChange,
    staffMemberOptions,
    selectedStaffId,
    disabled,
    placeholder,
    optionLabelHeight,
    noDefaultStyling,
    hasError,
    selectClassName,
  }) => {
    // Create a thin wrapper around the selectionChange callback to extract out the original activity.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onValueChange = useCallback(
      onSelectionChange ? (selection) => onSelectionChange(selection.entity) : () => {},
      [],
    );

    // this interface is coming from react-select
    const formatOptionLabel = useCallback(({ label }, { context }) => {
      // this can be any JSX
      if (context === 'menu') return <span title={label}>{label}</span>;
      // this can be any JSX
      return label;
    }, []);

    const customStyles = {
      option: (styles, { data }) => {
        if (data?.entity?.isFormerStaff) {
          return {
            ...styles,
            fontStyle: 'italic',
            color: '#aaa',
          };
        }

        return styles;
      },
    };

    return (
      <StatelessSelect
        className={classnames(!noDefaultStyling && 'staff-dropdown', disabled && 'disabled', selectClassName)}
        styles={customStyles}
        styleClass="staff-dropdown"
        onValueChange={onValueChange}
        options={staffMemberOptions}
        selectedOption={selectedStaffId}
        isClearable={false}
        formatOptionLabel={formatOptionLabel}
        optionLabelHeight={optionLabelHeight}
        disabled={disabled}
        placeholder={placeholder}
        hasError={hasError}
      />
    );
  },
);

StaffDropdown.displayName = 'StaffDropdown';

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

StaffDropdown.propTypes = {
  selectClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  noDefaultStyling: PropTypes.bool,
  optionLabelHeight: PropTypes.number,
  placeholder: PropTypes.string,
  selectedStaffId: PropTypes.string,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType).isRequired,
  // Callbacks
  onSelectionChange: PropTypes.func,
};

StaffDropdown.defaultProps = {
  selectClassName: undefined,
  disabled: false,
  hasError: false,
  noDefaultStyling: false,
  optionLabelHeight: undefined,
  placeholder: '',
  selectedStaffId: undefined,
  // Callbacks
  onSelectionChange: undefined,
};

export default StaffDropdown;
