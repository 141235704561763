import { fetchPostP } from '@sb-itops/redux/fetch';
import uuid from '@sb-itops/uuid';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { store } from '@sb-itops/redux';
// eslint-disable-next-line import/no-cycle
import { getById as getBankAccountById } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountService',
  keyPath: 'id',
});

export const saveBankAccount = (bankAccountDetails) => {
  if (!bankAccountDetails?.accountType) {
    throw new Error('Account needs to have accountType specified.');
  }

  const saveBankAccountThunk = async () => {
    let updatedBankAccount = { ...bankAccountDetails, versionId: uuid() };

    const existingBankAccount = getBankAccountById(updatedBankAccount.id);
    if (existingBankAccount) {
      updatedBankAccount = { ...existingBankAccount, ...updatedBankAccount };
    }

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [updatedBankAccount] });

    // Apply the save in the backend.
    try {
      const accountType = bankAccountDetails.accountType.toUpperCase();
      const path = `/billing/bank-account/update/:accountId/${accountType}`;
      const fetchOptions = { body: JSON.stringify(updatedBankAccount) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [updatedBankAccount] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveBankAccountThunk);
};
