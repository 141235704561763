
import { updateCache as updateRedux } from '@sb-matter-management/redux/auto-number-definition';

angular
  .module('@sb-billing/services')
  .service('sbMatterAutoNumberDefinitionService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    sbGenericCacheService.setupCache({
      name: 'sbMatterAutoNumberDefinitionService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'matter-management/auto-number-definition' },
        poll: 60,
        subscriptions: 'notifier.BrowseMattersNotifications.AutoNumberDefinitionUpdated'
      },
      updateRedux
    });
  });
