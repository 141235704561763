import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';

function getNonZeroTrustMatterBalancesMap(allTrustMatterBalances) {
  const nonZeroTrustMatterBalancesMap = allTrustMatterBalances.reduce((acc, matterBalance) => {
    const balanceType = balanceTypes.AVAILABLE;
    if (matterBalance[balanceType] && matterBalance[balanceType] > 0) {
      acc[matterBalance.matterId] = matterBalance;
    }
    return acc;
  }, {});

  return nonZeroTrustMatterBalancesMap;
}

export { getNonZeroTrustMatterBalancesMap };
