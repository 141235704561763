/**
 * @module sb-itops/auth2/actions
 */
import * as types from './types';

export const assumeUserIsAuthenticated = () => ({
  type: types.ASSUME_USER_IS_AUTHENTICATED,
  payload: {},
});

/**
 * forceUserIdentity
 * Forcibly sets the underlying user identity. The intent of this action is for migrating old user identities.
 * TODO: remove after 9th of September 2020
 * @returns {object} forceUserIdentity action
 */
export const forceUserIdentity = ({ userIdentity }) => ({
  type: types.FORCE_USER_IDENTITY,
  payload: {
    userIdentity,
  },
});

/**
 * loginInitiated
 * Creates a loginInitiated redux action.
 * @returns {object} loginInitiated action
 */
export const loginInitiated = () => ({
  type: types.LOGIN_INITIATED,
  payload: {},
});

/**
 * loginChallenged
 * Creates a loginChallenged redux action.
 * @param {Challenge} @sb-itops/business-logic/authentication/challenge
 * @returns {object} loginChallenged action
 */
export const loginChallenged = ({ challenge }) => ({
  type: types.LOGIN_CHALLENGED,
  payload: {
    challenge,
  },
});

/**
 * loginSuccess
 * Creates a loginSuccess redux action.
 * @param {Object} params
 * @param {UserIdentity} params.userIdentity @sb-itops/business-logic/authentication/user-identity
 * @returns {object} loginSuccess action
 */
export const loginSuccess = ({ userIdentity }) => ({
  type: types.LOGIN_SUCCESS,
  payload: { userIdentity },
});

/**
 * loginFailure
 * Creates a loginFailure redux action.
 * @param {Object} [params]
 * @param {Object} [params.failureDetails]
 * @returns {object} loginFailure action
 */
export const loginFailure = ({ failureDetails } = {}) => ({
  type: types.LOGIN_FAILURE,
  payload: {
    failureDetails,
  },
});

/**
 * challengeResponseInitiated
 * Creates a challengeResponseInitiated redux action.
 * @returns {object} challengeResponseInitiated action
 */
export const challengeResponseInitiated = () => ({
  type: types.CHALLENGE_RESPONSE_INITIATED,
  payload: {},
});

/**
 * challengeResponseFailure
 * Creates a challengeResponseFailure redux action.
 * @returns {object} challengeResponseFailure action
 */
export const challengeResponseFailure = () => ({
  type: types.CHALLENGE_RESPONSE_FAILURE,
  payload: {},
});

/**
 * refreshTokenInitiated
 * Creates a refreshTokenInitiated redux action.
 * @returns {object} refreshTokenInitiated action
 */
export const refreshTokenInitiated = () => ({
  type: types.REFRESH_TOKEN_INITIATED,
  payload: {},
});

/**
 * refreshTokenSuccess
 * Creates a refreshTokenSuccess redux action.
 * @returns {object} refreshTokenSuccess action
 */
export const refreshTokenSuccess = ({ userIdentity }) => ({
  type: types.REFRESH_TOKEN_SUCCESS,
  payload: {
    userIdentity,
  },
});

/**
 * refreshTokenFailure
 * Creates a refreshTokenFailure redux action.
 * @returns {object} refreshTokenFailure action
 */
export const refreshTokenFailure = () => ({
  type: types.REFRESH_TOKEN_FAILURE,
  payload: {},
});

/**
 * logoutInitiated
 * Creates a logoutInitiated redux action.
 * @returns {object} logoutInitiated action
 */
export const logoutInitiated = () => ({
  type: types.LOGOUT_INITIATED,
  payload: {},
});

/**
 * logoutSuccess
 * Creates a logoutSuccess redux action.
 * @returns {object} logoutSuccess action
 */
export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
  payload: {},
});

/**
 * logoutFailure
 * Creates a logoutFailure redux action.
 * @returns {object} logoutFailure action
 */
export const logoutFailure = () => ({
  type: types.LOGOUT_FAILURE,
  payload: {},
});
