/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react';
import classNames from 'classnames';
import { ContextMenu } from '@sb-itops/react';
import { WidgetSettings } from './WidgetSettings';
import * as Styles from './WidgetTitle.module.scss';
import { useOutsideAlerter } from './hooks';
import { WIDGET_DEFAULTS } from './WidgetDefaults';

interface Props {
  isEditing: boolean;
  onRefresh?: Function;
  onChange: Function;
  onRemove: Function;
  onClick?: React.MouseEventHandler;
  settings: any;
  children?: React.ReactNode;
}

export const WidgetTitle = ({ isEditing, settings, onRefresh, onChange, onRemove, onClick }: Props) => {
  const titleRef = useRef(null);
  const settingsButtonId = `widget-settings-open-${settings.id}`;
  const stopDrag = (event) => {
    // Prevent dragging component accidentlly while trying to edit settings
    // event.preventDefault();
    event.stopPropagation();
  };

  useOutsideAlerter(titleRef, onChange, [settingsButtonId]);
  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.bar}>
          <div className={classNames(!isEditing && onClick && Styles.title)} onClick={!isEditing ? onClick : undefined}>
            {isEditing && settings.rename ? (
              <input
                className={Styles.input}
                value={(settings.title || '').toUpperCase()}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onBlur={() => {
                  onChange({ rename: !settings.rename });
                }}
                onChange={(e) => {
                  onChange({ title: e.target.value });
                }}
              />
            ) : (
              settings.title
            )}
          </div>
          <div className={Styles.icons}>
            {!isEditing && onRefresh && (
              <i onClick={() => onRefresh({})} className={`${Styles.icon} icon icon-refresh`} />
            )}
            {isEditing && (
              <ContextMenu
                distance={5}
                position="top-right"
                arrow={false}
                className={Styles.dropdown}
                body={({ close }) => (
                  <div className="create-bill-context-menu-body list-group">
                    {WIDGET_DEFAULTS[settings.type]?.showSettings && (
                      <div
                        className="list-group-item"
                        onClick={() => {
                          onChange({ show: !settings.show });
                          close();
                        }}
                      >
                        Configure
                      </div>
                    )}
                    <div
                      className="list-group-item"
                      onClick={() => {
                        onChange({ rename: !settings.rename });
                        close();
                      }}
                    >
                      Rename
                    </div>
                    <div
                      className="list-group-item"
                      onClick={() => {
                        onRemove();
                        close();
                      }}
                    >
                      Delete
                    </div>
                  </div>
                )}
              >
                <i onMouseDown={stopDrag} id={settingsButtonId} className={`${Styles.icon} icon icon-kebab-fill`} />
              </ContextMenu>
            )}
          </div>
        </div>
        {settings.show && (
          <div
            onMouseDown={stopDrag}
            ref={titleRef}
            className={Styles.settings}
            style={settings.position.x + settings.size.x > 5 ? { right: '25px' } : { left: 'calc(100% + 5px)' }}
          >
            <WidgetSettings onChange={onChange} settings={settings} />
          </div>
        )}
      </div>
      {isEditing && <div className={Styles.editShield} />}
    </>
  );
};
