import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  forms2PropTypes,
  Button,
  buttonTypes,
  FloatingCard,
  SlidingToggle,
  CurrencyInput2,
  FormLabel,
  DropDownList,
  TitleDropdown,
  Modal,
  useTranslation,
} from '@sb-itops/react';
import { getRegion } from '@sb-itops/region';
import { testingGetAllValuesForKey } from '@sb-itops/region-schemes';
import { featureActive } from '@sb-itops/feature';
import { getPersonTitles } from '@sb-customer-management/business-logic/contacts/services/get-person-titles';
import { phonePlaceholders } from '@sb-customer-management/business-logic/contacts/entities/constantsTypescript';
import { hasPhoneAreaCode } from '@sb-customer-management/business-logic/contacts/services/has-phone-number-prefix';
import { getRoles, timekeeperClassificationOptions } from '@sb-firm-management/business-logic/firms';
import { StaffColorPicker } from 'web/components/staff-color-picker/StaffColorPicker';
import Styles from './AddStaffUser.module.scss';

const REGION = getRegion();

const roleOptions = getRoles(REGION).map((role) => ({ label: role, value: role }));

const titleOptions = getPersonTitles({ includeTopLabelOption: false, region: REGION });

const AddStaffUser = React.memo(
  ({
    onFieldValueUpdated,
    firstName,
    lastName,
    title,
    validateForm,
    formDisabled,
    submitFailed,
    phoneAreaCode,
    phoneNumber,
    cellAreaCode,
    cellNumber,
    formInitialised,
    isFormerStaff,
    // twoFA,
    sbAccess,
    enterTimeAsUnits,
    timekeeperClassificationCode,
    email,
    hourlyRate,
    onSave,
    onClose,
    id,
    role,
    initials,
    colorFill,
    colorStroke,
    isUtbmsEnabled,
    phoneNumberScheme,
    showFormerStaffField,
    showSmokeballAccessField,
    showFormerStaffModal,
    showUserConflictModal,
    setShowUserConflictModal,
    onIsFormerStaffModalCancel,
    onIsFormerStaffModalProceed,
    onIsFormerStaffFieldUpdated,
  }) => {
    const { t } = useTranslation();
    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

    if (!formInitialised) {
      return null;
    }

    return (
      <div className={Styles.addStaffUser}>
        <FloatingCard applyMargin={false}>
          <fieldset disabled={formDisabled}>
            <h3 className={Styles.title}>{id?.value ? 'Edit Staff Member' : 'Add New Staff'}</h3>
            <div className="row">
              <div className="col-xs-2">
                <label>Title</label>
                <TitleDropdown
                  selectedTitle={title?.value}
                  options={titleOptions}
                  disabled={formDisabled}
                  onChange={({ value }) => {
                    onFieldValueUpdated({ title: value });
                  }}
                  className={classnames(isErrorClassnames(title))}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-5">
                <FormLabel label="First Name" field={firstName} submitFailed={submitFailed} uppercase={false} />
                <input
                  name="firstName"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(firstName))}
                  value={firstName?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ firstName: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-5">
                <FormLabel label="Last Name" field={lastName} submitFailed={submitFailed} uppercase={false} />
                <input
                  name="lastName"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(lastName))}
                  value={lastName?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ lastName: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-2">
                <FormLabel label="Initials" field={initials} submitFailed={submitFailed} uppercase={false} />
                <input
                  name="initials"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(initials))}
                  value={initials?.value || `${firstName.value[0] || ''}${lastName.value[0] || ''}`.toUpperCase()}
                  onChange={(e) => {
                    onFieldValueUpdated({ initials: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-10">
                <label>Role</label>
                <DropDownList
                  selectedOption={roleOptions.find(
                    (option) => option.value === role?.value || option.label === role?.value,
                  )}
                  options={roleOptions}
                  disabled={formDisabled}
                  onChange={({ value }) => {
                    onFieldValueUpdated({ role: value });
                  }}
                  className={classnames(isErrorClassnames(role))}
                  onBlur={validateForm}
                  stateless
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <FormLabel label="Email Address" field={email} submitFailed={submitFailed} uppercase={false} />
                <input
                  name="email"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(email))}
                  value={email.value}
                  onChange={(e) => {
                    onFieldValueUpdated({ email: e.target.value });
                  }}
                  onBlur={validateForm}
                />
                {email.isInvalid && email.invalidReason?.includes('valid email') && (
                  <span className={Styles.errorMessage}>Invalid email format</span>
                )}
                {email.isInvalid && email.invalidReason?.includes('already been used') && (
                  <span className={Styles.errorMessage}>{email.invalidReason}</span>
                )}
              </div>
            </div>
            <div className="row">
              {hasPhoneAreaCode(phoneNumberScheme) && (
                <div className="col-xs-3">
                  <label>Area Code</label>
                  <input
                    name="phoneAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(phoneAreaCode))}
                    value={phoneAreaCode?.value || ''}
                    placeholder={phonePlaceholders.phoneAreaCode[phoneNumberScheme]}
                    onChange={(e) => {
                      onFieldValueUpdated({ phoneAreaCode: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              )}

              <div className={hasPhoneAreaCode(phoneNumberScheme) ? 'col-xs-6' : 'col-xs-9'}>
                <label>Phone Number</label>
                <input
                  name="phoneNumber"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(phoneNumber))}
                  value={phoneNumber?.value || ''}
                  placeholder={phonePlaceholders.phoneNumber[phoneNumberScheme]}
                  onChange={(e) => {
                    onFieldValueUpdated({ phoneNumber: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
            <div className="row">
              {hasPhoneAreaCode(phoneNumberScheme) && (
                <div className="col-xs-3">
                  <label>Area Code</label>
                  <input
                    name="cellAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(cellAreaCode))}
                    value={cellAreaCode?.value || ''}
                    placeholder={phonePlaceholders.cellAreaCode[phoneNumberScheme]}
                    onChange={(e) => {
                      onFieldValueUpdated({ cellAreaCode: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              )}
              <div className={hasPhoneAreaCode(phoneNumberScheme) ? 'col-xs-6' : 'col-xs-9'}>
                <label>Mobile Number</label>
                <input
                  name="cellNumber"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(cellNumber))}
                  value={cellNumber?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ cellNumber: e.target.value });
                  }}
                  placeholder={phonePlaceholders.cellNumber[phoneNumberScheme]}
                  onBlur={validateForm}
                />
              </div>
            </div>
            {featureActive('NUCWEB-436') && (
              <div className="row">
                <div className="col-xs-3">
                  <label>{t('color')}</label>
                  <StaffColorPicker
                    colorFill={colorFill.value}
                    colorStroke={colorStroke.value}
                    onChange={(fill, stroke) => {
                      onFieldValueUpdated({ colorFill: fill });
                      onFieldValueUpdated({ colorStroke: stroke });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xs-3">
                <label>Hourly Rate</label>
                <CurrencyInput2
                  onChange={(e) => {
                    onFieldValueUpdated({ hourlyRate: e.target.value });
                  }}
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  value={+hourlyRate?.value}
                  hasError={submitFailed && hourlyRate?.isInvalid}
                />
              </div>
            </div>
            {isUtbmsEnabled && (
              <div className="row">
                <div className="col-xs-9">
                  <label>Timekeeper Classification</label>
                  <DropDownList
                    selectedOption={timekeeperClassificationOptions.find(
                      (option) =>
                        option.value === timekeeperClassificationCode?.value ||
                        option.label === timekeeperClassificationCode?.value,
                    )}
                    options={timekeeperClassificationOptions}
                    disabled={formDisabled}
                    onChange={({ value }) => {
                      onFieldValueUpdated({ timekeeperClassificationCode: value });
                    }}
                    className={classnames(isErrorClassnames(timekeeperClassificationCode))}
                    onBlur={validateForm}
                    stateless
                  />
                </div>
              </div>
            )}
            <div className={classnames('row', Styles.enterTimeAsUnits)}>
              <div className="col-xs-6">
                <SlidingToggle
                  scope="unit-time-entries"
                  onChange={(name, value) => onFieldValueUpdated({ enterTimeAsUnits: value })}
                  selected={enterTimeAsUnits.value}
                />
                <div
                  onClick={() => onFieldValueUpdated({ enterTimeAsUnits: !enterTimeAsUnits.value })}
                  className={Styles.toggleLabel}
                >
                  Enter Time Entries as Units
                </div>
              </div>
            </div>
            {showSmokeballAccessField && (
              <div className="row">
                <div className="col-xs-6">
                  <SlidingToggle
                    scope="sb-access-toggle"
                    onChange={(name, value) => onFieldValueUpdated({ sbAccess: value })}
                    selected={sbAccess.value}
                    disabled={isFormerStaff.value}
                  />
                  <div
                    onClick={() =>
                      isFormerStaff.value ? undefined : onFieldValueUpdated({ sbAccess: !sbAccess.value })
                    }
                    className={classnames(Styles.toggleLabel, isFormerStaff.value && Styles.toggleLabelDisabled)}
                  >
                    {`Allow Smokeball Access (uses a ${t('license')})`}{' '}
                  </div>
                </div>
              </div>
            )}
            {/*
              12/01/2022 Currently not available. Will be added in a future ticket
              <div className="row">
                <div className="col-xs-6">
                  <SlidingToggle
                    scope="twoFA-toggle"
                    onChange={(name, value) => onFieldValueUpdated({ twoFA: value })}
                    selected={twoFA}
                  />
                  <div className={Styles.toggleLabel}>Use Two Factor Authentication (2FA)</div>
                </div>
              </div> */}
            {showFormerStaffField && (
              <div className="row">
                <div className="col-xs-6">
                  {showFormerStaffModal && (
                    <StaffModal
                      title="Set as former staff"
                      body={
                        <div>
                          <p>Setting this user as former staff will also remove their Smokeball access.</p>
                          <p>Would you like to continue?</p>
                        </div>
                      }
                      proceedButtonType={buttonTypes.warning}
                      onProceed={onIsFormerStaffModalProceed}
                      onCancel={onIsFormerStaffModalCancel}
                    />
                  )}
                  <SlidingToggle
                    scope="former-staff-toggle"
                    onChange={onIsFormerStaffFieldUpdated}
                    selected={isFormerStaff.value}
                  />
                  <div onClick={onIsFormerStaffFieldUpdated} className={Styles.toggleLabel}>
                    Is a Former Staff Member
                  </div>
                </div>
              </div>
            )}
            {showUserConflictModal && (
              <UserConflictModal
                title={
                  <>
                    <i className={classnames('fa fa-warning', Styles.warning)} />
                    {`Email already linked to ${t('licence')}d user`}
                  </>
                }
                body={
                  <div>
                    <p>
                      A {t('licence')}d staff member is already using this email. You may save the staff member again
                      but they will not be able to log in to Smokeball.
                    </p>
                    <p>
                      If you would like to enable log in access for this user using this email address, contact{' '}
                      <a href={`tel:${t('sbPhoneNumber')}`}>Smokeball Support</a>
                    </p>
                  </div>
                }
                onProceed={(e) => {
                  setShowUserConflictModal(false);
                  onFieldValueUpdated({ sbAccess: false });
                  onSave(e);
                }}
                onCancel={onClose}
              />
            )}
          </fieldset>
          <div className={Styles.formFooter}>
            <Button
              locked={formDisabled}
              disabled={formDisabled}
              className={Styles.saveButton}
              size="full-width"
              onClick={onSave}
            >
              Save
            </Button>
            <Button
              disabled={formDisabled}
              className={Styles.cancelButton}
              size="full-width"
              type="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </FloatingCard>
      </div>
    );
  },
);

const UserConflictModal = ({ body, title, onProceed, onCancel }) => (
  <Modal
    className={Styles.staffModal}
    isVisible
    title={title}
    body={body}
    footer={
      <div className={Styles.buttonPanel}>
        <Button className={Styles.modalButton} type={buttonTypes.primary} onClick={onProceed}>
          SAVE WITHOUT ACCESS
        </Button>
        <Button className={Styles.modalButton} type={buttonTypes.secondary} onClick={onCancel}>
          CANCEL
        </Button>
      </div>
    }
    onModalClose={onCancel}
  />
);

const StaffModal = ({ body, title, proceedButtonType, onProceed, onCancel }) => (
  <Modal
    className={Styles.staffModal}
    isVisible
    title={title}
    body={body}
    footer={
      <div className={Styles.buttonPanel}>
        <Button className={Styles.modalButton} type={proceedButtonType} onClick={onProceed}>
          Yes
        </Button>
        <Button className={Styles.modalButton} type={buttonTypes.secondary} onClick={onCancel}>
          No
        </Button>
      </div>
    }
    onModalClose={onCancel}
  />
);

AddStaffUser.displayName = 'AddStaffUser';

const { Forms2Field } = forms2PropTypes;

AddStaffUser.propTypes = {
  showUserConflictModal: PropTypes.bool.isRequired,
  setShowUserConflictModal: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  id: Forms2Field,
  firstName: Forms2Field,
  lastName: Forms2Field,
  title: Forms2Field,
  onSave: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  isUtbmsEnabled: PropTypes.bool,
  phoneNumberScheme: PropTypes.oneOf(testingGetAllValuesForKey('phoneNumberScheme')).isRequired,
  phoneAreaCode: Forms2Field,
  phoneNumber: Forms2Field,
  cellAreaCode: Forms2Field,
  cellNumber: Forms2Field,
  formInitialised: PropTypes.bool.isRequired,
  isFormerStaff: Forms2Field,
  twoFA: Forms2Field,
  sbAccess: Forms2Field,
  colorFill: Forms2Field,
  colorStroke: Forms2Field,
  enterTimeAsUnits: Forms2Field,
  timekeeperClassificationCode: Forms2Field,
  email: Forms2Field,
  hourlyRate: Forms2Field,
  role: Forms2Field,
  initials: Forms2Field,
  showFormerStaffField: PropTypes.bool.isRequired,
  showSmokeballAccessField: PropTypes.bool.isRequired,
  showFormerStaffModal: PropTypes.bool.isRequired,
  onIsFormerStaffModalCancel: PropTypes.func.isRequired,
  onIsFormerStaffModalProceed: PropTypes.func.isRequired,
  onIsFormerStaffFieldUpdated: PropTypes.func.isRequired,
};

AddStaffUser.defaultProps = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  title: undefined,
  phoneAreaCode: undefined,
  phoneNumber: undefined,
  cellAreaCode: undefined,
  cellNumber: undefined,
  isFormerStaff: undefined,
  twoFA: undefined,
  sbAccess: undefined,
  colorFill: undefined,
  colorStroke: undefined,
  enterTimeAsUnits: undefined,
  timekeeperClassificationCode: undefined,
  isUtbmsEnabled: undefined,
  email: undefined,
  hourlyRate: undefined,
  role: undefined,
  initials: undefined,
};

export default AddStaffUser;
