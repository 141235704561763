import React, { forwardRef } from 'react';

export const SbClientSdkIframe = forwardRef<HTMLIFrameElement, { sdkAppUrl: string }>((props, ref) => {
  const style: React.HTMLAttributes<HTMLIFrameElement>['style'] = {
    top: `0`,
    left: `0`,
    width: `100%`,
    height: `100%`,
  };

  const iframeProps = {
    style,
    id: 'SDKIframe',
    src: props.sdkAppUrl,
  };

  return <iframe ref={ref} {...iframeProps} title="sdkEmbedded" allow="clipboard-write" />;
});
