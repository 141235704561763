'use strict';

const { hasFacet, facets } = require('@sb-itops/region-facets');
const { transactionType } = require('../transactions/entities/constants');

const {
  Deposit,
  DepositReversal,
  InvoicePayment,
  InvoicePaymentReversal,
  MatterAdjustment,
  MatterAdjustmentReversal,
  VendorPayment,
  VendorPaymentReversal,
  BankFees,
  BankFeesReversal,
  Interest,
  InterestReversal,
} = transactionType;

const depositTypes = [
  Deposit,
  DepositReversal,
  ...(hasFacet(facets.trustAccountInterest) ? [Interest, InterestReversal] : []),
];

const paymentTypes = [
  InvoicePayment,
  InvoicePaymentReversal,
  VendorPayment,
  VendorPaymentReversal,
  ...(hasFacet(facets.trustAccountBankFee) ? [BankFees, BankFeesReversal] : []),
];

const reconcileableTypes = [...depositTypes, ...paymentTypes, MatterAdjustment, MatterAdjustmentReversal];

const statusByValue = Object.freeze({
  0: 'IN_PROGRESS',
  1: 'COMPLETED',
  2: 'CANCELLED',
});

const statusByName = Object.freeze({
  IN_PROGRESS: 0,
  COMPLETED: 1,
  CANCELLED: 2,
});

module.exports = {
  reconcileableTypes,
  depositTypes,
  paymentTypes,
  statusByValue,
  statusByName,
};
