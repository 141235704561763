import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '../button';
import { ExpandCollapseButton } from '../expand-collapse-button';
import Styles from './PanelExpander.module.scss';

// Note: The reason why we visually hide the children rather than prevent
// rendering them is because the children may contain redux state that is
// shared with adjacent components (eg, Matter filters and Matter table)
// The cause is that the PanelExpander state is persisted between browser
// refreshes via `registerFeature(panelExpander, { persist: true })` in
// monorepo/apps/smokeball-billing-web/src/services/bootstrapper/register-redux-reducers.js
// See: https://smokeball.atlassian.net/browse/BB-7746

const PanelExpander = memo(
  ({
    expanded,
    className,
    containerClassName,
    children,
    toggleExpanded,
    title,
    onResetFilters,
    reverse,
    fullWidth,
  }) => (
    <>
      <div
        className={classnames({
          [className]: true,
          [Styles.collapsed]: !expanded,
          [Styles.expanded]: expanded,
          [Styles.notReversed]: !reverse,
          [Styles.fullWidth]: fullWidth,
        })}
      >
        <div className={classnames(Styles.header, !expanded && Styles.hideContents, containerClassName)}>
          {children}
        </div>
        {!expanded && (
          <div onClick={() => toggleExpanded(!expanded)} className={Styles.header}>
            <h1>{title}</h1>
          </div>
        )}
      </div>
      <div
        className={classnames({
          [Styles.collapsed]: !expanded,
          [Styles.expanded]: expanded,
          [Styles.notReversed]: !reverse,
          [Styles.fullWidth]: fullWidth,
          [Styles.expandCollapseContainer]: true,
        })}
      >
        {onResetFilters && expanded && (
          <div className={Styles.resetButton}>
            <Button type="secondary" onClick={onResetFilters}>
              Reset Filters
            </Button>
          </div>
        )}
        <div className={Styles.expandCollapse}>
          <ExpandCollapseButton
            onExpandCollapseClick={() => toggleExpanded(!expanded)}
            reverse={reverse}
            open={expanded}
          />
        </div>
      </div>
    </>
  ),
);

PanelExpander.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onResetFilters: PropTypes.func,
  reverse: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

PanelExpander.defaultProps = {
  containerClassName: '',
  className: '',
  onResetFilters: undefined,
  title: 'View Filters',
  reverse: true,
  fullWidth: false,
};

export default PanelExpander;
