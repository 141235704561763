import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import {
  CREDIT_NOTE_MODAL_ID,
  ADD_PAYMENT_MODAL_ID,
  ADD_LEAD_MODAL_ID,
  CONTACT_CREATE_EDIT_MODAL_ID,
  DEPOSIT_FUNDS_MODAL_ID,
  ADD_MATTER_WIZARD_MODAL_ID,
} from 'web/react-redux';
import { ADD_EVENT_MODAL_ID, ADD_TASK_MODAL_ID, ADD_PHONE_MESSAGE_MODAL_ID } from 'web/containers';
import { EXPENSE_MODAL_ID, FEE_MODAL_ID, ADD_PAYMENT_MODAL_ID as ADD_PAYMENT_NEW_MODAL_ID, DEPOSIT_FUNDS_MODAL_ID as DEPOSIT_FUNDS_NEW_MODAL_ID } from 'web/components';
import { isNewTheme } from 'web/services/theme';
import { hasBillingAccess } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').component('sbQuickAdd', {
  'templateUrl': 'ng-components/quick-add/quick-add.html',
  'controller': function ($scope, $state, $uibModal, sbLinkService, sbLocalisationService, sbAsyncOperationsService, sbExpenseService) {
    'use strict';

    var that = this;
    var isPopoverOpen = false;
    that.sbData = {};

    that.disableLODPaymentModal = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-13936');

    that.depositModal = {};

    that.hasBillingAccess = hasBillingAccess;
    that.isOpen = isOpen;
    that.open = open;
    that.close = close;
    that.openExpenseModal = openExpenseModal;
    that.openFeeModal = openFeeModal;
    that.openModal = openModal;
    that.openAddMatter = openAddMatter;
    that.closeModal = closeModal;
    that.dataChangeFunction = dataChangeFunction;
    that.openAddEvent = openAddEvent;
    that.openAddCredit = openAddCredit;
    that.openAddPayment = openAddPayment;
    that.openAddTask = openAddTask;
    that.openAddPhoneMessage = openAddPhoneMessage;
    that.openAddLead = openAddLead;
    that.openAddContact = openAddContact;
    that.openDepositModal = openDepositModal; // New react deposit funds
    that.onClickLink = sbLinkService.onClickLink;
    that.onClickLinks = sbLinkService.onClickLinks;
    that.t = sbLocalisationService.t;
    that.isNewTheme = isNewTheme();

    function isOpen() {
      return isPopoverOpen;
    }

    function open() {
      isPopoverOpen = true;
    }

    function close() {
      isPopoverOpen = false;
    }

    function dataChangeFunction(key, doc) {
      that.sbData[key] = doc && doc.data;
    }

    function openExpenseModal() {
      setModalDialogVisible({
        modalId: EXPENSE_MODAL_ID,
        props: {
          scope: 'GlobalQuickAdd/expense-modal',
          sbAsyncOperationsService,
        },
      });
    }

    function openFeeModal(matterId) {
      // Matter Page > Overview tab > Billing Widget
      if (matterId) {
        setModalDialogVisible({ modalId: FEE_MODAL_ID, props: { matterId, scope: 'MatterPageOverview/fee-modal' } });
        return;
      }

      // Global Quick Fee Add
      setModalDialogVisible({ modalId: FEE_MODAL_ID, props: { scope: 'GlobalQuickAdd/fee-modal' } });
    }

    function openDepositModal() {
      if (featureActive('BB-14323')) {
        setModalDialogVisible({ modalId: DEPOSIT_FUNDS_NEW_MODAL_ID, props: { scope: 'GlobalQuickAdd/deposit-funds-modal' } });
      } else {
        setModalDialogVisible({ modalId: DEPOSIT_FUNDS_MODAL_ID });
      }
    }
    function openAddTask() {
      setModalDialogVisible({ modalId: ADD_TASK_MODAL_ID });
    }
    function openAddPhoneMessage() {
      setModalDialogVisible({ modalId: ADD_PHONE_MESSAGE_MODAL_ID });
    }
    function openAddCredit() {
      setModalDialogVisible({ modalId: CREDIT_NOTE_MODAL_ID });
    }
    function openAddEvent() {
      setModalDialogVisible({ modalId: ADD_EVENT_MODAL_ID });
    }
    function openAddLead() {
      setModalDialogVisible({ modalId: ADD_LEAD_MODAL_ID });
    }
    function openAddPayment() {
      if (!that.disableLODPaymentModal) {
        setModalDialogVisible({ modalId: ADD_PAYMENT_NEW_MODAL_ID, props: { scope: 'GlobalQuickAdd/add-payment-modal' } });
      } else {
        // Non-LOD version
        setModalDialogVisible({ modalId: ADD_PAYMENT_MODAL_ID });
      }
    }
    function openAddContact() {
      setModalDialogVisible({ modalId: CONTACT_CREATE_EDIT_MODAL_ID });
    }
    
    function openAddMatter() {
      if (featureActive('NUCWEB-735')) {
        setModalDialogVisible({ modalId: ADD_MATTER_WIZARD_MODAL_ID });
      } else {
        $state.go('home.billing.add-matter');
      }
    }

    function openModal(id) {
      that.showModal = id;
    }

    function closeModal() {
      that.showModal = undefined;
    }
  }
});
