'use strict';

const fuzzyTime = require('./fuzzy-time');
const tax = require('./tax');
const { isAutoTimeEntry } = require('./is-auto-time-entry');
const { convertDurationToType } = require('./convert-duration-to-type');
const { formatDollarAmountForDisplay } = require('./format-dollar-amount-for-display');

module.exports = {
  fuzzyTime,
  tax,
  isAutoTimeEntry,
  formatDollarAmountForDisplay,
  convertDurationToType,
};
