/**
 * @typedef {object} MatterTotals
 * @property {string} accountId
 * @property {string} matterId
 * @property {string} lastUpdated
 * @property {number} unbilled
 * @property {number} unbilledTax
 * @property {number} billable
 * @property {number} billed
 * @property {number} billedTax
 * @property {number} draft
 * @property {number} paid
 * @property {number} unpaid
 * @property {number} writtenOff
 * @property {number} discount
 * @property {number} waived
 * @property {number} interest
 * @property {number} unpaidExcInterest
 * @property {boolean} isFromServer
 */

/**
 * @typedef {object} MatterTypeaheadSummary
 * @property {string} id matterId
 * @property {string} display
 * @property {Date} matterStarted
 * @property {string} matterStartedISO
 * @property {string} matterClientNames
 * @property {string} status e.g. Open
 * @property {string} typeahead value to search for typeahead filtering
 * @property {MatterTotals} matterTotals
 * @property {boolean} hasFunds
 */

/**
 * @typedef {object} QueryPagination
 * @property {number} pageSize maximum page size (limit)
 * @property {string} field name of the array field being paginated
 */

/**
 * @typedef {object} SubscribedQuery
 * @property {object} query
 * @property {string[]} notificationIds
 * @property {function} [onOpdateQuery]
 * @property {string[]} [rootFieldsToEvict]
 */

module.exports = {};
