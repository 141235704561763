import { fetchPostP } from '@sb-itops/redux/fetch';
import { useEffect } from 'react';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('record-metrics');

export const sendMetric = async (name: string, properties?: { [key: string]: number | string }) => {
  try {
    const path = `/itops/metrics/event/:accountId/`;
    const body = JSON.stringify({
      eventType: name,
      properties,
    });

    await fetchPostP({ path, fetchOptions: { body } });
  } catch (err) {
    log.warn('Failed to record metrics', err);
  }
};

export const useMetric = (name: string, properties?: { [key: string]: number | string }) => {
  useEffect(() => {
    sendMetric(name, properties);
  }, []);
};
