'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');
const {
  billingFrequencyAnnuallySubType,
  billingFrequencyAnnuallySubTypeLabels,
} = require('./billing-frequency-annually-sub-type');
const {
  billingFrequencyMonthlySubType,
  billingFrequencyMonthlySubTypeLabels,
} = require('./billing-frequency-monthly-sub-type');
const {
  billingFrequencyQuarterlySubType,
  billingFrequencyQuarterlySubTypeLabels,
} = require('./billing-frequency-quarterly-sub-type');
const { billingFrequencyHoldSubType, billingFrequencyHoldSubTypeLabels } = require('./billing-frequency-hold-sub-type');

const billingFrequencySubType = Object.freeze({
  NONE: 0,
  ...billingFrequencyAnnuallySubType,
  ...billingFrequencyMonthlySubType,
  ...billingFrequencyQuarterlySubType,
  ...billingFrequencyHoldSubType,
});

const billingFrequencySubTypeLabels = Object.freeze({
  NONE: 'None',
  ...billingFrequencyAnnuallySubTypeLabels,
  ...billingFrequencyMonthlySubTypeLabels,
  ...billingFrequencyQuarterlySubTypeLabels,
  ...billingFrequencyHoldSubTypeLabels,
});

const billingFrequencySubTypeOptions = getTypeAsOptions({
  typeLabels: billingFrequencySubTypeLabels,
  typeValues: billingFrequencySubType,
});

module.exports = {
  billingFrequencySubType,
  billingFrequencySubTypeLabels,
  billingFrequencySubTypeOptions,
};
