import { updateCache as updateRedux,  clearCache as clearRedux, } from '@sb-billing/redux/matter-communication-settings';

angular
  .module('@sb-billing/services')
  .service('sbMatterCommunicationSettingsService', function(
    sbGenericCacheService,
    sbEndpointType
  ) {
    'use strict';

    sbGenericCacheService.setupCache({
      name: 'sbMatterCommunicationSettingsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/matter-communication-settings' },
        poll: 60,
        subscriptions: 'notifier.BillingMattersNotifications.MatterCommunicationSettingsUpdated'
      },
      updateRedux,
      clearRedux
    });
  });
