// This component has been rebuilt into React under monorepo/billing/react/edit-billing-unit-form
// Keep the whole fee-interval-edit folder for now in case we need to roll back

angular.module('sb.billing.webapp').component('sbFeeIntervalEdit',
  {
    'templateUrl': 'ng-components/fee-interval-edit/fee-interval-edit.html',
    'controller': function($scope, sbFeeConfigurationService, sbMessageDisplayService) {
      'use strict';

      const ctrl = this;
      const pluralizeMinutes = (mins) => `minute${mins === 1 ? '' : 's'}`;

      ctrl.$onInit = () => {
        ctrl.save = save;

        $scope.$on('smokeball-data-update-sbFeeIntervalEdit', update);

        update();
      };

      function update(event, eventParam) {
        let current0 = ctrl.current && ctrl.current.mins || null;
        let current = sbFeeConfigurationService.getFeeInterval();

        ctrl.intervals = _.map(sbFeeConfigurationService.getAllowedIntervals(), mins => {
          let option = {mins: mins, display: mins + ` ${pluralizeMinutes(mins)}`};

          if (current === mins) {
            ctrl.current = option;
            ctrl.selected = option;
          }

          return option;
        });

        if (current0 && current0 !== current && eventParam === 'BILLING_UNIT_SYNC') {
          sbMessageDisplayService.success(`Billing Units auto-synched to ${ctrl.selected.mins} minutes.`);
        }

      }

      function save() {
        sbFeeConfigurationService.setFeeIntervalP(ctrl.selected.mins)
          .then(() => {
            sbMessageDisplayService.success(`Billing units set to ${ctrl.selected.mins} ${pluralizeMinutes(ctrl.selected.mins)}.`);
          });
        ctrl.current = ctrl.selected;
      }
    }
  });
