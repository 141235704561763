import React from 'react';
import PropTypes from 'prop-types';
import CurrencyDisplay from './CurrencyDisplay';

function calculateStyle(initialStyleClass, customClasses) {
  return `${initialStyleClass || ''} ${customClasses}`;
}

const CurrencyDisplayBridge = (props) => (
  <CurrencyDisplay
    amount={props.amountInCents}
    hideDollar={props.hideDollar}
    styleClass={calculateStyle(props.initialStyleClass, props.customClasses)}
    region={props.region}
  />
);
CurrencyDisplayBridge.propTypes = {
  amountInCents: PropTypes.number,
  hideDollar: PropTypes.bool,
  initialStyleClass: PropTypes.string,
  customClasses: PropTypes.string,
  region: PropTypes.string,
};

CurrencyDisplayBridge.defaultProps = {
  amountInCents: undefined,
  hideDollar: false,
  initialStyleClass: undefined,
  customClasses: undefined,
  region: undefined,
};

export default CurrencyDisplayBridge;
