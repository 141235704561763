angular.module('sb.billing.webapp').directive('sbActivityList', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'scope': { onSelect: '&?'},
    'controller': 'SbActivityListController',
    'controllerAs': 'activityList',
    'templateUrl': 'ng-components/activity-list/activity-list.html'
  };
});
