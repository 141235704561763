import { sortByProperty } from '@sb-itops/nodash';

export const getAccountOptionsForBankAccountId = ({ accountMappings, bankAccountId, lawpayBankAccounts }) => {
  const mappings = accountMappings.filter((m) => m.sbbBankAccountId === bankAccountId);
  const options = mappings.reduce((opts, m) => {
    const lawpayBankAccount = lawpayBankAccounts.find((acct) => acct.id === m.lawpayBankAccountId);
    if (lawpayBankAccount) {
      // eslint-disable-next-line no-param-reassign
      opts[lawpayBankAccount.type] = {
        label: lawpayBankAccount.name,
        value: lawpayBankAccount.id,
        isTrust: lawpayBankAccount.trustAccount,
        type: lawpayBankAccount.type,
      };
    }

    return opts;
  }, {});

  return options;
};

export const formatBankAccounts = ({
  bankAccounts,
  lawpayBankAccounts,
  accountMappings,
  getBankAccountName,
  isTrustAccountClosed,
  t,
}) => {
  const formattedAccounts = bankAccounts.map((bankAccount) => {
    const options = getAccountOptionsForBankAccountId({
      accountMappings,
      bankAccountId: bankAccount.id,
      lawpayBankAccounts,
    });

    return {
      id: bankAccount.id,
      label: getBankAccountName(bankAccount, t),
      accountType: bankAccount.accountType,
      closed: bankAccount.accountType === 'TRUST' && isTrustAccountClosed(bankAccount),
      ...options,
    };
  });

  const caseSensitive = false;

  return sortByProperty(formattedAccounts, 'label', 'asc', caseSensitive);
};
