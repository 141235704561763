import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes, SlidingToggle, Spinner, TextEditor, Icon } from '@sb-itops/react';
import Styles from './InvoiceEmailForm.module.scss';

export const InvoiceEmailForm = React.memo(
  ({
    isLoading,
    showIncludeDebtor,
    includeDebtor,
    readOnlyToAddress,
    readOnlyCcBcc,
    toAddress,
    staffAddress,
    fromAddress,
    ccAddress,
    bccAddress,
    sendCopyToMe,
    showCombineAttachments,
    combineAttachments,
    subject,
    message,
    showPreviewButton,
    isPreviewMode,
    previewSubject,
    previewMessage,
    selectedTabId,
    showDisabledPayButtonsMessage,
    onFieldValueUpdated,
    onPreviewToggled,
  }) => {
    if (isLoading) {
      return (
        <div className={Styles.invoiceEmailForm}>
          <Spinner height={500} message="Loading" />
        </div>
      );
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toAddressInputRef = React.useRef();
    const formDisabled = showIncludeDebtor && !includeDebtor?.value;

    const onChangeMessage = (value, delta, source) => {
      if (source !== 'user') {
        return;
      }
      onFieldValueUpdated('message', value);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (toAddress?.isInvalid) {
        toAddressInputRef.current.focus();
      }
    }, [toAddress?.isInvalid, selectedTabId]);

    return (
      <div className={Styles.invoiceEmailForm}>
        {showIncludeDebtor && (
          <div className={classnames('form-group', Styles.formGroupInline)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={Styles.sendCopyLabel}
              htmlFor="includeDebtorSlider"
              onClick={(e) => {
                e.preventDefault();
                onFieldValueUpdated('includeDebtor', !includeDebtor?.value);
              }}
            >
              <SlidingToggle id="includeDebtorSlider" scope="includeDebtorSlider" selected={includeDebtor?.value} />
              <div>Email this debtor</div>
            </label>
          </div>
        )}

        <fieldset disabled={formDisabled}>
          {readOnlyToAddress?.value && (
            <div className="form-group">
              <label>TO:</label>
              <input className="form-control" placeholder={readOnlyToAddress?.value} disabled />
            </div>
          )}

          {!readOnlyToAddress?.value && (
            <div className={classnames('form-group', toAddress?.isInvalid && 'has-error')}>
              <label>TO:</label>
              <input
                className="form-control"
                value={toAddress?.value}
                onChange={(e) => onFieldValueUpdated('toAddress', e.target.value)}
                ref={toAddressInputRef}
              />
              {toAddress?.isInvalid && <div className={Styles.errorMessage}>{toAddress?.invalidReason}</div>}
            </div>
          )}
          <div className="row">
            <div className={classnames('form-group col-sm-6', ccAddress?.isInvalid && 'has-error')}>
              <label>CC:</label>
              <input
                className="form-control"
                value={ccAddress?.value}
                onChange={(e) => onFieldValueUpdated('ccAddress', e.target.value)}
                disabled={readOnlyCcBcc?.value}
                placeholder={readOnlyCcBcc?.value ? 'Will be sent to matter defaults' : undefined}
              />
              {ccAddress?.isInvalid && <div className={Styles.errorMessage}>{ccAddress?.invalidReason}</div>}
            </div>
            <div className={classnames('form-group col-sm-6', bccAddress?.isInvalid && 'has-error')}>
              <label>BCC:</label>
              <input
                className="form-control"
                value={bccAddress?.value}
                onChange={(e) => onFieldValueUpdated('bccAddress', e.target.value)}
                disabled={readOnlyCcBcc?.value}
                placeholder={readOnlyCcBcc?.value ? 'Will be sent to matter defaults' : undefined}
              />
              {bccAddress?.isInvalid && <div className={Styles.errorMessage}>{bccAddress?.invalidReason}</div>}
            </div>
          </div>
          <div className={classnames('form-group', Styles.formGroupInline)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={Styles.sendCopyLabel}
              htmlFor="sendCopySlider"
              disabled={formDisabled}
              onClick={(e) => {
                e.preventDefault();
                onFieldValueUpdated('sendCopyToMe', !sendCopyToMe?.value);
              }}
            >
              <SlidingToggle
                id="sendCopySlider"
                scope="sendCopySlider"
                selected={sendCopyToMe?.value}
                disabled={formDisabled}
              />
              <div>Send a copy of the email to me ({staffAddress?.value})</div>
            </label>
          </div>

          {showCombineAttachments && (
            <div className={classnames('form-group', Styles.formGroupInline)}>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                className={Styles.sendCopyLabel}
                htmlFor="combineAttachments"
                disabled={formDisabled}
                onClick={(e) => {
                  e.preventDefault();
                  onFieldValueUpdated('combineAttachments', !combineAttachments?.value);
                }}
              >
                <SlidingToggle
                  id="combineAttachmentsSlider"
                  scope="combineAttachmentsSlider"
                  selected={combineAttachments?.value}
                  disabled={formDisabled}
                />
                <div>Combine multiple invoices into a single PDF attachment</div>
              </label>
            </div>
          )}

          <div className={classnames('form-group', fromAddress?.isInvalid && 'has-error')}>
            <label>FROM:</label>
            <input
              className="form-control"
              value={fromAddress?.value}
              onChange={(e) => onFieldValueUpdated('fromAddress', e.target.value)}
            />
          </div>

          <div className={classnames('form-group', subject?.isInvalid && 'has-error')}>
            <label>SUBJECT:</label>
            <input
              className="form-control"
              value={isPreviewMode ? previewSubject : subject?.value}
              onChange={(e) => onFieldValueUpdated('subject', e.target.value)}
              disabled={isPreviewMode}
            />
          </div>

          <div className={classnames('form-group', Styles.textEditor, message?.isInvalid && 'has-error')}>
            <label>MESSAGE:</label>
            {/* Input mode text editor */}
            {!isPreviewMode && <TextEditor value={message?.value} disabled={formDisabled} onChange={onChangeMessage} />}

            {/* Preview mode text editor */}
            {isPreviewMode && <TextEditor value={previewMessage} disabled />}

            {showPreviewButton && (
              <div className={Styles.textEditorButtonPanel} title={isPreviewMode ? `Edit Email` : `Preview Email`}>
                <Button className={Styles.previewButton} type={buttonTypes.secondary} onClick={onPreviewToggled}>
                  {isPreviewMode ? `Edit Email` : `Preview Email`}
                </Button>
              </div>
            )}
            {showDisabledPayButtonsMessage && (
              <div className={Styles.disabledPayButtonsMessage}>
                <Icon type="information" className={Styles.infoIcon} />
                <p>
                  Some of your selected invoices have the pay button disabled - these invoices and emails will not show
                  the pay button
                </p>
              </div>
            )}
          </div>
        </fieldset>
      </div>
    );
  },
);

InvoiceEmailForm.displayName = 'InvoiceEmailForm';

InvoiceEmailForm.propTypes = {
  isLoading: PropTypes.bool,
  showIncludeDebtor: PropTypes.bool,
  includeDebtor: PropTypes.object,
  readOnlyToAddress: PropTypes.object,
  readOnlyCcBcc: PropTypes.object,
  toAddress: PropTypes.object,
  fromAddress: PropTypes.object,
  ccAddress: PropTypes.object,
  bccAddress: PropTypes.object,
  staffAddress: PropTypes.object,
  sendCopyToMe: PropTypes.object,
  showCombineAttachments: PropTypes.bool,
  combineAttachments: PropTypes.object,
  subject: PropTypes.object,
  message: PropTypes.object,
  showPreviewButton: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  previewSubject: PropTypes.string,
  previewMessage: PropTypes.string,
  onFieldValueUpdated: PropTypes.func,
  onPreviewToggled: PropTypes.func,
  selectedTabId: PropTypes.string,
  showDisabledPayButtonsMessage: PropTypes.bool,
};

InvoiceEmailForm.defaultProps = {
  bccAddress: undefined,
  ccAddress: undefined,
  combineAttachments: undefined,
  fromAddress: undefined,
  includeDebtor: undefined,
  isLoading: undefined,
  isPreviewMode: undefined,
  message: undefined,
  onFieldValueUpdated: undefined,
  onPreviewToggled: undefined,
  previewMessage: undefined,
  previewSubject: undefined,
  readOnlyCcBcc: undefined,
  readOnlyToAddress: undefined,
  selectedTabId: undefined,
  sendCopyToMe: undefined,
  showCombineAttachments: undefined,
  showDisabledPayButtonsMessage: undefined,
  showIncludeDebtor: undefined,
  showPreviewButton: undefined,
  staffAddress: undefined,
  subject: undefined,
  toAddress: undefined,
};

export default InvoiceEmailForm;
