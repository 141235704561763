'use strict';

/**
 * @typedef { import('../types').Activity } Activity
 */

const { entryType, durationType } = require('../../shared/entities');

/**
 * Returns the preferred duration type for the passed activity.
 *
 * This function can be used to determine how a user would expect to input a duration for a fee created based on the
 * passed activity type.
 *
 * To simply infer the duration type from an activity alone, consider using getActivityDuration().
 *
 *
 * How this function resolves the preferred durationType:
 *
 * If the acitivty type is EXPENSE or FIXED, 'FIXED' is returned.
 *
 * If preferDurationAsUnits is truthy and activity's durationMins property is divisible by billingIncrementsMins,
 * 'UNITS' is retruned.
 *
 *  Otherwise 'HOURS' is returned.
 *
 * @param {Object} params
 * @param {Activity} params.activity
 * @param {boolean} [params.preferDurationAsUnits]
 * @param {number} [params.billingIncrementsMins]
 * @returns {'HOURS'|'UNITS'|'FIXED'}
 */
const getPreferredActivityDurationType = ({ activity, preferDurationAsUnits = false, billingIncrementsMins = 1 }) => {
  if (activity.type === entryType.EXPENSE || activity.type === entryType.FIXED) {
    return durationType.FIXED;
  }

  if (preferDurationAsUnits && (!activity.durationMins || !(activity.durationMins % billingIncrementsMins))) {
    return durationType.UNITS;
  }

  return durationType.HOURS;
};

module.exports = {
  getPreferredActivityDurationType,
};
