import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';

// DO NOT modify this object
let DEFAULT_FILTERS = Object.freeze({
  sentRemindersOnly: false,
  overdueBy: 'all',
  overdueAmount: undefined,
  attorneys: [],
  remindedInDays: 14,
  invoicedInDays: 7,
  hideMatterDebtorWithNoOverdueInvoices: true,
});

const overdueByFilters = [
  {
    display: 'All',
    key: 'all',
  },
  {
    display: '1 - 30 Days',
    key: '1To30',
  },
  {
    display: '31 - 60 Days',
    key: '31To60',
  },
  {
    display: '61 - 90 Days',
    key: '61To90',
  },
  {
    display: '91+ Days',
    key: 'above90',
  },
];

const overdueRangePredicates = {
  notOverdue: ({ daysOverdue }) => daysOverdue <= 0,
  '1To30': ({ daysOverdue }) => daysOverdue >= 1 && daysOverdue <= 30,
  '31To60': ({ daysOverdue }) => daysOverdue >= 31 && daysOverdue <= 60,
  '61To90': ({ daysOverdue }) => daysOverdue >= 61 && daysOverdue <= 90,
  above90: ({ daysOverdue }) => daysOverdue > 90,
  all: () => true,
};

let showFilters = [];

const filterInvoiceReminders = ({ filters, remindersAndInvoices }) => {
  // We need to maintain state between each call to the filter function.
  // This is because the filtered state of a reminder has an impact on the invoices which belong to the reminder.
  // It's a side effect of converting a 2 level tree (1 reminder -> N invoices) to an array (reminder, invoice, invoice, reminder, invoice...)
  const remindersToKeep = {};
  const invoicesToKeep = {};
  const matterTypesLookup = new Set(filters.matterTypes);

  return remindersAndInvoices.reduce((filteredRemindersAndInvoices, reminderOrInvoice) => {
    // Handle invoice row.
    if (reminderOrInvoice.type === 'INVOICE') {
      const invoice = reminderOrInvoice;
      if (
        remindersToKeep[invoice.matterDebtorId] && // If the parent reminder has been filtered out, the invoice should be filtered too.
        invoicesToKeep[invoice.invoiceId] && // We need to filter out the reminder record if all invoices are filtered as a result of overdue range.
        filters.expandedReminders[invoice.matterDebtorId]
      ) {
        // We don't need to show the invoice if the reminder isn't expanded.

        filteredRemindersAndInvoices.push(reminderOrInvoice);
      }

      return filteredRemindersAndInvoices;
    }

    // Handle reminder row.
    const reminder = reminderOrInvoice;
    const {
      matterDebtorId,
      lastReminderDate,
      lastReminderInDays,
      lastInvoicedInDays,
      matterDebtorContainsNotOverdueInvoices,
      matterAttorneyResponsible,
      totalUnpaid,
      matter: { status, matterTypeId },
      matterSendPreferences,
    } = reminder;

    if (filters.matterStatuses && !filters.matterStatuses.includes(status)) {
      return filteredRemindersAndInvoices;
    }

    if (filters.matterTypes && filters.matterTypes.length && !matterTypesLookup.has(matterTypeId)) {
      return filteredRemindersAndInvoices;
    }

    // Send preferences
    // When all option is selected, skip filtering, we minus one because reminder page doesn't have Communication as a filter option
    const totalNumberOfAvailablePreferences = hasFacet(facets.eBillingSendingPreference) ? Object.keys(sentViaTypes).length - 1 : Object.keys(sentViaTypes).length - 2;
    // Check if any send preference in `filters.sendPreferences` exists in `matterSendPreferences`
    // worth mentioning that `filters.sendPreferences` is a set of strings, but `matterSendPreferences` is an array of number, therefore need to convert them to string before comparing
    if (featureActive('BB-11448') && filters.sendPreferences &&
      filters.sendPreferences.length !== totalNumberOfAvailablePreferences &&
      !matterSendPreferences.some(preference => filters.sendPreferences.includes(String(preference)))) {
      return filteredRemindersAndInvoices;
    }

    // exclude reminders that have not been reminded in the defined date, undefined means do not filter
    if (filters.remindedInDays > 0) {
      if (lastReminderInDays <= filters.remindedInDays) {
        return filteredRemindersAndInvoices;
      }
    }

    // exclude reminders that have not been invoiced in the defined date, undefined means do not filter
    if (filters.invoicedInDays > 0) {
      if (lastInvoicedInDays <= filters.invoicedInDays) {
        return filteredRemindersAndInvoices;
      }
    }

    // exclude reminder that the matter debtor combination has/has not remaining overdue invoices
    if (filters.hideMatterDebtorWithNoOverdueInvoices) {
      if (matterDebtorContainsNotOverdueInvoices === filters.hideMatterDebtorWithNoOverdueInvoices) {
        return filteredRemindersAndInvoices;
      }
    }

    // Overdue range. If there are no invoices left after this filter, the reminder row can be removed.
    reminder.invoices = reminder.invoices.reduce((remainingInvoices, invoice) => {
      const overdueByKeys = filters.overdueBy || ['all'];
      overdueByKeys.forEach((overdueByKey) => {
        if (overdueRangePredicates && overdueRangePredicates[overdueByKey](invoice)) {
          // Used to indicate to the following invoice rows belonging to this reminder whether or not they should be filtered.
          invoicesToKeep[invoice.invoiceId] = true;
          remainingInvoices.push(invoice);
        }
      });
      return remainingInvoices;
    }, []);

    if (reminder.invoices.length === 0) {
      return filteredRemindersAndInvoices;
    }

    // Overdue amount.
    if (filters.overdueAmount > 0 && totalUnpaid < filters.overdueAmount) {
      return filteredRemindersAndInvoices;
    }

    // Sent reminders
    if (filters.sentRemindersOnly && !lastReminderDate) {
      return filteredRemindersAndInvoices;
    }

    // Attorney responsible
    const matchesSelectedAttorney = filters.attorneys.includes(matterAttorneyResponsible);
    if (!matchesSelectedAttorney && filters.attorneys.length !== 0) {
      return filteredRemindersAndInvoices;
    }

    remindersToKeep[matterDebtorId] = true;
    filteredRemindersAndInvoices.push(reminderOrInvoice);
    return filteredRemindersAndInvoices;
  }, []);
};

export { DEFAULT_FILTERS, overdueByFilters, overdueRangePredicates, showFilters, filterInvoiceReminders };
