import React from 'react';
import PropTypes from 'prop-types';
import { SubscriptionPaymentForm } from 'web/react-redux';
import { subscriptionStatusByType } from '@sb-finance/business-logic/subscription/entities/constants';
import Styles from './UpdatePaymentMethodRoute.module.scss';

export const UpdatePaymentMethodRoute = React.memo((props) => {
  const { subscriptionRecord, onPaymentMethodUpdated, onPaymentMethodUpdateAbort } = props;

  if (!subscriptionRecord) {
    return null;
  }

  return (
    <div className={Styles.updatePaymentMethodRoute}>
      <SubscriptionPaymentForm
        stripeProductId={subscriptionRecord.externalProductIds[0]}
        subscriptionRecord={subscriptionRecord}
        onPaymentMethodUpdated={onPaymentMethodUpdated}
        onPaymentMethodUpdateAbort={onPaymentMethodUpdateAbort}
      />
    </div>
  );
});

UpdatePaymentMethodRoute.displayName = 'UpdatePaymentMethodRoute';

UpdatePaymentMethodRoute.propTypes = {
  subscriptionRecord: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    externalSubscriptionId: PropTypes.string.isRequired,
    externalCustomerId: PropTypes.string.isRequired,
    externalProductIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.oneOf(Object.values(subscriptionStatusByType)).isRequired,
    externalPaymentMethodIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  onPaymentMethodUpdated: PropTypes.func.isRequired,
  onPaymentMethodUpdateAbort: PropTypes.func.isRequired,
};

UpdatePaymentMethodRoute.defaultProps = {
  subscriptionRecord: undefined,
};
