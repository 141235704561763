angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('home.billing.view-matter', {
      url: '/view-matter/:matterId',
      template: '<sb-matter-view></sb-matter-view>',
      params: {
        openedFromTab: null,
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter'],
        derivePersistanceKey: (name, sbParams) => `${name, sbParams.matterId}`,
        findUnsavedChanges: ({trustRetainerBelowLimit}) => ({trustRetainerBelowLimit}),
      }
    });

});
