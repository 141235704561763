import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

const { opdateCache: opdateExpenseCache } = optimisticUpdateFactory({
  ngCacheName: 'sbExpenseService',
  keyPath: 'expenseId',
});

export const onUpdateExpenseBulkIsBillable = ({ commandResult }) => {
  opdateExpenseCache({ optimisticEntities: commandResult.versions });
};
