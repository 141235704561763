import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getById } from '@sb-billing/redux/invoices';

import InvoiceDisplay from './InvoiceDisplay';

const mapStateToProps = (state, { asLink, inline, onClickLink, className, invoiceId }) => {
  const invoice = getById(invoiceId);
  return {
    className,
    asLink,
    inline,
    invoice: invoice && invoice.currentVersion,
    onClickLink: () => onClickLink({ type: 'invoice', id: invoiceId }),
  };
};

const InvoiceDisplayContainer = withReduxStore(connect(mapStateToProps)(InvoiceDisplay));

InvoiceDisplayContainer.propTypes = {
  invoiceId: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  onClickLink: PropTypes.func,
  className: PropTypes.string,
};

InvoiceDisplayContainer.defaultProps = {
  invoiceId: undefined,
};

export default InvoiceDisplayContainer;
