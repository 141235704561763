import { fetchPostP } from '@sb-itops/redux/fetch';
import { getAccountId } from 'web/services/user-session-management';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import uuid from '@sb-itops/uuid';
import { getById as getSavedPaymentMethodsByCustomerId } from '../customer-billing-configuration';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbCustomerBillingConfigurationService',
  keyPath: 'entityId',
});

export const deleteCard = (deleteCardDetails) => {
  const { providerType, contactId, cardId, providerSpecificFields } = deleteCardDetails;

  const deleteCardThunk = async () => {
    let opdateEntity;

    try {
      const cBConfig = getSavedPaymentMethodsByCustomerId(contactId) || { paymentMethods: [], entityId: contactId };
      const paymentMethods = (cBConfig.paymentMethods || []).filter((pm) => pm.token !== cardId);
      opdateEntity = { ...cBConfig, paymentMethods };
      opdateCache({ optimisticEntities: [opdateEntity] });

      const payload = {
        ...cBConfig,
        versionId: uuid(),
        providerType,
        paymentMethods,
        providerSpecificFields,
      };

      const path = `/billing/payment-provider/delete-card/${providerType.toLowerCase()}/${getAccountId()}/${cardId}/`;
      const fetchOptions = { body: JSON.stringify(payload) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [opdateEntity] });
      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(deleteCardThunk);
};
