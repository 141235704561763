import { getRegion, regionType } from '@sb-itops/region';
import { t } from '@sb-itops/localisation-web';

const REGION = getRegion();

const labels = {
  amount: {
    [regionType.AU]: () => t('invoiceTable.amountLabel'),
    [regionType.US]: ({ invoiceMode }) =>
      invoiceMode === 'PAID' ? t('invoiceTable.amountLabelPaid') : t('invoiceTable.amountLabelUnpaid'),
    [regionType.GB]: () => t('invoiceTable.amountLabel'),
  },
};

export function getLabel(props) {
  const { label } = props;
  // return t('invoiceTable.amountLabel', { invoiceMode });
  return labels[label][REGION](props);
}

export function userNameGetter({ rowData }) {
  return rowData.user?.person?.name || '';
}

export function dataGetter({ dataKey, rowData }) {
  return rowData[dataKey];
}

export function invoiceTotalsDataGetter({ rowData, dataKey }) {
  return rowData.totals?.[dataKey] ?? 0;
}
