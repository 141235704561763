'use strict';

// modifying these styles will modify all emails using these styles
// please carefully test all email templates that's importing these styles
//
// NB: the below style is necessary in order to make our emails appear in email
// clients in the same way that the user sees it in our WYSIWYG editor
// p {
//   margin: 0;
// }
//
// Warning: do not inject comments below, it could break styling in Gmail/Outlook
const emailHtmlWrapper = `
<!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <title></title>
  <meta charset="utf-8" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      padding: 0;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important;
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none;
    }

    p {
      margin: 0;
      line-height: inherit;
    }

    .email-title {
      font-size: 18px;
      font-weight: bold;
    }  

    @media (max-width:620px) {
      .icons-inner {
        text-align: center;
      }

      .icons-inner td {
        margin: 0 auto;
      }

      .row-content {
        width: 100% !important;
      }

      .stack .column {
        width: 100%;
        display: block;
      }

      .dod-invoice {
        margin-left: 0px;
      }

      .button-mb {
        margin-bottom: 10px;
      }
    }
  </style>
</head>

<body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="100%">
    <tbody>
      <tr>
        <td>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation"
            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 600px;"
                    width="600">
                    <tbody>
                      <tr>
                        <td class="column"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top;"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                            <tr>
                              <td style="font-size:7px;height:20px;background-color:#ffffff" width="100%"></td>
                            </tr>
                            <tr>
                              <td
                                style="border-bottom:0px solid #FFFFFF;border-left:0px solid #FFFFFF;border-right:0px solid #FFFFFF;padding-top:0px;padding-bottom:0px;">
                                <table border="0" cellpadding="20" cellspacing="0" class="image_block"
                                  role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                  width="100%">
                                  <tr>
                                    <td style="padding-bottom:10px;padding-left:20px;padding-right:20px;padding-top:10px;">
                                      [FIRM_LOGO]
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation"
            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 600px;"
                    width="600">
                    <tbody>
                      <tr>
                        <td class="column"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 10px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                            <tr>
                              <td style="padding-bottom:10px;padding-left:20px;padding-right:20px;padding-top:10px;">


                                <div style="font-family: sans-serif">
                                  <div
                                    style="font-size: 14px; mso-line-height-alt: 21px; color: #393d47; line-height: 1.5; font-family: Helvetica Neue, Helvetica, Arial, sans-serif;">
                                    [[EMAIL_BODY]]  
                                  </div>
                                </div>

                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>
`;

const applyEmailHtmlWrapper = ({ emailBody }) => {
  const finalEmailBody = emailHtmlWrapper.replace('[[EMAIL_BODY]]', emailBody);
  return finalEmailBody;
};

module.exports = {
  applyEmailHtmlWrapper,
};
