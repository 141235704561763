import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { getLogger } from '@sb-itops/fe-logger';
import { useIsMounted } from '@sb-itops/react';
import { getFirmDetails } from '@sb-firm-management/redux/firm-management';
import uuid from '@sb-itops/uuid';
import { useMemo, useState } from 'react';
import { useGoogleMapsLoader } from 'web/hooks';

function buildFormattedAddress(addressArr = []) {
  return addressArr.join(' ');
}

const log = getLogger('GoogleMarkerFromAddress');

// Takes the prop activeAddress and computes a map and marker prop that can be consumed by a map component
const hooks = {
  useMaps: ({ activeAddress }) => {
    const [mapState, setMapState] = useState({ map: null, marker: null });

    const isMounted = useIsMounted();

    const { isLoaded } = useGoogleMapsLoader();

    let addressToFind = buildFormattedAddress(activeAddress?.address);

    // Fallback to firm address
    if (!addressToFind) {
      const { state, country } = getFirmDetails()?.businessAddress || {};
      addressToFind = buildFormattedAddress([state, country]);
    }

    // Using memo to cache previously fetched addresses
    const newStateP = useMemo(
      () =>
        new Promise((resolve) => {
          if (!isLoaded || !addressToFind || !window.google?.maps) {
            resolve({ map: null, marker: null });
            return;
          }
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ address: addressToFind }, (results, status) => {
            let marker;
            let map;
            if (status !== window.google.maps.GeocoderStatus.OK) {
              resolve({ map, marker });
              log.warn(`Failed to geocode address: "${addressToFind}", status = "${status}"`);
              return;
            }
            if (!results) {
              resolve({ map, marker });
              log.warn(`Failed to geocode address: "${addressToFind}, no results found`);
              return;
            }
            const locationIsStreetAddress = (results[0].types || []).some((type) =>
              ['street_address', 'premise', 'subpremise'].includes(type),
            );
            const location = results[0]?.geometry?.location;
            if (locationIsStreetAddress) {
              map = { center: { lat: location.lat(), lng: location.lng() }, zoom: 16 };
              marker = { coords: { lat: location.lat(), lng: location.lng() }, id: uuid() };
            } else {
              map = { center: { lat: location.lat(), lng: location.lng() }, zoom: 7 };
              marker = {};
            }
            resolve({ map, marker });
          });
        }),
      [addressToFind, isLoaded],
    );

    // Update state when a new address is found
    newStateP
      .then((newState) => {
        // eslint-disable-next-line promise/always-return
        if (newState !== mapState && isMounted?.current) {
          setMapState(newState);
        }
      })
      .catch((err) => log.error(err));

    return mapState;
  },
};

export const GoogleMarkerFromAddress = composeHooks(hooks);

GoogleMarkerFromAddress.displayName = 'GoogleMarkerFromAddress';

GoogleMarkerFromAddress.propTypes = {
  activeAddress: PropTypes.object.isRequired,
};

GoogleMarkerFromAddress.defaultProps = {};
