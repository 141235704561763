import { gql } from '@apollo/client';

const query = gql`
  query SbClientSdkStaffData($staffId: ID!) {
    staffMembers(id: $staffId) {
      id
      firstName
      middleName
      lastName
      initials
      email
      name
      title
      isCurrentStaff
      isFormerStaff
    }
  }
`;

const notificationIds = ['FirmManagementNotifications.PersonUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SbClientSdkStaffData = {
  query,
  notificationIds,
};
