'use strict';

const mapItemToOption = (item, hideDescription) => {
  const { code, description } = item;
  return {
    value: code,
    label: hideDescription ? code : `${code} (${description})`,
    data: item,
  };
};

const mapItemsToOptions = (list) => {
  if (!list) {
    return [[], {}];
  }
  const { options, optionsMap } = list.reduce(
    (acc, item) => {
      const option = mapItemToOption(item);
      acc.options.push(option);
      acc.optionsMap[item.code] = option;
      return acc;
    },
    { options: [], optionsMap: {} },
  );

  return [options, optionsMap];
};

/**
 * @typedef {Object} ActivityCodeGroup
 * @property {string} group - group name
 * @property {Object[]} activities - list of activity codes
 */

/**
 * @typedef {Object} TaskCodeGroup
 * @property {string} group - group name
 * @property {Object[]} tasks - list of task codes
 */

/**
 * Build drop down options for activity/task code items, which may be grouped
 * @param {(ActivityCodeGroup|TaskCodeGroup)[]} itemGroups - item groups, each with a list of items
 * @param {'activities'|'tasks'} itemsKey - e.g. activities or tasks
 * @returns an array or options or grouped options, and a map of all options by code
 */
const buildGroupedDropDownOptions = (itemGroups, itemsKey) => {
  // only show group header if there is more than one groupbuildGroupedDropDownOptions
  const isGrouped = itemGroups && itemGroups[0] && itemGroups[0].group !== undefined && itemGroups.length > 1;

  if (!isGrouped) {
    return mapItemsToOptions(itemGroups[0] && itemGroups[0][itemsKey]);
  }
  const { groupedOptions, groupedOptionsMap } = itemGroups.reduce(
    (acc, itemGroup) => {
      const [itemOptions, itemOptionsMap] = mapItemsToOptions(itemGroup[itemsKey]);
      acc.groupedOptions.push({
        label: itemGroup.group,
        options: itemOptions,
      });
      acc.groupedOptionsMap = { ...acc.groupedOptionsMap, ...itemOptionsMap };
      return acc;
    },
    { groupedOptions: [], groupedOptionsMap: {} },
  );

  return [groupedOptions, groupedOptionsMap];
};

module.exports = {
  mapItemToOption, // it's likely this will be deprecated for external usage once we get rid of Formik
  buildGroupedDropDownOptions,
};
