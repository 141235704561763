angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/devs-only';

  $stateProvider
    .state('home.billing.support.devs-only', {
      url: '/devs-only',
      templateUrl: BASE_DIRECTORY + '/devs-only.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});
