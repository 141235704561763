import { SORT_ROWS } from './types';

const INITIAL_STATE = {
  trustCheques: {
    sortBy: 'effectiveDate',
    sortDirection: 'desc',
  },
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SORT_ROWS: {
      const { sortBy, sortDirection } = action.payload;
      return {
        ...state,
        trustCheques: {
          sortBy,
          sortDirection,
        },
      };
    }
    default: {
      return state;
    }
  }
};
