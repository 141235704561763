import { store } from '@sb-itops/redux';

/**
 * Toggles one or more properties in a leaf node in the redux tree.
 *
 * E.g. Take the example store:
 * {
 *   someCoolLeaf: {
 *      someFlag: false,
 *      someOtherFlag: 1,
 *   }
 * }
 *
 * Then calling toggleStateAction('someFlag', 'someOtherFlag', 'someNewFlag') will result in the store containing:
 * {
 *   someCoolLeaf: {
 *      someFlag: true,
 *      someOtherFlag: false,
 *      someNewFlag: true
 *   }
 * }
 *
 * Note the type corecion to boolean.
 */
export const toggleState = ({ actionName = 'TOGGLE_STATE' }) => {
  const ACTION_TYPE = actionName;

  const toggleStateAction = (...propsToToggle) =>
    store.dispatch({
      type: ACTION_TYPE,
      payload: {
        propsToToggle,
      },
    });

  const toggleStateReducer = (state, action) => {
    const { propsToToggle } = action.payload;

    return propsToToggle.reduce(
      (toggledValues, propToToggle) => {
        // eslint-disable-next-line no-param-reassign
        toggledValues[propToToggle] = !state[propToToggle];
        return toggledValues;
      },
      { ...state },
    );
  };

  return { toggleStateAction, toggleStateReducer, ACTION_TYPE };
};
