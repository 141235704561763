import composeHooks from '@sb-itops/react-hooks-compose';

import { InitStaffSettings } from 'web/graphql/queries';
import { useCacheQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getUsername } from 'web/services/user-session-management';

import { SetupUserWarningBanner } from './SetupUserWarningBanner';

/**
 * Important: Do not replicate this pattern. This container exists under the following constraints:
 *  1. It needs to be available on every route; and
 *  2. the data is only fetched from Apollo cache (no network requests)
 *
 * Should these requirements change, this container should no longer be used.
 */

const hooks = () => ({
  useStaffSettingsData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const isStaffMember = !!staffData?.loggedInStaff;

    return {
      userName: isStaffMember ? '' : getUsername(),
      isStaffMember,
    };
  },
});

export const SetupUserWarningBannerContainer = withApolloClient(composeHooks(hooks)(SetupUserWarningBanner));
