import { fetchAccessAuthorisationsFromWebP } from '@sb-integration/web-client-sdk';
import { cachePermissions, getCachedPermissions } from './matter-permissions-cache';
import { TPermission } from './types';
import { makePermissionsLookup } from './make-permissions-lookup';

/**
 * Calls matter permissions to check the matter ids, returns a boolean lookup
 * keyed to the matterIds.
 *
 * @param { string[] } matterIds
 * @return { Record<matterId, boolean> }
 */
export const fetchMatterPermissionsFromWebP = async (matterIds): Promise<Record<string, boolean>> => {
  const resourceIds = matterIds.map((id) => `matters/${id}`);

  const cached = getCachedPermissions(resourceIds);
  if (!cached.misses.length) {
    return makePermissionsLookup(Object.values(cached.hits));
  }

  const permissions: TPermission[] = await fetchAccessAuthorisationsFromWebP(resourceIds);
  cachePermissions(permissions);

  return makePermissionsLookup(permissions);
};
