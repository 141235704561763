import { getById as getTrustChequeById } from '@sb-billing/redux/trust-cheques';
import { getById as getTransactionById } from '@sb-billing/redux/transactions';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { getById as getVendorPaymentById } from '@sb-billing/redux/vendor-payments';
import  { transactionType as transactionTypes }from '@sb-billing/business-logic/transactions/entities/constants'
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplayService from '@sb-itops/message-display';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getTransactionsByCheque, consolidateChequeTransactions, createChequeDescription } from '@sb-billing/business-logic/transactions/services';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { localiseTransactionSource } from '@sb-billing/business-logic/transactions/services';
import { bankAccountTypes } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';

const buildDescription = ({ cheque, consolidatedTx, t }) =>
  createChequeDescription({ cheque, consolidatedTx, excludeReason: true, hasReasonFieldFacet: hasFacet(facets.reasonField), getPaymentById, getInvoiceNumberById, t });

angular.module('sb.billing.webapp').component('sbTrustChequeDetails', {
  templateUrl: 'ng-components/trust-cheque-details/trust-cheque-details.html',
  bindings: { chequeId: '<', onClose: '<' },
  controller: function (sbVendorPaymentsService, sbPaymentService, sbLinkService, sbLocalisationService, sbTransactionService, focusService) {
    const ctrl = this;
    const log = getLogger('sbTrustChequeDetails');
    ctrl.t = sbLocalisationService.t;

    ctrl.view = {
      isReverseCollapsed: true,
      cheque: {}, // for the renders before the data loads
      isMatterContactBalanceType: isMatterContactBalanceType()
    };

    ctrl.errors = {
      reason: false,
    };

    ctrl.model = {
      reason: undefined,
      delete: false,
    };


    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.toggleReversalUI = toggleReversalUI;
    ctrl.closeModal = closeModal;
    ctrl.processReversal = processReversal;
    ctrl.validate = validate;
    ctrl.isChequeReversible = isChequeReversible;
    ctrl.t = sbLocalisationService.t;

    ctrl.$onChanges = () => {
      const cheque = getTrustChequeById(ctrl.chequeId);
      const chequeTransactions = getTransactionsByCheque({ cheque, getTransactionById });
      const consolidatedTx = consolidateChequeTransactions({ transactions: chequeTransactions })[cheque.chequeId];

      const vendorPayment = consolidatedTx.paymentIds.length === 1 && getVendorPaymentById(consolidatedTx.paymentIds[0]);

      ctrl.view.cheque = {
        chequeId: cheque.chequeId,
        effectiveDate: cheque.chequeDate,
        systemDate: cheque.lastUpdated,
        chequeNumber: cheque.chequeNumber,
        chequeMemo: cheque.chequeMemo,
        isPrinted: cheque.isPrinted,
        isTrustToOffice: cheque.isTrustToOffice,
        ...consolidatedTx,
        description: buildDescription({ cheque, consolidatedTx, t: ctrl.t }),
        source: getTransactionSource(consolidatedTx),
      };

      ctrl.view.vendorPayment = vendorPayment;
    };

    function getTransactionSource(transaction) {
      if (transaction.source) {
        return localiseTransactionSource({ source: transaction.source, t: sbLocalisationService.t });
      } else if (transaction.bankAccountType === bankAccountTypes.TRUST && transaction.bankAccountId) {
        const trustAccount = getBankAccountById(transaction.bankAccountId);
        return getBankAccountName(trustAccount, sbLocalisationService.t);
      }
      // Should never get here since these are all trust transactions and all should have a bankAccountId
      return sbLocalisationService.t('capitalize', { val: transaction.bankAccountType.toLowerCase() }); 
    }

    function validate() {
      ctrl.errors.reason = !ctrl.model.reason || !ctrl.model.reason.trim();
      return Object.keys(ctrl.errors).every((key) => !ctrl.errors[key]);
    }

    function toggleReversalUI(opts = {}) {
      ctrl.view.isReverseCollapsed = !ctrl.view.isReverseCollapsed;
      ctrl.model.delete = opts.delete;

      if (!ctrl.view.isReverseCollapsed) {
        focusService.focusOn('reason-field');
      }
    }

    function isChequeReversible() {
      // reversal transaction will have effective date of TODAY so check that is valid
      const { bankAccountType, bankAccountId, transactionIds } = ctrl.view.cheque;
      const isReconciledPeriod = bankAccountType.toUpperCase() === 'TRUST' && isReconciled({ yyyymmdd: moment().format('YYYYMMDD'), trustAccountId: bankAccountId });

      // For trust to office transactions, if any of the transactions for a single transaction are reconciled, they will all be reconciled (per business rules).
      // We use some() in the TTO case to defend against bugs that allow partial reconciliation of TTO, we still (especially) want to prevent reversal in that situation.
      // For non trust to office transactions, there will only be one transactionId anyway, so using some() vs every() will make no difference.
      const reconciled = transactionIds.some((transactionId) => sbTransactionService.isReconciled(transactionId));
      return !isReconciledPeriod && !reconciled && isChequeReversibleByType(ctrl.view.cheque);
    }
    function isChequeReversibleByType(cheque) {
      const { transactionType, isReversed, paymentIds } = cheque;
      switch (transactionType) {
        case transactionTypes.VendorPayment:
          return !isReversed;
        case transactionTypes.InvoicePayment:
          // For trust to office transactions, there will be one or more payment ids. Non trust to office will have 1 payment id.
          // A payment is reversible if it's not yet reversed and the invoice the payment was applied to has not been waived.
          // For TTO, if any invoice that was part of the TTO is waived, the entire TTO becomes non-reversible.
          return paymentIds.every(paymentId => sbPaymentService.isReversiblePayment(paymentId));
        default:
          return false;
      }
    }

    function processReversal() {
      if (!validate()) {
        return;
      }

      switch (ctrl.view.cheque.transactionType) {
        case transactionTypes.VendorPayment:
          processVendorPaymentReversal();
          break;
        case transactionTypes.InvoicePayment:
          processInvoicePaymentReversal();
          break;
        default:
          log.error('Attempt to reverse cheque for unsupported transaction type', ctrl.view.cheque.transactoinType);
      }
    }

    function processVendorPaymentReversal() {
      const accountType = ctrl.view.cheque.bankAccountType;
      sbVendorPaymentsService.reversalPayToVendorP({
        accountType,
        bankAccountId: ctrl.view.cheque.bankAccountId,
        transactionId: ctrl.view.cheque.transactionIds[0],
        reason: ctrl.model.reason,
        deleteTransaction: ctrl.model.delete
      })
        .then(() => {
          closeModal();
          messageDisplayService.success(
            messageDisplayService
              .builder()
              .text(`Payment ${ctrl.model.delete ? 'deletion' : 'reversal'} processed`)
              .group('insufficient-funds')
          );
        })
        .catch((err) => {
          closeModal();
          log.error('Problem processing reversal', err);
          messageDisplayService.error(
            messageDisplayService
              .builder()
              .title(`${ctrl.model.delete ? 'deletion' : 'reversal'} not processsed`)
              .text(`Failed to process payment ${ctrl.model.delete ? 'deletion' : 'reversal'}. Please check your connection and try again`)
          );
        });
    }

    async function processInvoicePaymentReversal() {
      //Reversals can over draw matter or firm trust balance in Au for compliance
      try {
        await sbPaymentService.reverseInvoicePaymentP({
          paymentId: ctrl.view.cheque.paymentIds[0], // For a trust to office transfer cheque, the backend will reverse all related payments when one is provided. 
          reason: ctrl.model.reason,
          deleteTransaction: ctrl.model.delete,
          allowOverdraw: hasFacet(facets.allowOverdraw),
        });

        messageDisplayService.success(
          messageDisplayService
            .builder()
            .text(`Invoice payment ${ctrl.model.delete ? 'deletion' : 'reversal'} processed`)
        );
      }
      catch (err) {
        log.error('Problem processing reversal', err);
        messageDisplayService.error(
          messageDisplayService
            .builder()
            .title(`${ctrl.model.delete ? 'Deletion' : 'Reversal'} not processsed`)
            .text(`Failed to process ${ctrl.model.delete ? 'deletion' : 'reversal'}. Please check your connection and try again`)
        );
      }

      closeModal();
    }

    function closeModal() {
      ctrl.onClose({ dismiss: true });
    }
  }
});
