'use strict';

import { store, flushPersistedStateP, RESET_NON_PERSISTED_REDUX_DATA } from '@sb-itops/redux';
import { getLogger } from '@sb-itops/fe-logger';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { destroyApolloClient } from 'web/services/apollo';
import { logoutP, logoutAllP, getOwningCompany } from 'web/services/user-session-management';
import { teardownPostAuthenticationBootstrapP } from 'web/services/bootstrapper';
import { clearAllCommandDispatchSubscriptions } from '@sb-integration/web-client-sdk';
import * as applicationState from 'web/redux/features/application-state';
import * as billingReports from 'web/redux/route/home-billing-reports/feature';

const getIsPrivateComputer = () => applicationState.selectors.getIsPrivateComputer(store.getState());
const { resetReportState } = billingReports.actions;

angular.module('sb.billing.webapp').controller('SbLogoutController', function ($state, $rootScope, sbGenericCacheService, sbUnsavedChangesService, sbTabService) {
  const log = getLogger('SbLogoutController');
  const ctrl = this;

  ctrl.isTriConveyBranding = getOwningCompany() === 'TriConvey';

  // This IIFE performs all of the clean-up tasks associated with a logout and calls user-session-management service
  // to perform the actual logout. Take caution if modifying the try/catch blocks within the logout() function; they were
  // chosen carefully to make a "best effort" at logout. We don't want an early failure of clean-up to prevent us from
  // cleaning up as much as we can.
  (async function logout() {
    // Bootstrapper teardown
    teardownPostAuthenticationBootstrapP();

    // Clear event listener subscriptions in the web-client-sdk.
    clearAllCommandDispatchSubscriptions();

    // Shut down the generic cache and clear its data.
    try {
      // If the user was unregistered (the firm/user mapping is removed), then clear persistent storage
      const unregisteredUser = Boolean($state.params.unregisteredUser);

      const clearPersistentStorage = !getIsPrivateComputer() || unregisteredUser;
      log.info(`Shutting down caches, clearPersistentStorage = ${clearPersistentStorage}`);
      await sbGenericCacheService.clearAllCachesP({ clearPersistentStorage });
    } catch (err) {
      log.warn('Failed to clear all caches, manual clean-up may be required', err);
    }

    // Clear non-persisted in memory data from redux.
    // Report state is persist because we want to maintain the state of showFilters
    // Therefore we need to clear report state here
    try {
      store.dispatch({ type: RESET_NON_PERSISTED_REDUX_DATA, payload: {} });
      store.dispatch(resetReportState());
    } catch (err) {
      log.warn('Failed to clear redux data, refresh required', err);
    }

    // Clear services that maintain state which is effectively per user data.
    try {
      sbUnsavedChangesService.clearMemory();
      sbTabService.closeAll();

      delete $rootScope.returnToState;
      delete $rootScope.returnToStateParams;
      delete $rootScope.sbPermissions;
    } catch (err) {
      log.warn('Failed to clear miscellaneous service state', err);
    }

    // Call logout in the user-session-management service to try to invalidate the user's tokens.
    // If the logout fails, the tokens will eventually time out anyway. Given that we have already
    // cleaned-up local state, it's relatively safe to ignore a logout failure here.
    const logoutAllDevices = $state.params.allDevices;

    try {
      logoutAllDevices ? await logoutAllP() : await logoutP();
    } catch (err) {
      log.warn('Failed to complete logout ', err);

      if (logoutAllDevices) {
        displayErrorToUser('Failed to log out from all devices. Please contact Smokeball support for more information');
      }
    }

    // Wait for any in memory redux state to be fully persisted (if required) before continuing.
    try {
      await flushPersistedStateP();
    } catch (err) {
      log.warn('Failed to flush state to persistence', err);
    }

    // Clear all Apollo GraphQL caches.
    // Moved down here to give RESET_NON_PERSISTED_REDUX_DATA enough time to run, so that the
    // getAccountId in user-session-management is cleared before it gets used by apollo link
    try {
      const apolloClientDestroyed = await destroyApolloClient();

      if (!apolloClientDestroyed) {
        throw new Error('Could not clear apollo client');
      }
    } catch (err) {
      log.warn('Failed to clear apollo cache, refresh required', err);
    }

    // If present, remove 'uid' parameter that is passed from the desktop app.
    // Not needed for next user logging in, and could cause a mismatch.
    const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
    if (urlParams.has('uid')) {
      urlParams.delete('uid');
      // Force change window.location (page reload) because $state.go keeps on reinstating the 'uid' parameter if we attempt history.replaceState
      window.location.search = [...urlParams.keys()].length ? `?${urlParams.toString()}/` : '';
    }

    // Clean-up is complete, take the user to the login page.
    $state.go('login');

  })();
});
