/*
Generic UI state reducers. State is stored under mandatory route and id properties
*/

export const getRouteIdKey = (route, id) => `${route}-${id}`;

export const addItemReducer = (state, action) => {
  const { route, id } = action.meta;
  const key = getRouteIdKey(route, id);

  if (!state[key]) {
    return { ...state, [key]: { [action.payload.key]: action.payload.value } };
  }

  // quick and dirty way of avoiding immutability - could be better
  const next = JSON.parse(JSON.stringify(state));
  next[key][action.payload.key] = action.payload.value;

  return next;
};

export const clearStateReducer = (state, action) => {
  const { route, id } = action.meta;
  const routeIdKey = getRouteIdKey(route, id);
  const next = {};

  Object.keys(state).forEach((key) => {
    if (!key.startsWith(routeIdKey)) {
      next[key] = state[key];
    }
  });

  return next;
};
