import React from 'react';
import Styles from './Redacted.module.scss';

type TProps = {
  redacted?: Record<string, number>;
  field: string;
  singular: string;
  plural: string;
};

export const RedactedInfo = (props: TProps) => {
  const count = props.redacted?.[props.field] || 0;
  const word = count === 1 ? props.singular : props.plural;
  const isAre = count === 1 ? 'is' : 'are';
  return count ? (
    <div className={Styles.message}>
      ⚠️ {count} {word} {isAre} restricted from view as you do not have permission to view the associated matter.
    </div>
  ) : null;
};
