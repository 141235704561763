/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ExpandCollapseToggler } from '@sb-itops/react/expand-collapse-toggler';
import { getRegion } from '@sb-itops/region';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { InvoiceDisplay } from '@sb-billing/react/invoice-display';
import { CurrencyInput2, CurrencyDisplay, Button, useTranslation } from '@sb-itops/react';
import { SlidingToggle } from '@sb-itops/react/sliding-toggle';
import { Checkbox } from '@sb-itops/react/checkbox';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { MatterDisplay } from '@sb-matter-management/react';
import { capitalize } from '@sb-itops/nodash';
import classnames from 'classnames';
import { PrintLater, printMethodsByValue, notApplicablePrintMethodLocalised } from '@sb-billing/business-logic/cheques';
import { InvoiceZeroBalanceModal } from 'web/react-redux';
import Styles from './FinaliseWithPaymentsTable.module.scss';

const REGION = getRegion();

const { checkboxCellWrapperRenderer, balanceCellLocalisedRenderer } = utils;
const FinaliseWithPaymentsTable = memo((props) => {
  const {
    sort,
    sortBy,
    sortDirection,
    rows,
    initialised,
    // invoiceIds,
    matterIds,
    matterRows,
    setInitialised,
    // onToggleSelectAll,
    onToggleAutoAllocateAll,
    selectedInvoiceIds,
    trustChequesEnabled,
    onChangeChequePrintingMethod,
    onFinalise,
    chequePrintingMethod,
    isError,
    payments,
    selected,
    isZeroBalanceInvoiceVisible,
    zeroBalanceInvoiceCount,
    hideZeroBalanceModal,
    showZeroBalanceModal,
    mattersWithPayments,
    setMattersWithPayments,
  } = props;

  const { t } = useTranslation();

  if (!initialised) {
    // onToggleSelectAll(invoiceIds, true);
    onToggleAutoAllocateAll(matterIds, true);
    setInitialised(true);
    return null;
  }

  const onClickFinalise = () => {
    let invoicesZeroBalanceCount = 0;
    let invoicesSelectedCount = 0;
    const invoiceTotalDueMap = rows.reduce((acc, row) => {
      // Rows can be of type matter or invoice
      if (row.type !== 'INVOICE') {
        return acc;
      }

      acc[row.id] = row.totalDue;
      return acc;
    }, {});

    const matterPaymentGroups = matterRows.reduce((mpgs, matterRow) => {
      const invoicePayments = matterRow.invoiceIds.reduce((ips, invoiceId) => {
        const isSelectedInvoice = selected[invoiceId];

        if (isSelectedInvoice) {
          invoicesSelectedCount += 1;
        }

        if (isSelectedInvoice && payments[invoiceId]) {
          ips.push({ invoiceId, payments: payments[invoiceId] });
        }

        // Display "zero dollar balance warning" modal if an invoice with $0 totalDue is to be finalized and paid
        if (isSelectedInvoice && invoiceTotalDueMap[invoiceId] === 0) {
          invoicesZeroBalanceCount += 1;
        }

        return ips;
      }, []);
      if (invoicePayments.length > 0) {
        // eslint-disable-next-line no-param-reassign
        mpgs[matterRow.id] = invoicePayments;
      }
      return mpgs;
    }, {});

    if (invoicesZeroBalanceCount > 0) {
      // save matter payments to state so that we can use it in the modal
      setMattersWithPayments(matterPaymentGroups);
      showZeroBalanceModal(invoicesSelectedCount);
    } else {
      onFinalise({ selectedInvoiceIds, matterPaymentGroups, chequePrintingMethod });
    }
  };

  const notApplicablePrintOption = notApplicablePrintMethodLocalised(t);

  return (
    // the panel body and the ribbon bar shouldnt really live here - they break cohesion & single responsiblility.
    // they are here temporarily due to my mismanagement of state locality, where the container for this table is creating the
    // rows. This should happen higher up in the route component itself (and through a selector), as the error state (which is derived from the invoices + payments)
    // is used by the `Finalize & Pay` button, in addition to the table rows.
    <>
      <div className={classnames('ribbon', 'panel', 'panel-primary', Styles.ribbonAvoidConflict)}>
        <Button onClick={onClickFinalise} disabled={isError || selectedInvoiceIds.length === 0}>
          {t('capitalize', { val: 'finalise' })} & Pay ({selectedInvoiceIds.length})
        </Button>
        {trustChequesEnabled && (
          <div>
            {`${capitalize(t('cheque'))} Printing Method:`}
            <select
              className={classnames('form-control', Styles.chequePrintingMethodDropdown)}
              value={chequePrintingMethod}
              onChange={onChangeChequePrintingMethod}
            >
              <option value={notApplicablePrintOption.value}>{notApplicablePrintOption.display}</option>
              <option value={PrintLater}>{printMethodsByValue[PrintLater].display}</option>
            </select>
          </div>
        )}
      </div>

      <Table
        list={rows}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowClassName={({ rowData }) => (rowData && rowData.type === 'INVOICE' ? Styles.invoiceRow : 'matter-row')}
        onRowClick={onRowClicked(props)}
      >
        <Column
          className="row-expander"
          dataKey="rowExpander"
          label=""
          width={25}
          headerRenderer={expandAllRowsRenderer(props)}
          cellRenderer={expanderCellRenderer(props)}
          disableSort
        />
        <Column
          dataKey="rowSelector"
          label=""
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(toggleAllSelectionRenderer(props))}
          cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer(props))}
          disableSort
        />
        <Column
          dataKey="matterDisplay"
          label="Matter"
          cellRenderer={matterOrInvoiceNumberCellRenderer(props, t)}
          flexGrow={5}
        />
        <Column dataKey="debtor" label="Debtor(s)" cellRenderer={debtorCellRenderer(props)} flexGrow={5} disableSort />
        <Column
          className="right-align"
          dataKey="totalDue"
          label="Amount Due"
          cellRenderer={balanceCellLocalisedRenderer}
          width={100}
          footerRenderer={balanceCellLocalisedRenderer}
        />
        <Column
          className="right-align"
          dataKey="creditBalance"
          label="Credit Balance"
          width={100}
          flexGrow={1}
          cellRenderer={accountCellRenderer(props, 'credit')}
        />
        <Column
          className={Styles.paymentAmount}
          dataKey="creditAmount"
          label="Credit Payments"
          width={100}
          cellRenderer={paymentAmountCellRenderer(props, 'credit')}
          flexGrow={1}
          disableSort
        />
        <Column
          className="right-align"
          dataKey="trustBalance"
          label={`${t('trust')} Balance`}
          width={100}
          flexGrow={1}
          cellRenderer={accountCellRenderer(props, 'trust')}
        />
        <Column
          className={Styles.paymentAmount}
          dataKey="trustAmount"
          label={`${t('trust')} Payments`}
          width={100}
          cellRenderer={paymentAmountCellRenderer(props, 'trust')}
          flexGrow={1}
          disableSort
        />
        <Column
          className="right-align"
          dataKey="operatingBalance"
          label={`${t('operating')} Balance`}
          width={100}
          flexGrow={1}
          cellRenderer={accountCellRenderer(props, 'operating')}
        />
        <Column
          className={Styles.paymentAmount}
          dataKey="operatingAmount"
          label={`${t('operating')} Payments`}
          width={100}
          flexGrow={1}
          cellRenderer={paymentAmountCellRenderer(props, 'operating')}
          disableSort
        />
        <Column
          className={Styles.autoAllocate}
          dataKey="isAutoAllocated"
          label="Auto Allocate"
          cellRenderer={autoAllocateRenderer(props)}
          width={50}
          flexGrow={1}
        />
      </Table>
      <InvoiceZeroBalanceModal
        isVisible={isZeroBalanceInvoiceVisible}
        invoiceCount={zeroBalanceInvoiceCount}
        onConfirm={() => {
          hideZeroBalanceModal();
          onFinalise({ selectedInvoiceIds, matterPaymentGroups: mattersWithPayments, chequePrintingMethod });
        }}
        onCancel={hideZeroBalanceModal}
      />
    </>
  );
});

function onRowClicked({ onToggleMatterExpanded }) {
  return ({ rowData }) => {
    // Invoice rows do not need an expander, just the spacing for the column.
    if (rowData.type !== 'MATTER') {
      return;
    }

    onToggleMatterExpanded(rowData.id, !rowData.isExpanded);
  };
}

function expandAllRowsRenderer({ matterIds, allExpanded, onToggleAllMattersExpanded }) {
  return () => (
    <ExpandCollapseToggler
      className={Styles.headerExpandCollapseToggler}
      isExpanded={allExpanded}
      onToggled={() => onToggleAllMattersExpanded(matterIds, !allExpanded)}
    />
  );
}

function expanderCellRenderer({ onToggleMatterExpanded }) {
  return ({ rowData }) => {
    // Invoice rows do not need an expander, just the spacing for the column.
    if (rowData.type !== 'MATTER') {
      return null;
    }

    return (
      <ExpandCollapseToggler
        isExpanded={rowData.isExpanded}
        className={Styles.expandCollapseToggler}
        onToggled={() => onToggleMatterExpanded(rowData.id, !rowData.isExpanded)}
      />
    );
  };
}

function toggleAllSelectionRenderer({ invoiceIds, allSelected, onToggleSelectAll }) {
  return () => (
    <Checkbox
      className="row-checkbox"
      checked={allSelected}
      onChange={() => {
        onToggleSelectAll(invoiceIds, !allSelected);
      }}
    />
  );
}

function checkboxCellRenderer({ onToggleInvoiceSelected, onToggleMatterSelected }) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return (
        <Checkbox
          checked={rowData.isSelected}
          onChange={() => onToggleMatterSelected(rowData.invoiceIds, !rowData.isSelected)}
        />
      );
    }
    return (
      <Checkbox
        checked={rowData.isSelected}
        onChange={() => onToggleInvoiceSelected(rowData.id, !rowData.isSelected)}
      />
    );
  };
}

function matterOrInvoiceNumberCellRenderer({ onClickLink }, t) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return (
        <div className={Styles.matterRowContainer}>
          <MatterDisplay onClickLink={onClickLink} asLink matterId={rowData.id} />
          {rowData.isMultipleContactBalances && (
            <ContextMenu
              body={() =>
                'This matter has retainer funds from multiple contacts, funds will be taken from the contact ' +
                'with the lowest retainer balance first when finalizing with payments. To allocate by contact, ' +
                `please ${t('finalise')} individually.`
              }
              timeout={0}
              showOnHover
            >
              <span className="icon icon-contacts-1" />
            </ContextMenu>
          )}
        </div>
      );
    }
    return <InvoiceDisplay invoiceId={rowData.id} onClickLink={onClickLink} asLink />;
  };
}

function debtorCellRenderer({ onClickLink }) {
  return ({ rowData }) => {
    if (rowData.type === 'MATTER') {
      return null;
    }

    return (
      rowData &&
      rowData.debtorIds && (
        <div className={Styles.invoiceDebtors}>
          <span className={Styles.debtorsDisplay}>
            {rowData.debtorIds
              .map((debtorId) => (
                <ContactDisplay
                  key={debtorId}
                  onClickLink={onClickLink}
                  asLink
                  contactId={debtorId}
                  inline
                  className={Styles.debtor}
                  showLastNameFirst
                />
              ))
              .reduce((acc, elem) => {
                if (acc === null) {
                  return [elem];
                }
                return [...acc, ' | ', elem];
              }, null)}
          </span>
          {rowData.debtorIds.length > 1 && (
            <span className={Styles.multiDebtorsIcon}>
              <i title={rowData.debtorDisplay} className={classnames('icon', 'icon-contacts-1')} />
            </span>
          )}
        </div>
      )
    );
  };
}

function autoAllocateRenderer({ onToggleAutoAllocate }) {
  return ({ rowData }) => {
    if (rowData.type !== 'MATTER') {
      return null;
    }

    return (
      <label className={Styles.autoAllocateCell}>
        <SlidingToggle
          scope={rowData.id}
          name={rowData.id}
          selected={rowData.isAutoAllocated}
          onChange={() => onToggleAutoAllocate(rowData.id, !rowData.isAutoAllocated)}
        />
      </label>
    );
  };
}

function paymentAmountCellRenderer({ onChangePaymentAmount, bankAccountIds }, accountType) {
  return ({ rowData }) => {
    const paymentAmount = Math.max(0, rowData[`${accountType}Amount`]);

    if (rowData.type === 'MATTER') {
      return (
        <CurrencyDisplay
          amount={paymentAmount}
          asInput={false}
          styleClass={classnames(rowData[`${accountType}IsError`] && !rowData.isExpanded && Styles.errorText)}
          region={REGION}
        />
      );
    }

    const accountBalance = Math.max(0, rowData[`${accountType}Balance`]);
    const max = Math.min(rowData.totalDue, accountBalance);
    const isError = rowData[`${accountType}Error`];
    const isDisabled = isError
      ? false
      : rowData.isAutoAllocated || accountBalance === 0 || !rowData.isSelected || rowData.totalDue === 0;
    return (
      <CurrencyInput2
        className="currency-input"
        value={paymentAmount}
        min={0}
        max={max}
        disabled={isDisabled}
        onChange={(e) => onChangePaymentAmount(bankAccountIds[accountType.toUpperCase()], rowData.id, e.target.value)}
        hasError={isError}
        hideDollar
      />
    );
  };
}

function accountCellRenderer(props, accountType) {
  return ({ rowData }) => (
    <CurrencyDisplay amount={Math.max(0, rowData[`${accountType}Balance`])} asInput={false} region={REGION} />
  );
}

FinaliseWithPaymentsTable.displayName = 'FinaliseWithPaymentsTable';

FinaliseWithPaymentsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  initialised: PropTypes.bool.isRequired,
  invoiceIds: PropTypes.array.isRequired,
  matterIds: PropTypes.array.isRequired,
  setInitialised: PropTypes.func.isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  onFinalise: PropTypes.func.isRequired,
  selectedInvoiceIds: PropTypes.array.isRequired,
  trustChequesEnabled: PropTypes.bool.isRequired,
  onChangeChequePrintingMethod: PropTypes.func.isRequired,
  // chequePrintingMethod will be undefined if chequePrinting is disabled
  chequePrintingMethod: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onToggleAllMattersExpanded: PropTypes.func.isRequired,
  onToggleMatterExpanded: PropTypes.func.isRequired,
  onToggleInvoiceSelected: PropTypes.func.isRequired,
  onToggleMatterSelected: PropTypes.func.isRequired,
  onToggleAutoAllocate: PropTypes.func.isRequired,
  onToggleAutoAllocateAll: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangePaymentAmount: PropTypes.func.isRequired,
  payments: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  matterRows: PropTypes.array.isRequired,
  isZeroBalanceInvoiceVisible: PropTypes.bool.isRequired,
  zeroBalanceInvoiceCount: PropTypes.number.isRequired,
  hideZeroBalanceModal: PropTypes.func.isRequired,
  showZeroBalanceModal: PropTypes.func.isRequired,
  mattersWithPayments: PropTypes.object,
  setMattersWithPayments: PropTypes.func.isRequired,
};

FinaliseWithPaymentsTable.defaultProps = {
  chequePrintingMethod: undefined,
  mattersWithPayments: undefined,
};

export default FinaliseWithPaymentsTable;
