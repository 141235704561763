'use strict';

const { parseJwt } = require('./parse-jwt');

/**
 * @typedef { import('../entities/types.js').SbBillingAuthProviderIdentity } SbBillingAuthProviderIdentity
 * @typedef { import('../entities/types.js').UserIdentity } UserIdentity
 */

/**
 * Non-standard fields provided by the SbBillingAuthProvider.
 * @typedef {Object} SbBillingAuthProviderFields
 * @property {string} idToken - Id token jwt
 * @property {string} accessToken - Access token jwt
 * @property {string} deviceKey - Device key from which the authentication request originated.
 */

/* End SbBillingAuthProvider specific types */

class UserIdentityFactory {
  /**
   * fromSbBillingAuthenticationProviderIdentity
   * Creates a {@link UserIdentity} object from a {@link SbBillingAuthProviderResult}.
   * @param {SbBillingAuthProviderIdentity} sbBillingAuthProviderIdentity
   * @return {UserIdentity} A valid user identity.
   */
  static fromSbBillingAuthenticationProviderIdentity(sbBillingAuthProviderIdentity) {
    const { region, id, access, deviceKey } = sbBillingAuthProviderIdentity;
    const decodedIdToken = parseJwt({ jwt: id });
    const roles = (decodedIdToken['custom:smokeball:roles'] || '').split(',');

    // worth noting that a Firm Owner have 4 roles currently, one of which is FirmAdministrator,
    // which is sufficient to determine ownership. But to make a user a firm owner, it's not
    // sufficient to set the FirmAdministrator role.
    const isFirmOwner = roles.includes('FirmAdministrator');

    return {
      region,
      authToken: id, // SbBillingAuthProvider expects HTTP requests to use id token as the bearer.
      username: decodedIdToken.email,
      tokenIssuedAt: decodedIdToken.iat,
      tokenExpiresAt: decodedIdToken.exp,
      browserTimeAtTokenIssue: Math.floor(Date.now() / 1000),
      userId: decodedIdToken['cognito:username'],
      roles,
      isFirmOwner,
      accountId: decodedIdToken['custom:smokeball:firmId'],
      productTier: decodedIdToken['custom:smokeball:productId'],
      owningCompany: decodedIdToken['custom:smokeball:owner'] || 'Smokeball', // Older users won't have an owner claim, meaning the owner is Smokeball.
      isMissingProductClaim: !decodedIdToken['custom:smokeball:productId'],
      provider: 'sbBillingAuthProvider',
      custom: {
        region,
        id,
        access,
        deviceKey,
      },
    };
  }
}

module.exports = {
  UserIdentityFactory,
};
