'use strict';

const { eInvoiceEnabledOptionValues } = require('./einvoice-enabled-options');

/**
 * Determine whether eInvoice should be enabled for a given matter based on matter
 * and firm settings provided. This method should only be called when BB-5725 eInvoice
 * is already turned on.
 * @param {Object} params.matterInvoiceSettings matter invoice settings
 * @param {Object} params.eInvoiceSettings firm eInvoice settings
 * @returns {boolean} indicating if eInvoice is enabled for the given matter
 */
function determineEInvoiceEnabled({ matterInvoiceSettings, eInvoiceSettings }) {
  // eInvoice is enabled only if
  // 1. BB-5725 is enabled as a feature switch (LD), and
  // 2. Matter settings has eInvoice enabled or Firm eInvoice setting has eInvoice enabled
  //    a) matter settings default to use firm default if eInvoiceEnabledOption is not set
  //    b) firm eInvoice setting defaults to enabled if eInvoiceDisabled is not set
  const eInvoiceEnabledForFirm = !eInvoiceSettings?.eInvoiceDisabled;
  const eInvoiceEnabledForMatter = matterInvoiceSettings?.eInvoiceEnabledOption === eInvoiceEnabledOptionValues.ENABLED;
  const useFirmDefault = [undefined, eInvoiceEnabledOptionValues.USE_FIRM_DEFAULT].includes(
    matterInvoiceSettings?.eInvoiceEnabledOption,
  );
  const eInvoiceEnabled = eInvoiceEnabledForMatter || (useFirmDefault && eInvoiceEnabledForFirm);

  return eInvoiceEnabled;
}

module.exports = {
  determineEInvoiceEnabled,
};
