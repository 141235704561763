'use strict';

const { buttonColours, buttonStyles, createButton } = require('@sb-itops/email');

const createPayNowButton = ({ url, label = 'PAY NOW', colour = buttonColours.GREEN, style = buttonStyles.SOLID }) => {
  const payNowButton = createButton({
    url,
    label,
    colour,
    style,
  });
  return payNowButton;
};

module.exports = {
  createPayNowButton,
};
