/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox, useTranslation, StatelessSelect, Chip, LoadingPlaceholderElement } from '@sb-itops/react';
import React from 'react';
import { AddTaskModalContainer, AddEventModalContainer } from 'web/containers';
import moment from 'moment';
import { humanise } from 'web/business-logic/calendar';
import classNames from 'classnames';
import recurringIcon from 'web/assets/icon-recurring.svg';
import { ChevronLeft, ChevronRight, Plus } from 'web/components/icons';
import * as Styles from './DailyDigestWidget.module.scss';

const getDayDisplay = (d, t) => {
  const diff = moment().startOf('day').diff(moment(d), 'days');

  if (diff === 0) {
    return 'Today';
  }
  if (diff === 1) {
    return 'Yesterday';
  }
  if (diff === -1) {
    return 'Tomorrow';
  }
  if (diff < -1 || diff > 1) {
    return t('date', { ts: d, format: 'Do [of] MMMM' });
  }
  return '';
};

const getOverdueStripe = (t) => {
  if (t.isCompleted) {
    return Styles.completed;
  }
  const diff = moment().startOf('day').diff(moment(t.dueDate), 'days');
  if (diff > 0) {
    return Styles.overdue;
  }
  if (diff === 0) {
    return Styles.dueToday;
  }
  return undefined;
};

const getOverdueDisplay = (t) => {
  const diff = moment().startOf('day').diff(moment(t.dueDate), 'days');

  if (t.isCompleted) {
    return null;
  }
  if (diff === 0) {
    return <div className={Styles.dueTodayText}>Due today</div>;
  }
  if (diff === 1) {
    return <div className={Styles.overdueText}>Due yesterday</div>;
  }
  if (diff === -1) {
    return <div>Due tomorrow</div>;
  }
  if (diff > 1) {
    return <div className={Styles.overdueText}>{diff} days overdue</div>;
  }
  if (diff < -1) {
    return <div>Due in {Math.abs(diff)} days</div>;
  }
  return null;
};

export const DailyDigestWidget = ({
  tasks,
  onClickLink,
  tasksLoading,
  onSaveTask,
  showTaskModal,
  setShowTaskModal,
  events,
  allDayEvents,
  eventsLoading,
  showEventModal,
  setShowEventModal,
  digestDay,
  setSelectedDay,
  selectedStaffId,
  setSelectedStaffId,
  staffOptions,
  selectedTab,
  setSelectedTab,
  incompleteTasks,
  completeTasks,
  staffMap,
  matterId,
}) => {
  const { t } = useTranslation();

  let taskList = tasks;
  if (selectedTab === 2) {
    taskList = incompleteTasks;
  }
  if (selectedTab === 3) {
    taskList = completeTasks;
  }

  return (
    <div className={Styles.digestContainer}>
      <div className={Styles.digestControls}>
        <div className={Styles.calendarNav}>
          <div className={Styles.iconButton} onClick={() => setSelectedDay(moment(digestDay).subtract(1, 'day'))}>
            <ChevronLeft />
          </div>
          {[...Array(7)].map((e, i) => (
            <div
              key={i}
              title={t('date', {
                date: moment(digestDay)
                  .add(i - 3, 'days')
                  .toDate(),
                format: 'Do MMMM',
              })}
              className={classNames(Styles.labelBlock, i - 3 === 0 && Styles.selected)}
              onClick={() => setSelectedDay(moment(digestDay).add(i - 3, 'days'))}
            >
              <div
                className={
                  moment(digestDay)
                    .add(i - 3, 'days')
                    .isBefore(moment().startOf('day'))
                    ? Styles.past
                    : undefined
                }
              >
                {t('date', {
                  date: moment(digestDay)
                    .add(i - 3, 'days')
                    .toDate(),
                  format: 'ddd',
                })}
              </div>
              {i - 3 === 0 && (
                <div className={Styles.selectedDate}>
                  {t('date', {
                    date: moment(digestDay)
                      .add(i - 3, 'days')
                      .toDate(),
                    format: 'Do MMMM',
                  })}
                </div>
              )}
            </div>
          ))}
          <div className={Styles.iconButton} onClick={() => setSelectedDay(moment(digestDay).add(1, 'day'))}>
            <ChevronRight />
          </div>
        </div>
        {!matterId && <div className={Styles.divider} />}
        {!matterId && (
          <div className={Styles.staffFilter}>
            <StatelessSelect
              placeholder="Filter by staff member"
              options={staffOptions}
              selectedOption={selectedStaffId}
              onChange={(newStaffOption) => setSelectedStaffId(newStaffOption?.value)}
              isClearable
              showDropdown={false}
            />
          </div>
        )}
      </div>
      <div className={Styles.digestBody}>
        <div className={Styles.digestWidget}>
          <div className={Styles.subHeading}>
            <div className={Styles.title}>
              {`${getDayDisplay(digestDay, t)}'s Tasks`}
              <Plus onClick={() => setShowTaskModal({ assigneeIds: [selectedStaffId] })} className={Styles.plus} />
              <a onClick={() => onClickLink({ type: matterId ? 'matterTasks' : 'productivityTasks', id: matterId })}>
                Go to Tasks
              </a>
            </div>
          </div>
          <div className={Styles.tabs}>
            <div className={classNames(selectedTab === 1 && Styles.selected)} onClick={() => setSelectedTab(1)}>
              <strong>{tasks.length}</strong> All
            </div>
            <div className={classNames(selectedTab === 2 && Styles.selected)} onClick={() => setSelectedTab(2)}>
              <strong>{incompleteTasks.length}</strong> Incomplete
            </div>
            <div className={classNames(selectedTab === 3 && Styles.selected)} onClick={() => setSelectedTab(3)}>
              <strong>{completeTasks.length}</strong> Completed
            </div>
          </div>
          {tasksLoading ? (
            <div className={Styles.loading}>
              <LoadingPlaceholderElement className={Styles.placeholder} />
            </div>
          ) : (
            <div className={Styles.content}>
              {taskList.length === 0 && 'No tasks'}
              {taskList.map((task, i) => (
                <div
                  key={task.id + i}
                  className={classNames(Styles.task, getOverdueStripe(task))}
                  onClick={() => {
                    setShowTaskModal(task);
                  }}
                >
                  <Checkbox
                    checked={!!task.isCompleted}
                    onChange={(value) => onSaveTask({ ...task, isCompleted: value })}
                    className={Styles.checkbox}
                  />
                  <div className={Styles.textBox}>
                    <div title={task.subject} className={classNames(Styles.overflowText, Styles.subject)}>
                      {task.subject}
                    </div>
                    {!matterId && (
                      <div
                        title={(task.matter?.clients || []).map((c) => c.displayName).join(' | ')}
                        className={classNames(Styles.assignee)}
                      >
                        {(task.matter?.clients || []).map((c, index) => (
                          <span key={`${task.id}${c.id || ''}`} className={Styles.client}>
                            {index > 0 && ' | '}
                            {c.displayName}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className={classNames(Styles.overflowText, Styles.alignRight, Styles.noShrink)}>
                      {getOverdueDisplay(task)}
                    </div>
                  </div>
                  {!!task?.matter?.matterType?.name && (
                    <Chip className={Styles.matter} label={task?.matter?.matterType?.name} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={Styles.digestWidget}>
          <div className={Styles.subHeading}>
            <div className={Styles.title}>
              {`${getDayDisplay(digestDay, t)}'s Events`}
              <Plus onClick={() => setShowEventModal({ resourceIds: [selectedStaffId] })} className={Styles.plus} />
              <a
                onClick={() => onClickLink({ type: matterId ? 'matterEvents' : 'productivityCalendar', id: matterId })}
              >
                Go to Calendar
              </a>
            </div>
          </div>
          {eventsLoading ? (
            <LoadingPlaceholderElement className={Styles.placeholder} />
          ) : (
            <div>
              {!!allDayEvents?.length && (
                <div className={Styles.eventBlock}>
                  {allDayEvents.map((event) => (
                    <div
                      key={(event.id || event.originalId) + event.endTime}
                      className={Styles.event}
                      onClick={() => {
                        setShowEventModal(event);
                      }}
                    >
                      <div className={Styles.time}>
                        <div className={Styles.duration}>All Day</div>
                      </div>
                      <div className={Styles.description}>
                        <div title={event.subject} className={Styles.subject}>
                          {event.subject}
                        </div>
                        {!matterId && (
                          <div
                            title={(event.matter?.clients || []).map((c) => c.displayName).join(' | ')}
                            className={Styles.staff}
                          >
                            {(event.matter?.clients || []).map((c, index) => (
                              <span key={`${event.id}${c.id || ''}`} className={Styles.client}>
                                {index > 0 && ' | '}
                                {c.displayName}
                              </span>
                            ))}
                          </div>
                        )}
                        <div className={Styles.staff}>{event.location || ''}</div>
                      </div>
                      {event.originalId && (
                        <div className={Styles.recurring}>
                          <img alt="This event is recurring" src={recurringIcon} />
                        </div>
                      )}
                      {event?.matter?.matterType?.name && (
                        <Chip className={Styles.matter} label={event?.matter?.matterType?.name} />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {events.length === 0 && allDayEvents.length === 0 && 'No events'}
              {events.length !== 0 && (
                <div className={Styles.eventBlock}>
                  {events.map((event, i) => [
                    i > 0 &&
                      moment(event.endTime).isAfter(moment()) &&
                      !moment(events[i - 1].endTime).isAfter(moment()) && (
                        <div key="current-time" className={Styles.currentTime} />
                      ),
                    <div
                      key={(event.id || event.originalId) + event.endTime}
                      className={classNames(Styles.event, !moment(event.endTime).isAfter(moment()) && Styles.past)}
                      onClick={() => {
                        setShowEventModal(event);
                      }}
                    >
                      <div className={Styles.time}>
                        <div>{moment(event.startTime).format('hh:mm A')}</div>
                        <div className={Styles.duration}>
                          {humanise(moment.duration(moment(event.endTime).diff(moment(event.startTime))))}
                        </div>
                      </div>
                      <div className={Styles.description}>
                        <div title={event.subject} className={Styles.subject}>
                          {event.subject}
                        </div>
                        {!matterId && (
                          <div
                            title={(event.matter?.clients || []).map((c) => c.displayName).join(' | ')}
                            className={Styles.staff}
                          >
                            {(event.matter?.clients || []).map((c, index) => (
                              <span key={`${event.id}${c.id || ''}`} className={Styles.client}>
                                {index > 0 && ' | '}
                                {c.displayName}
                              </span>
                            ))}
                          </div>
                        )}
                        <div className={Styles.staff}>{event.location || ''}</div>
                      </div>
                      {event.originalId && (
                        <div className={Styles.recurring}>
                          <img alt="This event is recurring" src={recurringIcon} />
                        </div>
                      )}
                      {event?.matter?.matterType?.name && (
                        <Chip className={Styles.matter} label={event?.matter?.matterType?.name} />
                      )}
                    </div>,
                  ])}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showTaskModal && (
        <AddTaskModalContainer
          matterId={matterId}
          scope="widgets.task"
          task={showTaskModal}
          completer={showTaskModal.completerId && staffMap[showTaskModal.completerId]}
          onClose={() => setShowTaskModal(false)}
        />
      )}
      {showEventModal && (
        <AddEventModalContainer
          matterId={matterId}
          appointmentData={showEventModal}
          onClose={() => setShowEventModal(false)}
        />
      )}
    </div>
  );
};
