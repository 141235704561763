
'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/subscription-complete';

  // this is the landing page for trial subscription should Stripe
  // decides a url redirection is required upon confirmSetup(). 
  $stateProvider
    .state('subscription-complete', {
      url: '/subscription-complete',
      templateUrl: BASE_DIRECTORY + '/subscription-complete.html',
      controllerAs: '$ctrl',
      controller: function($state, sbLinkService) {
        const ctrl = this;
        ctrl.onClickLink = sbLinkService.onClickLink;
      }
    });
});
