import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { InvoiceListActionBar, InvoicesFilterPanel, InvoiceTable } from 'web/components';
import { MessageBar, PaginationSelector } from '@sb-itops/react';
import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';

import Styles from './BillingContactInvoicesRoute.module.scss';

export const BillingContactInvoicesRoute = React.memo(
  ({
    invoiceDataLoading,
    isBulkCreateInvoicesInProgress,
    /** Invoice filter panel */
    invoiceListReduxState,
    invoiceStatusCounts,
    /** Invoice list action bar */
    contactId,
    isCombineInPdfInProgress,
    isDownloadLedesInProgress,
    sbAsyncOperationsService,
    sbSaveInvoiceCommand,
    scope,
    selectedInvoicesByAction,
    // Callbacks
    onDeselectDeletedDraftInvoices,
    onFinaliseDraftInvoicesSuccess,
    toggleInvoicesToCreateStatement,
    /** Invoice table */
    invoices,
    invoicesOnSort,
    invoicesSortBy,
    invoicesSortDirection,
    invoiceStatuses,
    selectedInvoices,
    showDebtor,
    showMatter,
    showStatement,
    latestCorrespondencePerContact,
    latestCorrespondenceLoading,
    // Callbacks
    onClickLink,
    onOpenInvoiceCommunicateModal,
    onToggleInvoices,
    onFetchLatestCorrespondenceDetailsForInvoice,
    /** Invoice list action bar & Invoice table */
    onOpenInvoiceEmailModal,
    onOpenMarkInvoiceAsSentModal,
    /** Pagination */
    isPaginationHidden,
    invoicesCurrentPage,
    invoicesTotalNumberOfPages,
    invoicesOnPageChange,
  }) => (
    <div className={Styles.billingContactInvoicesRoute}>
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        <div className={Styles.invoicesSidePanel}>
          <InvoicesFilterPanel
            invoiceListReduxState={invoiceListReduxState}
            invoiceStatusCounts={invoiceStatusCounts}
          />
        </div>
        <div className={classnames('panel-body', Styles.panelContent)}>
          {/* Invoice list action bar */}
          <div className="ribbon panel panel-primary">
            <InvoiceListActionBar
              contactId={contactId}
              isCombineInPdfInProgress={isCombineInPdfInProgress}
              isDownloadLedesInProgress={isDownloadLedesInProgress}
              sbSaveInvoiceCommand={sbSaveInvoiceCommand}
              sbAsyncOperationsService={sbAsyncOperationsService}
              scope={scope}
              selectedInvoicesByAction={selectedInvoicesByAction}
              showAddCredit
              showAddPayment
              showBulkActions
              showDownloadOptions
              showCreateStatement={showStatement}
              // Callbacks
              onDeselectDeletedDraftInvoices={onDeselectDeletedDraftInvoices}
              onFinaliseDraftInvoicesSuccess={onFinaliseDraftInvoicesSuccess}
              toggleInvoicesToCreateStatement={toggleInvoicesToCreateStatement}
              onOpenInvoiceEmailModal={onOpenInvoiceEmailModal}
              onOpenMarkInvoiceAsSentModal={onOpenMarkInvoiceAsSentModal}
              onClickLink={onClickLink}
            />
          </div>
          {isBulkCreateInvoicesInProgress && (
            <div>
              <MessageBar
                messageType="warn"
                messageText="A bulk invoice request is currently being processed - this table will update with new entries on completion."
              />
            </div>
          )}
          {/* Invoice table */}
          <div className={Styles.invoiceTableContainer}>
            <InvoiceTable
              invoices={invoices}
              invoiceStatuses={invoiceStatuses}
              isLoading={invoiceDataLoading}
              selectedInvoices={selectedInvoices}
              showDebtor={showDebtor}
              showMatter={showMatter}
              showStatement={showStatement}
              sort={invoicesOnSort}
              sortBy={invoicesSortBy}
              sortDirection={invoicesSortDirection}
              latestCorrespondenceLoading={latestCorrespondenceLoading}
              latestCorrespondencePerContact={latestCorrespondencePerContact}
              onFetchLatestCorrespondenceDetailsForInvoice={onFetchLatestCorrespondenceDetailsForInvoice}
              // Callbacks
              onClickLink={onClickLink}
              onMarkInvoiceAsSent={(rowData) => onOpenMarkInvoiceAsSentModal({ invoiceIds: [rowData.invoiceId] })}
              onSendInvoiceCommunicate={(rowData) =>
                onOpenInvoiceCommunicateModal({ invoiceIds: [rowData.invoiceId], contactId: rowData.contactId })
              }
              onSendInvoiceEmail={(rowData) =>
                onOpenInvoiceEmailModal({ invoiceIds: [rowData.invoiceId], contactId: rowData.contactId })
              }
              onToggleInvoices={onToggleInvoices}
            />
          </div>
          {/* Pagination */}
          <PaginationSelector
            name="contact-invoice-list"
            className={Styles.paginationSection}
            hidePagination={isPaginationHidden}
            numberOfPagesDisplayed={10}
            selectedPage={invoicesCurrentPage}
            totalNumberOfPages={invoicesTotalNumberOfPages}
            onPageChange={invoicesOnPageChange}
          />
        </div>
      </div>
    </div>
  ),
);

BillingContactInvoicesRoute.displayName = 'BillingContactInvoicesRoute';

BillingContactInvoicesRoute.propTypes = {
  invoiceDataLoading: PropTypes.bool.isRequired,
  isBulkCreateInvoicesInProgress: PropTypes.bool.isRequired,
  /** Invoice filter panel */
  invoiceListReduxState: PropTypes.object.isRequired,
  invoiceStatusCounts: PropTypes.object.isRequired,
  /** Invoice list action bar */
  contactId: PropTypes.string.isRequired,
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  selectedInvoicesByAction: PropTypes.object.isRequired,
  // Callbacks
  onDeselectDeletedDraftInvoices: PropTypes.func.isRequired,
  onFinaliseDraftInvoicesSuccess: PropTypes.func.isRequired,
  toggleInvoicesToCreateStatement: PropTypes.func.isRequired,
  /** Invoice table */
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoicesOnSort: PropTypes.func.isRequired,
  invoicesSortBy: PropTypes.string.isRequired,
  invoicesSortDirection: PropTypes.string.isRequired,
  invoiceStatuses: PropTypes.arrayOf(
    PropTypes.oneOf([
      invoiceStatusesMap.DRAFT,
      invoiceStatusesMap.FINAL,
      invoiceStatusesMap.PAID,
      invoiceStatusesMap.VOID,
      'OVERDUE', // Pseudo status based on "FINAL" invoices and their due dates
    ]),
  ).isRequired,
  latestCorrespondenceLoading: PropTypes.bool,
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object), // CorrespondenceHistory entity,
  selectedInvoices: PropTypes.object.isRequired,
  showDebtor: PropTypes.bool.isRequired,
  showMatter: PropTypes.bool.isRequired,
  showStatement: PropTypes.bool.isRequired,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onFetchLatestCorrespondenceDetailsForInvoice: PropTypes.func.isRequired,
  onOpenInvoiceCommunicateModal: PropTypes.func.isRequired,
  onToggleInvoices: PropTypes.func.isRequired,
  /** Pagination */
  invoicesCurrentPage: PropTypes.number.isRequired,
  invoicesOnPageChange: PropTypes.func.isRequired,
  invoicesTotalNumberOfPages: PropTypes.number.isRequired,
  isPaginationHidden: PropTypes.bool.isRequired,
  /** Invoice list action bar & Invoice table */
  onOpenInvoiceEmailModal: PropTypes.func.isRequired,
  onOpenMarkInvoiceAsSentModal: PropTypes.func.isRequired,
};

BillingContactInvoicesRoute.defaultProps = {
  latestCorrespondenceLoading: undefined,
  latestCorrespondencePerContact: undefined,
};
