import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';

import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { getInvoiceLatestVersion as getInvoiceLatestVersionByInvoiceId } from '@sb-billing/redux/invoices';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';

import {
  split as splitDescription,
  interpolateValues as interpolateDescription,
} from '@sb-billing/interpolate-description';

import InterpolatedDescription from './InterpolatedDescription';

const getterMap = {
  account: getBankAccountById,
  contact: getContactById,
  invoice: getInvoiceLatestVersionByInvoiceId,
  matter: getMatterById,
};

const mapStateToProps = (state, { description, parts, deleted, onClickLink, isError, onClickDisplay }) => ({
  parts: parts || interpolateDescription(splitDescription(description), (type, id) => getterMap[type](id)),
  deleted,
  onClickLink,
  isError,
  onClickDisplay,
});

const InterpolatedDescriptionContainer = withReduxStore(connect(mapStateToProps)(InterpolatedDescription));

InterpolatedDescriptionContainer.displayName = 'InterpolatedDescriptionContainer';

InterpolatedDescriptionContainer.propTypes = {
  description: PropTypes.string,
  parts: PropTypes.array,
  deleted: PropTypes.any,
  onClickLink: PropTypes.func,
  isError: PropTypes.bool,
};

InterpolatedDescriptionContainer.defaultProps = {
  description: undefined,
  parts: undefined,
  deleted: undefined,
  onClickLink: undefined,
  isError: false,
};

export default InterpolatedDescriptionContainer;
