import { interpolateText } from '@sb-billing/business-logic/deposit-request/services';
import { getFirmName, getPhoneNumber, getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { getLineSummary as getMatterLineSummary } from '@sb-matter-management/redux/matters';
import { formatContactSalutation } from '@sb-customer-management/business-logic/contacts-summary/services';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { getMap as getBankAccountBalances } from '@sb-billing/redux/bank-account-balances';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';

export const getDepositRequestEmailInterpolated = async (depositRequest, t) => {
  const people = await fetchPeopleP(depositRequest.payorId).catch(() => []);

  const matterBankAccountBalance = getMatterBalance(getBankAccountBalances(), {
    matterId: depositRequest.matterId,
    bankAccountId: depositRequest.bankAccountId,
    balanceType: balanceTypes.BALANCE,
  });

  const matterTotals = getMatterTotalsById(depositRequest.matterId);

  const data = {
    firmName: getFirmName(),
    phoneNumber: getPhoneNumber(),
    matterLineSummary: getMatterLineSummary(depositRequest.matterId),
    staffName: getStaffEmailDetails().name,
    requestReason: depositRequest.reason,
    showPaymentButton: true,
    amountRequestedCents: depositRequest.requestedAmount,
    debtorName: formatContactSalutation(people),
    matterAmountDue: matterTotals && matterTotals.unpaid,
    // even though it is named trustBalance, this can be either TRUST or OPERATING account balance
    trustBalance: matterBankAccountBalance,
  };
  const subject = interpolateText(depositRequest.subject, data, t);
  const message = interpolateText(depositRequest.message, data, t);

  return { subject, message };
};
