import { fetchPostP } from '@sb-itops/redux/fetch';
import uuid from '@sb-itops/uuid';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { store } from '@sb-itops/redux';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountService',
  keyPath: 'id',
});

// Only to be used for Controlled Money Accounts
// Other accounts should be created using the existing methods
export const createBankAccount = (bankAccountDetails) => {
  if (!bankAccountDetails?.accountType) {
    throw new Error('Account needs to have accountType specified.');
  }

  const createBankAccountThunk = async () => {
    const newBankAccount = { ...bankAccountDetails, versionId: uuid() };

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [newBankAccount] });

    // Apply the save in the backend.
    try {
      const accountType = bankAccountDetails.accountType.toUpperCase();
      const path = `/billing/bank-account/create/:accountId/${accountType}`;
      const fetchOptions = { body: JSON.stringify(newBankAccount) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [newBankAccount] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(createBankAccountThunk);
};
