export const mapStaffMember = (s) => ({
  id: s.id,
  title: s.title,
  firstName: s.firstName,
  middleName: s.middleName,
  lastName: s.lastName,
  initials: s.initials,
  email: s.email,
  role: s.firmRole,
  former: s.isFormerStaff,
  enabled: !s.isFormerStaff,
  // versionId: string,
  phone: `${s.phoneAreaCode || ''}${s.phoneNumbe || ''}` || undefined,
  cell: `${s.cellAreaCode || ''}${s.cellNumber || ''}` || undefined,
  // avatar: string,
  userId: s.userId,
});
