import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { CreateEditContactForm } from '../contact-create-edit';
import Styles from './ContactCreateEditModal.module.scss';

const SaveType = Object.freeze({
  SAVE: 'save',
  SAVEANDNEW: 'saveAndNew',
});

export const ContactCreateEditModal = React.memo(
  ({
    onSave,
    onSaveAndNew,
    onClose,
    isSubmitDisabled,
    formInitialised,
    isVisible,
    scope,
    contactId,
    initialOrg,
    isUtbmsEnabled,
  }) => {
    if (!isVisible) {
      return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [saving, setSaving] = useState('');
    const onSaveClick = (event) => {
      setSaving(SaveType.SAVE);
      onSave(event);
    };
    const onSaveAndNewClick = (event) => {
      setSaving(SaveType.SAVEANDNEW);
      onSaveAndNew(event);
    };

    return (
      <Modal
        className={Styles.createEditModal}
        isVisible={isVisible}
        title={contactId ? 'Edit Contact' : 'Add a New Contact'}
        body={
          <CreateEditContactForm
            initialOrg={initialOrg}
            contactId={contactId}
            formInitialised={formInitialised}
            scope={scope}
            isUtbmsEnabled={isUtbmsEnabled}
          />
        }
        footer={
          <div className={Styles.buttonContainer}>
            <Button
              className={Styles.saveButton}
              onClick={onSaveClick}
              disabled={isSubmitDisabled}
              form={scope}
              locked={isSubmitDisabled && saving === SaveType.SAVE}
            >
              Save
            </Button>
            {!contactId && (
              <Button
                className={Styles.saveAndNewButton}
                onClick={onSaveAndNewClick}
                disabled={isSubmitDisabled}
                locked={isSubmitDisabled && saving === SaveType.SAVEANDNEW}
                type="secondary"
                form={scope}
              >
                Save & New
              </Button>
            )}
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

ContactCreateEditModal.displayName = 'ContactCreateEditModal';

ContactCreateEditModal.propTypes = {
  contactId: PropTypes.string,
  initialOrg: PropTypes.string,
  scope: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndNew: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  formInitialised: PropTypes.bool,
  isUtbmsEnabled: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
};

ContactCreateEditModal.defaultProps = {
  contactId: undefined,
  initialOrg: undefined,
  isUtbmsEnabled: undefined,
  isSubmitDisabled: false,
  formInitialised: false,
};
