'use strict';

const getFirmPhoneNumber = (firmDetails) => {
  const phoneDetails = firmDetails ? firmDetails.phone : undefined;

  if (!phoneDetails) {
    return '';
  }

  // For AU region, the phone value is a string
  if (typeof phoneDetails === 'string') {
    return phoneDetails;
  }

  const areaCode = phoneDetails.areaCode;
  const localNumber = phoneDetails.localNumber;

  return areaCode && localNumber ? `(${areaCode}) ${localNumber}` : '';
};

module.exports = {
  getFirmPhoneNumber,
};
