import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoiceTotalsService',
  keyPath: 'invoiceId',
});

const validateTotals = ({ total, paid, unpaid, paidByCredit, waived }) => {
  if (!Number.isFinite(total) || total < 0) {
    throw new Error(`Invalid total value: ${total}`);
  }

  if (!Number.isFinite(paid) || paid < 0) {
    throw new Error(`Invalid paid value: ${paid}`);
  }

  if (!Number.isFinite(paidByCredit) || paidByCredit < 0) {
    throw new Error(`Invalid paidByCredit value: ${paidByCredit}`);
  }

  if (!Number.isFinite(unpaid) || unpaid < 0) {
    throw new Error(`Invalid unpaid value: ${unpaid}`);
  }

  if (!Number.isFinite(waived) || waived < 0) {
    throw new Error(`Invalid waived value: ${waived}`);
  }

  if (waived && paid + unpaid + waived + paidByCredit !== total) {
    throw new Error(`Totals do not balance: ${paid} + ${unpaid} + ${waived} + ${paidByCredit}  !== ${total}`);
  }

  if (!waived && paid + unpaid + paidByCredit !== total) {
    throw new Error(`Totals do not balance: ${paid} + ${unpaid} + ${paidByCredit}  !== ${total}`);
  }
};

const adjustTotals = (
  invoiceTotals,
  { total = 0, paid = 0, unpaid = 0, waived = 0, tax = 0, paidByCredit = 0 },
  skipValidation = false,
) => {
  const adjusted = { ...invoiceTotals };
  adjusted.total = (adjusted.total || 0) + total;
  adjusted.tax = (adjusted.tax || 0) + tax;
  adjusted.paid += paid;
  adjusted.paidByCredit = (adjusted.paidByCredit || 0) + paidByCredit;
  adjusted.unpaid += unpaid;
  adjusted.unpaidExcInterest += unpaid;
  adjusted.waived += waived || 0;

  if (!skipValidation) {
    validateTotals(adjusted);
  }

  return adjusted;
};

const adjustTotalsForPayment = (invoiceTotals, amount, paidByCredit) => {
  // Amount paid is allowed to be greater than invoice total. Residual lands in operating account (handled elsewhere).
  // In terms of total adjustment, the amount paid becomes the invoice total unpaid amount.
  const paid = amount > invoiceTotals.unpaid ? invoiceTotals.unpaid : amount;

  return adjustTotals(invoiceTotals, {
    paid,
    paidByCredit,
    unpaid: -paid - paidByCredit,
  });
};

export const buildInvoiceTotalsOpdates = (invoiceTotals, payment) => {
  let toBePaid = payment.payors ? payment.payors.reduce((sum, { amount }) => sum + amount, 0) : payment.amount;
  let creditsToBePaid = 0;
  if (payment.sourceAccountType === 'Credit') {
    creditsToBePaid = toBePaid;
    toBePaid = 0;
  }

  const adjustedInvoiceTotals = adjustTotalsForPayment(invoiceTotals, toBePaid, creditsToBePaid);

  return [adjustedInvoiceTotals];
};
