import { gql } from '@apollo/client';

const query = gql`
  query InitStaffSettings {
    staffMembers {
      id
      initials
      isFormerStaff
      name
      rate
      colorFill
      colorStoke
    }
    loggedInStaff {
      id
      initials
      name
      rate
      feeConfiguration {
        id
        enterTimeAsUnits
      }
      colorFill
      colorStoke
      email
    }
  }
`;

const notificationIds = [
  'FirmManagementNotifications.PersonUpdated',
  'FeesNotifications.StaffMemberHourlyRateUpdated',
  'FeesNotifications.StaffFeeConfigurationUpdated',
];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitStaffSettings = {
  query,
  notificationIds,
};
