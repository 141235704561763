angular.module('sb.billing.webapp').component('sbDataDepositSlipById', {
  require: { composer: '^sbCompose' },
  bindings: { depositSlipId: '<?', entityDataKey: '@?' },
  controller: function ($scope, sbLoggerService, sbDepositSlipService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataDepositSlipById');
    const entityDataKey = ctrl.entityDataKey || 'depositSlip';

    // log.setLogLevel('info');

    ctrl.$onChanges = () => {
      updateDepositSlip();
    };

    $scope.$on('smokeball-data-update-sbDepositSlipService', () => {
      log.info('saw data update');
      updateDepositSlip();
    });

    function updateDepositSlip () {
      const depositSlip = sbDepositSlipService.get(ctrl.depositSlipId);
      log.info('depositSlip: ', depositSlip);

      if (depositSlip) {
        ctrl.composer.setComposeData({ data: depositSlip }, entityDataKey);
      }
    }
  }
});
