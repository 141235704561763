import React from 'react';
import * as Styles from './StaffColorPicker.module.scss';

export const StaffColorBlock = ({ colorFill, colorStroke, selected, onClick, style = {} }) => (
  <div
    className={Styles.color}
    onClick={onClick}
    style={{ backgroundColor: colorFill, borderColor: colorStroke, ...style }}
  >
    {selected && <i className={`${Styles.selected} icon icon-icon-fat-check-circle-filled`} />}
  </div>
);
