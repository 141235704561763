import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').controller('SbAdvancedSearchMattersCtrl', function ($state) {
  'use strict';
  const that = this;

  that.loadMatter = function (id) {
    if (id) {
      if (featureActive('NUCWEB-208')) {
        $state.go('home.billing.view-matter.dashboard', { matterId: id });
      } else {
        $state.go('home.billing.view-matter.fees', {matterId: id});
      }
    }
  };
});
