'use strict';

/**
 * calculateRetainerRequestAmount
 * This function will calculate the (trust) retainer amount due, which will be displayed and added to an invoice.
 *
 * If the "Activate Minimum Trust Retainer" is OFF (in the matter trust retainer settings), the retainer request amount will be zero
 *
 * @param {Object} params
 * @param {number} params.matterTrustBalance
 * @param {MatterBillingConfiguration} params.matterBillingConfiguration
 * @returns number
 */
function calculateRetainerRequestAmount({ matterTrustBalance, matterBillingConfiguration }) {
  const {
    minimumTrustRetainerActive,
    minimumTrustRetainerAmount,
    trustRetainerReplenishAmount: replenishUpToAmount,
  } = matterBillingConfiguration;

  if (minimumTrustRetainerActive === false) {
    return 0;
  }

  const replenishmentRequired = matterTrustBalance < minimumTrustRetainerAmount;
  const retainerRequestAmount = replenishmentRequired ? replenishUpToAmount - matterTrustBalance : 0;

  return retainerRequestAmount;
}

module.exports = {
  calculateRetainerRequestAmount,
};
