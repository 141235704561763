import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';

import { SplitPayorsList } from './SplitPayorsList';

const hooks = () => ({
  useSummary: ({ payorAmounts }) => {
    const { totalPayorAmounts } = Object.values(payorAmounts).reduce(
      (acc, amount) => {
        acc.totalPayorAmounts += amount;
        return acc;
      },
      { totalPayorAmounts: 0 },
    );

    return {
      summary: {
        amount: totalPayorAmounts,
      },
    };
  },
  useSplitPayors: (props) => {
    const { payors, payorAmounts, onUpdateFieldValues } = props;

    function onChangePayorAmount({ id, amount }) {
      const { newPayorAmounts, newTotalAmount } = payors.reduce(
        (acc, payor) => {
          // Not the relevant payor
          if (payor.id !== id) {
            acc.newTotalAmount += payorAmounts[payor.id] || 0;
            return acc;
          }

          // Relevant payor
          acc.newTotalAmount = acc.newTotalAmount + amount || 0;
          acc.newPayorAmounts[payor.id] = amount;
          return acc;
        },
        {
          newPayorAmounts: { ...payorAmounts },
          newTotalAmount: 0,
        },
      );

      onUpdateFieldValues({
        payorAmounts: newPayorAmounts,
        amount: newTotalAmount,
      });
    }

    function onSelectPayor({ id, checked }) {
      const selectedPayorsTotalAvailableAmount = payors.find((payor) => payor.id === id).available;

      const getUpdatedAmount = () => {
        if (checked) {
          // Need to add the difference, because the user may have already entered a value in
          const payorAmountUnassigned = selectedPayorsTotalAvailableAmount - (payorAmounts[id] || 0);
          const newTotalAmount = props.amount + payorAmountUnassigned;

          return newTotalAmount;
        }

        return props.amount - selectedPayorsTotalAvailableAmount;
      };

      const updatedPayorAmounts = {
        ...payorAmounts,
        [id]: checked ? selectedPayorsTotalAvailableAmount : 0,
      };

      onUpdateFieldValues({
        payorAmounts: updatedPayorAmounts,
        amount: getUpdatedAmount(),
      });
    }

    return {
      payors,
      // Callbacks
      onChangePayorAmount,
      onSelectPayor,
    };
  },
});

export const SplitPayorsListContainer = composeHooks(hooks)(SplitPayorsList);

SplitPayorsListContainer.propTypes = {
  amount: PropTypes.number.isRequired,
  payors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  payorAmounts: PropTypes.object, // Mapped object where: { <contactId>: <allocatedAmount> }
  // Callbacks
  onUpdateFieldValues: PropTypes.func.isRequired,
};

SplitPayorsListContainer.defaultProps = {
  payorAmounts: Object.freeze({}),
};

SplitPayorsListContainer.displayName = 'SplitPayorsListContainer';
