angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/settings/email-templates';

  $stateProvider
    .state('settings.email-templates', {
      templateUrl: `${BASE_DIRECTORY}/email-templates.html`,
      controller: 'sbEmailTemplatesController',
      controllerAs: '$ctrl',
      url: '/email-templates/:tab?',
      data: {
        navHighlights: ['email-templates'],
      },
    });
});
