import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query DashboardInvoices($filter: InvoiceForDashboardListFilter, $limit: Int, $sort: ListSort) {
    invoiceForDashboardList(filter: $filter, limit: $limit, sort: $sort) {
      sortOrder {
        fieldNames
        directions
      }
      results {
        id
        status
        issuedDate
        dueDate
        finalizedTimestamp
        invoiceNumber
        totals {
          id
          total
          paid
          unpaidExcInterest
        }
      }
    }
  }
`;

const notificationIds = ['WebQueryInvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DashboardInvoices = {
  query,
  notificationIds,
};
