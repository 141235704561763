'use strict';

const { frequencyTypes, installmentFrequencies } = require('../entities/constants');

/**
 * The server stores the installmentFrequency as type + interval when the UI only has an enum.
 * This will transform from type+interval to enum.
 * @param {object} arguments
 * @param {1|0} arguments.type - type of installment
 * @param {1|2} arguments.interval - number of intervals
 * @returns {'Weekly'|'BiWeekly'|'Monthly'|'BiMonthly'} the frequency.
 * @throws if params are not of any default value.
 */
const getInstallmentFrequency = ({ type, interval }) => {
  if (type === frequencyTypes.WEEKLY) {
    if (interval === 2) {
      return installmentFrequencies.BI_WEEKLY;
    }
    if (interval === 1) {
      return installmentFrequencies.WEEKLY;
    }
  }

  if (type === frequencyTypes.MONTHLY) {
    if (interval === 2) {
      return installmentFrequencies.BI_MONTHLY;
    }
    if (interval === 1) {
      return installmentFrequencies.MONTHLY;
    }
  }

  throw new Error(`Invalid type or interval type: ${type}, interval: ${interval}`);
};

module.exports = {
  getInstallmentFrequency,
};
