import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as yup from 'yup';

import { getLogger } from '@sb-itops/fe-logger';
import {
  error as displayErrorToUser,
  success as displaySuccessToUser,
  builder as messageDisplayBuilder,
} from '@sb-itops/message-display';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';

import { getIncludingTaxAmount } from '@sb-billing/business-logic/expense/services';
import { useSubscribedQuery } from 'web/hooks';
import { OperatingChequeDetailsModalData } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { OperatingChequeDetailsModal } from './OperatingChequeDetailsModal';

export const OPERATING_CHEQUE_DETAILS_MODAL_ID = 'OPERATING_CHEQUE_DETAILS_MODAL_ID';
const SCOPE = 'operating-cheque-details-modal';
const log = getLogger('OperatingChequeDetailsModal.container');

const OperatingChequeDetailsModalSchema = yup.object().shape({
  reason: yup.string().required(),
});

const hooks = (props) => ({
  useOperatingChequeDetailsModalDataQuery: () => {
    const { data, loading, error } = useSubscribedQuery(OperatingChequeDetailsModalData, {
      variables: {
        id: props.chequeId,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const operatingCheque = data?.operatingCheque || {};
    const { id, chequeDate, payTo, createdBy, lastUpdated, reversed, chequeMemo, expenses, reversalReason } =
      operatingCheque;

    let amount = 0;
    expenses?.forEach((expense) => {
      amount += getIncludingTaxAmount(expense);
    });

    return {
      chequeId: id,
      amount,
      effectiveDate: chequeDate,
      payTo,
      createdBy,
      systemDate: lastUpdated,
      memo: chequeMemo,
      expenses,
      isReversed: reversed,
      reversalReason,
      // other
      isLoading: loading,
    };
  },
  useFormFields: () => {
    const { t } = useTranslation();
    const [isReverseCollapsed, setReverseCollapsed] = useState(true);
    const reasonInputRef = useRef(null);

    useEffect(() => {
      if (!isReverseCollapsed && reasonInputRef.current) {
        reasonInputRef.current.focus();
      }
    }, [isReverseCollapsed]);

    const {
      formFields,
      submitFailed,
      formInitialised,
      onInitialiseForm,
      onUpdateFieldValues,
      onSubmitFormWithValidation,
      onValidateForm,
    } = useForm({ scope: SCOPE, schema: OperatingChequeDetailsModalSchema });

    const toggleReversalUI = ({ isDelete }) => {
      onUpdateFieldValues('hideCheque', isDelete);
      setReverseCollapsed(!isReverseCollapsed);
    };

    const onReasonChangedHandler = () => {
      onUpdateFieldValues('reason', reasonInputRef.current.value);
    };

    return {
      reasonField: formFields.reason,
      isReverseCollapsed,
      reasonInputRef,
      submitFailed,
      formInitialised,
      onValidateForm,
      onReasonChangedHandler,
      toggleReversalUI,
      onLoad: () => {
        onInitialiseForm({
          reason: '',
          hideCheque: false,
        });
      },
      onProcessReversal: async () => {
        onValidateForm();
        await onSubmitFormWithValidation({
          submitFnP: async (finalFormValues) => {
            const type = t('capitalizeAllWords', { val: 'operatingCheque' });
            try {
              await dispatchCommand({
                type: 'Billing.Accounts.Messages.Commands.ReverseOperatingCheque',
                message: {
                  chequeId: props.chequeId,
                  hideCheque: finalFormValues.hideCheque,
                  reason: finalFormValues.reason,
                },
              });
              props.onClose();
              displaySuccessToUser(`${type} ${finalFormValues.hideCheque ? 'deletion' : 'reversal'} processed`);
            } catch (err) {
              props.onClose();
              log.error('Problem processing reversal', err);
              displayErrorToUser(
                messageDisplayBuilder()
                  .title(`${finalFormValues.hideCheque ? 'deletion' : 'reversal'} not processed`)
                  .text(
                    `Failed to process ${type} ${
                      finalFormValues.hideCheque ? 'deletion' : 'reversal'
                    }. Please check your connection and try again`,
                  ),
              );
            }
          },
        });
      },
    };
  },
});

export const OperatingChequeDetailsModalContainer = withApolloClient(
  composeHooks(hooks)(withOnLoad(OperatingChequeDetailsModal)),
);

OperatingChequeDetailsModalContainer.displayName = 'OperatingChequeDetailsModal.container';

OperatingChequeDetailsModalContainer.propTypes = {
  chequeId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  // function/callbacks
  onClose: PropTypes.func.isRequired,
};

OperatingChequeDetailsModalContainer.defaultProps = {};
