import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { useTranslation } from '@sb-itops/react';
import Styles from './BulkTransactionsTable.module.scss';

export const BulkTransactionsTable = memo(({ transactions, totalAmount, onClickLink }) => {
  const { t } = useTranslation();
  if (!transactions || !transactions.length) {
    return null;
  }
  return (
    <div className={Styles.transactionTable}>
      <div className={classnames(Styles.transactionList, Styles.sbTable)}>
        <div className={classnames(Styles.sbTableHeader, Styles.column)}>
          <div className={classnames(Styles.sbTableCell, Styles.matter)}>Matter</div>
          <div className={classnames(Styles.sbTableCell, Styles.receivedFrom)}>Received From</div>
          <div className={classnames(Styles.sbTableCell, Styles.amount)}>Amount</div>
        </div>

        <div className={classnames(Styles.sbTableContent, Styles.list)} style={{ maxHeight: '200px' }}>
          {transactions.map(({ id, matterId, contactId: receivedFrom, amount }) => (
            <div className={classnames(Styles.item, Styles.column)} key={`${id}`}>
              <div className={classnames(Styles.sbTableCell, Styles.matter)}>
                <MatterDisplay onClickLink={onClickLink} matterId={matterId} asLink inline />
              </div>
              <div className={classnames(Styles.sbTableCell, Styles.receivedFrom)}>
                <ContactDisplay onClickLink={onClickLink} contactId={receivedFrom} showLastNameFirst asLink inline />
              </div>
              <div className={classnames(Styles.sbTableCell, Styles.amount)}>{t('cents', { val: amount || 0 })}</div>
            </div>
          ))}
        </div>

        <div className={classnames(Styles.sbTableFooter, Styles.item, Styles.column)}>
          <div className={classnames(Styles.sbTableCell, Styles.matter)} />
          <div className={classnames(Styles.sbTableCell, Styles.receivedFrom)} />
          <div className={classnames(Styles.sbTableCell, Styles.amount)}>{t('cents', { val: totalAmount || 0 })}</div>
        </div>
      </div>
    </div>
  );
});

BulkTransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      matterId: PropTypes.string,
      amount: PropTypes.number,
      receivedFrom: PropTypes.string,
    }),
  ).isRequired,
  totalAmount: PropTypes.number.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

BulkTransactionsTable.defaultProps = {};
