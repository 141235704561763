/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import {
  validateMultiple as isValidEmailList,
  validateSingle as isValidEmail,
  validateMaxEmailsNumber as isOverMaxEmailsNumber,
} from '@sb-itops/email';

export const invoiceReminderEmailFormSchema = Yup.object().shape({
  readOnlyToAddress: Yup.string(),
  toAddress: Yup.string().when('readOnlyToAddress', {
    is: undefined,
    then: Yup.string()
      .test('is-email-list', 'Email address is not a valid format', (value) => isValidEmailList(value))
      .test('is-over-max-emails-number', 'A maximum of five recipients are allowed', (value) =>
        isOverMaxEmailsNumber(value),
      )
      .required('Email address is required'), // Must stay at last to avoid error message overwritten by above tests
    otherwise: Yup.string(),
  }),
  fromAddress: Yup.string()
    .required()
    .test('is-email', '${path} is not a valid email address', (value) => isValidEmail(value)),
  ccAddress: Yup.string()
    .test('is-email-list', 'Email address is not a valid format', (value) => value === '' || isValidEmailList(value))
    .test(
      'is-over-max-emails-number',
      'A maximum of five recipients are allowed',
      (value) => value === '' || isOverMaxEmailsNumber(value),
    ),
  bccAddress: Yup.string()
    .test('is-email-list', 'Email address is not a valid format', (value) => value === '' || isValidEmailList(value))
    .test(
      'is-over-max-emails-number',
      'A maximum of five recipients are allowed',
      (value) => value === '' || isOverMaxEmailsNumber(value),
    ),
  sendCopyToMe: Yup.boolean().required(),
  subject: Yup.string().required(),
  message: Yup.string().required(),
});
