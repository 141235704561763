import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';

import { createSelector } from 'reselect';
import { getRegion, states } from '@sb-itops/region';
import { sort as sortItems } from '@sb-itops/sort';
import { featureActive, featureData } from '@sb-itops/feature';
import { getList as getMatterTypes } from '@sb-matter-types/redux/matter-types';
import {
  isValidMatterTypeForLocation,
  isValidLeadTypeForLocation,
  isMatterTypeGenericOrFed,
} from '@sb-matter-types/business-logic/matter-types/services';
import { getById as getMatterTypeConfigurationById } from '@sb-matter-listing/redux/matter-type-configurations';

import { MatterTypeSelect } from './MatterTypeSelect';

const REGION = getRegion();

const getLocationOptions = () => states[REGION];

const selectCategoryOptions = createSelector(
  () => getMatterTypes(),
  (locationId) => locationId,
  (locationId, showOnlyLeads) => showOnlyLeads,
  (locationId, showOnlyLeads, shouldIncludeTestCategories) => shouldIncludeTestCategories,
  (allMatterTypes, locationId, showOnlyLeads, shouldIncludeTestCategories) =>
    getCategoryOptions(allMatterTypes, locationId, showOnlyLeads, shouldIncludeTestCategories),
);

const getCategoryOptions = (allMatterTypes, locationId, showOnlyLeads, shouldIncludeTestCategories) => {
  if (!locationId) {
    return [];
  }

  const matterTypeRegex = (featureActive('NUCWEB-490') && featureData('NUCWEB-490')) || '.*';

  const testCategories = ['QA', 'Test'];
  const categories = allMatterTypes.reduce((acc, matterType) => {
    if (showOnlyLeads) {
      if (isValidLeadTypeForLocation(matterType, locationId)) {
        // accumulate categories for given locationId
        matterType.categories.forEach((category) => {
          const categoryName = category && category.trim();
          if (categoryName && categoryName !== '' && !acc[categoryName]) {
            if (categoryName.match(matterTypeRegex)) {
              // add to category list if shouldIncludeTestCategories is true or if category is not a test category
              if (shouldIncludeTestCategories || !testCategories.includes(categoryName)) {
                acc[category] = { label: category, value: category };
              }
            }
          }
        });
      }
      return acc;
    }
    if (isValidMatterTypeForLocation(matterType, locationId)) {
      // accumulate categories for given locationId
      matterType.categories.forEach((category) => {
        const categoryName = category && category.trim();
        if (categoryName && categoryName !== '' && !acc[categoryName]) {
          if (categoryName.match(matterTypeRegex)) {
            // add to category list if shouldIncludeTestCategories is true or if category is not a test category
            if (shouldIncludeTestCategories || !testCategories.includes(categoryName)) {
              acc[category] = { label: category, value: category };
            }
          }
        }
      });
    }
    return acc;
  }, {});

  const categoryOptions = Object.values(categories);
  const sortedOptions = sortItems(categoryOptions, ['label'], ['ASC']);
  return sortedOptions;
};

const selectMatterTypeOptions = createSelector(
  () => getMatterTypes(),
  (locationId) => locationId,
  (locationId, showOnlyLeads) => showOnlyLeads,
  (locationId, showOnlyLeads, matterTypeCategory) => matterTypeCategory,
  (allMatterTypes, locationId, showOnlyLeads, matterTypeCategory) =>
    getMatterTypeOptions(allMatterTypes, locationId, showOnlyLeads, matterTypeCategory),
);

// could have included this as part of getCategoryOptions to save a loop but it will
// make it a lot harder to read, would only consider this if we run into performance
// issues, I doubt it as we are memoizing with reselect to construct these options
const getMatterTypeOptions = (allMatterTypes, locationId, showOnlyLeads, matterTypeCategory) => {
  if (!locationId || !matterTypeCategory) {
    return [];
  }

  const matterTypesForCategory = allMatterTypes.reduce((acc, matterType) => {
    if (
      (showOnlyLeads
        ? isValidLeadTypeForLocation(matterType, locationId)
        : isValidMatterTypeForLocation(matterType, locationId)) &&
      matterType.categories.includes(matterTypeCategory) // matter type is for selected category
    ) {
      // don't return any matter types with no possible clients defined as client role is a required field
      const originalMatterTypeId = matterType.matterTypeId; // could look like [GUID] or [GUID]_[LocationId]
      const possibleClients = getPossibleClients(originalMatterTypeId);
      if (possibleClients && possibleClients.length > 0) {
        // GENERIC/FED are generic matter types defined all locations with some exclusions
        // defined in "excludedLocationIds" property, these needs to be differentiated with
        // a location suffix when stored as part of the Matter entity, this is because our
        // Content team may create content that is location specific. i.e. technically
        // matterTypeIdWithLocationSuffix is a fake id constructed using [originalMatterTypeId]_[locationId]
        // for GENERIC or FED matter types.
        const appendLocationIdSuffix = isMatterTypeGenericOrFed(matterType);
        // NB: some originalMatterTypeId already has locationId suffix
        const matterTypeIdWithLocationSuffix = `${originalMatterTypeId}${
          appendLocationIdSuffix ? `_${locationId}` : ''
        }`;
        acc[matterTypeIdWithLocationSuffix] = {
          label: matterType.name,
          value: matterTypeIdWithLocationSuffix,
          originalMatterTypeId, // this is required to help look up possible client roles from MatterTypeConfiguration
        };
      }
    }
    return acc;
  }, {});
  const matterTypeOptions = Object.values(matterTypesForCategory);
  const sortedOptions = sortItems(matterTypeOptions, ['label'], ['ASC']);
  return sortedOptions;
};

const getPossibleClients = (matterTypeId) => {
  if (!matterTypeId) {
    return undefined;
  }

  const matterTypeConfig = getMatterTypeConfigurationById(matterTypeId);
  if (!matterTypeConfig) {
    return undefined;
  }

  return matterTypeConfig.possibleClients;
};

const mapStateToProps = (
  state,
  {
    isRequired,
    showError,
    locationId,
    matterTypeCategory,
    matterTypeId,
    onLocationUpdated,
    onCategoryUpdated,
    onMatterTypeUpdated,
    showOnlyLeads,
  },
) => {
  const shouldIncludeTestCategories = featureActive('BB-7838');
  const locationOptions = getLocationOptions();
  const matterTypeCategoryOptions = locationId
    ? selectCategoryOptions(locationId, showOnlyLeads, shouldIncludeTestCategories)
    : [];
  const matterTypeOptions =
    locationId && matterTypeCategory ? selectMatterTypeOptions(locationId, showOnlyLeads, matterTypeCategory) : [];

  const categoryUpdatedHandler = (newCategory) => {
    onCategoryUpdated(newCategory);
    const matterTypeOptionsForNewCategory =
      locationId && newCategory ? selectMatterTypeOptions(locationId, showOnlyLeads, newCategory) : [];

    // preselect matter type if there's only one in the new category
    if (matterTypeOptionsForNewCategory.length === 1) {
      onMatterTypeUpdated(matterTypeOptionsForNewCategory[0]);
    }
  };

  return {
    isRequired,
    showError,
    locationId,
    matterTypeCategory,
    matterTypeId,
    // select options
    locationOptions,
    matterTypeCategoryOptions,
    matterTypeOptions,
    // callbacks
    onLocationUpdated,
    onCategoryUpdated: categoryUpdatedHandler,
    onMatterTypeUpdated,
  };
};

export const MatterTypeSelectContainer = withReduxStore(connect(mapStateToProps)(MatterTypeSelect));

MatterTypeSelectContainer.displayName = 'MatterTypeSelectContainer';

MatterTypeSelectContainer.propTypes = {
  isRequired: PropTypes.bool,
  showError: PropTypes.bool,
  locationId: PropTypes.string,
  matterTypeCategory: PropTypes.string,
  matterTypeId: PropTypes.string,
  clientContactId: PropTypes.string,
  disabled: PropTypes.bool,
  showOnlyLeads: PropTypes.bool,
  // callbacks
  onLocationUpdated: PropTypes.func.isRequired,
  onCategoryUpdated: PropTypes.func.isRequired,
  onMatterTypeUpdated: PropTypes.func.isRequired,
};

MatterTypeSelectContainer.defaultProps = {
  showOnlyLeads: false,
  isRequired: true,
  showError: false,
  locationId: undefined,
  matterTypeCategory: undefined,
  matterTypeId: undefined,
  disabled: false,
};
