import { SELECT_DATE, SELECT_STAFF_ID } from './types';

export const selectDate = ({ date }) => {
  if (!date) {
    throw new Error('date is required');
  }
  return {
    type: SELECT_DATE,
    payload: { date },
  };
};

export const selectStaffId = ({ staffId }) => ({
  type: SELECT_STAFF_ID,
  payload: { staffId },
});
