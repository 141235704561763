'use strict';

const { surchargeTypeValues } = require('./surcharge-types');

/**
 * Determine surcharge settings to use on draft invoice screen
 * @param {object} params
 * @param {boolean} params.surchargeEnabled indicates whether surcharge feature is enabled
 * @param {Object} params.invoiceVersion invoice version
 * @param {Object} params.matterInvoiceSettings matter invoice settings
 * @param {Object} params.invoiceSettingsTemplate invoice template settings, if no specific template selected
 * this should always default to firm invoice template settings. This is named invoiceSettingsTemplate to maintain
 * consistency with cache name, even though semantically invoice template settings make more sense.
 * @param {Object} params.defaultSurchargeSettings default surcharge settings when surcharge is enabled
 * @returns {Object} surchargeSettings to use
 */
function determineSurchargeSettings({
  surchargeEnabled,
  invoiceVersion,
  matterInvoiceSettings,
  invoiceSettingsTemplate,
  defaultSurchargeSettings,
}) {
  // surcharges settings should be fetch from
  // 1) invoice
  //    1.1 invoice applyTo and description will be overridden by matter, template or default
  //    code settings where applicable because there's no UI to allow user to modify these
  //    fields when the code is first written
  // 2) if not exist, fetch from matter settings
  //    2.1 where surcharge type is none, all stored values are ignored and set to system default
  //    this ensure system continue to function as per user's expectation even if there are unexpected
  //    stored value in surcharge settings. It is envisaged that this could actually be a useful
  //    feature where we remember user's previous settings when they switched from using surcharge to
  //    not using it, and the later decide to turn it on again.
  // 3) if not exist, fetch from template settings
  //    3.1 where surcharge type is none, all stored values are ignored and set to system default
  // 4) otherwise use defaults
  let surchargeSettings;
  if (surchargeEnabled) {
    if (!defaultSurchargeSettings) {
      throw new Error('defaultSurchargeSettings is required');
    }

    if (invoiceVersion?.surcharge) {
      surchargeSettings = {
        type: invoiceVersion.surcharge?.type,
        percentageBp: invoiceVersion.surcharge?.percentageBp,
        fixedSurcharge: invoiceVersion.surcharge?.fixedSurcharge,
        // default to matter or template surcharge applyTo as user cannot override this on the draft invoice UI
        applyTo:
          (matterInvoiceSettings?.surchargeOverridden && matterInvoiceSettings?.surcharge?.applyTo) || // matter invoice settings may not have surcharge settings
          invoiceSettingsTemplate?.surcharge?.applyTo || // invoice template settings may not have surcharge settings
          defaultSurchargeSettings.applyTo,
        // default to matter or template surcharge label as user cannot override this on the draft invoice UI
        description:
          (matterInvoiceSettings?.surchargeOverridden && matterInvoiceSettings?.surcharge?.description) ||
          invoiceSettingsTemplate?.surcharge?.description ||
          defaultSurchargeSettings.description,
      };
    } else if (matterInvoiceSettings?.surchargeOverridden && matterInvoiceSettings?.surcharge) {
      const surchargeType = matterInvoiceSettings.surcharge.type || surchargeTypeValues.NONE;
      surchargeSettings = {
        type: matterInvoiceSettings.surcharge.type,
        percentageBp:
          surchargeType === surchargeTypeValues.PERCENTAGE
            ? matterInvoiceSettings.surcharge.percentageBp
            : defaultSurchargeSettings.percentageBp,
        fixedSurcharge:
          surchargeType === surchargeTypeValues.FIXED
            ? matterInvoiceSettings.surcharge.fixedSurcharge
            : defaultSurchargeSettings.fixedSurcharge,
        applyTo:
          surchargeType !== surchargeTypeValues.NONE
            ? matterInvoiceSettings.surcharge.applyTo
            : defaultSurchargeSettings.applyTo,
        description:
          surchargeType !== surchargeTypeValues.NONE
            ? matterInvoiceSettings.surcharge.description
            : defaultSurchargeSettings.description,
      };
    } else if (invoiceSettingsTemplate?.surcharge) {
      const surchargeType = invoiceSettingsTemplate.surcharge?.type || surchargeTypeValues.NONE;
      surchargeSettings = {
        type: surchargeType,
        percentageBp:
          surchargeType === surchargeTypeValues.PERCENTAGE
            ? invoiceSettingsTemplate.surcharge?.percentageBp
            : defaultSurchargeSettings.percentageBp,
        fixedSurcharge:
          surchargeType === surchargeTypeValues.FIXED
            ? invoiceSettingsTemplate.surcharge?.fixedSurcharge
            : defaultSurchargeSettings.fixedSurcharge,
        applyTo:
          surchargeType !== surchargeTypeValues.NONE
            ? invoiceSettingsTemplate.surcharge?.applyTo
            : defaultSurchargeSettings.applyTo,
        description:
          surchargeType !== surchargeTypeValues.NONE
            ? invoiceSettingsTemplate.surcharge?.description
            : defaultSurchargeSettings.description,
      };
    } else {
      surchargeSettings = { ...defaultSurchargeSettings };
    }
  }
  return surchargeSettings;
}

module.exports = {
  determineSurchargeSettings,
};
