angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/create-bill';

  $stateProvider

  .state('home.billing.create-bill', {
    url: '/create-bill/:matterId/:invoiceId',
    templateUrl: BASE_DIRECTORY + '/create-bill.html',
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
    params: {
      preselectedFeeIds: null,
      preselectedExpenseIds: null,
    },
    data: {
      authorized: true,
      navHighlights: ['billing', 'bills'],
      tab: { type: 'invoice' },
      derivePersistanceKey: function (stateName, sbParams) {
        if (sbParams.invoiceId) {
          return stateName + '.invoice-id.' + sbParams.invoiceId;
        } else {
          return stateName + '.matter-id.' + sbParams.matterId;
        }
      },
      findUnsavedChanges: function (sbData) {
        var changes = {};

        _.each(sbData, function (data, key) {
          if (data.$unsavedChanges) {
            changes[key] = data.$unsavedChanges;
          }
        });

        return changes;
      }
    }
  });

});
