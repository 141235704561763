angular.module('sb.billing.webapp').directive('sbActionWaiveInvoice', function (sbWaiveInvoiceCommand, sbMessageDisplayService, sbLoggerService) {
  'use strict';

  return {
    'restrict': 'A',
    'require': {group: 'sbComposeGroup'},
    link: function (scope, element, attrs, ctrl) {
      var invoice;
      var log = sbLoggerService.getLogger('sbActionWaiveInvoice');

      ctrl.group.onInvoiceData(function (data) {
        invoice = data;
      });
      ctrl.group.setAction(waiveInvoice, isWaivable);

      function waiveInvoice() {
        if (invoice) {
          ctrl.group.setLock(true);
          return sbWaiveInvoiceCommand.executeP(invoice.invoiceId)
            .then(function () {
              ctrl.group.setLock();
            })
            .catch(function (err) {
              sbMessageDisplayService.error(
                sbMessageDisplayService
                  .builder()
                  .text('Failed to waive invoice')
                  .conditionalText(' #{0}', invoice.invoiceNumber)
                  .text('. Please check your connection and try again.')
              );

              log.warn('Failed to waive invoice ' + err.message, invoice);
              ctrl.group.setLock();
            });
        } else {
          throw new Error('no invoice found to waive');
        }
      }

      function isWaivable() {
        return invoice && sbWaiveInvoiceCommand.isWaivable(invoice.invoiceId);
      }
    }
  };
});
