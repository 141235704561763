const { dispatchCommand } = require('./src/dispatch-command');
const { subscribeToCommandDispatchEvents, clearAllCommandDispatchSubscriptions } = require('./src/subscriptions');
const { fetchAccessAuthorisationsFromWebP } = require('./src/fetch-access-authorisations-from-web');

module.exports = {
  dispatchCommand,
  subscribeToCommandDispatchEvents,
  clearAllCommandDispatchSubscriptions,
  fetchAccessAuthorisationsFromWebP,
};
