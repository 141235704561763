import PropTypes from 'prop-types';
import { useState } from 'react';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { findPaymentPlansByInvoiceIds } from '@sb-billing/redux/payment-plans/selectors';
import { getList as getPaymentPlansList } from '@sb-billing/redux/payment-plans';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useSelector } from 'react-redux';
import { getInvoiceLatestVersion } from '@sb-billing/redux/invoices';
import { featureActive } from '@sb-itops/feature';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { SplitPaymentsList } from './SplitPaymentsList';

/**
 * Get payment details object from passed invoices
 * @param {Array.<string>} invoiceIds - a list of the invoice Ids
 * @param {{ [string] : object }} payments - object of payment details
 * @param {{ [string] : number }} amounts - object of amounts entered for invoiceId
 * @returns {Object} an object with totals
 */
const getTotals = ({ invoiceIds, payments, amounts }) =>
  invoiceIds.reduce(
    (acc, invoiceId) => {
      acc.due += payments[invoiceId].due;
      acc.paid += amounts[invoiceId] || 0;
      acc.interest += payments[invoiceId].interest;
      acc.unpaidExcInterest += payments[invoiceId].unpaidExcInterest;
      if (!acc.anyPaymentWithPaymentPlan) {
        acc.anyPaymentWithPaymentPlan = !!payments[invoiceId].paymentPlanId;
      }
      return acc;
    },
    { due: 0, paid: 0, interest: 0, unpaidExcInterest: 0, anyPaymentWithPaymentPlan: false },
  );

/**
 * Get payment details object from passed invoices
 * @param {Array.<object>} invoices -  a list of the invoices.
 * @returns {Object} an object of {invoiceIds:[], payments:{}}
 */
const getPayments = ({ invoices }) => {
  const paymentPlanIdsMap = getInvoiceIdToPaymentPlanIdMap(invoices);
  const paymentsAll = invoices.reduce(
    (acc, inv) => {
      const unpaidInterest = inv.unpaidExcInterest > 0 ? inv.interest : inv.total - inv.paid + inv.interest;
      const invoice = {
        invoiceId: inv.currentVersion.invoiceId,
        invoiceNumber: inv.currentVersion.invoiceNumber,
        dueDate: inv.currentVersion.dueDate, // in form YYYYMMDD
        matterId: inv.matterId,
        due: inv.unpaid || 0, // cents
        unpaidExcInterest: inv.unpaidExcInterest,
        interest: unpaidInterest,
        paymentPlanId: paymentPlanIdsMap[inv.currentVersion.invoiceId],
      };
      acc.invoiceIds.push(invoice.invoiceId);
      acc.payments[invoice.invoiceId] = invoice;
      return acc;
    },
    {
      invoiceIds: [],
      payments: {},
    },
  );
  paymentsAll.invoiceIds = paymentsAll.invoiceIds.sort(
    (a, b) => paymentsAll.payments[a].dueDate - paymentsAll.payments[b].dueDate,
  );

  return paymentsAll;
};

/**
 * Get the active payment plan id for a particular invoice (if any).
 * @param {Array.<object>} invoices -  a list of the invoices.
 * @returns {{ [string] : string }} a map of { [invoiceId]: paymentPlanId }
 */
const getInvoiceIdToPaymentPlanIdMap = (invoices = []) => {
  const paymentPlansForInvoicesList = findPaymentPlansByInvoiceIds(getPaymentPlansList(), {
    invoiceIds: invoices.map((inv) => inv.currentVersion.invoiceId),
    status: paymentPlanStatuses.ACTIVE,
  });

  // build a map of { [invoiceId]: paymentPlanId }
  return paymentPlansForInvoicesList.reduce((acc, paymentPlan) => {
    paymentPlan.invoiceIds.forEach((invoiceId) => {
      acc[invoiceId] = paymentPlan.id;
    });
    return acc;
  }, {});
};

const invoiceHasUnpaidAnticipatedDisbursements = (invoiceId) => {
  if (!featureActive('BB-9573')) return false;

  return hasUnpaidAnticipatedDisbursements({
    invoice: getInvoiceLatestVersion(invoiceId),
    getExpenseById,
    getExpensePaymentDetailsById,
  });
};

const getSplitPayments = ({ invoiceIds, payments, amounts }) =>
  invoiceIds.reduce((acc, invId) => {
    if (amounts[invId] > 0) {
      acc.push({
        ...payments[invId],
        amount: amounts[invId],
      });
    }
    return acc;
  }, []);

const hooks = ({ invoices, onUpdate, paymentType }) => ({
  useSplitPayments: () => {
    const [amounts, setAmounts] = useState({});
    const [lastPaymentType, setLastPaymentType] = useState(paymentType);

    if (lastPaymentType !== paymentType) {
      // reset amounts entered when paymentType changes as invoices in list will be different
      setLastPaymentType(paymentType);
      setAmounts({});
    }

    const { invoiceIds = [], payments = {} } = useSelector(() => (invoices ? getPayments({ invoices }) : {}));
    const totals = getTotals({ invoiceIds, payments, amounts });

    return {
      // we don't want to show error message when invoices is undefined
      showErrorWhenNoInvoices: Array.isArray(invoices),
      anyPaymentWithPaymentPlan: totals.anyPaymentWithPaymentPlan,
      invoiceIds,
      payments,
      amounts,
      totals,
      allChecked: !!invoiceIds.length && totals.paid === totals.due,
      invoiceHasUnpaidAnticipatedDisbursements,
      onSelectAll: () => {
        let newAmounts = {};
        if (totals.paid !== totals.due) {
          newAmounts = invoiceIds.reduce((acc, invoiceId) => {
            acc[invoiceId] = payments[invoiceId].due;
            return acc;
          }, {});
        }
        setAmounts(newAmounts);
        onUpdate(getSplitPayments({ invoiceIds, payments, amounts: newAmounts }));
      },
      onChange: (invoiceId, amount) => {
        const newAmounts = { ...amounts, [invoiceId]: amount };
        setAmounts(newAmounts);
        onUpdate(getSplitPayments({ invoiceIds, payments, amounts: newAmounts }));
      },
    };
  },
});

const SplitPaymentsListContainer = withReduxProvider(composeHooks(hooks)(SplitPaymentsList));

SplitPaymentsListContainer.displayName = 'SplitPaymentsListContainer';

SplitPaymentsListContainer.propTypes = {
  invoices: PropTypes.array,
  paymentType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

SplitPaymentsListContainer.defaultProps = {
  invoices: undefined,
};

export default SplitPaymentsListContainer;
