import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onBulkApplyRateSet = ({ commandMessage }) => {
  const { matterIds, rateSetId, rateSetName } = commandMessage;
  const apolloClient = getApolloClient();

  const matterFragment = gql`
    fragment OpdateMatter on Matter {
      id
      matterHourlyRate {
        id
        rateSet {
          id
          name
        }
      }
    }
  `;

  return matterIds.map((matterId) => {
    const optimisticMatter = {
      __typename: 'Matter',
      id: matterId,
      matterHourlyRate: {
        id: matterId,
        rateSet: {
          id: rateSetId,
          name: rateSetName,
        },
      },
    };

    const matterIdentifier = apolloClient.cache.identify(optimisticMatter);

    apolloClient.writeFragment({
      id: matterIdentifier,
      fragment: matterFragment,
      data: optimisticMatter,
    });

    return {
      eventType: apolloEntityOpdateEventTypes.ENTITY_UPDATED,
      typeName: 'Matter',
      optimisticEntity: optimisticMatter,
    };
  });
};
