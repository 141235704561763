import {
  VendorPaymentEntrySplitPayorsFormContainer,
  validateForm,
  marshalData,
} from './VendorPaymentEntrySplitPayorsForm.container';
import { VendorPaymentEntrySplitPayorsSchema } from './VendorPaymentEntrySplitPayorsForm.yup';

export {
  VendorPaymentEntrySplitPayorsFormContainer as VendorPaymentEntrySplitPayorsForm,
  VendorPaymentEntrySplitPayorsSchema,
  validateForm,
  marshalData,
};
