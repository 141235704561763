import { fetchPostP } from '../fetch';
import { getCreatedPreSignedUrl } from './selectors';
import { SET_PRE_SIGNED_URL, SET_PRE_SIGNED_URL_FAILED } from './types';

// postEndpoint : this is the post operation that is going to get the s3 bucket and get a pre signed url
// responsePreSignedUrlGetter: This will parse the response from the endpoint

export const createPreSignedUrl =
  ({ s3Bucket, ...props }, postEndpoint, responsePreSignedUrlGetter) =>
  async (dispatch) => {
    try {
      // TODO maybe this do not need to be a json, review this.
      const fetchOptions = {
        body: JSON.stringify({ s3Bucket, ...props }),
      };
      const response = await fetchPostP({ path: postEndpoint, fetchOptions });

      dispatch({
        type: SET_PRE_SIGNED_URL,
        payload: {
          preSignedUrl: (responsePreSignedUrlGetter && responsePreSignedUrlGetter(response.body)) || response.body.url,
          s3Bucket,
        },
      });

      return getCreatedPreSignedUrl({ s3Bucket });
    } catch (ex) {
      dispatch({
        type: SET_PRE_SIGNED_URL_FAILED,
        payload: {
          s3Bucket,
        },
      });

      return 'Failed getting url';
    }
  };
