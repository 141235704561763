import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSort } from '@sb-itops/redux/sort/use-sort';

import { buildClientCommunicationsHistory } from '@sb-billing/business-logic/correspondence-history';
import { getList as getCorrespondenceHistoryList } from '@sb-billing/redux/correspondence-history';
import {
  getInvoiceNumberById as getInvoiceNumberByInvoiceId,
  getByMatterId as getInvoicesByMatterId,
} from '@sb-billing/redux/invoices';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';

import { usePrintCorrespondenceHistoryPdf } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import * as billingAccountsTransactions from 'web/redux/route/home-billing-accounts-transactions';
import * as billingMatterTransactions from 'web/redux/route/home-billing-matter-transactions';

import { ClientCommunications } from './ClientCommunications';

const FEATURE_SCOPE = 'client-communications';

const exportSortByMap = {
  debtor: 'to', // use "to" column to sort when exporting, as "debtor" is not exported
};

const getContactDisplayById = (contactId) => {
  const contactDisplay = getContactDisplay(contactId, { showLastNameFirst: true });
  return contactDisplay;
};

const hooks = (props) => ({
  useProps: () => {
    const { listType, matterId, onClickLink } = props;

    return {
      listType,
      matterId,
      onClickLink,
    };
  },
  useCommunicationsSort: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: FEATURE_SCOPE,
      initialSortBy: 'timestamp',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
});

const queryHooks = ({ matterId, listType, sortBy, sortDirection }) => ({
  useCorrespondenceHistoryListData: () => {
    const { t } = useTranslation();
    const { showMarkAsSentEntries } = useSelector((state) =>
      listType === 'account'
        ? billingAccountsTransactions.selectors.getFilters(state)
        : billingMatterTransactions.selectors.getFilters(state),
    );
    const correspondenceHistoryList = getCorrespondenceHistoryList();
    const matterInvoicesIds = (getInvoicesByMatterId(matterId) || []).map((invoice) => invoice.invoiceId);
    const numberOfDaysToShow = 90;
    const sortedCorrespondenceHistoryData = buildClientCommunicationsHistory({
      matterId,
      matterInvoicesIds,
      correspondenceHistoryList,
      showMarkAsSentEntries,
      listType,
      sortBy,
      sortDirection,
      numberOfDaysToShow,
      getContactDisplayById,
      getInvoiceNumberById: getInvoiceNumberByInvoiceId,
      getStaffByUserId: getPersonByUserId,
      t,
    });

    return {
      numberOfDaysToShow,
      showMarkAsSentEntries,
      correspondenceHistoryData: sortedCorrespondenceHistoryData,
    };
  },
});

const dependentHooks = ({
  matterId,
  numberOfDaysToShow,
  showMarkAsSentEntries,
  sortBy,
  sortDirection,
  sbAsyncOperationsService,
}) => ({
  useExportMenuOptions: () => {
    const { printCorrespondenceHistoryPdf, isLoadingCorrespondenceHistoryPdf } = usePrintCorrespondenceHistoryPdf();
    const sort = !sortBy
      ? undefined
      : { fieldNames: [exportSortByMap[sortBy] || sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] };

    const onPrintPdf = async () => {
      await printCorrespondenceHistoryPdf({ filters: { matterId, numberOfDaysToShow, sort, showMarkAsSentEntries } });
    };

    const onExportCsv = async () => {
      try {
        sbAsyncOperationsService.startExportCorrespondenceHistoryCsv({
          matterId,
          numberOfDaysToShow,
          sort,
          showMarkAsSentEntries,
        });
      } catch (error) {
        throw new Error('Failed to export client communications history. Please try again later.');
      }
    };

    const exportMenuOptions = [
      {
        id: 'print-pdf',
        label: 'Print',
        onClick: onPrintPdf,
      },
      {
        id: 'export-csv',
        label: 'CSV',
        onClick: onExportCsv,
      },
    ];
    return {
      isGeneratingPDFReport: isLoadingCorrespondenceHistoryPdf,
      exportMenuOptions,
    };
  },
});

export const ClientCommunicationsContainer = withReduxProvider(
  composeHooks(hooks)(composeHooks(queryHooks)(composeHooks(dependentHooks)(ClientCommunications))),
);

ClientCommunicationsContainer.displayName = 'ClientCommunicationsContainer';

ClientCommunicationsContainer.propTypes = {
  listType: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

ClientCommunicationsContainer.defaultProps = {
  matterId: '',
};
