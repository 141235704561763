import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import validator from 'validator';
import { getAccountId } from 'web/services/user-session-management';
import { getSignatureUrl, uploadSignatureFile, deleteSignatureFile } from '@sb-billing/redux/invoice-settings-template';
import { fileSize, uploadFileType } from '@sb-itops/business-logic/files/entities/constants';
import Styles from './SignatureSettings.module.scss';

export const SignatureSettings = memo(({ signatureName, templateId, onSignatureNameChange, updatePreviewData }) => {
  const accountId = getAccountId();
  const fileInputRef = useRef(null);
  // Use signatureNameValue instead of using signatureName directly to avoid cursor moves to the end of the text while editing
  // This only happens when this component is embedded in an angular component invoice-settings-invoices.html
  // We can remove the useState and useEffect below once we migrate the firm invoice template page to React
  const [signatureNameValue, setSignatureNameValue] = useState(signatureName);
  const [isUploadingOrFetchingSignatureImage, setIsUploadingOrFetchingSignatureImage] = useState(false);
  const [isDeletingSignatureImage, setIsDeletingSignatureImage] = useState(false);
  const [signatureImageURL, setSignatureImageURL] = useState('');
  const [signatureImageInput, setSignatureImageInput] = useState('');

  // Update signatureName value when switching between templates in Firm Settings
  useEffect(() => {
    setSignatureNameValue(signatureName);
  }, [signatureName]);

  useEffect(() => {
    setSignatureImageInput(''); // Clear the file input when switching between template
    const loadSignature = async () => {
      // when clicking Add New template button, the templateId is add-new2 before saving
      // don't want to try fetch with that as the uuid as express throws a 400 error
      if (!validator.isUUID(templateId)) return;
      try {
        setIsUploadingOrFetchingSignatureImage(true);
        const preSignedUrl = await getSignatureUrl({ accountId, templateId });
        setSignatureImageURL(preSignedUrl);
      } catch (err) {
        displayErrorToUser(`Failed to get signature Url for the template ${templateId}. Error: ${err.message || ''}`);
        setSignatureImageURL('');
      } finally {
        setIsUploadingOrFetchingSignatureImage(false);
      }
    };

    loadSignature();
  }, [templateId, accountId]);

  const onSignatureNameChangeHandler = useCallback(
    (e) => {
      const target = e.currentTarget;
      onSignatureNameChange(target.value);
      setSignatureNameValue(target.value);
    },
    [onSignatureNameChange],
  );

  const handleSignatureInput = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSignatureImageInput(file);
  };

  const deleteSignature = async () => {
    try {
      setIsDeletingSignatureImage(true);
      await deleteSignatureFile({ accountId, templateId });
      displaySuccessToUser('Invoice signature deleted');
      setSignatureImageURL('');
      updatePreviewData();
    } catch (err) {
      displayErrorToUser(`Delete signature file failed: ${err.message || ''}`);
    } finally {
      setIsDeletingSignatureImage(false);
    }
  };

  const uploadSignature = async () => {
    if (!templateId) return;

    if (signatureImageInput.size > fileSize.THREE_MB) {
      displayErrorToUser(`Upload signature file failed: Attachment size cannot exceed 3MB.`);
      return;
    }

    if (signatureImageInput.type !== uploadFileType.PNG && signatureImageInput.type !== uploadFileType.JPEG) {
      displayErrorToUser(`Upload signature file failed: Attachment type must be PNG or JPEG.`);
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(signatureImageInput);
    reader.onload = async () => {
      try {
        setIsUploadingOrFetchingSignatureImage(true);
        const fileName = signatureImageInput.name;
        const fileExtension = fileName.split('.').pop();
        const preSignedUrl = await uploadSignatureFile({
          accountId,
          templateId,
          signatureImage: reader.result,
          fileExtension,
        });
        displaySuccessToUser('Invoice signature uploaded');
        setSignatureImageURL(preSignedUrl);
        updatePreviewData();
      } catch (err) {
        displayErrorToUser(`Upload signature file failed: ${err.message || ''}`);
      } finally {
        // clear file name in the file input field
        fileInputRef.current.value = null;
        setSignatureImageInput('');
        setIsUploadingOrFetchingSignatureImage(false);
      }
    };
  };

  return (
    <div className={Styles.signatureSettings}>
      <div className={Styles.signatureImage}>
        <div className={Styles.signatureImageLabel}>
          <label>Signature Image</label>
          <i
            className="icon-information"
            color="lightgrey"
            title="This signature will be added to all PDF invoices using this template, under the notes field"
          />
        </div>
        <div className={Styles.signatureImageInput}>
          <div className={Styles.inputFields}>
            <input
              type="file"
              name="signature"
              accept=".jpeg,.jpg,.png"
              ref={fileInputRef}
              key={`${templateId}-file-input`}
              className={classnames(Styles.signatureUpload, 'form-control')}
              disabled={false}
              onChange={handleSignatureInput}
            />
            <div className={Styles.buttonsWrapper}>
              <button
                type="button"
                className="btn btn-default btn-xs"
                disabled={isUploadingOrFetchingSignatureImage || isDeletingSignatureImage || !signatureImageInput}
                onClick={uploadSignature}
              >
                {!isUploadingOrFetchingSignatureImage && <span> {signatureImageURL ? 'Replace' : 'Upload'} </span>}
                {isUploadingOrFetchingSignatureImage && (
                  <span className="fa fa-spinner fa-pulse fa-fw uploading-signature" />
                )}
              </button>
              <button
                type="button"
                className="btn btn-default btn-xs"
                disabled={!signatureImageURL}
                onClick={deleteSignature}
              >
                {!isDeletingSignatureImage && <span>Delete</span>}
                {isDeletingSignatureImage && <span className="fa fa-spinner fa-pulse fa-fw uploading-signature" />}
              </button>
            </div>
          </div>
          {signatureImageURL && (
            <div className={Styles.signaturePreview}>
              <img src={signatureImageURL} alt="signature" />
            </div>
          )}
        </div>
        <div className={Styles.signatureHelpText}>PNG and JPEG format(s) supported, up to 3MB.</div>
      </div>
      <div className={Styles.signatureName}>
        <div className={Styles.signatureNameLabel}>
          <label>Signature Name</label>
          <i
            className="icon-information"
            color="lightgrey"
            title="This name will be added to all PDF invoices using this template, under the signature image"
          />
        </div>
        <input
          className="form-control"
          type="text"
          value={signatureNameValue}
          onChange={onSignatureNameChangeHandler}
          maxLength={32}
        />
      </div>
    </div>
  );
});

SignatureSettings.displayName = 'SignatureSettings';

SignatureSettings.propTypes = {
  templateId: PropTypes.string.isRequired,
  signatureName: PropTypes.string,
  // callbacks
  onSignatureNameChange: PropTypes.func,
  updatePreviewData: PropTypes.func,
};

SignatureSettings.defaultProps = {
  signatureName: undefined,
  // callbacks
  onSignatureNameChange: () => {},
  updatePreviewData: () => {},
};
