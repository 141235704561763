import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaffSelectorList } from './StaffSelectorList';

/**
 * StaffSelectorListContainer
 *
 * Container which provides simplified handling for staff selections made within the StaffSelectorList. Specifically, the container
 * removes the need from the user to handle 3 different staff status events (toggle, select all, clear all) and presents a single
 * onStaffSelectionChange instead.
 */
export const StaffSelectorListContainer = ({
  initiallySelectedStaffIds,
  forceSelectedStaffIds,
  onStaffSelectionChange,
  ...props
}) => {
  const [selectedStaffIdLookup, setSelectedStaffIdLookup] = useState(() =>
    initiallySelectedStaffIds.reduce((acc, staffId) => {
      acc[staffId] = true;
      return acc;
    }, {}),
  );

  // At times we need to change the values via an external function, such as
  // resetting the form with a default values. In this case, we pass through
  // the forceSelectedStaffIds instead of initiallySelectedStaffIds.
  useEffect(() => {
    const shouldUpdate =
      forceSelectedStaffIds &&
      (forceSelectedStaffIds.length !== Object.keys(selectedStaffIdLookup).length ||
        forceSelectedStaffIds.some((staffId) => !selectedStaffIdLookup[staffId]));

    if (shouldUpdate) {
      setSelectedStaffIdLookup(
        forceSelectedStaffIds.reduce((acc, staffId) => {
          acc[staffId] = true;
          return acc;
        }, {}),
      );
    }
  }, [forceSelectedStaffIds, selectedStaffIdLookup]);

  const onToggleStaff = (staffIdToggled) => {
    selectedStaffIdLookup[staffIdToggled] = !selectedStaffIdLookup[staffIdToggled];
    onStaffSelectionChange(
      Object.entries(selectedStaffIdLookup)
        .filter(([, isSelected]) => isSelected)
        .map(([id]) => id),
    );
    setSelectedStaffIdLookup({ ...selectedStaffIdLookup });
  };

  const onSelectAllStaff = (allSelectableIds) => {
    const allStaffSelected = allSelectableIds.reduce((acc, staffId) => {
      acc[staffId] = true;
      return acc;
    }, {});

    onStaffSelectionChange(Object.keys(allStaffSelected));
    setSelectedStaffIdLookup(allStaffSelected);
  };

  const onDeselectAllStaff = () => {
    onStaffSelectionChange([]);
    setSelectedStaffIdLookup({});
  };

  return (
    <StaffSelectorList
      {...props}
      selectedStaffIds={selectedStaffIdLookup}
      onToggleStaff={onToggleStaff}
      onSelectStaff={onSelectAllStaff}
      onDeselectAllStaff={onDeselectAllStaff}
    />
  );
};

StaffSelectorListContainer.displayName = 'StaffSelectorListContainer';

StaffSelectorListContainer.propTypes = {
  staff: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  initiallySelectedStaffIds: PropTypes.arrayOf(PropTypes.string),
  forceSelectedStaffIds: PropTypes.arrayOf(PropTypes.string),
  showNoneOption: PropTypes.bool,
  disableDefaultPadding: PropTypes.bool,
  onStaffSelectionChange: PropTypes.func.isRequired,
};

StaffSelectorListContainer.defaultProps = {
  initiallySelectedStaffIds: [],
  forceSelectedStaffIds: undefined,
  disableDefaultPadding: false,
  showNoneOption: false,
};
