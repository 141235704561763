import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StripeAccountManagementPanel } from './StripeAccountManagementPanel';

export const StripeAccountManagementPanelContainer = (props) => {
  const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false);

  const disconnectManagementProps = {
    showConfirmDisconnect,
    onRequestDisconnectAccount: () => setShowConfirmDisconnect(true),
    onCancelDisconnectAccount: () => setShowConfirmDisconnect(false),
    onDisconnectAccount: (...args) => {
      setShowConfirmDisconnect(false);
      props.onDisconnectAccount(...args);
    },
  };

  return <StripeAccountManagementPanel {...props} {...disconnectManagementProps} />;
};

StripeAccountManagementPanelContainer.displayName = 'StripeAccountManagementPanelContainer';

StripeAccountManagementPanelContainer.propTypes = {
  linkedAccount: PropTypes.object,
  bankAccountType: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isDisconnecting: PropTypes.bool,
  onConnectAccount: PropTypes.func.isRequired,
  onContinueOnboarding: PropTypes.func.isRequired,
  onDisconnectAccount: PropTypes.func.isRequired,
};

StripeAccountManagementPanelContainer.defaultProps = {
  linkedAccount: undefined,
  disabled: false,
  isConnecting: false,
  isDisconnecting: false,
};
