'use strict';

const {
  convertSettingsForGQL: lawpayConvertSettingsForGQL,
  convertSettingsFromGQL: lawpayConvertSettingsFromGQL,
} = require('./lawpay');
const {
  convertSettingsForGQL: stripeConvertSettingsForGQL,
  convertSettingsFromGQL: stripeConvertSettingsFromGQL,
} = require('./stripe');
const {
  convertSettingsForGQL: ezyCollectConvertSettingsForGQL,
  convertSettingsFromGQL: ezyCollectConvertSettingsFromGQL,
} = require('./ezy-collect');
const {
  convertSettingsForGQL: feeWiseConvertSettingsForGQL,
  convertSettingsFromGQL: feeWiseConvertSettingsFromGQL,
} = require('./fee-wise');
const { providers } = require('../entities/constants');

const providerSpecificConvertForGQLFns = {
  [providers.LAWPAY]: lawpayConvertSettingsForGQL,
  [providers.STRIPE]: stripeConvertSettingsForGQL,
  [providers.EZY_COLLECT]: ezyCollectConvertSettingsForGQL,
  [providers.FEE_WISE]: feeWiseConvertSettingsForGQL,
};

const providerSpecificConvertFromGQLFns = {
  [providers.LAWPAY]: lawpayConvertSettingsFromGQL,
  [providers.STRIPE]: stripeConvertSettingsFromGQL,
  [providers.EZY_COLLECT]: ezyCollectConvertSettingsFromGQL,
  [providers.FEE_WISE]: feeWiseConvertSettingsFromGQL,
};

function convertSettingsForGQL({ providerType, formattedSettings }) {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  const convertForGQLFn = providerSpecificConvertForGQLFns[providerType];
  if (!convertForGQLFn) {
    throw new Error(`Cannot convert settings for provider type ${providerType}`);
  }

  return convertForGQLFn({ formattedSettings });
}

function convertSettingsFromGQL({ providerType, formattedSettingsFromGQL }) {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  const convertFromGQLFn = providerSpecificConvertFromGQLFns[providerType];
  if (!convertFromGQLFn) {
    throw new Error(`Cannot convert settings for provider type ${providerType}`);
  }

  return convertFromGQLFn({ formattedSettingsFromGQL });
}

module.exports = {
  convertSettingsForGQL,
  convertSettingsFromGQL,
};
