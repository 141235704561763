import React from 'react';
import { t } from '@sb-itops/localisation-web';
import { SlidingToggle } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
import Styles from '../ReportFilter.module.scss';

const AgingSummaryFilter = ({ onSelectOption, onToggleOption, staff, filters }) => {
  if (!filters) {
    return null;
  }
  const { attorneyResponsible, includeFirmHeader, includeFirmLogo, includeFirmName } = filters;

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.attorney}>
        <label>{t('capitalizeAll', { val: 'personResponsible' })}</label>
        <StatelessSelect
          className=""
          selectedOption={attorneyResponsible}
          isClearable={false}
          options={staff}
          onValueChange={(option) => onSelectOption({ key: 'attorneyResponsible', option })}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

AgingSummaryFilter.displayName = 'AgingSummaryFilter';

export default AgingSummaryFilter;
