import { updateCache as updateRedux, clearCache as clearRedux } from '@sb-billing/redux/expense-payment-details';

angular
  .module('@sb-billing/services')
  .service('sbExpensePaymentDetailsService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    sbGenericCacheService.setupCache({
      name: 'sbExpensePaymentDetailsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/expense-payment-details' },
        poll: 60,
        subscriptions: 'notifier.ExpensesNotifications.ExpensePaymentDetailsUpdated'
      },
      updateRedux,
      clearRedux,
    });
  });