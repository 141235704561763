import React from 'react';
import PropTypes from 'prop-types';
import { StatelessSelect } from '@sb-itops/react';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { IntegrationConnectionPanel } from '@sb-billing/react';

import lawpayLogo from 'web/assets/integration-lawpay.png';
import stripeLogo from 'web/assets/integration-stripe.png';
import smokeballPayLogo from 'web/assets/smokeball-logo.svg';

import Styles from './PaymentProviderActivationForm.module.scss';

const logoByProvider = {
  [providers.LAWPAY]: lawpayLogo,
  [providers.STRIPE]: stripeLogo,
  [providers.EZY_COLLECT]: smokeballPayLogo,
  [providers.FEE_WISE]: smokeballPayLogo,
};

export const PaymentProviderActivationForm = ({
  providerOptions,
  paymentProvider,
  isActivating,
  onPaymentProviderChanged,
  onActivate,
}) => {
  if (!paymentProvider) {
    return null;
  }

  const providerType = paymentProvider.value;

  return (
    <div className={Styles.paymentProviderActivationForm}>
      <form name="paymentProviderActivation">
        <div className="form-group">
          <StatelessSelect
            selectedOption={paymentProvider}
            options={providerOptions}
            onChange={(selectedOption) => onPaymentProviderChanged(selectedOption)}
            isClearable={false}
            className={Styles.providerSelect}
            disabled={isActivating}
          />
        </div>

        <div className="form-group">
          <IntegrationConnectionPanel
            integration={providerNames[providerType]}
            isConnected={false}
            isConnecting={isActivating}
            connectToIntegration={() => onActivate(paymentProvider.value)}
            url={undefined}
            logoSrc={logoByProvider[providerType]}
          />
        </div>
      </form>
    </div>
  );
};

PaymentProviderActivationForm.displayName = 'PaymentProviderActivationForm';

PaymentProviderActivationForm.propTypes = {
  providerOptions: PropTypes.array.isRequired,
  paymentProvider: PropTypes.object.isRequired,
  isActivating: PropTypes.bool.isRequired,
  onPaymentProviderChanged: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
};
