import { gql } from '@apollo/client';

import { matterSummaryFragment } from '../fragments/matterSummaryFragment.graphql';

const query = gql`
  query FeeFormData($id: ID!) {
    fee(id: $id) {
      id
      feeDate
      feeEarnerStaff {
        id
        initials
        isFormerStaff
        name
      }
      finalized
      matter {
        ...matterSummaryFragment
      }
      activityCode
      feeActivityId
      utbmsActivityCode
      utbmsTaskCode
      description
      duration
      durationWorked
      feeType
      rate
      isTaxExempt
      amountIncludesTax
      invoice {
        id
        invoiceNumber
      }
      isBillable
      waived
      notes
      sourceItems {
        activityCount
        activityRelatedId
        activityType
        billable
        description
        duration
        durationWorked
        originalBillable
        sourceActivityIds
      }
      isInvoicedExternally
    }
  }

  ${matterSummaryFragment}
`;

const notificationIds = ['FeesNotifications.AccountFeesUpdated', 'InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FeeFormData = {
  query,
  notificationIds,
};
