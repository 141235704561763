import { getById as getAccountById, getTrustAccounts } from '@sb-billing/redux/bank-account';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withOnLoad } from '@sb-itops/react/hoc';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { states, ALL_STATES, getRegion } from '@sb-itops/region';
import PropTypes from 'prop-types';

import { TrustAccountAddEditForm } from './TrustAccountAddEditForm';
import { createTrustAccountAddEditFormSchema } from './TrustAccountAddEditFormSchema';

const hooks = ({ scope, region, bankAccountId }) => ({
  useForms: () => {
    const numberOfTrustAccounts = getTrustAccounts().length;
    const bankBranchNumberRegex = getBankAccountStrategy(getRegion(), bankAccountStrategies.BRANCH_NUMBER_REGEX);

    const form = useForm({
      scope,
      schema: createTrustAccountAddEditFormSchema({ numberOfTrustAccounts, bankBranchNumberRegex }),
    });

    const {
      formFields,
      formSubmitting,
      formInitialised,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFields,
      onValidateForm,
    } = form;

    const {
      accountName,
      accountNumber,
      bankName,
      branchName,
      branchNumber,
      displayName,
      location,
      statutoryDepositMatterId,
    } = formFields;

    const supportsTrustAccountPerState = hasFacet(facets.trustAccountPerState);

    const stateOptions = [ALL_STATES, ...states[region]];
    // we need to know all statutory deposit matter IDs (excluding the one for current account)
    // so we can hide these IDs in MatterTypeahead as a matter
    // can be a statutory deposit matter for 1 trust account only
    const existingStatutoryDepositMatterIds = getTrustAccounts().reduce((acc, trustAccount) => {
      if (trustAccount.statutoryDepositMatterId && (!bankAccountId || bankAccountId !== trustAccount.id)) {
        acc.push(trustAccount.statutoryDepositMatterId);
      }
      return acc;
    }, []);

    const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);
    const supportsElectronicPayment = hasFacet(facets.electronicPayment);
    const bankNameAndBranchNameRequiredForTrustAccount = hasFacet(facets.bankNameAndBranchNameRequiredForTrustAccount);

    const onLoad = () => {
      const bankAccount = bankAccountId ? getAccountById(bankAccountId) : undefined;
      const defaultLocation = !hasFacet(facets.trustAccountPerState) ? ALL_STATES.value : undefined;
      const fieldValues = {
        accountName: bankAccount ? bankAccount.accountName : undefined,
        accountNumber: bankAccount ? bankAccount.accountNumber : undefined,
        bankName: bankAccount ? bankAccount.bankName : undefined,
        branchName: bankAccount ? bankAccount.branchName : undefined,
        branchNumber: bankAccount ? bankAccount.branchNumber : undefined,
        displayName: bankAccount && bankAccount.displayName ? bankAccount.displayName : undefined,
        // Trust accounts for new firms have the location property set to null
        // As this field is hidden unless the trustAccountPerState is on, we have
        // to set the default location to ALL_STATES otherwise validation will fail
        location: bankAccount?.location || defaultLocation,
        statutoryDepositMatterId:
          bankAccount && bankAccount.statutoryDepositMatterId ? bankAccount.statutoryDepositMatterId : undefined,
      };
      onInitialiseForm(fieldValues);
      const onUnload = () => onClearForm();
      return onUnload;
    };

    return {
      // values
      scope,
      region,
      stateOptions,
      existingStatutoryDepositMatterIds,
      accountName,
      accountNumber,
      bankName,
      branchName,
      branchNumber,
      displayName,
      location,
      statutoryDepositMatterId,
      supportsStatutoryDepositMatter,
      supportsElectronicPayment,
      supportsTrustAccountPerState,
      bankNameAndBranchNameRequiredForTrustAccount,
      formDisabled: formSubmitting,
      formInitialised,
      submitFailed,
      // callbacks
      onLoad,
      onFieldValueUpdated: (fieldValues) => {
        onUpdateFields(fieldValues);
      },
      validateForm: () => {
        onValidateForm();
      },
    };
  },
});

const TrustAccountAddEditFormContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(TrustAccountAddEditForm)));
TrustAccountAddEditFormContainer.displayName = 'TrustAccountAddEditFormContainer';

TrustAccountAddEditFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string,
  isBankAccountClosed: PropTypes.bool.isRequired,
  isValidStatutoryDepositMatterId: PropTypes.bool.isRequired,
  region: PropTypes.string.isRequired,
};
TrustAccountAddEditFormContainer.defaultProps = {
  bankAccountId: undefined,
};

export default TrustAccountAddEditFormContainer;
