angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/entries/rate-sets';

  $stateProvider
    .state('home.billing.entries.rate-sets', {
      url: '/rate-sets',
      templateUrl: BASE_DIRECTORY + '/rate-sets.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'entries']
      }
    });
});
