angular.module('sb.billing.webapp').component('sbDepositSlipView', {
  templateUrl: 'ng-components/deposit-slip-view/deposit-slip-view.html',
  bindings: { depositSlipId: '<' },
  controller: function (sbLoggerService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDepositSlipView');

    // log.setLogLevel('info');

    ctrl.$onInit = () => {
      ctrl.sbData = {};
      ctrl.view = {
        slipLoaded: false,
      };
    };

    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.onSlipLoaded = onSlipLoaded;

    function onSlipLoaded () {
      ctrl.view.slipLoaded = true;
    }

    function dataChangeFunction (key, doc) {
      log.info('data change', key, doc);

      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }
    }
  }
});
