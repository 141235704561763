import { TOGGLE_SHOW_FILTERS, SET_FILTER, RESET_FILTER, TOGGLE_HIDE_FILTER } from './types';

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});
export const toggleHideFilters = (event) => ({
  type: TOGGLE_HIDE_FILTER,
  payload: { filterName: event.currentTarget.id },
});

export const setFilter = (filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});
export const onFilterByRecent = (filterByRecent) => ({
  type: SET_FILTER,
  payload: { filterName: 'recent', filterValue: filterByRecent },
});
export const onResetFilters = () => ({
  type: RESET_FILTER,
  payload: {},
});
export const setMatterId = (matterId) => ({
  type: SET_FILTER,
  payload: { filterName: 'matterId', filterValue: matterId },
});
export const onFilterInvoiceStatus = (selectedInvoiceStatuses) => ({
  type: SET_FILTER,
  payload: { filterName: 'invoiceStatuses', filterValue: selectedInvoiceStatuses },
});
export const onFilterSentStatus = (selectedSentStatuses) => ({
  type: SET_FILTER,
  payload: { filterName: 'sentStatuses', filterValue: selectedSentStatuses },
});
export const onFilterBillingEvent = (selectedBillingEvent) => ({
  type: SET_FILTER,
  payload: { filterName: 'billingEvents', filterValue: selectedBillingEvent },
});
export const onChangeIssueDateFilter = (issueDate) => ({
  type: SET_FILTER,
  payload: { filterName: 'issueDate', filterValue: issueDate },
});
export const onChangeFinalizedOnFilter = (finalizedOn) => ({
  type: SET_FILTER,
  payload: { filterName: 'finalizedOn', filterValue: finalizedOn },
});
