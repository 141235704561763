import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import { getUniqueDebtorIdsForInvoices } from '@sb-billing/business-logic/invoice/services';
import { selectors } from '@sb-billing/redux/invoice-pre-draft';
import { getInvoiceLatestVersion as getInvoiceById } from '@sb-billing/redux/invoices';
import { InvoiceCommunicateModal } from './InvoiceCommunicateModal';
import { convertToInvoiceCommunicateRequests as marshallNoTabForm } from '../invoice-communicate-form';
import { convertToInvoiceCommunicateRequests as marshallMultiTabForm } from '../invoice-communicate-tab-per-debtor-form';

const { getVersionById: getPreDraftInvoiceById } = selectors;
const getInvoiceOrPreDraftInvoiceById = (state, preDraftMode) => (invoiceId) =>
  (preDraftMode && getPreDraftInvoiceById(state, { invoiceId })) || getInvoiceById(invoiceId);

const mapStateToProps = (
  state,
  {
    scope,
    isVisible,
    invoiceIds,
    debtorId,
    preDraftMode,
    quickPaymentsTotalAmount,
    onSend,
    onClose,
    sbInvoiceSendService,
  },
) => {
  const uniqueDebtorIdsForInvoices = getUniqueDebtorIdsForInvoices({
    invoiceIds,
    getInvoiceById: getInvoiceOrPreDraftInvoiceById(state, preDraftMode),
  });
  const debtorIds = debtorId ? [debtorId] : uniqueDebtorIdsForInvoices;

  // get matterIds now and pass down as the invoiceIds we pass may not exist in cache for preDraft
  const matterIds = getUniqueMatterIdsFromInvoiceIds({
    invoiceIds,
    getInvoiceById: getInvoiceOrPreDraftInvoiceById(state, preDraftMode),
  });

  const useMultiTabForm = invoiceIds.length === 1 && debtorIds.length > 1;
  const isMultiDebtorInvoice = invoiceIds.length === 1 && uniqueDebtorIdsForInvoices.length > 1;

  const { selectors: formSelectors } = withScopedFeature({ scope, state })(forms);

  const atLeastOneTabInUse =
    !formSelectors.getIsInitialised() ||
    !useMultiTabForm ||
    Object.values(formSelectors.getFormFields().communicateData).some(
      (debtorCommunicateData) => debtorCommunicateData.includeDebtor.value,
    );

  const isSubmitDisabled = !formSelectors.getIsInitialised() || !formSelectors.getIsValid() || !atLeastOneTabInUse;

  return {
    scope,
    isVisible,
    isSubmitDisabled,
    invoiceIds,
    debtorIds,
    matterIds,
    useMultiTabForm,
    isMultiDebtorInvoice,
    paymentProviderEnabledForInvoicePayment: sbInvoiceSendService.isPaymentProviderEnabledForInvoicePayment(),
    onSend: () => {
      const marshallFn = useMultiTabForm ? marshallMultiTabForm : marshallNoTabForm;
      const invoiceCommunicateRequests = marshallFn({
        state,
        scope,
        invoiceIds,
        debtorIds,
      });
      onSend(invoiceCommunicateRequests);
    },
    onPreview: (invoiceCommunicateRequest) =>
      sbInvoiceSendService.createInvoiceCommunicatePreviewP({
        invoiceCommunicateRequest,
        preDraftMode,
        quickPaymentsTotalAmount,
      }),
    onClose,
  };
};

const getUniqueMatterIdsFromInvoiceIds = ({ invoiceIds, getInvoiceById: getInvoiceByIdFn }) => {
  if (!Array.isArray(invoiceIds)) return [];

  const matterIds = new Set();
  invoiceIds.forEach((invoiceId) => {
    const { matterId } = getInvoiceByIdFn(invoiceId) || {};
    if (matterId) {
      matterIds.add(matterId);
    }
  });

  return Array.from(matterIds);
};

export const InvoiceCommunicateModalContainer = withReduxStore(
  connect(mapStateToProps, undefined)(InvoiceCommunicateModal),
);

InvoiceCommunicateModalContainer.displayName = 'InvoiceCommunicateModalContainer';

InvoiceCommunicateModalContainer.propTypes = {
  scope: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  debtorId: PropTypes.string,
  preDraftMode: PropTypes.bool, // Includes an invoice that has unsaved changes (create/edit invoice)
  quickPaymentsTotalAmount: PropTypes.number, // Total amount in quickPayments, to be subtracted from total owing for the invoice/debtor in preview
  onSend: PropTypes.func.isRequired,
  sbInvoiceSendService: PropTypes.object.isRequired, // Need this until functionality moved to business logic.
  onClose: PropTypes.func.isRequired,
};

InvoiceCommunicateModalContainer.defaultProps = {
  scope: 'invoice-communicate-modal',
  debtorId: undefined,
  preDraftMode: false,
  quickPaymentsTotalAmount: undefined,
};

export default InvoiceCommunicateModalContainer;
