import { gql } from '@apollo/client';

const query = gql`
  query ContactListMeta(
    $contactFilter: ContactFilter
    $hasPaymentPlan: Boolean
    $paymentPlanFilter: PaymentPlanFilter
  ) {
    contactListMeta(
      # These properties don't need to be here except that Apollo cache
      # ignores the differences in the field arguments and overwrites the
      # existing root query cache unless we specify them here as well
      contactFilter: $contactFilter
      hasPaymentPlan: $hasPaymentPlan
      paymentPlanFilter: $paymentPlanFilter
    ) {
      statusCounts(
        contactFilter: $contactFilter
        hasPaymentPlan: $hasPaymentPlan
        paymentPlanFilter: $paymentPlanFilter
      ) {
        current
        deleted
      }
    }
  }
`;

const notificationIds = ['CustomerManagementWebQuery.EntityUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ContactListMeta = {
  query,
  notificationIds,
};
