import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ModalDialog, modalDialogButtonSizes } from '@sb-itops/react/modal-dialog';
import { PaymentPlanForm } from './payment-plan-form';
import Styles from './PaymentPlanModalDialog.module.scss';

const PaymentPlanModalDialog = memo(
  ({
    modalDialogTitle,
    paymentPlanModalId,
    backButtonVisible,
    nextButtonVisible,
    nextButtonEnabled,
    saveButtonEnabled,
    selectedPaymentPlanId,
    relatedContacts,
    includedMatterIds,
    selectedTab,
    formFields,
    fieldValues,
    startingDate,
    candidateMatters,
    paymentMethodOptions,
    creditCardFormProps,
    // callbacks & functions
    onFieldValueChange,
    onSaveClick,
    onPlanDetailsTabClick,
    onInclusionsTabClick,
    onAddCreditCard,
  }) => (
    <ModalDialog
      modalId={paymentPlanModalId}
      className={Styles.paymentPlanModalContainer}
      body={
        <PaymentPlanForm
          selectedPaymentPlanId={selectedPaymentPlanId}
          relatedContacts={relatedContacts}
          includedMatterIds={includedMatterIds}
          selectedTab={selectedTab}
          formFields={formFields}
          fieldValues={fieldValues}
          startingDate={startingDate}
          paymentMethodOptions={paymentMethodOptions}
          candidateMatters={candidateMatters}
          nextButtonEnabled={nextButtonEnabled}
          onFieldValueChange={onFieldValueChange}
          onPlanDetailsTabClick={onPlanDetailsTabClick}
          onInclusionsTabClick={onInclusionsTabClick}
          onAddCreditCard={onAddCreditCard}
          creditCardFormProps={creditCardFormProps}
        />
      }
      title={modalDialogTitle}
      buttons={{
        primary: {
          text: 'Save',
          size: modalDialogButtonSizes.medium,
          props: {
            disabled: !saveButtonEnabled,
            size: 'full-width',
          },
        },
        secondary: {
          show: false,
        },
        customList: [
          backButtonVisible && {
            text: 'Back',
            size: modalDialogButtonSizes.medium,
            props: {
              onClick: onPlanDetailsTabClick,
              size: 'full-width',
            },
          },
          nextButtonVisible && {
            text: 'Next',
            size: modalDialogButtonSizes.medium,
            props: {
              onClick: onInclusionsTabClick,
              disabled: !nextButtonEnabled,
              size: 'full-width',
            },
          },
        ].filter(Boolean),
      }}
      onPrimaryClick={onSaveClick}
    />
  ),
);

PaymentPlanModalDialog.displayName = 'PaymentPlanModalDialog';

PaymentPlanModalDialog.propTypes = {
  selectedPaymentPlanId: PropTypes.string,
  includedMatterIds: PropTypes.arrayOf(PropTypes.string),
  paymentPlanModalId: PropTypes.string.isRequired,
  backButtonVisible: PropTypes.bool,
  nextButtonVisible: PropTypes.bool,
  nextButtonEnabled: PropTypes.bool,
  saveButtonEnabled: PropTypes.bool,
  modalDialogTitle: PropTypes.string.isRequired,
  selectedTab: PropTypes.string,
  formFields: PropTypes.object,
  fieldValues: PropTypes.object,
  startingDate: PropTypes.instanceOf(Date),
  candidateMatters: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string.isRequired,
      due: PropTypes.number.isRequired,
    }),
  ),
  relatedContacts: PropTypes.array,
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  creditCardFormProps: PropTypes.shape({
    allowAutoChargeCreditCard: PropTypes.bool.isRequired,
    isNewCreditCardEnabled: PropTypes.bool.isRequired,
    showCreditCardForm: PropTypes.bool,
    setShowCreditCardForm: PropTypes.func.isRequired,
    isSubmittingCreditCard: PropTypes.bool.isRequired,
    setIsSubmittingCreditCard: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.bool.isRequired,
    setTriggerSubmit: PropTypes.func.isRequired,
    isReadyForSubmit: PropTypes.bool.isRequired,
    setIsReadyForSubmit: PropTypes.func.isRequired,
    bankAccountId: PropTypes.string.isRequired,
  }),
  // callbacks & functions
  onFieldValueChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onPlanDetailsTabClick: PropTypes.func.isRequired,
  onInclusionsTabClick: PropTypes.func.isRequired,
  onAddCreditCard: PropTypes.func.isRequired,
};

PaymentPlanModalDialog.defaultProps = {
  selectedPaymentPlanId: undefined,
  backButtonVisible: false,
  nextButtonVisible: false,
  saveButtonEnabled: false,
  nextButtonEnabled: false,
  includedMatterIds: [],
  candidateMatters: [],
  paymentMethodOptions: [],
  relatedContacts: [],
  selectedTab: undefined,
  startingDate: undefined,
  formFields: {},
  fieldValues: {},
  creditCardFormProps: {
    isNewCreditCardEnabled: false,
    showCreditCardForm: false,
  },
};

export default PaymentPlanModalDialog;
