import PropTypes from 'prop-types';
import { withLinkHandler } from '@sb-itops/react/hoc';
import BankReconciliationTable from './BankReconciliationTable';

const Container = withLinkHandler('bankReconciliation')(BankReconciliationTable);

Container.propTypes = {
  /**
   * Data to be rendered in the table
   */
  bankReconciliations: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.number.isRequired,
      description: PropTypes.string,
      closingCashBookBalance: PropTypes.number.isRequired,
      userName: PropTypes.string,
    }),
  ).isRequired,

  /**
   * The callback to be invoked when user clicks on sort
   */
  sort: PropTypes.func,

  /**
   * The sort direction to be considered when sorting the column
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc']),

  onClickLink: PropTypes.func.isRequired,
};

Container.displayName = `Container(${BankReconciliationTable.displayName || BankReconciliationTable.name})`;

export default Container;
