/**
 * @typedef { import('../types').FetchResponse } FetchResponse
 */

const { responseTypes, bodyDownloaders } = require('./constants');
const { FetchError } = require('./fetch-error');

/**
 * simpleFetch
 * 
 * Thin ease of use wrapper around a native fetch function.
 * 
 * @param {Object} params
 * @param {function} params.fetch Environment fetch function implementation, e.g. node-fetch or window.fetch
 * @param {string} params.url The URL to direct the HTTP request toward.
 * @param {string} params.method The HTTP method, e.g. GET, POST etc.
 * @param {Object|undefined} params.body The body data to send in the HTTP request.
 * @param {Object} [params.headers] The headers to send in the HTTP request.
 * @param {string} [params.responseType] The type of response expected. See responseTypes in constants.js
 * @param {boolean} [params.throwOnFailure] Throw an error instead of returning FetchResponse if request fails.
 * @param {boolean} [params.skipStringify] Allows overiding auto body stringifying for json content types. Defaults to false.
 * @returns {Promise<FetchResponse>}
 */
const simpleFetch = async ({ fetch, url, method, headers = {}, body, responseType = responseTypes.json, throwOnFailure = false, skipStringify = false }) => {
  const bodyDownloader = bodyDownloaders[responseType];
  if (!bodyDownloader) {
    throw new Error(`Unsupported response type '${responseType}'`);
  }

  const stringifyBody = !skipStringify && (!headers['Content-Type'] || headers['Content-Type'].includes('application/json'));
  const encodedBody = body && stringifyBody ? JSON.stringify(body) : body;

  const rawFetchResponse = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: encodedBody ? encodedBody : undefined,
  });

  /** @type {FetchResponse} */
  const processedResponse = {
    success: rawFetchResponse.ok,
    url,
    status: rawFetchResponse.status,
    statusText: rawFetchResponse.statusText,
    body: await attemptBodyDownload({ rawFetchResponse, bodyDownloader }), // Even non 200's might have a body
  };

  if (!rawFetchResponse.ok && throwOnFailure) {
    throw new FetchError(processedResponse);
  }

  return processedResponse;
};

/**
 * attemptBodyDownload
 * 
 * Attempts to download the body from a raw fetch response.
 * 
 * @param {Object} params
 * @param {Object} params.rawFetchResponse The response object from a fetch call.
 * @param {Function} params.bodyDownloader The function used to download a response body from a fetch response.
 * 
 * @returns {Promise<Object|undefined>}
 */
const attemptBodyDownload = async ({ rawFetchResponse, bodyDownloader }) => {
  try {
    const responseBody = await bodyDownloader(rawFetchResponse);
    return responseBody;
  } catch (err) {
    // If the raw fetch response was a non-200, we just consume the error and assume there is no further failure.
    // We do this because we want the user to get a useful FetchError to deal with the non 200 status, rather than
    // an unexpected error.
    if (!rawFetchResponse.ok) {
      return undefined;
    }

    throw err;
  }
};

/**
 * createSimpleFetcher
 * 
 * Convenience factory function to a closure around simpleFetch to avoid having to pass a fetch
 * function parameter on every call to simpleFetch.
 * 
 * @param {Object} params
 * @param {Function} params.fetch Environment fetch function implementation, e.g. node-fetch or window.fetch
 * @returns {Function}
 */
const createSimpleFetcher = ({ fetch }) => (simpleFetchArgs) => simpleFetch({ fetch, ...simpleFetchArgs });

module.exports = {
  simpleFetch,
  createSimpleFetcher,
};
