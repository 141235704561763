'use strict';

const applicationStatuses = {
  NO_APPLICATION_OR_ACCOUNT: 'NO_APPLICATION_OR_ACCOUNT',
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  APPLICATION_PROCESSING: 'APPLICATION_PROCESSING',
  APPLICATION_SUCCESSFUL: 'APPLICATION_SUCCESSFUL',
  APPLICATION_UNSUCCESSFUL: 'APPLICATION_UNSUCCESSFUL',
};

module.exports = {
  applicationStatuses,
};
