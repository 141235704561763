import {
  TOGGLE_SHOW_FILTERS,
  SET_FILTER,
  SELECT_REQUEST,
  RESET_FILTER,
  SET_REQUESTS,
  SELECT_ALL_REQUEST,
} from './types';

export const onChangeFilter = (filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});

export const setRequests = (data) => ({
  type: SET_REQUESTS,
  payload: data,
});

export const onResetFilters = () => ({
  type: RESET_FILTER,
  payload: {},
});

export const onSelectRequest = (request) => ({
  type: SELECT_REQUEST,
  payload: { request },
});
export const onSelectAllRequests = (selected) => ({
  type: SELECT_ALL_REQUEST,
  payload: { selected },
});
