import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './RealLoadingBar.module.scss';

// Real loading bar when we know how long an operation will take
export const RealLoadingBar = ({
  loading,
  animationDurationMs,
  hideAfterCompletionMs,
  containerClassName,
  containerStyle,
  progressClassName,
  progressStyle,
  progress,
}) => {
  const [visible, setVisible] = useState(loading);

  useEffect(() => {
    let loadingTimeout;
    // Allow the bar to complete, then reset state
    if (progress >= 100 || !loading) {
      loadingTimeout = setTimeout(() => {
        setVisible(false);
      }, hideAfterCompletionMs);
    } else if (loading) {
      setVisible(true);
    }

    return () => loadingTimeout && clearTimeout(loadingTimeout);
  }, [progress, hideAfterCompletionMs, loading]);

  const mergedContainerStyle = {
    ...containerStyle,
    opacity: visible ? 1 : 0,
  };

  const animationMs = progress >= 100 ? Math.round(animationDurationMs / 5) : animationDurationMs;

  const mergedProgressStyle = {
    ...progressStyle,
    transition: `width ${animationMs}ms ease-out`,
    width: `${loading ? progress : 100}%`,
  };

  return (
    <div className={classnames(Styles.loadingBarContainer, containerClassName)} style={mergedContainerStyle}>
      <div className={classnames(Styles.loadingBarProgress, progressClassName)} style={mergedProgressStyle} />
    </div>
  );
};

RealLoadingBar.displayName = 'RealLoadingBar';

RealLoadingBar.propTypes = {
  loading: PropTypes.bool.isRequired,
  animationDurationMs: PropTypes.number,
  hideAfterCompletionMs: PropTypes.number,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  progressClassName: PropTypes.string,
  progressStyle: PropTypes.object,
  progress: PropTypes.number.isRequired,
};
RealLoadingBar.defaultProps = {
  animationDurationMs: 1000,
  hideAfterCompletionMs: 500,
  containerClassName: undefined,
  containerStyle: undefined,
  progressClassName: undefined,
  progressStyle: undefined,
};
