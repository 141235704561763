import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import FinancialSummaryItem from './FinancialSummaryItem';
import FinancialSummary from './FinancialSummary';

export const FinancialSummaryByContact = ({
  onClickLink,
  showTrustAccount,
  totals,
  contactId,
  onFilterByRecent,
  onFilterInvoiceStatus,
  matterContactBalanceEnabled,
  trustTooltip,
}) => {
  const { t } = useTranslation();
  return (
    <FinancialSummary>
      <div className="financial-summary-totals">
        <div className="totals-container">
          <FinancialSummaryItem
            name="Unpaid"
            total={totals.UNPAID}
            onClick={() => {
              onFilterByRecent(false);
              onFilterInvoiceStatus(['FINAL', 'OVERDUE']);
              onClickLink({ type: 'contactBills', id: contactId });
            }}
          />
          <FinancialSummaryItem
            name="Overdue"
            total={totals.OVERDUE}
            onClick={() => {
              onFilterByRecent(false);
              onFilterInvoiceStatus(['OVERDUE']);
              onClickLink({ type: 'contactBills', id: contactId });
            }}
          />
          <FinancialSummaryItem
            total={totals.CREDIT}
            name="Credit"
            show={matterContactBalanceEnabled && totals.CREDIT > 0}
            onClick={() =>
              onClickLink({ type: 'contactTransactions', id: 'credit-note-ledger', params: [{ contactId }] })
            }
          />
          <FinancialSummaryItem
            total={totals.OPERATING}
            name={t('operatingRetainer')}
            show={matterContactBalanceEnabled && totals.OPERATING > 0}
            onClick={() => onClickLink({ type: 'contactTransactions', id: 'operating', params: [{ contactId }] })}
          />
          <FinancialSummaryItem
            name={t('trust')}
            total={totals.TRUST}
            show={matterContactBalanceEnabled && showTrustAccount}
            onClick={() => onClickLink({ type: 'contactTransactions', id: 'trust', params: [{ contactId }] })}
            tooltip={trustTooltip}
          />
        </div>
      </div>
    </FinancialSummary>
  );
};

FinancialSummaryByContact.displayName = 'FinancialSummaryByContact';

FinancialSummaryByContact.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onFilterByRecent: PropTypes.func.isRequired,
  onFilterInvoiceStatus: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  totals: PropTypes.object.isRequired,
  showTrustAccount: PropTypes.bool.isRequired,
  matterContactBalanceEnabled: PropTypes.bool.isRequired,
  trustTooltip: PropTypes.element,
};
FinancialSummaryByContact.defaultProps = {
  trustTooltip: undefined,
};
