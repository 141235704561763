import { useState, useEffect } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useCacheQuery } from 'web/hooks';
import { InitFirmUtbmsSettings, InitActivityCodes } from 'web/graphql/queries';
import * as forms from '@sb-itops/redux/forms2';
import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { FirmUtbmsSettings } from './FirmUtbmsSettings';
import { FirmUtbmsSettingsSchema } from './FirmUtbmsSettingsSchema';

const log = getLogger('firm-utbms-settings');
const mapABACodes = {
  isABALitigation: 'Litigation',
  isABABankruptcy: 'Bankruptcy',
  isABAProject: 'Project',
  isABACounseling: 'Counseling',
  isABAWorkersCompensation: "Workers' Compensation",
};

const scope = 'firm-utbms-settings';

const hooks = () => ({
  useCustomTaskCodesListData: () => {
    // TODO: Please do not copy this pattern because the data loading will need to be moved to the route container once it's converted from angular to react
    // This is a temporary solution, it's not recommended to put data fetching hooks in same level as the form hooks
    const { data, error } = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    if (error) {
      throw new Error(error);
    }
    const customTaskCodes = data?.utbmsCustomTaskCodes || [];

    return {
      customTaskCodes,
    };
  },
  useEditCustomTaskCode: () => {
    const [currentCustomTaskCode, setCurrentCustomTaskCode] = useState();
    const [showAddEditCustomTaskCodeCard, setShowAddEditCustomTaskCodeCard] = useState(false);
    const [editCustomTaskCodeId, setEditCustomTaskCodeId] = useState(undefined);

    const closeAddEditCustomTaskCodeCard = () => {
      setShowAddEditCustomTaskCodeCard(false);
      setEditCustomTaskCodeId(undefined);
      setCurrentCustomTaskCode(undefined);
    };

    const addCustomTaskCode = () => {
      setShowAddEditCustomTaskCodeCard(true);
      setEditCustomTaskCodeId(undefined);
      setCurrentCustomTaskCode(undefined);
    };

    const editCustomTaskCode = ({ id, customTaskCodes }) => {
      const customTaskCodeToBeEdited = customTaskCodes.find((code) => code.id === id);
      setShowAddEditCustomTaskCodeCard(true);
      setEditCustomTaskCodeId(id);
      setCurrentCustomTaskCode(customTaskCodeToBeEdited);
    };

    return {
      currentCustomTaskCode,
      editCustomTaskCodeId,
      setCurrentCustomTaskCode,
      showAddEditCustomTaskCodeCard,
      closeAddEditCustomTaskCodeCard,
      addCustomTaskCode,
      editCustomTaskCode,
    };
  },
  useSelectors: () => {
    const dispatch = useDispatch();

    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);

    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const { isUtbmsEnabled, ledesFirmId, utbmsCodesets, utbmsCodesRequired } = formFields;
    const { data: utbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);
    const firmUtbmsSettings = utbmsSettingsData?.firmUtbmsSettings;
    let utbmsCodeSetsError;

    useEffect(() => {
      if (firmUtbmsSettings) {
        const { selectedCodeSets = [] } = firmUtbmsSettings;
        const utbmsCodesetsInit = {};

        Object.entries(mapABACodes).forEach(([key, value]) => {
          if (selectedCodeSets.indexOf(value) >= 0) utbmsCodesetsInit[key] = true;
        });

        dispatch(
          formActions.initialiseForm({
            fieldValues: {
              isUtbmsEnabled: firmUtbmsSettings.isUtbmsEnabled,
              utbmsCodesRequired: firmUtbmsSettings.utbmsCodesRequired,
              ledesFirmId: firmUtbmsSettings.ledesFirmId,
              utbmsCodesets: Object.keys(utbmsCodesetsInit).length > 0 ? utbmsCodesetsInit : undefined,
            },
          }),
        );
      } else {
        // When firmUtbmsSettings entity is not created yet we initalise the form with the below default value
        dispatch(
          formActions.initialiseForm({
            fieldValues: {
              isUtbmsEnabled: false,
              utbmsCodesRequired: false,
              ledesFirmId: null,
              utbmsCodesets: undefined,
            },
          }),
        );
      }

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firmUtbmsSettings]);

    const validateFn = (fieldValues) => {
      const formErrors = {};
      if (
        fieldValues.isUtbmsEnabled &&
        !Object.keys(mapABACodes).some((key) => fieldValues.utbmsCodesets && fieldValues.utbmsCodesets[key] === true)
      ) {
        // at least one of the code field should be true
        formErrors.isUtbmsEnabled = 'At least one code set should be selected.';
      }
      return formErrors;
    };

    return {
      // config
      utbmsCodeSetsError,
      // form fields
      isUtbmsEnabled,
      ledesFirmId,
      utbmsCodesRequired,
      utbmsCodesets,
      // form
      formInitialised,
      submitFailed,
      formSubmitting,
      onFieldValueUpdated: (fieldValues) => {
        dispatch(formActions.updateFieldValues({ fieldValues }));
        dispatch(formOperations.validateForm({ schema: FirmUtbmsSettingsSchema, validateFn }));
      },
      onSave: async (event) => {
        event.preventDefault();
        await dispatch(formOperations.validateForm({ schema: FirmUtbmsSettingsSchema, validateFn }));

        try {
          await dispatch(
            formOperations.submitFormWithValidationP({
              submitFnP: async (formFieldValues) => {
                const saveUtbmsSettings = {
                  versionId: uuid(),
                  isUtbmsEnabled: formFieldValues.isUtbmsEnabled,
                  ledesFirmId: null,
                  utbmsCodesRequired: false,
                  selectedCodeSets: [],
                };
                if (formFieldValues.isUtbmsEnabled) {
                  saveUtbmsSettings.selectedCodeSets = getSelectedCodeSets(formFieldValues);
                  saveUtbmsSettings.ledesFirmId = formFieldValues.ledesFirmId;
                  saveUtbmsSettings.utbmsCodesRequired = formFieldValues.utbmsCodesRequired;
                }

                await dispatchCommand({
                  type: 'Billing.Activities.Messages.Commands.SaveBillingUtbmsSettings',
                  message: saveUtbmsSettings,
                });

                messageDisplay.success('Firm UTBMS / LEDES settings saved');
              },
            }),
          );
        } catch (err) {
          messageDisplay.error(
            'Failed to save firm UTBMS / LEDES settings. Please check your connection and try again.',
          );
          log.warn('Problem saving firm UTBMS / LEDES settings', err);
        }
      },
    };
  },
});

const getSelectedCodeSets = (formFieldValues) => {
  const selectedCodeSets = Object.keys(formFieldValues.utbmsCodesets).reduce((acc, codeSet) => {
    if (formFieldValues.utbmsCodesets[codeSet]) {
      acc.push(mapABACodes[codeSet]);
    }
    return acc;
  }, []);
  return selectedCodeSets;
};

export const FirmUtbmsSettingsContainer = withApolloClient(withReduxProvider(composeHooks(hooks)(FirmUtbmsSettings)));

FirmUtbmsSettingsContainer.displayName = 'FirmUtbmsSettingsContainer';
