import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react';
import Styles from './SubscriptionPaymentComplete.module.scss';

const subtitle = `We won't charge your card until your trial completes, and so for now we hope you enjoy Smokeball!`;

export const SubscriptionPaymentComplete = ({ onPaymentMethodUpdated, acknowledgeButtonLabel }) => (
  <div className={Styles.paymentComplete}>
    <div className={Styles.title}>Subscription Successful!</div>
    <div className={Styles.subtitle}>
      <p> {subtitle} </p>
      <p> Please reach out to us if you have any questions, or if there is anything we can help you with. </p>
    </div>
    <div className={Styles.buttonSection}>
      <Button onClick={onPaymentMethodUpdated}>{acknowledgeButtonLabel}</Button>
    </div>
  </div>
);

SubscriptionPaymentComplete.displayName = 'SubscriptionPaymentComplete';

SubscriptionPaymentComplete.propTypes = {
  onPaymentMethodUpdated: PropTypes.func.isRequired,
  acknowledgeButtonLabel: PropTypes.string,
};

SubscriptionPaymentComplete.defaultProps = {
  acknowledgeButtonLabel: 'Close',
};
