import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { featureActive } from '@sb-itops/feature';
import { Modal, Translation, Button, buttonTypes, LoadingBarInfinite, Spinner } from '@sb-itops/react';
import { MattersDisplayFromMatters } from '@sb-matter-management/react/matters-display';
import { capitalize } from '@sb-itops/nodash';

import Styles from './FeeWiseTransactionDetailsModal.module.scss';
import { bankAccountName } from './bank-account-name';
import { paymentMethodName } from './payment-method-name';

const FEE_WISE_REFUND_CONFIRMATION_MODAL_ID = 'FEE_WISE_REFUND_CONFIRMATION_MODAL_ID';

export const FeeWiseTransactionDetailsModal = ({
  isVisible,
  loading,
  transactionDetail,
  onClose,
  onMatterClick,
  // refund
  isRefundConfirmationModalVisible,
  isRequestingRefund,
  openRefundConfirmationModal,
  requestRefund,
}) => {
  const {
    customerPaymentMethod: cpm,
    amount,
    date,
    matters,
    bankAccount,
    payerName,
    refundRequestedTimestamp,
  } = transactionDetail;
  const isRefundRequestEnabled = featureActive('BB-13152');
  return (
    <>
      <Modal
        className={Styles.feeWisePayoutDetailsModal}
        modalHeaderClassName={Styles.modalHeader}
        modalBodyClassName={Styles.modalBody}
        modalFooterClassName={Styles.modalFooter}
        title="Payment Details"
        isVisible={isVisible}
        onModalClose={onClose}
        shouldCloseOnOverlayClick={false}
        showFooter
        body={
          loading ? (
            <div className={Styles.loadingContainer}>
              <LoadingBarInfinite loading={loading} />
            </div>
          ) : (
            <Translation>
              {(t) => (
                <div className={Styles.gridContainer}>
                  <div className={classnames(Styles.columnLeft, Styles.row)}>
                    <div className={classnames('col-md-5', Styles.boldLabel)}>Amount</div>
                    <div className="col-md-7">{t('cents', { val: amount })}</div>
                  </div>
                  <div className={classnames(Styles.columnRight, Styles.row)}>
                    <div className={classnames('col-md-5', Styles.boldLabel)}>Date</div>
                    <div className="col-md-7">{t('date', { ts: date })}</div>
                  </div>

                  <div className={classnames(Styles.columnLeft, Styles.row)}>
                    <div className={classnames('col-md-5', Styles.boldLabel)}>Matter</div>
                    <div className="col-md-7">
                      {Array.isArray(matters) && matters.length > 0 && (
                        <MattersDisplayFromMatters matters={matters} asLink onClickLink={onMatterClick} />
                      )}
                    </div>
                  </div>
                  <div className={classnames(Styles.columnRight, Styles.row)}>
                    <div className={classnames('col-md-5', Styles.boldLabel)}>Account</div>
                    <div className="col-md-7">{bankAccountName({ bankAccount, t })}</div>
                  </div>

                  <div className={classnames(Styles.columnLeft, Styles.row)}>
                    <div className={classnames('col-md-5', Styles.boldLabel)}>Payer Name</div>
                    <div className="col-md-7">{payerName}</div>
                  </div>
                  <div className={classnames(Styles.columnRight, Styles.row)}>
                    <div className={classnames('col-md-5', Styles.boldLabel)}>Method</div>
                    <div className="col-md-7">{paymentMethodName({ paymentMethod: cpm?.paymentMethod })}</div>
                  </div>
                  {cpm?.card && (
                    <>
                      <div className={classnames(Styles.columnLeft, Styles.row)}>
                        <div className={classnames('col-md-5', Styles.boldLabel)}>Card Type</div>
                        <div className="col-md-7">{capitalize(cpm.card?.scheme || '')}</div>
                      </div>
                      <div className={classnames(Styles.columnRight, Styles.row)}>
                        <div className={classnames('col-md-5', Styles.boldLabel)}>Last four digits</div>
                        <div className="col-md-7">{cpm.card?.partialNumber}</div>
                      </div>
                    </>
                  )}
                  {cpm?.debit && (
                    <div className={classnames(Styles.columnRight, Styles.row)}>
                      <div className={classnames('col-md-5', Styles.boldLabel)}>Last four digits</div>
                      <div className="col-md-7">{cpm.debit?.partialNumber}</div>
                    </div>
                  )}
                  {refundRequestedTimestamp && (
                    <div className={classnames(Styles.columnRight, Styles.row)}>
                      <div className={classnames('col-md-5', Styles.boldLabel)}>Refund Requested</div>
                      <div className="col-md-7">{t('date', { ts: refundRequestedTimestamp })}</div>
                    </div>
                  )}
                </div>
              )}
            </Translation>
          )
        }
        footer={
          <div className={Styles.buttonContainer}>
            {isRefundRequestEnabled && (
              <Button disabled={isRequestingRefund} onClick={openRefundConfirmationModal} type={buttonTypes.dangerAlt}>
                Refund {isRequestingRefund && <Spinner small />}
              </Button>
            )}
          </div>
        }
      />
      {isRefundConfirmationModalVisible && (
        <ConfirmationModalDialog
          primaryButtonText="Refund"
          onConfirm={() => requestRefund({ transactionDetail })}
          modalId={FEE_WISE_REFUND_CONFIRMATION_MODAL_ID}
          body={
            <>
              <p>
                Refunding a payment will return the money paid to the payer. The refund can take up to ten days to
                appear in the client’s bank account.
              </p>
              <p>Are you sure you would like to refund this payment?</p>
            </>
          }
        />
      )}
    </>
  );
};

FeeWiseTransactionDetailsModal.displayName = 'FeeWiseTransactionDetailsModal';

FeeWiseTransactionDetailsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMatterClick: PropTypes.func.isRequired,
  transactionDetail: PropTypes.shape({
    date: PropTypes.string,
    amount: PropTypes.number,
    matters: PropTypes.array,
    accountDisplay: PropTypes.string,
    payerName: PropTypes.string,
    customerPaymentMethod: PropTypes.shape({
      paymentMethod: PropTypes.string,
      card: PropTypes.shape({
        scheme: PropTypes.string,
        partialNumber: PropTypes.string,
      }),
      debit: PropTypes.shape({
        accountName: PropTypes.string,
        partialNumber: PropTypes.string,
      }),
    }),
    bankAccount: PropTypes.shape({
      id: PropTypes.string,
      accountType: PropTypes.string,
      accountName: PropTypes.string,
      displayName: PropTypes.string,
    }),
    refundRequestedTimestamp: PropTypes.string, // UTC/ISO timestamp string
  }).isRequired,

  // refund
  isRefundConfirmationModalVisible: PropTypes.bool.isRequired,
  isRequestingRefund: PropTypes.bool.isRequired,
  openRefundConfirmationModal: PropTypes.func.isRequired,
  requestRefund: PropTypes.func.isRequired,
};

FeeWiseTransactionDetailsModal.defaultProps = {};
