import { store } from '@sb-itops/redux';

/**
 * Creates a default state at the specified path.
 * This is useful when making route or feature level redux implementations which make use of generic behaviours.
 * You should call this action only once per path.
 */
export const setDefaultState = ({ actionName = 'SET_DEFAULT_STATE', defaultState }) => {
  const SET_DEFAULT_STATE = actionName;

  const setDefaultStateAction = () =>
    store.dispatch({
      type: SET_DEFAULT_STATE,
      payload: {
        defaultState,
      },
    });

  const setDefaultStateReducer = (state, action) => ({ ...action.payload.defaultState });

  const setDefaultStateHandler = {
    [SET_DEFAULT_STATE]: setDefaultStateReducer,
  };

  return { setDefaultStateAction, setDefaultStateReducer, setDefaultStateHandler, SET_DEFAULT_STATE };
};
