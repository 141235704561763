import React from 'react';
import PropTypes from 'prop-types';
import { StatelessSelect } from '@sb-itops/react';
import classnames from 'classnames';

import Styles from './MatterTypeSelect.module.scss';

export const MatterTypeSelect = ({
  isRequired,
  showError,
  // form fields
  locationId,
  matterTypeCategory,
  matterTypeId,
  disabled,
  // select options
  locationOptions,
  matterTypeCategoryOptions,
  matterTypeOptions,
  // callbacks
  onLocationUpdated,
  onCategoryUpdated,
  onMatterTypeUpdated,
}) => {
  const selectedMatterTypeCategory = matterTypeCategoryOptions.find(
    (categoryOption) => categoryOption.value === matterTypeCategory,
  );
  const selectedMatterType = matterTypeOptions.find((matterTypeOption) => matterTypeOption.value === matterTypeId);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.locationRow}>
        <StatelessSelect
          className={Styles.locationSelect}
          options={locationOptions}
          selectedOption={locationId}
          disabled={disabled}
          onChange={(newLocation) => onLocationUpdated(newLocation.value)}
        />
        <StatelessSelect
          className={classnames(
            Styles.categorySelect,
            showError && isRequired && locationId && !matterTypeCategory ? Styles.hasError : undefined,
          )}
          placeholder="Select an area of law..."
          options={matterTypeCategoryOptions}
          selectedOption={selectedMatterTypeCategory}
          disabled={disabled}
          onChange={(newCategory) => onCategoryUpdated(newCategory.value)}
        />
      </div>
      <div>
        <StatelessSelect
          className={showError && isRequired && matterTypeCategory && !matterTypeId ? Styles.hasError : undefined}
          placeholder="Select a matter type..."
          options={matterTypeOptions}
          disabled={disabled || !matterTypeCategory}
          selectedOption={selectedMatterType}
          onChange={(newMatterType) => onMatterTypeUpdated(newMatterType)}
        />
      </div>
    </div>
  );
};

const selectOptions = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

MatterTypeSelect.propTypes = {
  isRequired: PropTypes.bool,
  showError: PropTypes.bool,
  locationId: PropTypes.string,
  matterTypeCategory: PropTypes.string,
  matterTypeId: PropTypes.string,
  disabled: PropTypes.bool,
  // select options
  locationOptions: PropTypes.arrayOf(selectOptions),
  matterTypeCategoryOptions: PropTypes.arrayOf(selectOptions),
  matterTypeOptions: PropTypes.arrayOf(selectOptions),
  // callbacks
  onLocationUpdated: PropTypes.func.isRequired,
  onCategoryUpdated: PropTypes.func.isRequired,
  onMatterTypeUpdated: PropTypes.func.isRequired,
};

MatterTypeSelect.defaultProps = {
  isRequired: true,
  showError: false,
  locationId: undefined,
  matterTypeCategory: undefined,
  matterTypeId: undefined,
  disabled: undefined,
  // select options
  locationOptions: [],
  matterTypeCategoryOptions: [],
  matterTypeOptions: [],
};

MatterTypeSelect.displayName = 'MatterTypeSelect';
