import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import { capitalize } from '@sb-itops/nodash';
import { useTranslation } from '@sb-itops/react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import Styles from './ExpenseTable.module.scss';

const {
  amountCellLocalisedRendererWithWaived,
  balanceCellLocalisedRenderer,
  checkboxCellWrapperRenderer,
  tickRenderer,
  yyyymmddLocalisedRenderer,
} = utils;

const isRowCheckboxDisabled = (rowData) => {
  // Anticipated Disbursement NOT supported
  if (!featureActive('BB-9573')) {
    return !!rowData.operatingCheque?.id;
  }

  // Disable checkbox unless it is unpaid AD
  if (rowData.isAnticipated && !rowData.expensePaymentDetails?.isPaid) {
    return false;
  }

  return true;
};

function checkboxHeaderCellRenderer({ props, isChequeSelection }) {
  return () => {
    const { selectedExpenseIds, expenses, onToggleExpenses } = props;
    const selectableExpenses = isChequeSelection ? expenses.filter((exp) => !isRowCheckboxDisabled(exp)) : expenses;
    const disabled = selectableExpenses.length === 0;

    const currentPageSelectedCount = expenses.filter((expense) => selectedExpenseIds[expense.id]).length;
    const isAllSelected =
      !disabled && !!currentPageSelectedCount && currentPageSelectedCount === selectableExpenses.length;

    const onToggleAllExpenses = () => {
      const expensesToToggle = isAllSelected
        ? expenses.filter((expense) => !isChequeSelection || !isRowCheckboxDisabled(expense))
        : expenses.filter(
            (expense) => (!isChequeSelection || !isRowCheckboxDisabled(expense)) && !selectedExpenseIds[expense.id],
          ); // if some not selected: toggle those not already selected
      onToggleExpenses(expensesToToggle);
    };

    return <Checkbox checked={isAllSelected} onChange={onToggleAllExpenses} disabled={disabled} />;
  };
}

function checkboxCellRenderer({ props, isChequeSelection }) {
  return ({ rowData }) => {
    const { onToggleExpenses, selectedExpenseIds } = props;

    return (
      <Checkbox
        checked={selectedExpenseIds[rowData.id]}
        onChange={() => {
          const expense = rowData;
          onToggleExpenses([expense]);
        }}
        // When selecting expenses for cheque creation, some may be disabled (firm list)
        // When selecting for bulk actions, all should be enabled (matter list)
        disabled={isChequeSelection && isRowCheckboxDisabled(rowData)}
      />
    );
  };
}

function subjectCellRenderer({ cellData, rowData: expense }, onOpenChequeModal, t) {
  return (
    <div className={Styles.subjectField}>
      <div className={Styles.description} title={cellData}>
        {cellData}
      </div>
      {featureActive('BB-3331') && expense?.operatingCheque?.id && (
        <div className={Styles.infoIcon}>
          <i
            title={`This ${t('expense')} is associated with an ${t('operatingCheque')}.`}
            className="icon icon-alert-1"
            onClick={(event) => {
              event.stopPropagation();
              onOpenChequeModal(expense.operatingCheque.id);
            }}
          />
        </div>
      )}
      {featureActive('BB-9573') &&
        expense?.isAnticipated &&
        !expense.expensePaymentDetails?.isPaid &&
        expense.invoice?.status === invoiceStatus.PAID && (
          <div className={Styles.infoIcon}>
            <i
              title={`This ${t('expense')} has been paid by your client but is not marked as paid to the supplier.`}
              className="icon-icon-fat-exclamation-circle-filled"
            />
          </div>
        )}
    </div>
  );
}

function staffInitialsCellRenderer({ cellData }) {
  return cellData?.initials || undefined;
}

function quantityRenderer({ cellData }) {
  return (Math.abs(cellData || 0) / 100).toFixed(2);
}

function supplierDueDateCellRenderer({ rowData }) {
  if (rowData?.isAnticipated) {
    if (!rowData.expensePaymentDetails?.isPaid) {
      return yyyymmddLocalisedRenderer({ cellData: rowData.expensePaymentDetails?.paymentDue });
    }

    return 'Paid';
  }

  // Handle non anticipated disbursement
  if (rowData.expensePaymentDetails?.isPaid || rowData.operatingCheque?.id) {
    return 'Paid';
  }

  if (!rowData.expensePaymentDetails?.isPayable) {
    return '-';
  }

  return '';
}

export const ExpenseTable = (props) => {
  const {
    dataLoading,
    expenses,
    sortDirection,
    sortBy,
    onSort,
    expenseSummary,
    showAnticipatedDisbursementColumn,
    showBulkUpdateSelectColumn,
    showChequeCreateSelectColumn,
    showMatterColumn,
    onOpenChequeModal,
  } = props;

  const { t } = useTranslation();

  const matterCellRenderer = ({ cellData, rowData }) => {
    if (!cellData?.id) {
      return undefined;
    }

    const matterDisplay = getMatterDisplay(cellData, rowData.matter?.matterType?.name);

    if (!rowData.matter?.id) {
      return matterDisplay;
    }

    // TODO: Create a GQL-compatible MatterDisplay to handle all use cases
    return (
      <LinkableText
        text={matterDisplay}
        onClickLink={() => props.onClickLink({ type: 'matter', id: rowData.matter.id })}
        asLink
        inline
      />
    );
  };

  const billedCellRenderer = ({ cellData, rowData }) => {
    if (rowData.isInvoicedExternally) {
      return (
        <div className="center-align">
          <span>Yes</span>
        </div>
      );
    }

    if (cellData !== 'PENDING' && rowData?.invoice?.id) {
      return (
        <div className="center-align">
          <LinkableText
            text={`#${rowData.invoice.invoiceNumber || 'unknown'}`}
            onClickLink={() => props.onClickLink({ type: 'invoice', id: rowData.invoice.id })}
            asLink
            inline
          />
        </div>
      );
    }

    return null;
  };

  const rowClick = ({ rowData }) => {
    props.onOpenExpenseModal(rowData);
  };

  const footer = () => {
    const billable = expenseSummary.billable;
    const waived = expenseSummary.waived;
    const nonBillable = expenseSummary.nonBillable;
    const billed = expenseSummary.billed;

    return (
      <div className={Styles.expenseTableFooter}>
        <span>
          {t('cents', {
            val: billable || 0,
          })}{' '}
          Billable (inclusive of{' '}
          {t('cents', {
            val: waived || 0,
          })}{' '}
          written off)
        </span>
        <span>
          {t('cents', {
            val: nonBillable || 0,
          })}{' '}
          Non-Billable
        </span>
        <span>
          {t('cents', {
            val: billed || 0,
          })}{' '}
          Billed
        </span>
        {hasFacet(facets.tax) && <span>(all amounts exc. {t('tax')})</span>}
      </div>
    );
  };

  const contextMenuCellRenderer = ({ rowData }) =>
    rowData.operatingCheque?.isPrinted && (
      <ContextMenu
        // eslint-disable-next-line react/no-unstable-nested-components
        body={() => (
          <div className="context-menu-body list-group">
            <button
              type="button"
              className="list-group-item"
              onClick={() => props.onPrintCheque(rowData.operatingCheque.id)}
            >
              Reprint {capitalize(t('cheque'))}
            </button>
          </div>
        )}
      >
        <div className="context-menu-cell">...</div>
      </ContextMenu>
    );

  return (
    <Table
      className={Styles.billingExpenseTable}
      dataLoading={dataLoading}
      footerRenderer={footer}
      list={expenses}
      showFooter={!!expenseSummary}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onRowClick={rowClick}
    >
      {(showChequeCreateSelectColumn || showBulkUpdateSelectColumn) && (
        <Column
          key="UNNEEDED"
          dataKey="UNNEEDED"
          label=""
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(
            checkboxHeaderCellRenderer({ props, isChequeSelection: showChequeCreateSelectColumn }),
          )}
          cellRenderer={checkboxCellWrapperRenderer(
            checkboxCellRenderer({ props, isChequeSelection: showChequeCreateSelectColumn }),
          )}
          disableSort
        />
      )}
      <Column
        key="expenseDate"
        dataKey="expenseDate"
        label="Date"
        flexGrow={2}
        width={24}
        cellRenderer={yyyymmddLocalisedRenderer}
      />
      <Column
        key="staffInitials"
        dataKey="expenseEarnerStaff"
        label="Staff"
        flexGrow={0.8}
        cellRenderer={staffInitialsCellRenderer}
      />
      {showMatterColumn && (
        <Column key="matter" dataKey="matter" label="Matter" flexGrow={4} cellRenderer={matterCellRenderer} />
      )}
      <Column
        className={Styles.expenseTableActivity}
        key="activity"
        dataKey="activityCode"
        label="Activity"
        flexGrow={0.8}
        width={24}
      />
      {props.showTaskColumn && (
        <Column dataKey="utbmsTaskCode" label={t('capitalizeAllWords', { val: 'expense' })} flexGrow={1} />
      )}
      <Column
        key="description"
        dataKey="description"
        label="Subject"
        flexGrow={6}
        cellRenderer={(data) => subjectCellRenderer(data, onOpenChequeModal, t)}
      />
      <Column
        className="right-align"
        dataKey="quantity"
        label="Quantity"
        flexGrow={2}
        disableSort
        cellRenderer={quantityRenderer}
        width={24}
      />
      <Column
        dataKey="price"
        label="Price"
        className={classnames(Styles.expenseTablePrice, 'right-align')}
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
        disableSort
      />
      <Column
        className="right-align"
        cellRenderer={amountCellLocalisedRendererWithWaived}
        dataKey="amountExcTax"
        disableSort
        flexGrow={2}
        label={hasFacet(facets.tax) ? `Amount exc. ${t('tax')}` : 'Amount'}
      />
      {props.showTaxColumns && (
        <Column
          className="right-align"
          dataKey="tax"
          label={t('tax')}
          cellRenderer={balanceCellLocalisedRenderer}
          flexGrow={1.6}
          width={24}
        />
      )}
      {props.showTaxColumns && (
        <Column
          className="right-align"
          dataKey="amountIncTax"
          label={`Amount inc. ${t('tax')}`}
          labelTooltip={`Amount including ${t('tax')}`}
          flexGrow={2.4}
          cellRenderer={amountCellLocalisedRendererWithWaived}
          disableSort
        />
      )}
      {featureActive('BB-9573') && showAnticipatedDisbursementColumn && (
        <Column
          dataKey="expenseId"
          label="Supplier Due Date"
          cellRenderer={supplierDueDateCellRenderer}
          flexGrow={2}
          width={24}
          disableSort
        />
      )}
      <Column
        key="isBillable"
        dataKey="isBillable"
        label="Billable?"
        flexGrow={1}
        width={24}
        cellRenderer={tickRenderer}
      />
      <Column
        key="billed"
        dataKey="invoiceNumber"
        label="Billed?"
        flexGrow={1}
        width={32}
        cellRenderer={billedCellRenderer}
      />
      {featureActive('BB-3331') && (
        <Column dataKey="UNUSED" label="" disableSort width={40} cellRenderer={contextMenuCellRenderer} />
      )}
    </Table>
  );
};

ExpenseTable.displayName = 'ExpenseTable';

ExpenseTable.propTypes = {
  dataLoading: PropTypes.bool,
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      expenseDate: PropTypes.number,
      expenseEarnerStaff: PropTypes.shape({
        id: PropTypes.string,
        initials: PropTypes.string,
      }),
      matter: PropTypes.object,
      activityCode: PropTypes.string,
      utbmsTaskCode: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      rate: PropTypes.number,
      amount: PropTypes.number,
      isBillable: PropTypes.oneOf([true, false, null]),
      invoiceNumber: PropTypes.string,
      amountExclTax: PropTypes.number,
      amountIncTax: PropTypes.number,
      tax: PropTypes.number,
    }),
  ).isRequired,
  expenseSummary: PropTypes.shape({
    units: PropTypes.number.isRequired,
    nonBillable: PropTypes.number.isRequired,
    billable: PropTypes.number.isRequired,
    billed: PropTypes.number.isRequired,
    waived: PropTypes.number.isRequired,
  }),
  selectedExpenseIds: PropTypes.object,
  showAnticipatedDisbursementColumn: PropTypes.bool,
  showBulkUpdateSelectColumn: PropTypes.bool,
  showChequeCreateSelectColumn: PropTypes.bool,
  showMatterColumn: PropTypes.bool,
  showTaskColumn: PropTypes.bool,
  showTaxColumns: PropTypes.bool,
  sortBy: PropTypes.oneOf([
    'expenseDate',
    'expenseEarnerStaff',
    'activityCode',
    'utbmsTaskCode',
    'matter',
    'description',
    'isBillable',
    'invoiceNumber',
    'tax',
  ]).isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onClickLink: PropTypes.func.isRequired,
  onOpenChequeModal: PropTypes.func.isRequired,
  onOpenExpenseModal: PropTypes.func.isRequired,
  onPrintCheque: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onToggleExpenses: (props, propName, componentName) => {
    if (
      (props.showChequeCreateSelectColumn === true || props.showBulkUpdateSelectColumn === true) &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error(
        ` Warning: Failed prop type: The prop '${propName}' is marked as required in '${componentName}', but its value is '${props[propName]}'`,
      );
    }
    return null;
  },
};

ExpenseTable.defaultProps = {
  dataLoading: false,
  expenseSummary: {
    units: 0,
    nonBillable: 0,
    billable: 0,
    billed: 0,
    waived: 0,
  },
  selectedExpenseIds: {},
  showAnticipatedDisbursementColumn: false,
  showBulkUpdateSelectColumn: false,
  showChequeCreateSelectColumn: false,
  showMatterColumn: false,
  showTaskColumn: false,
  showTaxColumns: false,
  onToggleExpenses: undefined,
};

export default ExpenseTable;
