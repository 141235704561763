const { regionType } = require('@sb-itops/region');

const personTitle = Object.freeze({
  MR: { value: 'Mr', label: 'Mr', isAbbreviated: true },
  MRS: { value: 'Mrs', label: 'Mrs', isAbbreviated: true },
  MISS: { value: 'Miss', label: 'Miss', isAbbreviated: false },
  MS: { label: 'Ms', value: 'Ms', isAbbreviated: true },
  MX: { label: 'Mx', value: 'Mx', isAbbreviated: true },
  DR: { label: 'Dr', value: 'Dr', isAbbreviated: true },
  MASTER: { label: 'Master', value: 'Master', isAbbreviated: false },
  HON: { label: 'Honorable', value: 'Honorable', isAbbreviated: true },
  HON_AU: { label: 'Honourable', value: 'Honourable', isAbbreviated: true },
  PROFESSOR: { label: 'Professor', value: 'Professor', isAbbreviated: false },
  REVEREND: { label: 'Reverend', value: 'Reverend', isAbbreviated: false },
  MAGISTRATE: { label: 'Magistrate', value: 'Magistrate', isAbbreviated: false },
  JUDGE: { label: 'Judge', value: 'Judge', isAbbreviated: false },
  FATHER: { label: 'Father', value: 'Father', isAbbreviated: false },
  ATTORNEY: { label: 'Attorney', value: 'Attorney', isAbbreviated: false },
});

const personTitles = {
  [regionType.US]: [
    personTitle.MR,
    personTitle.MRS,
    personTitle.MISS,
    personTitle.MS,
    personTitle.MX,
    personTitle.DR,
    personTitle.MASTER,
    personTitle.HON,
    personTitle.PROFESSOR,
    personTitle.REVEREND,
    personTitle.MAGISTRATE,
    personTitle.JUDGE,
    personTitle.FATHER,
    personTitle.ATTORNEY,
  ],
  [regionType.AU]: [
    personTitle.MR,
    personTitle.MRS,
    personTitle.MISS,
    personTitle.MS,
    personTitle.MX,
    personTitle.DR,
    personTitle.MASTER,
    personTitle.HON_AU,
    personTitle.PROFESSOR,
    personTitle.REVEREND,
    personTitle.MAGISTRATE,
    personTitle.JUDGE,
    personTitle.FATHER,
  ],
  [regionType.GB]: [personTitle.MR, personTitle.MRS, personTitle.MISS, personTitle.MS, personTitle.MX, personTitle.DR],
};

const contactTypes = Object.freeze({
  PERSON: 'person',
  ORGANISATION: 'organisation',
  TRUST: 'trust',
  GROUP_OF_PEOPLE: 'groupOfPeople',
  COMPANY: 'company',
});

const companyType = Object.freeze({
  CORPORATION: { value: 'Corporation', label: 'Corporation' },
  GOVERNMENT_AGENCY: { value: 'Government Agency', label: 'Government Agency' },
  LLC: { value: 'Limited Liability Company', label: 'Limited Liability Company' },
  SOLE_PROPRIETOR: { value: 'Sole Proprietor', label: 'Sole Proprietor' },
  SOLE_TRADER: { value: 'Sole Trader', label: 'Sole Trader' },
  PARTNERSHIP: { value: 'Partnership', label: 'Partnership' },
  LIMITED_LIABILITY_PARTNERSHIP: { value: 'Limited Liability Partnership', label: 'Limited Liability Partnership' },
  NOT_FOR_PROFIT: { value: 'Not-for-Profit', label: 'Not-for-Profit' },
  COMPANY: { value: 'Company', label: 'Company' },
  GOVERNMENT_DEPARTMENT: { value: 'Government Department', label: 'Government Department' },
});

const auCompanyTypes = [
  companyType.COMPANY,
  companyType.SOLE_PROPRIETOR,
  companyType.PARTNERSHIP,
  companyType.GOVERNMENT_DEPARTMENT,
];
const usCompanyTypes = [
  companyType.CORPORATION,
  companyType.GOVERNMENT_AGENCY,
  companyType.LLC,
  companyType.SOLE_PROPRIETOR,
  companyType.PARTNERSHIP,
  companyType.NOT_FOR_PROFIT,
];
const gbCompanyTypes = [
  companyType.COMPANY,
  companyType.SOLE_TRADER,
  companyType.PARTNERSHIP,
  companyType.LIMITED_LIABILITY_PARTNERSHIP,
  companyType.GOVERNMENT_DEPARTMENT,
];

const auPostalBoxTypes = Object.freeze({
  CARE_PO: { value: 'Care PO', label: 'Care PO' },
  CMA: { value: 'CMA', label: 'CMA' },
  CMB: { value: 'CMB', label: 'CMB' },
  CPA: { value: 'CPA', label: 'CPA' },
  GPO_BOX: { value: 'GPO Box', label: 'GPO Box' },
  LOCKED_BAG: { value: 'Locked Bag', label: 'Locked Bag' },
  MS: { value: 'MS', label: 'MS' },
  PO_BOX: { value: 'PO Box', label: 'PO Box' },
  RSD: { value: 'RSD', label: 'RSD' },
  RMB: { value: 'RMB', label: 'RMB' },
  RMS: { value: 'RMS', label: 'RMS' },
  PRIVATE_BAG: { value: 'Private Bag', label: 'Private Bag' },
});

const auPostalBoxTypesList = Object.values(auPostalBoxTypes);

const auContactRole = Object.freeze({
  CLIENT: 'Client',
});

const gbContactRole = Object.freeze({
  CLIENT: 'Client',
});

const usContactRole = Object.freeze({
  CLIENT: 'Client',
});

const contactRoleStrategy = {
  [regionType.AU]: auContactRole,
  [regionType.GB]: gbContactRole,
  [regionType.US]: usContactRole,
};

module.exports = {
  personTitle,
  personTitles,
  contactTypes,
  auCompanyTypes,
  usCompanyTypes,
  gbCompanyTypes,
  auPostalBoxTypes,
  auPostalBoxTypesList,
  companyType,
  contactRoleStrategy,
};
