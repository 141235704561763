import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/custom-task-code';

angular
  .module('@sb-billing/services')
  .service('sbCustomTaskCodeService', function(sbGenericCacheService, sbEndpointType) {
    const endpoint = 'billing/custom-task-codes';

    sbGenericCacheService.setupCache({
      name: 'sbCustomTaskCodeService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
        poll: 60,
        subscriptions: 'notifier.BillingActivitiesNotifications.CustomTaskCodeUpdated',
      },
      updateRedux,
      clearRedux,
    });
  });
