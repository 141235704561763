/* eslint-disable no-await-in-loop */
import * as PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { CalendarData, InitStaffSettings, RecurringEventData } from 'web/graphql/queries';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as calendarFiltersFeature from 'web/redux/features/calendar-filters';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import moment from 'moment';
import { getUniqueColors } from 'web/business-logic/calendar';
import { CalendarModalContainer } from './CalendarModal.container';

const FETCH_LIMIT = 100;

const hooks = ({ matterId, scope }) => ({
  useGraphQL: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const [selectedCells, setSelectedCells] = useState();
    // Used to prevent the loading bar flashing and resetting inbetween fetches
    const [loading, setLoading] = useState(false);
    const previousResultCount = useRef(0);

    const { selectedItem: currentCalendarPage, setSelectedItem: setPageNumber } = useSingleItemSelection({
      scope,
      initialSelection: 0,
    });

    // Only load the last month. Up to a week of the previous can be displayed on the calendar month view so we want to include those items as well
    const minDate = matterId ? moment() : moment().startOf('month').subtract(1, 'week');
    const maxDate = matterId
      ? moment().add(2, 'year').toISOString()
      : moment().endOf('month').add(2, 'week').toISOString();
    const [loadFromDate, setLoadFromDate] = useState(minDate.toISOString());
    const [currentlySelectedEndDate, setCurrentlySelectedEndDate] = useState(maxDate);
    const { selectors, actions } = useScopedFeature(calendarFiltersFeature, scope);
    const { sortBy, sortDirection } = useSelector(selectors.getSortData);
    const selectedView = useSelector(selectors.getSelectedView);
    const staffFilter = useSelector(selectors.getStaffFilter);
    const dispatch = useDispatch();

    const calendarQueryResult = useSubscribedQuery(CalendarData, {
      variables: {
        eventFilter: {
          matterId,
          min: loadFromDate,
          sortBy,
          sortDirection: sortDirection.toUpperCase(),
          max: currentlySelectedEndDate,
        },
        // Must be compatible with both the calendar and the matter events table
        offset: currentCalendarPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
      },
    });

    const onCalendarListPageChange = async ({ selected: pageNumber }) => {
      setPageNumber(pageNumber);
      setLoading(true);
      await calendarQueryResult.refetch({ offset: pageNumber * FETCH_LIMIT });
      setLoading(false);
    };

    // We lack a good way of fetching updates to the items we want, so we need to fetch everything
    // useSubscribedQuery onCompleted does not run after the first query unless notifyOnNetworkStatusChange is set
    // in which case it will fire on EVERY call which is undesirable
    // So we manually check and update the query
    // This sucks as if the client has 1k events, they will see 90% of the disappear after any changes
    useEffect(() => {
      if (matterId) {
        return;
      }
      const totalCount = calendarQueryResult?.data?.eventList?.totalCount || 0;
      const resultCount = calendarQueryResult?.data?.eventList?.results?.length || 0;

      // If we havent got all the results and are successfully still returning new results, keep loading
      // We bail out if we havent received any results in the last call as plenty can go wrong here, like DB updates midway through loading
      if (resultCount < totalCount && previousResultCount.current !== resultCount) {
        setLoading(true);

        previousResultCount.current = resultCount;

        calendarQueryResult.fetchMore({
          variables: {
            offset: resultCount,
          },
        });
      } else {
        previousResultCount.current = 0;
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarQueryResult?.data?.eventList?.results?.length, calendarQueryResult?.data?.eventList?.totalCount]);

    const recurringEventQueryResult = useSubscribedQuery(RecurringEventData, {
      variables: {
        eventFilter: {
          matterId,
          min: loadFromDate,
          max: currentlySelectedEndDate,
        },
      },
    });

    const recurringEventList = recurringEventQueryResult?.data?.recurringEventList?.results || [];

    // Latch the loading state
    if (!loading && calendarQueryResult.loading && !matterId) {
      setLoading(true);
    }

    const fetchMore = async (startDate, endDate) => {
      if (moment(currentlySelectedEndDate).isSameOrAfter(endDate) && moment(loadFromDate).isSameOrBefore(startDate)) {
        // Dont load anything we already have
        return;
      }
      setCurrentlySelectedEndDate(endDate.toISOString());
      setLoadFromDate(startDate.toISOString());
      calendarQueryResult.refetch();
    };

    if (calendarQueryResult.error) {
      throw new Error(calendarQueryResult.error);
    }

    const eventList = calendarQueryResult?.data?.eventList?.results || [];
    const eventCount = calendarQueryResult?.data?.eventList?.totalCount || 0;
    const totalNumberOfCalendarPages = Math.ceil(eventCount / FETCH_LIMIT);

    const entityMap = getUniqueColors(staffData?.staffMembers || []).reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr }),
      {},
    );

    // Stores refs to the appointments in the list so they can be modified after processing
    const appointmentRef = {};
    const modifiedRecurringAppointments = [];

    const calendar = eventList
      .concat(recurringEventList)
      .map((appointment) => {
        let skip = true;
        if (!appointment) {
          return null;
        }
        const staff = appointment.resourceIds.map((staffId) => {
          if (
            skip &&
            (staffFilter[staffId] === undefined ? !entityMap[staffId]?.isFormerStaff : !staffFilter[staffId])
          ) {
            skip = false;
          }
          return entityMap[staffId];
        });
        if (skip) {
          return null;
        }
        const matterDisplay = getMatterDisplayById(appointment.matterId);
        // HACK ALERT:
        // Dev Extreme has a bug where all day events coming from the desktop (which go from 12AM to 12AM) display over two days as calendar events must be 24hrs.
        // This is the workaround
        const endDate = appointment.allDay
          ? moment(appointment.endTime).subtract(1, 'second').toISOString()
          : appointment.endTime;

        appointmentRef[appointment.id] = {
          ...appointment,
          staff,
          startDate: appointment.startTime,
          endDate,
          matterDisplay,
          text: appointment.subject,
          // used to keep track of the original id and rrule when a recurring item is edited
          originalId: appointment.id,
          originalRRULE: appointment.rRULE,
        };
        // If this is a modified recurring appointment
        if (appointment.patternAppointmentId) {
          modifiedRecurringAppointments.push(appointmentRef[appointment.id]);
          if (appointment.eventType === 4) {
            // Deleted recurring instance
            return null;
          }
        }

        return appointmentRef[appointment.id];
      })
      .filter((appointment) => appointment);

    // Modify recurring appointments to ensure the parent appointment is processed first
    modifiedRecurringAppointments.forEach((appointment) => {
      if (appointmentRef[appointment.patternAppointmentId] && appointment?.originalStartTime?.replace) {
        // The backend uses a different structure for recurring appointments, even though we are using the same third party library for both
        // This populates the recurrenceException property so devexpress displays the appointments correctly
        // recurrenceException needs to be a comma separated list of the removed recurrence instances as ISO string date times with no separating characters
        appointmentRef[appointment.patternAppointmentId].recurrenceException = `${
          (appointmentRef[appointment.patternAppointmentId].recurrenceException
            ? `${appointmentRef[appointment.patternAppointmentId].recurrenceException},`
            : '') + appointment.originalStartTime.replace(/-|:|\./g, '').slice(0, 15)
        }Z`;
      }
    });

    const onDateChanged = () => {
      calendarQueryResult.refetch();
    };

    return {
      selectedCells,
      setSelectedCells,
      scope,
      staffFilter,
      totalNumberOfCalendarPages,
      currentCalendarPage,
      sortBy,
      sortDirection,
      sort: (payload) => {
        dispatch(actions.setSort(payload));
      },
      selectedView,
      setSelectedView: (view) => {
        dispatch(actions.setSelectedView({ view }));
      },
      setStaffFilter: (filter) => {
        dispatch(actions.setStaffFilter({ filter }));
      },
      onCalendarListPageChange,
      fetchMore,
      loading: loading || recurringEventQueryResult.loading,
      calendar,
      loggedInStaff: staffData?.loggedInStaff,
      onDateChanged,
    };
  },
});

export const CalendarDataContainer = (WrappedCalendarRoute) => {
  const WrappedComponent = CalendarModalContainer(composeHooks(hooks)(WrappedCalendarRoute));
  WrappedComponent.displayName = 'CalendarDataContainer';

  WrappedComponent.propTypes = {
    scope: PropTypes.string.isRequired,
    matterId: PropTypes.string,
    onClickLink: PropTypes.func,
  };

  WrappedComponent.defaultProps = {
    matterId: undefined,
    onClickLink: () => {},
  };
  return WrappedComponent;
};
