import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Radio.module.scss';

const Radio = memo(({ checked, disabled, children, value, name, onChange, direction }) => {
  const onChangeFn = (e) => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    onChange(e, value);
  };

  const containerClasses = classnames(Styles.radioContainer, direction === 'row' && Styles.radioContainerRow);
  const radioClasses = classnames(Styles.radio, direction === 'row' && Styles.radioRow);

  return (
    // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
    <label className={containerClasses} onClick={onChangeFn} role="presentation">
      <input
        className={radioClasses}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChangeFn}
        onClick={onClick}
      />
      {children}
    </label>
  );
});

function onClick(e) {
  e.stopPropagation();
}

Radio.displayName = 'Radio';

Radio.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  // we do not need to require the name since the RadioList will require the name and pass it down to the Radio.
  name: PropTypes.string,
  direction: PropTypes.string,
};

Radio.defaultProps = {
  onChange: () => {},
  checked: false,
  disabled: false,
  name: undefined,
  children: undefined,
  direction: 'column',
};

export default Radio;
