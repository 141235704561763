import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { getApolloClient } from 'web/services/apollo';

export const withApolloClient = (WrappedComponent) => (props) =>
  (
    <ApolloProvider client={getApolloClient()}>
      <WrappedComponent {...props} />
    </ApolloProvider>
  );
