import { getApolloClient } from "web/services/apollo";
import { InitFirmUser } from "web/graphql/queries";
import { getUserId } from "web/services/user-session-management";

angular.module("sb.billing.webapp").service("sbUserPermissionsService", function ($rootScope, sbLoggerService) {
  'use strict';
  const that = this;
  const log = sbLoggerService.getLogger('sbUserPermissionsService');
  // log.setLogLevel('info');

  that.initAndSubscribe = initAndSubscribe;

  function initAndSubscribe() {
    const apolloClient = getApolloClient();
    apolloClient
      .watchQuery({
        query: InitFirmUser.query,
        fetchPolicy: 'cache',
      })
      .subscribe((val) => {
        const currentUserId = getUserId();

        if (val.data && val.data.loggedInUser && val.data.loggedInUser.permissions) {
          $rootScope.$applyAsync(() => {
            $rootScope.sbPermissions = val.data.loggedInUser.permissions.reduce((acc, permission) => {
              if (permission.userId === currentUserId) {
                acc[permission.resourceId] = permission.isAuthorized;
              }
              return acc;
            }, {});
            log.info('applyAsync', new Date(), $rootScope.sbPermissions);
          });
        }
      });
  }
});
