import * as React from 'react';
import AsyncSelect from 'react-select/async';
import { OptionProps } from 'react-select';
import { Search } from 'web/components/icons';
import { isNewTheme } from 'web/services/theme';
import Styles from './TopnavSearch.module.scss';

export type TStyleOverrides = {
  inputWidth?: string;
  dropdownHeight?: string;
  dropdownWidth?: string;
};

export type TElementOptionProps = {
  value: string;
  element: JSX.Element;
  navFunc?: () => void;
  isDisabled?: boolean;
  noStyling?: boolean;
};

const Placeholder = () => (
  <span className={Styles.placeholder}>
    <Search />
    Search
  </span>
);

const ElementOption = (props: OptionProps<TElementOptionProps>) => {
  const { innerProps, innerRef, data } = props;
  const { navFunc, element, noStyling } = data;
  const classes = [
    `${navFunc ? Styles.navContainer : Styles.headContainer}`,
    `${navFunc && props.isFocused ? Styles.focused : ''}`,
    `${navFunc && props.isSelected ? Styles.selected : ''}`,
  ]
    .filter(Boolean)
    .join(' ');

  const className = noStyling ? '' : classes;

  return (
    <div ref={innerRef} {...innerProps} className={className}>
      {element}
    </div>
  );
};

type TProps = {
  asyncOptionElementResolver: (input: string) => Promise<TElementOptionProps[]>;
  runOnReturnKey?: (inputText: string) => void;
  styleOverrides?: TStyleOverrides;
};

export const TopnavTypeahead = ({ asyncOptionElementResolver, runOnReturnKey, styleOverrides }: TProps) => {
  const [inputText, setInputText] = React.useState('');
  const [defaultOptions, setDefaultOptions] = React.useState<TElementOptionProps[]>([]);

  const refreshOptions = async (input: string): Promise<TElementOptionProps[]> => {
    const options = await asyncOptionElementResolver(input);
    setDefaultOptions(options);
    return options;
  };

  const clear = () => {
    setInputText('');
    setDefaultOptions([]);
  };

  const components: {
    Option: (props: OptionProps<TElementOptionProps>) => React.JSX.Element;
    DropdownIndicator: null;
    Placeholder?: () => React.JSX.Element;
  } = {
    Option: ElementOption,
    DropdownIndicator: null,
    Placeholder,
  };

  if (!isNewTheme()) {
    delete components.Placeholder;
  }

  return (
    <div className={Styles.searchContainer}>
      <AsyncSelect
        inputId="topnav-search-react"
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: styleOverrides?.inputWidth || '300px',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            minWidth: styleOverrides?.dropdownWidth || '450px',
            right: '0',
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            paddingBottom: '0',
          }),
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            if (runOnReturnKey) {
              setInputText('');
              runOnReturnKey(inputText);
            }
          }
        }}
        inputValue={inputText}
        onInputChange={(value, action) => {
          if (action.action === 'input-change') {
            setInputText(value);
          }
        }}
        onChange={(item) => {
          if (item && item.navFunc) {
            setInputText('');
            item.navFunc();
          }
        }}
        components={components}
        placeholder="Search"
        blurInputOnSelect
        closeMenuOnSelect
        defaultOptions={defaultOptions}
        loadOptions={refreshOptions}
        maxMenuHeight={styleOverrides?.dropdownHeight || '600px'}
      />
      {inputText && <i className={`icon fa-times ${Styles.clearSearchIcon}`} onClick={clear} />}
      {!inputText && !isNewTheme() && <i className={`icon fa-search ${Styles.searchIcon}`} onClick={clear} />}
    </div>
  );
};
