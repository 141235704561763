import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { Button } from '@sb-itops/react';
import { selectors } from 'web/redux/route/billing-support-debug';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

export const DebugRegenerateInvoicePdfButtonContainer = withReduxProvider(({ invoiceId }) => {
  const showDebug = useSelector(selectors.getShowDebug);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const onClick = async () => {
    try {
      setIsProcessingRequest(true);
      const invoice = getInvoiceById(invoiceId);
      await fetchPostP({
        path: `/billing/invoice-pdf/regenerate/:accountId/`,
        fetchOptions: {
          body: JSON.stringify({
            invoiceId,
            versionId: invoice.currentVersion.isOriginalInvoice
              ? invoice.invoicePdfVersionId
              : invoice.currentVersion.versionId,
          }),
        },
      });
      displaySuccessToUser(
        'Regenerate invoice PDF request successfully sent, this is processed through a .Net queue so may take some time to complete.',
      );
    } catch (error) {
      displayErrorToUser('Failed to send regenerate invoice PDF request.');
    } finally {
      setIsProcessingRequest(false);
    }
  };

  if (!showDebug) {
    return null;
  }

  return (
    <Button onClick={onClick} disabled={isProcessingRequest} locked={isProcessingRequest}>
      Debug: Regenerate PDF
    </Button>
  );
});

DebugRegenerateInvoicePdfButtonContainer.displayName = 'DebugRegenerateInvoicePdfButton';

DebugRegenerateInvoicePdfButtonContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

DebugRegenerateInvoicePdfButtonContainer.defaultProps = {};
