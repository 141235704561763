import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/matter-invoice-settings';

angular
  .module('@sb-billing/services')
  .service('sbMatterInvoiceSettingsService', function(
    sbGenericCacheService,
    sbGenericEndpointService,
    sbEndpointType,
  ) {
    const that = this;
    const matterInvoiceSettingsEndpoint = 'billing/matter-invoice-settings';

    sbGenericCacheService.setupCache({
      name: 'sbMatterInvoiceSettingsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: matterInvoiceSettingsEndpoint },
        poll: 60,
        subscriptions: 'notifier.InvoicingNotifications.MatterInvoiceSettingsUpdated'
      },
      updateRedux,
      clearRedux,
    });

    that.saveP = saveP;

    function saveP(data) {
      return sbGenericEndpointService.postPayloadP(matterInvoiceSettingsEndpoint, undefined, data, 'POST', {
        changeset: { ['sbMatterInvoiceSettingsService']: [data] }
      });
    }
  });
