'use strict';

const { Cent } = require('@sb-itops/money');
const { FIXED, PERCENTAGE } = require('../entities/discount-type').discountType;

/**
 * @param {object} param
 * @param {Discount} param.discount
 * @param {number} param.amountExclTax cents
 * @param {number} param.tax pre-discount tax cents
 * @returns {number} cents
 */
const calculateFeeTaxTotal = ({ discount, amountExclTax, tax }) => {
  if (!discount) {
    return new Cent(tax).cents();
  }

  const { type, fixedDiscount, percentage } = discount;

  if (type === FIXED && fixedDiscount >= 0 && amountExclTax > 0) {
    // Apply tax percentage to discounted amount rather than converting fixed discount to a percentage to avoid .4999 rounding errors
    const discountedTax = (tax / amountExclTax) * Math.max(0, amountExclTax - fixedDiscount);
    return new Cent(discountedTax).cents();
  }

  if (type === PERCENTAGE && percentage >= 0) {
    const discountedTax = (percentage / 100) * tax;
    // apply rounding on the result amount after deducting discount
    return new Cent(tax - discountedTax).cents();
  }

  return tax;
};

module.exports = {
  calculateFeeTaxTotal,
};
