const { personTitle } = require('../entities/constants');

function isAbbreviatedTitle(title) {
  const titleObj = personTitle[title.toUpperCase()];
  return titleObj && titleObj.isAbbreviated;
}

module.exports = {
  isAbbreviatedTitle,
};
