import PropTypes from 'prop-types';
import React from 'react';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { LawpaySettingsForm as LawpaySettingsForm2 } from '../lawpay-settings-form.2';
import { StripeSettingsForm } from '../stripe-settings-form';
import { EzyCollectSettingsForm } from '../ezy-collect-settings-form';
import { FeeWiseSettings } from '../fee-wise-settings';

export const PaymentProviderSettingsForm = ({ activeProvider }) => {
  const providerSettingsForm = {
    [providers.LAWPAY]: LawpaySettingsForm2,
    [providers.STRIPE]: StripeSettingsForm,
    [providers.EZY_COLLECT]: EzyCollectSettingsForm,
    [providers.FEE_WISE]: FeeWiseSettings,
  };

  // the original plan was to have a generic form encasing provider specific form fields
  // and allow this form to manage all form logic like loading and saving, didn't go down
  // this route as we felt it's overly compliciated, so instead this just serve as a
  // container to import the provider specific form that the firm has already connected with.
  const SettingsForm = providerSettingsForm[activeProvider];
  if (!SettingsForm) {
    return null;
  }

  return <SettingsForm />;
};

PaymentProviderSettingsForm.displayName = 'PaymentProviderSettingsForm';

PaymentProviderSettingsForm.propTypes = {
  activeProvider: PropTypes.string,
};

PaymentProviderSettingsForm.defaultProps = {
  activeProvider: undefined,
};

export default PaymentProviderSettingsForm;
