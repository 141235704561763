// The below is essentially a copy of the invoice-totals-opdate implementation, although they
// look very similar now, from a business logic stand point, they are likely to diverge in the
// future, please do not consolidate the logic for these without discussion with team first.
//
// DISCUSS:
// I am seeing a lot of logic in redux modules, I think we should establish some rules about
// what we can put into redux modules, e.g. redux should
// 1) handle state management and act like a local data store
// 2) any API calls and subsequent store updates (Ideally it shouldn't even do #2) and should leave
//    this up to the application layer, this allow redux modules to handle purely state management
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbMatterTotalsService',
  keyPath: 'matterId',
});

const validateTotals = ({ paid, unpaid, waived, paidByCredit }) => {
  if (!Number.isFinite(paid) || paid < 0) {
    throw new Error(`Invalid paid value: ${paid}`);
  }

  if (!Number.isFinite(paidByCredit) || paidByCredit < 0) {
    throw new Error(`Invalid paidByCredit value: ${paidByCredit}`);
  }

  if (!Number.isFinite(unpaid) || unpaid < 0) {
    throw new Error(`Invalid unpaid value: ${unpaid}`);
  }

  if (!Number.isFinite(waived) || waived < 0) {
    throw new Error(`Invalid waived value: ${waived}`);
  }
};

const adjustTotals = (matterTotals, { paid = 0, unpaid = 0, waived = 0, paidByCredit = 0 }, skipValidation = false) => {
  const adjusted = { ...matterTotals };
  adjusted.paid += paid;
  adjusted.paidByCredit = (adjusted.paidByCredit || 0) + paidByCredit;
  adjusted.unpaid += unpaid;
  adjusted.unpaidExcInterest += unpaid;
  adjusted.waived += waived || 0;

  if (!skipValidation) {
    validateTotals(adjusted);
  }

  return adjusted;
};

const adjustTotalsForPayment = (matterTotals, amount, paidByCredit) => {
  // can we overdraw the trust balance on a matter?
  const paid = amount > matterTotals.unpaid ? matterTotals.unpaid : amount;

  return adjustTotals(matterTotals, {
    paid,
    paidByCredit,
    unpaid: -paid - paidByCredit,
  });
};

const buildMatterTotalsOpdates = (matterTotals, payment) => {
  let toBePaid = payment.payors ? payment.payors.reduce((sum, { amount }) => sum + amount, 0) : payment.amount;
  let creditsToBePaid = 0;
  if (payment.source === 'Credit') {
    creditsToBePaid = toBePaid;
    toBePaid = 0;
  }
  const adjustedMatterTotals = adjustTotalsForPayment(matterTotals, toBePaid, creditsToBePaid);

  return [adjustedMatterTotals];
};

export { opdateCache, rollbackOpdateCache, buildMatterTotalsOpdates };
