import { schemeMappings } from '@sb-itops/region-schemes';

export const hasToggleForStreetAddressIsMailingAddress = (addressScheme) => {
  switch (addressScheme) {
    case schemeMappings.addressScheme.US:
      return true;
    case schemeMappings.addressScheme.AU:
    case schemeMappings.addressScheme.GB:
      return false;
    default:
      throw new Error(`Unimplemented addressScheme ${addressScheme}`);
  }
};
