'use strict';

/**
 * Filters out any transactions that aren't related to operating invoice payments.
 *
 * KEY DETAILS:
 * Payment entities are NOT uniquely identified by a paymentId. The paymentId is used to (logically) group
 * multiple payment entities into the single "payment event".
 *
 * @param {Object} params
 * @param {Array<Payment>} params.payments - Array of payments
 * @param {Array<Transaction>} params.transactions - Array of transactions
 * @returns {Array<Transaction>} The filtered transactions.
 */
function filterOperatingInvoicePayments({ transactions, payments }) {
  // Group of payments (grouped by paymentId), with any payments that aren't related to the operating account removed
  // (a trust payment is an example for example)
  const paymentGroups = payments.reduce((acc, payment) => {
    if (payment.sourceAccountId && !payment.sourceAccountId.endsWith('/Operating')) {
      return acc;
    }

    if (acc[payment.paymentId]) {
      acc[payment.paymentId].balance += payment.balance;
      acc[payment.paymentId].payments.push(payment);
    } else {
      acc[payment.paymentId] = {
        balance: payment.balance,
        paymentId: payment.paymentId,
        payments: [payment],
      };
    }

    return acc;
  }, {});

  /** Group of transactions by paymentId, with transactions that don't have a paymentId removed
   * (meaning they aren't related to payments - a deposit for example).
   */
  const transactionGroups = transactions.reduce((acc, tx) => {
    if (!tx.paymentId) {
      return acc;
    }

    if (acc[tx.paymentId]) {
      const payment =
        paymentGroups &&
        paymentGroups[tx.paymentId] &&
        paymentGroups[tx.paymentId].payments &&
        paymentGroups[tx.paymentId].payments[0];
      if (!acc[tx.paymentId].txs.find((txn) => txn.id === tx.id) || (payment && payment.reversed)) {
        acc[tx.paymentId].balance += tx.balance;
        acc[tx.paymentId].txs.push(tx);
      }
    } else {
      acc[tx.paymentId] = {
        balance: tx.balance,
        txs: [tx],
      };
    }

    return acc;
  }, {});

  // Merge the payment groups with the transaction groups, so we have a collection of payments and their transactions together in one entity
  const paymentTransactionGroups = Object.values(paymentGroups).reduce((acc, paymentGroup) => {
    acc[paymentGroup.paymentId] = {
      ...paymentGroup,
      txGroup: transactionGroups[paymentGroup.paymentId],
    };

    return acc;
  }, {});

  return paymentTransactionGroups;
}

module.exports = {
  filterOperatingInvoicePayments,
};
