'use strict';

const { computeRoleChanges } = require('./compute-role-changes');
const { getClientRoleName } = require('./get-client-role-name');
const { getOtherSideRoleName } = require('./get-otherside-role-name');
const { getRolesInItemsOrder } = require('./get-roles-in-items-order');

const getClientRoles = (roles) => roles.filter((role) => role.isClient);
const getOtherSideRoles = (roles) => roles.filter((role) => role.isOtherSide);

module.exports = {
  computeRoleChanges,
  getClientRoleName,
  getClientRoles,
  getOtherSideRoleName,
  getOtherSideRoles,
  getRolesInItemsOrder,
};
