import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactSelect, { components } from 'react-select';
import Styles from './MultiSelectDropdown.module.scss';
import { Checkbox } from '../checkbox';

const selectPortal = document.getElementById('select-portal');

const CustomOption = ({ children, ...props }) => (
  <components.Option {...props}>
    <Checkbox
      checked={!!props.selectProps.value.find((item) => item?.value === props.value)}
      className={Styles.checkbox}
      disabled={props.isDisabled}
      onChange={() => {
        const newSelectedIds = props.selectProps.value.filter((item) => item.value !== props.value);

        if (newSelectedIds.length === props.selectProps.value.length) {
          newSelectedIds.push(props.data);
        }
        props.selectProps.onChange(newSelectedIds);
      }}
    />
    {children}
    {props?.data?.disabledText && props.isDisabled && (
      <span className={Styles.disabledText}>{props.data.disabledText}</span>
    )}
  </components.Option>
);

const CustomMenuList = (props) => {
  const isGroupedOptions = !!props.selectProps.options[0]?.options;
  const flatOptions = isGroupedOptions
    ? props.selectProps.options.reduce((acc, group) => {
        group.options.forEach((option) => {
          acc.push(option);
        });
        return acc;
      }, [])
    : props.selectProps.options;
  return (
    <>
      <div
        style={props.getStyles('option', props)}
        className={classnames(Styles.hoverOption, Styles.option)}
        onClick={() => {
          if (props.selectProps.value.length === flatOptions.length) {
            props.selectProps.onChange([]);
          } else {
            props.selectProps.onChange(flatOptions.filter((o) => !o.disabled));
          }
        }}
      >
        <Checkbox
          checked={props.selectProps.value.length === flatOptions.length}
          className={Styles.checkbox}
          onChange={() => {
            if (props.selectProps.value.length === flatOptions.length) {
              props.selectProps.onChange([]);
            } else {
              props.selectProps.onChange(flatOptions.filter((o) => !o.disabled));
            }
          }}
        />
        (Select all)
      </div>
      <components.MenuList {...props} />
    </>
  );
};

const CustomMultiValueContainer = ({ data, selectProps }) => {
  if (selectProps.value && selectProps.value[selectProps.value.length - 1].value === data.value) {
    return <span>{`${data.label} `}</span>;
  }
  return <span>{`${data.label}, `}</span>;
};

const formatGroupLabel = (data) => (
  <div className={Styles.groupedLabel}>
    <span>{data.label}</span>
  </div>
);

const MultiSelectDropdown = ({
  options,
  className,
  value,
  hasError,
  autoFocus,
  showCustomMenuList,
  disabled,
  // callbacks and functions
  isOptionDisabled,
  onSelectedIdsChanged,
}) => {
  const isGroupedOptions = !!options[0]?.options;
  // construct item options map to enable faster lookup
  const optionsMap = isGroupedOptions
    ? options.reduce((acc, group) => {
        group.options.forEach((option) => {
          acc[option.value] = option;
        });
        return acc;
      }, {})
    : options.reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {});

  let menuPortalTarget;

  if (selectPortal) {
    menuPortalTarget = selectPortal;
  }

  return (
    <ReactSelect
      isMulti
      menuPortalTarget={menuPortalTarget}
      autoFocus={autoFocus}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={options}
      formatGroupLabel={formatGroupLabel}
      components={{
        Option: CustomOption,
        MultiValueContainer: CustomMultiValueContainer,
        MenuList: showCustomMenuList ? CustomMenuList : components.MenuList,
      }}
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
      classNamePrefix={classnames(className, hasError && 'has-error', 'multi-select-dropdown')}
      value={(value || []).map((selectedId) => optionsMap[selectedId]).filter((i) => i)}
      onChange={(values) => onSelectedIdsChanged(values || [])}
      styles={{
        valueContainer: (base) => ({
          ...base,
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
        }),
        menuList: (base) => ({
          ...base,
          marginTop: '0px',
        }),
        option: (base, { isDisabled }) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
          color: isDisabled ? 'hsl(0, 0%, 80%)' : 'inherit',
          ':active': {
            backgroundColor: '#DEEBFF',
          },
        }),
      }}
    />
  );
};

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

MultiSelectDropdown.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
        searchText: PropTypes.string,
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            searchText: PropTypes.string,
          }),
        ),
      }),
    ),
  ]).isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showCustomMenuList: PropTypes.bool,
  disabled: PropTypes.bool,
  // callbacks & functions
  onSelectedIdsChanged: PropTypes.func.isRequired,
  isOptionDisabled: PropTypes.func,
};

MultiSelectDropdown.defaultProps = {
  value: [],
  className: undefined,
  isOptionDisabled: undefined,
  hasError: false,
  autoFocus: false,
  showCustomMenuList: true,
  disabled: false,
};

export default MultiSelectDropdown;
