import { fetchGraphqlData } from 'web/services/network';

/**
 * @typedef {Object} FirmDetails
 * @property {string} id
 * @property {string} name
 * @property {string} creationDate
 * @property {number} currentUserCount
 */

/**
 * Fetches firm details here is intentionally by-passing the apollo frontend infrastructure
 * and is intended to help lift the initialisation of launch darkly to earlier in the bootstrap
 * process of the web app.
 * @param {Object} params 
 * @param {Object} params.log logger
 * @returns {Promise<FirmDetails|undefined>} firm details
 */
const fetchFirmDetails = async ({ log }) => {
  try {
    const firmDetailsGraphqlQuery = [{
      "operationName": "FetchFirmDetails",
      "query": `query FetchFirmDetails {
        firm {
          id
          firmName
          creationDate
        }
        firmUsersList {
          id
          users {
            id
          }
        }
      }`
    }];

    const { data: firmDetails } = await fetchGraphqlData(firmDetailsGraphqlQuery);

    return {
      ...firmDetails.firm,
      currentUserCount: firmDetails.firmUsersList
        && firmDetails.firmUsersList.users
        && firmDetails.firmUsersList.users.length || 0,
    };
  } catch (err) {
    log.error('Failed to fetch firm details:', err);
  }
};

module.exports = {
  fetchFirmDetails,
};
