import {
  getList,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/payments';
import {
  getMap as getBankAccountBalanceState,
} from '@sb-billing/redux/bank-account-balances';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getBalanceType } from '@sb-billing/redux/bank-account-settings';
import { selectors, opdates } from '@sb-billing/redux/bank-account-balances.2';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { hasFacet, facets } from '@sb-itops/region-facets';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());
const { getBankAccountBalanceById } = selectors;
const { getBankAccountBalanceOpdate, getTemplateBankAccount } = opdates;

angular.module('@sb-billing/services').service('sbPaymentService', function (sbLoggerService, sbGenericEndpointService, sbInvoiceTotalsService, sbGenericCacheService, sbBankAccountBalancesService,
 sbTransactionService, sbEndpointType, sbBankAccountService, sbInvoicingService, sbLocalisationService) {
  const that = this;
  const log = sbLoggerService.getLogger('sbPaymentService');
  const endpoint = 'billing/payment';

  sbGenericCacheService.setupCache({
    name: 'sbPaymentService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/payment' },
      poll: 60,
      subscriptions: ['notifier.InvoicingNotifications.PaymentReceivedOnInvoice', 'notifier.InvoicingNotifications.PaymentReversed']
    },
    updateRedux,
    clearRedux,
  });

  // log.setLogLevel('info');

  that.balanceType = {
    CONTACT: 0,
    MATTER: 1
  };

  that.ERROR_CODE = {
    insufficientFunds: 'insufficientFunds',
    paymentReversed: 'paymentReversed',
    transactionNotFound: 'transactionNotFound',
  };

  that.getPaymentsById = getPaymentsById;
  that.getPaymentsByFilter = getPaymentsByFilter;

  that.optimisticUpdate = optimisticUpdate;
  that.softDeletePayment = softDeletePayment;
  that.reverseInvoicePaymentP = reverseInvoicePaymentP;
  that.decodePaymentSource = decodePaymentSource;
  that.isReversiblePayment = isReversiblePayment;
  that.isOverpaid = isOverpaid;
  that.getOverpaidAmount = getOverpaidAmount;
  that.getPaidAmount = getPaidAmount;

  function _getAll() {
    return _.filter(getList(), (payment) => !payment.isDeleted);
  }

  function getPaymentsById(id) {
    return getById(id);
  }

  function softDeletePayment(id) {
    const payment = getById(id);
    if (payment) {
      payment.isDeleted = true;
    }
  }

  function getPaymentsByFilter (filter = () => true) {
    return _getAll()
      .filter(filter);
  }

  function buildChanges(payment) {
    const payload = {
      paymentId: payment.paymentId,
      destinationAccountId: getAccountId() + '/Operating',
      payorId: payment.payorId,
      matterId: payment.matterId,
      reference: payment.reference,
      userId: payment.userId,
      note: payment.note,
      timestamp: moment().toISOString(),
      effectiveDate: +moment().format('YYYYMMDD'),
      accountId: getAccountId(),
      invoices: [{
        invoiceId: payment.invoiceId,
        amount: payment.amount
      }]
    };

    if (payment.sourceAccountBalanceType !== undefined && payment.sourceAccountBalanceType !== null) {
      payload.sourceAccountId = payment.sourceAccountId || `${getAccountId()}/${_.capitalize(payment.sourceAccountType.toLowerCase())}`;
      payload.sourceAccountBalanceType = payment.sourceAccountBalanceType;
    } else {
      payload.source = payment.source;
    }

    return payload;
  }

  function opdatePaymentService(opdates, payment) {
    opdates.sbPaymentService = opdates.sbPaymentService || [];

    let opdatePayment = _.find(opdates.sbPaymentService, 'paymentId', payment.paymentId);
    if(_.isEmpty(opdatePayment)){
      opdatePayment = buildChanges(payment);
      opdates.sbPaymentService.push(opdatePayment);
    } else {
      _.merge(opdatePayment, buildChanges(payment));
    }
  }

  function opdateInvoiceTotalsService(opdates, payment) {
    opdates.sbInvoiceTotalsService = opdates.sbInvoiceTotalsService || [];
    const invoiceTotals = _.find(opdates.sbInvoiceTotalsService, 'invoiceId', payment.invoiceId) || sbInvoiceTotalsService.getTotalsForInvoiceId(payment.invoiceId);
    if(_.isEmpty(invoiceTotals)){
      throw new Error(`No invoice totals found for invoice ID: ${payment.invoiceId}`);
    }
    const adjustedInvoiceTotals = sbInvoiceTotalsService.adjustTotalsForPayment(invoiceTotals, payment.source !== 'Credit' ? payment.amount : 0, payment.source === 'Credit' ? payment.amount : 0);

    //replace the invoiceTotal instance in array with adjusted one
    const invoiceTotalIndex = _.findIndex(opdates.sbInvoiceTotalsService, 'invoiceId', payment.invoiceId);

    if (invoiceTotalIndex === -1) {
      opdates.sbInvoiceTotalsService.push(adjustedInvoiceTotals);
    } else {
      opdates.sbInvoiceTotalsService[invoiceTotalIndex] = adjustedInvoiceTotals;
    }
  }

  function opdateInvoicingService(opdates, payment) {
    const adjustedInvoiceTotals = opdates.sbInvoiceTotalsService.find(it => it.invoiceId === payment.invoiceId)
    // update invoice if paid in full
    if (!_.isEmpty(adjustedInvoiceTotals) && adjustedInvoiceTotals.unpaid === 0) {
      opdates.sbInvoicingService = opdates.sbInvoicingService || [];
      const opInvoice = _.find(opdates.sbInvoicingService, 'invoiceId', payment.invoiceId);
      const invoice = sbInvoicingService.getInvoice(payment.invoiceId);

      if(_.isEmpty(opInvoice)){
        opdates.sbInvoicingService.push({invoiceId: payment.invoiceId, currentVersion: { ...invoice, status: 'PAID'} });
      } else {
        _.set(opInvoice, 'currentVersion.status', 'PAID');
      }
    }
  }

  function opdateBankAccountBalancesService(bankAccountBalance, payment, accountId) {
    sbBankAccountBalancesService.adjustFirmOperatingBalance(opdates, payment.amount, accountId);

    if (payment.sourceAccountBalanceType === undefined || payment.sourceAccountBalanceType === null) {   // e.g. Cash
      return;
    }

    if (!isValidSourceAccountBalanceType(payment.sourceAccountBalanceType)) {
      log.warn('unknown sourceAccountBalanceType for payment: ', payment.sourceAccountBalanceType);
      throw new Error('unknown sourceAccountBalanceType for payment: ' + payment.sourceAccountBalanceType);
    }

    const { amount, payorId: contactId, matterId } = payment;
    const allowOverdraw = hasFacet(facets.allowOverdraw);

    const opdate = getBankAccountBalanceOpdate({ 
      transaction: {
        cents: -amount,
        contactId,
        matterId,
      },
      bankAccountBalance,
      balanceType: getBalanceType(),
      allowOverdraw
    });

    return opdate;
  }

  function isValidSourceAccountBalanceType(sourceAccountBalanceType) {
    return ['CONTACT', 0, '0', 'MATTER', 1, '1'].includes(sourceAccountBalanceType);
  }

  function optimisticUpdate(opdates, payment) {
    log.info(`optimisticUpdate`, opdates, payment);
    opdates = opdates || {};
    opdatePaymentService(opdates, payment);
    opdateInvoiceTotalsService(opdates, payment);
    opdateInvoicingService(opdates, payment);

    const bankAccountId = payment.sourceAccountId;
    const bankAccountBalance = 
      (opdates.sbBankAccountBalancesService && opdates.sbBankAccountBalancesService.find((acct) => acct.id === bankAccountId))
      || getBankAccountBalanceById(getBankAccountBalanceState(), { bankAccountId })
      || getTemplateBankAccount(bankAccountId);
    const bankAccountBalanceOpdate = opdateBankAccountBalancesService(bankAccountBalance, payment, getAccountId());
    opdates.sbBankAccountBalancesService = [bankAccountBalanceOpdate];
  }

  function reverseInvoicePaymentP({ paymentId, transactionId, reason, deleteTransaction = false, allowOverdraw = false }) {
    let opdates = {};
    const txToReverse = transactionId && sbTransactionService.getById(transactionId);

    if (txToReverse) {
      if(txToReverse.reversed) {
        throw new Error('Invoice payment transaction already reversed');
      }

      // Must not mutate the `reversed` value in the original txToReverse object to prevent
      // a premature opdate. This causes issues with split payments and Trust to Office
      // as we only use one payment (out of many potentially) to reverse the transfer
      opdates.sbTransactionService = [{ ...txToReverse, reversed: true }];
      // the line below is baffling
      paymentId = txToReverse.paymentId;
    }

    const paymentToReverse = paymentId && getById(paymentId);
    opdates.sbPaymentService = [{
      ...paymentToReverse,
      id: paymentId,
      reversedAt: moment().toISOString(),
      isHidden: deleteTransaction,
      allowOverdraw,
    }];

    // The opdate collated so far for payment and transaction doesn't really do much
    // or at least it's not something the user can easily notice because they are opdating
    // the existing payment and transaction record, which means if user is on a transaction
    // list UI trying to do a reversal, they won't see the reversal transaction record appear
    // until after the operation has completed and sync'd back to the frontend.
    // There's no way we can do a more complete opdate because the existing command does
    // not provide a way for us to specify the two transaction ids used to create the reversal
    // transaction entities.

    // How to do a more complete Opdate?
    // On an invoice payment reversal, three things happens for each payment record
    // 1) the payment record will be marked with a reversal date
    // 2) the old transaction records linked to each payment records are marked as
    //    reverse, one for each bank account (trust and operating)
    // 3) we create 2x reversal transaction records for the same payment, one for
    //    each of the old transaction records.
    // A complete opdate will need to take care of all three considerations above.
    // In addition, for TTO and Single Invoice Split Payors payments, all three considerations
    // apply for all payments that are part of the same group of payments, linked through
    // payment.multiPaymentId field (which is set for both TTO and Split Payors)

    if (paymentToReverse.isTrustToOffice || paymentToReverse.multiPaymentId) {
      // don't do opdates for trust to office or split payors invoice payments.
      // This is because for these type of invoice payments, they must be reversed
      // as a group, the payment id and transaction id passed above is one of many
      // payments/transactions in the group. Doing opdates like this could
      // momentarily cause unexpected data to show up on the UI. It is possible to
      // find out what all the related payments and transactions are in order to
      // perform a more comprehensive opdate, but this still won't help because of the
      // explanation above with regard with not being able to create opdate entities
      // for the reversal transactions with correct GUIDs.
      opdates = {};
    }

    // Prepare and send reversal command.
    const reversalCmd = {
      reason,
      userId: getUserId(),
      effectiveDate: +moment().format('YYYYMMDD'),
      hideTransactions: deleteTransaction,
      allowOverdraw,
    };

    return sbGenericEndpointService.postPayloadP(endpoint, `reversal/${paymentId}`, reversalCmd, 'POST', { changeset: opdates });
  }

  function decodePaymentSource(payment) {
    // Source type is already set for cash and bank transfers.
    // For other sources, we derive the account type using the source account id.
    if (!payment) {
      return '';
    }
    let decodedSource = (payment.source || getAccountSource(payment)).toLowerCase();
    if (decodedSource === 'echeck') {
      return 'eCheck';
    }
    if (decodedSource === 'trust') {
      const sourceBankAccount = getBankAccountById(payment.sourceAccountId)
      return getBankAccountName(sourceBankAccount, sbLocalisationService.t);
    }
    return _.startCase(localizeSource(decodedSource));
  }

  function localizeSource(source) {
    return (source.endsWith('check')) ?
      source.replace(/check/gi, sbLocalisationService.t('cheque')) : source;
  }

  function getAccountSource(payment) {
    const account = sbBankAccountService.get(payment.sourceAccountId);
    return (account && _.isString(account.accountType)) ?
      account.accountType : '';
  }

  // Payment is reversible if it hasn't already been reversed and if its invoice isn't waived.
  function isReversiblePayment(paymentId) {
    const payment = getPaymentsById(paymentId);
    return _.isObject(payment) && _.isEmpty(payment.reversedAt) && payment.invoices.every((invoice) => !sbInvoicingService.isInvoiceWaived(invoice.invoiceId));
  }

  function isOverpaid(paymentId) {
    const payment = getPaymentsById(paymentId);
    return payment && payment.totalAmount > payment.invoices.reduce((total, inv) => total + inv.amount, 0);
  }

  function getOverpaidAmount(paymentId) {
    const payment = getPaymentsById(paymentId);
    if (isOverpaid(paymentId)) {
      const paid = payment.invoices.reduce((total, inv) => total + inv.amount, 0);
      return payment.totalAmount - paid;
    }
  }

  function getPaidAmount(paymentId) {
    const payment = getPaymentsById(paymentId);
    return payment ? payment.invoices.reduce((total, inv) => total + inv.amount, 0) : 0;
  }

});
