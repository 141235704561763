'use strict';

const { sort } = require('@sb-itops/sort');
const { hasFacet, facets } = require('@sb-itops/region-facets');

const { consolidateChequeTransactions, createChequeDescription } = require('../transactions/services');
const { sortChequesByChequeNumber } = require('./sort-cheques-by-cheque-number');
const { getChequeNumberString } = require('./get-cheque-number-string');

function buildTrustChequesTable({
  transactions,
  trustCheques,
  vendorPayments,
  t,
  sortBy,
  sortDirection,
  getMatterDisplay,
  getContactDisplay,
  getPaymentById,
  getInvoiceNumberById,
  getBankAccountName,
}) {
  const totals = { amount: 0 };
  const consolidatedTxsByChequeId = consolidateChequeTransactions({ transactions });

  const cheques = trustCheques.reduce((validCheques, cheque) => {
    if (cheque.isPrinted && !cheque.chequeNumber) {
      return validCheques;
    }

    const consolidatedTx = consolidatedTxsByChequeId[cheque.chequeId];
    if (!consolidatedTx) {
      return validCheques;
    }

    totals.amount += consolidatedTx.amount;

    const vendorPayment = consolidatedTx.paymentIds.length === 1 && vendorPayments[consolidatedTx.paymentIds[0]];
    const payeeId = vendorPayment ? vendorPayment.payeeId : undefined;

    validCheques.push({
      chequeId: cheque.chequeId,
      effectiveDate: cheque.chequeDate,
      systemDate: cheque.lastUpdated,
      matterIds: consolidatedTx.matterIds,
      matterDisplay: consolidatedTx.matterIds.map((matterId) => `${getMatterDisplay(matterId)}`).join('\n'),
      bankAccountType: consolidatedTx.bankAccountType,
      bankAccountName: getBankAccountName ? getBankAccountName(cheque.bankAccountId) : undefined, // needed for csv/pdf export
      payeeId,
      amount: consolidatedTx.amount,
      description: createChequeDescription({
        cheque,
        consolidatedTx,
        hasReasonFieldFacet: hasFacet(facets.reasonField),
        getPaymentById,
        getInvoiceNumberById,
        t,
      }),
      isDeleted: consolidatedTx.isDeleted,
      isReversed: consolidatedTx.isReversed,
      chequeNumber: getChequeNumberString(cheque),
      chequeMemo: cheque.chequeMemo ? cheque.chequeMemo.trim() : '',
      isPrinted: cheque.isPrinted,
      payeeString: payeeId ? getContactDisplay(payeeId, { showLastNameFirst: true }) : undefined, // used only for sorting
    });

    return validCheques;
  }, []);

  let sortedCheques = [];

  if (sortBy === 'chequeNumber') {
    sortedCheques = sortChequesByChequeNumber(cheques, sortDirection);
  } else {
    sortedCheques = sort(cheques, [sortBy, 'systemDate'], [sortDirection, 'asc']);
  }

  return {
    cheques: sortedCheques,
    unprintedCheques: cheques.filter((cheque) => !cheque.isPrinted),
    totals,
    sortBy,
    sortDirection,
  };
}

module.exports = {
  buildTrustChequesTable,
};
