import { getTaxRate } from '@sb-billing/redux/gst-tax-settings';
import { costTypes, costTypeLabels } from '@sb-billing/business-logic/expense/services';

angular.module('sb.billing.webapp').component('sbActivityExpenseEntry',
  {
    'templateUrl': 'ng-components/activity-entry/activity-expense-entry.html',
    'bindings': {activity: '<', activityForm: '<', syncUpdate: '&'},
    'controller': function ($scope, sbLocalisationService) {
      'use strict';

      const ctrl = this;

      ctrl.view = {
        costTypes,
        costTypeLabels,
      };

      ctrl.t = sbLocalisationService.t;
      ctrl.onSelectTaxExempt = onSelectTaxExempt;
      ctrl.onSelecAmountIncludesTax = onSelecAmountIncludesTax;
      ctrl.onFocusNumberInput = onFocusNumberInput;
      ctrl.onSelectCostType = onSelectCostType;
      const firmTaxRate = Math.round(getTaxRate() / 100 * 10) / 10; // Round it to one decimal


      ctrl.gstChanged = () => {
        ctrl.activity.gst = ctrl.activity.hasGst === 'N' ? 0 : ctrl.activity.gst;
      };

      ctrl.$onInit = () => {
        $scope.$watch('$ctrl.activity', function(activity) {
          ctrl.syncUpdate({activity});
        });
      };

      function onSelectTaxExempt(newValue) {
        if (ctrl.activity.amountIncludesTax && newValue) {
          ctrl.activity.amountIncludesTax = !newValue;
        }

        if (newValue) {
          ctrl.activity.inputTaxRate = 0;
          ctrl.activity.outputTaxRate = 0;
        } else {
          ctrl.activity.inputTaxRate = firmTaxRate;
          ctrl.activity.outputTaxRate = firmTaxRate;
        }
      }

      function onSelecAmountIncludesTax(newValue) {
        if (ctrl.activity.taxExempt && newValue) {
          ctrl.activity.taxExempt = !newValue;
          ctrl.activity.inputTaxRate = firmTaxRate;
          ctrl.activity.outputTaxRate = firmTaxRate;
        }
      }

      function onFocusNumberInput(event) {
        event.target.select();
      }

      function onSelectCostType(expenseCostType) {
        ctrl.activity.expenseCostType = expenseCostType.id;
      }
    }
  }
);
