'use strict';

const calculateFeeBillableDuration = ({ fee }) => {
  let billableDuration;

  // isBillable === 'null' indicates some source items are non-billable.
  // Calculate billable duration for grouped fees only if there are non-billable items,
  // otherwise there's no need to as we can use the 'duration' field which already has
  // a tally of all source items. This is also a good over-arching condition to check as it
  // gracefully handles any inconsistencies between fee.isBillable and fee.sourceItems[x].billable.
  // This is possible if BB-5548 is switched on and then switched off. In such a scenario,
  // fee.isBillable takes precedence over fee.sourceItems[x].billable.
  if (fee.billableDuration || fee.billableDuration === 0) {
    billableDuration = fee.billableDuration;
  } else if (fee.isBillable === null && fee.sourceItems && fee.sourceItems.length > 0) {
    billableDuration = fee.sourceItems.reduce((acc, item) => {
      if (item.billable || (item.billable === null && fee.isBillable)) {
        return acc + item.duration;
      }
      return acc;
    }, 0);
  } else {
    billableDuration = fee.isBillable ? fee.duration : 0;
  }

  return billableDuration;
};

module.exports = {
  calculateFeeBillableDuration,
};
