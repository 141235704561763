import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import composeHooks from '@sb-itops/react-hooks-compose';

import { isModalVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';

import { useCacheQuery, useSubscribedQuery, useContactTypeaheadData, useSubscribedLazyQuery } from 'web/hooks';
import { debounce } from '@sb-itops/nodash';
import {
  CreateOperatingChequeModalExpenseTableData,
  InitOperatingChequePrintSettings,
  InitOperatingBankAccount,
  OperatingChequeAvailableNumbers,
} from 'web/graphql/queries';
import { CreateOperatingChequeModalFormsContainer } from './CreateOperatingChequeModal.forms.container';

const CREATE_OPERATING_CHEQUE_MODAL_ID = 'create-operating-cheque-modal';
const scope = CREATE_OPERATING_CHEQUE_MODAL_ID;
const FETCH_LIMIT = 100; // Magic number defined by BB-11401

const hooks = () => ({
  useSelectors: () => ({
    modalId: CREATE_OPERATING_CHEQUE_MODAL_ID,
    scope,
    onModalClose: () => {
      setModalDialogHidden({ modalId: CREATE_OPERATING_CHEQUE_MODAL_ID });
    },
    isVisible: isModalVisible({ modalId: CREATE_OPERATING_CHEQUE_MODAL_ID }),
  }),
  useContactTypeaheadData: () => {
    const {
      contactOptions: payToContactOptions,
      contactOptionsDataLoading: payToContactOptionsDataLoading,
      contactOptionsHasMore: payToContactOptionsHasMore,
      onFetchContactOptions: onFetchPayToContactOptions,
      onFetchMoreContactOptions: onFetchMorePayToContactOptions,
    } = useContactTypeaheadData();

    return {
      payToContactOptions,
      payToContactOptionsDataLoading,
      payToContactOptionsHasMore,
      onFetchPayToContactOptions,
      onFetchMorePayToContactOptions,
    };
  },
  useOperatingChequePrintData: () => {
    const { data: chequePrintData } = useCacheQuery(InitOperatingChequePrintSettings.query);
    const operatingChequePrintSettings = chequePrintData?.operatingChequePrintSettings?.[0];

    return {
      operatingChequePrintSettings,
    };
  },
  useOperatingChequeNumberData: () => {
    const [getAvailableOperatingChequeNumber, operatingChequeNumberResults] = useSubscribedLazyQuery(
      OperatingChequeAvailableNumbers,
      {
        context: { skipRequestBatching: true },
        variables: {},
      },
    );

    const data = operatingChequeNumberResults.data?.operatingChequeAvailableNumbers;

    const lastOperatingChequeNumber = data?.lastChequeNumber;
    const availableOperatingChequeNumber = data?.availableChequeNumbers.length
      ? data.availableChequeNumbers[0]
      : undefined;
    const onGetAvailableOperatingChequeNumber = debounce(
      ({ chequeNumberFrom }) => {
        getAvailableOperatingChequeNumber({
          variables: {
            filter: {
              chequeNumberFrom,
              quantity: 1,
            },
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    return {
      lastOperatingChequeNumber,
      availableOperatingChequeNumber,
      isLoadingAvailableOperatingChequeNumber: operatingChequeNumberResults.loading,
      onGetAvailableOperatingChequeNumber,
    };
  },
  useExpenseQuery: ({ expenseIds }) => {
    const expensesQueryResult = useSubscribedQuery(CreateOperatingChequeModalExpenseTableData, {
      variables: {
        ids: expenseIds,
        offset: 0,
        limit: FETCH_LIMIT,
        sort: undefined,
      },
    });

    if (expensesQueryResult.error) {
      throw new Error(expensesQueryResult.error);
    }

    const { data: expenseData } = expensesQueryResult;
    const expenseList = expenseData?.expenseList?.results || [];

    return {
      expenses: expenseList,
      expenseCount: expenseData?.expenseList?.totalCount,
      expenseDataLoading: expenseList.length === 0 && expensesQueryResult.loading,
    };
  },
  useOperatingBankAccountQuery: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);

    const operatingAccount = operatingBankAccountData?.bankAccounts?.[0];

    return {
      operatingBankAccountData: operatingAccount,
    };
  },
});

export const CreateOperatingChequeModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(CreateOperatingChequeModalFormsContainer)),
);

CreateOperatingChequeModalContainer.displayName = 'CreateOperatingChequeModalContainer';

CreateOperatingChequeModalContainer.propTypes = {
  expenseIds: PropTypes.array,
  onClearSelectedExpenses: PropTypes.func,
};

CreateOperatingChequeModalContainer.defaultProps = {};

export default CreateOperatingChequeModalContainer;
export { CREATE_OPERATING_CHEQUE_MODAL_ID };
