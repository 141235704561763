'use strict';

const { calculateInstallmentAmountDue } = require('./calculate-installment-amount-due');
const { calculatePaymentPlanPosition } = require('./calculate-payment-plan-position');
const { calculatePaymentPlanPositionStatus } = require('./calculate-payment-plan-position-status');
const {
  calculatePaymentPlanPositionPaymentMethodStatus,
} = require('./calculate-payment-plan-position-payment-method-status');
const { determinePaymentMethodExpiry } = require('./determine-payment-method-expiry');
const { displayPaymentMethodStatus } = require('./display-payment-method-status');
const { hasInstallmentDueOnDate } = require('./has-installment-due-on-date');
const { formatPaymentFrequency, installmentFrequencyOptions } = require('./format-payment-frequency');
const { formatPaymentPlanDescription } = require('./format-payment-plan-description');
const { formatPaymentPlanDescriptionGraphql } = require('./format-payment-plan-description-graphql');
const { getInstallmentFrequency } = require('./get-installment-frequency');
const { getInstallmentFrequencyTypeAndInterval } = require('./get-installment-frequency-type-and-interval');
const { getPaymentPlanStatus } = require('./get-payment-plan-status');
const { getPaymentPlanStatusByLabel } = require('./get-payment-plan-status-by-label');

// Discussed this with Rom and we've agreed to basically create a separate file for
// each business logic function e.g. [entity_name]/services/[function-name].js
//
// The reasons being
// 1) it's easy to search for such logic in IDE, this is important as we've decided to
// structure our code base for maximum composability which inherently means things are
// harder to find because they are not co-located.
// 2) this alleviates the need to sub-categorise different services until there really
// is a natural need to sub-categorise. Otherwise we could be forced to name services
// with crappy names.

module.exports = {
  calculateInstallmentAmountDue,
  calculatePaymentPlanPosition,
  calculatePaymentPlanPositionStatus,
  calculatePaymentPlanPositionPaymentMethodStatus,
  displayPaymentMethodStatus,
  determinePaymentMethodExpiry,
  hasInstallmentDueOnDate,
  formatPaymentFrequency,
  formatPaymentPlanDescription,
  formatPaymentPlanDescriptionGraphql,
  installmentFrequencyOptions,
  getInstallmentFrequency,
  getInstallmentFrequencyTypeAndInterval,
  getPaymentPlanStatus,
  getPaymentPlanStatusByLabel,
};
