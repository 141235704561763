import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Styles from './ConnectToIntegration.module.scss';

const ConnectToIntegration = ({ integration, connectToIntegration, url }) => {
  if (!url) {
    return <ConnectionButton integration={integration} connectToIntegration={connectToIntegration} />;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <ConnectionButton integration={integration} connectToIntegration={connectToIntegration} />
    </a>
  );
};

const ConnectionButton = ({ integration, connectToIntegration }) => (
  <button
    type="button"
    className={classnames('btn', 'btn-primary', Styles.integrationConnectionButton)}
    onClick={connectToIntegration}
  >
    {`Connect to ${integration}`}
  </button>
);

ConnectToIntegration.propTypes = {
  integration: PropTypes.string.isRequired,
  connectToIntegration: PropTypes.func.isRequired,
  url: PropTypes.string,
};

ConnectToIntegration.defaultProps = {
  url: undefined,
};

ConnectToIntegration.displayName = 'ConnectToIntegration';

export default ConnectToIntegration;
