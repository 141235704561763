import React from 'react';
import { getProductTier } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';
import { Lock } from 'web/components/icons';
import { useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs';
import { isBarUser } from '@sb-finance/redux/subscription';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import * as Styles from './FeatureLockPill.module.scss';

export const FeatureLockPill = withReduxProvider(() => {
  const isBillTier = useSelector(() => getProductTier() === productTierIdsByName.BILL);
  const isBar = useSelector(isBarUser);
  if (!featureActive('NUCWEB-794') || !isBillTier || !isBar) {
    return null;
  }
  return (
    <div className={Styles.featureLockPill}>
      <Lock /> This is a Boost feature
    </div>
  );
});
