import uuid from '@sb-itops/uuid';
import { dateToInteger as dateToYYYYMMDD } from '@sb-itops/date';

export const marshallValues = ({ date, reason, amount }) => ({
  id: uuid(),
  date: dateToYYYYMMDD(date),
  description: reason,
  amount,
  isReconciled: false,
  isNew: true,
});
