import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getList as getTransactions } from '@sb-billing/redux/transactions';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortDirectionFeature from '@sb-itops/redux/sort';
import { getDebtorIds as getBillingConfigDebtorIds } from '@sb-billing/business-logic/matters/billing-config';
import { getDefaultDebtorId as getMatterDefaultDebtorId } from '@sb-matter-management/business-logic/matters/services';
import { getById as getBillingConfigById } from '@sb-billing/redux/billing-configuration';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';

import * as billingMatterTransactions from 'web/redux/route/home-billing-matter-transactions';

import { getMatterDebtorIds } from 'web/business-logic/matters/get-matter-debtor-ids';
import { MatterCreditNoteLedger } from './MatterCreditNoteLedger';

const findMatterDefaultDebtorId = (matterId) => {
  const billingConfig = getBillingConfigById(matterId) || {};
  const matter = getMatterById(matterId);
  const matterDebtors = getMatterDebtorIds({
    matterDefaultDebtorId: getMatterDefaultDebtorId(matter),
    billingConfigDebtorIds: getBillingConfigDebtorIds(billingConfig),
  });
  const defaultDebtorId = matterDebtors.length ? matterDebtors[0] : undefined;
  return defaultDebtorId;
};

const findCreditTransactions = ({ matterId, showDeletedTransactions }) => {
  const creditTransactions = getTransactions().filter((transaction) => {
    const bankAccount = getBankAccountById(transaction.bankAccountId);

    const isCreditTransactionForMatter =
      transaction.matterId === matterId && bankAccount && bankAccount.accountType === 'CREDIT';

    if (showDeletedTransactions) {
      return isCreditTransactionForMatter;
    }

    return isCreditTransactionForMatter && !transaction.isHidden;
  });
  return creditTransactions;
};

const getMatterCreditNoteLedgerFeature = (state) => {
  const FEATURE_SCOPE = 'matter-credit-note-ledger';
  return withScopedFeatures({ state, scope: FEATURE_SCOPE })({
    sortDirection: sortDirectionFeature,
  });
};

const mapStateToProps = (state, { matterId, onClickLink }) => {
  const { sortDirection: firmCreditNotesSortDirection } = getMatterCreditNoteLedgerFeature(state);
  const sortBy = firmCreditNotesSortDirection.selectors.getSortBy() || undefined;
  const sortDirection = firmCreditNotesSortDirection.selectors.getSortDirection() || undefined;
  const { showHidden } = billingMatterTransactions.selectors.getFilters(state);
  const showDeletedTransactions = showHidden;

  const transactions = findCreditTransactions({ matterId, showDeletedTransactions });
  const defaultDebtorId = findMatterDefaultDebtorId(matterId);

  return {
    matterId,
    contactId: defaultDebtorId,
    transactions,
    sortDirection,
    sortBy,
    onClickLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { sortDirection: firmCreditNotesSortDirection } = getMatterCreditNoteLedgerFeature();
  return {
    onSort: ({ sortDirection, sortBy }) => {
      dispatch(firmCreditNotesSortDirection.actions.setSortDirection({ sortDirection }));
      dispatch(firmCreditNotesSortDirection.actions.setSortBy({ sortBy }));
    },
  };
};

export const MatterCreditNoteLedgerContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(MatterCreditNoteLedger),
);

MatterCreditNoteLedgerContainer.displayName = 'MatterCreditNoteLedgerContainer';

MatterCreditNoteLedgerContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

MatterCreditNoteLedgerContainer.defaultProps = {};
