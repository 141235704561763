import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getLogger } from '@sb-itops/fe-logger';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { sortByProperty } from '@sb-itops/nodash';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getFirmName, getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import {
  deactivateProvider as deactivateEzyCollectIntegration,
  disconnect as disconnectEzyCollectAccount,
  saveSettings as saveEzyCollectSettings,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getTrustAccounts, getOperatingAccount, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { EzyCollectSettingsForm } from './EzyCollectSettingsForm';

const log = getLogger('EzyCollectSettingsFormContainer');

const mapStateToProps = (state, { t }) => {
  const ezyCollectSettings = getProviderSettings(providers.EZY_COLLECT) || {};
  const accountId = getAccountId();

  const trustAccounts = getTrustAccounts().map((trustAccount) => {
    const trustAccountId = trustAccount.id;

    return {
      bankAccountId: trustAccountId,
      bankAccountType: 'Trust',
      bankAccountName: getBankAccountName(trustAccount, t),
      linkedTrustAccount: (ezyCollectSettings.linkedAccounts || {})[trustAccountId],
      isClosed: isTrustAccountClosed(trustAccount),
    };
  });

  const operatingAccountId = getOperatingAccount()?.id || `${accountId}/Operating`;
  const operatingAccount = {
    bankAccountId: operatingAccountId,
    bankAccountType: 'Operating',
    bankAccountName: 'Operating Bank Account',
    linkedOperatingAccount: (ezyCollectSettings.linkedAccounts || {})[operatingAccountId],
  };

  return {
    trustAccounts,
    operatingAccount,
    invoiceSettings: {
      showInvoiceLink: ezyCollectSettings.showInvoiceLink || false,
      showScanToPay: ezyCollectSettings.showScanToPay || false,
      clientCoversFeeOnPayments: ezyCollectSettings.clientCoversFeeOnPayments || false,
    },
  };
};

const mapDispatchToProps = () => ({
  onConnectAccount: async ({ bankAccountId, bankAccountType }) => {
    const loggedInPerson = getPersonByUserId(getUserId());

    try {
      const requestInfo = {
        connectionStep: 'CREATE_PAYMENT_APPLICATION', // A payment application is a new account on-boarding.
        bankAccountId,
        bankAccountType,
        companyName: getFirmName(),
        contactName: loggedInPerson.name,
        contactEmail: loggedInPerson.email,
      };

      const path = `/billing/payment-provider/connect/${providers.EZY_COLLECT.toLowerCase()}/:accountId/`;
      const fetchOptions = { body: JSON.stringify(requestInfo) };
      const { body } = await fetchPostP({ path, fetchOptions });
      window.open(body.onBoardingUrl);
    } catch (err) {
      log.error(`Failed to create ${providerNames.EZY_COLLECT} account`, err);
      displayErrorToUser(`Failed to create ${providerNames.EZY_COLLECT} account`);
    }
  },

  onContinueOnboarding: async ({ bankAccountId }) => {
    try {
      const requestInfo = {
        connectionStep: 'CONTINUE_PAYMENT_APPLICATION',
        bankAccountId,
      };
      const path = `/billing/payment-provider/connect/${providers.EZY_COLLECT.toLowerCase()}/:accountId/`;
      const fetchOptions = { body: JSON.stringify(requestInfo) };
      const { body } = await fetchPostP({ path, fetchOptions });
      window.open(body.onBoardingUrl);
    } catch (err) {
      log.error(`Failed to continue onboarding ${providerNames.EZY_COLLECT} account`, err);
      displayErrorToUser(`Failed to continue onboarding for ${providerNames.EZY_COLLECT} account`);
    }
  },

  onManageAccount: async ({ bankAccountId }) => {
    try {
      const requestInfo = {
        connectionStep: 'CREATE_ACCOUNT_MANAGEMENT_URL',
        smokeballBankAccountId: bankAccountId,
      };
      const path = `/billing/payment-provider/connect/${providers.EZY_COLLECT.toLowerCase()}/:accountId/`;
      const fetchOptions = { body: JSON.stringify(requestInfo) };
      const { body } = await fetchPostP({ path, fetchOptions });
      window.open(body.accountManagementUrl);
    } catch (err) {
      log.error(`Failed to open ${providerNames.EZY_COLLECT} account management URL`, err);
      displayErrorToUser(`Failed to load account management page`);
    }
  },

  onSave: async (updatedProviderSettings) => {
    const providerSettings = {
      ...(getProviderSettings(providers.EZY_COLLECT) || {}),
      ...updatedProviderSettings,
    };

    try {
      await saveEzyCollectSettings({ fetchPostP, providerType: providers.EZY_COLLECT, providerSettings });
      displaySuccessToUser(`${providerNames.EZY_COLLECT} integration settings saved successfully`);
    } catch (err) {
      log.error(`Failed to save ${providerNames.EZY_COLLECT} integration settings`, err);
      displayErrorToUser(`Failed to save ${providerNames.EZY_COLLECT} integration settings`);
    }
  },

  onDisconnectAccount: async ({ bankAccountId }) => {
    try {
      const providerSpecificPayload = {
        bankAccountId,
      };

      await disconnectEzyCollectAccount({ fetchPostP, providerType: providers.EZY_COLLECT, providerSpecificPayload });
      displaySuccessToUser(`${providerNames.EZY_COLLECT} account disconnected successfully`);
    } catch (err) {
      log.error(`Failed to disconnect ${providerNames.EZY_COLLECT} account`, err);
      displayErrorToUser(`Failed to disconnect ${providerNames.EZY_COLLECT} account`);
    }
  },

  onDeactivate: async () => {
    try {
      await deactivateEzyCollectIntegration({ fetchPostP, providerType: providers.EZY_COLLECT });
      displaySuccessToUser(`${providerNames.EZY_COLLECT} integration disconnected successfully`);
    } catch (err) {
      log.error(`Failed to deactivate ${providerNames.EZY_COLLECT} integration`, err);
      displayErrorToUser(`Failed to deactivate ${providerNames.EZY_COLLECT} integration`);
    }
  },
});

export const EzyCollectSettingsFormContainer = withReduxStore(
  withTranslation()(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(({ invoiceSettings, onSave, onDeactivate, trustAccounts, ...props }) => {
      const [showInvoiceLink, onShowInvoiceLinkChange] = useState(invoiceSettings.showInvoiceLink);
      const [showScanToPay, onShowScanToPayChange] = useState(invoiceSettings.showScanToPay);
      const [clientCoversFeeOnPayments, onClientCoversFeeOnPaymentsChange] = useState(
        invoiceSettings.clientCoversFeeOnPayments,
      );
      const [showClosedTrustAccounts, onShowClosedTrustAccountsChange] = useState(false);
      const [isSaving, setIsSaving] = useState(false);
      const [isDeactivating, setIsDeactivating] = useState(false);
      const [manageAccountUrlLoadingStatus, setManageAccountUrlLoadingStatus] = useState({});
      const [accountConnecting, setAccountConnecting] = useState('');
      const [accountDisconnecting, setAccountDisconnecting] = useState('');

      const trustAccountsProps = {
        showClosedTrustAccounts,
        onShowClosedTrustAccountsChange,
        trustAccounts: sortByProperty(
          trustAccounts.filter((ta) => showClosedTrustAccounts || !ta.isClosed),
          'bankAccountName',
          'asc',
          false,
        ),
      };

      const invoiceSettingsProps = {
        showInvoiceLink,
        onShowInvoiceLinkChange,
        showScanToPay,
        onShowScanToPayChange,
        clientCoversFeeOnPayments,
        onClientCoversFeeOnPaymentsChange,
      };

      const connectionManagementProps = {
        accountConnecting,
        onConnectAccount: async ({ bankAccountId, bankAccountType }) => {
          setAccountConnecting(bankAccountId); // Action in progress.
          await props.onConnectAccount({ bankAccountId, bankAccountType }); // Handlers must not throw.
          setAccountConnecting(''); // Action complete.
        },
        accountDisconnecting,
        onDisconnectAccount: async ({ bankAccountId }) => {
          setAccountDisconnecting(bankAccountId); // Action in progress.
          await props.onDisconnectAccount({ bankAccountId }); // Handlers must not throw.
          setAccountDisconnecting(''); // Action complete.
        },
        manageAccountUrlLoadingStatus,
        onManageAccount: async ({ bankAccountId }) => {
          setManageAccountUrlLoadingStatus({ ...manageAccountUrlLoadingStatus, [bankAccountId]: true });
          await props.onManageAccount({ bankAccountId }); // Handlers must not throw.
          setManageAccountUrlLoadingStatus({ ...manageAccountUrlLoadingStatus, [bankAccountId]: false });
        },
      };

      const createIntegrationActionHandler =
        (handlerFn, stateUpdateFn) =>
        async (...args) => {
          stateUpdateFn(true); // Action in progress.
          await handlerFn(...args); // Handlers must not throw.
          stateUpdateFn(false); // Action complete.
        };

      const saveManagementProps = {
        isSaving,
        onSave: createIntegrationActionHandler(onSave, setIsSaving),
        isDeactivating,
        onDeactivate: createIntegrationActionHandler(onDeactivate, setIsDeactivating),
      };

      return (
        <EzyCollectSettingsForm
          {...props}
          {...invoiceSettingsProps}
          {...connectionManagementProps}
          {...saveManagementProps}
          {...trustAccountsProps}
        />
      );
    }),
  ),
);

EzyCollectSettingsFormContainer.displayName = 'EzyCollectSettingsFormContainer';

EzyCollectSettingsFormContainer.propTypes = {};

EzyCollectSettingsFormContainer.defaultProps = {};
