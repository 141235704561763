/**
 * scopeFeature() accepts a Redux Feature and returns a modified Redux Feature which supports 'scoping'.
 *
 * @description
 * The main benefit of scopeFeature() is that programmers can code Redux Features's with the assumption that there
 * will only ever one copy of the Feature's state in the application, whilst still allowing applications the ability
 * to create and use multiple copies of the state.
 *
 * For example, suppose we are coding a Redux Feature to manage forms state. As we don't know whether the applications
 * that eventually make use of Forms Redux Feature will have more than one form, we need to code our Forms Redux Feature in
 * a way that would support multiple forms storing state via the Redux Feature.
 *
 * This would generally entail having all selectors and action creators receiving a "formId" parameter. The "formId" parameter
 * would then be used by the selectors to "slice" the state accordingly and for the reducer to operate on the correct slice of
 * state. In this scenario, we refer to this extra code as "scoping boilerplate" and the "formId" parameter as "scope".
 *
 * If we have a goal of coding useful and re-usable Redux Features (we do), we would need to add the same scoping boilerplate
 * code to every Redux Feature, as we will never have a way of knowing if in the future applications will demand scoping capability
 * on a particular Redux Feature. Even higher level Redux Features suffer from the same issue. Something like a FeeEditSidePanel
 * Redux Feature might, in the future, need to be used on different routes in an application, each requiring it's own unique state.
 *
 * scopeFeature() solves the conflicting problems of avoiding boilerplate and allowing reuse of Redux Features by;
 *   1. Allowing developers to code Redux Features as if there is no scoping capability, e.g. in the forms example above developers
 *      would code it as if there will only ever be one form in any application using the forms Redux Feature
 *   2. Modifying the Redux Feature passed to it so that actions and selectors accept a "scope" parameter and do with the parameter
 *      what a reasonable developer would expect.
 *
 * @param {{ [actions]: object, [selectors]: object, [operations]: object, [types]: object, reducer: function, defaultPath: string }} featureToBeScoped - A conforming Redux Feature
 * @return {{ actions: object, selectors: object, operations: object, types: object, reducer: function, defaultPath: string }} The modified Redux Feature that allows scoping.
 * @throws if featureToBeScoped is not defined
 * @throws if featureToBeScoped.reducer is not defined
 *
 * @example
 *
 * import { scopeFeature } from '@sb-itops/redux/hofs';
 * import * as yourFeature from './feature';
 *
 * const { reducer, selectors, actions, types, operations, defaultPath } = scopeFeature(sortFeature);
 *
 */
export const scopeFeature = (featureToBeScoped) => {
  if (!featureToBeScoped) {
    throw new Error('feature needs to be defined');
  }

  if (!featureToBeScoped.reducer) {
    throw new Error('feature reducer needs to be defined');
  }

  const feature = {
    selectors: {},
    actions: {},
    operations: {},
    types: {},
    ...featureToBeScoped,
  };

  const typesValues = Object.values(feature.types).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  // Add scoping to selectors.
  const selectors = Object.entries(feature.selectors).reduce((acc, [selectorName, selectorFn]) => {
    acc[selectorName] = (state, args) => {
      if (!args.scope) {
        throw new Error(`Selector argument 'scope' must be passed into a scope based selector function call`);
      }

      const { scope, ...argsWithoutScope } = args;

      // If the scope hasn't yet had state created for it, we will pass the default state from the non-scoped
      // reducer to simulate correct redux behaviour of having default state prior to selectors being called.
      const scopeState = state[scope] || feature.reducer(undefined, {});
      return selectorFn(scopeState, argsWithoutScope);
    };
    return acc;
  }, {});

  // Add scoping to action creators.
  const actions = Object.entries(feature.actions).reduce((acc, [actionName, actionFn]) => {
    acc[actionName] = (args) => {
      if (!args.scope) {
        throw new Error(`Action creator 'scope' argument must be passed in a scope based action creator function call`);
      }

      const { scope, ...argsWithoutScope } = args;

      const action = actionFn(argsWithoutScope);
      action.meta = action.meta ? { ...action.meta, scope } : { scope };
      return action;
    };

    return acc;
  }, {});

  // Add scoping to the reducer function.
  const reducer = (state = {}, action = {}) => {
    // This is a non scoped action, probably a "special" action passed by redux itself. In this case,
    // just return the current state as the underlying reducer shouldn't be acting upon non-scoped actions.
    const actionScope = action.meta && action.meta.scope;
    if (!actionScope) {
      return state;
    }

    // Only when we are dispatching an action from the feature. Otherwise will initialize the scope in every feature state.
    if (typesValues[action.type]) {
      // Apply the underlying reducer to the scoped state.
      const currentScopedState = state[actionScope];
      const newScopedState = feature.reducer(currentScopedState, action);

      // If the existing scoped state differs from the new scoped state, we create a new object reference at the top level.
      if (currentScopedState !== newScopedState) {
        return { ...state, [actionScope]: newScopedState };
      }
    }

    return state;
  };

  // Add scoping to operations.
  const operations = Object.entries(feature.operations).reduce((acc, [operationName, operationFn]) => {
    acc[operationName] = (args) => (dispatch, getState) => {
      if (!args.scope) {
        throw new Error(`operation 'scope' argument must be passed in a scope based operation function call.`);
      }

      if (!args.defaultPath) {
        throw new Error(
          `operation 'defaultPath' argument must be passed in a scope based action creator function call.`,
        );
      }

      const { scope, ...argsWithoutScope } = args;

      const operationThunk = operationFn(argsWithoutScope);

      // This is going to be the dispatch function passed to the thunk first parameter.
      // Will scope the action to dispatch to the current scope.
      const scopedDispatchFunction = (actionObjectOrFunction) => {
        // We only going to mutate with the scope when we dispatch actions not functions.
        if (typeof actionObjectOrFunction === 'object') {
          const actionObject = actionObjectOrFunction;
          const scopedAction = {
            meta: actionObject.meta ? { ...actionObject.meta, scope } : { scope },
            ...actionObject,
          };

          return dispatch(scopedAction);
        }

        // Execute the thunk within the scope.
        const actionFunction = actionObjectOrFunction;
        return dispatchableThunk(actionFunction);
      };

      // This is the get state wrapper function, is going to be sent to the thunk that will have access to the scoped state.
      const scopedGetStateFunction = () => {
        const state = getState();
        const operationState = state[args.defaultPath];

        // If the scope hasn't yet had state created for it, we will pass the default state from the non-scoped
        // reducer to simulate correct redux behaviour of having default state prior to selectors being called.
        const scopedState = operationState[scope] || feature.reducer(undefined, {});
        return scopedState;
      };

      // When we are dispatching a thunk we will executing the thunk with our scoped dispatch and getState.
      const dispatchableThunk = (f) => f(scopedDispatchFunction, scopedGetStateFunction);

      return operationThunk(scopedDispatchFunction, scopedGetStateFunction);
    };

    return acc;
  }, {});

  return {
    ...feature,
    actions,
    selectors,
    reducer,
    operations,
  };
};
