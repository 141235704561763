import { gql } from '@apollo/client';

const query = gql`
  query OperatingChequeDetailsModalData($id: ID!) {
    operatingCheque(id: $id) {
      id
      chequeDate
      expenses {
        id
        description
        expenseDate
        quantity
        price
        amountIncludesTax
        tax
        matter {
          id
          matterNumber
          clientDisplay
          otherSideDisplay
          matterType {
            matterTypeId
            name
          }
        }
      }
      payTo {
        id
        displayName
        displayNameFirstLast
        removed
        lastName
        firstName
        type
        people {
          id
          displayName
          displayNameFirstLast
          removed
          firstName
          lastName
          type
        }
      }
      createdBy {
        id
        person {
          id
          name
          initials
        }
      }
      lastUpdated
      isHidden
      reversed
      chequeMemo
      reversalReason
    }
  }
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpensePaymentDetailsUpdated',
  'AccountsNotifications.OperatingChequeUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const OperatingChequeDetailsModalData = {
  query,
  notificationIds,
};
