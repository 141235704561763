import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getLoggedInUserId, getCurrentUsers } from '@sb-firm-management/redux/firm-management';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { getById as getContactSummaryById } from '@sb-customer-management/redux/contacts-summary';
import * as forms from '@sb-itops/redux/forms2';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getById as getMatterInvoiceSettings } from '@sb-billing/redux/matter-invoice-settings';
import { getById as getEInvoiceSettings } from '@sb-billing/redux/einvoice-settings';
import { determineEInvoiceEnabled } from '@sb-billing/business-logic/einvoice';
import { appendPaymentAndEInvoicePlaceholders } from '@sb-billing/business-logic/invoice-via-communicate';
import { getAccountId } from 'web/services/user-session-management';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { isPayButtonEnabledForMatter } from '@sb-billing/business-logic/matters/invoice-settings';

import { invoiceCommunicateFormSchema } from './invoice-communicate-form-schema';
import { InvoiceCommunicateForm } from '../../../components/invoice-communicate-form';

export const defaultCommunicateMessage = `Dear [DEBTOR_NAME],

Please see attached invoice, for [MATTER_TITLE]. A total of [AMOUNT_DUE] is now due by [DATE_DUE].`;

const mapStateToProps = (state, { scope, invoiceIds, debtorIds, onPreview }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields } = formSelectors.getFormState(state);
  const { toAddress, fromUserId, message, isToAddressReadOnly, debtorType, debtorDisplayName } = formFields;
  const userOptions = getCurrentUsers().map((user) => ({
    label: user.name,
    value: user.userId,
  }));

  const mappedState = {
    isLoading: !formInitialised,
    toAddress,
    fromUserId,
    isToAddressReadOnly,
    message,
    debtorType,
    debtorDisplayName,
    userOptions,
    showPreviewButton: invoiceIds.length === 1 && debtorIds.length === 1,
    onPreview: () => {
      const [invoiceCommunicateRequest] = convertToInvoiceCommunicateRequests({
        state,
        scope,
        invoiceIds,
        debtorIds,
      });
      return onPreview(invoiceCommunicateRequest);
    },
  };

  return mappedState;
};

export const convertToInvoiceCommunicateRequests = ({ state, scope, invoiceIds, debtorIds }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);
  const formFields = formSelectors.getFieldValues(state);
  const { debtorFirstName, debtorLastName } = formatDebtorFirstAndLastName({ formFields });

  return [
    {
      invoiceIds,
      debtorId: debtorIds.length === 1 ? debtorIds[0] : undefined,
      template: {
        toAddress: formFields.toAddress,
        message: formFields.message,
        fromUserId: formFields.fromUserId,
        debtorFirstName,
        debtorLastName,
      },
    },
  ];
};

const mapDispatchToProps = (
  dispatch,
  { scope, debtorIds, matterIds, isMultiDebtorInvoice, paymentProviderEnabledForInvoicePayment },
) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(
        prepareFormData({
          debtorIds, // cannot rely on the debtorIds to determine if the invoice is multi-debtors. e.g. resending failed message will always pass in one debtorId for all invoice
          matterIds,
          formActions,
          formOperations,
          isMultiDebtorInvoice, // to determine if the form should be prepared for multi-debtors invoice
          paymentProviderEnabledForInvoicePayment,
        }),
      );
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: invoiceCommunicateFormSchema }));
    },
  };
};

const prepareFormData =
  ({
    debtorIds,
    matterIds,
    formActions,
    formOperations,
    isMultiDebtorInvoice,
    paymentProviderEnabledForInvoicePayment,
  }) =>
  async (dispatch) => {
    const { toAddress } = await buildAddressInformationP({ debtorIds });
    const { debtorType, debtorFirstName, debtorLastName, debtorDisplayName } = buildDebtorInformationP({ debtorIds });

    const accountId = getAccountId();
    const matterInvoiceSettings = getMatterInvoiceSettings(matterIds[0]);
    const eInvoiceSettings = getEInvoiceSettings(accountId);
    const eInvoiceEnabled =
      featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch)
        ? determineEInvoiceEnabled({ matterInvoiceSettings, eInvoiceSettings })
        : featureActive('BB-5725');

    const communicateMessage = appendPaymentAndEInvoicePlaceholders({
      message: defaultCommunicateMessage,
      isMultiDebtorInvoice,
      eInvoiceEnabled,
      paymentProviderEnabledForInvoicePayment,
      payButtonEnabledForMatter: isPayButtonEnabledForMatter({
        matterInvoiceSettings,
      }),
    });

    const loggedInUserId = getLoggedInUserId() || '';

    // For Organisations/Group of People without Email, we leave it as readonly, and so the user can't send it.
    const isToAddressReadOnly =
      !!toAddress || (debtorType === 'Organisation' && !toAddress) || (debtorType === 'GroupOfPeople' && !toAddress);

    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          toAddress,
          fromUserId: loggedInUserId,
          message: communicateMessage,
          isToAddressReadOnly,
          debtorType,
          debtorFirstName,
          debtorLastName,
          debtorDisplayName,
        },
      }),
    );

    dispatch(formOperations.validateSchema({ schema: invoiceCommunicateFormSchema }));
  };

const buildAddressInformationP = async ({ debtorIds }) => {
  try {
    const people = await fetchPeopleP(debtorIds[0]);
    return { toAddress: formatContactsEmails(people) };
  } catch (err) {
    return {};
  }
};

const buildDebtorInformationP = ({ debtorIds }) => {
  try {
    const people = getContactSummaryById(debtorIds[0]);
    return {
      debtorType: people.type,
      debtorFirstName: people.firstName,
      debtorLastName: people.lastName,
      debtorDisplayName: people.displayName,
    };
  } catch (err) {
    return {};
  }
};

const formatDebtorFirstAndLastName = ({ formFields }) => {
  let debtorFirstName;
  let debtorLastName;

  // When the email address is missing for a debtor, we set both name as ''
  // For Person/Staff, we use their own first/last name
  // For Organisation/GroupOfPeople, we use debtorDisplayName as first name and leave last name as blank
  if (!formFields.isToAddressReadOnly) {
    debtorFirstName = '';
    debtorLastName = '';
  } else {
    debtorFirstName =
      formFields.debtorType === 'Person' || formFields.debtorType === 'Staff'
        ? formFields.debtorFirstName
        : formFields.debtorDisplayName;
    debtorLastName =
      formFields.debtorType === 'Person' || formFields.debtorType === 'Staff' ? formFields.debtorLastName : '';
  }

  return { debtorFirstName, debtorLastName };
};

export const InvoiceCommunicateFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ onPreview, ...props }) => {
      const [isPreviewMode, setIsPreviewMode] = useState(props.showPreviewButton);
      const [previewMessage, setPreviewMessage] = useState('');

      const onPreviewToggled = async () => {
        setIsPreviewMode(!isPreviewMode);
      };

      const getPreviewInfo = async () => {
        const { message } = await onPreview();
        setPreviewMessage(message);
      };

      useEffect(
        () => {
          // Get preview info on load/when message changes instead of on toggle to enable defaulting to preview mode
          // onPreview fails when props.message is undefined (on first load before fetch)

          // Only need to fetch if the preview button is enabled
          if (props.message && props.showPreviewButton) {
            getPreviewInfo();
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.message],
      );

      return (
        <InvoiceCommunicateForm
          {...props}
          previewMessage={previewMessage}
          onPreviewToggled={onPreviewToggled}
          isPreviewMode={isPreviewMode}
        />
      );
    }),
  ),
);

InvoiceCommunicateFormContainer.displayName = 'InvoiceCommunicateFormContainer';

InvoiceCommunicateFormContainer.propTypes = {
  debtorIds: PropTypes.arrayOf(PropTypes.string),
  invoiceIds: PropTypes.arrayOf(PropTypes.string),
  matterIds: PropTypes.arrayOf(PropTypes.string),
  consolidatedMode: PropTypes.bool,
  showPreviewButton: PropTypes.bool,
  onPreview: PropTypes.func,
  isMultiDebtorInvoice: PropTypes.bool.isRequired,
  paymentProviderEnabledForInvoicePayment: PropTypes.bool.isRequired,
};

InvoiceCommunicateFormContainer.defaultProps = {
  debtorIds: [],
  invoiceIds: [],
  matterIds: [],
  consolidatedMode: false,
  showPreviewButton: false,
  onPreview: undefined,
};

export default InvoiceCommunicateFormContainer;
