import React from 'react';
import PropTypes from 'prop-types';
import { Button, CardContainer, FloatingCard, StatusPill, SlidingToggle, Icon, Spinner } from '@sb-itops/react';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import classnames from 'classnames';
import lawpayLogo from 'web/assets/integration-lawpay.png';
import smokeballPayLogo from 'web/assets/smokeball-logo.svg';
import stripeLogo from 'web/assets/integration-stripe.png';

import Styles from './PaymentProviderActivationPanel.module.scss';

const logoByProvider = {
  [providers.LAWPAY]: lawpayLogo,
  [providers.STRIPE]: stripeLogo,
  [providers.FEE_WISE]: smokeballPayLogo,
};

export const PaymentProviderActivationPanel = ({
  disabled,
  isActiveProvider,
  isActivateToggled,
  onActivateToggle,
  providerType,
  buttonLabel,
  showButton,
  onButtonClick,
  inProgress,
  activateTooltip,
}) => {
  const providerName = providerNames[providerType];

  return (
    <div className={Styles.paymentProviderActivationCardContainer}>
      <CardContainer>
        <FloatingCard applyMargin={false}>
          <div className={Styles.paymentProviderActivationCard}>
            <div className={Styles.row}>
              <img alt={`${providerName} logo`} src={logoByProvider[providerType]} />
              <StatusPill
                statusLabel={isActiveProvider ? 'ACTIVE' : 'INACTIVE'}
                className={isActiveProvider ? Styles.pillActive : Styles.pillInactive}
              />
            </div>
            <div className={Styles.row}>
              <div className={Styles.activation}>
                <SlidingToggle
                  id="paymentProviderActivationPanelToggle"
                  name="paymentProviderActivationPanelToggle"
                  scope="payment-provider-activation-panel-toggle"
                  onChange={(id, newValue) => onActivateToggle(newValue)}
                  selected={isActivateToggled}
                  disabled={disabled}
                />
                <label
                  className={classnames(Styles.labelText, disabled && Styles.disabled)}
                  htmlFor="paymentProviderActivationPanelToggle"
                >
                  Activate {providerName}
                </label>
                <Icon
                  type="information"
                  tooltip={
                    activateTooltip ||
                    'Activate your payment processor to start taking payments. You can only have one processor active at a time.'
                  }
                  className={Styles.icon}
                />
              </div>
              {showButton && (
                <Button onClick={onButtonClick} disabled={disabled}>
                  {buttonLabel} {inProgress && <Spinner small />}
                </Button>
              )}
            </div>
          </div>
        </FloatingCard>
      </CardContainer>
    </div>
  );
};

PaymentProviderActivationPanel.displayName = 'PaymentProviderActivationPanel';

PaymentProviderActivationPanel.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isActiveProvider: PropTypes.bool.isRequired,
  isActivateToggled: PropTypes.bool.isRequired,
  onActivateToggle: PropTypes.func.isRequired,
  providerType: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  showButton: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  activateTooltip: PropTypes.string,
};
PaymentProviderActivationPanel.defaultProps = {
  activateTooltip: undefined,
};
