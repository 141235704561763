angular.module('sb.billing.webapp').config(function($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/end-of-month-pdf';

  $stateProvider.state('home.billing.end-of-month-pdf', {
    url: '/end-of-month-pdf/:endOfMonthReportId/:month',
    templateUrl: BASE_DIRECTORY + '/end-of-month-pdf.html',
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
    data: {
      authorized: true,
      navHighlights: ['billing', 'accounts', 'end-of-month'],
      tab: { type: 'end-of-month-pdf' },
    },
  });
});
