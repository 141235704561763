import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import {
  providers,
  providerNames,
  feeCoverageModes,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { dateToInteger, today } from '@sb-itops/date';
import {
  calculateFeeDetails,
  extractFeeSchedule,
  getChargeErrorMessage,
  getMinChargeAmountInCents,
  getPaymentFormConfiguration,
  isErrorSmokeballPreChargeError,
} from '@sb-billing/business-logic/payment-provider/services';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { preCharge as preChargeProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { useTranslation } from '@sb-itops/react';
import { createInvoicePaymentChargeRequest } from '@sb-billing/business-logic/payment-provider/requests/create-invoice-payment-charge-request';
import uuid from '@sb-itops/uuid';

import { CreditCardPaymentModal } from './CreditCardPaymentModal';
import { creditCardPaymentFormSchema } from './credit-card-payment-form-schema';

const region = getRegion();

const log = getLogger('CreditCardPaymentModal.forms.container');

/**
 * Used to derive the balance after payment, when the user edits the "Amount" field
 *  - E.g. making partial payments
 *
 * @param {Object} params
 * @param {Object} params.formValues
 * @param {Object} params.invoice
 * @returns {number}
 */
function deriveBalanceAfterPayment({ formValues, invoice }) {
  if (!formValues || !invoice) {
    return undefined;
  }

  const unpaidAmountOnInvoice = invoice.totals.unpaid;
  const paymentAmount = formValues.paymentAmount;
  const balanceAfterPayment = unpaidAmountOnInvoice - paymentAmount;

  return balanceAfterPayment;
}

/**
 * Returns the fee schedule to be used in the calculatePaymentFeeDetails function
 *
 * This in turn will provide the relevant credit card fees for the payment
 *
 * @param {Object} params
 * @param {boolean} params.clientIsCoveringFee
 * @param {string} params.providerType // E.g. Stripe
 * @param {Object} params.formattedProviderSpecificSettings
 * @param {string} params.operatingBankAccountId
 *
 * @returns {Object} // E.g. The StripeFeeSchedule type in our schema
 */
function getFeeSchedule({
  clientIsCoveringFee,
  providerType,
  formattedProviderSpecificSettings,
  operatingBankAccountId,
}) {
  if (!clientIsCoveringFee) {
    return undefined;
  }

  const feeSchedule = extractFeeSchedule({
    providerType,
    formattedProviderSpecificSettings,
    bankAccountId: operatingBankAccountId,
  });

  return feeSchedule;
}

/**
 * Generates the credit card fee amounts
 *
 * @param {Object} params
 * @param {boolean} params.clientIsCoveringFee
 * @param {Object} params.feeSchedule
 * @param {Object} params.formValues
 * @param {Object} [params.providerSpecificChargeData] Not required by all Payment Providers (only if additional info is required for calculating the fee (e.g. credit card type))
 * @param {string} params.providerType // E.g. Stripe
 * @returns {{
 *  effectiveAmountInCents: number,
 *  effectiveFeeInCents: number
 * }}
 */
function calculatePaymentFeeDetails({
  clientIsCoveringFee,
  feeSchedule,
  formValues,
  providerSpecificChargeData,
  providerType,
}) {
  if (!clientIsCoveringFee) {
    return {};
  }

  const paymentFeeDetails = calculateFeeDetails({
    providerType,
    feeSchedule,
    desiredAmountInCents: formValues.paymentAmount || 0,
    providerSpecificFields: providerSpecificChargeData,
  });

  return paymentFeeDetails;
}

const hooks = () => ({
  useCreditCardPaymentForm: ({
    firmName,
    formattedProviderSpecificSettings,
    invoice,
    isLoading: areQueriesLoading,
    loggedInStaffMember,
    operatingBankAccountId,
    payor,
    providerType,
    sbNotifiedOperationP,
    scope,
    // Callbacks
    onCreditCardPaymentModalClose,
  }) => {
    const { t } = useTranslation();
    const accountId = getAccountId();

    const invoiceNumber = invoice?.invoiceNumber;

    /**
     * CreditCardPaymentForm
     */

    const creditCardPaymentForm = useForm({
      scope,
      schema: creditCardPaymentFormSchema,
    });

    const {
      formFields: paymentFormFields,
      formValid: isPaymentFormValid,
      formValues: paymentFormValues,
      formInitialised: isPaymentFormInitialised,
      onClearForm: onClearPaymentForm,
      onInitialiseForm: onInitialisePaymentForm,
      onUpdateFields: onUpdatePaymentFormFields,
    } = creditCardPaymentForm;

    // Resets the form when the modal is closed
    // eslint-disable-next-line arrow-body-style
    React.useEffect(() => {
      return () => {
        onClearPaymentForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CreditCardPaymentForm - Initialisation
     */

    const isReadyToInitialisePaymentForm = !areQueriesLoading && !isPaymentFormInitialised;

    if (isReadyToInitialisePaymentForm) {
      const defaultValues = getDefaultValues();

      onInitialisePaymentForm(defaultValues);
      onValidatePaymentForm(); // Highlight reason field
    }

    function getDefaultValues() {
      return {
        chargeId: uuid(),
        paymentAmount: invoice.totals.unpaid,
        paymentDate: dateToInteger(today()),
        payorId: payor.id,
        reason: '',
      };
    }

    /**
     * CreditCardPaymentForm - credit card fees
     */

    // When calculating the fee details (calculatePaymentFeeDetails), some Payment Providers (e.g. Stripe) provide info related to what has been entered in the charge form, to help determine the fee amounts
    //  * This data is provided by the PaymentProviderChargeForm pre-submission
    //  * E.g. credit card type
    //
    // Important note:
    //  * This data (providerSpecificChargeData) should not be used in constructing the charge when onPaymentProviderChargeFormSubmit fires
    //  * Tokenised data will be passed to onPaymentProviderChargeFormSubmit() for that purpose
    const [providerSpecificChargeData, setProviderSpecificChargeData] = React.useState();

    const clientIsCoveringFee = formattedProviderSpecificSettings.clientCoversFeeOnPayments;

    const feeSchedule = getFeeSchedule({
      clientIsCoveringFee,
      providerType,
      formattedProviderSpecificSettings,
      operatingBankAccountId,
    });

    const feeDetails = calculatePaymentFeeDetails({
      clientIsCoveringFee,
      feeSchedule,
      formValues: paymentFormValues,
      providerType,
      providerSpecificChargeData,
    });

    /**
     * CreditCardPaymentForm - Field updates
     */

    const contactOptions = [
      {
        id: payor?.id,
        display: payor?.displayName,
      },
    ];

    // "Amount" and "Reason" fields are user editable
    //  * Updates to the "Amount" field will update related fields (credit card fees)
    function onPaymentFormFieldChange({ key, value }) {
      const updatedField = { [key]: value };

      onUpdatePaymentFormFields(updatedField);
      onValidatePaymentForm();
    }

    /**
     * Form validation
     */

    // CreditCardPaymentForm validation
    //  * Managed completely with the Yup schema
    function onValidatePaymentForm() {
      const context = {
        unpaidAmount: invoice?.totals?.unpaid || 0,
      };

      creditCardPaymentForm.onValidateForm(context);
    }

    // PaymentProviderChargeForm validation
    //  * Managed in combination with a Yup schema and the relevant Payment Providers' validation
    const [isChargeFormReadyToSubmit, setIsChargeFormReadyToSubmit] = React.useState(false);
    const onPaymentProviderChargeFormReadyToSubmit = (isReady) => setIsChargeFormReadyToSubmit(!!isReady);

    /**
     * PaymentProviderChargeForm
     */

    // Used to help build the Payment Providers' UI
    const paymentFormConfiguration = getPaymentFormConfiguration({
      providerType,
      bankAccountId: operatingBankAccountId,
      formattedProviderSpecificSettings,
      bankAccountType: bankAccountTypeEnum.OPERATING,
    });

    // Required by FeeWise
    const onPaymentProviderChargeFormPreCharge = async (args) =>
      preChargeProvider({
        fetchPostP,
        providerType: args.providerType,
        providerSpecificFields: args.providerSpecificFields,
      });

    /**
     * Submission
     */

    // Once data is prepared from both:
    //  1. Smokeball (CreditCardPaymentForm)
    //    * E.g. invoice related data such as amounts, date, payor, etc.
    //  2. Payment Provider (PaymentProviderChargeForm)
    //    * E.g. payment token, card details, etc.
    //
    // The actual payment is ready to be processed.
    //
    // There are two key callbacks involved with processing the payment:
    //  1. onTriggerSubmission
    //    * Informs PaymentProviderChargeForm that the submission process has begun
    //    * This will initiate the generation of a payment token
    //  2. onPaymentProviderChargeFormSubmit
    //    * Processes the payment
    //      * As both Smokeball and Payment Provider data are now available

    const [triggerPaymentProviderChargeFormSubmit, setTriggerPaymentProviderChargeFormSubmit] = React.useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = React.useState(false);

    const isSubmitting = triggerPaymentProviderChargeFormSubmit || isProcessingPayment;

    const stopSubmissionLoadingStates = () => {
      setIsProcessingPayment(false);
      setTriggerPaymentProviderChargeFormSubmit(false);
    };

    // Submission callback 1
    const onTriggerSubmission = () => setTriggerPaymentProviderChargeFormSubmit(true);

    // Submission callback 2
    async function onPaymentProviderChargeFormSubmit(paymentProviderChargeFormData) {
      try {
        setIsProcessingPayment(true);

        const paymentData = {
          smokeballFormData: marshalSmokeballData(),
          providerFormData: paymentProviderChargeFormData,
        };

        const chargeRequest = createInvoicePaymentChargeRequest({
          accountId,
          requestedByUserId: getUserId(),
          paymentFormData: paymentData,
          providerType,
          staffName: loggedInStaffMember.name || 'firm staff member',
          firmName,
          matterId: invoice?.matter?.id,
          invoiceId: invoice?.id,
          feeCoverageMode: clientIsCoveringFee ? feeCoverageModes.CLIENT_PAYS : feeCoverageModes.FIRM_PAYS,
          targetBankAccountId: operatingBankAccountId,
          t,
        });

        await processCreditCardChargeWithNotification(chargeRequest);
      } catch (error) {
        log.error(error);
        if (isErrorSmokeballPreChargeError(error)) {
          const errorText = `The transaction was declined by the card issuer: ${error.message}`;
          messageDisplay.error(messageDisplay.builder().title('The credit card was not charged').text(errorText));
        } else {
          messageDisplay.error(
            `Credit card transaction for invoice #${invoiceNumber} failed prior to processing. The credit card was not charged.`,
          );
        }

        stopSubmissionLoadingStates();
      }
    }

    function processCreditCardChargeWithNotification(chargeRequest) {
      const postCreditCardChargeP = () =>
        fetchPostP({
          path: `/billing/payment-provider/charge/${providerType.toLowerCase()}/${accountId}/`,
          fetchOptions: {
            body: JSON.stringify(chargeRequest),
          },
        });

      return sbNotifiedOperationP(postCreditCardChargeP, {
        requestId: chargeRequest.id,
        completionNotification: 'PaymentProviderChargeCompleted',
        completionFilterFn: (message) => message.id === chargeRequest.id,
        timeoutMs: 45000,
      })
        .then((notification) => onPaymentCompleted({ message: notification.payload, chargeRequest }))
        .catch((error) => onPaymentError({ error, chargeRequest }));
    }

    // onPaymentCompleted is called when the PaymentProviderChargeCompleted notification related to the credit card payment is received
    //  * NB: receipt of PaymentProviderChargeCompleted does not necessarily mean that the credit card charge has been processed successfully (it may contain failures)
    function onPaymentCompleted({ message, chargeRequest }) {
      const isPaymentSuccessful = message.status !== 'FAILED';
      const amountWithSymbol = t('cents', { val: chargeRequest.descriptionAmount });

      const displaySuccessMessage = () => {
        const paymentProviderName = providerNames[chargeRequest.providerType] || 'your payment integration';

        messageDisplay.success(
          `A payment of ${amountWithSymbol} against invoice #${invoiceNumber} was successfully charged via ${paymentProviderName}.`,
        );
      };

      const displayFailureMessage = () => {
        const failureMessage = derivePaymentFailureMessage();

        messageDisplay.error(
          `A payment of ${amountWithSymbol} against invoice #${invoiceNumber} failed - ${failureMessage}`,
        );
      };

      const derivePaymentFailureMessage = () => {
        // The message for a failed payment can be from:
        //  1. Smokeball
        //  2. Payment Provider
        //  3. A standard default
        const { smokeballResponse } = message;

        if (smokeballResponse) {
          return smokeballResponse.failure.message;
        }

        const defaultMessage = 'An unexpected error occurred during transaction processing';
        const { message: paymentProviderErrorMessage } = getChargeErrorMessage({
          defaultMessage,
          chargeStatusResult: message,
          providerType: chargeRequest.providerType,
        });

        return paymentProviderErrorMessage || defaultMessage;
      };

      stopSubmissionLoadingStates();

      if (isPaymentSuccessful) {
        displaySuccessMessage();
        onCreditCardPaymentModalClose(); // Only close modal on success (keep modal open when failure occurs)
      } else {
        displayFailureMessage();
      }
    }

    // onPaymentError is called if the notified operation fails, which means one of two things:
    //  1. The PaymentProviderChargeCompleted was not received within the expected time frame
    //  2. The call to postCreditCardChargeP failed.
    function onPaymentError({ error, chargeRequest }) {
      const amountWithSymbol = t('cents', { val: chargeRequest.amountInCents });

      if (error.operationTimedOut) {
        messageDisplay.warn(
          `Credit card transaction status for payment of ${amountWithSymbol} against invoice #${invoiceNumber} could not be determined. Please check your merchant portal for reference #${chargeRequest.id}.`,
        );
        return;
      }

      messageDisplay.error(
        `Credit card transaction for payment of ${amountWithSymbol} against invoice #${invoiceNumber} failed prior to processing. The credit card was not charged.`,
      );
      log.error('Credit card payment failed', error);

      stopSubmissionLoadingStates();
    }

    // Prepare Smokeball data for submission to the Payment Provider
    function marshalSmokeballData() {
      const paymentAmount = paymentFormValues.paymentAmount || 0;
      const paymentAmountInclFee = feeDetails.effectiveAmountInCents;
      const chargeAmountInCents = clientIsCoveringFee ? paymentAmountInclFee : paymentAmount;

      const smokeballFormData = {
        ...paymentFormValues,
        paymentAmount: chargeAmountInCents,
        amountLessFees: paymentAmount,
      };

      return smokeballFormData;
    }

    /**
     * Other
     */

    const totalChargeAmount = marshalSmokeballData().paymentAmount;

    return {
      /** CreditCardPaymentForm props */
      balanceDueAfterPayment: deriveBalanceAfterPayment({ invoice, formValues: paymentFormValues }),
      clientIsCoveringFee,
      contactOptions,
      feeDetails,
      ...paymentFormFields,
      invoiceId: invoice?.id,
      invoiceNumber,
      isPaymentFormInitialised,
      isSubmitting,
      minAmountAllowed: getMinChargeAmountInCents({ providerType, region }),
      operatingBankAccountId,
      showReasonField: hasFacet(facets.reasonField),
      /** PaymentProviderChargeForm props */
      paymentFormConfiguration,
      triggerPaymentProviderChargeFormSubmit,
      onPaymentProviderChargeFormPreCharge,
      onPaymentProviderChargeFormPreSubmitChange: setProviderSpecificChargeData,
      onPaymentProviderChargeFormReadyToSubmit,
      onPaymentProviderChargeFormSubmit,
      onTriggerSubmission,
      /** CreditCardPaymentForm & PaymentProviderChargeForm shared props */
      providerType,
      /** Modal footer */
      isProcessButtonDisabled: isSubmitting || !(isPaymentFormValid && isChargeFormReadyToSubmit),
      showTotalChargeAmount:
        !areQueriesLoading && isPaymentFormInitialised && clientIsCoveringFee && totalChargeAmount > 0,
      totalChargeAmount,
      onPaymentFormFieldChange,
    };
  },
});

const dependentHooks = () => ({});

export const CreditCardPaymentModalFormsContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)(CreditCardPaymentModal),
);

CreditCardPaymentModalFormsContainer.displayName = 'CreditCardPaymentModalFormsContainer';

CreditCardPaymentModalFormsContainer.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invoiceNumber: PropTypes.number.isRequired,
    totals: PropTypes.shape({
      id: PropTypes.string.isRequired,
      unpaid: PropTypes.number.isRequired,
    }).isRequired,
  }),
  firmName: PropTypes.string.isRequired,
  formattedProviderSpecificSettings: PropTypes.object.isRequired,
  loggedInStaffMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  operatingBankAccountId: PropTypes.string.isRequired,
  providerType: PropTypes.oneOf(Object.values(providers)),
  payor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }),
  sbNotifiedOperationP: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  showCreditCardPaymentModal: PropTypes.bool.isRequired,
  // Callbacks
  onCreditCardPaymentModalClose: PropTypes.func.isRequired,
};

CreditCardPaymentModalFormsContainer.defaultProps = {
  // Initially undefined, but will resolve to a value when the data is fetched
  invoice: undefined,
  payor: undefined,
};
