'use strict';

const { paymentMethods, paymentMethodAccountSuffixes } = require('./payment-methods');

/**
 * @typedef {'billingWebApp'|'paymentPortal'} ApplicationType
 */

// Applications need to provide their own styling to the hosted fields used within the LawpayPaymentForm.
// This sucks, but unfortunately there isn't a great way around it. We deal with it here as it seems like
// the most obvious and consistent place, the down sides are that it could be difficult to find this styling
// and we have granted application knowledge to business logic.
const hostedFieldStyles = {
  billingWebApp: {
    'font-size': '14px',
    'font-family': '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    color: 'rgb(0, 0, 0)',
  },
  paymentPortal: {
    'font-size': '16px',
    'font-family': '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    color: 'rgb(85, 85, 85)',
  },
};

/**
 * Map of payment method to name of the account mapping array in the lawpay settings
 */
const paymentMethodSettingNameMapping = {
  [paymentMethods.CREDIT_CARD]: 'cardBankAccountMappings',
  [paymentMethods.ECHEQUE]: 'eCheckBankAccountMappings',
};

/**
 * getPaymentFormConfiguration
 *
 * Returns the information required to display a payment form (i.e. charge UI) for LawPay.
 *
 * @param {Object} params
 * @param {Object} params.formattedProviderSpecificSettings Current LawPay settings
 * @param {string} params.bankAccountType The bank account type being used for the payment form
 * @param {string} params.bankAccountId The smokeball bank account id being used for the payment form
 * @param {Object} [params.providerSpecificFields] Provider specific fields
 * @param {ApplicationType} [params.providerSpecificFields.application] The application requesting the configuration. This allows provider specific
 *                                                                      implementations to optionally configure by UI differently by application.
 * @returns {Object} The LawPay specific details.
 */
const getPaymentFormConfiguration = ({
  formattedProviderSpecificSettings,
  bankAccountId,
  bankAccountType,
  providerSpecificFields,
}) => {
  const { application } = providerSpecificFields || {};

  // Return payment methods currently supported for the bank account type.
  // This is done by looping through all available payment methods and checking for a correctly
  // set up lawpay account configuration for the bank account type / payment method combination.
  const enabledPaymentMethods = Object.values(paymentMethods).reduce((acc, paymentMethod) => {
    // new MTA structure
    if (formattedProviderSpecificSettings.cardBankAccountMappings) {
      const accountMapping = formattedProviderSpecificSettings[paymentMethodSettingNameMapping[paymentMethod]].find(
        (mapping) => mapping.sbbBankAccountId === bankAccountId,
      );

      if (accountMapping) {
        const account = formattedProviderSpecificSettings.lawpayBankAccounts.find(
          (bankAccount) => bankAccount.id === accountMapping?.lawpayBankAccountId,
        );
        if (!!account && account.name !== 'None') {
          acc.push(paymentMethod);
          return acc;
        }
      }
    }

    // Pre-MTA
    const accountSuffix = paymentMethodAccountSuffixes[paymentMethod];
    const account = formattedProviderSpecificSettings[`${bankAccountType.toLowerCase()}BankAccount${accountSuffix}`];
    if (!!account && account.name !== 'None') {
      acc.push(paymentMethod);
    }

    return acc;
  }, []);

  return {
    publicKey: formattedProviderSpecificSettings.publicKey,
    enabledPaymentMethods,
    hostedFieldsStyle: hostedFieldStyles[application] || hostedFieldStyles.billingWebApp,
    supportsFeeDetails: false,
  };
};

module.exports = {
  getPaymentFormConfiguration,
};
