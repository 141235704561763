import { gql } from '@apollo/client';

const query = gql`
  query AddPaymentModalBankAccounts($bankAccountFilter: BankAccountFilter) {
    #  We need all trust bank accounts so we can determine if selected matter is statutory deposit matter
    bankAccounts(filter: $bankAccountFilter) {
      id
      statutoryDepositMatterId
    }
  }
`;

const notificationIds = ['AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const AddPaymentModalBankAccounts = {
  query,
  notificationIds,
};
