'use strict';

const billingType = Object.freeze({
  FIXED_FEE: 'Fixed Fee',
  FIXED_FEE_PER_APPEARANCE: 'Fixed Fee per Appearance',
  TIME_BASED: 'Time Based',
  CONTINGENCY_$: 'Contingency ($)',
  CONTINGENCY_P: 'Contingency (%)',
  NOT_BILLABLE: 'Not Billable',
  CONDITIONAL_FEE_AGREEMENT: 'Conditional Fee Agreement',
});

module.exports = {
  billingType,
};
