/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'payments',
  keyPath: 'paymentId',
  ngCacheName: 'sbPaymentService',
  syncType: syncTypes.SINCE,
  immutable: false,
  changesetProcessor: ({ entities }) =>
    entities &&
    entities.map((payment) => ({
      ...payment,
      amount: payment.invoices && payment.invoices.reduce((total, inv) => total + inv.amount || 0, 0),
    })),
});

export const getPaymentsByInvoiceIds = (invoiceIds) =>
  getList().filter(({ invoices = [] }) => invoices.some(({ invoiceId }) => invoiceIds.includes(invoiceId)));

export const getPaymentsByInvoiceId = (invoiceId) =>
  getList().reduce((payments, payment) => {
    const { isDeleted, invoices = [] } = payment;
    if (!isDeleted && invoices.some((invoice) => invoice.invoiceId === invoiceId)) {
      // client code expects that the invoices array will only have the invoices we are filtering for
      payments.push({ ...payment, invoices: invoices.filter((invoice) => invoice.invoiceId === invoiceId) });
    }

    return payments;
  }, []);

export const getPaymentsByMultiPaymentId = (multiPaymentId) =>
  getList().filter((payment) => payment.multiPaymentId === multiPaymentId);

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export * from './payment-opdates';

export * from './receive-payment';
