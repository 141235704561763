'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const staffTargetFrequency = Object.freeze({
  WEEKLY: 0,
  MONTHLY: 1,
});

const staffTargetFrequencyLabels = Object.freeze({
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
});

const staffTargetFrequencyOptions = getTypeAsOptions({
  typeLabels: staffTargetFrequencyLabels,
  typeValues: staffTargetFrequency,
});

const defaultStaffTargetFrequency = staffTargetFrequency.WEEKLY;

module.exports = {
  defaultStaffTargetFrequency,
  staffTargetFrequency,
  staffTargetFrequencyLabels,
  staffTargetFrequencyOptions,
};
