import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

const withListeners = (events) => (WrappedComponent) =>
  class extends React.Component {
    componentDidMount() {
      const props = this.props;
      const eventUnregisters = events.map(({ eventName, listener }) => {
        let event = eventName;
        if (isFunction(eventName)) {
          // when the eventName is a callback, means it is an event name creator. It is going to be based on props.
          event = eventName(props);
        }

        return props.$scope.$on(`${event}`, async (e, ...args) => {
          const result = await listener({ ...args }, props);
          if (result) {
            if (props.stateUpdater) {
              props.stateUpdater(result);
            } else {
              // only update the state when they return something
              this.setState(result);
            }
          }
        });
      });

      // we do not have the angular hooks anymore, we need to watch for the scope destroy event ourselvs once we are outside angular.
      // so we do not have memory leaks
      const unregisterDestroy = props.$scope.$on('$destroy', function destroyScope() {
        this.unregisters.forEach((unregister) => unregister());
        unregisterDestroy();
      });

      this.unregisters = [...eventUnregisters, unregisterDestroy];
    }

    componentWillUnmount() {
      this.unregisters.forEach((unregister) => unregister());
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

withListeners.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      eventName: PropTypes.string.isRequired,
      listener: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default withListeners;
