'use strict';

const { sortByProperty } = require('@sb-itops/nodash');
const { ALL_STATES } = require('@sb-itops/region');
const { isBankAccountClosed } = require('./is-bank-account-closed');
const { getBankAccountsByLocation } = require('./get-bank-accounts-by-location');
const { shouldShowOriginalTrust } = require('./should-show-original-trust');
const { getBankAccountName } = require('./get-bank-account-name');
const { getUniqueTrustAccountsFromTransactions } = require('../../transactions/services');

/**
 * @param {Object} params
 * @param {Object} params.legacyTrustAccount
 * @param {Array.<Object>} params.trustAccounts
 * @param {string} params.locationId
 * @param {string} params.matterId
 * @param {Array.<Object>} params.matterTransactions
 * @param {function} params.getBankAccountById
 * @param {function} params.t
 * @param {boolean} params.includeClosed
 * @returns {Array.<Object>} Bank accounts valid for the matter
 */
const getAvailableTrustAccountsForMatter = ({
  legacyTrustAccount,
  trustAccounts,
  locationId,
  matterId,
  matterTransactions,
  getBankAccountById,
  t,
  includeClosed = false,
}) => {
  const isTrustAccountClosed = (ta) => isBankAccountClosed({ bankAccount: ta });

  if (!matterId) {
    return [];
  }

  const activeTrustAccounts = trustAccounts.filter((trust) => !isTrustAccountClosed(trust));

  // get all trust accounts for given location
  const trustAccountsByLocation = getBankAccountsByLocation(
    includeClosed ? trustAccounts : activeTrustAccounts,
    locationId,
  );
  // get all trust accounts which were used to transact on the matter
  const trustAccountsByTransaction = getUniqueTrustAccountsFromTransactions({
    transactions: matterTransactions,
    getBankAccountById,
  }).filter((ta) => includeClosed || !isTrustAccountClosed(ta));

  // get all trust accounts with location set to ALL_STATES
  const trustAccountsForAllStates = getBankAccountsByLocation(
    includeClosed ? trustAccounts : activeTrustAccounts,
    ALL_STATES.value,
  );

  const originalTrustAccounts = [];
  if (shouldShowOriginalTrust(activeTrustAccounts)) {
    // this is needed as legacy trust account might have been filtered out previously
    // but in this special case we want to display it
    originalTrustAccounts.push(legacyTrustAccount);
  }

  const trustAccountsByMatter = originalTrustAccounts
    .concat(trustAccountsByTransaction)
    .concat(trustAccountsByLocation)
    .concat(trustAccountsForAllStates);

  // deduplicate and add label
  const filtersTrustAccounts = Array.from(new Set(trustAccountsByMatter)).map((ta) => ({
    ...ta,
    label: getBankAccountName(ta, t),
  }));

  return sortByProperty(filtersTrustAccounts, 'label', 'asc', false);
};

module.exports = {
  getAvailableTrustAccountsForMatter,
};
