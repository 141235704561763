import { DEFAULT_FILTERS } from 'web/ng-routes/home/billing/bills/account-reminders/filter-invoice-reminders';
import { TOGGLE_SHOW_FILTERS, SET_FILTER, RESET_FILTER, TOGGLE_HIDE_FILTERS } from './types';

const INITIAL_STATE = {
  showFilters: true,
  filters: DEFAULT_FILTERS,
  hideFilters: {
    remindersRecommended: false,
    matterStatus: false,
    matterType: false,
    overdueBy: false,
    personResponsible: false,
    hideDebtor: false,
    general: false,
    sendPreferences: false,
  },
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case RESET_FILTER: {
      return {
        ...state,
        filters: { ...DEFAULT_FILTERS, reset: true },
      };
    }
    case SET_FILTER: {
      const result = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
      return result;
    }
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case TOGGLE_HIDE_FILTERS: {
      return {
        ...state,
        hideFilters: {
          ...state.hideFilters,
          [action.payload.key]: !state.hideFilters[[action.payload.key]],
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
