angular.module('sb.billing.webapp').component('sbHookModal',
  {
    transclude: true,
    bindings: { hookMode: '@?', modalClass: '@?', backdrop: '@?'},
    require: { group: '^sbComposeGroup' },
    controller: function ($uibModal, $transclude, sbLoggerService) {
      'use strict';
      const ctrl = this;
      const log = sbLoggerService.getLogger('sbHookModal');
      let modalContentClass = '';

      //log.setLogLevel('info');

      this.$onInit = () => {
        log.info('hook mode', ctrl.hookMode);

        if (ctrl.hookMode && ctrl.hookMode === 'posthook') {
          modalContentClass += 'actionposthook-modal-content ';
          ctrl.group.setActionPostHook(openModal);
        } else {
          modalContentClass += 'actionprehook-modal-content ';
          ctrl.group.setActionPreHook(openModal);
        }
      };

      this.$onDestroy = () => {
        // It is necessary to clear the pre-hook for the containing compose group because there's one instance
        // where a conditional hook-modal is used in the finalise draft invoice screen for the send email
        // functionality. The issue is this pre-hook is never cleared when user unticks send email checkbox after
        // ticking it. This causes the send email modal to pop up even though this is no longer the intention of
        // the user.
        //
        // The clearing of post-hook and pre-hook for the containing compose group must use the same conditions
        // used in $onInit. This is because other child components in the same compose group may also decide to
        // set these hooks. But we can be sure that in each compose group, each of these hook can only be set once,
        // otherwise it will result in some buggy code, thus we can be sure that if we have set these hooks on init
        // that we can also clear them on destroy.
        if (ctrl.hookMode && ctrl.hookMode === 'posthook') {
          ctrl.group.setActionPostHook(undefined);
        } else {
          ctrl.group.setActionPreHook(undefined);
        }
      };

      function openModal(actionParams) {
        function transcludeHelper() {
          let content;
          let confirmFn;
          let cancelFn;
          let transScope;

          function setCancelFn (fn) {
            cancelFn = fn;
          }

          function setConfirmFn (fn) {
            confirmFn = fn;
          }

          $transclude((clone, scope) => {
            transScope = scope;
            content = clone;
            scope.$sbModal = {
              actionParams: actionParams,
              confirm: (dataOut) => {
                log.info('confirm', dataOut);
                confirmFn(dataOut);
              },
              cancel: () => {
                log.info('cancel');
                cancelFn();
              }
            };
          });

          return {
            content,
            setConfirmFn,
            setCancelFn,
            $destroy: () => {
              if (transScope) {
                transScope.$destroy();
              }
            }
          };
        }

        return $uibModal.open({
          template: `<div class="${modalContentClass}" sb-hook-modal-transclude-content></div>`,
          bindToController: true,
          controllerAs: '$ctrl',
          windowClass: ctrl.modalClass,
          backdrop: ctrl.backdrop || 'static',
          keyboard: false,
          resolve: {
            settings: () => transcludeHelper(),
          },
          controller: function ($rootScope, $scope, $uibModalInstance, settings) {
            this.content = settings.content;
            const routeChangeSuccessHandler = $rootScope.$on('$locationChangeSuccess', function() {
              $uibModalInstance.dismiss();
            });
            settings.setCancelFn($uibModalInstance.dismiss);
            settings.setConfirmFn($uibModalInstance.close);
            $scope.$on('$destroy', () => {
              settings.$destroy();
              routeChangeSuccessHandler();
            });
          },
        }).result;
      }
    }
  }
);
