import React from 'react';
import { WarningPanel } from '@sb-itops/react';

import Styles from './NoBillingAccessWarning.module.scss';

export const NoBillingAccessWarning = () => (
  <div className={Styles.noBillingAccessWarning}>
    <WarningPanel>
      <div className={Styles.noAccessWarning}>
        <i className="icon icon-alert-2" />
        <div>You do not have access to billing functionalities.</div>
      </div>
    </WarningPanel>
  </div>
);

NoBillingAccessWarning.displayName = 'NoBillingAccessWarning';

NoBillingAccessWarning.propTypes = {};

NoBillingAccessWarning.defaultProps = {};
