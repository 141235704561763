'use strict';

const { validateTypeLabelsAndValues } = require('./validate-type-labels-and-values');

const getTypeAsOptions = ({ typeLabels, typeValues }) => {
  validateTypeLabelsAndValues({ typeLabels, typeValues });
  const keys = Object.keys(typeLabels);
  const typeAsOptions = keys.map((key) => ({ label: typeLabels[key], value: typeValues[key] }));
  return typeAsOptions;
};

module.exports = {
  getTypeAsOptions,
};
