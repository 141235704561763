import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioList } from '@sb-itops/react/radio-list';
import { bankAccount as bankAccountBl } from '@sb-billing/business-logic';

import Styles from './ApplyPaymentsRadioList.module.scss';

const { getBankAccountDisplay } = bankAccountBl.services;

// TODO this should go somewhere that is accessible to both react & angularjs
export const applyPaymentsRadioListOptions = {
  NO_PAYMENT: 'NO_PAYMENT',
  AUTOMATIC_PAYMENT: 'AUTOMATIC_PAYMENT',
  MANUAL_PAYMENT: 'MANUAL_PAYMENT',
};

// this is only needed right now becasue it is going to be used in an angular view. Once we move this outside angular this is not needed anymore as
// an standalone component.
const ApplyPaymentsRadioList = memo(({ selected, onChange, onReviewAllocationClick, preferredBankAccountId }) => {
  const stopEventPropagation = (e, eventHandlerFn = () => {}) => {
    e.stopPropagation();
    eventHandlerFn(e);
  };

  return (
    <RadioList selected={selected} onChange={onChange} name="ApplyPaymentsRadioList">
      <Radio value={applyPaymentsRadioListOptions.NO_PAYMENT}>Apply no payments right now</Radio>
      <Radio value={applyPaymentsRadioListOptions.MANUAL_PAYMENT}>
        <div className={Styles.radioContent}>
          <div>Apply payments manually</div>

          {selected === applyPaymentsRadioListOptions.MANUAL_PAYMENT && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className={Styles.link} onClick={(e) => stopEventPropagation(e, onReviewAllocationClick)}>
              Review payment allocation
            </a>
          )}
        </div>
      </Radio>
      <Radio value={applyPaymentsRadioListOptions.AUTOMATIC_PAYMENT}>
        <div className={Styles.radioContent}>
          <div>Apply payments automatically</div>
          {selected === applyPaymentsRadioListOptions.AUTOMATIC_PAYMENT && (
            <div className={Styles.autoPaymentSubtext} onClick={stopEventPropagation}>
              Allocated from available {getBankAccountDisplay(preferredBankAccountId)} funds first, lowest contact
              balance first.
            </div>
          )}
        </div>
      </Radio>
    </RadioList>
  );
});

ApplyPaymentsRadioList.displayName = 'ApplyPaymentsRadioList';

ApplyPaymentsRadioList.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onReviewAllocationClick: PropTypes.func.isRequired,
  preferredBankAccountId: PropTypes.string.isRequired,
};

ApplyPaymentsRadioList.defaultProps = {
  selected: undefined,
};

export default ApplyPaymentsRadioList;
