import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FeeWisePayoutsRoute } from './payouts';
import { FeeWiseTransactionsRoute } from './transactions';

const FeeWisePaymentsRoute = memo((props) => {
  const { categoryOptionMap, selectedCategory, onClickLink, fwPanelFilterScope } = props;
  // Filters
  const { dateRangeType, fromDate, toDate, onDateListChange } = props;
  const { payoutSelectedStatusIds, payoutOnSelectStatus, payoutStatusOptions } = props;
  const { transactionSelectedStatusIds, transactionOnSelectStatus, transactionStatusOptions } = props;
  const { linkedAccountSelected, linkedAccountOnSelect, linkedAccountLoading, linkedAccountOptions } = props;

  switch (selectedCategory) {
    case categoryOptionMap.transactions.key:
      return (
        <FeeWiseTransactionsRoute
          onClickLink={onClickLink}
          panelFilterScope={fwPanelFilterScope}
          // date filter
          dateRangeType={dateRangeType}
          fromDate={fromDate}
          toDate={toDate}
          onDateListChange={onDateListChange}
          // status filter
          selectedStatusIds={transactionSelectedStatusIds}
          onSelectStatus={transactionOnSelectStatus}
          statusOptions={transactionStatusOptions}
          // account filter
          linkedAccountSelected={linkedAccountSelected}
          linkedAccountOnSelect={linkedAccountOnSelect}
          linkedAccountLoading={linkedAccountLoading}
          linkedAccountOptions={linkedAccountOptions}
        />
      );
    case categoryOptionMap.payouts.key:
      return (
        <FeeWisePayoutsRoute
          onClickLink={onClickLink}
          panelFilterScope={fwPanelFilterScope}
          // date filter
          dateRangeType={dateRangeType}
          fromDate={fromDate}
          toDate={toDate}
          onDateListChange={onDateListChange}
          // status filter
          selectedStatusIds={payoutSelectedStatusIds}
          onSelectStatus={payoutOnSelectStatus}
          statusOptions={payoutStatusOptions}
          // account filter
          linkedAccountSelected={linkedAccountSelected}
          linkedAccountOnSelect={linkedAccountOnSelect}
          linkedAccountLoading={linkedAccountLoading}
          linkedAccountOptions={linkedAccountOptions}
        />
      );

    default:
      return null;
  }
});

FeeWisePaymentsRoute.displayName = 'FeeWisePaymentsRoute';

FeeWisePaymentsRoute.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  categoryOptionMap: PropTypes.object.isRequired,
  onClickLink: PropTypes.func.isRequired,
  fwPanelFilterScope: PropTypes.string.isRequired,

  dateRangeType: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  onDateListChange: PropTypes.func.isRequired,

  payoutSelectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  payoutOnSelectStatus: PropTypes.func.isRequired,
  payoutStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  transactionSelectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  transactionOnSelectStatus: PropTypes.func.isRequired,
  transactionStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  linkedAccountSelected: PropTypes.string.isRequired,
  linkedAccountOnSelect: PropTypes.func.isRequired,
  linkedAccountLoading: PropTypes.bool.isRequired,
  linkedAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default FeeWisePaymentsRoute;
