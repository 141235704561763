'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const billingFrequencyAnnuallySubType = Object.freeze({
  ANNUALLY_JAN: 1,
  ANNUALLY_FEB: 2,
  ANNUALLY_MAR: 3,
  ANNUALLY_APR: 4,
  ANNUALLY_MAY: 5,
  ANNUALLY_JUN: 6,
  ANNUALLY_JUL: 7,
  ANNUALLY_AUG: 8,
  ANNUALLY_SEP: 9,
  ANNUALLY_OCT: 10,
  ANNUALLY_NOV: 11,
  ANNUALLY_DEC: 12,
});

const billingFrequencyAnnuallySubTypeLabels = Object.freeze({
  ANNUALLY_JAN: 'January',
  ANNUALLY_FEB: 'February',
  ANNUALLY_MAR: 'March',
  ANNUALLY_APR: 'April',
  ANNUALLY_MAY: 'May',
  ANNUALLY_JUN: 'June',
  ANNUALLY_JUL: 'July',
  ANNUALLY_AUG: 'August',
  ANNUALLY_SEP: 'September',
  ANNUALLY_OCT: 'October',
  ANNUALLY_NOV: 'November',
  ANNUALLY_DEC: 'December',
});

const billingFrequencyAnnuallySubTypeOptions = getTypeAsOptions({
  typeLabels: billingFrequencyAnnuallySubTypeLabels,
  typeValues: billingFrequencyAnnuallySubType,
});

module.exports = {
  billingFrequencyAnnuallySubType,
  billingFrequencyAnnuallySubTypeLabels,
  billingFrequencyAnnuallySubTypeOptions,
};
