'use strict';

const defaultEmailBody = () => `
<p>Dear [DEBTOR_NAME],</p>
<p><br></p>
<p>Please see attached statement. If you have any questions, please contact our office.</p>
<p><br></p>
<p>[PAY_NOW_BUTTON]</p>
<p><br></p>
<p>Thanks,</p>
<p>[USER_NAME]</p>
<p>[FIRM_NAME]</p>
<p>[PHONE_NUMBER]</p>`;

const invoiceStatementEmailTemplateDefaults = () => ({
  emailSubject: 'Your New Statement - [DEBTOR_NAME]',
  emailBody: defaultEmailBody(),
  sendCopyToUser: true,
});

module.exports = {
  invoiceStatementEmailTemplateDefaults,
};
