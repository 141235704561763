import { SET_MODAL_DIALOG_VISIBLE, SET_MODAL_DIALOG_HIDDEN } from './types';

const initState = {
  modalDialogs: {},
};

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_MODAL_DIALOG_VISIBLE: {
      return {
        ...state,
        modalDialogs: {
          ...state.modalDialogs,
          [action.modalId]: {
            status: modalStatuses.visible,
            props: action.props,
          },
        },
      };
    }

    case SET_MODAL_DIALOG_HIDDEN: {
      return {
        ...state,
        modalDialogs: {
          ...state.modalDialogs,
          [action.modalId]: undefined,
        },
      };
    }

    default: {
      return state;
    }
  }
};

// export status so everyone is aware
export const modalStatuses = {
  visible: 'visible',
};

// reducer for this state tree
export default reducer;
