import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getRoleOptions } from '@sb-matter-listing/redux/matter-type-configurations';
import { getMatterDebtorIdsForAddressOverride } from '@sb-billing/business-logic/invoice-settings';
import { getDebtorDefaultNameAndAddress } from '@sb-customer-management/redux/contacts';

import MatterContacts from './MatterContacts';

const getUpdatedDebtorAddressesWhenContactOrDebtorChanges = async ({
  clientCustomerIds,
  debtorIds,
  currentDebtorAddresses,
}) => {
  const newDebtorIdsForAddressOverride = getMatterDebtorIdsForAddressOverride({ debtorIds, clientCustomerIds });
  // If the debtor detail is already present just return it, otherwise fetch the new debtor
  const updatedDebtorAddresses = Promise.all(
    newDebtorIdsForAddressOverride.map(
      async (id) =>
        currentDebtorAddresses.find((debtor) => debtor.debtorId === id) ||
        getDebtorDefaultNameAndAddress({
          id,
        }),
    ),
  );

  return updatedDebtorAddresses;
};

const mapStateToProps = (
  state,
  { formInitialised, formFields, formFieldValues, formDisabled, formSubmitting, submitFailed },
) => {
  const { originalMatterTypeId, clientRole, clientCustomerIds, debtorIds, otherSideRole, otherSideCustomerIds } =
    formFieldValues;

  const { clientCustomerIdsIsRequired } = formFields;

  const rolesMap = originalMatterTypeId ? getRoleOptions(originalMatterTypeId, true) : {};
  const clientRoleLabel = rolesMap[clientRole];
  const otherSideRoleLabel = rolesMap[otherSideRole];

  return {
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    submitFailed,
    // form fields values
    clientCustomerIds,
    debtorIds,
    otherSideRole,
    otherSideCustomerIds,
    // form fields
    clientCustomerIdsIsRequired,
    // other
    clientRoleLabel,
    otherSideRoleLabel,
  };
};

const mapDispatchToProps = (dispatch, { formFieldValues, onFieldValueSet, onFieldValuesUpdated }) => ({
  onClientsChanged: async (newSelectedContactIds) => {
    onFieldValueSet({
      field: 'clientCustomerIds',
      value: newSelectedContactIds,
    });
    const { debtorIds, overriddenDebtorAddresses, selectedDebtorId } = formFieldValues;
    const filteredDebtorIds = debtorIds.filter((debtorId) => debtorId);
    // Only update overriddenDebtorAddresses when there is no debtor (we use first client as debtor)
    if (filteredDebtorIds.length === 0) {
      const updatedDebtorAddresses = await getUpdatedDebtorAddressesWhenContactOrDebtorChanges({
        clientCustomerIds: newSelectedContactIds,
        debtorIds,
        currentDebtorAddresses: overriddenDebtorAddresses,
      });
      onFieldValueSet({
        field: 'overriddenDebtorAddresses',
        value: updatedDebtorAddresses,
      });
      // We also need to update selectedDebtorId if the selected debtor is not exist anymore
      const isDebtorIdExists = updatedDebtorAddresses.some((debtor) => debtor.debtorId === selectedDebtorId);
      if (!isDebtorIdExists) {
        onFieldValuesUpdated({
          selectedDebtorId: updatedDebtorAddresses.length > 0 ? updatedDebtorAddresses[0].debtorId : '',
        });
      }
    }
  },
  onDebtorsChanged: async (newSelectedContactIds) => {
    onFieldValueSet({
      field: 'debtorIds',
      value: newSelectedContactIds,
    });

    // When debtor changes, we update debtorAddress
    const { clientCustomerIds, overriddenDebtorAddresses, selectedDebtorId } = formFieldValues;
    const updatedDebtorAddresses = await getUpdatedDebtorAddressesWhenContactOrDebtorChanges({
      clientCustomerIds,
      debtorIds: newSelectedContactIds,
      currentDebtorAddresses: overriddenDebtorAddresses,
    });
    onFieldValueSet({
      field: 'overriddenDebtorAddresses',
      value: updatedDebtorAddresses,
    });
    // We also need to update selectedDebtorId if the selected debtor is not exist anymore
    const isDebtorIdExists = updatedDebtorAddresses.some((debtor) => debtor.debtorId === selectedDebtorId);
    if (!isDebtorIdExists) {
      onFieldValuesUpdated({
        selectedDebtorId: updatedDebtorAddresses.length > 0 ? updatedDebtorAddresses[0].debtorId : '',
      });
    }
  },
  onOtherSidesChanged: (newSelectedContactIds) => {
    // onFieldValuesUpdated doesn't work in all scenarios, this is a redux/form2 api
    // bug related to dealing with arrays, I don't have time to chase down now
    onFieldValueSet({
      field: 'otherSideCustomerIds',
      value: newSelectedContactIds,
    });
  },
});

export const MatterContactsContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(MatterContacts));

MatterContactsContainer.displayName = 'MatterContactsContainer';

MatterContactsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onFieldValueSet: PropTypes.func.isRequired, // generic method for set a field
  onClickLink: PropTypes.func.isRequired,
};

MatterContactsContainer.defaultProps = {
  matterId: undefined,
};
