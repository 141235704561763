'use strict';

/**
 * Conditionally add placeholder of pay now button or/and eInvoice link at the end pf communicate message
 * @param {Object} params
 * @param {String} params.message origin message
 * @param {Boolean} params.isMultiDebtorInvoice
 * @param {Boolean} params.eInvoiceEnabled
 * @param {Boolean} params.paymentProviderEnabledForInvoicePayment
 * @param {Boolean} params.payButtonEnabledForMatter
 * @returns {String} message after adding placeholder conditionally
 */
function appendPaymentAndEInvoicePlaceholders({
  message,
  isMultiDebtorInvoice,
  eInvoiceEnabled,
  paymentProviderEnabledForInvoicePayment = false,
  payButtonEnabledForMatter,
}) {
  // Pay now button only available for single debtor
  if (
    paymentProviderEnabledForInvoicePayment &&
    payButtonEnabledForMatter &&
    eInvoiceEnabled &&
    !isMultiDebtorInvoice
  ) {
    return `${message}\n\n[PAY_NOW_BUTTON]   |   [VIEW_DETAILS_BUTTON]`;
  }

  if (paymentProviderEnabledForInvoicePayment && payButtonEnabledForMatter && !isMultiDebtorInvoice) {
    return `${message}\n\n[PAY_NOW_BUTTON]`;
  }

  if (eInvoiceEnabled) {
    return `${message}\n\n[VIEW_DETAILS_BUTTON]`;
  }

  return message;
}

module.exports = {
  appendPaymentAndEInvoicePlaceholders,
};
