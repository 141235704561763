'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/pre-authentication';

  $stateProvider
    .state('pre-authentication', {
      templateUrl: BASE_DIRECTORY + '/pre-authentication.html',
      controller: 'sbPreAuthenticationController',
      controllerAs: '$ctrl'
  });
});
