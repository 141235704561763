'use strict';

const { sort } = require('@sb-itops/sort');
const { entryTypeMap } = require('../../shared/entities');

/**
 * mapTasksByCategory
 *
 * Combines custom utbms task code and utbms task code into a single array
 *
 * During this process, tasks can be:
 *  1. Labelled (e.g. customUtbmsCode or utbmsCode)
 *  2. Filtered by passed in types
 *  3. Sorted
 *  4. Transformed
 *
 * @param {Object} params
 * @param {Array<Object>} params.utbmsCustomTaskCodes
 * @param {Array<Object>} params.utbmsTaskCodes
 * @param {Array<String>} params.filterCustomCodeByTypes
 *
 * @returns {Array<Object>}
 */
function mapTasksByCategory({ utbmsCustomTaskCodes = [], utbmsTaskCodes = [], filterCustomCodeByTypes = [] }) {
  // Custom tasks
  const customTasks = utbmsCustomTaskCodes.length
    ? utbmsCustomTaskCodes.reduce((acc, task) => {
        const isIncludedInTypes = filterCustomCodeByTypes.includes(entryTypeMap[task.entryType]);

        if (isIncludedInTypes) {
          acc.push({
            ...task,
            category: 'utbmsCustomTaskCodes',
          });
        }
        return acc;
      }, [])
    : [];

  const sortedCustomTasks = sort(customTasks, ['code'], ['ASC']);

  // UTBMS tasks
  const utbmsTasks = utbmsTaskCodes.length
    ? utbmsTaskCodes.map((task) => ({
        ...task,
        category: 'utbmsTaskCodes',
      }))
    : [];

  const sortedUtbmsTasks = sort(utbmsTasks, ['code'], ['ASC']);

  return [...sortedUtbmsTasks, ...sortedCustomTasks]; // This order matters to keep standard UTBMS tasks at the top
}

module.exports = {
  mapTasksByCategory,
};
