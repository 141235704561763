import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery } from 'web/hooks';
import { WidgetClientInfo } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { Contact } from 'types';
import { ClientInfoWidget } from './ClientInfoWidget';

interface TClientInfoWidgetContainer {
  isEditing: boolean;
  matterId: string;
  onChange: () => void;
  onRemove: () => void;
  settings: any;
  onClickLink: (input: { type: string; id: string }) => void;
}

const hooks = ({ matterId }: TClientInfoWidgetContainer) => ({
  useGraphQLforClientInfo: () => {
    const { data, loading, error } = useSubscribedQuery(WidgetClientInfo, {
      variables: {
        id: matterId,
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
        },
      },
    });

    if (error) {
      throw error;
    }
    // Filter out duplicates
    const partiesHash: {
      [key: string]: Contact & { isDebtor?: boolean; isOtherside?: boolean; cell: string };
    } = {};
    (data?.matter?.clients || []).forEach((c) => {
      partiesHash[c.id] = c;
    });
    (data?.matter?.billingConfiguration?.debtors || []).forEach((d) => {
      partiesHash[d.id] = { ...d };
      partiesHash[d.id].isDebtor = true;
    });
    (data?.matter?.otherSide || []).forEach((o) => {
      partiesHash[o.id] = { ...o };
      partiesHash[o.id].isOtherside = true;
    });
    return {
      parties: Object.values(partiesHash),
      loading,
    };
  },
});

export const ClientInfoWidgetContainer = withApolloClient(composeHooks(hooks)(ClientInfoWidget));
