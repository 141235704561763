import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { sortByProperty, capitalize } from '@sb-itops/nodash';
import ReportConfigSelector from './ReportConfigSelector';

const categoryTitleLookup = {
  internal: 'Standard Reports',
  external: 'Custom Reports',
};

const mapStateToProps = (state, { selectedId, reportConfigs, onSelectionChange }) => {
  // Categorise the report configs as internal, external, or as a custom category
  // that is set via a tag `category:<custom name>` in Reports Admin tool.
  // Different categories are displayed in their own sections of the config selector.
  const categorisedReportConfigs = reportConfigs.reduce((acc, reportConfig) => {
    if (reportConfig.featureSwitch && !featureActive(reportConfig.featureSwitch)) {
      return acc;
    }

    acc[reportConfig.category] = acc[reportConfig.category] || [];
    acc[reportConfig.category].push(reportConfig);
    return acc;
  }, {});

  // Now that the reports are categorised, we need to convert the report categories object into a shape accepted by the component.
  // The component expects report categories to be an array in the form: [{ title: 'Category 1', reportConfigs: [] }, ...]
  const reportCategorySections = Object.entries(categorisedReportConfigs).reduce(
    (acc, [categoryName, configs]) => {
      const reportConfigCategory = {
        name: categoryName,
        title: categoryTitleLookup[categoryName] || `${capitalize(categoryName) || 'Custom'} Reports`,
        reportConfigs: sortByProperty(configs, 'name'),
      };

      switch (categoryName) {
        case 'internal':
        case 'external':
          acc[categoryName] = reportConfigCategory;
          break;

        default:
          acc.tagged.push(reportConfigCategory);
          break;
      }

      return acc;
    },
    {
      internal: undefined,
      external: undefined,
      tagged: [],
    },
  );

  // Sort tagged categories alphabetically
  if (reportCategorySections.tagged.length > 1) {
    reportCategorySections.tagged = sortByProperty(reportCategorySections.tagged, 'title');
  }

  // The internal category of reports should always be displayed first, so we ensure it's at the start of the array.
  // The external category should always appear last.
  const reportConfigCategories = [
    reportCategorySections.internal,
    ...reportCategorySections.tagged,
    reportCategorySections.external,
  ].filter(Boolean);

  // Special case - if there is only one category present, the title of the category gets overridden to 'Choose a Report'.
  if (reportConfigCategories.length === 1) {
    reportConfigCategories[0].title = 'Choose a Report';
  }

  return {
    reportConfigCategories,
    selectedId,
    onSelectionChange,
  };
};

const mapDispatchToProps = (state, { selectedId, onSelectionChange }) => ({
  onLoad: () => {
    onSelectionChange({ id: selectedId });
  },
});

export const ReportConfigSelectorContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(ReportConfigSelector)),
);

ReportConfigSelectorContainer.displayName = 'ReportConfigSelectorContainer';

ReportConfigSelectorContainer.propTypes = {
  selectedId: PropTypes.string,
  reportConfigs: PropTypes.arrayOf(PropTypes.object),
  onSelectionChange: PropTypes.func,
  title: PropTypes.string,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
};

ReportConfigSelectorContainer.defaultProps = {
  selectedId: undefined,
  reportConfigs: [],
  onSelectionChange: () => {},
  title: 'Choose a Report',
};

export default ReportConfigSelectorContainer;
