'use strict';

const { entities } = require('../../shared');

const { entryType } = entities;

function getFeeDaySummaries(fees) {
  const groupedFees = groupBy(fees, 'feeDate');
  return Object.keys(groupedFees).map((feeDate) => ({
    formattedDateString: `${feeDate}`,
    totalCents: accumulateTotalCents(groupedFees[feeDate]),
    totalMinutes: accumulateTotalMinutes(groupedFees[feeDate]),
  }));
}

function groupBy(fees, property) {
  return fees.reduce((groups, fee) => {
    if (groups[fee[property]]) {
      groups[fee[property]].push(fee);
    } else {
      // eslint-disable-next-line no-param-reassign
      groups[fee[property]] = [fee];
    }

    return groups;
  }, {});
}

function accumulateTotalCents(fees) {
  return fees.reduce((sum, fee) => {
    if (fee.feeType === entryType.FIXED && fee.isBillable) {
      return sum + fee.rate;
    }
    if (fee.feeType === entryType.TIME) {
      if (fee.isBillable) {
        // helps rounding 1 minute durations up to the 2 decimal points to match the individual fee entry
        // Could not feature switch here as accessing featureActive throws a window undefined error.
        return sum + (fee.rate * fee.duration) / 60; // TODO: decimal-operation
      }

      // Apollo based fee entities provide billable duration which factors in partially billable.
      if (fee.billableDuration || fee.billableDuration === 0) {
        return sum + (fee.rate * fee.billableDuration) / 60;
      }

      // fee.isBillable is 'null' signifies some source items are billable, and some are not.
      // only billable items are counted. NB: billable items that are waived are also counted
      if (fee.isBillable === null && fee.sourceItems && fee.sourceItems.length > 0) {
        const billableDuration = fee.sourceItems.reduce((acc, item) => {
          if (item.billable) {
            return acc + item.duration;
          }
          return acc;
        }, 0);
        return sum + (fee.rate * billableDuration) / 60;
      }
    }
    return sum;
  }, 0);
}

function accumulateTotalMinutes(fees) {
  const total = fees.reduce((sum, fee) => {
    if (fee.feeType === entryType.TIME) {
      // helps rounding 1 minute durations up to the 2 decimal points to match the individual fee entry
      return sum + ((fee.duration * 100) / 60).toFixed(0) * 60;
    }
    return sum;
  }, 0);

  return total / 100;
}

module.exports = {
  getFeeDaySummaries,
};
