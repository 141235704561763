import { gql } from '@apollo/client';

const query = gql`
  query FinalisedInvoiceEntries($id: ID!) {
    invoice(id: $id) {
      id
      feeTaxRate
      invoiceNumber
      entries {
        expenseEntity {
          id
          amountIncludesTax
          description
          expenseDate
          isBillable
          price
          quantity
          tax
          outputTax
          waived
        }
        feeEntity {
          id
          amountIncludesTax
          billableDuration
          billableTax
          description
          duration
          feeDate
          feeEarnerStaff {
            id
            name
          }
          feeType
          invoice {
            id
            feeTaxRate
          }
          isBillable
          isTaxExempt
          rate
          sourceItems {
            billable
            duration
          }
          tax
          waived
        }
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceUpdated', 'WebQueryInvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FinalisedInvoiceEntries = {
  query,
  notificationIds,
};
