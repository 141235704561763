import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as forms from '@sb-itops/redux/forms2';
import { useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useTranslation } from '@sb-itops/react';

import TabReconDateRange from './TabReconDateRange';

const formatDate = (date, t) => t('date', { yyyymmdd: date });

const hooks = ({ trustAccountId }) => ({
  useGetActiveBankRecon: () => {
    const { t } = useTranslation();
    const formScope = `billing-create-bank-reconciliation-form-${trustAccountId}`;

    const { selectors: formSelectors } = useScopedFeature(forms, formScope);
    const formState = useSelector(formSelectors.getFormState);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { startDate, endDate } = formState?.fields;

    const [display, setDisplay] = useState('Setup');

    useEffect(
      () => {
        if (startDate?.value && endDate?.value) {
          const dateString = `${formatDate(startDate?.value, t)} - ${formatDate(endDate?.value, t)}`;
          setDisplay(dateString);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [startDate?.value, endDate?.value],
    );

    return {
      display,
    };
  },
});

export const TabReconDateRangeContainer = withReduxProvider(composeHooks(hooks)(TabReconDateRange));

TabReconDateRangeContainer.propTypes = {
  trustAccountId: PropTypes.string,
};

TabReconDateRangeContainer.defaultProps = {
  trustAccountId: undefined,
};

TabReconDateRangeContainer.displayName = 'TabReconDateRangeContainer';
