import PropTypes from 'prop-types';

import { withErrorHandler, withDisableHandler } from '@sb-itops/react/hoc';
import MatterTypeahead from './MatterTypeahead.wrapper';

const MatterTypeaheadBridge = withDisableHandler({ renderInsideOwnContainer: false })(
  withErrorHandler((value) => !value)(MatterTypeahead),
);

MatterTypeaheadBridge.displayName = 'MatterTypeaheadBridge';

MatterTypeaheadBridge.propTypes = {
  initialId: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hasDisableFlag: PropTypes.bool,
  hasErrorFlag: PropTypes.bool,
  onDisableUpdated: PropTypes.func,
  focusKey: PropTypes.string,
  filter: PropTypes.string,
  onBlur: PropTypes.func,
  onPostBlur: PropTypes.func,
  onValueChange: PropTypes.func,
  onClear: PropTypes.func,
  empty: PropTypes.bool,
};

MatterTypeaheadBridge.defaultProps = {
  initialId: undefined,
  placeholder: undefined,
  name: undefined,
  hasDisableFlag: false,
  hasErrorFlag: false,
  onDisableUpdated: undefined,
  focusKey: undefined,
  filter: undefined,
  onBlur: undefined,
  onPostBlur: undefined,
  onValueChange: undefined,
  onClear: undefined,
  empty: undefined,
};

export default MatterTypeaheadBridge;
