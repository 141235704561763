const { requestFeatureActive } = require('./request-feature-active');
const { hasFacet, facets } = require('@sb-itops/region-facets');
const {
  getAppEnv,
  envType,
} = require('@sb-itops/app-env');

const logger = require('@sb-itops/fe-logger');
const log = logger.getLogger('@sb-itops/feature');
log.setLogLevel('warn');

const level = Object.freeze({
  OFF: 'OFF',
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  RC: 'RC',
  PRODUCTION: 'PRODUCTION'
});

const environmentToLevel = Object.freeze({
  [envType.LOCAL]: level.LOCAL,
  [envType.DEV]: level.DEV,
  [envType.RC]: level.RC,
  [envType.QA]: level.PRODUCTION,
  [envType.PRODUCTION]: level.PRODUCTION,
  [envType.LIVE]: level.PRODUCTION, // LIVE is set as APP_ENV for lambdas
});

const features = {
  // HIDDEN-IN-QA should only be used to hide things that are permanently not being shown in production environments (QA/Live)
  'HIDDEN-IN-QA': { featureLevel: level.RC, description: 'Hide the feature in production environments' },
  'WI-2086': { featureLevel: level.DEV, description: 'Watcher performance tracking' },
  'BB-162': { featureLevel: level.OFF, description: 'Allow saving DRAFT invoices without an issued date & due date' },
  'BB-417': { featureLevel: level.DEV, description: 'Billing utilities in firm settings' },
  'BB-1761': { featureLevel: hasFacet(facets.originalInvoice) ? level.PRODUCTION : level.OFF, description: 'Original Invoices' }, // BB-1761 is mostly used in US, but it is known to be used in AU for some firms as well. e.g. https://smokeball.slack.com/archives/C01HHHU1EDU/p1675145512016159
  'BB-1986': { featureLevel: level.PRODUCTION, description: 'Retroactive interest' },
  'BB-2412': { featureLevel: level.PRODUCTION, description: 'Matter balances' },
  'BB-2530': { featureLevel: level.PRODUCTION, description: 'Matter balances report - exclude externally' }, // OFF until "BilledExcExternal" is added to the "MatterTotals" table
  'BB-2738': { featureLevel: level.PRODUCTION, description: 'Optional timekeeper initials in Account Statement report.' },
  'BB-3331': { featureLevel: level.PRODUCTION, description: 'Operating cheques' },
  'BB-3398': { featureLevel: level.PRODUCTION, description: 'Add Organisation' },
  'BB-4416': { featureLevel: level.PRODUCTION, description: 'Split Billing' },
  'BB-4470': { featureLevel: level.RC, description: 'Include lead matters filter in time and expense report' },
  'BB-4655': { featureLevel: level.PRODUCTION, description: 'React date list filter' },
  'BB-4706': { featureLevel: level.PRODUCTION, description: 'custom notes in matter and invoice settings.' },
  'BB-4763': { featureLevel: level.PRODUCTION, description: 'React matter type accordian' },
  'BB-4832': { featureLevel: level.PRODUCTION, description: 'Additional Time Billing Increment Option (one minute increment, 1 minute increment)' },
  'BB-4877': { featureLevel: level.PRODUCTION, description: 'Income Allocation report in AU' },
  'BB-5109': { featureLevel: level.PRODUCTION, description: 'Payment Plan Auto Charge' },
  'BB-5211': { featureLevel: level.PRODUCTION, description: 'Bank reconciliation totals' },
  'BB-5345': { featureLevel: level.PRODUCTION, description: 'Time view refinements' },
  'BB-5508': { featureLevel: level.PRODUCTION, description: 'Add reason field to the invoice payments.' },
  'BB-5509': { featureLevel: level.PRODUCTION, description: 'Add support for Operating Account Detail' },
  'BB-5511': { featureLevel: level.PRODUCTION, description: 'Support for the direct debit payment type' },
  'BB-5578': { featureLevel: level.OFF, description: 'Load on Demand - Matters + Contact page' },
  'BB-5604': { featureLevel: level.OFF, description: 'Make Reports Alphabetical to align with rest of billing' },
  'BB-5725': { featureLevel: level.RC, description: 'eInvoice' },
  'BB-5948': { featureLevel: level.PRODUCTION, description: 'Add bank details to Direct debit payments' },
  'BB-6222': { featureLevel: level.OFF, description: 'Enable Xero' }, // enabled via LD
  'BB-6381': { featureLevel: level.PRODUCTION, description: 'Controlled Monies Accounts' },
  'BB-6398': { featureLevel: level.PRODUCTION, description: 'Display Less Funds in Trust in Invoice Summary' },
  'BB-6412': { featureLevel: level.PRODUCTION, description: 'Trust Cheques CSV/PDF Export' },
  'BB-6491': { featureLevel: level.PRODUCTION, description: 'Payment provider e-cheques/e-checks support' },
  'BB-6595': { featureLevel: level.PRODUCTION, description: 'Billable Lead Matters' },
  'BB-6682': { featureLevel: level.OFF, description: 'Split unbilled amounts in matter balances report' }, // BB-6682 is only enabled on a case by case basis for customers that require it.
  'BB-6801': { featureLevel: level.RC, description: 'Matter billing estimate in financial summary (fee estimate)' },
  'BB-6865': { featureLevel: level.OFF, description: 'Description on demand in eInvoices' },
  'BB-6908': { featureLevel: level.PRODUCTION, description: 'Evergreen Retainer Request on Invoice' },
  'BB-6946': { featureLevel: level.PRODUCTION, description: 'Ability to print draft reconciliation' },
  'BB-7088': { featureLevel: level.PRODUCTION, description: 'Zero-balance invoice modal message' },
  'BB-7210': { featureLevel: level.LOCAL, description: 'DEV ONLY convenience feature. Defaults issue date on draft invoices to a valid date rather than today' },
  'BB-7270': { featureLevel: level.PRODUCTION, description: 'Surcharge' },
  'BB-7526': { featureLevel: level.PRODUCTION, description: 'Bulk sync large file support via streaming' },
  'BB-7599': { featureLevel: level.RC, description: 'Add Address to Operating Checks' },
  'BB-7624': { featureLevel: level.PRODUCTION, description: 'Allow user to set Billing Increments by matter in Web' },
  'BB-7634': { featureLevel: level.PRODUCTION, description: 'Transfer funds receipt' },
  'BB-7676': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Delete Matter' },
  'BB-7677': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Create, edit, delete contacts' },
  'BB-7844': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Firm Details page' },
  'BB-7845': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Staff Settings page' },
  'BB-7838': { featureLevel: level.OFF, description: 'Include QA/Test Matter Type Categories. Requires the NUCWEB-490 regex to include the test categories if it is turned on' },
  'BB-7911': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - View & Restore Deleted Matters' },
  'BB-7912': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - View & Restore Deleted Contacts' },
  'BB-7929': { featureLevel: level.OFF, description: 'New notifier version' },
  'BB-7980': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Trial progress' },
  'BB-8047': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Recent matters and invoices' },
  'BB-8062': { featureLevel: level.RC, description: 'Billing Standalone - Welcome modal' },
  'BB-8260': { featureLevel: level.OFF, description: 'Billing Standalone - Dynamic LD links for customise invoice tutorial' }, // JSON feature flag: these are controlled exclusively via launch darkly
  'BB-8498': { featureLevel: level.OFF, description: 'Trust Statement Transaction Summary' },
  'BB-8525': { featureLevel: level.PRODUCTION, description: 'Billing Standalone - Show Status Pill in Search Dropdown Results' },
  'BB-8561': { featureLevel: level.RC, description: 'QuickBooks trust transactions integration' },
  'BB-8671': { featureLevel: level.PRODUCTION, description: 'Protected Trust Funds' },
  'BB-8689': { featureLevel: level.PRODUCTION, description: 'Cache integrity' },
  'BB-8724': { featureLevel: level.PRODUCTION, description: 'Save activity processing in new command processor' },
  'BB-8857': { featureLevel: level.PRODUCTION, description: 'Fees Bulk Action' },
  'BB-8892': { featureLevel: level.OFF, description: 'Billing Standalone - Dynamic LD links for welcome modal tutorial' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-8904': { featureLevel: level.PRODUCTION, description: 'Trust statement report show opening balance' },
  'BB-9084': { featureLevel: level.OFF, description: 'Option to Hide Due Date on Invoices' },
  'BB-9097': { featureLevel: level.PRODUCTION, description: 'Invoice via Communicate' },
  'BB-9111': { featureLevel: level.PRODUCTION, description: 'Send Invoice information to Xero' },
  'BB-9195': { featureLevel: level.PRODUCTION, description: 'Add Option for LEDES Timekeeper Classification Codes' },
  'BB-9228': { featureLevel: level.PRODUCTION, description: 'Create Invoices - Entry type filter' },
  'BB-9243': { featureLevel: level.PRODUCTION, description: 'Bulk LEDES download' },
  'BB-9310': { featureLevel: level.PRODUCTION, description: 'Billing Widget Data' },
  'BB-9322': { featureLevel: level.PRODUCTION, description: 'eInvoice - Display paid, waived, and zero dollars due invoices' }, // BB-5725 (eInvoice) is a required dependency
  'BB-9475': { featureLevel: level.OFF, description: 'LD links for Xero setting invoice integration help link' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-9573': { featureLevel: level.OFF, description: 'Anticipated Disbursements' },
  'BB-9596': { featureLevel: level.PRODUCTION, description: 'Multiple Trust Accounts, lawpay acct type filter' },
  'BB-9654': { featureLevel: level.OFF, description: 'Enable higher resolution logo through Launch Darkly' }, // JSON feature flag: these are controlled exclusively via launch darkly'
  'BB-9674': { featureLevel: level.OFF, description: 'Load on Demand - Contact details page' },
  'BB-10092': { featureLevel: level.RC, description: 'Option to Append Receipts to Invoices' },
  'BB-10108': { featureLevel: level.RC, description: 'EzyCollect Payment Provider' },
  'BB-10195': { featureLevel: level.PRODUCTION, description: 'Allow custom times for billing units in web app' },
  'BB-10293': { featureLevel: level.RC, description: 'MYOB invoice and payments integration' },
  'BB-10306': { featureLevel: level.OFF, description: 'Local HMR (fast reload)' },
  'BB-10347': { featureLevel: level.PRODUCTION, description: 'Add Print and Export feature to Expenses pages' },
  'BB-10356': { featureLevel: level.RC, description: 'Allow users to hide GST columns from Invoices' },
  'BB-10409': { featureLevel: level.PRODUCTION, description: 'Add static signature image to PDF invoices' },
  'BB-10530': { featureLevel: level.PRODUCTION, description: 'Add Reason field to CC Deposit Request' },
  'BB-10531': { featureLevel: level.RC, description: 'Return payment link for debtor matter balance lambda' },
  'BB-10500': { featureLevel: level.RC, description: 'matter balances report' },
  'BB-10615': { featureLevel: level.OFF, description: 'LD links for MYOB setting invoice integration help link' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-10835': { featureLevel: level.RC, description: 'Rate Sets' },
  'BB-10855': { featureLevel: level.PRODUCTION, description: 'Add full name and role to Professional Fee Summary on Invoices' },
  'BB-10919': { featureLevel: level.PRODUCTION, description: 'Support large filesize invoices' },
  'BB-11038': { featureLevel: level.PRODUCTION, description: 'Proactively refresh auth token' },
  'BB-11099': { featureLevel: level.OFF, description: 'LD links for Rate Set help link' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-11100': { featureLevel: level.LOCAL, description: 'UI Refresh' },
  'BB-11288': { featureLevel: level.PRODUCTION, description: 'Online invoicing authentication' },
  'BB-11313': { featureLevel: level.PRODUCTION, description: 'Tier selection in payment update'},
  'BB-11329': { featureLevel: level.OFF, description: 'LD links for Send Via Communicate help link' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-11448': { featureLevel: level.PRODUCTION, description: 'Client Communication' },
  'BB-11596': { featureLevel: level.OFF, description: 'Google Tag Manager for Web App' }, // LOCAL won't initialise GTM even if the feature switch is ON
  'BB-11703': { featureLevel: level.OFF, description: 'Allow direct credit card payments for boost tier'},
  'BB-11704': { featureLevel: level.PRODUCTION, description: 'Billing Frequency' },
  'BB-11888': { featureLevel: level.OFF, description: 'LEDES - Export notes as description' },
  'BB-11975': { featureLevel: level.RC, description: 'Permissions MVP'},
  'BB-12031': { featureLevel: level.OFF, description: 'Tier selection in subscription completion'},
  'BB-12038': { featureLevel: level.RC, description: 'FeeWise Payment Provider' },
  'BB-12097': { featureLevel: level.PRODUCTION, description: 'Disable Deposits and Deposit Requests for Contacts with no Addresses AU/UK' },
  'BB-12106': { featureLevel: level.OFF, description: 'Dynamic links for subscription/update payment method screens'}, // JSON feature flag: this must be turned on through launch darkly
  'BB-12182': { featureLevel: level.PRODUCTION, description: 'EOM Report Improvements for Compliance'},
  'BB-12218': { featureLevel: level.RC, description: 'Pricing model selection when activating subscriptions'},
  'BB-12308': { featureLevel: level.PRODUCTION, description: 'Invoice Previews should include same totals as finalised invoices'},
  'BB-12383': { featureLevel: level.LOCAL, description: 'Use Weasyprint for Invoice PDF generation'},
  'BB-12386': { featureLevel: level.PRODUCTION, description: 'Allow placeholders in the Custom Text option for Invoice title and subtitle' },
  'BB-12385': { featureLevel: level.PRODUCTION, description: 'Allow users to select which invoice supplementary tables have a page break'},
  'BB-12393': { featureLevel: level.PRODUCTION, description: 'Allow users to override invoice address on matter' },
  'BB-12394': { featureLevel: level.PRODUCTION, description: 'Add option to display line numbers on invoice' },
  'BB-12792': { featureLevel: level.PRODUCTION, description: 'Firm payment details' },
  'BB-12830': { featureLevel: level.LOCAL, description: 'Invoice logo generator powered by AI' },
  'BB-12890': { featureLevel: level.RC, description: 'Add Brainfish Support AI to the Help Icon in SB' },
  'BB-12895': { featureLevel: level.PRODUCTION, description: 'Invoice PDF Preview with no timeout'},
  'BB-12741': { featureLevel: level.OFF, description: 'Stripe v2 - UK + new UI for AU' }, // Should be manually/LD enabled
  'BB-12757': { featureLevel: level.OFF, description: 'LD links for Stripe help link' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-12822': { featureLevel: level.PRODUCTION, description: 'Support Tab - Data Explorer' },
  'BB-12838': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Email Modal (Finalised invoice page)' },
  'BB-12951': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Email Modal (Matter page > Invoices tab)' },
  'BB-12977': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Email Modal (Contact page > Invoices tab)' },
  'BB-12983': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Email Modal (Invoices page)' },
  'BB-12984': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Email Modal (Advanced search page > Invoices tab)' },
  'BB-12987': { featureLevel: level.PRODUCTION, description: 'Input / Output Tax' },
  'BB-12990': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Email Modal (Draft Invoice Page (legacy))' },
  'BB-13005': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Communicate Modal' }, // A single feature switch for all areas using the modal
  'BB-13152': { featureLevel: level.OFF, description: 'FeeWise Refund Request through Email' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-13182': { featureLevel: level.PRODUCTION, description: '2FA column in Staff & Users table and Account Security in My Preferences' },
  'BB-13182-LINK': { featureLevel: level.OFF, description: '2FA setings in My Preferences' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-13236': { featureLevel: level.OFF, description: 'Force 2FA enabled for Xero users & Xero 2FA notice on connection' },
  'BB-13218': { featureLevel: level.RC, description: 'BarBill Subscriptions' },
  'BB-13237': { featureLevel: level.OFF, description: 'Xero banner - Enable 2FA messages' }, // JSON feature flag: this must be turned on through launch darkly
  'BB-13244': { featureLevel: level.OFF, description: 'Churn Zero for Web App' },
  'BB-13254': { featureLevel: level.PRODUCTION, description: 'Vendor Payment Entry Split Payors React modal' },
  'BB-13299': { featureLevel: level.DEV, description: 'Support for Stripe fee schedules with valid from date' },
  'BB-13329': { featureLevel: level.OFF, description: 'UI Refresh Mk2' },
  'BB-13250': { featureLevel: level.LOCAL, description: 'Allow users to manage subscriptions within web' },
  'BB-13352': { featureLevel: level.PRODUCTION, description: 'Hard and Soft Costs' },
  'BB-13460': { featureLevel: level.OFF, description: 'Dynamic product tier information for subscription management page' },
  'BB-13500': { featureLevel: level.PRODUCTION, description: 'Use Command Manager for commands sending email' },
  'BB-13563': { featureLevel: level.PRODUCTION, description: 'Time Worked' },
  'BB-13588': { featureLevel: level.OFF, description: 'Matter tab order for productivity (boost+)' },
  'BB-13599': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Firm invoice list' },
  'BB-13753': { featureLevel: level.LOCAL, description: 'Dashboard Daily digest widget (Boost+)' },
  'BB-13792': { featureLevel: level.PRODUCTION, description: 'TTO modal - Show warning for overdrawn matters' },
  'BB-13823': { featureLevel: level.OFF, description: 'Onboarding wizard (replaces welcome modal)' },
  'BB-13823-DEBUG': { featureLevel: level.OFF, description: 'Onboarding wizard debug (always shows)' },
  'BB-13936': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Add Payment Modal' },
  'BB-13941': { featureLevel: level.PRODUCTION, description: 'Web App Permissions' },
  'BB-13951': { featureLevel: level.OFF, description: 'Hide reports and global account data from non-firm owners' },
  'BB-13988': { featureLevel: level.RC, description: 'Optimise handling of images when generating invoice PDF' },
  'BB-14011': { featureLevel: level.PRODUCTION, description: 'Client Statements' },
  'BB-14037': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Bulk Trust to Office' },
  'BB-14057': { featureLevel: level.OFF, description: 'Server Side Debug Mode' },
  'BB-14064': { featureLevel: level.PRODUCTION, description: 'Online payments top level settings menu item' },
  'BB-14072': { featureLevel: level.PRODUCTION, description: 'Matter List default sort by MatterNumber' },
  'BB-14081': { featureLevel: level.OFF, description: 'Use jsdom for parsing html img src attribute' },
  'BB-14091': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Finalised Invoice Add Payment Modal' },
  'BB-14095': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Credit Card Payment Modal' },
  'BB-14112': { featureLevel: level.PRODUCTION, description: 'Application form changes when creating a Firm in Feewise' },
  'BB-14190': { featureLevel: level.PRODUCTION, description: 'Hide Staff Users Settings tab' }, // Used to hide the Staff Users tab settings for Grow and Prosper
  'BB-14220': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Contact invoice list' },
  'BB-14222': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Matter invoice list' },
  'BB-14223': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Advanced Search invoice list' },
  'BB-14288': { featureLevel: level.OFF, description: 'Increase Batch limit for Bulk Sync' },
  'BB-14297': { featureLevel: level.PRODUCTION, description: 'Change Fee Export to use Reporting DB' },
  'BB-14323': { featureLevel: level.PRODUCTION, description: 'Bulk Trust Deposit / Receipt' },
  'BB-14377': { featureLevel: level.OFF, description: 'Enforce 2fa for logged in Xero users' },
  'BB-14531': { featureLevel: level.PRODUCTION, description: 'Staff Targets' },
  'BB-14708': { featureLevel: level.PRODUCTION, description: 'Load on Demand - Invoice Statement Add Payment Modal' },
  'BB-14897': { featureLevel: level.OFF, description: 'Remove ability to disable 2FA - Web App' }, // LD managed only
  'BB-14929': { featureLevel: level.OFF, description: 'Load on Demand - Disable Expense entity caches' },
  'BB-14974': { featureLevel: level.PRODUCTION, description: 'Hide tax display on invoices' },
  'LOOP-561': { featureLevel: level.DEV, description: 'Share files through communicate client portal' },
  'NUCWEB-179': { featureLevel: level.DEV, description: 'Productivity - Tasks' },
  'NUCWEB-82': { featureLevel: level.DEV, description: 'Productivity - Calendar' },
  'NUCWEB-123': { featureLevel: level.OFF, description: 'Dashboard' },
  'NUCWEB-70': { featureLevel: level.DEV, description: 'Productivity - Documents' },
  'NUCWEB-71': { featureLevel: level.DEV, description: 'Memos' },
  'NUCWEB-121': { featureLevel: level.DEV, description: 'Advanced search for Memos' },
  'NUCWEB-120': { featureLevel: level.DEV, description: 'Advanced search for Tasks' },
  'NUCWEB-164': { featureLevel: level.DEV, description: 'Advanced search for Events' },
  'NUCWEB-98': { featureLevel: level.DEV, description: 'Communicate Integration' },
  'NUCWEB-118': { featureLevel: level.DEV, description: 'Matter-Permissions' },
  'NUCWEB-151': { featureLevel: level.DEV, description: 'Matter Numbering' },
  'NUCWEB-134': { featureLevel: level.DEV, description: 'Data Backup settings page' },
  'NUCWEB-169': { featureLevel: level.DEV, description: 'Leads List page' },
  'NUCWEB-170': { featureLevel: level.DEV, description: 'Matter List page excludes leads' },
  'NUCWEB-173': { featureLevel: level.DEV, description: 'Quick Add Leads button' },
  'NUCWEB-249': { featureLevel: level.DEV, description: 'Intake Integration' },
  'NUCWEB-133': { featureLevel: level.DEV, description: 'Convert Lead to Matter' },
  'NUCWEB-351': { featureLevel: level.DEV, description: 'Show all widgets on matter page' },
  'NUCWEB-367': { featureLevel: level.OFF, description: 'Show recent docs widgets on matter page' },
  'NUCWEB-360': { featureLevel: level.PRODUCTION, description: 'User preferences' },
  'NUCWEB-405': { featureLevel: level.OFF, description: '(FUTURE IMPROVEMENT) Show the Intake Widgets' },
  'NUCWEB-406': { featureLevel: level.OFF, description: 'Show the “Intake” item in the “Firm Settings” sidebar' },
  'NUCWEB-407': { featureLevel: level.OFF, description: 'Show the “Forms” option in the top level menu' },
  'NUCWEB-408': { featureLevel: level.OFF, description: 'Show the “Intake” tab on matters' },
  'NUCWEB-409': { featureLevel: level.OFF, description: 'Show the “Intake” tab on leads' },
  'NUCWEB-436': { featureLevel: level.DEV, description: 'Show staff colours' },
  'NUCWEB-450': { featureLevel: level.DEV, description: 'My details page in My prefs' },
  'NUCWEB-208': { featureLevel: level.PRODUCTION, description: 'Show the Matter and Lead Overview dashboard' },
  'NUCWEB-490': { featureLevel: level.OFF, description: 'Matter types regex (used to restrict available matter types)' }, // JSON feature flag: this must be turned on through launch darkly
  'NUCWEB-503': { featureLevel: level.OFF, description: 'Show matters/leads with no activity widgets' },
  'NUCWEB-504': { featureLevel: level.DEV, description: 'Show leads with no activity widget' },
  'NUCWEB-505': { featureLevel: level.DEV, description: 'Show matters with no activity widget' },
  'NUCWEB-557': { featureLevel: level.OFF, description: 'Use react topnav search' },
  'NUCWEB-558': { featureLevel: level.OFF, description: 'Use productivity opensearch endpoint for matters' },
  'NUCWEB-564': { featureLevel: level.PRODUCTION, description: 'Trust contacts' },
  'NUCWEB-662': { featureLevel: level.OFF, description: 'Data Migration Settings' },
  'NUCWEB-675': { featureLevel: level.PRODUCTION, description: 'DOB for contacts' },
  'NUCWEB-609': { featureLevel: level.RC, description: 'Outlook calendar settings' },
  'NUCWEB-657': { featureLevel: level.OFF, description: 'Smokeball Marketplace' }, // JSON feature flag: this must be turned on through launch darkly
  'NUCWEB-728': { featureLevel: level.OFF, description: 'File launcher prototype download button' },
  'NUCWEB-749': { featureLevel: level.OFF, description: 'Archie tab' },
  'NV-5121': { featureLevel: level.OFF, description: 'Create letters from matter documents' },
  'NUCWEB-726': { featureLevel: level.PRODUCTION, description: 'Edit and inline contact LoD' },
  'NUCWEB-747': { featureLevel: level.OFF, description: 'Onboarding Wizard' },
  'NUCWEB-787': { featureLevel: level.OFF, description: 'File manager download links' }, // JSON feature flag: this must be turned on through launch darkly
  'NUCWEB-735': { featureLevel: level.OFF, description: 'Add matter wizard' },
  'NV-5392': { featureLevel: level.OFF, description: 'Open matter documents on the web' },
  'NUCWEB-783': { featureLevel: level.OFF, description: 'Document tab redesign' },
  'NUCWEB-794': { featureLevel: level.PRODUCTION, description: 'Bill feature lock' },
  'NUCWEB-799': { featureLevel: level.OFF, description: 'Feature promos/CTAs' },
  'NUCWEB-799-DEBUG': { featureLevel: level.OFF, description: 'Bill feature promos debug (FORCED TO SHOW)' },
  'NUCWEB-780': { featureLevel: level.PRODUCTION, description: 'Calendar sync godaddy warning' },
  'IN-1799': { featureLevel: level.DEV, description: 'InfoTrack tab' },
  'NUCWEB-855': { featureLevel: level.PRODUCTION, description: 'Boost trial banners' },
  'NUCWEB-855-DEBUG7': { featureLevel: level.OFF, description: 'Boost trial banners force 7 day banner to show' },
  'NUCWEB-855-DEBUG3': { featureLevel: level.OFF, description: 'Boost trial banners force 3 day banner to show' },
  'NUCWEB-855-DEBUG1': { featureLevel: level.OFF, description: 'Boost trial banners force 1 day banner to show' },
};

// featureMetaData is set after launch darkly loads as part of initialise-launch-darkly.js
const featureMetaDataStore = {};

const getPayload = () => Object.keys(features)
  .reduce((payload, key) => {
    payload[key] = features[key].featureLevel;

    return payload;
  }, {});

/**
 * @returns {Array.<string>}
 */
const getEnabledFeatures = () =>
  Object
    .keys(features)
    .filter((feature) => featureActive(feature));

const getAll = () => features;

const enableFeature = (featureTag, manuallyOverridden) => {
  // Don't allow non-manual overrides to enable features that have been manually overridden
  if (!manuallyOverridden && features[featureTag] && features[featureTag].manuallyOverridden) {
    return features;
  }

  features[featureTag] = { featureLevel: environmentToLevel[getAppEnv()], description: features[featureTag] && features[featureTag].description, manuallyOverridden };
  return features;
};

const disableFeature = (featureTag, manuallyOverridden) => {
  // Don't allow non-manual overrides to disable features that have been manually overridden
  if (!manuallyOverridden && features[featureTag] && features[featureTag].manuallyOverridden) {
    return features;
  }

  features[featureTag] = { featureLevel: level.OFF, description: features[featureTag] && features[featureTag].description, manuallyOverridden };
  return features;
};

/**
 * Set feature data will correctly set feature switch status as well as any data associated with it
 * @param {string} featureTag e.g. BB-9654
 * @param {*} featureValue supports boolean, JSON and number values for now, can easily be extended to support string in the future.
 */
const setFeatureData = (featureTag, featureValue) => {
  // handle boolean feature flags
  if (typeof featureValue === 'boolean') {
    featureValue ? enableFeature(featureTag) : disableFeature(featureTag);
    return;
  }

  // handle numeric feature flags
  if (typeof featureValue === 'number') {
    // by convention 0 signifies feature is OFF
    if (featureValue === 0) {
      featureMetaDataStore[featureTag] = undefined; // clear feature data
      disableFeature(featureTag);
    } else {
      featureMetaDataStore[featureTag] = featureValue;
      enableFeature(featureTag);
    }
    return;
  }

  // handle JSON feature flags
  if (featureValue !== null && typeof featureValue === 'object') {
    // by convention {} empty JSON object signifies feature is OFF
    if (Object.keys(featureValue).length > 0) {
      featureMetaDataStore[featureTag] = featureValue;
      enableFeature(featureTag);
    } else {
      featureMetaDataStore[featureTag] = undefined; // clear feature data
      disableFeature(featureTag);
    }
    return;
  }

  // // handle string feature flags
  if (featureValue !== null && typeof featureValue === 'string') {
    // by convention an empty string signifies feature is OFF
    if (featureValue !== '') {
      featureMetaDataStore[featureTag] = featureValue;
      enableFeature(featureTag);
    } else {
      featureMetaDataStore[featureTag] = undefined; // clear feature data
      disableFeature(featureTag);
    }
    return;
  }
};

const featureData = (featureTag) => {
  return featureMetaDataStore[featureTag];
};

const getFeatureLevel = (featureTag) => features[featureTag] && features[featureTag].featureLevel;

const getFeatureDescription = (featureTag) => `${featureTag} ${features[featureTag] && features[featureTag].description}`;

// usually a JIRA ticket e.g. BB-nnnn
const featureActive = (featureTag, environment) => {
  const env = environment || envType[getAppEnv()];
  const featureLevel = getFeatureLevel(featureTag);
  const featureDesc = getFeatureDescription(featureTag);

  if (!featureLevel) {
    log.error('no feature level configured for feature', featureTag, ' so assumed to be OFF');
    return false;
  }

  if (!env) {
    log.warn('cannot determine environment');
    return false; // if possible level and non-determinate env, return false for safety
  }

  const on = featureOn(featureLevel, env);

  if (env !== envType.PRODUCTION && env !== envType.LIVE) {
    log.info('feature', featureDesc, 'at level', featureLevel, 'in environment', env, 'is', on ? 'ON' : 'OFF');
  }

  return on;
};

let demo = false;

const featureOn = (featureLevel, env) => {
  if (featureLevel === level.OFF) {
    return false; // feature is disabled
  }

  switch (env) {
    case envType.LOCAL:
      if (demo) {
        return featureLevel === level.PRODUCTION || false;
      }
      return true; // all non-off features available in LOCAL frontend
    case envType.DEV:
      if (demo) {
        return featureLevel === level.PRODUCTION || false;
      }
      return (featureLevel === level.DEV || featureLevel === level.RC || featureLevel === level.PRODUCTION); // DEV and PRODUCTION features are available in DEV frontend
    case envType.RC:
      if (demo) {
        return featureLevel === level.PRODUCTION;
      }
      return (featureLevel === level.RC || featureLevel === level.PRODUCTION);
    case envType.PRODUCTION:
    case envType.QA:
    case envType.LIVE:
      return featureLevel === level.PRODUCTION; // only PRODUCTION features available in PRODUCTION/QA/LIVE frontends
    default:
      log.error('unknown environment ', env);
      throw new Error('unknown environment: ' + env);
  }
};

const toggleDemo = () => demo = !demo;
const getDemoMode = () => demo;

/**
 * Update all feature flags
 * @param {Object} featureFlags key/value pairs of featureTag/featureValue
 */
const updateFeatures = (featureFlags) => {
  if (!featureFlags) {
    return;
  }

  Object.entries(featureFlags).forEach(([featureTag, featureValue]) => {
    setFeatureData(featureTag, featureValue);
  });
};

module.exports = {
  featureActive,
  featureData,
  featureOn,
  getFeatureLevel,
  getAll,
  toggleDemo,
  getDemoMode,
  enableFeature,
  disableFeature,
  setFeatureData,
  getPayload,
  getEnabledFeatures,
  requestFeatureActive,
  updateFeatures,
};
