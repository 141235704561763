import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as forms from '@sb-itops/redux/forms2';
import { store } from '@sb-itops/redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useTranslation } from '@sb-itops/react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import * as messageDisplay from '@sb-itops/message-display';
import {
  getById as getBankAccountById,
  getNumberingSettings as getTransactionNumberingSettings,
} from '@sb-billing/redux/bank-account';
import uuid from '@sb-itops/uuid';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { isMatterContactBalanceType, getBalanceType } from '@sb-billing/redux/bank-account-settings';
import { dateToInteger } from '@sb-itops/date';
import { getMap as getState } from '@sb-billing/redux/bank-account-balances';
import {
  opdateCache,
  rollbackOpdateCache,
} from '@sb-billing/redux/bank-account-balances/bank-account-balances-opdates';

import { selectors, opdates } from '@sb-billing/redux/bank-account-balances.2';
import { capitalize } from '@sb-itops/nodash';
import { TransferFundsModal } from './TransferFundsModal';
import { TransferFundsFormSchema } from '../transfer-funds-form/TransferFundsFormSchema';
import { getTransferFundsValidateFn } from '../transfer-funds-form';

export const TRANSFER_FUNDS_MODAL_ID = 'transfer-funds-modal';
const TRANSFER_FUNDS_SCOPE = 'transfer-funds-form';
const localisationConfig = {
  transferReason: hasFacet(facets.reasonField),
  allowOverdraw: hasFacet(facets.allowOverdraw),
  autoGeneratedReference: hasFacet(facets.autoGeneratedReference),
  electronicPayment: hasFacet(facets.electronicPayment),
  supportsStatutoryDepositMatter: hasFacet(facets.statutoryDepositMatter),
  showAuthorisedBy: hasFacet(facets.authorisedByField),
  matterClientAddressRequiredForTrustDeposit: hasFacet(facets.matterClientAddressRequiredForTrustDeposit),
  matterClientRequiredForTrustDeposit: hasFacet(facets.matterClientRequiredForTrustDeposit),
  matterDescriptionRequiredForTrustDeposit: hasFacet(facets.matterDescriptionRequired),
  matterClientOrDescriptionRequired:
    hasFacet(facets.matterClientRequiredForTrustDeposit) || hasFacet(facets.matterDescriptionRequired),
};
const matterToMatterEndpoint = '/billing/transfer-funds/:accountId/matter-to-matter/';

const { getBankAccountBalanceById } = selectors;
const { getBankAccountBalanceOpdate, getTemplateBankAccount } = opdates;

const hooks = ({ onClose: onCloseProp, contactId, matterId, bankAccountId, isVisible, onClickLink }) => ({
  useSelectors: () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectors: formSelectors, operations: formOperations } = useScopedFeature(forms, TRANSFER_FUNDS_SCOPE);
    const formState = useSelector(formSelectors.getFormState);

    const isSubmitting = formState.formSubmitting;
    const validate = async () => {
      const context = {
        ...localisationConfig,
        showReason: localisationConfig.transferReason,
        showContacts: isMatterContactBalanceType(),
        supportsReference: localisationConfig.autoGeneratedReference,
        isTransferNumberingEnabled,
        t,
      };
      await dispatch(
        formOperations.validateForm({
          validateFn: getTransferFundsValidateFn(context),
          schema: TransferFundsFormSchema,
          context,
        }),
      );
    };

    const onClose = onCloseProp || (() => setModalDialogHidden({ modalId: TRANSFER_FUNDS_MODAL_ID }));

    const onTransferFunds = async () => {
      try {
        await validate();
        dispatch(
          await formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              const data = marshalData(formFieldValues, t, isMatterContactBalanceType());

              await transferMatterToMatterP(data);

              if (data.accountType && data.accountType.toUpperCase() === 'TRUST' && data.pdfOnTransfer === true) {
                onClickLink({
                  type: 'transferReceipt',
                  id: { transactionId: data.destinationTransactionId, paymentId: data.paymentId },
                });
              } else {
                messageDisplay.success('Funds transfered successfully');
              }
              setModalDialogHidden({ modalId: TRANSFER_FUNDS_MODAL_ID });
            },
          }),
        );
      } catch (err) {
        messageDisplay.error(messageDisplay.builder().text('Failed to transfer funds'));
      }
    };

    return {
      contactId,
      matterId,
      bankAccountId,
      scope: TRANSFER_FUNDS_SCOPE,
      isVisible,
      onClose,
      isSubmitLocked: isSubmitting,
      onTransferFunds,
      isTransferNumberingEnabled,
      localisationConfig,
    };
  },
});

function isTransferNumberingEnabled(bankAccountId) {
  if (!bankAccountId) {
    return false;
  }

  const { transferNumberingSettings } = getTransactionNumberingSettings({
    bankAccountId,
  });
  return !transferNumberingSettings || !transferNumberingSettings.useManualNumbering;
}

const marshalData = (formFieldValues, t, matterContactBalanceType) => {
  const {
    authorisedBy,
    sourceMatterId,
    destinationMatterId,
    sourceContactId,
    destinationContactId,
    sourceBankAccountId,
    amount,
    reference,
    reason,
    note,
    pdfOnTransfer,
  } = formFieldValues;

  const accountType = getBankAccountById(sourceBankAccountId)?.accountType;

  const funds = {
    userId: getUserId(),
    accountId: getAccountId(),
    amount,
    accountType: capitalize(accountType), // endpoint wants it as Trust not TRUST for some reason
    bankAccountId: sourceBankAccountId,
    contactId: matterContactBalanceType ? sourceContactId : undefined,
    destinationContactId: matterContactBalanceType ? destinationContactId : undefined,
    sourceMatterId,
    destinationMatterId,
    destinationTransactionId: uuid(),
    note,
    reason,
    authorisedBy, // this will only have a value in AU/UK
    paymentId: uuid(),
    timestamp: moment().toISOString(),
    pdfOnTransfer,
    effectiveDate: dateToInteger(new Date()), // NB this must always be set to todays date. Can't be back dated.
  };

  if (!isTransferNumberingEnabled(sourceBankAccountId)) {
    funds.reference = reference;
  }

  return funds;
};

const getBankAccountBalanceServiceOpdate = ({ bankAccountBalance, transfer, balanceType }) => {
  const { amount, contactId } = transfer;
  const allowOverdraw = hasFacet(facets.allowOverdraw);

  let bankAccountBalanceOpdate;

  bankAccountBalanceOpdate = getBankAccountBalanceOpdate({
    transaction: {
      cents: -amount,
      contactId,
      matterId: transfer.sourceMatterId,
    },
    bankAccountBalance,
    balanceType,
    allowOverdraw,
  });

  bankAccountBalanceOpdate = getBankAccountBalanceOpdate({
    transaction: {
      cents: amount,
      contactId,
      matterId: transfer.destinationMatterId,
    },
    bankAccountBalance: bankAccountBalanceOpdate,
    balanceType,
    allowOverdraw,
  });

  return bankAccountBalanceOpdate;
};

async function transferMatterToMatterP(transfer) {
  const balanceType = getBalanceType();
  const bankAccountBalance =
    getBankAccountBalanceById(getState(), { bankAccountId: transfer.bankAccountId }) || getTemplateBankAccount();

  const transferFundsThunk = async () => {
    // Apply to save optimistically.
    const opdate = getBankAccountBalanceServiceOpdate({ bankAccountBalance, transfer, balanceType });
    opdateCache({ optimisticEntities: [opdate] });

    // Apply the save in the backend.
    try {
      const path = matterToMatterEndpoint;
      const fetchOptions = { body: JSON.stringify(transfer) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [opdate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(transferFundsThunk);
}

export const TransferFundsModalContainer = withReduxProvider(composeHooks(hooks)(TransferFundsModal));

TransferFundsModalContainer.displayName = 'TransferFundsModalContainer';

TransferFundsModalContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  onClose: PropTypes.func,
  onClickLink: PropTypes.func,
  isVisible: PropTypes.bool,
};

TransferFundsModalContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  isVisible: false,
  onClose: undefined,
  printCheques: () => {},
  onClickLink: () => {},
};
