angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/settings/bank-account';

  // Settings (provides ui-view)
  $stateProvider

  .state('settings.bank-account', {
    url: '/bank-account',
    templateUrl: BASE_DIRECTORY + '/settings.html',
    data: {
      navHighlights: ['bank-account']
    }
  });
});
