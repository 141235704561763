import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AdjustmentsTable } from '../adjustments-table';
import { AddAdjustmentModalButton } from '../add-adjustment-modal-button';
import Styles from './BankReconciliationAdjustments.module.scss';

export const BankReconciliationAdjustments = memo(({ route, id, onUpdate, trustAccountId }) => (
  <>
    <div className={classnames(Styles.panel, 'ribbon panel')}>
      <AddAdjustmentModalButton route={route} id={id} onUpdate={onUpdate} trustAccountId={trustAccountId} />
    </div>
    <AdjustmentsTable route={route} id={id} onUpdate={onUpdate} trustAccountId={trustAccountId} />
  </>
));

BankReconciliationAdjustments.displayName = 'BankReconciliationAdjustments';

BankReconciliationAdjustments.propTypes = {
  route: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string.isRequired,
};
