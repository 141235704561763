import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { sendMetric } from 'web/services/metrics';
import { getSettings } from './index';
import { getDefaultReceiptSettings } from '../trust-receipt-settings';
import { getDefaultElectronicPaymentNumberingSettings } from '../bank-account';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbControlledMoneyAccountSettingsService',
  keyPath: 'accountId',
});

export const saveControlledMoneyAccountSettings = async (newCMASettings) => {
  const currentSettings = getSettings();
  const newSettings = {
    ...(currentSettings || {}),
    numberingSettings: {
      receipt: getDefaultReceiptSettings(),
      electronicPayments: getDefaultElectronicPaymentNumberingSettings(),
    },
    createPDFReceiptOnDeposit: false,
    createPDFReceiptOnPayment: false,
  };
  if (currentSettings) {
    newSettings.numberingSettings.receipt = currentSettings.receiptSettings;
    newSettings.numberingSettings.electronicPayments = currentSettings.electronicPaymentNumberingSettings;
    newSettings.createPDFReceiptOnPayment = currentSettings.createPDFReceiptOnPayment;
    newSettings.createPDFReceiptOnDeposit = currentSettings.createPDFReceiptOnDeposit;
  }
  if (newCMASettings.electronicPayments) {
    newSettings.numberingSettings.electronicPayments = newCMASettings.electronicPayments;
  }
  if (newCMASettings.receipt) {
    newSettings.numberingSettings.receipt = newCMASettings.receipt;
  }
  if (newCMASettings.createPDFReceiptOnDeposit !== undefined) {
    newSettings.createPDFReceiptOnDeposit = newCMASettings.createPDFReceiptOnDeposit;
  }
  if (newCMASettings.createPDFReceiptOnPayment !== undefined) {
    newSettings.createPDFReceiptOnPayment = newCMASettings.createPDFReceiptOnPayment;
  }

  opdateCache({ optimisticEntities: [newSettings] });

  sendMetric('SaveControlledMoneyAccount');

  try {
    const path = `/billing/controlled-money-account-settings/:accountId/`;
    const fetchOptions = { body: JSON.stringify(newSettings) };
    await fetchPostP({ path, fetchOptions });
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: [newSettings] });
    throw err;
  }
};
