import {
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/matter-totals';

// @ts-ignore
angular.module('@sb-billing/services').service('sbMatterTotalsService', function (sbGenericCacheService, sbLoggerService, sbEndpointType) {
  'use strict';

  const that = this,
    log = sbLoggerService.getLogger('sbMatterTotalsService');

  that.getTotalsForMatterId = getTotalsForMatterId;

  sbGenericCacheService.setupCache({
    name: 'sbMatterTotalsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/matter-totals' },
      poll: 60,
      subscriptions: 'notifier.BillingMattersNotifications.BillingMatterTotalsUpdated'
    },
    updateRedux,
    clearRedux,
  });

  function getTotalsForMatterId(id) {
    log.debug('fetching matter totals for ID: %s', id);
    return getById(id);
  }
});
