'use strict';

const balanceTypes = Object.freeze({
  BALANCE: 'balance',
  AVAILABLE: 'availableBalance',
  PROTECTED: 'protectedBalance',
});

const validAccountTypeForBalanceType = Object.freeze({
  TRUST: 'TRUST',
});

module.exports = {
  balanceTypes,
  validAccountTypeForBalanceType,
};
