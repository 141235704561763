import { SET_SELECTED_TAB, CLEAR_SELECTION_TAB } from './types';

export const setSelectedTab = ({ tab }) => ({
  type: SET_SELECTED_TAB,
  payload: {
    tab,
  },
});

export const clearSelectedTab = () => ({
  type: CLEAR_SELECTION_TAB,
});
