import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import moment from 'moment';
import { opdateCache, rollbackOpdateCache, buildReversalTransactionOpdates } from './index';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

// Only to be used for Controlled Money Accounts
export const reverseBankFee = (transaction) => {
  const { transactionId } = transaction;

  if (!transactionId) {
    throw new Error('Invalid Transaction, you need a transaction id for reversing a transaction');
  }

  const reverseBankFeeThunk = async () => {
    const reversalTransaction = buildReversalFeeTransaction(transaction);

    const opdateReversalTransaction = buildReversalFeeTransactionOpdates({
      originalTransaction: transaction,
      reversalTransactionId: reversalTransaction.newTransactionId,
    });

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: opdateReversalTransaction });

    try {
      const path = `/billing/bank-account/reversal/:accountId/bankfee/${transactionId}`;
      const fetchOptions = { body: JSON.stringify(reversalTransaction) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: opdateReversalTransaction });

      throw err;
    }
  };

  return store.dispatch(reverseBankFeeThunk);
};

const buildReversalFeeTransaction = (transaction) => {
  const { bankAccountId, accountType, reason, deleteTransaction } = transaction;
  const accountId = getAccountId();
  const userId = getUserId();
  return {
    bankAccountId,
    accountType,
    newTransactionId: uuid(),
    accountId,
    userId,
    effectiveDate: +moment().format('YYYYMMDD'),
    reason,
    hideTransactions: !!deleteTransaction,
  };
};

const buildReversalFeeTransactionOpdates = ({ originalTransaction, reversalTransactionId }) => {
  const reversalOpdates = buildReversalTransactionOpdates({
    originalTransaction,
    reversalTransactionId,
  });
  // reversalOpdates is [reversalTransaction, originalTransactionUpdated]
  // unlike regular transaction, bank fee opdate should have a reason and no note
  reversalOpdates[0].reason = reversalOpdates[0].note;
  delete reversalOpdates[0].note;

  return reversalOpdates;
};
