import { regionType, getRegion } from "@sb-itops/region";
import { bankAccountTypeEnum } from "@sb-billing/business-logic/bank-account/entities/constants";

const regionStrategy = {
  [regionType.AU]: {
    showBankAccountActionButtons: ({ accountType }) => accountType && accountType === bankAccountTypeEnum.TRUST,
    showViewStatementButton: () => false,
    showPrintButton: () => true,
  },
  [regionType.GB]: {
    showBankAccountActionButtons: ({ accountType }) => accountType && accountType === bankAccountTypeEnum.TRUST,
    showViewStatementButton: () => false,
    showPrintButton: () => true,
  },
  [regionType.US]: {
    showBankAccountActionButtons: ({ accountType }) =>
      accountType && (accountType === bankAccountTypeEnum.TRUST || accountType === bankAccountTypeEnum.OPERATING),
    showViewStatementButton: () => true,
    showPrintButton: () => false,
  },
};

export default {
  ...regionStrategy[getRegion()],
};
