import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbDefaultMatterBillingConfigurationService',
  keyPath: 'accountId',
});

export const buildOpdates = (config, versionId) => ({
  ...config,
  versionId,
});
