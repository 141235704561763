'use strict';

const Big = require('big.js');
const { Cent } = require('@sb-itops/money');
const { TaxStrategy } = require('./tax-strategy');

class SimpleTaxRateStrategy extends TaxStrategy {
  /**
   * @param {object} param
   * @param {number} param.taxRate
   * @param {boolean} param.isTaxExempt
   * @param {boolean} param.isTaxInclusive
   */
  constructor({ taxRate, isTaxExempt, isTaxInclusive }) {
    if (!Number.isInteger(taxRate)) {
      throw new Error('taxRate must be an integer');
    }

    if (taxRate < 0) {
      throw new Error('taxRate cannot be negative');
    }

    super();

    Object.defineProperty(this, 'taxRate', {
      configurable: false,
      enumerable: false,
      value: taxRate,
      writable: false,
    });

    Object.defineProperty(this, 'isTaxExempt', {
      configurable: false,
      enumerable: false,
      value: isTaxExempt,
      writable: false,
    });

    Object.defineProperty(this, 'isTaxInclusive', {
      configurable: false,
      enumerable: false,
      value: isTaxInclusive,
      writable: false,
    });
  }

  calculate(money) {
    super.calculate(money);
    let taxcents;

    if (this.isTaxExempt) {
      taxcents = 0;
    } else {
      const rate = new Big(this.taxRate).div(10000);
      const multiplier = !this.isTaxInclusive ? rate : rate.div(new Big(1).plus(rate));

      taxcents = parseInt(new Big(money.cents()).times(multiplier).toFixed(0), 10);
    }

    return {
      amountExcTax: !this.isTaxInclusive ? money : new Cent(money.cents() - taxcents),
      tax: new Cent(taxcents),
    };
  }
}

module.exports = { SimpleTaxRateStrategy };
