import { gql } from '@apollo/client';

const query = gql`
  query SbClientSdkRolesData($matterId: ID!) {
    matter(
      id: $matterId
      filter: { excludeBillableLeadMatters: false, includeNonBillableLeadMatters: true, includeDeleted: true }
    ) {
      id
      roles {
        id
        roleId
        matterId
        entityId
        representativeIds
      }
    }
  }
`;

const notificationIds = ['RelateEntitiesToMatter'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SbClientSdkRolesData = {
  query,
  notificationIds,
};
