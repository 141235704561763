import { shared } from '@sb-billing/business-logic';

const { getMinutesFuzzy, roundToInterval } = shared.services.fuzzyTime;

angular.module('@sb-billing/services').service('sbFuzzyFeeService', function(sbFeeConfigurationService) {
  const that = this;

  that.getMinutesFuzzy = (duration) => getMinutesFuzzy({
    duration,
    interval: sbFeeConfigurationService.getFeeInterval()
  });

  that.roundToInterval = (mins) => roundToInterval({
    mins,
    interval: sbFeeConfigurationService.getFeeInterval()
  });

});
