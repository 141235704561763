import * as React from 'react';

import { TopnavTypeahead, TElementOptionProps, TStyleOverrides } from './TopnavTypeahead';
import Styles from './TopnavSearch.module.scss';

export type TOptionElement = {
  value: string;
  element: JSX.Element;
};

export type TSection = 'Matters' | 'Leads' | 'Contacts' | 'Invoices';

export type TResolvedOptionElements = {
  matters: TOptionElement[];
  leads: TOptionElement[];
  contacts: TOptionElement[];
  invoices: TOptionElement[];
  // optional overrides for section headings
  mattersHeader?: string;
  leadsHeader?: string;
  contactsHeader?: string;
  invoicesHeader?: string;
  // optional override for section ordering
  sectionOrder?: TSection[];
};

const prepareSelectOptions = (
  input: string,
  resolved: TResolvedOptionElements,
  onClickLink: (nav: any) => void,
): TElementOptionProps[] => {
  const selectOptions: TElementOptionProps[] = [];

  const addMattersSection = () => {
    if (resolved.matters.length) {
      selectOptions.push();

      selectOptions.push({
        value: 'matters',
        element: (
          <span>
            <i className="icon-matter icon" />
            {resolved.mattersHeader || 'Matters'}
          </span>
        ),
        isDisabled: true,
      });

      selectOptions.push(
        ...resolved.matters.map((oe) => ({
          ...oe,
          navFunc: () => onClickLink({ id: oe.value, type: 'matter' }),
        })),
      );
    }
  };

  const addLeadsSection = () => {
    if (resolved.leads.length) {
      selectOptions.push();

      selectOptions.push({
        value: 'leads',
        element: (
          <span>
            <i className="icon-lead icon" />
            {resolved.leadsHeader || 'Leads'}
          </span>
        ),
        isDisabled: true,
      });

      selectOptions.push(
        ...resolved.leads.map((oe) => ({
          ...oe,
          navFunc: () => onClickLink({ id: oe.value, type: 'matter' }),
        })),
      );
    }
  };

  const addContactsSection = () => {
    if (resolved.contacts.length) {
      selectOptions.push();

      selectOptions.push({
        value: 'contacts',
        element: (
          <span>
            <i className="icon-profile-1 icon" />
            {resolved.contactsHeader || 'Contacts'}
          </span>
        ),
        isDisabled: true,
      });

      selectOptions.push(
        ...resolved.contacts.map((oe) => ({
          ...oe,
          navFunc: () => onClickLink({ id: oe.value, type: 'contact' }),
        })),
      );
    }
  };

  const addInvoicesSection = () => {
    if (resolved.invoices.length) {
      selectOptions.push();

      selectOptions.push({
        value: 'invoices',
        element: (
          <span>
            <i className="fa-money icon" />
            {resolved.invoicesHeader || 'Invoices'}
          </span>
        ),
        isDisabled: true,
      });

      selectOptions.push(
        ...resolved.invoices.map((oe) => ({
          ...oe,
          navFunc: () => onClickLink({ id: oe.value, type: 'invoice' }),
        })),
      );
    }
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const section of resolved.sectionOrder || ['Matters', 'Leads', 'Contacts', 'Invoices']) {
    switch (section) {
      case 'Matters': {
        addMattersSection();
        break;
      }
      case 'Leads': {
        addLeadsSection();
        break;
      }
      case 'Contacts': {
        addContactsSection();
        break;
      }
      case 'Invoices': {
        addInvoicesSection();
        break;
      }
      default: {
        // pass
      }
    }
  }

  if (selectOptions.length) {
    const navFunc = () => onClickLink({ id: input, type: 'advancedSearch' });
    selectOptions.push({
      navFunc,
      value: 'advanced',
      element: <div className={Styles.advanced}>See all results</div>,
      noStyling: true,
    });
  }

  return selectOptions;
};

type TProps = {
  optionResolver: (input: string) => Promise<TResolvedOptionElements>;
  onClickLink: (nav: any) => void;
  styleOverrides?: TStyleOverrides;
};

export const TopnavSearch = ({ optionResolver, onClickLink, styleOverrides }: TProps) => (
  <TopnavTypeahead
    asyncOptionElementResolver={async (input: string) =>
      prepareSelectOptions(input, await optionResolver(input), onClickLink)
    }
    runOnReturnKey={(search) => onClickLink({ type: 'advancedSearch', id: search })}
    styleOverrides={styleOverrides}
  />
);
