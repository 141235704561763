import { getMatterDisplayById, getById as getMatterById } from '@sb-matter-management/redux/matters';
import { fetchById as fetchContactById } from '@sb-customer-management/redux/contacts';
import { getReportTypes, buildSubmitForm, getPdfOptionsByReportType } from '@sb-billing/redux/report-config';
import { featureActive } from '@sb-itops/feature';
import { getLocationFromMatterTypeId } from '@sb-matter-types/business-logic/matter-types/services';
import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('sb.billing.webapp')
  .directive('sbActionGenerateAndDownloadReport', function(
    sbReportingService,
    sbAsyncOperationsService,
    sbMessageDisplayService,
    sbLocalisationService
  ) {
    return {
      restrict: 'A',
      require: { group: 'sbComposeGroup' },
      link: function(scope, element, attrs, ctrl) {
        let isDownloading;
        const DISPLAY_REMOVAL_GROUP = 'sbActionGerateAndDownloadReport';

        const trustLedgerOptions = {
          reportType: 'trust-ledger',
          bankAccountId: undefined,
          fromDate: undefined,
          toDate: undefined,
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          showInvoiceHistory: false,
          showInvoiceSummary: false,
          showAccountSummary: false,
          showFeesAndExpenses: false,
          showDeletedTransactions: false,
          showTransactionHistory: true,
          showTransactionHistoryWithInvoiceNumbers: true,
          printFirmName: true,
          printOnLetterhead: false,
          showLedger: false,
          showTrustAccountName: true,
          showTimeKeeperInitials: false,
          showExpenseTimeKeeperInitials: !featureActive('BB-2738'),
          showMatterDescriptionInRows: true,
          showMatterLevelColumn: true,
          runByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
        };

        const isTrustLedgerEnabled = hasFacet(facets.trustLedger);

        // this is so angular can execute an async/await function
        function downloadReport(args) {
          return downloadReportP(args);
        }

        async function downloadReportP({ matter, contact, showHidden, ledger, bankAccountId, displayAsMatterTransactionsList }) {
          ctrl.group.setLock(true);

          const contactInfo =
            contact && contact.entityId && (await fetchContactById(contact.entityId));
          const matterDisplay = matter && getMatterDisplayById(matter.matterId);
          const matterInfo = matter && getMatterById(matter.matterId);
          const locationId = matterInfo && getLocationFromMatterTypeId(matterInfo.matterTypeId, getMatterTypeById);

          const form = buildFilterForm({
            matter: matter && { ...matter, matterInfo, matterDisplay },
            contact: contact && { ...contact, contactInfo },
            showHidden,
            ledger,
            bankAccountId,
            locationId,
            displayAsMatterTransactionsList,
          });

          const allowOverdraw = hasFacet(facets.allowOverdraw);
          const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
          const supportsCMA = hasFacet(facets.CMA);
          const filterData = await generateReport(buildSubmitForm(form, sbLocalisationService.t, { supportsEnteredDate, allowOverdraw, supportsCMA }));

          return getReport(filterData);
        }

        function buildFilterForm({ matter, contact, showHidden, ledger, bankAccountId, locationId, displayAsMatterTransactionsList }) {

          if (displayAsMatterTransactionsList) {
            trustLedgerOptions.runByEnteredDate = locationId !== 'NSW';
          }

          const allowOverdraw = hasFacet(facets.allowOverdraw);
          const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
          const supportsCMA = hasFacet(facets.CMA);

          const reportOptions =
            isTrustLedgerEnabled && !ledger
              ? trustLedgerOptions
              : getReportTypes(sbLocalisationService.t, { supportsEnteredDate, allowOverdraw, supportsCMA }).find((reportConfig) => reportConfig.type === 'account-statement')
                  .filterForm;

          if (matter) {
            const filterForm = {
              ...reportOptions,
              bankAccountId,
              showDeletedTransactions: showHidden,
              matterContactRadio: 'matter',
              matterOrContactInfo: {
                id: matter.matterId,
                display: matter.matterDisplay,
              },
            };

            if (isTrustLedgerEnabled) {
              filterForm.matterOrContactInfo.display = `${filterForm.matterOrContactInfo.display} ${
                matter.description ? '- ' + matter.description : ''
              }`;
            }

            return filterForm;
          }

          const filterForm = {
            ...reportOptions,
            bankAccountId,
            showDeletedTransactions: showHidden,
            matterContactRadio: 'contact',
            matterOrContactInfo: {
              id: contact.entityId,
              display: contact.display,
            },
          };

          return filterForm;
        }

        function generateReport(filterForm) {
          return sbReportingService.generateReportP(filterForm);
        }

        function getReport(rawData) {
          if (isDownloading) {
            return;
          }

          if (rawData) {
            setDownloading(true);  
            const format = 'pdf';

            const allowOverdraw = hasFacet(facets.allowOverdraw);
            const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
            const supportsCMA = hasFacet(facets.CMA);
            const requestData = {
              reportGenerationId: rawData.reportGenerationId,
              reportType: rawData.data.reportType,
              bankAccountId: rawData.data.bankAccountId,
              pdfOptions: getPdfOptionsByReportType(rawData.data.reportType, sbLocalisationService.t, { supportsEnteredDate, allowOverdraw, supportsCMA }),
            };

            sbAsyncOperationsService.startGenerateReport(format, requestData);
          } else {
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text('Failed to download report. Please try again later.')
                .group(DISPLAY_REMOVAL_GROUP),
            );
          }

          setDownloading(false);
        }

        function setDownloading(status) {
          isDownloading = status;
          ctrl.group.setLock(status);
        }

        ctrl.group.setAction(downloadReport);
      },
    };
  });
