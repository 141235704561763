import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').component('sbDataInvoiceById', {
  require:  { compose: '^sbCompose' },
  bindings: { invoiceId: '<', composeDataKey: '@' },
  controller: function ($scope, sbInvoicingService, sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataInvoiceById');
    let invoice;
    let initialized;

    ctrl.$onInit = () => {
      log.info(`init invoiceId`, ctrl.invoiceId);
      update();
      initialized = true;
    };

    ctrl.$onChanges = () => {
      if (initialized) {
        update();
      }
    };

    $scope.$on('smokeball-data-update-sbInvoicingService', update);

    function update() {
      log.info(`invoiceId: ${ctrl.invoiceId}`);
      const fullInvoice = ctrl.invoiceId && sbInvoicingService.getFullInvoice(ctrl.invoiceId);
      const newInvoice = fullInvoice && fullInvoice.currentVersion;
      if (featureActive('BB-14011') && fullInvoice && fullInvoice.invoiceStatementId) {
        newInvoice.invoiceStatementId = fullInvoice.invoiceStatementId;
      }

      // we should make sure newInvoice is truthy before proceeding but other behaviour relies on this initially firing when the newInvoice is an empty string and the invoice is undefined
      if (newInvoice !== invoice) {
        invoice = newInvoice;
        ctrl.compose.setComposeData({dataType: 'invoices', data: invoice}, ctrl.composeDataKey || 'invoice');
      }
    }

  }
});
