import PropTypes from 'prop-types';
import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getRawAmount as getRawExpenseAmount } from '@sb-billing/business-logic/expense/services';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { sort } from '@sb-itops/sort';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import OperatingChequeExpenseTable from './OperatingChequeExpenseTable';

const hooks = ({ expenses }) => ({
  useOperatingChequeExpenseTable: () => {
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortBy, setSortBy] = useState('expenseDate');

    const onSort = (sortProps) => {
      setSortDirection(sortProps.sortDirection);
      setSortBy(sortProps.sortBy);
    };

    // Adding amount and matterDisplay here for sorting. As we're defining matterDisplay
    // here, we can use it instead of relying on MattersDisplayFromMatters
    const expensesList = expenses.map((expense) => ({
      ...expense,
      amount: getRawExpenseAmount(expense),
      matterDisplay: getMatterDisplay(expense.matter),
    }));

    return {
      expenses: sort(expensesList, [sortBy], [sortDirection]),
      sortDirection,
      sortBy,
      onSort,
    };
  },
});

const Container = withReduxProvider(composeHooks(hooks)(OperatingChequeExpenseTable));

Container.propTypes = {
  expenses: PropTypes.array,
};

Container.defaultProps = {
  expenses: [],
};

Container.displayName = `Container(${OperatingChequeExpenseTable.displayName})`;

export default Container;
