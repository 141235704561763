import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Button.module.scss';

export const buttonSizes = {
  small: 'small',
  default: 'default-size',
  'full-width': Styles.fullWidth,
};

export const buttonTypes = {
  default: 'default-type',
  secondary: 'secondary',
  tertiary: 'tertiary',
  info: 'info',
  warning: 'warning',
  link: 'link',
  tab: 'tab',
  danger: 'danger',
  dangerAlt: 'danger-alt',
};

const Button = (props) => {
  const { locked, disabled, onClick, children, form, className, size, type, highlight, title, style, borderless } =
    props;
  const isLink = type === buttonTypes.link;
  const isTab = type === buttonTypes.tab;
  const handleClick = (event) => !locked && !disabled && onClick(event);

  let buttonClasses;
  switch (type) {
    case buttonTypes.tertiary:
      buttonClasses = 'btn-tertiary';
      break;
    case buttonTypes.secondary:
      buttonClasses = 'btn-secondary';
      break;
    case buttonTypes.info:
      buttonClasses = 'btn-info';
      break;
    case buttonTypes.danger:
      buttonClasses = 'btn-danger';
      break;
    case buttonTypes.dangerAlt:
      buttonClasses = 'btn-danger-alt';
      break;
    default:
      buttonClasses = 'btn-primary';
  }

  buttonClasses = type === buttonTypes.warning ? 'btn-warning' : buttonClasses;

  /* eslint-disable react/button-has-type */
  return (
    <div
      className={classnames(
        isLink && 'link-button',
        isTab && 'tab-button',
        className,
        buttonSizes[size] || 'default-size',
        type,
      )}
    >
      <button
        type={form ? 'submit' : 'button'}
        className={classnames(
          !isLink && 'btn sb-button',
          !isLink && buttonClasses,
          highlight && 'highlight-button',
          borderless && Styles.borderless,
        )}
        disabled={disabled}
        onClick={handleClick}
        form={form}
        title={title}
        style={style}
      >
        {locked && <i className="fa fa-spinner fa-pulse fa-fw" />}
        {children}
      </button>
    </div>
  );
  /* eslint-enable react/button-has-type */
};

Button.displayName = 'Button';

Button.propTypes = {
  className: PropTypes.string,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  borderless: PropTypes.bool,
  form: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  highlight: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(buttonSizes)),
  type: PropTypes.oneOf(Object.values(buttonTypes)),
  style: PropTypes.object,
};

Button.defaultProps = {
  className: undefined,
  locked: false,
  disabled: false,
  borderless: false,
  form: undefined,
  title: undefined,
  onClick: () => {},
  highlight: false,
  size: 'default',
  type: buttonTypes.default,
  style: undefined,
};

export default Button;
