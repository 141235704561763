import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import {
  getById as getBankAccountById,
  getDefaultCreditAccount,
  getOperatingAccount,
  getTrustAccount,
} from '../bank-account';

import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'bulk-finalize-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbBulkFinalizeSettingsService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const getSettings = () => getList()[0];

/**
 * Gets the preferred bank account id from the settings, or returns the default (the trust account)
 * NOTE: the `state` parameter is not used as we don't write selectors correctly with respect
 * to redux. Its been passed in, in case we ever get around to changing that.
 */
export const getPreferredBankAccountId = (state) => {
  const settings = getSettings(state);

  if (!settings || !settings.preferredBankAccountId) {
    return getTrustAccount().id;
  }

  return settings.preferredBankAccountId;
};

export const getPreferredBankAccounts = (bulkFinaliseSettings = {}) => {
  const { preferredBankAccountId } = bulkFinaliseSettings;
  const preferredBankAccounts = bulkFinaliseSettings.preferredBankAccounts || [];

  // fall back to legacy behaviour with credit account placed first
  if (preferredBankAccounts.length < 3) {
    return preferredBankAccountId === getOperatingAccount().id
      ? [getDefaultCreditAccount(), getOperatingAccount(), getTrustAccount()]
      : [getDefaultCreditAccount(), getTrustAccount(), getOperatingAccount()];
  }

  return preferredBankAccounts.map((bankAccountId) => getBankAccountById(bankAccountId));
};

export const getPreferredBankAccountTypes = (bulkFinaliseSettings = {}) => {
  const preferredBankAccounts = getPreferredBankAccounts(bulkFinaliseSettings);
  return preferredBankAccounts.map((pba) => pba.accountType);
};

/**
 * mapBankAccountTypesToBankAccounts
 * Used for getting preferred accounts which are different than default bank accounts.
 * @param {Array.<string>} accountTypes Array of bank account types (TRUST|OPERATING|CREDIT)
 * @param {Object|undefined} bankAccountsByType
 * @param {Object|undefined} bankAccountsByType.TRUST Trust BankAccount, default Trust used if not provided
 * @param {Object|undefined} bankAccountsByType.OPERATING Oprating BankAccount, default Operating used if not provided
 * @param {Object|undefined} bankAccountsByType.CREDIT Credit BankAccount, default Credit used if not provided
 * @return {Array.<Object>} Array of bank accounts with order defined by account type
 */
export const mapBankAccountTypesToBankAccounts = ({ accountTypes, bankAccountsByType }) => {
  if (!Array.isArray(accountTypes)) return [];

  const bankAccounts = {
    [bankAccountTypeEnum.TRUST]: bankAccountsByType?.TRUST || getTrustAccount(),
    [bankAccountTypeEnum.OPERATING]: bankAccountsByType?.OPERATING || getOperatingAccount(),
    [bankAccountTypeEnum.CREDIT]: bankAccountsByType?.CREDIT || getDefaultCreditAccount(),
  };
  return accountTypes.map((at) => bankAccounts[at]);
};

export const saveSettings = (settings) => {
  const path = `/billing/bulk-finalize-settings/:accountId/`;
  const fetchOptions = { body: JSON.stringify(settings) };
  return fetchPostP({ path, fetchOptions });
};
