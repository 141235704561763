/* eslint-disable no-unused-vars */
'use strict';

let _backendOptions;

module.exports = {
  type: 'backend',
  // i18next appears to check the parameter list, include all params even if not used
  init: function (services, backendOptions, i18nextOptions) {
    if (!backendOptions.fetch) {
      throw new Error('fetch required to load resources');
    }
    if (typeof backendOptions.fetch !== 'function') {
      throw new Error('fetch must be a function');
    }
    _backendOptions = backendOptions;
  },
  // i18next appears to check the parameter list, include all params even if not used
  read: async function (language, namespace) {
    console.log('loading http resources', language, namespace);
    try {
      const response = await _backendOptions.fetch({ language });
      return response;
    } catch (err) {
      // do something here?
    }
  },
};
