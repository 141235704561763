"use strict";

import { featureActive } from "@sb-itops/feature";

angular.module("sb.billing.webapp").directive("sbPermissionIf", function ($rootScope, $parse, ngIfDirective) {
  const ngIf = ngIfDirective[0];

  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority - 2,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    $$tlb: true,
    link: function (scope, element, attrs) {
      const sbPermissionIfParsed = $parse(attrs.sbPermissionIf)(scope);
      const initialNgIf = attrs.ngIf;

      let permitted = initialNgIf ? scope.$eval(initialNgIf) : true;
      attrs.ngIf = () => permitted;

      ngIf.link.apply(ngIf, arguments);

      scope.$watch(
        () => $rootScope.sbPermissions,
        (newValue) => {
          const isAuthorized = !featureActive("BB-13941") || (newValue && newValue[sbPermissionIfParsed]);
          permitted = !!isAuthorized && (initialNgIf ? scope.$eval(initialNgIf) : true);
        },
      );
    },
  };
});
