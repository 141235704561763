import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, useTranslation } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
import * as messageDisplay from '@sb-itops/message-display';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog/actions';
import { ModalDialog } from '@sb-itops/react/modal-dialog';
import { EndOfMonthReportsTable } from '@sb-billing/react/end-of-month-reports-table';
import { getYears, getMonths, getLongMonth } from '@sb-billing/business-logic/accounts/end-of-month-reports';
import { EndOfMonthFilterPanel } from 'web/react-redux/components/end-of-month-filter-panel';
import { TrustAccountsOverview } from 'web/react-redux';
import Styles from './BillingAccountsEndOfMonth.module.scss';

const BillingAccountsEndOfMonth = memo(
  ({
    trustAccountId,
    trustAccountName,
    reports,
    month,
    year,
    modalId,
    accountType,
    sortDirection,
    lastPageNumbers,
    userId,
    isSpecificPage,
    isReconciled,
    isFirstReport,
    canRunReport,
    onConfirm,
    onClickLink,
    onSortDirectionChange,
    onSelectMonth,
    onSelectYear,
  }) => {
    const [selectedYear, selectYear] = useState();
    const [selectedMonth, selectMonth] = useState();
    const thisYear = new Date().getFullYear();
    const years = getYears(thisYear);
    const months = getMonths();
    const { t } = useTranslation();

    const onConfirmClick = () => {
      setModalDialogHidden({ modalId });
      onConfirm({
        bankAccountId: trustAccountId,
        lastPageNumbers,
        userId,
        month,
        year,
      });
    };

    const onMonthSelected = (option) => {
      onSelectMonth(option.value);
      selectMonth(option);
    };

    const onYearSelected = (option) => {
      onSelectYear(option.value);
      selectYear(option);
    };

    const onOpenReportClick = ({ report }) => {
      const reportMoment = moment(report.date, 'YYYYMMDD');

      onClickLink({
        type: 'endOfMonth',
        id: report.id,
        params: [`${getLongMonth(reportMoment.month())} ${reportMoment.year()}`],
      });
    };

    return (
      <div className="master-detail-panel">
        <div className={Styles.flexColumn}>
          <EndOfMonthFilterPanel onClickLink={onClickLink} accountType={accountType} trustAccountId={trustAccountId} />
        </div>

        <div className="data">
          <ModalDialog
            modalId={modalId}
            body={
              <div>
                <p>
                  Run end of month reporting for {getLongMonth(month)} {year} for the account {trustAccountName}
                </p>
                <p>Warning: {t('finalising')} the end of month will lock this period</p>
              </div>
            }
            title="Run End of Month"
            buttons={{
              rightAlign: true,
              primary: {
                text: 'Run',
              },
              secondary: {
                text: 'Cancel',
              },
            }}
            onPrimaryClick={onConfirmClick}
          />

          {isSpecificPage && (
            <div className="panel-body">
              <div className="ribbon panel panel-primary">
                <Button
                  onClick={() =>
                    isReconciled
                      ? setModalDialogVisible({ modalId })
                      : messageDisplay.error('Cannot run end of month report before bank reconciliation')
                  }
                  disabled={!canRunReport || !(Number.isInteger(month) && Number.isInteger(year))}
                >
                  Run End of Month
                </Button>
                {isFirstReport && (
                  <div className={Styles.period}>
                    <div className={Styles.choose}>Please choose the first reporting period</div>
                    <StatelessSelect
                      className={Styles.select}
                      selectedOption={selectedMonth}
                      isClearable={false}
                      options={months}
                      onValueChange={(option) => onMonthSelected(option)}
                      placeholder="Select month"
                    />
                    <StatelessSelect
                      className={Styles.select}
                      selectedOption={selectedYear}
                      isClearable={false}
                      options={years}
                      onValueChange={(option) => onYearSelected(option)}
                      placeholder="Select year"
                    />
                  </div>
                )}
              </div>
              <EndOfMonthReportsTable
                reports={reports}
                onRowClick={onOpenReportClick}
                sortDirection={sortDirection}
                onSortDirectionChange={onSortDirectionChange}
              />
            </div>
          )}
          {!isSpecificPage && <TrustAccountsOverview onClickLink={onClickLink} parentPage="END_OF_MONTH" />}
        </div>
      </div>
    );
  },
);

BillingAccountsEndOfMonth.displayName = 'BillingAccountsEndOfMonth';

BillingAccountsEndOfMonth.propTypes = {
  trustAccountId: PropTypes.string,
  trustAccountName: PropTypes.string,
  reports: PropTypes.array,
  month: PropTypes.number,
  year: PropTypes.number,
  modalId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string,
  accountType: PropTypes.string,
  lastPageNumbers: PropTypes.any,
  userId: PropTypes.string.isRequired,
  isReconciled: PropTypes.bool.isRequired,
  isFirstReport: PropTypes.bool.isRequired,
  canRunReport: PropTypes.bool.isRequired,
  isSpecificPage: PropTypes.bool.isRequired,

  onConfirm: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onSortDirectionChange: PropTypes.func.isRequired,
  onSelectMonth: PropTypes.func.isRequired,
  onSelectYear: PropTypes.func.isRequired,
};

BillingAccountsEndOfMonth.defaultProps = {
  reports: [],
  month: undefined,
  year: undefined,
  sortDirection: undefined,
  lastPageNumbers: undefined,
  accountType: 'TRUST',
  trustAccountName: '',
  trustAccountId: undefined,
};

export default BillingAccountsEndOfMonth;
