'use strict';

const statesInAustralia = [
  { name: 'New South Wales', id: 'NSW' },
  { name: 'Victoria', id: 'VIC' },
  { name: 'Queensland', id: 'QLD' },
  { name: 'South Australia', id: 'SA' },
  { name: 'Western Australia', id: 'WA' },
  { name: 'Northern Territory', id: 'NT', isTerritory: true },
  { name: 'Australian Capital Territory', id: 'ACT', isTerritory: true },
  { name: 'Tasmania', id: 'TAS' },
];

module.exports = {
  statesInAustralia,
};
