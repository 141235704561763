import { TOGGLE_EXPANDED } from './types';

const INITIAL_FILTER_STATE = {
  expanded: true,
};

export const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case TOGGLE_EXPANDED: {
      return {
        ...state,
        expanded: !state.expanded,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
