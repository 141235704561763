import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';

const findMatterIdsWithUnpaidBalanceByDebtorId = ({ allInvoicesMap, allInvoiceTotals, debtorId }) => {
  if (!allInvoicesMap) {
    throw new Error('allInvoicesMap is required');
  }
  if (!allInvoiceTotals) {
    throw new Error('allInvoiceTotals is required');
  }

  // find all matters with invoices from the debtor that are still owing money
  const { map, list } = allInvoiceTotals.reduce(
    (acc, invoiceTotal) => {
      // invoices on payment plan are considered paid even when the interest component is not paid
      if (invoiceTotal.unpaidExcInterest <= 0) {
        return acc;
      }

      // a few quick checks to skip further look up and processing
      const invoice = allInvoicesMap[invoiceTotal.invoiceId];
      if (
        !invoice ||
        !invoice.currentVersion ||
        invoice.currentVersion.status !== 'FINAL' || // check only invoices that are in FINAL status
        !invoice.currentVersion.debtors
      ) {
        return acc;
      }

      const invoiceDebtorIds = invoice.currentVersion.debtors.map((debtor) => debtor.id);
      if (!invoiceDebtorIds.includes(debtorId)) {
        return acc;
      }

      // include matter in payment plan only if there are single debtor invoices for the matter
      const canIncludeInPaymentPlan = invoiceDebtorIds.length === 1;

      if (!Object.prototype.hasOwnProperty.call(acc.map, invoiceTotal.matterId)) {
        // if matter already added to list, don't include it again
        acc.map[invoiceTotal.matterId] = canIncludeInPaymentPlan;
        acc.list.push(invoiceTotal.matterId);
      } else {
        // otherwise update canIncludeInPaymentPlan flag
        acc.map[invoiceTotal.matterId] = acc.map[invoiceTotal.matterId] || canIncludeInPaymentPlan;
      }
      return acc;
    },
    { map: {}, list: [] },
  );

  return { map, list };
};

/**
 * Find matter ids where the specified debtor has unpaid balance, this could include
 * 1) single debtor invoices (which could be included in payment plan)
 * 2) multi-debtor invoices (which can't be included in payment plans but needs to be returned)
 * NB: Unpaid interest are not considered unpaid in the context of payment plans
 * @param {object} state
 * @param {object} state.allInvoicesMap
 * @param {Array.<object>} state.allInvoiceTotals
 * @param {object} parameters
 * @param {string} state.debtorId
 * @return {object} a map of matter ids (key) where the debtor has invoices that still owe money
 * with a boolean value (indicating whether or not the invoice can be included in a payment plan)
 */
export const findMatterIdsWithUnpaidBalanceForDebtorAsMap = ({ allInvoicesMap, allInvoiceTotals }, { debtorId }) => {
  const { map } = findMatterIdsWithUnpaidBalanceByDebtorId({ allInvoicesMap, allInvoiceTotals, debtorId });
  return map;
};

/**
 * Find matter ids where the specified debtor has unpaid balance, this could include
 * 1) single debtor invoices (which could be included in payment plan)
 * 2) multi-debtor invoices (which can't be included in payment plans but needs to be returned)
 * NB: Unpaid interest are not considered unpaid in the context of payment plans
 * @param {object} state
 * @param {object} state.allInvoicesMap
 * @param {Array.<object>} state.allInvoiceTotals
 * @param {{ debtorId: string }} { debtorId }
 * @return {Array.<string>} a list of matter ids (key) where the debtor has invoices that still owe money
 */
export const findMatterIdsWithUnpaidBalanceForDebtor = ({ allInvoicesMap, allInvoiceTotals }, { debtorId }) => {
  const { list } = findMatterIdsWithUnpaidBalanceByDebtorId({ allInvoicesMap, allInvoiceTotals, debtorId });
  return list;
};

/**
 * Find and return a map of debtorId to its currently active payment plan
 * Debtors with no active payment plan will not be included.
 * @param {object} state
 * @param {Array.<object>} state.allPaymentPlans
 * @return {object} a map of debtor ids to currently active payment plan
 */
export const findDebtorIdWithActivePaymentPlanAsMap = ({ allPaymentPlans }) => {
  if (!allPaymentPlans) {
    throw Error('allPaymentPlans is required');
  }

  const map = allPaymentPlans.reduce((acc, paymentPlan) => {
    if (paymentPlan.status === paymentPlanStatuses.ACTIVE) {
      acc[paymentPlan.debtorId] = paymentPlan;
    }
    return acc;
  }, {});
  return map;
};

/**
 * Find a list of all the debtor ids with unpaid balance (exc interest).
 * NB: Unpaid interest are not considered unpaid in the context of payment plans
 * @param {object} state
 * @param {object} state.allInvoicesMap
 * @param {Array.<object>} state.allInvoiceTotals
 * @param {Array.<object>} state.allPaymentPlans
 * @param {{ exclude: { debtorIds: Array.<string> }}} debtorIds - debtors to exclude from the search.
 * @return {Array.<string>} a list of all the debtor ids that are still owing money.
 */
export const findDebtorIdsWithUnpaidBalanceAndNotOnPaymentPlan = (
  { allInvoicesMap, allInvoiceTotals, allPaymentPlans },
  { exclude: { debtorIds = [] } } = { exclude: { debtorIds: [] } },
) => {
  if (!allInvoicesMap) {
    throw new Error('allInvoicesMap is required');
  }
  if (!allInvoiceTotals) {
    throw new Error('allInvoiceTotals is required');
  }
  if (!allPaymentPlans) {
    throw new Error('allPaymentPlans is required');
  }

  // get a map/index of debtors to their active payment plan
  const debtorIdsWithActivePaymentPlanMap = findDebtorIdWithActivePaymentPlanAsMap({ allPaymentPlans });

  // find all debtor invoices that are still owing money
  const { list } = allInvoiceTotals.reduce(
    (acc, invoiceTotal) => {
      const invoice = allInvoicesMap[invoiceTotal.invoiceId];
      if (
        invoice &&
        invoice.currentVersion.status === 'FINAL' && // check only invoices that are in FINAL status
        invoiceTotal.unpaidExcInterest > 0 && // invoices on payment plan are considered paid even when the interest component is not paid
        !acc.map[invoiceTotal.debtorId] && // if debtor already added to list, don't include it again
        !debtorIds.includes(invoiceTotal.debtorId) // don't include exclusions
      ) {
        if (!Object.prototype.hasOwnProperty.call(debtorIdsWithActivePaymentPlanMap, invoiceTotal.debtorId)) {
          // include only those not already on an active payment plan
          acc.map[invoiceTotal.debtorId] = invoiceTotal.debtorId;
          acc.list.push(invoiceTotal.debtorId);
        }
      }
      return acc;
    },
    { map: {}, list: [] },
  );

  return list;
};
