'use strict';

const { operationTypes } = require('./entities/index');

/**
 * @param {Array<{Object}>} rawHistory
 * @param {*} moment
 * @returns {import('./types').IntegrationHistoryModel} correlationHistory
 * we are slotting each IntegrationHistory row into a group, then slotting each group into a correlation,
 * and putting the ones that match together
 */
const buildIntegrationHistoryModel = (rawHistory, moment) => {
  const correlations = {};

  rawHistory.forEach((historyRecord) => {
    const uniqueCorrelationIdentifier = buildCorrelationIdentifier(historyRecord);

    if (uniqueCorrelationIdentifier in correlations) {
      // correlation does exist
      const correlation = correlations[uniqueCorrelationIdentifier];
      const uniqueGroupIdentifier = buildGroupIdentifier(historyRecord);

      if (uniqueGroupIdentifier in correlation.groups) {
        // group does exist
        correlation.groups[uniqueGroupIdentifier].items.push({
          ...historyRecord,
          timestamp: moment(historyRecord.timestamp),
          operationType: operationTypes[historyRecord.operationType],
        });
      } else {
        // group doesn't exist, create new
        const newGroup = createGroupFromRecord(historyRecord, moment);
        correlation.groups[uniqueGroupIdentifier] = newGroup;
      }
    } else {
      // correlation doesn't exist, create new
      const newCorrelation = createCorrelationFromRecord(historyRecord, moment, uniqueCorrelationIdentifier);
      correlations[uniqueCorrelationIdentifier] = newCorrelation;
    }
  });

  const flattenedCorrelations = Object.values(correlations).reduce((corrAcc, correlation) => {
    const groupsAsArray = Object.values(correlation.groups).reduce((groupAcc, group) => {
      // Calculate derived properties for group
      const orderByLastUpdatedDesc = group.items.sort((a, b) => b.timestamp.diff(a.timestamp));
      const recalculatedGroup = {
        ...group,
        lastUpdated: group.items.reduce((acc, item) => Math.max(item.timestamp.unix(), acc), -Infinity), // gets max timestamp
        transactionDate: group.items.reduce((acc, item) => Math.max(item.transactionDate, acc), -Infinity), // gets max timestamp
        isFailed: orderByLastUpdatedDesc.length ? isRecordFailed(orderByLastUpdatedDesc[0]) : false,
        items: orderByLastUpdatedDesc,
      };
      groupAcc.push(recalculatedGroup);
      return groupAcc;
    }, []);

    // Calculate derived properties for correlation
    const recalculatedCorrelation = {
      ...correlation,
      groups: groupsAsArray,
      // derived properties
      isFailed: groupsAsArray.some((group) => group.isFailed),
      lastUpdated: groupsAsArray.reduce((acc, group) => Math.max(acc, group.lastUpdated), -Infinity), // gets max timestamp
      transactionDate: groupsAsArray.reduce((acc, group) => Math.max(acc, group.transactionDate), -Infinity), // gets max timestamp
      operationType: groupsAsArray[0].operationType,
    };

    corrAcc.push(recalculatedCorrelation);
    return corrAcc;
  }, []);

  return flattenedCorrelations;
};

const buildCorrelationIdentifier = (historyRecord) =>
  `${historyRecord.integrationTarget}&${historyRecord.correlationId}`;

const buildGroupIdentifier = (historyRecord) =>
  `${historyRecord.operationType}&${(!!historyRecord.isReversal).toString()}`;

/**
 * Mid-level of hierarchy. One group is unique by integration name, correlation id, 
   operation type and reversal (true/false).
 * @param {*} historyRecord 
 * @returns 
 */
const createGroupFromRecord = (historyRecord, moment) => ({
  groupIdentifier: buildGroupIdentifier(historyRecord),
  operationType: operationTypes[historyRecord.operationType],
  isReversal: !!historyRecord.isReversal,
  items: [
    {
      ...historyRecord,
      timestamp: moment(historyRecord.timestamp),
    },
  ],
});

/**
 * Highest level of hierarchy - group of history items by correlation id.
   these might contain several operations, each with history items (e.g. correlation id might be payment id,
   and operations can be batch payment, credit payment, reversals etc.)
 * @param {*} historyRecord 
 * @returns 
 */
const createCorrelationFromRecord = (historyRecord, moment, uniqueCorrelationIdentifier) => {
  const group = createGroupFromRecord(historyRecord, moment);
  const groups = {};
  groups[group.groupIdentifier] = group;

  return {
    uniqueCorrelationIdentifier,
    integrationName: historyRecord.integrationTarget,
    correlationId: historyRecord.correlationId,
    groups,
  };
};

const isRecordFailed = (historyRecord) => !!historyRecord.exceptionMessage || !!historyRecord.exceptionDetail;

module.exports = {
  buildIntegrationHistoryModel,
};
