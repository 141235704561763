const sanitizeHtml = require('sanitize-html');

/**
 * cleanHtml
 * Uses sanitizeHtml to remove any tags not on that whitelist
 * 
 * default allowedTags: [
  'h3',     'h4',         'h5',
  'h6',     'blockquote', 'p',
  'a',      'ul',         'ol',
  'nl',     'li',         'b',
  'i',      'strong',     'em',
  'strike', 'abbr',       'code',
  'hr',     'br',         'div',
  'table',  'thead',      'caption',
  'tbody',  'tr',         'th',
  'td',     'pre',        'iframe' (removed in cleanHtml),
],
 * @param {object} params
 * @param {string} params.dirtyHtml
 * @param {Array} [params.ensureAllowedTags] Array of html tags to allow in addition to the default sanitize-html whitelist
 * @return {string} html cleaned of all but the allowed tags
 */
const cleanHtml = ({ dirtyHtml, ensureAllowedTags = [] }) => {
  if (!dirtyHtml) {
    return dirtyHtml;
  }

  // Good lord iframes should not be allowed by default. Iframes expose SSRF attack risks.
  return sanitizeHtml(dirtyHtml,{
    allowedTags: sanitizeHtml.defaults.allowedTags.filter((tag) => tag !== 'iframe').concat(ensureAllowedTags),
  });
}

/**
* @param {object} params
* @param {string} params.base64EncodedHtml as read from the DB
* @param {Array} [params.ensureAllowedTags] Array of html tags to allow in addition to the default sanitize-html whitelist
* @return {string} html decoded from base64 and cleaned of all but the allowed tags
*/
const decodeAndClean64Html = ({ base64EncodedHtml, ensureAllowedTags = ['u'] }) => {
  if (!base64EncodedHtml) {
    return base64EncodedHtml;
  }
  const dirtyHtml = Buffer.from(base64EncodedHtml, 'base64').toString('utf8');

  return cleanHtml({dirtyHtml, ensureAllowedTags});
};

/**
* @param {object} params
* @param {string} params.base64EncodedHtml as read from the DB
* @param {Array} [params.ensureAllowedTags] Array of html tags to allow in addition to the default sanitize-html whitelist
* @return {string} html decoded from base64 and cleaned of all but the allowed tags, then encoded back into base64
*/
const cleanBase64Html = ({ base64EncodedHtml, ensureAllowedTags = ['u'] }) => {
  const cleanedHtml = decodeAndClean64Html({ base64EncodedHtml, ensureAllowedTags })
  return Buffer.from(cleanedHtml, 'utf8').toString('base64');
}

/** 
* @param {object} params
* @param {string} params.dirtyHtml
* @param {Array} [params.ensureAllowedTags] Array of html tags to allow in addition to the default sanitize-html whitelist
* @return {string} html cleaned of all but the allowed tags, then encoded into base64
*/
const cleanAndEncode64Html = ({ dirtyHtml, ensureAllowedTags = ['u'] }) =>
{
  if (!dirtyHtml)
    return dirtyHtml;
  
  const cleanedHtml = cleanHtml({ dirtyHtml, ensureAllowedTags });
  
  return Buffer.from(cleanedHtml, 'utf8').toString('base64');
}

module.exports = {
  cleanHtml,
  decodeAndClean64Html,
  cleanBase64Html,
  cleanAndEncode64Html,
};

