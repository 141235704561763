import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const toErrorClass = (hasError) => (hasError ? 'has-error' : '');

const getErrorFromClass = (errorClass) => errorClass === 'has-error';

/* eslint-disable */
const enhanceComponent = (errorCondition = noop) => WrappedComponent => {
  class WithErrorHandlerComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.onValueChange = this.onValueChange.bind(this);
      this.state = {
        customClasses: toErrorClass(!!this.props.hasErrorFlag),
        fromValueChange: false,
      };
    }

    // TODO REVIEW THIS LOGIC.
    // what this is saying is that parent props override any current error state, we need to provide any way to the parent component
    // change the parent prop if needed and update the state of this component.
    static getDerivedStateFromProps({ hasErrorFlag, onErrorUpdated }, { customClasses, fromValueChange }) {
      const hasStateError = getErrorFromClass(customClasses) && fromValueChange;
      const newCustomClass = toErrorClass(hasErrorFlag || hasStateError);
      if (newCustomClass !== customClasses) {
        // send the callback for the error update upwards
        if (onErrorUpdated) onErrorUpdated(hasErrorFlag);

        return {
          customClasses: newCustomClass,
          fromValueChange: false,
        };
      }
      return null;
    }

    onValueChange(value) {
      // props take over state
      const hasError =
        this.props.hasErrorFlag ||
        (this.props.errorConditionHandler && this.props.errorConditionHandler(value)) ||
        errorCondition(value);

      if (toErrorClass(hasError) !== this.state.customClasses && this.props.onErrorUpdated)
        this.props.onErrorUpdated(hasError);

      // // send change callback upwards
      if (this.props.onValueChange) this.props.onValueChange(value);

      this.setState({
        customClasses: toErrorClass(hasError),
        fromValueChange: true,
      });
    }

    render() {
      return (
        <WrappedComponent {...this.props} onValueChange={this.onValueChange} customClasses={this.state.customClasses} />
      );
    }
  }

  WithErrorHandlerComponent.propTypes = {
    hasErrorFlag: PropTypes.bool,
    onErrorUpdated: PropTypes.func,
    onValueChange: PropTypes.func,
  };

  return WithErrorHandlerComponent;
};

export default enhanceComponent;
/* eslint-enable */
