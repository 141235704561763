import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CardContainer, FloatingCard } from '@sb-itops/react';

import Styles from './SettingsInfoCard.module.scss';

const getIcon = (iconName) => {
  if (!iconName) {
    return '';
  }
  if (iconName === 'INFO') {
    return <i className={classnames('icon', 'icon-information', Styles.infoIcon)} />;
  }
  if (iconName === 'SUCCESS') {
    return <i className={classnames('icon', Styles.successIcon)} data-icon="&#xe07a;" />;
  }
  if (iconName === 'ERROR') {
    return <i className={classnames('icon', Styles.errorIcon)} data-icon="&#xe0de;" />;
  }
  return '';
};

const SettingsInfoCard = memo(({ iconName, heading, subheading, children }) => (
  <div className={Styles.settingsInfoCardContainer}>
    <CardContainer>
      <FloatingCard>
        <div className={Styles.settingsInfoCard}>
          {getIcon(iconName)}
          <div className={Styles.heading}>{heading}</div>
          <div className={Styles.subheading}>{subheading}</div>
          <div className={Styles.content}>{children}</div>
        </div>
      </FloatingCard>
    </CardContainer>
  </div>
));

SettingsInfoCard.displayName = 'SettingsInfoCard';

SettingsInfoCard.propTypes = {
  iconName: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

SettingsInfoCard.defaultProps = {
  iconName: undefined,
  children: null,
};

export default SettingsInfoCard;
