'use strict';

/**
 * A transaction filter function for trust transactions with ability to specify
 * additional filter values { showHidden, matterId, contactId, trustAccountId }
 * @param {Object} transaction
 * @param {Object} filterValues
 * @param {boolean} filterValues.showHidden
 * @param {string} filterValues.matterId
 * @param {string} filterValues.contactId
 * @param {string} filterValues.trustAccountId
 */
function trustTransactionFilter(transaction, { showHidden, matterId, contactId, trustAccountId }) {
  if (transaction.bankAccountType !== 'Trust') {
    return false;
  }

  if (!showHidden && transaction.isHidden) {
    return false;
  }

  if (matterId && matterId !== transaction.matterId) {
    return false;
  }

  if (contactId && contactId !== transaction.contactId) {
    return false;
  }

  if (trustAccountId && trustAccountId !== transaction.bankAccountId) {
    return false;
  }

  return true;
}

module.exports = {
  trustTransactionFilter,
};
