import React from 'react';
import PropTypes from 'prop-types';

const NavTabMenu = ({ tabId, menuOptions }) => (
  <div className="context-menu context-menu-body list-group">
    {menuOptions.map(([text, closeTabs]) => (
      <button key={text} type="button" className="list-group-item" onClick={() => closeTabs(tabId)}>
        {text}
      </button>
    ))}
  </div>
);

NavTabMenu.propTypes = {
  tabId: PropTypes.number.isRequired,
  menuOptions: PropTypes.array.isRequired,
};

NavTabMenu.displayName = 'NavTabMenu';

export default NavTabMenu;
