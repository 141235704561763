angular.module('sb.billing.webapp').controller('SbInvoiceLineSummaryByInvoiceNumberController', function ($scope, sbInvoicingService) {
  'use strict';
  var that = this;

  function update() {
    if ($scope.invoiceNumber) {
      var invoiceId = sbInvoicingService.getInvoiceIDByNumber($scope.invoiceNumber);
      if(invoiceId) {
        that.view = sbInvoicingService.getInvoice(invoiceId);
        $scope.matterId = that.view.matterId;
        $scope.invoiceId = invoiceId;
      }
    } 
  }

  $scope.$watch('invoiceNumber', update);
  $scope.$on('smokeball-data-update-sbInvoicingService', update);
});
