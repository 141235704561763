import { getAccountId, getUserId, getUsername } from 'web/services/user-session-management';

export const initialiseAnalytics = ({ log, defaultTeardown }) => {
  try {
    const accountId = getAccountId();
    const userId = getUserId();
    const username = getUsername();

    window.sbAnalytics.setIdentity({ accountId, userId, username });
  } catch (err) {
    // We don't stop the user from using billing if analytics fails, just log it.
    log.warn('Failed to initialise analytics', err);
  }

  return defaultTeardown;
};
