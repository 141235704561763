import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { today, dateToInteger } from '@sb-itops/date';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { useTranslation, withReduxStore } from '@sb-itops/react';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withOnLoad } from '@sb-itops/react/hoc';
import {
  isPaymentProviderEnabledForBankAccount,
  getProviderSettings,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import {
  calculateFeeDetails,
  extractFeeSchedule,
  getMinChargeAmountInCents,
} from '@sb-billing/business-logic/payment-provider/services';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/redux/contacts/index';
import { getMap as getBankAccountBalances } from '@sb-billing/redux/bank-account-balances';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { getRegion } from '@sb-itops/region';
import { CreditCardDepositForm } from './CreditCardDepositForm';
import { creditCardDepositFormSchema } from './credit-card-deposit-form-schema';

const scope = 'credit-card-deposit-form';
const region = getRegion();

const mapStateToProps = (state, { providerType, providerSpecificChargeData, isSubmitting, clientIsCoveringFee }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formDirty, formValid } = formSelectors.getFormState(state);

  if (!formInitialised) {
    return { isLoading: true };
  }

  const contactOptions = getContactTypeAheadSummaries();
  const { bankAccountId, dateDeposited, payorId, matterId, depositAmount, reason } = formFields;

  const matterBalance =
    matterId && matterId.value && bankAccountId && bankAccountId.value
      ? getMatterBalance(getBankAccountBalances(), { bankAccountId: bankAccountId.value, matterId: matterId.value })
      : 0;
  const balanceAfterDeposit = matterBalance + ((depositAmount && depositAmount.value) || 0);

  // Calculate the fee details if the fee is being passed on to the client.
  const formattedPaymentProviderSettings = getProviderSettings(providerType);
  const feeSchedule = clientIsCoveringFee
    ? extractFeeSchedule({
        providerType,
        formattedProviderSpecificSettings: formattedPaymentProviderSettings,
        bankAccountId: bankAccountId?.value,
      })
    : undefined;

  const feeDetails = clientIsCoveringFee
    ? calculateFeeDetails({
        providerType,
        feeSchedule,
        providerSpecificFields: providerSpecificChargeData,
        desiredAmountInCents: depositAmount.value || 0,
      })
    : {};

  const minAmountAllowed = getMinChargeAmountInCents({ providerType, region });

  return {
    providerType,
    contactOptions,
    minAmountAllowed,
    bankAccountId,
    dateDeposited,
    payorId,
    matterId,
    depositAmount,
    balanceAfterDeposit,
    clientIsCoveringFee,
    feeDetails,
    formattedPaymentProviderSettings,
    reason,
    formInitialised,
    isSubmitting,
    formDirty,
    formValid,
    // config
    showReason: hasFacet(facets.reasonField),
  };
};

const mapDispatchToProps = (
  dispatch,
  { defaultPayorId, providerSpecificChargeData, defaultMatterId, defaultBankAccountId, onChange },
) => {
  const {
    actions: formActions,
    operations: formOperations,
    selectors: formSelectors,
  } = withScopedFeature({ scope })(forms);

  const matterClientAddressRequiredForTrustDeposit = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);

  return {
    onLoad: () => {
      const fieldValues = {
        chargeId: uuid(),
        bankAccountId: defaultBankAccountId,
        dateDeposited: dateToInteger(today()),
        payorId: defaultPayorId,
        matterId: defaultMatterId,
        depositAmount: 0,
        reason: '',
      };

      dispatch(formActions.initialiseForm({ fieldValues }));

      const onUnload = () => {
        // clear form on unmount to conform with pattern used else where
        dispatch(formActions.clearForm());
      };
      return onUnload;
    },

    onFormFieldChange: ({
      key,
      value,
      providerType,
      clientIsCoveringFee,
      bankAccountId,
      formattedPaymentProviderSettings,
    }) => {
      if (key) {
        dispatch(formActions.updateFieldValues({ fieldValues: { [key]: value } }));
        dispatch(
          formOperations.validateForm({
            schema: creditCardDepositFormSchema,
            validateFn: getValidateFn({ matterClientAddressRequiredForTrustDeposit }),
          }),
        );
      }

      const { fieldValues } = formSelectors.getFormState(store.getState(), { fieldsAsValues: true });
      const desiredAmountInCents = fieldValues.depositAmount || 0;

      const feeSchedule = clientIsCoveringFee
        ? extractFeeSchedule({
            providerType,
            formattedProviderSpecificSettings: formattedPaymentProviderSettings,
            bankAccountId: bankAccountId?.value,
          })
        : undefined;

      const feeDetails =
        clientIsCoveringFee &&
        calculateFeeDetails({
          providerType,
          feeSchedule,
          desiredAmountInCents,
          providerSpecificFields: providerSpecificChargeData,
        });

      const chargeAmountInCents = clientIsCoveringFee ? feeDetails.effectiveAmountInCents : desiredAmountInCents;
      onChange({ ...fieldValues, depositAmount: chargeAmountInCents, amountLessFees: desiredAmountInCents });
    },

    onOpenEditContactModal: (clickedContactId) => {
      setModalDialogVisible({ modalId: CONTACT_CREATE_EDIT_MODAL_ID, props: { contactId: clickedContactId } });
    },
  };
};

export const CreditCardDepositFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ formValid, onReadyForSubmit, ...props }) => {
      const { t } = useTranslation();
      useEffect(() => {
        onReadyForSubmit(formValid);
      }, [formValid, onReadyForSubmit]);

      const { matterId, bankAccountId, formattedPaymentProviderSettings, providerType } = props;

      useEffect(() => {
        if (props.formattedPaymentProviderSettings) {
          handleFieldChange({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.feeDetails?.effectiveAmountInCents, formattedPaymentProviderSettings]);

      const handleFieldChange = ({ key, value }) =>
        props.onFormFieldChange({
          key,
          value,
          clientIsCoveringFee: props.clientIsCoveringFee,
          providerType,
          bankAccountId,
          formattedPaymentProviderSettings,
        });

      const [lastMatterId, setLastMatterId] = useState('');
      const matterIdValue = matterId?.value;
      // We want to keep list of bank accounts even when we clear the matter so we need to save last matter ID
      if (matterIdValue && matterIdValue !== lastMatterId) {
        setLastMatterId(matterIdValue);
      }

      const bankAccountOptions = getBankAccountOptions({ matterId: matterIdValue || lastMatterId, providerType, t });

      const onMatterSelected = (selectedMatter) => {
        handleFieldChange({ key: matterId.key, value: selectedMatter && selectedMatter.id });

        if (selectedMatter) {
          // handle case when selected bank account is not in the list for newly selected matter
          const bankAccountOptionsForNewMatter = getBankAccountOptions({
            matterId: selectedMatter?.id,
            providerType,
            t,
          });
          if (
            bankAccountOptionsForNewMatter.length &&
            bankAccountId?.value &&
            !bankAccountOptionsForNewMatter.find((ba) => ba.value === bankAccountId.value)
          ) {
            // select first option in case selected bank account is not in the list
            handleFieldChange({ key: bankAccountId.key, value: bankAccountOptionsForNewMatter?.[0]?.value });
          }
        }
      };

      return (
        <CreditCardDepositForm
          {...props}
          onFormFieldChange={handleFieldChange}
          onMatterSelected={onMatterSelected}
          bankAccountOptions={bankAccountOptions}
        />
      );
    }),
  ),
);

const getBankAccountOptions = ({ matterId, providerType, t }) => {
  let bankAccounts = [];

  if (!matterId) {
    return bankAccounts;
  }

  const isValidOption = (bankAccountId) =>
    providerType && isPaymentProviderEnabledForBankAccount({ bankAccountId, providerType });

  if (hasFacet(facets.operatingAccount)) {
    const operatingAccount = getOperatingAccount();
    bankAccounts = isValidOption(operatingAccount.id)
      ? [{ label: 'Operating Retainer', value: operatingAccount.id }]
      : [];
  }

  const activeTrustAccounts = filterTrustAccountsByMatter(matterId);

  const trustAccounts = activeTrustAccounts.reduce((acc, bankAccount) => {
    const bankAccountId = bankAccount?.id;
    if (!bankAccountId) {
      return acc;
    }

    if (isValidOption(bankAccountId)) {
      const label = getBankAccountName(bankAccount, t);
      acc.push({ label, value: bankAccountId });
    }
    return acc;
  }, []);

  return bankAccounts.concat(trustAccounts);
};

const getValidateFn =
  ({ matterClientAddressRequiredForTrustDeposit }) =>
  async (formFields) => {
    const formErrors = {};

    if (formFields.matterId) {
      const matter = getMatterById(formFields.matterId);
      formErrors.matterId = [];
      const clientsMissingAddresses = matterClientAddressRequiredForTrustDeposit
        ? await matterClientsMissingAddresses(matter)
        : [];

      if (matterClientAddressRequiredForTrustDeposit && clientsMissingAddresses && clientsMissingAddresses.length > 0) {
        formErrors.matterId = [
          ...formErrors.matterId,
          ...clientsMissingAddresses.map((client) => `contactId:${client.id}'s address is incomplete.`),
        ];
      }
      if (formErrors.matterId.length > 0) {
        formErrors.matterId = formErrors.matterId.join('\n');
      } else {
        delete formErrors.matterId;
      }
    } else {
      formErrors.matterId = true;
    }

    return formErrors || {};
  };

async function matterClientsMissingAddresses(matter) {
  const matterClients = matter.clientCustomerIds;
  const clientContactsWithMissingStreetAddress = await findContactsWithMissingStreetOrPOAddress({
    contactIds: matterClients,
    spreadGroupsOfPeople: true,
  });
  return clientContactsWithMissingStreetAddress;
}

CreditCardDepositFormContainer.displayName = 'CreditCardDepositFormContainer';

CreditCardDepositFormContainer.propTypes = {
  providerType: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  // for fee calculation.
  providerSpecificChargeData: PropTypes.object,
  // for pre-selection of fields
  defaultPayorId: PropTypes.string,
  defaultMatterId: PropTypes.string,
  defaultBankAccountId: PropTypes.string,
  clientIsCoveringFee: PropTypes.bool,
};

CreditCardDepositFormContainer.defaultProps = {
  // for pre-selection of fields
  defaultPayorId: undefined,
  defaultMatterId: undefined,
  defaultBankAccountId: undefined,
  clientIsCoveringFee: false,
  providerSpecificChargeData: undefined,
};
