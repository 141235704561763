'use strict';

/**
 * @typedef {'billingWebApp'|'paymentPortal'} ApplicationType
 */

// Applications need to provide their own styling to the hosted fields used within the EzyCollectPaymentForm.
// This sucks, but unfortunately there isn't a great way around it. We deal with it here as it seems like
// the most obvious and consistent place, the down sides are that it could be difficult to find this styling
// and we have granted application knowledge to business logic.
// See: https://stripe.com/docs/js/appendix/style?type=card
const hostedFieldStyles = {
  billingWebApp: {
    base: {
      color: 'rgb(0, 0, 0)',
      fontSize: '14px',
      fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    },
  },
  paymentPortal: {
    base: {
      color: 'rgb(85, 85, 85)',
      fontSize: '16px',
      fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    },
  },
};

/**
 * getPaymentFormConfiguration
 *
 * Returns the information required to display a payment form (i.e. charge UI) for EzyCollect.
 *
 * @param {Object} params
 * @param {Object} params.formattedProviderSpecificSettings Current EzyCollect settings
 * @param {Object} [params.providerSpecificFields] Provider specific fields
 * @param {ApplicationType} [params.providerSpecificFields.application] The application requesting the configuration. This allows provider specific
 *                                                                      implementations to optionally configure by UI differently by application.
 *
 * @returns {Object} The EzyCollect specific details.
 */
const getPaymentFormConfiguration = ({ formattedProviderSpecificSettings, providerSpecificFields }) => {
  const { application } = providerSpecificFields || {};

  return {
    publicKey: formattedProviderSpecificSettings.publicKey,
    hostedFieldsStyle: hostedFieldStyles[application] || hostedFieldStyles.billingWebApp,
    supportsFeeDetails: true,
  };
};

module.exports = {
  getPaymentFormConfiguration,
};
