import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { store } from '@sb-itops/redux';
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  $stateProvider
    .state('home.billing.accounts.transactions.operating-cheques', {
      url: '/operating-cheques',
      template: `<sb-transactions-operating-cheques></sb-transactions-operating-cheques>`,
      reloadOnSearch: false,
      params: {},
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'OPERATING',
        entity: 'CHEQUE',
      },
      onEnter: function() {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.operating-cheques', {}], parentPageName: PARENT_PAGE_NAME }));
      }, 
    });

});
