import React from 'react';

const LinkFilterButton = (props) => (
  <button type="button" className="link-filter-button" {...props}>
    {props.children}
  </button>
);

LinkFilterButton.displayName = 'LinkFilterButton';

export default LinkFilterButton;
