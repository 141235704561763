import { useState, useEffect } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useTranslation } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getRegion } from '@sb-itops/region';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { calculateDatesForPeriod, periods } from '@sb-itops/date';
import { fetchIntegrationHistory } from '@sb-billing/redux/integration-history';
import {
  buildIntegrationHistoryModel,
  getItemIdToResendFromCorrelation,
} from '@sb-billing/business-logic/integration-history';
import * as messageDisplay from '@sb-itops/message-display';
import { sortByProperty, debounce } from '@sb-itops/nodash';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import moment from 'moment';
import { BillingSupportAccountingTransactions } from './BillingSupportAccountingTransactions';

const hooks = () => ({
  useAccountingTransactions: () => {
    const { startDate, endDate } = calculateDatesForPeriod(periods.THIS_MONTH);
    const { t } = useTranslation();
    const [showFailedOnly, setShowFailedOnly] = useState(false);
    const [fromDate, setFromDate] = useState(startDate);
    const [toDate, setToDate] = useState(endDate);
    const [transactionsFull, setTransactionsFull] = useState([]);
    const [transactionsView, setTransactionsView] = useState([]);
    const [filterById, setFilterById] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [hasResentSingle, setHasResentSingle] = useState(false);
    const [selectedCorrelationIds, setSelectedCorrelationIds] = useState({});
    const [detailedTransaction, setShowDetail] = useState(undefined);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const onSelectChange = (rowData) => {
      // SelectedCorrelationIds = { uniqueCorrelationIdentifier: correlationObj }
      // Note uniqueCorrelationIdentifier !== correlationId, as we also need to look at integration name to differentiate unique transactions
      if (rowData.uniqueCorrelationIdentifier in selectedCorrelationIds) {
        const { [rowData.uniqueCorrelationIdentifier]: _, ...rest } = selectedCorrelationIds;
        setSelectedCorrelationIds(rest);
      } else {
        const newSelectedCorrelationIds = {
          ...selectedCorrelationIds,
          [rowData.uniqueCorrelationIdentifier]: rowData,
        };
        setSelectedCorrelationIds(newSelectedCorrelationIds);
      }
    };

    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: 'integration-history-sort',
      initialSortBy: 'lastUpdated',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    const region = getRegion();
    const dateFormat = t('DD/MM/YYYY');

    const toggleShowFailed = () => {
      setShowFailedOnly(!showFailedOnly);
    };

    const fetchTransactions = async () => {
      setDataLoading(true);
      const rawIntegrationHistory = await fetchIntegrationHistory();
      const integrationHistoryModel = buildIntegrationHistoryModel(rawIntegrationHistory, moment);
      setTransactionsFull(integrationHistoryModel);
      setDataLoading(false);
    };

    useEffect(() => {
      const filteredTransactions = filterTransactions({
        transactions: transactionsFull,
        toDate,
        fromDate,
        filterById,
        sortBy,
        sortDirection,
        showFailedOnly,
      });
      setTransactionsView(filteredTransactions);
    }, [transactionsFull, toDate, fromDate, filterById, sortBy, sortDirection, showFailedOnly]);

    const onResend = async ({ correlation, isBatch }) => {
      setIsResending(true);
      const itemId = getItemIdToResendFromCorrelation(correlation);
      try {
        if (itemId) {
          await dispatchCommand({
            type: 'Billing.Integrations.Messages.ResendIntegrationMessageRequest',
            message: { integrationHistoryId: itemId },
          });
          if (!isBatch) {
            messageDisplay.info('Resending message... refresh in a few seconds to check status');
            setIsResending(false);
            setHasResentSingle(true);
          }
        }
      } catch (e) {
        if (!isBatch) {
          messageDisplay.error('Error resending message, please escalate');
          setIsResending(false);
        }
      }
    };

    const batchResend = async () => {
      setIsResending(true);
      const transactionsToResend = Object.values(selectedCorrelationIds);
      try {
        const transactionPromises = transactionsToResend.map((transaction) =>
          onResend({ correlation: transaction, isBatch: true }),
        );
        await Promise.all(transactionPromises);
        messageDisplay.info('Resending messages... refresh in a few seconds to check status');
        setIsResending(false);
        // reset selected checkboxes on batch resend
        setSelectedCorrelationIds({});
        setSelectAllChecked(false);
      } catch (e) {
        messageDisplay.error('Error resending message, please escalate');
        setIsResending(false);
      }
    };

    return {
      transactions: transactionsView,
      dataLoading,
      isResending,
      hasResentSingle,
      filterById,
      selectAllChecked,
      showFailedOnly,
      fromDate,
      toDate,
      format: dateFormat,
      region,
      detailedTransaction,
      selectedCorrelationIds,
      sortBy,
      sortDirection,
      onSort,
      fetchFullData: debounce(() => fetchTransactions()),
      setShowDetail,
      setHasResentSingle,
      setFilterById: debounce((term) => setFilterById(term)),
      toggleShowFailed,
      onSelectChange,
      onSelectAllChange: () => {
        if (!selectAllChecked) {
          const allViewTransactionsSelected = transactionsView.reduce((acc, transaction) => {
            if (transaction.isFailed) {
              return {
                ...acc,
                [transaction.uniqueCorrelationIdentifier]: transaction,
              };
            }
            return acc;
          }, {});
          const newSelectedCorrelationIds = {
            ...allViewTransactionsSelected,
          };
          setSelectedCorrelationIds(newSelectedCorrelationIds);
          setSelectAllChecked(true);
        } else {
          setSelectedCorrelationIds({});
          setSelectAllChecked(false);
        }
      },
      onStartDateSelect: setFromDate,
      onEndDateSelect: setToDate,
      onResend,
      batchResend,
    };
  },
});

const checkAdditionalIdsForCorrelation = (correlation, filterPhrase) =>
  correlation.groups.some((group) =>
    group.items.some((item) => {
      if (item.id.includes(filterPhrase)) {
        return true;
      }
      if (item.additionalIds && item.additionalIds.length) {
        return item.additionalIds.some((additionalId) => additionalId?.id?.includes(filterPhrase));
      }
      return false;
    }),
  );

const filterTransactions = ({ transactions, toDate, fromDate, filterById, sortBy, sortDirection, showFailedOnly }) => {
  const filteredTransactions = transactions.filter((transaction) => {
    if (
      !transaction.lastUpdated ||
      transaction.lastUpdated < moment(fromDate).unix() ||
      transaction.lastUpdated > moment(toDate).unix()
    ) {
      return false;
    }

    if (showFailedOnly) {
      if (!transaction.isFailed) {
        return false;
      }
    }

    if (filterById) {
      return (
        transaction.correlationId.includes(filterById) || checkAdditionalIdsForCorrelation(transaction, filterById)
      );
    }
    return true;
  });

  const sortedTransactions = sortByProperty(filteredTransactions, sortBy, sortDirection, false, 'a');
  return sortedTransactions;
};

export const BillingSupportAccountingTransactionsContainer = withReduxProvider(
  composeHooks(hooks)(BillingSupportAccountingTransactions),
);

BillingSupportAccountingTransactionsContainer.displayName = 'BillingSupportAccountingTransactionsContainer';

BillingSupportAccountingTransactionsContainer.propTypes = {};
BillingSupportAccountingTransactionsContainer.defaultProps = {};
