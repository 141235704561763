angular.module('sb.billing.webapp').component('sbEditIcon', {
  templateUrl: 'ng-components/edit-icon/edit-icon.html',
  controller: function (sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbEditIcon');
    
    ctrl.$onInit = () => {
      log.info(`uiSref: ${ctrl.uiSref}`);
    }
  }
});