import { EXPENSE_PSEUDO_STATUS } from '@sb-billing/business-logic/expense/entities/expense-pseudo-status';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { today, integerToDate } from '@sb-itops/date';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'expense-payment-details',
  keyPath: 'id',
  ngCacheName: 'sbExpensePaymentDetailsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbExpensePaymentDetailsService',
  keyPath: 'id',
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated, clearCache } = api;
export { opdateCache, rollbackOpdateCache };

export const getPseudoStatusById = (expenseId) => {
  const paymentDetails = getById(expenseId);

  let status = '';

  if (!paymentDetails) {
    return status;
  }

  if (!paymentDetails.isPaid) {
    if (integerToDate(paymentDetails.paymentDue) < today()) {
      status = EXPENSE_PSEUDO_STATUS.OVERDUE;
    } else {
      status = EXPENSE_PSEUDO_STATUS.UNPAID;
    }
  } else {
    status = EXPENSE_PSEUDO_STATUS.PAID;
  }

  return status;
};
