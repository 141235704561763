import { gql } from '@apollo/client';

const query = gql`
  query DashboardTotalsData {
    firmBalancesSummary {
      credit
      controlledMoney
      trust
      operating
      unbilled
      unpaidExcInterest
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const DashboardTotalsData = {
  query,
  notificationIds,
};
