import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { ALL_STATES } from '@sb-itops/region';

export const getDefaultAutofillTrustAccount = (defaultTrustAccounts, activeTrustAccounts) => {
  // If they have any default trust accounts selected in settings
  if (defaultTrustAccounts.length > 0) {
    // In which case, get the ALL_STATES account if they have one, else get the first default account
    const defaultAutofillAccountId = defaultTrustAccounts.find(
      (account) => account.location === ALL_STATES.value,
    )?.defaultTrustAccountId;
    if (defaultAutofillAccountId) {
      return getBankAccountById(defaultAutofillAccountId);
    }
    return getBankAccountById(defaultTrustAccounts[0]?.defaultTrustAccountId);
  }

  // If no default trust accounts selected, get the first active trust account with an ALL_STATES location, otherwise just the first one
  if (activeTrustAccounts.length > 0) {
    return activeTrustAccounts.find((account) => account.location === ALL_STATES.value) || activeTrustAccounts[0];
  }

  // No active trust accounts, don't autofill
  return {};
};
