import { matterContactBalanceAutoAllocator } from '@sb-billing/allocate-invoice-payment';

const applyAutoPayments = (
  invoiceTotals,
  trustAllocations,
  operatingAllocations,
  creditAllocations,
  preferredBankAccountTypes,
) => {
  const { payments } = matterContactBalanceAutoAllocator(
    invoiceTotals,
    trustAllocations.concat(operatingAllocations).concat(creditAllocations),
    preferredBankAccountTypes,
  );

  const trust = trustAllocations.map((allocation) => ({
    ...allocation,
    amount: payments[`trust_${allocation.contactId}`].amount || 0,
  }));

  const operating = operatingAllocations.map((allocation) => ({
    ...allocation,
    amount: payments[`operating_${allocation.contactId}`].amount || 0,
  }));

  const credit = creditAllocations.map((allocation) => ({
    ...allocation,
    amount: payments[`credit_${allocation.contactId}`].amount || 0,
  }));

  return {
    trust,
    operating,
    credit,
  };
};

export { applyAutoPayments };
