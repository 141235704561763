const { sortByOrder, isFunction } = require('lodash');

const sortDirections = {
  ASC: 'asc',
  DESC: 'desc'
}

module.exports = {
  sort,
  sortDirections
};

// Apologies the JsDoc for this file arent super helpful - was taking too long to figure out
// how to get it to work

/**
 * Sorts the `entities` by the `iterees`, according to the specified `orders`.
 * 
 * `iterees` can be an array of strings or functions. In case of a function, the function will
 * be passed an entity and should return the property value (value, NOT property name).
 * @param {[*]} entities Array of objects that will get sorted
 * @param {Array<string>} iterees Array of properties to sort the entities.
 * @param {Array<'ASC' | 'DESC'|'asc'|'desc'>} orders Array of sort orders to sort the entities. 'ASC' or 'DESC'.
 */
function sort (entities, iterees, orders) {
  return sortByOrder(entities, getIterees(iterees), getOrders(orders));
}

function getIterees (iterees) {
  return iterees.map((iteree) => getSortIteree(iteree));
}

function getOrders (orders) {
  return orders ? orders.map((order) => order.toLowerCase()) : undefined;
}

function getSortIteree (iteree) {
  return (entity) => {
    const field = isFunction(iteree) ? iteree(entity) : entity[iteree];

    return typeof field === 'string' ? field.toLowerCase() : field;
  };
}