import React from 'react';
import { CurrencyInput2, forms2PropTypes } from '@sb-itops/react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const Amount = ({ amount, onAmountChange, hasError }) => (
  <div className={classnames('col-sm-6', 'form-group')}>
    <label>Amount</label>
    <CurrencyInput2
      name="amount"
      value={amount?.value || 0}
      hasError={hasError}
      onChange={(e) => onAmountChange(e.target.value)}
    />
  </div>
);

Amount.displayName = 'AvailableBalances';
const { Forms2Field } = forms2PropTypes;
Amount.propTypes = {
  amount: Forms2Field,
  onAmountChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

Amount.defaultProps = {
  amount: undefined,
  hasError: false,
};
