'use strict';

/**
 * Check resourceId is authorised in given authResults
 * @param {object} params
 * @param {AccessAuthorisation[]} params.accessAuthorisations
 * @param {string} params.resourceId
 * @returns {boolean} boolean indicating if resourceId is authorised
 */
function checkAccessToResourceIsAuthorised({ accessAuthorisations, resourceId }) {
  const resourceAuthorisation = accessAuthorisations.find(
    (accessAuthorisation) => accessAuthorisation.resourceId === resourceId,
  );
  return !!(resourceAuthorisation && resourceAuthorisation.isAuthorized);
}

module.exports = {
  checkAccessToResourceIsAuthorised,
};
