import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabList } from '@sb-itops/react/tab-list';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { LawpayCreditCardFormContainer } from '../lawpay-credit-card-form';
import { LawpayElectronicChequeFormContainer } from '../lawpay-electronic-cheque-form';
import Styles from './LawpayPaymentForm.module.scss';

export const LawpayPaymentForm = ({ scope, tabs, selectedTab, onChangeSelectedTab, onClearSelectedTab, ...props }) => {
  useEffect(() => {
    // This is used by the Credit Card deposit modal
    // The user can switch between Trust and Operating retainer accounts
    // and one may have a payment method enabled while the other might not
    // We clear the selection if the payment method doesn't exist to force
    // selection of the first available tab
    if (tabs && tabs.length && !tabs.some((tab) => tab.id === selectedTab)) {
      onClearSelectedTab();
    }
  }, [tabs, selectedTab, onClearSelectedTab]);

  // If there are no applicable forms to show, show error message and exit
  // Possible cause: no bank account set up for the payment method
  if (!tabs || tabs.length === 0) {
    return <div>Cannot load the payment form</div>;
  }

  return (
    <div className="lawpay-payment-form">
      {tabs.length > 1 && <TabList list={tabs} onTabSelectionChange={onChangeSelectedTab} className={Styles.tabList} />}

      {selectedTab === paymentMethods.CREDIT_CARD && (
        <LawpayCreditCardFormContainer
          className={Styles.lawpayPaymentFormInner}
          scope={`${scope}-credit-card`}
          {...props}
        />
      )}

      {selectedTab === paymentMethods.ECHEQUE && (
        <LawpayElectronicChequeFormContainer
          className={Styles.lawpayPaymentFormInner}
          scope={`${scope}-echeque`}
          {...props}
        />
      )}
    </div>
  );
};

LawpayPaymentForm.displayName = 'LawpayPaymentForm';

LawpayPaymentForm.propTypes = {
  scope: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  onChangeSelectedTab: PropTypes.func.isRequired,
  onClearSelectedTab: PropTypes.func.isRequired,
  publicKey: PropTypes.string.isRequired,
  hostedFieldsStyle: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

LawpayPaymentForm.defaultProps = {};
