'use strict';

const { calculateSurcharge } = require('./calculate-surcharge');
const { defaultSurchargeSettings } = require('./default-surcharge-settings');
const { determineSurchargeSettings } = require('./determine-surcharge-settings');
const { minimumSurchargeValues } = require('./minimum-surcharge-values');
const { isSurchargeValid } = require('./is-surcharge-valid');

const { surchargeApplyToLabels, surchargeApplyToValues, surchargeApplyToOptions } = require('./surcharge-apply-to');

const { surchargeTypeLabels, surchargeTypeValues, surchargeTypeOptions } = require('./surcharge-types');

module.exports = {
  defaultSurchargeSettings,
  minimumSurchargeValues,
  surchargeApplyToLabels,
  surchargeApplyToOptions,
  surchargeApplyToValues,
  surchargeTypeLabels,
  surchargeTypeOptions,
  surchargeTypeValues,
  // Functions
  calculateSurcharge,
  determineSurchargeSettings,
  isSurchargeValid,
};
