'use strict';

const { todayAsInteger: getTodayAsInteger } = require('@sb-itops/date');

/**
 * @typedef {object} findCurrentAndFutureVersionsResult
 * @property {RateSetVersion} currentVersion
 * @property {RateSetVersion} futureVersion
 */

/**
 * Find current and future version, this assumes versions has already been sorted from oldest to newest
 * The UI is only interested in the current version and the next version, if there is one.
 * @param {RateSet} rateSet
 * @param {number} todayAsInteger today as an integer YYYYMMDD
 * @returns {findCurrentAndFutureVersionsResult} object containing currentVersion and futureVersion
 */
const findCurrentAndFutureVersions = ({ rateSet, todayAsInteger = getTodayAsInteger() }) => {
  if (!rateSet) {
    throw new Error('rateSet is required');
  }

  if (!todayAsInteger || !(todayAsInteger > 19000101)) {
    throw new Error('todayAsInteger is required and must be a valid date expressed as an integer YYYYMMDD');
  }

  if (!rateSet.versions || rateSet.versions.length === 0) {
    return { currentVersion: undefined, futureVersion: undefined };
  }

  const { currentVersion, futureVersion } = rateSet.versions.reduce((acc, val, index) => {
    // keep searching only if not found, otherwise skip
    if (!acc.currentVersion) {
      // validTo of null means it's the last version defined
      if (val.validFrom <= todayAsInteger && (val.validTo === null || val.validTo >= todayAsInteger)) {
        acc.currentVersion = val;
        // validTo only has a value when there are future versions
        if (val.validTo && index < rateSet.versions.length - 1) {
          acc.futureVersion = rateSet.versions[index + 1];
        }
      }
    }
    return acc;
  }, {});
  return { currentVersion, futureVersion };
};

module.exports = {
  findCurrentAndFutureVersions,
};
