import React from 'react';
import PropTypes from 'prop-types';
import { PercentageInput } from '@sb-itops/react/percentage-input';

function basisPointsToPercent(basisPoints, maxNbDecimals = 2) {
  if (Number.isInteger(basisPoints)) {
    return (basisPoints / 100).toFixed(maxNbDecimals);
  }
  return undefined;
}

export const BasisPointInput = ({
  name,
  basisPoints,
  maxBasisPoints,
  placeholder,
  disabled,
  hasError,
  onChange,
  showPercent,
  isPerAnnum,
}) => {
  const percent = Number.isFinite(basisPoints) && basisPoints >= 0 ? basisPoints / 100 : undefined;
  const maxPercent = basisPointsToPercent(maxBasisPoints);
  const onFocus = (event) => {
    event.target.select();
  };
  const onPercentageChange = (newPercent) => {
    const newBasisPoints = Number.isFinite(newPercent) && newPercent >= 0 ? Math.round(newPercent * 100) : undefined;
    onChange(newBasisPoints);
  };

  return (
    <PercentageInput
      name={name}
      percent={percent}
      maxPercent={maxPercent}
      placeholder={placeholder}
      disabled={disabled}
      hasError={hasError}
      showPercent={showPercent}
      isPercentPerAnnum={isPerAnnum}
      onFocus={onFocus}
      onChange={onPercentageChange}
    />
  );
};

BasisPointInput.displayName = 'BasisPointInput';

BasisPointInput.propTypes = {
  name: PropTypes.string,
  basisPoints: PropTypes.number,
  maxBasisPoints: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  showPercent: PropTypes.bool,
  isPerAnnum: PropTypes.bool, // this is only required because percentages are stored in basis points in .net backend in all instances except for discounts
  // callbacks
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

BasisPointInput.defaultProps = {
  name: undefined,
  basisPoints: undefined,
  maxBasisPoints: undefined,
  placeholder: undefined,
  disabled: false,
  hasError: false,
  showPercent: true,
  isPerAnnum: true,
  onBlur: () => {},
};
