/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Check, Pause, Play, Trash2, Timer } from 'web/components/icons';
import moment from 'moment';
import { Button, Tooltip } from '@sb-itops/react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';
import { MatterTypeahead2 } from '@sb-matter-management/react';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import Styles from './Timers.module.scss';

// see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
const useOutsideAlerter = (refs, setShow) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!refs.some((ref) => ref.current && ref.current.contains(event.target))) {
        setShow('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [refs, setShow]);
};

export const Clock = ({ timer, showDescription = false }) => {
  function drawClock() {
    if (!timer) {
      return [0, 0, 0];
    }
    const s = timer.elapsedSeconds + (timer.activeSinceUnix ? moment().unix() - timer.activeSinceUnix : 0);

    return [Math.floor(s / 3600), Math.floor((s % 3600) / 60), Math.floor(s % 60)];
  }
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const [hours, minutes, seconds] = drawClock();

  useEffect(() => {
    if (!timer?.active) return () => {};
    let interval;
    setTimeout(() => {
      interval = setInterval(() => forceUpdate(), 1000);
    }, 900 - new Date().getMilliseconds());
    return () => clearInterval(interval);
  }, [timer]);

  const clientNames = showDescription && timer?.matter?.clientNames ? `${timer?.matter?.clientNames} - ` : '';
  const description = showDescription && !clientNames && timer?.description ? `${timer?.description} - ` : '';
  return timer ? (
    <span>
      <span>{`${clientNames || description}${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0',
      )}:${String(seconds).padStart(2, '0')}`}</span>
    </span>
  ) : null;
};

const OnClickOutsideHOC = ({ children, setEditingMatterId }) => {
  const timerRef = useRef(null);
  useOutsideAlerter([timerRef], setEditingMatterId);

  return <div ref={timerRef}>{children}</div>;
};

export const Timers = ({
  feeChartData,
  feeChartDomain,
  pauseTimer,
  resumeTimer,
  deleteTimer,
  setDescription,
  setMatter,
  createEntry,
  createTimer,
  timers,
  expanded,
  matterSummariesDataLoading,
  onFetchMoreMatterSummaries,
  matterSummariesHasMore,
  onFetchMatterSummaries,
  matterSummaries,
  loadingMatters,
  defaultMatterSummaries,
  activeTimer,
  toggleExpanded,
}) => {
  const [editingDescriptionId, setEditingDescriptionId] = useState('');
  const [editingMatterId, setEditingMatterId] = useState('');

  return (
    <>
      <div className={classNames(Styles.clock, expanded && Styles.active)} onClick={toggleExpanded}>
        <Clock showDescription timer={activeTimer} />
        {!activeTimer && 'Create/View Timers'}
        <Timer />
      </div>
      {expanded && (
        <div className={classNames(Styles.container, 'popover bottom bottom-right')}>
          <div className={classNames('arrow', Styles.arrow)} />
          <div className={Styles.hours}>
            <div className={Styles.hoursTitle}>Today&apos;s hours</div>
            <div className={Styles.hoursSumm}>
              <div className={Styles.billSumm}>{feeChartData[0]?.Billable || 0}</div>
              <div className={Styles.nonbillSumm}>{feeChartData[0]?.['Non-Billable'] || 0}</div>
            </div>
            <ResponsiveContainer width="100%" height={65}>
              <BarChart layout="vertical" data={feeChartData}>
                <XAxis domain={feeChartDomain} type="number" hide />
                <YAxis type="category" hide />
                <Legend height={22} align="left" iconType="square" />
                <Bar dataKey="Billable" fill="#4BE9A7" stackId="a" />
                <Bar dataKey="Non-Billable" fill="#DDE0E9" stackId="a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className={Styles.timers}>
            <div className={Styles.timerContainer}>
              {timers.map((timer) => (
                <div key={timer.timerId} className={classNames(Styles.timerInstance, timer.active && Styles.active)}>
                  {editingDescriptionId !== timer.timerId && (
                    <div
                      onClick={() => setEditingDescriptionId(timer.timerId)}
                      className={classNames(Styles.subject, !timer.description && Styles.placeholderText)}
                    >
                      {timer.description || 'Add a description'}
                    </div>
                  )}
                  {editingDescriptionId === timer.timerId && (
                    <input
                      defaultValue={timer.description}
                      autoFocus
                      className={classNames(Styles.subject)}
                      onBlur={(event) => {
                        setEditingDescriptionId('');
                        setDescription(timer.timerId, event.target.value);
                      }}
                    />
                  )}
                  {editingMatterId !== timer.timerId && (
                    <div
                      onClick={() => !loadingMatters && setEditingMatterId(timer.timerId)}
                      className={classNames(Styles.description, !timer.matterId && Styles.placeholderText)}
                    >
                      {loadingMatters && 'Loading...'}
                      {!loadingMatters &&
                        timer.matterId &&
                        getMatterDisplay(timer.matter, timer.matter?.matterType?.name)}
                      {!loadingMatters && !timer.matterId && 'Select a matter'}
                    </div>
                  )}
                  {editingMatterId === timer.timerId && (
                    <OnClickOutsideHOC setEditingMatterId={setEditingMatterId}>
                      <MatterTypeahead2
                        defaultMatters={defaultMatterSummaries}
                        isLoading={matterSummariesDataLoading}
                        matters={matterSummaries}
                        containerClassName={classNames(Styles.typeahead)}
                        maxMenuHeight={250} // Default is 300 and was making the dropdown overflow
                        onInputChange={onFetchMatterSummaries}
                        onLoadMore={onFetchMoreMatterSummaries}
                        onMatterSelected={(matter) => {
                          setEditingMatterId('');
                          setMatter(timer.timerId, matter?.data);
                        }}
                        placeholder="Select a matter..."
                        selectedMatterId={timer.matterId}
                        actionList={
                          matterSummariesHasMore === true
                            ? [
                                {
                                  displayComponent: (
                                    <span>
                                      <i className="fa fa-plus" /> &emsp;Show more results
                                    </span>
                                  ),
                                  callback: () => {
                                    if (matterSummariesDataLoading) {
                                      return;
                                    }

                                    onFetchMoreMatterSummaries();
                                  },
                                },
                              ]
                            : []
                        }
                      />
                    </OnClickOutsideHOC>
                  )}
                  <div className={Styles.time}>
                    {timer.active ? (
                      <Tooltip title="Stop" interactive={false} delay={100}>
                        <span
                          onClick={() => {
                            pauseTimer(timer.timerId);
                          }}
                          className={classNames(Styles.icon, Styles.pause)}
                        >
                          <Pause />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Start" interactive={false} delay={100}>
                        <span
                          onClick={() => {
                            resumeTimer(timer.timerId);
                          }}
                          className={Styles.icon}
                        >
                          <Play />
                        </span>
                      </Tooltip>
                    )}
                    <div className={Styles.timeDisplay}>
                      <Clock timer={{ ...timer }} />
                    </div>

                    {/* Complete Timer */}
                    <Tooltip title="Complete" interactive={false} delay={100}>
                      <span onClick={() => createEntry(timer.timerId)} className={Styles.operations}>
                        <Check />
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete" interactive={false} delay={100}>
                      <span
                        onClick={() => deleteTimer(timer.timerId)}
                        className={classNames(Styles.operations, Styles.trash)}
                      >
                        <Trash2 />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Button type="secondary" className={Styles.createTimer} onClick={createTimer}>
            Create Timer
          </Button>
        </div>
      )}
    </>
  );
};
