'use strict';

const { isPayButtonEnabledForMatter } = require('../matters/invoice-settings/is-pay-button-enabled-for-matter');

/**
 * This function will identify in a given list of matters if:
 *  1. Any matter has ENABLED pay buttons
 *  2. Any matter has DISABLED pay buttons
 *
 * @param {object} params
 * @param {object[]} params.matters
 * @param {boolean} params.firmCanIncludePaymentLinks
 * @returns {{
 *  anyMatterHasPayButtonEnabled: {boolean},
 *  anyMatterHasPayButtonDisabled: {boolean},
 * }}
 */
function getPayButtonOptionForMatters({ matters, firmCanIncludePaymentLinks }) {
  const payButtonSettingsForMatters = matters.reduce(
    (acc, matter) => {
      const { matterInvoiceSettings } = matter;

      // If matterInvoiceSettings is null, default to firm settings
      const isPayButtonEnabled = matterInvoiceSettings
        ? isPayButtonEnabledForMatter({ matterInvoiceSettings })
        : firmCanIncludePaymentLinks;

      if (isPayButtonEnabled) {
        acc.anyMatterHasPayButtonEnabled = true;
      } else {
        acc.anyMatterHasPayButtonDisabled = true;
      }

      return acc;
    },
    {
      anyMatterHasPayButtonEnabled: false,
      anyMatterHasPayButtonDisabled: false,
    },
  );

  return payButtonSettingsForMatters;
}

module.exports = {
  getPayButtonOptionForMatters,
};
