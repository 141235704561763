import { useRef, useEffect } from 'react';
import * as applicationState from 'web/redux/features/application-state';
import { store } from '@sb-itops/redux';

/**
 * Subscribes to redux store events to serve as a drop in replacement for the angular event system while we transition away from angular
 *
 * This should be relatively easy to remove once all the angular events are stripped out
 *
 * LIMITATIONS:
 * Unsubscribes the handler after firing the callback as this is designed for use with the 'tab close' events.
 * Since tabs can be closed while the component is not rendered, we wish to unload this listener only after the tab is closed.
 * Will register multiple times if the component is mounted/unmounted/mounted, but in my testing the callback was only ever fired once.
 *
 * @param type {string}
 * @param effect {function}
 * @param deps
 */
export function useReduxActionOnce(type, effect) {
  const currentValue = useRef(null);
  const unsubscribe = useRef(null);

  const handleChange = () => {
    const state = store.getState();
    const action = applicationState.selectors.getLastAction(state);
    const previousValue = currentValue.current;
    currentValue.current = action.type;

    if (previousValue !== action.type && type === action.type) {
      let payloads = action.payload;
      if (!Array.isArray(payloads)) {
        payloads = payloads.entities ? Object.values(payloads.entities) : [payloads];
      }
      effect(payloads);
      // unsubscribe after firing the callback
      unsubscribe.current();
    }
  };

  useEffect(() => {
    // Register handler if not already registered
    if (!unsubscribe.current) {
      unsubscribe.current = store.subscribe(handleChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
