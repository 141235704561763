'use strict';

const indicatorStatuses = Object.freeze({
  EMAIL_ERROR: 'EMAIL_ERROR',
  COMMUNICATE_ERROR: 'COMMUNICATE_ERROR',
  NOT_SENT: 'NOT_SENT',
  IN_PROGRESS: 'IN_PROGRESS',
  EMAIL_PARTIALLY_SENT: 'EMAIL_PARTIALLY_SENT',
  COMMUNICATE_PARTIALLY_SENT: 'COMMUNICATE_PARTIALLY_SENT',
  EMAIL_SUCCESS: 'EMAIL_SUCCESS',
  COMMUNICATE_SUCCESS: 'COMMUNICATE_SUCCESS',
});

module.exports = { indicatorStatuses };
