'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const ledesTimekeeperClassificationTypeValues = Object.freeze({
  USE_6_CHARACTER_CODES: 0,
  USE_2_CHARACTER_CODES: 1,
});

const ledesTimekeeperClassificationTypeLabels = Object.freeze({
  USE_6_CHARACTER_CODES: '6 Character Codes',
  USE_2_CHARACTER_CODES: '2 Character Codes',
});

const ledesTimekeeperClassificationTypeOptions = getTypeAsOptions({
  typeLabels: ledesTimekeeperClassificationTypeLabels,
  typeValues: ledesTimekeeperClassificationTypeValues,
});

module.exports = {
  ledesTimekeeperClassificationTypeLabels,
  ledesTimekeeperClassificationTypeValues,
  ledesTimekeeperClassificationTypeOptions,
};
