'use strict';

const uuid = require('@sb-itops/uuid');
const { simpleFetch } = require('@sb-itops/fetch-wrapper');
const { AuthenticationResultFactory } = require('./authentication-result-factory');
const { UserIdentityFactory } = require('./user-identity-factory');
const { SbBillingAuthProviderChallengeAnswerFactory } = require('./sb-billing-auth-provider-challenge-answer-factory');
const { ChallengeResponseError } = require('../entities/custom-errors');

/**
 * @typedef { import('../entities/types.js').AuthenticationResult } AuthenticationResult
 * @typedef { import('../entities/types.js').AuthenticationChallenge } AuthenticationChallenge
 * @typedef { import('../entities/types.js').UserIdentity } UserIdentity
 * @typedef { import('../entities/types.js').SbBillingAuthProviderResult } SbBillingAuthProviderResult
 * @typedef { import('../entities/types.js').SbBillingAuthProviderIdentity } SbBillingAuthProviderIdentity
 */

const PROVIDER_ID = 'sbBillingAuthProvider';

const urlPaths = {
  LOGIN: '/authentication/login',
  RESPOND_TO_CHALLENGE: '/authentication/respond-to-challenge',
  REFRESH_TOKEN: '/authentication/refresh',
  LOGOUT: '/authentication/logout',
  LOGOUT_ALL: '/authentication/logout-all',
  SUBSCRIPTION_DETAILS: '/sign-up/customer-details',
};

/**
 * getDefaultHeaders
 *
 * Returns the standard set of headers that are required for calls to the SbBillingAuthProvider.
 *
 * @param {Object} idToken The token to be used as the bearer in the Authorization header.
 * @return {Object} Object containing default HTTP header key/value pairs.
 */
const getDefaultHeaders = ({ idToken } = {}) => ({
  Authorization: idToken ? `Bearer ${idToken}` : undefined,
  'x-correlation-id': uuid(),
  'Content-Type': 'application/json;charset=utf-8',
});

/**
 * fetch
 *
 * Simple wrapper around fetch to throw an exception if this service is being used in a non-browser environment.
 * This service could be updated to work in non-browser envs with a fetch polyfill or non-window based fetch.
 *
 * @param {Object} idToken The token to be used as the bearer in the Authorization header.
 * @return {Object} Object containing default HTTP header key/value pairs.
 */
const fetch = ({ url, method, headers, body, throwOnFailure = true }) => {
  // eslint-disable-next-line no-undef
  if (!window) {
    throw new Error('SbBillingAuthProviderClient only supports browser usage at this time');
  }

  // eslint-disable-next-line no-undef
  return simpleFetch({ fetch: window.fetch, url, method, headers, body, throwOnFailure });
};

/**
 * loginP
 *
 * Performs a login against a SbBillingAuthProvider.
 *
 * @param {Object} params
 * @param {string} params.authServiceUrl The location of the SbBillingAuthProvider, aka auth service lambda.
 * @param {string} params.username The username of the user to be authenticated.
 * @param {string} params.password The password for the user to be authenticated.
 * @param {Object} [params.headers] Any custom headers to be sent in the login HTTP request.
 *
 * @return {Promise<AuthenticationResult>}
 */
const loginP = async ({ authServiceUrl, username, password, headers = {} }) => {
  const loginUrl = `${authServiceUrl}${urlPaths.LOGIN}`;

  const loginResponse = await fetch({
    url: loginUrl,
    method: 'POST',
    headers: { ...getDefaultHeaders(), ...headers },
    body: { email: username, password },
    throwOnFailure: false,
  });

  if (loginResponse.success) {
    /** @type SbBillingAuthProviderResult */
    const sbBillingAuthProviderResult = loginResponse.body;
    return AuthenticationResultFactory.fromSbBillingAuthenticationProviderResult(sbBillingAuthProviderResult);
  }

  // Throw a general error indicating a failure due to reason other than
  throw new Error('Failed to login');
};

/**
 * respondToChallengeP
 *
 * Performs a challenge response against a SbBillingAuthProvider.
 *
 * @param {Object} params
 * @param {string} params.serviceUrl The location of the SbBillingAuthProvider.
 * @param {AuthenticationChallenge} params.challenge The challenge that was issued
 * @param {Object} params.solution The answer to the challenge that was issued.
 * @param {Object} [params.headers] Any custom headers to be sent in the login HTTP request.
 *
 * @return {Promise<AuthenticationResult>}
 */
const respondToChallengeP = async ({ serviceUrl, challenge, solution, headers = {} }) => {
  const url = `${serviceUrl}${urlPaths.RESPOND_TO_CHALLENGE}`;
  const challengeAnswer = SbBillingAuthProviderChallengeAnswerFactory.fromChallengeAndSolution(challenge, solution);

  const response = await fetch({
    url,
    method: 'POST',
    headers: { ...getDefaultHeaders(), ...headers },
    body: challengeAnswer,
    throwOnFailure: false,
  });

  if (!response.success) {
    const msg = `Failed to respond to challenge: statusCode: ${response.status}, statusText: ${response.statusText}}`;
    const errorType = response?.body?.error?.type || 'Error';
    throw new ChallengeResponseError(msg, errorType);
  }

  return AuthenticationResultFactory.fromSbBillingAuthenticationProviderResult(response.body);
};

/**
 * refreshTokenP
 *
 * Performs a token refresh for a UserIdentity created via a SbBillingAuthProvider.
 * It is expected that the refresh token is present in HTTP cookies (managed by the SbBillingAuthProvider)
 *
 * @param {Object} params
 * @param {string} params.serviceUrl The location of the SbBillingAuthProvider.
 * @param {UserIdentity} params.userIdentity A user identity issued by a SbBillingAuthProvider.
 * @param {Object} [params.headers] Any custom headers to be sent in the refresh token HTTP request.
 *
 * @return {Promise<UserIdentity>} An updated UserIdentity object.
 */
const refreshTokenP = async ({ serviceUrl, userIdentity, headers }) => {
  if (userIdentity.provider !== PROVIDER_ID) {
    throw new Error('refreshTokenP is compatible only with UserIdentities originating from a SbBillingAuthProvider');
  }

  const url = `${serviceUrl}${urlPaths.REFRESH_TOKEN}`;

  /** @type SbBillingAuthProviderIdentity */
  const sbBillingAuthProviderIdentity = userIdentity.custom;

  const response = await fetch({
    url,
    method: 'POST',
    headers: { ...getDefaultHeaders(), ...headers },
    body: { userId: userIdentity.userId, deviceKey: sbBillingAuthProviderIdentity.deviceKey },
  });

  return UserIdentityFactory.fromSbBillingAuthenticationProviderIdentity(response.body.message);
};

/**
 * logoutP
 *
 * Performs a logout on a UserIdentity created via a SbBillingAuthProvider.
 *
 * @param {Object} params
 * @param {string} params.serviceUrl The location of the SbBillingAuthProvider.
 * @param {UserIdentity} params.userIdentity A user identity issued by a SbBillingAuthProvider.
 * @param {Object} [params.headers] Any custom headers to be sent in the refresh token HTTP request.
 */
const logoutP = async ({ serviceUrl, userIdentity, headers = {} }) => {
  if (userIdentity.provider !== PROVIDER_ID) {
    throw new Error('logoutP is compatible only with UserIdentities originating from a SbBillingAuthProvider');
  }

  const { id, access, deviceKey } = userIdentity.custom;
  const url = `${serviceUrl}${urlPaths.LOGOUT}`;

  await fetch({
    url,
    method: 'POST',
    headers: { ...getDefaultHeaders({ idToken: id }), ...headers },
    body: { accessToken: access, deviceKey },
  });
};

/**
 * logoutAllP
 *
 * Performs a logout across all devices on the user ID specified in the userIdentity.
 * The current session will also be logged out. Tokens may take up to 1 hour to expire
 * per SbBillingAuthProvider.
 *
 * @param {Object} params
 * @param {string} params.serviceUrl The location of the SbBillingAuthProvider.
 * @param {UserIdentity} params.userIdentity A user identity issued by a SbBillingAuthProvider.
 * @param {Object} [params.headers] Any custom headers to be sent in the refresh token HTTP request.
 */
const logoutAllP = async ({ serviceUrl, userIdentity, headers = {} }) => {
  if (userIdentity.provider !== PROVIDER_ID) {
    throw new Error('logoutAllP is compatible only with UserIdentities originating from a SbBillingAuthProvider');
  }

  const { id, access } = userIdentity.custom;
  const url = `${serviceUrl}${urlPaths.LOGOUT_ALL}`;

  const response = await fetch({
    url,
    method: 'POST',
    headers: { ...getDefaultHeaders({ idToken: id }), ...headers },
    body: { accessToken: access },
  });

  return response.body;
};

module.exports = {
  loginP,
  respondToChallengeP,
  logoutP,
  refreshTokenP,
  logoutAllP,
};
