/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export const AddEditControlledMoneyAccountSchema = ({ bankBranchNumberRegex }) =>
  Yup.object().shape({
    beneficiaryIds: Yup.array().min(1).required(),
    accountOpenedDate: Yup.number().required(),
    bankName: Yup.string().required(),
    accountName: Yup.string().required(),
    branchNumber: Yup.string().matches(bankBranchNumberRegex).required(),
    accountNumber: Yup.string().required(),
  });
