import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ClickableIcon } from '../clickable-icon';

import Styles from './FloatingCard.module.scss';

export const FloatingCard = React.forwardRef(
  // The `expandIfCollapsed` property activates when it is *changed* to true - it doesn't force the card to stay expanded
  (
    {
      children,
      maxWidth,
      heading,
      collapsible,
      applyMargin,
      hideShadow,
      initiallyCollapsed,
      expandIfCollapsed,
      onToggleCollapse,
      classname,
    },
    ref,
  ) => {
    const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);
    const onClickCollapseButton = () => {
      if (collapsible) {
        const newValue = !isCollapsed;
        setIsCollapsed(newValue);
        onToggleCollapse(newValue); // in case the parent component needs this for whatever reason
      }
    };

    useEffect(() => {
      // The `expandIfCollapsed` property has an effect only when it is *changed* to true (rising edge transition).
      // Don't compare against `isCollapsed` otherwise the card will be forced to stay expanded because of `useEffect` dependency changes.
      // NOTE : Future changes to useEffect with other dependencies may need you to track the last state of `expandIfCollapsed`
      if (expandIfCollapsed) {
        setIsCollapsed(false);
      }
    }, [expandIfCollapsed]);

    return (
      <div
        ref={ref}
        className={classnames(
          Styles.floatingCard,
          applyMargin && Styles.applyMargin,
          hideShadow && Styles.hideShadow,
          classname,
        )}
      >
        {(heading || collapsible) && ( // when there's a heading or the card is collapsible
          <div
            className={classnames(Styles.collapsibleHeader, !isCollapsed && Styles.collapsibleAndExpanded)}
            onClick={onClickCollapseButton}
            title={heading || ''}
          >
            {collapsible && (
              <ClickableIcon
                className={classnames((isCollapsed && 'icon-arrow-40') || 'icon-arrow-37', Styles.collapsibleButton)}
              />
            )}
            <h3>{heading || ''}</h3>
          </div>
        )}
        {children && !isCollapsed && (
          <div style={maxWidth ? { maxWidth } : undefined} className={collapsible ? Styles.childContainer : undefined}>
            {children}
          </div>
        )}
      </div>
    );
  },
);

FloatingCard.displayName = 'FloatingCard';
FloatingCard.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number, // sets the max width of the children (especially for forms)
  heading: PropTypes.string, // heading text
  collapsible: PropTypes.bool,
  applyMargin: PropTypes.bool,
  initiallyCollapsed: PropTypes.bool,
  expandIfCollapsed: PropTypes.bool,
  hideShadow: PropTypes.bool, // In newer UI design, floating cards dont have a shadow
  classname: PropTypes.string,
  onToggleCollapse: PropTypes.func,
};

FloatingCard.defaultProps = {
  maxWidth: undefined,
  heading: undefined,
  collapsible: false,
  hideShadow: false,
  classname: '',
  applyMargin: true,
  initiallyCollapsed: false,
  expandIfCollapsed: false,
  onToggleCollapse: () => {},
};
