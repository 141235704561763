import * as types from './types';

/**
 * Action creator for the INITIALSE_FORM action.
 *
 * @param {Object} fieldValues An n level nested object representing the initial form values.
 *
 * @return {Object} Dispatchable redux action object.
 */
export const initialiseForm = ({ fieldValues = {} } = {}) => ({
  type: types.INITIALISE_FORM,
  payload: {
    fieldValues,
  },
});

export const startValidateForm = () => ({
  type: types.START_VALIDATE_FORM,
  payload: {},
});

export const endValidateForm = ({ errors = {} } = {}) => ({
  type: types.END_VALIDATE_FORM,
  payload: {
    validationErrors: errors,
  },
});

export const updateFieldValues = ({ fieldValues = {} } = {}) => ({
  type: types.UPDATE_FIELD_VALUES,
  payload: {
    fieldValues,
  },
});

export const setFieldValue = ({ field, value } = {}) => ({
  type: types.SET_FIELD_VALUE,
  payload: {
    field,
    value: { [field]: value },
  },
});

export const deleteField = ({ field } = {}) => ({
  type: types.SET_FIELD_VALUE,
  payload: {
    field,
    value: {},
  },
});

export const startSubmit = () => ({
  type: types.START_SUBMIT,
  payload: {},
});

export const endSubmit = ({ failed } = {}) => ({
  type: types.END_SUBMIT,
  payload: {
    failed,
  },
});

export const clearForm = () => ({
  type: types.CLEAR_FORM,
  payload: {},
});

export const resetForm = ({ fieldValues } = {}) => ({
  type: types.RESET_FORM,
  payload: {
    fieldValues,
  },
});

export const clearSubmitFailed = () => ({
  type: types.CLEAR_SUBMIT_FAILED,
  payload: {},
});
