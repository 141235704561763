'use strict';

const { getLogoUrl } = require('@sb-itops/email');

function getInvoiceLogoUrl({ sbDomain, env, accountId, invoiceId }) {
  const invoiceLogoUrl = getLogoUrl({ sbDomain, env, path: `firm/${accountId}/invoice/${invoiceId}/logo` });
  return invoiceLogoUrl;
}

module.exports = {
  getInvoiceLogoUrl,
};
