import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import Styles from './InvoiceEmailModal.module.scss';
import { InvoiceEmailTabPerDebtorForm } from '../invoice-email-tab-per-debtor-form';
import { InvoiceEmailForm } from '../invoice-email-form';

export const InvoiceEmailModal = React.memo(
  ({
    isVisible,
    isSubmitDisabled,
    scope,
    useMultiTabForm,
    invoiceIds,
    debtorIds,
    matterIds,
    consolidateEmails,
    warningMessage,
    onSend,
    onPreview,
    onClose,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.invoiceEmailModal}
        isVisible={isVisible}
        title="Send Email"
        body={getModalBody({
          scope,
          useMultiTabForm,
          invoiceIds,
          debtorIds,
          matterIds,
          consolidateEmails,
          onPreview,
        })}
        footer={
          <div>
            {warningMessage && <div className={Styles.warningMessage}>{warningMessage}</div>}
            <Button onClick={onSend} disabled={isSubmitDisabled}>
              Send Now
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

const getModalBody = ({ scope, useMultiTabForm, invoiceIds, debtorIds, consolidateEmails, onPreview, matterIds }) => {
  if (useMultiTabForm) {
    return (
      <div className={Styles.multiTabForm}>
        <InvoiceEmailTabPerDebtorForm
          scope={scope}
          invoiceId={invoiceIds[0]}
          matterId={matterIds[0]}
          debtorIds={debtorIds}
          onPreview={onPreview}
        />
      </div>
    );
  }

  return (
    <div className={Styles.standardForm}>
      <InvoiceEmailForm
        scope={scope}
        invoiceIds={invoiceIds}
        debtorIds={debtorIds}
        matterIds={matterIds}
        onPreview={onPreview}
        consolidatedMode={consolidateEmails}
      />
    </div>
  );
};

InvoiceEmailModal.displayName = 'InvoiceEmailModal';

InvoiceEmailModal.propTypes = {
  isVisible: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  scope: PropTypes.string,
  useMultiTabForm: PropTypes.bool,
  invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  debtorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  consolidateEmails: PropTypes.bool,
  warningMessage: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  onPreview: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

InvoiceEmailModal.defaultProps = {
  isVisible: false,
  isSubmitDisabled: false,
  scope: 'invoice-email-modal',
  consolidateEmails: false,
  useMultiTabForm: false,
  warningMessage: undefined,
  onPreview: undefined,
};
