import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PreviewPdf, useTranslation } from '@sb-itops/react';
import { ContactDisplay2 } from '@sb-customer-management/react';

import { PageNav, InvoiceStatementDebugEntities, InvoiceStatementSummaryPanel } from 'web/components';
import { InvoiceStatementActionBar } from 'web/react-redux';

import Styles from './BillingViewInvoiceStatementPdfRoute.module.scss';

export const BillingViewInvoiceStatementPdfRoute = memo(
  ({
    invoiceStatementLoading,
    invoiceStatementId,
    invoiceStatementNumber,
    debtor,
    invoices,
    previewUrl,
    invoiceStatementStatus,
    invoiceStatementLastUpdated,
    onClickLink,
    pageNavItems,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={Styles.billingViewInvoiceStatementPdfRoute}>
        <InvoiceStatementDebugEntities invoiceStatementId={invoiceStatementId} debtor={debtor} invoices={invoices} />
        <div className={Styles.pageTitle}>
          <span>
            {t('clientStatement')} #{invoiceStatementNumber || ''}
          </span>
          <ContactDisplay2 contact={debtor} onClickLink={onClickLink} asLink />
        </div>

        <div className="sb-tab-header">
          <PageNav
            navItems={pageNavItems}
            navCurrentPath="invoiceStatementView"
            onGotoOrReset={(path) => onClickLink({ type: path, id: invoiceStatementId })}
            scope="invoice-statement"
          />
        </div>
        <div className="master-detail-panel">
          <div className={classnames('panel-body', Styles.panelLayout)}>
            <InvoiceStatementActionBar
              invoiceStatementId={invoiceStatementId}
              invoiceStatementNumber={invoiceStatementNumber}
              invoiceStatementStatus={invoiceStatementStatus}
              invoiceStatementLoading={invoiceStatementLoading}
              previewUrl={previewUrl}
            />

            <div className={Styles.partialSurround}>
              <div className={Styles.partialContent}>
                <PreviewPdf url={previewUrl} />
              </div>
              <div className={Styles.sidePanel}>
                <InvoiceStatementSummaryPanel
                  debtor={debtor}
                  invoices={invoices}
                  invoiceStatementStatus={invoiceStatementStatus}
                  invoiceStatementLastUpdated={invoiceStatementLastUpdated}
                  onClickLink={onClickLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

BillingViewInvoiceStatementPdfRoute.displayName = 'BillingViewInvoiceStatementPdfRoute';
BillingViewInvoiceStatementPdfRoute.propTypes = {
  invoiceStatementLoading: PropTypes.bool.isRequired,
  invoiceStatementId: PropTypes.string.isRequired,
  invoiceStatementNumber: PropTypes.string,
  debtor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    displayNameFirstLast: PropTypes.string,
    trustBalance: PropTypes.number,
  }),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.number.isRequired,
      totals: PropTypes.shape({
        interest: PropTypes.number.isRequired,
        totalExcInterest: PropTypes.number.isRequired,
        waived: PropTypes.number.isRequired,
        paidByCredit: PropTypes.number.isRequired,
        paid: PropTypes.number.isRequired,
        unpaid: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
  previewUrl: PropTypes.string,
  invoiceStatementStatus: PropTypes.string,
  invoiceStatementLastUpdated: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  pageNavItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      className: PropTypes.string,
      order: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

BillingViewInvoiceStatementPdfRoute.defaultProps = {
  invoiceStatementNumber: undefined,
  debtor: undefined,
  invoices: [],
  previewUrl: undefined,
  invoiceStatementStatus: undefined,
  invoiceStatementLastUpdated: undefined,
};
