import React, { memo } from 'react';
import { MultiSelectFilters } from '@sb-itops/react';
import PropTypes from 'prop-types';

const ClientCommunicationsToggle = memo(({ showMarkAsSentEntries, onClientCommunicationToggleChange }) => {
  const clientCommunicationToggleOptions = [
    {
      id: 'showMarkAsSentEntries',
      name: 'Manually Marked As Sent',
      selected: showMarkAsSentEntries,
    },
  ];

  return (
    <MultiSelectFilters
      id="clientCommunicationFilter"
      filterOptions={clientCommunicationToggleOptions}
      onSelect={onClientCommunicationToggleChange}
    />
  );
});

ClientCommunicationsToggle.propTypes = {
  showMarkAsSentEntries: PropTypes.bool.isRequired,
  onClientCommunicationToggleChange: PropTypes.func.isRequired,
};

ClientCommunicationsToggle.defaultProps = {};

export default ClientCommunicationsToggle;
