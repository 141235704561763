import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getLogger } from '@sb-itops/fe-logger';
import { getById as getCorrespondenceHistoryById } from '../correspondence-history';

import domain from '../domain';

const log = getLogger('correspondence-history-full');

const api = cacheFactory({
  domain,
  name: 'correspondence-history-full',
  keyPath: 'id',
  ngCacheName: 'sbCorrespondenceHistoryFullService',
  syncType: syncTypes.NONE,
  immutable: false,
});

export const { getById, getList, getMap, updateCache, updateCacheAndBroadcast, UPDATE_CACHE } = api;

// thunks
const ENDPOINT = '/billing/correspondence-history/:accountId';

export const fetchById = async (id) => {
  const correspondenceHistoryFull = getById(id);
  const correspondenceHistory = getCorrespondenceHistoryById(id); // summary

  // if we have already received the (full) history and it is the most up to date, just return it
  if (
    correspondenceHistoryFull &&
    correspondenceHistory &&
    // there is no versionId so we check the date matches
    correspondenceHistoryFull.lastUpdated === correspondenceHistory.lastUpdated
  ) {
    return correspondenceHistoryFull;
  }

  try {
    // get the last updated record
    const response = await fetchGetP({ path: `${ENDPOINT}/${id}` });
    if (response?.status === 200 && response?.body?.payload) {
      updateCacheAndBroadcast({ entities: response.body.payload });
    } else {
      log.error('Error occurred getting full correspondence history', response);
    }
  } catch (err) {
    log.error('Error occurred getting full correspondence history', err);
  }

  return getById(id);
};
