'use strict';

const { getChargeErrorMessage: getLawpayChargeErrorMessage } = require('./lawpay');
const { getChargeErrorMessage: getStripeChargeErrorMessage } = require('./stripe');
const { getChargeErrorMessage: getFeeWiseChargeErrorMessage } = require('./fee-wise');
const { providers } = require('../entities/constants');

const providerSpecificChargeErrorMessageFns = {
  [providers.LAWPAY]: getLawpayChargeErrorMessage,
  [providers.STRIPE]: getStripeChargeErrorMessage,
  [providers.EZY_COLLECT]: getStripeChargeErrorMessage, // Ezy-Collect uses a stripe backend behind the scenes.
  [providers.FEE_WISE]: getFeeWiseChargeErrorMessage,
};

/**
 * getChargeErrorMessage
 *
 * @param {Object} params
 * @param {string} params.defaultMessage Default message which may be used by provider if no better message found
 * @param {Object} params.chargeStatusResult Charge result object containing specific provider charge error
 * @param {string} params.providerType The provider type for which to retrieve error message
 *
 * @returns {Object} An object with error message or default message
 */
const getChargeErrorMessage = ({ defaultMessage, chargeStatusResult, providerType }) => {
  if (!chargeStatusResult) {
    throw new Error('chargeStatusResult must be provided');
  }

  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  const providerSpecificChargeErrorMessageFn = providerSpecificChargeErrorMessageFns[providerType];
  if (!providerSpecificChargeErrorMessageFn) {
    throw new Error(`Failed to retrieve charge error message for provider type '${providerType}'`);
  }

  return (
    providerSpecificChargeErrorMessageFn({
      defaultMessage,
      chargeStatusResult,
    }) || {}
  );
};

module.exports = {
  getChargeErrorMessage,
};
