/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CurrencyInput2,
  FormLabel,
  LoadingPlaceholderElement,
  Spinner,
  StatelessSelect,
} from '@sb-itops/react';
import { Modal } from '@sb-itops/react/modal';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import { AddressForm } from '@sb-customer-management/react';
import { getSchemeValue } from '@sb-itops/region-schemes';
import { mapAddressFormFields } from '@sb-customer-management/business-logic/contacts/services/address-search';
import { getStateIdFromName, getRegion } from '@sb-itops/region';
import { OnboardingWizardIframeContainer } from 'web/containers';
import * as Styles from './WelcomeModal.module.scss';
import { MatterTypeSelect } from '../matter-type-select';

const REGION = getRegion();

const WelcomeModalContent = ({ onClose, helpLink }) => (
  <>
    <div className={Styles.content}>
      <div className={classnames('logo-pale', Styles.logo)} />
      <h2>Welcome to Smokeball!</h2>
      <p>To get started as quickly and as easily as possible, you can view our Getting Started guides below.</p>
      <p>You can find these guides again by clicking on the help icon at the top of the page.</p>
    </div>
    <div className={Styles.footer}>
      <Button onClick={() => window.open(helpLink, '_blank').focus()} className={Styles.footerBtn}>
        Getting Started Guide
      </Button>
      <br />
      <Button type="secondary" className={Styles.footerBtn} onClick={onClose}>
        Continue
      </Button>
    </div>
  </>
);

const StepContent = ({
  onFieldValueUpdated,
  submitFailed,
  activeStep,
  formFields,
  clientRoleOptions,
  billingUnitOptions,
}) => {
  const {
    hourlyRate,
    selectedBillingUnit,
    firstName,
    lastName,
    email,
    matterTypeId,
    categoryId,
    locationId,
    clientRole,
    businessAddress,
    customUnit,
  } = formFields;
  if (activeStep === 1)
    return (
      <div className={classnames(Styles.stepContent, Styles.addressStep)}>
        <div className={Styles.subheading}>What is the address of your law firm?</div>
        <AddressForm
          formInitialised
          addressScheme={getSchemeValue('addressScheme')}
          {...businessAddress}
          onFieldValueUpdated={(payload) => {
            onFieldValueUpdated({ businessAddress: payload });
            if (payload.state) {
              // UK Addresses override the state ID to match the desktop
              const mappedState = getStateIdFromName(payload.state, REGION) || payload.state;
              onFieldValueUpdated({ locationId: mappedState });
            }
          }}
          validateForm={() => {}}
          submitFailed={submitFailed}
          onAddressAutocompleteSelected={(payload) => {
            const addressFields = mapAddressFormFields(payload, getSchemeValue('addressScheme'));
            onFieldValueUpdated({ businessAddress: addressFields });
            if (addressFields.state) {
              // UK Addresses override the state ID to match the desktop
              const mappedState = getStateIdFromName(payload.state, REGION) || payload.state;
              onFieldValueUpdated({ locationId: mappedState });
            }
          }}
          forceShowState
          useManualEntrySwitch
        />
      </div>
    );
  if (activeStep === 2)
    return (
      <div className={Styles.stepContent}>
        <div className={Styles.subheading}>Tell us about your billing rates.</div>
        <div className="row">
          <div className={classnames('col-xs-6', hourlyRate?.isInvalid && submitFailed && 'has-error')}>
            <FormLabel
              label="What is your hourly billing rate?"
              field={hourlyRate}
              submitFailed={submitFailed}
              uppercase={false}
            />
            <CurrencyInput2
              onChange={(e) => {
                onFieldValueUpdated({ hourlyRate: e.target.value });
              }}
              // eslint-disable-next-line no-unsafe-optional-chaining
              value={+hourlyRate?.value}
              hasError={submitFailed && hourlyRate?.isInvalid}
            />
          </div>
        </div>
        <div className={classnames('row', Styles.rowMarginException)}>
          <div className="col-sm-12">
            <FormLabel
              label={
                selectedBillingUnit.value === 'Custom'
                  ? 'In what time increments do you bill? (minutes)'
                  : 'In what time increments do you bill?'
              }
              field={selectedBillingUnit}
              submitFailed={submitFailed}
              uppercase={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <StatelessSelect
              onChange={(billingUnit) => {
                onFieldValueUpdated({ selectedBillingUnit: billingUnit.value });
                onFieldValueUpdated({ customUnit: null });
              }}
              options={billingUnitOptions}
              selectedOption={selectedBillingUnit.value}
            />
          </div>
          {selectedBillingUnit.value === 'Custom' && (
            <div className={classnames('col-sm-3', Styles.customUnit)}>
              <input
                type="number"
                min="1"
                max="60"
                className={classnames('form-control')}
                value={customUnit.value || ''}
                step={1}
                name="customUnits"
                onChange={(e) => {
                  onFieldValueUpdated({ customUnit: e.target.value });
                }}
              />
              {customUnit.isInvalid && customUnit.value && (
                <span className={Styles.customUnitError}>Valid: 1 to 60</span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  if (activeStep === 3)
    return (
      <div className={Styles.stepContent}>
        <div className={Styles.subheading}>Now let’s create your first client.</div>
        <div className="row">
          <div className={classnames('col-xs-6', firstName?.isInvalid && submitFailed && 'has-error')}>
            <FormLabel label="First Name" field={firstName} submitFailed={submitFailed} uppercase={false} />
            <input
              className={classnames('form-control')}
              type="text"
              autoFocus
              value={firstName?.value || ''}
              onChange={(e) => onFieldValueUpdated({ firstName: e.target.value })}
            />
          </div>
          <div className={classnames('col-xs-6', lastName?.isInvalid && submitFailed && 'has-error')}>
            <FormLabel label="Last Name" field={lastName} submitFailed={submitFailed} uppercase={false} />
            <input
              className={classnames('form-control')}
              type="text"
              value={lastName?.value || ''}
              onChange={(e) => onFieldValueUpdated({ lastName: e.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className={classnames('col-xs-12', email?.isInvalid && 'has-error')}>
            <FormLabel label="Email" optional field={email} submitFailed={submitFailed} uppercase={false} />
            <input
              className={classnames('form-control')}
              type="text"
              value={email?.value || ''}
              onChange={(e) => onFieldValueUpdated({ email: e.target.value })}
            />
          </div>
        </div>
      </div>
    );
  if (activeStep === 4)
    if (!firstName?.value) {
      // If there was no contact filled out, show alternative end screen
      return (
        <div className={Styles.endScreen}>
          <div className={Styles.thanks}>Thank you!</div>
          Let&apos;s get started
        </div>
      );
    }
  return (
    <div className={Styles.stepContent}>
      <div className={Styles.subheading}>Lastly, just a few details about your client&apos;s matter.</div>
      <div className="row">
        <div className={classnames('col-xs-12', matterTypeId?.isInvalid && submitFailed && 'has-error')}>
          <FormLabel label="Matter Type" field={matterTypeId} submitFailed={submitFailed} uppercase={false} />
          <MatterTypeSelect
            locationId={locationId?.value}
            matterTypeCategory={categoryId?.value}
            matterTypeId={matterTypeId?.value}
            onLocationUpdated={(value) => {
              onFieldValueUpdated({ locationId: value });
              onFieldValueUpdated({ clientRole: undefined });
              onFieldValueUpdated({ matterTypeId: undefined });
              onFieldValueUpdated({ originalMatterTypeId: undefined });
            }}
            onCategoryUpdated={(value) => {
              onFieldValueUpdated({ categoryId: value });
              onFieldValueUpdated({ clientRole: undefined });
              onFieldValueUpdated({ matterTypeId: undefined });
              onFieldValueUpdated({ originalMatterTypeId: undefined });
            }}
            onMatterTypeUpdated={(option) => {
              onFieldValueUpdated({ matterTypeId: option?.value });
              onFieldValueUpdated({ originalMatterTypeId: option?.originalMatterTypeId });
              onFieldValueUpdated({ clientRole: undefined });
            }}
            isRequired
            showError={submitFailed}
          />
        </div>
      </div>
      {clientRoleOptions.length > 1 && (
        <div className="row">
          <div className={classnames('col-xs-12', clientRole?.isInvalid && submitFailed && 'has-error')}>
            <FormLabel label="Client Role" field={clientRole} submitFailed={submitFailed} uppercase={false} />
            <div>
              <StatelessSelect
                className={classnames(submitFailed && clientRole?.isInvalid && 'has-error')}
                options={clientRoleOptions}
                disabled={!(matterTypeId && matterTypeId.value)}
                selectedOption={clientRole.value}
                onChange={(newClientRole) => onFieldValueUpdated({ clientRole: newClientRole.value })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Stepper = ({ steps, activeStep }) => {
  const elements = [];
  for (let i = 1; i <= steps; i += 1) {
    if (i !== 1) {
      elements.push(<div key={`sep${i}`} className={classnames(Styles.separator)} />);
    }
    elements.push(
      <div key={`ele${i}`} className={classnames(Styles.circle, i <= activeStep && Styles.activeStep)}>
        {i}
      </div>,
    );
  }
  return <div className={Styles.stepper}>{elements}</div>;
};

const numberOfSteps = 4;
const WizardModalContent = ({
  onFieldValueChanged,
  formFields,
  clientRoleOptions,
  onFieldValueUpdated,
  onSave,
  activeStep,
  setActiveStep,
  formSubmitting,
  formInitialised,
  formValid,
  billingUnitOptions,
  loading,
}) => (
  <>
    <div className={classnames(Styles.content)}>
      <div className={classnames('logo-pale', Styles.logo)} />
      <h2>Welcome to Smokeball!</h2>
      <p>Let&apos;s get started setting up your firm.</p>
      <Stepper steps={numberOfSteps} activeStep={activeStep} />
      {formInitialised ? (
        <StepContent
          activeStep={activeStep}
          onFieldValueChanged={onFieldValueChanged}
          onFieldValueUpdated={onFieldValueUpdated}
          formFields={formFields}
          clientRoleOptions={clientRoleOptions}
          billingUnitOptions={billingUnitOptions}
        />
      ) : (
        <>
          <div className={Styles.loadingText}>Loading your address details...</div>
          <LoadingPlaceholderElement className={Styles.loadingPlaceholder} isLine />
        </>
      )}
    </div>
    <div className={Styles.footer}>
      {activeStep === 4 && formFields.lastName?.value && (
        <div
          onClick={(e) => onSave(e, true)}
          className={classnames(Styles.skip, 'link-button', 'onboarding-modal-skip')}
        >
          Skip creating matter and exit
        </div>
      )}
      {activeStep === 3 && (
        <div
          onClick={() => {
            onFieldValueUpdated({ firstName: '' });
            onFieldValueUpdated({ lastName: '' });
            setActiveStep(4);
          }}
          className={classnames(Styles.skip, 'link-button', 'onboarding-modal-skip')}
        >
          Skip creating a contact and matter
        </div>
      )}
      {activeStep !== numberOfSteps && (
        <Button
          className={classnames(Styles.footerBtn, 'onboarding-modal-next')}
          onClick={() => {
            setActiveStep(activeStep + 1);
          }}
          disabled={!formValid || (activeStep === 3 && (!formFields.firstName?.value || !formFields.lastName?.value))}
        >
          Next
        </Button>
      )}
      {activeStep === numberOfSteps && (
        <Button
          className={classnames(
            Styles.footerBtn,
            formFields.firstName?.value ? 'onboarding-modal-complete' : 'onboarding-modal-done',
          )}
          onClick={onSave}
          disabled={
            formSubmitting ||
            !formValid ||
            loading ||
            (!!formFields.firstName?.value &&
              (!formFields.matterTypeId?.value || (!formFields.clientRole.value && clientRoleOptions.length > 1)))
          }
        >
          {formFields.firstName?.value ? 'Complete ' : 'Done '}
          {(formSubmitting || loading) && <Spinner small />}
        </Button>
      )}
      {activeStep !== 1 && (
        <Button
          type="secondary"
          className={classnames(Styles.footerBtn, 'onboarding-modal-back')}
          onClick={() => setActiveStep(activeStep - 1)}
          disabled={formSubmitting}
        >
          Back
        </Button>
      )}
    </div>
  </>
);

const WelcomeModal = ({
  onClose,
  open,
  helpLink,
  formFields,
  clientRoleOptions,
  onFieldValueUpdated,
  onSave,
  activeStep,
  setActiveStep,
  formSubmitting,
  formInitialised,
  formValid,
  billingUnitOptions,
  loading,
  onClickLink,
}) => {
  if (featureActive('NUCWEB-747')) {
    return open && <OnboardingWizardIframeContainer onClose={onClose} onClickLink={onClickLink} />;
  }
  return (
    <Modal
      className={classnames(Styles.welcomeModal, featureActive('BB-13823') && Styles.newModal, 'onboarding-modal')}
      title=" "
      body={
        featureActive('BB-13823')
          ? WizardModalContent({
              onClose,
              helpLink,
              formFields,
              clientRoleOptions,
              onFieldValueUpdated,
              onSave,
              activeStep,
              setActiveStep,
              formSubmitting,
              formInitialised,
              formValid,
              billingUnitOptions,
              loading,
            })
          : WelcomeModalContent({ onClose, helpLink })
      }
      isVisible={open}
      onModalClose={onClose}
      shouldCloseOnOverlayClick={false}
      showFooter={false}
    />
  );
};

WelcomeModal.displayName = 'WelcomeModal';

WelcomeModal.propTypes = {
  clientRoleOptions: PropTypes.array,
  billingUnitOptions: PropTypes.array,
  formFields: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  helpLink: PropTypes.string.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

WelcomeModal.defaultProps = {
  clientRoleOptions: [],
  billingUnitOptions: [],
};

export default WelcomeModal;
