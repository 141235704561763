import React from 'react';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as messageDisplay from '@sb-itops/message-display';
import * as forms from '@sb-itops/redux/forms2';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import { useDispatch, useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { protectBalance } from '@sb-billing/redux/balance-protection';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { ProtectBalanceAddModal } from './ProtectBalanceAddModal';
import { createProtectBalanceAddFormSchema } from '../protect-balance-add/ProtectBalanceAddFormSchema';

const scope = 'protect-balance-add';
const ADD_PROTECT_FUNDS_SCHEMA = createProtectBalanceAddFormSchema();

export const ProtectBalanceAddModalContainer = withReduxProvider((props) => {
  const { onClose, matterId, bankAccountId } = props;
  const { selectors: formSelectors, operations: formOperations } = useScopedFeature(forms, scope);
  const { formSubmitting } = useSelector(formSelectors.getFormState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validateFn = (fieldValues) => {
    const formErrors = {};
    const reasonRowsErrors = {};
    let atLeastOneFilled = false;
    // we need to check there is at least one valid pair of reason+amount
    // empty reason+amount is valid, as long as there is at least 1 other valid pair
    // at the same time, we want to error form if only one of the reason/amount is filled out
    const rowKeys = Object.keys(fieldValues.reasonRows);
    rowKeys.forEach((key) => {
      const row = fieldValues.reasonRows[key];
      // ignore empty rows
      if (!row.reason && !row.amount) {
        return;
      }
      // ignore fully filled rows
      if (row.reason && row.amount) {
        atLeastOneFilled = true;
        return;
      }

      reasonRowsErrors[key] = {
        reason: !row.reason ? 'Reason is missing' : undefined,
        amount: !row.amount ? 'Amount is missing' : undefined,
      };
    });

    if (!atLeastOneFilled && !Object.keys(reasonRowsErrors).length) {
      // there is no valid pair of reason+amount and there are no other errors
      // meaning we have only empty rows, which is invalid
      reasonRowsErrors[rowKeys[0]] = {
        reason: 'Reason is missing',
        amount: 'Amount is missing',
      };
    }

    if (Object.keys(reasonRowsErrors).length) {
      formErrors.reasonRows = reasonRowsErrors;
    }

    // check we have enough money available
    if (fieldValues.fundsAvailable < fieldValues.totalProtected) {
      formErrors.totalProtected = `${t('cents', {
        val: fieldValues.totalProtected - fieldValues.fundsAvailable,
      })} over available funds`;
    }

    // Forms 2 expects errors to be reported as flattened dot object notation.
    return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
  };

  const validateForm = () => {
    dispatch(formOperations.validateForm({ schema: ADD_PROTECT_FUNDS_SCHEMA, validateFn }));
  };

  return (
    <ProtectBalanceAddModal
      {...props}
      {...{
        scope,
        bankAccountId,
        matterReadOnly: !!matterId,
        isSubmitDisabled: formSubmitting,
        onSave: async (event) => {
          event.preventDefault();
          validateForm();

          try {
            await dispatch(
              formOperations.submitFormWithValidationP({
                submitFnP: async (formFieldValues) => {
                  await save({ formFieldValues, bankAccountId });
                  onClose();
                  messageDisplay.success('Funds protected successfully.');
                },
              }),
            );
          } catch (err) {
            messageDisplay.error('Failed to protect the funds');
          }
        },
        validateForm,
      }}
    />
  );
});

const save = async ({ formFieldValues, bankAccountId }) => {
  const { matterId, contactId, reasonRows } = formFieldValues;
  const validRows = Object.keys(reasonRows).reduce((acc, key) => {
    if (reasonRows[key].reason && reasonRows[key].amount) {
      acc.push(reasonRows[key]);
    }
    return acc;
  }, []);

  await protectBalance({
    matterId,
    contactId,
    bankAccountId,
    protectItems: validRows,
  });
};

ProtectBalanceAddModalContainer.displayName = 'ProtectBalanceAddModalContainer';

ProtectBalanceAddModalContainer.propTypes = {
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ProtectBalanceAddModalContainer.defaultProps = {
  matterId: undefined,
  onClose: () => setModalDialogHidden({ modalId: scope }),
};

export default ProtectBalanceAddModalContainer;
