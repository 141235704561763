import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, buttonSizes as buttonSizesEnum, buttonTypes as buttonTypesEnum } from '../button';
import { LinkableText } from '../linkable-text';

export const buttonSizes = buttonSizesEnum;
export const buttonTypes = buttonTypesEnum;

/**
 * @deprecated
 * Simply use a Button which triggers some state update, which subsequently sets the visible flag to true on Modal.
 */
class OpenModalButton extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    opened: false,
  };

  onCloseModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState(() => ({
      opened: false,
    }));
  };

  onOpenModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (!this.props.disabled) {
      this.setState(({ opened }) => ({
        opened: !opened,
      }));
    }
  };

  render() {
    return (
      <>
        {this.props.asIcon && (
          <div
            className="open-modal-icon-container"
            onClick={this.onOpenModal}
            disabled={this.props.disabled}
            role="button"
            tabIndex={0}
          >
            <i className={this.props.asIcon} />
          </div>
        )}
        {!this.props.asIcon && !this.props.asLink && (
          <Button
            onClick={this.onOpenModal}
            size={this.props.buttonSize}
            type={this.props.buttonType}
            disabled={this.props.disabled}
          >
            {this.props.inputText}
          </Button>
        )}
        {!this.props.asIcon && this.props.asLink && (
          <LinkableText
            onClick={this.onOpenModal}
            disabled={this.props.disabled}
            asLink
            text={this.props.inputText}
            onClickLink={this.onOpenModal}
          />
        )}
        {this.state.opened && !this.props.disabled && this.props.onModalRenderer(this.onCloseModal)}
      </>
    );
  }
}

OpenModalButton.displayName = 'OpenModalButton';

OpenModalButton.propTypes = {
  inputText: PropTypes.string.isRequired,
  onModalRenderer: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  asLink: PropTypes.bool,
  asIcon: PropTypes.string,
  buttonSize: PropTypes.oneOf(Object.values(buttonSizes)),
  buttonType: PropTypes.oneOf(Object.values(buttonTypes)),
};

OpenModalButton.defaultProps = {
  disabled: false,
  asLink: false,
  asIcon: undefined,
  buttonSize: undefined,
  buttonType: undefined,
};

export default OpenModalButton;
