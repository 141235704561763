import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { getBulkDepositIdFromReversalId } from '@sb-billing/business-logic/transactions/services';
import domain from '../domain';
import { getById as getTransactionById } from '../transactions';

const api = cacheFactory({
  domain,
  name: 'transaction-receipts-service',
  keyPath: 'id',
  ngCacheName: 'sbTransactionReceiptService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

// if its a reversal, the receipt for the transaction will the receipt of the original transaction
// from services/src/transaction-receipts-service/transaction-receipts-service.js
export function getTransactionReceiptById(id) {
  const transaction = getTransactionById(id);

  if (!transaction) {
    // if the transaction doesn't exist, the id may be 'bulkDepositId' or 'bulkDepositId_reversal'
    // which both uses 'bulkDepositId' as the key for the receipt
    return getById(getBulkDepositIdFromReversalId(id));
  }

  if (transaction && transaction.type === transactionType.DepositReversal) {
    return getTransactionReceiptById(transaction.reversedFromTransactionId);
  }

  return getById(id);
}
