import { featureActive } from '@sb-itops/feature';
import React from 'react';
import CreateEditContactFormWithoutRedux from './CreateEditContactForm.container';
import CreateEditContactFormWithRedux from './CreateEditContactFormWithRedux.container';
import { CreateEditContactSchema } from './CreateEditContactSchema';

const CreateEditContactForm = (props) => {
  if (featureActive('NUCWEB-726')) {
    return <CreateEditContactFormWithoutRedux {...props} />;
  }
  return <CreateEditContactFormWithRedux {...props} />;
};

export { CreateEditContactForm, CreateEditContactSchema };
