import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import * as messageDisplay from '@sb-itops/message-display';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { getList as getExpenseList } from '@sb-billing/redux/expenses';
import { closePaymentPlanP } from '@sb-billing/redux/payment-plans';
import { debugAutoPayment } from '@sb-billing/redux/payment-provider';
import { useReduxActionOnce } from 'web/hooks';
import { useDispatch, useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useQuery } from '@apollo/client';
import { BillingPaymentPlanRouteData } from 'web/graphql/queries';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { getAppEnv, envType } from '@sb-itops/app-env';
import { featureActive } from '@sb-itops/feature';
import { ADD_PAYMENT_MODAL_ID as ADD_PAYMENT_NEW_MODAL_ID } from 'web/components';
import { withApolloClient } from '../../hocs/withApolloClient';
import ContactPaymentPlan from './ContactPaymentPlan';
import { contactPaymentPlanFeature } from './contact-payment-plan-feature';
import { ADD_PAYMENT_MODAL_ID } from '../../components/payment-add-modal';

const tabTypes = {
  CONTACT: 'contact',
};

const addEditModalId = 'add-edit-payment-plan';
const closePaymentPlanModalId = 'close-payment-plan-confirmation';

const onShowModal = ({ modalId }) => setModalDialogVisible({ modalId });
const onCloseModal = ({ modalId }) => setModalDialogHidden({ modalId });

const hooks = ({ contactId, onClickLink }) => ({
  useGraphQL: () => {
    const dispatch = useDispatch();

    // get the payment plan redux feature
    const {
      sort: {
        selectors: { getSortDirection },
        actions: { reset: resetSortDirection, setSortDirection },
      },
      expandableLists: {
        selectors: { getExpandedItems },
        actions: { reset: resetExpandCollapse, toggleItem },
      },
      selectedPaymentPlan: {
        selectors: { getSelectedPaymentPlan },
        actions: { clearSelectedPaymentPlan, setSelectedPaymentPlan },
      },
    } = useSelector(contactPaymentPlanFeature)({ contactId });

    // Clear store if tab closed
    useReduxActionOnce('smokeball-tab-closed', ([{ type, contactId: closedContactId }]) => {
      // Check if the tab containing this component is being closed.
      if (type === tabTypes.CONTACT && closedContactId === contactId) {
        // clear payment plan redux state only when contact tab is closed
        dispatch(resetSortDirection());
        dispatch(resetExpandCollapse());
        dispatch(clearSelectedPaymentPlan());
      }
    });

    const { data, loading, error, refetch } = useQuery(BillingPaymentPlanRouteData, {
      variables: {
        paymentPlanFilter: { contactIds: [contactId] },
        unpaidMatterFilter: { debtorIds: [contactId] },
        matterFilter: { includeDeleted: true },
      },
    });

    if (error) {
      throw new Error(error);
    }
    const paymentPlans = data?.paymentPlans || [];
    const mattersWithUnpaidBalanceForDebtor = data?.unpaidMatters || [];

    const { activePaymentPlan, pastPaymentPlans } = paymentPlans.reduce(
      (acc, pp) => {
        if (pp.status === paymentPlanStatuses.ACTIVE) {
          acc.activePaymentPlan = pp;
        }
        if (pp.status === paymentPlanStatuses.CLOSED) {
          acc.pastPaymentPlans.push(pp);
        }
        return acc;
      },
      { activePaymentPlan: {}, pastPaymentPlans: [] },
    );

    const selectedPaymentPlanId = getSelectedPaymentPlan();
    const currentPaymentPlan = selectedPaymentPlanId
      ? paymentPlans.find((pp) => pp.id === selectedPaymentPlanId)
      : activePaymentPlan;
    const { id, startDate, lastUpdatedBy, installments, status, lastUpdated, $optimistic } = currentPaymentPlan;

    const lastInstallment = installments && installments[installments.length - 1];

    const mattersNotIncludedInPaymentPlan = mattersWithUnpaidBalanceForDebtor.reduce((acc, unpaidMatter) => {
      if (!activePaymentPlan?.matters?.find((matter) => matter.id === unpaidMatter.id)) {
        acc.push(unpaidMatter.matter);
      }
      return acc;
    }, []);

    const isContactOwingMoney = mattersWithUnpaidBalanceForDebtor.length > 0;
    const paymentsTableSortDirection = getSortDirection({ defaultSortDirection: 'asc' });

    const onClosePaymentPlan = async () => {
      try {
        await closePaymentPlanP(id);
        messageDisplay.success('Payment plan was closed successfully.');
      } catch (ex) {
        messageDisplay.error('Failed to close payment plan.');
      } finally {
        onCloseModal({ modalId: closePaymentPlanModalId });
      }
    };

    const onAddPayment = (cId) => {
      const disableLODPaymentModal =
        getExpenseList().length > 0 && !featureActive('BB-14929') && !featureActive('BB-13936');

      if (!disableLODPaymentModal) {
        setModalDialogVisible({
          modalId: ADD_PAYMENT_NEW_MODAL_ID,
          props: { scope: 'ContactPaymentPlan/add-payment-modal', contactId: cId },
        });
      } else {
        // Non-LOD version
        setModalDialogVisible({ modalId: ADD_PAYMENT_MODAL_ID, props: { contactId: cId } });
      }
    };

    const showDebug = useSelector(supportDebugSelectors.getShowDebug) && envType.PRODCTION !== getAppEnv();

    return {
      showDebug,
      triggerAutoCharge: () => debugAutoPayment(activePaymentPlan.accountId, activePaymentPlan.id),
      // when there is an optimistic update we need to wait for the notification to remove the optimistic flag.
      isLoading: !!$optimistic || loading,
      paymentPlanId: id,
      paymentPlan: currentPaymentPlan,
      paymentPlanEvents: currentPaymentPlan?.events || [],
      paymentPlanModalId: addEditModalId,
      onClickLink,
      showRightPanel: !!id,
      onAddPayment,
      onClosePaymentPlan,
      startDate,
      lastUpdatedOn: lastUpdated,
      lastUpdatedBy: lastUpdatedBy && lastUpdatedBy.name,
      lastInstallmentDate: lastInstallment && lastInstallment.date,
      isCurrentPaymentPlanActive: status === paymentPlanStatuses.ACTIVE,
      isContactOwingMoney,
      paymentsTableSortDirection,
      mattersIncludedInPaymentPlan: currentPaymentPlan.matters,
      mattersNotIncludedInPaymentPlan,
      activePaymentPlanId: activePaymentPlan.id,
      expandedLists: getExpandedItems(),
      pastPaymentPlans,
      closePaymentPlanModalId,
      onCreateNewPaymentPlan: () => onShowModal({ modalId: addEditModalId }),
      onEditPaymentPlan: () => onShowModal({ modalId: addEditModalId }),
      onShowClosePaymentPlanConfirmation: () => onShowModal({ modalId: closePaymentPlanModalId }),
      onNewPaymentPlanCreated: () => {
        dispatch(clearSelectedPaymentPlan());
      },
      onPaymentsTableSortDirectionChange: (direction) => {
        dispatch(setSortDirection({ sortDirection: direction }));
      },
      onFilterChanged: (paymentPlanId) => {
        dispatch(setSelectedPaymentPlan({ paymentPlanId }));
      },
      onToggleClick: (listName) => {
        dispatch(toggleItem({ id: listName }));
      },
      error,
      refetch,
      paymentPlans,
    };
  },
});

const ContactPaymentPlanContainer = withApolloClient(withReduxProvider(composeHooks(hooks)(ContactPaymentPlan)));

ContactPaymentPlanContainer.displayName = 'ContactPaymentPlanContainer';

ContactPaymentPlanContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

ContactPaymentPlanContainer.defaultProps = {
  printCheques: () => {},
};

export default ContactPaymentPlanContainer;
