import { isNewTheme } from 'web/services/theme';
import { hasBillingAccess } from "web/services/user-session-management";

angular
  .module('sb.billing.webapp')
  .controller('ContactsController', function($scope, $state, sbLinkService) {
    const ctrl = this;
    const key = 'cr';
    ctrl.categoryParam = $state.params[key];
    ctrl.hasBillingAccess = hasBillingAccess;
    ctrl.isNewTheme = isNewTheme();
    ctrl.onClickLink = sbLinkService.onClickLink;
  });
