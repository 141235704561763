'use strict';

//translate boost env to app env
function getBoostAppEnv({ boostEnv }) {
  switch (boostEnv) {
    case 'dev':
      return 'DEV';

    case 'rc':
      return 'RC';

    case 'qa':
      return 'QA';

    case 'live':
      return 'PRODUCTION';

    default:
      return 'LOCAL';
  }
}

module.exports = {
  getBoostAppEnv,
};
