import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';
import { ADD_INVOICE_PAYMENT_MODAL_ID, UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID } from 'web/components';

export const InvoiceAddPaymentButton = ({ invoiceId, bankAccountType, label, disabled, locked, asLink }) => {
  const onOpenAddInvoicePaymentModal = () => {
    const isAnticipatedDisbursementsFeatureEnabled = featureActive('BB-9573');

    if (isAnticipatedDisbursementsFeatureEnabled) {
      // If an Invoice has an unpaid AD, we need to show an "acknowledge and then proceed" modal, before allowing the user to continue
      onOpenUnpaidADModal({ invoiceId, bankAccountType });
    } else {
      // If AD feature is disabled, the check is unneeded
      onOpenInvoicePaymentModal({ invoiceId, bankAccountType });
    }
  };

  if (asLink) {
    return <LinkableText text={label} onClickLink={onOpenAddInvoicePaymentModal} asLink />;
  }

  return (
    <Button onClick={onOpenAddInvoicePaymentModal} disabled={disabled} locked={locked}>
      {label}
    </Button>
  );
};

function onOpenUnpaidADModal({ invoiceId, bankAccountType }) {
  setModalDialogVisible({
    modalId: UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID,
    props: {
      invoiceId,
      // Once the user has acknowledged this, we can open the invoice payment modal
      onAcknowledgeAndProceed: () => onOpenInvoicePaymentModal({ invoiceId, bankAccountType }),
    },
  });
}

function onOpenInvoicePaymentModal({ invoiceId, bankAccountType }) {
  setModalDialogVisible({
    modalId: ADD_INVOICE_PAYMENT_MODAL_ID,
    props: {
      invoiceId,
      bankAccountType,
      // printCheques TODO: BB-14091 Do we need printCheque? It's passed into the legacy one
      scope: 'finalised-invoice-page/add-invoice-payment-modal',
    },
  });
}

InvoiceAddPaymentButton.displayName = 'InvoiceAddPaymentButton';

InvoiceAddPaymentButton.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  bankAccountType: PropTypes.string,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  asLink: PropTypes.bool,
};

InvoiceAddPaymentButton.defaultProps = {
  bankAccountType: undefined,
  locked: false,
  disabled: false,
  asLink: false,
};
