import { useMemo } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useSelector } from 'react-redux';
import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { bankAccountState, bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import {
  categoryOptionMap,
  getSelectedCategory,
} from 'web/redux/route/home-billing-accounts-payment-provider-payments-fee-wise';
import { useDateRangeSelect } from '@sb-itops/redux/date-range-select/use-date-range-select';
import { dateRangeTypes } from '@sb-itops/date';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { FeeWiseLinkedAccountOptions } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { sortByProperty, capitalize } from '@sb-itops/nodash';
import FeeWisePaymentsRoute from './FeeWisePaymentsRoute';

const SCOPE = 'FeeWisePaymentsRoute';
const SCOPE_FILTER_PANEL = `${SCOPE}/fwPanelFilter`;
const SCOPE_FILTER_ACCOUNT = `${SCOPE}/fwAccountFilter`;
const SCOPE_FILTER_DATE = `${SCOPE}/fwDateFilter`;
const SCOPE_FILTER_TRANSACTION_STATUS = `${SCOPE}/fwTransactionStatusFilter`;
const SCOPE_FILTER_PAYOUT_STATUS = `${SCOPE}/fwPayoutStatusFilter`;

const hooks = () => ({
  useFilterCategory: () => ({
    categoryOptionMap,
    selectedCategory: useSelector(getSelectedCategory),
    fwPanelFilterScope: SCOPE_FILTER_PANEL,
  }),
  useDateFilter: () => {
    const { fromDate, toDate, dateRangeType, selectDateRangeType, selectFromDate, selectToDate } = useDateRangeSelect({
      scope: SCOPE_FILTER_DATE,
    });

    const onDateListChange = (dateFilter) => {
      const { filterType: dateFilterType, from, to } = dateFilter;
      selectDateRangeType({ dateRangeType: dateFilterType });
      if (dateFilterType !== dateRangeTypes.ALL) {
        selectFromDate({ from });
        selectToDate({ to });
      }
    };

    return { dateRangeType, fromDate, toDate, onDateListChange };
  },
  usePayoutStatusFilter: () => {
    const { selectedItems: payoutSelectedStatus, selectItems: payoutOnSelectStatus } = useMultipleItemSelection({
      scope: SCOPE_FILTER_PAYOUT_STATUS,
    });
    const { t } = useTranslation();

    const payoutSelectedStatusIds = Object.keys(payoutSelectedStatus);

    return {
      payoutSelectedStatusIds,
      payoutOnSelectStatus,
      payoutStatusOptions: [
        { id: 'Paid', label: 'Paid' },
        { id: 'Failed', label: 'Failed' },
        { id: 'Cancelled', label: capitalize(t('cancelled')) },
      ],
    };
  },
  useTransactionStatusFilter: () => {
    const { selectedItems: transactionSelectedStatus, selectItems: transactionOnSelectStatus } =
      useMultipleItemSelection({
        scope: SCOPE_FILTER_TRANSACTION_STATUS,
      });

    const transactionSelectedStatusIds = Object.keys(transactionSelectedStatus);

    return {
      transactionSelectedStatusIds,
      transactionOnSelectStatus,
      transactionStatusOptions: [
        { id: 'Paid', label: 'Paid' },
        { id: 'Failed', label: 'Failed' },
        { id: 'Processing', label: 'Processing' },
      ],
    };
  },
  useAccountFilter: () => {
    const { selectedItem: linkedAccountSelected, setSelectedItem: linkedAccountOnSelect } = useSingleItemSelection({
      scope: SCOPE_FILTER_ACCOUNT,
      initialSelection: '',
    });

    return { linkedAccountSelected, linkedAccountOnSelect };
  },
  useAccountFilterData: () => {
    const { t } = useTranslation();
    const { data, error, loading: linkedAccountLoading } = useSubscribedQuery(FeeWiseLinkedAccountOptions);

    if (error) {
      throw new Error(error);
    }

    const linkedAccountOptions = useMemo(() => {
      const options = (data?.feeWiseLinkedAccounts || []).reduce(
        (acc, { feeWiseAccountId, bankAccount }) => {
          if (bankAccount.accountType === bankAccountTypeEnum.OPERATING) {
            acc[bankAccountTypeEnum.OPERATING].push({
              value: feeWiseAccountId,
              label: t('operatingRetainer'),
            });
          } else if (
            bankAccount.accountType === bankAccountTypeEnum.TRUST &&
            bankAccount.state === bankAccountState.OPEN
          ) {
            acc[bankAccountTypeEnum.TRUST].push({
              value: feeWiseAccountId,
              label: getBankAccountName(bankAccount, t),
            });
          }
          return acc;
        },
        { [bankAccountTypeEnum.OPERATING]: [], [bankAccountTypeEnum.TRUST]: [] },
      );

      const allOption = { value: '', label: 'All' };
      const caseSensitive = false;
      const sortedTrusts = sortByProperty(options[bankAccountTypeEnum.TRUST], 'label', 'asc', caseSensitive);
      return [allOption, ...options[bankAccountTypeEnum.OPERATING], ...sortedTrusts];
    }, [data?.feeWiseLinkedAccounts, t]);

    return { linkedAccountLoading, linkedAccountOptions };
  },
});

const onLoadHooks = ({
  dateRangeType,
  onDateListChange,
  fromDate,
  toDate,
  payoutOnSelectStatus,
  payoutStatusOptions,
  transactionOnSelectStatus,
  transactionStatusOptions,
}) => ({
  useOnLoad: () => ({
    // Since we don't offer All Dates as a filter, we can tell no filters were set manually and we set them to default
    onLoad: () => {
      if (dateRangeType === dateRangeTypes.ALL) {
        onDateListChange({ filterType: dateRangeTypes.THIS_MONTH, from: fromDate, to: toDate });
        payoutOnSelectStatus(payoutStatusOptions.map((opt) => opt.id));
        transactionOnSelectStatus(transactionStatusOptions.map((opt) => opt.id));
      }
    },
  }),
});

export const FeeWisePaymentsRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(onLoadHooks)(withOnLoad(FeeWisePaymentsRoute)))),
);

FeeWisePaymentsRouteContainer.displayName = 'FeeWisePaymentsRouteContainer';

FeeWisePaymentsRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};
