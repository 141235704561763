import semver from 'semver';
import { getLogger } from '@sb-itops/fe-logger';
import { warn as displayWarningToUser, builder as buildMessage } from '@sb-itops/message-display';
import { envType, getFrontendEnv } from '@sb-itops/environment-config';
import { getBuildVersionP } from './get-build-version';

/* global SB_BUILD_VERSION */

const log = getLogger('start-checking-version-updates');

const pollingIntervals = {
  NORMAL: 15 * 60 * 1000,
  RAPID: 30 * 1000,
};

let versionPoller;

/**
 * startPollingForVersions
 *
 * Initiates checking for new version availablility.
 */
export const startPollingForVersionsP = () => {
  if (versionPoller) {
    return;
  }

  const frontEndEnv = getFrontendEnv();
  const pollingIntervalMs = getPollingInterval(frontEndEnv);
  versionPoller = setInterval(notifyUserIfNewVersionAvailableP, pollingIntervalMs);

  return notifyUserIfNewVersionAvailableP();
};

/**
 * stopPollingForVersions
 *
 * Stops polling for new version availability.
 */
export const stopPollingForVersions = () => {
  if (versionPoller) {
    clearInterval(versionPoller);
  }

  versionPoller = undefined;
};

/**
 * notifyUserIfNewVersionAvailableP
 *
 * Checks if a new version is available and displays a messge to the user if there is a new verison.
 */
export async function notifyUserIfNewVersionAvailableP() {
  try {
    const version = await getBuildVersionP();
    log.info(`app: ${SB_BUILD_VERSION}, server: ${version}`);

    const serverVersion = semver.coerce(version);
    const runningVersion = semver.coerce(SB_BUILD_VERSION);

    if (!semver.valid(serverVersion) || !semver.valid(runningVersion)) {
      log.error(`Server version (${version}) or build version(${SB_BUILD_VERSION}) not valid semvers`);
      return;
    }

    if (semver.lt(runningVersion, serverVersion)) {
      displayNewVersionMessageToUser();
      return;
    }

    log.debug('No version change detected');
  } catch (err) {
    log.warn('Could not get build version: ', err);
  }
}

/**
 * getPollingInterval
 *
 * Returns an amount of time in millseconds to be used for the new version polling period.
 *
 * @param frontEndEnv The current frontend environment
 */
function getPollingInterval(frontEndEnv) {
  switch (frontEndEnv) {
    case envType.LOCAL:
    case envType.DEV:
    case envType.RC:
      return pollingIntervals.RAPID;
    case envType.QA:
    case envType.PRODUCTION:
      return pollingIntervals.NORMAL;
    default:
      log.error('Encountered unknown environment:', frontEndEnv);
      return pollingIntervals.NORMAL;
  }
}

/**
 * displayNewVersionMessageToUser
 *
 * Displays a message to the user informing them of new version availability.
 *
 */
function displayNewVersionMessageToUser() {
  displayWarningToUser(
    buildMessage()
      .text('A new version of Smokeball is available. Please refresh your browser to get the latest features.')
      .group('versionNotification')
      .action(() => window.location.reload())
      .actionText('Refresh browser'),
  );
}
