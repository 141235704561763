import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column } from '@sb-itops/react/table';
import Styles from './RestLogsTable.module.scss';

export const RestLogsTable = React.memo(({ logItems }) => {
  const urlCellRenderer = ({ cellData }) => cellData && cellData.URL;
  const correlationCellRenderer = ({ cellData }) => cellData && cellData.CORRELATION_ID;

  return (
    <Table className={Styles.restLogsTable} list={logItems}>
      <Column dataKey="timestamp" label="Timestamp" flexGrow={1} />
      <Column dataKey="context" label="URL" cellRenderer={urlCellRenderer} flexGrow={1} />
      <Column dataKey="context" label="Correlation ID" cellRenderer={correlationCellRenderer} flexGrow={1} />
      <Column dataKey="message" label="Message" flexGrow={6} disableSort />
    </Table>
  );
});

RestLogsTable.displayName = 'LogItemsTable';

RestLogsTable.propTypes = {
  logItems: PropTypes.array.isRequired,
};

RestLogsTable.defaultProps = {};
