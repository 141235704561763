'use strict';

const { fetchPostP } = require('@sb-itops/redux/fetch');

/**
 * Calls matter permissions to check the matter ids, returns a boolean lookup
 * keyed to the matterIds.
 *
 * @param { string[] } matterIds
 * @return { Promise<{ resourceId: string, userId: string, accountId: string, isAuthorized: boolean, error?: string }[]> }
 */
const fetchAccessAuthorisationsFromWebP = async (resourceIds) => {
  if (!resourceIds || resourceIds.length === 0) {
    return [];
  }

  const ENDPOINT = '/itops/authorisation/:accountId/';

  const response = await fetchPostP({
    path: ENDPOINT,
    responseType: undefined,
    skipCamelCase: undefined,
    fetchOptions: { body: JSON.stringify({ resourceIds }) },
  });

  if (response && response.status === 200 && response.body) {
    return response.body;
  }
  throw new Error(`Error occurred fetching smokeball permissions: ${response && JSON.stringify(response)}`);
};

module.exports = {
  fetchAccessAuthorisationsFromWebP,
};
