import TagManager from 'react-gtm-module';
import { getAppEnv, envType } from '@sb-itops/app-env';
import { getGoogleTagManagerId } from '@sb-itops/environment-config';
import { getRegion } from '@sb-itops/region';
import { featureActive } from '@sb-itops/feature';
import { getProductTier, getAccountId, getOwningCompany, getUserId, getUsername } from "web/services/user-session-management";

const initialiseGoogleTagManager = ({ log, defaultTeardown, firmDetails }) => {
  if (!featureActive('BB-11596')) {
    return defaultTeardown;
  }

  try {
    const appEnv = getAppEnv();

    // Google recommend you use the same tag for all similar environments and regions and then split them in analytics
    const googleTagManagerId = getGoogleTagManagerId();
    const dataLayer = {
      tier: getProductTier(),
      region: getRegion(),
      productLabel: getOwningCompany(),
      firmId: getAccountId(),
      firmName: firmDetails && firmDetails.firmName,
      userId: getUserId(),
      userEmail: getUsername(),
    };

    const googleTagManagerConfig =
      appEnv === envType.LOCAL ? null : { gtmId: googleTagManagerId, dataLayer };

    if (googleTagManagerConfig) {
      TagManager.initialize(googleTagManagerConfig);
    }
  } catch (err) {
    // We shouldn't stop the user from using the web app if Google Tag Manager fails, just log it.
    log.warn('Failed to initialise Google Tag Manager', err);
  }

  return defaultTeardown;
};

export { initialiseGoogleTagManager };
