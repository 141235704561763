const PDF_ENDPOINT = 'billing/invoice-reminders/preview-settings';
const ACCOUNT_REMINDER_MESSAGE_GROUP = 'ACCOUNT_REMINDER_MESSAGE_GROUP';

angular.module('sb.billing.webapp').component('sbAccountReminderPreviewPdf', {
  templateUrl: 'ng-components/account-reminder-preview-pdf/account-reminder-preview-pdf.html',
  bindings: { settings: '<' },
  controller: function (sbLoggerService, sbGenericEndpointService, sbMessageDisplayService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbAccountReminderPreviewPdf');

    ctrl.isReloading = false;

    ctrl.$onInit = refreshPdf;
    ctrl.$onChanges = refreshPdf;

    function refreshPdf () {
      log.info('refreshng PDF');
      ctrl.isReloading = true;

      if (_.isEmpty(ctrl.settings)) {
        return;
      }

      // in devapp, webpack is having problems when $onInit uses async/await. We use plain promises as a workaround. 
      downloadPdfP(ctrl.settings)
        .then(
          (pdfB64) => renderPdf(pdfB64),
          (ex) => handleError(ex)
        )
        .finally(() =>  {
          ctrl.isReloading = false;
        });
    }

    function downloadPdfP(data) {
      return sbGenericEndpointService.postPayloadP(PDF_ENDPOINT, undefined, data);
    }

    function renderPdf (pdfB64) {
      log.info(`Loaded PDF data for invoice reminder`);
      ctrl.pdfDataUrl = `data:application/pdf;base64,${pdfB64}`; //TODO
    }

    function handleError (e) {
      log.error(`Failed to download invoice reminder PDF`, e);
      sbMessageDisplayService.error(
        sbMessageDisplayService
        .builder()
        .text(`Failed to download invoice reminder PDF`)
        .group(ACCOUNT_REMINDER_MESSAGE_GROUP)
      );
    }
  }
});
