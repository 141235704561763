import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getFirmUtbmsTasks, getById as getTaskByCode } from '@sb-billing/redux/utbms-task-codes';
import { getFeeCustomTaskCodes } from '@sb-billing/redux/custom-task-code';
import TaskDropdown from './TaskDropdown';

const mapStateToProps = (state, { selectedTaskCode, onSelectionChange, disabled }) => {
  const originalSelectedTask = getTaskByCode(selectedTaskCode);
  const utbmsTasks = getFirmUtbmsTasks();
  const customUtbsmTasks = getFeeCustomTaskCodes();
  const tasks = [
    { group: 'UTBMS CODES', tasks: utbmsTasks },
    { group: 'CUSTOM UTBMS CODES', tasks: customUtbsmTasks },
  ];

  return {
    disabled,
    tasks,
    selectedTaskCode,
    originalSelectedTask,
    onSelectionChange,
  };
};

const TaskDropdownContainer = withReduxStore(connect(mapStateToProps)(TaskDropdown));

TaskDropdownContainer.propTypes = {
  selectedTaskCode: PropTypes.string,
  onSelectionChange: PropTypes.func,
};

TaskDropdownContainer.defaultProps = {
  selectedTaskCode: undefined,
  onSelectionChange: undefined,
};

export default TaskDropdownContainer;
