angular
  .module('sb.billing.webapp')
  .controller('sbTrustStatementCancelController', function(sbEnvironmentConfigService, sbGenericEndpointService, sbMessageDisplayService) {
    if (sbEnvironmentConfigService.getFrontendEnv() === 'PRODUCTION') {
      return;
    }

    const ctrl = this;
    ctrl.cancelRequest = cancelRequest;

    async function cancelRequest() {
      try {
        await sbGenericEndpointService.postPayloadP(`billing/report-trust-statement/cancel`);
        sbMessageDisplayService.success(`Cancelled`);
      } catch (err) {
        sbMessageDisplayService.error(`Failed: ${err.message}`);
      }
    }
  });
