import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { DepositFundsForm } from '../deposit-funds-form';
import Styles from './DepositFundsModal.module.scss';

export const DepositFundsModal = React.memo(
  ({
    scope,
    isSubmitLocked,
    onClose,
    onDepositFunds,
    isVisible,
    contactId,
    matterId,
    bankAccountId,
    localisationConfig,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.depositFundsModal}
        isVisible={isVisible}
        title="Deposit Funds"
        body={
          <DepositFundsForm
            contactId={contactId}
            matterId={matterId}
            bankAccountId={bankAccountId}
            scope={scope}
            localisationConfig={localisationConfig}
          />
        }
        footer={
          <div>
            <div className={Styles.buttonContainer}>
              <Button
                className={Styles.processButton}
                onClick={onDepositFunds}
                disabled={isSubmitLocked}
                form={scope}
                locked={isSubmitLocked}
                size="full-width"
              >
                Process
              </Button>
            </div>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

DepositFundsModal.displayName = 'DepositFundsModal';

DepositFundsModal.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  onDepositFunds: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isSubmitLocked: PropTypes.bool,
  localisationConfig: PropTypes.object.isRequired,
};

DepositFundsModal.defaultProps = {
  matterId: undefined,
  contactId: undefined,
  bankAccountId: undefined,
  isSubmitLocked: false,
  onClose: () => {},
};
