import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@sb-itops/react/button';
import { DatePicker, forms2PropTypes, useTranslation } from '@sb-itops/react';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { CreateBankReconciliation } from '../bank-reconciliation';

import Styles from './BankReconciliationsSetup.module.scss';

const BankReconciliationsSetup = ({
  trustAccountId,
  trustAccountName,
  formInvalid,
  finishSetupModalId,
  removeSetupModalId,
  showFinishSetupModal,
  showRemoveSetupModal,
  reconStartDate,
  fromFilterDate,
  maxToDate,
  toFilterDate,
  isRemoved,
  isAccountClosed,
  selectedTxnIds,
  selectedDepositSlips,
  selectedConsolidatedTransactions,
  transactions,
  depositSlips,
  consolidatedTransactions,
  isSaving,
  isRemoving,
  scope,
  // callbacks
  onClickLink,
  onFieldValueUpdated,
  onReconStartDateChange,
  finishSetup,
  removeReconciliationSetup,
  validateForm,
  getDayBefore,
  setIsSaving,
  setIsRemoving,
}) => {
  const { t } = useTranslation();
  // when we navigate away this page may be rendered for a split second without the proper route params
  // just return null if that happens
  if (!trustAccountId) {
    return null;
  }
  return (
    <div className={Styles.bankReconciliationsSetup}>
      <div className={Styles.reconHeader}>
        <h3>Bank Reconciliation - Setup: {trustAccountName}</h3>
      </div>
      <div className={Styles.panelContainer}>
        <div className={Styles.bodyPanel}>
          {!isAccountClosed && (
            <div className={Styles.trustInfo}>
              Before you can reconcile your {t('trust').toLowerCase()}, there is some initial information required.
            </div>
          )}
          {isAccountClosed && (
            <div className={Styles.trustInfo}>Cannot complete setup - {t('trustAccount').toLowerCase()} is closed</div>
          )}
          <div className={Styles.startDatePickerContainer}>
            <span className={Styles.boldText}>What is the start date for the first reconciliation?</span>
            <span className={Styles.startDatePicker}>
              <DatePicker
                onSelect={(date) => {
                  onReconStartDateChange(date);
                  onFieldValueUpdated({ maxToDate: date ? dateToInteger(getDayBefore(date)) : undefined });
                  onFieldValueUpdated({ toFilterDate: date ? dateToInteger(getDayBefore(date)) : undefined });
                  onFieldValueUpdated({ fromFilterDate: undefined });
                  validateForm();
                }}
                value={reconStartDate && reconStartDate.value && integerToDate(reconStartDate.value)}
                hasError={reconStartDate && reconStartDate.isInvalid}
                format="DD/MM/YYYY"
              />
            </span>
          </div>
          <hr />
          <div className={Styles.textContainer}>
            <span className={Styles.boldText}>
              Select any current unbanked {t('trust').toLowerCase()} receipts and unpresented {t('trust').toLowerCase()}{' '}
              {t('cheques')}
            </span>
          </div>
          <div className={Styles.datePickerContainer}>
            <span className={Styles.datePicker}>
              <label className={Styles.datePickerLabel}>From</label>
              <DatePicker
                onSelect={(date) => {
                  onFieldValueUpdated({ fromFilterDate: date ? dateToInteger(date) : undefined });
                  validateForm();
                }}
                value={fromFilterDate && fromFilterDate.value && integerToDate(fromFilterDate.value)}
                hasError={fromFilterDate && fromFilterDate.isInvalid}
                format="DD/MM/YYYY"
                maxDate={toFilterDate && toFilterDate.value && getDayBefore(integerToDate(toFilterDate.value))}
              />
            </span>
            <span className={Styles.datePicker}>
              <label className={Styles.datePickerLabel}>To</label>
              <DatePicker
                onSelect={(date) => {
                  onFieldValueUpdated({ toFilterDate: date ? dateToInteger(date) : undefined });
                  validateForm();
                }}
                value={toFilterDate && toFilterDate.value && integerToDate(toFilterDate.value)}
                hasError={toFilterDate && toFilterDate.isInvalid}
                format="DD/MM/YYYY"
                maxDate={maxToDate && maxToDate.value && integerToDate(maxToDate.value)}
              />
            </span>
          </div>
          <CreateBankReconciliation
            trustAccountId={trustAccountId}
            startDate={fromFilterDate && fromFilterDate.value && integerToDate(fromFilterDate.value)}
            endDate={toFilterDate && toFilterDate.value && integerToDate(toFilterDate.value)}
            onClickLink={onClickLink}
            showPrintDraft={false}
            transactions={transactions}
            depositSlips={depositSlips}
            consolidatedTransactions={consolidatedTransactions}
            summaryData={{}}
            onRowClick={() => {}}
            scope={scope}
            validateForm={validateForm}
          />
        </div>
        <div className={Styles.leftPanel}>
          <Button
            className={Styles.reconButton}
            disabled={formInvalid || isAccountClosed}
            onClick={showFinishSetupModal}
          >
            Finish Setup
          </Button>
          {!isRemoved && (
            <Button className={Styles.reconButton} onClick={showRemoveSetupModal}>
              Remove Reconciliation Setup
            </Button>
          )}
        </div>
      </div>

      <ConfirmationModalDialog
        onConfirm={async () => {
          setIsSaving(true);
          finishSetup({
            selectedDepositSlips,
            selectedTxnIds,
            selectedConsolidatedTransactions,
            depositSlips,
            transactions,
            consolidatedTransactions,
          });
          setIsSaving(false);
        }}
        isConfirming={isSaving}
        modalId={finishSetupModalId}
        title="Setup Reconciliation"
        body={`Warning: ${t(
          'finalising',
        )} this bank reconciliation setup will lock all of the historical transactions prior to the reconciliation start date.`}
        primaryButtonText="Setup"
      />
      <ConfirmationModalDialog
        onConfirm={() => {
          setIsRemoving(true);
          removeReconciliationSetup();
          setIsRemoving(false);
        }}
        isConfirming={isRemoving}
        modalId={removeSetupModalId}
        title="Remove Reconciliation Setup"
        body="Warning: removing this bank reconciliation setup will un-lock all historical transactions."
        primaryButtonText="Remove"
      />
    </div>
  );
};

BankReconciliationsSetup.displayName = 'BankReconciliationsSetup';

BankReconciliationsSetup.propTypes = {
  trustAccountId: PropTypes.string,
  trustAccountName: PropTypes.string,
  scope: PropTypes.string.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onReconStartDateChange: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool,
  isRemoved: PropTypes.bool,
  reconStartDate: forms2PropTypes.Forms2Field,
  fromFilterDate: forms2PropTypes.Forms2Field,
  maxToDate: forms2PropTypes.Forms2Field,
  toFilterDate: forms2PropTypes.Forms2Field,
  selectedTxnIds: PropTypes.array.isRequired,
  selectedDepositSlips: PropTypes.array.isRequired,
  selectedConsolidatedTransactions: PropTypes.array.isRequired,
  transactions: PropTypes.array.isRequired,
  depositSlips: PropTypes.array.isRequired,
  consolidatedTransactions: PropTypes.array.isRequired,
  isSaving: PropTypes.bool,
  isRemoving: PropTypes.bool,
  isAccountClosed: PropTypes.bool,
  // callbacks
  finishSetupModalId: PropTypes.string.isRequired,
  removeSetupModalId: PropTypes.string.isRequired,
  showFinishSetupModal: PropTypes.func.isRequired,
  showRemoveSetupModal: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  finishSetup: PropTypes.func.isRequired,
  removeReconciliationSetup: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  getDayBefore: PropTypes.func.isRequired,
  setIsSaving: PropTypes.func,
  setIsRemoving: PropTypes.func,
};

BankReconciliationsSetup.defaultProps = {
  formInvalid: true,
  isRemoved: false,
  trustAccountId: undefined,
  trustAccountName: undefined,
  reconStartDate: undefined,
  fromFilterDate: undefined,
  maxToDate: undefined,
  toFilterDate: undefined,
  isSaving: false,
  isRemoving: false,
  isAccountClosed: false,
  setIsSaving: () => {},
  setIsRemoving: () => {},
};

export default BankReconciliationsSetup;
