/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getSchemeValue, testingGetAllValuesForKey } from '@sb-itops/region-schemes';
import {
  RadioButtons,
  forms2PropTypes,
  Typeahead,
  useTranslation,
  Spinner,
  FloatingCard,
  TabListV2,
} from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';

import ContactPersonForm from '@sb-customer-management/react/contact-person-form/ContactPersonForm';
import ContactCompanyForm from '@sb-customer-management/react/contact-company-form/ContactCompanyForm';
import ContactTrustForm from '@sb-customer-management/react/contact-trust-form/ContactTrustForm';
import { AddressForm } from '@sb-customer-management/react/address-form';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { ContactTypeahead2 } from '@sb-customer-management/react';
import Styles from './CreateEditContactForm.module.scss';

const CreateEditContactForm = ({
  scope,
  phoneNumberScheme,
  addressScheme,
  formInitialised,
  contactType,
  onRadioChange,
  onFieldValueUpdated,
  onFieldValueSet,
  personFields,
  addressFields,
  companyFields,
  bankAccountFields,
  trustFields,
  submitFailed,
  deleteField,
  validateForm,
  formDisabled,
  companyOptions,
  peopleOptions,
  contactId,
  inline,
  isNewUI,
  isUtbmsEnabled,
  onAddressAutocompleteSelected,
  showBankDetailsTab,
  peopleOptionsDataLoading,
  peopleOptionsHasMore,
  onFetchPeopleOptions,
  onFetchMorePeopleOptions,
  companyOptionsDataLoading,
  companyOptionsHasMore,
  onFetchCompanyOptions,
  onFetchMoreCompanyOptions,
  linkedCompanyList,
}) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return <Spinner small />;
  }

  const isEdit = !!contactId;
  const alreadyLinkedToCompany = !!personFields?.linkedCompany?.originalValue;

  const contactTypeOptions = [
    {
      id: contactTypes.PERSON,
      label: 'Person',
      active: contactType === contactTypes.PERSON,
    },
    {
      id: contactTypes.COMPANY,
      label: `${capitalize(t('organisation'))}`,
      active: contactType === contactTypes.COMPANY,
    },
  ];

  if (featureActive('NUCWEB-564') && hasFacet(facets.trustContacts)) {
    contactTypeOptions.push({
      id: contactTypes.TRUST,
      label: 'Trust',
      active: contactType === contactTypes.TRUST,
    });
  }

  const onBankFieldsUpdated = (payload) => onFieldValueUpdated({ bankAccountFields: payload });

  const personForm = (
    <ContactPersonForm
      formInitialised={formInitialised}
      phoneNumberScheme={phoneNumberScheme}
      isUtbmsEnabled={isUtbmsEnabled}
      {...personFields}
      validateForm={validateForm}
      submitFailed={submitFailed}
      formDisabled={formDisabled}
      onFieldValueUpdated={(payload) => onFieldValueUpdated({ personFields: payload })}
    />
  );

  const addToExistingOrg = (
    <div>
      <label>Add to Existing {t('organisation')}?</label>
      {featureActive('NUCWEB-726') ? (
        <ContactTypeahead2
          contactOptions={companyOptions}
          contactOptionsHasMore={companyOptionsHasMore}
          defaultContactId={personFields?.linkedCompany?.value}
          defaultContactOptions={(linkedCompanyList || []).map((company) => ({
            label: company?.displayName,
            value: company?.id,
          }))}
          disabled={formDisabled || alreadyLinkedToCompany}
          className={classnames(Styles.formSelect)}
          placeholder="None"
          menuPlacement="top"
          isClearable
          isLoading={companyOptionsDataLoading}
          maxMenuHeight={250}
          // Callbacks
          onContactSelected={(option) =>
            onFieldValueUpdated({ 'personFields.linkedCompany': option?.value || undefined })
          }
          onFetchContactOptions={onFetchCompanyOptions}
          onFetchMoreContactOptions={onFetchMoreCompanyOptions}
        />
      ) : (
        <Typeahead
          selectedOption={
            companyOptions?.find(
              (option) =>
                option.value === personFields?.linkedCompany?.value ||
                option.label === personFields?.linkedCompany?.value,
            ) || undefined
          }
          disabled={formDisabled || alreadyLinkedToCompany}
          menuPlacement="top"
          options={companyOptions}
          placeholder="None"
          className={classnames(Styles.formSelect)}
          onSelect={(option) => {
            onFieldValueUpdated({ 'personFields.linkedCompany': option?.value || undefined });
          }}
        />
      )}
    </div>
  );

  const addressForm = (
    <AddressForm
      formInitialised={formInitialised}
      addressScheme={addressScheme}
      {...addressFields}
      validateForm={validateForm}
      formDisabled={formDisabled}
      onFieldValueUpdated={(payload) => onFieldValueUpdated({ addressFields: payload })}
      submitFailed={submitFailed}
      optional
      onAddressAutocompleteSelected={onAddressAutocompleteSelected}
      forceShowState
    />
  );

  const companyForm = (
    <ContactCompanyForm
      formInitialised={formInitialised}
      phoneNumberScheme={getSchemeValue('phoneNumberScheme')}
      addressScheme={getSchemeValue('addressScheme')}
      companyScheme={getSchemeValue('companyScheme')}
      contactId={contactId}
      {...companyFields}
      {...bankAccountFields}
      showBankDetailsTab={showBankDetailsTab}
      addressFields={addressFields}
      onFieldValueUpdated={onFieldValueUpdated}
      submitFailed={submitFailed}
      formDisabled={formDisabled}
      deleteField={deleteField}
      validateForm={validateForm}
      peopleOptions={peopleOptions}
      peopleOptionsDataLoading={peopleOptionsDataLoading}
      peopleOptionsHasMore={peopleOptionsHasMore}
      onFetchPeopleOptions={onFetchPeopleOptions}
      onFetchMorePeopleOptions={onFetchMorePeopleOptions}
      inline={inline}
      optionalAddress
      isUtbmsEnabled={isUtbmsEnabled}
      onAddressAutocompleteSelected={onAddressAutocompleteSelected}
    />
  );
  const trustForm = (
    <ContactTrustForm
      formInitialised={formInitialised}
      phoneNumberScheme={getSchemeValue('phoneNumberScheme')}
      addressScheme={getSchemeValue('addressScheme')}
      contactId={contactId}
      {...trustFields}
      {...bankAccountFields}
      showBankDetailsTab={showBankDetailsTab}
      addressFields={addressFields}
      onFieldValueUpdated={onFieldValueUpdated}
      onFieldValueSet={onFieldValueSet}
      submitFailed={submitFailed}
      formDisabled={formDisabled}
      deleteField={deleteField}
      validateForm={validateForm}
      peopleOptions={peopleOptions}
      peopleOptionsDataLoading={peopleOptionsDataLoading}
      peopleOptionsHasMore={peopleOptionsHasMore}
      onFetchPeopleOptions={onFetchPeopleOptions}
      onFetchMorePeopleOptions={onFetchMorePeopleOptions}
      inline={inline}
      optionalAddress
      isUtbmsEnabled={isUtbmsEnabled}
      onAddressAutocompleteSelected={onAddressAutocompleteSelected}
    />
  );

  if (isNewUI) {
    return (
      <div className={Styles.container}>
        {!isEdit && !alreadyLinkedToCompany && (
          <TabListV2
            selectedId={contactType}
            onTabSelectionChange={(tabId) => {
              onFieldValueUpdated({ contactType: tabId });
            }}
            list={contactTypeOptions}
            className={Styles.tabs}
          />
        )}
        {contactType === contactTypes.PERSON && (
          <>
            <FloatingCard classname={Styles.card} applyMargin={false}>
              <div className={Styles.heading}>
                <div className={Styles.title}>Details</div>
              </div>
              <div className={Styles.body}>{personForm}</div>
            </FloatingCard>
            <FloatingCard classname={Styles.card} applyMargin={false}>
              <div className={Styles.heading}>
                <div className={Styles.title}>Address</div>
              </div>
              <div className={Styles.body}>{addressForm}</div>
            </FloatingCard>
          </>
        )}
        {contactType === contactTypes.COMPANY && (
          <FloatingCard classname={Styles.card} applyMargin={false}>
            <div className={Styles.heading}>
              <div className={Styles.title}>Details</div>
            </div>
            <div className={Styles.body}>{companyForm}</div>
          </FloatingCard>
        )}
        {contactType === contactTypes.TRUST && (
          <FloatingCard classname={Styles.card} applyMargin={false}>
            <div className={Styles.heading}>
              <div className={Styles.title}>Details</div>
            </div>
            <div className={Styles.body}>{trustForm}</div>
          </FloatingCard>
        )}
      </div>
    );
  }

  return (
    <div className={Styles.container} id={scope}>
      <div className={Styles.radioButtonContainer}>
        <RadioButtons
          buttonList={contactTypeOptions}
          disabled={isEdit || alreadyLinkedToCompany}
          onRadioChange={onRadioChange}
          size="full-width"
        />
      </div>

      {contactType === contactTypes.PERSON && (
        <div className={Styles.contactPerson}>
          {!inline && <h4>Contact Details</h4>}
          <ContactPersonForm
            formInitialised={formInitialised}
            phoneNumberScheme={phoneNumberScheme}
            isUtbmsEnabled={isUtbmsEnabled}
            {...personFields}
            validateForm={validateForm}
            submitFailed={submitFailed}
            formDisabled={formDisabled}
            onFieldValueUpdated={(payload) => onFieldValueUpdated({ personFields: payload })}
          >
            {showBankDetailsTab && inline && hasFacet(facets.contactBankBalance) && (
              <>
                <hr className={Styles.hr} />
                <div>
                  <div className={classnames('form-group')}>
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>Account Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankAccountFields.accountName.value}
                          onChange={(e) => onBankFieldsUpdated({ accountName: e.target.value })}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label>Bank</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankAccountFields.bankName.value}
                          onChange={(e) => onBankFieldsUpdated({ bankName: e.target.value })}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>{t('bankBranchNumber')}</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankAccountFields.bankBranchNumber.value}
                          onChange={(e) => {
                            const re = /^(\d{1,3}|\d{3}-?\d{0,3})$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              onBankFieldsUpdated({ bankBranchNumber: e.target.value });
                            }
                          }}
                        />
                      </div>

                      <div>
                        <div className="form-group col-lg-6">
                          <label>Account Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={bankAccountFields.accountNumber.value}
                            onChange={(e) => {
                              const re = /^[0-9]*$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                onBankFieldsUpdated({ accountNumber: e.target.value });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <hr className={Styles.hr} />
            <div>{addressForm}</div>
          </ContactPersonForm>
          <hr className={classnames(Styles.hr, Styles.hrTop)} />
          {addToExistingOrg}
        </div>
      )}

      {contactType === contactTypes.COMPANY && (
        <div className={Styles.contactCompany}>
          {!inline && <h4>{capitalize(t('organisation'))} Details</h4>}
          {companyForm}
        </div>
      )}
      {contactType === contactTypes.TRUST && (
        <div className={Styles.contactTrust}>
          {!inline && <h4>Trust Details</h4>}
          {trustForm}
        </div>
      )}
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

CreateEditContactForm.displayName = 'CreateEditContactForm';
CreateEditContactForm.propTypes = {
  phoneNumberScheme: PropTypes.oneOf(testingGetAllValuesForKey('phoneNumberScheme')).isRequired,
  addressScheme: PropTypes.oneOf(testingGetAllValuesForKey('addressScheme')).isRequired,
  isUtbmsEnabled: PropTypes.bool,
  showBankDetailsTab: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  validateForm: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  contactType: PropTypes.oneOf(['person', 'company', 'trust']),
  onRadioChange: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired,
  onAddressAutocompleteSelected: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  companyOptions: PropTypes.arrayOf(PropTypes.object),
  peopleOptions: PropTypes.arrayOf(PropTypes.object),
  contactId: PropTypes.string,
  inline: PropTypes.bool, // The inline form has different requirements, e.g. optional address
  isNewUI: PropTypes.bool,
  linkedCompanyList: PropTypes.arrayOf(PropTypes.object),
  personFields: PropTypes.shape({
    title: Forms2Field,
    firstName: Forms2Field,
    lastName: Forms2Field,
    phoneAreaCode: Forms2Field,
    phoneNumber: Forms2Field,
    cellAreaCode: Forms2Field,
    cellNumber: Forms2Field,
    email: Forms2Field,
    ledesClientId: Forms2Field,
  }),
  bankAccountFields: PropTypes.shape({
    accountName: Forms2Field,
    accountNumber: Forms2Field,
    bankBranchNumber: Forms2Field,
    bankName: Forms2Field,
  }),
  addressFields: PropTypes.shape({
    buildingLevel: Forms2Field,
    unitNumber: Forms2Field,
    unitType: Forms2Field,
    streetNumber: Forms2Field,
    streetName: Forms2Field,
    streetType: Forms2Field,
    addressLine1: Forms2Field,
    addressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
    county: Forms2Field,
    locality: Forms2Field,
    country: Forms2Field,
  }),
  companyFields: PropTypes.shape({
    companyName: Forms2Field,
    companyType: Forms2Field,
    companyPhoneAreaCode: Forms2Field,
    companyPhoneNumber: Forms2Field,
    companyEmail: Forms2Field,
    companyFax: Forms2Field,
    companyFaxAreaCode: Forms2Field,
    companyLedesClientId: Forms2Field,
  }),
  trustFields: PropTypes.shape({
    trustName: Forms2Field,
    trustStatus: Forms2Field,
    trustPhoneAreaCode: Forms2Field,
    trustPhoneNumber: Forms2Field,
    trustNumber: Forms2Field,
    trustDate: Forms2Field,
    trustees: PropTypes.arrayOf(Forms2Field),
    trustFax: Forms2Field,
    trustFaxAreaCode: Forms2Field,
    trustLedesClientId: Forms2Field,
  }),
};

CreateEditContactForm.defaultProps = {
  linkedCompanyList: [],
  contactType: 'person',
  showBankDetailsTab: undefined,
  bankAccountFields: {},
  companyFields: {},
  trustFields: {},
  personFields: {},
  addressFields: {},
  companyOptions: undefined,
  peopleOptions: undefined,
  contactId: undefined,
  inline: false,
  isNewUI: false,
  isUtbmsEnabled: undefined,
};
export default CreateEditContactForm;
