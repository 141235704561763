'use strict';

const { paymentPlanStatuses } = require('../entities/constants');

const getPaymentPlanStatus = (status) => {
  switch (status) {
    case 0:
      return paymentPlanStatuses.ACTIVE;
    case 1:
      return paymentPlanStatuses.CLOSED;
    default:
      throw new Error(`Unknown status "${status}" for paymentPlan`);
  }
};

module.exports = {
  getPaymentPlanStatus,
};
