'use strict';

import { validateMaxEmailsNumber as isOverMaxEmailsNumber } from '@sb-itops/email'
import { interpolateText } from '@sb-billing/business-logic/evergreen-retainer';
import { getMap as getBankAccountBalances }  from '@sb-billing/redux/bank-account-balances';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { selectors }  from '@sb-billing/redux/bank-account-balances.2';
import { getPhoneNumber, getFirmName } from '@sb-firm-management/redux/firm-management';
import { getLineSummary as getMatterLineSummary } from '@sb-matter-management/redux/matters';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';

const { getMatterTrustBalance } = selectors;

/**
 * @decprecated 
 * Use TrustDepositRequestModal/Form instead
 */
angular.module('sb.billing.webapp').component('sbCbuiRequestRetainer', {
  templateUrl: 'ng-composable-components/callback-ui/request-retainer/cbui-request-retainer.html',
  bindings: { debtorId: '<', callbackFn : '&', matterId: '<', accountType: '<' },
  controller: function (sbSimpleContactMbService, sbFirmManagementMbService, focusService, sbDefaultMatterBillingConfigurationService, sbBillingConfigurationService, sbCbuiRequestRetainerValidator, sbLocalisationService) {
    const ctrl = this;

    ctrl.send = send;
    ctrl.validateTo = validateTo;
    ctrl.validateFrom = validateFrom;
    ctrl.validateCc = validateCc;
    ctrl.validateBcc = validateBcc;
    ctrl.validateToEmailsNumber = validateToEmailsNumber;
    ctrl.validateCcEmailsNumber = validateCcEmailsNumber;
    ctrl.validateBccEmailsNumber = validateBccEmailsNumber;

    ctrl.view = {
      salutation: '',
      staffName: '',
      loading: true,
    };
    ctrl.model = {
      from: '',
      to: '',
      subject: '',
      message: '',
      bcc: '',
      cc: '',
    };

    ctrl.$onInit = async () => {
      if (ctrl.debtorId) {
        await updateClientAddressP(ctrl.debtorId);
        updateSender();
        updateEmail(ctrl.debtorId, ctrl.matterId);
        initCcBcc(ctrl.matterId);
      }

      ctrl.validator = new sbCbuiRequestRetainerValidator(() => ctrl.model);
      ctrl.view.loading = false;
    };

    function initCcBcc(matterId) {
      const matterEmailSettings = getMatterEmailSettings(matterId) || {};
      ctrl.model.cc = (matterEmailSettings.cCAddresses || []).join(', ');
      ctrl.model.bcc = (matterEmailSettings.bCCAddresses || []).join(', ');
    }  

    async function updateClientAddressP (debtorId) {
      const contactInfoData = await sbSimpleContactMbService.getPeopleP(debtorId);
      const { to, salutation } = sbSimpleContactMbService.getEmails(contactInfoData);

      ctrl.model.to = to
      ctrl.view.salutation = salutation;
    }

    async function updateSender() {
      const { name, email } = await sbFirmManagementMbService.getStaffEmailDetails();

      ctrl.view.staffName = name;
      ctrl.model.from = email;
    }

    async function updateEmail(debtorId, matterId) {
      const defaultMatterBillingConfig = sbDefaultMatterBillingConfigurationService.getConfig();
      const matterBillingConfig = sbBillingConfigurationService.getByMatterId(matterId);
      const staff = sbFirmManagementMbService.getLoggedInStaffMember();
      const replenishAmount = matterBillingConfig.trustRetainerSettingsOverridden ? matterBillingConfig.trustRetainerReplenishAmount : defaultMatterBillingConfig.trustRetainerReplenishAmount;
      const replenishUpToAmount = matterBillingConfig.trustRetainerSettingsOverridden ?
        matterBillingConfig.trustRetainerReplenishAmount :
        defaultMatterBillingConfig.trustRetainerReplenishAmount;
      const minimumTrustRetainerAmount = matterBillingConfig.trustRetainerSettingsOverridden ?
        matterBillingConfig.minimumTrustRetainerAmount :
        defaultMatterBillingConfig.minimumTrustRetainerAmount;
      // Get trust balance across all trust accounts
      const matterTrustBalance = getMatterTrustBalance(getBankAccountBalances(), { matterId, balanceType: balanceTypes.BALANCE });
      const matterTotals = getMatterTotalsById(matterId);
      const amountRequested = replenishUpToAmount - matterTrustBalance;

      const emailData = {
        debtorName: await getDebtorName(debtorId),
        amountRequested,
        accountType: 'Trust', // Always trust for evergreen retainer.
        amount: replenishAmount,
        staffName: staff.name,
        matterLineSummary: getMatterLineSummary(matterId),
        replenishUpToCents: replenishUpToAmount,
        amountRequestedCents: amountRequested,
        outstandingBalance: matterTotals && matterTotals.unpaid,
        phoneNumber: getPhoneNumber(),
        firmName: getFirmName(),
        trustBalance: matterTrustBalance,
        minimumThreshold: minimumTrustRetainerAmount,
      }

      ctrl.model.message = interpolateText(defaultMatterBillingConfig.trustRetainerReplenishEmailBody, emailData, sbLocalisationService.t);
      ctrl.model.subject = interpolateText(defaultMatterBillingConfig.trustRetainerReplenishEmailSubject, emailData, sbLocalisationService.t);
      ctrl.model.sendCopy = !defaultMatterBillingConfig.doNotSendReplenishEmailToUser;
    }

    async function getDebtorName (debtorId) {
      const contactInfoData = await sbSimpleContactMbService.getPeopleP(debtorId);
      const contactInfo = contactInfoData.reduce((all, info) => all.concat(info), []).filter((info) => info.email && info.salutation);
      return contactInfo.map((c) => `${c.salutation}`).join(', ');
    }

    function validateTo(skipDirtyChecking) {
      return ctrl.validator ? ctrl.validator.validateField('to', skipDirtyChecking) : false;
    }

    function validateFrom(skipDirtyChecking) {
      return ctrl.validator ? ctrl.validator.validateField('from', skipDirtyChecking) : false;
    }

    function validateCc(skipDirtyChecking) {
      return ctrl.validator ? (ctrl.model.cc === '' || ctrl.validator.validateField('cc', skipDirtyChecking)) : false;
    }

    function validateBcc(skipDirtyChecking) {
      return ctrl.validator ? (ctrl.model.bcc === '' || ctrl.validator.validateField('bcc', skipDirtyChecking)) : false;
    }

    function validateToEmailsNumber() {
      return isOverMaxEmailsNumber(ctrl.model.to);
    }
    function validateCcEmailsNumber() {
      return isOverMaxEmailsNumber(ctrl.model.cc) || ctrl.model.cc === '';
    }
    function validateBccEmailsNumber() {
      return isOverMaxEmailsNumber(ctrl.model.bcc) || ctrl.model.bcc === '';
    }

    function validate(skipDirtyChecking) {
      const isOverMaxEmailsNumber = validateToEmailsNumber() && validateCcEmailsNumber() && validateBccEmailsNumber();
      return validateTo(skipDirtyChecking) && validateFrom(skipDirtyChecking) && validateCc(skipDirtyChecking) && validateBcc(skipDirtyChecking) && isOverMaxEmailsNumber;
    }

    function send() {
      if (validate(true)) {
        ctrl.callbackFn({data: marshal({ matterId: ctrl.matterId, ...ctrl.model})});
      } else {
        if (!validateTo(true) || !validateToEmailsNumber()) {
          focusService.focusOn('to-email');
        } else if (!validateCc(true) || !validateCcEmailsNumber()) {
          focusService.focusOn('cc-email');
        } else if (!validateBcc(true) || !validateBccEmailsNumber()) {
          focusService.focusOn('bcc-email');
        } else if (!validateFrom(true)) {
          focusService.focusOn('from-email');
        }
      }
    }

    function marshal (rawData) {
      let bcc = rawData.bcc;
      if (rawData.sendCopy) {
        bcc = rawData.bcc ? `${rawData.bcc}, ${rawData.from}` : rawData.from;
      }

      return {
        matterId: rawData.matterId,
        to: rawData.to,
        from: rawData.from,
        subject: rawData.subject,
        body: rawData.message,
        // empty string would not pass endpoint validation so either email or undefined
        cc: rawData.cc ? rawData.cc : undefined,
        bcc: bcc ? bcc : undefined,
      };
    }
  }
});
