'use strict';

/**
 * @typedef { import('../types.js').StripeChargeFormData } StripeChargeFormData
 */

const { capitalize } = require('@sb-itops/nodash');

/**
 * getChargeDescription
 *
 * @param {Object} params
 * @param {number} params.amountInCents The amount for the charge.
 * @param {boolean} params.includeStaffDetails Flag to indicate whether to include staff details in the description.
 * @param {string} [params.staffName] Staff name to be used in the staff details portion of description. Ignored if includeStaffDetails is false.
 * @param {StripeChargeFormData} params.providerSpecificFields The data received from the stripe charge form
 * @param {function} params.t Translate function

 * @returns {string} A human readable description of the charge.
 */
const getChargeDescription = ({ amountInCents, staffName, providerSpecificFields, t }) => {
  const dollarAmountWithSymbol = t('cents', { val: amountInCents });

  const staffDetails = staffName ? ` (Processed by ${staffName})` : '';

  const { paymentToken } = providerSpecificFields;

  const cardType = paymentToken && paymentToken.card && paymentToken.card.brand;
  const cardholderName = paymentToken && paymentToken.card && paymentToken.card.name;
  const cardNumberTruncated = paymentToken && paymentToken.card && paymentToken.card.last4;

  const creditCardLabel = cardType ? `${capitalize(cardType)} credit card` : 'Credit card'; // Amex, visa, mastercard etc.
  return `${creditCardLabel} charge from ${cardholderName} (***${cardNumberTruncated}) of ${dollarAmountWithSymbol}${staffDetails} via Stripe`;
};

module.exports = {
  getChargeDescription,
};
