import { pluck } from 'lodash';
import {
  updateCache as updateRedux,
  getMap,
  getUtbmsTasks,
  getFirmUtbmsTasks,
  getFirmUtbmsTasksExpense as getExpenseTaskCodes,
} from '@sb-billing/redux/utbms-task-codes';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('@sb-billing/services')
  .service('sbUtbmsTaskCodeService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    if (!hasFacet(facets.utbms)) {
      return;
    }

    const that = this;
    const utbmsTaskCodesEndpoint = 'billing/utbms-codes/task-code';

    sbGenericCacheService.setupCache({
      name: 'sbUtbmsTaskCodeService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: utbmsTaskCodesEndpoint },
        poll: 60
      },
      updateRedux
    });

    that.getUtbmsTasks = getUtbmsTasks;
    that.getCodes = getCodes;
    that.getUtbmsTaskByCode = getUtbmsTaskByCode;
    that.getFirmUtbmsTasks = getFirmUtbmsTasks;
    that.getExpenseTaskCodes = getExpenseTaskCodes;

    function getUtbmsTaskByCode(code) {
      return getMap()[code];
    }

    function getCodes() {
      return pluck(getUtbmsTasks(), 'code');
    }
  });
