'use strict';

/**
 * Given a reportConfig object and a valid bearer token, this function calls an endpoint
 * which creates a ssrs URL that can be used to load the SSRS report represented by the
 * reportConfig object.
 */
const makeSsrsReportUrl = ({ reportConfig, authToken, windowsTimeZone }) => {
  const ssrsUrl = encodeURI(
    `${reportConfig.baseUrl}?report=${reportConfig.reportId}&msTimezone=${windowsTimeZone}&sbat=${authToken}`,
  );
  return ssrsUrl;
};

module.exports = {
  makeSsrsReportUrl,
};
