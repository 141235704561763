import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as messageDisplay from '@sb-itops/message-display';
import { useSingleLedesDownload, useAsyncWithRetries } from 'web/hooks';
import DownloadLedesDropdownOption from './DownloadLedesDropdownOption';

const onRetriesExhausted = () => {
  messageDisplay.error('Something went wrong. Please try again in a few minutes, or contact Support.');
};

const hooks = () => ({
  useExportLedes: ({ invoiceIds, setButtonLoading }) => {
    const onLoadingChange = (isLoading) => {
      setButtonLoading(isLoading);
    };
    const { downloadLedes } = useSingleLedesDownload();
    const { executeFunction } = useAsyncWithRetries({
      fn: () => downloadLedes([invoiceIds]),
      retries: 5,
      onRetriesExhausted,
      onLoadingChange,
    });

    return {
      downloadLedes: executeFunction,
    };
  },
});

const DownloadLedesDropdownOptionContainer = composeHooks(hooks)(DownloadLedesDropdownOption);

DownloadLedesDropdownOptionContainer.displayName = 'DownloadLedesDropdownOption';

DownloadLedesDropdownOptionContainer.propTypes = {
  invoiceIds: PropTypes.array.isRequired,
  setButtonLoading: PropTypes.func.isRequired,
};

DownloadLedesDropdownOptionContainer.defaultProps = {};

export default DownloadLedesDropdownOptionContainer;
