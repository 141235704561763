'use strict';

const { regionType, getRegion } = require('@sb-itops/region');

const taxRateByRegion = {
  [regionType.AU]: 1000,
  [regionType.GB]: 2000,
  [regionType.US]: 0,
};

const getDefaultTaxRate = () => taxRateByRegion[getRegion()];

module.exports = {
  getDefaultTaxRate,
};
