'use strict';

const moment = require('moment');

const determinePaymentMethodExpiry = ({ expiry }) => {
  const now = moment();

  const endOfExpiryMonth = moment(expiry, 'MM/YYYY').add(1, 'month');
  if (endOfExpiryMonth.isBefore(now)) {
    return 'expired';
  }

  const startOfExpiryWarning = moment(endOfExpiryMonth).subtract(30, 'days');
  if (startOfExpiryWarning.isBefore(now)) {
    return 'expiryWarning';
  }

  return '';
};

module.exports = {
  determinePaymentMethodExpiry,
};
