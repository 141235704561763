import { useMemo, useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';

import { staffTargetAssignmentTypeOptions } from '@sb-billing/business-logic/staff-targets';
import { useSubscribedQuery } from 'web/hooks';
import { StaffTargetGroupTableData, StaffTargetGroupEntities, StaffTargetAssignments } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { StaffTargets } from './StaffTargets';

const hooks = () => ({
  useTargetGroupTable: () => {
    const [showInactive, setShowInactive] = useState(false);
    const {
      data,
      error,
      loading: targetGroupsLoading,
    } = useSubscribedQuery(StaffTargetGroupTableData, {
      variables: {
        staffTargetGroupListFilter: {
          includeInactive: showInactive,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    return {
      showInactive,
      setShowInactive,
      targetGroups: data?.staffTargetGroupList?.results || [],
      targetGroupsLoading,
    };
  },
  useEditTargetGroup: () => {
    const [currentTargetGroup, setCurrentTargetGroup] = useState();
    const [currentTargetGroupId, setCurrentTargetGroupId] = useState();
    const [isAddMode, setIsAddMode] = useState(false);

    useSubscribedQuery(StaffTargetGroupEntities, {
      variables: {
        ids: [currentTargetGroupId],
      },
      onCompleted: (data) => {
        const staffTargetGroup = currentTargetGroupId ? data?.staffTargetGroups?.[0] : undefined;
        setCurrentTargetGroup(JSON.parse(JSON.stringify(staffTargetGroup))); // so this can be used when saving the form
      },
      skip: !currentTargetGroupId,
    });

    const clearCurrentTargetGroup = () => {
      setCurrentTargetGroupId(undefined);
      setCurrentTargetGroup(undefined);
      setIsAddMode(false);
    };

    const addNewTargetGroup = () => {
      setCurrentTargetGroupId(undefined);
      setCurrentTargetGroup(undefined);
      setIsAddMode(true);
    };

    return {
      currentTargetGroup,
      currentTargetGroupId,
      isAddMode,
      // func & callbacks
      addNewTargetGroup,
      clearCurrentTargetGroup,
      setCurrentTargetGroupId,
    };
  },
});

const dependentHooks = () => ({
  useTargetAssignmentTable: ({ targetGroups }) => {
    const { activeTargetGroupOptions, activeTargetGroupLookup } = useMemo(
      () =>
        targetGroups.reduce(
          (acc, group) => {
            if (!group.isInactive) {
              acc.activeTargetGroupOptions.push({
                label: group.name,
                value: group.id,
              });
              acc.activeTargetGroupLookup[group.id] = group;
            }
            return acc;
          },
          { activeTargetGroupOptions: [], activeTargetGroupLookup: {} },
        ),
      [targetGroups],
    );

    const staffFilterOptions = [...staffTargetAssignmentTypeOptions, ...activeTargetGroupOptions];

    const [staffFilter, setStaffFilter] = useState(staffFilterOptions[0].value); // default to 'All'
    const {
      data,
      error,
      loading: isStaffTargetAssignmentsLoading,
    } = useSubscribedQuery(StaffTargetAssignments, {
      variables: {
        staffMemberFilter: {
          excludeFormerStaff: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    return {
      staffFilter,
      staffFilterOptions,
      setStaffFilter,
      activeTargetGroupOptions,
      activeTargetGroupLookup,
      staffMembers: data?.staffMembers || [],
      isStaffTargetAssignmentsLoading,
    };
  },
});

export const StaffTargetsContainer = withApolloClient(composeHooks(hooks)(composeHooks(dependentHooks)(StaffTargets)));

StaffTargetsContainer.displayName = 'StaffTargetsContainer';
