angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/advanced-search/documents';

  $stateProvider
    .state('home.billing.advanced-search.documents', {
      url: '/documents?searchTerm',
      templateUrl: BASE_DIRECTORY + '/documents.html',
      data: {
        authorized: true,
        navHighlights: ['billing'],
      },
      reloadOnSearch: false,
    });
});
