import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { decodeHtml } from '@sb-billing/encode-decode-html-invoice-settings';
import domain from '../domain';

const titleLineType = {
  None: 'None',
  MatterDescription: 'MatterDescription',
  MatterTitle: 'MatterTitle',
  Custom: 'Custom',
};

const titleLine1TypeByValue = {
  0: titleLineType.None,
  1: titleLineType.MatterDescription,
  2: titleLineType.Custom,
  3: titleLineType.MatterTitle,
};

const titleLine2TypeByValue = {
  0: titleLineType.None,
  1: titleLineType.MatterDescription,
  2: titleLineType.Custom,
  3: titleLineType.MatterTitle,
};

const api = cacheFactory({
  domain,
  name: 'invoice-settings',
  keyPath: 'details',
  ngCacheName: 'sbInvoiceSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
  changesetProcessor: ({ entities }) => {
    if (entities[0]) {
      // we knowingly mutate the entity as the generic cache needs this key to persist it
      // eslint-disable-next-line no-param-reassign
      entities[0].details = 'details';
      const settings = { ...entities[0] };

      if (Number.isInteger(settings.titleLine1Option)) {
        settings.titleLine1Option = titleLine1TypeByValue[settings.titleLine1Option];
      }

      if (Number.isInteger(settings.titleLine2Option)) {
        settings.titleLine2Option = titleLine2TypeByValue[settings.titleLine2Option];
      }
      return [settings];
    }
    return [];
  },
});

export const { getById, getMap, getList, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const getDetails = () => getMap().details;

export const getSettings = () => {
  const cached = getList()[0];

  return cached ? decodeHtml(cached) : {};
};
