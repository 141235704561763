import { SET_DEBUG_ENTITY, CLEAR_DEBUG_ENTITY_HISTORY, TOGGLE_SHOW_DEBUG } from './types';

const initialState = {
  showDebug: false,
  debugEntity: undefined,
  debugEntityHistory: [],
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SHOW_DEBUG: {
      return {
        ...state,
        showDebug: !state.showDebug,
      };
    }

    case SET_DEBUG_ENTITY: {
      const newDebugEntity = {
        ...action.payload,
        timestamp: new Date(),
      };

      const debugEntityHistory = [...state.debugEntityHistory];

      // add to history where appropriate (to front of list)
      if (debugEntityHistory.length > 0) {
        const lastDebugEntity = debugEntityHistory[0];

        // 1. debug entity with no groupEntityCacheName and groupEntityId set
        // will appear as a new debug entity in history
        // 2. debug entity with groupEntityCacheName and groupEntityId set
        // will be grouped under the specified group entity
        const isPartOfAGroup = !!newDebugEntity.groupEntityCacheName;

        if (
          isPartOfAGroup &&
          lastDebugEntity.entityCacheName === newDebugEntity.groupEntityCacheName &&
          lastDebugEntity.entityId === newDebugEntity.groupEntityId
        ) {
          // group related entities under existing debug entity, the assumption here is
          // the grouping entity is always available as the last debug entity in history.

          // if an entity is already part of the group, don't add it again.
          if (lastDebugEntity.relatedEntities && lastDebugEntity.relatedEntities.length > 0) {
            if (
              !lastDebugEntity.relatedEntities.find(
                (entity) =>
                  entity.entityId === newDebugEntity.entityId &&
                  entity.entityCacheName === newDebugEntity.entityCacheName,
              )
            ) {
              lastDebugEntity.relatedEntities.unshift(newDebugEntity);
            }
          } else {
            lastDebugEntity.relatedEntities = [newDebugEntity];
          }
        } else if (
          lastDebugEntity.entityCacheName !== newDebugEntity.entityCacheName ||
          lastDebugEntity.entityId !== newDebugEntity.entityId
        ) {
          debugEntityHistory.unshift(newDebugEntity);
        } // don't add the same debug entity if it's the last one in history
      } else {
        debugEntityHistory.unshift(newDebugEntity);
      }

      // make sure history can never grow to much more than 100 items. This is so storage
      // and memory usage can never blow out after extended period of use. Storage is important
      // here because we are persisting this history from redux to indexDB to help deal with
      // remote support issues. This way we have some idea of where the user last left off.
      if (debugEntityHistory.length > 100) {
        debugEntityHistory.pop(); // popping the last one off is sufficient as it ensure the list doesn't grow
      }

      return {
        ...state,
        debugEntity: newDebugEntity,
        debugEntityHistory,
      };
    }

    case CLEAR_DEBUG_ENTITY_HISTORY: {
      return {
        ...state,
        debugEntityHistory: initialState.debugEntityHistory,
      };
    }

    default: {
      return state;
    }
  }
};
