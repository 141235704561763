import { featureActive } from '@sb-itops/feature';

angular
  .module('@sb-billing/services')
  .service('sbInvoiceExportLedesService', function(sbGenericEndpointService) {
    const that = this;

    const batchExportLedes1998bEndpoint = 'billing/invoice-export/ledes-1998a-batch';

    //that.downloadLedes1998b = downloadLedes1998b; deprecated for react hook useSingleLedesDownload
    that.batchExportLedes1998bP = batchExportLedes1998bP;


    function batchExportLedes1998bP (requestId, invoiceIds) {
      const extraArgs = { skipCamelCase: true };

      let body = {
        requestId,
        exportNotesAsDescription: featureActive('BB-11888'),
        invoiceIds,
      };
  
      return sbGenericEndpointService.postPayloadP(batchExportLedes1998bEndpoint, ``, body, 'POST', extraArgs);
    }
  });