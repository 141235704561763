import { getByMatterId as getInvoicesByMatterId, invoiceStatuses } from '@sb-billing/redux/invoices';
import { getMap as getInvoicesTotalsMap } from '@sb-billing/redux/invoice-totals';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals/selectors';
import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';

const calculateUnpaidExcInterest = (invoicesList = []) =>
  invoicesList.reduce((acc, { invoiceId }) => {
    const { unpaidExcInterest } = getTotalsForInvoiceId(getInvoicesTotalsMap(), { invoiceId });
    return acc + unpaidExcInterest;
  }, 0);

/**
 * build the inclusions list from the matter list
 * @param {Array} matterList - list fo matters.
 * @param {object} options
 * @param {string} options.contactId - contact id to use for the inclusions list
 * @returns {Array} - array of inclusions/
 */
export const buildInclusionsList = (matterList, { debtorId }) => {
  if (!debtorId) {
    throw new Error(`debtorId cannot be falsey`);
  }

  if (!matterList) {
    return [];
  }

  return matterList.map((matter) => {
    // we don't have a way right now to move this to a selectors file since it is coming from an indexer inside the cache.
    const invoicesByMatterId = getInvoicesByMatterId(matter.matterId) || [];
    return {
      ...matter,
      matterType: getMatterTypeById(matter.matterTypeId),
      dueForThisDebtorOnly: calculateUnpaidExcInterest(
        invoicesByMatterId.filter(
          (invoice) =>
            invoice.currentVersion.status === invoiceStatuses.FINAL &&
            invoice.currentVersion.debtors &&
            invoice.currentVersion.debtors.length === 1 && // include only those invoices with one debtors
            invoice.currentVersion.debtors.find(({ id }) => id === debtorId),
        ),
      ),
      due: calculateUnpaidExcInterest(
        invoicesByMatterId.filter(
          (invoice) =>
            invoice.currentVersion.status === invoiceStatuses.FINAL &&
            invoice.currentVersion.debtors &&
            invoice.currentVersion.debtors.find(({ id }) => id === debtorId),
        ),
      ),
    };
  });
};
