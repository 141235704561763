import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as formsFeature from '@sb-itops/redux/forms2';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { todayAsInteger } from '@sb-itops/date';
import { capitalize } from '@sb-itops/nodash';
import { isModalVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import {
  getNumberingSettings as getTransactionNumberingSettings,
  getOperatingAccount,
  getById as getBankAccountById,
} from '@sb-billing/redux/bank-account';
import {
  isMatterContactBalanceType,
  getSettings as getBankAccountSettings,
} from '@sb-billing/redux/bank-account-settings';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { PrintNow, PrintManually, PrintNotApplicable } from '@sb-billing/business-logic/cheques';
import { findLastChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import {
  getList as getAllTrustCheques,
  chequeExistsForBankAccount,
  getByBankAccountId as getTrustChequesByBankAccountId,
} from '@sb-billing/redux/trust-cheques';
import { receivePayment } from '@sb-billing/redux/payments';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import { toCamelCase } from '@sb-itops/camel-case';
import logFactory from '@sb-itops/fe-logger';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { getMatterTrustBalanceByDate } from '@sb-billing/redux/transactions';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { allocateContactBalancePayments } from './allocate-contact-balance-payments';
import TrustToOfficeModal from './TrustToOfficeModal';

const log = logFactory.getLogger('TrustToOfficeModalContainer');
const PROCESS_TRUST_TO_OFFICE_MODAL_ID = 'process-trust-to-office-modal';
const REDUX_DATE_FORMAT = 'YYYYMMDD';

function getScopedTrustToOfficeModalFeature(state) {
  const scope = PROCESS_TRUST_TO_OFFICE_MODAL_ID;
  return withScopedFeatures({ state, scope })({
    formsFeature,
  });
}

function getDefaultPrintMethod() {
  // This is not fetching default print method from trust cheque settings. This is matching
  // behaviour in receive payment modal. I think the reasoning for that is in settings there's
  // no print not applicable options, so in areas where we need the print not applicable option
  // we would not default the print method to what's specified in trust cheque settings.
  return PrintNotApplicable;
}

function isTrustToOfficeTransferNumberingEnabled(bankAccountId) {
  const { trustToOfficeNumberingSettings } = getTransactionNumberingSettings({
    bankAccountId,
  });
  return !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering;
}

function isTrustChequePrintingActive(trustBankAccountId) {
  return !!(getTrustChequePrintSettingsById(trustBankAccountId) || {}).printingActive;
}

function isReferenceEditable({ printMethod, bankAccountId }) {
  // The reference field is really the transaction reference, but in the case of a cheque payment, this is also used as the cheque number
  // 1. For US eletronic payments (Print Not Applicable), this field is always editable
  // 2. For AU eletronic payments, this field is only editable when Trust to Office auto transaction numbering is off.
  // 3. This field is also editable for Print Manually scenario for cheque payments
  const ttoNumbering = hasFacet(facets.ttoNumbering);
  return (
    printMethod === PrintManually ||
    (printMethod === PrintNotApplicable &&
      (!ttoNumbering || (ttoNumbering && !isTrustToOfficeTransferNumberingEnabled(bankAccountId))))
  );
}

function buildReceivePaymentMessage({ formData, paymentSummary, bankAccountId }) {
  const printMethod = formData.printMethod;
  const isPrintManually = printMethod === PrintManually;

  const isCheque = printMethod !== PrintNotApplicable;
  const chequeId = isCheque ? uuid() : undefined;

  let reference;
  if (isPrintManually) {
    // assign reference (cheque number) provided by user, or get the next cheque number
    reference = formData.reference || getNextChequeNumber(getAllTrustCheques());
  } else if (isReferenceEditable({ printMethod, bankAccountId })) {
    reference = formData.reference;
  }

  // auto/manual allocation in trust to office screen only decides how much to pay
  // for each matter, for contact balance firms (US), we need to decide which contact
  // in the matter to allocate the funds from. funds are automatically taken from the
  // contact with the smallest balance first.
  let payments;
  const isContactBalanceFirm = isMatterContactBalanceType();
  if (isContactBalanceFirm) {
    payments = allocateContactBalancePayments(paymentSummary.paymentsForEachMatter, bankAccountId);
    // Contact Balance Firms: payments is an array of items with the
    // following shape for each matter and payor
    //   {
    //     matterId: 'baa0a463-ebec-444c-94f1-6bd077546729',
    //     payorId: 'payor1id-ebec-444c-94f1-6bd077546729',
    //     invoices: [
    //       {
    //         invoiceId: '577ea959-8477-4dd6-9160-58125f16b4cd',
    //         amount: 17600,
    //       },
    //       {
    //         invoiceId: '44992a87-179d-416b-af0a-bcaaba8a2c68',
    //         amount: 16500,
    //       },
    //       {
    //         invoiceId: '75b2bde3-2260-46c4-85b9-a41be0a6b2b7',
    //         amount: 2200,
    //       },
    //     ],
    //   },
  } else {
    payments = paymentSummary.paymentsForEachMatter.map((receivePaymentForMatter) => {
      const transferBetweenAccountsTransactionId = uuid();
      return {
        ...receivePaymentForMatter,
        transferBetweenAccountsTransactionId,
      };
    });
    // Matter Balance Firms: payments is an array of items with the
    // following shape for each matter
    //   {
    //     matterId: 'baa0a463-ebec-444c-94f1-6bd077546729',
    //     invoices: [
    //       {
    //         invoiceId: '577ea959-8477-4dd6-9160-58125f16b4cd',
    //         amount: 17600,
    //       },
    //       {
    //         invoiceId: '44992a87-179d-416b-af0a-bcaaba8a2c68',
    //         amount: 16500,
    //       },
    //       {
    //         invoiceId: '75b2bde3-2260-46c4-85b9-a41be0a6b2b7',
    //         amount: 2200,
    //       },
    //     ],
    //   },
  }

  const destinationBank = hasFacet(facets.operatingAccountDetail)
    ? {
        destinationBankAccountName: getOperatingAccount().accountName,
        destinationBankAccountNumber: getOperatingAccount().accountNumber,
        destinationBankBranchNumber: getOperatingAccount().branchNumber,
      }
    : {};

  let reasonValue;
  // reasonField is AU/GB facet
  if (hasFacet(facets.reasonField)) {
    reasonValue = formData.reason;
  } else if (hasFacet(facets.trustPaymentReasonField)) {
    // trustPaymentReasonField is US facet
    // For US, we keep reason field hidden from UI, but set the reason value when submitted if the invoice payment source is trust
    const paymentsForEachMatter = paymentSummary.paymentsForEachMatter || [];
    const invoiceNumbersArray = paymentsForEachMatter.flatMap((payment) =>
      (payment.invoices || []).map((invoice) => {
        const invoiceNumber = getInvoiceNumberById(invoice.invoiceId);
        return invoiceNumber;
      }),
    );
    // Sort the invoice numbers numerically
    invoiceNumbersArray.sort((a, b) => a - b);
    // Convert sorted numbers back to strings prefixed with '#'
    const sortedInvoiceNumbers = invoiceNumbersArray.map((invoiceNumber) => `#${invoiceNumber}`);
    const invoiceNumbers = sortedInvoiceNumbers.join(', ');
    reasonValue = `Legal Fees on ${invoiceNumbersArray.length === 1 ? 'Invoice' : 'Invoices'} ${invoiceNumbers}`;
  }

  const receivePaymentMessage = {
    totalAmount: paymentSummary.totalAmount,
    effectiveDate: todayAsInteger(),
    isTrustToOffice: true,
    reason: reasonValue,
    multiPaymentId: uuid(),
    accountPayments: [
      {
        sourceAccountId: bankAccountId,
        destinationAccountId: getOperatingAccount().id,
        isElectronicPayment: !isCheque,
        isCheque,
        chequeId,
        chequePrintActive: isCheque,
        chequePrintMethod: isCheque && printMethod,
        reference,
        chequeMemo: isCheque && hasFacet(facets.chequeMemo) ? formData.memo : undefined,
        payments,
        ...destinationBank,
      },
    ],
  };
  // receipt id is used to redirect to PDF before the PDF exists
  // it is either multiPaymentId if there are multiple payments
  // or paymentId if it 1 payment (multiple invoices on same matter is still 1 payment as long as it is for same payor)
  let receiptId;
  if (payments.length) {
    receiptId = payments.length === 1 ? payments[0].paymentId : receivePaymentMessage.multiPaymentId;
  }
  return { receivePaymentMessage, chequeId, receiptId, payments };
}

const getDefaultPdfOnTrustPayment = () => {
  const bankAccountSettings = getBankAccountSettings() || {};
  return bankAccountSettings.createPDFReceiptOnTrustPayment;
};

function mapStateToProps(state, { paymentSummary, bankAccountId, t }) {
  const {
    formsFeature: {
      selectors: { getFormState, getFieldValues },
    },
  } = getScopedTrustToOfficeModalFeature(state);

  const CHEQUE_LABEL = capitalize(t('cheque'));

  // get modal visibility
  const visible = isModalVisible({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID });
  const lastChequeReferenceFound = findLastChequeNumber(getTrustChequesByBankAccountId(bankAccountId));

  // get form fields
  const formState = getFormState();
  const fieldValues = formState.formInitialised ? getFieldValues() : {};
  const { printMethod, memo, reason, pdfOnTrustPayment } = fieldValues;
  const formSubmitting = formState.formSubmitting;

  // The reference field is really the transaction reference, but in the case of a cheque payment, this is also used as the cheque number
  const referenceIsEditable = isReferenceEditable({ printMethod, bankAccountId });
  let reference;
  if (printMethod === PrintManually) {
    // In the PrimtManually scenario, the reference assigned will be the last cheque reference found + 1.
    // This is assigned for display purpose only here (when it's not overridden by the user aleady). The
    // actual reference will be determined at point of form submission so to ensure no cheque number overlaps.
    reference =
      !fieldValues || fieldValues.reference === undefined
        ? getNextChequeNumber(getAllTrustCheques())
        : fieldValues.reference;
  } else if (referenceIsEditable) {
    // If reference field is editable, propagate edited estate if any
    reference = fieldValues && fieldValues.reference;
  }

  // validate form fields
  const errors = {};

  // stops submission without showing any errors, this is a guard against incorrect use of modal
  if (!paymentSummary || !paymentSummary.totalAmount || paymentSummary.totalAmount <= 0) {
    errors.paymentSummary = true;
  }

  if (fieldValues.printMethod === PrintManually) {
    // validate reference only when it's overridden by user which is possible only when they print manually
    if (fieldValues.reference !== undefined) {
      if (fieldValues.reference === '') {
        errors.reference = true;
        errors.referenceIsRequired = `Warning: ${CHEQUE_LABEL} reference is required.`;
      } else if (!/^[0-9]+$/.test(fieldValues.reference)) {
        errors.reference = true;
        errors.referenceMustBeNumeric = `Warning: ${CHEQUE_LABEL} reference must be numeric.`;
      } else if (
        hasFacet(facets.allowDuplicateCheque) &&
        chequeExistsForBankAccount(+fieldValues.reference, bankAccountId)
      ) {
        errors.reference = true;
        errors.referenceIsAlreadyInUse = `Warning: ${CHEQUE_LABEL} reference is already in use. Last ${CHEQUE_LABEL.toLowerCase()} reference printed was ${lastChequeReferenceFound}.`;
      } else if (chequeExistsForBankAccount(+fieldValues.reference, bankAccountId)) {
        errors.reference = true;
        errors.referenceIsAlreadyInUse = `Warning: ${CHEQUE_LABEL} reference is already in use. Last ${CHEQUE_LABEL.toLowerCase()} reference printed was ${lastChequeReferenceFound}.`;
      }
    }
  }

  if (hasFacet(facets.reasonField) && !fieldValues.reason) {
    errors.reason = true;
  }
  const disableSubmit = Object.keys(errors).length > 0 || formSubmitting;
  const trustAccountName = getBankAccountName(getBankAccountById(bankAccountId), t);

  const matterOverdrawnWarnings = getMatterOverdrawnWarnings({
    paymentSummary,
    bankAccountId,
    t,
    modalIsVisible: visible,
    formSubmitting,
  });

  return {
    modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID,
    visible,
    paymentSummary,
    printMethod,
    referenceIsEditable,
    reference,
    memo,
    reason,
    pdfOnTrustPayment,
    trustAccountName,
    errors,
    matterOverdrawnWarnings,
    disableSubmit,
    formSubmitting,
    isTrustChequePrintingActive: isTrustChequePrintingActive(bankAccountId),
    showChequeMemo: hasFacet(facets.chequeMemo),
    showReason: hasFacet(facets.reasonField),
  };
}

function mapDispatchToProps(
  dispatch,
  { paymentSummary, onPaymentsSubmitted, onOpenTrustChequesModal, bankAccountId, onClickLink, t },
) {
  const {
    formsFeature: {
      actions: { initialiseForm, updateFieldValues, clearForm },
      operations: { submitFormP },
    },
  } = getScopedTrustToOfficeModalFeature();

  const updateFieldValue = (field, value) => {
    dispatch(updateFieldValues({ fieldValues: { [field]: value } }));
  };

  const getInitialFieldValues = () => ({
    checkDate: moment().format(REDUX_DATE_FORMAT),
    payToContactId: undefined,
    memo: undefined,
    reason: hasFacet(facets.reasonField)
      ? `${t('capitalize', { val: 'trustToOfficeTransferLabel' })} for costs and outlays`
      : undefined,
    printMethod: getDefaultPrintMethod(),
    reference: undefined,
    pdfOnTrustPayment: getDefaultPdfOnTrustPayment(),
  });

  const resetForm = () => {
    dispatch(
      updateFieldValues({
        fieldValues: getInitialFieldValues(),
      }),
    );
  };

  return {
    onLoad: () => {
      dispatch(
        initialiseForm({
          fieldValues: getInitialFieldValues(),
        }),
      );
      return () => {
        dispatch(clearForm());
      };
    },
    onPrintMethodSelected: (selectedOption) => {
      updateFieldValue('printMethod', selectedOption && selectedOption.value);
      updateFieldValue('reference', undefined); // clear reference field to assume default
    },
    onReferenceChange: (newReference) => {
      updateFieldValue('reference', newReference);
    },
    onMemoChange: (newMemo) => {
      updateFieldValue('memo', newMemo);
    },
    onReasonChange: (newReason) => {
      updateFieldValue('reason', newReason);
    },
    onPdfOnTrustPaymentChange: (newPdfOnTrustPayment) => {
      updateFieldValue('pdfOnTrustPayment', newPdfOnTrustPayment);
    },

    onSubmit: async () => {
      await dispatch(
        submitFormP({
          submitFnP: async (formData) => {
            const successFailMessagePrefix = `${paymentSummary.invoiceCount} invoice(s) on ${paymentSummary.matterCount} matter(s)`;

            try {
              // build receive payment message from form data and payment summary passed from trust to office screen
              const { receivePaymentMessage, chequeId, receiptId, payments } = buildReceivePaymentMessage({
                formData,
                paymentSummary,
                bankAccountId,
              });
              const openReceipt = formData.printMethod !== PrintNow && formData.pdfOnTrustPayment && receiptId;

              if (openReceipt) {
                const paymentIds = payments.reduce((acc, item) => {
                  acc[item.paymentId] = true;
                  return acc;
                }, {});

                // Keep in "submitting" state until we get notifications all payments were saved with 20s timeout
                await receivePaymentAndWaitForNotifications({ receivePaymentMessage, paymentIds, timeout: 20000 });
              } else {
                // submit receive payment message
                await receivePayment(receivePaymentMessage);
              }

              // reset this modal form as well as any selection/payment amount entered in trust to office screen
              resetForm();
              onPaymentsSubmitted();
              setModalDialogHidden({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID });

              // open trust cheque printing modal if applicable
              if (formData.printMethod === PrintNow) {
                onOpenTrustChequesModal({ chequeId, bankAccountId });
              } else if (openReceipt) {
                // redirect to PDF
                onClickLink({
                  type: 'trustToOfficeTransferReceipt',
                  id: { paymentId: receiptId },
                });
              } else {
                const successMessage = `${successFailMessagePrefix} ${t('trustToOfficeSuccessMessage')}.`;
                messageDisplay.success(successMessage);
              }
            } catch (err) {
              const failMessage = `${successFailMessagePrefix} ${t('trustToOfficeSuccessMessage')}.`;
              messageDisplay.error(failMessage);
              throw err;
            }
          },
        }),
      );
    },
    onCloseModal: async () => {
      setModalDialogHidden({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID });
      resetForm();
    },
  };
}

// If user chooses redirection to TTO receipt, we want to delay it by using "submitting" state until we are sure all payments are saved.
//
// The logic is as follows:
// - Since TTO payment is only pseudo-transaction, we don't get any notification that it has been finalised.
// - By sending TTO payment command we create multiple invoice payments and those send "PaymentReceivedOnInvoice" notification
// - We have all paymentIds available here before sending them to endpoint, therefore we can subscribe to these notifications before command
//   is sent cross check the Ids (fallback is timeout just in case notification is missed for any reason)
const receivePaymentAndWaitForNotifications = async ({ receivePaymentMessage, paymentIds, timeout }) => {
  const expectedPaymentIds = { ...paymentIds };
  let unsubscribe;

  const listenerP = new Promise((resolve) => {
    unsubscribe = subscribeToNotifications({
      notificationIds: ['InvoicingNotifications'],
      callback: (notificationPayload) => {
        let payload;
        try {
          payload = toCamelCase(JSON.parse(notificationPayload));
        } catch (err) {
          payload = {};
        }

        if (payload.messageId === 'PaymentReceivedOnInvoice' && payload.entityId) {
          delete expectedPaymentIds[payload.entityId];
        }

        if (!Object.keys(expectedPaymentIds).length) {
          log.info('Received notifications for all payments');
          resolve();
        }
      },
    });
  });

  // submit receive payment message
  await receivePayment(receivePaymentMessage);

  const timeoutP = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

  return Promise.race([listenerP, timeoutP]).finally(() => unsubscribe());
};

const getMatterOverdrawnWarnings = ({ paymentSummary, bankAccountId, modalIsVisible, formSubmitting, t }) => {
  // getMatterTrustBalanceByDate gets the balance by looping over all transactions for matter and bank account so
  // it can get pretty slow for paymentSummary with lot of matters. Therefore, we don't want to calculate anything,
  // unless the modal is visible and we support overdraw.
  if (!modalIsVisible || formSubmitting || !featureActive('BB-13792') || !hasFacet(facets.allowOverdraw)) {
    return [];
  }

  const warnings = [];
  const todayAsInt = todayAsInteger();

  paymentSummary.paymentsForEachMatter.forEach((payment) => {
    const matterId = payment.matterId;
    const matterTotalPayment = payment.matterTotalPayment;
    const matterBalanceAtDate = getMatterTrustBalanceByDate(matterId, todayAsInt, bankAccountId);

    if (matterTotalPayment > matterBalanceAtDate) {
      warnings.push(
        `${getMatterDisplayById(matterId)} (${t('cents', { val: matterTotalPayment - matterBalanceAtDate })})`,
      );
    }
  });

  return warnings;
};

const TrustToOfficeModalContainer = withTranslation()(
  withReduxStore(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(TrustToOfficeModal))),
);

TrustToOfficeModalContainer.displayName = 'TrustToOfficeModalContainer';

TrustToOfficeModalContainer.propTypes = {
  paymentSummary: PropTypes.any,
  bankAccountId: PropTypes.string.isRequired,
  onPaymentsSubmitted: PropTypes.func.isRequired,
  onOpenTrustChequesModal: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
};

TrustToOfficeModalContainer.defaultProps = {
  onClickLink: () => {},
};

export default TrustToOfficeModalContainer;
