import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withReduxStore } from '@sb-itops/react';
import { getOrganisationName as getXeroOrgName } from '@sb-billing/redux/xero';

import { IntegrationConnectionPanel } from './IntegrationConnectionPanel';

const mapStateToProps = (
  state,
  { connectToIntegration, integration, isConnected, isConnecting, isDisconnecting, logoSrc, url },
) => {
  // For xero integration only
  const getOrganisationName = () => (integration.toLowerCase() === 'xero' ? getXeroOrgName() : undefined);

  return {
    connectToIntegration,
    integration,
    isConnected,
    isConnecting,
    isDisconnecting,
    logoSrc,
    organisationName: getOrganisationName(),
    url,
  };
};

export const IntegrationConnectionPanelContainer = withReduxStore(
  connect(mapStateToProps, null)(IntegrationConnectionPanel),
);

IntegrationConnectionPanelContainer.propTypes = {
  integration: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool,
  isDisconnecting: PropTypes.bool,
  connectToIntegration: PropTypes.func.isRequired,
  url: PropTypes.string,
  logoSrc: PropTypes.string.isRequired,
};

IntegrationConnectionPanelContainer.defaultProps = {
  url: undefined,
  isConnecting: false,
  isDisconnecting: false,
};

IntegrationConnectionPanelContainer.displayName = 'IntegrationConnectionPanelContainer';
