import { SET_DEBUG_ENTITY, CLEAR_DEBUG_ENTITY_HISTORY, TOGGLE_SHOW_DEBUG } from './types';

/**
 * Set debug cache/entity for quick lookup
 * @param {object} debugEntity
 * @param {string} debugEntity.entityCacheName
 * @param {string} debugEntity.entityId
 * @returns {object} action
 */
export const setDebugEntity = (debugEntity) => ({
  type: SET_DEBUG_ENTITY,
  payload: debugEntity,
});

/**
 * Clear all debug entity history
 */
export const clearDebugEntityHistory = () => ({
  type: CLEAR_DEBUG_ENTITY_HISTORY,
  payload: undefined,
});

export const toggleShowDebug = () => ({
  type: TOGGLE_SHOW_DEBUG,
  payload: {},
});
