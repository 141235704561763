import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { useDispatch, useSelector } from 'react-redux';
import { operationTypes, relatedItemType } from '@sb-billing/business-logic/correspondence-history';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getInvoiceLatestVersion as getInvoiceById } from '@sb-billing/redux/invoices';
import * as messageDisplay from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { MarkAsSentModal } from './MarkAsSentModal';

const uuid = require('@sb-itops/uuid');

export const MARK_AS_SENT_MODAL_ID = 'mark-as-sent-modal';

const hooks = (props) => ({
  useModal: () => {
    const { scope, isVisible, selectedItems, operationType, onClose } = props;
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);

    let matterIds; // MarkAsSentForm uses matterIds to get matterCommunicationSettings
    // currently only operationTypes.INVOICE and operationTypes.INVOICE_REMINDER uses this component
    if (operationType === operationTypes.INVOICE_REMINDER) {
      const matterIdsSet = new Set(selectedItems.map((reminder) => reminder.matterId));
      matterIds = Array.from(matterIdsSet);
    } else {
      matterIds = getUniqueMatterIdsFromInvoiceIds({
        invoiceIds: selectedItems,
      });
    }

    const { formInitialised, formSubmitting, formValid } = useSelector(formSelectors.getFormState);
    const isSubmitDisabled = !formInitialised || formSubmitting || !formValid;

    const dispatch = useDispatch();
    const onMark = async () => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formData) => {
              // Covert the field selected dateSent to UTC.
              // To differentiate between multiple correspondences at the same date, we need to set the time to the current time. This is to fix https://smokeball.atlassian.net/browse/BB-13326
              const utcDate = moment(formData.dateSent.toString())
                .set({
                  hour: moment().hour(),
                  minute: moment().minute(),
                  second: moment().second(),
                })
                .utc();
              const sentTimestamp = utcDate.toISOString();

              if (operationType === operationTypes.INVOICE_REMINDER) {
                const promises = selectedItems.map(({ matterId, debtorId, invoices }) => {
                  const { relatedIds, relatedItems } = invoices.reduce(
                    (acc, invoice) => {
                      acc.relatedIds.push(invoice.invoiceId);
                      acc.relatedItems.push({
                        id: invoice.invoiceId,
                        type: relatedItemType.INVOICE,
                      });
                      return acc;
                    },
                    { relatedIds: [], relatedItems: [] },
                  );

                  relatedItems.push(
                    {
                      id: matterId,
                      type: relatedItemType.MATTER,
                    },
                    {
                      id: debtorId,
                      type: relatedItemType.CONTACT,
                    },
                  );

                  const markAsSentMessage = {
                    sentTimestamp,
                    operationType,
                    correspondenceId: uuid(),
                    sentVia: formData.sentVia,
                    internalNote: formData.internalNote,
                    matterId,
                    contactIds: [debtorId],
                    relatedIds,
                    relatedItems,
                  };
                  return dispatchCommand({
                    type: 'Billing.Shared.Messages.Commands.NotifyCorrespondenceSucceeded',
                    message: markAsSentMessage,
                  });
                });
                await Promise.all(promises);
              } else {
                // currently only operationTypes.INVOICE falls to this block, and selectedItems are invoiceIds when operationType is INVOICE
                const relatedItems = selectedItems.map((invoiceId) => ({
                  id: invoiceId,
                  type: relatedItemType.INVOICE,
                }));

                const markAsSentMessage = {
                  sentTimestamp,
                  operationType,
                  correspondenceId: uuid(),
                  sentVia: formData.sentVia,
                  internalNote: formData.internalNote,
                  relatedIds: selectedItems,
                  relatedItems,
                };
                await dispatchCommand({
                  type: 'Billing.Shared.Messages.Commands.NotifyCorrespondenceSucceeded',
                  message: markAsSentMessage,
                });
              }

              onClose();
              await dispatch(formActions.clearForm());
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to mark as sent');
      }
    };

    return {
      scope,
      isVisible,
      matterIds,
      isSubmitDisabled,
      // func & callbacks
      onMark,
      onClose,
    };
  },
});

const getUniqueMatterIdsFromInvoiceIds = ({ invoiceIds }) => {
  if (!Array.isArray(invoiceIds)) return [];
  const matterIds = new Set();
  invoiceIds.forEach((invoiceId) => {
    const { matterId } = getInvoiceById(invoiceId) || {};
    if (matterId) {
      matterIds.add(matterId);
    }
  });
  return Array.from(matterIds);
};

export const MarkAsSentModalContainer = withReduxProvider(composeHooks(hooks)(MarkAsSentModal));

MarkAsSentModalContainer.displayName = 'MarkAsSentModalContainer';

MarkAsSentModalContainer.propTypes = {
  scope: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  operationType: PropTypes.oneOf(Object.values(operationTypes)),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        matterId: PropTypes.string,
        debtorId: PropTypes.string,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string,
          }),
        ),
      }),
    ]),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};
MarkAsSentModalContainer.defaultProps = {
  scope: 'mark-as-sent-modal',
  operationType: operationTypes.INVOICE, // Default to invoice type
};
export default MarkAsSentModalContainer;
