'use strict';

import { getTrustAccount } from '@sb-billing/redux/bank-account';

angular.module('sb.billing.webapp').controller('sbEmailTemplatesController', function ($scope, $state) {
  const ctrl = this;

  ctrl.activeTab = $state.params.tab || 'invoices';
  ctrl.trustAccount = getTrustAccount();

  const listeners = [
    $scope.$on('$destroy', () => {
      for (let unregister of listeners) unregister();
    }),
    $scope.$on('smokeball-data-update-sbBankAccountService', () => {
      ctrl.trustAccount = getTrustAccount();
    }),
  ];

  ctrl.setTab = (tabName) => {
    ctrl.activeTab = tabName;
  };
});
