import { utilsFactory, store } from '@sb-itops/redux';
import { wrappedPersistReducer } from 'web/services/bootstrapper/register-redux-reducers';
import { domain } from '../../domain';

const name = 'home.billing.accounts.payment-provider-payments.fee-wise';

const { createActionName, registerReducer, getState } = utilsFactory({ domain, name });
export const initState = { category: 'transactions', showFilters: true };

// actions
export const SELECT_CATEGORY = createActionName('SELECT_CATEGORY');
export const TOGGLE_SHOW_FILTERS = createActionName('TOGGLE_SHOW_FILTERS');

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SELECT_CATEGORY:
      return { ...state, category: action.payload };
    case TOGGLE_SHOW_FILTERS:
      return { ...state, showFilters: !state.showFilters };
    // generic action triggered by billing-nav-controller
    case 'RESET_FILTERS':
      return { ...state, category: initState.category };
    default:
      return state;
  }
};

registerReducer(
  wrappedPersistReducer({ defaultPath: `${domain}/${name}`, reducer }, { persist: true, whitelist: ['showFilters'] }),
);

// selectors
export const getSelectedCategory = () => {
  if (getState()) {
    return getState().category;
  }
  return initState.category;
};

export const getShowFilters = () => {
  if (getState()) {
    return getState().showFilters;
  }
  return initState.showFilters;
};

// action creators
export const selectCategory = (payload) => store.dispatch({ type: SELECT_CATEGORY, payload });
export const toggleShowFilters = () => store.dispatch({ type: TOGGLE_SHOW_FILTERS });

export const categoryOptionMap = {
  transactions: { key: 'transactions', name: 'Transactions' },
  payouts: { key: 'payouts', name: 'Payouts' },
};
