export type TMappedRoles = ReturnType<typeof mapRoles>;

export const mapRoles = (rawRoles: any[]) => {
  const mappedRoles = rawRoles.map((r) => ({
    id: r.id,
    contactId: r.contactId,
    representativeIds: r.representatives?.map((x) => x.id) || [],
    name: r.name,
    isClient: r.isClient,
    isOtherSide: r.isOtherSide,
  }));

  return mappedRoles;
};
