import * as types from './types';

export const getBootstrapStatus = (state) => state.bootstrapStatus;
export const getAuthorisationSuccessful = (state) => state.authorisationSuccessful;
export const getAuthorisationFailureType = (state) => state.authorisationFailureType;
export const getUserPermissions = (state) => state.userPermissions;
export const getIsStorageQuotaSufficient = (state) => state.isStorageQuotaSufficient;
export const getServerVersion = (state) => state.serverVersion;
export const getFileManagerInstalled = (state) => state.persisted.isFileManagerInstalled;
export const getIsUserDataClearing = (state) => state.isUserDataClearing;
export const getLoginFailureMessage = (state) => state.loginFailureMessage;
export const getIsPrivateComputer = (state) => state.persisted.isPrivateComputer;
export const getLastLoggedInUser = (state) => state.persisted.lastLoggedInUser;
export const getLastAction = (state) => state.lastAction;
export const getLoginBlockedReason = (state) => state.persisted.loginBlockedReason;
export const getLoginFormSubheader = (state) => state.loginFormSubheader;
export const getIsMainMenuExpanded = (state) => {
  const { EXPANDED, NEVER_EXPANDED } = types.mainMenuExpansionStates;
  const mainMenuExpansionState = state?.persisted?.mainMenuExpansionState;
  if (mainMenuExpansionState === EXPANDED) {
    return true;
  }

  if (mainMenuExpansionState === NEVER_EXPANDED || mainMenuExpansionState === undefined) {
    return undefined;
  }

  return false;
};

// The claimed owning company is similar to the "owning company" in itops/auth,
// with the exception that it's not cryptographically secure. In other words,
// it can be faked. This is typically set via the querystring param "owner=" and
// should be ignored once the user has logged in and the cryptographically signed
// owning company claim can be utilised instead.
export const getOwningCompanyInsecure = (state) => state.owningCompanyInsecure;
