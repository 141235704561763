'use strict';

angular.module('@sb-billing/services').service('sbDefaultBankAccountsService', function (sbGenericEndpointService, sbLoggerService) {
  var that = this,
    log = sbLoggerService.getLogger('sbDefaultBankAccountsService'),
    endpoint = 'billing/default-bank-accounts';

  that.createP = createP;

  function createP() {
    log.info('creating default bank accounts. ');
    return sbGenericEndpointService.postPayloadP(endpoint + '/create', undefined, undefined)
      .then(function (res) {
        if (res && res.payload) {
          log.info('created default bank accounts', res.payload);
          return true;
        }
      });
  }

});
