import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getLogItems, getIsCollectingLogs, Actions } from 'web/redux/route/billing-support-rest-logs';
import { BillingSupportRestLogs } from './BillingSupportRestLogs';

const mapStateToProps = () => ({
  logItems: getLogItems(),
  isCollectingLogs: getIsCollectingLogs(),
  clearLogItems: Actions.clearLogItems,
  startLogCollection: Actions.startLogCollection,
  stopLogCollection: Actions.stopLogCollection,
});

export const BillingSupportRestLogsContainer = withReduxStore(connect(mapStateToProps)(BillingSupportRestLogs));

BillingSupportRestLogsContainer.displayName = 'BillingSupportRestLogsContainer';

BillingSupportRestLogsContainer.propTypes = {};
BillingSupportRestLogsContainer.defaultProps = {};
