import {
  showMessage,
  dismissMessageGroup,
} from '@sb-itops/redux/message';

import { isEmpty, isNumber, isString } from 'lodash';

import MessageBuilder from './message-builder';

const defaultTimeoutMs = 5000;

const levelTitles = {
  success: 'Success',
  info: 'Information',
  warn: 'Warning',
  error: 'Error',
};

/**
 * Returns a new MessageBuilder object which allows for fluid api around  the MessageDisplay options.
 * @returns {*} The new sbMessageBuilder object.
 */
export function builder() {
  return new MessageBuilder();
}

export function success(options) {
  showMessage(parseMessageOptions('success', options));
}

export function info(options) {
  showMessage(parseMessageOptions('info', options));
}

export function warn(options) {
  showMessage(parseMessageOptions('warn', options));
}

export function error(options) {
  showMessage(parseMessageOptions('error', options));
}

export const dismissGroup = dismissMessageGroup;

/**
 * Clients can use different modes for creating a message:
 * A plain string, a builder object, or a message options object.
 * This function takes options and figures out which mode is being used.
 * It returns a message options object which is compatible with postMessage.
 * The passed level is injected into the message options object prior to return.
 */
function parseMessageOptions(level, options) {
  if (isEmpty(options)) {
    throw new Error('Cannot post message without options or builder');
  }

  let actualOptions;

  // Client is using a message builder.
  if (options instanceof MessageBuilder) {
    actualOptions = options.getOptions();
  } else if (isString(options)) {
    // Client has provided a simple string to be used as message text.
    actualOptions = { msgText: options };
  }

  // otherwise assume that the client has provided a valid options object for posting a message

  actualOptions.level = level;

  // success or info message defaults to having a dismissal timeout.
  if ((level === 'success' || level === 'info') && !isNumber(options.timeout)) {
    actualOptions.timeout = defaultTimeoutMs;
  }

  // Apply the default title if none has been provided.
  actualOptions.title = actualOptions.title || levelTitles[level];

  return actualOptions;
}

// When  attempting to display a caught exception the desired text could often be in many places
export function getError(err) {
  if (err) {
    if (err.message) {
      return err.message;
    }
    if (err.payload && err.payload.body && err.payload.body.message) {
      return err.payload.body.message;
    }
    if (err.statusText) {
      if (err.data && err.data.message) {
        return err.statusText + ' (' + err.data.message + ')';
      }
      return err.statusText;
    }
  }
  return 'unknown';
}
