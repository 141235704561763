import { SET_SELECTED_PAYMENT_PLAN } from './types';

const initialState = null;

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_PAYMENT_PLAN: {
      return {
        selectedPaymentPlan: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

// reducer for this state tree
export default reducer;
