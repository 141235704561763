import { gql } from '@apollo/client';

const query = gql`
  query DraftInvoiceEmailModalData($debtorIds: [ID], $feeIds: [ID], $matterId: ID!) {
    contacts(ids: $debtorIds) {
      id
      customLetterName
      displayNameFirstLast
      email
      firstName
      lastName
      letterNameFormat
      people {
        id
        email
        firstName
        lastName
        letterNameFormat
        title
        type
      }
      title
      type
    }
    fees(ids: $feeIds) {
      id
      duration
      feeType
    }
    matter(id: $matterId) {
      id
      matterEmailSettings {
        id
        bccAddresses
        ccAddresses
      }
      matterInvoiceSettings {
        id
        payButtonEnabledOption
      }
    }
  }
`;

const notificationIds = [
  'BillingMattersNotifications.MatterEmailSettingsUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
  'FeesNotifications.AccountFeesUpdated',
  'InvoicingNotifications.InvoiceUpdated',
  'InvoicingNotifications.MatterInvoiceSettingsUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoiceEmailModalData = {
  query,
  notificationIds,
};
