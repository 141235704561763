import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { CreditCardSaveRequestForm } from '../credit-card-save-request-form';
import Styles from './CreditCardSaveRequestModal.module.scss';

export const CreditCardSaveRequestModal = React.memo(
  ({ onSubmit, onClose, isSubmitDisabled, isSubmitLocked, isVisible, scope, contactId }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.depositRequestModal}
        isVisible={isVisible}
        title="Add Credit Card Request"
        body={<CreditCardSaveRequestForm scope={scope} contactId={contactId} />}
        footer={
          <div className="row">
            <div className="col-lg-3">
              <Button
                onClick={onSubmit}
                disabled={isSubmitDisabled || isSubmitLocked}
                form={scope}
                locked={isSubmitLocked}
                size="full-width"
              >
                Send Now
              </Button>
            </div>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

CreditCardSaveRequestModal.displayName = 'CreditCardSaveRequestModal';

CreditCardSaveRequestModal.propTypes = {
  scope: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isSubmitLocked: PropTypes.bool,
};

CreditCardSaveRequestModal.defaultProps = {
  isSubmitDisabled: false,
  isSubmitLocked: false,
};
