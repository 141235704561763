import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { OrderedList } from '@sb-itops/react';
import { Button } from '@sb-itops/react/button';
import { Spinner } from '@sb-itops/react/spinner';
import * as messageDisplay from '@sb-itops/message-display';

import Styles from './FinaliseWithPaymentsSettings.module.scss';

const FinaliseWithPaymentsSettings = React.memo(
  ({ settings, preferredBankAccounts, defaultBankAccountId, onSaveSettings }) => {
    const [preferredBankAccountId, setPreferedBankAccountId] = useState(
      () => settings.preferredBankAccountId || defaultBankAccountId,
    );
    const [saving, setSaving] = useState(false);

    // if the user hasn't save the settings before, default the dirty to false
    // this is so the user can save the default settings straight away
    // NB: as we need to ensure backward compatiblity at all times, we can
    // be sure that preferredBankAccountId will always be available regardless
    const [dirty, setDirty] = useState(!settings.preferredBankAccountId);

    const [newPreferredBankAccounts, setPreferredBankAccounts] = useState(preferredBankAccounts);

    const onChangePreferredBankAccounts = (reorderedPreferredBankAccounts) => {
      const firstPreferredBankAccountId =
        reorderedPreferredBankAccounts && reorderedPreferredBankAccounts.length > 0
          ? reorderedPreferredBankAccounts[0]?.id
          : undefined;
      setPreferredBankAccounts(reorderedPreferredBankAccounts);
      setPreferedBankAccountId(firstPreferredBankAccountId); // set this for backwards compatibility
      setDirty(true);
    };

    const onClickSave = async () => {
      try {
        setSaving(true);
        await onSaveSettings({
          preferredBankAccountId,
          preferredBankAccounts: newPreferredBankAccounts.map((preferredBankAccount) => preferredBankAccount.id),
        });
        setDirty(false);
        messageDisplay.success('Finalize with Payments settings saved successfully');
      } catch (err) {
        messageDisplay.error('Saving Finalize with Payments settings failed');
      } finally {
        setSaving(false);
      }
    };

    return (
      <div className={Styles.finaliseWithPaymentsSettings}>
        <div className={Styles.line}>
          For payments, choose which fund to use first:
          <OrderedList
            list={newPreferredBankAccounts}
            displayKey="accountTypeDisplay"
            onListChanged={(newList) => onChangePreferredBankAccounts(newList)}
          />
        </div>
        <Button className={Styles.saveButton} onClick={onClickSave} disabled={saving || !dirty}>
          {' '}
          {saving && <Spinner small />} Save
        </Button>
      </div>
    );
  },
);

FinaliseWithPaymentsSettings.displayName = 'FinaliseWithPaymentsSettings';

FinaliseWithPaymentsSettings.propTypes = {
  // settings can be undefined if the user has never saved the settings before
  settings: PropTypes.any,
  preferredBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accountTypeDisplay: PropTypes.string,
    }),
  ),
  defaultBankAccountId: PropTypes.string,
  onSaveSettings: PropTypes.func.isRequired,
};

FinaliseWithPaymentsSettings.defaultProps = {
  settings: {},
  preferredBankAccounts: undefined,
  defaultBankAccountId: undefined,
};

export default FinaliseWithPaymentsSettings;
