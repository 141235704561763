const StyleProperties = require('./style-properties.module');

class AllowedStyleProperties {
  static isTagAllowed(propertyName) {
    const isAllowed = false;

    for (let i = 0; i < StyleProperties.length; i++) {
      if (StyleProperties[i].propertyName == propertyName) {
        return StyleProperties[i].allowed == true;
      }
    }

    return isAllowed;
  }

  static getAllowedTags() {
    const allowedTags = [];

    StyleProperties.forEach((value) => {
      if (value.allowed) {
        allowedTags.push(value);
      }
    });

    return allowedTags;
  }
}

module.exports = AllowedStyleProperties;
