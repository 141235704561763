import * as React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, CurrencyInput2, useTranslation } from '@sb-itops/react';

import classNames from 'classnames';
import Styles from './SplitPayorsList.module.scss';

/**
 * This is a LOD compatible component
 *  - TODO: Convert to using Table component
 *
 * Legacy file: monorepo/apps/smokeball-billing-web/src/react-redux/components/split-payors-list/SplitPayorsList.jsx
 */
export const SplitPayorsList = ({ payorAmounts, payors, summary, onChangePayorAmount, onSelectPayor }) => {
  const { t } = useTranslation();
  const formatCurrency = (cents) => t('cents', { val: cents });

  return (
    <div className={classNames('split-payors-list', 'sb-table', Styles.splitPayorsListTable)}>
      <div className="sb-table-header">
        <div className={classNames('sb-table-cell', 'contact', Styles.tableColumnHeaders)}>Contact</div>
        <div className={classNames('sb-table-cell', 'available', Styles.tableColumnHeaders)}>Available</div>
        <div className={classNames('sb-table-cell', 'amount', Styles.tableColumnHeaders)}>Amount</div>
        <div className={classNames('sb-table-cell', 'amount-all', Styles.tableColumnHeaders)}>All</div>
      </div>
      <div className={classNames('sb-table-content', 'list', Styles.payorList)}>
        {payors.map((payor) => {
          const isChecked = payor.available === payorAmounts[payor.id];
          const amount = payorAmounts[payor.id] ?? 0;

          return (
            <div className="item" key={payor.id}>
              <div className="sb-table-cell contact">
                <div className={Styles.payorContainer}>
                  <div className={Styles.payorName}>{payor.displayName}</div>
                </div>
              </div>
              <div className="sb-table-cell available">{formatCurrency(payor.available)}</div>
              <div className="sb-table-cell amount">
                <CurrencyInput2
                  name="amount"
                  value={amount || 0}
                  min={0}
                  max={payor.available}
                  onChange={(e) => onChangePayorAmount({ id: payor.id, amount: e.target.value ?? 0 })}
                  className={Styles.amountInput}
                />
              </div>
              <div className={classNames('sb-table-cell', 'amount-checkbox', Styles.amountCheckbox)}>
                <Checkbox checked={isChecked} onChange={() => onSelectPayor({ id: payor.id, checked: !isChecked })} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="sb-table-footer item">
        <div className="sb-table-cell contact" />
        <div className="sb-table-cell available" />
        <div className="sb-table-cell amount">{formatCurrency(summary?.amount)}</div>
        <div className="sb-table-cell amount-checkbox" />
      </div>
    </div>
  );
};

SplitPayorsList.propTypes = {
  payors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  payorAmounts: PropTypes.object, // Mapped object where: { <contactId>: <allocatedAmount> }
  summary: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }).isRequired,
  // Callbacks
  onChangePayorAmount: PropTypes.func.isRequired,
  onSelectPayor: PropTypes.func.isRequired,
};

SplitPayorsList.defaultProps = {
  payorAmounts: {},
};

SplitPayorsList.displayName = 'SplitPayorsList';
