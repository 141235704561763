import React from 'react';
import PropTypes from 'prop-types';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { LawpayPaymentForm } from '@sb-billing/lawpay-payment-form';
import { StripePaymentForm } from '@sb-billing/stripe-payment-form';
import { FeeWisePaymentForm } from '@sb-billing/fee-wise-payment-form';
import Styles from './PaymentProviderChargeForm.module.scss';

export const PaymentProviderChargeForm = ({
  providerType,
  triggerSubmit,
  paymentFormConfiguration,
  isSubmitting,
  onReadyForSubmit,
  onSubmit,
  onPreSubmitChange,
  onPreCharge,
  region,
}) => (
  <div className={Styles.PaymentProviderChargeForm}>
    {providerType === providers.LAWPAY && (
      <LawpayPaymentForm
        enabledPaymentMethods={paymentFormConfiguration.enabledPaymentMethods}
        publicKey={paymentFormConfiguration.publicKey}
        hostedFieldsStyle={paymentFormConfiguration.hostedFieldsStyle}
        triggerSubmit={triggerSubmit}
        isSubmitting={isSubmitting}
        onReadyForSubmit={onReadyForSubmit}
        onSubmit={onSubmit}
      />
    )}
    {providerType === providers.STRIPE && (
      <StripePaymentForm
        countryCode={region}
        publicKey={paymentFormConfiguration.publicKey}
        hostedFieldsStyle={paymentFormConfiguration.hostedFieldsStyle}
        triggerSubmit={triggerSubmit}
        isSubmitting={isSubmitting}
        onReadyForSubmit={onReadyForSubmit}
        onSubmit={onSubmit}
        onPreSubmitChange={onPreSubmitChange}
      />
    )}
    {providerType === providers.EZY_COLLECT && (
      <StripePaymentForm /* EZY Collect is currently utilising stripe in the background */
        countryCode="AU" /* TODO: LM: this needs to be dynamic to support multi region EzyCollect */
        publicKey={paymentFormConfiguration.publicKey}
        hostedFieldsStyle={paymentFormConfiguration.hostedFieldsStyle}
        triggerSubmit={triggerSubmit}
        isSubmitting={isSubmitting}
        useSmokeballLogo // Forces the smokeball logo to display instead of 'powered by stripe' logo.
        onReadyForSubmit={onReadyForSubmit}
        onSubmit={onSubmit}
        onPreSubmitChange={onPreSubmitChange}
      />
    )}
    {providerType === providers.FEE_WISE && (
      <FeeWisePaymentForm
        enabledPaymentMethods={paymentFormConfiguration.enabledPaymentMethods}
        payorDetails={paymentFormConfiguration.payorDetails}
        payorId={paymentFormConfiguration.payorId}
        tokenType={paymentFormConfiguration.tokenType}
        hostedFieldsStyle={paymentFormConfiguration.hostedFieldsStyle}
        termsText={paymentFormConfiguration.termsText}
        triggerSubmit={triggerSubmit}
        isSubmitting={isSubmitting}
        onReadyForSubmit={onReadyForSubmit}
        onSubmit={onSubmit}
        onPreCharge={onPreCharge}
      />
    )}
  </div>
);

PaymentProviderChargeForm.displayName = 'PaymentProviderChargeForm';

PaymentProviderChargeForm.propTypes = {
  providerType: PropTypes.string.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
  paymentFormConfiguration: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPreSubmitChange: PropTypes.func.isRequired,
  onPreCharge: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
};

PaymentProviderChargeForm.defaultProps = {};
