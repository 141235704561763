angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-bill/payments';

  $stateProvider

  .state('home.billing.view-bill.payments', {
    url: '/payments',
    templateUrl: BASE_DIRECTORY + '/view-bill-payments.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'bills'],
      tab: { type: 'invoice' }
    }
  });

});
