'use strict';

angular.module('sb.billing.webapp').directive('sbActionReverseWaiveInvoice', function (sbReverseWaiveInvoiceCommand, sbMessageDisplayService, sbLoggerService) {
  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      const log = sbLoggerService.getLogger('sbActionReverseWaiveInvoice');
      let invoice;

      ctrl.group.onInvoiceData((data) => {
        invoice = data;
      });

      ctrl.group.setAction(reverseWaiveInvoice, isReversible);

      function reverseWaiveInvoice () {
        if (invoice) {
          ctrl.group.setLock(true);
          return sbReverseWaiveInvoiceCommand.executeP(invoice.invoiceId)
            .then(() => { ctrl.group.setLock(); })
            .catch((err) => {
              sbMessageDisplayService.error(
                sbMessageDisplayService
                  .builder()
                  .text('Failed to reverse waive of invoice')
                  .conditionalText(' #{0}', invoice.invoiceNumber)
                  .text('. Please check your connection and try again.')
              );

              log.warn('Failed to reverse waive of invoice ' + err.message, invoice);
              ctrl.group.setLock();
            });
        } else {
          throw new Error('no invoice found to reverse waive');
        }
      }

      function isReversible () {
        return invoice && invoice.waived;
      }
    }
  };
});
