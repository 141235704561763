import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { getInvoiceSummariesByFilter } from '@sb-billing/redux/invoices';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import {
  paymentMethodTypes,
  paymentMethodTypesByProvider,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import {
  isMatterContactBalanceType,
  isMatterBalanceType,
  getSettings as getBankAccountSettings,
} from '@sb-billing/redux/bank-account-settings';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import { todayAsInteger } from '@sb-itops/date';
import {
  getNumberingSettings as getTransactionNumberingSettings,
  getOperatingAccount,
} from '@sb-billing/redux/bank-account';
import { getByBankAccountId as getTrustChequesByBankAccountId } from '@sb-billing/redux/trust-cheques';
import {
  PAYMENT_TYPE,
  getPaymentSources,
  getDirectPaymentSources,
  PAYMENT_SOURCE,
} from 'web/redux/selectors/payment-source';
import {
  printMethods as printMethodsList,
  notApplicablePrintMethodLocalised,
  PrintNow,
  PrintLater,
  PrintNotApplicable,
  PrintManually,
} from '@sb-billing/business-logic/cheques';
import { getNextChequeNumber } from 'web/services/cheques';
import { getLogger } from '@sb-itops/fe-logger';
import {
  getActiveProvider,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { paymentMethods as lawpayPaymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { getMinChargeAmountInCents } from '@sb-billing/business-logic/payment-provider/services';
import { useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import { PaymentAddForm } from './PaymentAddForm';
import { getValidateFn } from './validation';

const paymentTypes = {
  MATTER: 'MATTER',
  CLIENT: 'CLIENT',
};
const log = getLogger('paymentAddForm');
const region = getRegion();

const localisationConfig = {
  echeque: hasFacet(facets.echeque),
  paymentReason: hasFacet(facets.reasonField),
  chequeMemo: hasFacet(facets.chequeMemo),
  ttoNumbering: hasFacet(facets.ttoNumbering),
  allowOverdraw: hasFacet(facets.allowOverdraw),
};

const hooks = (props) => ({
  useConfig: () => ({
    supportsEcheque: localisationConfig.echeque,
    showReason: localisationConfig.paymentReason,
    showChequeMemo: localisationConfig.chequeMemo,
  }),
  useSelectors: () => {
    const { t } = useTranslation();
    const {
      currentStep,
      matterId: defaultMatterId,
      contactId: defaultContactId,
      scope,
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      onChargeFormDataChange,
      triggerChargeFormSubmit,
    } = props;
    const dispatch = useDispatch();

    const bankAccountSettings = getBankAccountSettings() || {};

    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const {
      paymentType,
      matterId,
      clientId,
      contactId,
      effectiveDate,
      comment,
      amount,
      reference,
      chequeMemo,
      reason,
      pdfOnTrustPayment,
      takePaymentNow,
    } = formFields;
    // fields which are objects pass as regular object
    const { printingMethod, source, payments = [] } = useSelector(formSelectors.getFieldValues);

    const printMethods = [notApplicablePrintMethodLocalised(t), ...printMethodsList].map((pm) => ({
      label: pm.display,
      value: pm.value,
    }));

    const activeProviderType = getActiveProvider();

    // onLoad, form is not initialised
    useEffect(() => {
      const todayAsInt = todayAsInteger();
      const paymentTypeOnLoad = defaultContactId ? paymentTypes.CLIENT : paymentTypes.MATTER;
      const sourcesOnLoad = getPaymentSourcesByType(paymentTypeOnLoad, t, {
        matterId: defaultMatterId,
        effectiveDate: todayAsInt,
        activeProviderType,
      });
      // we can't use invoiceSummaries variable as it is not inited at this time
      const invSummaries = getInvoiceSummaries({ debtorId: defaultContactId, matterId: defaultMatterId }) || [];
      const debtorIds = getDebtorIds(invSummaries);
      const debtorId = debtorIds.length === 1 ? debtorIds[0] : undefined;
      setContactFallback(defaultContactId || debtorId);

      log.info('initialised');
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            matterId: defaultMatterId,
            contactId: defaultContactId || debtorId,
            clientId: defaultContactId,
            effectiveDate: todayAsInt,
            comment: undefined,
            amount: 0,
            reference: undefined,
            reason: undefined,
            chequeMemo: undefined,
            saveCardDetails: false,
            source: sourcesOnLoad.find((src) => src.isDefault),
            takePaymentNow: false,
            printingMethod: printMethods[0],
            pdfOnTrustPayment: bankAccountSettings.createPDFReceiptOnTrustPayment,
            paymentType: defaultContactId ? paymentTypes.CLIENT : paymentTypes.MATTER,
            payments: [],
          },
        }),
      );

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sources = getPaymentSourcesByType(paymentType?.value, t, {
      matterId: matterId?.value,
      effectiveDate: effectiveDate?.value,
      activeProviderType,
    });

    // uses undefined value when we can't get invoices, that is:
    // no matter selected for Matter type
    // no client selected for Client type
    let invoiceSummaries;

    if (paymentType?.value === paymentTypes.CLIENT) {
      invoiceSummaries = clientId?.value ? getInvoiceSummaries({ debtorId: clientId?.value }) : undefined;
    } else if (paymentType?.value === paymentTypes.MATTER) {
      invoiceSummaries = matterId?.value ? getInvoiceSummaries({ matterId: matterId?.value }) : undefined;
    }

    const [reasonOverridden, setReasonOverridden] = useState(false);
    const [showAddContactForm, setShowAddContactForm] = useState(false);
    const [contactFallback, setContactFallback] = useState(undefined);
    const [takePaymentNowDisabled, setTakePaymentNowDisabled] = useState(true);

    const totalPayments = payments.reduce((runningTotal, invoicePayment) => runningTotal + invoicePayment.amount, 0);

    const minAmountAllowed = activeProviderType
      ? getMinChargeAmountInCents({ providerType: activeProviderType, region })
      : 0;

    const validate = () => {
      dispatch(formOperations.validateForm({ validateFn: getValidateFn({ t, minAmountAllowed }) }));
    };
    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      validate();
    };
    const onFieldValueSet = ({ field, value }) => {
      dispatch(formActions.setFieldValue({ field, value }));
      validate();
    };

    const updateDefaultReason = ({ paymentSource }) => {
      if (reasonOverridden) {
        return;
      }
      let newReason = '';
      if (paymentSource?.paymentType !== PAYMENT_TYPE.trust) {
        newReason = '';
      } else {
        newReason = `${t('capitalize', { val: 'trustToOfficeTransferLabel' })} for costs and outlays`;
      }
      onFieldValueUpdated({ reason: newReason });
    };

    const onPaymentSourceChange = (newSource) => {
      const fieldsToUpdate = {};

      onFieldValueSet({ field: 'source', value: newSource });
      fieldsToUpdate.reference = isReferenceReadonly({ source: newSource, printingMethod, activeProviderType })
        ? ''
        : reference?.value;

      // if takePaymentNowFieldEnabled
      if (activeProviderType) {
        if (
          newSource?.paymentSource === PAYMENT_SOURCE.creditCard &&
          hasPaymentProviderConfiguredForSource({ source: newSource, activeProviderType })
        ) {
          setTakePaymentNowDisabled(false);
        } else if (
          localisationConfig.echeque &&
          newSource?.paymentSource === PAYMENT_SOURCE.eCheck &&
          hasPaymentProviderConfiguredForSource({ source: newSource, activeProviderType })
        ) {
          setTakePaymentNowDisabled(true);
          fieldsToUpdate.takePaymentNow = true;
          fieldsToUpdate.effectiveDate = todayAsInteger();
        } else {
          setTakePaymentNowDisabled(true);
          fieldsToUpdate.takePaymentNow = false;
        }
      }

      // if the firm has matter contact balances, change the contactId if the
      // user has selected a non-direct source (trust or operating)
      if (isMatterContactBalanceType()) {
        fieldsToUpdate.contactId = newSource?.contactId || contactFallback;
      }
      // if the firm has matter balances, we clear the contactId if the user
      // has selected a non-direct source (trust or operating)
      else if (isMatterBalanceType()) {
        const isDirectPayment = newSource?.paymentType === PAYMENT_TYPE.direct;
        fieldsToUpdate.contactId = isDirectPayment ? contactFallback : undefined;
      }

      onFieldValueUpdated(fieldsToUpdate);
      updateDefaultReason({ paymentSource: newSource });
    };

    const readOnlyReference = isReferenceReadonly({ source, printingMethod, takePaymentNow, activeProviderType });

    const showPaymentPlanPaymentsWithInterestWarning = !!payments.find(
      (invoicePayment) => !!invoicePayment.paymentPlanId && invoicePayment.amount > invoicePayment.unpaidExcInterest,
    );

    const isTrustChequesEnabled =
      source?.paymentType === PAYMENT_TYPE.trust && isTrustChequePrintingActive(source?.bankAccountId);

    return {
      currentStep,
      scope,
      readOnlyReference,
      showAddContactForm,
      setShowAddContactForm,
      printMethods,
      contactOptions: getContactTypeAheadSummaries(),
      trustChequesEnabled: isTrustChequesEnabled,
      invoiceSummaries,
      isChequeMemoVisible: isChequeMemoVisible(printingMethod),
      onFieldValueUpdated,
      sources,
      showPaymentPlanPaymentsWithInterestWarning,
      showTakePaymentNowField: !!activeProviderType,
      takePaymentNowDisabled,
      effectiveDateDisabled: isEffectiveDateDisabled({ takePaymentNow, activeProviderType }),
      shouldDisableTtoPdf: shouldDisableTtoPdf(printingMethod),
      balance: (amount?.value || 0) - totalPayments,
      onPaymentTypeChange: (newPaymentType) => {
        const fieldsToUpdate = { paymentType: newPaymentType };

        dispatch(formActions.setFieldValue({ field: 'payments', value: [] }));
        onFieldValueUpdated(fieldsToUpdate);

        // when payment type changes from Matter to Client/Debtor or vice versa,
        // paymentSource has to be reset to default
        const sourcesUpdated = getPaymentSourcesByType(newPaymentType, t, {
          matterId: matterId?.value,
          effectiveDate: effectiveDate?.value,
          activeProviderType,
        });
        const newSource = sourcesUpdated.find((src) => src.isDefault);
        onPaymentSourceChange(newSource);
      },
      onChangePrintingMethod: (newPrintingMethod) => {
        onFieldValueUpdated({ printingMethod: newPrintingMethod });
        if (source?.paymentType === PAYMENT_TYPE.trust) {
          if (isReferenceReadonly({ source, printingMethod: newPrintingMethod, activeProviderType })) {
            onFieldValueUpdated({ reference: '' });
          } else if (newPrintingMethod?.value === PrintManually) {
            onFieldValueUpdated({
              reference: getNextChequeNumber(getTrustChequesByBankAccountId(source.bankAccountId)),
            });
          }
          // when readonlyReference is false and it is printNotApplicable we need to clear the reference, since this means that
          // the numbering is disabled
          else if (newPrintingMethod?.value === PrintNotApplicable) {
            onFieldValueUpdated({ reference: '' });
          }
        }
      },
      onSelectMatter: (newMatterId) => {
        const fieldsToUpdate = {};
        const invoiceSummariesUpdated = getInvoiceSummaries({ matterId: newMatterId });
        const debtorIdsUpdated = getDebtorIds(invoiceSummariesUpdated);

        fieldsToUpdate.matterId = newMatterId;

        if (isMatterContactBalanceType() || (isMatterBalanceType() && source?.paymentType === PAYMENT_TYPE.direct)) {
          const debtorId = debtorIdsUpdated.length === 1 ? debtorIdsUpdated[0] : undefined;
          fieldsToUpdate.contactId = debtorId;
          setContactFallback(debtorId);
        }
        const sourcesUpdated = getPaymentSourcesByType(paymentTypes.MATTER, t, {
          matterId: newMatterId,
          effectiveDate: effectiveDate?.value,
          activeProviderType,
        });
        fieldsToUpdate.source = sourcesUpdated.find((s) => s.isDefault);

        dispatch(formActions.setFieldValue({ field: 'payments', value: [] }));
        onFieldValueUpdated(fieldsToUpdate);
      },
      onSelectContact: (newContactId) => {
        setContactFallback(newContactId);
        onFieldValueUpdated({ contactId: newContactId });
      },
      onSelectClient: (newClientId) => {
        const fieldsToUpdate = {};

        if (newClientId) {
          const invoiceSummariesUpdated = getInvoiceSummaries({ debtorId: newClientId });
          const debtorIdsUpdated = getDebtorIds(invoiceSummariesUpdated);
          fieldsToUpdate.clientId = newClientId;
          fieldsToUpdate.contactId = debtorIdsUpdated.length === 1 ? debtorIdsUpdated[0] : undefined;
          fieldsToUpdate.source = sources.find((s) => s.isDefault);
        } else {
          fieldsToUpdate.clientId = undefined;
          fieldsToUpdate.contactId = undefined;
        }

        onFieldValueUpdated(fieldsToUpdate);
        dispatch(formActions.setFieldValue({ field: 'payments', value: [] }));
      },
      onTakePaymentNowToggled: (name, val) => {
        onFieldValueUpdated({ [name]: val });
        if (val === true) {
          onFieldValueUpdated({ effectiveDate: todayAsInteger() });
        }
      },
      onPaymentsListChange: (invoicePayments) => {
        updateDefaultReason({ paymentSource: source });
        dispatch(formActions.setFieldValue({ field: 'payments', value: invoicePayments }));
        // update amount so the field is dirty and validation highlights it
        onFieldValueUpdated({ amount: amount?.value });
        validate();
      },
      onReasonChange: (newReason) => {
        setReasonOverridden(true);
        onFieldValueUpdated({ reason: newReason });
      },
      onPaymentSourceChange,

      // form
      paymentType,
      matterId,
      clientId,
      contactId,
      effectiveDate,
      comment,
      amount,
      reference,
      chequeMemo,
      printingMethod,
      reason,
      source,
      takePaymentNow,
      pdfOnTrustPayment,
      // form
      submitFailed,
      formDisabled: formSubmitting,
      formInitialised,
      getValidateFn,
      // Underlying charge forms props
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      onChargeFormDataChange,
      triggerChargeFormSubmit,
    };
  },
});

const isChequeMemoVisible = (printingMethod) =>
  printingMethod?.value === PrintNow || printingMethod?.value === PrintManually;

function getInvoiceSummaries({ matterId, debtorId }) {
  if (!matterId && !debtorId) {
    return [];
  }

  return getInvoiceSummariesByFilter({
    matterId,
    debtorId,
    status: 'FINAL',
  }).map((invoiceSummary) => {
    const invoiceTotals = getTotalsForInvoiceId(invoiceSummary.invoiceId);
    const totals = {
      total: invoiceTotals?.total || 0,
      billed: invoiceTotals?.billed || 0,
      unpaid: invoiceTotals?.unpaid || 0,
      paid: invoiceTotals?.paid || 0,
      unpaidExcInterest: invoiceTotals?.unpaidExcInterest || 0,
      interest: invoiceTotals?.interest || 0,
    };

    return { ...invoiceSummary, ...totals, withPaymentPlan: true };
  });
}

function getDebtorIds(invoiceSummaries) {
  return (invoiceSummaries || []).reduce(
    (debtors, invoice) => [...new Set([...invoice.currentVersion.debtors.map((d) => d.id), ...debtors])],
    [],
  );
}

function shouldDisableTtoPdf(printingMethod) {
  // If Print Now trust cheques is selected, we disable the TTO receipt option as cheques take priority over the receipt
  // The receipt can be generated from accounts > trust > print detail if needed
  return printingMethod?.value === PrintNow;
}

function isEffectiveDateDisabled({ takePaymentNow, activeProviderType }) {
  return !!activeProviderType && takePaymentNow?.value === true;
}

/**
 * The reference is read-only, if;
 * The region uses TTO auto numbering, selected account is a trust account, print method is PrintNotApplicable OR
 * The selected account is a trust account, trust cheque printing is enabled, and the printing method selected is 'Print Now' or 'Print Later' OR
 * The takePaymentNow toggle is displayed and set to true
 *
 * We double down on the "selected account is a trust account" just to be sure that the two concepts are independent.
 */
function isReferenceReadonly({ printingMethod, source, takePaymentNow, activeProviderType }) {
  const isReadOnlyCheque =
    source?.paymentType === PAYMENT_TYPE.trust &&
    isTrustChequePrintingActive(source.bankAccountId) &&
    (printingMethod?.value === PrintNow || printingMethod?.value === PrintLater);

  const isReadOnlyElectronicPayment =
    source?.paymentType === PAYMENT_TYPE.trust &&
    printingMethod?.value === PrintNotApplicable &&
    localisationConfig.ttoNumbering &&
    isTrustToOfficeNumberingEnabled(source.bankAccountId);

  const takePaymentNowSelected = !!activeProviderType && takePaymentNow?.value === true;

  return isReadOnlyCheque || isReadOnlyElectronicPayment || takePaymentNowSelected;
}

function hasPaymentProviderConfiguredForSource({ source, activeProviderType }) {
  const operatingAccountId = getOperatingAccount().id;

  return isPaymentProviderEnabledForBankAccount({
    providerType: activeProviderType,
    bankAccountId: operatingAccountId,
    providerSpecificFields: {
      paymentMethod: getPaymentMethodFromSource(source), // used for LP only
    },
  });
}

function getPaymentMethodFromSource(source) {
  // This is used for Lawpay only, other payment providers just ignore paymentMethod
  switch (source.paymentSource) {
    case PAYMENT_SOURCE.creditCard:
      return lawpayPaymentMethods.CREDIT_CARD;
    case PAYMENT_SOURCE.eCheck:
      return lawpayPaymentMethods.ECHEQUE;
    default:
      return null;
  }
}

function isTrustToOfficeNumberingEnabled(trustBankAccountId) {
  const { trustToOfficeNumberingSettings } = getTransactionNumberingSettings({
    bankAccountId: trustBankAccountId,
  });

  return !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering;
}

function isTrustChequePrintingActive(trustBankAccountId) {
  return !!(getTrustChequePrintSettingsById(trustBankAccountId) || {}).printingActive;
}

/**
 * get the payment sources list based if it is matter or contact payment type.
 * @param {('CLIENT'|'MATTER')} paymentType
 * @param {function} t - Localisation function
 * @param {object} [options]
 * @param {string} [options.matterId] - uuid of the matter id
 * @param {Date} [options.effectiveDate] - date from which we need to get the balances
 * @param {Date} [options.activeProviderType] - active payment provider type
 * @returns {Array.<object>} list of payment sources
 * @throws if param.options.effectiveDate is falsey when paymentType is 'MATTER'
 */
function getPaymentSourcesByType(paymentType, t, options = {}) {
  let sources = [];

  if (paymentType === paymentTypes.MATTER) {
    const { matterId, effectiveDate } = options;

    if (!effectiveDate) {
      return [];
    }

    sources = getPaymentSources({
      matterId,
      includeCombined: false,
      effectiveDate,
      includeCreditSources: false,
      t,
      allowOverdraw: localisationConfig.allowOverdraw,
    });
  }
  if (paymentType === paymentTypes.CLIENT) {
    sources = getDirectPaymentSources(t);
  }

  const eCheckSource = {
    label: t('echeque'),
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.eCheck,
    value: PAYMENT_SOURCE.eCheck,
  };

  const { activeProviderType } = options;
  const supportedPaymentMethodTypes = paymentMethodTypesByProvider[activeProviderType];
  if (
    localisationConfig.echeque &&
    !!activeProviderType &&
    hasPaymentProviderConfiguredForSource({ source: eCheckSource, activeProviderType }) &&
    (supportedPaymentMethodTypes || []).includes(paymentMethodTypes.DIRECT_DEBIT)
  ) {
    sources.push(eCheckSource);
  }

  return sources;
}

export const PaymentAddFormContainer = withReduxProvider(composeHooks(hooks)(PaymentAddForm));

PaymentAddFormContainer.displayName = 'PaymentAddFormContainer';

PaymentAddFormContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  scope: PropTypes.string.isRequired,
  onChargeFormSubmit: PropTypes.func,
  onChargeFormReadyForSubmit: PropTypes.func,
  onChargeFormDataChange: PropTypes.func.isRequired,
  triggerChargeFormSubmit: PropTypes.bool,
};

PaymentAddFormContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  onChargeFormSubmit: () => {},
  onChargeFormReadyForSubmit: () => {},
  triggerChargeFormSubmit: false,
};
