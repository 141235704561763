import { interpolateText } from '@sb-billing/business-logic/invoice-statement';
import { getFirmName, getPhoneNumber, getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';

export const getInvoiceStatementEmailInterpolated = async ({ debtorName, subject, message }) => {
  const data = {
    firmName: getFirmName(),
    phoneNumber: getPhoneNumber(),
    staffName: getStaffEmailDetails().name,
    showPaymentButton: true,
    debtorName,
  };

  const interpolatedSubject = interpolateText(subject, data);
  const interpolatedMessage = interpolateText(message, data);

  return { subject: interpolatedSubject, message: interpolatedMessage };
};
