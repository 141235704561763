import * as types from './types';

export const setDashboards = (dashboards) => ({
  type: types.SET_DASHBOARDS,
  payload: { dashboards },
});
export const setSettings = (settings) => ({
  type: types.SET_DASHBOARD_SETTINGS,
  payload: { settings },
});
export const setTempSettings = (tempSettings) => ({
  type: types.SET_TEMP_SETTINGS,
  payload: { tempSettings },
});
export const setCurrentDashboard = (currentDashboard) => ({
  type: types.SET_CURRENT_DASHBOARD,
  payload: { currentDashboard },
});
