'use strict';

const enUS = require('./en-US.json');
const enAU = require('./en-AU.json');
const enGB = require('./en-GB.json');
const getLanguageDetector = require('./sbb-language-detector');
const addFormatters = require('./formatters');
const interpolationOptions = require('./interpolation-options');

const resources = {
  'en-US': enUS,
  'en-AU': enAU,
  'en-GB': enGB,
};

const getResources = (lng) => {
  if (!lng) {
    throw new Error('Missing lng');
  }
  if (!resources[lng]) {
    throw new Error(`No resources for lng ${lng}`);
  }
  return resources[lng];
};

module.exports = {
  getResources,
  getLanguageDetector,
  addFormatters,
  interpolationOptions
};
