import React, { useEffect, useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getCurrentStaff, getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import uuid from '@sb-itops/uuid';
import { getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { TaskSchema, PhoneMessageSchema, AddTaskModal, AddTaskModalMode, AddPhoneMessageModal } from 'web/components';
import { Task, Person } from 'types';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import moment from 'moment';
import { getLogger } from '@sb-itops/fe-logger';
import { withOnLoad } from '@sb-itops/react';
import { checkMatterPermissionsAndDisplayErrorPopupP } from 'web/services/matter-permissions';
import { useQuery } from '@apollo/client';
import { FirmTaskCategoriesDocument } from 'web/graphql/types/graphql';
import { falsyGuard } from '@sb-itops/type-helpers';

const log = getLogger('AddTaskModalContainer');

export const ADD_TASK_MODAL_ID = 'add-task-modal';
export const ADD_PHONE_MESSAGE_MODAL_ID = 'add-phone-message-modal';

export interface IAddTaskModalContainerProps {
  scope: string;
  matterId: string;
  allowMatterSwitching?: boolean;
  task?: Task;
  onClose: () => void;
  completer: Person;
  isPhoneMessage?: boolean;
}

const hooks = ({
  scope,
  matterId,
  task,
  allowMatterSwitching = true,
  onClose,
  isPhoneMessage,
}: IAddTaskModalContainerProps) => ({
  useCategories: () => {
    const [existingCategories, setExistingCategories] = useState(task?.categories ? [...task.categories] : []);

    const { data } = useQuery(FirmTaskCategoriesDocument);

    useEffect(() => {
      const taskCats = task?.categories ? [...task.categories] : [];
      const dataCats = (data?.firmTaskCategories || []).map((c) => c?.id).filter(falsyGuard);
      const catSet = new Set<string>([...taskCats, ...dataCats]);
      setExistingCategories([...catSet]);
    }, [data, task]);

    return {
      existingCategories,
    };
  },
  useModalProps: () => {
    const {
      formFields,
      formSubmitting,
      submitFailed,
      onSubmitFormWithValidation,
      onInitialiseForm,
      onClearForm,
      onResetForm,
      onFieldValueSet,
      onValidateForm,
    } = useForm<Task>({ scope, schema: isPhoneMessage ? PhoneMessageSchema : TaskSchema });

    const [commandLoading, setCommandLoading] = useState(false);

    const loggedInStaff = getLoggedInStaff();

    const onSaveTask = async (newTask: Task, isNewEntity: boolean) => {
      const assigneesChanged = JSON.stringify(task?.assigneeIds) !== JSON.stringify(newTask.assigneeIds);
      const marshalledData = {
        id: newTask.id,
        versionId: uuid(),
        matterId: newTask.matterId,
        subject: newTask.subject,
        note: newTask.note,
        isCompleted: newTask.isCompleted,
        isRemoved: false,
        assigneeIds: newTask.assigneeIds,
        dueDate: newTask.dueDate,
        categories: newTask.categories,
        subTaskIds: [],
        creationTimeStamp: newTask.creationTimeStamp || moment().toISOString(),
        isNewEntity,
        lastAssignedById: assigneesChanged ? getLoggedInStaff().id : task?.lastAssignedById || '',
      };

      await dispatchCommand({ type: 'Tasking.ManageTasks.Messages.SaveTasks2', message: marshalledData });
    };

    return {
      assignableStaff: getCurrentStaff(),
      assignableMatters: getMatterTypeaheadSummaries(true),
      allowMatterSwitching,
      isRemoved: task?.isRemoved,
      mode: task?.id ? AddTaskModalMode.edit : AddTaskModalMode.create,
      fields: formFields,
      loading: formSubmitting || commandLoading,
      submitFailed,
      loggedInStaff,
      onDelete: async () => {
        try {
          if (formSubmitting || commandLoading) {
            return;
          }
          setCommandLoading(true);
          const marshalledData = {
            id: task?.id,
            versionId: uuid(),
          };
          if (task?.isRemoved) {
            await dispatchCommand({ type: 'Tasking.ManageTasks.Messages.RestoreTask', message: marshalledData });
            displaySuccessToUser('Task restored.');
          } else {
            await dispatchCommand({ type: 'Tasking.ManageTasks.Messages.RemoveTask', message: marshalledData });
            displaySuccessToUser('Your task is deleted');
          }

          setCommandLoading(false);
          onClose();
        } catch {
          displayErrorToUser('Failed to delete task');
          setCommandLoading(false);
        }
      },
      onLoad: () => {
        // Default to todays date if we are creating a new task, otherwise respect empty due dates for existing tasks
        const dueDate = task?.id
          ? task?.dueDate && dateToInteger(moment(task?.dueDate).toDate())
          : dateToInteger(new Date());
        const fieldValues = {
          ...(task || {}),
          id: task?.id || uuid(),
          subject: task?.subject || '',
          dueDate,
          assigneeIds: task?.assigneeIds || (isPhoneMessage ? [] : [loggedInStaff.id]),
          categories: task?.categories ? [...task.categories] : [],
          matterId: task?.matterId || matterId || '',
          note: task?.note || '',
          durationType: task?.durationType || 'HOURS',
          duration: task?.duration || '',
          isCompleted: task?.isCompleted || false,
        } as Task;

        onInitialiseForm(fieldValues);

        const onUnload = () => onClearForm();
        return onUnload;
      },
      onFieldValueUpdated: onFieldValueSet,
      onSubmit: () => {
        onValidateForm();

        onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            if (
              !(await checkMatterPermissionsAndDisplayErrorPopupP(formData.matterId, {
                group: 'matter-permissions-tasks',
              }))
            ) {
              return;
            }

            try {
              const isNewEntity = !task?.id;
              await onSaveTask(
                {
                  ...formData,
                  dueDate: formData.dueDate && integerToDate(formData.dueDate).toISOString(),
                },
                isNewEntity,
              );
              displaySuccessToUser('Your task has been saved');
              onClose();
            } catch (err) {
              displayErrorToUser('Failed to save task');
              log.error(err);
            }
          },
        });
      },
      onSubmitSaveAndNew: () => {
        onValidateForm();

        onSubmitFormWithValidation({
          submitFnP: async (formData: Task) => {
            if (
              !(await checkMatterPermissionsAndDisplayErrorPopupP(formData.matterId, {
                group: 'matter-permissions-tasks',
              }))
            ) {
              return;
            }

            try {
              const isNewEntity = !task?.id;
              await onSaveTask(
                {
                  ...formData,
                  dueDate: formData.dueDate && integerToDate(formData.dueDate).toISOString(),
                },
                isNewEntity,
              );
              displaySuccessToUser('Your task has been saved');
              onResetForm();
              onFieldValueSet('id', uuid());
            } catch (err) {
              displayErrorToUser('Failed to save task');
              log.error(err);
            }
          },
        });
      },
    };
  },
});

const withIsPhoneMessage =
  (WrappedComponent) =>
  ({ ...props }) =>
    <WrappedComponent {...{ ...props, isPhoneMessage: true }} />;

export const AddTaskModalContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(AddTaskModal)));
export const AddPhoneMessageModalContainer = withReduxProvider(
  withIsPhoneMessage(composeHooks(hooks)(withOnLoad(AddPhoneMessageModal))),
);
