import { fetchGetP } from '@sb-itops/redux/fetch';
import { mapRoles } from '../mappers';

export const clientSdkGetRoles = async (matterId: string) => {
  const path = `/matter-management/matter-roles/:accountId/${matterId}`;

  const responseData = await fetchGetP({ path });

  const result = {
    versionId: responseData.body.versionId,
    roles: mapRoles(responseData.body.roles),
  };

  return result;
};
