
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';

angular.module('sb.billing.webapp').directive('sbShowIfPaymentProviderActive', function() {
  'use strict';
  return {
    link: function link(scope, element, attrs) {
      const activeProvider = getActiveProvider();
      const providerType = attrs.sbShowIfPaymentProviderActive;

      if (!activeProvider || activeProvider !== providerType) {
        element.hide();
      }
    }
  };
});
