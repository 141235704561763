import { cacheFactory, syncTypes, indexTypes } from '@sb-itops/redux';
import { integerToDate, today as nowDateOnly } from '@sb-itops/date';
import { fetchPeople, getEmailFromContactId } from '@sb-customer-management/redux/contacts';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { featureActive } from '@sb-itops/feature';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import {
  getAttorneyResponsibleFilter,
  getMatterTypeFilter,
  getMatterIdsFilter,
  getSentStatusFilter,
  getSendPreferenceFilter,
  getBillingEventFilter,
  getStatusFilter,
  getStatusesFilter,
  getBalanceFilter,
  getIssuedDateFilter,
  getFinalizedOnFilter,
  getBillingTypeFilter,
  getBillingFrequencyFilter,
  getRecentInvoicesFilter,
  getOverdueFilter,
  getNotOverdueFilter,
  getDebtorFilter,
  getDebtorsFilter,
  getInvoiceIdsFilter,
  issuedDateFilterTypes,
} from './filters';
import domain from '../domain';

const statusByValue = {
  0: 'DRAFT',
  1: 'FINAL',
  2: 'PAID',
  3: 'DELETED',
  4: 'VOID',
};

export const invoiceStatuses = {
  DRAFT: statusByValue[0],
  FINAL: statusByValue[1],
  PAID: statusByValue[2],
  DELETED: statusByValue[3],
  VOID: statusByValue[4],
};

export const { UPDATE_CACHE, getMap, getList, getById, updateCache, clearCache, getIndex } = cacheFactory({
  domain,
  name: 'invoices',
  keyPath: 'invoiceId',
  ngCacheName: 'sbInvoicingService',
  syncType: syncTypes.SINCE,
  immutable: false,
  indexes: [
    {
      name: 'byMatterId',
      indexer: (invoice) => invoice.currentVersion.matterId,
      type: indexTypes.ONE_TO_MANY,
    },
  ],
  changesetProcessor: ({ entities = [] }) =>
    entities.map((invoice) => {
      const transformed = { ...invoice };

      if (invoice.currentVersion && Number.isInteger(invoice.currentVersion.status)) {
        transformed.currentVersion.status = statusByValue[invoice.currentVersion.status];
        transformed.currentVersion.invoiceId = invoice.invoiceId;
      }

      if (invoice.isDeleted) {
        transformed.currentVersion.status = 'DELETED';
      }

      return transformed;
    }),
});

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoicingService',
  keyPath: 'invoiceId',
});

export const getInvoiceLatestVersion = (id) => {
  const invoice = getById(id);
  return invoice && invoice.currentVersion;
};

export const getPseudoStatusById = (id) => {
  const latestVersion = getInvoiceLatestVersion(id);
  if (!latestVersion) {
    return;
  }

  if (latestVersion.status === 'FINAL' && integerToDate(latestVersion.dueDate) < nowDateOnly()) {
    // eslint-disable-next-line consistent-return
    return 'OVERDUE';
  }

  // eslint-disable-next-line consistent-return
  return latestVersion.status;
};

export const getInvoiceDebtorIds = (invoice) => {
  if (!invoice.debtors || invoice.debtors.length === 0) {
    return [];
  }

  return featureActive('BB-4416') ? invoice.debtors.map((d) => d.id) : [invoice.debtors[0].id];
};

export const getContactIdFromInvoice = (invoice) => {
  const contact = invoice && invoice.debtors && invoice.debtors[0];
  return contact && contact.id;
};

export const getContactIdFromInvoiceId = (invoiceId) => getContactIdFromInvoice(getInvoiceLatestVersion(invoiceId));

// THIS NEXT 3 Methods are for getting the default email of an invoice
// will try to get the debtor email + salutation latest record, if no will try to
// get the matter contacts info and try to get email or debtor from them

const fetchDebtorContactEmailsFromInvoiceId = (invoiceId) => async (dispatch) => {
  const contactId = getContactIdFromInvoiceId(invoiceId);
  await dispatch(fetchPeople(contactId));
  const emails = getEmailFromContactId(contactId);

  if (emails && emails.length) {
    return emails;
  }
  return undefined;
};

const fetchMatterCustomEmailsFromInvoiceId = (invoiceId) => (dispatch) => {
  const { matterId } = getInvoiceLatestVersion(invoiceId) || {};
  const { clientCustomerIds = [] } = getMatterById(matterId) || {};
  return Promise.all(clientCustomerIds.map((customerId) => dispatch(fetchPeople(customerId))));
};

export const fetchEmailsFromInvoiceId = (invoiceId) => async (dispatch) => {
  const resultFetchDebtor = await dispatch(fetchDebtorContactEmailsFromInvoiceId(invoiceId));
  if (resultFetchDebtor) {
    return resultFetchDebtor;
  }
  return dispatch(fetchMatterCustomEmailsFromInvoiceId(invoiceId));
};

const getDebtorContactEmailsFromInvoiceId = (invoiceId) => {
  const contactId = getContactIdFromInvoiceId(invoiceId);
  const emails = getEmailFromContactId(contactId);
  if (emails && emails.length > 0) {
    return emails;
  }
  return undefined;
};

const getMatterCustomEmailsFromInvoiceId = (invoiceId) => {
  const { matterId } = getInvoiceLatestVersion(invoiceId) || {};
  const { clientCustomerIds } = getMatterById(matterId) || {};
  const matterCustomersEmails = clientCustomerIds
    .map((customerId) => getEmailFromContactId(customerId))
    .filter((info) => info && info.email);

  if (matterCustomersEmails && matterCustomersEmails.length > 0) {
    return matterCustomersEmails;
  }
  return undefined;
};

// create selector
export const getEmailsFromInvoiceId = (invoiceId) => {
  // try to get it first from the invoice
  const resultGetDebtor = getDebtorContactEmailsFromInvoiceId(invoiceId);
  if (resultGetDebtor) {
    return resultGetDebtor;
  }
  // try to get it from the matter otherwise
  return getMatterCustomEmailsFromInvoiceId(invoiceId);
};

export const getByIds = (invoiceIds) => invoiceIds.map(getById);

export const getByMatterId = (matterId) => getIndex('byMatterId')[matterId];

/**
 * Returns all invoices associated with debtor id, including shared invoices
 * @param {String} debtorId
 *
 * @return {Array.<invoice>} The list of invoices
 */
export const getByDebtorId = (debtorId) =>
  getList().filter((invoice) => invoice.currentVersion.debtors.some((debtor) => debtor.id === debtorId));

/**
 * Get invoices that match the given matter id and status.
 *
 * @param {String} matterId The matter id to filter
 * @param {String} status The status to filter
 *
 * @return {Array.<invoice>} The list of invoices
 */
export const getByMatterIdAndStatus = (matterId, status) => {
  const invoicesByMatter = getByMatterId(matterId);
  return invoicesByMatter && invoicesByMatter.filter((invoice) => invoice.currentVersion.status === status);
};

export const getInvoiceSummariesByFilter = (options) => {
  // Build up the filter functions to be applied to each invoice object.
  const filters = [];
  if (options.status) {
    filters.push(getStatusFilter(options.status));
  }

  if (options.matterId || options.matterIds) {
    const matterIds = options.matterIds || [options.matterId];
    filters.push(getMatterIdsFilter(matterIds));
  }

  if (options.debtorId) {
    filters.push(getDebtorFilter(options.debtorId));
  }

  if (options.debtorIds) {
    filters.push(getDebtorsFilter(options.debtorIds));
  }

  if (options.overdueOnly) {
    filters.push(getOverdueFilter());
  } else if (options.notOverdue) {
    filters.push(getNotOverdueFilter());
  }

  if (options.invoiceIds) {
    filters.push(getInvoiceIdsFilter(options.invoiceIds));
  }

  // startDate is sometimes 0
  if (options.startDate !== undefined && options.endDate !== undefined) {
    filters.push(
      getIssuedDateFilter({
        startDate: options.startDate,
        endDate: options.endDate,
        filter: issuedDateFilterTypes.CUSTOM,
      }),
    );
  }

  // Now apply all of the active filters to the invoices in the cache.
  return getList().filter((invoice) => filters.every((filterFn) => filterFn(invoice)));
};

export function getLatestInvoice({ matterId, debtorIds }) {
  const invoices = getInvoiceSummariesByFilter({ matterId, debtorIds });

  if (!invoices.length) {
    return null;
  }

  const latestInvoice = invoices.reduce((latest, invoice) => {
    if (invoice.currentVersion.status !== 'FINAL' && invoice.currentVersion.status !== 'PAID') {
      return latest;
    }

    // By default the first item in the list is the acc value
    // If that is not finalised, we should replace it immediately
    if (latest.currentVersion.status !== 'FINAL' && latest.currentVersion.status !== 'PAID') {
      return invoice;
    }

    if (latest.currentVersion.issuedDate > invoice.currentVersion.issuedDate) {
      return latest;
    }

    if (!invoice.currentVersion.issuedDate) {
      return latest;
    }

    return invoice;
  });

  if (
    !latestInvoice ||
    (latestInvoice.currentVersion.status !== 'FINAL' && latestInvoice.currentVersion.status !== 'PAID')
  ) {
    return null;
  }

  return latestInvoice.currentVersion;
}

export const getInvoiceNumberById = (id) => {
  const invoice = getById(id);
  return invoice && invoice.currentVersion && invoice.currentVersion.invoiceNumber;
};

export const getInvoicePDFVersionId = (id) => {
  const invoice = getById(id);
  return invoice && invoice.invoicePdfVersionId;
};

const hasPendinginvoices = (invoiceIdsToCombine) => {
  const someInvoicesPendingCreation = invoiceIdsToCombine.some((invoiceId) => {
    const invoice = getById(invoiceId);
    return invoice && invoice.currentVersion && invoice.currentVersion.invoiceNumber === 'PENDING';
  });
  return someInvoicesPendingCreation;
};

export {
  hasPendinginvoices,
  getAttorneyResponsibleFilter,
  getMatterTypeFilter,
  getSentStatusFilter,
  getSendPreferenceFilter,
  getBillingEventFilter,
  getBillingTypeFilter,
  getBillingFrequencyFilter,
  getStatusFilter,
  getStatusesFilter,
  getBalanceFilter,
  getIssuedDateFilter,
  getFinalizedOnFilter,
  getRecentInvoicesFilter,
};
