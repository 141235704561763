'use strict';

const MAX_EMAILS_ALLOWED_DEFAULT = 5;

/**
 * IF YOU ARE GOING TO CHANGE THESE REGEX, you may need to change them in `./marshal.js`.
 * The regexes have purposely been kept separate as they do *slightly* different things.
 * 
 * If you change the regex, you need to make sure that the tests still pass.
 */

/**
 * Identifies a "plain email" - `displayName13@smokeball.com` or an appended email `displayName13+test123@smokeball.com`.
 * 
 * Used to verify that a given string is a "plain email".
 */
const plainEmailPattern = /^[\w\-.]+(\+\w+)?@[\w-]+(?:\.[\w-]+){1,3}$/;

/**
 * Used to identify an email with display name - which must be in the form `"Display Name" <email>`.
 * `email` may refer to any number of ';|,' separated emails.
 * 
 * This regex does not validate the emails for a display name email, that is done with `plainEmailPattern` in a separate step.
 * 
 * EX (valid): "Hunter Steele & Max, esq" <email1@smokeball.com;email2@smokeball.com;>
 *
 * EX (invalid): Hunter Steele & Max, esq <email1@smokeball.com;email2@smokeball.com;>
 */
const emailDisplayEmails = /"[^"]+"\s*<([^<>]+)>/;

/**
 * Identifies (and captures) either of the aforementioned accepted emails.
 * 
 * The regex is similar to the previous, either with the groups removed or with non-capturing groups.
 * 
 * This regex does not validate the emails for a display name email, that is done with `plainEmailPattern` in a separate step.
 * 
 * Note: this regex requires that each email has a trailing `;|,` which is added to the string pre-split.
 * This was done due to the complexity of having an inner separator WRT regexes
 */
const emailGroupsPattern = /^("[^"]+"\s*<[^<>]+>|[\w\-.]+(\+\w+)?@[\w-]+(?:\.[\w-]+){1,3})(?:\s*[;,]\s*)$/;

/**
 * Identifies either empty/whitespace contact or an end ',' in then email string.
 */
const emptyEmailInGroups = /(^\s*[;,]|\s*[;,]\s*[;,]\s*|\s*[,]\s*$)|(^\s*$)/;

/**
 * Identifies a single email (that is either a plain email or a display name email)
 */
const singleEmail = /^\s*("[^"]+"\s*<\s*[\w\-.]+(\+\w+)?@[\w-]+(?:\.[\w-]+){1,3}\s*>|[\w\-.]+(\+\w+)?@[\w-]+(?:\.[\w-]+){1,3})\s*$/;

/**
 * Used to split the email strings over (one the display name parts have been parsed). The regex checks for a character that is either a `;` or a `,`.
 **/ 
const emailSeparator = /[;,]/;

/**
 * Validates the provided email string, returning false if any of the emails are malformed.
 * 
 * Set `allowDisplayName` to `false` to fail validation if a display name email is present in the string.
 *
 * @param {string} email A string representing one or more `;|,` separated emails
 */
function validateMultiple (email, { allowDisplayName = true } = {}) {
  if (!email) {
    return false;
  }

  if (emptyEmailInGroups.test(email)) {
    return false;
  }

  if (allowDisplayName) {
    return validateMultipleWithDisplayName(email);
  }

  return validateMultipleWithoutDisplayName(email);
}

/**
 * Validates the provided email string, returning false if any of the emails are malformed.
 * 
 * @param {string} email A string representing one or more `;|,` separated emails 
 */
function validateMultipleWithDisplayName (email) {
  // split the email string into groups of display name emails & plain emails
  return withEndingSeparator(email)
    .split(emailGroupsPattern)
    .map((str) => str && str.trim())
    .filter((str) => str)
    .every((emailBlock) => {
      // if the email is a valid single plain email, not further processing required
      if (plainEmailPattern.test(emailBlock)) {
        return true;
      }

      // otherwise it might be a display name email - get the emails out of the display name email.
      return emailBlock.split(emailDisplayEmails)
        .map((str) => str && str.trim())
        .filter((str) => str)
        .every((emails) => {
          // validate each email, returning true if all are valid plain emails
          return emails.split(emailSeparator)
            .map((str) => str && str.trim())
            .filter((str) => str)
            .every((email) => plainEmailPattern.test(email));
        });
    });
}

/**
 * Validates a string of multiple `;|,` emails, failing if the string contains an invalid email or an display name email.
 * 
 * @param {string} email A string representing one or more `;|,` separated emails
 */
function validateMultipleWithoutDisplayName (email) {
  return withEndingSeparator(email)
    .split(emailSeparator)
    .map((str) => str && str.trim())
    .filter((str) => str)
    .every((email) => plainEmailPattern.test(email));
}

/**
 * Validates the provided email string, returning false if the total email addresses number is over the maxEmailsAllowed defined in this file
 * Note: this function should be called after the email passed validateMultiple to get an accurate count result
 * @param {string} email A string representing one or more `;|,` separated emails
 * @param {string} options.maxEmailsAllowed limit the max numeber of email addresses allowed in per email request
 */
function validateMaxEmailsNumber (email, { maxEmailsAllowed = MAX_EMAILS_ALLOWED_DEFAULT } = {}) {
  if (!email) {
    return false;
  }

  let totalEmailAddresses = 0;
  // split the email string into groups of display name emails & plain emails
  withEndingSeparator(email)
    .split(emailGroupsPattern)
    .map((str) => str && str.trim())
    .filter((str) => str)
    .forEach((emailBlock) => {
      // Sum up the number of each email blocks
      emailBlock.split(emailDisplayEmails)
        .map((str) => str && str.trim())
        .filter((str) => str)
        .forEach((emails) => {
          const totalEmailsOfBlock = emails.split(emailSeparator)
            .map((str) => str && str.trim())
            .filter((str) => str)
          totalEmailAddresses += totalEmailsOfBlock.length;
        });
    });

    return totalEmailAddresses <= maxEmailsAllowed;
}

/**
 * Validates a single plain or display name email.
 * 
 * @param {string} email a string reprenting a single email
 */
function validateSingle (email, { allowDisplayName = true } = {}) {
  return allowDisplayName ? singleEmail.test(email) : plainEmailPattern.test(email);
}

/**
 * Adds a `;` if the supplied string does not end with a `;`
 * 
 * @param {string} email  A string representing one or more `;|,` separated emails
 */
function withEndingSeparator (email) {
  return !email.match(/;$/) ? `${email};` : email;
}

module.exports = {
  validateMultiple,
  validateMaxEmailsNumber,
  validateSingle,
  MAX_EMAILS_ALLOWED_DEFAULT
};
