import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

export const bankAccountName = ({ bankAccount, t }) => {
  if (!bankAccount) {
    return null;
  }

  if (bankAccount && bankAccount.accountType === bankAccountTypeEnum.OPERATING) {
    return t('operating');
  }

  return getBankAccountName(bankAccount, t);
};
