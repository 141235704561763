'use strict';

/**
 * isFirmCardSavingEnabledForBankAccount
 *
 * @returns {boolean}
 */
const isFirmCardSavingEnabledForBankAccount = () => false;

module.exports = {
  isFirmCardSavingEnabledForBankAccount,
};
