import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import moment from 'moment';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { usePagination, useSubscribedQuery } from 'web/hooks';
import {
  BillingContactsPaymentPlansRouteData,
  BillingContactsPaymentPlansRouteAdditionalData,
  ContactListMeta,
} from 'web/graphql/queries';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { formatPaymentFrequency } from '@sb-billing/business-logic/payment-plan/services';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { BillingContactsPaymentPlansRoute } from './BillingContactsPaymentPlansRoute';

const FETCH_LIMIT = 50;

const buildDebtorWithPaymentPlanItem = ({ contact, paymentPlan, t }) => {
  const { lastPaymentDate, totalAmount, unpaidAmount, installmentFrequency, installmentAmount } = paymentPlan;
  const { type, representativeOfsDisplayName, cell, email, phone, displayName, id } = contact;
  const paid = totalAmount - unpaidAmount;
  const paymentMethod = paymentPlan.paymentPlanPosition?.paymentMethod || {};
  const status = paymentPlan.paymentPlanPosition?.status || '';
  const planDetails = `${t('cents', { val: installmentAmount })} ${formatPaymentFrequency(installmentFrequency)}`;

  // Used so we can sort the payment methods naturally using react virtualized
  const paymentMethodSortKey = paymentMethod.type + paymentMethod.status;

  return {
    id,
    status,
    outstanding: unpaidAmount,
    lastPaymentOnYYYYMMDD: lastPaymentDate,
    paid,
    type,
    organization: representativeOfsDisplayName,
    cell,
    email,
    phone,
    displayName,
    planDetails,
    paymentMethod,
    paymentMethodSortKey,
  };
};

const SCOPE = 'BillingContactsPaymentPlansRoute';
const contactStatusFilterScope = `${SCOPE}/contactPaymentPlansStatusFilter`;
const paymentPlanTableScope = `${SCOPE}/debtors-with-payment-plan-table`;

const hooks = ({ onClickLink }) => ({
  useScopes: () => ({
    contactListFilterScope: `${SCOPE}/contactPaymentPlansFilterPanel`,
  }),
  useOnLoad: () => {
    const { selectedItems: contactStatusSelections, selectItems: onContactStatusSelect } = useMultipleItemSelection({
      scope: contactStatusFilterScope,
    });

    return {
      contactStatusSelections,
      onContactStatusSelect,
      onLoad: () => {
        if (Object.values(contactStatusSelections).length === 0) {
          onContactStatusSelect(['current']);
        }
      },
    };
  },
  useListMeta: () => {
    const metaQueryResult = useSubscribedQuery(
      ContactListMeta,
      {
        variables: {
          hasPaymentPlan: true,
          paymentPlanFilter: {
            status: paymentPlanStatuses.ACTIVE,
          },
        },
      },
      {
        notificationIds: [
          ...ContactListMeta.notificationIds,
          'WebQueryDebtorsNotifications.PaymentPlanUpdated',
          'DebtorsNotifications.PaymentPlanEventUpdated', // Not available as a WebQuery notification
        ],
      },
    );

    if (metaQueryResult.error) {
      throw new Error(metaQueryResult.error);
    }

    const queryDataStatusCounts = metaQueryResult?.data?.contactListMeta?.statusCounts;
    const { current, deleted } = queryDataStatusCounts || {};

    return {
      contactStatusCounts: {
        current,
        deleted,
      },
      contactStatusCountsLoading: !queryDataStatusCounts && metaQueryResult.loading,
    };
  },
  useContactsPaymentPlanListData: () => {
    const {
      currentPage: currentPaymentPlanPage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${SCOPE}-pagination`,
      fetchLimit: FETCH_LIMIT,
    });

    const { t } = useTranslation();

    const onPaymentPlanListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const { selectedItems: contactStatusSelections } = useMultipleItemSelection({
      scope: contactStatusFilterScope,
    });

    const paymentPlanSort = useSort({
      scope: paymentPlanTableScope,
      initialSortBy: 'displayName',
      initialSortDirection: 'asc',
    });

    const onPaymentPlanSort = (sortProps) => {
      paymentPlanSort.setSortBy(sortProps.sortBy);
      paymentPlanSort.setSortDirection(sortProps.sortDirection);
    };

    const paymentPlansQueryResult = useSubscribedQuery(BillingContactsPaymentPlansRouteData, {
      // initial/empty state for contactStatusSelections is an empty object, in which case we skip the data fetch
      skip: Object.keys(contactStatusSelections).length === 0,
      variables: {
        contactFilter: {
          includeStatus: contactStatusSelections,
        },
        paymentPlanFilter: {
          status: paymentPlanStatuses.ACTIVE,
        },
        paymentPlanStatusDate: moment().format('YYYYMMDD'),
        offset: currentPaymentPlanPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !paymentPlanSort.setSortBy
          ? undefined
          : {
              fieldNames: [paymentPlanSort.sortBy],
              directions: [`${paymentPlanSort.sortDirection || 'ASC'}`.toUpperCase()],
            },
      },
    });

    const additionalDataQueryResult = useSubscribedQuery(BillingContactsPaymentPlansRouteAdditionalData, {
      // initial/empty state for contactStatusSelections is an empty object, in which case we skip the data fetch
      skip: Object.keys(contactStatusSelections).length === 0,
      context: { skipRequestBatching: true },
    });

    if (paymentPlansQueryResult.error || additionalDataQueryResult.error) {
      throw new Error(paymentPlansQueryResult.error || additionalDataQueryResult.error);
    }

    const { data: paymentPlansData } = paymentPlansQueryResult;
    const contactPaymentPlanList = paymentPlansData?.contactPaymentPlanList?.results || [];
    const {
      totalCount: paymentPlanContactCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfPaymentPlanPages,
    } = getPagination({
      totalCount: paymentPlansData?.contactPaymentPlanList?.totalCount || 0,
      loading: paymentPlansQueryResult.loading,
    });
    const { data: additionalData } = additionalDataQueryResult;
    const unpaidMatters = additionalData?.unpaidMatters || [];

    const debtorsWithPaymentPlansHash = contactPaymentPlanList.reduce((acc, { paymentPlan, contact }) => {
      if (!contact || !contact.id) {
        return acc;
      }
      if (!acc[contact.id]) {
        acc[contact.id] = buildDebtorWithPaymentPlanItem({ paymentPlan, contact, t });
      }
      return acc;
    }, {});

    const debtorsWithPaymentPlans = Object.values(debtorsWithPaymentPlansHash);

    const disableCreatePaymentPlan = !unpaidMatters.some((unpaidMatter) =>
      unpaidMatter.debtorIds.some((debtorId) => !debtorsWithPaymentPlansHash[debtorId]),
    );

    return {
      hidePagination,
      disableCreatePaymentPlan,
      debtorsWithPaymentPlans,
      paymentPlanDataLoading: contactPaymentPlanList.length === 0 && paymentPlansQueryResult.loading,
      paymentPlanContactCount,
      totalNumberOfPaymentPlanPages,
      currentPaymentPlanPage,
      onPaymentPlanListPageChange,
      paymentPlanSortBy: paymentPlanSort.sortBy,
      paymentPlanSortDirection: paymentPlanSort.sortDirection,
      onPaymentPlanSort,
    };
  },
  useOnSelect: () => ({
    onSelect: ({ id }) => onClickLink({ type: 'contact', id }),
  }),
});

export const BillingContactsPaymentPlansRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(withOnLoad(BillingContactsPaymentPlansRoute))),
);

BillingContactsPaymentPlansRouteContainer.displayName = 'BillingContactsPaymentPlansRouteContainer';

BillingContactsPaymentPlansRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};

BillingContactsPaymentPlansRouteContainer.defaultProps = {};
