import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { selectCategory } from 'web/redux/route/home-billing-contacts';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { withOnLoad } from '@sb-itops/react';
import { ContactTableCategory } from 'web/containers';
import { useSubscribedQuery, usePagination } from 'web/hooks';
import { BillingContactsRouteData, ContactListMeta } from 'web/graphql/queries';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { BillingContactsRoute } from './BillingContactsRoute';

const FETCH_LIMIT = 50;

const SCOPE = 'BillingContactsRoute';
const contactStatusFilterScope = `${SCOPE}/contactStatusFilter`;
const contactTableScope = `${SCOPE}/contact-table`;

const hooks = ({ category, selectedCategory, onClickLink }) => ({
  useScopes: () => ({
    contactListFilterScope: `${SCOPE}/contactFilterPanel`,
  }),
  useOnLoad: () => {
    const { selectedItems: contactStatusSelections, selectItems: onContactStatusSelect } = useMultipleItemSelection({
      scope: contactStatusFilterScope,
    });

    return {
      contactStatusSelections,
      onContactStatusSelect,
      onLoad: () => {
        if (Object.values(contactStatusSelections).length === 0) {
          onContactStatusSelect(['current']);
        }

        if (category) {
          selectCategory(category);
        }
      },
    };
  },
  useListMeta: () => {
    const categories = selectedCategory?.values.includes('Clients') ? ['CLIENT'] : [];

    const metaQueryResult = useSubscribedQuery(ContactListMeta, {
      variables: {
        contactFilter: {
          excludeTypes: ['GroupOfPeople'],
          includeRoles: categories,
        },
      },
    });

    if (metaQueryResult.error) {
      throw new Error(metaQueryResult.error);
    }

    const queryDataStatusCounts = metaQueryResult?.data?.contactListMeta?.statusCounts;
    const { current, deleted } = queryDataStatusCounts || {};

    return {
      contactStatusCounts: {
        current,
        deleted,
      },
      contactStatusCountsLoading: !queryDataStatusCounts && metaQueryResult.loading,
    };
  },
  useContactListData: () => {
    const {
      currentPage: currentContactPage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${SCOPE}-pagination-${selectedCategory.key}`,
      fetchLimit: FETCH_LIMIT,
    });

    const onContactListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: contactTableScope,
      initialSortBy: 'displayName',
      initialSortDirection: 'asc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    const { selectedItems: contactStatusSelections } = useMultipleItemSelection({
      scope: contactStatusFilterScope,
    });

    const categories = selectedCategory?.values.includes('Clients') ? ['CLIENT'] : [];

    const contactsQueryResult = useSubscribedQuery(BillingContactsRouteData, {
      // initial/empty state is an empty object equalling to no filters selected, in which case we can skip the data fetch
      skip: Object.keys(contactStatusSelections).length === 0,
      variables: {
        contactFilter: {
          excludeTypes: ['GroupOfPeople'],
          includeStatus: contactStatusSelections,
          includeRoles: categories,
        },
        offset: currentContactPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (contactsQueryResult.error) {
      throw new Error(contactsQueryResult.error);
    }

    const { data: contactsData } = contactsQueryResult;
    const contactList = contactsData?.contactList?.results || [];
    const {
      totalCount: contactCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfContactPages,
    } = getPagination({ totalCount: contactsData?.contactList?.totalCount, loading: contactsQueryResult.loading });

    return {
      contacts: contactList,
      contactCount,
      contactDataLoading: contactList.length === 0 && contactsQueryResult.loading,
      hidePagination,
      totalNumberOfContactPages,
      currentContactPage,
      onContactListPageChange,
      onSort,
      sortBy,
      sortDirection,
    };
  },
  useOnSelect: () => ({
    onSelect: ({ id }) => onClickLink({ type: 'contact', id }),
  }),
  useModal: () => ({
    showCreateModal: () => setModalDialogVisible({ modalId: CONTACT_CREATE_EDIT_MODAL_ID }),
  }),
});

export const BillingContactsRouteContainer = withApolloClient(
  withReduxProvider(ContactTableCategory(composeHooks(hooks)(withOnLoad(BillingContactsRoute)))),
);

BillingContactsRouteContainer.displayName = 'BillingContactsRouteContainer';

BillingContactsRouteContainer.propTypes = {
  category: PropTypes.string,
  selectedCategory: PropTypes.object.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

BillingContactsRouteContainer.defaultProps = {
  category: '',
  selectedCategory: {},
};
