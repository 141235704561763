import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';
// eslint-disable-next-line import/no-cycle
import { getById } from './selectors';

const api = cacheFactory({
  domain,
  name: 'payment-provider-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbPaymentProviderSettingsService',
  syncType: syncTypes.ALL,
});

const updateCache = api.updateCache;
const getMap = api.getMap;

export { getMap, updateCache, getById };
