import _ from 'lodash';
import {
  TOGGLE_SHOW_FILTERS,
  SET_FILTER,
  TOGGLE_HIDE_FILTER,
  RESET_FILTER,
  SET_EXPANDED_CLIENTS,
  SET_SELECTED_MATTERS,
  billingTypeFilterOptions,
} from './types';

export const setSelectedMatters = (selectedMatters) => ({
  type: SET_SELECTED_MATTERS,
  payload: selectedMatters,
});

export const setExpandedClients = (expandedClients) => ({
  type: SET_EXPANDED_CLIENTS,
  payload: expandedClients,
});

export const setFilter = (filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});

export const setAllMatters = (bool) => ({
  type: SET_FILTER,
  payload: { filterName: 'allMatters', filterValue: bool },
});

export const setGroupByClient = (bool) => ({
  type: SET_FILTER,
  payload: { filterName: 'groupByClient', filterValue: bool },
});
export const setHighlightedMatter = (bool) => ({
  type: SET_FILTER,
  payload: { filterName: 'highlightedMatter', filterValue: bool },
});

export const onTrustRetainerClearClick = () => ({
  type: SET_FILTER,
  payload: { filterName: 'minTrustRetainerSelected', filterValue: null },
});

export const onTrustRetainerInputValueChanged = (value) => ({
  type: SET_FILTER,
  payload: { filterName: 'minTrustRetainerSelected', filterValue: value },
});

export const onOperatingRetainerClearClick = () => ({
  type: SET_FILTER,
  payload: { filterName: 'minOperatingRetainerSelected', filterValue: null },
});

export const onOperatingRetainerInputValueChanged = (value) => ({
  type: SET_FILTER,
  payload: { filterName: 'minOperatingRetainerSelected', filterValue: value },
});

export const onDisplayedMattersChanged = (matters) => ({
  type: SET_FILTER,
  payload: { filterName: 'matterTypesDisplayed', filterValue: _.unique(matters.map((matter) => matter.matterTypeId)) },
});

export const onBillingTypeFilterChanged = (selected) => ({
  type: SET_FILTER,
  payload: {
    filterName: 'billingTypesSelected',
    filterValue: selected && billingTypeFilterOptions.length === selected.length ? undefined : selected,
  },
});

export const onEntryTypeFilterChanged = (selected) => ({
  type: SET_FILTER,
  payload: {
    filterName: 'entryTypesSelected',
    filterValue: selected,
  },
});

export const onBillingFrequencyFilterChanged = (selectedBillingFrequencySubTypes) => ({
  type: SET_FILTER,
  payload: {
    filterName: 'billingFrequencySubTypesSelected',
    filterValue: selectedBillingFrequencySubTypes || [],
  },
});

export const onSelectMatterStatusFilter = (selected) => ({
  type: SET_FILTER,
  payload: { filterName: 'matterFilterStatusSelected', filterValue: selected },
});

export const resetFilters = () => ({
  type: RESET_FILTER,
  payload: {},
});

export const toggleHideFilters = (event) => ({
  type: TOGGLE_HIDE_FILTER,
  payload: { filterName: event.currentTarget.id },
});

export const setAttorneysFilter = (ids) => ({
  type: SET_FILTER,
  payload: { filterName: 'selectedAttorneys', filterValue: ids },
});

export const onChangeMinUnbilled = (minUnbilled) => ({
  type: SET_FILTER,
  payload: { filterName: 'minValue', filterValue: minUnbilled },
});

function getSelectedMatterIds(selectedMatters) {
  return Object.entries(selectedMatters).reduce((acc, [matterId, selected]) => {
    if (selected) {
      acc.push(matterId);
    }
    return acc;
  }, []);
}

export const onSelectMatters = (matters) => ({
  type: SET_FILTER,
  payload: {
    filterName: 'mattersData',
    filterValue: {
      count: getSelectedMatterIds(matters).length,
      selected: matters,
    },
  },
});

export const onMatterTypeFilterChanged = (selectedMatterTypes) => ({
  type: SET_FILTER,
  payload: {
    filterName: 'matterTypes',
    filterValue: selectedMatterTypes || [],
  },
});

export const onChangeIssueDateFilter = (issueDateFilter) => ({
  type: SET_FILTER,
  payload: { filterName: 'dateListFilter', filterValue: issueDateFilter },
});
