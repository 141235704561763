'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-contact/bills';

  $stateProvider

  .state('home.billing.view-contact.bills', {
    url: '/bills',
    templateUrl: BASE_DIRECTORY + '/view-contact-bills.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'contacts', 'view-contact'],
      tab: { type: 'contact' }
    },
    controller: 'ViewContactBillsController',
    controllerAs: '$ctrl',
    reloadOnSearch: false,
  });

});
