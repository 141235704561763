import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { sendStatuses } from '@sb-billing/business-logic/correspondence-history';
import { MultiContactCorrespondenceDetails } from '../multi-contact-correspondence-details';
import Styles from './InvoiceCorrespondenceSendStatus.module.scss';

const sendClassesByStatus = {
  [sendStatuses.IN_PROGRESS]: classnames('icon-sync-2', Styles.blueText),
  [sendStatuses.SUCCESS]: classnames('icon-check-circle-2', Styles.greenText),
  [sendStatuses.ERROR]: classnames('icon-alert-2', Styles.redText),
};

export const InvoiceCorrespondenceSendStatus = ({ invoiceId, sendStatus, allowResend, onResendClicked }) => {
  if (sendStatus === sendStatuses.NOT_SENT) {
    return null;
  }

  const messageBody = () => (
    <MultiContactCorrespondenceDetails
      invoiceId={invoiceId}
      allowResend={allowResend}
      onResendClicked={onResendClicked}
    />
  );

  return (
    <div className={Styles.invoiceCorrespondenceSendStatus}>
      <div className={Styles.iconContainer}>
        <ContextMenu body={messageBody}>
          <i className={sendClassesByStatus[sendStatus]} />
        </ContextMenu>
      </div>
    </div>
  );
};

InvoiceCorrespondenceSendStatus.displayName = 'InvoiceCorrespondenceSendStatus';

InvoiceCorrespondenceSendStatus.propTypes = {
  invoiceId: PropTypes.string,
  sendStatus: PropTypes.number.isRequired,
  allowResend: PropTypes.bool,
  onResendClicked: () => {},
};

InvoiceCorrespondenceSendStatus.defaultProps = {
  invoiceId: undefined,
  onResendClicked: () => {},
  allowResend: false,
};

export default InvoiceCorrespondenceSendStatus;
