angular.module("sb.billing.webapp").config(function ($stateProvider) {
  "use strict";

  const BASE_DIRECTORY = "ng-routes/home/billing/view-matter/archie";

  $stateProvider.state("home.billing.view-matter.archie", {
    url: "/archie",
    templateUrl: `${BASE_DIRECTORY}/view-matter-archie.html`,
    controller: "sbViewMatterArchieController",
    controllerAs: "$ctrl",
    reloadOnSearch: false,
    data: {
      authorized: true,
      navHighlights: ["billing", "matters", "view-matter", "archie"],
      tab: { type: "matter" },
    },
  });
});
