import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { forms2PropTypes, SlidingToggle, Button } from '@sb-itops/react';

import { isNewTheme } from 'web/services/theme';
import { t } from '@sb-itops/localisation-web';
import Styles from './FeeWiseApplicationForm.module.scss';
import { FirmDetails } from './sections/FirmDetails';
import { OperatingAccountDetails } from './sections/OperatingAccountDetails';
import { TrustAccountDetails } from './sections/TrustAccountDetails';
import { AuthorizedRepresentative } from './sections/AuthorizedRepresentative';
import { BeneficialOwner } from './sections/BeneficialOwner';
import { AcknowledgementCheckbox } from './sections/AcknowledgementCheckbox';
import { TestingAndDebug } from './sections/TestingAndDebug';

import feewiseLogo from '../../../assets/integration-feewise.svg';

export const FeeWiseApplicationForm = ({
  // Form sections
  firmDetails,
  operatingAccountDetails,
  trustAccountDetails,
  authorizedRepresentative,
  beneficialOwner,
  acknowledgementCheckbox,
  testAndDebug,
  // Refs
  firmDetailsSectionRef,
  operatingAccountDetailsSectionRef,
  trustAccountDetailsSectionRef,
  authorizedRepresentativeSectionRef,
  beneficialOwnerSectionRef,
  acknowledgementCheckboxSectionRef,
  // Others
  today,
  formInitialised,
  beneficialOwnerDifferent,
  setBeneficialOwnerDifferent,
  setConfirmationDateTime,
  businessStructureOptions,
  accountTypeOptions,
  localisationConfig,
  isSubmitting,
  isSaving,
  isNonProdAppEnv,
  autofillFormData,
  onUpdateBusinessOwnership,
  onToggleField,
  onValidateForm,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  onSubmit,
  onSave,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <div className={Styles.feeWiseApplicationForm}>
      {isNonProdAppEnv && (
        <TestingAndDebug
          autofillFormData={autofillFormData}
          testAndDebug={testAndDebug}
          onFieldUpdated={onFieldUpdated}
        />
      )}
      <FirmDetails
        firmDetails={firmDetails}
        formInitialised={formInitialised}
        businessStructureOptions={businessStructureOptions}
        submitFailed={submitFailed}
        formDisabled={formDisabled || isSubmitting}
        onFieldUpdated={onFieldUpdated}
        sectionRef={firmDetailsSectionRef}
        localisationConfig={localisationConfig}
      />
      <OperatingAccountDetails
        operatingAccountDetails={operatingAccountDetails}
        formInitialised={formInitialised}
        accountTypeOptions={accountTypeOptions}
        submitFailed={submitFailed}
        formDisabled={formDisabled || isSubmitting}
        onFieldUpdated={onFieldUpdated}
        onToggleField={onToggleField}
        sectionRef={operatingAccountDetailsSectionRef}
      />
      <TrustAccountDetails
        trustAccountDetails={trustAccountDetails}
        formInitialised={formInitialised}
        accountTypeOptions={accountTypeOptions}
        submitFailed={submitFailed}
        formDisabled={formDisabled || isSubmitting}
        onFieldUpdated={onFieldUpdated}
        onToggleField={onToggleField}
        sectionRef={trustAccountDetailsSectionRef}
      />
      <AuthorizedRepresentative
        authorizedRepresentative={authorizedRepresentative}
        formInitialised={formInitialised}
        submitFailed={submitFailed}
        formDisabled={formDisabled || isSubmitting}
        onFieldUpdated={onFieldUpdated}
        onUpdateBusinessOwnership={onUpdateBusinessOwnership}
        setBeneficialOwnerDifferent={setBeneficialOwnerDifferent}
        sectionRef={authorizedRepresentativeSectionRef}
        onValidateForm={onValidateForm}
        today={today}
      />

      <div className="form-group">
        <div className={Styles.toggleContainer}>
          <SlidingToggle
            id="beneficialOwnerDifferent"
            scope="beneficialOwnerDifferent"
            name="beneficialOwnerDifferent"
            onChange={() => setBeneficialOwnerDifferent(!beneficialOwnerDifferent)}
            selected={beneficialOwnerDifferent}
            disabled={formDisabled || isSubmitting}
          />
          <span
            className={Styles.labelText}
            htmlFor="beneficialOwnerDifferent"
            onClick={() => setBeneficialOwnerDifferent(!beneficialOwnerDifferent)}
          >
            Majority Owner is different from {t('capitalize', { val: 'authorised' })} Representative
          </span>
          <i
            title="A majority owner must own or control at least 25% of the firms equity"
            className={classnames('icon icon-question-mark', Styles.labelText, Styles.questionIcon)}
          />
        </div>
      </div>

      {beneficialOwnerDifferent && (
        <BeneficialOwner
          beneficialOwner={beneficialOwner}
          formInitialised={formInitialised}
          submitFailed={submitFailed}
          formDisabled={formDisabled || isSubmitting}
          onFieldUpdated={onFieldUpdated}
          onUpdateBusinessOwnership={onUpdateBusinessOwnership}
          sectionRef={beneficialOwnerSectionRef}
          today={today}
        />
      )}
      <AcknowledgementCheckbox
        acknowledgementCheckbox={acknowledgementCheckbox}
        formInitialised={formInitialised}
        submitFailed={submitFailed}
        formDisabled={formDisabled || isSubmitting}
        onFieldUpdated={onFieldUpdated}
        onValidateForm={onValidateForm}
        setConfirmationDateTime={setConfirmationDateTime}
        sectionRef={acknowledgementCheckboxSectionRef}
      />
      <div className={Styles.toggleContainer}>
        <Button
          size="full-width"
          className={Styles.submitButtons}
          onClick={async () => {
            await onSubmit();
          }}
          locked={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          type={isNewTheme() ? 'tertiary' : 'info'}
          size="full-width"
          className={Styles.submitButtons}
          onClick={onSave}
          locked={isSaving}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </div>

      <div className={Styles.logoContainer}>
        <span className={Styles.logoText}>In partnership with</span>
        <img alt="Feewise logo" src={feewiseLogo} className={Styles.logoImg} />
      </div>
    </div>
  );
};

FeeWiseApplicationForm.displayName = 'FeeWiseApplicationForm';

const { Forms2Field } = forms2PropTypes;

FeeWiseApplicationForm.propTypes = {
  firmDetails: PropTypes.shape({
    legalCompanyName: Forms2Field,
    tradingAs: Forms2Field,
    businessStructure: Forms2Field,
    employerIdNumber: Forms2Field,
    phoneNumber: Forms2Field,
    emailAddress: Forms2Field,
    website: Forms2Field,
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
  }),
  operatingAccountDetails: PropTypes.shape({
    accountHolderName: Forms2Field,
    accountName: Forms2Field,
    routingNumber: Forms2Field,
    accountNumber: Forms2Field,
    accountType: Forms2Field,
    nominateForMonthlyBilling: Forms2Field,
  }),
  trustAccountDetails: PropTypes.shape({
    accountHolderName: Forms2Field,
    accountName: Forms2Field,
    routingNumber: Forms2Field,
    accountNumber: Forms2Field,
    accountType: Forms2Field,
  }),
  authorizedRepresentative: PropTypes.shape({
    firstName: Forms2Field,
    middleName: Forms2Field,
    lastName: Forms2Field,
    maidenName: Forms2Field,
    jobTitle: Forms2Field,
    phoneNumber: Forms2Field,
    emailAddress: Forms2Field,
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
    dateOfBirth: Forms2Field,
    last4SocialSecurity: Forms2Field,
    businessOwnership: Forms2Field,
  }),
  beneficialOwner: PropTypes.shape({
    firstName: Forms2Field,
    middleName: Forms2Field,
    lastName: Forms2Field,
    maidenName: Forms2Field,
    jobTitle: Forms2Field,
    phoneNumber: Forms2Field,
    emailAddress: Forms2Field,
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
    dateOfBirth: Forms2Field,
    last4SocialSecurity: Forms2Field,
    businessOwnership: Forms2Field,
  }),
  acknowledgementCheckbox: PropTypes.shape({
    applicantsName: Forms2Field,
    jobTitle: Forms2Field,
    confirmation: Forms2Field,
  }),
  testAndDebug: PropTypes.shape({
    createFirmOwnerAccount: Forms2Field,
  }),
  firmDetailsSectionRef: PropTypes.object.isRequired,
  operatingAccountDetailsSectionRef: PropTypes.object.isRequired,
  trustAccountDetailsSectionRef: PropTypes.object.isRequired,
  authorizedRepresentativeSectionRef: PropTypes.object.isRequired,
  beneficialOwnerSectionRef: PropTypes.object.isRequired,
  acknowledgementCheckboxSectionRef: PropTypes.object.isRequired,
  businessStructureOptions: PropTypes.array.isRequired,
  accountTypeOptions: PropTypes.array.isRequired,
  today: PropTypes.object.isRequired,
  beneficialOwnerDifferent: PropTypes.bool.isRequired,
  setBeneficialOwnerDifferent: PropTypes.func.isRequired,
  setConfirmationDateTime: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isSaving: PropTypes.bool,
  isNonProdAppEnv: PropTypes.bool,
  autofillFormData: PropTypes.func,
  localisationConfig: PropTypes.shape({
    showBusinessStructure: PropTypes.bool.isRequired,
    populateACN: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleField: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onFieldUpdated: PropTypes.func.isRequired,
  onUpdateBusinessOwnership: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
};

FeeWiseApplicationForm.defaultProps = {
  formDisabled: false,
  isSubmitting: false,
  isSaving: false,
  firmDetails: {},
  operatingAccountDetails: {},
  trustAccountDetails: {},
  authorizedRepresentative: {},
  beneficialOwner: {},
  acknowledgementCheckbox: {},
  testAndDebug: {},
  isNonProdAppEnv: false,
  autofillFormData: () => {},
};
