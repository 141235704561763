'use strict';

/**
 * Determines if feeVersion can be modified
 *
 * A fee version can be modified if it's not "finalized" yet, if it's finalised, it could be
 * 1. on a finalized invoice, i.e. has an associated "invoiceId", or
 * 2. be an externally invoiced fee with null "invoiceId", but finalized = true
 * @param {object} feeVersion
 * @returns {boolean}
 */
const feeCanBeModified = (feeVersion) => !feeVersion.finalized;

module.exports = {
  feeCanBeModified,
};
