/* eslint-disable import/no-cycle */
import { featureActive } from '@sb-itops/feature';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { optimisticUpdate } from 'web/services/apollo/optimistic-update';
import { getList } from './index';

const { opdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbUserBillingAttributesService',
  keyPath: 'userId',
});

export const saveUserBillingAttributes = ({ viewedMessage, userId }) => {
  const saveUserBillingAttributesThunk = async () => {
    let opdateResult;

    if (featureActive('BB-5578')) {
      opdateResult = optimisticUpdate({
        identifier: {
          __typename: 'UserBillingAttributes',
          id: userId,
        },
        fieldOpdates: {
          viewedMessages: (existingViewedMessages = []) => [...existingViewedMessages, viewedMessage],
        },
        rootQueryField: 'userBillingAttributes',
      });
    } else {
      const existingUserBillingAttributes = getList()?.[0] || {};
      const existingViewedMessages = existingUserBillingAttributes?.viewedMessages || [];

      const newUserBillingAttributes = {
        ...existingUserBillingAttributes,
        viewedMessages: [...existingViewedMessages, viewedMessage],
      };

      // Apply to save optimistically.
      opdateCache({ optimisticEntities: [newUserBillingAttributes] });
    }

    // Apply the save in the backend.
    try {
      const userBillingAttributesMessage = {
        viewedMessage,
      };

      const path = `/billing/user-billing-attributes/:accountId/`;
      const fetchOptions = { body: JSON.stringify(userBillingAttributesMessage) };
      await fetchPostP({ path, fetchOptions });

      if (opdateResult) {
        opdateResult.opdateServerSuccess();
      }
    } catch (err) {
      if (opdateResult) {
        opdateResult.rollbackOpdate();
      }

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveUserBillingAttributesThunk);
};
