angular.module('sb.billing.webapp').controller('SbActivityModalController', function ($scope, $uibModalInstance, preFill) {
  'use strict';

  $scope.preFill = preFill;

  $scope.onClose = function (success) {
    if (success) {
      $uibModalInstance.close();
    }
  };
});
