import {
  updateCache as updateRedux,
  getById,
  getTemplateBankAccount,
}  from '@sb-billing/redux/bank-account-balances';

angular.module('@sb-billing/services').service('sbBankAccountBalancesService', function (sbGenericCacheService, sbLoggerService, sbEndpointType) {
  const that = this;
  const BANK_ACCOUNT_BALANCE_ENDPOINT = 'billing/bank-account-balances';
  const log = sbLoggerService.getLogger('sbBankAccountBalancesService');
  sbGenericCacheService.setupCache({
    name: 'sbBankAccountBalancesService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: BANK_ACCOUNT_BALANCE_ENDPOINT},
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.AccountBalanceUpdated'
    },
    updateRedux
  });

  // log.setLogLevel('info');

  that.adjustFirmOperatingBalance = adjustFirmOperatingBalance;

  function adjustFirmOperatingBalance(opdates, cents, accountId) {
    

    if (!_.isNumber(cents)) {
      log.error('Invalid parameters, cents: %s', cents);
      throw new Error('Invalid parameters');
    }

    opdates.sbBankAccountBalancesService = opdates.sbBankAccountBalancesService || [];
    
    const bankAccountId = accountId + '/Operating';
    const bankAccountOpdate = opdates.sbBankAccountBalancesService.find((acct) => acct.id === bankAccountId);
    const bankAccount = bankAccountOpdate || _.cloneDeep(getById(bankAccountId)) || getTemplateBankAccount(bankAccountId);
    opdates.sbBankAccountBalancesService.push(bankAccount);

    if (bankAccount.balance + cents < 0) {
      log.error('Balance cannot be less than 0 after adding amount, accountId: %s, cents: %s, balance', bankAccountId, cents, bankAccount);
      throw new Error('Cannot have a negative balance');
    }

    log.info('opening firm operating balance', bankAccount);
    bankAccount.balance += cents;
    log.info('new firm operating balance', bankAccount);
  }

});
