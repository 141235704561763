'use strict';

const { fuzzyTime } = require('../../shared/services');

const { convertMinsToUnits } = fuzzyTime;

/**
 * accumulateSourceItemsDurations
 *
 * Accumulates the billable and non-billable amounts from an AutoTime fee's source items
 *
 * @param {Object} params
 * @param {Array<{ duration: number, billable: boolean }>} params.sourceItems // In mins
 * @param {number} params.interval
 * @returns {{
 *  billableDurationInHours: number,
 *  billableDurationInMins: number,
 *  billableDurationInUnits: number,
 *  nonBillableDurationInHours: number,
 *  nonBillableDurationInMins: number,
 *  nonBillableDurationInUnits: number,
 *  workedDurationInHours: number,
 *  workedDurationInMins: number,
 *  workedDurationInUnits: number,
 * }}
 */
function accumulateSourceItemsDurations(props) {
  const { sourceItems, interval } = props;

  const validSourceItems = Array.isArray(sourceItems);
  const validInterval = Number.isInteger(interval) && interval > 0 && interval <= 60;

  if (!validSourceItems) {
    throw new Error('Please provide a valid sourceItems value');
  }

  if (!validInterval) {
    throw new Error('Please provide a valid interval value');
  }

  if (sourceItems.length === 0) {
    return {
      billableDurationInHours: 0,
      billableDurationInMins: 0,
      billableDurationInUnits: 0,
      nonBillableDurationInHours: 0,
      nonBillableDurationInMins: 0,
      nonBillableDurationInUnits: 0,
      workedDurationInHours: 0,
      workedDurationInMins: 0,
      workedDurationInUnits: 0,
    };
  }

  // Duration in mins
  const { billableDurationInMins, nonBillableDurationInMins, workedDurationInMins } = sourceItems.reduce(
    (acc, item) => {
      const { billable, durationBilled: durationInMins, durationWorked } = item;
      const durationWorkedInMins = durationWorked ?? durationInMins;

      if (billable) {
        acc.billableDurationInMins += durationInMins;
      } else {
        acc.nonBillableDurationInMins += durationInMins;
      }

      acc.workedDurationInMins += durationWorkedInMins;

      return acc;
    },
    {
      billableDurationInMins: 0,
      nonBillableDurationInMins: 0,
      workedDurationInMins: 0,
    },
  );

  // Duration in hours
  const billableDurationInHours = +(billableDurationInMins / 60).toFixed(5);
  const nonBillableDurationInHours = +(nonBillableDurationInMins / 60).toFixed(5);
  const workedDurationInHours = +(workedDurationInMins / 60).toFixed(5);

  // Duration in units
  const billableDurationInUnits = convertMinsToUnits({ mins: billableDurationInMins, interval });
  const nonBillableDurationInUnits = convertMinsToUnits({ mins: nonBillableDurationInMins, interval });
  const workedDurationInUnits = convertMinsToUnits({ mins: workedDurationInMins, interval });

  const durations = {
    billableDurationInHours,
    billableDurationInMins,
    billableDurationInUnits,
    nonBillableDurationInHours,
    nonBillableDurationInMins,
    nonBillableDurationInUnits,
    workedDurationInHours,
    workedDurationInMins,
    workedDurationInUnits,
  };

  return durations;
}

module.exports = {
  accumulateSourceItemsDurations,
};
