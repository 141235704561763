
// We need to stop using redux fetch and use itops fetch wrapper instead once
// it supports auto token refresh and buffering failed 401 requests.
const { fetchPostP } = require('@sb-itops/redux/fetch');
const { broadcastCommandDispatchEvent } = require('./subscriptions');

const dispatchCommand = async ({ type, message }) => {
  const body = JSON.stringify({ type, message });

  const { body: responseBody } = await fetchPostP({
    path: `/integration/web-command-processor/execute-command/:accountId/`,
    fetchOptions: {
      body,
    },
  });

  const { executionResponse } = responseBody;
  broadcastCommandDispatchEvent({ commandType: type, commandMessage: message, commandResult: executionResponse });
  return executionResponse;
};

module.exports = {
  dispatchCommand,
};
