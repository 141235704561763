// Important: do not use the 'lucide-react' package directly, as the bundle
// size will increase dramatically if importing all icons.
//
// In this file we select the subset we use in the application

export {
  AtSign,
  BarChartBig,
  Blocks,
  Book,
  BookOpenCheck,
  CalendarDays,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ClipboardPen,
  Contact,
  CopyIcon,
  Edit,
  File,
  FilePen,
  Files,
  Folder,
  FolderPen,
  FolderPlus,
  HelpCircle,
  Info,
  Landmark,
  LayoutDashboard,
  ListTodo,
  MessagesSquare,
  Phone,
  Plus,
  PlusSquare,
  Search,
  Settings,
  Smartphone,
  SquareUserRound,
  Store,
  Timer,
  TimerOff,
  Trash2,
} from 'lucide-react';

export * from './custom';
