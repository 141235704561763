import { TOGGLE_SHOW_FILTERS } from './types';

export const reducer = (state = { showFilters: true }, action = {}) => {
  switch (action.type) {
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
