import {
  TOGGLE_EXPAND,
  TOGGLE_ITEMS,
  SET_SHOW_PAYMENTS,
  SET_SHOW_RECEIPTS,
  SET_SEARCH_FILTER,
  SET_SHOW_DELETED,
  TOGGLE_SHOW_URECONCILED,
  CLEAR_STATE,
  TOGGLE_SHOW_SUMMARY,
  ADD_ADJUSTMENT,
  SET_ADJUSTMENTS,
  TOGGLE_REMOVE_ADJUSTMENT,
  TOGGLE_RECONCILE_ADJUSTMENT,
} from './types';

export const toggleExpand = ({ id }) => ({
  type: TOGGLE_EXPAND,
  payload: {
    id,
  },
});

export const toggleItems = ({ transactionIds = [], depositSlipIds = [], consolidatedIds = [] }) => ({
  type: TOGGLE_ITEMS,
  payload: {
    transactionIds,
    depositSlipIds,
    consolidatedIds,
  },
});

export const setShowPayments = ({ option }) => ({
  type: SET_SHOW_PAYMENTS,
  payload: {
    option,
  },
});

export const setShowReceipts = ({ option }) => ({
  type: SET_SHOW_RECEIPTS,
  payload: {
    option,
  },
});

export const setShowDeleted = ({ option }) => ({
  type: SET_SHOW_DELETED,
  payload: {
    option,
  },
});

export const setSearchFilter = ({ option }) => ({
  type: SET_SEARCH_FILTER,
  payload: {
    option,
  },
});

export const toggleShowUnreconciled = () => ({
  type: TOGGLE_SHOW_URECONCILED,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});

export const toggleShowSummary = () => ({
  type: TOGGLE_SHOW_SUMMARY,
});

export const addAdjustment = ({ adjustment }) => ({
  type: ADD_ADJUSTMENT,
  payload: {
    adjustment,
  },
});

export const setAdjustments = ({ adjustments }) => ({
  type: SET_ADJUSTMENTS,
  payload: {
    adjustments,
  },
});

export const toggleRemoveAdjustment = ({ adjustmentId }) => ({
  type: TOGGLE_REMOVE_ADJUSTMENT,
  payload: {
    id: adjustmentId,
  },
});

export const toggleReconcileAdjustment = ({ adjustmentId }) => ({
  type: TOGGLE_RECONCILE_ADJUSTMENT,
  payload: {
    id: adjustmentId,
  },
});
