/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ShowSystemDateToggleContact, ShowDeletedTransactionsToggle, DateListFilter } from 'web/react-redux';
import { PanelExpander, useTranslation } from '@sb-itops/react';
import { TrustAccountsForFilterPanels } from '@sb-billing/react/trust-accounts-filter-panel';

const ContactTransactionsFilterPanel = memo(
  ({
    onClickLink,
    accountType,
    showHidden,
    scope,
    selectedTrustAccountId,
    trustAccounts,
    onDateListChange,
    onDeletedTransactionsChange,
    expanded,
    toggleExpanded,
    dateListFilter,
    contactId,
    showOperatingAccount,
    showDeletedToggle,
    trustAccountsFilterPanelConfig,
  }) => {
    const { t } = useTranslation();
    return (
      <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
        <div className="side-menu-block">
          <h3>View</h3>
          <ul className="side-menu-list capitalize">
            <TrustAccountsForFilterPanels
              trustAccounts={trustAccounts}
              contactId={contactId}
              onClickLink={onClickLink}
              selectedTrustAccountId={selectedTrustAccountId}
              selectedAccountType={accountType}
              {...trustAccountsFilterPanelConfig}
            />
            {showOperatingAccount && (
              <li
                onClick={() => onClickLink({ id: 'operating', type: 'contactTransactions', params: [{ contactId }] })}
                className={classnames({ active: accountType === 'OPERATING' })}
              >
                Operating Retainer Account
              </li>
            )}
            <li
              onClick={() => onClickLink({ id: 'ledger', type: 'contactTransactions', params: [{ contactId }] })}
              className={classnames({ active: accountType === 'LEDGER' })}
            >
              Ledger
            </li>
            <li
              onClick={() =>
                onClickLink({ id: 'credit-note-ledger', type: 'contactTransactions', params: [{ contactId }] })
              }
              className={classnames({ active: accountType === 'CREDIT-NOTE-LEDGER' })}
            >
              {t('creditNote')} Ledger
            </li>
          </ul>
          <div>
            <h3>Show</h3>
            {accountType !== 'LEDGER' && showDeletedToggle && (
              <ShowDeletedTransactionsToggle
                onSelect={(id, value) => {
                  onDeletedTransactionsChange(value);
                }}
                label={accountType === 'CREDIT-NOTE-LEDGER' ? 'Deleted Credit Memos' : undefined}
                selected={showHidden}
                className="deleted-transactions-toggle"
              />
            )}
            <ShowSystemDateToggleContact />
          </div>
          {accountType === 'LEDGER' && (
            <div>
              <h3>For Entries Dated</h3>
              <DateListFilter
                onChange={onDateListChange}
                className="side-menu-list"
                scope={scope}
                {...dateListFilter}
              />
            </div>
          )}
        </div>
      </PanelExpander>
    );
  },
);

ContactTransactionsFilterPanel.displayName = 'ContactTransactionsFilterPanel';

ContactTransactionsFilterPanel.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onDateListChange: PropTypes.func.isRequired,
  onDeletedTransactionsChange: PropTypes.func.isRequired,
  showOperatingAccount: PropTypes.bool.isRequired,
  showDeletedToggle: PropTypes.bool.isRequired,
  accountType: PropTypes.string,
  selectedTrustAccountId: PropTypes.string,
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  entity: PropTypes.string,
  showHidden: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  dateListFilter: PropTypes.shape({
    selectedFilter: PropTypes.string,
    before: PropTypes.number, // effective date YYYYMMDD
    from: PropTypes.number, // effective date YYYYMMDD
    to: PropTypes.number, // effective date YYYYMMDD
  }),
  contactId: PropTypes.string.isRequired,
  trustAccountsFilterPanelConfig: PropTypes.shape({
    showProtectedFunds: PropTypes.bool.isRequired,
    overviewOnClicklinkParams: PropTypes.object.isRequired,
    getSpecificOnClickLinkParams: PropTypes.func.isRequired,
  }).isRequired,
};

ContactTransactionsFilterPanel.defaultProps = {
  entity: undefined,
  accountType: undefined,
  showHidden: undefined,
  dateListFilter: {},
  selectedTrustAccountId: undefined,
};

export default ContactTransactionsFilterPanel;
