import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import * as Styles from './MatterDescriptionBar.module.scss';

export const TrustTooltipSummary = ({ totals }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.tooltipContent} onClick={(e) => e.stopPropagation()}>
      <div>
        <div>Protected Funds:</div>
        <div>Available Funds:</div>
        <div>Total Funds:</div>
      </div>
      <div className={Styles.moneyColumn}>
        <div>{t('cents', { val: totals.protected })}</div>
        <div>{t('cents', { val: totals.available })}</div>
        <div>{t('cents', { val: totals.total })}</div>
      </div>
    </div>
  );
};

TrustTooltipSummary.displayName = 'TrustTooltipSummary';

TrustTooltipSummary.propTypes = {
  totals: PropTypes.object.isRequired,
};
