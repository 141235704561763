import { syncTypes } from './reducer-factory';

export const validateConfig = ({ domain, name, keyPath, ngCacheName, syncType, immutable, changesetProcessor }) => {
  const errors = [];

  if (!domain) {
    errors.push(`domain missing`);
  }
  if (!name) {
    errors.push(`name missing`);
  }
  if (!keyPath) {
    errors.push(`keyPath missing`);
  }
  if (!ngCacheName) {
    errors.push(`ngCacheName missing`);
  }
  if (!syncTypes[syncType]) {
    errors.push(`syncType invalid: ${syncType}`);
  }
  if (typeof immutable !== 'boolean') {
    errors.push(`immutable invalid: ${immutable}`);
  }
  if (changesetProcessor && typeof changesetProcessor !== 'function') {
    errors.push(`changesetProcessor must be a function`);
  }

  if (errors.length > 0) {
    throw new Error(`Invalid config: ${errors.join[',']}`);
  }
};
