import { dot as nestedObjectToFlattened } from 'dot-object';
import { getById as getBankAccountById, isTrustAccount, getTrustAccounts } from '@sb-billing/redux/bank-account';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/redux/contacts/index';
import moment from 'moment';

export const getTransferFundsValidateFn =
  ({
    supportsReference,
    supportsStatutoryDepositMatter,
    showContacts,
    matterClientAddressRequiredForTrustDeposit,
    matterClientOrDescriptionRequired,
    matterClientRequiredForTrustDeposit,
    matterDescriptionRequiredForTrustDeposit,
    isTransferNumberingEnabled,
    t,
  }) =>
  async (formFields) => {
    const formErrors = {};

    // source matter
    if (formFields.sourceMatterId) {
      const matterIsStatutoryDeposit = isStatutoryDepositMatter({
        matterId: formFields.sourceMatterId,
        trustBankAccounts: getTrustAccounts(),
        supportsStatutoryDepositMatter,
      });
      if (matterIsStatutoryDeposit) {
        formErrors.sourceMatterId = 'Cannot transfer funds from a statutory deposit matter';
      }
    }

    // source bank account
    if (formFields.sourceBankAccountId) {
      const bankAccount = getBankAccountById(formFields.sourceBankAccountId);
      if (
        isTrustAccount(bankAccount.accountType) &&
        isReconciled({
          yyyymmdd: moment().format('YYYYMMDD'),
          trustAccountId: formFields.sourceBankAccountId,
        })
      ) {
        formErrors.sourceMatterId = "The bank account's reconciliation has already been completed for today";
        formErrors.sourceBankAccountId = "The bank account's reconciliation has already been completed for today";
      }
    }

    // destination matter
    if (formFields.destinationMatterId) {
      const matter = getMatterById(formFields.destinationMatterId);
      formErrors.destinationMatterId = [];
      const hasClients = matterHasClients(matter, matterClientRequiredForTrustDeposit);
      const hasDescription = matterHasDescription(matter, matterDescriptionRequiredForTrustDeposit);
      const clientsMissingAddresses = matterClientAddressRequiredForTrustDeposit
        ? await matterClientsMissingAddresses(matter)
        : [];

      const matterIsStatutoryDeposit = isStatutoryDepositMatter({
        matterId: formFields.destinationMatterId,
        trustBankAccounts: getTrustAccounts(),
        supportsStatutoryDepositMatter,
      });
      if (matterIsStatutoryDeposit) {
        formErrors.destinationMatterId.push('Cannot transfer funds to the statutory deposit matter');
      }

      if (matterClientOrDescriptionRequired && !(hasClients && hasDescription)) {
        if (hasClients && !hasDescription) {
          formErrors.destinationMatterId.push(
            'A matter description has not been added to the matter. Please add a description before making a trust transfer.',
          );
        } else if (!hasClients && hasDescription) {
          formErrors.destinationMatterId.push(
            'A client has not been added to the matter. Please add a client before making a trust transfer.',
          );
        } else {
          formErrors.destinationMatterId.push(
            'A matter description and client has not been added to the matter. Please add before making a trust transfer.',
          );
        }
      }

      if (matterClientAddressRequiredForTrustDeposit && clientsMissingAddresses && clientsMissingAddresses.length > 0) {
        formErrors.destinationMatterId = [
          ...formErrors.destinationMatterId,
          ...clientsMissingAddresses.map((client) => `contactId:${client.id}'s address is incomplete.`),
        ];
      }

      if (!formFields.destinationBankAccountId) {
        formErrors.destinationMatterId.push(
          `The matter you're transferring to must have access to the same ${t(
            'trustAccount',
          ).toLowerCase()} as the originating funds`,
        );
      }

      if (formFields.sourceMatterId === formFields.destinationMatterId) {
        if (!showContacts) {
          formErrors.destinationMatterId.push(`You cannot transfer funds to the same matter`);
        } else if (formFields.sourceContactId === formFields.destinationContactId) {
          formErrors.destinationMatterId.push(`You cannot transfer funds to the same contact and matter`);
          formErrors.destinationContactId = true;
        }
      }

      if (formErrors.destinationMatterId.length > 0) {
        formErrors.destinationMatterId = formErrors.destinationMatterId.join('\n');
      } else {
        delete formErrors.destinationMatterId;
      }
    }

    // reference
    if (
      supportsReference &&
      formFields.sourceBankAccountId &&
      !isTransferNumberingEnabled(formFields.sourceBankAccountId) &&
      !formFields.reference
    ) {
      formErrors.reference = true;
    }

    // amount
    if (formFields.amount) {
      if (formFields.amount > formFields.sourceBalanceAvailable) {
        formErrors.amount = true;
      }
    }

    // Forms 2 expects errors to be reported as flattened dot object notation.
    return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
  };

function matterHasClients(matter, matterClientRequiredForTrustDeposit) {
  if (!matterClientRequiredForTrustDeposit) {
    // this function is used to display correct error so if matter client is not required
    // we treat it as that matter has clients
    return true;
  }
  return matter ? matter.clientCustomerIds && matter.clientCustomerIds.length > 0 : true;
}

function matterHasDescription(matter, matterDescriptionRequiredForTrustDeposit) {
  if (!matterDescriptionRequiredForTrustDeposit) {
    // this function is used to display correct error so if description is not required
    // we treat it as that matter has description
    return true;
  }
  return matter ? matter.description && matter.description.length > 0 : true;
}

async function matterClientsMissingAddresses(matter) {
  const matterClients = matter.clientCustomerIds;
  const clientContactsWithMissingStreetAddress = await findContactsWithMissingStreetOrPOAddress({
    contactIds: matterClients,
    spreadGroupsOfPeople: true,
  });
  return clientContactsWithMissingStreetAddress;
}
