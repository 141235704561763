import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { fetchById as fetchFullCorrespondenceHistoryById } from '@sb-billing/redux/correspondence-history-full';
import { Modal, Spinner, useTranslation } from '@sb-itops/react';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';

import { DebugEntity } from 'web/react-redux';

import Styles from './CorrespondenceHistoryDetailsModal.module.scss';

const CorrespondenceHistoryDetailsModal = ({ correspondenceHistoryIdToShow, isVisible, onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [correspondenceHistoryDetail, setCorrespondenceHistoryDetail] = useState({});

  useEffect(() => {
    const loadCorrespondenceHistoryDetail = async () => {
      if (!correspondenceHistoryIdToShow) return;

      try {
        setIsLoading(true);
        const fullCorrespondenceHistory = await fetchFullCorrespondenceHistoryById(correspondenceHistoryIdToShow);
        setCorrespondenceHistoryDetail(fullCorrespondenceHistory);
      } catch (err) {
        displayErrorToUser(
          `Failed to get full correspondence history ${correspondenceHistoryIdToShow}. Error: ${err.message || ''}`,
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadCorrespondenceHistoryDetail();
  }, [correspondenceHistoryIdToShow]);

  const { status, sentTimestamp, lastUpdated, to, from, cc, bcc, userId, body, relatedIds, internalNote } =
    correspondenceHistoryDetail;
  const timeStamp = sentTimestamp || lastUpdated; // if sentTimestamp exists we use it, otherwise we use lastUpdated

  let emailBody = body;
  // Check if body exists and contains the [INVOICE_NUMBER] placeholder
  if (body && body.includes('[INVOICE_NUMBER]')) {
    const invoiceId = relatedIds && relatedIds[0];
    const invoiceNumber = getInvoiceNumberById(invoiceId) || '';
    emailBody = body.replace(/\[INVOICE_NUMBER\]/g, invoiceNumber);
  }

  return (
    <Modal
      className={Styles.correspondenceHistoryDetailModal}
      isVisible={isVisible}
      title="Client Communication"
      body={
        <>
          <DebugEntity entityCacheName="sbCorrespondenceHistoryService" entityId={correspondenceHistoryIdToShow} />
          {isLoading ? (
            <div className={Styles.loading}>
              <Spinner small />
            </div>
          ) : (
            <div className={Styles.container}>
              {Number.isInteger(status) && (
                <div className="col-sm-6 form-group">
                  <label className={Styles.label}>Status:</label>
                  {status === 0 && 'In progress'}
                  {status === 1 && 'Success'}
                  {status === 2 && 'Fail'}
                </div>
              )}
              {timeStamp && (
                <div className="col-sm-6 form-group">
                  <label className={Styles.label}>Date:</label>
                  {t('date', {
                    yyyymmdd: timeStamp,
                  })}
                </div>
              )}
              {to && (
                <div className="col-sm-12 form-group">
                  <label className={Styles.label}>To:</label>
                  {to}
                </div>
              )}
              {cc && (
                <div className="col-sm-6 form-group">
                  <label className={Styles.label}>CC:</label>
                  {cc}
                </div>
              )}
              {bcc && (
                <div className="col-sm-6 form-group">
                  <label className={Styles.label}>BCC:</label>
                  {bcc}
                </div>
              )}
              {from && (
                <div className="col-sm-6 form-group">
                  <label className={Styles.label}>From:</label>
                  {from}
                </div>
              )}
              {userId && (
                <div className="col-sm-6 form-group">
                  <label className={Styles.label}>Sent by:</label>
                  {getPersonByUserId(userId)?.name}
                </div>
              )}
              {body && (
                <div className="col-sm-12 form-group">
                  <label>Body of email:</label>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div className={Styles.emailBody} dangerouslySetInnerHTML={{ __html: emailBody }} />
                </div>
              )}
              {internalNote && (
                <div className="col-sm-12 form-group">
                  <label>Notes:</label>
                  <div className={Styles.internalNote}>{internalNote}</div>
                </div>
              )}
            </div>
          )}
        </>
      }
      showFooter={false}
      onModalClose={onClose}
    />
  );
};

CorrespondenceHistoryDetailsModal.displayName = 'CorrespondenceHistoryDetailsModal';

CorrespondenceHistoryDetailsModal.propTypes = {
  correspondenceHistoryIdToShow: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CorrespondenceHistoryDetailsModal.defaultProps = {};

export default CorrespondenceHistoryDetailsModal;
