'use strict';

const { entryType, durationType } = require('../../shared/entities');

/**
 * derive preferred task duration type
 * @param {Object} params
 * @param {Object} params.taskCode
 * @param {number} [params.taskCode.entryType] entry type is only present in custom UTBMS task codes
 * it's not present in the standard UTBMS task codes
 * @param {boolean} params.preferDurationAsUnits user prefers duration as units in staff settings
 * @returns
 */
const getPreferredTaskDurationType = ({ taskCode, preferDurationAsUnits = false }) => {
  if (taskCode.entryType === entryType.FIXED) {
    return durationType.FIXED;
  }

  return preferDurationAsUnits ? durationType.UNITS : durationType.HOURS;
};

module.exports = {
  getPreferredTaskDurationType,
};
