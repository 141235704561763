'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-contact/details';

  $stateProvider

  .state('home.billing.view-contact.details', {
    url: '/details',
    templateUrl: BASE_DIRECTORY + '/view-contact-details.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'contacts', 'view-contact'],
      tab: { type: 'contact' }
    },
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl'
  });

});
