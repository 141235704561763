'use strict';

const { getRegion, regionType } = require('@sb-itops/region');

const languages = {
  [regionType.US]: 'en-US',
  [regionType.AU]: 'en-AU',
  [regionType.GB]: 'en-GB',
}

module.exports = (forcedRegion) => ({
  type: 'languageDetector',
  async: false,
  init: function () {
    // nothing to do
  },
  detect: function() {
    const region = forcedRegion || getRegion();
    if (!languages[region]) {
      throw new Error(`Unsupported region ${region}`);
    }
    
    return languages[region];
  },
})