import React, { useState } from 'react';
import { Button } from '@sb-itops/react';
import { ChartMixed, CircleInfo } from 'web/components/icons';
import { getProductTier } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';
import { sendMetric } from 'web/services/metrics';
import { trialBoostNow } from 'web/services/subscription';
import { useSelector } from 'react-redux';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { withReduxProvider } from 'web/react-redux/hocs';
import { isBarUser } from '@sb-finance/redux/subscription';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import * as Styles from './FeatureLockModal.module.scss';

const log = getLogger('FeatureLockModal');

const featureText = {
  tasks: {
    title: 'Manage tasks seamlessly with Smokeball Boost',
    text: "Manage your entire law firm's tasks, reminders, and document workflows with one system. Save time, reduce errors, and streamline your business.",
  },
  calendar: {
    title: 'Unlock calendar management with Smokeball Boost',
    text: 'Keep on top of your day-to-day and stay organised with ease with Smokeball Boost.',
  },
  files: {
    title: 'Keep all your files in one place with Smokeball Boost',
    text: 'Store all documents and emails in one place to efficiently manage your matter. You can even sync this with Outlook.',
  },
  messages: {
    title: 'Secure client communication with Smokeball Boost',
    text: 'Have secure conversations and share large files with your clients easily, via the Client Portal.',
  },
  matterEvents: {
    title: "Create and sync matter-specific event's with Smokeball Boost",
    text: 'Create matter-specific calendar events that sync with Outlook.',
  },
  memos: {
    title: 'Capture notes easily with Memos in Smokeball Boost',
    text: 'Perfect for storing quick notes from call logs, discovery, or any matter-related actions. Stay organised and keep every detail in one place.',
  },
};

interface IFeatureLockModalProps {
  children: React.JSX.Element;
  feature: keyof typeof featureText;
  onClickLink: (options: any) => void;
}

export const FeatureLockModal = withReduxProvider((props: IFeatureLockModalProps) => {
  const isBillTier = useSelector(() => getProductTier() === productTierIdsByName.BILL);
  const isBar = useSelector(isBarUser);
  const isBillFeatureLockOn = featureActive('NUCWEB-794');
  const showFeatureLock = isBillTier && isBillFeatureLockOn && isBar;
  const [loading, setLoading] = useState(false);

  return (
    <>
      {props.children}
      {/* Deliberately not using react-modal as we do not want to portal the modal to the top level */}
      {showFeatureLock && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={Styles.featureLockModal}
        >
          <div className={Styles.form}>
            <div className={Styles.titleBar}>
              <div className={Styles.icon}>
                <ChartMixed />
              </div>
              {featureText[props.feature].title}
            </div>
            <div className={Styles.mainForm}>{featureText[props.feature].text}</div>
            <div className={Styles.footer}>
              <Button
                className={Styles.button}
                onClick={async () => {
                  sendMetric('featureLock', { feature: props.feature });
                  setLoading(true);
                  await trialBoostNow().catch((e) => {
                    log.error(e);
                    messageDisplay.error(
                      'We could not move you to the boost tier at this time. Please contact Smokeball support for assistance.',
                    );
                  });
                  setLoading(false);
                }}
                type="warning"
                locked={loading}
              >
                Trial Boost Now
              </Button>
            </div>
            <div className={Styles.banner}>
              <CircleInfo fill="#FFF" />
              <div>Unlock your 30 day trial now, no credit card details needed</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
