import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { forms2PropTypes } from '@sb-itops/react';
import { regionType } from '@sb-itops/region';

import Styles from './AdjustmentForm.module.scss';
import { AdjustmentAmount, AdjustmentDate, AdjustmentReason } from './fields';

export const AdjustmentForm = ({
  // Form
  formName,
  region,
  formSubmitFailed,
  // Form fields
  date,
  reason,
  amount,
  // Form handlers
  onSubmit,
  onDateChange,
  onReasonChange,
  onAmountChange,
}) => (
  <form id={formName} className={classNames(Styles.form, 'main-form')} autoComplete="off" onSubmit={onSubmit}>
    <fieldset className={Styles.fieldSet}>
      <AdjustmentDate
        date={date}
        region={region}
        onDateChange={onDateChange}
        hasError={formSubmitFailed && date?.isInvalid}
      />
      <AdjustmentReason
        reason={reason}
        onReasonChange={onReasonChange}
        hasError={formSubmitFailed && reason?.isInvalid}
      />
      <AdjustmentAmount
        amount={amount}
        onAmountChange={onAmountChange}
        hasError={formSubmitFailed && amount?.isInvalid}
      />
    </fieldset>
  </form>
);

AdjustmentForm.displayName = 'AdjustmentForm';

const { Forms2Field } = forms2PropTypes;

AdjustmentForm.propTypes = {
  // Form
  formName: PropTypes.string.isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  formSubmitFailed: PropTypes.bool.isRequired,
  // Form fields
  date: Forms2Field,
  reason: Forms2Field,
  amount: Forms2Field,
  // Form handlers
  onDateChange: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AdjustmentForm.defaultProps = {
  date: undefined,
  reason: undefined,
  amount: undefined,
};
