'use strict';

const { isClientCardSavingEnabledForBankAccount: isLawpayClientCardSavingEnabledForBankAccount } = require('./lawpay');
const { isClientCardSavingEnabledForBankAccount: isStripeClientCardSavingEnabledForBankAccount } = require('./stripe');
const {
  isClientCardSavingEnabledForBankAccount: isEzyCollectClientCardSavingEnabledForBankAccount,
} = require('./ezy-collect');
const {
  isClientCardSavingEnabledForBankAccount: isFeeWiseClientCardSavingEnabledForBankAccount,
} = require('./fee-wise');
const { providers } = require('../entities/constants');

const clientCardSavingEnabledFns = {
  [providers.LAWPAY]: isLawpayClientCardSavingEnabledForBankAccount,
  [providers.STRIPE]: isStripeClientCardSavingEnabledForBankAccount,
  [providers.EZY_COLLECT]: isEzyCollectClientCardSavingEnabledForBankAccount,
  [providers.FEE_WISE]: isFeeWiseClientCardSavingEnabledForBankAccount,
};

/**
 * isClientCardSavingEnabledForBankAccount
 * Returns true if the payment provider supports client saving card for the specified bank account, false otherwise.
 * This can be used for example to allow client save card using Payment Portal.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings The current provider settings for the provider type being checked.
 * @param {object} params.providerType The provider type to check for payment provider availability
 * @param {string} params.bankAccountId The bank account id to check for payment provider availability
 * @param {string} params.bankAccountType The bank account type to check for payment provider availability
 * @returns {boolean}
 */
const isClientCardSavingEnabledForBankAccount = ({
  formattedProviderSpecificSettings,
  providerType,
  bankAccountId,
  bankAccountType,
}) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!bankAccountId) {
    throw new Error('bankAccountId must be provided');
  }

  if (!formattedProviderSpecificSettings) {
    return false;
  }

  const clientCardSavingEnabledForBankAccountFn = clientCardSavingEnabledFns[providerType];
  if (!clientCardSavingEnabledForBankAccountFn) {
    throw new Error(`Cannot determine if client card saving is enabled for provider type ${providerType}`);
  }

  return clientCardSavingEnabledForBankAccountFn({
    formattedProviderSpecificSettings,
    bankAccountId,
    bankAccountType,
  });
};

module.exports = {
  isClientCardSavingEnabledForBankAccount,
};
