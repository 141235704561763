import { fetchGetP } from '@sb-itops/redux/fetch';

export const checkSbClientSdkWhitelistThrows = async (clientId: string) => {
  if (!clientId) {
    throw new Error(`missing clientId`);
  }

  const response = await fetchGetP({
    path: `/integration/endpoint-locator/sb-client-sdk-whitelist-check/:accountId/${clientId}`,
  });

  if (response.status === 200) {
    const result = response.body;

    if (result !== true) {
      throw new Error(`${clientId} not whitelisted`);
    }

    return result;
  }

  throw new Error(`unexpected status: ${response.status}`);
};
