import * as Yup from 'yup';
import { PAYMENT_TYPE } from '@sb-billing/business-logic/payment-source';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';
import { PrintNotApplicable, PrintManually } from '@sb-billing/business-logic/cheques';
import { isDateReconciled } from '@sb-billing/business-logic/bank-reconciliation';

import { paymentTypeEnum } from './AddPaymentModalBody';

// Context we need to pass when validating:
// t function
// minAmountAllowed
// paymentSourceOptionsMap
// allowOverdraw
// isMatterContactBalanceFirm
// reasonOnInvoicePaymentsEnabled
// isTrustChequePrintingActiveForBankAccountId function
// isTrustToOfficeNumberingEnabled function
// supportsTtoNumbering
// nextTrustChequeNumber
// lastTrustChequeNumber
// bankReconciliationLatestCompleted
// bankReconciliationSetup

export const addPaymentFormSchema = Yup.object().shape({
  amount: Yup.number()
    .min(0.1)
    .required()
    .test('is-valid-amount', '', (amount, { createError, options, parent: fieldValues }) => {
      if (Object.keys(options.context || {}).length === 0) {
        return true; // skip validation if no context is provided
      }

      const { paymentSourceOptionsMap, minAmountAllowed, allowOverdraw, t } = options.context || {};
      const { takePaymentNow, payments, paymentSourceId } = fieldValues;

      const paymentSourceSelected = paymentSourceOptionsMap[paymentSourceId];
      const totalPayments = Object.values(payments || {}).reduce(
        (runningTotal, invoicePayment) => runningTotal + invoicePayment,
        0,
      );

      let errorMessage;

      if (takePaymentNow && amount < minAmountAllowed) {
        errorMessage = `Minimal amount allowed by your payment processor is ${t('cents', {
          val: minAmountAllowed,
        })}`;
      } else if (takePaymentNow && (amount <= 0 || amount > totalPayments)) {
        errorMessage = 'Amount should be equal or less than the invoice totals selected';
      } else if (!takePaymentNow && amount !== totalPayments) {
        errorMessage = 'Amount should be equal to the invoice totals selected';
      } else if (amountExceedsBalance({ paymentSource: paymentSourceSelected, allowOverdraw, amount })) {
        errorMessage = 'Amount exceeds available balance';
      }

      if (errorMessage) {
        return createError({
          path: 'amount',
          message: errorMessage,
        });
      }

      return true;
    }),

  matterId: Yup.string().when('paymentType', {
    is: paymentTypeEnum.MATTER,
    then: customYupValidators.uuid().required(),
    otherwise: customYupValidators.uuid().nullable().optional(),
  }),

  clientId: Yup.string().when('paymentType', {
    is: paymentTypeEnum.CLIENT,
    then: customYupValidators.uuid().required(),
    otherwise: customYupValidators.uuid().nullable().optional(),
  }),

  // the paidById has an error if the paidById is not present AND:
  //   1. it is matter balances AND from a direct source OR
  //   2. it is contact balances from any non-combined source
  paidById: Yup.string().when(['paymentSourceId', '$paymentSourceOptionsMap', '$isMatterContactBalanceFirm'], {
    is: (paymentSourceId, paymentSourceOptionsMap, isMatterContactBalanceFirm) => {
      if (paymentSourceOptionsMap === undefined && isMatterContactBalanceFirm === undefined) {
        return false; // use 'otherwise' schema if no context is provided
      }

      return (
        (isMatterContactBalanceFirm && !paymentSourceOptionsMap[paymentSourceId]?.isCombinedBalance) ||
        (!isMatterContactBalanceFirm && paymentSourceOptionsMap[paymentSourceId]?.paymentType === PAYMENT_TYPE.direct)
      );
    },
    then: customYupValidators.uuid().required(),
    otherwise: customYupValidators.uuid().nullable().optional(),
  }),

  effectiveDate: customYupValidators
    .integerDate()
    .required()
    .test('is-reconciled', '', (effectiveDate, { createError, options, parent: fieldValues }) => {
      if (Object.keys(options.context || {}).length === 0) {
        return true; // skip validation if no context is provided
      }

      const { paymentSourceOptionsMap, bankReconciliationLatestCompleted, bankReconciliationSetup } =
        options.context || {};
      const { paymentSourceId } = fieldValues;

      const paymentSourceSelected = paymentSourceOptionsMap[paymentSourceId];
      const isTrustPayment = paymentSourceSelected?.paymentType === PAYMENT_TYPE.trust;

      if (
        isTrustPayment &&
        effectiveDate &&
        paymentSourceSelected?.bankAccountId &&
        isDateReconciled({
          yyyymmdd: effectiveDate,
          bankReconciliationLatestCompleted,
          bankReconciliationSetup,
        })
      ) {
        return createError({
          path: 'effectiveDate',
          message: 'Warning: the date selected has already been reconciled',
        });
      }

      return true;
    }),

  reason: Yup.string().when(['paymentSourceId', '$paymentSourceOptionsMap', '$reasonOnInvoicePaymentsEnabled'], {
    is: (paymentSourceId, paymentSourceOptionsMap, reasonOnInvoicePaymentsEnabled) => {
      if (paymentSourceOptionsMap === undefined && reasonOnInvoicePaymentsEnabled === undefined) {
        return false; // use 'otherwise' schema if no context is provided
      }
      return (
        reasonOnInvoicePaymentsEnabled && paymentSourceOptionsMap[paymentSourceId]?.paymentType === PAYMENT_TYPE.trust
      );
    },
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),

  reference: Yup.string().test('is-valid-reference', '', (reference, { createError, options, parent: fieldValues }) => {
    if (Object.keys(options.context || {}).length === 0) {
      return true; // skip validation if no context is provided
    }

    const {
      paymentSourceOptionsMap,
      isTrustChequePrintingActiveForBankAccountId,
      isTrustToOfficeNumberingEnabled,
      supportsTtoNumbering,
      t,
      nextTrustChequeNumber,
      lastTrustChequeNumber,
    } = options.context || {};
    const { paymentSourceId, printingMethodId } = fieldValues;

    const paymentSourceSelected = paymentSourceOptionsMap[paymentSourceId];
    const isTrustPayment = paymentSourceSelected?.paymentType === PAYMENT_TYPE.trust;
    const isTrustChequePrintingActive = isTrustChequePrintingActiveForBankAccountId(
      paymentSourceSelected?.bankAccountId,
    );
    const isTtoNumberingEnabled = isTrustToOfficeNumberingEnabled(
      paymentSourceSelected?.trustToOfficeNumberingSettings,
    );

    const referenceRequired = isReferenceRequired({
      isTrustPayment,
      printingMethodId,
      isTrustChequePrintingActive,
      isTtoNumberingEnabled,
      supportsTtoNumbering,
    });

    const referenceEmpty = referenceRequired && !reference;
    const isUsingTrustCheque = isTrustChequePrintingActive && printingMethodId !== PrintNotApplicable;

    if (referenceEmpty) {
      return createError({
        path: 'reference',
        message: 'Reference is required',
      });
    }

    if (isUsingTrustCheque && referenceRequired && !/^[0-9]+$/.test(reference)) {
      return createError({
        path: 'reference',
        message: `Warning: ${t('capitalize', { val: 'cheque' })} reference must be numeric`,
      });
    }

    if (
      isTrustPayment &&
      !referenceEmpty &&
      isUsingTrustCheque &&
      printingMethodId === PrintManually &&
      // Strip out leading zeros and compare
      nextTrustChequeNumber?.replace(/^0+/, '') !== reference?.replace(/^0+/, '')
    ) {
      return createError({
        path: 'reference',
        message: `Warning: ${t('capitalize', { val: 'cheque' })} reference is already in use. Last ${t(
          'cheque',
        ).toLowerCase()} reference printed was ${lastTrustChequeNumber}.`,
      });
    }

    return true;
  }),
});

// For trust payments, reference seems to be used for two separate purposes
// 1. When Trust Cheque is used for the invoice payment: Reference is treated like a cheque number,
//    and has to be numeric. This same reference number is used in the 1) cheque 2) payment & 3) trust
//    transaction records associated with this invoice payment.
//    a) In the PrintManually scenario, this reference field is required
//    b) In non-PrintManually scenarios, this reference number won’t be allocated until the cheque is printed.
// 2. When trust payment is made with bank transfer: i.e. either
//    a) trust cheque is turned off for the firm or
//    b) print method is “Not Applicable (Bank Transfer)”
//    Reference here can be non-numeric and is used as the reference number for 1) payment and 2) transaction
//    record associated with the invoice payment. This field is now required when auto numbering for
//    Trust to Office Transfer is turned OFF in AU.
//
// For non-trust payment types, it seems this is a manually allocated reference number and is not required.
//
// History (AU only)
// Before BB-8425 was implemented, transaction number used to use auto numbering created for Electronic Payments.
// It is now using auto numbering for Trust to Office Transfer. One notable difference with this change is that
// when Trust to Office Transfer auto numbering is turned OFF, the transaction reference field is now a required field.
//
// Technical Notes
// When making changes to reference or invoice payment in general, please consider both
// 1. Matter > Invoices > Add Payment (this file)
// 2. Invoice > Payments > Add Payment
function isReferenceRequired({
  isTrustPayment,
  printingMethodId,
  isTrustChequePrintingActive,
  isTtoNumberingEnabled,
  supportsTtoNumbering,
}) {
  const isTrustChequePrintManually = isTrustChequePrintingActive && printingMethodId === PrintManually;

  // trust cheque printing is not applicable when
  // 1. trust cheque is not enabled for firm
  // 2. Not Applicable is selected as trust cheque print method
  // 3. print method is not selected (for some reason, we defensively assume it's Not Applicable)
  const isTrustChequeNotApplicable =
    !isTrustChequePrintingActive || printingMethodId === PrintNotApplicable || !printingMethodId;

  return (
    isTrustPayment &&
    (isTrustChequePrintManually || (supportsTtoNumbering && isTrustChequeNotApplicable && !isTtoNumberingEnabled))
  );
}

/**
 * Returns `true` if the source is an account (trust or operating) and the account has less the amount specified by the user.
 *
 * Returns `false` if the source is not an account or the amount specified by the user is less than the account amount.
 * */
function amountExceedsBalance({ paymentSource, allowOverdraw, amount }) {
  if (paymentSource?.paymentType === PAYMENT_TYPE.direct) {
    return false;
  }

  const hasProtectedBalance = Number.isFinite(paymentSource?.protectedBalance) && paymentSource?.protectedBalance > 0;
  // we allow overdraw on the balance in AU for compliance.
  if (allowOverdraw && !hasProtectedBalance) {
    return false;
  }

  return amount > (paymentSource?.balance || 0);
}
