'use strict';

// Get the billable minutes of a given matter for time/fee entry
// If the billable minutes has been overridden in the matter, use matter's billable minutes
// Otherwise, use the default firm level billable minutes
const getMatterBillableMinutes = ({ matterHourlyRate, firmBillableMinutes }) => {
  const isBillableMinutesOverridden = !!matterHourlyRate?.billableMinutes;
  const matterBillableMinutes = isBillableMinutesOverridden ? matterHourlyRate.billableMinutes : firmBillableMinutes;

  return matterBillableMinutes;
};

module.exports = {
  getMatterBillableMinutes,
};
