import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import { Button, FloatingCard, Modal } from '@sb-itops/react';
import Styles from './AccountSecurity.module.scss';

export const AccountSecurity = ({
  brandName,
  loading,
  userHasTwoFactorEnabled,
  buttonDisabled,
  buttonHoverText,
  onOpenSbSecurityUrl,
  moreInfoLink,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  if (loading) {
    return <i className="fa fa-spinner fa-pulse fa-fw" />;
  }

  return (
    <div className={Styles.page}>
      <FloatingCard applyMargin={false}>
        <div className={Styles.title}>
          <h3>Two Factor Authentication</h3>
          <div className={classnames(Styles.activeTag, userHasTwoFactorEnabled && Styles.greenHighlight)}>
            {userHasTwoFactorEnabled ? 'ENABLED' : 'DISABLED'}
          </div>
        </div>

        <p>
          Two Factor Authentication (or MFA) is a common way to help secure your account. When you login to your{' '}
          {brandName} account you will be prompted to enter a code that only you have access to.
          {moreInfoLink && (
            <>
              &nbsp;
              <a href={moreInfoLink} target="_blank" rel="noopener noreferrer">
                More info
              </a>
              .
            </>
          )}
        </p>

        {!featureActive('BB-14897') && (
          <div className={Styles.buttonsRow}>
            {userHasTwoFactorEnabled ? (
              <Button disabled={buttonDisabled} title={buttonHoverText} onClick={() => setConfirmationModalOpen(true)}>
                DISABLE 2FA
              </Button>
            ) : (
              <Button disabled={buttonDisabled} title={buttonHoverText} onClick={onOpenSbSecurityUrl}>
                ENABLE 2FA
              </Button>
            )}
          </div>
        )}
        {featureActive('BB-14897') && !userHasTwoFactorEnabled && (
          <div className={Styles.buttonsRow}>
            <Button disabled={buttonDisabled} title={buttonHoverText} onClick={onOpenSbSecurityUrl}>
              ENABLE 2FA
            </Button>
          </div>
        )}
      </FloatingCard>

      {userHasTwoFactorEnabled && (
        <Modal
          className={Styles.confirmationModal}
          title="Please Confirm"
          body={
            <div>Are you sure you want to disable 2FA? This will reduce the security of your {brandName} account.</div>
          }
          footer={
            <div className={Styles.footer}>
              <Button
                onClick={() => {
                  onOpenSbSecurityUrl();
                  setConfirmationModalOpen(false);
                }}
              >
                Disable
              </Button>
              <Button type="secondary" onClick={() => setConfirmationModalOpen(false)}>
                Cancel
              </Button>
            </div>
          }
          isVisible={confirmationModalOpen}
          onModalClose={() => setConfirmationModalOpen(false)}
          shouldCloseOnOverlayClick={false}
        />
      )}
    </div>
  );
};

AccountSecurity.propTypes = {
  brandName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  userHasTwoFactorEnabled: PropTypes.bool.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  onOpenSbSecurityUrl: PropTypes.func.isRequired,
  buttonHoverText: PropTypes.string,
  moreInfoLink: PropTypes.string,
};

AccountSecurity.defaultProps = {
  buttonHoverText: undefined,
  moreInfoLink: undefined,
};

AccountSecurity.displayName = 'AccountSecurity';
