import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Spinner = memo(({ small, simpleMessage, message, width, height, unit, className }) => {
  // We need to do this as the spinner doesn't allow for ease of controlling width/height independently.
  // This maintains backwards compatible behaviour
  const setDefaultWidth = width === undefined && height === undefined;
  // If they haven't specified a width and height, use the defaults. Otherwise, use what they pass in.
  const style = setDefaultWidth
    ? { width: `800px`, height: `600px` }
    : { width: width ? `${width}${unit}` : undefined, height: height ? `${height}${unit}` : undefined };

  return (
    <>
      {!!small && <i className={classnames(`fa fa-spinner fa-spin`, className)} aria-hidden="true" />}
      {!small && (
        <div className={classnames(`query-db-spinner spinner-text`, className)} style={style}>
          <div align="center" className="fond">
            <div className="contener_general">
              <div className="contener_mixte">
                <div className="ballcolor ball_1">&nbsp;</div>
              </div>
              <div className="contener_mixte">
                <div className="ballcolor ball_2">&nbsp;</div>
              </div>
              <div className="contener_mixte">
                <div className="ballcolor ball_3">&nbsp;</div>
              </div>
              <div className="contener_mixte">
                <div className="ballcolor ball_4">&nbsp;</div>
              </div>
            </div>
            {simpleMessage && <div className="loading-message">{simpleMessage}</div>}
            {!simpleMessage && message && (
              <div className="loading-message">
                {message}... <div className="med-weight">Please wait</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  message: PropTypes.string,
  simpleMessage: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  unit: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  message: undefined,
  simpleMessage: undefined,
  width: undefined,
  height: undefined,
  unit: 'px',
  small: false,
  className: undefined,
};

export default Spinner;
