import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '@sb-itops/react';
import Styles from './ReportRunnerSSRS.module.scss';

const ReportRunnerSSRS = React.memo(({ reportId, ssrsUrl, showLoadingSpinner, onSsrsContentReady }) => (
  <div className={Styles.columnFlex}>
    <div className={Styles.columnFlex}>
      {showLoadingSpinner && <Spinner className={Styles.loadingIndicator} />}
      <iframe
        key={reportId}
        title="ssrs-report-viewer"
        className={classnames(Styles.columnFlex, showLoadingSpinner ? Styles.hidden : '')}
        src={ssrsUrl}
        onLoad={onSsrsContentReady}
      />
    </div>
  </div>
));

ReportRunnerSSRS.displayName = 'ReportRunnerSSRS';

ReportRunnerSSRS.propTypes = {
  reportId: PropTypes.string.isRequired,
  ssrsUrl: PropTypes.string,
  showLoadingSpinner: PropTypes.bool,
  onSsrsContentReady: PropTypes.func.isRequired,
};

ReportRunnerSSRS.defaultProps = {
  showLoadingSpinner: false,
  ssrsUrl: undefined,
};

export default ReportRunnerSSRS;
