import React from 'react';
import smokeballLogoWhiteSrc from 'web/assets/smokeball-logo-white-text.svg';
import smokeballLogoSrc from 'web/assets/smokeball-logo.svg';
import classNames from 'classnames';
import Styles from './Topbar.module.scss';

export const Topbar = ({ isTriConveyBranded, isGrowProsper, buildVersion, serverVersion, firmName }) => (
  <div className={classNames(isGrowProsper && Styles.isGrowProsper, Styles.container)}>
    {!isTriConveyBranded && (
      <img
        src={isGrowProsper ? smokeballLogoSrc : smokeballLogoWhiteSrc}
        title={`Smokeball Web: ${buildVersion} API: ${serverVersion}`}
        className={Styles.logo}
        alt="Smokeball"
      />
    )}

    {isTriConveyBranded && (
      <div className={Styles.triConveyLogoContainer}>
        <div className="tri-convey-logo" title={`triConvey Web: ${buildVersion} API: ${serverVersion}`} />
      </div>
    )}
    <div className={Styles.separator} />
    <h3 className="title">{firmName}</h3>
  </div>
);
