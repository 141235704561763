import { roundCents } from '@sb-billing/bankers-rounding';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getMap } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';

const { getMatterBalance, getMatterContactBalance } = selectors;

export function addAmountToBalance({ balance, amount }) {
  if (Number.isInteger(balance) && Number.isInteger(amount)) {
    const cents = roundCents(Math.abs(amount || 0) + (balance || 0));
    return cents;
  }
  return balance || 0;
}

export function subtractAmountFromBalance({ balance, amount }) {
  if (Number.isInteger(balance) && Number.isInteger(amount)) {
    const cents = roundCents((balance || 0) - Math.abs(amount || 0));
    return cents;
  }
  return balance || 0;
}

export function getSourceBalance({ matterId, bankAccountId, contactId, amount }) {
  const fieldsToUpdate = {
    sourceBalanceAvailable: 0,
    sourceBalanceAvailableAfter: 0,
  };
  if (!matterId || !bankAccountId || (isMatterContactBalanceType() && !contactId)) {
    return fieldsToUpdate;
  }
  let balance = 0;
  if (contactId && isMatterContactBalanceType()) {
    balance = getMatterContactBalance(getMap(), {
      bankAccountId,
      contactId,
      matterId,
    });
  } else {
    balance = getMatterBalance(getMap(), { bankAccountId, matterId });
  }
  const availableAfter = subtractAmountFromBalance({ balance, amount });
  return {
    sourceBalanceAvailable: balance,
    sourceBalanceAvailableAfter: availableAfter,
  };
}

export function getDestinationBalance({ matterId, bankAccountId, contactId, amount }) {
  const fieldsToUpdate = {
    destinationBalanceAvailable: 0,
    destinationBalanceAvailableAfter: 0,
  };
  if (!matterId || !bankAccountId || (isMatterContactBalanceType() && !contactId)) {
    return fieldsToUpdate;
  }
  let balance = 0;
  if (contactId && isMatterContactBalanceType()) {
    balance = getMatterContactBalance(getMap(), {
      bankAccountId,
      contactId,
      matterId,
    });
  } else {
    balance = getMatterBalance(getMap(), { bankAccountId, matterId });
  }
  const availableAfter = addAmountToBalance({ balance, amount });
  return {
    destinationBalanceAvailable: balance,
    destinationBalanceAvailableAfter: availableAfter,
  };
}
