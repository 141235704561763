'use strict';

const {
  regionType,
  envType,
  initialise,
  getGoogleApiKey,
  getFrontendEnv,
  getBackendEnv,
  getRegion,
  getRestApiUrl,
  getDotNetHostUrl,
  getNotificationServerHostUrl,
  getLawPayClientId,
  getLawPayRedirectUrl
} = require('@sb-itops/environment-config');

angular.module('@sb-itops/services').constant('sbRegionType', regionType);

angular.module('@sb-itops/services').constant('sbEnvironmentType', envType);

angular.module('@sb-itops/services').provider('sbEnvironmentConfigService', function() {
  this.initialise = initialise;
  this.getGoogleApiKey = getGoogleApiKey;

  this.$get = function() {
    return {
      getFrontendEnv,
      getBackendEnv,
      getRegion,
      getRestApiUrl,
      getDotNetHostUrl,
      getNotificationServerHostUrl,
      getGoogleApiKey,
      getLawPayClientId,
      getLawPayRedirectUrl
    };
  };
});
