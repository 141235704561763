import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getMap as getMatterBalanceState } from '@sb-billing/redux/bank-account-balances';
import { WarningList } from '@sb-itops/react/warning-list';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

const mapStateToProps = (state, { paymentIds, t }) => {
  function displayCurrency(value) {
    return t('cents', { val: value });
  }

  const warnings = paymentIds.reduce((acc, paymentId) => {
    const payment = getPaymentById(paymentId);
    if (!payment || payment.isTrustToOffice) {
      // Trust to office reversal warnings are handled through TrustToOfficeReversalWarning component
      return acc;
    }

    const overPaidAmount = (payment && payment.totalAmount - payment.amount) || 0;
    const isOverPayment = overPaidAmount > 0;

    const bankAccountId = isOverPayment ? payment.overpaymentAccountId : payment.sourceAccountId;
    const bankAccount = bankAccountId ? getBankAccountById(bankAccountId) : {};
    const isTrustAccountInvoicePayment = bankAccount.accountType === bankAccountTypeEnum.TRUST;
    const paidAmount = (payment && payment.amount) || 0;

    if (!isTrustAccountInvoicePayment) {
      return acc;
    }

    const matterTrustBalance = getMatterBalance(getMatterBalanceState(), {
      bankAccountId: bankAccount.id,
      matterId: payment.matterId,
    });

    const trustBalanceAfterReversal = matterTrustBalance + (isOverPayment ? -overPaidAmount : paidAmount);
    const isNegativeTrustBalanceAfterReversal = hasFacet(facets.allowOverdraw) && trustBalanceAfterReversal < 0;

    if (isOverPayment && !isNegativeTrustBalanceAfterReversal) {
      acc.push({
        text: `Warning: This action will reverse the invoice payment of ${displayCurrency(
          paidAmount,
        )} as well as the overpaid amount of ${displayCurrency(overPaidAmount)}`,
      });
    }

    if (isOverPayment && isNegativeTrustBalanceAfterReversal) {
      acc.push({
        text: `Warning: As of the transaction date, the ${t(
          'trustAccount',
        ).toLowerCase()} will be overdrawn to a balance of ${displayCurrency(
          trustBalanceAfterReversal,
        )}. This action will also reverse the invoice payment of ${displayCurrency(
          paidAmount,
        )} as well as the overpaid amount of ${displayCurrency(overPaidAmount)}`,
      });
    }

    if (isTrustAccountInvoicePayment && !isOverPayment && isNegativeTrustBalanceAfterReversal) {
      acc.push({
        text: `Warning: As of the transaction date, the ${t(
          'trustAccount',
        ).toLowerCase()} will be overdrawn to a balance of ${displayCurrency(trustBalanceAfterReversal)}`,
      });
    }

    return acc;
  }, []);

  return { list: warnings };
};

export const InvoicePaymentReversalWarningContainer = withTranslation()(
  withReduxStore(connect(mapStateToProps)(WarningList)),
);

InvoicePaymentReversalWarningContainer.displayName = 'InvoicePaymentReversalWarningContainer';

InvoicePaymentReversalWarningContainer.propTypes = {
  paymentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InvoicePaymentReversalWarningContainer.defaultProps = {};
