/* eslint-disable no-param-reassign */
import { fetchDeleteP, fetchPutP } from '@sb-itops/redux/fetch';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { InitTimers } from 'web/graphql/queries';
import uuid from '@sb-itops/uuid';
import moment from 'moment';

const formatTimersResult = (timersResult, timersQuery) => {
  const matterMap = timersQuery.data.timers.reduce((summ, curr) => {
    if (!curr.matterId) {
      return summ;
    }
    summ[curr.matterId] = curr.matter;
    return summ;
  }, {});

  return timersResult.body.map((timer) => ({
    active: null,
    creationIso: null,
    description: null,
    elapsedSeconds: null,
    matterId: null,
    ...timer,
    activeSinceUnix: timer.active ? moment().unix() : null,
    __typename: 'Timer',
    matter: matterMap[timer.matterId] || null,
  }));
};

const pauseActiveTimer = (timers) => {
  timers.forEach((timer) => {
    if (timer.active) {
      timer.active = null;
      timer.elapsedSeconds = (timer.elapsedSeconds || 0) + (moment().unix() - (timer.activeSinceUnix || 0));
      timer.activeSinceUnix = null;
    }
  });
  return timers;
};

export const createTimerP = async (timersQuery) => {
  const newTimer = { timerId: uuid(), elapsedSeconds: 0, active: true, activeSinceUnix: moment().unix() };

  const opDate = pauseActiveTimer(timersQuery.data.timers.map((timer) => ({ ...timer })));
  opDate.push(newTimer);

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult({ body: opDate }, timersQuery),
    },
  });

  const timersResult = await fetchPutP({
    path: `/billing/user-timer/${getAccountId()}/${getUserId()}/${newTimer.timerId}/create`,
  });

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult(timersResult, timersQuery),
    },
  });
};
export const deleteTimerP = async (id, timersQuery) => {
  const opDate = timersQuery.data.timers.filter((timer) => timer.timerId !== id);

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: opDate,
    },
  });
  const timersResult = await fetchDeleteP({ path: `/billing/user-timer/${getAccountId()}/${getUserId()}/${id}` });

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult(timersResult, timersQuery),
    },
  });
};
export const pauseTimerP = async (id, timersQuery) => {
  const opDate = timersQuery.data.timers.map((timer) => ({ ...timer }));
  const activeTimer = opDate.find((timer) => timer.timerId === id);
  if (!id || (activeTimer && activeTimer.timerId === id)) {
    pauseActiveTimer(opDate);
  }

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: opDate,
    },
  });

  const timersResult = await fetchPutP({
    path: `/billing/user-timer/${getAccountId()}/${getUserId()}/${id}/pause`,
  });
  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult(timersResult, timersQuery),
    },
  });
};
export const resumeTimerP = async (id, timersQuery) => {
  const opDate = timersQuery.data.timers.map((timer) => ({ ...timer }));
  const selectedTimer = opDate.find((timer) => timer.timerId === id);
  pauseActiveTimer(opDate);
  if (selectedTimer) {
    selectedTimer.active = true;
    selectedTimer.activeSinceUnix = moment().unix();
  }

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: opDate,
    },
  });
  const timersResult = await fetchPutP({
    path: `/billing/user-timer/${getAccountId()}/${getUserId()}/${id}/resume`,
  });
  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult(timersResult, timersQuery),
    },
  });
};
export const updateTimerMatterIdP = async (id, matter, timersQuery) => {
  const opDate = timersQuery.data.timers.map((timer) => ({
    ...timer,
    matterId: timer.timerId === id ? matter?.id || '' : timer.matterId,
    matter: timer.timerId === id ? matter || null : timer.matter,
  }));

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: opDate,
    },
  });

  const timersResult = await fetchPutP({
    path: `/billing/user-timer/${getAccountId()}/${getUserId()}/${id}/setMatter/${matter?.id || ''}`,
    fetchOptions: {},
  });
  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult(timersResult, timersQuery).map((timer) => ({
        ...timer,
        matter: timer.timerId === id ? matter || null : timer.matter,
      })),
    },
  });
};
export const updateTimerDescriptionP = async (id, description, timersQuery) => {
  const opDate = timersQuery.data.timers.map((timer) => ({
    ...timer,
    description: timer.timerId === id ? description || '' : timer.description,
  }));

  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: opDate,
    },
  });

  const timersResult = await fetchPutP({
    path: `/billing/user-timer/${getAccountId()}/${getUserId()}/${id}/setDescription`,
    fetchOptions: { body: JSON.stringify({ description }) },
  });
  timersQuery.client.writeQuery({
    query: InitTimers.query,
    variables: timersQuery.variables,
    data: {
      timers: formatTimersResult(timersResult, timersQuery),
    },
  });
};
