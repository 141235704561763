import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { usePrevious } from '../hooks';
import { PaginationSelector } from './PaginationSelector';

const hooks = ({ selectedPage, totalNumberOfPages, onPageChange }) => ({
  usePagination: () => {
    let localSelectedPage = selectedPage;

    // To prevent the PaginationSelector from flashing due to the
    // totalNumberOfPages changing to 0 while page data is loading, we
    // keep the previous value to use as a placeholder until the query returns
    const previousNumberOfPages = usePrevious(totalNumberOfPages);

    // Minimum number of pages should be 1 in order to keep the pagination visible.
    // An empty result set should be seen as an empty page rather than an absence of a page
    const numberOfPages = totalNumberOfPages || previousNumberOfPages || 1;

    // If the user requests data that results in a smaller number of pages
    // while they are on a page that is beyond what is now available (resulting
    // in an empty result set) we reset the current page here instead of
    // displaying an empty page (or table)
    if (totalNumberOfPages && selectedPage > 0 && selectedPage + 1 > totalNumberOfPages) {
      localSelectedPage = 0;
      onPageChange({ selected: 0 });
    }

    return {
      selectedPage: localSelectedPage,
      totalNumberOfPages: numberOfPages,
    };
  },
});

export const PaginationSelectorContainer = composeHooks(hooks)(PaginationSelector);

PaginationSelectorContainer.displayName = 'PaginationSelectorContainer';

PaginationSelectorContainer.propTypes = {
  selectedPage: PropTypes.number,
  totalNumberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

PaginationSelectorContainer.defaultProps = {
  selectedPage: undefined,
};
