import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import InvoiceConfirmFinaliseModal from './InvoiceConfirmFinaliseModal';

export const InvoiceConfirmFinaliseModalContainer = withReduxProvider(InvoiceConfirmFinaliseModal);

InvoiceConfirmFinaliseModalContainer.displayName = 'InvoiceConfirmFinaliseModalContainer';

InvoiceConfirmFinaliseModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hasZeroBalance: PropTypes.bool.isRequired,
  hasUnpaidAD: PropTypes.bool.isRequired,
  invoiceCount: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InvoiceConfirmFinaliseModalContainer.defaultProps = {};
