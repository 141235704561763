import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import moment from 'moment';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import uuid from '@sb-itops/uuid';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { getDefaultCreditAccount } from '@sb-billing/redux/bank-account';
import store from '@sb-itops/redux/store';
import { CreditNoteModal } from './CreditNoteModal';
import { formDisabled } from './CreditNoteForm.container';

const log = getLogger('CreditNoteModalContainer');

export const CREDIT_NOTE_MODAL_ID = 'credit-note-modal';

const mapStateToProps = (state, { scope, contactId, matterId }) => ({
  scope,
  contactId,
  matterId,
  isSaveDisabled: formDisabled({ scope }),
});

const mapDispatchToProps = (dispatch, { scope, onClose }) => ({
  onClose,
  onSave: async () => {
    const { operations } = withScopedFeature({ scope })(forms);

    await dispatch(
      operations.submitFormP({
        validateBeforeSubmit: true,
        submitFnP: async ({ amount, selectedDate, selectedMatterId, selectedContactId, reason, note }) => {
          const body = JSON.stringify({
            bankAccountId: getDefaultCreditAccount().id,
            amount,
            effectiveDate: Number(moment(selectedDate).format('YYYYMMDD')),
            matterId: selectedMatterId,
            payorId: selectedContactId,
            transactionId: uuid(),
            userId: authSelectors.getUserId(store.getState()),
            description: note,
            note: reason,
          });

          try {
            await fetchPostP({
              path: `/billing/bank-account/credit/:accountId/`,
              fetchOptions: {
                body,
              },
            });
          } catch (err) {
            log.error(err);
            messageDisplay.error(
              messageDisplay
                .builder()
                .text('Problem saving credit')
                .conditionalText('{0}', err.message ? `: ${err.message}` : ''),
            );
          }
        },
      }),
    );
    onClose();
  },
});

export const CreditNoteModalContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(CreditNoteModal));

CreditNoteModalContainer.displayName = 'CreditNoteModalContainer';

CreditNoteModalContainer.propTypes = {
  scope: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  contactId: PropTypes.string,
  matterId: PropTypes.string,
};

CreditNoteModalContainer.defaultProps = {
  scope: 'credit-note-modal',
  isVisible: false,
  contactId: undefined,
  matterId: undefined,
};
