import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import moment from 'moment';
import { opdateCache, rollbackOpdateCache } from './index';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());
const depositEndpoint = '/billing/bank-account/deposit/:accountId/';

function buildTransaction(data) {
  const userId = getUserId();
  const opdateId = uuid();
  const transaction = {
    accountId: data.accountId,
    userId,
    accountType: data.accountType,
    amount: data.amount,
    bankAccountId: data.bankAccountId,
    payorId: data.contactId,
    description: data.description,
    id: data.transactionId || opdateId,
    transactionId: data.transactionId || opdateId,
    matterId: data.matterId,
    note: data.note,
    source: data.source,
    timestamp: moment().toISOString(),
    effectiveDate: data.effectiveDate || +moment().format('YYYYMMDD'),
    saveReceiptNumberAsReference: data.saveReceiptNumberAsReference || false,
  };

  if (data.reason) {
    transaction.reason = data.reason;
  }

  if (data.reference) {
    transaction.reference = data.reference;
  }

  if (data.bankName || data.drawerBank || data.bankBranchNumber) {
    transaction.bankName = data.bankName;
    transaction.drawerBank = data.drawerBank;
    transaction.bankBranchNumber = data.bankBranchNumber;
  }

  return transaction;
}

export const depositFunds = (fundsDetails) => {
  if (!fundsDetails?.accountType) {
    throw new Error('Account needs to have accountType specified.');
  }

  const accountId = getAccountId();
  const marshalledFundsDetails = buildTransaction({ ...fundsDetails, accountId });

  const depositFundsThunk = async () => {
    const newTransaction = { ...marshalledFundsDetails, versionId: uuid() };

    // Apply to save optimistically.
    const depositOpdate = buildDepositOpdate(newTransaction);
    opdateCache({ optimisticEntities: [depositOpdate] });

    // Apply the save in the backend.
    try {
      const path = depositEndpoint;
      const fetchOptions = { body: JSON.stringify(newTransaction) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [depositOpdate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(depositFundsThunk);
};

function buildDepositOpdate(fundsDetails) {
  return {
    ...fundsDetails,
    type: transactionType.Deposit,
    timestamp: new Date().toISOString(),
  };
}
