import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import { getMap as getMatterTypes } from '@sb-matter-types/redux/matter-types';
import {
  getList as getMatterList,
  getById as getMatterById,
  getMatterDisplayById,
} from '@sb-matter-management/redux/matters';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { findUsedMatterTypeIds, buildMatterTypeTree } from 'web/redux/selectors/used-matter-types';
import InvoiceHistoryFilter from './InvoiceHistoryFilter';

const { periodOption, periodOptions } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);
const defaultSelectOption = { value: 'all', label: 'All' };
const invoiceStatuses = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'final',
    label: 'Final',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const getAreaOfLawList = (matterId) => {
  const matterTypes = getMatterTypes();
  const matters = getMatterList();
  const chosenMatterTypes = findUsedMatterTypeIds(matters);
  const matter = matterId && getMatterById(matterId);

  const matterTypeTree = buildMatterTypeTree({
    matterTypeIds: chosenMatterTypes,
    matterTypes,
  });

  const areasOfLaw = [
    defaultSelectOption,
    ...matterTypeTree.map((matterTypeCategory) => ({
      label: matterTypeCategory.display,
      value: matterTypeCategory.data,
    })),
  ];

  const matterAreasOfLaw = matter
    ? areasOfLaw.filter(({ value }) => {
        const genericMatterTypeId = matter.matterTypeId && matter.matterTypeId.split('_')[0];

        return value.includes(matter.matterTypeId) || value.includes(genericMatterTypeId);
      })
    : [];

  return matterAreasOfLaw.length ? matterAreasOfLaw : areasOfLaw;
};

const mapStateToProps = (state, { filters }) => ({
  contacts: getContactTypeAheadSummaries(),
  staff: [defaultSelectOption, ...getCurrentStaff().map((s) => ({ value: s.id, label: `${s.initials} - ${s.name}` }))],
  areasOfLaw: getAreaOfLawList(filters ? filters.matterId : undefined),
  invoiceStatuses,
  periodOptions,
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      const defaultState = {
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        showInvoicesFor: 'all',
        attorneyResponsible: defaultSelectOption,
        areaOfLaw: defaultSelectOption,
        invoiceStatus: defaultSelectOption,
        errors: {},
      };

      if (hasFacet(facets.timeKeeperInitials)) {
        defaultState.showTimeKeeperInitials = false;
      }

      if (hasFacet(facets.deleteTransaction)) {
        defaultState.showDeletedTransactions = false;
      }

      onFiltersChanged(defaultState);
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onMatterSelected: (matter) =>
    matter
      ? dispatch(
          onFiltersChanged({
            ...filters,
            // in AU, for Law Society compliance reasons, we need to show the matter description
            matter: {
              id: matter.id,
              display: getMatterDisplayById(matter.id, false, hasFacet(facets.includeDescriptionInMatterDisplay)),
            },
            errors: {
              ...filters.errors,
              matter: false,
            },
          }),
        )
      : dispatch(
          onFiltersChanged({
            ...filters,
            matter: undefined,
            errors: {
              ...filters.errors,
              matter: filters.showInvoicesFor === 'matter',
            },
          }),
        ),

  onContactSelected: (option) =>
    option
      ? onFiltersChanged({
          ...filters,
          contact: { id: option.value, display: option.label },
          errors: {
            ...filters.errors,
            contact: false,
          },
        })
      : onFiltersChanged({
          ...filters,
          contact: undefined,
          errors: {
            ...filters.errors,
            contact: filters.showInvoicesFor === 'contact',
          },
        }),

  onShowInvoicesFor: (showInvoicesFor) =>
    onFiltersChanged({
      ...filters,
      showInvoicesFor,
      errors: {
        ...filters.errors,
        matter: showInvoicesFor === 'matter' && !filters.matter,
        contact: showInvoicesFor === 'contact' && !filters.contact,
      },
    }),

  onSelectOption: ({ key, option }) => onFiltersChanged({ ...filters, [key]: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const InvoiceHistoryFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(InvoiceHistoryFilter)),
);

InvoiceHistoryFilterContainer.displayName = `InvoiceHistoryFilterContainer`;

export default InvoiceHistoryFilterContainer;
