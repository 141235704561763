// is not recursive but should be easy to add if needed

/**
 * cleanObject
 * Removes properties from an object based on specified values. This is currently NOT recursive.
 * @param {object} obj Object to clean
 * @param {object} options
 * @param {Array<null|undefined|string>} [options.removeValues] values to remove. If not specified, properties with null and undefined values will be removed
 * @returns {object|undefined|null}
 * @throws if you pass a non-object value.
 */
function cleanObject(obj, options = {}) {
  if (obj === undefined || obj === null) {
    return obj;
  }

  if (typeof obj !== 'object' || Array.isArray(obj)) {
    throw new Error(`cleanObject expects an object, got: ${Array.isArray(obj) ? 'array' : typeof obj}`);
  }

  const valuesToRemove = Array.isArray(options.removeValues) ? options.removeValues : [null, undefined];

  return Object.keys(obj).reduce((acc, key) => {
    if (!valuesToRemove.includes(obj[key])) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

module.exports = {
  cleanObject,
};
