'use strict';

const costTypes = Object.freeze({
  HARD: 0,
  SOFT: 1,
});

const costTypeLabels = Object.freeze({
  HARD: 'Hard Cost',
  SOFT: 'Soft Cost',
});

module.exports = { costTypes, costTypeLabels };
