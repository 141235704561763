'use strict';

const { getTypeAsMaps, getTypeAsOptions } = require('@sb-itops/enum-helpers');

const invoiceStatementStatus = {
  ACTIVE: 0,
  VOID: 1,
};

const invoiceStatementStatusLabels = {
  ACTIVE: 'Active',
  VOID: 'Void',
};

const [invoiceStatementStatusMap, invoiceStatementStatusLabelToValueMap] = getTypeAsMaps({
  typeLabels: invoiceStatementStatusLabels,
  typeValues: invoiceStatementStatus,
});

const invoiceStatementStatusOptions = getTypeAsOptions({
  typeLabels: invoiceStatementStatusLabels,
  typeValues: invoiceStatementStatus,
});

module.exports = {
  invoiceStatementStatus,
  invoiceStatementStatusLabels,
  invoiceStatementStatusMap,
  invoiceStatementStatusLabelToValueMap,
  invoiceStatementStatusOptions,
};
