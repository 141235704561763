import { Duration } from 'moment';

export const humanise = (date: Duration): string => {
  const days = Math.round(date.asDays() * 10) / 10;
  const hours = Math.round(date.asHours() * 10) / 10;
  const minutes = Math.round(date.asMinutes() * 10) / 10;
  if (days >= 1) {
    return `${days} day${days !== 1 ? 's' : ''}`;
  }
  if (hours >= 1) {
    return `${hours} hr${hours !== 1 ? 's' : ''}`;
  }
  if (minutes >= 1) {
    return `${minutes} min${minutes !== 1 ? 's' : ''}`;
  }
  const result = date.humanize();
  if (result === 'a day') {
    return 'all day';
  }
  return result;
};
