import React from 'react';
import PropTypes from 'prop-types';
import { TasksTabContainer } from 'web/containers';
import { isNewTheme } from 'web/services/theme';
import { FeatureLockModal, FeatureLockPill } from 'web/react-redux/components/feature-lock';
import Styles from './ProductivityTasksRoute.module.scss';

const scope = 'productivity-firm-tasks-route';

export const ProductivityTasksRoute = ({ onClickLink }) => (
  <div className={Styles.productivityTasksRoute}>
    <div className="page-top-bar">
      <div className="page-name">
        {!isNewTheme() && <div className="page-name-icon icon-tasks" />}
        <h1 className="page-name-heading">
          Tasks
          <FeatureLockPill />
        </h1>
      </div>
      <div className="sb-tab-header">
        <ul className="nav nav-pills">
          <li className="active">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Tasks</a>
          </li>
        </ul>
      </div>
    </div>
    <FeatureLockModal feature="tasks" onClickLink={onClickLink}>
      <TasksTabContainer scope={scope} onClickLink={onClickLink} allowMatterSwitching />
    </FeatureLockModal>
  </div>
);

ProductivityTasksRoute.displayName = 'ProductivityTasksRoute';

ProductivityTasksRoute.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};

ProductivityTasksRoute.defaultProps = {};
