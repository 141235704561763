'use strict';

const { capitalize } = require('@sb-itops/nodash');

// We should use AU english (cheque, eCheque) but these values are used as `source` on Payment entity so can't be changed now
const PAYMENT_SOURCE = Object.freeze({
  check: 'Check',
  bankTransfer: 'Bank Transfer',
  cash: 'Cash',
  creditCard: 'Credit Card',
  eCheck: 'eCheck',
});

const PAYMENT_TYPE = Object.freeze({
  direct: 'Direct',
  trust: 'Trust',
  operating: 'Operating',
  credit: 'Credit',
});

const getDirectPaymentOptions = (t) => [
  {
    label: capitalize(t('cheque')),
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.check,
    isDefault: true,
    value: PAYMENT_SOURCE.check,
  },
  {
    label: 'Bank Transfer',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.bankTransfer,
    value: PAYMENT_SOURCE.bankTransfer,
  },
  {
    label: 'Cash',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.cash,
    value: PAYMENT_SOURCE.cash,
  },
  {
    label: 'Credit Card',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.creditCard,
    value: PAYMENT_SOURCE.creditCard,
  },
];

// The below is migrated from: monorepo/apps/smokeball-billing-web/src/redux/selectors/payment-source.js

/**
 * A default is chosen using the following rules in order of preference:
 * If the matter has > 0 held in Trust, the default is the combined balance, or contact balance if there is no combined
 * If the matter has > 0 held in Operating, the default is the combined balance, or contact balance if there is no combined
 * If the matter has no money held in an account, the default is the payment source marked as isDefault, e.g. Check/Cheque
 */
const getDefaultPaymentSource = (paymentSources) =>
  getDefaultOption(paymentSources, PAYMENT_TYPE.trust) ||
  getDefaultOption(paymentSources, PAYMENT_TYPE.operating) ||
  getDefaultOption(paymentSources);

/**
 * Returns the payment source for a particular payment type or undefined if the payment type is not provided or not found, this function is going to be use when we want
 * to get a particular payment source.
 * @param {Array<Payment_Source>} paymentSources
 * @param {PAYMENT_TYPE} paymentType
 * @return {Payment_Source||undefined} options, payment source or undefined
 */
const getPaymentSource = (paymentSources, paymentType) => {
  if (paymentSources) {
    if (paymentType) {
      const opt =
        paymentSources.find((option) => option.paymentType === paymentType && option.isCombinedBalance) ||
        paymentSources.find((option) => option.paymentType === paymentType);

      return opt;
    }
  }
  return undefined;
};

const getDefaultOption = (options, paymentType) => {
  if (!paymentType) {
    return options.find((option) => option.isDefault);
  }

  const opt =
    options.find((option) => option.paymentType === paymentType && option.isCombinedBalance) ||
    options.find((option) => option.paymentType === paymentType && option.balance > 0);

  return opt;
};

module.exports = {
  PAYMENT_SOURCE,
  PAYMENT_TYPE,
  getDirectPaymentOptions,
  getDefaultPaymentSource,
  getPaymentSource,
};
