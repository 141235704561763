import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class LinkableText extends React.Component {
  constructor(props) {
    super(props);
    this.onClickLink = this.onClickLink.bind(this);
  }

  onClickLink(e) {
    if (this.props.disabled) {
      return;
    }
    e.stopPropagation();
    this.props.onClickLink();
  }

  render() {
    const {
      className,
      text,
      asLink = false,
      inline = false,
      tabIndex,
      disabled,
      tooltip,
      appendTooltip,
      isError,
    } = this.props;

    // show text as tooltip if no tooltip specified, appendTooltip if that's specified as option
    const title = appendTooltip ? text + (tooltip ? ` ${tooltip}` : '') : tooltip || text;

    return inline ? (
      <span className={className} title={title} disabled={disabled}>
        {asLink ? (
          <span
            type="button"
            className={classnames('link-button', disabled && 'link-button-disabled', isError && 'link-button-error')}
            onClick={this.onClickLink}
            tabIndex={tabIndex}
            role="button"
          >
            {text}
          </span>
        ) : (
          <span title={title}>{text}</span>
        )}
      </span>
    ) : (
      <div className={className} title={title} disabled={disabled}>
        {asLink ? (
          <span
            type="button"
            className={classnames('link-button', disabled && 'link-button-disabled')}
            onClick={this.onClickLink}
            tabIndex={tabIndex}
            role="button"
          >
            {text}
          </span>
        ) : (
          <span title={title}>{text}</span>
        )}
      </div>
    );
  }
}

LinkableText.displayName = 'LinkableText';

LinkableText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  asLink: PropTypes.any,
  inline: PropTypes.bool,
  onClickLink: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  appendTooltip: PropTypes.bool,
  isError: PropTypes.bool,
};

// for now I will set all props optional as undefined. The idea is not validating anymore in the component
// and start using the default props. I wont do it in the components we already have but keep in mind from now
// if you are working or modifying this component please update the prop types. GP
LinkableText.defaultProps = {
  className: undefined,
  asLink: undefined,
  inline: false,
  onClickLink: undefined,
  tabIndex: undefined,
  text: '',
  disabled: false,
  tooltip: undefined,
  appendTooltip: false,
  isError: false,
};

export default LinkableText;
