import { DashboardFeeData, InitStaffSettings, InitFirmTaxSettings } from 'web/graphql/queries';
import moment from 'moment';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { fee as feeSummary, shared } from '@sb-billing/business-logic';
import { getRegion } from '@sb-itops/region';
import { useCacheQuery } from './use-cache-query';
import { useSubscribedQuery } from './use-subscribed-query';

const { FIXED } = shared.entities.entryType;
const REGION = getRegion();

const { calculateFeeAmountTax } = feeSummary.services;

const calculateFeesAndSummary = (originalFees, firmTaxRate, region) => {
  let totalUnits = 0;
  let totalHoursBillable = 0;
  let totalHoursNonBillable = 0;
  let totalBillableAmount = 0;
  let totalNonBillableAmount = 0;

  originalFees.forEach((originalFee) => {
    const fee = { ...originalFee };
    // TODO remove this by putting the feeTaxRate in the gql query
    const invoice = fee.invoiceId ? getInvoiceById(fee.invoice?.id) : null;

    const taxRate = invoice ? invoice.currentVersion.feeTaxRate : firmTaxRate;
    const { billableAmountExclTax, nonBillableAmountExclTax } = calculateFeeAmountTax({
      fee,
      taxRate,
      region,
    });

    totalBillableAmount += billableAmountExclTax || 0;
    totalNonBillableAmount += nonBillableAmountExclTax || 0;

    const units = (+fee.duration as number) && fee.feeType !== FIXED ? fee.duration : 0;
    const billableUnits = (+fee.billableDuration as number) && fee.feeType !== FIXED ? fee.billableDuration : 0;
    totalUnits += units;

    // totals related to billable amount (which can be different to total amount for grouped fees)
    totalHoursNonBillable += units - billableUnits;
    totalHoursBillable += billableUnits;
  });

  const summary = {
    units: totalUnits,
    billable: totalHoursBillable,
    nonBillable: totalHoursNonBillable,
    totalBillableAmount,
    totalNonBillableAmount,
  };

  return { summary };
};

export const useDashboardFees = ({ matterId }: { matterId?: string }) => {
  const { data: staffData } = useCacheQuery(InitStaffSettings.query);
  const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
  const { taxRate } = firmTaxSettingsData?.firmTaxSettings || {};

  const feesQueryResult = useSubscribedQuery(DashboardFeeData, {
    variables: {
      feeFilter: {
        feeAgeDays: {
          min: 0,
          max: 7,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        feeEarnerStaffIds: undefined,
        includeStatus: {
          current: true,
        },
      },
    },
  });

  if (feesQueryResult.error) {
    throw feesQueryResult.error;
  }

  const { data: feeData } = feesQueryResult;
  const feeList = feeData?.feeList?.results || [];

  const summaryPerDay: any[] = [];

  let totalNonBillable = 0;
  let totalBillable = 0;
  let totalNonBillableHours = 0;
  let totalBillableHours = 0;

  for (let i = 0; i < 8; i += 1) {
    const filteredFees = feeList.filter(
      (fee) =>
        (!matterId || fee.matter.id === matterId) &&
        !fee.matter.isLead &&
        fee.feeDate === +moment().subtract(i, 'days').format('YYYYMMDD'),
    );

    const { summary } = calculateFeesAndSummary(filteredFees, taxRate, REGION);

    const hours = Math.floor(summary.units / 60);
    const minutes = summary.units % 60;
    const billableHours = (summary.billable / 60).toFixed(2);
    const nonBillableHours = (summary.nonBillable / 60).toFixed(2);

    totalNonBillable += summary.totalNonBillableAmount || 0;
    totalBillable += summary.totalBillableAmount || 0;
    totalNonBillableHours += Number(nonBillableHours) || 0;
    totalBillableHours += Number(billableHours) || 0;

    summaryPerDay.push({
      name: !i
        ? `Today ${moment().format('ddd')}`
        : `${moment().subtract(i, 'days').format('D')} ${moment().subtract(i, 'days').format('ddd')}`,
      hours,
      minutes,
      date: moment().subtract(i, 'days'),
      Billable: billableHours,
      'Non-Billable': nonBillableHours,
      BillableAmount: Number((summary.totalBillableAmount || 0).toFixed(2)),
      'Non-BillableAmount': Number((summary.totalNonBillableAmount || 0).toFixed(2)),
    });
  }

  const summaryToday = summaryPerDay.splice(0, 1);

  summaryPerDay.reverse();

  return {
    loading: feesQueryResult.loading,
    staff: staffData?.loggedInStaff?.id,
    summaryPerDay,
    summaryToday,
    totalNonBillable,
    totalBillable,
    totalNonBillableHours: totalNonBillableHours.toFixed(2),
    totalBillableHours: totalBillableHours.toFixed(2),
  };
};
