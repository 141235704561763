import { featureActive } from '@sb-itops/feature';
import { store } from '@sb-itops/redux';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { selectors as commSelectors, actions as commActions } from 'web/redux/features/communicate';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';

export const getIsAuthenticated = () => authSelectors.isAuthenticated(store.getState());

const pollCommunicate = async (log) => {
  if (featureActive('NUCWEB-98')) {
    // placed here in case the feature is toggled later
    try {
      // only poll if user is authenticated
      if (authSelectors.isAuthenticated(store.getState())) {
        const commUnreads = commSelectors.getUnreadConversationDetails(store.getState());

        // only bother to poll if communicate has not set the notified value, this value obsoletes the poling
        if (commUnreads && !commUnreads.notified) {
          const response = await fetchGetP({ path: '/integration/communicate-unreads/:accountId/' });

          if (response.status === 200) {
            const result = response.body;
            if (Array.isArray(result)) {
              // try to avoid unneeded dispatch as it screws up reselect
              const oldIds = (commUnreads.polled || [])
                .map((c) => c.conversationId)
                .sort()
                .join();
              const newIds = result
                .map((c) => c.conversationId)
                .sort()
                .join();
              if (newIds !== oldIds) {
                store.dispatch(commActions.setUnreadConvPolling({ value: result }));
              }
            }
          }
        }
      }
    } catch (e) {
      log.warn(`Error polling communicate: ${e}`);
    }
  }
};

const FIFTEEN_MIN_MILLIS = 1000 * 60 * 15;

let intervalId = null;

export const initialiseCommunicateUnreadPolling = async ({ log }) => {
  if (intervalId !== null) {
    throw new Error('Communicate is already polling');
  }

  intervalId = setInterval(async () => {
    await pollCommunicate(log);
  }, FIFTEEN_MIN_MILLIS);

  setTimeout(async () => {
    await pollCommunicate(log);
  }, 1);

  return () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };
};
