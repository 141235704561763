import composeHooks from '@sb-itops/react-hooks-compose';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  WidgetClientInfo,
  DashboardInvoiceTotals,
  MatterDetails,
  DashboardActivity,
  DashboardMatterData,
  InitStaffSettings,
} from 'web/graphql/queries';
import moment from 'moment';
import { useSubscribedQuery, useDashboardFees, useCacheQuery } from 'web/hooks';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { isNewTheme } from 'web/services/theme';
import { useDispatch } from 'react-redux';
import * as viewMatterBillsFilters from 'web/redux/route/home-billing-view-matter-bills';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { BillingMatterDashboardRoute } from './BillingMatterDashboardRoute';

const bundleByDay = (items) =>
  items.reduce((summ, item) => {
    if (!summ.length || summ[summ.length - 1].date !== item.date) {
      summ.push({ date: item.date, items: [] });
    }
    summ[summ.length - 1].items.push(item);
    return summ;
  }, []);

const hooks = ({ matterId }) => ({
  useTempState: () => ({
    scope: 'BILLING_DASHBOARD',
  }),
  useStaffData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    return {
      hasMultipleStaff: staffData.staffMembers.length > 1,
    };
  },
  useInvoiceFilterActions: () => {
    const dispatch = useDispatch();
    return {
      onFilterByRecent: (value) => {
        dispatch(viewMatterBillsFilters.actions.onFilterByRecent(value));
      },
      onFilterInvoiceStatus: (status) => {
        dispatch(viewMatterBillsFilters.actions.onFilterInvoiceStatus(status));
      },
    };
  },
  useActivityData: () => {
    const [matterDetails, setMatterDetails] = useState<any>({});
    const { data, loading, error } = useSubscribedQuery(DashboardActivity, {
      variables: {
        filter: {
          matterId,
          statuses: [invoiceStatus.FINAL, invoiceStatus.PAID],
          pivotDate: Number(moment().format('YYYYMMDD')),
        },
        limit: 10,
      },
    });

    useEffect(() => {
      (async () => {
        // This can fail for brand new matters, we can retry or ignore the error since these are non critical properties.
        // For simplicity I have chosen to ignore the error
        const matterDetailsResponse = await fetchGetP({
          path: `/matter-management/matter/get/:accountId/${matterId}/`,
        }).catch(() => {});
        const matterDetailsBody = matterDetailsResponse?.body;
        if (matterDetailsBody) {
          setMatterDetails({
            openedDate: matterDetailsBody.openedDate && moment(matterDetailsBody.openedDate).format('YYYYMMDD'),
            closedDate: matterDetailsBody.closedDate && moment(matterDetailsBody.closedDate).format('YYYYMMDD'),
            leadOpenedDate:
              matterDetailsBody.leadOpenedDate && moment(matterDetailsBody.leadOpenedDate).format('YYYYMMDD'),
            leadClosedDate:
              matterDetailsBody.leadClosedDate && moment(matterDetailsBody.leadClosedDate).format('YYYYMMDD'),
          });
        }
      })();
    }, []);

    if (error) {
      throw error;
    }

    const pastActivities = data?.invoiceActivity?.pastActivity?.slice?.() || [];
    const futureActivities = data?.invoiceActivity?.futureActivity?.slice?.() || [];
    if (matterDetails.openedDate) {
      pastActivities.push({ type: 'matterOpened', date: matterDetails.openedDate });
    }
    if (matterDetails.closedDate) {
      pastActivities.unshift({ type: 'matterClosed', date: matterDetails.closedDate });
    }

    return {
      activities: {
        pastActivities: bundleByDay(pastActivities),
        futureActivities: bundleByDay(futureActivities),
        loading,
      },
    };
  },
  useMatterData: () => {
    const matterQueryResult = useSubscribedQuery(
      MatterDetails,
      {
        variables: {
          matterId,
          includeBillingConfiguration: false,
          includeMatterHourlyRate: false,
          matterFilter: {
            includeNonBillableLeadMatters: true,
            includeDeleted: true,
          },
        },
      },
      {
        notificationIds: [...MatterDetails.notificationIds],
      },
    );

    if (matterQueryResult.error) {
      throw matterQueryResult.error;
    }

    const { data: matterData } = matterQueryResult;
    const matter = matterData?.matter;

    return { matterDetails: { matter, loading: matterQueryResult.loading } };
  },
  useGraphQLforClientInfo: () => {
    const { data, loading, error } = useSubscribedQuery(WidgetClientInfo, {
      variables: {
        id: matterId,
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
        },
      },
    });

    if (error) {
      throw error;
    }

    const debtors = data?.matter?.billingConfiguration?.debtors || [];
    const otherSide = data?.matter?.otherSide || [];

    const otherClients = data?.matter?.clients?.slice() || [];

    const defaultClient = otherClients.shift();
    return {
      clients: {
        otherClients,
        debtors,
        otherSide,
        loading,
        defaultClient,
      },
    };
  },
  useMatterTotals: () => {
    const {
      data: matterTotalData,
      loading: matterTotalLoading,
      error: matterTotalError,
    } = useSubscribedQuery(DashboardMatterData, {
      variables: {
        matterId,
      },
    });

    const {
      data: invoicesData,
      loading: invoicesLoading,
      error: invoicesError,
    } = useSubscribedQuery(DashboardInvoiceTotals, {
      variables: {
        filter: {
          pivotDate: Number(moment().format('YYYYMMDD')),
          matterIds: [matterId],
          statuses: [invoiceStatus.FINAL],
        },
      },
    });

    if (matterTotalError || invoicesError) {
      throw matterTotalError || invoicesError;
    }

    const showControlledMoneyAccount = featureActive('BB-6381') && hasFacet(facets.CMA);

    return {
      accounts: {
        loading: matterTotalLoading || invoicesLoading,
        totals: {
          CREDIT: matterTotalData?.matter?.matterBalanceCredit?.balance || 0,
          CONTROLLEDMONEY:
            (showControlledMoneyAccount && matterTotalData?.matter?.matterBalanceControlledMoneySummary?.balance) || 0,
          TRUST: matterTotalData?.matter?.matterBalanceTrustSummary?.balance || 0,
          OPERATING: matterTotalData?.matter?.matterBalanceOperating?.balance || 0,
          UNBILLED: matterTotalData?.matter?.matterTotals?.unbilled || 0,
          UNPAID: matterTotalData?.matter?.matterTotals?.unpaidExcInterest || 0,
          OVERDUE: invoicesData?.invoiceTotalSummary?.overdue || 0,
        },
        showControlledMoneyAccount,
      },
    };
  },
  useFeeListData: () => {
    const fees = useDashboardFees({ matterId });
    return {
      fees,
    };
  },
});
const oldHooks = () => ({
  useTempState: () => ({
    scope: 'BILLING_DASHBOARD',
  }),
});
export const BillingMatterDashboardRouteContainer = withApolloClient(
  withReduxProvider((props) => composeHooks(isNewTheme() ? hooks : oldHooks)(BillingMatterDashboardRoute)(props)),
);

BillingMatterDashboardRouteContainer.propTypes = {
  onClickLink: PropTypes.func,
  matterId: PropTypes.string.isRequired,
  trustRetainerBelowLimit: PropTypes.bool.isRequired,
};

BillingMatterDashboardRouteContainer.defaultProps = {
  onClickLink: () => {},
};
