import React from 'react';
import { Translation } from '@sb-itops/react';

const dictionary = {
  open: 'Opened',
};

export const groupFormatHandler = ({ label, options }) => (
  <div className="matter-typeahead-group">
    <span className="matter-typeahead-group-menu-header">
      <i className="matters-icon" />
      {label}
      <span className="matter-typeahead-group-group-badge">{options.length}</span>
    </span>
  </div>
);

export const getStatusLabel = (status) => {
  const matterStatus = status?.toLowerCase();
  return !matterStatus || matterStatus === 'open' ? '' : `(${status})`;
};

export const formatDateItem = ({ date, t }) => {
  if (!date) {
    return '';
  }
  // Format date already in ISO string format.
  const dateAsIsoString = typeof date === 'string' ? date : date.toISOString();
  return t('date', { ts: dateAsIsoString, inUtc: true });
};

export const getMatterLabel = (display, status) => [display, getStatusLabel(status)].join(' ');

export const labelFormatHandler = (
  { display, status, matterStarted, matterClientNames, clientDisplay, isLead },
  { context },
) => {
  const getFormattedDate = (t) => formatDateItem({ date: matterStarted, t });
  const matterLabel = getMatterLabel(display, status);
  const getSelectLabel = (t) => `${matterLabel} - ${dictionary.open} ${getFormattedDate(t)}`;
  if (context === 'value')
    return (
      <Translation>
        {(t) => (
          <div className="matter-typeahead-text" title={getSelectLabel(t)}>
            {getSelectLabel(t)}
          </div>
        )}
      </Translation>
    );

  return (
    <Translation>
      {(t) => (
        <div style={{ display: 'flex' }}>
          <div className="matter-typeahead-group-display-icon" title={matterLabel}>
            <i className={isLead ? 'icon-lead' : 'icon-matter'} />
          </div>
          <div className="matter-typeahead-group-display-text" title={matterLabel}>
            <div className="matter-typeahead-group-label">{matterLabel}</div>
            <div className="matter-typeahead-group-client">Client: {clientDisplay || matterClientNames}</div>
            <div className="matter-typeahead-group-display-date">
              {dictionary.open} {formatDateItem({ date: matterStarted, t })}
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

export const noMattersToDisplayHandler = () => 'No matters found with this search criteria';
