import React from 'react';
import { LinkableText, Column } from '@sb-itops/react';
import { Table, utils } from '@sb-itops/react/table';
import moment from 'moment';
import { Staff, TaskResults } from 'types';
import { ADD_TASK_MODAL_ID } from 'web/containers';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import Styles from './TaskList.module.scss';

const { timestampLocalisedRenderer } = utils;
const ROW_HEIGHT = 76;
export interface ITaskListProps {
  entities?: TaskResults[];
  searchMeetsMinLength: boolean;
  hideMatter?: boolean;
  searchTerm: string;
  matterNames?: Record<string, string>;
  getStaffByPersonId: (id: string) => Staff;
  onOpenEditTask: (payload: { task: string }) => void;
  onClickLink?: (data: { type: string; id: string }) => void;
}

const dateCellRenderer = ({ cellData }) => timestampLocalisedRenderer({ cellData });
const dueDateCellRenderer = ({ cellData }) => (
  <div className={moment(cellData).isBefore(moment().startOf('day')) ? Styles.overDue : ''}>
    {timestampLocalisedRenderer({ cellData })}
  </div>
);

const SubjectRenderer = ({ rowData }) => rowData.subject;
const ContentHighlightRenderer = (searchTerm: string, subject: string, note: string) => {
  const element: (string | JSX.Element)[] = [];
  const content = `${subject} ${note}`;

  let index = 0;

  const regexp = new RegExp(`(.*?)(${searchTerm})`, 'gi');

  // eslint-disable-next-line no-restricted-syntax
  for (const m of content.matchAll(regexp)) {
    element.push(m[1]);
    element.push(
      <span key={m.index} className={Styles.highlight}>
        {m[2]}
      </span>,
    );
    index = (m.index || 0) + m[0].length;
  }
  element.push(content.slice(index, content.length));
  return element;
};

const MatterNameRenderer =
  (matterNames: Record<string, string>, onClickLink: (data: { type: string; id: string }) => void) =>
  ({ rowData }) =>
    (
      <LinkableText
        text={matterNames[rowData.matterId]}
        onClickLink={() => onClickLink({ type: 'matter', id: rowData.matterId })}
        asLink
        inline
      />
    );

const customRowRenderer =
  (searchTerm: string) =>
  ({ className, columns, index, key, style, rowData, onRowClick }) => {
    const a11yProps = { 'aria-rowindex': index + 1 };
    return (
      <div
        onClick={(event) => onRowClick({ event, index, rowData })}
        className={className}
        {...a11yProps}
        key={key}
        style={style}
      >
        <div style={{ ...style, display: 'flex', height: `${ROW_HEIGHT / 2}px`, top: 0 }}>{columns}</div>
        <div
          className={Styles.highlightContainer}
          style={{ ...style, height: `${ROW_HEIGHT / 2}px`, top: `${ROW_HEIGHT / 2}px`, paddingRight: '12px' }}
        >
          <div className={Styles.textContainer}>
            {ContentHighlightRenderer(searchTerm, rowData.subject || '', rowData.note || '')}
          </div>
        </div>
      </div>
    );
  };

const TaskTabs = (props: { cellData?: string[] }) => (
  <>
    {(props.cellData || []).map((label) => (
      <span key={label} className={Styles.categoryTag}>
        {label}
      </span>
    ))}
  </>
);

export const TaskList = ({
  entities,
  getStaffByPersonId,
  matterNames,
  onClickLink,
  hideMatter,
  searchMeetsMinLength,
  searchTerm,
}: ITaskListProps) => (
  <div className={Styles.container}>
    {!searchMeetsMinLength && <div className={Styles.warning}>Searches must contain at least 3 characters</div>}
    <Table
      className={Styles.table}
      onRowClick={({ rowData }) => {
        setModalDialogVisible({
          modalId: ADD_TASK_MODAL_ID,
          props: {
            task: rowData,
            completer: rowData.completerId && getStaffByPersonId(rowData.completerId),
            allowMatterSwitching: true,
          },
        });
      }}
      list={entities || []}
      dataLoading={!entities}
      rowRenderer={customRowRenderer(searchTerm)}
      rowHeight={ROW_HEIGHT}
    >
      <Column disableSort key="subject" dataKey="subject" label="Name" flexGrow={2} cellRenderer={SubjectRenderer} />
      <Column key="dueDate" dataKey="dueDate" label="Due" cellRenderer={dueDateCellRenderer} width={100} />
      <Column
        disableSort
        key="categories"
        dataKey="categories"
        label="Categories"
        cellRenderer={TaskTabs}
        flexGrow={1}
      />
      <Column
        key="completionDate"
        dataKey="completionDate"
        label="Completed on"
        cellRenderer={dateCellRenderer}
        width={150}
      />
      <Column
        disableSort
        key="assigneeIds"
        dataKey="assigneeIds"
        label="Assigned to"
        cellRenderer={({ cellData }) =>
          cellData &&
          cellData
            .map((id) => getStaffByPersonId(id)?.name)
            .filter((i) => !!i)
            .join(', ')
        }
        flexGrow={1}
      />
      {!hideMatter && onClickLink && (
        <Column
          key="matterId"
          dataKey="matterId"
          label="Matter"
          flexGrow={1}
          cellRenderer={MatterNameRenderer(matterNames || {}, onClickLink)}
        />
      )}
    </Table>
  </div>
);
