import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getSettings, saveSettings, getPreferredBankAccounts } from '@sb-billing/redux/bulk-finalize-settings';
import { getTrustAccount, getOperatingAccount } from '@sb-billing/redux/bank-account';

import FinaliseWithPaymentsSettings from './FinaliseWithPaymentsSettings';

function mapStateToProps() {
  const settings = getSettings();
  const preferredBankAccounts = getPreferredBankAccounts(settings);

  return {
    settings,
    preferredBankAccounts,
    defaultBankAccountId: getDefaultBankAccountId(),
  };
}

function mapDispatchToProps() {
  return {
    // we have written XHR actions to not really be redux actions,
    // hence no external call to dispatch is needed
    onSaveSettings: (settings) => saveSettings(settings),
  };
}
const FinaliseWithPaymentsSettingsContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(FinaliseWithPaymentsSettings),
);

function getDefaultBankAccountId() {
  const trustAccount = getTrustAccount();
  // return trustAccountId or operatingId or undefined
  return trustAccount ? trustAccount.id : getOperatingAccount()?.id;
}

FinaliseWithPaymentsSettingsContainer.displayName = 'FinaliseWithPaymentsSettingsContainer';

export default FinaliseWithPaymentsSettingsContainer;
