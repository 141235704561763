import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/end-of-month-reports';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('@sb-billing/services')
  .service('sbEndOfMonthReportsService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    'use strict';
    const transactionReceiptEndpoint = 'billing/end-of-month-reports';

    const supportsEndOfMonthReport = hasFacet(facets.endOfMonthReport);
    if (supportsEndOfMonthReport) {
      sbGenericCacheService.setupCache({
        name: 'sbEndOfMonthReportsService',
        sync: {
          endpoint: { type: sbEndpointType.SYNC_SINCE, stub: transactionReceiptEndpoint },
          poll: 60,
          subscriptions: 'notifier.AccountsNotifications.EndOfMonthReportUpdated',
        },
        updateRedux,
        clearRedux,
      });
    }
  });
