const allocateTargetFromSources = require('./allocate-target-from-sources');

/**
 * Shape of sources drawn upon for allocation.
 *
 * @typedef  {Object} Source
 * @property {string} sourceId - The id of the source
 * @property {number} amount   - The amount available to be drawn upon in this source.
 */

/**
 * Shape of target amounts to be allocated.
 *
 * @typedef  {Object} Target
 * @property {string} targetId - The id of the target
 * @property {number} amount   - The amount to be allocated for this target.
 */

/**
 * Shape of allocations created by this allocator.
 *
 * @typedef  {Object} Allocation
 * @property {string} sourceId  - The id of the source drawn upon for this allocation
 * @property {string} targetId  - The id of the target amount being allocated
 * @property {number} amount    - The amount of this allocation.
 * @property {number} remainingOnTarget - The amount remaining to be allocated for the target after this allocation has been applied - i.e. running remaining amount of the target.
 * @property {number} remainingOnSource - The amount remaining on the source used for this allocation - i.e. running remaining amount of the source.
 */

/**
 * allocateTargetsFromSources
 *
 * Creates {@link Allocation}s against an array of {@link Target} amounts by drawing upon an array of {@link Source} amounts.
 * The order of allocation is dictated by the ordering of {@link targets} and {@link sources}.
 *
 * The strategy behaves as follows;
 *
 * Loop over each target in the order they are provided
 *   For each target, loop over each source in the order they are provided
 *     For each source, draw the maximum amount possible to satisfy the target amount (up to the remaining target amount).
 *
 * @param {Source[]} sources      - The sources which will be drawn upon to satisfy target amounts.
 * @param {Target[]} targets      - The target amounts to be allocated.
 * @param {boolean}  zeroAllocate - Flag indicating whether sources will receive a 0 amount allocations once the current target amount has hit 0.
 *                                  True: All sources will be present in the final allocations for each target, some may have 0 amount allocations.
 *                                  False: Some sources may not be present in the final allocations for each target, all allocation amounts will be > 0.
 * @param {Function} transform    - Function which receives each allocation in order and returns a transformed allocation. Can be used by the caller to reshape the allocation objects.
 * @param {Function} accumulate   - Function which receives each allocation in order which can be used by the caller to efficiently calculate accumulation data across the allocations.
 *
 * @return {Allocation[]}         - The calculated allocations.
 */
module.exports = ({
  sources,
  targets,
  zeroAllocate = true,
  accumulate = () => {},
  transform = (allocation) => allocation,
}) => {
  const sourceBalances = sources.reduce((acc, source) => {
    acc[source.sourceId] = source.amount;
    return acc;
  }, {});

  return targets.reduce((acc, target) => {
    const updatedSources = Object.entries(sourceBalances).map(([sourceId, amount]) => ({ sourceId, amount }));
    const allocations = allocateTargetFromSources({
      target,
      sources: updatedSources,
      zeroAllocate,
      accumulate: (allocation, rawAllocation) => {
        sourceBalances[rawAllocation.sourceId] = rawAllocation.remainingOnSource;
        accumulate(allocation, rawAllocation);
      },
      transform,
    });

    acc.push(...allocations);
    return acc;
  }, []);
};
