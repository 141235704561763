'use strict';

/**
 * @typedef {Object} Contact
 * @property {string} id - uuid
 * @property {string} displayName
 */

/**
 * @typedef {Object} Debtor
 * @property {string} id - uuid
 * @property {Contact[]} debtors
 */

/**
 * @typedef {Object} Invoice
 * @property {string} id - uuid
 * @property {Debtor[]} debtors
 */

/**
 * @typedef {Object} BillingConfiguration
 * @property {string} id - uuid
 * @property {Contact[]} debtors
 */

/**
 * @typedef {Object} Matter
 * @property {string} id - uuid
 * @property {BillingConfiguration} billingConfiguration
 * @property {Contact[]} clients
 */

/**
 * Get Debtors from Invoice or Matter
 *
 * Note: This function expects Load on Demand compatible data structures
 *
 * @param {Object} params
 * @param {Invoice} params.invoice
 * @param {Matter} params.matter
 * @returns {Contact[]} An array of contacts
 */
const getDebtorsFromInvoiceOrMatter = ({ invoice, matter }) => {
  // Debtors on invoice
  const invoiceDebtors = invoice?.debtors?.map((debtor) => debtor.contact);
  if (invoiceDebtors?.length) {
    return invoiceDebtors;
  }

  // Debtors as defined in the matter's billing configuration
  const matterDebtors = matter?.billingConfiguration?.debtors;
  if (matterDebtors?.length) {
    return matterDebtors;
  }

  // First client
  const [firstClient] = matter?.clients || [];
  if (firstClient) {
    return [firstClient];
  }

  return [];
};

module.exports = {
  getDebtorsFromInvoiceOrMatter,
};
