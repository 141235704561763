import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import { Select } from '../select';

function calculateStyle({ initialStyleClass, customClasses }) {
  return `${initialStyleClass || ''} ${customClasses}`;
}

/**
 * @deprecated
 * Use typeahead.2
 */
const Typeahead = memo((props) => {
  const { initialId, findInOptions, options, onValueChange, onClear, valueField, displayField } = props;
  const handleChange = useCallback(
    (value, { action }) => {
      if (action === 'clear' && onClear) {
        onClear();
      }
      onValueChange(value);
    },
    [onClear, onValueChange],
  );

  const noItemsToDisplayIndicator = useCallback(
    () => (props.noItemsToDisplayIndicator && props.noItemsToDisplayIndicator()) || 'No results found',
    [props],
  );

  const getDisplayFieldFromOption = useCallback((op) => get(op, displayField || 'display'), [displayField]);

  const getValueFieldFromOption = useCallback((op) => get(op, valueField || 'id'), [valueField]);

  const defaultValue = useMemo(
    () => initialId && findInOptions && findInOptions(options, initialId),
    [findInOptions, initialId, options],
  );

  return (
    <Select
      // we need to spread props so if we are passing any react-select prop we can send it down to the component.
      {...props}
      className={classnames('typeahead-container', props.className)}
      styleClass={calculateStyle(props)}
      onValueChange={handleChange}
      options={options}
      getOptionLabel={getDisplayFieldFromOption}
      getOptionValue={getValueFieldFromOption}
      noOptionsMessage={noItemsToDisplayIndicator}
      disableInputOnSelection
      defaultValue={defaultValue || null}
    />
  );
});

Typeahead.displayName = 'Typeahead';

Typeahead.propTypes = {
  initialId: PropTypes.string,
  noItemsToDisplayIndicator: PropTypes.func,
  displayField: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onClear: PropTypes.func,
  findInOptions: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  hasError: PropTypes.bool,
};

Typeahead.defaultProps = {
  initialId: undefined,
  noItemsToDisplayIndicator: undefined,
  displayField: undefined,
  valueField: undefined,
  className: undefined,
  onClear: undefined,
  onValueChange: () => {},
  hasError: false,
};

export default Typeahead;
