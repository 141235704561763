import { SET_SORT_DIRECTION, SET_SORT_BY, RESET_SORT_DIRECTION } from './types';
import { sortDirections } from './sort-directions';

/**
 * Sets sort direction
 * @param {'asc'|'desc'|'ASC'|'DESC'} sortDirection
 * @throws if sortDirection is falsy
 */
export const setSortDirection = ({ sortDirection }) => {
  if (!sortDirection) {
    throw new Error('sortDirection cannot be falsy');
  }

  const direction = sortDirection.toUpperCase();

  if (!sortDirections[direction]) {
    throw new Error(`sortDirection need to be one of ${Object.values(sortDirections)}`);
  }

  return {
    type: SET_SORT_DIRECTION,
    payload: {
      sortDirection,
    },
  };
};

/**
 * Sets sort by field
 * @param {*} sortBy field name to sort by
 */
export const setSortBy = ({ sortBy }) => {
  if (!sortBy) {
    throw new Error('sortBy cannot be falsy');
  }

  return {
    type: SET_SORT_BY,
    payload: {
      sortBy,
    },
  };
};

/**
 * Reset sort direction
 */
export const reset = () => ({
  type: RESET_SORT_DIRECTION,
});
