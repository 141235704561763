import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';

import { MatterLatePaymentSettings } from './MatterLatePaymentSettings';

const mapStateToProps = (state, { formInitialised, formFields, formDisabled, formSubmitting, submitFailed }) => {
  const { hasInterestRate, ratePa, interestApplyMode, hasGracePeriod, gracePeriodDays } = formFields.interestSettings;

  return {
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    submitFailed,
    // form2 fields
    hasInterestRate,
    ratePa,
    interestApplyMode,
    hasGracePeriod,
    gracePeriodDays,
  };
};

const mapDispatchToProps = (dispatch, { onFieldValuesUpdated }) => ({
  onRatePaChange: (ratePa) => {
    onFieldValuesUpdated({ 'interestSettings.ratePa': ratePa });
  },
});

export const MatterLatePaymentSettingsContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(MatterLatePaymentSettings),
);

MatterLatePaymentSettingsContainer.displayName = 'MatterLatePaymentSettingsContainer';

MatterLatePaymentSettingsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterLatePaymentSettingsContainer.defaultProps = {
  matterId: undefined,
};
