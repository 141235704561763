'use strict';

const { applyEmailHtmlWrapper } = require('./apply-email-html-wrapper');
const { applyEmailTitle } = require('./apply-email-title');
const { buttonColours, buttonStyles, createButton } = require('./create-button');
const { createIconImage } = require('./create-icon-image');
const { createImage } = require('./create-image');
const { createLink } = require('./create-link');
const { createLogoMarkup } = require('./create-logo-markup');
const { extractEmailsFromString } = require('./extract-emails-from-string');
const { getFirmLogoUrl } = require('./get-firm-logo-url');
const { getLogoUrl } = require('./get-logo-url');
const { getPublicAssetsHostUrl } = require('./get-public-assets-host-url');
const { getTransparentFallbackLogoInBase64 } = require('./get-transparent-fallback-logo-image-in-base64');
const { marshal } = require('./marshal');
const { marshalDisplayName } = require('./marshall-display-name');
const { validateMultiple, validateMaxEmailsNumber, validateSingle, MAX_EMAILS_ALLOWED_DEFAULT } = require('./validate-email');

module.exports = {
  applyEmailHtmlWrapper,
  applyEmailTitle,
  buttonColours,
  buttonStyles,
  createButton,
  createLink,
  createIconImage,
  createImage,
  createLogoMarkup,
  extractEmailsFromString,
  getFirmLogoUrl,
  getLogoUrl,
  getPublicAssetsHostUrl,
  getTransparentFallbackLogoInBase64,
  marshal,
  marshalDisplayName,
  validateMultiple,
  validateMaxEmailsNumber,
  validateSingle,
  MAX_EMAILS_ALLOWED_DEFAULT
};
