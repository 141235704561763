import {
  isLeadMatter,
  isSaving as isMatterSaving,
  isDeleted as isMatterDeleted,
  restoreDeletedMatter,
  getMap as getMattersMap,
} from '@sb-matter-management/redux/matters';
import { store } from '@sb-itops/redux';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import * as messageDisplay from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { checkMatterPermissionsAndDisplayErrorPopupP } from 'web/services/matter-permissions';
import { getIntakeConfig } from 'web/services/intake-features';
import { isNewTheme } from 'web/services/theme';
import archieSparkles from 'web/assets/sparkles.png';


import { selectors as commSelectors } from 'web/redux/features/communicate';
import { getProductTier, hasBillingAccess } from 'web/services/user-session-management';
import { sortByProperty } from '@sb-itops/nodash';
import { isBarUser } from '@sb-finance/redux/subscription';

angular.module('sb.billing.webapp').component('sbMatterView', {
  templateUrl: 'ng-components/matter-view/matter-view.html',
  controller: function ($rootScope, $scope, $state, $stateParams, $location, sbLoggerService, sbUnsavedChangesService, sbMessageDisplayService, sbTrustRetainersService, sbBillingConfigurationService,
    sbDefaultMatterBillingConfigurationService, sbBankAccountService, sbLinkService, sbLocalisationService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('SbPartialStateController');
    const confirmRestoreMatterModalId = 'confirm-restore-matter';

    $scope.t = sbLocalisationService.t;

    let persistanceKey;
    let findUnsavedChanges;
    const openedFromTab = $stateParams.openedFromTab || false;

    ctrl.sbParams = _.cloneDeep($stateParams);
    ctrl.sbParamsAll = _.cloneDeep($state.params);
    ctrl.sbParams.sbSubState = getSubSState($state.current.name);
    ctrl.sbData = { stateName: $state.current.name };
    ctrl.$onInit = () => {
      // Update recent matter
      // If matter doesnt exist, route is incorrect
      // We only send the command if the matter is not open in a tab yet
      const matter = getMattersMap()[ctrl.sbParamsAll.matterId]
      if (featureActive('BB-8047') && matter && !openedFromTab) {
        const message = {
          matterId: ctrl.sbParamsAll.matterId,
        };

        dispatchCommand({ type: 'Billing.Matters.Messages.Commands.SaveRecentMatter', message }).catch(err => {
          log.error('Failed to save recent matter', err);
        });
      }
    };
    ctrl.isEditableMatter = isEditableMatter;
    ctrl.isDeletedMatter = isDeletedMatter;
    ctrl.isProcessingMatter = isProcessingMatter;
    ctrl.isMatterBillable = isMatterBillable;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.isNewTheme = isNewTheme();

    ctrl.confirmRestoreMatterModalId = confirmRestoreMatterModalId;
    ctrl.onClickRestoreMatter = onClickRestoreMatter;
    ctrl.onConfirmRestoreMatter = onConfirmRestoreMatter;
    ctrl.onCancelRestoreMatter = onCancelRestoreMatter;
    ctrl.commUnreads = 0;
    ctrl.archieSparkles = archieSparkles;

    ctrl.hasBillingAccess = hasBillingAccess();
    ctrl.hasMatterPermissions = featureActive('NUCWEB-118') ? false : true;
    ctrl.matterPermissionsChecked = featureActive('NUCWEB-118') ? false : true;
    ctrl.supportsOperatingAccount = hasFacet(facets.operatingAccount);
    ctrl.supportsCMA = hasFacet(facets.CMA) && featureActive('BB-6381');

    const checkMatterPermissions = async () => {
      const allowed = await checkMatterPermissionsAndDisplayErrorPopupP(ctrl.sbParamsAll.matterId, undefined, {
        onClick: () => {
          $state.go('home.billing.matters');
          sbMessageDisplayService.dismissGroup('matter-permissions');
        },
        text: 'Go to matter list',
      })
      ctrl.hasMatterPermissions = allowed;
      ctrl.matterPermissionsChecked = true;
      $scope.$applyAsync();
    }

    if (featureActive('NUCWEB-118')) {
      checkMatterPermissions();
    }

    // deprecated
    async function onConfirmRestoreMatter() {
      try {
        restoreDeletedMatter({ matterId: ctrl.sbParamsAll.matterId });
        setModalDialogHidden({ modalId: confirmRestoreMatterModalId });
      } catch (err) {
        messageDisplay.error('Failed to restore matter');
      }
    }

    // deprecated
    function onCancelRestoreMatter() {
      setModalDialogHidden({ modalId: confirmRestoreMatterModalId });
    }

    // deprecated
    function onClickRestoreMatter() {
      setModalDialogVisible({ modalId: confirmRestoreMatterModalId, props: { matterId: ctrl.sbParamsAll.matterId } });
    }

    // deprecated
    function isDeletedMatter() {
      const isDeleted = isMatterDeleted(ctrl.sbParamsAll.matterId);
      return isDeleted;
    }

    // deprecated
    function isEditableMatter() {
      // don't allow edit when it's
      // 1. statutory matter, or
      // 2. while a matter is being created/saved/restored/deleted, and
      // 3. matter is deleted
      return !ctrl.isStatutoryDepositMatter() && !isMatterSaving(ctrl.sbParamsAll.matterId) && !isDeletedMatter();
    }

    function isMatterBillable() {
      // In AU and GB, billing against a lead is disabled when there is no reference. Time and fees, transactions, etc are all disabled for these leads.
      return (ctrl.sbData.matter && !ctrl.sbData.matter.isLead) || hasFacet(facets.leadWithNoReferenceIsBillable) || (ctrl.sbData.matter && ctrl.sbData.matter.isLead && !!ctrl.sbData.matter.matterNumber);
    }

    function isProcessingMatter() {
      // while a matter is being created/saved/restored/deleted
      return isMatterSaving(ctrl.sbParamsAll.matterId)
    }

    const checkRetainerWarning = () => {
      const retainerBelowLimit = checkRetainer();
      const retainerStatusChanged = ctrl.view.trustRetainerBelowLimit !== retainerBelowLimit; // we only want to show the message box if the retainer status has changed between update events
      ctrl.view.trustRetainerBelowLimit = retainerBelowLimit;

      if (ctrl.view.trustRetainerBelowLimit) {
        if (retainerStatusChanged) {
          sbMessageDisplayService.warn(
            sbMessageDisplayService
              .builder()
              .text(`Matter ${sbLocalisationService.t('trust').toLowerCase()} balance is below threshold`)
              .action(() => {
                $state.go('home.billing.accounts.evergreen-requests', { request: 'all' });
                sbMessageDisplayService.dismissGroup('retainer-warning');
              })
              .actionText('See All')
              .group('retainer-warning')
          );
        }
      } else {
        sbMessageDisplayService.dismissGroup('retainer-warning');
      }
    }

    ctrl.view = {
      trustRetainerBelowLimit: checkRetainer(),
    };

    if ($state.current.data) {
      ctrl.sbRouteData = _.cloneDeep($state.current.data);

      if ($state.current.data.derivePersistanceKey) {
        persistanceKey = $state.current.data.derivePersistanceKey($state.current.name, ctrl.sbParams);
        ctrl.view = sbUnsavedChangesService.loadMemory(persistanceKey) || ctrl.view;
        checkRetainerWarning();
        findUnsavedChanges = $state.current.data.findUnsavedChanges;
      }
    }

    ctrl.toContactCard = toContactCard;
    ctrl.showMessage = showMessage;
    ctrl.isStatutoryDepositMatter = () => sbBankAccountService.isStatutoryDepositMatter(ctrl.sbParamsAll.matterId);
    ctrl.isLeadMatter = _isLeadMatter;
    ctrl.displayIntakeTab = () => {
      const isLead = _isLeadMatter();
      if (isLead) {
        return getIntakeConfig().enableForLeads;
      }
      return getIntakeConfig().enableForMatters;
    }
    ctrl.displayArchieTab = () => {
      return featureActive('NUCWEB-749');
    }
    ctrl.displayInfoTrackTab = () => {
      return featureActive('IN-1799');
    }

    ctrl.currentProductTier = getProductTier();

    const listeners = [
      $scope.$on('$destroy', () => {
        if (persistanceKey && findUnsavedChanges) {
          const changes = findUnsavedChanges(ctrl.view);
          sbUnsavedChangesService.saveMemory(persistanceKey, changes);
        }

        for (let unregister of listeners) unregister();
      }),
      $scope.$on('smokeball-data-update-sbTrustRetainersService', checkRetainerWarning),
      store.subscribe(() => {
        try {
          if (getProductTier() !== ctrl.currentProductTier) {
            ctrl.currentProductTier = getProductTier();
            ctrl.navItems = generateNavItems();
            $scope.$applyAsync();
          }
          const commUnreads = commSelectors.getUnreadConversationCounts(store.getState(), ctrl.sbParamsAll.matterId);
          if (commUnreads) {
            let count = commUnreads.notified;

            if (count === null) {
              count = commUnreads.polled || 0;
            }

            if (count && count > 9) {
              count = "9+";
            } 

            if (ctrl.commUnreads !== count) {
              ctrl.commUnreads = count;
              $scope.$applyAsync();
            }
          }
        } catch (e) {
          log.warn('error fetching communicate unreads')
        }
      }),
    ];

    ctrl.sbNavHighlightPath = $state.current.name;
    $scope.$on('$stateChangeSuccess', (event, state) => {
      // clone state params
      ctrl.sbParams = _.cloneDeep($stateParams);
      ctrl.sbParamsAll = _.cloneDeep($state.params);
      ctrl.sbParams.sbSubState = getSubSState(state.name);
      ctrl.sbData.stateName = $state.current.name;

      // set nav highlights
      ctrl.sbNavHighlights = {};
      if (state.data) {
        ctrl.sbRouteData = _.cloneDeep(state.data);

        if (state.data.navHighlights) {
          _.each(state.data.sbNavHighlights, highlight => (ctrl.sbNavHighlights[highlight] = true));
        }
      }

      if (state.name) {
        ctrl.sbNavHighlightPath = state.name;
      }
    });

    onInit();

    function onInit() {
      if (ctrl.sbData.stateName && ctrl.sbData.stateName !== 'home.billing.view-matter.transactions.trust' && ctrl.sbParamsAll.matterId && ctrl.isStatutoryDepositMatter()) {
        ctrl.onClickLink({ type: 'matterTransactions', id: 'trust', params: [{ matterId: ctrl.sbParamsAll.matterId, trustAccountId: 'all' }] });
      }
    }

    ctrl.dataChangeFunction = (key, doc) => {
      log.info('data change', key, doc);
      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }

      ctrl.navItems = generateNavItems();
    };

    function toContactCard(contactId) {
      log.info('going to contact.');
      $state.go('home.billing.view-contact.details', { contactId });
    }

    function checkRetainer() {
      const trustRetainerEntry = sbTrustRetainersService.getById(ctrl.sbParamsAll.matterId);
      const billingConfigEntry = sbBillingConfigurationService.getByMatterId(ctrl.sbParamsAll.matterId) || {};
      const matterDefaultConfigEntry = sbDefaultMatterBillingConfigurationService.getConfig() || {};

      return trustRetainerEntry && billingConfigEntry.minimumTrustRetainerActive && matterDefaultConfigEntry.minimumTrustRetainerActive ? !trustRetainerEntry.replenished : false;
    }

    function getSubSState(name) {
      var subState = name.match(/^.*\.([^.]*)$/);
      return subState ? subState[1] : name;
    }

    function showMessage(type, message, error) {
      if (!_.includes(['success', 'info', 'warn', 'error'], type)) {
        log.warn(`invalid message display type: ${type}`);
        return;
      }
      sbMessageDisplayService[type](buildMessage(message, error));
    }

    function buildMessage(msg, err) {
      return sbMessageDisplayService
        .builder()
        .text(msg)
        .conditionalText(': {0}', err && err.message);
    }

    function _isLeadMatter() {
      return isLeadMatter(ctrl.sbParamsAll.matterId);
    }

    // If making any changes here, consider the changes in the view for the legacy nav
    // The main difference is the order of the nav items
    const generateNavItems = () => {
      const navItems = [];
      const isBill = !featureActive('BB-13588');
      const isBillTier = ['SMK001'].includes(getProductTier());
      const isBar = isBarUser();
      const isBillFeatureLockOn = featureActive('NUCWEB-794');
      const showLock = isBillTier && isBillFeatureLockOn && isBar;
      if (featureActive('NUCWEB-208') && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.dashboard',
          display: 'Overview',
          className: 'dash-active',
          order: isBill ? 0 : 0, 
        });
      }
      if (ctrl.displayArchieTab()) {
        navItems.push({
          path: 'home.billing.view-matter.archie',
          display: 'Archie',
          order: isBill ? 1 : 1, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isStatutoryDepositMatter() && ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.fees',
          display: 'Time & Fees',
          order: isBill ? 2 : 4, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.fees',
          display: 'Time & Fees',
          disabled: true,
          linkClassName: 'unbillable-tooltip',
          tooltip: 'Please add a matter number if you wish to bill against this lead',
          order: isBill ? 2 : 4, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isStatutoryDepositMatter() && ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.expenses',
          display: sbLocalisationService.t('expenses'),
          order: isBill ? 3 : 5, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.expenses',
          display: sbLocalisationService.t('expenses'),
          disabled: true,
          linkClassName: 'unbillable-tooltip',
          tooltip: 'Please add a matter number if you wish to bill against this lead',
          order: isBill ? 3 : 5, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isStatutoryDepositMatter() && ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.bills',
          display: 'Invoices',
          order: isBill ? 4 : 6, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.bills',
          display: 'Invoices',
          disabled: true,
          linkClassName: 'unbillable-tooltip',
          tooltip: 'Please add a matter number if you wish to bill against this lead',
          order: isBill ? 4 : 6, 
        });
      }
      if (ctrl.hasBillingAccess && ctrl.sbData.trustAccounts && ctrl.sbData.trustAccounts.length && ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.transactions.trust',
          active: 'home.billing.view-matter.transactions',
          display: 'Transactions',
          order: isBill ? 5 : 7, 
        });
      }
      if (ctrl.hasBillingAccess && !ctrl.isMatterBillable()) {
        navItems.push({
          path: 'home.billing.view-matter.transactions',
          display: 'Transactions',
          disabled: true,
          linkClassName: 'unbillable-tooltip',
          tooltip: 'Please add a matter number if you wish to bill against this lead',
          order: isBill ? 5 : 7, 
        });
      }
      if (ctrl.hasBillingAccess && !(ctrl.sbData.trustAccounts && ctrl.sbData.trustAccounts.length) && ctrl.isMatterBillable() && ctrl.supportsOperatingAccount) {
        navItems.push({
          path: 'home.billing.view-matter.transactions.operating',
          active: 'home.billing.view-matter.transactions',
          display: 'Transactions',
          order: isBill ? 5 : 7, 
        });
      }
      if (ctrl.hasBillingAccess && !(ctrl.sbData.trustAccounts && ctrl.sbData.trustAccounts.length) && ctrl.isMatterBillable() && !ctrl.supportsOperatingAccount && ctrl.supportsCMA) {
        navItems.push({
          path: `home.billing.view-matter.transactions.controlled-money`,
          active: 'home.billing.view-matter.transactions',
          display: 'Transactions',
          order: isBill ? 5 : 7, 
        });
      }
      if (ctrl.displayIntakeTab() && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.intake',
          display: 'Intake',
          order: isBill ? 6 : 8, 
        });
      }
      if ((featureActive('NUCWEB-82') || showLock) && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.calendar',
          display: 'Events',
          order: isBill ? 7 : 9, 
          lock: showLock,
        });
      }
      if ((featureActive('NUCWEB-179') || showLock) && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.tasks',
          display: 'Tasks',
          order: isBill ? 8 : 10,
          lock: showLock,
        });
      }
      if ((featureActive('NUCWEB-70') || showLock) && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.documents',
          display: 'Documents',
          order: isBill ? 9 : 2, 
          lock: showLock,
        });
      }
      if ((featureActive('NUCWEB-71') || showLock) && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.memos',
          display: 'Memos',
          order: isBill ? 10 : 11,
          lock: showLock,
        });
      }
      if ((featureActive('NUCWEB-71') || showLock) && !ctrl.isStatutoryDepositMatter()) {
        navItems.push({
          path: 'home.billing.view-matter.communicate',
          display: 'Messages',
          linkClassName: 'badged-nav-pill',
          prefix: ctrl.commUnreads ? { className: 'communicate-badge-matter', display: ctrl.commUnreads } : undefined,
          order: isBill ? 11 : 3, 
          lock: showLock,
        });
      }
      if(ctrl.displayInfoTrackTab()) {
        navItems.push({
          path: 'home.billing.view-matter.infotrack',
          display: 'InfoTrack',
          order: 12, 
        });
      }

      return sortByProperty(navItems, "order");
    };
    ctrl.navItems = generateNavItems();

    // needed because we can't reset a state when searchOnReload is false
    ctrl.gotoOrReset = function (to) {
      if ($state.current.name !== to) {
        // not at state so let's go
        $state.go(to);
      } else {
        $scope.$broadcast('resetFilters');
        store.dispatch({ type: 'RESET_FILTERS' });
      }
    };

    /*------------------------------------------------------------------------------------------------------------
     * REMEMBER FEATURE
     * ----------------
     * define a top level parent state that IS NOT ABSTRACT, then given that child
     * states inherit data from parent states and the parent state has 'remember: true'
     * and also 'rememberParent: some.parent.state.name' then:
     *
     *  - if the FROM state has 'remember: true' then we need to save it against the key of rememberParent
     *
     *  - if the TO state has 'remember: true' and the TO state's name matches 'rememberParent' then
     *    this is a special case. Here we get the last child state for this parent that was remembered
     *    and we navigate there.
     *
     *  - In cases where we arrive at a parent that does not yet have any remembered child state
     *    we go to the 'rememberDefaultChild' if set
   -----------------------------------------------------------------------------------------------------------*/
    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState) => {
      const fromRem = !!_.get(fromState, 'data.remember');
      const toRem = !!_.get(toState, 'data.remember');
      const fromRoot = _.get(fromState, 'data.rememberParent');
      const toRoot = _.get(toState, 'data.rememberParent');
      const toDefault = _.get(toState, 'data.rememberDefaultChild');

      if (fromRem && fromRoot) {
        log.info(`remember exit ${fromState.name}`);
        sbUnsavedChangesService.saveMemory(
          `${fromRoot}.state.name`,
          fromState.name
        );
        sbUnsavedChangesService.saveMemory(
          `${fromRoot}.state.params`,
          fromState.params
        );
      }

      if (toRem && toState.name === toRoot) {
        log.info(`remember enter to:${toState.name}`);
        const restoreStateName = sbUnsavedChangesService.loadMemory(
          `${toRoot}.state.name`
        );
        const restoreStateParams = sbUnsavedChangesService.loadMemory(
          `${toRoot}.state.params`
        );
        log.info(`restore state name:${restoreStateName}`);
        if (_.isEmpty(restoreStateName)) {
          if (toDefault) {
            log.warn(`going to default:${toDefault}`);
            $state.go(toDefault); // NB state must support no params
          }
        } else {
          $state.go(restoreStateName, restoreStateParams);
        }
      }
    }
    );
  }
});
