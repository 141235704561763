import { getTrustAccount } from '@sb-billing/redux/bank-account';
import { store } from '@sb-itops/redux';
import { selectors as filterSelectors } from 'web/redux/route/home-billing-accounts-transactions';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions/trust';

  $stateProvider
    .state('home.billing.accounts.transactions.trust', {
      url: '/trust/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/trust-transactions.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.trust', { ...$stateParams }], parentPageName: PARENT_PAGE_NAME }));
      },
      params: {
        trustAccountId: null,
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
          trustAccountDetails: getTrustAccount(),
        })
      },
    });
});
