import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, useTranslation } from '@sb-itops/react';
import { ContactTypeIcon } from '../contact-type-icon';
import { ContactDisplay } from '../contact-display';

export const ContactTable = (props) => {
  const rowClick = ({ rowData }) => {
    const { onSelect } = props;
    const { entityId } = rowData;
    onSelect({ entityId });
  };

  const { contacts, sortDirection, sortBy, sort } = props;
  const { t } = useTranslation();

  return (
    <Table
      className="contact-table"
      onRowClick={rowClick}
      list={contacts}
      sort={sort}
      sortBy={sortBy}
      sortDirection={sortDirection}
    >
      <Column dataKey="displayName" label="Name" cellRenderer={displayNameCellRenderer} flexGrow={3} />
      <Column
        dataKey="representativeOfs"
        label={t('organisation')}
        cellRenderer={representativeOfsCellRenderer}
        flexGrow={3}
      />
      <Column dataKey="phone" label="Phone" flexGrow={1} disableSort />
      <Column dataKey="cell" label={t('mobile')} flexGrow={1} disableSort />
      <Column dataKey="email" label="Email" flexGrow={3} disableSort />
    </Table>
  );
};

function displayNameCellRenderer({ rowData }) {
  return (
    <>
      <ContactTypeIcon contactType={rowData.type} />
      <span />
      <ContactDisplay contactId={rowData.entityId} inline showLastNameFirst />
    </>
  );
}

function representativeOfsCellRenderer({ rowData }) {
  const { representativeOfs } = rowData;
  const size = representativeOfs.length - 1;
  return representativeOfs.map((rep, idx) => rep && rep.displayName + (idx === size ? '' : '; '));
}

ContactTable.defaultProps = {
  onSelect: undefined,
  sort: undefined,
  sortBy: 'displayName',
  sortDirection: 'asc',
  contacts: [],
};

ContactTable.propTypes = {
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  sort: PropTypes.func,
  contacts: PropTypes.array,
  onSelect: PropTypes.func,
};
