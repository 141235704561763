import { featureActive } from '@sb-itops/feature';

type TIntakeConfig = {
  showIntakeWidgets: boolean;
  showIntakeSettings: boolean;
  showIntakeFileMenuItem: boolean;
  enableForMatters: boolean;
  enableForLeads: boolean;
};

export const getIntakeConfig = (): TIntakeConfig => ({
  showIntakeWidgets: !!featureActive('NUCWEB-405'),
  showIntakeSettings: !!featureActive('NUCWEB-406'),
  showIntakeFileMenuItem: !!featureActive('NUCWEB-407'),
  enableForMatters: !!featureActive('NUCWEB-408'),
  enableForLeads: !!featureActive('NUCWEB-409'),
});
