import { Spinner } from '@sb-itops/react';
import { MattersDisplayFromMatters } from '@sb-matter-management/react';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import iconLead from 'web/assets/icon-lead.svg';
import { WidgetTitle, getTextSize } from './libs';
import * as WidgetStyles from './Widgets.module.scss';

export const LeadsWithNoActivityWidget = ({
  isEditing,
  leads,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  onRefresh,
  count,
}) => (
  <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
    <WidgetTitle
      isEditing={isEditing}
      onRefresh={onRefresh}
      onClick={() => onClickLink({ type: 'leadList' })}
      onChange={onChange}
      onRemove={onRemove}
      settings={settings}
    />
    {loading ? (
      <div className={WidgetStyles.loading}>
        <Spinner small />
      </div>
    ) : (
      [
        (!settings.style?.value || settings.style?.value === 'list') && (
          <div key="content" className={WidgetStyles.content}>
            {leads.length === 0 && 'No leads to display'}
            {leads.map((lead) => (
              <div
                key={lead.matter.id}
                className={WidgetStyles.itemCard}
                onClick={() => onClickLink({ type: 'matter', id: lead.matter.id })}
              >
                <div>
                  <img src={iconLead} alt="lead icon" className={`${WidgetStyles.largeIcon}`} />
                </div>
                <div className={WidgetStyles.textBox}>
                  <div className={classNames(WidgetStyles.overflowText)}>{lead.matter.clientDisplayName}</div>
                  <MattersDisplayFromMatters
                    onClickLink={onClickLink}
                    className={WidgetStyles.blueGray}
                    showClient
                    showOtherSide
                    matters={[lead.matter]}
                  />
                </div>
                <div
                  className={classNames(
                    WidgetStyles.overflowText,
                    WidgetStyles.alignRight,
                    WidgetStyles.noShrink,
                    WidgetStyles.blueGray,
                  )}
                >
                  {moment(lead.lastActivityDate).fromNow()}
                </div>
              </div>
            ))}
          </div>
        ),
        settings.style?.value === 'number' && (
          <div key="count" className={`${WidgetStyles.countContent} ${getTextSize(settings.size)}`}>
            <h1 className={WidgetStyles.hOne}>{count}</h1>
            LEADS
          </div>
        ),
      ]
    )}
  </div>
);
