import * as Yup from 'yup';

import { websiteSchema, addressLineSchema, last4SocialSecuritySchema } from './schemas/specificSchemas.yup';
import {
  routingNumberFieldSchema,
  socialSecurityFieldSchema,
  nationalIdFieldSchema,
  zipCodeFieldSchema,
} from './schemas/strategySchemas.yup';

export const websiteUrlError = 'Must be a valid URL and start with http:// or https://';
export const getMajorityOwnerDifferentErrorMessage = (t) =>
  `Must be at least 25% when Majority Owner is not different to the ${t('capitalize', {
    val: 'authorised',
  })} Representative`;
export const businessOwnershipError = 'Must be at least 25%';

export const feeWiseApplicationFormSchema = Yup.object().shape({
  firmDetails: Yup.object().shape({
    legalCompanyName: Yup.string().required(),
    tradingAs: Yup.string(),
    businessStructure: Yup.string().when('$localisationConfig.showBusinessStructure', {
      is: true,
      then: (businessStructure) => businessStructure.required(),
      otherwise: (businessStructure) => businessStructure.nullable().optional(),
    }),
    employerIdNumber: nationalIdFieldSchema,
    phoneNumber: Yup.string().required(),
    emailAddress: Yup.string().email().required(),
    website: websiteSchema.url(websiteUrlError).required(),
    streetAddressLine1: addressLineSchema.required(),
    streetAddressLine2: addressLineSchema,
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: zipCodeFieldSchema.required(),
  }),
  operatingAccountDetails: Yup.object().shape({
    accountHolderName: Yup.string().required(),
    accountName: Yup.string().required(),
    routingNumber: routingNumberFieldSchema.required(),
    accountNumber: Yup.string().required(),
    accountType: Yup.string().required(),
    nominateForMonthlyBilling: Yup.boolean().required(),
  }),
  trustAccountDetails: Yup.object()
    .shape({
      accountHolderName: Yup.string(),
      accountName: Yup.string(),
      routingNumber: routingNumberFieldSchema,
      accountNumber: Yup.string(),
      accountType: Yup.string(),
    })
    .when('$shouldValidateTrustAccount', {
      is: true,
      then: Yup.object().shape({
        accountHolderName: Yup.string().required(),
        accountName: Yup.string().required(),
        routingNumber: routingNumberFieldSchema.required(),
        accountNumber: Yup.string().required(),
        accountType: Yup.string().required(),
      }),
    }),
  authorizedRepresentative: Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    jobTitle: Yup.string().required(),
    phoneNumber: Yup.string().required(),
    emailAddress: Yup.string()
      .email()
      .required()
      // This must be function not () => {} or else we can't access this
      // eslint-disable-next-line func-names
      .test('authorizedRepresentative.emailAddress', 'Emails must be different', function (value) {
        const benEmail = this.options.context.formValues?.beneficialOwner?.emailAddress;
        return benEmail !== value;
      }),
    streetAddressLine1: addressLineSchema.required(),
    streetAddressLine2: addressLineSchema,
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: zipCodeFieldSchema.required(),
    dateOfBirth: Yup.date().required(),
    last4SocialSecurity: last4SocialSecuritySchema.when('$newFormFields', {
      is: (newFormFields) => newFormFields,
      then: (last4SocialSecurity) => last4SocialSecurity.nullable().optional(),
      otherwise: (last4SocialSecurity) => last4SocialSecurity.required(),
    }),
    socialSecurityNumber: socialSecurityFieldSchema,
    businessOwnership: Yup.number()
      .required()
      .positive()
      .integer()
      .min(0)
      .max(100)
      .when(['$beneficialOwnerDifferent', '$t'], (beneficialOwnerDifferent, t, originalSchema) => {
        if (!beneficialOwnerDifferent) {
          return Yup.number()
            .required()
            .positive()
            .integer()
            .min(25, getMajorityOwnerDifferentErrorMessage(t))
            .max(100);
        }
        return originalSchema;
      }),
  }),
  beneficialOwner: Yup.object()
    .shape({
      // When majority owner toggle is false, we don't care what they've previously entered into the form
      firstName: Yup.string(),
      lastName: Yup.string(),
      jobTitle: Yup.string(),
      phoneNumber: Yup.string(),
      emailAddress: Yup.string(),
      streetAddressLine1: Yup.string(),
      streetAddressLine2: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      dateOfBirth: Yup.date(),
      last4SocialSecurity: Yup.string(),
      socialSecurityNumber: Yup.string(),
      businessOwnership: Yup.number(),
    })
    .when('$beneficialOwnerDifferent', {
      is: true,
      then: Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        jobTitle: Yup.string().required(),
        phoneNumber: Yup.string().required(),
        emailAddress: Yup.string()
          .email()
          .required()
          // This must be function not () => {} or else we can't access this
          // eslint-disable-next-line func-names
          .test('beneficialOwner.emailAddress', 'Emails must be different', function (value) {
            const authEmail = this.options.context.formValues?.authorizedRepresentative?.emailAddress;
            return authEmail !== value;
          }),
        streetAddressLine1: addressLineSchema.required(),
        streetAddressLine2: addressLineSchema,
        city: Yup.string().required(),
        state: Yup.string().required(),
        zipCode: zipCodeFieldSchema.required(),
        dateOfBirth: Yup.date().required(),
        last4SocialSecurity: last4SocialSecuritySchema.when('$newFormFields', {
          is: (newFormFields) => newFormFields,
          then: (last4SocialSecurity) => last4SocialSecurity.nullable().optional(),
          otherwise: (last4SocialSecurity) => last4SocialSecurity.required(),
        }),
        socialSecurityNumber: socialSecurityFieldSchema,
        businessOwnership: Yup.number().required().positive().integer().min(25, businessOwnershipError).max(100),
      }),
    }),
  acknowledgementCheckbox: Yup.object().shape({
    confirmation: Yup.boolean().oneOf([true]),
  }),
});
