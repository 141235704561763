'use strict';

const { calculateRetainerRequestAmount } = require('./calculate-retainer-request-amount');
const { determineShowRetainerSetting } = require('./determine-show-retainer-setting');
const { getRetainerDefaults } = require('./get-retainer-defaults');
const { interpolateText } = require('./interpolate-text');
const {
  showRetainerOnInvoiceOptionLabels,
  showRetainerOnInvoiceOptions,
  showRetainerOnInvoiceOptionValues,
} = require('./show-retainer-on-invoice-options');

module.exports = {
  calculateRetainerRequestAmount,
  determineShowRetainerSetting,
  getRetainerDefaults,
  interpolateText,
  showRetainerOnInvoiceOptionLabels,
  showRetainerOnInvoiceOptions,
  showRetainerOnInvoiceOptionValues,
};
