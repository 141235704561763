'use strict';

/**
 * @typedef { import('../types').Activity } Activity
 */

const { entryType, durationType } = require('../../shared/entities');

/**
 * Returns the inferred duration type for the given activity.
 *
 * @param {Object} params
 * @param {Activity} params.activity
 * @returns {'HOURS'|'FIXED'}
 */
const getActivityDurationType = ({ activity }) => {
  if (activity.type === entryType.EXPENSE || activity.type === entryType.FIXED) {
    return durationType.FIXED;
  }

  return durationType.HOURS;
};

module.exports = {
  getActivityDurationType,
};
