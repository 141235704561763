import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import * as Styles from './Tooltip.module.scss';

export const FeeEstimateTooltip = ({ percentageFundsUsed, fundsUsed, estimate }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.content} onClick={(e) => e.stopPropagation()}>
      <div>
        <div>Total Estimate:</div>
        <div>Funds used:</div>
        <div>Percentage used:</div>
      </div>
      <div className={Styles.moneyColumn}>
        <div>{t('cents', { val: estimate })}</div>
        <div>{t('cents', { val: fundsUsed })}</div>
        <div>{Math.round(percentageFundsUsed * 10000) / 100}%</div>
      </div>
    </div>
  );
};

FeeEstimateTooltip.displayName = 'FeeEstimateTooltip';

FeeEstimateTooltip.propTypes = {
  percentageFundsUsed: PropTypes.number.isRequired,
  fundsUsed: PropTypes.number.isRequired,
  estimate: PropTypes.number.isRequired,
};
