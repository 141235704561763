import { getByPaymentId } from '@sb-billing/redux/transactions';

angular.module('sb.billing.webapp').component('sbDataTransactionById', {
  require: { compose: '^sbCompose' },
  bindings: { transactionId: '<', paymentId: '<', composeDataKey: '=?' },
  controller: function ($scope, sbTransactionService, sbLoggerService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataTransactionById');

    let txn;
    let initialized;

    $scope.$on('smokeball-data-update-sbTransactionService', update);

    ctrl.$onInit = () => {
      log.info('transactionId', ctrl.transactionId, 'paymentId', ctrl.paymentId);
      update();
      initialized = true;
    };

    ctrl.$onChanges = () => {
      if (initialized) {
        update();
      }
    };

    function update() {
      let newTxn = ctrl.transactionId && sbTransactionService.getById(ctrl.transactionId);

      if (!newTxn) {
        // trust transfers have 2 transactions linked by a payment id, for consistency we only want the from transaction
        newTxn = ctrl.paymentId && getByPaymentId(ctrl.paymentId).filter((tx) => tx.amount < 0)[0];
      }

      if (newTxn && newTxn !== txn) {
        txn = newTxn;
        ctrl.compose.setComposeData({ dataType: 'transaction', data: newTxn }, ctrl.composeDataKey || 'transaction');
      }
    }

  }
});
