import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import * as messageDisplay from '@sb-itops/message-display';
import * as forms from '@sb-itops/redux/forms2';
import PropTypes from 'prop-types';
import { getById as getBankAccountById, saveBankAccount } from '@sb-billing/redux/bank-account';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getMap as getMatterBalanceState } from '@sb-billing/redux/bank-account-balances';
import { bankAccountState, bankAccountSaveAction } from '@sb-billing/business-logic/bank-account/entities/constants';
import * as controlledMoneyAccountsFeature from 'web/redux/features/controlled-money-accounts';
import { ControlledMoneyAccountCloseReopenModal } from './ControlledMoneyAccountCloseReopenModal';
import { CloseReopenControlledMoneyAccountSchema } from '../controlled-money-account-close-reopen';

const scope = 'close-reopen-controlled-money-account';

const getControlledMoneyAccountsFeature = (state, matterId) => {
  const FEATURE_SCOPE = `controlled-money-accounts-${matterId}`;
  return withScopedFeature({ state, scope: FEATURE_SCOPE })(controlledMoneyAccountsFeature);
};

export const ControlledMoneyAccountCloseReopenModalContainer = withReduxProvider(
  ({ bankAccountId, matterId, onClose }) => {
    const { selectors: formSelectors, operations: formOperations } = useSelector((state) =>
      withScopedFeature({ state, scope }),
    )(forms);

    const { formSubmitting } = useSelector(formSelectors.getFormState);

    const matterBalance = getMatterBalance(getMatterBalanceState(), { matterId, bankAccountId });

    const initalAccountState = useRef(null);

    const bankAccount = getBankAccountById(bankAccountId);
    if (initalAccountState.current === null) {
      initalAccountState.current = bankAccount.state;
    }

    const accountIsOpen = initalAccountState.current === bankAccountState.OPEN;

    const { actions: cmaActions } = getControlledMoneyAccountsFeature(null, matterId);

    const dispatch = useDispatch();

    const lastOpenDate = bankAccount.accountStateHistory.length
      ? bankAccount.accountStateHistory[bankAccount.accountStateHistory.length - 1].changeDate
      : bankAccount.accountOpenedDate;

    const validateForm = () =>
      dispatch(
        formOperations.validateForm({
          schema: CloseReopenControlledMoneyAccountSchema,
          validateFn: (fieldValues) => {
            const formErrors = {};

            if (fieldValues.stateChangeDate < lastOpenDate) {
              formErrors.stateChangeDate = accountIsOpen
                ? 'The account closing date must be after the opening date'
                : 'The account open date must be after the closing date';
            }
            return formErrors;
          },
        }),
      );

    return (
      <ControlledMoneyAccountCloseReopenModal
        {...{
          scope,
          isSubmitDisabled: formSubmitting,
          accountIsOpen,
          accountBalance: matterBalance,
          onClose,
          bankAccountId,
          matterId,
          validateForm,
          lastOpenDate,
          onSubmit: async ({ event }) => {
            // REOPEN OR CLOSE
            event.preventDefault();
            await validateForm();

            try {
              await dispatch(
                formOperations.submitFormWithValidationP({
                  submitFnP: async (formFieldValues) => {
                    await changeAccountState({
                      toState: accountIsOpen ? bankAccountState.CLOSED : bankAccountState.OPEN,
                      bankAccountId,
                      formFieldValues,
                    });

                    if (accountIsOpen) {
                      // visibleClosedAccount id map to maintains display in side panel MatterTransactionsFilterPanel
                      dispatch(cmaActions.addToVisibleClosedIdMap({ cmaId: bankAccountId }));
                    } else {
                      // Open account
                      dispatch(cmaActions.removeFromVisibleClosedIdMap({ cmaId: bankAccountId }));
                    }

                    onClose();
                    messageDisplay.success(`Account ${accountIsOpen ? 'closed' : 'reopened'} successfully.`);
                  },
                }),
              );
            } catch (err) {
              messageDisplay.error(`Failed to ${accountIsOpen ? 'close' : 'reopen'} the account`);
            }
          },
        }}
      />
    );
  },
);
const changeAccountState = async ({ toState, bankAccountId, formFieldValues }) => {
  const { stateChangeDate, reason, internalNote } = formFieldValues;
  const bankAccount = getBankAccountById(bankAccountId);
  const {
    id,
    accountType,
    accountOpenedDate,
    bankName,
    accountName,
    branchNumber,
    accountNumber,
    beneficiaryIds,
    associatedMatterId,
  } = bankAccount;

  const action = toState === bankAccountState.OPEN ? bankAccountSaveAction.REOPEN : bankAccountSaveAction.CLOSE;

  await saveBankAccount({
    id,
    accountType,
    accountOpenedDate,
    bankName,
    accountName,
    branchNumber,
    accountNumber,
    beneficiaryIds,
    associatedMatterId,
    // state change
    state: toState,
    stateChangeDate,
    reason,
    internalNote,
    action,
  });
};

ControlledMoneyAccountCloseReopenModalContainer.displayName = 'ControlledMoneyAccountCloseReopenModalContainer';

ControlledMoneyAccountCloseReopenModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  matterId: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
};

export default ControlledMoneyAccountCloseReopenModalContainer;
