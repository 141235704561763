import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Pill.module.scss';

/**
 * A special UI component that looks like a pill
 * @param {object} params
 * @param {string} params.label statusLabel can be any arbitrary text
 * @param {string} params.className className can be provided to override the default colour scheme
 * e.g.
 * .special-pill {
 *   background-color: #A94442;
 *   color: white;
 *   border: 1px solid #A94442;
 * }
 */
export const Pill = memo(({ label, className }) => <span className={classNames(Styles.pill, className)}>{label}</span>);

Pill.displayName = 'Pill';

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string, // use className to override default status pill colour and/or styling
};

Pill.defaultProps = {
  className: '',
};
