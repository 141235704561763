import { utilsFactory as uiReduxStateFactory } from '@sb-itops/redux';
import { domain } from '../../domain';
import name from './name';
import { getCurrentInvoiceTemplate as getCurrentInvoiceTemplateState } from '../../selectors/current-invoice-template';

// importing ui actions
import {
  updateSelectedInvoiceTemplate as updateSelectedInvoiceTemplateState,
  updateSelectedInvoiceTemplateReducerEntry,
} from './actions/update-selected-invoice-template';

import {
  clearSelectedInvoiceTemplate as clearSelectedInvoiceTemplateState,
  clearSelectedInvoiceTemplateReducerEntry,
} from './actions/clear-selected-invoice-template';

import {
  updateSelectedInvoiceTemplateConfiguration as updateSelectedInvoiceTemplateConfigurationState,
  updateSelectedInvoiceTemplateConfigurationReducerEntry,
} from './actions/update-selected-invoice-template-configuration';

import {
  clearSelectedInvoiceTemplateConfiguration as clearSelectedInvoiceTemplateConfigurationState,
  clearSelectedInvoiceTemplateConfigurationReducerEntry,
} from './actions/clear-selected-invoice-template-configuration';

import { setDefaultState, setDefaultStateReducerEntry } from './actions/set-default-state';

// creating the redux state in the store
const { getState } = uiReduxStateFactory({
  domain,
  name,
  reducers: [
    setDefaultStateReducerEntry,
    updateSelectedInvoiceTemplateReducerEntry,
    updateSelectedInvoiceTemplateConfigurationReducerEntry,
    clearSelectedInvoiceTemplateConfigurationReducerEntry,
    clearSelectedInvoiceTemplateReducerEntry,
  ],
});

const buildUniqueId = (matterId, invoiceId) =>
  `${matterId ? `MatterId/${matterId}` : ''}${invoiceId ? `-InvoiceId/${invoiceId}` : ''}`;

// export actions
export const updateSelectedInvoiceTemplate = ({ matterId, invoiceId, templateId }) => {
  const state = getState();
  const uniqueId = buildUniqueId(matterId, invoiceId);
  updateSelectedInvoiceTemplateState(state, { uniqueId, templateId });
};

export const updateSelectedInvoiceTemplateConfiguration = ({ matterId, invoiceId, ...config }) => {
  const state = getState();
  const uniqueId = buildUniqueId(matterId, invoiceId);
  updateSelectedInvoiceTemplateConfigurationState(state, { uniqueId, ...config });
};

export const clearSelectedInvoiceTemplate = ({ matterId, invoiceId }) => {
  const state = getState();
  const uniqueId = buildUniqueId(matterId, invoiceId);
  clearSelectedInvoiceTemplateState(state, { uniqueId });
};

export const initSelectedInvoiceTemplate = ({ matterId, invoiceId }) => {
  const state = getState();
  const uniqueId = buildUniqueId(matterId, invoiceId);
  if (!state[uniqueId]) {
    clearSelectedInvoiceTemplateState(state, { uniqueId });
  }
};

export const clearSelectedInvoiceTemplateConfiguration = ({ matterId, invoiceId }) => {
  const state = getState();
  const uniqueId = buildUniqueId(matterId, invoiceId);
  clearSelectedInvoiceTemplateConfigurationState(state, { uniqueId });
};

// export selectors
export const getSelectedInvoiceTemplate = ({ matterId, invoiceId }) => {
  const state = getState()[buildUniqueId(matterId, invoiceId)];
  if (state) {
    return state.selectedInvoiceTemplate;
  }
  return null;
};

export const getSelectedInvoiceTemplateConfiguration = ({ matterId, invoiceId }) => {
  const state = getState()[buildUniqueId(matterId, invoiceId)];
  if (state) {
    return state.selectedInvoiceTemplateConfiguration;
  }
  return null;
};

// this selector will also evaluate the ui state
export const getCurrentInvoiceTemplate = ({ matterId, invoiceId }) =>
  getCurrentInvoiceTemplateState(
    matterId,
    invoiceId,
    getSelectedInvoiceTemplate({ matterId, invoiceId }),
    getSelectedInvoiceTemplateConfiguration({ matterId, invoiceId }),
  );

// dispatch initial action
setDefaultState();
