/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InputNumberFilter, SlidingToggle, StackedItemSelector, PanelExpander, useTranslation } from '@sb-itops/react';

const SCOPE = 'evergreen-requests';

const EvergreenRequestsSidePanel = memo(
  ({
    onToggleHideMattersAboveMinimumThreshold,
    onChangeFilter,
    onToggleRequestOlderThanFilter,
    onShowIfReplenishAmountGreaterThanAmount,
    onToggleReplenishAmountFilter,
    onHideIfLastRequestOlderThanDaysChange,
    onResetFilters,
    expanded,
    toggleExpanded,
    filters,
    filterOptions,
  }) => {
    const { t } = useTranslation();
    if (!filterOptions || !filters) {
      return null;
    }
    return (
      <div className="evergreen-requests-side-panel">
        <PanelExpander
          className="panel-filter"
          onResetFilters={onResetFilters}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        >
          <div className="side-menu-block">
            <h3>MATTERS</h3>
            <ul className="side-menu-list">
              <StackedItemSelector
                items={filterOptions.request.map((item) => ({
                  id: item.value || item.display || item,
                  label: item.display || item,
                }))}
                selectedItemId={filters.request}
                onItemSelected={(selectedItem) => onChangeFilter('request', selectedItem)}
              />
            </ul>
          </div>

          <div className="side-menu-block">
            <h3>EVERGREEN STATUS</h3>
            <ul className="side-menu-list">
              <li>
                <div className="toggle-filters">
                  <span
                    onClick={(event) => {
                      onToggleHideMattersAboveMinimumThreshold(!filters.hideMattersAboveMinimumThreshold);
                      event.stopPropagation();
                    }}
                  >
                    Hide matters above min. threshold
                  </span>
                  <div>
                    <SlidingToggle
                      scope={SCOPE}
                      name="hideMattersAboveMinimumThreshold"
                      selected={filters.hideMattersAboveMinimumThreshold}
                      onChange={(key, value) => onToggleHideMattersAboveMinimumThreshold(value)}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="toggle-filters">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleRequestOlderThanFilter(!filters.enableRequestOlderThanFilter);
                    }}
                  >
                    <span>Hide if last request older than</span>
                    <InputNumberFilter
                      step={1}
                      value={filters.hideIfLastRequestOlderThanDays}
                      onChange={onHideIfLastRequestOlderThanDaysChange}
                      classNames="hide-if-last-request-older-than"
                    />
                    <span>days</span>
                  </div>
                  <div>
                    <SlidingToggle
                      scope={SCOPE}
                      name="enableRequestOlderThanFilter"
                      selected={filters.enableRequestOlderThanFilter}
                      onChange={(key, value) => onToggleRequestOlderThanFilter(value)}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="toggle-filters">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleReplenishAmountFilter(!filters.enableReplenishAmountFilter);
                    }}
                  >
                    <span>Show if replenish amount greater than {t('currencySymbol')}</span>
                    <InputNumberFilter
                      step={1}
                      value={filters.showIfReplenishAmountGreaterThanAmount}
                      onChange={onShowIfReplenishAmountGreaterThanAmount}
                      classNames="show-if-replenish-amount-greater-than"
                      noScroll
                    />
                  </div>
                  <div>
                    <SlidingToggle
                      scope={SCOPE}
                      name="enableReplenishAmountFilter"
                      selected={filters.enableReplenishAmountFilter}
                      onChange={(key, value) => onToggleReplenishAmountFilter(value)}
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="side-menu-block">
            <h3>UNPAID</h3>
            <ul className="side-menu-list">
              <StackedItemSelector
                items={filterOptions.unpaid.map((item) => ({ id: item.value, label: item.display(t) }))}
                selectedItemId={filters.unpaid}
                onItemSelected={(selectedItem) => onChangeFilter('unpaid', selectedItem)}
              />
            </ul>
          </div>
        </PanelExpander>
      </div>
    );
  },
);

EvergreenRequestsSidePanel.displayName = 'EvergreenRequestsSidePanel';

EvergreenRequestsSidePanel.propTypes = {
  filters: PropTypes.object,
  filterOptions: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  onToggleHideMattersAboveMinimumThreshold: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onToggleRequestOlderThanFilter: PropTypes.func.isRequired,
  onShowIfReplenishAmountGreaterThanAmount: PropTypes.func.isRequired,
  onToggleReplenishAmountFilter: PropTypes.func.isRequired,
  onHideIfLastRequestOlderThanDaysChange: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
};

EvergreenRequestsSidePanel.defaultProps = {
  filters: undefined,
  filterOptions: undefined,
};

export default EvergreenRequestsSidePanel;
