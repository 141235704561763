import React from 'react';
import { Button, Modal } from '@sb-itops/react';
import { Person } from 'types';
import Styles from './AddTaskModal.module.scss';
import { AddPhoneMessageContainer } from './AddPhoneMessageContainer';
import { IAddPhoneMessageProps } from './AddPhoneMessage';

export interface IAddPhoneMessageModalProps extends IAddPhoneMessageProps {
  onClose: () => void;
  onSubmit: () => void;
  onSubmitSaveAndNew: () => void;
  completer?: Person;
  isRemoved?: boolean;
}

export const AddPhoneMessageModal = (props: IAddPhoneMessageModalProps) => (
  <Modal
    className={Styles.addTaskModal}
    title="New Phone Message"
    body={<AddPhoneMessageContainer {...props} />}
    footer={
      <div className={Styles.footer}>
        <div className={Styles.saveButtons}>
          <div className="row">
            <div className="col-lg-4">
              <Button size="full-width" onClick={props.onSubmit} disabled={props.loading} locked={props.loading}>
                Save
              </Button>
            </div>
            <div className="col-lg-4">
              <Button
                size="full-width"
                onClick={props.onSubmitSaveAndNew}
                disabled={props.loading}
                locked={props.loading}
                type="secondary"
              >
                Save & New
              </Button>
            </div>
          </div>
        </div>
      </div>
    }
    onModalClose={props.onClose}
  />
);
