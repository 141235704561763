'use strict';

const { titleLineTypes } = require('./title-line-types');
const { interpolateCustomTextTitle } = require('./interpolate-custom-text-title');

const generateInvoiceTitle = ({
  t,
  titleLineOption,
  titleLineCustomText,
  matterNumber,
  matterClientDisplay,
  matterTypeName,
  matterTitle,
  matterDescription,
}) => {
  switch (titleLineOption) {
    case titleLineTypes.NONE:
      return '';

    case titleLineTypes.CUSTOM:
      return interpolateCustomTextTitle({
        t,
        text: titleLineCustomText || '',
        matterNumber,
        matterClientString: matterClientDisplay,
        matterTypeName,
        matterDescription,
      });

    case titleLineTypes.MATTER_DESCRIPTION:
      return matterDescription;

    case titleLineTypes.MATTER_TITLE:
    default:
      return matterTitle;
  }
};

module.exports = {
  generateInvoiceTitle,
};
