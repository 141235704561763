'use strict';

// Interpolation options
// https://www.i18next.com/translation-function/interpolation

module.exports = {
  // default values when we interpolate given variable
  defaultVariables: {
    format: 'DD/MM/YYYY',
  },
};