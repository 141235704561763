'use strict';

import { getLogger } from '@sb-itops/fe-logger';

angular.module('sb.billing.webapp').component('sbStripeOnboardingReturn', {
  templateUrl: 'ng-components/stripe-onboarding-return/stripe-onboarding-return.html',
  controller: function ($scope, $stateParams, sbGenericEndpointService) {
    const ctrl = this;
    const log = getLogger('sbStripeOnboardingReturn');

    // Update the status and force a digest cycle
    function changeStatus(status) {
      $scope.$applyAsync(() => {
        ctrl.status = status;
      });
    }

    (async function refreshStripeAccountDataP() {
      try {
        changeStatus('LOADING');

        const body = { connectionStep: 'RETURN_FROM_ONBOARDING', stripeAccountId: $stateParams.stripeAccountId };
        await sbGenericEndpointService.postPayloadP('/billing/payment-provider/connect/stripe', undefined, body);

        changeStatus('SUCCESS');

      } catch (err) {
        changeStatus('FAILURE');
        log.error('Failed to handle return from stripe onboarding', err);
      }
    })();
  },
});
