'use strict';

const applyEmailTitle = ({ emailTitle = '', emailBody = '' } = {}) => {
  const newEmailBody = `<span class="email-title">${emailTitle}</span>
<br />
<br />
${emailBody}
`;
  return newEmailBody;
};

module.exports = {
  applyEmailTitle,
};
