import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './PaginationSelector.module.scss';

export const PaginationSelector = ({
  className,
  hidePagination,
  selectedPage,
  totalNumberOfPages,
  numberOfPagesDisplayed,
  numberOfMarginPages,
  nextLabel,
  previousLabel,
  onPageChange,
}) =>
  hidePagination ? null : (
    <div className={classnames(Styles.paginationSelector, className)}>
      <ReactPaginate
        previousLabel={previousLabel}
        breakLabel="..."
        nextLabel={nextLabel}
        forcePage={selectedPage}
        onPageChange={onPageChange}
        pageRangeDisplayed={numberOfPagesDisplayed || totalNumberOfPages}
        marginPagesDisplayed={numberOfMarginPages}
        pageCount={totalNumberOfPages}
        pageClassName={Styles.pageItem}
        pageLinkClassName={Styles.pageLink}
        previousClassName={Styles.pageItem}
        previousLinkClassName={Styles.pageLink}
        nextClassName={Styles.pageItem}
        nextLinkClassName={Styles.pageLink}
        breakClassName={Styles.pageItem}
        breakLinkClassName={Styles.pageLink}
        containerClassName={Styles.paginatorContainer}
        activeClassName={Styles.selected}
        renderOnZeroPageCount={null}
      />
    </div>
  );

PaginationSelector.displayName = 'PaginationSelector';

PaginationSelector.propTypes = {
  className: PropTypes.string,
  hidePagination: PropTypes.bool,
  selectedPage: PropTypes.number,
  totalNumberOfPages: PropTypes.number.isRequired,
  numberOfPagesDisplayed: PropTypes.number,
  numberOfMarginPages: PropTypes.number,
  nextLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  previousLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onPageChange: PropTypes.func.isRequired,
};

PaginationSelector.defaultProps = {
  className: undefined,
  hidePagination: undefined,
  selectedPage: -1,
  numberOfMarginPages: 2,
  numberOfPagesDisplayed: undefined,
  nextLabel: <i className="fa fa-angle-right" />,
  previousLabel: <i className="fa fa-angle-left" />,
};
