/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ContactCreateEditModal } from 'web/react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { featureActive } from '@sb-itops/feature';
import { StatusPill, useTranslation } from '@sb-itops/react';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { DeleteContactModalDialog } from '@sb-billing/react/delete-contact-modal-dialog';
import { RestoreContactModalDialog } from '@sb-billing/react/restore-contact-modal-dialog';

import classNames from 'classnames';
import Styles from './BillingViewContactDetailsRoute.module.scss';

export const BillingViewContactDetailsRoute = memo((props) => {
  const {
    loading,
    onClickLink,
    setActiveAddressType,
    activeAddressType,
    activeAddress,
    contactCards,
    addresses,
    map,
    marker,
    editContactId,
    setEditContactId,
    showContactModal,
    setShowContactModal,
    deleteContactModalId,
    onDeleteContact,
    showDeleteModal,
    restoreContactModalId,
    onRestoreContact,
    showRestoreModal,
  } = props;

  const { t } = useTranslation();

  if (loading) {
    return null;
  }

  const primaryContact = contactCards?.[0] || {};

  function renderContactCardActions(type) {
    return type !== 'groupofpeople';
  }

  return (
    <div className={classNames('master-detail-panel', Styles.container)}>
      <div className={classNames(Styles.entityDataDisplay, Styles.contactCards)}>
        <div className={Styles.cardsPanel}>
          <div>
            <ul className={Styles.listInline}>
              {contactCards.map((card) => (
                <li key={card.id} className={Styles[card.type]}>
                  <div className={Styles.well}>
                    <div
                      className={classNames(
                        Styles.body,
                        Styles.card,
                        !renderContactCardActions(card.type) && Styles.noFooter,
                      )}
                    >
                      <div className="row">
                        <div className="col-sm-10">
                          <a
                            onClick={() => {
                              onClickLink({ type: 'contact', id: card.id });
                            }}
                            className={Styles.cardNameLink}
                          >
                            <div className={Styles.cardNameContainer}>
                              <p className={Styles.cardName}>{card.displayName}</p>
                              {featureActive('BB-7912') && card.removed && <StatusPill statusLabel="Deleted" />}
                            </div>
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        <div className={classNames('col-md-4', Styles.cardLabel)}>Phone:</div>
                        <div className={classNames('col-md-8', Styles.cardPhone)}>
                          <a href={`tel:${card.phone || card.phone2}`}>{card.phone || card.phone2}</a>
                        </div>
                      </div>

                      <div className="row">
                        <div className={classNames('col-md-4', Styles.cardLabel)}>
                          <span className="capitalise">{t('mobile')}</span>:
                        </div>
                        <div className={classNames('col-md-8', Styles.cardCell)}>
                          <a href={`tel:${card.cell}`}>{card.cell}</a>
                        </div>
                      </div>

                      <div className="row">
                        <div className={classNames('col-md-4', Styles.cardLabel)}>Email:</div>
                        <div className={classNames('col-md-8', Styles.cardEmail)}>
                          <a className={Styles.cardEmailLink} href={`mailto:${card.email}`} title={card.email}>
                            {card.email}
                          </a>
                        </div>
                      </div>

                      {!!card?.representativeOfs?.length && (
                        <div className="row">
                          <div className={classNames('col-md-4', Styles.cardLabel)}>Of:</div>
                          <div className={classNames('col-md-8', Styles.cardOrganization)}>
                            {card.representativeOfs?.map((org, index) => (
                              <span key={org.id}>
                                <a
                                  onClick={() => {
                                    onClickLink({ type: 'contact', id: org.id });
                                  }}
                                >
                                  {org.displayName}
                                </a>
                                {index < card.representativeOfs.length - 1 && <span key={index}> | </span>}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    {renderContactCardActions(card.type) && featureActive('BB-7677') && !card.removed && (
                      <>
                        <hr className={Styles.separator} />
                        <div className={Styles.footer}>
                          <LinkableText
                            className={Styles.item}
                            text="EDIT"
                            onClickLink={() => {
                              props.setEditContactId(card.id);
                              props.setShowContactModal(true);
                            }}
                            asLink
                            inline
                          />
                          <LinkableText
                            className={Styles.item}
                            text="DELETE"
                            onClickLink={() => showDeleteModal(card.id)}
                            asLink
                            inline
                          />
                        </div>
                      </>
                    )}
                    {renderContactCardActions(card.type) &&
                      featureActive('BB-7677') &&
                      card.removed &&
                      featureActive('BB-7912') && (
                        <>
                          <hr className={Styles.separator} />
                          <div className={Styles.footer}>
                            <LinkableText
                              className={Styles.item}
                              text="RESTORE"
                              onClickLink={() => showRestoreModal(card.id)}
                              asLink
                              inline
                            />
                          </div>
                        </>
                      )}
                    {(!featureActive('BB-7677') ||
                      (featureActive('BB-7677') && card.removed && !featureActive('BB-7912'))) && (
                      <div className={Styles.noFooter} />
                    )}
                  </div>
                </li>
              ))}
              {['organisation', 'trust'].includes(primaryContact?.type) && !primaryContact?.removed && (
                <div className={classNames('link-button', Styles.addContact)} onClick={() => setShowContactModal(true)}>
                  <i className="icon icon-add-3" />
                  ADD CONTACT
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="panel-body">
        <div className={Styles.contactAddresses}>
          <div className="sb-tab-header">
            <ul className={classNames('nav', 'nav-tabs')}>
              {addresses.map((address) => (
                <li
                  key={address.name}
                  role="presentation"
                  className={address.type === activeAddressType ? 'active' : ''}
                >
                  <a
                    onClick={() => {
                      setActiveAddressType(address.type);
                    }}
                  >
                    {address.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className={Styles.addressTab}>
            <div className={Styles.addressSummary}>
              {activeAddress?.address?.map((addressLine, index) => (
                <span className={Styles.addressLine} key={index}>
                  {index === 0 && <i className={classNames('fa', 'fa-map-marker', Styles.faMapMarker)} />}
                  {addressLine}
                </span>
              ))}
            </div>
            <div className={Styles.mapView}>
              {map && (
                <GoogleMap
                  center={map.center}
                  zoom={map.zoom}
                  mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Marker position={marker.coords} id={marker.id} />
                </GoogleMap>
              )}
            </div>
            <ContactCreateEditModal
              isVisible={showContactModal}
              onClose={() => {
                setEditContactId('');
                setShowContactModal(false);
              }}
              onClickLink={onClickLink}
              contactId={editContactId}
              initialOrg={primaryContact.id !== editContactId ? primaryContact.id : undefined}
            />
            <DeleteContactModalDialog deleteContactModalId={deleteContactModalId} onDeleteContact={onDeleteContact} />
            <RestoreContactModalDialog
              restoreContactModalId={restoreContactModalId}
              onRestoreContact={onRestoreContact}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

BillingViewContactDetailsRoute.displayName = 'BillingViewContactDetailsRoute';
BillingViewContactDetailsRoute.propTypes = {
  setActiveAddressType: PropTypes.func.isRequired,
  activeAddressType: PropTypes.string,
  activeAddress: PropTypes.object,
  contactCards: PropTypes.array,
  org: PropTypes.object,
  addresses: PropTypes.array,
  map: PropTypes.object,
  marker: PropTypes.object,
  center: PropTypes.object,
  id: PropTypes.string,
  coords: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onClickLink: PropTypes.func,
  editContactId: PropTypes.string,
  setEditContactId: PropTypes.func,
  showContactModal: PropTypes.bool,
  setShowContactModal: PropTypes.func,
  deleteContactModalId: PropTypes.string.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.func.isRequired,
  restoreContactModalId: PropTypes.string.isRequired,
  onRestoreContact: PropTypes.func.isRequired,
  showRestoreModal: PropTypes.func.isRequired,
};

BillingViewContactDetailsRoute.defaultProps = {
  loading: false,
  error: {},
  onClickLink: () => {},
  activeAddressType: '',
  activeAddress: {},
  contactCards: [],
  org: undefined,
  addresses: [],
  map: undefined,
  marker: undefined,
  center: undefined,
  id: '',
  coords: undefined,
  editContactId: undefined,
  setEditContactId: undefined,
  showContactModal: undefined,
  setShowContactModal: undefined,
};
