import {
  TOGGLE_SHOW_FILTERS,
  SET_FILTER,
  ADD_TO_VISIBLE_CLOSED_ID_MAP,
  REMOVE_FROM_VISIBLE_CLOSED_ID_MAP,
} from './types';

export const setFilter = ({ filterName, filterValue }) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});

export const addToVisibleClosedIdMap = ({ cmaId }) => ({
  type: ADD_TO_VISIBLE_CLOSED_ID_MAP,
  payload: { cmaId },
});

export const removeFromVisibleClosedIdMap = ({ cmaId }) => ({
  type: REMOVE_FROM_VISIBLE_CLOSED_ID_MAP,
  payload: { cmaId },
});
