import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';

/**
 * Find the payment plans for a particular debtor.
 * @param {Array.<object>} paymentPlansList -  a list of the payment plans
 * @param {object} options - findPaymentPlansByDebtorId options.
 * @param {string} options.debtorId -  a debtor id to get the pps.
 * @param {('Active'|'Closed')} [options.status] -  the pp status.
 * @returns {object|undefined} the latest pp for a given debtor
 * @throws if paymentPlansList is falsy
 * @throws if debtorId is falsy
 */
export const findPaymentPlansByDebtorId = (paymentPlansList, { debtorId, status }) => {
  if (!debtorId) {
    throw new Error(`debtorId cannot be falsy`);
  }
  if (!paymentPlansList) {
    throw new Error(`paymentPlansList cannot be falsy`);
  }

  return paymentPlansList.filter((pp) => pp.debtorId === debtorId && (status ? pp.status === status : true));
};

/**
 * Find the latest activepayment plan for a particular debtor.
 * @param {Array.<object>} paymentPlansList -  a list of the payment plans
 * @param {object} options - findActivePaymentPlanByDebtorId options.
 * @param {string} options.debtorId -  a debtor id to get the pps.
 * @returns {object|undefined} the latest pp for a given debtor
 * @throws if paymentPlansList is falsy
 * @throws if debtorId is falsy
 */
export const findActivePaymentPlanByDebtorId = (paymentPlansList, { debtorId }) => {
  if (!debtorId) {
    throw new Error(`debtorId cannot be falsy`);
  }
  if (!paymentPlansList) {
    throw new Error(`paymentPlansList cannot be falsy`);
  }

  const paymentPlanResult = findPaymentPlansByDebtorId(paymentPlansList, {
    debtorId,
    status: paymentPlanStatuses.ACTIVE,
  });

  return paymentPlanResult[0];
};

/**
 * Find the latest activepayment plan for a particular matter.
 * @param {Array.<object>} paymentPlansList -  a list of the payment plans
 * @param {object} options - findActivePaymentPlanByMatterId options.
 * @param {string} options.matterId -  a matter id to get the pps.
 * @returns {object|undefined} the latest pp for a given matter
 * @throws if paymentPlansList is falsy
 * @throws if matterId is falsy
 */
export const findActivePaymentPlanByMatterId = (paymentPlansList, { matterId }) => {
  if (!matterId) {
    throw new Error(`matterId cannot be falsy`);
  }
  if (!paymentPlansList) {
    throw new Error(`paymentPlansList cannot be falsy`);
  }

  const paymentPlanResult = paymentPlansList.filter(
    (pp) => pp?.matterIds?.includes(matterId) && pp.status === paymentPlanStatuses.ACTIVE,
  );

  return paymentPlanResult[0];
};

/**
 * Get all the payment plans under a particular invoice. we can have one active and multiple closed.
 * @param {Array.<object>} paymentPlansList -  a list of the payment plans
 * @param {object} options - findPaymentPlansByDebtorId options.
 * @param {Array.<string>} options.invoiceIds -  a collection or invoicesIds
 * @param {('Active'|'Closed')} [options.status] -  the pp status.
 * @returns {Array.<object>|undefined} all the payment plan with any invoice in the invoiceIds
 * @throws if paymentPlansList is falsy
 * @throws if invoiceIds is falsy or not an array
 */
export const findPaymentPlansByInvoiceIds = (paymentPlansList, { invoiceIds, status }) => {
  if (!invoiceIds && !Array.isArray(invoiceIds)) {
    throw new Error(`invoiceIds cannot be falsy and must be an array`);
  }
  if (!paymentPlansList) {
    throw new Error(`paymentPlansList cannot be falsy`);
  }

  return paymentPlansList.filter(
    (pp) =>
      (status ? pp.status === status : true) &&
      pp.invoiceIds &&
      pp.invoiceIds.some((invoiceId) => invoiceIds.includes(invoiceId)),
  );
};

/**
 * Get all the payment plans that are active from the payment plan list
 * @param {Array.<object>} paymentPlansList -  a list of the payment plans
 * @returns {Array.<object>|undefined} all the payment plan that are active from the giver list
 * @throws if paymentPlansList is falsy
 */
export const findActivePaymentPlans = (paymentPlansList) => {
  if (!paymentPlansList) {
    throw new Error(`paymentPlansList cannot be falsy`);
  }

  return paymentPlansList.filter((pp) => pp.status === paymentPlanStatuses.ACTIVE);
};
