import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import domain from '../domain';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoiceSettingsTemplateFilesService',
  keyPath: 'id',
});

// Setting up Cache
const { getMap, updateCache, clearCache } = cacheFactory({
  domain,
  name: 'invoice-settings-template-files',
  keyPath: 'id',
  ngCacheName: 'sbInvoiceSettingsTemplateFilesService',
  syncType: syncTypes.SINCE,
});

export { getMap, updateCache, clearCache, opdateCache, rollbackOpdateCache };
