/* eslint-disable no-undef */

const transformToMb = (value) => value / (1024 * 1024);

const calculateStorageQuotasP = async () => {
  if (!navigator.storage || !navigator.storage.estimate) {
    return undefined;
  }

  const { usage, quota } = await navigator.storage.estimate();
  return {
    usage,
    usageInMb: transformToMb(usage),
    quota,
    quotaInMb: transformToMb(quota),
    percentUsed: quota > 0 ? (usage / quota) * 100 : 100,
  };
};

const isIncognitoModeP = () => {
  const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
  if (!fs) {
    return true;
  }

  return new Promise((resolve) => {
    fs(
      window.TEMPORARY,
      100,
      () => resolve(false),
      () => resolve(true),
    );
  });
};

const isStorageQuotaSufficientP = async () => {
  const { usageInMb, quotaInMb } = await calculateStorageQuotasP();
  const isIncognitoMode = await isIncognitoModeP();
  return isIncognitoMode || quotaInMb - usageInMb > 300;
};

module.exports = {
  calculateStorageQuotasP,
  isStorageQuotaSufficientP,
};
