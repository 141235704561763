import { gql } from '@apollo/client';

const query = gql`
  query ExpenseCounts($filter: ExpenseListFilter) {
    expenseCounts(filter: $filter) {
      disbursements
      anticipatedDisbursements
      anticipatedDisbursementsUnpaid
      anticipatedDisbursementsOverdue
      anticipatedDisbursementsPaid
    }
  }
`;

const notificationIds = [
  'WebQueryExpensesNotifications.ExpenseUpdated',
  'WebQueryExpensesNotifications.ExpensePaymentDetailsUpdated',
  'WebQueryInvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ExpenseCounts = {
  query,
  notificationIds,
};
