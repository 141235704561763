import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { getSettings as getInvoiceEmailSettings } from '@sb-billing/redux/invoice-email-settings';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import * as forms from '@sb-itops/redux/forms2';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import { isPayButtonEnabledForFirmAndMatter } from '@sb-billing/redux/matter-invoice-settings';

import { invoiceEmailFormSchema } from './invoice-email-form-schema';
import { InvoiceEmailForm } from '../../../components/invoice-email-form';

const mapStateToProps = (state, { scope, invoiceIds, debtorIds, matterIds, consolidatedMode, onPreview }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields } = formSelectors.getFormState(state);
  const {
    toAddress,
    readOnlyToAddress,
    readOnlyCcBcc,
    fromAddress,
    ccAddress,
    bccAddress,
    staffAddress,
    sendCopyToMe,
    combineAttachments,
    subject,
    message,
  } = formFields;

  const payButtonSettingForMatters = matterIds.reduce(
    (acc, matterId) => {
      if (isPayButtonEnabledForFirmAndMatter({ matterId })) {
        acc.oneMatterHasPayButtonEnabled = true;
      } else {
        acc.oneMatterHasPayButtonDisabled = true;
      }
      return acc;
    },
    {
      oneMatterHasPayButtonEnabled: null,
      oneMatterHasPayButtonDisabled: null,
    },
  );

  const mappedState = {
    isLoading: !formInitialised,
    readOnlyToAddress,
    readOnlyCcBcc,
    toAddress,
    fromAddress,
    ccAddress,
    bccAddress,
    staffAddress,
    sendCopyToMe,
    showCombineAttachments: hasFacet(facets.combineAttachment) && consolidatedMode, // Combining attachments only supported in consolidated mode.
    combineAttachments,
    subject,
    message,
    showPreviewButton: (invoiceIds.length === 1 || consolidatedMode) && debtorIds.length === 1,
    // If at least one of the selected invoice's matters have enabled pay buttons, we show the pay button in the modal message. However, if one of the selected invoices have disabled pay buttons, we inform them that these invoice emails will not show the pay button.
    showDisabledPayButtonsMessage: !!(
      payButtonSettingForMatters.oneMatterHasPayButtonEnabled &&
      payButtonSettingForMatters.oneMatterHasPayButtonDisabled
    ),
    onPreview: () => {
      const [invoiceEmailRequest] = convertToInvoiceEmailRequests({
        state,
        scope,
        invoiceIds,
        debtorIds,
        consolidate: consolidatedMode,
      });
      return onPreview(invoiceEmailRequest);
    },
  };

  return mappedState;
};

export const convertToInvoiceEmailRequests = ({ state, scope, invoiceIds, debtorIds, consolidate }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);

  const formFields = formSelectors.getFieldValues(state);

  let bcc = formFields.bccAddress;
  if (formFields.sendCopyToMe) {
    bcc = formFields.bccAddress ? `${formFields.bccAddress}, ${formFields.staffAddress}` : formFields.staffAddress;
  }

  return [
    {
      invoiceIds,
      debtorId: debtorIds.length === 1 ? debtorIds[0] : undefined,
      consolidate, // Optional, request that only a single email is sent even if N > 1 invoices.
      combine: formFields.combineAttachments, // All pdf attachments will be combined into single pdf
      // when CC/BCC is readonly in UI (multiple matters and multiple debtors)
      // we need to know as we wanna replace CC/BCC with matter defaults
      useMatterCcBcc: formFields.readOnlyCcBcc,
      template: {
        toAddress: formFields.readOnlyToAddress ? '' : formFields.toAddress,
        replyToAddress: formFields.fromAddress,
        bcc,
        cc: formFields.ccAddress,
        subject: formFields.subject,
        message: formFields.message,
      },
    },
  ];
};

const mapDispatchToProps = (dispatch, { scope, invoiceIds, debtorIds, matterIds, consolidatedMode }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(prepareFormData({ invoiceIds, matterIds, debtorIds, consolidatedMode, formActions, formOperations }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: invoiceEmailFormSchema }));
    },
  };
};

const prepareFormData =
  ({ invoiceIds, debtorIds, matterIds, consolidatedMode, formActions, formOperations }) =>
  async (dispatch) => {
    const { readOnlyToAddress, toAddress } = await buildAddressInformationP({ invoiceIds, debtorIds });
    const { email: staffEmailAddress } = getStaffEmailDetails() || {};
    const { emailSubject, emailBody, sendCopyToUser } = getInvoiceEmailSettings();

    // Remove the pay button from send email modal only if all the selected invoice's matters have disabled pay buttons in their settings.
    //
    // If at least one of the selected invoice's matters have enabled pay buttons, show it on the modal.
    const showPayButtons = matterIds.some((matterId) => isPayButtonEnabledForFirmAndMatter({ matterId }));

    const getMessage = () => {
      if (consolidatedMode) {
        return consolidatedMessage;
      }

      if (!showPayButtons) {
        const emailBodyWithoutPaymentButtons = emailBody
          .replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '') // Remove the placeholder - mirror evergreen retainer handling of Quill paragraphs
          .replace(/(:?<p>)?\[PAY_NOW_LINK\](:?<\/p><p><br \/><\/p>)?/g, '');
        return emailBodyWithoutPaymentButtons;
      }

      return emailBody;
    };

    const consolidatedSubject = `Your New Invoices - [FIRM_NAME]`;
    const consolidatedMessage = `<p>Dear [DEBTOR]</p><br/><p>Your latest invoices are attached. If you have any questions, please contact our office.</p><br/><p>Kind regards</p><p>[USER]</p>`;

    // merge all CC/BCC across all matters
    const readOnlyCcBcc = matterIds.length > 1 && debtorIds.length > 1;
    const { bCCAddresses = [], cCAddresses = [] } = readOnlyCcBcc ? {} : getUniqueCcBccFromMatterIds(matterIds);

    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          readOnlyToAddress,
          readOnlyCcBcc,
          toAddress,
          ccAddress: cCAddresses.join(', '),
          bccAddress: bCCAddresses.join(', '),
          fromAddress: staffEmailAddress,
          staffAddress: staffEmailAddress,
          sendCopyToMe: sendCopyToUser,
          combineAttachments: hasFacet(facets.combineAttachment) && consolidatedMode, // Combining attachments currently only supported when in consolidated mode
          subject: consolidatedMode ? consolidatedSubject : emailSubject, // Consolidated mode doesn't support full interpolation and therefore no firm templates.
          message: getMessage(), // Consolidated mode doesn't support full interpolation and therefore no firm templates.
        },
      }),
    );

    dispatch(formOperations.validateSchema({ schema: invoiceEmailFormSchema }));
  };

const buildAddressInformationP = async ({ invoiceIds, debtorIds }) => {
  // If there is more than one debtor being emailed, we want to show the to address as a read only summary.
  if (debtorIds.length !== 1) {
    const readOnlyToAddress = `${invoiceIds.length} invoices will be emailed to ${debtorIds.length} debtors`;
    return { readOnlyToAddress };
  }

  // If there is a single debtor, we attempt to figure out the email address for the debtor so that we can pre-populate the
  // toAddress field. We swallow any failure here because the debtor might not have an email address, but we still want to
  // give the customer a chance to enter one manually.
  try {
    const people = await fetchPeopleP(debtorIds[0]);
    return { toAddress: formatContactsEmails(people) };
  } catch (err) {
    return {};
  }
};

export const InvoiceEmailFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ onPreview, ...props }) => {
      const [isPreviewMode, setIsPreviewMode] = useState(props.showPreviewButton);
      const [previewSubject, setPreviewSubject] = useState('');
      const [previewMessage, setPreviewMessage] = useState('');

      const onPreviewToggled = async () => {
        setIsPreviewMode(!isPreviewMode);
      };

      const getPreviewInfo = async () => {
        const { subject, message } = await onPreview();
        setPreviewSubject(subject);
        setPreviewMessage(message);
      };

      useEffect(
        () => {
          // Get preview info on load/when message changes instead of on toggle to enable defaulting to preview mode
          // onPreview fails when props.message is undefined (on first load before fetch)

          // Only need to fetch if the preview button is enabled
          if (props.message && props.showPreviewButton) {
            getPreviewInfo();
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.message],
      );

      return (
        <InvoiceEmailForm
          {...props}
          previewSubject={previewSubject}
          previewMessage={previewMessage}
          onPreviewToggled={onPreviewToggled}
          isPreviewMode={isPreviewMode}
        />
      );
    }),
  ),
);

const getUniqueCcBccFromMatterIds = (matterIds) => {
  if (!Array.isArray(matterIds)) return {};

  const cc = new Set();
  const bcc = new Set();
  matterIds.forEach((matterId) => {
    const settings = getMatterEmailSettings(matterId);
    (settings?.cCAddresses || []).forEach((item) => cc.add(item));
    (settings?.bCCAddresses || []).forEach((item) => bcc.add(item));
  });

  return { cCAddresses: Array.from(cc), bCCAddresses: Array.from(bcc) };
};

InvoiceEmailFormContainer.displayName = 'InvoiceEmailFormContainer';

InvoiceEmailFormContainer.propTypes = {
  debtorIds: PropTypes.arrayOf(PropTypes.string),
  invoiceIds: PropTypes.arrayOf(PropTypes.string),
  matterIds: PropTypes.arrayOf(PropTypes.string),
  consolidatedMode: PropTypes.bool,
  showPreviewButton: PropTypes.bool,
  onPreview: PropTypes.func,
};

InvoiceEmailFormContainer.defaultProps = {
  debtorIds: [],
  invoiceIds: [],
  matterIds: [],
  consolidatedMode: false,
  showPreviewButton: false,
  onPreview: undefined,
};

export default InvoiceEmailFormContainer;
