'use strict';

import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/bulk-deposit';


angular.module('@sb-billing/services').service('sbBulkDepositService', function (
  sbGenericCacheService, sbEndpointType ) {

  const bulkDepositEndpoint = 'billing/bulk-deposit';

  sbGenericCacheService.setupCache({
    name: 'sbBulkDepositService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: bulkDepositEndpoint },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.BulkDepositUpdated',
    },
    updateRedux,
    clearRedux,
  });

});
