import { getApolloClient } from 'web/services/apollo';
import { SbClientSdkStaffDataDocument } from 'web/graphql/types/graphql';
import { mapStaffMember } from '../mappers';

export const clientSdkGetStaff = async (staffId: string) => {
  const apolloClient = getApolloClient();
  const staffData = await apolloClient.query({
    query: SbClientSdkStaffDataDocument,
    variables: { staffId },
    fetchPolicy: 'network-only',
  });
  const staff = staffData?.data?.staffMembers;
  if (staff && staff.length > 0) {
    return mapStaffMember(staff[0]);
  }
  return {};
};
