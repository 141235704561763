import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withReduxStore } from '@sb-itops/react';
import { isModalVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { deleteInvoices } from '@sb-billing/redux/invoices/delete-invoices';
import uuid from '@sb-itops/uuid';

import DeleteDraftInvoiceProceedModalDialog from './DeleteDraftInvoiceProceedModalDialog';

const mapStateToProps = (state, { confirmationModalId }) => ({
  modalId: confirmationModalId,
});

const mapDispatchToProps = (dispatch, { confirmationModalId, selectedInvoiceId, onProceedClick }) => ({
  onProceedClick: () => {
    if (isModalVisible({ modalId: confirmationModalId })) {
      deleteInvoices([
        {
          invoiceId: selectedInvoiceId,
          versionId: uuid(),
        },
      ]);
      setModalDialogHidden({ modalId: confirmationModalId });
      if (onProceedClick) {
        onProceedClick();
      }
    }
  },
});

const DeleteDraftInvoiceProceedModalDialogContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(DeleteDraftInvoiceProceedModalDialog),
);

DeleteDraftInvoiceProceedModalDialogContainer.displayName = 'DeleteDraftInvoiceProceedModalDialogContainer';

DeleteDraftInvoiceProceedModalDialogContainer.propTypes = {
  selectedInvoiceId: PropTypes.string,
  onProceedClick: PropTypes.func,
  confirmationModalId: PropTypes.string.isRequired,
};

DeleteDraftInvoiceProceedModalDialogContainer.defaultProps = {
  selectedInvoiceId: '',
  onProceedClick: undefined,
};

export default DeleteDraftInvoiceProceedModalDialogContainer;
