import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query MatterInvoiceListData($id: ID!) {
    matter(id: $id) {
      id
      billingConfiguration {
        id
        debtorIds
      }
      clients {
        id # The first client is the default debtor (if no assigned debtor present)
      }
      status
    }
  }
`;

const notificationIds = [
  'BillingMattersNotifications.BillingConfigurationUpdated',
  'BrowseMattersNotifications.MatterUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterInvoiceListData = {
  query,
  notificationIds,
};
