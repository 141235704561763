'use strict';

// Stripe subscription status https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses
// Smokeball's Subscription record status would have to map to these same status
const subscriptionStatusByType = Object.freeze({
  TRIALING: 0,
  ACTIVE: 1,
  CANCELLED: 2,
  PAST_DUE: 3,
  UNPAID: 4,
});

const productTierIds = Object.freeze({
  SMK001: 'SMK001', // Tier one, Bill
  SMK004: 'SMK004', // Tier two, Boost
  SMK002: 'SMK002', // Tier three, Grow
  SMK003: 'SMK003', // Tier four, Propser+
});

const productTierIdsByName = Object.freeze({
  BILL: productTierIds.SMK001,
  BOOST: productTierIds.SMK004,
  GROW: productTierIds.SMK002,
  PROSPER: productTierIds.SMK003,
});

const productTierLabelByTierId = Object.freeze({
  [productTierIds.SMK001]: 'Bill',
  [productTierIds.SMK004]: 'Boost',
  [productTierIds.SMK002]: 'Grow',
  [productTierIds.SMK003]: 'Prosper+',
});

const subscriptionIntervals = Object.freeze({
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
});

module.exports = {
  subscriptionStatusByType,
  productTierIds,
  productTierIdsByName,
  productTierLabelByTierId,
  subscriptionIntervals,
};
