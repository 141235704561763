'use strict';

const owningCompanyBrandNames = Object.freeze({
  Smokeball: 'Smokeball',
  TriConvey: 'triConvey',
});

module.exports = {
  owningCompanyBrandNames,
};
