import React, { memo, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';
import Styles from './RadioList.module.scss';

const RadioList = memo(({ children, selected, name, onChange, direction }) => {
  if (children.length <= 1) {
    throw new Error('RadioList must only contain more than 1 children');
  }

  if (children.length > 1 && children.some((child) => child.type.displayName !== Radio.displayName)) {
    throw new Error('RadioList must only contain Radio components');
  }

  // we need to hydrate the nodes props so we can re-use the radio as an stand alone component
  const childrenWithRadioListProps = Children.map(children, (child) => {
    const value = child.props.value;
    return cloneElement(child, {
      ...child.props,
      value,
      name,
      checked: selected === value,
      onChange,
      direction,
    });
  });

  return (
    <div className={direction === 'column' ? Styles.radioListCol : Styles.radioListRow}>
      {childrenWithRadioListProps}
    </div>
  );
});

RadioList.displayName = 'RadioList';

RadioList.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  direction: PropTypes.string,
};

RadioList.defaultProps = {
  selected: undefined,
  direction: 'column',
};

export default RadioList;
