/* eslint-disable func-names */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import { operationTypeLabels } from '@sb-billing/business-logic/correspondence-history';
import { CorrespondenceHistoryDetailsModal } from '../correspondence-history-details-modal';
import Styles from './ClientCommunicationTable.module.scss';

const { timestampLocalisedRenderer } = utils;

const toCellRenderer = ({ rowData }) => {
  const to = rowData.to || '';
  return (
    <span className={Styles.emailDisplay} title={to}>
      {to}
    </span>
  );
};

const debtorCellRenderer =
  ({ onClickLink }) =>
  ({ rowData }) => {
    const { contactIds } = rowData;

    if (contactIds && contactIds.length > 0) {
      return (
        <ContactDisplay
          key={contactIds[0]}
          onClickLink={onClickLink}
          asLink
          contactId={contactIds[0]}
          inline
          showLastNameFirst
        />
      );
    }
    return null;
  };

const referenceCellRenderer =
  ({ onClickLink }) =>
  ({ rowData }) => {
    const { operationTypeLabel, relatedIds } = rowData;
    if (
      operationTypeLabel === operationTypeLabels.INVOICE ||
      operationTypeLabel === operationTypeLabels.INVOICE_REMINDER
    ) {
      const invoiceIdsDescription = relatedIds.map((invoiceId) => `#invoiceId:${invoiceId}`).join(', ');
      return (
        <InterpolatedDescription
          description={invoiceIdsDescription}
          onClickLink={onClickLink}
          deleted={rowData.isHidden}
        />
      );
    }
    return null;
  };

const ClientCommunicationTable = memo(
  ({ matterId, numberOfDaysToShow, correspondenceHistoryData, sortBy, sortDirection, onClickLink, onSort }) => {
    const [showCorrespondenceHistoryDetailsModal, setShowCorrespondenceHistoryDetailsModal] = useState(false);
    const [correspondenceHistoryIdToShow, setCorrespondenceHistoryIdToShow] = useState(undefined);

    const openCorrespondenceHistoryDetailsModal = (correspondenceHistoryId) => {
      setShowCorrespondenceHistoryDetailsModal(true);
      setCorrespondenceHistoryIdToShow(correspondenceHistoryId);
    };

    const closeCorrespondenceHistoryDetailsModal = () => {
      setShowCorrespondenceHistoryDetailsModal(false);
      setCorrespondenceHistoryIdToShow(undefined);
    };

    const onRowClickHandler = (e) => {
      openCorrespondenceHistoryDetailsModal(e.rowData && e.rowData.correspondenceHistoryId);
    };

    return (
      <>
        {showCorrespondenceHistoryDetailsModal && correspondenceHistoryIdToShow && (
          <CorrespondenceHistoryDetailsModal
            correspondenceHistoryIdToShow={correspondenceHistoryIdToShow}
            onClose={closeCorrespondenceHistoryDetailsModal}
            isVisible={showCorrespondenceHistoryDetailsModal}
          />
        )}
        <Table
          list={correspondenceHistoryData}
          onRowClick={onRowClickHandler}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sort={onSort}
        >
          <Column
            dataKey="timestamp"
            label={`Date${!matterId ? ` (Last ${numberOfDaysToShow} Days)` : ''}`}
            flexGrow={1}
            cellRenderer={timestampLocalisedRenderer}
          />
          <Column dataKey="operationTypeLabel" label="Type" flexGrow={1.2} />
          <Column
            dataKey="reference"
            label="Reference"
            cellRenderer={referenceCellRenderer({ onClickLink })}
            flexGrow={2}
          />
          <Column dataKey="sentViaLabel" label="Method" flexGrow={1} />
          <Column dataKey="to" label="To" cellRenderer={toCellRenderer} flexGrow={3} />
          <Column dataKey="debtor" label="Debtor" cellRenderer={debtorCellRenderer({ onClickLink })} flexGrow={2} />
          <Column dataKey="sentBy" label="Sent By" flexGrow={2} />
        </Table>
      </>
    );
  },
);

ClientCommunicationTable.displayName = 'CreditNoteLedgerTransactionTable';

ClientCommunicationTable.propTypes = {
  matterId: PropTypes.string, // if not provided, the table will show correspondence for the last numberOfDaysToShow days for the firm
  numberOfDaysToShow: PropTypes.number,
  correspondenceHistoryData: PropTypes.arrayOf(
    PropTypes.shape({
      correspondenceHistoryId: PropTypes.string,
      timestamp: PropTypes.string,
      contactIds: PropTypes.array,
      to: PropTypes.string,
      operationTypeLabel: PropTypes.string,
      relatedIds: PropTypes.array,
      reference: PropTypes.number,
      sentViaLabel: PropTypes.string,
      sentBy: PropTypes.string,
    }),
  ),
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
};

ClientCommunicationTable.defaultProps = {
  matterId: undefined,
  numberOfDaysToShow: undefined,
  correspondenceHistoryData: [],
  sortDirection: 'desc',
  sortBy: 'sentTimestamp',
};

export default ClientCommunicationTable;
