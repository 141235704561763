import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProductTier } from '@sb-finance/react';
import {
  subscriptionIntervals,
  subscriptionStatusByType,
} from '@sb-finance/business-logic/subscription/entities/constants';
import billImage from 'web/assets/time-management.svg';
import boostImage from 'web/assets/migration.svg';
import Styles from './ManageSubscription.module.scss';

/* import { PaymentElement } from '@stripe/react-stripe-js';
import { Button, Spinner, useTranslation } from '@sb-itops/react';

import { SubscriptionTierSelector } from 'web/react-redux'; 
import { featureActive } from '@sb-itops/feature'; */

export const ManageSubscription = ({
  currentTier,
  tierPricingInfo,
  tierMarketingInfo,
  isBarBillFreeBoostTrial,
  freeTiers,
  freeTierPriceDescription,
  subscriptionFrequency,
  subscriptionRecord,
  updatingToSmokeballProductId,
  onTierSwitchRequested,
  onMonthlySubscriptionSelected,
  onYearlySubscriptionSelected,
  cancelSubscriptionInProgress,
  onCancelSubscription,
  onManagePaymentMethod,
}) => {
  const { SMK001: billPricingInfo, SMK004: boostPricingInfo } = tierPricingInfo;
  const { bill: billMarketingInfo, boost: boostMarketingInfo } = tierMarketingInfo;

  const isMonthlyFrequency = subscriptionFrequency === subscriptionIntervals.MONTHLY;
  const isInTrialPeriod = subscriptionRecord.status === subscriptionStatusByType.TRIALING;
  const isCurrentTierBill = currentTier === 'SMK001';
  const isCurrentTierBoost = currentTier === 'SMK004';
  const pricingIntervalLabel =
    subscriptionFrequency === subscriptionIntervals.MONTHLY ? 'per month' : 'per month (charged annually)';
  const hasPaymentMethod = subscriptionRecord.externalPaymentMethodIds.length > 0;

  const boostFreePriceDescription = isBarBillFreeBoostTrial
    ? 'You are trialing Boost free for 30 days'
    : freeTierPriceDescription;

  let managePaymentMethodLabel;

  if (hasPaymentMethod) {
    managePaymentMethodLabel = 'Update Credit Card';
  } else if (isInTrialPeriod || isBarBillFreeBoostTrial) {
    managePaymentMethodLabel = 'Subscribe Now';
  } else {
    managePaymentMethodLabel = 'Add Credit Card';
  }

  return (
    <div className={Styles.manageSubscription}>
      {isCurrentTierBoost && (
        <div className={Styles.blurbContainer}>
          <div className={Styles.title}>
            <span>Manage your Smokeball Subscription</span>
          </div>
          <div className={Styles.subTitle}>
            <span>You may switch tiers at any time.</span>
          </div>
        </div>
      )}
      {!isCurrentTierBoost && (
        <div className={Styles.blurbContainer}>
          <div className={Styles.title}>
            <span>
              Let Smokeball help your firm <span className={Styles.emphasis}>level up</span>
            </span>
          </div>
          <div className={Styles.subTitle}>
            <span>Organize matters, client information and documents easily with Boost.</span>
          </div>
        </div>
      )}
      <div className={Styles.pricingSwitchContainer}>
        <div className={Styles.pricingSwitch}>
          <div
            onClick={onMonthlySubscriptionSelected}
            className={classnames(isMonthlyFrequency && Styles.active, Styles.pricingSwitchText)}
          >
            Monthly
          </div>
          <div
            onClick={onYearlySubscriptionSelected}
            className={classnames(!isMonthlyFrequency && Styles.active, Styles.pricingSwitchText)}
          >
            Yearly
          </div>
        </div>
      </div>
      <div className={Styles.productTierTilesSection}>
        {/* Bill */}
        <ProductTier
          thumbnail={billImage}
          priceInCents={freeTiers.SMK001 ? 0 : billPricingInfo.normalisedPrice}
          pricingInterval={pricingIntervalLabel}
          priceDescription={freeTiers.SMK001 ? freeTierPriceDescription : ''}
          tierName="Bill"
          tierBlurb={billMarketingInfo.tierBlurb}
          tierBulletPointColour={billMarketingInfo.tierBulletPointColour}
          tierBulletPoints={billMarketingInfo.tierBulletPoints}
          isPendingCancel={subscriptionRecord.cancelAtPeriodEnd}
          currentPeriodEnd={subscriptionRecord.currentPeriodEnd}
          switchToTierLabel={isInTrialPeriod ? 'Trial Bill Now' : 'Downgrade Now'}
          switchToTierInProgress={updatingToSmokeballProductId === 'SMK001'}
          onSwitchToTier={() => onTierSwitchRequested(billPricingInfo)}
          cancelSubscriptionInProgress={cancelSubscriptionInProgress}
          onCancelSubscription={onCancelSubscription}
          hideManagePaymentMethod={freeTiers.SMK001}
          managePaymentMethodLabel={managePaymentMethodLabel}
          cancelSubscriptionLabel={isInTrialPeriod ? 'Cancel Trial' : 'Cancel Subscription'}
          onManagePaymentMethod={() => onManagePaymentMethod({ externalProductId: billPricingInfo.stripeProductId })}
          active={isCurrentTierBill}
          hidePriceInterval={freeTiers.SMK001}
        />

        {/* Boost */}
        <ProductTier
          thumbnail={boostImage}
          priceInCents={freeTiers.SMK004 || isBarBillFreeBoostTrial ? 0 : boostPricingInfo.normalisedPrice}
          pricingInterval={pricingIntervalLabel}
          priceDescription={freeTiers.SMK004 || isBarBillFreeBoostTrial ? boostFreePriceDescription : ''}
          tierName="Boost"
          tierBlurb={boostMarketingInfo.tierBlurb}
          tierBulletPointColour={boostMarketingInfo.tierBulletPointColour}
          tierBulletPoints={boostMarketingInfo.tierBulletPoints}
          isPendingCancel={subscriptionRecord.cancelAtPeriodEnd}
          currentPeriodEnd={subscriptionRecord.currentPeriodEnd}
          switchToTierLabel={isInTrialPeriod ? 'Trial Boost Now' : 'Upgrade Now'}
          switchToTierInProgress={updatingToSmokeballProductId === 'SMK004'}
          onSwitchToTier={() => onTierSwitchRequested(boostPricingInfo)}
          cancelSubscriptionInProgress={cancelSubscriptionInProgress}
          onCancelSubscription={onCancelSubscription}
          hideManagePaymentMethod={freeTiers.SMK004}
          managePaymentMethodLabel={managePaymentMethodLabel}
          cancelSubscriptionLabel={isInTrialPeriod || isBarBillFreeBoostTrial ? 'Cancel Trial' : 'Cancel Subscription'}
          onManagePaymentMethod={() =>
            onManagePaymentMethod({
              externalProductId: boostPricingInfo.stripeProductId,
              isBarBillFreeBoostTrial,
              requiresTierUpdate: isBarBillFreeBoostTrial && !hasPaymentMethod,
            })
          }
          highlighted
          active={isCurrentTierBoost}
          hidePriceInterval={freeTiers.SMK004 || isBarBillFreeBoostTrial}
        />
      </div>
    </div>
  );
};

ManageSubscription.displayName = 'ManageSubscription';

ManageSubscription.propTypes = {
  currentTier: PropTypes.oneOf(['SMK001', 'SMK004']).isRequired, // Subscription management is currently only available for bill and boost tiers
  freeTiers: PropTypes.object.isRequired,
  freeTierPriceDescription: PropTypes.string.isRequired,
  tierPricingInfo: PropTypes.object.isRequired,
  tierMarketingInfo: PropTypes.object.isRequired,
  isBarBillFreeBoostTrial: PropTypes.bool,
  subscriptionFrequency: PropTypes.oneOf(Object.values(subscriptionIntervals)).isRequired,
  subscriptionRecord: PropTypes.object.isRequired,
  updatingToSmokeballProductId: PropTypes.string,
  cancelSubscriptionInProgress: PropTypes.bool.isRequired,
  onCancelSubscription: PropTypes.func.isRequired,
  onManagePaymentMethod: PropTypes.func.isRequired,
  onTierSwitchRequested: PropTypes.func.isRequired,
  onMonthlySubscriptionSelected: PropTypes.func.isRequired,
  onYearlySubscriptionSelected: PropTypes.func.isRequired,
};

ManageSubscription.defaultProps = {
  updatingToSmokeballProductId: undefined,
  isBarBillFreeBoostTrial: false,
};
