import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getLogger } from '@sb-itops/fe-logger';
import { fetchGetP } from '@sb-itops/redux/fetch';
import composeHooks from '@sb-itops/react-hooks-compose';
import { sort } from '@sb-itops/sort';
import { getPaymentsByInvoiceIds } from '@sb-billing/redux/payments';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';

import { InvoiceStatementData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { hasBillingAccess } from 'web/services/user-session-management';

import { getInvoiceStatementPayments } from './get-invoice-statement-payments';
import { BillingViewInvoiceStatementPaymentsRoute } from './BillingViewInvoiceStatementPaymentsRoute';

const log = getLogger('BillingViewInvoiceStatementPaymentsRoute.container');

const hooks = () => ({
  usePermissions: () => ({
    hasBillingAccess: hasBillingAccess(),
  }),
  useInvoiceStatementData: ({ invoiceStatementId, sbPaymentService, sbTabService }) => {
    const { data: invoiceStatementData, loading: invoiceStatementLoading } = useSubscribedQuery(InvoiceStatementData, {
      variables: {
        invoiceStatementId,
      },
    });

    const [previewUrl, setPreviewUrl] = useState(null);

    const fetchInvoiceStatementPdfSignedUrl = useCallback(async () => {
      try {
        const response = await fetchGetP({
          path: `/billing/invoice-statement-pdf/:accountId/${invoiceStatementId}/presigned-url/`,
        });

        setPreviewUrl(response.body?.preSignedUrl);
      } catch (error) {
        log.error('Error occurred while fetching invoice statement PDF preview', error);
      }
    }, [invoiceStatementId]);

    useEffect(() => {
      if (invoiceStatementData?.invoiceStatement?.id) {
        fetchInvoiceStatementPdfSignedUrl();
      }

      const unsub = subscribeToNotifications({
        notificationIds: ['InvoiceStatementPDFSyncNotification'],
        callback: (payloadString) => {
          const payload = JSON.parse(payloadString);
          if (payload.invoiceStatementId === invoiceStatementId) {
            fetchInvoiceStatementPdfSignedUrl();
          }
        },
      });
      return unsub;
    }, [fetchInvoiceStatementPdfSignedUrl, invoiceStatementData?.invoiceStatement?.id, invoiceStatementId]);

    const {
      number: invoiceStatementNumber,
      debtor,
      invoices,
      status: invoiceStatementStatus,
      lastUpdated: invoiceStatementLastUpdated,
    } = invoiceStatementData?.invoiceStatement || {};

    useEffect(() => {
      if (invoiceStatementNumber || debtor?.displayNameFirstLast) {
        sbTabService.updateTabInfo(
          { type: 'invoice-statement', id: invoiceStatementId },
          {
            primaryLabel: `Statement #${invoiceStatementNumber || ''}`,
            secondaryLabel: debtor?.displayNameFirstLast,
          },
        );
      }
    }, [debtor?.displayNameFirstLast, invoiceStatementId, invoiceStatementNumber, sbTabService]);

    const { statementInvoiceIds, statementInvoiceMap, statementBalance } = (invoices || []).reduce(
      (acc, invoice) => {
        acc.statementInvoiceIds.push(invoice.id);
        acc.statementInvoiceMap[invoice.id] = invoice;
        acc.statementBalance += invoice.totals.totalExcInterest;
        return acc;
      },
      { statementInvoiceIds: [], statementInvoiceMap: {}, statementBalance: 0 },
    );

    const payments = useSelector(() => getPaymentsByInvoiceIds(statementInvoiceIds));
    const filteredPayments = JSON.parse(JSON.stringify(payments.filter((payment) => payment.reversedAt === null)));
    const sortedPayments = sort(filteredPayments, ['effectiveDate', 'timestamp'], ['asc', 'asc']);

    const invoiceStatementPayments = getInvoiceStatementPayments({
      payments: sortedPayments,
      statementBalance,
      statementInvoiceMap,
      sort,
      getContactById,
      decodePaymentSource: sbPaymentService.decodePaymentSource,
    });

    return {
      invoiceStatementLoading,
      invoiceStatementId,
      invoiceStatementNumber,
      previewUrl,
      debtor,
      invoices,
      payments: invoiceStatementPayments,
      invoiceStatementStatus,
      invoiceStatementLastUpdated,
    };
  },
  usePageNavItems: () => ({
    pageNavItems: [
      {
        path: 'invoiceStatementView',
        display: 'View',
        order: 1,
      },
      {
        path: 'invoiceStatementPayments',
        display: 'Payments',
        className: 'dash-active',
        order: 2,
      },
    ],
  }),
});

export const BillingViewInvoiceStatementPaymentsRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(BillingViewInvoiceStatementPaymentsRoute)),
);

BillingViewInvoiceStatementPaymentsRouteContainer.displayName = 'BillingViewInvoiceStatementPaymentsRouteContainer';

BillingViewInvoiceStatementPaymentsRouteContainer.propTypes = {
  invoiceStatementId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func,
  $uibModal: PropTypes.any.isRequired,
  $state: PropTypes.any.isRequired,
  sbPaymentService: PropTypes.any.isRequired,
  sbTabService: PropTypes.any.isRequired,
};

BillingViewInvoiceStatementPaymentsRouteContainer.defaultProps = {
  onClickLink: () => {},
};
