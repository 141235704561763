/* eslint-disable prettier/prettier */

'use strict';

const {
  deriveSupplementaryTablesAdditionalOptionsFromSectionOptions,
} = require('./section-options/derive-supplementary-tables-additional-options-from-section-options');

/**
 * Get invoice additional options and layout based on the original template setting and current settings of the draft invoice.
 *
 * @param {object} originalInvoiceSettingsTemplate - Original template data set under firm setting.
 * @param {object} currentDraftInvoiceSettings - currentDraftInvoiceSettings get from saved draft invoice.
 * @param {object} overriddenDraftInvoiceSettings - The overwrite config made in the draft invoice page.
 * @param {Object} [sectionOptions] - final sectionOptions determined through determineFinalSectionOptions
 * is required here when invoiceSupplementaryTablesPageBreakEnabled is true.
 * @param {object} invoiceSupplementaryTablesPageBreakEnabled - feature flag value of BB-12385.
 * @param {object} showItemNumbersEnabled - feature flag value of BB-12394.
 * @returns {object} - invoice additional options and layout used for PDF generation purpose.
 */

const determineFinalInvoiceAdditionalOptionsAndLayout = ({
  originalInvoiceSettingsTemplate,
  currentDraftInvoiceSettings,
  overriddenDraftInvoiceSettings,
  sectionOptions,
  invoiceSupplementaryTablesPageBreakEnabled,
  showItemNumbersEnabled,
}) => {
  if (invoiceSupplementaryTablesPageBreakEnabled && !sectionOptions) {
    throw new Error('sectionOptions is required in when invoiceSupplementaryTablesPageBreakEnabled is enabled');
  }

  const invoiceAdditionalOptions = {
    ...originalInvoiceSettingsTemplate.invoiceAdditionalOptions,
    ...originalInvoiceSettingsTemplate.defaultLayout,
    ...(currentDraftInvoiceSettings.additionalOptionsFromInvoice || {}),
    ...(currentDraftInvoiceSettings.layoutFromInvoice || {}),
    ...(overriddenDraftInvoiceSettings || {}),
  };

  // When new page break feature enabled, we get supplementary tables related invoiceAdditionalOptions from sectionOption to make sure they are consistent
  const supplementaryTablesInvoiceAdditionalOptions = invoiceSupplementaryTablesPageBreakEnabled
    ? deriveSupplementaryTablesAdditionalOptionsFromSectionOptions({ sectionOptions })
    : invoiceAdditionalOptions;

  // showItemNumbers is set at the template level, there's no matter or invoice level overrides
  // when saving, previewing or finalising a draft invoice, showItemNumbers should be fetched
  // from template settings, i.e. this is as oppose to using showItemNumbers saved in the draft invoice
  const showItemNumbers = showItemNumbersEnabled
    ? originalInvoiceSettingsTemplate?.invoiceAdditionalOptions?.showItemNumbers
    : undefined;

  const {
    showSummaryForTimekeepers,
    showTimekeeperRole,
    hidePriorBalance,
    hidePaymentSummary,
    showInvoiceSummary,
    showAccountSummary,
    showTransactionHistory,
  } = supplementaryTablesInvoiceAdditionalOptions;

  const {
    showStaffNameOnEntries,
    showRateOnEntries,
    showDurationOnEntries,
    showDescriptionForEntries,
    showHoursSummary,
    hideFeeTotal,
    hideFeeTotalSummary,
    hideDueDate,
    hideTaxDisplay,
    showRetainer,
    appendExpenseReceipts,
    hideAmountAndTaxPerLineItem,
  } = invoiceAdditionalOptions;

  return {
    options: {
      showStaffNameOnEntries,
      showRateOnEntries,
      hidePriorBalance,
      hidePaymentSummary,
      showInvoiceSummary,
      showSummaryForTimekeepers,
      showTimekeeperRole,
      showDurationOnEntries,
      showAccountSummary,
      showDescriptionForEntries,
      showHoursSummary,
      hideFeeTotal,
      hideFeeTotalSummary,
      hideDueDate,
      hideTaxDisplay,
      showRetainer,
      showTransactionHistory,
      appendExpenseReceipts,
      showItemNumbers,
      hideAmountAndTaxPerLineItem,
    },
  };
};

module.exports = {
  determineFinalInvoiceAdditionalOptionsAndLayout,
};
