import { gql } from '@apollo/client';
import { expenseEntityFragment } from '../fragments/expenseEntityFragment.graphql';

const query = gql`
  query DraftInvoiceExpenses($matterId: ID!, $includeNonBillableItems: Boolean!) {
    unbilledExpenses(matterIds: [$matterId], filter: { includeNonBillableItems: $includeNonBillableItems }) {
      ...expenseEntityFragment
    }
  }

  ${expenseEntityFragment}
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpenseDeleted',
  'InvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoiceExpenses = {
  query,
  notificationIds,
};
