'use strict';

const { bankAccountTypes } = require('../../bank-account/entities/constants');
const { isTransactionBulkDeposit } = require('./is-transaction-bulk-deposit');
const { consolidateBulkDepositTransactions } = require('./consolidate-bulk-deposit-transactions');

/**
 * Consolidate transactions belonging to the same bulk deposit into
 * one pseudo transaction, leaving other type of transaction entities unchanged
 * @param {Object} params
 * @param {Object[]} params.transactions - list of transaction entities
 * @param {function} params.t - i18next translate function
 * @param {boolean} params.bdOnly - boolean to determine whether we want the return array to include regular
 * transactions or BTD pseudo-transactions only, if true will return child transactions as additional attribute
 * @returns a list of transactions in roughly the same shape as existing transaction entities
 */
function consolidateOperatingTransactions({ transactions, t, bdOnly = false }) {
  // Keep a temporary map of transactions for each bulk deposit
  const bulkDepositTransactionsMap = {};

  const regularTransactions = transactions.reduce((acc, txn) => {
    if (isTransactionBulkDeposit({ transaction: txn, bankAccountType: bankAccountTypes.OPERATING })) {
      let id = txn.paymentId;
      if (txn.reversedFromTransactionId) {
        id = `${txn.paymentId}_reversal`;
      }

      if (bulkDepositTransactionsMap[id]) {
        bulkDepositTransactionsMap[id].push({ ...txn, bulkDepositId: id });
      } else {
        bulkDepositTransactionsMap[id] = [{ ...txn, bulkDepositId: id }];
      }
    } else {
      acc.push(txn);
    }

    return acc;
  }, []);

  const bulkDepositConsolidatedTransactions = consolidateBulkDepositTransactions({
    bulkDepositTransactionsMap,
    t,
  });

  if (bdOnly) {
    const btdConsolidatedTransactionWithChildTxs = bulkDepositConsolidatedTransactions.map((tx) => ({
      ...tx,
      transactions: bulkDepositTransactionsMap[tx.id],
    }));
    return btdConsolidatedTransactionWithChildTxs;
  }

  // return the combined list of transactions/pseudo transactions
  return [...bulkDepositConsolidatedTransactions, ...regularTransactions];
}

module.exports = {
  consolidateOperatingTransactions,
};
