angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/entries/time';

  $stateProvider

  .state('home.billing.entries.time', {
    url: '/time',
    templateUrl: BASE_DIRECTORY + '/entries-time.html',
    controller: 'EntriesTimeController',
    controllerAs: 'ctrl',
    params: {
      initialFilterDate: undefined,
    },
    data: {
      authorized: true,
      navHighlights: ['billing', 'entries']
    }
  });

});
