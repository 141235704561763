import { useSubscribedQuery } from 'web/hooks';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { RateSetOptions } from 'web/graphql/queries';
import RateSetBulkApplyModal from './RateSetBulkApplyModal';

const hooks = (props) => ({
  useRateSetOptions: () => {
    const {
      data,
      error,
      loading: rateSetsLoading,
    } = useSubscribedQuery(RateSetOptions, {
      variables: {
        rateSetListFilter: {
          includeHidden: false,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const rateSets = data?.rateSetList?.results || [];
    const rateSetOptions = rateSets.map(({ name: label, id: value }) => ({
      value,
      label,
      searchText: label.toLowerCase(),
    }));

    return {
      ...props,
      rateSetsLoading,
      rateSetOptions,
    };
  },
});

export const RateSetBulkApplyModalContainer = withApolloClient(composeHooks(hooks)(RateSetBulkApplyModal));

RateSetBulkApplyModalContainer.displayName = 'RateSetBulkApplyModal.container';
