angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/edit-deposit-slip';

  $stateProvider
    .state('home.billing.edit-deposit-slip', {
      url: '/edit-deposit-slip/:depositSlipId',
      templateUrl: BASE_DIRECTORY + '/edit-deposit-slip.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        tab: { type: 'edit-deposit-slip' }
      }
    });

});
