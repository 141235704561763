/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getRegion } from '@sb-itops/region';
import { isAbbreviatedTitle } from '@sb-customer-management/business-logic/contacts/services';
import { createContactAddressChecker } from '@sb-customer-management/contact-address-checker';
import { getById as getContactSummaryById } from '../contacts-summary';
import domain from '../domain';

const region = getRegion();
const letterFormat = {
  FORMAL: 0,
  INFORMAL: 1,
  CUSTOM: 2,
};

const api = cacheFactory({
  domain,
  name: 'contacts',
  keyPath: 'id',
  ngCacheName: 'sbSimpleContactMbService',
  syncType: syncTypes.NONE,
  immutable: false,
});

export const { getList, getMap, updateCache, updateCacheAndBroadcast, UPDATE_CACHE } = api;

// selectors
export const getById = (id) => getMap()[id];

// thunks
const endpoint = '/customer-management/contact/:accountId';

export const fetchByIds = async (ids) => Promise.all(ids.map((contactId) => fetchById(contactId)));

export const fetchById = async (id) => {
  // if we have already received the (full) contact and it is the most up to date, just return it
  const contact = getById(id);
  const contactSummary = getContactSummaryById(id);

  if (contact && contactSummary && contact.versionId === contactSummary.versionId) {
    return contact;
  }

  // get the last updated record
  const result = await fetchGetP({ path: `${endpoint}/${id}` });
  updateCacheAndBroadcast({ entities: result.body.payload });

  return getById(id);
};

// given a contact return the way we need to call him, do not export this this is only used internally
const getPersonNameForLetter = (contact) => {
  if (!contact) {
    throw new Error(`Cannot get letter name for invalid contact ${JSON.stringify(contact)}`);
  }
  if (!contact.person) {
    throw new Error(`Letter name is only supported for person contact ${JSON.stringify(contact)}`);
  }
  const person = contact.person;
  switch (person.letterNameFormat) {
    case letterFormat.FORMAL: {
      let title = person.title;
      if (region === 'US' && title && !title.endsWith('.') && isAbbreviatedTitle(title)) {
        title += '.';
      }

      return `${title ? `${title} ` : ''}${!title ? `${person.firstName} ` : ''}${person.lastName}`;
    }
    case letterFormat.INFORMAL: {
      return person.firstName;
    }
    case letterFormat.CUSTOM: {
      return person.customLetterName;
    }
    default: {
      return contact.displayName || '';
    }
  }
};

const getPersonEmailInfo = (contactSummary) => {
  const contact = getById(contactSummary.entityId);
  const person = contact && contact.person;

  if (!person || !contactSummary.email) {
    return undefined;
  }

  return {
    email: contactSummary.email,
    salutation: getPersonNameForLetter(contact),
    id: contactSummary.entityId,
  };
};

export const getEmailFromContactId = (contactId) => {
  const toResolve = [contactId];
  const contactEmails = [];
  const resolved = {};

  while (toResolve.length) {
    const toResolveId = toResolve.pop();
    resolved[toResolveId] = true;
    const contactSummary = getContactSummaryById(toResolveId);
    let contactEmail;

    if (contactSummary) {
      switch (contactSummary.type) {
        case 'Person':
          contactEmail = getPersonEmailInfo(contactSummary);
          if (contactEmail) {
            contactEmails.push(contactEmail);
          }
          break;
        case 'GroupOfPeople':
          contactSummary.personIds.split(';').reduce((ids, id) => {
            if (!resolved[id]) {
              ids.push(id);
            }
            return ids;
          }, toResolve);
          break;
        default:
          contactEmails.push({
            email: contactSummary.email,
            salutation: contactSummary.displayName || '',
            id: toResolveId,
          });
      }
    }
  }

  return contactEmails;
};

/**
 * Fetch all the people associated with a contact.
 *
 * Returns either the contact itself, if it's a Person, or its associated people, if it's a GroupOfPeople
 * @param {string} contactId
 * @returns Array.<object>
 */
export const fetchPeopleP = async (contactId) => {
  const { type, personIds, displayName, email } = getContactSummaryById(contactId);

  switch (type) {
    case 'Person': {
      const contact = getById(contactId) || (await fetchById(contactId));
      const salutation = getPersonNameForLetter(contact);

      return [
        {
          email,
          salutation,
          id: contactId,
        },
      ];
    }
    case 'GroupOfPeople': {
      return Promise.all(
        personIds.split(';').map(async (personId) => {
          const contact = await fetchById(personId);
          const person = contact.person || contact;
          const salutation = getPersonNameForLetter(contact);
          return {
            ...person,
            salutation,
          };
        }),
      );
    }
    default: {
      return [
        {
          email,
          salutation: displayName,
          id: contactId,
        },
      ];
    }
  }
};

// given a contact and his type fetch all the contact related
export const fetchDerivePeople = (contactId) => (dispatch) => {
  const contactSummary = getContactSummaryById(contactId);
  if (!contactSummary) {
    return undefined;
  }
  if (contactSummary.type === 'Person') {
    return dispatch(fetchById(contactId));
  }
  if (contactSummary.type === 'GroupOfPeople') {
    return Promise.all(contactSummary.personIds.split(';').map((personId) => fetchById(personId)));
  }

  return contactSummary;
};

// give a contactID (that corresponds to either a 'Person' or 'GroupOfPeople'), fetch all the people
export const fetchPeople = (contactId) => (dispatch) => dispatch(fetchDerivePeople(contactId));

// spreadGroupsOfPeople = return groups of people individually
export const findContactsWithMissingStreetOrPOAddress = async ({ contactIds, spreadGroupsOfPeople = false }) => {
  let contacts = await fetchByIds(contactIds);
  const contactAddressChecker = createContactAddressChecker(region);

  if (!spreadGroupsOfPeople) {
    return contactAddressChecker.findContactsWithMissingStreetOrPOAddress(contacts);
  }

  const missingAddresses = contactAddressChecker.findContactsWithMissingStreetOrPOAddress(contacts);
  const hasGroupOfPeopleMissingAddresses = missingAddresses.find((contact) => contact.groupOfPeople);

  if (!hasGroupOfPeopleMissingAddresses) {
    return missingAddresses;
  }

  // If any of the missing addresses are groups of people, we then have to spread the groups of people and get the contituent contacts
  // Because we can't edit groups of people in the web to add an address
  // We can only add addresses for every contact in the group individually
  const nonGroupContacts = missingAddresses.filter((contact) => !contact.groupOfPeople);
  const groups = missingAddresses.filter((contact) => contact.groupOfPeople);

  const flattenedContactIds = groups.reduce((acc, group) => {
    const ids = [...acc, ...group.groupOfPeople.personIds];
    return ids;
  }, []);
  const constituents = await fetchByIds(flattenedContactIds);
  contacts = nonGroupContacts.concat(constituents);
  // Remove duplicates (can happen if a client is also in a group of people that are clients on the matter)
  const uniqueIds = new Set();
  const uniqueContacts = contacts.filter((contact) => {
    const isDuplicate = uniqueIds.has(contact.id);
    uniqueIds.add(contact.id);
    return !isDuplicate;
  });
  contacts = uniqueContacts;

  return contactAddressChecker.findContactsWithMissingStreetOrPOAddress(contacts);
};

export * from './create-contact';
export * from './edit-contact';
export * from './delete-contact';
export { restoreDeletedContact } from './restore-deleted-contact';
export { getDebtorDefaultNameAndAddress } from './get-debtor-default-name-and-address';
