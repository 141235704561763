import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { createSelector } from 'reselect';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'utbms-activity-codes',
  keyPath: 'code',
  ngCacheName: 'sbUtbmsActivityCodeService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

const getUtbmsActivitiesSelector = createSelector(
  (list) => list || [],
  (list) => list.filter((v) => !v.deleted),
);

const getFeeUtbmsActivitiesSelector = createSelector(
  (list) => list || [],
  (list) => list.filter((act) => act.type === 'Activity'),
);

export function getUtbmsActivities() {
  return getUtbmsActivitiesSelector(getList());
}

export function getFeeUtbmsActivities() {
  const utbmsActivities = getUtbmsActivities();
  if (!utbmsActivities) return [];
  return getFeeUtbmsActivitiesSelector(utbmsActivities);
}
