'use strict';

const { hasPayments } = require('./has-payments');

function isEditable(invoice, invoiceTotals) {
  return (
    !!invoice &&
    invoice.currentVersion.status === 'FINAL' &&
    !!invoiceTotals &&
    !hasPayments(invoiceTotals) &&
    !invoice.invoiceStatementId // not part of an invoice statement
  );
}

module.exports = {
  isEditable,
};
