import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dateToInteger } from '@sb-itops/date';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getReconciliationsForMonth } from '@sb-billing/redux/bank-reconciliations';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import {
  getEndOfMonth,
  getNextReportPeriod,
  reconciled,
} from '@sb-billing/business-logic/accounts/end-of-month-reports';
import { getLatest as getLatestEndOfMonthReport, saveEndOfMonthReport } from '@sb-billing/redux/end-of-month-reports';
import { getFirmName, getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

import { getAllEndOfMonthReports } from 'web/redux/selectors/end-of-month-reports';
import * as endOfMonth from 'web/redux/route/home-billing-accounts-end-of-month/feature';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import BillingAccountsEndOfMonth from './BillingAccountsEndOfMonth';

const modalId = 'end-of-month-modal-id';

const getAllReconciliationsThisPeriod = (yyyymmdd, trustAccountId) =>
  yyyymmdd ? getReconciliationsForMonth({ date: moment(yyyymmdd, 'YYYYMMDD').toDate(), trustAccountId }) : [];

const path = endOfMonth.defaultPath;
const { getMonth, getYear, getSortDirection } = endOfMonth.selectors;
const { setMonth, setSortDirection, setYear } = endOfMonth.actions;

const mapStateToProps = (state, { trustAccountId, t }) => {
  const isSpecificPage = !!trustAccountId;
  const trustAccountName = getBankAccountName(getBankAccountById(trustAccountId), t);

  // only staff members (not setup users) can run the report
  const canRunReport = !!getLoggedInStaff();
  const latestEomReport = getLatestEndOfMonthReport(trustAccountId);
  const accountType = bankAccountTypeEnum.TRUST;

  // prompt users for the first reporting period
  const isFirstReport = !latestEomReport;

  // calculate the reporting period from previous report, or user selections, if no report available
  const { month, year } = getNextReportPeriod({
    latestEomReport,
    defaultMonth: getMonth(state[path]),
    defaultYear: getYear(state[path]),
  });
  const reportYYYYMMDD = getEndOfMonth({ month, year });

  // only run report for reconciled periods
  let isReconciled = false;
  if (trustAccountId) {
    const reconciliations = getAllReconciliationsThisPeriod(reportYYYYMMDD, trustAccountId);
    const { endDate: reconciledYYYYMMDD, status } = reconciliations[reconciliations.length - 1] || {};
    isReconciled =
      status !== 'InProgress' &&
      reconciled({
        reconciledYYYYMMDD,
        reportYYYYMMDD,
      });
  }

  return {
    trustAccountId,
    trustAccountName,
    reports: getAllEndOfMonthReports(trustAccountId),
    month,
    year,
    modalId,
    accountType,
    sortDirection: getSortDirection(state[path]),
    lastPageNumbers: latestEomReport && latestEomReport.lastPageNumbers,
    userId: authSelectors.getUserId(state),
    isReconciled,
    isFirstReport,
    canRunReport,
    isSpecificPage: !!isSpecificPage,
  };
};

const mapDispatchToProps = (dispatch, { onClickLink, trustAccountId, t }) => ({
  onSelectMonth: (month) => dispatch(setMonth({ month })),
  onSelectYear: (year) => dispatch(setYear({ year })),
  onSortDirectionChange: (sortDirection) => dispatch(setSortDirection({ sortDirection })),
  onConfirm: ({ bankAccountId, lastPageNumbers, userId, month, year }) => {
    const today = new Date();
    const date = getEndOfMonth({ month, year });
    const newEntry = {
      date,
      createdDate: dateToInteger(today),
      reportPrintingDate: `${t('date', { date: today })} ${moment(today).format('LT')}`,
      bankAccountId,
      lastPageNumbers,
      userId,
    };

    saveEndOfMonthReport({
      ...newEntry,
      firmName: getFirmName(),
      bankReconciliationsIdsCompleted: getAllReconciliationsThisPeriod(date, trustAccountId).map(
        (bankRecon) => bankRecon && bankRecon.id,
      ),
    });
  },
  onClickLink,
});

const BillingAccountsEndOfMonthContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BillingAccountsEndOfMonth)),
);

BillingAccountsEndOfMonthContainer.displayName = `Container(${BillingAccountsEndOfMonth.displayName})`;

BillingAccountsEndOfMonthContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string,
};

BillingAccountsEndOfMonthContainer.defaultProps = {
  trustAccountId: undefined,
};

export default BillingAccountsEndOfMonthContainer;
