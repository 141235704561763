import { debounce } from '@sb-itops/nodash'
import { featureActive } from '@sb-itops/feature';
import * as messageDisplay from '@sb-itops/message-display';

import { TaskSearch, MemoSearch, DocumentSearch, EventSearch } from 'web/graphql/queries';
import { getMatterTypeaheadSummaries, getInvoiceTypeaheadSummaries, getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { hasBillingAccess } from 'web/services/user-session-management';
import { getApolloClient } from 'web/services/apollo';

angular.module('sb.billing.webapp').component('sbDataSearchResults', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: { searchTerm: '<', searchOptions: '<', searchDocs: '<', composeKey: '@' },
  controller: function ($scope, sbLoggerService, sbGenericSearchService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataSearchResults');
    const SEARCH_BOUNCE_TIMEOUT_MS = 200;
    const DOCUMENT_SEARCH_BOUNCE_TIMEOUT_MS = 2000;
    const DEFAULT_COMPOSE_KEY = 'searchResults';
    let documentSearchPromise = Promise.resolve();
    let queuedDocumentSearch = null;

    const memosFeatureOn = featureActive('NUCWEB-121')
    const documentsFeatureOn = featureActive('NUCWEB-70')
    const eventsFeatureOn = featureActive('NUCWEB-164')
    const tasksFeatureOn = featureActive('NUCWEB-120')
    let memoData, documentData, eventsData, tasksData;

    const searchDocuments = debounce(
      () => {
        const searchTerm = preProcessSearchTerm();
        if (ctrl.searchDocs && !ctrl.searchOptions.searchInvoiceOnly && searchTerm) {
          $scope.$applyAsync(async () => {
            const apolloClient = getApolloClient();


            const updateResults = () => ctrl.sbComposeCtrl.setComposeData({
              ...searchForResults(),
              documents: documentsFeatureOn && documentData && documentData.results || null,
              memos: memosFeatureOn && memoData && memoData.results || null,
              events: eventsFeatureOn && eventsData && eventsData.results || null,
              tasks: tasksFeatureOn && tasksData && tasksData.results || null,
              redacted: {
                memos: memosFeatureOn && memoData && memoData.redactedCount || 0,
                events: eventsFeatureOn && eventsData && eventsData.redactedCount || 0,
                tasks: tasksFeatureOn && tasksData && tasksData.redactedCount || 0,
                documents: documentsFeatureOn && documentData && documentData.redactedCount || 0,
              }
            }, ctrl.composeKey || DEFAULT_COMPOSE_KEY);

            // Schedule a search
            queuedDocumentSearch = () =>
              Promise.all([
                memosFeatureOn ? apolloClient.query({
                  query: MemoSearch,
                  context: { skipRequestBatching: true },
                  variables: {
                    filter: { searchTerm },
                  },
                  fetchPolicy: 'network-only',
                }).then(r => {
                  memoData = r.data.memoSearch;
                  updateResults();
                }).catch(() => {
                  messageDisplay.error("Error searching memos, please try again later")
                  memoData = { results: [] };
                  updateResults();
                }) : Promise.resolve(),
                documentsFeatureOn ? apolloClient.query({
                  context: { skipRequestBatching: true },
                  query: DocumentSearch,
                  variables: {
                    filter: { searchTerm },
                  },
                  fetchPolicy: 'network-only',
                }).then(r => {
                  documentData = r.data.documentSearch;
                  updateResults();
                }).catch(() => {
                  messageDisplay.error("Error searching documents, please try again later")
                  documentData = { results: [] };
                  updateResults();
                }) : Promise.resolve(),
                eventsFeatureOn ? apolloClient.query({
                  context: { skipRequestBatching: true },
                  query: EventSearch,
                  variables: {
                    filter: { searchTerm },
                  },
                  fetchPolicy: 'network-only',
                }).then(r => {
                  eventsData = r.data.eventSearch;
                  updateResults();
                }).catch(() => {
                  messageDisplay.error("Error searching events, please try again later")
                  eventsData = { results: [] };
                  updateResults();
                }) : Promise.resolve(),
                tasksFeatureOn ? apolloClient.query({
                  context: { skipRequestBatching: true },
                  query: TaskSearch,
                  variables: {
                    taskFilter: { searchText: searchTerm },
                    offset: 0,
                    limit: 100,
                  },
                  fetchPolicy: 'network-only',
                }).then(r => {
                  tasksData = r.data.taskList;
                  updateResults();
                }).catch(() => {
                  messageDisplay.error("Error searching tasks, please try again later")
                  tasksData = { results: [] };
                  updateResults();
                }) : Promise.resolve(),
              ]);
            // Wait for any searches in progress. Searches have an extremely variable execution time and without this old searches may overwrite newer ones
            await documentSearchPromise;
            // Execute the waiting search. There may be multiple invocations at this step so we should ensure this only gets executed once
            if (queuedDocumentSearch) {
              documentSearchPromise = queuedDocumentSearch().then(() => {
                updateResults();
                // zero any previous results before executing search
                memoData = null;
                documentData = null;
                eventsData = null;
                tasksData = null;
              });
              // Clear previously queued search as we are executing it now
              queuedDocumentSearch = null;
              await documentSearchPromise;
            }
          });
        }
      },
      DOCUMENT_SEARCH_BOUNCE_TIMEOUT_MS,
      { leading: false },
    );

    let initialised = false;

    ctrl.$onInit = initialise;

    const updateData = debounce(
      () => {
        $scope.$applyAsync(() => {
          ctrl.sbComposeCtrl.setComposeData(searchForResults(), ctrl.composeKey || DEFAULT_COMPOSE_KEY);
          searchDocuments();
        });
      },
      SEARCH_BOUNCE_TIMEOUT_MS,
      { leading: true },
    );

    ctrl.$onChanges = (changesObj) => {
      //this is needed because the component lifecycle hooks go in order change, init ...
      if (!initialised) {
        initialise();
      }

      if (changesObj.searchTerm) {
        log.info('update data', changesObj);
        updateData();
      }
    };

    function initialise() {
      log.info('composeKey', ctrl.composeKey);

      // Set up the search options.
      const defaultOptions = {
        searchInvoiceOnly: false,
        allMatters: false,
      };

      ctrl.searchOptions = _.merge(defaultOptions, ctrl.searchOptions || {});

      initialised = true;
    }

    function searchForResults() {
      const searchTerm = preProcessSearchTerm();

      // Clear the compose data if the search term is empty
      if (_.isEmpty(searchTerm)) {
        return { invoices: [], contacts: [], matters: [], documents: [] };
      }

      const matterTypeAhead = getMatterTypeaheadSummaries().filter(m => !m.isLead);
      const leadsTypeAhead = getMatterTypeaheadSummaries(true).filter(m => m.isLead);
      const matters = ctrl.searchOptions.searchInvoiceOnly
        ? []
        : sbGenericSearchService.search(
          matterTypeAhead,
          'id',
          ['typeahead', 'matterClientNames', 'clientDisplay', 'matterStartedISO'],
          searchTerm,
        );
      const leads = ctrl.searchOptions.searchInvoiceOnly
        ? []
        : sbGenericSearchService.search(
          leadsTypeAhead,
          'id',
          ['typeahead', 'matterClientNames', 'clientDisplay', 'matterStartedISO'],
          searchTerm,
        );

      const invoices = hasBillingAccess()
        ? sbGenericSearchService.search(
          getInvoiceTypeaheadSummaries(),
          'id',
          ['typeahead'],
          searchTerm,
        )
      : [];

      const contacts = ctrl.searchOptions.searchInvoiceOnly
        ? []
        : sbGenericSearchService.search(getContactTypeAheadSummaries(), 'id', ['typeahead'], searchTerm);

      const documents = ctrl.searchOptions.searchInvoiceOnly ? [] : null;

      return { invoices, contacts, matters, leads, documents };
    }

    function preProcessSearchTerm() {
      let term = (ctrl.searchTerm && ctrl.searchTerm.trim()) || '';

      // We make searching sane, by only searching when we have 3 or more characters in our search term, with the exception of invoices.
      // We use the # symbol to indicate that a search should be for an invoice only, in which case there is no length restriction as
      // the number of potential matches is much smaller.
      if (term.length > 0 && term[0] === '#') {
        ctrl.searchOptions.searchInvoiceOnly = true;
      } else {
        ctrl.searchOptions.searchInvoiceOnly = false;
        term = term.length > 2 && term;
      }

      return term;
    }
  },
});
