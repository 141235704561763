import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query ContactMattersData(
    $matterListFilter: MatterListFilter
    $includeIsOverdrawn: Boolean!
    $sort: ListSort
    $limit: Int
    $offset: Int
  ) {
    matterList(filter: $matterListFilter, sort: $sort, limit: $limit, offset: $offset) {
      totalCount
      results {
        id
        matterNumber
        description
        status
        matterType {
          matterTypeId
          matterTypeIdWithoutLocation
          name
        }
        otherSide {
          id
          displayName
        }
        otherSideDisplay
        attorneyResponsible {
          id
          initials
        }
        clients {
          id
          displayName
        }
        clientDisplay
        matterTotals {
          id
          unbilled
          unpaidExcInterest
        }
        matterBalanceTrustSummary {
          matterId
          isOverdrawn @include(if: $includeIsOverdrawn)
        }
      }
    }
  }
`;

const notificationIds = [
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
  'WebQueryBillingMattersNotifications.BillingMatterTotalsUpdated',
  'WebQueryAccountsNotifications.AccountBalanceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ContactMattersData = {
  query,
  notificationIds,
};
