'use strict';

const { getTypeAsMaps, getTypeAsOptions } = require('@sb-itops/enum-helpers');

const entryType = {
  FIXED: 0,
  TIME: 1,
  EXPENSE: 2,
};

const entryTypeLabels = {
  FIXED: 'Fee',
  TIME: 'Time',
  EXPENSE: 'Expense',
};

const [entryTypeMap, entryTypeLabelToValueMap] = getTypeAsMaps({
  typeLabels: entryTypeLabels,
  typeValues: entryType,
});

const entryTypeOptions = getTypeAsOptions({
  typeLabels: entryTypeLabels,
  typeValues: entryType,
});

module.exports = {
  entryType,
  entryTypeLabels,
  entryTypeMap,
  entryTypeLabelToValueMap,
  entryTypeOptions,
};
