import _ from 'lodash';

const roleToCategory = {
  Client: 'Clients',
  Attorney: 'Attorneys',
  WCC: 'Courts',
  AuthorityCourt: 'Courts',
  Judge: 'Judges',
  Arbitrator: 'Arbitrators',
  EmployeeDoctor: 'Doctors',
  EmployerDoctor: 'Doctors',
  HomeownerAssociation: 'Homeowner Associations',
  Insurer: 'Insurers',
  DefendantInsurer: 'Insurers',
  PlaintiffInsurer: 'Insurers',
  Investigator: 'Investigators',
  Lender: 'Lenders',
  SellerLender: 'Lenders',
  Mediator: 'Mediators',
  Prosecutor: 'Prosecutors',
  ListingRealtor: 'Realtors',
  SellingRealtor: 'Realtors',
  ListingBroker: 'Realtors',
  SellingBroker: 'Realtors',
  LandlordBroker: 'Realtors',
  TenantBroker: 'Realtors',
  LeasingRealtor: 'Realtors',
  Surveyors: 'Surveyors',
  TitleCompany: 'Title Companies',
};

export const categoryToRole = Object.fromEntries(Object.entries(roleToCategory).map((entries) => entries.reverse()));

function contactsByCategory(contactsByRole, roleToCategoryMap) {
  const newState = {};

  _.each(contactsByRole, (ids, role) => {
    const category = roleToCategoryMap[role];
    const idMap = newState[category] || {};

    newState[category] = idMap;

    _.each(ids, (id) => {
      idMap[id] = true;
    });
  });

  return newState;
}

// dereference representativeOfs to actual entities
function denormalizeContacts(contacts, state) {
  const denormalizedContacts = contacts.map((contact) => {
    if (!contact.representativeOfs || contact.representativeOfs.length === 0) {
      return contact;
    }
    const contactWithFirms = { ...contact };
    // use a reduce to remove crap data
    contactWithFirms.representativeOfs = contact.representativeOfs.reduce((firms, entityId) => {
      if (state[entityId]) {
        firms.push(state[entityId]);
      }
      return firms;
    }, []);
    return contactWithFirms;
  });

  return denormalizedContacts;
}

export default function getByCategory(contacts, contactsByRole, category, state) {
  const denormalizedContacts = denormalizeContacts(contacts, state);

  if (category === 'All') {
    return _.values(denormalizedContacts);
  }

  const entitiesByCategory = contactsByCategory(contactsByRole, roleToCategory);

  return Object.values(denormalizedContacts).filter(
    (contact) => !entitiesByCategory[category] || entitiesByCategory[category][contact.entityId],
  );
}
