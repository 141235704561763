import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { todayAsInteger } from '@sb-itops/date';
import { getById as getMatterCommunicationSettings } from '@sb-billing/redux/matter-communication-settings';
import { externallySentViaMap, getExternalSentViaOptions } from '@sb-billing/business-logic/correspondence-history';
import { hasFacet, facets } from '@sb-itops/region-facets';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { markAsSentFormSchema } from './mark-as-sent-form-schema';
import { MarkAsSentForm } from './MarkAsSentForm';

const hooks = (props) => ({
  useForm: () => {
    const { matterIds, scope } = props;
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);

    const { formInitialised, fields: formFields } = useSelector(formSelectors.getFormState);

    const { internalNote, dateSent, sentVia } = formFields;

    const matterCommunicationSettings = getMatterCommunicationSettings(matterIds[0]) || {};
    const matterCorrespondencePreferences = matterCommunicationSettings.correspondencePreferences || [];
    const isSendViaNotMatchMatterPreference =
      matterCorrespondencePreferences.length > 0 && !matterCorrespondencePreferences.includes(sentVia?.value);

    const externalSentViaOptions = getExternalSentViaOptions({
      isEBillingAvailable: hasFacet(facets.eBillingSendingPreference),
    });

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        prepareFormData({
          matterCorrespondencePreferences,
          formActions,
          formOperations,
        }),
      );
      const onUnload = () => {
        dispatch(formActions.clearForm());
      };
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onFieldValueUpdated = (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: markAsSentFormSchema }));
    };

    return {
      // form fields
      internalNote,
      dateSent,
      sentVia,
      // form state
      isLoading: !formInitialised,
      // other
      externalSentViaOptions,
      isSendViaNotMatchMatterPreference,
      // func & callbacks
      onFieldValueUpdated,
    };
  },
});

const prepareFormData =
  ({ matterCorrespondencePreferences, formActions, formOperations }) =>
  async (dispatch) => {
    const defaultSentVia =
      matterCorrespondencePreferences.find((preference) => !!externallySentViaMap[preference]) || '';
    const todayAsInt = todayAsInteger();

    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          internalNote: '',
          dateSent: todayAsInt,
          sentVia: defaultSentVia,
        },
      }),
    );
    dispatch(formOperations.validateSchema({ schema: markAsSentFormSchema }));
  };

export const MarkAsSentFormContainer = withReduxProvider(composeHooks(hooks)(MarkAsSentForm));

MarkAsSentFormContainer.displayName = 'MarkAsSentFormContainer';

MarkAsSentFormContainer.propTypes = {
  matterIds: PropTypes.arrayOf(PropTypes.string),
};

MarkAsSentFormContainer.defaultProps = {
  matterIds: [],
};

export default MarkAsSentFormContainer;
