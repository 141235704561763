import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect, forms2PropTypes, FormLabel } from '@sb-itops/react';
import { t } from '@sb-itops/localisation-web';
import { ContactTypeahead } from '@sb-customer-management/react';

import { includeErrorClassnameIfInvalid } from '../../add-edit-matter/include-error-classname-if-invalid';

export const MatterStaff = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields
  attorneyResponsibleId,
  personAssistingId,
  originatingAttorneyId,
  referralType,
  referrerId,
  // select options
  staffOptions,
  contactOptions,
  // callbacks
  onFieldValuesUpdated,
  setAddContactCallback,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={t('capitalizeAll', { val: 'personResponsible' })}
            field={attorneyResponsibleId}
            submitFailed={submitFailed}
            optional
            uppercase={false}
          />
          <div>
            <StatelessSelect
              placeholder="Select staff responsible for matter..."
              options={staffOptions}
              className={classnames(includeErrorClassnameIfInvalid(attorneyResponsibleId, submitFailed))}
              selectedOption={attorneyResponsibleId && attorneyResponsibleId.value}
              disabled={formDisabled}
              onChange={(newStaffOption) => onFieldValuesUpdated('attorneyResponsibleId', newStaffOption?.value)}
              isClearable
              showDropdown={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            uppercase={false}
            label="Person Assisting"
            field={personAssistingId}
            submitFailed={submitFailed}
            optional
          />
          <div>
            <StatelessSelect
              placeholder="Select staff assisting matter..."
              options={staffOptions}
              className={classnames(includeErrorClassnameIfInvalid(personAssistingId, submitFailed))}
              selectedOption={personAssistingId && personAssistingId.value}
              disabled={formDisabled}
              onChange={(newStaffOption) => onFieldValuesUpdated('personAssistingId', newStaffOption?.value)}
              isClearable
              showDropdown={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={t('capitalizeAllWords', { val: 'introducer' })}
            field={originatingAttorneyId}
            submitFailed={submitFailed}
            optional
            uppercase={false}
          />
          <div>
            <StatelessSelect
              placeholder={`Select ${t('introducer')} for matter...`}
              options={staffOptions}
              className={classnames(includeErrorClassnameIfInvalid(originatingAttorneyId, submitFailed))}
              selectedOption={originatingAttorneyId && originatingAttorneyId.value}
              disabled={formDisabled}
              onChange={(newStaffOption) => onFieldValuesUpdated('originatingAttorneyId', newStaffOption?.value)}
              isClearable
              showDropdown={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 form-group">
          <FormLabel
            uppercase={false}
            label="Referral Type"
            field={referralType}
            submitFailed={submitFailed}
            optional
          />
          <StatelessSelect
            placeholder="Select type..."
            options={t('referralTypes')
              .split(',')
              .map((v) => ({ label: v, value: v }))}
            className={classnames(includeErrorClassnameIfInvalid(referralType, submitFailed))}
            selectedOption={referralType && referralType.value}
            disabled={formDisabled}
            onChange={(newType) => onFieldValuesUpdated('referralType', newType?.value)}
            isClearable
            showDropdown={false}
          />
        </div>
        <div className="col-lg-8 form-group">
          <FormLabel uppercase={false} label="Referrer" field={referrerId} submitFailed={submitFailed} optional />
          <div>
            <ContactTypeahead
              contacts={contactOptions}
              onContactSelected={(newReferrer) => onFieldValuesUpdated('referrerId', newReferrer?.value)}
              selectedContactId={referrerId && referrerId.value}
              disabled={formDisabled}
              placeholder="Select referrer for matter..."
              actionList={[
                {
                  displayComponent: (
                    <span>
                      <i className="fa fa-plus" /> &emsp;<strong>Add Contact</strong>
                    </span>
                  ),
                  callback: () =>
                    setAddContactCallback(() => (contact) => onFieldValuesUpdated('referrerId', contact.id)),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
};

const selectOptions = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const { Forms2Field } = forms2PropTypes;

MatterStaff.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  attorneyResponsibleId: Forms2Field,
  personAssistingId: Forms2Field,
  originatingAttorneyId: Forms2Field,
  referrerId: Forms2Field,
  referralType: Forms2Field,
  // select options
  staffOptions: PropTypes.arrayOf(selectOptions),
  contactOptions: PropTypes.arrayOf(PropTypes.object),
  // callbacks
  setAddContactCallback: PropTypes.func.isRequired,
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterStaff.defaultProps = {
  // form state
  formDisabled: undefined,
  submitFailed: undefined,
  // form fields
  attorneyResponsibleId: undefined,
  personAssistingId: undefined,
  originatingAttorneyId: undefined,
  referrerId: undefined,
  referralType: undefined,
  // select options
  staffOptions: [],
  contactOptions: [],
};

MatterStaff.displayName = 'MatterStaff';
