import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'utbms-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbUtbmsSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const isUtbmsEnabled = () => {
  const utbmsSettings = getList()[0];
  return utbmsSettings && utbmsSettings.isUtbmsEnabled;
};
