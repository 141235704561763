/* eslint-disable import/no-cycle */
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';

import { getById, opdateCache, rollbackOpdateCache } from './index';

export const saveInterestSettings = async (accountId, interestSettings) => {
  await store.dispatch(async () => {
    const { matterId, invoiceInterestSettings } = interestSettings;
    const existingInterestSettings = getById(matterId) || {};
    const finalSettings = {
      ...existingInterestSettings.settings,
      ratePA: invoiceInterestSettings.ratePa,
      gracePeriodDays: invoiceInterestSettings.gracePeriodDays,
      interestApplyMode: invoiceInterestSettings.interestApplyMode,
    };
    const interestSettingsToOpdate = {
      matterId,
      accountId,
      settings: finalSettings,
    };

    opdateCache({ optimisticEntities: [interestSettingsToOpdate] });

    try {
      await fetchPostP({
        path: '/billing/interest-settings/:accountId/',
        fetchOptions: { body: JSON.stringify(interestSettings) },
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [interestSettingsToOpdate] });
      throw err;
    }
  });
};
