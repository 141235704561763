import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, useTranslation, buttonTypes, LoadingBarInfinite } from '@sb-itops/react';
import Styles from './DepositFundsModal.module.scss';
import { DepositFundsModalBody } from './DepositFundsModalBody';

export const DEPOSIT_FUNDS_MODAL_ID = 'deposit-funds-new-modal';

export const DepositFundsModal = ({
  showModal,
  isModalLoading,
  onModalClose,
  submitIsDisabled,
  processIsLocked,
  processReceiptIsLocked,

  // form
  formData,
  formErrors, // Contains data related to form errors
  submitFailed,
  formSubmitting,
  depositSourceOptions,
  validationDataLoading,
  clientsMissingAddresses,
  // ui
  totalAmount,
  disableAddRow,
  disableBankAccount,
  readOnlyReference,
  isChequeSource,
  supportsElectronicPayment,
  useBankNameForDepositFunds,
  entryMatterRowRef,
  addRowTooltip,
  allowReceipt,
  // callbacks
  onUpdateFieldValues,
  onAddMatterRow,
  onRemoveMatterRow,
  onUpdateLockedRowAmount,
  onSelectMatter,
  onSelectReceivedFrom,
  onSelectDrawer,
  onSelectBankAccount,
  onSelectEffectiveDate,
  onProcess,
  onOpenEditContactModal,
  // bank accounts
  bankAccountOptions,
  bankAccountOptionsLoading,
  bankReconciliationLoading,
  // matter typeahead
  matterDetailsLoading,
  matterTrustBankAccountIdsLoading,
  defaultMatterSummaries,
  matterSummaries,
  matterSummariesDataLoading,
  matterSummariesHasMore,
  onFetchMatterSummaries,
  onFetchMoreMatterSummaries,
  // received from typeahead
  defaultReceivedFromContactOptions,
  receivedFromContactOptions,
  receivedFromContactOptionsDataLoading,
  receivedFromContactOptionsHasMore,
  onFetchReceivedFromContactOptions,
  onFetchMoreReceivedFromContactOptions,
  // drawer typeahead
  drawerContactOptions,
  drawerContactOptionsDataLoading,
  drawerContactOptionsHasMore,
  onFetchDrawerContactOptions,
  onFetchMoreDrawerContactOptions,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      className={Styles.depositFundsModal}
      modalHeaderClassName={Styles.modalHeaderContainer}
      modalBodyClassName={Styles.modalBodyContainer}
      modalFooterClassName={Styles.modalFooterContainer}
      isVisible={showModal}
      title="Deposit Funds"
      body={
        isModalLoading ? (
          <div className={Styles.modalBodyLoadingContainer}>
            <LoadingBarInfinite loading={isModalLoading} />
          </div>
        ) : (
          <DepositFundsModalBody
            // form
            formData={formData}
            formErrors={formErrors}
            submitFailed={submitFailed}
            formDisabled={formSubmitting}
            depositSourceOptions={depositSourceOptions}
            validationDataLoading={validationDataLoading}
            clientsMissingAddresses={clientsMissingAddresses}
            // ui
            disableAddRow={disableAddRow}
            disableBankAccount={disableBankAccount}
            readOnlyReference={readOnlyReference}
            isChequeSource={isChequeSource}
            supportsElectronicPayment={supportsElectronicPayment}
            useBankNameForDepositFunds={useBankNameForDepositFunds}
            entryMatterRowRef={entryMatterRowRef}
            addRowTooltip={addRowTooltip}
            // callbacks
            onUpdateFieldValues={onUpdateFieldValues}
            onAddMatterRow={onAddMatterRow}
            onRemoveMatterRow={onRemoveMatterRow}
            onUpdateLockedRowAmount={onUpdateLockedRowAmount}
            onSelectMatter={onSelectMatter}
            onSelectReceivedFrom={onSelectReceivedFrom}
            onSelectDrawer={onSelectDrawer}
            onSelectBankAccount={onSelectBankAccount}
            onSelectEffectiveDate={onSelectEffectiveDate}
            onOpenEditContactModal={onOpenEditContactModal}
            // bank accounts
            bankAccountOptions={bankAccountOptions}
            bankAccountOptionsLoading={bankAccountOptionsLoading}
            bankReconciliationLoading={bankReconciliationLoading}
            // matter typeahead
            matterDetailsLoading={matterDetailsLoading}
            matterTrustBankAccountIdsLoading={matterTrustBankAccountIdsLoading}
            defaultMatterSummaries={defaultMatterSummaries}
            matterSummaries={matterSummaries}
            matterSummariesDataLoading={matterSummariesDataLoading}
            matterSummariesHasMore={matterSummariesHasMore}
            onFetchMatterSummaries={onFetchMatterSummaries}
            onFetchMoreMatterSummaries={onFetchMoreMatterSummaries}
            // received from typeahead
            defaultReceivedFromContactOptions={defaultReceivedFromContactOptions}
            receivedFromContactOptions={receivedFromContactOptions}
            receivedFromContactOptionsDataLoading={receivedFromContactOptionsDataLoading}
            receivedFromContactOptionsHasMore={receivedFromContactOptionsHasMore}
            onFetchReceivedFromContactOptions={onFetchReceivedFromContactOptions}
            onFetchMoreReceivedFromContactOptions={onFetchMoreReceivedFromContactOptions}
            // drawer typeahead
            drawerContactOptions={drawerContactOptions}
            drawerContactOptionsDataLoading={drawerContactOptionsDataLoading}
            drawerContactOptionsHasMore={drawerContactOptionsHasMore}
            onFetchDrawerContactOptions={onFetchDrawerContactOptions}
            onFetchMoreDrawerContactOptions={onFetchMoreDrawerContactOptions}
          />
        )
      }
      footer={
        <>
          <div className={Styles.totalAmountContainer}>
            <div className={Styles.amount}>{t('cents', { val: totalAmount })}</div>
            <div className={Styles.label}>Total amount</div>
          </div>
          <div className={Styles.buttonContainer}>
            {allowReceipt ? (
              <>
                <Button
                  className={Styles.processButton}
                  type={buttonTypes.secondary}
                  onClick={() => onProcess({ withReceipt: false })}
                  disabled={submitIsDisabled}
                  locked={processIsLocked}
                >
                  Process Only
                </Button>

                <Button
                  className={Styles.processAndReceiptButton}
                  onClick={() => onProcess({ withReceipt: true })}
                  disabled={submitIsDisabled}
                  locked={processReceiptIsLocked}
                >
                  Process/Open Receipt
                </Button>
              </>
            ) : (
              <Button
                className={Styles.processAndReceiptButton}
                onClick={() => onProcess({ withReceipt: false })}
                disabled={submitIsDisabled}
                locked={processIsLocked}
              >
                Process
              </Button>
            )}
          </div>
        </>
      }
      onModalClose={onModalClose}
    />
  );
};

DepositFundsModal.propTypes = {
  isModalLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  submitIsDisabled: PropTypes.bool.isRequired,
  processIsLocked: PropTypes.bool.isRequired,
  processReceiptIsLocked: PropTypes.bool.isRequired,
  // form
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  depositSourceOptions: PropTypes.array.isRequired,
  validationDataLoading: PropTypes.bool.isRequired,
  clientsMissingAddresses: PropTypes.array.isRequired,
  // bank accounts
  bankAccountOptions: PropTypes.array.isRequired,
  bankAccountOptionsLoading: PropTypes.bool.isRequired,
  bankReconciliationLoading: PropTypes.bool.isRequired,
  // ui
  totalAmount: PropTypes.number.isRequired,
  disableAddRow: PropTypes.bool.isRequired,
  disableBankAccount: PropTypes.bool.isRequired,
  readOnlyReference: PropTypes.bool.isRequired,
  isChequeSource: PropTypes.bool.isRequired,
  supportsElectronicPayment: PropTypes.bool.isRequired,
  useBankNameForDepositFunds: PropTypes.bool.isRequired,
  entryMatterRowRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  addRowTooltip: PropTypes.string,
  allowReceipt: PropTypes.bool.isRequired,
  // callbacks
  onUpdateFieldValues: PropTypes.func.isRequired,
  onAddMatterRow: PropTypes.func.isRequired,
  onRemoveMatterRow: PropTypes.func.isRequired,
  onUpdateLockedRowAmount: PropTypes.func.isRequired,
  onSelectMatter: PropTypes.func.isRequired,
  onSelectReceivedFrom: PropTypes.func.isRequired,
  onSelectDrawer: PropTypes.func.isRequired,
  onSelectBankAccount: PropTypes.func.isRequired,
  onSelectEffectiveDate: PropTypes.func.isRequired,
  onProcess: PropTypes.func.isRequired,
  onOpenEditContactModal: PropTypes.func.isRequired,
  // matter typeahead
  matterDetailsLoading: PropTypes.bool.isRequired,
  matterTrustBankAccountIdsLoading: PropTypes.bool.isRequired,
  defaultMatterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterSummariesDataLoading: PropTypes.bool.isRequired,
  matterSummariesHasMore: PropTypes.bool.isRequired,
  onFetchMatterSummaries: PropTypes.func.isRequired,
  onFetchMoreMatterSummaries: PropTypes.func.isRequired,
  // received from typeahead
  defaultReceivedFromContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  receivedFromContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  receivedFromContactOptionsDataLoading: PropTypes.bool.isRequired,
  receivedFromContactOptionsHasMore: PropTypes.bool.isRequired,
  onFetchReceivedFromContactOptions: PropTypes.func.isRequired,
  onFetchMoreReceivedFromContactOptions: PropTypes.func.isRequired,
  // drawer typeahead
  drawerContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  drawerContactOptionsDataLoading: PropTypes.bool.isRequired,
  drawerContactOptionsHasMore: PropTypes.bool.isRequired,
  onFetchDrawerContactOptions: PropTypes.func.isRequired,
  onFetchMoreDrawerContactOptions: PropTypes.func.isRequired,
};
DepositFundsModal.defaultProps = {
  entryMatterRowRef: undefined,
  addRowTooltip: undefined,
};

DepositFundsModal.displayName = 'DepositFundsModal';
