angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/add-edit-matter';

  $stateProvider
    .state('home.billing.add-matter', {
      url: '/add-matter',
      templateUrl: `${BASE_DIRECTORY}/add-matter.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      params: {
        contactId: null,
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters'],
        tab: { type: 'add-matter' },
        derivePersistenceKey: (stateName) => `${stateName}`,
      }
    })
    .state('home.billing.edit-matter', {
      url: '/edit-matter/:matterId',
      templateUrl: `${BASE_DIRECTORY}/edit-matter.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters'],
        tab: { type: 'edit-matter' },
        derivePersistenceKey: (stateName, matter) => `${stateName}.matter-id.${matter && matter.matterId}`,
      }
    });
});