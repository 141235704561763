import React from 'react';
import { DatePicker, SlidingToggle } from '@sb-itops/react';

import Styles from '../ReportFilter.module.scss';

const CmaListingFilter = ({ onEndSelect, onToggleOption, filters }) => {
  if (!filters) {
    return null;
  }

  const { endDate, includeFirmHeader, includeFirmLogo, includeFirmName, errors } = filters;

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.period}>
        <label>To</label>
        <DatePicker onSelect={onEndSelect} value={endDate} hasError={errors.endDate} />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

CmaListingFilter.displayName = 'CmaListingFilter';

export default CmaListingFilter;
