angular.module('sb.billing.webapp').filter('sbStaffSearch', function () {
  'use strict';

  return function(staff, searchString) {
    return _.filter(staff, function (member) {
      var matcher = new RegExp(searchString, 'i');

      if (member.name && member.name.match(matcher)) {
        return true;
      }
      if (member.initials && member.initials.match(matcher)) {
        return true;
      }
    });
  };
});
