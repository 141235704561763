/**
 * Read value from another field
 *
 * @param {Object} param
 * @param {string} param.fieldName - Field to read value from
 * @param {string} [param.nestedFieldName] - If fieldName returns a reference or an array of references, this
 *                                           key is used to extract the values from each property in the array
 * @param {string} [param.joinSeparator=" | "] - Separator used to join all the values from the array
 * @returns {(value, { readField }) => String|Object}
 */
export const readValueFromAnotherField =
  ({ fieldName, nestedFieldName, joinSeparator = ' | ' }) =>
  (value, { readField }) => {
    const field = readField({ fieldName });

    // If the field is a reference to another entity, return
    // the value of the nested field within that entity
    if (field && field.__ref) {
      const theField = readField({ fieldName: nestedFieldName, from: field });
      return theField;
    }

    // If the field is not a reference and it is not an array,
    // then it holds the resolved value
    if (!Array.isArray(field)) {
      return field;
    }

    // The field is an array, collate the nested field values and concatenate them
    const fieldContents = field
      .map((entityRef) => readField({ fieldName: nestedFieldName, from: entityRef }))
      .join(joinSeparator);
    return fieldContents;
  };
