/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spinner, useTranslation } from '@sb-itops/react';
import React from 'react';
import { MatterDisplay } from '@sb-matter-management/react';
import { humanise } from 'web/business-logic/calendar';
import { AddEventModalContainer } from 'web/containers';
import classNames from 'classnames';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './EventsWidget.module.scss';

export const EventsWidget = ({
  isEditing,
  events,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  showEventModal,
  setShowEventModal,
  matterId,
}) => {
  const { t } = useTranslation();

  const getTimestampDisplay = (d) => {
    const diff = moment().startOf('day').diff(moment(d), 'days');

    if (diff === 0) {
      return <div className={WidgetStyles.bold}>Today</div>;
    }
    if (diff === -1) {
      return <div>Tomorrow</div>;
    }
    if (diff > 1) {
      return <div>{t('date', { ts: d })}</div>;
    }
    if (diff < -1) {
      return <div>{t('date', { ts: d })}</div>;
    }
    return null;
  };

  return (
    <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
      <WidgetTitle
        isEditing={isEditing}
        onClick={() => {
          if (matterId) {
            onClickLink({ id: matterId, type: 'matterEvents' });
          } else {
            onClickLink({ type: 'productivityCalendar' });
          }
        }}
        onChange={onChange}
        onRemove={onRemove}
        settings={settings}
      />
      {loading ? (
        <div className={WidgetStyles.loading}>
          <Spinner small />
        </div>
      ) : (
        <div className={WidgetStyles.content}>
          {events.length === 0 && 'No upcoming events'}
          {events.map((event) => (
            <div
              key={event.id || event.originalId + event.endTime}
              className={Styles.event}
              onClick={() => {
                setShowEventModal(event);
              }}
            >
              <div className={Styles.time}>
                <div>{moment(event.startTime).format('hh:mm A')}</div>
                <div className={Styles.duration}>
                  {humanise(moment.duration(moment(event.endTime).diff(moment(event.startTime))))}
                </div>
              </div>
              <div className={Styles.description}>
                <div className={classNames(Styles.subject, WidgetStyles.bold)}>{event.subject}</div>
                {!matterId && <MatterDisplay onClickLink={onClickLink} asLink matterId={event.matterId} />}
                <div className={Styles.staff}>{event.staff.join(', ')}</div>
                <div className={Styles.staff}>{event.location}</div>
              </div>
              <div className={classNames(WidgetStyles.alignRight, WidgetStyles.noShrink)}>
                {getTimestampDisplay(event.startTime)}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={WidgetStyles.separator} />
      <a className={WidgetStyles.footerAction} onClick={() => setShowEventModal({})}>
        ADD EVENT
      </a>
      {showEventModal && (
        <AddEventModalContainer
          matterId={matterId}
          appointmentData={showEventModal}
          onClose={() => setShowEventModal(false)}
        />
      )}
    </div>
  );
};
