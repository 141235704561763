import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as tabsFeature from '@sb-itops/redux/tabs';
import * as formsFeature from '@sb-itops/redux/forms2';
import { buildFeatureScope } from '@sb-itops/build-feature-scope';

export const paymentPlanModalDialogFeature = (state) => {
  const scope = buildFeatureScope('create-edit-payment-plan');

  return withScopedFeatures({ state, scope })({
    tabs: tabsFeature,
    forms: formsFeature,
  });
};
