/* eslint-disable prettier/prettier */
import React from "react";
import PropTypes from "prop-types";
import { Spinner, Button, Modal, Icon } from "@sb-itops/react";
import { CreditCardSaveForm } from "../credit-card-save-form";
import Styles from "./CreditCardSaveModal.module.scss";

export const CreditCardSaveModal = (props) => {
  const { readyForSubmit, isSubmitting, onTriggerSubmit, onClose, isVisible, selectedCard, bankAccountId, contactId } = props;

  const isSubmitDisabled = !readyForSubmit || isSubmitting;

  return (
    <Modal
      title={selectedCard ?
        <>
          <span className={Styles.titleInfo}>Replace Credit Card</span>
          <Icon
            type="information"
            color="darkslategrey"
            tooltip="This will replace the selected card with a completely new one. Any recurring billing using the previous card will be updated."
          />
        </> : "Save Credit Card"}
      body={
        <CreditCardSaveForm
          triggerSubmit={props.triggerChargeFormSubmit}
          isSubmitting={props.isSubmitting}
          onSubmit={props.onChargeFormSubmit}
          onReadyForSubmit={props.onChargeFormReadyForSubmit}
          bankAccountId={bankAccountId}
          initialValues={selectedCard}
          contactId={contactId}
        />
      }
      isVisible={isVisible}
      footer={
        <div>
          <Button onClick={onTriggerSubmit} disabled={isSubmitDisabled}>
            {selectedCard ? "Replace" : "Save"} Card {isSubmitting && <Spinner small />}
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  );
}

CreditCardSaveModal.displayName = "CreditCardSaveModal";

CreditCardSaveModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  readyForSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  onTriggerSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCard: PropTypes.object,
  bankAccountId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
  // form
  triggerChargeFormSubmit: PropTypes.bool,
  onChargeFormSubmit: PropTypes.func.isRequired,
  onChargeFormReadyForSubmit: PropTypes.func.isRequired,
};

CreditCardSaveModal.defaultProps = {
  isSubmitting: false,
  triggerChargeFormSubmit: false,
  selectedCard: undefined
};
