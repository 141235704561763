import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { store } from '@sb-itops/redux';
import * as messageDisplay from '@sb-itops/message-display';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { bankAccountState, bankAccountSaveAction } from '@sb-billing/business-logic/bank-account/entities/constants';
import { generateTransactionList } from '@sb-billing/business-logic/bank-reconciliation/transaction-list';
import { ACCOUNT_TYPE, saveBankAccount, getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { saveSettings as saveBankAccountSettings } from '@sb-billing/redux/bank-account-settings/save-settings';
import {
  getLastCompleted as getLastCompletedBankRec,
  getLatest as getLatestBankReconciliation,
} from '@sb-billing/redux/bank-reconciliations';
import { getByBankAccountId as getTransactionList } from '@sb-billing/redux/transactions';
import { TrustAccountCloseModal } from './TrustAccountCloseModal';

const scope = 'trust-account-close-modal';

export const TrustAccountCloseModalContainer = withReduxProvider(({ bankAccountId, onCloseCard, onCloseModal }) => {
  const [isLocked, setIsLocked] = useState(false);
  const { t } = useTranslation();

  const bankAccountIsDefaultPaymentAccount = !!(getBankAccountSettings()?.defaultTrustAccounts || []).find(
    (account) => account.defaultTrustAccountId === bankAccountId,
  );

  const bankAccountHasOpenAdjustments = hasOpenAdjustments(bankAccountId);
  const bankAccountIsReconciled =
    !hasUnreconciledTransactions(bankAccountId) && !hasReconciliationInProgess(bankAccountId);

  const showWarningOnCloseTrustAccount = hasFacet(facets.canCloseTrustAccountWithWarning);

  return (
    <TrustAccountCloseModal
      {...{
        scope,
        onCloseModal,
        bankAccountHasOpenAdjustments,
        bankAccountId,
        bankAccountIsDefaultPaymentAccount,
        bankAccountIsReconciled,
        isLocked,
        showWarningOnCloseTrustAccount,
        onCloseAccount: async (event) => {
          setIsLocked(true);
          event.preventDefault();

          try {
            await closeAccount({ bankAccountId, bankAccountIsDefaultPaymentAccount, t });

            onCloseModal();
            onCloseCard();
            messageDisplay.success(`Account closed successfully.`);
          } catch (err) {
            setIsLocked(false);
            messageDisplay.error(`Failed to close the account`);
          }
        },
      }}
    />
  );
});

const closeAccount = async ({ bankAccountId, bankAccountIsDefaultPaymentAccount, t }) => {
  const reason = 'Web settings - close';
  const bankAccount = getBankAccountById(bankAccountId);
  // Endpoint expect accountName so we need to make sure there is one
  const accountName = bankAccount.accountName || t('trustAccount');

  await saveBankAccount({
    id: bankAccountId,
    accountType: ACCOUNT_TYPE.trust.toUpperCase(),
    state: bankAccountState.CLOSED,
    internalNote: reason,
    reason,
    accountName,
    action: bankAccountSaveAction.CLOSE,
  });

  if (bankAccountIsDefaultPaymentAccount) {
    await removeDefaultPaymentAccount({ bankAccountId });
  }
};

const removeDefaultPaymentAccount = async ({ bankAccountId }) => {
  const currentDefaultTrustAccounts = getBankAccountSettings()?.defaultTrustAccounts || [];
  store.dispatch(
    saveBankAccountSettings({
      defaultTrustAccounts: currentDefaultTrustAccounts.filter(
        (account) => account.defaultTrustAccountId !== bankAccountId,
      ),
    }),
  );
};

const hasUnreconciledTransactions = (bankAccountId) => {
  const bankReconciliation = getLatestBankReconciliation(bankAccountId);
  const transactions = generateTransactionList({
    transactions: getTransactionList(bankAccountId),
    filters: {
      bankReconciliation,
    },
    filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
  });

  return transactions.length > 0;
};

const hasReconciliationInProgess = (bankAccountId) => {
  const bankReconciliation = getLatestBankReconciliation(bankAccountId);

  if (!bankReconciliation) {
    return false;
  }

  return bankReconciliation.status === 'InProgress';
};

const hasOpenAdjustments = (bankAccountId) => {
  const lastCompletedBankRec = getLastCompletedBankRec(bankAccountId);
  if (!lastCompletedBankRec) {
    // No last completed bank rec = no open adjustments
    return false;
  }
  // But if there is a last completed bank rec then need to check if there are open adjustments
  return lastCompletedBankRec.adjustments && lastCompletedBankRec.adjustments.some((adj) => !adj.isReconciled);
};

TrustAccountCloseModalContainer.displayName = 'TrustAccountCloseModalContainer';

TrustAccountCloseModalContainer.propTypes = {
  onCloseCard: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  bankAccountId: PropTypes.string.isRequired,
};

export default TrustAccountCloseModalContainer;
