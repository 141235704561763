import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getById as getVendorPaymentById } from '@sb-billing/redux/vendor-payments';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getMap as getMatterBalanceState } from '@sb-billing/redux/bank-account-balances';
import { WarningList } from '@sb-itops/react/warning-list';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

const mapStateToProps = (state, { paymentIds, t }) => {
  function displayCurrency(value) {
    return t('cents', { val: value });
  }

  const warnings = paymentIds.reduce((acc, paymentId) => {
    const payment = getVendorPaymentById(paymentId);
    if (!payment) {
      return acc;
    }

    const bankAccountId = payment.sourceAccountId;
    const bankAccount = bankAccountId ? getBankAccountById(bankAccountId) : {};
    const isTrustAccountVendorPayment = bankAccount.accountType === bankAccountTypeEnum.TRUST;

    if (!isTrustAccountVendorPayment) {
      return acc;
    }

    const paidAmount = (payment && payment.amount) || 0;
    const matterTrustBalance = getMatterBalance(getMatterBalanceState(), {
      bankAccountId: bankAccount.id,
      matterId: payment.matterId,
    });
    const trustBalanceAfterReversal = matterTrustBalance + paidAmount;
    const isNegativeTrustBalanceAfterReversal = hasFacet(facets.allowOverdraw) && trustBalanceAfterReversal < 0;

    if (isTrustAccountVendorPayment && isNegativeTrustBalanceAfterReversal) {
      acc.push({
        text: `Warning: As of the transaction date, the ${t(
          'trustAccount',
        ).toLowerCase()} will be overdrawn to a balance of ${displayCurrency(trustBalanceAfterReversal)}`,
      });
    }

    return acc;
  }, []);

  return { list: warnings };
};

export const VendorPaymentReversalWarningContainer = withTranslation()(
  withReduxStore(connect(mapStateToProps)(WarningList)),
);

VendorPaymentReversalWarningContainer.displayName = 'VendorPaymentReversalWarningContainer';

VendorPaymentReversalWarningContainer.propTypes = {
  paymentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

VendorPaymentReversalWarningContainer.defaultProps = {};
