import React from 'react';
import { store } from '@sb-itops/redux';

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

const withReduxStore = (WrappedComponent) => {
  const WrappedComponentWithReduxStore = (props) => <WrappedComponent {...props} store={store} />;
  WrappedComponentWithReduxStore.displayName = `WrappedComponentWithReduxStore(${getDisplayName(WrappedComponent)})`;
  return WrappedComponentWithReduxStore;
};

export default withReduxStore;
