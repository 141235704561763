import { TSchemeValuesFor, schemeMappings } from '@sb-itops/region-schemes';

export const hasDetailedAddresses = (addressScheme: TSchemeValuesFor<'addressScheme'>): boolean => {
  switch (addressScheme) {
    case schemeMappings.addressScheme.US:
      return false;
    case schemeMappings.addressScheme.AU:
    case schemeMappings.addressScheme.GB:
      return true;
    default:
      throw new Error(`Unimplemented addressScheme ${addressScheme}`);
  }
};
