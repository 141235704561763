/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'interest-settings-service',
  keyPath: 'matterId',
  ngCacheName: 'sbInterestSettingsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInterestSettingsService',
  keyPath: 'matterId',
});

export * from './save-interest-settings';
