'use strict';

const { operationTypes } = require('./operation-type');

/**
 * Group correspondence history by each matter and contact pair(Invoice Reminder is keyed by a matterContactId).
 * @param {Object} params
 * @param {Object[]} params.correspondenceHistoryList - The array of all correspondence history object
 * @returns {{Object} - The last correspondence history item for each reminder, keyed by matterContactId}
 */

const getLastCorrespondenceHistoryForReminders = ({ correspondenceHistoryList }) => {
  const lastCorrespondenceHistoryForReminders = correspondenceHistoryList.reduce((acc, correspondenceItem) => {
    // Reminder correspondence is type INVOICE_REMINDER and always has a matterId, and one contactId
    if (
      correspondenceItem.operationType !== operationTypes.INVOICE_REMINDER ||
      !correspondenceItem.matterId ||
      correspondenceItem.contactIds.length !== 1
    ) {
      return acc;
    }
    const contactId = correspondenceItem.contactIds[0]; // Reminder correspondence only has one contact
    const matterContactId = `${correspondenceItem.matterId || ''}-${contactId || ''}`; // Reminder items are id'd by matter debtor id.
    const existingItem = acc[matterContactId];
    const existingItemTimestamp =
      (existingItem && existingItem.sentTimestamp) || (existingItem && existingItem.lastUpdated);
    const currentItemTimestamp = correspondenceItem.sentTimestamp || correspondenceItem.lastUpdated;

    const isCurrentItemMoreRecent = existingItemTimestamp < currentItemTimestamp;

    if (!existingItem || isCurrentItemMoreRecent) {
      acc[matterContactId] = correspondenceItem;
    }

    return acc;
  }, {});
  return lastCorrespondenceHistoryForReminders;
};

module.exports = {
  getLastCorrespondenceHistoryForReminders,
};
