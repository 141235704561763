import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import * as Styles from './MatterOverview.module.scss';
import {
  BillableOverviewWidget,
  ClientInfoWidget,
  MemosWidget,
  RecentDocumentsWidget,
  TasksWidget,
  EventsWidget,
} from '../widgets';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const HEIGHT = 10;
export const WIDTH = 14;

const emptyFunc = () => {};

const renderItem = ({ id, type, matterId, onClickLink, settings, trustRetainerBelowLimit }) => {
  switch (type) {
    case 'RecentDocuments':
      if (!featureActive('NUCWEB-367')) {
        return null;
      }
      return (
        <RecentDocumentsWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={false}
          onChange={emptyFunc}
          onRemove={emptyFunc}
          settings={settings[id]}
        />
      );
    case 'BillableOverview':
      return (
        <BillableOverviewWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={false}
          onChange={emptyFunc}
          onRemove={emptyFunc}
          settings={settings[id]}
          trustRetainerBelowLimit={trustRetainerBelowLimit}
        />
      );
    case 'ClientInfo':
      return (
        <ClientInfoWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={false}
          onChange={emptyFunc}
          onRemove={emptyFunc}
          settings={settings[id]}
        />
      );
    case 'Memos':
      return (
        <MemosWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={false}
          onChange={emptyFunc}
          onRemove={emptyFunc}
          settings={settings[id]}
        />
      );
    case 'Tasks':
      return (
        <TasksWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={false}
          onChange={emptyFunc}
          onRemove={emptyFunc}
          settings={settings[id]}
        />
      );
    case 'Events':
      return (
        <EventsWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={false}
          onChange={emptyFunc}
          onRemove={emptyFunc}
          settings={settings[id]}
        />
      );
    default:
      return () => null;
  }
};

const defaultSettings = {
  'default-events': {
    id: 'default-events',
    position: { x: 0, y: 0 },
    type: 'Events',
    colour: '#b5b5b5',
    title: 'Upcoming Events',
    size: { x: 4, y: 5 },
  },
  'default-tasks': {
    id: 'default-tasks',
    position: { x: 4, y: 0 },
    type: 'Tasks',
    colour: '#b5b5b5',
    title: 'Incomplete Tasks',
    size: { x: 4, y: 5 },
  },
  'default-memos': {
    id: 'default-memos',
    position: { x: 0, y: 5 },
    type: 'Memos',
    colour: '#b5b5b5',
    title: 'Recent Memos',
    size: { x: 4, y: 5 },
  },
  'default-documents': {
    id: 'default-documents',
    position: { x: 4, y: 5 },
    type: 'RecentDocuments',
    colour: '#b5b5b5',
    title: 'Recent Documents',
    size: { x: 4, y: 5 },
  },
  'default-billable-overview': {
    id: 'default-billable-overview',
    position: { x: 8, y: 0 },
    size: { x: 6, y: 4 },
    type: 'BillableOverview',
    colour: '#b5b5b5',
    title: 'Billing',
  },
  'default-client-info': {
    id: 'default-client-info',
    position: { x: 8, y: 4 },
    size: { x: 6, y: 6 },
    type: 'ClientInfo',
    colour: '#b5b5b5',
    title: 'Client Info',
  },
};

// Bill clients (the lowest tier) only have the two widgets
const settingsBill = {
  'default-billable-overview': {
    id: 'default-billable-overview',
    position: { x: 0, y: 0 },
    size: { x: 6, y: 4 },
    type: 'BillableOverview',
    colour: '#b5b5b5',
    title: 'Billing',
  },
  'default-client-info': {
    id: 'default-client-info',
    position: { x: 0, y: 4 },
    size: { x: 6, y: 6 },
    type: 'ClientInfo',
    colour: '#b5b5b5',
    title: 'Client Info',
  },
};

export const MatterOverview = ({ matterId, onClickLink, trustRetainerBelowLimit }) => {
  const [availableHeight, setAvailableHeight] = useState(600);
  const selectedSettings = featureActive('NUCWEB-351') ? defaultSettings : settingsBill;
  const filteredComponents = Object.values(selectedSettings);
  const children = filteredComponents.map(({ id, type }) => (
    <div key={id}>
      {renderItem({
        id,
        type,
        matterId,
        settings: selectedSettings,
        onClickLink,
        trustRetainerBelowLimit,
      })}
    </div>
  ));

  useEffect(() => {
    setTimeout(() => {
      setAvailableHeight(document.querySelector('sbb-billing-matter-dashboard-route')?.getBoundingClientRect()?.height);
    }, 100);
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.gridContainer}>
        <ResponsiveGridLayout
          measureBeforeMount
          className={Styles.gridStyles}
          compactType={null}
          maxRows={HEIGHT}
          layouts={{
            lg: filteredComponents.map(({ position, size, id, minX, minY }) => ({
              i: id,
              x: position.x,
              y: position.y,
              w: size.x,
              h: size.y,
              minW: minX,
              minH: minY,
            })),
          }}
          margin={[10, 10]}
          padding={[0, 0]}
          resizeHandle={null}
          isBounded
          preventCollision
          onLayoutChange={() => {}}
          isDraggable={false}
          isResizable={false}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: WIDTH, md: WIDTH, sm: WIDTH, xs: WIDTH, xxs: WIDTH }}
          // Height minus container padding (20) minus row padding (currently 10px per row) minus 5px * 2 border in edit mode
          rowHeight={(availableHeight - 20 - (HEIGHT - 1) * 10) / HEIGHT}
        >
          {children}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

MatterOverview.propTypes = {
  matterId: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  trustRetainerBelowLimit: PropTypes.bool.isRequired,
};

MatterOverview.defaultProps = {
  matterId: '',
};
