import { isEditable } from '@sb-billing/business-logic/invoice/services';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';

angular.module('sb.billing.webapp').directive('sbActionSetInvoiceDraft', function ($state, sbTabService, sbInvoiceEditService, sbLoggerService, sbMessageDisplayService) {
  'use strict';

  const log = sbLoggerService.getLogger(`sbActionSetInvoiceDraft`);

  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      let invoice;

      ctrl.group.onInvoiceData((data) => { invoice = data; });
      ctrl.group.setAction(setInvoiceDraft, () => !!invoice && isEditable(getInvoiceById(invoice.invoiceId), getTotalsForInvoiceId(invoice.invoiceId)));

      function setInvoiceDraft() {
        if (invoice) {
          ctrl.group.setLock(true);

          const currentInvoiceTab = sbTabService.getCurrentTab();
          return sbInvoiceEditService.editInvoiceP(invoice.invoiceId, invoice.matterId)
            .then(function () {
              ctrl.group.setLock(false);
              sbTabService.close(currentInvoiceTab);
              $state.go('home.billing.goto-invoice', {invoiceId: invoice.invoiceId});
            })
            .catch(function (err) {
              sbMessageDisplayService.error(
                sbMessageDisplayService
                  .builder()
                  .text('Failed to edit invoice')
                  .conditionalText(' #{0}', invoice.invoiceNumber)
                  .text('. Please check your connection and try again.')
              );

              log.error('Failed to edit invoice', err);
              ctrl.group.setLock(false);
            });
        } else {
          throw new Error('no invoice found to edit');
        }
      }

    }
  };
});
