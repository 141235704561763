'use strict';

const { createImage } = require('./create-image');

function createLogoMarkup(logoUrl) {
  const logoMarkup = createImage({
    src: logoUrl,
    style: 'display: block; height: auto; border: 0; height: 75px; max-width: 100%;',
    height: '75',
  });
  return logoMarkup;
}

module.exports = {
  createLogoMarkup,
};
