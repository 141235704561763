import { gql } from '@apollo/client';

import { matterSummaryFragment } from '../fragments/matterSummaryFragment.graphql';

const query = gql`
  query MatterSummaryData($id: ID!) {
    matter(id: $id) {
      ...matterSummaryFragment
    }
  }

  ${matterSummaryFragment}
`;

const notificationIds = [
  'WebQueryBillingSharedNotifications.CustomerBillingConfigurationUpdated',
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterSummaryData = {
  query,
  notificationIds,
};
