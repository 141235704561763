import { sortByProperty } from '@sb-itops/nodash';
import { getFirmName } from '@sb-firm-management/redux/firm-management';
import { featureActive } from '@sb-itops/feature';

import { BANK_BALANCE_TYPE, getBalanceType, isMatterContactBalanceType } from '../bank-account-settings';

export const getPdfOptionsByReportType = (type, t, { supportsEnteredDate, allowOverdraw, supportsCMA }) =>
  getReportTypes(t, { supportsEnteredDate, allowOverdraw, supportsCMA }).find((reportType) => reportType.type === type)
    .pdfOptions || {};

export const buildSubmitForm = (filterForm, t, { supportsEnteredDate, allowOverdraw, supportsCMA }) => {
  const submitForm = {};

  const config = getReportTypes(t, { supportsEnteredDate, allowOverdraw, supportsCMA }).find(
    (reportType) => reportType.type === filterForm.reportType,
  );
  const configForm = config.filterForm;
  const settings = config.settings || {};

  Object.keys(filterForm).forEach((property) => {
    if (
      Object.prototype.hasOwnProperty.call(filterForm, property) &&
      Object.prototype.hasOwnProperty.call(configForm, property)
    ) {
      submitForm[property] = filterForm[property];
    }
  });

  // Takes the supplied `settings` and returns an object with the same keys, with any function properties evaluated as a boolean value
  return Object.assign(
    submitForm,
    Object.entries(settings).reduce(
      (newSettings, [name, value]) => ({
        ...newSettings,
        [name]: typeof value === 'function' ? !!value() : value,
      }),
      {},
    ),
  );
};

export const getReportTypes = (t, { supportsEnteredDate, allowOverdraw, supportsCMA }) =>
  sortByProperty(
    [
      {
        type: 'invoice-history',
        display: 'Invoice History',
        filterForm: {
          reportType: 'invoice-history',
          fromDate: '',
          toDate: '',
          invoiceStatus: '',
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          attorney: { id: '', display: '' },
          areaOfLaw: { ids: [], display: '' },
          printOnLetterhead: false,
          printFirmName: false,
        },
      },
      {
        type: 'accounts-receivable',
        display: 'Accounts Receivable',
        filterForm: {
          reportType: 'accounts-receivable',
          fromDate: '',
          toDate: '',
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          attorney: { id: '', display: '' },
          printOnLetterhead: false,
          printFirmName: false,
        },
      },
      {
        type: 'aging-summary',
        display: 'Aging Summary',
        filterForm: {
          reportType: 'aging-summary',
          attorney: { id: '', display: '' },
          printOnLetterhead: false,
          printFirmName: false,
        },
      },
      {
        type: 'matter-balances',
        display: 'Matter Balances',
        filterForm: {
          reportType: 'matter-balances',
          attorney: { id: '', display: '' },
          matterStatus: { id: '', display: '' },
          matterBalancesViewOption: '',
          filterMattersWoBalances: '',
          filterIncompleteMatters: '',
          excludeExternallyBilled: true,
          showMatterDebt: false,
          printOnLetterhead: false,
          printFirmName: false,
        },
        pdfOptions: { O: 'Landscape' },
      },
      {
        type: 'account-statement',
        display: 'Account Statements',
        filterForm: {
          reportType: 'account-statement',
          fromDate: undefined,
          toDate: undefined,
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          showInvoiceHistory: true,
          showInvoiceSummary: true,
          showAccountSummary: true,
          showFeesAndExpenses: true,
          showDeletedTransactions: false,
          showTransactionHistory: true,
          showTransactionHistoryWithInvoiceNumbers: true,
          printOnLetterhead: false,
          printFirmName: false,
          showLedger: true,
          showTrustAccountName: true,
          showTimeKeeperInitials: true,
          showExpenseTimeKeeperInitials: !featureActive('BB-2738'),
          runByEnteredDate: supportsEnteredDate,
        },
        pdfOptions: {
          footerRight: '[pdf-hf-data]',
          O: 'Landscape',
        },
        settings: {
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => !isMatterContactBalanceType(),
        },
      },
      {
        type: 'trust-statement',
        display: t('report.trustStatements.label'),
        filterForm: {
          reportType: 'trust-statement',
          fromDate: undefined,
          toDate: undefined,
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          printOnLetterhead: false,
          runByEnteredDate: supportsEnteredDate,
        },
        pdfOptions: {
          footerRight: '[pdf-hf-data]',
          O: 'Landscape',
        },
        settings: {
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => !isMatterContactBalanceType(),
        },
      },
      {
        type: 'trial-balance',
        display: t('report.trialBalance.label'),
        filterForm: {
          reportType: 'trial-balance',
          toDate: '',
          showZeroBalance: true,
          printOnLetterhead: false,
          printFirmName: false,
        },
      },
      {
        type: 'income-by-attorney',
        display: 'Income Allocation',
        filterForm: {
          reportType: 'income-by-attorney',
          fromDate: '',
          toDate: '',
          includedStaffIds: undefined,
          showHours: false,
          showPercentage: true,
          printOnLetterhead: false,
          printFirmName: false,
        },
        pdfOptions: {
          O: 'Landscape',
          zoom: '0.9',
          footerLeft: `Notes:
      * Partial payments for an invoice are applied pro-rata across fees and ${t('expenses')}
      * Waived amounts simply leave invoices partially paid (which then follows the above rule)
      * Discounts are applied to fees only
      * Interest income is not included`,
          footerFontSize: 7,
          B: '26mm',
          footerSpacing: 16,
        },
      },
      {
        type: 'time-fees-expenses',
        display: `Time and ${('capitalizeAllWords', { val: 'expense' })} Entries`,
        filterForm: {
          reportType: 'time-fees-expenses',
          fromDate: '',
          toDate: '',
          staffMatterRadio: '',
          staffOrMatterInfo: { id: '', display: '' },
          areaOfLaw: { ids: [], display: '' },
          typeOfEntries: { id: '', display: '' },
          showEntriesList: '',
          showDescriptionField: '',
          showLeadMatters: '',
          excludeExternallyBilled: true,
          printOnLetterhead: false,
          printFirmName: false,
        },
        pdfOptions: { O: 'Landscape' },
      },
      {
        type: 'account-balances',
        display: 'Account Balances',
        filterForm: {
          reportType: 'account-balances',
          fromDate: '',
          toDate: '',
          bankAccount: { id: '', display: '' },
          transactionType: { id: '', display: '' },
          showTransactionsList: '',
          printOnLetterhead: false,
          printFirmName: false,
          showDeletedTransactions: '',
        },
        pdfOptions: {
          footerRight: '[pdf-hf-data]',
          O: 'Landscape',
        },
        settings: {
          showComplianceDescriptions: true,
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => getBalanceType() !== BANK_BALANCE_TYPE.matterContact,
        },
      },
      {
        type: 'journal-transfers',
        display: t('report.journalTransfers.label'),
        reportEndpoint: 'report-account-balances',
        filterForm: {
          reportType: 'journal-transfers',
          fromDate: '',
          toDate: '',
          bankAccount: { id: '', display: '' },
          transactionType: { id: '', display: '' },
          showTransactionsList: '',
          printOnLetterhead: false,
          printFirmName: false,
          showDeletedTransactions: '',
        },
        settings: {
          showComplianceDescriptions: true,
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => getBalanceType() !== BANK_BALANCE_TYPE.matterContact,
        },
        pdfOptions: { O: 'Landscape' },
      },
      {
        type: 'receipts-cash-book',
        display: t('report.receiptsCashBook.label'),
        reportEndpoint: 'report-account-balances',
        filterForm: {
          reportType: 'receipts-cash-book',
          fromDate: '',
          toDate: '',
          bankAccount: { id: '', display: '' },
          transactionType: { id: '', display: '' },
          showTransactionsList: '',
          printOnLetterhead: false,
          printFirmName: false,
          showDeletedTransactions: '',
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      {
        type: 'cash-book-payments',
        display: t('report.cashBookPayments.label'),
        reportEndpoint: 'report-account-balances',
        filterForm: {
          reportType: 'cash-book-payments',
          fromDate: '',
          toDate: '',
          bankAccount: { id: '', display: '' },
          transactionType: { id: '', display: '' },
          showTransactionsList: '',
          printOnLetterhead: false,
          printFirmName: false,
          showDeletedTransactions: '',
        },
        settings: {
          showComplianceDescriptions: true,
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => getBalanceType() !== BANK_BALANCE_TYPE.matterContact,
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      {
        type: 'audit-logs',
        display: 'Audit Logs',
        filterForm: {
          reportType: 'audit-logs',
          fromDate: '',
          toDate: '',
          printOnLetterhead: false,
          printFirmName: false,
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      {
        type: 'operating-cheques',
        display: t('capitalizeAllWords', { val: 'operatingCheques' }),
        filterForm: {
          reportType: 'operating-cheques',
          printOnLetterhead: false,
          printFirmName: false,
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      // Doesn't get displayed in the `Choose a Report` list in the `Reports` section
      // Search for sb-action-generate-and-download-report to see where it's used
      // this report is AU only
      {
        type: 'trust-ledger',
        reportEndpoint: 'report-account-statement',
        filterForm: {
          reportType: 'trust-ledger',
          bankAccountId: undefined,
          fromDate: undefined,
          toDate: undefined,
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          showInvoiceHistory: false,
          showInvoiceSummary: false,
          showAccountSummary: false,
          showFeesAndExpenses: false,
          showDeletedTransactions: false,
          showTransactionHistory: true,
          showTransactionHistoryWithInvoiceNumbers: true,
          printOnLetterhead: false,
          printFirmName: true,
          showLedger: false,
          showTrustAccountName: true,
          showTimeKeeperInitials: false,
          showExpenseTimeKeeperInitials: !featureActive('BB-2738'),
          runByEnteredDate: supportsEnteredDate,
        },
        settings: {
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => getBalanceType() !== BANK_BALANCE_TYPE.matterContact,
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      {
        type: 'trust-receipts',
        display: 'Trust Receipts',
        pdfOptions: { footerRight: '[pdf-hf-data]' },
        filterForm: {
          reportType: 'trust-receipts',
          fromDate: '',
          toDate: '',
          printOnLetterhead: false,
          printFirmName: false,
        },
      },
      {
        settings: {
          // hide the paidBy column if the account IS NOT using matter-contact balances
          hidePaidBy: () => !isMatterContactBalanceType(),
        },
        type: 'ledger-export',
        display: 'Ledger Export',
        filterForm: {
          reportType: 'trust-receipts',
          fromDate: '',
          toDate: '',
          matterContactRadio: '',
          matterOrContactInfo: { id: '', display: '' },
          printOnLetterhead: false,
          printFirmName: false,
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      allowOverdraw && {
        type: 'overdraw-trust-account',
        display: 'Overdrawn',
        filterForm: {
          reportType: 'overdraw-trust-account',
          fromDate: '',
          toDate: '',
          bankAccount: { id: '', display: '' },
          printOnLetterhead: false,
          printFirmName: false,
        },
        settings: {
          reportFirm: getFirmName(),
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      supportsCMA && {
        type: 'cma-listing',
        display: 'Controlled Money Listing',
        filterForm: {
          reportType: 'cma-listing',
          toDate: '',
          printOnLetterhead: false,
          printFirmName: false,
        },
        settings: {
          reportFirm: getFirmName(),
        },
        pdfOptions: { O: 'Landscape', footerRight: '[pdf-hf-data]' },
      },
      {
        type: 'protected-funds-listing',
        display: 'Protected funds Listing',
        filterForm: {
          reportType: 'protected-funds-listing',
          matterId: '',
          bankAccountId: '',
          bankAccountName: '',
          sortBy: '',
          sortDirection: '',
        },
        pdfOptions: { O: 'Portrait', footerRight: '[pdf-hf-data]' },
      },
    ].filter((value) => !!value),
    'display',
  );
