import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from '@sb-itops/react';
import { ProtectedFundsLineItem } from './ProtectedFundsLineItem';

const mapStateToProps = (state, { amount, indentLeft, matterId, bankAccountId, t }) => {
  const parsedAmount = Number.isFinite(amount) ? t('cents', { val: amount }) : 'n/a';

  return {
    amount: parsedAmount,
    indentLeft,
    isLink: !!(matterId && bankAccountId),
  };
};

const mapDispatchToProps = (dispatch, { onClickLink, matterId, bankAccountId, overrideRedirect, disabled }) => ({
  onViewProtectedFunds: () => {
    if (disabled) {
      return;
    }

    overrideRedirect();
    onClickLink({
      type: 'matterTransactions',
      id: 'protected-funds',
      params: [{ matterId, trustAccountId: bankAccountId }],
    });
  },
});

const ProtectedFundsLineItemContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProtectedFundsLineItem),
);

ProtectedFundsLineItemContainer.displayName = 'ProtectedFundsLineItemContainer';

ProtectedFundsLineItemContainer.propTypes = {
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  amount: PropTypes.number,
  indentLeft: PropTypes.bool,
  onClickLink: PropTypes.func,
  overrideRedirect: PropTypes.func,
  disabled: PropTypes.bool,
};

ProtectedFundsLineItemContainer.defaultProps = {
  matterId: undefined,
  bankAccountId: undefined,
  amount: undefined,
  onClickLink: () => {},
  overrideRedirect: () => {},
  indentLeft: false,
  disabled: false,
};

export default ProtectedFundsLineItemContainer;
