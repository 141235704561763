import React from 'react';
import PropTypes from 'prop-types';
import { MemosTab } from 'web/components';
import { FeatureLockModal } from 'web/react-redux/components/feature-lock';

export const ViewMatterMemosRouteContainer = ({ matterId, selectedMemoId, onClickLink }) => (
  <FeatureLockModal feature="memos" onClickLink={onClickLink}>
    <MemosTab matterId={matterId} selectedMemoId={selectedMemoId} onClickLink={onClickLink} />
  </FeatureLockModal>
);

ViewMatterMemosRouteContainer.displayName = 'ViewMatterMemosRouteContainer';

ViewMatterMemosRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  selectedMemoId: PropTypes.string,
};

ViewMatterMemosRouteContainer.defaultProps = { selectedMemoId: '' };
