import * as Yup from 'yup';

import {
  nationalIdFieldValues,
  routingNumberFieldValues,
  socialSecurityFieldValues,
  zipCodeFieldValues,
} from '@sb-billing/business-logic/payment-provider/services/fee-wise/strategies';
import {
  routingNumberSchema,
  bsbSchema,
  sortCodeSchema,
  socialSecuritySchema,
  driversLicenseOrTfnSchema,
  nationalInsuranceNumberSchema,
  nineNumericPattern,
  zipCodeSchema,
  postCodeSchema,
  gbPostCodeSchema,
} from './specificSchemas.yup';

export const routingNumberFieldSchema = Yup.string()
  .when('$localisationConfig.routingNumberField', {
    is: routingNumberFieldValues.ROUTING_NUMBER,
    then: routingNumberSchema,
  })
  .when('$localisationConfig.routingNumberField', {
    is: routingNumberFieldValues.BSB,
    then: bsbSchema,
  })
  .when('$localisationConfig.routingNumberField', {
    is: routingNumberFieldValues.SORT_CODE,
    then: sortCodeSchema,
  });

export const socialSecurityFieldSchema = Yup.string()
  .when('$localisationConfig.socialSecurityField', {
    is: socialSecurityFieldValues.SOCIAL_SECURITY,
    then: socialSecuritySchema,
  })
  .when('$localisationConfig.socialSecurityField', {
    is: socialSecurityFieldValues.DRIVERS_LICENSE_OR_TFN,
    then: driversLicenseOrTfnSchema,
  })
  .when('$localisationConfig.socialSecurityField', {
    is: socialSecurityFieldValues.NATIONAL_INSURANCE_NUMBER,
    then: nationalInsuranceNumberSchema,
  })
  .when('$newFormFields', {
    is: (newFormFields) => newFormFields,
    then: (socialSecurityNumber) => socialSecurityNumber.required(),
    otherwise: (socialSecurityNumber) => socialSecurityNumber.nullable().optional(),
  });

export const nationalIdFieldSchema = Yup.string()
  .required()
  .when('$localisationConfig.nationalIdField', {
    is: nationalIdFieldValues.EIN,
    then: (employerIdNumber) =>
      employerIdNumber.test('len', 'Must be exactly in format XX-XXXXXXX', (val) => {
        if (val) {
          return val.toString().length === 10;
        }
        return false;
      }),
  })
  .when('$localisationConfig.nationalIdField', {
    is: nationalIdFieldValues.ACN,
    then: (employerIdNumber) =>
      employerIdNumber.test('len', 'Must be exactly nine numeric characters', (val) => {
        if (val) {
          return nineNumericPattern.test(val);
        }
        return false;
      }),
  })
  .when('$localisationConfig.nationalIdField', {
    is: nationalIdFieldValues.CRN,
    then: (employerIdNumber) =>
      employerIdNumber.test('len', 'Must be exactly eight characters', (val) => {
        if (val) {
          return val.toString().length === 8;
        }
        return false;
      }),
  });

export const zipCodeFieldSchema = Yup.string()
  .when('$localisationConfig.zipCodeField', {
    is: zipCodeFieldValues.ZIP_CODE,
    then: zipCodeSchema,
  })
  .when('$localisationConfig.zipCodeField', {
    is: zipCodeFieldValues.FOUR_DIGIT_POSTCODE,
    then: postCodeSchema,
  })
  .when('$localisationConfig.zipCodeField', {
    is: zipCodeFieldValues.GB_POST_CODE,
    then: gbPostCodeSchema,
  });
