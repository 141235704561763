'use strict';

const urlPaths = {
  ACTIVATE_PROVIDER: '/billing/payment-provider/activate-provider/:accountId/',
  DEACTIVATE_PROVIDER: '/billing/payment-provider/deactivate-provider/:accountId/',
  SAVE_SETTINGS: '/billing/payment-provider/settings/:accountId/',
  CONNECT: '/billing/payment-provider/connect/:accountId/',
  DISCONNECT: '/billing/payment-provider/disconnect/:accountId/',
  PRE_CHARGE: '/billing/payment-provider/pre-charge/:accountId/',
  MANAGE: '/billing/payment-provider/manage/:accountId/',
  TEMP_ITEM: '/billing/payment-provider/temp-item/:accountId/',
};

/**
 * activateProvider
 *
 * Activates a payment provider.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType The provider type to be activated: LAWPAY, STRIPE etc.
 *
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const activateProvider = async ({ fetchPostP, providerType }) => {
  const fetchOptions = { body: JSON.stringify({ providerType }) };
  await fetchPostP({ path: urlPaths.ACTIVATE_PROVIDER, fetchOptions });
};

/**
 * deactivateProvider
 *
 * Deactivates the currently active payment provider.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType The provider to deactivate. Request will fail if providerType is not the currently active provider in DB.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const deactivateProvider = async ({ fetchPostP, providerType }) => {
  const fetchOptions = { body: JSON.stringify({ providerType }) };
  await fetchPostP({ path: urlPaths.DEACTIVATE_PROVIDER, fetchOptions });
};

/**
 * saveSettings
 *
 * Saves the payment integration settings for a provider.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType Provider type for the settings being saved.
 * @param {Object} params.providerSettings The provider settings to be saved.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const saveSettings = async ({ fetchPostP, providerType, providerSettings }) => {
  const fetchOptions = { body: JSON.stringify({ providerType, providerSettings }) };
  await fetchPostP({ path: urlPaths.SAVE_SETTINGS, fetchOptions });
};

/**
 * connect
 *
 * Performs a connection for the provider.
 *
 * The idea of connection is decided by the provider implementation, as a result connection may
 * not be used by all providers. Some providers perform "connection" as part of activation.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType Provider type for the connection attempt.
 * @param {Object} [params.providerPayload] The data required by the payment provider implementation to facilitate a connection.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const connect = async ({ fetchPostP, providerType, providerPayload }) => {
  const fetchOptions = { body: JSON.stringify({ providerType, providerPayload }) };
  await fetchPostP({ path: urlPaths.CONNECT, fetchOptions });
};

/**
 * disconnect
 *
 * Performs a disconnection for a payment provider.
 *
 * The mechanics of disconnection is decided by the provider implementation, as a result disconnect may
 * not be used by all providers. Some providers perform "disconnection" as part of deactivation.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType Provider type for the disconnection attempt.
 * @param {Object} [params.providerSpecificPayload] The data required by the payment provider implementation to facilitate a disconnection.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const disconnect = async ({ fetchPostP, providerType, providerSpecificPayload }) => {
  const fetchOptions = { body: JSON.stringify({ providerType, providerSpecificPayload }) };
  await fetchPostP({ path: urlPaths.DISCONNECT, fetchOptions });
};

/**
 * preCharge
 *
 * Executes pre-charge request.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType Provider type for the pre-charge attempt.
 *
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const preCharge = async ({ fetchPostP, providerType, providerSpecificFields }) => {
  const fetchOptions = { body: JSON.stringify({ providerType, providerSpecificFields }) };
  const response = await fetchPostP({ path: urlPaths.PRE_CHARGE, fetchOptions });
  return response.body;
};

/**
 * manage
 *
 * Executes manage request.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType Provider type for the pre-charge attempt.
 * @param {Object} params.providerSpecificFields
 *
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const manage = async ({ fetchPostP, providerType, providerSpecificFields }) => {
  const fetchOptions = { body: JSON.stringify({ providerType, providerSpecificFields }) };
  const response = await fetchPostP({ path: urlPaths.MANAGE, fetchOptions });
  return response.body;
};

/**
 * saveTempItem
 *
 * Saves an arbitrary temporary item.
 *
 * @param {Object} params
 * @param {function} params.fetchPostP Function for posting data.
 * @param {string} params.providerType Provider type for the settings being saved.
 * @param {string} params.itemType item type for the settings being saved.
 * @param {Object} params.data The provider settings to be saved.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const saveTempItem = async ({ fetchPostP, providerType, itemType, data }) => {
  const fetchOptions = { body: JSON.stringify({ providerType, itemType, data }) };
  const response = await fetchPostP({ path: urlPaths.TEMP_ITEM, fetchOptions });
  return response;
};

/**
 * loadTempItem
 *
 * Loads an previously saved temporary item.
 *
 * @param {Object} params
 * @param {function} params.fetchGetP Function for getting data.
 * @param {string} params.providerType Provider type for the settings being loaded.
 * @param {string} params.itemType item type for the settings being loaded.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const loadTempItem = async ({ fetchGetP, providerType, itemType }) => {
  const response = await fetchGetP({ path: `${urlPaths.TEMP_ITEM}${providerType}/${itemType}`, fetchOptions: {} });
  return response.body;
};

/**
 * deleteTempItem
 *
 * Deletes a previously saved temporary item.
 *
 * @param {Object} params
 * @param {function} params.fetchDeleteP Function for deleting data.
 * @param {string} params.providerType Provider type for the settings being deleted.
 * @param {string} params.itemType item type for the settings being deleted.
 * @return {Promise<*>} A resolved promise if successful, rejected otherwise.
 */
const deleteTempItem = async ({ fetchDeleteP, itemType, providerType }) => {
  const response = await fetchDeleteP({ path: `${urlPaths.TEMP_ITEM}${providerType}/${itemType}`, fetchOptions: {} });
  return response;
};

module.exports = {
  activateProvider,
  deactivateProvider,
  saveSettings,
  connect,
  disconnect,
  preCharge,
  manage,
  saveTempItem,
  loadTempItem,
  deleteTempItem,
};
