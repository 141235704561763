/**
 * A contact with at least the following fields
 * @typedef {object} Contact
 * @property {string} displayName
 * @property {boolean} removed
 */

/**
 * Contact status selections as returned by ToggleListFilter
 * @typedef {object} ContactStatusSelections
 * @property {boolean} [current]
 * @property {boolean} [deleted]
 */

/**
 * Filter contacts
 * @param {object} params
 * @param {Contact[]} params.contacts input contacts to filter
 * @param {ContactStatusSelections} params.contactStatusSelections contact status selections
 * @returns {Contact[]} filtered contacts
 */
function filterContacts({ contacts, contactStatusSelections }) {
  let filteredContacts;

  // filter by status
  if (!contactStatusSelections.current && !contactStatusSelections.deleted) {
    filteredContacts = []; // show nothing
  } else if (!contactStatusSelections.current) {
    filteredContacts = contacts.filter((contact) => contact.removed); // show deleted
  } else if (!contactStatusSelections.deleted) {
    filteredContacts = contacts.filter((contact) => !contact.removed); // show current (not deleted)
  } else {
    filteredContacts = contacts; // show all
  }
  return filteredContacts;
}

module.exports = {
  filterContacts,
};
