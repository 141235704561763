import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import uuid from '@sb-itops/uuid';
import { sort } from '@sb-itops/sort';

import { getById as getInvoiceById, getList as getAllInvoices } from '@sb-billing/redux/invoices';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getMap as getMattersMap, getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { findActivePaymentPlanByDebtorId } from '@sb-billing/redux/payment-plans/selectors';
import { getList as getAllPaymentPlans } from '@sb-billing/redux/payment-plans';
import {
  getMatterBalances,
  getMatterContactBalances,
  getBankAccountMap,
} from '@sb-billing/redux/bank-account-balances.2/selectors';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import * as trustToOffice from 'web/redux/route/home-billing-bills-trust-to-office/feature';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { sortByProperty } from '@sb-itops/nodash';
import { featureActive } from '@sb-itops/feature';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { filterTrustToOfficeInvoices } from './filter-trust-to-office-invoices';
import { getNonZeroTrustMatterBalancesMap } from './get-non-zero-matter-trust-balance-map';
import { getInvoicesThatCanBePaidByTrust } from './get-invoice-that-can-be-paid-by-trust';
import BillingBillsTrustToOffice from './BillingBillsTrustToOffice';
import {
  autoAllocateInvoicePaymentsForMatter,
  autoAllocateInvoicePayments,
} from './allocate-trust-to-office-invoice-payments';

function getInvoiceRows(invoices, selectedInvoiceIdsMap, paymentsMap, matterBalance, isAutoAllocated) {
  let runningMatterTrustBalance = (matterBalance && matterBalance[balanceTypes.AVAILABLE]) || 0;

  return invoices.map((invoice) => {
    const isSelected = selectedInvoiceIdsMap[invoice.invoiceId];
    const trustAmount = isSelected ? paymentsMap[invoice.invoiceId] || 0 : 0;
    const trustError =
      isSelected &&
      (isPaymentError(trustAmount, runningMatterTrustBalance, invoice.totalDue) || trustAmount > invoice.totalDue);
    const trustBalance = runningMatterTrustBalance;
    const paymentPlan =
      invoice.debtorIds &&
      invoice.debtorIds.length === 1 &&
      findActivePaymentPlanByDebtorId(getAllPaymentPlans(), {
        debtorId: invoice.debtorIds[0],
      });

    runningMatterTrustBalance -= isSelected ? trustAmount : 0;
    const hasUnpaidAD =
      featureActive('BB-9573') &&
      hasUnpaidAnticipatedDisbursements({
        invoice: invoice.currentVersion,
        getExpenseById,
        getExpensePaymentDetailsById,
      });

    return {
      type: 'INVOICE',
      invoiceId: invoice.invoiceId,
      matterId: invoice.matterId,
      accountId: invoice.accountId,
      debtorIds: invoice.debtorIds,
      issuedDate: invoice.issuedDate,
      dueDate: invoice.dueDate,
      totalDue: invoice.totalDue,
      hasUnpaidAD,
      isSelected,
      trustAmount,
      trustBalance,
      trustError,
      isAutoAllocated,
      debtorDisplay: invoice.debtorIds
        .map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true }))
        .join(' | '),
      paymentPlanId: (paymentPlan && paymentPlan.id) || undefined,
    };
  });
}

function getMatterRows({
  matterIdToInvoiceRows,
  expanded,
  matterBalances,
  bankAccountId,
  autoAllocations,
  isMatterContactBalanceFirm,
  invoicePayments,
}) {
  const getMatterContactBalancesForMatter = (matterId) =>
    bankAccountId ? getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId }) : [];

  return Object.entries(matterIdToInvoiceRows).map(([matterId, invoiceRows]) => {
    const matterBalance = matterBalances[matterId] || {};
    const invoices = invoiceRows;

    const invoiceIds = invoiceRows.map((invoiceRow) => invoiceRow.invoiceId);
    const isExpanded = expanded[matterId] || false;
    const hasUnpaidAD =
      featureActive('BB-9573') &&
      // We are interested in selected invoices with payment greater than 0. This is because user can
      // select invoice but doesn't have enough money in trust so it is ignored for an actual payment
      invoices.some((inv) => inv.isSelected && inv.hasUnpaidAD && invoicePayments[inv.invoiceId] > 0);

    return {
      type: 'MATTER',
      matterId,
      isExpanded,
      matterDisplay: getMatterDisplayById(matterId),
      isSelected: invoiceRows.every((invoiceRow) => invoiceRow.isSelected),
      isMultipleContactBalances: isMatterContactBalanceFirm
        ? getMatterContactBalancesForMatter(matterId).length > 1
        : false,
      trustIsError: invoiceRows.some((invoiceRow) => invoiceRow.trustError),
      // business rule: Matters added to the list after its already loaded should come in with the auto allocate toggle on
      isAutoAllocated: autoAllocations[matterId] === undefined || autoAllocations[matterId],
      invoices,
      invoiceIds,
      hasUnpaidAD,
      trustBalance: matterBalance[balanceTypes.AVAILABLE] || 0,
      totalDue: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.totalDue, 0),
      trustAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.trustAmount, 0),
    };
  });
}

function groupInvoicesByMatter({
  invoices,
  sortBy,
  sortDirection,
  selectedInvoiceIdsMap,
  paymentsMap,
  expandedMatterIdsMap,
  autoAllocationsMap,
  matterBalancesMap,
  bankAccountId,
}) {
  // group invoices by matter id
  const matterIdToInvoices = invoices.reduce((matterMap, invoice) => {
    if (matterMap[invoice.matterId]) {
      matterMap[invoice.matterId].push(invoice);
    } else {
      // eslint-disable-next-line no-param-reassign
      matterMap[invoice.matterId] = [invoice];
    }
    return matterMap;
  }, {});

  // for each matter group, turn input invoices into invoice table row format
  const matterIdToInvoiceRows = Object.entries(matterIdToInvoices).reduce((acc, [matterId, matterInvoices]) => {
    // we always generate the invoice rows as a selected, hidden invoice row with an error should block payment
    const invoiceRows = getInvoiceRows(
      sort(matterInvoices, ['issuedDate', 'validFrom'], ['asc', 'asc']),
      selectedInvoiceIdsMap,
      paymentsMap,
      // a matter wont have a balance if there's been no transactions yet
      matterBalancesMap[matterId] || {},
      // if a matter has no autoAllocation value it is a new matter, which we default to being auto allocated.
      // otherwise, take the user specified autoAllocation value
      autoAllocationsMap[matterId] === undefined || autoAllocationsMap[matterId],
    );

    acc[matterId] = invoiceRows;
    return acc;
  }, {});

  // gather display info for each matter
  const isMatterContactBalanceFirm = isMatterContactBalanceType();
  const matterRows = getMatterRows({
    matterIdToInvoiceRows,
    expanded: expandedMatterIdsMap,
    matterBalances: matterBalancesMap,
    bankAccountId,
    autoAllocations: autoAllocationsMap,
    isMatterContactBalanceFirm,
    invoicePayments: paymentsMap,
  });

  // sort matters by sort order
  const sortedMatterRows = sort(matterRows, [sortBy], [sortDirection]);

  // if matter is expanded, show all invoices for the matter
  const rows = sortedMatterRows.reduce((acc, matterRow) => {
    acc.push(matterRow);
    if (matterRow.isExpanded) {
      acc.push(...matterIdToInvoiceRows[matterRow.matterId]);
    }
    return acc;
  }, []);

  return {
    rows,
    allMatterIds: matterRows.map((row) => row.matterId),
    allInvoiceIds: Array.from(new Set(matterRows.map((row) => row.invoiceIds))).flat(),
    allExpanded: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isExpanded),
    allSelected: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isSelected),
    hasError: matterRows.some((matterRow) => matterRow.trustIsError || matterRow.operatingIsError),
  };
}

function isPaymentError(paymentAmount, matterBalance, invoiceDue) {
  return !!paymentAmount && (paymentAmount < 0 || paymentAmount > matterBalance || paymentAmount > invoiceDue);
}

function getMatterTypesForInvoices(invoices) {
  const matters = getMattersMap();
  return [
    ...new Set(
      invoices.reduce((matterTypeIds, invoice) => {
        if (matters[invoice.matterId]) {
          matterTypeIds.push(matters[invoice.matterId].matterTypeId);
        }
        return matterTypeIds;
      }, []),
    ),
  ];
}

const path = trustToOffice.defaultPath;
const {
  getSelectedInvoices,
  getExpandedMatters,
  getPayments,
  getAutoAllocatedMatters,
  getFilters,
  getVisibleFilterGroups,
  getSort,
  getShowFilters,
} = trustToOffice.selectors;

const mapStateToProps = (state, { t }) => {
  const sortBy = getSort(state[path]).sortBy;
  const sortDirection = getSort(state[path]).sortDirection;
  const selectedInvoiceIdsMap = getSelectedInvoices(state[path]);
  const paymentsMap = getPayments(state[path]);
  const expandedMatterIdsMap = getExpandedMatters(state[path]);
  const autoAllocationsMap = getAutoAllocatedMatters(state[path]);
  const filters = getFilters(state[path]);
  const visibleFilterGroups = getVisibleFilterGroups(state[path]);
  const trustAccountId = filters.bankAccountId;

  const activeTrustAccounts = sortByProperty(
    getActiveTrustAccounts().map((ta) => ({ value: ta.id, label: getBankAccountName(ta, t) })),
    'label',
    'asc',
    false,
  );

  // get matters balances for matter with a trust balance
  const allTrustMatterBalances = trustAccountId
    ? getMatterBalances(getBankAccountMap(), { bankAccountId: trustAccountId })
    : [];
  const allNonZeroTrustMatterBalancesMap = getNonZeroTrustMatterBalancesMap(allTrustMatterBalances);

  // get invoices that can be paid by trust
  const invoices = getInvoicesThatCanBePaidByTrust(getAllInvoices(), allNonZeroTrustMatterBalancesMap);

  // get matter types for filter display
  const matterTypes = getMatterTypesForInvoices(invoices);

  // filter invoices
  const filteredInvoices = filterTrustToOfficeInvoices(invoices, filters);

  // auto allocate payments where appropriate
  const autoAllocatedPaymentsMap = autoAllocateInvoicePayments({
    invoices: filteredInvoices,
    selectedInvoiceIdsMap,
    autoAllocationsMap,
    matterBalancesMap: allNonZeroTrustMatterBalancesMap,
    paymentsMap,
  });

  // group all the invoices by matter and sort accordingly for table display
  const { rows, allMatterIds, allInvoiceIds, allExpanded, allSelected, hasError } = groupInvoicesByMatter({
    invoices: filteredInvoices,
    sortBy,
    sortDirection,
    selectedInvoiceIdsMap,
    paymentsMap: autoAllocatedPaymentsMap,
    expandedMatterIdsMap,
    autoAllocationsMap,
    matterBalancesMap: allNonZeroTrustMatterBalancesMap,
    bankAccountId: trustAccountId,
  });

  // marshall payment summary so this can be used by trust to office transfer modal dialog
  const paymentsForEachMatter = marshallPaymentsForEachMatter(selectedInvoiceIdsMap, autoAllocatedPaymentsMap);
  const paymentSummary = buildPaymentSummary(paymentsForEachMatter);

  const tableSummary = buildTableSummary(rows);

  const disablePayButton =
    !paymentSummary ||
    !paymentSummary.totalAmount ||
    !paymentSummary.totalAmount > 0 ||
    !paymentSummary.paymentsForEachMatter ||
    !paymentSummary.paymentsForEachMatter.length > 0 ||
    hasError;

  const expanded = getShowFilters(state[path]);

  return {
    expanded,
    rows,
    sortBy,
    sortDirection,
    allInvoiceIds,
    allMatterIds,
    allExpanded,
    allSelected,
    hasError,
    paymentSummary,
    tableSummary,
    disablePayButton,
    matterTypes,
    filters,
    visibleFilterGroups,
    selectedMatterTypes: [...new Set(filters.matterTypes)],
    trustAccounts: activeTrustAccounts,
  };
};

const {
  // TABLE actions
  toggleAutoAllocate,
  toggleSelect,
  setPayments,
  toggleExpand,
  selectAll,
  expandAll,
  clearState,
  // FILTER actions
  setFilter,
  resetFilters,
  setFilterGroupVisibility,
  sortRows,
} = trustToOffice.actions;

// thunk function to change filter and update selected invoices accordingly
export const changeFilter = (filterName, filterValue) => async (dispatch, getGlobalState) => {
  // update the filters
  await dispatch(setFilter(filterName, filterValue));

  // get trust to office page state
  const state = getGlobalState();
  const trustToOfficePageState = state[path];

  // get selected invoices
  const selectedInvoices = Object.entries(trustToOfficePageState.selectedInvoices).reduce(
    (acc, [invoiceId, isSelected]) => {
      if (isSelected) {
        const invoice = getInvoiceById(invoiceId);
        if (invoice) {
          acc.push(invoice);
        }
      }
      return acc;
    },
    [],
  );

  // find all non zero trust matter balance
  const trustBankAccountId = trustToOfficePageState.filters.bankAccountId;
  const allTrustMatterBalances = trustBankAccountId
    ? getMatterBalances(getBankAccountMap(), { bankAccountId: trustBankAccountId })
    : [];
  const allNonZeroTrustMatterBalancesMap = getNonZeroTrustMatterBalancesMap(allTrustMatterBalances);

  // get selected invoices that can be paid by trust, this will also reshape invoices in
  // a way that's used on the trust to office table and which is suitable for filtering
  const selectedInvoicesThatCanBePaidByTrust = getInvoicesThatCanBePaidByTrust(
    selectedInvoices,
    allNonZeroTrustMatterBalancesMap,
  );

  // find selected invoices that match updated filters
  const filteredSelectedInvoices = filterTrustToOfficeInvoices(
    selectedInvoicesThatCanBePaidByTrust,
    trustToOfficePageState.filters,
  );
  const selectedInvoiceIds = filteredSelectedInvoices.map(
    (selectedInvoiceThatMatchFilter) => selectedInvoiceThatMatchFilter.invoiceId,
  );

  // update selected invoice to only those matching filter criteria
  dispatch(selectAll({ invoiceIds: selectedInvoiceIds, selected: true }));
};

const mapDispatchToProps = (dispatch, { onClickLink, onOpenTrustChequesModal }) => ({
  // table callbacks
  onClickLink,
  onOpenTrustChequesModal,
  onToggleAllMattersExpanded: (matterIds, expanded) => dispatch(expandAll({ matterIds, expanded })),
  onToggleMatterExpanded: (matterId) => dispatch(toggleExpand({ matterId })),
  onToggleInvoiceSelected: (invoiceId, selected) => dispatch(toggleSelect({ invoiceIds: [invoiceId], selected })),
  onToggleMatterSelected: (invoiceIds, selected) => dispatch(toggleSelect({ invoiceIds, selected })),
  onToggleSelectAll: (invoiceIds, selected) => dispatch(selectAll({ invoiceIds, selected })),
  onToggleAutoAllocate: ({ matterId, isAutoAllocated, invoices, matterTrustBalance }) => {
    dispatch(toggleAutoAllocate({ matterId, isAutoAllocated }));

    const selectedInvoices = invoices.reduce((acc, invoice) => {
      if (invoice.isSelected) {
        acc.push(invoice);
      }
      return acc;
    }, []);

    if (isAutoAllocated) {
      // auto allocate on: make sure to clear any manually allocated payments entered by user
      const manuallyAllocatedPaymentsToClear = selectedInvoices.reduce((acc, invoice) => {
        acc[invoice.invoiceId] = undefined;
        return acc;
      }, {});
      dispatch(setPayments(manuallyAllocatedPaymentsToClear));
    } else {
      // auto allocate toggled off: use auto allocation logic to set default payment amount for selected invoices
      const paymentAllocationsMap = autoAllocateInvoicePaymentsForMatter({
        matterId,
        matterTrustBalance,
        invoices: selectedInvoices,
      });
      dispatch(setPayments(paymentAllocationsMap));
    }
  },
  onChangePaymentAmount: (invoiceId, amount) => dispatch(setPayments({ [invoiceId]: amount })),
  onPayButtonClick: async () => setModalDialogVisible({ modalId: 'process-trust-to-office-modal' }),
  onPaymentsSubmitted: () => dispatch(clearState()),
  // filters callbacks
  toggleShowFilters: () => dispatch(trustToOffice.actions.toggleShowFilters()),
  onResetFilters: () => dispatch(resetFilters()),
  onToggleFilterGroupVisibility: (filterGroupName, isVisible) =>
    dispatch(setFilterGroupVisibility(filterGroupName, isVisible)),
  onFilterIssueDate: (selectedFilter) => dispatch(changeFilter('issueDate', selectedFilter)),
  onFilterBillingType: (billingTypes, allSelected) =>
    dispatch(
      changeFilter('billingTypes', {
        selections: billingTypes,
        allSelected,
      }),
    ),
  onFilterMatterType: (matterTypes) => dispatch(changeFilter('matterTypes', matterTypes)),
  onFilterMatterStatus: (matterStatus) => dispatch(changeFilter('matterStatuses', matterStatus)),
  onStaffChange: (attorneyResponsible) => dispatch(changeFilter('attorneysResponsible', attorneyResponsible)),
  onFilterTrustBalance: (e) => dispatch(changeFilter('minimumTrustBalance', e.target.value)),
  onFilterTrustAccount: (trustAccount) => dispatch(changeFilter('bankAccountId', trustAccount.value)),
  sort: ({ sortBy, sortDirection }) => dispatch(sortRows({ sortBy, sortDirection })),
});

function marshallPaymentsForEachMatter(selectedInvoiceIdsMap, paymentsMap) {
  // 1. get a list of invoices selected for payment
  // 2. group invoice payments by matter in a map for fast lookup
  const invoicePaymentsByMatter = Object.keys(selectedInvoiceIdsMap).reduce((acc, invoiceId) => {
    // add invoice only if it has been selected
    if (selectedInvoiceIdsMap[invoiceId]) {
      const invoice = getInvoiceById(invoiceId);
      const hasUnpaidAD =
        featureActive('BB-9573') &&
        hasUnpaidAnticipatedDisbursements({
          invoice: invoice.currentVersion,
          getExpenseById,
          getExpensePaymentDetailsById,
        });
      let matterInvoicePayments = acc[invoice.matterId];

      // add selected invoice only if payment amount is > 0
      const amount = paymentsMap[invoiceId] || 0; // defaults to 0
      if (amount > 0) {
        const invoicePayment = {
          invoiceId,
          amount,
          hasUnpaidAD,
        };

        // push into existing matter payment group, or create a new group
        // assumption here is that invoices can never repeat
        if (matterInvoicePayments) {
          matterInvoicePayments.invoices.push(invoicePayment);
          matterInvoicePayments.matterTotalPayment += invoicePayment.amount;
        } else {
          matterInvoicePayments = {
            // payorId is only required for contact balance firms
            paymentId: uuid(),
            matterId: invoice.matterId,
            invoices: [invoicePayment],
            matterTotalPayment: invoicePayment.amount,
          };
        }

        return {
          ...acc,
          [invoice.matterId]: matterInvoicePayments,
        };
      }
    }
    return acc;
  }, []);

  // turn map into an array
  return Object.values(invoicePaymentsByMatter);
}

function buildPaymentSummary(paymentsForEachMatter) {
  const matterCount = paymentsForEachMatter.length;
  let hasUnpaidAD = false;
  let invoiceCount = 0;
  const totalAmount = paymentsForEachMatter.reduce(
    (acc, matterPayments) =>
      acc +
      matterPayments.invoices.reduce((matterTotalAcc, invoice) => {
        if (invoice.hasUnpaidAD) {
          hasUnpaidAD = true;
        }
        invoiceCount += 1;
        return matterTotalAcc + invoice.amount;
      }, 0),
    0,
  );

  const paymentSummary = {
    totalAmount,
    matterCount,
    invoiceCount,
    paymentsForEachMatter,
    hasUnpaidAD,
  };

  return paymentSummary;
}

function buildTableSummary(rows) {
  const summary = {
    totalDue: 0,
    trustBalance: 0,
    trustAmount: 0,
  };

  return rows.reduce(
    (total, row) =>
      row.type === 'MATTER'
        ? {
            totalDue: total.totalDue + row.totalDue,
            trustBalance: total.trustBalance + row.trustBalance,
            trustAmount: total.trustAmount + row.trustAmount,
          }
        : total,
    summary,
  );
}

const BillingBillsTrustToOfficeComponent = (props) => <BillingBillsTrustToOffice {...props} />;

const BillingBillsTrustToOfficeContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BillingBillsTrustToOfficeComponent)),
);

BillingBillsTrustToOfficeContainer.displayName = 'BillingBillsTrustToOfficeContainer';

BillingBillsTrustToOfficeContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onOpenTrustChequesModal: PropTypes.func.isRequired,
};

BillingBillsTrustToOfficeContainer.defaultProps = {};

export default BillingBillsTrustToOfficeContainer;
