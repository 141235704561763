import composeHooks from '@sb-itops/react-hooks-compose';
import { useQuery } from '@apollo/client';
import { WidgetNewMatters } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import moment from 'moment';
import { useState } from 'react';
import { NewMattersWidget } from './NewMattersWidget';

const hooks = ({ settings }) => ({
  useGraphQLforRecentMatters: () => {
    const [showMatterList, setShowMatterList] = useState(false);

    const { data, loading, error } = useQuery(WidgetNewMatters, {
      variables: {
        filter: {
          startDate: moment()
            .startOf('day')
            .subtract(settings.timeFrame || 1, settings.timeFrameUnits?.value || 'month')
            .toISOString(),
          excludeBillableLeadMatters: true,
        },
        includeMatterBalance: false,
        limit: 20,
        sort: {
          fieldNames: ['matterStarted'],
          directions: ['DESC'],
        },
      },
    });

    if (error) {
      throw new Error(error);
    }
    return {
      showMatterList,
      setShowMatterList,
      matters: data?.matterList?.results || [],
      loading,
      count: data?.matterList?.totalCount || 0,
    };
  },
});

export const NewMattersWidgetContainer = withApolloClient(composeHooks(hooks)(NewMattersWidget));
