'use strict';

/**
 * @typedef {object} EInvoiceLayout
 * @property {number} BY_TIMEKEEPER 0
 * @property {number} AS_LIST 1
 */
const eInvoiceLayout = Object.freeze({
  BY_TIMEKEEPER: 0,
  AS_LIST: 1,
});

module.exports = { eInvoiceLayout };
