import React from 'react';
import PropTypes from 'prop-types';
import { Modal, forms2PropTypes } from '@sb-itops/react';
import { CreditNoteDetails } from './CreditNoteDetails';
import Styles from './CreditNoteDetailsModal.module.scss';

export const CreditNotePaymentDetailsModal = React.memo(
  ({
    amount,
    contact,
    description,
    effectiveDate,
    formInitialised,
    formValid,
    formSubmitting,
    isDeleted,
    internalNote,
    isVisible,
    processedBy,
    reason,
    showDebug,
    showDelete,
    showReverse,
    transactionId,
    multiPaymentId,
    onCancel,
    onClickLink,
    onClose,
    onFieldValueUpdated,
    onProcess,
  }) => {
    if (!isVisible || !formInitialised) {
      return null;
    }

    return (
      <Modal
        className={Styles.modal}
        isVisible={isVisible}
        title="Credit Details"
        body={
          <CreditNoteDetails
            amount={amount}
            contact={contact}
            description={description}
            effectiveDate={effectiveDate}
            formValid={formValid}
            formSubmitting={formSubmitting}
            isDeleted={isDeleted}
            internalNote={internalNote}
            processedBy={processedBy}
            reason={reason}
            showDebug={showDebug}
            showDelete={showDelete}
            showReverse={showReverse}
            transactionId={transactionId}
            multiPaymentId={multiPaymentId}
            onCancel={onCancel}
            onClickLink={onClickLink}
            onProcess={onProcess}
            onFieldValueUpdated={onFieldValueUpdated}
          />
        }
        onModalClose={onClose}
        showFooter={false}
      />
    );
  },
);

CreditNotePaymentDetailsModal.displayName = 'CreditNotePaymentDetailsModal';

const { Forms2Field } = forms2PropTypes;

CreditNotePaymentDetailsModal.propTypes = {
  amount: PropTypes.number.isRequired,
  contact: PropTypes.string,
  description: PropTypes.string,
  effectiveDate: PropTypes.string.isRequired,
  formInitialised: PropTypes.bool,
  formValid: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  isDeleted: PropTypes.bool,
  internalNote: PropTypes.string,
  isVisible: PropTypes.bool,
  processedBy: PropTypes.string.isRequired,
  reason: Forms2Field,
  showDebug: PropTypes.bool,
  showDelete: PropTypes.bool.isRequired,
  showReverse: PropTypes.bool.isRequired,
  transactionId: PropTypes.string,
  multiPaymentId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onProcess: PropTypes.func.isRequired,
};

CreditNotePaymentDetailsModal.defaultProps = {
  contact: undefined,
  description: '',
  formInitialised: false,
  formValid: true,
  formSubmitting: false,
  isDeleted: false,
  internalNote: '',
  isVisible: false,
  reason: undefined,
  showDebug: false,
  transactionId: '',
  multiPaymentId: undefined,
};
