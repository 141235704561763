import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { sendMetric } from 'web/services/metrics';
import { getEInvoiceSettings } from './selectors';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbEInvoiceSettingsService',
  keyPath: 'accountId',
});

export const saveSettings = ({ layout, eInvoiceDisabled }) => {
  const currentSettings = getEInvoiceSettings();
  const newSettings = { ...currentSettings, layout, eInvoiceDisabled };

  return async () => {
    opdateCache({ optimisticEntities: [newSettings] });

    sendMetric('SaveEinvoiceSettings');

    try {
      const path = `/billing/einvoice-settings/:accountId/`;
      const fetchOptions = { body: JSON.stringify(newSettings) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [currentSettings] }); // This should really be the optimistic entity, but the code for opdating is truly baked.
      throw err;
    }
  };
};
