import { SET_SORT_DIRECTION, SET_MONTH, SET_YEAR, TOGGLE_EXPANDED } from './types';

export const setSortDirection = ({ sortDirection }) => ({
  type: SET_SORT_DIRECTION,
  payload: {
    sortDirection,
  },
});

export const setMonth = ({ month }) => ({
  type: SET_MONTH,
  payload: {
    month,
  },
});

export const setYear = ({ year }) => ({
  type: SET_YEAR,
  payload: {
    year,
  },
});

export const toggleExpanded = () => ({
  type: TOGGLE_EXPANDED,
  payload: {},
});
