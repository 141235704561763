'use strict';

/**
 * generateAutoTimeFeeSummary
 *
 * Generates a summary regarding an AutoTime fee's billable and non-billable amounts and durations
 *
 * @param {Object} params
 * @param {number} params.billableAmountExclTaxInCents
 * @param {number} params.billableDurationInMins
 * @param {string} [params.currencySign]
 * @param {number} params.nonBillableAmountExclTaxInCents
 * @param {number} params.nonBillableDurationInMins
 * @returns {string}
 */
function generateAutoTimeFeeSummary(props) {
  const {
    billableAmountExclTaxInCents,
    billableDurationInMins,
    currencySign,
    nonBillableAmountExclTaxInCents,
    nonBillableDurationInMins,
  } = props;

  const validateBillableDurationInMins = () => Number.isInteger(billableDurationInMins);

  const validateNonBillableDurationInMins = () => Number.isInteger(nonBillableDurationInMins);

  const validCurrencySign = ['$', '£'].includes(currencySign);
  const validBillableAmountExclTaxInCents = Number.isInteger(billableAmountExclTaxInCents);
  const validBillableDurationInMins = validateBillableDurationInMins();
  const validNonBillableAmountExclTaxInCents = Number.isInteger(nonBillableAmountExclTaxInCents);
  const validNonBillableDurationInMins = validateNonBillableDurationInMins();

  if (!validCurrencySign) {
    throw new Error('Please provide a valid currencySign value');
  }
  if (!validBillableAmountExclTaxInCents) {
    throw new Error('Please provide a valid billableAmountExclTaxInCents value');
  }
  if (!validBillableDurationInMins) {
    throw new Error('Please provide a valid billableDurationInMins value');
  }
  if (!validNonBillableAmountExclTaxInCents) {
    throw new Error('Please provide a valid nonBillableAmountExclTaxInCents value');
  }
  if (!validNonBillableDurationInMins) {
    throw new Error('Please provide a valid nonBillableDurationInMins value');
  }

  const billableAmountInDollars = (billableAmountExclTaxInCents / 100).toFixed(2);
  const billableDurationInHours = (billableDurationInMins / 60).toFixed(2);
  const nonBillableAmountInDollars = (nonBillableAmountExclTaxInCents / 100).toFixed(2);
  const nonBillableDurationInHours = (nonBillableDurationInMins / 60).toFixed(2);

  return `${currencySign}${billableAmountInDollars} Billable, ${currencySign}${nonBillableAmountInDollars} Non-Billable, ${billableDurationInHours}h Billable, ${nonBillableDurationInHours}h Non-Billable`;
}

module.exports = {
  generateAutoTimeFeeSummary,
};
