import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as tabsFeature from '@sb-itops/redux/tabs';
import { paymentMethods, paymentMethodNames } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { LawpayPaymentForm } from './LawpayPaymentForm';

const scope = '@sb-billing/lawpay-payment-form';

function mapStateToProps(state, { enabledPaymentMethods, isSubmitting }) {
  const {
    selectors: { getSelectedTab },
  } = withScopedFeature({ state, scope })(tabsFeature);

  const selectedTab = getSelectedTab();

  // Control the available payment methods here
  const lawpayPaymentMethods = {
    [paymentMethods.CREDIT_CARD]: paymentMethodNames.CREDIT_CARD,
    [paymentMethods.ECHEQUE]: 'eCheck',
  };

  // Enabled payment methods come from selectors/lambda depending on which
  // bank accounts the firm has configured
  const tabs = !enabledPaymentMethods
    ? []
    : enabledPaymentMethods.reduce((acc, method) => {
        if (lawpayPaymentMethods[method]) {
          const preSelected = !selectedTab && !acc.length; // Highlight the first tab if none are explicitly selected

          // Array.push wouldn't work, so...
          return [
            ...acc,
            {
              text: lawpayPaymentMethods[method],
              id: method,
              selected: selectedTab === method || preSelected,
              disabled: isSubmitting,
            },
          ];
        }

        return acc;
      }, []);

  return {
    selectedTab: selectedTab || (tabs && tabs[0] && tabs[0].id), // Select the first tab if none are selected
    tabs,
    scope,
  };
}

const mapDispatchToProps = (dispatch) => {
  const {
    actions: { setSelectedTab, clearSelectedTab },
  } = withScopedFeature({ scope })(tabsFeature);

  return {
    onLoad:
      () =>
      // Clear the tab selection on exit
      () => {
        dispatch(clearSelectedTab());
      },
    onChangeSelectedTab: (tabId) => {
      dispatch(setSelectedTab({ tab: tabId }));
    },
    onClearSelectedTab: () => {
      dispatch(clearSelectedTab());
    },
  };
};

export const LawpayPaymentFormContainer = connect(mapStateToProps, mapDispatchToProps)(withOnLoad(LawpayPaymentForm));

LawpayPaymentFormContainer.displayName = 'LawpayPaymentFormContainer';

LawpayPaymentFormContainer.propTypes = {
  // array of paymentMethods keys
  enabledPaymentMethods: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  publicKey: PropTypes.string.isRequired,
  hostedFieldsStyle: PropTypes.object,
  triggerSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

LawpayPaymentFormContainer.defaultProps = {
  hostedFieldsStyle: {},
};
