import React from 'react';
import PropTypes from 'prop-types';
import { forms2PropTypes } from '@sb-itops/react';
import { InvoiceTemplateSettings } from '@sb-billing/react/invoice-template-settings';

import { decodeFooter, titleLine1EnumToString, titleLine2EnumToString } from './transforms';

export const MatterInvoiceSettings = ({
  // form state
  formInitialised,
  formDisabled,
  isSurchargeValid,
  // form fields
  invoiceTemplateId,
  invoiceFooter,
  invoiceFooterOverridden,
  invoiceTitleLine1CustomText,
  invoiceTitleLine1Option,
  invoiceTitleLine1Overridden,
  invoiceTitleLine2CustomText,
  invoiceTitleLine2Option,
  invoiceTitleLine2Overridden,
  addressesOverridden,
  overriddenDebtorAddresses,
  selectedDebtorId,
  eInvoiceEnabledOption,
  payButtonEnabledOption,
  surchargeOverridden,
  surchargeType,
  surchargePercentage,
  surchargeAmount,
  surchargeApplyTo,
  surchargeLabel,
  // other
  templateInvoiceSettings,
  eInvoiceEnabledOptions,
  showPaymentsSection,
  eInvoiceSelectEnabled,
  // callbacks
  onTemplateUpdated,
  onResetToTemplateDefault,
  onTitleOptionsUpdated,
  onTitleTextUpdated,
  onSubtitleOptionsUpdated,
  onSubtitleTextUpdated,
  onFooterUpdated,
  onAddressesOverriddenUpdated,
  onDebtorAddressesUpdated,
  onChangeSelectedDebtorTab,
  onResetToDebtorDefaultAddress,
  onEInvoiceEnabledOptionUpdated,
  onPayButtonEnabledOptionChange,
  onSurchargeOverriddenUpdated,
  onSurchargeTypeUpdated,
  onSurchargePercentageUpdated,
  onSurchargeAmountUpdated,
  onSurchargeApplyToUpdated,
  onSurchargeLabelUpdated,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <InvoiceTemplateSettings
        eInvoiceSelectEnabled={eInvoiceSelectEnabled}
        hideHeading
        disabled={formDisabled}
        isSurchargeValid={isSurchargeValid}
        showResetTemplateButton={
          invoiceTitleLine1Overridden.value || invoiceTitleLine2Overridden.value || invoiceFooterOverridden.value
        }
        templateId={`${invoiceTemplateId.value}`}
        titleOption={
          invoiceTitleLine1Overridden.value
            ? titleLine1EnumToString(invoiceTitleLine1Option.value)
            : titleLine1EnumToString(templateInvoiceSettings.titleLine1Option)
        }
        titleText={
          invoiceTitleLine1Overridden.value
            ? invoiceTitleLine1CustomText.value || ''
            : templateInvoiceSettings.titleLine1CustomText || ''
        }
        subtitleOption={
          invoiceTitleLine2Overridden.value
            ? titleLine2EnumToString(invoiceTitleLine2Option.value)
            : titleLine2EnumToString(templateInvoiceSettings.titleLine2Option)
        }
        subtitleText={
          invoiceTitleLine2Overridden.value
            ? invoiceTitleLine2CustomText.value || ''
            : templateInvoiceSettings.titleLine2CustomText || ''
        }
        footer={
          invoiceFooterOverridden.value
            ? decodeFooter(invoiceFooter.value)
            : decodeFooter(templateInvoiceSettings.footer)
        }
        selectedDebtorId={selectedDebtorId?.value}
        addressesOverridden={addressesOverridden?.value}
        overriddenDebtorAddresses={overriddenDebtorAddresses}
        onSetDefaultDebtorAddress={onResetToDebtorDefaultAddress}
        onChangeSelectedDebtorTab={onChangeSelectedDebtorTab}
        eInvoiceEnabledOptions={eInvoiceEnabledOptions}
        eInvoiceEnabledOption={eInvoiceEnabledOption?.value}
        payButtonEnabledOption={payButtonEnabledOption?.value}
        showPaymentsSection={showPaymentsSection}
        surchargeOverridden={surchargeOverridden?.value}
        surchargeType={surchargeType?.value}
        surchargePercentage={surchargePercentage?.value}
        surchargeAmount={surchargeAmount?.value}
        surchargeApplyTo={surchargeApplyTo?.value}
        surchargeLabel={surchargeLabel?.value}
        onSetDefaultTemplate={() => onResetToTemplateDefault(invoiceTemplateId)}
        onTemplateChange={onTemplateUpdated}
        onTitleChangeOptions={onTitleOptionsUpdated}
        onTitleChangeText={onTitleTextUpdated}
        onSubtitleChangeOptions={onSubtitleOptionsUpdated}
        onSubtitleChangeText={onSubtitleTextUpdated}
        onFooterChange={onFooterUpdated}
        onAddressesOverriddenChange={onAddressesOverriddenUpdated}
        onDebtorAddressesUpdated={onDebtorAddressesUpdated}
        onEInvoiceEnabledOptionChange={onEInvoiceEnabledOptionUpdated}
        onPayButtonEnabledOptionChange={onPayButtonEnabledOptionChange}
        onSurchargeOverriddenChange={onSurchargeOverriddenUpdated}
        onSurchargeTypeChange={onSurchargeTypeUpdated}
        onSurchargePercentageChange={onSurchargePercentageUpdated}
        onSurchargeAmountChange={onSurchargeAmountUpdated}
        onSurchargeApplyToChange={onSurchargeApplyToUpdated}
        onSurchargeLabelChange={onSurchargeLabelUpdated}
      />
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterInvoiceSettings.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  isSurchargeValid: PropTypes.bool.isRequired,
  // form fields
  invoiceTemplateId: Forms2Field,
  invoiceTitleLine1CustomText: Forms2Field,
  invoiceTitleLine1Option: Forms2Field,
  invoiceTitleLine1Overridden: Forms2Field,
  invoiceTitleLine2CustomText: Forms2Field,
  invoiceTitleLine2Option: Forms2Field,
  invoiceTitleLine2Overridden: Forms2Field,
  addressesOverridden: Forms2Field,
  overriddenDebtorAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      debtorId: PropTypes.string,
      debtorDisplayName: PropTypes.string,
      addressLines: PropTypes.string,
    }),
  ),
  selectedDebtorId: Forms2Field,
  invoiceFooter: Forms2Field,
  invoiceFooterOverridden: Forms2Field,
  eInvoiceEnabledOption: Forms2Field,
  payButtonEnabledOption: Forms2Field,
  surchargeOverridden: Forms2Field,
  surchargeType: Forms2Field,
  surchargePercentage: Forms2Field,
  surchargeAmount: Forms2Field,
  surchargeApplyTo: Forms2Field,
  surchargeLabel: Forms2Field,
  // others
  templateInvoiceSettings: PropTypes.object,
  eInvoiceEnabledOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  showPaymentsSection: PropTypes.bool,
  eInvoiceSelectEnabled: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onTemplateUpdated: PropTypes.func.isRequired,
  onResetToTemplateDefault: PropTypes.func.isRequired,
  onTitleOptionsUpdated: PropTypes.func.isRequired,
  onTitleTextUpdated: PropTypes.func.isRequired,
  onSubtitleOptionsUpdated: PropTypes.func.isRequired,
  onSubtitleTextUpdated: PropTypes.func.isRequired,
  onAddressesOverriddenUpdated: PropTypes.func.isRequired,
  onChangeSelectedDebtorTab: PropTypes.func.isRequired,
  onDebtorAddressesUpdated: PropTypes.func.isRequired,
  onResetToDebtorDefaultAddress: PropTypes.func.isRequired,
  onFooterUpdated: PropTypes.func.isRequired,
  onEInvoiceEnabledOptionUpdated: PropTypes.func.isRequired,
  onPayButtonEnabledOptionChange: PropTypes.func.isRequired,
  onSurchargeOverriddenUpdated: PropTypes.func.isRequired,
  onSurchargeTypeUpdated: PropTypes.func.isRequired,
  onSurchargePercentageUpdated: PropTypes.func.isRequired,
  onSurchargeAmountUpdated: PropTypes.func.isRequired,
  onSurchargeApplyToUpdated: PropTypes.func.isRequired,
  onSurchargeLabelUpdated: PropTypes.func.isRequired,
};

MatterInvoiceSettings.defaultProps = {
  // form state
  formDisabled: undefined,
  // form fields
  invoiceTemplateId: undefined,
  invoiceTitleLine1CustomText: undefined,
  invoiceTitleLine1Option: undefined,
  invoiceTitleLine1Overridden: undefined,
  invoiceTitleLine2CustomText: undefined,
  invoiceTitleLine2Option: undefined,
  invoiceTitleLine2Overridden: undefined,
  addressesOverridden: undefined,
  overriddenDebtorAddresses: [],
  selectedDebtorId: undefined,
  invoiceFooter: undefined,
  invoiceFooterOverridden: undefined,
  eInvoiceEnabledOption: undefined,
  payButtonEnabledOption: undefined,
  surchargeOverridden: undefined,
  surchargeType: undefined,
  surchargePercentage: undefined,
  surchargeAmount: undefined,
  surchargeApplyTo: undefined,
  surchargeLabel: undefined,
  // others
  showPaymentsSection: false,
  templateInvoiceSettings: undefined,
  eInvoiceSelectEnabled: false,
};

MatterInvoiceSettings.displayName = 'MatterInvoiceSettings';
