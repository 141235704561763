import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Button, SlidingToggle, Spinner, TemplateMacroSelector, TextEditor, forms2PropTypes } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import Styles from './InvoiceEmailTemplateSettings.module.scss';

export const InvoiceEmailTemplateSettings = ({
  emailSubject,
  emailBody,
  sendCopyToUser,
  isSaving,
  formDirty,
  onFormDataUpdated,
  formInitialised,
  doNotSendInvoiceAttachment,
  invoiceEmailMacros,
  onSaveButtonClick,
  allowInvoiceEmailAttachment,
}) => {
  const emailSubjectRef = useRef();
  let emailBodyReactQuillRef;
  const [subjectSelectedMacro, setSubjectSelectedMacro] = useState('');
  const [bodySelectedMacro, setBodySelectedMacro] = useState('');
  const [bodyEditorCursorPosition, setBodyEditorCursorPosition] = useState(0);

  if (!formInitialised) {
    return null;
  }

  return (
    <div className={Styles.invoiceEmailTemplateSettings}>
      <div className="form-group">
        <label>Email Subject</label>
        <input
          className="form-control"
          value={emailSubject.value}
          onChange={(e) => onFormDataUpdated({ key: emailSubject.key, value: e.target.value })}
          ref={emailSubjectRef}
        />
        <TemplateMacroSelector
          selectedMacroName={subjectSelectedMacro}
          macros={invoiceEmailMacros.subjectMacros}
          onMacroChanged={setSubjectSelectedMacro}
          onMacroInserted={(macroName) => {
            if (!emailSubjectRef) return;
            const selectionStart = emailSubjectRef.current.selectionStart;
            const selectionEnd = emailSubjectRef.current.selectionEnd;
            // insert the macroName
            const value = emailSubject.value;
            const newValue =
              value.substring(0, selectionStart) + macroName + value.substring(selectionEnd, value.length);
            onFormDataUpdated({ key: emailSubject.key, value: newValue });
          }}
        />
      </div>
      <div className="form-group">
        <label>Email Body</label>
        <TextEditor
          className="invoice-email-template-body-editor"
          value={emailBody.value}
          onChange={(value) => onFormDataUpdated({ key: emailBody.key, value })}
          onBlur={(range) => {
            setBodyEditorCursorPosition(range.index);
          }}
          // eslint-disable-next-line no-return-assign
          reactQuillRef={(rqRef) => (emailBodyReactQuillRef = rqRef)}
        />
        <TemplateMacroSelector
          selectedMacroName={bodySelectedMacro}
          macros={invoiceEmailMacros.bodyMacros}
          onMacroChanged={setBodySelectedMacro}
          onMacroInserted={(macroName) => {
            if (!emailBodyReactQuillRef || typeof emailBodyReactQuillRef.getEditor !== 'function') return;

            const quill = emailBodyReactQuillRef.getEditor();
            // If quill didn't have focus/selection immediately before this function is triggered, quill.getSelection() returns null
            const cursorPosition = quill.getSelection() ? quill.getSelection().index : bodyEditorCursorPosition || 0;
            quill.insertText(cursorPosition, macroName);
            setBodyEditorCursorPosition(cursorPosition + macroName.length);
          }}
        />
      </div>
      <div className={classnames('form-group', Styles.formGroupInline)}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <label
          className={Styles.toggleLabel}
          htmlFor="sendCopySlider"
          onClick={(e) =>
            e.target.name === this && // this is a hack to prevent 2nd event from SlidingToggle div that undoes this event
            onFormDataUpdated({ key: sendCopyToUser.key, value: !sendCopyToUser.value })
          }
        >
          <SlidingToggle id="sendCopySlider" scope="invoice-template-email" selected={sendCopyToUser.value} />
          <div className={Styles.labelText}>Send a Copy of the Email to the User</div>
        </label>
        {allowInvoiceEmailAttachment && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <label
            className={Styles.toggleLabel}
            htmlFor="doNotSendInvoiceSlider"
            onClick={(e) =>
              e.target.name === this && // this is a hack to prevent 2nd event from SlidingToggle div that undoes this event
              onFormDataUpdated({ key: doNotSendInvoiceAttachment.key, value: !doNotSendInvoiceAttachment.value })
            }
          >
            <SlidingToggle
              id="doNotSendInvoiceSlider"
              scope="doNotSendInvoiceSlider"
              selected={!doNotSendInvoiceAttachment.value}
            />
            <div className={Styles.labelText}>Attach the invoice to the email as a PDF document</div>
          </label>
        )}
        {allowInvoiceEmailAttachment && featureActive('BB-6865') && (
          <p className={Styles.invoiceAttachmentExplainerNote}>
            Note: The invoice PDF attachment is not included when using Descriptions on Demand
          </p>
        )}
      </div>
      <div className="form-group">
        <Button
          onClick={() => onSaveButtonClick({ emailSubject, emailBody, sendCopyToUser, doNotSendInvoiceAttachment })}
          disabled={isSaving.value || !formDirty}
        >
          {isSaving.value && <Spinner small />} Save
        </Button>
      </div>
    </div>
  );
};

InvoiceEmailTemplateSettings.displayName = 'InvoiceEmailTemplateSettings';

const { Forms2Field } = forms2PropTypes;

InvoiceEmailTemplateSettings.propTypes = {
  emailSubject: Forms2Field,
  emailBody: Forms2Field,
  sendCopyToUser: Forms2Field,
  doNotSendInvoiceAttachment: Forms2Field,
  isSaving: Forms2Field,
  addLawpayButton: PropTypes.bool,
  formDirty: PropTypes.bool,
  evergreenRetainerEnabled: PropTypes.bool,
  formInitialised: PropTypes.bool,
  onFormDataUpdated: PropTypes.func.isRequired,
  invoiceEmailMacros: PropTypes.shape({
    subjectMacros: PropTypes.array.isRequired,
    bodyMacros: PropTypes.array.isRequired,
  }).isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  allowInvoiceEmailAttachment: PropTypes.bool,
};

InvoiceEmailTemplateSettings.defaultProps = {
  emailSubject: undefined,
  emailBody: undefined,
  sendCopyToUser: undefined,
  doNotSendInvoiceAttachment: undefined,
  isSaving: undefined,
  allowInvoiceEmailAttachment: false,
  addLawpayButton: false,
  evergreenRetainerEnabled: false,
  formDirty: false,
  formInitialised: false,
};

export default InvoiceEmailTemplateSettings;
