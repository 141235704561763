import { getActiveProvider } from "@sb-billing/redux/payment-provider-settings/selectors";

angular
  .module("sb.billing.webapp")
  .controller("PaymentProviderPaymentsController", function (sbLinkService, sbOverrideRedirectService) {
    const ctrl = this;

    ctrl.getActiveProvider = getActiveProvider;
    ctrl.onClickLink = (args) => {
      // We need to call service belowe first to correctly redirect user to specific trust account or ledger
      // Please read comment in sbOverrideRedirectService for more info
      sbOverrideRedirectService.overrideNext();
      sbLinkService.onClickLink(args);
    };
  });