import React from 'react';
import classnames from 'classnames';

/**
 * asFormikField has been purposefully moved to the Billing domain to help reduce the scope of this function.
 * Currently its only usages are within the Billing domain and should eventually be completely removed.
 *
 * @deprecated Formik should no longer be used, use @sb-itops/redux/forms.2 for form state management
 */
const asFormikField = (mapFormikToComponent) => (WrappedComponent) =>
  React.memo(({ field, form, label, className, ...props }) => {
    const { name } = field;
    const cssClasses = classnames('as-formik-field row form-field-container form-group', `${name}-field`, className, {
      'has-error': form.errors[field.name],
    });

    const passThroughProps = {
      ...props,
      ...mapFormikToComponent({ field, form, ...props }),
    };

    return (
      <div className={cssClasses}>
        {label && (
          /* eslint-disable-next-line */
          <label htmlFor={name} className="field-name">
            {label}
          </label>
        )}
        <WrappedComponent name={name} {...passThroughProps} />
      </div>
    );
  });

export default asFormikField;
