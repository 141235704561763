'use strict';

const metricEventType = Object.freeze({
  EMAIL_SENT: 'EmailSent',
  COMMUNICATE_SENT: 'CommunicateSent',
  BULK_CREATE_INVOICES: 'BulkCreateInvoices',
  SLOW_LOGIN: 'SlowLogin',
  VIEW_INVOICE_LIST: 'ViewInvoiceList',
  VIEW_INVOICE_DETAIL: 'ViewInvoiceDetail',
  VIEW_INVOICE_DETAIL_EXPAND_ALL: 'ViewInvoiceDetailExpandAll',
  VIEW_INVOICE_DETAIL_EXPAND_TIMEKEEPER: 'ViewInvoiceDetailExpandTimekeeper',
  VIEW_INVOICE_DETAIL_EXPAND_TIMEKEEPER_DATE: 'ViewInvoiceDetailExpandTimekeeperDate',
  DOWNLOAD_INVOICE: 'DownloadInvoice',
  DOWNLOAD_INVOICE_SUMMARY: 'DownloadInvoiceSummary',
  PAY_INVOICES: 'PayInvoices',
});

module.exports = { metricEventType };
