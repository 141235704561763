import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { sort as sortItems } from '@sb-itops/sort';
import { getCurrentStaff, getAllStaff } from '@sb-firm-management/redux/firm-management';
import { getUsedAttorneyResponsibleIds } from '@sb-matter-management/redux/matters';
import StaffMultiSelect from './StaffMultiSelect';

const mapStateToProps = (
  state,
  { showCurrentStaffOnly, selectedStaffIds, showUsedStaffOnly, showNoneOption, onStaffChange },
) => {
  const allStaffs = getStaff(showCurrentStaffOnly, showUsedStaffOnly, showNoneOption);
  return {
    allStaffs,
    selectedStaffIds,
    onSelectStaff: (staffId) => {
      const staffIndex = selectedStaffIds.indexOf(staffId);
      const staffIsAlreadySelected = staffIndex !== -1;
      if (staffIsAlreadySelected) {
        onStaffChange([...selectedStaffIds.slice(0, staffIndex), ...selectedStaffIds.slice(staffIndex + 1)]);
      } else {
        onStaffChange([...selectedStaffIds, staffId]);
      }
    },
    onSelectAllStaff: () => {
      onStaffChange(allStaffs.map((staff) => staff.id));
    },
    onClearAllStaff: () => {
      onStaffChange([]);
    },
  };
};

function getStaff(showCurrentStaffOnly, showUsedStaffOnly, showNoneOption) {
  const allStaffs = showCurrentStaffOnly ? getCurrentStaff() : getAllStaff();
  let staffs = allStaffs;

  if (showUsedStaffOnly) {
    const usedStaffIds = getUsedAttorneyResponsibleIds();
    staffs = allStaffs.filter((staff) => usedStaffIds.includes(staff.id));
  }

  if (showNoneOption) {
    const noneStaffMember = { id: null, name: 'None', initials: 'None' };
    staffs.push(noneStaffMember);
  }

  return sortItems(staffs, ['initials'], ['ASC']);
}

const StaffMultiSelectContainer = withReduxStore(connect(mapStateToProps)(StaffMultiSelect));

StaffMultiSelectContainer.displayName = 'StaffMultiSelectContainer';

StaffMultiSelectContainer.propTypes = {
  showCurrentStaffOnly: PropTypes.bool,
  showUsedStaffOnly: PropTypes.bool,
  showNoneOption: PropTypes.bool,
  selectedStaffIds: PropTypes.arrayOf(PropTypes.string),
  onStaffChange: PropTypes.func.isRequired,
};

StaffMultiSelectContainer.defaultProps = {
  showCurrentStaffOnly: true,
  showUsedStaffOnly: false,
  showNoneOption: false,
};

export default StaffMultiSelectContainer;
