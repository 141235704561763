'use strict';

const isValidBankAccountType = ({ bankAccountType, supportsCMA, supportsOperatingAccount }) => {
  if (bankAccountType === 'ControlledMoney') {
    return supportsCMA;
  }

  if (bankAccountType === 'Operating') {
    return supportsOperatingAccount;
  }

  // otherwise valid in all regions
  return true;
};

module.exports = {
  isValidBankAccountType,
};
