'use strict';

// We can't return a map from GQL so we use special formatter for and from GQL.
// TODO This should be solved on global level
function convertSettingsForGQL({ formattedSettings }) {
  return formattedSettings
    ? { ...formattedSettings, linkedAccounts: Object.values(formattedSettings.linkedAccounts || {}) }
    : {};
}

function convertSettingsFromGQL({ formattedSettingsFromGQL }) {
  if (!formattedSettingsFromGQL) {
    return {};
  }

  const linkedAccounts = (formattedSettingsFromGQL.linkedAccounts || []).reduce((acc, linkedAcc) => {
    acc[linkedAcc.bankAccountId] = linkedAcc;
    return acc;
  }, {});
  return { ...formattedSettingsFromGQL, linkedAccounts };
}

module.exports = {
  convertSettingsForGQL,
  convertSettingsFromGQL,
};
