import { gql } from '@apollo/client';

const query = gql`
  query WebQueryMatters($matterIds: [ID]) {
    mattersWq(ids: $matterIds) {
      id
      matterNumber
      status
      matterTypeId
      isLead
      description
      descriptionAutomation
      attorneyResponsibleId
      leadMatterType
    }
  }
`;

const notificationIds = ['MatterManagementWebQuery'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const WebQueryMatters = {
  query,
  notificationIds,
};
