/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useState, useRef } from 'react';
import { Table, utils } from '@sb-itops/react/table';
import {
  Button,
  RealLoadingBar,
  Checkbox,
  InputWithAddon,
  Column,
  SlidingToggle,
  useTranslation,
} from '@sb-itops/react';
import { fetchGetP, fetchDeleteP, fetchPatchP } from '@sb-itops/redux/fetch';
import { ContextMenu } from '@sb-itops/react/context-menu';
import classNames from 'classnames';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { downloadZip, InputWithMeta } from 'client-zip';
import { Staff, Documents, Folder, Expanded, File, Document, DocumentResults } from 'types';
import { FileDetailQuery, FileDetailDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';
import { featureActive } from '@sb-itops/feature';
import { SendFilesViaCommunicateFormModal } from 'web/react-redux';
import { useMetric, sendMetric } from 'web/services/metrics';
import { setFileManagerInstalled } from 'web/redux/features/application-state/actions';
import { useDispatch } from 'react-redux';
import Styles from './DocumentsTab.module.scss';
import { IconSelector } from './icon-selector';
import { FileTree } from './file-tree';
import { ReplaceModal, DeleteModal, MoveModal, SingleInputModal, ViewFileModal, CreateLetterModal } from './modals';
import folderSrc from '../../assets/Page-1.svg';
import { DocumentList } from './DocumentList';
import { FileManagerDownloadModal } from './modals/FileManagerDownloadModal';

const { timestampLocalisedRenderer } = utils;
interface IDocumentsTabProps {
  getPersonByUserId: (id: string) => Staff;
  documents: Documents;
  loading: boolean;
  isFileManagerInstalled: boolean;
  searchFilter: string;
  setSearchFilter: (searchTerm: string) => void;
  searchResults: DocumentResults[] | undefined;
  matterName: string;
  error: string;
  selectedPath: string;
  setSelectedPath: (payload: string) => void;
  sortDirection: 'asc' | 'desc';
  sortBy: string;
  setSort: (payload: { sortBy: string; sortDirection: 'asc' | 'desc' }) => void;
  matterId: string;
  createNewFolder: (payload: string) => Promise<void>;
  selectedFolder: Folder;
  expanded: Expanded;
  setExpanded: (payload: Expanded) => void;
  uploadFiles: (payload: FileList | globalThis.File[] | null) => Promise<void>;
  replaceFile: (payload: FileList | globalThis.File[] | null, file: File) => Promise<void>;
  showRenameModal: Document | false;
  setShowRenameModal: (payload: Document | false) => void;
  showMoveModal: Document[] | false;
  setShowMoveModal: (payload: Document[] | false) => void;
  showDeleteModal: Document[] | false;
  setShowDeleteModal: (payload: Document[] | false) => void;
  showReplaceModal: { onReplace: () => void; onRename: () => void } | false;
  setShowReplaceModal: (payload: { onReplace: () => void; onRename: () => void } | false) => void;
  showNewFolderModal: boolean;
  setShowNewFolderModal: (payload: boolean) => void;
  showShareModal: { documents: Document[]; isLiving: boolean } | false;
  setShowShareModal: (payload: { documents: Document[]; isLiving: boolean } | false) => void;
  onDownload: (data: { rowData: File }) => void;
  showFileDetailModal: File | false;
  setShowFileDetailModal: (payload: File | false) => void;
  setDocuments: (payload: Documents) => void;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  getFileDetail: Function;
  showCreateLetterModal: boolean;
  setShowCreateLetterModal: (payload: boolean) => void;
  showDownloadFileManagerModal: Document | false;
  setShowDownloadFileManagerModal: (payload: Document | false) => void;
  oneDriveIsUnauthorized: boolean;
  isOpeningDocumentOnWeb: boolean;
  openDocumentOnWeb: (fileId: string) => Promise<void>;
}

const emptyDivRenderer = () => <div />;

const isConflict = (targetFolder: Folder, files: FileList | globalThis.File[] | { name: string }[] | null) => {
  if (
    Object.values(targetFolder.contents).some((i) =>
      Array.from(files || []).some((f) => f.name === `${i.data.name}${(i as File).data.fileExtension}`),
    )
  ) {
    return true;
  }
  return false;
};

const renameFile = (selectedFolder: Folder, originalFile: globalThis.File, i = 0): globalThis.File => {
  const fileNameSplit = originalFile.name.split('.');
  const extension = fileNameSplit.pop();
  const newName = i === 0 ? originalFile.name : `${fileNameSplit.join('.')} (${i}).${extension}`;
  if (isConflict(selectedFolder, [{ name: newName }])) {
    return renameFile(selectedFolder, originalFile, i + 1);
  }
  const newFile = new window.File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
  return newFile;
};

const log = getLogger('matter_documents');

const getFolderInDocs = (documents: Documents, path: string) =>
  path
    .split('.')
    .reduce((prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev), documents as Folder);

const CheckboxRenderer =
  (selectedItems, onSelect) =>
  ({ rowData }) =>
    rowData.type === 'folder' ? null : (
      <div className={Styles.alignCenter}>
        <Checkbox
          className={Styles.checkbox}
          onChange={() => onSelect({ rowData, event: {} }, true)}
          checked={!!selectedItems[rowData.data.id]}
        />
      </div>
    );

const IconRenderer = ({ rowData }) => (
  <div className={Styles.alignCenter}>
    {rowData.type === 'folder' ? (
      <img className="icon" alt="folder" src={folderSrc} />
    ) : (
      <IconSelector isSolid extension={rowData.data.fileExtension} />
    )}
    {rowData.data.isFavorite && <div className={classNames('icon icon-star-1', Styles.smallFavoriteIcon)} />}
  </div>
);

const isLoading = (file: File) => file?.loading !== undefined || file?.data?.isUploaded === false;

const ActionsRenderer =
  ({
    onRename,
    onMove,
    onDownload,
    onDelete,
    onReplace,
    onView,
    onShare,
    onFileEditing,
    matterId,
    selectedItems,
    isFileManagerInstalled,
    onUriHandled,
    oneDriveIsUnauthorized,
    isOpeningDocumentOnWeb,
    openDocumentOnWeb,
  }) =>
  ({ rowData }) => {
    const isFileEditEnabled =
      !['.eml', '.msg'].includes(rowData.data.fileExtension) && Object.keys(selectedItems).length < 2;

    const editOnWebAvailable =
      featureActive('NV-5392') &&
      rowData.type === 'file' &&
      rowData.data?.fileExtension === '.docx' &&
      oneDriveIsUnauthorized === false;

    return (
      !isLoading(rowData) && (
        <ContextMenu
          className={Styles.contextMenu}
          // eslint-disable-next-line react/no-unstable-nested-components
          arrow={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          body={({ close }) => (
            <div className={classNames(Styles.createBillContextMenuBody, 'list-group')}>
              {rowData.type === 'file' && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => {
                    onView(rowData);
                    close();
                  }}
                >
                  View Details
                </button>
              )}
              {featureActive('NUCWEB-728') && rowData.type === 'file' && (
                <button
                  type="button"
                  className={classNames(isFileEditEnabled ? '' : Styles.disabledStyles, 'list-group-item')}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!isFileEditEnabled) {
                      return;
                    }

                    sendMetric(editOnWebAvailable ? 'DocumentsOpenDesktop' : 'DocumentsOpen');

                    // smokeballfilemanager://DownloadFile?matterId=MATTERID&fileId=FILEID&fileName=FILENAME.EXTENSION
                    document.location = `smokeballfilemanager://DownloadFile?matterId=${encodeURIComponent(
                      matterId,
                    )}&fileId=${encodeURIComponent(rowData.data.id)}&fileName=${encodeURIComponent(
                      `${rowData.data.name || ''}${rowData.data.fileExtension || ''}`,
                    )}`;
                    setTimeout(() => {
                      if (isFileManagerInstalled) {
                        return;
                      }
                      if (document.hasFocus()) {
                        onFileEditing(rowData);
                      } else {
                        onUriHandled();
                      }
                    }, 100);
                    close();
                  }}
                >
                  {editOnWebAvailable ? <>Edit on Desktop</> : <>Edit</>}
                </button>
              )}
              {editOnWebAvailable && (
                <button
                  type="button"
                  className={classNames(isFileEditEnabled ? '' : Styles.disabledStyles, 'list-group-item')}
                  disabled={isOpeningDocumentOnWeb}
                  onClick={(e) => {
                    e.stopPropagation();

                    sendMetric('DocumentsOpenWeb');

                    openDocumentOnWeb(rowData.data.id);

                    close();
                  }}
                >
                  Edit on Web
                </button>
              )}
              {rowData.type === 'file' && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => {
                    onDownload({ rowData });
                    close();
                  }}
                >
                  Download
                </button>
              )}
              {featureActive('LOOP-561') && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => {
                    onShare(rowData, true, rowData.type === 'folder');
                    close();
                  }}
                >
                  Share a link
                </button>
              )}
              {featureActive('LOOP-561') && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => {
                    onShare(rowData, false, rowData.type === 'folder');
                    close();
                  }}
                >
                  Send a copy
                </button>
              )}
              {rowData.type === 'file' && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => {
                    onReplace(rowData);
                    close();
                  }}
                >
                  Replace File
                </button>
              )}
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onRename(rowData);
                  close();
                }}
              >
                Rename
              </button>
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onMove(rowData);
                  close();
                }}
              >
                Move
              </button>
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onDelete(rowData);
                  close();
                }}
              >
                Delete
              </button>
            </div>
          )}
        >
          <div className={Styles.menuIcon}>...</div>
        </ContextMenu>
      )
    );
  };

const NameRenderer =
  (setShowFileDetailModal, onFavourite) =>
  ({ rowData }) =>
    (
      <div className={Styles.item}>
        {rowData.type === 'file' && !isLoading(rowData) ? (
          <a
            onClick={(e) => {
              e.stopPropagation();
              setShowFileDetailModal(rowData);
            }}
          >
            {rowData.data.name}
          </a>
        ) : (
          <div>{rowData.data.name}</div>
        )}
        <div className={Styles.loading}>
          <RealLoadingBar loading={isLoading(rowData)} progress={rowData.loading || 0} />
        </div>
        {rowData.type === 'file' && !isLoading(rowData) && (
          <div className={Styles.favouriteIcon}>
            <div
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                onFavourite(rowData);
              }}
              className={classNames(
                'icon',
                !rowData.data.isFavorite && 'icon-star-1-1',
                rowData.data.isFavorite && 'icon-star-1',
              )}
            />
          </div>
        )}
      </div>
    );
const DateCellRenderer = ({ rowData, dataKey }) => (
  <div>{timestampLocalisedRenderer({ cellData: rowData.data[dataKey] })}</div>
);

const DateTimeCellRenderer = ({ rowData, dataKey }) => (
  <div>{timestampLocalisedRenderer({ cellData: rowData.data[dataKey], format: 'hhmm a DD/MM/YYYY' })}</div>
);

// handle drag events
const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

export const DocumentsTab = (props: IDocumentsTabProps) => {
  const {
    documents,
    loading,
    sortDirection,
    sortBy,
    setSort,
    matterId,
    createNewFolder,
    selectedFolder,
    selectedPath,
    setSelectedPath,
    expanded,
    setExpanded,
    getPersonByUserId,
    uploadFiles,
    showRenameModal,
    setShowRenameModal,
    showMoveModal,
    setShowMoveModal,
    showDeleteModal,
    setShowDeleteModal,
    showReplaceModal,
    setShowReplaceModal,
    showNewFolderModal,
    setShowNewFolderModal,
    showFileDetailModal,
    setShowFileDetailModal,
    showShareModal,
    setShowShareModal,
    setDocuments,
    replaceFile,
    matterName,
    getFileDetail,
    fileDetailResult,
    onDownload,
    searchFilter,
    setSearchFilter,
    searchResults,
    showCreateLetterModal,
    setShowCreateLetterModal,
    showDownloadFileManagerModal,
    setShowDownloadFileManagerModal,
    isFileManagerInstalled,
    oneDriveIsUnauthorized,
    isOpeningDocumentOnWeb,
    openDocumentOnWeb,
  } = props;

  const dispatch = useDispatch();

  const lineItems = Object.values(selectedFolder.contents).sort((a, b) => {
    if (a.type === 'file' && b.type === 'folder') {
      return 1;
    }
    if (b.type === 'file' && a.type === 'folder') {
      return -1;
    }
    return 0;
  });

  const { t } = useTranslation();

  useMetric('NavMatterDocuments');

  const [selection, setSelection] = useState({});
  const [showFavourites, setShowFavourites] = useState(false);
  const [documentToReplace, setDocumentToReplace] = useState<File>();
  const [updateLoading, setUpdateLoading] = useState(false);

  const inputMultiRef = useRef<HTMLInputElement>(null);
  const inputSingleRef = useRef<HTMLInputElement>(null);

  const dragDataRef = useRef<any>(null);

  // triggers when file is dropped
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0] && inputMultiRef.current) {
      const files = e.dataTransfer.files;
      if (isConflict(selectedFolder, files)) {
        setShowReplaceModal({
          onReplace: async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const f of files || []) {
              if (isConflict(selectedFolder, [f])) {
                await replaceFile(
                  files,
                  Object.values(selectedFolder.contents).find(
                    (i) => `${i.data.name}${(i as File).data.fileExtension}` === f.name,
                  ) as File,
                );
              } else {
                await uploadFiles([f]);
              }
            }
            setShowReplaceModal(false);
          },
          onRename: async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const f of files || []) {
              const newFile = renameFile(selectedFolder, f);
              await uploadFiles([newFile]);
            }
            setShowReplaceModal(false);
          },
        });
      } else {
        uploadFiles(files);
      }
    }
  };

  const moveFromTo = async (doc: Document, folderPath: string) => {
    try {
      if (isLoading(doc as File)) {
        messageDisplay.error('Please wait until file has finished uploading');
        return;
      }
      if ((doc as File).folderPath === folderPath) {
        return;
      }
      if (folderPath.includes((doc as File).data.id)) {
        messageDisplay.error('A folder cannot be moved to one of its children');
        return;
      }
      const newDocs = { ...documents };
      const oldSelectedFolder = getFolderInDocs(newDocs, doc.folderPath);

      const folderId = folderPath.split('.')[folderPath.split('.').length - 1];
      setUpdateLoading(true);

      const newSelectedFolder = getFolderInDocs(newDocs, folderPath);
      newSelectedFolder.contents[doc.data.id] = oldSelectedFolder.contents[doc.data.id];
      newSelectedFolder.contents[doc.data.id].folderPath = newSelectedFolder.folderPath
        ? `${newSelectedFolder.folderPath}.${newSelectedFolder.data.id}`
        : newSelectedFolder.data?.id || '';
      delete oldSelectedFolder.contents[doc.data.id];
      setDocuments(newDocs);
      if (doc.type === 'folder') {
        await fetchPatchP({
          path: `/matter-management/matter/folder/:accountId/${matterId}/${doc.data.id}`,
          fetchOptions: {
            body: JSON.stringify({
              parentFolderId: folderId || null,
            }),
          },
        });
      } else {
        await fetchPatchP({
          path: `/matter-management/matter/file/:accountId/${matterId}/${doc.data.id}`,
          fetchOptions: {
            body: JSON.stringify({
              folderId: folderId || null,
            }),
          },
        });
      }
      setUpdateLoading(false);
      setShowMoveModal(false);
      setSelection({});
    } catch (e) {
      messageDisplay.error('Something went wrong moving your document. Please try again later');
      log.error(e);
    }
  };

  // Ref so that we can toggle draggable on the whole row.
  // This is required so when the grip icon is dragged it can move the parent entire row
  const rowRef = React.useRef<HTMLDivElement[]>([]);

  const customDnDRowRenderer = ({
    className,
    columns,
    index,
    key,
    style,
    onRowClick,
    rowData,
  }: {
    className: string;
    columns: JSX.Element;
    index: number;
    key: string;
    style: any;
    onRowClick: Function;
    rowData: Document;
  }) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        onClick={(event) => onRowClick({ event, index, rowData })}
        {...a11yProps}
        className={className}
        key={key}
        style={style}
        onDragOver={(event) => {
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          event.dataTransfer.effectAllowed = 'move';
        }}
        ref={(el) => {
          if (el) {
            rowRef.current[index] = el;
          }
        }}
        onDragStart={(event) => {
          event.stopPropagation();
          // eslint-disable-next-line no-param-reassign
          event.dataTransfer.effectAllowed = 'move';
          dragDataRef.current = rowData;
        }}
        onDrop={() => {
          if (rowData.type === 'folder' && dragDataRef.current) {
            moveFromTo(dragDataRef.current, `${rowData.folderPath}${rowData.folderPath ? '.' : ''}${rowData.data.id}`);
          }
        }}
        onDragEnd={() => {
          rowRef.current[index].setAttribute('draggable', 'false');
          dragDataRef.current = null;
        }}
      >
        {columns}
      </div>
    );
  };

  const onSelect = async ({ rowData, event }, multi) => {
    // We are only interested in the selected items
    const selectedKeys = Object.keys(selection).filter((key) => selection[key]);
    if (rowData.type === 'folder') {
      setSelectedPath(selectedPath ? `${selectedPath}.${rowData.data.id}` : rowData.data.id);
    } else if (event.shiftKey && selectedKeys.length <= 1) {
      // Select all items between current selection and clicked item
      const initialKey = selectedKeys.length ? selectedKeys[0] : null;
      const newKey = rowData.data.id;
      let foundKeys = 0;
      setSelection(
        lineItems.reduce((acc, curr, i) => {
          if (initialKey === curr.data.id) {
            // Dont de-select the initial selection
            foundKeys += 1;
            return acc;
          }
          // Select current selection or if none are selected, select from the first item
          if (newKey === curr.data.id || (!initialKey && i === 0)) {
            foundKeys += 1;
            return { ...acc, [curr.data.id]: !selection[curr.data.id] };
          }
          if (foundKeys === 1) {
            return { ...acc, [curr.data.id]: !selection[curr.data.id] };
          }
          return acc;
        }, selection),
      );
    } else if (event.ctrlKey || event.shiftKey || multi) {
      // Shift key acts like ctrl if more than 1 item is selected
      setSelection({ ...selection, [rowData.data.id]: !selection[rowData.data.id] });
    } else {
      setSelection({ [rowData.data.id]: !selection[rowData.data.id] });
    }
  };

  const onFavourite = async (rowData) => {
    setUpdateLoading(true);
    try {
      await fetchPatchP({
        path: `/matter-management/matter/file/:accountId/${matterId}/${rowData.data.id}`,
        fetchOptions: {
          body: JSON.stringify({
            isFavorite: !rowData.data.isFavorite,
          }),
        },
      });
      const newDocs = { ...documents };
      const newSelectedFolder = getFolderInDocs(newDocs, rowData.folderPath);
      (newSelectedFolder.contents[rowData.data.id] as File).data.isFavorite = !rowData.data.isFavorite;
      setDocuments(newDocs);
    } catch (err) {
      messageDisplay.error('Error saving changes, please try again later');
    }
    setUpdateLoading(false);
  };

  const ActionsMenu = useCallback(
    ({ item }: { item: Document }) => (
      <ContextMenu
        className={Styles.contextMenu}
        arrow={false}
        // eslint-disable-next-line react/no-unstable-nested-components
        body={({ close }) => (
          <div className={classNames(Styles.createBillContextMenuBody, 'list-group')}>
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                if (isLoading(item as File)) {
                  messageDisplay.error('Please wait until file has finished uploading');
                  return;
                }
                setShowRenameModal(item);
                close();
              }}
            >
              Rename
            </button>

            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                if (isLoading(item as File)) {
                  messageDisplay.error('Please wait until file has finished uploading');
                  return;
                }
                setShowMoveModal([item]);
                close();
              }}
            >
              Move
            </button>

            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                if (isLoading(item as File)) {
                  messageDisplay.error('Please wait until file has finished uploading');
                  return;
                }
                setShowDeleteModal([item]);
                close();
              }}
            >
              Delete
            </button>
          </div>
        )}
      >
        <div className={classNames(Styles.menuIcon, Styles.lineHeight)}>...</div>
      </ContextMenu>
    ),
    [],
  );

  const selectedItems = Object.entries(selection)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => value)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([key, value]) => key);

  return (
    <div className={classNames('master-detail-panel', Styles.container)}>
      {showNewFolderModal && (
        <SingleInputModal
          initial=""
          label="Name"
          onClose={() => {
            setShowNewFolderModal(false);
          }}
          title={selectedPath === '' ? 'New Folder' : 'New Subfolder'}
          onSubmit={async (name) => {
            setUpdateLoading(true);
            try {
              await createNewFolder(name);
              setShowNewFolderModal(false);
            } catch (error) {
              messageDisplay.error('Error creating new folder, please try again later');
            }
            setUpdateLoading(false);
          }}
          formSubmitting={updateLoading}
        />
      )}
      {showRenameModal && (
        <SingleInputModal
          initial={showRenameModal.data.name}
          label="Name"
          onClose={() => {
            setShowRenameModal(false);
          }}
          title="Rename"
          onSubmit={async (newName) => {
            setUpdateLoading(true);
            try {
              if (showRenameModal.type === 'folder') {
                await fetchPatchP({
                  path: `/matter-management/matter/folder/:accountId/${matterId}/${showRenameModal.data.id}`,
                  fetchOptions: {
                    body: JSON.stringify({
                      name: newName,
                    }),
                  },
                });
              } else {
                await fetchPatchP({
                  path: `/matter-management/matter/file/:accountId/${matterId}/${showRenameModal.data.id}`,
                  fetchOptions: {
                    body: JSON.stringify({
                      fileName: newName,
                    }),
                  },
                });
              }
              const newDocs = { ...documents };
              const newSelectedFolder = getFolderInDocs(newDocs, showRenameModal.folderPath);
              newSelectedFolder.contents[showRenameModal.data.id].data.name = newName;
              setDocuments(newDocs);
              setShowRenameModal(false);
            } catch (err) {
              messageDisplay.error('Error saving changes, please try again later');
            }
            setUpdateLoading(false);
          }}
          formSubmitting={updateLoading}
        />
      )}
      {showMoveModal && (
        <MoveModal
          documents={documents}
          onClose={() => {
            setShowMoveModal(false);
          }}
          onSubmit={async (folderPath) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const doc of showMoveModal) {
              await moveFromTo(doc, folderPath);
            }
          }}
          formSubmitting={updateLoading}
        />
      )}
      {showFileDetailModal && (
        <ViewFileModal
          onClose={() => {
            setShowFileDetailModal(false);
          }}
          matterId={matterId}
          file={showFileDetailModal}
          formSubmitting={updateLoading}
          getFileDetail={getFileDetail}
          fileDetailResult={fileDetailResult}
          getPersonByUserId={getPersonByUserId}
          setShowShareModal={setShowShareModal}
        />
      )}
      {showReplaceModal && (
        <ReplaceModal
          onClose={() => {
            setShowReplaceModal(false);
          }}
          onRename={showReplaceModal.onRename}
          onReplace={showReplaceModal.onReplace}
          formSubmitting={updateLoading}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onClose={() => {
            setShowDeleteModal(false);
          }}
          documents={showDeleteModal}
          onSubmit={async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const doc of showDeleteModal || []) {
              try {
                setUpdateLoading(true);
                if (doc.type === 'folder') {
                  await fetchDeleteP({
                    path: `/matter-management/matter/folder/:accountId/${matterId}/${doc.data.id}`,
                    fetchOptions: {},
                  });
                } else {
                  await fetchDeleteP({
                    path: `/matter-management/matter/file/:accountId/${matterId}/${doc.data.id}`,
                    fetchOptions: {},
                  });
                }
                const newDocs = { ...documents };
                const newSelectedFolder = getFolderInDocs(newDocs, doc.folderPath);

                delete newSelectedFolder.contents[doc.data.id];
                setDocuments(newDocs);
                setUpdateLoading(false);
                setShowDeleteModal(false);
                setSelection({});
              } catch (e) {
                messageDisplay.error('Something went wrong deleting your document. Please try again later');
                log.error(e);
              }
            }
          }}
          formSubmitting={updateLoading}
        />
      )}
      {showShareModal && (
        <SendFilesViaCommunicateFormModal
          documents={showShareModal?.documents ?? []}
          isLiving={showShareModal?.isLiving ?? false}
          isVisible
          matterId={matterId}
          onClose={() => setShowShareModal(false)}
        />
      )}
      {showCreateLetterModal && (
        <CreateLetterModal matterId={matterId} onClose={() => setShowCreateLetterModal(false)} />
      )}
      {showDownloadFileManagerModal && (
        <FileManagerDownloadModal onClose={() => setShowDownloadFileManagerModal(false)} />
      )}
      <div className={Styles.panelFilter}>
        <FileTree
          documents={documents}
          setExpanded={setExpanded}
          setSelectedPath={setSelectedPath}
          selectedPath={selectedPath}
          expanded={expanded}
          ActionsMenu={ActionsMenu}
          dragDataRef={dragDataRef}
          moveFromTo={moveFromTo}
        />
        <div className={Styles.showFavourites}>
          <span onClick={() => setShowFavourites(!showFavourites)} className={Styles.label}>
            {`Show Only ${t('favourite')}s`}
          </span>
          <SlidingToggle
            scope="show-deleted-memos"
            onChange={(name, value) => setShowFavourites(value)}
            selected={showFavourites}
          />
        </div>
      </div>
      <div
        className={classNames('panel-body', Styles.documentsBody)}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className={classNames('ribbon', 'panel', 'panel-primary')}>
          <form id="hidden-form">
            <input
              ref={inputMultiRef}
              className={Styles.hiddenFileInput}
              type="file"
              multiple
              onChange={(ev) => {
                const files = ev.target.files;
                if (!files) {
                  return;
                }
                if (isConflict(selectedFolder, files)) {
                  setShowReplaceModal({
                    onReplace: async () => {
                      // eslint-disable-next-line no-restricted-syntax
                      for (const f of files || []) {
                        if (isConflict(selectedFolder, [f])) {
                          await replaceFile(
                            files,
                            Object.values(selectedFolder.contents).find(
                              (i) => `${i.data.name}${(i as File).data.fileExtension}` === f.name,
                            ) as File,
                          );
                        } else {
                          await uploadFiles([f]);
                        }
                      }
                      setShowReplaceModal(false);
                      // Clear file input
                      if (inputMultiRef.current) {
                        inputMultiRef.current.value = '';
                        inputMultiRef.current.type = 'text';
                        inputMultiRef.current.type = 'file';
                      }
                    },
                    onRename: async () => {
                      // eslint-disable-next-line no-restricted-syntax
                      for (const f of files || []) {
                        const newFile = renameFile(selectedFolder, f);
                        await uploadFiles([newFile]);
                      }
                      setShowReplaceModal(false);
                      // Clear file input
                      if (inputMultiRef.current) {
                        inputMultiRef.current.value = '';
                        inputMultiRef.current.type = 'text';
                        inputMultiRef.current.type = 'file';
                      }
                    },
                  });
                } else {
                  uploadFiles(files).then(() => {
                    // Clear file input
                    if (inputMultiRef.current) {
                      inputMultiRef.current.value = '';
                      inputMultiRef.current.type = 'text';
                      inputMultiRef.current.type = 'file';
                    }
                  });
                }
              }}
            />
            <input
              ref={inputSingleRef}
              className={Styles.hiddenFileInput}
              type="file"
              onChange={(ev) => {
                if (documentToReplace) {
                  replaceFile(ev.target.files, documentToReplace);
                }
              }}
            />
          </form>
          <Button
            onClick={() => {
              setShowNewFolderModal(true);
            }}
          >
            {selectedPath === '' ? 'New Folder' : 'New Subfolder'}
          </Button>
          <Button
            onClick={() => {
              inputMultiRef?.current?.click();
            }}
          >
            Upload Document
          </Button>
          {featureActive('NV-5121') && (
            <Button
              onClick={() => {
                sendMetric('CreateLetterModal');
                setShowCreateLetterModal(true);
              }}
            >
              Create Letter
            </Button>
          )}
          {selectedItems.length === 1 && (
            <Button
              onClick={() => {
                const id = selectedItems[0];
                const file = selectedFolder.contents[id];
                if (file.type === 'file') {
                  if (isLoading(file as File)) {
                    messageDisplay.error('Please wait until file has finished uploading');
                    return;
                  }
                  onDownload({ rowData: file });
                }
              }}
            >
              Download Document
            </Button>
          )}
          {searchFilter.length > 2 && (
            <Button
              onClick={() => {
                setSearchFilter('');
              }}
            >
              Back to File Browser
            </Button>
          )}
          {selectedItems.length > 1 && (
            <Button
              onClick={async () => {
                sendMetric('DocumentsBulkDownload');
                messageDisplay.info('Collecting your documents, please wait');
                const files: InputWithMeta[] = [];
                try {
                  for (let i = 0; i < selectedItems.length; i += 1) {
                    const id = selectedItems[i];
                    const file = selectedFolder.contents[id];
                    if (file.type === 'file') {
                      if (isLoading(file as File)) {
                        messageDisplay.error('Please wait until file has finished uploading');
                      } else {
                        const apolloClient = getApolloClient();
                        const fileDetail = await apolloClient.query({
                          query: FileDetailDocument,
                          variables: { id: file.data.id },
                          fetchPolicy: 'network-only',
                        });

                        if (fileDetail?.data?.fileDetail?.versions?.[0]?.isCancelled) {
                          messageDisplay.error(
                            `The upload of ${
                              file.data.name + file.data.fileExtension
                            } was cancelled so this document was skipped`,
                          );
                        } else if (fileDetail?.data?.fileDetail?.versions?.[0]?.isUploaded === false) {
                          messageDisplay.error(
                            `${
                              file.data.name + file.data.fileExtension
                            } has not finished uploading yet so this document was skipped`,
                          );
                        } else {
                          const {
                            body: { downloadUrl },
                          } = await fetchGetP({
                            path: `/matter-management/matter/file/:accountId/${matterId}/${file.data.id}/download`,
                            fetchOptions: {},
                          });
                          const fileToDownload = await fetch(downloadUrl);
                          files.push({
                            name: file.data.name + file.data.fileExtension,
                            lastModified: new Date(),
                            input: fileToDownload,
                          });
                        }
                      }
                    }
                  }
                  const blob = await downloadZip(files).blob();
                  // make and click a temporary link to download the Blob
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = `${matterName || 'matter_files'}.zip`;
                  link.click();
                  link.remove();
                  URL.revokeObjectURL(link.href);
                } catch (e) {
                  log.error(e);
                  messageDisplay.error(
                    'Something went wrong collecting your documents, please try downloading them individually',
                  );
                }
              }}
            >
              {`Bulk Download (${selectedItems.length})`}
            </Button>
          )}
          {selectedItems.length > 1 && (
            <Button
              onClick={() => {
                const files = selectedItems.map((id) => selectedFolder.contents[id]);
                if (files.some((f) => !!isLoading(f as File))) {
                  messageDisplay.error('Please wait until file has finished uploading');
                  return;
                }
                sendMetric('DocumentsBulkMove');
                setShowMoveModal(files);
              }}
            >
              {`Bulk Move (${selectedItems.length})`}
            </Button>
          )}
          {selectedItems.length > 1 && (
            <Button
              onClick={() => {
                const files = selectedItems.map((id) => selectedFolder.contents[id]);
                if (files.some((f) => !!isLoading(f as File))) {
                  messageDisplay.error('Please wait until file has finished uploading');
                  return;
                }
                sendMetric('DocumentsBulkDelete');
                setShowDeleteModal(files);
              }}
            >
              {`Bulk Delete (${selectedItems.length})`}
            </Button>
          )}
          <InputWithAddon
            className={Styles.search}
            icon="search-icon"
            placeholder="Search for..."
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value || '')}
          />
        </div>
        {searchFilter.length > 2 && (
          <DocumentList entities={searchResults} getPersonByUserId={getPersonByUserId} searchMeetsMinLength />
        )}
        {searchFilter.length < 3 && (
          <Table
            className={Styles.documentsTable}
            onRowClick={onSelect}
            list={lineItems.filter((i) => !showFavourites || (i as File).data.isFavorite || i.type === 'folder')}
            sort={setSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            dataLoading={loading || updateLoading}
            rowRenderer={customDnDRowRenderer}
            rowClassName={({ rowData }) => classNames(selection[rowData?.data?.id] && Styles.selectedRow, Styles.row)}
          >
            <Column
              dataKey="grip"
              headerRenderer={emptyDivRenderer}
              width={15}
              style={{
                paddingLeft: '0',
              }}
              disableSort
              // eslint-disable-next-line react/no-unstable-nested-components
              cellRenderer={({ rowIndex }) => (
                <div className={Styles.iconAlignment}>
                  <i
                    className={classNames(Styles.dragElement, 'icon', 'icon-grab-handle')}
                    onMouseDown={() => {
                      // Required to prevent other children triggering drag events
                      rowRef.current[rowIndex].setAttribute('draggable', 'true');
                    }}
                    onMouseUp={() => {
                      // Required to prevent other children triggering drag events
                      rowRef.current[rowIndex].setAttribute('draggable', 'false');
                    }}
                  />
                </div>
              )}
            />
            <Column
              key="icon"
              dataKey="icon"
              label=""
              width={34}
              cellRenderer={CheckboxRenderer(selection, onSelect)}
              disableSort
            />
            <Column
              key="icon"
              className={Styles.noPadding}
              dataKey="icon"
              label=""
              width={34}
              cellRenderer={IconRenderer}
              disableSort
            />
            <Column
              disableSort
              key="name"
              dataKey="name"
              label="Name"
              cellRenderer={NameRenderer(setShowFileDetailModal, onFavourite)}
              flexGrow={2}
            />
            <Column
              key="dateCreated"
              dataKey="dateCreated"
              label="Date created"
              cellRenderer={DateCellRenderer}
              width={120}
            />
            <Column
              key="dateModified"
              dataKey="dateModified"
              label="Date modified"
              cellRenderer={DateTimeCellRenderer}
              width={160}
            />
            <Column
              disableSort
              key="staff"
              dataKey="staff"
              label="Staff"
              cellRenderer={({ rowData }) =>
                rowData.data.ownerId ? getPersonByUserId(rowData.data.ownerId)?.name : null
              }
              flexGrow={1}
            />
            <Column
              key="actions"
              dataKey="actions"
              label=""
              width={34}
              cellRenderer={ActionsRenderer({
                isFileManagerInstalled,
                onUriHandled: () => dispatch(setFileManagerInstalled({ isFileManagerInstalled: true })),
                onView: (rowData: File) => {
                  setShowFileDetailModal(rowData);
                },
                onReplace: (rowData: File) => {
                  setDocumentToReplace(rowData);
                  inputSingleRef?.current?.click();
                },
                onRename: (rowData: Document) => {
                  setShowRenameModal(rowData);
                },
                onMove: (rowData: Document) => {
                  setShowMoveModal([rowData]);
                },
                onDownload,
                onDelete: (rowData: Document) => {
                  setShowDeleteModal([rowData]);
                },
                onShare: (rowData: Document, isLiving: boolean) => {
                  setShowShareModal({ documents: [rowData], isLiving });
                },
                onFileEditing: (rowData: Document) => {
                  setShowDownloadFileManagerModal(rowData);
                },
                matterId,
                selectedItems,
                oneDriveIsUnauthorized,
                isOpeningDocumentOnWeb,
                openDocumentOnWeb,
              })}
              disableSort
            />
          </Table>
        )}
      </div>
    </div>
  );
};

DocumentsTab.displayName = 'DocumentsTab';
