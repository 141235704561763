import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { StatelessSelect } from '@sb-itops/react/select';
import { Spinner } from '@sb-itops/react/spinner';
import classnames from 'classnames';
import Styles from './AddressAutocomplete.module.scss';

const Option = components.Option;

const optionRenderer = ({ label, children, ...props }) => {
  const splitAddress = label.split(new RegExp(`(${props.selectProps.inputValue})`, 'gi'));
  // Only highlight if we got an odd number of results. If we did not, it will be very difficult to tell which part of the string to highlight
  if (splitAddress.length && (splitAddress.length - 1) % 2 === 0) {
    return (
      <Option {...props}>
        {splitAddress.map((text, index) => {
          if (index % 2) {
            return <strong>{text}</strong>;
          }
          return <span>{text}</span>;
        })}
      </Option>
    );
  }
  return (
    <Option {...props} label={label}>
      {children}
    </Option>
  );
};

const AddressAutocomplete = ({ label, onSelect, fetchAddressAutocompleteInfo, fetchAndMapAddress, isLoaded }) => {
  const [address, setAddress] = useState(null);
  const onSelectHandler = async (option) => {
    setAddress(option);
    const addressInfo = await fetchAddressAutocompleteInfo(option.value);
    onSelect(addressInfo);
  };

  return (
    <>
      <label>{label}</label>
      {isLoaded ? (
        <div className={classnames('input-group', Styles.search)}>
          <div className="input-group-addon">
            <i className={classnames('fa-fw', 'fa', 'fa-search')} />
          </div>
          <StatelessSelect
            placeholder="Enter address here"
            showDropdown={false}
            loadOptions={fetchAndMapAddress}
            onChange={onSelectHandler}
            selectedOption={address}
            onFocus={() => {
              setAddress(null);
            }}
            optionRenderer={optionRenderer}
            noOptionsMessage={(message) => {
              if (message.inputValue) {
                return 'No results found';
              }
              return null;
            }}
          />
        </div>
      ) : (
        <div style={{ height: '33px' }}>
          <Spinner small />
        </div>
      )}
    </>
  );
};

AddressAutocomplete.displayName = 'AddressAutocomplete';

AddressAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  fetchAddressAutocompleteInfo: PropTypes.func.isRequired,
  fetchAndMapAddress: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  label: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
};

AddressAutocomplete.defaultProps = {
  selectedOption: undefined,
  label: undefined,
};

export default AddressAutocomplete;
