import { connect } from 'react-redux';
import { getRegion } from '@sb-itops/environment-config';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import AgingSummaryFilter from './AgingSummaryFilter';

const defaultSelectOption = { value: 'all', label: 'All' };

const mapStateToProps = (state, { filters }) => ({
  staff: [defaultSelectOption, ...getCurrentStaff().map((s) => ({ value: s.id, label: `${s.initials} - ${s.name}` }))],
  filters,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      onFiltersChanged({
        region: getRegion(),
        attorneyResponsible: defaultSelectOption,
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
      });
    }
  },

  onSelectOption: ({ key, option }) => onFiltersChanged({ ...filters, [key]: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const AgingSummaryFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(AgingSummaryFilter)),
);

AgingSummaryFilterContainer.displayName = `AgingSummaryFilterContainer`;

export default AgingSummaryFilterContainer;
