/**
 * build a feature scope path.
 *
 * @param {string} scope - the scope within the feature
 * @param {Object.<string, string|number>} [options] - this is a key value pair object.
 * @return string a id based on the scope + options
 * @throw if scope is falsy
 * @throw if options is not an object
 *
 * @example:
 *
 * const result = buildFeatureScope('my-feature', { contactId: 'uuid1', invoiceId: 'uuid2' })
 *
 * // result will be: 'my-feature-contactId:uuid1-invoiceId:uuid2'
 *
 */
export const buildFeatureScope = (scope, options = {}) => {
  if (!scope) {
    throw new Error(`path cannot be falsy`);
  }

  if (options && typeof options !== 'object') {
    throw new Error(`options need to be an object`);
  }

  const entries = Object.entries(options);

  if(entries.length === 0){
    return scope;
  }

  const entriesSorted = entries.sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  return `${scope}-${entriesSorted
    .reduce((acc, [key, value]) => {
      acc.push(`${key}:${value}`);
      return acc;
    }, [])
    .join('-')}`;
};
