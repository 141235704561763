angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/settings/billing-utilities';

  $stateProvider

  .state('settings.utilities', {
    templateUrl: BASE_DIRECTORY + '/billing-utilities.html',
    url: '/billing-utilities',
    data: {
      navHighlights: ['billing-utilities']
    },
    controller: 'sbBillingUtilitiesController',
    controllerAs: '$ctrl',
  });

});
