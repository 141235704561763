import React from 'react';
import PropTypes from 'prop-types';
import { LinkableText, Translation } from '@sb-itops/react';
import { sentViaTypes, externalSentViaTypes, getSentViaText } from '@sb-billing/business-logic/correspondence-history';
import { Spinner } from '@sb-itops/react/spinner';
import { featureActive } from '@sb-itops/feature';
import Styles from './MultiContactCorrespondenceDetails.module.scss';

export const MultiContactCorrespondenceDetails = (props) => {
  const { latestCorrespondencePerContact, loading } = props;

  // If there is no latestCorrespondencePerContact, it means this invoice has not yet been sent
  if (!loading && latestCorrespondencePerContact?.length === 0) {
    return renderSendViaOptions(props);
  }

  return (
    <div className={Styles.multiContactCorrespondenceDetails}>
      {loading ? (
        <Spinner small />
      ) : (
        (latestCorrespondencePerContact || []).map((correspondenceHistory) => {
          const { status } = correspondenceHistory;

          switch (status) {
            case -1: // Not sent.
              return null;
            case 0: // In progress
            case 1: // Success
              return renderNonFailure(correspondenceHistory, props);
            case 2: // Error
              return renderFailure(correspondenceHistory, props);
            default:
              throw new Error(`Unexpected correspondence status ${status}`);
          }
        })
      )}
    </div>
  );
};

const renderNonFailure = (correspondenceHistory) => {
  const { id, contactDisplayName, userDisplayName, to, cc, bcc, from, lastUpdated, status, sentVia, sentTimestamp } =
    correspondenceHistory;

  const isManuallySent = Object.values(externalSentViaTypes).includes(sentVia);

  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });

  return (
    <div className={Styles.contactStatus} key={id}>
      <div>
        <span className={Styles.detailLabel} />
        <span className={Styles.contactName}>{isManuallySent ? 'INVOICE SENT MANUALLY' : contactDisplayName}</span>
      </div>
      <div>
        <span>
          <span className={Styles.detailLabel}>Status: </span>
          {status === 0 ? 'In Progress' : `Sent${sentViaText}`}
        </span>
      </div>
      {!isManuallySent && (
        <>
          <div>
            <span className={Styles.detailLabel}>To: </span>
            <span className={Styles.detailValue}>{to}</span>
          </div>
          {!!cc && (
            <div>
              <span className={Styles.detailLabel}>Cc: </span>
              <span className={Styles.detailValue}>{cc}</span>
            </div>
          )}
          {!!bcc && (
            <div>
              <span className={Styles.detailLabel}>Bcc: </span>
              <span className={Styles.detailValue}>{bcc}</span>
            </div>
          )}
          <div>
            <span className={Styles.detailLabel}>From: </span>
            <span className={Styles.detailValue}>{from}</span>
          </div>
        </>
      )}
      <div>
        <span className={Styles.detailLabel}>Sent By: </span>
        <span className={Styles.detailValue}>{userDisplayName}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>On: </span>
        <Translation>{(t) => t('date', { ts: sentTimestamp || lastUpdated })}</Translation>
      </div>
    </div>
  );
};

const renderFailure = (correspondenceHistory, { allowResend, onResendClicked }) => {
  const { id, contactId, errorMessage, from, contactDisplayName, userDisplayName, lastUpdated, sentVia } =
    correspondenceHistory;
  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });

  return (
    <div className={Styles.contactStatus} key={id}>
      <div>
        <span className={Styles.detailLabel} />
        <span className={Styles.contactName}>{contactDisplayName}</span>
      </div>
      <div>
        <span>
          <span className={Styles.detailLabel}>Status: </span>Failed Send{sentViaText}
        </span>
      </div>
      <div>
        <span className={Styles.detailLabel}>Reason: </span>
        <span className={Styles.detailValue}>{errorMessage}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>From: </span>
        <span className={Styles.detailValue}>{from}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>Sent By: </span>
        <span className={Styles.detailValue}>{userDisplayName}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>On: </span>
        <Translation>{(t) => t('date', { ts: lastUpdated })}</Translation>
      </div>
      {allowResend && (
        <div className={Styles.resendLink}>
          <span>
            <LinkableText text="Resend" onClickLink={() => onResendClicked({ contactId, sentVia })} asLink />
          </span>
        </div>
      )}
    </div>
  );
};

const renderSendViaOptions = ({ onSendViaMenuClicked }) => (
  <div className="context-menu-body list-group">
    <button
      type="button"
      className="list-group-item"
      onClick={() => {
        onSendViaMenuClicked({ sendVia: sentViaTypes.EMAIL });
      }}
    >
      Send via Email
    </button>
    {featureActive('BB-9097') && (
      <button
        type="button"
        className="list-group-item"
        onClick={() => {
          onSendViaMenuClicked({ sendVia: sentViaTypes.COMMUNICATE });
        }}
      >
        Send via Client Portal
      </button>
    )}
    {featureActive('BB-11448') && (
      <button type="button" className="list-group-item" onClick={onSendViaMenuClicked}>
        Mark as Sent
      </button>
    )}
  </div>
);

MultiContactCorrespondenceDetails.displayName = 'MultiContactCorrespondenceDetails';

MultiContactCorrespondenceDetails.propTypes = {
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object).isRequired,
  allowResend: PropTypes.bool,
  onResendClicked: PropTypes.func,
  onSendViaMenuClicked: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};

MultiContactCorrespondenceDetails.defaultProps = {
  allowResend: false,
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};
