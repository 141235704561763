'use strict';

const appEnv = Object.freeze({
  DEV: 'dev',
  RC: 'rc',
  QA: 'qa',
  LIVE: 'live',
});

module.exports = {
  appEnv,
};
