const httpMethods = Object.freeze({
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
});

const responseTypes = Object.freeze({
  arrayBuffer: 'arrayBuffer',
  blob: 'blob',
  json: 'json',
  text: 'text',
});

const bodyDownloaders = {
  [responseTypes.arrayBuffer]: (res) => res.arrayBuffer(),
  [responseTypes.blob]: (res) => res.blob(),
  // res.json is shorthand for res.text then JSON.parse.
  // If the response happens to be invalid JSON the body is lost, this avoids that scenario and returns the text (which is technically valid JSON)
  // See https://www.ietf.org/rfc/rfc7159.txt and https://jsonlint.com/
  [responseTypes.json]: (res) => res.text().then((data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      // Most of the time we dont expect a simple string when we receive a JSON payload
      // This allows us to debug unexpected payloads
      console.error(new Error(`Could not parse JSON response body: ${data}`));
      return data;
    }
  }),
  [responseTypes.text]: (res) => res.text(),
};

module.exports = {
  httpMethods,
  responseTypes,
  bodyDownloaders,
};
