import { SET_PRE_SIGNED_URL, SET_PRE_SIGNED_URL_FAILED } from './types';

const initState = {};

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_PRE_SIGNED_URL: {
      const id = action.payload.s3Bucket;
      return {
        ...state,
        [id]: {
          preSignedUrl: action.payload.preSignedUrl,
          lastUpdated: new Date(),
        },
      };
    }

    case SET_PRE_SIGNED_URL_FAILED: {
      const id = action.payload.s3Bucket;
      return {
        ...state,
        [id]: {
          ...(state[id] || {}), // restore any prev value
          failed: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};

// reducer for this state tree
export default reducer;
