export const marshalSubmit = ({
  formValues,
  beneficialOwnerDifferent,
  countryCode,
  shouldValidateTrustAccount,
  confirmationDateTime,
  externalFirmId,
  localisationConfig,
}) => {
  const { firmDetails, operatingAccountDetails, trustAccountDetails, authorizedRepresentative, beneficialOwner } =
    formValues;

  const formatDates = (dateObj) => moment(dateObj).format('YYYY-MM-DD');
  const marshalledValues = {
    // Firm Details
    company: {
      external_id: externalFirmId,
      legal_name: firmDetails.legalCompanyName,
      legal_structure: localisationConfig.showBusinessStructure ? firmDetails.businessStructure : 'PrivateCorporation',
      tax_id: firmDetails.employerIdNumber,
      trading_name: firmDetails.tradingAs || firmDetails.legalCompanyName,
      contact_number: firmDetails.phoneNumber,
      contact_email: firmDetails.emailAddress,
      billing_email: firmDetails.emailAddress,
      website: firmDetails.website,
    },
    address: {
      address1: firmDetails.streetAddressLine1,
      address2: firmDetails.streetAddressLine2,
      city: firmDetails.city,
      state_code: firmDetails.state,
      country_code: countryCode,
      postal_code: firmDetails.zipCode,
    },

    // Operating Account Details
    office_account: {
      account_type: 'Office',
      account_name: operatingAccountDetails.accountHolderName,
      alias: operatingAccountDetails.accountName,
      branch_code: operatingAccountDetails.routingNumber,
      account_number: operatingAccountDetails.accountNumber,
      account_holder_type: operatingAccountDetails.accountType,
      is_default: operatingAccountDetails.nominateForMonthlyBilling,
      country_code: countryCode,
    },

    roles: [
      {
        // Authorised Representative
        first_name: authorizedRepresentative.firstName,
        last_name: authorizedRepresentative.lastName,
        title: authorizedRepresentative.jobTitle,
        phone: authorizedRepresentative.phoneNumber,
        email: authorizedRepresentative.emailAddress,
        address: {
          address1: authorizedRepresentative.streetAddressLine1,
          address2: authorizedRepresentative.streetAddressLine2,
          city: authorizedRepresentative.city,
          state_code: authorizedRepresentative.state,
          country_code: countryCode,
          postal_code: authorizedRepresentative.zipCode,
        },
        date_of_birth: formatDates(authorizedRepresentative.dateOfBirth),
        is_representative: true,
        is_executive: authorizedRepresentative.businessOwnership >= 25,
        ownership_percentage: authorizedRepresentative.businessOwnership.toString(),
        ssn_last_4: authorizedRepresentative.last4SocialSecurity,
        national_id: authorizedRepresentative.socialSecurityNumber,
      },
    ],

    terms_of_service: {
      accepted: confirmationDateTime,
      document_name: 'FeeWise Terms of Service and Privacy Policy',
    },
  };
  if (beneficialOwnerDifferent) {
    marshalledValues.roles.push({
      // Majority/Beneficial Owner
      first_name: beneficialOwner.firstName,
      last_name: beneficialOwner.lastName,
      title: beneficialOwner.jobTitle,
      phone: beneficialOwner.phoneNumber,
      email: beneficialOwner.emailAddress,
      address: {
        address1: beneficialOwner.streetAddressLine1,
        address2: beneficialOwner.streetAddressLine2,
        city: beneficialOwner.city,
        state_code: beneficialOwner.state,
        country_code: countryCode,
        postal_code: beneficialOwner.zipCode,
      },
      date_of_birth: formatDates(beneficialOwner.dateOfBirth),
      is_representative: false,
      is_executive: true,
      ownership_percentage: beneficialOwner.businessOwnership.toString(),
      ssn_last_4: beneficialOwner.last4SocialSecurity,
      national_id: beneficialOwner.socialSecurityNumber,
    });
  }

  if (shouldValidateTrustAccount) {
    marshalledValues.trust_account = {
      account_type: 'Trust',
      account_name: trustAccountDetails.accountHolderName,
      alias: trustAccountDetails.accountName,
      branch_code: trustAccountDetails.routingNumber,
      account_number: trustAccountDetails.accountNumber,
      account_holder_type: trustAccountDetails.accountType,
      country_code: countryCode,
    };
  }

  return marshalledValues;
};
