import { getFirmName } from '@sb-firm-management/redux/firm-management';
import {
  getActiveTrustAccountsWithoutCheckingMTAFeature,
  getTrustAccount,
} from '@sb-billing/redux/bank-account';
import { getLatest as getLatestBankReconciliation } from '@sb-billing/redux/bank-reconciliations';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';

angular
  .module('sb.billing.webapp')
  .controller('sbBankReconCancelController', function($scope, sbBankReconciliationService, sbLocalisationService, sbMessageDisplayService, sbEnvironmentConfigService) {
    'use strict';

    if (sbEnvironmentConfigService.getFrontendEnv() === 'PRODUCTION') {
      return;
    }
    const ctrl = this;

    ctrl.trustAccounts = getActiveTrustAccountsWithoutCheckingMTAFeature().map((trust) => ({ label: `${getBankAccountName(trust, sbLocalisationService.t)} (${trust.id})`, value: trust }));
    ctrl.selectedTrustAccount = ctrl.trustAccounts.find((trustAccount) => trustAccount.value.id === getTrustAccount().id);
    ctrl.onSelectTrustAccount = onSelectTrustAccount;
    ctrl.getAccountName = (val) => getBankAccountName(val, sbLocalisationService.t);
    ctrl.reconciliation = ctrl.selectedTrustAccount ? getLatestBankReconciliation(ctrl.selectedTrustAccount.value.id, true) : undefined;
    ctrl.firmName = getFirmName();
    ctrl.show = true;
    ctrl.onClickProceed = onClickProceed;
    ctrl.onCloseModal = onCloseModal;
    ctrl.proceedOk = () => `${ctrl.proceed}`.trim() === `${ctrl.firmName}`.trim() && `${ctrl.trustAccountName}`.trim() === `${getBankAccountName(ctrl.selectedTrustAccount.value, sbLocalisationService.t)}`.trim();
    ctrl.onClickCancelRecon = onClickCancelRecon;
    ctrl.formatDate = formatDate;
    ctrl.t = sbLocalisationService.t

    function onClickProceed() {
      cancelBankRec();
    }
    
    function onCloseModal() {
      ctrl.proceed = '';
      ctrl.trustAccountName = '';
    }

    function formatDate(yyyymmdd) {
      return sbLocalisationService.t('date', { yyyymmdd, format: 'Do MMM YYYY' });
    }
    
    function onClickCancelRecon() {
      if (!ctrl.note) {
        ctrl.error = true;

        return;
      }
      ctrl.confirmCancelRecon();
    }

    function fetchLatestBankRecon() {
      ctrl.reconciliation = ctrl.selectedTrustAccount ? getLatestBankReconciliation(ctrl.selectedTrustAccount.value.id, true) : undefined;
    }

    function onSelectTrustAccount(selection) {
      ctrl.selectedTrustAccount = selection;
      ctrl.reconciliation = selection ? getLatestBankReconciliation(selection.value.id, true) : undefined;
    }
    
    function buildMessage(msg, err) {
      return sbMessageDisplayService
        .builder()
        .text(msg)
        .conditionalText(': {0}', err && err.message);
    }

    function cancelBankRec() {
      if (!ctrl.note) {
        ctrl.error = true;

        return;
      }

      ctrl.error = false;

      // NOTE: cancelP resolves on undefined recon ID
      sbBankReconciliationService.cancelP(ctrl.reconciliation.id, ctrl.note)
        .then(() => {
          sbMessageDisplayService.success(buildMessage('Reconciliation cancelled'));
          ctrl.note = '';
          ctrl.proceed = '';
          ctrl.trustAccountName = '';
        })
        .catch(err => {
          sbMessageDisplayService.error(buildMessage('Failed to cancel reconciliation', err));
        });
    }

    const listeners = [
      $scope.$on('$destroy', () => {
        for (let unregister of listeners) unregister();
      }),
      $scope.$on('smokeball-data-update-sbBankReconciliationService', fetchLatestBankRecon),
    ];

  });
