'use strict';

/**
 * get initials for a person name
 * @param {string} param.firstName string
 * @param {string} param.lastName string
 * @returns {string|undefined} return a string of initials based on provided names, return undefined if no first and last name provided.
 * initials is not requiered by Communicate notification, pass undefined instead of '' to avoid overriding any value may genereted on Communicate side
 */
const getPersonInitials = ({ firstName = '', lastName = '' }) =>
  `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`.toUpperCase() || undefined;

module.exports = {
  getPersonInitials,
};
