import { gql } from '@apollo/client';

import { contactAddressesFragment } from '../fragments/contactAddressesFragment.graphql';

const query = gql`
  query DepositFundsMatterData($matterId: ID!) {
    matter(id: $matterId) {
      id
      description
      clients {
        id
        displayNameFirstLast
        type
        ...contactAddressesFragment
        people {
          id
          accountId
          cell
          displayName
          displayNameFirstLast
          email
          firstName
          lastName
          phone
          phone2
          type
          ...contactAddressesFragment
        }
      }
    }
  }

  ${contactAddressesFragment}
`;

const notificationIds = ['MatterManagementWebQuery.MatterUpdated', 'CustomerManagementWebQuery.EntityUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DepositFundsMatterData = {
  query,
  notificationIds,
};
