import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  today,
  startOfToday,
  endOfToday,
  startOfDay,
  endOfDay,
  startOfThisMonth,
  endOfThisMonth,
  startOfLastMonth,
  endOfLastMonth,
  dateToInteger,
  isValidDate,
} from '@sb-itops/date';
import moment from 'moment';
import { featureActive } from '@sb-itops/feature';
import { capitalizeAllWords } from '@sb-itops/nodash';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useTranslation } from '@sb-itops/react';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as panelExpanderFeature from '@sb-itops/redux/panel-expander';
import * as expenseFiltersFeature from 'web/redux/features/expense-filters';
import { dateFilterTypes } from './constants';
import { ExpenseFilter } from './ExpenseFilter';

interface IProps {
  filterScope: string;
  filterDateRange?: {
    from: number;
    to: number;
  };
  onUpdateFilterDateRange: (range: { from: number; to: number }) => void;
  loggedInStaff?: {
    id: string;
  };
  staffMembers: {
    id: string;
    name: string;
    initials: string;
  }[];
  staffIds?: string[];
  onSelectStaffIds: (ids: string[]) => void;
  anticipatedDisbursementsEnabled: boolean;
  expenseStatusCounts?: {
    disbursements: number;
    anticipatedDisbursements: number;
    anticipatedDisbursementsUnpaid: number;
    anticipatedDisbursementsOverdue: number;
    anticipatedDisbursementsPaid: number;
  };
  expenseStatusCountsLoading?: boolean;
}

const hooks = ({
  filterScope,
  filterDateRange,
  onUpdateFilterDateRange,
  loggedInStaff,
  staffIds,
  onSelectStaffIds,
  anticipatedDisbursementsEnabled,
  expenseStatusCounts,
}: IProps) => ({
  useFilterTabs: () => {
    const { selectors, actions } = useScopedFeature(panelExpanderFeature, filterScope);
    const expanded = useSelector<{}, boolean>(selectors.getExpanded);
    const dispatch = useDispatch();

    return {
      expanded,
      toggleExpanded: () => {
        dispatch(actions.toggleExpanded());
      },
    };
  },
  useFilters: () => {
    const { t } = useTranslation();

    // Do not copy this pattern, it was used because all of the filter
    // settings are persisted via the expense-filters feature and needed
    // to be backwards-compatible.

    const dispatch = useDispatch();
    const expenseFilters = useScopedFeature(expenseFiltersFeature, filterScope);

    // #region date filter
    const selectedDateFilterType =
      useSelector<{}, keyof typeof dateFilterTypes>(expenseFilters.selectors.getSelectedDateFilterType) ||
      dateFilterTypes.TODAY;
    const selectedBeforeDate = useSelector(expenseFilters.selectors.getSelectedBeforeDate) || today();
    const selectedFromDate = useSelector(expenseFilters.selectors.getSelectedFromDate) || startOfThisMonth();
    const selectedToDate = useSelector(expenseFilters.selectors.getSelectedToDate) || endOfThisMonth();
    const [beforeDateIsValid, setBeforeDateIsValid] = useState(isValidDate(selectedBeforeDate));
    const [fromDateIsValid, setFromDateIsValid] = useState(isValidDate(selectedFromDate));
    const [toDateIsValid, setToDateIsValid] = useState(isValidDate(selectedToDate));

    const onChangeDates = (newFormValues) => {
      const formValues = {
        selectedDateFilterType,
        selectedBeforeDate,
        selectedFromDate,
        selectedToDate,
        ...newFormValues,
      };

      let fromDate;
      let toDate;
      switch (formValues.selectedDateFilterType) {
        case dateFilterTypes.BEFORE:
          fromDate = undefined;
          toDate = moment(formValues.selectedBeforeDate).subtract(1, 'day').toDate();
          break;
        case dateFilterTypes.THIS_MONTH:
          fromDate = startOfThisMonth();
          toDate = endOfThisMonth();
          break;
        case dateFilterTypes.LAST_MONTH:
          fromDate = startOfLastMonth();
          toDate = endOfLastMonth();
          break;
        case dateFilterTypes.CUSTOM:
          fromDate = formValues.selectedFromDate;
          toDate = formValues.selectedToDate;
          break;
        default:
          // defaults to showing expenses from today
          fromDate = startOfToday();
          toDate = endOfToday();
      }

      const fromDateInteger = !fromDate ? fromDate : dateToInteger(fromDate);
      const toDateInteger = !toDate ? toDate : dateToInteger(toDate);

      // Update parent container if the values have changed
      if (!filterDateRange || filterDateRange.from !== fromDateInteger || filterDateRange.to !== toDateInteger) {
        onUpdateFilterDateRange({ from: fromDateInteger, to: toDateInteger });
      }
    };

    const getDefaultDateFieldValues = () => ({
      selectedDateFilterType: dateFilterTypes.TODAY,
      beforeDate: today(),
      fromDate: startOfThisMonth(),
      toDate: endOfThisMonth(),
    });

    // When a user returns to this route `filterDateRange` state is undefined
    if (!filterDateRange) {
      onChangeDates(undefined);
    }

    const resetDateFilterValidations = () => {
      setBeforeDateIsValid(isValidDate(selectedBeforeDate));
      setFromDateIsValid(isValidDate(selectedFromDate));
      setToDateIsValid(isValidDate(selectedToDate));
    };

    const onUpdateDateField = (field, value) => {
      switch (field) {
        case 'selectedDateFilterType': {
          if (selectedDateFilterType !== value) {
            dispatch(expenseFilters.actions.selectDateFilterType({ dateFilterType: value }));

            // Propagate any changes to the parent container
            onChangeDates({ [field]: value });

            resetDateFilterValidations();
          }
          break;
        }
        case 'selectedBeforeDate': {
          if (value && isValidDate(value)) {
            dispatch(expenseFilters.actions.selectBeforeDate({ beforeDate: value }));

            // Propagate any changes to the parent container
            onChangeDates({ [field]: value });

            if (!beforeDateIsValid) {
              setBeforeDateIsValid(true);
            }
          } else {
            setBeforeDateIsValid(false);
          }
          break;
        }
        case 'selectedFromDate': {
          const fromDate = value && isValidDate(value) && startOfDay(value);
          if (fromDate && fromDate <= selectedToDate) {
            dispatch(expenseFilters.actions.selectFromDate({ fromDate: startOfDay(value) }));

            // Propagate any changes to the parent container
            onChangeDates({ [field]: value });

            if (!fromDateIsValid) {
              setFromDateIsValid(true);
            }
          } else {
            setFromDateIsValid(false);
          }
          break;
        }
        case 'selectedToDate': {
          const toDate = value && isValidDate(value) && endOfDay(value);
          if (toDate && toDate >= selectedFromDate) {
            dispatch(expenseFilters.actions.selectToDate({ toDate: endOfDay(value) }));

            // Propagate any changes to the parent container
            onChangeDates({ [field]: value });

            if (!toDateIsValid) {
              setToDateIsValid(true);
            }
          } else {
            setToDateIsValid(false);
          }
          break;
        }
        default:
          break;
      }
    };
    // #endregion date filter

    // #region staff filter
    const selectedStaffIds =
      useSelector<{}, string[]>(expenseFilters.selectors.getSelectedStaffIds) ||
      (loggedInStaff && [loggedInStaff.id]) ||
      [];

    if (!staffIds) {
      onSelectStaffIds(selectedStaffIds);
    }

    const onStaffSelectionChange = (newStaffIds) => {
      dispatch(expenseFilters.actions.selectStaffIds({ staffIds: newStaffIds }));
      onSelectStaffIds(newStaffIds);
    };
    // #endregion staff filter

    // #region status filter
    const hideIfOnFinalisedInvoice =
      useSelector<{}, boolean>(expenseFilters.selectors.getHideIfOnFinalisedInvoice) || false;
    const hideIfOnACheck = useSelector<{}, boolean>(expenseFilters.selectors.getHideIfOnACheck) || false; // US naming for consistency
    const showDisbursements = useSelector<{}, boolean>(expenseFilters.selectors.getShowDisbursements);
    const showAnticipatedDisbursements = useSelector<{}, boolean>(
      expenseFilters.selectors.getShowAnticipatedDisbursements,
    );
    const showAnticipatedDisbursementsUnpaid = useSelector<{}, boolean>(
      expenseFilters.selectors.getShowAnticipatedDisbursementsUnpaid,
    );
    const showAnticipatedDisbursementsOverdue = useSelector<{}, boolean>(
      expenseFilters.selectors.getShowAnticipatedDisbursementsOverdue,
    );
    const showAnticipatedDisbursementsPaid = useSelector<{}, boolean>(
      expenseFilters.selectors.getShowAnticipatedDisbursementsPaid,
    );

    const SHOW_DISBURSEMENTS = 'expense-filters-showDisbursements';
    const SHOW_ANTICIPATED_DISBURSEMENTS = 'expense-filters-showAnticipatedDisbursements';
    const SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID = 'expense-filters-showAnticipatedDisbursementsUnpaid';
    const SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE = 'expense-filters-showAnticipatedDisbursementsOverdue';
    const SHOW_ANTICIPATED_DISBURSEMENTS_PAID = 'expense-filters-showAnticipatedDisbursementsPaid';
    const HIDE_IF_ON_FINALISED_INVOICE_FILTER_ID = 'expense-filters-hideIfOnFinalisedInvoice';
    const HIDE_IF_ON_A_CHECK_FILTER_ID = 'expense-filters-hideIfOnACheck';

    const statusToggleOptions = !anticipatedDisbursementsEnabled
      ? undefined
      : [
          {
            id: SHOW_DISBURSEMENTS,
            name: capitalizeAllWords(t('expenses')),
            selected: showDisbursements,
            count: expenseStatusCounts?.disbursements || 0,
          },
          {
            id: SHOW_ANTICIPATED_DISBURSEMENTS,
            name: `Anticipated ${capitalizeAllWords(t('expenses'))}`,
            selected: showAnticipatedDisbursements,
            count: expenseStatusCounts?.anticipatedDisbursements || 0,
          },
          {
            id: SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID,
            name: 'Unpaid',
            selected: showAnticipatedDisbursementsUnpaid,
            count: expenseStatusCounts?.anticipatedDisbursementsUnpaid || 0,
          },
          {
            id: SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE,
            name: 'Overdue',
            selected: showAnticipatedDisbursementsOverdue,
            count: expenseStatusCounts?.anticipatedDisbursementsOverdue || 0,
          },
          {
            id: SHOW_ANTICIPATED_DISBURSEMENTS_PAID,
            name: 'Paid',
            selected: showAnticipatedDisbursementsPaid,
            count: expenseStatusCounts?.anticipatedDisbursementsPaid || 0,
          },
        ];

    const onShowDisbursements = (value) => {
      dispatch(expenseFilters.actions.setShowDisbursements({ showDisbursements: value }));
    };
    const onShowAnticipatedDisbursements = (value) => {
      dispatch(expenseFilters.actions.setShowAnticipatedDisbursements({ showAnticipatedDisbursements: value }));
    };
    const onShowAnticipatedDisbursementsUnpaid = (value) => {
      dispatch(
        expenseFilters.actions.setShowAnticipatedDisbursementsUnpaid({ showAnticipatedDisbursementsUnpaid: value }),
      );
    };
    const onShowAnticipatedDisbursementsOverdue = (value) => {
      dispatch(
        expenseFilters.actions.setShowAnticipatedDisbursementsOverdue({ showAnticipatedDisbursementsOverdue: value }),
      );
    };
    const onShowAnticipatedDisbursementsPaid = (value) => {
      dispatch(expenseFilters.actions.setShowAnticipatedDisbursementsPaid({ showAnticipatedDisbursementsPaid: value }));
    };
    const onStatusOptionsChange = (field, value) => {
      switch (field) {
        case SHOW_DISBURSEMENTS:
          onShowDisbursements(value);
          break;
        case SHOW_ANTICIPATED_DISBURSEMENTS:
          onShowAnticipatedDisbursements(value);
          onShowAnticipatedDisbursementsUnpaid(value);
          onShowAnticipatedDisbursementsOverdue(value);
          onShowAnticipatedDisbursementsPaid(value);
          break;
        case SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID:
          if (value && !showAnticipatedDisbursements) {
            onShowAnticipatedDisbursements(true);
          }

          onShowAnticipatedDisbursementsUnpaid(value);
          break;
        case SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE:
          if (value && !showAnticipatedDisbursements) {
            onShowAnticipatedDisbursements(true);
          }

          onShowAnticipatedDisbursementsOverdue(value);
          break;
        case SHOW_ANTICIPATED_DISBURSEMENTS_PAID:
          if (value && !showAnticipatedDisbursements) {
            onShowAnticipatedDisbursements(true);
          }

          onShowAnticipatedDisbursementsPaid(value);
          break;
        default:
          break;
      }

      const statuses = {
        [SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID]: showAnticipatedDisbursementsUnpaid,
        [SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE]: showAnticipatedDisbursementsOverdue,
        [SHOW_ANTICIPATED_DISBURSEMENTS_PAID]: showAnticipatedDisbursementsPaid,
      };
      statuses[field] = value;
      // If there are no anticipated disbursements child statuses selected,
      // deselect the main option too
      if (showAnticipatedDisbursements && !Object.values(statuses).some((status) => !!status)) {
        onShowAnticipatedDisbursements(false);
      }
    };

    const otherOptions = [
      {
        id: HIDE_IF_ON_FINALISED_INVOICE_FILTER_ID,
        name: `Hide if on ${t('finalised')} invoices`,
        selected: hideIfOnFinalisedInvoice,
        count: 0,
      },
    ];
    if (featureActive('BB-3331')) {
      otherOptions.push({
        id: HIDE_IF_ON_A_CHECK_FILTER_ID,
        name: `Hide if on a ${t('cheque')}`,
        selected: hideIfOnACheck,
        count: 0,
      });
    }

    const onOtherOptionsChange = (field, value) => {
      switch (field) {
        case HIDE_IF_ON_FINALISED_INVOICE_FILTER_ID:
          dispatch(expenseFilters.actions.setHideIfOnFinalisedInvoice({ hideIfOnFinalisedInvoice: value }));
          break;
        case HIDE_IF_ON_A_CHECK_FILTER_ID:
          dispatch(expenseFilters.actions.setHideIfOnACheck({ hideIfOnACheck: value }));
          break;
        default:
          break;
      }
    };
    // #endregion status filter

    const onResetFilters = () => {
      const defaultDateFieldValues = getDefaultDateFieldValues();
      dispatch(
        expenseFilters.actions.selectDateFilterType({ dateFilterType: defaultDateFieldValues.selectedDateFilterType }),
      );
      dispatch(expenseFilters.actions.selectBeforeDate({ beforeDate: defaultDateFieldValues.beforeDate }));
      dispatch(expenseFilters.actions.selectFromDate({ fromDate: startOfDay(defaultDateFieldValues.fromDate) }));
      dispatch(expenseFilters.actions.selectToDate({ toDate: endOfDay(defaultDateFieldValues.toDate) }));
      resetDateFilterValidations();
      onChangeDates(defaultDateFieldValues);

      dispatch(expenseFilters.actions.selectStaffIds({ staffIds: (loggedInStaff && [loggedInStaff.id]) || [] }));
      dispatch(expenseFilters.actions.setHideIfOnFinalisedInvoice({ hideIfOnFinalisedInvoice: false }));
      dispatch(expenseFilters.actions.setHideIfOnACheck({ hideIfOnACheck: false }));
      onShowDisbursements(true);
      onShowAnticipatedDisbursements(true);
      onShowAnticipatedDisbursementsUnpaid(true);
      onShowAnticipatedDisbursementsOverdue(true);
      onShowAnticipatedDisbursementsPaid(true);
    };

    return {
      // Dates
      selectedDateFilterType,
      selectedBeforeDate,
      selectedFromDate,
      selectedToDate,
      beforeDateIsValid,
      fromDateIsValid,
      toDateIsValid,
      onUpdateDateField,

      // Staff
      selectedStaffIds,
      onStaffSelectionChange,

      // Status + Other
      statusToggleOptions,
      onStatusOptionsChange,
      otherOptions,
      onOtherOptionsChange,

      onResetFilters,
    };
  },
});

export const ExpenseFilterContainer = composeHooks(hooks)(ExpenseFilter);
