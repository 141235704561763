import { gql } from '@apollo/client';

const query = gql`
  query FeeWiseLinkedAccounts {
    feeWiseLinkedAccounts {
      feeWiseAccountId
      bankAccount {
        id
        state
        accountType
        accountName
        displayName
      }
    }
  }
`;

const notificationIds = ['PaymentProviderSettingsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FeeWiseLinkedAccountOptions = {
  query,
  notificationIds,
};
