import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

const { opdateCache: opdateExpenseCache } = optimisticUpdateFactory({
  ngCacheName: 'sbExpenseService',
  keyPath: 'expenseId',
});

export const onSaveExpense = ({ commandResult }) => {
  opdateExpenseCache({ optimisticEntities: [{ expenseId: commandResult.expenseId, ...commandResult.version }] });
};
