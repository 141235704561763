import composeHooks from '@sb-itops/react-hooks-compose';
import { getRegion } from '@sb-itops/region';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { getTaxRate } from '@sb-billing/redux/gst-tax-settings';
import { isLeadMatter, isBillableLeadMatterId } from '@sb-matter-management/redux/matters';
import { fee as feeSummary, shared } from '@sb-billing/business-logic';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import moment from 'moment';
import { FeeTableData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { BillableHoursWidget } from './BillableHoursWidget';

const { FIXED } = shared.entities.entryType;
const REGION = getRegion();

const { calculateFeeAmountTax } = feeSummary.services;

const calculateFeesAndSummary = (originalFees, region) => {
  let totalUnits = 0;
  let totalHoursBillable = 0;
  let totalHoursNonBillable = 0;

  originalFees.forEach((originalFee) => {
    const fee = { ...originalFee };
    const invoice = fee.invoiceId ? getInvoiceById(fee.invoiceId) : null;
    const firmTaxRate = getTaxRate();

    const taxRate = invoice ? invoice.currentVersion.feeTaxRate : firmTaxRate;
    const { billableAmountExclTax, nonBillableAmountExclTax } = calculateFeeAmountTax({
      fee,
      taxRate,
      region,
    });

    // helps rounding 1 minute durations up to the 2 decimal points to match the individual fee entry
    const units =
      (+fee.duration as number) && fee.feeType !== FIXED ? Number(((fee.duration * 100) / 60).toFixed(0)) * 60 : 0;
    totalUnits += units;

    // totals related to billable amount (which can be different to total amount for grouped fees)
    const total: number = billableAmountExclTax + nonBillableAmountExclTax || 1;
    totalHoursNonBillable += Number(((units * nonBillableAmountExclTax) / total / 60).toFixed(0)) * 60;
    totalHoursBillable += Number(((units * billableAmountExclTax) / total / 60).toFixed(0)) * 60;
  });

  const summary = {
    units: totalUnits / 100,
    billable: totalHoursBillable / 100,
    nonBillable: totalHoursNonBillable / 100,
  };

  return { summary };
};

interface Props {
  isEditing: boolean;
  onChange: Function;
  onRemove: Function;
  onClickLink: React.MouseEventHandler;
  settings: any;
  matterId: string;
}

const hooks = ({ matterId }: Props) => ({
  useFeeListData: () => {
    const feesQueryResult = useSubscribedQuery(FeeTableData, {
      variables: {
        feeFilter: {
          feeDate: {
            from: +moment().format('YYYYMMDD'),
            to: +moment().format('YYYYMMDD'),
          },
          feeEarnerStaffIds: [getLoggedInStaff()?.id],
          includeStatus: {
            current: true,
          },
          matterIds: matterId ? [matterId] : undefined,
        },
      },
    });

    if (feesQueryResult.error) {
      throw feesQueryResult.error;
    }

    const { data: feeData } = feesQueryResult;
    const feeList = feeData?.feeList?.results || [];

    const filteredFees = feeList.filter((fee) => !isLeadMatter(fee.matterId) || isBillableLeadMatterId(fee.matterId));

    const { summary } = calculateFeesAndSummary(filteredFees, REGION);

    const hours = (summary.units / 60).toFixed(2);
    const billableHours = (summary.billable / 60).toFixed(2);
    const nonBillableHours = (summary.nonBillable / 60).toFixed(2);

    return {
      loading: filteredFees.length === 0 && feesQueryResult.loading,
      staff: getLoggedInStaff(),
      hours,
      billableHours,
      nonBillableHours,
    };
  },
});

export const BillableHoursWidgetContainer = withApolloClient(composeHooks(hooks)(BillableHoursWidget));
