import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// eslint-disable-next-line
import { DEPRECATED_ProceedDialog } from "@sb-itops/react/proceed-dialog";
import { ButtonGroupButton } from '../button-group-button';

const ButtonGroup = memo((props) => {
  const {
    buttons,
    onDiscardCancel,
    onDiscardConfirm,
    onSelect,
    onEdit,
    onDelete,
    selectedButtonId,
    showDiscardDialog,
    className,
  } = props;
  const cssClasses = classnames('button-group', className);

  const confirmationMessage = 'Are you sure you want to discard your changes to the template?';

  return (
    <>
      <div className={cssClasses}>
        {buttons.map((button) => {
          const isSelected = button.id === selectedButtonId;
          return (
            <ButtonGroupButton
              key={button.id}
              data={button}
              onSelect={onSelect}
              onEdit={onEdit}
              onDelete={onDelete}
              unsavedChanges={button.unsavedChanges}
              isSelected={isSelected}
              editMode={button.editMode}
            />
          );
        })}
      </div>
      {showDiscardDialog && (
        // eslint-disable-next-line
        <DEPRECATED_ProceedDialog
          bodyText={confirmationMessage}
          noCaption="Cancel"
          onYesClick={onDiscardConfirm}
          onNoClick={onDiscardCancel}
          title="Discard changes to invoice settings template"
          yesCaption="Discard"
        />
      )}
    </>
  );
});

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      deletable: PropTypes.bool,
      editable: PropTypes.bool,
      createNew: PropTypes.bool,
      unsavedChanges: PropTypes.bool,
      isDeleted: PropTypes.bool,
      editMode: PropTypes.bool,
    }),
  ).isRequired,
  onDiscardCancel: PropTypes.func,
  onDiscardConfirm: PropTypes.func,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  selectedButtonId: PropTypes.string,
  showDiscardDialog: PropTypes.bool,
  className: PropTypes.string,
};

ButtonGroup.defaultProps = {
  selectedButtonId: undefined,
  showDiscardDialog: false,
  className: undefined,
  onDiscardCancel: () => {},
  onDiscardConfirm: () => {},
  onSelect: () => {},
  onEdit: () => {},
  onDelete: () => {},
};

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
