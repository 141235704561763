import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onCreateEditTask = ({ commandMessage }) => {
  const optimisticTask = {
    __typename: 'Task',
    completionDate: commandMessage.isCompleted && new Date().toISOString(),
    // Shouldnt be blank, but can be for opdates!
    accountId: '',
    parentTaskId: '',
    creatorId: '',
    lastUpdatedById: '',
    subTaskIds: [],
    categories: [],
    completerId: '',
    taskDefinitionId: '',
    assigneeId: '',
    assigneeType: '0',
    priority: '0',
    reminderTime: null,
    creationTimeStamp: '',
    duration: null,
    durationString: '',
    durationSetByWorkflow: false,
    nonRemovedSubTasksCount: 0,
    completedSubTasksCount: 0,
    ...commandMessage,
  };

  const apolloClient = getApolloClient();

  const taskIdentifier = apolloClient.cache.identify(optimisticTask);

  apolloClient.writeFragment({
    id: taskIdentifier,
    fragment: gql`
      fragment OpdateTask on Task {
        ${Object.keys(optimisticTask).join('\n')}
      }
    `,
    data: {
      ...optimisticTask,
    },
  });

  return {
    eventType: commandMessage.isNewEntity
      ? apolloEntityOpdateEventTypes.ENTITY_ADDED
      : apolloEntityOpdateEventTypes.ENTITY_UPDATED,
    typeName: 'Task',
    optimisticEntity: optimisticTask,
  };
};
