import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';

import { CheckboxLabelInline, FormLabel, Icon, LinkableText, StatelessSelect, TextEditor } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { payButtonEnabledOptionValues } from '@sb-billing/business-logic/matters/invoice-settings';

import { InvoiceSettingTitle } from '../invoice-setting-title';
import { InvoiceTemplateDropdownContainer } from '../invoice-template-dropdown';
import { SurchargeSettings } from '../surcharge-settings';

const InvoiceTemplateSettings = memo(
  ({
    // form fields
    templateId,
    titleOption,
    titleText,
    subtitleOption,
    subtitleText,
    footer,
    eInvoiceEnabledOption,
    payButtonEnabledOption,
    addressesOverridden,
    overriddenDebtorAddresses,
    selectedDebtorId,
    surchargeOverridden,
    surchargeType,
    surchargePercentage,
    surchargeAmount,
    surchargeApplyTo,
    surchargeLabel,
    // other
    disabled,
    eInvoiceEnabledOptions,
    hideHeading,
    isSurchargeValid,
    isValidFooter,
    selectedTemplateDeleted,
    showPaymentsSection,
    showResetTemplateButton,
    showResetToDefaultButton,
    eInvoiceSelectEnabled,

    // callbacks
    onTemplateChange,
    onTitleChangeOptions,
    onTitleChangeText,
    onSubtitleChangeText,
    onSubtitleChangeOptions,
    onFooterChange,
    onDebtorAddressesUpdated,
    onEInvoiceEnabledOptionChange,
    onPayButtonEnabledOptionChange,
    onAddressesOverriddenChange,
    onSetDefaultDebtorAddress,
    onChangeSelectedDebtorTab,
    onSurchargeOverriddenChange,
    onSurchargePercentageChange,
    onSurchargeAmountChange,
    onSurchargeTypeChange,
    onSurchargeApplyToChange,
    onSurchargeLabelChange,
    onClearSelection,
    onSetDefaultTemplate,
  }) => {
    // on un-mount
    useEffect(() => () => onClearSelection(), [onClearSelection]);
    const [clickedResetToTemplateDefault, setClickedResetToTemplateDefault] = useState(false);
    const selectedDebtor = overriddenDebtorAddresses?.find((debtor) => debtor.debtorId === selectedDebtorId);

    return (
      <div className="edit-matter-detail-panel">
        <div className="form-underline" />
        {!hideHeading && <h5>Invoice Settings</h5>}
        <div className="title form-group">
          <FormLabel label="Invoice Template" optional />
          <InvoiceTemplateDropdownContainer
            disabled={disabled}
            templateId={templateId}
            onValueChange={onTemplateChange}
          />
          {showResetToDefaultButton && (
            <LinkableText asLink text="Reset to firm default" onClickLink={onClearSelection} inline />
          )}
          {showResetTemplateButton && (
            <>
              <span className="reset-separator" />
              <LinkableText
                asLink
                text="Reset to template default"
                onClickLink={() => {
                  onSetDefaultTemplate();
                  setClickedResetToTemplateDefault(true);
                }}
                inline
              />
            </>
          )}
          {selectedTemplateDeleted && (
            <span>
              The selected invoice template has been deleted from firm settings, but will continue to be applied until
              changed.
            </span>
          )}
        </div>
        <div className="title form-group">
          <FormLabel label="Invoice Title" optional />
          <InvoiceSettingTitle
            showFirmOption={false}
            showResetButton={false}
            selectedOption={titleOption}
            selectedText={titleText}
            onChangeOption={onTitleChangeOptions}
            onChangeText={onTitleChangeText}
            clickedResetToTemplateDefault={clickedResetToTemplateDefault}
            setClickedResetToTemplateDefault={setClickedResetToTemplateDefault}
          />
        </div>
        <div className="title form-group">
          <FormLabel label="Invoice Subtitle" optional />
          <InvoiceSettingTitle
            showFirmOption={false}
            showResetButton={false}
            selectedOption={subtitleOption}
            selectedText={subtitleText}
            onChangeOption={onSubtitleChangeOptions}
            onChangeText={onSubtitleChangeText}
            clickedResetToTemplateDefault={clickedResetToTemplateDefault}
            setClickedResetToTemplateDefault={setClickedResetToTemplateDefault}
          />
        </div>
        {featureActive('BB-12393') && (
          <div className="title form-group">
            <FormLabel label="Invoice Address" optional />
            <div className="override-address-checkbox">
              <CheckboxLabelInline checked={addressesOverridden} onChange={onAddressesOverriddenChange}>
                Override debtor address
              </CheckboxLabelInline>
            </div>
            {addressesOverridden && (
              <div className="override-debtor-addresses">
                <div className="sb-tab-header">
                  <ul className="nav nav-pills">
                    {overriddenDebtorAddresses.map((d) => (
                      <li className={classnames({ active: d.debtorId === selectedDebtorId })} key={d.debtorId}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => onChangeSelectedDebtorTab(d.debtorId)}>{d.debtorDisplayName}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                {selectedDebtor && (
                  <>
                    <textarea
                      className="form-control"
                      disabled={disabled}
                      onChange={(e) => onDebtorAddressesUpdated(e.target.value)}
                      rows="5"
                      value={selectedDebtor.addressLines || ''}
                    />
                    <span className="address-reset-separator" />
                    <LinkableText asLink text="RESET" onClickLink={onSetDefaultDebtorAddress} inline />
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {featureActive('BB-4706') && (
          <div className="form-group matter-settings-notes-field">
            <FormLabel label="Notes" optional />
            <TextEditor
              value={footer}
              className={!isValidFooter ? 'text-editor-error' : ''}
              fixedHeight
              onChange={onFooterChange}
              disabled={disabled}
            />
            {!isValidFooter && <div className="footer-error">Maximum 30 lines allowed</div>}
          </div>
        )}
        {featureActive('BB-5725') && eInvoiceSelectEnabled && (
          <div className="form-group">
            <FormLabel label="EInvoice" optional />
            <StatelessSelect
              disabled={disabled}
              onChange={onEInvoiceEnabledOptionChange}
              options={eInvoiceEnabledOptions}
              selectedOption={eInvoiceEnabledOption}
            />
          </div>
        )}
        {featureActive('BB-7270') && (
          <div className="form-group surcharge-settings">
            <FormLabel label="Surcharge" optional />
            <div className="override-surcharge-checkbox">
              <CheckboxLabelInline checked={surchargeOverridden} onChange={onSurchargeOverriddenChange}>
                Override default surcharge settings
              </CheckboxLabelInline>
            </div>
            {surchargeOverridden && (
              <div className="override-surcharge-settings">
                <SurchargeSettings
                  surchargeAmount={surchargeAmount}
                  surchargeApplyTo={surchargeApplyTo}
                  surchargeLabel={surchargeLabel}
                  surchargePercentage={surchargePercentage}
                  surchargeType={surchargeType}
                  isSurchargeValid={isSurchargeValid}
                  onSurchargeAmountChange={onSurchargeAmountChange}
                  onSurchargeApplyToChange={onSurchargeApplyToChange}
                  onSurchargeLabelChange={onSurchargeLabelChange}
                  onSurchargePercentageChange={onSurchargePercentageChange}
                  onSurchargeTypeChange={onSurchargeTypeChange}
                />
              </div>
            )}
          </div>
        )}
        {showPaymentsSection && (
          <div className="form-group">
            <FormLabel label="Payments" optional />
            <div className="payments-settings">
              <CheckboxLabelInline
                checked={payButtonEnabledOption === payButtonEnabledOptionValues.ENABLED}
                onChange={onPayButtonEnabledOptionChange}
              >
                Include payment buttons and QR codes on invoices and emails/messages
              </CheckboxLabelInline>
              <Icon
                type="information"
                tooltip="This option applies to the Pay button and QR code on your PDF invoices and invoice reminders, invoice emails and Client Portal messages, and the eInvoice portal"
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

InvoiceTemplateSettings.propTypes = {
  // form fields
  templateId: PropTypes.string,
  titleText: PropTypes.string,
  titleOption: PropTypes.string,
  subtitleText: PropTypes.string,
  subtitleOption: PropTypes.string,
  footer: PropTypes.string,
  eInvoiceEnabledOption: PropTypes.number,
  payButtonEnabledOption: PropTypes.number,
  surchargeOverridden: PropTypes.bool,
  addressesOverridden: PropTypes.bool,
  overriddenDebtorAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      debtorId: PropTypes.string,
      debtorDisplayName: PropTypes.string,
      addressLines: PropTypes.string,
    }),
  ),
  selectedDebtorId: PropTypes.string,
  surchargeType: PropTypes.number,
  surchargeAmount: PropTypes.number,
  surchargePercentage: PropTypes.number,
  surchargeApplyTo: PropTypes.number,
  surchargeLabel: PropTypes.string,
  // other
  disabled: PropTypes.bool,
  eInvoiceEnabledOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  hideHeading: PropTypes.bool,
  isSurchargeValid: PropTypes.bool.isRequired,
  isValidFooter: PropTypes.bool,
  selectedTemplateDeleted: PropTypes.bool,
  showPaymentsSection: PropTypes.bool,
  showResetTemplateButton: PropTypes.bool,
  showResetToDefaultButton: PropTypes.bool,
  eInvoiceSelectEnabled: PropTypes.bool,
  // callbacks
  onTemplateChange: PropTypes.func.isRequired,
  onTitleChangeOptions: PropTypes.func.isRequired,
  onTitleChangeText: PropTypes.func.isRequired,
  onSubtitleChangeOptions: PropTypes.func.isRequired,
  onSubtitleChangeText: PropTypes.func.isRequired,
  onFooterChange: PropTypes.func.isRequired,
  onDebtorAddressesUpdated: PropTypes.func.isRequired,
  onEInvoiceEnabledOptionChange: PropTypes.func.isRequired,
  onPayButtonEnabledOptionChange: PropTypes.func.isRequired,
  onSurchargeOverriddenChange: PropTypes.func.isRequired,
  onAddressesOverriddenChange: PropTypes.func.isRequired,
  onSetDefaultDebtorAddress: PropTypes.func.isRequired,
  onChangeSelectedDebtorTab: PropTypes.func.isRequired,
  onSurchargeTypeChange: PropTypes.func.isRequired,
  onSurchargePercentageChange: PropTypes.func.isRequired,
  onSurchargeAmountChange: PropTypes.func.isRequired,
  onSurchargeApplyToChange: PropTypes.func.isRequired,
  onSurchargeLabelChange: PropTypes.func.isRequired,
  onClearSelection: PropTypes.func,
  onSetDefaultTemplate: PropTypes.func,
};

InvoiceTemplateSettings.defaultProps = {
  // form fields
  templateId: undefined,
  titleText: undefined,
  titleOption: undefined,
  subtitleText: undefined,
  subtitleOption: undefined,
  footer: undefined,
  eInvoiceEnabledOption: undefined,
  payButtonEnabledOption: payButtonEnabledOptionValues.ENABLED,
  surchargeOverridden: false,
  addressesOverridden: false,
  overriddenDebtorAddresses: [],
  selectedDebtorId: undefined,
  surchargeType: undefined,
  surchargePercentage: 0,
  surchargeAmount: 0,
  surchargeApplyTo: undefined,
  surchargeLabel: undefined,
  // other
  isValidFooter: true,
  showPaymentsSection: false,
  showResetTemplateButton: false,
  showResetToDefaultButton: false,
  selectedTemplateDeleted: false,
  hideHeading: false,
  disabled: false,
  eInvoiceSelectEnabled: false,
  // callbacks
  onClearSelection: () => {},
  onSetDefaultTemplate: undefined,
};

export default InvoiceTemplateSettings;
