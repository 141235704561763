import { 
  getById,
  getList,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/deposit-slip';

angular.module('@sb-billing/services').service('sbDepositSlipService', function (sbLoggerService, sbGenericEndpointService, sbGenericCacheService,
  sbEndpointType, sbUuidService, sbBankReconciliationService) {
  'use strict';

  const that = this;
  const log = sbLoggerService.getLogger('sbDepositSlipService');
  const ENDPOINT = 'billing/deposit-slips';

  sbGenericCacheService.setupCache({
    name: 'sbDepositSlipService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: ENDPOINT },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.DepositSlipUpdated',
    },
    updateRedux,
    clearRedux,
  });

  that.get = get;
  that.getByFilter = getByFilter;
  that.saveP = saveP;
  that.deleteP = deleteP;
  that.isReconciled = isReconciled;

  function get (id) {
    if (id) {
      const slip  = getById(id);
      return slip.isRemoved ? undefined : slip;
    }

    return getList().filter((slip) => !slip.isRemoved);
  }

  function getByFilter(filter) {
    return getList().filter(filter);
  }

  function saveP (data) {
    log.info(`saving deposit slip:`, data);
    return sbGenericEndpointService.postPayloadP(ENDPOINT, '', data, 'POST', {changeset: {sbDepositSlipService: [data]}});
  }

  function deleteP (depositSlipId) {
    log.info(`deleting deposit slip:`, depositSlipId);
    const data = {
      versionId: sbUuidService.get(),
      id: depositSlipId,
    };

    return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/delete`, depositSlipId, data); //
  }

  function isReconciled (depositSlipId) {
    const depositSlip = get(depositSlipId);

    if (!depositSlip || !depositSlip.reconciliationId) {
      return false;
    }

    const reconciliation = sbBankReconciliationService.getById(depositSlip.reconciliationId);

    //if it has a reconciliationid but no reconciliation, assume its been reconciled via the recon setup.
    return !reconciliation || reconciliation.status === 'Completed';
  }

});
