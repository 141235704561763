'use strict';

const { getLogoUrl } = require('./get-logo-url');

function getFirmLogoUrl({ sbDomain, env, accountId }) {
  const invoiceLogoUrl = getLogoUrl({ sbDomain, env, path: `firm/${accountId}/logo` });
  return invoiceLogoUrl;
}

module.exports = {
  getFirmLogoUrl,
};
