import PropTypes from 'prop-types';

import { featureActive } from '@sb-itops/feature';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities/status';
import composeHooks from '@sb-itops/react-hooks-compose';

import { UnpaidAnticipatedDisbursementAcknowledgementModalData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { UnpaidAnticipatedDisbursementAcknowledgementModal } from './UnpaidAnticipatedDisbursementAcknowledgementModal';

export const UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID =
  'UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID';

const onModalClose = () => setModalDialogHidden({ modalId: UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID });

const hooks = () => ({
  useQueryData: ({ invoiceId }) => {
    const isAnticipatedDisbursementsFeatureEnabled = featureActive('BB-9573');

    const { data, loading, error } = useSubscribedQuery(UnpaidAnticipatedDisbursementAcknowledgementModalData, {
      variables: {
        ids: [invoiceId],
        invoiceListFilter: {
          invoiceStatuses: [invoiceStatus.FINAL],
        },
      },
      skip: !isAnticipatedDisbursementsFeatureEnabled,
    });

    if (error) {
      throw new Error(error);
    }

    const invoiceHasUnpaidAD = !!data?.invoiceList?.results?.[0]?.listItemProperties?.hasUnpaidAnticipatedDisbursements;

    return {
      isAnticipatedDisbursementsFeatureEnabled,
      isLoading: loading,
      invoiceHasUnpaidAD,
    };
  },
});

const dependentHooks = () => ({
  useModal: (props) => {
    const { isAnticipatedDisbursementsFeatureEnabled, invoiceHasUnpaidAD, isLoading } = props;

    const onAcknowledgeAndProceed = () => {
      props.onAcknowledgeAndProceed();
      onModalClose(); // Close this modal, when proceeding
    };

    // Can automatically proceed if:
    //  1. AD feature is not enabled
    //  2. AD feature is enabled, but there are no unpaid ADs
    if (!isAnticipatedDisbursementsFeatureEnabled || (!isLoading && !invoiceHasUnpaidAD)) {
      onAcknowledgeAndProceed();
    }

    return {
      onAcknowledgeAndProceed,
      onModalClose,
    };
  },
});

export const UnpaidAnticipatedDisbursementAcknowledgementModalContainer = withApolloClient(
  composeHooks(hooks)(composeHooks(dependentHooks)(UnpaidAnticipatedDisbursementAcknowledgementModal)),
);

UnpaidAnticipatedDisbursementAcknowledgementModalContainer.displayName =
  'UnpaidAnticipatedDisbursementAcknowledgementModalContainer';

UnpaidAnticipatedDisbursementAcknowledgementModalContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onAcknowledgeAndProceed: PropTypes.func.isRequired,
};

UnpaidAnticipatedDisbursementAcknowledgementModalContainer.defaultProps = {};
