import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation } from '@sb-itops/react';

import Styles from './FinaliseInvoiceConfirmationModal.module.scss';

export const FinaliseInvoiceConfirmationModal = ({
  hasUnpaidAD,
  hasZeroBalance,
  invoiceCount,
  isLoading,
  isVisible,
  // Callbacks
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title="Please Confirm"
      className={Styles.finaliseInvoiceConfirmationModal}
      modalBodyClassName={Styles.modalBodyContainer}
      body={getBody({ isLoading, hasZeroBalance, hasUnpaidAD, invoiceCount, t })}
      isLoading={isLoading}
      footer={
        <div className={Styles.modalFooter}>
          <div className={Styles.buttonGroup}>
            <Button className={Styles.footerBtn} onClick={onConfirm} disabled={isLoading}>
              {t('finalise')}
            </Button>
            <Button className={Styles.footerBtn} type="secondary" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
          </div>
        </div>
      }
      isVisible={isVisible}
      onModalClose={onCancel}
      shouldCloseOnOverlayClick={false}
    />
  );
};

const getBody = ({ hasZeroBalance, hasUnpaidAD, invoiceCount, t }) => (
  <div className={Styles.modalBody}>
    {hasZeroBalance && !hasUnpaidAD && (
      <>
        <p>
          {`${invoiceCount === 1 ? 'This invoice' : `At least one of the invoices you've selected`} has a ${t(
            'currencySymbol',
          )}0 balance and
          will be marked as paid upon ${t('finalising')}.`}
        </p>
        <p>This status cannot be reversed.</p>
      </>
    )}
    {!hasZeroBalance && hasUnpaidAD && (
      <p>
        {`${invoiceCount === 1 ? 'This invoice' : `One or more invoices`}  contains an anticipated ${t(
          'expense',
        )} that has not yet been paid to the supplier.`}
      </p>
    )}
    {hasZeroBalance && hasUnpaidAD && (
      <ul>
        <li>
          {`${invoiceCount === 1 ? 'This invoice' : `At least one of the invoices you've selected`} has a ${t(
            'currencySymbol',
          )}0 balance and
          will be marked as paid upon ${t('finalising')}. This status cannot be reversed.`}
        </li>
        <li>{`${invoiceCount === 1 ? 'This invoice' : `One or more invoices`}  contains an anticipated ${t(
          'expense',
        )} that has not yet been paid to the supplier.`}</li>
      </ul>
    )}
    <p>Are you sure you want to proceed?</p>
  </div>
);
FinaliseInvoiceConfirmationModal.displayName = 'FinaliseInvoiceConfirmationModal';

FinaliseInvoiceConfirmationModal.propTypes = {
  hasUnpaidAD: PropTypes.bool,
  hasZeroBalance: PropTypes.bool,
  invoiceCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  // Callbacks
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

FinaliseInvoiceConfirmationModal.defaultProps = {
  // Undefined until data is fetched
  hasUnpaidAD: undefined,
  hasZeroBalance: undefined,
};
