import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getMap,
  getCurrentContacts,
  getById as getContactById
} from '@sb-customer-management/redux/contacts-summary';

angular.module('@sb-customer-management/services').service('sbContactsMbService', function (sbGenericCacheService, sbEndpointType) {
  const that = this;
  // no need to store reference to cache as all data is managed by redux
  sbGenericCacheService.setupCache({
      name: 'sbContactsMbService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'customer-management' },
        poll: 15,
        subscriptions: ['notifier.CustomerManagementNotifications', 'notifier.CustomerManagementNotfications'],
      },
    updateRedux,
    clearRedux,
    });

  that.getById = getById;
  that.getByIds = getByIds;
  that.getCurrentContacts = getCurrentContacts;
  that.getCurrentContactsNotRemoved = getCurrentContactsNotRemoved;

  function getCurrentContactsNotRemoved() {
    return _.filter(getMap(), function (c) {
      return !c.removed;
    });
  }

  function getByIds(idArray) {
    return idArray.map(id => getById(id));
  }

  function getById(id) {
    return getContactById(id);
  }

});
