const { capitalize } = require('@sb-itops/nodash');

/**
 * Get localised version of transaction description.
 *
 * @param {function} t Translate function
 * @param {string} description Transaction description
 * @returns {string}
 */
function localiseDescription(t, description) {
  // check deposit and their corresponding reversal transactions has the word
  // "Check deposit" in the description field irrespective of region, thus why
  // the following translation is required. there seem to be no other ways to identify
  // these transactions, using the txn.type field is not sufficient.
  if (description === 'Check deposit') {
    return `${capitalize(t('cheque'))} deposit`;
  }

  if (description === 'Reversal: Check deposit') {
    return `Reversal: ${capitalize(t('cheque'))} deposit`;
  }

  // otherwise pass through existing description with no translation
  return description;
}

module.exports = {
  localiseDescription,
};
