import { SET_SORT_BY, RESET_FILTER } from './types';

export const onResetFilters = () => ({
  type: RESET_FILTER,
  payload: {},
});
export const onSort = ({ sortBy, sortDirection }) => ({
  type: SET_SORT_BY,
  payload: { sortBy, sortDirection },
});
