import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { sort as sortItems } from '@sb-itops/sort';
import { useSelector } from 'react-redux';
import { getByIds as getTransactionsByIds } from '@sb-billing/redux/transactions';
import { BulkTransactionsTable } from './BulkTransactionsTable';

const hooks = ({ transactionIds, onClickLink }) => ({
  useData: () => {
    const unsortedTransactions = useSelector(() => getTransactionsByIds(transactionIds));
    const transactions = sortItems(unsortedTransactions, ['effectiveDate'], ['ASC']);
    const totalAmount = transactions.reduce((acc, { amount }) => acc + amount, 0);

    return {
      totalAmount,
      transactions,
      onClickLink,
    };
  },
});

export const BulkTransactionsTableContainer = withReduxProvider(composeHooks(hooks)(BulkTransactionsTable));

BulkTransactionsTableContainer.displayName = 'BulkTransactionsTableContainer';

BulkTransactionsTableContainer.propTypes = {
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickLink: PropTypes.func,
};

BulkTransactionsTableContainer.defaultProps = {
  onClickLink: () => {},
};
