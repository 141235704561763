import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/recent-matters';

angular.module('@sb-billing/services').service('sbRecentMatterService', function(sbGenericCacheService, sbLoggerService, sbEndpointType) {
  'use strict';

  sbGenericCacheService.setupCache({
    name: 'sbRecentMatterService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/recent-matters' },
      poll: 60,
      subscriptions: 'notifier.BillingMattersNotifications.RecentMattersUpdated'
    },
    updateRedux,
    clearRedux,
  });
});
