import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCreatedPreSignedUrl, createPreSignedUrl } from '@sb-itops/redux/pre-sign-url';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { getS3BucketById } from '@sb-billing/redux/end-of-month-reports';
import { PreviewPdf } from '@sb-itops/react/preview-pdf';
import * as messageDisplay from '@sb-itops/message-display';

const postEndpoint = `/billing/pre-sign-url/:accountId/sign`;

const mapStateToProps = (state, { id }) => {
  const s3Bucket = getS3BucketById(id);
  return {
    url: getCreatedPreSignedUrl({ s3Bucket }),
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onLoad: () => {
    const onLoadP = async () => {
      try {
        const s3Bucket = getS3BucketById(id);
        await dispatch(createPreSignedUrl({ s3Bucket, expiresSeconds: 86400 }, postEndpoint));
      } catch (ex) {
        messageDisplay.error('Failed to preview end of month report');
      }
    };
    onLoadP();
  },
  onDownloadFail: () => messageDisplay.error('Failed to preview end of month report'),
});

const EndOfMonthPreviewContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(PreviewPdf)));

EndOfMonthPreviewContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EndOfMonthPreviewContainer;
