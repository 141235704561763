angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.configure-matter-numbering', {
      template: '<sbb-matter-numbering></sbb-matter-numbering>',
      url: '/configure-matter-numbering',
      data: {
        navHighlights: ['configure-matter-numbering']
      }
    });
});