angular.module('sb.billing.webapp').component('sbHookStateGo',
  {
    bindings: { to : '<?', params : '<?', derivedParams : '<?', onPreNavigate: '&?' },
    require: { group: '^sbComposeGroup' },
    controller: function ($state, sbLoggerService) {
      'use strict';
      var ctrl = this,
        log = sbLoggerService.getLogger('sbHookStateGo');


      ctrl.$onInit = function() {
        ctrl.group.setActionPostHook(stateGo);
      };

      function stateGo(data) {
        if (ctrl.onPreNavigate) {
          ctrl.onPreNavigate();
        }
        
        var params = {};

        if (ctrl.params) {
          params = _.cloneDeep(ctrl.params);
        }

        _.each(ctrl.derivedParams, function (v, k) {
          params[k] = _.get(data, v);
        });

        if (ctrl.to) {
          $state.go(ctrl.to, params);
        } else {
          log.warn('nowhere to go');
        }
      }
    }
  }
);
