'use strict';

/**
 * @typedef { import('../types.js').EzyCollectChargeFormData } EzyCollectChargeFormData
 */

const { capitalize } = require('@sb-itops/nodash');
const { Money, MONETARY_UNIT } = require('@sb-itops/money');
const { providerNames } = require('../../../entities/constants');

const centsToDollars = (cents) => {
  const money = new Money(cents, MONETARY_UNIT.cent);
  return money.dollars();
};

/**
 * getChargeDescription
 *
 * @param {Object} params
 * @param {number} params.amountInCents The amount for the charge.
 * @param {string} params.firmName The name of the firm to which the charge for the description belongs.
 * @param {string} [params.staffName] Staff name to be used in the staff details portion of description.
 * @param {EzyCollectChargeFormData} params.providerSpecificFields The data received from the ezy collect charge form

 * @returns {string} A human readable description of the charge.
 */
const getChargeDescription = ({ amountInCents, firmName, staffName, providerSpecificFields }) => {
  const dollarAmount = centsToDollars(amountInCents);

  let firmNamePrefix = '';
  if (firmName) {
    // Just in case some firm name has been entered with weird casing, we normalise so the description doesn't look bizzare
    // e.g. luke's Cool firm: Amex credit card charge from Luke Marsden.....
    firmNamePrefix = firmName
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    firmNamePrefix = `${firmNamePrefix}: `;
  }

  const staffDetails = staffName ? ` (Processed by ${staffName})` : '';

  const { paymentToken } = providerSpecificFields;

  const cardType = paymentToken && paymentToken.card && paymentToken.card.brand;
  const cardholderName = paymentToken && paymentToken.card && paymentToken.card.name;
  const cardNumberTruncated = paymentToken && paymentToken.card && paymentToken.card.last4;

  const creditCardLabel = cardType ? `${capitalize(cardType)} credit card` : 'Credit card'; // Amex, visa, mastercard etc.
  return `${firmNamePrefix}${creditCardLabel} charge from ${cardholderName} (***${cardNumberTruncated}) of $${dollarAmount}${staffDetails} via ${providerNames.EZY_COLLECT}`;
};

module.exports = {
  getChargeDescription,
};
