import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import uuid from '@sb-itops/uuid';
// eslint-disable-next-line import/no-cycle
import { getById as getCustomerBillingConfigurationById } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbCustomerBillingConfigurationService',
  keyPath: 'entityId',
});

export const saveCustomerBillingConfiguration = (customerBillingConfiguration) => {
  const saveCustomerBillingConfigurationThunk = async () => {
    let newCustomerBillingConfiguration = { ...customerBillingConfiguration, versionId: uuid() };

    const existingCustomerBillingConfiguration = getCustomerBillingConfigurationById(
      customerBillingConfiguration.entityId,
    );
    if (existingCustomerBillingConfiguration) {
      newCustomerBillingConfiguration = { ...existingCustomerBillingConfiguration, ...newCustomerBillingConfiguration };
    }

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [newCustomerBillingConfiguration] });

    // Apply the save in the backend.
    try {
      const path = `/billing/customer-billing-configuration/:accountId/${newCustomerBillingConfiguration.entityId}`;
      const fetchOptions = { body: JSON.stringify(newCustomerBillingConfiguration) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [newCustomerBillingConfiguration] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveCustomerBillingConfigurationThunk);
};
