import { dot as nestedObjectToFlattened } from 'dot-object';
import * as types from './types';

/* State Shape
{
  isInitialised      : boolean                        : True if the form is initialised, false otherwise. Default false.
  initialFieldValues : Object<fieldName, fieldValue>  : Stores the initial values of the form, i.e. the values used when initially displaying the form.
  fieldValues        : Object<fieldName, fieldValue>  : Stores the current values of the form. Currently values typically differ from intial values after user input.
  isDirty            : boolean                        : True if the form has been updated since last submit, false otherwise. Default false.
  dirtyFields        : Object<fieldName, boolean>     : A lookup of isDirty values at the field level rather than whole form level.
  isValid            : boolean                        : True if the form's last validation was successful, false otherwise. Default false.
  validationErrors   : Object<fieldName, string>      : A lookup of validation errors at a field level.
  isValidating       : boolean                        : True if the form is currently undergoing a validation, false otherwise. Default false.
  validationExpired  : boolean                        : True if the form has been updated since the last submit, false otherwise. Default false.
  isSubmitting       : boolean                        : True if the form is currently being submitted, false otherwise. Default false.
  submitFailed       : boolean                        : If submitFormP is called, and fails, submitFailed will be set to true. A subsequent successful submit will set it back to false. Default false.
}
*/

// Defines the initial values set in state.
const initialState = {
  isInitialised: false,
  initialFieldValues: {},
  fieldValues: {},
  isDirty: false,
  dirtyFields: {},
  isValid: false,
  validationErrors: {},
  isValidating: false,
  validationExpired: false,
  isSubmitting: false,
  submitFailed: false,
  submitError: undefined,
};

// Action -> Handler function lookup.
const reducerLookup = {
  [types.INITIALISE_FORM]: initialiseForm,
  [types.START_VALIDATE_FORM]: startValidateForm,
  [types.END_VALIDATE_FORM]: endValidateForm,
  [types.UPDATE_FIELD_VALUES]: updateFieldValues,
  [types.SET_FIELD_VALUE]: setFieldValue,
  [types.START_SUBMIT]: startSubmit,
  [types.END_SUBMIT]: endSubmit,
  [types.CLEAR_FORM]: clearForm,
  [types.RESET_FORM]: resetForm,
  [types.CLEAR_SUBMIT_FAILED]: clearSubmitFailed,
};

// The actual reducer function which simply forwards an action on to a handler function.
export const reducer = (state = initialState, action = {}) => {
  const reducerFn = reducerLookup[action.type];
  return reducerFn ? reducerFn(state, action) : state;
};

/**
 * Handler for the INITIALSE_FORM action.
 *
 * @param  {Object} state   The current redux state.
 * @param  {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function initialiseForm(state, action) {
  // DISCUSS: The duplicate initialisation check works fine with modal forms
  // where the form redux state can be explicitly cleared upon closing the modal.
  // But when working with inline forms, where the form maybe shown or hidden
  // depending on the the view state, it leads to some unnatural usage.
  // Commenting out for now until discussion is finalised.
  // if (state.isInitialised) {
  //   throw new Error('Duplicate initialisation of form detected');
  // }

  // This to guard against user of form2 from initialising fields to an
  // empty object which is not allowed. Removing this can lead to
  // an 'object is not extensible' error when trying to update object
  Object.values(action.payload.fieldValues).forEach((fieldValue) => {
    // Check if object is empty without lodash
    if (fieldValue && fieldValue.constructor === Object && Object.keys(fieldValue).length === 0) {
      throw new Error('Cannot initialise form to empty object, set it to undefined');
    }
  });

  return {
    ...initialState,
    isInitialised: true,
    initialFieldValues: nestedObjectToFlattened(action.payload.fieldValues),
    fieldValues: nestedObjectToFlattened(action.payload.fieldValues),
  };
}

/**
 * Handler for the START_VALIDATE_FORM action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function startValidateForm(state) {
  return {
    ...state,
    isValidating: true,
    validationExpired: false,
  };
}

/**
 * Handler for the END_VALIDATE_FORM action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function endValidateForm(state, action) {
  return {
    ...state,
    validationErrors: action.payload.validationErrors || {},
    isValid: Object.keys(action.payload.validationErrors || {}).length === 0,
    isValidating: false,
  };
}

/**
 * Handler for the UPDATE_FIELD_VALUES action.
 *
 * The related action creator flattens the field values object representing the form.
 * That means this handler only needs to worry about an object with a single level of nesting.
 * Nested properties are flattened using dot-and-bracket notation.
 *
 * @see actions.updateFormValues()
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function updateFieldValues(state, action) {
  const flattenedFieldValues = nestedObjectToFlattened(action.payload.fieldValues);
  const dirtyFields = Object.keys(flattenedFieldValues).reduce(
    (acc, field) => {
      acc[field] = true;
      return acc;
    },
    { ...state.dirtyFields },
  );

  return {
    ...state,
    dirtyFields,
    fieldValues: {
      ...state.fieldValues,
      ...flattenedFieldValues,
    },
    isDirty: true,
    validationExpired: true,
  };
}

/**
 * Handler for the SET_FIELD_VALUES action.
 *
 * UPDATE_FIELD_VALUES relies on spreading the keys of a flattened object to update the store
 * That means it is not possible to reduce or reset a object or array key by omission
 * This action completely resets the field to the supplied value
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function setFieldValue(state, action) {
  const flattenedFieldValues = nestedObjectToFlattened(action.payload.value);
  const dirtyFields = Object.keys(flattenedFieldValues).reduce(
    (acc, field) => {
      acc[field] = true;
      return acc;
    },
    { ...state.dirtyFields },
  );

  const fieldValues = Object.keys(state.fieldValues).reduce((acc, field) => {
    if (acc[field] || field.startsWith(`${action.payload.field}.`) || field === action.payload.field) {
      return acc;
    }
    acc[field] = state.fieldValues[field];
    return acc;
  }, flattenedFieldValues);

  return {
    ...state,
    dirtyFields,
    fieldValues,
    isDirty: true,
    validationExpired: true,
  };
}

/**
 * Handler for the START_SUBMIT action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function startSubmit(state) {
  return {
    ...state,
    isSubmitting: true,
  };
}

/**
 * Handler for the END_SUBMIT action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function endSubmit(state, action) {
  if (action.payload.failed) {
    return {
      ...state,
      isSubmitting: false,
      submitFailed: true,
    };
  }

  return {
    ...state,
    isSubmitting: false,
    isDirty: false,
    dirtyFields: {},
    submitFailed: false,
  };
}

/**
 * Handler for the CLEAR_FORM action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function clearForm() {
  return {
    ...initialState,
  };
}

/**
 * Handler for the RESET_FORM action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function resetForm(state, action) {
  const initialFieldValues = action.payload.fieldValues || state.initialFieldValues;

  return {
    ...initialState,
    isInitialised: true,
    initialFieldValues,
    fieldValues: initialFieldValues,
  };
}

/**
 * Handler for the CLEAR_SUBMIT_FAILED action.
 *
 * @param {Object} state   The current redux state.
 * @param {Object} action  The redux action which triggered the invocation of this function.
 *
 * @return {Object} The new redux state.
 */
function clearSubmitFailed(state) {
  return {
    ...state,
    submitFailed: false,
  };
}
