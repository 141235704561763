import { components as ReactSelectComponents } from 'react-select';
import React from 'react';
import classNames from 'classnames';

const Option = (props) => <ReactSelectComponents.Option {...props} />;

export const virtualizeRowRenderer =
  ({ children, listItemClassName, onItemFocus }) =>
  ({ key, index, style, isVisible }) => {
    const currentProps = children[index].props;

    if (currentProps.isFocused) {
      onItemFocus({ data: currentProps.data, index, isVisible });
    }

    return (
      <div
        className={classNames(listItemClassName, 'select-option')}
        key={key}
        style={style}
        title={currentProps.label}
      >
        <Option {...currentProps} />
      </div>
    );
  };
