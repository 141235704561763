import * as React from 'react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('useExportAndPrintPdf');

export function useExportAndPrintPdf({ printDialogIframeId, exportTypeLabel, exportUrl }) {
  const [isGeneratingPdf, setIsGeneratingPdf] = React.useState(false);

  async function exportAndPrintPdf({ filters }) {
    try {
      setIsGeneratingPdf(true);
      const response = await fetchPostP({
        path: exportUrl,
        fetchOptions: { body: JSON.stringify({ fileFormat: 'pdf', ...filters }) },
      });
      onPrintDialogOpen({ printDialogIframeId, exportFile: response.body.exportFile });
    } catch (error) {
      log.error(`Failed to print ${exportTypeLabel} PDF due to error: `, error);
      displayErrorToUser(`Failed to print ${exportTypeLabel} PDF. Please try again later.`);
    } finally {
      setIsGeneratingPdf(false);
    }
  }

  return {
    isGeneratingPdf,
    exportAndPrintPdf,
  };
}

function onPrintDialogOpen({ printDialogIframeId, exportFile }) {
  const iframe = document.frames ? document.frames[printDialogIframeId] : document.getElementById(printDialogIframeId);
  if (iframe) {
    const iframeDocument = iframe.contentWindow.document;
    iframeDocument.open('text/html', 'replace');
    iframeDocument.write(exportFile);
    iframeDocument.close();
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 0);
  }
}
