/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spinner, Checkbox, useTranslation, Icon } from '@sb-itops/react';
import { MatterDisplay } from '@sb-matter-management/react';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { AddTaskModalContainer, AddEventModalContainer, AddPhoneMessageModalContainer } from 'web/containers';
import { getStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import Calendar from 'react-calendar';
import moment from 'moment';
import { humanise } from 'web/business-logic/calendar';
import classNames from 'classnames';
import recurringIcon from 'web/assets/icon-recurring.svg';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './DailyDigestWidget.module.scss';

const el = document.createElement('div');
const Portal = ({ children }) => {
  useEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  return createPortal(children, el);
};

const calendarHeight = 360;
const calendarWidth = 320;
const getCalendarTop = (component) => {
  const rect = component.current.getBoundingClientRect();
  const componentBase = rect.top + rect.height;
  const viewPortHeight = window.innerHeight;
  const calendarTop = componentBase + calendarHeight > viewPortHeight ? viewPortHeight - calendarHeight : componentBase;

  return calendarTop;
};
const getCalendarLeft = (component) => {
  const rect = component.current.getBoundingClientRect();
  // Leave a 10px gap from the edge of the page. Center calendar on the target ref
  const componentBase = Math.max(rect.left + rect.width / 2 - calendarWidth / 2, 10);
  const viewPortWidth = window.innerWidth;
  const calendarLeft =
    componentBase + calendarWidth > viewPortWidth ? viewPortWidth - calendarWidth - 10 : componentBase;

  return calendarLeft;
};

// see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
const useOutsideAlerter = (refs, setShowCalendar) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!refs.some((ref) => ref.current && ref.current.contains(event.target))) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [refs, setShowCalendar]);
};

export const DailyDigestWidget = ({
  isEditing,
  tasks,
  onClickLink,
  tasksLoading,
  onChange,
  onRemove,
  settings,
  onCompleteTask,
  showTaskModal,
  setShowTaskModal,
  showPhoneMessageModal,
  setShowPhoneMessageModal,
  events,
  eventsLoading,
  showEventModal,
  setShowEventModal,
  digestDay,
  showCalendar,
  setShowCalendar,
  setTempSettings,
}) => {
  const { t } = useTranslation();
  const calendarTextRef = useRef(null);
  const calendarRef = useRef(null);
  useOutsideAlerter([calendarTextRef, calendarRef], setShowCalendar);

  const getOverdueDisplay = (d) => {
    const diff = moment().startOf('day').diff(moment(d), 'days');

    if (diff === 0) {
      return <div className={WidgetStyles.bold}>Today</div>;
    }
    if (diff === 1) {
      return <div className={Styles.overdue}>Yesterday</div>;
    }
    if (diff === -1) {
      return <div>Tomorrow</div>;
    }
    if (diff > 1) {
      return <div className={Styles.overdue}>{moment().startOf('day').diff(moment(d), 'days')} days overdue</div>;
    }
    if (diff < -1) {
      return <div>{t('date', { ts: d })}</div>;
    }
    return null;
  };

  return (
    <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
      <WidgetTitle isEditing={isEditing} onChange={onChange} onRemove={onRemove} settings={settings} />
      <div className={Styles.digestContainer}>
        <div className={Styles.digestControls}>
          <div ref={calendarTextRef} className={Styles.calendarText} onClick={() => setShowCalendar(true)}>
            {t('date', { date: digestDay, format: 'dddd DD MMMM, YYYY' })}
            <Portal>
              {showCalendar && (
                <div
                  ref={calendarRef}
                  className={Styles.calendar}
                  style={{ top: `${`${getCalendarTop(calendarTextRef)}px`}`, left: getCalendarLeft(calendarTextRef) }}
                >
                  <Calendar
                    onChange={(value) => {
                      setTempSettings(moment(value).toISOString());
                      setShowCalendar(false);
                    }}
                    value={digestDay.toDate()}
                    // calendar type configures the style of the calendar, e.g. start of the working week
                    // US is the correct format for both AU and US
                    // see: https://github.com/wojtekmaj/react-calendar
                    calendarType="US"
                  />
                  <button
                    type="button"
                    className={Styles.todayBtn}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setTempSettings(moment().toISOString());
                      setShowCalendar(false);
                    }}
                  >
                    Today
                  </button>
                </div>
              )}
            </Portal>
          </div>{' '}
          <div className={Styles.calendarNav}>
            <Icon
              className={Styles.iconButton}
              type="chevron-left"
              onClick={() => setTempSettings(moment(digestDay).subtract(1, 'day').toISOString())}
              isFA
            />
            <span className={Styles.labelBlock} onClick={() => setTempSettings(moment().toISOString())}>
              {t('date', { date: digestDay.toDate(), format: 'Do MMMM YYYY' })}
            </span>
            <Icon
              className={Styles.iconButton}
              type="chevron-right"
              onClick={() => setTempSettings(moment(digestDay).add(1, 'day').toISOString())}
              isFA
            />
          </div>
        </div>
        <div className={Styles.digestBody}>
          <div className={Styles.digestWidget}>
            <div className={Styles.subHeading}>
              <div className={Styles.title}>EVENTS</div>
            </div>
            <div className={Styles.separator} />
            {eventsLoading ? (
              <div className={WidgetStyles.loading}>
                <Spinner small />
              </div>
            ) : (
              <div className={WidgetStyles.content}>
                {events.length === 0 && 'No events today'}
                {events.map((event) => (
                  <div
                    key={event.id || event.originalId + event.endTime}
                    className={Styles.event}
                    onClick={() => {
                      setShowEventModal(event);
                    }}
                  >
                    <div className={Styles.time}>
                      <div>{moment(event.startTime).format('hh:mm A')}</div>
                      <div className={Styles.duration}>
                        {humanise(moment.duration(moment(event.startTime).diff(moment(event.endTime))))}
                      </div>
                    </div>
                    <div className={Styles.description}>
                      <div className={Styles.subject}>{event.subject}</div>
                      <MatterDisplay onClickLink={onClickLink} asLink matterId={event.matterId} />
                      <div className={Styles.staff}>{event.staff.join(', ')}</div>
                    </div>
                    {event.originalId && (
                      <div className={Styles.recurring}>
                        <img alt="This event is recurring" src={recurringIcon} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className={WidgetStyles.separator} />
            <a className={WidgetStyles.footerAction} onClick={() => setShowEventModal({})}>
              ADD EVENT
            </a>
          </div>
          <div className={Styles.digestWidget}>
            <div className={Styles.subHeading}>
              <div className={Styles.title}>TASKS</div>
            </div>
            <div className={Styles.separator} />
            {tasksLoading ? (
              <div className={WidgetStyles.loading}>
                <Spinner small />
              </div>
            ) : (
              <div className={WidgetStyles.content}>
                {tasks.length === 0 && 'No overdue tasks'}
                {tasks
                  .filter((task) => !(task.categories && task.categories.includes('Phone Message')))
                  .map((task) => (
                    <div
                      key={task.id}
                      className={Styles.task}
                      onClick={() => {
                        setShowTaskModal(task);
                      }}
                    >
                      <Checkbox checked={false} onChange={() => onCompleteTask(task)} className={Styles.checkbox} />
                      <div className={WidgetStyles.textBox}>
                        <div className={classNames(WidgetStyles.overflowText, Styles.subject)}>{task.subject}</div>
                        <MatterDisplay onClickLink={onClickLink} asLink matterId={task.matterId} />
                      </div>
                      <div
                        className={classNames(
                          WidgetStyles.overflowText,
                          WidgetStyles.alignRight,
                          WidgetStyles.noShrink,
                        )}
                      >
                        {getOverdueDisplay(task.dueDate)}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className={WidgetStyles.separator} />
            <a className={WidgetStyles.footerAction} onClick={() => setShowTaskModal({})}>
              ADD TASK
            </a>
          </div>
          <div className={Styles.digestWidget}>
            <div className={Styles.subHeading}>
              <div className={Styles.title}>PHONE MESSAGES</div>
            </div>
            <div className={Styles.separator} />
            {tasksLoading ? (
              <div className={WidgetStyles.loading}>
                <Spinner small />
              </div>
            ) : (
              <div className={WidgetStyles.content}>
                {tasks.length === 0 && 'No overdue tasks'}
                {tasks
                  .filter((task) => task.categories && task.categories.includes('Phone Message'))
                  .map((task) => (
                    <div
                      key={task.id}
                      className={Styles.task}
                      onClick={() => {
                        setShowTaskModal(task);
                      }}
                    >
                      <Checkbox checked={false} onChange={() => onCompleteTask(task)} className={Styles.checkbox} />
                      <div className={WidgetStyles.textBox}>
                        <div className={classNames(WidgetStyles.overflowText, Styles.subject)}>{task.subject}</div>
                        <MatterDisplay onClickLink={onClickLink} asLink matterId={task.matterId} />
                        {task.note?.match(/^Urgent.*$/m) ? <div>{task.note?.match(/^Urgent.*$/m)[0]}</div> : null}
                      </div>
                      <div
                        className={classNames(
                          WidgetStyles.overflowText,
                          WidgetStyles.alignRight,
                          WidgetStyles.noShrink,
                        )}
                      >
                        {getOverdueDisplay(task.dueDate)}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className={WidgetStyles.separator} />
            <a className={WidgetStyles.footerAction} onClick={() => setShowPhoneMessageModal({})}>
              ADD PHONE MESSAGE
            </a>
          </div>
        </div>
      </div>
      {showTaskModal && (
        <AddTaskModalContainer
          scope="widgets.task"
          task={showTaskModal}
          completer={showTaskModal.completerId && getStaffByPersonId(showTaskModal.completerId)}
          onClose={() => setShowTaskModal(false)}
        />
      )}
      {showPhoneMessageModal && (
        <AddPhoneMessageModalContainer
          scope="widgets.task"
          task={showPhoneMessageModal}
          completer={showPhoneMessageModal.completerId && getStaffByPersonId(showPhoneMessageModal.completerId)}
          onClose={() => setShowPhoneMessageModal(false)}
        />
      )}
      {showEventModal && (
        <AddEventModalContainer appointmentData={showEventModal} onClose={() => setShowEventModal(false)} />
      )}
    </div>
  );
};
