'use strict';

function interpolateText(
  string,
  {
    accountType,
    debtorName,
    replenishUpToCents,
    staffName,
    amountRequestedCents,
    matterLineSummary,
    outstandingBalance,
    phoneNumber,
    firmName,
    showPaymentButton,
    trustBalance,
    minimumThreshold,
  },
  t,
) {
  const interpolatedString = [
    replaceAccountType(t, handleNullishValue(accountType)),
    replaceDebtor(handleNullishValue(debtorName)),
    replaceReplenishAmount(handleNullishValue(getCurrencyString(replenishUpToCents, t))),
    replaceUsername(handleNullishValue(staffName)),
    replaceAmountRequested(handleNullishValue(getCurrencyString(amountRequestedCents, t))),
    replaceMatter(handleNullishValue(matterLineSummary)),
    replaceMatterAmountDue(handleNullishValue(getCurrencyString(outstandingBalance, t))),
    replacePhoneNumber(handleNullishValue(phoneNumber)),
    replaceFirmName(handleNullishValue(firmName)),
    replacePayNowButton(showPaymentButton),
    replacePaymentButton(showPaymentButton),
    replaceLawPayPaymentButton(showPaymentButton),
    replaceTrustBalance(t, handleNullishValue(getCurrencyString(trustBalance, t))),
    replaceMinimumThreshold(handleNullishValue(getCurrencyString(minimumThreshold, t))),
  ].reduce((str, fn) => fn(str), string);

  return interpolatedString;
}

// NOTE: currency string replacement functions need to use a function as the replacement parameter
// as string.replace treats the $ character as group selection if you are only passing a string
// (as the second parameter to string.replace)

function replaceAccountType(t, accountType = '') {
  let formattedAccountType = accountType;
  if (accountType) {
    const lowerCaseAccountType = accountType.toLowerCase();
    const localisedAccountType = t(lowerCaseAccountType);
    formattedAccountType = `${localisedAccountType[0].toUpperCase()}${localisedAccountType.slice(1)}`;
  }

  return (string) => string.replace(/\[ACCOUNT_TYPE\]/g, () => formattedAccountType);
}

function replaceDebtor(debtorName = '') {
  return (string) => string.replace(/\[CLIENT_NAME\]|\[DEBTOR_NAME\]/g, debtorName);
}

function replaceReplenishAmount(replenishUpToCents = '') {
  // REPLENISH_AMOUNT a deprecated placeholder, but we cannot change as users may have used it in their templates
  return (string) => string.replace(/\[REPLENISH_AMOUNT\]|\[REPLENISH_UP_TO\]/g, () => replenishUpToCents);
}

function replaceAmountRequested(amountRequestedCents = '') {
  return (string) => string.replace(/\[AMOUNT_REQUESTED\]/g, () => amountRequestedCents);
}

function replaceMatter(matterLineSummary = '') {
  return (string) => string.replace(/\[MATTER_NAME\]/g, matterLineSummary);
}

function replaceUsername(staffName = '') {
  return (string) => string.replace(/\[USER_NAME\]/g, staffName);
}

function replaceMatterAmountDue(outstandingBalance = '') {
  // OUTSTANDING_BALANCE is a deprecated placeholder, keeping it here for backwards compatibility
  return (string) => string.replace(/\[OUTSTANDING_BALANCE\]|\[MATTER_AMOUNT_DUE\]/g, outstandingBalance);
}

function replacePhoneNumber(phoneNumber = '') {
  return (string) => string.replace(/\[PHONE_NUMBER\]/g, phoneNumber);
}

function replaceFirmName(firmName = '') {
  return (string) => string.replace(/\[FIRM_NAME\]/g, firmName);
}

// the implementation here is unfortunately specific to the component we use to allow the user
// to write formatted text (Quill).
// This code will remove the button placeholder in addition to the blank line below it (if it exists)
function replacePayNowButton(showButton) {
  return (string) => (showButton ? string : string.replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, ''));
}

function replacePaymentButton(showButton) {
  // PAYMENT_BUTTON a deprecated placeholder, it is replaced by PAY_NOW_BUTTON, but we cannot remove as users may have used it in their templates
  return (string) => (showButton ? string : string.replace(/(:?<p>)?\[PAYMENT_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '')); //
}

function replaceLawPayPaymentButton(showButton) {
  // LAWPAY_PAYMENT_BUTTON a deprecated placeholder, but we cannot remove as users may have used it in their templates
  return (string) =>
    showButton ? string : string.replace(/(:?<p>)?\[LAWPAY_PAYMENT_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '');
}

function replaceTrustBalance(t, trustBalance = '') {
  return (string) => string.replace(new RegExp(`\\[${t('macros.trustBalance')}\\]`, 'g'), () => trustBalance);
}

function replaceMinimumThreshold(minimumThreshold = '') {
  return (string) => string.replace(/\[MINIMUM_THRESHOLD\]/g, () => minimumThreshold);
}

function handleNullishValue(value) {
  // eslint-disable-next-line eqeqeq
  return value == undefined ? undefined : value;
}

// we need to remove the dollar sign from the currency string for backwards compatability reasons,
// where the template had separated the dollar sign from the currency value placeholder.
// Ex: $[AMOUNT_REPLENISHED] rather than [AMOUNT_REPLENISHED]
function getCurrencyString(cents, t) {
  if (cents !== 0 && !cents) {
    return undefined;
  }
  return t('cents', { val: cents, hideCurrencySymbol: true });
}

module.exports = {
  interpolateText,
};
