import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DropdownMenuButton, Modal } from '@sb-itops/react';
import { Button, buttonTypes } from '@sb-itops/react/button';

import Styles from './BulkActionsButton.module.scss';

export const BulkActionsButton = ({
  label,
  disabled,
  showConfirmationModal,
  showConfirmSpinner,
  confirmationTitle,
  confirmationMessage,
  onConfirm,
  onCancel,
  onClose,
  menuOptions,
}) => (
  <>
    <DropdownMenuButton label={label} menuOptions={menuOptions} disabled={disabled} />
    {showConfirmationModal && (
      <Modal
        className={Styles.confirmationModal}
        isVisible={showConfirmationModal}
        title={confirmationTitle}
        body={<div>{confirmationMessage}</div>}
        footer={
          <div className={Styles.buttonBar}>
            <Button onClick={onConfirm} disabled={showConfirmSpinner}>
              Continue {showConfirmSpinner && <i className={classnames('fa fa-spinner fa-pulse fa-fw', Styles.icon)} />}
            </Button>
            <Button type={buttonTypes.secondary} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    )}
  </>
);

BulkActionsButton.displayName = 'BulkActionsButton';

BulkActionsButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  showConfirmationModal: PropTypes.bool.isRequired,
  showConfirmSpinner: PropTypes.bool.isRequired,
  confirmationTitle: PropTypes.string,
  confirmationMessage: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

BulkActionsButton.defaultProps = {
  label: 'Bulk Actions',
  confirmationTitle: undefined,
};
