import React from 'react';
import PropTypes from 'prop-types';
import { LinkableText, Translation } from '@sb-itops/react';
import { externalSentViaTypes, getSentViaText, sendStatuses } from '@sb-billing/business-logic/correspondence-history';
import { getLogger } from '@sb-itops/fe-logger';
import { Spinner } from '@sb-itops/react/spinner';
import { featureActive } from '@sb-itops/feature';
import Styles from './ReminderCorrespondenceDetails.module.scss';

const log = getLogger('ReminderCorrespondenceDetails');

export const ReminderCorrespondenceDetails = (props) => {
  const { latestFullCorrespondenceHistory, loading } = props;

  // If there is no latestFullCorrespondenceHistory, it means this invoice has not yet been sent
  // If the latestFullCorrespondenceHistory exists but does not have status property, it means this invoice is sent before BB-11448 feature available
  if (!loading && !latestFullCorrespondenceHistory) {
    return null;
  }

  return (
    <div className={Styles.reminderCorrespondenceDetails}>
      {loading ? (
        <Spinner small />
      ) : (
        (() => {
          if (latestFullCorrespondenceHistory.status === sendStatuses.NOT_SENT) {
            return null;
          }
          if (
            latestFullCorrespondenceHistory.status === sendStatuses.IN_PROGRESS || // We don't currently sent reminders in progress, but if we do in the future, it should enter this case
            latestFullCorrespondenceHistory.status === sendStatuses.SUCCESS
          ) {
            return renderNonFailure(latestFullCorrespondenceHistory, props);
          }
          if (latestFullCorrespondenceHistory.status === sendStatuses.ERROR) {
            return renderFailure(latestFullCorrespondenceHistory, props);
          }
          log.warn(
            `Unexpected correspondence latestFullCorrespondenceHistory.status ${latestFullCorrespondenceHistory.status}`,
          );
          return null;
        })()
      )}
    </div>
  );
};

const renderNonFailure = (correspondenceHistory) => {
  const { id, contactDisplayName, userDisplayName, to, cc, bcc, from, lastUpdated, status, sentVia, sentTimestamp } =
    correspondenceHistory;

  const isManuallySent = Object.values(externalSentViaTypes).includes(sentVia);

  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });

  return (
    <div className={Styles.contactStatus} key={id}>
      <div>
        <span className={Styles.detailLabel} />
        <span className={Styles.contactName}>{isManuallySent ? 'REMINDER SENT MANUALLY' : contactDisplayName}</span>
      </div>
      <div>
        <span>
          <span className={Styles.detailLabel}>Status: </span>
          {status === 0 ? 'In Progress' : `Sent${sentViaText}`}
        </span>
      </div>
      {!isManuallySent && (
        <>
          {!!to && (
            <div>
              <span className={Styles.detailLabel}>To: </span>
              <span className={Styles.detailValue}>{to}</span>
            </div>
          )}
          {!!cc && (
            <div>
              <span className={Styles.detailLabel}>Cc: </span>
              <span className={Styles.detailValue}>{cc}</span>
            </div>
          )}
          {!!bcc && (
            <div>
              <span className={Styles.detailLabel}>Bcc: </span>
              <span className={Styles.detailValue}>{bcc}</span>
            </div>
          )}
          {!!from && (
            <div>
              <span className={Styles.detailLabel}>From: </span>
              <span className={Styles.detailValue}>{from}</span>
            </div>
          )}
        </>
      )}
      {!!userDisplayName && (
        <div>
          <span className={Styles.detailLabel}>Sent By: </span>
          <span className={Styles.detailValue}>{userDisplayName}</span>
        </div>
      )}
      <div>
        <span className={Styles.detailLabel}>On: </span>
        <Translation>{(t) => t('date', { ts: sentTimestamp || lastUpdated })}</Translation>
      </div>
    </div>
  );
};

const renderFailure = (correspondenceHistory, { allowResend, onResendClicked }) => {
  const { id, errorMessage, from, contactDisplayName, userDisplayName, lastUpdated, sentVia } = correspondenceHistory;
  const sentViaText = getSentViaText({
    sentVia,
    sendViaCommunicateEnabled: featureActive('BB-9097'),
    clientCommunicationEnabled: featureActive('BB-11448'),
  });

  return (
    <div className={Styles.contactStatus} key={id}>
      <div>
        <span className={Styles.detailLabel} />
        <span className={Styles.contactName}>{contactDisplayName}</span>
      </div>
      <div>
        <span>
          <span className={Styles.detailLabel}>Status: </span>Failed Send{sentViaText}
        </span>
      </div>
      <div>
        <span className={Styles.detailLabel}>Reason: </span>
        <span className={Styles.detailValue}>{errorMessage}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>From: </span>
        <span className={Styles.detailValue}>{from}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>Sent By: </span>
        <span className={Styles.detailValue}>{userDisplayName}</span>
      </div>
      <div>
        <span className={Styles.detailLabel}>On: </span>
        <Translation>{(t) => t('date', { ts: lastUpdated })}</Translation>
      </div>
      {allowResend && (
        <div className={Styles.resendLink}>
          <span>
            <LinkableText text="Resend" onClickLink={() => onResendClicked({ sentVia })} asLink />
          </span>
        </div>
      )}
    </div>
  );
};

ReminderCorrespondenceDetails.displayName = 'ReminderCorrespondenceDetails';

ReminderCorrespondenceDetails.propTypes = {
  latestFullCorrespondenceHistory: PropTypes.object,
  allowResend: PropTypes.bool,
  onResendClicked: PropTypes.func,
  onSendViaMenuClicked: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};

ReminderCorrespondenceDetails.defaultProps = {
  latestFullCorrespondenceHistory: undefined,
  allowResend: false,
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};
