import React from 'react';
import { CurrencyDisplay, forms2PropTypes } from '@sb-itops/react';
import classnames from 'classnames';

export const AvailableBalances = ({ balanceAvailable, balanceAvailableAfter }) => (
  <>
    <div className={classnames('col-sm-3', 'form-group')}>
      <label>Available</label>
      <CurrencyDisplay amount={balanceAvailable?.value} />
    </div>
    <div className={classnames('col-sm-3', 'form-group')}>
      <label>Available After</label>
      <CurrencyDisplay amount={balanceAvailableAfter?.value} />
    </div>
  </>
);

AvailableBalances.displayName = 'AvailableBalances';
const { Forms2Field } = forms2PropTypes;
AvailableBalances.propTypes = {
  balanceAvailable: Forms2Field,
  balanceAvailableAfter: Forms2Field,
};

AvailableBalances.defaultProps = {
  balanceAvailable: undefined,
  balanceAvailableAfter: undefined,
};
