import composeHooks from '@sb-itops/react-hooks-compose';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useSelector, useDispatch } from 'react-redux';
import * as taskFiltersFeature from 'web/redux/features/task-filters';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FirmTaskCategories, FirmTaskCategoriesDocument } from 'web/graphql/types/graphql';
import { falsyGuard } from '@sb-itops/type-helpers';
import { TaskFilters } from './TaskFilters';

interface ITaskFiltersContainerProps {
  scope: string;
  isMatterPage: boolean;
  taskViewCounts: any;
  loading: boolean;
}

const hooks = ({ scope }: ITaskFiltersContainerProps) => ({
  useCategories: () => {
    const [categories, setCategories] = useState<FirmTaskCategories[]>([]);

    const { data } = useQuery(FirmTaskCategoriesDocument);

    useEffect(() => {
      const cats = (data?.firmTaskCategories || []).filter(falsyGuard);
      setCategories(cats);
    }, [data]);

    return {
      categories,
    };
  },
  useTaskFiltersStore: () => {
    const dispatch = useDispatch();

    const { selectors, actions } = useScopedFeature(taskFiltersFeature, scope);
    const taskView = useSelector(selectors.getTaskView) as string;
    const showDeleted = useSelector(selectors.getShowDeleted) as boolean;
    const assigneeIds = useSelector(selectors.getAssigneeIds) as string[];
    const categoryIds = useSelector(selectors.getCategoryIds) as string[];
    const expanded = useSelector(selectors.getExpanded) as boolean;
    const assigneeFilterExpanded = useSelector(selectors.getAssigneeFilterExpanded) as boolean;
    const categoryFilterExpanded = useSelector(selectors.getCategoryFilterExpanded) as boolean;
    const viewExpanded = useSelector(selectors.getViewExpanded) as boolean;

    return {
      toggleExpanded: () => {
        dispatch(actions.toggleExpanded());
      },
      setTaskView: (newTaskView: string) => {
        dispatch(actions.setTaskView({ taskView: newTaskView }));
      },
      setShowDeleted: (newToggle: boolean) => {
        dispatch(actions.setShowDeleted({ showDeleted: newToggle }));
      },
      setAssigneeIds: (newAssigneeIds: string[]) => {
        dispatch(actions.setAssigneeIds({ assigneeIds: newAssigneeIds }));
      },
      setCategoryIds: (newCategoryIds: string[]) => {
        dispatch(actions.setCategoryIds({ categoryIds: newCategoryIds }));
      },
      toggleAssigneeFilterExpanded: () => {
        dispatch(actions.toggleAssigneeFilterExpanded());
      },
      toggleCategoryFilterExpanded: () => {
        dispatch(actions.toggleCategoryFilterExpanded());
      },
      toggleViewExpanded: () => {
        dispatch(actions.toggleViewExpanded());
      },
      showDeleted,
      assigneeIds,
      categoryIds,
      taskView,
      expanded,
      staff: getCurrentStaff(),
      assigneeFilterExpanded,
      categoryFilterExpanded,
      viewExpanded,
    };
  },
});

export const TaskFiltersContainer = composeHooks(hooks)(TaskFilters);
