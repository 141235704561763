import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { regionType } from '@sb-itops/region';
import { buttonTypes, Button, Icon, LoadingBarInfinite, Modal } from '@sb-itops/react';
import {
  entryType as entryTypeEnum,
  durationType as durationTypeEnum,
} from '@sb-billing/business-logic/shared/entities';
import {
  activityCategoryLabels,
  activityCategories,
  taskCategoryLabels,
} from '@sb-billing/business-logic/activities/entities/constants';

import Styles from './FeeModal.module.scss';

import { FeeModalBody } from './FeeModalBody';

export const FEE_MODAL_ID = 'fee-modal';

export const FeeModal = (props) => {
  /** Modal body and footer props */
  const { invoiceNumber, isNewFee } = props;
  /** Modal body props */
  const {
    activities,
    descriptionFieldMaxLength,
    durationFieldInputDisabled,
    durationFieldTypeDisabled,
    formData,
    formDataMappedObjects,
    formErrors,
    hasDurationTypeChanged,
    initialValues,
    isFormSubmitting,
    isOnFinalisedInvoice,
    matterBillableMinutes,
    matterSummaries,
    matterSummariesDataLoading,
    matterSummariesHasMore,
    region,
    showTaskField,
    showTaxField,
    showTaxOptions,
    staffMemberOptions,
    subjects,
    taskFieldEnabled,
    tasks,
    taxOptions,
    writeOffFieldEnabled,
    // Callbacks
    onDeleteFee,
    onDurationBilledFieldBlur,
    onDurationWorkedFieldBlur,
    onFetchMatterSummaries,
    onFetchMoreMatterSummaries,
    onHandleTaxOptionsBlur,
    onNavigateToInvoice,
    onShowTaxOptionsToggle,
    onUpdateActivityField,
    onUpdateBillableField,
    onUpdateDurationFieldType,
    onUpdateDurationBilledFieldValue,
    onUpdateDurationWorkedFieldValue,
    onUpdateField,
    onUpdateMatterField,
    onUpdateSourceItemsField,
    onUpdateStaffField,
    onUpdateTaskField,
    onUpdateTaxField,
    setHasDurationTypeChanged,
    setIsSubjectOverridable,
  } = props;
  /** Modal footer props */
  const {
    autoTimeSummary,
    formInitialised,
    isAutoTimeFee,
    isOnDraftInvoice,
    isFormDisabled,
    loading,
    showModal,
    hideSaveAndNewButton,
    // Callbacks
    onFormSubmit,
    onModalClose,
  } = props;

  return (
    <Modal
      className={Styles.feeModal}
      modalHeaderClassName={Styles.modalHeader}
      modalBodyClassName={Styles.modalBody}
      modalFooterClassName={Styles.modalFooter}
      isVisible={showModal}
      title={isNewFee ? 'New Time / Fee Entry' : 'Time / Fee Details'}
      body={
        loading || !formInitialised ? (
          <div className={Styles.feeModalLoadingContainer}>
            <LoadingBarInfinite loading={loading} />
          </div>
        ) : (
          <FeeModalBody
            activities={activities}
            descriptionFieldMaxLength={descriptionFieldMaxLength}
            durationFieldInputDisabled={durationFieldInputDisabled}
            durationFieldTypeDisabled={durationFieldTypeDisabled}
            formData={formData}
            formDataMappedObjects={formDataMappedObjects}
            formErrors={formErrors}
            hasDurationTypeChanged={hasDurationTypeChanged}
            initialValues={initialValues}
            invoiceNumber={invoiceNumber}
            isAutoTimeFee={isAutoTimeFee}
            isFormDisabled={isFormDisabled}
            isFormSubmitting={isFormSubmitting}
            isNewFee={isNewFee}
            isOnFinalisedInvoice={isOnFinalisedInvoice}
            matterBillableMinutes={matterBillableMinutes}
            matterSummaries={matterSummaries}
            matterSummariesDataLoading={matterSummariesDataLoading}
            matterSummariesHasMore={matterSummariesHasMore}
            onDeleteFee={onDeleteFee}
            onDurationBilledFieldBlur={onDurationBilledFieldBlur}
            onDurationWorkedFieldBlur={onDurationWorkedFieldBlur}
            onFetchMatterSummaries={onFetchMatterSummaries}
            onFetchMoreMatterSummaries={onFetchMoreMatterSummaries}
            onHandleTaxOptionsBlur={onHandleTaxOptionsBlur}
            onNavigateToInvoice={onNavigateToInvoice}
            onShowTaxOptionsToggle={onShowTaxOptionsToggle}
            onUpdateActivityField={onUpdateActivityField}
            onUpdateBillableField={onUpdateBillableField}
            onUpdateDurationFieldType={onUpdateDurationFieldType}
            onUpdateDurationBilledFieldValue={onUpdateDurationBilledFieldValue}
            onUpdateDurationWorkedFieldValue={onUpdateDurationWorkedFieldValue}
            onUpdateField={onUpdateField}
            onUpdateMatterField={onUpdateMatterField}
            onUpdateSourceItemsField={onUpdateSourceItemsField}
            onUpdateStaffField={onUpdateStaffField}
            onUpdateTaskField={onUpdateTaskField}
            onUpdateTaxField={onUpdateTaxField}
            region={region}
            setHasDurationTypeChanged={setHasDurationTypeChanged}
            setIsSubjectOverridable={setIsSubjectOverridable}
            showTaskField={showTaskField}
            showTaxField={showTaxField}
            showTaxOptions={showTaxOptions}
            staffMemberOptions={staffMemberOptions}
            subjects={subjects}
            taskFieldEnabled={taskFieldEnabled}
            tasks={tasks}
            taxOptions={taxOptions}
            writeOffFieldEnabled={writeOffFieldEnabled}
          />
        )
      }
      footer={
        <div className={Styles.formFooter}>
          <div className={Styles.leftContent}>
            <div className={classNames(Styles.formButtonsContainer, !hideSaveAndNewButton && Styles.multipleButtons)}>
              <Button
                className={Styles.formButtonContainer}
                disabled={loading || isFormDisabled}
                onClick={onFormSubmit}
              >
                {isNewFee ? 'Save' : 'Update'}
              </Button>
              {!hideSaveAndNewButton && (
                <Button
                  className={Styles.formButtonContainer}
                  type={buttonTypes.secondary}
                  disabled={loading || isFormDisabled}
                  onClick={() => onFormSubmit({ saveAndNew: true })}
                >
                  Save & New
                </Button>
              )}
            </div>
            {isOnDraftInvoice && invoiceNumber && (
              <div className={Styles.draftInvoiceWarning}>
                <i className="icon icon-alert-1" />
                <span>On draft invoice #{invoiceNumber}</span>
              </div>
            )}
          </div>
          <div className={Styles.rightContent}>
            {isAutoTimeFee && (
              <div className={Styles.autoTimeSummary}>
                <Icon type="information" className={Styles.infoIcon} />
                <p>{autoTimeSummary}</p>
              </div>
            )}
          </div>
        </div>
      }
      onModalClose={onModalClose}
    />
  );
};

const MatterSummaryType = PropTypes.shape({
  attorneyResponsible: PropTypes.shape({
    id: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  billingConfiguration: PropTypes.shape({
    billingType: PropTypes.string,
    id: PropTypes.string.isRequired,
    isUtbmsEnabled: PropTypes.bool.isRequired,
  }),
  clientDisplay: PropTypes.string.isRequired,
  clientNames: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  matterHourlyRate: PropTypes.shape({
    allStaffRate: PropTypes.number,
    billableMinutes: PropTypes.number,
    id: PropTypes.string.isRequired,
    rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
    rateSet: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          ratesPerStaff: PropTypes.arrayOf(
            PropTypes.shape({
              rate: PropTypes.number.isRequired,
              staffId: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }),
    ratesPerStaff: PropTypes.arrayOf(PropTypes.object),
  }),
  matterNumber: PropTypes.string.isRequired,
  matterStarted: PropTypes.instanceOf(Date),
  matterType: PropTypes.shape({
    name: PropTypes.string,
  }),
  otherSide: PropTypes.arrayOf(PropTypes.object),
  otherSideDisplay: PropTypes.string,
  status: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  matterClientNames: PropTypes.string.isRequired,
  matterStartedISO: PropTypes.string.isRequired,
  typeahead: PropTypes.string.isRequired,
});

const SourceItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    activityCount: PropTypes.number,
    activityRelatedId: PropTypes.string,
    activityType: PropTypes.number,
    billable: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    durationBilled: PropTypes.number.isRequired,
    id: PropTypes.string, // Added (not part of entity)
    originalBillable: PropTypes.bool,
    sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
);

const ActivityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isBillable: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(entryTypeEnum)),
  units: PropTypes.number,
  isTaxInclusive: PropTypes.bool,
  isTaxExempt: PropTypes.bool,
  rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
  allStaffRate: PropTypes.number,
  ratesPerStaff: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string.isRequired,
      rate: PropTypes.number.isRequired,
    }).isRequired,
  ),
  description: PropTypes.string.isRequired,
  category: PropTypes.oneOf(Object.values(activityCategories)),
});

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

const TaskType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

FeeModal.propTypes = {
  invoiceNumber: PropTypes.number,
  isNewFee: PropTypes.bool.isRequired,
  hideSaveAndNewButton: PropTypes.bool.isRequired,
  /** Modal footer specific */
  autoTimeSummary: PropTypes.string,
  isOnDraftInvoice: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  /** Modal body specific */
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(activityCategoryLabels)),
      activities: PropTypes.arrayOf(ActivityType),
    }),
  ),
  descriptionFieldMaxLength: PropTypes.number.isRequired,
  durationFieldInputDisabled: PropTypes.bool,
  durationFieldTypeDisabled: PropTypes.bool,
  formData: PropTypes.shape({
    description: PropTypes.string,
    durationBilledInMins: PropTypes.number,
    durationWorkedInMins: PropTypes.number,
    feeDate: PropTypes.instanceOf(Date),
    staffId: PropTypes.string,
    isBillable: PropTypes.bool,
    isTaxExempt: PropTypes.bool,
    isWriteOff: PropTypes.bool,
    isTaxInclusive: PropTypes.bool,
    rateInCents: PropTypes.number,
    sourceItems: SourceItemsType,
    subject: PropTypes.string,
    // Derived values required in form submission and/or form fields
    amountExclTaxInCents: PropTypes.number,
    billableTaxAmountInCents: PropTypes.number,
    taxAmountInCents: PropTypes.number,
    // Form state not required in form submission (can be edited by user)
    durationBilled: PropTypes.string, // Billed duration field value (hours/units)
    durationWorked: PropTypes.string, // Worked duration field value (hours/units)
    durationType: PropTypes.oneOf(Object.values(durationTypeEnum)),
    // Form state not required in form submission (cannot be edited by user)
    billableAmountExclTaxInCents: PropTypes.number,
    nonBillableAmountExclTaxInCents: PropTypes.number,
    timeBilledInHoursAndMinutes: PropTypes.string,
    timeWorkedInHoursAndMinutes: PropTypes.string,
    // IDs that map with objects used by containers and form components
    activityId: PropTypes.string,
    matterId: PropTypes.string,
    subjectActivityId: PropTypes.string,
    taskId: PropTypes.string,
  }),
  formDataMappedObjects: PropTypes.shape({
    activity: ActivityType,
    matter: MatterSummaryType,
    staff: StaffEntityType,
    subjectActivity: ActivityType,
    task: TaskType,
  }).isRequired,
  formErrors: PropTypes.object.isRequired,
  hasDurationTypeChanged: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    defaultMatterSummaries: PropTypes.arrayOf(MatterSummaryType),
    sourceItems: SourceItemsType.isRequired,
  }),
  isAutoTimeFee: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  isOnFinalisedInvoice: PropTypes.bool.isRequired,
  matterBillableMinutes: PropTypes.number,
  matterSummaries: PropTypes.arrayOf(MatterSummaryType.isRequired).isRequired,
  matterSummariesDataLoading: PropTypes.bool.isRequired,
  matterSummariesHasMore: PropTypes.bool.isRequired,
  region: PropTypes.oneOf(Object.values(regionType)),
  showTaskField: PropTypes.bool,
  showTaxField: PropTypes.bool,
  showTaxOptions: PropTypes.bool,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType).isRequired,
  subjects: PropTypes.arrayOf(ActivityType.isRequired),
  taskFieldEnabled: PropTypes.bool,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(taskCategoryLabels)),
      tasks: PropTypes.arrayOf(TaskType),
    }),
  ),
  taxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ),
  writeOffFieldEnabled: PropTypes.bool,
  // Callbacks
  onDeleteFee: PropTypes.func.isRequired,
  onDurationBilledFieldBlur: PropTypes.func,
  onDurationWorkedFieldBlur: PropTypes.func,
  onFetchMatterSummaries: PropTypes.func,
  onFetchMoreMatterSummaries: PropTypes.func,
  onHandleTaxOptionsBlur: PropTypes.func,
  onNavigateToInvoice: PropTypes.func.isRequired,
  onShowTaxOptionsToggle: PropTypes.func,
  onUpdateActivityField: PropTypes.func,
  onUpdateBillableField: PropTypes.func,
  onUpdateDurationFieldType: PropTypes.func,
  onUpdateDurationBilledFieldValue: PropTypes.func,
  onUpdateDurationWorkedFieldValue: PropTypes.func,
  onUpdateField: PropTypes.func,
  onUpdateMatterField: PropTypes.func,
  onUpdateSourceItemsField: PropTypes.func,
  onUpdateStaffField: PropTypes.func,
  onUpdateTaskField: PropTypes.func,
  onUpdateTaxField: PropTypes.func,
  setHasDurationTypeChanged: PropTypes.func.isRequired,
  setIsSubjectOverridable: PropTypes.func.isRequired,
};

FeeModal.defaultProps = {
  autoTimeSummary: '',
  invoiceNumber: undefined,
  /** Required props to pass into FeeModalBody that are undefined until the containers finish loading and initialise the form  */
  activities: undefined,
  durationFieldInputDisabled: undefined,
  durationFieldTypeDisabled: undefined,
  formData: undefined,
  initialValues: undefined,
  matterBillableMinutes: undefined,
  region: undefined,
  showTaskField: undefined,
  showTaxField: undefined,
  showTaxOptions: undefined,
  subjects: undefined,
  taskFieldEnabled: undefined,
  tasks: undefined,
  taxOptions: undefined,
  writeOffFieldEnabled: undefined,
  // Callbacks
  onDurationBilledFieldBlur: undefined,
  onDurationWorkedFieldBlur: undefined,
  onFetchMatterSummaries: undefined,
  onFetchMoreMatterSummaries: undefined,
  onHandleTaxOptionsBlur: undefined,
  onShowTaxOptionsToggle: undefined,
  onUpdateActivityField: undefined,
  onUpdateBillableField: undefined,
  onUpdateDurationFieldType: undefined,
  onUpdateDurationBilledFieldValue: undefined,
  onUpdateDurationWorkedFieldValue: undefined,
  onUpdateField: undefined,
  onUpdateMatterField: undefined,
  onUpdateSourceItemsField: undefined,
  onUpdateStaffField: undefined,
  onUpdateTaskField: undefined,
  onUpdateTaxField: undefined,
};

FeeModal.displayName = 'FeeModal';
