'use strict';

const { feeCanBeModified } = require('./fee-can-be-modified');

/**
 * Determines if feeVersion can be marked as billable
 *
 * A fee version can be marked as billable if
 * 1. it can be modified
 * 2. is not already billable
 * @param {object} feeVersion
 * @returns {boolean}
 */
const feeCanBeMarkedAsBillable = (feeVersion) => feeCanBeModified(feeVersion) && !feeVersion.isBillable;

module.exports = {
  feeCanBeMarkedAsBillable,
};
