import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { buttonTypes, Button, LinkableText, Modal, Icon } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { durationType as durationTypeEnum } from '@sb-billing/business-logic/shared/entities';

import { FeeSourceItemEntryContainer } from './FeeSourceItemEntry.container';

import Styles from './FeeSourceItemsEntries.module.scss';

export const FeeSourceItemsEntries = (props) => {
  const {
    durationType,
    feeDurationBilled,
    feeDurationWorked,
    hasDurationTypeChanged,
    interval,
    isDisabled,
    sourceItems,
    // Modal
    onSetShowModal,
    showModal,
    // Callbacks
    onAddSourceItemEntry,
    onDeleteFee,
    onSourceItemsChange,
    onSourceItemsDurationTypeChange,
  } = props;

  const onModalClose = () => onSetShowModal(false);
  const isTimeWorkedEnabled = featureActive('BB-13563');

  return (
    <div className={Styles.feeSourceItemsContainer}>
      {/* Header */}
      <div
        className={classNames(Styles.feeSourceItemsHeaderAndControl, isTimeWorkedEnabled && Styles.timeWorkedEnabled)}
      >
        <div className={Styles.descriptionColumnHeaderContainer}>
          <label>Description</label>
        </div>
        {!isTimeWorkedEnabled && (
          <div className={Styles.durationColumnHeaderContainer}>
            <label>Duration</label>
            {featureActive('BB-5345') && (
              <div className={classNames('btn-group', Styles.durationButtonsContainer)}>
                <button
                  type="button"
                  className={classNames(
                    'btn',
                    Styles.durationButton,
                    durationType === durationTypeEnum.HOURS ? 'btn-primary' : 'btn-default',
                  )}
                  disabled={isDisabled}
                  onClick={() => onSourceItemsDurationTypeChange({ newDurationType: durationTypeEnum.HOURS })}
                >
                  Hrs
                </button>
                <button
                  type="button"
                  className={classNames(
                    'btn',
                    Styles.durationButton,
                    durationType === durationTypeEnum.UNITS ? 'btn-primary' : 'btn-default',
                  )}
                  disabled={isDisabled}
                  onClick={() => onSourceItemsDurationTypeChange({ newDurationType: durationTypeEnum.UNITS })}
                >
                  Units
                </button>
              </div>
            )}
          </div>
        )}
        {isTimeWorkedEnabled && (
          <>
            <div className={Styles.workingColumnHeaderContainer}>
              <label>WORKING</label>
              <Icon
                className={Styles.questionIcon}
                type="question-mark"
                tooltip="Can be used to track the actual working time for this staff member, if different to the time you wish to bill"
              />
            </div>
            <div className={Styles.billingColumnHeaderContainer}>
              <label>BILLING</label>
              <Icon
                className={Styles.questionIcon}
                type="question-mark"
                tooltip="Can be changed, independently of working time, to vary the time you wish to bill"
              />
            </div>
          </>
        )}
        <div className={Styles.billableColumnHeaderContainer}>
          <label>{isTimeWorkedEnabled ? 'Billable' : 'Billable?'}</label>
        </div>
        {isTimeWorkedEnabled && <div className={Styles.trashBinHeaderContainer} />}
      </div>
      {/* Source items */}
      <div className={Styles.feeSourceItemsListContainer}>
        <div className={classNames(Styles.feeSourceItemsList, isDisabled && Styles.disabled)}>
          {sourceItems.map((sourceItem, index) => (
            <FeeSourceItemEntryContainer
              key={sourceItem.id}
              index={index}
              hasDurationTypeChanged={hasDurationTypeChanged}
              durationType={durationType}
              feeDurationBilled={feeDurationBilled}
              feeDurationWorked={feeDurationWorked}
              interval={interval}
              isDisabled={isDisabled}
              sourceItem={sourceItem}
              sourceItems={sourceItems}
              onSetShowModal={onSetShowModal}
              onSourceItemsChange={onSourceItemsChange}
            />
          ))}
        </div>
        {/* Add source item */}
        <LinkableText
          className={Styles.addFeeSourceItemLink}
          text="Add Item..."
          onClickLink={onAddSourceItemEntry}
          asLink
        />
      </div>
      {/* Fee deletion confirmation modal */}
      <Modal
        title="Remove Fee"
        body="Removing all activities will delete this fee. Do you want to continue?"
        isVisible={showModal}
        modalFooterClassName={Styles.deleteFeeModalFooter}
        onModalClose={onModalClose}
        footer={
          <div className={Styles.buttonGroup}>
            <Button onClick={onDeleteFee}>Yes</Button>
            <Button type={buttonTypes.secondary} onClick={onModalClose}>
              No
            </Button>
          </div>
        }
      />
    </div>
  );
};

FeeSourceItemsEntries.displayName = 'FeeSourceItemsEntries';

const SourceItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    activityCount: PropTypes.number,
    activityRelatedId: PropTypes.string,
    activityType: PropTypes.number,
    billable: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    durationBilled: PropTypes.number.isRequired,
    id: PropTypes.string, // Added (not part of entity)
    originalBillable: PropTypes.bool,
    sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
);

FeeSourceItemsEntries.propTypes = {
  durationType: PropTypes.oneOf(Object.values(durationTypeEnum)).isRequired,
  feeDurationBilled: PropTypes.string.isRequired,
  feeDurationWorked: PropTypes.string.isRequired,
  hasDurationTypeChanged: PropTypes.bool.isRequired,
  interval: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  sourceItems: SourceItemsType.isRequired,
  // Modal
  onSetShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  // Callbacks
  onAddSourceItemEntry: PropTypes.func.isRequired,
  onDeleteFee: PropTypes.func.isRequired,
  onSourceItemsChange: PropTypes.func.isRequired,
  onSourceItemsDurationTypeChange: PropTypes.func.isRequired,
};

FeeSourceItemsEntries.defaultProps = {};
