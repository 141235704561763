import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uuid from '@sb-itops/uuid';
import '../affini-pay/fieldGen_1.4.0'; // Horrendously adds AffiniPay to window object.
// import '../affini-pay'; // Horrendously adds AffiniPay to window object.
import Styles from './ElectronicChequeHostedFields.module.scss';

const { HostedFields } = window.AffiniPay;

// The hosted field updates are sent back to the parent as 'errors'. We can't actually send back
// the real current values of the hosted fields - that's a tokenisation time concern. We send
// back the errors so that the parent can perform it's own overall form validation if the hosted
// fields form part of a larger form.
const hostedFieldMap = { bank_account_number: 'bankAccountNumberStatus', routing_number: 'routingNumberStatus' };

export const ElectronicChequeHostedFields = memo(
  ({
    publicKey,
    disabled,
    style,
    bankAccountNumberError,
    routingNumberError,
    onFieldChange,
    onHostedFieldsApiReady,
  }) => {
    // The hosted form fields require globally unique ids to function correctly.
    const [bankAccountNumberFieldId] = useState(`bank_account_number_${uuid()}`);
    const [routingNumberFieldId] = useState(`routing_number_${uuid()}`);

    // We want the hosted fields initialisation to take place after the initial render of the DOM for this component, hence our
    // use of useEffect(). Note that no dependencies are passed to the second parameter of useEffect(), indicating that we
    // only want the below code to run once, no matter how many times the props used within it might change. The effect is that
    // the caller can't do things like dynamically change the styles of hosted fields. That's a pretty nutso idea anyway.
    useEffect(() => {
      const createConfigField = (id, type) => ({
        selector: `#${id}`,
        input: {
          type,
          css: style,
        },
      });

      const config = {
        publicKey,
        fields: [
          createConfigField(bankAccountNumberFieldId, 'bank_account_number'),
          createConfigField(routingNumberFieldId, 'routing_number'),
        ],
      };

      const { getState, getPaymentToken } = HostedFields.initializeFields(config, handleHostedFieldsChange);
      onHostedFieldsApiReady({ getState, getPaymentToken });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The onChange prop is only useful to the parent in determining whether a field is valid or not.
    // We don't send back the actual eCheque information to the parent via onChange. Eventually the parent needs
    // to run a full tokenization over the data in the hosted fields, which they can do by calling the functions
    // returned back via onHostedFieldsApiReady().
    const handleHostedFieldsChange = ({ target }) => {
      // For sensitive fields (bankAccountNumber / routingNumber), the value is sent to the parent as either true or false.
      // Every hosted field change target includes an additional 'card' property which indicates the type
      // of card currently entered. This could also be useful for validation from above, so we pass that as a
      // separate change update.
      onFieldChange({ field: hostedFieldMap[target.type], value: !target.error });
    };

    return (
      <div className={Styles.electronicChequeHostedFields}>
        <div key="bank_account_number" className={classnames(Styles.cell, Styles.cell50, Styles.bankAccountNumber)}>
          <div className={Styles.fieldContainer}>
            <label htmlFor={bankAccountNumberFieldId}>Bank Account Number</label>
            <div
              id={bankAccountNumberFieldId}
              className={classnames('form-control', bankAccountNumberError && Styles.fieldError)}
            />
            {disabled && <div className={classnames(Styles.disabledEntry, 'form-control')} />}
          </div>
        </div>

        <div key="routing_number" className={classnames(Styles.cell, Styles.cell50, Styles.routingNumber)}>
          <div className={Styles.fieldContainer}>
            <label htmlFor={routingNumberFieldId}>Routing Number</label>
            <div
              id={routingNumberFieldId}
              className={classnames('form-control', routingNumberError && Styles.fieldError)}
            />
            {disabled && <div className={classnames(Styles.disabledEntry, 'form-control')} />}
          </div>
        </div>
      </div>
    );
  },
);

ElectronicChequeHostedFields.displayName = 'ElectronicChequeHostedFields';

ElectronicChequeHostedFields.propTypes = {
  publicKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  bankAccountNumberError: PropTypes.bool,
  routingNumberError: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onHostedFieldsApiReady: PropTypes.func.isRequired,
};

ElectronicChequeHostedFields.defaultProps = {
  disabled: false,
  bankAccountNumberError: false,
  routingNumberError: false,
  // unfortunately we cannot style the affini pay component with css. The only way they provide a way of doing this is by style options.
  // We need to expose this here so if we need we can override the affini pay default styles
  style: {},
};
