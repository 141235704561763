import { updateCache as updateRedux } from '@sb-billing/redux/report-config';

angular
  .module('@sb-billing/services')
  .service('sbReportConfigService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    const reportConfigEndpoint = 'billing/report-config';

    sbGenericCacheService.setupCache({
      name:'sbReportConfigService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: reportConfigEndpoint },
        poll: 60,
        subscriptions: 'notifier.ReportLinkUpdates',
      },
      updateRedux,
    });
  });
