'use strict';

angular.module('sb.billing.webapp').factory('combineAccountRemindersOp', ($http, sbAccountRemindersPreviewService, sbNotifiedOperationP, sbLoggerService, sbUuidService) => {
  return (reminders) => {
    const log = sbLoggerService.getLogger('combineAccountRemindersOp');
    const requestId = sbUuidService.get();
    const timeoutMs = reminders.reduce((accumulator, item) => accumulator + item.invoices.length * 60000, 0);

    // Object representing the current state of this operation.
    const operation = {
      label: `Combining ${reminders.length} Invoice Reminders`,
      isComplete: false,
      error: null,
      fileData: null,
      tab: {
        title: 'Combined Reminders',
        subtitle: false
      },
      nbReminders: reminders.length,
      progress: {
        nbSteps: 0,
        maxSteps: 1,
        percentage: null
      }
    };
    
    // Kick off the operation
    sbNotifiedOperationP(startPdfCombineP, { // TODO
      requestId,
      completionNotification: sbAccountRemindersPreviewService.readyCombineNotificationMessage,
      progressNotification: sbAccountRemindersPreviewService.progressCombineNotificationMessage,
      timeoutMs,
    })
    .then(onPdfCombineComplete, undefined, onPdfCombineProgress)
    .catch(onError);

    return operation;

    // Returns a promise for combining of invoices into a single PDF. Used by sbNotifiedOperation to begin processing.
    function startPdfCombineP() {
      return sbAccountRemindersPreviewService.getCombinedPdfPreviewBase64P(requestId, reminders);
    }

    // Called by sbNotifiedOperation whenever some progress is made during the combine PDF operation.
    function onPdfCombineProgress(msg) {
      const progress = operation.progress;
      ++progress.nbSteps;

      const newMaxProgress = _.get(msg, 'payload.maxProgress') || progress.maxSteps;
      progress.maxSteps = Math.max(newMaxProgress, progress.nbSteps);

      if (progress.maxSteps) {
        progress.percentage = Math.min((progress.nbSteps / progress.maxSteps) * 100, 99.5); // Don't go over 99.5 % for incomplete operation. 
      }
    }

    // Called by sbNotifiedOperation when the combine PDF operation completes.
    // Note that we still have a bit of work to do when the PDF combining completes, mainly downloading the document content as base64.
    function onPdfCombineComplete(msg) {
      const payload = msg.payload;
      const request = {
        method: 'GET',
        url: payload.previewUrl,
        responseType: 'arraybuffer'
      };
      
      log.info('send request', request);
      return $http(request)
        .then(response => {
          const file = new Blob([response.data], {type: 'application/pdf'});
          operation.isComplete = true;
          operation.progress.percentage = 100;
          operation.fileData = file; 
        });
    }

    // The hopefully never called error handler.
    function onError(err) {
      log.error(`Failed to generate combined PDF with requestId: ${requestId}`, err);
      operation.error = err;
      operation.isComplete = true;
    }
  };
});
