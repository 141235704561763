'use strict';

const { appendPaymentAndEInvoicePlaceholders } = require('./append-payment-and-einvoice-placeholders');
const { buildCommunicateMessageNotification } = require('./build-communicate-message-notification');
const { buildFilesForCommunicateMessageAttachments } = require('./build-files-for-communicate-message-attachment');
const { buildRolesForGroupOfPeopleDebtor, buildRolesForNonGroupOfPeopleDebtor } = require('./build-roles-for-debtor');
const { getContactFirstAndLastNames } = require('./get-contact-first-and-last-names');
const { getDefaultCommunicateMessage } = require('./get-default-communicate-message');
const { getPersonInitials } = require('./get-person-initials');

module.exports = {
  appendPaymentAndEInvoicePlaceholders,
  buildCommunicateMessageNotification,
  buildFilesForCommunicateMessageAttachments,
  buildRolesForGroupOfPeopleDebtor,
  buildRolesForNonGroupOfPeopleDebtor,
  getContactFirstAndLastNames,
  getDefaultCommunicateMessage,
  getPersonInitials,
};
