import {
  getReportTypes,
} from '@sb-billing/redux/report-config';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import uuid from '@sb-itops/uuid';

angular
  .module('@sb-billing/services')
  .service('sbReportingService', function(
    $http,
    sbGenericEndpointService,
    sbUnsavedChangesService,
    sbNotifiedOperationP,
    sbLocalisationService,
    $state,
    $location,
  ) {
    const that = this;

    let reportTypeChangeHandler = [];
    let activeReportType;
    let destroyReportType;

    that.generateReportP = generateReportP;
    that.downloadReportFileP = downloadReportFileP;

    that.onReportTypeChanged = onReportTypeChanged;
    that.registerReportTypeChange = registerReportTypeChange;
    that.clearReportTypeChangeHandler = clearReportTypeChangeHandler;

    that.retrieveSavedReportType = retrieveSavedReportType;
    that.saveFilterForm = saveFilterForm;
    that.updateURL = updateURL;
    that.loadDefaultFilter = loadDefaultFilter;
    that.isFilterDefault = isFilterDefault;
    that.getQueryParam = getQueryParam;
    that.setQueryParam = updateURL;
    that.t = sbLocalisationService.t;

    /**
     * 
     * @param {*} filterForm 
     * @param {*} download skips notifications and download if false, default true
     * @returns 
     */
    async function generateReportP(filterForm, download = true) {
      const showLastNameFirst = featureActive('BB-5604');
      
      // showLeadMatters is used in time & expenses report and will be deprecated once BB-6595 goes live
      const includeLeads = featureActive('BB-6595') || filterForm.showLeadMatters;
      
      const filters = { ...filterForm, showLastNameFirst, includeLeads, reportGenerationId: filterForm.reportGenerationId || uuid() };
  
      const allowOverdraw = hasFacet(facets.allowOverdraw);
      const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
      const supportsCMA = hasFacet(facets.CMA);

      const { reportEndpoint } = getReportTypes(that.t, { supportsEnteredDate, allowOverdraw, supportsCMA }).find(({ type }) => type === filters.reportType) || {};
      const endpoint = `/billing/${reportEndpoint || `report-${filters.reportType}`}`;

      // !download currently only used for all matters trust statement which is emailed out, and expects its own params returned from endpoint
      if (!download) {
        const reportResponse = await sbGenericEndpointService.postPayloadP(endpoint, null, filters, 'POST', { skipCamelCase: true });
        return reportResponse;
      }

      const notification = await sbNotifiedOperationP(async () =>
        sbGenericEndpointService.postPayloadP(endpoint, null, filters, 'POST', { skipCamelCase: true }),
        {
          completionNotification: 'ReportGenerationFinished',
          errorNotification: 'ReportGenerationFailure',
          requestId: filters.reportGenerationId,
          timeoutMs: 180000,
        }
      );
      
      const reportResponse = notification.payload;

      const requestOptions = {
        method: 'GET',
        url: reportResponse.signedUrl,
        responseType: 'text',
      };

      const templateResponse = await $http(requestOptions);

      if (!templateResponse) {
        throw new Error('Could not download report. Please contact support for assistance')
      }
      reportResponse.template = templateResponse.data;
      return reportResponse;
    }

    function downloadReportFileP(requestId, reportFormat, reportData) {
      if (!reportData) {
        return Promise.reject(new Error('Invalid report format.'));
      }

      const endpoint = '/billing/report';
      const extraConfig = reportFormat === 'pdf' ? { skipCamelCase: true } : null;

      return sbGenericEndpointService.postPayloadP(
        endpoint,
        `${reportData.reportType}/${reportFormat}`,
        { requestId, ...reportData },
        'POST',
        extraConfig,
      );
    }

    function onReportTypeChanged(type) {
      if (activeReportType === type) {
        updateURL('type', type);
        return;
      }

      if (activeReportType) {
        $location.search({});
      }
      destroyReportType = activeReportType || type;
      activeReportType = type;
      if (!_.isEmpty(reportTypeChangeHandler)) {
        _.each(reportTypeChangeHandler, handler => {
          if (_.isFunction(handler)) {
            handler(type);
          }
        });
      }
      saveReportType(type);
      updateURL('type', type);
    }

    function registerReportTypeChange(handler) {
      if (activeReportType) {
        handler(activeReportType);
      }
      reportTypeChangeHandler.push(handler);
    }

    function clearReportTypeChangeHandler() {
      reportTypeChangeHandler = [];
    }

    function saveReportType(type) {
      sbUnsavedChangesService.saveMemory('sbDataReportSelectedType', type);
    }

    function retrieveSavedReportType() {
      const allowOverdraw = hasFacet(facets.allowOverdraw);
      const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
      const supportsCMA = hasFacet(facets.CMA);

      return sbUnsavedChangesService.loadMemory('sbDataReportSelectedType') || getReportTypes(that.t, { supportsEnteredDate, allowOverdraw, supportsCMA })[0].type;
    }

    function saveFilterForm(key, value) {
      const savedFilterForms = sbUnsavedChangesService.loadMemory('sbDataReportSavedFilterForms') || [];

      if ($state.current.name !== 'home.billing.reports') {
        destroyReportType = activeReportType;
      }

      const destroyForm = savedFilterForms[destroyReportType] || {};
      destroyForm[key] = value;
      savedFilterForms[destroyReportType] = destroyForm;
      sbUnsavedChangesService.saveMemory('sbDataReportSavedFilterForms', savedFilterForms);
    }

    function retrieveSavedFilterForm(key) {
      const savedFilterForms = sbUnsavedChangesService.loadMemory('sbDataReportSavedFilterForms');
      const activeForm = savedFilterForms && savedFilterForms[activeReportType];

      return activeForm && activeForm[key];
    }

    function updateURL(key, value) {
      if (_.isObject(value) || _.isBoolean(value)) {
        value = value.toString();
      }

      $location.search(key, value);
    }

    function getQueryParam(key) {
      return $location.search()[key];
    }

    function loadDefaultFilter(key) {
      return getQueryParam(key) || retrieveSavedFilterForm(key);
    }

    function isFilterDefault(reportName, key, value) {
      const allowOverdraw = hasFacet(facets.allowOverdraw);
      const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
      const supportsCMA = hasFacet(facets.CMA);

      const reportType = getReportTypes(that.t, { supportsEnteredDate, allowOverdraw, supportsCMA }).find(rt => rt.type === reportName);

      return reportType ? reportType.filterForm[key] === value : false;
    }
  });
