import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as messageDisplay from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';

import { MatterTypeDetails } from 'web/graphql/queries';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getClientRoleOptions } from '@sb-matter-listing/redux/matter-type-configurations';

import { useQuery } from '@apollo/client';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getList as getMatterTypes } from '@sb-matter-types/redux/matter-types';
import { optimisticUpdate } from 'web/services/apollo/optimistic-update';

import { ConvertLeadModal } from './ConvertLeadModal';

export const CONVERT_LEAD_MODAL_ID = 'convert-lead-to-matter-modal';

const getIdOfMatchingMatterType = (category, name, location) => {
  if (!category || !name) {
    return null;
  }

  const matterTypes = getMatterTypes();

  const matchingMatterType = matterTypes.find(
    (m) =>
      // Matter type, not lead
      !m.typeCategory &&
      m.categories.find((c) => c === category) &&
      m.name === name &&
      m.locationId === (location || 'GENERIC'),
  );

  return matchingMatterType;
};

const hooks = ({ matterId, onClose }) => ({
  useGraphQLforLeadData: () => {
    const [saving, setSaving] = useState();
    const [clientRoleId, setClientRoleId] = useState();
    const [categoryId, setCategoryId] = useState();
    const [locationId, setLocationId] = useState();
    const [matterTypeId, setMatterTypeId] = useState();
    const { data, loading, error } = useQuery(MatterTypeDetails.query, {
      variables: {
        matterId,
        filter: {
          includeNonBillableLeadMatters: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    useEffect(() => {
      if (data?.matter?.matterTypeId && data?.matter?.matterType) {
        const mappedLocationId = data.matter.matterTypeId.split('_')[1] || 'GENERIC';
        // Get the matching generic type
        const matchingMatterType = getIdOfMatchingMatterType(
          data?.matter?.matterType.categories[0],
          data?.matter?.matterType.name,
          data?.matter?.matterType.locationId,
        );
        // Set the location using the suffix on matterTypeId that comes from the matter
        setLocationId(mappedLocationId);
        if (matchingMatterType) {
          setCategoryId(matchingMatterType.categories[0]);
          // Add the location suffix back on to the selected matter type
          setMatterTypeId(`${matchingMatterType.matterTypeId}_${mappedLocationId}`);
          if (matchingMatterType.locationId && mappedLocationId === 'GENERIC') {
            setLocationId(matchingMatterType.locationId);
          }
        } else {
          setCategoryId(data?.matter?.matterType.categories[0]);
        }
      }
    }, [data]);

    let clientRoleOptions = matterTypeId ? getClientRoleOptions(matterTypeId) : [];

    if (clientRoleOptions.length === 0 && matterTypeId) {
      const matterTypeIdWithoutLocation = matterTypeId.split('_')[0];
      clientRoleOptions = matterTypeId ? getClientRoleOptions(matterTypeIdWithoutLocation) : [];
    }

    return {
      clientRoleId,
      setClientRoleId,
      clientRoleOptions,
      loading,
      saving,
      setCategoryId,
      setLocationId,
      setMatterTypeId,
      categoryId,
      matterTypeId,
      locationId,
      onSubmit: async () => {
        setSaving(true);
        try {
          let opdateResult;

          try {
            opdateResult = optimisticUpdate({
              identifier: {
                __typename: 'Matter',
                id: matterId,
              },
              fieldOpdates: {
                isLead: () => false,
              },
              rootQueryField: 'matter',
            });

            await fetchPostP({
              path: `/matter-management/matter/convert-lead/:accountId/${matterId}/`,
              fetchOptions: {
                body: JSON.stringify({
                  matterId,
                  matterTypeId,
                  clientRoleId,
                }),
              },
            });

            opdateResult.opdateServerSuccess();
          } catch {
            if (opdateResult) {
              opdateResult.rollbackOpdate();
            }
          }

          messageDisplay.success(`Success! Lead converted.`);
          onClose();
        } catch (e) {
          messageDisplay.error(
            `${e?.payload?.body?.message || 'Failed to convert lead to matter. Please try again later'}`,
          );
        }
        setSaving(false);
      },
    };
  },
});

export const ConvertLeadModalContainer = withApolloClient(composeHooks(hooks)(ConvertLeadModal));

ConvertLeadModalContainer.displayName = 'ConvertLeadModalContainer';

ConvertLeadModalContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

ConvertLeadModalContainer.defaultProps = {};
