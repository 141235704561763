import * as types from './types';

export const setAuthorisationResult = ({ successful, failureType, userPermissions }) => ({
  type: types.SET_AUTHORISATION_RESULT,
  payload: {
    successful,
    failureType,
    userPermissions,
  },
});

export const setStorageQuotaCalculated = ({ isQuotaSufficient }) => ({
  type: types.SET_STORAGE_QUOTA_CALCULATED,
  payload: {
    isQuotaSufficient,
  },
});

export const setServerVersion = ({ serverVersion }) => ({
  type: types.SET_SERVER_VERSION,
  payload: {
    serverVersion,
  },
});

export const setFileManagerInstalled = ({ isFileManagerInstalled }) => ({
  type: types.SET_FILE_MANAGER_INSTALLED,
  payload: {
    isFileManagerInstalled,
  },
});

export const setUserDataClearing = ({ isClearing }) => ({
  type: types.SET_USER_DATA_CLEARING,
  payload: {
    isClearing,
  },
});

export const setPrivateComputer = ({ isPrivateComputer }) => ({
  type: types.SET_PRIVATE_COMPUTER,
  payload: {
    isPrivateComputer,
  },
});

export const setIsMainMenuExpanded = ({ isMainMenuExpanded }) => ({
  type: types.SET_MAIN_MENU_EXPANSION_STATE,
  payload: {
    mainMenuExpansionState: isMainMenuExpanded
      ? types.mainMenuExpansionStates.EXPANDED
      : types.mainMenuExpansionStates.COLLAPSED,
  },
});

export const clearMainMenuExpansionState = () => ({
  type: types.CLEAR_MAIN_MENU_EXPANSION_STATE,
  payload: {},
});

export const setLastLoggedInUser = ({ username }) => ({
  type: types.SET_LAST_LOGGED_IN_USER,
  payload: {
    username,
  },
});

export const setLoginFailureMessage = ({ message, isVerbose = false } = {}) => ({
  type: types.SET_LOGIN_FAILURE_MESSAGE,
  payload: {
    message,
    isVerbose,
  },
});

export const setOwningCompanyInsecure = ({ owningCompanyInsecure }) => ({
  type: types.SET_OWNING_COMPANY_INSECURE,
  payload: {
    owningCompanyInsecure,
  },
});

export const setLoginBlockedReason = ({ reason }) => ({
  type: types.SET_LOGIN_BLOCKED_REASON,
  payload: {
    reason,
  },
});

export const setLoginFormSubheader = ({ subheader }) => ({
  type: types.SET_LOGIN_FORM_SUBHEADER,
  payload: {
    subheader,
  },
});
