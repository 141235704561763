'use strict';

angular.module('sb.billing.webapp').config(function($stateProvider) {

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-contact/credit-card';

  $stateProvider.state('home.billing.view-contact.credit-card', {
    url: '/credit-card',
    templateUrl: BASE_DIRECTORY + '/view-credit-card-route.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'contacts', 'view-contact'],
      tab: { type: 'contact' },
    },
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
  });
});
