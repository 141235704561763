'use strict';

import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { providerNames, feeCoverageModes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getActiveProvider, getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { isErrorSmokeballPreChargeError } from '@sb-billing/business-logic/payment-provider/services';
import { createDepositChargeRequest } from '@sb-billing/business-logic/payment-provider/requests/create-deposit-charge-request';
import { getSettings as getBankAccountSettings } from "@sb-billing/redux/bank-account-settings";
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { getChargeErrorMessage } from '@sb-billing/business-logic/payment-provider/services';
import { getById as getBankAccountById, isTrustAccount,isOperatingAccount } from '@sb-billing/redux/bank-account';

angular.module('sb.billing.webapp').component('sbCreditCardDepositModalButton', {
  bindings: { modalScope: '<', matterId: '<', payorId: '<', accountType: '<', disabled: '<', bankAccountId: '<', title: '<?' },
  templateUrl: 'ng-components/credit-card-deposit-modal-button/credit-card-deposit-modal-button.html',
  controller: function ($state, sbNotifiedOperationP, sbGenericEndpointService, sbFirmManagementMbService, sbLocalisationService) {
    const ctrl = this;
    const log = getLogger('sbCreditCardDepositModalButton');

    ctrl.onOpenModal = onOpenModal;
    ctrl.onCloseModal = onCloseModal;
    ctrl.onSubmitP = onSubmitP;
    ctrl.setIsSubmitting = setIsSubmitting;

    ctrl.viewState = {
      modalVisible: false,
      isSubmitting: false,
    };

    function onOpenModal() {
      ctrl.viewState.modalVisible = true;
    }

    function onCloseModal() {
      ctrl.viewState.modalVisible = false;
    }

    function setIsSubmitting(value) {
      ctrl.viewState.isSubmitting = value;
    }

    function printReceipt(transactionId) {
      const trustReceiptSettings = getBankAccountSettings();
      const createPDFReceiptOnDeposit = trustReceiptSettings && trustReceiptSettings.createPDFReceiptOnDeposit;
      if (createPDFReceiptOnDeposit) {
        $state.go('home.billing.deposit-receipt', { transactionId });
      }
    }

    // This function is called when the PaymentProviderChargeCompleted notification related to the deposit transaction is
    // received. Note that the PaymentProviderChargeCompleted may contain failures, receipt of PaymentProviderChargeCompleted
    // does not necessarily mean that the deposit charge has been processed successfully.
    function onDepositCompleted({ message, chargeRequest }) {
      const bankAccount = getBankAccountById(chargeRequest.targetBankAccountId);
      const billingAccountType = isOperatingAccount(bankAccount.accountType) ? `${sbLocalisationService.t("operatingRetainer")} account` : sbLocalisationService.t('capitalize', { val: 'trustAccount' });

      const matterName = getMatterDisplayById(chargeRequest.matterId);
      const paymentProviderName = providerNames[chargeRequest.providerType] || 'your payment integration';
      const amountWithSymbol = sbLocalisationService.t('cents', { val: chargeRequest.descriptionAmount })

      if (message.status !== 'FAILED') {
        messageDisplay.success(`A deposit of ${amountWithSymbol} was made into the ${billingAccountType} of matter ${matterName} via ${paymentProviderName}.`);
        if (isTrustAccount(bankAccount.accountType)) {
          printReceipt(chargeRequest.id);
        }
        onCloseModal();
      } else {
        // TODO: LM: This is terrible and needs a heap of cleaning up.
        let failureMessage = '';
        if (message.smokeballResponse) {
          failureMessage = message.smokeballResponse.failure.message;
        } else {
          const defaultMessage = 'An unexpected error occurred during transaction processing';
          const { message: errorMessage } = getChargeErrorMessage({ defaultMessage, chargeStatusResult: message, providerType: chargeRequest.providerType});
          failureMessage = errorMessage || defaultMessage;
        }

        messageDisplay.error(`An attempt to deposit ${amountWithSymbol} into the ${billingAccountType} of matter ${matterName} failed - ${failureMessage}`);
      }

      setIsSubmitting(false);
    }

    // This function is called if the notified operation fails. It means one of two things;
    // 1) The PaymentProviderChargeCompleted was not received within the expected time frame
    // 2) The call to postCreditCardChargeP failed.
    function onDepositError({ error, chargeRequest }) {
      const bankAccount = getBankAccountById(chargeRequest.targetBankAccountId);
      const billingAccountType = isOperatingAccount(bankAccount.accountType) ? `${sbLocalisationService.t("operatingRetainer")} account` : sbLocalisationService.t('capitalize', { val: 'trustAccount' });

      const matterName = getMatterDisplayById(chargeRequest.matterId);
      const amountWithSymbol = sbLocalisationService.t('cents', { val: chargeRequest.amountInCents })

      if (error.operationTimedOut) {
        messageDisplay.warn(`Credit card transaction status for deposit of ${amountWithSymbol} into the ${billingAccountType} of matter ${matterName} could not be determined. Please check your merchant portal for reference #${chargeRequest.id}.`);
        return;
      }

      log.error('Credit card deposit failed', error);
      messageDisplay.error(`Credit card transaction for deposit of ${amountWithSymbol} into the ${billingAccountType} of matter ${matterName} failed prior to processing. The credit card was not charged.`);

      setIsSubmitting(false);
    }

    function onSubmitP(paymentFormData) {
      setIsSubmitting(true);
        
      try {
        const providerType = getActiveProvider();
        const providerSpecificSettings = getProviderSettings(providerType);
        const staff = sbFirmManagementMbService.getLoggedInStaffMember();
        const staffName = staff.name || 'firm staff member';
        const firmName = sbFirmManagementMbService.getLoggedInStaffFirmName();
        const feeCoverageMode = providerSpecificSettings.clientCoversFeeOnDeposits ? feeCoverageModes.CLIENT_PAYS : feeCoverageModes.FIRM_PAYS;
        const accountId = getAccountId();
        const requestedByUserId = getUserId();

        const chargeRequest = createDepositChargeRequest({ accountId, requestedByUserId, providerType, paymentFormData, firmName, staffName, feeCoverageMode, t: sbLocalisationService.t });

        const postCreditCardChargeP = () => sbGenericEndpointService.postPayloadP(`/billing/payment-provider/charge/${providerType.toLowerCase()}`, undefined, chargeRequest);

        return sbNotifiedOperationP(postCreditCardChargeP, {
          requestId: chargeRequest.id,
          completionNotification: 'PaymentProviderChargeCompleted',
          completionFilterFn: message => message.id === chargeRequest.id,
          timeoutMs: 45000
        })
          .then(notification => onDepositCompleted({ message: notification.payload, chargeRequest }))
          .catch(error => onDepositError({ error, chargeRequest }));
      } catch (err) {
        log.error(err);
        if (isErrorSmokeballPreChargeError(err)) {
          const errText = `The transaction was declined by the card issuer: ${err.message}`;
          messageDisplay.error(messageDisplay.builder().title("The credit card was not charged").text(errText));
        } else {
          messageDisplay.error(`Credit card transaction for deposit into matter failed prior to processing. The credit card was not charged.`);
        }
        setIsSubmitting(false); 
      }
    }
  }
});