import composeHooks from '@sb-itops/react-hooks-compose';
import { useQuery } from '@apollo/client';
import { WidgetRecentDocuments } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import moment from 'moment';
import { RecentDocumentsWidget } from './RecentDocumentsWidget';

const hooks = ({ settings, matterId }) => ({
  useGraphQLforRecentMatters: () => {
    const { data, loading, error, refetch } = useQuery(WidgetRecentDocuments, {
      variables: {
        filter: {
          startDate: moment()
            .startOf('day')
            .subtract(settings.timeFrame || 1, settings.timeFrameUnits?.value || 'month')
            .toISOString(),
          matterId,
        },
      },
      notifyOnNetworkStatusChange: true,
    });

    if (error) {
      throw new Error(error);
    }
    return {
      onRefresh: refetch,
      documents: data?.recentDocuments || [],
      loading,
    };
  },
});

export const RecentDocumentsWidgetContainer = withApolloClient(composeHooks(hooks)(RecentDocumentsWidget));
