angular.module('sb.billing.webapp').directive('sbS3Upload', function ($parse) {
  'use strict';
  return {
    'restrict': 'A',
    'link': function (scope, element, attrs) {
      var model = $parse(attrs.sbS3Upload);
      var modelSetter = model.assign;

      element.bind('change', function () {
        scope.$apply(function () {
          /*
           NOTE: we set the file on the object uploadedFiles.
           This allows the directive to be used in a nested child scope.
           As long as a parent scope has uploadedFiles the file will be placed there.
           */
          modelSetter(scope.uploadedFiles, element[0].files[0]);
        });
      });
    }
  };
});
