import { getTransactionReceiptById } from '@sb-billing/redux/transaction-receipts';

angular.module('sb.billing.webapp').component('sbDataReceiptById', {
  require:  { compose: '^sbCompose' },
  bindings: { transactionId: '<', composeDataKey: '=?' },
  controller: function ($scope, sbTransactionReceiptService, sbLoggerService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataReceiptById');

    ctrl.$onInit = () => {
      log.info('init transactionId', ctrl.transactionId);
      update();
    };

    ctrl.$onChanges = () => {
      update();
    };

    function update() {
      log.info(`transactionId: ${ctrl.transactionId}`);

      if (ctrl.transactionId) {
        const receipt = getTransactionReceiptById(ctrl.transactionId);

        if (receipt) {
          ctrl.compose.setComposeData({ dataType: ctrl.composeDataKey || 'receipt', data: receipt }, ctrl.composeDataKey || 'receipt');
        }
      }
    }

    $scope.$on('smokeball-data-update-sbTransactionReceiptService', update);
  }
});
