const { pick: pickValueFromObject } = require('dot-object');
module.exports = ({ property, includeNull = false, includeUndefined = false }) => {
  if (!property) {
    throw new Error(`'extractFirst' requires a property parameter`);
  }

  return (dataPoint, currentValue) => {
    const newValue = pickValueFromObject(property, dataPoint);
    const isCurrentValueValid = (includeNull || currentValue !== null) && (includeUndefined || currentValue !== undefined);
    return isCurrentValueValid ? currentValue : newValue;
  };
};
