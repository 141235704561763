'use strict';

const Yup = require('yup');

const StaffFeeConfigSchema = Yup.object().shape({
  enterTimeAsUnits: Yup.bool(),
  timekeeperClassificationCode: Yup.string(),
  autoTimeIsEnabled: Yup.bool().nullable(true),
  autoTimeIsBillable: Yup.bool().nullable(true),
  sendAutoTimeDailyEmail: Yup.bool().nullable(true),
  staffId: Yup.string().nullable(true),
  estimatedAnnualCost: Yup.number().nullable(true),
  autoTimeExcludeClosedMatters: Yup.bool().nullable(true),
  autoTimeSetNotBillableSubsequentEmailReviews: Yup.bool().nullable(true),
  groupEmailsIgnoringSubject: Yup.bool().nullable(true),
  groupAutoTimeFees: Yup.bool().nullable(true),
  activityTypeSettings: Yup.array(
    Yup.object({
      activityType: Yup.number().nullable(true),
      activitySubType: Yup.string().nullable(true),
      exclude: Yup.bool().nullable(true),
      billableMinutes: Yup.number().nullable(true),
    }),
  ),
});

module.exports = {
  StaffFeeConfigSchema,
};
