/* eslint-disable no-restricted-syntax */
import React from 'react';
import classnames from 'classnames';
import { t } from '@sb-itops/localisation-web';
import { SlidingToggle, MultiSelectDropdown, MultiSelectCreatable, FormLabel } from '@sb-itops/react';
import { MatterTypeahead } from '@sb-matter-management/react';
import { FormData } from '@sb-itops/redux/forms2/use-form';
import { MatterSummary, Staff, Task } from 'types';
import { falsyGuard } from '@sb-itops/type-helpers';
import Styles from './AddTask.module.scss';

export type TSelectable = {
  label: string;
  value: string;
};

export type TContactSummary = {
  id: string;
  representativeOfs?: string[];
  type: string;
  display: string;
  displayFirst: string;
  typeahead: string;
  isDeleted?: boolean;
};

export interface IAddPhoneMessagetransitoryProps {
  setSelectedPersonContact: (c?: TContactSummary) => void;
  selectedPersonContact?: TContactSummary;
  selectedNonPersonContact?: TContactSummary;
  setSelectedNonPersonContact: (c?: TContactSummary) => void;
  personContacts?: TContactSummary[];
  nonPersonContacts?: TContactSummary[];
  urgent: boolean;
  setUrgent: (v: boolean) => void;
  pleaseCallBack: boolean;
  setPleaseCallBack: (v: boolean) => void;
  willCallAgain: boolean;
  setWillCallAgain: (v: boolean) => void;
  message: string;
  setMessage: (v: string) => void;
  availablePhoneNumbers: TSelectable[];
  selectedPhoneNumbers: TSelectable[];
  setSelectedPhoneNumbers: (numbers: TSelectable[]) => void;
}

export interface IAddPhoneMessageProps {
  fields: FormData<Task>['formFields'];
  assignableStaff: Array<Staff>;
  assignableMatters: Array<MatterSummary>;
  allowMatterSwitching?: boolean;
  loading?: boolean;
  submitFailed?: boolean;
  onFieldValueUpdated: FormData<Task>['onFieldValueSet'];
}

interface IMergedProps extends IAddPhoneMessageProps, IAddPhoneMessagetransitoryProps {}

// If we need to reuse this anywhere we should create a new component for it
export const AddPhoneMessage = ({
  assignableStaff,
  assignableMatters,
  allowMatterSwitching,
  loading,
  onFieldValueUpdated,
  submitFailed,
  fields,
  selectedPersonContact,
  setSelectedPersonContact,
  selectedNonPersonContact,
  setSelectedNonPersonContact,
  personContacts,
  nonPersonContacts,
  urgent,
  setUrgent,
  willCallAgain,
  setWillCallAgain,
  pleaseCallBack,
  setPleaseCallBack,
  message,
  setMessage,
  availablePhoneNumbers,
  selectedPhoneNumbers,
  setSelectedPhoneNumbers,
}: IMergedProps) => {
  const { matterId, assigneeIds } = fields;

  return (
    <div className={Styles.addTask}>
      <fieldset className={Styles.formFieldset} disabled={loading}>
        <div className="form-group">
          <FormLabel
            label="Assigned To"
            field={
              {
                key: 'assigneeIds',
                isInvalid: !assigneeIds.length,
                invalidReason: assigneeIds.length ? null : 'required',
              } as any
            }
            submitFailed={submitFailed}
          />
          {/* 
            Autofocus is desirable for modals so this eslint warning can be ignored
            eslint-disable-next-line jsx-a11y/no-autofocus
            */}
          <MultiSelectDropdown
            autoFocus
            hasError={submitFailed && !assigneeIds.length}
            options={assignableStaff.map((staff) => ({ label: staff.name, value: staff.id, searchText: staff.name }))}
            onSelectedIdsChanged={(selectedStaffIds) => {
              onFieldValueUpdated(
                'assigneeIds',
                selectedStaffIds.map((item) => item.value),
              );
            }}
            value={assigneeIds.map((assignedStaffId) => assignedStaffId.value)}
          />
        </div>

        <div className="row">
          <div className={classnames('col-xs-12 form-group', Styles.sliderRow)}>
            <div className={Styles.slider}>Flag message as Urgent</div>
            <SlidingToggle
              scope="flag-as-urgent"
              onChange={(key, value) => {
                setUrgent(value);
              }}
              selected={urgent}
            />
          </div>
        </div>

        <div className="form-group">
          <FormLabel label="Caller's Name" optional />
          <MultiSelectCreatable
            className={Styles.contactSelect}
            options={[selectedPersonContact, ...(personContacts || [])]
              .filter(falsyGuard)
              .map((c) => ({ label: c.display, value: c.id, typeahead: c.typeahead }))}
            onSelectedIdsChanged={(selected) => {
              // this means the selectable was cleared
              if (Array.isArray(selected) && selected.length === 0) {
                setSelectedPersonContact(undefined);
                return;
              }
              const actual = selected as TSelectable;
              const found = (personContacts || []).find((c) => c.id === actual.value);
              if (found) {
                // this was an existing contact
                setSelectedPersonContact(found);
              } else {
                // this was a new contact lets convert it
                setSelectedPersonContact({
                  id: actual.value,
                  display: actual.label,
                  type: 'Person',
                  displayFirst: actual.label,
                  typeahead: actual.label,
                });
              }
            }}
            isMulti={false}
            value={[selectedPersonContact && selectedPersonContact.id].filter(falsyGuard)}
          />
        </div>

        <div className="row">
          <div className={classnames('col-xs-12 form-group', Styles.sliderRow)}>
            <div className={Styles.slider}>Please call back</div>
            <SlidingToggle
              scope="please-call-back"
              onChange={(key, value) => {
                setPleaseCallBack(value);
              }}
              selected={pleaseCallBack}
            />
            <div className={Styles.slider}>Will call again</div>
            <SlidingToggle
              scope="will-call-again"
              onChange={(key, value) => {
                setWillCallAgain(value);
              }}
              selected={willCallAgain}
            />
          </div>
        </div>

        <div className="form-group">
          <FormLabel label={t('organisation')} optional />
          <MultiSelectCreatable
            className={Styles.contactSelect}
            options={[selectedNonPersonContact, ...(nonPersonContacts || [])]
              .filter(falsyGuard)
              .map((c) => ({ label: c.display, value: c.id, typeahead: c.typeahead }))}
            onSelectedIdsChanged={(selected) => {
              // this means the selectable was cleared
              if (Array.isArray(selected) && selected.length === 0) {
                setSelectedNonPersonContact(undefined);
                return;
              }
              const actual = selected as TSelectable;
              const found = (nonPersonContacts || []).find((c) => c.id === actual.value);
              if (found) {
                // this was an existing contact
                setSelectedNonPersonContact(found);
              } else {
                // this was a new contact lets convert it
                setSelectedNonPersonContact({
                  id: actual.value,
                  display: actual.label,
                  type: 'Person',
                  displayFirst: actual.label,
                  typeahead: actual.label,
                });
              }
            }}
            isMulti={false}
            value={[selectedNonPersonContact && selectedNonPersonContact.id].filter(falsyGuard)}
          />
        </div>

        <div className="form-group">
          <FormLabel label="Phone Number" optional />
          <MultiSelectCreatable
            options={[...availablePhoneNumbers, ...selectedPhoneNumbers]}
            onSelectedIdsChanged={(newSelection) => {
              setSelectedPhoneNumbers(newSelection as TSelectable[]);
            }}
            value={selectedPhoneNumbers.map((phNo) => phNo.value)}
          />
        </div>

        <div className="form-group">
          <FormLabel label="Matter" field={matterId} optional />
          {/* Dumb workaround for mattertypeahead which cant be reset, this will reset it after the form is submitted so save and new works */}
          {loading ? (
            <MatterTypeahead
              key={1}
              matterSummaries={assignableMatters}
              onValueChange={(newMatter) => onFieldValueUpdated('matterId', newMatter ? newMatter.id : '')}
              onClear={() => onFieldValueUpdated('matterId', '')}
              initialId={matterId?.value}
              filter="all"
              disabled={!allowMatterSwitching}
              hasError={matterId && matterId.isInvalid}
            />
          ) : (
            <MatterTypeahead
              key={2}
              matterSummaries={assignableMatters}
              onValueChange={(newMatter) => onFieldValueUpdated('matterId', newMatter ? newMatter.id : '')}
              onClear={() => onFieldValueUpdated('matterId', '')}
              initialId={matterId?.value}
              filter="all"
              disabled={!allowMatterSwitching}
              hasError={matterId && matterId.isInvalid}
            />
          )}
        </div>
        <div className="form-group">
          <FormLabel label="Message" optional />
          <div className={classnames(Styles.textAreaControl)}>
            <textarea
              className={classnames('form-control', Styles.textArea)}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={5}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};
