'use strict';

const getChargeErrorMessage = ({ defaultMessage, chargeStatusResult }) => {
  const { providerResponse: stripeResponse } = chargeStatusResult;

  const message = stripeResponse.type === 'StripeCardError' ? stripeResponse.message : defaultMessage;

  return { message };
};

module.exports = {
  getChargeErrorMessage,
};
