/* eslint-env browser */
'use strict';

const { regionType } = require('./region-type');
const { states, getStateNameFromId, getStateIdFromName, ALL_STATES } = require('./states');

const regionTLDs = [
  { regionId: regionType.AU, tld: '.com.au' },
  { regionId: regionType.US, tld: '.com' },
  { regionId: regionType.GB, tld: '.co.uk' },
];

const regionLocationIds = {
  [regionType.AU]: states[regionType.AU].map((location) => location.id),
  [regionType.US]: states[regionType.US].map((location) => location.id),
  [regionType.GB]: states[regionType.GB].map((location) => location.id),
}

const parseQueryString = (queryString) => {
  const objURL = {};
  queryString.replace('/', '').replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    objURL[$1.toUpperCase()] = $3;
  });
  return objURL;
};

const getRegion = (forcedRegion) => {
  // Force feature used by unit tests.
  if (forcedRegion) {
    return forcedRegion;
  }

  // tested dynamically in method call to prevent the global space being contaminated in tests
  const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
  
  // For node processes use the env variable.
  if (!isBrowser) {
    // ENDPOINT_LOCATION is used by endpoints, REGION is used by some lambdas
    return process.env.ENDPOINT_LOCATION || process.env.REGION;
  }
  
  // Check for querystring override of region.
  const qKeys = Object.keys(parseQueryString(window.location.search));
  const region = qKeys.find(q => regionType[q.toUpperCase()]);
  
  if (region) {
    return region;
  }
  
  // Local always defaults to US, other regions need to be set via override.
  const hostName = window.location.hostname;
  if (hostName.startsWith('local')) {
    return regionType.US;
  }

  if (hostName === 'au.billinglocal.net') {
    return regionType.AU;
  }

  if (hostName === 'us.billinglocal.net') {
    return regionType.US;
  }

  // Determine the region based on the hostname.
  const regionAndTLD = regionTLDs.find(regionAndTLD =>
    hostName.endsWith(regionAndTLD.tld)
  );

  if (regionAndTLD) {
    return regionAndTLD.regionId;
  } else {
    throw new Error(`Failed to determine region for hostname: ${hostName}`);
  }
};

// Returns an array of available location ids from the given region (case sensitive)
const getLocationIds = (region) => regionLocationIds[region];

module.exports = {
  regionType,
  states,
  getRegion,
  parseQueryString,
  getStateNameFromId,
  getStateIdFromName,
  getLocationIds,
  ALL_STATES,
};
