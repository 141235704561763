import { gql } from '@apollo/client';

const query = gql`
  query MicrosoftCalendars {
    microsoftCalendars {
      id
      name
      ownerName
      ownerEmailAddress
      isPublic
    }
  }
`;

const notificationIds = [
  'CalendarSyncSettingsNotifications.CalendarSettingsUpdated',
  'CalendarSyncSettingsNotifications.MicrosoftCalendarSyncSettingsUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const MicrosoftCalendars = {
  query,
  notificationIds,
};
