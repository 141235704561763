import { store } from '@sb-itops/redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import { getOpenPendingMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { FEE_MODAL_ID } from 'web/components';

angular.module('sb.billing.webapp').component('sbTimers', {
  bindings: {
    onNext: '<',
  },
  controller: 'SbTimersController',
  templateUrl: 'ng-components/timers/timers.html'
});

angular.module('sb.billing.webapp').controller('SbTimersController', function ($uibModal, $scope, sbUserTimerService, focusService, sbUuidService) {
  const that = this;

  $scope.$on('smokeball-typeahead-update-matter', setMatterDescriptions);

  that.view = {
    timers: _.cloneDeep(sbUserTimerService.getAllTimers()),
    matterTypeaheads: getOpenPendingMatterTypeaheadSummaries(),
  };

  that.obsoleteNowMessage = `Timers have now moved to the Smokeball Windows native app. You can still complete any current timers to have your time registered as per usual. Any new timers can now be started directly from the Smokeball Windows native app.`;

  setMatterDescriptions();

  let unsubscribeFromStore = store.subscribe(() => {
    const newMatterTypeaheads = getOpenPendingMatterTypeaheadSummaries();
    if (that.view.matterTypeaheads !== newMatterTypeaheads) {
      that.view.matterTypeaheads = newMatterTypeaheads;
      setMatterDescriptions();
    }
  });

  that.$onDestroy = () => {
    unsubscribeFromStore();
  };

  function setMatterDescriptions() {
    _.each(that.view.timers, function (timer) {
      setMatterDescription(timer);
    });
  }

  function setMatterDescription(timer) {
    var matterTa;

    if (timer.matterId) {
      matterTa = _.find(that.view.matterTypeaheads, function (tam) { return tam.id === timer.matterId; });
    }

    timer.display = (matterTa && matterTa.display) || '';
  }

  function pauseActiveTimer() {
    _.each(that.view.timers, function (timer) {
      if (timer.active) {
        delete timer.active;
        timer.elapsedSeconds = (timer.elapsedSeconds || 0) + (moment().unix() - timer.activeSinceUnix);
        delete timer.activeSinceUnix;
      }
    });
  }

  that.create = function () {
    var newTimer = { timerId: sbUuidService.get(), elapsedSeconds: 0, active: true, activeSinceUnix: moment().unix() };
    sbUserTimerService.createTimerP(newTimer.timerId);
    pauseActiveTimer();
    that.view.timers.push(newTimer);
  };

  that.delete = function (timerId) {
    sbUserTimerService.deleteTimerP(timerId);
    that.view.timers = that.view.timers.filter((timer) => timer.timerId !== timerId);
  };

  that.pause = function (timerId) {
    sbUserTimerService.pauseTimerP(timerId);

    const activeTimer = sbUserTimerService.getActiveTimer();
    if (!timerId || (activeTimer && activeTimer.timerId === timerId)) {
      pauseActiveTimer(timerId);
    }
  };

  that.resume = function (timerId) {
    var timer = _.find(that.view.timers, { timerId: timerId });
    sbUserTimerService.resumeTimerP(timerId);
    pauseActiveTimer();
    if (timer) {
      timer.active = true;
      timer.activeSinceUnix = moment().unix();
    }
  };

  that.setDescription = function (timerId, desc) {
    sbUserTimerService.updateTimerDescriptionP(timerId, desc || '');
  };

  that.setMatter = function (timerId, matterId) {
    var timer = _.find(that.view.timers, { timerId: timerId });
    sbUserTimerService.updateTimerMatterIdP(timerId, matterId || '');
    if (timer) {
      timer.matterId = matterId;
      setMatterDescription(timer);
    }
  };

  that.focusMatter = function (timerId) {
    var timer = _.find(that.view.timers, { timerId: timerId });
    if (timer) {
      if (timer.matterId) {
        focusService.focusOn(timer.timerId + '-timer-matter-clear-button');
      } else {
        focusService.focusOn(timer.timerId + '-timer-matter-field');
      }
    }
  };

  that.createEntry = function (timerId) {
    that.pause(timerId);
    showModal(timerId);
  };

  function showModal(timerId) {
    var timer = _.find(that.view.timers, { timerId });

    if (timer) {
      //tell the top level nav to close the popup that wraps this directive
      that.onNext();

      setModalDialogVisible({
        modalId: FEE_MODAL_ID,
        props: {
          scope: 'Timers/fee-modal',
          hideSaveAndNewButton: true,
          matterId: timer.matterId,
          prefilledDurationInMinutes: Math.round(timer.elapsedSeconds / 60),
          prefilledFeeDescription: timer.description,
          preventDurationFieldSyncUpdates: true,
          onFeeSave: () => that.delete(timerId)
        }
      });
    }
  }
});
