angular.module('sb.billing.webapp').component('sbEntryModalClickable', {
  'templateUrl': 'ng-components/entry-modal-clickable/entry-modal-clickable.html',
  'bindings': { entryType: '@?', matterId: '<?', entry: '<?', onClickBindContext: '<?', onClickBindName: '@?' },
  'transclude': true,
  'controller': function ($state, sbLoggerService, $uibModal) {
    'use strict';

    var ctrl = this;
    var log = sbLoggerService.getLogger('sbEntryModalClickable');

    ctrl.$onInit = function () {
      if (ctrl.onClickBindContext && ctrl.onClickBindName) {
        ctrl.onClickBindContext[ctrl.onClickBindName] = function (event, entry, entryTypeOverride, matterIdOverride) {
          ctrl.entry = entry;
          ctrl.openModal(event, entryTypeOverride, matterIdOverride);
        };
      }
    };

    ctrl.openModal = function ($event, entryTypeOverride = null, matterIdOverride = null) {
      var entryType = entryTypeOverride || ctrl.entryType, entry = ctrl.entry;

      log.info('loading entry : ', ctrl.entry);
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }

      if (entry) {
        entry = _.cloneDeep(entry);
        if (!entryType) {
          // entryType not specified via binding
          if (entry.feeId || entry.feeVersionId) {
            entryType = 'FEE';
          }
          if (entry.expenseId || entry.expenseVersionId) {
            entryType = 'EXPENSE';
          }
        }
      }

      entryType = entryType && entryType.toUpperCase();

      switch (entryType) {
        case 'FEE':
          log.warn('Legacy fee modal is no longer available'); // LOD only
          break;
        case 'EXPENSE':
          log.warn('Legacy expense modal is no longer available'); // LOD only
          break;
        case 'PAYMENT':
          openPaymentModal(entry, matterIdOverride || ctrl.matterId);
          break;
        default:
          log.warn('don\'t know how to deal with entryType: ', entryType);
      }
    };

    function openPaymentModal(entry, matterId) {
      var prefill;

      if (entry) {
        prefill = entry;
      }

      $uibModal.open({
        templateUrl: 'ng-components/payment-entry/modal/payment-view-modal.html',
        controller: 'SbPaymentViewModalController',
        resolve: {
          sbPaymentViewModalConfig: function () {
            return {
              preFill: prefill || matterId,
              features: {
                update: false,
                saveAndNew: false,
                state: $state.$current.name
              }
            };
          }
        },
        backdrop: 'static',
      });
    }

  }
});
