import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

export const api = cacheFactory({
  domain,
  name: 'operating-cheque-print-settings',
  keyPath: 'bankAccountId',
  ngCacheName: 'sbOperatingChequePrintSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
});
