angular.module('sb.billing.webapp').directive('sbActionRegenerateInvoice', function (sbGenericEndpointService, sbMessageDisplayService, sbDispatcherService, sbNotifiedOperationP, sbEnvironmentType, sbEnvironmentConfigService) {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      const regeneratePdfEndpoint = 'billing/invoice-pdf/regenerate';
      const PDFSyncMessage = 'PDFSyncNotification';
      const timeoutMs = _.includes([sbEnvironmentType.LOCAL, sbEnvironmentType.DEV], sbEnvironmentConfigService.getFrontendEnv()) ? 30000 : 10000;
      let invoice;

      ctrl.group.onInvoiceData(data => invoice = data);
      ctrl.group.setAction(regenerate);

      function regenerate() {
        // Invoice ID must be present.
        const invoiceId = _.get(invoice, 'invoiceId');

        if (_.isEmpty(invoiceId)) {
          sbMessageDisplayService.warn('Failed to regenerate invoice due to missing invoice ID.');
          throw new Error('Could not find invoice ID for regeneration');
        }

        ctrl.group.setLock(true);

        const regenerateFnP = () => sbGenericEndpointService.postPayloadP(regeneratePdfEndpoint, undefined, {invoiceId});

        return sbNotifiedOperationP(regenerateFnP, {
          completionNotification: PDFSyncMessage,
          completionFilterFn: message => message.invoiceId === invoiceId,
          timeoutMs
        })
        .catch(err => {
          sbMessageDisplayService.error('Failed to regenerate invoice, please try again later.');
          throw err;
        })
        .finally(() => ctrl.group.setLock(false));
      }
    }
  };
});
