const { getRegion, regionType } = require('@sb-itops/region');
// const { schemeMappings } = require('@sb-itops/region-schemes');
const { isAbbreviatedTitle } = require('./is-abbreviated-title');

const getContactNameGeneric = (contact) => {
  if (contact && contact.person && contact.person.title) {
    return `${contact.person.title} ${contact.entityName}`;
  }

  if (contact && contact.trust && contact.trust.fullTrustName) {
    return contact.trust.fullTrustName;
  }

  return contact.entityName;
};

const getContactNameUS = (contact) => {
  if (contact && contact.person && contact.person.title) {
    let title = contact.person.title;
    if (title && !title.endsWith('.') && isAbbreviatedTitle(title)) {
      title += '.';
    }
    return `${title} ${contact.entityName}`;
  }

  if (contact && contact.trust && contact.trust.fullTrustName) {
    return contact.trust.fullTrustName;
  }

  return contact.entityName;
};

const getContactNameStrategy = {
  [regionType.AU]: getContactNameGeneric,
  [regionType.GB]: getContactNameGeneric,
  [regionType.US]: getContactNameUS,
};

// Make any required changes to get the name of the entity
// so far only required for a person and if they have a title
function getContactName(contact) {
  if (!getContactNameStrategy[getRegion()]) {
    throw new Error('Unsupported region');
  }
  return getContactNameStrategy[getRegion()](contact);
}

module.exports = {
  getContactName,
};
