const hostedFieldsCollection = {};

const buildUniqueId = (publicKey) => publicKey;

export const registerHostedFields = (publicKey, hostedFields) => {
  const uniqueId = buildUniqueId(publicKey);

  if (!uniqueId) {
    return () => {};
  }

  if (hostedFieldsCollection[uniqueId]) {
    throw Error(
      'you are using an already assign hosted field identifier, check that you cleared any old hosted field before creating a new one',
    );
  }

  hostedFieldsCollection[uniqueId] = hostedFields;

  return hostedFieldsCollection[uniqueId];
};

export const unregisterHostedFields = (publicKey) => {
  const uniqueId = buildUniqueId(publicKey);

  if (!uniqueId) {
    return undefined;
  }

  if (!hostedFieldsCollection[uniqueId]) {
    throw Error(
      'you are unregistering an invalid hosted field, check that you cleared any old hosted field before creating a new one',
    );
  }

  delete hostedFieldsCollection[uniqueId];

  return undefined;
};

export const getHostedField = (publicKey) => {
  const uniqueId = buildUniqueId(publicKey);

  if (!uniqueId) {
    return undefined;
  }

  return hostedFieldsCollection[uniqueId];
};
