'use strict';

const { createLink } = require('@sb-itops/email');

const createPayNowLink = ({ url }) => {
  const payNowLink = createLink({
    label: 'PAY NOW',
    url,
  });
  return payNowLink;
};

module.exports = {
  createPayNowLink,
};
