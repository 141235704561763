import { isFinite, get, capitalize } from 'lodash';

import { getMap as getState } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { getMatterTrustBalanceByDate } from '@sb-billing/redux/transactions';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { getById as getEntityBankDetailsById } from '@sb-billing/redux/entity-bank-details';
import { getDefaultPaymentSource, getPaymentSources as getPaymentSourcesFromRedux } from 'web/redux/selectors/payment-source';
import {
  matterHasProtectedFundsForBankAccountId,
} from '@sb-billing/redux/balance-protection';
import { integerToDate } from '@sb-itops/date';
import uuid from '@sb-itops/uuid';
import { Cent } from '@sb-itops/money';
import { dateToInteger } from '@sb-itops/date';
import { isControlledMoniesAccount, getById as getBankAccountById, getOperatingAccount } from '@sb-billing/redux/bank-account';
import { roundCents } from '@sb-billing/bankers-rounding';
import { getSettings as getCMASettings } from '@sb-billing/redux/controlled-money-account-settings';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import {
  getNumberingSettings as getTransactionNumberingSettings,
} from '@sb-billing/redux/bank-account';
import { featureActive } from '@sb-itops/feature';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { sortByOrder } from '@sb-itops/nodash';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import { getByBankAccountId as getTrustChequesByBankAccountId, chequeExistsForBankAccount } from '@sb-billing/redux/trust-cheques';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  printMethods as printMethodsList,
  printMethodsByValue,
  PrintNow,
  PrintManually,
} from '@sb-billing/business-logic/cheques';
import { findLastChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import { DEPOSIT_FUNDS_MODAL_ID } from 'web/react-redux';
import { DEPOSIT_FUNDS_MODAL_ID as DEPOSIT_FUNDS_NEW_MODAL_ID } from 'web/components';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

const { getMatterBalance } = selectors;

const accountTypeToSourceName = {
  'TRUST': 'trustPaymentSource',
  'CONTROLLEDMONEY': 'cmaPaymentSource',
};

angular.module('sb.billing.webapp').component('sbCbuiVendorPaymentEntrySplitPayors', {
  templateUrl: 'ng-composable-components/callback-ui/vendor-payment-entry-split-payors/cbui-vendor-payment-entry-split-payors.html',
  bindings: {
    paidById: '<?',
    matterId: '<?',
    accountType: '<?',
    bankAccountId: '<?',
    callbackFn: '&',
    closeModal: '&',
    printCheque: '&'
  },
  controller: function ($state, focusService, sbLoggerService, sbPaymentDepositSources, sbLocalisationService, sbBankAccountService
  ) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbCbuiVendorPaymentEntrySplitPayors');

    ctrl.supportsElectronicPayment = hasFacet(facets.electronicPayment);
    ctrl.allowOverdraw = hasFacet(facets.allowOverdraw)
    ctrl.CMA = hasFacet(facets.CMA)
    ctrl.matterTrustBalanceByDate = hasFacet(facets.matterTrustBalanceByDate);
    ctrl.matterFieldSeparateRow = hasFacet(facets.matterFieldSeparateRow)
    ctrl.supportsBPAY = hasFacet(facets.BPAY);
    ctrl.supportsTrustPaymentBankTransferType = hasFacet(facets.trustPaymentBankTransferType);

    let showErrors = false;

    ctrl.sbData = {};
    ctrl.getBankAccountId = getBankAccountId;
    ctrl.getContactMatterBalanceEnabled = isMatterContactBalanceType;
    ctrl.prepField = prepField;
    ctrl.processVendorPayment = processVendorPayment;
    ctrl.clickCloseBtn = clickCloseBtn;
    ctrl.toggleAddContact = toggleAddContact;
    ctrl.getLastChequeNumber = getLastChequeNumber;
    ctrl.matterSelected = matterSelected;
    ctrl.isShowPaymentReason = isShowPaymentReason;
    ctrl.t = sbLocalisationService.t;

    ctrl.onSelectAccount = onSelectAccount;
    ctrl.onSelectPaidBy = onSelectPaidBy;
    ctrl.onSelectMatter = onSelectMatter;
    ctrl.onSaveContact = onSaveContact;
    ctrl.onPrintMethodUpdated = onPrintMethodUpdated;
    ctrl.onSelectPaidTo = onSelectPaidTo;
    ctrl.onChangeAmount = onChangeAmount;
    // ctrl.onPaidByChanged = onPaidByChanged;
    ctrl.onPaymentSource = onPaymentSource;
    ctrl.onPayorsUpdate = onPayorsUpdate;
    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.disableDate = disableDate;
    ctrl.isTrustChequesEnabled = isTrustChequesEnabled;
    ctrl.isStatutoryDepositMatter = () => sbBankAccountService.isStatutoryDepositMatter(ctrl.model.matterId);
    ctrl.showBankDetails = showBankDetails;
    ctrl.showTransferType = showTransferType;
    ctrl.showBpay = showBpay;
    ctrl.onShowDepositFundsModal = onShowDepositFundsModal;

    ctrl.$onInit = () => {
      if (!ctrl.bankAccountId) {
        ctrl.bankAccountId = ctrl.accountType === bankAccountTypeEnum.OPERATING ? getOperatingAccount().id : undefined;
      }

      log.info(`initialised, paidById ${ctrl.paidById}, matterId ${ctrl.matterId}, accountType ${ctrl.accountType}`);
      ctrl.accountType = ctrl.accountType.toUpperCase() || 'TRUST';
      ctrl.paymentType = ctrl.accountType.charAt(0).toUpperCase() + ctrl.accountType.slice(1).toLowerCase();

      const sourceName = accountTypeToSourceName[ctrl.accountType] ? accountTypeToSourceName[ctrl.accountType] : 'paymentDepositSource';

      const cmaSettings = getCMASettings() || {};
      const bankAccountSettings = getBankAccountSettings() || {};
     
      const generatePaymentPdfNow = ctrl.accountType === 'TRUST' ? bankAccountSettings.createPDFReceiptOnTrustPayment : cmaSettings.createPDFReceiptOnPayment;

      const bankAccounts = getBankAccountsForMatter(ctrl.matterId);

      ctrl.view = {
        bankAccounts,
        bankAccountOptions: bankAccounts.map((account) => ({ label: account.display, value: account.id })),
        paymentSources: [],
        source: sbPaymentDepositSources.get(sourceName), // cheque, bank transfer
        transferType: sbPaymentDepositSources.getBankTransferSources(sourceName),
        invalidBalance: false,
        addContactOpen: false,
        referenceReadOnly: false,
        chequePayableToBank: false,
        showBankDetailsTab: true,
        printMethods: printMethodsList,
        generatePaymentPdfNow,
        generateOperatingPaymentPdfNow: bankAccountSettings.createPDFReceiptOnOperatingPayment,
        minPaymentDate: undefined,
        maxPaymentDate: new Date(),
        balanceFieldLabel: ctrl.accountType === 'CONTROLLEDMONEY' ? `${ctrl.t('CMA')} Balance after payment`:'Available after payment'
      };

      const bankAccount = ctrl.bankAccountId ? getBankAccountById(ctrl.bankAccountId) : undefined;
      ctrl.view.minPaymentDate = (bankAccount && isControlledMoniesAccount(bankAccount.accountType)) ? integerToDate(bankAccount.accountOpenedDate) : undefined;

      ctrl.errors = {};

      ctrl.model = {
        account: ctrl.matterId ? bankAccount : undefined, // if we come from contact > transactions we have bankAccountId but not matterId - we DON'T want to autofill in this case
        amount: 0,
        date: new Date(),
        type: sbPaymentDepositSources.getDefaultDepositSource(ctrl.view.source) || ctrl.view.source[0],
        transferType: ctrl.view.transferType[0],
        printingMethod: ctrl.view.printMethods[2],
        reference: shouldGenerateReference()
          ? getNextChequeNumber(getTrustChequesByBankAccountId(ctrl.model.source.bankAccountId))
          : undefined,
        matterId: ctrl.matterId,
        paidById: ctrl.paidById,
        reason: undefined,
        contactAndMatterBalance: 0,
        effectiveDate: new Date(),
      };

      ctrl.view.referenceReadOnly = isReferenceReadOnly();
      if (ctrl.view.referenceReadOnly) {
        ctrl.model.reference = undefined;
      }

      syncPaymentSources();
      syncBalanceValue();
      log.info('initial model', ctrl.model);
    };

    ctrl.$postLink = () => {
      focus();
    };

    function onShowDepositFundsModal() {
      clickCloseBtn();
      if (featureActive('BB-14323')) {
        setModalDialogVisible({
          modalId: DEPOSIT_FUNDS_NEW_MODAL_ID,
          props: {
            scope: 'VendorPaymentEntrySplitPayors/deposit-funds-modal',
            matterId: ctrl.model.matterId,
            bankAccountId: ctrl.bankAccountId,
            contactId: ctrl.model.paidById,
          },
        });
      } else {
        setModalDialogVisible({
          modalId: DEPOSIT_FUNDS_MODAL_ID,
          props: { matterId: ctrl.model.matterId, bankAccountId: ctrl.bankAccountId, contactId: ctrl.model.paidById },
        });
      }
    }

    function getChequePrintSettings(trustBankAccountId) {
      return (getTrustChequePrintSettingsById(trustBankAccountId) || {});
    }

    function getBankAccountId() {
      if (ctrl.accountType === 'TRUST') {
        const acc = ctrl.model.account && ctrl.model.account.id ? ctrl.model.account.id : undefined;
        return acc;
      }
      // for CMA and Operating we can't change so return what is passed in prop
      return ctrl.bankAccountId;
    }

    function getBankAccountsForMatter(matterId) {
      let accounts = [];

      // Add Trust Accounts
      const trustAccounts = filterTrustAccountsByMatter(matterId);
      accounts = [...formatAccounts(trustAccounts)];

      return accounts;
    }

    function formatAccounts(accounts) {
      const formattedAccounts = accounts.map((account) => {
        return { ...account, display: getBankAccountName(account, sbLocalisationService.t) };
      });

      return sortByOrder(formattedAccounts, ['display'], ['asc']);
    }

    function matterSelected() {
      return !!(ctrl.model.matterId && ctrl.model.matterId.length > 0);
    }

    function isShowPaymentReason() {
      const isTrustPayment = ctrl.accountType === 'TRUST';
      // We show reason field in US for trust payment form and it's optional
      const isShowPaymentReason = hasFacet(facets.reasonField) || (hasFacet(facets.trustPaymentReasonField) && isTrustPayment);
      return isShowPaymentReason;
    }

    function isTrustElectronicPaymentEnabled() {
      const isAUBankTransfer = ctrl.accountType === 'TRUST'
        && ctrl.supportsElectronicPayment
        && ctrl.model.type.value === 'Bank transfer';

      if (!isAUBankTransfer) {
        return false;
      }

      if (!getBankAccountId()) {
        return false;
      }

      const { electronicPaymentNumberingSettings } = getTransactionNumberingSettings({
        bankAccountId: getBankAccountId(),
      });
      return !electronicPaymentNumberingSettings || !electronicPaymentNumberingSettings.useManualNumbering;
    }

    function isTrustChequesEnabled() {
      if (ctrl.accountType !== 'TRUST' || !ctrl.model || !ctrl.model.account || !ctrl.model.account.id) {
        return false;
      }

      return !!getChequePrintSettings(ctrl.model.account.id).printingActive;
    }

    function isCMAElectronicPaymentEnabled() {
      const isAUBankTransfer = ctrl.accountType === 'CONTROLLEDMONEY'
        && ctrl.supportsElectronicPayment
        && ctrl.model.type.value === 'Bank transfer';

      if (!isAUBankTransfer) {
        return false;
      }

      const { electronicPaymentNumberingSettings } = getCMASettings() || {};

      return !electronicPaymentNumberingSettings || !electronicPaymentNumberingSettings.useManualNumbering;
    }

    function dataChangeFunction(key, doc) {
      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }
    }

    /**
     * @typedef {Object<string, number>} Payor key is the payor id, value amount in cents
     */

    /**
     * @param {Array<Payor>} payors
     */
    function onPayorsUpdate(payors) {
      ctrl.model.payors = payors;
      const amount = Object.values(payors).reduce((acc, amount) => acc + (isFinite(amount) ? amount : 0), 0);
      onChangeAmount(amount);
    }

    function onPaymentSource(option) {
      if (option) {
        ctrl.model.source = option;
        if (!option.isCombinedBalance) {
          ctrl.model.paidById = option.contactId;
        } else {
          ctrl.model.paidById = null;
        }
        onChangeAmount(0);
      } else {
        ctrl.model.paidById = null;
        delete ctrl.model.source;
      }
    }

    function onChangeAmount(event) {
      const newAmount = typeof (event) === 'number' ? event : event.target.value;
      if (ctrl.model.amount !== newAmount) {
        ctrl.model.amount = Math.abs(newAmount);
        syncBalanceValue();
        prepField('amount');
      }
    }

    function onSelectAccount(bankAccountOption) {
      ctrl.model.account = bankAccountOption ? ctrl.view.bankAccounts.find(ba => ba.id === bankAccountOption.value) : null;
      syncPaymentSources();
      syncBalanceValue();
      prepField('account');
    }

    function getInitialCheckPrintMethod() {
      const printMethod = get(getChequePrintSettings(getBankAccountId()), 'printMethod');
      return printMethodsByValue[printMethod] || ctrl.view.printMethods[2];
    }

    function getPaymentSources() {
      return getPaymentSourcesFromRedux({
        accountType: ctrl.accountType,
        matterId: ctrl.model.matterId,
        bankAccountId: getBankAccountId(),
        t: sbLocalisationService.t,
        allowOverdraw: ctrl.allowOverdraw
      })
    }

    function isReferenceReadOnly() {
      if (ctrl.accountType === 'CONTROLLEDMONEY') {
        return isCMAElectronicPaymentEnabled();
      }

      const isReadyOnlyCheque = ctrl.accountType === 'TRUST'
        && (ctrl.model.type && ctrl.model.type.value.endsWith('Check'))
        && (ctrl.model.printingMethod && ctrl.model.printingMethod.value !== PrintManually)
        && isTrustChequesEnabled();

      return isReadyOnlyCheque || isTrustElectronicPaymentEnabled();
    }

    function toggleAddContact() {
      ctrl.view.addContactOpen = !ctrl.view.addContactOpen;
    }

    function getBalance() {
      const amount = Math.abs(ctrl.model.amount || 0);

      if (ctrl.CMA && isControlledMoniesAccount(ctrl.accountType)) {
        return new Cent(
          ctrl.model.contactAndMatterBalance - amount,
        ).dollars();
      }

      if (ctrl.matterTrustBalanceByDate && ctrl.model.matterId && ctrl.model.effectiveDate && getBankAccountId()) {
        const date = dateToInteger(ctrl.model.effectiveDate);
        const matterTrustBalanceByDate = getMatterTrustBalanceByDate(ctrl.model.matterId, date, getBankAccountId());
        return new Cent(
          matterTrustBalanceByDate - amount,
        ).dollars();
      }

      if (ctrl.model.matterId) {
        if (ctrl.matterTrustBalanceByDate && !ctrl.model.effectiveDate) {
          // If date is not valid, return undefined;
          return undefined;
        }
        // ctrl.model.contactAndMatterBalance is available balance, already takes into account protected balances
        const cents = roundCents((ctrl.model.contactAndMatterBalance || 0) - amount);
        return new Cent(cents).dollars();
      }
    }

    function syncBalanceValue() {
      const bankAccountId = getBankAccountId();
      const matterBalance = ctrl.model.matterId && bankAccountId ? getMatterBalance(getState(), { matterId: ctrl.model.matterId, bankAccountId }) : 0;

      ctrl.model.contactAndMatterBalance = matterBalance;
      ctrl.model.balance = getBalance();

      if (bankAccountId) {
        if (ctrl.allowOverdraw && !matterHasProtectedFundsForBankAccountId(ctrl.model.matterId, bankAccountId)) {
          // We allow matter and firm balances to be overdrawn in AU/GB for compliance
          ctrl.model.insufficientFunds = false;
        } else {
          ctrl.model.insufficientFunds = ctrl.model.matterId && matterBalance <= 0;
        }
      } else {
        ctrl.model.insufficientFunds = false;
      }
    }

    function syncPaymentSources() {
      ctrl.view.paymentSources = getPaymentSources();
      ctrl.model.printingMethod = getInitialCheckPrintMethod();

      if (ctrl.model.matterId && (ctrl.accountType !== bankAccountTypeEnum.TRUST || (ctrl.accountType === bankAccountTypeEnum.TRUST && getBankAccountId()))) {
        onPaymentSource(getDefaultPaymentSource(getPaymentSources()));
      } else {
        onPaymentSource();
      }
    }

    function prepopulatePaidToFieldsIfElectronicPaymentEnabled() {
      if (!ctrl.supportsElectronicPayment) return;

      const paidToContact = getContactById(ctrl.model.paidToId);
      const paidToContactBankDetails = getEntityBankDetailsById(ctrl.model.paidToId) || {};

      const paidToContactName = paidToContact ? paidToContact.displayName : '';
      const { accountName = '', bankName = '', bankBranchNumber = '', accountNumber = '' } = paidToContactBankDetails;

      if ((ctrl.model.type.value === 'Bank transfer' && !ctrl.showBpay() || isDirectDebitActive())) {
        ctrl.model.bankAccountName = accountName;
        ctrl.model.bankBranchNumber = bankBranchNumber;
        ctrl.model.bankAccountNumber = accountNumber;
        prepField('bankAccountName');
        prepField('bankBranchNumber');
        prepField('bankAccountNumber');
      } else if (ctrl.model.type.value.endsWith('Check')) {
        ctrl.model.payeeName = paidToContactName;
        ctrl.model.bankName = bankName;
        ctrl.model.bankBranch = bankBranchNumber;
        prepField('payeeName');
        prepField('bankName');
        prepField('bankBranch');
      }
    }

    function shouldGenerateReference() {
      return (
        ctrl.accountType === 'TRUST' &&
        isTrustChequesEnabled() &&
        ctrl.model &&
        ctrl.model.type &&
        ctrl.model.type.value === 'Trust Check' &&
        get(ctrl, 'model.printingMethod.value') === PrintManually
      );
    }

    function onPrintMethodUpdated() {
      ctrl.view.referenceReadOnly = isReferenceReadOnly();
      ctrl.model.reference = ctrl.view.referenceReadOnly || !shouldGenerateReference()
        ? undefined
        : getNextChequeNumber(getTrustChequesByBankAccountId(ctrl.model.source.bankAccountId)) || '';

      const printMethod = ctrl.model.printingMethod.value;
      ctrl.isChequeMemoVisible =
        printMethod === PrintNow || printMethod === PrintManually;

      prepField('reference');
      prepopulatePaidToFieldsIfElectronicPaymentEnabled();
    }

    function onSelectPaidTo() {
      prepField('paidto');
      prepopulatePaidToFieldsIfElectronicPaymentEnabled();
    }

    function onSaveContact(contactId) {
      ctrl.model.paidToId = contactId;
      ctrl.view.addContactOpen = false;
      prepField('paidto');
      prepopulatePaidToFieldsIfElectronicPaymentEnabled();
    }

    function onSelectPaidBy(option) {
      onPaymentSource(option);
      prepField('paidBy');
      if (!ctrl.model.matterId) {
        focusService.focusOn('vendor-payment-matter');
      } else {
        focusService.focusOn('vendor-payment-source');
      }
    }

    function onSelectMatter(matter) {
      ctrl.model.matterId = matter && matter.id;
      prepField('matter');
      focusService.focusOn('vendor-payment-source');

      const bankAccounts = getBankAccountsForMatter(ctrl.model.matterId);

      ctrl.view.bankAccounts = bankAccounts;
      ctrl.view.bankAccountOptions = bankAccounts.map((account) => ({ label: account.display, value: account.id }));

      if (!ctrl.model.matterId) {
        ctrl.model.account = null;
      } else if (ctrl.model.matterId && ctrl.model.account && ctrl.view.bankAccounts) {
        if (!ctrl.view.bankAccounts.find((account) => account.id === getBankAccountId())) {
          ctrl.model.account = null;
        }
      }

      syncPaymentSources();
      syncBalanceValue();
    }

    function chequeNumberUsed() {
      if (get(ctrl, 'model.printingMethod.value') === PrintManually &&
        get(ctrl, 'model.type.value').endsWith('Check')) {
        const chequeNumberStr = ctrl.model.reference;
        return isFinite(+chequeNumberStr) && chequeExists(chequeNumberStr);
      }
      return false;
    }

    function chequeExists(chequeNumberStr) {
      if (!ctrl.model || !ctrl.model.account || !ctrl.model.account.id) {
        return false;
      }
      return chequeExistsForBankAccount(chequeNumberStr, ctrl.model.account.id)
    }

    function prepField(name) {
      log.debug('prepField', name);
      const isTrust = ctrl.accountType.toUpperCase() === 'TRUST';
      showErrors = true;

      switch (name.toUpperCase()) {
        case 'ACCOUNT':
          ctrl.errors.account = isTrust && !(ctrl.model.account && ctrl.model.account.id);
          break;
        case 'MATTER':
          ctrl.errors.matter = !ctrl.model.matterId && showErrors;
          syncBalanceValue();
          break;
        case 'PAIDBY':
          if (ctrl.getContactMatterBalanceEnabled()) {
            ctrl.errors.paidBy = !ctrl.model.paidById && !(ctrl.model.source && ctrl.model.source.isCombinedBalance) && showErrors;
          }
          break;
        case 'PAIDTO':
          ctrl.errors.paidTo = !ctrl.model.paidToId && showErrors;
          break;
        case 'EFFECTIVEDATE':
          ctrl.errors.effectiveDate = !ctrl.model.effectiveDate;
          delete ctrl.errors.reconciled;
          if (isTrust && ctrl.model.account && ctrl.model.account.id) {
            ctrl.errors.reconciled = isReconciled({ yyyymmdd: dateToInteger(ctrl.model.effectiveDate), trustAccountId: ctrl.model.account.id });
          }
          syncBalanceValue();
          prepField('amount');
          break;
        case 'AMOUNT': {
          const amount = Math.abs(Math.trunc(ctrl.model.amount));
          let overdrawsAvailableBalance = false;
          // AU/GB firms without protected funds can overdraw
          const canOverdrawBalance = ctrl.allowOverdraw && (!featureActive('BB-8671') || !matterHasProtectedFundsForBankAccountId(ctrl.model.matterId, getBankAccountId()));

          if (!canOverdrawBalance) {
            overdrawsAvailableBalance = isMatterContactBalanceType() ?
              amount > ((ctrl.model.source && ctrl.model.source.balance) || 0) :
              amount > ctrl.model.contactAndMatterBalance;
          }
          else {
            syncBalanceValue();
          }

          const amountNotValid = !amount || amount < 0;

          if (!canOverdrawBalance) {
            ctrl.errors.amount = showErrors && (amountNotValid || overdrawsAvailableBalance);
            if (featureActive('BB-8671') && matterHasProtectedFundsForBankAccountId(ctrl.model.matterId, getBankAccountId())) {
              // Use a different error key here to as the overdraw warning will show inline already
              ctrl.errors.exceedsAvailableBalance = overdrawsAvailableBalance;
            } else {
              ctrl.errors.overdrawsAvailableBalance = overdrawsAvailableBalance;
            }
          }

          // Only valid case is number greater than 0
          if (canOverdrawBalance) {
            ctrl.errors.amount = showErrors && (amountNotValid || Number.isNaN(amount));
          }

          break;
        }
        case 'REFERENCE':
          if (ctrl.accountType === "CONTROLLEDMONEY") {
            // if auto numbering is not enabled for CMA, reference is required for both Bank Transfer and Bank Cheque
            const referenceRequired = !isCMAElectronicPaymentEnabled();
            ctrl.errors.referenceEmpty = showErrors && referenceRequired && (ctrl.model.reference === '' || ctrl.model.reference === undefined);
            ctrl.errors.reference = showErrors && ctrl.errors.referenceEmpty;
            break;
          }

          if (isTrust && isTrustChequesEnabled()) {
            const referenceRequired = get(ctrl, 'model.type.value').endsWith('Check') && get(ctrl, 'model.printingMethod.value') === PrintManually;

            ctrl.errors.referenceEmpty = showErrors && referenceRequired && (ctrl.model.reference === '' || ctrl.model.reference === undefined);
            ctrl.errors.referenceNumber = showErrors && referenceRequired && !ctrl.errors.referenceEmpty && !isFinite(+ctrl.model.reference);
            ctrl.errors.chequeNumberInUse = showErrors && !ctrl.errors.referenceEmpty && chequeNumberUsed();
            ctrl.errors.reference = showErrors && (ctrl.errors.referenceEmpty || ctrl.errors.referenceNumber || ctrl.errors.chequeNumberInUse);
          }
          break;
        case 'BANKACCOUNTNAME':
          if (ctrl.showBankDetails() && !ctrl.showBpay()) {
            ctrl.errors.bankAccountName = !ctrl.model.bankAccountName && showErrors;
          } else {
            ctrl.errors.bankAccountName = false;
          }
          break;
        case 'BANKBRANCHNUMBER':
          if (ctrl.showBankDetails() && !ctrl.showBpay()) {
            ctrl.errors.bankBranchNumber = !ctrl.model.bankBranchNumber && showErrors;
          } else {
            ctrl.errors.bankBranchNumber = false;
          }
          break;
        case 'BANKACCOUNTNUMBER':
          if (ctrl.showBankDetails() && !ctrl.showBpay()) {
            ctrl.errors.bankAccountNumber = !ctrl.model.bankAccountNumber && showErrors;
          } else {
            ctrl.errors.bankAccountNumber = false;
          }
          break;
        case 'BANKNAME':
          if (ctrl.supportsElectronicPayment && ctrl.model.type.value.endsWith("Check")
            && ctrl.view.chequePayableToBank) {
            ctrl.errors.bankName = !ctrl.model.bankName && showErrors;
          } else {
            ctrl.errors.bankName = false;
          }
          break;
        case 'BANKBRANCH':
          if (ctrl.supportsElectronicPayment && ctrl.model.type.value.endsWith("Check")
            && ctrl.view.chequePayableToBank) {
            ctrl.errors.bankBranch = !ctrl.model.bankBranch && showErrors;
          } else {
            ctrl.errors.bankBranch = false;
          }
          break;
        case 'TRANSFERTYPE':
          if (ctrl.supportsTrustPaymentBankTransferType && ctrl.model.type.value.endsWith("Bank Transfer") && ctrl.accountType === 'TRUST') {
            ctrl.errors.transferType = !ctrl.model.transferType && showErrors;
          } else {
            ctrl.errors.transferType = false;
          }
          break;
        case 'ORGCOMPANYNAME':
          if (ctrl.showBankDetails() && ctrl.showBpay()) {
            ctrl.errors.orgCompanyName = !ctrl.model.orgCompanyName && showErrors;
          } else {
            ctrl.errors.orgCompanyName = false;
          }
          break;
        case 'BILLERCODE':
          if (ctrl.showBankDetails() && ctrl.showBpay()) {
            ctrl.errors.billerCode = !ctrl.model.billerCode && showErrors;
          } else {
            ctrl.errors.billerCode = false;
          }
          break;
        case 'BPAYREFERENCE':
          if (ctrl.showBankDetails() && ctrl.showBpay()) {
            ctrl.errors.bpayReference = !ctrl.model.bpayReference && showErrors;
          } else {
            ctrl.errors.bpayReference = false;
          }
          break;
        case 'PAYEENAME':
          if (ctrl.supportsElectronicPayment && ctrl.model.type.value.endsWith("Check")
            && ctrl.view.chequePayableToBank) {
            ctrl.errors.payeeName = !ctrl.model.payeeName && showErrors;
          } else {
            ctrl.errors.payeeName = false;
          }
          break;
        case 'REASON':
          if (hasFacet(facets.reasonField)) {
            ctrl.errors.reason = !ctrl.model.reason && showErrors;
          } else {
            ctrl.errors.reason = false;
          }
      }

      log.debug('errors', ctrl.errors);
    }

    function focus() {
      if (!ctrl.model.matterId) {
        focusService.focusOn('vendor-payment-matter');
      } else if (!ctrl.model.amount) {
        focusService.focusOn('vendor-payment-amount');
      } else if (!ctrl.model.paidById) {
        focusService.focusOn('vendor-payment-paid-by');
      }
    }

    function getLastChequeNumber() {
      if (!ctrl.model || !ctrl.model.account || !ctrl.model.account.id) {
        return undefined;
      }
      return findLastChequeNumber(getTrustChequesByBankAccountId(ctrl.model.account.id));
    }

    function isValid() {
      showErrors = true;
      prepField('account');
      prepField('matter');
      prepField('paidBy');
      prepField('paidTo');
      prepField('amount');
      prepField('effectiveDate');
      prepField('reference');
      if (ctrl.supportsElectronicPayment) {
        prepField('bankAccountName');
        prepField('bankBranchNumber');
        prepField('bankAccountNumber');
        prepField('bankName');
        prepField('bankBranch');
        prepField('payeeName');
        prepField('transferType');
        prepField('billerCode');
        prepField('orgCompanyName');
        prepField('bpayReference');
      }
      prepField('reason');
      const invalid = Object.keys(ctrl.errors).some((key) => ctrl.errors[key]);
      log.info('is valid?', !invalid, ctrl.errors);
      return !invalid;
    }

    async function processVendorPayment() {
      if (ctrl.isStatutoryDepositMatter()) {
        ctrl.model.paidById = sbBankAccountService.getFirmContact().entityId;
      }

      if (isValid()) {
        const data = marshal();
        // console.log('payment', JSON.stringify(data, null, 2));
        await ctrl.callbackFn({ data, log });
        if (shouldPrintCheques(data)) {
          await ctrl.printCheque({ data });
        }
        printVendorProofOfPayment(data);
      } else {
        focus();
      }
    }

    function shouldPrintCheques(payment) {
      return ctrl.accountType === 'TRUST' && get(ctrl, 'model.type.value').endsWith('Check') && isTrustChequesEnabled() && payment.chequePrintMethod === PrintNow;
    }

    async function printVendorProofOfPayment(payment) {
      // If we need the receipt to have the reference generated or not we need to force it in the endpoint.
      // I know this is wrong, but it is the only way we have right now to force the receipt to regenerate if the reference is not present.
      // this should have been handled by the UI but with the implementation how it is we can't. This will need to be reviewed.
      const withChequeNumbering = payment.chequePrintMethod === PrintNow;

      // For contact balances the paymentId and transaction Id are picked from the payors.
      if (isMatterContactBalanceType()) {
        for (let i = 0; i < payment.payors.length; i++) {
          const payor = payment.payors[i];
          if (payment.printPaymentProof) {
            await $state.go('home.billing.vendor-proof-of-payment', {
              transactionId: payor.transactionId,
              paymentId: payor.paymentId,
              // only pass down the chequeId when we need to wait for the reference
              chequeId: withChequeNumbering ? payment.chequeId : undefined,
            });
          }
        }
      } else {
        if (payment.printPaymentProof) {
          $state.go('home.billing.vendor-proof-of-payment', {
            transactionId: payment.transactionId,
            paymentId: payment.paymentId,
            // only pass down the chequeId when we need to wait for the reference
            chequeId: withChequeNumbering ? payment.chequeId : undefined,
          });
        }
      }
    }

    function getPayors() {
      return ctrl.model.source.isCombinedBalance
        ? Object.keys(ctrl.model.payors) // { [payorId]: amountInCents }
          .reduce((payors, payorId) => {
            if (ctrl.model.payors[payorId]) {
              payors.push({
                payorId,
                amount: ctrl.model.payors[payorId],
                paymentId: uuid(),
                transactionId: uuid(),
              });
            }
            return payors;
          }, [])
        : [{
          payorId: ctrl.model.source.contactId,
          amount: ctrl.model.amount,
          paymentId: uuid(),
          transactionId: uuid(),
        }];
    }

    function getAmount() {
      return ctrl.model.source.isCombinedBalance ? Object.values(ctrl.model.payors).reduce((sum, amount) => sum + (Number.isFinite(amount) ? amount : 0), 0) : ctrl.model.amount;
    }

    function marshal() {
      const isElectronicPayment = isTrustElectronicPaymentEnabled() || isCMAElectronicPaymentEnabled();

      const payment = {
        accountType: capitalize(ctrl.accountType.toLowerCase()),
        bankAccountId: getBankAccountId(),
        amount: isMatterContactBalanceType() ? getAmount() : ctrl.model.amount,
        payeeId: ctrl.model.paidToId,
        matterId: ctrl.model.matterId,
        description: `${ctrl.model.type.value} vendor payment`,
        note: ctrl.model.note,
        source: ctrl.model.type.value,
        reason: ctrl.model.reason,
        reference: isElectronicPayment ? '' : ctrl.model.reference,
        chequePrintMethod: ctrl.model.printingMethod.value,
        effectiveDate: dateToInteger(ctrl.model.effectiveDate),
        isElectronicPayment,
        // For contact balances the paymentId and transaction Id are picked from the payors.
        paymentId: !isMatterContactBalanceType() ? uuid() : null,
        transactionId: !isMatterContactBalanceType() ? uuid() : null,
      };

      if (ctrl.getContactMatterBalanceEnabled()) {
        payment.payors = getPayors();
      }

      if (shouldPrintCheques(payment)) {
        payment.chequeId = uuid();
      }

      if (ctrl.supportsElectronicPayment) {
        if (ctrl.model.type.value === 'Bank transfer' || isDirectDebitActive()) {
          if (ctrl.showBpay()) {
            payment.billerCode = ctrl.model.billerCode;
            payment.orgCompanyName = ctrl.model.orgCompanyName;
            payment.bpayReference = ctrl.model.bpayReference;
            // Enum DirectDeposit = 0, Bpay = 1
            payment.bankTransferType = ctrl.view.transferType.indexOf(ctrl.model.transferType);
          } else {
            payment.bankAccountName = ctrl.model.bankAccountName;
            payment.bankBranchNumber = ctrl.model.bankBranchNumber;
            payment.bankAccountNumber = ctrl.model.bankAccountNumber;
          }
        }

        if (ctrl.model.type.value.endsWith("Check") && ctrl.view.chequePayableToBank) {
          payment.bankName = ctrl.model.bankName;
          payment.bankBranch = ctrl.model.bankBranch;
          payment.payeeName = ctrl.model.payeeName;
        }
      }

      if (ctrl.allowOverdraw) {
        payment.allowOverdraw = true;
      }

      payment.chequeMemo = ctrl.isChequeMemoVisible ? ctrl.model.chequeMemo : undefined;

      ['TRUST', 'CONTROLLEDMONEY'].includes(ctrl.accountType)
        ? payment.printPaymentProof = ctrl.view.generatePaymentPdfNow
        : payment.printPaymentProof = ctrl.view.generateOperatingPaymentPdfNow;

      return payment;
    }

    function clickCloseBtn() {
      ctrl.closeModal();
    }

    function disableDate() {
      return ctrl.model.amount < 0;
    }

    function isDirectDebitActive() {
      return featureActive('BB-5948') && ctrl.model && ctrl.model.type && ctrl.model.type.value === 'Direct Debit';
    }

    function showBankDetails() {
      return ctrl.supportsElectronicPayment && ['TRUST', 'CONTROLLEDMONEY'].includes(ctrl.accountType) && (ctrl.model.type.value === 'Bank transfer' || isDirectDebitActive());
    }

    function showTransferType() {
      return ctrl.supportsTrustPaymentBankTransferType && ctrl.supportsElectronicPayment && ctrl.accountType === 'TRUST' && ctrl.model && ctrl.model.type && ctrl.model.type.value === 'Bank transfer';
    }

    function showBpay() {
      return ctrl.supportsBPAY && showTransferType() && ctrl.model.transferType && ctrl.model.transferType.value === 'BPAY';
    }
  }
});
