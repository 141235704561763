import React from 'react';
import { Button } from '@sb-itops/react/button';
import { Modal } from '@sb-itops/react/modal';
import * as Styles from './DeleteMemoConfirmationModal.module.scss';

const DeleteMemoConfirmationModal = ({
  isVisible,
  onConfirm,
  onClose,
  actionType,
}: {
  isVisible: boolean;
  actionType: string;
  onConfirm: () => void;
  onClose: () => void;
}) => (
  <Modal
    title={`${actionType} Memo`}
    body={
      <div>
        <p>Are you sure you want to {actionType.toLowerCase()} this memo?</p>
      </div>
    }
    footer={
      <div className={Styles.footer}>
        <Button className={Styles.footerBtn} onClick={onConfirm}>
          {actionType}
        </Button>
        <Button className={Styles.footerBtn} type="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    }
    isVisible={isVisible}
    onModalClose={onClose}
    shouldCloseOnOverlayClick={false}
  />
);

export { DeleteMemoConfirmationModal };
