import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import SendViaCommunicateConfirmModal from './SendViaCommunicateConfirmModal';

const SendViaCommunicateConfirmModalContainer = ({
  showSendViaCommunicateConfirmModal,
  setShowSendViaCommunicateConfirmModal,
  doNotShowSendConfirmModalAgain,
  onDoNotShowSendViaCommunicateConfirmModalAgain,
  setShowCommunicateModal,
}) => {
  const [doNotShowAgainChecked, setDoNotShowAgainChecked] = useState(false);
  // Determine if the confirm send via communicate dialog should be shown to this user.
  const isVisible = !doNotShowSendConfirmModalAgain && showSendViaCommunicateConfirmModal;

  const closeDialog = () => {
    setShowSendViaCommunicateConfirmModal(false);
  };

  const handleDoNotShowAgainCheckbox = () => {
    setDoNotShowAgainChecked(!doNotShowAgainChecked);
  };

  const onSend = () => {
    if (doNotShowAgainChecked) {
      // Take action when the user click do not show again.
      onDoNotShowSendViaCommunicateConfirmModalAgain();
    }
    setShowSendViaCommunicateConfirmModal(false);
    setShowCommunicateModal(true);
  };

  return (
    <SendViaCommunicateConfirmModal
      isVisible={isVisible}
      onClose={closeDialog}
      onSend={onSend}
      handleDoNotShowAgainCheckbox={handleDoNotShowAgainCheckbox}
      doNotShowAgainChecked={doNotShowAgainChecked}
    />
  );
};

SendViaCommunicateConfirmModalContainer.displayName = 'SendViaCommunicateConfirmModal';
SendViaCommunicateConfirmModalContainer.propTypes = {
  showSendViaCommunicateConfirmModal: propTypes.bool.isRequired,
  doNotShowSendConfirmModalAgain: propTypes.bool.isRequired,
  setShowSendViaCommunicateConfirmModal: propTypes.func.isRequired,
  onDoNotShowSendViaCommunicateConfirmModalAgain: propTypes.func.isRequired,
  setShowCommunicateModal: propTypes.func.isRequired,
};
SendViaCommunicateConfirmModalContainer.defaultProps = {};

export default withReduxProvider(SendViaCommunicateConfirmModalContainer);
