import { regionType } from '@sb-itops/region';

type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const getFirstDayOfWeek = (region: string): FirstDayOfWeek => {
  const sunDay = 0;
  const monDay = 1;
  const firstDayOfWeek = {
    [regionType.AU]: monDay,
    [regionType.GB]: monDay,
    [regionType.US]: sunDay,
  };

  return firstDayOfWeek[region] as FirstDayOfWeek;
};
