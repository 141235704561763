import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/recent-invoices';

angular.module('@sb-billing/services').service('sbRecentInvoiceService', function(sbGenericCacheService, sbLoggerService, sbEndpointType) {
  'use strict';

  sbGenericCacheService.setupCache({
    name: 'sbRecentInvoiceService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/recent-invoices' },
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.RecentInvoicesUpdated'
    },
    updateRedux,
    clearRedux,
  });
});
