import { Maybe, TMandatory } from '../types';

export const falsyGuard = <T>(thing: Maybe<T>): thing is T => !!thing;

export const nonNullishFieldsGuardFactory =
  <K extends string>(fields: K[]) =>
  <T>(thing: Maybe<T>): thing is TMandatory<T, K> => {
    if (!thing) {
      return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const f of fields) {
      const val = thing[f as unknown as keyof T];
      if (val === undefined || val === null) {
        return false;
      }
    }

    return true;
  };

export const nonNullishIdGuard = nonNullishFieldsGuardFactory(['id']);
