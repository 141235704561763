import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uuid from '@sb-itops/uuid';
import { Typeahead } from '../typeahead.2';
import styles from './MultiSelectTypeahead.module.scss';

let unprotectedDragIndex = 0;

// global object workaround for trash DnD spec that doesnt allow you to identify the origin of your DnD events
const dragData = {};

const MultiSelectTypeahead = ({
  selectedIds,
  options,
  max,
  className,
  placeholder,
  enableReordering,
  disabled,
  isRequired,
  menuPlacement,
  maxMenuHeight,
  // callbacks and functions
  onSelectedIdsChanged,
}) => {
  // Used to prevent dragover affecting other elements
  const [elementId] = useState(uuid());
  // internal state to keep track of cleared item that should be auto focused on
  const [indexToFocus, setIndexToFocus] = useState(undefined);

  // construct item options map to enable faster lookup
  const optionsMap = options.reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});

  const updateSelectedIdsIfChanged = (newSelectedIds) => {
    if (
      newSelectedIds.length !== selectedIds.length ||
      !newSelectedIds.every((id, index) => id === selectedIds[index])
    ) {
      onSelectedIdsChanged(newSelectedIds.filter((id) => id));
    }
  };

  // one of the subtle use case here is user can clear an item selection, when they do we
  // still need to render this cleared selection, thus selectedIds can be undefined
  const selectedIdsNonEmpty = selectedIds.filter((id) => id);
  const noItemSelected = selectedIdsNonEmpty.length === 0;

  const onChange = (selectedIndex, selectedOption) => {
    const newSelectedIds = [...selectedIds];

    // The selection of a duplicate should update the position rather than insert a duplicate
    const existingIdIndex = newSelectedIds.indexOf(selectedOption?.value);
    if (selectedOption && existingIdIndex !== -1) {
      newSelectedIds[existingIdIndex] = '';
    }
    newSelectedIds[selectedIndex] = selectedOption?.value;

    // improves UX by focusing on cleared item so user can start typing to find the right item
    if (!selectedOption) {
      setIndexToFocus(selectedIndex);
    }
    updateSelectedIdsIfChanged(newSelectedIds);
  };

  const moveIndex = (indexToMove, targetIndex) => {
    const reorderedIds = [...selectedIds];

    // In the case where an item is moved to replace the pseudo item at the end of the list, do nothing
    if (targetIndex + 1 > reorderedIds.length) {
      return;
    }
    reorderedIds.splice(indexToMove, 1);
    reorderedIds.splice(targetIndex, 0, selectedIds[indexToMove]);
    updateSelectedIdsIfChanged(reorderedIds);
    unprotectedDragIndex = targetIndex;
  };

  const divRef = React.useRef([]);

  return (
    <>
      {selectedIds
        .concat([''])
        .slice(0, max)
        .map((selectedId, index) => {
          // this look up is required in order to set a default selection
          const selectedOption = optionsMap[selectedId];

          return (
            <div
              className={styles.wrapper}
              key={`select-${index}`}
              id={`select-${index}`}
              ref={(el) => {
                divRef.current[index] = el;
              }}
              onDragOver={(event) => {
                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'move';
                if (index !== unprotectedDragIndex && dragData[elementId]) {
                  moveIndex(unprotectedDragIndex, index);
                }
              }}
              onDragStart={(event) => {
                // Unprotected var used as event data is not availabile during dragover
                unprotectedDragIndex = index;
                event.stopPropagation();
                dragData[elementId] = true;
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'move';
              }}
              onDragEnd={() => {
                if (dragData[elementId]) {
                  divRef.current[index].setAttribute('draggable', 'false');
                  dragData[elementId] = false;
                }
              }}
            >
              <div className={classnames(styles.field, className)}>
                {enableReordering && max > 1 && (
                  <i
                    onMouseDown={() => {
                      // Required to prevent other children triggering drag events
                      divRef.current[index].setAttribute('draggable', 'true');
                    }}
                    onMouseUp={() => {
                      // Required to prevent other children triggering drag events
                      divRef.current[index].setAttribute('draggable', 'false');
                    }}
                    className={classnames(styles.dragElement, 'icon', 'icon-grab-handle')}
                  />
                )}
                <Typeahead
                  options={options}
                  disabled={disabled}
                  selectedOption={selectedOption}
                  onSelect={(option) => onChange(index, option)}
                  placeholder={placeholder}
                  className={styles.select}
                  containerClassName={isRequired && noItemSelected && index === 0 ? styles.hasError : undefined}
                  menuPlacement={menuPlacement}
                  maxMenuHeight={maxMenuHeight}
                  autoFocus={index === indexToFocus}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

MultiSelectTypeahead.displayName = 'MultiSelectTypeahead';

MultiSelectTypeahead.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ).isRequired,
  max: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  enableReordering: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  // callbacks & functions
  onSelectedIdsChanged: PropTypes.func.isRequired,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  maxMenuHeight: PropTypes.number,
  onClickLink: PropTypes.func,
};

MultiSelectTypeahead.defaultProps = {
  selectedIds: [],
  max: 3,
  className: undefined,
  placeholder: 'Select',
  enableReordering: true,
  disabled: false,
  isRequired: true,
  menuPlacement: 'bottom',
  maxMenuHeight: undefined,
  onClickLink: () => {},
};

export default MultiSelectTypeahead;
