import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { withOnLoad } from '@sb-itops/react';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import moment from 'moment';
import { LawpayCreditCardForm } from './LawpayCreditCardForm';
import { LawpayCreditCardFormSchema } from './LawpayCreditCardFormSchema';

const yearNow = new Date().getFullYear();
const yearOptions = new Array(30)
  .fill(null)
  .map((item, index) => ({ value: `${index + yearNow}`, label: index + yearNow }));
const monthOptions = new Array(12).fill(null).map((item, index) => ({ value: `${index + 1}`, label: index + 1 }));

const mapFormFieldsToToken = (formFields) => ({
  name: formFields.cardholderName,
  address1: formFields.addressLine1,
  address2: formFields.addressLine2,
  city: formFields.city,
  state: formFields.state,
  postal_code: formFields.postalCode,
  exp_year: formFields.expirationYear,
  exp_month: formFields.expirationMonth,
  country: 'US',
  email: formFields.email,
});

const mapStateToProps = (state, { scope, isSubmitting }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);
  const { formInitialised, fields, formDirty, formValid } = formSelectors.getFormState(state);
  const formFieldValues = formSelectors.getFieldValues(state);

  return {
    yearOptions,
    monthOptions,
    formFields: fields,
    formFieldValues,
    isSubmitting,
    formInitialised,
    formDirty,
    formValid,
  };
};

const mapDispatchToProps = (dispatch, { scope }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const fieldValues = {
        cardholderName: '',
        expirationMonth: new Date().getMonth() + 1,
        expirationYear: yearNow,
        addressLine1: '',
        city: '',
        addressLine2: '',
        country: 'US',
        state: undefined,
        postalCode: '',
        email: '',
        creditCardType: undefined,
        creditCardNumberStatus: false,
        securityCodeStatus: false,
      };

      dispatch(formActions.initialiseForm({ fieldValues }));

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onChange: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(
        formOperations.validateForm({
          schema: LawpayCreditCardFormSchema,
          validateFn: (fieldValues) => {
            const formErrors = {};

            if (
              moment(`${fieldValues.expirationMonth}-${fieldValues.expirationYear}`, 'MM-YYYY')
                .endOf('month')
                .isBefore(moment())
            ) {
              formErrors.expirationMonth = 'This card has already expired';
            }
            return formErrors;
          },
        }),
      );
    },
  };
};

const onSubmitTriggered = async ({ hostedFieldsApi, formFieldValues, onSubmit }) => {
  let paymentToken;
  try {
    const mappedFields = mapFormFieldsToToken(formFieldValues);
    paymentToken = await hostedFieldsApi.getPaymentToken(mappedFields);
  } catch (err) {
    // This can happen if the form data is baked, e.g. a credit card is placed in the name field.
    // In that situation, the tokenizer will detect it and fail. It's a PITA to manage it correctly,
    // as the affinipay API doesn't give us any useful information about which field is backed. It's
    // a bit hacky, but we will need to consume the error here and signify to sendPaymentP that an
    // error in tokenization has occured by sending an undefined payment token.

    // Affinipay hosted fields API should have already set this to be undefined already at this point,
    // but we set it anyway just to be sure we signify error.
    paymentToken = undefined;
  }

  onSubmit({
    paymentMethod: paymentMethods.CREDIT_CARD,
    paymentToken,
    // exposing some fields to help make success/failure message more meaningful
    cardType: formFieldValues.creditCardType,
    cardholderName: formFieldValues.cardholderName,
    cardNumberTruncated: paymentToken && paymentToken.number,
  });
};

export const LawpayCreditCardFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnLoad(({ triggerSubmit, formFieldValues, formValid, onReadyForSubmit, onSubmit, ...props }) => {
    // Hosted fields API handling.
    // When the hosted fields are initialised, an API will be provided which allows for tokenization of the hosted fields and the form data.
    // We hijack the `onSubmit` function of the overall form here and inject the hosted fields API into it.
    const [hostedFieldsApi, setHostedFieldsApi] = useState();
    const [submissionTriggered, setSubmissionTriggered] = useState(false);

    onReadyForSubmit(!!(hostedFieldsApi && formValid));

    // Trigger the submission
    if (triggerSubmit && !submissionTriggered) {
      if (!hostedFieldsApi || !formValid) {
        throw new Error('Lawpay credit card form submission should not be triggered when in non-ready state');
      }

      setSubmissionTriggered(true);
      onSubmitTriggered({ hostedFieldsApi, formFieldValues, onSubmit });
    }

    // We are ok to allow submission again.
    if (!triggerSubmit && submissionTriggered) {
      setSubmissionTriggered(false);
    }

    return <LawpayCreditCardForm onHostedFieldsApiReady={setHostedFieldsApi} {...props} />;
  }),
);

LawpayCreditCardFormContainer.displayName = 'LawpayCreditCardFormContainer';

LawpayCreditCardFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  publicKey: PropTypes.string.isRequired,
  hostedFieldsStyle: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

LawpayCreditCardFormContainer.defaultProps = {};
