/**
 * Apply rules for debtors to use on a matter
 * @param {object} param
 * @param {object} param.matterDefaultDebtorId
 * @param {object} param.billingConfigDebtorIds
 * @returns {Array<string>} the debtors or an empty array
 */
const getMatterDebtorIds = ({ matterDefaultDebtorId, billingConfigDebtorIds = [] }) => {
  if (billingConfigDebtorIds.length) {
    return billingConfigDebtorIds;
  }

  if (matterDefaultDebtorId) {
    return [matterDefaultDebtorId];
  }

  return [];
};

module.exports = {
  getMatterDebtorIds,
};
