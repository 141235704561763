import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, buttonTypes, LoadingBarInfinite, Modal } from '@sb-itops/react';

import Styles from './InvoiceCommunicateModal.module.scss';
import { InvoiceCommunicateForm } from '../invoice-communicate-form';

export const INVOICE_COMMUNICATE_MODAL_ID = 'invoice-communicate-modal';

/**
 * Styling for multi tabs
 *
 * @param {object} params
 * @param {boolean} params.tabValid
 * @param {boolean} params.debtorIncluded
 * @returns string
 */
const getTabIndicatorClasses = ({ tabValid, debtorIncluded }) => {
  if (!debtorIncluded) {
    return classnames('icon-comm-strike', Styles.tabIndicator);
  }

  if (!tabValid) {
    return classnames('icon-alert-1', Styles.tabIndicator, Styles.redText);
  }

  return classnames('icon-check-circle-2', Styles.tabIndicator, Styles.blueText);
};

export const InvoiceCommunicateModal = ({
  /** Modal */
  showModal,
  onModalClose,
  /** Form */
  debtorType,
  debtorDisplayName,
  formInitialised,
  fromUserId,
  includeDebtor,
  isLoading,
  isSubmitDisabled,
  isToAddressReadOnly,
  message,
  onFormSubmit,
  toAddress,
  onFieldValueUpdated,
  userOptions,
  // Preview
  isPreviewMode,
  onPreviewToggled,
  previewMessage,
  showPreviewButton,
  // Multi tab
  isMultiTabForm,
  onChangeTab,
  selectedTabId,
  showIncludeDebtor,
  tabs,
}) => (
  <Modal
    title="Send Secure Client Portal Message"
    className={Styles.invoiceCommunicateModal}
    modalBodyClassName={Styles.invoiceCommunicateModalBody}
    body={
      isLoading || !formInitialised ? (
        <div className={Styles.loadingBarContainer}>
          <LoadingBarInfinite loading={isLoading} />
        </div>
      ) : (
        <div className={classnames(Styles.invoiceCommunicateFormContainer, isMultiTabForm && Styles.multiTabForm)}>
          {isMultiTabForm && (
            <div className={Styles.tabList}>
              {tabs.map(({ id, text, tabValid, debtorIncluded }) => (
                <Button
                  key={id}
                  type={buttonTypes.tab}
                  highlight={selectedTabId === id}
                  onClick={() => onChangeTab({ newlySelectedTabId: id })}
                >
                  <div className={getTabIndicatorClasses({ tabValid, debtorIncluded })} />
                  <span title={text} className={Styles.tabText}>
                    {text}
                  </span>
                </Button>
              ))}{' '}
            </div>
          )}
          <div className={Styles.invoiceCommunicateForm}>
            <InvoiceCommunicateForm
              debtorDisplayName={debtorDisplayName}
              debtorType={debtorType}
              fromUserId={fromUserId}
              includeDebtor={includeDebtor}
              isLoading={isLoading}
              isPreviewMode={isPreviewMode}
              isToAddressReadOnly={isToAddressReadOnly}
              message={message}
              onFieldValueUpdated={onFieldValueUpdated}
              selectedTabId={selectedTabId}
              showIncludeDebtor={showIncludeDebtor}
              previewMessage={previewMessage}
              onPreviewToggled={onPreviewToggled}
              showPreviewButton={showPreviewButton}
              toAddress={toAddress}
              userOptions={userOptions}
            />
          </div>
        </div>
      )
    }
    footer={
      <Button onClick={onFormSubmit} disabled={isSubmitDisabled}>
        Send Now
      </Button>
    }
    isVisible={showModal}
    onModalClose={onModalClose}
  />
);

InvoiceCommunicateModal.displayName = 'InvoiceCommunicateModal';

InvoiceCommunicateModal.propTypes = {
  /** Modal */
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  /** Form */
  formInitialised: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  onSend: PropTypes.func.isRequired,
  showIncludeDebtor: PropTypes.bool.isRequired,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // Callbacks
  onFormSubmit: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  // Required but initially undefined
  debtorType: PropTypes.object,
  debtorDisplayName: PropTypes.object,
  fromUserId: PropTypes.object,
  includeDebtor: PropTypes.object,
  isToAddressReadOnly: PropTypes.object,
  message: PropTypes.object,
  toAddress: PropTypes.object,
  // Preview
  isPreviewMode: PropTypes.bool.isRequired,
  onPreviewToggled: PropTypes.func.isRequired,
  previewMessage: PropTypes.string.isRequired,
  showPreviewButton: PropTypes.bool.isRequired,
  // Multi tab
  isMultiTabForm: PropTypes.bool.isRequired,
  onChangeTab: PropTypes.func,
  selectedTabId: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
};

InvoiceCommunicateModal.defaultProps = {
  // Required but initially undefined
  debtorType: undefined,
  debtorDisplayName: undefined,
  fromUserId: undefined,
  includeDebtor: undefined,
  isToAddressReadOnly: undefined,
  message: undefined,
  toAddress: undefined,
  // Multi tab
  onChangeTab: undefined,
  selectedTabId: undefined,
  tabs: undefined,
};
