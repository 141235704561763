angular.module('sb.billing.webapp').service('sbDpReconciliationSetup', function(sbDpHelpers, sbReconciliationSetupService) {
  'use strict';

  const that = this;
  that.get = get;

  function get(destroyables, onUpdate) {
    const dataProvider = sbDpHelpers.provideData(sbReconciliationSetupService.get, onUpdate);
    sbDpHelpers.registerCacheWatcher(destroyables, 'sbReconciliationService', dataProvider);
  }

});
