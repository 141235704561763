// LOD compatible version of `monorepo/customer-management/redux/contacts/index.js`

/**
 * Finds contacts with missing street or PO address
 *
 * @typedef {object} Options
 * @property {Contact[]} contacts
 * @property {ContactAddressChecker} contactAddressChecker
 * @property {boolean} [spreadGroupsOfPeople=false] spreads groups of people individually
 * @returns {<Contact[]>} contacts with missing address
 */
const findContactsWithMissingStreetOrPOAddress = ({
  contacts,
  contactAddressChecker,
  spreadGroupsOfPeople = false,
}) => {
  const contactsForChecker = convertContacts(contacts);

  if (!spreadGroupsOfPeople) {
    return contactAddressChecker.findContactsWithMissingStreetOrPOAddress(contactsForChecker);
  }

  const missingAddresses = contactAddressChecker.findContactsWithMissingStreetOrPOAddress(contactsForChecker);
  const hasGroupOfPeopleMissingAddresses = missingAddresses.find((contact) => contact.groupOfPeople);

  if (!hasGroupOfPeopleMissingAddresses) {
    return missingAddresses;
  }

  // If any of the missing addresses are groups of people, we then have to spread the groups of people and get the constituent contacts
  // Because we can't edit groups of people in the web to add an address
  // We can only add addresses for every contact in the group individually
  const { nonGroupContacts, groups } = missingAddresses.reduce(
    (acc, contact) => {
      if (contact.groupOfPeople) {
        acc.groups.push(contact);
      } else {
        acc.nonGroupContacts.push(contact);
      }
      return acc;
    },
    { nonGroupContacts: [], groups: [] },
  );

  const constituents = groups.reduce((acc, group) => {
    const ids = [...acc, ...group.groupOfPeople.people];
    return ids;
  }, []);

  let expandedContacts = nonGroupContacts.concat(constituents);
  // Remove duplicates (can happen if a client is also in a group of people that are clients on the matter)
  const uniqueIds = new Set();
  const uniqueContacts = expandedContacts.filter((contact) => {
    const isDuplicate = uniqueIds.has(contact.id);
    uniqueIds.add(contact.id);
    return !isDuplicate;
  });
  expandedContacts = uniqueContacts;

  return contactAddressChecker.findContactsWithMissingStreetOrPOAddress(expandedContacts);
};

// Rather than re-writing the logic to support LOD format, I convert LOD format to format which we use in with redux
const convertContacts = (contacts) =>
  (contacts || []).reduce((acc, contact) => {
    if (contact.type) {
      const c = { ...contact, [contact.type]: contact };
      acc.push(c);
    }

    return acc;
  }, []);

module.exports = {
  findContactsWithMissingStreetOrPOAddress,
};
