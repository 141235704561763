import { getReceiptNumberPrefix } from '@sb-billing/redux/trust-receipt-settings';
import { getNumberingPrefixes } from '@sb-billing/redux/bank-account';
import * as unscopedForms2 from '@sb-itops/redux/forms2/feature';

const createExampleNumber = ({ nextNumber = '', leadingZeros = 0, prefix = '' } = {}) => {
  const nextNumberStr = `${nextNumber}`;
  if (nextNumberStr.length > 10 || leadingZeros > 10) {
    return 'N/A';
  }

  return `${prefix}${nextNumberStr.padStart(leadingZeros, '0')}`;
};

export const getNumberSettingPrefixes = (state, { bankAccountId }) => {
  const receipt = getReceiptNumberPrefix(bankAccountId);
  const {
    transferNumberingPrefix: transferOfFunds,
    electronicPaymentNumberingPrefix: electronicPayments,
    trustToOfficeNumberingPrefix: trustToOffice,
  } = getNumberingPrefixes({ bankAccountId });

  return {
    receipt,
    transferOfFunds,
    electronicPayments,
    trustToOffice,
  };
};

export const getExampleNumbers = (state) => {
  const { receipt, transferOfFunds, electronicPayments, trustToOffice } =
    unscopedForms2.selectors.getFieldValues(state) || {};

  return {
    receipt: createExampleNumber(receipt),
    transferOfFunds: createExampleNumber(transferOfFunds),
    electronicPayments: createExampleNumber(electronicPayments),
    trustToOffice: createExampleNumber(trustToOffice),
  };
};
