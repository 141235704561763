/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ShowSystemDateToggleMatter,
  ShowDeletedTransactionsToggle,
  DateListFilter,
  ClientCommunicationsToggle,
} from 'web/react-redux';
import { TrustAccountsForFilterPanels } from '@sb-billing/react/trust-accounts-filter-panel';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { PanelExpander, useTranslation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import classnames from 'classnames';

const MatterTransactionsFilterPanel = memo(
  ({
    onClickLink,
    accountType,
    cmaId,
    cmaAccounts,
    selectedTrustAccountId,
    trustAccounts,
    trustAccountsFilterPanelConfig,
    matterId,
    showHidden,
    showMarkAsSentEntries,
    scope,
    onClientCommunicationToggleChange,
    onDateListChange,
    onDeletedTransactionsChange,
    expanded,
    toggleExpanded,
    dateListFilter,
    showControlledMoneyAccount,
    showOperatingAccount,
    showLedgerAccount,
    showCreditNoteLedger,
    visibleClosedCmaIdMap,
  }) => {
    const { t } = useTranslation();
    return (
      <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
        <div className="side-menu-block">
          <h3>Accounts</h3>
          <ul className="side-menu-list capitalize">
            <TrustAccountsForFilterPanels
              trustAccounts={trustAccounts}
              matterId={matterId}
              onClickLink={onClickLink}
              selectedTrustAccountId={selectedTrustAccountId}
              selectedAccountType={accountType}
              {...trustAccountsFilterPanelConfig}
            />
            {showControlledMoneyAccount && (
              <>
                <li
                  onClick={() =>
                    onClickLink({
                      id: 'controlled-money',
                      type: 'matterTransactions',
                      params: [{ matterId, cmaId: 'all' }],
                    })
                  }
                  className={classnames({ active: accountType === 'CONTROLLED_MONEY' && cmaId === 'all' })}
                >
                  {t('controlledMoneyAccount')}
                </li>
                {cmaAccounts.map((account) => (
                  <li
                    key={account.id}
                    onClick={() =>
                      onClickLink({
                        id: 'controlled-money',
                        type: 'matterTransactions',
                        params: [{ matterId, cmaId: account.id }],
                      })
                    }
                    className={classnames('indent', {
                      active: accountType === 'CONTROLLED_MONEY' && cmaId === account.id,
                    })}
                  >
                    <span className={account.id in visibleClosedCmaIdMap ? 'closed-account' : ''}>{account.label}</span>
                  </li>
                ))}
              </>
            )}
            {showOperatingAccount && (
              <li
                onClick={() => onClickLink({ id: 'operating', type: 'matterTransactions', params: [{ matterId }] })}
                className={classnames({ active: accountType === 'OPERATING' })}
              >
                Operating Retainer Account
              </li>
            )}
          </ul>
          {(showLedgerAccount || showCreditNoteLedger) && (
            <div>
              <h3>Ledger</h3>
              <ul className="side-menu-list capitalize">
                {showLedgerAccount && (
                  <li
                    onClick={() => onClickLink({ id: 'ledger', type: 'matterTransactions', params: [{ matterId }] })}
                    className={classnames({ active: accountType === 'LEDGER' })}
                  >
                    Invoice Ledger
                  </li>
                )}
                {showCreditNoteLedger && (
                  <li
                    onClick={() =>
                      onClickLink({ id: 'credit-note-ledger', type: 'matterTransactions', params: [{ matterId }] })
                    }
                    className={classnames({ active: accountType === 'CREDIT-NOTE-LEDGER' })}
                  >
                    {t('creditNote')} Ledger
                  </li>
                )}
              </ul>
            </div>
          )}
          {featureActive('BB-11448') && (
            <div>
              <h3>History</h3>
              <ul className="side-menu-list capitalize">
                <li
                  onClick={() =>
                    onClickLink({ id: 'client-communications', type: 'matterTransactions', params: [{ matterId }] })
                  }
                  className={classnames({ active: accountType === 'CLIENT-COMMUNICATIONS' })}
                >
                  <span>Client Communications</span>
                </li>
              </ul>
            </div>
          )}
          <div>
            <h3>Show</h3>
            {accountType !== 'LEDGER' && accountType !== 'CLIENT-COMMUNICATIONS' && hasFacet(facets.deleteTransaction) && (
              <ShowDeletedTransactionsToggle
                onSelect={(id, value) => {
                  onDeletedTransactionsChange(value);
                }}
                label={accountType === 'CREDIT-NOTE-LEDGER' ? 'Deleted Credit Memos' : undefined}
                selected={showHidden}
                className="deleted-transactions-toggle"
              />
            )}
            {accountType !== 'CLIENT-COMMUNICATIONS' && <ShowSystemDateToggleMatter />}
            {accountType === 'CLIENT-COMMUNICATIONS' && (
              <ClientCommunicationsToggle
                showMarkAsSentEntries={showMarkAsSentEntries}
                onClientCommunicationToggleChange={onClientCommunicationToggleChange}
              />
            )}
          </div>
          {accountType === 'LEDGER' && (
            <div>
              <h3>For Entries Dated</h3>
              <DateListFilter
                onChange={onDateListChange}
                className="side-menu-list"
                scope={scope}
                {...dateListFilter}
              />
            </div>
          )}
        </div>
      </PanelExpander>
    );
  },
);

const cmaAccount = PropTypes.shape({ id: PropTypes.string, label: PropTypes.string });

MatterTransactionsFilterPanel.displayName = 'MatterTransactionsFilterPanel';

MatterTransactionsFilterPanel.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onClientCommunicationToggleChange: PropTypes.func.isRequired,
  onDateListChange: PropTypes.func.isRequired,
  onDeletedTransactionsChange: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  matterId: PropTypes.string.isRequired,
  isStatutoryDepositMatter: PropTypes.bool,
  showHidden: PropTypes.bool,
  showMarkAsSentEntries: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  showTrustOverview: PropTypes.bool.isRequired,
  showControlledMoneyAccount: PropTypes.bool.isRequired,
  showLedgerAccount: PropTypes.bool.isRequired,
  showOperatingAccount: PropTypes.bool.isRequired,
  showCreditNoteLedger: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  dateListFilter: PropTypes.shape({
    selectedFilter: PropTypes.string,
    before: PropTypes.number, // effective date YYYYMMDD
    from: PropTypes.number, // effective date YYYYMMDD
    to: PropTypes.number, // effective date YYYYMMDD
  }),
  cmaAccounts: PropTypes.arrayOf(cmaAccount),
  cmaId: PropTypes.string,
  visibleClosedCmaIdMap: PropTypes.object,
  selectedTrustAccountId: PropTypes.string,
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  trustAccountsFilterPanelConfig: PropTypes.shape({
    showProtectedFunds: PropTypes.bool.isRequired,
    overviewOnClicklinkParams: PropTypes.object.isRequired,
    getSpecificOnClickLinkParams: PropTypes.func.isRequired,
    getProtectedFundsOnClickLinkParams: PropTypes.func.isRequired,
  }).isRequired,
};

MatterTransactionsFilterPanel.defaultProps = {
  isStatutoryDepositMatter: false,
  accountType: undefined,
  cmaId: undefined,
  showHidden: undefined,
  showMarkAsSentEntries: undefined,
  dateListFilter: {},
  cmaAccounts: [],
  visibleClosedCmaIdMap: {},
  selectedTrustAccountId: undefined,
};

export default MatterTransactionsFilterPanel;
