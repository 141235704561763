import PropTypes from 'prop-types';
import { withOnLoad } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { FeeWisePayoutTableData } from 'web/graphql/queries';
import { useQuery } from '@apollo/client';
import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { FeeWisePayoutsRoute } from './FeeWisePayoutsRoute';
import { getFilterDatesForQuery } from '../get-filter-dates-for-query';

const SCOPE = 'FeeWisePayoutsRoute';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_PAYOUT_TABLE = `${SCOPE}/payout-table`;
const FETCH_LIMIT = 50;

const hooks = ({ selectedStatusIds, statusOptions, dateRangeType, fromDate, toDate, linkedAccountSelected }) => ({
  useFeeWisePayoutListData: () => {
    const { selectedItem: currentPage, setSelectedItem: setPageNumber } = useSingleItemSelection({
      scope: SCOPE_PAGINATION,
      initialSelection: 0,
    });

    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: SCOPE_PAYOUT_TABLE,
      initialSortBy: 'date',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    const skipPayoutsQuery = selectedStatusIds.length === 0;
    const status = statusOptions.length === selectedStatusIds.length ? [] : selectedStatusIds;
    const { from, to } = getFilterDatesForQuery({ dateRangeType, fromInt: fromDate, toInt: toDate });
    const feeWiseAccountIds = linkedAccountSelected ? [linkedAccountSelected] : [];

    const fwPayoutsQueryResult = useQuery(FeeWisePayoutTableData, {
      fetchPolicy: 'cache-and-network',
      skip: skipPayoutsQuery,
      variables: {
        feeWisePayoutFilter: {
          status,
          from,
          to,
          feeWiseAccountIds,
        },
        // We count pages from 0 but FeeWise counts from 1
        page: currentPage + 1,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (fwPayoutsQueryResult.error) {
      throw new Error(fwPayoutsQueryResult.error);
    }
    const { data: fwPayoutsData } = fwPayoutsQueryResult;
    const fwPayoutList = fwPayoutsData?.feeWisePayoutList?.results || [];

    const payoutCount = fwPayoutsData?.feeWisePayoutList?.totalCount || 0;
    const numberOfPages = Math.ceil(payoutCount / FETCH_LIMIT);

    if (!fwPayoutsQueryResult.loading && currentPage > numberOfPages) {
      // Reset page to 0 if we requested page higher than total number of pages
      setPageNumber(0);
    }

    return {
      payoutCount,
      payouts: fwPayoutList,
      payoutsDataLoading: fwPayoutList.length === 0 && fwPayoutsQueryResult.loading,
      // pagination
      hidePagination: !(currentPage > 0 || numberOfPages > 1),
      currentPage,
      numberOfPages: Math.ceil(payoutCount / FETCH_LIMIT),
      onPageChange: ({ selected: pageNumber }) => setPageNumber(pageNumber),
      // sort
      onSort,
      sortBy,
      sortDirection,
    };
  },
});

export const FeeWisePayoutsRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(withOnLoad(FeeWisePayoutsRoute))),
);

FeeWisePayoutsRouteContainer.displayName = 'FeeWisePayoutsRouteContainer';

FeeWisePayoutsRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  panelFilterScope: PropTypes.string.isRequired,

  dateRangeType: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  onDateListChange: PropTypes.func.isRequired,

  selectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  linkedAccountSelected: PropTypes.string.isRequired,
  linkedAccountOnSelect: PropTypes.func.isRequired,
  linkedAccountLoading: PropTypes.bool.isRequired,
  linkedAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};
