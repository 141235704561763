/**
 * ATTENTION!!!
 *
 * Dont call this function directly. Use  @sb-customer-management/redux/contacts-summary/getContactDisplay instead as it will resolve GroupOfPeople
 *
 * Get the display name of a contactSummary object.
 * @param {object} contact A contactSummary entity
 * @param {object} opts The options for configuring the returned display name
 * @param {boolean} [opts.showLastNameFirst] Represent the last name in the form `lastName, firstName`
 * @param {boolean} [opts.hideDeletedStatus] Optionally hide deleted status in display name
 * @returns {String|undefined} The display name of the contactSummary object or undefined if there is not contact
 */
function getDisplayName(contact, { showLastNameFirst, hideDeletedStatus } = {}) {
  if (!contact) {
    return undefined;
  }

  if (showLastNameFirst && (contact.type === 'Person' || contact.type === 'Staff')) {
    const displayName = [contact.lastName, contact.firstName].filter((name) => name).join(', ') || contact.displayName;
    return `${displayName}${contact.removed && !hideDeletedStatus ? ' (Deleted)' : ''}`;
  }

  return `${contact.displayName}${contact.removed && !hideDeletedStatus ? ' (Deleted)' : ''}`;
}

module.exports = {
  getDisplayName,
};
