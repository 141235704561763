import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/expenses';

angular.module('@sb-billing/services').service('sbExpenseCacheManager', function (sbGenericCacheService, sbEndpointType) {
  sbGenericCacheService.setupCache({
    name: 'sbExpenseService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/expenses' },
      poll: 60,
      subscriptions: ['notifier.ExpensesNotifications.ExpenseUpdated', 'notifier.ExpensesNotifications.ExpenseDeleted']
    },
    updateRedux,
    clearRedux,
  });
});
