'use strict';

const { accumulateSourceItemsDurations } = require('./accumulate-source-items-durations');
const { calculateFeeAmounts } = require('./calculate-fee-amounts');
const { calculateFeeAmountTax } = require('./calculate-fee-amount-tax');
const { calculateFeeBillableDuration } = require('./calculate-fee-billable-duration');
const { calculateFeeDescriptionMaxLength } = require('./calculate-fee-description-max-length');
const { calculateDurationFieldValue } = require('./calculate-duration-field-value');
const { deriveFeeBillableStatus } = require('./derive-fee-billable-status');
const { feeCanBeMarkedAsNonBillable } = require('./fee-can-be-marked-as-non-billable');
const { feeCanBeMarkedAsBillable } = require('./fee-can-be-marked-as-billable');
const { feeCanBeModified } = require('./fee-can-be-modified');
const { generateAutoTimeFeeSummary } = require('./generate-auto-time-fee-summary');
const { generateFeeNotes } = require('./generate-fee-notes');
const { generateTimeInHoursAndMinutes } = require('./generate-time-in-hours-and-minutes');
const { getFeeDaySummaries } = require('./get-fee-day-summaries');
const { deriveNewFeeBillableStatus } = require('./derive-new-fee-billable-status');

module.exports = {
  accumulateSourceItemsDurations,
  calculateFeeAmounts,
  calculateFeeAmountTax,
  calculateFeeBillableDuration,
  calculateFeeDescriptionMaxLength,
  calculateDurationFieldValue,
  deriveFeeBillableStatus,
  feeCanBeMarkedAsBillable,
  feeCanBeMarkedAsNonBillable,
  feeCanBeModified,
  generateAutoTimeFeeSummary,
  generateFeeNotes,
  generateTimeInHoursAndMinutes,
  getFeeDaySummaries,
  deriveNewFeeBillableStatus,
};
