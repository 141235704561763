'use strict';

const moment = require('moment');
const { today } = require('@sb-itops/date');
const { formatPaymentFrequency } = require('./format-payment-frequency');

/**
 * Formats payment plan parameters into a sentence
 * @param {*} paymentPlan payment plan with installments, totalAmount and unpaidAmount
 * @returns { paymentPlanDescription }
 */
const formatPaymentPlanDescription = (paymentPlan, t) => {
  if (!paymentPlan || !paymentPlan.installments.length) {
    return '';
  }
  const { installments } = paymentPlan;

  const formatCurrency = (cents) => t('cents', { val: cents });
  const formatDate = (date) => moment(date, 'YYYYMMDD').format('MMMM Do');

  const nextInstallment = installments.find((i) => moment(i.date, 'YYYYMMDD').isAfter(today()));
  const lastInstallment = installments[installments.length - 1];

  const nextPaymentDue = nextInstallment
    ? `Next payment due ${formatDate(nextInstallment.date)}.`
    : `Last payment was missed ${formatDate(lastInstallment.date)}.`;
  const paymentAmount = formatCurrency(paymentPlan.installmentAmount);
  const paymentFrequency = formatPaymentFrequency(paymentPlan.installmentFrequency).toLowerCase();

  return `Payments of ${paymentAmount} ${paymentFrequency}. ${nextPaymentDue}`;
};

module.exports = {
  formatPaymentPlanDescription,
};
