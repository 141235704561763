// show modal or isufficient funds modal
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import Styles from './VendorPaymentEntrySplitPayorsModal.module.scss';
import { VendorPaymentEntrySplitPayorsForm } from '../vendor-payment-entry-split-payors-form';

export const VendorPaymentEntrySplitPayorsModal = ({
  onProcess,
  onClose,
  onOpenDepositFundsModal,
  scope,
  isSubmitLocked,
  modalTitle,
  hasInsufficientFunds,
  matterId,
  bankAccountId,
  onBankAccountIdChange,
  onMatterIdChange,
}) =>
  hasInsufficientFunds ? (
    <Modal
      className={Styles.vendorPaymentEntrySplitPayorsModal}
      title={modalTitle}
      body={
        <div>
          <div className="row">
            <div className="col-lg-12">
              <p> You do not have sufficient funds in your firm&apos;s trust account. </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p>Please deposit funds if you wish to proceed.</p>
            </div>
          </div>
        </div>
      }
      footer={
        <div className={Styles.buttonContainer}>
          <Button onClick={onOpenDepositFundsModal}>Deposit Funds</Button>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
      onModalClose={onClose}
    />
  ) : (
    <Modal
      className={Styles.vendorPaymentEntrySplitPayorsModal}
      title={modalTitle}
      body={
        <VendorPaymentEntrySplitPayorsForm
          matterId={matterId}
          bankAccountId={bankAccountId}
          scope={scope}
          onBankAccountIdChange={onBankAccountIdChange}
          onMatterIdChange={onMatterIdChange}
        />
      }
      footer={
        <div className={Styles.buttonContainer}>
          <Button
            className={Styles.processButton}
            onClick={onProcess}
            disabled={isSubmitLocked}
            form={scope}
            locked={isSubmitLocked}
            size="full-width"
          >
            Process
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  );

VendorPaymentEntrySplitPayorsModal.displayName = 'VendorPaymentEntrySplitPayorsModal';

VendorPaymentEntrySplitPayorsModal.propTypes = {
  onProcess: PropTypes.func.isRequired,
  onOpenDepositFundsModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  isSubmitLocked: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  hasInsufficientFunds: PropTypes.bool.isRequired,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  onBankAccountIdChange: PropTypes.func.isRequired,
  onMatterIdChange: PropTypes.func.isRequired,
};
VendorPaymentEntrySplitPayorsModal.defaultProps = {
  matterId: undefined,
  bankAccountId: undefined,
};
