import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query OperatingChequeListData($filter: OperatingChequeListFilter, $offset: Int!, $limit: Int!, $sort: ListSort) {
    operatingChequeList(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      results {
        id
        lastUpdated
        chequeDate
        chequeNumber
        chequeMemo
        payTo {
          id
          displayName
        }
        expenses {
          id
          amountIncludesTax
          price
          quantity
          tax
          matter {
            id
            matterNumber
            clientDisplay
            otherSideDisplay
            matterType {
              matterTypeId
              name
            }
          }
        }
        chequeAmount
        isHidden
        isPrinted
        reversed
      }
    }
  }
`;

const notificationIds = [
  'WebQueryExpensesNotifications.ExpenseUpdated',
  'WebQueryExpensesNotifications.ExpensePaymentDetailsUpdated',
  'WebQueryAccountsNotifications.OperatingChequeUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const OperatingChequeListData = {
  query,
  notificationIds,
};
