'use strict';

const { isValidLines } = require('./custom-notes/is-valid-lines');
const { isValidCharacters } = require('./custom-notes/is-valid-characters');
const constants = require('./entities/constants');
const { applySupplementaryTablesSettingToTemplate } = require('./apply-supplementary-tables-setting-to-template');
const {
  determineFinalInvoiceAdditionalOptionsAndLayout,
} = require('./determine-final-invoice-additional-options-and-layout');
const {
  deriveSectionOptionsFromAdditionalOptions,
} = require('./section-options/derive-section-options-from-additional-options');
const { deriveOverriddenInvoiceSettings } = require('./derive-overridden-invoice-settings');
const { determineFinalSectionOptions } = require('./section-options/determine-final-section-options');
const { generateInvoiceTitle } = require('./generate-invoice-title');
const { getCustomTextTitleMacros } = require('./get-custom-text-title-macros');
const { interpolateCustomTextTitle } = require('./interpolate-custom-text-title');
const { getMatterDebtorIdsForAddressOverride } = require('./get-matter-debtor-ids-for-address-override');

module.exports = {
  isValidLines,
  isValidCharacters,
  constants,
  applySupplementaryTablesSettingToTemplate,
  deriveSectionOptionsFromAdditionalOptions,
  deriveOverriddenInvoiceSettings,
  determineFinalInvoiceAdditionalOptionsAndLayout,
  determineFinalSectionOptions,
  generateInvoiceTitle,
  getCustomTextTitleMacros,
  interpolateCustomTextTitle,
  getMatterDebtorIdsForAddressOverride,
};
