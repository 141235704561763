import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import { Checkbox } from '@sb-itops/react/checkbox';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { featureActive } from '@sb-itops/feature';
import Styles from './FeeTable.module.scss';

const {
  amountCellLocalisedRendererWithWaived,
  amountCellLocalisedRendererWithZero,
  yyyymmddLocalisedRenderer,
  durationCellRenderer,
  checkboxCellWrapperRenderer,
} = utils;

function checkboxHeaderCellRenderer({ props }) {
  return () => {
    const { selectedFeeIds, fees, onToggleFees } = props;
    const currentPageSelectedCount = fees.filter((fee) => selectedFeeIds[fee.id]).length;
    const isAllSelected = !!currentPageSelectedCount && currentPageSelectedCount === fees.length;
    const onToggleAllFees = () => {
      const feesToToggle = isAllSelected
        ? fees // if all selected: toggle all fees
        : fees.filter((fee) => !selectedFeeIds[fee.id]); // if some not selected: toggle those not already selected
      onToggleFees(feesToToggle);
    };
    return <Checkbox checked={isAllSelected} onChange={onToggleAllFees} />;
  };
}

function checkboxCellRenderer({ props }) {
  return ({ rowData }) => {
    const { onToggleFees, selectedFeeIds } = props;
    return (
      <Checkbox
        checked={selectedFeeIds[rowData.id]}
        onChange={() => {
          const fee = rowData;
          onToggleFees([fee]);
        }}
      />
    );
  };
}

function staffInitialsCellRenderer({ cellData }) {
  if (!cellData || !cellData.initials) return undefined;
  return cellData.initials;
}

function hoursCellRenderer({ cellData, rowData }) {
  if (rowData.feeType === 0) return 'Fixed';
  return durationCellRenderer({ cellData });
}

function billableCellRenderer({ rowData }) {
  // 'null' indicates there are both billable and non-billable items in grouped fee
  if (rowData.isBillable === null) {
    return (
      <div className="left-align">
        <i className="billable-icon" />
        <i className="icon icon-information" title="This grouped fee contains both billable and non-billable items." />
      </div>
    );
  }
  // otherwise render a tick if all items are billable
  return rowData.isBillable ? (
    <div className="left-align">
      <i className="billable-icon" />
    </div>
  ) : (
    ''
  );
}

export const FeeTable = (props) => {
  const {
    autoScroll,
    currentFeeId,
    dataLoading,
    fees,
    sortDirection,
    sortBy,
    onSort,
    feeSummary,
    selectable,
    showFeeDateColumn,
    showStaffInitialsColumn,
    showMatterColumn,
  } = props;

  const { t } = useTranslation();

  let scrollToIndex = autoScroll && currentFeeId ? fees.findIndex((fee) => fee.id === currentFeeId) : undefined;
  scrollToIndex = scrollToIndex !== undefined && scrollToIndex > -1 ? scrollToIndex : undefined;

  const matterCellRenderer = ({ cellData, rowData }) => {
    if (!cellData?.id) {
      return undefined;
    }

    const matterDisplay = getMatterDisplay(cellData, rowData.matter?.matterType?.name);

    if (!rowData.matter?.id) {
      return matterDisplay;
    }

    // TODO: Create a GQL-compatible MatterDisplay to handle all use cases
    return (
      <LinkableText
        text={matterDisplay}
        onClickLink={() => props.onClickLink({ type: 'matter', id: rowData.matter.id })}
        asLink
        inline
        truncateText
      />
    );
  };

  const billedCellRenderer = ({ cellData, rowData }) => {
    if (rowData.isInvoicedExternally) {
      return (
        <div className="center-align">
          <span>Yes</span>
        </div>
      );
    }

    if (cellData !== 'PENDING' && rowData?.invoice?.id) {
      return (
        <div className="center-align">
          <LinkableText
            text={`#${rowData.invoice.invoiceNumber || 'unknown'}`}
            onClickLink={() => props.onClickLink({ type: 'invoice', id: rowData.invoice.id })}
            asLink
            inline
          />
        </div>
      );
    }

    return null;
  };

  const rowClick = ({ rowData, index }) => {
    props.onClickRow({ ...rowData, feeIndex: index });
  };

  const rowClass = ({ index }) => {
    // Header row.
    if (index === -1) {
      return 'fee-editor-side-panel-scrollable';
    }

    return currentFeeId && fees[index].id === currentFeeId
      ? 'selected fee-editor-side-panel-scrollable'
      : 'fee-editor-side-panel-scrollable';
  };

  const footer = () => {
    const hours = (feeSummary.units / 60).toFixed(2);
    const billable = feeSummary.billable;
    const waived = feeSummary.waived;
    const nonBillable = feeSummary.nonBillable;
    const billed = feeSummary.billed;

    return (
      <div className={Styles.feeTableFooter}>
        <span>{hours} hours logged</span>
        <span>
          {t('cents', {
            val: billable || 0,
          })}{' '}
          Billable (inclusive of{' '}
          {t('cents', {
            val: waived || 0,
          })}{' '}
          written off)
        </span>
        <span>
          {t('cents', {
            val: nonBillable || 0,
          })}{' '}
          Non-Billable
        </span>
        <span>
          {t('cents', {
            val: billed || 0,
          })}{' '}
          Billed
        </span>
      </div>
    );
  };

  return (
    <Table
      className={Styles.billingFeeTable}
      dataLoading={dataLoading}
      footerRenderer={footer}
      list={fees}
      rowClassName={rowClass}
      scrollToIndex={scrollToIndex}
      showFooter={!!feeSummary}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onRowClick={rowClick}
    >
      {selectable && (
        <Column
          key="UNNEEDED"
          dataKey="UNNEEDED"
          label=""
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(checkboxHeaderCellRenderer({ props }))}
          cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer({ props }))}
          disableSort
        />
      )}

      {showFeeDateColumn && (
        <Column key="feeDate" dataKey="feeDate" label="Date" flexGrow={2} cellRenderer={yyyymmddLocalisedRenderer} />
      )}
      {showStaffInitialsColumn && (
        <Column
          key="staffInitials"
          dataKey="feeEarnerStaff"
          label="Staff"
          flexGrow={0.8}
          cellRenderer={staffInitialsCellRenderer}
        />
      )}
      {showMatterColumn && (
        <Column key="matter" dataKey="matter" label="Matter" flexGrow={3} cellRenderer={matterCellRenderer} />
      )}
      <Column
        className={Styles.feeTableActivity}
        key="activity"
        dataKey="activityCode"
        label="Activity"
        flexGrow={0.8}
      />
      {props.showTaskColumn && <Column dataKey="utbmsTaskCode" label="Task" flexGrow={1} />}
      <Column
        className={Styles.descriptionField}
        key="description"
        dataKey="description"
        label="Subject"
        flexGrow={5}
      />
      {featureActive('BB-13563') && (
        <Column
          className="right-align"
          key="durationWorked"
          dataKey="durationWorked"
          label="Working Hrs"
          flexGrow={2}
          cellRenderer={hoursCellRenderer}
          disableSort
        />
      )}
      <Column
        className="right-align"
        key="duration"
        dataKey="duration"
        label={featureActive('BB-13563') ? 'Billing Hrs' : 'Hours'}
        flexGrow={2}
        cellRenderer={hoursCellRenderer}
        disableSort
      />
      <Column
        className={classnames(Styles.feeTableRate, 'right-align')}
        key="rate"
        dataKey="rate"
        label="Rate"
        flexGrow={2}
        cellRenderer={amountCellLocalisedRendererWithZero}
        disableSort
      />
      {!props.showTaxColumns && (
        <Column
          className="right-align"
          key="amountExclTax"
          dataKey="amountExclTax"
          label="Amount"
          flexGrow={2}
          cellRenderer={amountCellLocalisedRendererWithWaived}
          disableSort
        />
      )}
      {props.showTaxColumns && (
        <Column
          className="right-align"
          key="amountExclTax"
          dataKey="amountExclTax"
          label={`Amount exc. ${t('tax')}`}
          flexGrow={2}
          cellRenderer={amountCellLocalisedRendererWithZero}
          disableSort
        />
      )}
      {props.showTaxColumns && (
        <Column
          className="right-align"
          key="tax"
          dataKey="tax"
          label={t('tax')}
          flexGrow={2}
          cellRenderer={amountCellLocalisedRendererWithZero}
          disableSort
        />
      )}
      {props.showTaxColumns && (
        <Column
          className="right-align"
          key="total"
          dataKey="total"
          label={`Amount inc. ${t('tax')}`}
          flexGrow={2}
          cellRenderer={amountCellLocalisedRendererWithWaived}
          disableSort
        />
      )}
      <Column
        key="isBillable"
        dataKey="isBillable"
        label={featureActive('BB-13563') ? 'Billable' : 'Billable?'}
        flexGrow={0.8}
        cellRenderer={billableCellRenderer}
      />
      <Column
        key="billed"
        dataKey="invoiceNumber"
        label={featureActive('BB-13563') ? 'Billed' : 'Billed?'}
        flexGrow={0.8}
        cellRenderer={billedCellRenderer}
      />
    </Table>
  );
};

FeeTable.displayName = 'FeeTable';

FeeTable.propTypes = {
  autoScroll: PropTypes.bool,
  currentFeeId: PropTypes.string,
  dataLoading: PropTypes.bool,
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      feeDate: PropTypes.number,
      feeEarnerStaff: PropTypes.shape({
        id: PropTypes.string,
        initials: PropTypes.string,
      }),
      matter: PropTypes.object,
      activityCode: PropTypes.string,
      utbmsTaskCode: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      rate: PropTypes.number,
      amount: PropTypes.number,
      isBillable: PropTypes.oneOf([true, false, null]),
      invoiceNumber: PropTypes.number,
      amountExclTax: PropTypes.number,
      tax: PropTypes.number,
    }),
  ).isRequired,
  selectable: PropTypes.bool,
  selectedFeeIds: PropTypes.object,
  showFeeDateColumn: PropTypes.bool,
  showMatterColumn: PropTypes.bool,
  showStaffInitialsColumn: PropTypes.bool,
  showTaskColumn: PropTypes.bool,
  showTaxColumns: PropTypes.bool,
  sortBy: PropTypes.oneOf([
    'feeDate',
    'feeEarnerStaff',
    'activityCode',
    'utbmsTaskCode',
    'matter',
    'description',
    'isBillable',
    'invoiceNumber',
  ]).isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  feeSummary: PropTypes.shape({
    units: PropTypes.number.isRequired,
    nonBillable: PropTypes.number.isRequired,
    billable: PropTypes.number.isRequired,
    billed: PropTypes.number.isRequired,
    waived: PropTypes.number.isRequired,
  }),
  onClickLink: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onToggleFees: (props, propName, componentName) => {
    // Required if `selectable` is true
    if (props.selectable === true && (props[propName] === undefined || typeof props[propName] !== 'function')) {
      return new Error(
        ` Warning: Failed prop type: The prop '${propName}' is marked as required in '${componentName}', but its value is '${props[propName]}'`,
      );
    }
    return null;
  },
};

FeeTable.defaultProps = {
  autoScroll: false,
  currentFeeId: undefined,
  dataLoading: false,
  selectable: false,
  selectedFeeIds: {},
  showFeeDateColumn: true,
  showMatterColumn: false,
  showStaffInitialsColumn: true,
  showTaskColumn: false,
  showTaxColumns: false,
  feeSummary: {
    units: 0,
    nonBillable: 0,
    billable: 0,
    billed: 0,
    waived: 0,
  },
  onToggleFees: undefined,
};

export default FeeTable;
