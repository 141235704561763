import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import CreditHistoryFilter from './CreditHistoryFilter';

const { periodOption, periodOptions } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const mapStateToProps = (state, { filters }) => ({
  periodOptions,
  contacts: getContactTypeAheadSummaries(),
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      const defaultState = {
        isMatterContactBalanceType: isMatterContactBalanceType(),
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        showEntriesFor: 'matter',
        errors: {
          matter: true,
        },
      };

      if (hasFacet(facets.timeKeeperInitials)) {
        defaultState.showTimeKeeperInitials = false;
      }

      if (hasFacet(facets.deleteTransaction)) {
        defaultState.showDeletedTransactions = false;
      }

      onFiltersChanged(defaultState);
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onMatterSelected: (matter) =>
    matter
      ? onFiltersChanged({
          ...filters,
          matter: { id: matter.id, display: matter.display },
          errors: {
            ...filters.errors,
            matter: false,
          },
        })
      : onFiltersChanged({
          ...filters,
          matter: undefined,
          errors: {
            ...filters.errors,
            matter: filters.showEntriesFor === 'matter',
          },
        }),

  onContactSelected: (option) =>
    option
      ? dispatch(
          onFiltersChanged({
            ...filters,
            contact: { id: option.value, display: option.label },
            errors: {
              ...filters.errors,
              contact: false,
            },
          }),
        )
      : dispatch(
          onFiltersChanged({
            ...filters,
            contact: undefined,
            errors: {
              ...filters.errors,
              contact: filters.showEntriesFor === 'contact',
            },
          }),
        ),

  onShowEntriesFor: (showEntriesFor) =>
    onFiltersChanged({
      ...filters,
      showEntriesFor,
      errors: {
        ...filters.errors,
        matter: (showEntriesFor === 'matter' && !filters.matter) || false,
        contact: (showEntriesFor === 'contact' && !filters.contact) || false,
      },
    }),

  onSelectOption: ({ key, option }) => onFiltersChanged({ ...filters, [key]: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const CreditHistoryFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(CreditHistoryFilter)),
);

CreditHistoryFilterContainer.displayName = `CreditHistoryFilterContainer`;

export default CreditHistoryFilterContainer;
