import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import * as evergreenRequestsFilters from 'web/redux/route/home-billing-accounts-evergreen-requests';
import EvergreenRequestsSidePanel from './EvergreenRequestsSidePanel';

const mapStateToProps = (state) => {
  const { getShowFilters, getFilterOptions, getFilters } = evergreenRequestsFilters.selectors;
  const expanded = getShowFilters(state);
  const filterOptions = getFilterOptions(state);
  const filters = getFilters(state);
  return { expanded, filters, filterOptions };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(evergreenRequestsFilters.actions.toggleShowFilters()),
  onResetFilters: () => dispatch(evergreenRequestsFilters.actions.onResetFilters()),
  onChangeFilter: (key, value) => dispatch(evergreenRequestsFilters.actions.onChangeFilter(key, value)),
  onToggleHideMattersAboveMinimumThreshold: (value) =>
    dispatch(evergreenRequestsFilters.actions.onChangeFilter('hideMattersAboveMinimumThreshold', value)),
  onToggleRequestOlderThanFilter: (value) =>
    dispatch(evergreenRequestsFilters.actions.onChangeFilter('enableRequestOlderThanFilter', value)),
  onShowIfReplenishAmountGreaterThanAmount: (value) =>
    dispatch(evergreenRequestsFilters.actions.onChangeFilter('showIfReplenishAmountGreaterThanAmount', value)),
  onToggleReplenishAmountFilter: (value) =>
    dispatch(evergreenRequestsFilters.actions.onChangeFilter('enableReplenishAmountFilter', value)),
  onHideIfLastRequestOlderThanDaysChange: (value) =>
    dispatch(evergreenRequestsFilters.actions.onChangeFilter('hideIfLastRequestOlderThanDays', value)),
});

const EvergreenRequestsSidePanelContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(EvergreenRequestsSidePanel),
);

export default EvergreenRequestsSidePanelContainer;
