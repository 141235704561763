'use strict';

// .Net defaults matterInvoiceSettings.payButtonEnabledOption to 0
// In the above case, we treat pay buttons as enabled
// We only disable the pay button when explicitly set
const payButtonEnabledOptionValues = Object.freeze({
  DOT_NET_DEFAULT: 0,
  DISABLED: 1,
  ENABLED: 2,
});

module.exports = {
  payButtonEnabledOptionValues,
};
