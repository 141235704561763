'use strict';

const { entryType } = require('../entities');

/**
 * Accumulate the invoice entries (fees) durations
 *
 * @param {object} params
 * @param {object[]} params.entries
 * @returns {number}
 */
function accumulateInvoiceEntriesTotalDuration({ entries }) {
  const { invoiceTotalDuration } = entries.reduce(
    (acc, entry) => {
      const fee = entry.feeEntity;

      if (fee && fee.feeType === entryType.TIME) {
        acc.invoiceTotalDuration += fee.duration;
      }

      return acc;
    },
    {
      invoiceTotalDuration: 0,
    },
  );

  return invoiceTotalDuration;
}

module.exports = {
  accumulateInvoiceEntriesTotalDuration,
};
