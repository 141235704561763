import { ApolloLink, HttpLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { getRestApiUrl } from '@sb-itops/environment-config';
import uuid from '@sb-itops/uuid';
import { getEnabledFeatures } from '@sb-itops/feature';
import { store } from '@sb-itops/redux';
import { getLogger } from '@sb-itops/fe-logger';
import {
  getAuthToken,
  getAccountId,
  refreshTokenP,
  getIsAuthRefreshInProgress,
  getIsAuthenticated,
} from 'web/services/user-session-management';
import { onError } from "@apollo/client/link/error";

const log = getLogger('apollo');

export const errors = [];

export const addError = (err) => {
  // keep first ten errors as initial failures often cause subsequent ones
  if (errors.length >= 100) {
    errors.splice(10, 1, '... (items omitted)');
    errors.splice(11, 1);
    errors.push(err);
  }else {
    errors.push(err);
  }
}

let retryQueue = [];

const httpLink = () => {
  const uri = `${getRestApiUrl()}/v2/integration/webapp-graphql-server/${getAccountId()}/`;

  return new HttpLink({
    uri,
  });
};

const batchHttpLink = () => {
  const uri = `${getRestApiUrl()}/v2/integration/webapp-graphql-server/${getAccountId()}/`;

  return new BatchHttpLink({
    uri,

    // The requests will be held until either of the below 2 settings gets reached
    // Requests that are identical will get de-duped
    batchMax: 10,
    batchInterval: 50,

    // Custom `batchKey` function because the 'x-correlation-id' header causes
    // the default `batchKey` function to create a new batch on every request.
    // Setting the 'x-correlation-id' header as a part of BatchHttpLink
    // fetchParams object keeps the same correlation ID for each request
    batchKey: (operation) => {
      const context = operation.getContext();

      const contextConfig = {
        http: context.http,
        options: context.fetchOptions,
        credentials: context.credentials,
        headers: context.headers.Authorization, // excluding the 'x-correlation-id' header
      };

      return uri + JSON.stringify(contextConfig);
    },
  });
};

const preferredLink = () =>
  new ApolloLink.split((operation) => operation.getContext().skipRequestBatching === true, httpLink(), batchHttpLink());

const authLink = () =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${getAuthToken()}`,
        'x-correlation-id': uuid(),
        'x-features': getEnabledFeatures().join(','),
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (operation) {
      addError(`Query Name: ${operation.operationName}, Filters: ${JSON.stringify(operation.variables, null, 2)}`)
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => addError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
    }
    if (networkError) {
      addError(`[Network error]: ${networkError}`);
    }
  });

// Store subscription logic borrowed from
// monorepo/itops/services/src/generic-endpoint/generic-endpoint-service.js
store.subscribe(async () => {
  // If no requests are queued, we can ignore any state updates as it means no requests have yet failed
  // due to bad auth.
  if (retryQueue.length === 0) {
    return;
  }

  // If the user is currently in the process of re-authenticating via refresh token, then we are only interested
  // in what eventually happens. So we can skip this.
  if (getIsAuthRefreshInProgress()) {
    return;
  }

  // We aren't refreshing and we aren't authenticated, reject anything in the queue and return.
  if (!getIsAuthenticated()) {
    rejectQueue();
    return;
  }

  // We are authenticated again, resubmit all the queued requests.
  submitQueue();
});

function rejectQueue() {
  log.warn(`rejecting ${retryQueue.length} queued requests`);
  retryQueue.forEach((request) => {
    request.reject(new Error('Unauthorized'));
  });

  retryQueue = [];
}

function submitQueue() {
  log.warn(`submitting ${retryQueue.length} queued requests`);
  retryQueue.forEach((request) => {
    request.resolve(true);
  });

  retryQueue = [];
}

const retryLink = new RetryLink({
  delay: {
    initial: 0,
  },
  attempts: {
    max: 2,
    retryIf: async (error) => {
      // If the token has expired and we get a 401 response, we need to
      // refresh the token before retrying the request
      if (error.statusCode === 401) {
        try {
          // For the first request, we will await the token refresh before
          // allowing retry
          if (!getIsAuthRefreshInProgress()) {
            await refreshTokenP();
            return true;
          }

          // We will queue subsequent retries until the token has been
          // refreshed, at which point these promises will either be
          // resolved to true (submitQueue) or rejected (rejectQueue)
          return new Promise((resolve, reject) => {
            retryQueue.push({ resolve, reject });
          });
        } catch (err) {
          return false;
        }
      }

      // Otherwise, attempt retry
      return true;
    },
  },
});

export { httpLink, batchHttpLink, preferredLink, authLink, retryLink, errorLink };
