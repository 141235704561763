/* eslint-disable no-param-reassign */
export const marshalSave = ({ formValues, beneficialOwnerDifferent, confirmationDateTime }) => {
  function removeEmptyStringUndefined(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        removeEmptyStringUndefined(obj[key]);
      } else if (obj[key] === '' || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }

  const removeEmptyObjects = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
        // eslint-disable-next-line no-param-reassign
        delete obj[key];
      }
    });
    return obj;
  };

  // We only want to send if it is true
  // Otherwise it will needlessly trigger validation on load
  if (formValues.acknowledgementCheckbox.confirmation === false) {
    // eslint-disable-next-line no-param-reassign
    delete formValues.acknowledgementCheckbox;
  }

  const reducedFormValues = removeEmptyStringUndefined(formValues);
  const noEmptyObjects = removeEmptyObjects(reducedFormValues);

  const saveData = {
    ...noEmptyObjects,
    beneficialOwnerDifferent,
    confirmationDateTime,
  };

  return saveData;
};
