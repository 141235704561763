angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.rate-sets', {
      template: '<sbb-rate-sets-settings></sbb-rate-sets-settings>',
      url: '/rate-sets',
      data: {
        navHighlights: ['rate-sets']
      }
    });
});
