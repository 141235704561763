import * as Yup from 'yup';
import moment from 'moment';

export const paymentPlanSchema = Yup.object().shape({
  debtor: Yup.string().required(),
  installmentAmount: Yup.number().integer().min(1).required(),
  installmentFrequency: Yup.string().required(),
  nextInstallmentDate: Yup.string()
    .required()
    .test('isOnOrAfterToday', 'must be on or after today', (value) =>
      moment(value).isSameOrAfter(moment().startOf('day')),
    ),
  inclusions: Yup.object()
    .required()
    .test(
      'notEmpty',
      'at least one matter must be included',
      (value) => value && Object.values(value).filter((isIncluded) => isIncluded).length > 0,
    ),
});
