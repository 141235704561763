import { store } from '@sb-itops/redux';
import { selectors as filterSelectors } from 'web/redux/route/home-billing-accounts-transactions';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions/ledger';

  $stateProvider
    .state('home.billing.accounts.transactions.ledger', {
      url: '/ledger',
      templateUrl: BASE_DIRECTORY + '/ledger.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'LEDGER',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          startDate: filterSelectors.getFilters(store.getState()).dateListFilter.startDate,
          endDate: filterSelectors.getFilters(store.getState()).dateListFilter.endDate,
        })
      },
      onEnter: function() {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.ledger', {}], parentPageName: PARENT_PAGE_NAME }));
      }, 
    });
});
