/* eslint-disable import/no-cycle */
import moment from 'moment';
import uuid from '@sb-itops/uuid';

import { opdateCache as opdatePayments, rollbackOpdateCache as rollbackPaymentsOpdate } from './payment-opdates';

import { opdateCache as opdateTransactions, rollbackOpdateCache as rollbackTransactionsOpdate } from '../transactions';

import {
  getById as getInvoiceTotalsById,
  buildInvoiceTotalsOpdates,
  opdateCache as opdateInvoiceTotals,
  rollbackOpdateCache as rollbackInvoiceTotalsOpdate,
} from '../invoice-totals';

import {
  getById as getMatterTotalsById,
  buildMatterTotalsOpdates,
  opdateCache as opdateMatterTotals,
  rollbackOpdateCache as rollbackMatterTotalsOpdate,
} from '../matter-totals';

// expose these methods through bank account balances 2
import {
  buildBankAccountBalanceOpdates,
  opdateCache as opdateBankAccountBalances,
  rollbackOpdateCache as rollbackBankAccountBalancesOpdate,
} from '../bank-account-balances';

import {
  getFirmBankAccount,
  getBankAccountMap as getBankAccountBalancesMap,
} from '../bank-account-balances.2/selectors';

const BANK_ACCOUNT_BALANCE_TYPES = {
  CONTACT: 0,
  MATTER: 1,
};

function buildReceivePaymentOpdateEntities({ accountId, userId, isContactBalanceFirm, receivePaymentMessage }) {
  // receivePaymentMessage has the same shape as ReceivePayment command, see .net code
  // or for quick ref: https://smokeball.slack.com/files/U031QTH3C/F01C9RT08SX/Untitled.cs
  //
  // totalAmount: paymentSummary.totalAmount,
  // effectiveDate: todayAsInteger(),
  // isTrustToOffice: true,
  // accountPayments: [
  //   {
  //     sourceAccountId: getTrustAccount().id,
  //     destinationAccountId: getOperatingAccount(),
  //     isElectronicPayment: REGION === 'US' ? false : !isCheque,
  //     isCheque,
  //     chequeId,
  //     chequePrintActive: isCheque,
  //     chequePrintMethod: isCheque && formData.printMethod,
  //     reference,
  //     chequeMemo: isCheque && REGION === 'US' ? formData.memo : undefined,
  //     payments: [
  //       {
  //         paymentId: "c88d31fc-2889-4d0e-9cca-0c07509683ed",
  //         transferBetweenAccountsTransactionId: "9acd8d5c-b044-49d8-99e8-fa7d24030369",
  //         matterId: "0503a005-106a-4525-9487-a1f61d1e3691",
  //         payorId: "05e593a6-63bb-4e89-a4eb-5bd0240fdcaa",
  //         invoices: [
  //           {
  //             "invoiceId": "1337f1c1-ab55-4775-8f34-ebf6959ec042",
  //             "amount": 9200
  //           }
  //         ]
  //       },
  //     ],
  //   },
  // ],

  const currentTimeStamp = moment().toISOString();
  const effectiveDate = receivePaymentMessage.effectiveDate;
  const reason = receivePaymentMessage.reason;
  const paymentOpdateEntities = [];
  const sourceAccountTransactionOpdateEntities = [];
  const invoiceTotalsOpdateEntitiesMap = {};
  const matterTotalsOpdateEntitiesMap = {};
  const bankAccountBalancesOpdateEntitiesMap = {};

  // multi payment id is used for TTO (Dec 2020), a temporary one is allocated here
  // for opdate purpose in order to group related payments/transactions together
  const tempMultiPaymentId = receivePaymentMessage.isTrustToOffice ? uuid() : undefined;

  receivePaymentMessage.accountPayments.forEach((accountPayment) => {
    accountPayment.payments.forEach((payment) => {
      // payment is for the invoices related to
      // 1) a matter for Matter Balance firms
      // 2) a matter/payor for Contact Baalnce firms

      // create invoice payment opdate entities
      const totalAmount = payment.invoices.reduce((acc, invoice) => acc + invoice.amount, 0);
      paymentOpdateEntities.push({
        $sbEntityType: 'Billing.Invoicing.Entities.Payment, Billing.Invoicing.Entities',
        accountId,
        userId,
        paymentId: payment.paymentId,
        sourceAccountId: accountPayment.sourceAccountId,
        destinationAccountId: accountPayment.destinationAccountId,
        sourceAccountBalanceType: isContactBalanceFirm
          ? BANK_ACCOUNT_BALANCE_TYPES.CONTACT
          : BANK_ACCOUNT_BALANCE_TYPES.MATTER,
        payorId: payment.payorId,
        matterId: payment.matterId,
        reference: accountPayment.reference,
        note: null,
        reason,
        source: null,
        reversedAt: null, // this field needs to be set to null otherwise entry will get filtered
        timestamp: currentTimeStamp,
        lastUpdated: currentTimeStamp,
        effectiveDate,
        totalAmount,
        chequePrintActive: accountPayment.chequePrintActive,
        chequeId: accountPayment.chequeId,
        overpaymentAccountId: null,
        isHidden: false,
        isQuickPayment: !!receivePaymentMessage.isQuickPayment,
        isTrustToOffice: !!receivePaymentMessage.isTrustToOffice,
        multiPaymentId: tempMultiPaymentId,
        invoices: payment.invoices,
        amount: totalAmount,
      });

      // for each payment, create its corresponding opdate transaction entities
      // but only for the source account, e.g. in the case of TTO, it's the trust bank account
      // there's usually another set of corresponding destination account transactions but
      // as we cannot specify the transaction ids for these, no opdate entities can be created
      // also it doesn't seem like these transactions are displayed anyway in the case of TTO
      sourceAccountTransactionOpdateEntities.push({
        $sbEntityType: 'Billing.Accounts.QueryHandlers.Model.Transaction, Billing.Accounts.QueryHandlers',
        id: payment.transferBetweenAccountsTransactionId,
        accountId,
        bankAccountId: accountPayment.sourceAccountId,
        matterId: payment.matterId,
        contactId: payment.payorId,
        paymentId: payment.paymentId,
        depositSlipId: null,
        reconciliationId: null,
        chequeId: null,
        amount: -totalAmount,
        description: `Transfer to accountId:${accountPayment.destinationAccountId} for payment of ${
          payment.invoices.length > 1 ? 'invoices' : 'invoice'
        } ${payment.invoices
          .map(
            (invoice, index) => ` #invoiceId:${invoice.invoiceId}${index === payment.invoices.length - 1 ? '' : ','}`,
          )
          .join('')}`,
        reference: accountPayment.reference,
        note: null,
        source: null,
        drawerBank: null,
        userId,
        timestamp: currentTimeStamp,
        effectiveDate,
        type: 'InvoicePayment',
        reversed: false,
        reason,
        isHidden: false,
        otherBankAccountNumber: null,
        otherBankAccountName: null,
        bankName: null,
        bankBranchName: null,
        bankBranchNumber: null,
        authorizedBy: null,
        reversedToTransactionId: null,
        reversedFromTransactionId: null,
        lastUpdated: currentTimeStamp,
        bankAccountType: 'Trust',
      });

      // goes through the invoices in each payment and create the following opdate entities
      // 1) invoice totals
      // 2) mattter totals
      // 3) bank account balances
      payment.invoices.forEach((invoicePayment) => {
        // since payment for Contact Balance firms are for each matter/payor group,
        // this means invoice total opdate needs to be calculated by taking into consideration
        // multiple payment entries/groups.

        // Create invoice total opdate entity, get invoice total running balance from local variable if available
        const invoiceTotal = invoiceTotalsOpdateEntitiesMap[invoicePayment.invoiceId] || {
          // paidByCredit may not be present in the invoice total
          paidByCredit: 0,
          ...getInvoiceTotalsById(invoicePayment.invoiceId),
        };
        const [invoiceTotalOpdateEntity] = buildInvoiceTotalsOpdates(invoiceTotal, invoicePayment);
        invoiceTotalsOpdateEntitiesMap[invoicePayment.invoiceId] = invoiceTotalOpdateEntity;

        // Create matter total opdate entity, get matter total running balance from local variable if available
        const matterTotal = matterTotalsOpdateEntitiesMap[payment.matterId] || {
          // paidByCredit may not be present in the matter total
          paidByCredit: 0,
          ...getMatterTotalsById(payment.matterId),
        };
        const [matterTotalOpdateEntity] = buildMatterTotalsOpdates(matterTotal, invoicePayment);
        matterTotalsOpdateEntitiesMap[payment.matterId] = matterTotalOpdateEntity;

        // Create bank account balances opdate, get running balance from local variable if available
        const trustBankAccountId = accountPayment.sourceAccountId;
        const operatingBankAccountId = accountPayment.destinationAccountId;
        const trustAccount =
          bankAccountBalancesOpdateEntitiesMap[trustBankAccountId] ||
          getFirmBankAccount(getBankAccountBalancesMap(), { bankAccountId: trustBankAccountId });
        const operatingAccount =
          bankAccountBalancesOpdateEntitiesMap[operatingBankAccountId] ||
          getFirmBankAccount(getBankAccountBalancesMap(), { bankAccountId: operatingBankAccountId });

        // munging payment data in a shape that allows us to make use of existing api for building opdate entities
        // for bank account balances, so matter and contact balances can be calculated and opdated correctly
        const [trustOpdate, operatingOpdate] = buildBankAccountBalanceOpdates({
          payment: {
            ...invoicePayment, // invoiceId and amount field
            matterId: payment.matterId,
            sourceAccountType: 'Trust',
            payors: [{ matterId: payment.matterId, payorId: payment.payorId, amount: invoicePayment.amount }],
          },
          operatingAccount,
          trustAccount,
          bankBalanceType: isContactBalanceFirm ? 'MatterContact' : 'Matter',
        });
        bankAccountBalancesOpdateEntitiesMap[trustBankAccountId] = trustOpdate;
        bankAccountBalancesOpdateEntitiesMap[operatingBankAccountId] = operatingOpdate;
      });

      // The following opdates are deemed not necessary, but could be added for
      // completeness in the future to avoid unnecessary user confusions if their
      // request takes a bit of time to process.

      // opdate invoices: status
      // {
      //   $type: "Billing.Invoicing.Entities.Invoice, Billing.Invoicing.Entities",
      //   AccountId: "76658e03-832f-4949-a8e7-905f0d124aa8",
      //   MatterId: "246c6382-3802-4565-9d6f-f76e7c675a62",
      //   InvoiceId: "f41f0273-5cf8-469e-8069-81d64a818554",
      //   FinalizedTimestamp: "2020-11-02T23:35:31.0484441Z",
      //   versionIds: [
      //     "43e7e8a2-08c4-41de-b1e8-238391b02110",
      //     "7507de86-18f1-4132-b6cf-700acd25360b",
      //     "c2e51851-7cb9-4177-a855-d552e9786641",
      //   ],
      //   invoicePdfVersionId: "c2e51851-7cb9-4177-a855-d552e9786641",
      //   currentVersion: {
      //     $type:
      //       "Billing.Invoicing.Entities.InvoiceVersion, Billing.Invoicing.Entities",
      //     VersionId: "c2e51851-7cb9-4177-a855-d552e9786641",
      //     MatterId: "246c6382-3802-4565-9d6f-f76e7c675a62",
      //     Debtors: [
      //       {
      //         $type: "Billing.Debtor, Billing.Shared",
      //         Id: "dd985c8e-717d-4972-b274-c83ce380c141",
      //         Ratio: 1,
      //       },
      //     ],
      //     Description: null,
      //     InvoiceNumber: 283,
      //     IssuedDate: 20201021,
      //     DueDate: 20201028,
      //     ValidTo: null,
      //     ValidFrom: "2020-11-02T23:35:31.0484441Z",
      //     Entries: [
      //       {
      //         $type: "Billing.InvoicedMatterEntryItem, Billing.Shared",
      //         Id: "fd1db853-ed58-4dfa-ad99-83cdefa80c2d",
      //         VersionId: "4d64a0d4-c182-48cc-a2b7-827067a5e164",
      //         Type: 1,
      //       },
      //     ],
      //     FeesOverallSummary: null,
      //     ExpensesOverallSummary: null,
      //     FeesTotalOverride: null,
      //     ExpensesTotalOverride: null,
      //     Discount: null,
      //     Layout: {
      //       $type:
      //         "Billing.Invoicing.Entities.InvoiceLayout, Billing.Invoicing.Entities",
      //       ExpenseLineItemConfiguration: 0,
      //       FeeLineItemConfiguration: 0,
      //       ShowStaffNameOnEntries: false,
      //       ShowRateDurationOnEntries: false,
      //       ShowRateOnEntries: false,
      //       ShowDurationOnEntries: false,
      //       FeeSummaryLineDescription: "",
      //       ExpenseSummaryLineDescription: "",
      //       ShowNonBillableFees: false,
      //       ShowNonBillableExpenses: false,
      //       ShowDescriptionForEntries: true,
      //       IncludeNonBillableItems: false,
      //     },
      //     AdditionalOptions: {
      //       $type:
      //         "Billing.Invoicing.Entities.AdditionalOptions, Billing.Invoicing.Entities",
      //       ShowInvoiceSummary: true,
      //       ShowAccountSummary: true,
      //       ShowTransactionHistory: true,
      //       HidePriorBalance: false,
      //       ShowSummaryForTimekeepers: true,
      //       HidePaymentSummary: false,
      //       ShowHoursSummary: false,
      //     },
      //     Status: "FINAL",
      //     PaidDate: 0,
      //     UserId: "165df54a-11ab-4f29-891e-a329bfd42237",
      //     InvoiceTitle: "JAMES-007 - Zhuo | Multi-Debtor - Buy - Third Party",
      //     InvoiceTitleLine2: "",
      //     Footer: "PHA+PGJyPjwvcD4=",
      //     Waived: false,
      //     MerchantPaymentReference: "aefda280-59c1-452e-ad88-b33f4cbf7888",
      //     FeeTaxRate: 0,
      //     OriginalBoostRouteHeader: "rc",
      //     IsOriginalInvoice: true,
      //     HasBeenFinalized: true,
      //     TemplateId: "b1a01a8f-2c8b-482a-b6fe-2b9070554f3a",
      //     InterestSettings: null,
      //     InterestEntries: [],
      //     InvoiceId: "f41f0273-5cf8-469e-8069-81d64a818554",
      //   },
      //   $sbSyncValue: "637399569310484441",
      // }
    });
  });

  return {
    paymentOpdateEntities,
    transactionOpdateEntities: sourceAccountTransactionOpdateEntities,
    invoiceTotalsOpdateEntities: Object.values(invoiceTotalsOpdateEntitiesMap),
    matterTotalsOpdateEntities: Object.values(matterTotalsOpdateEntitiesMap),
    bankAccountBalancesOpdateEntities: Object.values(bankAccountBalancesOpdateEntitiesMap),
  };
}

function opdateReceivePayment(opdateEntities) {
  const {
    paymentOpdateEntities,
    transactionOpdateEntities,
    invoiceTotalsOpdateEntities,
    matterTotalsOpdateEntities,
    bankAccountBalancesOpdateEntities,
  } = opdateEntities;

  // apply opdates
  opdatePayments({ optimisticEntities: paymentOpdateEntities });
  opdateTransactions({ optimisticEntities: transactionOpdateEntities });
  opdateInvoiceTotals({ optimisticEntities: invoiceTotalsOpdateEntities });
  opdateMatterTotals({ optimisticEntities: matterTotalsOpdateEntities });
  opdateBankAccountBalances({ optimisticEntities: bankAccountBalancesOpdateEntities });
}

function rollbackReceivePaymentOpdate(opdateEntities) {
  const {
    paymentOpdateEntities,
    transactionOpdateEntities,
    invoiceTotalsOpdateEntities,
    matterTotalsOpdateEntities,
    bankAccountBalancesOpdateEntities,
  } = opdateEntities;

  // apply rollbacks
  rollbackPaymentsOpdate({ optimisticEntities: paymentOpdateEntities });
  rollbackTransactionsOpdate({ optimisticEntities: transactionOpdateEntities });
  rollbackInvoiceTotalsOpdate({ optimisticEntities: invoiceTotalsOpdateEntities });
  rollbackMatterTotalsOpdate({ optimisticEntities: matterTotalsOpdateEntities });
  rollbackBankAccountBalancesOpdate({ optimisticEntities: bankAccountBalancesOpdateEntities });
}

export { buildReceivePaymentOpdateEntities, opdateReceivePayment, rollbackReceivePaymentOpdate };
