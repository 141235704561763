import { dot as nestedObjectToFlattened } from 'dot-object';
import { getById as getBankAccountById, isTrustAccount } from '@sb-billing/redux/bank-account';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';

export const validateFn = (formFields) => {
  const formErrors = {};
  // effective date
  if (formFields.bankAccountId) {
    const bankAccount = getBankAccountById(formFields.bankAccountId);
    if (
      isTrustAccount(bankAccount.accountType) &&
      isReconciled({
        yyyymmdd: formFields.effectiveDate,
        trustAccountId: formFields.bankAccountId,
      })
    ) {
      formErrors.effectiveDate = 'Warning: the date selected has already been reconciled';
    }
  }

  // Forms 2 expects errors to be reported as flattened dot object notation.
  return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
};
