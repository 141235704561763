import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPostP } from '@sb-itops/redux/fetch';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

export const restoreDeletedContact = (contactId) =>
  store.dispatch(async () => {
    const opDate = { entityId: contactId, removed: false };
    opdateCache({ optimisticEntities: [opDate] });

    try {
      const path = `/customer-management/contact/:accountId/restore/${contactId}/`;
      const fetchOptions = {};
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [opDate] });
      throw err;
    }
  });
