import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './HtmlWithTooltip.module.scss';

export const HtmlWithTooltip = ({ children, tooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnClick = (e) => {
    e.stopPropagation();
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const containerClasses = classnames(Styles.container, showTooltip && Styles.active);

  return (
    <div className={containerClasses} onClick={handleOnClick} onMouseLeave={handleMouseLeave}>
      {children}
      <div className={Styles.tooltip}>{tooltip}</div>
    </div>
  );
};

HtmlWithTooltip.displayName = 'HtmlWithTooltip';

HtmlWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node.isRequired,
};
