
'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/update-payment-method-complete';

  // This route is only required in case Stripe does a redirect after confirmSetup.
  // This could happen when the user enters a 3DS enabled card for payment.
  $stateProvider
    .state('update-payment-method-complete', {
      url: '/update-payment-method-complete',
      templateUrl: BASE_DIRECTORY + '/update-payment-method-complete.html',
      controllerAs: '$ctrl',
      controller: function($state, sbLinkService) {
        const ctrl = this;
        ctrl.onClickLink = sbLinkService.onClickLink;
      }
    });
});
