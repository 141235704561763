/* eslint-disable import/no-cycle */
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';

import { opdateCache, rollbackOpdateCache } from './index';

export const saveMatterInvoiceSettings = async (accountId, invoiceSettings) => {
  await store.dispatch(async () => {
    const { matterId, matterInvoiceSettings } = invoiceSettings;
    const invoiceSettingsToOpdate = {
      matterId,
      accountId,
      ...matterInvoiceSettings,
    };

    opdateCache({ optimisticEntities: [invoiceSettingsToOpdate] });

    try {
      await fetchPostP({
        path: '/billing/matter-invoice-settings/:accountId/',
        fetchOptions: { body: JSON.stringify({ matterId, ...matterInvoiceSettings }) },
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [invoiceSettingsToOpdate] });
      throw err;
    }
  });
};
