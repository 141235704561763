import React from 'react';
import PropTypes from 'prop-types';
import { Button, useTranslation } from '@sb-itops/react';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';

export const InvoiceAddPaymentButton = ({
  id,
  label,
  onClick,
  disabled,
  locked,
  asLink,
  hasUnpaidAnticipatedDisbursements,
}) => {
  const { t } = useTranslation();
  const onButtonClick = () =>
    featureActive('BB-9573') && hasUnpaidAnticipatedDisbursements ? setModalDialogVisible({ modalId }) : onClick();

  const modalId = `add-invoice-payment-unpaid-ad-modal-${id}`;

  return (
    <>
      {asLink ? (
        <LinkableText text={label} onClickLink={onButtonClick} asLink />
      ) : (
        <Button onClick={onButtonClick} disabled={disabled} locked={locked}>
          {label}
        </Button>
      )}
      {featureActive('BB-9573') && (
        <ConfirmationModalDialog
          onConfirm={() => {
            setModalDialogHidden({ modalId });
            onClick();
          }}
          modalId={modalId}
          title={`Unpaid Anticipated ${t('capitalizeAllWords', { val: 'expense' })}`}
          body={`This invoice contains an anticipated ${t(
            'expense',
          )} that has not yet been paid to the supplier. Are you sure you want to pay it?`}
          primaryButtonText="Add Payment"
        />
      )}
    </>
  );
};

InvoiceAddPaymentButton.displayName = 'InvoiceAddPaymentButton';

InvoiceAddPaymentButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  asLink: PropTypes.bool,
  hasUnpaidAnticipatedDisbursements: PropTypes.bool.isRequired,
};
InvoiceAddPaymentButton.defaultProps = {
  locked: false,
  disabled: false,
  asLink: false,
};
