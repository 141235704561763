import React from 'react';
import { Button, Modal } from '@sb-itops/react';
import classnames from 'classnames';
import { Staff, File, Document } from 'types';
import { FileDetailQuery } from 'web/graphql/types/graphql';
import { featureActive } from '@sb-itops/feature';
import { Cross } from 'web/components/icons';
import Styles from './Modal.module.scss';
import { ViewFileContainer as ViewFile } from './ViewFileContainer';
import { IconSelector } from '../icon-selector';

export interface IViewFileModalProps {
  onClose: () => void;
  formSubmitting: boolean;
  matterId: string;
  file: File;
  getPersonByUserId: (id: string) => Staff;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  getFileDetail: Function;
  setShowShareModal: (payload: { documents: Document[]; isLiving: boolean } | false) => void;
  // V2 properties
  onFileEditing?: (file: Document) => void;
  onUriHandled?: () => void;
  isFileManagerInstalled?: boolean;
  oneDriveIsUnauthorized?: boolean;
  isOpeningDocumentOnWeb?: boolean;
  openDocumentOnWeb?: (fileId: string) => Promise<void>;
}

export const ViewFileModal = (props: IViewFileModalProps) => {
  if (featureActive('NUCWEB-783')) {
    return (
      <Modal
        className={classnames(Styles.largeModal)}
        title=""
        showModalBodyPadding={false}
        isModalBodyScrollable={false}
        showFooter={false}
        modalBodyClassName={Styles.modalBody}
        body={
          <div className={Styles.newModal}>
            <div className={Styles.titleBar}>
              <div className={Styles.titleIcon}>
                <IconSelector fill="#1C2230" extension={props.file.data.fileExtension} isSolid />
              </div>
              <div className={Styles.heading}>{props.file.data.name + props.file.data.fileExtension}</div>
              <div onClick={props.onClose} style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                <Cross />
              </div>
            </div>
            <ViewFile
              getFileDetail={props.getFileDetail}
              fileDetailResult={props.fileDetailResult}
              file={props.file}
              matterId={props.matterId}
              getPersonByUserId={props.getPersonByUserId}
              setShowShareModal={props.setShowShareModal}
            />
          </div>
        }
        onModalClose={props.onClose}
      />
    );
  }
  return (
    <Modal
      className={classnames(Styles.modal, Styles.largeModal, Styles.noPadding)}
      title="File Details"
      body={
        <ViewFile
          getFileDetail={props.getFileDetail}
          fileDetailResult={props.fileDetailResult}
          file={props.file}
          matterId={props.matterId}
          getPersonByUserId={props.getPersonByUserId}
          setShowShareModal={props.setShowShareModal}
        />
      }
      footer={
        <Button
          size="full-width"
          onClick={() => props.onClose()}
          disabled={props.formSubmitting}
          locked={props.formSubmitting}
        >
          Close
        </Button>
      }
      onModalClose={props.onClose}
    />
  );
};
