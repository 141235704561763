import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react/button';

import Styles from './LoginXeroTwoFactorNotice.module.scss';

export const LoginXeroTwoFactorNotice = memo(({ onEnableTwoFactorClick, helpGuideUrl }) => (
  <div className={Styles.loginNoticeContainer}>
    <div className={Styles.headerText}>Important Notice</div>
    <p>As your firm uses Xero, you are now required to enable Two-Factor Authentication (2FA) on your account.</p>
    <p>To get started, click Enable 2FA below.</p>
    <Button className={Styles.enableTwoFactorButton} onClick={onEnableTwoFactorClick}>
      Enable 2FA
    </Button>
    {helpGuideUrl && (
      <div className={Styles.linksSection}>
        Need Help?&nbsp;
        <a href={helpGuideUrl} target="_blank" rel="noopener noreferrer">
          View our 2FA Help Guide
        </a>
      </div>
    )}
  </div>
));

LoginXeroTwoFactorNotice.displayName = 'LoginXeroTwoFactorNotice';

LoginXeroTwoFactorNotice.propTypes = {
  onEnableTwoFactorClick: PropTypes.func.isRequired,
  helpGuideUrl: PropTypes.string.isRequired,
};
