import { WidgetTitle } from './WidgetTitle';
import { WidgetSettings } from './WidgetSettings';
import { useOutsideAlerter } from './hooks';
import { WIDGET_DEFAULTS, getTextSize } from './WidgetDefaults';
import { TrustTooltipSummary } from './TrustTooltipSummary';
import { CreditTooltipSummary } from './CreditTooltipSummary';
import { FeeEstimateTooltip } from './FeeEstimateTooltip';
import { UnpaidTooltip } from './UnpaidTooltip';
import * as WidgetSettingsStyles from './WidgetSettings.module.scss';

export {
  WidgetTitle,
  WidgetSettings,
  WidgetSettingsStyles,
  WIDGET_DEFAULTS,
  getTextSize,
  useOutsideAlerter,
  TrustTooltipSummary,
  CreditTooltipSummary,
  FeeEstimateTooltip,
  UnpaidTooltip,
};
