'use strict';

import { getLogger } from '@sb-itops/fe-logger';

angular.module('sb.billing.webapp').component('sbLawPayOauthConnect', {
  templateUrl: 'ng-components/lawpay-oauth-connect/lawpay-oauth-connect.html',
  controller: function ($scope, $stateParams, sbGenericEndpointService, sbEnvironmentConfigService) {
    const ctrl = this;
    const log = getLogger('sbLawPayOauthConnect');

    // Update the status and force a digest cycle
    function changeStatus(status) {
      $scope.$applyAsync(() => {
        ctrl.status = status;
      });
    }

    (async function connectToLawpayP() {
      try {
        changeStatus('LOADING');

        const body = { authorizationCode: $stateParams.authorization_code, redirectUrl: sbEnvironmentConfigService.getLawPayRedirectUrl() };
        await sbGenericEndpointService.postPayloadP('/billing/payment-provider/connect/lawpay', undefined, body)
        
        changeStatus('SUCCESS');

      } catch (err) {
        changeStatus('FAILURE');
        log.error('Failed to connect to LawPay', err);
      }
    })();
  },
});
