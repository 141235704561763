import { fetchPostP } from '@sb-itops/redux/fetch';

/**
 * @typedef {Object} GraphQlQuery
 * @property {string} operationName
 * @property {string} query
 * @property {Object} variables
 */

/**
 * @typedef {Object} GraphQlResponse
 * @property {Object} data
 * @property {Object} errors
 */

/**
 * This should only be used where we cannot use the standard Load on Demand 
 * functionality for fetching data (ie, Angular controllers) and even then it 
 * should be temporary.
 * 
 * The advantages of using the standard LOD functionality:
 * 1. Apollo cache
 * 2. Apollo link to dedupe and concatenate GQL requests to prevent hammering the endpoint
 * 3. Notification subscription management
 * @param {[GraphQlQuery]} graphqlQuery 
 * @returns {GraphQlResponse}
 */
async function fetchGraphqlData (graphqlQuery) {
  const results = await fetchPostP({
    path: '/integration/webapp-graphql-server/:accountId/',
    fetchOptions: {
      body: JSON.stringify(graphqlQuery),
    }
  });
  return results.body[0];
}

module.exports = {
  fetchGraphqlData,
};