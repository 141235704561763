import { cacheFactory, syncTypes, store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { createSelector } from 'reselect';
import {
  encodeHtml,
  decodeHtml,
  stringsToEnums,
  enumsToStrings,
  createDefaultSettings,
} from '@sb-billing/encode-decode-html-invoice-settings';
import { featureActive } from '@sb-itops/feature';
// @ts-ignore
import { getUIState, selectTemplate } from 'web/redux/route/home-settings-invoice';
import { fetchPostP, fetchDeleteP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { getFirmDetails } from '@sb-firm-management/redux/firm-management';
import uuid from '@sb-itops/uuid';
import domain from '../domain';

const titleLineOption = ['None', 'MatterDescription', 'Custom', 'MatterTitle'];
const getLoggedInUserId = () => authSelectors.getUserId(store.getState());

const api = cacheFactory({
  domain,
  name: 'invoice-settings-template',
  keyPath: 'id',
  ngCacheName: 'sbInvoiceSettingsTemplateService',
  syncType: syncTypes.SINCE,
});

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoiceSettingsTemplateService',
  keyPath: 'id',
});

export const { getMap, getList, getById, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;

export const getTemplateByIdOrFirmDefault = (templateId) => {
  const template = getById(templateId);
  if (!templateId || !template) {
    return getFirmDefault();
  }
  return template;
};

/**
 * @deprecated
 * functions that get by id should return the entity or undefined.
 * returns the template with the specified id or the application default with a special temporary id
 */
export const getTemplateById = (id) => {
  if (id === 'application-default') {
    return getApplicationDefault();
  }

  const template = getById(id);

  if (template) {
    const templateFound = { ...template };
    templateFound.settings = decodeHtml({ ...templateFound.settings });
    templateFound.settings = enumsToStrings({ ...templateFound.settings });
    return templateFound;
  }

  const uiState = getUIState();
  const newTemplate = {
    name: (uiState[id] && uiState[id].name) || '',
    isDefault: false,
    isDeleted: false,
    createNew: true,
    settings: enumsToStrings(decodeHtml({ ...getDefaultTemplate().settings })),
  };

  return newTemplate;
};

export const getFirmDefault = () => {
  const firmDefault = getList().find((template) => template.isDefault);
  return firmDefault;
};

export const createFirmDefault = () => {
  const firmDefault = getFirmDefault();
  if (!firmDefault) {
    const newDefaultTemplate = {
      id: uuid(),
      isDefault: true,
      isDeleted: false,
      name: 'Firm Default',
      settings: generateDefaultSettings(),
    };
    return createFirmDefaultTemplate(newDefaultTemplate);
  }
  return firmDefault;
};

// Provides the settings to use for the firm's default to should be created on-demand.
// DO NOT USE FOR ANY OTHER PURPOSE
export const getApplicationDefault = () => ({
  id: 'application-default',
  isDefault: true,
  isDeleted: false,
  name: 'Firm Default',
  settings: generateDefaultSettings(),
});

/**
 * @deprecated
 * use getFirmDefault or getApplicationDefault explicitly
 * -----------------------------------------------------------------
 * the firm default template if found, or the applicaton default
 */
export const getDefaultTemplate = () => {
  const templates = getList();
  if (!templates || templates.length === 0) {
    return getApplicationDefault();
  }
  const value = templates.find((template) => template.isDefault);
  if (!value) {
    return getApplicationDefault();
  }
  return value;
};

const withStringAsEnums = createSelector(
  (state) => state || {},
  (matterInvoiceSetting) => ({
    ...matterInvoiceSetting,
    titleLine1Option:
      matterInvoiceSetting.titleLine1Option !== undefined && titleLineOption[matterInvoiceSetting.titleLine1Option],
    titleLine2Option:
      matterInvoiceSetting.titleLine2Option !== undefined && titleLineOption[matterInvoiceSetting.titleLine2Option],
  }),
);

export const getDefaultTemplateSettingsWithStringAsEnums = () => {
  const templatesSettings = getDefaultTemplate().settings;
  if (!templatesSettings) {
    return null;
  }
  return withStringAsEnums(templatesSettings);
};

export const getByTemplateIdSettingWithStringAsEnums = (templateId) => {
  const valueById = getById(templateId);
  if (valueById && valueById.settings) {
    return withStringAsEnums(valueById.settings);
  }
  return undefined;
};

export const saveTemplate = (templateToBeSaved) => {
  const template = { ...templateToBeSaved };

  const saveInvoiceSettingsTemplateThunk = async () => {
    const userId = getLoggedInUserId();
    template.settings = encodeHtml({ ...template.settings });
    template.settings = stringsToEnums({ ...template.settings });

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [{ ...template }] });

    // Apply the save in the backend.
    try {
      const path = `/billing/invoice-settings-template/:accountId/${userId}`;
      const fetchOptions = { body: JSON.stringify(template) };
      const response = await fetchPostP({ path, fetchOptions });
      return response;
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [{ ...template }] }); // This should really be the optimistic entity, but the code for opdating is truly baked.
      throw err;
    }
  };

  selectTemplate(templateToBeSaved.id);
  return store.dispatch(saveInvoiceSettingsTemplateThunk);
};

export const createFirmDefaultTemplate = (templateToBeSaved) => {
  const template = { ...templateToBeSaved };

  const createFirmDefaultTemplateThunk = async () => {
    const userId = getLoggedInUserId();
    template.settings = encodeHtml({ ...template.settings });
    template.settings = stringsToEnums({ ...template.settings });

    opdateCache({ optimisticEntities: [{ ...template }] });

    try {
      const path = `/billing/invoice-settings-template/:accountId/${userId}/default`;
      const fetchOptions = { body: JSON.stringify(template) };
      const response = await fetchPostP({ path, fetchOptions });
      return response;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [{ ...template }] });
      throw err;
    }
  };

  return store.dispatch(createFirmDefaultTemplateThunk);
};

export const deleteTemplate = (id) => {
  const deleteInvoiceSettingsTemplateThunk = async () => {
    const userId = getLoggedInUserId();
    const path = `/billing/invoice-settings-template/:accountId/${userId}/${id}`;
    await fetchDeleteP({ path });
  };

  return store.dispatch(deleteInvoiceSettingsTemplateThunk);
};

export const uploadAttachmentP = ({ pdfAttachment, templateId }) => {
  const uploadPdfAttachmentThunk = async () => {
    const path = templateId
      ? `/billing/invoice-pdf-attachment/:accountId/${templateId}`
      : `/billing/invoice-pdf-attachment/:accountId/`;

    const options = {
      pdfAttachment,
    };

    const fetchOptions = { body: JSON.stringify(options) };

    const res = await fetchPostP({ path, fetchOptions });
    return res.body && res.body.pdfS3Key;
  };

  return store.dispatch(uploadPdfAttachmentThunk);
};

export const deleteAttachment = (templateId) => {
  const deleteAttachmentThunk = async () => {
    const path = templateId
      ? `/billing/invoice-pdf-attachment/:accountId/${templateId}`
      : `/billing/invoice-pdf-attachment/:accountId`;

    await fetchDeleteP({ path });
  };

  return store.dispatch(deleteAttachmentThunk);
};

const generateDefaultSettings = () => {
  const firmDetails = getFirmDetails();
  const descriptionOnDemandFeatureEnabled = featureActive('BB-5725') && featureActive('BB-6865');
  return createDefaultSettings({ firmDetails, descriptionOnDemandFeatureEnabled });
};

export { getSignatureUrl } from './get-signature-url';
export { uploadSignatureFile } from './upload-signature-file';
export { deleteSignatureFile } from './delete-signature-file';
