'use strict';

const frequencyTypes = Object.freeze({
  WEEKLY: 0,
  MONTHLY: 1,
});

const installmentFrequencies = Object.freeze({
  WEEKLY: 'Weekly',
  BI_WEEKLY: 'BiWeekly',
  MONTHLY: 'Monthly',
  BI_MONTHLY: 'BiMonthly',
});

const installmentFrequenciesValues = Object.values(installmentFrequencies);

const paymentPlanPositionDisplayStatus = Object.freeze({
  AHEAD: 'Ahead',
  BEHIND: 'Behind',
  ON_TRACK: 'On track',
});

const paymentPlanStatuses = Object.freeze({
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
});

module.exports = {
  frequencyTypes,
  installmentFrequencies,
  installmentFrequenciesValues,
  paymentPlanPositionDisplayStatus,
  paymentPlanStatuses,
};
