import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import { ContactTypeIcon } from '@sb-customer-management/react/contact-type-icon';
import ContactDisplay from '@sb-customer-management/react/contact-display/ContactDisplay';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import Styles from './ContactPaymentPlanTable.module.scss';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

function displayNameCellRenderer({ rowData }) {
  return (
    <>
      <ContactTypeIcon contactType={rowData.type} />
      <span />
      <ContactDisplay contactId={rowData.id} displayName={rowData.displayName} inline showLastNameFirst />
    </>
  );
}

const paymentMethodStatusIcons = {
  expired: classnames(Styles.contactPaymentPlanIcon, Styles.error, 'icon', 'icon-icon-fat-exclamation-circle-filled'),
  failed: classnames(Styles.contactPaymentPlanIcon, Styles.error, 'icon', 'icon-icon-fat-exclamation-circle-filled'),
  expiryWarning: classnames(Styles.contactPaymentPlanIcon, Styles.warning, 'fa', 'fa-warning'),
  onTrack: '',
};

function paymentMethodCellRenderer({ rowData }) {
  const cellData = rowData.paymentMethod;
  if (!cellData) {
    return null;
  }

  return (
    <div title={cellData.statusDisplay}>
      {cellData.type}
      <i className={classnames(paymentMethodStatusIcons[cellData.status])} />
    </div>
  );
}

function statusCellRendererWithIcon({ cellData }) {
  if (!cellData) {
    return null;
  }

  const status = cellData.charAt(0).toUpperCase() + cellData.slice(1).toLowerCase();

  return (
    <div>
      <span>{status}</span>
      {status === 'Behind' && (
        <i
          className={classnames(
            Styles.contactPaymentPlanIcon,
            Styles.error,
            'icon',
            'icon-icon-fat-exclamation-circle-filled',
          )}
          title="This debtor is behind."
        />
      )}
    </div>
  );
}

const ContactPaymentPlanTable = memo(
  ({ dataLoading, debtorsWithPaymentPlans, onPaymentPlanSort, sortBy, sortDirection, onRowClick }) => {
    const { t } = useTranslation();
    return (
      <Table
        className={Styles.contactPaymentPlanTable}
        dataLoading={dataLoading}
        list={debtorsWithPaymentPlans}
        sort={onPaymentPlanSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onRowClick={(e) => onRowClick(e.rowData.id)}
      >
        <Column dataKey="displayName" label="name" flexGrow={3} cellRenderer={displayNameCellRenderer} />
        <Column dataKey="organization" label={t('organisation')} flexGrow={2} />
        <Column dataKey="phone" label="phone" flexGrow={1} className={Styles.phoneColumn} />
        <Column dataKey="cell" label={t('mobile')} flexGrow={1} className={Styles.cellMobileColumn} />
        <Column dataKey="email" label="email" flexGrow={3} className={Styles.emailColumn} />
        <Column
          dataKey="lastPaymentOnYYYYMMDD"
          label="last payment"
          flexGrow={1}
          cellRenderer={yyyymmddLocalisedRenderer}
        />
        <Column
          dataKey="paid"
          label="paid"
          className="right-align"
          flexGrow={1}
          cellRenderer={balanceCellLocalisedRenderer}
          disableSort
        />
        <Column
          dataKey="outstanding"
          label="outstanding"
          className="right-align"
          flexGrow={1}
          cellRenderer={balanceCellLocalisedRenderer}
        />
        <Column dataKey="status" label="status" width={90} cellRenderer={statusCellRendererWithIcon} />
        {featureActive('BB-5109') && [
          <Column
            key="planDetails"
            dataKey="planDetails"
            label="Plan Details"
            flexGrow={2}
            disableSort
            className={Styles.planDetailsColumn}
          />,
          <Column
            key="paymentMethodSortKey"
            dataKey="paymentMethodSortKey"
            label="type"
            width={120}
            cellRenderer={paymentMethodCellRenderer}
          />,
        ]}
      </Table>
    );
  },
);

ContactPaymentPlanTable.propTypes = {
  dataLoading: PropTypes.bool,

  /**
   * Debtors that have the payment plans setup
   */
  debtorsWithPaymentPlans: PropTypes.arrayOf(
    PropTypes.shape({
      debtorId: PropTypes.string,
      displayName: PropTypes.string,
      organization: PropTypes.string,
      phone: PropTypes.string,
      cell: PropTypes.string,
      email: PropTypes.string,
      lastPaymentOnYYYYMMDD: PropTypes.number,
      status: PropTypes.string,
      paid: PropTypes.number,
      outstanding: PropTypes.number,
      paymentMethod: PropTypes.shape({
        statusDisplay: PropTypes.string,
        status: PropTypes.string,
        type: PropTypes.string,
      }),
      planDetails: PropTypes.string,
      paymentMethodSortKey: PropTypes.string,
    }),
  ),

  /**
   * The callback to be invoked when user clicks to sort the payment plans.
   */
  onPaymentPlanSort: PropTypes.func.isRequired,

  /**
   * The field that the payment plans are currently sorted on.
   */
  sortBy: PropTypes.string,

  /**
   * The direction that the payment plans are currently sorted on.
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc']),

  onRowClick: PropTypes.func.isRequired,
};

ContactPaymentPlanTable.defaultProps = {
  dataLoading: false,
  debtorsWithPaymentPlans: [],
  sortBy: 'displayName',
  sortDirection: 'asc',
};

ContactPaymentPlanTable.displayName = 'ContactPaymentPlanTable';

export default ContactPaymentPlanTable;
