import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react';

const ButtonWithCount = ({ onClick, buttonText, count }) => {
  const disabled = count === 0;

  return <Button disabled={disabled} onClick={onClick}>{`${buttonText} (${count})`}</Button>;
};

ButtonWithCount.displayName = 'ButtonWithCount';

ButtonWithCount.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  count: PropTypes.number,
};

ButtonWithCount.defaultProps = {
  count: 0,
};

export default ButtonWithCount;
