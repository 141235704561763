import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { integerToDate } from '@sb-itops/date';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { DatePicker, CurrencyInput2, CurrencyDisplay, forms2PropTypes } from '@sb-itops/react';
import { ContactTypeahead } from '@sb-customer-management/react';
import Styles from './CreditCardPaymentForm.module.scss';

const { Forms2Field } = forms2PropTypes;

export const CreditCardPaymentForm = ({
  providerType,
  contactOptions,
  showReasonField,
  minAmountAllowed,
  // what user has selected
  payorId,
  paymentDate,
  paymentAmount,
  reason,
  balanceDueAfterPayment,
  clientIsCoveringFee,
  feeDetails,
  // form states & callbacks
  isSubmitting,
  formInitialised,
  onFormFieldChange,
}) => {
  if (!formInitialised) {
    return null;
  }

  const onAmountChanged = (event) => {
    onFormFieldChange({ key: paymentAmount.key, value: event.target.value });
  };

  const onReasonChanged = (event) => {
    event.preventDefault();
    onFormFieldChange({ key: 'reason', value: event.target.value });
  };

  return (
    <div className={Styles.creditCardPaymentForm}>
      <form name="creditCardPaymentForm">
        <fieldset>
          <div className="row">
            <div className="col-lg-6 form-group">
              <label>Paid By</label>
              <div
                className={classnames(Styles.contactField, payorId && payorId.isInvalid ? Styles.hasError : undefined)}
              >
                <ContactTypeahead
                  className={Styles.contactSelect}
                  contacts={contactOptions}
                  selectedContactId={payorId && payorId.value}
                  disabled
                />
              </div>
            </div>

            <div className="col-lg-6 form-group">
              <label>Date</label>
              <div className={paymentDate && paymentDate.isInvalid ? Styles.hasError : undefined}>
                <DatePicker
                  value={paymentDate && paymentDate.value && integerToDate(paymentDate.value)}
                  onSelect={() => {}}
                  hasError={paymentDate && paymentDate.isInvalid}
                  format="DD/MM/YYYY"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 form-group">
              <label>Amount</label>
              <CurrencyInput2
                className={paymentAmount && paymentAmount.isInvalid ? Styles.hasError : undefined}
                name="amount"
                value={paymentAmount && paymentAmount.value}
                onChange={onAmountChanged}
                min={minAmountAllowed}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label>Balance Due After Payment</label>
              <CurrencyDisplay amount={balanceDueAfterPayment} />
            </div>
          </div>

          {clientIsCoveringFee && (
            <div className={classnames('row', providerType === providers.EZY_COLLECT && Styles.thinRow)}>
              <div className="col-lg-6 form-group">
                <label>Total Amount of Charge</label>
                <CurrencyDisplay name="totalAmountOfCharge" amount={feeDetails.effectiveAmountInCents} />
              </div>
              <div className="col-lg-6 form-group">
                {providerType !== providers.EZY_COLLECT && <label>Includes {providerNames[providerType]} Fee</label>}
                {providerType === providers.EZY_COLLECT && <label>Includes payment processing fee</label>}
                <CurrencyDisplay name="feeComponent" amount={feeDetails.effectiveFeeInCents} />
              </div>
            </div>
          )}

          {clientIsCoveringFee && providerType === providers.EZY_COLLECT && (
            <div className="row">
              <div className={classnames('col-lg-12', Styles.feeInfo)}>
                <span>
                  <i className={classnames('fa', 'fa-info-circle', Styles.icon)} />
                  The fee will be calculated and the total charge updated after the card number is entered
                </span>
              </div>
            </div>
          )}

          {showReasonField && (
            <div className="row" ng-class="{'has-error': $ctrl.errors.reason}">
              <div className="col-lg-12 form-group">
                <label>Reason</label>
                <textarea
                  className={classnames('form-control', reason.isInvalid && Styles.hasError)}
                  value={reason.value}
                  maxLength={500}
                  onChange={onReasonChanged}
                  rows="5"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          )}
          <hr />
        </fieldset>
      </form>
    </div>
  );
};

CreditCardPaymentForm.displayName = 'CreditCardPaymentForm';

CreditCardPaymentForm.propTypes = {
  showReasonField: PropTypes.bool.isRequired,
  providerType: PropTypes.string.isRequired,
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ),
  minAmountAllowed: PropTypes.number,
  // what user has selected
  payorId: Forms2Field,
  paymentDate: Forms2Field,
  paymentAmount: Forms2Field,
  reason: Forms2Field,
  balanceDueAfterPayment: PropTypes.number,
  // form states & callbacks
  isSubmitting: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool,
  clientIsCoveringFee: PropTypes.bool,
  feeDetails: PropTypes.object,
  onFormFieldChange: PropTypes.func.isRequired,
};

CreditCardPaymentForm.defaultProps = {
  contactOptions: [],
  minAmountAllowed: 0,
  // what user has selected
  payorId: undefined,
  paymentDate: undefined,
  paymentAmount: undefined,
  reason: undefined,
  balanceDueAfterPayment: 0,
  clientIsCoveringFee: false,
  feeDetails: {},
  // form states & callbacks
  formInitialised: false,
};
