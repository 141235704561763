import { gql } from '@apollo/client';

const query = gql`
  query WidgetClientInfo($id: ID!, $filter: MatterFilter) {
    matter(id: $id, filter: $filter) {
      id
      clients {
        id
        displayName
        displayNameFirstLast
        cell
        email
        firstName
        lastName
        phone
        phone2
        type
        residentialAddress {
          addressLine0
          addressLine1
          addressLine2
          city
          state
          zipCode
          country
        }
      }
      otherSide {
        id
        displayName
        displayNameFirstLast
        cell
        email
        firstName
        lastName
        phone
        phone2
        type
        residentialAddress {
          addressLine0
          addressLine1
          addressLine2
          city
          state
          zipCode
          country
        }
      }
      billingConfiguration {
        id
        debtors {
          id
          displayName
          displayNameFirstLast
          cell
          email
          firstName
          lastName
          phone
          phone2
          type
          residentialAddress {
            addressLine0
            addressLine1
            addressLine2
            city
            state
            zipCode
            country
          }
        }
      }
    }
  }
`;

const notificationIds = ['CustomerManagementNotifications', 'MatterManagementWebQuery.MatterUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const WidgetClientInfo = {
  query,
  notificationIds,
};
