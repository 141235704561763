import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import {
  isLeadMatter,
  isSaving as isMatterSaving,
  isDeleted as isMatterDeleted,
} from '@sb-matter-management/redux/matters';
import { getTrustAccounts } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { MatterActions } from './MatterActions';

const hooks = ({ matterId }) => ({
  useMatterDetailsHandler: () => {
    const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);
    const isItemStatutoryDepositMatter = useSelector(() =>
      isStatutoryDepositMatter({
        matterId,
        trustBankAccounts: getTrustAccounts(),
        supportsStatutoryDepositMatter,
      }),
    );
    const isDeleted = useSelector(() => isMatterDeleted(matterId));
    const isLead = useSelector(() => isLeadMatter(matterId));
    const isSaving = useSelector(() => isMatterSaving(matterId));
    return {
      isDeleted,
      isEditable: !isItemStatutoryDepositMatter && !isDeleted,
      isSaving,
      isLead,
    };
  },
});

export const MatterActionsContainer = withReduxProvider(composeHooks(hooks)(MatterActions));

MatterActionsContainer.displayName = 'MatterActionsContainer';

MatterActionsContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

MatterActionsContainer.defaultProps = {};
