import { store } from '@sb-itops/redux';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';

angular.module('sb.billing.webapp').component('sbTypeahead',
  {
    'templateUrl': 'ng-components/typeahead/typeahead.html',
    'bindings': { updateMode: '@?', typeaheadType: '@', filter: '@', placeHolder: '@', disabledFlag: '<', hasErrorFlag: '<', initialId: '<', clearInvalidOnBlur: '<?', blur: '&', focus: '@', onSelect: '&', onClear: '&', onPostBlur: '&', name: '@?' },
    'require': { ngModel: 'ngModel' },
    'controller': function ($element, $scope, $timeout, sbLoggerService) {
      'use strict';
      var ctrl = this, initialized, log = sbLoggerService.getLogger('sbTypeahead', $scope.$id);

      var pendingId;

      const unsubscribeFromStore = store.subscribe(_.debounce(() => {
        loadTypeahead();
      }, 100));

      ctrl.$onInit = function () {
        log.info('typeahead type %s, placeholder %s, disabledFlag %s, hasErrorFlag %s, initialId %s, focus %s, name %s',
          ctrl.typeaheadType,
          ctrl.placeHolder,
          ctrl.disabledFlag,
          ctrl.hasErrorFlag,
          ctrl.initialId,
          ctrl.focus,
          ctrl.name
        );

        if (ctrl.initialId !== undefined && !ctrl.updateMode) {
          ctrl.ngModel.$setViewValue(ctrl.initialId);
        }
        loadTypeahead();
        initialized = true;

        $scope.$watch('$ctrl.ngModel.$viewValue', function () {
          var same = ctrl.ngModel.$viewValue === (ctrl.tao && ctrl.tao.id);
          if (!same) {
            setTaoFromId(ctrl.ngModel.$viewValue);
          }
        });
      };

      ctrl.$onChanges = function (changes) {
        if (initialized) {
          if (changes.typeaheadType) {
            ctrl.typeahead = undefined;
            ctrl.tao = undefined;
            loadTypeahead();
          }
          if (changes.initialId) {
            setTaoFromId(ctrl.initialId);
          }
        }
      };

      ctrl.$onDestroy = () => {
        unsubscribeFromStore();
      };

      ctrl.taoOnSelected = function (tao) {
        log.info('set value on model', tao);
        ctrl.ngModel.$setViewValue(tao && tao.id);
        ctrl.onSelect();
      };

      ctrl.clearTao = function () {
        ctrl.tao = undefined;
        ctrl.ngModel.$setViewValue();
        $timeout(function () {
          $element.find('input').focus(); // needs to happen after next digest because field currently disabled
        });
        if(_.isFunction(ctrl.onClear())){
          ctrl.onClear();
        }
      };

      ctrl.processBlur = function() {
        if (ctrl.clearInvalidOnBlur && ctrl.tao && !ctrl.tao.id) {
          ctrl.tao = undefined;
          ctrl.ngModel.$setViewValue();
        }

        if (_.isFunction(ctrl.blur)) {
          ctrl.blur();
        }

        if (_.isFunction(ctrl.onPostBlur)) {
          $timeout(ctrl.onPostBlur);
        }
      };

      function loadTypeahead() {
        // only get contact typeahead data - we can do this as this is an intermediate change as this component is getting replaced by react!
        ctrl.typeahead = getContactTypeAheadSummaries();

        if(!ctrl.tao) {
          setTaoFromId(pendingId || ctrl.initialId);
        }
        else{
          setTaoFromId(ctrl.tao.id);
        }
      }

      function setTaoFromId(id) {
        ctrl.tao = (id === undefined) ? undefined : getTao(id);

        // if the typeahead didnt contain the contact with the specified id, save that ID so it can be checked next
        // time the typeahead gets updated
        if (!ctrl.tao) {
          pendingId = id;
        } else {
          pendingId = undefined;
        }

        log.info('set typeahead from id', id, ctrl.tao);
      }

      function getTao(id) {
        return _.find(ctrl.typeahead, function (tao) {
          return tao && tao.id === id;
        });
      }
    }
  }
);
