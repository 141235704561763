angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/entries/expenses';

  $stateProvider

  .state('home.billing.entries.expenses', {
    url: '/expenses',
    templateUrl: BASE_DIRECTORY + '/entries-expenses.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'entries']
    }
  });

});
