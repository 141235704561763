'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { featureActive } from '@sb-itops/feature';
import {
  getList,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/fee-configuration';

const getAccountId = () => authSelectors.getAccountId(store.getState());

angular.module('@sb-billing/services').service('sbFeeConfigurationService', function (sbGenericEndpointService, sbGenericCacheService, sbEndpointType, sbUuidService) {
  const that = this,
    endpoint = 'billing/fee-configuration',
    allowedIntervals = [6, 15],
    defaultInterval = 6;
  
  if (featureActive('BB-4832')) {
    allowedIntervals.unshift(1);
  }

  sbGenericCacheService.setupCache({
    name: 'sbFeeConfigurationService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
      poll: 60,
      subscriptions: 'notifier.FeesNotifications.FeeConfigurationUpdated'
    },
    updateRedux,
    clearRedux,
  }),

  //log.setLogLevel('info');

  that.getAllowedIntervals = getAllowedIntervals;
  that.getFeeInterval = getFeeInterval;
  that.setFeeIntervalP = setFeeIntervalP;

  function getAllowedIntervals() {
    return _.cloneDeep(allowedIntervals);
  }

  function getFeeInterval() {
    var cached = getList()[0];

    if (!cached) {
      return defaultInterval;
    } else {
      return cached.billableMinutes;
    }
  }

  function getOpdates (data) {
    return {
      sbFeeConfigurationService: [data]
    };
  }

  function setFeeIntervalP(mins) {
    var data = {};
    if (_.contains(allowedIntervals, mins)) {
      data.accountId = getAccountId();
      data.billableMinutes = mins;
      data.versionId = sbUuidService.get();

      return sbGenericEndpointService.postPayloadP(endpoint, undefined, data, 'POST', {changeset: getOpdates(data)});
    } else {
      throw new Error('invalid interval ' + mins);
    }
  }

});
