import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';

import { regionType, getRegion } from '@sb-itops/region';
import { PaymentProviderIntegration } from './PaymentProviderIntegration';

const REGION = getRegion();

const uiStrategy = {
  [regionType.AU]: {
    showNewUI: () => hasFacet(facets.integrationStripe) && featureActive('BB-12741'),
  },
  [regionType.GB]: {
    showNewUI: ({ activeProvider }) =>
      // In case the feature switch is OFF, but Stripe is active, we still want to see new settings UI so user can deactivate Stripe
      (hasFacet(facets.integrationStripe) && featureActive('BB-12741')) || activeProvider === providers.STRIPE,
  },
  [regionType.US]: {
    showNewUI: ({ activeProvider }) =>
      // In case the feature switch is OFF, but FeeWise is active, we still want to see new settings UI
      // so user can deactivate FeeWise. After deactivation, old settings just with LP is shown.
      (hasFacet(facets.integrationFeeWise) && featureActive('BB-12038')) || activeProvider === providers.FEE_WISE,
  },
};

const mapStateToProps = () => {
  const activeProvider = getActiveProvider();
  const providerUIStrategy = uiStrategy[REGION];

  return {
    activeProvider,
    showNewUIProviderSettings: providerUIStrategy?.showNewUI({ activeProvider }) || false,
  };
};

const mapDispatchToProps = () => ({});

export const PaymentProviderIntegrationContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(PaymentProviderIntegration),
);

PaymentProviderIntegrationContainer.displayName = 'PaymentProviderIntegrationContainer';

PaymentProviderIntegrationContainer.propTypes = {};

PaymentProviderIntegrationContainer.defaultProps = {};
