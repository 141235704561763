import { getAllStaffWithUserIds, getLoggedInUserId } from '@sb-firm-management/redux/firm-management';
import { withOnLoad } from '@sb-itops/react/hoc';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

import SendFilesViaCommunicateForm from './SendFilesViaCommunicateForm';
import { SendFilesViaCommunicateSchema } from './SendFilesViaCommunicateSchema';
import { getValidateFn } from './validation';

const hooks = (props) => ({
  useState: () => {
    const {
      isLiving,
      matterId,
      onSelectedContactsUpdate,
      onStaffIdsUpdate,
      parties,
      partiesLoading,
      removeSelectedDocument,
      setShowSelectFilesModal,
      scope,
      selectedContacts,
      selectedDocuments,
      staffIds,
    } = props;

    const {
      formSubmitting,
      formInitialised,
      formValidation,
      formFields,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFields,
      onValidateForm,
    } = useForm({ scope, schema: SendFilesViaCommunicateSchema, validateFn: getValidateFn(selectedContacts) });

    const { isMfaRequired, message } = formFields;

    const loggedInStaffId = getLoggedInUserId();
    const staffOptions = getAllStaffWithUserIds().filter((staff) => staff.userId && staff.userId !== loggedInStaffId);

    const onContactsChanged = (selectedIds) => {
      const contactIdMap = {};
      const deduplicatedIds = selectedIds.filter((contactId) => {
        if (contactId && !contactIdMap[contactId]) {
          contactIdMap[contactId] = true;
          return true;
        }
        return false;
      }, []);
      const newContacts = parties.filter(({ id }) => deduplicatedIds.includes(id));
      onSelectedContactsUpdate(newContacts);
    };

    return {
      scope,
      isLiving,
      isMfaRequired,
      matterId,
      message,
      parties,
      partiesLoading,
      removeSelectedDocument,
      setShowSelectFilesModal,
      staffIds,
      staffOptions,
      selectedContacts,
      selectedDocuments,
      formDisabled: formSubmitting,
      formInitialised,
      formValidation,
      submitFailed,
      onLoad: () => {
        const fieldValues = {
          isMfaRequired: false,
          message: '',
        };

        onInitialiseForm(fieldValues);
        return onClearForm;
      },
      onContactsChanged,
      onStaffIdsUpdate,
      onUpdateFields,
      onValidateForm,
    };
  },
});

export const SendFilesViaCommunicateFormContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(SendFilesViaCommunicateForm)),
);

SendFilesViaCommunicateFormContainer.displayName = 'SendFilesViaCommunicateFormContainer';

SendFilesViaCommunicateFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  isLiving: PropTypes.bool,
  matterId: PropTypes.string,
  onSelectedContactsUpdate: PropTypes.func.isRequired,
  onStaffIdsUpdate: PropTypes.func.isRequired,
  parties: PropTypes.arrayOf(PropTypes.object).isRequired,
  partiesLoading: PropTypes.bool.isRequired,
  removeSelectedDocument: PropTypes.func.isRequired,
  setShowSelectFilesModal: PropTypes.func.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),
  staffIds: PropTypes.arrayOf(PropTypes.string),
};
SendFilesViaCommunicateFormContainer.defaultProps = {
  isLiving: undefined,
  matterId: undefined,
  selectedContacts: [],
  selectedDocuments: undefined,
  staffIds: [],
};

export default SendFilesViaCommunicateFormContainer;
