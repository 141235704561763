import { Spinner } from '@sb-itops/react';
import { FeeSummaryChart } from '@sb-billing/react';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './BillableGraphWidget.module.scss';

export const BillableGraphWidget = ({
  isEditing,
  chartFees,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  selectedMonth,
  onMonthChange,
}) => (
  <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
    <WidgetTitle
      isEditing={isEditing}
      onClick={() => onClickLink({ type: 'timeAndFees' })}
      onChange={onChange}
      onRemove={onRemove}
      settings={settings}
    />
    {loading ? (
      <div className={WidgetStyles.loading}>
        <Spinner small />
      </div>
    ) : (
      <div className={Styles.chart}>
        <FeeSummaryChart
          fees={chartFees}
          isWidget
          dataLoading={loading}
          selectedMonthYYYYMM={selectedMonth.format('YYYYMM')}
          onMonthChange={onMonthChange}
          // No reason to select the day in this chart, it will just confuse the user
          selectedDayYYYYMMDD={moment().format('YYYYMMDD')}
          onDayChange={() => {}}
        />
      </div>
    )}
  </div>
);
