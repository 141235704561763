'use strict';

const { getPaymentSource: getLawpayPaymentSource } = require('./lawpay');
const { getPaymentSource: getStripePaymentSource } = require('./stripe');
const { getPaymentSource: getEzyCollectPaymentSource } = require('./ezy-collect');
const { getPaymentSource: getFeeWisePaymentSource } = require('./fee-wise');
const { providers } = require('../entities/constants');

const providerSpecificPaymentSourceFns = {
  [providers.LAWPAY]: getLawpayPaymentSource,
  [providers.STRIPE]: getStripePaymentSource,
  [providers.EZY_COLLECT]: getEzyCollectPaymentSource,
  [providers.FEE_WISE]: getFeeWisePaymentSource,
};

/**
 * getPaymentSource
 *
 * Parses the provider specific charge fields to produce a "payment source". The payment source is used by the charge endpoint to
 * populate the source field for a payment / deposit transaction.
 *
 * This should return a value from PAYMENT_SOURCE in `billing/business-logic/payment-source/index.js`
 *
 * @param {Object} params
 * @param {string} params.providerType The provider type that was used to create the charge data.
 * @param {Object} params.providerSpecificFields The provider specific data received from a charge form.
 *
 * @returns {string} The payment source
 */
const getPaymentSource = ({ providerType, providerSpecificFields }) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!providerSpecificFields) {
    throw new Error('providerSpecificFields must be provided');
  }

  const providerSpecificPaymentSourceFn = providerSpecificPaymentSourceFns[providerType];
  if (!providerSpecificPaymentSourceFn) {
    throw new Error(`Failed to retrieve payment source for unsupported provider type '${providerType}'`);
  }

  return providerSpecificPaymentSourceFn({
    providerSpecificFields,
  });
};

module.exports = {
  getPaymentSource,
};
