import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

export const api = cacheFactory({
  domain,
  name: 'trust-receipt-settings',
  keyPath: 'bankAccountId',
  ngCacheName: 'sbTrustReceiptSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
  changesetProcessor: ({ entities, stateList }) => {
    if (!entities[0]) {
      return [];
    }

    if (!stateList[0]) {
      return entities;
    }

    if (entities[0].versionIds.includes(stateList[0].versionId)) {
      return entities;
    }

    return [];
  },
});
