'use strict';

const { search } = require('./index');

angular.module('@sb-itops/services').service('sbGenericSearchService', function() {
  const that = this;

  that.search = search;

});
