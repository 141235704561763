angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/advanced-search/invoices';

  $stateProvider
    .state('home.billing.advanced-search.invoices', {
      url: '/invoices?searchTerm',
      templateUrl: BASE_DIRECTORY + '/invoices.html',
      data: {
        authorized: true,
        navHighlights: ['billing']
      },
      reloadOnSearch: false,
    });

});
