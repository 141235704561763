import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import {
  getById as getContactById,
  generateContactSummaryOpdate,
  generateContactEntityOpdate,
} from '@sb-customer-management/redux/contacts';

// sbContactsMbService AKA contact summary
const { opdateCache: opdateContactsMbService } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

// sbSimpleContactMbService AKA ironically the complex contact entity cache (includes address)
const { opdateCache: opdateSimpleContactMbService } = optimisticUpdateFactory({
  ngCacheName: 'sbSimpleContactMbService',
  keyPath: 'entityId',
});

// sbCustomerBillingConfigurationService
const { opdateCache: opdateCustomerBillingConfigurationService } = optimisticUpdateFactory({
  ngCacheName: 'sbCustomerBillingConfigurationService',
  keyPath: 'entityId',
});

export const onCreateEditPersonContact = ({ commandMessage, commandResult }) => {
  const contactEntity = {
    contactType: commandMessage.contactFields.contactType,
    ...commandMessage.contactFields.personFields,
    ...commandMessage.contactFields.addressFields,
  };

  const { contact, customerBillingConfiguration } = commandResult;

  // sbContactsMbService
  const opdateSummaryEntity = generateContactSummaryOpdate({ id: contact.id, contact: contactEntity });
  opdateContactsMbService({ optimisticEntities: [opdateSummaryEntity] });

  // sbSimpleContactMbService
  const opdateEntity = generateContactEntityOpdate({ id: contact.id, contact: contactEntity });
  const opdateEntities = [opdateEntity];

  if (contact.linkedCompany) {
    // May be added through a company contact page to a company - need to opdate contact cards which reads entity.people
    const cachedCompanyEntity = getContactById(contact.linkedCompany);
    if (cachedCompanyEntity) {
      if (cachedCompanyEntity.people && Array.isArray(cachedCompanyEntity.people)) {
        cachedCompanyEntity.people.push(opdateEntity);
      } else {
        cachedCompanyEntity.people = [opdateEntity];
      }

      opdateEntities.push(cachedCompanyEntity);
    }
  }

  opdateSimpleContactMbService({ optimisticEntities: opdateEntities });

  // sbCustomerBillingConfigurationService
  if (customerBillingConfiguration) {
    const newCustomerBillingConfiguration = {
      entityId: contact.id,
      ledesClientId: commandMessage.contactFields.personFields.ledesClientId,
      versionId: customerBillingConfiguration.versionId,
    };

    opdateCustomerBillingConfigurationService({ optimisticEntities: [newCustomerBillingConfiguration] });
  }
};
