angular.module('sb.billing.webapp').controller('sbViewMatterFeesController', function ($scope, $state, sbLinkService, $stateParams, sbMattersMbService, sbLoggerService) {
  'use strict';

  var that = this;
  var log = sbLoggerService.getLogger('sbViewMatterFeesController');

  that.feeListData = [];

  that.view = {
    matterId: $stateParams.matterId
  };

  that.isEditableMatter = isEditableMatter;

  that.sbData = {};
  that.dataChangeFunction = dataChangeFunction;

  that.onNavigateToInvoice = (id) => sbLinkService.onClickLink({ type: 'invoice', id });

  that.getFeeListScope = getFeeListScope;

  that.onClickLink = sbLinkService.onClickLink;

  that.onCreateInvoice = (matterId) => $state.go('home.billing.create-bill', { matterId });

  update();

  $scope.$on('smokeball-data-update-sbMattersMbService',   update);
  $scope.$on('smokeball-data-update-sbMatterTypesService', update);

  function getFeeListScope() {
    const displayMode = 'matter';
    const matterId = that.view.matterId;
    const scope = `${displayMode}-fee-list${matterId ? `-${matterId}` : ''}`;
    return scope;
  }

  function update() {
    log.info('matter id', $stateParams.matterId);

    if ($stateParams.matterId) {
      that.view = sbMattersMbService.getById($stateParams.matterId);
    }
  }

  function isEditableMatter() {
    return sbMattersMbService.isEditableMatter($stateParams.matterId);
  }

  function dataChangeFunction(key, doc) {
    if (doc && doc.data) {
      _.set(that.sbData, key, doc.data);
    } else {
      _.set(that.sbData, key, doc);
    }
  }
});
