/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from '@sb-itops/react';
import Styles from './GenerateLogoModal.module.scss';

export const GenerateLogoModal = ({
  promptText,
  onPromptTextChange,
  generationInProgress,
  imageUrl,
  isVisible,
  onGenerateLogo,
  onApplyGeneratedImageAsFirmLogo,
  onClose,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      className={Styles.generateLogoModal}
      isVisible={isVisible}
      title="Use AI to Generate Firm Logo with AI"
      body={createGenerateFirmLogoBody({
        imageUrl,
        promptText,
        onPromptTextChange,
        generationInProgress,
        onGenerateLogo,
        onApplyGeneratedImageAsFirmLogo,
      })}
      footer={
        <div>
          <Button onClick={onGenerateLogo} disabled={generationInProgress}>
            Generate Logo using AI
            {'  '}
            {generationInProgress && <Spinner small />}
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  );
};

const createGenerateFirmLogoBody = ({
  imageUrl,
  promptText,
  onPromptTextChange,
  generationInProgress,
  onApplyGeneratedImageAsFirmLogo,
}) => (
  <div className={Styles.modalBody}>
    <div className={Styles.brandingContainer}>
      <marquee className={Styles.rainbowText} direction="right">
        POWERED BY AI
      </marquee>
    </div>
    <div className={Styles.contentContainer}>
      <div className={Styles.textAreaContainer}>
        <label>Enter a Logo Description</label>
        <textarea
          className={classnames(Styles.description, generationInProgress && Styles.disabled)}
          value={promptText || ''}
          onChange={(e) => onPromptTextChange(e.target.value)}
          rows={8}
          disabled={generationInProgress}
          maxLength={200}
        />
      </div>
      <div className={Styles.previewContainer}>
        <div className={Styles.logoPreview}>{imageUrl && <img width="100%" alt="Logo preview" src={imageUrl} />}</div>
        <div className={Styles.links}>
          {imageUrl && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <span className={Styles.fakeLink} onClick={onApplyGeneratedImageAsFirmLogo}>
              Apply as Invoice Logo
            </span>
          )}
        </div>
      </div>
    </div>
    <div className={Styles.brandingContainer}>
      <marquee className={Styles.rainbowText} behavior="alternate">
        POWERED BY AI
      </marquee>
    </div>
  </div>
);
GenerateLogoModal.displayName = 'GenerateLogoModal';

GenerateLogoModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  generationInProgress: PropTypes.bool.isRequired,
  promptText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onPromptTextChange: PropTypes.func.isRequired,
  onGenerateLogo: PropTypes.func.isRequired,
  onApplyGeneratedImageAsFirmLogo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

GenerateLogoModal.defaultProps = {
  imageUrl: undefined,
};
