import * as React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@sb-itops/react/modal';
import { Button } from '@sb-itops/react/button';

import { AdjustmentForm } from '../adjustment-form';

const AdjustmentModal = React.memo(({ formName, modalTitle, onSubmitHandler, onModalClosed }) => {
  const onAdjustmentModalClosed = () => {
    onModalClosed();
  };

  return (
    <Modal
      title={modalTitle}
      body={<AdjustmentForm scope="adjustment-form" formName={formName} onSubmitHandler={onSubmitHandler} />}
      footer={
        <div className="adjustments-footer row">
          <Button className="col-lg-4" form={formName} size="full-width">
            Add
          </Button>
        </div>
      }
      onModalClose={onAdjustmentModalClosed}
    />
  );
});

AdjustmentModal.displayName = 'AdjustmentModal';

AdjustmentModal.propTypes = {
  formName: PropTypes.string.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClosed: PropTypes.func.isRequired,
};

export default AdjustmentModal;
