import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

export const { UPDATE_CACHE, getMap, getList, getById, updateCache } = cacheFactory({
  domain,
  name: 'registered-users',
  keyPath: 'firmId',
  ngCacheName: 'sbRegisteredUsersService',
  syncType: syncTypes.ALL,
});

export const getRegisteredUserIds = () => (getList()[0] && getList()[0].userIds) || undefined;
