'use strict';

/**
 * isClientCardSavingEnabledForBankAccount
 *
 * @returns {boolean}
 */
const isClientCardSavingEnabledForBankAccount = () => false;

module.exports = {
  isClientCardSavingEnabledForBankAccount,
};
