import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CurrencyInput2, useTranslation } from '@sb-itops/react';
import Styles from './SplitPayorsList.module.scss';

const getTotal = ({ payors }) =>
  Object.values(payors).reduce((total, amount) => (Number.isInteger(amount) ? total + amount : total), 0);

const getAmount = (formData, payorId) => formData.payors[payorId] || 0;

// this component is very strict in the way the api was designed. Is very coupled on how the split Payor works and because of that is not quite re-usable.
// TODO migrate it to use the InvoicePaymentAllocationTable.
const SplitPayorsList = ({ payors, formData, onChange, onSelect }) => {
  const onChangeHandler = useCallback(
    (payor, { value }) => {
      onChange(payor, value);
    },
    [onChange],
  );

  const onSelectHandler = useCallback(
    (payor, { checked }) => {
      onSelect(payor, checked);
    },
    [onSelect],
  );

  const { t } = useTranslation();
  const formatCurrency = (cents) => t('cents', { val: cents });

  return (
    <div className="split-payors-list sb-table">
      <div className="sb-table-header">
        <div className="sb-table-cell contact">Contact</div>
        <div className="sb-table-cell available">Available</div>
        <div className="sb-table-cell amount">Amount</div>
        <div className="sb-table-cell amount-all">All</div>
      </div>
      <div className="sb-table-content list" style={{ maxHeight: '200px' }}>
        {[...payors].map((payor) => (
          <div className="item" key={payor.id}>
            <div className="sb-table-cell contact">
              <div className={Styles.payorContainer}>
                <div className={Styles.payorName}>{payor.displayName}</div>
              </div>
            </div>
            <div className="sb-table-cell available">{formatCurrency(payor.available)}</div>
            <div className="sb-table-cell amount">
              <CurrencyInput2
                name="amount"
                value={getAmount(formData, payor.id)}
                onChange={(e) => onChangeHandler(payor, e.currentTarget)}
              />
            </div>
            <div className="sb-table-cell amount-checkbox">
              <label>
                <input
                  name="allCheckbox"
                  className="form-control checkbox"
                  type="checkbox"
                  onClick={(e) => onSelectHandler(payor, e.currentTarget)}
                />
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="sb-table-footer item">
        <div className="sb-table-cell contact" />
        <div className="sb-table-cell available" />
        <div className="sb-table-cell amount">{formatCurrency(getTotal(formData))}</div>
        <div className="sb-table-cell amount-checkbox">
          <input className="form-control hidden-element" type="checkbox" />
        </div>
      </div>
    </div>
  );
};

SplitPayorsList.displayName = 'SplitPayorsList';

SplitPayorsList.propTypes = {
  payors: PropTypes.array.isRequired,
  formData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

SplitPayorsList.defaultProps = {
  formData: {
    payors: {},
  },
};

export default SplitPayorsList;
