/**
 * withParameterInjections accepts an object containing a set of named parameters and their associated values
 * to be injected into a collection of functions.
 *
 * withParameterInjections is not suitable for injecting into selector functions, as selectors follow a special
 * signature of (state, ...otherArgs). Use withSelectorParameterInjections for injecting into selectors.
 *
 * @param  {Object}   argsToInject Parameter names and associated values to inject.
 * @return {Function} Function accepting a collection of functions to have injections applied.
 *
 * @example
 *  const myFunction = ({ someArg }) => {};
 *  const myOtherFunction = ({ someOtherArg }) => {};
 *  const someFunctions = { myFunction, myOtherFunction };
 *
 *  const argToInject = 5;
 *  const someFunctionsWithInjections = withParameterInjections({ argToInject })(someFunctions);
 *  // Now
 *  //  someFunctionsWithInjections.myFunction can be called like someFunctionsWithInjections.myFunction({ someArg })
 *  //  but behaves like someFunctionsWithInjections.myFunction({ someArg, argToInject = 5})
 */
export const withParameterInjections = (argsToInject) => (functionsByName) =>
  Object.entries(functionsByName || {}).reduce((acc, [functionName, originalFunction]) => {
    acc[functionName] = (passedArgs) => originalFunction({ ...argsToInject, ...passedArgs });
    return acc;
  }, {});
