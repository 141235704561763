import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';

angular.module('sb.billing.webapp').component('sbInvoiceProofOfPaymentView', {
  templateUrl: 'ng-components/invoice-proof-of-payment-view/invoice-proof-of-payment-view.html',
  controller: function ($rootScope, $stateParams, sbNotifiedOperationP, sbUnsavedChangesService) {
    'use strict';
    const ctrl = this;
    const log = getLogger('sbInvoiceProofOfPaymentView');
    const TYPE = 'invoice-proof-of-payment';

    ctrl.$onInit = () => {
      ctrl.sbParams = _.cloneDeep($stateParams); //need to do this while we have the stone age ui-router
      ctrl.sbData = {};
      ctrl.view = {
        receiptLoaded: false,
      };

      ctrl.savedChangesKey = `invoice-proof-of-payment_${ctrl.sbParams.invoiceId}`;
      ctrl.getPayloadConfig = {
        endpoint: 'billing/invoice-proof-of-payment-pdf',
        additional: ctrl.sbParams.invoiceId,
      };

      ctrl.tabCloseListenerDeregister = $rootScope.$on('smokeball-tab-closed', onTabClosed);
      ctrl.pdfUrl = sbUnsavedChangesService.loadMemory(ctrl.savedChangesKey)
    };

    ctrl.onPdfLoaded = onReceiptLoaded;
    ctrl.onError = onError;

    // This listener doesn't really belong in the component, it should be moved higher.
    function onTabClosed(e, { type, invoiceId }) {
      // Check if the tab containing this component is being closed.
      if (type === TYPE && invoiceId === ctrl.sbParams.invoiceId) {
        ctrl.tabCloseListenerDeregister();
        URL.revokeObjectURL(ctrl.pdfUrl);
        ctrl.pdfUrl = undefined;
        sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey);
      }
    }
    

    function onReceiptLoaded (pdfUrl) {
      ctrl.pdfUrl = pdfUrl;
      sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey, pdfUrl);
      ctrl.view.receiptLoaded = true;
    }

    function onError(err) {
      log.error(`Failed to download deposit receipt pdf for transactionId: '${ctrl.sbParams.transactionId}'`, err);
      messageDisplay.error(`Failed to download deposit receipt pdf`);
    }
  }
});
