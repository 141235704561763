import React from 'react';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { PageBannerXero } from '../page-banner-xero';
import { PageBannerBoostTrial } from '../page-banner-boost-trial';

export const PageBanners = ({ navHighlights, onClickLink }) => (
  <>
    {featureActive('BB-6222') && <PageBannerXero key="PageBannerXero" navHighlights={navHighlights} />}
    {featureActive('NUCWEB-855') && <PageBannerBoostTrial key="PageBannerBoostTrial" onClickLink={onClickLink} />}
  </>
);

PageBanners.displayName = 'PageBanners';

PageBanners.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  navHighlights: PropTypes.object, // Used to trigger a re-render when route changes
};

PageBanners.defaultProps = {
  navHighlights: undefined,
};
