import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getAppEnv, envType } from '@sb-itops/app-env';
import { error as displayErrorToUser, warn as displayWarningToUser } from '@sb-itops/message-display';
import { Button } from '@sb-itops/react';
import { fetchPostP } from '@sb-itops/redux/fetch';

import { selectors } from 'web/redux/route/billing-support-debug';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

export const DebugCheckInvoicePaymentLinkExpiryButtonContainer = withReduxProvider(({ invoiceIds }) => {
  const showDebug = useSelector(selectors.getShowDebug);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const onClick = async () => {
    try {
      setIsProcessingRequest(true);
      const response = await fetchPostP({
        path: `/billing/invoices/check-payment-links/:accountId/`,
        fetchOptions: {
          body: JSON.stringify({ invoiceIds: invoiceIds.length > 0 ? invoiceIds : undefined }),
        },
      });
      displayWarningToUser(`${JSON.stringify(response.body?.invoiceChecked)}`);
    } catch (error) {
      displayErrorToUser('Failed to check invoice payment link.');
    } finally {
      setIsProcessingRequest(false);
    }
  };

  // this debug functionality is not available in production environment
  if (!showDebug || getAppEnv() === envType.PRODUCTION) {
    return null;
  }

  return (
    <Button onClick={onClick} disabled={isProcessingRequest || !invoiceIds?.length} locked={isProcessingRequest}>
      {invoiceIds && invoiceIds.length > 0
        ? 'Debug: Show Payment Link Expiration'
        : 'Debug: Show Payment Link Expiration (No Invoice Ids Provided)'}
    </Button>
  );
});

DebugCheckInvoicePaymentLinkExpiryButtonContainer.displayName = 'DebugCheckInvoicePaymentLinkExpiryButtonContainer';

DebugCheckInvoicePaymentLinkExpiryButtonContainer.propTypes = {
  invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DebugCheckInvoicePaymentLinkExpiryButtonContainer.defaultProps = {};
