angular.module('sb.billing.webapp').service('sbUiRouterDebuggerService', function ($rootScope, sbLoggerService) {
  'use strict';

  const log = sbLoggerService.getLogger('sbUiRouterDebuggerService');

  $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, err) => {
    log.error(`$stateChangeError during transition from ${fromState.name} to ${toState.name}`);
    log.error(err);
  });

  $rootScope.$on('$stateChangeStart', (event, toState, toParams/*, fromState, fromParams*/) => {
    log.debug(`$stateChangeStart to ${toState.name} - fired when the transition begins. \n toState:`, toState, ',\n toParams:', toParams);
  });

  $rootScope.$on('$stateChangeSuccess', (event, toState/*, toParams, fromState, fromParams*/) => {
    log.debug(`$stateChangeSuccess to ${toState.name} - fired once the state transition is complete.`);
  });

  $rootScope.$on('$viewContentLoaded',(event) => {
    log.debug('$viewContentLoaded - fired after dom rendered', event);
  });

  $rootScope.$on('$stateNotFound', (event, unfoundState, fromState, fromParams) => {
    log.warn(`$stateNotFound ${unfoundState.name} - fired when a state cannot be found by its name.`);
    log.warn(unfoundState, fromState, fromParams);
  });
});
