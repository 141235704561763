import { gql } from '@apollo/client';

const query = gql`
  query InitPaymentProviderSettings {
    paymentProviderSettings {
      activeProvider
      providers {
        STRIPE {
          showInvoiceLink
          showScanToPay
          clientCoversFeeOnDeposits
          clientCoversFeeOnPayments
          publicKey
          linkedAccounts {
            stripeAccountId
            bankAccountType
            bankAccountId
            onboardingComplete
          }
          feeSchedule {
            creditCard {
              validFrom
              domestic {
                percentageFee
                fixedFeeCents
                feeTaxPercent
              }
              international {
                percentageFee
                fixedFeeCents
                feeTaxPercent
              }
            }
          }
        }
        LAWPAY {
          showInvoiceLink
          showScanToPay
          lawpayAccount
          publicKey
          lawpayBankAccounts {
            id
            name
            publicKey
            currency
            type
            trustAccount
            recurringChargesEnabled
          }
          cardBankAccountMappings {
            sbbBankAccountId
            lawpayBankAccountId
          }
          eCheckBankAccountMappings {
            sbbBankAccountId
            lawpayBankAccountId
          }
        }
        FEE_WISE {
          showInvoiceLink
          showScanToPay
          firmId
          status
          feeWiseAccounts {
            id
            name
            accountType
          }
          linkedAccounts {
            feeWiseAccountId
            bankAccountId
            bankAccountType
          }
        }
      }
    }
  }
`;

const notificationIds = ['PaymentProviderSettingsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InitPaymentProviderSettings = {
  query,
  notificationIds,
};
