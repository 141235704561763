import React, { useState } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useSelector } from 'react-redux';
import { setModalDialogVisible, setModalDialogHidden, isModalVisible } from '@sb-itops/redux/modal-dialog';
import {
  getActiveProvider,
  isFirmCardSavingEnabledForBankAccount,
  isClientCardSavingEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import * as messageDisplay from '@sb-itops/message-display';
import PropTypes from 'prop-types';
import { saveCard, deleteCard } from '@sb-billing/redux/payment-provider';
import { getOperatingAccount, getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getById as getSavedPaymentMethodsByCustomerId } from '@sb-billing/redux/customer-billing-configuration';
import { findActivePaymentPlanByDebtorId } from '@sb-billing/redux/payment-plans/selectors';
import { getList as getPaymentPlansList, savePaymentPlan as savePaymentPlanP } from '@sb-billing/redux/payment-plans';
import { dateToInteger } from '@sb-itops/date';
import { getLogger } from '@sb-itops/fe-logger';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { isErrorSmokeballPreChargeError } from '@sb-billing/business-logic/payment-provider/services';
import { getRegion, regionType } from '@sb-itops/region';
import { featureActive } from '@sb-itops/feature';
import ContactCreditCard from './ContactCreditCard';

const saveCreditCardModalId = 'save-credit-card-modal';
const deleteCreditCardModalId = 'delete-credit-card-modal';
const log = getLogger('ContactCreditCardContainer');

const addCardDisabledTooltipFn = {
  [regionType.US]: () => 'Please setup a compatible payment provider',
  [regionType.AU]: () =>
    featureActive('BB-12038')
      ? `Please enable ${providerNames[providers.FEE_WISE]} to use this feature`
      : 'Please setup a compatible payment provider',
  [regionType.GB]: () =>
    featureActive('BB-12038')
      ? `Please enable ${providerNames[providers.FEE_WISE]} to use this feature`
      : 'Please setup a compatible payment provider',
};

const ContactCreditCardContainer = withReduxProvider(({ contactId, ...props }) => {
  // always Operating account for now
  const bankAccountId = getOperatingAccount()?.id;
  const currentPaymentPlanMethodId = findActivePaymentPlanByDebtorId(getPaymentPlansList(), {
    debtorId: contactId,
  })?.paymentMethodId;
  const [cardSubmitting, setCardSubmitting] = useState(false);
  const [selectedCard, setSelectedCard] = useState(undefined);
  const isSaveModalVisible = useSelector(() => isModalVisible({ modalId: saveCreditCardModalId }));

  const activeProvider = getActiveProvider();

  const isNewCreditCardEnabled = isFirmCardSavingEnabledForBankAccount({ providerType: activeProvider, bankAccountId });
  const isNewCreditCardRequestEnabled = isClientCardSavingEnabledForBankAccount({
    providerType: activeProvider,
    bankAccountId,
  });
  const addNewCardTooltip = !isNewCreditCardEnabled ? addCardDisabledTooltipFn[getRegion()]() : '';

  const onHandleSubmit = async (saveCardFormData) => {
    setCardSubmitting(true);
    try {
      // provide cardId in case we replacing existing card
      const cardId = selectedCard ? { cardId: selectedCard.token } : {};

      await saveCard({ saveCardFormData, providerType: activeProvider, contactId, bankAccountId, ...cardId });

      onModalClose({ modalId: saveCreditCardModalId });
      messageDisplay.success('Saved successfully.');
    } catch (err) {
      log.error(err);
      if (isErrorSmokeballPreChargeError(err)) {
        const errText = `The card was declined by the card issuer: ${err.message}`;
        messageDisplay.error(errText);
      } else if (err?.payload?.body?.message) {
        messageDisplay.error(err.payload.body.message);
      } else {
        messageDisplay.error('Failed to save the card; please contact support for assistance.');
      }
    }
    setCardSubmitting(false);
  };

  const onExistingCardClick =
    ({ modalId }) =>
    (card) => {
      // This can get messy really quickly if we try to update card of non-active provider.
      // We wanna disallow replacing a card if
      // - There is no active provider
      // - Active provider is different than the provider of saved card
      // - Card provider is FeeWise as we haven't implemented FeeWise car replacement
      if (
        modalId === saveCreditCardModalId &&
        (!activeProvider || activeProvider !== card.provider || card.provider === providers.FEE_WISE)
      ) {
        return;
      }
      setSelectedCard(card);
      setModalDialogVisible({ modalId });
    };

  const onModalClose = ({ modalId }) => {
    setSelectedCard(undefined);
    setModalDialogHidden({ modalId });
  };

  const onHandleDelete = async () => {
    try {
      await deleteCard({
        providerType: selectedCard.provider,
        contactId,
        cardId: selectedCard.token,
        providerSpecificFields: { bankAccountId }, // operating bank account id
      });
      if (!!currentPaymentPlanMethodId && selectedCard.token === currentPaymentPlanMethodId) {
        // remove card from payment plan
        const paymentPlan = findActivePaymentPlanByDebtorId(getPaymentPlansList(), { debtorId: contactId });
        await savePaymentPlanP(marshalPaymentPlanUpdate(paymentPlan));
      }
      onModalClose({ modalId: deleteCreditCardModalId });
      messageDisplay.success('Card deleted successfully.');
    } catch (err) {
      log.error(err);
      messageDisplay.error('Failed to delete the card');
    }
  };

  const savedPaymentMethods = useSelector(
    () => getSavedPaymentMethodsByCustomerId(contactId)?.paymentMethods || [],
  ).map((paymentMethod) => ({
    ...paymentMethod,
    bankAccount: getBankAccountById(paymentMethod.bankAccountId)?.accountTypeDisplay || 'Operating',
  }));

  return (
    <ContactCreditCard
      {...props}
      {...{
        isSubmitting: cardSubmitting,
        onCreditCardSubmit: onHandleSubmit,
        onCreditCardDelete: onHandleDelete,
        isNewCreditCardEnabled,
        isNewCreditCardRequestEnabled,
        isSaveModalVisible,
        deleteModalId: deleteCreditCardModalId,
        bankAccountId,
        paymentMethods: savedPaymentMethods,
        onNewCreditCard: () => setModalDialogVisible({ modalId: saveCreditCardModalId }),
        onCloseNewCreditCard: () => onModalClose({ modalId: saveCreditCardModalId }),
        onCloseDeleteCreditCard: () => onModalClose({ modalId: deleteCreditCardModalId }),
        onOpenReplaceCard: onExistingCardClick({ modalId: saveCreditCardModalId }),
        onOpenDeleteConfirmation: onExistingCardClick({ modalId: deleteCreditCardModalId }),
        selectedCard,
        currentPaymentPlanMethodId,
        contactId,
        activeProvider,
        addNewCardTooltip,
      }}
    />
  );
});

// This is very similar to marshallNewPaymentPlan from react-redux/components/payment-plan-modal-dialog/PaymentPlanModalDialogWithReduxEntities.container.jsx
// as the saved plan does not contain nextInstallmentDate but you have to provide it to the endpoint. All that is required for this componentis to set the paymentMethodId to empty
const marshalPaymentPlanUpdate = (paymentPlan) => {
  const findNextInstallmentDate = (installments) => {
    const todayAsInteger = dateToInteger(new Date());
    const next = installments.find((installment) => installment.date >= todayAsInteger);
    return next ? String(next.date) : `${todayAsInteger}`;
  };

  const nextInstallmentDate = findNextInstallmentDate(paymentPlan.installments);

  return {
    id: paymentPlan.id,
    debtorId: paymentPlan.debtorId,
    matterIds: paymentPlan.matterIds,
    nextInstallmentDate: +nextInstallmentDate,
    installmentFrequency: paymentPlan.installmentFrequency,
    installmentAmount: paymentPlan.installmentAmount,
    paymentMethodId: 'None',
  };
};

ContactCreditCardContainer.displayName = 'ContactCreditCardContainer';

ContactCreditCardContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
};

export default ContactCreditCardContainer;
