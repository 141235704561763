'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const surchargeTypeValues = Object.freeze({
  NONE: 0,
  FIXED: 1,
  PERCENTAGE: 2,
});

const surchargeTypeLabels = Object.freeze({
  NONE: 'None',
  FIXED: 'Fixed Amount',
  PERCENTAGE: 'Percentage',
});

const surchargeTypeOptions = getTypeAsOptions({
  typeLabels: surchargeTypeLabels,
  typeValues: surchargeTypeValues,
});

module.exports = {
  surchargeTypeLabels,
  surchargeTypeValues,
  surchargeTypeOptions,
};
