import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import * as forms from '@sb-itops/redux/forms2';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getEInvoiceSettings } from '@sb-billing/redux/einvoice-settings/selectors';
import { saveSettings } from '@sb-billing/redux/einvoice-settings/operations';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { EInvoiceSettingsForm } from './EInvoiceSettingsForm';

const log = getLogger('EInvoiceSettingsFormContainer');
const scope = 'einvoice-settings';

const mapStateToProps = (state) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formDirty } = formSelectors.getFormState(state);
  const { eInvoiceEnabled, eInvoiceLayout, isSaving } = formFields;

  return {
    eInvoiceSwitchEnabled: featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch),
    eInvoiceEnabled,
    eInvoiceLayout,
    isSaving,
    formInitialised,
    formDirty,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const eInvoiceSettings = getEInvoiceSettings();
      const fieldValues = {
        // Translate eInvoiceDisabled (stored value in eInvoiceSettings entity) to
        // eInvoiceEnabled (form field value). eInvoiceDisabled is named this way as the
        // intended behaviour for eInvoice is to be enabled when BB-5725 feature is enabled.
        // This naming probably the only way to achieve this given that no data migration
        // is performed to prepopulate new properties for existing eInvoiceSettings entities.
        eInvoiceEnabled: !eInvoiceSettings.eInvoiceDisabled,
        eInvoiceLayout: eInvoiceSettings.layout,
        isSaving: false,
      };

      dispatch(formActions.initialiseForm({ fieldValues }));
      const onUnload = () => dispatch(formActions.clearForm());

      return onUnload;
    },

    onEInvoiceToggleChange: (newToggleValue) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { eInvoiceEnabled: newToggleValue } }));
    },

    onEInvoiceLayout: ({ layout }) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { eInvoiceLayout: layout } }));
    },

    onSave: async ({ layout, eInvoiceEnabled }) => {
      try {
        await dispatch(
          formOperations.submitFormP({
            submitFnP: () => {
              // Translate eInvoiceEnable (form field value) to eInvoiceDisabled (value to be stored)
              const eInvoiceDisabled =
                featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch) ? !eInvoiceEnabled : undefined;
              dispatch(saveSettings({ layout, eInvoiceDisabled }));
            },
          }),
        );
      } catch (err) {
        log.error(err);
        throw err;
      }
    },
  };
};

export const EInvoiceSettingsFormContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(EInvoiceSettingsForm)),
);

EInvoiceSettingsFormContainer.displayName = `${EInvoiceSettingsForm.displayName}Container`;
EInvoiceSettingsFormContainer.propTypes = {};
EInvoiceSettingsFormContainer.defaultProps = {};
