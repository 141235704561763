import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';

import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbMatterCommunicationSettingsService',
  keyPath: 'matterId',
});

export const saveMatterCommunicationSettings = async (accountId, communicationSettings) => {
  await store.dispatch(async () => {
    const { matterId, correspondencePreferences, eBillingSite, otherMethod } = communicationSettings;

    const communicationSettingsToOpdate = {
      accountId,
      matterId,
      correspondencePreferences,
      eBillingSite,
      otherMethod,
    };

    opdateCache({ optimisticEntities: [communicationSettingsToOpdate] });

    try {
      await fetchPostP({
        path: '/billing/matter-communication-settings/:accountId/',
        fetchOptions: { body: JSON.stringify(communicationSettingsToOpdate) },
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [communicationSettingsToOpdate] });
      throw err;
    }
  });
};
