import { SET_SORT_DIRECTION, RESET_SORT_DIRECTION, SET_SORT_BY } from './types';

const initialState = {};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: action.payload.sortDirection,
      };
    }
    case SET_SORT_BY: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    }
    case RESET_SORT_DIRECTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
