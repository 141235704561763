import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from '@sb-itops/react/modal-dialog';
import { useTranslation } from '@sb-itops/react';

export const UnprotectFundsModal = ({ onYes, modalId }) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      modalId={modalId}
      body={`Do you want to unprotect these funds and release them back into available ${t('trust')} funds?`}
      title="Unprotect Funds"
      buttons={{
        primary: {
          text: 'Yes',
        },
        secondary: {
          text: 'No',
        },
      }}
      onPrimaryClick={onYes}
    />
  );
};

UnprotectFundsModal.propTypes = {
  onYes: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
};
