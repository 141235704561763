import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation } from '@sb-itops/react';
import { ProtectBalanceAddForm } from '../protect-balance-add';
import Styles from './ProtectBalanceAddModal.module.scss';

export const ProtectBalanceAddModal = React.memo(
  ({ onSave, onClose, isSubmitDisabled, isVisible, scope, matterId, matterReadOnly, validateForm, bankAccountId }) => {
    const { t } = useTranslation();
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.protectBalanceAddModal}
        isVisible={isVisible}
        title={`Protect ${t('trust')} Funds`}
        body={
          <ProtectBalanceAddForm
            scope={scope}
            matterId={matterId}
            matterReadOnly={matterReadOnly}
            validateForm={validateForm}
            bankAccountId={bankAccountId}
          />
        }
        footer={
          <div className={Styles.buttonContainer}>
            <Button
              className={Styles.openButton}
              onClick={onSave}
              disabled={isSubmitDisabled}
              form={scope}
              locked={isSubmitDisabled}
              size="full-width"
            >
              Process
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

ProtectBalanceAddModal.displayName = 'ProtectBalanceAddModal';

ProtectBalanceAddModal.propTypes = {
  scope: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  matterReadOnly: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
};

ProtectBalanceAddModal.defaultProps = {
  isSubmitDisabled: false,
  matterId: undefined,
};
