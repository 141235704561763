import { sort as sortItems } from '@sb-itops/sort';

// this do not need to be a parameters since it will be handled by the function. If this changes the whole behaviour would change
const sortBy = ['effectiveDate', 'timestamp'];

/**
 * will calculate the next cheque number available based on the cheque number passed
 * @param {string} chequeNumber - cheque number
 * @param {function} findChequeByChequeNumber - a function to find a cheque by its cheque number
 * @param {boolean} allowDuplicateNumbers - allow check numbers that have been used before
 * @return {number} the next cheque number available
 */
const calculateNextChequeNumber = (chequeNumber, findChequeByChequeNumber, allowDuplicateNumbers = false) => {
  let resultNum = chequeNumber;
  while (allowDuplicateNumbers && findChequeByChequeNumber(resultNum)) {
    resultNum += 1;
    if (allowDuplicateNumbers) break;
  }

  return resultNum;
};

/**
 * A Configuration
 * @typedef {Object} Configuration
 * @property {Object} chequesSelected - object with uuid as props that identifies the selected cheques.
 * @property {string} sortDirection - asc or dsc. default to asc
 * @property {string} firstChequeNumber - the cheque number from which to start. default to 0
 * @property {number} chequeNumberPadding - the number of padding to add to the cheque number
 * @property {function} findChequeByChequeNumber - returns a cheque by it's number (to exclude duplicates)
 * @property {function} allowDuplicateNumbers - allow cheque numbers that have been used before in the sequence
 */

/**
 * generate the cheque number and creates a new array with the cheques and the reference assign based on the cheque configuration.
 * @param {Array.<Cheque>} list of cheques unsorted
 * @param {Configuration} configuration for generating the cheque number
 * @return {Array.<Cheque>} array of cheques with reference value populated
 */
export const assignReferenceNumberToCheques = (
  cheques,
  {
    chequesSelected = {},
    firstChequeNumber = 0,
    chequeNumberPadding = undefined,
    findChequeByChequeNumber,
    allowDuplicateNumbers = false,
  },
) => {
  if (!findChequeByChequeNumber) {
    throw new Error('findChequeByChequeNumber function is mandatory');
  }

  let lastChequeNumber = firstChequeNumber - 1;
  const sortedChequesByOrder = sortItems(cheques, sortBy, ['asc', 'asc']);

  const referencesMap = sortedChequesByOrder.reduce((acc, cheque) => {
    if (chequesSelected[cheque.chequeId]) {
      lastChequeNumber = calculateNextChequeNumber(
        lastChequeNumber + 1,
        findChequeByChequeNumber,
        allowDuplicateNumbers,
      );
      acc[cheque.chequeId] = `${lastChequeNumber}`.padStart(chequeNumberPadding, '0');
    }
    return acc;
  }, {});

  // If there are no selected cheques, this should be equal to the firstChequeNumber
  if (firstChequeNumber - 1 === lastChequeNumber) {
    lastChequeNumber = firstChequeNumber;
  }

  return {
    cheques: cheques.map((cheque) => ({
      ...cheque,
      reference: referencesMap[cheque.chequeId],
    })),
    lastChequeNumber: `${lastChequeNumber}`.padStart(chequeNumberPadding, '0'),
  };
};
