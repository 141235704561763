import { featureActive } from '@sb-itops/feature';
import { capitalize } from '@sb-itops/nodash';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('@sb-billing/services').service('sbPaymentDepositSources', function (sbLocalisationService) {
  'use strict';
  const that = this;

  const paymentSource = Object.freeze({
    BANK_CHECK: 'Bank Check',
    TRUST_CHECK: 'Trust Check',
    CHECK: 'Check',
    BANK_TRANSFER: 'Bank transfer',
    CASH: 'Cash',
    CREDIT_CARD: 'Credit Card',
    OPENING_BALANCE: 'Opening Balance',
    DIRECT_DEBIT: 'Direct Debit',
  });

  const bankTransferSource = Object.freeze({
    BPAY: 'BPAY',
    DIRECT_DEPOSIT: 'Direct Deposit',
  });

  const localizedCheck = capitalize(sbLocalisationService.t('cheque'));

  that.get = (sourceType) => getSources()[sourceType] || [];
  that.getBankTransferSources = (sourceType) => getBankTransferSources()[sourceType] || [];
  that.getDefaultDepositSource = (sources) => getDefaultSource(sources);
  that.paymentSource = paymentSource;

  function getSources() {
    const sources = {
      paymentDepositSource: [
        { accountDisplay: 'Bank Transfer', value: paymentSource.BANK_TRANSFER },
        { accountDisplay: `${localizedCheck}`, value: paymentSource.CHECK },
        { accountDisplay: 'Cash', value: paymentSource.CASH },
        { accountDisplay: 'Credit Card', value: paymentSource.CREDIT_CARD }
      ],
      trustPaymentSource: [
        { accountDisplay: 'Bank Transfer', value: paymentSource.BANK_TRANSFER }
      ],
      cmaPaymentSource: [],
    };

    // CMA enabled
    if (hasFacet(facets.CMA)) {
      sources.cmaPaymentSource = [
        { accountDisplay: `Bank ${localizedCheck}`, value: paymentSource.BANK_CHECK },
        { accountDisplay: 'Bank Transfer', value: paymentSource.BANK_TRANSFER },
      ];
    }

    if (hasFacet(facets.chequeTrustPaymentTypes)) {
      sources.trustPaymentSource.push({ accountDisplay: `Bank ${localizedCheck}`, value: paymentSource.BANK_CHECK });
      sources.trustPaymentSource.push({ accountDisplay: `${sbLocalisationService.t('trustCheque')}`, value: paymentSource.TRUST_CHECK });
      if (featureActive('BB-5511')) {
        sources.trustPaymentSource.push({ accountDisplay: 'Direct Debit', value: paymentSource.DIRECT_DEBIT });
      }
    } else {
      sources.trustPaymentSource.push({ accountDisplay: `${localizedCheck}`, value: paymentSource.CHECK });
    }

    const sortedTrustPaymentSource = _.sortByOrder(sources.trustPaymentSource, ['accountDisplay'], ['asc']);
    const sortedDepositPaymentSource = _.sortByOrder(sources.paymentDepositSource, ['accountDisplay'], ['asc']);

    return {
      paymentDepositSource: sortedDepositPaymentSource,
      trustPaymentSource: sortedTrustPaymentSource,
      cmaPaymentSource: sources.cmaPaymentSource,
    };
  }

  function getDefaultSource(sources) {
    // BB-7638, payment deposit source default to bank transfer
    return sources.find(source => source.value === paymentSource.BANK_TRANSFER);
  }

  // AU trust payments only 
  function getBankTransferSources() {
    const sources = {
      trustPaymentSource: [
        { accountDisplay: 'Direct Deposit', value: bankTransferSource.DIRECT_DEPOSIT },
        { accountDisplay: 'BPAY', value: bankTransferSource.BPAY },
      ],
    };
    const supportsTrustPaymentBankTransferType = hasFacet(facets.trustPaymentBankTransferType);
    return supportsTrustPaymentBankTransferType ? sources : {};
  }
});
