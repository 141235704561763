/* eslint-disable no-param-reassign */
import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterDetailsBar, DashboardMatterData } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useSubscribedQuery } from 'web/hooks';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import classNames from 'classnames';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import React from 'react';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { MatterDescriptionBar } from './MatterDescriptionBar';
import { TrustTooltipSummary } from './TrustTooltipSummary';
import Styles from './MatterDescriptionBar.module.scss';

const hooks = ({ matterId, onClickLink, trustRetainerBelowLimit }) => ({
  useTrustTooltip: () => {
    const { t } = useTranslation();

    const {
      data: matterTotalData,
      loading: matterTotalLoading,
      error: matterTotalError,
    } = useSubscribedQuery(DashboardMatterData, {
      variables: {
        matterId,
      },
    });

    if (matterTotalError) {
      throw matterTotalError;
    }

    const showControlledMoneyAccount = featureActive('BB-6381') && hasFacet(facets.CMA);

    const trustBalance = {
      total: matterTotalData?.matter?.matterBalanceTrustSummary?.[balanceTypes.BALANCE] || 0,
      protected: matterTotalData?.matter?.matterBalanceTrustSummary?.[balanceTypes.PROTECTED] || 0,
      available: matterTotalData?.matter?.matterBalanceTrustSummary?.[balanceTypes.AVAILABLE] || 0,
    };

    const trustTooltip = featureActive('BB-8671') ? (
      <div className={classNames(Styles.left, Styles.hover)}>
        <TrustTooltipSummary totals={trustBalance} />
        <i className={Styles.tab} />
      </div>
    ) : undefined;

    return {
      t,
      trustRetainerBelowLimit,
      trustTooltip,
      loading: matterTotalLoading,
      totals: {
        TRUST: matterTotalData?.matter?.matterBalanceTrustSummary?.balance || 0,
      },
      showControlledMoneyAccount,
    };
  },
  useMatterDetails: () => {
    const matterQueryResult = useSubscribedQuery(
      MatterDetailsBar,
      {
        skip: !matterId,
        variables: {
          matterId,
          matterFilter: {
            includeNonBillableLeadMatters: true,
            includeDeleted: true,
          },
        },
      },
      {
        notificationIds: [...MatterDetailsBar.notificationIds],
      },
    );

    if (matterQueryResult.error) {
      throw matterQueryResult.error;
    }

    const { data } = matterQueryResult;
    const matter = data?.matter;
    const otherClients = data?.matter?.clients?.slice() || [];

    const defaultClient = otherClients.shift();

    return { matter, loading: matterQueryResult.loading, defaultClient, onClickLink };
  },
});

export const MatterDescriptionBarContainer = withApolloClient(composeHooks(hooks)(MatterDescriptionBar));

MatterDescriptionBarContainer.propTypes = {
  onClickLink: PropTypes.func,
  matterId: PropTypes.string,
  trustRetainerBelowLimit: PropTypes.bool.isRequired,
};
