import { featureActive, featureOn, getAll } from '@sb-itops/feature';
import { getAllFlags } from '@sb-itops/launch-darkly-client-side';
import { getFrontendEnv } from '@sb-itops/environment-config';

angular.module('sb.billing.webapp').component('sbDataFeatureSwitches', {
    require: {
      sbComposeCtrl: '^sbCompose'
    },
    bindings: {
      composeKey: '@',
      parent: '<',
    },
    controller: function (sbLoggerService) {
      const that = this;
      that.launchDarklyFlags = getAllFlags();
      const log = sbLoggerService.getLogger('sbDataFeatureSwitches');


      that.$onInit = function () {
        if (_.isEmpty(that.composeKey)) {
          throw new Error('Cannot create feature switches with invalid compose key');
        }

        log.info('composeKey', that.composeKey);
        updateData();
        that.parent.refreshSwitches = updateData;
      };
      
      function updateData(){
        let switches = [], features = getAll();
        log.info(`features`, features);
        const frontEndEnv = getFrontendEnv();
        
        _.each(_.keys(features), function (key) {
          switches.push({ 
            code: key, 
            inCodeLevel: features[key].featureLevel, 
            launchDarklyLevel: mapLaunchDarklyValuesToString(that.launchDarklyFlags, key, frontEndEnv), 
            finalLevel: featureActive(key), 
            description: features[key].description,
          });
        });
        
        switches = switches.map((switchData) => ({
          ...switchData,
          style: mapBackgroundColor(switchData, frontEndEnv),
        }
        ));
        
        that.switches = _.sortBy(switches, 'code');
        that.sbComposeCtrl.setComposeData(switches, that.composeKey);
      }

    } 
  }
);

function mapLaunchDarklyValuesToString (launchDarklyFlags, key, frontEndEnv) {
  if (frontEndEnv === 'LOCAL' || !Object.keys(launchDarklyFlags).includes(key)) {
    return '-';
  }

  if (typeof launchDarklyFlags[key] === 'object') {
    if (Object.keys(launchDarklyFlags[key]).length > 0) {
      return JSON.stringify(launchDarklyFlags[key]);
    } else {
      return 'FORCED OFF';
    }

  }
  return launchDarklyFlags[key] ? 'FORCED ON' : 'FORCED OFF';
}

function mapBackgroundColor (switchData, frontEndEnv) {
  const inCodeLevelBoolean = featureOn(switchData.inCodeLevel, frontEndEnv);
  const launchDarklyBoolean = switchData.launchDarklyLevel === '-' ? inCodeLevelBoolean : (switchData.launchDarklyLevel === 'FORCED ON' || switchData.launchDarklyLevel.startsWith('{'));
  const finalLevelBoolean = switchData.finalLevel; 
  if (inCodeLevelBoolean === launchDarklyBoolean && launchDarklyBoolean === finalLevelBoolean) {
    return '';
  }
  return 'yellow-background';
}
