import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@sb-itops/react';
import Styles from './InvoicePreview.module.scss';

/**
 * Why not use PreviewPdf?
 * 1. I've tried to reuse that component without success. It works in the modal context,
 *    but don't work well when you need things to change, e.g. selecting a different
 *    template and triggering another preview. The hack for it is to conditionally render
 *    the PdfViewer and have an additional spinner outside of PdfViewer which kind of defeats
 *    the purpose of the component in the first place, but it also requires some hacky css
 *    to ensure the external and internal spinner lines up as in InvoiceReminderEmailSettings.jsx
 * 2. I also tried refactoring this component to accept pdfUrl instead of pdfBlob, which works,
 *    but I found the user experience is not quite as a user would expect it. The screen would
 *    show no spinners while the invoice preview PDF is being generated. It's possible to show
 *    the spinner indicator in a more timely manner, but we'll then need to have an additional
 *    prop "loading" and it would be indicating a status not directly relevant to what's
 *    happening in this component.
 * Long story short, I've already tried it.
 * @param {Object} props
 * @param {Blob} props.pdfBlob
 * @param {string} props.loadingMessage
 * @param {boolean} props.showSampleIndicator
 * @returns
 */
export const InvoicePreview = ({ pdfBlob, loadingMessage, showSampleIndicator }) => {
  let pdfBlobUrl;
  if (pdfBlob) {
    pdfBlobUrl = URL.createObjectURL(pdfBlob);
  }

  return (
    <div className={Styles.invoicePreview}>
      <div className={classnames(Styles.pdfViewer, showSampleIndicator ? Styles.sampleIndicator : undefined)}>
        {pdfBlobUrl && (
          <object data={pdfBlobUrl} type="application/pdf" className={Styles.pdfObject}>
            <p>This browser does not support PDF!</p>
          </object>
        )}
        {!pdfBlobUrl && <Spinner message={loadingMessage} width={200} height={250} />}
      </div>
    </div>
  );
};

InvoicePreview.propTypes = {
  pdfBlob: PropTypes.object,
  loadingMessage: PropTypes.string,
  showSampleIndicator: PropTypes.bool,
};

InvoicePreview.defaultProps = {
  pdfBlob: undefined,
  loadingMessage: 'Generating PDF',
  showSampleIndicator: false,
};
