/**
 * Enable features via query string
 *
 * Example 1: http://localhost:9001/?features=BB-1234#/billing/matters
 * Example 2: http://localhost:9001/?features=BB-1234+BB-5678#/billing/matters
 */

import { envType, getFrontendEnv } from '@sb-itops/environment-config';
import { parseQueryString } from '@sb-itops/region';
import { enableFeature, disableFeature } from '@sb-itops/feature';

const enableFeaturesViaQueryString = () => {
  if (getFrontendEnv() === envType.PRODUCTION) {
    return;
  }

  const qs = parseQueryString(window.location.search);
  if (qs.FEATURES) {
    const features = qs.FEATURES.split('+');

    const manuallyOverridden = true; // Prevents launch darkly from overwritting manual overrides.
    features.forEach((featureCode) => {
      if (featureCode.startsWith('~')) {
        const strippedFeatureCode = featureCode.substring(1);
        disableFeature(strippedFeatureCode, manuallyOverridden);
      } else {
        enableFeature(featureCode, manuallyOverridden);
      }
    });
  }
};

export { enableFeaturesViaQueryString };
