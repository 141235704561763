import { hasFacet, facets } from '@sb-itops/region-facets';
import { entryTypeLabels } from '@sb-billing/business-logic/shared/entities';
import { defaultPath } from './default-path';

export const TOGGLE_HIDE_FILTER = `${defaultPath}/TOGGLE_HIDE_FILTER`;
export const SET_FILTER = `${defaultPath}/SET_FILTER`;
export const RESET_FILTER = `${defaultPath}/RESET_FILTER`;
export const TOGGLE_SHOW_FILTERS = `${defaultPath}/TOGGLE_SHOW_FILTERS`;
export const SET_EXPANDED_CLIENTS = `${defaultPath}/SET_EXPANDED_CLIENTS`;
export const SET_SELECTED_MATTERS = `${defaultPath}/SET_SELECTED_MATTERS`;

export const matterStatusFilterOptions = [
  { label: 'Open', value: 'Open' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Closed', value: 'Closed' },
];

const supportsContingencyBillingType = hasFacet(facets.contingencyBillingType);
export const billingTypeFilterOptions = [
  { label: 'Time Based', value: 'Time Based' },
  { label: 'Fixed Fee', value: 'Fixed' },
  // 'Invoices (list)', 'Finalize with payments' and 'Bulk trust payment' tabs use getBillingTypeFilter to filter invoices by billing type
  // 'Create Invoices' does not have existing invoice yet so it uses different function to filter (filterEntries in invoice-entries-service.js)
  // We perform case-sensitive search for substring so we can match
  // 'Contingency' with 'Contingency ($)' and 'Contingency (%)' and 'Fixed' with 'Fixed Fee' and 'Fixed Fee per Appearance'
  ...(supportsContingencyBillingType ? [{ id: 'Contingency', label: 'Contingency' }] : []),
];

export const getLocalisedEntryTypeFilterOptions = (t) => [
  // We use entryTypeLabels as our components expect value to be a string and not an integer, which entryType is
  { label: 'Time', value: entryTypeLabels.TIME },
  { label: 'Fixed Fee', value: entryTypeLabels.FIXED },
  {
    label: typeof t === 'function' ? `${t('capitalizeAll', { val: 'expenses' })}` : 'Expenses',
    value: entryTypeLabels.EXPENSE,
  },
];
