'use strict';

/**
 * Determines if expenseVersion can be modified
 *
 * A expense version can be modified if it's not "finalized" yet, if it's finalised, it could be
 * 1. on a finalized invoice, i.e. has an associated "invoiceId", or
 * 2. be an externally invoiced expense with null "invoiceId", but finalized = true
 * @param {object} expenseVersion
 * @returns {boolean}
 */
const expenseCanBeModified = (expenseVersion) => !expenseVersion.finalized;

module.exports = {
  expenseCanBeModified,
};
