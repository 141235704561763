angular.module('sb.billing.webapp').service('sbDpHelpers', function($rootScope) {
  'use strict';

  const that = this;
  that.registerWatcher = registerWatcher;
  that.registerCacheWatcher = registerCacheWatcher;
  that.provideData = provideData;
  that.provideDataById = provideDataById;
  that.provideDataByIds = provideDataByIds;
  that.provideDataByFilterFunction = provideDataByFilterFunction;

  function registerWatcher(destroyables, watcher, dataProvider) {
    if (!(destroyables instanceof Set)) {
      throw new Error('invalid destroyables set');
    }
    if (!_.isFunction(watcher)) {
      throw new Error('invalid watcher function');
    }
    if (!_.isFunction(dataProvider)) {
      throw new Error('invalid data provider function');
    }

    // NB the watcher here is a function that returns the object to watch.
    // The object to watch can also be a function. The object returned by the watcher
    // function should remain constant unless a data update is required.
    //
    // The watcher function should NOT be implemented is such a way that it
    // ALWAYS returns a new object as this will cause a digest error.
    //
    destroyables.add($rootScope.$watch(watcher, dataProvider));
  }

  function registerCacheWatcher(destroyables, cacheName, dataProvider) {
    if (!(destroyables instanceof Set)) {
      throw new Error('invalid destroyables set');
    }
    if (_.isEmpty(cacheName)) {
      throw new Error('invalid cache name');
    }
    if (!_.isFunction(dataProvider)) {
      throw new Error('invalid data provider function');
    }
    destroyables.add($rootScope.$on(`smokeball-data-update-${cacheName}`, dataProvider));
  }

  // This is the simplest data provider. Cache contains a single row
  // that is fetched via a GET functions that accepts no parameters
  function provideData(fetchFunction, onUpdate) {
    let prevResult;

    if (!_.isFunction(fetchFunction)) {
      throw new Error('invalid get() data provider function');
    }
    if (!_.isFunction(onUpdate)) {
      throw new Error('invalid data update handler function');
    }

    return _.throttle(() => {
      const newResult = fetchFunction();

      if (prevResult !== newResult) {
        prevResult = newResult;
        onUpdate(newResult);
      }
    }, 250);
  }

  // Here the watcher function returns a single GUID that is the ID
  // of the cahce row to be fetched. The row is fetched using the getById() function.
  function provideDataById(watchFunction, fetchFunction, onUpdate) {
    let prevResult;

    if (!_.isFunction(watchFunction)) {
      throw new Error('invalid getId() watcher function');
    }
    if (!_.isFunction(fetchFunction)) {
      throw new Error('invalid getById(guid) data provider function');
    }
    if (!_.isFunction(onUpdate)) {
      throw new Error('invalid data update handler function');
    }

    return _.throttle(() => {
      const id = watchFunction();
      const newResult = _.isEmpty(id) ? undefined : fetchFunction(id);

      if (prevResult !== newResult) {
        prevResult = newResult;
        onUpdate(newResult);
      }
    }, 250);
  }

  // Here the watcher function returns an array GUID's that contains the ID's
  // of the cahce rows to be fetched. Each row is fetched using the getById() function.
  function provideDataByIds(watchFunction, fetchFunction, onUpdate) {

    if (!_.isFunction(watchFunction)) {
      throw new Error('invalid getIds() watcher function');
    }
    if (!_.isFunction(fetchFunction)) {
      throw new Error('invalid getById(guid) data provider function');
    }
    if (!_.isFunction(onUpdate)) {
      throw new Error('invalid data update handler function');
    }

    return _.throttle(() => {
      const ids = watchFunction();
      const results = [];
      _.each(ids, _id => {
        const result = fetchFunction(_id);
        if (!_.isEmpty(result)) {
          results.push();
        }
      });
      onUpdate(results);
    }, 250);
  }

  // Here the watcher function returns a filter function. The cache rows are fetched
  // using the filter function returned by the watcher function.
  function provideDataByFilterFunction(watchFunction, fetchFunction, onUpdate) {

    if (!_.isFunction(watchFunction)) {
      throw new Error('invalid getFilterFunct() watcher function');
    }
    if (!_.isFunction(fetchFunction)) {
      throw new Error('invalid getByFilter(filterFunct) data provider function');
    }
    if (!_.isFunction(onUpdate)) {
      throw new Error('invalid data update handler function');
    }

    return _.throttle(() => {
      const filterFunct = watchFunction();
      let newResult;

      if (_.isFunction(filterFunct)) {
        newResult = fetchFunction(filterFunct) || [];
      }

      onUpdate(newResult);
    }, 250);
  }

});
