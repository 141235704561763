angular
  .module('sb.billing.webapp')
  .factory(
    'exportFeeOp',
    (
      $rootScope,
      $http,
      sbGenericEndpointService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
    ) => {
      const log = sbLoggerService.getLogger('exportFeeOp');
      return (feeFilter) => {
        // Object representing the current state of this operation.
        const operation = {
          label: `Time and Fee Entries`,
          reportFormat: 'csv',
          isComplete: false,
          error: null,
          fileData: null,
        };

        downloadFeeDataP(operation, feeFilter);

        return operation;
      };

      async function downloadFeeDataP(operation, feeFilter) {
        const requestId = sbUuidService.get();

        try {
          // Kick off the operation
          const { payload } = await sbNotifiedOperationP(() => startExportingFeeP(requestId, feeFilter), {
            requestId,
            completionNotification: 'FeeExportReadyNotification',
            errorNotification: 'FeeExportFailureNotification',
            timeoutMs: 30000,
          });

          const request = {
            method: 'GET',
            url: payload.exportFileUrl || payload.feeUrl, // feeUrl can be deprecated after BB-6412 goes live
            responseType: 'arraybuffer'
          };

          const response = await $http(request);
          const file = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});
          operation.fileData = file;
        } catch (err) {
          log.error(`Failed to export fee for requestId: ${requestId}`, err);
          operation.error = err;
        } finally {
          $rootScope.$applyAsync(() => {
            operation.isComplete = true;
          });
        }

        // Returns a promise for exporting fees into a single PDF/CSV. Used by sbNotifiedOperation to begin processing.
        function startExportingFeeP(requestId, feeFilter) {
          const requestData = {
            requestId,
            fileFormat: 'csv',
            ...feeFilter,
          };

          return sbGenericEndpointService.postPayloadP('/billing/fees/export', undefined, requestData, 'POST');
        }
      }
    }
  );
