/**
 * Returns the currently selected item.
 *
 * @return {*} The currently selected item, undefined if no item is currently selected.
 */
export const getSelectedItem = (state) => state.selectedItem;

/**
 * Indicates whether the selectedItem value has changed
 *
 * @return {boolean} true if the selectedItem value has changed
 */
export const getHasChanged = (state) => state.hasChanged;
