import composeHooks from '@sb-itops/react-hooks-compose';
import { useState } from 'react';
import { Menu, IMenu } from './Menu';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hooks = (p: Omit<IMenu, 'setShowDeleteDashboardModal' | 'showDeleteDashboardModal'>) => ({
  useState: () => {
    const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState<string | false>(false);
    return {
      setShowDeleteDashboardModal,
      showDeleteDashboardModal,
    };
  },
});

export const MenuContainer = composeHooks(hooks)(Menu);
