/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, StatelessSelect, CurrencyInput2, forms2PropTypes } from '@sb-itops/react';
import { MatterTypeahead } from '@sb-matter-management/react';
import { ContactTypeahead } from '@sb-customer-management/react';
import Styles from './CreditNoteForm.module.scss';

const CreditNoteForm = ({
  formDisabled,
  formInitialised,
  showInlineContactForm,
  inlineContactForm,
  amount,
  balance,
  selectedDate,
  selectedMatterId,
  selectedContactId,
  matterOptions,
  contactOptions,
  onAddContact,
  onFieldValueUpdated,
  submitFailed,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <div className={Styles.creditNoteForm}>
      <fieldset className="section-fieldset" disabled={formDisabled}>
        <div className="row">
          <div className="col-lg-6 form-group">
            <label>Date</label>
            <DatePicker
              onSelect={(date) => {
                onFieldValueUpdated({ selectedDate: date?.toISOString() });
              }}
              value={selectedDate?.value ? moment(selectedDate?.value).toDate() : undefined}
              disabled={formDisabled}
              format="DD/MM/YYYY"
              hasError={submitFailed && selectedDate?.isInvalid}
            />
          </div>
          <div className="col-lg-6 form-group">
            <label>Account</label>
            <div>
              <StatelessSelect
                options={[{ value: 'credit', label: 'Credit' }]}
                selectedOption={{ value: 'credit', label: 'Credit' }}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 form-group">
            <label>Matter</label>
            <div className="matter-field">
              <MatterTypeahead
                matterSummaries={matterOptions}
                onValueChange={(matter) => {
                  onFieldValueUpdated({ selectedMatterId: matter?.id });
                }}
                initialId={selectedMatterId?.value}
                disabled={formDisabled}
                hasError={submitFailed && selectedMatterId?.isInvalid}
              />
            </div>
          </div>
          <div className="col-lg-6 form-group">
            <label>Contact</label>
            <ContactTypeahead
              onContactSelected={(contact) => {
                onFieldValueUpdated({ selectedContactId: contact?.value });
              }}
              onAdd={onAddContact}
              contacts={contactOptions}
              selectedContactId={selectedContactId?.value}
              disabled={formDisabled}
              isAddActive={showInlineContactForm}
              hasError={submitFailed && selectedContactId?.isInvalid}
              placeholder="Select a contact..."
              showAdd
            />
          </div>
        </div>
        {showInlineContactForm && (
          <div className="row">
            <div className="col-lg-12 form-group">{inlineContactForm}</div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-6 form-group">
            <label>Amount</label>
            <CurrencyInput2
              onChange={(e) => {
                onFieldValueUpdated({ amount: e.target.value });
              }}
              // eslint-disable-next-line no-unsafe-optional-chaining
              value={+amount?.value}
              hasError={submitFailed && amount?.isInvalid}
            />
          </div>

          <div className="col-lg-6 form-group">
            <label>Credit Balance</label>
            <CurrencyInput2 value={+balance?.value + +amount?.value} disabled />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 form-group">
            <label htmlFor="vendor-payment-reason">Reason (Internal Use)</label>
            <div className="matter-field">
              <textarea
                className="form-control"
                maxLength={500}
                onChange={(e) => {
                  e.preventDefault();
                  onFieldValueUpdated({ reason: e.target.value });
                }}
                rows="5"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const contactOption = PropTypes.shape({
  id: PropTypes.string.isRequired,
  display: PropTypes.any,
});

const matterOption = PropTypes.shape({
  id: PropTypes.string,
  display: PropTypes.string,
  matterStarted: PropTypes.object,
  matterStartedISO: PropTypes.string,
  matterClientNames: PropTypes.string,
  status: PropTypes.string,
  typeahead: PropTypes.string,
  matterTotals: PropTypes.object,
  hasFunds: PropTypes.boolean,
});

const { Forms2Field } = forms2PropTypes;

CreditNoteForm.propTypes = {
  formInitialised: PropTypes.bool.isRequired,
  showInlineContactForm: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  inlineContactForm: PropTypes.node.isRequired,
  formDisabled: PropTypes.bool,
  amount: Forms2Field,
  balance: Forms2Field,
  selectedMatterId: Forms2Field,
  selectedContactId: Forms2Field,
  selectedDate: Forms2Field,
  matterOptions: PropTypes.arrayOf(matterOption).isRequired,
  contactOptions: PropTypes.arrayOf(contactOption).isRequired,
  onAddContact: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
};

CreditNoteForm.defaultProps = {
  formDisabled: undefined,
  selectedMatterId: undefined,
  selectedContactId: undefined,
  selectedDate: undefined,
  amount: undefined,
  balance: undefined,
};

CreditNoteForm.displayName = 'CreditNoteForm';

export default CreditNoteForm;
