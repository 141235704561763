import * as types from './types';

/* State Shape
{
  selectedItem : * : Currently selected item. Default undefined.
}
*/

// Defines the initial values set in state.
const initialState = {
  selectedItem: undefined,
  hasChanged: false,
};

// Action -> Handler function lookup.
const reducerLookup = {
  [types.SET_SELECTED_ITEM]: setSelectedItem,
  [types.CLEAR_SELECTED_ITEM]: clearSelectedItem,
};

/**
 * The main reducer function for the feature.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggering a potential update to the store.
 * @return {object} The new store state.
 */
export const reducer = (state = initialState, action = {}) => {
  const reducerFn = reducerLookup[action.type];
  return reducerFn ? reducerFn(state, action) : state;
};

/**
 * Action handler for types.SET_SELECTED_ITEM.
 * Used to mark an item as selected.
 */
function setSelectedItem(state, action) {
  const { item } = action.payload;
  return {
    ...state,
    selectedItem: item,
    hasChanged: true,
  };
}

/**
 * Action handler for types.CLEAR_SELECTED_ITEM.
 * Used to clear the selected item.
 */
function clearSelectedItem(state) {
  return {
    ...state,
    selectedItem: undefined,
  };
}
