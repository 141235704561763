import { getState } from './redux-api-factory';

export const getCreatedPreSignedUrl = ({ s3Bucket }, state = getState()) => {
  if (!s3Bucket) {
    return undefined;
  }

  const { preSignedUrl } = (state && state[s3Bucket]) || {};
  return preSignedUrl;
};
