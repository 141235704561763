'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home';

  $stateProvider
    .state('home', {
      url: '',
      templateUrl: BASE_DIRECTORY + '/top-level-nav.html',
      controller: 'sbTopLevelNavController',
      controllerAs: 'topnav',
      abstract: true,
      data: {
        authorized: true
      },
    })
    .state('lawpayOauth', {
      url: '/lawpay?authorization_code',
      template: '<sb-law-pay-oauth-connect class="flex"></sb-law-pay-oauth-connect>',
      data: {
        authorized: true
      }
    })
    .state('stripeOnboardingReturn', {
      url: '/stripe-return?stripeAccountId',
      template: '<sb-stripe-onboarding-return class="flex"></sb-stripe-onboarding-return>',
    });
});
