import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forms2PropTypes } from '../forms2';
import Styles from './FormLabel.module.scss';

// If you want to use this ANYWHERE else, please put it in a common library
const Asterisk = () => (
  <svg fill="#D31510" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 384 512">
    <path d="M192 32c13.3 0 24 10.7 24 24l0 156.9 131.4-81.3c11.3-7 26.1-3.5 33 7.8s3.5 26.1-7.8 33L237.6 256l135 83.6c11.3 7 14.7 21.8 7.8 33s-21.8 14.7-33 7.8L216 299.1 216 456c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-156.9L36.6 380.4c-11.3 7-26.1 3.5-33-7.8s-3.5-26.1 7.8-33l135-83.6-135-83.6c-11.3-7-14.8-21.8-7.8-33s21.8-14.8 33-7.8L168 212.9 168 56c0-13.3 10.7-24 24-24z" />
  </svg>
);

const FormLabel = ({ label, field, submitFailed, className, optional, uppercase, explainerText }) => (
  <label className={classnames(Styles.formLabel, uppercase && Styles.uppercaseLabel, className)}>
    {label}
    {!optional && (
      <span
        className={classnames(
          Styles.asterisk,
          field?.isInvalid &&
            (field?.isDirty || submitFailed) &&
            field?.invalidReason?.toLowerCase()?.indexOf('required') !== -1
            ? Styles.hasError
            : '',
        )}
      >
        <Asterisk />
      </span>
    )}
    {explainerText && (
      <i className={classnames(Styles.explainerIcon, 'icon', 'icon-question-mark')} title={explainerText} />
    )}
  </label>
);

const { Forms2Field } = forms2PropTypes;

FormLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  field: Forms2Field,
  submitFailed: PropTypes.bool,
  optional: PropTypes.bool,
  uppercase: PropTypes.bool, // will force label to be in upper case
  explainerText: PropTypes.string,
};

FormLabel.defaultProps = {
  className: undefined,
  label: '',
  field: undefined,
  submitFailed: false,
  optional: false,
  uppercase: true,
  explainerText: undefined,
};

FormLabel.displayName = 'FormLabel';

export default FormLabel;
