import { getMatterTypeNameById } from '@sb-matter-types/redux/matter-types';
import { getList as getMattersList, getByIds as getMattersByIds } from '@sb-matter-management/redux/matters';
import { getCurrentStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import { getMap as getBankAccountBalancesState } from '@sb-billing/redux/bank-account-balances'
import { getMatterTrustBalance } from '@sb-billing/redux/bank-account-balances.2/selectors'
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';



angular.module('sb.billing.webapp').component('sbDataMatters', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: { composeKey: '@', filterIds: '<?' },
  controller: function($scope, sbLoggerService, sbBankAccountService, sbMatterTotalsService) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbDataMatters');

    //log.setLogLevel('info');

    $scope.$on('smokeball-data-update-sbMattersMbService', updateData);

    that.$onInit = () => {
      log.info('composeKey', that.composeKey);
    };

    that.$onChanges = (changesObj) => {
      log.info('sbDataMatters', changesObj);

      if (changesObj.filterIds) {
        //if the filters have actually changed - not just copy of the old filters
        if (!_.isEqual(changesObj.filterIds.currentValue, changesObj.filterIds.previousValue)) {
          updateData();
        }
      } else {
        updateData();
      }
    };

    function isOverdrawnTrustAccount(matterId) {
      if (sbBankAccountService.isStatutoryDepositMatter(matterId) || !matterId) {
        return false;
      }

      const matterTrustBalance = getMatterTrustBalance(getBankAccountBalancesState(), {
        matterId,
        balanceType: balanceTypes.BALANCE,
      });

      return matterTrustBalance < 0;
    }

    function updateData() {
      let matters = _.isArray(that.filterIds)
        ? getMattersByIds(that.filterIds)
        : getMattersList();
      matters = matters.map((matter) => {
        const totals = sbMatterTotalsService.getTotalsForMatterId(matter.matterId);
        const attorneyResponsible = getCurrentStaffByPersonId(matter.attorneyResponsibleId) || {};
        const clientIds = matter.clientCustomerIds;
        
        return {
          totals,
          ...matter,
          matterTypeDisplay: getMatterTypeNameById(matter.matterTypeId),
          attorneyResponsibleName: attorneyResponsible.name,
          attorneyResponsibleInitials: attorneyResponsible.initials,
          isOverdrawnTrustAccount: isOverdrawnTrustAccount(matter.matterId),
          clientIds,
          clientDisplay: getClientDisplay(clientIds), // used for sorting
        };
      });

      that.sbComposeCtrl.setComposeData(matters, that.composeKey);
    }

    function getClientDisplay (clientIds) {
      return clientIds.map((clientId) => getContactDisplay(clientId, { showLastNameFirst: true })).join(' | ');
    }
  },
});
