import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './affini-pay/fieldGen_1.4.0'; // Horrendously adds AffiniPay to window object.
// import './affini-pay'; // Horrendously adds AffiniPay to window object.
import uuid from '@sb-itops/uuid';
import Styles from './CreditCardEntryGroup.module.scss';
import { registerHostedFields, unregisterHostedFields, getHostedField } from './hosted-fields';

const { HostedFields } = window.AffiniPay;

export const hostedFieldsTypes = {
  creditCard: 'credit_card_number',
  cvv: 'cvv',
};

const fields = [
  { label: 'Card Number', type: hostedFieldsTypes.creditCard, name: 'creditCard' },
  { label: 'Security Code', type: hostedFieldsTypes.cvv, name: 'securityCode' },
];

const uniqueId = uuid();

const buildUniqueId = (affiniType) => `${affiniType}_${uniqueId.replace(/-/g, '')}`;

// affini pay has a bug with IPhones/IPad, when focusing out of the input (cvv/credit-card) it does never put
// the focus flag in false (it put it in true but never back to false). As a result we need to bay-pass the !focus validation
// only for this devices, otherwise the on-change is never executed. This is used so we do not show an error if there is no value
// in the input
const IsIPadOrIPhone = () => !!window.navigator.platform && /iPad|iPhone/.test(window.navigator.platform);

const CreditCardFieldGroup = memo(({ publicKey, onChange, onCompleted, disabled, style }) => {
  const onChangeHandler = useCallback(
    (state) => {
      state.fields.forEach(({ selector, type, error, card, focus }) => {
        // we will only trigger the change when we los focus
        if ((!focus || IsIPadOrIPhone) && state.target.selector === selector) {
          onChange({
            currentTarget: {
              name: fields.find((field) => field.type === type).name,
              // we wont expose any credit card or cvv data
              value: !!(error === ''),

              type: card,
            },
          });
        }
      });
      if (state.isReady) {
        // we execute the onCreditCardEntriesCompleted callback passing another callback that will pass down all the data for doing the payment.
        onCompleted();
      }
    },
    [onCompleted, onChange],
  );

  useEffect(() => {
    const config = {
      publicKey,
      fields: fields.map(({ type }) => ({
        selector: `#${buildUniqueId(type)}`,
        input: {
          type,
          css: {
            'font-size': '14px',
            ...style,
          },
        },
      })),
    };

    const timeoutId = setTimeout(() => {
      if (!getHostedField(publicKey)) {
        const hostedFields = HostedFields.initializeFields(config, onChangeHandler);
        registerHostedFields(publicKey, hostedFields);
      }
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onChangeHandler, publicKey, style]);

  useEffect(
    () => () => {
      unregisterHostedFields(publicKey);
    },
    [publicKey],
  );

  return (
    <div className={Styles.row}>
      {fields.map(({ type, label }) => {
        const id = buildUniqueId(type);
        return (
          <div key={type} className={classnames(Styles.cell, Styles.cell50, type)}>
            <label htmlFor={id}>{label}</label>
            <div className="form-control" id={id} />
            {disabled && <div className={classnames(Styles.disabledEntry, 'form-control')} />}
          </div>
        );
      })}
    </div>
  );
});

CreditCardFieldGroup.displayName = 'CreditCardFieldGroup';

CreditCardFieldGroup.propTypes = {
  publicKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

CreditCardFieldGroup.defaultProps = {
  onCompleted: () => {},
  disabled: false,
  // unfortunately we cannot style the affini pay component with css. The only way they provide a way of doing this is by style options.
  // We need to expose this here so if we need we can override the affini pay default styles
  style: {},
};

export default CreditCardFieldGroup;
