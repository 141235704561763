import { dot as nestedObjectToFlattened } from 'dot-object';
import { getById as getBankAccountById, isTrustAccount, getTrustAccounts } from '@sb-billing/redux/bank-account';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/redux/contacts/index';

export const getValidateFn =
  ({
    matterClientRequiredForTrustDeposit,
    matterDescriptionRequiredForTrustDeposit,
    matterClientOrDescriptionRequired,
    matterClientAddressRequiredForTrustDeposit,
    supportsStatutoryDepositMatter,
    depositReason: requiresReasonField,
  }) =>
  async (formFields) => {
    const formErrors = {};

    // matter
    if (formFields.matterId) {
      const matter = getMatterById(formFields.matterId);
      formErrors.matterId = [];
      const hasClients = matterHasClients(matter, matterClientRequiredForTrustDeposit);
      const hasDescription = matterHasDescription(matter, matterDescriptionRequiredForTrustDeposit);
      const clientsMissingAddresses = matterClientAddressRequiredForTrustDeposit
        ? await matterClientsMissingAddresses(matter)
        : [];

      if (matterClientOrDescriptionRequired && !(hasClients && hasDescription)) {
        if (hasClients && !hasDescription) {
          formErrors.matterId.push(
            'A matter description has not been added to the matter. Please add a description before making a trust deposit.',
          );
        } else if (!hasClients && hasDescription) {
          formErrors.matterId.push(
            'A client has not been added to the matter. Please add a client before making a trust deposit.',
          );
        } else {
          formErrors.matterId.push(
            'A matter description and client has not been added to the matter. Please add before making a trust deposit.',
          );
        }
      }
      if (matterClientAddressRequiredForTrustDeposit && clientsMissingAddresses && clientsMissingAddresses.length > 0) {
        formErrors.matterId = [
          ...formErrors.matterId,
          ...clientsMissingAddresses.map((client) => `contactId:${client.id}'s address is incomplete.`),
        ];
      }
      if (formErrors.matterId.length > 0) {
        formErrors.matterId = formErrors.matterId.join('\n');
      } else {
        delete formErrors.matterId;
      }
    } else {
      formErrors.matterId = true;
    }

    if (formFields.amount && formFields.matterId) {
      const matterIsStatutoryDeposit = isStatutoryDepositMatter({
        matterId: formFields.matterId,
        trustBankAccounts: getTrustAccounts(),
        supportsStatutoryDepositMatter,
      });
      if (matterIsStatutoryDeposit) {
        const isError = formFields.balance > 0;
        if (isError) {
          formErrors.amount = 'Statutory deposit matter balance cannot exceed $0';
        }
      }
    }

    // effective date
    if (formFields.bankAccountId) {
      const bankAccount = getBankAccountById(formFields.bankAccountId);
      if (formFields.effectiveDate) {
        if (
          isTrustAccount(bankAccount.accountType) &&
          isReconciled({
            yyyymmdd: formFields.effectiveDate,
            trustAccountId: formFields.bankAccountId,
          })
        ) {
          formErrors.effectiveDate = 'Warning: the date selected has already been reconciled';
        }
      }
    }

    // reason
    if (requiresReasonField && !formFields.reason) {
      formErrors.reason = true;
    }
    // Forms 2 expects errors to be reported as flattened dot object notation.
    return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
  };

function matterHasClients(matter, matterClientRequiredForTrustDeposit) {
  if (!matterClientRequiredForTrustDeposit) {
    // this function is used to display correct error so if matter client is not required
    // we treat it as that matter has clients
    return true;
  }
  return matter ? matter.clientCustomerIds && matter.clientCustomerIds.length > 0 : true;
}

function matterHasDescription(matter, matterDescriptionRequiredForTrustDeposit) {
  if (!matterDescriptionRequiredForTrustDeposit) {
    // this function is used to display correct error so if description is not required
    // we treat it as that matter has description
    return true;
  }
  return matter ? matter.description && matter.description.length > 0 : true;
}

async function matterClientsMissingAddresses(matter) {
  const matterClients = matter.clientCustomerIds;
  const clientContactsWithMissingStreetAddress = await findContactsWithMissingStreetOrPOAddress({
    contactIds: matterClients,
    spreadGroupsOfPeople: true,
  });
  return clientContactsWithMissingStreetAddress;
}
