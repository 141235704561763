/* global analytics*/
'use strict';

const initialiseSegment = require('./segment-snippet'); 
const envConfig = require('@sb-itops/environment-config');

// Analytics is noop for any non dev or non prod environment.
// We also ensure tracking ID presence, otherwise noop logic takes hold.
const SEGMENT_TRACKING_ID = envConfig.getTrackingId();
if (envConfig.getAnalyticsEnabled() && envConfig.getTrackingId()) {
  // Force the segment snippet to run prior to SB analytics wrapper.
  initialiseSegment(SEGMENT_TRACKING_ID);
}

// Initialise the SB analytics API which wraps the underlying analytics mechanism.
bootstrapAnalytics();

function bootstrapAnalytics() {
  // We still need to present the public API for SB analytics even if analytics is disabled.
  // Otherwise we will get a bunch of undefined errors.
  window.sbAnalytics = {
    setIdentity,
    pageChange,
    postEvent,
  };

  // Make the SB analytics API noop if analytics is disabled.
  if (!envConfig.getAnalyticsEnabled() || !SEGMENT_TRACKING_ID) {
    Object.keys(sbAnalytics).forEach(fnName => {
      sbAnalytics[fnName] = () => {};
    });

    return;
  }

  // Contains functions keyed by an event ID to allow pre processing of extraData passed to events.
  const eventPreprocessors = {};

  function pageChange(name, extraData) {
    const {toQueryString, toState, toParams, fromState/*, fromParams*/} = extraData;
    analytics.page(toState.name, {
      referrer: fromState.url,
      path: toState.url,
      search: toQueryString,
      params: toParams
    });
  }

  /**
   * Sets the identity of the currenlty logged in user
   * @param identity, Identity object which at minimum requires a unique userId property.
   */
  function setIdentity({ userId, username, accountId }) {
    analytics.identify(userId, {
      email: username, 
      company: accountId
    });
  }

  /**
   * post event to analytics queue with the custom event information
   * @param eventType, factory function that will be used to create the event
   * @param ...args, options needed by the factory function
   */
  function postEvent(eventType, extraData) {
    const preProcessFn = _.isFunction(eventPreprocessors[eventType]) ? eventPreprocessors[eventType] : (extraData) => extraData;
    analytics.track(eventType, preProcessFn(extraData));
  }
}
