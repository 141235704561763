'use strict';

// FeeWise constants
const paymentMethods = {
  Card: 'Card',
  DirectDebit: 'DirectDebit',
};

module.exports = {
  paymentMethods,
};
