import producer from 'immer';
import { addResetNonPersistedDataActionHandler } from './reset-all-redux-data';

export class ReducerRegistry {
  constructor() {
    this.emitChange = null;
    this.reducers = {};
    this.customResetReducers = new Set();
  }

  getReducers() {
    return { ...this.reducers };
  }

  // eslint-disable-next-line no-unused-vars
  register(name, baseReducer, immutable = true, autoClearOnLogout = false, persisted = false) {
    this.reducers = {
      ...this.reducers,
      [name]: (state, action) => {
        const reducer =
          autoClearOnLogout && !persisted ? addResetNonPersistedDataActionHandler(baseReducer) : baseReducer;

        if (!immutable || process.env.NODE_ENV === 'production') {
          return reducer(state, action);
        }
        return producer(reducer(state, action), (draftState) => draftState);
      },
    };

    if (this.emitChange) {
      this.emitChange(this.getReducers());
    }
  }

  setChangeListener(listener) {
    this.emitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();

export default reducerRegistry;
