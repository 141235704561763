import moment from 'moment';
import PropTypes from 'prop-types';
import { useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { featureActive } from '@sb-itops/feature';
import { sortByProperty } from '@sb-itops/nodash';
import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { isModalVisible, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { sort as sortItems } from '@sb-itops/sort';
import uuid from '@sb-itops/uuid';

// business logic
import {
  bankAccountState,
  bankAccountStateByValue,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';

import {
  BankAccountsWithBalances,
  BulkTrustToOfficeInvoices,
  InitBankAccountSettings,
  InvoicesWithUnpaidAmount,
  MatterTypesFilter,
  StaffMembersList,
} from 'web/graphql/queries';
import { useCacheQuery, usePagination, useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as trustToOffice from 'web/redux/route/home-billing-bills-trust-to-office/feature';

import { getNonZeroTrustMatterBalancesMap } from './get-non-zero-matter-trust-balance-map';

import { BulkTrustToOfficeRoute } from './BulkTrustToOfficeRoute';
import {
  autoAllocateInvoicePaymentsForMatter,
  autoAllocateInvoicePayments,
} from './allocate-trust-to-office-invoice-payments';

const FETCH_LIMIT = 200;
const SCOPE = 'bulk-trust-to-office-route';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const PROCESS_TRUST_TO_OFFICE_MODAL_ID = 'process-trust-to-office-modal';

const sortByMap = {
  matterDisplay: 'matter',
  totalDue: 'matterUnpaid',
  trustBalance: 'matterTrustBalanceAvailable',
};

function getInvoiceRows(invoices, selectedInvoiceIdsMap, paymentsMap, matterBalance, isAutoAllocated) {
  let runningMatterTrustBalance = (matterBalance && matterBalance[balanceTypes.AVAILABLE]) || 0;

  return invoices.map((invoice) => {
    const isSelected = selectedInvoiceIdsMap[invoice.invoiceId];
    const trustAmount = isSelected ? paymentsMap[invoice.invoiceId] || 0 : 0;
    const trustError =
      isSelected &&
      (isPaymentError(trustAmount, runningMatterTrustBalance, invoice.totalDue) || trustAmount > invoice.totalDue);
    const trustBalance = runningMatterTrustBalance;

    runningMatterTrustBalance -= isSelected ? trustAmount : 0;

    return {
      type: 'INVOICE',
      invoiceId: invoice.invoiceId,
      invoiceNumber: invoice.invoiceNumber,
      matterId: invoice.matterId,
      matter: invoice.matter,
      accountId: invoice.accountId,
      debtors: invoice.debtors,
      issuedDate: invoice.issuedDate,
      dueDate: invoice.dueDate,
      totalDue: invoice.totalDue,
      hasUnpaidAD: invoice.hasUnpaidAD,
      isSelected,
      trustAmount,
      trustBalance,
      trustError,
      isAutoAllocated,
      paymentPlan: invoice.listItemProperties.activePaymentPlanDetails,
    };
  });
}

function getMatterRows({
  matterIdToInvoiceRows,
  expanded,
  autoAllocationsMap,
  matterBalancesMap,
  matterContactBalancesMap,
  isMatterContactBalanceFirm,
  invoicePayments,
}) {
  const getMatterContactBalancesForMatter = (matterId) => matterContactBalancesMap[matterId] || [];

  return Object.entries(matterIdToInvoiceRows).map(([matterId, invoiceRows]) => {
    const matterBalance = matterBalancesMap[matterId] || {};
    const invoices = invoiceRows;
    const matter = invoiceRows[0].matter;

    const invoiceIds = invoiceRows.map((invoiceRow) => invoiceRow.invoiceId);
    const isExpanded = expanded[matterId] || false;
    const hasUnpaidAD =
      featureActive('BB-9573') &&
      // We are interested in selected invoices with payment greater than 0. This is because user can
      // select invoice but doesn't have enough money in trust so it is ignored for an actual payment
      invoices.some((inv) => inv.isSelected && inv.hasUnpaidAD && invoicePayments[inv.invoiceId] > 0);

    return {
      type: 'MATTER',
      matterId,
      isExpanded,
      matterDisplay: getMatterDisplay(matter, matter?.matterType?.name),
      isSelected: invoiceRows.every((invoiceRow) => invoiceRow.isSelected),
      isMultipleContactBalances: isMatterContactBalanceFirm
        ? getMatterContactBalancesForMatter(matterId).length > 1
        : false,
      trustIsError: invoiceRows.some((invoiceRow) => invoiceRow.trustError),
      // business rule: Matters added to the list after its already loaded should come in with the auto allocate toggle on
      isAutoAllocated: autoAllocationsMap[matterId] === undefined || autoAllocationsMap[matterId],
      invoices,
      invoiceIds,
      hasUnpaidAD,
      trustBalance: matterBalance[balanceTypes.AVAILABLE] || 0,
      totalDue: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.totalDue, 0),
      trustAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.trustAmount, 0),
    };
  });
}

function groupInvoicesByMatter({
  invoices,
  sortBy,
  sortDirection,
  selectedInvoiceIdsMap,
  paymentsMap,
  expandedMatterIdsMap,
  autoAllocationsMap,
  isMatterContactBalanceFirm,
  matterBalancesMap,
  matterContactBalancesMap,
}) {
  // group invoices by matter id
  const matterIdToInvoices = invoices.reduce((matterMap, invoice) => {
    if (matterMap[invoice.matterId]) {
      matterMap[invoice.matterId].push(invoice);
    } else {
      // eslint-disable-next-line no-param-reassign
      matterMap[invoice.matterId] = [invoice];
    }
    return matterMap;
  }, {});

  // for each matter group, turn input invoices into invoice table row format
  const matterIdToInvoiceRows = Object.entries(matterIdToInvoices).reduce((acc, [matterId, matterInvoices]) => {
    // we always generate the invoice rows as a selected, hidden invoice row with an error should block payment
    const invoiceRows = getInvoiceRows(
      sortItems(matterInvoices, ['issuedDate', 'validFrom'], ['asc', 'asc']),
      selectedInvoiceIdsMap,
      paymentsMap,
      // a matter wont have a balance if there's been no transactions yet
      matterBalancesMap[matterId] || {},
      // if a matter has no autoAllocation value it is a new matter, which we default to being auto allocated.
      // otherwise, take the user specified autoAllocation value
      autoAllocationsMap[matterId] === undefined || autoAllocationsMap[matterId],
    );

    acc[matterId] = invoiceRows;
    return acc;
  }, {});

  // gather display info for each matter
  const matterRows = getMatterRows({
    matterIdToInvoiceRows,
    expanded: expandedMatterIdsMap,
    autoAllocationsMap,
    matterBalancesMap,
    matterContactBalancesMap,
    isMatterContactBalanceFirm,
    invoicePayments: paymentsMap,
  });

  // sort matters by sort order
  const sortedMatterRows = sortItems(matterRows, [sortBy], [sortDirection]);

  // if matter is expanded, show all invoices for the matter
  const rows = sortedMatterRows.reduce((acc, matterRow) => {
    acc.push(matterRow);
    if (matterRow.isExpanded) {
      acc.push(...matterIdToInvoiceRows[matterRow.matterId]);
    }
    return acc;
  }, []);

  return {
    rows,
    allMatterIds: matterRows.map((row) => row.matterId),
    allInvoiceIds: Array.from(new Set(matterRows.map((row) => row.invoiceIds))).flat(),
    allExpanded: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isExpanded),
    allSelected: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isSelected),
    hasError: matterRows.some((matterRow) => matterRow.trustIsError || matterRow.operatingIsError),
  };
}

function isPaymentError(paymentAmount, matterBalance, invoiceDue) {
  return !!paymentAmount && (paymentAmount < 0 || paymentAmount > matterBalance || paymentAmount > invoiceDue);
}

const path = trustToOffice.defaultPath;
const {
  getSelectedInvoices,
  getSelectedInvoicesMap, // cached invoices when paginating across pages so we don't have to refetch
  getExpandedMatters,
  getPayments,
  getAutoAllocatedMatters,
  getFilters,
  getVisibleFilterGroups,
  getSort,
  getShowFilters,
} = trustToOffice.selectors;

const {
  // TABLE actions
  toggleAutoAllocate,
  toggleSelect,
  setPayments,
  toggleExpand,
  expandAll,
  clearState,
  // FILTER actions
  setFilter,
  resetFilters,
  setFilterGroupVisibility,
  sortRows,
} = trustToOffice.actions;

const hooks = () => ({
  useActions: ({ onClickLink, onOpenTrustChequesModal }) => {
    const dispatch = useDispatch();
    const changeFilter = async (filterName, filterValue) => {
      await dispatch(setFilter(filterName, filterValue));
    };

    return {
      // table callbacks
      onClickLink,
      onOpenTrustChequesModal,
      onToggleAllMattersExpanded: (matterIds, expanded) => dispatch(expandAll({ matterIds, expanded })),
      onToggleMatterExpanded: (matterId) => dispatch(toggleExpand({ matterId })),
      onToggleAutoAllocate: ({ matterId, isAutoAllocated, invoices, matterTrustBalance }) => {
        dispatch(toggleAutoAllocate({ matterId, isAutoAllocated }));

        const selectedInvoices = invoices.reduce((acc, invoice) => {
          if (invoice.isSelected) {
            acc.push(invoice);
          }
          return acc;
        }, []);

        if (isAutoAllocated) {
          // auto allocate on: make sure to clear any manually allocated payments entered by user
          const manuallyAllocatedPaymentsToClear = selectedInvoices.reduce((acc, invoice) => {
            acc[invoice.invoiceId] = undefined;
            return acc;
          }, {});
          dispatch(setPayments(manuallyAllocatedPaymentsToClear));
        } else {
          // auto allocate toggled off: use auto allocation logic to set default payment amount for selected invoices
          const paymentAllocationsMap = autoAllocateInvoicePaymentsForMatter({
            matterId,
            matterTrustBalance,
            invoices: selectedInvoices,
          });
          dispatch(setPayments(paymentAllocationsMap));
        }
      },
      onChangePaymentAmount: (invoiceId, amount) => dispatch(setPayments({ [invoiceId]: amount })),
      onPayButtonClick: async () => setModalDialogVisible({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID }),
      onPaymentsSubmitted: () => dispatch(clearState()),
      // filters callbacks
      toggleShowFilters: () => dispatch(trustToOffice.actions.toggleShowFilters()),
      onResetFilters: () => dispatch(resetFilters()),
      onToggleFilterGroupVisibility: (filterGroupName, isVisible) =>
        dispatch(setFilterGroupVisibility(filterGroupName, isVisible)),
      onFilterIssueDate: (selectedFilter) => changeFilter('issueDate', selectedFilter),
      onFilterBillingType: (selectedBillingTypes, allBillingTypesSelected) =>
        changeFilter('billingTypes', {
          selections: selectedBillingTypes,
          allSelected: allBillingTypesSelected,
        }),
      onFilterMatterType: (selectedMatterTypes) => changeFilter('matterTypes', selectedMatterTypes),
      onFilterMatterStatus: (matterStatus) => changeFilter('matterStatuses', matterStatus),
      onStaffChange: (attorneyResponsible) => changeFilter('attorneysResponsible', attorneyResponsible),
      onFilterTrustBalance: (e) => changeFilter('minimumTrustBalance', e.target.value),
      onFilterTrustAccount: (trustAccount) => changeFilter('bankAccountId', trustAccount.value),
      sort: ({ sortBy, sortDirection }) => dispatch(sortRows({ sortBy, sortDirection })),
    };
  },
  usePagination: () => {
    const { currentPage, setPageNumber, getPagination } = usePagination({
      scope: SCOPE_PAGINATION,
      fetchLimit: FETCH_LIMIT,
    });

    const onPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    return { currentPage, onPageChange, getPagination };
  },
  useTrustToOfficeModal: () => {
    const isTrustToOfficeModalVisible = useSelector(() =>
      isModalVisible({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID }),
    );
    return {
      isTrustToOfficeModalVisible,
    };
  },
});

const queryHooks = () => ({
  useBankAccountSettingsData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const isMatterContactBalanceFirm =
      bankAccountSettingsData?.bankAccountSettings?.bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];
    return {
      isMatterContactBalanceFirm,
    };
  },
  useTrustAccountsFilterData: () => {
    const { t } = useTranslation();
    const { data: activeBankAccountsData, loading: trustAccountsLoading } = useSubscribedQuery(
      BankAccountsWithBalances,
      {
        variables: {
          bankAccountFilter: {
            accountTypes: [bankAccountTypeEnum.TRUST],
            state: [bankAccountStateByValue[bankAccountState.OPEN]],
          },
          includeContactBalances: false,
          includeMatterBalances: false,
        },
      },
    );

    const activeTrustAccountOptions = sortByProperty(
      (activeBankAccountsData?.bankAccounts || []).map((ta) => ({ value: ta.id, label: getBankAccountName(ta, t) })),
      'label',
      'asc',
      false,
    );

    return {
      trustAccounts: activeTrustAccountOptions,
      trustAccountsLoading,
    };
  },
  useMatterTypesFilterData: () => {
    const matterTypes = useRef();
    const {
      data,
      error,
      loading: matterTypesLoading,
    } = useSubscribedQuery(MatterTypesFilter, {
      variables: {
        filter: {
          excludeMatterTypeWithoutMatter: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    if (!matterTypesLoading && data?.matterTypeList?.results) {
      matterTypes.current = data?.matterTypeList?.results;
    }

    return { matterTypes: matterTypes.current, matterTypesLoading };
  },
  useStaffMemberFilterData: () => {
    const {
      data,
      error,
      loading: staffMembersLoading,
    } = useSubscribedQuery(
      StaffMembersList,
      {
        variables: { filter: { excludeFormerStaff: true, excludeStaffWithoutMatter: true } },
      },
      {
        notificationIds: [...StaffMembersList.notificationIds, 'MatterManagementWebQuery.MatterUpdated'],
      },
    );

    if (error) {
      throw new Error(error);
    }

    const staffMembers =
      data?.staffMembersList?.results.map((staff) => ({ ...staff, name: `${staff.firstName} ${staff.lastName}` })) ||
      [];

    return { staffMembers, staffMembersLoading };
  },
});

const dependentHooks = () => ({
  useBulkTrustToOfficeTableData: ({ isMatterContactBalanceFirm, currentPage, getPagination }) => {
    const dispatch = useDispatch();
    const state = useSelector((reduxState) => reduxState);
    const sortBy = getSort(state[path]).sortBy;
    const sortDirection = getSort(state[path]).sortDirection;
    const selectedInvoiceIdsMap = getSelectedInvoices(state[path]); // invoice id selection state across all pages
    const selectedInvoicesMap = getSelectedInvoicesMap(state[path]); // a cache of selected invoices from all pages at the time they were first selected
    const paymentsMap = getPayments(state[path]);
    const expandedMatterIdsMap = getExpandedMatters(state[path]);
    const autoAllocationsMap = getAutoAllocatedMatters(state[path]);
    const filters = getFilters(state[path]);
    const visibleFilterGroups = getVisibleFilterGroups(state[path]);
    const trustBankAccountId = filters.bankAccountId;

    // get matters balances for selected trust account
    const { data: bankAccountsData } = useSubscribedQuery(BankAccountsWithBalances, {
      skip: !trustBankAccountId,
      variables: {
        bankAccountIds: trustBankAccountId ? [trustBankAccountId] : [],
        includeContactBalances: isMatterContactBalanceFirm,
        includeMatterBalances: true,
      },
    });

    // get latest unpaid amount for invoices selected across all pages
    const selectedInvoiceIds = useMemo(() => Object.keys(selectedInvoiceIdsMap), [selectedInvoiceIdsMap]);
    const { data: invoicesWithUnpaidAmountData } = useSubscribedQuery(InvoicesWithUnpaidAmount, {
      variables: {
        invoiceIds: selectedInvoiceIds,
      },
    });
    const invoicesWithUnpaidAmountMap = useMemo(
      () =>
        (invoicesWithUnpaidAmountData?.invoices || []).reduce((acc, invoice) => {
          acc[invoice.id] = invoice;
          return acc;
        }, {}),
      [invoicesWithUnpaidAmountData?.invoices],
    );

    const [allNonZeroTrustMatterBalancesMap, matterContactBalancesMap] = useMemo(() => {
      const allTrustMatterBalances = bankAccountsData?.bankAccounts?.[0]?.bankAccountBalances?.matterBalances || [];
      const nonZeroTrustMatterBalancesMap = getNonZeroTrustMatterBalancesMap(allTrustMatterBalances);
      const matterContactBalancesMapForSelectedBankAccount = (
        bankAccountsData?.bankAccounts?.[0]?.bankAccountBalances?.contactBalances || []
      ).reduce((acc, contactBalance) => {
        if (acc[contactBalance.matterId]) {
          acc[contactBalance.matterId].push(contactBalance);
        } else {
          acc[contactBalance.matterId] = [contactBalance];
        }
        return acc;
      }, {});
      return [nonZeroTrustMatterBalancesMap, matterContactBalancesMapForSelectedBankAccount];
    }, [bankAccountsData?.bankAccounts]);

    // translate billing types filter
    const billingTypes = (filters.billingTypes?.selections || []).reduce((acc, item) => {
      if (item === 'contingency') {
        acc.push('Contingency ($)');
        acc.push('Contingency (%)');
        return acc;
      }

      if (item === 'fixed') {
        acc.push('Fixed Fee');
        acc.push('Fixed Fee per Appearance');
        return acc;
      }

      if (item === 'time') {
        acc.push(`Time Based`);
      }

      acc.push(item);
      return acc;
    }, []);

    let sort;
    if (sortBy === 'matterDisplay') {
      sort = {
        fieldNames: ['matter'],
        directions: [`${sortDirection || 'ASC'}`.toUpperCase()],
      };
    } else {
      // by default use matter as secondary sort
      sort = {
        fieldNames: [sortByMap[sortBy] || sortBy, 'matter'],
        directions: [`${sortDirection || 'ASC'}`.toUpperCase(), 'ASC'],
      };
    }

    // get unpaid invoices for selected bank account and filters
    const {
      data: invoicesData,
      error: invoicesError,
      loading: invoicesLoading,
    } = useSubscribedQuery(BulkTrustToOfficeInvoices, {
      context: { skipRequestBatching: true },
      skip: !trustBankAccountId,
      variables: {
        invoiceListFilter: {
          bankAccountIds: trustBankAccountId ? [trustBankAccountId] : [],
          invoiceStatuses: ['FINAL'],
          issuedDate:
            filters.issueDate?.filterType && filters.issueDate.filterType !== 'ALL'
              ? { from: filters.issueDate.startDate, to: filters.issueDate.endDate }
              : undefined,
          matterBillingTypes: !filters.billingTypes?.allSelected && billingTypes.length > 0 ? billingTypes : undefined,
          matterTypeIds: filters.matterTypes,
          matterStatuses: filters.matterStatuses.map((status) => status.toLowerCase()),
          matterAttorneyResponsibleIds: filters.attorneysResponsible,
          matterTrustBalanceAvailable: { from: filters.minimumTrustBalance || 1 }, // i.e. on this page we are only interested in invoices in matter with non zero trust balance
        },
        paymentPlanStatusAsOfDate: +moment().format('YYYYMMDD'),
        offset: currentPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort,
      },
    });

    if (invoicesError) {
      throw new Error(invoicesError);
    }

    const memoizedResult = useMemo(() => {
      // for each invoice on the current page
      // 1) add to working invoices map
      // 2) auto allocate payments where appropriate
      const { invoices, invoicesMap, workingInvoicesMap } = (invoicesData?.invoiceList?.results || []).reduce(
        (acc, invoice) => {
          const finalInvoice = {
            ...invoice,
            invoiceId: invoice.id,
            matterId: invoice.matter.id,
            hasUnpaidAD: invoice.listItemProperties.hasUnpaidAnticipatedDisbursements,
            totalDue: invoice.totals.unpaid,
          };
          finalInvoice.currentVersion = { ...finalInvoice };

          // deduplicate just in case as there's no performance penalty and invoice-list was returning duplicates at some point
          if (!acc.invoicesMap[invoice.id]) {
            acc.invoices.push(finalInvoice);
          }
          acc.invoicesMap[finalInvoice.invoiceId] = finalInvoice;

          // add invoice to working invoices map, which includes
          // 1) invoices on current page
          // 2) selected invoices from other pages
          //    i.e. these were cached when the invoice was first selected, which might be slightly out of date
          acc.workingInvoicesMap[finalInvoice.invoiceId] = finalInvoice;

          return acc;
        },
        { invoices: [], invoicesMap: {}, workingInvoicesMap: { ...selectedInvoicesMap } },
      );

      // for each work invoice ensure latest unpaid amount is used
      Object.keys(workingInvoicesMap).forEach((invoiceId) => {
        if (
          invoicesWithUnpaidAmountMap[invoiceId] &&
          Number.isFinite(invoicesWithUnpaidAmountMap[invoiceId].totals?.unpaid)
        ) {
          workingInvoicesMap[invoiceId].totalDue = invoicesWithUnpaidAmountMap[invoiceId].totals.unpaid;
        }
      });

      // auto allocate payments where appropriate
      const workingInvoices = Object.values(workingInvoicesMap);
      const autoAllocatedPaymentsMap = autoAllocateInvoicePayments({
        invoices: workingInvoices,
        selectedInvoiceIdsMap,
        autoAllocationsMap,
        matterBalancesMap: allNonZeroTrustMatterBalancesMap,
      });

      return {
        invoices,
        invoicesMap,
        workingInvoicesMap,
        autoAllocatedPaymentsMap,
      };
    }, [
      invoicesData?.invoiceList?.results,
      selectedInvoicesMap,
      selectedInvoiceIdsMap,
      autoAllocationsMap,
      allNonZeroTrustMatterBalancesMap,
      invoicesWithUnpaidAmountMap,
    ]); // memoize to avoid reprocessing on every render

    const { invoices, invoicesMap, workingInvoicesMap, autoAllocatedPaymentsMap } = memoizedResult;

    // workingPaymentsMap include both manually and automatically allocated payments
    // due to support for manually allocate payments, we cannot fully memoize the steps
    // below which depends on workingPaymentsMap, or at least it's counter productive to do so
    const workingPaymentsMap = { ...paymentsMap, ...autoAllocatedPaymentsMap };

    // group all the invoices by matter and sort accordingly for table display
    const { rows, allMatterIds, allInvoiceIds, allExpanded, allSelected, hasError } = groupInvoicesByMatter({
      invoices,
      sortBy,
      sortDirection,
      selectedInvoiceIdsMap,
      paymentsMap: workingPaymentsMap,
      expandedMatterIdsMap,
      autoAllocationsMap,
      isMatterContactBalanceFirm,
      matterBalancesMap: allNonZeroTrustMatterBalancesMap,
      matterContactBalancesMap,
    });

    // marshall payment summary so this can be used by trust to office transfer modal dialog
    const paymentsForEachMatter = marshallPaymentsForEachMatter(
      selectedInvoiceIdsMap,
      workingPaymentsMap,
      workingInvoicesMap,
    );
    const paymentSummary = buildPaymentSummary(paymentsForEachMatter);

    const tableSummary = buildTableSummary(rows);

    const disablePayButton =
      !paymentSummary ||
      !paymentSummary.totalAmount ||
      !paymentSummary.totalAmount > 0 ||
      !paymentSummary.paymentsForEachMatter ||
      !paymentSummary.paymentsForEachMatter.length > 0 ||
      hasError;

    const expanded = getShowFilters(state[path]);

    // pagination
    const invoicesTotalCount = invoicesData?.invoiceList?.totalCount || 0;
    const { hidePagination, totalNumberOfPages } = getPagination({
      totalCount: invoicesTotalCount,
      loading: invoicesLoading,
    });

    const onSelectInvoices = (invoiceIds, selected) => {
      dispatch(toggleSelect({ invoiceIds, selected, invoicesMap }));
    };

    return {
      loading: invoicesLoading,
      expanded,
      rows,
      sortBy,
      sortDirection,
      allInvoiceIds,
      allMatterIds,
      allExpanded,
      allSelected,
      hasError,
      tableSummary,
      filters,
      visibleFilterGroups,
      selectedMatterTypes: [...new Set(filters.matterTypes)],
      // pay button/modal
      disablePayButton,
      paymentSummary,
      // pagination
      hidePagination,
      totalNumberOfPages,
      // callbacks
      onSelectInvoices,
    };
  },
});

function marshallPaymentsForEachMatter(selectedInvoiceIdsMap, paymentsMap, invoicesMap) {
  // 1. get a list of invoices selected for payment
  // 2. group invoice payments by matter in a map for fast lookup
  const invoicePaymentsByMatter = Object.keys(selectedInvoiceIdsMap).reduce((acc, invoiceId) => {
    // add invoice only if it has been selected
    const invoice = invoicesMap[invoiceId];
    if (selectedInvoiceIdsMap[invoiceId] && invoice) {
      let matterInvoicePayments = acc[invoice.matterId];

      // add selected invoice only if payment amount is > 0
      const amount = paymentsMap[invoiceId] || 0; // defaults to 0
      if (amount > 0) {
        const invoicePayment = {
          invoiceId,
          amount,
          hasUnpaidAD: invoice.hasUnpaidAD,
          invoiceNumber: invoice.invoiceNumber,
        };

        // push into existing matter payment group, or create a new group
        // assumption here is that invoices can never repeat
        if (matterInvoicePayments) {
          matterInvoicePayments.invoices.push(invoicePayment);
          matterInvoicePayments.matterTotalPayment += invoicePayment.amount;
        } else {
          matterInvoicePayments = {
            // payorId is only required for contact balance firms
            paymentId: uuid(),
            matterId: invoice.matterId,
            matterDisplay: getMatterDisplay(invoice.matter, invoice.matter?.matterType?.name),
            invoices: [invoicePayment],
            matterTotalPayment: invoicePayment.amount,
          };
        }

        return {
          ...acc,
          [invoice.matterId]: matterInvoicePayments,
        };
      }
    }
    return acc;
  }, []);

  // turn map into an array
  return Object.values(invoicePaymentsByMatter);
}

function buildPaymentSummary(paymentsForEachMatter) {
  const matterCount = paymentsForEachMatter.length;
  let hasUnpaidAD = false;
  let invoiceCount = 0;
  const totalAmount = paymentsForEachMatter.reduce(
    (acc, matterPayments) =>
      acc +
      matterPayments.invoices.reduce((matterTotalAcc, invoice) => {
        if (invoice.hasUnpaidAD) {
          hasUnpaidAD = true;
        }
        invoiceCount += 1;
        return matterTotalAcc + invoice.amount;
      }, 0),
    0,
  );

  const paymentSummary = {
    totalAmount,
    matterCount,
    invoiceCount,
    paymentsForEachMatter,
    hasUnpaidAD,
  };

  return paymentSummary;
}

function buildTableSummary(rows) {
  const summary = {
    totalDue: 0,
    trustBalance: 0,
    trustAmount: 0,
  };

  return rows.reduce(
    (total, row) =>
      row.type === 'MATTER'
        ? {
            totalDue: total.totalDue + row.totalDue,
            trustBalance: total.trustBalance + row.trustBalance,
            trustAmount: total.trustAmount + row.trustAmount,
          }
        : total,
    summary,
  );
}

export const BulkTrustToOfficeRouteContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(composeHooks(queryHooks)(composeHooks(dependentHooks)(BulkTrustToOfficeRoute))),
  ),
);

BulkTrustToOfficeRouteContainer.displayName = 'BulkTrustToOfficeRouteContainer';

BulkTrustToOfficeRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onOpenTrustChequesModal: PropTypes.func.isRequired,
};

BulkTrustToOfficeRouteContainer.defaultProps = {};
