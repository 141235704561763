'use strict';

const { getFirmPhoneNumber } = require('./get-firm-phone-number');
const { getFirmCurrentUsers } = require('./get-firm-current-users');
const { getFirmNameAndAddress } = require('./get-firm-name-and-address');

module.exports = {
  getFirmPhoneNumber,
  getFirmCurrentUsers,
  getFirmNameAndAddress,
};
