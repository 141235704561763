import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import FinancialSummaryItem from './FinancialSummaryItem';
import FinancialSummary from './FinancialSummary';

export const FinancialSummaryByFirm = ({
  onClickLink,
  totals,
  onFilterByRecent,
  onFilterInvoiceStatus,
  showControlledMoneyAccount,
  showTrustAccount,
  trustTooltip,
  onNavigateToTrustOverview,
}) => {
  const { t } = useTranslation();
  return (
    <FinancialSummary>
      <div className="financial-summary-totals">
        <div className="totals-container">
          <FinancialSummaryItem
            name="Unbilled"
            total={totals.UNBILLED}
            onClick={() => onClickLink({ type: 'bulkBill' })}
          />
          <FinancialSummaryItem
            name="Unpaid"
            total={totals.UNPAID}
            onClick={() => {
              onFilterByRecent(false);
              onFilterInvoiceStatus(['FINAL', 'OVERDUE']);
              onClickLink({ type: 'bills' });
            }}
          />
          <FinancialSummaryItem
            name="Overdue"
            total={totals.OVERDUE}
            onClick={() => {
              onFilterByRecent(false);
              onFilterInvoiceStatus(['OVERDUE']);
              onClickLink({ type: 'bills' });
            }}
          />
          <FinancialSummaryItem
            total={totals.CREDIT}
            name="Credit"
            show={totals.CREDIT > 0}
            onClick={() => onClickLink({ type: 'transactions', id: 'credit-note-ledger' })}
          />
          <FinancialSummaryItem
            name={t('operatingRetainer')}
            total={totals.OPERATING}
            show={totals.OPERATING > 0}
            onClick={() => onClickLink({ type: 'transactions', id: 'operating' })}
          />
          <FinancialSummaryItem
            name={t('trust')}
            show={showTrustAccount}
            total={totals.TRUST}
            onClick={onNavigateToTrustOverview}
            tooltip={trustTooltip}
          />
          <FinancialSummaryItem
            name={t('CMA')}
            redText={totals.CONTROLLEDMONEY < 0}
            show={showControlledMoneyAccount}
            total={totals.CONTROLLEDMONEY}
          />
        </div>
      </div>
    </FinancialSummary>
  );
};

FinancialSummaryByFirm.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onNavigateToTrustOverview: PropTypes.func.isRequired,
  onFilterByRecent: PropTypes.func.isRequired,
  onFilterInvoiceStatus: PropTypes.func.isRequired,
  totals: PropTypes.object.isRequired,
  showControlledMoneyAccount: PropTypes.bool.isRequired,
  trustTooltip: PropTypes.element,
  showTrustAccount: PropTypes.bool,
};
FinancialSummaryByFirm.defaultProps = {
  trustTooltip: undefined,
  showTrustAccount: false,
};
