import { useEffect } from 'react';

// Based on the click outside logic used in DatePicker
// see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
const useClickOutside = (ref, handler) => {
  useEffect(() => {
    if (!handler) {
      return undefined;
    }

    const handleClickOutside = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, handler]);
};

export { useClickOutside };
