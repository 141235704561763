import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Styles from './MessageBar.module.scss';

/**
 * Message Bar
 * This was initially created to show warning messages above tables, but could be used anywhere really
 * @param {object} params
 * @param {React.ReactNode} params.children - custom message to show, can be a string or a component
 * @param {string} [params.messageText] - initially created as an option so we can use this same component in angular
 * @param {string} params.messageType - type of message to show, can be 'warn', 'error', 'info', 'success'
 * @param {string} [params.className] - custom class name to apply to the message bar
 * @param {boolean} [params.showBorder] - whether to show a border around the message bar
 * @returns {React.ReactNode} - The message bar
 */
export const MessageBar = ({ children, messageText, messageType, className, showBorder }) => (
  <div
    className={classNames(Styles.messageBarContainer, Styles[messageType], showBorder && Styles.withBorder, className)}
  >
    {messageText || children}
  </div>
);

MessageBar.displayName = 'MessageBar';

MessageBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  messageText: PropTypes.string,
  messageType: PropTypes.oneOf(['warn', 'error', 'info', 'success']),
  showBorder: PropTypes.bool,
};

MessageBar.defaultProps = {
  children: undefined,
  messageText: undefined,
  messageType: undefined,
  className: undefined,
  showBorder: false,
};
