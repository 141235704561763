'use strict';

const { regionType } = require('@sb-itops/region');

const localQS = {
  [regionType.AU]: 'AU',
  [regionType.GB]: 'GB',
  [regionType.US]: '',
};

const getLocalModeQueryString = (region) => {
  if (!region) {
    throw new Error('Region is required');
  }

  if (localQS[region] === undefined) {
    throw new Error('Unsupported region');
  }

  return localQS[region];
};

module.exports = {
  getLocalModeQueryString,
};
