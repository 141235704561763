import utilsFactory from '../utils-factory';
import { domain } from '../domain';
import store from '../store';

// `immutable: false` required as immu + react dont play well together - See https://github.com/facebook/react/issues/16058
const { createActionName, registerReducer, getState } = utilsFactory({ reduxPath: `${domain}/name`, immutable: false });

export const initState = {
  messages: [],
  messageId: 0,
};

// action types
export const SHOW_MESSAGE = createActionName('SHOW_MESSAGE');
export const DISMISS_MESSAGE = createActionName('DISMISS_MESSAGE');
export const DISMISS_MESSAGE_GROUP = createActionName('DISMISS_MESSAGE_GROUP');

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      const { options } = action;

      const groupIndex = options.group ? state.messages.findIndex((message) => message.group === options.group) : -1;

      // if there is a group already, update the message in place
      if (groupIndex !== -1) {
        const oldMessage = state.messages[groupIndex];
        const message = {
          ...oldMessage,
          counter: oldMessage.counter + 1,
          timestamp: Date.now(),
          msgText: options.msgText,
          level: options.level,
        };

        return {
          ...state,
          messages: [...state.messages.slice(0, groupIndex), message, ...state.messages.slice(groupIndex + 1)],
        };
      }

      // otherwise we create a new message
      const messageId = state.messageId + 1;
      const message = {
        id: messageId,
        counter: 1,
        timestamp: Date.now(),
        timeoutId: options.timeout >= 0 ? setTimeout(() => dismissMessage(messageId), options.timeout) : undefined,
        ...options,
      };

      return {
        ...state,
        messages: [...state.messages, message],
        messageId,
      };
    }

    case DISMISS_MESSAGE_GROUP: {
      const index = state.messages.findIndex((message) => message.group === action.group);
      const message = state.messages[index];

      if (!message) {
        return state;
      }

      // Attempt to remove any auto dismiss timers to save on resources.
      clearTimeout(message.timeoutId);

      return {
        ...state,
        messages: [...state.messages.slice(0, index), ...state.messages.slice(index + 1)],
      };
    }

    case DISMISS_MESSAGE: {
      const index = state.messages.findIndex((message) => message.id === action.id);
      const message = state.messages[index];

      if (!message) {
        return state;
      }

      // Attempt to remove any auto dismiss timers to save on resources.
      clearTimeout(message.timeoutId);

      return {
        ...state,
        messages: [...state.messages.slice(0, index), ...state.messages.slice(index + 1)],
      };
    }

    default: {
      return state;
    }
  }
};

registerReducer(reducer);

// selectors
export const getMessages = () => getState().messages;

// actions
export const showMessage = (options) => {
  store.dispatch({
    type: SHOW_MESSAGE,
    options,
  });
};

export const dismissMessage = (id) =>
  store.dispatch({
    type: DISMISS_MESSAGE,
    id,
  });

export const dismissMessageGroup = (group) =>
  store.dispatch({
    type: DISMISS_MESSAGE_GROUP,
    group,
  });
