import { hasFacet, facets } from '@sb-itops/region-facets';

// TODO rename some function here (naming is misleading)
// getLastPdfPreviewBase64P => getPdfPreviewByIdBase64P
// getSinglePdfPreviewBase64P => getPdfPreviewByRequestBase64P
angular.module('@sb-billing/services').service('sbAccountRemindersPreviewService', function (sbGenericEndpointService, sbLoggerService) {

  const that = this;
  const log = sbLoggerService.getLogger('sbInvoicePreviewService');
  const singleEndpoint = 'billing/invoice-reminders/preview/';
  const combinedEndpoint = 'billing/invoice-reminders/preview-combined/';

  that.getSinglePdfPreviewBase64P = getSinglePdfPreviewBase64P;
  that.getCombinedPdfPreviewBase64P = getCombinedPdfPreviewBase64P;
  that.getLastPdfPreviewBase64P = getLastPdfPreviewBase64P;
  that.readyCombineNotificationMessage = 'RemindersPdfReadyNotification';
  that.progressCombineNotificationMessage = 'RemindersPdfProgressNotification';

  async function downloadPdfP(reminderId) {
    try {
      const docStreamEndpoint = 'billing/document-stream';
      const docStreamConfig = {
        skipCamelCase: true,
      };
  
      log.info(`Attempting to download invoice reminder : '${reminderId}', reminderId '${reminderId}'`);
      await sbGenericEndpointService.getPayloadP(docStreamEndpoint, `reminders/${reminderId}`, { ...docStreamConfig, method: 'HEAD' })
      return sbGenericEndpointService.getPayloadP(docStreamEndpoint, `reminders/${reminderId}`, docStreamConfig);
    }
    catch(ex){
      throw 'Failed to download invoice reminder pdf';
    }
  }

  function getSinglePdfPreviewBase64P(reminder) {
    log.info(`getting single pdf preview`, reminder);
    const body = {
      // for regions where reminder PDF cannot be sent as part of email due to GDPR, preview will
      // generate a PDF user can use, for this reason we don't want the draft watermark on the PDF
      // in reality I don't see why we would want to put the draft watermark on there. It's also 
      // worth noting that previously the includeWatermark sent here to endpoints were ignored anyway.
      includeWatermark: hasFacet(facets.allowEmailAttachment) ? true : false,
      data: {
        invoiceReminders: [buildSinglePdfReminderRequestData(reminder)],
       },
    };
    return sbGenericEndpointService.postPayloadP(singleEndpoint, ``, body, 'POST');
  }

  function getLastPdfPreviewBase64P(reminderId) {
    return downloadPdfP(reminderId);
  }

  function getCombinedPdfPreviewBase64P(requestId, reminders) {
    log.info(`combining pdf for ${requestId}`, reminders);
    const extraArgs = { skipCamelCase: true };
    const body = {
      requestId,
      data: {
        invoiceReminders: buildCombinePdfRequestData(reminders),
       },
    };

    return sbGenericEndpointService.postPayloadP(combinedEndpoint, ``, body, 'POST', extraArgs);
  }

  function buildCombinePdfRequestData(reminders) {
    return reminders.map(({debtorId, matterId, invoices = []}) => ({
      contactId: debtorId,
      matterId,
      invoiceIds: invoices.map((invoice) => invoice.invoiceId)
    }));
  }

  function buildSinglePdfReminderRequestData(reminder){
    const { debtorId, matterId, invoices } = reminder;
    return {
      contactId: debtorId,
      matterId,
      invoiceIds: invoices.map((invoice) => invoice.invoiceId)
    };
  }

});
