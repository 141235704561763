import React from 'react';
import PropTypes from 'prop-types';
import { forms2PropTypes, FormLabel, StatelessSelect } from '@sb-itops/react';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import classNames from 'classnames';
import { SimpleContactMultiSelect } from '../../simple-contact-multi-select';
import { includeErrorClassnameIfInvalid } from '../../add-edit-matter/include-error-classname-if-invalid';

// setting this to an unlikely high limit as clients and other sides has no limits technically
const NO_MAXIMUM = 1000000;

const MatterContacts = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields values
  clientCustomerIds,
  matterTypeId,
  clientRole,
  otherSideRole,
  otherSideCustomerIds,
  // form fields
  clientCustomerIdsIsRequired,
  // other
  clientRoleOptions,
  clientRoleLabel,
  otherSideRoleLabel,
  // callbacks
  onClientRoleUpdated,
  onClientsChanged,
  onOtherSidesChanged,
  onClickLink,
  setAddContactCallback,
}) => {
  if (!formInitialised) {
    return null;
  }

  const contactOptions = getContactTypeAheadSummaries().reduce(
    (acc, { display: label, id: value, typeahead: searchText, isDeleted }) => {
      if (!isDeleted) {
        acc.push({
          value,
          label,
          searchText,
        });
      }
      return acc;
    },
    [],
  );

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={`Client ${clientRoleLabel && clientRoleLabel !== 'Client' ? `(${clientRoleLabel})` : ``}`}
            field={clientCustomerIdsIsRequired}
            uppercase={false}
            submitFailed={submitFailed}
          />
          <div>
            <SimpleContactMultiSelect
              selectedContactIds={clientCustomerIds}
              contactOptions={contactOptions}
              onContactsChanged={onClientsChanged}
              max={NO_MAXIMUM} // there's technically no maximum of clients allowed, so just setting this to an unlikely high number
              disabled={formDisabled}
              isRequired={submitFailed && clientCustomerIdsIsRequired && clientCustomerIdsIsRequired.value}
              onClickLink={onClickLink}
              actionList={[
                {
                  displayComponent: (
                    <span>
                      <i className="fa fa-plus" /> &emsp;<strong>Add Contact</strong>
                    </span>
                  ),
                  callback: () =>
                    setAddContactCallback(
                      () => (contact) => onClientsChanged([...clientCustomerIds.filter(Boolean), contact.id]),
                    ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel uppercase={false} label="Client Role" field={clientRole} submitFailed={submitFailed} />
          <div>
            <StatelessSelect
              className={classNames(submitFailed && includeErrorClassnameIfInvalid(clientRole, submitFailed))}
              options={clientRoleOptions}
              disabled={formDisabled || !(matterTypeId && matterTypeId.value)}
              selectedOption={clientRole.value}
              onChange={(newClientRole) => onClientRoleUpdated(newClientRole.value)}
            />
          </div>
        </div>
      </div>

      {otherSideRole && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel
              label={otherSideRoleLabel || 'Other Side'}
              uppercase={false}
              submitFailed={submitFailed}
              optional
            />
            <div>
              <SimpleContactMultiSelect
                selectedContactIds={otherSideCustomerIds}
                contactOptions={contactOptions}
                onContactsChanged={onOtherSidesChanged}
                max={NO_MAXIMUM} // there's technically no maximum of other sides allowed, so just setting this to an unlikely high number
                disabled={formDisabled}
                isRequired={false}
                onClickLink={onClickLink}
                actionList={[
                  {
                    displayComponent: (
                      <span>
                        <i className="fa fa-plus" /> &emsp;<strong>Add Contact</strong>
                      </span>
                    ),
                    callback: () =>
                      setAddContactCallback(
                        () => (contact) => onOtherSidesChanged([...otherSideCustomerIds.filter(Boolean), contact.id]),
                      ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </fieldset>
  );
};

const selectOptions = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

MatterContacts.displayName = 'MatterContacts';

const { Forms2Field } = forms2PropTypes;

MatterContacts.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  clientCustomerIds: PropTypes.arrayOf(PropTypes.string),
  matterTypeId: Forms2Field,
  clientRole: Forms2Field,
  otherSideRole: PropTypes.string,
  otherSideCustomerIds: PropTypes.arrayOf(PropTypes.string),
  // form fields
  clientCustomerIdsIsRequired: Forms2Field,
  // other
  clientRoleOptions: PropTypes.arrayOf(selectOptions),
  clientRoleLabel: PropTypes.string,
  otherSideRoleLabel: PropTypes.string,
  // callbacks
  onClientsChanged: PropTypes.func.isRequired,
  onOtherSidesChanged: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  setAddContactCallback: PropTypes.func.isRequired,
  onClientRoleUpdated: PropTypes.func.isRequired,
};

MatterContacts.defaultProps = {
  // form state
  formDisabled: false,
  formSubmitting: false,
  submitFailed: false,
  // form fields values
  clientCustomerIds: [],
  matterTypeId: undefined,
  clientRole: undefined,
  otherSideRole: undefined,
  otherSideCustomerIds: [],
  // form fields
  clientCustomerIdsIsRequired: undefined,
  // other
  clientRoleOptions: [],
  clientRoleLabel: undefined,
  otherSideRoleLabel: undefined,
};

export default MatterContacts;
