import { gql } from '@apollo/client';
import { createPolledSubscription } from 'web/services/apollo/polling-manager';

const query = gql`
  query InitFirmUser {
    loggedInUser {
      id
      permissions {
        userId
        isAuthorized
        resourceId
        operationId
      }
    }
  }
`;

const notificationIds = ['Authorization.PrincipalPolicyUpdated'];
const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

/** @type {import('./types').PollFunction} */
const poll = () =>
  createPolledSubscription({
    subscribedNotificationId: notificationIds[0],
    intervalMs: FIVE_MINUTES_IN_MS,
  });

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitFirmUser = {
  query,
  notificationIds,
  poll,
};
