import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { INVOICE_COMMUNICATE_MODAL_ID } from 'web/components';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import {
  DraftInvoiceCommunicateModalData,
  InvoiceCommunicateModalData,
  InitOperatingBankAccount,
  InitPaymentProviderSettings,
} from 'web/graphql/queries';
import {
  convertSettingsFromGQL,
  getFirmPaymentProviderPaymentSettings,
} from '@sb-billing/business-logic/payment-provider/services';
import { accumulateInvoiceEntriesTotalDuration } from '@sb-billing/business-logic/invoice/services';
import { getRegion } from '@sb-itops/region';

import { InvoiceCommunicateModalFormsContainer } from './InvoiceCommunicateModal.forms.container';
import { MultiTabInvoiceCommunicateModalFormsContainer } from './MultiTabInvoiceCommunicateModal.forms.container';

/**
 * Builds an object that the invoice communicate modal forms container can use
 *
 * This is needed for the Draft Invoices Page because the user can create and dynamically update the invoice communicate data
 *
 * --- Additional info ---
 * Creating and sending an invoice from the draft invoice page will:
 *  1. Be a single invoice
 *  2. Have either a single or multiple debtors
 *  3. Be linked to a single matter
 *
 * @param {object} params
 * @param {object} params.draftInvoiceCommunicateModalData
 * @param {string} params.invoiceId
 * @returns {object} Matches the data structure returned by the InvoiceCommunicateModalData query, which is expected by the forms container
 */
function buildInvoiceCommunicateModalData({ draftInvoiceCommunicateModalData, invoiceId }) {
  if (!draftInvoiceCommunicateModalData) {
    return undefined;
  }

  const { contacts, fees, firmEInvoiceSettings, firmUsersList, matter } = draftInvoiceCommunicateModalData;

  // Only fee entries are relevant for the [HOURS] value displayed in invoice communicate messages
  // Map fees as invoice entries
  const entries = fees.map((fee) => ({
    id: fee.id,
    feeEntity: fee,
  }));

  // Map contacts as invoice debtors
  const debtors = contacts.map((contact) => ({
    id: contact.id,
    contact,
  }));

  return {
    invoices: [
      {
        id: invoiceId,
        debtors,
        entries,
        matter,
      },
    ],
    firmEInvoiceSettings,
    firmUsersList,
  };
}

/**
 * This function will extract and process relevant data from a list of invoices in preparation for the forms container and invoice communicate requests
 *
 * @param {object} params
 * @param {object[]} params.invoices
 * @returns {{
 *  debtorIds: string[],
 *  invoiceTotalDurationsByIdMap: object,
 * }}
 */
function extractAndProcessDataFromInvoices({ invoices = [] }) {
  const { uniqueProperties, invoiceTotalDurationsByIdMap } = invoices.reduce(
    (acc, invoice) => {
      const { debtors } = invoice;

      debtors.forEach((debtor) => acc.uniqueProperties.debtorIds.add(debtor.id));

      acc.invoiceTotalDurationsByIdMap[invoice.id] = accumulateInvoiceEntriesTotalDuration({
        entries: invoice.entries,
      });

      return acc;
    },
    {
      uniqueProperties: {
        debtorIds: new Set(),
      },
      invoiceTotalDurationsByIdMap: {},
    },
  );

  return {
    debtorIds: Array.from(uniqueProperties.debtorIds),
    invoiceTotalDurationsByIdMap,
  };
}

// The Invoice Communicate Modal is currently only used for:
//  * Single invoices (i.e. multi invoice is not (yet) supported)
//  * Single or multi debtors
//  * No consolidated mode
const hooks = () => ({
  useInvoiceCommunicateModalDataQuery: ({ debtorId, draftInvoice, invoiceIds }) => {
    const { data, loading, error } = useSubscribedQuery(InvoiceCommunicateModalData, {
      variables: {
        ids: invoiceIds,
      },
      skip: draftInvoice,
    });

    // Exit early if draft invoice (useDraftInvoiceCommunicateModalDataQuery hook runs instead)
    if (draftInvoice) {
      return {};
    }

    if (error) {
      throw new Error(error);
    }

    const { debtorIds: uniqueDebtorIdsFromInvoices, invoiceTotalDurationsByIdMap } = extractAndProcessDataFromInvoices({
      invoices: data?.invoices,
    });

    // There are certain instances when a single debtor is selected (over the invoice debtors)
    const relevantDebtorIds = debtorId ? [debtorId] : uniqueDebtorIdsFromInvoices;

    const isMultiTabForm = invoiceIds.length === 1 && relevantDebtorIds.length > 1;

    return {
      debtorIds: relevantDebtorIds,
      firmEInvoiceSettings: data?.firmEInvoiceSettings,
      firmUsersList: data?.firmUsersList,
      invoiceCommunicateModalData: data,
      invoiceTotalDurationsByIdMap,
      isLoadingQuery: loading,
      isMultiTabForm,
      // To identify if an invoice actually contains multiple debtors is to use the uniqueDebtorIdsFromInvoices variable
      //  * uniqueDebtorIdsFromInvoices - counts the actual number of debtors on invoice
      //  * relevantDebtorIds - this value may be overridden
      // Therefore, this ensures that even if relevantDebtorIds is overridden to a single debtor, we still retain and pass the knowledge that it is a multi debtor invoice
      //  * This is specifically used by the appendPaymentAndEInvoicePlaceholders function regarding resending failed messages
      isMultiDebtorInvoice: invoiceIds.length === 1 && uniqueDebtorIdsFromInvoices.length > 1,
      showIncludeDebtor: isMultiTabForm,
    };
  },
  // This hook is specifically for the draft invoice page
  useDraftInvoiceCommunicateModalDataQuery: ({ draftInvoice, invoiceIds }) => {
    const { data, loading, error } = useSubscribedQuery(DraftInvoiceCommunicateModalData, {
      variables: {
        debtorIds: draftInvoice?.debtorIds,
        matterId: draftInvoice?.matterId,
        feeIds: draftInvoice?.feeIds,
      },
      skip: !draftInvoice,
    });

    // Exit early if not draft invoice (useInvoiceCommunicateModalDataQuery hook runs instead)
    if (!draftInvoice) {
      return {};
    }

    if (error) {
      throw new Error(error);
    }

    const invoiceCommunicateModalData = buildInvoiceCommunicateModalData({
      draftInvoiceCommunicateModalData: data,
      invoiceId: invoiceIds[0],
    });

    const invoiceTotalDurationsByIdMap = {
      [invoiceIds[0]]: accumulateInvoiceEntriesTotalDuration({
        entries: invoiceCommunicateModalData?.invoices[0]?.entries ?? [],
      }),
    };

    const debtorIds = draftInvoice.debtorIds;
    const isMultiDebtorInvoice = invoiceIds.length === 1 && debtorIds.length > 1;

    return {
      debtorIds,
      firmEInvoiceSettings: invoiceCommunicateModalData?.firmEInvoiceSettings,
      firmUsersList: invoiceCommunicateModalData?.firmUsersList,
      invoiceCommunicateModalData,
      invoiceTotalDurationsByIdMap,
      isLoadingQuery: loading,
      isMultiTabForm: isMultiDebtorInvoice,
      isMultiDebtorInvoice,
      showIncludeDebtor: isMultiDebtorInvoice,
    };
  },
  useInvoiceCommunicateModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: INVOICE_COMMUNICATE_MODAL_ID });

    return {
      region: getRegion(),
      onModalClose,
    };
  },
  usePaymentProviderSettingsQuery: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);
    const { data: paymentProviderSettingsData } = useCacheQuery(InitPaymentProviderSettings.query);

    const activeProviderType = paymentProviderSettingsData.paymentProviderSettings.activeProvider;

    const { showInvoiceLink: firmCanIncludePaymentLinks } = getFirmPaymentProviderPaymentSettings({
      activeProviderFormattedSettings:
        activeProviderType &&
        convertSettingsFromGQL({
          providerType: activeProviderType,
          formattedSettingsFromGQL: paymentProviderSettingsData.paymentProviderSettings.providers[activeProviderType],
        }),
      activeProviderType,
      operatingAccountId: operatingBankAccountData.bankAccounts[0]?.id,
    });

    return {
      firmCanIncludePaymentLinks,
    };
  },
});

const dependentHooks = () => ({
  useFirmUsersList: ({ firmUsersList }) => {
    // Provides the options for the FROM field
    const firmUserOptions = React.useMemo(
      () =>
        firmUsersList?.users?.map((user) => ({
          label: user.person.name,
          value: user.id,
        })),
      [firmUsersList?.users],
    );

    return {
      showPreviewButton: true,
      userOptions: firmUserOptions || [],
    };
  },
});

export const InvoiceCommunicateModalContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(
      composeHooks(dependentHooks)((props) =>
        props.isMultiTabForm ? (
          // Form for a single invoice with multiple debtors
          <MultiTabInvoiceCommunicateModalFormsContainer {...props} />
        ) : (
          // Standard form
          <InvoiceCommunicateModalFormsContainer {...props} />
        ),
      ),
    ),
  ),
);

InvoiceCommunicateModalContainer.displayName = 'InvoiceCommunicateModalContainer';

InvoiceCommunicateModalContainer.propTypes = {
  // debtorId prop
  //  * Normally, the debtorIds will be retrieved from the relevant invoice fetched
  //  * However, there are certain scenarios where we want to override and specify a debtorId instead:
  //    1. Contact Invoices page
  //      * When an invoice has multiple debtors, but we are on a specific contact's invoice page, we send it to the contact whose page we are on
  debtorId: PropTypes.string,
  invoiceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  scope: PropTypes.string.isRequired,
  // Callbacks
  onPreview: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  // Draft invoice specific props
  //  * The data on the draft invoices page is dynamic as it can be changed by user input (e.g. adding/removing fees or debtors)
  //  *  Therefore, we pass the relevant entity ids, which is sourced from redux. This will ensure we query for the most up to date data
  draftInvoice: PropTypes.shape({
    debtorIds: PropTypes.arrayOf(PropTypes.string),
    feeIds: PropTypes.arrayOf(PropTypes.string),
    matterId: PropTypes.string,
  }),
};

InvoiceCommunicateModalContainer.defaultProps = {
  debtorId: undefined,
  draftInvoice: undefined,
};
