'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-contact/matters';

  $stateProvider

  .state('home.billing.view-contact.matters', {
    url: '/matters',
    templateUrl: BASE_DIRECTORY + '/view-contact-matters.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'contacts', 'view-contact'],
      tab: { type: 'contact' }
    },
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl'
  });

});
