'use strict';

const { applyEmailHtmlWrapper } = require('@sb-itops/email');

const buildInvoiceEmailWithSummaryText = (emailBody) => {
  // The [INVOICE_NUMBER] placeholder is interpolated in the .NET code (BB-7333)
  const isSummaryBoxPlaceHolderInsertedInTemplate = emailBody.includes('[SUMMARY_BOX]');

  // if the SUMMARY_BOX placeholder is NOT present in the Invoice Email Template
  // then show summary box after the custom email body
  const emailBodyWithSummaryText = `
  <div class="dod-invoice">
    <span class="email-title">INVOICE #[INVOICE_NUMBER]</span>
    <br />
    <br />
    ${emailBody}
    <br />

    ${!isSummaryBoxPlaceHolderInsertedInTemplate ? '[SUMMARY_BOX]' : ''}
  </div>
  `;

  const finalEmailHtml = applyEmailHtmlWrapper({ emailBody: emailBodyWithSummaryText });

  return finalEmailHtml;
};

module.exports = {
  buildInvoiceEmailWithSummaryText,
};
