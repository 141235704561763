'use strict';

angular.module('sb.billing.webapp').component('sbDataTransactionsByFilters',{
  require: { composer: '^sbCompose' },
  bindings: { filters: '<', entityDataKey: '@', showHidden: '<' },
  controller: function ($scope, sbLoggerService, sbTransactionService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDataTransactionsByFilters');
    const entityDataKey = that.entityDataKey || 'transactions';

    //log.setLogLevel('info');

    that.$onChanges = (changesObj) => {
      extractBindingsChange('filters', changesObj);
      extractBindingsChange('entityDataKey', changesObj);
      updateTransactions();
    };

    $scope.$on('smokeball-data-update-sbTransactionService', () => {
      log.info('saw data update');
      updateTransactions();
    });

    $scope.$on('funds-deposited', () => {
      log.info('saw funds deposited');
      updateTransactions();
    });

    function extractBindingsChange(key, changesObj) {
      if (changesObj && changesObj[key] && changesObj[key].currentValue) {
        that[key] = changesObj[key].currentValue;
      }
    }

    function updateTransactions() {
      log.info('fetching transactions using filters', that.filters);
      let transactions = sbTransactionService.getByFilter(that.filters) || [];

      if (!that.showHidden) {
        transactions = transactions.filter((tx) => !tx.isHidden);
      }

      log.info('transactions : ', transactions);
      that.composer.setComposeData({dataType: entityDataKey, data: transactions}, entityDataKey);
    }
  }
});
