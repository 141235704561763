function getFirstOrganisationInList(contacts) {
  return contacts.find(
    (entity) =>
      entity.groupOfPeople ||
      entity.organisation ||
      entity.company ||
      entity.partnership ||
      entity.soleProprietor ||
      entity.trust,
  );
}

module.exports = {
  getFirstOrganisationInList,
};
