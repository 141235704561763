import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import smokeballPayLogo from '../assets/smokeball-logo.svg';
import Styles from './FeeWiseSavedCardForm.module.scss';

export const FeeWiseSavedCardForm = ({ savedCard }) => (
  <div className={Styles.savedCardChargeForm}>
    <fieldset disabled>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label>Description (Internal Only)</label>
          <div>
            <input className="form-control" readOnly type="text" value={savedCard.description || ''} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <div className="row">
            <div className="col-xs-6 form-group">
              <label>Card Number</label>
              <div>
                <input className="form-control" readOnly type="text" value={savedCard.display} />
              </div>
            </div>

            <div className="col-xs-3" />
            <div className={classNames('col-xs-3', Styles.feeWiseLogoContainer)}>
              <img
                className={Styles.feeWiseLogo}
                src={smokeballPayLogo}
                alt={`Processing by ${providerNames.FEE_WISE}`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 form-group">
          <label>Expiration Date</label>
          <div className={Styles.expiration}>
            <input className="form-control" readOnly type="text" value={savedCard.expirationMonth} />
            <span className={Styles.separator}>/</span>
            <input className="form-control" readOnly type="text" value={savedCard.expirationYear} />
          </div>
        </div>
      </div>
    </fieldset>
  </div>
);

FeeWiseSavedCardForm.displayName = 'FeeWiseSavedCardForm';

FeeWiseSavedCardForm.propTypes = {
  savedCard: PropTypes.object.isRequired,
};

FeeWiseSavedCardForm.defaultProps = {};
