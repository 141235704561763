import uuid from '@sb-itops/uuid';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { getById as getStaffHourlyRateById } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbStaffHourlyRateService',
  keyPath: 'staffId',
});

export const saveStaffHourlyRate = (staffHourlyRate) => {
  const saveStaffHourlyRateThunk = async () => {
    let newStaffHourlyRate = { ...staffHourlyRate, versionId: uuid() };

    const existingStaffHourlyRate = getStaffHourlyRateById(staffHourlyRate.staffId);
    if (existingStaffHourlyRate) {
      newStaffHourlyRate = { ...existingStaffHourlyRate, ...newStaffHourlyRate };
    }

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [newStaffHourlyRate] });

    // Apply the save in the backend.
    try {
      const path = `/billing/staff-hourly-rate/:accountId/staff/${newStaffHourlyRate.staffId}`;
      const fetchOptions = { body: JSON.stringify(newStaffHourlyRate) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [newStaffHourlyRate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveStaffHourlyRateThunk);
};
