import Big from 'big.js';
import { sort as sortItems } from '@sb-itops/sort';

// this do not need to be a parameters since it will be handled by the function. If this changes the whole behaviour would change
const sortBy = ['effectiveDate', 'timestamp'];

/**
 * A Configuration
 * @typedef {Object} Configuration
 * @property {Object} chequesSelected - object with uuid as props that identifies the selected cheques.
 * @property {string} sortDirection - asc or dsc. default to asc
 * @property {string} firstChequeNumber - the cheque number from which to start. default to 0
 * @property {number} chequeNumberPadding - the number of padding to add to the cheque number
 * @property {array} availableChequeNumbers - Array of available cheque numbers for unprinted cheques
 * @property {function} allowDuplicateNumbers - allow cheque numbers that have been used before in the sequence
 */

/**
 * generate the cheque number and creates a new array with the cheques and the reference assign based on the cheque configuration.
 * @param {Array.<Cheque>} list of cheques unsorted
 * @param {Configuration} configuration for generating the cheque number
 * @return {Array.<Cheque>} array of cheques with reference value populated
 */
export const assignReferenceNumberToCheques = (
  cheques,
  {
    chequesSelected = {},
    firstChequeNumber = '0',
    chequeNumberPadding = undefined,
    availableChequeNumbers = [],
    allowDuplicateNumbers = false,
  },
) => {
  const firstChequeNumberString = firstChequeNumber || '0';
  let lastChequeNumber = new Big(firstChequeNumberString).minus(1);
  const sortedChequesByOrder = sortItems(cheques, sortBy, ['asc', 'asc']);
  let counter = 0; // counter to keep trace of how many selected cheques has been iterated

  const referencesMap = sortedChequesByOrder.reduce((acc, cheque) => {
    if (chequesSelected[cheque.chequeId]) {
      lastChequeNumber = allowDuplicateNumbers ? lastChequeNumber.plus(1) : availableChequeNumbers[counter] || '';
      acc[cheque.chequeId] = `${lastChequeNumber.toString()}`.padStart(chequeNumberPadding, '0');
      counter += 1;
    }
    return acc;
  }, {});

  // If there are no selected cheques, this should be equal to the firstChequeNumber
  if (new Big(firstChequeNumberString).minus(1).toString() === lastChequeNumber.toString()) {
    lastChequeNumber = firstChequeNumber;
  }

  return {
    cheques: cheques.map((cheque) => ({
      ...cheque,
      reference: referencesMap[cheque.chequeId],
    })),
    lastChequeNumber: `${lastChequeNumber.toString()}`.padStart(chequeNumberPadding, '0'),
  };
};
