import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import AccountsReceivableFilter from './AccountsReceivableFilter';

const { periodOptions, periodOption } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);
const defaultSelectOption = { value: 'all', label: 'All' };

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const mapStateToProps = (state, { filters }) => ({
  contacts: getContactTypeAheadSummaries(),
  staff: [defaultSelectOption, ...getCurrentStaff().map((s) => ({ value: s.id, label: `${s.initials} - ${s.name}` }))],
  periodOptions,
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      const defaultState = {
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        showInvoicesFor: 'all',
        attorneyResponsible: defaultSelectOption,
        errors: {},
      };

      onFiltersChanged(defaultState);
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onMatterSelected: (matter) =>
    matter
      ? dispatch(
          onFiltersChanged({
            ...filters,
            matter: { id: matter.id, display: matter.display },
            errors: {
              ...filters.errors,
              matter: false,
            },
          }),
        )
      : dispatch(
          onFiltersChanged({
            ...filters,
            matter: undefined,
            errors: {
              ...filters.errors,
              matter: filters.showInvoicesFor === 'matter',
            },
          }),
        ),

  onContactSelected: (option) =>
    option
      ? dispatch(
          onFiltersChanged({
            ...filters,
            contact: { id: option.value, display: option.label },
            errors: {
              ...filters.errors,
              contact: false,
            },
          }),
        )
      : dispatch(
          onFiltersChanged({
            ...filters,
            contact: undefined,
            errors: {
              ...filters.errors,
              contact: filters.showInvoicesFor === 'contact',
            },
          }),
        ),

  onShowInvoicesFor: (showInvoicesFor) =>
    onFiltersChanged({
      ...filters,
      showInvoicesFor,
      errors: {
        ...filters.errors,
        matter: showInvoicesFor === 'matter' && !filters.matter,
        contact: showInvoicesFor === 'contact' && !filters.contact,
      },
    }),

  onSelectOption: ({ key, option }) => onFiltersChanged({ ...filters, [key]: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const AccountsReceivableFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(AccountsReceivableFilter)),
);

AccountsReceivableFilterContainer.displayName = `AccountsReceivableFilterContainer`;

export default AccountsReceivableFilterContainer;
