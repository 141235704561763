import { globalFeature } from '@sb-itops/redux/hofs';
import * as thisFeature from './feature';

import { toggleShowSystemDateFactory } from '../../features/show-system-date';

export const { reducer, selectors, actions, types, defaultPath } = globalFeature(thisFeature);

export const toggleShowSystemDateApi = toggleShowSystemDateFactory({
  reduxPath: 'home.billing.matter.transactions.system-date',
});
