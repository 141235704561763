'use strict';

const installmentFrequencyOptions = [
  { value: 'Weekly', label: 'Every Week' },
  { value: 'BiWeekly', label: 'Bi-Weekly' },
  { value: 'Monthly', label: 'Every Month' },
  { value: 'BiMonthly', label: 'Bi-Monthly' },
];

const installmentFrequencyLabel = installmentFrequencyOptions.reduce((labels, option) => {
  // eslint-disable-next-line no-param-reassign
  labels[option.value] = option.label;

  return labels;
}, {});

/**
 * Formats payment plan Enum value into a word
 * @param {string} installmentFrequency installmentFrequency from paymentPlan
 * @returns {string}
 */
const formatPaymentFrequency = (installmentFrequency) => {
  const paymentFrequency =
    (installmentFrequency && installmentFrequencyLabel[installmentFrequency]) || installmentFrequency;
  return paymentFrequency;
};

module.exports = {
  installmentFrequencyOptions,
  formatPaymentFrequency,
};
