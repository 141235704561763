import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';

angular.module('sb.billing.webapp').component('sbTransferReceiptView', {
  templateUrl: 'ng-components/transfer-receipt-view/transfer-receipt-view.html',
  controller: function ($rootScope, $stateParams, sbNotifiedOperationP, sbUnsavedChangesService) {
    'use strict';
    const ctrl = this;
    const log = getLogger('sbTransferReceiptView');

    ctrl.$onInit = () => {
      ctrl.sbParams = _.cloneDeep($stateParams); // need to do this while we have the stone age ui-router
      ctrl.sbData = {};
      ctrl.view = {
        receiptLoaded: false,
      };

      ctrl.savedChangesKey = `transfer-receipt_${ctrl.sbParams.paymentId}`;

      ctrl.getPayloadConfig = {
        endpoint: 'billing/transfer-receipt-pdf',
        additional: `${ctrl.sbParams.paymentId}/presigned-url`,
      };

      ctrl.tabCloseListenerDeregister = $rootScope.$on('smokeball-tab-closed', onTabClosed);
      ctrl.pdfUrl = sbUnsavedChangesService.loadMemory(ctrl.savedChangesKey)
    };

    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.onPdfLoaded = onReceiptLoaded;
    ctrl.onNotificationsP = onNotificationsP;
    ctrl.onError = onError;

    function onTabClosed(e, { type, transactionId }) {
      // Check if the tab containing this component is being closed.
      if (type === 'transfer-receipt' && transactionId === ctrl.sbParams.paymentId) {
        ctrl.tabCloseListenerDeregister();
        URL.revokeObjectURL(ctrl.pdfUrl);
        ctrl.pdfUrl = undefined;
        sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey);
      }
    }

    function onReceiptLoaded (pdfUrl) {
      ctrl.pdfUrl = pdfUrl;
      sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey, pdfUrl);
      ctrl.view.receiptLoaded = true;
    }

    function dataChangeFunction (key, doc) {
      log.info('data change', key, doc);

      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }
    }

    function onNotificationsP() {
      return Promise.all([
        waitForTransactionNotificationP(),
      ]);
    }

    async function waitForTransactionNotificationP () {
      sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'AccountsNotifications',
        completionFilterFn: (message) => message.messageId === 'AccountTransactionsRecorded' && message.entityIds.includes(ctrl.sbParams.transactionId),
        timeoutMs: 10000,
      });
    }

    function onError(err) {
      log.error(`Failed to download deposit receipt pdf for paymentId: ${ctrl.sbParams.paymentId}`, err);
      messageDisplay.error(`Failed to download deposit receipt pdf`);
    }
  }
});
