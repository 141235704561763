angular.module('sb.billing.webapp').component('sbDataTrustChequeById', {
  require: { composer: '^sbCompose' },
  bindings: { chequeId: '<?', composeDataKey: '@?' },
  controller: function ($scope, sbLoggerService, sbTrustChequeService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataTrustChequeById');
    const entityDataKey = ctrl.composeDataKey || 'trustCheque';

    ctrl.$onChanges = () => {
      updateTrustCheque();
    };

    $scope.$on('smokeball-data-update-sbTrustChequeService', () => {
      log.info('saw data update');
      updateTrustCheque();
    });

    function updateTrustCheque () {
      const trustCheque = sbTrustChequeService.getById(ctrl.chequeId);

      log.info('trustCheque: ', trustCheque);

      if (trustCheque) {
        ctrl.composer.setComposeData({ data: trustCheque, dataType: entityDataKey }, entityDataKey);
      }
    }
  }
});
