import {
  updateCache as updateRedux, 
}  from '@sb-billing/redux/invoice-reminder-settings';

angular.module('@sb-billing/services').service('sbAccountRemindersSettingsService', function (sbGenericCacheService, sbGenericEndpointService, sbEndpointType) {
  'use strict';

  const ENDPOINT = 'billing/invoice-reminders/settings';
  sbGenericCacheService.setupCache({
    name: 'sbAccountRemindersSettingsService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: ENDPOINT},
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.InvoiceReminderSettingsUpdated'
    },
    updateRedux
  });
});

