/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './StaffSelectorList.module.scss';

export const StaffSelectorList = ({
  staff,
  selectedStaffIds,
  showNoneOption,
  disableDefaultPadding,
  onToggleStaff,
  onSelectStaff,
  onDeselectAllStaff,
}) => (
  <div className={classnames(Styles.staffSelectorList, !disableDefaultPadding && Styles.defaultPadding)}>
    {staff.map(({ id, name, initials }) => {
      const isSelected = selectedStaffIds[id];
      return (
        <span
          key={`staff-selector-${id}`}
          className={classnames(Styles.staffLabel, isSelected ? Styles.labelPrimary : Styles.labelDefault)}
          onClick={() => onToggleStaff(id)}
          title={name}
          alt={name}
        >
          {initials}
        </span>
      );
    })}

    {showNoneOption && (
      <span
        key="staff-selector-none"
        className={classnames(Styles.staffLabel, selectedStaffIds.none ? Styles.labelPrimary : Styles.labelDefault)}
        onClick={() => onToggleStaff('none')}
        title="None"
        alt="None"
      >
        None
      </span>
    )}

    <ul className={Styles.links}>
      <li
        onClick={() => {
          // At the moment selectAllItems only selects items already in the
          // reducer's map (ie, staff that have been manually selected). This is
          // because we do not init the feature with all the available staffIds.
          // For now, we will loop over all the avaialble staff and select them.
          // More info: https://git.cd.nova.int.boostbilling.com/boost/sb-billing/merge_requests/7629/diffs
          const staffIds = staff.map(({ id }) => id);
          if (showNoneOption) {
            staffIds.push('none');
          }
          onSelectStaff(staffIds);
        }}
      >
        Select All
      </li>
      <li onClick={onDeselectAllStaff}>Clear All</li>
    </ul>
  </div>
);

StaffSelectorList.displayName = 'StaffSelectorList';

StaffSelectorList.propTypes = {
  staff: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  selectedStaffIds: PropTypes.object.isRequired,
  showNoneOption: PropTypes.bool,
  disableDefaultPadding: PropTypes.bool,
  onToggleStaff: PropTypes.func,
  onSelectStaff: PropTypes.func,
  onDeselectAllStaff: PropTypes.func,
};

StaffSelectorList.defaultProps = {
  disableDefaultPadding: false,
  onToggleStaff: () => {},
  onSelectStaff: () => {},
  onDeselectAllStaff: () => {},
  showNoneOption: false,
};
