import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortDirectionFeature from '@sb-itops/redux/sort';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import {
  getActiveByMatterId as getProtectedFundsByMatterId,
  getActiveAll as getProtectedFunds,
  getActiveByMatterBankAccountId as getProtectedFundsByMatterBankAccountId,
  getActiveByBankAccountId as getProtectedFundsByBankAccountId,
  unprotectBalance,
} from '@sb-billing/redux/balance-protection';
import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { sortByOrder } from '@sb-itops/nodash';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getMatterDisplayById, getById as getMatterById } from '@sb-matter-management/redux/matters';
import { isClosedMatter } from '@sb-matter-management/business-logic/matters/services';
import { buildSubmitForm, getPdfOptionsByReportType } from '@sb-billing/redux/report-config';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { useTranslation } from '@sb-itops/react';
import { ProtectedFunds } from './ProtectedFunds';

const FEATURE_SCOPE = 'protected-funds';
const UNPROTECT_BALANCE_CONFIRMATION_MODAL_ID = 'unprotect-balance-confirmation';
const log = getLogger('ProtectedFundsContainer');

const getFilteredProtectedFunds = ({ matterId, trustAccountId }) => {
  if (matterId && trustAccountId) {
    return getProtectedFundsByMatterBankAccountId(matterId, trustAccountId);
  }
  if (matterId) {
    return getProtectedFundsByMatterId(matterId);
  }
  if (trustAccountId) {
    return getProtectedFundsByBankAccountId(trustAccountId);
  }
  return getProtectedFunds();
};

const sortProtectedFunds = ({ sortBy, sortDirection, protectedFunds }) => {
  if (sortBy && sortDirection) {
    if (sortBy === 'contactDisplay') {
      return sortByOrder(
        protectedFunds,
        [sortBy, 'matterDisplay', 'timestamp'],
        [sortDirection, 'asc', sortDirection],
        false,
      );
    }
    if (sortBy === 'matterDisplay') {
      return sortByOrder(
        protectedFunds,
        [sortBy, 'contactDisplay', 'timestamp'],
        [sortDirection, 'asc', sortDirection],
        false,
      );
    }
    return sortByOrder(protectedFunds, [sortBy, 'timestamp'], [sortDirection, sortDirection], false);
  }
  return protectedFunds;
};

const hooks = ({ matterId, trustAccountId, sbReportingService, sbAsyncOperationsService }) => ({
  useProtectedFundsReportDownload: () => {
    const [isDownloading, setDownloading] = useState('');
    const { t } = useTranslation();
    // used for both PDF and CSV
    const downloadReport = async ({
      format = 'pdf',
      matterId: matterIdSelected,
      trustAccountId: bankAccountId,
      sortBy,
      sortDirection,
    }) => {
      if (isDownloading) {
        return;
      }

      setDownloading(format);
      const trustAccount = getBankAccountById(bankAccountId);
      const bankAccountName = trustAccount ? trustAccount.accountName || t('trustAccount') : undefined;

      try {
        const form = {
          reportType: 'protected-funds-listing',
          matterId: matterIdSelected,
          bankAccountId,
          bankAccountName,
          sortBy,
          sortDirection,
        };

        const allowOverdraw = hasFacet(facets.allowOverdraw);
        const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
        const supportsCMA = hasFacet(facets.CMA);
        const filterData = await sbReportingService.generateReportP(
          buildSubmitForm(form, t, { supportsEnteredDate, allowOverdraw, supportsCMA }),
        );

        const requestData = {
          reportGenerationId: filterData.reportGenerationId,
          reportType: filterData.data.reportType,
          ...(format === 'pdf'
            ? {
                pdfOptions: getPdfOptionsByReportType(filterData.data.reportType, t, {
                  supportsEnteredDate,
                  allowOverdraw,
                  supportsCMA,
                }),
              }
            : {}),
        };
        sbAsyncOperationsService.startGenerateReport(format, requestData);
      } catch (e) {
        messageDisplay.error(messageDisplay.builder().text('Failed to generate file. Please try again later.'));
      }

      setDownloading('');
    };

    return {
      downloadReport,
      isDownloading,
    };
  },
  useSelectors: () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [unprotectBalanceId, setUnprotectBalanceId] = useState('');
    const { sortFeature } = useSelector((state) =>
      withScopedFeatures({ state, scope: FEATURE_SCOPE })({
        sortFeature: sortDirectionFeature,
      }),
    );

    const trustAccount = getBankAccountById(trustAccountId);
    const protectedFunds = getFilteredProtectedFunds({ matterId, trustAccountId });
    const mappedProtectedFunds = protectedFunds.map((pf) => ({
      ...pf,
      contactDisplay: (pf.contactId && getContactDisplay(pf.contactId, { showLastNameFirst: true })) || '',
      matterDisplay: getMatterDisplayById(pf.matterId) || '',
    }));

    const sortBy = sortFeature.selectors.getSortBy() || 'timestamp';
    const sortDirection = sortFeature.selectors.getSortDirection() || 'desc';
    const sortedMappedProtectedFunds = sortProtectedFunds({
      sortBy,
      sortDirection,
      protectedFunds: mappedProtectedFunds,
    });

    const protectedFundsTitle = `Protected Funds: ${getBankAccountName(trustAccount, t)}`;
    return {
      protectedFunds: sortedMappedProtectedFunds,
      trustAccountId,
      protectedFundsTitle,
      sortBy,
      sortDirection,
      unprotectBalanceModalId: UNPROTECT_BALANCE_CONFIRMATION_MODAL_ID,
      supportsMatterContactBalance: hasFacet(facets.matterContactBalance),
      onSort: ({ sortDirection: newSortDirection, sortBy: newSortBy }) => {
        dispatch(sortFeature.actions.setSortDirection({ sortDirection: newSortDirection }));
        dispatch(sortFeature.actions.setSortBy({ sortBy: newSortBy }));
      },
      onUnprotectBalance: async () => {
        try {
          setModalDialogHidden({ modalId: UNPROTECT_BALANCE_CONFIRMATION_MODAL_ID });
          setUnprotectBalanceId('');
          await unprotectBalance(unprotectBalanceId);
          messageDisplay.success('Funds unprotected successfully.');
        } catch (err) {
          log.error(err);
          messageDisplay.error('Failed to unprotect funds.');
        }
      },
      showUnprotectBalanceModal: (id) => {
        setModalDialogVisible({ modalId: UNPROTECT_BALANCE_CONFIRMATION_MODAL_ID });
        setUnprotectBalanceId(id);
      },
    };
  },
  useProtectFundsDisabled: () => {
    if (!hasFacet(facets.blockTrustTransactionsOnClosedMatter)) {
      return { protectFundsIsDisabled: false };
    }

    const matterIsClosed = isClosedMatter(getMatterById(matterId));

    return {
      protectFundsIsDisabled: matterIsClosed,
      protectFundsIsDisabledTooltip: matterIsClosed ? 'Cannot transact on closed matters' : '',
    };
  },
});

export const ProtectedFundsContainer = withReduxProvider(composeHooks(hooks)(ProtectedFunds));

ProtectedFundsContainer.displayName = 'ProtectedFundsContainer';

ProtectedFundsContainer.propTypes = {
  matterId: PropTypes.string,
  trustAccountId: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  sbReportingService: PropTypes.object,
  sbAsyncOperationsService: PropTypes.object,
};

ProtectedFundsContainer.defaultProps = {
  matterId: undefined,
  trustAccountId: undefined,
  sbReportingService: undefined,
  sbAsyncOperationsService: undefined,
};
