import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toCamelCase } from '@sb-itops/camel-case';
import { getLogger } from '@sb-itops/fe-logger';
import { featureActive } from '@sb-itops/feature';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchPostP } from '@sb-itops/redux/fetch';
import uuid from '@sb-itops/uuid';

import { subscribeToNotifications } from 'web/services/subscription-manager';

import { InvoicePreview } from './InvoicePreview';

const log = getLogger('InvoicePreview.container');

const useFetchPreviewPdfBlob = ({ invoicePreviewData, fetchEntryDetails }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchInvoicePreviewPdf() {
      try {
        setLoading(true);
        setResult(null);

        const response = await fetchPostP({
          path: `/billing/invoice-pdf/preview/:accountId/presigned-url/`,
          fetchOptions: {
            body: JSON.stringify(invoicePreviewData),
          },
        });

        const preSignedUrl = response.body?.preSignedUrl;
        const pdfFileResponse = await fetch(preSignedUrl);
        const fileBlob = await pdfFileResponse.blob();
        const pdfBlob = new Blob([fileBlob], { type: 'application/pdf' }); // without this line the PDF will be downloaded instead of showing a preview

        setResult(pdfBlob);
      } catch (error) {
        log.error('Error occurred while fetching invoice PDF preview', error);
        displayErrorToUser('Error occurred while generating invoice PDF preview');
      } finally {
        setLoading(false);
      }
    }

    if (invoicePreviewData && !featureActive('BB-12895')) {
      fetchInvoicePreviewPdf();
    }
  }, [invoicePreviewData]);

  useEffect(() => {
    async function generateInvoicePreviewPdf(requestId) {
      try {
        setLoading(true);
        setResult(null);

        await fetchPostP({
          path: `/billing/invoice-pdf/preview/:accountId/presigned-url-notified/`,
          fetchOptions: {
            body: JSON.stringify({ ...invoicePreviewData, requestId, fetchEntryDetails }),
          },
        });
      } catch (error) {
        log.error('Error occured while sending request to start invoice PDF preview generation: ', error);
        displayErrorToUser('Error occurred while generating invoice PDF preview');
        setLoading(false);
      }
    }

    let unsub;
    if (invoicePreviewData && featureActive('BB-12895')) {
      const requestId = uuid();

      const unsubReadyNotification = subscribeToNotifications({
        notificationIds: ['InvoicePreviewPdfReady'],
        callback: async (payload) => {
          const message = toCamelCase(JSON.parse(payload));

          // multiple users from same firm could be previewing different draft invoices
          if (message.requestId === requestId && message.previewUrl) {
            try {
              const preSignedUrl = message.previewUrl;
              const pdfFileResponse = await fetch(preSignedUrl);
              const fileBlob = await pdfFileResponse.blob();
              const pdfBlob = new Blob([fileBlob], { type: 'application/pdf' }); // without this line the PDF will be downloaded instead of showing a preview
              setResult(pdfBlob);
            } catch (error) {
              log.error('Error occured while downloading and converting invoice PDF blob for preview: ', error);
              displayErrorToUser('Error occurred while generating invoice PDF preview');
            } finally {
              setLoading(false);
            }
          }
        },
      });

      const unsubFailNotification = subscribeToNotifications({
        notificationIds: ['InvoicePreviewPdfFail'],
        callback: async (payload) => {
          const message = toCamelCase(JSON.parse(payload));

          // multiple users from same firm could be previewing different draft invoices
          if (message.requestId === requestId) {
            log.error('Error occured while generating invoice PDF preview on server: ', message.error);
            displayErrorToUser('Error occurred while generating invoice PDF preview');
            setLoading(false);
          }
        },
      });

      unsub = () => {
        unsubReadyNotification();
        unsubFailNotification();
      };

      generateInvoicePreviewPdf(requestId);
    }

    return unsub;
  }, [invoicePreviewData, fetchEntryDetails]);

  return [result, loading];
};

const hooks = (props) => ({
  useFetchPreviewPdfBlob: ({ invoicePreviewData, fetchEntryDetails }) => {
    const [pdfBlob] = useFetchPreviewPdfBlob({ invoicePreviewData, fetchEntryDetails });

    return {
      pdfBlob,
      showSampleIndicator: props.showSampleIndicator,
    };
  },
});

export const InvoicePreviewContainer = composeHooks(hooks)(InvoicePreview);

InvoicePreviewContainer.displayName = 'InvoicePreviewContainer';

InvoicePreviewContainer.propTypes = {
  invoiceId: PropTypes.string,
  invoicePreviewData: PropTypes.shape({
    invoice: PropTypes.object, // invoice version
    // invoice version must also contain
    // 1. retainerRequestAmount (if applicable)
    // 2. status (label)
    // 3. clients (array of contact objects with address details)
    // 4. footer (decoded html)
    invoiceTotals: PropTypes.object, // invoice totals
    invoiceSettings: PropTypes.object, // invoice settings template
    payments: PropTypes.arrayOf(PropTypes.object), // invoice payments
    priorBalances: PropTypes.object, // supplementary table: prior balance
    statement: PropTypes.object, // supplementary tables: invoice history, account summary, transaction history
    firmDetails: PropTypes.shape({
      abn: PropTypes.string,
      acn: PropTypes.acn,
    }),
    matter: PropTypes.shape({
      billingConfiguration: PropTypes.object,
    }),
    staff: PropTypes.object, // sbFirmManagementMbService.details, should probably fix the naming once we understand what this really is for
    fees: PropTypes.arrayOf(PropTypes.object), // feeVersion with feeId & feeEarner, once BB-12895 is ON only feeId will be sent
    expenses: PropTypes.arrayOf(PropTypes.object), // expenseVersion with expenseId & expenseEarner, once BB-12895 is ON only expenseId will be sent
  }),
  fetchEntryDetails: PropTypes.bool.isRequired, // force the fetching of entry details on the server end
  showSampleIndicator: PropTypes.bool,
};

InvoicePreviewContainer.defaultProps = {
  showSampleIndicator: false,
};
