import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  isErrorSmokeballPreChargeError,
  calculateFeeDetails,
  extractFeeSchedule,
  getMinChargeAmountInCents,
} from '@sb-billing/business-logic/payment-provider/services';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { feeCoverageModes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { addBatchPayment } from '@sb-billing/redux/invoices/batch-pay-invoice';
import { saveCard } from '@sb-billing/redux/payment-provider';
import {
  getActiveProvider,
  getProviderSettings,
  isFirmCardSavingEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import {
  PrintNow,
  PrintNotApplicable,
  PrintManually,
  printMethodsByCode,
  printMethodsByValue,
} from '@sb-billing/business-logic/cheques';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getLoggedInStaff, getFirmName } from '@sb-firm-management/redux/firm-management';
import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchPostP } from '@sb-itops/redux/fetch';
import * as forms from '@sb-itops/redux/forms2';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { PAYMENT_TYPE } from 'web/redux/selectors/payment-source';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { createBatchClientInvoicePaymentChargeRequest } from '@sb-billing/business-logic/payment-provider/requests/create-batch-client-invoice-payment-charge-request';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { useTranslation } from '@sb-itops/react';
import { getValidateFn } from '../payment-add-form';
import { PaymentAddModal } from './PaymentAddModal';
import { ADDING_PAYMENT_STEP, TAKING_PAYMENT_STEP } from '../payment-add-form/PaymentAddForm';

const ADD_PAYMENT_SCOPE = 'add-payment-form';
export const ADD_PAYMENT_MODAL_ID = 'add-payment-modal';
const ALLOCATE_FUNDS_GROUP_NAME = 'ALLOCATE_FUNDS';
const region = getRegion();

const hooks = ({ contactId, matterId, printCheques, onClose, onClickLink, isVisible }) => ({
  useSelectors: () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectors: formSelectors, operations: formOperations } = useScopedFeature(forms, ADD_PAYMENT_SCOPE);
    const formState = useSelector(formSelectors.getFormState);
    const { payments = [] } = useSelector(formSelectors.getFieldValues);
    const [currentStep, setCurrentStep] = useState(ADDING_PAYMENT_STEP);
    const [paymentsContainsUnpaidAnticipatedDisbursement, setPaymentsContainsUnpaidAnticipatedDisbursement] =
      useState(false);

    const [totalChargeAmount, setTotalChargeAmount] = useState();
    const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
    const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
    const [paymentFormData, setPaymentFormData] = useState(null);
    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();

    const onSaveCard = useCallback(async (saveCardFormData) => {
      const providerType = getActiveProvider();
      const payorId = saveCardFormData?.smokeballFormData?.payorId;
      const bankAccountId = saveCardFormData?.smokeballFormData?.bankAccountId;

      try {
        return await saveCard({
          bankAccountId,
          contactId: payorId,
          providerType,
          saveCardFormData,
        });
      } catch (error) {
        messageDisplay.error('Failed to save card details');
        throw error;
      }
    }, []);

    const onTakePaymentSuccess = useCallback(() => {
      messageDisplay.success(
        getTakePaymentMessage(true, {
          amount: paymentFormData?.smokeballFormData?.totalAmount,
          invoices: paymentFormData?.smokeballFormData?.invoices,
          payorId: paymentFormData?.smokeballFormData?.payorId,
          source: paymentFormData?.smokeballFormData?.source,
          t,
        }),
      );

      onTakePaymentComplete();
      onClose();
    }, [paymentFormData, t]);

    const onTakePaymentFailed = useCallback(
      ({ failureMessage }) => {
        messageDisplay.error(
          getTakePaymentMessage(false, {
            amount: paymentFormData?.smokeballFormData?.totalAmount,
            invoices: paymentFormData?.smokeballFormData?.invoices,
            payorId: paymentFormData?.smokeballFormData?.payorId,
            source: paymentFormData?.smokeballFormData?.source,
            failureMessage,
            t,
          }),
        );

        onTakePaymentComplete();
      },
      [paymentFormData, t],
    );

    const onTakePaymentComplete = () => {
      setPaymentFormData(null);
      setTriggerChargeFormSubmit(false);
    };

    useEffect(() => {
      const callback = (response) => {
        const message = JSON.parse(response);

        if (message.status !== 'FAILED') {
          onTakePaymentSuccess();
        } else {
          onTakePaymentFailed({ failureMessage: message?.smokeballResponse?.failure?.message });
        }
      };
      return subscribeToNotifications({
        notificationIds: ['PaymentProviderChargeCompleted'],
        callback,
      });
    }, [onTakePaymentFailed, onTakePaymentSuccess]);

    const isReadyToTakePayment = formState.formValid && chargeFormReadyForSubmit;
    const isSubmitting = triggerChargeFormSubmit || formState.formSubmitting;

    // we specifically want to display errors for reference, amount, effectiveDate
    const errors = [];
    if (formState?.fields?.reference?.invalidReason) {
      errors.push(formState?.fields?.reference?.invalidReason);
    }
    if (formState?.fields?.amount?.invalidReason) {
      errors.push(formState?.fields?.amount?.invalidReason);
    }
    if (formState?.fields?.effectiveDate?.invalidReason) {
      errors.push(formState?.fields?.effectiveDate?.invalidReason);
    }

    useEffect(() => {
      if (payments && isPaymentsContainsUnpaidAnticipatedDisbursement(payments)) {
        setPaymentsContainsUnpaidAnticipatedDisbursement(true);
      } else {
        setPaymentsContainsUnpaidAnticipatedDisbursement(false);
      }
    }, [payments]);

    if (paymentsContainsUnpaidAnticipatedDisbursement) {
      errors.push(`A selected invoice contains an unpaid anticipated ${t('expense')}`);
    }

    const providerType = getActiveProvider();
    const minAmountAllowed = providerType ? getMinChargeAmountInCents({ providerType, region }) : 0;

    const validate = async (event) => {
      event.preventDefault();
      await dispatch(formOperations.validateForm({ validateFn: getValidateFn({ t, minAmountAllowed }) }));
    };

    const onAddPayment = async () => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              const data = marshalData(formFieldValues);

              await payInvoices(data);

              if (
                data.sourceAccountType &&
                data.sourceAccountType.toUpperCase() === 'TRUST' &&
                data.pdfOnTrustPayment === true &&
                // if also printing trust cheque NOW, trust cheque wins and we don't navigate to TTO PDF
                // printing method 1 = Print Now
                !(data.chequePrintActive && formFieldValues?.printingMethod?.value === PrintNow)
              ) {
                onClickLink({
                  type: 'trustToOfficeTransferReceipt',
                  id: { transactionId: data.transactionId, paymentId: data.paymentId },
                });
              } else if (!(data.chequePrintActive && formFieldValues?.printingMethod?.value === PrintNow)) {
                messageDisplay.success('Payment added successfully');
              }

              if (
                formFieldValues?.source?.paymentType === PAYMENT_TYPE.trust &&
                formFieldValues?.printingMethod?.value === PrintNow
              ) {
                printCheques({
                  chequeId: data.transferBetweenAccountsTransactionId,
                  bankAccountId: data.sourceBankAccountId,
                });
              }

              onClose();
            },
          }),
        );
      } catch (err) {
        messageDisplay.error(messageDisplay.builder().text('Failed to process batch payments'));
      }
    };

    const onTakePayment = async (providerFormData) => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              const smokeballFormData = marshalData(formFieldValues, chargeFormData);

              const providerSpecificSettings = getProviderSettings(providerType);
              const staff = getLoggedInStaff();
              const staffName = staff.name || 'firm staff member';
              const firmName = getFirmName();
              const feeCoverageMode = providerSpecificSettings.clientCoversFeeOnPayments
                ? feeCoverageModes.CLIENT_PAYS
                : feeCoverageModes.FIRM_PAYS;
              setPaymentFormData({ providerFormData, smokeballFormData });

              const bankAccountId = smokeballFormData?.bankAccountId;
              const isCardSavingEnabled =
                bankAccountId && isFirmCardSavingEnabledForBankAccount({ providerType, bankAccountId });
              if (isCardSavingEnabled && smokeballFormData?.saveCardDetails) {
                const savedCard = await onSaveCard({ providerFormData, smokeballFormData });

                if (savedCard) {
                  await makeCharge({
                    providerType,
                    paymentFormData: {
                      providerFormData: {
                        ...providerFormData,
                        paymentToken: {
                          ...providerFormData.paymentToken,
                          id: savedCard.token,
                        },
                      },
                      smokeballFormData,
                    },
                    staffName,
                    firmName,
                    feeCoverageMode,
                    t,
                  });
                }
              } else {
                await makeCharge({
                  providerType,
                  paymentFormData: { providerFormData, smokeballFormData },
                  staffName,
                  firmName,
                  feeCoverageMode,
                  t,
                });
              }
            },
          }),
        );
      } catch (err) {
        if (isErrorSmokeballPreChargeError(err)) {
          const errText = `The card was declined by the card issuer: ${err.message}`;
          messageDisplay.error(errText);
        } else {
          messageDisplay.error(messageDisplay.builder().text('Failed to process batch payments'));
        }
        setTriggerChargeFormSubmit(false);
      }
    };

    const formattedProviderSpecificSettings = getProviderSettings(providerType);
    const clientIsCoveringFee = formattedProviderSpecificSettings?.clientCoversFeeOnPayments;

    return {
      totalChargeAmount,
      clientIsCoveringFee,
      currentStep,
      errors,
      contactId,
      matterId,
      printCheques,
      scope: ADD_PAYMENT_SCOPE,
      isVisible,
      isSubmitDisabled: isDisabled({ currentStep, isSubmitting, isReadyToTakePayment }),
      isSubmitLocked: isSubmitting,
      isTakingPaymentNow: formState?.fields?.takePaymentNow?.value,
      isReadyToTakePayment,
      onChargeFormDataChange: (providerSpecificChargeData) => {
        setChargeFormData(providerSpecificChargeData);
        // Since charge form change may change fee and therefore total amount charged, we must recalculate
        if (clientIsCoveringFee) {
          const desiredAmountInCents = formState?.fields?.amount?.value || 0;

          const feeSchedule = clientIsCoveringFee
            ? extractFeeSchedule({
                providerType,
                formattedProviderSpecificSettings,
                bankAccountId: getOperatingAccount().id,
              })
            : undefined;

          const feeDetails =
            clientIsCoveringFee &&
            calculateFeeDetails({
              providerType,
              feeSchedule,
              desiredAmountInCents,
              providerSpecificFields: providerSpecificChargeData,
            });

          setTotalChargeAmount(feeDetails.effectiveAmountInCents);
        }
      },
      onChargeFormSubmit: onTakePayment,
      onChargeFormReadyForSubmit: (isReady) => setChargeFormReadyForSubmit(!!isReady),
      triggerChargeFormSubmit,
      onSave: async (event) => {
        await validate(event);

        if (!payments.length) {
          toggleAllocateFundsWarning(true);
          // fall through so form fails and highlight fields
        }

        if (formState?.fields?.takePaymentNow?.value === true) {
          if (currentStep.id === TAKING_PAYMENT_STEP.id) {
            setTriggerChargeFormSubmit(true);
          } else if (formState.formValid === true) {
            toggleAllocateFundsWarning(false);
            setCurrentStep(TAKING_PAYMENT_STEP);
          }
        } else {
          toggleAllocateFundsWarning(false);
          onAddPayment();
        }
      },
      onClose,
    };
  },
});

const makeCharge = async ({ providerType, paymentFormData, staffName, firmName, feeCoverageMode, t }) => {
  const newCharge = createBatchClientInvoicePaymentChargeRequest({
    accountId: getAccountId(),
    requestedByUserId: getUserId(),
    providerType,
    paymentFormData,
    staffName,
    firmName,
    feeCoverageMode,
    t,
  });

  const path = `/billing/payment-provider/charge/${providerType.toLowerCase()}/${newCharge.accountId}/`;
  const fetchOptions = { body: JSON.stringify(newCharge) };
  const response = await fetchPostP({ path, fetchOptions });

  return response.body;
};

const isDisabled = ({ currentStep, isReadyToTakePayment, isSubmitting }) => {
  if (currentStep.id === TAKING_PAYMENT_STEP.id && !isReadyToTakePayment) {
    return true;
  }

  return isSubmitting;
};

const isPaymentsContainsUnpaidAnticipatedDisbursement = (payments) => {
  if (!featureActive('BB-9573')) return false;

  return payments.some((payment) => {
    const invoice = getInvoiceById(payment.invoiceId);

    return hasUnpaidAnticipatedDisbursements({
      invoice: invoice.currentVersion,
      getExpenseById,
      getExpensePaymentDetailsById,
    });
  });
};

const getTakePaymentMessage = (success, { amount, invoices, payorId, source, failureMessage, t }) => {
  const payorName = getContactDisplay(payorId);
  const amountWithSymbol = t('cents', { val: amount });

  let message = `A payment of ${amountWithSymbol} `;

  if (invoices.length > 1) {
    message += `for ${invoices.length} invoices `;
  } else {
    message += `against invoice #${invoices[0].invoiceNumber} `;
  }

  message += success ? `was successfully charged ` : `failed to be charged `;

  message += `to ${payorName}'s ${source}. ${!success ? failureMessage || '' : ''}`;

  return message;
};

const marshalData = (formFieldValues, providerSpecificChargeData) => {
  const {
    source,
    contactId,
    reference,
    comment,
    payments,
    reason,
    effectiveDate,
    amount,
    paymentType,
    printingMethod,
    pdfOnTrustPayment,
    chequeMemo,
    takePaymentNow,
    saveCardDetails,
  } = formFieldValues;

  const isBankAccount = source.paymentType !== PAYMENT_TYPE.direct;
  const isTrustPayment = source.paymentType === PAYMENT_TYPE.trust;

  const msg = {
    paymentId: uuid(), // need to generate for opdating and sending to print trust pdf
    payorId: contactId,
    source: isBankAccount ? undefined : source.value,
    sourceAccountType: isBankAccount ? source.paymentType.toUpperCase() : undefined, // TRUST|OPERATING
    sourceBankAccountId: isBankAccount ? source.bankAccountId : undefined,
    invoices: payments,
    reference,
    note: comment,
    effectiveDate,
    totalAmount: amount,
    paymentType, // used by backend to determine which message to send
  };

  let reasonValue = null;
  // reasonField is AU/GB facet
  if (featureActive('BB-5508') && hasFacet(facets.reasonField)) {
    reasonValue = reason;
  } else if (hasFacet(facets.trustPaymentReasonField) && isTrustPayment) {
    // trustPaymentReasonField is US facet
    // For US, we keep reason field hidden from UI, but set the reason value when submitted if the invoice payment source is trust
    const invoiceIds = (payments || []).map((payment) => `#${payment.invoiceNumber}`).join(', ');
    reasonValue = `Legal Fees on Invoice ${invoiceIds}`;
  }
  msg.reason = reasonValue;

  msg.isElectronicPayment =
    hasFacet(facets.electronicPayment) &&
    source.paymentType === PAYMENT_TYPE.trust &&
    printingMethod?.value === PrintNotApplicable;

  if (hasFacet(facets.allowOverdraw)) {
    msg.allowOverdraw = true;
  }

  // These values are needed for handling charges in onTakePayment function
  if (takePaymentNow) {
    const bankAccountId = getOperatingAccount().id;
    msg.bankAccountId = bankAccountId;
    msg.saveCardDetails = saveCardDetails;

    // Handle fees
    const providerType = getActiveProvider();
    // Calculate the fee details if the fee is being passed on to the client.
    const formattedProviderSpecificSettings = getProviderSettings(providerType);
    const clientIsCoveringFee = formattedProviderSpecificSettings.clientCoversFeeOnPayments;

    if (clientIsCoveringFee) {
      const feeSchedule = clientIsCoveringFee
        ? extractFeeSchedule({
            providerType,
            formattedProviderSpecificSettings,
            bankAccountId,
          })
        : undefined;

      const feeDetails = clientIsCoveringFee
        ? calculateFeeDetails({
            providerType,
            feeSchedule,
            providerSpecificFields: providerSpecificChargeData,
            desiredAmountInCents: amount || 0,
          })
        : {};

      msg.amountInCents = feeDetails.effectiveAmountInCents;
      msg.amountLessFees = feeDetails.desiredAmountInCents;
    } else {
      msg.amountInCents = amount;
      msg.amountLessFees = amount;
    }

    // Individual invoices need to have the paymentId attached
    // If amount is less than invoices totalPayments then apply to oldest invoice first
    const paymentIds = [];
    const totalPayments = payments.reduce((runningTotal, invoicePayment) => runningTotal + invoicePayment.amount, 0);
    if (amount !== totalPayments) {
      let remaining = amount;
      let invoices = [];
      for (let i = 0; i <= payments.length; i += 1) {
        let invoice = payments[i];

        if (!paymentIds[invoice.matterId]) {
          paymentIds[invoice.matterId] = uuid();
        }
        const paymentId = paymentIds[invoice.matterId];

        if (remaining < invoice.amount) {
          invoice = { ...invoice, amount: remaining, paymentId };
          invoices = [...invoices, invoice];
          break;
        }

        invoices = [...invoices, { ...invoice, paymentId }];

        remaining -= invoice.amount;
      }
      msg.invoices = invoices;
    } else {
      msg.invoices = payments.map((invoice) => {
        if (!paymentIds[invoice.matterId]) {
          paymentIds[invoice.matterId] = uuid();
        }
        const paymentId = paymentIds[invoice.matterId];

        return { ...invoice, paymentId };
      });
    }
  }

  msg.chequeMemo =
    printingMethod?.value === PrintNow || printingMethod?.value === PrintManually ? chequeMemo : undefined;

  if (source.paymentType === PAYMENT_TYPE.trust && pdfOnTrustPayment === true) {
    msg.pdfOnTrustPayment = true;
  }

  if (source.paymentType === PAYMENT_TYPE.trust && printingMethod) {
    const chequePrintActive = printingMethod.value !== PrintNotApplicable;
    // if chequePrintActive is false, set chequePrintMethod to the first print method options
    const chequePrintMethod = chequePrintActive
      ? printMethodsByValue[printingMethod.value].code
      : printMethodsByCode[0].code;
    // need to generate transaction Id (for the trust -> operating transaction) now so that it can be passed to Print Cheque Modal if necessary.
    const transferBetweenAccountsTransactionId = uuid();
    return {
      ...msg,
      transferBetweenAccountsTransactionId,
      chequePrintActive,
      chequePrintMethod,
    };
  }

  return msg;
};

const payInvoices = async (payment) => {
  const payload = { ...payment };
  // Add the operating account detail for payment from trust
  if (
    featureActive('BB-5509') &&
    hasFacet(facets.operatingAccountDetail) &&
    payment.sourceAccountType &&
    payment.sourceAccountType.toUpperCase() === 'TRUST'
  ) {
    const operatingAccount = getOperatingAccount() || {};
    const bankAccountDetails = { ...operatingAccount };
    payload.destinationBankAccountName = bankAccountDetails.accountName;
    payload.destinationBankAccountNumber = bankAccountDetails.accountNumber;
    payload.destinationBankBranchNumber = bankAccountDetails.branchNumber;
  }
  return addBatchPayment(payload);
};

const toggleAllocateFundsWarning = (show = true) => {
  messageDisplay.dismissGroup(ALLOCATE_FUNDS_GROUP_NAME);

  if (show) {
    messageDisplay.warn(
      messageDisplay.builder().text(`Please allocate funds to at least one invoice`).group(ALLOCATE_FUNDS_GROUP_NAME),
    );
  }
};

export const PaymentAddModalContainer = withReduxProvider(composeHooks(hooks)(PaymentAddModal));

PaymentAddModalContainer.displayName = 'PaymentAddModalContainer';

PaymentAddModalContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  onClose: PropTypes.func,
  printCheques: PropTypes.func,
  onClickLink: PropTypes.func,
  isVisible: PropTypes.bool,
};

PaymentAddModalContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  isVisible: false,
  onClose: () => {},
  printCheques: () => {},
  onClickLink: () => {},
};
