import composeHooks from '@sb-itops/react-hooks-compose';
import { useQuery } from '@apollo/client';
import { WidgetMattersWithNoActivity } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import moment from 'moment';
import { MattersWithNoActivityWidget } from './MattersWithNoActivityWidget';

const hooks = ({ settings }) => ({
  useGraphQLforRecentMatters: () => {
    const { data, loading, error, refetch } = useQuery(WidgetMattersWithNoActivity, {
      variables: {
        filter: {
          startDate: moment()
            .startOf('day')
            .subtract(settings.timeFrame || 1, settings.timeFrameUnits?.value || 'month')
            .toISOString(),
          onlyLeads: false,
          countOnly: settings?.style?.value === 'number',
        },
      },
      notifyOnNetworkStatusChange: true,
    });

    if (error) {
      throw new Error(error);
    }
    return {
      onRefresh: refetch,
      matters: data?.mattersWithNoActivity?.matters || [],
      count: data?.mattersWithNoActivity?.count || 0,
      loading,
    };
  },
});

export const MattersWithNoActivityWidgetContainer = withApolloClient(composeHooks(hooks)(MattersWithNoActivityWidget));
