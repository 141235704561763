import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ClickableIcon } from '../clickable-icon';

const CollapsibleHeader = memo(({ text, children, onClick, name, collapsed }) => (
  <div>
    <div className="collapsible-button-wrapper" id={name} onClick={onClick} role="presentation" title={text || ''}>
      <h3 className="collapsible-header">{text || ''}</h3>
      <ClickableIcon className={classnames((collapsed && 'icon-arrow-66') || 'icon-arrow-65', 'collapsible-button')} />
    </div>
    {children && !collapsed && <div>{children}</div>}
  </div>
));

CollapsibleHeader.displayName = 'CollapsibleHeader';

CollapsibleHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
};

CollapsibleHeader.defaultProps = {
  text: undefined,
  children: undefined,
  collapsed: false,
};

export default CollapsibleHeader;
