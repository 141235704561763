import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect } from '../select';
import Styles from './Typeahead.module.scss';

const Typeahead = (props) => {
  const {
    options,
    selectedOption,
    disabled,
    onSelect,
    onClear,
    onAdd,
    placeholder,
    className,
    hasError,
    showAdd,
    isAddActive,
    autoFocus,
    containerClassName,
    isClearable,
    menuPlacement,
    maxMenuHeight,
    actionList,
  } = props;
  const addStyles = `${Styles.add} ${disabled ? Styles.disabled : ''} ${!disabled && hasError ? Styles.error : ''}`;
  const icon = `${Styles.icon} fa ${isAddActive ? 'fa-minus' : 'fa-plus'} fa-fw`;

  return (
    <div className={classnames(Styles.container, containerClassName)}>
      <StatelessSelect
        disabled={disabled}
        options={options}
        onChange={onSelect}
        onAdd={onAdd}
        placeholder={placeholder}
        className={className}
        clearValue={onClear}
        hasError={hasError}
        selectedOption={selectedOption}
        showAdd={showAdd}
        showDropdown={false}
        isClearable={isClearable}
        autoFocus={autoFocus}
        menuPlacement={menuPlacement}
        maxMenuHeight={maxMenuHeight}
        actionList={actionList}
      />
      {showAdd && (
        <div className={addStyles} onClick={!disabled ? () => onAdd() : () => {}}>
          <i className={icon} />
        </div>
      )}
    </div>
  );
};

const option = PropTypes.shape({
  label: PropTypes.any,
  value: PropTypes.any.isRequired,
});

const actions = PropTypes.shape({
  displayComponent: PropTypes.any,
  callback: PropTypes.func,
});

Typeahead.propTypes = {
  selectedOption: PropTypes.oneOfType([option, PropTypes.string, PropTypes.number, PropTypes.array]),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  onAdd: PropTypes.func,
  options: PropTypes.arrayOf(option),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  showAdd: PropTypes.bool,
  isAddActive: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isClearable: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  maxMenuHeight: PropTypes.number,
  actionList: PropTypes.arrayOf(actions),
};

Typeahead.defaultProps = {
  autoFocus: undefined,
  selectedOption: undefined,
  placeholder: 'Select item...',
  onSelect: () => {},
  onClear: () => {},
  onAdd: () => {},
  options: [],
  className: undefined,
  containerClassName: undefined,
  disabled: false,
  hasError: false,
  showAdd: false,
  isAddActive: false,
  isClearable: true,
  menuPlacement: 'bottom',
  maxMenuHeight: undefined,
  actionList: [],
};

Typeahead.displayName = 'Typeahead';

export default Typeahead;
