import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { sendStatuses } from '@sb-billing/business-logic/correspondence-history';
import { ReminderCorrespondenceStatusIndicator } from './ReminderCorrespondenceStatusIndicator';

const hooks = ({ lastCorrespondenceHistory, onResendClicked }) => ({
  useSendStatusData: () => {
    const hasReminderBeenSent = !!lastCorrespondenceHistory;

    let sendStatus;
    if (hasReminderBeenSent && lastCorrespondenceHistory.status === sendStatuses.SUCCESS) {
      sendStatus = sendStatuses.SUCCESS;
    } else if (hasReminderBeenSent && lastCorrespondenceHistory.status === sendStatuses.ERROR) {
      sendStatus = sendStatuses.ERROR;
    } else {
      sendStatus = sendStatuses.NOT_SENT;
    }

    return {
      sendStatus,
      lastCorrespondenceHistory,
      onResendClicked: ({ sentVia }) => onResendClicked({ sentVia }),
    };
  },
});

export const ReminderCorrespondenceStatusIndicatorContainer = withReduxProvider(
  composeHooks(hooks)(ReminderCorrespondenceStatusIndicator),
);

ReminderCorrespondenceStatusIndicatorContainer.displayName = 'ReminderCorrespondenceStatusIndicatorContainer';

ReminderCorrespondenceStatusIndicatorContainer.propTypes = {
  lastCorrespondenceHistory: PropTypes.object,
  onResendClicked: PropTypes.func,
};

ReminderCorrespondenceStatusIndicatorContainer.defaultProps = {
  lastCorrespondenceHistory: undefined,
  onResendClicked: () => {},
};

export default ReminderCorrespondenceStatusIndicatorContainer;
