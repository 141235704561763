'use strict';

const { chequeStylesByRegion } = require('./cheque-styles');
const { printMethodsByValue } = require('./print-methods');

/**
 * Get Default Operating Cheque Print Settings
 * @param {Object} params
 * @param {String} params.region
 * @returns Object with default print settings
 */
const getDefaultOperatingChequePrintSettings = ({ region } = {}) => ({
  printingActive: false,
  includeVendorAddress: false,
  printMethod: printMethodsByValue.PrintLater.value,
  style: chequeStylesByRegion[region][0].value,
});

module.exports = {
  getDefaultOperatingChequePrintSettings,
};
