import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').service('sbWatcherNotificationService', function ($interval, $rootElement, $location, $rootScope, $window) {
  'use strict';

  const that = this;

  that.listeners = [];
  that.registerListener = registerListener;

  initEvents();

  function initEvents() {
    if (featureActive('WI-2086')) {
      $interval(checkWatchers, 1000);

      $rootScope.$on('$locationChangeSuccess', checkWatchers);

      $window.Smokeball = $window.Smokeball || {};
      $window.Smokeball.getWatchers = getWatchers;
    }
  }

  function registerListener(fn) {
    if (_.isFunction(fn)) {
      that.listeners.push(fn);
    }
  }

  function checkWatchers() {
    const count = getWatchers().length;

    that.listeners.forEach(listener => {
      listener({count, path: $location.path()});
    });
  }

  function getWatchers(root) {
    root = root || $rootElement;

    function getElemWatchers(element) {
      const isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
      const scopeWatchers = getWatchersFromScope(element.data().$scope);
      let watchers = scopeWatchers.concat(isolateWatchers);
      _.each(element.children(), function (childElement) {
        watchers = watchers.concat(getElemWatchers(angular.element(childElement)));
      });
      return watchers;
    }

    function getWatchersFromScope(scope) {
      if (scope) {
        return scope.$$watchers || [];
      } else {
        return [];
      }
    }

    return getElemWatchers(root);
  }
});
