import { store } from '@sb-itops/redux';
import {selectors as filterSelectors} from 'web/redux/route/home-billing-accounts-transactions';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions/operating';

  $stateProvider
    .state('home.billing.accounts.transactions.operating', {
      url: '/operating',
      templateUrl: BASE_DIRECTORY + '/operating-transactions.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'OPERATING',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        })
      },
      onEnter: function() {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.operating', {}], parentPageName: PARENT_PAGE_NAME }));
      }, 
    });
});
