'use strict';

const { deriveSectionOptionsFromAdditionalOptions } = require('./derive-section-options-from-additional-options');
const {
  deriveSupplementaryTablesAdditionalOptionsFromSectionOptions,
} = require('./derive-supplementary-tables-additional-options-from-section-options');
const { overrideTemplateSectionOptions } = require('./override-template-section-options');

/**
 * Get invoice section options based on the original template setting and current invoiceAdditionalOptions of the draft invoice.
 *
 * @param {object} originalInvoiceSettingsTemplate - Original template data set under firm setting.
 * @param {object} invoiceAdditionalOptions - invoiceAdditionalOptions get from the draft invoice.
 * @param {object} overriddenDraftInvoiceSettings - The overwrite config made in the draft invoice page.
 * @returns {object} - Updated invoice section options used for PDF generation purpose.
 */

const determineFinalSectionOptions = ({
  originalInvoiceSettingsTemplate,
  invoiceAdditionalOptions,
  overriddenDraftInvoiceSettings,
}) => {
  const sectionOptionsHasNoSettings = !(
    originalInvoiceSettingsTemplate.sectionOptions && originalInvoiceSettingsTemplate.sectionOptions.feeSummary
  ); // use feeSummary as the test for no settings

  const originalTemplateSectionOptions = sectionOptionsHasNoSettings
    ? deriveSectionOptionsFromAdditionalOptions({
        invoiceSettingsTemplate: originalInvoiceSettingsTemplate,
      })
    : originalInvoiceSettingsTemplate.sectionOptions;

  // When both additionalOptions and sectionOptions exist in original template
  // We use sectionOptions to derive additionalOptions in case there are conflict
  const originalTemplateInvoiceAdditionalOptions = sectionOptionsHasNoSettings
    ? originalInvoiceSettingsTemplate.invoiceAdditionalOptions
    : deriveSupplementaryTablesAdditionalOptionsFromSectionOptions({
        sectionOptions: originalInvoiceSettingsTemplate.sectionOptions,
      });

  const finalAdditionalOptions = {
    ...originalTemplateInvoiceAdditionalOptions,
    ...(invoiceAdditionalOptions || {}),
    ...(overriddenDraftInvoiceSettings || {}),
  };

  const updatedSectionOptions = overrideTemplateSectionOptions({
    originalTemplateSectionOptions,
    finalAdditionalOptions,
  });

  return updatedSectionOptions;
};

module.exports = {
  determineFinalSectionOptions,
};
