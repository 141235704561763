angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing';

  $stateProvider

  .state('home.billing', {
    url: '/billing',
    templateUrl: BASE_DIRECTORY + '/billing-nav.html',
    controller: 'sbBillingNavController',
    controllerAs: 'billingNav',
    abstract: true,
    data: {
      authorized: true,
      navHighlights: ['billing']
    }
  });

});
