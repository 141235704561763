'use strict';

angular.module('sb.billing.webapp').component('sbFocusableInput', {
  templateUrl: 'ng-components/focusable-input/focusable-input.html',
  bindings: {initialText: '<', focusKey: '@?', onChange: '&?', modelOptions: '<?'},
  controller: function (focusService, sbUuidService, $element) {
    const that = this;

    // Create a unique focus key if none is provided.
    if (_.isEmpty(that.focusKey)) {
      that.focusKey = sbUuidService.get();
    }

    that.text = that.initialText;

    focusService.register(that.focusKey, $element.find('input'));

    that.selectAndFocus = () => {
      focusService.focusOn(that.focusKey, true);
    };

    that.$onChanges = (changes) => {
      if (changes.initialText && _.isFunction(that.onChange)) {
        that.text = that.initialText;
      }
    };
  }
});
