'use strict';

const { bankAccountTypeTranslationKeyByType } = require('../entities/constants');

const localiseBankAccountTypeDisplay = (entity, t) => {
  if (entity && entity.accountType) {
    const localised = t(bankAccountTypeTranslationKeyByType[entity.accountType.toUpperCase()]);
    if (localised) {
      return localised;
    }
    // A fallback just in case, should never reach
    return entity.accountTypeDisplay;
  }
  return 'unknown';
};

module.exports = {
  localiseBankAccountTypeDisplay,
};
