
'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/manage-subscription';

  $stateProvider
    .state('home.billing.manage-subscription', {
      url: '/manage-subscription',
      templateUrl: BASE_DIRECTORY + '/manage-subscription.html',
      controllerAs: '$ctrl',
      controller: function($state, sbLinkService) {
        const ctrl = this;
        ctrl.onClickLink = sbLinkService.onClickLink;
      }
    });
});
