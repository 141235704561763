import { defaultPath } from './default-path';

export const TOGGLE_ITEMS = `${defaultPath}/TOGGLE_ITEMS`;
export const TOGGLE_EXPAND = `${defaultPath}/TOGGLE_EXPAND`;
export const SET_SHOW_PAYMENTS = `${defaultPath}/SET_SHOW_PAYMENTS`;
export const SET_SHOW_RECEIPTS = `${defaultPath}/SET_SHOW_RECEIPTS`;
export const SET_SHOW_DELETED = `${defaultPath}/SET_SHOW_DELETED`;
export const SET_SEARCH_FILTER = `${defaultPath}/SET_SEARCH_FILTER`;
export const TOGGLE_SHOW_URECONCILED = `${defaultPath}/TOGGLE_SHOW_URECONCILED`;
export const CLEAR_STATE = `${defaultPath}/CLEAR_STATE`;

// bank recon summary panel
export const SET_BANK_STATEMENT_BALANCE = `${defaultPath}/SET_BANK_STATEMENT_BALANCE`;
export const TOGGLE_SHOW_SUMMARY = `${defaultPath}/TOGGLE_SHOW_SUMMARY`;

// adjustments
export const ADD_ADJUSTMENT = `${defaultPath}/ADD_ADJUSTMENT`;
export const SET_ADJUSTMENTS = `${defaultPath}/SET_ADJUSTMENTS`;
export const TOGGLE_REMOVE_ADJUSTMENT = `${defaultPath}/TOGGLE_REMOVE_ADJUSTMENT`;
export const TOGGLE_RECONCILE_ADJUSTMENT = `${defaultPath}/TOGGLE_RECONCILE_ADJUSTMENT`;
