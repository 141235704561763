import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forms2PropTypes, Button, SlidingToggle, FormLabel, useTranslation } from '@sb-itops/react';
import { schemeMappings, testingGetAllValuesForKey } from '@sb-itops/region-schemes';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { phonePlaceholders } from '@sb-customer-management/business-logic/contacts/entities/constantsTypescript';
import { hasPhoneAreaCode } from '@sb-customer-management/business-logic/contacts/services/has-phone-number-prefix';
import { AddressForm, PostalAddressFormAu, PostalAddressFormGb } from '@sb-customer-management/react';
import Styles from './FirmDetails.module.scss';
import { hasToggleForStreetAddressIsMailingAddress } from './FirmDetailsStrategy';

const FirmDetails = React.memo(
  ({
    onFieldValueUpdated,
    phoneNumberScheme,
    addressScheme,
    name,
    abn,
    acn,
    validateForm,
    formDisabled,
    submitFailed,
    phoneAreaCode,
    phoneNumber,
    faxAreaCode,
    faxNumber,
    formInitialised,
    onSave,
    mailingAddress,
    businessAddress,
    streetIsMailingAddress,
    onAddressAutocompleteSelected,
  }) => {
    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
    const { t } = useTranslation();

    if (!formInitialised) {
      return null;
    }
    return (
      <div className={Styles.firmDetails}>
        <fieldset disabled={formDisabled}>
          <h3 className={Styles.title}>Firm Details</h3>
          {/* 
          AWAITING UI
          <input
            type="file"
            name="logo"
            className="logo-upload form-control"
            onChange={(e) => {
              const files = e.target.files;
              if (!files || !files.length) {
                return;
              }

              fetchPostP({
                path: `/firm-management/upload-logo/firm/:accountId/`,
                fetchOptions: {
                  file: files[0],
                },
              });
            }}
          />
          {firmLogo && <img src={firmLogo} alt="logo" />} */}
          <div className="row">
            <div className="col-xs-12">
              <FormLabel label="Firm Name" field={name} submitFailed={submitFailed} uppercase={false} />
              <input
                name="name"
                type="text"
                className={classnames('form-control', isErrorClassnames(name))}
                value={name?.value || ''}
                onChange={(e) => {
                  onFieldValueUpdated({ name: e.target.value });
                }}
                onBlur={validateForm}
              />
            </div>
          </div>
          {hasFacet(facets.businessNumber) && (
            <div className="row">
              <div className="col-xs-6">
                <FormLabel label={t('acn')} field={acn} submitFailed={submitFailed} uppercase={false} optional />
                <input
                  name="acn"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(acn))}
                  value={acn?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ acn: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-6">
                <FormLabel label={t('abn')} field={abn} submitFailed={submitFailed} uppercase={false} optional />
                <input
                  name="abn"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(abn))}
                  value={abn?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ abn: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
          )}
          <div className="row">
            {hasPhoneAreaCode(phoneNumberScheme) && (
              <div className="col-xs-3">
                <label>Area Code</label>
                <input
                  name="phoneAreaCode"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(phoneAreaCode))}
                  value={phoneAreaCode?.value || ''}
                  placeholder={phonePlaceholders.phoneAreaCode[phoneNumberScheme]}
                  onChange={(e) => {
                    onFieldValueUpdated({ phoneAreaCode: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            )}
            <div className="col-xs-6">
              <label>Phone Number</label>
              <input
                name="phoneNumber"
                type="text"
                pattern="[0-9]+"
                className={classnames('form-control', isErrorClassnames(phoneNumber))}
                value={phoneNumber?.value || ''}
                placeholder={phonePlaceholders.phoneAreaCode[phoneNumberScheme]}
                onChange={(e) => {
                  onFieldValueUpdated({ phoneNumber: e.target.value });
                }}
                onBlur={validateForm}
              />
            </div>
          </div>
          <div className="row">
            {hasPhoneAreaCode(phoneNumberScheme) && (
              <div className="col-xs-3">
                <label>Area Code</label>
                <input
                  name="faxAreaCode"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(faxAreaCode))}
                  value={faxAreaCode?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ faxAreaCode: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            )}
            <div className="col-xs-6">
              <label>Fax Number</label>
              <input
                name="faxNumber"
                type="text"
                pattern="[0-9]+"
                className={classnames('form-control', isErrorClassnames(faxNumber))}
                value={faxNumber?.value || ''}
                onChange={(e) => {
                  onFieldValueUpdated({ faxNumber: e.target.value });
                }}
                onBlur={validateForm}
              />
            </div>
          </div>
          <hr className={Styles.hr} />
          <h3>Street Address</h3>
          <AddressForm
            addressScheme={addressScheme}
            formInitialised={formInitialised}
            forceShowState
            {...businessAddress}
            validateForm={validateForm}
            formDisabled={formDisabled}
            onFieldValueUpdated={(payload) => onFieldValueUpdated({ businessAddress: payload })}
            submitFailed={submitFailed}
            optionalExceptState
            onAddressAutocompleteSelected={(addressInfo) =>
              onAddressAutocompleteSelected('businessAddress', addressInfo)
            }
          />
          <h3>{t('mailingAddress')}</h3>
          {hasToggleForStreetAddressIsMailingAddress(addressScheme) && (
            <div className="row">
              <div className="col-xs-12">
                <SlidingToggle
                  scope="street-is-mailing-address"
                  onChange={(label, value) => onFieldValueUpdated({ streetIsMailingAddress: !value })}
                  selected={!streetIsMailingAddress?.value}
                />
                <div
                  onChange={() => onFieldValueUpdated({ streetIsMailingAddress: !streetIsMailingAddress?.value })}
                  className={Styles.toggleLabel}
                >
                  Different to street address
                </div>
              </div>
            </div>
          )}
          <hr className={Styles.hr} />
          {addressScheme === schemeMappings.addressScheme.AU && (
            <PostalAddressFormAu
              formInitialised={formInitialised}
              {...mailingAddress}
              validateForm={validateForm}
              formDisabled={formDisabled}
              onFieldValueUpdated={(payload) => onFieldValueUpdated({ mailingAddress: payload })}
              submitFailed={submitFailed}
              optional
            />
          )}
          {addressScheme === schemeMappings.addressScheme.GB && (
            <PostalAddressFormGb
              formInitialised={formInitialised}
              {...mailingAddress}
              validateForm={validateForm}
              formDisabled={formDisabled}
              onFieldValueUpdated={(payload) => onFieldValueUpdated({ mailingAddress: payload })}
              submitFailed={submitFailed}
              optional
            />
          )}
          {addressScheme === schemeMappings.addressScheme.US && !streetIsMailingAddress.value && (
            <AddressForm
              formInitialised={formInitialised}
              addressScheme={addressScheme}
              {...mailingAddress}
              validateForm={validateForm}
              formDisabled={formDisabled}
              onFieldValueUpdated={(payload) => onFieldValueUpdated({ mailingAddress: payload })}
              submitFailed={submitFailed}
              optional
              onAddressAutocompleteSelected={(addressInfo) =>
                onAddressAutocompleteSelected('mailingAddress', addressInfo)
              }
            />
          )}
        </fieldset>
        <div className={Styles.formFooter}>
          <Button
            locked={formDisabled}
            disabled={formDisabled}
            className={Styles.saveButton}
            size="full-width"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    );
  },
);

FirmDetails.displayName = 'FirmDetails';

const { Forms2Field } = forms2PropTypes;

FirmDetails.propTypes = {
  phoneNumberScheme: PropTypes.oneOf(testingGetAllValuesForKey('phoneNumberScheme')).isRequired,
  addressScheme: PropTypes.oneOf(testingGetAllValuesForKey('addressScheme')).isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  name: Forms2Field,
  abn: Forms2Field,
  acn: Forms2Field,
  onSave: PropTypes.func.isRequired,
  onAddressAutocompleteSelected: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  streetIsMailingAddress: Forms2Field,
  phoneAreaCode: Forms2Field,
  phoneNumber: Forms2Field,
  faxAreaCode: Forms2Field,
  faxNumber: Forms2Field,
  formInitialised: PropTypes.bool.isRequired,
  mailingAddress: PropTypes.object,
  firmLogo: PropTypes.string,
  businessAddress: PropTypes.object,
};

FirmDetails.defaultProps = {
  abn: undefined,
  acn: undefined,
  firmLogo: undefined,
  name: undefined,
  phoneAreaCode: undefined,
  phoneNumber: undefined,
  streetIsMailingAddress: undefined,
  faxAreaCode: undefined,
  faxNumber: undefined,
  mailingAddress: undefined,
  businessAddress: undefined,
};

export default FirmDetails;
