import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { TrustDepositRequestForm } from '../trust-deposit-request-form';
import Styles from './TrustDepositRequestModal.module.scss';

export const TrustDepositRequestModal = React.memo(
  ({
    onSubmit,
    onClose,
    isSubmitDisabled,
    isSubmitLocked,
    isVisible,
    scope,
    bankAccountId,
    debtorId,
    matterId,
    title,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.trustDepositRequestModal}
        isVisible={isVisible}
        title={title}
        body={
          <TrustDepositRequestForm
            scope={scope}
            bankAccountId={bankAccountId}
            debtorId={debtorId}
            matterId={matterId}
          />
        }
        footer={
          <div className="row">
            <div className="col-lg-3">
              <Button
                onClick={onSubmit}
                disabled={isSubmitDisabled || isSubmitLocked}
                form={scope}
                locked={isSubmitLocked}
                size="full-width"
              >
                Send Now
              </Button>
            </div>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

TrustDepositRequestModal.displayName = 'TrustDepositRequestModal';

TrustDepositRequestModal.propTypes = {
  scope: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  debtorId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isSubmitLocked: PropTypes.bool,
};

TrustDepositRequestModal.defaultProps = {
  isSubmitDisabled: false,
  isSubmitLocked: false,
};
