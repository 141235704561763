'use strict';

const PrintManually = 'PrintManually';
const PrintNow = 'PrintNow';
const PrintLater = 'PrintLater';
const PrintNotApplicable = 'PrintNotApplicable';

const printMethods = [
  { display: 'Print Manually', code: 0, value: PrintManually },
  { display: 'Print Now', code: 1, value: PrintNow },
  { display: 'Print Later', code: 2, value: PrintLater },
];

const notApplicablePrintMethodLocalised = (t) => ({
  display: t('notApplicablePrintMethod'),
  code: 3,
  value: PrintNotApplicable,
});

const printMethodsByCode = printMethods.reduce((methodsByCode, method) => {
  // eslint-disable-next-line no-param-reassign
  methodsByCode[method.code] = method;
  return methodsByCode;
}, {});

const printMethodsByValue = printMethods.reduce((methodsByValue, method) => {
  // eslint-disable-next-line no-param-reassign
  methodsByValue[method.value] = method;
  return methodsByValue;
}, {});

module.exports = {
  PrintManually,
  PrintNow,
  PrintLater,
  PrintNotApplicable,
  printMethods,
  notApplicablePrintMethodLocalised,
  printMethodsByCode,
  printMethodsByValue,
};
