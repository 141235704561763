'use strict';

const { paymentMethods } = require('./payment-methods');
const { getAccountConfiguration } = require('./get-account-configuration');

/**
 * isPaymentProviderEnabledForBankAccount
 * Returns true if lawpay payments are enabled for the passed Smokeball Billing bank account ID.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings Lawpay settings object
 * @param {string} params.bankAccountId The bank account ID to check for lawpay payment availability.
 * @param {string} params.bankAccountType The bank account type to check for lawpay payment availability.
 * @param {object} params.providerSpecificFields Provider specific fields
 * @param {('CREDIT_CARD'|'ECHEQUE')} [params.providerSpecificFields.paymentMethod] Lawpay payment method
 *
 * @returns {boolean}
 */
function isPaymentProviderEnabledForBankAccount({
  formattedProviderSpecificSettings,
  bankAccountId,
  bankAccountType,
  providerSpecificFields = {},
}) {
  const { paymentMethod } = providerSpecificFields;

  if (!!paymentMethod && !Object.values(paymentMethods).includes(paymentMethod)) {
    throw new Error('invalid paymentMethod provided');
  }

  // Incomplete/invalid configuration if no public key is present.
  if (!formattedProviderSpecificSettings.publicKey) {
    return false;
  }

  // new MTA structure
  if (formattedProviderSpecificSettings.cardBankAccountMappings) {
    let allBankAccountIds;
    if (paymentMethod === paymentMethods.CREDIT_CARD) {
      allBankAccountIds = formattedProviderSpecificSettings.cardBankAccountMappings;
    } else if (paymentMethod === paymentMethods.ECHEQUE) {
      allBankAccountIds = formattedProviderSpecificSettings.eCheckBankAccountMappings;
    } else {
      allBankAccountIds = [
        ...formattedProviderSpecificSettings.cardBankAccountMappings,
        ...formattedProviderSpecificSettings.eCheckBankAccountMappings,
      ];
    }
    const mappedIds = allBankAccountIds.map((m) => m.sbbBankAccountId);

    const isAccountEnabled = mappedIds.includes(bankAccountId);

    return isAccountEnabled;
  }

  // pre-MTA
  if (!(bankAccountId.endsWith('Trust') || bankAccountId.endsWith('Operating'))) {
    return false;
  }

  let paymentMethodsToCheck = paymentMethods;
  if (paymentMethod) {
    paymentMethodsToCheck = [paymentMethod];
  }

  const isAccountEnabled = Object.keys(paymentMethodsToCheck).some((pm) => {
    const accountConfiguration = getAccountConfiguration({
      formattedProviderSpecificSettings,
      bankAccountType,
      paymentMethod: pm,
    });

    return !!accountConfiguration && accountConfiguration.name !== 'None';
  });

  return isAccountEnabled;
}

module.exports = {
  isPaymentProviderEnabledForBankAccount,
};
