import React, { useState, useEffect } from 'react';
import { DateRangePicker, SlidingToggle, useTranslation } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
import { MatterTypeahead } from 'web/react-redux/components/matter-typeahead';
import Styles from '../ReportFilter.module.scss';

const AccountStatementFilter = ({
  onSelectPeriod,
  onEndSelect,
  onStartSelect,
  onToggleOption,
  onShowInvoicesFor,
  onMatterSelected,
  onContactSelected,
  filters,
  periodOptions,
  showFilterByEnteredDateToggle,
  showDeletedTransactionsToggle,
  contacts,
}) => {
  const {
    isMatterContactBalanceType,
    periodSelected,
    startDate,
    endDate,
    includeFirmHeader,
    includeFirmLogo,
    includeFirmName,
    showDeletedTransactions,
    showTimeKeeperInitials,
    showInvoiceHistory,
    showInvoiceSummary,
    showAccountSummary,
    showTransactionHistory,
    showInvoiceNumbersForTransactions,
    showFeesExpenses,
    showLedger,
    showTrustAccountName,
    showInvoicesFor,
    matter,
    contact,
    runByEnteredDate,
    errors,
  } = filters || {};
  const { t } = useTranslation();
  const [matterFocus, setMatterFocus] = useState(false);

  useEffect(() => {
    setMatterFocus(showInvoicesFor === 'matter');
  }, [showInvoicesFor]);

  if (!filters) {
    return null;
  }

  const contactOptions = contacts.map(({ display: label, id: value }) => ({ value, label }));

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.period}>
        <label>Report period</label>
        <DateRangePicker
          onSelect={onSelectPeriod}
          onEndSelect={onEndSelect}
          onStartSelect={onStartSelect}
          options={periodOptions}
          selected={periodSelected}
          endDate={endDate}
          endHasError={errors.endDate}
          startDate={startDate}
          startHasError={errors.startDate}
        />
      </div>
      <div className={Styles.separator} />
      {showFilterByEnteredDateToggle && (
        <div className={Styles.runByEnteredDateFilter}>
          <span data-name="runByEnteredDate" onClick={toggleOption} className={Styles.option}>
            Filter by Entered Date
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="runByEnteredDate" name="runByEnteredDate" selected={runByEnteredDate} />
            </div>
          </span>
          <div className={Styles.separator} />
        </div>
      )}
      <div className={Styles.printOptions}>
        <label>Show invoices for</label>
        <div className={Styles.options}>
          <input
            type="radio"
            onChange={() => onShowInvoicesFor('matter')}
            checked={showInvoicesFor === 'matter'}
            className={Styles.radioOption}
          />
          <MatterTypeahead
            disabled={showInvoicesFor !== 'matter'}
            onValueChange={onMatterSelected}
            className={Styles.typeahead}
            hasError={errors.matter}
            onClear={onMatterSelected}
            initialId={matter ? matter.id : undefined}
            focus={matterFocus}
            filter="all"
          />
          {isMatterContactBalanceType && (
            <>
              <input
                type="radio"
                onChange={() => onShowInvoicesFor('contact')}
                checked={showInvoicesFor === 'contact'}
                className={Styles.radioOption}
              />
              <StatelessSelect
                disabled={showInvoicesFor !== 'contact'}
                options={contactOptions}
                onChange={onContactSelected}
                placeholder="Select a contact ..."
                isClearable
                checked={showInvoicesFor === 'contact'}
                className={Styles.typeahead}
                clearValue={onContactSelected}
                hasError={errors.contact}
                selectedOption={contact ? contactOptions.find((c) => c.value === contact.id) : undefined}
              />
            </>
          )}
          <input
            type="radio"
            onChange={() => onShowInvoicesFor('allMatters')}
            checked={showInvoicesFor === 'allMatters'}
            className={Styles.radioOption}
          />
          <span className={Styles.option} onClick={() => onShowInvoicesFor('allMatters')}>
            All matters
          </span>
        </div>
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.reportOptions}>
        <label>View options</label>
        <div className={Styles.options}>
          <span data-name="showInvoiceHistory" onClick={toggleOption} className={Styles.option}>
            Show invoice history
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showInvoiceHistory" name="showInvoiceHistory" selected={showInvoiceHistory} />
            </div>
          </span>
          <span data-name="showInvoiceSummary" onClick={toggleOption} className={Styles.option}>
            Show invoice summary
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showInvoiceSummary" name="showInvoiceSummary" selected={showInvoiceSummary} />
            </div>
          </span>
          <span data-name="showAccountSummary" onClick={toggleOption} className={Styles.option}>
            Show account summary
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showAccountSummary" name="showAccountSummary" selected={showAccountSummary} />
            </div>
          </span>
          <span data-name="showTransactionHistory" onClick={toggleOption} className={Styles.option}>
            Show transaction history
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="showTransactionHistory"
                name="showTransactionHistory"
                selected={showTransactionHistory}
              />
            </div>
          </span>
          <span
            data-name="showInvoiceNumbersForTransactions"
            onClick={(e) => {
              if (showTransactionHistory) {
                toggleOption(e);
              }
            }}
            className={Styles.option}
          >
            Show invoice numbers for transactions
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="showInvoiceNumbersForTransactions"
                name="showInvoiceNumbersForTransactions"
                selected={showInvoiceNumbersForTransactions}
                disabled={!showTransactionHistory}
              />
            </div>
          </span>
          <span data-name="showFeesExpenses" onClick={toggleOption} className={Styles.option}>
            {`Show fees and ${t('expenses')}`}
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showFeesExpenses" name="showFeesExpenses" selected={showFeesExpenses} />
            </div>
          </span>
          <span
            data-name="showTimeKeeperInitials"
            onClick={(e) => {
              if (showFeesExpenses) {
                toggleOption(e);
              }
            }}
            className={Styles.option}
          >
            Show time keeper initials
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="showTimeKeeperInitials"
                name="showTimeKeeperInitials"
                selected={showTimeKeeperInitials}
                disabled={!showFeesExpenses}
              />
            </div>
          </span>
          <span data-name="showLedger" onClick={toggleOption} className={Styles.option}>
            Show ledger
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showLedger" name="showLedger" selected={showLedger} />
            </div>
          </span>
          <span data-name="showTrustAccountName" onClick={toggleOption} className={Styles.option}>
            {`Show ${t('trustAccount').toLowerCase()} name`}
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showTrustAccountName" name="showTrustAccountName" selected={showTrustAccountName} />
            </div>
          </span>
          {showDeletedTransactionsToggle && (
            <span data-name="showDeletedTransactions" onClick={toggleOption} className={Styles.option}>
              Show deleted transactions
              <div className={Styles.toggleContainer}>
                <SlidingToggle
                  scope="showDeletedTransactions"
                  name="showDeletedTransactions"
                  selected={showDeletedTransactions}
                />
              </div>
            </span>
          )}
        </div>
      </div>
      <div className={Styles.separator} />
    </div>
  );
};

AccountStatementFilter.displayName = 'AccountStatementFilter';

export default AccountStatementFilter;
