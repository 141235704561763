import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { Icon } from '@sb-itops/react';
import Styles from './ProtectedFundsLineItem.module.scss';

export const ProtectedFundsLineItem = ({ amount, tooltip, onViewProtectedFunds, indentLeft, isLink, disabled }) => (
  <div className={Styles.protectedFundsLineItem}>
    <div className={Styles.lineItem}>
      <LinkableText
        className={classnames(Styles.linkText, indentLeft ? Styles.checkboxMarginLeft : '')}
        text="View Protected Funds"
        tooltip={tooltip}
        asLink
        disabled={!isLink}
        onClickLink={onViewProtectedFunds}
      />
      <div className={classnames(Styles.labelDisabled, Styles.protectedFundsAmount)} title={tooltip}>
        {!disabled && (
          <Icon
            className={Styles.infoIcon}
            type="question-mark"
            tooltip="Protected funds must be released before they can be applied to an invoice"
          />
        )}
        {amount}
      </div>
    </div>
  </div>
);

ProtectedFundsLineItem.displayName = 'FinalisePanel';
ProtectedFundsLineItem.propTypes = {
  amount: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onViewProtectedFunds: PropTypes.func.isRequired,
  isLink: PropTypes.bool.isRequired,
  indentLeft: PropTypes.bool,
  disabled: PropTypes.bool,
};

ProtectedFundsLineItem.defaultProps = {
  tooltip: undefined,
  indentLeft: false,
  disabled: false,
};
