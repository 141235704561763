import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'recentInvoices',
  keyPath: 'userId',
  ngCacheName: 'sbRecentInvoiceService',
  syncType: syncTypes.SINCE,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getRecentInvoices = () => getList()?.[0]?.invoiceIds || [];

export const getRecentInvoicesMap = () =>
  getRecentInvoices().reduce((acc, invoiceId) => {
    acc[invoiceId] = true;
    return acc;
  }, {});

export * from './save-recent-invoice';
