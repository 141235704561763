'use strict';

const i18next = require('i18next');
const { initReactI18next } = require('react-i18next');
const Backend = require('i18next-chained-backend');
const { getLanguageDetector, addFormatters, interpolationOptions } = require('@sb-itops/localisation-resources');
const httpBackend = require('./http-backend');
const moduleBackend = require('./module-backend');

let _t;

const t = (keys, options) => {
  if (!_t) {
    throw new Error('Localisation not initialised');
  }
  return _t(keys, options);
};

/**
 * @param {object} params
 * @param {function} params.fetch function that takes one paranmeter Object<{ language: string }>
 * @param {boolean} [debug]
 * @returns {Promise<function>} wrapper for i8next.t
 */
const initWebP = async ({ fetch, debug = false } = {}) => {
  // only initialise once, after that use one of the instance functions
  if (_t) {
    return t;
  }
  if (!fetch) {
    throw new Error('fetch required to load resources');
  }
  if (typeof fetch !== 'function') {
    throw new Error('fetch must be a function');
  }

  _t = await i18next
    .use(getLanguageDetector())
    .use(Backend.default)
    .use(initReactI18next)
    .init({
      fallbackLng: false,
      load: "currentOnly",
      debug,
      backend: {
        backends: [httpBackend, moduleBackend],
        backendOptions: [
          { fetch }, // options for httpBackend
        ],
      },
      interpolation: interpolationOptions,
    });

  addFormatters(i18next);

  return t;
};

module.exports = {
  initWebP,
  t,
};
