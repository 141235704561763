angular.module('sb.billing.webapp').controller('SbReduxListController', function ($scope, $timeout, sbUnsavedChangesService) {
  'use strict';

  const that = this;
  that.sortAndReload = sortAndReload;

  /**
   * Sets up default values and initialises the data source for the list.
   * Also subscribes for changes on the underlying data source.
   */
  that.$onInit = function() {
    // Apply default values.
    that.type = ($scope.config.type === 'static') ? 'static': 'infinite'; // Default to infinite.
    that.currentSortColumn = {};

    that.sortedData = [];
    that.datasource = createDataSource(that.type);

    // Load any saved state.
    // Default state is used when none is available from the sbUnsavedChangesService.
    const defaultState = {
      scrollPosition: 1,
      sortColumn: {}
    };

    that.stateMemory = _.merge(defaultState, sbUnsavedChangesService.loadMemory($scope.config.stateKey) || {});

    // Listen for changes to the underlying data.
    $scope.$watch('data', reloadList);

    // Apply the initial sort column and perform the first list 'reload'.
    initialSort();
    reloadList();
  };

  /**
   * Used to save the state for the next load of this list.
   */
  that.$onDestroy = function() {
    // Only infinite scroll supports scroll position memory...for now.
    if (that.type !== 'infinite') {
      return;
    }

    const stateToSave = {
      scrollPosition: _.get(that, 'clUiScrollAdapter.topVisibleScope.$index', 1),
      sortColumn: that.currentSortColumn
    };

    sbUnsavedChangesService.saveMemory($scope.config.stateKey, stateToSave);
  };

  function sortAndReload(column) {
    if (applySort(column)) {
      reloadList();
    }
  }

  /**
   * For the initial sort, we first need to establish whether we have either
   *  - A saved sort column from a previous invocation of this list
   *  - The default column specified via the config.
   */
  function initialSort() {
    if (!_.isEmpty(that.stateMemory.sortColumn)) {
      const sortColumn =  _.find($scope.config.columns, {'label': that.stateMemory.sortColumn.label});
      applySort(_.defaults(sortColumn, that.stateMemory.sortColumn), true);
    }
    else {
      const defaultSortColumn = _.find($scope.config.columns, {'defaultSort': true});
      if (!_.isEmpty(defaultSortColumn)) {
        applySort(defaultSortColumn);
      }
    }

    // Do we still need to do this with redux?
    //$timeout($scope.provider.startRefreshingData.bind($scope.provider));
  }

  function createDataSource(sourceType) {
    switch (sourceType) {
      case 'infinite':
        return {
          get: function (index, count, success) {
            if (!_.isEmpty(that.sortedData)) {
              index--; //index is not ZERO based so make it so, said Capt. Picard.
              const start = index < 0 ? 0 : index;
              const end = Math.min(index + count, that.sortedData.length);
              const ds = _.slice(that.sortedData, start, end);
              success(ds);
            } else {
              success([]);
            }
          }
        };

      case 'static':
        return that.sortedData;

      default:
        throw new Error('Failed to get data for unexpected type \'' + sourceType + '\'');
    }
  }

  /**
   * Triggered when the underlying data provider's data has changed.
   */
  function reloadList() {
    that.sortedData = that.sortFn($scope.data);

    switch (that.type) {
      case 'infinite':
        // Our current version of ui scroll has an issue where the adapter may not have been bound even after postLink.
        // We will update versions soon and hopefully the issue will go away.
        if (that.clUiScrollAdapter) {
          that.clUiScrollAdapter.reload(that.stateMemory.scrollPosition);
          that.stateMemory.scrollPosition = 1;
        }
        break;

      case 'static':
        // For a static list, we need to recreate the data source so that it operates
        that.datasource = createDataSource(that.type);
        break;
    }
  }

  /**
   * Applies a sort function over underlying data.
   * @param columnDef The column which is the source of the sort request (i.e. click occurred on column header).
   * @param isSavedSort Flag to indicate whether we are applying a sort as a result of a user action or a page reload.
   * @returns True if a new sort has taken hold, false otherwise.
   */
  function applySort(columnDef, isSavedSort) {
    let sortApplied = false;

    // Don't do anything if sorting is not enabled on the passed column.
    if (!columnDef.sort) {
      return sortApplied;
    }

    // Toggle the sort order if required.
    if (!isSavedSort) {
      if (!columnDef.toggleSortDisabled && !_.isEmpty(columnDef.sortOrder)) {
        columnDef.sortOrder = (columnDef.sortOrder === 'asc') ? 'desc' : 'asc';
      }
      else {
        columnDef.sortOrder = columnDef.defaultSortOrder || 'asc';
      }
    }

    // If the sort applied to the column is a function, just set it directly at the sort function.
    if (_.isFunction(columnDef.sort)) {
      that.sortFn = (data) => {
        return columnDef.sort(data, columnDef.sortOrder);
      };

      sortApplied = true;
    }

    // Otherwise, check if sort is a string representing a property and use the default sorter.
    else if (_.isString(columnDef.sort)) {
      // Default sorting function simply assumes that sort contains a string representing a property in the data items.
      that.sortFn = (dataSet) => {
        const iteratee = (dataItem) => {
          const searchValue = _.get(dataItem, columnDef.sort);
          return _.isString(searchValue) ? searchValue.toLowerCase() : searchValue;
        };

        return _.sortByOrder(dataSet, iteratee, [columnDef.sortOrder || 'asc']);
      };

      sortApplied = true;
    }

    if (!isSavedSort) {
      // Nuke the sort style applied to the last sorted column, add a new sort style to the new sort column.
      that.currentSortColumn.sortStyle = '';
      that.currentSortColumn = columnDef;
      that.currentSortColumn.sortStyle = (columnDef.sortOrder !== '') ? 'sorted-' + columnDef.sortOrder : '';
    }
    else {
      that.currentSortColumn = columnDef;
    }

    return sortApplied;
  }
});
