import uuid from '@sb-itops/uuid';
import { getApolloClient } from 'web/services/apollo';

/**
 * Handling standard notification subscriptions. When a notification arrives
 * we check if there are any related subscriptions registered, and evict the
 * subscribed query cache.
 *
 * Because the query is no longer in the cache, the next time the user visits
 * a page that is using the query, it will be re-fetched. If the user happens
 * to be on the page when the query is evicted, the query will be re-fetched
 * instantly.
 *
 * Subscriptions structure:
 *
 * Map {
 *   'SampleNotification' => Map {
 *     'subscriptionGuid1' => [ 'contactList' ],
 *     'subscriptionGuid2' => [ 'contactList', 'matterList' ],
 *   },
 *   'AnotherNotification' => Map {
 *     'subscriptionGuid1' => [ 'contactList' ]
 *   },
 * }
 */
const subscriptions = new Map();

/**
 * Register Notification Subscriptions
 *
 * @param {object} params
 * @param {string[]} params.notificationIds
 * @param {string[]} params.rootFieldsToEvict Queries may have multiple root fields
 * @returns function that clears the newly added notification subscriptions
 */
const subscribeToNotifications = ({ notificationIds, rootFieldsToEvict }) => {
  const subscriptionId = uuid();

  notificationIds.forEach((notificationId) => {
    const currentSubsForNotificationId = subscriptions.get(notificationId);

    if (!currentSubsForNotificationId) {
      subscriptions.set(notificationId, new Map([[subscriptionId, rootFieldsToEvict]]));
    } else {
      currentSubsForNotificationId.set(subscriptionId, rootFieldsToEvict);
    }
  });

  return () => {
    notificationIds.forEach((notificationId) => {
      const currentSubsForNotificationId = subscriptions.get(notificationId);

      if (currentSubsForNotificationId) {
        currentSubsForNotificationId.delete(subscriptionId);
      }

      // Clean up notificationId map
      if (currentSubsForNotificationId.size === 0) {
        subscriptions.delete(notificationId);
      }
    });
  };
};

/**
 * @param {Object} params
 * @param {String[]} params.rootQueryFields The keys of ROOT_QUERY from Apollo cache data
 * @param {String} params.fieldName The name of the field to check
 * @returns
 */
const isFieldInCache = ({ rootQueryFields, fieldName }) => {
  return rootQueryFields.some(rootQueryField => rootQueryField === fieldName || rootQueryField.startsWith(`${fieldName}(`) || rootQueryField.startsWith(`${fieldName}:`));
}

/**
 * Process Notification
 *
 * @param {Object} params
 * @param {string} params.provider
 * @param {string} [params.action]
 * @returns
 */
const processNotification = ({ provider, action }) => {
  const notificationId = action ? `${provider}.${action}` : provider;
  const notificationSubscriptions = subscriptions.get(notificationId);
  if (!notificationSubscriptions || !notificationSubscriptions.size) {
    return;
  }

  // Instead of refetching the queries, we can evict with `broadcast = true`
  // and let the Apollo client refetch the active queries that were evicted
  // as and when required
  const apolloClient = getApolloClient();

  const rootQueryFields = Object.keys(apolloClient.cache.data.data.ROOT_QUERY);

  notificationSubscriptions.forEach((fieldList) => {
    if (!fieldList) {
      return;
    }

    fieldList.forEach((fieldName) => {

      // The ROOT_QUERY in cache could either be the name of the Query field
      // (ie, `activityCodes`) or if it has variables, it could be
      // `invoice({"id":"some-guid"})`.
      // cache.evict will evict either/both, however in order to determine if
      // we should add them to the retry queue we need to check if either
      // variant exists.
      if (isFieldInCache({ rootQueryFields, fieldName })) {

        // In the case where a notification is received while we are in
        // retryQueue delay, and the field exists in ROOT_QUERY (ie has already
        // been fetched), as we're performing the eviction we can safely delete
        // the entry from the retryQueue so that we are not unnecessarily
        // refetching again.
        retryQueue.delete(fieldName);

        // This will evict any root-level fields matching the name, regardless
        // of which query variables were used
        apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName, broadcast: true });
      } else {
        // If the fieldName is already in the retry queue, ignore it in order
        // to prevent duplicate requests
        if (!retryQueue.has(fieldName)) {
          retryQueue.set(fieldName, 0);

          // Avoid triggering the retryQueue if it's already running
          if (retryQueue.size === 1) {
            processRetryQueue({ apolloClient });
          }
        }
      }
    });
  });

  apolloClient.cache.gc();
};

const retryQueue = new Map();
const RETRY_DELAY_MS = 500;
const MAX_RETRIES = 20;
// Max wait time before allowing stale data to persist is 20 x 500ms = 10 seconds

/**
 * Process the retry queue with delays
 *
 * @param {Object} params
 * @param {Object} params.apolloClient
 * @returns
 */
const processRetryQueue = async ({ apolloClient }) => {
  if (retryQueue.size === 0) {
    return;
  }

  const rootQueryFields = Object.keys(apolloClient.cache.data.data.ROOT_QUERY);

  retryQueue.forEach((count, fieldName) => {
    if (isFieldInCache({ rootQueryFields, fieldName }) || count >= MAX_RETRIES) {
      retryQueue.delete(fieldName);
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName, broadcast: true });
    } else {
      retryQueue.set(fieldName, count + 1);
    }
  });

  if (retryQueue.size > 0) {
    await new Promise((resolve) => {
      setTimeout(resolve, RETRY_DELAY_MS);
    });

    processRetryQueue({ apolloClient });
  }
}

export { subscribeToNotifications, processNotification };
