import { SET_REPORT_STATE, TOGGLE_SHOW_FILTERS, RESET_ALL_REPORT_STATE } from './types';

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
});

export const setReportState = ({ reportId, changedState }) => ({
  type: SET_REPORT_STATE,
  payload: {
    reportId,
    changedState,
  },
});

export const resetReportState = () => ({
  type: RESET_ALL_REPORT_STATE,
});
