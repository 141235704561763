'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const billingBulkActionTypes = Object.freeze({
  UNKNOWN: 0,
  BULK_CREATE_INVOICES: 1,
});

const billingBulkActionTypeLabels = Object.freeze({
  UNKNOWN: 'Unknown',
  BULK_CREATE_INVOICES: 'Bulk Create Invoices',
});

const billingBulkActionTypeOptions = getTypeAsOptions({
  typeLabels: billingBulkActionTypeLabels,
  typeValues: billingBulkActionTypes,
});

module.exports = {
  billingBulkActionTypes,
  billingBulkActionTypeLabels,
  billingBulkActionTypeOptions,
};
