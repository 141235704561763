import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getFirmName, getPhoneNumber, getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { getById as getMatterInvoiceSettings } from '@sb-billing/redux/matter-invoice-settings';
import { getAllSettings as getAllPaymentProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { fetchById as fetchContactById } from '@sb-customer-management/redux/contacts';
import { interpolateInvoiceReminderEmail } from '@sb-billing/business-logic/invoice-reminder';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { isPaymentProviderEnabledForBankAccount } from '@sb-billing/business-logic/payment-provider/services';
import { payButtonEnabledOptionValues } from '@sb-billing/business-logic/matters/invoice-settings';
import { getContactSalutation } from '@sb-customer-management/business-logic/contacts/services';
import { getAccountId } from 'web/services/user-session-management';
import { InvoiceReminderEmailModal } from './InvoiceReminderEmailModal';

const mapStateToProps = (state, { isVisible, reminders, onSend, onClose, t }) => {
  const scope = 'invoice-reminder-send-email-modal';
  const remindersCount = reminders.length;
  const { uniqueDebtorIds, uniqueMatterIds } = reminders.reduce(
    (acc, reminder) => {
      const { debtorId, matterId } = reminder;
      acc.uniqueDebtorIds.add(debtorId);
      acc.uniqueMatterIds.add(matterId);
      return acc;
    },
    { uniqueDebtorIds: new Set(), uniqueMatterIds: new Set() },
  );

  const debtorIds = Array.from(uniqueDebtorIds);
  const matterIds = Array.from(uniqueMatterIds);

  // preview function will be called if there is only one reminder
  const createReminderEmailPreviewP = async ({ invoiceReminderEmailRequest }) => {
    const { matterDisplay } = reminders[0];
    const { subject: rawSubject, message: rawMessage } = invoiceReminderEmailRequest.template;

    // 1) fetch all data needed for interpolation
    const accountId = getAccountId();
    const matterId = reminders[0].matterId;
    const firmName = getFirmName() || '';
    const firmPhoneNumber = getPhoneNumber();
    const staffEmailDetails = getStaffEmailDetails();
    const matterInvoiceSettings = getMatterInvoiceSettings(matterId) || {};
    const allPaymentProviderSettings = getAllPaymentProviderSettings();
    const contact = await fetchContactById(reminders[0].debtorId);
    const contactSalutation = getContactSalutation(contact);
    const matterTotals = getMatterTotalsById(matterId);

    const { activeProvider } = allPaymentProviderSettings;
    const paymentProviderSettings = allPaymentProviderSettings.providers[activeProvider];
    const paymentProviderConnected =
      paymentProviderSettings &&
      activeProvider &&
      isPaymentProviderEnabledForBankAccount({
        formattedProviderSpecificSettings: paymentProviderSettings,
        providerType: activeProvider,
        bankAccountId: `${accountId}/Operating`,
        bankAccountType: 'OPERATING',
      });
    // 2) derive final settings
    const firmLevelPayNowEnabled = paymentProviderSettings && paymentProviderSettings.showInvoiceLink;
    // payButtonEnabledOption attribute may not exist or could be 0 (default .net value)
    // In either of those two cases, this setting should default to true
    const matterLevelPayNowEnabled =
      'payButtonEnabledOption' in matterInvoiceSettings
        ? !(matterInvoiceSettings.payButtonEnabledOption === payButtonEnabledOptionValues.DISABLED)
        : payButtonEnabledOptionValues.ENABLED;
    const hasMultiDebtorInvoice = reminders[0].invoices.some((invoice) => invoice.debtorIds > 1);
    const showPayNowButton =
      matterLevelPayNowEnabled && paymentProviderConnected && !hasMultiDebtorInvoice && firmLevelPayNowEnabled;

    const subject = interpolateInvoiceReminderEmail(
      {
        matterDisplay,
        contactSalutation,
        phoneNumber: firmPhoneNumber,
        firmName,
        totalUnpaidPastDue: reminders[0].totalUnpaid,
        matterTotalsUnpaid: (matterTotals && matterTotals.unpaid) || 0,
        user: staffEmailDetails,
        invoiceCount: reminders[0].invoices.length,
        showPayNowButton,
        isPreviewMode: true,
        t,
      },
      rawSubject,
    );
    const message = interpolateInvoiceReminderEmail(
      {
        matterDisplay,
        contactSalutation,
        phoneNumber: firmPhoneNumber,
        firmName,
        totalUnpaidPastDue: reminders[0].totalUnpaid,
        matterTotalsUnpaid: (matterTotals && matterTotals.unpaid) || 0,
        user: staffEmailDetails,
        invoiceCount: reminders[0].invoices.length,
        showPayNowButton,
        isPreviewMode: true,
        t,
      },
      rawMessage,
    );
    return { subject, message };
  };

  // The following form selectors effectively create a tight coupling with the InvoiceReminderEmailForm
  // containers. It's not ideal, but duplicating this logic into the containers gets weird an messy.
  const { selectors: formSelectors } = withScopedFeature({ scope, state })(forms);

  const isSubmitDisabled = !formSelectors.getIsInitialised() || !formSelectors.getIsValid();

  return {
    scope,
    isVisible,
    isSubmitDisabled,
    debtorIds,
    matterIds,
    remindersCount,
    onSend: () => {
      const invoiceReminderEmailRequest = convertToInvoiceReminderEmailRequest({
        state,
        scope,
      });
      onSend(invoiceReminderEmailRequest);
      onClose();
    },
    onPreview: () => {
      const invoiceReminderEmailRequest = convertToInvoiceReminderEmailRequest({
        state,
        scope,
      });
      return createReminderEmailPreviewP({
        invoiceReminderEmailRequest,
        t,
      });
    },
    onClose,
  };
};

const convertToInvoiceReminderEmailRequest = ({ state, scope }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);

  const formFields = formSelectors.getFieldValues(state);

  let bcc = formFields.bccAddress;
  if (formFields.sendCopyToMe) {
    bcc = formFields.bccAddress ? `${formFields.bccAddress}, ${formFields.staffAddress}` : formFields.staffAddress;
  }

  return {
    // when CC/BCC is readonly in UI (multiple matters and multiple debtors)
    // we need to know as we want to replace CC/BCC with matter defaults
    useMatterCcBcc: formFields.readOnlyCcBcc,
    template: {
      toAddress: formFields.readOnlyToAddress ? '' : formFields.toAddress,
      replyToAddress: formFields.fromAddress,
      bcc,
      cc: formFields.ccAddress,
      subject: formFields.subject,
      message: formFields.message,
    },
  };
};

export const InvoiceReminderEmailModalContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, undefined)(InvoiceReminderEmailModal)),
);

InvoiceReminderEmailModalContainer.displayName = 'InvoiceReminderEmailModalContainer';

InvoiceReminderEmailModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  reminders: PropTypes.arrayOf(PropTypes.object),
  warningMessage: PropTypes.string, // If present, displays a warning message above the send button if present.
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

InvoiceReminderEmailModalContainer.defaultProps = {
  reminders: [],
  warningMessage: undefined,
};

export default InvoiceReminderEmailModalContainer;
