import { gql } from '@apollo/client';

const query = gql`
  query InitTodaysFeeDuration($feeFilter: FeeFilter) {
    todaysFeeDuration(filter: $feeFilter) {
      billableDuration
      totalDuration
    }
  }
`;

const notificationIds = ['WebQueryFeesNotifications.AccountFeesUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitTodaysFeeDuration = {
  query,
  notificationIds,
  // The variables and their values must match all usages of this query
  // via useCacheQuery
  variables: {
    feeFilter: {
      feeAgeDays: {
        min: 0,
        max: 0,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      feeEarnerStaffIds: undefined,
      includeStatus: {
        current: true,
      },
    },
  },
};
