import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import { entryType as activityEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { NoBillingAccessWarning } from '@sb-billing/react';
import { featureActive } from '@sb-itops/feature';
import { Button, MessageBar, PaginationSelector, PreviewPdfModal, useTranslation } from '@sb-itops/react';

import { BulkActionsButton } from 'web/react-redux';
import { ExpenseTable, QuickExpenseEntry } from 'web/components';

import Styles from './MatterExpenseEntries.module.scss';

export const MatterExpenseEntries = ({
  region,
  matterId,
  hasBillingAccess,
  onOpenExpenseModal,

  // Ribbon
  onCreateInvoice,
  bulkActions,
  bulkActionsDisabled,
  exportFileUrl,
  isGeneratingPDFReport,
  onCloseExportModal,
  onGenerateCSVReport,
  onGeneratePDFReport,

  // Quick add
  scopeQuickAdd,
  activities,
  tasks,
  utbmsEnabledForFirm,
  utbmsCodesRequiredByFirm,
  staffMemberOptions,
  registeredForGst,
  taxRateBasisPoints,
  matter,
  isEditableMatter,
  loggedInStaff,

  // Message bar
  isBulkCreateInvoicesInProgress,

  // Table data
  showAnticipatedDisbursementColumn,
  showBulkUpdateSelectColumn,
  showChequeCreateSelectColumn,
  showMatterColumn,
  showTaskColumn,
  showTaxColumns,
  selectedExpenseIds,
  expenseDataLoading,
  expenses,
  sortBy,
  sortDirection,
  onSort,
  onClickLink,
  onOpenChequeModal,
  onPrintCheque,
  onToggleExpenses,

  // Pagination
  hidePagination,
  currentExpensePage,
  totalNumberOfExpensePages,
  onExpenseListPageChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('master-detail-panel', Styles.matterExpenseEntries)}>
      <div className="panel-body">
        {hasBillingAccess && (
          <>
            <div className="ribbon panel">
              <Button onClick={() => onOpenExpenseModal()} disabled={!isEditableMatter}>
                New {t('expense')} Entry
              </Button>
              <Button
                onClick={() => onCreateInvoice(matterId)}
                disabled={!isEditableMatter || isBulkCreateInvoicesInProgress}
                title={
                  isBulkCreateInvoicesInProgress
                    ? 'Please wait for the bulk invoices to complete before creating new ones'
                    : undefined
                }
              >
                Create Invoice
              </Button>
              <BulkActionsButton bulkActions={bulkActions} disabled={bulkActionsDisabled} />
              {featureActive('BB-10347') && (
                <>
                  <div className={Styles.btnSpacer} />
                  <Button className={Styles.download} onClick={onGeneratePDFReport} locked={isGeneratingPDFReport}>
                    Print
                  </Button>
                  <Button className={Styles.download} onClick={onGenerateCSVReport}>
                    CSV
                  </Button>
                  <PreviewPdfModal isVisible={!!exportFileUrl} url={exportFileUrl} onClose={onCloseExportModal} />
                </>
              )}
            </div>
            <div className={Styles.quickExpenseEntrySection}>
              <QuickExpenseEntry
                scope={scopeQuickAdd}
                region={region}
                matter={matter}
                activities={activities}
                tasks={tasks}
                showMatterField={false}
                showTasksField={utbmsEnabledForFirm}
                utbmsCodesRequiredByFirm={utbmsCodesRequiredByFirm}
                staffMemberOptions={staffMemberOptions}
                loggedInStaff={loggedInStaff}
                registeredForGst={registeredForGst}
                taxRateBasisPoints={taxRateBasisPoints}
              />
            </div>

            {isBulkCreateInvoicesInProgress && (
              <div className={Styles.messageBar}>
                <MessageBar messageType="warn">
                  A bulk invoice request is currently being processed - this table will update with new entries on
                  completion.
                </MessageBar>
              </div>
            )}

            <div className={Styles.expenseTableSection}>
              <ExpenseTable
                selectedExpenseIds={selectedExpenseIds}
                currentExpensePage={currentExpensePage}
                dataLoading={expenseDataLoading}
                expenses={expenses}
                showAnticipatedDisbursementColumn={showAnticipatedDisbursementColumn}
                showBulkUpdateSelectColumn={showBulkUpdateSelectColumn}
                showChequeCreateSelectColumn={showChequeCreateSelectColumn}
                showMatterColumn={showMatterColumn}
                showTaskColumn={showTaskColumn}
                showTaxColumns={showTaxColumns}
                sortBy={sortBy}
                sortDirection={sortDirection}
                totalNumberOfExpensePages={totalNumberOfExpensePages}
                onClickLink={onClickLink}
                onOpenChequeModal={onOpenChequeModal}
                onOpenExpenseModal={onOpenExpenseModal}
                onExpenseListPageChange={onExpenseListPageChange}
                onSort={onSort}
                onPrintCheque={onPrintCheque}
                onToggleExpenses={onToggleExpenses}
              />
              <PaginationSelector
                name="matterExpense"
                className={Styles.paginationSection}
                hidePagination={hidePagination}
                numberOfPagesDisplayed={10}
                selectedPage={currentExpensePage}
                totalNumberOfPages={totalNumberOfExpensePages}
                onPageChange={onExpenseListPageChange}
              />
            </div>
          </>
        )}
        {!hasBillingAccess && <NoBillingAccessWarning />}
      </div>
    </div>
  );
};

MatterExpenseEntries.displayName = 'MatterExpenseEntries';

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

MatterExpenseEntries.propTypes = {
  region: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  hasBillingAccess: PropTypes.bool.isRequired,

  // Ribbon
  onCreateInvoice: PropTypes.func.isRequired,
  bulkActions: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      confirmTitle: PropTypes.string, // optionally supported
      confirmMessage: PropTypes.string.isRequired,
      onConfirm: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  bulkActionsDisabled: PropTypes.bool,
  exportFileUrl: PropTypes.string,
  isGeneratingPDFReport: PropTypes.bool.isRequired,
  onCloseExportModal: PropTypes.func.isRequired,
  onGenerateCSVReport: PropTypes.func.isRequired,
  onGeneratePDFReport: PropTypes.func.isRequired,

  // Quick add
  scopeQuickAdd: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isBillable: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(activityEntryTypes)),
      units: PropTypes.number,
      isTaxInclusive: PropTypes.bool,
      isTaxExempt: PropTypes.bool,
      rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
      allStaffRate: PropTypes.number,
      ratesPerStaff: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.string.isRequired,
          rate: PropTypes.number.isRequired,
        }).isRequired,
      ),
      description: PropTypes.string.isRequired,
      category: PropTypes.oneOf(Object.values(activityCategories)),
    }).isRequired,
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  utbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
  utbmsEnabledForFirm: PropTypes.bool.isRequired,
  registeredForGst: PropTypes.bool,
  taxRateBasisPoints: PropTypes.number,
  matter: PropTypes.shape({
    billingConfiguration: PropTypes.shape({
      isUtbmsEnabled: PropTypes.bool.isRequired,
      billingType: PropTypes.string,
    }).isRequired,
  }),
  isBulkCreateInvoicesInProgress: PropTypes.bool.isRequired,
  isEditableMatter: PropTypes.bool,
  loggedInStaff: PropTypes.shape({
    id: PropTypes.string,
  }),

  // Table data
  expenseDataLoading: PropTypes.bool.isRequired,
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      expenseDate: PropTypes.number,
      expenseEarnerStaff: PropTypes.shape({
        id: PropTypes.string,
        initials: PropTypes.string,
      }),
      matter: PropTypes.object,
      activityCode: PropTypes.string,
      utbmsTaskCode: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      rate: PropTypes.number,
      amount: PropTypes.number,
      isBillable: PropTypes.oneOf([true, false, null]),
      invoiceNumber: PropTypes.string,
      amountExclTax: PropTypes.number,
      amountIncTax: PropTypes.number,
      tax: PropTypes.number,
    }),
  ).isRequired,
  selectedExpenseIds: PropTypes.object,
  showAnticipatedDisbursementColumn: PropTypes.bool,
  showBulkUpdateSelectColumn: PropTypes.bool.isRequired,
  showChequeCreateSelectColumn: PropTypes.bool.isRequired,
  showMatterColumn: PropTypes.bool.isRequired,
  showTaskColumn: PropTypes.bool,
  showTaxColumns: PropTypes.bool.isRequired,
  sortBy: PropTypes.oneOf([
    'expenseDate',
    'expenseEarnerStaff',
    'activityCode',
    'utbmsTaskCode',
    'matter',
    'description',
    'isBillable',
    'invoiceNumber',
    'tax',
  ]).isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType),
  onClickLink: PropTypes.func.isRequired,
  onOpenChequeModal: PropTypes.func.isRequired,
  onOpenExpenseModal: PropTypes.func.isRequired,
  onPrintCheque: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onToggleExpenses: PropTypes.func.isRequired,

  // Pagination
  hidePagination: PropTypes.bool.isRequired,
  currentExpensePage: PropTypes.number.isRequired,
  totalNumberOfExpensePages: PropTypes.number.isRequired,
  onExpenseListPageChange: PropTypes.func.isRequired,
};

MatterExpenseEntries.defaultProps = {
  bulkActionsDisabled: undefined,
  exportFileUrl: '',
  registeredForGst: undefined,
  taxRateBasisPoints: undefined,
  matter: undefined,
  isEditableMatter: undefined,
  loggedInStaff: undefined,
  selectedExpenseIds: {},
  showAnticipatedDisbursementColumn: undefined,
  showTaskColumn: undefined,
  staffMemberOptions: undefined,
};
