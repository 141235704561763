import { defaultPath } from './default-path';

export const SET_CALENDAR_VIEW = `${defaultPath}/SET_CALENDAR_VIEW`;
export const SET_SORT = `${defaultPath}/SET_SORT`;
export const SET_SELECTED_VIEW = `${defaultPath}/SET_SELECTED_VIEW`;
export const SET_STAFF_FILTER = `${defaultPath}/SET_STAFF_FILTER`;
export const TOGGLE_EXPANDED = `${defaultPath}/TOGGLE_EXPANDED`;
export const TOGGLE_GROUP_HEADER_EXPANDED = `${defaultPath}/TOGGLE_GROUP_HEADER_EXPANDED`;
export const TOGGLE_STAFF_HEADER_EXPANDED = `${defaultPath}/TOGGLE_STAFF_HEADER_EXPANDED`;
export const TOGGLE_MY_HEADER_EXPANDED = `${defaultPath}/TOGGLE_MY_HEADER_EXPANDED`;
export const TOGGLE_FORMER_STAFF_ENABLED = `${defaultPath}/TOGGLE_FORMER_STAFF_ENABLED`;

export type CalendarViewTypes = 'ALL';
