import { fetchPostP } from '@sb-itops/redux/fetch';

import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { createCardSaveRequest } from './create-save-card-request';
import { getById as getSavedPaymentMethodsByCustomerId } from '../customer-billing-configuration';

const { opdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbCustomerBillingConfigurationService',
  keyPath: 'entityId',
});

export const saveCard = (saveCardDetails) => {
  const { saveCardFormData, providerType, contactId, bankAccountId, cardId } = saveCardDetails;

  const saveCardThunk = async () => {
    const newCard = createCardSaveRequest({
      saveCardFormData,
      providerType,
      contactId,
      bankAccountId,
    });

    const path = `/billing/payment-provider/save-card/${providerType.toLowerCase()}/${newCard.accountId}/${
      cardId ? `${cardId}/` : ''
    }`;
    const fetchOptions = { body: JSON.stringify(newCard) };
    const res = await fetchPostP({ path, fetchOptions });
    const newCardData = res.body;

    // Minimal opdate for UI, has to be after lawpay call for token
    const cBConfig = getSavedPaymentMethodsByCustomerId(contactId) || { paymentMethods: [], entityId: contactId };

    let paymentMethods;
    if (cardId) {
      paymentMethods = (cBConfig.paymentMethods || []).map((pm) => (pm.token === newCardData.token ? newCardData : pm));
    } else {
      paymentMethods = [...(cBConfig.paymentMethods || []), newCardData];
    }

    const opdateEntity = { ...cBConfig, paymentMethods };
    opdateCache({ optimisticEntities: [opdateEntity] });

    return newCardData;
  };

  return store.dispatch(saveCardThunk);
};
