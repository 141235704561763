import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { Button, Spinner, useTranslation } from '@sb-itops/react';
import { UniqueNumberingSettings } from '@sb-billing/react/unique-numbering-settings';

import Styles from './TransactionNumberingSettings.module.scss';

const warningList = ['Warning: This prefix has previously been used for another option.'];

const hasMatchingPrefix = (thisPrefix, otherPrefixes) =>
  otherPrefixes.some((otherPrefix) => {
    // ignore if this prefix is not set
    if (!thisPrefix?.prefix?.value) {
      return false;
    }

    // ignore if this prefix is using manual numbering
    if (thisPrefix?.useManualNumbering?.value) {
      return false;
    }

    // ignore if other prefix is using manual numbering
    if (otherPrefix?.useManualNumbering?.value) {
      return false;
    }

    return otherPrefix?.prefix?.value === thisPrefix?.prefix?.value;
  });

const getPrefixesErrors = ({ receipt, electronicPayments, transferOfFunds, trustToOffice }) => ({
  receipt: hasMatchingPrefix(receipt, [electronicPayments, transferOfFunds, trustToOffice]),
  electronicPayments: hasMatchingPrefix(electronicPayments, [receipt, transferOfFunds, trustToOffice]),
  transferOfFunds: hasMatchingPrefix(transferOfFunds, [electronicPayments, receipt, trustToOffice]),
  trustToOffice: hasMatchingPrefix(trustToOffice, [electronicPayments, receipt, transferOfFunds]),
});

export const TransactionNumberingSettings = memo(
  ({
    formDirty,
    formInitialised,
    formValid,
    formSubmitting,
    fields,
    exampleNumbers,
    prefixesWithWarnings,
    onChange,
    onSave,
    config,
    scope,
  }) => {
    const { t } = useTranslation();
    if (!formInitialised) {
      return null;
    }

    const saveForm = () => {
      onSave();
    };

    const prefixErrors = getPrefixesErrors(fields);
    const prefixesValid =
      !prefixErrors.receipt &&
      !prefixErrors.electronicPayments &&
      (!prefixErrors.transferOfFunds || !config.transferOfFunds);

    const supportElectronicPayment = hasFacet(facets.electronicPayment);

    return (
      <div className={Styles.transactionNumberingSettings}>
        <fieldset disabled={formSubmitting}>
          {/* Receipt Numbering */}
          <h4 className={Styles.uniqueNumberingTitle}>Receipts</h4>
          <UniqueNumberingSettings
            name={`${scope}-receipt`}
            {...fields.receipt}
            exampleNumber={exampleNumbers.receipt}
            onChange={(values) =>
              onChange({
                receipt: {
                  ...values,
                },
              })
            }
            showPrefixError={prefixErrors.receipt}
            prefixWarnings={(prefixesWithWarnings && prefixesWithWarnings.receipt && warningList) || []}
          />
          <div className={Styles.separator} />

          {supportElectronicPayment && config.electronicPayments && (
            <>
              {/* Electronic Payment Numbering */}
              <h4 className={Styles.uniqueNumberingTitle}>Electronic Payments</h4>
              <UniqueNumberingSettings
                name={`${scope}-electronicPayments`}
                {...fields.electronicPayments}
                exampleNumber={exampleNumbers.electronicPayments}
                onChange={(values) =>
                  onChange({
                    electronicPayments: {
                      ...values,
                    },
                  })
                }
                showPrefixError={prefixErrors.electronicPayments}
                prefixWarnings={(prefixesWithWarnings && prefixesWithWarnings.electronicPayments && warningList) || []}
              />
              <div className={Styles.separator} />

              {config.trustToOffice && (
                <>
                  {/* Trust to Office Numbering */}
                  <h4 className={Styles.uniqueNumberingTitle}>{t('trustToOfficeTransferLabel')}</h4>
                  <UniqueNumberingSettings
                    name={`${scope}-trustToOffice`}
                    {...fields.trustToOffice}
                    exampleNumber={exampleNumbers.trustToOffice}
                    onChange={(values) =>
                      onChange({
                        trustToOffice: {
                          ...values,
                        },
                      })
                    }
                    showPrefixError={prefixErrors.trustToOffice}
                    prefixWarnings={(prefixesWithWarnings && prefixesWithWarnings.trustToOffice && warningList) || []}
                  />
                  <div className={Styles.separator} />
                </>
              )}

              {/* Transfer of Funds Numbering */}
              {config.transferOfFunds && (
                <>
                  <h4 className={Styles.uniqueNumberingTitle}>Transfer of Funds</h4>
                  <UniqueNumberingSettings
                    name={`${scope}-transferOfFunds`}
                    {...fields.transferOfFunds}
                    exampleNumber={exampleNumbers.transferOfFunds}
                    onChange={(values) =>
                      onChange({
                        transferOfFunds: {
                          ...values,
                        },
                      })
                    }
                    showPrefixError={prefixErrors.transferOfFunds}
                    prefixWarnings={(prefixesWithWarnings && prefixesWithWarnings.transferOfFunds && warningList) || []}
                  />
                  <div className={Styles.separator} />
                </>
              )}
            </>
          )}
        </fieldset>
        <Button
          className={Styles.saveButton}
          disabled={!formDirty || !formValid || formSubmitting || !prefixesValid}
          onClick={saveForm}
        >
          Save {'  '} {formSubmitting && <Spinner small />}
        </Button>
      </div>
    );
  },
);

TransactionNumberingSettings.displayName = 'TransactionNumberingSettings';

TransactionNumberingSettings.propTypes = {
  scope: PropTypes.string.isRequired,
  formInitialised: PropTypes.bool,
  formDirty: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  exampleNumbers: PropTypes.object.isRequired,
  formValid: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  config: PropTypes.shape({
    transferOfFunds: PropTypes.bool,
    electronicPayments: PropTypes.bool,
    trustToOffice: PropTypes.bool,
  }),
  prefixesWithWarnings: PropTypes.shape({
    transferOfFunds: PropTypes.bool,
    electronicPayments: PropTypes.bool,
    trustToOffice: PropTypes.bool,
    receipt: PropTypes.bool,
  }).isRequired,
};

TransactionNumberingSettings.defaultProps = {
  formDirty: false,
  formInitialised: false,
  config: {
    transferOfFunds: true,
    electronicPayments: true,
    trustToOffice: true,
  },
};

export default TransactionNumberingSettings;
