import { Button, Modal, useTranslation } from '@sb-itops/react';
import React from 'react';
import PropTypes from 'prop-types';

import Styles from './TrustAccountCloseModal.module.scss';

export const TrustAccountCloseModal = React.memo(
  ({
    onCloseAccount,
    onCloseModal,
    isLocked,
    bankAccountHasOpenAdjustments,
    bankAccountIsDefaultPaymentAccount,
    bankAccountIsReconciled,
    showWarningOnCloseTrustAccount,
  }) => {
    const { t } = useTranslation();

    return (
      <Modal
        className={Styles.closeReopenModal}
        title="Close Account"
        body={
          <>
            {bankAccountIsDefaultPaymentAccount && (
              <p>
                This account is currently a default payment account. If you close this account you will need to select a
                new default payment account.
              </p>
            )}
            {showWarningOnCloseTrustAccount && (!bankAccountIsReconciled || bankAccountHasOpenAdjustments) && (
              <p>This account has unreconciled transactions and/or adjustments. Are you sure you want to close it?</p>
            )}
            {!bankAccountIsDefaultPaymentAccount && !(showWarningOnCloseTrustAccount && !bankAccountIsReconciled) && (
              <p>
                Are you sure you want to close this {t('trustAccount').toLowerCase()}? The transaction history will be
                kept however the {t('trustAccount').toLowerCase()} will no longer be shown on matters or selectable as a
                payment or deposit source.
              </p>
            )}
          </>
        }
        footer={
          <div className={Styles.buttonContainer}>
            <Button
              className={Styles.submitButton}
              onClick={onCloseAccount}
              size="full-width"
              locked={isLocked}
              disabled={isLocked}
            >
              Close Account
            </Button>
            <Button
              className={Styles.cancelButton}
              onClick={onCloseModal}
              size="full-width"
              type="secondary"
              disabled={isLocked}
            >
              Cancel
            </Button>
          </div>
        }
        onModalClose={onCloseModal}
      />
    );
  },
);

TrustAccountCloseModal.displayName = 'TrustAccountCloseModal';

TrustAccountCloseModal.propTypes = {
  onCloseAccount: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  bankAccountHasOpenAdjustments: PropTypes.bool.isRequired,
  bankAccountIsDefaultPaymentAccount: PropTypes.bool.isRequired,
  bankAccountIsReconciled: PropTypes.bool.isRequired,
  showWarningOnCloseTrustAccount: PropTypes.bool.isRequired,
};
