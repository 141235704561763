import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from '@sb-itops/uuid';

import { MultiSelectFilters } from '../multi-select-filters';

/**
 * Builds on the MultiSelectFilters by adding:
 * - the ability to include an 'All' option,
 * - handling for using ctrl + select as a shortcut to select a previously unselected filter, whilst de-selecting everything else.
 */
const ToggleListFilter = memo(
  ({
    options,
    onSelect,
    showCount,
    counterDataLoading,
    hideShowAllOption,
    selected,
    showAllLabel,
    optionCounts,
    countDeleted,
  }) => {
    const componentId = useMemo(() => uuid(), []);
    const showAllId = 'ALL';

    const defaultInternals = useMemo(() => {
      const allItemsIds = [];
      const allCount = optionCounts
        ? Object.entries(optionCounts).reduce(
            (acc, [key, count]) => (key.toUpperCase() === 'DELETED' && !countDeleted ? acc : acc + (count || 0)),
            0,
          )
        : options.reduce((acc, item) => acc + (item.count || 0), 0);

      const defaultOptions = options.reduce((listOptions, item) => {
        const parsedItem = {
          ...item,
          name: item.label,
          id: item.id || item.value || uuid(),
          count: optionCounts ? optionCounts[item.id || item.value] || 0 : item.count,
        };
        listOptions.push(parsedItem);
        allItemsIds.push(parsedItem.id);
        return listOptions;
      }, []);

      if (!hideShowAllOption) {
        defaultOptions.unshift({ id: showAllId, name: showAllLabel, count: allCount });
      }

      return {
        allItemsIds,
        defaultOptions,
      };
    }, [options, hideShowAllOption, optionCounts, showAllLabel, countDeleted]);

    const optionsMemo = useMemo(
      () =>
        defaultInternals.defaultOptions.map((item) => ({
          ...item,
          selected:
            selected === undefined ||
            selected.length === defaultInternals.defaultOptions.length - (hideShowAllOption ? 0 : 1) ||
            selected.includes(item.id) ||
            selected.includes(showAllId),
        })),
      [defaultInternals.defaultOptions, hideShowAllOption, selected],
    );

    const onSelectHandler = useCallback(
      (filterId, selection) => {
        // if show all is selected
        if (filterId === showAllId) {
          if (selection) {
            return onSelect([...defaultInternals.allItemsIds], true /* Indicates selection of all */);
          }
          return onSelect([]);
        }

        // when individual filter is selected
        const defaultSelection = selected || [...defaultInternals.allItemsIds];
        if (filterId && !selection) {
          return onSelect(defaultSelection.filter((value) => value !== filterId));
        }

        // when all options selected, make sure to set second parameter 'allSelected' to true
        const selectedFilterIds = [...new Set([...defaultSelection, filterId])];
        const allSelected = selectedFilterIds.length === defaultInternals.allItemsIds.length;
        return onSelect(selectedFilterIds, allSelected);
      },
      [defaultInternals.allItemsIds, onSelect, selected],
    );

    const onForceSelectHandler = useCallback((filterId) => onSelect([filterId]), [onSelect]);

    return (
      <MultiSelectFilters
        id={componentId}
        filterOptions={optionsMemo}
        onSelect={onSelectHandler}
        onForceSelect={onForceSelectHandler}
        showCounter={showCount || !!optionCounts}
        counterDataLoading={counterDataLoading}
      />
    );
  },
);

ToggleListFilter.displayName = 'ToggleListFilter';

ToggleListFilter.propTypes = {
  counterDataLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string, // if not passing will generate a unique value per item
      value: PropTypes.string, // Alias for id
      count: PropTypes.number,
    }),
  ).isRequired,
  // if selected is undefined we will select the `select-all` option. otherwise this will ba an array of the options value/uuid.
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  showCount: PropTypes.bool,
  optionCounts: PropTypes.object,
  hideShowAllOption: PropTypes.bool,
  countDeleted: PropTypes.bool,
  showAllLabel: PropTypes.string,
};

ToggleListFilter.defaultProps = {
  counterDataLoading: false,
  showCount: false,
  optionCounts: undefined,
  onSelect: () => {},
  hideShowAllOption: false,
  countDeleted: false, // default to existing behaviour which seems to be relied upon by the invoices list filter
  selected: undefined,
  showAllLabel: 'Show All',
};

export default ToggleListFilter;
