import { isPaymentEventType } from '@sb-billing/business-logic/payment-plan-event/services';
import { getList } from './index';

/**
 * Get the payment plan payments events for a given payment plan
 * @param {Array.<object>} paymentPlanEventsList -  a list of the payment plans events
 * @param {object} options -  findPaymentsFromPaymentPlanEvents options.
 * @param {string} options.paymentPlanId -  a paymentPlanId id to get the pps payments.
 * @returns {object|undefined} the latest pp for a given debtor
 * @throws if paymentPlanEventsList is falsy
 * @throws if paymentPlanId is falsy
 */
export const findPaymentEvents = (paymentPlanEventsList, { paymentPlanId }) => {
  if (!paymentPlanId) {
    throw new Error(`paymentPlanId cannot be falsy`);
  }

  if (!paymentPlanEventsList) {
    throw new Error(`paymentPlanEventsList cannot be falsy`);
  }

  return paymentPlanEventsList.filter(
    (paymentPlanEvent) => paymentPlanEvent.paymentPlanId === paymentPlanId && isPaymentEventType(paymentPlanEvent.type),
  );
};

// Shortcut for findPaymentEvents above
export const getPaymentPlanEventsListById = (paymentPlanId) => findPaymentEvents(getList(), { paymentPlanId });
