import { featureActive } from '@sb-itops/feature';
import { getActiveTrustAccounts, getTrustAccount } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { sortByProperty } from '@sb-itops/nodash';

angular.module('sb.billing.webapp').component('sbQuickBooksIntegration', {
  'templateUrl': 'ng-components/quickbooks/quickbooks-integration.html',
  'controller': function (sbLoggerService, sbQuickBooksService, $scope, sbMessageDisplayService, sbLocalisationService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbQuickBooksIntegration');

    ctrl.t = sbLocalisationService.t;

    const DO_NOT_INTEGRATE = { name: `Do not integrate ${ctrl.t('operatingCheque')} payments`, id: -1 }; // Adding an ID means this is a valid option to save with, we will strip value in marshal
    const DEFAULT_OPERATING_BANK_ACCOUNT = { name: 'Select...' };
    const DEFAULT_LEGAL_FEES_ACCOUNT = { name: 'Select...' };
    const DEFAULT_CLIENT_EXPENSE_HARD_ACCOUNT = { name: 'Select...' };
    const DEFAULT_CLIENT_EXPENSE_SOFT_ACCOUNT = { name: 'Select...' };
    const DEFAULT_OPERATING_CHEQUE_ACCOUNT = { name: 'Select...' };
    const DEFAULT_OPERATING_CHEQUE_SOFT_COST_ACCOUNT = { name: 'Select...'};
    const DEFAULT_OPERATING_LIABILITY_ACCOUNT = { name: 'None', id: -1 }; // Adding an ID means this is a valid option to save with, we will strip value in marshal
    const DEFAULT_SURCHARGE_ACCOUNT = { name: 'None', id: -1 }; // Adding an ID means this is a valid option to save with, we will strip value in marshal

    let formDirty;

    ctrl.save = save;
    ctrl.prepField = prepField;
    ctrl.disconnectQuickBooks = disconnectQuickBooks;
    ctrl.showTrustIntegration = featureActive('BB-8561');
    ctrl.hardAndSoftCostFeatureEnabled = featureActive('BB-13352');
    ctrl.trustAccounts = getMapTrustAccounts();
    ctrl.getOfficePaymentsTooltipText = getOfficePaymentsTooltipText;

    //log.setLogLevel('info');

    ctrl.$onInit = () => {
      ctrl.view = {
        integrationEnabled: false,
      };
      ctrl.errors = {
        trustBankAccounts: {},
        trustLiabilityAccounts: {},
      };

      ctrl.model = {
        integrateGeneral: false,
        integrateTrust: false,
        operatingLiabilityAccount: DEFAULT_OPERATING_LIABILITY_ACCOUNT,
        operatingChequeAccount: DEFAULT_OPERATING_CHEQUE_ACCOUNT,
        operatingChequeSoftCostAccount: DEFAULT_OPERATING_CHEQUE_SOFT_COST_ACCOUNT,
        surchargeAccount: DEFAULT_SURCHARGE_ACCOUNT,
      };

      //only load the rest of the data if the user is authorized.
      loadIsAuthed()
        .then(() => {
          loadAccounts();
          loadSelectedAccounts();
        });
    };

    $scope.$on('smokeball-data-update-sbQuickBooksService', () => {
      loadIsAuthed()
        .then(() => {
          loadAccounts();
          loadSelectedAccounts();
        });

    });

    $scope.$watch('quickbooksIntegration', (quickbooksIntegration) => {
      if (quickbooksIntegration) {
        $scope.$watch('quickbooksIntegration.$dirty', (dirty) => {
          formDirty = dirty;
        });
      }
    });

    function disconnectQuickBooks () {
      sbQuickBooksService.disableP()
        .then(() => {
          sbMessageDisplayService.success('Disconnected from QuickBooks');
        })
        .catch((err) => {
          log.info('error disconnecting from QuickBooks', err);
          sbMessageDisplayService.error('Failed to disconnect from QuickBooks');
        });
    }

  function getOfficePaymentsTooltipText({ expenseType }) {
      return `When a ${expenseType} ${ctrl.t('expense')} is marked as paid, the payment details will be applied to this account`;
    }

    function loadAccounts () {
      sbQuickBooksService.getBankAccountsP()
        .then((accounts) => {
          // general ledger transactions accounts
          ctrl.view.operatingBankAccounts      = [DEFAULT_OPERATING_BANK_ACCOUNT, ...(accounts.operatingBankAccounts || [])];
          ctrl.view.legalFeesAccounts          = [DEFAULT_LEGAL_FEES_ACCOUNT, ...(accounts.legalFeesAccounts || [])];
          ctrl.view.clientExpensesAccounts     = [DEFAULT_CLIENT_EXPENSE_HARD_ACCOUNT, ...(accounts.clientExpensesAccounts || [])];
          ctrl.view.clientSoftCostExpensesAccounts = [DEFAULT_CLIENT_EXPENSE_SOFT_ACCOUNT, ...(accounts.clientSoftCostExpensesAccounts || [])];
          ctrl.view.operatingLiabilityAccounts = [DEFAULT_OPERATING_LIABILITY_ACCOUNT, ...(accounts.operatingLiabilityAccounts || [])];
          ctrl.view.operatingChequeAccounts    = [DEFAULT_OPERATING_CHEQUE_ACCOUNT, DO_NOT_INTEGRATE, ...(accounts.operatingChequeAccounts || [])];
          ctrl.view.operatingChequeSoftCostAccounts = [DEFAULT_OPERATING_CHEQUE_SOFT_COST_ACCOUNT, DO_NOT_INTEGRATE, ...(accounts.operatingChequeSoftCostAccounts || [])];
          ctrl.view.surchargeAccounts          = [DEFAULT_SURCHARGE_ACCOUNT, ...(accounts.surchargeAccounts || [])];
          // trust transactions accounts
          ctrl.view.trustBankAccounts          = accounts.trustBankAccounts || [];
          ctrl.view.trustLiabilityAccounts     = accounts.trustLiabilityAccounts || [];
        });
    }

    function loadSelectedAccounts () {
      return sbQuickBooksService.getSettingsP()
        .then((settings) => {
          ctrl.model.integrateGeneral          = settings.generalLedgerTransactionsEnabled;
          ctrl.model.integrateTrust            = settings.trustTransactionsEnabled;
          // general ledger transactions accounts
          ctrl.model.operatingBankAccount      = accountPresent(settings.operatingBankAccount) ? settings.operatingBankAccount : DEFAULT_OPERATING_BANK_ACCOUNT;
          ctrl.model.legalFeesAccount          = accountPresent(settings.legalFeesAccount) ? settings.legalFeesAccount : DEFAULT_LEGAL_FEES_ACCOUNT;
          ctrl.model.clientExpensesAccount     = accountPresent(settings.clientExpensesAccount) ? settings.clientExpensesAccount : DEFAULT_CLIENT_EXPENSE_HARD_ACCOUNT;
          // If there is no value yet set (haven't saved with soft cost feature on), show account based on hard cost
          ctrl.model.clientSoftCostExpensesAccount = accountPresent(settings.clientSoftCostExpensesAccount) ? settings.clientSoftCostExpensesAccount : getDefaultAccountForSoftCostFieldWhenValueIsNotSet({ hardCostAccount: ctrl.model.clientExpensesAccount, softCostAccountOptions: ctrl.view.clientSoftCostExpensesAccounts });
          ctrl.model.operatingLiabilityAccount = accountPresent(settings.operatingLiabilityAccount) ? settings.operatingLiabilityAccount : DEFAULT_OPERATING_LIABILITY_ACCOUNT;
          ctrl.model.operatingChequeAccount    = loadOperatingChequeAccount(settings.operatingChequeAccount, settings.officePaymentsIntegrationEnabled);
          // If there is no value yet set (haven't saved with soft cost feature on), show account based on hard cost
          ctrl.model.operatingChequeSoftCostAccount = loadOperatingChequeSoftCostsAccount(settings.operatingChequeSoftCostAccount, settings.officePaymentsSoftCostIntegrationEnabled);
          ctrl.model.surchargeAccount          = accountPresent(settings.surchargeAccount) ? settings.surchargeAccount : DEFAULT_SURCHARGE_ACCOUNT;
          // trust transactions accounts
          ctrl.model.trustBankAccounts          = getSettingsTrustBankAccounts(settings);
          ctrl.model.trustLiabilityAccounts     = getSettingsTrustLiabilityAccounts(settings);
        });
    }

    function loadIsAuthed () {
      return sbQuickBooksService.isAuthorizedP()
        .then((isAuthorized) => {
          ctrl.view.integrationEnabled = isAuthorized;
        });
    }

    function loadOperatingChequeAccount(operatingChequeAccount, officePaymentsIntegrationEnabled) {
      if (officePaymentsIntegrationEnabled) {
        return accountPresent(operatingChequeAccount) ? operatingChequeAccount : DEFAULT_OPERATING_CHEQUE_ACCOUNT;
      } else {
        return DO_NOT_INTEGRATE;
      }
    }

    function loadOperatingChequeSoftCostsAccount(operatingChequeSoftCostAccount, officePaymentsSoftCostIntegrationEnabled) {
      if (officePaymentsSoftCostIntegrationEnabled) {
        return accountPresent(operatingChequeSoftCostAccount) ? operatingChequeSoftCostAccount : getDefaultAccountForSoftCostFieldWhenValueIsNotSet({ hardCostAccount: ctrl.model.operatingChequeAccount, softCostAccountOptions: ctrl.view.operatingChequeSoftCostAccounts });
      } else {
        return DO_NOT_INTEGRATE;
      }
    }

    function getMapTrustAccounts () {
      const trustAccounts = getActiveTrustAccounts();
      const mappedTrustAccounts = trustAccounts.map((trust) => ({
        ...trust,
        name: getBankAccountName(trust, sbLocalisationService.t),
      }));
      const sortedTrustAccounts = sortByProperty(
        mappedTrustAccounts,
        'name',
        'asc',
        false,
      );
      return sortedTrustAccounts;
    }

    /**
     * With the introduction of MTA there may now be multiple trust accounts mapped to Quickbooks accounts
     * If the plural object exists `trustBankAccounts`, only use information from that
     * If the plural object does not exist, use the singular and attribute it to default trust
     */
    function getSettingsTrustBankAccounts (settings) {
      let trustBankAccounts = [];
      if (settings.trustBankAccounts) {
        trustBankAccounts = settings.trustBankAccounts;
      } else if (settings.trustBankAccount) {
        trustBankAccounts = [{
          quickbooksAccount: settings.trustBankAccount,
          trustAccountId: getTrustAccount().id,
        }];
      }
      return mapSettingsTrustAccountsArrayToById(trustBankAccounts);
    }

    function getSettingsTrustLiabilityAccounts (settings) {
      let trustLiabilityAccounts = [];
      if (settings.trustLiabilityBankAccounts) {
        trustLiabilityAccounts = settings.trustLiabilityBankAccounts;
      } else if (settings.trustLiabilityAccount) {
        trustLiabilityAccounts = [{
          quickbooksAccount: settings.trustLiabilityAccount,
          trustAccountId: getTrustAccount().id,
        }];
      }
      return mapSettingsTrustAccountsArrayToById(trustLiabilityAccounts);
    }

    function mapSettingsTrustAccountsArrayToById (trustAccounts) {
      return trustAccounts.reduce((acc, accountMapping) => {
        acc[accountMapping.trustAccountId] = accountMapping.quickbooksAccount;
        return acc;
      }, {});
    }

    function mapSettingsTrustAccountsByIdToArray (trustAccountsMap) {
      const ids = Object.keys(trustAccountsMap);
      return ids.map((id) => ({
        trustAccountId: id,
        quickbooksAccount: trustAccountsMap[id],
      }));
    }

    function prepField (name, trustAccountId) {
      const isExpenseAccountSameAsLegalFeeAccount = () => isSameAsLegalFeeAccount(ctrl.model.clientExpensesAccount);
      const isSoftCostExpenseAccountSameAsLegalFeeAccount = () => ctrl.hardAndSoftCostFeatureEnabled && isSameAsLegalFeeAccount(ctrl.model.clientSoftCostExpensesAccount);

      switch (name) {
        // general ledger transactions accounts
        case 'operatingBankAccount':
          ctrl.errors.operatingBankAccount  = ctrl.model.integrateGeneral && !accountPresent(ctrl.model.operatingBankAccount) && formDirty;
          break;
        case 'legalFeesAccount':
          ctrl.errors.legalFeesAccount      = ctrl.model.integrateGeneral && (!accountPresent(ctrl.model.legalFeesAccount) || isExpenseAccountSameAsLegalFeeAccount() || isSoftCostExpenseAccountSameAsLegalFeeAccount()) && formDirty;
          break;
        case 'clientExpensesAccount':
          ctrl.errors.clientExpensesAccount = ctrl.model.integrateGeneral && (!accountPresent(ctrl.model.clientExpensesAccount) || isExpenseAccountSameAsLegalFeeAccount()) && formDirty;
          break;
        case 'clientSoftCostExpensesAccount':
          ctrl.errors.clientSoftCostExpensesAccount = ctrl.model.integrateGeneral && (!accountPresent(ctrl.model.clientSoftCostExpensesAccount) || isSoftCostExpenseAccountSameAsLegalFeeAccount());
          break;
        case 'operatingChequeAccount':
          ctrl.errors.operatingChequeAccount = ctrl.model.integrateGeneral && !accountPresent(ctrl.model.operatingChequeAccount);
          break;
        case 'operatingChequeSoftCostAccount':
          ctrl.errors.operatingChequeSoftCostAccount = ctrl.model.integrateGeneral && !accountPresent(ctrl.model.operatingChequeSoftCostAccount);
          break;
        // trust transactions accounts
        // these need to be either both blank or both filled
        case 'trustBankAccounts':
          ctrl.errors.trustBankAccounts[trustAccountId]      = ctrl.model.integrateTrust   && accountPresent(ctrl.model.trustBankAccounts[trustAccountId]) !== accountPresent(ctrl.model.trustLiabilityAccounts[trustAccountId]) && !accountPresent(ctrl.model.trustBankAccounts[trustAccountId]);
          break;
        case 'trustLiabilityAccounts':
          ctrl.errors.trustLiabilityAccounts[trustAccountId] = ctrl.model.integrateTrust   && accountPresent(ctrl.model.trustBankAccounts[trustAccountId]) !== accountPresent(ctrl.model.trustLiabilityAccounts[trustAccountId]) && !accountPresent(ctrl.model.trustLiabilityAccounts[trustAccountId]);
          break;
      }
    }

    function marshal () {
      const obj = {
        generalLedgerTransactionsEnabled: ctrl.model.integrateGeneral,
        trustTransactionsEnabled: ctrl.model.integrateTrust,
      };

      if (ctrl.model.integrateGeneral) {
        const operatingChequeAccountIsDoNotIntegrate          = isSameAccount(ctrl.model.operatingChequeAccount, DO_NOT_INTEGRATE);
        const operatingChequeSoftCostAccountIsDoNotIntegrate  = isSameAccount(ctrl.model.operatingChequeSoftCostAccount, DO_NOT_INTEGRATE);
        obj.operatingBankAccount                              = ctrl.model.operatingBankAccount;
        obj.legalFeesAccount                                  = ctrl.model.legalFeesAccount;
        obj.clientExpensesAccount                             = ctrl.model.clientExpensesAccount;
        obj.clientSoftCostExpensesAccount                     = ctrl.hardAndSoftCostFeatureEnabled ? ctrl.model.clientSoftCostExpensesAccount : undefined;
        obj.operatingLiabilityAccount                         = isSameAccount(ctrl.model.operatingLiabilityAccount, DEFAULT_OPERATING_LIABILITY_ACCOUNT) ? undefined: ctrl.model.operatingLiabilityAccount;
        obj.operatingChequeAccount                            = operatingChequeAccountIsDoNotIntegrate ? undefined : ctrl.model.operatingChequeAccount;
        obj.operatingChequeSoftCostAccount                    = getOperatingChequeSoftCostAccountForSaving(ctrl.model.operatingChequeSoftCostAccount, operatingChequeSoftCostAccountIsDoNotIntegrate);
        obj.surchargeAccount                                  = featureActive('BB-7270') ? (isSameAccount(ctrl.model.surchargeAccount, DEFAULT_SURCHARGE_ACCOUNT) ? undefined : ctrl.model.surchargeAccount) : undefined;
        obj.officePaymentsIntegrationEnabled                  = !operatingChequeAccountIsDoNotIntegrate;
        obj.officePaymentsSoftCostIntegrationEnabled          = !operatingChequeSoftCostAccountIsDoNotIntegrate;
      }

      if (ctrl.model.integrateTrust) {
        obj.trustBankAccounts                                 = mapSettingsTrustAccountsByIdToArray(ctrl.model.trustBankAccounts);
        obj.trustLiabilityAccounts                            = mapSettingsTrustAccountsByIdToArray(ctrl.model.trustLiabilityAccounts);
      }

      return obj;
    }

    function isSameAccount(account, otherAccount) {
      if (account && otherAccount && account.name === otherAccount.name) {
        return true;
      }
      return false;
    }

    function getOperatingChequeSoftCostAccountForSaving(operatingChequeSoftCostAccount, operatingChequeSoftCostAccountIsDoNotIntegrate) {
      if (ctrl.hardAndSoftCostFeatureEnabled) {
          return operatingChequeSoftCostAccountIsDoNotIntegrate ? undefined : operatingChequeSoftCostAccount;
      } else {
          return undefined;
      }
    }

    /**
     * Checks if the account is exists in the options and return the account or undefined when loadSelectedAccounts
     * For soft cost fields, if there is no value yet set, we default the value as the hard cost field
     * However, hard cost fields have more options (Eg. Income & Asset), and soft cost fields have less options (Eg. Income only). Therefore, if hard cost has selected an asset account, then the account is not exist in soft cost options. In this case, we show empty for soft cost field, and the user needs to select an account to proceed for saving (If the user clicks save directly, the soft cost field will show in red)
     */
    function getDefaultAccountForSoftCostFieldWhenValueIsNotSet({ hardCostAccount, softCostAccountOptions }) {
      if (hardCostAccount.name === 'None') {
        return hardCostAccount;
      }

      const accountExist = softCostAccountOptions.find(a => a.id === hardCostAccount.id);
      if (accountExist) {
        return hardCostAccount;
      } else {
        return undefined;
      }
    }

    /***
     * Check if the account passed in is the same account as legal fee account
     *
     * @returns {boolean}
     */
    function isSameAsLegalFeeAccount(accountToCheck) {
      const isSameAccount = accountToCheck && accountToCheck.id === ctrl.model.legalFeesAccount.id;
      return isSameAccount;
    }

    /***
     * WebStorm says this can be simplified - it can, but its easier to add/change like this
     *
     * @returns {boolean}
     */
    function isValid () {
      if (ctrl.model.integrateGeneral && (
        !accountPresent(ctrl.model.operatingBankAccount) || 
        !accountPresent(ctrl.model.legalFeesAccount) || 
        !accountPresent(ctrl.model.clientExpensesAccount) ||
        !accountPresent(ctrl.model.operatingChequeAccount)
      )) {
        return false;
      }

      if (ctrl.model.integrateTrust && ctrl.trustAccounts.some((account) => accountPresent(ctrl.model.trustBankAccounts[account.id]) !== accountPresent(ctrl.model.trustLiabilityAccounts[account.id]))) {
        return false;
      }

      if (ctrl.model.integrateGeneral && ctrl.model.legalFeesAccount.id === ctrl.model.clientExpensesAccount.id) {
        return false;
      }

      if (ctrl.model.integrateGeneral && ctrl.hardAndSoftCostFeatureEnabled) {
        if (!accountPresent(ctrl.model.clientSoftCostExpensesAccount)) {
          return false;
        }
        if (ctrl.model.legalFeesAccount.id === ctrl.model.clientSoftCostExpensesAccount.id) {
          return false;
        }
        if (!ctrl.model.operatingChequeSoftCostAccount) {
          return false;
        }
      }

      return true;
    }

    /**
     * Checks if the parameter object is present or not.
     *
     * Need to do this as the backend returns an object for an unselected account (rather than not returning anything for that account)
     *
     * @param account
     * @returns {Boolean}
     */
    function accountPresent (account) {
      return !!(account && account.name && account.id);
    }

    /**
     * We need to clear any accounts that are not being used anymore, as this is what the backend does
     */
    function clearUnusedAccounts () {
      if (!ctrl.model.integrateGeneral) {
        ctrl.model.operatingBankAccount           = DEFAULT_OPERATING_BANK_ACCOUNT;
        ctrl.model.legalFeesAccount               = DEFAULT_LEGAL_FEES_ACCOUNT;
        ctrl.model.clientExpensesAccount          = DEFAULT_CLIENT_EXPENSE_HARD_ACCOUNT;
        ctrl.model.clientSoftCostExpensesAccount  = DEFAULT_CLIENT_EXPENSE_SOFT_ACCOUNT;
        ctrl.model.operatingLiabilityAccount      = DEFAULT_OPERATING_LIABILITY_ACCOUNT;
        ctrl.model.operatingChequeAccount         = DEFAULT_OPERATING_CHEQUE_ACCOUNT;
        ctrl.model.operatingChequeSoftCostAccount = DEFAULT_OPERATING_CHEQUE_SOFT_COST_ACCOUNT;
        ctrl.model.surchargeAccount               = DEFAULT_SURCHARGE_ACCOUNT;
      }

      if (!ctrl.model.integrateTrust) {
        ctrl.model.trustBankAccounts               = [];
        ctrl.model.trustLiabilityAccounts          = [];
      }
    }

    function save () {
      if (isValid()) {
        //need to prep these fields as their error states depend on each other
        prepField('legalFeesAccount');
        prepField('clientExpensesAccount');
        if (ctrl.hardAndSoftCostFeatureEnabled) {
          prepField('clientSoftCostExpensesAccount');
        }

        sbQuickBooksService.saveSettingsP(marshal())
          .then(clearUnusedAccounts)
          .then(() => {
            sbMessageDisplayService.success(
              sbMessageDisplayService
                .builder()
                .text('Quickbooks integration settings saved')
            );
          })
          .catch((err) => {
            log.info('error saving settings', err);
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text('Failed to save integration settings')
                .conditionalText(': {0}', err.message)
            );
          });
      } else {
        prepField('operatingBankAccount');
        prepField('legalFeesAccount');
        prepField('clientExpensesAccount');
        prepField('operatingChequeAccount');
        if (ctrl.hardAndSoftCostFeatureEnabled) {
          prepField('clientSoftCostExpensesAccount');
          prepField('operatingChequeSoftCostAccount');
        }
        ctrl.trustAccounts.forEach((account) => {
          prepField('trustBankAccounts', account.id);
          prepField('trustLiabilityAccounts', account.id);
        })
 
        log.info(ctrl.errors);
        log.info(ctrl.model.legalFeesAccount);
        log.info(ctrl.model.clientExpensesAccount);
        if (ctrl.hardAndSoftCostFeatureEnabled) {
          log.info(ctrl.model.clientSoftCostExpensesAccount);
          log.info(ctrl.model.operatingChequeSoftCostAccount);
        }
      }
    }

  }
});
