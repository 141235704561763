'use strict';

const defaultColours = {
  WHITE: '#FFFFFF',
  GREEN: '#1DBF73',
};

const createLink = ({ label, colour = defaultColours.GREEN, url }) => {
  const textColour = colour;

  const linkHtml = `
    <a href="${url}" style="color:${textColour};display:inline-block;font-family:sans-serif;font-size:13px;font-weight:bold;line-height:21px;text-align:center;text-decoration:none;-webkit-text-size-adjust:none;">
      ${label}
    </a>
  `;
  return linkHtml;
};

module.exports = {
  createLink,
  defaultColours,
};
