import * as yup from 'yup';

export const AppointmentSchema = yup.object().shape({
  matterId: yup.string().nullable(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
  subject: yup.string().required(),
  description: yup.string().nullable(),
  location: yup.string().nullable(),
  resourceIds: yup.array().of(yup.string()).min(1),
  allDay: yup.boolean(),
  isRemoved: yup.boolean(),
});
