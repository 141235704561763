import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  CollapsibleHeader,
  CurrencyInput2,
  PanelExpander,
  PaginationSelector,
  Spinner,
  ToggleListFilter,
  useTranslation,
} from '@sb-itops/react';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { StaffSelectorListContainer } from '@sb-firm-management/react';
import { setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { TrustToOfficeTable } from 'web/components/trust-to-office-table';
import { MatterTypeSelector } from 'web/containers';
import { BankAccountDropDown } from 'web/react-redux/components/bank-account-drop-down';

import { BulkTrustToOfficeModal } from 'web/components/bulk-trust-to-office-modal';
import { DateListFilter } from '../../components/date-list-filter';

import Styles from './BulkTrustToOfficeRoute.module.scss';

const TTO_UNPAID_AD_MODAL_ID = 'tto-unpaid-ad-modal';

const supportsContingencyBillingType = hasFacet(facets.contingencyBillingType);
const MATTER_STATUS_FILTER_OPTIONS = [
  { id: 'Open', label: 'Open' },
  { id: 'Pending', label: 'Pending' },
  { id: 'Closed', label: 'Closed' },
];
const BILLING_TYPE_FILTER_OPTIONS = [
  { id: 'time', label: 'Time Based' },
  { id: 'fixed', label: 'Fixed' },
  ...(supportsContingencyBillingType ? [{ id: 'contingency', label: 'Contingency' }] : []),
];

export const BulkTrustToOfficeRoute = React.memo(
  ({
    loading,
    rows,
    sort,
    sortBy,
    sortDirection,
    allInvoiceIds,
    allMatterIds,
    allExpanded,
    allSelected,
    hasError,
    tableSummary,
    filters,
    visibleFilterGroups,
    expanded,
    // pay button/modal
    disablePayButton,
    isTrustToOfficeModalVisible,
    paymentSummary,
    // pagination
    hidePagination,
    currentPage,
    onPageChange,
    totalNumberOfPages,
    // filter options
    trustAccounts,
    trustAccountsLoading,
    matterTypes,
    matterTypesLoading,
    staffMembers,
    staffMembersLoading,
    // callbacks & functions
    onFilterTrustAccount,
    onFilterIssueDate,
    onFilterBillingType,
    onFilterMatterType,
    onFilterMatterStatus,
    onToggleFilterGroupVisibility,
    onStaffChange,
    onFilterTrustBalance,
    onResetFilters,
    onSelectInvoices,
    onToggleAllMattersExpanded,
    onToggleMatterExpanded,
    onToggleAutoAllocate,
    onClickLink,
    onChangePaymentAmount,
    onOpenTrustChequesModal,
    onPayButtonClick,
    onPaymentsSubmitted,
    toggleShowFilters,
    selectedMatterTypes,
  }) => {
    const { t } = useTranslation();
    const onPayButtonClickHandler = () => {
      if (featureActive('BB-9573') && paymentSummary?.hasUnpaidAD) {
        setModalDialogVisible({ modalId: TTO_UNPAID_AD_MODAL_ID });
        return;
      }
      onPayButtonClick();
    };
    const onADWarningConfirm = () => {
      setModalDialogHidden({ modalId: TTO_UNPAID_AD_MODAL_ID });
      onPayButtonClick();
    };

    return (
      <div className={classnames('master-detail-panel', Styles.trustToOfficePanel)}>
        <div className="firm-invoices-side-panel">
          <PanelExpander
            className="panel-filter"
            onResetFilters={onResetFilters}
            toggleExpanded={toggleShowFilters}
            expanded={expanded}
          >
            <div className={Styles.trustAccountSection}>
              <div className={Styles.trustAccountTitle}>
                <h3>{t('trustAccount')}</h3>
              </div>
              {trustAccountsLoading ? (
                <Spinner className={Styles.loadingSpinner} small />
              ) : (
                <div className={Styles.trustAccountSelection}>
                  <BankAccountDropDown
                    disabled={!trustAccounts.length}
                    placeholder={`No open ${t('trustAccounts').toLowerCase()}`}
                    onValueChange={(bankAccount) => {
                      onFilterTrustAccount(bankAccount);
                    }}
                    options={trustAccounts}
                    selectedOptionValue={filters.bankAccountId}
                  />
                </div>
              )}
            </div>
            <CollapsibleHeader
              text="Issue Date"
              onClick={() => {
                onToggleFilterGroupVisibility('issueDate', !visibleFilterGroups.issueDate);
              }}
              name="issueDate"
              collapsed={!visibleFilterGroups.issueDate}
            >
              <DateListFilter
                onChange={onFilterIssueDate}
                selectedFilter={filters.issueDate.filterType}
                before={filters.issueDate.before}
                from={filters.issueDate.from}
                to={filters.issueDate.to}
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Billing Type"
              onClick={() => {
                onToggleFilterGroupVisibility('billingTypes', !visibleFilterGroups.billingTypes);
              }}
              name="billingType"
              collapsed={!visibleFilterGroups.billingTypes}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={BILLING_TYPE_FILTER_OPTIONS}
                selected={filters.billingTypes.selections}
                onSelect={onFilterBillingType}
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Matter Type"
              onClick={() => {
                onToggleFilterGroupVisibility('matterTypes', !visibleFilterGroups.matterTypes);
              }}
              name="matterType"
              collapsed={!visibleFilterGroups.matterTypes}
            >
              {matterTypesLoading ? (
                <Spinner className={Styles.loadingSpinner} small />
              ) : (
                <MatterTypeSelector
                  matterTypes={matterTypes}
                  onSelectMatterType={onFilterMatterType}
                  selectedMatterTypeIds={selectedMatterTypes}
                />
              )}
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Matter Status"
              onClick={() => {
                onToggleFilterGroupVisibility('matterStatuses', !visibleFilterGroups.matterStatuses);
              }}
              name="matterStatus"
              collapsed={!visibleFilterGroups.matterStatuses}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={MATTER_STATUS_FILTER_OPTIONS}
                selected={filters.matterStatuses}
                onSelect={onFilterMatterStatus}
              />
            </CollapsibleHeader>
            <div className="attorney-responsible-container">
              <CollapsibleHeader
                text={t('capitalizeAllWords', { val: 'personResponsible' })}
                onClick={() => {
                  onToggleFilterGroupVisibility('attorneysResponsible', !visibleFilterGroups.attorneysResponsible);
                }}
                name="attorneyResponsible"
                collapsed={!visibleFilterGroups.attorneysResponsible}
              >
                {staffMembersLoading ? (
                  <Spinner className={Styles.loadingSpinner} small />
                ) : (
                  <StaffSelectorListContainer
                    staff={staffMembers}
                    onStaffSelectionChange={onStaffChange}
                    initiallySelectedStaffIds={filters.attorneysResponsible || []}
                    showNoneOption
                  />
                )}
              </CollapsibleHeader>
            </div>
            <CollapsibleHeader
              text="Matter Balances"
              onClick={() => {
                onToggleFilterGroupVisibility('matterBalances', !visibleFilterGroups.matterBalances);
              }}
              name="matterBalances"
              collapsed={!visibleFilterGroups.matterBalances}
            >
              <div className={Styles.debtorBalances}>
                <div className="balance-type">
                  <span>At least {t('currencySymbol')}</span>
                  <CurrencyInput2
                    className={Styles.balanceValue}
                    value={filters.minimumTrustBalance}
                    onChange={onFilterTrustBalance}
                    hideDollar
                  />
                  <span>in {t('trust')}</span>
                </div>
              </div>
            </CollapsibleHeader>
          </PanelExpander>
        </div>
        <div className="panel-body">
          {filters?.bankAccountId && isTrustToOfficeModalVisible && (
            <BulkTrustToOfficeModal
              bankAccountId={filters.bankAccountId}
              paymentSummary={paymentSummary}
              onPaymentsSubmitted={onPaymentsSubmitted}
              onOpenTrustChequesModal={onOpenTrustChequesModal}
              onClickLink={onClickLink}
            />
          )}
          <div className={classnames('ribbon', 'panel', 'panel-primary', Styles.ribbonAvoidConflict)}>
            <Button onClick={onPayButtonClickHandler} disabled={disablePayButton}>
              {' '}
              Pay ({paymentSummary.invoiceCount})
            </Button>
          </div>

          {featureActive('BB-9573') && (
            <ConfirmationModalDialog
              onConfirm={onADWarningConfirm}
              modalId={TTO_UNPAID_AD_MODAL_ID}
              title={`Unpaid Anticipated ${t('capitalizeAllWords', { val: 'expense' })}`}
              body={`One or more invoices contains an anticipated ${t(
                'expense',
              )} that has not yet been paid to the supplier. Are you sure you want to proceed?`}
              primaryButtonText="Pay"
            />
          )}

          <TrustToOfficeTable
            loading={loading}
            rows={rows}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            allInvoiceIds={allInvoiceIds}
            allMatterIds={allMatterIds}
            allExpanded={allExpanded}
            allSelected={allSelected}
            hasError={hasError}
            tableSummary={tableSummary}
            onSelectInvoices={onSelectInvoices}
            onToggleAllMattersExpanded={onToggleAllMattersExpanded}
            onToggleMatterExpanded={onToggleMatterExpanded}
            onToggleAutoAllocate={onToggleAutoAllocate}
            onClickLink={onClickLink}
            onChangePaymentAmount={onChangePaymentAmount}
          />
          <PaginationSelector
            className={Styles.paginationSection}
            hidePagination={hidePagination}
            numberOfPagesDisplayed={10}
            selectedPage={currentPage}
            totalNumberOfPages={totalNumberOfPages}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    );
  },
);

BulkTrustToOfficeRoute.displayName = 'BulkTrustToOfficeRoute';

BulkTrustToOfficeRoute.propTypes = {
  loading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  allMatterIds: PropTypes.array.isRequired,
  allInvoiceIds: PropTypes.array.isRequired,
  allExpanded: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  tableSummary: PropTypes.object.isRequired,
  filters: PropTypes.object,
  visibleFilterGroups: PropTypes.object,
  expanded: PropTypes.bool.isRequired,

  // pay button/modal
  disablePayButton: PropTypes.bool.isRequired,
  isTrustToOfficeModalVisible: PropTypes.bool.isRequired,
  paymentSummary: PropTypes.object.isRequired,

  // pagination
  hidePagination: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalNumberOfPages: PropTypes.number.isRequired,

  // filter options
  trustAccounts: PropTypes.array.isRequired,
  trustAccountsLoading: PropTypes.bool.isRequired,
  matterTypes: PropTypes.array,
  matterTypesLoading: PropTypes.bool.isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  staffMembersLoading: PropTypes.bool.isRequired,

  // callbacks & functions
  onFilterTrustAccount: PropTypes.func.isRequired,
  onFilterIssueDate: PropTypes.func.isRequired,
  onFilterBillingType: PropTypes.func.isRequired,
  onFilterMatterType: PropTypes.func.isRequired,
  onFilterMatterStatus: PropTypes.func.isRequired,
  onToggleFilterGroupVisibility: PropTypes.func.isRequired,
  onStaffChange: PropTypes.func.isRequired,
  onFilterTrustBalance: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onSelectInvoices: PropTypes.func.isRequired,
  onToggleAllMattersExpanded: PropTypes.func.isRequired,
  onToggleMatterExpanded: PropTypes.func.isRequired,
  onToggleAutoAllocate: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangePaymentAmount: PropTypes.func.isRequired,
  onPayButtonClick: PropTypes.func.isRequired,
  onPaymentsSubmitted: PropTypes.func.isRequired,
  onOpenTrustChequesModal: PropTypes.func.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  selectedMatterTypes: PropTypes.array,
};

BulkTrustToOfficeRoute.defaultProps = {
  matterTypes: [],
  filters: {},
  visibleFilterGroups: {},
  selectedMatterTypes: [],
};
