import React from 'react';
import { Button, Modal } from '@sb-itops/react';
import { Document } from 'types';
import Styles from './Modal.module.scss';

export interface IDeleteModalProps {
  onClose: () => void;
  onSubmit: () => void;
  formSubmitting: boolean;
  documents: Document[];
}

export const DeleteModal = (props: IDeleteModalProps) => {
  let text = 'Are you sure you would like to delete this file?';
  let title = 'Delete File';
  if (props.documents.length > 1) {
    text = 'Are you sure you would like to delete these files?';
    title = 'Delete Files';
  } else if (props.documents.some((doc) => doc.type === 'folder')) {
    text = 'Are you sure you would like to delete this folder?';
    title = 'Delete Folder';
  }
  return (
    <Modal
      className={Styles.modal}
      title={title}
      body={<p>{text}</p>}
      footer={
        <>
          <Button
            size="full-width"
            onClick={props.onSubmit}
            disabled={props.formSubmitting}
            locked={props.formSubmitting}
          >
            Delete
          </Button>
          <Button
            size="full-width"
            onClick={props.onClose}
            disabled={props.formSubmitting}
            locked={props.formSubmitting}
            type="secondary"
          >
            Cancel
          </Button>
        </>
      }
      onModalClose={props.onClose}
    />
  );
};
