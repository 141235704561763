import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { ContactBalanceAllocationModal } from './ContactBalanceAllocationModal';
import { initAllocations } from './init-allocations';

const hooks = () => ({
  useAllocations: ({ balances, multiPayments, invoiceTotals }) => {
    const [trustAllocations, setTrustAllocations] = useState(() =>
      initAllocations({ balances, multiPayments, accountType: bankAccountTypeEnum.TRUST }),
    );
    const [operatingAllocations, setOperatingAllocations] = useState(() =>
      initAllocations({ balances, multiPayments, accountType: bankAccountTypeEnum.OPERATING }),
    );
    const [creditAllocations, setCreditAllocations] = useState(() =>
      initAllocations({ balances, multiPayments, accountType: bankAccountTypeEnum.CREDIT }),
    );

    const totalAmount = trustAllocations
      .concat(operatingAllocations)
      .concat(creditAllocations)
      .reduce((acc, allocation) => acc + allocation.amount, 0);

    return {
      trustAllocations,
      onChangeTrustAllocations: setTrustAllocations,
      operatingAllocations,
      onChangeOperatingAllocations: setOperatingAllocations,
      creditAllocations,
      onChangeCreditAllocations: setCreditAllocations,
      totalAmount,
      hasError: invoiceTotals.total < totalAmount,
    };
  },
});

export const ContactBalanceAllocationModalContainer = composeHooks(hooks)(ContactBalanceAllocationModal);

ContactBalanceAllocationModalContainer.displayName = 'ContactBalanceAllocationModalContainer';

ContactBalanceAllocationModalContainer.propTypes = {
  preferredBankAccountTypes: PropTypes.array.isRequired,
  onChangeAllocations: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  invoiceTotals: PropTypes.object,

  balances: PropTypes.shape({
    TRUST: PropTypes.shape({
      contactBalances: PropTypes.array.isRequired,
    }),
    OPERATING: PropTypes.shape({
      contactBalances: PropTypes.array.isRequired,
    }),
    CREDIT: PropTypes.shape({
      contactBalances: PropTypes.array.isRequired,
    }),
  }).isRequired,
  multiPayments: PropTypes.array,
};

ContactBalanceAllocationModalContainer.defaultProps = {
  invoiceTotals: undefined,
  multiPayments: [],
};
