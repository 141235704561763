import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Styles from './CreditCardEntryGroupFields.module.scss';
import CreditCardEntryGroup from './CreditCardEntryGroup';

const CreditCardEntryGroupFields = ({ creditCardNumberError, securityCodeError, ...props }) => (
  <div
    className={classnames(
      Styles.creditCardGroupField,
      creditCardNumberError && Styles.creditCardNumberError,
      securityCodeError && Styles.securityCodeError,
    )}
  >
    <CreditCardEntryGroup {...props} />
  </div>
);

CreditCardEntryGroupFields.propTypes = {
  creditCardNumberError: PropTypes.bool,
  securityCodeError: PropTypes.bool,
  // this is just so it works with angular
  publicKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  uniqueIdentifier: PropTypes.string,
};

CreditCardEntryGroupFields.defaultProps = {
  creditCardNumberError: false,
  securityCodeError: false,
  // this is just so it works with angular
  onCompleted: () => {},
  uniqueIdentifier: undefined,
};

CreditCardEntryGroupFields.displayName = 'CreditCardEntryGroupFields';

export default CreditCardEntryGroupFields;
