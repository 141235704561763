import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyDisplay } from '@sb-itops/react';
import { PaymentPlanInclusionsTable } from '../payment-plan-inclusions-table';
import Styles from './PaymentPlanInclusions.module.scss';

const PaymentPlanInclusions = memo(({ matters, inclusions, onValueChange }) => {
  const totals = matters.reduce(
    (acc, matter) => {
      acc.due += matter.due || 0;
      acc.included += inclusions[matter.matterId] ? matter.dueForThisDebtorOnly || 0 : 0;
      return acc;
    },
    {
      due: 0,
      included: 0,
    },
  );

  const onSelectMatterHandler = useCallback(
    (selectedMatter) => {
      const matterId = selectedMatter.matterId;
      const inclusionsListOld = inclusions || {};
      const newInclusionsMap = {
        ...inclusionsListOld,
        [matterId]: !inclusionsListOld[matterId],
      };
      onValueChange('inclusions', newInclusionsMap);
    },
    [inclusions, onValueChange],
  );

  const onSelectAllHandler = useCallback(
    (selected) => {
      if (matters) {
        const newInclusionsMap = matters.reduce((acc, matter) => {
          acc[matter.matterId] = matter.canIncludeInPaymentPlan && selected;
          return acc;
        }, {});

        onValueChange('inclusions', newInclusionsMap);
      }
    },
    [matters, onValueChange],
  );

  return (
    <fieldset className={Styles.paymentPlanInclusions}>
      <div className={Styles.tableContainer}>
        <div className={Styles.totals}>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Due</label>
            <CurrencyDisplay amount={totals.due} />
          </div>
          <div className={classnames(Styles.total, Styles.lastTotal, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Included</label>
            <CurrencyDisplay amount={totals.included} />
          </div>
        </div>
        <span className={Styles.infoLabel}>
          New invoices from the selected matters will be automatically added to the plan.
        </span>
        <PaymentPlanInclusionsTable
          matters={matters}
          inclusions={inclusions}
          onSelectMatter={onSelectMatterHandler}
          onSelectAll={onSelectAllHandler}
        />
      </div>
    </fieldset>
  );
});

PaymentPlanInclusions.propTypes = {
  /**
   * Matters list to be shown to user
   */
  matters: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string.isRequired,
      due: PropTypes.number.isRequired, // total amount due on the matter for associated debtor, including both single and multi-debtor invoices
      dueForThisDebtorOnly: PropTypes.number.isRequired, // total amount due on the matter for this debtor, not including multi-debtor invoices
      canIncludeInPaymentPlan: PropTypes.bool.isRequired, // matter can be included in payment plan, i.e. has only unpaid single debtor invoices
    }),
  ),

  /**
   * Key, Value pair for the matterIds that are to be selected. eg: { matterId1 : true / false }.
   */
  inclusions: PropTypes.object,

  /**
   * Callback function to be called when there is change in the matter selection
   */
  onValueChange: PropTypes.func.isRequired,
};

PaymentPlanInclusions.defaultProps = {
  matters: [],
  inclusions: {},
};

PaymentPlanInclusions.displayName = 'PaymentPlanInclusions';

export default PaymentPlanInclusions;
