import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { sortByOrder } from 'lodash';
import Styles from './EndOfMonthReportsTable.module.scss';

const { yyyymmddLocalisedRenderer } = utils;

const sortBy = 'createdDate';

const rowTypes = {
  pending: 'InProgress',
  error: 'Failed',
  success: 'Completed',
};

const rowStyle = ({ rowData }) => {
  if (!rowData || (rowData && rowData.status === rowTypes.success)) {
    return '';
  }
  if (rowData && rowData.status === rowTypes.error) {
    return Styles.endOfMonthRowError;
  }
  return Styles.endOfMonthRowPending;
};

export function textCellWithStatusRenderer({ cellData, rowData }) {
  if (rowData.status === rowTypes.success) {
    return cellData;
  }
  if (rowData.status === rowTypes.error) {
    return (
      <div title={rowData.errorMessage}>
        {cellData} - <span>FAILED</span>
      </div>
    );
  }
  // as default put it in pending status
  return `${cellData} - PENDING`;
}

const EndOfMonthReportsTable = memo(({ reports, onRowClick, sortDirection, onSortDirectionChange }) => {
  const onSort = useCallback(
    (sortProps) => {
      onSortDirectionChange(sortProps.sortDirection);
    },
    [onSortDirectionChange],
  );

  const reportsSorted = useMemo(
    () => sortByOrder(reports, [sortBy, 'lastUpdated'], [sortDirection]),
    [reports, sortDirection],
  );
  const onRowClickHandler = useCallback(
    ({ rowData }) => {
      if (rowData.status && rowData.status !== rowTypes.pending && rowData.status !== rowTypes.error) {
        onRowClick({ report: rowData });
      }
    },
    [onRowClick],
  );

  return (
    <Table
      className={Styles.endOfMonthTable}
      onRowClick={onRowClickHandler}
      list={reportsSorted}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      rowClassName={rowStyle}
    >
      <Column dataKey="createdDate" label="Date" cellRenderer={yyyymmddLocalisedRenderer} flexGrow={2} />
      <Column
        dataKey="description"
        label="Description"
        cellRenderer={textCellWithStatusRenderer}
        flexGrow={6}
        disableSort
      />
      <Column dataKey="createdBy" label="Created By" flexGrow={2} disableSort />
    </Table>
  );
});

EndOfMonthReportsTable.displayName = 'EndOfMonthReportsTable';

EndOfMonthReportsTable.propTypes = {
  reports: PropTypes.array,
  onRowClick: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  onSortDirectionChange: PropTypes.func.isRequired,
};

EndOfMonthReportsTable.defaultProps = {
  reports: [],
  sortDirection: 'asc',
};

export default EndOfMonthReportsTable;
