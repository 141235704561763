import * as React from 'react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('usePrintFeesPdf');

export function usePrintFeesPdf() {
  const [isLoadingFeesPdf, setIsLoadingFeesPdf] = React.useState(false);

  async function printFeesPdf({ feeFilters }) {
    try {
      setIsLoadingFeesPdf(true);
      const response = await fetchPostP({
        path: '/billing/fees/export/:accountId/',
        fetchOptions: { body: JSON.stringify({ fileFormat: 'pdf', ...feeFilters }) },
      });
      onFeesPrintDialogOpen(response.body.feeFile);
    } catch (error) {
      log.error('Failed to export fees due to error: ', error);
      displayErrorToUser('Failed to export fees. Please try again later.');
    } finally {
      setIsLoadingFeesPdf(false);
    }
  }

  return {
    isLoadingFeesPdf,
    printFeesPdf,
  };
}

function onFeesPrintDialogOpen(feeFile) {
  const id = 'feePdf';
  const iframe = document.frames ? document.frames[id] : document.getElementById(id);
  if (iframe) {
    const iframeDocument = iframe.contentWindow.document;
    iframeDocument.open('text/html', 'replace');
    iframeDocument.write(feeFile);
    iframeDocument.close();
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 0);
  }
}
