import React, { DragEventHandler, useState } from 'react';
import { getLogger } from '@sb-itops/fe-logger';

import { TRelativePosition, TWidget } from '../types';
import { AssemblyDropzone } from './AssemblyDropzone';

const log = getLogger('ConfigureMatterNumbering.AssemblyDropzone.container');

type TAssemblyDropzoneContainerProps = {
  installedWidgets: TWidget[];
  addWidget: (widget: TWidget, index: number) => void;
  moveWidget: (widget: TWidget, index: number) => void;
  deleteWidget: (widget: TWidget) => void;
  selectWidget: (instanceId?: string) => void;
  selectedWidgetId?: string;
};

export const AssemblyDropzoneContainer = ({
  installedWidgets,
  addWidget,
  moveWidget,
  deleteWidget,
  selectWidget,
  selectedWidgetId,
}: TAssemblyDropzoneContainerProps) => {
  const [dragPos, setDragPos] = useState<{ dragoverX: number }>();
  const [placeholderPos, setPlaceholderPos] = useState<number>();
  const [dragInstanceId, setDragInstanceId] = useState<string>();

  const clearDragFields = () => {
    setDragPos(undefined);
    setPlaceholderPos(undefined);
    setDragInstanceId(undefined);
  };

  const updatePlaceholderPosition = (reportingWidgetId: string | undefined, relativePosition: TRelativePosition) => {
    if (placeholderPos === undefined || !reportingWidgetId) {
      return;
    }

    const widgetPos = installedWidgets.findIndex((v) => v.instanceId === reportingWidgetId);

    if (widgetPos === -1) {
      return;
    }

    switch (relativePosition) {
      case 'left': {
        if (placeholderPos > widgetPos) {
          setPlaceholderPos(widgetPos);
        }
        break;
      }
      case 'right': {
        if (placeholderPos < widgetPos + 1) {
          setPlaceholderPos(widgetPos + 1);
        }
        break;
      }
      default: {
        throw new Error(`unhandled relativePosition: ${relativePosition}`);
      }
    }
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (ev) => {
    clearDragFields();
    try {
      const data = ev.dataTransfer.getData('ConfigureMatterNumbering/Widget');
      const { label, widgetId, instanceId, ...rest } = JSON.parse(data) as TWidget;

      if (label && widgetId) {
        if (!instanceId) {
          addWidget({ label, widgetId, ...rest }, placeholderPos || 0);
        } else {
          moveWidget({ label, widgetId, instanceId, ...rest }, placeholderPos || 0);
        }
      }
    } catch (e) {
      log.warn(`drop event failed because ${e}`);
    }
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (ev) => {
    if (typeof placeholderPos !== 'number') {
      setPlaceholderPos(0);
    }
    ev.preventDefault();
    setDragPos({ dragoverX: ev.clientX });
    // eslint-disable-next-line no-param-reassign
    ev.dataTransfer.dropEffect = dragInstanceId ? 'move' : 'copy';
  };

  const onDragLeave: DragEventHandler<HTMLDivElement> = (ev) => {
    // eslint-disable-next-line no-param-reassign
    ev.dataTransfer.dropEffect = 'none';
    const found = (dragInstanceId && installedWidgets.find((w) => w.instanceId === dragInstanceId)) || undefined;
    if (found) {
      deleteWidget(found);
    }
    clearDragFields();
  };

  const getRenderableWidgets = () => {
    const widgets = [...installedWidgets];

    // insert placeholder if we have one
    if (placeholderPos !== undefined) {
      widgets.splice(placeholderPos, 0, { label: '', widgetId: 'placeholder', sbEntityType: '' });
    }

    // if we are moving a widget, filter it out
    if (dragPos && dragInstanceId) {
      return widgets.filter((w) => w.instanceId !== dragInstanceId);
    }
    return widgets;
  };

  return (
    <AssemblyDropzone
      {...{
        onDragLeave,
        onDragOver,
        onDrop,
        setDragInstanceId,
        selectWidget,
        selectedWidgetId,
        updatePlaceholderPosition,
        widgets: getRenderableWidgets(),
        dragoverX: dragPos?.dragoverX,
      }}
    />
  );
};
