/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { validateMultiple as isValidEmailList, validateSingle as validateSingleEmail } from '@sb-itops/email';

export const debtorCommunicateDataSchema = Yup.object().shape({
  toAddress: Yup.string().when('debtorType', {
    is: (type) => type === 'Person' || type === 'Organisation' || type === 'Staff',
    then: Yup.string()
      .required()
      .test('is-single-email', '${path} is not a valid email', (value) => validateSingleEmail(value)),
    otherwise: Yup.string()
      .required()
      .test('is-email-list', '${path} is not a valid email list', (value) => isValidEmailList(value)),
  }),
  fromUserId: Yup.string().required(),
  message: Yup.string().required(),
});
