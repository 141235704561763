'use strict';

function convertSettingsForGQL({ formattedSettings }) {
  return formattedSettings;
}

function convertSettingsFromGQL({ formattedSettingsFromGQL }) {
  return formattedSettingsFromGQL;
}

module.exports = {
  convertSettingsForGQL,
  convertSettingsFromGQL,
};
