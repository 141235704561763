import { getRegion, regionType } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { store } from '@sb-itops/redux';
import { type as BALANCE_TYPE } from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { getSettings } from './index';
import { saveSettings } from './save-settings';

export const initialiseBankAccountSettings = (accountId) => {
  let bankAccountSettings = getSettings();
  if (!bankAccountSettings) {
    bankAccountSettings = {
      accountId,
      bankBalanceType: [regionType.AU, regionType.GB].includes(getRegion())
        ? BALANCE_TYPE.matter
        : BALANCE_TYPE.matterContact,
      createPDFReceiptOnDeposit: hasFacet(facets.createPDFReceiptOnDepositDefaultSettingTrue),
      createPDFReceiptOnTrustPayment: false,
      createPDFReceiptOnOperatingPayment: false,
      restrictTrustAccountEdit: getBankAccountStrategy(
        getRegion(),
        bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES,
      ),
      restrictOperatingAccountEdit: getBankAccountStrategy(
        getRegion(),
        bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES,
      ),
    };

    store.dispatch(saveSettings(bankAccountSettings));
  }
  return bankAccountSettings;
};
