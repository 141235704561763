import { gql } from '@apollo/client';

const query = gql`
  query DraftInvoiceCommunicateModalData($debtorIds: [ID], $feeIds: [ID], $matterId: ID!) {
    contacts(ids: $debtorIds) {
      id
      customLetterName
      displayNameFirstLast
      email
      firstName
      lastName
      letterNameFormat
      people {
        id
        email
        firstName
        lastName
        letterNameFormat
        title
        type
      }
      title
      type
    }
    fees(ids: $feeIds) {
      id
      duration
      feeType
    }
    firmEInvoiceSettings {
      id
      eInvoiceDisabled
    }
    firmUsersList {
      id
      users {
        id
        person {
          name
        }
      }
    }
    matter(id: $matterId) {
      id
      matterInvoiceSettings {
        id
        eInvoiceEnabledOption
        payButtonEnabledOption
      }
    }
  }
`;

const notificationIds = [
  'CustomerManagementWebQuery.EntityUpdated',
  'FeesNotifications.AccountFeesUpdated',
  'FirmManagementNotifications.FirmUsersUpdated', // Adding firm user
  'FirmManagementNotifications.PersonUpdated', // Updating firm user
  'InvoicingNotifications.EInvoiceSettingsUpdated',
  'InvoicingNotifications.InvoiceUpdated',
  'InvoicingNotifications.MatterInvoiceSettingsUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoiceCommunicateModalData = {
  query,
  notificationIds,
};
