'use strict';

const { Cent } = require('@sb-itops/money');
const { TaxStrategy } = require('./tax-strategy');

class NoTaxStrategy extends TaxStrategy {
  calculate(money) {
    super.calculate(money);

    return {
      amountExcTax: money,
      tax: new Cent(0),
    };
  }
}

module.exports = { NoTaxStrategy };
