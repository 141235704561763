import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const defaultInterval = 6;

const api = cacheFactory({
  domain,
  name: 'fee-configuration',
  keyPath: 'accountId',
  ngCacheName: 'sbFeeConfigurationService',
  syncType: syncTypes.SINCE,
  immutable: false,
  changesetProcessor: ({ entities, stateList }) => {
    // no new data
    if (!entities[0]) {
      return [];
    }

    // nothing in cache
    if (!stateList[0]) {
      return entities;
    }

    // server version includes latest in cache
    if (entities[0].versionIds.includes(stateList[0].versionId)) {
      return entities;
    }

    return [];
  },
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getFeeInterval = () => {
  const feeConfig = getList()[0];
  return !feeConfig ? defaultInterval : feeConfig.billableMinutes;
};

export { getAllowedIntervals } from './get-allowed-intervals';
