import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import * as billsListFilters from 'web/redux/route/home-billing-bills-list';
import { InvoiceTable } from '../../components/invoice-table';

const mapStateToProps = (state) => {
  const { getFilters } = billsListFilters.selectors;
  const filters = getFilters(state);
  return { invoiceStatuses: filters.invoiceStatuses };
};

const BillsListRouteInvoiceTable = withReduxStore(connect(mapStateToProps, {})(InvoiceTable));

BillsListRouteInvoiceTable.propTypes = {
  invoices: PropTypes.array,
  displayMode: PropTypes.string,
  sort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  summary: PropTypes.object,
  sendEmail: PropTypes.func,
  onSelectInvoice: PropTypes.func,
  onSelectAllInvoices: PropTypes.func,
  selectedInvoices: PropTypes.object,
  onClickLink: PropTypes.func,
  onSendEmailFromIndicatorIcon: PropTypes.func,
  onSendCommunicateFromIndicatorIcon: PropTypes.func,
  onMarkAsSentFromIndicatorIcon: PropTypes.func,
  showDebtor: PropTypes.bool,
  showMatter: PropTypes.bool,
};

export default BillsListRouteInvoiceTable;
