angular.module('sb.billing.webapp').component('sbAuiButton',
  {
    templateUrl: 'ng-composable-components/action-ui/button/aui-button.html',
    transclude: true,
    bindings: { actionParams: '<?', disabled: '<?', buttonClass: '@?', autoRun: '<?' },
    require: { group: '^sbComposeGroup' },
    controller: function($timeout) {
      const that = this;
      
      that.onClick = onClick;
      that.disable = disable;

      that.$onInit = () => {
        if (that.autoRun) {
          $timeout(that.group.doAction.bind(that, that.actionParams));
        }
      };

      /**
       * DO NOT REMOVE THIS
       * It is used in trust-cheque-print-statistic >:(
       **/
      function disable(value) {
        if (value === true) {
          that.disabled = value;
        } else {
          delete that.disabled;
        }
      }

      async function onClick () {
        try {
          !that.group.locked && await that.group.doAction(that.actionParams);
        } catch (err) {
          // soak the error here as there no where else for the exception to go
          // log.error((err && err.message) || err);
        }
      }
    }
  }
);
