import { getAccountId } from 'web/services/user-session-management';
import {
  getById as getSubscriptionByAccountId,
  opdateCache as opdateSubscription,
} from '@sb-finance/redux/subscription';

export const onSetSubscriptionPaymentMethod = ({ commandResult }) => {
  const subscription = getSubscriptionByAccountId(getAccountId());
  const subscriptionForOpdate = { ...subscription };
  subscriptionForOpdate.externalPaymentMethodIds = [commandResult.paymentMethodId];
  opdateSubscription({ optimisticEntities: [subscriptionForOpdate] });
};
