import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getById,
} from '@sb-billing/redux/customer-billing-configuration';

angular.module('@sb-billing/services').service('sbCustomerBillingConfigurationService', function (sbGenericCacheService, sbEndpointType, sbLoggerService) {

    const that = this;
    const log = sbLoggerService.getLogger('sbCustomerBillingConfigurationService');
    const basePath = 'billing/customer-billing-configuration';

    sbGenericCacheService.setupCache({
      name: 'sbCustomerBillingConfigurationService',
      sync: {
        endpoint: {
          type: sbEndpointType.SYNC_SINCE,
          stub: basePath
        },
        poll: 60,
        subscriptions: 'notifier.BillingSharedNotifications.CustomerBillingConfigurationUpdated'
      },
      updateRedux,
      clearRedux,
    });

    that.getByEntityId = getByEntityId;

    function getByEntityId(entityId) {
      const customerLedes = getById(entityId) || {};
      log.info(`getByEntityId ${entityId}`, customerLedes);
      return customerLedes;
    }
  });
