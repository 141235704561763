import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react';
import { subscriptionStatusByType } from '@sb-finance/business-logic/subscription/entities/constants';

import Styles from './UpdatePaymentMethodComplete.module.scss';

const { TRIALING, ACTIVE, PAST_DUE, UNPAID } = subscriptionStatusByType;

export const UpdatePaymentMethodComplete = ({
  subscriptionStatus,
  onAcknowledgePaymentMethodUpdated,
  acknowledgeButtonLabel,
}) => {
  let title = 'Payment Details Updated!';
  let subtitle;
  let subtitle2;
  if (subscriptionStatus === TRIALING) {
    subtitle = `We won't charge your card until your trial completes, and so for now we hope you enjoy Smokeball!`;
    subtitle2 = `Please reach out to us if you have any questions, or if there is anything we can help you with.`;
  } else if (subscriptionStatus === PAST_DUE) {
    title = `Subscription Successful!`;
    subtitle = `Please reach out to us if you have any questions, or if there is anything we can help you with.`;
  } else {
    // this generic message is suitable for all cases, customisations above is an attempt to make the messaging smoother
    subtitle = `You payment details have now been updated. Please reach out to us if you have any questions, or if there is anything we can help you with.`;
  }

  return (
    <div className={Styles.updatePaymentMethodComplete}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.subtitle}>
        <p> {subtitle} </p>
        {subtitle2 && <p> {subtitle2}</p>}
      </div>
      <div className={Styles.buttonSection}>
        <Button onClick={onAcknowledgePaymentMethodUpdated}>{acknowledgeButtonLabel}</Button>
      </div>
    </div>
  );
};

UpdatePaymentMethodComplete.displayName = 'UpdatePaymentMethodComplete';

UpdatePaymentMethodComplete.propTypes = {
  subscriptionStatus: PropTypes.oneOf([TRIALING, ACTIVE, PAST_DUE, UNPAID]),
  onAcknowledgePaymentMethodUpdated: PropTypes.func.isRequired,
  acknowledgeButtonLabel: PropTypes.string,
};

UpdatePaymentMethodComplete.defaultProps = {
  subscriptionStatus: undefined,
  acknowledgeButtonLabel: 'Close',
};
