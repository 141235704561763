import { graphql } from '../types';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

export const ContactSearchProductivityDocument = graphql(`
  query ContactSearchProductivity(
    $filter: CustomerSearchProductivityInput
    $offset: Int
    $limit: Int
    $searchText: String!
  ) {
    contactSearchProductivity(filter: $filter, offset: $offset, limit: $limit, searchText: $searchText) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        id
        displayName
        email
        phone
        phone2
        cell
      }
    }
  }
`);
