import { useEffect } from 'react';

// see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export const useOutsideAlerter = (ref, onChange, ids) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        // let the calendar button/icon handler toggle the calendar
        // otherwise, the calendar would be closed here and then opened by the button/icon
        !ids.includes(event.target.id)
      ) {
        onChange({ show: undefined });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ids, ref, onChange]);
};
