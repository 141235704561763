/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

// we are not going to have opdates so far on the cache. We will see if we need some sort of indicator at the end of the feature.
const api = cacheFactory({
  domain,
  name: 'payment-plans',
  keyPath: 'id',
  ngCacheName: 'sbPaymentPlansService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export * from './save-payment-plan';

export * from './close-payment-plan';
