'use strict';

/**
 * Returns unique trust account ids from a given list of transactions
 * @param {Array.<{ bankAccountType: string, bankAccountId: string }>} transactions
 * @param {func} getBankAccountById
 * @returns {Array.<string>} trust account ids
 */
const getUniqueTrustAccountsFromTransactions = ({ transactions, getBankAccountById }) => {
  if (!transactions) {
    return [];
  }

  const uniqueTrustAccountIds = Array.from(
    transactions.reduce((acc, txn) => {
      if (txn.bankAccountType && txn.bankAccountType.toUpperCase() === 'TRUST') {
        acc.add(txn.bankAccountId);
      }
      return acc;
    }, new Set()),
  );

  return uniqueTrustAccountIds.map((id) => getBankAccountById(id));
};
module.exports = {
  getUniqueTrustAccountsFromTransactions,
};
