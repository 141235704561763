/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import { Spinner, useTranslation } from '@sb-itops/react';
import React from 'react';
import classNames from 'classnames';
import { cleanHtml } from '@sb-itops/html';
import moment from 'moment';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './MemosWidget.module.scss';

export const MemosWidget = ({ isEditing, memos, onClickLink, loading, onChange, onRemove, settings, matterId }) => {
  const { t } = useTranslation();

  const getTimestampDisplay = (d) => {
    const diff = moment().startOf('day').diff(moment(d), 'days');

    if (diff === 0) {
      return <div className={WidgetStyles.bold}>Today</div>;
    }
    if (diff === 1) {
      return <div>Yesterday</div>;
    }
    if (diff > 1) {
      return <div>{t('date', { ts: d })}</div>;
    }
    return null;
  };

  return (
    <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
      <WidgetTitle
        isEditing={isEditing}
        onClick={() => onClickLink({ type: 'matterMemos', id: matterId })}
        onChange={onChange}
        onRemove={onRemove}
        settings={settings}
      />
      {loading ? (
        <div className={WidgetStyles.loading}>
          <Spinner small />
        </div>
      ) : (
        <div className={WidgetStyles.content}>
          {memos.length === 0 && 'There are no memos on this matter'}
          {memos.map((memo) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onClickLink({ type: 'matterMemos', id: matterId, params: [memo.id] });
              }}
              key={memo.id}
              className={Styles.memo}
            >
              <div className={Styles.description}>
                <div className={Styles.subject}>{memo.title}</div>
                <div
                  className={Styles.preview}
                  dangerouslySetInnerHTML={{
                    __html: cleanHtml({ dirtyHtml: memo.html, ensureAllowedTags: ['u', 'span'] }),
                  }}
                />
                <div className={Styles.staff}>
                  {!!memo.createdByStaff?.colorFill && (
                    <div
                      className={Styles.staffColor}
                      style={{ backgroundColor: `${memo.createdByStaff.colorFill}` }}
                    />
                  )}
                  {memo.createdByStaff?.name}
                </div>
              </div>
              <div className={classNames(Styles.time, WidgetStyles.alignRight)}>
                {getTimestampDisplay(memo.createdDate)}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={WidgetStyles.separator} />
      <a className={WidgetStyles.footerAction} onClick={() => onClickLink({ type: 'matterMemos', id: matterId })}>
        ADD MEMO
      </a>
    </div>
  );
};
