import { getRegion } from '@sb-itops/region';

const REGION = getRegion();

const REFERRAL_TYPES = {
  GB: [
    '',
    'Accountant',
    'Builder',
    'Direct',
    'Existing Client',
    'Facebook',
    'Financial Institution',
    'Flyer',
    'Friend',
    'Lead Generator',
    'Mortgage Broker',
    'Networking Group',
    'Newspaper',
    'Other',
    'Previous Client',
    'Radio',
    'Real Estate Agent',
    'Solicitor',
    'Staff Member',
    'Surveyor',
    'Walk-in',
    'Web',
    'Word of Mouth',
    'Yellow Pages',
    'Social Media',
    'Developer',
    'Barrister',
    'LinkedIn',
    'Google',
    'Instagram',
    'Financial Planner',
    'Purchaser’s Advocate',
    'Vendor’s Advocate',
  ],
  AU: [
    '',
    'Accountant',
    'Builder',
    'Direct',
    'Existing Client',
    'Facebook',
    'Financial Institution',
    'Flyer',
    'Friend',
    'Lead Generator',
    'Mortgage Broker',
    'Networking Group',
    'Newspaper',
    'Other',
    'Previous Client',
    'Radio',
    'Real Estate Agent',
    'Solicitor',
    'Staff Member',
    'Surveyor',
    'Walk-in',
    'Web',
    'Word of Mouth',
    'Yellow Pages',
    'Social Media',
    'Developer',
    'Barrister',
    'LinkedIn',
    'Google',
    'Instagram',
    'Financial Planner',
    'Purchaser’s Advocate',
    'Vendor’s Advocate',
  ],
  US: [
    '',
    'Accountant',
    'Agent',
    'Bar Association',
    'Court',
    'Direct',
    'Existing Client',
    'Financial Planner',
    'Flyer',
    'Previous Client',
    'Radio Ad',
    'Web',
    'Word of Mouth',
    'Yellow Pages',
    'Other',
    'Attorney',
    'Friend',
    'Loan Officer',
    'Mortgage Broker',
    'Networking Group',
    'Seminar',
    'Walk-in',
    'Billboard',
    'Website Form Inquiry',
    'Medical Provider',
    'Facebook',
    'Lead Generator',
    'Internal Employee',
  ],
};

export const referralTypeOptions = REFERRAL_TYPES[REGION].map((s) => ({ value: s, label: s || 'Select ...' }));
