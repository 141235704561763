import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { yupSchemaValidator } from '@sb-itops/business-logic/validation/services';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withReduxStore } from '@sb-itops/react';
import { getLoggedInUserId, getCurrentUsers } from '@sb-firm-management/redux/firm-management';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { getContactDisplay, getById as getContactSummaryById } from '@sb-customer-management/redux/contacts-summary';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getById as getMatterInvoiceSettings } from '@sb-billing/redux/matter-invoice-settings';
import { getById as getEInvoiceSettings } from '@sb-billing/redux/einvoice-settings';
import { determineEInvoiceEnabled } from '@sb-billing/business-logic/einvoice';
import { appendPaymentAndEInvoicePlaceholders } from '@sb-billing/business-logic/invoice-via-communicate';
import { getAccountId } from 'web/services/user-session-management';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { debtorCommunicateDataSchema } from './invoice-communicate-tab-per-debtor-form-schemas';
import { InvoiceCommunicateTabPerDebtorForm } from './InvoiceCommunicateTabPerDebtorForm';
import { defaultCommunicateMessage } from '../invoice-communicate-form';

const mapStateToProps = (state, { scope, invoiceId, onPreview }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields } = formSelectors.getFormState(state);

  if (!formInitialised) {
    return { isLoading: true };
  }

  const { selectedTabId, communicateData = {} } = formFields;

  const userOptions = getCurrentUsers().map((user) => ({
    label: user.name,
    value: user.userId,
  }));

  const tabs = Object.entries(communicateData).map(([debtorId, debtorCommunicateData]) => ({
    text: getContactDisplay(debtorId),
    id: debtorId,
    debtorIncluded: communicateData[debtorId].includeDebtor.value,
    tabValid: Object.values(debtorCommunicateData).every((field) => field.isValid),
  }));

  return {
    isLoading: !formInitialised,
    selectedTabId: selectedTabId.value,
    tabs,
    formData: communicateData[selectedTabId.value],
    userOptions,
    onPreviewId: (selectedDebtorId) => {
      const [invoiceCommunicateRequest] = convertToInvoiceCommunicateRequests({
        state,
        scope,
        invoiceIds: [invoiceId],
        debtorIds: [selectedDebtorId],
        selectedDebtorId,
      });
      return onPreview(invoiceCommunicateRequest);
    },
  };
};

const mapDispatchToProps = (dispatch, { scope, debtorIds }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(prepareFormData({ debtorIds, formActions, formOperations }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onSelectedDebtorChanged: (selectedTabId) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { selectedTabId } }));
    },
    onFormDataUpdated: (debtorId, field, value) => {
      const fieldValues = {
        communicateData: {
          [debtorId]: { [field]: value },
        },
      };

      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateForm({ validateFn }));
    },
  };
};

const prepareFormData =
  ({ debtorIds, matterId, formActions, formOperations }) =>
  async (dispatch) => {
    const debtorsInformationPromises = debtorIds.map(async (debtorId) => {
      const toAddress = await buildAddressInformationP({ debtorId });
      const { debtorType, debtorFirstName, debtorLastName, debtorDisplayName } = buildDebtorInformationP({ debtorId });
      const isToAddressReadOnly =
        !!toAddress || (debtorType === 'Organisation' && !toAddress) || (debtorType === 'GroupOfPeople' && !toAddress);

      return {
        debtorId,
        toAddress,
        isToAddressReadOnly,
        debtorType,
        debtorFirstName,
        debtorLastName,
        debtorDisplayName,
      };
    });
    const debtorsInformation = await Promise.all(debtorsInformationPromises);

    const accountId = getAccountId();
    const matterInvoiceSettings = getMatterInvoiceSettings(matterId);
    const eInvoiceSettings = getEInvoiceSettings(accountId);
    const eInvoiceEnabled =
      featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch)
        ? determineEInvoiceEnabled({ matterInvoiceSettings, eInvoiceSettings })
        : featureActive('BB-5725');

    const communicateMessage = appendPaymentAndEInvoicePlaceholders({
      message: defaultCommunicateMessage,
      isMultiDebtorInvoice: true,
      eInvoiceEnabled,
    });
    const loggedInUserId = getLoggedInUserId() || '';

    const fieldValues = debtorsInformation.reduce(
      (
        acc,
        { debtorId, toAddress, isToAddressReadOnly, debtorType, debtorFirstName, debtorLastName, debtorDisplayName },
      ) => {
        acc.communicateData[debtorId] = {
          includeDebtor: true,
          toAddress,
          fromUserId: loggedInUserId,
          message: communicateMessage,
          isToAddressReadOnly,
          debtorType,
          debtorFirstName,
          debtorLastName,
          debtorDisplayName,
        };

        return acc;
      },
      {
        selectedTabId: debtorsInformation[0]?.debtorId,
        communicateData: {},
      },
    );

    dispatch(formActions.initialiseForm({ fieldValues }));
    dispatch(formOperations.validateForm({ validateFn }));
  };

const buildAddressInformationP = async ({ debtorId }) => {
  try {
    const people = await fetchPeopleP(debtorId);
    return formatContactsEmails(people);
  } catch (err) {
    // No email address for debtor.
    return '';
  }
};

const buildDebtorInformationP = ({ debtorId }) => {
  try {
    const people = getContactSummaryById(debtorId);
    return {
      debtorType: people.type,
      debtorFirstName: people.firstName,
      debtorLastName: people.lastName,
      debtorDisplayName: people.displayName,
    };
  } catch (err) {
    return {};
  }
};

const formatDebtorFirstAndLastName = ({ debtorCommunicateData }) => {
  let debtorFirstName;
  let debtorLastName;

  // When the email address is missing for a debtor, we set both name as ''
  // For Person/Staff, we use their own first/last name
  // For Organisation/GroupOfPeople, we use debtorDisplayName as first name and leave last name as blank
  if (!debtorCommunicateData.isToAddressReadOnly) {
    debtorFirstName = '';
    debtorLastName = '';
  } else {
    debtorFirstName =
      debtorCommunicateData.debtorType === 'Person' || debtorCommunicateData.debtorType === 'Staff'
        ? debtorCommunicateData.debtorFirstName
        : debtorCommunicateData.debtorDisplayName;
    debtorLastName =
      debtorCommunicateData.debtorType === 'Person' || debtorCommunicateData.debtorType === 'Staff'
        ? debtorCommunicateData.debtorLastName
        : '';
  }

  return { debtorFirstName, debtorLastName };
};

const validateFn = (formFields) => {
  const communicateDataErrors = Object.entries(formFields.communicateData).reduce(
    (acc, [debtorId, debtorCommunicateData]) => {
      if (debtorCommunicateData.includeDebtor) {
        const errors = yupSchemaValidator(debtorCommunicateData, debtorCommunicateDataSchema);
        if (Object.keys(errors).length) {
          acc[debtorId] = errors;
        }
      }
      return acc;
    },
    {},
  );

  // Forms 2 expects errors to be reported as flattened dot object notation.
  return Object.keys(communicateDataErrors).length
    ? nestedObjectToFlattened({ communicateData: communicateDataErrors })
    : {};
};

export const convertToInvoiceCommunicateRequests = ({ state, scope, invoiceIds, selectedDebtorId }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);

  const formFields = formSelectors.getFieldValues(state);

  return Object.entries(formFields.communicateData).reduce((acc, [debtorId, debtorCommunicateData]) => {
    if (!debtorCommunicateData.includeDebtor || (selectedDebtorId && debtorId !== selectedDebtorId)) {
      // If the debtor is not included in the send (user toggle), or
      // we only want to process the selected debtor (eg, previewing email)
      return acc;
    }
    const { debtorFirstName, debtorLastName } = formatDebtorFirstAndLastName({ debtorCommunicateData });

    acc.push({
      invoiceIds,
      debtorId,
      template: {
        toAddress: debtorCommunicateData.toAddress,
        message: debtorCommunicateData.message,
        fromUserId: debtorCommunicateData.fromUserId,
        debtorFirstName,
        debtorLastName,
      },
    });

    return acc;
  }, []);
};

export const InvoiceCommunicateTabPerDebtorFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ onPreviewId, selectedTabId, ...props }) => {
      const [previewStateByTabId, setPreviewStateByTabId] = useState({});

      // onPreviewId is not present on the first render, so we would like to run this exactly once, but only after onPreviewId is present
      useEffect(
        () => {
          if (onPreviewId) {
            (async () => {
              // Load the preview data for all the debtors
              const previewData = await Promise.all(
                props.debtorIds.map(async (debtorId) => {
                  const { message } = await onPreviewId(debtorId);
                  return {
                    debtorId,
                    previewMessage: message,
                    isPreviewMode: true,
                  };
                }),
              );
              const previewState = previewData.reduce((acc, data) => {
                acc[data.debtorId] = data;
                return acc;
              }, {});
              setPreviewStateByTabId(previewState);
            })();
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [!onPreviewId],
      );

      const onPreviewToggled = async () => {
        const previewStateForTab = previewStateByTabId[selectedTabId] || {
          isPreviewMode: false, // Will end up causing to toggle on in the same function invocation.
        };

        previewStateForTab.isPreviewMode = !previewStateForTab.isPreviewMode;

        if (previewStateForTab.isPreviewMode) {
          const { message } = await onPreviewId(selectedTabId);
          previewStateForTab.previewMessage = message;
        }

        setPreviewStateByTabId({
          ...previewStateByTabId,
          [selectedTabId]: previewStateForTab,
        });
      };

      return (
        <InvoiceCommunicateTabPerDebtorForm
          {...props}
          {...previewStateByTabId[selectedTabId]}
          selectedTabId={selectedTabId}
          onPreviewToggled={onPreviewToggled}
        />
      );
    }),
  ),
);

InvoiceCommunicateTabPerDebtorFormContainer.displayName = 'InvoiceCommunicateTabPerDebtorFormContainer';

InvoiceCommunicateTabPerDebtorFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  debtorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InvoiceCommunicateTabPerDebtorFormContainer.defaultProps = {};

export default InvoiceCommunicateTabPerDebtorFormContainer;
