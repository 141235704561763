angular
  .module('@sb-billing/services')
  .service('sbMatterStaffService', function(sbLoggerService, sbGenericEndpointService) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbBillingConfigurationService');
    const basePath = 'matter-management/matter-staff';

    that.saveMatterStaffP = saveMatterStaffP;

    function saveMatterStaffP({ matterId, attorneyResponsibleId, personAssistingId, originatingAttorneyId }) {
      log.info(`save config`, { matterId, attorneyResponsibleId, personAssistingId, originatingAttorneyId });

      const payload = {
        matterId,
        attorneyResponsibleId,
        personAssistingId,
        originatingAttorneyId
      };

      log.info('saving config', payload);

      return sbGenericEndpointService.postPayloadP(basePath, undefined, payload, 'POST');
    }
  });
