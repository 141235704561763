import React from 'react';
import { t } from '@sb-itops/localisation-web';

import { SlidingToggle, Button } from '@sb-itops/react';

import Styles from './ConfigureMatterNumbering.module.scss';
import { TWidget } from './types';
import { InstallableWidgets } from './InstallableWidgets';
import { AssemblyDropzone } from './assembly-dropzone';
import { WidgetEditor } from './WidgetEditor';
import { MatterNumberPreview } from './MatterNumberPreview';

type ConfigureMatterNumberingProps = {
  installableWidgets: TWidget[];
  installedWidgets: TWidget[];
  addWidget: (widget: TWidget, index: number) => void;
  moveWidget: (widget: TWidget, index: number) => void;
  deleteWidget: (widget: TWidget) => void;
  selectWidget: (instanceId?: string) => void;
  setSelectedWidgetValue: (pos: number, stringValue: string) => void;
  selectedWidget?: TWidget;
  assignToLeads: boolean;
  setAssignToLeads: (value: boolean) => void;
  useAutoReference: boolean;
  setUseAutoReference: (value: boolean) => void;
  save: () => void;
  saving: boolean;
};

const getToday = () => new Date();
const getNextYear = () => {
  const d = getToday();
  d.setFullYear(d.getFullYear() + 1);
  return d;
};
const getNextMonth = () => {
  const d = getToday();
  d.setMonth((d.getMonth() + 1) % 12);
  return d;
};

export const ConfigureMatterNumbering = ({
  installableWidgets,
  installedWidgets,
  addWidget,
  moveWidget,
  deleteWidget,
  selectWidget,
  selectedWidget,
  setSelectedWidgetValue,
  assignToLeads,
  setAssignToLeads,
  useAutoReference,
  setUseAutoReference,
  save,
  saving,
}: ConfigureMatterNumberingProps) => (
  <div className={Styles.configureMatterNumbering}>
    <h3> {t('matterNumber')}</h3>
    <div className={Styles.useAutoReferenceToggle}>
      <SlidingToggle
        scope="use-auto-reference-toggle"
        onChange={() => setUseAutoReference(!useAutoReference)}
        selected={useAutoReference}
      />
      <div onClick={() => setUseAutoReference(!useAutoReference)} className={Styles.useAutoReferenceToggleLabel}>
        Automate {t('matterNumber')}
      </div>
    </div>
    <div className={Styles.assignToLeadsToggle}>
      <SlidingToggle
        scope="assign-to-leads-toggle"
        onChange={() => setAssignToLeads(!assignToLeads)}
        selected={assignToLeads}
      />
      <div onClick={() => setAssignToLeads(!assignToLeads)} className={Styles.assignToLeadsToggleLabel}>
        Assign to leads
      </div>
    </div>
    <InstallableWidgets installableWidgets={installableWidgets} />
    <AssemblyDropzone
      {...{
        installedWidgets,
        addWidget,
        moveWidget,
        deleteWidget,
        selectWidget,
        selectedWidgetId: selectedWidget?.instanceId,
      }}
    />
    <WidgetEditor
      widget={selectedWidget}
      setWidgetValue={setSelectedWidgetValue}
      deleteWidget={() => selectedWidget && deleteWidget(selectedWidget)}
    />
    <MatterNumberPreview
      widgets={installedWidgets}
      previewParams={[
        { counter: 0, date: getToday(), label: 'Today:' },
        { counter: 17, date: getNextMonth(), label: 'Next month:' },
        { counter: 220, date: getNextYear(), label: 'Next year:' },
      ]}
    />
    <Button onClick={() => save()} disabled={saving} locked={saving}>
      Save
    </Button>
  </div>
);
