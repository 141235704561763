import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

export const { UPDATE_CACHE, getById, getMap, getList, updateCache } = cacheFactory({
  domain,
  name: 'matter-types',
  keyPath: 'matterTypeId',
  ngCacheName: 'sbMatterTypesMbService',
  syncType: syncTypes.SINCE,
});

export const getTypeOrBaseTypeById = (id) => {
  if (!id) {
    return '';
  }
  const matterType = getById(id);

  if (!matterType) {
    const [baseMatterTypeId] = id.split('_');
    return getById(baseMatterTypeId);
  }

  return matterType;
};

export const getCategoriesById = (id) => {
  const matterType = getTypeOrBaseTypeById(id);
  return matterType && matterType.categories;
};

export const getMatterTypeNameById = (id) => {
  const matterType = getTypeOrBaseTypeById(id);
  return matterType && matterType.name;
};

export const getMatterTypesById = (id) => {
  const matterTypeName = getMatterTypeNameById(id);
  return (getCategoriesById(id) || ['']).map((category) => [category, `${category}/${matterTypeName}`]).join();
};

/**
 * Returns the matterTypeIds of the matter that have the specified category.
 * Use with caution, as this iterates over all the matter types.
 * If you are looking to use this in a hot path, look to change this into a selector for performance
 * improvements.
 * @param {String} category the name of the category
 */
export const findMatterTypeIdsByCategory = (category) =>
  getList().reduce((ids, matterType) => {
    if (matterType.categories.includes(category)) {
      ids.push(matterType.matterTypeId);
    }

    return ids;
  }, []);

/**
 * Returns the matterTypeId of the matter that has the specified name.
 * Use with caution, as this iterates over all the matter types.
 * If you are looking to use this in a hot path, change this into a selector for performance
 * improvements.
 * @param {String} name the name of the matter type
 */
export const findMatterTypeIdByName = (name) => {
  const mt = getList().find((matterType) => matterType.name === name);

  if (mt) {
    return mt.matterTypeId;
  }

  return undefined;
};

const LEAD_MATTER_TYPE = 1;
export const isLeadMatterType = (matterTypeId) => {
  if (!matterTypeId) {
    return false;
  }

  const matterType = getTypeOrBaseTypeById(matterTypeId);

  if (!matterType) {
    return false;
  }

  return !!matterType && matterType.typeCategory === LEAD_MATTER_TYPE;
};
