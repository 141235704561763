import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import FinancialSummaryItem from './FinancialSummaryItem';
import FinancialSummary from './FinancialSummary';

export const FinancialSummaryByMatter = ({
  creditTooltip,
  showTrustAccount,
  onClickLink,
  totals,
  isStatutoryDepositMatter,
  showFeeEstimate,
  showControlledMoneyAccount,
  matterId,
  onFilterByRecent,
  onFilterInvoiceStatus,
  trustRetainerBelowLimit,
  trustTooltip,
}) => {
  const { t } = useTranslation();
  if (!matterId) {
    return null;
  }
  return (
    <FinancialSummary>
      <div className="financial-summary-totals">
        <div className="totals-container">
          <FinancialSummaryItem
            name="Unbilled"
            total={totals.UNBILLED}
            show={!isStatutoryDepositMatter}
            onClick={() => onClickLink({ type: 'matterFees', id: matterId })}
          />
          <FinancialSummaryItem
            name="Unpaid"
            show={!isStatutoryDepositMatter}
            total={totals.UNPAID}
            onClick={() => {
              onFilterByRecent(false);
              onFilterInvoiceStatus(['FINAL', 'OVERDUE']);
              onClickLink({ type: 'matterBills', id: matterId });
            }}
          />
          <FinancialSummaryItem
            name="Overdue"
            show={!isStatutoryDepositMatter}
            total={totals.OVERDUE}
            onClick={() => {
              onFilterByRecent(false);
              onFilterInvoiceStatus(['OVERDUE']);
              onClickLink({ type: 'matterBills', id: matterId });
            }}
          />
          <FinancialSummaryItem
            total={totals.CREDIT}
            name="Credit"
            show={!isStatutoryDepositMatter && totals.CREDIT > 0}
            onClick={() =>
              onClickLink({ type: 'matterTransactions', id: 'credit-note-ledger', params: [{ matterId }] })
            }
            tooltip={creditTooltip}
          />
          <FinancialSummaryItem
            total={totals.OPERATING}
            name={t('operating')}
            show={!isStatutoryDepositMatter && totals.OPERATING > 0}
            onClick={() => onClickLink({ type: 'matterTransactions', id: 'operating', params: [{ matterId }] })}
          />
          <FinancialSummaryItem
            name={t('trust')}
            show={showTrustAccount}
            total={totals.TRUST}
            redText={trustRetainerBelowLimit}
            onClick={() => onClickLink({ type: 'matterTransactions', id: 'trust', params: [{ matterId }] })}
            tooltip={trustTooltip}
          />
          <FinancialSummaryItem
            name={t('CMA')}
            show={showControlledMoneyAccount}
            redText={totals.CONTROLLEDMONEY < 0}
            total={totals.CONTROLLEDMONEY}
            onClick={() =>
              onClickLink({
                type: 'matterTransactions',
                id: 'controlled-money',
                params: [{ matterId, cmaId: 'all' }],
              })
            }
          />
          <FinancialSummaryItem
            name="Fee/Estimate"
            show={showFeeEstimate}
            total={totals.FEE_ESTIMATE || 0}
            onClick={() => onClickLink({ type: 'matterFees', id: matterId })}
          />
        </div>
      </div>
    </FinancialSummary>
  );
};

FinancialSummaryByMatter.displayName = 'FinancialSummaryByMatter';

FinancialSummaryByMatter.propTypes = {
  creditTooltip: PropTypes.element,
  matterId: PropTypes.string,
  onFilterByRecent: PropTypes.func.isRequired,
  onFilterInvoiceStatus: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  trustRetainerBelowLimit: PropTypes.bool,
  isStatutoryDepositMatter: PropTypes.bool,
  showFeeEstimate: PropTypes.bool,
  showControlledMoneyAccount: PropTypes.bool,
  totals: PropTypes.object,
  showTrustAccount: PropTypes.bool,
  trustTooltip: PropTypes.element,
};
FinancialSummaryByMatter.defaultProps = {
  creditTooltip: undefined,
  matterId: '',
  trustRetainerBelowLimit: false,
  isStatutoryDepositMatter: false,
  showFeeEstimate: false,
  showControlledMoneyAccount: false,
  totals: {},
  showTrustAccount: false,
  trustTooltip: undefined,
};
