import { updateCache as updateRedux, getList } from '@sb-billing/redux/utbms-settings';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('@sb-billing/services')
  .service('sbUtbmsSettingsService', function(
    sbGenericCacheService,
    sbGenericEndpointService,
    sbEndpointType,
    sbUuidService,
  ) {
    if (!hasFacet(facets.utbms)) {
      return;
    }

    const that = this;
    const utbmsSettingsEndpoint = 'billing/firm-utbms-settings';

    sbGenericCacheService.setupCache({
      name: 'sbUtbmsSettingsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: utbmsSettingsEndpoint },
        poll: 60,
        subscriptions: 'notifier.BillingActivitiesNotifications.BillingUtbmsSettingsUpdated'
      },
      updateRedux
    });

    that.get = get;
    that.saveP = saveP;

    function get() {
      return getList()[0];
    }

    function saveP(utbmsSettings) {
      const versionId = sbUuidService.get();
      const changeset = {
        sbUtbmsSettingsService: [utbmsSettings]
      };

      return sbGenericEndpointService.postPayloadP(utbmsSettingsEndpoint, versionId, { utbmsSettings }, 'POST', {
        changeset
      });
    }
  });
