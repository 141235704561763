import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getByMatterIdWithStringAsEnums } from '@sb-billing/redux/matter-invoice-settings';
import { getDetails } from '@sb-billing/redux/invoice-settings';
import { InvoiceSettingTitle2 } from '../invoice-setting-title';

const mapStateToProps = (state, { matterId, onChangeOption, onChangeText }) => {
  const matterSetting = getByMatterIdWithStringAsEnums(matterId);
  const invoiceSetting = getDetails();

  return {
    onChangeOption,
    onChangeText,
    showFirmOption: true,
    showResetButton: true,
    selectedOption: matterSetting && matterSetting.titleLine2Overridden ? matterSetting.titleLine2Option : 'Firm',
    selectedText:
      matterSetting && matterSetting.titleLine2Overridden
        ? matterSetting.titleLine2CustomText
        : invoiceSetting && invoiceSetting.titleLine2CustomText,
    defaultText: invoiceSetting && invoiceSetting.titleLine2CustomText,
  };
};

const MatterSettingSubtitle = withReduxStore(connect(mapStateToProps)(InvoiceSettingTitle2));

MatterSettingSubtitle.displayName = 'MatterSettingSubtitle';

MatterSettingSubtitle.propTypes = {
  matterId: PropTypes.string.isRequired,
  onChangeOption: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
};

export default MatterSettingSubtitle;
