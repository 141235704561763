import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoicingService',
  keyPath: 'invoiceId',
});

// if the invoice will be paid in full set the status to PAID
export const buildInvoiceOpdates = (invoiceTotals, payment, invoice) => {
  const toBePaid = payment.payors ? payment.payors.reduce((sum, { amount }) => sum + amount, 0) : payment.amount;

  return toBePaid >= invoiceTotals.unpaid
    ? [{ invoiceId: payment.invoiceId, currentVersion: { ...invoice.currentVersion, status: 'PAID' } }]
    : [];
};
