/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { hasFacet, facets } from '@sb-itops/region-facets';

export const creditCardDepositFormSchema = Yup.object().shape({
  bankAccountId: Yup.string().required(),
  dateDeposited: Yup.number().required(),
  payorId: Yup.string().required(),
  matterId: Yup.string().required(),
  reason: hasFacet(facets.reasonField) ? Yup.string().required() : undefined,
  depositAmount: Yup.number().min(1).required(),
});
