import React from 'react';
import PropTypes from 'prop-types';
import { forms2PropTypes, FormLabel, TitleDropdown, useTranslation, Spinner, DatePicker } from '@sb-itops/react';
import classnames from 'classnames';
import { getRegion } from '@sb-itops/region';
import { schemeMappings, testingGetAllValuesForKey } from '@sb-itops/region-schemes';
import { capitalize } from '@sb-itops/nodash';
import { getPersonTitles } from '@sb-customer-management/business-logic/contacts/services/get-person-titles';
import { phonePlaceholders } from '@sb-customer-management/business-logic/contacts/entities/constantsTypescript';
import moment from 'moment';
import { featureActive } from '@sb-itops/feature';
import Styles from './ContactPersonForm.module.scss';

const REGION = getRegion();

const titleOptions = getPersonTitles({ includeTopLabelOption: false, region: REGION });

const ContactPersonForm = ({
  formInitialised,
  phoneNumberScheme,
  title,
  birthDate,
  firstName,
  lastName,
  phoneAreaCode,
  phoneNumber,
  cellAreaCode,
  cellNumber,
  email,
  ledesClientId,
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  removeRequired,
  isUtbmsEnabled,
  children,
}) => {
  const isErrorClassnames = (field, overrideStyle) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, overrideStyle) : '';
  const { t } = useTranslation();

  if (!formInitialised) {
    return <Spinner small />;
  }

  const showPhoneAreaCodeField = phoneNumberScheme === schemeMappings.phoneNumberScheme.US;

  return (
    <div className="contact-person-form">
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-xs-2">
            <label>Title</label>
            <TitleDropdown
              selectedTitle={title?.value}
              options={titleOptions}
              disabled={formDisabled}
              className={classnames(isErrorClassnames(title, Styles.formSelect))}
              onChange={({ value }) => {
                onFieldValueUpdated({ title: value });
              }}
              onBlur={validateForm}
            />
          </div>
          <div className="col-xs-4">
            <FormLabel label="First Name" field={firstName} submitFailed={submitFailed} optional={removeRequired} />
            <input
              name="firstName"
              type="text"
              className={classnames('form-control', isErrorClassnames(firstName, Styles.formControl))}
              value={firstName?.value || ''}
              onChange={(e) => {
                onFieldValueUpdated({ firstName: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
          <div className="col-xs-6">
            <FormLabel label="Last Name" field={lastName} submitFailed={submitFailed} optional />
            <input
              name="lastName"
              type="text"
              className={classnames('form-control', isErrorClassnames(lastName, Styles.formControl))}
              value={lastName?.value || ''}
              onChange={(e) => {
                onFieldValueUpdated({ lastName: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-8">
            <FormLabel label="Email" field={email} submitFailed={submitFailed} optional />
            <input
              name="email"
              type="text"
              className={classnames('form-control', isErrorClassnames(email, Styles.formControl))}
              value={email?.value || ''}
              onChange={(e) => {
                onFieldValueUpdated({ email: e.target.value });
              }}
              onBlur={validateForm}
            />
            {email?.isInvalid && email?.invalidReason?.indexOf('valid email') !== -1 && (
              <span className={Styles.errorMessage}>Invalid email format</span>
            )}
          </div>
          {featureActive('NUCWEB-675') && (
            <div className="col-xs-4">
              <FormLabel label="DoB" field={birthDate} submitFailed={submitFailed} optional />
              <DatePicker
                hasError={birthDate?.isInvalid}
                // In the DB the birthDate field is stored as a UTC time but we are using it as a YYY-MM-DD time
                onSelect={(date) => onFieldValueUpdated({ birthDate: date ? moment(date).format('YYYY-MM-DD') : '' })}
                value={birthDate?.value ? moment(birthDate?.value).toDate() : null}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-xs-6">
            <label>Home</label>
            <div className="row">
              {showPhoneAreaCodeField && (
                <div className="col-xs-3">
                  <input
                    name="phoneAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(phoneAreaCode, Styles.formControl))}
                    value={phoneAreaCode?.value || ''}
                    placeholder={formDisabled ? '' : phonePlaceholders.phoneAreaCode[phoneNumberScheme]}
                    onChange={(e) => {
                      onFieldValueUpdated({ phoneAreaCode: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              )}

              <div className={showPhoneAreaCodeField ? 'col-xs-9' : 'col-xs-12'}>
                <input
                  name="phoneNumber"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(phoneNumber, Styles.formControl))}
                  value={phoneNumber?.value || ''}
                  placeholder={formDisabled ? '' : phonePlaceholders.phoneNumber[phoneNumberScheme]}
                  onChange={(e) => {
                    onFieldValueUpdated({ phoneNumber: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <label>{capitalize(t('mobile'))}</label>
            <div className="row">
              {showPhoneAreaCodeField && (
                <div className="col-xs-3">
                  <input
                    name="cellAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(cellAreaCode, Styles.formControl))}
                    value={cellAreaCode?.value || ''}
                    placeholder={formDisabled ? '' : phonePlaceholders.cellAreaCode[phoneNumberScheme]}
                    onChange={(e) => {
                      onFieldValueUpdated({ cellAreaCode: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              )}
              <div className={showPhoneAreaCodeField ? 'col-xs-9' : 'col-xs-12'}>
                <input
                  name="cellNumber"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(cellNumber, Styles.formControl))}
                  value={cellNumber?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ cellNumber: e.target.value });
                  }}
                  placeholder={formDisabled ? '' : phonePlaceholders.cellNumber[phoneNumberScheme]}
                  onBlur={validateForm}
                />
              </div>
            </div>
          </div>
        </div>
        {children}
        {!!isUtbmsEnabled && (
          <div className="row">
            <div className="col-xs-12">
              <label>Ledes Client Id</label>
              <input
                name="ledesClientId"
                type="text"
                className={classnames('form-control', isErrorClassnames(ledesClientId, Styles.formControl))}
                value={ledesClientId?.value || ''}
                onBlur={validateForm}
                onChange={(e) => {
                  onFieldValueUpdated({ ledesClientId: e.target.value });
                }}
                required
              />
            </div>
          </div>
        )}
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

ContactPersonForm.displayName = 'ContactPersonForm';
ContactPersonForm.propTypes = {
  phoneNumberScheme: PropTypes.oneOf(testingGetAllValuesForKey('phoneNumberScheme')).isRequired,
  title: Forms2Field,
  firstName: Forms2Field,
  birthDate: Forms2Field,
  lastName: Forms2Field,
  phoneAreaCode: Forms2Field,
  phoneNumber: Forms2Field,
  cellAreaCode: Forms2Field,
  cellNumber: Forms2Field,
  email: Forms2Field,
  ledesClientId: Forms2Field,
  onFieldValueUpdated: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool,
  formInitialised: PropTypes.bool,
  submitFailed: PropTypes.bool,
  removeRequired: PropTypes.bool,
  isUtbmsEnabled: PropTypes.bool,
  children: PropTypes.node,
};

ContactPersonForm.defaultProps = {
  title: titleOptions.at(0),
  firstName: undefined,
  lastName: undefined,
  birthDate: undefined,
  phoneAreaCode: undefined,
  phoneNumber: undefined,
  cellAreaCode: undefined,
  cellNumber: undefined,
  email: undefined,
  ledesClientId: undefined,
  isUtbmsEnabled: undefined,
  formDisabled: false,
  formInitialised: false,
  submitFailed: false,
  removeRequired: false,
  children: undefined,
};

export default ContactPersonForm;
