import { store } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getById, opdateCache, rollbackOpdateCache } from './index';
import { getById as getAutoNumberDefinitionByAccountId } from '../auto-number-definition';

export const saveMatter = async ({
  matterId,
  accountId,
  clientRole,
  clientIds,
  otherSideRole,
  otherSideIds,
  matterNumber,
  status,
  description,
  attorneyResponsibleId,
  personAssistingId,
  originatingAttorneyId,
  isLead,
  openedDate,
  closedDate,
  leadOpenedDate,
  leadClosedDate,
  leadClosedReason,
  referralType,
  referrerId,
}) => {
  await store.dispatch(async () => {
    const existingMatter = getById(matterId);
    const autoNumberDefinition = getAutoNumberDefinitionByAccountId(accountId);
    const useAutoReference = autoNumberDefinition && autoNumberDefinition.useAutoReference;

    const matterForOpdate = {
      ...existingMatter,
      clientCustomerIds: clientIds,
      clientRole,
      status,
      description,
      attorneyResponsibleId,
      personAssistingId,
      originatingAttorneyId,
      openedDate,
      closedDate,
      leadOpenedDate,
      leadClosedDate,
      leadClosedReason,
      referralType,
      referrerId,
    };
    if (!useAutoReference) {
      matterForOpdate.matterNumber = matterNumber;
    }

    try {
      const body = {
        matterId,
        clientRole,
        clientIds,
        otherSideRole,
        otherSideIds,
        description,
        status,
        openedDate,
        closedDate,
        leadOpenedDate,
        leadClosedDate,
        leadClosedReason,
        referralType,
        referrerId,
        // Temporary solution: sending '' empty string to clear the following fields for now.
        // Ali said this is not straight forward to do. In the future it is envisaged that
        // we'll allow clearing of fields by setting it to null. Another word we'll be sending
        // 1) undefined when there's no update for a field
        // 2) null to clear a field
        personResponsibleStaffId: attorneyResponsibleId || '',
        personAssistingStaffId: personAssistingId || '',
        originatingStaffId: originatingAttorneyId || '',
        // Ali requested that we always send isLead
        isLead,
      };

      // update matter number only when auto numbering is off
      if (!useAutoReference) {
        body.matterNumber = matterNumber;
      }

      const fetchOptions = { body: JSON.stringify(body) };
      await fetchPostP({
        path: `/matter-management/matter/update/:accountId/`,
        fetchOptions,
      });

      opdateCache({ optimisticEntities: [matterForOpdate] });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [matterForOpdate] });
      throw err;
    }
  });
};
