import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { FloatingCard, forms2PropTypes, FormLabel, DatePicker, useTranslation } from '@sb-itops/react';
import { PercentageInput } from '@sb-itops/react/percentage-input';

import { featureActive } from '@sb-itops/feature';
import { AddressFields } from './AddressFields/AddressFields.container';
import Styles from '../FeeWiseApplicationForm.module.scss';
import { businessOwnershipError } from '../FeeWiseApplicationFormSchema.yup';

export const BeneficialOwner = ({
  beneficialOwner,
  formInitialised,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  onUpdateBusinessOwnership,
  sectionRef,
  today,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <FloatingCard applyMargin={false} classname={Styles.marginBottom} ref={sectionRef}>
      <div className={Styles.sectionTitle}>
        Majority Owner <span className={Styles.sectionSubtitle}>(must own at least 25% of the law firm)</span>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="First Name" />
          <input
            type="text"
            value={beneficialOwner?.firstName?.value}
            className={classnames('form-control', isErrorClassnames(beneficialOwner?.firstName))}
            onChange={(e) => onFieldUpdated({ 'beneficialOwner.firstName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Last Name" />
          <input
            type="text"
            value={beneficialOwner?.lastName?.value}
            className={classnames('form-control', isErrorClassnames(beneficialOwner?.lastName))}
            onChange={(e) => onFieldUpdated({ 'beneficialOwner.lastName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Job Title" />
          <input
            type="text"
            value={beneficialOwner?.jobTitle?.value}
            className={classnames('form-control', isErrorClassnames(beneficialOwner?.jobTitle))}
            onChange={(e) => onFieldUpdated({ 'beneficialOwner.jobTitle': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Phone Number" />
          <input
            type="text"
            value={beneficialOwner?.phoneNumber?.value}
            className={classnames('form-control', isErrorClassnames(beneficialOwner?.phoneNumber))}
            onChange={(e) => onFieldUpdated({ 'beneficialOwner.phoneNumber': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Email Address" />
          <input
            type="email"
            value={beneficialOwner?.emailAddress?.value}
            className={classnames('form-control', isErrorClassnames(beneficialOwner?.emailAddress))}
            onChange={(e) => onFieldUpdated({ 'beneficialOwner.emailAddress': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <AddressFields
        addressFields={beneficialOwner}
        parentPath="beneficialOwner"
        formInitialised={formInitialised}
        submitFailed={submitFailed}
        formDisabled={formDisabled}
        onFieldUpdated={onFieldUpdated}
      />
      <div className="row">
        <div className={classnames('form-group', 'col-sm-4')}>
          <FormLabel uppercase={false} label="Date of Birth" />
          <DatePicker
            disabled={formDisabled}
            onSelect={(date) =>
              onFieldUpdated({
                'beneficialOwner.dateOfBirth': date || undefined,
              })
            }
            value={beneficialOwner?.dateOfBirth?.value ? new Date(beneficialOwner?.dateOfBirth?.value) : undefined}
            hasError={!!isErrorClassnames(beneficialOwner?.dateOfBirth)}
            maxDate={today}
          />
        </div>
        {!featureActive('BB-14112') && (
          <div className={classnames('form-group', 'col-sm-4')}>
            <FormLabel uppercase={false} label="Last 4 digits Social Security" />
            <input
              type="text"
              value={beneficialOwner?.last4SocialSecurity?.value}
              className={classnames('form-control', isErrorClassnames(beneficialOwner?.last4SocialSecurity))}
              onChange={(e) => onFieldUpdated({ 'beneficialOwner.last4SocialSecurity': e.target.value })}
              disabled={formDisabled}
            />
            {!!isErrorClassnames(beneficialOwner?.last4SocialSecurity) &&
              beneficialOwner?.last4SocialSecurity?.invalidReason.includes('Must be exactly') && (
                <span className={Styles.errorMsg}>{beneficialOwner?.last4SocialSecurity?.invalidReason}</span>
              )}
          </div>
        )}
        {featureActive('BB-14112') && (
          <div className={classnames('form-group', 'col-sm-4')}>
            <FormLabel uppercase={false} label={t('settings.feewise.nationalIdLabel')} />
            <input
              type="text"
              value={beneficialOwner?.socialSecurityNumber?.value}
              className={classnames('form-control', isErrorClassnames(beneficialOwner?.socialSecurityNumber))}
              onChange={(e) => onFieldUpdated({ 'beneficialOwner.socialSecurityNumber': e.target.value })}
              disabled={formDisabled}
            />
            {!!isErrorClassnames(beneficialOwner?.socialSecurityNumber) &&
              beneficialOwner?.socialSecurityNumber?.invalidReason.includes('Must be') && (
                <span className={Styles.errorMsg}>{beneficialOwner?.socialSecurityNumber?.invalidReason}</span>
              )}
          </div>
        )}

        <div className={classnames('form-group', 'col-sm-4')}>
          <FormLabel uppercase={false} label="Business Ownership" />
          <div className={classnames('input-group', Styles.inputGroup)}>
            <PercentageInput
              step="1"
              percent={beneficialOwner?.businessOwnership?.value}
              disabled={formDisabled}
              hasError={!!isErrorClassnames(beneficialOwner?.businessOwnership)}
              onChange={(percent) =>
                onUpdateBusinessOwnership({
                  percent: typeof percent === 'number' && Number.isFinite(percent) ? percent : 0,
                  max: 100,
                  min: 0,
                  fieldName: 'beneficialOwner.businessOwnership',
                })
              }
            />
            {!!isErrorClassnames(beneficialOwner?.businessOwnership) &&
              beneficialOwner?.businessOwnership?.invalidReason === businessOwnershipError && (
                <span className={Styles.errorMsg}>{beneficialOwner?.businessOwnership?.invalidReason}</span>
              )}
          </div>
        </div>
      </div>
    </FloatingCard>
  );
};

BeneficialOwner.displayName = 'BeneficialOwner';

const { Forms2Field } = forms2PropTypes;

BeneficialOwner.propTypes = {
  beneficialOwner: PropTypes.shape({
    firstName: Forms2Field,
    lastName: Forms2Field,
    jobTitle: Forms2Field,
    phoneNumber: Forms2Field,
    emailAddress: Forms2Field,
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
    dateOfBirth: Forms2Field,
    last4SocialSecurity: Forms2Field,
    socialSecurityNumber: Forms2Field,
    businessOwnership: Forms2Field,
  }),
  today: PropTypes.object.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
  onUpdateBusinessOwnership: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

BeneficialOwner.defaultProps = {
  formDisabled: false,
  beneficialOwner: {},
};
