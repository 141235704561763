import React from 'react';
import { Button, Modal } from '@sb-itops/react';
import { FormData } from '@sb-itops/redux/forms2/use-form';
import { MatterSummary } from 'types/index';
import { Party } from 'web/hooks/use-matter-parties';
import Styles from './AddEvent.module.scss';
import { AddEvent, AppointmentFields } from './AddEvent';

export interface DevExtremeInitAppointment {
  allDay: boolean;
  startDate: Date;
  endDate: Date;
}

type FormTypes = Pick<
  FormData<AppointmentFields>,
  | 'formFields'
  | 'formSubmitting'
  | 'formInitialised'
  | 'onUpdateFieldValues'
  | 'onFieldValueSet'
  | 'formValidation'
  | 'submitFailed'
>;

export interface IAddEventModalProps extends FormTypes {
  matterSummaries: MatterSummary[];
  attendees: { id: string; name: string }[];
  parties: Party[];
  partiesLoading: boolean;
  onDelete: () => Promise<void>;
  onSubmit: () => Promise<void>;
  onSubmitSaveAndNew: () => Promise<void>;
  onClose: () => void;
  mode: AskEditAppointmentModalMode;
}

export enum AskEditAppointmentModalMode {
  create,
  edit,
}

export const AddEventModal = ({
  onDelete,
  onSubmit,
  onSubmitSaveAndNew,
  onClose,
  matterSummaries,
  attendees,
  parties,
  partiesLoading,
  formFields,
  formSubmitting,
  formInitialised,
  onUpdateFieldValues,
  onFieldValueSet,
  formValidation,
  submitFailed,
  mode,
}: IAddEventModalProps) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <Modal
      className={Styles.addEventModal}
      title={mode === AskEditAppointmentModalMode.create ? 'Create Event' : 'Edit Event'}
      body={
        <>
          <AddEvent
            matterSummaries={matterSummaries}
            attendees={attendees}
            parties={parties}
            partiesLoading={partiesLoading}
            fields={formFields}
            onUpdateFieldValues={onUpdateFieldValues}
            onFieldValueSet={onFieldValueSet}
            formValidation={formValidation}
            submitFailed={submitFailed}
            formSubmitting={formSubmitting}
          />
          {mode === AskEditAppointmentModalMode.edit && (
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            <a className={Styles.link} onClick={onDelete}>
              Delete
            </a>
          )}
        </>
      }
      footer={
        <div className={Styles.footer}>
          <div className="row">
            <div className="col-lg-4">
              <Button onClick={onSubmit} size="full-width" disabled={formSubmitting} locked={formSubmitting}>
                Save
              </Button>
            </div>
            {mode === AskEditAppointmentModalMode.create && (
              <div className="col-lg-4">
                <Button
                  onClick={onSubmitSaveAndNew}
                  disabled={formSubmitting}
                  locked={formSubmitting}
                  type="secondary"
                  size="full-width"
                >
                  Save & New
                </Button>
              </div>
            )}
          </div>
        </div>
      }
      onModalClose={onClose}
    />
  );
};
