angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/view-combined-pdf';

  $stateProvider
    .state('home.billing.view-combined-pdf', {
      url: '/view-combined-pdf/:operationId',
      templateUrl: `${BASE_DIRECTORY}/view-combined-pdf.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing'],
        tab: { type: 'view-combined-pdf' }
      }
    });
});