import PropTypes from 'prop-types';
import React from 'react';

import { DropDownList } from '../drop-down-list';

export const TitleDropdown = ({ selectedTitle, options, disabled, onChange, className, onBlur }) => {
  const titleOptions = [...options];

  const titleOptionFound = titleOptions.find(
    (option) => option.value === selectedTitle || option.label === selectedTitle,
  );

  let selectedTitleOption = titleOptionFound;

  // add any non-falsy custom title to the list of options available
  // this is because SB Desktop app allows arbitrary custom titles
  // not adding this means the title entered previous in SB desktop
  // could be lost when user saves a contact with a custom title
  if (selectedTitle && !titleOptionFound) {
    const customTitleOption = { label: selectedTitle, value: selectedTitle };
    selectedTitleOption = customTitleOption;
    titleOptions.unshift(customTitleOption);
  }

  return (
    <DropDownList
      selectedOption={selectedTitleOption}
      options={titleOptions}
      disabled={disabled}
      onChange={onChange}
      className={className}
      onBlur={onBlur}
      stateless
    />
  );
};

TitleDropdown.displayName = 'TitleDropdown';

TitleDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    }),
  ),
  selectedTitle: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

TitleDropdown.defaultProps = {
  options: [],
  selectedTitle: undefined,
  disabled: false,
  className: undefined,
  onChange: undefined,
  onBlur: undefined,
};
