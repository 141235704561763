import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import {
  editContact as editPersonContactViaRedux,
  editCompanyContact as editCompanyContactViaRedux,
} from '@sb-customer-management/redux/contacts';
import * as messageDisplay from '@sb-itops/message-display';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { isUtbmsEnabled } from '@sb-billing/redux/utbms-settings';
import { getRegion } from '@sb-itops/region';
import { saveCustomerBillingConfiguration } from '@sb-billing/redux/customer-billing-configuration';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { CreateEditContactSchema } from '../contact-create-edit/CreateEditContactSchema';
import { ContactCreateEditModal } from './ContactCreateEditModal';

const log = getLogger('ContactCreateEditModalContainer');
export const CONTACT_CREATE_EDIT_MODAL_ID = 'create-edit-contact';

const mapStateToProps = (state, { isVisible }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope: CONTACT_CREATE_EDIT_MODAL_ID })(forms);
  const { formSubmitting } = formSelectors.getFormState(state);
  return {
    isUtbmsEnabled: isUtbmsEnabled(),
    scope: CONTACT_CREATE_EDIT_MODAL_ID,
    isVisible,
    isSubmitDisabled: formSubmitting,
  };
};

const mapDispatchToProps = (
  dispatch,
  { onClickLink, onClose, contactId, onContactCreated, onContactCreatedWhenSaveAndNew, onContactEdited, isVisible },
) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({
    scope: CONTACT_CREATE_EDIT_MODAL_ID,
  })(forms);
  const isEdit = !!contactId;

  return {
    isVisible,
    onSave: async (event) => {
      event.preventDefault();
      await dispatch(formOperations.validateSchema({ schema: CreateEditContactSchema(getRegion()) }));
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFields) => {
              if (isEdit) {
                await editContactViaRedux(formFields, contactId);
                onClose(); // The onClose needs to be before onContactEdited as onContactEdited may be opening another instance of this modal
                onContactEdited();
                return;
              }

              const newContactId = await createContactViaDispatcher(formFields);
              onClickLink({ type: 'contact', id: newContactId }); // redirect to new contact page if create
              onContactCreated(newContactId);
              onClose();
            },
          }),
        );
      } catch (err) {
        log.error(err);
        messageDisplay.error('Failed to save contact');
      }
    },
    onSaveAndNew: async (event) => {
      event.preventDefault();
      await dispatch(formOperations.validateSchema({ schema: CreateEditContactSchema(getRegion()) }));
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFields) => {
              if (isEdit) {
                await editContactViaRedux(formFields, contactId);

                await dispatch(formActions.resetForm());
                messageDisplay.success('The contact has been updated successfully');
                return;
              }

              const newContactId = await createContactViaDispatcher(formFields);
              onContactCreatedWhenSaveAndNew(newContactId);
              await dispatch(formActions.resetForm());
              messageDisplay.success('The contact has been created successfully');
            },
          }),
        );
      } catch (err) {
        log.error('Failed to save contact', err);
        messageDisplay.error('Failed to save contact');
      }
    },
  };
};

const editContactViaRedux = async (formFields, contactId) => {
  if (formFields.contactType === contactTypes.COMPANY) {
    const { additionalContactProps } = formFields.companyFields || {};
    const people = Object.values(additionalContactProps || {});
    await editCompanyContactViaRedux(
      {
        id: contactId,
        contactType: formFields.contactType,
        ...formFields.companyFields,
        ...formFields.addressFields,
      },
      people,
    );
    if (isUtbmsEnabled()) {
      await saveCustomerBillingConfiguration({
        entityId: contactId,
        ledesClientId: formFields.companyFields.companyLedesClientId,
      });
    }
  } else if (formFields.contactType === contactTypes.PERSON) {
    await editPersonContactViaRedux({
      id: contactId,
      contactType: formFields.contactType,
      ...formFields.personFields,
      ...formFields.addressFields,
    });

    if (isUtbmsEnabled()) {
      await saveCustomerBillingConfiguration({
        entityId: contactId,
        ledesClientId: formFields.personFields.ledesClientId,
      });
    }
  } else if (formFields.contactType === contactTypes.TRUST) {
    await editPersonContactViaRedux({
      id: contactId,
      contactType: formFields.contactType,
      ...formFields.trustFields,
      ...formFields.addressFields,
    });
    if (isUtbmsEnabled()) {
      await saveCustomerBillingConfiguration({
        entityId: contactId,
        ledesClientId: formFields.trustFields.trustLedesClientId,
      });
    }
  }
};

const createContactViaDispatcher = async (formFields) => {
  if (![contactTypes.PERSON, contactTypes.COMPANY, contactTypes.TRUST].includes(formFields.contactType)) {
    throw new Error(`Unsupported contact type '${formFields.contactType}'`);
  }

  let type = 'Integration.CreateCompanyContact';
  if (formFields.contactType === contactTypes.PERSON) {
    type = 'Integration.CreatePersonContact';
  } else if (formFields.contactType === contactTypes.TRUST) {
    type = 'Integration.CreateTrustContact';
  }

  const message = { isUtbmsEnabled: isUtbmsEnabled(), contactFields: formFields };

  const dispatchResult = await dispatchCommand({ type, message });
  return dispatchResult.contact.id;
};

export const ContactCreateEditModalContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(ContactCreateEditModal),
);

ContactCreateEditModalContainer.displayName = 'ContactCreateEditModalContainer';

ContactCreateEditModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func.isRequired,
  contactId: PropTypes.string,
  initialOrg: PropTypes.string,
  messageDisplay: PropTypes.object,
  onContactCreated: PropTypes.func,
  onContactCreatedWhenSaveAndNew: PropTypes.func,
  onContactEdited: PropTypes.func,
};

ContactCreateEditModalContainer.defaultProps = {
  messageDisplay: { success: () => {} },
  initialOrg: undefined,
  contactId: undefined,
  onContactCreated: () => {},
  onContactCreatedWhenSaveAndNew: () => {},
  onContactEdited: () => {},
};
