import { createActionPromise } from 'web/ng-composable-components/actions/action-factory';
import * as messageDisplay from '@sb-itops/message-display';
import { Cent } from '@sb-itops/money';
import { addInvoicePayment } from '@sb-billing/redux/invoices/add-invoice-payment'
import { getByType as getBankAccountByType } from '@sb-billing/redux/bank-account';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').directive('sbActionPayInvoice', function ($state) {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      let invoice;

      // Yikes! removing this causes the add payment button to disappear from the UI!
      ctrl.group.onInvoiceData(function (data) { invoice = data; });
      ctrl.group.setAction(payInvoice, isPayable);

      function payInvoice(payment) {
        // Add the operating account detail for payment from trust
        if (hasFacet(facets.operatingAccountDetail) && featureActive('BB-5509') && payment.sourceAccountType && payment.sourceAccountType.toLowerCase() !== 'operating') {
          const operatingAccounts = getBankAccountByType('Operating');
          const bankAccountDetails = operatingAccounts && JSON.parse(JSON.stringify(operatingAccounts[0]));
          payment.destinationBankAccountName = bankAccountDetails.accountName;
          payment.destinationBankAccountNumber = bankAccountDetails.accountNumber;
          payment.destinationBankBranchNumber = bankAccountDetails.branchNumber;
        }

        const actionP = createActionPromise({
          composeGroup: ctrl.group,
          action: addInvoicePayment,
        })
        
        return actionP(payment)
          .then(async () => {
            if (
              payment.sourceAccountType &&
              payment.sourceAccountType.toUpperCase() === 'TRUST' &&
              payment.pdfOnTrustPayment === true &&
              // if also printing trust cheque NOW, trust cheque wins and we don't navigate to TTO PDF
              // printing method 1 = Print Now
              !(payment.chequePrintActive && payment.chequePrintMethod === 1)
            ) {

              // CONTACT BALANCE TYPE (US ONLY)
              if (payment.payors && payment.payors.length > 0) {
                for (let i = 0; i < payment.payors.length; i++) {
                  // await/setTimeout is needed otherwise only the TTO receipt of the last payor will be opened, instead of all of them
                  await new Promise((resolve) => {
                    setTimeout(() => {
                      $state.go('home.billing.trust-to-office-transfer-receipt', {
                        transactionId: payment.transferBetweenAccountsTransactionId,
                        // If the matter is a contact balance type, the payment Id is not on the payment obj but on each individual payor
                        paymentId: payment.payors[i].paymentId,
                      });
                      resolve();
                    }, 0)
                  });
                }
              // MATTER BALANCE TYPE (MOST COMMON)
              } else {
                $state.go('home.billing.trust-to-office-transfer-receipt', {
                  transactionId: payment.transferBetweenAccountsTransactionId,
                  paymentId: payment.paymentId,
                });
              }
            } else if (!(payment.chequePrintActive && payment.chequePrintMethod === 1)) {
              messageDisplay.success('Payment added successfully');
            }
          })
          .catch(() => {
            messageDisplay.error(
              messageDisplay.builder()
                .text('Failed to add payment')
                .conditionalText(' for {0}', new Cent(payment.amount).toString())
                .text(' to invoice')
                .conditionalText(' #{0}', invoice && invoice.invoiceNumber)
            );
          });
      }

      function isPayable() {
        return invoice && invoice.status === 'FINAL';
      }

    }
  };
});
