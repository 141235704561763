'use strict';

const { Cent } = require('@sb-itops/money');

const { Fee } = require('../entities/fee');
const { createTaxStrategy } = require('../../shared/services/tax');
const { durationType: durationTypeEnum, entryType } = require('../../shared/entities');

/**
 * Calculates the relevant fee and tax amounts
 * @param {Object} params
 * @param {number} [params.billableDurationInMins] AutoTime fee source items can contain both billable and non-billable items
 * @param {number} params.durationInMins
 * @param {durationTypeEnum} params.durationType
 * @param {boolean} params.isBillable
 * @param {boolean} params.isTaxExempt
 * @param {boolean} params.isTaxFacetEnabled
 * @param {boolean} params.isTaxInclusive
 * @param {number} params.rateInCents
 * @param {string} params.region
 * @param {number} [params.taxRateBasisPoints]
 * @returns {{
 *  amountExclTaxInCents: number,
 *  billableAmountExclTaxInCents: number,
 *  billableTaxAmountInCents: number,
 *  nonBillableAmountExclTaxInCents: number,
 *  taxAmountInCents: number,
 * }}
 */
function calculateFeeAmounts({
  billableDurationInMins,
  durationInMins,
  durationType,
  isBillable,
  isTaxExempt,
  isTaxFacetEnabled,
  isTaxInclusive,
  rateInCents,
  region,
  taxRateBasisPoints,
}) {
  if (!region) {
    throw new Error('Region is required');
  }

  const validateBillableDurationInMins = () =>
    billableDurationInMins === undefined || (Number.isInteger(billableDurationInMins) && billableDurationInMins >= 0);

  const validateDurationInMins = () => Number.isInteger(durationInMins) && durationInMins >= 0;

  const validBillableDurationInMins = validateBillableDurationInMins();
  const validDurationInMins = validateDurationInMins();
  const validDurationType = Object.keys(durationTypeEnum).includes(durationType);
  const validIsBillable = typeof isBillable === 'boolean';
  const validIsTaxExempt = typeof isTaxExempt === 'boolean';
  const validIsTaxFacetEnabled = typeof isTaxFacetEnabled === 'boolean';
  const validIsTaxInclusive = typeof isTaxInclusive === 'boolean';
  const validRateInCents = Number.isInteger(rateInCents) && rateInCents >= 0;
  const validTaxRateBasisPoints =
    taxRateBasisPoints === undefined || (Number.isInteger(taxRateBasisPoints) && taxRateBasisPoints >= 0);

  if (!validBillableDurationInMins) {
    throw new Error('Please provide a valid billableDurationInMins value');
  }

  if (!validDurationInMins) {
    throw new Error('Please provide a valid durationInMins value');
  }

  if (!validDurationType) {
    throw new Error('Please provide a valid durationType value');
  }

  if (!validIsBillable) {
    throw new Error('Please provide a valid isBillable value');
  }

  if (!validIsTaxExempt) {
    throw new Error('Please provide a valid isTaxExempt value');
  }

  if (!validIsTaxFacetEnabled) {
    throw new Error('Please provide a valid isTaxFacetEnabled value');
  }

  if (!validIsTaxInclusive) {
    throw new Error('Please provide a valid isTaxInclusive value');
  }

  if (!validRateInCents) {
    throw new Error('Please provide a valid rateInCents value');
  }

  if (!validTaxRateBasisPoints) {
    throw new Error('Please provide a valid taxRateBasisPoints value');
  }

  const taxStrategy = isTaxFacetEnabled
    ? createTaxStrategy({
        taxRate: taxRateBasisPoints,
        isTaxInclusive,
        isTaxExempt,
        region,
      })
    : undefined;

  const determineBillableDuration = () => {
    if (!isBillable) {
      return '0m';
    }

    // Use billableDurationInMins if provided, otherwise durationInMins is assumed to be fully billable
    if (billableDurationInMins !== undefined) {
      return `${billableDurationInMins}m`;
    }

    return `${durationInMins}m`;
  };

  const fee = new Fee({
    rate: new Cent(rateInCents || 0),
    feeType: durationType === durationTypeEnum.FIXED ? entryType.FIXED : entryType.TIME,
    duration: `${durationInMins}m`,
    taxStrategy,
    billableDuration: determineBillableDuration(),
  });
  const amountExclTaxInCents = fee.amountExcTax()?.cents() || 0;
  const billableAmountExclTaxInCents = fee.billableAmountExcTax()?.cents() || 0;
  const amounts = {
    amountExclTaxInCents,
    billableAmountExclTaxInCents,
    billableTaxAmountInCents: fee.billableTax()?.cents() || 0,
    nonBillableAmountExclTaxInCents: amountExclTaxInCents - billableAmountExclTaxInCents,
    taxAmountInCents: fee.tax()?.cents() || 0,
  };

  return amounts;
}

module.exports = {
  calculateFeeAmounts,
};
