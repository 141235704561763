import PropTypes from 'prop-types';
import React from 'react';
import { FloatingCard, forms2PropTypes, CheckboxLabelInline, useTranslation } from '@sb-itops/react';

import Styles from '../FeeWiseApplicationForm.module.scss';

export const AcknowledgementCheckbox = ({
  acknowledgementCheckbox,
  formInitialised,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  onValidateForm,
  setConfirmationDateTime,
  sectionRef,
}) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <FloatingCard applyMargin={false} ref={sectionRef}>
      <div className="row">
        <div className="col-sm-12">
          <CheckboxLabelInline
            hasError={!!isErrorClassnames(acknowledgementCheckbox?.confirmation)}
            parentClassName={Styles.acknowledgementCheckbox}
            checkboxClassName={Styles.checkboxAlignment}
            checked={acknowledgementCheckbox?.confirmation?.value}
            onChange={(confirmed) => {
              onFieldUpdated({ 'acknowledgementCheckbox.confirmation': confirmed });
              if (confirmed) {
                setConfirmationDateTime(moment().format());
              } else {
                setConfirmationDateTime(undefined);
              }
              onValidateForm();
            }}
            disabled={formDisabled}
          >
            I confirm that I am {t('authorised')} to act on behalf of the business and that the details entered are
            correct to the best of my knowledge, and I agree to be bound by the FeeWise{' '}
            <a href="https://www.getfeewise.com/services-agreement?hsLang=en" target="_blank" rel="noopener noreferrer">
              Services Agreement
            </a>{' '}
            and for personal information to be collected, used and disclosed in accordance with the{' '}
            <a href="https://www.getfeewise.com/privacy-policy?hsLang=en" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
            <br />
            <br />
            Please note: When applying for a Smokeball Payments account, our payments partner FeeWise does not conduct
            any credit checks. Submitting an application will have no impact on your credit score or history.
          </CheckboxLabelInline>
        </div>
      </div>
    </FloatingCard>
  );
};

AcknowledgementCheckbox.displayName = 'AcknowledgementCheckbox';

const { Forms2Field } = forms2PropTypes;

AcknowledgementCheckbox.propTypes = {
  acknowledgementCheckbox: PropTypes.shape({
    applicantsName: Forms2Field,
    jobTitle: Forms2Field,
    confirmation: Forms2Field,
  }),
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  setConfirmationDateTime: PropTypes.func.isRequired,
  onFieldUpdated: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

AcknowledgementCheckbox.defaultProps = {
  formDisabled: false,
  acknowledgementCheckbox: {},
};
