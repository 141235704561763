'use strict';

/**
 * @typedef { import('../../../types/index.js').FeeDetails } FeeDetails
 * @typedef { import('../types.js').StripeProcessingFeeData } StripeProcessingFeeData
 */

const { pick: getNestedObjectValue } = require('dot-object');

/**
 * calculateFeeDetails
 *
 * When using the Stripe calculation to pass the Stripe fee to the customer,
 * in order to prevent Stripe fees being over the agreed value when rounding
 * the tax on the fee, in certain scenarios Stripe will reduce their
 * effectiveFeeInCents in their calculations by 1 cent.
 *
 * Without matching their effective fee calculation on our end, we would end up
 * overcharging by 1 cent.
 *
 * @param {Object} params
 * @param {number} params.desiredAmountInCents The amount for the charge.
 * @param {Object} params.feeSchedule The formatted provider specific settings for the payment provider.
 * @param {StripeProcessingFeeData} params.providerSpecificFields The provider specific data used to calculate fee information.
 * @returns {FeeDetails} The calculated fee details object.
 */
const calculateFeeDetails = ({ desiredAmountInCents, feeSchedule, providerSpecificFields }) => {
  // Determine the correct fee model.
  const { cardRegion, feeScheduleCurrentTsOverride } = providerSpecificFields;

  const feeModelKey = `creditCard.${cardRegion || 'domestic'}`;

  const cardFeeModel = feeSchedule.creditCard;
  let feeModel;

  if (Array.isArray(cardFeeModel)) {
    // this should be NOW or any datetime for testing purposes
    const referenceTime = feeScheduleCurrentTsOverride
      ? new Date(feeScheduleCurrentTsOverride).getTime()
      : new Date().getTime();

    let feeModelTemp;
    // We could sort the entries first and then find the latest but
    // we don't expect cardFeeModel array to have many items so we just do it in one loop
    cardFeeModel.forEach((feeModelWithValidity) => {
      if (!feeModelWithValidity.validFrom) {
        throw new Error(`Missing validFrom in Stripe fee model ${feeModelWithValidity}`);
      }

      const validFromTime = new Date(feeModelWithValidity.validFrom).getTime();

      if (
        validFromTime <= referenceTime &&
        (!feeModelTemp || validFromTime > new Date(feeModelTemp.validFrom).getTime())
      ) {
        feeModelTemp = feeModelWithValidity;
      }
    });
    feeModel = feeModelTemp ? feeModelTemp[cardRegion || 'domestic'] : undefined;
  } else {
    // original non-array version which has no "valid from" date
    feeModel = getNestedObjectValue(feeModelKey, feeSchedule);
  }

  if (!feeModel) {
    throw new Error(`No Stripe fee model setup for '${feeModelKey}'`);
  }

  // See the FeeDetails type definition for more information.
  // See https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  // See https://support.stripe.com/questions/rounding-rules-for-stripe-fees
  const { percentageFee, fixedFeeCents, feeTaxPercent = 0 } = feeModel;
  const desiredAmountFeeInCents = Math.round(desiredAmountInCents * percentageFee + fixedFeeCents);

  // Estimate the charge amount including desiredAmountInCents + Stripe fees
  const estimatedAmountInCents = Math.round((desiredAmountInCents + fixedFeeCents) / (1 - percentageFee));

  // In the below section we match Stripe's logic for fee tax rounding.

  // As the Stripe fee includes tax, we need to remove the tax portion in order
  // to apply rounding to the base fee and the tax portion separately
  const stripeFeePreTax = (estimatedAmountInCents * percentageFee + fixedFeeCents) / (1 + feeTaxPercent);
  const stripeFeeRound = Math.round(stripeFeePreTax);

  // Calculate the Stripe Fee tax
  const stripeFeeTax = stripeFeeRound * feeTaxPercent;
  const stripeFeeTaxRound = Math.round(stripeFeeTax);

  const stripeFeeTotal = stripeFeeRound + stripeFeeTaxRound;

  // Determine the difference from our original estimated amount, which we will
  // use to modify the final effective amount
  const differenceFromEstimate = desiredAmountInCents - (estimatedAmountInCents - stripeFeeTotal);

  // Final amounts
  const effectiveAmountInCents = estimatedAmountInCents + differenceFromEstimate;
  const effectiveFeeInCents = stripeFeeTotal;

  return {
    desiredAmountInCents,
    desiredAmountFeeInCents,
    effectiveAmountInCents,
    effectiveFeeInCents,
  };
};

module.exports = {
  calculateFeeDetails,
};
