import { getCurrentUsers } from '@sb-firm-management/redux/firm-management';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { withReduxStore } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import * as forms from '@sb-itops/redux/forms2';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { nonNullishIdGuard } from '@sb-itops/type-helpers';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useSubscribedQuery } from 'web/hooks';
import { WidgetClientInfo } from 'web/graphql/queries';
import { FileDetailsDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';

import { SendFilesViaCommunicateSchema } from '../send-files-via-communicate-form';
import { SendFilesViaCommunicateFormModal } from './SendFilesViaCommunicateFormModal';
import { getValidateFn } from '../send-files-via-communicate-form/validation';

const scope = 'send-files-via-communicate';

const hooks = ({ documents, matterId }) => ({
  useContacts: () => {
    const [selectedContacts, onSelectedContactsUpdate] = useState([]);

    return {
      selectedContacts,
      onSelectedContactsUpdate,
    };
  },
  useMatterPartyData: () => {
    const { data, loading, error } = useSubscribedQuery(WidgetClientInfo, {
      variables: {
        id: matterId || '',
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
        },
      },
    });

    if (!matterId) {
      return {
        parties: [],
        partiesLoading: false,
      };
    }

    if (error) {
      throw error;
    }

    // Filter out duplicates
    const debtors = (data?.matter?.billingConfiguration?.debtors || []).filter((d) => {
      if ((data?.matter?.clients || []).find((c) => c.id === d.id)) {
        return false;
      }
      return true;
    });

    return {
      parties: (data?.matter?.clients || []).concat(debtors),
      partiesLoading: loading,
    };
  },
  useSelectedDocuments: () => {
    const [selectedDocuments, onSelectedDocumentsUpdate] = useState(documents);

    return {
      selectedDocuments,
      onSelectedDocumentsUpdate,
    };
  },
  useStaffIds: () => {
    const [staffIds, onStaffIdsUpdate] = useState([]);

    return {
      staffIds,
      onStaffIdsUpdate,
    };
  },
});

const mapStateToProps = (state, { documents, isLiving, isVisible, selectedContacts, staffIds }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formSubmitting } = formSelectors.getFormState(state);

  return {
    scope,
    documents,
    isLiving,
    isVisible,
    isSubmitDisabled: formSubmitting,
    selectedContacts,
    staffIds,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    selectedContacts,
    isLiving,
    onClose,
    onSelectedContactsUpdate,
    onSelectedDocumentsUpdate,
    onStaffIdsUpdate,
    matterId,
    modalId,
    selectedDocuments,
    staffIds,
  },
) => {
  const { operations: formOperations } = withScopedFeature({ scope })(forms);

  const authToken = authSelectors.getAuthToken(store.getState());

  const onCloseModal = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    if (modalId) {
      setModalDialogHidden({ modalId });
    }
  };

  return {
    authToken,
    isLiving,
    matterId,
    selectedContacts,
    selectedDocuments,
    staffIds,
    onProcess: async (event) => {
      event.preventDefault();
      await dispatch(
        formOperations.validateForm({
          schema: SendFilesViaCommunicateSchema,
          validateFn: getValidateFn(selectedContacts),
        }),
      );

      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              await sendPayload({
                authToken,
                formFieldValues,
                selectedContacts,
                isLiving,
                selectedDocuments,
                matterId,
                staffIds,
              });

              onCloseModal();
              messageDisplay.success('Message sent successfully');
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to send message');
      }
    },
    onCloseModal,
    onSelectedContactsUpdate,
    onSelectedDocumentsUpdate,
    onStaffIdsUpdate,
  };
};

const sendPayload = async ({
  authToken,
  formFieldValues,
  selectedContacts,
  isLiving,
  selectedDocuments,
  matterId,
  staffIds,
}) => {
  const { isMfaRequired, message: communicateMessage } = formFieldValues;

  const matter = getMatterById(matterId);

  const externalRoles = [];

  const userIds = getCurrentUsers()
    .filter((user) => staffIds.includes(user.id))
    .map((user) => user.userId);

  for (let i = 0; i < selectedContacts.length; i += 1) {
    externalRoles.push({
      externalRef: selectedContacts[i].id,
      email: selectedContacts[i].email,
      firstName: selectedContacts[i].firstName,
      lastName: selectedContacts[i].lastName,
      initials: undefined, // optional so ignoring for now
      roleDisplay: undefined, // optional so ignoring for now
    });
  }

  const filesToAdd = [];

  if (selectedDocuments.length > 0) {
    const selectedDocumentKeys = selectedDocuments.map((document) => document.data.id);

    const apolloClient = getApolloClient();
    const fileDetails = await apolloClient.query({
      query: FileDetailsDocument,
      variables: { ids: selectedDocumentKeys },
      fetchPolicy: 'network-only',
    });

    const findVersion = (key) => {
      const details = (fileDetails.data.fileDetails || []).filter(nonNullishIdGuard);
      const found = details.find((i) => i.id === key);
      if (found && found.versions && found.versions[0]) {
        return found.versions[0];
      }
      return undefined;
    };

    let filesNotUploadedYet = false;

    for (let i = 0; i < selectedDocuments.length; i += 1) {
      const document = selectedDocuments[i];
      if (document.type === 'folder') {
        filesToAdd.push({
          id: document.data.id,
          fileName: document.data.name,
          isFolder: true,
          isLivingFile: isLiving,
        });
      } else if (document.type === 'file') {
        const fileId = document.data.id;
        const fileVersion = findVersion(fileId);
        if (fileVersion?.isUploaded === false || fileVersion?.isCancelled) {
          messageDisplay.error(`${document.data.name + document.data.fileExtension} has not finished uploading yet`);
          filesNotUploadedYet = true;
        } else {
          filesToAdd.push({
            id: fileId,
            versionId: fileVersion?.versionId,
            fileName: `${document.data.name}${document.data.fileExtension}`,
            size: document.data.sizeBytes,
            isFolder: false,
            isLivingFile: isLiving,
          });
        }
      }
    }

    if (filesNotUploadedYet) {
      throw new Error('Files not all uploaded');
    }
  }

  const message = {
    matterId,
    authToken,
    message: communicateMessage,
    conversationDetails: {
      matterId,
      userIds,
      description: matter?.description || '',
      externalRoles,
      descriptionAutomation: matter?.description || '',
      matterNumber: matter.matterNumber || '',
      isMfaRequired,
    },
    files: filesToAdd,
    source: 'SendFilesViaCommunicateFormModal',
  };

  await dispatchCommand({
    message,
    type: 'ItOps.Communicate.Messages.AddFilesToCommunicate',
  });
};

export const SendFilesViaCommunicateFormModalContainer = withReduxStore(
  composeHooks(hooks)(connect(mapStateToProps, mapDispatchToProps)(SendFilesViaCommunicateFormModal)),
);

SendFilesViaCommunicateFormModalContainer.displayName = 'SendFilesViaCommunicateFormModalContainer';

SendFilesViaCommunicateFormModalContainer.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  isLiving: PropTypes.bool,
  matterId: PropTypes.string,
  modalId: PropTypes.string,
  onClose: PropTypes.func,
};

SendFilesViaCommunicateFormModal.defaultProps = {
  documents: undefined,
  isLiving: undefined,
  matterId: undefined,
  modalId: undefined,
  onClose: () => {},
};

export default SendFilesViaCommunicateFormModalContainer;
