// DEVEXTREME SHOULD NOT LEAVE THIS FOLDER

import React, { ComponentProps, memo, useEffect, useRef, useState } from 'react';
import { Scheduler, View } from 'devextreme-react/scheduler';
import Calendar from 'devextreme/ui/calendar';
import Popover from 'devextreme/ui/popover';
// Import CSS for the scheduler
import 'devextreme/dist/css/dx.light.css';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { getRegion } from '@sb-itops/region';
import { getFirstDayOfWeek } from 'web/business-logic/calendar';
import { MatterSummary, Person } from 'types';
import Styles from './Schedule.module.scss';
import { AddEventModal } from '../add-event-modal';
import { AppointmentComponent } from './appointment-component';

const isBusinessDay = (date: Date) => ![0, 6].includes(moment(date).day());

const isBusinessHours = (date: Date) => {
  const time = moment(date);
  const opening = moment(date).set({ h: 8, m: 0 });
  const closing = moment(date).set({ h: 18, m: 0 });

  // if it is the weekend
  if (!isBusinessDay(date)) {
    return false;
  }

  if (!time.isSameOrAfter(opening) || !time.isBefore(closing)) {
    return false;
  }
  return true;
};

const DateCell = (props) => {
  const { date } = props;
  return <div>{moment(date).format('dddd, D MMMM')}</div>;
};

export const DataCell = ({ startDate, children }) => {
  const isGreyedDate = isBusinessHours(startDate);

  let cssClasses = '';

  if (!isGreyedDate) {
    cssClasses = Styles.outsideBusinessHours;
  }

  return <div className={cssClasses}>{children}</div>;
};

export const MonthDataCell = ({ text }) => <div className={Styles.paddingRight}>{text}</div>;

export interface ISchedulerEditingProps {
  height?: number | string;
  editing: true;
  loading?: boolean;
  dataSource: DataSource;
  editModelData: any;
  loggedInStaff: Person;
  attendees: { id: string; name: string }[];
  matterSummaries: MatterSummary[];
  selectedView?: ComponentProps<typeof Scheduler>['defaultCurrentView'];
  fetchMore: (date: moment.Moment, endDate: moment.Moment) => void;
  onCloseModal: () => void;
  setSelectedView: (view: string) => void;
  onDeleteAppointment: (options: { appointmentData: any }) => Promise<void>;
  onSaveAppointment: (options: { appointmentData: any }) => Promise<void>;
  onAppointmentOpen: (event: any) => void;
  setSelectedCells: (selectedCells: any[]) => void;
  selectedDate?: Date;
}

export interface ISchedulerMiniProps {
  height?: number | string;
  editing: false;
  selectedView?: ComponentProps<typeof Scheduler>['defaultCurrentView'];
  setSelectedView: (view: string) => void;
  setSelectedCells: (selectedCells: any[]) => void;
  loading?: boolean;
  dataSource?: DataSource;
  editModelData?: any;
  loggedInStaff?: Person;
  attendees?: { id: string; name: string }[];
  matterSummaries?: MatterSummary[];
  minDate?: Date;
  fetchMore: (date: moment.Moment, endDate: moment.Moment) => void;
  onCloseModal?: () => void;
  onDeleteAppointment?: (options: { appointmentData: any }) => Promise<void>;
  onSaveAppointment?: (options: { appointmentData: any }) => Promise<void>;
  onAppointmentOpen?: (event: any) => void;
  selectedDate?: Date;
}

const disableAppointmentTooltip = (event) => {
  // Disable the appointment tooltip without disabling the 'show more' popup
  if (!event.targetElement?.[0]?.classList?.contains('dx-scheduler-appointment-collector')) {
    // eslint-disable-next-line no-param-reassign
    event.cancel = true;
  }
};

// Temp variable used for capturing which cells are selected. The inbuilt event system for devextreme sucks for this, so we have to determine outselves when the user has finished selecting
// If we constantly update the selection, it will cause the calendars to rerender.
// Whenever any calendar on screen rerenders, it iterferes with the selection event on ALL rendered calendars, so we must take care to not rerender while a selection is happening
let selectedCells;

const views: ComponentProps<typeof Scheduler>['defaultCurrentView'] = ['day', 'workWeek', 'week', 'month'];

export const Schedule = memo(
  ({
    dataSource,
    height = '100%',
    editing,
    editModelData,
    onSaveAppointment,
    onDeleteAppointment,
    onAppointmentOpen,
    onCloseModal,
    selectedView = 'day',
    setSelectedView,
    attendees,
    matterSummaries,
    loggedInStaff,
    fetchMore,
    loading,
    selectedDate,
    setSelectedCells,
  }: ISchedulerEditingProps | ISchedulerMiniProps) => {
    const firstDayOfWeek = getFirstDayOfWeek(getRegion());
    const isFirstContentRender = useRef(true);
    const [currentDate, setCurrentDate] = useState(new Date());
    const onQuickSaveAppointment = ({ appointmentData }) =>
      editing &&
      onSaveAppointment({
        appointmentData: {
          ...appointmentData,
          startTime: appointmentData.startDate,
          endTime: appointmentData.endDate,
          matterId: appointmentData.matterId || '',
        },
      });

    // Hack, without this the first render will show a dropdown for view selection rather than the buttons.
    // The issue is fixed after the first view change, so we will trigger one immediately
    useEffect(() => {
      if (editing) {
        setSelectedView(selectedView === 'day' ? 'month' : 'day');
        setTimeout(() => {
          setSelectedView(selectedView);
        });
      }
    }, []);

    useEffect(() => {
      if (selectedDate && selectedDate !== currentDate) {
        setCurrentDate(selectedDate);
      }
    }, [selectedDate]);

    const updateSelectedCellsOnSelectionFinish = () => {
      if (!editing && selectedCells && selectedCells.length) {
        setSelectedCells(selectedCells);
        const diff = moment(selectedCells[selectedCells.length - 1].endDate).diff(selectedCells[0].startDate, 'days');
        const weekday = moment(selectedCells[0].startDate).isoWeekday();
        const crossesWeekend = diff + weekday - 1 > 5;
        const crossesWeek = diff + weekday - 1 > 7;
        if (crossesWeekend && selectedView === 'workWeek') {
          setSelectedView('week');
          // Leave user on currently selected view as it is always bigger than 1 day
        } else if (diff > 1 && !crossesWeekend && ['day', 'workWeek'].includes(selectedView)) {
          // Workweek if the diff doesnt cross a weekend
          setSelectedView('workWeek');
        } else if (diff > 1 && !crossesWeek && selectedView !== 'month') {
          setSelectedView('week');
        } else if (crossesWeek) {
          setSelectedView('month');
        }
        fetchMore(
          moment(selectedCells[0].startDate).startOf('month').subtract(1, 'week'),
          moment(selectedCells[0].startDate).endOf('month').add(1, 'week'),
        );
      }
      selectedCells = undefined;
    };

    return (
      <div
        className={Styles.mainCalendar}
        onMouseUp={updateSelectedCellsOnSelectionFinish}
        onMouseLeave={updateSelectedCellsOnSelectionFinish}
      >
        <Scheduler
          dataSource={dataSource}
          editing={editing}
          height={height}
          recurrenceRuleExpr="rRULE"
          onContentReady={(e) => {
            if (isFirstContentRender.current) {
              // We would only like to scroll down on the first load, not after any subsequent updates
              const viewStartDate = new Date(e.component.getStartViewDate());
              viewStartDate.setHours(12);
              viewStartDate.setMinutes(0);
              e.component.scrollTo(viewStartDate);
              // To prevent a jump after the content loads, we want to continually set the viewStartDate until the content is loaded for the first time
              if (!loading) {
                isFirstContentRender.current = false;
              }
            }

            // Hacky workaround to use the 'showTodayButton' feature on the popup nav calendar buried deep in the devextreme component
            // Documented here: https://supportcenter.devexpress.com/ticket/details/t608208/scheduler-add-the-today-button-to-the-toolbar
            // and here: https://supportcenter.devexpress.com/ticket/details/t644979/devextreme-scheduler-mvc-how-to-add-a-today-button-to-scheduler-s-default-calendar
            const element = document.querySelector('.dx-scheduler-navigator-calendar-popover');

            if (!element || (element as any).initComplete) {
              return;
            }
            (element as any).initComplete = true;

            Popover.getInstance(element).on('showing', () => {
              const cal = document.querySelector('.dx-scheduler-navigator-calendar');
              if (!cal) {
                return;
              }
              Calendar.getInstance(cal).option('showTodayButton', true);
            });
          }}
          onOptionChanged={(e) => {
            if (e.name === 'currentView') {
              setSelectedView(e.value);
            }
            if (e.name === 'selectedCellData' && e.value) {
              selectedCells = e.value;
            }
            if (e.name === 'currentDate') {
              setCurrentDate(e.value);
              if (editing) {
                fetchMore(
                  moment(e.value).startOf('month').subtract(1, 'week'),
                  moment(e.value).endOf('month').add(1, 'week'),
                );
              }
            }
          }}
          firstDayOfWeek={firstDayOfWeek}
          shadeUntilCurrentTime
          currentView={editing ? selectedView : 'month'}
          onAppointmentFormOpening={onAppointmentOpen}
          onAppointmentAdded={onQuickSaveAppointment}
          onAppointmentUpdated={onQuickSaveAppointment}
          onAppointmentDeleted={onDeleteAppointment}
          appointmentComponent={AppointmentComponent}
          onAppointmentTooltipShowing={disableAppointmentTooltip}
          useDropDownViewSwitcher={false}
          visible
          views={views}
          currentDate={currentDate}
        >
          <View type="day" dataCellRender={DataCell} />
          <View type="workWeek" dataCellRender={DataCell} dateCellRender={DateCell} />
          <View type="week" dataCellRender={DataCell} dateCellRender={DateCell} />
          <View type="month" dataCellRender={MonthDataCell} />
        </Scheduler>
        {/* portal-root is used by the appointment component (passed to the Scheduler) as a mount point.  */}
        {/* It is necessary to allow the child to break out of dev-extreme and avoids all the rendering issues  */}
        {editing && <div id="portal-root" />}
        {editing && !!editModelData && (
          <AddEventModal
            attendees={attendees}
            matterSummaries={matterSummaries}
            appointmentData={editModelData}
            onDeleteAppointment={onDeleteAppointment}
            onSaveAppointment={onSaveAppointment}
            onClose={onCloseModal}
            loggedInStaff={loggedInStaff}
          />
        )}
      </div>
    );
  },
);
