'use strict';

// sbNotifiedOperation executes an async operation and returns a Promise which is resolved upon receipt of a specific notification.
// The notification causing the resolution is passed to the resolve function.
// Once asyncOpFnP function is completed, a timer is started which limits the amount of time the notified operation can wait for the
// notification message before being considered a failure.
// A failure due to any of the following causes the returned promise to be rejected;
// * A rejection of the promise returned from executing asyncOpFnP
// * Timeout expiry
//  An optional processNotifcation can be provided which causes the returned promise's notify callback to be called whenever an
// instance of the processNotifcation is received.
angular.module('sb.billing.webapp').factory('sbNotifiedOperationP', ($q, $timeout, sbDispatcherService, sbUuidService) => {
  // opts: {requestId:uuid, comletionNotification:string, progressNotification:string, completionFilterFn:(), progressFilterFn:(), timeoutMs:number, waitForever: boolean}
  return (asyncOpFnP, opts) => {
    const requestId = opts.requestId || sbUuidService.get();

    // Set up the options for this notified promise operation.
    const defaultOpts = {
      requestId,
      completionFilterFn: message => message.requestId === requestId,
      progressFilterFn: message => message.requestId === requestId,
      errorFilterFn: message => message.requestId === requestId,
      timeoutMs: 10000,
      waitForever: false,
      progressResetsTimeout: false
    };

    opts = _.extend(defaultOpts, opts);

    // This deferred's promise is only resolved once the completion notification is received.
    const deferred = $q.defer();
    let timerP;

    // Called on completion, whether successful or failed, to unsubscribe from any notifcations for which we were listening.
    const unregisterFromActions = () => {
      sbDispatcherService.unregisterSyncAction(opts.completionNotification, opts.requestId);
      
      if (!_.isEmpty(opts.progressNotification)) {
        sbDispatcherService.unregisterSyncAction(opts.progressNotification, opts.requestId);
      }

      if (!_.isEmpty(opts.errorNotification)) {
        sbDispatcherService.unregisterSyncAction(opts.errorNotification, opts.requestId);
      }
    };

    const restartTimeout = () => {
      if (!opts.waitForever) {
        $timeout.cancel(timerP);
        timerP = $timeout(() => {
          const err = new Error(`Notified promise operation ${opts.requestId} did not receive notification '${opts.completionNotification}' within ${opts.timeoutMs}ms`);
          err.operationTimedOut = true;
          onFailureFn(err);
        }, opts.timeoutMs);
      }
    };

    // Called when the completion notification is received.
    const onCompleteFn = (notification) => {
      $timeout.cancel(timerP);
      unregisterFromActions();
      deferred.resolve(notification);
    };

    // Called when a progress notification is received.
    const onProgressFn = (notification) => {
      if (opts.progressResetsTimeout) {
        restartTimeout();
      }
      deferred.notify(notification);
    };

    // Called when either the passed promise errors or when the completion notification was not received in time.
    const onFailureFn = err => {
      unregisterFromActions();
      deferred.reject(err);
    };

    // Register handlers for the completion and progress (if present) notifications.
    sbDispatcherService.registerSyncAction(opts.completionNotification, opts.requestId, onCompleteFn, opts.completionFilterFn);
    
    if (!_.isEmpty(opts.progressNotification)) {
      sbDispatcherService.registerSyncAction(opts.progressNotification, opts.requestId, onProgressFn, opts.progressFilterFn);
    }

    if (!_.isEmpty(opts.errorNotification)) {
      sbDispatcherService.registerSyncAction(opts.errorNotification, opts.requestId, onFailureFn, opts.errorFilterFn);
    }

    // Wait for the passed
    asyncOpFnP()
      .then(() => {
        restartTimeout();
      })
      .catch(onFailureFn);

    return deferred.promise;
  };
});
