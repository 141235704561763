import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'trust-retainers',
  keyPath: 'matterId',
  ngCacheName: 'sbTrustRetainersService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;
