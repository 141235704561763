import { TSchemeValuesFor, schemeMappings } from '@sb-itops/region-schemes';

export const hasPhoneAreaCode = (phoneNumberScheme: TSchemeValuesFor<'phoneNumberScheme'>): boolean => {
  switch (phoneNumberScheme) {
    case schemeMappings.phoneNumberScheme.US:
      return true;
    case schemeMappings.phoneNumberScheme.AU:
    case schemeMappings.phoneNumberScheme.GB:
      return false;
    default:
      throw new Error(`Unimplemented phoneNumberScheme ${phoneNumberScheme}`);
  }
};
