angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/switches';

  $stateProvider
    .state('home.billing.support.switches', {
      url: '/switches',
      templateUrl: BASE_DIRECTORY + '/switches.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});