angular.module('sb.billing.webapp').controller('sbSettingsActivitiesEditController',function($state, $stateParams){
  'use strict';
  var that = this;

  that.activityId = $stateParams.activityId;

  that.gotoList = function () {
    $state.go('settings.activities.list');
  };

  that.deleteActivity = function () {
    $state.go('settings.activities.delete', {activityId: that.activityId});
  }
});
