angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.intake', {
      template: '<sbb-intake-iframe mode="\'settings\'" style="flex: 1;"></sbb-intake-iframe>',
      url: '/intake',
      data: {
        navHighlights: ['intake']
      }
    });
});
