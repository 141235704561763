'use strict';

import { featureActive, featureData } from "@sb-itops/feature";
import { getProductTier, getAccountId } from "web/services/user-session-management";
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').component('sbMyobIntegration', {
  templateUrl: 'ng-components/myob/myob-integration.html',
  controller: function(
    sbLoggerService,
    $scope,
    sbMessageDisplayService,
    sbMyobIntegrationService,
    sbMyobBusinessService,
    sbMyobValidator,
    $timeout,
    $window,
    sbLocalisationService,
  ) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbMyobIntegration');

    // Yes, this looks a bit whack, see BB-11523
    // We want to change None to Select... for the mandatory accounts
    // Client Cost Liability is technically not mandatory, but we want to force them to select "Do not integrate" instead of defaulting to None
    // as some clients were missing this and getting confused
    // basically Select..., None and Do not integrate all do the same thing, which is nothing
    // We can't just change the labels as we need Select AND Do not integrate for CCL accounts
    const DEFAULT_EMPTY_ACCOUNT = { name: 'None', id: -1 };
    const DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS = { name: 'Select...', id: -1 };
    const DO_NOT_INTEGRATE = { name: `Do not integrate ${sbLocalisationService.t('expense')} payments`, id: -2 };

    const accountId = getAccountId();

    ctrl.t = sbLocalisationService.t;

    ctrl.save = save;
    ctrl.myobViewDataLoaded = false;
    ctrl.disconnectMyob = disconnectMyob;
    ctrl.isWaiting = false;
    ctrl.businessSelected = businessSelected;
    ctrl.onLoginStarted = onLoginStarted;
    ctrl.onLoginTimeout = onLoginTimeout;
    ctrl.onLoginComplete = onLoginComplete;
    ctrl.onChangeIntegrateGeneralLedgerTransactions = onChangeIntegrateGeneralLedgerTransactions;
    ctrl.onChangeInvoiceIntegration = onChangeInvoiceIntegration;
    ctrl.onConfirmEnableInvoiceIntegration = onConfirmEnableInvoiceIntegration;
    ctrl.onCancelEnableInvoiceIntegration = onCancelEnableInvoiceIntegration;
    ctrl.onClickHelpLink = onClickHelpLink;
    ctrl.onDownloadButtonClick = onDownloadButtonClick;
    ctrl.getOfficePaymentsTooltipText = getOfficePaymentsTooltipText;

    ctrl.hardAndSoftCostFeatureEnabled = featureActive('BB-13352');
    ctrl.featureActive = featureActive;

    ctrl.$onInit = () => {
      ctrl.view = {};
      ctrl.view.myobInvoiceAndPaymentIntegrationFeatureEnabled = hasFacet(facets.integrationMyobInvoiceAndPayment) && featureActive('BB-10293')
      ctrl.view.showDownloadButton = false;
      ctrl.view.showButtonSpinner = false;
      ctrl.view.showInvoiceIntegrationConfirmationModal = false;
      // setting isInvoiceIntegrationEnabledOnLoad to true on init to hide the toggle before the MYOB setting loads. This is to avoid showing the toggle to new firms that haven't connected to MYOB yet. The invoice integration toggle should only show for user who aleady connected to MYOB but not having this enabled yet.
      ctrl.view.isInvoiceIntegrationEnabledOnLoad = true;
      ctrl.view.hasEnabledInvoiceIntegrationOnCurrentSave = false;

      ctrl.errors = {};

      ctrl.model = {
        integrateGeneral: false,
        integrateTrust: false,
        operatingBankAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        legalFeesAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientExpensesAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientSoftCostExpensesAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientCostLiabilityAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientSoftCostLiabilityAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        badDebtAccount: DEFAULT_EMPTY_ACCOUNT,
        interestAccount: DEFAULT_EMPTY_ACCOUNT,
        surchargeAccount: DEFAULT_EMPTY_ACCOUNT,
      };

      if (isAuthenticated()) {
        // this will end up triggering a cache update.
        refreshDefaultViewData();
      }

      ctrl.validator = new sbMyobValidator(() => ctrl.model, () => ctrl.view);
    };

    $scope.$on('smokeball-data-update-sbMyobIntegrationService', () => {
      if (isAuthenticated()) {
        sbMessageDisplayService.dismissGroup('CONNECT-WARNING');
        refreshDefaultViewData();
        loadSettingsViewData();

      }
    });

    $scope.$on('smokeball-data-update-sbMyobBusinessService', () => {
      if (isAuthenticated()) {
        sbMessageDisplayService.dismissGroup('CONNECT-WARNING');
        refreshDefaultViewData();
        loadSettingsViewData();
      }
    });

    function onLoginStarted() {
      // when we are logging in to Myob by user iteraction we do not need to refresh the account and businesses.
      ctrl.myobViewDataLoaded = true;
      ctrl.isWaiting = true;
    }

    function onLoginTimeout() {
      ctrl.isWaiting = false;
    }

    function onLoginComplete() {
      log.info('Login complete');
      ctrl.isWaiting = false;
      ctrl.myobViewDataLoaded = false;
      refreshDefaultViewData();
    }

    function getOfficePaymentsTooltipText() {
      return `When a ${ctrl.t('expense')} is marked as paid, the payment details will be applied to this account`;
    }

    function onChangeIntegrateGeneralLedgerTransactions (name, value) {
      ctrl.model.integrateGeneral = value;
    }

    function onChangeInvoiceIntegration (name, value) {
      if (value) {
        ctrl.view.showInvoiceIntegrationConfirmationModal = true;
      } else {
        ctrl.model.invoiceIntegrationEnabled = false;
      }
    }

    function onConfirmEnableInvoiceIntegration () {
      ctrl.view.showInvoiceIntegrationConfirmationModal = false;
      ctrl.model.invoiceIntegrationEnabled = true;
    }

    function onCancelEnableInvoiceIntegration () {
      ctrl.view.showInvoiceIntegrationConfirmationModal = false;
    }

    function onClickHelpLink() {
      const tier = getProductTier();
      const helpLink = featureActive('BB-10615')
        ? featureData('BB-10615')[tier || 'SMK001']
        : 'https://support.smokeball.com/hc/en-au';
      $window.open(helpLink, '_blank');
    }

    async function onDownloadButtonClick() {
      ctrl.view.showButtonSpinner = true;
      if (ctrl.view.downloadLink) {
        // download link exists after saving the enable invoice integration setting
        sbMyobBusinessService.downloadInvoiceReport({ url: ctrl.view.downloadLink, timestamp: ctrl.model.invoiceIntegrationEnabledAt })
        ctrl.view.showButtonSpinner = false;
      } else {
        // otherwise, fetch a new one
        try {
          const res = await sbMyobBusinessService.getPreSignedUrlOfOutstandingInvoiceReport({ accountId, timestamp: ctrl.model.invoiceIntegrationEnabledAt });
          if (res.body.signedUrl) {
            sbMyobBusinessService.downloadInvoiceReport({ url: res.body.signedUrl, timestamp: ctrl.model.invoiceIntegrationEnabledAt })
            ctrl.view.showButtonSpinner = false;
          } else {
            ctrl.errors.downloadLink = new Error('Failed to fetch outstanding invoice report');
            sbMessageDisplayService.error('Failed to fetch outstanding invoice report');
            ctrl.view.showButtonSpinner = false;
          }
        } catch (err) {
          ctrl.errors.downloadLink = err;
          log.error('Failed to fetch outstanding invoice report', err);
          sbMessageDisplayService.error('Failed to fetch outstanding invoice report');
          ctrl.view.showButtonSpinner = false;
        }
      }
    }

    function refreshDefaultViewData() {
      log.info('refreshing MYOB data');
      if (!ctrl.myobViewDataLoaded) {
        // we do not know when the notification comes, we need to remove this indicator in the cache event listener
        ctrl.isWaiting = true;
        ctrl.myobViewDataLoaded = true;

        log.info('refreshing MYOB business lists');
        // this will send the notification to .NET that will refresh the businesses for this user
        // since we do not know if the businesses where updated inside Myob or not
        sbMyobIntegrationService.refreshBusinesses();
        // in case there is an issue loading the business in MYOB show a warning
        $timeout(() => {
          if (ctrl.isWaiting) {
            sbMessageDisplayService.warn(
              sbMessageDisplayService
              .builder()
              .text('Loading the MYOB business list is taking longer than expected.')
              .group('CONNECT-WARNING')
            );
            ctrl.isWaiting = false;
          }
        }, 15000);

        // if we have the settings pre loaded, we need to force an account refresh since we do not know if
        // the accounts were updated in Myob or not.
        if (areSettingsLoaded()) {
          log.info('refreshing MYOB business accounts');
          const settings = sbMyobBusinessService.getSettings();
          sbMyobBusinessService.refreshAccounts(settings.businessId);
        }
      }
    }

    function loadSettingsViewData() {
      const settingsLoaded = areSettingsLoaded();
      const isUserAuthenticated = isAuthenticated();

      if (isUserAuthenticated) {
        loadBusinessesList();

        if (settingsLoaded) {
          loadMandatoryFields();
        }

        if (ctrl.model.companyFile) {
          loadAccountsLists(ctrl.model.companyFile.id, ctrl.integrationVersion || 0);
          ctrl.isWaiting = false;
          sbMessageDisplayService.dismissGroup('CONNECT-WARNING');
        }

        // after the company is loaded and the accounts list also, if we have a pre saved
        // setting we have to load the account view values
        if (settingsLoaded) {
          loadViewAccountsFromSettings();
        }

        ctrl.validator = new sbMyobValidator(() => ctrl.model, () => ctrl.view);
      }
    }

    function resetModel() {
      ctrl.model = {
        integrateGeneral: false,
        integrateTrust: false,
        operatingBankAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        legalFeesAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientExpensesAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientSoftCostExpensesAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientCostLiabilityAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        clientSoftCostLiabilityAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
        badDebtAccount : DEFAULT_EMPTY_ACCOUNT,
        interestAccount : DEFAULT_EMPTY_ACCOUNT,
        surchargeAccount: DEFAULT_EMPTY_ACCOUNT,
      };
    }

    function businessSelected(businessId) {
      // refresh the accounts based on the new business selection
      ctrl.isWaiting = true;
      sbMyobBusinessService.refreshAccounts(businessId);

      // in case there is an issue loading the business in MYOB gracefully show an error
      $timeout(() => {
        if (ctrl.isWaiting) {
          clearUnusedAccounts();
          sbMessageDisplayService.warn(
            sbMessageDisplayService
            .builder()
            .text('Loading the MYOB company file is taking longer than expected.')
            .group('CONNECT-WARNING')
          );
          ctrl.isWaiting = false;
        }
      }, 15000);
    }

    function areSettingsLoaded() {
      return !!sbMyobBusinessService.getSettings();
    }

    function loadBusinessesList() {
      const businessesList = sbMyobIntegrationService.getBusinesses();
      if (businessesList && businessesList.length) {
        ctrl.isWaiting = false;
      }
    
      ctrl.view.companyFiles = businessesList;
    }

    async function disconnectMyob() {
      try {
        await sbMyobIntegrationService.disableP();
        resetModel();
        sbMessageDisplayService.success(sbMessageDisplayService.builder().text('Disconnected from MYOB'));
        ctrl.isWaiting = false;
        sbMessageDisplayService.dismissGroup('CONNECT-WARNING');
      } catch (ex) {
        log.info('error disconnecting from MYOB');
      }
    }

    function loadAccountsLists(businessId, integrationVersion) {
      const accounts = sbMyobBusinessService.getBankAccounts(businessId, integrationVersion);
      ctrl.view.operatingBankAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.operatingBankAccounts || [])];
      ctrl.view.legalFeesAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.legalFeesAccounts || [])];
      ctrl.view.clientExpensesAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.clientExpensesAccounts || [])];
      ctrl.view.clientSoftCostExpensesAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.clientSoftCostExpensesAccounts || [])];
      ctrl.view.clientCostLiabilityAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, DO_NOT_INTEGRATE, ...(accounts.clientCostLiabilityAccounts || [])];
      ctrl.view.clientSoftCostLiabilityAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, DO_NOT_INTEGRATE, ...(accounts.clientSoftCostLiabilityAccounts || [])];
      ctrl.view.badDebtAccounts = [DEFAULT_EMPTY_ACCOUNT, ...(accounts.badDebtAccounts || [])];
      ctrl.view.interestAccounts = [DEFAULT_EMPTY_ACCOUNT, ...(accounts.interestAccounts || [])];
      ctrl.view.surchargeAccounts = [DEFAULT_EMPTY_ACCOUNT, ...(accounts.surchargeAccounts || [])];
    }

    function findAccountById(account) {
      return [
        ...(ctrl.view.operatingBankAccounts || []),
        ...(ctrl.view.legalFeesAccounts || []),
        ...(ctrl.view.clientExpensesAccounts || []),
        ...(ctrl.view.clientSoftCostExpensesAccounts || []),
        ...(ctrl.view.badDebtAccounts || []),
        ...(ctrl.view.interestAccounts || []),
        ...(ctrl.view.surchargeAccounts || []),
      ].find(a => a.id === account.id);
    }

    /*eslint-disable */
    // id can be string or in here
    // This blows up if account is undefined, assuming undefined is equivalent to DEFAULT_EMPTY_ACCOUNT in there absence of any other plan
    const isDefaultEmptyAccount = account => !account || account.id == DEFAULT_EMPTY_ACCOUNT.id || account.id == DO_NOT_INTEGRATE.id;
    /*eslint-enable */

    // here we can also make sure any selected account matches the latest
    // loaded accounts
    function replaceWithDefaultEmpty(account) {
      return accountPresent(account) ? findAccountById(account) : DEFAULT_EMPTY_ACCOUNT;
    }

    function replaceWithMandatoryDefaultEmpty(account) {
      return accountPresent(account) ? findAccountById(account) : DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
    }

    function loadViewAccountsFromSettings() {
      const settings = sbMyobBusinessService.getSettings();
      if (
        (!ctrl.model.companyFile || ctrl.model.companyFile.id === settings.businessId) &&
        ctrl.model.integrateGeneral
        ) {
          ctrl.model.operatingBankAccount = replaceWithMandatoryDefaultEmpty(settings.operatingBankAccount);
          ctrl.model.legalFeesAccount = replaceWithMandatoryDefaultEmpty(settings.legalFeesAccount);
          // Previously we show all account as options, with feature(BB-13352) on we only show income and asset type for clientExpensesAccount options. Therefore the previous selection could no longer be an option, in this case we show default empty account
          ctrl.model.clientExpensesAccount = replaceWithMandatoryDefaultEmptyForSpecificField(settings.clientExpensesAccount, ctrl.view.clientExpensesAccounts);
          ctrl.model.clientSoftCostExpensesAccount = loadSelectedClientSoftCostExpensesAccount(settings);
          // Previously we show all account as options, with feature(BB-13352) on we only show expense and asset type for clientCostLiabilityAccount options. Therefore the previous selection could no longer be an option, in this case we show default empty account
          ctrl.model.clientCostLiabilityAccount = hasSelectedDoNotIntegrateOfficePayments(settings) ? DO_NOT_INTEGRATE : replaceWithMandatoryDefaultEmptyForSpecificField(settings.clientCostLiabilityAccount, ctrl.view.clientCostLiabilityAccounts);
          ctrl.model.clientSoftCostLiabilityAccount = loadSelectedClientSoftCostLiabilityAccount(settings);
          ctrl.model.badDebtAccount = replaceWithDefaultEmpty(settings.badDebtAccount);
          ctrl.model.interestAccount = replaceWithDefaultEmpty(settings.interestAccount);
          ctrl.model.surchargeAccount = replaceWithDefaultEmpty(settings.surchargeAccount);
      } else {
        clearUnusedAccounts();
      }
    }

    function replaceWithMandatoryDefaultEmptyForSpecificField(account, accountOptions) {
      const selectedAccountOption =  accountPresent(account) && (accountOptions || []).find(a => a.id === account.id);
      return selectedAccountOption ? selectedAccountOption : DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
    }

    function loadSelectedClientSoftCostExpensesAccount(myobSettings) {
      if (myobSettings.clientSoftCostExpensesAccount) {
        return replaceWithMandatoryDefaultEmpty(myobSettings.clientSoftCostExpensesAccount)
      } else {
        // clientExpensesAccount has income and asset types as option, while clientSoftCostExpensesAccount only has income type, so if clientExpensesAccount is an asset account, we show default empty account
        return replaceWithMandatoryDefaultEmptyForSpecificField(myobSettings.clientExpensesAccount, ctrl.view.clientSoftCostExpensesAccounts);
      }
    }


    function loadSelectedClientSoftCostLiabilityAccount(myobSettings) {
      // if clientSoftCostLiabilityAccount has account stored, we show it
      if (myobSettings.clientSoftCostLiabilityAccount) {
        return replaceWithMandatoryDefaultEmpty(myobSettings.clientSoftCostLiabilityAccount);
      } else {
        // if clientSoftCostLiabilityAccount is null, it could be two situations:
        // situation 1. User has saved with feature(BB-13352) on but selected DO_NOT_INTEGRATE for clientSoftCostLiabilityAccount, in this case clientSoftCostExpensesAccount would have account stored
        if (myobSettings.clientSoftCostExpensesAccount) {
          return DO_NOT_INTEGRATE;
        } else {
          // situation 2. User haven't saved with soft cost feature(BB-13352) on, then we show account based on hard cost.
          // if clientCostLiabilityAccount is DO_NOT_INTEGRATE, then we show DO_NOT_INTEGRATE for clientSoftCostLiabilityAccount as well
          if (ctrl.model.clientCostLiabilityAccount && ctrl.model.clientCostLiabilityAccount.id === DO_NOT_INTEGRATE.id) {
            return DO_NOT_INTEGRATE;
          } else {
            // clientCostLiabilityAccount has expense and asset types as option, while clientSoftCostLiabilityAccount only has expense type, so if clientCostLiabilityAccount is an asset account, we show default empty account
            return  replaceWithMandatoryDefaultEmptyForSpecificField(myobSettings.clientCostLiabilityAccount, ctrl.view.clientSoftCostLiabilityAccounts)
          }
        }
      }
    }

    function hasSelectedDoNotIntegrateOfficePayments(myobSettings) {
      if ('officePaymentsIntegrationEnabled' in myobSettings) {
        if (myobSettings.officePaymentsIntegrationEnabled === false) {
          return true;
        }
      }
      return false;
    }

    async function loadMandatoryFields() {
      const settings = sbMyobBusinessService.getSettings();
      ctrl.view.isInvoiceIntegrationEnabledOnLoad = settings.invoiceIntegrationEnabled;
      ctrl.model.invoiceIntegrationEnabled = settings.invoiceIntegrationEnabled;
      ctrl.model.invoiceIntegrationEnabledAt = settings.invoiceIntegrationEnabledAt;

      if (!ctrl.model.companyFile || ctrl.model.companyFile.id === settings.businessId) {
        ctrl.model.companyFile = sbMyobIntegrationService.getBusiness(settings.businessId);
        ctrl.model.integrateGeneral = settings.generalLedgerTransactionsEnabled;
      } else {
        ctrl.model.integrateGeneral = false;
      }

      if (featureActive('BB-10293') && ctrl.view.isInvoiceIntegrationEnabledOnLoad && ctrl.model.invoiceIntegrationEnabledAt) {
        ctrl.view.showDownloadButton = true;
      }
    }

    function isAuthenticated() {
      const authed = sbMyobIntegrationService.isAuthorized();
      ctrl.view.integrationEnabled = authed;
      
      if (!authed) {
        return authed;
      }

      ctrl.integrationVersion = sbMyobIntegrationService.getIntegrationVersion();
      ctrl.view.isConnectedToMyobV1 = ctrl.integrationVersion === 0;

      return authed;
    }

    // Need to do this as the backend returns an object for an unselected account
    // rather than not returning anything for that account
    function accountPresent(account) {
      return !!(account && account.name && !isDefaultEmptyAccount(account));
    }

    function unsetIfDefaultEmpty(account) {
      return isDefaultEmptyAccount(account) ? undefined : account;
    }

    function marshal() {
      const settings = {
        generalLedgerTransactionsEnabled: ctrl.model.integrateGeneral,
        trustTransactionsEnabled: ctrl.model.integrateTrust,
        invoiceIntegrationEnabled: featureActive('BB-10293') ? ctrl.model.invoiceIntegrationEnabled : false,
        invoiceIntegrationEnabledAt: featureActive('BB-10293') ? ctrl.model.invoiceIntegrationEnabledAt : null,
      };

      settings.businessId = ctrl.model.companyFile.id;

      if (ctrl.model.integrateGeneral) {
        settings.officePaymentsIntegrationEnabled = ctrl.model.clientCostLiabilityAccount.id !== DO_NOT_INTEGRATE.id;
        settings.operatingBankAccount = unsetIfDefaultEmpty(ctrl.model.operatingBankAccount);
        settings.legalFeesAccount = unsetIfDefaultEmpty(ctrl.model.legalFeesAccount);
        settings.clientExpensesAccount = unsetIfDefaultEmpty(ctrl.model.clientExpensesAccount);
        settings.clientSoftCostExpensesAccount = ctrl.hardAndSoftCostFeatureEnabled ? unsetIfDefaultEmpty(ctrl.model.clientSoftCostExpensesAccount) : undefined;
        settings.clientCostLiabilityAccount = unsetIfDefaultEmpty(ctrl.model.clientCostLiabilityAccount);
        settings.clientSoftCostLiabilityAccount = ctrl.hardAndSoftCostFeatureEnabled ? unsetIfDefaultEmpty(ctrl.model.clientSoftCostLiabilityAccount) : undefined;
        settings.badDebtAccount = featureActive('BB-10293') ? unsetIfDefaultEmpty(ctrl.model.badDebtAccount) : undefined;
        settings.interestAccount = featureActive('BB-10293') ? unsetIfDefaultEmpty(ctrl.model.interestAccount) : undefined;
        settings.surchargeAccount = featureActive('BB-7270') ? unsetIfDefaultEmpty(ctrl.model.surchargeAccount) : undefined;
      }

      return settings;
    }

    // We need to clear any accounts that are not being used anymore
    // as this is what the backend does
    function clearUnusedAccounts() {
      ctrl.model.operatingBankAccount = DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
      ctrl.model.legalFeesAccount = DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
      ctrl.model.clientExpensesAccount = DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
      ctrl.model.clientSoftCostExpensesAccount = DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
      ctrl.model.clientCostLiabilityAccount = DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
      ctrl.model.clientSoftCostLiabilityAccount = DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
      ctrl.model.badDebtAccount = DEFAULT_EMPTY_ACCOUNT;
      ctrl.model.interestAccount = DEFAULT_EMPTY_ACCOUNT;
      ctrl.model.surchargeAccount = DEFAULT_EMPTY_ACCOUNT;
    }

    function save() {
      const fieldInvalid = ctrl.validator.validateAll();
      if (!fieldInvalid) {
        const isEnablingInvoiceIntegration = featureActive('BB-10293') && !ctrl.view.isInvoiceIntegrationEnabledOnLoad && ctrl.model.invoiceIntegrationEnabled;

        // get timestamp on enabling invoice integration
        if (isEnablingInvoiceIntegration) {
          // invoiceIntegrationEnabledAt is set to local timestamp when MYOB invoice integration is first enabled
          // at which point the outstanding invoice CSV report is generated once and stored permanently. This field
          // is not used by .net and is used as a way to find the outstanding invoice CSV report already generated.
          ctrl.model.invoiceIntegrationEnabledAt = new Date();

          // show button with spinner, generating invoice report
          ctrl.view.showDownloadButton = true;
          ctrl.view.showButtonSpinner = true;
        }

        const newSettings = marshal();

        log.info('saving entity ', newSettings);

        sbMyobBusinessService
          .saveSettingsP(newSettings)
          .then(async () => {
            sbMessageDisplayService.success(sbMessageDisplayService.builder().text('MYOB integration settings saved'));
            ctrl.view.hasEnabledInvoiceIntegrationOnCurrentSave = isEnablingInvoiceIntegration;
            if (isEnablingInvoiceIntegration) {
              try {
                const res = await sbMyobBusinessService.generateOutstandingInvoiceReport(ctrl.model.invoiceIntegrationEnabledAt)
                ctrl.view.downloadLink = res.signedUrl;
                ctrl.view.showButtonSpinner = false;
              } catch (err) {
                ctrl.errors.downloadLink = err;
                ctrl.view.showButtonSpinner = false;
                sbMessageDisplayService.error(
                  sbMessageDisplayService
                  .builder()
                  .text('Failed to generate outstanding invoice report')
                  .conditionalText(': {0}', err.message)
                );
              }
            }
          })
          .catch(err => {
            log.info('error saving settings', err);
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text('Failed to save integration settings')
                .conditionalText(': {0}', err.message)
            );
          });
      }
    }
  }
});
