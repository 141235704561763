import {
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/staff-fee-configuration';

angular.module('@sb-billing/services').service('sbStaffFeeConfigurationService', function(sbGenericCacheService, sbEndpointType, sbLoggerService) {    
    const that = this;
    const log = sbLoggerService.getLogger('sbStaffFeeConfigurationService');
    const basePath = 'billing/staff-fee-configuration';

    sbGenericCacheService.setupCache({
      name: 'sbStaffFeeConfigurationService',
      sync: {
        endpoint: {
          type: sbEndpointType.SYNC_SINCE,
          stub: basePath
        },
        poll: 60,
        subscriptions: 'notifier.FeesNotifications.StaffFeeConfigurationUpdated',
      },
      updateRedux,
      clearRedux,
    });

    that.getByStaffId = getByStaffId;

    function getByStaffId(staffId) {
      const config = getById(staffId) || {};
      log.info(`getByMatterId ${staffId}`, config);
      return config;
    }
  });