/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column } from '@sb-itops/react/table';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { StatusPill } from '@sb-itops/react';

import Styles from './PaymentMethodsTable.module.scss';

const cellRenderer = ({ cellData }) => {
  if (!cellData) {
    return null;
  }
  return cellData;
};

const deleteCardRenderer =
  ({ onDeleteClick }) =>
  ({ rowData }) =>
    (
      <span
        className={Styles.deleteCardButton}
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick(rowData);
        }}
      >
        <i className="fa fa-trash" />
      </span>
    );

const providerRenderer =
  ({ activeProvider }) =>
  ({ cellData }) => {
    const providerName = providerNames[cellData] || '';

    if (!providerName) {
      return null;
    }

    return (
      <div>
        {providerName}
        {activeProvider !== cellData && <StatusPill statusLabel="DEACTIVATED" className={Styles.pillDeactivated} />}
      </div>
    );
  };

export const PaymentMethodsTable = memo(({ paymentMethods, onRowClick, onDeleteClick, activeProvider }) => (
  <Table list={paymentMethods} onRowClick={({ rowData }) => onRowClick(rowData)} className={Styles.paymentMethodsTable}>
    <Column dataKey="description" label="Description" flexGrow={1} cellRenderer={cellRenderer} disableSort />
    <Column
      className="capitalize"
      dataKey="bankAccount"
      label="Destination Account"
      flexGrow={1}
      cellRenderer={cellRenderer}
      disableSort
    />
    <Column
      className="capitalize"
      dataKey="display"
      label="Card Number"
      flexGrow={1}
      cellRenderer={cellRenderer}
      disableSort
    />
    <Column
      className="capitalize"
      dataKey="expiry"
      label="Expiry"
      flexGrow={0.5}
      cellRenderer={({ cellData }) => {
        const [expiryMonth, year] = cellData.split('/');
        const expiryYear = year.length === 2 ? year : year.slice(-2);
        const expiry = `${expiryMonth.padStart(2, '0')}/${expiryYear}`;
        // Lawpay January is saved as 1/2010 but we want to normalize it to 01/10
        return cellData ? expiry : null;
      }}
      disableSort
    />
    <Column
      className="capitalize"
      dataKey="provider"
      label="Provider"
      flexGrow={1}
      cellRenderer={providerRenderer({ activeProvider })}
      disableSort
    />
    <Column dataKey="" label="" cellRenderer={deleteCardRenderer({ onDeleteClick })} width={40} disableSort />
  </Table>
));

PaymentMethodsTable.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  activeProvider: PropTypes.string.isRequired,
};
