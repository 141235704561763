import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

angular.module('@sb-itops/services').service('sbOptimisticUpdatesService', function ($rootScope, sbLoggerService, sbGenericCacheService, smokeballDBKeyPaths) {
  'use strict';
  var log = sbLoggerService.getLogger('sbOptimisticUpdatesService');
  $rootScope.$on('opdate-posted', applyChangeset);

  //applies the changeset to the relevent caches
  function applyChangeset(event, updateChangeset = {}) {
    _.each(_.keys(updateChangeset), function (cacheId) {
      const cache = sbGenericCacheService.getCache(cacheId);
      const changes = [];

      if (cache.updateRedux) {
        const { opdateCache } = optimisticUpdateFactory({ ngCacheName: cacheId, keyPath: smokeballDBKeyPaths[cacheId] });
        opdateCache({ optimisticEntities: updateChangeset[cacheId] });
      } else {
        _.each(updateChangeset[cacheId], function (change) {
          //so change can be the full entity or only the part that must change
          var key = change[smokeballDBKeyPaths[cacheId]];
          log.info('applying update change:', change, 'to cache:', cacheId, 'for key:', key);
          var currentEntityVersion = cache.cachedModel[key] || {};
          var newEntityVersion = _.merge(currentEntityVersion, change);
          newEntityVersion.$optimistic = moment().toISOString();
          log.info('cache:', cacheId, 'changed entity version for key:', key, 'is:', newEntityVersion);
          changes.push(newEntityVersion);
        });
        sbGenericCacheService._persistChangesetP(cache, changes, undefined, undefined, true);
      }
    });
  }
});
