import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { featureActive } from '@sb-itops/feature';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { CreditCardSaveRequestModal } from './CreditCardSaveRequestModal';
import { creditCardSaveRequestFormSchema, convertToEmailCreditCardSaveRequest } from '../credit-card-save-request-form';

const SCOPE = 'credit-card-save-request-form';
const log = getLogger('CreditCardSaveRequestModalContainer');
const CREDIT_CARD_SAVE_REQUEST_ENDPOINT = `/billing/payment-provider/request-card-details/:accountId/`;

const hooks = ({ onClose, contactId }) => ({
  useCreditCardSaveRequestModal: () => {
    const { formSubmitting, formInitialised, onValidateForm, onSubmitFormWithValidation } = useForm({
      scope: SCOPE,
      schema: creditCardSaveRequestFormSchema,
    });

    return {
      onSubmit: async () => {
        onValidateForm();

        await onSubmitFormWithValidation({
          submitFnP: async (formValues) => {
            try {
              await onSubmitCreditCardSaveRequest({ formValues, contactId });
              messageDisplay.success('The request for credit card details has been sent');
              onClose();
            } catch (err) {
              log.error(err);
              messageDisplay.error(
                messageDisplay
                  .builder()
                  .text('Failed to send request for credit card details')
                  .conditionalText(': {val}', err.message)
                  .conditionalText(
                    '. {0}',
                    err?.payload?.body?.message === emailMessages.notAllowedToSendEmailsServer
                      ? emailMessages.notAllowedToSendEmailsDisplay
                      : '',
                  ),
              );
            }
          },
        });
      },
      isSubmitDisabled: !formInitialised,
      isSubmitLocked: formSubmitting,
      scope: SCOPE,
    };
  },
});

const onSubmitCreditCardSaveRequest = async ({ formValues, contactId }) => {
  const creditCardSaveRequest = await convertToEmailCreditCardSaveRequest({
    creditCardSaveRequestData: formValues,
    contactId,
    interpolate: true,
  });

  if (featureActive('BB-13500')) {
    // NEW BB-13500
    return dispatchCommand({
      type: 'Integration.RequestCreditCardDetails',
      message: {
        ...creditCardSaveRequest,
        message: undefined,
        sendTo: undefined,
        body: creditCardSaveRequest.message,
        to: creditCardSaveRequest.sendTo,
      },
    });
  }
  // OLD pre BB-13500
  return fetchPostP({
    path: CREDIT_CARD_SAVE_REQUEST_ENDPOINT,
    fetchOptions: { body: JSON.stringify(creditCardSaveRequest) },
  });
};

export const CreditCardSaveRequestModalContainer = withReduxProvider(composeHooks(hooks)(CreditCardSaveRequestModal));
CreditCardSaveRequestModalContainer.displayName = 'CreditCardSaveRequestModalContainer';

CreditCardSaveRequestModalContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
