import React from 'react';
import { Button, buttonTypes, FloatingCard } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import adeleThumbnail from 'web/assets/adele-thumbnail.png';
import * as Styles from './PromoCard.module.scss';

interface IPromoCardProps {
  children: React.JSX.Element;
  title: string;
  text: string;
  showCard: boolean;
  onSetup: () => void;
  onDismiss: () => void;
  onFindOutMore?: () => void;
}

export const PromoCardBase = (props: IPromoCardProps) => {
  if (!(featureActive('NUCWEB-799') || featureActive('NUCWEB-799-DEBUG')) || !props.showCard) {
    return null;
  }

  return (
    <FloatingCard>
      <div className={Styles.promoCard}>
        <div className={Styles.form}>
          <div className={Styles.titleBar}>
            <div className={Styles.icon}>
              <img src={adeleThumbnail} alt="" />
            </div>
            <div>
              <strong>Adele</strong> from Smokeball
            </div>
          </div>
          <div className={Styles.content}>
            <div className={Styles.title}>{props.title}</div>
            <div>{props.text}</div>
          </div>
          <div className={Styles.footer}>
            <Button onClick={props.onSetup}>Set up now</Button>
            {props.onFindOutMore && (
              <Button type={buttonTypes.secondary} onClick={props.onFindOutMore}>
                Find Out More
              </Button>
            )}
            <Button className={Styles.dismissButton} onClick={props.onDismiss} type={buttonTypes.tertiary}>
              Dismiss
            </Button>
          </div>
        </div>
        <div className={Styles.graphic}>{props.children}</div>
      </div>
    </FloatingCard>
  );
};
