angular.module('sb.billing.webapp').config(function ($stateProvider/*, sbBankReconciliationUnsavedChangesServiceProvider */) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/create-bank-reconciliation';

  $stateProvider
    .state('home.billing.create-bank-reconciliation', {
      url: '/create-bank-reconciliation',
      templateUrl: BASE_DIRECTORY + '/create-bank-reconciliation.html',
      controller: 'sbBankReconController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation', 'trust'],
        accountType: 'TRUST',
        tab: {
          type: 'bank-reconciliation',
        }
      },
    })
    .state('home.billing.create-bank-reconciliation.account', {
      url: '/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/create-bank-reconciliation.html',
      controller: 'sbBankReconController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation', 'trust'],
        accountType: 'TRUST',
        tab: {
          type: 'bank-reconciliation',
        }
      },
 
    });

  $stateProvider
    .state('home.billing.create-bank-reconciliation.adjustments', {
      url: '/adjustments',
      templateUrl: BASE_DIRECTORY + '/create-bank-reconciliation.html',
      controller: 'sbBankReconController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation', 'trust', 'adjustments'],
        accountType: 'TRUST',
        tab: { type: 'bank-reconciliation' }
      }
    })
    .state('home.billing.create-bank-reconciliation.adjustments.account', {
      url: '/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/create-bank-reconciliation.html',
      controller: 'sbBankReconController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation', 'trust', 'adjustments'],
        accountType: 'TRUST',
        tab: { type: 'bank-reconciliation' }
      }
    });
});
