'use strict';

const { makePeriscopeReportUrlP } = require('./make-periscope-report-url');
const { makeSsrsReportUrl } = require('./make-ssrs-report-url');
const { mapSmokeballFilters } = require('./map-smokeball-filters');
const { getDownloadPdfOptions } = require('./get-download-pdf-options');
const { validateDateRange } = require('./validate-filters');
const { sortTransactionsGroupsAccountsOrder } = require('./sort-transactions-groups-accounts-order');
const { toCsv } = require('./to-csv');
const { filterBulkDepositTransactions } = require('./transactions/filter-bulk-deposit-transactions');
const { filterOtherTrustTransactions } = require('./transactions/filter-other-trust-transactions');
const { filterOtherOperatingTransactions } = require('./transactions/filter-other-operating-transactions');
const { filterTrustInvoicePayments } = require('./transactions/filter-trust-invoice-payments');
const { filterControlledMoneyTransactions } = require('./transactions/filter-controlled-money-transactions');
const { filterOperatingInvoicePayments } = require('./transactions/filter-operating-invoice-payments');

module.exports = {
  makePeriscopeReportUrlP,
  makeSsrsReportUrl,
  mapSmokeballFilters,
  getDownloadPdfOptions,
  validateDateRange,
  sortTransactionsGroupsAccountsOrder,
  toCsv,
  // Functions below are meant for transactions filtering in reports only.
  // They are currently used in account balance, account statement and trust statement reports.
  filterBulkDepositTransactions,
  filterOtherTrustTransactions,
  filterOtherOperatingTransactions,
  filterTrustInvoicePayments,
  filterControlledMoneyTransactions,
  filterOperatingInvoicePayments,
};
