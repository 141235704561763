import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { withOnLoad } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogVisible, isModalVisible } from '@sb-itops/redux/modal-dialog';

import { PaymentProviderRefund } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { FeeWiseTransactionDetailsModal } from './FeeWiseTransactionDetailsModal';

const FEE_WISE_REFUND_CONFIRMATION_MODAL_ID = 'FEE_WISE_REFUND_CONFIRMATION_MODAL_ID';

const hooks = ({ transactionDetail }) => ({
  useRequestRefund: () => {
    const { data, loading } = useSubscribedQuery(PaymentProviderRefund, {
      variables: {
        id: transactionDetail.paymentReference,
        providerType: providers.FEE_WISE,
      },
    });

    const [isRequestingRefund, setIsRequestingRefund] = useState(false);
    const isRefundConfirmationModalVisible = useSelector(() =>
      isModalVisible({ modalId: FEE_WISE_REFUND_CONFIRMATION_MODAL_ID }),
    );
    const openRefundConfirmationModal = () => {
      setModalDialogVisible({ modalId: FEE_WISE_REFUND_CONFIRMATION_MODAL_ID });
    };

    const requestRefund = async () => {
      try {
        const { paymentReference, payerName, amount, date, reference } = transactionDetail;

        const message = {
          providerType: providers.FEE_WISE,
          // Ideally we'll only need the paymentReference, but this is not feasible as
          // FeeWise does not have API support for fetching a single payment/transaction.
          // We'll need this to be provider specific anyway as different providers may have
          // different refund capabilities in the future, e.g. varying the refund amount or
          // other communication preferences.
          providerRefundRequest: {
            paymentReference,
            payerName,
            amount,
            date,
            reference,
          },
        };

        setIsRequestingRefund(true);
        await dispatchCommand({ type: 'Integration.RequestPaymentProviderRefund', message });
        displaySuccessToUser('Successfully sent refund request');
      } catch (err) {
        displayErrorToUser('Error occurred while sending refund request');
      } finally {
        setIsRequestingRefund(false);
      }
    };
    return {
      loading,
      isRefundConfirmationModalVisible,
      openRefundConfirmationModal,
      requestRefund,
      isRequestingRefund,
      transactionDetail: {
        ...transactionDetail,
        refundRequestedTimestamp: data?.paymentProviderRefund?.requestedTimestamp,
      },
    };
  },
});

export const FeeWiseTransactionDetailsModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(withOnLoad(FeeWiseTransactionDetailsModal))),
);

FeeWiseTransactionDetailsModalContainer.displayName = 'FeeWiseTransactionDetailsModalContainer';

FeeWiseTransactionDetailsModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMatterClick: PropTypes.func.isRequired,
  transactionDetail: PropTypes.shape({
    date: PropTypes.string,
    amount: PropTypes.number,
    matters: PropTypes.array,
    accountDisplay: PropTypes.string,
    payerName: PropTypes.string,
    customerPaymentMethod: PropTypes.shape({
      paymentMethod: PropTypes.string,
      card: PropTypes.shape({
        scheme: PropTypes.string,
        partialNumber: PropTypes.string,
      }),
      debit: PropTypes.shape({
        accountName: PropTypes.string,
        partialNumber: PropTypes.string,
      }),
    }),
    bankAccount: PropTypes.shape({
      id: PropTypes.string,
      accountType: PropTypes.string,
      accountName: PropTypes.string,
      displayName: PropTypes.string,
    }),
  }).isRequired,
};

FeeWiseTransactionDetailsModalContainer.defaultProps = {};
