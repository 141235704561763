/**
 * @module @sb-itops/redux/operation
 */
import utilsFactory from '../utils-factory';
import { domain } from '../domain';
import store from '../store';
import name from './name';
import {
  operationReducer,
  OPERATION_CLEAR,
  OPERATION_FAIL,
  OPERATION_START,
  OPERATION_SUCCESS,
  OPERATION_STATE,
} from './reducer';

const { registerReducer, getState } = utilsFactory({ domain, name });

registerReducer(operationReducer);

const getOperation = (id) => getState()[id];

const operationStart = (id) =>
  store.dispatch({
    type: OPERATION_START,
    payload: {
      id,
    },
  });

const operationSuccess = (id) =>
  store.dispatch({
    type: OPERATION_SUCCESS,
    payload: {
      id,
    },
  });

const operationFail = (id, error) =>
  store.dispatch({
    type: OPERATION_FAIL,
    payload: {
      id,
      error,
    },
  });

const operationClear = (id) =>
  store.dispatch({
    type: OPERATION_CLEAR,
    payload: {
      id,
    },
  });

export { OPERATION_STATE, getOperation, operationStart, operationSuccess, operationFail, operationClear };
