import React from 'react';
import PropTypes from 'prop-types';

const TabReconDateRange = ({ display }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{display}</>
);

TabReconDateRange.propTypes = {
  display: PropTypes.string.isRequired,
};

TabReconDateRange.defaultProps = {};

TabReconDateRange.displayName = 'TabReconDateRange';

export default TabReconDateRange;
