'use strict';

const { isPaymentProviderEnabledForBankAccount } = require('./is-payment-provider-enabled-for-bank-account');
const { bankAccountTypeEnum } = require('../../bank-account/entities/constants');

/**
 * This function will return the firm's payment provider payment settings
 *
 * In essence, it will perform these checks:
 *  1. Payment provider is connected
 *  2. Operating account is connected and ready for use
 *  3. Whether the invoice payment options are active or not
 *    - Payment links (e.g. pay buttons)
 *    - QR codes
 *
 * @param {Object} params
 * @param {Object} params.activeProviderFormattedSettings if retrieving payment provider data from graphql, please ensure to apply the formatting provided in convertSettingsFromGQL (payment provider business logic function)
 * @param {'LAWPAY' | 'STRIPE' | 'FEE_WISE'} params.activeProviderType
 * @param {string} params.operatingAccountId
 * @returns {{ showInvoiceLink: boolean, showScanToPay: boolean }}
 */
function getFirmPaymentProviderPaymentSettings({
  activeProviderFormattedSettings,
  activeProviderType,
  operatingAccountId,
}) {
  const isOperatingAccountAuthorised =
    !!operatingAccountId &&
    !!activeProviderType &&
    isPaymentProviderEnabledForBankAccount({
      formattedProviderSpecificSettings: activeProviderFormattedSettings,
      providerType: activeProviderType,
      bankAccountId: operatingAccountId,
      bankAccountType: bankAccountTypeEnum.OPERATING,
    });

  const settings = {
    showInvoiceLink: isOperatingAccountAuthorised && !!activeProviderFormattedSettings?.showInvoiceLink,
    showScanToPay: isOperatingAccountAuthorised && !!activeProviderFormattedSettings?.showScanToPay,
  };

  return settings;
}

module.exports = {
  getFirmPaymentProviderPaymentSettings,
};
