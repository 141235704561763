angular.module('sb.billing.webapp').filter('sbActivitySearch', function () {
  'use strict';

  return function(activities, searchString) {
    return _.filter(activities, function (activity) {
      var matcher = new RegExp(searchString, 'i');

      if (activity.description && activity.description.match(matcher)) {
        return true;
      }
      if (activity.code && activity.code.match(matcher)) {
        return true;
      }
    });
  };
});
