/**
 * DEPRECATED
 * 
 * This component has been replaced by DatePicker.jsx and the angular bridge sbb-date-picker
 */
angular.module('sb.billing.webapp').component('sbDatepicker', {
  templateUrl: 'ng-components/datepicker/datepicker.html',
  bindings: {
    format: '@',
    disabledFlag: '<',
    hasErrorFlag: '<',
    initialDate: '<',
    minDate: '<',
    maxDate: '<',
    size: '@?',
    minimal: '<',
    hideDelete: '<',
    // remove these?
    name: '@?',
    updateMode: '@?',
    appendToBody: '<?',
  },
  require: { ngModel: 'ngModel' },
  controller: function ($scope, sbLoggerService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDatepicker');

    let initialized;

    $scope.$watch('$ctrl.ngModel.$viewValue', () => {
      if (ctrl.ngModel.$viewValue !== ctrl.date) {
        ctrl.date = ctrl.ngModel.$viewValue;
      }
    });

    // log.setLogLevel('info');

    //TODO make sure that if the input field is invalid (eg 'asdasd') that we still make the field invalid, regardless of set error flag value

    ctrl.$onInit = () => {
      ctrl.dateFormat = ctrl.format;
      log.info('dateFormat', ctrl.dateFormat);
      log.info('initialDate', ctrl.initialDate);

      if (ctrl.initialDate && !ctrl.updateMode) {
        ctrl.ngModel.$setViewValue(ctrl.initialDate);
      }

      setDateFromInitial();
      setOptions();

      ctrl.datePickerOpen = false;

      initialized = true;
    };

    ctrl.$onChanges = (changes) => {
      log.info('saw changes', changes);
      if (initialized) {
        setOptions();
        if (changes.initialDate) {
          setDateFromInitial();
        }
      }
    };

    ctrl.onSelect = (date) => {
      ctrl.ngModel.$setViewValue(date);
    };

    ctrl.toggleDatePicker = (forceValue) => {
      ctrl.datePickerOpen = (forceValue !== undefined) ? forceValue : !ctrl.datePickerOpen;
    };

    ctrl.setErrorFlag = (hasError) => {
      return hasError !== undefined ? hasError : (ctrl.date <= 0);
    };

    function setDateFromInitial() {
      if (!ctrl.updateMode) {
        ctrl.date = ctrl.initialDate;
      }
    }

    function setOptions() {
      ctrl.options = {};
      ctrl.options.minDate = ctrl.minDate;
      ctrl.options.maxDate = ctrl.maxDate;
    }

  }
});
