/**
 * Custom Offset-Limit Pagination
 *
 * @param {boolean|string[]} keyArgs Unique key fields
 * @param {string} resultsField Field containing the array of results
 * @returns The merged results, matching the request type
 */
export const customOffsetLimitPagination = (keyArgs = false, resultsField = 'results') => ({
  keyArgs,
  merge(existing, incoming, { args }) {
    if (!existing) {
      return incoming;
    }

    if (!incoming) {
      return existing;
    }

    const oldResults = existing && existing[resultsField];
    const merged = oldResults ? oldResults.slice(0) : [];
    const newResults = incoming && incoming[resultsField];

    if (newResults) {
      if (args) {
        const { offset = 0 } = args;
        for (let i = 0; i < newResults.length; ++i) {
          merged[offset + i] = newResults[i];
        }
      } else {
        merged.push.apply(merged, newResults);
      }
    }

    return {
      ...existing,
      ...incoming,
      results: merged,
    };
  },
});
