import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import Styles from './InvoiceReminderEmailModal.module.scss';
// import { InvoiceReminderEmailTabPerDebtorForm } from '../invoice-reminder-email-tab-per-debtor-form';
import { InvoiceReminderEmailForm } from '../invoice-reminder-email-form';

export const InvoiceReminderEmailModal = React.memo(
  ({
    isVisible,
    isSubmitDisabled,
    scope,
    debtorIds,
    matterIds,
    remindersCount,
    warningMessage,
    onSend,
    onPreview,
    onClose,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.invoiceReminderEmailModal}
        isVisible={isVisible}
        title="Send Reminder"
        body={
          <div className={Styles.standardForm}>
            <InvoiceReminderEmailForm
              scope={scope}
              debtorIds={debtorIds}
              matterIds={matterIds}
              remindersCount={remindersCount}
              onPreview={onPreview}
            />
          </div>
        }
        footer={
          <div>
            {warningMessage && <div className={Styles.warningMessage}>{warningMessage}</div>}
            <Button onClick={onSend} disabled={isSubmitDisabled}>
              Send Now
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

InvoiceReminderEmailModal.displayName = 'InvoiceReminderEmailModal';

InvoiceReminderEmailModal.propTypes = {
  isVisible: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  scope: PropTypes.string,
  // invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  debtorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  remindersCount: PropTypes.number.isRequired,
  warningMessage: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  onPreview: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

InvoiceReminderEmailModal.defaultProps = {
  isVisible: false,
  isSubmitDisabled: false,
  scope: 'invoice-reminder-email-modal',
  warningMessage: undefined,
  onPreview: undefined,
};
