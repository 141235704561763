import { gql } from '@apollo/client';

const query = gql`
  query InitOperatingChequePrintSettings {
    operatingChequePrintSettings {
      id
      printingActive
      style
      printMethod
      includeVendorAddress
    }
  }
`;

const notificationIds = ['AccountsNotifications.OperatingChequePrintSettingsUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitOperatingChequePrintSettings = {
  query,
  notificationIds,
};
