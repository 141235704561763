'use strict';

/**
 * Given a HTTP fetcher and reportConfig object, this function calls an endpoint
 * which creates a periscope URL that can be used to load the periscope report
 * represented by the reportConfig.
 */
const makePeriscopeReportUrlP = async ({ fetchPostP, reportConfig }) => {
  const path = `/billing/external-reports/make-periscope-url/:accountId/`;
  const fetchOptions = { body: JSON.stringify(reportConfig) };
  const response = await fetchPostP({ path, fetchOptions });
  return response.body.payload.periscopeUrl;
};

module.exports = {
  makePeriscopeReportUrlP,
};
