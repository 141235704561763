'use strict';

// Any changes to these will need to be handled in monorepo/billing/business-logic/evergreen-retainer/services/interpolate-text.js
// when isForEmailSubject is true, the placeholder for html based content should not be included.
const getEvergreenRetainerEmailMacros = ({ isForEmailSubject, t, showPayNowButton }) => {
  const macros = [
    // Invoice Details
    { name: '[DEBTOR_NAME]', tooltip: 'Debtor name' },
    { name: '[AMOUNT_REQUESTED]', tooltip: 'Amount requested' },
    { name: '[MATTER_AMOUNT_DUE]', tooltip: 'Amount due (matter total)' },
    { name: '[REPLENISH_UP_TO]', tooltip: 'Replenish up to amount' },
    // Matter Details
    { name: '[MATTER_NAME]', tooltip: 'Matter name' },
    { name: '[MINIMUM_THRESHOLD]', tooltip: `Minimum ${t('trust').toLowerCase()} threshold` },
    { name: `[${t('macros.trustBalance')}]`, tooltip: `Current ${t('trust').toLowerCase()} balance` },
    // Firm Details
    { name: '[USER_NAME]', tooltip: 'Current user' },
    { name: '[FIRM_NAME]', tooltip: 'Firm name' },
    { name: '[PHONE_NUMBER]', tooltip: 'Phone number' },
  ];

  // Including [PAY_NOW_BUTTON] only when get macros for email body, as it's html based content
  if (showPayNowButton && !isForEmailSubject) {
    macros.push(
      ...[
        // Invoice Details
        { name: '[PAY_NOW_BUTTON]', tooltip: 'Pay now button' },
      ],
    );
  }

  return macros;
};

module.exports = { getEvergreenRetainerEmailMacros };
