import * as React from 'react';
import { fetchGetP, fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { responseTypes } from '@sb-itops/fetch-wrapper/src/constants';

const log = getLogger('useCreateLetter');

export function useCreateLetter() {
  const [authorizationLoading, setAuthorizationLoading] = React.useState(false);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [isCreatingLetter, setIsCreatingLetter] = React.useState(false);

  React.useEffect(() => {
    setAuthorizationLoading(true);

    fetchGetP({
      path: `/integration/endpoint-locator/letter/:accountId/get-user-has-granted-access`,
      responseType: responseTypes.text,
    })
      .then((authorized) => {
        setIsUnauthorized(authorized.body === 'false');
        setAuthorizationLoading(false);
        return undefined;
      })
      .catch((reason) => {
        setIsUnauthorized(true);
        setAuthorizationLoading(false);
        log.error(`Failed to check if user has granted access: `, reason);
        displayErrorToUser(`Failed to check if user has granted access. Please try again later.`);
      });
  }, []);

  async function createLetter(matterId, addresseeId, addresseeName, body) {
    try {
      setIsCreatingLetter(true);
      const letterUrlResponse = await fetchPostP({
        path: `/integration/endpoint-locator/letter/:accountId/get-letter-url`,
        fetchOptions: {
          body: JSON.stringify({
            matterId,
            addressees: [{ id: addresseeId, name: addresseeName }],
            body,
          }),
        },
        responseType: responseTypes.text,
        skipCamelCase: true,
      });

      return letterUrlResponse.body;
    } catch (error) {
      log.error(`Failed to create letter: `, error);
      displayErrorToUser(`Failed to create letter to ${addresseeName}. Please try again later.`);
    } finally {
      setIsCreatingLetter(false);
    }

    return undefined;
  }

  return {
    authorizationLoading,
    isUnauthorized,
    isCreatingLetter,
    createLetter,
  };
}
