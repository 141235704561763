import * as Yup from 'yup';
import { durationType } from '@sb-billing/business-logic/shared/entities';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';

export const saveFeeSchema = Yup.object().shape({
  matterId: customYupValidators.uuid().when('showMatterField', {
    is: true,
    then: (matterId) => matterId.required(),
    otherwise: (matterId) => matterId.optional(),
  }),
  duration: Yup.number().when('durationType', {
    is: durationType.FIXED,
    then: (duration) => duration.oneOf([0]).required(),
    otherwise: (duration) => duration.moreThan(0).required(),
  }),
  durationInMins: Yup.number().integer().required(),
  subject: Yup.string().min(1).max(1000).required(),
  rateInCents: Yup.number()
    .integer()
    .required()
    .test((value) => value >= 0),
  activityId: Yup.string().when('utbmsCodesRequiredForMatter', {
    is: true,
    then: (activityId) => activityId.required(),
    otherwise: (activityId) => activityId.optional(),
  }),
  taskId: Yup.string().when(['isUtbmsActivity', 'utbmsCodesRequiredForMatter'], {
    is: (isUtbmsActivity, utbmsCodesRequiredForMatter) => isUtbmsActivity || utbmsCodesRequiredForMatter,
    then: (taskId) => taskId.required(),
    otherwise: (taskId) => taskId.optional(),
  }),
  staffId: customYupValidators.uuid().when('showStaffField', {
    is: true,
    then: (staffId) => staffId.required(),
    otherwise: (staffId) => staffId.optional(),
  }),
  feeDate: Yup.date().when('showDateField', {
    is: true,
    then: (feeDate) => feeDate.required(),
    otherwise: (feeDate) => feeDate.optional(),
  }),
});
