'use strict';

const authenticationStatuses = Object.freeze({
  AUTHORIZED: 'AUTHORIZED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  REFRESHING: 'REFRESHING',
});

const authenticationResultTypes = Object.freeze({
  CHALLENGE: 'CHALLENGE',
  USER_IDENTITY: 'USER_IDENTITY',
});

module.exports = {
  authenticationStatuses,
  authenticationResultTypes,
};
