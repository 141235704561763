import React from 'react';
import PropTypes from 'prop-types';
import { DropDownList, useTranslation } from '@sb-itops/react';
import { TransactionNumberingSettings } from '../../components/transaction-numbering-settings';
import Styles from './TrustTransactionNumberingTab.module.scss';

export const TrustTransactionNumberingTab = React.memo((props) => {
  const {
    scope,
    formState,
    prefixesWithWarnings,
    exampleNumbers,
    bankAccountId,
    bankAccounts,
    onBankAccountSelection,
    onSave,
    onChange,
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className={Styles.trustAccountSelectionContainer}>
        <h3>{t('trust')} Transaction Numbering</h3>
        <div className={Styles.bankAccountSelect}>
          <label>Select your account:</label>
          <DropDownList
            placeholder={`No ${t('trustAccounts').toLowerCase()} opened`}
            onValueChange={(bankAccount) => {
              onBankAccountSelection(bankAccount.value);
            }}
            stateless
            options={bankAccounts}
            selectedOption={bankAccounts.find((o) => o.value === bankAccountId)}
          />
        </div>
        <div className={Styles.separator} />
      </div>
      {!!bankAccounts.length && (
        <TransactionNumberingSettings
          scope={scope}
          formState={formState}
          prefixesWithWarnings={prefixesWithWarnings}
          exampleNumbers={exampleNumbers}
          onSave={onSave}
          onChange={onChange}
        />
      )}
    </div>
  );
});

TrustTransactionNumberingTab.displayName = 'TrustTransactionNumberingTab';

TrustTransactionNumberingTab.propTypes = {
  scope: PropTypes.string.isRequired,
  formState: PropTypes.object.isRequired,
  prefixesWithWarnings: PropTypes.shape({
    receipt: PropTypes.bool,
    transferOfFunds: PropTypes.bool,
    electronicPayments: PropTypes.bool,
    trustToOffice: PropTypes.bool,
  }).isRequired,
  exampleNumbers: PropTypes.object.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  bankAccounts: PropTypes.array.isRequired,
  onBankAccountSelection: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

TrustTransactionNumberingTab.defaultProps = {};
