import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { withOnLoad } from '@sb-itops/react';
import { LawpaySavedCardForm } from './LawpaySavedCardForm';

export const LawpaySavedCardFormContainer = withOnLoad(({ onReadyForSubmit, onSubmit, savedCard, triggerSubmit }) => {
  const [submissionTriggered, setSubmissionTriggered] = useState(false);
  const [expirationMonth, year] = savedCard.expiry.split('/');
  const expirationYear = year.length === 2 ? year : year.slice(-2); // only 2 digits year
  const savedCardWithExpiration = {
    ...savedCard,
    ...{ expirationMonth: expirationMonth.padStart(2, '0'), expirationYear },
  };

  onReadyForSubmit(true);

  // Trigger the submission
  if (triggerSubmit && !submissionTriggered) {
    setSubmissionTriggered(true);
    onSubmit({
      paymentMethod: paymentMethods.CREDIT_CARD,
      paymentToken: {
        id: savedCard.token,
      },
      // exposing some fields to help make success/failure message more meaningful
      cardType: savedCard.cardType,
      cardholderName: savedCard.cardholderName,
      cardNumberTruncated: savedCard.display,
    });
  }

  // We are ok to allow submission again.
  if (!triggerSubmit && submissionTriggered) {
    setSubmissionTriggered(false);
  }

  return <LawpaySavedCardForm savedCard={savedCardWithExpiration} />;
});

LawpaySavedCardFormContainer.displayName = 'LawpaySavedCardFormContainer';

LawpaySavedCardFormContainer.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  savedCard: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
};

LawpaySavedCardFormContainer.defaultProps = {};
