import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { CalendarData, InitStaffSettings, RecurringEventData } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useState } from 'react';
import { rrulestr } from 'rrule';
import { sortByProperty } from '@sb-itops/nodash';
import { EventsWidget } from './EventsWidget';

const hooks = ({ matterId }) => ({
  useGraphQLforEvents: () => {
    const min = moment().startOf('day');
    const max = moment().startOf('day').add(1, 'month');
    const [showEventModal, setShowEventModal] = useState(false);
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const { data, loading, error } = useSubscribedQuery(CalendarData, {
      variables: {
        eventFilter: {
          matterId,
          min: min.toISOString(),
          max: max.toISOString(),
        },
        offset: 0,
        limit: 100,
      },
    });

    const recurringEventQueryResult = useSubscribedQuery(RecurringEventData, {
      variables: {
        eventFilter: {
          matterId,
          min: min.toISOString(),
          max: max.toISOString(),
        },
      },
    });

    const recurringEventList = recurringEventQueryResult?.data?.recurringEventList?.results || [];

    const appointmentRef = {};
    const expandedAppointments = [];

    recurringEventList.forEach((a) => {
      if (a?.eventType === 1 && a?.rRULE) {
        const rRULEParsed = rrulestr(a.rRULE, { dtstart: moment(a.startTime).toDate() });
        const children = rRULEParsed.between(min.toDate(), max.toDate(), true).map((d) => {
          const dateString = d.toISOString();
          if (a.startTime === dateString) {
            return a;
          }
          const diff = moment(dateString).add(moment(a.endTime).diff(moment(a.startTime)), 'millisecond');
          const appointmentCopy = {
            ...a,
            patternAppointmentId: a.id,
            originalId: a.id,
            startTime: dateString,
            endTime: diff.toISOString(),
            originalAppointment: a,
          };
          delete appointmentCopy.id;
          return appointmentCopy;
        });
        appointmentRef[a.id] = children;

        expandedAppointments.push(...children);
      }
    });
    recurringEventList.forEach((a) => {
      if (
        a?.eventType !== 1 &&
        appointmentRef[a?.patternAppointmentId] &&
        (moment(a?.startTime).isBetween(min, max) || moment(a?.originalStartTime).isBetween(min, max))
      ) {
        const matchingInstance = appointmentRef[a.patternAppointmentId].findIndex(
          (c) => c.startTime === a.originalStartTime,
        );
        if (matchingInstance !== -1) {
          if (a.eventType === 4) {
            // remove instance
            appointmentRef[a.patternAppointmentId].splice(matchingInstance, 1);
          } else {
            // update instance
            Object.assign(appointmentRef[a.patternAppointmentId], a);
          }
        } else if (a.eventType !== 4) {
          appointmentRef[a.patternAppointmentId].push(a);
        }
      }
    });

    const rawEvents = data?.eventList?.results || [];

    const entityMap = (staffData?.staffMembers || []).reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

    const events = sortByProperty(
      rawEvents
        .concat(expandedAppointments)
        .map((appointment) => {
          let skip = true;
          if (!appointment) {
            return null;
          }
          const staff = appointment.resourceIds.map((staffId) => {
            if (skip && staffId === getLoggedInStaff()?.id) {
              skip = false;
            }
            return entityMap[staffId]?.name;
          });
          if (skip) {
            return null;
          }
          return {
            ...appointment,
            staff,
            startDate: appointment.startTime,
            endDate: appointment.endTime,
          };
        })
        .filter((a) => !!a),
      'startTime',
      'asc',
    ).slice(0, 20);

    if (error) {
      throw new Error(error);
    }
    return {
      showEventModal,
      setShowEventModal,
      events,
      loading,
    };
  },
});

export const EventsWidgetContainer = withApolloClient(composeHooks(hooks)(EventsWidget));

EventsWidgetContainer.displayName = 'EventsWidget.container';
