import { gql } from '@apollo/client';

const query = gql`
  query PrintOperatingChequeModalData {
    unprintedOperatingCheques {
      id
      chequeNumber
      isPrinted
      reversed
      lastUpdated
      chequeDate
      chequeMemo
      expenses {
        id
        amountIncludesTax
        price
        quantity
        tax
        matter {
          id
          matterNumber
          clientDisplay
          otherSideDisplay
          matterType {
            matterTypeId
            name
          }
        }
      }
      payTo {
        id
        displayName
        displayNameFirstLast
      }
    }
  }
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpensePaymentDetailsUpdated',
  'AccountsNotifications.OperatingChequeUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const PrintOperatingChequeModalData = {
  query,
  notificationIds,
};
