import { withParameterInjections } from './lib/with-parameter-injections';
import { withSelectorParameterInjections } from './lib/with-selector-parameter-injections';
import { withSelectorStateSlicing } from './lib/with-selector-state-slicing';

/**
 * withScopedFeature is a convenience function to make usage of scoped features simpler.
 *
 * @description
 * Assuming that the passed state and scope parameters are valid (see below for more detail), withScopedFeature returns
 * a function which accepts a feature and returns a modified feature whose;
 *   1. Actions are automatically injected with a scope parameter.
 *   2. Selectors are automatically injected with a scope parameter.
 *   3. Selectors are automatically injected with a state parameter.
 *   4. Selectors automatically slice the global redux state passed by the default path of the feature.
 *
 *
 * @param {Object} state Likely the global redux store state. If null or undefined;
 *                         1. The returned feature's selectors will not have the state parameter injected into them.
 *                         2. The returned feature's selectors will not perform any additional state slicing.
 * @param {string} scope The scope currently in context for the passed Redux Feature. If undefined or null;
 *                         1. The returned feature's actions will not have the scope parameter injected into them.
 *                         2. The returned feature's selectors will not have the scope parameter injected into them.
 *
 * @return {Function} A function accepting a scoped feature to be juiced up.
 *
 * @example
 *
 *  suppose you have a feature that looks like the following (e.g. you used {@link scopeFeature});
 *
 * defaultPath.js
 *   export const defaultPath = 'my/cool/path';
 * actions.js
 *   export someCoolAction = ({ scope, otherData }) => ({ type: SOME_COOL_ACTION, payload: otherData, meta: { scope } });
 * selectors.js
 *   export someCoolSelector = (state, { scope, id }) => state[scope].coolness[id];
 *
 * Then passing a feature to the HOF will return a feature that behaves like;
 *
 * defaultPath.js
 *   export const defaultPath = 'my/cool/path';
 * actions.js
 *   export const someCoolAction = ({ otherData }) => ({ type: SOME_COOL_ACTION, payload: otherData  meta: { scopePassedToTheHof } });
 * selectors.js
 *   export const someCoolSelector = ({ id }) => statePassedToTheHoff[defaultPath][scopePassedToTheHof].coolness[id];
 */
export const withScopedFeature =
  ({ state, scope }) =>
  (feature) => {
    const actionsWithScopeInjected = withParameterInjections({ scope })(feature.actions);
    const operationsWithScopeInjected = withParameterInjections({ scope, defaultPath: feature.defaultPath })(
      feature.operations,
    );
    const slicedSelectors = withSelectorStateSlicing(feature.defaultPath)(feature.selectors);
    const selectorsWithStateAndScopeInjected = withSelectorParameterInjections({ scope, state })(slicedSelectors);

    return {
      ...feature,
      actions: actionsWithScopeInjected,
      selectors: selectorsWithStateAndScopeInjected,
      operations: operationsWithScopeInjected,
    };
  };
