import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react/button';
import { Modal } from '@sb-itops/react/modal';
import * as Styles from './XeroInvoiceIntegrationConfirmationModal.module.scss';

const XeroInvoiceIntegrationConfirmationModal = ({ isVisible, onConfirm, onClose }) => (
  <Modal
    title="Enable Xero Invoice Integration"
    body={
      <div>
        <p>
          Enabling this feature will change your Xero integration. New invoices and payments will be sent to Xero as
          invoices and payments, rather than as journal entries. Once turned on, to ensure your financial data
          integrity, this can’t be turned off again.
        </p>
        <p>Are you sure you want to continue?</p>
      </div>
    }
    footer={
      <div className={Styles.footer}>
        <Button className={Styles.footerBtn} onClick={onConfirm}>
          Continue
        </Button>
        <Button className={Styles.footerBtn} type="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    }
    isVisible={isVisible}
    onModalClose={onClose}
    shouldCloseOnOverlayClick={false}
  />
);

XeroInvoiceIntegrationConfirmationModal.displayName = 'XeroInvoiceIntegrationConfirmationModal';

XeroInvoiceIntegrationConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

XeroInvoiceIntegrationConfirmationModal.defaultProps = {};

export { XeroInvoiceIntegrationConfirmationModal };
