import { Modal } from '@sb-itops/react';
import React from 'react';
import { featureActive } from '@sb-itops/feature';
import * as Styles from './AddWidgets.module.scss';
import { WIDGET_DEFAULTS } from './libs';

export const AddWidgets = ({ onAddWidget, onClose, matterId }) => (
  <Modal
    className={Styles.modal}
    title="Select a Widget from the Library"
    body={
      <div className={Styles.widgets}>
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.DailyDigest)}>
          <div className={Styles.title}>My Daily Digest</div>
          <div>View a list of all tasks and events assigned to you that are scheduled for today</div>
        </div>
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.Tasks)}>
          <div className={Styles.title}>My Overdue Tasks</div>
          <div>View a list of all overdue tasks assigned to you{matterId ? ' for this matter' : ''}</div>
        </div>
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.Events)}>
          <div className={Styles.title}>My Upcoming Events</div>
          <div>
            View a list of all events on your calendar for today and the future{matterId ? ' for this matter' : ''}
          </div>
        </div>
        {!matterId && (
          <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.RecentlyViewed)}>
            <div className={Styles.title}>Recently Viewed Matters</div>
            <div>Quickly access your recently viewed matters</div>
          </div>
        )}
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.BillableHours)}>
          <div className={Styles.title}>Hours</div>
          <div>See todays billable and non billable hours{matterId ? ' for this matter' : ''}</div>
        </div>
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.BillableGraph)}>
          <div className={Styles.title}>Billable Activity</div>
          <div>An overview of this months fee activity</div>
        </div>
        {/* <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.RecentDocuments)}>
          <div className={Styles.title}>My Recent Documents</div>
          <div>
            Access a list of your recently created, edited, or viewed document so you can easily navigate back to it.
          </div>
        </div> */}
        {featureActive('NUCWEB-504') && (
          <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.LeadsNoActivity)}>
            <div className={Styles.title}>Leads with No Activity</div>
            <div>
              Providing you with visibility over any leads that have not had activity over a period of time so that you
              can be sure to stay on top of these new business opportunities
            </div>
          </div>
        )}
        {featureActive('NUCWEB-505') && (
          <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.MattersNoActivity)}>
            <div className={Styles.title}>Matters with No Activity</div>
            <div>
              Providing you with visibility over any matters that have not had activity over a period of time so that
              you can be sure to stay on top of all of your matters
            </div>
          </div>
        )}
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.NewLeads)}>
          <div className={Styles.title}>New Leads</div>
          <div>View all new leads that have been created in a given time frame</div>
        </div>
        <div className={Styles.widget} onClick={() => onAddWidget(WIDGET_DEFAULTS.NewMatters)}>
          <div className={Styles.title}>New Matters</div>
          <div>View all new matters that have been created in a given time frame</div>
        </div>
      </div>
    }
    footer={<div />}
    onModalClose={onClose}
  />
);
