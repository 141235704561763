import React from 'react';
import { Table, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { Column } from '@sb-itops/react';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import moment from 'moment';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { Task } from 'types';
import Styles from './TaskTable.module.scss';

const log = getLogger('TaskTable');

const { checkboxCellWrapperRenderer, timestampLocalisedRenderer } = utils;

const dueDateCellRenderer = ({ cellData }) => (
  <div className={moment(cellData).isBefore(moment().startOf('day')) ? Styles.overDue : ''}>
    {timestampLocalisedRenderer({ cellData })}
  </div>
);

function checkboxCellRenderer({ props }) {
  return ({ rowData }) => {
    const { onSaveTask } = props;
    return (
      <Checkbox
        checked={!!rowData.isCompleted}
        onChange={async (value) => {
          try {
            await onSaveTask({ ...rowData, isCompleted: value }, false);
            displaySuccessToUser(value ? 'Task completed' : 'Your task has been saved');
          } catch (err) {
            displayErrorToUser(value ? 'Failed to complete task' : 'Failed to save task');
            log.error(err);
          }
        }}
      />
    );
  };
}

function matterCellRenderer({ props: { onClickLink } }) {
  return ({ cellData: matterId }) => <MatterDisplay onClickLink={onClickLink} asLink matterId={matterId} />;
}

const completedViews = ['RECENTLY_COMPLETED', 'ALL_COMPLETED'];

const TaskTabs = (props: { cellData?: string[] }) => (
  <>
    {(props.cellData || []).map((label) => (
      <span key={label} className={Styles.categoryTag}>
        {label}
      </span>
    ))}
  </>
);

interface ITaskTableProps {
  tasks: Task[];
  showMatter?: boolean;
  onSaveTask: (task: Task, isNewEntity: boolean) => void;
  view: string;
  onClickLink: (options: { type: string; id: string }) => void;
  loading: boolean;
  onClickTaskRow: (options: { task: Task }) => void;
  sortDirection: 'ASC' | 'DESC';
  sortBy: string;
  setSort: (payload: { sortBy: string; sortDirection: 'asc' | 'desc' }) => void;
}

export const TaskTable = (props: ITaskTableProps) => {
  const { tasks, showMatter, loading, onClickTaskRow, view, sortDirection, sortBy, setSort } = props;

  return (
    <Table
      className={Styles.taskTable}
      onRowClick={({ rowData }) => onClickTaskRow({ task: rowData })}
      rowClassName={({ rowData }) => rowData && rowData.isRemoved && Styles.deleted}
      list={tasks}
      sort={setSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      dataLoading={loading}
      resetScrollPositionOnDataChange
    >
      <Column
        key="completed"
        dataKey="completed"
        label=""
        width={34}
        cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer({ props }))}
        disableSort
      />
      <Column key="subject" dataKey="subject" label="Name" flexGrow={3} />
      {!completedViews.includes(view) && (
        <Column key="dueDate" dataKey="dueDate" label="Due" cellRenderer={dueDateCellRenderer} width={100} />
      )}
      {completedViews.includes(view) && (
        <Column
          key="completionDate"
          dataKey="completionDate"
          label="Completed on"
          cellRenderer={timestampLocalisedRenderer}
          width={150}
        />
      )}
      <Column
        disableSort
        key="categories"
        dataKey="categories"
        label="Categories"
        cellRenderer={TaskTabs}
        flexGrow={1}
      />
      <Column
        disableSort
        key="assignees"
        dataKey="assignees"
        label="Assigned to"
        cellRenderer={({ cellData }) => cellData && cellData.join(', ')}
        flexGrow={1}
      />
      <Column disableSort key="lastAssignedBy" dataKey="lastAssignedBy" label="Assigned by" flexGrow={1} />
      {showMatter && (
        <Column
          key="matterId"
          dataKey="matterId"
          disableSort
          label="Matter"
          cellRenderer={matterCellRenderer({ props })}
          flexGrow={3}
        />
      )}
    </Table>
  );
};

TaskTable.displayName = 'TaskTable';
