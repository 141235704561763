import React from 'react';
import { TaskGrid } from 'web/components';
import Styles from './ProductivityTest.module.scss';

export const ProductivityTest = () => (
  <div className={Styles.productivityTest}>
    <TaskGrid />
  </div>
);

ProductivityTest.displayName = 'ProductivityTest';

ProductivityTest.propTypes = {};

ProductivityTest.defaultProps = {};
