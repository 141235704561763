import { createSelector } from 'reselect';
import { sortByProperty } from '@sb-itops/nodash';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: `relate-contact-to-matters`,
  keyPath: 'key',
  ngCacheName: 'sbRelateContactToMattersMbService',
  syncType: syncTypes.SINCE,
});

export const { updateCache, clearCache } = api;

// The endpoint previously returned mapping data in the structure:
// {
//   key: entity.EntityId + entity.RoleId + entity.MatterId,
//   matterId: entity.MatterId,
//   roleId: entity.RoleId,
//   entityId: entity.EntityId,
//   representativeIds: entity.RepresentativeIds,
// }
//
// The behaviour has been moved here to make working with the presync checks easier.
// The getMap, getList and getById have been changed to present consistent data
// to users of the cache
const getMapSelector = createSelector(
  (list) => list,
  (list) =>
    sortByProperty(list, '$sbSyncValue', 'asc').reduce((acc, entity) => {
      const mapping = {
        key: entity.entityId + entity.roleId + entity.matterId,
        matterId: entity.matterId,
        roleId: entity.roleId,
        entityId: entity.entityId,
        representativeIds: entity.representativeIds,
      };
      acc[mapping.key] = mapping;

      return acc;
    }, {}),
);

// see getMapSelector above
export const getMap = () => getMapSelector(api.getList());

// see getMapSelector above
export const getList = () => Object.values(getMap());

// see getMapSelector above
export const getById = (id) => getMap()[id];

export const getContactsByRoleSelector = createSelector(
  (list) => list,
  (list) =>
    list.reduce((acc, entity) => {
      // If the entity id has already been mapped to this role, we don't want
      // to add it to the accumulator array for this role.
      const entitiesInRole = {};
      if (entitiesInRole[entity.entityId]) {
        return acc;
      }

      entitiesInRole[entity.entityId] = true;

      const ids = acc[entity.roleId] || [];
      ids.push(entity.entityId);
      acc[entity.roleId] = ids;

      return acc;
    }, {}),
);

export const getContactsByRole = () => getContactsByRoleSelector(getList());
