'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/bills/list';

  // TODO make this use a component
  $stateProvider
    .state('home.billing.bills.list', {
      url: '/list',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/list-bills.html',
      controller: 'ListBillsController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills', 'list-bills']
      }
    });

});
