import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import moment from 'moment';
import classnames from 'classnames';
import uuid from '@sb-itops/uuid';
import 'react-calendar/dist/Calendar.css';
import './DatePicker.scss';
import { getRegion, regionType } from '@sb-itops/region';
import { useTranslation } from '../localisation';
import Styles from './DatePicker.module.scss';

export const sizes = ['XS', 'S', 'M', 'L'];
// modal has hard-coded 29.5px top margin
const MODAL_TOP_OFFSET = 29.5;

const getFormattedDate = (date, format, t) => (date && t('date', { date, format })) || undefined;

const calendarTypeByRegion = {
  [regionType.US]: 'US',
  [regionType.AU]: 'ISO 8601',
  [regionType.GB]: 'ISO 8601',
};

const parseDate = (text, validDateFormats) => moment(text, validDateFormats, true);

// see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
const useOutsideAlerter = (ref, toggleShowCalendar, ids) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        // let the calendar button/icon handler toggle the calendar
        // otherwise, the calendar would be closed here and then opened by the button/icon
        !ids.includes(event.target.id)
      ) {
        toggleShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ids, ref, toggleShowCalendar]);
};

// https://stackoverflow.com/questions/35939886/find-first-scrollable-parent
// https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775
// we dont use the includeHidden param here, but I've left it in-case this is re-used elsewhere
const getScrollParent = (element, includeHidden) => {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === 'fixed') {
    return document.body;
  }

  // eslint-disable-next-line no-cond-assign
  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent);

    if (excludeStaticParent && style.position === 'static') {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent;
    }
  }

  return document.body;
};

// dirty hack to see if we're in a modal based on a class name
function isInModal(element) {
  if (element.className && element.className.split(' ').indexOf('modal-dialog') >= 0) {
    return true;
  }

  return element.parentNode && isInModal(element.parentNode);
}

// Angular modal uses div transform so we need to use modal right edge position rather than clientWidth
const getClientWidth = ({ inModal }) => {
  if (inModal) {
    const modalElements = document.getElementsByClassName('modal-dialog');
    if (modalElements.length) {
      return modalElements[0].getBoundingClientRect()?.right;
    }
  }

  return document.body.clientWidth;
};

const calendarHeight = 360;
const getCalendarTop = (component, { offset }) => {
  const rect = component.current.getBoundingClientRect();
  const componentBase = rect.top + rect.height - offset;
  const viewPortHeight = window.innerHeight;
  const calendarTop = componentBase + calendarHeight > viewPortHeight ? viewPortHeight - calendarHeight : componentBase;

  return calendarTop;
};

/**
 * @callback onSelect
 * @param {object} native Date
 */

/**
 * @param {object} props
 * @param {onSelect} props.onSelect
 * @param {object} [props.value] native date
 * @param {object} [props.minDate] native date
 * @param {object} [props.maxDate] native date
 * @param {string} [props.format] a US supported moment format
 * @param {boolean} [props.hasError]
 * @param {boolean} [props.disabled]
 * @param {string} [props.size] one of XS, S, M, L
 * @param {boolean} [props.minimal] remove icons, open calendar on click if input field
 * @param {boolean} [props.className] css class name for component
 * @param {boolean} [props.containerClassName] css class name for the container of the component
 * @param {boolean} [props.hideDelete] hide delete button on date picker
 */
const DatePicker = ({
  onSelect,
  value,
  minDate,
  maxDate,
  format,
  hasError,
  disabled,
  size,
  minimal,
  className,
  containerClassName,
  hideDelete,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const calendarIconRef = useRef(null);
  const calendarRef = useRef(null);
  const [text, setText] = useState('');
  const [date, setDate] = useState();
  const [dateFormat, setDateFormat] = useState();
  const [validDateFormats, setValidDateFormats] = useState([]);
  const [showCalendar, toggleShowCalendar] = useState(false);
  const [calendarBtnId] = useState(`showCalendarBtn-${uuid()}`);
  const [showCalendarIconId] = useState(`showCalendarBtn-${uuid()}`);
  const [inputInViewRef, inputInView] = useInView();
  const [inModal, setInModal] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  useOutsideAlerter(calendarRef, toggleShowCalendar, [calendarBtnId, showCalendarIconId]);

  // Get valid dateformat resources
  // These are used for parsing user input, and aren't directly called in code
  // e.g. if user types 1/06/2023 (D/MM/YYYY) or 1 June 2023
  // we need to be able to parse it correctly and fix the formatted display
  useEffect(() => {
    const resources = t('datePicker', { returnObjects: true });
    const validDateFormatArray = Object.keys(resources).reduce((acc, key) => {
      acc.push(t(key));
      return acc;
    }, []);
    setValidDateFormats(validDateFormatArray);
  }, [t]);

  // ensure that the calendar pops-up to the left when the date picker is too close to the right of the view/modal edge
  // modified from: https://stackoverflow.com/questions/44638737/how-to-align-the-popup-div-direction-in-css
  useEffect(() => {
    if (inputRef.current && calendarRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      // if we are in angular modal, the clientWidth is right edge of modal rather than the browser's display area
      const clientWidth = getClientWidth({ inModal });
      const calendarwidth = 320;

      if (inputRect.left + calendarwidth > clientWidth) {
        calendarRef.current.classList.add(Styles.toLeft);
      }
    }
  });

  // position the calendar relative to the input field on scroll
  useEffect(() => {
    if (calendarRef.current && inputRef.current) {
      const scrollParent = getScrollParent(inputRef.current);
      const offset = inModal ? MODAL_TOP_OFFSET : 0;

      scrollParent.onscroll = () => {
        if (calendarRef.current) {
          calendarRef.current.style.top = `${getCalendarTop(inputRef, { offset })}px`;
        }
      };
    }
  });

  // when calendar opens, decide if it's in a modal
  useEffect(() => {
    if (calendarRef.current) {
      setInModal(isInModal(calendarRef.current));
    }
  }, [showCalendar]);

  if (date !== value) {
    setText(value ? getFormattedDate(value, format, t) : '');
    setDate(value);
  }

  // there is no reason to switch format while rendered, but its still useful for testing
  if (format !== dateFormat) {
    setText(value ? getFormattedDate(value, format, t) : '');
    setDateFormat(format);
  }

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      parseAndSelect();
    }
  };

  const parseAndSelect = () => {
    if (text.trim() === '') {
      onSelect();
      setText('');
    }
    if (text !== getFormattedDate(value, format, t)) {
      const parsed = parseDate(text, validDateFormats);
      // if the entered date is valid, display in preferred format
      // respect min / max date
      if (parsed.isValid()) {
        if (parsed.toDate().getTime() > maxDate?.getTime()) {
          onSelect(maxDate);
          setText(getFormattedDate(maxDate, format, t));
        } else if (parsed.toDate().getTime() < minDate?.getTime()) {
          onSelect(minDate);
          setText(getFormattedDate(minDate, format, t));
        } else {
          onSelect(parsed.toDate());
          setText(getFormattedDate(parsed, format, t));
        }
      }
    }
  };

  const onClear = () => {
    onSelect();
    toggleShowCalendar(false);
    setText('');
    inputRef.current.focus();
  };

  const selectDate = (selected) => {
    onSelect(selected);
    setText(getFormattedDate(selected, format, t));
    toggleShowCalendar(false);
  };

  const clearIconClasses = classnames(...['fa', 'fa-times', 'fa-fw']);
  const clearBtnClasses = classnames(
    Styles[`clearIcon${size}`],
    Styles.clearIcon,
    disabled ? Styles.clearDisabled : Styles.clearEnabled,
    hasFocus && Styles.clearBtnFocus,
    hasError && Styles.clearBtnError,
  );
  const calendarIconClasses = classnames(...['fa', 'fa-calendar', 'fa-fw']);
  const calendarBtnClasses = classnames(
    Styles[`calendarIcon${size}`],
    Styles.calendarIcon,
    disabled ? Styles.calendarDisabled : Styles.calendarEnabled,
    hasFocus && Styles.calendarBtnFocus,
    hasError && Styles.calendarBtnError,
  );

  return (
    <div className={classnames(inModal ? Styles.containerModal : Styles.container, 'date-picker', containerClassName)}>
      <div
        ref={inputInViewRef}
        className={classnames(minimal ? Styles.inputRowMinimal : Styles.inputRow, Styles[`inputRow${size}`])}
      >
        <input
          type="text"
          ref={inputRef}
          className={classnames(Styles.dateInput, Styles[`dateInput${size}`], hasError && Styles.inputError)}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={onEnter}
          onFocus={() => {
            if (showCalendar) {
              toggleShowCalendar(false);
            }
            setHasFocus(true);
          }}
          onBlur={() => {
            parseAndSelect();
            setHasFocus(false);
          }}
          disabled={disabled}
          onClick={() => {
            if (minimal) {
              toggleShowCalendar(true);
            }
          }}
        />
        {!minimal && !hideDelete && (
          <button type="button" className={clearBtnClasses} onClick={onClear} disabled={disabled}>
            <i className={clearIconClasses} />
          </button>
        )}
        {!minimal && (
          <button
            // id is here to manage the behaviour when clicking outside the calendar pop-up
            id={calendarBtnId}
            type="button"
            className={calendarBtnClasses}
            onClick={() => toggleShowCalendar(!showCalendar)}
            disabled={disabled}
            ref={calendarIconRef}
          >
            <i
              // id is here to manage the behaviour when clicking outside the calendar pop-up
              id={showCalendarIconId}
              className={calendarIconClasses}
            />
          </button>
        )}
      </div>
      {inputInView && showCalendar && (
        <div
          className={classnames(className, Styles.calendar)}
          ref={calendarRef}
          style={{ top: `${`${getCalendarTop(inputRef, { offset: inModal ? MODAL_TOP_OFFSET : 0 })}px`}` }}
        >
          <Calendar
            onChange={selectDate}
            value={value}
            // calendar type configures the style of the calendar, e.g. start of the working week
            // US is the correct format for both AU and US
            // see: https://github.com/wojtekmaj/react-calendar
            calendarType={calendarTypeByRegion[getRegion()]}
            minDate={minDate}
            maxDate={maxDate}
          />
          <button
            type="button"
            className={Styles.todayBtn}
            onClick={() => {
              const now = new Date();
              onSelect(now);
              setText(getFormattedDate(now, format, t));
              toggleShowCalendar(false);
            }}
          >
            Today
          </button>
        </div>
      )}
    </div>
  );
};

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  // DatePicker does its own localisation of the format.
  // This means that the calling code should *not* localise
  // and hard-code the value to be the localisation resource dateformat key suffix, e.g. D MMMM YYYY
  format: PropTypes.string,
  size: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  minimal: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  hideDelete: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: undefined,
  minDate: undefined,
  maxDate: undefined,
  format: 'DD/MM/YYYY',
  size: 'M',
  hasError: false,
  disabled: false,
  minimal: false,
  className: undefined,
  containerClassName: undefined,
  hideDelete: false,
};

export default DatePicker;
