angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/reset-modals';

  $stateProvider
    .state('home.billing.support.reset-modals', {
      url: '/reset-modals',
      templateUrl: BASE_DIRECTORY + '/reset-modals.html',
      controller: 'SupportResetModalsController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});
