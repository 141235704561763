import { setDefaultState as setInitStateFactory } from '../../../actions/generic/set-default-state';
import { domain } from '../../../domain';
import name from '../name';

const defaultState = {};

const { setDefaultStateAction, setDefaultStateReducer, SET_DEFAULT_STATE } = setInitStateFactory({
  actionName: `${domain}/${name}/INIT_DEFAULT_STATE`,
  defaultState,
});

export const setDefaultState = () => setDefaultStateAction();

export const setDefaultStateReducerEntry = {
  [SET_DEFAULT_STATE]: setDefaultStateReducer,
};
