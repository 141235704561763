'use strict';

const { entryTypeLabels } = require('../../shared/entities');

/** @typedef {import('./types').UtbmsTaskCode} UtbmsTaskCode */

/**
 * Filter UTBMS task codes (Time and Fees)
 *
 * @param {Object} param
 * @param {UtbmsTaskCode[]} param.utbmsTaskCodes
 * @param {String[]} param.utbmsCodeSetsUsedByFirm Firm UTBMS selectedCodeSets array
 * @returns {Object} Filtered utbmsTaskCodes array
 */
const filterUtbmsTaskCodes = ({ utbmsTaskCodes, utbmsCodeSetsUsedByFirm } = {}) => {
  if (utbmsCodeSetsUsedByFirm === undefined) {
    return [];
  }

  const utbmsCodeSetsUsedByFirmLookup = new Set(utbmsCodeSetsUsedByFirm);

  // don't use third party task codes when custom task codes are enabled
  const filteredTaskCodes = utbmsTaskCodes.filter(
    (taskCode) =>
      (taskCode.type !== 'ThirdParty' &&
        taskCode.type !== 'ExpenseThirdParty' &&
        utbmsCodeSetsUsedByFirmLookup.has(taskCode.type)) ||
      (taskCode.category === 'utbmsCustomTaskCodes' && taskCode.type !== entryTypeLabels.EXPENSE),
  );

  return filteredTaskCodes;
};

module.exports = {
  filterUtbmsTaskCodes,
};
