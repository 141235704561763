angular.module('sb.billing.webapp').controller('sbViewMatterExpensesController', function ($scope, $state, $uibModal, $stateParams, sbLinkService, sbAsyncOperationsService) {
  'use strict';

  var that = this;

  that.view = {
    matterId: $stateParams.matterId
  };

  that.onClickLink = sbLinkService.onClickLink;
  that.onPrintCheque = (chequeId) => sbAsyncOperationsService.startOperatingChequeCreation([chequeId]);
  that.onExportExpenseCsv = sbAsyncOperationsService.startExportExpenseCsv;

  that.onCreateInvoice = (matterId) => $state.go('home.billing.create-bill', { matterId });
});
