/* eslint-disable no-bitwise */

export const fileFolderChangeTypeMap = {
  Revert: 0,
  Copy: 1,
  CopyToAnotherMatter: 2,
  CopyFromAnotherMatter: 3,
  Restore: 4,
  Rename: 5,
  Move: 6,
  MoveToAnotherMatter: 7,
  MoveFromAnotherMatter: 8,
  Delete: 9,
  Created: 10,
  Modified: 11,
  Cancelled: 12,
  AddedToFavorites: 13,
  RemovedFromFavorites: 14,
};

export const fileFolderChangeTypeLabelMap = {
  0: 'File Reverted',
  1: 'Copied',
  2: 'Copied To Another Matter',
  3: 'Copied From Another Matter',
  4: 'Restored',
  5: 'Renamed',
  6: 'Moved',
  7: 'Moved To Another Matter',
  8: 'Moved From Another Matter',
  9: 'Deleted',
  10: 'Created',
  11: 'Modified',
  12: 'Cancelled',
  13: 'Added To Favorites',
  14: 'Removed From Favorites',
};
