import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { CloseReopenControlledMoneyAccountForm } from '../controlled-money-account-close-reopen';
import Styles from './ControlledMoneyAccountCloseReopenModal.module.scss';

export const ControlledMoneyAccountCloseReopenModal = React.memo(
  ({
    onSubmit,
    onClose,
    accountIsOpen,
    isSubmitDisabled,
    scope,
    matterId,
    accountBalance,
    validateForm,
    lastOpenDate,
  }) => {
    const balanceIsZero = accountBalance === 0;

    return (
      <Modal
        className={Styles.closeReopenModal}
        title={accountIsOpen ? 'Close Account' : 'Reopen Account'}
        body={
          <CloseReopenControlledMoneyAccountForm
            scope={scope}
            matterId={matterId}
            isCloseAccount={accountIsOpen}
            validateForm={validateForm}
            lastOpenDate={lastOpenDate}
          />
        }
        footer={
          <div className={Styles.buttonContainer}>
            <Button
              className={Styles.submitButton}
              onClick={(event) => onSubmit({ event })}
              disabled={(accountIsOpen && !balanceIsZero) || isSubmitDisabled}
              form={scope}
              locked={isSubmitDisabled}
              size="full-width"
            >
              {accountIsOpen ? 'Close Account' : 'Reopen Account'}
            </Button>
            {accountIsOpen && !balanceIsZero && (
              <div className={Styles.errorMessage}>Only accounts with a zero dollar balance can be closed</div>
            )}
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

ControlledMoneyAccountCloseReopenModal.displayName = 'ControlledMoneyAccountCloseReopenModal';

ControlledMoneyAccountCloseReopenModal.propTypes = {
  scope: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  matterId: PropTypes.string.isRequired,
  validateForm: PropTypes.func.isRequired,
  lastOpenDate: PropTypes.number.isRequired,
  accountIsOpen: PropTypes.bool.isRequired,
  accountBalance: PropTypes.number.isRequired,
};

ControlledMoneyAccountCloseReopenModal.defaultProps = {
  isSubmitDisabled: false,
};
