const Yup = require('yup');

const { isValidUuid } = require('@sb-itops/business-logic/validation/services');

// staffTargetAssignment is an object like { [staffId]: staffTargetGroupId, [staffId]: staffTargetGroupId, ... }
const StaffTargetAssignmentSchema = Yup.object()
  .test('dynamic-keys', 'Invalid staffIds or targetGroupIds', (obj) =>
    // extract the keys (staffIds) and values (targetGroupIds) from the object, and validate them
    Object.entries(obj).every(([staffId, staffTargetGroupId]) => {
      const isValidStaffId = isValidUuid(staffId);
      const isValidTargetGroupId =
        staffTargetGroupId === null || staffTargetGroupId === undefined || isValidUuid(staffTargetGroupId);

      return isValidStaffId && isValidTargetGroupId;
    }),
  )
  .nullable();

module.exports = {
  StaffTargetAssignmentSchema,
};
