/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BankReconciliationsFilterPanel } from 'web/react-redux/components/bank-reconciliations-filter-panel';
import { BankReconciliationsList } from 'web/react-redux/components/bank-reconciliations-list';
import { TrustAccountsOverview } from '../../components/trust-accounts-overview';
import Styles from './BillingBankReconciliationsHome.module.scss';

const BillingBankReconciliationsHome = memo(({ onClickLink, trustAccountId, accountType }) => (
  <div className="data panel-default">
    <div className={classnames('master-detail-panel', Styles.masterDetailPanel)}>
      <div className={Styles.filterPanel}>
        <BankReconciliationsFilterPanel
          onClickLink={onClickLink}
          accountType={accountType}
          trustAccountId={trustAccountId}
        />
      </div>

      <div className={Styles.panelBody}>
        {!trustAccountId && <TrustAccountsOverview onClickLink={onClickLink} parentPage="BANKRECS" />}
        {trustAccountId && trustAccountId !== 'all' && (
          <BankReconciliationsList trustAccountId={trustAccountId} onClickLink={onClickLink} />
        )}
      </div>
    </div>
  </div>
));

BillingBankReconciliationsHome.displayName = 'BillingBankReconciliationsHome';

BillingBankReconciliationsHome.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string,
  accountType: PropTypes.string.isRequired,
};

BillingBankReconciliationsHome.defaultProps = {
  trustAccountId: undefined,
};

export default BillingBankReconciliationsHome;
