import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useCallback, useEffect, useState } from 'react';
import { getAccountId, getProductTier } from 'web/services/user-session-management';
import { fetchSmokeballSubscriptionRecord } from 'web/services/subscription';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import { InitUserBillingAttributes, ProductBoostTrial } from 'web/graphql/queries';
import moment from 'moment';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs';
import { featureActive } from '@sb-itops/feature';
import { isBarUser } from '@sb-finance/redux/subscription';
import { PageBannerBoostTrial } from './PageBannerBoostTrial';

const SEVEN_DAY_WARNING_MESSAGE_ID = 'SEVEN_DAY_WARNING_MESSAGE_ID';
const THREE_DAY_WARNING_MESSAGE_ID = 'THREE_DAY_WARNING_MESSAGE_ID';

const ONE_MINUTE = 60000;

const hooks = () => ({
  useTrialLengthData: () => {
    const [instantHide, setInstantHide] = useState();
    const [isBarBillFreeBoostTrial, setIsBarBillFreeBoostTrial] = useState();

    // Error is ignored here, let's not break every page in the app if this fails
    const { data, loading } = useSubscribedQuery(ProductBoostTrial, {
      skip: !isBarBillFreeBoostTrial,
    });

    const { data: userBillingAttributesData, loading: userBillingAttributesLoading } = useCacheQuery(
      InitUserBillingAttributes.query,
    );

    const viewedMessages = userBillingAttributesData?.userBillingAttributes?.viewedMessages || [];

    const trialStart = data?.productBoostTrialStart && moment(data?.productBoostTrialStart);
    const trialLength = trialStart && moment().diff(trialStart, 'days');

    const isExpiring7Days =
      (!viewedMessages.includes(SEVEN_DAY_WARNING_MESSAGE_ID) && trialStart && trialLength >= 23 && trialLength < 27) ||
      featureActive('NUCWEB-855-DEBUG7');
    const isExpiring3Days =
      (!viewedMessages.includes(THREE_DAY_WARNING_MESSAGE_ID) && trialStart && trialLength >= 27 && trialLength < 29) ||
      featureActive('NUCWEB-855-DEBUG3');
    const isExpiring1Day = (trialStart && trialLength >= 29) || featureActive('NUCWEB-855-DEBUG1');

    let message = '';
    let showCloseButton = true;

    if (isExpiring7Days) {
      message =
        "Your 30-day Boost trial will expire in 7 days. Upgrade now so you don't lose access to productivity features";
    }
    if (isExpiring3Days) {
      message =
        "Your 30-day Boost trial will expire in 3 days. Upgrade now so you don't lose access to productivity features";
    }
    if (isExpiring1Day) {
      message =
        "Your 30-day Boost trial will expire in 1 day. Upgrade now so you don't lose access to productivity features";
      showCloseButton = false;
    }

    const isLoading = isBarBillFreeBoostTrial === undefined || loading || userBillingAttributesLoading;
    const showBanner = !!message && !isLoading && isBarBillFreeBoostTrial && !instantHide;

    const productTier = useSelector(() => getProductTier());
    const barUser = useSelector(() => isBarUser());

    const downloadSubscriptionData = useCallback(async () => {
      if (productTier !== productTierIdsByName.BOOST || !barUser) {
        setIsBarBillFreeBoostTrial(false);
        return;
      }
      // Cant use the subscription store as we need the stripe product status
      const downloadedSubscriptionRecord = await fetchSmokeballSubscriptionRecord({ accountId: getAccountId() });

      setIsBarBillFreeBoostTrial(
        downloadedSubscriptionRecord?.custom?.smokeballProductIdInStripe === productTierIdsByName.BILL,
      );
    }, [productTier, barUser]);

    useEffect(() => {
      downloadSubscriptionData();
      const unsub = subscribeToNotifications({
        notificationIds: ProductBoostTrial.notificationIds,
        callback: () => {
          downloadSubscriptionData();
        },
      });
      return unsub;
    }, [downloadSubscriptionData]);

    return {
      onClose: async () => {
        setInstantHide(true);
        if (isExpiring7Days && !viewedMessages.includes(SEVEN_DAY_WARNING_MESSAGE_ID)) {
          await dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: SEVEN_DAY_WARNING_MESSAGE_ID },
          });
        }
        if (isExpiring3Days && !viewedMessages.includes(THREE_DAY_WARNING_MESSAGE_ID)) {
          await dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: THREE_DAY_WARNING_MESSAGE_ID },
          });
        }
        setTimeout(() => {
          // Switch back after a while so we can show the other messages
          setInstantHide(false);
        }, ONE_MINUTE);
      },
      showBanner,
      showCloseButton,
      message,
    };
  },
});

export const PageBannerBoostTrialContainer = withReduxProvider(
  withApolloClient(composeHooks(hooks)(PageBannerBoostTrial)),
);

PageBannerBoostTrialContainer.displayName = 'PageBannerBoostTrialContainer';

PageBannerBoostTrialContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};

PageBannerBoostTrialContainer.defaultProps = {};
