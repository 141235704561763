import { SET_REPORT_STATE, TOGGLE_SHOW_FILTERS, RESET_ALL_REPORT_STATE } from './types';

const initialState = {
  showFilters: true,
  reportState: {},
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }

    case SET_REPORT_STATE: {
      return {
        ...state,
        reportState: {
          ...state.reportState,
          [action.payload.reportId]: {
            ...state.reportState[action.payload.reportId],
            ...action.payload.changedState,
          },
        },
      };
    }

    case RESET_ALL_REPORT_STATE: {
      return {
        ...state,
        reportState: {},
      };
    }

    default: {
      return state;
    }
  }
};
