import {
  updateCache as updateRedux,
  getById,
  getList,
  getByType,
  getActive,
  getTrustAccounts,
  getOperatingAccount,
} from '@sb-billing/redux/bank-account';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { firmManagement } from '@sb-firm-management/redux';

angular.module('@sb-billing/services').service('sbBankAccountService', function (sbGenericCacheService, sbLoggerService, sbEndpointType, sbRegionType, sbEnvironmentConfigService) {
  'use strict';

  const that = this;
  const log = sbLoggerService.getLogger('sbBankAccountService');
  
  const { updateP } = sbGenericCacheService.setupCache({
    name: 'sbBankAccountService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'billing/bank-account' },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.BankAccountUpdated'
    },
    updateRedux,
  });
  
  let firmContact = null;

  // log.setLogLevel('debug');

  that.accountType = {
    trust: 'TRUST',
    operating: 'OPERATING'
  };

  that.get = get;
  that.getActive = getActive;
  that.getByType = getByType;
  that.getOperatingAccount = getOperatingAccount;
  that.syncBankAccount = syncBankAccount;
  that.operatingDepositsAllowed = operatingDepositsAllowed;
  that.getFirmContact = getFirmContact;
  that.isStatutoryDepositMatter = (matterId) => isStatutoryDepositMatter({
    matterId,
    trustBankAccounts: getTrustAccounts(),
    supportsStatutoryDepositMatter: hasFacet(facets.statutoryDepositMatter)
  });

  function get(id) {
    log.info('get', id || 'all');

    return id ? getById(id) : getList().filter((account) => !account.isDeleted);
  }


  function syncBankAccount() {
    return getList().length ? Promise.resolve() : updateP().then(() => getList().length);
  }

  function operatingDepositsAllowed() {
    const allowedOperatingDepositRegions = [sbRegionType.US];
    const currentRegion = sbEnvironmentConfigService.getRegion();
    return allowedOperatingDepositRegions.indexOf(currentRegion) !== -1;
  }

  function getFirmContact() {
    if (!firmContact) {
      const firmDetails = firmManagement.getFirmDetails();
      
      firmContact = {
        accountId: firmDetails && firmDetails.accountId,
        displayName: firmDetails && firmDetails.firmName,
        entityId: '23b3857d-5c5b-4871-8fbc-35a7cb670f5a',
      };
    }

    return firmContact;
  }

});
