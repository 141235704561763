import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Table as RvTable, Column as RvColumn, AutoSizer, SortDirection } from 'react-virtualized';
import classnames from 'classnames';
import { LoadingBarInfinite } from '../loading-bar-infinite';
import { headerCellRenderer } from './utils';

const HEADER_HEIGHT = 30;
const ROW_HEIGHT = 38;
const FOOTER_HEIGHT = 29;

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.renderFooter = this.renderFooter.bind(this);
    this.recomputeRowHeights = this.recomputeRowHeights.bind(this);
  }

  componentDidUpdate(prevProps) {
    // For paginated data we want to reset the scroll position when switching
    // between pages, otherwise the scroll position from the previous page
    // will be retained
    if (
      this.props.resetScrollPositionOnDataChange &&
      this.props.list &&
      this.props.list.length &&
      this.props.list !== prevProps.list
    ) {
      this.RvRef.scrollToPosition(0);
    }
  }

  recomputeRowHeights({ index }) {
    this.RvRef.recomputeRowHeights(index);
  }

  renderFooter({ height, summary }) {
    const { children } = this.props;

    if (this.props.footerRenderer) {
      return this.props.footerRenderer({ height, footerData: summary });
    }

    return (
      <div className="table-footer">
        {children.map((child, index) => {
          if (!child) {
            return undefined;
          }
          const { props } = child;
          const { className, flexGrow, style, footerRenderer = () => '', width: cellWidth, dataKey } = props;
          const cellStyle = {
            ...style,
            flexGrow,
            width: cellWidth,
          };

          return (
            <div key={`table-cell-${index}`} className={classnames('table-cell', className)} style={cellStyle}>
              {footerRenderer({
                dataKey,
                footerData: summary,
                cellData: summary[dataKey],
              })}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      className,
      onRowClick,
      children,
      showFooter,
      dataLoading,
      loadingBarContainerClassName,
      rowClassName,
      scrollToIndex,
      sortBy,
      sortDirection,
      sort,
      list,
      summary,
      style,
      rowRenderer,
      rowHeight,
    } = this.props;

    const rowGetter = ({ index }) => list[index];
    const rowClassFn = ({ index }) => {
      const rowClass = _.isFunction(rowClassName)
        ? rowClassName({ index, rowData: rowGetter({ index }) })
        : rowClassName;
      return classnames('table-row', rowClass);
    };
    const sorter = ({ sortDirection: newSortDirection, sortBy: newSortBy }) =>
      sort({ sortDirection: newSortDirection.toLowerCase(), sortBy: newSortBy });

    return (
      <div className={classnames('table-class', className)} style={style}>
        {/* Align the LoadingBarInfinite with the bottom border of the header cells */}
        <LoadingBarInfinite
          loading={dataLoading}
          containerClassName={loadingBarContainerClassName}
          containerStyle={{ marginTop: 29 }}
        />

        <AutoSizer style={{ flex: '1 1 auto' }}>
          {({ width, height }) => (
            <RvTable
              ref={(el) => {
                this.RvRef = el;
              }}
              headerClassName="header-cell"
              headerHeight={HEADER_HEIGHT}
              height={height - (showFooter ? FOOTER_HEIGHT : 0)}
              onRowClick={onRowClick}
              rowClassName={rowClassFn}
              rowCount={list.length}
              rowGetter={rowGetter}
              rowHeight={rowHeight || ROW_HEIGHT}
              scrollToIndex={scrollToIndex}
              sort={sorter}
              sortBy={sortBy}
              sortDirection={sortDirection === 'asc' ? SortDirection.ASC : SortDirection.DESC}
              width={width}
              rowRenderer={rowRenderer}
            >
              {/* doing the code like this sucks but allows us to still use components rather than functions */}
              {/* which im not necessarily against - TH */}
              {React.Children.map(children, (child) => {
                if (!child) {
                  return undefined;
                }
                const { props } = child;
                const classNameColumn = classnames('table-cell', props.classNameColumn, props.className);
                const headerClassName = classnames(props.headerClassName, props.className);
                const headerRenderer = props.headerRenderer || headerCellRenderer;
                const widthColumn = props.width || 0;
                const flexGrow = props.flexGrow || 0;

                return (
                  <RvColumn
                    {...props}
                    className={classNameColumn}
                    headerClassName={headerClassName}
                    headerRenderer={headerRenderer}
                    width={widthColumn}
                    flexGrow={flexGrow}
                  />
                );
              })}
            </RvTable>
          )}
        </AutoSizer>
        {showFooter && this.renderFooter({ height: FOOTER_HEIGHT, summary })}
      </div>
    );
  }
}

Table.displayName = 'Table';

Table.propTypes = {
  list: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classNameColumn: PropTypes.string,
  onRowClick: PropTypes.func,
  scrollToIndex: PropTypes.number,
  showFooter: PropTypes.bool,
  dataLoading: PropTypes.bool,
  loadingBarContainerClassName: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sort: PropTypes.func,
  summary: PropTypes.object,
  style: PropTypes.object,
  rowRenderer: PropTypes.func,
  rowHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  footerRenderer: PropTypes.func,
  resetScrollPositionOnDataChange: PropTypes.bool,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Table.defaultProps = {
  className: '',
  classNameColumn: undefined,
  onRowClick: undefined,
  rowHeight: undefined,
  scrollToIndex: undefined,
  showFooter: false,
  dataLoading: false,
  loadingBarContainerClassName: undefined,
  rowRenderer: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  sort: () => {},
  summary: undefined,
  style: undefined,
  footerRenderer: undefined,
  resetScrollPositionOnDataChange: false,
  rowClassName: '',
};

export default Table;
