'use strict';

const {
  defaultStaffTargetFrequency,
  staffTargetFrequency,
  staffTargetFrequencyLabels,
  staffTargetFrequencyOptions,
} = require('./staff-target-frequency');
const { staffTargetType, staffTargetTypeLabels, staffTargetTypeOptions } = require('./staff-target-type');
const {
  staffTargetAssignmentType,
  staffTargetAssignmentTypeLabels,
  staffTargetAssignmentTypeOptions,
} = require('./staff-target-assignment-type');

module.exports = {
  defaultStaffTargetFrequency,
  staffTargetFrequency,
  staffTargetFrequencyLabels,
  staffTargetFrequencyOptions,
  staffTargetType,
  staffTargetTypeLabels,
  staffTargetTypeOptions,
  staffTargetAssignmentType,
  staffTargetAssignmentTypeLabels,
  staffTargetAssignmentTypeOptions,
};
