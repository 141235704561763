angular.module('sb.billing.webapp').component('sbDataInvoiceStatusesWithCounts', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: { invoiceSummaries: '<', attorneyIds : '<', composeKey: '@' },
  controller: function ($scope, sbLoggerService, sbInvoicingService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDataInvoiceStatusesWithCounts');
    that.composeKey = that.composeKey || 'invoiceStatuses';

    that.$onChanges = () => {
      log.info('on change - update data');
      updateData();
    };

    $scope.$on('smokeball-data-update-sbMattersMbService', updateData);

    function updateData() {
      const invoicesByAttorneys = sbInvoicingService.filterInvoicesByAttorneys(that.invoiceSummaries, that.attorneyIds);
      const counts = _.chain(invoicesByAttorneys).pluck('currentVersion.status').countBy().value();
      const statuses = angular.copy(sbInvoicingService.getInvoiceStatuses());

      // 'Overdue' is a pseudo state which is manually added to the statuses.
      // We'd like it to be presented after the 'unpaid', aka FINAL state if possible.
      const indexOfUnpaid = _.findIndex(statuses, {'value': 'FINAL'});
      const indexForOverdue = (indexOfUnpaid > -1) ? indexOfUnpaid + 1 : statuses.length;
      statuses.splice(indexForOverdue, 0, {display: 'Overdue', value: 'OVERDUE'});

      log.info('counts : ', counts);
      log.info('statuses : ', statuses);

      _.each(statuses, (status) => {
        if (status.value === 'OVERDUE') { // Special case for 'overdue' pseudo status.
          status.badge = sbInvoicingService.filterOverdueSummaries(invoicesByAttorneys).length;
        }
        else {
          status.badge = counts[status.value] || 0;
        }
      });

      log.info('setting options data : ', statuses);
      log.info('on key : ', that.composeKey);
      that.sbComposeCtrl.setComposeData(statuses, that.composeKey);
    }
  }
});
