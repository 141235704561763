'use strict';

const getTransactionsByCheque = ({ cheque, excludeReversals = false, getTransactionById }) => {
  const isOldStyleCheque = !cheque.payments;

  const transactions = isOldStyleCheque
    ? [getTransactionById(cheque.transactionId)]
    : cheque.payments.map(({ transactionId }) => getTransactionById(transactionId));

  if (excludeReversals) {
    return transactions;
  }

  const allTransactions = transactions.reduce((acc, { reversedToTransactionId }) => {
    if (reversedToTransactionId) {
      acc.push(getTransactionById(reversedToTransactionId));
    }
    return acc;
  }, transactions);

  return allTransactions;
};

module.exports = {
  getTransactionsByCheque,
};
