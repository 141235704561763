import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';
import { Typeahead, forms2PropTypes, FormLabel } from '@sb-itops/react';

import Styles from '../../FeeWiseApplicationForm.module.scss';

export const GenericAddressFields = ({
  addressFields,
  parentPath,
  stateOptions,
  isErrorClassnames,
  formDisabled,
  onFieldUpdated,
  t,
}) => (
  <>
    <div className="row">
      <div className={classnames('form-group', 'col-sm-6')}>
        <FormLabel uppercase={false} label="Street Address Line 1 (PO Boxes not allowed)" />
        <input
          type="text"
          value={addressFields?.streetAddressLine1?.value}
          className={classnames('form-control', isErrorClassnames(addressFields?.streetAddressLine1))}
          onChange={(e) => onFieldUpdated({ [`${parentPath}.streetAddressLine1`]: e.target.value })}
          disabled={formDisabled}
        />
        {!!isErrorClassnames(addressFields?.streetAddressLine1) &&
          addressFields?.streetAddressLine1?.invalidReason.includes('PO Box') && (
            <span className={Styles.errorMsg}>{addressFields?.streetAddressLine1?.invalidReason}</span>
          )}
      </div>
      <div className={classnames('form-group', 'col-sm-6')}>
        <FormLabel uppercase={false} label="Street Address Line 2" optional />
        <input
          type="text"
          value={addressFields?.streetAddressLine2?.value}
          className={classnames('form-control', isErrorClassnames(addressFields?.streetAddressLine2))}
          onChange={(e) => onFieldUpdated({ [`${parentPath}.streetAddressLine2`]: e.target.value })}
          disabled={formDisabled}
        />
        {!!isErrorClassnames(addressFields?.streetAddressLine2) &&
          addressFields?.streetAddressLine2?.invalidReason.includes('PO Box') && (
            <span className={Styles.errorMsg}>{addressFields?.streetAddressLine2?.invalidReason}</span>
          )}
      </div>
    </div>
    <div className="row">
      <div className={classnames('form-group', 'col-sm-6')}>
        <FormLabel uppercase={false} label={t('capitalizeAllWords', { val: 'suburb' })} />
        <input
          type="text"
          value={addressFields?.city?.value}
          className={classnames('form-control', isErrorClassnames(addressFields?.city))}
          onChange={(e) => onFieldUpdated({ [`${parentPath}.city`]: e.target.value })}
          disabled={formDisabled}
        />
      </div>
      <div className={classnames('form-group', 'col-sm-3')}>
        <FormLabel uppercase={false} label="State" />
        <Typeahead
          selectedOption={stateOptions.find((option) => option.value === addressFields.state?.value)}
          options={stateOptions}
          disabled={formDisabled}
          placeholder="Select a state..."
          onSelect={(option) => {
            onFieldUpdated({
              [`${parentPath}.state`]: option?.value || undefined,
            });
          }}
          hasError={!!isErrorClassnames(addressFields?.state)}
        />
      </div>
      <div className={classnames('form-group', 'col-sm-3')}>
        <FormLabel uppercase={false} label={t('capitalizeAllWords', { val: 'postcode' })} />
        <input
          type="text"
          value={addressFields?.zipCode?.value}
          className={classnames('form-control', isErrorClassnames(addressFields?.zipCode))}
          onChange={(e) => onFieldUpdated({ [`${parentPath}.zipCode`]: e.target.value })}
          disabled={formDisabled}
        />
        {!!isErrorClassnames(addressFields?.zipCode) &&
          addressFields?.zipCode?.invalidReason.includes('Must be exactly') && (
            <span className={Styles.errorMsg}>{addressFields?.zipCode?.invalidReason}</span>
          )}
      </div>
    </div>
  </>
);

GenericAddressFields.displayName = 'GenericAddressFields';

const { Forms2Field } = forms2PropTypes;
GenericAddressFields.propTypes = {
  addressFields: PropTypes.shape({
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
  }),
  stateOptions: PropTypes.array.isRequired,
  parentPath: PropTypes.string.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
  isErrorClassnames: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

GenericAddressFields.defaultProps = {
  formDisabled: false,
  addressFields: {},
};
