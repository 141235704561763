'use strict';

const { regionType, getRegion } = require('@sb-itops/region');

const defaultClientCoversFeeStrategy = ({ isDeposit, providerSettings }) =>
  isDeposit ? providerSettings?.clientCoversFeeOnDeposits : providerSettings?.clientCoversFeeOnPayments;

const strategy = {
  [regionType.AU]: ({ isDeposit, providerSettings, hasRetainerRequest }) => {
    if (isDeposit) {
      return providerSettings?.clientCoversFeeOnDeposits;
    }
    // AU must charge the client a fee for deposits with retainer requests or the trust balance will be wrong
    return hasRetainerRequest || providerSettings?.clientCoversFeeOnPayments;
  },
  [regionType.US]: defaultClientCoversFeeStrategy,
  [regionType.GB]: defaultClientCoversFeeStrategy,
};

/**
 * @param {object} param
 * @param {boolean} param.hasRetainerRequest
 * @param {boolean} param.isDeposit
 * @param {object} param.providerSettings
 * @returns {boolean}
 */
const clientCoversFeeStrategy = ({ isDeposit, providerSettings, hasRetainerRequest }) =>
  !!strategy[getRegion()]({
    isDeposit,
    providerSettings,
    hasRetainerRequest,
  });

module.exports = {
  clientCoversFeeStrategy,
};
