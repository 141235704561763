'use strict';

const {
  billingFrequency,
  billingFrequencyLabels,
  billingFrequencyOptions,
  billingFrequencyTree,
} = require('./billing-frequency');
const {
  billingFrequencySubType,
  billingFrequencySubTypeLabels,
  billingFrequencySubTypeOptions,
} = require('./billing-frequency-sub-type');
const { billingType } = require('./billing-type');
const { billingUnitOptionLabels, billingUnitOptionValues, billingUnitOptions } = require('./billing-unit-options');

const { getBillableMinutesForSaving } = require('./get-billable-minutes-for-saving');
const { getBillingFrequencySubTypeFieldLabel } = require('./get-billing-frequency-sub-type-field-label');
const { getBillingFrequencySubTypeOptions } = require('./get-billing-frequency-sub-type-options');
const { getMatterBillableMinutes } = require('./get-matter-billable-minutes');

const { validateBillingFrequency } = require('./validate-billing-frequency');

/**
 * @param {BillingConfiguration} billingConfig
 * @returns
 */
const getDebtorIds = (billingConfig) => {
  if (!billingConfig || !billingConfig.currentWorkItem) {
    return [];
  }

  const { currentWorkItem } = billingConfig;
  const defaultDebtorId = currentWorkItem.debtorEntityId;

  if (currentWorkItem.debtorIds && currentWorkItem.debtorIds.length) {
    return currentWorkItem.debtorIds;
  }

  if (defaultDebtorId) {
    return [defaultDebtorId];
  }

  return [];
};

module.exports = {
  billingFrequency,
  billingFrequencyLabels,
  billingFrequencyOptions,
  billingFrequencyTree,
  billingFrequencySubType,
  billingFrequencySubTypeLabels,
  billingFrequencySubTypeOptions,
  billingType,
  billingUnitOptionLabels,
  billingUnitOptionValues,
  billingUnitOptions,
  getDebtorIds,
  getBillableMinutesForSaving,
  getBillingFrequencySubTypeFieldLabel,
  getBillingFrequencySubTypeOptions,
  getMatterBillableMinutes,
  validateBillingFrequency,
};
