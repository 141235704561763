'use strict';

const { chargeErrorNames, preChargeErrorNames } = require('../entities/custom-errors');

// With change to node 20 and changing the way how we package dependencies for endpoints, we can't use conditions
// such as "(error instanceof ChargeError)" anymore as error thrown in module and caught in EP
// may be different instance.

// These functions are not bullet proof solution and would be probably better to solve in different way longterm.

// Can be used instead of (error instanceof ChargeError)
function isErrorSmokeballChargeError(err) {
  if (!err) {
    return false;
  }

  const validErrorNames = [
    // Errors which extends from ChargeError in billing/business-logic/payment-provider/entities/custom-errors.js
    chargeErrorNames.ProviderApiChargeError,
    chargeErrorNames.CardAndAddressCountryMismatch,
    chargeErrorNames.InternationalCardsNotSupported,
    chargeErrorNames.ZeroBalanceOwingChargeError,
    chargeErrorNames.ServerError,
  ];

  const isChargeError = err.name === chargeErrorNames.ChargeError;

  const isExtendedChargeError =
    validErrorNames.includes(err.name) && Object.getPrototypeOf(err.constructor).name === chargeErrorNames.ChargeError;

  return (isChargeError || isExtendedChargeError) && 'isSmokeballError' in err;
}

// Can be used instead of (error instanceof PreChargeError)
function isErrorSmokeballPreChargeError(err) {
  if (!err) {
    return false;
  }

  const isPreChargeError = err.name === preChargeErrorNames.PreChargeError;

  return isPreChargeError && 'isSmokeballError' in err;
}

module.exports = {
  isErrorSmokeballChargeError,
  isErrorSmokeballPreChargeError,
};
