/**
 * @typedef {Object} deferredPromise
 * @property {function} defer - The initialisation function to be called when a new promise is to be deferred
 */

/**
 * Creates a deferred promise that is saved to a React ref that is passed in
 * @param {Ref} deferRef
 * @returns {deferredPromise}
 */
export function useDeferredPromise(deferRef) {
  const defer = () => {
    const deferred = {};
    const promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    deferred.promise = promise;
    // eslint-disable-next-line no-param-reassign
    deferRef.current = deferred;
    return deferRef.current;
  };

  return { defer };
}
