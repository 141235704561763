/* eslint-disable jsx-a11y/anchor-is-valid */
import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import classNames from 'classnames';
import React from 'react';
import { Button, buttonTypes } from '@sb-itops/react';
import { CONVERT_LEAD_MODAL_ID } from '../convert-lead-modal';
import * as Styles from './MatterActions.module.scss';

export const RESTORE_MATTER_MODAL_ID = 'confirm-restore-matter';

export const MatterActions = ({ isDeleted, isEditable, matterId, isLead, onClickLink, isSaving }) => (
  <div className={Styles.container}>
    {featureActive('BB-7911') && isDeleted && (
      <div className={Styles.matterRestore}>
        <Button
          type={buttonTypes.secondary}
          className={Styles.specialButton}
          disabled={isSaving}
          onClick={() => setModalDialogVisible({ modalId: RESTORE_MATTER_MODAL_ID, props: { matterId } })}
        >
          {isSaving ? (
            'Processing changes...'
          ) : (
            <>
              {isLead ? 'RESTORE LEAD' : 'RESTORE MATTER'}
              <div className={classNames('icon icon-undo', Styles.icon)} />
            </>
          )}
        </Button>
      </div>
    )}
    {isLead && featureActive('NUCWEB-133') && (
      <div className={Styles.matterEdit}>
        <Button onClick={() => setModalDialogVisible({ modalId: CONVERT_LEAD_MODAL_ID, props: { matterId } })}>
          CONVERT LEAD TO MATTER
          <div data-icon="&#xe12f;" className={classNames('icon', Styles.icon)} />
        </Button>
      </div>
    )}
    {isEditable && (
      <div className={Styles.matterEdit}>
        <Button
          type={buttonTypes.secondary}
          className={Styles.specialButton}
          disabled={isSaving}
          onClick={() => {
            onClickLink({ type: 'editMatter', id: matterId });
          }}
        >
          {isSaving ? (
            'Processing changes...'
          ) : (
            <>
              {isLead ? 'EDIT LEAD' : 'EDIT MATTER'}
              <div data-icon="&#xe03b;" className={classNames('icon', Styles.icon)} />
            </>
          )}
        </Button>
      </div>
    )}
  </div>
);
