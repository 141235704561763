import { featureActive, enableFeature, disableFeature } from '@sb-itops/feature';
import { getAppEnv, envType } from '@sb-itops/app-env';

angular.module('sb.billing.webapp').component('sbFeatureSwitches', {
  require: {
    sbComposeCtrl: '^sbCompose'
  },
  bindings: { 
    provider: '<',
    refreshSwitches: '<',
  },
  templateUrl: 'ng-composable-components/data-ui/custom/feature-switches/dui-feature-switches.html',
  controllerAs: 'ctrl',
  controller: function () {
    const that = this;

    that.listConfig = {
      stateKey: 'stats',
      type: 'static',
      onClick: (item) => {
        if (getAppEnv() !== envType.PRODUCTION) {
          if (featureActive(item.code)) {
            disableFeature(item.code, true);
          } else {
            enableFeature(item.code, true);
          }
          that.refreshSwitches && that.refreshSwitches();
        }
      },
      columns: [
        { label: 'Code', sort: 'code', property: 'code', defaultSort: true },
        { label: 'In-Code Level', sort: 'inCodeLevel', property: 'inCodeLevel' },
        { label: 'LaunchDarkly Level', sort: 'launchDarklyLevel', property: 'launchDarklyLevel' },
        { label: 'Final Level', sort: 'finalLevel', property: 'finalLevel' },
        { label: 'Description', property: 'description' }
      ]
    };
  }
});
