import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { sort as sortItems } from '@sb-itops/sort';

import {
  filterUtbmsTaskCodes,
  mapActivitiesByCategory,
  mapTasksByCategory,
} from '@sb-billing/business-logic/activities/services';
import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import {
  firmFeeConfigurationDefaults,
  entryTypeLabels,
  entryType as entryTypesEnum,
} from '@sb-billing/business-logic/shared/entities';
import * as services from '@sb-matter-management/business-logic/matters/services';
import { feeCanBeMarkedAsBillable, feeCanBeMarkedAsNonBillable } from '@sb-billing/business-logic/fee/services';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

import {
  BillingBulkActions,
  FeeTableData,
  InitActivityCodes,
  InitFirmFeeConfiguration,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitStaffSettings,
  MatterDetails,
} from 'web/graphql/queries';
import { useCacheQuery, usePagination, usePrintFeesPdf, useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { hasBillingAccess } from 'web/services/user-session-management';
import { FEE_MODAL_ID } from 'web/components';

import { MatterFeeEntries } from './MatterFeeEntries';

const FETCH_LIMIT = 50;
const SCOPE = 'MatterFeeEntriesRoute';

const hooks = ({ onClickLink, matterId }) => ({
  useScope: () => ({
    scope: SCOPE,
  }),
  usePermissions: () => ({
    hasBillingAccess: hasBillingAccess(),
  }),
  useFilters: () => {
    const [selectedStaffMember, setSelectedStaffMember] = useState(null);

    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const { data: firmFeeConfigData } = useCacheQuery(InitFirmFeeConfiguration.query);
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    if (!selectedStaffMember && (staffData?.loggedInStaff?.id || staffData?.staffMembers[0])) {
      setSelectedStaffMember(staffData?.loggedInStaff || staffData?.staffMembers[0]);
    }

    const userPrefersDurationsAsUnits = staffData?.loggedInStaff?.feeConfiguration?.enterTimeAsUnits;
    const billingIncrementsMins =
      firmFeeConfigData?.firmFeeConfiguration?.billableMinutes || firmFeeConfigurationDefaults.BILLABLE_MINUTES;

    const { taxRate: taxRateBasisPoints, registeredForGst } = firmTaxSettingsData?.firmTaxSettings || {};

    const supportsUtbms = hasFacet(facets.utbms);
    const utbmsEnabledForFirm = (supportsUtbms && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled) || false;
    const utbmsCodesRequiredByFirm =
      utbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;

    const utbmsCodeSetsUsedByFirm = firmUtbmsSettingsData?.firmUtbmsSettings?.selectedCodeSets;

    const staffMemberOptions = useMemo(() => {
      const options = !staffData?.staffMembers?.length
        ? []
        : staffData.staffMembers.map((staffMemberEntity) => ({
            value: staffMemberEntity.id,
            label: `${staffMemberEntity.initials} (${staffMemberEntity.name})`,
            entity: {
              ...staffMemberEntity,
              rate: staffMemberEntity.rate || 0,
            },
          }));

      const sortedOptions = sortItems(options, ['label'], ['ASC']);

      return sortedOptions;
    }, [staffData?.staffMembers]);

    return {
      staffMemberOptions,
      selectedStaffMember,
      onStaffMemberChange: (newStaffMember) => {
        setSelectedStaffMember(newStaffMember);
      },
      userPrefersDurationsAsUnits,
      billingIncrementsMins,
      registeredForGst,
      taxRateBasisPoints,
      utbmsEnabledForFirm,
      utbmsCodeSetsUsedByFirm,
      utbmsCodesRequiredByFirm,
      showTaskColumn: utbmsEnabledForFirm,
      showTasksField: utbmsEnabledForFirm,
    };
  },
  useActivityCodes: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    const activities = useMemo(
      () =>
        mapActivitiesByCategory({
          activityCodes: activityCodesResult?.data?.activityCodes,
          utbmsActivityCodes: activityCodesResult?.data?.utbmsActivityCodes,
          filterCustomCodeByTypes: [entryTypesEnum.FIXED, entryTypesEnum.TIME],
        }),
      [activityCodesResult?.data?.activityCodes, activityCodesResult?.data?.utbmsActivityCodes],
    );

    const tasks = useMemo(
      () =>
        mapTasksByCategory({
          utbmsTaskCodes: activityCodesResult?.data?.utbmsTaskCodes,
          utbmsCustomTaskCodes: activityCodesResult?.data?.utbmsCustomTaskCodes,
          filterCustomCodeByTypes: [entryTypeLabels.FIXED, entryTypeLabels.TIME],
        }),
      [activityCodesResult?.data?.utbmsTaskCodes, activityCodesResult?.data?.utbmsCustomTaskCodes],
    );

    return {
      activities,
      activityDataLoading: activities.length === 0 && activityCodesResult.loading,
      tasks,
    };
  },
  useModalAndPopoutSelection: () => {
    const [currentFee, setCurrentFee] = useState();
    const [showFeeSidePanel, setShowFeeSidePanel] = useState(false);

    const onRowClick = (fee) => {
      // Clicking a fee row will either:
      //  1. Open the fee modal
      //  2. Changes the fee in the side panel (if open)
      if (!showFeeSidePanel) {
        setModalDialogVisible({
          modalId: FEE_MODAL_ID,
          props: {
            scope: `${SCOPE}/fee-modal`,
            feeId: fee.id,
            matterId,
            onNavigateToInvoice: () => onClickLink({ type: 'invoice', id: fee.invoice?.id }),
          },
        });
      }

      setCurrentFee(fee);
    };

    const onNewEntry = () => {
      setModalDialogVisible({
        modalId: FEE_MODAL_ID,
        props: {
          scope: `${SCOPE}/fee-modal`,
          matterId,
        },
      });
    };

    return {
      currentFee,
      showFeeSidePanel,
      onNewEntry,
      onSetShowFeeSidePanel: setShowFeeSidePanel,
      onSetCurrentFee: setCurrentFee,
      onRowClick,
    };
  },
  useBulkActions: () => {
    const scopeTable = `${SCOPE}/matter-fee-table/${matterId}`;
    const scopeTableBillable = `${scopeTable}/billable`;
    const scopeTableNonBillable = `${scopeTable}/non-billable`;

    /**
     * We need to use 3 multiple Item selection because with load on Demand we can't refer to the Redux Entities anymore
     * So, the only moment we can test if a Fee can be modified and marked as billable or not is during the onRowSelect event
     */

    // Holds all the checked rows and controls the header checkbox's events
    const { selectedItems: selectedFeeIds, toggleItems } = useMultipleItemSelection({
      scope: scopeTable,
    });

    // Holds only billable checked fees. (Fees that can be modified and marked as non-billable)
    const { selectedItems: selectedBillableIds, toggleItems: toggleBillableItems } = useMultipleItemSelection({
      scope: scopeTableBillable,
    });

    // Holds only non-billable checked fees. (Fees that can be modified and marked as billable)
    const { selectedItems: selectedNonBillableFeeIds, toggleItems: toggleNonBillableItems } = useMultipleItemSelection({
      scope: scopeTableNonBillable,
    });

    // This is the moment mentioned above where we have the Fee entity and can test if it can be modified
    const onRowSelect = (fees) => {
      const [selected, billable, nonBillable] = fees.reduce(
        ([accSelected, accBillable, accNonBillable], fee) => {
          if (feeCanBeMarkedAsNonBillable(fee)) {
            accBillable.push(fee.id);
          }

          if (feeCanBeMarkedAsBillable(fee)) {
            accNonBillable.push(fee.id);
          }

          accSelected.push(fee.id);

          return [accSelected, accBillable, accNonBillable];
        },
        [[], [], []],
      );

      toggleItems(selected);
      toggleBillableItems(billable);
      toggleNonBillableItems(nonBillable);
    };

    const billableIds = Object.keys(selectedBillableIds);
    const billableIdsCount = billableIds.length;
    const nonBillableIds = Object.keys(selectedNonBillableFeeIds);
    const nonBillableIdsCount = nonBillableIds.length;

    const markFeeAs = async (feeIds, isBillable) => {
      await dispatchCommand({ type: 'Integration.FeesBulkUpdateBillable', message: { feeIds, isBillable } });
      toggleBillableItems(feeIds);
      toggleNonBillableItems(feeIds);
    };

    const bulkActions = {
      'mark-as-billable': {
        label: `Mark as Billable (${nonBillableIdsCount})`,
        confirmMessage: `This will mark ${nonBillableIdsCount} items as billable.`,
        onConfirm: () => markFeeAs(nonBillableIds, true),
        disabled: !nonBillableIdsCount,
      },
      'mark-as-non-billable': {
        label: `Mark as Non-Billable (${billableIdsCount})`,
        confirmMessage: `This will mark ${billableIdsCount} items as non-billable.`,
        onConfirm: () => markFeeAs(billableIds, false),
        disabled: !billableIdsCount,
      },
    };

    const bulkActionsDisabled = !nonBillableIdsCount && !billableIdsCount;

    return { onRowSelect, bulkActions, scopeTable, bulkActionsDisabled, selectedFeeIds };
  },
  useColumnDisplay: () => ({
    showFeeDateColumn: true,
    showStaffInitialsColumn: true,
    showMatterColumn: false,
    showTaxColumns: hasFacet(facets.tax),
    showMatterField: false,
    showDateField: true,
    showStaffField: true,
    includeBillingConfiguration: true,
    includeMatterHourlyRate: true,
  }),
  useFeesSort: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: `${SCOPE}/matter-fee-table/${matterId}`,
      initialSortBy: 'feeDate',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
});

const queryHooks = ({
  matterId,
  includeBillingConfiguration,
  includeMatterHourlyRate,
  sortBy,
  sortDirection,
  tasks,
  utbmsCodeSetsUsedByFirm,
  utbmsEnabledForFirm,
  sbAsyncOperationsService,
}) => ({
  useBillingBulkActionsData: () => {
    const { data } = useSubscribedQuery(BillingBulkActions, {
      variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES, bulkEntityIds: [matterId] },
    });

    const isBulkCreateInvoicesInProgress = data?.billingBulkActionList?.totalCount > 0;
    return {
      isBulkCreateInvoicesInProgress,
    };
  },
  useFirmUtbmsTasks: () => {
    // If we haven't yet received the task codes used by the firm, we aren't able to filter from the full list.
    // In this case we won't show any utbms tasks.
    if (!utbmsEnabledForFirm || !utbmsCodeSetsUsedByFirm) {
      return {
        tasks: [],
      };
    }

    const filteredTasks = filterUtbmsTaskCodes({
      utbmsTaskCodes: tasks, // both standard and custom task codes
      utbmsCodeSetsUsedByFirm,
    });

    return {
      tasks: filteredTasks,
    };
  },
  useMatterData: () => {
    const matterQueryResult = useSubscribedQuery(
      MatterDetails,
      {
        variables: {
          matterId,
          includeBillingConfiguration,
          includeMatterHourlyRate,
        },
      },
      {
        notificationIds: [
          ...MatterDetails.notificationIds,
          includeBillingConfiguration && 'BillingMattersNotifications.BillingConfigurationUpdated',
          includeMatterHourlyRate && 'FeesNotifications.MatterHourlyRateUpdated',
          includeMatterHourlyRate && 'FeesNotifications.StaffFeeConfigurationUpdated',
          includeMatterHourlyRate && 'FeesNotifications.StaffMemberHourlyRateUpdated',
        ].filter(Boolean),
      },
    );

    if (matterQueryResult.error) {
      throw new Error(matterQueryResult.error);
    }

    const { data: matterData } = matterQueryResult;
    const isEditableMatter = services.isEditableMatter(matterData?.matter);
    const matter = matterData?.matter;

    return { isEditableMatter, matter };
  },
  useFeeListData: () => {
    const {
      currentPage: currentFeePage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${SCOPE}/pagination/${matterId}`,
      fetchLimit: FETCH_LIMIT,
    });

    const onFeeListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const feesQueryResult = useSubscribedQuery(FeeTableData, {
      variables: {
        feeFilter: {
          matterIds: [matterId],
          includeStatus: {
            current: true,
          },
        },
        offset: currentFeePage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (feesQueryResult.error) {
      throw new Error(feesQueryResult.error);
    }

    const { data: feeData } = feesQueryResult;
    const feeList = feeData?.feeList?.results || [];

    const {
      totalCount: feeCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfFeePages,
    } = getPagination({ totalCount: feeData?.feeList?.totalCount, loading: feesQueryResult.loading });

    return {
      matterId,
      currentFeePage,
      fees: feeList,
      feeCount,
      feeDataLoading: feeList.length === 0 && feesQueryResult.loading,
      totalNumberOfFeePages,
      hidePagination,
      onFeeListPageChange,
    };
  },
  useExportReport: () => {
    const { printFeesPdf, isLoadingFeesPdf } = usePrintFeesPdf();
    const sort = !sortBy
      ? undefined
      : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] };

    const onGeneratePDFReport = async () => {
      await printFeesPdf({ feeFilters: { matterId, sort, showTaskColumn: utbmsEnabledForFirm } });
    };

    const onGenerateCSVReport = async () => {
      try {
        sbAsyncOperationsService.startExportFeeCsv({ matterId, sort, showTaskColumn: utbmsEnabledForFirm });
      } catch (error) {
        throw new Error('Failed to export fee. Please try again later.');
      }
    };

    return {
      isGeneratingPDFReport: isLoadingFeesPdf,
      onGeneratePDFReport,
      onGenerateCSVReport,
    };
  },
});

export const MatterFeeEntriesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(MatterFeeEntries))),
);

MatterFeeEntriesContainer.displayName = 'MatterFeeEntriesContainer';

MatterFeeEntriesContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

MatterFeeEntriesContainer.defaultProps = {};
