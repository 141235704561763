import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import uuid from '@sb-itops/uuid';
import { store } from '@sb-itops/redux';
import { api } from './cache';
import { getDefaultOperatingChequePrintSettings } from './default-settings';
import { getOperatingAccount } from '../bank-account';

const { getById: getOperatingChequePrintSettingsById } = api;

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbOperatingChequePrintSettingsService',
  keyPath: 'bankAccountId',
});

export const saveOperatingChequePrintSettings = (operatingChequeSettings) => {
  const bankAccountId = operatingChequeSettings.bankAccountId || getOperatingAccount().id;
  const currentSettings =
    getOperatingChequePrintSettingsById(bankAccountId) || getDefaultOperatingChequePrintSettings();
  const newSettings = { ...currentSettings, ...operatingChequeSettings, bankAccountId };

  const saveOperatingChequePrintSettingsThunk = async () => {
    opdateCache({ optimisticEntities: [newSettings] });

    try {
      const path = `/billing/operating-cheque-print-settings/:accountId/`;
      const payload = { versionId: uuid(), operatingChequePrintSettings: newSettings };
      const fetchOptions = { body: JSON.stringify(payload) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [newSettings] });
      throw err;
    }
  };

  return store.dispatch(saveOperatingChequePrintSettingsThunk);
};
