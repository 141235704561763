'use strict';

/**
 * Determines if bank account details are empty in AU/UK
 * @param {Object} param
 * @param {Object} param.bankAccount Bank Account entity
 * @returns {boolean}
 */
function isBankAccountDetailsEmpty({ bankAccount }) {
  if (!bankAccount) {
    return true;
  }

  return (
    bankAccount.displayName === null &&
    bankAccount.bankName === null &&
    bankAccount.branchNumber === null &&
    bankAccount.accountNumber === null
  );
}

module.exports = {
  isBankAccountDetailsEmpty,
};
