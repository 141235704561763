'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const rateOverrideTypes = Object.freeze({
  NO_OVERRIDE: 0,
  ALL_STAFF_OVERRIDE: 1,
  CUSTOM_STAFF_OVERRIDE: 2,
  RATE_SET_OVERRIDE: 3,
});

const rateOverrideTypeLabels = Object.freeze({
  NO_OVERRIDE: 'Do not override rate',
  ALL_STAFF_OVERRIDE: 'Override rate for all staff',
  CUSTOM_STAFF_OVERRIDE: 'Override default rate per staff member',
  RATE_SET_OVERRIDE: 'Use rate sets',
});

const rateOverrideTypeOptions = getTypeAsOptions({
  typeLabels: rateOverrideTypeLabels,
  typeValues: rateOverrideTypes,
});

module.exports = {
  rateOverrideTypeLabels,
  rateOverrideTypeOptions,
  rateOverrideTypes,
};
