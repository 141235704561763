angular.module('sb.billing.webapp').component('sbDataMatterBillingConfig', {
  require:  { compose: '^sbCompose' },
  bindings : { composeDataKey: '=?' },
  controller: function ($scope, sbLoggerService, sbDefaultMatterBillingConfigurationService) {
    'use strict';

    const ctrl = this;
    // const log = sbLoggerService.getLogger('sbDataMatterBillingConfig');
    let initialized = false

    const listeners = [
      $scope.$on('$destroy', () => {
        for (let unregister of listeners) unregister();
      }),
      $scope.$on('smokeball-data-update-sbDefaultMatterBillingConfigurationService', update),
    ];

    ctrl.$onInit = () => {
      update();
      initialized = true;
    };

    ctrl.$onChanges = () => {
      if (initialized) {
        update();
      }
    };

    function update() {
      const config = sbDefaultMatterBillingConfigurationService.getConfig();

      ctrl.compose.setComposeData(config, ctrl.composeDataKey || 'matterBillingConfig');
    }
  }
});
