/**
 * Reducer function to return a map of entities based on current state including new/updated entities from action.payload
 * @param state
 *    the current state
 * @param action
 *    action.payload
 *      array of entities or entity object, keyed on the property identified by action.meta
 *    action.meta
 *      string identifying the key path property of the entity
 * @returns map of entities keyed on the property identified by action.meta
 */
const validLastUpdated = (lastUpdated) =>
  lastUpdated !== undefined && lastUpdated !== 'undefined' && lastUpdated !== '';

const cacheReducer = (state, payload, keyPath, lastUpdated, isSyncAll) => {
  const data = Array.isArray(payload) ? payload : [payload];
  const updates = data.reduce((acc, curr) => {
    const key = curr[keyPath];
    acc[key] = curr;

    return acc;
  }, {});

  const next = {
    // SYNC_ALL caches always overwrite the previous state
    entities: isSyncAll ? { ...updates } : { ...state.entities, ...updates },
  };

  if (validLastUpdated(lastUpdated)) {
    next.lastUpdated = lastUpdated;
  }

  return next;
};

export default cacheReducer;
