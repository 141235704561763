'use strict';

const { hasFacet, facets } = require('@sb-itops/region-facets');
const auContactAddressChecker = require('./src/au-contact-address-checker');
const gbContactAddressChecker = require('./src/gb-contact-address-checker');

const createContactAddressChecker = (region) => {
  if (hasFacet(facets.matterClientAddressRequiredForTrustDeposit, { forcedRegion: region })) {
    const contactAddressCheckers = {
      AU: auContactAddressChecker,
      GB: gbContactAddressChecker,
    };
    if (!contactAddressCheckers[region]) {
      throw new Error(`Region '${region}' not supported by address checker yet.`);
    }
    return contactAddressCheckers[region];
  }

  throw new Error(`Region '${region}' does not have facet enabled for address checker.`);
};

module.exports = {
  createContactAddressChecker,
};
