import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import uuid from '@sb-itops/uuid';
import { buildOpdates, opdateCache, rollbackOpdateCache } from './opdates';

export const saveConfig = (config) => {
  const versionId = uuid();
  const path = `/billing/default-matter-billing-configuration/:accountId/${versionId}`;
  const opdateCaches = () => {
    const matterBillingConfigurationOpdates = [buildOpdates(config, versionId)];

    opdateCache({ optimisticEntities: matterBillingConfigurationOpdates });

    return () => {
      rollbackOpdateCache({ optimisticEntities: matterBillingConfigurationOpdates });
    };
  };

  return fetchPostThunkFactory({
    path,
    opdateCaches,
    responseType: 'text',
  })(config);
};
