'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  var BASE_DIRECTORY = 'ng-routes/preferences';

  $stateProvider
  .state('preferences', {
    url: '/preferences',
    templateUrl: BASE_DIRECTORY + '/preferences.html',
    controller: 'PreferencesController',
    controllerAs: 'preferences',
    data: {
      authorized: true
    },
  });
});
