import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';

import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { todayAsInteger } from '@sb-itops/date';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getDirectPaymentSources, PAYMENT_SOURCE, PAYMENT_TYPE } from 'web/redux/selectors/payment-source';
import { getLogger } from '@sb-itops/fe-logger';
import {
  getActiveProvider,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import {
  paymentMethodTypes,
  paymentMethodTypesByProvider,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import { paymentMethods as lawpayPaymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { getMinChargeAmountInCents } from '@sb-billing/business-logic/payment-provider/services';
import { useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import { InvoiceStatementPaymentAddForm } from './InvoiceStatementPaymentAddForm';
import { getValidateFn } from './validation';

const log = getLogger('invoiceStatementPaymentAddForm');
const region = getRegion();

const localisationConfig = {
  echeque: hasFacet(facets.echeque),
  paymentReason: hasFacet(facets.reasonField),
};

const hooks = (props) => ({
  useConfig: () => ({
    supportsEcheque: localisationConfig.echeque,
    showReason: localisationConfig.paymentReason,
  }),
  useSelectors: () => {
    const { t } = useTranslation();
    const {
      currentStep,
      debtorId,
      invoiceSummaries,
      scope,
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      onChargeFormDataChange,
      triggerChargeFormSubmit,
    } = props;
    const dispatch = useDispatch();

    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const { paymentType, contactId, effectiveDate, comment, amount, reference, reason, takePaymentNow } = formFields;
    // fields which are objects pass as regular object
    const { source, payments = [] } = useSelector(formSelectors.getFieldValues);
    const activeProviderType = getActiveProvider();

    // onLoad, form is not initialised
    useEffect(() => {
      const todayAsInt = todayAsInteger();
      const sourcesOnLoad = getPaymentSourcesByType(t, activeProviderType);

      log.info('initialised');
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            contactId: debtorId,
            effectiveDate: todayAsInt,
            comment: undefined,
            amount: 0,
            reference: undefined,
            reason: undefined,
            saveCardDetails: false,
            source: sourcesOnLoad.find((src) => src.isDefault),
            takePaymentNow: false,
            paymentType: 'CLIENT',
            payments: [],
          },
        }),
      );

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sources = getPaymentSourcesByType(t, activeProviderType);

    const [reasonOverridden, setReasonOverridden] = useState(false);
    const [takePaymentNowDisabled, setTakePaymentNowDisabled] = useState(true);

    const totalPayments = payments.reduce((runningTotal, invoicePayment) => runningTotal + invoicePayment.amount, 0);
    const minAmountAllowed = activeProviderType
      ? getMinChargeAmountInCents({ providerType: activeProviderType, region })
      : 0;

    const validate = () => {
      dispatch(formOperations.validateForm({ validateFn: getValidateFn({ t, minAmountAllowed }) }));
    };
    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      validate();
    };
    const onFieldValueSet = ({ field, value }) => {
      dispatch(formActions.setFieldValue({ field, value }));
      validate();
    };

    const updateDefaultReason = () => {
      if (reasonOverridden) {
        return;
      }
      onFieldValueUpdated({ reason: '' });
    };

    const onPaymentSourceChange = (newSource) => {
      const fieldsToUpdate = {};

      onFieldValueSet({ field: 'source', value: newSource });

      // if takePaymentNowFieldEnabled
      if (activeProviderType) {
        if (
          newSource?.paymentSource === PAYMENT_SOURCE.creditCard &&
          hasPaymentProviderConfiguredForSource({ source: newSource })
        ) {
          setTakePaymentNowDisabled(false);
        } else if (
          localisationConfig.echeque &&
          newSource?.paymentSource === PAYMENT_SOURCE.eCheck &&
          hasPaymentProviderConfiguredForSource({ source: newSource })
        ) {
          setTakePaymentNowDisabled(true);
          fieldsToUpdate.takePaymentNow = true;
          fieldsToUpdate.reference = '';
          fieldsToUpdate.effectiveDate = todayAsInteger();
        } else {
          setTakePaymentNowDisabled(true);
          fieldsToUpdate.takePaymentNow = false;
        }
      }

      onFieldValueUpdated(fieldsToUpdate);
      updateDefaultReason();
    };

    const readOnlyReference = isReferenceReadonly({ takePaymentNow, activeProviderType });

    const showPaymentPlanPaymentsWithInterestWarning = !!payments.find(
      (invoicePayment) => !!invoicePayment.paymentPlanId && invoicePayment.amount > invoicePayment.unpaidExcInterest,
    );

    return {
      currentStep,
      scope,
      readOnlyReference,
      contactOptions: getContactTypeAheadSummaries(),
      invoiceSummaries,
      onFieldValueUpdated,
      sources,
      showPaymentPlanPaymentsWithInterestWarning,
      showTakePaymentNowField: !!activeProviderType,
      takePaymentNowDisabled,
      effectiveDateDisabled: isEffectiveDateDisabled({ takePaymentNow, activeProviderType }),
      balance: (amount?.value || 0) - totalPayments,
      onSelectContact: (newContactId) => {
        onFieldValueUpdated({ contactId: newContactId });
      },
      onTakePaymentNowToggled: (name, val) => {
        onFieldValueUpdated({ [name]: val });
        if (val === true) {
          onFieldValueUpdated({ effectiveDate: todayAsInteger() });
          onFieldValueUpdated({ reference: '' });
        }
      },
      onPaymentsListChange: (invoicePayments) => {
        updateDefaultReason();
        dispatch(formActions.setFieldValue({ field: 'payments', value: invoicePayments }));
        // update amount so the field is dirty and validation highlights it
        onFieldValueUpdated({ amount: amount?.value });
        validate();
      },
      onReasonChange: (newReason) => {
        setReasonOverridden(true);
        onFieldValueUpdated({ reason: newReason });
      },
      onPaymentSourceChange,

      // form
      paymentType,
      contactId,
      effectiveDate,
      comment,
      amount,
      reference,
      reason,
      source,
      takePaymentNow,
      // form
      submitFailed,
      formDisabled: formSubmitting,
      formInitialised,
      getValidateFn,
      // Underlying charge forms props
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      onChargeFormDataChange,
      triggerChargeFormSubmit,
    };
  },
});

function isEffectiveDateDisabled({ takePaymentNow, activeProviderType }) {
  return !!activeProviderType && takePaymentNow?.value === true;
}

function isReferenceReadonly({ takePaymentNow, activeProviderType }) {
  const takePaymentNowSelected = !!activeProviderType && takePaymentNow?.value === true;

  return takePaymentNowSelected;
}

function hasPaymentProviderConfiguredForSource({ source }) {
  const operatingAccountId = getOperatingAccount().id;

  return isPaymentProviderEnabledForBankAccount({
    providerType: getActiveProvider(),
    bankAccountId: operatingAccountId,
    providerSpecificFields: {
      paymentMethod: getPaymentMethodFromSource(source), // used for LP only
    },
  });
}

function getPaymentMethodFromSource(source) {
  // This is used for Lawpay only, other payment providers just ignore paymentMethod
  switch (source.paymentSource) {
    case PAYMENT_SOURCE.creditCard:
      return lawpayPaymentMethods.CREDIT_CARD;
    case PAYMENT_SOURCE.eCheck:
      return lawpayPaymentMethods.ECHEQUE;
    default:
      return null;
  }
}

/**
 * get the payment sources list based if it is matter or contact payment type.
 * @param {function} t - Localisation function
 * @returns {Array.<object>} list of payment sources
 */
function getPaymentSourcesByType(t, activeProviderType) {
  const sources = getDirectPaymentSources(t);

  const eCheckSource = {
    label: t('echeque'),
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.eCheck,
    value: PAYMENT_SOURCE.eCheck,
  };

  const supportedPaymentMethodTypes = paymentMethodTypesByProvider[activeProviderType];

  if (
    localisationConfig.echeque &&
    !!activeProviderType &&
    hasPaymentProviderConfiguredForSource({ source: eCheckSource }) &&
    (supportedPaymentMethodTypes || []).includes(paymentMethodTypes.DIRECT_DEBIT)
  ) {
    sources.push(eCheckSource);
  }

  return sources;
}

export const InvoiceStatementPaymentAddFormContainer = withReduxProvider(
  composeHooks(hooks)(InvoiceStatementPaymentAddForm),
);

InvoiceStatementPaymentAddFormContainer.displayName = 'InvoiceStatementPaymentAddFormContainer';

InvoiceStatementPaymentAddFormContainer.propTypes = {
  debtorId: PropTypes.string.isRequired,
  invoiceSummaries: PropTypes.array.isRequired,
  scope: PropTypes.string.isRequired,
  onChargeFormSubmit: PropTypes.func,
  onChargeFormReadyForSubmit: PropTypes.func,
  onChargeFormDataChange: PropTypes.func.isRequired,
  triggerChargeFormSubmit: PropTypes.bool,
};

InvoiceStatementPaymentAddFormContainer.defaultProps = {
  onChargeFormSubmit: () => {},
  onChargeFormReadyForSubmit: () => {},
  triggerChargeFormSubmit: false,
};
