angular.module('@sb-itops/services').service('sbAppVersionService', function($http, sbLoggerService, sbEnvironmentConfigService) {
  'use strict';

  const that = this,
    log = sbLoggerService.getLogger('sbAppVersionService');

  that.versionP = versionP;

  function versionP() {
    const apiHost = sbEnvironmentConfigService.getRestApiUrl();
    const request = {
      method: 'GET',
      url: apiHost + '/versions/' // because webpack dev server proxy needs the trailing slash
    };
    
    log.info('send request', request);
    return $http(request)
      .then((response) => {
        log.info(`App version ${response.data.app}`);
        return response.data.app;
      })
      .catch((err) => {
        log.error('Problem getting app version', err);
        throw err;
      });
  }
});
