'use strict';

/**
 * @typedef { import('../types').Activity } Activity
 * @typedef { import('../types').ActivityStaffRateConfig } ActivityStaffRateConfig
 * @typedef { import('../types').StaffRateOverride } StaffRateOverride
 * @typedef { import('../types').ActivityMatterRateConfig } ActivityMatterRateConfig
 * @typedef {import('../types').ActivityRateOverrideTypes } ActivityRateOverrideTypes
 */

const { activityRateOverrideTypes } = require('../entities/constants');

/**
 * @deprecated It's handling three scenarios in one, which it shouldn't be
 * 1) Activity Time Entry Rate Config
 * 2) Activity Fee Entry (fixed fee) Rate Config
 * 3) Matter Rate Config
 * These scenarios are divergent by nature and has already started to diverge
 * more with the introduction of RateSets. Technically it still works at the
 * moment as the rate type value is mostly the same for all three scenarios.
 *
 * Non-exported helper function used by {@link deriveActivityRate} which is used to determine
 * the effective rate implied by a particular rate configuration.
 *
 * Note that a rate of 0 is legitimate.
 *
 * @param {Object} params
 * @param {string} [params.staffId]
 * @param {ActivityRateOverrideTypes} [params.rateOverrideType]
 * @param {number} [params.allStaffRate]
 * @param {Array<StaffRateOverride>} [params.ratesPerStaff]
 * @returns {number|undefined} The derived rate, or undefined if no derived rate can be found.
 */
const deriveRateFromConfig = ({ staffId, rateOverrideType, allStaffRate, ratesPerStaff }) => {
  if (rateOverrideType === undefined || rateOverrideType === activityRateOverrideTypes.NO_OVERRIDE) {
    return undefined;
  }

  if (staffId && rateOverrideType === activityRateOverrideTypes.CUSTOM_STAFF_OVERRIDE) {
    const overrideForStaffMember = ratesPerStaff?.find((staffRateOverride) => staffRateOverride.staffId === staffId);

    if (overrideForStaffMember?.rate || overrideForStaffMember?.rate === 0) {
      return overrideForStaffMember.rate;
    }
  }

  if (rateOverrideType === activityRateOverrideTypes.ALL_STAFF_OVERRIDE) {
    return allStaffRate || allStaffRate === 0 ? allStaffRate : undefined;
  }

  return undefined;
};

/**
 * @deprecated once BB-10835 Rate Sets is released.
 *
 * Given an activity and optionally a staff rate config and/or matter rate config, this function
 * returns the default rate for the activity via the following order of precendence:
 *  1. Specific staff member rate override in the matter rate config
 *  2. All staff rate override in the matter rate config
 *  3. Specific staff rate override member rate in the activity
 *  4. All staff rate override in the activity
 *  5. Staff member default rate in the staff rate config
 *
 * It's good because it's simple. See BB-676 for more information.
 *
 * @param {Object} params
 * @param {Activity} params.activity
 * @param {ActivityStaffRateConfig} [params.staffRateConfig]
 * @param {ActivityMatterRateConfig} [params.matterRateConfig]
 */

const deriveActivityRate = ({ activity, staffRateConfig = {}, matterRateConfig = {} }) => {
  // Attempt to derive rate using matter rate configuration.
  const matterRate = deriveRateFromConfig({
    ...matterRateConfig,
    staffId: staffRateConfig?.id,
  });

  if (matterRate !== undefined) {
    return matterRate;
  }

  // Attempt to derive using activity rate configuration.
  const activityRate = deriveRateFromConfig({
    ...activity,
    staffId: staffRateConfig?.id,
  });

  if (activityRate !== undefined) {
    return activityRate;
  }

  // Attempt to derive using staff default rate.
  if (staffRateConfig?.rate || staffRateConfig?.rate === 0) {
    return staffRateConfig?.rate;
  }

  return undefined;
};

module.exports = {
  deriveActivityRate,
};
