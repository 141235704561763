'use strict';

const tokenTypes = {
  SINGLE_USE: 'SingleUse',
  MULTI_USE: 'MultiUse',
};

module.exports = {
  tokenTypes,
};
