angular.module('sb.billing.webapp').directive('sbQueryDbSpinner', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'scope': {
      message : '='
    },
    'controller': 'SbQueryDBSpinnerController',
    'controllerAs': 'queryDBSpinner',
    'templateUrl': 'ng-components/query-db-spinner/query-db-spinner.html'
  };
});
