import { getAllReportsByBankAccountId, statusMap } from '@sb-billing/redux/end-of-month-reports';
import { createSelector } from 'reselect';
import { getCurrentStaffByUserId } from '@sb-firm-management/redux/firm-management';

const getAllEndOfMonthReportsSelector = createSelector(
  (state) => state || {},
  (endOfMonthReports) =>
    endOfMonthReports
      .filter(({ status }) => status !== statusMap.cancelled)
      .map((endOfMonthReport) => {
        const momentDate = moment(endOfMonthReport.date, 'YYYYMMDD');
        const month = momentDate.format('MMMM');
        const year = momentDate.year();
        const user = getCurrentStaffByUserId(endOfMonthReport.userId);
        return {
          ...endOfMonthReport,
          createdBy: (user && user.name) || endOfMonthReport.userId,
          description: `End of Month Report for ${month} ${year} `,
        };
      }),
);

export const getAllEndOfMonthReports = (trustAccountId) =>
  getAllEndOfMonthReportsSelector(getAllReportsByBankAccountId(trustAccountId));
