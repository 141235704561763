import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from '@sb-itops/uuid';
import * as forms from '@sb-itops/redux/forms2';
import store from '@sb-itops/redux/store';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { opdateCache, getById as getTransactionById } from '@sb-billing/redux/transactions';
import { capitalize } from '@sb-itops/nodash';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { createOpdates } from '@sb-billing/business-logic/accounts/transaction/reverse-credit-opdates';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getMap as getBankAccountBalanceMap } from '@sb-billing/redux/bank-account-balances';
import { getDefaultCreditAccount } from '@sb-billing/redux/bank-account';
import { creditNoteDetailsSchema } from './credit-note-details-schema';
import { CreditNoteDetailsModal } from './CreditNoteDetailsModal';

const log = getLogger('CreditNoteTransactionDetailsModal');
const scope = 'credit-note-details-modal';

const mapStateToProps = (state, { isVisible, transactionId, t }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, formSubmitting, formValid, fields: formFields } = formSelectors.getFormState(state);
  const { reason } = formFields;
  const {
    amount,
    effectiveDate,
    contactId,
    userId,
    matterId,
    note: internalNote,
    reason: transactionReason,
    reversed,
    isHidden,
    reversedFromTransactionId,
  } = getTransactionById(transactionId);

  const date = t('date', { yyyymmdd: effectiveDate });
  const processedBy = getPersonByUserId(userId)?.name;
  const contact = getContactDisplay(contactId);
  const showDebug = supportDebugSelectors.getShowDebug(state);
  const creditBankAccountId = getDefaultCreditAccount()?.id;
  const matterCreditBalance =
    getMatterBalance(getBankAccountBalanceMap(), { bankAccountId: creditBankAccountId, matterId }) || 0;
  const canReverseDelete = !reversed && !reversedFromTransactionId && amount <= matterCreditBalance;
  const isReversal = !!reversedFromTransactionId;
  const description = `${isReversal ? 'Reversal: ' : ''}Matter credited`;

  return {
    formInitialised,
    formValid,
    formSubmitting,
    isVisible,
    amount,
    effectiveDate: date,
    contact,
    processedBy,
    internalNote: internalNote || transactionReason,
    reason,
    description,
    transactionId,
    isDeleted: isHidden,
    showDebug,
    showReverse: canReverseDelete,
    showDelete: hasFacet(facets.deleteTransaction) && canReverseDelete,
  };
};

const mapDispatchToProps = (dispatch, { onClose, onClickLink, t }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            reason: '',
          },
        }),
      );
      dispatch(formOperations.validateSchema({ schema: creditNoteDetailsSchema }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onClickLink,
    onFieldValueUpdated: (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateSchema({ schema: creditNoteDetailsSchema }));
    },
    onProcess: async ({ transactionId, isDelete }) => {
      try {
        const { selectors, operations } = withScopedFeature({ scope })(forms);
        const { fields: formFields } = selectors.getFormState(store.getState());
        const { reason } = formFields;
        const transaction = getTransactionById(transactionId);
        const userId = authSelectors.getUserId(store.getState());
        const newTransactionId = uuid();
        const effectiveDate = Number(moment(new Date()).format('YYYYMMDD'));

        const body = {
          bankAccountId: transaction.bankAccountId,
          transactionIdToReverse: transactionId,
          newTransactionId,
          reason: reason.value,
          userId,
          effectiveDate,
          hideTransactions: isDelete,
          checkMatterBalance: false,
          allowOverdraw: false,
        };

        await dispatch(
          operations.submitFormP({
            submitFnP: async () =>
              fetchPostP({
                path: `/billing/bank-account/reversal/:accountId/credit/${transactionId}`,
                fetchOptions: {
                  body: JSON.stringify(body),
                },
              }),
          }),
        );

        const opdates = createOpdates({
          transaction,
          userId,
          newTransactionId,
          reason: reason.value,
          effectiveDate,
          isDelete,
          timestamp: moment().toISOString(),
        });
        opdateCache({ optimisticEntities: opdates });

        messageDisplay.success(`${capitalize(t('creditNote'))} ${isDelete ? 'deleted' : 'reversed'}.`);
      } catch (err) {
        log.error(err);
        messageDisplay.error(
          messageDisplay
            .builder()
            .text('Problem deleting credit')
            .conditionalText('{0}', err.message ? `: ${err.message}` : ''),
        );
      } finally {
        onClose();
      }
    },
    onCancel: onClose,
    onClose,
  };
};

export const CreditNoteTransactionDetailsModalContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(CreditNoteDetailsModal))),
);

CreditNoteTransactionDetailsModalContainer.displayName = 'CreditNoteTransactionDetailsModalContainer';

CreditNoteTransactionDetailsModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
  isVisible: PropTypes.bool,
  transactionId: PropTypes.string,
};

CreditNoteTransactionDetailsModalContainer.defaultProps = {
  isVisible: false,
  transactionId: undefined,
  onClickLink: () => {},
};
