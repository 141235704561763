import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@sb-itops/react/modal';
import classnames from 'classnames';
import { Translation } from '@sb-itops/react';
import { bankAccountName } from './bank-account-name';
import Styles from './FeeWisePayoutDetailsModal.module.scss';

const localizeBankAccountType = ({ accountType, t }) => {
  switch (accountType) {
    // We don't localize Office type to match FeeWise Dashboard in all regions
    // case 'Office':
    //   return t('operatingAccountType');
    case 'Trust':
      return t('trustAccountType');
    default:
      return accountType;
  }
};

export const FeeWisePayoutDetailsModal = ({ isVisible, onClose, payoutDetail }) => {
  const { amount, date, reference, status, account, bankAccount } = payoutDetail;
  return (
    <Modal
      className={Styles.feeWisePayoutDetailsModal}
      title="Payout Details"
      isVisible={isVisible}
      onModalClose={onClose}
      shouldCloseOnOverlayClick={false}
      showFooter={false}
      body={
        <Translation>
          {(t) => (
            <div className={Styles.gridContainer}>
              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Amount</div>
                <div className="col-md-7">{t('cents', { val: amount })}</div>
              </div>
              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Date</div>
                <div className="col-md-7">{t('date', { ts: date })}</div>
              </div>

              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Reference</div>
                <div className="col-md-7">{reference}</div>
              </div>
              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Status</div>
                <div className="col-md-7">{status}</div>
              </div>

              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Account Name</div>
                <div className="col-md-7">{bankAccountName({ bankAccount, t })}</div>
              </div>
              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Account Type</div>
                <div className="col-md-7">{localizeBankAccountType({ accountType: account?.accountType, t })}</div>
              </div>

              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Routing Number</div>
                <div className="col-md-7">{account?.routingNumber}</div>
              </div>
              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Account Number</div>
                <div className="col-md-7">{account?.accountNumber}</div>
              </div>
              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Country Code</div>
                <div className="col-md-7">{account?.countryCode}</div>
              </div>
            </div>
          )}
        </Translation>
      }
    />
  );
};

FeeWisePayoutDetailsModal.displayName = 'FeeWisePayoutDetailsModal';

FeeWisePayoutDetailsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  payoutDetail: PropTypes.shape({
    date: PropTypes.string,
    reference: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.number,
    account: PropTypes.shape({
      accountName: PropTypes.string,
      accountType: PropTypes.string,
      routingNumber: PropTypes.string,
      accountNumber: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    bankAccount: PropTypes.shape({
      id: PropTypes.string,
      accountType: PropTypes.string,
      accountName: PropTypes.string,
      displayName: PropTypes.string,
    }),
  }).isRequired,
};

FeeWisePayoutDetailsModal.defaultProps = {};
