import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { yupSchemaValidator } from '@sb-itops/business-logic/validation/services';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withReduxStore } from '@sb-itops/react';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { getSettings as getInvoiceEmailSettings } from '@sb-billing/redux/invoice-email-settings';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import { debtorEmailDataSchema } from './invoice-email-tab-per-debtor-form-schemas';
import { InvoiceEmailTabPerDebtorForm } from './InvoiceEmailTabPerDebtorForm';

const mapStateToProps = (state, { scope, invoiceId, onPreview }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields } = formSelectors.getFormState(state);

  if (!formInitialised) {
    return { isLoading: true };
  }

  const { selectedTabId, emailData = {} } = formFields;

  const tabs = Object.entries(emailData).map(([debtorId, debtorEmailData]) => ({
    text: getContactDisplay(debtorId),
    id: debtorId,
    debtorIncluded: emailData[debtorId].includeDebtor.value,
    tabValid: Object.values(debtorEmailData).every((field) => field.isValid),
  }));

  return {
    isLoading: !formInitialised,
    selectedTabId: selectedTabId.value,
    tabs,
    formData: emailData[selectedTabId.value],
    onPreviewId: (selectedDebtorId) => {
      const [invoiceEmailRequest] = convertToInvoiceEmailRequests({
        state,
        scope,
        invoiceIds: [invoiceId],
        debtorIds: [selectedDebtorId],
        selectedDebtorId,
      });
      return onPreview(invoiceEmailRequest);
    },
  };
};

const mapDispatchToProps = (dispatch, { scope, debtorIds, invoiceId, matterId }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(prepareFormData({ debtorIds, invoiceId, matterId, formActions, formOperations }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onSelectedDebtorChanged: (selectedTabId) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { selectedTabId } }));
    },
    onFormDataUpdated: (debtorId, field, value) => {
      const fieldValues = {
        emailData: {
          [debtorId]: { [field]: value },
        },
      };

      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateForm({ validateFn }));
    },
  };
};

const prepareFormData =
  ({ debtorIds, matterId, formActions, formOperations }) =>
  async (dispatch) => {
    const debtorsInformationPromises = debtorIds.map(async (debtorId) => {
      const toAddress = await buildAddressInformationP({ debtorId });
      return { debtorId, toAddress };
    });

    const debtorsInformation = await Promise.all(debtorsInformationPromises);

    const { email: staffMemberAddress } = getStaffEmailDetails() || {};
    const { emailSubject, emailBody, sendCopyToUser } = getInvoiceEmailSettings();

    // We have 1 invoice (and therefore only 1 matterId) with multiple debtors
    // so we can load CC/BCC from matter and use it as default for each debtor
    const { bCCAddresses = [], cCAddresses = [] } = getMatterEmailSettings(matterId) || {};

    const fieldValues = debtorsInformation.reduce(
      (acc, { debtorId, toAddress }) => {
        acc.emailData[debtorId] = {
          includeDebtor: true,
          toAddress,
          ccAddress: cCAddresses.join(', '),
          bccAddress: bCCAddresses.join(', '),
          fromAddress: staffMemberAddress,
          staffAddress: staffMemberAddress,
          sendCopyToMe: sendCopyToUser,
          subject: emailSubject,
          message: emailBody,
        };

        return acc;
      },
      {
        selectedTabId: debtorsInformation[0]?.debtorId,
        emailData: {},
      },
    );

    dispatch(formActions.initialiseForm({ fieldValues }));
    dispatch(formOperations.validateForm({ validateFn }));
  };

const buildAddressInformationP = async ({ debtorId }) => {
  try {
    const people = await fetchPeopleP(debtorId);
    return formatContactsEmails(people);
  } catch (err) {
    // No email address for debtor.
    return '';
  }
};

const validateFn = (formFields) => {
  const emailDataErrors = Object.entries(formFields.emailData).reduce((acc, [debtorId, debtorEmailData]) => {
    if (debtorEmailData.includeDebtor) {
      const errors = yupSchemaValidator(debtorEmailData, debtorEmailDataSchema);
      if (Object.keys(errors).length) {
        acc[debtorId] = errors;
      }
    }
    return acc;
  }, {});

  // Forms 2 expects errors to be reported as flattened dot object notation.
  return Object.keys(emailDataErrors).length ? nestedObjectToFlattened({ emailData: emailDataErrors }) : {};
};

export const convertToInvoiceEmailRequests = ({ state, scope, invoiceIds, selectedDebtorId }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);

  const formFields = formSelectors.getFieldValues(state);

  return Object.entries(formFields.emailData).reduce((acc, [debtorId, debtorEmailData]) => {
    if (!debtorEmailData.includeDebtor || (selectedDebtorId && debtorId !== selectedDebtorId)) {
      // If the debtor is not included in the send (user toggle), or
      // we only want to process the selected debtor (eg, previewing email)
      return acc;
    }

    let bcc = debtorEmailData.bccAddress;
    if (debtorEmailData.sendCopyToMe) {
      bcc = debtorEmailData.bccAddress
        ? `${debtorEmailData.bccAddress}, ${debtorEmailData.staffAddress}`
        : debtorEmailData.staffAddress;
    }

    acc.push({
      invoiceIds,
      debtorId,
      template: {
        toAddress: debtorEmailData.toAddress,
        replyToAddress: debtorEmailData.fromAddress,
        bcc,
        cc: debtorEmailData.ccAddress,
        subject: debtorEmailData.subject,
        message: debtorEmailData.message,
      },
    });

    return acc;
  }, []);
};

export const InvoiceEmailTabPerDebtorFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ onPreviewId, selectedTabId, ...props }) => {
      const [previewStateByTabId, setPreviewStateByTabId] = useState({});

      // onPreviewId is not present on the first render, so we would like to run this exactly once, but only after onPreviewId is present
      useEffect(
        () => {
          if (onPreviewId) {
            (async () => {
              // Load the preview data for all the debtors
              const previewData = await Promise.all(
                props.debtorIds.map(async (debtorId) => {
                  const { subject, message } = await onPreviewId(debtorId);
                  return {
                    debtorId,
                    previewSubject: subject,
                    previewMessage: message,
                    isPreviewMode: true,
                  };
                }),
              );
              const previewState = previewData.reduce((acc, data) => {
                acc[data.debtorId] = data;
                return acc;
              }, {});
              setPreviewStateByTabId(previewState);
            })();
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [!onPreviewId],
      );

      const onPreviewToggled = async () => {
        const previewStateForTab = previewStateByTabId[selectedTabId] || {
          isPreviewMode: false, // Will end up causing to toggle on in the same function invocation.
        };

        previewStateForTab.isPreviewMode = !previewStateForTab.isPreviewMode;

        if (previewStateForTab.isPreviewMode) {
          const { subject, message } = await onPreviewId(selectedTabId);
          previewStateForTab.previewSubject = subject;
          previewStateForTab.previewMessage = message;
        }

        setPreviewStateByTabId({
          ...previewStateByTabId,
          [selectedTabId]: previewStateForTab,
        });
      };

      return (
        <InvoiceEmailTabPerDebtorForm
          {...props}
          {...previewStateByTabId[selectedTabId]}
          selectedTabId={selectedTabId}
          onPreviewToggled={onPreviewToggled}
        />
      );
    }),
  ),
);

InvoiceEmailTabPerDebtorFormContainer.displayName = 'InvoiceEmailTabPerDebtorFormContainer';

InvoiceEmailTabPerDebtorFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  debtorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InvoiceEmailTabPerDebtorFormContainer.defaultProps = {};

export default InvoiceEmailTabPerDebtorFormContainer;
