import { gql } from '@apollo/client';

const query = gql`
  query InvoiceEmailModalData($ids: [ID]) {
    invoices(ids: $ids) {
      id
      debtors {
        id
        contact {
          id
          customLetterName
          displayNameFirstLast
          email
          firstName
          lastName
          letterNameFormat
          people {
            id
            email
            firstName
            lastName
            letterNameFormat
            title
            type
          }
          title
          type
        }
      }
      entries {
        id
        feeEntity {
          id
          duration
          feeType
        }
      }
      matter {
        id
        matterEmailSettings {
          id
          bccAddresses
          ccAddresses
        }
        matterInvoiceSettings {
          id
          payButtonEnabledOption
        }
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceEmailModalData = {
  query,
  notificationIds,
};
