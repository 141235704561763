import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { InvoiceListActionBar, InvoicesFilterPanel, InvoiceTable } from 'web/components';
import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';
import { MessageBar, PaginationSelector } from '@sb-itops/react';
import { NoBillingAccessWarning } from '@sb-billing/react';

import Styles from './BillingMatterInvoicesRoute.module.scss';

export const BillingMatterInvoicesRoute = React.memo(
  ({
    isBulkCreateInvoicesInProgress,
    userHasBillingAccess,
    // Callbacks
    onOpenInvoiceEmailModal,
    onOpenMarkInvoiceAsSentModal,
    /** Invoice filter panel */
    invoiceListReduxState,
    invoiceStatusCounts,
    /** Invoice list action bar */
    assignedOrDefaultMatterDebtorId,
    isCombineInPdfInProgress,
    isDownloadLedesInProgress,
    isEditableMatter,
    isLoadingMatterData,
    matterId,
    sbAsyncOperationsService,
    sbSaveInvoiceCommand,
    scope,
    selectedInvoicesByAction,
    // Callbacks
    onDeselectDeletedDraftInvoices,
    onFinaliseDraftInvoicesSuccess,
    onNavigateTo,
    /** Invoice table */
    invoiceDataLoading,
    invoices,
    invoicesOnSort,
    invoicesSortBy,
    invoicesSortDirection,
    invoiceStatuses,
    latestCorrespondenceLoading,
    latestCorrespondencePerContact,
    selectedInvoices,
    showDebtor,
    showMatter,
    // Callbacks
    onClickLink,
    onOpenInvoiceCommunicateModal,
    onToggleInvoices,
    onFetchLatestCorrespondenceDetailsForInvoice,
    /** Pagination */
    isPaginationHidden,
    invoicesCurrentPage,
    invoicesTotalNumberOfPages,
    invoicesOnPageChange,
  }) => (
    <div className={Styles.billingMatterInvoicesRoute}>
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        {!userHasBillingAccess ? (
          <NoBillingAccessWarning />
        ) : (
          <>
            <div className={Styles.invoicesSidePanel}>
              <InvoicesFilterPanel
                invoiceListReduxState={invoiceListReduxState}
                invoiceStatusCounts={invoiceStatusCounts}
              />
            </div>
            <div className={classnames('panel-body', Styles.panelContent)}>
              {/* Invoice list action bar */}
              <div className="ribbon panel panel-primary">
                <InvoiceListActionBar
                  assignedOrDefaultMatterDebtorId={assignedOrDefaultMatterDebtorId}
                  isBulkCreateInvoicesInProgress={isBulkCreateInvoicesInProgress}
                  isCombineInPdfInProgress={isCombineInPdfInProgress}
                  isDownloadLedesInProgress={isDownloadLedesInProgress}
                  isEditableMatter={isEditableMatter}
                  isLoading={isLoadingMatterData}
                  matterId={matterId}
                  sbAsyncOperationsService={sbAsyncOperationsService}
                  sbSaveInvoiceCommand={sbSaveInvoiceCommand}
                  scope={scope}
                  selectedInvoicesByAction={selectedInvoicesByAction}
                  showAddCredit
                  showAddPayment
                  showBulkActions
                  showCreateInvoice
                  showDownloadOptions
                  // Callbacks
                  onDeselectDeletedDraftInvoices={onDeselectDeletedDraftInvoices}
                  onFinaliseDraftInvoicesSuccess={onFinaliseDraftInvoicesSuccess}
                  onOpenInvoiceEmailModal={onOpenInvoiceEmailModal}
                  onOpenMarkInvoiceAsSentModal={onOpenMarkInvoiceAsSentModal}
                  onNavigateTo={onNavigateTo}
                />
              </div>
              {/* Bulk create invoices warning message */}
              {isBulkCreateInvoicesInProgress && (
                <div>
                  <MessageBar
                    messageType="warn"
                    messageText="A bulk invoice request is currently being processed - this table will update with new entries on completion."
                  />
                </div>
              )}
              {/* Invoice table */}
              <div className={Styles.invoiceTableContainer}>
                <InvoiceTable
                  invoices={invoices}
                  invoiceStatuses={invoiceStatuses}
                  isLoading={invoiceDataLoading}
                  selectedInvoices={selectedInvoices}
                  showDebtor={showDebtor}
                  showMatter={showMatter}
                  sort={invoicesOnSort}
                  sortBy={invoicesSortBy}
                  sortDirection={invoicesSortDirection}
                  latestCorrespondenceLoading={latestCorrespondenceLoading}
                  latestCorrespondencePerContact={latestCorrespondencePerContact}
                  onFetchLatestCorrespondenceDetailsForInvoice={onFetchLatestCorrespondenceDetailsForInvoice}
                  // Callbacks
                  onClickLink={onClickLink}
                  onMarkInvoiceAsSent={(rowData) => onOpenMarkInvoiceAsSentModal({ invoiceIds: [rowData.invoiceId] })}
                  onSendInvoiceCommunicate={(rowData) =>
                    onOpenInvoiceCommunicateModal({ invoiceIds: [rowData.invoiceId], contactId: rowData.contactId })
                  }
                  onSendInvoiceEmail={(rowData) =>
                    onOpenInvoiceEmailModal({ invoiceIds: [rowData.invoiceId], contactId: rowData.contactId })
                  }
                  onToggleInvoices={onToggleInvoices}
                />
              </div>
              {/* Pagination */}
              <PaginationSelector
                name="matter-invoice-list"
                className={Styles.paginationSection}
                hidePagination={isPaginationHidden}
                numberOfPagesDisplayed={10}
                selectedPage={invoicesCurrentPage}
                totalNumberOfPages={invoicesTotalNumberOfPages}
                onPageChange={invoicesOnPageChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  ),
);

BillingMatterInvoicesRoute.displayName = 'BillingMatterInvoicesRoute';

BillingMatterInvoicesRoute.propTypes = {
  isBulkCreateInvoicesInProgress: PropTypes.bool.isRequired,
  userHasBillingAccess: PropTypes.bool.isRequired,
  // Callbacks
  onOpenInvoiceEmailModal: PropTypes.func.isRequired,
  onOpenMarkInvoiceAsSentModal: PropTypes.func.isRequired,
  /** Invoice filter panel */
  invoiceListReduxState: PropTypes.object.isRequired,
  invoiceStatusCounts: PropTypes.object.isRequired,
  /** Invoice list action bar */
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  isEditableMatter: PropTypes.bool.isRequired,
  isLoadingMatterData: PropTypes.bool.isRequired,
  matterId: PropTypes.string.isRequired,
  assignedOrDefaultMatterDebtorId: PropTypes.string,
  sbAsyncOperationsService: PropTypes.object.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  selectedInvoicesByAction: PropTypes.object.isRequired,
  // Callbacks
  onDeselectDeletedDraftInvoices: PropTypes.func.isRequired,
  onFinaliseDraftInvoicesSuccess: PropTypes.func.isRequired,
  /** Invoice table */
  invoiceDataLoading: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoicesOnSort: PropTypes.func.isRequired,
  invoicesSortBy: PropTypes.string.isRequired,
  invoicesSortDirection: PropTypes.string.isRequired,
  invoiceStatuses: PropTypes.arrayOf(
    PropTypes.oneOf([
      invoiceStatusesMap.DRAFT,
      invoiceStatusesMap.FINAL,
      invoiceStatusesMap.PAID,
      invoiceStatusesMap.VOID,
      'OVERDUE', // Pseudo status based on "FINAL" invoices and their due dates
    ]),
  ).isRequired,
  latestCorrespondenceLoading: PropTypes.bool,
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object), // CorrespondenceHistory entity,
  selectedInvoices: PropTypes.object.isRequired,
  showDebtor: PropTypes.bool.isRequired,
  showMatter: PropTypes.bool.isRequired,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onFetchLatestCorrespondenceDetailsForInvoice: PropTypes.func.isRequired,
  onOpenInvoiceCommunicateModal: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
  onToggleInvoices: PropTypes.func.isRequired,
  /** Pagination */
  invoicesCurrentPage: PropTypes.number.isRequired,
  invoicesOnPageChange: PropTypes.func.isRequired,
  invoicesTotalNumberOfPages: PropTypes.number.isRequired,
  isPaginationHidden: PropTypes.bool.isRequired,
};

BillingMatterInvoicesRoute.defaultProps = {
  assignedOrDefaultMatterDebtorId: undefined,
  latestCorrespondenceLoading: undefined,
  latestCorrespondencePerContact: undefined,
};
