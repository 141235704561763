import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SlidingToggle = React.memo(({ id, onChange, scope, name, selected, disabled, someChildSelected }) => {
  const handleChange = (event) => {
    event.stopPropagation();
    // TODO use the standard (event, ...) inteface
    onChange(event.target.name, event.target.checked);
  };
  const scopedName = id || `${scope}-${name}`;

  return (
    <label className="switch" htmlFor={scopedName}>
      <input
        id={scopedName}
        type="checkbox"
        name={name}
        checked={selected}
        onChange={handleChange}
        disabled={disabled}
      />
      <div
        data-name={name}
        data-selected={selected}
        className={classnames('slider', disabled && 'disabled', someChildSelected && 'someChildSelected')}
      />
    </label>
  );
});

SlidingToggle.displayName = 'SlidingToggle';

SlidingToggle.propTypes = {
  // id uniquely identifies the sliding toggle input on the
  // form, important when there are multiple sliding toggles
  id: PropTypes.string,
  // in the abscence of an id, scope is used to create the id
  // we should consolidate to using either id or scope, not both
  scope: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  // name is really the fieldName, it identifies the form field
  name: PropTypes.string,
  disabled: PropTypes.bool,
  someChildSelected: PropTypes.bool,
};

SlidingToggle.defaultProps = {
  id: undefined,
  selected: false,
  name: 'default',
  onChange: () => {},
  disabled: false,
  someChildSelected: false,
};

export default SlidingToggle;
