/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'user-billing-attributes',
  keyPath: 'userId',
  ngCacheName: 'sbUserBillingAttributesService',
  syncType: syncTypes.SINCE,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getViewedMessages = () => getList()?.[0]?.viewedMessages || [];
export const getViewedMessagesMap = () =>
  getViewedMessages().reduce((acc, messageId) => {
    acc[messageId] = true;
    return acc;
  }, {});

export * from './save-user-billing-attributes';
