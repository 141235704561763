const { regionType, getRegion } = require('@sb-itops/region');
const { sortByProperty } = require('@sb-itops/nodash');
const { getActiveTrustAccounts, getOperatingAccount } = require('@sb-billing/redux/bank-account');
const { getBankAccountName } = require('@sb-billing/business-logic/bank-account/services');
const { bankAccount: bankAccountBusinessLogic } = require('@sb-billing/business-logic');

const { formatBankAccountDisplay } = bankAccountBusinessLogic.services;
const defaultAccountOption = { value: 'all', label: 'All' };

const getAUTrustAccountOptions = ({ t }) => {
  const activeTrustAccounts = getActiveTrustAccounts();
  const bankAccounts = sortByProperty(
    activeTrustAccounts.map((ba) => ({
      value: ba.id,
      label: getBankAccountName(ba, t), // display name in selection for navigation
      accountName: ba.accountName, // account full name in the generated report
    })),
    'label',
    'asc',
    false,
  );

  return bankAccounts;
};

// TODO: Copy AU implementation for GB.
const getGBTrustAccountOptions = ({ t }) => {
  const activeTrustAccounts = getActiveTrustAccounts();
  const bankAccounts = sortByProperty(
    activeTrustAccounts.map((ba) => ({
      value: ba.id,
      label: getBankAccountName(ba, t), // display name in selection for navigation
      accountName: ba.accountName, // account full name in the generated report
    })),
    'label',
    'asc',
    false,
  );

  return bankAccounts;
};

const getUSTrustAccountOptions = ({ t, includeAllOption = false }) => {
  const operatingAccount = getOperatingAccount();
  const activeTrustAccounts = getActiveTrustAccounts();
  const accounts = operatingAccount ? [operatingAccount, ...activeTrustAccounts] : [...activeTrustAccounts];

  let bankAccounts = sortByProperty(
    accounts.map((ba) => ({
      value: ba.id,
      label:
        ba.accountTypeDisplay !== 'Operating'
          ? getBankAccountName(ba, t)
          : formatBankAccountDisplay(ba.accountTypeDisplay), // display name in selection for navigation
      accountName: ba.accountName || formatBankAccountDisplay(ba.accountTypeDisplay), // account full name in the generated report
    })),
    'label',
    'asc',
    false,
  );

  // US accounts has three options: All, Trust and Operating Retainer
  bankAccounts = includeAllOption ? [defaultAccountOption, ...bankAccounts] : bankAccounts;

  return bankAccounts;
};

const regionAccountOptionsStrategy = {
  [regionType.AU]: {
    getTrustAccountOptions: getAUTrustAccountOptions,
    getDefaultAccountSelected: ({ t }) => getAUTrustAccountOptions({ t })[0],
  },
  [regionType.GB]: {
    getTrustAccountOptions: getGBTrustAccountOptions,
    getDefaultAccountSelected: ({ t }) => getGBTrustAccountOptions({ t })[0],
  },
  [regionType.US]: {
    getTrustAccountOptions: getUSTrustAccountOptions,
    getDefaultAccountSelected: ({ t, includeAllOption = false }) =>
      getUSTrustAccountOptions({ t, includeAllOption })[0],
  },
};

export default {
  ...regionAccountOptionsStrategy[getRegion()],
};
