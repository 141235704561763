/* eslint-disable react/prop-types */
/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CurrencyInput from './CurrencyInput';

class CurrencyInputToggled extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    hideDollar: PropTypes.bool,
    name: PropTypes.string,
    max: PropTypes.number,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    focus: PropTypes.string,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    hasError: PropTypes.bool,
    valueParser: PropTypes.func,
  };

  static defaultProps = {
    hideDollar: false,
    name: undefined,
    max: undefined,
    value: NaN,
    disabled: false,
    placeholder: undefined,
    focus: undefined,
    onBlur: () => {},
    className: undefined,
    hasError: false,
    valueParser: undefined,
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    toggled: true,
    value: undefined,
  };

  onClickToggled = () => {
    const toggled = true;
    this.setState({ toggled }, () => this.onChange({ target: { value: this.state.value } }));
  };

  onClickUnToggle = () => {
    const toggled = false;
    this.setState({ toggled }, () => this.onChange({ target: { value: this.state.value } }));
  };

  onChange = (e) => {
    const isToggled = !!this.state.toggled;
    const { name, valueParser } = this.props;
    const amount = e.target.value;

    this.setState({ value: amount }, () => {
      let value;
      if (!valueParser) {
        value = isToggled ? amount * -1 : amount;
      } else {
        value = valueParser(isToggled, amount);
      }

      this.props.onChange({
        ...e,
        target: {
          name,
          value,
        },
        currentTarget: {
          name,
          value,
        },
      });
    });
  };

  render() {
    const props = this.props;
    const { toggled } = this.state;

    const toggledClassNames = classnames('btn', 'toggled', toggled ? 'active btn-primary' : 'btn-default');
    const unToggleClassNames = classnames('btn', 'unToggle', !toggled ? 'active btn-primary' : 'btn-default');

    return (
      <div className="currency-input-toggle">
        <CurrencyInput
          min={0}
          max={props.max}
          value={this.state.toggled ? props.value * -1 : props.value} // When Withdrawal is toggled, the value will be negative, we need to pass the value as positive for currency input
          hideDollar={props.hideDollar}
          disabled={props.disabled}
          name={props.name}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          className={props.className}
          onChange={this.onChange}
          hasError={props.hasError}
        />
        <div className="btn-group">
          <button type="button" className={toggledClassNames} onClick={this.onClickToggled}>
            {props.firstToggleText || 'Withdrawal'}
          </button>
          <button type="button" className={unToggleClassNames} onClick={this.onClickUnToggle}>
            {props.secondToggleText || 'Deposit'}
          </button>
        </div>
      </div>
    );
  }
}

CurrencyInputToggled.displayName = 'CurrencyInputToggled';

export default CurrencyInputToggled;
