import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { getContactDisplay as getContactDisplayFromContact } from '@sb-customer-management/business-logic/contacts/services';
import ContactDisplay from './ContactDisplay';

const hooks = (props) => ({
  useContactDisplay: () => {
    const { contact, showLastNameFirst } = props;
    const displayName = getContactDisplayFromContact(contact, { showLastNameFirst });
    return {
      contactId: contact?.id,
      displayName,
    };
  },
});

const ContactDisplay2Container = composeHooks(hooks)(ContactDisplay);

ContactDisplay2Container.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    displayNameFirstLast: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    removed: PropTypes.bool,
    type: PropTypes.string,
    people: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        displayNameFirstLast: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        removed: PropTypes.bool,
        type: PropTypes.string,
      }),
    ),
  }),
  onClickLink: PropTypes.func,
  className: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  showLastNameFirst: PropTypes.bool,
};

ContactDisplay2Container.defaultProps = {
  showLastNameFirst: false,
  contact: undefined,
};

export default ContactDisplay2Container;
