'use strict';

const depositSlipPdf = {
  AU: {
    getRoute: ({ depositSlipId, version }) => [depositSlipId, version],
  },
  GB: {
    getRoute: ({ depositSlipId, version }) => [depositSlipId, version],
  },
  US: {
    getRoute: ({ depositSlipId }) => `${depositSlipId}`,
  },
};

module.exports = {
  depositSlipPdf,
};
