import { fetchPostP } from '@sb-itops/redux/fetch';
import uuid from '@sb-itops/uuid';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { store } from '@sb-itops/redux';
import { api } from './cache';
import { getDefaultReceiptSettings } from './default-settings';

const { getById: getTrustReceiptSettingsById } = api;

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbTrustReceiptSettingsService',
  keyPath: 'bankAccountId',
});

export const saveTrustReceiptSettings = (settings) => {
  const saveTrustReceiptSettingsThunk = async () => {
    let updatedSettings = { ...settings, versionId: uuid() };

    const existingSettings = getTrustReceiptSettingsById(updatedSettings.bankAccountId) || getDefaultReceiptSettings();
    if (existingSettings) {
      updatedSettings = { ...existingSettings, ...updatedSettings };
    }
    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [updatedSettings] });

    // Apply the save in the backend.
    try {
      const bankAccountId = encodeURIComponent(updatedSettings.bankAccountId);
      const path = `/billing/trust-receipt/settings/:accountId/${bankAccountId}/`;
      const fetchOptions = { body: JSON.stringify(updatedSettings) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [updatedSettings] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveTrustReceiptSettingsThunk);
};
