'use strict';

const { regionType } = require('@sb-itops/region');

const mustBeFirmOwnerBankAccountDetailChanges = {
  [regionType.AU]: true,
  [regionType.GB]: true,
  [regionType.US]: false,
};

const canShowPaymentDetailsOnInvoices = {
  [regionType.AU]: true,
  [regionType.GB]: true,
  [regionType.US]: false,
};

const branchNumberRegex = {
  [regionType.AU]: /^\d{3}-?\d{3}$/,
  [regionType.GB]: /^\d{2}-?\d{2}-?\d{2}$/,
  [regionType.US]: /(.*?)/, // match everything - no bank branch number restrictions in US
};

const bankAccountStrategies = {
  FIRM_OWNER_BANK_DETAIL_CHANGES: 'FIRM_OWNER_BANK_DETAIL_CHANGES',
  PAYMENT_DETAILS_ON_INVOICES: 'PAYMENT_DETAILS_ON_INVOICES',
  BRANCH_NUMBER_REGEX: 'BRANCH_NUMBER_REGEX',
};

const bankAccountStrategiesMap = {
  FIRM_OWNER_BANK_DETAIL_CHANGES: mustBeFirmOwnerBankAccountDetailChanges,
  PAYMENT_DETAILS_ON_INVOICES: canShowPaymentDetailsOnInvoices,
  BRANCH_NUMBER_REGEX: branchNumberRegex,
};

const getBankAccountStrategy = (region, strategy) => {
  if (!Object.keys(bankAccountStrategies).includes(strategy)) {
    throw Error(`Strategy ${strategy} not defined in strategies.js, needs to be added before it can be called`);
  }
  return bankAccountStrategiesMap[strategy][region];
};

module.exports = {
  bankAccountStrategies,
  getBankAccountStrategy,
};
