import { store } from '@sb-itops/redux';
import { fetchDeleteP } from '@sb-itops/redux/fetch';
import { opdateCache, rollbackOpdateCache, getById } from './index';

export const deleteMatter = async ({ matterId }) => {
  await store.dispatch(async () => {
    const existingMatter = getById(matterId);
    const opdateEntity = { ...existingMatter, status: 'Deleted' };
    opdateCache({ optimisticEntities: [opdateEntity] });

    try {
      const path = `/matter-management/matter/delete/:accountId/${matterId}/`;
      const fetchOptions = {};
      const res = await fetchDeleteP({ path, fetchOptions });

      return res.body.id;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [opdateEntity] });
      throw err;
    }
  });
};
