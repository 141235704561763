angular.module('sb.billing.webapp').component('sbDataBankReconciliationById', {
  'require':  { compose: '^sbCompose' },
  'bindings' : { bankReconciliationId: '<', composeDataKey: '@?' },
  'controller': function ($scope, sbBankReconciliationService, sbLoggerService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataBankReconciliationById');
    let recon, initialized;

    ctrl.$onInit = () => {
      update();
      initialized = true;
    };

    ctrl.$onChanges = () => {
      if (initialized) {
        update();
      }
    };

    function update() {
      log.info('updating bank reconciliation latest');
      const newRecon = sbBankReconciliationService.getById(ctrl.bankReconciliationId);
      if (newRecon && newRecon !== recon) {
        recon = newRecon;
        ctrl.compose.setComposeData({ dataType: 'reconciliation', data: newRecon }, ctrl.composeDataKey || 'reconciliation');
      }
    }

    $scope.$on('smokeball-data-update-sbBankReconciliationService', update);
  }
});
