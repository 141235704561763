'use strict';

const { fetchAccessAuthorisations } = require('./fetch-access-authorisations');

/**
 * fetch authorisations for given Smokeball resourceIds
 * @param {object} params
 * @param {string} params.accountId
 * @param {string} params.userId
 * @param {string[]} params.matterIds
 * @param {'AU'|'US'|'GB'} params.region
 * @param {'LOCAL'|'DEV'|'RC'|'QA'|'LIVE'} params.appEnv
 * @param {'dev'|'staging'|'prod'} params.dataEnv
 * @returns {Promise<Object.<string, boolean>>} Record<matterId, isAuthorised> for each matterId
 */
async function fetchMatterAuthorisations({ accountId, userId, matterIds, region, appEnv, dataEnv }) {
  const matterAuthorisations = await fetchAccessAuthorisations({
    accountId,
    userId,
    resourceIds: matterIds.map((id) => `matters/${id}`),
    region,
    appEnv,
    dataEnv,
  });

  const matterPermissions = matterAuthorisations.reduce((acc, i) => {
    // safety case. refusing to overwrite false with true although we do not expect multiple identical resourceId
    if (acc[i.resourceId] !== false) {
      acc[i.resourceId.replace(/^matters\//, '')] = i.isAuthorized;
    }

    return acc;
  }, {});

  return matterPermissions;
}

module.exports = {
  fetchMatterAuthorisations,
};
