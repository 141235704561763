'use strict';

/**
 * Tests whether given matterType is generic or fed. Undefined matter types return false.
 * @param {object} matterType
 * @returns boolean
 */
const isMatterTypeGenericOrFed = (matterType) => ['GENERIC', 'FED'].includes(matterType?.locationId);

/**
 * Tests whether given location is in the exclusion list of a generic or fed matter type
 * @param {*} matterType
 * @param {*} locationId
 * @returns
 */
const shouldIncludeGenericFedMatterTypeForLocation = (matterType, locationId) =>
  // user selected location is not in exclusion list for GENERIC/FED matter type
  isMatterTypeGenericOrFed(matterType) && !matterType.excludedLocationIds.includes(locationId);

/**
 * @param {object} matterType
 * @param {string} locationId
 * @returns boolean whether matter type is valid for given location
 */
const isValidMatterTypeForLocation = (matterType, locationId) =>
  !matterType.isDeleted && // dont' show deleted matter types
  (!matterType.typeCategory || matterType.typeCategory === 0) && // include 1) matter types only 2) there's no type category for old records 3) lead types (typeCategory === 1) are not included
  matterType.categories &&
  matterType.categories.length > 0 && // there are defined categories for matter type
  (matterType.locationId === locationId || // matter type location matches user selected location, or
    shouldIncludeGenericFedMatterTypeForLocation(matterType, locationId)); // GENERIC/FED matter type does not exclude this location

/**
 * @param {object} matterType
 * @param {string} locationId
 * @returns boolean whether matter type is valid for given location
 */
const isValidLeadTypeForLocation = (matterType, locationId) =>
  !matterType.isDeleted && // dont' show deleted matter types
  matterType.typeCategory === 1 && // include lead types only
  matterType.categories &&
  matterType.categories.length > 0 && // there are defined categories for matter type
  (matterType.locationId === locationId || // matter type location matches user selected location, or
    shouldIncludeGenericFedMatterTypeForLocation(matterType, locationId)); // GENERIC/FED matter type does not exclude this location

/**
 * @depecated Use getLocationFromMatterType instead which does not require getter function
 *
 * Given a matterTypeId and getter function, returns the correct location (state) for the matter type
 * @param {string} matterTypeId
 * @param {function} getMatterTypeById
 * @returns {string|undefined} locationId
 */
const getLocationFromMatterTypeId = (matterTypeId, getMatterTypeById) => {
  if (!matterTypeId || !getMatterTypeById || typeof getMatterTypeById !== 'function') {
    return undefined;
  }
  const matterType = getMatterTypeById(matterTypeId);
  if (!matterType) {
    return undefined;
  }

  let locationId = matterType.locationId;
  if (isMatterTypeGenericOrFed(matterType)) {
    [, locationId] = matterTypeId.split('_');
    // If no _ID given, just return 'GENERIC'
    if (!locationId) {
      locationId = matterType?.locationId;
    }
  }
  return locationId;
};

/**
 * Given a matterTypeId and matter type object, returns the correct location (state) for the matter type.
 * LOD-compatible version of getLocationFromMatterTypeId function above
 * @param {object} matterType
 * @param {string} matterTypeId
 * @returns {string|undefined} locationId
 */
const getLocationFromMatterType = (matterType, matterTypeId) => {
  if (!matterTypeId || !matterType) {
    return undefined;
  }

  let locationId = matterType.locationId;
  if (isMatterTypeGenericOrFed(matterType)) {
    [, locationId] = matterTypeId.split('_');
    // If no _ID given, just return 'GENERIC'
    if (!locationId) {
      locationId = matterType?.locationId;
    }
  }
  return locationId;
};

module.exports = {
  isMatterTypeGenericOrFed,
  isValidMatterTypeForLocation,
  isValidLeadTypeForLocation,
  getLocationFromMatterTypeId,
  getLocationFromMatterType,
};
