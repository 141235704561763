'use strict';

const { Cent } = require('@sb-itops/money');
const { discountType } = require('../entities/discount-type');

const { FIXED, PERCENTAGE } = discountType;

const calculateCashDiscount = (totalFeeAmountExclTax, discountAmount) => {
  const discount = new Cent(Math.max(discountAmount, 0)); // can't be < 0

  return Math.min(totalFeeAmountExclTax, discount.cents()); // can't be > total invoice amount
};

const calculatePercentageDiscount = (totalFeeAmountExclTax, discountPercent) => {
  const perc = Math.max(0, discountPercent); // can't be < 0
  const discount = new Cent((totalFeeAmountExclTax * perc) / 100);

  return Math.min(totalFeeAmountExclTax, discount.cents()); // can't be > total invoice amount
};

/**
 * @param {object} param
 * @param {number} param.totalFeeAmountExclTax cents
 * @param {Discount} param.discount
 */
const calculateDiscount = ({ totalFeeAmountExclTax = 0, discount }) => {
  let discountTotal = 0;

  if (discount) {
    switch (discount.type) {
      case FIXED:
        discountTotal = calculateCashDiscount(totalFeeAmountExclTax, discount.fixedDiscount || 0);
        break;
      case PERCENTAGE:
        discountTotal = calculatePercentageDiscount(totalFeeAmountExclTax, discount.percentage || 0);
        break;
      default:
        break;
    }
  }

  return new Cent(discountTotal).cents();
};

module.exports = {
  calculateDiscount,
};
