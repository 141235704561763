import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Typeahead, FloatingCard, forms2PropTypes, FormLabel, useTranslation } from '@sb-itops/react';
import { AddressFields } from './AddressFields/AddressFields.container';

import Styles from '../FeeWiseApplicationForm.module.scss';
import { websiteUrlError } from '../FeeWiseApplicationFormSchema.yup';

export const FirmDetails = ({
  firmDetails,
  formInitialised,
  businessStructureOptions,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  sectionRef,
  localisationConfig,
}) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
  const websiteField = () => (
    <div className={classnames('form-group', 'col-sm-6')}>
      <FormLabel uppercase={false} label="Website" />
      <input
        type="text"
        value={firmDetails?.website?.value}
        placeholder="Include https:// in your URL"
        className={classnames('form-control', isErrorClassnames(firmDetails?.website))}
        onChange={(e) => onFieldUpdated({ 'firmDetails.website': e.target.value })}
        disabled={formDisabled}
      />
      {!!isErrorClassnames(firmDetails?.website) && firmDetails?.website?.invalidReason === websiteUrlError && (
        <span className={Styles.errorMsg}>{firmDetails?.website?.invalidReason}</span>
      )}
    </div>
  );

  return (
    <FloatingCard applyMargin={false} classname={Styles.marginBottom} ref={sectionRef}>
      <div className={Styles.sectionTitle}>Firm Details</div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Firm Legal Company Name" />
          <input
            type="text"
            value={firmDetails?.legalCompanyName?.value}
            className={classnames('form-control', isErrorClassnames(firmDetails?.legalCompanyName))}
            onChange={(e) => onFieldUpdated({ 'firmDetails.legalCompanyName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Trading As (if different)" optional />
          <input
            type="text"
            value={firmDetails?.tradingAs?.value}
            className={classnames('form-control', isErrorClassnames(firmDetails?.tradingAs))}
            onChange={(e) => onFieldUpdated({ 'firmDetails.tradingAs': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        {localisationConfig.showBusinessStructure && (
          <div className={classnames('form-group', 'col-sm-6')}>
            <FormLabel uppercase={false} label="Business Structure" />
            <Typeahead
              selectedOption={businessStructureOptions.find(
                (option) => option.value === firmDetails.businessStructure?.value,
              )}
              options={businessStructureOptions}
              disabled={formDisabled}
              placeholder="Select..."
              onSelect={(option) => {
                onFieldUpdated({
                  'firmDetails.businessStructure': option?.value || undefined,
                });
              }}
              hasError={!!isErrorClassnames(firmDetails?.businessStructure)}
            />
          </div>
        )}

        {/* If we hide business structure, move website field to fill hole */}
        {!localisationConfig.showBusinessStructure && websiteField()}

        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label={t('settings.feewise.einLabel')} />
          <input
            type="text"
            value={firmDetails?.employerIdNumber?.value}
            className={classnames('form-control', isErrorClassnames(firmDetails?.employerIdNumber))}
            onChange={(e) => onFieldUpdated({ 'firmDetails.employerIdNumber': e.target.value })}
            disabled={formDisabled}
          />
          {!!isErrorClassnames(firmDetails?.employerIdNumber) &&
            firmDetails?.employerIdNumber?.invalidReason.includes('Must be exactly') && (
              <span className={Styles.errorMsg}>{firmDetails?.employerIdNumber?.invalidReason}</span>
            )}
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Phone Number" />
          <input
            type="text"
            value={firmDetails?.phoneNumber?.value}
            className={classnames('form-control', isErrorClassnames(firmDetails?.phoneNumber))}
            onChange={(e) => onFieldUpdated({ 'firmDetails.phoneNumber': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Email Address" />
          <input
            type="email"
            value={firmDetails?.emailAddress?.value}
            className={classnames('form-control', isErrorClassnames(firmDetails?.emailAddress))}
            onChange={(e) => onFieldUpdated({ 'firmDetails.emailAddress': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      {localisationConfig.showBusinessStructure && <div className="row">{websiteField()}</div>}
      <AddressFields
        addressFields={firmDetails}
        parentPath="firmDetails"
        formInitialised={formInitialised}
        submitFailed={submitFailed}
        formDisabled={formDisabled}
        onFieldUpdated={onFieldUpdated}
      />
    </FloatingCard>
  );
};

FirmDetails.displayName = 'FirmDetails';

const { Forms2Field } = forms2PropTypes;

FirmDetails.propTypes = {
  firmDetails: PropTypes.shape({
    legalCompanyName: Forms2Field,
    tradingAs: Forms2Field,
    businessStructure: Forms2Field,
    employerIdNumber: Forms2Field,
    phoneNumber: Forms2Field,
    emailAddress: Forms2Field,
    website: Forms2Field,
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
  }),
  businessStructureOptions: PropTypes.array.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
  localisationConfig: PropTypes.shape({
    showBusinessStructure: PropTypes.bool.isRequired,
    populateACN: PropTypes.bool.isRequired,
  }).isRequired,
};

FirmDetails.defaultProps = {
  formDisabled: false,
  firmDetails: {},
};
