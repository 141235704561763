'use strict';

const moment = require('moment');

/**
 * @param {*} param
 * @param {number} param.month, 0-11
 * @param {number} param.year
 * @returns {number} yyyymmdd
 */
const getEndOfMonth = ({ month, year }) => {
  if (!Number.isInteger(month) || !year) {
    return undefined;
  }
  const paddedMonth = `0${month + 1}`.slice(-2);
  const yyyymmdd = `${year}${paddedMonth}01`;

  return +moment(yyyymmdd, 'YYYYMMDD').endOf('month').format('YYYYMMDD');
};

const getEndOfNextMonth = (yyyymmdd) => moment(yyyymmdd, 'YYYYMMDD').startOf('month').add(1, 'months').endOf('month');

/**
 * Calculate the EOM report month based on the previous report
 * @param {object|undefined} latestEomReport
 * @returns {number|undefined} month 0-11
 */
const getNextReportMonth = (latestEomReport) =>
  latestEomReport && latestEomReport.date ? getEndOfNextMonth(latestEomReport.date).month() : undefined;

/**
 * Calculate the EOM report year based on the previous report
 * @param {object|undefined} latestEomReport
 * @returns {number|undefined} year YYYY
 */
const getNextReportYear = (latestEomReport) =>
  latestEomReport && latestEomReport.date ? getEndOfNextMonth(latestEomReport.date).year() : undefined;

/**
 * Calculate the EOM report year based on the previous report, or using defaults if latestEomReport isnt specified
 * @param {object} param
 * @param {object|undefined} param.latestEomReport
 * @param {number|undefined} param.defaultMonth, month 0-11
 * @param {number|undefined} param.defaultYear, YYYY
 * @returns {object} { month, year }
 */
const getNextReportPeriod = ({ latestEomReport, defaultMonth, defaultYear }) => {
  const month = getNextReportMonth(latestEomReport);

  return {
    month: Number.isInteger(month) ? month : defaultMonth,
    year: getNextReportYear(latestEomReport) || defaultYear,
  };
};

/**
 * @param {*} param
 * @param {number|undefined} param.reconciledYYYYMMDD
 * @param {number|undefined} param.reportYYYMMDD
 * @returns {boolean}
 */
const reconciled = ({ reconciledYYYYMMDD, reportYYYYMMDD }) => {
  if (!reconciledYYYYMMDD || !reportYYYYMMDD) {
    return false;
  }

  return reconciledYYYYMMDD === reportYYYYMMDD;
};

/**
 * Get Array of options representing the last 5 years up to the year specified
 * @param {number} thisYear
 * @returns {Array.<object>}
 */
const getYears = (thisYear) => Array.from({ length: 5 }, (e, i) => ({ label: thisYear - i, value: thisYear - i }));

/**
 * Get the long month description based on the index
 * @param {number} index, month 0-11
 * @returns {string}
 */
const getLongMonth = (index) => new Date(null, index, 1).toLocaleDateString('en', { month: 'long' });

/**
 * Get Array of options representing the months of the year
 * @param {number} thisYear
 * @returns {Array.<object>}
 */
const getMonths = () =>
  Array.from({ length: 12 }, (e, i) => ({
    label: getLongMonth(i),
    value: i,
  }));

module.exports = {
  getEndOfMonth,
  getNextReportPeriod,
  getYears,
  getMonths,
  getLongMonth,
  reconciled,
};
