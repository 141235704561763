'use strict';

/**
 * isPaymentProviderEnabledForBankAccount
 * Returns true if stripe payments are enabled for the passed bank account ID.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings Stripe settings as configured in firm settings
 * @param {string} params.bankAccountId The bank account ID to check for stripe payment availability.
 * @returns {boolean}
 */
function isPaymentProviderEnabledForBankAccount({ formattedProviderSpecificSettings, bankAccountId }) {
  if (!bankAccountId) {
    return false;
  }

  if (!formattedProviderSpecificSettings || !formattedProviderSpecificSettings.linkedAccounts) {
    return false;
  }

  const linkedAccount = formattedProviderSpecificSettings.linkedAccounts[bankAccountId.toLowerCase()];
  if (!linkedAccount) {
    return false;
  }

  return !!linkedAccount.onboardingComplete;
}

module.exports = {
  isPaymentProviderEnabledForBankAccount,
};
