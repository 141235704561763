import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbPaymentService',
  keyPath: 'paymentId',
});

export const buildPaymentOpdates = (payment, accountId, momentNow) => {
  const opdatePartial = {
    paymentId: payment.paymentId,
    destinationAccountId: `${accountId}/Operating`,
    matterId: payment.matterId,
    reference: payment.reference,
    userId: payment.userId,
    note: payment.note,
    timestamp: momentNow.toISOString(),
    effectiveDate: +momentNow.format('YYYYMMDD'),
    accountId,
  };

  return payment.payors
    ? payment.payors.map(({ paymentId, payorId, amount }) => ({
        ...opdatePartial,
        ...{
          paymentId,
          payorId,
          sourceAccountBalanceType: payment.sourceAccountBalanceType,
          sourceAccountId: `${accountId}/${payment.sourceAccountType}`,
          invoices: [
            {
              invoiceId: payment.invoiceId,
              amount,
            },
          ],
        },
      }))
    : [
        {
          ...opdatePartial,
          paymentId: payment.paymentId,
          payorId: payment.payorId,
          source: payment.source,
          invoices: [
            {
              invoiceId: payment.invoiceId,
              amount: payment.amount,
            },
          ],
        },
      ];
};
