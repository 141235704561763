import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { OpenModalButton } from '@sb-itops/react/open-modal-button';
import { AdjustmentModal } from '../adjustment-modal';

const AddAdjustmentModalButton = memo(({ onOpen, onSubmitHandler }) => {
  const onModalRenderer = useCallback(
    (onCloseHandler) => {
      if (onOpen) {
        onOpen();
      }

      const onSubmitHandlerCallback = async (...args) => {
        await onSubmitHandler(...args);
        onCloseHandler();
      };

      return (
        <AdjustmentModal
          formName="adjustmentForm"
          modalTitle="Adjustments"
          onSubmitHandler={onSubmitHandlerCallback}
          onModalClosed={onCloseHandler}
        />
      );
    },
    [onOpen, onSubmitHandler],
  );

  return <OpenModalButton inputText="Add Adjustment" onModalRenderer={onModalRenderer} />;
});

AddAdjustmentModalButton.displayName = 'AddAdjustmentModalButton';

AddAdjustmentModalButton.propTypes = {
  onOpen: PropTypes.func,
  onSubmitHandler: PropTypes.func.isRequired,
};

AddAdjustmentModalButton.defaultProps = {
  onOpen: undefined,
};

export default AddAdjustmentModalButton;
