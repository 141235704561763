'use strict';

//TODO - TH
// change this to component
// change setSelected to be something clearer
// change onlyUsed to be something clearer
angular.module('sb.billing.webapp').directive('sbStaffMultiSelect', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: { onlyUsed: '@', onSelect: '&' , setSelected: '=' },
    controller: 'SbStaffMultiSelectController',
    controllerAs: '$ctrl',
    templateUrl: 'ng-components/staff-multi-select/staff-multi-select.html'
  };
});
