'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const eInvoiceEnabledOptionValues = Object.freeze({
  USE_FIRM_DEFAULT: 0,
  DISABLED: 1,
  ENABLED: 2,
});

const eInvoiceEnabledOptionLabels = Object.freeze({
  USE_FIRM_DEFAULT: 'Use firm default',
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
});

const eInvoiceEnabledOptions = getTypeAsOptions({
  typeLabels: eInvoiceEnabledOptionLabels,
  typeValues: eInvoiceEnabledOptionValues,
});

module.exports = {
  eInvoiceEnabledOptionLabels,
  eInvoiceEnabledOptionValues,
  eInvoiceEnabledOptions,
};
