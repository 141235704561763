import {
  updateCache as updateRedux,
} from '@sb-billing/redux/operating-cheque-print-settings';
import { featureActive } from '@sb-itops/feature';

angular.module('@sb-billing/services').service('sbOperatingChequePrintSettingsService', function (
  sbGenericCacheService,
  sbEndpointType,
  ) {
  const endpoint = 'billing/operating-cheque-print-settings';
  const subscriptions = 'notifier.AccountsNotifications.OperatingChequePrintSettingsUpdated';

  if(!featureActive('BB-3331')) {
    return undefined;
  }

  sbGenericCacheService.setupCache({
    name: 'sbOperatingChequePrintSettingsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: endpoint },
      poll: 60,
      subscriptions
    },
    updateRedux
  });
});
