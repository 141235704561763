import { gql } from '@apollo/client';
import { invoiceTemplateFragment } from '../fragments/invoiceTemplateFragment.graphql';

const query = gql`
  query InvoiceSettingsTemplateFirmDefault {
    invoiceSettingsTemplateFirmDefault {
      ...invoiceTemplateFragment
    }
  }

  ${invoiceTemplateFragment}
`;

const notificationIds = ['InvoicingNotifications.InvoiceSettingsTemplateUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceSettingsTemplateFirmDefault = {
  query,
  notificationIds,
};
