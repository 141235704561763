import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, StatelessSelect, FormLabel, CurrencyInput2 } from '@sb-itops/react';
import classnames from 'classnames';
import { getRegion } from '@sb-itops/region';
import { ContactTypeahead } from '@sb-customer-management/react';
import { AddContactInlineForm } from 'web/react-redux';
import Styles from './AddLead.module.scss';
import { getBillingTypeOptions, billingTypes } from '../add-edit-matter/matter-fee-and-rate-settings/billing-types';
import { MatterTypeSelect } from '../matter-type-select';

const REGION = getRegion();

const AddLead = ({
  errors,
  attorneyResponsibleId,
  billingType,
  assignableStaff,
  matterTypeId,
  locationId,
  categoryId,
  referralType,
  referrerId,
  leadContactId,
  showAddLeadContactForm,
  setShowAddLeadContactForm,
  showAddReferrerContactForm,
  setShowAddReferrerContactForm,
  contactOptions,
  amount,
  contingencyAmount,
  submitFailed,
  // callbacks & functions
  onFieldValueChanged,
}) => {
  const { t } = useTranslation();

  const billingTypeOptions = getBillingTypeOptions(getRegion());

  return (
    <div className={classnames(Styles.addLead)}>
      <fieldset className={Styles.addContactTabs}>
        <div>
          <FormLabel label="Client" field={leadContactId} submitFailed />
          <ContactTypeahead
            contacts={contactOptions}
            onContactSelected={(newContact) => onFieldValueChanged('leadContactId', newContact?.value)}
            selectedContactId={leadContactId}
            showAdd
            onAdd={() => setShowAddLeadContactForm(!showAddLeadContactForm)}
            isAddActive={showAddLeadContactForm}
            placeholder="Select or create a contact for the lead..."
            hasError={!!errors?.leadContactId}
          />
          {showAddLeadContactForm && (
            <div className="row">
              <div className="col-sm-12 form-group">
                <AddContactInlineForm
                  onClose={() => setShowAddLeadContactForm(false)}
                  onContactCreated={(newContactId) => {
                    setShowAddLeadContactForm(!showAddLeadContactForm);
                    onFieldValueChanged('leadContactId', newContactId);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </fieldset>
      <hr className={Styles.hr} />
      <div className="form-group">
        <FormLabel label="Lead Type" />
        <div>
          <MatterTypeSelect
            showOnlyLeads
            locationId={locationId}
            matterTypeCategory={categoryId}
            matterTypeId={matterTypeId}
            onLocationUpdated={(value) => onFieldValueChanged('locationId', value)}
            onCategoryUpdated={(value) => onFieldValueChanged('categoryId', value)}
            onMatterTypeUpdated={(option) => {
              onFieldValueChanged('matterTypeId', option?.value);
              onFieldValueChanged('originalMatterTypeId', option?.originalMatterTypeId);
            }}
            isRequired
            showError={submitFailed}
          />
        </div>
      </div>
      <hr className={Styles.hr} />
      <div className="form-group">
        <FormLabel label={REGION === 'US' ? 'Attorney Responsible' : 'Person Responsible'} optional />
        <div>
          <StatelessSelect
            placeholder="Select staff responsible for lead..."
            options={assignableStaff.map((s) => ({ value: s.id, label: s.name }))}
            selectedOption={attorneyResponsibleId}
            onChange={(newOption) => onFieldValueChanged('attorneyResponsibleId', newOption?.value)}
            isClearable
            showDropdown={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-6">
          <FormLabel label="Referral Type" optional />
          <div>
            <StatelessSelect
              placeholder="Select referral type"
              options={t('referralTypes')
                .split(',')
                .map((v) => ({ label: v, value: v }))}
              selectedOption={referralType}
              onChange={(newType) => onFieldValueChanged('referralType', newType?.value)}
              isClearable
              showDropdown={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-12">
          <FormLabel label="Referrer" optional />
          <div>
            <ContactTypeahead
              contacts={contactOptions}
              onContactSelected={(newReferrer) => onFieldValueChanged('referrerId', newReferrer?.value)}
              selectedContactId={referrerId}
              showAdd
              onAdd={() => setShowAddReferrerContactForm(!showAddReferrerContactForm)}
              isAddActive={showAddReferrerContactForm}
              placeholder="Select referrer for lead..."
            />
            {showAddReferrerContactForm && (
              <div className="row">
                <div className="col-sm-12 form-group">
                  <AddContactInlineForm
                    onClose={() => setShowAddReferrerContactForm(false)}
                    onContactCreated={(newContactId) => {
                      setShowAddReferrerContactForm(!showAddReferrerContactForm);
                      onFieldValueChanged('referrerId', newContactId);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-6">
          <FormLabel label="Billing Type" optional />
          <div>
            <StatelessSelect
              placeholder="Select a billing type..."
              options={billingTypeOptions.filter((o) => !!o.value)}
              selectedOption={billingType}
              onChange={(newOption) => onFieldValueChanged('billingType', newOption?.value)}
              isClearable
              showDropdown={false}
            />
          </div>
        </div>
        {billingType && billingType !== billingTypes.NOT_BILLABLE && (
          <div className="form-group col-sm-6">
            <FormLabel label="Fee/Estimate" optional />
            {billingType !== billingTypes.CONTINGENCY_P && (
              <div>
                <CurrencyInput2
                  value={amount}
                  onChange={(e) => onFieldValueChanged('amount', e.target.value)}
                  useValue
                />
              </div>
            )}
            {billingType === billingTypes.CONTINGENCY_P && (
              <input
                className={classnames('form-control')}
                type="number"
                value={Number.isFinite(contingencyAmount) ? contingencyAmount : ''}
                onChange={(e) =>
                  onFieldValueChanged(
                    'contingencyAmount',
                    // Same functionality as Add Matter
                    e.target.value !== '' && !Number.isNaN(e.target.value)
                      ? Math.round(parseFloat(e.target.value) * 100) / 100
                      : undefined,
                  )
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AddLead.propTypes = {
  submitFailed: PropTypes.bool,
  errors: PropTypes.object,
  attorneyResponsibleId: PropTypes.string,
  referralType: PropTypes.string,
  billingType: PropTypes.string,
  referrerId: PropTypes.string,
  leadContactId: PropTypes.string,
  assignableStaff: PropTypes.array.isRequired,
  contactOptions: PropTypes.array.isRequired,
  matterTypeId: PropTypes.string,
  locationId: PropTypes.string,
  categoryId: PropTypes.string,
  amount: PropTypes.number,
  contingencyAmount: PropTypes.number,
  showAddReferrerContactForm: PropTypes.bool.isRequired,
  showAddLeadContactForm: PropTypes.bool.isRequired,
  // callbacks & functions
  onFieldValueChanged: PropTypes.func.isRequired,
  setShowAddReferrerContactForm: PropTypes.func.isRequired,
  setShowAddLeadContactForm: PropTypes.func.isRequired,
};

AddLead.defaultProps = {
  submitFailed: false,
  errors: {},
  attorneyResponsibleId: '',
  referralType: '',
  billingType: '',
  matterTypeId: '',
  referrerId: '',
  leadContactId: '',
  locationId: '',
  categoryId: '',
  amount: 0,
  contingencyAmount: 0,
};

export default AddLead;
