import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { generateContactSummaryOpdate, generateContactEntityOpdate } from '@sb-customer-management/redux/contacts';

// sbContactsMbService AKA contact summary
const { opdateCache: opdateContactsMbService } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

// sbSimpleContactMbService AKA ironically the complex contact entity cache (includes address)
const { opdateCache: opdateSimpleContactMbService } = optimisticUpdateFactory({
  ngCacheName: 'sbSimpleContactMbService',
  keyPath: 'entityId',
});

// sbCustomerBillingConfigurationService
const { opdateCache: opdateCustomerBillingConfigurationService } = optimisticUpdateFactory({
  ngCacheName: 'sbCustomerBillingConfigurationService',
  keyPath: 'entityId',
});

export const onCreateEditCompanyContact = ({ commandMessage, commandResult }) => {
  const companyEntity = {
    contactType: commandMessage.contactFields.contactType,
    ...commandMessage.contactFields.companyFields,
    ...commandMessage.contactFields.addressFields,
  };

  const { id: companyId } = commandResult.contact;

  const peopleWithBillingConfig = Object.values(companyEntity.additionalContactProps || {}).map((person, index) => {
    // The command ensures that people results arrive in the same order that they are sent in the command message.
    // This allows us to relate sent people to people info in the command result by index.
    const { id, customerBillingConfiguration } = commandResult.people[index];
    return {
      entity: {
        ...person,
        id,
      },
      customerBillingConfiguration,
    };
  });

  // sbContactsMbService opdates.
  const opdateSummaryEntity = generateContactSummaryOpdate({ id: companyId, contact: companyEntity });
  opdateSummaryEntity.people = peopleWithBillingConfig.map((personWithBillingConfig) =>
    generateContactSummaryOpdate({ id: personWithBillingConfig.entity.id, contact: personWithBillingConfig.entity }),
  );

  opdateContactsMbService({ optimisticEntities: [opdateSummaryEntity, ...opdateSummaryEntity.people] });

  // sbSimpleContactMbService opdates.
  const peopleEntities = peopleWithBillingConfig.map((personWithBillingConfig) => personWithBillingConfig.entity);
  const opdateEntity = generateContactEntityOpdate({
    id: companyId,
    contact: companyEntity,
    peopleArr: peopleEntities,
  });

  opdateSimpleContactMbService({ optimisticEntities: [opdateEntity] });

  // sbCustomerBillingConfigurationService opdates.
  if (!commandMessage.isUtbmsEnabled) {
    return;
  }

  const customerBillingConfigOpdates = peopleWithBillingConfig.reduce(
    (acc, { entity, customerBillingConfiguration }) => {
      if (customerBillingConfiguration && entity.ledesClientId) {
        acc.push({
          entityId: entity.id,
          ledesClientId: entity.ledesClientId,
          versionId: customerBillingConfiguration.versionId,
        });
      }

      return acc;
    },
    [],
  );

  opdateCustomerBillingConfigurationService({ optimisticEntities: [customerBillingConfigOpdates] });
};
