import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './InputWithAddon.module.scss';

const InputWithAddon = ({ value, className, onChange, placeholder, icon }) => {
  const inputRef = useRef(null);
  const [hasFocus, setHasFocus] = useState(false);

  const iconClasses = classnames(Styles.icon, hasFocus && Styles.iconFocus, 'input-group-addon');

  return (
    <div className={classnames(Styles.container, 'input-group', className)}>
      <input
        type="text"
        ref={inputRef}
        className={classnames(Styles.input, 'form-control')}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
      />
      <span onClick={() => inputRef.current.focus()} className={iconClasses}>
        <i className={icon} />
      </span>
    </div>
  );
};

InputWithAddon.displayName = 'InputWithAddon';

InputWithAddon.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

InputWithAddon.defaultProps = {
  className: undefined,
  placeholder: undefined,
};

export default InputWithAddon;
