const uuid = require('@sb-itops/uuid');

let subscriptions = [];

/**
 * Subscribes the passed function to command dispatched events. Registered subscription functions
 * will be called in the order they are registered.
 * 
 * @param {Object} params
 * @param {function} params.onCommandDispatched
 * @return {function} Function which, when called, will unsubscribe the event handler.
 */
const subscribeToCommandDispatchEvents = ({ onCommandDispatched }) => {
  if (typeof onCommandDispatched !== 'function') {
    throw new Error('Failed to create subscription: the passed onCommandDispatched parameter must be a valid function');
  }

  const subscriptionId = uuid();
  
  subscriptions.push({
    id: subscriptionId,
    onCommandDispatched,
  });

  const unsubscribe = () => {
    const subscriptionIndex = subscriptions.findIndex((subscription) => subscription.id === subscriptionId);
    subscriptions.splice(subscriptionIndex, 1);
  }

  return unsubscribe;
};

/**
 * Broadcasts a command dispatch event to all subscribed listeners.
 * 
 * @param {Object} params
 * @param {string} params.commandType The type of web command that was dispatched.
 * @param {Object} params.commandMessage The web command message data that was used in the dispatch.
 * @param {function} params.commandResult The result of the web command dispatch.
 */
const broadcastCommandDispatchEvent = ({ commandType, commandMessage, commandResult }) => {
  if (!commandType || typeof commandType !== 'string') {
    throw new Error('Failed to broadcast command dispatch event: the passed commandType must be a non-zero length string');
  }

  if (!commandMessage || typeof commandMessage !== 'object') {
    throw new Error('Failed to broadcast command dispatch event: the passed commandMessage must be a non-null object');
  }

  subscriptions.forEach((subscription) => {
    subscription.onCommandDispatched({ commandType, commandMessage, commandResult });
  });
};

/**
 * Unsubscribes all subscribed listeners.
 */
const clearAllCommandDispatchSubscriptions = () => {
  subscriptions = [];
};

module.exports = {
  subscribeToCommandDispatchEvents,
  broadcastCommandDispatchEvent,
  clearAllCommandDispatchSubscriptions,
};