import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from '@sb-itops/react';
import { ContactDisplay2 } from '@sb-customer-management/react';

import { PageNav, InvoiceStatementDebugEntities, InvoiceStatementSummaryPanel } from 'web/components';
import { InvoiceStatementActionBar, PaymentTable } from 'web/react-redux';

import Styles from './BillingViewInvoiceStatementPaymentsRoute.module.scss';

export const BillingViewInvoiceStatementPaymentsRoute = memo(
  ({
    invoiceStatementLoading,
    invoiceStatementId,
    invoiceStatementNumber,
    debtor,
    invoices,
    payments,
    previewUrl,
    invoiceStatementStatus,
    invoiceStatementLastUpdated,
    onClickLink,
    pageNavItems,
    // For Angular payment-view-modal used on PaymentTable
    $uibModal,
    $state,
  }) => {
    const { t } = useTranslation();
    return (
      <div className={Styles.billingViewInvoiceStatementPdfRoute}>
        <InvoiceStatementDebugEntities invoiceStatementId={invoiceStatementId} debtor={debtor} invoices={invoices} />

        <div className={Styles.pageTitle}>
          <span>
            {t('clientStatement')} #{invoiceStatementNumber || ''}
          </span>
          <ContactDisplay2 contact={debtor} onClickLink={onClickLink} asLink />
        </div>

        <div className="sb-tab-header">
          <PageNav
            navItems={pageNavItems}
            navCurrentPath="invoiceStatementPayments"
            onGotoOrReset={(path) => onClickLink({ type: path, id: invoiceStatementId })}
            scope="invoice-statement"
          />
        </div>
        <div className="master-detail-panel">
          <div className={classnames('panel-body', Styles.panelLayout)}>
            <InvoiceStatementActionBar
              invoiceStatementId={invoiceStatementId}
              invoiceStatementNumber={invoiceStatementNumber}
              invoiceStatementStatus={invoiceStatementStatus}
              invoiceStatementLoading={invoiceStatementLoading}
              previewUrl={previewUrl}
            />

            <div className={Styles.partialSurround}>
              <div className={Styles.partialContent}>
                <PaymentTable payments={payments} onClickLink={onClickLink} $uibModal={$uibModal} $state={$state} />
              </div>
              <div className={Styles.sidePanel}>
                <InvoiceStatementSummaryPanel
                  debtor={debtor}
                  invoices={invoices}
                  invoiceStatementStatus={invoiceStatementStatus}
                  invoiceStatementLastUpdated={invoiceStatementLastUpdated}
                  onClickLink={onClickLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

BillingViewInvoiceStatementPaymentsRoute.displayName = 'BillingViewInvoiceStatementPaymentsRoute';
BillingViewInvoiceStatementPaymentsRoute.propTypes = {
  invoiceStatementLoading: PropTypes.bool.isRequired,
  invoiceStatementId: PropTypes.string.isRequired,
  invoiceStatementNumber: PropTypes.string,
  debtor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    displayNameFirstLast: PropTypes.string,
    trustBalance: PropTypes.number,
  }),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.number.isRequired,
      totals: PropTypes.shape({
        interest: PropTypes.number.isRequired,
        totalExcInterest: PropTypes.number.isRequired,
        waived: PropTypes.number.isRequired,
        paidByCredit: PropTypes.number.isRequired,
        paid: PropTypes.number.isRequired,
        unpaid: PropTypes.number.isRequired,
      }),
    }),
  ),
  previewUrl: PropTypes.string,
  invoiceStatementStatus: PropTypes.string,
  invoiceStatementLastUpdated: PropTypes.string,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      effectiveDate: PropTypes.number.isRequired,
      reference: PropTypes.string,
      note: PropTypes.string,
      payor: PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string,
        displayNameFirstLast: PropTypes.string,
        trustBalance: PropTypes.number,
      }).isRequired,
      source: PropTypes.string,
      amount: PropTypes.number,
      balance: PropTypes.number,
    }),
  ),
  onClickLink: PropTypes.func.isRequired,
  pageNavItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      className: PropTypes.string,
      order: PropTypes.number.isRequired,
    }),
  ).isRequired,
  $uibModal: PropTypes.any.isRequired,
  $state: PropTypes.any.isRequired,
};

BillingViewInvoiceStatementPaymentsRoute.defaultProps = {
  invoiceStatementNumber: undefined,
  debtor: undefined,
  invoices: [],
  previewUrl: undefined,
  invoiceStatementStatus: undefined,
  invoiceStatementLastUpdated: undefined,
  payments: [],
};
