'use strict';

import uuid from '@sb-itops/uuid';

angular.module('@sb-itops/services').service('sbBuildVersionService', function (sbLoggerService, sbGenericEndpointService) {
  const that = this;
  const log = sbLoggerService.getLogger('sbBuildVersionService');

  that.buildVersionP = buildVersionP;

  function buildVersionP() {
    return sbGenericEndpointService.postPayloadP('version', uuid())
      .then((response) => {
        log.info(`Server responded with build version: ${response.version}`);
        return response.version;
      })
      .catch((err) => {
        log.warn('Problem getting build version', err);
        throw err;
      });
  }
});
