import { Spinner } from '@sb-itops/react';
import { MattersDisplayFromMatters } from '@sb-matter-management/react';
import React from 'react';
import classNames from 'classnames';
import iconMatter from 'web/assets/icon-matter.svg';
import iconLead from 'web/assets/icon-lead.svg';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';

export const RecentlyViewedWidget = ({ isEditing, matters, onClickLink, loading, onChange, onRemove, settings }) => (
  <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
    <WidgetTitle
      isEditing={isEditing}
      onClick={() => onClickLink({ type: 'matterList' })}
      onChange={onChange}
      onRemove={onRemove}
      settings={settings}
    />
    {loading ? (
      <div className={WidgetStyles.loading}>
        <Spinner small />
      </div>
    ) : (
      <div className={WidgetStyles.content}>
        {matters.length === 0 && 'No recent matters'}
        {matters.map((matter) => (
          <div
            key={matter.id}
            className={WidgetStyles.itemCard}
            onClick={() => onClickLink({ type: 'matter', id: matter.id })}
          >
            <div>
              <img
                src={matter.isLead ? iconLead : iconMatter}
                alt="matter icon"
                className={`${WidgetStyles.largeIcon}`}
              />
            </div>
            <div className={WidgetStyles.textBox}>
              <div className={classNames(WidgetStyles.overflowText)}>{matter.clientDisplayName}</div>
              <MattersDisplayFromMatters
                onClickLink={onClickLink}
                className={WidgetStyles.blueGray}
                showClient={false}
                matters={[matter]}
              />
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);
