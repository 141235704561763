import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Cent } from '@sb-itops/money';
import { withTranslation } from '../localisation';

const dollarField = (
  <div className="input-group-addon">
    <i className={classnames('fa-currency-icon', 'fa', 'fa-icon')} />
  </div>
);

/**
 * Use CurrencyDisplay when you want to represent a currency value, without allowing the user to change it.
 */
const CurrencyDisplay = ({ hideDollar, styleClass, amount, formatAmount, asInput, hasError, t }) => {
  if (asInput) {
    let amountDollars;
    if (formatAmount) {
      amountDollars = t('cents', { val: amount, hideCurrencySymbol: false });
    } else {
      amountDollars = Number.isFinite(amount) ? new Cent(amount).toString() : '0.00';
    }
    const groupClasses = classnames('input-group', 'currency-input', hasError && 'has-error', styleClass);
    return (
      <div className={groupClasses}>
        {!hideDollar && dollarField}
        <input type="text" className={classnames('form-control', 'currency')} value={amountDollars} disabled />
      </div>
    );
  }

  const groupClasses = classnames(styleClass);
  return <span className={groupClasses}>{t('cents', { val: amount, hideCurrencySymbol: hideDollar })}</span>;
};

CurrencyDisplay.displayName = 'CurrencyDisplay';

CurrencyDisplay.propTypes = {
  hideDollar: PropTypes.bool,
  styleClass: PropTypes.string,
  amount: PropTypes.number,
  asInput: PropTypes.bool,
  hasError: PropTypes.bool,
  region: PropTypes.string,
  formatAmount: PropTypes.bool,
  t: PropTypes.func.isRequired, // withTranslation
};

CurrencyDisplay.defaultProps = {
  asInput: true,
  hideDollar: false,
  styleClass: undefined,
  hasError: false,
  amount: 0,
  region: undefined,
  formatAmount: false,
};

export default withTranslation()(CurrencyDisplay);
