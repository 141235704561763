angular.module('sb.billing.webapp').directive('sbActionDummy', function (sbLoggerService) {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      var log = sbLoggerService.getLogger('sbActionDummy');

      ctrl.group.setAction(dummy);
      
      function dummy(params) {
        log.warn('received params: ', params);
        return Promise.resolve(params);
      }

    }
  };
});
