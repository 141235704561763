'use strict';

/**
 * @typedef {object} DiscountType
 * @property {number} FIXED 0
 * @property {number} PERCENTAGE 1
 */
const discountType = Object.freeze({
  FIXED: 0,
  PERCENTAGE: 1,
});

module.exports = { discountType };
