import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { WarningList } from '@sb-itops/react/warning-list';
import { ACCOUNT_TYPE } from '@sb-billing/redux/bank-account';
import { featureActive } from '@sb-itops/feature';
import { useTranslation } from '@sb-itops/react';

const getControlledMoneyAccountWarning = ({ matterBankAccountBalance, amount, t }) => {
  const currentBalance = matterBankAccountBalance || 0;
  const newBalance = currentBalance - amount;
  const willOverdraw = newBalance < 0;

  if (!willOverdraw) {
    return undefined;
  }

  const newBalanceDisplay = t('cents', { val: newBalance });

  return `Warning: As of the transaction date, the ${t(
    'controlledMoneyAccount',
  ).toLowerCase()} will be overdrawn to a balance of ${newBalanceDisplay}`;
};

const getTrustAccountWarning = ({
  isStatutoryDepositMatter,
  matterBankAccountBalance,
  hasProtectedFunds,
  amount,
  t,
}) => {
  if (isStatutoryDepositMatter) {
    return undefined;
  }

  // For trust matterBankAccountBalance should be balance at specific date
  const newBalance = matterBankAccountBalance - amount;
  const willOverdraw = newBalance < 0;

  if (!willOverdraw) {
    return undefined;
  }

  const newBalanceDisplay = t('cents', { val: newBalance });

  return hasProtectedFunds && featureActive('BB-8671')
    ? `There are insufficient available funds - please unprotect funds before use or overdraw.`
    : `Warning: As of the transaction date, the ${t(
        'trustAccount',
      ).toLowerCase()} will be overdrawn to a balance of ${newBalanceDisplay}`;
};

const getOperatingAccountWarning = () => undefined;

const warningHandler = {
  [ACCOUNT_TYPE.controlled_money.toUpperCase()]: getControlledMoneyAccountWarning,
  [ACCOUNT_TYPE.trust.toUpperCase()]: getTrustAccountWarning,
  [ACCOUNT_TYPE.operating.toUpperCase()]: getOperatingAccountWarning,
};

const hooks = () => ({
  useWarnings: ({ accountType, matterBankAccountBalance, isStatutoryDepositMatter, hasProtectedFunds, amount }) => {
    const { t } = useTranslation();
    const allowOverdraw = hasFacet(facets.allowOverdraw);

    if (!accountType || !allowOverdraw || !Number.isFinite(matterBankAccountBalance)) {
      return { list: undefined };
    }

    const handler = warningHandler[accountType.toUpperCase()];
    const warningText =
      handler && handler({ isStatutoryDepositMatter, matterBankAccountBalance, hasProtectedFunds, amount, t });

    if (!warningText) {
      return { list: undefined };
    }

    return { list: [{ text: warningText }] };
  },
});

const OverdrawWarningContainer = composeHooks(hooks)(WarningList);

OverdrawWarningContainer.propTypes = {
  accountType: PropTypes.string.isRequired,
  amount: PropTypes.number,
  matterBankAccountBalance: PropTypes.number,
  isStatutoryDepositMatter: PropTypes.bool,
  hasProtectedFunds: PropTypes.bool,
};

OverdrawWarningContainer.defaultProps = {
  amount: 0,
  matterBalanceAtDate: 0,
  isStatutoryDepositMatter: false,
  hasProtectedFunds: false,
};

export default OverdrawWarningContainer;
