/* eslint-env browser */
'use strict';

const _ = require('lodash');
const logFactory = require('@sb-itops/fe-logger');
const { getRegion: itOpsGetRegion, regionType: itOpsRegionType } = require('@sb-itops/region');

/*
 * Environment constants and lookup
 */
const envType = {
  DEV: 'DEV',
  RC: 'RC',
  QA: 'QA',
  PRODUCTION: 'PRODUCTION',
  LOCAL: 'LOCAL'
};

const log = logFactory.getLogger('@sb-itops/environment-config');
log.setLogLevel('info');

/*
 * Environment constants and lookup
 */
const envConfig = {
  [envType.LOCAL]: {
    googleApiKey: 'AIzaSyBrk31q94JUEe8n4HB_vMM6vr3tNxL2uLM',
    googleTagManagerId: 'GTM-W4X2TQ6',
  },
  [envType.DEV]: {
    googleApiKey: 'AIzaSyBqM3ghEZf_z1Zfgke-2_QSdJ8J2K0gpEA',
    analyticsEnabled: true,
    googleTagManagerId: 'GTM-W4X2TQ6',
  },
  [envType.RC]: {
    googleApiKey: 'AIzaSyBqM3ghEZf_z1Zfgke-2_QSdJ8J2K0gpEA',
    googleTagManagerId: 'GTM-W4X2TQ6',
  },
  [envType.QA]: {
    googleApiKey: 'AIzaSyBqM3ghEZf_z1Zfgke-2_QSdJ8J2K0gpEA',
    analyticsEnabled: true,
    googleTagManagerId: 'GTM-W4X2TQ6',
  },
  [envType.PRODUCTION]: {
    googleApiKey: 'AIzaSyBqM3ghEZf_z1Zfgke-2_QSdJ8J2K0gpEA',
    analyticsEnabled: true,
    googleTagManagerId: 'GTM-W4X2TQ6',
  }
};

const AU = {
  id: itOpsRegionType.AU,
  [envType.LOCAL]: {
    dataEnvSpecific: {
      dev: {
        endpointHosts: {
          dotNetHost: 'https://devtoolbar.smokeball.com.au',
          notificationServer: 'wss://devnotifier-aus.smokeball.com',
        },
        stripe: {
          corporatePublicKey: 'pk_test_51K8yQ7BRss8mRNU8CJOfGuEr5HktivCvtN6iOvF9TjBeDa1aEwSGLku2Uh8QS2KDgZSNVhZW644jJZ0RMQFjPCez00g5IzYQIE',
        },
      },
      staging: {
        endpointHosts: {
          dotNetHost: 'https://staging.smokeball.com.au',
          notificationServer: 'wss://testnotifier-aus.smokeball.com',
        },
        stripe: {
          corporatePublicKey: 'pk_test_51K8yQ7BRss8mRNU8CJOfGuEr5HktivCvtN6iOvF9TjBeDa1aEwSGLku2Uh8QS2KDgZSNVhZW644jJZ0RMQFjPCez00g5IzYQIE',
        },
      },
    },
    common: {
      endpointHosts: {
        restApi: 'http://localhost:9001',
      },
      stripe: {
        onboardingReturnUrl: 'http://localhost:9001/?AU&stripeAccountId='
      },
      subscription: {
        updatePaymentMethodUrl: 'http://localhost:4007/?AU#/add-or-update-payment-method/',
        serviceUrl: 'http://localhost:3003',
      },
      security: {
        mfaSetupUrl: 'https://stagingsecurity.smokeball.com.au/MFA?BrandName='
      }
    },
  },
  [envType.DEV]: {
    endpointHosts: {
      restApi: 'https://dev-aus.boostbilling.com',
      dotNetHost: 'https://devtoolbar.smokeball.com.au',
      notificationServer: 'wss://devnotifier-aus.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_test_51K8yQ7BRss8mRNU8CJOfGuEr5HktivCvtN6iOvF9TjBeDa1aEwSGLku2Uh8QS2KDgZSNVhZW644jJZ0RMQFjPCez00g5IzYQIE',
      onboardingReturnUrl: 'https://dev-app.smokeball.com.au/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://dev-join.smokeball.com.au/#/add-or-update-payment-method/',
      serviceUrl: 'https://dev-join.smokeball.com.au',
    },
    security: {
      mfaSetupUrl: 'https://devsecurity.smokeball.com.au/MFA?BrandName='
    }
  },
  [envType.RC]: {
    endpointHosts: {
      restApi: 'https://rc-aus.boostbilling.com',
      dotNetHost: 'https://staging.smokeball.com.au',
      notificationServer: 'wss://testnotifier-aus.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_test_51K8yQ7BRss8mRNU8CJOfGuEr5HktivCvtN6iOvF9TjBeDa1aEwSGLku2Uh8QS2KDgZSNVhZW644jJZ0RMQFjPCez00g5IzYQIE',
      onboardingReturnUrl: 'https://rc-app.smokeball.com.au/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://rc-join.smokeball.com.au/#/add-or-update-payment-method/',
      serviceUrl: 'https://rc-join.smokeball.com.au',
    },
    security: {
      mfaSetupUrl: 'https://stagingsecurity.smokeball.com.au/MFA?BrandName='
    }
  },
  [envType.QA]: {
    endpointHosts: {
      restApi: 'https://qa-aus.boostbilling.com',
      dotNetHost: 'https://toolbar.smokeball.com.au',
      notificationServer: 'wss://notifier-aus.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_test_51K6StWLBbhFWkS6xptqeTamZ2npNBN29GKyeF50wxEquXptryq5UO5zLARPY0sqosz2wnkL2wrmPiZgxMH6s3azH000jDM3OEm',
      onboardingReturnUrl: 'https://qa-app.smokeball.com.au/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://qa-join.smokeball.com.au/#/add-or-update-payment-method/',
      serviceUrl: 'https://qa-join.smokeball.com.au',
    },
    security: {
      mfaSetupUrl: 'https://security.smokeball.com.au/MFA?BrandName='
    }
  },
  [envType.PRODUCTION]: {
    segmentTrackingId: 'mofGs9QqvqkOttt60XPJgg1AzOOGPBTD',
    endpointHosts: {
      restApi: 'https://live-aus.boostbilling.com',
      dotNetHost: 'https://toolbar.smokeball.com.au',
      notificationServer: 'wss://notifier-aus.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_live_51K6StWLBbhFWkS6xazWqdnglU8WL7dNyexNf6ELZL3sxk8VAI3AEzOPtGfw9eNGxE8se2pyZW9TCnP8gTkLozS00004MiJqIDR',
      onboardingReturnUrl: 'https://app.smokeball.com.au/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://join.smokeball.com.au/#/add-or-update-payment-method/',
      serviceUrl: 'https://join.smokeball.com.au',
    },
    security: {
      mfaSetupUrl: 'https://security.smokeball.com.au/MFA?BrandName='
    }
  }
};

const GB = {
  id: itOpsRegionType.GB,
  [envType.LOCAL]: {
    dataEnvSpecific: {
      dev: {
        endpointHosts: {
          dotNetHost: 'https://devtoolbar.smokeball.co.uk',
          notificationServer: 'wss://devnotifier-lon.smokeball.com',
        },
        stripe: {
          corporatePublicKey: 'pk_test_51N7xduCdego54a24ly5coWBkDghNca83FY4oOr5RrHI0qQaeHYy5FBOLU0xdNEpLz2awmPcSE06Eq37e0Jq02eeb00ymOafLbk',
        },
      },
      staging: {
        endpointHosts: {
          dotNetHost: 'https://staging.smokeball.co.uk',
          notificationServer: 'wss://testnotifier-lon.smokeball.com',
        },
        stripe: {
          corporatePublicKey: 'pk_test_51N7xduCdego54a24ly5coWBkDghNca83FY4oOr5RrHI0qQaeHYy5FBOLU0xdNEpLz2awmPcSE06Eq37e0Jq02eeb00ymOafLbk',
        },
      },
    },
    common: {
      endpointHosts: {
        restApi: 'http://localhost:9001',
      },
      stripe: {
        onboardingReturnUrl: 'http://localhost:9001/?GB&stripeAccountId='
      },
      subscription: {
        updatePaymentMethodUrl: 'http://localhost:4007/?GB#/add-or-update-payment-method/',
        serviceUrl: 'http://localhost:3003',
      },
      security: {
        mfaSetupUrl: 'https://stagingsecurity.smokeball.co.uk/MFA?BrandName='
      }
    },
  },
  [envType.DEV]: {
    endpointHosts: {
      restApi: 'https://dev-lon.boostbilling.com',
      dotNetHost: 'https://staging.smokeball.co.uk',
      notificationServer: 'wss://testnotifier-lon.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_test_51N7xduCdego54a24ly5coWBkDghNca83FY4oOr5RrHI0qQaeHYy5FBOLU0xdNEpLz2awmPcSE06Eq37e0Jq02eeb00ymOafLbk',
      onboardingReturnUrl: 'https://dev-app.smokeball.co.uk/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://dev-join.smokeball.co.uk/#/add-or-update-payment-method/',
      serviceUrl: 'https://dev-join.smokeball.co.uk',
    },
    security: {
      mfaSetupUrl: 'https://devsecurity.smokeball.co.uk/MFA?BrandName='
    }
  },
  [envType.RC]: {
    endpointHosts: {
      restApi: 'https://rc-lon.boostbilling.com',
      dotNetHost: 'https://staging.smokeball.co.uk',
      notificationServer: 'wss://testnotifier-lon.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_test_51N7xduCdego54a24ly5coWBkDghNca83FY4oOr5RrHI0qQaeHYy5FBOLU0xdNEpLz2awmPcSE06Eq37e0Jq02eeb00ymOafLbk',
      onboardingReturnUrl: 'https://rc-app.smokeball.co.uk/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://rc-join.smokeball.co.uk/#/add-or-update-payment-method/',
      serviceUrl: 'https://rc-join.smokeball.co.uk',
    },
    security: {
      mfaSetupUrl: 'https://stagingsecurity.smokeball.co.uk/MFA?BrandName='
    }
  },
  [envType.QA]: {
    endpointHosts: {
      restApi: 'https://qa-lon.boostbilling.com',
      dotNetHost: 'https://toolbar.smokeball.co.uk',
      notificationServer: 'wss://notifier-lon.smokeball.com',
    },
    stripe: {
      corporatePublicKey: 'pk_test_51Mwe8EEwgKeRG92Wh05a1D0aO24k8ezyrqIc5HawckQoyNFcSSu1fh46k2VemmxfrxWP0BMXPvsknIX1yip8ZuJf0032ggnMZ0',
      onboardingReturnUrl: 'https://qa-app.smokeball.co.uk/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://qa-join.smokeball.co.uk/#/add-or-update-payment-method/',
      serviceUrl: 'https://qa-join.smokeball.co.uk',
    },
    security: {
      mfaSetupUrl: 'https://security.smokeball.co.uk/MFA?BrandName='
    }
  },
  [envType.PRODUCTION]: {
    segmentTrackingId: '', // TODO
    endpointHosts: {
      restApi: 'https://live-lon.boostbilling.com',
      dotNetHost: 'https://toolbar.smokeball.co.uk',
      notificationServer: 'wss://notifier-lon.smokeball.com',
    },
    stripe: {
      corporatePublicKey: '', // TODO
      onboardingReturnUrl: 'https://app.smokeball.co.uk/?stripeAccountId=',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://join.smokeball.co.uk/#/add-or-update-payment-method/',
      serviceUrl: 'https://join.smokeball.co.uk',
    },
    security: {
      mfaSetupUrl: 'https://security.smokeball.co.uk/MFA?BrandName='
    }
  }
};

const US = {
  id: itOpsRegionType.US,
  [envType.LOCAL]: {
    dataEnvSpecific: {
      dev: {
        endpointHosts: {
          dotNetHost: 'https://devtoolbar.smokeball.com',
          notificationServer: 'wss://devnotifier-nova.smokeball.com',
        },
        stripe: {
          corporatePublicKey: 'pk_test_51KGtCoFexcKC0s99sGx6m1JZdX6FnyYo6cfPDOq20WBSlNERF9UWbtuqTf1uJKgn2I6YHmHhpcf0HPDgvhTtO2QE00JREN7Ik5', // This is currently the AU key
        },
      },
      staging: {
        endpointHosts: {
          dotNetHost: 'https://staging.smokeball.com',
          notificationServer: 'wss://testnotifier-nova.smokeball.com',
        },
        stripe: {
          corporatePublicKey: 'pk_test_51KGtCoFexcKC0s99sGx6m1JZdX6FnyYo6cfPDOq20WBSlNERF9UWbtuqTf1uJKgn2I6YHmHhpcf0HPDgvhTtO2QE00JREN7Ik5',  // This is currently the AU key
        },
      },
    },
    common: {
      endpointHosts: {
        restApi: 'http://localhost:9001',
      },
      lawPay: {
        redirectUrl: 'http://localhost:9001',
        clientId: '7f502a26c24546e9060c8140065218e1817a2c29db46558585c0b220ca3d9683'
      },
      subscription: {
        updatePaymentMethodUrl: 'http://localhost:4007/add-or-update-payment-method/',
        serviceUrl: 'http://localhost:3003',
      },
      security: {
        mfaSetupUrl: 'https://stagingsecurity.smokeball.com/MFA?BrandName='
      }
    },
  },
  [envType.DEV]: {
    endpointHosts: {
      restApi: 'https://dev-nova.boostbilling.com',
      dotNetHost: 'https://devtoolbar.smokeball.com',
      notificationServer: 'wss://devnotifier-nova.smokeball.com',
    },
    lawPay: {
      redirectUrl: 'https://dev-app.smokeball.com',
      clientId: 'TODO'
    },
    stripe: {
      corporatePublicKey: 'pk_test_51KGtCoFexcKC0s99sGx6m1JZdX6FnyYo6cfPDOq20WBSlNERF9UWbtuqTf1uJKgn2I6YHmHhpcf0HPDgvhTtO2QE00JREN7Ik5',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://dev-join.smokeball.com/#/add-or-update-payment-method/',
      serviceUrl: 'https://dev-join.smokeball.com',
    },
    security: {
      mfaSetupUrl: 'https://devsecurity.smokeball.com/MFA?BrandName='
    }
  },
  [envType.RC]: {
    endpointHosts: {
      restApi: 'https://rc-nova.boostbilling.com',
      dotNetHost: 'https://staging.smokeball.com',
      notificationServer: 'wss://testnotifier-nova.smokeball.com',
    },
    lawPay: {
      redirectUrl: 'https://rc-app.smokeball.com',
      clientId: '9ba261b2bb8d83a68cadab499f8e38261676555d0f6276ef111fbfb6884b2cca'
    },
    stripe: {
      corporatePublicKey: 'pk_test_51KGtCoFexcKC0s99sGx6m1JZdX6FnyYo6cfPDOq20WBSlNERF9UWbtuqTf1uJKgn2I6YHmHhpcf0HPDgvhTtO2QE00JREN7Ik5',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://rc-join.smokeball.com/#/add-or-update-payment-method/',
      serviceUrl: 'https://rc-join.smokeball.com',
    },
    security: {
      mfaSetupUrl: 'https://stagingsecurity.smokeball.com/MFA?BrandName='
    }
  },
  [envType.QA]: {
    endpointHosts: {
      restApi: 'https://qa-nova.boostbilling.com',
      dotNetHost: 'https://toolbar.smokeball.com',
      notificationServer: 'wss://notifier-nova.smokeball.com',
    },
    lawPay: {
      redirectUrl: 'https://qa-app.smokeball.com',
      clientId: 'd5a875fec10b0e00718a45e0b127da339a3edb5768413b138fae052c4df60326'
    },
    stripe: {
      corporatePublicKey: 'pk_test_MO5VzoMKHxdgexEXPijEK5kg00LXnGmD4B',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://qa-join.smokeball.com/#/add-or-update-payment-method/',
      serviceUrl: 'https://qa-join.smokeball.com',
    },
    security: {
      mfaSetupUrl: 'https://security.smokeball.com/MFA?BrandName='
    }
  },
  [envType.PRODUCTION]: {
    segmentTrackingId: 'h9JZISJrA5AhxpMHmoHlDI3FvIemU5vV',
    endpointHosts: {
      restApi: 'https://live-nova.boostbilling.com',
      dotNetHost: 'https://toolbar.smokeball.com',
      notificationServer: 'wss://notifier-nova.smokeball.com',
    },
    lawPay: {
      redirectUrl: 'https://app.smokeball.com',
      clientId: '9acd31cfca3ae2f2176d255a9450bbfe4239871510e41a6c1e38c1f661a3cbf4'
    },
    stripe: {
      corporatePublicKey: 'pk_live_QJrukZ4ZCPhM9UOs497UKV5E00pu3PBECU',
    },
    subscription: {
      updatePaymentMethodUrl: 'https://join.smokeball.com/#/add-or-update-payment-method/',
      serviceUrl: 'https://join.smokeball.com',
    },
    security: {
      mfaSetupUrl: 'https://security.smokeball.com/MFA?BrandName='
    }
  }
};

const regionConfig = {
  [AU.id]: AU,
  [GB.id]: GB,
  [US.id]: US,
};

/*
 * Functionality require to fulfil the sbEnvironmentConfigService
 */

let frontendEnv;
let backendEnv;
let region;
let localDataEnv; // Controls which data environment is being used locally: dev or staging.
let config = {};

const envByHostName = {
  'local': envType.LOCAL,
  'au.billinglocal': envType.LOCAL,
  'us.billinglocal': envType.LOCAL,
  'dev-': envType.DEV,
  'rc-': envType.RC,
  'rcapp': envType.RC,
  'qa-': envType.QA,
  'app.smokeball': envType.PRODUCTION,
};


const initialise = (forcedFrontendEnv, forcedBackendEnv, forcedRegion) => {
  deriveEnvironment(forcedFrontendEnv, forcedBackendEnv);
  region = itOpsGetRegion(forcedRegion);

  if (frontendEnv === envType.LOCAL) {
    log.info(`APP_ENV set to ${frontendEnv}, DATA_ENV set to ${localDataEnv}, region set to ${region}`);

    config = _.merge(
      {},
      envConfig[backendEnv],
      regionConfig[region][envType.LOCAL].common,
      regionConfig[region][envType.LOCAL].dataEnvSpecific[localDataEnv],
    );
  } else {
    log.info(`APP_ENV set to ${frontendEnv}, region set to ${region}`);

    config = _.merge(
      {},
      envConfig[backendEnv],
      regionConfig[region][backendEnv]
    );
  }
};

const deriveEnvironment = (forcedFrontendEnv, forcedBackendEnv) => {
  // Force feature used by unit tests.
  if (forcedFrontendEnv || forcedBackendEnv) {
    frontendEnv = forcedFrontendEnv || forcedBackendEnv;
    backendEnv = forcedBackendEnv || forcedFrontendEnv;
    return;
  }

  // Determine the frontend environment based on hostname.
  const hostName = window.location.hostname;
  frontendEnv = _.find(
    envByHostName,
    (env, prefix) => _.startsWith(hostName, prefix)
  );

  if (!frontendEnv) {
    frontendEnv = envType.LOCAL;
  }

  if (frontendEnv === envType.LOCAL) {
    const validLocalDataEnvs = ['dev', 'DEV', 'staging', 'STAGING'];
    const urlParams = new URLSearchParams(window.location.search);
    localDataEnv = validLocalDataEnvs.find((dataEnv) => urlParams.has(dataEnv));
    localDataEnv = localDataEnv ? localDataEnv.toLowerCase() : 'staging';
  }

  backendEnv = frontendEnv;
};

const getFrontendEnv = () => frontendEnv;

const getBackendEnv = () => frontendEnv;

const getRestApiUrl = (opts) => {
  if (frontendEnv === backendEnv && !_.get(opts, 'forceFullQualification')) {
    return '';
  }

  return _.get(config, 'endpointHosts.restApi');
};

const getDotNetHostUrl = () => _.get(config, 'endpointHosts.dotNetHost');

const getNotificationServerHostUrl = () => _.get(config, 'endpointHosts.notificationServer');

const getGoogleApiKey = () => _.get(config, 'googleApiKey');

const getGoogleTagManagerId = () => _.get(config, 'googleTagManagerId');

const getStripeCorporatePublicKey = () => _.get(config, 'stripe.corporatePublicKey');

const getStripeOnboardingReturnUrl = () => _.get(config, 'stripe.onboardingReturnUrl');

const getLawPayClientId = () =>  _.get(config, 'lawPay.clientId');

const getLawPayRedirectUrl = () => _.get(config, 'lawPay.redirectUrl');

const getMfaSetupUrl = () => _.get(config, 'security.mfaSetupUrl');

const getSubscriptionUpdatePaymentMethodUrl = ({ accountId }) => {
  const updatePaymentMethodUrl = _.get(config, 'subscription.updatePaymentMethodUrl');

  if (!accountId || !updatePaymentMethodUrl) {
    return '';
  }

  return `${updatePaymentMethodUrl}${accountId}`;
}

const getSubscriptionServiceUrl = () => {
  return _.get(config, 'subscription.serviceUrl');
};

const getTrackingId = () => _.get(config, 'segmentTrackingId');

const getAnalyticsEnabled = () => _.get(config, 'analyticsEnabled', false);

const siteParams = {
  [itOpsRegionType.GB]: {
    sbSite: `https://www.smokeball.co.uk`,
    sbDomain: 'smokeball.co.uk',
  },
  [itOpsRegionType.AU]: {
    sbSite: `https://www.smokeball.com.au`,
    sbDomain: 'smokeball.com.au',
  },
  [itOpsRegionType.US]: {
    sbSite: `https://www.smokeball.com`,
    sbDomain: 'smokeball.com',
  },
};

const getSbSiteParams = () => siteParams[itOpsGetRegion()];

const forgotPasswordUrl = {
  [itOpsRegionType.GB]: 'https://security.smokeball.co.uk/Identity/Account/ForgotPassword',
  [itOpsRegionType.AU]: 'https://security.smokeball.com.au/Identity/Account/ForgotPassword',
  [itOpsRegionType.US]: 'https://security.smokeball.com/Identity/Account/ForgotPassword',
};

const getForgotPasswordUrl = () => forgotPasswordUrl[itOpsGetRegion()];

const supportHubUrl = {
  [itOpsRegionType.AU]: 'https://support.smokeball.com/hc/en-au',
  [itOpsRegionType.GB]: 'https://support.smokeball.com/hc/en-gb',
  [itOpsRegionType.US]: 'https://support.smokeball.com/hc/en-us',
};

const getSupportHubUrl = () => supportHubUrl[itOpsGetRegion()];

/**
 * @deprecated Use getRegion from sb-itops/region
 */
function getRegion(...args) {
  return itOpsGetRegion(...args);
}

/**
 * @deprecated Use regionType from sb-itops/region
 */
const regionType = itOpsRegionType;

module.exports = {
  regionType,
  envType,
  initialise,
  getAnalyticsEnabled,
  getGoogleApiKey,
  getGoogleTagManagerId,
  getForgotPasswordUrl,
  getFrontendEnv,
  getBackendEnv,
  getRegion,
  getRestApiUrl,
  getDotNetHostUrl,
  getNotificationServerHostUrl,
  getStripeCorporatePublicKey,
  getStripeOnboardingReturnUrl,
  getLawPayClientId,
  getLawPayRedirectUrl,
  getSubscriptionUpdatePaymentMethodUrl,
  getSubscriptionServiceUrl,
  getSupportHubUrl,
  getTrackingId,
  getSbSiteParams,
  getMfaSetupUrl,
};
