angular.module('sb.billing.webapp').component('sbDataTrustChequesSplitPayors', {
  require: { composer: '^sbCompose' },
  bindings: { composeDataKey: '@?' },
  controller: function ($scope, sbLoggerService, sbTrustChequeService, sbTransactionService, sbContactsMbService, sbFirmManagementMbService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataTrustChequesSplitPayors');

    ctrl.$onChanges = updateTrustCheques;
    ctrl.$onInit = updateTrustCheques;

    const listeners = [
      $scope.$on('smokeball-data-update-sbTrustChequeService', updateTrustCheques),
      $scope.$on('smokeball-data-update-sbTransactionService', updateTrustCheques),
      $scope.$on('smokeball-data-update-sbContactsMbService', updateTrustCheques),
      $scope.$on('smokeball-data-update-sbFirmMangementMbService', updateTrustCheques),
    ];

    ctrl.$onDestroy = () => {
      for (let unregister of listeners) {
        unregister();
      }
    }

    function updateTrustCheques () {
      const trustCheques = sbTrustChequeService.getByFilter((cheque) => !cheque.isPrinted);
      log.info('trustCheques: ', trustCheques);

      if (trustCheques) {
        const cheques = [];

        trustCheques.forEach(cheque => {
          const transactions = cheque.payments
            .map(p => sbTransactionService.getById(p.transactionId))
            .filter(tx => tx && !tx.reversed);

          if (transactions[0]) {
            const matterIds = transactions.map(transaction => transaction.matterId);
            cheques.push({
              bankAccountId:  cheque.bankAccountId,
              chequeId:  cheque.chequeId,
              effectiveDate: cheque.chequeDate,
              timestamp: transactions[0].timestamp,
              matterIds,
              payee: getPayee(transactions[0]),
              reference: getReference(transactions),
              amount: getAmount(transactions),
              chequeMemo: cheque.chequeMemo,
            });
          }
        });

        log.info('cheques', cheques);
        ctrl.composer.setComposeData(cheques, ctrl.composeDataKey || 'trustCheques');
      }
    }

    function amountReducer(acc, curr) {
      return { amount : Math.abs(acc.amount) + Math.abs(curr.amount) };
    }

    function getAmount(transactions) {
      return transactions.reduce(amountReducer, { amount : 0}).amount;
    }

    function referenceReducer(acc, curr) {
      return acc ? `${acc}, ${curr.reference}` : `${curr.reference ? curr.reference : ''}`;
    }

    function getReference(transactions) {
      return transactions.reduce(referenceReducer, '');
    }

    function getPayeeId(transaction) {
      return transaction.description.match(/Payment to contactId:(........-....-....-....-............)/)[1];
    }

    function getContact(transaction) {
      return sbContactsMbService.getById(getPayeeId(transaction)) || {};
    }

    function getPayee (transaction) {
      if (transaction.type === 'InvoicePayment') {
        return sbFirmManagementMbService.getLoggedInStaffFirmName();
      }

      return getContact(transaction).displayName;
    }
  }
});
