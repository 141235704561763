import * as React from 'react';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { useTranslation, DropdownMenuButton } from '@sb-itops/react';
import { DebugRefreshInvoicePaymentLinksButton } from 'web/react-redux';

import Styles from './InvoiceListActionBar.module.scss';

function generateDownloadMenuOptions({
  combineInPdfCount,
  combineInPdfWithCoverLetterCount,
  isCombineInPdfInProgress,
  isDownloadLedesInProgress,
  isUtbmsFacetEnabled,
  isBulkLedesDownloadFeatureEnabled,
  selectedInvoicesByAction,
  // Callbacks
  onCombineInPdf,
  onCombineInPdfWithCoverLetter,
  onDownloadLedes,
}) {
  if (!isUtbmsFacetEnabled) {
    return [];
  }

  const downloadLedesCount = Object.keys(selectedInvoicesByAction.downloadLedes).length;

  const utbmsDownloadMenuOptions = [
    {
      id: 'firm-invoices-list-utbms-download-option-id-1',
      label: `Combined PDF (${combineInPdfCount})`,
      disabled: !combineInPdfCount || isCombineInPdfInProgress,
      onClick: onCombineInPdf,
    },
    {
      id: 'firm-invoices-list-utbms-download-option-id-2',
      label: `Combined PDF with Cover Letter (${combineInPdfWithCoverLetterCount})`,
      disabled: !combineInPdfWithCoverLetterCount || isCombineInPdfInProgress,
      onClick: onCombineInPdfWithCoverLetter,
    },
  ];

  if (isUtbmsFacetEnabled && isBulkLedesDownloadFeatureEnabled) {
    utbmsDownloadMenuOptions.push({
      id: 'firm-invoices-list-utbms-download-option-id-3',
      label: `LEDES 1998B (${downloadLedesCount})`,
      disabled: !downloadLedesCount || isDownloadLedesInProgress,
      onClick: onDownloadLedes,
    });
  }

  return utbmsDownloadMenuOptions;
}

function generateBulkActionsMenuOptions({
  deleteDraftCount,
  emailCount,
  finaliseCount,
  markAsSentCount,
  createStatementCount,
  t,
  // Callbacks
  onDeleteDraftInvoices,
  onFinalise,
  onMarkAsSent,
  onSendViaEmail,
  onCreateStatement,
  // flag
  showCreateStatement,
}) {
  const bulkActionsMenuOptions = [
    {
      id: 'firm-invoices-list-bulk-action-option-id-1',
      label: `${t('capitalizeAllWords', { val: 'finalise' })} Invoices (${finaliseCount})`,
      disabled: !finaliseCount,
      onClick: onFinalise,
    },
    {
      id: 'firm-invoices-list-bulk-action-option-id-2',
      label: `Delete Drafts (${deleteDraftCount})`,
      disabled: !deleteDraftCount,
      onClick: onDeleteDraftInvoices,
    },
    {
      id: 'firm-invoices-list-bulk-action-option-id-3',
      label: `Send via Email (${emailCount})`,
      disabled: !emailCount,
      onClick: onSendViaEmail,
    },
    {
      id: 'firm-invoices-list-bulk-action-option-id-4',
      label: `Mark as Sent (${markAsSentCount})`,
      disabled: !markAsSentCount,
      onClick: onMarkAsSent,
    },
  ];

  if (showCreateStatement) {
    bulkActionsMenuOptions.push({
      id: 'contact-invoices-list-bulk-action-option-id-5',
      label: `Create Statement (${createStatementCount})`,
      disabled: !createStatementCount,
      onClick: onCreateStatement,
    });
  }

  return bulkActionsMenuOptions;
}

export const InvoiceListActionBar = ({
  isBulkActionsLoading,
  isBulkCreateInvoicesInProgress,
  isCombineInPdfInProgress,
  isCreateInvoiceDisabled,
  isDownloadLedesInProgress,
  isLoading,
  isUtbmsFacetEnabled,
  selectedInvoicesByAction,
  showAddCredit,
  showAddPayment,
  showBulkActions,
  showCreateInvoice,
  showDownloadOptions,
  // Callbacks
  onCombineInPdf,
  onCombineInPdfWithCoverLetter,
  onCreateInvoice,
  onDeleteDraftInvoices,
  onDownloadLedes,
  onFinalise,
  onMarkAsSent,
  onOpenAddCreditModal,
  onOpenAddPaymentModal,
  onSendViaEmail,
  onCreateStatement,
  // flag
  showCreateStatement,
}) => {
  const { t } = useTranslation();

  const combineInPdfCount = selectedInvoicesByAction.combineInPdf.length;
  const combineInPdfWithCoverLetterCount = selectedInvoicesByAction.combineInPdfWithCoverLetter.length;
  const finaliseCount = selectedInvoicesByAction.finalise.length;
  const deleteDraftCount = selectedInvoicesByAction.deleteDraft.length;
  const emailCount = selectedInvoicesByAction.email.length;
  const markAsSentCount = selectedInvoicesByAction.markAsSent.length;
  const createStatementCount = selectedInvoicesByAction.createStatement.length;

  const isBulkLedesDownloadFeatureEnabled = featureActive('BB-9243');
  const isBulkActionsDisabled =
    (!finaliseCount && !deleteDraftCount && !emailCount && !markAsSentCount) || isBulkActionsLoading;

  // Button menu options
  const utbmsDownloadMenuOptions = generateDownloadMenuOptions({
    combineInPdfCount,
    combineInPdfWithCoverLetterCount,
    isCombineInPdfInProgress,
    isDownloadLedesInProgress,
    isUtbmsFacetEnabled,
    isBulkLedesDownloadFeatureEnabled,
    selectedInvoicesByAction,
    // Callbacks
    onCombineInPdf,
    onCombineInPdfWithCoverLetter,
    onDownloadLedes,
  });
  const bulkActionsMenuOptions = generateBulkActionsMenuOptions({
    deleteDraftCount,
    emailCount,
    finaliseCount,
    markAsSentCount,
    createStatementCount,
    t,
    // Callbacks
    onDeleteDraftInvoices,
    onFinalise,
    onMarkAsSent,
    onSendViaEmail,
    onCreateStatement,
    // flag
    showCreateStatement,
  });

  return (
    <div className={Styles.invoiceListActionBar}>
      {/* Create invoice */}
      {showCreateInvoice && (
        <button
          className="btn btn-primary"
          type="button"
          disabled={isCreateInvoiceDisabled}
          onClick={onCreateInvoice}
          title={
            isBulkCreateInvoicesInProgress
              ? 'Please wait for the bulk invoices to complete before creating new ones'
              : ''
          }
        >
          Create Invoice
        </button>
      )}
      {/* Download actions */}
      {showDownloadOptions && (
        <div className={Styles.buttonGroup}>
          {/* UTBMS disabled */}
          {!isUtbmsFacetEnabled && (
            <button
              className="btn btn-primary"
              type="button"
              disabled={!combineInPdfCount || isCombineInPdfInProgress || isLoading}
              onClick={onCombineInPdf}
            >
              {`Combine In PDF (${combineInPdfCount})`}
            </button>
          )}
          {/* UTBMS enabled */}
          {isUtbmsFacetEnabled && (
            <DropdownMenuButton
              label={`Download (${combineInPdfCount || combineInPdfWithCoverLetterCount})`}
              disabled={
                (!combineInPdfCount && !combineInPdfWithCoverLetterCount) ||
                isCombineInPdfInProgress ||
                isDownloadLedesInProgress ||
                isLoading
              }
              menuOptions={utbmsDownloadMenuOptions}
            />
          )}
        </div>
      )}

      {/* Add Payment */}
      {showAddPayment && (
        <button className="btn btn-primary" type="button" onClick={onOpenAddPaymentModal} disabled={isLoading}>
          Add Payment
        </button>
      )}

      {/* Add Credit */}
      {showAddCredit && (
        <button className="btn btn-primary" type="button" onClick={onOpenAddCreditModal} disabled={isLoading}>
          Add Credit
        </button>
      )}

      {/* Bulk Actions */}
      {showBulkActions && (
        <DropdownMenuButton
          label="Bulk Actions"
          disabled={isBulkActionsDisabled || isLoading}
          menuOptions={bulkActionsMenuOptions}
          showSpinner={isBulkActionsLoading}
        />
      )}

      {/* Debug: Refresh expired payment links for all unpaid invoices */}
      <DebugRefreshInvoicePaymentLinksButton />
    </div>
  );
};

InvoiceListActionBar.displayName = 'InvoiceListActionBar';

InvoiceListActionBar.propTypes = {
  // Start note
  //  * Loading - fetching data
  isBulkActionsLoading: PropTypes.bool.isRequired, // This reflects the loading state of the Bulk Actions button (after an action was selected)
  isLoading: PropTypes.bool, // This reflects the initial loading state for the InvoiceListActionBar component (when required)
  //  * In Progress - operation is processing
  isCombineInPdfInProgress: PropTypes.bool,
  isDownloadLedesInProgress: PropTypes.bool,
  // End note
  isBulkCreateInvoicesInProgress: PropTypes.bool,
  isCreateInvoiceDisabled: PropTypes.bool,
  isUtbmsFacetEnabled: PropTypes.bool.isRequired,
  selectedInvoicesByAction: PropTypes.shape({
    combineInPdf: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    combineInPdfWithCoverLetter: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    deleteDraft: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    downloadLedes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    email: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    finalise: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    markAsSent: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    createStatement: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  showAddCredit: PropTypes.bool,
  showAddPayment: PropTypes.bool,
  showBulkActions: PropTypes.bool,
  showCreateInvoice: PropTypes.bool,
  showDownloadOptions: PropTypes.bool,
  showCreateStatement: PropTypes.bool,
  // Callbacks
  onCombineInPdf: PropTypes.func,
  onCombineInPdfWithCoverLetter: PropTypes.func,
  onCreateInvoice: PropTypes.func,
  onDeleteDraftInvoices: PropTypes.func,
  onDownloadLedes: PropTypes.func,
  onFinalise: PropTypes.func.isRequired,
  onMarkAsSent: PropTypes.func.isRequired,
  onOpenAddCreditModal: PropTypes.func,
  onOpenAddPaymentModal: PropTypes.func,
  onSendViaEmail: PropTypes.func.isRequired,
  onCreateStatement: PropTypes.func,
};

InvoiceListActionBar.defaultProps = {
  isBulkCreateInvoicesInProgress: undefined,
  isCombineInPdfInProgress: undefined,
  isCreateInvoiceDisabled: undefined,
  isDownloadLedesInProgress: undefined,
  isLoading: undefined,
  showAddCredit: undefined,
  showAddPayment: undefined,
  showBulkActions: undefined,
  showCreateInvoice: undefined,
  showDownloadOptions: undefined,
  showCreateStatement: false,
  // Callbacks
  onCombineInPdf: undefined,
  onCombineInPdfWithCoverLetter: undefined,
  onCreateInvoice: undefined,
  onDeleteDraftInvoices: undefined,
  onDownloadLedes: undefined,
  onOpenAddCreditModal: undefined,
  onOpenAddPaymentModal: undefined,
  onCreateStatement: () => {},
};
