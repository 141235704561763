// TODO this currently uses mix of LOD and caches which is wrong and should be rewritten to LOD only

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { isModalVisible, setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { productTierIds } from '@sb-finance/business-logic/subscription/entities/constants';
import { firmManagement } from '@sb-firm-management/redux';

import { UserTwoFactorStatuses } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { getIsFirmOwner, getProductTier } from 'web/services/user-session-management';

import StaffAccounts from './StaffAccounts';

const CANNOT_EDIT_MODAL_MESSAGE_DIALOG_ID = 'cannot-edit-staff-message-dialog';

const { getAllStaff, getFirmDetails } = firmManagement;

const hooks = () => ({
  useTwoFactorStatuses: () => {
    const { data: userTwoFactorStatusesData, loading: userTwoFactorStatusesLoading } = useSubscribedQuery(
      UserTwoFactorStatuses,
      {
        skip: !featureActive('BB-13182'),
      },
    );

    const userTwoFactorStatuses = userTwoFactorStatusesData?.userTwoFactorStatuses || [];

    const userTwoFactorStatusesById = userTwoFactorStatuses.reduce((acc, userTwoFactorStatus) => {
      acc[userTwoFactorStatus.id] = userTwoFactorStatus.twoFactorEnabled;
      return acc;
    }, {});

    return {
      userTwoFactorStatusesById,
      dataLoading: userTwoFactorStatusesLoading,
    };
  },
  useBillAndBoostTier: () => {
    const tier = getProductTier();
    const allUsersHaveSmokeballAccess = // SMK001 - Bill, SMK004 - Boost
      featureActive('BB-13218') && [productTierIds.SMK001, productTierIds.SMK004].includes(tier);
    return {
      allUsersHaveSmokeballAccess,
    };
  },
  usePermissions: () => {
    const isFirmOwner = getIsFirmOwner();
    return {
      isFirmOwner,
    };
  },
  useMessageModal: () => {
    const openCannotEditStaffMessageModal = () =>
      setModalDialogVisible({ modalId: CANNOT_EDIT_MODAL_MESSAGE_DIALOG_ID });
    const closeCannotEditStaffMessageModal = () =>
      setModalDialogHidden({ modalId: CANNOT_EDIT_MODAL_MESSAGE_DIALOG_ID });
    const showCannotEditStaffMessageModal = useSelector(() =>
      isModalVisible({ modalId: CANNOT_EDIT_MODAL_MESSAGE_DIALOG_ID }),
    );
    return {
      openCannotEditStaffMessageModal,
      closeCannotEditStaffMessageModal,
      showCannotEditStaffMessageModal,
    };
  },
});

export const StaffAccountsContainer = withApolloClient(
  composeHooks(hooks)(({ userTwoFactorStatusesById, ...props }) => {
    const [addStaffUser, setAddStaffUser] = useState();
    const [showFormerStaff, setShowFormerStaff] = useState(false);

    const showDebug = useSelector(supportDebugSelectors.getShowDebug);

    const currentUsersByPersonId = Object.values(getFirmDetails()?.currentUsers || {}).reduce((acc, val) => {
      acc[val.personId] = val.userId;
      return acc;
    }, {});

    const formerUsersByPersonId = Object.values(getFirmDetails()?.formerUsers || {}).reduce((acc, val) => {
      acc[val.personId] = val.userId;
      return acc;
    }, {});

    // We would like to subscribe to redux updates
    const staffList = useSelector(() => getAllStaff())
      .filter((person) => showFormerStaff || !person.isFormerStaff)
      .map((person) => {
        const userId = currentUsersByPersonId[person.id] || formerUsersByPersonId[person.id] || '';
        return {
          ...person,
          sbAccess: !!currentUsersByPersonId[person.id],
          userId,
          isFormerUser: !!formerUsersByPersonId[person.id],
          twoFactorEnabled: !!userTwoFactorStatusesById[userId],
        };
      });

    return (
      <StaffAccounts
        {...props}
        {...{
          setAddStaffUser,
          addStaffUser,
          staffList,
          setShowFormerStaff,
          showFormerStaff,
          showDebug,
        }}
      />
    );
  }),
);

StaffAccountsContainer.displayName = 'StaffAccountsContainer';

export default StaffAccountsContainer;
