'use strict';

const { fetchAccessAuthorisations } = require('./fetch-access-authorisations');
const { resourceIds } = require('./resource-ids');

/**
 * Fetch a map of high level user permissions after login
 *
 * NB: Matter specific permissions are intended to be fetched
 * on demand when the user navigates to a matter instead of
 * being fetched here.
 * @param {object} params
 * @param {string} params.accountId
 * @param {string} params.userId
 * @param {'AU'|'US'|'GB'} params.region
 * @param {'LOCAL'|'DEV'|'RC'|'QA'|'LIVE'} params.appEnv
 * @param {'dev'|'staging'|'prod'} params.dataEnv
 * @returns {UserPermissions} map of user permissions with resourceId as key
 */
async function fetchUserPermissions({ accountId, userId, region, appEnv, dataEnv }) {
  // fetching authorisations for all resourceIds in one go
  // initially we are fetching data for billing access only,
  // but this can easily be extended to handle other resources
  // by adding to the list of resourceIds we are interested in
  const userPermissionsResourceIds = [resourceIds.BILLING_DATA];
  const accessAuthorisations = await fetchAccessAuthorisations({
    accountId,
    userId,
    resourceIds: userPermissionsResourceIds,
    region,
    appEnv,
    dataEnv,
  });

  const userPermissions = accessAuthorisations.reduce((acc, accessAuthorization) => {
    acc[accessAuthorization.resourceId] = accessAuthorization;
    return acc;
  }, {});

  return userPermissions;
}

module.exports = {
  fetchUserPermissions,
};
