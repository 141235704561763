import React, { useEffect } from 'react';

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const withOnUpdate = (propDependencies) => (WrappedComponent) => {
  if (!Array.isArray(propDependencies)) {
    throw new Error('Array of strings must be provided for propDependencies');
  }

  const WrappedComponentWithOnUpdate = ({ onUpdate, ...props }) => {
    const dependencies = propDependencies.map((propName) => props[propName]).filter((p) => !!p);

    useEffect(() => {
      if (!onUpdate) {
        return undefined;
      }

      const cleanUpFn = onUpdate();
      if (cleanUpFn) {
        // eslint-disable-next-line consistent-return
        return cleanUpFn;
      }

      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
    return <WrappedComponent {...props} />;
  };

  WrappedComponentWithOnUpdate.displayName = `WrappedComponentWithOnUpdate(${getDisplayName(WrappedComponent)})`;
  return WrappedComponentWithOnUpdate;
};

export default withOnUpdate;
