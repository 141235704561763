import React, { useRef } from 'react';
import classnames from 'classnames';
import { Button, Spinner, useTranslation } from '@sb-itops/react';
import moment from 'moment';
import { fileFolderChangeTypeMap, fileFolderChangeTypeLabelMap } from 'web/business-logic/documents';
import { gql } from '@apollo/client';
import { getApolloClient } from 'web/services/apollo';
import * as messageDisplay from '@sb-itops/message-display';
import { Staff, File } from 'types';
import { FileDetailQuery } from 'web/graphql/types/graphql';
import { nonNullishFieldsGuardFactory } from '@sb-itops/type-helpers';
import { featureActive } from '@sb-itops/feature';
import { isNewTheme } from 'web/services/theme';
import Styles from './ViewFile.module.scss';

const isImageMap = {
  '.jpg': true,
  '.jpeg': true,
  '.png': true,
  '.gif': true,
  '.tiff': true,
};

export interface IViewFileProps {
  setVersionId: (id: string) => void;
  documentURL: string | undefined;
  versionId: string;
  loading: boolean;
  setLoading: (id: boolean) => void;
  replaceFile: (files: FileList | globalThis.File[] | null) => Promise<void>;
  restoreFile: (versionId: string) => Promise<void>;
  downloadFile: (versionId: string) => Promise<void>;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  file: File;
  getPersonByUserId: (id: string) => Staff;
  shareFile: (props: { file: File; isLiving: boolean }) => void;
}

const onModifyFileHistory = (fileDetail) => {
  const optimisticFileDetails = {
    __typename: 'FileDetail',
    ...fileDetail,
    versions: [
      {
        versionId: '',
        versionNumber: (fileDetail.versions[0]?.versionNumber || 0) + 1,
        changeType: fileFolderChangeTypeMap.Modified,
        clientTimestamp: new Date().toISOString(),
        timestamp: new Date().toISOString(),
        size: 0,
        __typename: 'FileVersion',
      },
      ...(fileDetail.versions || []),
    ],
  };

  const apolloClient = getApolloClient();

  const fileDetailsIdentifier = apolloClient.cache.identify(optimisticFileDetails);

  apolloClient.writeFragment({
    id: fileDetailsIdentifier,
    fragment: gql`
      fragment OpdateFileDetail on FileDetail {
        ${Object.keys(optimisticFileDetails).join('\n')}
      }
    `,
    data: {
      ...optimisticFileDetails,
    },
  });
};

const detailsGuard = nonNullishFieldsGuardFactory(['versionId', 'changeType']);

export const ViewFile = ({
  setVersionId,
  documentURL,
  versionId,
  fileDetailResult,
  file,
  loading,
  downloadFile,
  replaceFile,
  restoreFile,
  getPersonByUserId,
  shareFile,
}: IViewFileProps) => {
  const { t } = useTranslation();
  const inputSingleRef = useRef<HTMLInputElement>(null);
  let filePreviewContent = (
    <div className={Styles.transform}>
      <Spinner small />
    </div>
  );
  if (documentURL !== '' && !isImageMap[file.data.fileExtension]) {
    filePreviewContent = (
      <object data={documentURL} className={Styles.viewer}>
        <p>This document cannot be previewed</p>
      </object>
    );
  } else if (documentURL !== '' && isImageMap[file.data.fileExtension]) {
    filePreviewContent = <img src={documentURL} className={Styles.viewer} alt="This file cannot be previewed" />;
  }

  const formatFileFolderChangeTimeStamp = (ts) => {
    const monthAndDay = t('date', { ts, format: 'D MMM' });
    const hourAndMinute = t('date', { ts, format: 'hhmm A' });
    const formattedTimeStamp = `${monthAndDay} at ${hourAndMinute}`;
    return formattedTimeStamp;
  };

  return (
    <div className={Styles.viewFileContainer}>
      <div className={Styles.fileDetail}>
        <div className={Styles.fileInfo}>
          <div className={Styles.title}>{`${file.data.name}${file.data.fileExtension}`}</div>
          <div className={Styles.text}>Created: {moment(file.data.dateCreated).format(t('dateFormat_DD/MM/YYYY'))}</div>
          <div className={Styles.text}>
            Last Modified: {t('date', { date: file.data.dateModified, format: 'hhmm a DD/MM/YYYY' })}
          </div>
          <div className={Styles.text}>Created By: {getPersonByUserId(file.data.ownerId)?.name}</div>
        </div>
        <hr className={Styles.separator} />
        <div className={Styles.flex}>
          <div className={Styles.title}>Version History</div>
          <Button
            onClick={() => {
              inputSingleRef?.current?.click();
            }}
            locked={loading}
          >
            Upload new version
          </Button>
        </div>
        <div className={Styles.fileVersions}>
          {fileDetailResult?.loading && (
            <div className={Styles.loading}>
              <Spinner small />
            </div>
          )}
          {(fileDetailResult?.data?.fileDetail?.versions || []).filter(detailsGuard).map((item, index) => (
            <div
              key={item.versionId + item.clientTimestamp}
              onClick={() => {
                if (file.data.id !== item.versionId || index === 0) {
                  setVersionId(index === 0 ? '' : item.versionId);
                } else {
                  messageDisplay.info('The selected version cannot be previewed');
                }
              }}
              className={classnames(
                Styles.version,
                versionId === (index === 0 ? '' : item.versionId) && Styles.selected,
              )}
            >
              <div>
                <div>Version {item.versionNumber}</div>
                <div className={Styles.changeText}>{`${
                  fileFolderChangeTypeLabelMap[item.changeType] || 'Changed'
                } on ${formatFileFolderChangeTimeStamp(item.timestamp || item.clientTimestamp)}`}</div>
              </div>
              {index !== 0 && item.versionId !== file.data.id && (
                <Button
                  type="secondary"
                  className={Styles.restore}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    restoreFile(item.versionId);
                  }}
                >
                  Restore
                </Button>
              )}
              {(item.versionId !== file.data.id || index === 0) && (
                <Button
                  type={isNewTheme() ? 'tertiary' : 'info'}
                  locked={!item.versionId}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadFile(item.versionId);
                  }}
                >
                  {index !== 0 ? 'Download Original' : 'Download'}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
      <hr className={Styles.verticalSeparator} />
      <div className={Styles.embeddedPdf}>
        <div className={Styles.pdfInfo}>
          <span className={Styles.title}>
            Version{' '}
            {versionId
              ? fileDetailResult?.data?.fileDetail?.versions?.find((i) => i && i.versionId === versionId)
                  ?.versionNumber || 1
              : fileDetailResult?.data?.fileDetail?.versions?.length || 1}
          </span>
          <div className={Styles.buttons}>
            {featureActive('LOOP-561') && file.type === 'file' && (
              <Button disabled={!documentURL} onClick={() => shareFile({ file, isLiving: true })}>
                Share a link
              </Button>
            )}
            {featureActive('LOOP-561') && file.type === 'file' && (
              <Button disabled={!documentURL} onClick={() => shareFile({ file, isLiving: false })}>
                Send a copy
              </Button>
            )}
            <Button
              disabled={!documentURL}
              onClick={() => {
                if (documentURL === undefined) {
                  return;
                }
                const link = document.createElement('a');
                link.download = `${file.data.name}.pdf`;

                link.href = documentURL;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Download
            </Button>
          </div>
        </div>

        {filePreviewContent}
      </div>
      <input
        ref={inputSingleRef}
        className={Styles.hiddenFileInput}
        type="file"
        onChange={async (ev) => {
          const files = ev.target.files;

          if (!files || files.length > 1) {
            return;
          }

          await replaceFile(files);
          if (!versionId) {
            // We would like to maintain the currently selected preview.
            // Some events cannot be previewed, so we need to default back to an empty string instead of selecting that version
            const currentVersion = fileDetailResult?.data?.fileDetail?.versions?.[0]?.versionId || '';
            setVersionId(currentVersion !== file.data.id ? currentVersion : '');
          }
          onModifyFileHistory(fileDetailResult?.data?.fileDetail);
        }}
      />
    </div>
  );
};
