import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect } from '@sb-itops/react/select';
import { buildGroupedDropDownOptions, mapItemToOption } from '@sb-billing/business-logic/activities/services';

const ActivityDropdown = React.memo(
  ({
    onSelectionChange,
    activities,
    selectedActivityCode,
    originalSelectedActivity,
    disabled,
    placeholder,
    noDefaultStyling,
    hasError,
    hideSelectedOptionDescription,
    groupLabelHeight,
    optionLabelHeight,
    selectClassName,
  }) => {
    const isGrouped = activities && activities[0] && activities[0].group !== undefined && activities.length > 1; // only show group header if there is more than one group

    // Convert the activity objects to option objects accepted by DropDownList.
    const [options, optionsMap] = useMemo(() => buildGroupedDropDownOptions(activities, 'activities'), [activities]);

    // Stateless select expects an object reference, not an string id.
    let selectedOption = useMemo(
      () => (!selectedActivityCode ? null : optionsMap[selectedActivityCode]),
      [selectedActivityCode, optionsMap],
    );

    if (!selectedOption && !!originalSelectedActivity) {
      selectedOption = mapItemToOption(originalSelectedActivity);
    }

    // Create a thin wrapper around the selectionChange callback to extract out the original activity.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onValueChange = useCallback(onSelectionChange ? (selection) => onSelectionChange(selection.data) : () => {});

    const groupFormat = useCallback(({ label }) => <div className="activity-grouped-field-header">{label}</div>, []);

    // this interface is coming from react-select
    const formatOptionLabel = useCallback(
      (option, { context }) => {
        // this can be any JSX
        if (context === 'menu') return <span title={option.label}>{option.label}</span>;
        if (context === 'value') return hideSelectedOptionDescription ? option.value : option.label;
        // this can be any JSX
        return option.label;
      },
      [hideSelectedOptionDescription],
    );

    return (
      <StatelessSelect
        className={classnames(!noDefaultStyling && 'activity-dropdown', disabled && 'disabled', selectClassName)}
        onValueChange={onValueChange}
        options={options}
        selectedOption={selectedOption}
        formatGroupLabel={(isGrouped && groupFormat) || undefined}
        groupLabelHeight={(isGrouped && groupLabelHeight) || undefined}
        optionLabelHeight={optionLabelHeight}
        formatOptionLabel={formatOptionLabel}
        isClearable={false}
        disabled={disabled}
        placeholder={placeholder}
        isGrouped={isGrouped}
        hasError={hasError}
      />
    );
  },
);

ActivityDropdown.displayName = 'ActivityDropdown';

ActivityDropdown.propTypes = {
  selectClassName: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      activities: PropTypes.array,
    }),
  ),
  onSelectionChange: PropTypes.func,
  selectedActivityCode: PropTypes.string,
  originalSelectedActivity: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hideSelectedOptionDescription: PropTypes.bool,
  noDefaultStyling: PropTypes.bool,
  groupLabelHeight: PropTypes.number,
  optionLabelHeight: PropTypes.number,
  hasError: PropTypes.bool,
};

ActivityDropdown.defaultProps = {
  selectClassName: undefined,
  activities: [],
  onSelectionChange: undefined,
  selectedActivityCode: undefined,
  originalSelectedActivity: undefined,
  disabled: false,
  placeholder: undefined,
  hideSelectedOptionDescription: false,
  noDefaultStyling: false,
  groupLabelHeight: 40,
  optionLabelHeight: 40,
  hasError: false,
};

export default ActivityDropdown;
