import React from 'react';
import { FormLabel, IField, useTranslation } from '@sb-itops/react';
import classnames from 'classnames';
import Styles from './PostalAddressForm.module.scss';

type TProps = {
  formDisabled?: boolean;
  formInitialised?: boolean;
  boxNumber?: IField<string>;
  city?: IField<string>;
  zipCode?: IField<string>;
  onFieldValueUpdated: (update: Record<string, string | number | undefined>) => void;
  submitFailed?: boolean;
  optional?: boolean;
  validateForm: () => void;
};

export const PostalAddressFormGb = ({
  formInitialised,
  boxNumber,
  city,
  zipCode,
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  optional,
}: TProps) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field, style) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, style) : '';

  return (
    <div className={Styles.postalAddressForm}>
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-xs-4">
            <label>PO Box</label>
            <input
              name="Box Number"
              type="text"
              pattern="[0-9]+"
              className={classnames('form-control', isErrorClassnames(boxNumber, Styles.formControl))}
              value={boxNumber?.value || ''}
              onChange={(e) => {
                onFieldValueUpdated({ boxNumber: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
          <div className="col-xs-4">
            <FormLabel
              label={t('capitalizeAllWords', { val: 'suburb' })}
              field={city}
              submitFailed={submitFailed}
              optional={optional}
              uppercase={false}
            />
            <input
              name="suburb"
              type="text"
              pattern="[0-9]+"
              className={classnames('form-control', isErrorClassnames(city, Styles.formControl))}
              value={city?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onFieldValueUpdated({ city: e.target.value });
              }}
            />
          </div>
          <div className="col-xs-4">
            <FormLabel
              label={t('capitalizeAllWords', { val: 'postcode' })}
              field={zipCode}
              submitFailed={submitFailed}
              optional={optional}
              uppercase={false}
            />
            <input
              name="zipCode"
              type="text"
              pattern="[0-9]+"
              className={classnames('form-control', isErrorClassnames(zipCode, Styles.formControl))}
              value={zipCode?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onFieldValueUpdated({ zipCode: e.target.value });
              }}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};
