import * as types from './types';
import * as actions from './actions';

/* 
State Shape
{
  allSelected             : Boolean       : Flag indicating whether all items in all groups being managed are currently selected.
  numberOfParents         : Integer       : The number of parents/groups
  numberOfParentsSelected : Integer       : The number of parents selected
  parents                 : Object        : An object of key/value pairs of parentId/parentObject
  parentLookup            : Object        : An object of key/value pairs of childId/parentId
}

NB: parentObject shape
{
  selected                  : Boolean        : Flag indicating this parent and all its children is selected. If one of the children is not selected, this would be false.
  numberOfChildren          : Integer        : The number of children under this parent
  numberOfChildrenSelected  : Integer        : The number of children under this parent that's selected
  children                  : Object          : An object of key/value pairs of childId/boolean, the latter is a Flag indicating whether the child is selected.
}
*/

// Defines the initial values set in state.
const initialState = {
  allSelected: false,
  numberOfParents: 0,
  numberOfParentsSelected: 0,
  parents: {},
  parentLookup: {},
};

const reducerLookup = {
  [types.INITIALISE]: initialiseReducer,
  [types.SELECT_ALL]: selectAllReducer,
  [types.DESELECT_ALL]: deselectAllReducer,
  [types.TOGGLE_SELECT_ALL]: toggleSelectAllReducer,
  [types.SELECT_PARENT]: selectParentReducer,
  [types.DESELECT_PARENT]: deselectParentReducer,
  [types.TOGGLE_SELECT_PARENT]: toggleSelectParentReducer,
  [types.SELECT_CHILD]: selectChildReducer,
  [types.DESELECT_CHILD]: deselectChildReducer,
  [types.TOGGLE_SELECT_CHILD]: toggleSelectChildReducer,
  [types.RESET]: () => initialState,
};

export const reducer = (state = initialState, action = {}) => {
  const reducerFn = reducerLookup[action.type];
  return reducerFn ? reducerFn(state, action) : state;
};

function initialiseReducer(state, action) {
  const { parentIdToChildIds, selectedByDefault } = action.payload;

  const parentIds = Object.keys(parentIdToChildIds || {});
  const newState = {
    ...initialState,
    allSelected: selectedByDefault,
    numberOfParents: parentIds.length,
    numberOfParentsSelected: selectedByDefault ? parentIds.length : 0,
  };

  parentIds.forEach((parentId) => {
    const childIds = parentIdToChildIds[parentId] || [];
    const parent = {
      selected: selectedByDefault,
      numberOfChildren: childIds.length,
      numberOfChildrenSelected: selectedByDefault ? childIds.length : 0,
      children: {},
    };

    childIds.forEach((childId) => {
      parent.children[childId] = selectedByDefault;
      newState.parentLookup[childId] = parentId;
    });

    newState.parents[parentId] = parent;
  });

  return newState;
}

function calculateAllSelected(state) {
  const allSelected = state.numberOfParents > 0 ? state.numberOfParents === state.numberOfParentsSelected : 0;

  return {
    ...state,
    allSelected,
  };
}

function selectChild(state, childId) {
  const selectChildAction = actions.selectChild({ childId });
  return selectChildReducer(state, selectChildAction);
}

function selectChildReducer(state, action) {
  const { childId } = action.payload;
  const parentId = state.parentLookup[childId];
  const newState = { ...state };
  const parent = newState.parents[parentId];

  const childSelected = parent.children[childId];
  if (!childSelected) {
    parent.children[childId] = true;
    parent.numberOfChildrenSelected += 1;
    if (!parent.selected) {
      parent.selected =
        parent.numberOfChildren > 0 ? parent.numberOfChildrenSelected === parent.numberOfChildren : false;
      newState.numberOfParentsSelected += parent.selected ? 1 : 0;
    }
  }

  return calculateAllSelected(newState);
}

function deselectChild(state, childId) {
  const deselectChildAction = actions.deselectChild({ childId });
  return deselectChildReducer(state, deselectChildAction);
}

function deselectChildReducer(state, action) {
  const { childId } = action.payload;
  const parentId = state.parentLookup[childId];
  const newState = { ...state };
  const parent = newState.parents[parentId];

  const childSelected = parent.children[childId];
  if (childSelected) {
    parent.children[childId] = false;
    parent.numberOfChildrenSelected -= 1;
    if (parent.selected) {
      parent.selected = false;
      newState.numberOfParentsSelected -= 1;
    }
  }

  return calculateAllSelected(newState);
}

function toggleSelectChildReducer(state, action) {
  const { childId } = action.payload;
  const parentId = state.parentLookup[childId];
  const childSelected = state.parents[parentId].children[childId];
  const newState = childSelected ? deselectChild(state, childId) : selectChild(state, childId);
  return newState;
}

function selectParent(state, parentId) {
  return selectParentReducer(state, actions.selectParent({ parentId }));
}

function selectParentReducer(state, action) {
  const { parentId } = action.payload;
  const newState = { ...state };
  const parent = newState.parents[parentId];

  if (!parent.selected) {
    parent.selected = true;
    const childIds = Object.keys(parent.children);
    childIds.forEach((childId) => {
      parent.children[childId] = true;
    });
    parent.numberOfChildrenSelected = childIds.length;
    newState.numberOfParentsSelected += 1;
  } // skip the process if already selected

  return calculateAllSelected(newState);
}

function deselectParent(state, parentId) {
  return deselectParentReducer(state, actions.deselectParent({ parentId }));
}

function deselectParentReducer(state, action) {
  const { parentId } = action.payload;
  const newState = { ...state };
  const parent = newState.parents[parentId];

  if (parent.selected) {
    parent.selected = false;
    Object.keys(parent.children).forEach((childId) => {
      parent.children[childId] = false;
    });
    parent.numberOfChildrenSelected = 0;
    newState.numberOfParentsSelected -= 1;
  } // skip the process if already deselected

  return calculateAllSelected(newState);
}

function toggleSelectParentReducer(state, action) {
  const { parentId } = action.payload;
  const parent = state.parents[parentId];
  const newState = parent.selected ? deselectParent(state, parentId) : selectParent(state, parentId);
  return newState;
}

function selectAllReducer(state) {
  let newState = { ...state };

  Object.keys(newState.parents).forEach((parentId) => {
    newState = selectParent(newState, parentId);
  });

  return calculateAllSelected(newState);
}

function deselectAllReducer(state) {
  let newState = { ...state };

  Object.keys(newState.parents).forEach((parentId) => {
    newState = deselectParent(newState, parentId);
  });

  return calculateAllSelected(newState);
}

function toggleSelectAllReducer(state) {
  const newState = state.allSelected ? deselectAllReducer(state) : selectAllReducer(state);
  return newState;
}
