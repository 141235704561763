/**
 * Find all role options for the given matter type configuration
 * @param {object} matterTypeConfig 
 * @returns {object} An object with a 'list' and a 'map' of all possible role options
 */
function findRoleOptions(matterTypeConfig) {
  const defaultOptions = { list: [], map: {} };
  if (
    !matterTypeConfig ||
    !(matterTypeConfig.items && matterTypeConfig.items.length > 0)
  ) {
    return defaultOptions;
  }

  const roleOptions = matterTypeConfig.items.reduce(
    (acc, item) => {
      if (item.providerId === 'roles_provider') {
        acc.list.push({ label: item.name, value: item.id });
        acc.map[item.id] = item.name;
      }
      return acc;
    },
    { list: [], map: {} },
  );
  return roleOptions;
}

module.exports = {
  findRoleOptions,
};
