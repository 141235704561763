import { PaymentProviderChargeForm } from '@sb-billing/payment-provider-charge-form';
import { SavedCardChargeForm } from '@sb-billing/saved-card-charge-form';
import { CurrencyInput2, DropDownList, SlidingToggle } from '@sb-itops/react';
import PropTypes from 'prop-types';
import React from 'react';
import { PAYMENT_SOURCE } from 'web/redux/selectors/payment-source';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import Styles from './TakePaymentNowForm.module.scss';

export const TakePaymentNowForm = ({
  contactPaymentMethodOptions,
  onUpdateFieldValues,
  paymentFormConfiguration,
  activeProviderType,
  clientIsCoveringFee,
  feeDetails,
  isCardSavingEnabled,
  paidByContactDetailsLoading,
  // form
  formData,
  formSubmitting,
  paymentSourceSelected,
  // Underlying charge forms props
  onChargeFormDataChange,
  onChargeFormSubmit,
  onChargeFormReadyForSubmit,
  triggerChargeFormSubmit,
  onPreCharge,
}) => {
  const { amount, paymentMethod, saveCardDetails } = formData;

  return (
    <div className={Styles.takePaymentNowFormContainer}>
      <div className={Styles.takePaymentNowForm}>
        <fieldset disabled={formSubmitting}>
          <div className={Styles.row}>
            <div className={Styles.fieldContainer50}>
              <label>Amount</label>
              <CurrencyInput2 disabled name="amount" value={amount || 0} />
            </div>

            <div className={Styles.fieldContainer50}>
              {isCardSavingEnabled && paymentSourceSelected?.paymentSource === PAYMENT_SOURCE.creditCard && (
                <>
                  <label>Credit Card</label>
                  <DropDownList
                    disabled={formSubmitting}
                    isLoading={paidByContactDetailsLoading}
                    options={contactPaymentMethodOptions}
                    selectedOption={paymentMethod}
                    placeholder="Select or Add New..."
                    onValueChange={(newPaymentMethod) => {
                      onUpdateFieldValues({ paymentMethod: newPaymentMethod?.value });
                    }}
                    menuPlacement="bottom"
                    stateless
                  />
                </>
              )}
            </div>
          </div>

          {clientIsCoveringFee && (
            <div className={Styles.row}>
              <div className={Styles.fieldContainer50}>
                <label>Total amount of charge</label>
                <CurrencyInput2 disabled name="effectiveAmountInCents" value={feeDetails.effectiveAmountInCents || 0} />
              </div>
              <div className={Styles.fieldContainer50}>
                <label>Includes {providerNames[activeProviderType]} Fee</label>
                <CurrencyInput2 disabled name="effectiveAmountInCents" value={feeDetails.effectiveFeeInCents || 0} />
              </div>
            </div>
          )}

          {(paymentSourceSelected?.paymentSource === PAYMENT_SOURCE.eCheck ||
            (paymentSourceSelected?.paymentSource === PAYMENT_SOURCE.creditCard && paymentMethod === 'None') ||
            (!isCardSavingEnabled &&
              paymentSourceSelected?.paymentSource === PAYMENT_SOURCE.creditCard &&
              paymentMethod === undefined)) && (
            <>
              {isCardSavingEnabled && (
                <div className={Styles.saveCardDetailsContainer}>
                  <label htmlFor="saveCardDetails">
                    <SlidingToggle
                      id="saveCardDetails"
                      name="saveCardDetails"
                      onChange={(name, val) => onUpdateFieldValues({ [name]: val })}
                      scope="save-card-details"
                      selected={saveCardDetails}
                    />
                    <span>Save credit card details</span>
                  </label>
                </div>
              )}
              <hr />
              <PaymentProviderChargeForm
                providerType={activeProviderType}
                triggerSubmit={triggerChargeFormSubmit}
                isSubmitting={formSubmitting}
                paymentFormConfiguration={paymentFormConfiguration}
                onSubmit={onChargeFormSubmit}
                onReadyForSubmit={onChargeFormReadyForSubmit}
                onPreSubmitChange={onChargeFormDataChange}
                onPreCharge={onPreCharge}
              />
            </>
          )}

          {paymentSourceSelected?.paymentSource === PAYMENT_SOURCE.creditCard &&
            paymentMethod !== undefined &&
            paymentMethod !== 'None' && (
              <SavedCardChargeForm
                providerType={activeProviderType}
                isSubmitting={formSubmitting}
                onReadyForSubmit={onChargeFormReadyForSubmit}
                onSubmit={onChargeFormSubmit}
                savedCard={contactPaymentMethodOptions.find((pmo) => pmo.value === paymentMethod)?.data}
                triggerSubmit={triggerChargeFormSubmit}
              />
            )}
        </fieldset>
      </div>
    </div>
  );
};
TakePaymentNowForm.displayName = 'TakePaymentNowForm';

TakePaymentNowForm.propTypes = {
  paymentFormConfiguration: PropTypes.object,
  contactPaymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    }),
  ),
  activeProviderType: PropTypes.string,
  clientIsCoveringFee: PropTypes.bool,
  feeDetails: PropTypes.object,
  isCardSavingEnabled: PropTypes.bool.isRequired,
  paidByContactDetailsLoading: PropTypes.bool.isRequired,
  //  form
  formData: PropTypes.object.isRequired,
  onUpdateFieldValues: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  paymentSourceSelected: PropTypes.object.isRequired,
  // Underlying charge forms props
  onChargeFormDataChange: PropTypes.func.isRequired,
  onChargeFormSubmit: PropTypes.func.isRequired,
  onChargeFormReadyForSubmit: PropTypes.func.isRequired,
  triggerChargeFormSubmit: PropTypes.bool.isRequired,
  onPreCharge: PropTypes.func.isRequired,
};

TakePaymentNowForm.defaultProps = {
  paymentFormConfiguration: {},
  contactPaymentMethodOptions: [],
  activeProviderType: undefined,
  clientIsCoveringFee: false,
  feeDetails: {},
};
