const { facets, hasFacet } = require('@sb-itops/region-facets');
const { featureActive } = require('@sb-itops/feature');
const {
  getBankTransferSources: getBankTransferDepositSources,
  getDefaultSource: getDefaultDepositSource,
  getSources: getDepositSources,
} = require('@sb-billing/business-logic/payment-deposit-sources/services');
const { t } = require('@sb-itops/localisation-web');

// For deposit funds modal
// Wrapper for business logic methods in monorepo/billing/business-logic/payment-deposit-sources/services/index.js

const supportsCMA = hasFacet(facets.CMA);
const supportsChequeTrustPaymentTypes = hasFacet(facets.chequeTrustPaymentTypes);
const supportsDirectDebit = featureActive('BB-5511');
const supportsTrustPaymentBankTransferType = hasFacet(facets.trustPaymentBankTransferType);

const getSources = (sourceType) =>
  getDepositSources({ sourceType, t, supportsCMA, supportsChequeTrustPaymentTypes, supportsDirectDebit });
const getDefaultSource = (sources) => getDefaultDepositSource(sources);
const getBankTransferSources = (sourceType) =>
  getBankTransferDepositSources({ sourceType, supportsTrustPaymentBankTransferType });

export { getSources, getDefaultSource, getBankTransferSources };
