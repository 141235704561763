angular.module('sb.billing.webapp').component('sbRestErrorLogs', {
  templateUrl: 'ng-components/rest-error-logs/rest-error-logs.html',
  bindings: { errorId: '<?'},
  controller: function (sbRestErrorService) {
    'use strict';

    const ctrl = this;

    ctrl.$onChanges = function() {
      ctrl.logHistory = sbRestErrorService.getLogHistory(ctrl.errorId);
    };

  }
});

angular.module('sb.billing.webapp').config(function (JSONFormatterConfigProvider) {
  JSONFormatterConfigProvider.hoverPreviewEnabled = true;
});
