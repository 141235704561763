angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/data-explorer';

  $stateProvider
    .state('home.billing.support.data-explorer', {
      url: '/data-explorer',
      templateUrl: BASE_DIRECTORY + '/data-explorer.html',
      data: {
        authorized: true,
      }
    });

});
