angular.module('sb.billing.webapp').component('sbTransactionsTrustCheques', {
  templateUrl: 'ng-routes/home/billing/accounts/transactions/trust-cheques/transactions-trust-cheques.html',
  controller: function($scope, $location, $uibModal, sbLinkService, sbAsyncOperationsService, sbLocalisationService) {
    const ctrl = this;

    ctrl.printCheque = (chequeId) => sbAsyncOperationsService.startChequeCreation([chequeId]);
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.onClickRow = onClickRow;
    ctrl.t = sbLocalisationService.t;

    $scope.$on('$locationChangeSuccess', () => {
      ctrl.sbQuery = _.cloneDeep($location.search());
    });

    function onClickRow (e, chequeId) {
      $uibModal.open({
        templateUrl: 'ng-components/trust-cheque-details/modal/trust-cheque-details-modal.html',
        controller: 'sbTrustChequeDetailsModalController',
        size: 'lg',
        resolve: {
          chequeId: () => chequeId,
        },
        backdrop: 'static',
      });
    }
  }
});