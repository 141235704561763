'use strict';

const { regionType } = require('@sb-itops/region');
const { bankAccountTypeEnum } = require('../entities/constants');
const { isBankAccountDetailsEmpty } = require('./is-bank-account-details-empty');

/**
 * Determines if we need authorisation to update bank account details.
 * @param {Object} param
 * @param {Object} param.bankAccount Bank Account entity
 * @param {boolean} param.restrictTrust Bank Account setting for restrict Trust
 * @param {boolean} param.restrictOperating Bank Account setting for restrict Operating
 * @returns {boolean}
 */
function needsAuthorisationToChangeBankAccount({ bankAccount, restrictTrust = true, restrictOperating = true }) {
  if (!bankAccount || bankAccount.accountType === bankAccountTypeEnum.CONTROLLEDMONEY) {
    return false;
  }

  // If first edit, for helping our onboarding team to set stuff up for new customers
  if (isBankAccountDetailsEmpty({ bankAccount })) {
    return false;
  }

  if (bankAccount.accountType === bankAccountTypeEnum.TRUST && !restrictTrust) {
    return false;
  }

  if (bankAccount.accountType === bankAccountTypeEnum.OPERATING && !restrictOperating) {
    return false;
  }

  return true;
}

const strategy = {
  [regionType.AU]: needsAuthorisationToChangeBankAccount,
  [regionType.GB]: needsAuthorisationToChangeBankAccount,
  [regionType.US]: () => false,
};

module.exports = {
  needsAuthorisationToChangeBankAccount: (region) => strategy[region],
};
