/* eslint-disable react/no-unstable-nested-components */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation, ContextMenu } from '@sb-itops/react';
import { ContactDisplay2 } from '@sb-customer-management/react';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { capitalize } from '@sb-itops/nodash';

import Styles from './ChequeTable.module.scss';

const { amountCellLocalisedRenderer, yyyymmddLocalisedRenderer, timestampLocalisedRenderer } = utils;

const ChequeTable = memo(
  ({
    bankAccountType,
    cheques,
    totals,
    onSort,
    sortBy,
    sortDirection,
    showSystemDate,
    showChequeMemo,
    onClickRow,
    onClickLink,
    onPrintCheque,
    dataLoading,
  }) => {
    const { t } = useTranslation();
    const contextMenuCellRenderer = ({ rowData }) =>
      // we allow the cheque to be reprinted if its been printed already AND:
      // * its a trust cheque OR
      // * its an operating cheque that hasnt been reversed/deleted
      rowData.isPrinted &&
      (bankAccountType === 'Trust' || (bankAccountType === 'Operating' && !rowData.reversed)) && (
        <ContextMenu
          body={() => (
            <div className="context-menu-body list-group">
              <button type="button" className="list-group-item" onClick={() => onPrintCheque(rowData.id)}>
                Reprint {capitalize(t('cheque'))}
              </button>
            </div>
          )}
        >
          <div className="context-menu-cell">...</div>
        </ContextMenu>
      );

    const paidToCellRenderer = ({ rowData }) => {
      if (!rowData.payTo?.id) {
        return undefined;
      }
      // For trust cheques we do not show the deleted/reversed status
      // of the cheque in the reference cell as its already shown in the desciption,
      // which operating cheques do not have.
      if (bankAccountType === 'Trust') {
        return <ContactDisplay2 contact={rowData?.payTo} showLastNameFirst asLink inline onClickLink={onClickLink} />;
      }

      if (bankAccountType === 'Operating') {
        if (rowData.isHidden) {
          return (
            <div>
              <span className={Styles.deleted}>(Deleted) </span>
              <ContactDisplay2 contact={rowData?.payTo} showLastNameFirst asLink inline onClickLink={onClickLink} />
            </div>
          );
        }

        if (rowData.reversed) {
          return (
            <div>
              <span>Reversal: </span>
              <ContactDisplay2 contact={rowData?.payTo} showLastNameFirst asLink inline onClickLink={onClickLink} />
            </div>
          );
        }
        return <ContactDisplay2 contact={rowData?.payTo} showLastNameFirst asLink inline onClickLink={onClickLink} />;
      }

      return undefined;
    };

    const matterCellRenderer = ({ rowData }) => {
      if (!rowData?.expenses?.length) {
        return undefined;
      }

      const uniqueMatterIds = new Set();
      const tooltipDisplayArray = [];
      const uniqueMatters = rowData?.expenses?.reduce((acc, expense) => {
        if (uniqueMatterIds.has(expense.matter.id)) {
          return acc;
        }
        acc.push(expense.matter);
        uniqueMatterIds.add(expense.matter.id);
        tooltipDisplayArray.push(getMatterDisplay(expense.matter, expense.matter?.matterType?.name));

        return acc;
      }, []);

      if (!uniqueMatters.length) {
        return undefined;
      }

      const tooltip = tooltipDisplayArray.join('\n\n');

      const uniqueMatterLinks = uniqueMatters.map((matter, idx) => {
        const matterDisplay = getMatterDisplay(matter, matter?.matterType?.name);
        return (
          <React.Fragment key={matter.id}>
            <LinkableText
              text={matterDisplay}
              onClickLink={() => onClickLink({ type: 'matter', id: matter.id })}
              asLink
              inline
            />{' '}
            {idx < uniqueMatters.length - 1 ? ' | ' : ''}
            {uniqueMatters.length > 1 && idx === uniqueMatters.length - 1 && (
              <span className={Styles.mattersDisplayIcon}>
                <i className="icon icon-matter-multi" title={tooltip} />
              </span>
            )}
          </React.Fragment>
        );
      });

      return uniqueMatterLinks;
    };

    return (
      <Table
        onRowClick={({ rowData }) => onClickRow(undefined, rowData.id)}
        list={cheques}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        dataLoading={dataLoading}
        summary={totals}
      >
        <Column
          dataKey="chequeDate"
          headerClassName={Styles.chequeDateHeader}
          label={`${t('capitalize', { val: 'cheque' })} Date`}
          width={105}
          cellRenderer={yyyymmddLocalisedRenderer}
        />
        {showSystemDate && (
          <Column dataKey="lastUpdated" label="Entered" width={105} cellRenderer={timestampLocalisedRenderer} />
        )}
        <Column dataKey="payeeString" label="Paid To" flexGrow={2} cellRenderer={paidToCellRenderer} />
        <Column dataKey="matter" label="Matter(s)" flexGrow={3} cellRenderer={matterCellRenderer} />
        {showChequeMemo && (
          <Column dataKey="chequeMemo" label={`${t('capitalize', { val: 'cheque' })} Memo`} flexGrow={1} />
        )}
        <Column className="right-align" dataKey="chequeNumber" label="Reference" flexGrow={1} width={150} />
        <Column
          className="right-align"
          dataKey="chequeAmount"
          label="Amount"
          width={150}
          cellRenderer={amountCellLocalisedRenderer}
          footerRenderer={amountCellLocalisedRenderer}
        />
        <Column dataKey="UNUSED" label="" disableSort width={40} cellRenderer={contextMenuCellRenderer} />
      </Table>
    );
  },
);

ChequeTable.displayName = 'ChequeTable';

ChequeTable.propTypes = {
  cheques: PropTypes.array,
  showSystemDate: PropTypes.bool,
  showDescription: PropTypes.bool,
  showChequeMemo: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  onPrintCheque: PropTypes.func,
  totals: PropTypes.object,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  bankAccountType: PropTypes.string,
  dataLoading: PropTypes.bool,
};

ChequeTable.defaultProps = {
  cheques: [],
  showSystemDate: false,
  showDescription: false,
  totals: {},
  onClickLink: () => {},
  onClickRow: () => {},
  onPrintCheque: () => {},
  onSort: () => {},
  sortBy: 'chequeDate',
  sortDirection: 'desc',
  bankAccountType: undefined,
  dataLoading: true,
};

export default ChequeTable;
