import { object as flattenedObjectToNested } from 'dot-object';

export const getIsInitialised = (state) => state.isInitialised;
export const getIsValid = (state) => state.isValid;

export const getFormState = (state, { fieldsAsValues = false, emptyArraysAsFields = true } = {}) => ({
  formInitialised: state.isInitialised,
  formValid: state.isValid,
  formDirty: state.isDirty,
  formSubmitting: state.isSubmitting,
  submitFailed: state.submitFailed,
  formValidation: state.validationErrors,
  fieldValues: fieldsAsValues ? getFieldValues(state) : undefined,
  fields: !fieldsAsValues ? getFormFields(state, { emptyArraysAsFields }) : undefined,
});

export const getFormFields = (state, { emptyArraysAsFields } = { emptyArraysAsFields: false }) => {
  const formFields = Object.entries(state.fieldValues).reduce((acc, [fieldPath, fieldValue]) => {
    if (Array.isArray(fieldValue) && fieldValue.length === 0 && !emptyArraysAsFields) {
      acc[fieldPath] = fieldValue;
      return acc;
    }

    const formField = {
      key: fieldPath,
      value: fieldValue,
      originalValue: state.initialFieldValues[fieldPath],
      isClean: !state.dirtyFields[fieldPath],
      isDirty: !!state.dirtyFields[fieldPath],
      isValid: !state.validationErrors[fieldPath],
      isInvalid: !!state.validationErrors[fieldPath],
      invalidReason: state.validationErrors[fieldPath],
    };

    acc[fieldPath] = formField;
    return acc;
  }, {});

  return flattenedObjectToNested(formFields);
};

export const getFieldValues = (state) => flattenedObjectToNested({ ...state.fieldValues });
