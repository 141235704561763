import * as types from './types';

export const savePreDraftInvoice = ({ preDraftInvoice }) => ({
  type: types.SAVE_PRE_DRAFT_INVOICE,
  payload: {
    preDraftInvoice,
  },
});

export const removePreDraftInvoice = ({ invoiceId }) => ({
  type: types.REMOVE_PRE_DRAFT_INVOICE,
  payload: { invoiceId },
});
