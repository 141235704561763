import { gql } from '@apollo/client';
import { matterBillingConfiguration } from '../fragments/matterBillingConfiguration.graphql';

// Always include relevant ID fields to enable caching
const query = gql`
  query MatterBillingConfiguration($matterId: ID!) {
    matterBillingConfiguration(id: $matterId) {
      ...matterBillingConfiguration
    }
  }

  ${matterBillingConfiguration}
`;

const notificationIds = ['BillingMattersNotifications.BillingConfigurationUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterBillingConfiguration = {
  query,
  notificationIds,
};
