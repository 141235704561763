'use strict';

const Big = require('big.js');

/**
 * Returns cheque string string without padding for UI display purpose
 * null and undefined cheque numbers are returned as empty string
 * @param {*} cheque
 * @returns
 */
function getChequeNumberString(cheque) {
  // the cheque number can be null if the user had unprinted trust cheques, then turned off trust cheque printing
  // the cheque number can be undefined if the user hasnt printed the cheque yet.
  if (cheque.chequeNumber === null || cheque.chequeNumber === undefined) {
    return '';
  }

  if (/^\d+$/.test(cheque.chequeNumber)) {
    // we need to use big to handle big number string
    // we need to convert it to big first then back to string instead of use string directly
    // because for "0001" we want to show "1" in table UI for trust checks list
    const chequeNumberBig = new Big(cheque.chequeNumber);

    return chequeNumberBig.toString();
  }

  // the user has entered a cheque number that is not a number (technically allowed)
  return cheque.chequeNumber.trim();
}

module.exports = {
  getChequeNumberString,
};
