'use strict';

const { resourceIds } = require('./resource-ids');

const policyTypes = {
  ALLOW: 'allow',
  DENY: 'deny',
};

const policyReferenceByResource = {
  [resourceIds.BILLING_DATA_FIRM_ACCOUNTS]: 'BillingViewAccountsPolicy',
  [resourceIds.BILLING_DATA_FIRM_REPORTS]: 'BillingViewReportsPolicy',
};

module.exports = {
  policyTypes,
  policyReferenceByResource,
};
