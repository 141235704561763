'use strict';

import { getAccountId } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';

import xeroLogo from '../../assets/integration-xero.png'

angular.module('sb.billing.webapp').component('sbXeroLogin', {
  templateUrl: 'ng-components/xero/xero-login.html',
  bindings: { 
    isConnected: '<', 
    onLoginFailure: '<?', 
    onLoginStarted: '<?', 
    onLoginSuccess: '<?', 
  },
  controller: function ($scope, sbLoggerService, sbEnvironmentConfigService, sbMessageDisplayService, sbNotifiedOperationP) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbXeroLogin');

    ctrl.integration = 'xero';
    ctrl.logoSrc = xeroLogo;
    ctrl.onConnect = onConnect;
    ctrl.onConnectClick = onConnectClick;
    ctrl.xeroUrl = '';
    ctrl.showConfirmationModal = () => {}; // noop, is overwritten by modal

    ctrl.$postLink = () => {
      const accountId = getAccountId();
      const smokeballDomain = sbEnvironmentConfigService.getDotNetHostUrl();
      ctrl.xeroUrl = `${smokeballDomain}/integration/Xero/XeroHome?accountId=${accountId}`;
    };

    function onConnectClick(e) {
      if (!featureActive('BB-13236')) {
        onConnect();
        return;
      }

      // The Connect button is part of React component and opens the url upon click. To show confirmation modal first,
      // we block the click event propagation and show confirmation modal instead. If user confirms, we open the url from here.
      e.preventDefault();
      e.stopPropagation();
      ctrl.showConfirmationModal().then(() => {
        // This is resolved only when primary button (confirm) is clicked
        window.open(ctrl.xeroUrl, '_blank', 'noopener,noreferrer');
        onConnect();
      });
    }

    async function onConnect() {
      try {
        ctrl.onLoginStarted();
        await waitForAuthedNotificationP();
        ctrl.onLoginSuccess();
      } catch (err) {
        log.error('Xero authorisation failed', err);
        ctrl.onLoginFailure();
      }
    }

    async function waitForAuthedNotificationP() {
      await sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'XeroIntegration',
        completionFilterFn: message => message.messageId === 'XeroAccessTokenUpdated',
        timeoutMs: 60000,
      });
    }
  }
});
