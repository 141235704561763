'use strict';

const {
  onSaveMemory,
  saveMemory,
  loadMemory,
  clearMemory,
  changesExist,
} = require('./index');

angular.module('@sb-itops/services').service('sbUnsavedChangesService', function() {
  const that = this;

  that.onSaveMemory = onSaveMemory;
  that.saveMemory = saveMemory;
  that.loadMemory = loadMemory;
  that.clearMemory = clearMemory;
  that.changesExist = changesExist;

});
