import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { StatelessSelect } from '../select';
import { Button, buttonTypes } from '../button';
import Styles from './TemplateMacroSelector.module.scss';

// eslint-disable-next-line arrow-body-style
export const TemplateMacroSelector = memo(
  ({ selectedMacroName, macros, isDisabled, onMacroChanged, onMacroInserted }) => {
    const statelessSelectOptions = useMemo(
      () => macros.map(({ name, tooltip }) => ({ value: name, label: `${name} - ${tooltip}` })),
      [macros],
    );
    const tooltipByMacroName = useMemo(
      () =>
        macros.reduce((acc, { name, tooltip }) => {
          acc[name] = tooltip;
          return acc;
        }, {}),
      [macros],
    );

    return (
      <div className={Styles.templateMacroSelector}>
        <div className={Styles.selector}>
          <StatelessSelect
            disabled={isDisabled}
            placeholder="Select placeholder here..."
            selectedOption={
              selectedMacroName && {
                value: selectedMacroName,
                label: `${selectedMacroName} - ${tooltipByMacroName[selectedMacroName]}`,
              }
            }
            options={statelessSelectOptions}
            onChange={(option) => onMacroChanged(option.value)}
            isClearable={false}
          />
        </div>
        <div className={Styles.insertButton}>
          <Button onClick={() => onMacroInserted(selectedMacroName)} type={buttonTypes.link}>
            INSERT
          </Button>
        </div>
      </div>
    );
  },
);

TemplateMacroSelector.displayName = 'TemplateMacroSelector';

TemplateMacroSelector.propTypes = {
  selectedMacroName: PropTypes.string,
  macros: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDisabled: PropTypes.bool,
  onMacroChanged: PropTypes.func,
  onMacroInserted: PropTypes.func,
};

TemplateMacroSelector.defaultProps = {
  selectedMacroName: undefined,
  isDisabled: false,
  onMacroChanged: () => {},
  onMacroInserted: () => {},
};

export default TemplateMacroSelector;
