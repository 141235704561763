'use strict';

const accountTypeOptions = [
  { label: 'Company', value: 'Company' },
  { label: 'Individual', value: 'Individual' },
];

module.exports = {
  accountTypeOptions,
};
