import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getCustomTextTitleMacros } from '@sb-billing/business-logic/invoice-settings';
import { featureActive } from '@sb-itops/feature';
import { TemplateMacroSelector, useTranslation } from '@sb-itops/react';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { getSelectedTemplateId } from 'web/redux/route/home-settings-invoice';
import { invoiceSettingTitleOptions } from './helpers';

const InvoiceSettingTitle = memo(
  ({
    selectedOption,
    selectedText,
    onChangeOption,
    onChangeText,
    showFirmOption,
    showResetButton,
    defaultText,
    defaultSelectedOption,
    clickedResetToTemplateDefault,
    setClickedResetToTemplateDefault,
  }) => {
    const { t } = useTranslation();
    const isPlaceholderEnabled = featureActive('BB-12386');
    const templateId = getSelectedTemplateId();
    const customTextMacros = getCustomTextTitleMacros({ t });
    const customTextRef = useRef(null);
    const [isCustomClicked, setCustomClicked] = useState(false);
    const [showCustomTextInput, setShowCustomTextInput] = useState(
      selectedOption === invoiceSettingTitleOptions.custom,
    );
    const [selectedTitleMacros, setSelectedTitleMacros] = useState('');
    // Use selectedTextValue instead of using selectedText directly to avoid cursor moves to the end of the text while editing
    // This only happens when this component is embedded in an angular component invoice-settings-invoices.html
    // We can remove the useState once we migrate the firm invoice template page to React
    const [selectedTextValue, setSelectedTextValue] = useState(selectedText);

    const handleCustomTextInputDisplay = useCallback(
      (option) => {
        if (option === invoiceSettingTitleOptions.custom) {
          setShowCustomTextInput(true);
        } else if (showCustomTextInput) {
          setShowCustomTextInput(false);
        }
      },
      [showCustomTextInput],
    );

    const onTitleClickHandler = useCallback((e) => onChangeOption(e.target.parentElement.id), [onChangeOption]);

    const checkedHandler = useCallback(
      (e) => {
        e.stopPropagation();
        onChangeOption(e.target.value);
      },
      [onChangeOption],
    );

    useEffect(() => {
      // we need to prevent auto-focus on load as it would cause unwanted scroll to focused element
      if (isCustomClicked && showCustomTextInput) {
        customTextRef.current.focus();
        setCustomClicked(false);
      }
    }, [isCustomClicked, showCustomTextInput]);

    useEffect(() => {
      if (clickedResetToTemplateDefault) {
        handleCustomTextInputDisplay(selectedOption);
        setClickedResetToTemplateDefault(false);
      }
    }, [clickedResetToTemplateDefault, selectedOption, setClickedResetToTemplateDefault, handleCustomTextInputDisplay]);

    useEffect(() => {
      handleCustomTextInputDisplay(selectedOption);
    }, [handleCustomTextInputDisplay, selectedOption, templateId]);

    // Update selectedText value when switching between templates in Firm Settings
    // We can remove this useEffect once we migrate the firm invoice template page to React
    useEffect(() => {
      setSelectedTextValue(selectedText);
    }, [selectedText]);

    const textChangeHandler = useCallback(
      (value) => {
        onChangeText(value);
        setSelectedTextValue(value);
      },
      [onChangeText],
    );

    const resetHandler = useCallback(() => {
      const resetValueOption = showFirmOption ? invoiceSettingTitleOptions.firm : defaultSelectedOption;
      onChangeOption(resetValueOption);
      onChangeText(defaultText);
      setSelectedTextValue(defaultText);
    }, [defaultSelectedOption, defaultText, onChangeOption, onChangeText, showFirmOption]);

    return (
      <>
        <div className="title-radio list-v-summary invoice-setting-row">
          {showFirmOption && (
            <span
              className="single-line"
              id={invoiceSettingTitleOptions.firm}
              role="button"
              tabIndex={0}
              onClick={onTitleClickHandler}
            >
              <input
                type="radio"
                checked={selectedOption === invoiceSettingTitleOptions.firm}
                onChange={checkedHandler}
                value={invoiceSettingTitleOptions.firm}
              />
              <label>Firm</label>
            </span>
          )}
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.none}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectedOption === invoiceSettingTitleOptions.none}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.none}
            />
            <label>None</label>
          </span>
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.matterTitle}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectedOption === invoiceSettingTitleOptions.matterTitle}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.matterTitle}
            />
            <label>Matter Title</label>
          </span>
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.matterDescription}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectedOption === invoiceSettingTitleOptions.matterDescription}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.matterDescription}
            />
            <label>Matter Description</label>
          </span>
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.custom}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              setCustomClicked(true);
              onTitleClickHandler(e);
            }}
          >
            <input
              type="radio"
              checked={selectedOption === invoiceSettingTitleOptions.custom}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.custom}
            />
            <label>Custom text</label>
          </span>
          {showCustomTextInput && (
            <>
              <div className="input-group">
                <input
                  ref={customTextRef}
                  type="text"
                  className="form-control title-line-text zero-zindex"
                  onChange={(e) => textChangeHandler(e.target.value)}
                  onBlur={(e) => textChangeHandler(e.target.value)}
                  name="titleLine2CustomText"
                  value={selectedTextValue}
                />
              </div>
              {isPlaceholderEnabled && (
                <div className="title-placeholder-selector">
                  <TemplateMacroSelector
                    selectedMacroName={selectedTitleMacros}
                    macros={customTextMacros}
                    onMacroChanged={setSelectedTitleMacros}
                    onMacroInserted={(macroName) => {
                      if (!customTextRef) return;
                      const selectionStart = customTextRef.current.selectionStart;
                      const selectionEnd = customTextRef.current.selectionEnd;
                      // insert the macroName
                      const value = selectedTextValue;
                      const newValue =
                        value.substring(0, selectionStart) + macroName + value.substring(selectionEnd, value.length);
                      textChangeHandler(newValue);
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {showResetButton && selectedOption !== invoiceSettingTitleOptions.firm && (
          <LinkableText asLink text="Reset" onClickLink={resetHandler} inline />
        )}
      </>
    );
  },
);

InvoiceSettingTitle.displayName = 'InvoiceSettingTitle';

InvoiceSettingTitle.propTypes = {
  selectedOption: PropTypes.string,
  selectedText: PropTypes.string,
  selectedMatterDescriptionText: PropTypes.string,
  defaultSelectedOption: PropTypes.string,
  defaultText: PropTypes.string,
  onChangeOption: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  showFirmOption: PropTypes.bool,
  showResetButton: PropTypes.bool,
  clickedResetToTemplateDefault: PropTypes.bool,
  setClickedResetToTemplateDefault: PropTypes.func,
};

InvoiceSettingTitle.defaultProps = {
  selectedOption: undefined,
  selectedText: undefined,
  selectedMatterDescriptionText: undefined,
  defaultSelectedOption: undefined,
  defaultText: undefined,
  showFirmOption: false,
  showResetButton: false,
  clickedResetToTemplateDefault: false,
  setClickedResetToTemplateDefault: () => {},
};

export default InvoiceSettingTitle;
