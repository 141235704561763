/* eslint-disable max-classes-per-file */

'use strict';

/**
 * AuthenticationFailureError
 */
class AuthenticationFailureError extends Error {
  /**
   * constructor
   * Instantiates an AuthenticationFailure error object based on a FetchError.
   * @param {string} message Message describing the reason for the failure.
   * @param {Object} failureDetails Object containing details of the failure.
   */
  constructor(message, failureDetails) {
    super(message);

    this.name = this.constructor.name;
    this.failureDetails = failureDetails;
  }
}

class ChallengeResponseError extends Error {
  constructor(message, type) {
    super(message);

    this.name = this.constructor.name;
    this.type = type;
  }
}

module.exports = {
  AuthenticationFailureError,
  ChallengeResponseError,
};
