import { fetchGetP } from '@sb-itops/redux/fetch';

export const clientSdkGetMatterItems = async (matterId: string) => {
  const path = `/matter-management/matter/items/:accountId/${matterId}`;

  const responseData = await fetchGetP({ path });

  const mapItems = (i) => ({
    id: i.id,
    name: i.name,
    index: i.index,
    visible: i.visible,
    subItems: i.subItems ? i.subItems.map(mapItems) : [],
  });

  const result = {
    id: matterId,
    items: responseData.body.items.map(mapItems),
  };

  return result;
};
