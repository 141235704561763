import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from '@sb-itops/react/typeahead.2';

const ContactTypeahead = ({
  contacts,
  selectedContactId,
  disabled,
  onContactSelected,
  onAdd,
  placeholder,
  className,
  showAdd,
  hasError,
  actionList,
  maxMenuHeight,
  isAddActive,
  isClearable,
}) => {
  const options = useMemo(() => {
    if (contacts.length === 0) {
      return [];
    }
    return contacts.map((contact) => ({
      label: contact.display || contact.displayName,
      value: contact.id,
      searchText: contact.typeahead,
      data: contact,
    }));
  }, [contacts]);

  const selectedOption = useMemo(() => {
    if (options.length === 0 || !selectedContactId) {
      return null; // null is required to clear contact select, undefine will not
    }

    return options.find((option) => option.value === selectedContactId);
  }, [options, selectedContactId]);

  return (
    <Typeahead
      options={options}
      disabled={disabled}
      selectedOption={selectedOption}
      onSelect={onContactSelected}
      onAdd={onAdd}
      placeholder={placeholder}
      className={className}
      showAdd={showAdd}
      hasError={hasError}
      isAddActive={isAddActive}
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      actionList={actionList}
    />
  );
};

ContactTypeahead.displayName = 'ContactTypeahead';
const actions = PropTypes.shape({
  displayComponent: PropTypes.any,
  callback: PropTypes.func,
});

ContactTypeahead.propTypes = {
  selectedContactId: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onContactSelected: PropTypes.func,
  onClear: PropTypes.func,
  onAdd: PropTypes.func,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.any,
      displayName: PropTypes.any,
      id: PropTypes.string.isRequired,
    }),
  ),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showAdd: PropTypes.bool,
  hasError: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  actionList: PropTypes.arrayOf(actions),
  isAddActive: PropTypes.bool,
  isClearable: PropTypes.bool,
};

ContactTypeahead.defaultProps = {
  selectedContactId: undefined,
  placeholder: 'Select a contact...',
  name: undefined,
  onContactSelected: () => {},
  onAdd: () => {},
  onClear: undefined,
  contacts: [],
  className: undefined,
  disabled: false,
  showAdd: false,
  hasError: false,
  maxMenuHeight: undefined,
  actionList: [],
  isAddActive: false,
  isClearable: true,
};

export default ContactTypeahead;
