import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { featureActive } from '@sb-itops/feature';
import { getSettings } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountSettingsService',
  keyPath: 'accountId',
});

export const saveSettings = (bankAccountSettings) => {
  const currentSettings = getCurrentSettings();
  const newSettings = { ...currentSettings, ...bankAccountSettings };

  return async () => {
    opdateCache({ optimisticEntities: [newSettings] });

    try {
      const path = `/billing/bank-account-settings/:accountId/`;
      const fetchOptions = { body: JSON.stringify(newSettings) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [currentSettings] }); // This should really be the optimistic entity, but the code for opdating is truly baked.
      throw err;
    }
  };
};

const getCurrentSettings = () => {
  const currentSettings = { ...getSettings() };
  // The flags below are saved anytime we save Trust Account Settings and exist for new accounts.
  // They may not exist if user haven't saved trust setting for very long time so below we make sure they exist
  // We use feature flags to match validation in endpoints/bank-account-settings/route-bank-account-settings-save.js
  currentSettings.createPDFReceiptOnDeposit = !!currentSettings.createPDFReceiptOnDeposit;
  currentSettings.createPDFReceiptOnTrustPayment = !!currentSettings.createPDFReceiptOnTrustPayment;
  currentSettings.createPDFReceiptOnOperatingPayment = !!currentSettings.createPDFReceiptOnOperatingPayment;
  if (featureActive('BB-7634')) {
    currentSettings.createPDFReceiptOnMatterTransfer = !!currentSettings.createPDFReceiptOnMatterTransfer;
  }
  return currentSettings;
};
