'use strict';

const { periods } = require('@sb-itops/date');

const { ALL_TIME, THIS_WEEK, LAST_WEEK, THIS_MONTH, LAST_MONTH, CUSTOM } = periods;

const periodOption = Object.freeze({
  ALL_TIME: { value: ALL_TIME, label: 'All Time' },
  THIS_WEEK: { value: THIS_WEEK, label: 'This Week' },
  LAST_WEEK: { value: LAST_WEEK, label: 'Last Week' },
  THIS_MONTH: { value: THIS_MONTH, label: 'This Month' },
  LAST_MONTH: { value: LAST_MONTH, label: 'Last Month' },
  CUSTOM: { value: CUSTOM, label: 'Custom' },
});

const periodOptions = [
  periodOption.ALL_TIME,
  periodOption.THIS_WEEK,
  periodOption.LAST_WEEK,
  periodOption.THIS_MONTH,
  periodOption.LAST_MONTH,
  periodOption.CUSTOM,
];

module.exports = {
  periodOption,
  periodOptions,
};
