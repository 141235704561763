import { getLogger } from '@sb-itops/fe-logger';
import { BANK_BALANCE_TYPE } from '../bank-account-settings';
import { getBankAccountMap } from './selectors';

const log = getLogger('bank-account-balances.2/opdates');

/**
 * returns an opdate object for the bank account balance
 * @param {*} param
 * @param {object|undefined} param.opdates list of opdates keyed by entity type
 * @param {object} param.transaction the opdate transaction details
 * @param {object} param.bankAccount the bank account balance entity,
 * @param {string} param.balanceType
 * @param {boolean} param.allowOverdraw
 * @return {object} the opdated bank account
 */
export const getBankAccountBalanceOpdate = ({
  transaction,
  bankAccountBalance,
  balanceType,
  allowOverdraw = false,
}) => {
  const { cents, contactId, matterId } = transaction;

  if (!Number.isInteger(cents)) {
    throw new Error(`Invalid cents: ${cents}`);
  }
  if (balanceType === BANK_BALANCE_TYPE.matterContact && !contactId) {
    throw new Error(`Invalid contactId: ${contactId}`);
  }
  if (!matterId) {
    throw new Error(`Invalid matterId: ${matterId}`);
  }

  const bankAccountBalanceOpdate = JSON.parse(JSON.stringify(bankAccountBalance));
  bankAccountBalanceOpdate.contactBalances = bankAccountBalanceOpdate.contactBalances || [];
  bankAccountBalanceOpdate.matterBalances = bankAccountBalanceOpdate.matterBalances || [];

  let contactBalanceOpdate = bankAccountBalanceOpdate.contactBalances.find(
    (cb) => cb.contactId === contactId && cb.matterId === matterId,
  );

  if (!contactBalanceOpdate) {
    contactBalanceOpdate = {
      contactId,
      matterId,
      balance: 0,
      availableBalance: 0,
      protectedBalance: 0,
    };
    bankAccountBalanceOpdate.contactBalances.push(contactBalanceOpdate);
  }

  let matterBalanceOpdate = bankAccountBalanceOpdate.matterBalances.find((balance) => balance.matterId === matterId);

  if (!matterBalanceOpdate) {
    matterBalanceOpdate = {
      matterId,
      balance: 0,
      availableBalance: 0,
      protectedBalance: 0,
    };
    bankAccountBalanceOpdate.matterBalances.push(matterBalanceOpdate);
  }

  if (!allowOverdraw) {
    if (balanceType === BANK_BALANCE_TYPE.matterContact && contactBalanceOpdate.balance + cents < 0) {
      log.error(
        `contact balance cannot be less than 0 after adding totalProtectionAmount, contact balance: ${JSON.stringify(
          contactBalanceOpdate,
        )}, cents: ${cents}`,
      );
      throw new Error(`balance cannot be less than 0 after adding totalProtectionAmount`);
    }

    if (matterBalanceOpdate.balance + cents < 0) {
      log.error(
        `matter balance cannot be less than 0 after adding totalProtectionAmount, matter balance: ${JSON.stringify(
          matterBalanceOpdate,
        )}, cents: ${cents}`,
      );
      throw new Error(`balance cannot be less than 0 after adding totalProtectionAmount`);
    }

    if (bankAccountBalance.balance + cents < 0) {
      log.error(
        `account balance cannot be less than 0 after adding totalProtectionAmount, bank account: ${bankAccountBalanceOpdate.id}, cents: ${cents}`,
      );
      throw new Error(`balance cannot be less than 0 after adding totalProtectionAmount`);
    }
  }

  bankAccountBalanceOpdate.balance += cents;
  matterBalanceOpdate.balance += cents;
  contactBalanceOpdate.balance += cents;

  return bankAccountBalanceOpdate;
};

/**
 * returns an opdate object for the bank account balance
 * @param {*} param
 * @param {object} param.protectionDetails the opdate protection requests
 * @param {string} param.bankBalanceType matter contact or matter
 * @param {boolean} param.unprotect if true, will unprotect these funds from balances instead
 * @return {object} the opdated bank account
 */
export const getBankAccountBalanceProtectionOpdate = ({ protectionDetails, bankBalanceType, unprotect = false }) => {
  const { bankAccountId, contactId, matterId } = protectionDetails;

  if (bankBalanceType === BANK_BALANCE_TYPE.matterContact && !contactId) {
    throw new Error(`Invalid contactId: ${contactId}`);
  }
  if (!matterId) {
    throw new Error(`Invalid matterId: ${matterId}`);
  }

  const bankAccountBalance = getBankAccountMap()[bankAccountId];
  const bankAccountBalanceOpdate = JSON.parse(JSON.stringify(bankAccountBalance));
  bankAccountBalanceOpdate.contactBalances = bankAccountBalanceOpdate.contactBalances || [];
  bankAccountBalanceOpdate.matterBalances = bankAccountBalanceOpdate.matterBalances || [];

  const totalProtectionAmount = protectionDetails.protectItems.reduce((acc, item) => acc + item.amount, 0);

  if (!Number.isInteger(totalProtectionAmount)) {
    throw new Error(`Invalid cents: ${totalProtectionAmount}`);
  }

  let contactBalanceOpdate = bankAccountBalanceOpdate.contactBalances.find(
    (cb) => cb.contactId === contactId && cb.matterId === matterId,
  );

  if (!contactBalanceOpdate) {
    contactBalanceOpdate = {
      contactId,
      matterId,
      balance: 0,
      availableBalance: 0,
      protectedBalance: 0,
    };
    bankAccountBalanceOpdate.contactBalances.push(contactBalanceOpdate);
  }

  let matterBalanceOpdate = bankAccountBalanceOpdate.matterBalances.find((balance) => balance.matterId === matterId);

  if (!matterBalanceOpdate) {
    matterBalanceOpdate = {
      matterId,
      balance: 0,
      availableBalance: 0,
      protectedBalance: 0,
    };
    bankAccountBalanceOpdate.matterBalances.push(matterBalanceOpdate);
  }

  if (!unprotect) {
    if (
      bankBalanceType === BANK_BALANCE_TYPE.matterContact &&
      contactBalanceOpdate.availableBalance - totalProtectionAmount < 0
    ) {
      log.error(
        `contact available balance cannot be less than 0 after adding totalProtectionAmount, contact balance: ${JSON.stringify(
          contactBalanceOpdate,
        )}, totalProtectionAmount: ${totalProtectionAmount}`,
      );
      throw new Error(`balance cannot be less than 0 after adding totalProtectionAmount`);
    }

    if (matterBalanceOpdate.availableBalance - totalProtectionAmount < 0) {
      log.error(
        `matter available balance cannot be less than 0 after adding totalProtectionAmount, matter balance: ${JSON.stringify(
          matterBalanceOpdate,
        )}, totalProtectionAmount: ${totalProtectionAmount}`,
      );
      throw new Error(`balance cannot be less than 0 after adding totalProtectionAmount`);
    }

    bankAccountBalanceOpdate.availableBalance -= totalProtectionAmount;
    bankAccountBalanceOpdate.protectedBalance += totalProtectionAmount;
    matterBalanceOpdate.availableBalance -= totalProtectionAmount;
    matterBalanceOpdate.protectedBalance += totalProtectionAmount;
    contactBalanceOpdate.availableBalance -= totalProtectionAmount;
    contactBalanceOpdate.protectedBalance += totalProtectionAmount;
  } else {
    if (
      bankBalanceType === BANK_BALANCE_TYPE.matterContact &&
      contactBalanceOpdate.protectedBalance - totalProtectionAmount < 0
    ) {
      log.error(
        `contact protected balance cannot be less than 0 after unprotecting totalProtectionAmount, contact balance: ${JSON.stringify(
          contactBalanceOpdate,
        )}, totalProtectionAmount: ${totalProtectionAmount}`,
      );
      throw new Error(`protected balance cannot be less than 0 after unprotecting totalProtectionAmount`);
    }

    if (matterBalanceOpdate.protectedBalance - totalProtectionAmount < 0) {
      log.error(
        `matter protected balance cannot be less than 0 after unprotecting totalProtectionAmount, matter balance: ${JSON.stringify(
          matterBalanceOpdate,
        )}, totalProtectionAmount: ${totalProtectionAmount}`,
      );
      throw new Error(`protected balance cannot be less than 0 after unprotecting totalProtectionAmount`);
    }

    if (bankAccountBalance.protectedBalance - totalProtectionAmount < 0) {
      log.error(
        `account protected balance cannot be less than 0 after unprotecting totalProtectionAmount, bank account: ${bankAccountBalanceOpdate.id}, totalProtectionAmount: ${totalProtectionAmount}`,
      );
      throw new Error(`protected balance cannot be less than 0 after unprotecting totalProtectionAmount`);
    }

    bankAccountBalanceOpdate.availableBalance += totalProtectionAmount;
    bankAccountBalanceOpdate.protectedBalance -= totalProtectionAmount;
    matterBalanceOpdate.availableBalance += totalProtectionAmount;
    matterBalanceOpdate.protectedBalance -= totalProtectionAmount;
    contactBalanceOpdate.availableBalance += totalProtectionAmount;
    contactBalanceOpdate.protectedBalance -= totalProtectionAmount;
  }

  return bankAccountBalanceOpdate;
};

/**
 * @param {string} bankAccountId
 * @returns {BankAccount}
 */
export const getTemplateBankAccount = (bankAccountId) => ({
  isDeleted: false,
  isInactive: false,
  id: bankAccountId,
  balance: 0,
  contactBalances: [],
  matterBalances: [],
});
