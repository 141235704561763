angular
  .module('sb.billing.webapp')
  .factory(
    'downloadTransactionDetailsPdfOp',
    (
      $rootScope,
      $http,
      sbGenericEndpointService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
    ) => {
      const log = sbLoggerService.getLogger('downloadTransactionDetailsPdfOp');
      return (transactionData) => {
        // Object representing the current state of this operation.
        const operation = {
          label: `Transaction Details PDF`,
          description: transactionData.description,
          isComplete: false,
          error: null,
          fileData: null,
        };

        // No need to await here, the async state updates are manged inside downloadTransactionDetailsP,
        // i.e. downloadTransactionDetailsP updates the operation object.
        downloadTransactionDetailsP(operation, transactionData);

        return operation;
      };

      async function downloadTransactionDetailsP(operation, transactionData) {
        const requestId = sbUuidService.get();

        try {
          // Kick off the operattion
          const { payload } = await sbNotifiedOperationP(() => buildTransactionDetailsPdfP(requestId, transactionData), {
            requestId,
            completionNotification: 'TransactionDetailsPdfComplete',
            errorNotification: 'TransactionDetailsPdfFailure',
            timeoutMs: 30000,
          });

          const request = {
            method: 'GET',
            url: payload.pdfUrl,
            responseType: 'arraybuffer'
          };

          const pdfFileResponse = await $http(request);
          operation.fileData = new Blob([pdfFileResponse.data], {type: 'application/pdf'});
        
        } catch (err) {
          log.error(`Failed to download transaction details pdf for requestId: ${requestId}`, err);
          operation.error = err;
        
        } finally {
          $rootScope.$applyAsync(() => {
            operation.isComplete = true;
          });
        }

        // Returns a promise for combining of invoices into a single PDF. Used by sbNotifiedOperation to begin processing.
        function buildTransactionDetailsPdfP(requestId, transactionData) {
          const requestData = {
            requestId,
            ...transactionData,
          };

          return sbGenericEndpointService.postPayloadP('billing/transaction-details-pdf', 'create-pdf', requestData, 'POST');
        }
      }
    }
  );
