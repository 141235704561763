import validator from 'validator';
import uuid from '@sb-itops/uuid';
import {
  isBulkDepositReversalId,
  getBulkDepositIdFromReversalId,
} from '@sb-billing/business-logic/transactions/services';

export function marshalRecon({
  startDate,
  endDate,
  openingLedgerBalance,
  bankStatementBalance,
  trustAccountId,
  consolidatedTransactions,
  adjustments,
  selectedDepositSlipIds,
  selectedTransactionIds,
  selectedConsolidatedIds,
  existingDraftBankReconciliation,
}) {
  const reconciledDate = moment(new Date()).format('YYYYMMDD');
  const { selectedTtoChildTxns, selectedBulkDepositIds, selectedReversedBulkDepositIds } =
    selectedConsolidatedIds.reduce(
      (acc, consolidatedId) => {
        const consolidatedTransaction = consolidatedTransactions.find((ct) => ct.id === consolidatedId);
        if (consolidatedTransaction.isTrustToOffice) {
          acc.selectedTtoChildTxns = acc.selectedTtoChildTxns.concat(
            consolidatedTransactions.find((tto) => tto.id === consolidatedId).transactionIds,
          );
        }
        if (consolidatedTransaction.isBulkDeposit) {
          if (isBulkDepositReversalId(consolidatedTransaction.id)) {
            const bulkDepositId = getBulkDepositIdFromReversalId(consolidatedTransaction.id);
            acc.selectedReversedBulkDepositIds.push(bulkDepositId);
          } else {
            acc.selectedBulkDepositIds.push(consolidatedTransaction.id);
          }
        }
        return acc;
      },
      { selectedTtoChildTxns: [], selectedBulkDepositIds: [], selectedReversedBulkDepositIds: [] },
    );
  const transactionIds = [...selectedTransactionIds, ...selectedTtoChildTxns];
  const opdateId = uuid();
  return {
    startDate,
    endDate,
    bankStatementBalance,
    trustAccountId,
    bankAccountId: trustAccountId,
    reconciledDate,
    transactionIds,
    depositSlipIds: selectedDepositSlipIds.filter((id) => validator.isUUID(id)),
    bulkDepositIds: selectedBulkDepositIds.filter((id) => validator.isUUID(id)),
    bulkDepositReversalIds: selectedReversedBulkDepositIds.filter((id) => validator.isUUID(id)),
    adjustments: adjustments.filter((adj) => !adj.isDeleted),
    id: existingDraftBankReconciliation?.id || opdateId,
    reconciliationId: existingDraftBankReconciliation?.id || opdateId,
    opdateId: existingDraftBankReconciliation?.id || opdateId,
    // backend ignores it but it is needed in opdate otherwise the recon
    // summary panel "Opening Cash Book Balance" resets to 0 while saving
    openingLedgerBalance,
  };
}
