import * as React from 'react';
import PropTypes from 'prop-types';

import Styles from './SetupUserWarningBanner.module.scss';

export const SetupUserWarningBanner = ({ isStaffMember, userName }) => {
  if (isStaffMember) {
    return null;
  }

  return (
    <div
      className={Styles.setupUserWarningBanner}
    >{`${userName} is not a valid staff member - Please contact the Smokeball help desk`}</div>
  );
};

SetupUserWarningBanner.propTypes = {
  isStaffMember: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

SetupUserWarningBanner.defaultProps = {
  userName: '',
};

SetupUserWarningBanner.displayName = 'SetupUserWarningBanner';
