import { gql } from '@apollo/client';
import { sortByOrder } from '@sb-itops/nodash';
import { contactTableFragment } from '../fragments/contactTableFragment.graphql';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query BillingContactsRouteData($contactFilter: ContactFilter, $offset: Int, $limit: Int, $sort: ListSort) {
    contactList(filter: $contactFilter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        ...contactTableFragment
      }
    }
  }

  ${contactTableFragment}
`;

const notificationIds = ['CustomerManagementWebQuery.EntityUpdated'];

const onOpdateQuery = ({ queryData, queryVariables, entityOpdateEvent }) => {
  const { eventType, typeName, optimisticEntity } = entityOpdateEvent;

  // Ignore irrelevant entity types / operations.
  if (`${eventType}-${typeName}` !== 'ENTITY_ADDED-Contact') {
    return undefined;
  }

  // Ignore optimistic entities which do not fit within the current filter set.
  const onlyShowClients = queryVariables.contactFilter.includeRoles.includes('CLIENT');
  if (onlyShowClients && !optimisticEntity.roles?.some((role) => role.roleId.toUpperCase() === 'CLIENT')) {
    return undefined;
  }

  if (!queryVariables.contactFilter.includeStatus.deleted && optimisticEntity.removed) {
    return undefined;
  }

  if (queryVariables.contactFilter.excludeTypes.includes(optimisticEntity.type)) {
    return undefined;
  }

  // Ignore optimistic entities which do not sit within the currently viewed page of data.
  const { fieldNames: sortFields, directions: sortOrders } = queryVariables.sort;
  const updatedListItems = [optimisticEntity, ...queryData.contactList.results];
  const sortedListItems = sortByOrder(updatedListItems, sortFields, sortOrders, false /* case insensitive */);

  if (sortedListItems[0] === optimisticEntity || sortedListItems[sortedListItems.length - 1] === optimisticEntity) {
    return undefined;
  }

  // Return the new opdated data for the query.
  return {
    ...queryData,
    contactList: {
      ...queryData.contactList,
      results: sortedListItems,
    },
  };
};

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BillingContactsRouteData = {
  query,
  notificationIds,
  onOpdateQuery,
};
