angular.module('sb.billing.webapp').directive('sbActionPrintTrustCheques', function (sbTrustChequeService, sbLoggerService, sbMessageDisplayService, sbAsyncOperationsService, sbLocalisationService) {
  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      const log = sbLoggerService.getLogger('sbActionPrintTrustCheques');
      ctrl.group.setAction(printCheques);

      function printCheques (data) {
        if (data) {
          ctrl.group.setLock(true);
          sbTrustChequeService.flagChequesAsPrintedP(data)
            .then(() => {
              sbAsyncOperationsService.startChequeCreation(data.trustCheques.map((trustCheque) => trustCheque.chequeId));
            })
            .catch(err => {
              log.error(err);
              sbMessageDisplayService.error(`Failed to create ${sbLocalisationService.t('cheques')} PDF`);
            })
            .finally(() => {
              ctrl.group.setLock(false);
            });
        }
      }
    }
  };
});
