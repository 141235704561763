import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getRegion } from '@sb-itops/region';
import { FIXED } from '@sb-billing/business-logic/shared/entities';
import { calculateFeeAmountTax } from '@sb-billing/business-logic/fee/services';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { FeeTable } from './FeeTable';

const REGION = getRegion();

/**
 * Calculate additional data for each fee entry
 * @param params
 * @param {Object[]} params.originalFees
 * @param {number} params.firmTaxRateBasisPoints either invoice or firm tax rate
 */
const calculateFeesAndSummary = ({ originalFees, firmTaxRateBasisPoints, region }) => {
  let totalUnits = 0;
  let totalAmountBillable = 0;
  let totalAmountNonBillable = 0;
  let totalAmountWaived = 0;
  let totalAmountBilled = 0;

  const fees = originalFees.map((originalFee) => {
    const fee = { ...originalFee };
    const invoice = fee.invoice;

    // calculateFeeAmountTax will apply the tax provided in the fee object
    // if there is no `tax` provided, it needs the taxRate
    const taxRate = invoice?.currentVersion?.feeTaxRate || firmTaxRateBasisPoints;
    const {
      amountExclTax,
      tax,
      billableAmountExclTax,
      nonBillableAmountExclTax,
      writtenOffAmountExclTax,
      billedAmountExclTax,
    } = calculateFeeAmountTax({ fee, taxRate, region });

    // fee.onFinalisedInvoice = !!invoice && ['PAID', 'FINAL'].includes(invoice.status);
    fee.amountExclTax = amountExclTax;
    fee.total = amountExclTax + tax;

    // helps rounding 1 minute durations up to the 2 decimal points to match the individual fee entry
    totalUnits += +fee.duration && fee.feeType !== FIXED ? ((fee.duration * 100) / 60).toFixed(0) * 60 : 0;

    // totals related to billable amount (which can be different to total amount for grouped fees)
    totalAmountNonBillable += nonBillableAmountExclTax;
    totalAmountBillable += billableAmountExclTax;
    totalAmountWaived += writtenOffAmountExclTax;
    totalAmountBilled += billedAmountExclTax;

    return fee;
  });

  const summary = {
    units: totalUnits / 100,
    billable: totalAmountBillable,
    nonBillable: totalAmountNonBillable,
    waived: totalAmountWaived,
    billed: totalAmountBilled,
  };

  return { fees, summary };
};

const hooks = ({ fees, taxRateBasisPoints }) => ({
  useCalculateFeeListValues: () => {
    let feeList = [];
    let feeSummary;

    if (fees.length) {
      const calculatedFees = calculateFeesAndSummary({
        originalFees: fees,
        firmTaxRateBasisPoints: taxRateBasisPoints,
        region: REGION,
      });
      feeList = calculatedFees.fees;
      feeSummary = calculatedFees.summary;
    }

    return {
      fees: feeList,
      feeSummary,
    };
  },
});

export const FeeTableContainer = withReduxProvider(composeHooks(hooks)(FeeTable));
FeeTableContainer.displayName = 'FeeTableContainer';

FeeTableContainer.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  taxRateBasisPoints: PropTypes.number,
};

FeeTableContainer.defaultProps = {};
