import { gql } from '@apollo/client';

const query = gql`
  query LoggedInStaff {
    loggedInStaff {
      id
      initials
      name
      rate
      firstName
      lastName
      email
    }
  }
`;

const notificationIds = [];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const LoggedInStaff = {
  query,
  notificationIds,
};
