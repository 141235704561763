'use strict';

const { paymentPlanEventTypes } = require('../entities/constants');

/**
 * Converts the Payment Plan Event Type from number
 * @param {number} typeNumber Payment plan event type
 * @returns {string}
 * @throws on invalid payment plan event type
 */
const getPaymentPlanEventType = (typeNumber) => {
  switch (typeNumber) {
    case 0:
      return paymentPlanEventTypes.INVOICE_PAYMENT;
    case 1:
      return paymentPlanEventTypes.INVOICE_PAYMENT_REVERSAL;
    case 2:
      return paymentPlanEventTypes.INVOICE_PAYMENT_FAILED;
    default:
      throw new Error(`Unknown type "${typeNumber}" for paymentPlanEvents`);
  }
};

module.exports = {
  getPaymentPlanEventType,
};
