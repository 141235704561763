import * as invoiceEntities from '@sb-billing/business-logic/invoice/entities';

angular.module('@sb-billing/services').service('sbInvoiceEntriesService', function (
  sbGenericEndpointService) {
  'use strict';
  const that = this;
  const endpoint = '/billing/invoice/entries';

  that.getFeeEntriesFromInvoice = getFeeEntriesFromInvoice;
  that.getExpenseEntriesFromInvoice = getExpenseEntriesFromInvoice;
  that.getInvoiceEntriesChangeset = getInvoiceEntriesChangeset;
  that.entriesInvoicedExternally = entriesInvoicedExternally;

  function getInvoicedExpensesChangeset(items, invoiceId, finalized) {
    return _.chain(items)
      .filter(entry => entry.type === invoiceEntities.entryType.EXPENSE)
      .map(entry => ({expenseId: entry.id, invoiceId: invoiceId, finalized: finalized}))
      .value();
  }

  function getInvoiceEntriesChangeset(items, invoiceId, finalized) {
    return {
      sbExpenseService: getInvoicedExpensesChangeset(items, invoiceId, finalized),
    };
  }

  // Note does not return a complete Fee, only an invoice entry
  function getFeeEntriesFromInvoice(invoice) {
    if(!_.isEmpty(_.get(invoice, 'currentVersion.entries'))){
      return _.filter(invoice.currentVersion.entries, function (entry) {
        return entry.type !== invoiceEntities.entryType.EXPENSE;
      });
    }
  }

  // Note does not return a complete Expense, only an invoice entry
  function getExpenseEntriesFromInvoice(invoice) {
    if(!_.isEmpty(_.get(invoice, 'currentVersion.entries'))){
      return _.filter(invoice.currentVersion.entries, function (entry) {
        return entry.type === invoiceEntities.entryType.EXPENSE;
      });
    }
  }

  // Customers moving to Smokeball Billing need a way to mark which entries were invoiced using an external system
  function entriesInvoicedExternally({ fromDate, toDate }) {
    if (!_.isDate(fromDate)) {
      throw new Error('from date invalid');
    }

    if (!_.isDate(toDate)) {
      throw new Error('to date invalid');
    }

    const data = {
      fromDate: moment(fromDate).format('YYYYMMDD'),
      toDate: moment(toDate).format('YYYYMMDD')
    }

    return sbGenericEndpointService.postPayloadP(endpoint, undefined, data, 'POST')
  }

});
