// Based on https://mui.com/material-ui/react-popover
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import Styles from './Menu.module.scss';

export function getOffsetTop(rect, vertical) {
  let offset = 0;

  if (typeof vertical === 'number') {
    offset = vertical;
  } else if (vertical === 'center') {
    offset = rect.height / 2;
  } else if (vertical === 'bottom') {
    offset = rect.height;
  }

  return offset;
}

export function getOffsetLeft(rect, horizontal) {
  let offset = 0;

  if (typeof horizontal === 'number') {
    offset = horizontal;
  } else if (horizontal === 'center') {
    offset = rect.width / 2;
  } else if (horizontal === 'right') {
    offset = rect.width;
  }

  return offset;
}

function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

function useForkRef(...refs) {
  /**
   * This will create a new function if the refs passed to this hook change and are all defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return React.useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }

    return (instance) => {
      refs.forEach((ref) => {
        setRef(ref, instance);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}

// see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
const useOutsideAlerter = (ref, onClose, ids) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref?.current &&
        !ref.current.contains(event.target) &&
        // let the calendar button/icon handler toggle the calendar
        // otherwise, the calendar would be closed here and then opened by the button/icon
        !ids.includes(event.target.id)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ids, ref, onClose]);
};

const Portal = ({ children }) => {
  const mount = document.body;

  const [mountNode] = React.useState(document.createElement('div'));

  useEffect(() => {
    if (!mount || !mountNode) {
      return () => {};
    }
    mount.appendChild(mountNode);
    return () => mount.removeChild(mountNode);
  }, [mount, mountNode]);

  return createPortal(children, mountNode);
};

function getTransformOriginValue(transformOrigin) {
  return [transformOrigin.horizontal, transformOrigin.vertical]
    .map((n) => (typeof n === 'number' ? `${n}px` : n))
    .join(' ');
}

export const Menu = ({
  children,
  id,
  anchorEl,
  className,
  open,
  onClose,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  marginThreshold = 16,
}) => {
  const ref = React.useRef();
  const handleRef = useForkRef(ref);

  useOutsideAlerter(ref, onClose, [id]);

  const [isPositioned, setIsPositioned] = React.useState(open);

  // Popover attachment
  const getAnchorOffset = React.useCallback(() => {
    // If an anchor element wasn't provided, just use the parent body element of this Popover

    const anchorRect = anchorEl.getBoundingClientRect();

    return {
      top: anchorRect.top + getOffsetTop(anchorRect, anchorOrigin.vertical),
      left: anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
    };
  }, [anchorEl, anchorOrigin.vertical, anchorOrigin.horizontal]);

  const getTransformOrigin = React.useCallback(
    (elemRect) => ({
      vertical: getOffsetTop(elemRect, transformOrigin.vertical),
      horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
    }),
    [transformOrigin.vertical, transformOrigin.horizontal],
  );

  const getPositioningStyle = React.useCallback(
    (element) => {
      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };

      // Get the transform origin point on the element itself
      const elemTransformOrigin = getTransformOrigin(elemRect);

      // Get the offset of the anchoring element
      const anchorOffset = getAnchorOffset();

      // Calculate element positioning
      let top = anchorOffset.top - elemTransformOrigin.vertical;
      let left = anchorOffset.left - elemTransformOrigin.horizontal;
      const bottom = top + elemRect.height;
      const right = left + elemRect.width;

      // Use the parent window of the anchorEl if provided
      const containerWindow = ((anchorEl && anchorEl.ownerDocument) || document).defaultView || window;

      // Window thresholds taking required margin into account
      const heightThreshold = containerWindow.innerHeight - marginThreshold;
      const widthThreshold = containerWindow.innerWidth - marginThreshold;

      // Check if the vertical axis needs shifting
      if (top < marginThreshold) {
        const diff = top - marginThreshold;
        top -= diff;
        elemTransformOrigin.vertical += diff;
      } else if (bottom > heightThreshold) {
        const diff = bottom - heightThreshold;
        top -= diff;
        elemTransformOrigin.vertical += diff;
      }

      // Check if the horizontal axis needs shifting
      if (left < marginThreshold) {
        const diff = left - marginThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      } else if (right > widthThreshold) {
        const diff = right - widthThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      }

      return {
        top: `${Math.round(top)}px`,
        left: `${Math.round(left)}px`,
        transformOrigin: getTransformOriginValue(elemTransformOrigin),
      };
    },
    [anchorEl, getAnchorOffset, getTransformOrigin, marginThreshold],
  );

  const setPositioningStyles = React.useCallback(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const positioning = getPositioningStyle(element);

    if (positioning.top !== null) {
      element.style.top = positioning.top;
    }
    if (positioning.left !== null) {
      element.style.left = positioning.left;
    }
    element.style.transformOrigin = positioning.transformOrigin;
    setIsPositioned(true);
  }, [getPositioningStyle]);

  // const handleEntering = () => {
  //   setPositioningStyles();
  // };

  // const handleExited = () => {
  //   setIsPositioned(false);
  // };

  React.useEffect(() => {
    if (open) {
      setPositioningStyles();
    }
  });

  return (
    <div id={id} className={Styles.menu}>
      <Portal>
        <div
          ref={handleRef}
          {...(isPositioned ? undefined : { style: { opacity: 0 } })}
          style={{ ...(marginThreshold ? { margin: `${marginThreshold}px` } : undefined) }}
          className={classNames(Styles.popover, className)}
        >
          {open && children}
        </div>
      </Portal>
    </div>
  );
};

export const MenuItem = ({ children, onClick, className, tooltip, active }) => (
  <div onClick={onClick} className={classNames(Styles.menuItem, active && Styles.active, className)} title={tooltip}>
    {children}
  </div>
);
