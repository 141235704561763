import { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import debounce from 'debounce-promise';
import { buildCustomizableComponents, buildCustomStyles } from './helpers/select-helpers';

const wait = 100; // milliseconds

const filterOptions = (inputValue, options, maxNbOptions = 100) => {
  const filtered = options.filter((option) => {
    if (option.searchText) {
      return option.searchText.includes(inputValue.toLowerCase());
    }
    return createFilter({ ignoreAccents: false })({ data: {}, ...option }, inputValue);
  });

  return filtered.slice(0, maxNbOptions);
};

const filterGroupedOptions = (inputValue, optionGroups, maxNbOptions = 100) => {
  const maxOptionsPerGroup = Math.ceil(maxNbOptions / optionGroups.length);

  const filteredGroups = (optionGroups || []).reduce((acc, optionGroup) => {
    acc.push({
      ...optionGroup,
      options: filterOptions(inputValue, optionGroup.options, maxOptionsPerGroup),
    });
    return acc;
  }, []);

  return filteredGroups;
};

const selectPortal = document.getElementById('select-portal');

class StatelessSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidUpdate() {
    // unfortunately the autoFocus prop from react-select do not support changes on the prop. the focus default state is false and then is apply. as a result
    // the focus is never apply. we need to listen to the updates and trigger the react-select focus method when this occurs.
    if (this.props.focus) {
      this.reactSelect.focus();
    }
  }

  // look up matching options only after user stops typing, this will
  // also allow us to switch this look up to a rest API call when we
  // are no longer caching all information on the frontend
  debouncedLoadOptions = debounce(
    (inputValue) =>
      new Promise((resolve) => {
        const { isGrouped, options } = this.props;
        const filteredOptions = isGrouped
          ? filterGroupedOptions(inputValue, options)
          : filterOptions(inputValue, options);
        resolve(filteredOptions);
      }),
    wait,
    { leading: false },
  );

  onChangeHandler(value, { action }) {
    if (this.props.onValueChange) {
      this.props.onValueChange(value, { action });
    }

    // this is to unify the way we handle changes. We can't remove the old function since we need backwards compatibility.
    if (this.props.onChange) {
      this.props.onChange(action === 'clear' ? undefined : value);
      this.reactSelect.focus();
    }
  }

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    const { disabled, styleClass, selectedOption, options, autoFocus } = this.props;

    const defaultProps = {
      isMulti: false,
      isDisabled: disabled,
      isSearchable: true,
      blurInputOnSelect: true,
      menuPosition: 'fixed',
      menuPlacement: 'auto',
    };

    if (selectPortal) {
      defaultProps.menuPortalTarget = selectPortal;
    }

    // Allow passing a simple value rather than the object react-select expects
    // NB: checking for undefine and setting to null is to get around a react-select
    // issue discussed here https://github.com/JedWatson/react-select/issues/3066
    // the expected behaviour of setting value to undefine should be to clear the
    // existing selection, but react-select currently only do this when it's set to null
    let currentValue = selectedOption === undefined ? null : selectedOption;
    if (currentValue !== null && options && options.length) {
      if (Array.isArray(selectedOption)) {
        // For isMulti values - expecting a simple array of values
        currentValue = options.filter((item) => selectedOption.includes(item.value));
      } else if (!selectedOption.value && !selectedOption.label) {
        // Not providing the expected react-select option object, ie it's a string or a number
        currentValue = options.find((option) => option.value === selectedOption);
      }
    }

    return (
      <AsyncSelect
        ref={(c) => {
          this.reactSelect = c;
        }}
        {...defaultProps}
        {...this.props}
        loadOptions={this.props.loadOptions || ((inputValue) => this.debouncedLoadOptions(inputValue))}
        autoFocus={autoFocus}
        className={classNames(
          `select-${styleClass || 'default'} select-container`,
          this.props.className,
          this.props.disabled && 'select-disabled',
          this.props.hasError && 'error',
        )}
        classNamePrefix="form-select"
        // We filter the options ourselves in loadOptions to allow us to restrict returned results. No need to filter twice
        filterOption={() => true}
        styles={{
          ...buildCustomStyles(this.props),
          ...this.props.styles,
        }}
        value={currentValue}
        onChange={this.onChangeHandler}
        defaultOptions={options.slice(0, 1000)}
        onBlur={this.onBlur}
        components={buildCustomizableComponents(this.props)}
      />
    );
  }
}

StatelessSelect.displayName = 'StatelessSelect';

const option = PropTypes.shape({
  label: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
});

const optionGroup = PropTypes.shape({
  label: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(option).isRequired,
});

const actions = PropTypes.shape({
  displayComponent: PropTypes.any,
  callback: PropTypes.func,
});

StatelessSelect.propTypes = {
  disabled: PropTypes.bool,
  styleClass: PropTypes.string,
  styles: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  listItemClassName: PropTypes.string,
  className: PropTypes.string,
  focus: PropTypes.bool,
  onBlur: PropTypes.func,
  // onValueChange is DEPRECATED
  onValueChange: PropTypes.func,
  // USE onChange INSTEAD OF onValueChange
  onChange: PropTypes.func,
  loadOptions: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.arrayOf(option), PropTypes.arrayOf(optionGroup)]),
  selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.array]),
  optionLabelHeight: PropTypes.number,
  groupLabelHeight: PropTypes.number,
  virtualizeList: PropTypes.bool,
  disableInputOnSelection: PropTypes.bool,
  hasError: PropTypes.bool,
  showAdd: PropTypes.bool,
  showDropdown: PropTypes.bool,
  isClearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  actionList: PropTypes.arrayOf(actions),
  isGrouped: PropTypes.bool,
  onFocus: PropTypes.func,
  menuPosition: PropTypes.oneOf(['absolute', 'fixed']),
};

// for now I will set all props optional as undefined. The idea is not validating anymore in the component
// and start using the default props. I wont do it in the components we already have but keep in mind from now
// if you are working or modifying this component please update the prop types. GP
StatelessSelect.defaultProps = {
  disabled: false,
  styleClass: undefined,
  styles: undefined,
  name: undefined,
  placeholder: undefined,
  listItemClassName: undefined,
  className: undefined,
  focus: false,
  onBlur: undefined,
  onFocus: undefined,
  // onValueChange is DEPRECATED
  onValueChange: undefined,
  // USE onChange INSTEAD OF onValueChange
  onChange: () => {},
  loadOptions: undefined,
  onInputChange: undefined,
  getOptionLabel: undefined,
  getOptionValue: undefined,
  formatOptionLabel: undefined,
  formatGroupLabel: undefined,
  noOptionsMessage: undefined,
  options: [],
  selectedOption: undefined,
  optionLabelHeight: undefined,
  groupLabelHeight: undefined,
  virtualizeList: true,
  disableInputOnSelection: false,
  hasError: false,
  showAdd: false,
  showDropdown: true,
  isClearable: false,
  autoFocus: undefined,
  actionList: [],
  isGrouped: undefined,
  menuPosition: undefined,
};

export default StatelessSelect;
