const { personTitles } = require('../entities/constants');

/**
 * Get a list of person titles
 * @param {object} params
 * @param {boolean} [params.includeTopLabelOption] include a 'Title' option with notanOption: true for drop down list
 * @param {string} [params.region] defaults to 'US' to match existing behaviour
 * @returns {Array.<object>} Person Titles
 */
function getPersonTitles({ includeTopLabelOption = false, region }) {
  if (!region) {
    throw new Error('region is required');
  }

  const titleList = [...personTitles[region]];

  if (includeTopLabelOption) {
    titleList.unshift({ label: 'Title', notAnOption: true });
  }

  return titleList;
}

module.exports = {
  getPersonTitles,
};
