module.exports = [
  {
    opening: 'a',
    openingRtf: '{\\field',
    closing: '/a',
    closingRtf: '}',
  },
  {
    opening: 'b',
    openingRtf: '{\\b',
    closing: '/b',
    closingRtf: '}',
  },
  {
    opening: 'br',
    openingRtf: '',
    closing: 'br/',
    closingRtf: '',
  },
  {
    opening: 'center',
    openingRtf: '{\\pard\\qr',
    closing: '/center',
    closingRtf: '\\par}',
  },
  {
    opening: 'div',
    openingRtf: '{\\pard',
    closing: '/div',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'em',
    openingRtf: '{\\i',
    closing: '/em',
    closingRtf: '}',
  },
  {
    opening: 'font',
    openingRtf: '{',
    closing: '/font',
    closingRtf: '}',
  },
  {
    opening: 'h1',
    openingRtf: '{\\pard\\fs38',
    closing: '/h1',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'h2',
    openingRtf: '{\\pard\\fs34',
    closing: '/h2',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'h3',
    openingRtf: '{\\pard\\fs30',
    closing: '/h3',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'h4',
    openingRtf: '{\\pard\\fs26',
    closing: '/h4',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'h5',
    openingRtf: '{\\pard\\fs20',
    closing: '/h5',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'h6',
    openingRtf: '{\\pard\\fs16',
    closing: '/h6',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 'i',
    openingRtf: '{\\i',
    closing: '/i',
    closingRtf: '}',
  },
  {
    opening: 'li',
    openingRtf: '{\\pntext\\tab}',
    ol: '{\\pntext X.\\tab}{{\\*\\pn\\pnlvlbody\\pndec\\pnstart1{\\pntxta .}}',
    ul: "{{\\pntext \\'B7\\tab}{\\*\\pn\\pnlvlblt\\pnstart1{\\pntxtb\\'B7}}",
    ulClosing: '\\tx720\\fi-360\\li720\\sa0\\sl15\\slmult0\\lang22\\f0\\fs22\\par}',
    olClosing: '\\tx720\\fi-360\\li720\\sa0\\sl15\\slmult0\\par}',
    closing: '/li',
    closingRtf: '\\par',
  },
  {
    opening: 'ol',
    openingRtf: '{',
    closing: '/ol',
    closingRtf: '}',
  },
  {
    opening: 'ul',
    openingRtf: '{',
    closing: '/ul',
    closingRtf: '}',
  },
  {
    opening: 'mark',
    openingRtf: '{',
    closing: '/mark',
    closingRtf: '}',
  },
  {
    opening: 'p',
    openingRtf: '{\\pard',
    closing: '/p',
    closingRtf: '\\sb70\\par}',
  },
  {
    opening: 's',
    openingRtf: '{\\strike',
    closing: '/s',
    closingRtf: '}',
  },
  {
    opening: 'span',
    openingRtf: '{',
    closing: '/span',
    closingRtf: '}',
  },
  {
    opening: 'sub',
    openingRtf: '{\\sub',
    closing: '/sub',
    closingRtf: '}',
  },
  {
    opening: 'sup',
    openingRtf: '{\\super',
    closing: '/sup',
    closingRtf: '}',
  },
  {
    opening: 'strong',
    openingRtf: '{\\b',
    closing: '/strong',
    closingRtf: '}',
  },
  {
    opening: 'table',
    openingRtf: '{',
    closing: '/table',
    closingRtf: '}',
  },
  {
    opening: 'tbody',
    openingRtf: '',
    closing: '/tbody',
    closingRtf: '',
  },
  {
    opening: 'thead',
    openingRtf: '',
    closing: '/thead',
    closingRtf: '',
  },
  {
    opening: 'td',
    openingRtf: '{\\pard\\intbl\\qc',
    closing: '/td',
    closingRtf: '\\cell}',
  },
  {
    opening: 'th',
    openingRtf: '{\\pard\\intbl\\qc',
    closing: '/th',
    closingRtf: '\\cell}',
  },
  {
    opening: 'tr',
    openingRtf: '{\\trowd\\trgaph10',
    closing: '/tr',
    closingRtf: '\\row}',
  },
  {
    opening: 'u',
    openingRtf: '{\\ul',
    closing: '/u',
    closingRtf: '}',
  },
  {
    opening: 'img',
    openingRtf: '{\\pict',
    closing: '/img',
    closingRtf: '}',
  },
  {
    opening: 'html',
    openingRtf: '',
    closing: '/html',
    closingRtf: '',
  },
  {
    opening: 'head',
    openingRtf: '',
    closing: '/head',
    closingRtf: '',
  },
  {
    opening: 'body',
    openingRtf: '',
    closing: '/body',
    closingRtf: '',
  },
  {
    opening: 'style',
    openingRtf: '',
    closing: '/style',
    closingRtf: '',
  },
  {
    opening: 'meta',
    openingRtf: '',
    closing: 'meta',
    closingRtf: '',
  },
  {
    opening: 'title',
    openingRtf: '',
    closing: '/title',
    closingRtf: '',
  },
];
