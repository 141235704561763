'use strict';

const { dateToInteger } = require('@sb-itops/date');

/**
 * Return true if there's a payment plan installment due on the date specified
 * @param {*} paymentPlan payment plan with installments
 * @param {*} date date to check if there's a installment due
 */
const hasInstallmentDueOnDate = ({ installments }, date) => {
  if (!installments) {
    throw new Error('paymentPlan with installments is required');
  }

  const dateAsInteger = dateToInteger(date);

  return installments.some((installment) => installment.date === dateAsInteger);
};

module.exports = {
  hasInstallmentDueOnDate,
};
