import {
  subscribeToApolloEntityOpdateEvents,
  broadcastApolloEntityOpdateEvent,
  clearAllApolloEntityOpdateSubscriptions,
} from './subscriptions';
import { onCreateEditPersonContact } from './opdaters/create-edit-person-contact';
import { onCreateEditCompanyContact } from './opdaters/create-edit-company-contact';
import { onCreateEditTrustContact } from './opdaters/create-edit-trust-contact';
import { onCreateEditTask } from './opdaters/create-edit-task';
import { onCreateMemo, onEditMemo } from './opdaters/create-edit-memo';
import { onRestoreMemo } from './opdaters/restore-memo';
import { onDeleteMemo } from './opdaters/delete-memo';
import { onDeleteTask } from './opdaters/delete-task';
import { onRestoreTask } from './opdaters/restore-task';
import { onCreateEditAppointment } from './opdaters/create-edit-appointment';
import { onDeleteAppointment } from './opdaters/delete-appointment';
import { onSaveExpense } from './opdaters/save-expense';
import { onSaveFee } from './opdaters/save-fee';
import { onUpdateExpenseBulkIsBillable } from './opdaters/update-expense-bulk-billable';
import { onUpdateFeeBulkIsBillable } from './opdaters/update-fee-bulk-billable';
import { onBulkApplyRateSet } from './opdaters/bulk-apply-rate-set';
import { onSaveBillingUserAttributes } from './opdaters/save-user-billing-attributes';
import { onPatchSmokeballContact } from './opdaters/patch-smokeball-contact';

const opdateHandlers = {
  'Integration.CreatePersonContact': onCreateEditPersonContact,
  'Integration.CreateCompanyContact': onCreateEditCompanyContact,
  'Integration.CreateTrustContact': onCreateEditTrustContact,
  'Integration.ExpensesBulkUpdateBillable': onUpdateExpenseBulkIsBillable,
  'Integration.FeesBulkUpdateBillable': onUpdateFeeBulkIsBillable,
  'Integration.BulkApplyRateSetToMatters': onBulkApplyRateSet,
  'Tasking.ManageTasks.Messages.SaveTasks2': onCreateEditTask,
  'MatterManagement.Memos.Messages.AddMemo': onCreateMemo,
  'MatterManagement.Memos.Messages.UpdateMemo': onEditMemo,
  'MatterManagement.Memos.Messages.DeleteMemo': onDeleteMemo,
  'MatterManagement.Memos.Messages.RestoreMemo': onRestoreMemo,
  'Tasking.ManageTasks.Messages.RestoreTask': onRestoreTask,
  'Tasking.ManageTasks.Messages.RemoveTask': onDeleteTask,
  'Calendaring.ManageCalendars.Messages.SaveAppointment': onCreateEditAppointment,
  'Calendaring.ManageCalendars.Messages.RemoveAppointment': onDeleteAppointment,
  'Billing.Expenses.Commands.SaveExpense': onSaveExpense,
  'Billing.Fees.Commands.SaveFee': onSaveFee,
  'Billing.Shared.Messages.Commands.SaveBillingUserAttributes': onSaveBillingUserAttributes,
  'CustomerManagement.PatchSmokeballContact': onPatchSmokeballContact,
};

/**
 * Applies entity opdates required by a dispatched commands into the apollo cache.
 * Compatible with subscribeToCommandDispatchEvents() in @sb-integration/web-client-sdk.
 *
 * This function is connected to the web-client-sdk in the bootstrapper service.
 *
 * Note that this opdaters does not opdate cached GQL query results, only entities.
 *
 * @param {Object} event
 * @param {string} event.commandType The type of command that was dispatched
 * @param {Object} event.commandMessage The data that was sent to the command processor endpoint for the dispatched command.
 * @param {Object} event.commandResult The result of the dispatched command
 */
export const opdateApolloCacheForCommandResult = ({ commandType, commandMessage, commandResult }) => {
  const opdateApolloCacheEntities = opdateHandlers[commandType];

  if (!opdateApolloCacheEntities) {
    return;
  }

  const opdateResult = opdateApolloCacheEntities({ commandType, commandMessage, commandResult });

  const opdateEvents = Array.isArray(opdateResult) ? opdateResult : [opdateResult];
  opdateEvents.forEach((opdateEvent) => broadcastApolloEntityOpdateEvent(opdateEvent));
};

export { subscribeToApolloEntityOpdateEvents, clearAllApolloEntityOpdateSubscriptions };
