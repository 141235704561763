/**
 * @deprecated This table is replaced with the LOD-compatible file monorepo/apps/smokeball-billing-web/src/components/cheque-table/ChequeTable.jsx
 * As it's also used by Trust Cheques, it won't be able to be removed until Trust Cheques is also migrated to LOD
 */
/* eslint-disable react/no-unstable-nested-components */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation, ContextMenu } from '@sb-itops/react';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { MattersDisplay } from '@sb-matter-management/react/matters-display';
import { capitalize } from '@sb-itops/nodash';

import { InterpolatedDescription } from '../interpolated-description';
import Styles from './ChequeTable.module.scss';

const { amountCellLocalisedRenderer, yyyymmddLocalisedRenderer, timestampLocalisedRenderer } = utils;

const ChequeTable = memo(
  ({
    cheques,
    totals,
    onSort,
    sortBy,
    sortDirection,
    showSystemDate,
    showDescription,
    showChequeMemo,
    onClickRow,
    onClickLink,
    onPrintCheque,
  }) => {
    const { t } = useTranslation();
    const contextMenuCellRenderer = ({ rowData }) =>
      // we allow the cheque to be reprinted if its been printed already AND:
      // * its a trust cheque OR
      // * its an operating cheque that hasnt been reversed/deleted
      rowData.isPrinted &&
      (rowData.bankAccountType === 'Trust' || (rowData.bankAccountType === 'Operating' && !rowData.isReversed)) && (
        <ContextMenu
          body={() => (
            <div className="context-menu-body list-group">
              <button type="button" className="list-group-item" onClick={() => onPrintCheque(rowData.chequeId)}>
                Reprint {capitalize(t('cheque'))}
              </button>
            </div>
          )}
        >
          <div className="context-menu-cell">...</div>
        </ContextMenu>
      );

    const paidToCellRenderer = ({ rowData }) => {
      if (!rowData.payeeId) {
        return undefined;
      }
      // For trust cheques we do not show the deleted/reversed status
      // of the cheque in the reference cell as its already shown in the desciption,
      // which operating cheques do not have.
      if (rowData.bankAccountType === 'Trust') {
        return <ContactDisplay contactId={rowData.payeeId} onClickLink={onClickLink} asLink showLastNameFirst />;
      }

      if (rowData.bankAccountType === 'Operating') {
        if (rowData.isDeleted) {
          return (
            <div>
              <span className={Styles.deleted}>(Deleted) </span>
              <ContactDisplay contactId={rowData.payeeId} onClickLink={onClickLink} asLink inline showLastNameFirst />
            </div>
          );
        }

        if (rowData.isReversed) {
          return (
            <div>
              <span>Reversal: </span>
              <ContactDisplay contactId={rowData.payeeId} onClickLink={onClickLink} asLink inline showLastNameFirst />
            </div>
          );
        }

        return <ContactDisplay contactId={rowData.payeeId} onClickLink={onClickLink} asLink inline showLastNameFirst />;
      }

      return undefined;
    };

    return (
      <Table
        onRowClick={({ rowData }) => onClickRow(undefined, rowData.chequeId)}
        list={cheques}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        summary={totals}
        showFooter
      >
        <Column
          dataKey="effectiveDate"
          headerClassName={Styles.effectiveDateHeader}
          label={`${t('capitalize', { val: 'cheque' })} Date`}
          width={105}
          cellRenderer={yyyymmddLocalisedRenderer}
        />
        {showSystemDate && (
          <Column dataKey="systemDate" label="Entered" width={105} cellRenderer={timestampLocalisedRenderer} />
        )}
        {showDescription && (
          <Column
            dataKey="description"
            label="Description"
            flexGrow={2}
            cellRenderer={({ rowData }) => (
              <InterpolatedDescription
                description={rowData.description}
                onClickLink={onClickLink}
                deleted={rowData.isDeleted}
              />
            )}
            disableSort
          />
        )}
        <Column dataKey="payeeString" label="Paid To" flexGrow={2} cellRenderer={paidToCellRenderer} />
        <Column
          dataKey="matterDisplay"
          label="Matter(s)"
          flexGrow={3}
          cellRenderer={({ rowData }) => (
            <MattersDisplay matterIds={rowData.matterIds} onClickLink={onClickLink} asLink />
          )}
        />
        {showChequeMemo && (
          <Column dataKey="chequeMemo" label={`${t('capitalize', { val: 'cheque' })} Memo`} flexGrow={1} />
        )}
        <Column className="right-align" dataKey="chequeNumber" label="Reference" width={150} />
        <Column
          className="right-align"
          dataKey="amount"
          label="Amount"
          width={150}
          cellRenderer={amountCellLocalisedRenderer}
          footerRenderer={amountCellLocalisedRenderer}
        />
        <Column dataKey="UNUSED" label="" disableSort width={40} cellRenderer={contextMenuCellRenderer} />
      </Table>
    );
  },
);

ChequeTable.displayName = 'ChequeTable';

ChequeTable.propTypes = {
  cheques: PropTypes.array,
  showSystemDate: PropTypes.bool,
  showDescription: PropTypes.bool,
  showChequeMemo: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  onPrintCheque: PropTypes.func,
  totals: PropTypes.object,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  type: PropTypes.string,
};

ChequeTable.defaultProps = {
  cheques: [],
  showSystemDate: false,
  showDescription: false,
  totals: {},
  onClickLink: () => {},
  onClickRow: () => {},
  onPrintCheque: () => {},
  onSort: () => {},
  sortBy: 'effectiveDate',
  sortDirection: 'desc',
  type: undefined,
};

export default ChequeTable;
