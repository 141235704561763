import { useSelector, useDispatch } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getLogger } from '@sb-itops/fe-logger';
import { withOnLoad } from '@sb-itops/react/hoc';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { store } from '@sb-itops/redux';
import { hasFacet, facets } from '@sb-itops/region-facets';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useState } from 'react';
import * as forms from '@sb-itops/redux/forms2';
import * as messageDisplay from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';
import {
  getSettings as getInvoiceReminderSettings,
  saveInvoiceReminderSettings,
  getPreviewData,
} from '@sb-billing/redux/invoice-reminder-settings';
import {
  getActiveProvider,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { getInvoiceReminderEmailMacros } from '@sb-billing/business-logic/invoice-emailing';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { useReduxAction } from 'web/hooks';
import { InvoiceReminderEmailSettings } from './InvoiceReminderEmailSettings';
import { InvoiceReminderEmailSettingsSchema } from './InvoiceReminderEmailSettingsSchema';

const log = getLogger('InvoiceReminderEmailSettings');
const scope = 'invoice-reminder-email-settings';

const hooks = () => ({
  useSelectors: () => {
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const { formInitialised, fields: formFields, formDirty } = useSelector(formSelectors.getFormState);
    const { emailSubject, emailBody, notes, sendCopyToUser, showAccountSummary, showTransactionHistory } = formFields;

    const operatingPaymentProviderConnected = isPaymentProviderEnabledForBankAccount({
      bankAccountId: getOperatingAccount().id,
      providerType: getActiveProvider(),
    });

    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(undefined);
    const supportsOperatingAccount = hasFacet(facets.operatingAccount);

    const onRefeshPdf = async () => {
      setPreviewUrl(undefined);
      // get latest as form may have changed
      const formFieldValues = formSelectors.getFieldValues(store.getState());
      const url = await getPreviewPdfUrl(formFieldValues, supportsOperatingAccount);
      setPreviewUrl(url);
    };

    useReduxAction('billing/invoice-settings/UPDATE_CACHE', onRefeshPdf);

    return {
      getInvoiceReminderEmailMacros,
      operatingPaymentProviderConnected,
      isSaving: saving,
      emailSubject,
      emailBody,
      notes,
      sendCopyToUser,
      showAccountSummary,
      showTransactionHistory,
      formInitialised,
      formDirty,
      previewUrl,
      onRefeshPdf,
      onFormDataUpdated: ({ key, value }) => {
        dispatch(formActions.updateFieldValues({ fieldValues: { [key]: value } }));
        dispatch(formOperations.validateSchema({ schema: InvoiceReminderEmailSettingsSchema }));
      },

      onSaveButtonClick: async (event) => {
        event.preventDefault();
        await dispatch(formOperations.validateSchema({ schema: InvoiceReminderEmailSettingsSchema }));

        try {
          setSaving(true);
          await dispatch(
            formOperations.submitFormWithValidationP({
              submitFnP: async (formFieldValues) => {
                const currentSettings = getInvoiceReminderSettings();
                // for backwards compatibility we always have to set isActivated to true
                await saveInvoiceReminderSettings({ ...currentSettings, ...formFieldValues, isActivated: true });

                messageDisplay.success('Invoice reminder settings saved successfully');
              },
            }),
          );
        } catch (err) {
          log.error('Failed to save invoice reminder settings', err);
          messageDisplay.error('Failed to save invoice reminder settings');
        } finally {
          setSaving(false);
        }
      },
      onLoad: () => {
        let isMounted = true;

        const {
          emailSubject: emailSubjectInit,
          emailBody: emailBodyInit,
          notes: notesInit,
          sendCopyToUser: sendCopyToUserInit,
          showAccountSummary: showAccountSummaryInit,
          showTransactionHistory: showTransactionHistoryInit,
        } = getInvoiceReminderSettings();

        const fieldValues = {
          emailSubject: emailSubjectInit,
          emailBody: emailBodyInit,
          notes: notesInit,
          sendCopyToUser: sendCopyToUserInit,
          showAccountSummary: showAccountSummaryInit,
          showTransactionHistory: showTransactionHistoryInit,
        };

        dispatch(formActions.initialiseForm({ fieldValues }));
        dispatch(formOperations.validateSchema({ schema: InvoiceReminderEmailSettingsSchema }));
        getPreviewPdfUrl(fieldValues, supportsOperatingAccount)
          .then((url) => (isMounted ? setPreviewUrl(url) : undefined))
          .catch((err) => log.error(`problem previewing pdf ${err.message}`));
        const onUnload = () => {
          isMounted = false;
          // clear form on unmount to conform with pattern used else where
          dispatch(formActions.clearForm());
        };
        return onUnload;
      },
    };
  },
});

const getPreviewPdfUrl = async (formFieldValues, supportsOperatingAccount) => {
  const pdfSettings = await getPreviewData(formFieldValues, log, supportsOperatingAccount);
  const path = `/billing/invoice-reminders/preview-settings/:accountId/`;

  try {
    const fetchOptions = { body: JSON.stringify(pdfSettings) };
    const response = await fetchPostP({ path, fetchOptions, responseType: 'text' });

    if (response.status === 200 && response.body) {
      return `data:application/pdf;base64,${response.body}`;
    }
  } catch (error) {
    log.error(error);
  }

  return undefined;
};

const InvoiceReminderEmailSettingsContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(InvoiceReminderEmailSettings)),
);

InvoiceReminderEmailSettingsContainer.displayName = 'InvoiceReminderEmailSettingsContainer';

export default InvoiceReminderEmailSettingsContainer;
