import React from 'react';
import { featureActive, featureData } from '@sb-itops/feature';

export const Marketplace = () => {
  if (featureActive('NUCWEB-657') && featureData('NUCWEB-657')) {
    return (
      <iframe
        style={{ height: '100%', width: '100%' }}
        src={featureData('NUCWEB-657')}
        title="marketplaceEmbedded"
        allow="clipboard-write"
      />
    );
  }
  return null;
};

Marketplace.displayName = 'Marketplace';

Marketplace.propTypes = {};

Marketplace.defaultProps = {};
