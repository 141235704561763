'use strict';

const { ledesTimekeeperClassificationTypeValues } = require('./ledes-timekeeper-classification-types');

const twoCharacterTimekeeperClassificationCodes = Object.freeze({
  ASSOC: 'AS', // Associate
  CLKSEC: 'OT', // Clerk or Secretary
  LGLAST: 'LA', // Legal Assistant
  OFCOUN: 'OC', // Of Counsel
  NBOTHR: 'OT', // Other
  PARALG: 'PL', // Paralegal
  PARTNR: 'PT', // Partner
});

/**
 * Determine the final LEDES timekeeper classification code to use in ledes invoice export.
 * Use two character timekeeper classification code if firm has elected to use this.
 * @param {object} params
 * @param {string} params.timekeeperClassificationCode input timekeeper classification code
 * @param {Object} params.billingConfiguration matter billing configuration
 * @returns {string} two/sixe character time keeper classification code as appropriate
 */
const determineTimekeeperClassificationCode = ({ timekeeperClassificationCode, billingConfiguration }) => {
  if (
    billingConfiguration &&
    billingConfiguration.ledesTimekeeperClassificationType ===
      ledesTimekeeperClassificationTypeValues.USE_2_CHARACTER_CODES
  ) {
    return twoCharacterTimekeeperClassificationCodes[timekeeperClassificationCode] || timekeeperClassificationCode;
  }

  return timekeeperClassificationCode;
};

module.exports = {
  determineTimekeeperClassificationCode,
};
