'use strict';

const { regionType } = require('@sb-itops/region');
const { paymentFormTypes, applicationType } = require('../../../entities/constants');
const { tokenTypes } = require('./token-types');
const { paymentMethods } = require('./payment-methods');

/**
 * @typedef {'billingWebApp'|'paymentPortal'} ApplicationType
 */

// Applications need to provide their own styling to the hosted fields used within the FeeWisePaymentForm.
// This sucks, but unfortunately there isn't a great way around it. We deal with it here as it seems like
// the most obvious and consistent place, the down sides are that it could be difficult to find this styling
// and we have granted application knowledge to business logic.
// See: https://stripe.com/docs/elements/appearance-api (FeeWise uses Stripe under the hood)
const hostedFieldStyles = {
  billingWebApp: {
    appearance: {
      variables: {
        fontSizeBase: '14px',
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        borderRadius: '4px',
        colorDanger: 'rgb(169, 68, 66)',
        spacingGridRow: '15px',
        colorText: 'rgb(51, 51, 51)',
      },
      rules: {
        '.Label': {
          textTransform: 'uppercase',
          fontWeight: '600',
          fontSize: '14px',
        },
        '.Input': {
          padding: '7px 8px 7px 8px',
          boxShadow: 'none',
          borderColor: 'rgb(217, 217, 217)',
        },
        '.Input:focus': {
          borderColor: 'rgb(102, 175, 233)',
          outline: 'none',
          boxShadow: 'none',
        },
        '.Input--invalid': {
          boxShadow: 'none',
        },
        '.TermsText': {
          fontSize: '0',
        },
      },
    },
  },
  paymentPortal: {
    appearance: {
      variables: {
        fontSizeBase: '16px',
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        borderRadius: '4px',
        colorDanger: 'rgb(169, 68, 66)',
        spacingGridRow: '15px',
        colorText: 'rgb(85, 85, 85)',
      },
      rules: {
        '.Label': {
          textTransform: 'uppercase',
          fontWeight: '500',
          fontSize: '13px',
          color: 'rgb(41,43,44)',
        },
        '.Input': {
          padding: '7px 8px 7px 8px',
          boxShadow: 'none',
          borderColor: 'rgb(217, 217, 217)',
        },
        '.Input:focus': {
          borderColor: 'rgb(102, 175, 233)',
          outline: 'none',
          boxShadow: 'none',
        },
        '.Input--invalid': {
          boxShadow: 'none',
        },
        '.TermsText': {
          fontSize: '0',
        },
      },
    },
  },
};

const regionStrategy = {
  [regionType.AU]: ({ application, paymentFormType }) => {
    let termsText;

    if (application === applicationType.paymentPortal && paymentFormType === paymentFormTypes.CHARGE) {
      termsText =
        'By providing your payment information, you allow FeeWise ANZ to charge your account in accordance with their terms.';
    } else if (application === applicationType.paymentPortal && paymentFormType === paymentFormTypes.SAVE_DETAILS) {
      termsText =
        'By providing your card information, you allow FeeWise ANZ to charge your card for future payments in accordance with their terms.';
    }

    return { termsText, enabledPaymentMethods: [paymentMethods.Card] };
  },
  [regionType.GB]: ({ application, paymentFormType }) => {
    let termsText;

    if (application === applicationType.paymentPortal && paymentFormType === paymentFormTypes.CHARGE) {
      termsText =
        'By providing your card information, you allow FeeWise UK and Ireland to charge your account in accordance with their terms.';
    } else if (application === applicationType.paymentPortal && paymentFormType === paymentFormTypes.SAVE_DETAILS) {
      termsText =
        'By providing your card information, you allow FeeWise UK and Ireland to charge your card for future payments in accordance with their terms.';
    }

    return { termsText, enabledPaymentMethods: [paymentMethods.Card] };
  },
  [regionType.US]: ({ application, paymentFormType }) => {
    let termsText;
    const enabledPaymentMethods = [paymentMethods.Card];
    // With FeeWise, we can use eCheck (DirectDebit) only in payment portal as it requires link to an actual bank account
    if (application && application === applicationType.paymentPortal) {
      if (paymentFormType === paymentFormTypes.CHARGE) {
        enabledPaymentMethods.push(paymentMethods.DirectDebit);
        // We specifically don't mention card as the form is used for card and eCheck
        termsText =
          'By providing your payment information, you allow FeeWise US to charge your account in accordance with their terms.';
      } else if (paymentFormType === paymentFormTypes.SAVE_DETAILS) {
        termsText =
          'By providing your card information, you allow FeeWise US to charge your card for future payments in accordance with their terms.';
      }
    }

    return { termsText, enabledPaymentMethods };
  },
};

/**
 * getPaymentFormConfiguration
 *
 * Returns the information required to display a payment form (i.e. charge UI) for FeeWise.
 *
 * @param {Object} params
 * @param {Object} params.region The region.
 * @param {Object} params.providerSpecificFields
 * @param {string} [params.providerSpecificFields.paymentFormType] Specifies if form is used for charge or capturing payment details for later.
 * @param {string} [params.providerSpecificFields.contactSummary] Details of contact. Not strictly needed but is used in FeeWise dashboard when we save a card.
 * @param {ApplicationType} [params.providerSpecificFields.application] The application requesting the configuration. This allows provider specific
 *                                                                      implementations to optionally configure by UI differently by application.
 * @returns {Object} The FeeWise specific details.
 */
const getPaymentFormConfiguration = ({ providerSpecificFields = {}, region }) => {
  const { application, contactSummary = {}, paymentFormType = paymentFormTypes.CHARGE } = providerSpecificFields || {};

  const payorDetails = {
    // If contact is not a person, there is no first and last name in contact summary.
    // We don't particulary mind, because FeeWise doesn't require payor details.
    // The only reason why we even provide payor details is when saving card,
    // these details are shown in FeeWise dashboard. Without them, it is not clear whose card it is.
    firstName: contactSummary.firstName || contactSummary.displayName,
    lastName: contactSummary.lastName,
    email: contactSummary.email,
  };

  const regionSpecificFn = regionStrategy[region];
  if (!regionSpecificFn) {
    throw new Error('regionStrategy not implemented');
  }
  const { termsText, enabledPaymentMethods } = regionSpecificFn({ application, paymentFormType });

  return {
    hostedFieldsStyle: hostedFieldStyles[application] || hostedFieldStyles.billingWebApp,
    tokenType: paymentFormType === paymentFormTypes.SAVE_DETAILS ? tokenTypes.MULTI_USE : tokenTypes.SINGLE_USE,
    payorDetails,
    // contact from cache has `entityId` while contact from GQL has `id`
    payorId: contactSummary.entityId || contactSummary.id || undefined,
    supportsFeeDetails: false,
    enabledPaymentMethods,
    termsText,
  };
};

module.exports = {
  getPaymentFormConfiguration,
};
