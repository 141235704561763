import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getActiveProvider,
  getPaymentFormConfiguration,
  getProviderSettings,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { preCharge as preChargeProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { CreditCardPaymentModal } from './CreditCardPaymentModal';

const mapStateToProps = (state, { isVisible, isSubmitting, invoiceId, payorId, bankAccountId }) => {
  const providerType = getActiveProvider();
  const providerSettings = getProviderSettings(providerType);
  const paymentFormConfiguration = getPaymentFormConfiguration({ bankAccountId, providerType });

  const invoice = invoiceId && getInvoiceById(invoiceId);
  const invoiceNumber = invoice && invoice.currentVersion && invoice.currentVersion.invoiceNumber;

  return {
    providerType,
    paymentFormConfiguration,
    isVisible,
    isSubmitting,
    invoiceNumber,
    invoiceId,
    payorId,
    clientIsCoveringFee: providerSettings.clientCoversFeeOnPayments || false,
  };
};

export const CreditCardPaymentModalContainer = withReduxProvider(
  connect(mapStateToProps)(({ onSubmit, ...props }) => {
    const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
    const [invoicePaymentFormReadyForSubmit, setInvoicePaymentFormReadyForSubmit] = useState(false);
    const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
    const [invoicePaymentFormData, setInvoicePaymentFormData] = useState({});

    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();

    const readyForSubmit = invoicePaymentFormReadyForSubmit && chargeFormReadyForSubmit;
    const isSubmitting = triggerChargeFormSubmit || props.isSubmitting;

    const onHandleSubmit = async (providerFormData) => {
      const paymentFormData = { smokeballFormData: invoicePaymentFormData, providerFormData };
      await onSubmit(paymentFormData);
      setTriggerChargeFormSubmit(false);
    };

    const onHandlePreCharge = async ({ providerType, providerSpecificFields }) =>
      preChargeProvider({ fetchPostP, providerType, providerSpecificFields });

    return (
      <CreditCardPaymentModal
        {...props}
        triggerChargeFormSubmit={triggerChargeFormSubmit}
        onTriggerSubmit={() => setTriggerChargeFormSubmit(true)}
        readyForSubmit={readyForSubmit}
        isSubmitting={isSubmitting}
        chargeFormData={chargeFormData}
        onInvoicePaymentFormDataChange={setInvoicePaymentFormData}
        onInvoicePaymentFormReadyForSubmit={(isReady) => setInvoicePaymentFormReadyForSubmit(!!isReady)}
        onChargeFormReadyForSubmit={(isReady) => setChargeFormReadyForSubmit(!!isReady)}
        onChargeFormSubmit={onHandleSubmit}
        onChargeFormDataChange={setChargeFormData}
        onPreCharge={onHandlePreCharge}
        totalChargeAmount={invoicePaymentFormData?.paymentAmount}
      />
    );
  }),
);

CreditCardPaymentModalContainer.displayName = 'CreditCardPaymentModalContainer';

CreditCardPaymentModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invoiceId: PropTypes.string,
  payorId: PropTypes.string,
  bankAccountId: PropTypes.string.isRequired,
};

CreditCardPaymentModalContainer.defaultProps = {
  invoiceId: undefined,
  payorId: undefined,
};
