import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@sb-itops/fe-logger';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { fetchPeopleP, findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/redux/contacts';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getConfig as getDefaultMatterBillingConfiguration } from '@sb-billing/redux/default-matter-billing-configuration';
import { TrustDepositRequestForm } from './TrustDepositRequestForm';
import { createTrustDepositRequestFormSchema } from './trust-deposit-request-form-schema';
import { getTrustDepositRequestEmailInterpolated } from './trust-deposit-request-interpolated';

const DEPOSIT_REQUEST_FORM_SCHEMA = createTrustDepositRequestFormSchema();
const log = getLogger('TrustDepositRequestFormContainer');

const hooks = ({ debtorId, matterId, bankAccountId, scope }) => ({
  useTrustDepositRequestForm: () => {
    const { t } = useTranslation();
    const [isPreviewMode, setIsPreviewMode] = useState(true);
    const [previewSubject, setPreviewSubject] = useState('');
    const [previewMessage, setPreviewMessage] = useState('');

    const defaultMatterBillingConfiguration = getDefaultMatterBillingConfiguration();
    const matterClientAddressRequiredForTrustDeposit = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);

    const form = useForm({
      scope,
      schema: DEPOSIT_REQUEST_FORM_SCHEMA,
      validateFn: getValidateFn({ matterClientAddressRequiredForTrustDeposit, matterId }),
    });

    const getPreviewInfo = async () => {
      const { subject, message } = await convertToEmailTrustDepositRequest({
        depositRequestData: form.formValues,
        debtorId,
        matterId,
        bankAccountId,
        interpolate: true,
        t,
        defaultMatterBillingConfiguration,
      });
      setPreviewSubject(subject);
      setPreviewMessage(message);
    };

    const {
      formFields,
      formSubmitting,
      formInitialised,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFieldValues,
      onValidateForm,
    } = form;

    const { to, cc, bcc, from, subject, message, sendCopyToUser, staffAddress } = formFields;

    useEffect(() => {
      // Get preview info on load/when message changes instead of on toggle to enable defaulting to preview mode
      if (message) {
        getPreviewInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return {
      isPreviewMode,
      previewSubject,
      previewMessage,
      bankAccountId,
      // fields
      to,
      cc,
      bcc,
      from,
      subject,
      message,
      sendCopyToUser,
      staffAddress,
      // form
      onUpdateFieldValues: (fieldName, value) => {
        onUpdateFieldValues(fieldName, value);
        onValidateForm();
      },

      formInitialised,
      submitFailed,
      formDisabled: formSubmitting,
      // callbacks
      onPreviewToggle: () => setIsPreviewMode(!isPreviewMode),
      onOpenEditContactModal: (clickedContactId) => {
        setModalDialogVisible({ modalId: CONTACT_CREATE_EDIT_MODAL_ID, props: { contactId: clickedContactId } });
      },
      onLoad: () => {
        getInitFormValuesP({ matterId, debtorId, bankAccountId, t })
          .then((initValues) => {
            onInitialiseForm(initValues);
            return undefined;
          })
          .catch((err) => log.error(err));

        return onClearForm;
      },
    };
  },
});

const getInitFormValuesP = async ({ matterId, debtorId }) => {
  const people = await fetchPeopleP(debtorId).catch(() => []);
  const to = formatContactsEmails(people);
  const { email: from } = getStaffEmailDetails();

  const matterEmailSettings = getMatterEmailSettings(matterId) || {};
  const cc = (matterEmailSettings.cCAddresses || []).join(', ');
  const bcc = (matterEmailSettings.bCCAddresses || []).join(', ');

  const defaultMatterBillingConfiguration = getDefaultMatterBillingConfiguration();

  const subject = defaultMatterBillingConfiguration.trustRetainerReplenishEmailSubject;
  const message = defaultMatterBillingConfiguration.trustRetainerReplenishEmailBody;
  const sendCopyToUser = !defaultMatterBillingConfiguration.doNotSendReplenishEmailToUser || false;
  return {
    to,
    cc,
    bcc,
    from,
    subject,
    message,
    sendCopyToUser,
    staffAddress: from,
  };
};

export const convertToEmailTrustDepositRequest = async ({
  depositRequestData,
  debtorId,
  matterId,
  interpolate = true,
  t,
}) => {
  let bcc = depositRequestData.bcc;

  if (depositRequestData.sendCopyToUser) {
    bcc = depositRequestData.bcc
      ? `${depositRequestData.bcc}, ${depositRequestData.staffAddress}`
      : depositRequestData.staffAddress;
  }

  const matterBillingConfig = getCurrentConfigurationByMatterId(matterId);
  const defaultMatterBillingConfiguration = getDefaultMatterBillingConfiguration();

  const depositRequest = {
    to: depositRequestData.to,
    from: depositRequestData.from,
    // empty string would not pass endpoint validation so either email or undefined
    cc: depositRequestData.cc || undefined,
    bcc: bcc || undefined,
    subject: depositRequestData.subject,
    message: depositRequestData.message,
    footer: '',
    payorId: debtorId,
    matterId,
    bankAccountId: depositRequestData.bankAccountId,
  };

  if (interpolate) {
    const { message, subject } = await getTrustDepositRequestEmailInterpolated({
      depositRequest,
      t,
      defaultMatterBillingConfiguration,
      matterBillingConfig,
    });
    depositRequest.subject = subject;
    depositRequest.body = message;
  }

  return depositRequest;
};

export const getValidateFn =
  ({ matterClientAddressRequiredForTrustDeposit, matterId }) =>
  async () => {
    const formErrors = {};

    if (matterId) {
      const matter = getMatterById(matterId);
      formErrors.subject = [];
      const clientsMissingAddresses = matterClientAddressRequiredForTrustDeposit
        ? await matterClientsMissingAddresses(matter)
        : [];

      if (matterClientAddressRequiredForTrustDeposit && clientsMissingAddresses && clientsMissingAddresses.length > 0) {
        formErrors.subject = [
          ...formErrors.subject,
          ...clientsMissingAddresses.map((client) => `contactId:${client.id}'s address is incomplete.`),
        ];
      }
      if (formErrors.subject.length > 0) {
        formErrors.subject = formErrors.subject.join('\n');
      } else {
        delete formErrors.subject;
      }
    } else {
      formErrors.subject = true;
    }

    return formErrors || {};
  };

async function matterClientsMissingAddresses(matter) {
  const matterClients = matter.clientCustomerIds;
  const clientContactsWithMissingStreetAddress = await findContactsWithMissingStreetOrPOAddress({
    contactIds: matterClients,
    spreadGroupsOfPeople: true,
  });
  return clientContactsWithMissingStreetAddress;
}

export const TrustDepositRequestFormContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(TrustDepositRequestForm)),
);
TrustDepositRequestFormContainer.displayName = 'TrustDepositRequestFormContainer';

TrustDepositRequestFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  debtorId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
};
