import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { getById, opdateCache, rollbackOpdateCache } from './index';

export const saveBillingConfiguration = async ({
  matterId,
  accountId,
  trustAccountId,
  billingConfigurationWorkItemToSave,
  savedFromCreateMatter,
}) => {
  await store.dispatch(async () => {
    const existingBillingConfig = getById(matterId); // this may not exist until it's first saved, even for an existing matter
    const finalWorkItem = {
      ...existingBillingConfig, // this is to ensure all existing settings are copied across
      ...billingConfigurationWorkItemToSave,
    };
    const billingConfigurationToOpdate = {
      matterId,
      accountId,
      workItemIds: [...(existingBillingConfig?.workItemIds || []), billingConfigurationWorkItemToSave.workItemId],
      currentWorkItem: finalWorkItem,
    };

    opdateCache({ optimisticEntities: [billingConfigurationToOpdate] });
    try {
      const body = {
        matterId,
        trustAccountId, // this needs to be sent whenever retainer setting is changed
        workItem: finalWorkItem,
        savedFromCreateMatter,
      };
      // This is always set to TRUE for web and FALSE for native app
      // In web app, we always copy all existing settings (finalWorkItem above)
      // Native app does not do that, it would reset defaultTrustAccountId to null every time workItem is updated
      // (If this is not send or sent falsey, backend will ignore defaultTrustAccountId sent and just copy it from DB)
      body.defaultTrustAccountIdProvided = true;
      await fetchPostP({
        path: '/billing/billing-configuration/:accountId/',
        fetchOptions: { body: JSON.stringify(body) },
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [billingConfigurationToOpdate] });
      throw err;
    }
  });
};
