'use strict';

import uuid from '@sb-itops/uuid';
import { store } from '@sb-itops/redux';
import { actions } from '@sb-billing/redux/invoice-pre-draft';

angular.module('sb.billing.webapp').component('sbHookInvoiceEmailModal',
  {
    bindings: { modalScope: '<', invoiceId: '<', draftInvoice: '<', draftInvoiceTotals: '<', quickPaymentsTotalAmount: '<', warningMessage: '<' },
    require: { group: '^sbComposeGroup',  compose: '^sbCompose' },
    templateUrl: 'ng-composable-components/hooks/hook-invoice-email-modal/hook-invoice-email-modal.html',
    controller: function () {
      const ctrl = this;

      ctrl.onSend = () => {};
      ctrl.onClose = () => {};

      ctrl.isInitialised = false;

      ctrl.$onChanges = () => {
        if (!ctrl.draftInvoice || !ctrl.draftInvoiceTotals) {
          return;
        }

        if (!ctrl.isInitialised) {
          ctrl.modalVisible = false;

          // The draft invoice binding (and draft invoice totals) might relate to a previously saved draft invoice (i.e. a real entity)
          // or a 'pre-draft' invoice, i.e. an object in memory only that reflects an invoice that may be saved soon.
          // To allow email sending to interoperate with pre-draft invoices, we need to store data related to the pre-draft in redux.
          // If the invoiceId binding is undefined, we know we are dealing with a pre draft.
          // Setting invoice ids to the pre draft value will provide a way for the invoice email modal to retrieve data from the correct
          // location in redux.
          ctrl.preDraftInvoiceId = uuid(); // If not a pre-draft invoice, this doesn't get used.
          ctrl.invoiceIds = [ctrl.invoiceId || ctrl.preDraftInvoiceId];

          ctrl.group.setActionPreHook(onPreHookActionP);
          ctrl.isInitialised = true;
        }

        // This onChanges() function is called whenever there is an update on the draft invoice page.
        // If the invoiceId binding is undefined, we are deailing with a pre-draft invoice.
        // Here we save the latest pre-daft data to redux so that the invoice send logic can access
        // the most up to date data related to the pre-draft.
        const preDraftInvoice = {
          ...ctrl.draftInvoice,
          invoiceId: ctrl.invoiceId || ctrl.preDraftInvoiceId,
          invoiceVersion: {
            ...ctrl.draftInvoice.invoiceVersion,
            invoiceId: ctrl.invoiceId || ctrl.preDraftInvoiceId,
            preDraftInvoiceTotals: ctrl.draftInvoiceTotals,
          }
        }

        store.dispatch(actions.savePreDraftInvoice({ preDraftInvoice }));
      };

      ctrl.$onDestroy = () => {
        // Clean up the pre-draft invoice data as the preDraftInvoiceId will get re-generated if needed
        store.dispatch(actions.removePreDraftInvoice({ invoiceId: ctrl.preDraftInvoiceId }));

        ctrl.group.setActionPreHook(undefined);
      };

      function onPreHookActionP() {
        return new Promise((resolve, reject) => {
          ctrl.onSend = (invoiceEmailRequests) => {
            // Even if we the have the invoiceId from saved draft invoice, we could have
            // unsaved changes meaning that we need to send preDraftInvoiceId
            resolve({ emailDetails: invoiceEmailRequests, preDraftInvoiceId: ctrl.invoiceId || ctrl.preDraftInvoiceId });
            ctrl.modalVisible = false;
          };

          ctrl.onClose = () => {
            reject();
            ctrl.modalVisible = false;
          }

          ctrl.modalVisible = true;
        });
      }
    }
  }
);
