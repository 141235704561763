import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from '@sb-itops/react';

const BankAccountNumberDisplay = ({ bsb, accountNumber }) => {
  const { t } = useTranslation();
  if (bsb && accountNumber) {
    return (
      <>
        {t('bankBranchNumber')}: {bsb} | Acc: {accountNumber}
      </>
    );
  }
  return '';
};

BankAccountNumberDisplay.propTypes = {
  bsb: PropTypes.string,
  accountNumber: PropTypes.string,
};

BankAccountNumberDisplay.defaultProps = {
  bsb: undefined,
  accountNumber: undefined,
};

export default BankAccountNumberDisplay;
