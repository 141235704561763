angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/trust-cheque';

  $stateProvider
    .state('home.billing.trust-cheque', {
      url: '/trust-cheque/:operationId',
      templateUrl: `${BASE_DIRECTORY}/view-trust-cheque-pdf.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        tab: { type: 'trust-cheques-pdf' }
      }
    });
});
