import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery } from 'web/hooks';
import { WidgetTasks, CalendarData, RecurringEventData } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import uuid from '@sb-itops/uuid';
import { useState } from 'react';
import { selectors, actions } from 'web/redux/features/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getStaffByPersonId, getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { rrulestr } from 'rrule';
import { sortByProperty } from '@sb-itops/nodash';
import { CalendarDocument, RecurringEventsDocument, WidgetTasksDocument } from 'web/graphql/types/graphql';
import { nonNullishFieldsGuardFactory } from '@sb-itops/type-helpers';
import { DailyDigestWidget } from './DailyDigestWidget';

const log = getLogger('tasks.widget');

interface Props {
  isEditing: boolean;
  onChange: Function;
  onRemove: Function;
  onClickLink: React.MouseEventHandler;
  settings: any;
  matterId: string;
}

const hooks = ({ matterId, settings }: Props) => ({
  useActions: () => {
    const [showCalendar, setShowCalendar] = useState(false);
    const dispatch = useDispatch();

    return {
      showCalendar,
      setShowCalendar,
      setTempSettings: (value) => {
        dispatch(actions.setTempSettings({ [settings.id]: value }));
      },
    };
  },
  useGraphQLforEvents: () => {
    const tempSettings = useSelector<{}, {}>(selectors.getTempSettings) || {};

    const digestDay = moment(tempSettings[settings.id]).startOf('day');
    const endOfDay = moment(digestDay).add(1, 'day').add(-1, 'second');
    const [showEventModal, setShowEventModal] = useState(false);
    const {
      data,
      loading: eventsLoading,
      error,
    } = useSubscribedQuery(
      { ...CalendarData, query: CalendarDocument },
      {
        variables: {
          eventFilter: {
            matterId,
            min: digestDay.toISOString(),
            max: endOfDay.toISOString(),
          },
          offset: 0,
          limit: 1000,
        },
      },
    );

    const recurringEventQueryResult = useSubscribedQuery(
      { ...RecurringEventData, query: RecurringEventsDocument },
      {
        variables: {
          eventFilter: {
            matterId,
            min: digestDay.toISOString(),
            max: endOfDay.toISOString(),
          },
        },
      },
    );

    const recurringEventList = recurringEventQueryResult?.data?.recurringEventList?.results || [];

    const appointmentRef = {};
    const expandedAppointments: any[] = [];

    const event1Guard = nonNullishFieldsGuardFactory(['eventType', 'rRULE', 'startTime', 'endTime', 'id']);

    recurringEventList.filter(event1Guard).forEach((a) => {
      if (a.eventType === 1 && a.rRULE) {
        const rRULEParsed = rrulestr(a.rRULE, { dtstart: moment(a.startTime).toDate() });
        const children = rRULEParsed.between(digestDay.toDate(), endOfDay.toDate(), true).map((d) => {
          const dateString = d.toISOString();
          if (a.startTime === dateString) {
            return a;
          }
          const diff = moment(dateString).add(moment(a.endTime).diff(moment(a.startTime)), 'millisecond');
          const appointmentCopy = {
            ...a,
            patternAppointmentId: a.id,
            originalId: a.id,
            startTime: dateString,
            endTime: diff.toISOString(),
            originalAppointment: a,
            id: undefined,
          };
          return appointmentCopy;
        });
        appointmentRef[a.id] = children;

        expandedAppointments.push(...children);
      }
    });

    const eventNot1Guard = nonNullishFieldsGuardFactory(['patternAppointmentId', 'startTime', 'originalStartTime']);

    recurringEventList.filter(eventNot1Guard).forEach((a) => {
      if (
        a?.eventType !== 1 &&
        appointmentRef[a?.patternAppointmentId] &&
        (moment(a?.startTime).isBetween(digestDay, endOfDay) ||
          moment(a?.originalStartTime).isBetween(digestDay, endOfDay))
      ) {
        const matchingInstance = appointmentRef[a.patternAppointmentId].findIndex(
          (c) => c.startTime === a.originalStartTime,
        );
        if (matchingInstance !== -1) {
          if (a.eventType === 4) {
            // remove instance
            appointmentRef[a.patternAppointmentId].splice(matchingInstance, 1);
          } else {
            // update instance
            Object.assign(appointmentRef[a.patternAppointmentId], a);
          }
        } else if (a.eventType !== 4) {
          appointmentRef[a.patternAppointmentId].push(a);
        }
      }
    });

    const rawEvents = data?.eventList?.results || [];

    const events = sortByProperty(
      rawEvents.concat(expandedAppointments).map((appointment) => {
        const staff = (appointment?.resourceIds || []).map((staffId) => getStaffByPersonId(staffId)?.initials);
        return {
          ...appointment,
          staff,
          startDate: appointment?.startTime,
          endDate: appointment?.endTime,
        };
      }),
      'startTime',
      'asc',
    ).filter(
      (ev) =>
        // Filter out any appointments that have already finished today. We dont use graphql for this filter so we can reuse the cached data
        (!digestDay.isSame(moment().startOf('day')) || moment(ev.endTime).isAfter(moment())) &&
        // Filter out any appointments that are not for this user. Cannt filter on the db side as this property is stored in XML, in a BLOB
        (ev?.resourceIds || []).find((staffId) => staffId === getLoggedInStaff()?.id),
    );

    if (error) {
      throw new Error(`${error}`);
    }
    return {
      digestDay,
      showEventModal,
      setShowEventModal,
      events,
      eventsLoading: eventsLoading || recurringEventQueryResult.loading,
    };
  },
  useGraphQLforTasks: () => {
    const tempSettings = useSelector<{}, {}>(selectors.getTempSettings) || {};

    const digestDay = moment(tempSettings[settings.id]).endOf('day');

    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showPhoneMessageModal, setShowPhoneMessageModal] = useState(false);

    const {
      error,
      loading: tasksLoading,
      data,
    } = useSubscribedQuery(
      { ...WidgetTasks, query: WidgetTasksDocument },
      {
        variables: {
          taskFilter: {
            matterId,
            view: 'DIGEST',
            digestDate: digestDay.toISOString(),
            assigneeIds: [getLoggedInStaff()?.id],
          },
          limit: 20,
        },
      },
    );

    if (error) {
      throw new Error(`${error}`);
    }

    const tasks = data?.taskList?.results || [];

    return {
      showTaskModal,
      setShowTaskModal,
      showPhoneMessageModal,
      setShowPhoneMessageModal,
      onCompleteTask: async (task) => {
        try {
          const marshalledData = {
            id: task.id,
            versionId: uuid(),
            matterId: task.matterId,
            subject: task.subject,
            note: task.note,
            isRemoved: false,
            assigneeIds: task.assigneeIds,
            dueDate: task.dueDate,
            categories: task.categories,
            subTaskIds: task.subTaskIds,
            creationTimeStamp: task.creationTimeStamp || moment().toISOString(),
            isNewEntity: false,
            lastAssignedById: task?.lastAssignedById || '',
            isCompleted: true,
          };
          await dispatchCommand({
            type: 'Tasking.ManageTasks.Messages.SaveTasks2',
            message: marshalledData,
          });
          displaySuccessToUser('Task completed');
        } catch (err) {
          displayErrorToUser('Failed to complete task. Please try again later');
          log.error(err);
        }
      },
      tasks,
      tasksLoading,
    };
  },
});

export const DailyDigestWidgetContainer = withApolloClient(composeHooks(hooks)(DailyDigestWidget));
