angular.module('sb.billing.webapp').config(function ($stateProvider) {
  var BASE_DIRECTORY = 'ng-routes/home/billing/contacts/payment-plans';

  $stateProvider
    .state('home.billing.contacts.payment-plans', {
      url: '/payment-plans',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/payment-plans.html',
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts', 'payment-plans']
      }
    });

});
