import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Cent, Dollar } from '@sb-itops/money';
import { LinkableText } from '../linkable-text';
import { InputNumberFilter } from '../input-number-filter';

const NumericFilter = memo(({ startText, endText, clearText, onClearClick, value, onInputValueChanged, showClear }) => {
  const onInputNumericFilterChange = useCallback(
    (newValue) => {
      if (newValue === '') {
        onInputValueChanged(null);
      } else {
        onInputValueChanged(new Dollar(parseFloat(newValue)).cents() || 0);
      }
    },
    [onInputValueChanged],
  );

  const numericValue = Number.isFinite(value) ? new Cent(value).dollars() : '';

  return (
    <div className="numeric-list-filter">
      {(startText && <div className="numeric-list-filter-text">{startText}</div>) || ''}
      <InputNumberFilter step={0.01} value={numericValue} onChange={onInputNumericFilterChange} />
      {(endText && <div className="numeric-list-filter-text">{endText}</div>) || ''}
      {showClear && !!value && onClearClick && (
        <LinkableText className="numeric-list-filter-clear" asLink text={clearText} onClickLink={onClearClick} />
      )}
    </div>
  );
});

NumericFilter.propTypes = {
  startText: PropTypes.string,
  value: PropTypes.number,
  onInputValueChanged: PropTypes.func.isRequired,
  endText: PropTypes.string,
  clearText: PropTypes.string,
  onClearClick: PropTypes.func,
  showClear: PropTypes.bool,
};

NumericFilter.defaultProps = {
  value: undefined,
  startText: undefined,
  endText: undefined,
  clearText: 'Clear',
  onClearClick: undefined,
  showClear: false,
};

export default NumericFilter;
