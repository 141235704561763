'use strict';

const { checkUserIsAuthorised } = require('./check-user-is-authorised');
const { fetchAccessAuthorisations } = require('./fetch-access-authorisations');
const { fetchMatterAuthorisations } = require('./fetch-matter-authorisations');
const { fetchUserPermissions } = require('./fetch-user-permissions');
const { resourceIds } = require('./resource-ids');
const { policyTypes, policyReferenceByResource } = require('./policy');

module.exports = {
  checkUserIsAuthorised,
  fetchAccessAuthorisations,
  fetchMatterAuthorisations,
  fetchUserPermissions,
  resourceIds,
  policyTypes,
  policyReferenceByResource,
};
