angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-matter/fees';

  $stateProvider

  .state('home.billing.view-matter.fees', {
    url: '/time',
    templateUrl: BASE_DIRECTORY + '/view-matter-fees.html',
    controller: 'sbViewMatterFeesController',
    controllerAs: 'matterFees',
    data: {
      authorized: true,
      navHighlights: ['billing', 'matters', 'view-matter'],
      tab: { type: 'matter' }
    }
  });

});
