import { useMemo } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterMemos, InitStaffSettings } from 'web/graphql/queries';
import { useSubscribedQuery, useCacheQuery, useGetMatterById } from 'web/hooks';
import { getMatterDisplay } from '@sb-matter-management/redux/matters';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { MemosTab } from './MemosTab';

const hooks = ({ matterId, onClickLink }: { matterId: string; onClickLink: Function }) => ({
  useGraphQL: () => {
    const { loading, matter } = useGetMatterById(matterId);
    const printTitle = useMemo(() => getMatterDisplay(matter, false, false), [matter]);

    const memoQueryResult = useSubscribedQuery(MatterMemos, {
      variables: {
        filter: {
          matterId,
        },
      },
    });

    if (memoQueryResult.error) {
      throw memoQueryResult.error;
    }

    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    const { data: memoData } = memoQueryResult;

    return {
      printTitle,
      loggedInStaff: staffData?.loggedInStaff,
      loading: memoQueryResult.loading || loading,
      memos: memoData?.memos || [],
      onClickLink,
    };
  },
});

export const MemosTabContainer = withApolloClient(withReduxProvider(composeHooks(hooks)(MemosTab)));
