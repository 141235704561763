import PropTypes from 'prop-types';
import { forms2PropTypes, useTranslation } from '@sb-itops/react';

import { getRegion, regionType, states } from '@sb-itops/region';

import Styles from '../../FeeWiseApplicationForm.module.scss';
import { GenericAddressFields } from './GenericAddressFields';
import { GBAddressFields } from './GBAddressFields';

export const AddressFields = (props) => {
  const { formInitialised, submitFailed } = props;

  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  const region = getRegion();
  const stateOptions = states[region];

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
  const addressFieldsStrategy = {
    [regionType.US]: GenericAddressFields({ ...props, isErrorClassnames, t, stateOptions }),
    [regionType.AU]: GenericAddressFields({ ...props, isErrorClassnames, t, stateOptions }),
    [regionType.GB]: GBAddressFields({ ...props, isErrorClassnames, t }),
  };

  const addressFields = addressFieldsStrategy[region];

  return addressFields;
};

AddressFields.displayName = 'AddressFields';

const { Forms2Field } = forms2PropTypes;

AddressFields.propTypes = {
  addressFields: PropTypes.shape({
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
  }),
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
};

AddressFields.defaultProps = {
  formDisabled: false,
  addressFields: {},
};
