import { gql } from '@apollo/client';

const query = gql`
  query InitFirmInvoiceEmailSettings {
    firmInvoiceEmailSettings {
      emailBody
      emailSubject
      sendCopyToUser
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceEmailSettingsUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitFirmInvoiceEmailSettings = {
  query,
  notificationIds,
};
