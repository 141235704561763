import {
  getByMatterIdWithStringAsEnums,
  getById as getByMatterId,
  titleLineOptionsStringEnums,
  titleLineOptionByValue,
} from '@sb-billing/redux/matter-invoice-settings';
// global default to use until templates are enabled
import { getDetails as getInvoiceSettings } from '@sb-billing/redux/invoice-settings';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { getById as getTemplateById, getFirmDefault } from '@sb-billing/redux/invoice-settings-template';
import { getById as getMatterById, getMatterDisplay } from '@sb-matter-management/redux/matters';
import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';

const generateDefaultMatterTitle = (matterId) => {
  const matter = getMatterById(matterId);
  const matterType = matter && getMatterTypeById(matter.matterTypeId);
  if (!matter || !matterType) {
    return undefined;
  }

  return [matter.matterNumber, matter.clientDisplay, matterType.name].filter((segment) => segment).join(' - ');
};

const generateDefaultMatterDescription = (matterId) => {
  const matter = getMatterById(matterId);
  if (!matter) {
    return undefined;
  }
  return matter.description && matter.description !== '' ? matter.description : generateDefaultMatterTitle(matterId);
};

const getDefaultSetting = (matterId) => {
  const matterInvoiceSettings = getByMatterIdWithStringAsEnums(matterId);
  const invoiceSettings = getInvoiceSettings();

  if (!matterInvoiceSettings) {
    return invoiceSettings;
  }

  const titleSettings = matterInvoiceSettings.titleLine1Overridden ? matterInvoiceSettings : invoiceSettings;
  const subTitleSettings = matterInvoiceSettings.titleLine2Overridden ? matterInvoiceSettings : invoiceSettings;
  const footer = matterInvoiceSettings.footerOverridden ? matterInvoiceSettings.footer : invoiceSettings.footer;

  return {
    titleLine1CustomText: titleSettings.titleLine1CustomText,
    titleLine1Option: titleSettings.titleLine1Option,
    titleLine2CustomText: subTitleSettings.titleLine2CustomText,
    titleLine2Option: subTitleSettings.titleLine2Option,
    footer,
  };
};

const getDefaultSettingWithTemplate = (matterId) => {
  // the matterInvoiceSettings can be undefined when the matter is new.
  const matterInvoiceSettings = getByMatterId(matterId) || {};
  const matterInvoiceTemplate = matterInvoiceSettings.templateId && getTemplateById(matterInvoiceSettings.templateId);
  const templateSettings = (matterInvoiceTemplate && matterInvoiceTemplate.settings) || getFirmDefault().settings;

  // BUG: return templateSettings; would never get executed because when there is no matterInvoiceSettings !{} is false
  // not fixing now as there's a potential this could break something else and we really need to get the fix out there
  if (!matterInvoiceSettings) {
    return templateSettings;
  }

  const titleSettings = matterInvoiceSettings.titleLine1Overridden ? matterInvoiceSettings : templateSettings;
  const subTitleSettings = matterInvoiceSettings.titleLine2Overridden ? matterInvoiceSettings : templateSettings;
  const footer = matterInvoiceSettings.footerOverridden ? matterInvoiceSettings.footer : templateSettings.footer;

  return {
    titleLine1CustomText: titleSettings.titleLine1CustomText,
    titleLine1Option: titleLineOptionByValue[titleSettings.titleLine1Option],
    titleLine2CustomText: subTitleSettings.titleLine2CustomText,
    titleLine2Option: titleLineOptionByValue[subTitleSettings.titleLine2Option],
    footer,
  };
};

export function getDefaultTitleLine1FromSetting(settings, matterId) {
  switch (settings.titleLine1Option) {
    case titleLineOptionsStringEnums.none:
      return '';

    case titleLineOptionsStringEnums.custom:
      return settings.titleLine1CustomText || '';

    case titleLineOptionsStringEnums.matterDescription:
      return generateDefaultMatterDescription(matterId);

    case titleLineOptionsStringEnums.matterTitle:
    default:
      return generateDefaultMatterTitle(matterId);
  }
}

export function getDefaultTitleLine2FromSetting(settings, matterId) {
  switch (settings.titleLine2Option) {
    case titleLineOptionsStringEnums.none:
      return '';

    case titleLineOptionsStringEnums.custom:
      return settings.titleLine2CustomText || '';

    case titleLineOptionsStringEnums.matterDescription:
      return generateDefaultMatterDescription(matterId);

    case titleLineOptionsStringEnums.matterTitle:
      return generateDefaultMatterTitle(matterId);

    default:
      return '';
  }
}

export function getDefaultTitleLine1(matterId) {
  const settings = getDefaultSetting(matterId);
  if (!settings) {
    return undefined;
  }

  return getDefaultTitleLine1FromSetting(settings, matterId);
}

export function getDefaultTitleLine2(matterId) {
  const settings = getDefaultSetting(matterId);
  if (!settings) {
    return undefined;
  }

  return getDefaultTitleLine2FromSetting(settings, matterId);
}

export function getDefaultTitleLine1WithTemplate(matterId) {
  const settings = getDefaultSettingWithTemplate(matterId);
  if (!settings) {
    return undefined;
  }

  return getDefaultTitleLine1FromSetting(settings, matterId);
}

export function getDefaultTitleLine2WithTemplate(matterId) {
  const settings = getDefaultSettingWithTemplate(matterId);
  if (!settings) {
    return undefined;
  }

  return getDefaultTitleLine2FromSetting(settings, matterId);
}

export function getDefaultFooterWithTemplate(matterId) {
  const settings = getDefaultSettingWithTemplate(matterId);
  return !settings ? undefined : settings.footer;
}

export const getInvoiceTitle1 = (invoiceId) => {
  const invoice = getInvoiceById(invoiceId);
  if (!invoice) {
    return '';
  }

  return getMatterDisplay(getMatterById(invoice.matterId));
};
