import { dateToInteger, startOfThisMonth, endOfThisMonth, dateRangeTypes, todayAsInteger } from '@sb-itops/date';

import { SELECT_DATE_RANGE_TYPE, SELECT_BEFORE_DATE, SELECT_FROM_DATE, SELECT_TO_DATE } from './types';

const initialState = {
  selectedDateRangeType: dateRangeTypes.ALL,
  beforeDate: todayAsInteger(),
  fromDate: dateToInteger(startOfThisMonth()),
  toDate: dateToInteger(endOfThisMonth()),
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SELECT_DATE_RANGE_TYPE: {
      return {
        ...state,
        selectedDateRangeType: action.payload.dateRangeType,
      };
    }
    case SELECT_BEFORE_DATE: {
      return {
        ...state,
        beforeDate: action.payload.beforeDate,
      };
    }
    case SELECT_FROM_DATE: {
      return {
        ...state,
        fromDate: action.payload.fromDate,
      };
    }
    case SELECT_TO_DATE: {
      return {
        ...state,
        toDate: action.payload.toDate,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
