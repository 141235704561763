'use strict';

angular.module('sb.billing.webapp').controller('sbViewMatterDashboardController', function($scope, $state, $stateParams, sbLinkService, sbMessageDisplayService, sbTrustRetainersService, sbBillingConfigurationService, sbDefaultMatterBillingConfigurationService, sbLocalisationService) {
  const ctrl = this;
  ctrl.matterId = $stateParams.matterId;
  ctrl.onClickLink = sbLinkService.onClickLink;

  const checkRetainerWarning = () => {
    const retainerBelowLimit = checkRetainer();
    const retainerStatusChanged = ctrl.view.trustRetainerBelowLimit !== retainerBelowLimit; // we only want to show the message box if the retainer status has changed between update events
    ctrl.view.trustRetainerBelowLimit = retainerBelowLimit;

    if (ctrl.view.trustRetainerBelowLimit) {
      if (retainerStatusChanged) {
        sbMessageDisplayService.warn(
          sbMessageDisplayService
            .builder()
            .text(`Matter ${sbLocalisationService.t('trust').toLowerCase()} balance is below threshold`)
            .action(() => {
              $state.go('home.billing.accounts.evergreen-requests', { request: 'all' });
              sbMessageDisplayService.dismissGroup('retainer-warning');
            })
            .actionText('See All')
            .group('retainer-warning')
        );
      }
    } else {
      sbMessageDisplayService.dismissGroup('retainer-warning');
    }
  }

  ctrl.view = {
    trustRetainerBelowLimit: checkRetainer(),
  };

const listeners = [
  $scope.$on('$destroy', () => {
    for (let unregister of listeners) unregister();
  }),
    $scope.$on('smokeball-data-update-sbTrustRetainersService', checkRetainerWarning),
  ];

  ctrl.dataChangeFunction = (key, doc) => {
    if (doc && doc.data) {
      ctrl.sbData[key] = doc.data;
    } else {
      ctrl.sbData[key] = doc;
    }
  };

  function checkRetainer() {
    const trustRetainerEntry = sbTrustRetainersService.getById(ctrl.matterId);
    const billingConfigEntry = sbBillingConfigurationService.getByMatterId(ctrl.matterId) || {};
    const matterDefaultConfigEntry = sbDefaultMatterBillingConfigurationService.getConfig() || {};

    return trustRetainerEntry && billingConfigEntry.minimumTrustRetainerActive && matterDefaultConfigEntry.minimumTrustRetainerActive ? !trustRetainerEntry.replenished : false;
  }
});
