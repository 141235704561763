import PropTypes from 'prop-types';
import React, { memo, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import { LinkableText } from '@sb-itops/react/linkable-text';
import { TextEditor } from '@sb-itops/react/text-editor';
import { featureActive } from '@sb-itops/feature';
import { InvoiceTemplateDropdownContainer } from '../invoice-template-dropdown';
import { InvoiceAdditionalOptions } from '../invoice-additional-options';

const InvoiceTemplateSettings = memo(
  ({
    templateId,
    onTemplateChange,
    titleText,
    subtitleText,
    footer,
    showResetTemplateButton,
    onSubtitleChangeText,
    onTitleChangeText,
    onClearSelection,
    onSetDefaultTemplate,
    onFooterChange,
    isValidFooter,
    invoiceAdditionalOptions,
    onInvoiceConfigurationOptionChanged,
    resetText,
    selectedTemplateDeleted,
    provideShowRetainerOption,
    hasOpenTrustAccountsForMatter,
    supportsTax,
    isExpanded,
  }) => {
    // on un-mount
    useEffect(() => () => onClearSelection(), [onClearSelection]);

    const onSubtitleChangeTextHandler = useCallback(
      (e) => onSubtitleChangeText(e.target.value),
      [onSubtitleChangeText],
    );

    const onTitleChangeTextHandler = useCallback((e) => onTitleChangeText(e.target.value), [onTitleChangeText]);

    return (
      <div className={classnames('form-group', 'draft-invoice-template-settings', !isExpanded && 'hidden')}>
        <div className="input-group form-group">
          <label>TEMPLATE</label>
          <InvoiceTemplateDropdownContainer templateId={templateId} onValueChange={onTemplateChange} />
        </div>
        {showResetTemplateButton && (
          <div className="reset-template">
            <LinkableText asLink text={resetText} onClickLink={onSetDefaultTemplate} />
          </div>
        )}
        {selectedTemplateDeleted && (
          <div className="deleted-warning">
            <span>
              The selected invoice template has been deleted from firm settings, but will continue to be applied until
              changed.
            </span>
          </div>
        )}
        <div className="input-group form-group">
          <label>TITLE</label>
          <input className="form-control" type="text" value={titleText} onChange={onTitleChangeTextHandler} />
        </div>
        <div className="input-group form-group">
          <label>SUBTITLE</label>
          <input className="form-control" type="text" value={subtitleText} onChange={onSubtitleChangeTextHandler} />
        </div>
        {featureActive('BB-4706') && (
          <div className="input-group form-group">
            <label className="notes-input">NOTES</label>
            <div className="flex-grow">
              <TextEditor
                value={footer}
                className={!isValidFooter ? 'text-editor-error' : ''}
                fixedHeight
                onChange={onFooterChange}
              />
            </div>
            {!isValidFooter && <div className="footer-error">Maximum 30 lines allowed</div>}
          </div>
        )}
        <InvoiceAdditionalOptions
          {...invoiceAdditionalOptions}
          onOptionChanged={onInvoiceConfigurationOptionChanged}
          provideShowRetainerOption={provideShowRetainerOption}
          hasOpenTrustAccountsForMatter={hasOpenTrustAccountsForMatter}
          supportsTax={supportsTax}
        />
      </div>
    );
  },
);

InvoiceTemplateSettings.propTypes = {
  onTemplateChange: PropTypes.func.isRequired,
  onSubtitleChangeText: PropTypes.func.isRequired,
  onTitleChangeText: PropTypes.func.isRequired,
  onInvoiceConfigurationOptionChanged: PropTypes.func.isRequired,
  onFooterChange: PropTypes.func.isRequired,
  templateId: PropTypes.string,
  isValidFooter: PropTypes.bool,
  subtitleText: PropTypes.string,
  titleText: PropTypes.string,
  footer: PropTypes.string,
  showResetTemplateButton: PropTypes.bool,
  onClearSelection: PropTypes.func,
  onSetDefaultTemplate: PropTypes.func,
  invoiceAdditionalOptions: PropTypes.object,
  resetText: PropTypes.string,
  selectedTemplateDeleted: PropTypes.bool,
  provideShowRetainerOption: PropTypes.bool,
  hasOpenTrustAccountsForMatter: PropTypes.bool,
  supportsTax: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
};

InvoiceTemplateSettings.defaultProps = {
  templateId: undefined,
  subtitleText: undefined,
  titleText: undefined,
  footer: undefined,
  isValidFooter: true,
  showResetTemplateButton: false,
  onClearSelection: () => {},
  onSetDefaultTemplate: undefined,
  invoiceAdditionalOptions: {},
  resetText: 'Reset to default',
  selectedTemplateDeleted: false,
  provideShowRetainerOption: false,
  hasOpenTrustAccountsForMatter: false,
  isExpanded: false,
};

export default InvoiceTemplateSettings;
