'use strict';

/**
 * @typedef EntityLink
 * @property {string} id id of entity
 * @property {string} href hypertext reference, or hypertext link to entity resource
 * @property {string} rel relation to parent entity, or entity type
 */

/**
 * @typedef {EntityLink} ContactEntityLink
 */

/**
 * @typedef Role
 * @property {string} id role id
 * @property {string} name role name
 * @property {ContactEntityLink} contact contact details, including a link to the contact resource
 * @property {Object[]} representatives representatives
 * @property {Object[]} relationships relationships
 * @property {boolean} isClient is a client role
 * @property {boolean} isOtherSide is other side role
 */

/**
 * @typedef RoleToChange
 * @property {string} id role id
 * @property {string} name role name
 * @property {string} contactId contact id
 */

/**
 * @typedef RoleToCreate
 * @property {string} name role name
 * @property {string} contactId contact id
 */

/**
 * @typedef {RoleToChange} RoleToUpdate
 */

/**
 * @typedef {RoleToChange} RoleToDelete
 * only role id is really required here, but returning name and contactId will help with debugging
 */

/**
 * @typedef RoleChanges Type of changes required
 * @property {RoleToUpdate[]} update roles that need to be updated
 * @property {RoleToCreate[]} new new roles that needs to be created
 * @property {RoleToDelete[]} delete roles that need to be deleted
 */

/**
 * Given the specified roles for the roleName. Determine all role changes that need to be made
 * @param {Object} params
 * @param {Role[]} params.roles list of existing roles of roleName in order they appear on UI
 * @param {string} params.roleName role name for given list of roles that need to be updated
 * @param {string[]} params.contactIds list of contact ids associated with specified role
 * @param {boolean} [params.useStrictValidation] use strict validation, e.g. all roles must be of the same role name
 * @returns {RoleChanges}
 */
function computeRoleChanges({ roles, roleName, contactIds, useStrictValidation = false }) {
  if (!Array.isArray(roles)) {
    throw new Error('roles should be an array');
  }

  if (!roleName) {
    throw new Error('roleName is required');
  }

  if (!Array.isArray(contactIds)) {
    throw new Error('contactIds should be an array');
  }

  if (useStrictValidation && roles.some((role) => role.name !== roleName)) {
    throw new Error(`all roles must be of roleName ${roleName}`);
  }

  // NB: we are not currently supporting updating of representatives and relationships

  // roles to update and/or create
  const roleChanges = contactIds.reduce(
    (acc, currentClientId, currentIndex) => {
      if (currentIndex < roles.length) {
        // update existing role so as to present contact in the correct order on the UI
        const currentRole = roles[currentIndex];
        const existingRole = {
          id: currentRole.id,
          name: roleName,
          contactId: currentClientId,
        };
        acc.update.push(existingRole);
      } else {
        // if there's more new client ids than there are client roles, create new roles
        const newRole = { name: roleName, contactId: currentClientId };
        acc.new.push(newRole);
      }
      return acc;
    },
    { update: [], new: [], delete: [] },
  );

  // roles to delete
  if (roles.length > contactIds.length) {
    // Roles act like placeholders that we can associate a contact with
    // Any roles no longer needed are put into the delete bucket. E.g. if there are
    // more client roles than there are clients (contactIds), we need to delete these.
    roleChanges.delete = roles.slice(contactIds.length, roles.length).map((roleToDelete) => ({
      id: roleToDelete.id,
      name: roleToDelete.name,
      contactId: roleToDelete.contact?.id, // optional chaining to guard against bad data since this is not required data
    }));
  }

  return roleChanges;
}

module.exports = {
  computeRoleChanges,
};
