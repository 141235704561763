'use strict';
import {featureActive} from '@sb-itops/feature'

angular
  .module('sb.billing.webapp')
  .config(function($provide, $urlRouterProvider, sbLoggerServiceProvider) {
      // Set the default log level, override with query parameter if set.
      const urlParams = new URL(document.location).searchParams;
      const logLevel = urlParams.get('logLevel') || 'warn';
      sbLoggerServiceProvider.setDefaultLevel(logLevel);

   // If the user navigates to an unknown state manually, or a bug exists that allows it to happen,
   // we will redirect them to the firm matters list.
   // $urlRouterProvider.deferIntercept();
   $urlRouterProvider.otherwise(($injector) => {
      const $state = $injector.get('$state');
      if (featureActive('NUCWEB-123')) {
        $state.go('home.billing.dashboard');
      } else {
        $state.go('home.billing.matters');
      }
    });

    // disable Tab key inside text-angular component
    $provide.decorator('taOptions', function($delegate) {
      $delegate.keyMappings = [
        {
          commandKeyCode: 'TabKey',
          testForKey: () => false
        }
      ];

      return $delegate;
    });
  });

/* eslint-disable no-unused-vars */
angular
  .module('sb.billing.webapp')
  .run(($state, $rootScope, sbTabService, sbFeeConfigurationService,
    sbMatterAutoNumberDefinitionService, sbMattersMbService,
    sbMatterTypeConfigurationsService, sbMatterTypesMbService,
    sbFirmManagementMbService, sbDefaultBankAccountsService, sbStaffHourlyRateService, sbMatterHourlyRateService,
    sbInvoicingService, sbInvoiceTotalsService, sbMatterTotalsService, sbTransactionService, sbBillingActivityService, sbExpenseCacheManager,
    sbQuickBooksService, sbRelateContactToMattersMbService, sbInvoiceSettingsService, sbContactsMbService, sbBankAccountService, sbBankAccountBalancesService, sbOptimisticUpdatesService,
    sbGenericCacheService, sbLoggerService, sbPaymentService, sbTransactionReceiptService, sbRequestDepositService, sbNsbNotificationService, sbWatcherNotificationService,
    sbUpgradeService, sbUiRouterDebuggerService, sbTrustReceiptSettingsService, sbTrustChequePrintSettingsService, sbTrustChequeService, sbBankReconciliationService, sbRegisteredUsersService,
    sbDepositSlipService, sbTrustRetainersService, sbDefaultMatterBillingConfigurationService, sbInterestSettingsService, sbBillingConfigurationService, sbBankAccountSettingsService,
    sbAccountRemindersService, sbAccountRemindersSettingsService, sbVendorPaymentsService, sbUserTimerService, sbUtbmsSettingsService, sbUtbmsActivityCodeService, sbUtbmsTaskCodeService, sbCustomerBillingConfigurationService, sbStaffFeeConfigurationService,
    sbUnsavedChangesService, sbMessageDisplayService, sbGstTaxSettingsService, sbUuidService, sbEnvironmentConfigService, sbCorrespondenceHistoryService, sbBulkDepositService, sbMatterInvoiceSettingsService, sbInvoiceSettingsTemplateService, sbEndOfMonthReportsService, sbInvoiceSettingsTemplateFilesService,
    sbAutoNumberingPrefixesService, sbPaymentPlansService, sbPaymentPlanEventsService, sbBulkFinalizeSettingsService, sbOperatingChequePrintSettingsService, sbOperatingChequesService, sbEntityBankDetailsService,
    sbMyobIntegrationService, sbMyobBusinessService, sbXeroService,
    sbInvoiceEmailSettingsService, sbBillingEventService, sbUserBillingAttributesService,
    sbEInvoiceSettingsService, sbPaymentProviderSettingsService, sbSubscriptionService,
    sbRecentInvoiceService, sbRecentMatterService, sbReportConfigService, sbRateSetsService,
    sbControlledMoneyAccountSettingsService, sbBalanceProtectionService, sbMatterEmailSettingsService, sbMatterCommunicationSettingsService,
    sbBankReconciliationSetupService2, sbExpensePaymentDetailsService, sbCorrespondenceHistoryFullService, sbCustomTaskCodeService,
    $window) => {
      // Wait for the Smokeball object to become available and have our feature functions attached
      const deregisterSmokeballWatcher = $rootScope.$watch(() => $window.Smokeball && $window.Smokeball.enableFeature ? $window.Smokeball : null, (Smokeball) => {
        if (!Smokeball) {
          return; // The initial 'change' is empty, nothing to do here.
        }
        const enableFeature = Smokeball.enableFeature;
        const disableFeature = Smokeball.disableFeature;
        
        // Decorate the Smokeball feature methods so they trigger a an Angular digest, which in turn triggers react updates!
        const enabledByManualOverride = true; // Prevents launch darkly from overwritting manual overrides.
        const disabledByManualOverride = true; // Allows this manual override to overwrite others (e.g. URL)
        Smokeball.enableFeature = (featureTag) =>  $rootScope.$apply(() => enableFeature(featureTag, enabledByManualOverride));
        Smokeball.disableFeature = (featureTag) => $rootScope.$apply(() => disableFeature(featureTag, disabledByManualOverride));
        deregisterSmokeballWatcher(); // Deregister the watcher, decoration is one shot
      });
      $state.go('pre-authentication');
    });

/* eslint-disable no-unused-vars */
