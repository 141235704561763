import * as Yup from 'yup';

export const trustNumberingSettingsSchema = Yup.object().shape({
  receipt: Yup.object().shape({
    prefix: Yup.string().max(10),
    nextNumber: Yup.number().integer().min(1).max(9999999999).required(),
    leadingZeros: Yup.number().integer().min(0).max(10).required(),
  }),
  transferOfFunds: Yup.object().shape({
    useManualNumbering: Yup.bool().required(),
    prefix: Yup.string().max(10),
    nextNumber: Yup.number().integer().min(1).max(9999999999).required(),
    leadingZeros: Yup.number().integer().min(0).max(10).required(),
  }),
  electronicPayments: Yup.object().shape({
    useManualNumbering: Yup.bool().required(),
    prefix: Yup.string().max(10),
    nextNumber: Yup.number().integer().min(1).max(9999999999).required(),
    leadingZeros: Yup.number().integer().min(0).max(10).required(),
  }),
  trustToOffice: Yup.object().shape({
    useManualNumbering: Yup.bool().required(),
    prefix: Yup.string().max(10),
    nextNumber: Yup.number().integer().min(1).max(9999999999).required(),
    leadingZeros: Yup.number().integer().min(0).max(10).required(),
  }),
});

export const controlledMoneyNumberingSettingsSchema = Yup.object().shape({
  receipt: Yup.object().shape({
    prefix: Yup.string().max(10),
    nextNumber: Yup.number().integer().min(1).max(9999999999).required(),
    leadingZeros: Yup.number().integer().min(0).max(10).required(),
  }),
  electronicPayments: Yup.object().shape({
    useManualNumbering: Yup.bool().required(),
    prefix: Yup.string().max(10),
    nextNumber: Yup.number().integer().min(1).max(9999999999).required(),
    leadingZeros: Yup.number().integer().min(0).max(10).required(),
  }),
});
