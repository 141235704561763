'use strict';

/**
 * Generate fee notes
 * @param {Object} feeVersion
 * @param {boolean} durationInHours - false by default which means duration in feeVersion.sourceItems is in hours
 */
function generateFeeNotes(feeVersion, showNonBillableFees = true, durationInHours = false) {
  // 1) fee.notes is shown on invoice pdf when "Show description for Entries" invoicing option is turned on. For grouped fees,
  // this will show a summary of the all the source items. For non-grouped fees, show notes/description entered as part of the fee.
  // 2) when show non-billable fees option is specified, show non-billable items along with label, otherwise don't show non-billable items.
  const moreThanOne = feeVersion.sourceItems && feeVersion.sourceItems.length > 1;

  if (!(feeVersion.sourceItems && feeVersion.sourceItems.length > 0)) {
    return feeVersion.notes; // non-grouped fees, return notes/description entered as part of fee
  }

  // grouped fees, construct description from source items
  const notes = `${(feeVersion.sourceItems || [])
    .filter(({ description, billable }) => {
      const showItem = feeVersion.isBillable || billable || showNonBillableFees;
      return description && showItem;
    })
    .map(
      ({ duration, description, billable }) =>
        `- ${description} (${(durationInHours ? duration : duration / 60).toFixed(2)}hrs${
          feeVersion.isBillable === false || (feeVersion.isBillable === null && billable === false)
            ? ' - Non-Billable'
            : ''
        }) ${(moreThanOne && '\n') || ''}`,
    )
    .join('')}`;
  return notes;
}

module.exports = {
  generateFeeNotes,
};
