'use strict';

const moment = require('moment');

const createOpdates = ({ paymentToReverse, transactionToReverse }) => {
  const opdates = {};
  if (transactionToReverse.reversed) {
    throw new Error('Invoice payment transaction already reversed');
  }

  opdates.sbTransactionService = [
    {
      ...transactionToReverse,
      reversed: true,
    },
  ];

  opdates.sbPaymentService = [
    {
      ...paymentToReverse,
      id: paymentToReverse.paymentId,
      reversedAt: moment().toISOString(),
      isHidden: false,
      allowOverdraw: false,
    },
  ];

  return opdates;
};

module.exports = {
  createOpdates,
};
