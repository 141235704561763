'use strict';

const { providers, transactionTypes, feeCoverageModes } = require('../entities/constants');
const { getChargeDescription } = require('../services');
const { extractChargeFields: extractFeeWiseSpecificChargeFields } = require('../services/fee-wise');

const providerSpecificChargeExtractors = {
  [providers.LAWPAY]: extractLawpaySpecificChargeFields,
  [providers.STRIPE]: extractStripeSpecificChargeFields,
  [providers.EZY_COLLECT]: extractStripeSpecificChargeFields, // Ezy Collect is currently utilising a stripe backend for charging.
  [providers.FEE_WISE]: extractFeeWiseSpecificChargeFields,
};

const createDepositChargeRequest = ({
  accountId,
  requestedByUserId,
  providerType,
  paymentFormData,
  firmName,
  staffName,
  feeCoverageMode,
  t,
}) => {
  if (!providerType) {
    throw new Error('Provider type must be provided');
  }

  if (!paymentFormData || !paymentFormData.smokeballFormData || !paymentFormData.providerFormData) {
    throw new Error('Invalid payment form data');
  }

  const { smokeballFormData, providerFormData } = paymentFormData;

  const extractProviderSpecificChargeFields = providerSpecificChargeExtractors[providerType];
  if (!extractProviderSpecificChargeFields) {
    throw new Error(`Cannot create charge request for unsupported provider type '${providerType}'`);
  }

  const descriptionAmount =
    feeCoverageMode === feeCoverageModes.CLIENT_PAYS
      ? smokeballFormData.amountLessFees
      : smokeballFormData.depositAmount;

  // Nothing other than amountInCents should be passed to getChargeDescription from smokeballFormData.
  // This is because every app will have a different structure for smokeballFormData as it's the wrapping layer
  // around the provider specific form.
  const description = getChargeDescription({
    providerType,
    amountInCents: descriptionAmount,
    firmName,
    staffName,
    providerSpecificFields: providerFormData,
    t,
  });

  // WARNING
  // The only data derived from paymentFormData that is included in the below returned object must
  // be extracting using an extractor and passed in providerSpecificFields. Referring to provider
  // specific fields directly in a top level property below is a huge no-no as it will break the genericity
  // of the payment provider API.
  // Seriously. If you don't heed this warning, there will be endless frowns cast upon you.
  const providerSpecificFields = extractProviderSpecificChargeFields({ paymentFormData });
  return {
    id: smokeballFormData.chargeId,
    providerType,
    providerSpecificFields,
    transactionType: transactionTypes.DEPOSIT,
    description,
    amountInCents: smokeballFormData.depositAmount,
    amountLessFeesInCents: smokeballFormData.amountLessFees,
    targetBankAccountId: smokeballFormData.bankAccountId,
    reason: smokeballFormData.reason,
    effectiveDateYYYYMMDD: `${smokeballFormData.dateDeposited}`,
    accountId,
    matterId: smokeballFormData.matterId,
    payorId: smokeballFormData.payorId,
    requestedByUserId,
    feeCoverageMode,
    descriptionAmount,
  };
};

function extractLawpaySpecificChargeFields({ paymentFormData }) {
  const { providerFormData } = paymentFormData;

  if (!providerFormData.paymentToken) {
    throw new Error('Failed to extract lawpay specific charge fields - payment token is required');
  }

  if (!providerFormData.paymentMethod) {
    throw new Error('Failed to extract lawpay specific charge fields - payment method is required');
  }

  return { paymentToken: providerFormData.paymentToken, paymentMethod: providerFormData.paymentMethod };
}

function extractStripeSpecificChargeFields({ paymentFormData }) {
  const { providerFormData } = paymentFormData;

  if (!providerFormData.paymentToken) {
    throw new Error('Failed to extract Stripe specific charge fields - payment token is required');
  }

  return { paymentToken: providerFormData.paymentToken, email: providerFormData.email };
}

module.exports = {
  createDepositChargeRequest,
};
