import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { invoiceStatementStatusLabels } from '@sb-billing/business-logic/invoice-statement';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { isModalVisible, setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { store } from '@sb-itops/redux';
import composeHooks from '@sb-itops/react-hooks-compose';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';

import { InvoiceStatementActionBar } from './InvoiceStatementActionBar';

const VOID_STATEMENT_CONFIRMATION_MODAL_ID = 'void-statement-confirmation-modal';

const hooks = () => ({
  useVoidStatementConfirmationModal: ({ invoiceStatementId, invoiceStatementStatus }) => {
    const isVoid = invoiceStatementStatus === invoiceStatementStatusLabels.VOID.toUpperCase();
    const openVoidConfirmationModal = () => {
      setModalDialogVisible({ modalId: VOID_STATEMENT_CONFIRMATION_MODAL_ID });
    };
    const voidInvoiceStatement = async () => {
      try {
        await dispatchCommand({
          type: 'Billing.Invoicing.Messages.Commands.VoidInvoiceStatement',
          message: { invoiceStatementId },
        });

        setModalDialogHidden({ modalId: VOID_STATEMENT_CONFIRMATION_MODAL_ID });
      } catch (error) {
        messageDisplay.error('Failed to void the statement');
        setModalDialogHidden({ modalId: VOID_STATEMENT_CONFIRMATION_MODAL_ID });
      }
    };
    const showVoidConfirmationModal = useSelector(() =>
      isModalVisible({ modalId: VOID_STATEMENT_CONFIRMATION_MODAL_ID }),
    );

    const closeVoidConfirmationModal = () => {
      setModalDialogHidden({ modalId: VOID_STATEMENT_CONFIRMATION_MODAL_ID });
    };

    return {
      isVoid,
      openVoidConfirmationModal,
      closeVoidConfirmationModal,
      voidInvoiceStatement,
      showVoidConfirmationModal,
    };
  },
  useDownloadPdf: ({ invoiceStatementNumber, previewUrl }) => {
    const isPreviewReady = !!previewUrl;
    const [isDownloading, setIsDownloading] = useState(!isPreviewReady);

    useEffect(() => {
      setIsDownloading(!isPreviewReady);
    }, [isPreviewReady]);

    const downloadPdf = async () => {
      setIsDownloading(true);
      // Download the document from S3
      const invoiceStatementDocumentResponse = await fetch(previewUrl, {
        method: 'GET',
      });

      const invoiceStatementDocument = await invoiceStatementDocumentResponse.blob();
      FileSaver.saveAs(invoiceStatementDocument, `Statement-${invoiceStatementNumber}.pdf`);
      setIsDownloading(false);
    };

    return { isDownloading, downloadPdf };
  },
  useDebugMode: () => {
    const showDebug = useSelector(() => supportDebugSelectors.getShowDebug(store.getState()));
    return { showDebug };
  },
});

export const InvoiceStatementActionBarContainer = withReduxProvider(composeHooks(hooks)(InvoiceStatementActionBar));

InvoiceStatementActionBarContainer.displayName = 'InvoiceStatementActionBarContainer';
InvoiceStatementActionBarContainer.propTypes = {
  invoiceStatementLoading: PropTypes.bool.isRequired,
  invoiceStatementId: PropTypes.string.isRequired,
  invoiceStatementNumber: PropTypes.string,
  previewUrl: PropTypes.string, // Presigned URL for the invoice statement PDF
};
InvoiceStatementActionBarContainer.defaultProps = {
  invoiceStatementNumber: undefined,
  previewUrl: undefined,
};
