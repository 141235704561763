'use strict';

const bankTransferTypeEnum = {
  BANK_TRANSFER_OR_DEPOSIT: 0,
  BPAY: 1,
};

module.exports = {
  bankTransferTypeEnum,
};
