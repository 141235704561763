import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { getRegion } from '@sb-itops/environment-config';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import { featureActive } from '@sb-itops/feature';
import MatterBalancesFilter from './MatterBalancesFilter';

const defaultSelectOption = { value: 'all', label: 'All' };

const matterStatuses = [
  defaultSelectOption,
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Closed', label: 'Closed' },
  { value: 'Open', label: 'Open' },
  { value: 'Pending', label: 'Pending' },
];

const mapStateToProps = (state, { filters }) => ({
  staff: [defaultSelectOption, ...getCurrentStaff().map((s) => ({ value: s.id, label: `${s.initials} - ${s.name}` }))],
  matterStatuses,
  filters: filters
    ? {
        ...filters,
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      const defaultState = {
        region: getRegion(),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        attorneyResponsible: defaultSelectOption,
        matterStatus: defaultSelectOption,
        viewOption: 'showAsList',
        excludeZeroBalanceMatters: true,
        excludeIncompleteMatters: true,
        showMatterDebt: false,
        excludeExternallyBilled: featureActive('BB-2530'), // Should be true by default once the feature flag is removed
        splitUnbilledAmounts: featureActive('BB-6682'), // 6682 is an opt-in feature, determining which default value to use if feature flag is removed doesn't make sense.
      };

      onFiltersChanged(defaultState);
    }
  },

  onSelectOption: ({ key, option }) => onFiltersChanged({ ...filters, [key]: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const MatterBalancesFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(MatterBalancesFilter)),
);

MatterBalancesFilterContainer.displayName = `MatterBalancesFilterContainer`;

export default MatterBalancesFilterContainer;
