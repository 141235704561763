angular.module('sb.billing.webapp').component('sbDuiEntityDisplay', {
  require: { sbComposeCtrl: '^sbCompose' },
  templateUrl: 'ng-composable-components/data-ui/generic/entity-display/dui-entity-display.html',
  bindings: { entityDataKey: '@', displayFields: '<' },
  controller: function (sbLoggerService, $state) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDuiEntityDisplay');

    that.getField = getField;
    that.toContactCard = toContactCard;

    that.$onChanges = (changesObj) => {
      log.info('bindings change', changesObj);
      updateDisplay(changesObj.entityDataKey, changesObj.displayFields);
    };

    function updateDisplay(entityDataKey, displayFields) {
      updateEntityData(entityDataKey && entityDataKey.currentValue);
      if (displayFields && displayFields.currentValue) {
        updateDisplayFields(displayFields.currentValue);
      }
    }

    function updateEntityData(entityDataKey) {
      that.sbComposeCtrl.onUpdateData(entityDataKey, (entityData) => {
        that.entityData = entityData && entityData.data;
        log.info('entity data updated to : ', entityData);
      });
    }

    function updateDisplayFields(displayFields) {
      if (_.isEmpty(displayFields)) {
        displayFields = [];
      } else if (!_.isArray(displayFields)) {
        displayFields = [displayFields];
      }

      that.displayFields = displayFields;
    }

    function getField(inFieldSpec) {
      //if the fieldSpec is an object with a field property, that's the actual name of the field
      const fieldSpec = inFieldSpec.field || inFieldSpec;

      if (_.isString(fieldSpec)) {
        if (fieldSpec === 'self') {
          return that.entityData;
        }

        return _.get(that.entityData, fieldSpec);
      }

      if (fieldSpec.find) {
        const fieldName = _.find(fieldSpec.find, (f) => !_.isEmpty(_.get(that.entityData, f)));

        return fieldName && _.get(that.entityData, fieldName);
      }

      log.warn('unknown fieldSpec', fieldSpec);
    }

    function toContactCard () {
      log.info('going to contact');
      $state.go('home.billing.view-contact.details', {contactId: that.entityData.entityId});
    }

  }
});
