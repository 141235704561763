import React from 'react';
import { t } from '@sb-itops/localisation-web';

import Styles from '../ConfigureMatterNumbering.module.scss';
import { TRelativePosition, TWidget } from '../types';
import { DraggableWidget } from '../draggable-widget';

type TAssemblyDropzoneProps = {
  widgets: TWidget[];
  dragoverX?: number;
  updatePlaceholderPosition?: (reportingWidgetId: string, relativePosition: TRelativePosition) => void;
  setDragInstanceId?: (instanceId?: string) => void;
  onDragOver?: React.DragEventHandler;
  onDrop?: React.DragEventHandler;
  onDragLeave?: React.DragEventHandler;
  selectWidget?: (instanceId?: string) => void;
  selectedWidgetId?: string;
};

export const AssemblyDropzone = ({
  widgets,
  dragoverX,
  updatePlaceholderPosition,
  setDragInstanceId,
  onDragLeave,
  onDragOver,
  onDrop,
  selectWidget,
  selectedWidgetId,
}: TAssemblyDropzoneProps) => (
  <div>
    <div className={Styles.assemblyContainerContainer}>
      <div className={Styles.assemblyContainerHeader}>
        Drag components from above and rearrange them below to {t('customise')} your {t('matterNumber')}
      </div>
      <div className={Styles.assemblyContainer} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
        {widgets.map((widget) => (
          <DraggableWidget
            {...{
              widget,
              dragoverX,
              setDragInstanceId,
              key: widget.instanceId || widget.widgetId,
              setRelativePosition: (relPos) =>
                widget.instanceId && updatePlaceholderPosition && updatePlaceholderPosition(widget.instanceId, relPos),
              onClick: () => selectWidget && widget.instanceId && selectWidget(widget.instanceId),
              selected: !!(widget.instanceId && widget.instanceId === selectedWidgetId),
            }}
          />
        ))}
      </div>
    </div>
  </div>
);
