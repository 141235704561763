
/**
 * Enable debug via query string
 *
 * Example: http://localhost:9001/?debug#/billing/matters
 */

import { envType, getFrontendEnv } from '@sb-itops/environment-config';
import { parseQueryString } from '@sb-itops/region';
import { store } from '@sb-itops/redux';
import { actions as debugActions } from 'web/redux/route/billing-support-debug';
import { actions as appStateActions } from 'web/redux/features/application-state';
import triConveyFavicon from 'web/assets/triconvey-icon.svg';

const getOwningCompanyFromQuerystring = (qs) => {
  if (!qs.OWNER) {
    return 'Smokeball';
  }

  switch (qs.OWNER.toLowerCase()) {
    case 'triconvey':
      return 'TriConvey';
    
    case 'practiceevolve':
      return 'PracticeEvolve';

    default:
      return 'Smokeball';
  }
}

const processPreAuthQueryStrings = () => {
  const qs = parseQueryString(window.location.search);

  // Insecure because it's set via a querystring unlike the owningCompany claim within the auth
  // token which is cryptographically signed. There are cases where we need to make a best effort
  // at identifying the owning company of a user before they sign in. 
  const owningCompanyInsecure = getOwningCompanyFromQuerystring(qs);
  if (owningCompanyInsecure) {
    if (owningCompanyInsecure === 'TriConvey') {
      document.title = 'triConvey';

      const faviconLink = document.querySelector("link[rel~='icon']");
      if (faviconLink) {
        faviconLink.href = triConveyFavicon;
      }
    }

    store.dispatch(appStateActions.setOwningCompanyInsecure({ owningCompanyInsecure }));
  }

  if (getFrontendEnv() !== envType.PRODUCTION && Object.keys(qs).includes("DEBUG")) {
    store.dispatch(debugActions.toggleShowDebug({ }));
  }
};

export { processPreAuthQueryStrings };
