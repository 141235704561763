import { TOGGLE_SHOW_FILTERS, SET_FILTER, RESET_FILTER, TOGGLE_HIDE_FILTER } from './types';

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});
export const toggleHideFilters = (event) => ({
  type: TOGGLE_HIDE_FILTER,
  payload: { filterName: event.currentTarget.id },
});

export const setFilter = (filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});
export const onResetFilters = () => ({
  type: RESET_FILTER,
  payload: {},
});
export const onFilterByRecent = (filterByRecent) => ({
  type: SET_FILTER,
  payload: { filterName: 'recent', filterValue: filterByRecent },
});
export const onFilterInvoiceStatus = (selectedInvoiceStatuses) => ({
  type: SET_FILTER,
  payload: { filterName: 'invoiceStatuses', filterValue: selectedInvoiceStatuses },
});
export const onFilterSentStatus = (selectedSentStatuses) => ({
  type: SET_FILTER,
  payload: { filterName: 'sentStatuses', filterValue: selectedSentStatuses },
});
export const onFilterSendPreference = (selectedSendPreferences) => ({
  type: SET_FILTER,
  payload: { filterName: 'sendPreferences', filterValue: selectedSendPreferences },
});
export const onFilterBillingEvent = (selectedBillingEvent) => ({
  type: SET_FILTER,
  payload: { filterName: 'billingEvents', filterValue: selectedBillingEvent },
});
export const onFilterBillingType = (selections, allSelected) => ({
  type: SET_FILTER,
  payload: { filterName: 'billingTypes', filterValue: { selections, allSelected: !!allSelected } },
});
export const onFilterBillingFrequencySubType = (selectedBillingFrequencySubTypes) => ({
  type: SET_FILTER,
  payload: { filterName: 'billingFrequencySubTypes', filterValue: selectedBillingFrequencySubTypes || [] },
});
export const onFilterMatterTypes = (selectedMatterTypes) => ({
  type: SET_FILTER,
  payload: { filterName: 'matterTypes', filterValue: selectedMatterTypes || [] },
});
export const onFilterAttorneyResponsible = (attorneyIds) => ({
  type: SET_FILTER,
  payload: { filterName: 'attorneysResponsible', filterValue: attorneyIds },
});
export const onFilterTrustBalance = (balance) => ({
  type: SET_FILTER,
  payload: { filterName: 'minimumTrustBalance', filterValue: balance },
});
export const onFilterOperatingBalance = (balance) => ({
  type: SET_FILTER,
  payload: { filterName: 'minimumOperatingBalance', filterValue: balance },
});
export const onFilterMatterStatus = (selectedMatterStatuses) => ({
  type: SET_FILTER,
  payload: { filterName: 'matterStatuses', filterValue: selectedMatterStatuses },
});
export const onFilterUtbmsEnabled = (utbmsEnabled) => ({
  type: SET_FILTER,
  payload: { filterName: 'utbmsEnabled', filterValue: utbmsEnabled },
});
export const onChangeIssueDateFilter = (issueDate) => ({
  type: SET_FILTER,
  payload: { filterName: 'issueDate', filterValue: issueDate },
});
export const onChangeFinalizedOnFilter = (finalizedOn) => ({
  type: SET_FILTER,
  payload: { filterName: 'finalizedOn', filterValue: finalizedOn },
});
