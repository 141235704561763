angular.module('sb.billing.webapp').component('sbTrustChequePrintStatistic', {
  templateUrl: 'ng-components/trust-cheque-print-statistic/trust-cheque-print-statistic.html',
  bindings: { bankAccountId: '<?', statisticItem: '@', format: '@' },
  require: { button: '^sbAuiButton' },
  controller: function ($scope, sbTrustChequeService, sbLocalisationService, sbTransactionService) {
    const ctrl = this;

    ctrl.$onInit = () => {
      update(ctrl.bankAccountId);
      $scope.$on('smokeball-data-update-sbTrustChequeService', () => update(ctrl.bankAccountId));
      $scope.$on('smokeball-data-update-sbTransactionService', () => update(ctrl.bankAccountId));
    };

    function update(bankAccountId) {
      const filters = { 
        isPrinted: false,
        bankAccountId,
       };

      const unprintedCheques = sbTrustChequeService.getByFilter(filters)
        .map((cheque) => {
          const txId = cheque.payments[0].transactionId;

          return sbTransactionService.getById(txId);
        })
        .filter((txn) => txn && !txn.reversed);

      if (unprintedCheques && unprintedCheques.length > 0) {
        ctrl.display = `Print ${sbLocalisationService.t('cheques')} (${unprintedCheques.length})`;
        ctrl.button.disable(false);
      } else {
        ctrl.display = `Print ${sbLocalisationService.t('cheques')}`;
        ctrl.button.disable(true);
      }
    }
  }
});
