import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  CollapsibleHeader,
  Button,
  ToggleListFilter,
  CurrencyInput2,
  PanelExpander,
  useTranslation,
} from '@sb-itops/react';
import { BankAccountDropDown } from 'web/react-redux/components/bank-account-drop-down';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { TrustToOfficeModal } from '../../components/trust-to-office-modal';
import { TrustToOfficeTable } from '../../components/trust-to-office-table';
import { MatterTypeAccordian } from '../../components/matter-type-accordian';
import { StaffMultiSelect } from '../../components/staff-multi-select';
import { DateListFilter } from '../../components/date-list-filter';
import Styles from './BillingBillsTrustToOffice.module.scss';

const TTO_UNPAID_AD_MODAL_ID = 'tto-unpaid-ad-modal';

const supportsContingencyBillingType = hasFacet(facets.contingencyBillingType);
const MATTER_STATUS_FILTER_OPTIONS = [
  { id: 'Open', label: 'Open' },
  { id: 'Pending', label: 'Pending' },
  { id: 'Closed', label: 'Closed' },
];
const BILLING_TYPE_FILTER_OPTIONS = [
  { id: 'time', label: 'Time Based' },
  { id: 'fixed', label: 'Fixed' },
  ...(supportsContingencyBillingType ? [{ id: 'contingency', label: 'Contingency' }] : []),
];

const BillingBillsTrustToOffice = React.memo(
  ({
    rows,
    sort,
    sortBy,
    sortDirection,
    allInvoiceIds,
    allMatterIds,
    allExpanded,
    allSelected,
    hasError,
    paymentSummary,
    tableSummary,
    disablePayButton,
    matterTypes,
    filters,
    visibleFilterGroups,
    expanded,
    trustAccounts,
    // callbacks & functions
    onFilterTrustAccount,
    onFilterIssueDate,
    onFilterBillingType,
    onFilterMatterType,
    onFilterMatterStatus,
    onToggleFilterGroupVisibility,
    onStaffChange,
    onFilterTrustBalance,
    onResetFilters,
    onToggleAllMattersExpanded,
    onToggleMatterExpanded,
    onToggleInvoiceSelected,
    onToggleMatterSelected,
    onToggleSelectAll,
    onToggleAutoAllocate,
    onClickLink,
    onChangePaymentAmount,
    onOpenTrustChequesModal,
    onPayButtonClick,
    onPaymentsSubmitted,
    toggleShowFilters,
    selectedMatterTypes,
  }) => {
    const { t } = useTranslation();
    const onPayButtonClickHandler = () => {
      if (featureActive('BB-9573') && paymentSummary?.hasUnpaidAD) {
        setModalDialogVisible({ modalId: TTO_UNPAID_AD_MODAL_ID });
        return;
      }
      onPayButtonClick();
    };
    const onADWarningConfirm = () => {
      setModalDialogHidden({ modalId: TTO_UNPAID_AD_MODAL_ID });
      onPayButtonClick();
    };

    return (
      <div className={classnames('master-detail-panel', Styles.trustToOfficePanel)}>
        <div className="firm-invoices-side-panel">
          <PanelExpander
            className="panel-filter"
            onResetFilters={onResetFilters}
            toggleExpanded={toggleShowFilters}
            expanded={expanded}
          >
            <div className={Styles.trustAccountSection}>
              <div className={Styles.trustAccountTitle}>
                <h3>{t('trustAccount')}</h3>
              </div>
              <div className={Styles.trustAccountSelection}>
                <BankAccountDropDown
                  disabled={!trustAccounts.length}
                  placeholder={`No open ${t('trustAccounts').toLowerCase()}`}
                  onValueChange={(bankAccount) => {
                    onFilterTrustAccount(bankAccount);
                  }}
                  options={trustAccounts}
                  selectedOptionValue={filters.bankAccountId}
                />
              </div>
            </div>
            <CollapsibleHeader
              text="Issue Date"
              onClick={() => {
                onToggleFilterGroupVisibility('issueDate', !visibleFilterGroups.issueDate);
              }}
              name="issueDate"
              collapsed={!visibleFilterGroups.issueDate}
            >
              <DateListFilter
                onChange={onFilterIssueDate}
                selectedFilter={filters.issueDate.filterType}
                before={filters.issueDate.before}
                from={filters.issueDate.from}
                to={filters.issueDate.to}
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Billing Type"
              onClick={() => {
                onToggleFilterGroupVisibility('billingTypes', !visibleFilterGroups.billingTypes);
              }}
              name="billingType"
              collapsed={!visibleFilterGroups.billingTypes}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={BILLING_TYPE_FILTER_OPTIONS}
                selected={filters.billingTypes.selections}
                onSelect={onFilterBillingType}
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Matter Type"
              onClick={() => {
                onToggleFilterGroupVisibility('matterTypes', !visibleFilterGroups.matterTypes);
              }}
              name="matterType"
              collapsed={!visibleFilterGroups.matterTypes}
            >
              <MatterTypeAccordian
                matterTypes={matterTypes}
                onSelect={onFilterMatterType}
                selectedList={selectedMatterTypes}
                onlyUsed
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Matter Status"
              onClick={() => {
                onToggleFilterGroupVisibility('matterStatuses', !visibleFilterGroups.matterStatuses);
              }}
              name="matterStatus"
              collapsed={!visibleFilterGroups.matterStatuses}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={MATTER_STATUS_FILTER_OPTIONS}
                selected={filters.matterStatuses}
                onSelect={onFilterMatterStatus}
              />
            </CollapsibleHeader>
            <div className="attorney-responsible-container">
              <CollapsibleHeader
                text={t('capitalizeAllWords', { val: 'personResponsible' })}
                onClick={() => {
                  onToggleFilterGroupVisibility('attorneysResponsible', !visibleFilterGroups.attorneysResponsible);
                }}
                name="attorneyResponsible"
                collapsed={!visibleFilterGroups.attorneysResponsible}
              >
                <StaffMultiSelect
                  className=""
                  selectedStaffIds={filters.attorneysResponsible || []}
                  onStaffChange={onStaffChange}
                  showUsedStaffOnly
                  showNoneOption
                />
              </CollapsibleHeader>
            </div>
            <CollapsibleHeader
              text="Matter Balances"
              onClick={() => {
                onToggleFilterGroupVisibility('matterBalances', !visibleFilterGroups.matterBalances);
              }}
              name="matterBalances"
              collapsed={!visibleFilterGroups.matterBalances}
            >
              <div className={Styles.debtorBalances}>
                <div className="balance-type">
                  <span>At least {t('currencySymbol')}</span>
                  <CurrencyInput2
                    className={Styles.balanceValue}
                    value={filters.minimumTrustBalance}
                    onChange={onFilterTrustBalance}
                    hideDollar
                  />
                  <span>in {t('trust')}</span>
                </div>
              </div>
            </CollapsibleHeader>
          </PanelExpander>
        </div>
        <div className="panel-body">
          {filters?.bankAccountId && (
            <TrustToOfficeModal
              bankAccountId={filters.bankAccountId}
              paymentSummary={paymentSummary}
              onPaymentsSubmitted={onPaymentsSubmitted}
              onOpenTrustChequesModal={onOpenTrustChequesModal}
              onClickLink={onClickLink}
            />
          )}
          <div className={classnames('ribbon', 'panel', 'panel-primary', Styles.ribbonAvoidConflict)}>
            <Button onClick={onPayButtonClickHandler} disabled={disablePayButton}>
              {' '}
              Pay ({paymentSummary.invoiceCount})
            </Button>
          </div>

          {featureActive('BB-9573') && (
            <ConfirmationModalDialog
              onConfirm={onADWarningConfirm}
              modalId={TTO_UNPAID_AD_MODAL_ID}
              title={`Unpaid Anticipated ${t('capitalizeAllWords', { val: 'expense' })}`}
              body={`One or more invoices contains an anticipated ${t(
                'expense',
              )} that has not yet been paid to the supplier. Are you sure you want to proceed?`}
              primaryButtonText="Pay"
            />
          )}

          <TrustToOfficeTable
            rows={rows}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            allInvoiceIds={allInvoiceIds}
            allMatterIds={allMatterIds}
            allExpanded={allExpanded}
            allSelected={allSelected}
            hasError={hasError}
            tableSummary={tableSummary}
            onToggleAllMattersExpanded={onToggleAllMattersExpanded}
            onToggleMatterExpanded={onToggleMatterExpanded}
            onToggleInvoiceSelected={onToggleInvoiceSelected}
            onToggleMatterSelected={onToggleMatterSelected}
            onToggleSelectAll={onToggleSelectAll}
            onToggleAutoAllocate={onToggleAutoAllocate}
            onClickLink={onClickLink}
            onChangePaymentAmount={onChangePaymentAmount}
          />
        </div>
      </div>
    );
  },
);

BillingBillsTrustToOffice.displayName = 'BillingBillsTrustToOffice';

BillingBillsTrustToOffice.propTypes = {
  rows: PropTypes.array.isRequired,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  allMatterIds: PropTypes.array.isRequired,
  allInvoiceIds: PropTypes.array.isRequired,
  allExpanded: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  paymentSummary: PropTypes.object.isRequired,
  tableSummary: PropTypes.object.isRequired,
  disablePayButton: PropTypes.bool.isRequired,
  matterTypes: PropTypes.array,
  filters: PropTypes.object,
  visibleFilterGroups: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  trustAccounts: PropTypes.array.isRequired,
  // callbacks & functions
  onFilterTrustAccount: PropTypes.func.isRequired,
  onFilterIssueDate: PropTypes.func.isRequired,
  onFilterBillingType: PropTypes.func.isRequired,
  onFilterMatterType: PropTypes.func.isRequired,
  onFilterMatterStatus: PropTypes.func.isRequired,
  onToggleFilterGroupVisibility: PropTypes.func.isRequired,
  onStaffChange: PropTypes.func.isRequired,
  onFilterTrustBalance: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onToggleAllMattersExpanded: PropTypes.func.isRequired,
  onToggleMatterExpanded: PropTypes.func.isRequired,
  onToggleInvoiceSelected: PropTypes.func.isRequired,
  onToggleMatterSelected: PropTypes.func.isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  onToggleAutoAllocate: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangePaymentAmount: PropTypes.func.isRequired,
  onPayButtonClick: PropTypes.func.isRequired,
  onPaymentsSubmitted: PropTypes.func.isRequired,
  onOpenTrustChequesModal: PropTypes.func.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  selectedMatterTypes: PropTypes.array,
};

BillingBillsTrustToOffice.defaultProps = {
  matterTypes: [],
  filters: {},
  visibleFilterGroups: {},
  selectedMatterTypes: [],
};

export default BillingBillsTrustToOffice;
