import React from 'react';
import PropTypes from 'prop-types';
import Styles from './TilesArea.module.scss';
import Tile from './Tile';

// for now we do not need it but in the future we can include areas and assign them to the tiles.
const TilesArea = ({ children }) => {
  if (
    (children.type && children.type.displayName !== Tile.displayName) ||
    (children.length > 0 && children.some((child) => child.type.displayName !== Tile.displayName))
  ) {
    throw new Error('TilesArea must only contain Tiles components');
  }

  return <div className={Styles.tilesContainer}>{children}</div>;
};

TilesArea.displayName = 'TilesArea';

TilesArea.propTypes = {
  children: PropTypes.node.isRequired,
};

TilesArea.defaultProps = {};

export default TilesArea;
