import React from 'react';

import Styles from '../ConfigureMatterNumbering.module.scss';

type DraggableWidgetProps = {
  label: string;
  disablePointerEvents?: boolean;
  onDragStart?: React.DragEventHandler;
  className?: string;
  onClick?: () => void;
  selected?: boolean;
};

export const DraggableWidget = React.forwardRef<HTMLDivElement, DraggableWidgetProps>(
  ({ label, disablePointerEvents, onDragStart, className, onClick, selected }, ref) => (
    <div
      ref={ref}
      className={`${className || Styles.label} ${selected ? Styles.selected : ''}`}
      onClick={onClick}
      draggable={!!onDragStart}
      style={{
        pointerEvents: disablePointerEvents ? 'none' : 'inherit',
      }}
      onDragStart={onDragStart}
    >
      <i className="icon icon-grab-handle" />
      <p
        style={{
          pointerEvents: disablePointerEvents ? 'none' : 'inherit',
        }}
      >
        {label}
      </p>
    </div>
  ),
);
