import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getById } from '@sb-billing/redux/bank-account';
import BankAccountNumberDisplay from './BankAccountNumberDisplay';

const mapStateToProps = (state, { bankAccountId }) => {
  const decodedBankAccountId = bankAccountId;
  const bankAccountDetails = getById(decodedBankAccountId);

  return {
    bsb: bankAccountDetails?.branchNumber || '',
    accountNumber: bankAccountDetails?.accountNumber || '',
  };
};

const BankAccountNumberDisplayContainer = withReduxStore(connect(mapStateToProps)(BankAccountNumberDisplay));

BankAccountNumberDisplayContainer.displayName = 'BankAccountNumberDisplay';

BankAccountNumberDisplayContainer.propTypes = {
  bankAccountId: PropTypes.string,
};

BankAccountNumberDisplayContainer.defaultProps = {
  bankAccountId: '',
};

export default BankAccountNumberDisplayContainer;
