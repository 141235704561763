import { gql } from '@apollo/client';

const query = gql`
  query InvoiceCorrespondenceHistory($invoiceId: ID!) {
    correspondenceHistoryInvoiceLatest(invoiceId: $invoiceId) {
      id
      operationType
      relatedItems {
        id
        type
        contactEntity {
          id
          displayNameFirstLast
        }
      }
      from
      to
      cc
      bcc
      subject
      # body
      # internalNote
      user {
        id
        person {
          id
          name
        }
      }
      sentTimestamp
      lastUpdated
      status
      errorMessage
      # exceptionDetails
      sentVia

      # matter {
      #   id
      #   matterNumber
      # }
      # relatedIds
      contacts {
        id
        displayNameFirstLast
      }
    }
  }
`;

const notificationIds = [
  'BillingSharedNotifications.CorrespondenceHistoryUpdated', // Replace this with the WebQuery equivalent when available
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceCorrespondenceHistory = {
  query,
  notificationIds,
};
