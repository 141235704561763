import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as multiItemSelect from './index';

export const useMultipleItemSelection = ({ scope }) => {
  const dispatch = useDispatch();
  const { selectors, actions } = useScopedFeature(multiItemSelect, scope);

  const isAllItemsSelected = useSelector(selectors.getAllItemsSelected);
  const selectedItems = useSelector(selectors.getSelectedItems);
  const selectAllItems = () => dispatch(actions.selectAllItems());
  const deselectAllItems = () => dispatch(actions.deselectAllItems());
  const toggleItem = (itemToToggle) => {
    dispatch(actions.toggleItems({ items: [itemToToggle] }));
  };
  const toggleItems = (itemsToToggle) => {
    dispatch(actions.toggleItems({ items: itemsToToggle }));
  };
  const selectItems = (itemsToSelect) => {
    dispatch(actions.deselectAllItems());
    dispatch(actions.selectItems({ items: itemsToSelect }));
  };

  return {
    isAllItemsSelected, // when all items are selected, selecteItems may not have all the items, e.g. in paginated UI
    selectedItems,
    toggleItem,
    toggleItems,
    selectItems,
    selectAllItems,
    deselectAllItems,
  };
};
