'use strict';

const { createPayNowButton, createPayNowLink, createViewInvoiceButton } = require('../invoice-emailing');

const interpolateInvoiceReminderEmail = (
  {
    matterDisplay,
    contactSalutation,
    phoneNumber,
    firmName,
    totalUnpaidPastDue,
    matterTotalsUnpaid,
    user,
    eInvoiceUrl,
    invoiceCount,
    paymentUrl,
    showPayNowButton,
    isPreviewMode,
    t,
  },
  str,
) => {
  let interpolatedText = str
    .replace(/\[MATTER\]|\[MATTER_NAME\]/g, matterDisplay)
    .replace(/\[DEBTOR\]|\[DEBTOR_NAME\]/g, contactSalutation)
    .replace(/\[OVERDUE_AMOUNT\]/g, t('cents', { val: totalUnpaidPastDue }))
    .replace(/\[MATTER_AMOUNT_DUE\]/g, t('cents', { val: matterTotalsUnpaid }))
    .replace(/\[USER\]|\[USER_NAME\]/g, user.name)
    .replace(/\[PHONE_NUMBER\]/g, phoneNumber || '')
    .replace(/\[FIRM_NAME\]/g, firmName || '');

  if (!isPreviewMode) {
    if (showPayNowButton && paymentUrl) {
      interpolatedText = interpolatedText.replace(/\[PAY_NOW_BUTTON\]/g, createPayNowButton({ url: paymentUrl }));
      interpolatedText = interpolatedText.replace(/\[PAY_NOW_LINK\]/g, createPayNowLink({ url: paymentUrl }));
    }

    if (eInvoiceUrl) {
      interpolatedText = interpolatedText.replace(
        /\[VIEW_INVOICE_BUTTON\]/g,
        createViewInvoiceButton({ url: eInvoiceUrl, invoiceCount }),
      );
    }
  }

  // If no payment provider connected or no eInvoiceUrl provided, remove PAY_NOW_BUTTON, PAY_NOW_LINK, and VIEW_INVOICE_BUTTON placeholders
  interpolatedText = interpolatedText
    .replace(/\[PAY_NOW_BUTTON\]/g, '')
    .replace(/\[PAY_NOW_LINK\]/g, '')
    .replace(/\[VIEW_INVOICE_BUTTON\]/g, '');

  return interpolatedText;
};

module.exports = {
  interpolateInvoiceReminderEmail,
};
