import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReportConfigSelector, ReportRunnerPeriscope, ReportRunnerSSRS } from '@sb-billing/react';
import { ReportRunnerSmokeballBilling } from 'web/react-redux/components/report-runner-smokeball-billing';
import { isNewTheme } from 'web/services/theme';
import Styles from './BillingReporting.module.scss';

export const BillingReporting = React.memo((props) => {
  const { reportConfigs, selectedReportConfig, onSelectedReportConfigChange, showFilters, toggleShowFilters } = props;

  if (!selectedReportConfig) {
    return null;
  }

  return (
    <div className={Styles.reportPanel}>
      <div className="page-top-bar">
        <div className="page-name">
          {!isNewTheme() && <div className="page-name-icon icon-business-chart-1" />}
          <h1 className="page-name-heading">Reports</h1>
        </div>
        <div className="sb-tab-header">
          <ul className="nav nav-pills">
            <li className="active">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>Reports</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={classnames('master-detail-panel', Styles.panel)}>
        <ReportConfigSelector
          selectedId={selectedReportConfig.reportId}
          reportConfigs={reportConfigs}
          showFilters={showFilters}
          toggleShowFilters={toggleShowFilters}
          onSelectionChange={onSelectedReportConfigChange}
        />
        <div className="panel-body">{getReportRunner(props)}</div>
      </div>
    </div>
  );
});

const getReportRunner = ({
  selectedReportConfig,
  reportState,
  authToken,
  sbReportingService,
  setReportState,
  sbAsyncOperationsService,
}) => {
  // Convenience function so that report runners don't need to pass back their own report id
  // when saving their report state.
  const handleReportStateChange = (changedState) =>
    setReportState({ reportId: selectedReportConfig.reportId, changedState });

  switch (selectedReportConfig.provider) {
    case 'SmokeballBilling':
      return (
        <ReportRunnerSmokeballBilling
          reportConfig={selectedReportConfig}
          reportState={reportState}
          sbReportingService={sbReportingService}
          sbAsyncOperationsService={sbAsyncOperationsService}
          setReportState={handleReportStateChange}
        />
      );
    case 'Periscope':
      return (
        <ReportRunnerPeriscope
          reportConfig={selectedReportConfig}
          reportState={reportState}
          setReportState={handleReportStateChange}
        />
      );
    case 'SSRS':
      return (
        <ReportRunnerSSRS
          reportConfig={selectedReportConfig}
          reportState={reportState}
          authToken={authToken}
          setReportState={handleReportStateChange}
        />
      );
    default:
      return <div>Loading...</div>;
  }
};

BillingReporting.displayName = 'BillingReporting';

BillingReporting.propTypes = {
  selectedReportConfig: PropTypes.object,
  sbReportingService: PropTypes.object.isRequired,
  reportConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportState: PropTypes.object,
  onSelectedReportConfigChange: PropTypes.func.isRequired,
  setReportState: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
};

BillingReporting.defaultProps = {
  reportState: undefined,
  selectedReportConfig: undefined,
};

export default BillingReporting;
