import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { today, dateToInteger } from '@sb-itops/date';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { withReduxStore } from '@sb-itops/react';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withOnLoad } from '@sb-itops/react/hoc';
import { yupSchemaValidator } from '@sb-itops/business-logic/validation/services';
import { getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { getById as getInvoiceTotalsById } from '@sb-billing/redux/invoice-totals';
import {
  calculateFeeDetails,
  extractFeeSchedule,
  getMinChargeAmountInCents,
} from '@sb-billing/business-logic/payment-provider/services';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import { CreditCardPaymentForm } from '../../../components';
import { creditCardPaymentFormSchema } from './credit-card-payment-form-schema';

const scope = 'credit-card-payment-form';
const region = getRegion();

const mapStateToProps = (
  state,
  { providerType, bankAccountId, isSubmitting, invoiceId, clientIsCoveringFee, providerSpecificChargeData },
) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formDirty, formValid } = formSelectors.getFormState(state);

  if (!formInitialised) {
    return { isLoading: true };
  }

  const contactOptions = getContactTypeAheadSummaries();
  const { payorId, paymentDate, paymentAmount, reason } = formFields;

  const invoiceTotals = invoiceId && getInvoiceTotalsById(invoiceId);
  const unpaid = (invoiceTotals && invoiceTotals.unpaid) || 0;
  const balanceDueAfterPayment = unpaid - ((paymentAmount && paymentAmount.value) || 0);

  const formattedPaymentProviderSettings = getProviderSettings(providerType);

  const feeSchedule = clientIsCoveringFee
    ? extractFeeSchedule({
        providerType,
        formattedProviderSpecificSettings: formattedPaymentProviderSettings,
        bankAccountId,
      })
    : undefined;

  const feeDetails = clientIsCoveringFee
    ? calculateFeeDetails({
        providerType,
        feeSchedule,
        desiredAmountInCents: paymentAmount.value || 0,
        providerSpecificFields: providerSpecificChargeData,
      })
    : {};

  const minAmountAllowed = getMinChargeAmountInCents({ providerType, region });

  return {
    providerType,
    contactOptions,
    minAmountAllowed,
    payorId,
    paymentDate,
    paymentAmount,
    reason,
    balanceDueAfterPayment,
    clientIsCoveringFee,
    formattedPaymentProviderSettings,
    feeDetails,
    formInitialised,
    isSubmitting,
    formDirty,
    formValid,
    showReasonField: hasFacet(facets.reasonField),
  };
};

const mapDispatchToProps = (
  dispatch,
  { bankAccountId, defaultPayorId, invoiceId, providerSpecificChargeData, onChange },
) => {
  const {
    actions: formActions,
    operations: formOperations,
    selectors: formSelectors,
  } = withScopedFeature({ scope })(forms);

  const validateFn = (formFields) => {
    const schemaErrors = yupSchemaValidator(formFields, creditCardPaymentFormSchema);

    // Forms 2 expects errors to be reported as flattened dot object notation.
    const formErrors = Object.keys(schemaErrors).length ? nestedObjectToFlattened(schemaErrors) : {};

    // add any custom validation here that's not easy or convoluted to express using Yup
    const invoiceTotals = invoiceId && getInvoiceTotalsById(invoiceId);
    const unpaidAmount = (invoiceTotals && invoiceTotals.unpaid) || 0;
    if (formFields.paymentAmount > unpaidAmount) {
      formErrors.paymentAmount = 'Payment amount is greater than amount due on invoice';
    }

    return formErrors;
  };

  return {
    onLoad: () => {
      const invoiceTotals = invoiceId && getInvoiceTotalsById(invoiceId);
      const unpaidAmount = (invoiceTotals && invoiceTotals.unpaid) || 0;
      const fieldValues = {
        chargeId: uuid(),
        payorId: defaultPayorId,
        paymentDate: dateToInteger(today()),
        paymentAmount: unpaidAmount,
        reason: '',
      };

      dispatch(formActions.initialiseForm({ fieldValues }));
      dispatch(formOperations.validateForm({ validateFn }));
      onChange(fieldValues);

      const onUnload = () => {
        // clear form on unmount to conform with pattern used else where
        dispatch(formActions.clearForm());
      };
      return onUnload;
    },

    onFormFieldChange: ({ key, value, providerType, formattedPaymentProviderSettings, clientIsCoveringFee }) => {
      if (key) {
        dispatch(formActions.updateFieldValues({ fieldValues: { [key]: value } }));
        dispatch(formOperations.validateForm({ validateFn }));
      }

      const { fieldValues } = formSelectors.getFormState(store.getState(), { fieldsAsValues: true });
      const desiredAmountInCents = fieldValues.paymentAmount || 0;

      const feeSchedule = clientIsCoveringFee
        ? extractFeeSchedule({
            providerType,
            formattedProviderSpecificSettings: formattedPaymentProviderSettings,
            bankAccountId,
          })
        : undefined;

      const feeDetails =
        clientIsCoveringFee &&
        calculateFeeDetails({
          providerType,
          feeSchedule,
          desiredAmountInCents,
          providerSpecificFields: providerSpecificChargeData,
        });

      const chargeAmountInCents = clientIsCoveringFee ? feeDetails.effectiveAmountInCents : desiredAmountInCents;
      onChange({ ...fieldValues, paymentAmount: chargeAmountInCents, amountLessFees: desiredAmountInCents });
    },
  };
};

export const CreditCardPaymentFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ formValid, onReadyForSubmit, ...props }) => {
      useEffect(() => {
        onReadyForSubmit(formValid);
      }, [formValid, onReadyForSubmit]);

      useEffect(() => {
        if (props.formattedPaymentProviderSettings) {
          handleFieldChange({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.feeDetails?.effectiveAmountInCents, props.formattedPaymentProviderSettings]);

      const handleFieldChange = ({ key, value }) =>
        props.onFormFieldChange({
          key,
          value,
          formattedPaymentProviderSettings: props.formattedPaymentProviderSettings,
          clientIsCoveringFee: props.clientIsCoveringFee,
          providerType: props.providerType,
        });

      return <CreditCardPaymentForm {...props} onFormFieldChange={handleFieldChange} />;
    }),
  ),
);

CreditCardPaymentFormContainer.displayName = 'CreditCardPaymentFormContainer';

CreditCardPaymentFormContainer.propTypes = {
  providerType: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  invoiceId: PropTypes.string,
  clientIsCoveringFee: PropTypes.bool.isRequired,
  defaultPayorId: PropTypes.string,
};

CreditCardPaymentFormContainer.defaultProps = {
  invoiceId: undefined,
  defaultPayorId: undefined,
};
