import { featureActive } from '@sb-itops/feature';
import { error as displayErrorToUser, builder as buildMessageDisplay } from '@sb-itops/message-display';
import { t } from '@sb-itops/localisation-web';

import { Maybe } from '@sb-itops/type-helpers';
import { fetchMatterPermissionsFromWebP } from './fetch-matter-permissions-from-web';

/**
 * Checks matter permissions and if it fails will additionally raise an error
 * popup via @sb-itops/message-display
 *
 * This is safe be called with an undefined/null/empty string as matterId, and
 * it will skip the check and return true. Only a truthy string for matterId
 * will be checked.
 *
 * @param {(string | null | undefined)} [matterId]
 * @param {{ title?: string, text?: string, group?: string, }} [message]
 * @param {{ text: string, onClick: () => void, }} [action]
 *
 * @return { boolean }
 */
export const checkMatterPermissionsAndDisplayErrorPopupP = async (
  matterId?: Maybe<string>,
  message?: { title?: string; text?: string; group?: string },
  action?: { onClick: Function; text: string },
) => {
  if (!featureActive('NUCWEB-118')) {
    return true; // if feature disabled do not limit permission
  }

  if (typeof matterId !== 'string' || matterId === '') {
    return true; // if not concerned with a matter we allow it
  }

  const perms = await fetchMatterPermissionsFromWebP([matterId]);
  const isAuthorized = perms[matterId];

  if (isAuthorized) {
    return true;
  }

  const builtMessage = buildMessageDisplay()
    .title((message && message.title) || 'Insufficient Permissions')
    .text(
      (message && message.text) ||
        `Warning: You do not have access to this matter. Please contact the ${t('capitalize', {
          val: 'personResponsible',
        })} or Firm owner to gain access.`,
    )
    .group((message && message.group) || 'matter-permissions')
    .timeout(30 * 1000);

  if (action && action.onClick && action.text) {
    builtMessage.action(action.onClick).actionText(action.text);
  }

  displayErrorToUser(builtMessage);

  return false;
};
