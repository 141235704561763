import React from 'react';
import PropTypes from 'prop-types';
import { UpdatePaymentMethodComplete } from 'web/components/update-payment-method-complete';
import Styles from './UpdatePaymentMethodCompleteRoute.module.scss';

export const UpdatePaymentMethodCompleteRoute = React.memo((props) => {
  const { subscription, onAcknowledgePaymentMethodUpdated } = props;

  return (
    <div className={Styles.updatePaymentMethodCompleteRoute}>
      <div className={Styles.sideBox} />
      <div className={Styles.centreBox}>
        <div className={Styles.displayBox}>
          <UpdatePaymentMethodComplete
            subscriptionStatus={subscription?.status}
            onAcknowledgePaymentMethodUpdated={onAcknowledgePaymentMethodUpdated}
            acknowledgeButtonLabel="Continue"
          />
        </div>
      </div>
      <div className={Styles.sideBox} />
    </div>
  );
});

UpdatePaymentMethodCompleteRoute.displayName = 'UpdatePaymentMethodCompleteRoute';

UpdatePaymentMethodCompleteRoute.propTypes = {
  subscription: PropTypes.object,
  onAcknowledgePaymentMethodUpdated: PropTypes.func.isRequired,
};

UpdatePaymentMethodCompleteRoute.defaultProps = {
  subscription: undefined,
};
