import { getTrustAccount } from '@sb-billing/redux/bank-account';

// Filter visible reports based on pre-defined tags in the Report Admin tool
export const filterReportConfigs = (reportConfigs) => {
  const filteredReportConfigs = reportConfigs.reduce((acc, reportConfig) => {
    if (!reportConfig.billingFilters || !reportConfig.billingFilters.length) {
      acc.push(reportConfig);
      return acc;
    }

    if (reportConfig.billingFilters.every(customFilter)) {
      acc.push(reportConfig);
    }

    return acc;
  }, []);

  return filteredReportConfigs;
};

// The filter name is derived from the tag set in the Report Admin tool
// For example, `BillingFilter:TrustAccountEnabled` will resolve to `TrustAccountEnabled`
const customFilter = (filter) => {
  switch (filter) {
    case 'TrustAccountEnabled': {
      const trustAccount = getTrustAccount();
      return trustAccount && trustAccount.isInactive === false;
    }

    default:
      return true;
  }
};
