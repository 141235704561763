const { allocators } = require('@sb-billing/allocation');
const { createAccumulator, extractors } = require('@sb-itops/data-accumulation');

const { targetsFromSources: allocateTargetsFromSources } = allocators;

module.exports = {
  allocateMatterPaymentsForInvoices,
};

function allocateMatterPaymentsForInvoices({ invoiceIds, matterBalance, invoiceAmounts, preferredBankAccounts }) {
  const accumulator = createAccumulator({
    $invoiceId: {
      $bankAccountId: extractors.extractTotal({ property: 'amount' }),
    },
  });

  if (Object.keys(matterBalance).length <= 0) {
    return undefined;
  }

  let allocations = {};

  allocateTargetsFromSources({
    targets: invoiceIds.map((invoiceId) => ({
      targetId: invoiceId,
      amount: invoiceAmounts[invoiceId] || 0,
    })),
    sources: preferredBankAccounts.map((preferredBankAccount) => ({
      sourceId: preferredBankAccount.id,
      bankAccountType: preferredBankAccount.accountType,
      amount: matterBalance[preferredBankAccount.id] || 0,
    })),
    zeroAllocate: true,
    accumulate: (allocation) => {
      allocations = accumulator(allocations, allocation);
    },
    transform: (allocation) => ({
      ...allocation,
      bankAccountId: allocation.sourceId,
      invoiceId: allocation.targetId,
    }),
  });

  return allocations;
}
