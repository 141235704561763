import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { useTranslation } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { store } from '@sb-itops/redux';
import {
  getMatterBalance,
  getMatterContactBalances,
  getMatterContactBalance,
  getBankAccountMap,
} from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import {
  balanceTypes,
  validAccountTypeForBalanceType,
} from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getMatterForBankAccountTypeaheadSummaries, getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { ProtectBalanceAddForm } from './ProtectBalanceAddForm';

const ProtectBalanceAddFormContainer = (props) => {
  const { scope, matterId: defaultMatterId, bankAccountId, validateForm, matterReadOnly } = props;
  const { selectors: formSelectors, actions: formActions } = useScopedFeature(forms, scope);
  const { formInitialised, fields: formFields, submitFailed, formSubmitting } = useSelector(formSelectors.getFormState);
  const { matterId, contactId, reasonRows, fundsAvailable, totalProtected } = formFields;

  const { t } = useTranslation();

  const supportsMatterBalance = hasFacet(facets.matterBalance);
  const supportsMatterContactBalance = hasFacet(facets.matterContactBalance);
  const bankAccountType = getBankAccountById(bankAccountId).accountType;

  if (!(bankAccountType.toUpperCase() in validAccountTypeForBalanceType)) {
    throw new Error(`Protected balances not available on ${bankAccountType} accounts`);
  }

  const matterContacts = matterId?.value
    ? getMatterContactBalances(getBankAccountMap(), {
        matterId: matterId?.value,
        bankAccountId,
      })
    : [];

  const contactOptions = matterContacts.reduce((acc, item) => {
    if (item[balanceTypes.AVAILABLE] > 0) {
      acc.push({
        label: `${getContactDisplay(item.contactId)} (${t('cents', { val: item[balanceTypes.AVAILABLE] })} available)`,
        value: item.contactId,
      });
    }

    return acc;
  }, []);

  const dispatch = useDispatch();

  const onFieldValueUpdated = (fieldValues) => {
    dispatch(formActions.updateFieldValues({ fieldValues }));
  };

  const onReasonRowUpdated = (fieldValues) => {
    onFieldValueUpdated(fieldValues);
    // get latest as form has changed on previous line
    const { fields: formFieldsLatest } = formSelectors.getFormState(store.getState());
    const { reasonRows: reasonRowsLatest } = formFieldsLatest;

    const reasonRowKeys = Object.keys(reasonRowsLatest || {});
    const lastReasonRow = reasonRowKeys.length && reasonRowsLatest[reasonRowKeys[reasonRowKeys.length - 1]];
    // calculate total protected amount
    const totalAmount = reasonRowKeys.reduce((acc, key) => acc + (reasonRowsLatest[key].amount?.value || 0), 0);
    onFieldValueUpdated({ totalProtected: totalAmount });
    // automatically add new reason row when both reason and amount filled in last row
    if (lastReasonRow?.reason?.value && lastReasonRow?.amount?.value) {
      onFieldValueUpdated({
        reasonRows: {
          [`reason${reasonRowKeys.length}`]: { reason: undefined, amount: undefined },
        },
      });
    }
  };

  const refreshFundsAvailable = ({ matterId: matterIdSelected, contactId: contactIdSelected }) => {
    let availableBalance = 0;

    if (
      supportsMatterBalance &&
      matterIdSelected &&
      bankAccountType &&
      bankAccountType.toUpperCase() in validAccountTypeForBalanceType
    ) {
      availableBalance = getMatterBalance(getBankAccountMap(), {
        matterId: matterIdSelected,
        bankAccountId,
        balanceType: balanceTypes.AVAILABLE,
      });
    } else if (
      supportsMatterContactBalance &&
      matterIdSelected &&
      contactIdSelected &&
      bankAccountType &&
      bankAccountType.toUpperCase() in validAccountTypeForBalanceType
    ) {
      availableBalance = getMatterContactBalance(getBankAccountMap(), {
        matterId: matterIdSelected,
        contactId: contactIdSelected,
        bankAccountId,
        balanceType: balanceTypes.AVAILABLE,
      });
    }

    onFieldValueUpdated({ fundsAvailable: availableBalance });
  };

  useEffect(() => {
    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          matterId: defaultMatterId,
          contactId: undefined,
          reasonRows: { reason0: { reason: undefined, amount: undefined } },
          fundsAvailable: 0,
          totalProtected: 0,
        },
      }),
    );
    refreshFundsAvailable({ matterId: defaultMatterId });

    const onUnload = () => dispatch(formActions.clearForm());
    return onUnload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formInitialised && hasFacet(facets.matterContactBalance) && !contactId?.value && contactOptions.length === 1) {
    // preselect first contact if it is the only one
    onFieldValueUpdated({ contactId: contactOptions[0].value });
    refreshFundsAvailable({ matterId: matterId?.value, contactId: contactOptions[0].value });
    validateForm();
  }

  // When matter field is read-only, we really just wanna display matter details by matterId
  // It is faster to get all matters summaries and it is ok as we can't change matter anyway
  const matterOptions = matterReadOnly
    ? getMatterTypeaheadSummaries()
    : getMatterForBankAccountTypeaheadSummaries(bankAccountId);

  return (
    <ProtectBalanceAddForm
      {...props}
      {...{
        contactOptions,
        matterOptions,
        // fields
        matterId,
        contactId,
        reasonRows,
        fundsAvailable,
        totalProtected,
        // form
        onFieldValueUpdated,
        onReasonRowUpdated,
        refreshFundsAvailable,
        formInitialised,
        formDisabled: formSubmitting,
        submitFailed,
        // config
        supportsMatterBalance,
        supportsMatterContactBalance,
      }}
    />
  );
};

ProtectBalanceAddFormContainer.displayName = 'ProtectBalanceAddFormContainer';

ProtectBalanceAddFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  matterReadOnly: PropTypes.bool.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  validateForm: PropTypes.func.isRequired,
};
ProtectBalanceAddFormContainer.defaultProps = {
  matterId: undefined,
};

export default withReduxProvider(ProtectBalanceAddFormContainer);
