import {
  getList,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/staff-hourly-rate';

angular.module('@sb-billing/services').service('sbStaffHourlyRateService', function(sbGenericCacheService, sbLoggerService, sbEndpointType) {
  'use strict';

  var that = this,
    log = sbLoggerService.getLogger('sbStaffHourlyRateService');

  sbGenericCacheService.setupCache({
    name: 'sbStaffHourlyRateService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/staff-hourly-rate' },
      poll: 60,
      subscriptions: 'notifier.FeesNotifications.StaffMemberHourlyRateUpdated'
    },
    updateRedux,
    clearRedux,
  });

  //log.setLogLevel('info');

  that.getByStaffId = getByStaffId;
  that.getAll = getAll;

  function getByStaffId(staffId) {
    var rate = getById(staffId);
    log.info('get by staff id %s, rate', staffId, rate);
    return rate;
  }

  function getAll() {
    var rates = getList();
    log.info('get all', rates);
    return rates;
  }
});
