import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './RadioButtons.module.scss';

export const radioButtonSizes = {
  small: 'small',
  default: 'default-size',
  'full-width': Styles.fullWidth,
};

export const radioButtonStyles = {
  default: 'btn-default',
  primary: 'btn-primary',
};

const RadioButtons = (props) => {
  const { locked, disabled, className, size, buttonList, onRadioChange } = props;
  const activeId = buttonList.map((button) => button.active).indexOf(true);
  const [activeIdx, setActiveIdx] = useState(activeId);

  useEffect(() => {
    setActiveIdx(activeId);
  }, [activeId]);

  const handleClick = (idx) => {
    if (locked || disabled || idx === activeIdx) {
      return false;
    }
    // Unset old active button
    buttonList[activeIdx].active = false;

    // Set new active button: ONLY ONE ACTIVE BUTTON AT A TIME SUPPORTED
    setActiveIdx(idx);
    buttonList[idx].active = true;
    return onRadioChange(buttonList[idx]);
  };

  return (
    <div className={classnames('btn-group', Styles.btnGroup, className, radioButtonSizes[size] || 'default-size')}>
      {buttonList.map((button, idx) => (
        <button
          type="button"
          key={button.id}
          className={classnames(
            'btn',
            idx === activeIdx ? radioButtonStyles.primary : radioButtonStyles.default,
            Styles.radioButtons,
          )}
          disabled={disabled}
          onClick={() => handleClick(idx)}
        >
          <span className={Styles.buttonLabel}>{button.label}</span>
        </button>
      ))}
    </div>
  );
};

RadioButtons.displayName = 'RadioButtons';

RadioButtons.propTypes = {
  className: PropTypes.string,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  onRadioChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(radioButtonSizes)),
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      active: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

RadioButtons.defaultProps = {
  className: undefined,
  locked: false,
  disabled: false,
  size: 'default',
};

export default RadioButtons;
