import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect, forms2PropTypes, FormLabel, useTranslation, DatePicker } from '@sb-itops/react';
import { MatterStatusSelect } from '@sb-matter-management/react/matter-status-select';
import { capitalize } from '@sb-itops/nodash';
import moment from 'moment';
import { MatterTypeSelect } from '../../matter-type-select';
import { includeErrorClassnameIfInvalid } from '../include-error-classname-if-invalid';
import { matterFieldsRegionStrategy } from '../matter-fields-region-strategy';

import MatterDetailsStyles from './MatterDetails.module.scss';
import Styles from '../AddEditMatterForm.module.scss';

export const getMatterStatusOptions = ({ includeDeleted, t }) => {
  const options = [
    { label: 'Open', value: 'Open' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Closed', value: 'Closed' },
    { label: capitalize(t('cancelled')), value: 'Cancelled' },
  ];

  if (includeDeleted) {
    options.push({ label: 'Deleted', value: 'Deleted' });
  }

  return options;
};

const leadClosedReasons = [
  'Not fit for business',
  'Lost to competitor',
  'Pricing',
  'Not valid case',
  'Non Responsive',
  'Decided to wait',
  'Referred out',
  'Conflict of Interest',
].map((v) => ({ label: v, value: v }));

export const MatterDetails = ({
  matterId,
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields
  locationId,
  matterTypeCategory,
  matterTypeId,
  clientRole,
  matterNumber,
  status,
  description,
  openedDate,
  closedDate,
  leadOpenedDate,
  leadClosedDate,
  leadClosedReason,
  leadMatterTypeId,
  // select options
  clientRoleOptions,
  // other
  autoNumberDefinition,
  matterNumberExistsWarning,
  isLead,
  // callbacks
  onFieldValuesUpdated,
  onLocationUpdated,
  onCategoryUpdated,
  onMatterTypeUpdated,
  onClientRoleUpdated,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const useAutoReference = autoNumberDefinition && autoNumberDefinition.useAutoReference;
  const isEditMode = !!matterId;

  // Show deleted status option only when a matter is already deleted
  const includeDeleted = status?.value === 'Deleted';
  const statusOptions = getMatterStatusOptions({ includeDeleted, t });

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel label={isLead ? 'Lead Type' : 'Matter Type'} field={matterTypeId} submitFailed={submitFailed} />
          <div>
            <MatterTypeSelect
              locationId={locationId && locationId.value}
              matterTypeCategory={matterTypeCategory && matterTypeCategory.value}
              matterTypeId={matterTypeId && matterTypeId.value}
              onLocationUpdated={onLocationUpdated}
              onCategoryUpdated={onCategoryUpdated}
              onMatterTypeUpdated={onMatterTypeUpdated}
              disabled={formDisabled || isEditMode}
              isRequired
              showError={submitFailed}
              showOnlyLeads={isLead}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={t('matterNumber')}
            field={matterNumber}
            submitFailed={submitFailed}
            optional={!matterFieldsRegionStrategy.matterNumberRequired}
          />
          <div>
            <input
              className={classnames('form-control', includeErrorClassnameIfInvalid(matterNumber, submitFailed))}
              type="text"
              disabled={formDisabled || useAutoReference}
              value={(matterNumber && matterNumber.value) || ''}
              onChange={(e) => onFieldValuesUpdated({ matterNumber: e.target.value })}
            />
          </div>
          {matterNumberExistsWarning && (
            <div className={classnames('col-lg-12', Styles.errorMessage)}>{matterNumberExistsWarning}</div>
          )}
        </div>
      </div>

      {isEditMode && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel label={isLead ? 'Lead Status' : 'Matter Status'} field={status} submitFailed={submitFailed} />
            <div>
              <MatterStatusSelect
                value={status && status.value}
                className={includeErrorClassnameIfInvalid(status, submitFailed)}
                onChange={(newStatus) => {
                  if (isLead && ['Closed', 'Cancelled'].includes(newStatus.value)) {
                    // If the status is set to closed, also change the lead closed date to today
                    onFieldValuesUpdated({
                      leadClosedDate: moment().toDate(),
                    });
                  }
                  if (!isLead && ['Closed', 'Cancelled'].includes(newStatus.value)) {
                    // If the status is set to closed, also change the matter closed date to today
                    onFieldValuesUpdated({
                      closedDate: moment().toDate(),
                    });
                  }
                  onFieldValuesUpdated({ status: newStatus.value });
                }}
                disabled={formDisabled}
                options={statusOptions}
              />
            </div>
            {status.isInvalid && (status.isDirty || submitFailed) && (
              <div className={classnames('col-lg-12', Styles.errorMessage)}>{status.invalidReason}</div>
            )}
          </div>
        </div>
      )}
      {(isLead || leadMatterTypeId?.value) && (
        <div className="row">
          <div className="col-lg-6 form-group">
            <label>Lead Opened</label>
            <DatePicker
              onSelect={(date) => {
                onFieldValuesUpdated({ leadOpenedDate: date });
              }}
              value={leadOpenedDate?.value}
              hasError={leadOpenedDate && leadOpenedDate?.isInvalid}
              format="DD/MM/YYYY"
              hideDelete
              disabled={!isLead}
            />
          </div>
          <div className="col-lg-6 form-group">
            <label>Lead Closed</label>
            <DatePicker
              onSelect={(date) => {
                onFieldValuesUpdated({ leadClosedDate: date });
              }}
              value={leadClosedDate?.value}
              hasError={leadClosedDate && leadClosedDate?.isInvalid}
              format="DD/MM/YYYY"
              hideDelete
            />
          </div>
        </div>
      )}
      {isLead && leadClosedDate.value && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel label="Lead Closed Reason" field={leadClosedReason} submitFailed={submitFailed} />
            <div>
              <StatelessSelect
                placeholder="Select closed reason"
                options={leadClosedReasons}
                className={classnames(includeErrorClassnameIfInvalid(leadClosedReason, submitFailed))}
                selectedOption={leadClosedReason && leadClosedReason.value}
                onChange={(o) => onFieldValuesUpdated({ leadClosedReason: o?.value })}
                isClearable
                showDropdown={false}
              />
            </div>
          </div>
        </div>
      )}
      {!isLead && (
        <div className="row">
          <div className="col-lg-6 form-group">
            <label>Matter Opened</label>
            <DatePicker
              onSelect={(date) => {
                onFieldValuesUpdated({ openedDate: date });
              }}
              value={openedDate?.value}
              hasError={openedDate && openedDate?.isInvalid}
              format="DD/MM/YYYY"
              hideDelete
            />
          </div>
          {isEditMode && (
            <div className="col-lg-6 form-group">
              <label>Matter Closed</label>
              <DatePicker
                onSelect={(date) => {
                  onFieldValuesUpdated({ closedDate: date });
                }}
                value={closedDate?.value}
                hasError={closedDate && closedDate?.isInvalid}
                format="DD/MM/YYYY"
                hideDelete
              />
            </div>
          )}
        </div>
      )}
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={isLead ? 'Lead Description' : 'Matter Description'}
            field={description}
            submitFailed={submitFailed}
            optional={!matterFieldsRegionStrategy.matterDescriptionRequired}
          />
          <div>
            <textarea
              className={classnames(
                'form-control',
                MatterDetailsStyles.description,
                includeErrorClassnameIfInvalid(description, submitFailed),
              )}
              disabled={formDisabled}
              value={(description && description.value) || ''}
              onChange={(e) => {
                e.preventDefault();
                onFieldValuesUpdated({ description: e.target.value });
              }}
              rows="5"
            />
          </div>
        </div>
      </div>
      {!isEditMode && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel label="Client Role" field={clientRole} submitFailed={submitFailed} />
            <div>
              <StatelessSelect
                className={classnames(submitFailed && includeErrorClassnameIfInvalid(clientRole, submitFailed))}
                options={clientRoleOptions}
                disabled={formDisabled || !(matterTypeId && matterTypeId.value)}
                selectedOption={clientRole.value}
                onChange={(newClientRole) => onClientRoleUpdated(newClientRole.value)}
              />
            </div>
          </div>
        </div>
      )}
    </fieldset>
  );
};

const selectOptions = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const { Forms2Field } = forms2PropTypes;

MatterDetails.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  locationId: Forms2Field,
  matterTypeCategory: Forms2Field,
  matterTypeId: Forms2Field,
  clientRole: Forms2Field,
  matterNumber: Forms2Field,
  status: Forms2Field,
  description: Forms2Field,
  openedDate: Forms2Field,
  closedDate: Forms2Field,
  leadOpenedDate: Forms2Field,
  leadClosedDate: Forms2Field,
  leadClosedReason: Forms2Field,
  leadMatterTypeId: Forms2Field,
  // select options
  clientRoleOptions: PropTypes.arrayOf(selectOptions),
  // other
  isLead: PropTypes.bool,
  autoNumberDefinition: PropTypes.object,
  matterNumberExistsWarning: PropTypes.string,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onLocationUpdated: PropTypes.func.isRequired,
  onCategoryUpdated: PropTypes.func.isRequired,
  onMatterTypeUpdated: PropTypes.func.isRequired,
  onClientRoleUpdated: PropTypes.func.isRequired,
};

MatterDetails.defaultProps = {
  matterId: undefined,
  // form state
  formDisabled: undefined,
  submitFailed: undefined,
  // form fields
  locationId: undefined,
  matterTypeCategory: undefined,
  matterTypeId: undefined,
  clientRole: undefined,
  matterNumber: undefined,
  status: undefined,
  description: undefined,
  openedDate: undefined,
  closedDate: undefined,
  leadOpenedDate: undefined,
  leadClosedDate: undefined,
  leadClosedReason: undefined,
  leadMatterTypeId: undefined,
  // select options
  clientRoleOptions: [],
  // other
  isLead: undefined,
  autoNumberDefinition: undefined,
  matterNumberExistsWarning: undefined,
};

MatterDetails.displayName = 'MatterDetails';
