import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPostP } from '@sb-itops/redux/fetch';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbEntityBankDetailsService',
  keyPath: 'id',
});

export const createEntityBankDetails = (entityBankDetails) => {
  const createEntityBankDetailsThunk = async () => {
    // minimal opdate entity to provide user with instant feedback on UI
    opdateCache({ optimisticEntities: [entityBankDetails] });

    try {
      const path = `/billing/entity-bank-details/:accountId/`;
      const fetchOptions = { body: JSON.stringify(entityBankDetails) };
      await fetchPostP({ path, fetchOptions });
      return entityBankDetails;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [entityBankDetails] });
      throw err;
    }
  };

  return store.dispatch(createEntityBankDetailsThunk);
};
