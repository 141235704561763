'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';

const getUserId = () => authSelectors.getUserId(store.getState());
const getIsAuthenticated = () => authSelectors.isAuthenticated(store.getState());


angular.module('@sb-billing/services').service('sbUserTimerService', function ($rootScope, $interval, sbGenericEndpointService, sbMessageDisplayService, sbLoggerService) {
  const that = this;
  const endpoint = 'billing/user-timer';
  const timers = [];
  const log = sbLoggerService.getLogger('sbUserTimerService');

  let timerPoller;
  let polling = false;

  that.startPolling = startPolling;
  that.stopPolling = stopPolling;

  that.getActiveTimer = () => timers.find(timer => timer.active === true);
  that.getAllTimers = () => timers;
  that.forceQueryTimersP = () => callTimerApiP({ method: 'GET', path: 'getAll', type: 'getAll' });
  that.createTimerP = (id) => callTimerApiP({ method: 'PUT', path: id + '/create' });
  that.deleteTimerP = (id) => callTimerApiP({ method: 'DELETE', path: id });
  that.pauseTimerP = (id) => callTimerApiP({ method: 'PUT', path: id + '/pause' });
  that.resumeTimerP = (id) => callTimerApiP({ method: 'PUT', path: id + '/resume' });
  that.updateTimerMatterIdP = (id, matterId) => callTimerApiP({ method: 'PUT', path: id + '/setMatter/' + matterId });
  that.updateTimerDescriptionP = (id, description) => callTimerApiP({ method: 'PUT', path: id + '/setDescription', data: { description } });

  store.subscribe(() => {
    if (!timerPoller) {
      return;
    } else if (!getIsAuthenticated()) {
      pausePolling();
    } else if (!polling) {
      resumePolling();
    }
  });

  function startPolling() {
    if (timerPoller) {
      return;
    }

    timerPoller = $interval(poll, 60 * 1000);
    resumePolling();
  }

  function resumePolling() {
    polling = true;
    poll();
  }

  function pausePolling() {
    polling = false;
  }

  function stopPolling() {
    if (timerPoller) {
      $interval.cancel(timerPoller);
      timerPoller = undefined;
    }

    polling = false;
  }

  function poll() {
    if (!polling) {
      return;
    }

    that.forceQueryTimersP();
  }

  async function callTimerApiP(command) {
      log.info('calling timer api with command', command);
      try {
        const userId = getUserId();

        const response = await sbGenericEndpointService.payloadP({
          method: command.method,
          skipCamelCase: true,
          namespace: endpoint,
          additional: userId + '/' + command.path,
          data: command.data,
        });

        if (!response) {
          throw new Error('Failed to receive response from timer API');
        }

        timers.splice(0, timers.length, ...response); // Replace all elements of timers array with response elements.
        stampActiveTimer();

        $rootScope.$broadcast('smokeball-data-update-sbUserTimerService');
      }
      catch (err) {
        log.error('Failed to call timer API', err);

        // Don't show the user an error for a polling request if there are no active timers.
        if (command.type === 'getAll' && !that.getActiveTimer()) {
          return;
        }

        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text(`An unstable Internet connection has been detected, your timers may not be up to date. Please check your Internet connection and refresh the page to fix this problem.`)
            .group('TIMER_ERROR')
        );

        // throw err; - Eventually this should be caught and handled further up, not consumed here.
      }
  }

  function stampActiveTimer() {
    const activeTimer = that.getActiveTimer();

    if (activeTimer) {
      activeTimer.activeSinceUnix = moment().unix();
    }
  }
});
