const logger = require('@sb-itops/fe-logger');
const parse = require('./rtf-parser');
const rtfToHTML = require('./rtf-to-html.js');

const log = logger.getLogger('@rtf-to-html');

module.exports = asStream;
module.exports.fromStream = fromStream;
module.exports.fromString = fromString;

function asStream(opts, cb) {
  if (arguments.length === 1) {
    cb = opts;
    opts = null;
  }
  return parse(htmlifyresult(opts, cb));
}

function fromStream(stream, opts, cb) {
  if (arguments.length === 2) {
    cb = opts;
    opts = null;
  }
  return parse.stream(stream, htmlifyresult(opts, cb));
}

function fromString(string, opts, cb) {
  if (arguments.length === 2) {
    cb = opts;
    opts = null;
  }
  return parse.string(string, htmlifyresult(opts, cb));
}

function htmlifyresult(opts, cb) {
  return (err, doc) => {
    if (err) return cb(err);
    try {
      return cb(null, rtfToHTML(doc, opts));
    } catch (ex) {
      log.error(ex);
      return cb(ex);
    }
  };
}
