'use strict';

const buttonColours = {
  WHITE: '#FFFFFF',
  GREEN: '#1DBF73',
};

const buttonStyles = {
  SOLID: 'SOLID',
  OUTLINE: 'OUTLINE',
};

const createButton = ({ url, label, colour = buttonColours.GREEN, style = buttonStyles.SOLID }) => {
  const borderColour = colour;
  let backgroundColour = colour;
  let textColour = buttonColours.WHITE;

  // invert colour if outline style
  if (style === buttonStyles.OUTLINE) {
    textColour = colour;
    backgroundColour = buttonColours.WHITE;
  }

  // Things required to display correctly in Outlook Desktop email client
  // 1. <!--[if mso]>
  const buttonHtml = `
  <span style="padding-top:15px;padding-bottom:15px">
    <!--[if mso]>
      <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${url}" style="height:34px;v-text-anchor:middle;width:160px;" arcsize="12%" strokecolor="${borderColour}" fillcolor="${backgroundColour}">
        <w:anchorlock/>
        <center style="color:${textColour};font-family:sans-serif;font-size:13px;font-weight:normal;">
          ${label}
        </center>
      </v:roundrect>
    <![endif]-->
    <a href="${url}" class="button-mb" style="background-color:${backgroundColour};border:1px solid ${borderColour};border-radius:4px;color:${textColour};display:inline-block;font-family:sans-serif;font-size:13px;font-weight:normal;line-height:34px;text-align:center;text-decoration:none;width:160px;-webkit-text-size-adjust:none;mso-hide:all;">
      ${label}
    </a>
  </span>&nbsp;&nbsp;`;
  return buttonHtml;
};

module.exports = {
  buttonColours,
  buttonStyles,
  createButton,
};
