import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import Styles from './DebugEntityHistory.module.scss';

const DebugEntityDisplay = ({ debugEntity, isRelatedEntity, selected, onSelect }) => (
  <div
    className={classnames(
      Styles.debugEntity,
      isRelatedEntity ? Styles.relatedEntity : undefined,
      selected ? Styles.selected : undefined,
    )}
    onClick={() => {
      onSelect(debugEntity);
    }}
  >
    <span className={Styles.entityCacheName}>{debugEntity.entityCacheName}</span> &nbsp;
    <span className={Styles.entityId}>{debugEntity.entityId}</span>
    <span className={Styles.timestamp}>
      {debugEntity.timestamp ? moment(debugEntity.timestamp).format('MMM DD HH:mm') : undefined}
    </span>
  </div>
);

const DebugEntityHistory = (props) => {
  const { selectedDebugEntity, debugEntityHistory, onSelect } = props;

  return (
    <div>
      {debugEntityHistory.map((debugEntity, index) => {
        const selected =
          selectedDebugEntity &&
          selectedDebugEntity.entityCacheName === debugEntity.entityCacheName &&
          selectedDebugEntity.entityId === debugEntity.entityId;

        return (
          <Fragment key={`${index}-${debugEntity?.entityId}`}>
            <DebugEntityDisplay debugEntity={debugEntity} selected={selected} onSelect={onSelect} />
            {debugEntity.relatedEntities &&
              debugEntity.relatedEntities.map((relatedEntity, relatedIndex) => {
                const relatedEntitySelected =
                  selectedDebugEntity &&
                  selectedDebugEntity.entityCacheName === relatedEntity.entityCacheName &&
                  selectedDebugEntity.entityId === relatedEntity.entityId;

                return (
                  <DebugEntityDisplay
                    debugEntity={relatedEntity}
                    isRelatedEntity
                    selected={relatedEntitySelected}
                    onSelect={onSelect}
                    key={`${index}-${relatedIndex}-${relatedEntity?.entityId}`}
                  />
                );
              })}
          </Fragment>
        );
      })}
    </div>
  );
};

DebugEntityHistory.displayName = 'DebugEntityHistory';

DebugEntityHistory.propTypes = {
  selectedDebugEntity: PropTypes.object,
  debugEntityHistory: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
};

DebugEntityHistory.defaultProps = {
  selectedDebugEntity: undefined,
  debugEntityHistory: [],
};

export default DebugEntityHistory;
