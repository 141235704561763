angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/login';

  // Settings (provides ui-view)
  $stateProvider

  .state('login', {
    url: '/login',
    templateUrl: BASE_DIRECTORY + '/login.html',
    controllerAs: '$ctrl',
    controller: function($state, sbGenericCacheService) {
      const ctrl = this;
      ctrl.onRedirect = $state.go;
      ctrl.clearAllCachesP = sbGenericCacheService.clearAllCachesP
    }
  });
});
