import { useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

import { BulkActionsButton } from './BulkActionsButton';

const hooks = ({ bulkActions }) => ({
  useBulkActionHandlers: () => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showConfirmSpinner, setShowConfirmSpinner] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [bulkActionId, setBulkActionId] = useState(null);

    const onConfirm = async () => {
      setShowConfirmSpinner(true);
      if (bulkActionId) {
        await bulkActions[bulkActionId].onConfirm();
      }
      setShowConfirmSpinner(false);
      setShowConfirmationModal(false);
    };

    const onCancel = () => {
      onClose();
    };

    const onClose = () => {
      setShowConfirmationModal(false);
      setBulkActionId(null);
    };

    const openConfirmationModal = ({ actionId, confirmTitle, confirmMessage }) => {
      setShowConfirmationModal(true);
      setConfirmationTitle(confirmTitle);
      setConfirmationMessage(confirmMessage);
      setBulkActionId(actionId);
    };

    const menuOptions = Object.entries(bulkActions).map(([actionId, action]) => ({
      id: actionId,
      label: action.label,
      disabled: action.disabled,
      onClick: () =>
        openConfirmationModal({
          actionId,
          confirmTitle: action.confirmTitle,
          confirmMessage: action.confirmMessage,
        }),
    }));

    return {
      showConfirmationModal,
      showConfirmSpinner,
      confirmationTitle,
      confirmationMessage,
      menuOptions,
      onConfirm,
      onCancel,
      onClose,
    };
  },
});

export const BulkActionsButtonContainer = withReduxProvider(composeHooks(hooks)(BulkActionsButton));

BulkActionsButtonContainer.displayName = 'BulkActionsButtonContainer';

BulkActionsButtonContainer.propTypes = {
  disabled: PropTypes.bool,
  bulkActions: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      confirmTitle: PropTypes.string, // optionally supported
      confirmMessage: PropTypes.string.isRequired,
      onConfirm: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
};

BulkActionsButtonContainer.defaultProps = {
  disabled: false,
};
