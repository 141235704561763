import { createSelector } from 'reselect';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import {
  findOtherSideRole,
  findClientRoleOptions,
  findRoleOptions,
} from '@sb-matter-listing/business-logic/matter-type-configurations/services';
import domain from '../domain';

export const { UPDATE_CACHE, getById, getMap, getList, updateCache } = cacheFactory({
  domain,
  name: 'matter-type-configurations',
  keyPath: 'matterTypeId',
  ngCacheName: 'sbMatterTypeConfigurationsService',
  syncType: syncTypes.SINCE,
});

export const getOtherSideRole = (matterTypeId, clientRole) => {
  const matterTypeConfiguration = getById(matterTypeId);
  const otherSideRole = findOtherSideRole(matterTypeConfiguration, clientRole);
  return otherSideRole;
};

const selectClientRoleOptions = createSelector(
  (matterTypeConfig) => matterTypeConfig,
  (matterTypeConfig) => findClientRoleOptions(matterTypeConfig),
);

export const getClientRoleOptions = (matterTypeId, returnAsMap = false) => {
  if (!matterTypeId) {
    return [];
  }

  const matterTypeConfig = getById(matterTypeId);
  const options = selectClientRoleOptions(matterTypeConfig);
  return returnAsMap ? options.map : options.list;
};

const selectRoleOptions = createSelector(
  (matterTypeConfig) => matterTypeConfig,
  (matterTypeConfig) => findRoleOptions(matterTypeConfig),
);

export const getRoleOptions = (matterTypeId, returnAsMap = false) => {
  if (!matterTypeId) {
    return [];
  }

  const matterTypeConfig = getById(matterTypeId);
  const options = selectRoleOptions(matterTypeConfig);
  return returnAsMap ? options.map : options.list;
};
