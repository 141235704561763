import { getById as getContactById, getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getBalanceType, BANK_BALANCE_TYPE } from '@sb-billing/redux/bank-account-settings';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import {
  getDefaultCreditAccount,
  getOperatingAccount,
  getById as getBankAccountById,
} from '@sb-billing/redux/bank-account';
import { getMatterTrustBalanceByDate } from '@sb-billing/redux/transactions';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { findActivePaymentPlanByDebtorId } from '@sb-billing/redux/payment-plans/selectors';
import { getList as getPaymentPlansList } from '@sb-billing/redux/payment-plans';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import {
  balanceTypes,
  validAccountTypeForBalanceType,
} from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getDefaultPaymentSource, getPaymentSource } from '@sb-billing/business-logic/payment-source';
import { capitalize } from '@sb-itops/nodash';

const { getMatterBalance, getMatterContactBalances } = selectors;

// TODO this file can do with some light refactoring - it has not been done already as these changes are happening in RC.
// - Scoping case statements to allow use to use const
// - Reducing some code duplication
// - Changing exported function declarations to use destructured params.

export const PAYMENT_SOURCE = Object.freeze({
  check: 'Check',
  bankTransfer: 'Bank Transfer',
  cash: 'Cash',
  creditCard: 'Credit Card',
  eCheck: 'eCheck',
});

/**
 * @enum payment type.
 *
 */
const PAYMENT_TYPE = Object.freeze({
  direct: 'Direct',
  trust: 'Trust',
  operating: 'Operating',
  credit: 'Credit',
});

const contactDisplay = (contactId) => {
  const contact = getContactById(contactId);

  if (!contact) {
    return '';
  }

  return getContactDisplay(contact.entityId, { showLastNameFirst: true });
};

const getSplitPayors = (bankAccountId, matterId) => {
  const bankAccount = getBankAccountById(bankAccountId);
  // 'available' for TRUST and 'balance' for others
  const balanceType =
    bankAccount?.accountType?.toUpperCase() in validAccountTypeForBalanceType
      ? balanceTypes.AVAILABLE
      : balanceTypes.BALANCE;

  const contactBalances = matterId
    ? getMatterContactBalances(getBankAccountBalanceState(), { bankAccountId, matterId })
    : [];

  return contactBalances.map((mb) => {
    const paymentPlan = findActivePaymentPlanByDebtorId(getPaymentPlansList(), {
      debtorId: mb.contactId,
    });

    return {
      id: mb.contactId,
      available: mb[balanceType],
      displayName: contactDisplay(mb.contactId),
      paymentPlanId: (paymentPlan && paymentPlan.matterIds.includes(matterId) && paymentPlan.id) || undefined,
    };
  });
};

const getDirectOptions = (t) => [
  {
    label: capitalize(t('cheque')),
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.check,
    isDefault: true,
    value: PAYMENT_SOURCE.check,
  },
  {
    label: 'Bank Transfer',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.bankTransfer,
    value: PAYMENT_SOURCE.bankTransfer,
  },
  {
    label: 'Cash',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.cash,
    value: PAYMENT_SOURCE.cash,
  },
  {
    label: 'Credit Card',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.creditCard,
    value: PAYMENT_SOURCE.creditCard,
  },
];

const getDirectPaymentSources = (t) => JSON.parse(JSON.stringify(getDirectOptions(t)));

/**
 * @typedef {object} Payment_Source
 * @property {string} label the label to display
 * @property {string} paymentType one of trust|operating|direct
 * @property {string} value unique value used to properly render a specific option
 * @property {string} bankAccountId unique bank account id
 * @property {string} [paymentSource] descriptor, direct sources only
 * @property {number} [balance] the balance in cents, account sources only
 * @property {boolean} [isCombinedBalance] if the option balance is derived from more than 1 balance entity, account sources only
 * @property {string} [contactId] the id of the contact, account sources only
 */

/**
 * Payment sources include direct (external) payment sources if the accountType is not specified.
 *
 * Firms that operate Matter only balances will have an option for each of the trust and operating accounts
 * with balances available, in that order.
 *
 * Firms that operate MatterContact balances will have an option for each of the contacts with balances in
 * the trust and operating accounts, grouped by account and in that order. Where there is more than one
 * contact with a balance in an account, the group is preceded by a combined option with the total available
 * for that account.
 *
 * @param {object} data - data to be used to derive the payment sources
 * @param {string} [data.matterId] - The ID of the matter to get the balances for
 * @param {string} [data.accountType] optional - one of trust|operating
 * @param {boolean} [data.includeCombined]
 * @param {boolean} [data.includeCreditSources] used ot exclude credit sources from parts of the app that havent implemented it
 * @param {number} [data.effectiveDate] optional - date on which the payment is to be made
 * @param {function} data.t - Localisation function
 * @param {boolean} [data.allowOverdraw] allow overdraw of trust account
 *
 * @return {Payment_Option[]} options, payment sources for a matter based on the bank balance type
 */
const getPaymentSources = ({
  matterId,
  accountType,
  includeCombined = true,
  effectiveDate,
  includeCreditSources = true,
  bankAccountId = null,
  t,
  allowOverdraw,
}) => {
  let paymentSources = [];

  const operatingBankAccountId = getOperatingAccount().id;
  const creditBankAccountId = getDefaultCreditAccount()?.id;

  const trustAccounts = getTrustAccountsForPaymentSources({ matterId });

  const opts = !accountType ? [...getDirectOptions(t)] : [];
  const matterTrustBalances = trustAccounts.reduce((acc, trustAccount) => {
    acc[trustAccount.id] = matterId
      ? getMatterBalance(getBankAccountBalanceState(), { matterId, bankAccountId: trustAccount.id })
      : 0;
    return acc;
  }, {});

  const matterOperatingBalance = matterId
    ? getMatterBalance(getBankAccountBalanceState(), { matterId, bankAccountId: operatingBankAccountId })
    : 0;
  const matterCreditBalance =
    matterId && creditBankAccountId
      ? getMatterBalance(getBankAccountBalanceState(), { matterId, bankAccountId: creditBankAccountId })
      : 0;
  const bankBalanceType = getBalanceType();
  const includeTrust = !accountType || accountType.toLowerCase() === 'trust';
  const includeOperating = !accountType || accountType.toLowerCase() === 'operating';
  const includeCredit = includeCreditSources && (!accountType || accountType.toLowerCase() === 'credit');

  switch (bankBalanceType) {
    case BANK_BALANCE_TYPE.matter:
      trustAccounts.forEach((trustAccount) => {
        const trustBankAccountId = trustAccount.id;
        const matterTrustBalance = matterTrustBalances[trustBankAccountId];
        // In AU with the overdrawn feature, the trust balance should be updated dynamically
        // on the amount change or the effective date change.
        const matterTrustBalanceByDate =
          allowOverdraw && effectiveDate
            ? getMatterTrustBalanceByDate(matterId, effectiveDate, trustBankAccountId)
            : matterTrustBalance;

        const includeMatterTrust = allowOverdraw
          ? includeTrust && Number.isFinite(matterTrustBalance)
          : includeTrust && matterTrustBalance;

        if (includeMatterTrust) {
          const trustAccountName = `${getBankAccountName(trustAccount, t)}:`;
          opts.push({
            label: `${trustAccountName} ${t('cents', { val: matterTrustBalanceByDate })}`,
            paymentType: PAYMENT_TYPE.trust,
            balance: matterTrustBalance,
            value: trustBankAccountId,
            bankAccountId: trustBankAccountId,
          });
        }
      });

      if (includeOperating && matterOperatingBalance) {
        opts.push({
          label: `${t('operatingRetainer')} ${t('cents', { val: matterOperatingBalance })}`,
          paymentType: PAYMENT_TYPE.operating,
          balance: matterOperatingBalance,
          value: PAYMENT_TYPE.operating,
          bankAccountId: operatingBankAccountId,
        });
      }

      if (includeCredit && matterCreditBalance) {
        opts.push({
          label: `Credit ${t('cents', { val: matterCreditBalance })}`,
          paymentType: PAYMENT_TYPE.credit,
          balance: matterCreditBalance,
          value: PAYMENT_TYPE.credit,
          bankAccountId: creditBankAccountId,
        });
      }

      paymentSources = opts;
      break;

    case BANK_BALANCE_TYPE.matterContact:
      if (includeTrust) {
        trustAccounts.forEach((trustAccount) => {
          const trustBankAccountId = trustAccount.id;
          const trustAccountName = `${getBankAccountName(trustAccount, t)}:`;
          const matterTrustBalance = matterTrustBalances[trustBankAccountId];
          const matterContactTrustBalances = matterId
            ? getMatterContactBalances(getBankAccountBalanceState(), { bankAccountId: trustAccount.id, matterId })
            : [];

          if (includeCombined && matterContactTrustBalances.length > 1 && matterTrustBalance) {
            opts.push({
              label: `${trustAccountName} ${t('cents', { val: matterTrustBalance })} (Total Combined)`,
              paymentType: PAYMENT_TYPE.trust,
              balance: matterTrustBalance,
              isCombinedBalance: true,
              value: trustBankAccountId,
              bankAccountId: trustBankAccountId,
            });
          }

          matterContactTrustBalances.forEach((mb) => {
            const balanceType =
              'TRUST' in validAccountTypeForBalanceType ? balanceTypes.AVAILABLE : balanceTypes.BALANCE;

            opts.push({
              label: `${trustAccountName} ${t('cents', { val: mb[balanceType] })} (${contactDisplay(mb.contactId)})`,
              paymentType: PAYMENT_TYPE.trust,
              balance: mb[balanceType],
              contactId: mb.contactId,
              value: `${trustBankAccountId}-${mb.contactId}`,
              bankAccountId: trustBankAccountId,
            });
          });
        });
      }

      if (includeOperating) {
        const matterContactOperatingBalances = matterId
          ? getMatterContactBalances(getBankAccountBalanceState(), { bankAccountId: operatingBankAccountId, matterId })
          : [];

        if (includeCombined && matterContactOperatingBalances.length > 1 && matterOperatingBalance) {
          opts.push({
            label: `${t('operatingRetainer')} ${t('cents', { val: matterOperatingBalance })} (Total Combined)`,
            paymentType: PAYMENT_TYPE.operating,
            balance: matterOperatingBalance,
            isCombinedBalance: true,
            value: PAYMENT_TYPE.operating,
            bankAccountId: operatingBankAccountId,
          });
        }

        matterContactOperatingBalances.forEach((mb) => {
          opts.push({
            label: `${t('operatingRetainer')} ${t('cents', { val: mb.balance })} (${contactDisplay(mb.contactId)})`,
            paymentType: PAYMENT_TYPE.operating,
            balance: mb.balance,
            contactId: mb.contactId,
            value: `${PAYMENT_TYPE.operating}-${mb.contactId}`,
            bankAccountId: operatingBankAccountId,
          });
        });
      }

      if (includeCredit) {
        const matterContactCreditBalances =
          matterId && creditBankAccountId
            ? getMatterContactBalances(getBankAccountBalanceState(), { bankAccountId: creditBankAccountId, matterId })
            : [];

        if (includeCombined && matterContactCreditBalances.length > 1 && matterCreditBalance) {
          opts.push({
            label: `Credit ${t('cents', { val: matterCreditBalance })} (Total Combined)`,
            paymentType: PAYMENT_TYPE.credit,
            balance: matterCreditBalance,
            isCombinedBalance: true,
            value: PAYMENT_TYPE.credit,
            bankAccountId: creditBankAccountId,
          });
        }

        matterContactCreditBalances.forEach((mb) => {
          opts.push({
            label: `Credit ${t('cents', { val: mb.balance })} (${contactDisplay(mb.contactId)})`,
            paymentType: PAYMENT_TYPE.credit,
            balance: mb.balance,
            contactId: mb.contactId,
            value: `${PAYMENT_TYPE.credit}-${mb.contactId}`,
            bankAccountId: creditBankAccountId,
          });
        });
      }

      paymentSources = opts;
      break;

    default:
      throw new Error(`invalid bankBalanceType ${bankBalanceType}`);
  }

  return !bankAccountId ? paymentSources : paymentSources.filter((source) => source.bankAccountId === bankAccountId);
};

/**
 * Returns alphabetically sorted list of trust accounts available for a matter.
 *
 * @param {string} matterId The ID of the matter to get the balances for
 * @return {BankAccount[]} Array of trust accounts
 */
const getTrustAccountsForPaymentSources = ({ matterId }) => filterTrustAccountsByMatter(matterId);

/**
 * Returns the payment sources for a specified matter for each account, unless an `accountType` is specified.
 *
 * Effectively, it returns the same sources as a matter balance firm, and this is useful for
 * the invoice payment `pay now` links.
 *
 * @param {string} matterId The ID of the matter to get the balances for
 * @param {string} accountType, optional - one of trust|operating
 * @param {function} t - function for localisation
 * @param {boolean} allowTrustOverdraw - is overdraw possible
 * @return {Payment_Option[]} options, payment sources for a matter based on the bank balance type
 */
const getAccountPaymentSources = ({ matterId, accountType, t, allowTrustOverdraw }) => {
  const operatingBankAccountId = getOperatingAccount().id;
  const creditBankAccountId = getDefaultCreditAccount()?.id;
  const trustBankAccounts = getTrustAccountsForPaymentSources({ matterId });

  const matterTrustBalance = matterId
    ? trustBankAccounts.reduce(
        (acc, ta) => acc + getMatterBalance(getBankAccountBalanceState(), { matterId, bankAccountId: ta.id }),
        0,
      )
    : 0;
  const matterOperatingBalance = matterId
    ? getMatterBalance(getBankAccountBalanceState(), { matterId, bankAccountId: operatingBankAccountId })
    : 0;
  const matterCreditBalance = matterId
    ? getMatterBalance(getBankAccountBalanceState(), { matterId, bankAccountId: creditBankAccountId })
    : 0;
  const opts = [];

  const includeTrust = !accountType || accountType.toLowerCase() === 'trust';
  const includeOperating = !accountType || accountType.toLowerCase() === 'operating';
  const includeCredit = !accountType || accountType.toLowerCase() === 'credit';

  if (includeTrust && (allowTrustOverdraw || matterTrustBalance)) {
    opts.push({
      label: t('trust'),
      paymentType: PAYMENT_TYPE.trust,
      balance: matterTrustBalance,
      value: PAYMENT_TYPE.trust,
    });
  }

  if (includeOperating && matterOperatingBalance) {
    opts.push({
      label: t('operatingRetainer'),
      paymentType: PAYMENT_TYPE.operating,
      balance: matterOperatingBalance,
      value: PAYMENT_TYPE.operating,
    });
  }
  if (includeCredit && matterCreditBalance) {
    opts.push({
      label: `Credit`,
      paymentType: PAYMENT_TYPE.credit,
      balance: matterCreditBalance,
      value: PAYMENT_TYPE.credit,
    });
  }

  return opts;
};

export {
  PAYMENT_TYPE,
  getAccountPaymentSources,
  getSplitPayors,
  getPaymentSources,
  getDefaultPaymentSource,
  getPaymentSource,
  getDirectPaymentSources,
};
