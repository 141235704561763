import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/invoice-settings-template'; 

angular.module('@sb-billing/services').service('sbInvoiceSettingsTemplateService', function (sbGenericCacheService, sbEndpointType) {
  const endpoint = 'billing/invoice-settings-template';
  sbGenericCacheService.setupCache({
    name: 'sbInvoiceSettingsTemplateService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.InvoiceSettingsTemplateUpdated'
    },
    updateRedux,
    clearRedux,
  });
});
