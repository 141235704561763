import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').directive('sbActionStreamInvoice', function ($http, sbGenericEndpointService, FileSaver, Blob, sbMessageDisplayService, sbInvoicingService) {
  'use strict';

  function blobifyB64(b64, options) {
    var binary = window.atob(b64);
    var array = new Uint8Array(binary.length);

    for( let i = 0; i < binary.length; i++ ) {
      array[i] = binary.charCodeAt(i);
    }

    return new Blob([array], options);
  }

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    'link': function (scope, element, attrs, ctrl) {
      var isInDownload, invoice;
      var REMOVE_GROUP = 'sbActionDownloadReport';

      ctrl.group.onInvoiceData(function (data) { invoice = data; });
      ctrl.group.setAction(downloadBillPdf);

      function downloadBillPdf() {
        if (isInDownload) {
          return;
        }

        if (invoice) {
          setDownloading(true);

          let invoiceVersionId = invoice.versionId;

          if (featureActive('BB-1761') && invoice.isOriginalInvoice) {
            invoiceVersionId = sbInvoicingService.getOriginalInvoiceVersionId(invoice.invoiceId);
          }

          if (sbInvoicingService.isInvoiceVoided(invoice.invoiceId)) {
            invoiceVersionId = sbInvoicingService.getClassicInvoiceVersionId(invoice.invoiceId);
          }

          return downloadAndBlobifyPdfP(invoiceVersionId).then(function (blob) {
            FileSaver.saveAs(blob, 'invoice-' + invoice.invoiceNumber + '.pdf');
            resetDownload();
          }).catch(function (err) {
            handleDownloadError(err);
          });
        } else {
          throw new Error('no invoice found to download');
        }
      }

      function downloadAndBlobifyPdfP(versionId) {
        const payloadConfig = {
          skipCamelCase: true,
        };
        let namespace, additional;
      
        if (featureActive('BB-10919')) {
          // This endpoint returns 404 when invoice doesn't exist which is handled in handleDownloadError
          namespace = `billing/invoice-pdf`;
          additional = `${versionId}/presigned-url`;
        } else {
          namespace = 'billing/document-stream';
          additional = `invoices/${versionId}`;
        }
      
        return sbGenericEndpointService.getPayloadP(namespace, additional, payloadConfig).then(async (payload) => {
          let pdfBlob;
          if (featureActive('BB-10919')) {
            const request = {
              method: 'GET',
              url: payload.preSignedUrl,
              responseType: 'arraybuffer',
            };
            const pdfResponse = await $http(request);
            pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });
          } else {
            pdfBlob = blobifyB64(payload, { type: 'application/pdf' });
          }
          return pdfBlob;
        });
      }

      function handleDownloadError(err) {
        setDownloading(false);
        if (err.status === 404) {
          sbMessageDisplayService.error(
            sbMessageDisplayService
            .builder()
            .text('Document not found. Please try again later.')
            .group(REMOVE_GROUP)
          );
        } else {
          sbMessageDisplayService.error(
            sbMessageDisplayService
            .builder()
            .text('Failed to download invoice. Please check your connection and try again later')
            .conditionalText('({0})', err.statusCode)
            .group(REMOVE_GROUP)
          );
        }
      }

      function resetDownload() {
        setDownloading(false);
        sbMessageDisplayService.dismissGroup(REMOVE_GROUP);
      }

      function setDownloading(status) {
        isInDownload = status;
        ctrl.group.setLock(status);
      }

    }
  };
});
