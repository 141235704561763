import React, { memo, useState, useEffect } from 'react';
import * as sbUnsavedChangesService from '@sb-itops/services/src/unsaved-changes';
import { dateToInteger } from '@sb-itops/date';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateListFilter from './DateListFilter';

const DateListFilterContainer = memo(({ className, onChange, selectedFilter, from, to, before, scope }) => {
  const [view, setView] = useState(() => {
    let filters = sbUnsavedChangesService.loadMemory(scope);
    if (!filters) {
      filters = {
        before,
        from,
        to,
        filterType: selectedFilter,
      };
    }
    if (onChange) {
      onChange(filters);
    }
    return filters;
  });

  useEffect(() => () => sbUnsavedChangesService.saveMemory(scope, view));

  return (
    <DateListFilter
      before={view.before}
      to={view.to}
      from={view.from}
      className={className}
      selectedFilter={view.filterType}
      onChange={(newFilter) => {
        setView(newFilter);
        if (onChange) {
          onChange(newFilter);
        }
      }}
    />
  );
});

DateListFilterContainer.displayName = 'DateListFilterContainer';
DateListFilterContainer.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  selectedFilter: PropTypes.string,
  before: PropTypes.number, // effective date YYYYMMDD
  from: PropTypes.number, // effective date YYYYMMDD
  to: PropTypes.number, // effective date YYYYMMDD
  scope: PropTypes.string.isRequired,
};
DateListFilterContainer.defaultProps = {
  className: undefined,
  onChange: undefined,
  selectedFilter: 'ALL',
  before: dateToInteger(moment().toDate()), // effective date YYYYMMDD
  from: dateToInteger(moment(moment().format('YYYYMM'), 'YYYYMM').toDate()), // effective date YYYYMMDD
  to: dateToInteger(
    moment(moment(moment().format('YYYYMM'), 'YYYYMM').toDate())
      .add(1, 'month')
      .add(-1, 'day')
      .toDate(),
  ), // effective date YYYYMMDD
};

export default DateListFilterContainer;
