'use strict';

const getChargeErrorMessage = ({ defaultMessage, chargeStatusResult }) => {
  const { providerResponse: lawpayResponse } = chargeStatusResult;

  const failures = (lawpayResponse || {}).failures;
  const message = Object.values(failures)[0] || defaultMessage;

  return { message };
};

module.exports = {
  getChargeErrorMessage,
};
