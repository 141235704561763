import { getActiveProvider, isPaymentProviderEnabledForBankAccount } from '@sb-billing/redux/payment-provider-settings/selectors';
import { getAccountId } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').component('sbDataPaymentProviderBankAccountEnabled', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: { composeKey: '@?', accountType: '<'},
  controller: function () {
    const ctrl = this;
    const DEFAULT_COMPOSE_KEY = 'paymentProviderBankAccountEnabled';

    ctrl.$onInit = () => {
      if (_.isEmpty(ctrl.composeKey)) {
        ctrl.composeKey = DEFAULT_COMPOSE_KEY;
      }

      if (_.isEmpty(ctrl.accountType)) {
        ctrl.accountType = 'Operating';
      }

      ctrl.providerType = getActiveProvider();

      const bankAccountEnabled = ctrl.providerType && isPaymentProviderEnabledForBankAccount({ providerType: ctrl.providerType, bankAccountId: `${getAccountId()}/${ctrl.accountType}` });
      this.sbComposeCtrl.setComposeData(bankAccountEnabled, ctrl.composeKey);
    };
  }
}
);
