import PropTypes from 'prop-types';
import React from 'react';
import { Button, CardContainer, FloatingCard, Spinner } from '@sb-itops/react';
import Styles from './FeeWiseGetStartedCard.module.scss';

export const FeeWiseGetStartedCard = ({ onApplyClick, showLoading, contentText }) => (
  <div className={Styles.feeWiseGetStartedCardContainer}>
    <CardContainer>
      <FloatingCard>
        <div className={Styles.feeWiseGetStartedCard}>
          {showLoading && <Spinner small />}
          {!showLoading && (
            <>
              <div className={Styles.heading}>Getting Started</div>
              {contentText.map((text, index) => (
                <div className={Styles.content} key={index}>
                  {text}
                </div>
              ))}

              <div className={Styles.buttonBar}>
                <Button className={Styles.applyNowButton} size="full-width" onClick={onApplyClick}>
                  Apply Now
                </Button>
              </div>
            </>
          )}
        </div>
      </FloatingCard>
    </CardContainer>
  </div>
);

FeeWiseGetStartedCard.displayName = 'FeeWiseGetStartedCard';

FeeWiseGetStartedCard.propTypes = {
  onApplyClick: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
  contentText: PropTypes.array.isRequired,
};
