'use strict';

const creditCardSaveRequestDefaults = {
  EMAIL_SUBJECT: 'Credit Card details request from [FIRM_NAME]',
  EMAIL_MESSAGE:
    '<p>Dear [CLIENT_NAME],</p>' +
    '<p><br></p>' +
    `<p>Please use the button below to securely save your credit card details which will be used
     for future transactions. No payment will be applied to your card in this transaction.</p>` +
    '<p><br></p>' +
    '<p>[ADD_CARD_BUTTON]</p>' +
    '<p><br></p>' +
    '<p>Kind regards,</p>' +
    '<p>[USER_NAME]</p>' +
    '<p>[FIRM_NAME]</p>' +
    '<p>[PHONE_NUMBER]</p>',
};

module.exports = {
  creditCardSaveRequestDefaults,
};
