import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';

export const CreditTooltipSummary = memo(({ creditContactBalances }) => {
  const { t } = useTranslation();
  return (
    <div className="left-position" onClick={(e) => e.stopPropagation()}>
      <div className="column">
        {creditContactBalances.map((contactBalance) => (
          <div key={contactBalance.contactId}>{contactBalance.contactName}</div>
        ))}
      </div>
      <div className="column money-column">
        {creditContactBalances.map((contactBalance) => (
          <div key={contactBalance.contactId}>{t('cents', { val: contactBalance.availableBalance })}</div>
        ))}
      </div>
      <i />
    </div>
  );
});

CreditTooltipSummary.displayName = 'CreditTooltipSummary';

CreditTooltipSummary.propTypes = {
  creditContactBalances: PropTypes.array.isRequired,
};
