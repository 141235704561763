'use strict';

const { expenseCanBeModified } = require('./expense-can-be-modified');

/**
 * Determines if expenseVersion can be marked as billable
 *
 * A expense version can be marked as billable if
 * 1. it can be modified
 * 2. is not already non-billable
 * @param {object} expenseVersion
 * @returns {boolean}
 */
const expenseCanBeMarkedAsNonBillable = (expenseVersion) =>
  expenseCanBeModified(expenseVersion) && expenseVersion.isBillable;

module.exports = {
  expenseCanBeMarkedAsNonBillable,
};
