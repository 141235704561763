import { pick as pickValueFromObject } from 'dot-object';

/**
 * withSelectorStateSlicing accepts a property to be used to slice the state parameter which is passed to all the
 * selectors in a collection of selectors. The sliceProperty supports dot object notation - for some reason.
 *
 *
 * @param  {string}   sliceProperty Property used to slice the state. Support dot object notation, though I cannot think of a feature use case of dot object.
 * @return {Function} Function accepting a collection of selectors to have state slicing applied.
 *
 * @example:
 *
 *  const globalReduxState = {
 *    someFeature: {
 *      someSubFeatureThing: {
 *        someState: 1,
 *        someOtherState: 2,
 *      },
 *    },
 *  };
 *
 *  const mySelector = (state) => state.someState;
 *  const myOtherSelector = (state) => state.someOtherState;
 *
 *  mySelector(globalReduxState);     // returns undefined
 *  myOtherSelector(globalReduxState) // returns undefined
 *
 *  const selectors = withSelectorStateSlicing('someFeature.someSubFeatureThing')({ mySelector, myOtherSelector });
 *
 *  selectors.mySelector(globalReduxState);      // returns 1
 *  selectors.myOtherSelector(globalReduxState); // returns 2
 */
export const withSelectorStateSlicing = (sliceProperty) => (selectors) =>
  Object.entries(selectors || {}).reduce((acc, [selectorName, selectorFn]) => {
    acc[selectorName] = (state, args) =>
      state ? selectorFn(pickValueFromObject(sliceProperty, state), args) : selectorFn(state, args);
    return acc;
  }, {});
