import { useState, useMemo } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { resourceIds, policyTypes } from '@sb-itops/business-logic/authorisation';
import { SettingsStaffPermissions } from 'web/graphql/queries';
import { useQuery } from '@apollo/client';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { sortByProperty } from '@sb-itops/nodash';
import StaffPermissions from './StaffPermissions';

const savePermissionsEndpointPath = `/itops/authorisation/:accountId/principal-policy`;

const hooks = () => ({
  useData: () => {
    const permissionsQueryResult = useQuery(SettingsStaffPermissions, {
      fetchPolicy: 'cache-and-network',
    });

    if (permissionsQueryResult.error) {
      throw new Error(permissionsQueryResult.error);
    }

    const listOfUsersWithPermissions = permissionsQueryResult?.data?.firmUsersList?.users;

    return {
      isLoading: permissionsQueryResult.loading,
      listOfUsersWithPermissions,
    };
  },

  usePermissionsState: () => {
    // Used to persist what we get from backend
    const [permissionsDefault, setPermissionsDefault] = useState({});
    // Used to keep track of which permissions changed so we know waht to save
    const [permissions, setPermissions] = useState({});

    return {
      permissionsDefault,
      setLoadedPermissions: (permissionDefault) => {
        setPermissionsDefault(permissionDefault);
        setPermissions(
          // for "permissions", we need to track if they are dirty to save only changes
          Object.keys(permissionDefault).reduce((acc, userId) => {
            acc[userId] = {
              isDirty: false,
              permissions: permissionDefault[userId],
            };
            return acc;
          }, {}),
        );
      },

      permissions,
      onPermissionsChange: (changes) => {
        const newPermissions = Object.keys(changes).reduce((acc, userId) => {
          acc[userId] = {
            isDirty: true,
            permissions: changes[userId],
          };
          return acc;
        }, {});

        setPermissions((existingPermissions) => ({
          ...existingPermissions,
          ...newPermissions,
        }));
      },
      resetIsDirtyForPermissions: () => {
        setPermissions((existingPermissions) =>
          Object.keys(existingPermissions).reduce((acc, userId) => {
            acc[userId] = {
              ...existingPermissions[userId],
              isDirty: false,
            };
            return acc;
          }, {}),
        );
      },
    };
  },
});

const dependentHooks = () => ({
  useUserList: ({ setLoadedPermissions, listOfUsersWithPermissions }) => {
    const userList = useMemo(() => {
      const permissionsLookup = {};
      const list = (listOfUsersWithPermissions || []).reduce((acc, user) => {
        const permissionsByResourceId = user.permissions.reduce((permissionsMap, permission) => {
          // eslint-disable-next-line no-param-reassign
          permissionsMap[permission.resourceId] = permission.isAuthorized;
          return permissionsMap;
        }, {});

        const userRecord = {
          userId: user.id,
          name: user.person.name,
          email: user.person.email,
          isOwner: permissionsByResourceId[resourceIds.BILLING_FIRM_OWNER],
        };

        acc.push(userRecord);
        permissionsLookup[user.id] = permissionsByResourceId;

        return acc;
      }, []);

      setLoadedPermissions(permissionsLookup);
      return sortByProperty(list, 'name', 'asc', false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfUsersWithPermissions]);

    return { userList, permissionIds: resourceIds };
  },
  useSubmit: ({ permissions, resetIsDirtyForPermissions }) => {
    const [saving, setSaving] = useState(false);

    const dirtyPermissions = Object.keys(permissions).reduce((acc, userId) => {
      if (permissions[userId].isDirty) {
        acc[userId] = {
          [resourceIds.BILLING_DATA_FIRM_ACCOUNTS]:
            permissions[userId].permissions[resourceIds.BILLING_DATA_FIRM_ACCOUNTS],
          [resourceIds.BILLING_DATA_FIRM_REPORTS]:
            permissions[userId].permissions[resourceIds.BILLING_DATA_FIRM_REPORTS],
          // we don't want to include BILLING_FIRM_OWNER permission as it can't be changed in this component
        };
      }
      return acc;
    }, {});

    const onSavePermissions = async () => {
      try {
        setSaving(true);
        await marshalAndSubmitPermissions({ permissions: dirtyPermissions });
        displaySuccessToUser('Permissions saved');
        resetIsDirtyForPermissions();
      } catch (error) {
        displayErrorToUser('Failed to save permissions');
      } finally {
        setSaving(false);
      }
    };

    return { isSaving: saving, onSavePermissions, savingAllowed: Object.keys(dirtyPermissions).length > 0 };
  },
});

const marshalAndSubmitPermissions = async ({ permissions }) => {
  const userIds = Object.keys(permissions);

  const payload = userIds.reduce((acc, userId) => {
    acc[userId] = [
      {
        resourceId: resourceIds.BILLING_DATA_FIRM_ACCOUNTS,
        type: permissions[userId]?.[resourceIds.BILLING_DATA_FIRM_ACCOUNTS] ? policyTypes.ALLOW : policyTypes.DENY,
      },
      {
        resourceId: resourceIds.BILLING_DATA_FIRM_REPORTS,
        type: permissions[userId]?.[resourceIds.BILLING_DATA_FIRM_REPORTS] ? policyTypes.ALLOW : policyTypes.DENY,
      },
    ];
    return acc;
  }, {});

  return fetchPostP({
    path: savePermissionsEndpointPath,
    fetchOptions: {
      body: JSON.stringify(payload),
    },
  });
};

export const StaffPermissionsContainer = withApolloClient(
  composeHooks(hooks)(composeHooks(dependentHooks)(StaffPermissions)),
);

StaffPermissionsContainer.displayName = 'StaffPermissionsContainer';

export default StaffPermissionsContainer;
