export const buildDebtorCsvExportList = (debtorsWithPaymentPlans, t) => {
  const list = debtorsWithPaymentPlans.map((debtor) => {
    const newRecord = {}; // this is required to dynamically construct field names based on region
    newRecord.Name = debtor.contactDisplay || debtor.displayName || '';
    newRecord[t('organisation')] = debtor.organization || '';
    newRecord.Phone = debtor.phone || '';
    newRecord[t('mobile')] = debtor.cell || '';

    return {
      ...newRecord,
      Email: debtor.email || '',
      'Last Payment On': (debtor.lastPaymentOnYYYYMMDD && t('date', { yyyymmdd: debtor.lastPaymentOnYYYYMMDD })) || '',
      Paid: t('cents', { val: debtor.paid }),
      Outstanding: t('cents', { val: debtor.outstanding }),
      Status: debtor.status || '',
    };
  });

  return list;
};
