import { getDefaultCreditAccount, getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getMap as getState } from '@sb-billing/redux/bank-account-balances';
import {
  getStatusesFilter,
  getIssuedDateFilter,
  getBillingTypeFilter,
  getAttorneyResponsibleFilter,
} from '@sb-billing/redux/invoices';
import {
  getTypeId as getMatterTypeIdByMatterId,
  getStatus as getMatterStatusById,
} from '@sb-matter-management/redux/matters';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';

const { getMatterBalance } = selectors;

export const createInvoiceFilter = ({
  issueDate,
  billingTypes,
  attorneysResponsible,
  matterTypes,
  matterStatuses,
  minimumCreditBalance,
  minimumTrustBalance,
  minimumOperatingBalance,
  trustBankAccountId,
}) => {
  const filterFns = [getStatusesFilter(['DRAFT'])];
  const creditBankAccountId = getDefaultCreditAccount()?.id;
  const operatingBankAccountId = getOperatingAccount().id;

  if (issueDate) {
    filterFns.push(
      getIssuedDateFilter({
        filter: issueDate.filterType,
        startDate: issueDate.startDate,
        endDate: issueDate.endDate,
      }),
    );
  }

  if (billingTypes && !billingTypes.allSelected) {
    filterFns.push(getBillingTypeFilter(billingTypes.selections));
  }

  if (matterTypes && matterTypes.length) {
    const matterTypeLookup = new Set(matterTypes);
    filterFns.push((invoiceSummary) => {
      const matterTypeId = getMatterTypeIdByMatterId(invoiceSummary.matterId);
      return matterTypeLookup.has(matterTypeId);
    });
  }

  if (matterStatuses) {
    filterFns.push((invoiceSummary) => {
      const invoiceMatterStatus = getMatterStatusById(invoiceSummary.matterId);
      return invoiceMatterStatus !== 'Deleted' && matterStatuses.includes(invoiceMatterStatus);
    });
  }

  if (attorneysResponsible && attorneysResponsible.length > 0) {
    filterFns.push(getAttorneyResponsibleFilter(attorneysResponsible));
  }

  if (Number.isFinite(minimumCreditBalance)) {
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      const currentCreditBalance = getMatterBalance(getState(), {
        matterId: currentVersion.matterId,
        bankAccountId: creditBankAccountId,
      });
      return currentCreditBalance >= minimumCreditBalance;
    });
  }

  if (Number.isFinite(minimumTrustBalance)) {
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      const currentTrustBalance = trustBankAccountId
        ? getMatterBalance(getState(), {
            matterId: currentVersion.matterId,
            bankAccountId: trustBankAccountId,
          })
        : 0;
      return currentTrustBalance >= minimumTrustBalance;
    });
  }

  if (minimumOperatingBalance) {
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      const currentOperatingBalance = getMatterBalance(getState(), {
        matterId: currentVersion.matterId,
        bankAccountId: operatingBankAccountId,
      });
      return currentOperatingBalance >= minimumOperatingBalance;
    });
  }

  if (trustBankAccountId) {
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      const trustAccountsForMatter = filterTrustAccountsByMatter(currentVersion.matterId);
      return trustAccountsForMatter.some((ta) => ta.id === trustBankAccountId);
    });
  }

  return (invoiceSummary) => filterFns.every((filterFn) => filterFn(invoiceSummary));
};
