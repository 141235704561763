/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import classnames from 'classnames';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import {
  DatePicker,
  DurationPicker,
  SlidingToggle,
  MultiSelectDropdown,
  MultiSelectCreatable,
  FormLabel,
  TDropdownOption,
} from '@sb-itops/react';
import { MatterTypeahead } from '@sb-matter-management/react';
import { FormData } from '@sb-itops/redux/forms2/use-form';
import { MatterSummary, Staff, Task } from 'types';
import Styles from './AddTask.module.scss';

export interface IAddTaskProps {
  fields: FormData<Task>['formFields'];
  assignableStaff: Array<Staff>;
  existingCategories: string[];
  assignableMatters: Array<MatterSummary>;
  allowMatterSwitching?: boolean;
  loading?: boolean;
  submitFailed?: boolean;
  onFieldValueUpdated: FormData<Task>['onFieldValueSet'];
}

// If we need to reuse this anywhere we should create a new component for it
const emailRegex = /((?:\n|.)*?)(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)/g;
const urlRegex = /((?:\n|.)*?)(((https?|ftp):\/\/)?[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?)/g;
const linkRegex = /((?:\n|.)*?)(\[[^\]]+\]\([^)]+\))/g;
const beginsWithHTTPRegex = /^((https?|ftp):\/\/)/g;

const absoluteLink = (url: string) => (url.match(beginsWithHTTPRegex) ? url : `https://${url}`);

const linkerGen = (input: string) => {
  let output: any[] = [input];
  output = output.reduce((acc, curr, i) => {
    if (typeof curr === 'string') {
      let index = 0;
      for (const m of curr.matchAll(emailRegex)) {
        acc.push(m[1]);
        acc.push(
          <a title={m[2]} key={`${i}email${m.index}`} target="_blank" href={`mailto:${m[2]}`} rel="noreferrer">
            {m[2]}
          </a>,
        );
        index = (m.index || 0) + m[0].length;
      }
      acc.push(curr.slice(index, curr.length));
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
  output = output.reduce((acc, curr, i) => {
    if (typeof curr === 'string') {
      const urlSubRegex = /(?<=\()[^)]+(?=\))/;
      const nameSubRegex = /(?<=\[)[^\]]+(?=\])/;
      let index = 0;
      for (const m of curr.matchAll(linkRegex)) {
        const url = absoluteLink(urlSubRegex.exec(m[2])?.[0] || m[2]);
        const name = nameSubRegex.exec(m[2])?.[0] || m[2];
        acc.push(m[1]);
        acc.push(
          <a title={m[2]} key={`${i}link${m.index}`} target="_blank" href={url} rel="noreferrer">
            {name}
          </a>,
        );
        index = (m.index || 0) + m[0].length;
      }
      acc.push(curr.slice(index, curr.length));
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
  output = output.reduce((acc, curr, i) => {
    if (typeof curr === 'string') {
      let index = 0;
      for (const m of curr.matchAll(urlRegex)) {
        acc.push(m[1]);
        const value = m[2].length > 100 ? `${m[2].slice(0, 50)}...` : m[2];
        acc.push(
          <a title={m[2]} key={`${i}url${m.index}`} target="_blank" href={absoluteLink(m[2])} rel="noreferrer">
            {value}
          </a>,
        );
        index = (m.index || 0) + m[0].length;
      }
      acc.push(curr.slice(index, curr.length));
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  return output;
};

export const AddTask = ({
  assignableStaff,
  assignableMatters,
  existingCategories,
  allowMatterSwitching,
  loading,
  onFieldValueUpdated,
  submitFailed,
  fields,
}: IAddTaskProps) => {
  const { subject, matterId, note, duration, durationType, assigneeIds, dueDate, workflowId, isCompleted, categories } =
    fields;

  const [showEditError, setShowEditError] = useState(false);

  return (
    <div className={Styles.addTask}>
      <fieldset className={Styles.formFieldset} disabled={loading}>
        <div className={classnames('form-group', subject?.isInvalid && submitFailed && 'has-error')}>
          <FormLabel label="Name" field={subject} submitFailed={submitFailed} />
          <input
            className="form-control"
            // Autofocus is desirable for modals so this eslint warning can be ignored
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            value={subject.value}
            onChange={(e) => onFieldValueUpdated('subject', e.target.value)}
          />
        </div>

        <div className="form-group">
          <FormLabel label="Matter" field={matterId} optional />
          {/* Dumb workaround for mattertypeahead which cant be reset, this will reset it after the form is submitted so save and new works */}
          {loading ? (
            <MatterTypeahead
              key={1}
              matterSummaries={assignableMatters}
              onValueChange={(newMatter) => onFieldValueUpdated('matterId', newMatter ? newMatter.id : '')}
              onClear={() => onFieldValueUpdated('matterId', '')}
              initialId={matterId?.value}
              filter="all"
              disabled={!allowMatterSwitching}
              hasError={matterId && matterId.isInvalid}
            />
          ) : (
            <MatterTypeahead
              key={2}
              matterSummaries={assignableMatters}
              onValueChange={(newMatter) => onFieldValueUpdated('matterId', newMatter ? newMatter.id : '')}
              onClear={() => onFieldValueUpdated('matterId', '')}
              initialId={matterId?.value}
              filter="all"
              disabled={!allowMatterSwitching}
              hasError={matterId && matterId.isInvalid}
            />
          )}
        </div>

        <div className={Styles.flexColumnContainer}>
          <div className={classnames('form-group', Styles.flexColumn)}>
            <FormLabel label="Due" field={dueDate} optional />
            <DatePicker
              onSelect={(updatedDueDate) => {
                onFieldValueUpdated('dueDate', updatedDueDate && dateToInteger(updatedDueDate));
              }}
              disabled={!!workflowId}
              value={dueDate.value && integerToDate(dueDate.value)}
              hasError={dueDate && dueDate.isInvalid}
              format="DD/MM/YYYY"
            />
          </div>
          <div className={Styles.spacer} />
          <div className={classnames('form-group', Styles.flexColumn)}>
            <label className={Styles.completedLabel}>Completed?</label>
            <div className={Styles.slider}>
              <SlidingToggle
                scope="task-isCompleted"
                name="taskIsCompleted"
                selected={isCompleted.value}
                onChange={(label, value) => onFieldValueUpdated('isCompleted', value)}
              />
            </div>
          </div>

          {workflowId && (
            <>
              <div className={Styles.spacer} />
              <div className={classnames('form-group', Styles.flexColumn)}>
                <label>Duration</label>
                <DurationPicker
                  duration={duration.value || ''}
                  durationType={durationType?.value}
                  allowFixed={false}
                  durationDisabled
                  durationTypeDisabled
                  onDurationChanged={(newDuration) => onFieldValueUpdated('duration', newDuration)}
                  onDurationTypeChanged={(newDurationType) => onFieldValueUpdated('durationType', newDurationType)}
                />
              </div>
            </>
          )}
        </div>
        <div
          className="form-group"
          onClick={() => {
            if (workflowId) {
              setShowEditError(true);
            }
          }}
        >
          <FormLabel label="Assigned To" optional />
          <MultiSelectDropdown
            options={assignableStaff.map((staff) => ({ label: staff.name, value: staff.id, searchText: staff.name }))}
            onSelectedIdsChanged={(selectedStaffIds) => {
              onFieldValueUpdated(
                'assigneeIds',
                selectedStaffIds.map((item) => item.value),
              );
            }}
            value={assigneeIds.map((assignedStaffId) => assignedStaffId.value)}
          />
        </div>
        {!!workflowId && showEditError && (
          <div className={Styles.editError}>
            Unable to edit the due date or assignee as this task is linked to a workflow. Please login to the desktop
            app to manage workflow tasks
          </div>
        )}

        <div
          className="form-group"
          onClick={() => {
            if (workflowId) {
              setShowEditError(true);
            }
          }}
        >
          <FormLabel label="Categories" optional />
          <MultiSelectCreatable
            options={[
              ...existingCategories.map((c) => ({ label: c, value: c, searchText: c })),
              ...categories.map(({ value }) => ({ value, label: value, searchText: value })),
            ]}
            onSelectedIdsChanged={(selectedCats) => {
              onFieldValueUpdated(
                'categories',
                (selectedCats as TDropdownOption[]).map((item) => item.value),
              );
            }}
            value={categories.map((cat) => cat.value)}
          />
        </div>

        <div className="form-group">
          <label>Details</label>
          <div className={classnames(Styles.textAreaControl)}>
            <textarea
              className={classnames('form-control', Styles.textArea)}
              value={note.value || ''}
              onChange={(e) => onFieldValueUpdated('note', e.target.value)}
              rows={5}
            />
            <div onClick={() => {}} className={classnames(Styles.linker, 'form-control')}>
              {linkerGen(note.value || '')}
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};
