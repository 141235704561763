import uuid from '@sb-itops/uuid';
import { sort } from '@sb-itops/sort';
import { getMatterContactBalances, getBankAccountMap } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { allocators } from '@sb-billing/allocation';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';

const { targetsFromSources: allocateTargetsFromSources } = allocators;

function getContactBalancesForMatter(matterId, bankAccountId) {
  // we are only interested in contact balances from the trust account
  const contactBalances = getMatterContactBalances(getBankAccountMap(), {
    matterId,
    bankAccountId,
  });
  return sort(contactBalances, [balanceTypes.AVAILABLE], ['ASC']);
}

function allocateContactBalancePayments(paymentsForEachMatter, bankAccountId) {
  // paymentsForEachMatter is an array of items for each matter with the following shape
  //   {
  //     matterId: 'baa0a463-ebec-444c-94f1-6bd077546729',
  //     invoices: [
  //       {
  //         invoiceId: '577ea959-8477-4dd6-9160-58125f16b4cd',
  //         amount: 17600,
  //       },
  //       {
  //         invoiceId: '44992a87-179d-416b-af0a-bcaaba8a2c68',
  //         amount: 16500,
  //       },
  //       {
  //         invoiceId: '75b2bde3-2260-46c4-85b9-a41be0a6b2b7',
  //         amount: 2200,
  //       },
  //     ],
  //   },
  //

  const paymentsGroupedByMatterAndPayor = {};
  paymentsForEachMatter.forEach((paymentsForMatter) => {
    const matterId = paymentsForMatter.matterId;

    // For each matter gather all contact balances for the matter, ordered by
    // contact with the lowest balance first
    const contactBalancesForMatter = getContactBalancesForMatter(matterId, bankAccountId);

    // allocate balance for each invoice from contact, start with lowest balance first
    allocateTargetsFromSources({
      targets: paymentsForMatter.invoices.map(({ invoiceId, amount }) => ({
        targetId: invoiceId,
        amount: amount || 0,
      })),
      sources: contactBalancesForMatter.map((contactBalance) => ({
        sourceId: contactBalance.contactId,
        amount: contactBalance[[balanceTypes.AVAILABLE]],
      })),
      zeroAllocate: false,
      accumulate: (allocation) => {
        // allocation has the following shape
        // {
        //   sourceId,
        //   remainingOnTarget,
        //   remainingOnSource,
        //   targetId,
        //   amount: amountToAllocateFromSource,
        // };

        // group by matter/payor
        const payorId = allocation.sourceId;
        const invoiceId = allocation.targetId;
        const amount = allocation.amount;
        const matterAndPayorKey = `${matterId}|${payorId}`;
        const matterPayorGroup = paymentsGroupedByMatterAndPayor[matterAndPayorKey];
        if (matterPayorGroup) {
          // add invoice to existing matter/payor group
          matterPayorGroup.invoices.push({
            invoiceId,
            amount,
          });
        } else {
          // create new matter/payor group
          paymentsGroupedByMatterAndPayor[matterAndPayorKey] = {
            paymentId: uuid(),
            transferBetweenAccountsTransactionId: uuid(),
            matterId,
            payorId,
            invoices: [
              {
                invoiceId,
                amount,
              },
            ],
          };
        }
      },
    });
  }, []);

  // return array of payments grouped by matter and payor
  return Object.values(paymentsGroupedByMatterAndPayor);
}

export { allocateContactBalancePayments };
