import React from 'react';
import PropTypes from 'prop-types';
import RemindersGeneralFilterDisplay from './RemindersGeneralFilterDisplay';

class RemindersGeneralFilter extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    overdueAmountChecked: !!this.props.overdueAmount,
    overdueAmount: this.props.overdueAmount || undefined,
  };

  onFilterChanged = ({ value, field }) => {
    this.setState(
      (state) => ({
        ...state,
        [field]: value,
      }),
      () => {
        if (field === 'overdueAmountChecked') {
          this.props.onFilterChanged({
            value: value === false ? undefined : this.state.overdueAmount,
            field: 'overdueAmount',
          });
        } else if (field === 'overdueAmount' && this.state.overdueAmountChecked === true) {
          this.props.onFilterChanged({ value, field });
        }
      },
    );
  };

  propagateChanges = (newValue) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...newValue,
      }),
      () => {
        this.props.onFilterChanged({
          value: this.state.overdueAmountChecked ? this.state.overdueAmount : undefined,
          field: 'overdueAmount',
        });
      },
    );
  };

  // this is only going to be exposed to the parent component
  // eslint-disable-next-line react/no-unused-class-component-methods
  reset = () =>
    this.propagateChanges({
      overdueAmountChecked: false,
      overdueAmount: undefined,
    });

  render() {
    return <RemindersGeneralFilterDisplay {...this.props} {...this.state} onFilterChanged={this.onFilterChanged} />;
  }
}

RemindersGeneralFilter.propTypes = {
  overdueAmount: PropTypes.number,
  onFilterChanged: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

RemindersGeneralFilter.defaultProps = {
  overdueAmount: undefined,
};

export default RemindersGeneralFilter;
