import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typeahead, Menu, MenuItem, Highlighter, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import Styles from './EditableTypeahead.module.scss';

export const EditableTypeahead = ({
  id,
  inputValue,
  options,
  selectedOption,
  labelKey,
  menuClassName,
  disabled,
  hasError,
  onValueChange,
  onOptionSelected,
}) => {
  const typeaheadRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const selectedOptionId = selectedOption && selectedOption[labelKey];

  // This useEffect maintains the typeahead input cursor position in a predictable
  // place. Without it the cursor ends up at the last position after each render.
  useEffect(() => {
    const input = typeaheadRef.current;
    if (input) {
      // @ts-ignore
      input.getInput().setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [typeaheadRef, cursorPosition, inputValue, selectedOptionId]);

  const renderInput = (inputProps) => (
    <TypeaheadInputSingle
      {...inputProps}
      onChange={(event) => {
        // @ts-ignore
        setCursorPosition(event.target.selectionStart);
        inputProps.onChange(event);
      }}
      value={inputValue}
    />
  );

  const renderMenu = (results, menuProps) => {
    // @ts-ignore
    // const inputValue = typeaheadRef.current?.getInput().value;
    // @ts-ignore
    const activeItem = typeaheadRef.current?.state?.activeItem;

    // Don't show the options dropdown if the typed in string has no matches.
    // Avoids the stupid "no options found" type messaging.
    if (inputValue?.length && results.length === 0) {
      return <div />;
    }

    // The example docs suggest spreading all menu props into the menu components below.
    // However, react throws warnings because they end up getting applied to low level DOM
    // elements by mistake. Clearing the offending props out doesn't do any harm toward our
    // purposes in this component.
    const unsafeMenuProps = new Set(['newSelectionPrefix', 'paginationText', 'renderMenuItemChildren']);
    const safeMenuProps = Object.entries(menuProps).reduce((acc, [propName, propValue]) => {
      if (!unsafeMenuProps.has(propName)) {
        acc[propName] = propValue;
      }
      return acc;
    }, {});

    // When the user is scrolling through the list of results using keyboard arrows,
    // ensure that only the currently active element is highlighted using the <Highlighter>.
    const searchText = activeItem ? activeItem[labelKey] : inputValue;

    // The custom menu component. This renders the dropdown box below the input box.
    return (
      <Menu className={classnames(Styles.menu, menuClassName)} {...safeMenuProps}>
        {results.map((result, index) => (
          <MenuItem
            key={index}
            className={classnames(Styles.item, activeItem === result && Styles.active)}
            option={result}
            position={index}
            href="0"
          >
            <Highlighter search={searchText}>{result[labelKey]}</Highlighter>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <div className={classnames(Styles.editableTypeahead, hasError && Styles.hasError)}>
      <Typeahead
        id={id}
        ref={typeaheadRef}
        align="left"
        options={options}
        labelKey={labelKey}
        disabled={disabled}
        renderInput={renderInput}
        renderMenu={renderMenu}
        selected={selectedOption ? [selectedOption] : []}
        onInputChange={(newValue) => onValueChange(newValue)}
        onChange={(selectedOptions) => {
          const selected = selectedOptions[0];
          onOptionSelected(selected);

          // If a new option is selected, position the cursor at the end.
          // Otherwise this onChange was triggered by typing additional text
          // into the input which changes the selected value to undefined.
          if (selected && selected[labelKey]) {
            setCursorPosition(selected[labelKey].length);
          }
        }}
        highlightOnlyResult
      />
    </div>
  );
};

EditableTypeahead.displayName = 'EditableTypeahead';

EditableTypeahead.propTypes = {
  id: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectedOption: PropTypes.object,
  labelKey: PropTypes.string, // A property in the options objects.
  menuClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onValueChange: PropTypes.func,
  onOptionSelected: PropTypes.func,
};

EditableTypeahead.defaultProps = {
  selectedOption: undefined,
  inputValue: undefined,
  labelKey: 'label',
  disabled: false,
  menuClassName: undefined,
  hasError: false,
  onValueChange: () => {},
  onOptionSelected: () => {},
};
