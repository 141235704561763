angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider

  // go here when you need to go to an invoice but don't know if it's final or draft
    .state('home.billing.goto-invoice', {
      url: '/invoice/:invoiceId/:checkId?/:bankAccountId?',
      params: {
        checkId: {squash: true, value: null},
        bankAccountId: {squash: true, value: null},
      },
      template: '<div></div>',
      controllerAs: '$ctrl',
      controller: function ($state, sbLoggerService, sbInvoicingService) {
        var log = sbLoggerService.getLogger('home.billing.goto-invoice');
        var invoice = sbInvoicingService.getInvoice($state.params.invoiceId);

        function doNav(invoice) {
          if (invoice.status === 'DRAFT') {
            $state.go('home.billing.create-bill', { matterId: invoice.matterId, invoiceId: invoice.invoiceId });
          } else {
            $state.go('home.billing.view-bill.pdf', { invoiceId: invoice.invoiceId, checkId: $state.params.checkId, bankAccountId: $state.params.bankAccountId });
          }
        }

        if (invoice) {
          doNav(invoice);
        } else {
          sbInvoicingService.getInvoiceP($state.params.invoiceId).then(function (invoice) {
            if (invoice) {
              doNav(invoice);
            } else {
              log.warn('can\'t find invoice: ', $state.params.invoiceId);
            }
          });
        }
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills']
      }
    });

});
