angular.module('sb.billing.webapp').controller('sbSettingsActivitiesDeleteController',function($state, $stateParams, sbBillingActivityService, sbLoggerService, sbMessageDisplayService){
  'use strict';
  var that = this,
    log = sbLoggerService.getLogger('sbSettingsActivitiesDeleteController');

  that.activityId = $stateParams.activityId;

  if (that.activityId) {
    const activities = sbBillingActivityService.getAllActivites();
    that.activity = _.find(activities, function (activity) { 
      return activity.activityId === that.activityId; 
    });
  }

  that.deleteActivityP = function () {
    if ($stateParams.activityId) {
      that.processing = true;

      return sbBillingActivityService.deleteActivityP($stateParams.activityId) // the activityId of the activity loaded in case it was edited
        .then(function () {
          $state.go('settings.activities.list');
        })
        .catch(function (e) {
          sbMessageDisplayService.error('Failed to delete activity. Please check your connection and try again.');
          log.warn('Problem deleting activity', e);
        });
    }
  };
});
