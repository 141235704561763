angular.module('sb.billing.webapp').component('sbAuiNone',
  {
    templateUrl: 'ng-composable-components/action-ui/none/aui-none.html',
    require: { group: '^sbComposeGroup' },
    bindings: { actionParams: '<?', actionBindContext:  '<?', actionBindName : '@', groupBindName:  '@' },
    controller: function () {
      const that = this;

      that.$onInit = () => {
        if (that.groupBindName) {
          that.actionBindContext[that.groupBindName] = that.group;
        }
        that.actionBindContext[that.actionBindName] = (actionData) => {
          // store any data passed in on the context for later use
          // like to pass to $sbModal.confirm(actionData)
          if (actionData) {
            // extend action data here to allow multiple modals to modify it in sequence
            that.actionBindContext.$actionData = {
              ...that.actionBindContext.$actionData,
              ...actionData,
            };
          }

          return that.group.doAction(that.actionParams);
        };
      };

    }
  }
);
