import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useClickOutside } from '../hooks';
import Styles from './DurationPicker.module.scss';

export const displayModes = Object.freeze({
  RESPONSIVE: 'RESPONSIVE',
  STANDARD: 'STANDARD',
  MINIMAL: 'MINIMAL',
});

const durationTypeButtonLabels = {
  FIXED: 'FIXED',
  HOURS: 'HRS',
  UNITS: 'UNITS',
};

export const DurationPicker = ({
  duration,
  durationType,
  allowFixed,
  allowHours,
  allowUnits,
  onDurationChanged,
  onDurationBlur,
  onDurationTypeChanged,
  durationDisabled,
  durationHasError,
  durationTypeDisabled,
  clearFormGroupStyle,
  displayMode,
  className,
}) => {
  // Controls whether or not the minimal mode menu is currently being displayed.
  const [showMinimalModeMenu, setShowMinimalModeMenu] = useState(false);

  // For detecting clicks outside the minimal mode menu.
  const minimalModeMenuRef = useRef(null);
  useClickOutside(minimalModeMenuRef, () => setShowMinimalModeMenu(false));

  const handleMinimalModeMenuItemClicked = (itemType) => {
    // Don't trigger events if the already selected duration type is clicked.
    if (durationType === itemType) {
      return;
    }

    onDurationTypeChanged(itemType);
    setShowMinimalModeMenu(false);
  };

  const enableResponsiveness = displayMode === displayModes.RESPONSIVE;
  const enableStandardDisplay = displayMode === displayModes.RESPONSIVE || displayMode === displayModes.STANDARD;
  const enableMinimalDisplay = displayMode === displayModes.RESPONSIVE || displayMode === displayModes.MINIMAL;

  const getButtonClasses = (type) => {
    const activeOrInactiveClass = type === durationType ? Styles.active : Styles.inactive;
    return classnames(activeOrInactiveClass, durationTypeDisabled && Styles.disabled);
  };

  const isDurationTypeAllowed = (type) =>
    (type === 'FIXED' && allowFixed) || (type === 'HOURS' && allowHours) || (type === 'UNITS' && allowUnits);

  const onInputChange = (e) => {
    if (durationType === 'HOURS') {
      return onDurationChanged(e.target.value === '.' ? '0.' : e.target.value);
    }

    if (durationType === 'UNITS') {
      return onDurationChanged(`${parseInt(e.target.value, 10)}`);
    }

    return e.target.value;
  };

  return (
    <div className={classnames(Styles.durationPicker, className)}>
      <div
        className={classnames(
          !clearFormGroupStyle && 'form-group',
          Styles.durationInputContainer,
          enableMinimalDisplay && Styles.minimal,
        )}
      >
        <input
          type="text"
          className={classnames('form-control', Styles.durationInput, durationHasError && Styles.hasError)}
          value={duration}
          name="Duration"
          disabled={durationDisabled}
          onChange={onInputChange}
          onFocus={(e) => e.target.select()}
          onBlur={() => onDurationBlur()}
        />
      </div>

      {enableStandardDisplay && (
        <div
          className={classnames(
            Styles.standardButtonGroup,
            enableResponsiveness && Styles.responsiveStandardButtonGroup,
          )}
        >
          {allowFixed && (
            <button
              type="button"
              name="fixed"
              className={getButtonClasses('FIXED')}
              onClick={() => onDurationTypeChanged('FIXED')}
              disabled={durationTypeDisabled}
            >
              FIXED
            </button>
          )}
          {allowHours && (
            <button
              type="button"
              name="hours"
              className={getButtonClasses('HOURS')}
              onClick={() => onDurationTypeChanged('HOURS')}
              disabled={durationTypeDisabled}
            >
              HRS
            </button>
          )}
          {allowUnits && (
            <button
              type="button"
              name="units"
              className={getButtonClasses('UNITS')}
              onClick={() => onDurationTypeChanged('UNITS')}
              disabled={durationTypeDisabled}
            >
              UNITS
            </button>
          )}
        </div>
      )}

      {enableMinimalDisplay && (
        <div
          className={classnames(Styles.minimalButtonGroup, enableResponsiveness && Styles.responsiveMinimalButtonGroup)}
        >
          <button
            type="button"
            className={classnames(Styles.active, Styles.dropdownButton, durationTypeDisabled && Styles.disabled)}
            onClick={() => setShowMinimalModeMenu(!showMinimalModeMenu)}
          >
            <span>{durationTypeButtonLabels[durationType]}</span>
            {!durationTypeDisabled && <i className="icon icon-arrow-58" />}
          </button>
          {/* Dropdown list selected */}
          {showMinimalModeMenu && !durationTypeDisabled && (
            <div className={Styles.menu} ref={minimalModeMenuRef}>
              {Object.entries(durationTypeButtonLabels)
                .filter(([itemType]) => isDurationTypeAllowed(itemType))
                .map(([itemType, itemLabel]) => (
                  <div
                    key={itemType}
                    className={classnames(Styles.item, durationType === itemType && Styles.active)}
                    onClick={() => handleMinimalModeMenuItemClicked(itemType)}
                  >
                    {itemLabel}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DurationPicker.displayName = 'DurationPicker';

DurationPicker.propTypes = {
  duration: PropTypes.string,
  durationType: PropTypes.oneOf(['HOURS', 'UNITS', 'FIXED', '']),
  allowFixed: PropTypes.bool,
  allowHours: PropTypes.bool,
  allowUnits: PropTypes.bool,
  onDurationChanged: PropTypes.func.isRequired,
  onDurationBlur: PropTypes.func,
  onDurationTypeChanged: PropTypes.func.isRequired,
  durationDisabled: PropTypes.bool,
  durationHasError: PropTypes.bool,
  durationTypeDisabled: PropTypes.bool,
  className: PropTypes.string,
  clearFormGroupStyle: PropTypes.bool,
  displayMode: PropTypes.oneOf(Object.values(displayModes)),
};

DurationPicker.defaultProps = {
  duration: undefined,
  durationType: undefined,
  allowFixed: true,
  allowHours: true,
  allowUnits: true,
  durationDisabled: false,
  durationHasError: false,
  durationTypeDisabled: false,
  clearFormGroupStyle: false,
  className: undefined,
  displayMode: displayModes.RESPONSIVE,
  onDurationBlur: () => {},
};
