import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from '@sb-itops/react/typeahead';
import { useTranslation } from '@sb-itops/react';
import { groupMattersListSummaries, findInOptions } from './helpers/data';
import { groupFormatHandler, labelFormatHandler, noMattersToDisplayHandler, formatDateItem } from './helpers/ui';

export const MatterTypeaheadFilters = {
  all: 'all',
  editable: 'editable',
  open: 'open',
  default: 'default',
};

const matterWithOutstandingTotal = ({ matterTotals = {}, hasFunds }) =>
  matterTotals.unbilled > 0 || matterTotals.unpaid > 0 || hasFunds;

const isSelectedMatter = ({ id }, initialId) => id === initialId;

// this is performance enhancement so we do not have to lower case in each search iteration.
const toLowerCaseMatterTypeaheadOption = ({ matterTypeaheadOption, t }) => {
  const matterClientNamesLowerCase = (
    matterTypeaheadOption.clientDisplay ||
    matterTypeaheadOption.matterClientNames ||
    ''
  ).toLowerCase();

  return {
    ...matterTypeaheadOption,
    displayLowerCase: matterTypeaheadOption.display.toLowerCase(),
    typeaheadLowerCase: matterTypeaheadOption.typeahead?.toLowerCase(),
    matterClientNamesLowerCase,
    matterStartedFormatted: formatDateItem({ date: matterTypeaheadOption.matterStarted, t }),
  };
};

const MatterTypeahead = memo((props) => {
  const { t } = useTranslation();
  // get the data based on the filter
  const matterTypeaheadData = useMemo(() => {
    if (props.filter === MatterTypeaheadFilters.all) {
      return props.matterSummaries.map((m) => toLowerCaseMatterTypeaheadOption({ matterTypeaheadOption: m, t }));
    }
    if (props.filter === MatterTypeaheadFilters.editable) {
      return props.matterSummaries.reduce((acc, m) => {
        if (
          isSelectedMatter(m, props.initialId) ||
          m.status === 'Open' ||
          (m.status === 'Pending' && matterWithOutstandingTotal(m)) ||
          (m.status === 'Closed' && matterWithOutstandingTotal(m))
        ) {
          acc.push(toLowerCaseMatterTypeaheadOption({ matterTypeaheadOption: m, t }));
        }
        return acc;
      }, []);
    }
    if (props.filter === MatterTypeaheadFilters.open) {
      return props.matterSummaries.reduce((acc, m) => {
        if (isSelectedMatter(m, props.initialId) || m.status === 'Open') {
          acc.push(toLowerCaseMatterTypeaheadOption({ matterTypeaheadOption: m, t }));
        }
        return acc;
      }, []);
    }
    return props.matterSummaries.reduce((acc, m) => {
      if (isSelectedMatter(m, props.initialId) || m.status === 'Open' || m.status === 'Pending') {
        acc.push(toLowerCaseMatterTypeaheadOption({ matterTypeaheadOption: m, t }));
      }
      return acc;
    }, []);
  }, [props.filter, props.matterSummaries, props.initialId, t]);

  // transform the filtered matter summaries to be a grouped list.
  const options = useMemo(() => groupMattersListSummaries(matterTypeaheadData), [matterTypeaheadData]);

  const filterMatterTypeaheadOptionHandler = ({ data }, search) => {
    if (!search) {
      return true;
    }

    const searchLowerCase = search.toLowerCase();
    return (
      data &&
      (data.typeaheadLowerCase?.includes(searchLowerCase) ||
        data.displayLowerCase?.includes(searchLowerCase) ||
        data.matterClientNamesLowerCase?.includes(searchLowerCase) ||
        data.matterStartedFormatted?.includes(searchLowerCase))
    );
  };

  return (
    <Typeahead
      {...props}
      filterOption={filterMatterTypeaheadOptionHandler}
      options={options}
      className={`matter-typeahead ${props.className || ''}`}
      formatOptionLabel={labelFormatHandler}
      formatGroupLabel={groupFormatHandler}
      optionLabelHeight={70}
      groupLabelHeight={39}
      placeholder="Select a matter..."
      findInOptions={findInOptions}
      noItemsToDisplayIndicator={noMattersToDisplayHandler}
      listItemClassName="matter-typeahead-group-list-item"
      onValueChange={props.onValueChange}
    />
  );
});

MatterTypeahead.displayName = 'MatterTypeahead';

MatterTypeahead.propTypes = {
  initialId: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  onClear: PropTypes.func,
  groupBy: PropTypes.func,
  matterSummaries: PropTypes.array,
  groupByLabel: PropTypes.string,
  groupByOptions: PropTypes.string,
  filter: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.bool,
};

MatterTypeahead.defaultProps = {
  initialId: undefined,
  placeholder: undefined,
  name: undefined,
  onValueChange: () => {},
  onClear: undefined,
  groupBy: undefined,
  matterSummaries: [],
  groupByLabel: undefined,
  groupByOptions: undefined,
  filter: undefined,
  className: undefined,
  hasError: false,
};

export default MatterTypeahead;
