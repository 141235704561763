import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  buttonTypes,
  CurrencyInput2,
  StatelessSelect,
  forms2PropTypes,
  Icon,
  TextEditor,
  SlidingToggle,
} from '@sb-itops/react';
import { Spinner } from '@sb-itops/react/spinner';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import Styles from './DepositRequestForm.module.scss';

const { Forms2Field } = forms2PropTypes;

export const DepositRequestForm = React.memo(
  ({
    bankAccountOptions,
    minAmountAllowed,
    showReasonField,
    isPreviewMode,
    previewSubject,
    previewMessage,
    // fields
    bankAccountId,
    amount,
    to,
    cc,
    bcc,
    from,
    subject,
    message,
    reason,
    sendCopyToUser,
    staffAddress,
    // form and callbacks
    onUpdateFieldValues,
    formInitialised,
    submitFailed,
    formDisabled,
    onPreviewToggle,
    onOpenEditContactModal,
  }) => {
    if (!formInitialised) {
      return <Spinner />;
    }

    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
    const handleFieldChange = (fieldName) => (e) => {
      onUpdateFieldValues(fieldName, e.target.value);
    };
    const onChangeMessage = (value, delta, source) => {
      if (source !== 'user') {
        return;
      }
      onUpdateFieldValues('message', value);
    };

    return (
      <div className={Styles.depositRequestForm}>
        <fieldset disabled={formDisabled}>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Account</label>
              <StatelessSelect
                selectedOption={bankAccountId.value}
                options={bankAccountOptions}
                onChange={(option) => {
                  onUpdateFieldValues(bankAccountId.key, option?.value);
                }}
                disabled={formDisabled}
              />
            </div>

            <div className="col-sm-6 form-group">
              <label>Amount</label>
              <CurrencyInput2
                hasError={!!isErrorClassnames(amount)}
                name="amount"
                value={amount.value}
                onChange={handleFieldChange('amount')}
                min={minAmountAllowed}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 form-group">
              <label>To</label>
              <input
                className={classnames('form-control', isErrorClassnames(to))}
                type="text"
                value={to.value || ''}
                onChange={handleFieldChange('to')}
              />
              {isErrorClassnames(to) && <div className={Styles.errorMessage}>{to?.invalidReason}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>CC</label>
              <input
                className={classnames('form-control', isErrorClassnames(cc))}
                type="text"
                value={cc.value || ''}
                onChange={handleFieldChange('cc')}
              />
              {isErrorClassnames(cc) && <div className={Styles.errorMessage}>{cc?.invalidReason}</div>}
            </div>

            <div className="col-sm-6 form-group">
              <label>BCC</label>
              <input
                className={classnames('form-control', isErrorClassnames(bcc))}
                type="text"
                value={bcc.value || ''}
                onChange={handleFieldChange('bcc')}
              />
              {isErrorClassnames(bcc) && <div className={Styles.errorMessage}>{bcc?.invalidReason}</div>}
            </div>
          </div>

          <div className={classnames('form-group', Styles.formGroupInline)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={Styles.sendCopyLabel}
              htmlFor="sendCopySlider"
              onClick={(e) => {
                e.preventDefault();
                onUpdateFieldValues('sendCopyToUser', !sendCopyToUser.value);
              }}
            >
              <SlidingToggle
                id="sendCopySlider"
                scope="sendCopySlider"
                selected={sendCopyToUser.value}
                disabled={formDisabled}
              />
              <div>Send a copy of the email to me ({staffAddress.value})</div>
            </label>
          </div>

          <div className="row">
            <div className="col-lg-12 form-group">
              <label>From</label>
              <input
                className={classnames('form-control', isErrorClassnames(from))}
                type="text"
                value={from.value || ''}
                onChange={handleFieldChange('from')}
              />
              {isErrorClassnames(from) && <div className={Styles.errorMessage}>{from?.invalidReason}</div>}
            </div>
          </div>
          <div className="row">
            <div className={classnames('form-group', 'col-lg-12', subject.isInvalid ? Styles.withInlineError : '')}>
              <label>Subject</label>
              <input
                className={classnames('form-control', subject.isInvalid ? Styles.hasError : '')}
                type="text"
                value={isPreviewMode ? previewSubject : subject.value}
                disabled={isPreviewMode}
                onChange={handleFieldChange('subject')}
              />
              {subject && subject.isInvalid && (
                <div className={classnames('col-lg-12', Styles.errorMessage, Styles.errorMsgContainer)}>
                  {subject.invalidReason &&
                    typeof subject.invalidReason === 'string' &&
                    subject.invalidReason
                      .split('\n')
                      .map((individualReason) => (
                        <InterpolatedDescription
                          key={individualReason}
                          description={individualReason}
                          deleted={false}
                          onClickLink={() => {}}
                          isError
                          onClickDisplay={onOpenEditContactModal}
                        />
                      ))}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className={classnames('form-group', 'col-lg-12', Styles.textEditor)}>
              <label>Message</label>

              <TextEditor
                className={isErrorClassnames(message)}
                value={isPreviewMode ? previewMessage : message.value}
                disabled={isPreviewMode}
                onChange={onChangeMessage}
              />

              <div className={Styles.textEditorButtonPanel} title={isPreviewMode ? `Edit Email` : `Preview Email`}>
                <Button className={Styles.previewButton} type={buttonTypes.secondary} onClick={onPreviewToggle}>
                  {isPreviewMode ? `Edit Email` : `Preview Email`}
                </Button>
              </div>
            </div>
          </div>
          {showReasonField && (
            <div className="row">
              <div className="col-lg-12 form-group">
                <label>
                  Reason{' '}
                  <Icon
                    type="question-mark"
                    tooltip="This reason will be displayed on Trust Receipt and Trust ledger"
                  />
                </label>
                <textarea
                  className={classnames('form-control', isErrorClassnames(reason))}
                  rows="5"
                  maxLength="256"
                  onFocus={(e) => e.target.select()}
                  value={reason.value || ''}
                  onChange={handleFieldChange('reason')}
                />
              </div>
            </div>
          )}
        </fieldset>
      </div>
    );
  },
);

DepositRequestForm.displayName = 'DepositRequestForm';

DepositRequestForm.propTypes = {
  bankAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  showReasonField: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  previewSubject: PropTypes.string.isRequired,
  previewMessage: PropTypes.string.isRequired,
  minAmountAllowed: PropTypes.number,
  // form fields
  bankAccountId: Forms2Field,
  amount: Forms2Field,
  to: Forms2Field,
  cc: Forms2Field,
  bcc: Forms2Field,
  from: Forms2Field,
  subject: Forms2Field,
  message: Forms2Field,
  reason: Forms2Field,
  sendCopyToUser: Forms2Field,
  staffAddress: Forms2Field,
  // form & callbacks
  onUpdateFieldValues: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool,
  submitFailed: PropTypes.bool,
  formDisabled: PropTypes.bool,
  onPreviewToggle: PropTypes.func.isRequired,
  onOpenEditContactModal: PropTypes.func.isRequired,
};

DepositRequestForm.defaultProps = {
  bankAccountOptions: [],
  minAmountAllowed: 0,
  // form fields
  bankAccountId: undefined,
  amount: undefined,
  to: undefined,
  cc: undefined,
  bcc: undefined,
  from: undefined,
  subject: undefined,
  message: undefined,
  reason: undefined,
  sendCopyToUser: undefined,
  staffAddress: undefined,
  // form & callbacks
  formInitialised: false,
  submitFailed: false,
  formDisabled: false,
};
