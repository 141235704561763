import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import * as evergreenRequestsFilters from 'web/redux/route/home-billing-accounts-evergreen-requests';
import PropTypes from 'prop-types';
import EvergreenRequestTable from './EvergreenRequestTable';

const mapStateToProps = (state) => {
  const { getState } = evergreenRequestsFilters.selectors;
  const { requests, sortBy, sortDirection, selectedRequests, summary } = getState(state);
  return { requests, sortBy, sortDirection, selectedRequests, summary };
};

const mapDispatchToProps = (dispatch) => ({
  onResetFilters: () => dispatch(evergreenRequestsFilters.actions.onResetFilters()),
  onChangeFilter: (key, value) => dispatch(evergreenRequestsFilters.actions.onChangeFilter(key, value)),
  onSelectRequest: (value) => dispatch(evergreenRequestsFilters.actions.onSelectRequest(value)),
  onSelectAllRequests: (value) => dispatch(evergreenRequestsFilters.actions.onSelectAllRequests(value)),
  onShowIfReplenishAmountGreaterThanAmount: (value) =>
    dispatch(evergreenRequestsFilters.actions.onChangeFilter('showIfReplenishAmountGreaterThanAmount', value)),
});

const EvergreenRequestTableContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(EvergreenRequestTable),
);

EvergreenRequestTableContainer.propTypes = {
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  sort: PropTypes.func,
  emailRequests: PropTypes.func,
};

EvergreenRequestTableContainer.defaultProps = {
  onClickLink: () => {},
  onClickRow: () => {},
  sort: () => {},
  emailRequests: () => {},
};

export default EvergreenRequestTableContainer;
