'use strict';

angular.module('@sb-billing/services').service('sbBankReconciliationUnsavedChangesService', function (sbUnsavedChangesService) {
  const that = this;

  const UNSAVED_CHANGES_TAB_LABEL_KEY = 'sbBankRecon-tab-label';
  const UNSAVED_CHANGES_KEY = 'sbBankReconController-selected-transactions';

  that.clearUnsavedChanges = clearUnsavedChanges;
  that.UNSAVED_CHANGES_TAB_LABEL_KEY = UNSAVED_CHANGES_TAB_LABEL_KEY;
  that.UNSAVED_CHANGES_KEY = UNSAVED_CHANGES_KEY;

  function clearUnsavedChanges() {
    sbUnsavedChangesService.saveMemory(UNSAVED_CHANGES_KEY);
    sbUnsavedChangesService.saveMemory(UNSAVED_CHANGES_TAB_LABEL_KEY);
  }
});
