import { gql } from '@apollo/client';

const query = gql`
  query PaymentProviderRefund($id: ID!, $providerType: String!) {
    paymentProviderRefund(id: $id, providerType: $providerType) {
      id
      requestedTimestamp
    }
  }
`;

const notificationIds = ['PaymentProviderRefundUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const PaymentProviderRefund = {
  query,
  notificationIds,
};
