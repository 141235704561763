angular.module('@sb-itops/services').service('sbDispatcherService', function(sbLoggerService) {
  'use strict';

  const callbacks = {};
  const log = sbLoggerService.getLogger('sbDispatcherService');
  const actionListeners = {};

  //log.setLogLevel('debug');

  this.register = register;
  this.deregister = deregister;
  this.registerSyncAction = registerSyncAction;
  this.unregisterSyncAction = unregisterSyncAction;
  this.dispatch = dispatch;
  this.dispatchSyncAction = dispatchSyncAction;

  function register(who, cb) {
    if (callbacks[who]) {
      log.warn('Ignoring attempt to register with already registered id', who);
      return;
    }

    if (who && _.isString(who) && _.isFunction(cb)) {
      callbacks[who] = cb;
      log.info('registered', who);
    } else {
      throw new Error('invalid call to register');
    }
  }

  function deregister(who) {
    if (!callbacks[who]) {
      log.warn('Ignoring attempt to deregister already deregistered id', who);
      return;
    }

    delete callbacks[who];
  }

  function dispatch(message) {
    if (!message.action) {
      log.warn('received message without action, ignoring:', message);
      return;
    }
    
    log.info('dispatching message', message);

    Object.entries(callbacks).forEach(([who, callback]) => {
      try {
        log.debug('dispatching to', who);
        callback(message);
      } catch (e) {
        log.warn(who, 'callback threw an error: ', e);
      }
    });
  }

  function registerSyncAction(provider, who, cb, filter) {
    if (provider && _.isString(provider) && who && _.isString(who) && _.isFunction(cb)) {
      if (!actionListeners[provider]) {
        actionListeners[provider] = [];
      }

      actionListeners[provider].push({who, callback: cb, filter});
      log.info(`registered ${who} for provider ${provider}`);
    } else {
      throw new Error('invalid call to registerSyncAction');
    }
  }

  function unregisterSyncAction(provider, who) {
    if (provider && _.isString(provider) && who && _.isString(who)) {
      _.remove(actionListeners[provider], consumer => {
        return consumer.who === who;
      });
      log.info(`unregister Sync consumer ${who} for provider ${provider}`);
    } else {
      throw new Error('invalid call to unregisterSyncAction');
    }
  }

  function dispatchSyncAction(message) {
    if (message.action) {
      log.info('dispatching message', message);
      let providerConsumers = actionListeners[message.provider] || [];
      for (let value of providerConsumers) {
        if (value.filter && value.filter(message.payload)) {
          value.callback(message);
        }
      }
    } else {
      log.warn(`received message without action: ${message}`);
    }
  }

});
