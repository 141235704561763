import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTippy } from 'react-tippy';

const Tooltip = ({ children, isOpen, html, onRequestClose, arrow, position, interactive, title, delay, className }) => (
  <ReactTippy
    className={className}
    html={html}
    animateFill={false}
    theme="light"
    arrow={arrow}
    interactive={interactive}
    open={isOpen}
    onRequestClose={onRequestClose}
    position={position}
    title={title}
    delay={delay}
    popperOptions={{
      modifiers: {
        preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' },
      },
    }}
  >
    {children}
  </ReactTippy>
);

Tooltip.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  html: PropTypes.node,
  onRequestClose: PropTypes.func,
  arrow: PropTypes.bool,
  interactive: PropTypes.bool,
  position: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  delay: PropTypes.number,
};

Tooltip.defaultProps = {
  arrow: false,
  interactive: true,
  position: undefined,
  html: undefined,
  title: undefined,
  delay: undefined,
  isOpen: undefined,
  className: undefined,
  onRequestClose: undefined,
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
