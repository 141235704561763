import {
  SET_CALENDAR_VIEW,
  TOGGLE_EXPANDED,
  SET_SELECTED_VIEW,
  CalendarViewTypes,
  SET_SORT,
  SET_STAFF_FILTER,
  TOGGLE_GROUP_HEADER_EXPANDED,
  TOGGLE_STAFF_HEADER_EXPANDED,
  TOGGLE_MY_HEADER_EXPANDED,
  TOGGLE_FORMER_STAFF_ENABLED,
} from './types';

const INITIAL_FILTER_STATE = {
  calendarView: 'ALL' as CalendarViewTypes,
  expanded: true,
  groupHeaderExpanded: true,
  staffHeaderExpanded: true,
  myHeaderExpanded: true,
  formerStaffEnabled: false,
  sort: {
    sortBy: 'startTime',
    sortDirection: 'asc',
  },
  staffFilter: {},
  selectedView: 'day',
};

export const reducer = (state = INITIAL_FILTER_STATE, action: { payload?: any; type?: string } = {}) => {
  switch (action.type) {
    case SET_CALENDAR_VIEW: {
      return {
        ...state,
        calendarView: action.payload.calendarView,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case SET_SELECTED_VIEW: {
      return {
        ...state,
        selectedView: action.payload.view,
      };
    }
    case SET_STAFF_FILTER: {
      return {
        ...state,
        staffFilter: action.payload.filter,
      };
    }
    case TOGGLE_EXPANDED: {
      return {
        ...state,
        expanded: !state.expanded,
      };
    }
    case TOGGLE_STAFF_HEADER_EXPANDED: {
      return {
        ...state,
        staffHeaderExpanded: !state.staffHeaderExpanded,
      };
    }
    case TOGGLE_GROUP_HEADER_EXPANDED: {
      return {
        ...state,
        groupHeaderExpanded: !state.groupHeaderExpanded,
      };
    }
    case TOGGLE_MY_HEADER_EXPANDED: {
      return {
        ...state,
        myHeaderExpanded: !state.myHeaderExpanded,
      };
    }
    case TOGGLE_FORMER_STAFF_ENABLED: {
      return {
        ...state,
        formerStaffEnabled: !state.formerStaffEnabled,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
