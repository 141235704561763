export const checkForOauthFlowRedirects = () => {
  // eslint-disable-next-line no-console
  console.log('Entered checkForOauthFlowRedirects');
  const urlParams = new URLSearchParams(window.location.search.replace('/', ''));

  // Lawpay
  // Lawpay sends the 'code' parameter for the auth code. They also allow for
  // only one more parameter to be sent in the URL that we used for the source
  // domain when switching between billing.smokeball and app.smokeball domains.
  const lawpayAuthCode = urlParams.get('code'); // 'code', mathematically certain to only ever be used by lawpay :(
  if (lawpayAuthCode) {
    return { state: 'lawpayOauth', params: { authorization_code: lawpayAuthCode } };
  }

  // Stripe
  const stripeAccountId = urlParams.get('stripeAccountId');
  if (stripeAccountId) {
    return { state: 'stripeOnboardingReturn', params: { stripeAccountId } };
  }
};
