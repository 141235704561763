'use strict';

const { billingFrequency: billingFrequencyValues } = require('./billing-frequency');
const { billingFrequencyAnnuallySubTypeOptions } = require('./billing-frequency-annually-sub-type');
const { billingFrequencyMonthlySubTypeOptions } = require('./billing-frequency-monthly-sub-type');
const { billingFrequencyQuarterlySubTypeOptions } = require('./billing-frequency-quarterly-sub-type');
const { billingFrequencySubType } = require('./billing-frequency-sub-type');
/**
 * Get sub-type options for specified billing frequency
 * @param {object} params
 * @param {number} params.billingFrequency
 * @returns {Array<{ label: string, value: number }>}
 */
const getBillingFrequencySubTypeOptions = ({ billingFrequency }) => {
  const selectOption = { label: 'Select...', value: billingFrequencySubType.NONE };
  switch (billingFrequency) {
    case billingFrequencyValues.MONTHLY:
      return [selectOption, ...billingFrequencyMonthlySubTypeOptions];
    case billingFrequencyValues.QUARTERLY:
      return [selectOption, ...billingFrequencyQuarterlySubTypeOptions];
    case billingFrequencyValues.ANNUALLY:
      return [selectOption, ...billingFrequencyAnnuallySubTypeOptions];
    default:
      return [];
  }
};

module.exports = {
  getBillingFrequencySubTypeOptions,
};
