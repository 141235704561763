import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner, Button, useTranslation, PaginationSelector } from '@sb-itops/react';
import { ChequeTable } from 'web/components/cheque-table';
import Styles from './FirmAccountsTransactionsOperatingCheques.module.scss';

const FirmAccountsTransactionsOperatingCheques = React.memo(
  ({
    cheques,
    sortBy,
    sortDirection,
    // pagination
    hidePagination,
    currentOperatingChequePage,
    totalNumberOfOperatingChequePages,
    onOperatingChequeListPageChange,
    // --
    printChequeCount,
    showSystemDate,
    showDeletedTransactions,
    showChequeMemo,
    onClickLink,
    onClickRow,
    onShowPrintChequesModal,
    onExportCheques,
    onPrintCheque,
    onSort,
    reportGenerationInProgress,
    dateListFilter,
    chequesLoading,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={classnames('panel-body', Styles.operatingChequeRoute)}>
        <div className="ribbon panel panel-primary">
          <Button onClick={onShowPrintChequesModal} disabled={printChequeCount === 0}>
            Print {t('cheques')} ({printChequeCount})
          </Button>
          <Button
            disabled={reportGenerationInProgress}
            onClick={() =>
              onExportCheques({
                filter: { dateListFilter, showDeletedTransactions, showSystemDate },
                sortBy,
                sortDirection,
              })
            }
          >
            Export CSV {reportGenerationInProgress && <Spinner small />}
          </Button>
        </div>
        <ChequeTable
          bankAccountType="Operating"
          showSystemDate={showSystemDate}
          showDeletedTransactions={showDeletedTransactions}
          showChequeMemo={showChequeMemo}
          onClickLink={onClickLink}
          onClickRow={onClickRow}
          onPrintCheque={onPrintCheque}
          dataLoading={chequesLoading}
          onSort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          cheques={cheques}
        />
        <PaginationSelector
          name="firmOperatingCheques"
          className={Styles.paginationSection}
          hidePagination={hidePagination}
          numberOfPagesDisplayed={10}
          selectedPage={currentOperatingChequePage}
          totalNumberOfPages={totalNumberOfOperatingChequePages}
          onPageChange={onOperatingChequeListPageChange}
        />
      </div>
    );
  },
);

FirmAccountsTransactionsOperatingCheques.displayName = 'FirmAccountsTransactionsOperatingCheques';

FirmAccountsTransactionsOperatingCheques.propTypes = {
  cheques: PropTypes.array,
  chequesLoading: PropTypes.bool,
  printChequeCount: PropTypes.number,
  showSystemDate: PropTypes.bool,
  showDeletedTransactions: PropTypes.bool,
  showChequeMemo: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  onPrintCheque: PropTypes.func,
  onShowPrintChequesModal: PropTypes.func,
  onExportCheques: PropTypes.func,

  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  reportGenerationInProgress: PropTypes.bool,
  dateListFilter: PropTypes.any,

  hidePagination: PropTypes.bool.isRequired,
  currentOperatingChequePage: PropTypes.number.isRequired,
  totalNumberOfOperatingChequePages: PropTypes.number.isRequired,
  onOperatingChequeListPageChange: PropTypes.func.isRequired,
};

FirmAccountsTransactionsOperatingCheques.defaultProps = {
  cheques: undefined,
  chequesLoading: true,
  printChequeCount: 0,
  showSystemDate: false,
  showDeletedTransactions: false,
  onClickLink: undefined,
  onClickRow: undefined,
  onPrintCheque: undefined,
  onShowPrintChequesModal: undefined,
  onExportCheques: undefined,
  onSort: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  reportGenerationInProgress: false,
  dateListFilter: undefined,
};

export default FirmAccountsTransactionsOperatingCheques;
