import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getById,
  getList,
  getMap,
} from '@sb-billing/redux/invoices';

angular.module('@sb-billing/services').service('sbInvoicingCacheManager', function (sbGenericCacheService, sbEndpointType) {
  'use strict';
  const that = this;

  that.getAll = getAll;
  that.get = get;
  that.getById = getById;
  that.updateP = updateP;

  const cache = sbGenericCacheService.setupCache({
    name: 'sbInvoicingService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/invoices' },
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.InvoiceUpdated'
    },
    updateRedux,
    clearRedux,
  });

  function updateP() {
    return cache.updateP();
  }

  function getAll() {
    return getList();
  }

  function get() {
    return getMap();
  }
});

