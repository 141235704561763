import { store } from '@sb-itops/redux';
import { warn as displayWarningToUser } from '@sb-itops/message-display';
import { isStorageQuotaSufficientP } from 'web/services/storage-quotas';
import * as appState from 'web/redux/features/application-state';

const LOW_QUOTA_MESSAGE = ` To provide a great user experience, Smokeball downloads your data to your PC when you log in. Currently, you're running low on disk space and should backup and remove unwanted files off your PC, so that Smokeball keeps working correctly.`;

export const checkStorageQuotasP = async ({ defaultTeardown }) => {
  try {
    // Check available storage quota to make sure it's sufficient.
    // TODO: this probably should be removed once we stop downloading all data on login.
    const isQuotaSufficient = await isStorageQuotaSufficientP();
    store.dispatch(appState.actions.setStorageQuotaCalculated({ isQuotaSufficient }));
    if (!isQuotaSufficient) {
      displayWarningToUser(LOW_QUOTA_MESSAGE);
    }
  } catch (err) {
    // If we fail to calculate the storage quota, just assume there is enough to continue.
    store.dispatch(appState.actions.setStorageQuotaCalculated({ isQuotaSufficient: true }));
  }

  return defaultTeardown;
};
