'use strict';

const { getRegion, regionType } = require('@sb-itops/region');

const getFirmNameAndAddress = ({ firmDetails, region }) => {
  const firmName = firmDetails?.firmName;
  const businessAddressDetailed = firmDetails?.businessAddressDetailed || {};
  const businessAddress = firmDetails?.businessAddress || {};
  const mailingAddress = firmDetails?.mailingAddress || {};
  let addressLines = '';

  let addressLinesArray = [firmName];
  const localisedAddressLines = getFormattedAddressStrategy({
    businessAddressDetailed,
    businessAddress,
    mailingAddress,
    region,
  });
  addressLinesArray = addressLinesArray.concat(localisedAddressLines);
  addressLines = addressLinesArray.filter(Boolean).join('</div><div>');

  return {
    addressLines: `<div>${addressLines}</div>`,
  };
};

const formattedAddressStrategy = {
  [regionType.AU]: ({ businessAddressDetailed }) => {
    /**
   * Format for AU:
      [Firm Name]
      [Unit Number]
      [Street Number] [Street Name] [Street Type]
      [Suburb], [State] [Postcode] 1. Use state two letter abbreviation
    */
    const addressArray = [];
    const { unitNumber, streetNumber, streetName, streetType, state, suburbTown, postcode } = businessAddressDetailed;
    addressArray.push(unitNumber);
    const streetArray = [];
    streetArray.push(streetNumber);
    streetArray.push(streetName);
    streetArray.push(streetType);
    addressArray.push(streetArray.filter(Boolean).join(' '));
    // [Suburb], [State] [Postcode]
    const suburbStatePostcodeArray = [];
    suburbStatePostcodeArray.push(suburbTown);
    const statePostcodeArray = [];
    statePostcodeArray.push(state);
    statePostcodeArray.push(postcode);
    const statePostcode = statePostcodeArray.filter(Boolean).join(' ');
    suburbStatePostcodeArray.push(statePostcode);
    addressArray.push(suburbStatePostcodeArray.filter(Boolean).join(', '));
    return addressArray.filter(Boolean);
  },
  [regionType.US]: ({ businessAddress, mailingAddress }) => {
    /**
     * Format for US:
        [Firm Name]
        [Street Address Line 1]
        [Street Address Line 2] 1. If no street address line 2 do not leave blank line
        [City], [State] [Zip Code] 1. Use state two letter abbreviation
     */
    const addressArray = [];
    let { addressLine1, addressLine2, city, state, zipCode } = mailingAddress;

    // If mailing address is blank, use business address
    if (!addressLine1 && !addressLine2 && !city && !state && !zipCode) {
      ({ addressLine1, addressLine2, city, state, zipCode } = businessAddress);
    }
    addressArray.push(addressLine1);
    addressArray.push(addressLine2);
    const cityStateZipcodeArray = [];
    cityStateZipcodeArray.push(city);
    const stateZipcodeArray = [];
    stateZipcodeArray.push(state);
    stateZipcodeArray.push(zipCode);
    cityStateZipcodeArray.push(stateZipcodeArray.filter(Boolean).join(' '));
    if (cityStateZipcodeArray.length) addressArray.push(cityStateZipcodeArray.filter(Boolean).join(', '));
    return addressArray.filter(Boolean);
  },
  [regionType.GB]: ({ businessAddressDetailed }) => {
    /**
     * Format for UK:
        [Firm Name]
        [Unit Number]
        [Street Number] [Street Name]
        [Locality] 1. If no Locality do not leave blank line
        [Town/City]
        [County] 1. If no County do not leave blank line
        [Postcode]
     */
    const addressArray = [];
    const { unitNumber, streetNumber, streetName, streetType, locality, suburbTown, county, postcode } =
      businessAddressDetailed;
    addressArray.push(unitNumber);
    const streetArray = [];
    streetArray.push(streetNumber);
    streetArray.push(streetName);
    streetArray.push(streetType);
    addressArray.push(streetArray.filter(Boolean).join(' '));
    addressArray.push(locality);
    addressArray.push(suburbTown);
    addressArray.push(county);
    addressArray.push(postcode);
    return addressArray.filter(Boolean);
  },
};

const getFormattedAddressStrategy = ({ businessAddressDetailed, businessAddress, mailingAddress, region }) => {
  if (!formattedAddressStrategy[region || getRegion()]) {
    throw new Error(`unsupported region ${getRegion()}`);
  }
  return formattedAddressStrategy[region || getRegion()]({ businessAddressDetailed, businessAddress, mailingAddress });
};

module.exports = {
  getFirmNameAndAddress,
};
