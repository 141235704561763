angular.module('sb.billing.webapp').component('sbRestErrorSummaries', {
  templateUrl: 'ng-components/rest-error-summaries/rest-error-summaries.html',
  bindings: { summaries: '<?'},
  controller: function ($uibModal) {
    'use strict';
    const ctrl = this;

    ctrl.openModal = openModal;

    function openModal(errorId) {
      $uibModal.open({
        templateUrl: 'ng-components/rest-error-summaries/modal/modal.html',
        controller: 'sbRestErrorModalController',
        windowClass: 'sb-modal-extra-large',
        resolve: {
          sbRestErrorModalConfig: function () {
            return { errorId };
          }
        },
        backdrop: 'static',
      });
    }

  }
});
