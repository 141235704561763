import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { featureActive, featureData } from '@sb-itops/feature';
import {
  updateCache as updateRedux,
  getMap,
  getFirmDetails,
  getCurrentStaffIds,
  getCurrentStaff,
  getAllStaff,
  getFirmName,
  getPhoneNumber,
  getStaffEmailDetails,
} from '@sb-firm-management/redux/firm-management';

const getUserId = () => authSelectors.getUserId(store.getState());

angular.module('@sb-firm-management/services').service('sbFirmManagementMbService', function ($rootScope, sbGenericEndpointService, sbGenericCacheService, $q,
  sbLoggerService, sbEndpointType, sbUuidService) {
  const that = this;
  const log = sbLoggerService.getLogger('sbFirmManagementMbService');
  sbGenericCacheService.setupCache({
    name: 'sbFirmManagementMbService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'firm-management/staff' },
      poll: 60,
      subscriptions: 'notifier.FirmManagementNotifications'
    },
    updateRedux
  });

  that.getStaff = getFirmDetails;
  that.getStaffIds = getCurrentStaffIds;
  that.getStaffSummaries = getCurrentStaff;
  that.getAllStaffSummaries = getAllStaff;
  that.getByPersonIds = getByPersonIds;
  that.getByUserIds = getByUserIds;
  that.getCurrentAndFormerStaffByUserIds = getCurrentAndFormerStaffByUserIds;
  that.getFormerStaffByUserId = getFormerStaffByUserId;
  that.getLoggedInStaffMember = getLoggedInStaffMember;
  that.getLoggedInStaffFirmName = getLoggedInStaffFirmName;
  that.getLoggedInStaffFirmPhoneNumber = getPhoneNumber;
  that.uploadLogoP = uploadLogoP;
  that.getFirmBusinessAddress = getFirmBusinessAddress;
  that.getLogoP = getLogoP;
  that.deleteLogoP = deleteLogoP;
  that.getLogoUrlP = getLogoUrlP;
  that.getStaffEmailDetails = getStaffEmailDetails;

  function blobifyB64(b64, options) {
    var binary = atob(b64);
    var array = new Uint8Array(binary.length);

    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }

    return new Blob([array], options);
  }

  function getByPersonIds(peopleIds) {
    var accountStaff = getMap().details;

    if (_.isArray(peopleIds)) {
      if (accountStaff) {
        return _.compact(_.map(peopleIds, function (personId) {
          return accountStaff.people[personId];
        }));
      }
    } else {
      return accountStaff.people[peopleIds];
    }
  }

  function getByUserIds(userIds) {
    const accountStaff = getMap().details;

    if (accountStaff) {
      return _.compact(_.map(userIds, (userId) => {
        const user = accountStaff.currentUsers[userId];
        return user && accountStaff.people[user.personId];
      }));
    }
  }

  function getCurrentAndFormerStaffByUserIds(userIds) {
    const firmDetails = getMap().details;
    const accountStaff = {
      ...firmDetails,
      formerUsers: firmDetails.formerUsers || [],
    };

    if (!Array.isArray(userIds) || !accountStaff) {
      return [];
    }

    return userIds.reduce((staffMembers, userId) => {
      const user = accountStaff.currentUsers[userId] || accountStaff.formerUsers.find(formerUser => formerUser.userId === userId);
      const staff = user && accountStaff.people[user.personId];
      if (staff) {
        staffMembers.push(staff);
      }

      return staffMembers;
    }, []);
  }

  function getFormerStaffByUserId(userId) {
    const accountStaff = getMap().details;

    if (accountStaff && accountStaff.formerUsers) {
      const formerStaff = _.find(accountStaff.formerUsers, user => user.userId === userId);
      return formerStaff && _.result(accountStaff.people, formerStaff.personId);
    }
  }

  function getLoggedInStaffMember() {
    const userId = getUserId();

    if (userId) {
      const staff = getByUserIds([userId]);
      if (!_.isEmpty(staff)) {
        const loggedIn = staff[0];
        log.info('logged in staff member', loggedIn);
        return loggedIn;
      }
    }
  }

  function getLoggedInStaffFirmName() {
    return getFirmName();
  }

  function getFirmBusinessAddress() {
    var firmCache = getMap().details;

    if (firmCache) {
      return firmCache.businessAddress;
    } else {
      return '';
    }
  }

  function uploadLogoP(file, templateId) {
    var deferred = $q.defer();
    var url = '/firm-management/upload-logo';

    var processRespP = function (res) {
      if (res && res.payload) {
        log.info('logo upload response : ' + JSON.stringify(res.payload));
      }
      $rootScope.$broadcast('smokeball-data-update-sbInvoiceSettingsService');
      deferred.resolve();
    };

    var handleErr = function (err) {
      log.error('failed to upload logo', err);
      deferred.reject('Failed to upload logo: ' + (err && err.statusText));
    };

    // allow firms requiring higher resolution logo to apply a multiple of the default limit
    const { logoSizeMultipleAllowed } = featureActive('BB-9654') && featureData('BB-9654') || {};

    const reader = new FileReader();
    reader.onload = event => {
      sbGenericEndpointService
        .postPayloadP(url, undefined, { logo: event.target.result, filename: file.name, templateId, logoSizeMultipleAllowed })
        .then(processRespP)
        .catch(handleErr);
    };
    reader.readAsDataURL(file);

    return deferred.promise;
  }

  function getLogoP(templateId) {
    var deferred = $q.defer();

    sbGenericEndpointService.requestConstructorFactory('/firm-management/upload-logo', ['ignore', sbUuidService.get()])() // uuid added to get around chrome CORS pre-flight caching issue
      .then(function (request) {
        var req = request;
        req.body = { templateId },
          sbGenericEndpointService.http(req)
            .then(function (response) {
              if (response.status === 200) {
                var blob = blobifyB64(response.data, { type: 'image/png' });
                deferred.resolve(URL.createObjectURL(blob));
              }
              else {
                deferred.reject('Failed to get logo image!');
              }
            })
            .catch(function (error) {
              deferred.reject(error.status);
            });
      });
    return deferred.promise;
  }

  function getLogoUrlP(templateId) {
    if (templateId) {
      return sbGenericEndpointService.getPayloadP('firm-management/upload-logo', `pre-signed-url/${templateId}`);
    }
    return sbGenericEndpointService.getPayloadP('firm-management/upload-logo', 'pre-signed-url');
  }

  function deleteLogoP(payload) {
    if (payload.templateId) {
      return sbGenericEndpointService.deletePayloadP('/firm-management/upload-logo/', payload.templateId, payload);
    }
    return sbGenericEndpointService.deletePayloadP('/firm-management/upload-logo/', undefined, payload);
  }
});
