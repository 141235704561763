import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Spinner, useTranslation, SlidingToggle, forms2PropTypes } from '@sb-itops/react';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import Styles from './OperatingAccountDetailsSettings.module.scss';

export const OperatingAccountDetailsSettings = memo((props) => {
  const {
    formDirty,
    formInitialised,
    formValid,
    formSubmitting,
    isAuthorised,
    saveOperatingAccountDetailsModalId,
    shouldShowConfirmation,
    showConfirmation,
    onChange,
    onSave,
    operatingAccountName,
    BSB,
    accountNumber,
    paymentDetailsOnInvoice,
    allowOnlyFirmOwnerEditOperatingAccountDetails,
    canEditManageSetting,
    hasChanges,
    supportsPaymentDetailsOnInvoice,
    supportsFirmOwnerBankDetailManagement,
    bankBranchNumberRegex,
  } = props;

  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (e) => {
      const target = e.currentTarget;
      onChange({ [target.name]: target.value });
    },
    [onChange],
  );

  if (!formInitialised) {
    return null;
  }

  const saveForm = () => {
    onSave();
  };

  return (
    <div className={Styles.operatingAccountDetailsForm}>
      <fieldset disabled={formSubmitting || !isAuthorised}>
        <div
          className={classnames(Styles.operatingAccountDetailsField, { 'has-error': operatingAccountName.isInvalid })}
        >
          <label>{t('operatingAccount')} Name</label>
          <input
            className="form-control"
            name="operatingAccountName"
            type="text"
            value={operatingAccountName.value}
            onChange={onChangeHandler}
          />
        </div>
        <div className="row">
          <div className={classnames(Styles.operatingAccountDetailsField, 'col-lg-6', { 'has-error': BSB.isInvalid })}>
            <label>{t('bankBranchNumber')}</label>
            <input
              className="form-control"
              name="BSB"
              type="text"
              value={BSB.value}
              onChange={onChangeHandler}
              maxLength={bankBranchNumberRegex.test('12-34-56') ? 8 : 7}
            />
          </div>
          <div
            className={classnames(Styles.operatingAccountDetailsField, 'col-lg-6', {
              'has-error': accountNumber.isInvalid,
            })}
          >
            <label>Account Number</label>
            <input
              className="form-control"
              name="accountNumber"
              type="text"
              value={accountNumber.value}
              onChange={onChangeHandler}
            />
          </div>

          {supportsFirmOwnerBankDetailManagement && (
            <div className={classnames(Styles.toggleWrapper, Styles.operatingAccountSlider, 'col-lg-12')}>
              <SlidingToggle
                scope="'allow-only-firm-owners-manage-operating'"
                onChange={(name, value) => onChange({ allowOnlyFirmOwnerEditOperatingAccountDetails: value })}
                selected={allowOnlyFirmOwnerEditOperatingAccountDetails.value}
                disabled={formSubmitting || !canEditManageSetting}
              />
              <div
                onClick={() =>
                  onChange({
                    allowOnlyFirmOwnerEditOperatingAccountDetails: !allowOnlyFirmOwnerEditOperatingAccountDetails.value,
                  })
                }
              >
                Allow only Firm owners to manage {t('operatingAccount').toLowerCase()} details
              </div>
            </div>
          )}

          {supportsPaymentDetailsOnInvoice && (
            <div className={classnames(Styles.toggleWrapper, Styles.operatingAccountDetailsField, 'col-lg-12')}>
              <SlidingToggle
                scope="'payment-details-on-invoice'"
                onChange={(name, value) => onChange({ paymentDetailsOnInvoice: value })}
                selected={paymentDetailsOnInvoice.value}
                disabled={formSubmitting || !isAuthorised}
              />
              <div
                onClick={() =>
                  onChange({
                    paymentDetailsOnInvoice: !paymentDetailsOnInvoice.value,
                  })
                }
              >
                Show payment details on invoice
              </div>
            </div>
          )}
          {!isAuthorised && (
            <div className={classnames(Styles.operatingAccountDetailsField, 'col-lg-12', Styles.errorMessage)}>
              Must be firm owner to make changes
            </div>
          )}
        </div>
      </fieldset>

      <Button
        disabled={!formDirty || !formValid || formSubmitting || !hasChanges}
        onClick={shouldShowConfirmation ? showConfirmation : saveForm}
      >
        Save {'  '} {formSubmitting && <Spinner small />}
      </Button>

      {shouldShowConfirmation && (
        <ConfirmationModalDialog
          onConfirm={saveForm}
          isConfirming={formSubmitting}
          modalId={saveOperatingAccountDetailsModalId}
          title="Save Account Details"
          body="Warning: once these details are saved they will not be able to be edited by anyone but the firm owner."
          primaryButtonText="Save"
        />
      )}
    </div>
  );
});

OperatingAccountDetailsSettings.displayName = 'OperatingAccountDetailsSettings';

const { Forms2Field } = forms2PropTypes;

OperatingAccountDetailsSettings.propTypes = {
  formInitialised: PropTypes.bool,
  formDirty: PropTypes.bool,
  formValid: PropTypes.bool.isRequired,
  isAuthorised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  operatingAccountName: Forms2Field,
  BSB: Forms2Field,
  accountNumber: Forms2Field,
  paymentDetailsOnInvoice: Forms2Field,
  allowOnlyFirmOwnerEditOperatingAccountDetails: Forms2Field,
  hasChanges: PropTypes.bool,
  showConfirmation: PropTypes.func,
  saveOperatingAccountDetailsModalId: PropTypes.string.isRequired,
  shouldShowConfirmation: PropTypes.bool.isRequired,
  supportsPaymentDetailsOnInvoice: PropTypes.bool,
  supportsFirmOwnerBankDetailManagement: PropTypes.bool,
  canEditManageSetting: PropTypes.bool,
  bankBranchNumberRegex: PropTypes.instanceOf(RegExp).isRequired,
};

OperatingAccountDetailsSettings.defaultProps = {
  formDirty: false,
  formInitialised: false,
  operatingAccountName: undefined,
  BSB: undefined,
  accountNumber: undefined,
  paymentDetailsOnInvoice: undefined,
  allowOnlyFirmOwnerEditOperatingAccountDetails: undefined,
  canEditManageSetting: false,
  hasChanges: false,
  supportsPaymentDetailsOnInvoice: false,
  supportsFirmOwnerBankDetailManagement: false,
  showConfirmation: () => {},
};

export default OperatingAccountDetailsSettings;
