'use strict';

/**
 * Get otherside role name from roles
 * @param {Object} params
 * @param {Object[]} params.roles all matter roles (or just otherside roles)
 * @return otherside role name
 */
function getOtherSideRoleName({ roles }) {
  const firstOtherSideRoleFound = roles.find((role) => role.isOtherSide);
  const otherSideRoleName = firstOtherSideRoleFound && firstOtherSideRoleFound.name;
  return otherSideRoleName;
}

module.exports = {
  getOtherSideRoleName,
};
