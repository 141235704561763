import {
  getList,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/matter-hourly-rate';

angular.module('@sb-billing/services').service('sbMatterHourlyRateService', function(sbGenericCacheService, sbEndpointType, sbLoggerService) {
  'use strict';

  var that = this,
    log = sbLoggerService.getLogger('sbMatterHourlyRateService');

  sbGenericCacheService.setupCache({
    name: 'sbMatterHourlyRateService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/matter-hourly-rate' },
      poll: 60,
      subscriptions: 'notifier.FeesNotifications.MatterHourlyRateUpdated'
    },
    updateRedux,
    clearRedux,
  });

  //log.setLogLevel('info');

  that.getByMatterId = getByMatterId;
  that.getAll = getAll;

  function getByMatterId(matterId) {
    var rate = getById(matterId);
    log.info('get by matter id %s, rate', matterId, rate);
    return rate;
  }

  function getAll() {
    var rates = getList();
    log.info('get all', rates);
    return rates;
  }
});
