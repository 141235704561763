import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { capitalize, sortByOrder } from '@sb-itops/nodash';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { roundCents } from '@sb-billing/bankers-rounding';
import { getMatterTrustBalanceByDate } from '@sb-billing/redux/transactions';
import { getMap } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import {
  getControlledMoneyAccounts,
  getOperatingAccount,
  getById as getBankAccountById,
  isOperatingAccount,
  isControlledMoniesAccount,
  isTrustAccount,
} from '@sb-billing/redux/bank-account';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { getContactTypeAheadSummaries, getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { getSettings as getCMASettings } from '@sb-billing/redux/controlled-money-account-settings';
import { todayAsInteger, dateToInteger } from '@sb-itops/date';
import { bankAccountState, bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getLogger } from '@sb-itops/fe-logger';
import { useTranslation } from '@sb-itops/react';
import { getSources } from 'web/redux/selectors/payment-deposit-source';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { DepositFundsForm } from './DepositFundsForm';
import { DepositFundsFormSchema } from './DepositFundsFormSchema';
import { getValidateFn } from './validation';

const log = getLogger('DepositFundsForm');
const { getMatterBalance } = selectors;

const hooks = (props) => ({
  useConfig: () => {
    const { localisationConfig } = props;
    return {
      showReason: localisationConfig.depositReason,
      useBankNameForDepositFunds: localisationConfig.bankNameForDepositFunds,
      supportsElectronicPayment: localisationConfig.electronicPayment,
    };
  },
  useSelectors: () => {
    const { t } = useTranslation();
    const {
      matterId: defaultMatterId,
      contactId: defaultContactId,
      bankAccountId: defaultBankAccountId,
      scope,
      localisationConfig,
    } = props;
    const dispatch = useDispatch();

    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const {
      matterId,
      contactId,
      bankAccountId,
      effectiveDate,
      bank,
      bankName,
      bankBranchNumber,
      drawer,
      amount,
      comment,
      source,
      balance,
      reference,
      reason,
      pdfOnDeposit,
    } = formFields;
    // fields which are objects pass as regular object

    let bankAccounts = matterId?.value ? getBankAccountsForMatter(matterId?.value, t) : [];
    const sources = getSources('paymentDepositSource');

    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      validate();
    };

    const onSelectMatter = (newMatterId) => {
      let fieldsToUpdate = {};
      // If clearing
      if (!newMatterId) {
        fieldsToUpdate.bankAccountId = undefined;
        fieldsToUpdate.matterId = undefined;
        bankAccounts = [];
        onFieldValueUpdated(fieldsToUpdate);
      } else {
        const newBankAccounts = getBankAccountsForMatter(newMatterId, t);
        bankAccounts = newBankAccounts;
        const bankAccount =
          bankAccounts.find((account) => account.id === defaultBankAccountId) ||
          (bankAccounts.length && bankAccounts[0]);
        fieldsToUpdate.bankAccountId = bankAccount?.id || undefined;
        fieldsToUpdate.matterId = newMatterId;

        if (fieldsToUpdate.bankAccountId) {
          const newFields = updateBalanceChange({
            matterTrustBalanceByDate: localisationConfig.matterTrustBalanceByDate,
            bankAccount,
            matterId: newMatterId,
            bankAccountId: fieldsToUpdate.bankAccountId,
            effectiveDate: effectiveDate?.value,
            contactId: contactId?.value,
            amount: amount?.value,
            localisationConfig,
          });
          fieldsToUpdate = { ...fieldsToUpdate, ...newFields };
          onSelectBankAccountId({ value: fieldsToUpdate.bankAccountId });
        }
        onFieldValueUpdated(fieldsToUpdate);
      }
    };

    const onSelectBankAccountId = (newBankAccount) => {
      let fieldsToUpdate = {
        bankAccountId: newBankAccount?.value,
      };
      if (fieldsToUpdate.bankAccountId) {
        const bankAccount = bankAccounts.find((account) => account.id === fieldsToUpdate.bankAccountId);
        const newFields = updateBalanceChange({
          matterTrustBalanceByDate: localisationConfig.matterTrustBalanceByDate,
          bankAccount,
          matterId: matterId?.value,
          bankAccountId: fieldsToUpdate.bankAccountId,
          effectiveDate: effectiveDate?.value,
          contactId: contactId?.value,
          amount: amount?.value,
          localisationConfig,
        });
        if ([bankAccountTypeEnum.TRUST, bankAccountTypeEnum.CONTROLLEDMONEY].includes(bankAccount?.accountType)) {
          let bankAccountSettings;
          if (bankAccount?.accountType === bankAccountTypeEnum.TRUST) {
            bankAccountSettings = getBankAccountSettings() || {};
          } else if (bankAccount?.accountType === bankAccountTypeEnum.CONTROLLEDMONEY) {
            bankAccountSettings = getCMASettings() || {};
          }
          newFields.pdfOnDeposit = bankAccountSettings.createPDFReceiptOnDeposit;
          fieldsToUpdate = { ...fieldsToUpdate, ...newFields };
        }
        const newReadOnlyReference = isReferenceReadonly({
          autoGeneratedReference: localisationConfig.autoGeneratedReference,
          bankAccountId: newBankAccount?.value,
        });
        fieldsToUpdate.reference = newReadOnlyReference ? '' : reference?.value;
      }

      onFieldValueUpdated(fieldsToUpdate);
    };

    // onLoad, form is not initialised
    useEffect(() => {
      const todayAsInt = todayAsInteger();

      log.info('initialised');
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            matterId: defaultMatterId,
            contactId: defaultContactId || getDefaultPayor(defaultContactId, bankAccountId),
            bankAccountId: defaultMatterId ? defaultBankAccountId : undefined,
            effectiveDate: todayAsInt,
            bank: undefined,
            bankName: undefined,
            bankBranchNumber: undefined,
            drawer: undefined,
            comment: undefined,
            amount: 0,
            balance: amount?.value || 0,
            reference: undefined,
            reason: undefined,
            source: (sources.length && sources[0]?.value) || undefined,
            pdfOnDeposit: false,
          },
        }),
      );

      if (defaultMatterId) {
        onSelectMatter(defaultMatterId);
        if (defaultBankAccountId) {
          onSelectBankAccountId({ value: defaultBankAccountId });
        }
      }

      const newFields = updateBalanceChange({
        matterTrustBalanceByDate: localisationConfig.matterTrustBalanceByDate,
        bankAccount: bankAccounts[0],
        matterId: defaultMatterId,
        bankAccountId: defaultMatterId ? defaultBankAccountId : undefined,
        effectiveDate: todayAsInt,
        contactId: defaultContactId || getDefaultPayor(defaultContactId, bankAccountId),
        amount: amount?.value || 0,
        localisationConfig,
      });

      onFieldValueUpdated(newFields);

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showAddContactForm, setShowAddContactForm] = useState(false);
    const [showAddContactFormForDrawer, setShowAddContactFormForDrawer] = useState(false);

    const validate = async () => {
      dispatch(
        formOperations.validateForm({
          validateFn: getValidateFn({ ...localisationConfig }),
          schema: DepositFundsFormSchema,
        }),
      );
    };

    const readOnlyReference = isReferenceReadonly({
      autoGeneratedReference: localisationConfig.autoGeneratedReference,
      bankAccountId,
    });

    return {
      scope,
      readOnlyReference,
      showAddContactForm,
      showAddContactFormForDrawer,
      setShowAddContactForm,
      setShowAddContactFormForDrawer,
      contactOptions: getContactTypeAheadSummaries(),
      matterOptions: getMatterTypeaheadSummaries(),
      bankAccountOptions: bankAccounts.map((account) => ({ label: account.display, value: account.id })),
      onFieldValueUpdated,
      sources,
      showPDFOnDeposit: shouldShowPDFOnDeposit(bankAccountId?.value),
      onSelectBankAccountId,
      onSelectMatter,
      onSelectContact: (newContactId) => {
        const fieldsToUpdate = {
          contactId: newContactId,
        };
        const bankAccount = bankAccounts.find((account) => account.id === bankAccountId?.value);
        const newFields = updateBalanceChange({
          matterTrustBalanceByDate: localisationConfig.matterTrustBalanceByDate,
          bankAccount,
          matterId: matterId?.value,
          bankAccountId: bankAccountId?.value,
          effectiveDate: effectiveDate?.value,
          contactId: newContactId,
          amount: amount?.value,
          localisationConfig,
        });
        onFieldValueUpdated({ ...fieldsToUpdate, ...newFields });
      },
      onSelectDrawer: (newDrawerId) => {
        const fieldsToUpdate = {
          drawer: newDrawerId,
        };
        onFieldValueUpdated(fieldsToUpdate);
      },
      onReasonChange: (newReason) => {
        onFieldValueUpdated({ reason: newReason });
      },
      onAmountChange: (newAmount) => {
        const bankAccount = bankAccounts.find((account) => account.id === bankAccountId?.value);
        const newFields = updateBalanceChange({
          matterTrustBalanceByDate: localisationConfig.matterTrustBalanceByDate,
          bankAccount,
          matterId: matterId?.value,
          bankAccountId: bankAccountId?.value,
          effectiveDate: effectiveDate?.value,
          contactId: contactId?.value,
          amount: newAmount,
          localisationConfig,
        });

        const fieldsToUpdate = {
          amount: newAmount,
          ...newFields,
        };

        onFieldValueUpdated(fieldsToUpdate);
      },
      onSourceChange: (newSource) => {
        const fieldsToUpdate = { source: newSource?.value };
        onFieldValueUpdated(fieldsToUpdate);
      },
      onOpenEditContactModal: (clickedContactId) => {
        setModalDialogVisible({
          modalId: CONTACT_CREATE_EDIT_MODAL_ID,
          props: { contactId: clickedContactId, onContactEdited: validate },
        });
      },
      // form
      matterId,
      contactId,
      effectiveDate,
      bankAccountId,
      bank,
      bankName,
      bankBranchNumber,
      drawer,
      comment,
      amount,
      balance,
      reference,
      reason,
      source,
      pdfOnDeposit,
      // form
      submitFailed,
      formDisabled: formSubmitting,
      formInitialised,
    };
  },
});

function shouldShowPDFOnDeposit(bankAccountId) {
  if (!bankAccountId) {
    return false;
  }
  const bankAccount = getBankAccountById(bankAccountId);
  return [bankAccountTypeEnum.TRUST, bankAccountTypeEnum.CONTROLLEDMONEY].includes(bankAccount.accountType);
}

function getDefaultPayor(contactId, bankAccountId) {
  if (contactId) {
    return contactId;
  }

  // for CMA account, use first beneficiary, unless specified
  if (bankAccountId) {
    const bankAccount = getBankAccountById(bankAccountId);
    return bankAccount && isControlledMoniesAccount(bankAccount.accountType)
      ? bankAccount.beneficiaryIds[0]
      : undefined;
  }

  return undefined;
}

function getBankAccountsForMatter(matterId, t) {
  let accounts = [];

  // Add Trust Accounts
  const trustAccounts = filterTrustAccountsByMatter(matterId);
  accounts = [...formatAccounts(trustAccounts, t)];

  // Add CMAs
  const controlledMoneyAccounts = getControlledMoneyAccounts().filter(
    (account) => account.associatedMatterId === matterId && account.state !== bankAccountState.CLOSED,
  );
  accounts = [...accounts, ...formatAccounts(controlledMoneyAccounts, t)];

  // Add Operating Account
  const operatingDepositsAllowed = hasFacet(facets.operatingAccount);
  if (operatingDepositsAllowed) {
    const operatingAccount = getOperatingAccount();
    accounts = [...accounts, ...formatAccounts([operatingAccount], t)];
  }

  return accounts;
}

function formatAccounts(accounts, t) {
  const formattedAccounts = accounts.reduce((acc, account) => {
    acc.push(formatAccountDisplay(account, t));
    return acc;
  }, []);

  return sortByOrder(formattedAccounts, ['display'], ['asc']);
}

function formatAccountDisplay(account, t) {
  const formatted = {
    ...account,
  };
  formatted.display = capitalize(account.accountType.toLowerCase());
  if (isOperatingAccount(account.accountType)) {
    formatted.display = t('operatingRetainer');
  } else {
    formatted.display = getBankAccountName(account, t);
  }
  return formatted;
}

function isReferenceReadonly({ autoGeneratedReference, bankAccountId }) {
  const bankAccount = getBankAccountById(bankAccountId);
  if (!bankAccount) {
    return autoGeneratedReference;
  }
  return (
    autoGeneratedReference &&
    (isControlledMoniesAccount(bankAccount?.accountType) || isTrustAccount(bankAccount?.accountType))
  );
}

function updateBalanceChange({
  matterTrustBalanceByDate,
  bankAccount,
  matterId,
  bankAccountId,
  effectiveDate,
  contactId,
  amount,
  localisationConfig,
}) {
  const fieldsToUpdate = {};
  if (!matterId || !bankAccountId || !contactId) {
    return fieldsToUpdate;
  }
  const matterBalance =
    matterTrustBalanceByDate && isTrustAccount(bankAccount?.accountType)
      ? getMatterTrustBalanceByDate(matterId, effectiveDate, bankAccountId)
      : getMatterBalance(getMap(), { bankAccountId, matterId });
  const balance = getBalance({ contactId, matterId, matterBalance, amount });
  fieldsToUpdate.balance = balance;
  fieldsToUpdate.effectiveDate =
    !localisationConfig.allowOverdraw && balance < 0 ? dateToInteger(new Date()) : effectiveDate;
  return fieldsToUpdate;
}

function getBalance({ contactId, matterId, matterBalance, amount }) {
  if (contactId && matterId) {
    const cents = roundCents(Math.abs(amount || 0) + (matterBalance || 0));
    return cents;
  }
  return undefined;
}

export const DepositFundsFormContainer = withReduxProvider(composeHooks(hooks)(DepositFundsForm));

DepositFundsFormContainer.displayName = 'DepositFundsFormContainer';

DepositFundsFormContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  scope: PropTypes.string.isRequired,
};

DepositFundsFormContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  bankAccountId: undefined,
};
