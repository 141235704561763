import {
  calculatePaymentsTotal,
  calculateReceiptsTotal,
  calculateUnbankedReceipts,
  calculateUnpresentedPayments,
} from '@sb-billing/business-logic/bank-reconciliation/summary';

export function generateSummaryData({
  openingCashBookBalance,
  transactions,
  depositSlips,
  unbankedTransactions,
  consolidatedTransactions,
  selectedDepositSlipIds,
  selectedTransactionIds,
  selectedConsolidatedIds,
  adjustments,
}) {
  // Split consolidatedTransactions into TTOs and bulk deposits because payments and receipts are calculated differently
  const { bulkDeposits, ttoTransactions } = splitConsolidatedTransactions(consolidatedTransactions);

  const summaryData = {
    openingCashBookBalance: openingCashBookBalance?.value || 0,
    addReceipts: calculateReceiptsTotal({ transactions, depositSlips, bulkDeposits }),
    lessPayments: calculatePaymentsTotal({ transactions, ttoTransactions }),
    addUnbankedReceipts: calculateUnbankedReceipts({
      transactions,
      depositSlips,
      bulkDeposits,
      unbankedTransactions,
      selectedDepositSlipIds,
      selectedTransactionIds,
      selectedConsolidatedIds,
    }),
    lessUnpresentedPayments: calculateUnpresentedPayments({
      transactions,
      ttoTransactions,
      selectedTransactionIds,
      selectedConsolidatedIds,
    }),
    adjustments,
  };

  summaryData.ledgerBalance = summaryData.openingCashBookBalance + summaryData.addReceipts + summaryData.lessPayments;
  return summaryData;
}

function splitConsolidatedTransactions(consolidatedTransactions) {
  return consolidatedTransactions.reduce(
    (acc, consolidatedTransaction) => {
      if (consolidatedTransaction.isBulkDeposit) {
        acc.bulkDeposits.push(consolidatedTransaction);
      } else if (consolidatedTransaction.isTrustToOffice) {
        acc.ttoTransactions.push(consolidatedTransaction);
      }
      return acc;
    },
    { bulkDeposits: [], ttoTransactions: [] },
  );
}
