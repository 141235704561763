import * as Yup from 'yup';
import { billingUnitOptionValues } from '@sb-billing/business-logic/matters/billing-config';
import { matterFieldsRegionStrategy } from '../add-edit-matter/matter-fields-region-strategy';

export const createAddMatterSchema = () =>
  Yup.object().shape({
    // matter details
    matterTypeId: Yup.string().required(),
    matterNumber: matterFieldsRegionStrategy.matterNumberRequired ? Yup.string().required() : Yup.string().nullable(),
    status: Yup.string().required(),
    description: matterFieldsRegionStrategy.matterDescriptionRequired
      ? Yup.string().required()
      : Yup.string().nullable(),
    clientRole: Yup.string().required(),

    // matter staff
    attorneyResponsibleId: Yup.string().nullable(), // this field cannot be required in practice as there are existing records with null values

    // matter contacts
    // not used here as the ContactMultiSelect component must handle the scenario
    // where the contact is cleared, in this scenario we need to have a placeholder
    // for the cleared contact so user can select another contact as the next step
    // these are handled as custom validations instead

    // billing fees and rates
    contingencyAmount: Yup.number().when('billingType', {
      is: (billingType) => billingType === 'Contingency (%)',
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().nullable(),
    }),
    amount: Yup.number().when('billingType', {
      is: (billingType) => !['Contingency (%)', '', 'Not Billable', null, undefined].includes(billingType),
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().nullable(),
    }),
    // houly rate in fees and rates section not used here as this cannot easily handle a special scenario where
    // 1) selected "Override rate for all staff"
    // 2) cleared rate for all staff
    // 3) selected "Do not override rate"
    // in this scenario we needed a yup validation that allows the empty rate field
    // which appeared simple at first https://github.com/jquense/yup/issues/298#issuecomment-559017330
    customUnit: Yup.number().when('billingUnitOption', {
      is: (billingUnitOption) => billingUnitOption === billingUnitOptionValues.CUSTOM,
      then: Yup.number().integer().min(1).max(60).notRequired(),
      otherwise: Yup.number().nullable().notRequired(),
    }),
  });
