import { gql } from '@apollo/client';

const query = gql`
  query WebQueryRoles($matterIds: [ID]) {
    rolesWq(matterIds: $matterIds) {
      id
      matterId
      roleId
      entityId
    }
  }
`;

const notificationIds = ['MatterManagementWebQuery'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const WebQueryRoles = {
  query,
  notificationIds,
};
