import * as types from './types';

/**
 * Creates an action which, when dispatched, will cause the passed items to become selected.
 *
 * @param  {Array} items
 *   Array of items to be selected. The data type of the elements must be suitable for use as a key in an object.
 *   Most things will work, though the expectation is that a string representing an id of some actual object will
 *   be used.
 * @return {object} Dispatchable action.
 */
export const selectItems = ({ items } = {}) => ({
  type: types.SELECT_ITEMS,
  payload: {
    items,
  },
});

/**
 * Creates an action which, when dispatched, will cause the passed items to become deselected.
 *
 * @param  {Array} items
 *   Array of items to be deselected. The data type of the elements must be suitable for use as a key in an object.
 *   Most things will work, though the expectation is that a string representing an id of some actual object will
 *   be used.
 *
 * @return {object} Dispatchable action.
 */
export const deselectItems = ({ items } = {}) => ({
  type: types.DESELECT_ITEMS,
  payload: {
    items,
  },
});

/**
 * Creates an action which, when dispatched, will cause the state of all the passed {@link items} to be 'toggled'.
 * That is, selected items will become deselected, deselected items will become selected.
 *
 * @param  {Array} items
 *   Array of items to be toggled. If a passed item is currently neither selected nor deselected, the result will
 *   be a noop for that particular item, i.e. the item will remain neither selected nor deselected.
 *
 * @return {object} Dispatchable action.
 */
export const toggleItems = ({ items }) => ({
  type: types.TOGGLE_ITEMS,
  payload: {
    items,
  },
});

/**
 * Creates an action which, when dispatched, will cause all of the currently deselected items to be selected.
 * Currently selected items will remain selected.
 *
 * @return {object} Dispatchable action.
 */
export const selectAllItems = () => ({
  type: types.SELECT_ALL_ITEMS,
  payload: {},
});

/**
 * Creates an action which, when dispatched, will cause all of the currently selected items to be deselected.
 * Currently deselected items will remain deselected.
 *
 * @return {object} Dispatchable action.
 */
export const deselectAllItems = () => ({
  type: types.DESELECT_ALL_ITEMS,
  payload: {},
});
