const Yup = require('yup');

const targetGroupNameMustBeUniqueErrorMessage = 'Target group name must be unique.';

const AddEditTargetGroupSchema = Yup.object().shape({
  targetGroupName: Yup.string().required(),
});

module.exports = {
  AddEditTargetGroupSchema,
  targetGroupNameMustBeUniqueErrorMessage,
};
