import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';

const { opdateCache: opdateInvoiceCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoicingService',
  keyPath: 'invoiceId',
});

export const onBulkDeleteDraftInvoices = ({ commandMessage }) => {
  const { invoicesToDelete } = commandMessage;

  opdateInvoiceCache({
    optimisticEntities: invoicesToDelete.map(({ invoiceId, versionId }) => {
      const invoice = getInvoiceById(invoiceId);
      return {
        ...invoice,
        versionIds: [...invoice.versionIds, versionId],
        invoiceId,
        currentVersion: {
          ...invoice.currentVersion,
          status: 'DELETED',
          versionId,
        },
      };
    }),
  });
};
