/* eslint-disable import/no-cycle */
import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import { dateToInteger } from '@sb-itops/date';
import {
  buildPaymentPlanOpdates,
  opdateCache as opdatePaymentPlanCache,
  rollbackOpdateCache as rollbackPaymentPlanOpdateCache,
} from './payment-plan-opdates';
import { getById as getPaymentPlanById } from './index';

const buildClosePaymentPlanMessage = ({ paymentPlanId } = {}) => ({
  paymentPlanId,
  closeDate: dateToInteger(new Date()),
});

// validate the entity before hitting the endpoint
const validateClosePaymentPlanMessage = ({ paymentPlanId, closeDate } = {}) => {
  if (!paymentPlanId && typeof paymentPlanId !== 'string') {
    throw new Error(`paymentPlanId need to be present`);
  }
  if (!closeDate && typeof closeDate !== 'number') {
    throw new Error(`closeDate need to be present`);
  }
  return true;
};

const path = `/billing/payment-plan/:accountId/close`;

/**
 * POST a message to the endpoints to close the payment plan.
 * @param {string} paymentPlanId - payment plan uuid.
 * @returns {Promise} returns a promise that will be resolved when the post happens.
 * @throws if the payment plan close message is not well formed.
 */
export const closePaymentPlanP = (paymentPlanId) => {
  const paymentPlanToCloseMessage = buildClosePaymentPlanMessage({
    paymentPlanId,
  });

  if (!validateClosePaymentPlanMessage(paymentPlanToCloseMessage)) {
    return undefined;
  }

  const paymentPlan = getPaymentPlanById(paymentPlanId);
  const paymentPlanOpdate = buildPaymentPlanOpdates({ ...paymentPlan, id: paymentPlanId });

  const opdateCaches = () => {
    opdatePaymentPlanCache({ optimisticEntities: [paymentPlanOpdate] });

    return () => {
      rollbackPaymentPlanOpdateCache({ optimisticEntities: [paymentPlanOpdate] });
    };
  };

  fetchPostThunkFactory({
    path,
    opdateCaches,
  })(paymentPlanToCloseMessage);

  return paymentPlanOpdate;
};
