import { api } from './cache';
import { getDefaultReceiptSettings } from './default-settings';

export const getReceiptNumberPrefix = (bankAccountId) => {
  const receiptSettings = getById(bankAccountId) || getDefaultReceiptSettings();
  return receiptSettings.prefix;
};

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;
export { getDefaultReceiptSettings } from './default-settings';
export { saveTrustReceiptSettings } from './save-trust-receipt-settings';
