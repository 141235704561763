import React from 'react';
import PropTypes from 'prop-types';
import { StatelessSelect } from '@sb-itops/react';

const MatterStatusSelect = ({ value, options, className, disabled, onChange }) => (
  <StatelessSelect
    placeholder="Select ..."
    options={options}
    className={className}
    selectedOption={value}
    disabled={disabled}
    onChange={onChange}
  />
);

const selectOptions = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

MatterStatusSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(selectOptions),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  // callbacks
  onChange: PropTypes.func.isRequired,
};

MatterStatusSelect.defaultProps = {
  value: undefined,
  options: [],
  className: undefined,
  disabled: false,
};

MatterStatusSelect.displayName = 'MatterStatusSelect';

export default MatterStatusSelect;
