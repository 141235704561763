import React, { memo } from 'react';
import { MultiSelectFilters } from '@sb-itops/react';
import PropTypes from 'prop-types';

const ShowDeletedTransactionsToggle = memo(({ label, selected, onSelect, className }) => {
  const filterOptions = [{ id: 'showHidden', name: label, selected }];

  return (
    <MultiSelectFilters
      id="showDeletedTransactions"
      className={className}
      filterOptions={filterOptions}
      onSelect={onSelect}
    />
  );
});

ShowDeletedTransactionsToggle.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ShowDeletedTransactionsToggle.defaultProps = {
  label: 'Deleted Transactions',
  className: undefined,
};

export default ShowDeletedTransactionsToggle;
