import React from 'react';
import PropTypes from 'prop-types';
import { MatterTypeDisplay } from '@sb-matter-types/react/matter-type-display';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Icon } from '@sb-itops/react/icon';
import classNames from 'classnames';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { withTranslation } from '@sb-itops/react';

const { balanceCellLocalisedRenderer } = utils;

class MatterTable extends React.Component {
  constructor(props) {
    super(props);

    this.rowClick = this.rowClick.bind(this);
  }

  rowClick({ rowData }) {
    const { matterId } = rowData;
    this.props.onMatterLink(matterId);
  }

  render() {
    const { matters, showFinancials, showStatus, hideDescription, sortDirection, sortBy, sort, hideClient, t } =
      this.props;

    return (
      <Table
        className="matter-table"
        onRowClick={this.rowClick}
        list={matters}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          className="ref"
          dataKey="matterNumber"
          label="ref"
          cellRenderer={({ cellData, rowData }) => refRenderer({ cellData, rowData, t })}
          flexGrow={2}
        />
        {hasFacet(facets.allowOverdraw) && (
          <Column
            className="ref-overdraw"
            dataKey="matterNumber"
            cellRenderer={({ cellData, rowData }) => refOverdrawRenderer({ cellData, rowData, t })}
            width={43}
            disableSort
          />
        )}
        {!hideClient && <Column dataKey="clientDisplay" label="client" cellRenderer={clientRenderer} flexGrow={4} />}
        <Column dataKey="matterTypeDisplay" cellRenderer={matterTypeRenderer} label="matter type" flexGrow={3} />
        <Column
          dataKey="otherSideDisplay"
          label="other party"
          cellRenderer={otherSideRenderer}
          flexGrow={4}
          disableSort
        />
        {!hideDescription && (
          <Column className="description" dataKey="description" label="description" flexGrow={6} disableSort />
        )}
        <Column dataKey="attorneyResponsibleInitials" label={t('attorney')} flexGrow={1} />
        {showFinancials && (
          <Column
            className="right-align unbilled"
            dataKey="unbilled"
            label="unbilled"
            cellRenderer={balanceCellLocalisedRenderer}
            cellDataGetter={getTotalsData}
            defaultSortDirection="DESC"
            flexGrow={1}
          />
        )}
        {showFinancials && (
          <Column
            className="right-align unpaid"
            dataKey="unpaidExcInterest"
            label="due"
            cellRenderer={balanceCellLocalisedRenderer}
            cellDataGetter={getTotalsData}
            defaultSortDirection="DESC"
            flexGrow={1}
          />
        )}
        {showStatus && <Column dataKey="status" label="status" width={100} />}
      </Table>
    );
  }
}

function refOverdrawRenderer({ cellData, rowData: { isOverdrawnTrustAccount }, t }) {
  return (
    <div>
      {isOverdrawnTrustAccount && (
        <div className={classNames('ref-overdraw-icon', !cellData && 'ref-no-data')}>
          <Icon type="alert-1" tooltip={`Overdrawn ${t('trustAccount')}`} color="red" />
        </div>
      )}
    </div>
  );
}

function refRenderer({ cellData, rowData: { isOverdrawnTrustAccount }, t }) {
  return (
    <div>
      {cellData && (
        <div title={cellData} className="ref-text">
          {cellData}
        </div>
      )}
      {isOverdrawnTrustAccount && (
        <div className={classNames('ref-overdraw-icon', !cellData && 'ref-no-data')}>
          <Icon type="alert-1" tooltip={`Overdrawn ${t('trustAccount')}`} color="red" />
        </div>
      )}
    </div>
  );
}

function matterTypeRenderer({ rowData: { matterTypeId } }) {
  return <MatterTypeDisplay id={matterTypeId} />;
}

function clientRenderer({ rowData }) {
  return [...rowData.clientCustomerIds].map((clientId, index, clientCustomerIds) => (
    <span key={clientId}>
      <ContactDisplay contactId={clientId} showLastNameFirst inline />
      {index !== clientCustomerIds.length - 1 && ' | '}
    </span>
  ));
}

function otherSideRenderer({ rowData }) {
  return [...rowData.otherSideCustomerIds].map((otherSideCustomerId, index, otherSideCustomerIds) => (
    <span key={otherSideCustomerId}>
      <ContactDisplay contactId={otherSideCustomerId} showLastNameFirst inline />
      {index !== otherSideCustomerIds.length - 1 && ' | '}
    </span>
  ));
}

function getTotalsData({ rowData, dataKey }) {
  // For some of the matters its been observed that the totals is undefined.
  return rowData.totals ? rowData.totals[dataKey] : 0;
}

MatterTable.defaultProps = {
  sortBy: 'clientDisplay',
  sortDirection: 'asc',
  matters: [],
};

MatterTable.propTypes = {
  onMatterLink: PropTypes.func,
  matters: PropTypes.array,
  showFinancials: PropTypes.bool,
  showStatus: PropTypes.bool,
  hideDescription: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  sort: PropTypes.func,
  hideClient: PropTypes.bool,
  t: PropTypes.func.isRequired, // withTranslation
};

// for now I will set all props optional as undefined. The idea is not validating anymore in the component
// and start using the default props. I wont do it in the components we already have but keep in mind from now
// if you are working or modifying this component please update the prop types. GP
MatterTable.defaultProps = {
  onMatterLink: undefined,
  matters: undefined,
  showFinancials: undefined,
  showStatus: undefined,
  hideDescription: undefined,
  sortDirection: undefined,
  sortBy: undefined,
  sort: undefined,
  hideClient: false,
};

export default withTranslation()(MatterTable);
