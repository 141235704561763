import React from 'react';
import PropTypes from 'prop-types';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { FeeWisePaymentsRoute } from './fee-wise';

const paymentProviderPaymentsRouteComponents = {
  [providers.FEE_WISE]: FeeWisePaymentsRoute,
};

export const BillingAccountsPaymentProviderPaymentsRouteContainer = ({ onClickLink, providerType }) => {
  const ProviderSpecificRouteComponent = paymentProviderPaymentsRouteComponents[providerType];

  if (!ProviderSpecificRouteComponent) {
    return null;
  }

  return <ProviderSpecificRouteComponent onClickLink={onClickLink} />;
};

BillingAccountsPaymentProviderPaymentsRouteContainer.propTypes = {
  providerType: PropTypes.string,
  onClickLink: PropTypes.func,
};

BillingAccountsPaymentProviderPaymentsRouteContainer.defaultProps = {
  providerType: undefined,
  onClickLink: () => {},
};
