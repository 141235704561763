const factory = require('@sb-itops/fe-logger');

angular.module('@sb-itops/services').provider('sbLoggerService', function() {
  'use strict';

  const that = this;

  that.setDefaultLevel = factory.setDefaultLevel;
  that.$get = function ($log, $rootScope) {
    factory.setLogger(console);
    factory.on(factory.event.LOG_ERROR, (data) => $rootScope.$broadcast('log-error', data));

    return {
      getLogger: factory.getLogger,
      setDefaultLevel: factory.setDefaultLevel
    };
  };
});
