import { interpolateText } from '@sb-billing/business-logic/credit-card-add-request/services';
import { getFirmName, getPhoneNumber, getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { formatContactSalutation } from '@sb-customer-management/business-logic/contacts-summary/services';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';

export const getCreditCardSaveRequestEmailInterpolated = async (creditCardSaveRequest) => {
  const people = await fetchPeopleP(creditCardSaveRequest.contactId).catch(() => []);

  const data = {
    firmName: getFirmName(),
    phoneNumber: getPhoneNumber(),
    staffName: getStaffEmailDetails().name,
    showAddCardButton: true,
    debtorName: formatContactSalutation(people),
  };
  const subject = interpolateText(creditCardSaveRequest.subject, data);
  const message = interpolateText(creditCardSaveRequest.message, data);

  return { subject, message };
};
