import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InvoiceStatementData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import {
  isErrorSmokeballPreChargeError,
  calculateFeeDetails,
  extractFeeSchedule,
  getMinChargeAmountInCents,
} from '@sb-billing/business-logic/payment-provider/services';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { feeCoverageModes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { addBatchPayment } from '@sb-billing/redux/invoices/batch-pay-invoice';
import { saveCard } from '@sb-billing/redux/payment-provider';
import {
  getActiveProvider,
  getProviderSettings,
  isFirmCardSavingEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { getInvoiceSummariesByFilter, getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getLoggedInStaff, getFirmName } from '@sb-firm-management/redux/firm-management';
import { sort as sortItems } from '@sb-itops/sort';
import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchPostP } from '@sb-itops/redux/fetch';
import * as forms from '@sb-itops/redux/forms2';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { createBatchClientInvoicePaymentChargeRequest } from '@sb-billing/business-logic/payment-provider/requests/create-batch-client-invoice-payment-charge-request';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { useTranslation } from '@sb-itops/react';
import { getValidateFn } from '../invoice-statement-payment-add-form';
import { InvoiceStatementPaymentAddModal } from './InvoiceStatementPaymentAddModal';
import { ADDING_PAYMENT_STEP, TAKING_PAYMENT_STEP } from '../payment-add-form/PaymentAddForm';

const INVOICE_STATEMENT_PAYMENT_ADD_SCOPE = 'invoice-statement-payment-add-form';
export const INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID = 'invoice-statement-payment-add-modal';
const ALLOCATE_FUNDS_GROUP_NAME = 'ALLOCATE_FUNDS';
const region = getRegion();

const hooks = ({ invoiceStatementId, onClose, isVisible }) => ({
  useSelectors: () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectors: formSelectors, operations: formOperations } = useScopedFeature(
      forms,
      INVOICE_STATEMENT_PAYMENT_ADD_SCOPE,
    );
    const formState = useSelector(formSelectors.getFormState);
    const { payments = [] } = useSelector(formSelectors.getFieldValues);
    const [currentStep, setCurrentStep] = useState(ADDING_PAYMENT_STEP);
    const [paymentsContainsUnpaidAnticipatedDisbursement, setPaymentsContainsUnpaidAnticipatedDisbursement] =
      useState(false);

    const [totalChargeAmount, setTotalChargeAmount] = useState();
    const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
    const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
    const [paymentFormData, setPaymentFormData] = useState(null);
    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();

    const { data: invoiceStatementData, loading: invoiceStatementLoading } = useSubscribedQuery(InvoiceStatementData, {
      variables: {
        invoiceStatementId,
      },
    });
    const { number: invoiceStatementNumber, debtor, invoices } = invoiceStatementData?.invoiceStatement || {};
    const debtorId = debtor?.id;
    const invoiceIds = invoices?.map((i) => i.id);
    const invoiceSummaries = getInvoiceSummaries({ invoiceIds });

    const onSaveCard = useCallback(async (saveCardFormData) => {
      const providerType = getActiveProvider();
      const payorId = saveCardFormData?.smokeballFormData?.payorId;
      const bankAccountId = saveCardFormData?.smokeballFormData?.bankAccountId;

      try {
        return await saveCard({
          bankAccountId,
          contactId: payorId,
          providerType,
          saveCardFormData,
        });
      } catch (error) {
        messageDisplay.error('Failed to save card details');
        throw error;
      }
    }, []);

    const onTakePaymentSuccess = useCallback(() => {
      messageDisplay.success(
        getTakePaymentMessage(true, {
          amount: paymentFormData?.smokeballFormData?.totalAmount,
          invoices: paymentFormData?.smokeballFormData?.invoices,
          payorId: paymentFormData?.smokeballFormData?.payorId,
          source: paymentFormData?.smokeballFormData?.source,
          t,
        }),
      );

      onTakePaymentComplete();
      onClose();
    }, [paymentFormData, t]);

    const onTakePaymentFailed = useCallback(
      ({ failureMessage }) => {
        messageDisplay.error(
          getTakePaymentMessage(false, {
            amount: paymentFormData?.smokeballFormData?.totalAmount,
            invoices: paymentFormData?.smokeballFormData?.invoices,
            payorId: paymentFormData?.smokeballFormData?.payorId,
            source: paymentFormData?.smokeballFormData?.source,
            failureMessage,
            t,
          }),
        );

        onTakePaymentComplete();
      },
      [paymentFormData, t],
    );

    const onTakePaymentComplete = () => {
      setPaymentFormData(null);
      setTriggerChargeFormSubmit(false);
    };

    useEffect(() => {
      const callback = (response) => {
        const message = JSON.parse(response);

        if (message.status !== 'FAILED') {
          onTakePaymentSuccess();
        } else {
          onTakePaymentFailed({ failureMessage: message?.smokeballResponse?.failure?.message });
        }
      };
      return subscribeToNotifications({
        notificationIds: ['PaymentProviderChargeCompleted'],
        callback,
      });
    }, [onTakePaymentFailed, onTakePaymentSuccess]);

    const isReadyToTakePayment = formState.formValid && chargeFormReadyForSubmit;
    const isSubmitting = triggerChargeFormSubmit || formState.formSubmitting;

    // we specifically want to display errors for reference, amount, effectiveDate
    const errors = [];
    if (formState?.fields?.reference?.invalidReason) {
      errors.push(formState?.fields?.reference?.invalidReason);
    }
    if (formState?.fields?.amount?.invalidReason) {
      errors.push(formState?.fields?.amount?.invalidReason);
    }
    if (formState?.fields?.effectiveDate?.invalidReason) {
      errors.push(formState?.fields?.effectiveDate?.invalidReason);
    }

    useEffect(() => {
      if (payments && isPaymentsContainsUnpaidAnticipatedDisbursement(payments)) {
        setPaymentsContainsUnpaidAnticipatedDisbursement(true);
      } else {
        setPaymentsContainsUnpaidAnticipatedDisbursement(false);
      }
    }, [payments]);

    if (paymentsContainsUnpaidAnticipatedDisbursement) {
      errors.push(`A selected invoice contains an unpaid anticipated ${t('expense')}`);
    }

    const providerType = getActiveProvider();
    const minAmountAllowed = providerType ? getMinChargeAmountInCents({ providerType, region }) : 0;

    const validate = async (event) => {
      event.preventDefault();
      await dispatch(formOperations.validateForm({ validateFn: getValidateFn({ t, minAmountAllowed }) }));
    };

    const onAddPayment = async () => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              const data = marshalData(formFieldValues);
              await payInvoices(data);
              messageDisplay.success('Payment added successfully');
              onClose();
            },
          }),
        );
      } catch (err) {
        messageDisplay.error(messageDisplay.builder().text('Failed to process batch payments'));
      }
    };

    const onTakePayment = async (providerFormData) => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              const smokeballFormData = marshalData(formFieldValues, chargeFormData);

              const providerSpecificSettings = getProviderSettings(providerType);
              const staff = getLoggedInStaff();
              const staffName = staff.name || 'firm staff member';
              const firmName = getFirmName();
              const feeCoverageMode = providerSpecificSettings.clientCoversFeeOnPayments
                ? feeCoverageModes.CLIENT_PAYS
                : feeCoverageModes.FIRM_PAYS;
              setPaymentFormData({ providerFormData, smokeballFormData });

              const bankAccountId = smokeballFormData?.bankAccountId;
              const isCardSavingEnabled =
                bankAccountId && isFirmCardSavingEnabledForBankAccount({ providerType, bankAccountId });
              if (isCardSavingEnabled && smokeballFormData?.saveCardDetails) {
                const savedCard = await onSaveCard({ providerFormData, smokeballFormData });

                if (savedCard) {
                  await makeCharge({
                    providerType,
                    paymentFormData: {
                      providerFormData: {
                        ...providerFormData,
                        paymentToken: {
                          ...providerFormData.paymentToken,
                          id: savedCard.token,
                        },
                      },
                      smokeballFormData,
                    },
                    staffName,
                    firmName,
                    feeCoverageMode,
                    t,
                  });
                }
              } else {
                await makeCharge({
                  providerType,
                  paymentFormData: { providerFormData, smokeballFormData },
                  staffName,
                  firmName,
                  feeCoverageMode,
                  t,
                });
              }
            },
          }),
        );
      } catch (err) {
        if (isErrorSmokeballPreChargeError(err)) {
          const errText = `The card was declined by the card issuer: ${err.message}`;
          messageDisplay.error(errText);
        } else {
          messageDisplay.error(messageDisplay.builder().text('Failed to process batch payments'));
        }
        setTriggerChargeFormSubmit(false);
      }
    };

    const formattedProviderSpecificSettings = getProviderSettings(providerType);
    const clientIsCoveringFee = formattedProviderSpecificSettings?.clientCoversFeeOnPayments;

    return {
      totalChargeAmount,
      clientIsCoveringFee,
      currentStep,
      debtorId,
      errors,
      invoiceSummaries,
      invoiceStatementLoading,
      invoiceStatementNumber,
      scope: INVOICE_STATEMENT_PAYMENT_ADD_SCOPE,
      isVisible,
      isSubmitDisabled: isDisabled({ currentStep, isSubmitting, isReadyToTakePayment }),
      isSubmitLocked: isSubmitting,
      isTakingPaymentNow: formState?.fields?.takePaymentNow?.value,
      isReadyToTakePayment,
      onChargeFormDataChange: (providerSpecificChargeData) => {
        setChargeFormData(providerSpecificChargeData);
        // Since charge form change may change fee and therefore total amount charged, we must recalculate
        if (clientIsCoveringFee) {
          const desiredAmountInCents = formState?.fields?.amount?.value || 0;

          const feeSchedule = clientIsCoveringFee
            ? extractFeeSchedule({
                providerType,
                formattedProviderSpecificSettings,
                bankAccountId: getOperatingAccount().id,
              })
            : undefined;

          const feeDetails =
            clientIsCoveringFee &&
            calculateFeeDetails({
              providerType,
              feeSchedule,
              desiredAmountInCents,
              providerSpecificFields: providerSpecificChargeData,
            });

          setTotalChargeAmount(feeDetails.effectiveAmountInCents);
        }
      },
      onChargeFormSubmit: onTakePayment,
      onChargeFormReadyForSubmit: (isReady) => setChargeFormReadyForSubmit(!!isReady),
      triggerChargeFormSubmit,
      onSave: async (event) => {
        await validate(event);

        if (!payments.length) {
          toggleAllocateFundsWarning(true);
          // fall through so form fails and highlight fields
        }

        if (formState?.fields?.takePaymentNow?.value === true) {
          if (currentStep.id === TAKING_PAYMENT_STEP.id) {
            setTriggerChargeFormSubmit(true);
          } else if (formState.formValid === true) {
            toggleAllocateFundsWarning(false);
            setCurrentStep(TAKING_PAYMENT_STEP);
          }
        } else {
          toggleAllocateFundsWarning(false);
          onAddPayment();
        }
      },
      onClose,
    };
  },
});

function getInvoiceSummaries({ invoiceIds }) {
  if (invoiceIds && invoiceIds.length === 0) {
    return [];
  }

  const invoiceSummaries = getInvoiceSummariesByFilter({
    status: 'FINAL',
    invoiceIds,
  });
  const invoiceSummariesSorted = sortItems(invoiceSummaries, ['invoiceNumber'], ['asc']);

  return invoiceSummariesSorted.map((invoiceSummary) => {
    const invoiceTotals = getTotalsForInvoiceId(invoiceSummary.invoiceId);
    const totals = {
      total: invoiceTotals?.total || 0,
      billed: invoiceTotals?.billed || 0,
      unpaid: invoiceTotals?.unpaid || 0,
      paid: invoiceTotals?.paid || 0,
      unpaidExcInterest: invoiceTotals?.unpaidExcInterest || 0,
      interest: invoiceTotals?.interest || 0,
    };

    return { ...invoiceSummary, ...totals, withPaymentPlan: true };
  });
}

const makeCharge = async ({ providerType, paymentFormData, staffName, firmName, feeCoverageMode, t }) => {
  const newCharge = createBatchClientInvoicePaymentChargeRequest({
    accountId: getAccountId(),
    requestedByUserId: getUserId(),
    providerType,
    paymentFormData,
    staffName,
    firmName,
    feeCoverageMode,
    t,
  });

  const path = `/billing/payment-provider/charge/${providerType.toLowerCase()}/${newCharge.accountId}/`;
  const fetchOptions = { body: JSON.stringify(newCharge) };
  const response = await fetchPostP({ path, fetchOptions });

  return response.body;
};

const isDisabled = ({ currentStep, isReadyToTakePayment, isSubmitting }) => {
  if (currentStep.id === TAKING_PAYMENT_STEP.id && !isReadyToTakePayment) {
    return true;
  }

  return isSubmitting;
};

const isPaymentsContainsUnpaidAnticipatedDisbursement = (payments) => {
  if (!featureActive('BB-9573')) return false;

  return payments.some((payment) => {
    const invoice = getInvoiceById(payment.invoiceId);

    return hasUnpaidAnticipatedDisbursements({
      invoice: invoice.currentVersion,
      getExpenseById,
      getExpensePaymentDetailsById,
    });
  });
};

const getTakePaymentMessage = (success, { amount, invoices, payorId, source, failureMessage, t }) => {
  const payorName = getContactDisplay(payorId);
  const amountWithSymbol = t('cents', { val: amount });

  let message = `A payment of ${amountWithSymbol} `;

  if (invoices.length > 1) {
    message += `for ${invoices.length} invoices `;
  } else {
    message += `against invoice #${invoices[0].invoiceNumber} `;
  }

  message += success ? `was successfully charged ` : `failed to be charged `;

  message += `to ${payorName}'s ${source}. ${!success ? failureMessage || '' : ''}`;

  return message;
};

const marshalData = (formFieldValues, providerSpecificChargeData) => {
  const {
    source,
    contactId,
    reference,
    comment,
    payments,
    reason,
    effectiveDate,
    amount,
    paymentType,
    takePaymentNow,
    saveCardDetails,
  } = formFieldValues;

  const msg = {
    paymentId: uuid(),
    payorId: contactId,
    source: source.value,
    invoices: payments,
    reference,
    note: comment,
    effectiveDate,
    totalAmount: amount,
    paymentType, // used by backend to determine which message to send
    reason,
    isElectronicPayment: false,
  };

  if (hasFacet(facets.allowOverdraw)) {
    msg.allowOverdraw = true;
  }

  // These values are needed for handling charges in onTakePayment function
  if (takePaymentNow) {
    const bankAccountId = getOperatingAccount().id;
    msg.bankAccountId = bankAccountId;
    msg.saveCardDetails = saveCardDetails;

    // Handle fees
    const providerType = getActiveProvider();
    // Calculate the fee details if the fee is being passed on to the client.
    const formattedProviderSpecificSettings = getProviderSettings(providerType);
    const clientIsCoveringFee = formattedProviderSpecificSettings.clientCoversFeeOnPayments;

    if (clientIsCoveringFee) {
      const feeSchedule = clientIsCoveringFee
        ? extractFeeSchedule({
            providerType,
            formattedProviderSpecificSettings,
            bankAccountId,
          })
        : undefined;

      const feeDetails = clientIsCoveringFee
        ? calculateFeeDetails({
            providerType,
            feeSchedule,
            providerSpecificFields: providerSpecificChargeData,
            desiredAmountInCents: amount || 0,
          })
        : {};

      msg.amountInCents = feeDetails.effectiveAmountInCents;
      msg.amountLessFees = feeDetails.desiredAmountInCents;
    } else {
      msg.amountInCents = amount;
      msg.amountLessFees = amount;
    }

    // Individual invoices need to have the paymentId attached
    // If amount is less than invoices totalPayments then apply to oldest invoice first
    const paymentIds = [];
    const totalPayments = payments.reduce((runningTotal, invoicePayment) => runningTotal + invoicePayment.amount, 0);
    if (amount !== totalPayments) {
      let remaining = amount;
      let invoices = [];
      for (let i = 0; i <= payments.length; i += 1) {
        let invoice = payments[i];

        if (!paymentIds[invoice.matterId]) {
          paymentIds[invoice.matterId] = uuid();
        }
        const paymentId = paymentIds[invoice.matterId];

        if (remaining < invoice.amount) {
          invoice = { ...invoice, amount: remaining, paymentId };
          invoices = [...invoices, invoice];
          break;
        }

        invoices = [...invoices, { ...invoice, paymentId }];

        remaining -= invoice.amount;
      }
      msg.invoices = invoices;
    } else {
      msg.invoices = payments.map((invoice) => {
        if (!paymentIds[invoice.matterId]) {
          paymentIds[invoice.matterId] = uuid();
        }
        const paymentId = paymentIds[invoice.matterId];

        return { ...invoice, paymentId };
      });
    }
  }

  return msg;
};

const payInvoices = async (payment) => {
  const payload = { ...payment };
  return addBatchPayment(payload);
};

const toggleAllocateFundsWarning = (show = true) => {
  messageDisplay.dismissGroup(ALLOCATE_FUNDS_GROUP_NAME);

  if (show) {
    messageDisplay.warn(
      messageDisplay.builder().text(`Please allocate funds to at least one invoice`).group(ALLOCATE_FUNDS_GROUP_NAME),
    );
  }
};

export const InvoiceStatementPaymentAddModalContainer = withReduxProvider(
  composeHooks(hooks)(InvoiceStatementPaymentAddModal),
);

InvoiceStatementPaymentAddModalContainer.displayName = 'InvoiceStatementPaymentAddModalContainer';

InvoiceStatementPaymentAddModalContainer.propTypes = {
  invoiceStatementId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
};

InvoiceStatementPaymentAddModalContainer.defaultProps = {
  isVisible: false,
  onClose: () => {},
};
