'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const surchargeApplyToValues = Object.freeze({
  // none: 0,
  FEES: 1,
  // expenses: 2,
  FEES_AND_EXPENSES: 3,
});

const surchargeApplyToLabels = Object.freeze({
  // none: 'None',
  FEES: 'Time & Fees Only',
  // expenses: 'Expenses',
  FEES_AND_EXPENSES: 'Time & Fees and Expenses',
});

const surchargeApplyToOptions = getTypeAsOptions({
  typeLabels: surchargeApplyToLabels,
  typeValues: surchargeApplyToValues,
});

module.exports = {
  surchargeApplyToLabels,
  surchargeApplyToValues,
  surchargeApplyToOptions,
};
