import { gql } from '@apollo/client';

const query = gql`
  query InvoiceTableStatusesCountsData($invoiceListFilter: InvoiceListFilter) {
    invoiceList(filter: $invoiceListFilter) {
      totalStatusCounts {
        DRAFT
        FINAL
        PAID
        DELETED
        VOID
        OVERDUE
      }
    }
  }
`;

const notificationIds = ['WebQueryInvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceTableStatusesCountsData = {
  query,
  notificationIds,
};
