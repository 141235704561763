import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onDeleteAppointment = ({ commandMessage }) => {
  const optimisticAppointment = {
    __typename: 'Appointment',
    ...commandMessage,
    isRemoved: true,
  };

  const apolloClient = getApolloClient();

  const appointmentIdentifier = apolloClient.cache.identify(optimisticAppointment);

  apolloClient.writeFragment({
    id: appointmentIdentifier,
    fragment: gql`
      fragment OpdateRemoveAppointment on Appointment {
        ${Object.keys(optimisticAppointment).join('\n')}
      }
    `,
    data: {
      ...optimisticAppointment,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_REMOVED,
    typeName: 'Appointment',
    optimisticEntity: optimisticAppointment,
  };
};
