import React, { memo, useState } from 'react';
import classnames from 'classnames';
import { Button } from '@sb-itops/react/button';
import { CreditCardSaveModal } from 'web/react-redux/components/credit-card-save-modal';
import { CreditCardSaveRequestModal } from 'web/react-redux/components/credit-card-save-request-modal';
import { PaymentMethodsTable } from 'web/react-redux/components/payment-methods-table';
import PropTypes from 'prop-types';
import Styles from './ContactCreditCard.module.scss';
import { DeleteCardModal } from './DeleteCardModal';

const ContactCreditCard = memo(
  ({
    onNewCreditCard,
    onCloseNewCreditCard,
    onCloseDeleteCreditCard,
    onCreditCardSubmit,
    onCreditCardDelete,
    onOpenReplaceCard,
    onOpenDeleteConfirmation,
    isSaveModalVisible,
    isSubmitting,
    bankAccountId,
    isNewCreditCardEnabled,
    isNewCreditCardRequestEnabled,
    paymentMethods,
    selectedCard,
    deleteModalId,
    currentPaymentPlanMethodId,
    contactId,
    activeProvider,
    addNewCardTooltip,
  }) => {
    const [showSaveRequestModal, setShowSaveRequestModal] = useState(false);
    const cardInUse = !!selectedCard && currentPaymentPlanMethodId === selectedCard.token;

    return (
      <div className="master-detail-panel">
        <CreditCardSaveRequestModal
          isVisible={showSaveRequestModal}
          onClose={() => setShowSaveRequestModal(false)}
          contactId={contactId}
        />
        <CreditCardSaveModal
          isVisible={isSaveModalVisible}
          isSubmitting={isSubmitting}
          onClose={onCloseNewCreditCard}
          onSubmit={onCreditCardSubmit}
          bankAccountId={bankAccountId}
          selectedCard={selectedCard}
          contactId={contactId}
        />
        <DeleteCardModal
          onProceed={onCreditCardDelete}
          onClose={onCloseDeleteCreditCard}
          modalId={deleteModalId}
          cardInUse={cardInUse}
        />
        <div className={classnames('panel-body', Styles.panelContainer)}>
          <div className={Styles.viewPanel}>
            <div className={Styles.viewToolbar}>
              <Button
                title={addNewCardTooltip}
                className={Styles.toolbarButton}
                onClick={onNewCreditCard}
                type="tertiary"
                disabled={!isNewCreditCardEnabled}
              >
                Add Credit Card
              </Button>
              {isNewCreditCardRequestEnabled && (
                <Button
                  className={Styles.toolbarButton}
                  type="tertiary"
                  onClick={() => setShowSaveRequestModal(true)}
                  disabled={!isNewCreditCardEnabled}
                >
                  Request Credit Card
                </Button>
              )}
            </div>

            <PaymentMethodsTable
              paymentMethods={paymentMethods}
              onRowClick={onOpenReplaceCard}
              onDeleteClick={onOpenDeleteConfirmation}
              activeProvider={activeProvider}
            />
          </div>
        </div>
      </div>
    );
  },
);

ContactCreditCard.propTypes = {
  onNewCreditCard: PropTypes.func.isRequired,
  onCloseNewCreditCard: PropTypes.func.isRequired,
  onCreditCardSubmit: PropTypes.func.isRequired,
  isSaveModalVisible: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  isNewCreditCardEnabled: PropTypes.bool.isRequired,
  isNewCreditCardRequestEnabled: PropTypes.bool.isRequired,
  selectedCard: PropTypes.object,
  onCloseDeleteCreditCard: PropTypes.func.isRequired,
  onCreditCardDelete: PropTypes.func.isRequired,
  onOpenReplaceCard: PropTypes.func.isRequired,
  onOpenDeleteConfirmation: PropTypes.func.isRequired,
  deleteModalId: PropTypes.string.isRequired,
  currentPaymentPlanMethodId: PropTypes.string,
  paymentMethods: PropTypes.array.isRequired,
  contactId: PropTypes.string.isRequired,
  activeProvider: PropTypes.string.isRequired,
  addNewCardTooltip: PropTypes.string.isRequired,
};

ContactCreditCard.defaultProps = {
  selectedCard: undefined,
  currentPaymentPlanMethodId: undefined,
};

export default ContactCreditCard;
