'use strict';

/**
 * Extract emails from a string
 * @param {String} string string as per what a user might enter into to, cc, bcc fields
 * @returns {Array<String>} returns an array of lower-cased emails or an empty array if no emails found
 */
const extractEmailsFromString = (string) => {
  if (!string) {
    return [];
  }

  // normalise string to use white space delimiter and split it into an array
  const potentialEmails = string.toLowerCase().trim().replace(/[;|,]/g, ' ').split(/\s+/);

  // filter out non-emails, this way of matching is more precise than a pure regex approach
  const matches = potentialEmails.reduce((acc, potentialEmail) => {
    const matches = potentialEmail.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g);
    return matches ? acc.concat(matches) : acc;
  }, []);

  return matches && matches.length
    ? matches.map(email => email.toLowerCase().trim())
    : [];
};

module.exports = {
  extractEmailsFromString,
};
