module.exports = [
  {
    htmlEntity: '&Agrave;',
    rtfEscapeChar: "\\'c0",
  },
  {
    htmlEntity: '&Aacute;',
    rtfEscapeChar: "\\'c1",
  },
  {
    htmlEntity: '&Acirc;',
    rtfEscapeChar: "\\'c2",
  },
  {
    htmlEntity: '&Atilde;',
    rtfEscapeChar: "\\'c3",
  },
  {
    htmlEntity: '&Auml;',
    rtfEscapeChar: "\\'c4",
  },
  {
    htmlEntity: '&Aring;',
    rtfEscapeChar: "\\'c5",
  },
  {
    htmlEntity: '&AElig;',
    rtfEscapeChar: "\\'c6",
  },
  {
    htmlEntity: '&Ccedil;',
    rtfEscapeChar: "\\'c7",
  },
  {
    htmlEntity: '&Egrave;',
    rtfEscapeChar: "\\'c8",
  },
  {
    htmlEntity: '&Eacute;',
    rtfEscapeChar: "\\'c9",
  },
  {
    htmlEntity: '&Ecirc;',
    rtfEscapeChar: "\\'ca",
  },
  {
    htmlEntity: '&Euml;',
    rtfEscapeChar: "\\'cb",
  },
  {
    htmlEntity: '&Igrave;',
    rtfEscapeChar: "\\'cc",
  },
  {
    htmlEntity: '&Iacute;',
    rtfEscapeChar: "\\'cd",
  },
  {
    htmlEntity: '&Icirc;',
    rtfEscapeChar: "\\'ce",
  },
  {
    htmlEntity: '&Iuml;',
    rtfEscapeChar: "\\'cf",
  },
  {
    htmlEntity: '&ETH;',
    rtfEscapeChar: "\\'d0",
  },
  {
    htmlEntity: '&Ntilde;',
    rtfEscapeChar: "\\'d1",
  },
  {
    htmlEntity: '&Ograve;',
    rtfEscapeChar: "\\'d2",
  },
  {
    htmlEntity: '&Oacute;',
    rtfEscapeChar: "\\'d3",
  },
  {
    htmlEntity: '&Ocirc;',
    rtfEscapeChar: "\\'d4",
  },
  {
    htmlEntity: '&Otilde;',
    rtfEscapeChar: "\\'d5",
  },
  {
    htmlEntity: '&Ouml;',
    rtfEscapeChar: "\\'d6",
  },
  {
    htmlEntity: '&Oslash;',
    rtfEscapeChar: "\\'d8",
  },
  {
    htmlEntity: '&Ugrave;',
    rtfEscapeChar: "\\'d9",
  },
  {
    htmlEntity: '&Uacute;',
    rtfEscapeChar: "\\'da",
  },
  {
    htmlEntity: '&Ucirc;',
    rtfEscapeChar: "\\'db",
  },
  {
    htmlEntity: '&Uuml;',
    rtfEscapeChar: "\\'dc",
  },
  {
    htmlEntity: '&Yacute;',
    rtfEscapeChar: "\\'dd",
  },
  {
    htmlEntity: '&THORN;',
    rtfEscapeChar: "\\'de",
  },
  {
    htmlEntity: '&szlig;',
    rtfEscapeChar: "\\'df",
  },
  {
    htmlEntity: '&agrave;',
    rtfEscapeChar: "\\'e0",
  },
  {
    htmlEntity: '&aacute;',
    rtfEscapeChar: "\\'e1",
  },
  {
    htmlEntity: '&acirc;',
    rtfEscapeChar: "\\'e2",
  },
  {
    htmlEntity: '&atilde;',
    rtfEscapeChar: "\\'e3",
  },
  {
    htmlEntity: '&auml;',
    rtfEscapeChar: "\\'e4",
  },
  {
    htmlEntity: '&aring;',
    rtfEscapeChar: "\\'e5",
  },
  {
    htmlEntity: '&aelig;',
    rtfEscapeChar: "\\'e6",
  },
  {
    htmlEntity: '&ccedil;',
    rtfEscapeChar: "\\'e7",
  },
  {
    htmlEntity: '&egrave;',
    rtfEscapeChar: "\\'e8",
  },
  {
    htmlEntity: '&eacute;',
    rtfEscapeChar: "\\'e9",
  },
  {
    htmlEntity: '&ecirc;',
    rtfEscapeChar: "\\'ea",
  },
  {
    htmlEntity: '&euml;',
    rtfEscapeChar: "\\'eb",
  },
  {
    htmlEntity: '&igrave;',
    rtfEscapeChar: "\\'ec",
  },
  {
    htmlEntity: '&iacute;',
    rtfEscapeChar: "\\'ed",
  },
  {
    htmlEntity: '&icirc;',
    rtfEscapeChar: "\\'ee",
  },
  {
    htmlEntity: '&iuml;',
    rtfEscapeChar: "\\'ef",
  },
  {
    htmlEntity: '&eth;',
    rtfEscapeChar: "\\'f0",
  },
  {
    htmlEntity: '&ntilde;',
    rtfEscapeChar: "\\'f1",
  },
  {
    htmlEntity: '&ograve;',
    rtfEscapeChar: "\\'f2",
  },
  {
    htmlEntity: '&oacute;',
    rtfEscapeChar: "\\'f3",
  },
  {
    htmlEntity: '&ocirc;',
    rtfEscapeChar: "\\'f4",
  },
  {
    htmlEntity: '&otilde;',
    rtfEscapeChar: "\\'f5",
  },
  {
    htmlEntity: '&ouml;',
    rtfEscapeChar: "\\'f6",
  },
  {
    htmlEntity: '&oslash;',
    rtfEscapeChar: "\\'f8",
  },
  {
    htmlEntity: '&ugrave;',
    rtfEscapeChar: "\\'f9",
  },
  {
    htmlEntity: '&uacute;',
    rtfEscapeChar: "\\'fa",
  },
  {
    htmlEntity: '&ucirc;',
    rtfEscapeChar: "\\'fb",
  },
  {
    htmlEntity: '&uuml;',
    rtfEscapeChar: "\\'fc",
  },
  {
    htmlEntity: '&yacute;',
    rtfEscapeChar: "\\'fd",
  },
  {
    htmlEntity: '&thorn;',
    rtfEscapeChar: "\\'fe",
  },
  {
    htmlEntity: '&yuml;',
    rtfEscapeChar: "\\'ff",
  },
  {
    htmlEntity: '&nbsp;',
    rtfEscapeChar: '\\~',
  },
  {
    htmlEntity: '&ordm;',
    rtfEscapeChar: "\\'ba",
  },
  {
    htmlEntity: '&deg;',
    rtfEscapeChar: "\\'b0",
  },
  {
    htmlEntity: '\u200B',
    rtfEscapeChar: '',
  },
];
