import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LinkableText, StatusPill } from '@sb-itops/react';
import Styles from './MattersDisplay.module.scss';

export const MattersDisplay = memo(
  ({ className, matterDisplays, tooltip, asLink, inline, onClickLink, showStatusPill }) => {
    if (matterDisplays.length === 0) {
      return null;
    }

    // Regression Checklist whenever MattersDisplay is Changed
    // JIRA: https://smokeball.atlassian.net/browse/BB-5943

    const classNames = classnames(Styles.mattersDisplay, className);

    return (
      <span className={classNames} title={tooltip}>
        <span className={Styles.mattersDisplayText}>
          {matterDisplays.map(({ matterId, display, status }, index) => (
            <Fragment key={index}>
              {index > 0 && <span className={Styles.textSeparator}>|</span>}
              {showStatusPill && <StatusPill statusLabel={status} className={Styles.statusPill} />}
              <LinkableText
                key={matterId}
                text={display}
                className={Styles.linkableText}
                asLink={asLink}
                inline={inline}
                onClickLink={() => onClickLink({ matterId })}
                tooltip={tooltip}
              />
            </Fragment>
          ))}
        </span>
        {matterDisplays.length > 1 && (
          <span className={Styles.mattersDisplayIcon}>
            <i className="icon icon-matter-multi" title={tooltip} />
          </span>
        )}
      </span>
    );
  },
);

MattersDisplay.displayName = 'MattersDisplay';

MattersDisplay.propTypes = {
  className: PropTypes.string,
  matterDisplays: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string,
      display: PropTypes.string,
    }),
  ),
  tooltip: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  showStatusPill: PropTypes.bool,
  // statusPillPosition: future extension if the need arises e.g. prepend (current), append, top, bottom
  onClickLink: PropTypes.func,
};

MattersDisplay.defaultProps = {
  className: Styles.mattersDisplay,
  matterDisplays: [],
  tooltip: '',
  asLink: false,
  inline: true,
  showStatusPill: false,
  onClickLink: () => {},
};
