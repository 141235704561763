'use strict';

const { sentViaTypes, sentViaTypeLabels } = require('./sent-via-type');

/**
 * This function returns sent via text based on the sentVia and features.
 * @param {number} sentVia
 * @param {bool} sendViaCommunicateEnabled feature flag value of BB-9097
 * @param {bool} clientCommunicationEnabled feature flag value of BB-11448
 * @returns
 */
const getSentViaText = ({ sentVia, sendViaCommunicateEnabled, clientCommunicationEnabled }) => {
  const isSentViaCommunicate = sentVia === sentViaTypes.COMMUNICATE;

  if (!sendViaCommunicateEnabled && !clientCommunicationEnabled) {
    // User might sent it via communicate previously, but now no longer access to the Communicate feature. Eg. From T3 to T1
    if (isSentViaCommunicate) {
      return ' via Client Portal';
    }
    // When Communicate feature is off and it's sent via email
    return '';
  }

  // When at least one of the features (Communicate or Client Communication) is enabled, return the appropriate label
  const sentViaType = Object.keys(sentViaTypes).find((type) => sentViaTypes[type] === sentVia);
  return sentViaTypeLabels[sentViaType] ? ` via ${sentViaTypeLabels[sentViaType]}` : '';
};

module.exports = {
  getSentViaText,
};
