'use strict';

const getRetainerDefaults = (t) => ({
  TRUST_RETAINER_EMAIL_SUBJECT: `${t('trust')} deposit request for [MATTER_NAME]`,
  TRUST_RETAINER_EMAIL_MESSAGE:
    '<p>Dear [DEBTOR_NAME],</p>' +
    '<p><br></p>' +
    `<p>Please make a deposit of ${t('currencySymbol')}[AMOUNT_REQUESTED] to bring your account up to ${t(
      'currencySymbol',
    )}[REPLENISH_UP_TO], to your ${t(
      'trustAccount',
    )} for the above matter, which will be applied as a retainer on your account.</p>` +
    '<p><br></p>' +
    '<p>If you have any questions or concerns, please don’t hesitate to contact me.</p>' +
    '<p><br></p>' +
    '<p>Kind regards,</p>' +
    '<p><br></p>' +
    '<p>[USER_NAME]</p>',
  MINIMUM_TRUST_RETAINER_ACTIVE: false,
  MINIMUM_TRUST_RETAINER_AMOUNT: 100,
  TRUST_RETAINER_REPLENISH_AMOUNT: 1000,
  DO_NOT_SEND_REPLENISH_EMAIL_TO_USER: false,
});

module.exports = {
  getRetainerDefaults,
};
