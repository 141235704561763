import * as React from 'react';

import Styles from './MatterNavigationPanel.module.scss';
import { panelItemsType } from './types';

import { MatterNavigationPanelItem } from './matter-navigation-panel-item';

export const MatterNavigationPanel = ({ navPanelItems }) => (
  <div className={Styles.navPanel}>
    <h4>Quick Links:</h4>
    {navPanelItems.map(({ title, display, isActive, isErrorState, handleScroll }) => (
      <MatterNavigationPanelItem
        key={title}
        title={title}
        display={display}
        isActive={isActive}
        isErrorState={isErrorState}
        handleScroll={handleScroll}
      />
    ))}
  </div>
);

MatterNavigationPanel.displayName = 'MatterNavigationPanel';

MatterNavigationPanel.propTypes = {
  navPanelItems: panelItemsType.isRequired,
};
