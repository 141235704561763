export const marshallSettings = ({ showInvoiceLink, showScanToPay, bankAccounts }) => {
  const settingMappings = bankAccounts.reduce(
    (mappings, bankAccount) => {
      if (bankAccount.MerchantAccount) {
        mappings.cardBankAccountMappings.push({
          sbbBankAccountId: bankAccount.id,
          lawpayBankAccountId: bankAccount.MerchantAccount.value,
        });
      }
      if (bankAccount.AchAccount) {
        mappings.eCheckBankAccountMappings.push({
          sbbBankAccountId: bankAccount.id,
          lawpayBankAccountId: bankAccount.AchAccount.value,
        });
      }

      return mappings;
    },
    {
      cardBankAccountMappings: [],
      eCheckBankAccountMappings: [],
    },
  );

  return { showInvoiceLink, showScanToPay, ...settingMappings };
};
