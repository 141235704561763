import React from 'react';
import { forms2PropTypes } from '@sb-itops/react';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from '../TransferFundsForm.module.scss';

export const MatterErrorDisplay = ({ onOpenEditContactModal, matterIdField }) => (
  <div className={classnames(Styles.errorMsg, Styles.errorMsgContainer, Styles.contactError)}>
    {matterIdField.invalidReason &&
      typeof matterIdField.invalidReason === 'string' &&
      matterIdField.invalidReason
        .split('\n')
        .map((individualReason) => (
          <InterpolatedDescription
            key={individualReason}
            description={individualReason}
            deleted={false}
            onClickLink={() => {}}
            isError
            onClickDisplay={onOpenEditContactModal}
          />
        ))}
  </div>
);

MatterErrorDisplay.displayName = 'AvailableBalances';
const { Forms2Field } = forms2PropTypes;
MatterErrorDisplay.propTypes = {
  matterIdField: Forms2Field,
  onOpenEditContactModal: PropTypes.func.isRequired,
};

MatterErrorDisplay.defaultProps = {
  matterIdField: undefined,
};
