const { pick: pickValueFromObject } = require('dot-object');

module.exports = ({ property, startingValue = 0, nanValue = 0 }) => {
  if (!property) {
    throw new Error(`'extractTotal' requires a valid property parameter`);
  }

  return (dataPoint, currentValue) => {
    const rawDataPointValue  = pickValueFromObject(property, dataPoint); 
    const dataPointValue = Number.isNaN(rawDataPointValue) ? nanValue : rawDataPointValue || 0;
    return (currentValue || startingValue) + dataPointValue;
  };
};
