import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './MultiSelectFilters.module.scss';
import { SlidingToggle } from '../sliding-toggle';

class MultiSelectFilters extends React.Component {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.getOptionElements = this.getOptionElements.bind(this);
  }

  onClickHandler(event) {
    event.preventDefault();

    if (Object.keys(event.target.dataset).length <= 0) {
      return;
    }

    // Control click switches on the control clicked switch and switches all others off.
    const selected = !(event.target.dataset.selected === 'true');

    if (event.ctrlKey && selected) {
      this.props.onForceSelect(event.target.dataset.name);
      return;
    }

    this.props.onSelect(event.target.dataset.name, selected);
  }

  getOptionElements() {
    const { id, filterOptions, showCounter, counterDataLoading } = this.props;

    return filterOptions.map((option) => (
      <span
        key={option.id}
        data-name={option.id}
        data-selected={option.selected}
        className={classnames(Styles.option, this.props.direction === 'row' ? Styles.optionRow : Styles.optionCol)}
        role="button"
        onClick={this.onClickHandler}
        tabIndex={0}
        id={`${option.id.toLowerCase()}-filter-container`}
      >
        <span className={Styles.optionName}>{option.name}</span>
        <div
          className={classnames(
            Styles.counterAndToggleContainer,
            this.props.direction === 'row' ? Styles.counterAndToggleContainerRow : Styles.counterAndToggleContainerCol,
          )}
        >
          {showCounter && (
            <span className={Styles.counterBadge} id={`${option.id.toLowerCase()}-counter-value`}>
              {counterDataLoading ? '..' : option.count}
            </span>
          )}
          <SlidingToggle scope={id} name={option.id} selected={option.selected} />
        </div>
      </span>
    ));
  }

  render() {
    const cssClasses = classnames(
      Styles.itopsMultiSelect,
      this.props.direction === 'row' ? Styles.itopsMultiSelectRow : Styles.itopsMultiSelectCol,
      this.props.className,
    );

    return (
      <div className={cssClasses} id={this.props.id}>
        {this.getOptionElements()}
      </div>
    );
  }
}

MultiSelectFilters.displayName = 'MultiSelectFilters';

MultiSelectFilters.propTypes = {
  counterDataLoading: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      count: PropTypes.number,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onForceSelect: PropTypes.func,
  id: PropTypes.string.isRequired,
  showCounter: PropTypes.bool,
  className: PropTypes.string,
  direction: PropTypes.string,
};

MultiSelectFilters.defaultProps = {
  counterDataLoading: false,
  showCounter: false,
  onForceSelect: () => {},
  className: undefined,
  direction: 'column',
};

export default MultiSelectFilters;
