export const HTTP_METHOD = Object.freeze({
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
});

export const RESPONSE_TYPE = Object.freeze({
  arrayBuffer: 'arrayBuffer',
  blob: 'blob',
  json: 'json',
  text: 'text',
});

export const responseHandler = {
  [RESPONSE_TYPE.arrayBuffer]: (res) => res.arrayBuffer(),
  [RESPONSE_TYPE.blob]: (res) => res.blob(),
  [RESPONSE_TYPE.json]: (res) => res.json(),
  [RESPONSE_TYPE.text]: (res) => res.text(),
};

/**
 * Wrapper for the native fetch
 * @param {*} path path to the endpoint
 * @param {*} init the fetch init object
 */
export const fetch = (path, { method, body, headers }) =>
  // eslint-disable-next-line
  window.fetch(path, {
    method,
    body,
    headers,
    credentials: 'same-origin',
  });