import { PaymentProviderChargeForm } from '@sb-billing/payment-provider-charge-form';
import { SavedCardChargeForm } from '@sb-billing/saved-card-charge-form';
import { CurrencyInput2, DropDownList, forms2PropTypes, SlidingToggle } from '@sb-itops/react';
import PropTypes from 'prop-types';
import React from 'react';
import { PAYMENT_SOURCE } from 'web/redux/selectors/payment-source';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import classnames from 'classnames';
import Styles from './PaymentDetailsForm.module.scss';

export const PaymentDetailsForm = ({
  contactPaymentMethods,
  contactPaymentMethodOptions,
  onFieldValueUpdated,
  paymentFormConfiguration,
  providerType,
  clientIsCoveringFee,
  feeDetails,
  isCardSavingEnabled,
  // form fields
  amount,
  paymentMethod,
  saveCardDetails,
  source,
  // form
  formDisabled,
  formInitialised,
  isSubmitting,
  // Underlying charge forms props
  onChargeFormDataChange,
  onChargeFormSubmit,
  onChargeFormReadyForSubmit,
  triggerChargeFormSubmit,
  onPreCharge,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <div className={Styles.paymentDetailsForm}>
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label>Amount</label>
            <CurrencyInput2 disabled name="amount" value={amount?.value || 0} />
          </div>

          {isCardSavingEnabled && source?.paymentSource === PAYMENT_SOURCE.creditCard && (
            <div className="col-sm-6 form-group">
              <label>Credit Card</label>
              <DropDownList
                options={contactPaymentMethodOptions}
                selectedOption={paymentMethod?.value}
                placeholder="Select or Add New..."
                onValueChange={(newPaymentMethod) => {
                  onFieldValueUpdated({ paymentMethod: newPaymentMethod?.value });
                }}
                menuPlacement="bottom"
                stateless
              />
            </div>
          )}
        </div>

        {clientIsCoveringFee && (
          <div className={classnames('row', providerType === providers.EZY_COLLECT && Styles.thinRow)}>
            <div className="col-sm-6 form-group">
              <label>Total amount of charge</label>
              <CurrencyInput2 disabled name="effectiveAmountInCents" value={feeDetails.effectiveAmountInCents || 0} />
            </div>
            <div className="col-sm-6 form-group">
              {providerType !== providers.EZY_COLLECT && <label>Includes {providerNames[providerType]} Fee</label>}
              {providerType === providers.EZY_COLLECT && <label>Includes payment processing fee</label>}
              <CurrencyInput2 disabled name="effectiveAmountInCents" value={feeDetails.effectiveFeeInCents || 0} />
            </div>
          </div>
        )}
        {clientIsCoveringFee && providerType === providers.EZY_COLLECT && (
          <div className="row">
            <div className={classnames('col-lg-12', Styles.feeInfo)}>
              <span>
                <i className={classnames('fa', 'fa-info-circle', Styles.icon)} />
                The fee will be calculated and the total charge updated after the card number is entered
              </span>
            </div>
          </div>
        )}

        {(source?.paymentSource === PAYMENT_SOURCE.eCheck ||
          (source?.paymentSource === PAYMENT_SOURCE.creditCard && paymentMethod?.value === 'None') ||
          (!isCardSavingEnabled &&
            source?.paymentSource === PAYMENT_SOURCE.creditCard &&
            paymentMethod?.value === undefined)) && (
          <>
            {isCardSavingEnabled && (
              <div className={Styles.saveCardDetails}>
                <label htmlFor="saveCardDetails">
                  <SlidingToggle
                    id="saveCardDetails"
                    name="saveCardDetails"
                    onChange={(name, val) => onFieldValueUpdated({ [name]: val })}
                    scope="save-card-details"
                    selected={saveCardDetails?.value}
                  />
                  <span>Save credit card details</span>
                </label>
              </div>
            )}
            <hr />
            <PaymentProviderChargeForm
              providerType={providerType}
              triggerSubmit={triggerChargeFormSubmit}
              isSubmitting={isSubmitting}
              paymentFormConfiguration={paymentFormConfiguration}
              onSubmit={onChargeFormSubmit}
              onReadyForSubmit={onChargeFormReadyForSubmit}
              onPreSubmitChange={onChargeFormDataChange}
              onPreCharge={onPreCharge}
            />
          </>
        )}

        {source?.paymentSource === PAYMENT_SOURCE.creditCard &&
          paymentMethod?.value !== undefined &&
          paymentMethod?.value !== 'None' && (
            <SavedCardChargeForm
              providerType={providerType}
              isSubmitting={isSubmitting}
              onReadyForSubmit={onChargeFormReadyForSubmit}
              onSubmit={onChargeFormSubmit}
              savedCard={contactPaymentMethods.find((pm) => pm.token === paymentMethod?.value)}
              triggerSubmit={triggerChargeFormSubmit}
            />
          )}
      </fieldset>
    </div>
  );
};
PaymentDetailsForm.displayName = 'PaymentDetailsForm';
const { Forms2Field } = forms2PropTypes;
PaymentDetailsForm.propTypes = {
  onFieldValueUpdated: PropTypes.func,
  paymentFormConfiguration: PropTypes.object,
  contactPaymentMethods: PropTypes.array.isRequired,
  contactPaymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  providerType: PropTypes.string,
  clientIsCoveringFee: PropTypes.bool,
  feeDetails: PropTypes.object,
  isCardSavingEnabled: PropTypes.bool.isRequired,
  // form fields
  amount: Forms2Field,
  paymentMethod: Forms2Field,
  saveCardDetails: Forms2Field,
  source: PropTypes.object,
  // form
  formDisabled: PropTypes.bool,
  formInitialised: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  onChargeFormDataChange: PropTypes.func,
  onChargeFormSubmit: PropTypes.func,
  onChargeFormReadyForSubmit: PropTypes.func,
  triggerChargeFormSubmit: PropTypes.bool,
  onPreCharge: PropTypes.func.isRequired,
};

PaymentDetailsForm.defaultProps = {
  onFieldValueUpdated: () => {},
  paymentFormConfiguration: {},
  contactPaymentMethodOptions: [],
  providerType: undefined,
  clientIsCoveringFee: false,
  feeDetails: {},
  // form fields
  amount: undefined,
  paymentMethod: undefined,
  saveCardDetails: undefined,
  source: undefined,
  // form
  formDisabled: undefined,
  formInitialised: undefined,
  isSubmitting: false,
  submitFailed: undefined,
  onChargeFormDataChange: () => {},
  onChargeFormSubmit: () => {},
  onChargeFormReadyForSubmit: () => {},
  triggerChargeFormSubmit: false,
};
