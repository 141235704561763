const { getRegion, regionType } = require('@sb-itops/region');
// const { schemeMappings } = require('@sb-itops/region-schemes');

const ensureAddressGeneric = (addr) => {
  if (!addr) {
    return false;
  }

  // TODO: check if we really need to check for addressLine2 for AU, if not this funciton can be simplified
  return addr.addressLine1 && addr.addressLine2 ? addr : false;
};

const ensureAddressUS = (addr) => {
  if (!addr) {
    return false;
  }

  // address line 2 apparently isnt needed for the address to be valid
  // city isnt needed for the address to be valid - work around for https://smokeball.atlassian.net/browse/BB-4314
  return addr.addressLine1 /* && addr.addressLine2 && addr.city */ ? addr : false;
};

const ensureAddressStrategy = {
  [regionType.AU]: ensureAddressGeneric,
  [regionType.GB]: ensureAddressGeneric,
  [regionType.US]: ensureAddressUS,
};

function getContactMailingAddress(contact) {
  if (!ensureAddressStrategy[getRegion()]) {
    throw new Error('Unsupported region');
  }
  const ensureAddress = ensureAddressStrategy[getRegion()];

  if (contact.company) {
    return ensureAddress(contact.company.mailingAddress) || ensureAddress(contact.company.businessAddress);
  }
  if (contact.organisation) {
    return contact.organisation.businessAddress;
  }
  if (contact.partnership) {
    return ensureAddress(contact.partnership.mailingAddress) || ensureAddress(contact.partnership.businessAddress);
  }
  if (contact.soleProprietor) {
    return (
      ensureAddress(contact.soleProprietor.mailingAddress) || ensureAddress(contact.soleProprietor.businessAddress)
    );
  }
  if (contact.organisation) {
    return ensureAddress(contact.organisation.forwardingAddress) || ensureAddress(contact.organisation.businessAddress);
  }
  if (contact.groupOfPeople) {
    return (
      ensureAddress(contact.groupOfPeople.mailingAddress) || ensureAddress(contact.groupOfPeople.residentialAddress)
    );
  }
  if (contact.person) {
    return (
      ensureAddress(contact.person.mailingAddress) ||
      ensureAddress(contact.person.residentialAddress) ||
      ensureAddress(contact.person.businessAddress)
    );
  }
  if (contact.trust) {
    return ensureAddress(contact.trust.address);
  }

  return undefined;
}

module.exports = {
  getContactMailingAddress,
};
