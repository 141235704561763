import React from 'react';
import PropTypes from 'prop-types';
import { Modal, forms2PropTypes } from '@sb-itops/react';
import { CreditNoteDetails } from './CreditNoteDetails';
import Styles from './CreditNoteDetailsModal.module.scss';

export const CreditNoteDetailsModal = React.memo(
  ({
    isVisible,
    formInitialised,
    formValid,
    formSubmitting,
    amount,
    effectiveDate,
    contact,
    processedBy,
    internalNote,
    reason,
    description,
    transactionId,
    isDeleted,
    showDebug,
    showDelete,
    showReverse,
    onClose,
    onFieldValueUpdated,
    onProcess,
    onCancel,
    onClickLink,
  }) => {
    if (!isVisible || !formInitialised) {
      return null;
    }

    return (
      <Modal
        className={Styles.modal}
        isVisible={isVisible}
        title="Credit Details"
        body={
          <CreditNoteDetails
            formValid={formValid}
            formSubmitting={formSubmitting}
            amount={amount}
            effectiveDate={effectiveDate}
            contact={contact}
            processedBy={processedBy}
            internalNote={internalNote}
            description={description}
            reason={reason}
            isDeleted={isDeleted}
            showDebug={showDebug}
            showReverse={showReverse}
            showDelete={showDelete}
            transactionId={transactionId}
            onProcess={onProcess}
            onCancel={onCancel}
            onFieldValueUpdated={onFieldValueUpdated}
            onClickLink={onClickLink}
          />
        }
        onModalClose={onClose}
        showFooter={false}
      />
    );
  },
);

CreditNoteDetailsModal.displayName = 'CreditNoteDetailsModal';

const { Forms2Field } = forms2PropTypes;

CreditNoteDetailsModal.propTypes = {
  isVisible: PropTypes.bool,
  formInitialised: PropTypes.bool,
  formValid: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  amount: PropTypes.number.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  contact: PropTypes.string,
  processedBy: PropTypes.string.isRequired,
  internalNote: PropTypes.string,
  description: PropTypes.string,
  reason: Forms2Field,
  transactionId: PropTypes.string,
  isDeleted: PropTypes.bool,
  showDebug: PropTypes.bool,
  showReverse: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

CreditNoteDetailsModal.defaultProps = {
  isVisible: false,
  contact: undefined,
  internalNote: '',
  description: '',
  reason: undefined,
  transactionId: '',
  isDeleted: false,
  showDebug: false,
  formInitialised: false,
  formValid: true,
  formSubmitting: false,
};
