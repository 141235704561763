import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { RESPONSE_TYPE } from '@sb-itops/fetch-wrapper-browser';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { invoiceEmailTemplateDefaults } from '@sb-billing/business-logic/invoice-emailing/entities';
import { sendMetric } from 'web/services/metrics';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'invoice-email-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbInvoiceEmailSettingsService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const getSettings = () => {
  const dodFeatureEnabled = featureActive('BB-5725') && featureActive('BB-6865');
  const settings =
    getList()[0] ||
    invoiceEmailTemplateDefaults({
      showSummaryBox: dodFeatureEnabled,
      allowEmailAttachment: hasFacet(facets.allowEmailAttachment),
    });
  return settings;
};

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoiceEmailSettingsService',
  keyPath: 'accountId',
});

export const saveSettings = (invoiceEmailSettings) => {
  const currentSettings = getSettings();
  const newSettings = { ...currentSettings, ...invoiceEmailSettings };

  return async () => {
    opdateCache({ optimisticEntities: [newSettings] });

    sendMetric('SaveEmailSettings');

    try {
      const path = `/billing/invoice-email-settings/save/:accountId/`;
      const fetchOptions = { body: JSON.stringify(newSettings) };
      await fetchPostP({ path, fetchOptions, responseType: RESPONSE_TYPE.text });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [currentSettings] }); // This should really be the optimistic entity, but the code for opdating is truly baked.
      throw err;
    }
  };
};
