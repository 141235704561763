/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { sort as sortItems } from '@sb-itops/sort';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import uuid from '@sb-itops/uuid';
import { EXPENSE_MODAL_ID, OPERATING_CHEQUE_DETAILS_MODAL_ID } from 'web/components';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import {
  filterUtbmsTaskCodesExpenses,
  mapActivitiesByCategory,
  mapTasksByCategory,
} from '@sb-billing/business-logic/activities/services';
import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import {
  expenseCanBeMarkedAsBillable,
  expenseCanBeMarkedAsNonBillable,
} from '@sb-billing/business-logic/expense/services';
import { entryType as entryTypesEnum, entryTypeLabels } from '@sb-billing/business-logic/shared/entities';
import * as services from '@sb-matter-management/business-logic/matters/services';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

import {
  BillingBulkActions,
  ExpenseTableData,
  InitActivityCodes,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitStaffSettings,
  MatterDetails,
} from 'web/graphql/queries';
import { useCacheQuery, useSubscribedQuery, usePagination } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { hasBillingAccess } from 'web/services/user-session-management';

import { MatterExpenseEntries } from './MatterExpenseEntries';

const REGION = getRegion();

const log = getLogger('MatterExpenseEntries.container');

const FETCH_LIMIT = 50;

const SCOPE = 'MatterExpenseEntriesRoute';
const SCOPE_QUICK_ADD = `${SCOPE}/quick-expense-add`;
const expenseTableScope = `${SCOPE}/expense-table`;

const hooks = ({ matterId }) => ({
  useScope: () => ({
    scope: SCOPE,
    scopeQuickAdd: SCOPE_QUICK_ADD,
    region: REGION,
  }),
  usePermissions: () => ({
    hasBillingAccess: hasBillingAccess(),
  }),
  useStaffMemberData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    const { staffMemberOptions } = useMemo(() => {
      const allStaffMembers = staffData?.staffMembers || [];
      const allStaffMembersSorted = sortItems(allStaffMembers, ['initials', 'name'], ['ASC', 'ASC']);

      const sortedOptions = allStaffMembersSorted.map((staffMemberEntity) => ({
        value: staffMemberEntity.id,
        label: `${staffMemberEntity.initials} (${staffMemberEntity.name})`,
        entity: {
          ...staffMemberEntity,
          rate: staffMemberEntity.rate || 0,
        },
      }));

      return {
        staffMemberOptions: sortedOptions,
      };
    }, [staffData?.staffMembers]);

    return { loggedInStaff: staffData?.loggedInStaff, staffMemberOptions };
  },
  useSort: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: expenseTableScope,
      initialSortBy: 'expenseDate',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
  useBulkActions: () => {
    const scopeTable = `${SCOPE}/matter-expense-table/${matterId}`;
    const scopeTableBillable = `${scopeTable}/billable`;
    const scopeTableNonBillable = `${scopeTable}/non-billable`;

    // We need to use 3 multipleItemSelection because with Load on Demand we
    // can't refer to the Redux Entities. The only moment we can test if an
    // Expense can be modified and marked as billable or not is during the
    // onToggleExpenses event

    // Holds all the checked rows and controls the header checkbox events
    const { selectedItems: selectedExpenseIds, toggleItems } = useMultipleItemSelection({
      scope: scopeTable,
    });

    // Holds only billable checked expenses. (Expenses that can be modified
    // and marked as non-billable)
    const { selectedItems: selectedBillableIds, toggleItems: toggleBillableItems } = useMultipleItemSelection({
      scope: scopeTableBillable,
    });

    // Holds only non-billable checked expenses. (Expenses that can be modified
    // and marked as billable)
    const { selectedItems: selectedNonBillableExpenseIds, toggleItems: toggleNonBillableItems } =
      useMultipleItemSelection({
        scope: scopeTableNonBillable,
      });

    // When the user is selecting the expenses we have access to all the
    // relevant properties on the expenses to check if they can be modified
    const onToggleExpenses = (expenses) => {
      const [selected, billable, nonBillable] = expenses.reduce(
        ([accSelected, accBillable, accNonBillable], expense) => {
          if (expenseCanBeMarkedAsNonBillable(expense)) {
            accBillable.push(expense.id);
          }

          if (expenseCanBeMarkedAsBillable(expense)) {
            accNonBillable.push(expense.id);
          }

          accSelected.push(expense.id);

          return [accSelected, accBillable, accNonBillable];
        },
        [[], [], []],
      );

      toggleItems(selected);
      toggleBillableItems(billable);
      toggleNonBillableItems(nonBillable);
    };

    const billableIds = Object.keys(selectedBillableIds);
    const billableIdsCount = billableIds.length;
    const nonBillableIds = Object.keys(selectedNonBillableExpenseIds);
    const nonBillableIdsCount = nonBillableIds.length;

    const markExpenseAs = async (expenseIds, isBillable) => {
      await dispatchCommand({ type: 'Integration.ExpensesBulkUpdateBillable', message: { expenseIds, isBillable } });
      toggleBillableItems(expenseIds);
      toggleNonBillableItems(expenseIds);
    };

    const bulkActions = {
      'mark-as-billable': {
        label: `Mark as Billable (${nonBillableIdsCount})`,
        confirmMessage: `This will mark ${nonBillableIdsCount} ${
          nonBillableIdsCount === 1 ? 'item' : 'items'
        } as billable.`,
        onConfirm: () => markExpenseAs(nonBillableIds, true),
        disabled: !nonBillableIdsCount,
      },
      'mark-as-non-billable': {
        label: `Mark as Non-Billable (${billableIdsCount})`,
        confirmMessage: `This will mark ${billableIdsCount} ${
          billableIdsCount === 1 ? 'item' : 'items'
        } as non-billable.`,
        onConfirm: () => markExpenseAs(billableIds, false),
        disabled: !billableIdsCount,
      },
    };

    const bulkActionsDisabled = !nonBillableIdsCount && !billableIdsCount;

    return { bulkActions, bulkActionsDisabled, selectedExpenseIds, onToggleExpenses };
  },
  useTaxSettings: () => {
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);

    const { taxRate: taxRateBasisPoints, registeredForGst } = firmTaxSettingsData?.firmTaxSettings || {};

    return {
      taxRateBasisPoints,
      registeredForGst,
    };
  },
  useUtbmsSettings: () => {
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    const utbmsEnabledForFirm =
      (hasFacet(facets.utbms) && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled) || false;
    const utbmsCodesRequiredByFirm =
      utbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;

    const utbmsCodeSetsUsedByFirm = firmUtbmsSettingsData?.firmUtbmsSettings?.selectedCodeSets;

    return {
      utbmsEnabledForFirm,
      utbmsCodeSetsUsedByFirm,
      utbmsCodesRequiredByFirm,
      showTaskColumn: utbmsEnabledForFirm,
    };
  },
  useActivityCodes: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    const activities = useMemo(
      () =>
        mapActivitiesByCategory({
          activityCodes: activityCodesResult?.data?.activityCodes,
          utbmsActivityCodes: activityCodesResult?.data?.utbmsActivityCodes,
          filterCustomCodeByTypes: [entryTypesEnum.EXPENSE],
        }),
      [activityCodesResult?.data?.activityCodes, activityCodesResult?.data?.utbmsActivityCodes],
    );

    const tasks = useMemo(
      () =>
        mapTasksByCategory({
          utbmsTaskCodes: activityCodesResult?.data?.utbmsTaskCodes,
          utbmsCustomTaskCodes: activityCodesResult?.data?.utbmsCustomTaskCodes,
          filterCustomCodeByTypes: [entryTypeLabels.EXPENSE],
        }),
      [activityCodesResult?.data?.utbmsTaskCodes, activityCodesResult?.data?.utbmsCustomTaskCodes],
    );

    return {
      activities,
      tasks,
    };
  },
  useModal: ({ onClickLink, sbAsyncOperationsService }) => {
    const onOpenExpenseModal = (expense) => {
      setModalDialogVisible({
        modalId: EXPENSE_MODAL_ID,
        props: {
          scope: `${SCOPE}/expense-modal`,
          expenseId: expense?.id,
          matterId,
          sbAsyncOperationsService,
          onClickLink,
        },
      });
    };

    const onOpenChequeModal = (chequeId) => {
      setModalDialogVisible({
        modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID,
        props: {
          chequeId,
        },
      });
    };

    return {
      onOpenExpenseModal,
      onOpenChequeModal,
    };
  },
  useColumnDisplay: () => ({
    showAnticipatedDisbursementColumn: featureActive('BB-9573'),
    showBulkUpdateSelectColumn: true, // Matter level only
    showMatterColumn: false,
    showChequeCreateSelectColumn: false, // Firm-level only
    showTaxColumns: hasFacet(facets.tax),
  }),
});

// These hooks will be called once the hooks above have completed, and will
// receive their props. This is required as we cannot retrieve values from
// adjacent hooks in composeHooks
const queryHooks = ({
  matterId,
  sortBy,
  sortDirection,
  staffIds,
  tasks,
  utbmsEnabledForFirm,
  utbmsCodeSetsUsedByFirm,
  onExportExpenseCsv,
}) => ({
  useBillingBulkActionsData: () => {
    const { data } = useSubscribedQuery(BillingBulkActions, {
      variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES, bulkEntityIds: [matterId] },
    });

    const isBulkCreateInvoicesInProgress = data?.billingBulkActionList?.totalCount > 0;
    return {
      isBulkCreateInvoicesInProgress,
    };
  },
  useExport: () => {
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [exportFileUrl, setExportFileUrl] = useState('');

    const filters = {
      selectedFromDate: undefined,
      selectedToDate: undefined,
      selectedStaffIds: staffIds,
      hideIfOnFinalisedInvoice: false,
      hideIfOnACheck: false,
      showDisbursements: undefined,
      showAnticipatedDisbursements: undefined,
      showAnticipatedDisbursementsUnpaid: undefined,
      showAnticipatedDisbursementsOverdue: undefined,
      showAnticipatedDisbursementsPaid: undefined,
    };

    const sort = {
      sortBy,
      sortDirection,
    };

    /**
     * Export Expenses
     *
     * @param {object} data
     * @param {string} [data.exportId]
     * @param {PDF|CSV} data.type
     * @param {object} data.filters
     * @param {object} data.sort
     * @param {string} data.matterId 'all' for all matters, otherwise the matterId
     * @param {string} [data.matterName] Required if for a single matter
     * @param {boolean} [data.isUtbmsEnabled]
     * @returns {Promise<object>} fetch response object
     */
    const printExportExpenses = async (data) => {
      const exportId = data.exportId || uuid();
      const path = `/billing/expenses/export/:accountId/${exportId}`;
      const fetchOptions = { body: JSON.stringify({ ...data, exportId }) };
      return fetchPostP({ path, fetchOptions });
    };

    const onGeneratePDFReport = async () => {
      const type = 'PDF';
      const exportId = uuid();
      setGeneratingPdf(true);

      try {
        const res = await printExportExpenses({
          type,
          exportId,
          filters,
          sort,
          matterId,
          isUtbmsEnabled: utbmsEnabledForFirm,
          includeLeads: featureActive('BB-6595'),
        });

        setExportFileUrl(res.body.pdfLocation);
      } catch (err) {
        log.error(`Failed to export '${type}', exportId: ${exportId}`, err);
        displayErrorToUser(`Export failed to generate ${type} export - please try again later.`);
      } finally {
        setGeneratingPdf(false);
      }
    };

    const onGenerateCSVReport = async () => {
      const type = 'CSV';
      const exportId = uuid();

      try {
        // Currently using sbAsyncOperationsService from the controller
        onExportExpenseCsv({
          type,
          exportId,
          filters,
          sort,
          matterId,
          isUtbmsEnabled: utbmsEnabledForFirm,
        });
      } catch (err) {
        log.error(`Failed to export '${type}', exportId: ${exportId}`, err);
        displayErrorToUser(`Export failed to generate ${type} export - please try again later.`);
      }
    };

    return {
      exportFileUrl,
      isGeneratingPDFReport: generatingPdf,
      onGeneratePDFReport,
      onGenerateCSVReport,
      onCloseExportModal: () => setExportFileUrl(''),
    };
  },
  useFirmUtbmsTasks: () => {
    // If we haven't yet received the task codes used by the firm, we aren't
    // able to filter from the full list.
    // In this case we won't show any utbms tasks.
    if (!utbmsEnabledForFirm || !utbmsCodeSetsUsedByFirm) {
      return {
        tasks: [],
      };
    }

    const filteredTasks = filterUtbmsTaskCodesExpenses({
      utbmsTaskCodes: tasks, // both standard and custom task codes
      utbmsCodeSetsUsedByFirm,
    });

    return {
      tasks: filteredTasks,
    };
  },
  useMatterData: () => {
    const includeBillingConfiguration = true;
    const includeMatterHourlyRate = false;

    const matterQueryResult = useSubscribedQuery(
      MatterDetails,
      {
        variables: {
          matterId,
          includeBillingConfiguration,
          includeMatterHourlyRate,
        },
      },
      {
        notificationIds: [
          ...MatterDetails.notificationIds,
          includeBillingConfiguration && 'BillingMattersNotifications.BillingConfigurationUpdated',
        ].filter(Boolean),
      },
    );

    if (matterQueryResult.error) {
      throw new Error(matterQueryResult.error);
    }

    const { data: matterData } = matterQueryResult;
    const isEditableMatter = services.isEditableMatter(matterData?.matter);
    const matter = matterData?.matter;

    return { isEditableMatter, matter };
  },
  useExpenseListData: () => {
    const {
      currentPage: currentExpensePage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${SCOPE}-pagination`,
      fetchLimit: FETCH_LIMIT,
    });

    const onExpenseListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const expensesQueryResult = useSubscribedQuery(ExpenseTableData, {
      context: { skipRequestBatching: true },
      skip: !matterId,
      variables: {
        expenseFilter: {
          includeStatus: { current: true },
          matterIds: [matterId],
        },
        offset: currentExpensePage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (expensesQueryResult.error) {
      throw new Error(expensesQueryResult.error);
    }

    const { data: expenseData } = expensesQueryResult;
    const expenseList = expenseData?.expenseList?.results || [];

    const {
      totalCount: expenseCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfExpensePages,
    } = getPagination({ totalCount: expenseData?.expenseList?.totalCount, loading: expensesQueryResult.loading });

    return {
      currentExpensePage,
      expenses: expenseList,
      expenseCount,
      expenseDataLoading: expenseList.length === 0 && expensesQueryResult.loading,
      totalNumberOfExpensePages,
      hidePagination,
      onExpenseListPageChange,
    };
  },
});

export const MatterExpenseEntriesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(MatterExpenseEntries))),
);

MatterExpenseEntriesContainer.displayName = 'MatterExpenseEntriesContainer';

MatterExpenseEntriesContainer.propTypes = {
  sbAsyncOperationsService: PropTypes.object.isRequired, // LOD Expense modal -> Operating Cheque Print now modal
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  onExportExpenseCsv: PropTypes.func.isRequired,
  onPrintCheque: PropTypes.func.isRequired,
};

MatterExpenseEntriesContainer.defaultProps = {};
