'use strict';

const Yup = require('yup');

const StaffHourlyRateSchema = Yup.object().shape({
  rate: Yup.number().required(),
  staffId: Yup.string(),
  userId: Yup.string(),
});

module.exports = {
  StaffHourlyRateSchema,
};
