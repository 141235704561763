import { getByIds } from '@sb-customer-management/redux/contacts-summary';

angular.module('sb.billing.webapp').component('sbDataMatterById', {
  'require':  { compose: '^sbCompose' },
  'bindings' : { matterId: '<', composeDataKey: '=?' },
  'controller': function ($scope, sbMattersMbService, sbLoggerService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataMatterById');
    let matter, initialized;

    ctrl.$onInit = () => {
      log.info(`init matterId`, ctrl.matterId);
      update();
      initialized = true;
    };

    ctrl.$onChanges = () => {
      if (initialized) {
        update();
      }
    };

    async function update() {
      log.info(`update matterId: ${ctrl.matterId}`);
      const newMatter = ctrl.matterId && sbMattersMbService.getById(ctrl.matterId);

      if (newMatter && newMatter !== matter) {
        matter = newMatter;
        ctrl.compose.setComposeData({dataType: 'matter', data: matter}, ctrl.composeDataKey || 'matter');

        const clientContacts = await getByIds(matter.clientCustomerIds);
        // clone the data or we get an immutability error when angular tries to mutate the objects
        ctrl.compose.setComposeData({ dataType: 'clientContacts', data: JSON.parse(JSON.stringify(clientContacts)) }, 'clientContacts')
      }
    }

    $scope.$on('smokeball-data-update-sbMattersMbService', update);
  }
});

