import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { staffTargetAssignmentType } from '@sb-billing/business-logic/staff-targets';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { sortByProperty } from '@sb-itops/nodash';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as forms from '@sb-itops/redux/forms2';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useExportAndPrintPdf } from 'web/hooks';

import { StaffTargetAssignmentSchema } from './StaffTargetAssignment.schema';
import StaffTargetAssignmentTable from './StaffTargetAssignmentTable';

const hooks = ({
  staffFilter,
  setStaffFilter,
  staffMembers,
  isStaffTargetAssignmentsLoading,
  activeTargetGroupLookup,
}) => ({
  useForm: () => {
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, 'staff-target-assignment-form');

    const { fields: formFields, formSubmitting, formDirty, formValidation } = useSelector(formSelectors.getFormState);

    const dispatch = useDispatch();

    const { staffTargetAssignmentMapping, staffTargetAssignmentList } = useMemo(
      () =>
        staffMembers.reduce(
          (acc, staffTargetAssignment) => {
            // reflect the current value saved in db
            acc.staffTargetAssignmentMapping[staffTargetAssignment.id] =
              staffTargetAssignment.feeConfiguration?.staffTargetGroupId;

            // reflect the current value selected in form
            acc.staffTargetAssignmentList.push({
              id: staffTargetAssignment.id,
              name: staffTargetAssignment.name,
              staffTargetGroupId: formFields[staffTargetAssignment.id]?.value,
            });
            return acc;
          },
          { staffTargetAssignmentMapping: {}, staffTargetAssignmentList: [] },
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formFields, staffMembers],
    );

    useEffect(() => {
      if (staffMembers.length > 0) {
        // initialise the form when staff members from database are updated
        dispatch(
          formActions.initialiseForm({
            fieldValues: staffTargetAssignmentMapping,
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffMembers]);

    useEffect(() => {
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredAndSortedStaffTargetAssignmentList = useMemo(() => {
      const filteredList = staffTargetAssignmentList.filter(
        (staffTargetAssignment) =>
          staffFilter === staffTargetAssignmentType.ALL || // show all staff
          (staffFilter === staffTargetAssignmentType.UNASSIGNED && !staffTargetAssignment.staffTargetGroupId) || // show unassigned staff
          (staffFilter === staffTargetAssignmentType.ASSIGNED && staffTargetAssignment.staffTargetGroupId) || // show assigned staff
          staffFilter === staffTargetAssignment.staffTargetGroupId, // show staff assigned to a specific group
      );
      return sortByProperty(filteredList, 'name', 'asc', false);
    }, [staffTargetAssignmentList]);

    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateForm({ schema: StaffTargetAssignmentSchema }));
    };

    const isActiveTargetGroup = (staffTargetGroupId) =>
      staffTargetGroupId && activeTargetGroupLookup[staffTargetGroupId];

    const onSave = async () => {
      try {
        dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formData) => {
              const assignments = [];
              // Commands.AssignStaffTargets take delta values from the assignment table
              Object.keys(formData).forEach((staffId) => {
                // only include the field if it is dirty.
                // Also check if the target group is active to avoid user assigning inactive target group, it could happen during a small window of time between the target group is deactivated and staff assignment table reflects the update.
                // when unassign a staff, the value is null, so we need to check if it is null as well
                if (
                  formFields[staffId].isDirty &&
                  (isActiveTargetGroup(formData[staffId]) || formData[staffId] === null)
                ) {
                  assignments.push({ staffId, staffTargetGroupId: formData[staffId] });
                }
              });

              const targetAssignmentsToSave = {
                assignments,
              };

              await dispatchCommand({
                type: 'Billing.Fees.Commands.AssignStaffTargets',
                message: targetAssignmentsToSave,
              });

              messageDisplay.success('Target group assignments have been saved');
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to save Target group assignments');
      }
    };

    return {
      staffFilter,
      setStaffFilter,
      staffTargetAssignmentList: filteredAndSortedStaffTargetAssignmentList,
      isStaffTargetAssignmentsLoading,
      // form
      onFieldValueUpdated,
      formSubmitting,
      formDirty,
      formValidation,
      onSave,
    };
  },
  useExportOperations: () => {
    const printDialogIframeId = 'staffTargetAssignmentExportPdf';
    const exportUrl = '/billing/staff-target-assignments/export/:accountId/';
    const { exportAndPrintPdf, isGeneratingPdf } = useExportAndPrintPdf({
      printDialogIframeId,
      exportTypeLabel: 'staffTargetAssignments',
      exportUrl,
    });
    const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

    const onExportAsPdf = async () => {
      await exportAndPrintPdf({
        filters: { staffFilter },
      });
    };

    const onExportAsCsv = async () => {
      try {
        setIsGeneratingCsv(true);
        const response = await fetchPostP({
          path: exportUrl,
          fetchOptions: { body: JSON.stringify({ fileFormat: 'csv', staffFilter }) },
        });
        const csvBlob = new Blob([response.body.csvString], { type: 'data:text/csv;charset=utf-8' });
        saveAs(csvBlob, 'staff-target-assignments.csv');
      } catch (error) {
        throw new Error('Failed to export staff target assignment. Please try again later.');
      } finally {
        setIsGeneratingCsv(false);
      }
    };

    return {
      onExportAsCsv,
      onExportAsPdf,
      printDialogIframeId,
      isGeneratingCsv,
      isGeneratingPdf,
    };
  },
});

export const StaffTargetAssignmentTableContainer = composeHooks(hooks)(StaffTargetAssignmentTable);

StaffTargetAssignmentTableContainer.displayName = 'StaffTargetAssignmentTableContainer';

StaffTargetAssignmentTableContainer.propTypes = {
  staffFilter: PropTypes.string,
  setStaffFilter: PropTypes.func.isRequired,
  staffFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      feeConfiguration: PropTypes.shape({
        id: PropTypes.string,
        staffTargetGroupId: PropTypes.string,
      }),
    }),
  ),
  isStaffTargetAssignmentsLoading: PropTypes.bool,
  activeTargetGroupLookup: PropTypes.object,
};

StaffTargetAssignmentTableContainer.defaultProps = {
  staffFilter: staffTargetAssignmentType.ALL,
  staffFilterOptions: [],
  staffMembers: [],
  isStaffTargetAssignmentsLoading: false,
  activeTargetGroupLookup: {},
};

export default withReduxProvider(StaffTargetAssignmentTableContainer);
