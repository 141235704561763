import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import { DatePicker, CurrencyInput2, CurrencyDisplay, forms2PropTypes } from '@sb-itops/react';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { ContactTypeahead } from '@sb-customer-management/react';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import { BankAccountSelect } from '@sb-billing/react';
import { MatterTypeahead, AddContactInlineForm } from 'web/react-redux';
import Styles from './CreditCardDepositForm.module.scss';

const { Forms2Field } = forms2PropTypes;

export const CreditCardDepositForm = ({
  providerType,
  bankAccountOptions,
  contactOptions,
  minAmountAllowed,
  // what user has selected
  bankAccountId,
  dateDeposited,
  payorId,
  matterId,
  depositAmount,
  clientIsCoveringFee,
  feeDetails,
  // form states & callbacks
  balanceAfterDeposit,
  reason,
  isSubmitting,
  formInitialised,
  onFormFieldChange,
  onMatterSelected,
  onOpenEditContactModal,
  // config
  showReason,
}) => {
  const [showAddContactForm, setShowAddContactForm] = useState(undefined);

  if (!formInitialised) {
    return null;
  }

  const onBankAccountSelected = (selectedBankAccountId) => {
    onFormFieldChange({ key: bankAccountId.key, value: selectedBankAccountId });
  };

  const onDateDepositedSelected = (newDate) => {
    onFormFieldChange({ key: dateDeposited.key, value: dateToInteger(newDate) });
  };

  const onContactSelected = (selectedContactOption) => {
    onFormFieldChange({ key: payorId.key, value: selectedContactOption && selectedContactOption.value });
  };

  const onContactCreated = (newContactId) => {
    onFormFieldChange({ key: payorId.key, value: newContactId });
  };

  const onAmountChanged = (event) => {
    onFormFieldChange({ key: depositAmount.key, value: event.target.value });
  };

  const onReasonChanged = (event) => {
    event.preventDefault();
    onFormFieldChange({ key: 'reason', value: event.target.value });
  };

  return (
    <div className={Styles.creditCardDepositForm}>
      <form name="creditCardDepositForm">
        <fieldset>
          <div className="row">
            <div className="col-lg-6 form-group">
              <label>Account</label>
              <div className={bankAccountId && bankAccountId.isInvalid ? Styles.hasError : undefined}>
                <BankAccountSelect
                  bankAccountOptions={bankAccountOptions}
                  bankAccountId={bankAccountId && bankAccountId.value}
                  onBankAccountSelect={onBankAccountSelected}
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className="col-lg-6 form-group">
              <label>Date Deposited</label>
              <div className={dateDeposited && dateDeposited.isInvalid ? Styles.hasError : undefined}>
                <DatePicker
                  onSelect={onDateDepositedSelected}
                  value={dateDeposited && dateDeposited.value && integerToDate(dateDeposited.value)}
                  hasError={dateDeposited && dateDeposited.isInvalid}
                  format="DD/MM/YYYY"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className={classnames(
                'form-group',
                'col-sm-6',
                matterId && matterId.isInvalid ? Styles.withInlineError : '',
              )}
            >
              <label>Received From</label>
              <div>
                <ContactTypeahead
                  className={Styles.contactSelect}
                  contacts={contactOptions}
                  onContactSelected={onContactSelected}
                  selectedContactId={payorId && payorId.value}
                  disabled={isSubmitting}
                  showAdd
                  onAdd={() => (!isSubmitting ? setShowAddContactForm(!showAddContactForm) : undefined)}
                  hasError={payorId && payorId.isInvalid}
                  isAddActive={showAddContactForm}
                />
              </div>
            </div>

            <div
              className={classnames(
                'form-group',
                'col-sm-6',
                matterId && matterId.isInvalid ? Styles.withInlineError : '',
              )}
            >
              <label>Matter</label>
              <MatterTypeahead
                onValueChange={onMatterSelected}
                className={matterId && matterId.isInvalid ? Styles.hasError : undefined}
                onClear={onMatterSelected}
                initialId={matterId && matterId.value}
                filter="all"
                disabled={isSubmitting}
              />
            </div>
            {matterId && matterId.invalidReason && (
              <>
                <div className="col-lg-6" />
                <div className={classnames('col-lg-6', Styles.errorMsg, Styles.errorMsgContainer)}>
                  {matterId.invalidReason &&
                    typeof matterId.invalidReason === 'string' &&
                    matterId.invalidReason
                      .split('\n')
                      .map((individualReason) => (
                        <InterpolatedDescription
                          key={individualReason}
                          description={individualReason}
                          deleted={false}
                          onClickLink={() => {}}
                          isError
                          onClickDisplay={onOpenEditContactModal}
                        />
                      ))}
                </div>
              </>
            )}
          </div>

          {showAddContactForm && (
            <div className="row">
              <AddContactInlineForm
                onClose={() => setShowAddContactForm(false)}
                onContactCreated={(newContactId) => {
                  onContactCreated(newContactId);
                  setShowAddContactForm(false);
                }}
              />
            </div>
          )}
          <div className="row">
            <div className="col-lg-6 form-group">
              <label> Amount </label>
              <CurrencyInput2
                hasError={depositAmount && depositAmount.isInvalid}
                name="amount"
                value={depositAmount && depositAmount.value}
                onChange={onAmountChanged}
                min={minAmountAllowed}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label>Available After Deposit </label>
              <CurrencyDisplay amount={balanceAfterDeposit} />
            </div>
          </div>
          {clientIsCoveringFee && (
            <div className={classnames('row', providerType === providers.EZY_COLLECT && Styles.thinRow)}>
              <div className="col-lg-6 form-group">
                <label>Total Amount of Charge</label>
                <CurrencyDisplay name="totalAmountOfCharge" amount={feeDetails.effectiveAmountInCents} />
              </div>
              <div className="col-lg-6 form-group">
                {providerType !== providers.EZY_COLLECT && <label>Includes {providerNames[providerType]} Fee</label>}
                {providerType === providers.EZY_COLLECT && <label>Includes payment processing fee</label>}
                <CurrencyDisplay name="feeComponent" amount={feeDetails.effectiveFeeInCents} />
              </div>
            </div>
          )}
          {clientIsCoveringFee && providerType === providers.EZY_COLLECT && (
            <div className="row">
              <div className={classnames('col-lg-12', Styles.feeInfo)}>
                <span>
                  <i className={classnames('fa', 'fa-info-circle', Styles.icon)} />
                  The fee will be calculated and the total charge updated after the card number is entered
                </span>
              </div>
            </div>
          )}
          {showReason && (
            <div className="row" ng-class="{'has-error': $ctrl.errors.reason}">
              <div className="col-lg-12 form-group">
                <label>Reason</label>
                <textarea
                  className={classnames('form-control', reason.isInvalid && Styles.hasError)}
                  value={reason.value}
                  maxLength={500}
                  onChange={onReasonChanged}
                  rows="5"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          )}
          <hr />
        </fieldset>
      </form>
    </div>
  );
};

CreditCardDepositForm.displayName = 'CreditCardDepositForm';

CreditCardDepositForm.propTypes = {
  providerType: PropTypes.string.isRequired,
  bankAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ),
  minAmountAllowed: PropTypes.number,
  // what user has selected
  bankAccountId: Forms2Field,
  dateDeposited: Forms2Field,
  payorId: Forms2Field,
  matterId: Forms2Field,
  depositAmount: Forms2Field,
  clientIsCoveringFee: PropTypes.bool,
  feeDetails: PropTypes.object,
  reason: PropTypes.object,
  // form states & callbacks
  balanceAfterDeposit: PropTypes.number,
  isSubmitting: PropTypes.bool,
  formInitialised: PropTypes.bool,
  onFormFieldChange: PropTypes.func.isRequired,
  onMatterSelected: PropTypes.func.isRequired,
  onOpenEditContactModal: PropTypes.func.isRequired,
  // config
  showReason: PropTypes.bool,
};

CreditCardDepositForm.defaultProps = {
  bankAccountOptions: [],
  contactOptions: [],
  minAmountAllowed: 0,
  // what user has selected
  bankAccountId: undefined,
  dateDeposited: undefined,
  payorId: undefined,
  matterId: undefined,
  depositAmount: undefined,
  clientIsCoveringFee: false,
  feeDetails: {},
  reason: undefined,
  showReason: false,
  // form states & callbacks
  isSubmitting: false,
  balanceAfterDeposit: 0,
  formInitialised: false,
};
