
import {
  builder,
  dismissGroup,
  success,
  info,
  warn,
  error,
} from '@sb-itops/message-display';

angular.module('@sb-itops/services').service('sbMessageDisplayService', function() {
  const svc = this;

  svc.dismissGroup = dismissGroup;
  svc.builder = builder;
  svc.success = success;
  svc.info = info;
  svc.warn = warn;
  svc.error = error;
});
