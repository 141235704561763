import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { sort as sortItems } from '@sb-itops/sort';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { getMatterStatusOptions } from '@sb-matter-management/business-logic/matters/services';
import { featureActive } from '@sb-itops/feature';

import { MatterTable as MatterTableComponent } from 'web/components';

const hooks = ({ scope, matters, initialSortBy, initialSortDirection }) => ({
  useMatterStatusOptions: () => {
    const matterStatusOptions = getMatterStatusOptions({ showDeleted: featureActive('BB-7911') });
    return { matterStatusOptions };
  },
  useSortedMatters: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope,
      initialSortBy,
      initialSortDirection,
    });

    const sort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    const sortValues = [];
    const sortDirections = [sortDirection];
    switch (sortBy) {
      case 'attorneyResponsible':
        sortValues.push((row) => row.attorneyResponsible?.initials);
        break;
      case 'matterType':
        sortValues.push((row) => row.matterType?.name);
        break;
      case 'unbilled':
      case 'unpaidExcInterest':
        sortValues.push(
          (row) => (row.matterTotals && row.matterTotals[sortBy]) || 0,
          'clientDisplayName',
          'matterNumber',
        );
        sortDirections.push('asc', 'asc');
        break;
      default:
        sortValues.push(sortBy);
        break;
    }

    return {
      sort,
      sortBy,
      sortDirection,
      matters: sortItems(matters, sortValues, sortDirections),
    };
  },
});

export const MatterTable = composeHooks(hooks)(MatterTableComponent);

MatterTable.displayName = 'MatterTable.container';

MatterTable.propTypes = {
  scope: PropTypes.string.isRequired,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialSortBy: PropTypes.string,
  initialSortDirection: PropTypes.oneOf(['asc', 'desc']),
  onClickLink: PropTypes.func.isRequired,
  showStatus: PropTypes.bool,
};

MatterTable.defaultProps = {
  initialSortBy: 'matterNumber',
  initialSortDirection: 'desc',
  showStatus: undefined,
};
