'use strict';

const { getTypeAsMaps, getTypeAsOptions } = require('@sb-itops/enum-helpers');

// don't copy this enum implementation, this is the only enum type defined using a
// different naming convention to all the other enums created with @sb-itops/enum-helpers

/**
 * @typedef {object} sentViaTypes
 * @property {number} email 0
 * @property {number} communicate 1
 */
const sentViaTypes = Object.freeze({
  EMAIL: 0,
  COMMUNICATE: 1,
  // externalEmail: 2,
  MAIL: 3,
  E_BILLING: 4,
  OTHER: 5,
});

const sentViaTypeLabels = Object.freeze({
  EMAIL: 'Email',
  COMMUNICATE: 'Client Portal',
  // externalEmail: 'External Email',
  MAIL: 'Mail',
  E_BILLING: 'eBilling',
  OTHER: 'Other',
});

const [sentViaMap, sentViaLabelToValueMap] = getTypeAsMaps({
  typeLabels: sentViaTypeLabels,
  typeValues: sentViaTypes,
});

const sentViaOptions = getTypeAsOptions({
  typeLabels: sentViaTypeLabels,
  typeValues: sentViaTypes,
});

const externalSentViaTypes = Object.freeze({
  MAIL: sentViaTypes.MAIL,
  E_BILLING: sentViaTypes.E_BILLING,
  OTHER: sentViaTypes.OTHER,
});

const externalSentViaTypeLabels = Object.freeze({
  MAIL: sentViaTypeLabels.MAIL,
  E_BILLING: sentViaTypeLabels.E_BILLING,
  OTHER: sentViaTypeLabels.OTHER,
});

const [externallySentViaMap, externallySentViaLabelToValueMap] = getTypeAsMaps({
  typeLabels: externalSentViaTypeLabels,
  typeValues: externalSentViaTypes,
});

const getExternalSentViaOptions = ({ isEBillingAvailable }) => {
  const externalSentViaOptions = getTypeAsOptions({
    typeLabels: externalSentViaTypeLabels,
    typeValues: externalSentViaTypes,
  });

  if (!isEBillingAvailable) {
    // Exclude 'eBilling' option if isEBillingAvailable is false
    return externalSentViaOptions.filter((option) => option.value !== externalSentViaTypes.E_BILLING);
  }

  return externalSentViaOptions;
};

const getSentViaOptions = ({ isEBillingAvailable }) => {
  const groupedOptionsMap = sentViaOptions.reduce(
    (acc, option) => {
      if ([sentViaTypes.EMAIL, sentViaTypes.COMMUNICATE].includes(option.value)) {
        acc.sendViaSmokeball.push(option);
      } else if (isEBillingAvailable && option.value === sentViaTypes.E_BILLING) {
        // eBilling is only available for US
        acc.sendExternal.push(option);
      } else if (option.value !== sentViaTypes.E_BILLING) {
        acc.sendExternal.push(option);
      }
      return acc;
    },
    { sendViaSmokeball: [], sendExternal: [] },
  );

  const groupedOptions = [
    { label: 'Send via Smokeball', options: groupedOptionsMap.sendViaSmokeball },
    { label: 'Send External to Smokeball', options: groupedOptionsMap.sendExternal },
  ];

  return groupedOptions;
};

module.exports = {
  sentViaTypes,
  sentViaTypeLabels,
  sentViaMap,
  sentViaLabelToValueMap,
  getSentViaOptions,
  externalSentViaTypes,
  externalSentViaTypeLabels,
  externallySentViaMap,
  externallySentViaLabelToValueMap,
  getExternalSentViaOptions,
};
