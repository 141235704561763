/**
 * Round a number to an integer using bankers' rounding.
 *
 * If decimal places are desired, multiply and divide the number around this
 * operation.  Keep in mind that when using IEEE 754 floating-point numbers,
 * some decimal representations are impossible and the result may appear
 * un-rounded.
 *
 * Bankers' rounding is also known as convergent rounding, statistician's
 * rounding, Dutch rounding, Gaussian rounding, and even rounding.
 *
 * @example 1.5 rounds to 2.
 * @example 2.5 rounds to 2.
 *
 * @param input A Number to round.
 *
 * @return A rounded integer.
 */
const bankersRound = (input) => {
  /* Math.trunc is like floor, but works for negative numbers. */
  const integer = Math.trunc(input);
  const decimal = input - integer;

  /* Check whether we are close enough to half (within a small range of just
   * under half and just over half), due to the loss of precision that IEEE 754
   * floating-point can suffer through multiple operations and with certain
   * decimal numbers. */
  if (Math.abs(decimal) - 0.5 < Number.EPSILON && Math.abs(decimal) - 0.5 > -Number.EPSILON) {
    /* If it is already even, (integer % 2) will equal zero; if it is odd, then
     * (integer % 2) will be either -1 or 1, correctly rounding for both
     * positive and negative numbers. */
    return integer + (integer % 2);
  }
  /* JavaScript's Math.round rounds to +∞ instead of away from zero, so round
   * everything as an absolute value and flip the sign back for negative
   * numbers to achieve round-away-from-zero behaviour. */
  return Math.round(Math.abs(input)) * Math.sign(input);
};

module.exports = {
  bankersRound,
  /**
   * Round a number using bankers' rounding to zero decimal places.
   * i.e. assuming that it is a cents amount.
   *
   * @example 1.5 rounds to 2.
   * @example 2.5 rounds to 2.
   *
   * @param amount The value in cents to round to the nearest cent.
   *
   * @return An integer of the rounded cents.
   */
  roundCents: (amount) => bankersRound(amount),
  /**
   * Round a number using bankers' rounding to two decimal places.
   * i.e. assuming that it is a dollar amount.
   *
   * @example 1.015 rounds to 1.02.
   * @example 1.025 rounds to 1.02.
   *
   * @param amount The value in dollars to round to the nearest cent.
   *
   * @return A floating-point number of the rounded dollars.
   */
  roundDollars: (amount) => bankersRound(amount * 100.0) / 100.0,
};
