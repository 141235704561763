import { useState, useMemo } from 'react';

import { findCurrentAndFutureVersions } from '@sb-billing/business-logic/rate-sets';
import { todayAsInteger } from '@sb-itops/date';
import composeHooks from '@sb-itops/react-hooks-compose';

import { InitStaffSettings, RateSetTableData, RateSetEntities } from 'web/graphql/queries';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { RateSetsSettings } from './RateSetsSettings';

const hooks = () => ({
  useHiddenFilter: () => {
    const [showHidden, setShowHidden] = useState(false);
    return {
      showHidden,
      setShowHidden,
    };
  },
  useEditRateSet: () => {
    const [currentRateSet, setCurrentRateSet] = useState();
    const [currentRateSetId, setCurrentRateSetId] = useState();

    useSubscribedQuery(RateSetEntities, {
      variables: {
        ids: [currentRateSetId],
      },
      onCompleted: (data) => {
        const rateSet = currentRateSetId ? data?.rateSets?.[0] : undefined;
        setCurrentRateSet(JSON.parse(JSON.stringify(rateSet))); // so this can be used when saving the form
      },
      skip: !currentRateSetId,
    });

    const clearCurrentRateSet = () => {
      setCurrentRateSetId(undefined);
      setCurrentRateSet(undefined);
    };

    const addNewRateSet = () => {
      setCurrentRateSetId(undefined);
      setCurrentRateSet({});
    };

    return {
      currentRateSet,
      currentRateSetId,
      // func & callbacks
      addNewRateSet,
      clearCurrentRateSet,
      setCurrentRateSetId,
    };
  },
});

const queryHooks = () => ({
  useStaffMembers: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    return {
      staffMembers: staffData?.staffMembers,
    };
  },
  useRateSetTableData: ({ showHidden }) => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const staffByIdMap = useMemo(() => {
      const staffs = staffData?.staffMembers || [];
      return staffs.reduce((acc, staffMemberEntity) => {
        acc[staffMemberEntity.id] = staffMemberEntity;
        return acc;
      }, {});
    }, [staffData?.staffMembers]);

    const {
      data,
      error,
      loading: rateSetsLoading,
    } = useSubscribedQuery(RateSetTableData, {
      variables: {
        rateSetListFilter: {
          includeHidden: showHidden,
          rateSetDate: todayAsInteger(),
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    // rateSets is the full set of rate sets for this firm, if in the future we want to paginate
    // this list, a separate fetch for all rate set names is required in order to help determine
    // uniqueness of rate set names
    const rateSets = (data?.rateSetList?.results || []).reduce((acc, rateSet) => {
      const { currentVersion, futureVersion } = findCurrentAndFutureVersions({ rateSet });
      const staffs = [];
      if (currentVersion && currentVersion.ratesPerStaff) {
        Object.values(currentVersion.ratesPerStaff).forEach((customStaffRate) => {
          const initials = staffByIdMap[customStaffRate.staffId]?.initials;
          staffs.push({ ...customStaffRate, initials });
        });
      }

      // append staffs with initial from current version of rate set
      acc.push({ ...rateSet, currentVersion, futureVersion, staffs });

      return acc;
    }, []);

    return { rateSets, rateSetsLoading };
  },
});

export const RateSetsSettingsContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(RateSetsSettings))),
);

RateSetsSettingsContainer.displayName = 'RateSetsSettingsContainer';
