'use strict';

/**
 * extractFeeSchedule
 *
 * Returns the stripe fee schedule to be applied for the passed bankAccountId + region combination.
 * EZ collect utilises a per bank account fee schedule.
 *
 * @param {Object} params
 * @param {Object} params.formattedProviderSpecificSettings The amount for the charge.
 * @param {string} params.bankAccountId
 * @param {string} params.region
 * @returns {Object} The resulting fee schedule.
 */
const extractFeeSchedule = ({ formattedProviderSpecificSettings, bankAccountId }) => {
  if (!formattedProviderSpecificSettings) {
    throw new Error(`'formattedProviderSpecificSettings' must be provided to extract fee schedule`);
  }

  if (!bankAccountId) {
    throw new Error(`'bankAccountId' must be provided to extract fee schedule`);
  }

  const feeSchedule = formattedProviderSpecificSettings.feeSchedule[bankAccountId];
  if (!feeSchedule) {
    throw new Error(`EzCollect fee schedule not found for bankAccountId '${bankAccountId}'`);
  }

  return feeSchedule;
};

module.exports = {
  extractFeeSchedule,
};
