import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/balance-protection';

angular
  .module('@sb-billing/services')
  .service('sbBalanceProtectionService', function(
    sbGenericCacheService,
    sbEndpointType
  ) {
    'use strict';

    sbGenericCacheService.setupCache({
      name: 'sbBalanceProtectionService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/balance-protection' },
        poll: 60,
        subscriptions: 'notifier.AccountsNotifications.ProtectedBalanceUpdated'
      },
      updateRedux,
      clearRedux,
    });
  });
