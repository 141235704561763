import { yupSchemaValidator } from '@sb-itops/business-logic/validation/services';
import { dot as nestedObjectToFlattened } from 'dot-object';
import * as actions from './actions';
import * as selectors from './selectors';

/**
 * @deprecated use validateForm instead, which has an api compatible with this method
 */
export const validateSchema =
  ({ schema } = {}) =>
  (dispatch) => {
    if (!schema || typeof schema.validateSync !== 'function') {
      throw new Error('validateSchema requires expects a yup schema object');
    }

    dispatch(validateForm({ validateFn: (objToValidate) => yupSchemaValidator(objToValidate, schema) }));
  };

/**
 * Validates form, accepting either a custom validation function and/or a yup schema as input
 * @param {Object} param
 * @param {function} [param.validateFn] a custom validation fuction returning an object of errors, each keyed by their field names
 * @param {function} [param.schema] yup schema used for validation
 * @param {function} [param.context] yup context (conditional params etc) used for validation
 */
export const validateForm =
  ({ validateFn, schema, context }) =>
  async (dispatch, getState) => {
    if ((!schema || typeof schema.validateSync !== 'function') && (!validateFn || typeof validateFn !== 'function')) {
      throw new Error('validateForm expects either a yup schema object and/or a custom validateFn function');
    }

    const currentFieldValues = selectors.getFieldValues(getState());

    let schemaErrors = {};
    if (schema) {
      schemaErrors = yupSchemaValidator(currentFieldValues, schema, context);
    }

    let customErrors = {};
    if (validateFn) {
      customErrors = await validateFn(currentFieldValues);
    }

    // Forms 2 expects errors to be reported as flattened dot object notation.
    const errors = {
      ...(schemaErrors && Object.keys(schemaErrors).length ? nestedObjectToFlattened(schemaErrors) : {}),
      ...(customErrors && Object.keys(customErrors).length ? nestedObjectToFlattened(customErrors) : {}),
    };
    dispatch(actions.endValidateForm({ errors }));
  };

export const submitFormP =
  ({ submitFnP }) =>
  async (dispatch, getState) => {
    if (!submitFnP) {
      throw new Error('submitFnP cannot be falsy');
    }

    try {
      dispatch(actions.startSubmit());
      const formData = selectors.getFieldValues(getState());
      await submitFnP(formData);
      dispatch(actions.endSubmit());
      return formData;
    } catch (ex) {
      dispatch(actions.endSubmit({ failed: true }));
      throw ex;
    }
  };

export const submitFormWithValidationP =
  ({ submitFnP, onValidationFailed }) =>
  async (dispatch, getState) => {
    if (!submitFnP) {
      throw new Error('submitFnP cannot be falsy');
    }

    dispatch(actions.startSubmit());

    const formValid = selectors.getIsValid(getState());
    if (!formValid) {
      // As of 13/10/21, we havent decided on a standard way to validate forms.
      // Until this is decided, form validation will not throw.
      // Errors will be show on individual fields after the user presses the submit button
      dispatch(actions.endSubmit({ failed: true }));
      if (onValidationFailed) {
        onValidationFailed();
      }
      return false;
    }
    try {
      const formData = selectors.getFieldValues(getState());
      await submitFnP(formData);
      dispatch(actions.endSubmit());
      return formData;
    } catch (ex) {
      dispatch(actions.endSubmit({ failed: true }));
      throw ex;
    }
  };
