import PropTypes from 'prop-types';
import NavTabs from 'web/react-redux/components/nav-tabs/NavTabs';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getAllTabsInfo } from '@sb-billing/redux/nav-tabs';
import { useTranslation } from '@sb-itops/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';

const hooks = ({ tabs, onCloseAllTabs, onNavigateToTab, onCloseTab }) => ({
  useNavMenus: () => {
    const [tooltipOpen, setTooltipOpen] = useState({});
    const [contextOpen, setContextOpen] = useState({});

    const showTooltip = (tabId) => {
      if (contextOpen[tabId] !== true) {
        setTooltipOpen((curr) =>
          Object.keys(curr).reduce((acc, key) => {
            acc[key] = key === tabId;
            return acc;
          }, {}),
        );
      }
    };
    const hideTooltip = (tabId) => setTooltipOpen((curr) => ({ ...curr, [tabId]: false }));

    const showContext = (e, tabId) => {
      e.preventDefault();
      hideTooltip(tabId);
      setContextOpen((curr) => ({ ...curr, [tabId]: true }));
    };
    const hideContext = (tabId) => setContextOpen((curr) => ({ ...curr, [tabId]: false }));

    const closeAllTabs = (selectedTabId) => {
      onCloseAllTabs(undefined);
      hideContext(selectedTabId);
    };

    const closeOtherTabs = (selectedTabId) => {
      const tab = tabs.find(({ tabId }) => tabId === selectedTabId);
      onCloseAllTabs(tab);
      hideContext(selectedTabId);
    };

    const menuOptions = [
      ['Close all tabs', closeAllTabs],
      ['Close other tabs', closeOtherTabs],
    ];

    return {
      tooltipOpen,
      showTooltip,
      hideTooltip,
      contextOpen,
      showContext,
      hideContext,
      menuOptions,
    };
  },
  useTabInfo: () => {
    const { t } = useTranslation();
    const tabInfos = useSelector(getAllTabsInfo({ tabs, t }));

    const tabsInfo = tabs.reduce((acc, tab, idx) => {
      const { primaryLabel, secondaryLabel, tooltip, kbd, icon } = tabInfos[idx];

      acc[tab.tabId] = {
        id: tab.tabId,
        primaryLabel,
        secondaryLabel,
        tooltip,
        kbd,
        icon,
        isActive: tab.active,
        onNavigateToTab: () => onNavigateToTab(tab),
        onCloseTab: () => onCloseTab(tab),
      };

      return acc;
    }, {});

    return {
      tabsInfo,
    };
  },
});

export const NavTabsContainer = withReduxProvider(composeHooks(hooks)(NavTabs));

NavTabsContainer.displayName = 'NavTabsContainer';

NavTabsContainer.propTypes = {
  tabs: PropTypes.array.isRequired,
  onNavigateToTab: PropTypes.func.isRequired,
  onCloseTab: PropTypes.func.isRequired,
  onCloseAllTabs: PropTypes.func.isRequired,
};

NavTabsContainer.defaultProps = {};
