'use strict';

const { validateTypeLabelsAndValues } = require('./validate-type-labels-and-values');

const getTypeByLabel = ({ typeLabels, typeValues }) => {
  validateTypeLabelsAndValues({ typeLabels, typeValues });
  const keys = Object.keys(typeLabels);
  const typeByValue = keys.reduce((acc, key) => {
    acc[typeLabels[key]] = typeValues[key];
    return acc;
  }, {});
  return typeByValue;
};

module.exports = {
  getTypeByLabel,
};
