import {
  TOGGLE_SELECT_INVOICES,
  TOGGLE_EXPAND_MATTER,
  SET_PAYMENTS,
  TOGGLE_AUTO_ALLOCATE_MATTER,
  SELECT_ALL,
  EXPAND_ALL,
  CLEAR_STATE,
  RESET_FILTERS,
  SET_FILTER,
  SET_FILTER_GROUP_VISIBILITY,
  SORT_ROWS,
  TOGGLE_SHOW_FILTERS,
} from './types';

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});

export const clearState = () => ({
  type: CLEAR_STATE,
});

export const toggleSelect = ({ invoiceIds, selected, invoicesMap }) => ({
  type: TOGGLE_SELECT_INVOICES,
  payload: { invoiceIds, selected, invoicesMap },
});

export const toggleExpand = ({ matterId }) => ({
  type: TOGGLE_EXPAND_MATTER,
  payload: { matterId },
});

/**
 * Set and update invoice payments
 * @param {*} paymentsToUpdateMap - map of payments with key: invoiceId, value: amount in cents
 */
export const setPayments = (paymentsToUpdateMap) => ({
  type: SET_PAYMENTS,
  payload: {
    paymentsToUpdateMap,
  },
});

export const toggleAutoAllocate = ({ matterId, isAutoAllocated }) => ({
  type: TOGGLE_AUTO_ALLOCATE_MATTER,
  payload: { matterId, isAutoAllocated },
});

/**
 * @deprecated once BB-14037 - Load on Demand - Bulk Trust to Office is released and stable
 */
export const selectAll = ({ invoiceIds, selected }) => ({
  type: SELECT_ALL,
  payload: { invoiceIds, selected },
});

export const expandAll = ({ matterIds, expanded }) => ({
  type: EXPAND_ALL,
  payload: { matterIds, expanded },
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setFilter = (filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});

export const setFilterGroupVisibility = (filterGroupName, isVisible) => ({
  type: SET_FILTER_GROUP_VISIBILITY,
  payload: { filterGroupName, isVisible },
});

export const sortRows = ({ sortBy, sortDirection }) => ({
  type: SORT_ROWS,
  payload: {
    sortDirection,
    sortBy,
  },
});
