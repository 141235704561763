angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/settings/activities';

  $stateProvider

  .state('settings.activities', {
    templateUrl: BASE_DIRECTORY + '/activities.html',
    url: '/activities',
    data: {
      navHighlights: ['activities']
    }
  })

  .state('settings.activities.list', {
    url: '/list',
    templateUrl: BASE_DIRECTORY + '/activities-list.html',
    controller: 'sbSettingsActivitiesListController',
    controllerAs: 'settingsActivitiesList',
    data: {
      navHighlights: ['activities']
    }
  })

  .state('settings.activities.create', {
    url: '/create',
    templateUrl: BASE_DIRECTORY + '/activities-create.html',
    controller: 'sbSettingsActivitiesEditController',
    controllerAs: 'settingsActivitiesEdit',
    data: {
      navHighlights: ['activities']
    }
  })

  .state('settings.activities.edit', {
    url: '/edit/:activityId',
    templateUrl: BASE_DIRECTORY + '/activities-edit.html',
    controller: 'sbSettingsActivitiesEditController',
    controllerAs: 'settingsActivitiesEdit',
    data: {
      navHighlights: ['activities']
    }
  })

  .state('settings.activities.delete', {
    url: '/delete/:activityId',
    templateUrl: BASE_DIRECTORY + '/activities-delete.html',
    controller: 'sbSettingsActivitiesDeleteController',
    controllerAs: 'settingsActivitiesDelete',
    data: {
      navHighlights: ['activities']
    }
  });

});
