import * as types from './types';

export const initState = {};

export const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case types.SAVE_PRE_DRAFT_INVOICE: {
      const { invoiceId } = action.payload.preDraftInvoice;
      const newState = {
        ...state,
        [invoiceId]: action.payload.preDraftInvoice,
      };
      return newState;
    }
    case types.REMOVE_PRE_DRAFT_INVOICE: {
      const { [action.payload.invoiceId]: removed, ...remaining } = state;
      return remaining;
    }
    default:
      return state;
  }
};
