'use strict';

const { billingFrequency: billingFrequencyValues } = require('./billing-frequency');

// I am refraining from exposing this as yet another enum which could get confusing
// thus exposing this through a function instead for now.
const billingFrequencySubTypeFieldLabelMap = Object.freeze({
  [billingFrequencyValues.MONTHLY]: 'Cycle',
  [billingFrequencyValues.QUARTERLY]: 'Quarter Selection',
  [billingFrequencyValues.ANNUALLY]: 'Month',
});

const getBillingFrequencySubTypeFieldLabel = ({ billingFrequency }) =>
  billingFrequencySubTypeFieldLabelMap[billingFrequency];

module.exports = {
  getBillingFrequencySubTypeFieldLabel,
};
