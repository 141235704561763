import { store } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { opdateCache, rollbackOpdateCache, getById } from './index';

export const restoreDeletedMatter = async ({ matterId }) => {
  await store.dispatch(async () => {
    const existingMatter = getById(matterId);
    const opdateEntity = { ...existingMatter, status: 'Open' };
    opdateCache({ optimisticEntities: [opdateEntity] });

    try {
      const path = `/matter-management/matter/restore/:accountId/${matterId}/`;
      // @ts-ignore
      await fetchPostP({ path });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [opdateEntity] });
      throw err;
    }
  });
};
