'use strict';

/**
 * @typedef {object} SourceItem
 * @property {boolean|null} billable
 */

/**
 * @typedef {object} Fee
 * @property {Array.<SourceItem>} sourceItems
 * @property {boolean} isBillable
 */

/**
 * Derive billable status for fee (frontend code). Returns One of the following 3 status
 * a) true - fee is billable
 * b) false - fee is not billable
 * c) null - a grouped fee with both billable and non-billable items
 * @param {object} fee - latest fee version
 * @returns {boolean|null}
 */
const deriveFeeBillableStatus = (fee) => {
  const { isBillable, sourceItems } = fee;

  // if there are no source items defer to the fee
  if (!sourceItems || sourceItems.length === 0) {
    return isBillable;
  }

  // legacy source items have billable = null and defer to the fee
  if (sourceItems[0].billable === null) {
    return isBillable;
  }

  const hasBillableItems = sourceItems.some(({ billable }) => billable);
  const hasNonBillableItems = sourceItems.some(({ billable }) => billable === false);

  return hasBillableItems && hasNonBillableItems ? null : hasBillableItems;
};

module.exports = {
  deriveFeeBillableStatus,
};
