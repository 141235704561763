import * as React from 'react';
import PropTypes from 'prop-types';

import { featureActive } from '@sb-itops/feature';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities/status';
import composeHooks from '@sb-itops/react-hooks-compose';

import { FinaliseInvoiceConfirmationModalData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { FinaliseInvoiceConfirmationModal } from './FinaliseInvoiceConfirmationModal';

export const FINALISE_INVOICE_CONFIRMATION_MODAL_ID = 'finalise-invoice-confirmation-modal-id';

const onModalClose = () => setModalDialogHidden({ modalId: FINALISE_INVOICE_CONFIRMATION_MODAL_ID });

const hooks = () => ({
  useFinaliseInvoiceConfirmationQuery: ({ invoiceIds, onHasFetchedData }) => {
    const [hasFetchedData, setHasFetchedData] = React.useState(false);
    const isAnticipatedDisbursementsFeatureEnabled = featureActive('BB-9573');

    const { data, loading, error } = useSubscribedQuery(FinaliseInvoiceConfirmationModalData, {
      variables: {
        ids: invoiceIds,
        invoiceListFilter: {
          invoiceStatuses: [invoiceStatus.DRAFT],
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const queryResults = data?.invoiceList?.results;
    const invoices = queryResults ?? [];

    // Execute (optional) callback once query data has been fetched
    //  * E.g. To stop the Bulk Action button's loading state
    if (onHasFetchedData && !loading && queryResults && !hasFetchedData) {
      onHasFetchedData();
      setHasFetchedData(true);
    }

    // Check selected draft invoices for:
    //  1. Unpaid ADs
    //  2. Invoice totals with a balance of $0
    const { invoiceWithUnpaidAdPresent, invoiceWithZeroDollarTotalPresent } = invoices.reduce(
      (acc, invoice) => {
        const invoiceWithUnpaidAdFound = acc.invoiceWithUnpaidAdPresent;
        const invoiceWithZeroDollarTotalFound = acc.invoiceWithZeroDollarTotalPresent;

        // If both conditions are true, exit early
        //  * No need to continue as we need to show the modal by this stage
        //  * Both condition checks are required, as it determines which message to display
        if (invoiceWithUnpaidAdFound && invoiceWithZeroDollarTotalFound) {
          return acc;
        }

        if (!invoiceWithUnpaidAdFound && invoice.listItemProperties.hasUnpaidAnticipatedDisbursements) {
          acc.invoiceWithUnpaidAdPresent = true;
        }

        // When draft invoices have a null invoice total, treat it as 0
        if (!invoiceWithZeroDollarTotalFound && (invoice.totals === null || invoice.totals.total === 0)) {
          acc.invoiceWithZeroDollarTotalPresent = true;
        }

        return acc;
      },
      {
        invoiceWithUnpaidAdPresent: false,
        invoiceWithZeroDollarTotalPresent: false,
      },
    );

    return {
      isAnticipatedDisbursementsFeatureEnabled,
      isLoading: loading,
      invoiceWithUnpaidAdPresent,
      invoiceWithZeroDollarTotalPresent,
    };
  },
});

const dependentHooks = () => ({
  useModal: (props) => {
    const {
      invoiceWithUnpaidAdPresent,
      invoiceWithZeroDollarTotalPresent,
      invoiceIds,
      isAnticipatedDisbursementsFeatureEnabled,
      isLoading,
      // Callbacks
      onFinaliseDraftInvoices,
      onHasFetchedData,
    } = props;

    // Normally, we display the loading state in the modal while fetching data, but this modal may need to display its loading state outside
    //  * E.g. InvoiceListActionBar > Bulk Actions > Finalise
    //  * This is because we might not actually end up needing the modal (for the user to confirm and proceed)
    //    * So if we show and then close the modal without user interaction, it can cause confusion
    //    * Also, the query will be quick too (using list resolver to fetch data)
    //
    // In such cases, we will:
    //  * Initially hide the modal and
    //  * Handle the loading state outside
    //    * E.g. The Bulk Actions has a spinner
    //    * We use the onHasFetchedData callback to signal query completion
    //  * Show the modal if required
    const isModalVisible = onHasFetchedData ? props.isVisible && !isLoading : props.isVisible;

    const onConfirm = () => {
      onFinaliseDraftInvoices({ invoiceIds });
      onModalClose(); // Close this modal, when proceeding
    };

    // Can automatically proceed if both conditions are met:
    //  1. No unpaid ADs
    //  2. No invoice has a total balance of $0
    if (
      !isLoading &&
      (!isAnticipatedDisbursementsFeatureEnabled || !invoiceWithUnpaidAdPresent) &&
      !invoiceWithZeroDollarTotalPresent
    ) {
      onFinaliseDraftInvoices({ invoiceIds });
      onModalClose();
    }

    return {
      hasUnpaidAD: invoiceWithUnpaidAdPresent,
      hasZeroBalance: invoiceWithZeroDollarTotalPresent,
      invoiceCount: invoiceIds.length,
      isLoading,
      isVisible: isModalVisible,
      // Callbacks
      onCancel: onModalClose,
      onConfirm,
    };
  },
});

export const FinaliseInvoiceConfirmationModalContainer = withApolloClient(
  composeHooks(hooks)(composeHooks(dependentHooks)(FinaliseInvoiceConfirmationModal)),
);

FinaliseInvoiceConfirmationModalContainer.displayName = 'FinaliseInvoiceConfirmationModalContainer';

FinaliseInvoiceConfirmationModalContainer.propTypes = {
  invoiceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isVisible: PropTypes.bool.isRequired,
  // Callbacks
  onHasFetchedData: PropTypes.func, // Optional callback to run post query (e.g. to handle loading states outside of the modal)
  onFinaliseDraftInvoices: PropTypes.func.isRequired,
};

FinaliseInvoiceConfirmationModalContainer.defaultProps = {
  onHasFetchedData: undefined,
};
