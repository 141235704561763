import _ from 'lodash';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';

import { updateCache as updateRedux, getById } from '@sb-customer-management/redux/contacts';
import { createContactAddressChecker } from '@sb-customer-management/contact-address-checker';
import { getPersonTitles, isAbbreviatedTitle } from '@sb-customer-management/business-logic/contacts/services';
import { fetchById as fetchContactById, fetchByIds as fetchContactsByIds } from '@sb-customer-management/redux/contacts';
import { getRegion } from '@sb-itops/region';

const getUserId = () => authSelectors.getUserId(store.getState());

angular.module('@sb-customer-management/services').service('sbSimpleContactMbService', function (sbLoggerService, sbGenericEndpointService, sbGenericCacheService, sbUuidService, sbContactsMbService) {
  sbGenericCacheService.setupCache({
    name: 'sbSimpleContactMbService',
    updateRedux,
  });
  const region = getRegion();
  const letterFormat = {
    FORMAL: 0,
    INFORMAL: 1,
    CUSTOM: 2
  };

  const that = this;
  const contactEndpoint = 'customer-management/contact';

  that.saveContactP = saveContactP;
  that.getPersonNameForLetter = getPersonNameForLetter;
  that.getPeopleP = getPeopleP;
  that.getContactPersonTitle = getPersonTitles({ includeTopLabelOption: true, region });
  that.getEmails = getEmails;
  that.getSalutations = getSalutations;
  that.findAuContactsWithMissingStreetAddress = findAuContactsWithMissingStreetAddress;

  function getPersonNameForLetter(contactIdOrContactObj, defaultName) {
    let contact = contactIdOrContactObj;
    if (_.isString(contactIdOrContactObj)) {
      contact = getById(contactIdOrContactObj);
    }

    if (!contact) {
      throw new Error('Cannot get letter name for invalid contact');
    }

    if (!contact.person) {
      throw new Error('Letter name is only supported for person contact');
    }

    const person = contact.person;
    switch (person.letterNameFormat) {
      case letterFormat.FORMAL: {
        let title = _.get(person, 'title', '');
        if (region === 'US' && title && !title.endsWith('.') && isAbbreviatedTitle(title)) {
          title += '.';
        }
        return `${title ? title + ' ' : ''}${!title ? person.firstName + ' ' : ''}${person.lastName}`;
      }

      case letterFormat.INFORMAL: {
        return person.firstName;
      }

      case letterFormat.CUSTOM: {
        return person.customLetterName;
      }

      default: {
        return defaultName;
      }
    }
  }

  //give a contactID (that corresponds to either a 'Person' or 'GroupOfPeople'), get an array of all of the people
  async function getPeopleP (contactId) {
    const people = await derivePeople(contactId);
    return people.reduce((all, info) => all.concat(info), []);
  }

  async function derivePeople (contactId) {
    const contactSummary = sbContactsMbService.getById(contactId);
    const displayName = _.get(contactSummary, 'displayName', '');

    switch (contactSummary.type) {
      case 'Person': {
        const contactDetails = await fetchContactById(contactId);
        let personTitle = _.get(contactDetails, 'person.title');
        if (region === 'US' && personTitle && !personTitle.endsWith('.') && isAbbreviatedTitle(personTitle)) {
          personTitle += '.';
        }
        const nameForLetter = getPersonNameForLetter(contactDetails, `${personTitle ? personTitle + ' ' : '' }${displayName}`);

        return [{
          email: contactSummary.email,
          salutation: nameForLetter,
          id: contactId,
        }];
      }
      case 'GroupOfPeople': {
        return Promise.all(contactSummary.personIds.split(';').map(getPeopleP));
      }
      default: {
        return [{
          email: contactSummary.email,
          salutation: displayName,
          id: contactId,
        }];
      }
    }
  }

  function formatAuAddress(contactEntity) {

    if (region !== 'AU') {
      return;
    }

    // For AU address line 1 and 2 are not used. We need to use
    // DetailedStreetAddress. But because we still allow free form
    // entry in boost GUI we have no way to split the address line 1 & 2
    // into separate fields required in DetailedStreetAddress. So for now
    // we will simply store address line 1 in DetailedStreetAddress.StreetNumber
    // and address line 2 in DetailedStreetAddress.StreetName.

    const streetNumberField = contactEntity.person ? 'person.residentialAddress.detailedStreetAddress.streetNumber' : 'organisation.businessAddress.detailedStreetAddress.streetNumber';
    const streetNameField = contactEntity.person ? 'person.residentialAddress.detailedStreetAddress.streetName' : 'organisation.businessAddress.detailedStreetAddress.streetName';
    const getStreetNumberField = contactEntity.person ? 'person.residentialAddress.addressLine1' : 'organisation.businessAddress.addressLine1';
    const getStreetNameField = contactEntity.person ? 'person.residentialAddress.addressLine2' : 'organisation.businessAddress.addressLine2';

    _.set(contactEntity, streetNumberField, _.get(contactEntity, getStreetNumberField));
    _.set(contactEntity, streetNameField, _.get(contactEntity, getStreetNameField));
  }

  function saveContactP(contactEntity) {
    const payload = {};
    formatAuAddress(contactEntity);
    const entityId = contactEntity.id || sbUuidService.get(), newVersionId = sbUuidService.get();
    contactEntity.id = entityId;
    contactEntity.versionId = newVersionId;

    payload.entityId = entityId;
    payload.userId = getUserId();
    payload.versionId = newVersionId;
    payload.contactEntity = contactEntity;
    
    return sbGenericEndpointService.postPayloadP(
      contactEndpoint, entityId, payload,
      'POST',
      {
        changeset: {
          sbContactsMbService: [{
            entityId: contactEntity.id,
            removed: contactEntity.removed,
            type: contactEntity.organisation ? contactEntity.organisation.type : 'Person',
            displayName: contactEntity.entityName,
            firstName: contactEntity.person && contactEntity.person.firstName,
            lastName: contactEntity.person && contactEntity.person.lastName,
          }]
        }
      }
    );
  }

  /**
   * @deprecated Moved to business logic
   */
  function getEmails (contacts) {
    const contactInfo = contacts.reduce((all, info) => all.concat(info), []).filter((info) => info.email && info.salutation);

    return {
      to: contactInfo.map((c) => `"${c.salutation}" <${c.email}>`).join('; '),
      salutation: joinArrayWithLast(contactInfo.map((c) => c.salutation), ', ', ' and '),
    };
  }

  /**
   * @deprecated Moved to business logic
   */
  function getSalutations(contacts) {
    const contactInfo = contacts.reduce((all, info) => all.concat(info), []).filter((info) => info.salutation);

    return joinArrayWithLast(contactInfo.map((c) => c.salutation), ', ', ' and ');
  }

  /**
   * @deprecated Moved to business logic
   */
  function joinArrayWithLast(arr = [], seperator = ', ', last = ' and ') {
    if (arr.length <= 2) {
      return arr.join(last);
    }

    const [head, ...tail] = arr;
    return `${tail.join(seperator)}${last}${head}`;
  }

   /**
   * @deprecated Moved to monorepo/customer-management/redux/contacts/index.js
   */
  async function findAuContactsWithMissingStreetAddress(contactIds) {
    const contacts = await fetchContactsByIds(contactIds);
    const contactAddressChecker = createContactAddressChecker(region);
    return contactAddressChecker.findContactsWithMissingStreetOrPOAddress(contacts);
  }
});
