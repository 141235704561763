import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { store } from '@sb-itops/redux';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbAccountRemindersSettingsService',
  keyPath: 'accountId',
});

export const saveInvoiceReminderSettings = (settings) => {
  const thunk = async () => {
    const newSettings = { ...settings };

    opdateCache({ optimisticEntities: [newSettings] });

    try {
      const path = `/billing/invoice-reminders/settings/:accountId/`;
      const fetchOptions = { body: JSON.stringify(newSettings) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [newSettings] });
      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(thunk);
};
