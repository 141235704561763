import { gql } from '@apollo/client';

const query = gql`
  query DashboardInvoiceTotals($filter: InvoiceTotalSummaryFilter) {
    invoiceTotalSummary(filter: $filter) {
      overdue
    }
  }
`;

const notificationIds = ['WebQueryInvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DashboardInvoiceTotals = {
  query,
  notificationIds,
};
