import { gql } from '@apollo/client';

const query = gql`
  query BankReconciliationLatestCompletedData($bankAccountId: ID!) {
    bankReconciliationLatestCompletedByBankAccount(bankAccountId: $bankAccountId) {
      id
      endDate
    }
    bankReconciliationSetup(id: $bankAccountId) {
      id
      isRemoved
      reconciliationStartDate
    }
  }
`;

const notificationIds = [
  'AccountsNotifications.BankReconciliationUpdated',
  'AccountsNotifications.BankReconciliationSetupUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BankReconciliationLatestCompletedData = {
  query,
  notificationIds,
};
