'use strict';
import { featureActive } from '@sb-itops/feature'
import { hasFacet, facets } from '@sb-itops/region-facets';
import { store } from '@sb-itops/redux';
import { selectors } from 'web/redux/features/application-state';
import { getIntakeConfig } from 'web/services/intake-features';
import { getRegion, regionType } from '@sb-itops/region';
import { getIsFirmOwner } from 'web/services/user-session-management';

/* global SB_BUILD_VERSION */

// This could be replaced with facet check only once Stripe for GB is release for everyone
// Needs to be a function otherwise feature switches get baked in at init
const supportsPaymentProvider = (region) => {
  const paymentProviderEnabledMap = {
    [regionType.AU]: hasFacet(facets.integrationStripe),
    [regionType.GB]: hasFacet(facets.integrationStripe) && featureActive('BB-12741'),
    [regionType.US]: hasFacet(facets.integrationLawpay) || (hasFacet(facets.integrationFeeWise) && featureActive("BB-12038")),
  }

  return paymentProviderEnabledMap[region];
};

angular.module('sb.billing.webapp').controller('FirmSettingController',function($state, $scope, sbLocalisationService){
  const that = this;
  const nonNavigableStates = ['login', 'pre-authentication', 'post-authentication', 'home.billing.goto-invoice'];

  that.supportsPaymentProvider = supportsPaymentProvider(getRegion());
  that.supportsXero = hasFacet(facets.integrationXero) && featureActive("BB-6222");
  that.t = sbLocalisationService.t;
  
  that.getServerVersion = () => selectors.getServerVersion(store.getState());
  that.SB_BUILD_VERSION = SB_BUILD_VERSION;
  that.showIntakeSettings = () => getIntakeConfig().showIntakeSettings;
  // Show the settings menu item, if either Staff Accounts or Staff permissions tab is allowed to be shown
  that.showStaffSettings = () => featureActive('BB-7845') && (!featureActive('BB-14190') || (featureActive('BB-13941') && getIsFirmOwner()) || featureActive('BB-14531'));
  
  $scope.previousState = '';
  $scope.previousParams = {};
  $scope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
    if ($scope.previousState === '' && !nonNavigableStates.includes(from.name)) {
      $scope.previousState = from.name;
      $scope.previousParams = fromParams;
    }
    setTab();
  });

  that.close = function () {
    if ($scope.previousState && $scope.previousState !== '' && !$scope.previousState.match(/^settings/)) {
      if (!_.isEmpty($scope.previousParams)) {
        $state.go($scope.previousState, $scope.previousParams);
      }
      else {
        $state.go($scope.previousState);
      }
    }
    else {
      if (featureActive('NUCWEB-123')) {
        $state.go('home.billing.dashboard');
      } else {
        $state.go('home.billing.matters');
      }
    }
  };

  function setTab() {
    that.navHighlights = {};
    if ($state.current.data && $state.current.data.navHighlights) {
      _.each($state.current.data.navHighlights, function(tabName) {
        that.navHighlights[tabName] = true;
      });
    }
  }

});
