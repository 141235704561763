import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  activityCategories,
  activityRateOverrideTypes,
} from '@sb-billing/business-logic/activities/entities/constants';
import {
  convertToActivityGroupArray,
  convertToTaskGroupArray,
  deriveActivityRate,
} from '@sb-billing/business-logic/activities/services';
import {
  getRawAmount,
  calculateTaxAmount,
  calculateOutputTaxAmount,
} from '@sb-billing/business-logic/expense/services';
import { entryType as activityEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { QuickExpenseEntry } from './QuickExpenseEntry';

/**
 * The aim of this container is to synchronise field updates for dependent fields.
 */

const hooks = ({
  activities,
  tasks,
  matter: currentMatter,
  registeredForGst,
  isSubjectOverridable,
  utbmsCodesRequiredByFirm,
  setIsSubjectOverridable,
}) => ({
  // Controls the options available in the activity selector drop-down
  useAvailableActivityGroups: () => {
    const [activityGroupArray, setActivityGroupArray] = useState([]);

    useEffect(() => {
      updateAvailableActivityGroupsForMatter(currentMatter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities, currentMatter?.billingConfiguration?.isUtbmsEnabled]);

    const updateAvailableActivityGroupsForMatter = (matter) => {
      const availableActivityCategories = matter?.billingConfiguration?.isUtbmsEnabled
        ? Object.values(activityCategories)
        : [activityCategories.CUSTOM];
      setActivityGroupArray(
        convertToActivityGroupArray({ activities, filter: { types: availableActivityCategories } }),
      );
    };

    return {
      activities: activityGroupArray,
      updateAvailableActivityGroupsForMatter,
    };
  },
  useTaskGroups: () => {
    const [taskGroupArray, setTaskGroupArray] = useState([]);
    useEffect(() => {
      updateAvailableTaskGroupsForMatter(currentMatter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks, currentMatter?.billingConfiguration?.isUtbmsEnabled]);
    const updateAvailableTaskGroupsForMatter = (matter) => {
      if (matter?.billingConfiguration?.isUtbmsEnabled) {
        setTaskGroupArray(convertToTaskGroupArray({ tasks, filter: {} }));
      }
    };
    return {
      tasks: taskGroupArray,
      updateAvailableTaskGroupsForMatter,
    };
  },

  // Controls the options available in the subject typeahead.
  useAvailableSubjects: () => {
    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
      // when utbmsCodesRequiredForMatter is true, activity field is disabled so we need to set subject dropdown options to []
      const utbmsCodesRequiredForMatter =
        utbmsCodesRequiredByFirm && currentMatter?.billingConfiguration?.isUtbmsEnabled;

      if (utbmsCodesRequiredForMatter) {
        setSubjects([]);
      } else {
        setSubjects(activities.filter((activity) => activity.category === activityCategories.CUSTOM));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities, utbmsCodesRequiredByFirm, currentMatter?.billingConfiguration?.isUtbmsEnabled]);

    const updateAvailableSubjectsForMatter = (matter) => {
      const utbmsCodesRequiredForMatter = utbmsCodesRequiredByFirm && matter?.billingConfiguration?.isUtbmsEnabled;
      if (utbmsCodesRequiredForMatter) {
        setSubjects([]);
      } else {
        setSubjects(
          activities.filter(
            (activity) =>
              matter?.billingConfiguration?.isUtbmsEnabled || activity.category === activityCategories.CUSTOM,
          ),
        );
      }
    };

    return {
      subjects,
      updateAvailableSubjectsForMatter,
    };
  },

  // Controls the various form fields state.
  useQuickExpenseEntryForm: ({ formData, taxRateBasisPoints, onUpdateFormData }) => {
    const applyNewFormData = (newFormData) => {
      const {
        activity,
        quantity,
        priceInCents,
        inputTaxAmountInCents,
        outputTaxAmountInCents,
        isTaxInclusive,
        isTaxExempt,
        isInputTaxOverridden,
        isOutputTaxOverridden,
      } = {
        ...formData,
        ...newFormData,
      };

      // Expense calculators require the quantity to be supplied in basis points
      const quantityInBasisPoints = (quantity || 0) * 100;

      // Ensure the amount and tax are calculated correctly
      const price = Number.isFinite(priceInCents) ? priceInCents : 0;

      // Calculate amount
      const amountInCents = getRawAmount({
        quantity: quantityInBasisPoints,
        price,
      });

      // Calculate tax
      let inputTaxCents = inputTaxAmountInCents;
      let outputTaxCents = outputTaxAmountInCents;

      const activityInputTaxRate = activity?.inputTaxRate;
      const activityOutputTaxRate = activity?.outputTaxRate;

      const expense = {
        isTaxOverridden: isInputTaxOverridden,
        isOutputTaxOverridden,
        tax: inputTaxCents,
        outputTax: outputTaxCents,
        amountIncludesTax: isTaxInclusive,
        quantity: quantityInBasisPoints,
        price,
      };

      if (isTaxExempt) {
        inputTaxCents = isInputTaxOverridden ? inputTaxCents : 0;
        // It is a valid scenario that an activity code has tax exempt but with output tax rate, in this case we should re-calculate output tax if it's not overridden
        if (!isOutputTaxOverridden) {
          outputTaxCents =
            activityOutputTaxRate !== undefined && activityOutputTaxRate !== null
              ? calculateOutputTaxAmount({
                  expense: {
                    ...expense,
                    tax: inputTaxCents,
                  },
                  outputTaxRate: activityOutputTaxRate,
                })
              : 0; // Tax exemption is really applicable to input tax only, when output tax is not overridden, it should be set to same as the input tax (like how it behaves for non tax exempt activity at below). But because Desktop currently implements this as 0, we’ll keep 0 for consistency for now. I bug ticket (BB-13867) has been raised to address this.
        }
      } else {
        const taxRate = taxRateBasisPoints || 0; // taxRateBasisPoints could be undefined
        const inputTaxRate =
          activityInputTaxRate !== undefined && activityInputTaxRate !== null ? activityInputTaxRate : taxRate;

        inputTaxCents = calculateTaxAmount(expense, inputTaxRate);
        outputTaxCents =
          activityOutputTaxRate !== undefined && activityOutputTaxRate !== null
            ? calculateOutputTaxAmount({
                expense: {
                  ...expense,
                  tax: inputTaxCents,
                },
                outputTaxRate: activityOutputTaxRate,
              })
            : inputTaxCents; // If an activity code selected does not have input and output tax rates set up, the Output tax value should be set to the same as Input tax.
      }

      onUpdateFormData({
        ...newFormData,
        amountInCents,
        inputTaxAmountInCents: inputTaxCents,
        outputTaxAmountInCents: outputTaxCents,
      });
    };

    // Handles staff change events.
    const onUpdateStaffMemberField = (staffOption) => {
      applyNewFormData({ staffId: staffOption?.id || undefined });
    };

    // Handles matter change events.
    const onUpdateMatterField = (selectedMatter) => {
      const utbmsCodesRequiredForMatter =
        utbmsCodesRequiredByFirm && selectedMatter?.billingConfiguration?.isUtbmsEnabled;

      applyNewFormData({
        matter: selectedMatter,
        activity: undefined,
        task: undefined,
        subject: undefined,
        utbmsCodesRequiredForMatter,
      });
    };

    // Handles activity change events.
    // Select option from Activity dropdown or Subject dropdown will both trigger this function
    const onUpdateActivityField = ({ selectedActivity, isSelectedFromSubjectDropdown }) => {
      const utbmsActivitySelected = selectedActivity.category === activityCategories.UTBMS;

      const quantityInBasisPoints = selectedActivity.durationMins || 0; // default quantity is stored in durationMins

      const priceInCents = utbmsActivitySelected
        ? formData.priceInCents
        : deriveActivityRate({
            activity: selectedActivity,
          });

      const isBillable = selectedActivity.isBillable;

      applyNewFormData({
        activity: selectedActivity,
        task: undefined, // Clear task if an activity is selected, in we do not concatenate Expense Activity/Task subjects
        subject:
          isSubjectOverridable || isSelectedFromSubjectDropdown ? selectedActivity.description : formData.subject,
        isBillable,
        isTaxInclusive: selectedActivity.isTaxInclusive,
        isTaxExempt: selectedActivity.isTaxExempt,
        quantity: utbmsActivitySelected ? 1 : quantityInBasisPoints / 100,
        priceInCents,
      });
    };

    // Handles task change events.
    const onUpdateTaskField = (selectedTask) => {
      // Tasks only make sense for UTBMS activities, clear activity if activity not UTBMS
      // Unlike Fees, Expense subject/description is not concatenated.
      // UTBMS tasks are always (initially) set to billable, custom tasks will have `isBillable` property
      const isBillable = selectedTask.isBillable ?? true;
      applyNewFormData({
        activity: undefined,
        task: selectedTask,
        subject: isSubjectOverridable ? selectedTask.description : formData.subject,
        isBillable,
      });
    };

    // Handles tax amount change events.
    const onUpdateInputTaxAmountField = (inputTaxAmountInCents) => {
      applyNewFormData({
        inputTaxAmountInCents: inputTaxAmountInCents || 0,
        isInputTaxOverridden: true,
      });
    };

    // Handles all other form update events.
    const onUpdateField = (field, newValue) => {
      applyNewFormData({
        [field]: newValue,
      });
    };

    return {
      showTaxField: registeredForGst,
      tasksFieldDisabled: !formData.matter?.billingConfiguration?.isUtbmsEnabled,
      onUpdateField,
      onUpdateActivityField,
      onUpdateMatterField,
      onUpdateStaffMemberField,
      onUpdateTaskField,
      onUpdateInputTaxAmountField,
      setIsSubjectOverridable,
    };
  },
});

export const QuickExpenseEntryContainer = composeHooks(hooks)((props) => {
  // Selecting a new matter (or clearing a selected matter) needs to not only update form state,
  // it also need to update the available options in the activity drop-down and subject typeahead.
  // Rather than polluting the component with intimate knowledge of state, we combine all three operations
  // into a new matter field update function here.
  const onUpdateMatterField = (matter) => {
    props.onUpdateMatterField(matter);
    props.updateAvailableActivityGroupsForMatter(matter);
    props.updateAvailableTaskGroupsForMatter(matter);
    props.updateAvailableSubjectsForMatter(matter);
  };

  return <QuickExpenseEntry {...props} onUpdateMatterField={onUpdateMatterField} />;
});

QuickExpenseEntryContainer.displayName = 'QuickExpenseEntryContainer';

QuickExpenseEntryContainer.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      allStaffRate: PropTypes.number,
      category: PropTypes.oneOf(Object.values(activityCategories)),
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      durationMins: PropTypes.number,
      isBillable: PropTypes.bool.isRequired,
      isTaxExempt: PropTypes.bool,
      isTaxInclusive: PropTypes.bool,
      rateOverrideType: PropTypes.oneOf([...Object.values(activityRateOverrideTypes), undefined]),
      type: PropTypes.oneOf([activityEntryTypes.EXPENSE]),
    }).isRequired,
  ).isRequired,
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  matter: PropTypes.shape({
    billingConfiguration: PropTypes.shape({
      isUtbmsEnabled: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  quickAddDisabled: PropTypes.bool.isRequired,
  registeredForGst: PropTypes.bool,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ),
  taxRateBasisPoints: PropTypes.number,
  onUpdateFormData: PropTypes.func.isRequired,
};

QuickExpenseEntryContainer.defaultProps = {
  matter: undefined,
  registeredForGst: false,
  tasks: [],
  taxRateBasisPoints: undefined,
};
