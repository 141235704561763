import { store } from '@sb-itops/redux';
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { selectors as filterSelectors } from 'web/redux/route/home-billing-accounts-transactions';
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions/client-communications';

  $stateProvider
    .state('home.billing.accounts.transactions.client-communications', {
      url: '/client-communications',
      templateUrl: BASE_DIRECTORY + '/client-communications.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'CLIENT-COMMUNICATIONS',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showMarkAsSentEntries: filterSelectors.getFilters(store.getState()).showMarkAsSentEntries,
        })
      },
      onEnter: function() {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.client-communications', {}], parentPageName: PARENT_PAGE_NAME }));
      }, 
    });
});
