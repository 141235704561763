import { startPollingForVersionsP, stopPollingForVersions } from '../new-version-notifier';

export const startCheckingVersionUpdates = async ({ log }) => {
  try {
    startPollingForVersionsP();
  } catch (err) {
    log.warn('Could not start polling for new versions.', err);
  }

  return () => {
    stopPollingForVersions();
  };
};
