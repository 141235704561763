/* eslint-disable import/no-cycle */
import { consolidateOperatingTransactions } from '@sb-billing/business-logic/transactions/services';
import { getList as getAllTransactions } from './index';
import { getOperatingAccount } from '../bank-account';

/**
 * Get all trust transactions consolidated where applicable
 * 1. trust to office transactions associated with each trust to office transfer are
 *    consolidated into separate pseudo-transactions
 * 2. all other transactions are returned unchanged
 * @param {Object} filterValues
 * @param {boolean} filterValues.showHidden
 * @param {string} filterValues.matterId
 * @param {string} filterValues.contactId
 * @param {string} filterValues.trustAccountId
 * @param {function} filterValues.t i18next translate function
 */
export function getAllConsolidatedOperatingTransactions({ showHidden, matterId, contactId, t } = {}) {
  const allTransactions = getAllTransactions();
  const operatingAccountId = getOperatingAccount().id;
  const transactions = [...allTransactions].filter((txn) => {
    if (txn.bankAccountId !== operatingAccountId) {
      return false;
    }
    if (matterId && txn.matterId !== matterId) {
      return false;
    }
    if (contactId && txn.contactId !== contactId) {
      return false;
    }
    if (!showHidden && txn.isHidden) {
      return false;
    }
    return true;
  });

  const consolidatedTransactions = consolidateOperatingTransactions({
    transactions,
    t,
  });
  return consolidatedTransactions;
}

/**
 * Get consolidated trust transaction record by id
 * @param {String} consolidatedTrustTransactionId
 * @param {Object} filterValues
 * @param {boolean} filterValues.showHidden
 * @param {string} filterValues.matterId
 * @param {string} filterValues.contactId
 * @param {function} t i18next translate function
 *
 */
export function getConsolidatedOperatingTransactionById(id, t, { showHidden, matterId, contactId } = {}) {
  return getAllConsolidatedOperatingTransactions({ showHidden, matterId, contactId, t }).find(
    (transaction) => transaction.id === id,
  );
}
