import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uuid from '@sb-itops/uuid';
import '../affini-pay/fieldGen_1.4.0'; // Horrendously adds AffiniPay to window object.
// import '../affini-pay'; // Horrendously adds AffiniPay to window object.
import Styles from './LawpayHostedFields.module.scss';

const { HostedFields } = window.AffiniPay;

// affini pay has a bug with IPhones/IPad, when focusing out of a hosted input field (cvv/credit-card), the focus property of
// the field that was focused away from is never put back to false. As a result we need to assume that focus has always changed
// away on an apple device. This has the side effect of triggering red validation styles on partially input ccv/credit cards on
// apple devices.
// const isIPadOrIPhone = !!window.navigator.platform && /iPad|iPhone/.test(window.navigator.platform);

// The hosted field updates are sent back to the parent as 'errors'. We can't actually send back
// the real current values of the hosted fields - that's a tokenisation time concern. We send
// back the errors so that the parent can perform it's own overall form validation if the hosted
// fields form part of a larger form.
const hostedFieldMap = { credit_card_number: 'creditCardNumberStatus', cvv: 'securityCodeStatus' };

export const LawpayHostedFields = memo(
  ({ publicKey, disabled, style, creditCardNumberError, securityCodeError, onFieldChange, onHostedFieldsApiReady }) => {
    // The hosted form fields require globally unique ids to function correctly.
    const [creditCardNumberFieldId] = useState(`credit_card_number_${uuid()}`);
    const [cvvFieldId] = useState(`cvv_${uuid()}`);

    // We want the hosted fields initialisation to take place after the initial render of the DOM for this component, hence our
    // use of useEffect(). Note that no dependencies are passed to the second parameter of useEffect(), indicating that we
    // only want the below code to run once, no matter how many times the props used within it might change. The effect is that
    // the caller can't do things like dynamically change the styles of hosted fields. That's a pretty nutso idea anyway.
    useEffect(() => {
      const createConfigField = (id, type) => ({
        selector: `#${id}`,
        input: {
          type,
          css: style,
        },
      });

      const config = {
        publicKey,
        fields: [
          createConfigField(creditCardNumberFieldId, 'credit_card_number'),
          createConfigField(cvvFieldId, 'cvv'),
        ],
      };

      const { getState, getPaymentToken } = HostedFields.initializeFields(config, handleHostedFieldsChange);
      onHostedFieldsApiReady({ getState, getPaymentToken });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The onChange prop is only useful to the parent in determining whether a field is valid or not.
    // We don't send back the actual credit card information to the parent via onChange. Eventually the parent needs
    // to run a full tokenization over the data in the hosted fields, which they can do by calling the functions
    // returned back via onHostedFieldsApiReady().
    const handleHostedFieldsChange = ({ target }) => {
      // Only call the change handlers if a field has lost focus or we are running on an apple device (see comment at top of file)
      /* if (target.focus && !isIPadOrIPhone) {
        return;
      } */

      // We call onChange twice every time the hosted fields "update" (if they've lost focus or changed)
      // For sensitive fields (credit card / ccv), the value is sent to the parent as either true or false.
      // Every hosted field change target includes an additional 'card' property which indicates the type
      // of card currently entered. This could also be useful for validation from above, so we pass that as a
      // separate change update.
      onFieldChange({ field: hostedFieldMap[target.type], value: !target.error });

      // target may containe different things depending whats changing, only update the creditCardType when credit_card_number changes
      if (target.type === 'credit_card_number') {
        onFieldChange({ field: 'creditCardType', value: target.card });
      }
    };

    return (
      <div className={Styles.lawpayHostedFields}>
        <div key="credit_card_number" className={classnames(Styles.cell, Styles.cell50, Styles.creditCardNumber)}>
          <div className={Styles.fieldContainer}>
            <label htmlFor={creditCardNumberFieldId}>Card Number</label>
            <div
              id={creditCardNumberFieldId}
              className={classnames('form-control', creditCardNumberError && Styles.fieldError)}
            />
            {disabled && <div className={classnames(Styles.disabledEntry, 'form-control')} />}
          </div>
        </div>

        <div key="cvv" className={classnames(Styles.cell, Styles.cell50, Styles.securityCode)}>
          <div className={Styles.fieldContainer}>
            <label htmlFor={cvvFieldId}>Security Code</label>
            <div id={cvvFieldId} className={classnames('form-control', securityCodeError && Styles.fieldError)} />
            {disabled && <div className={classnames(Styles.disabledEntry, 'form-control')} />}
          </div>
        </div>
      </div>
    );
  },
);

LawpayHostedFields.displayName = 'LawpayHostedFields';

LawpayHostedFields.propTypes = {
  publicKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  creditCardNumberError: PropTypes.bool,
  securityCodeError: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onHostedFieldsApiReady: PropTypes.func.isRequired,
};

LawpayHostedFields.defaultProps = {
  disabled: false,
  creditCardNumberError: false,
  securityCodeError: false,
  // unfortunately we cannot style the affini pay component with css. The only way they provide a way of doing this is by style options.
  // We need to expose this here so if we need we can override the affini pay default styles
  style: {},
};
