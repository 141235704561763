require('lodash');
window.jQuery = require('jquery');
window.moment = require('moment');
require('angular');
require('angular-animate');
require('angular-bootstrap-contextmenu');
require('angular-chart.js');
require('angular-drag-and-drop-lists');
require('angular-file-saver');
require('angular-simple-logger');
require('angular-hotkeys');
require('angular-messages');
require('angular-ui-bootstrap');
require('angular-ui-router');
require('angular-ui-scroll');
require('bootstrap-sass');
require('textangular/dist/textAngular-sanitize');
require('textangular');
require('ui-router-extras');
require('ui-select');
require('jsonformatter');

require('@sb-itops/services');
// enableFeaturesViaQueryString must run before other ng services so featureActive used
// during services init evaluates correctly when queryString is used in non-prod env
const { enableFeaturesViaQueryString } = require('./services/bootstrapper/enable-features-via-querystring');
enableFeaturesViaQueryString();

require('@sb-billing/services');
require('@sb-matter-management/services');
require('@sb-customer-management/services');
require('@sb-finance/services');
require('@sb-firm-management/services');
require('@sb-matter-listing/services');
require('@sb-matter-types/services');
require('@sb-product-registration/services');

angular.module('sb.billing.webapp', [
  'ngAnimate', 'ngMessages', 'ngFileSaver', 'dndLists',
  'ui.bootstrap', 'ui.router', 'ui.scroll', 'ui.select', 'ct.ui.router.extras', 'ui.bootstrap.contextMenu',
  'cfp.hotkeys', 'chart.js', 'textAngular', 'jsonFormatter',
  '@sb-itops/services', '@sb-finance/services', '@sb-firm-management/services', '@sb-customer-management/services', '@sb-product-registration/services',
  '@sb-matter-management/services', '@sb-billing/services', '@sb-matter-listing/services', '@sb-matter-types/services']);

// Performance of lodash isEmpty() is n^2 shit. Overwrite.
if(_ && _.isEmpty && typeof _.isEmpty === 'function') {
  _.isEmpty = require('./ng-shims/isEmpty');
}

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

requireAll(require.context('./', true, /^((?!demo|\.spec|\.test|app|index).)*\.js$/));
requireAll(require.context('./', true, /^((?!demo|index|non-chrome).)*\.html$/));

require('./react-angular-bridges');
require('./app.js');
