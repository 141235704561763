import {
  getActiveProvider,
  getPaymentFormConfiguration,
  getProviderSettings,
  isFirmCardSavingEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getById as getCustomerBillingConfigByContactId } from '@sb-billing/redux/customer-billing-configuration';
import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import {
  calculateFeeDetails,
  extractFeeSchedule,
  getPaymentSource,
  getMinChargeAmountInCents,
} from '@sb-billing/business-logic/payment-provider/services';
import { useState } from 'react';
import { PAYMENT_SOURCE } from 'web/redux/selectors/payment-source';
import { useTranslation } from '@sb-itops/react';
import { paymentFormTypes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { preCharge as preChargeProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getById as getContactSummaryById } from '@sb-customer-management/redux/contacts-summary';
import { getRegion } from '@sb-itops/region';
import { PaymentDetailsForm } from './PaymentDetailsForm';

const region = getRegion();

const hooks = (props) => ({
  useSelectors: () => {
    const {
      scope,
      getValidateFn,
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      triggerChargeFormSubmit,
      onChargeFormDataChange,
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const { contactId, amount, paymentMethod, saveCardDetails } = formFields;

    // fields which are objects pass as regular object
    const { source } = useSelector(formSelectors.getFieldValues);

    const bankAccountId = getOperatingAccount().id;
    const providerType = getActiveProvider();
    const formattedProviderSpecificSettings = getProviderSettings(providerType);
    const paymentFormConfiguration = getPaymentFormConfiguration({
      bankAccountId,
      providerType,
      providerSpecificFields: {
        paymentFormType: saveCardDetails?.value ? paymentFormTypes.SAVE_DETAILS : paymentFormTypes.CHARGE, // Whether form is used for charging or saving details
        contactSummary: contactId?.value ? getContactSummaryById(contactId.value) : undefined,
      },
    });
    const contactPaymentMethods = getContactPaymentMethods({
      contactId: contactId?.value,
      activeProvider: providerType,
    });
    const contactPaymentMethodOptions = getContactPaymentMethodOptions(contactPaymentMethods);
    const isCardSavingEnabled =
      source?.paymentSource === PAYMENT_SOURCE.creditCard &&
      isFirmCardSavingEnabledForBankAccount({ providerType, bankAccountId });

    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();

    paymentFormConfiguration.enabledPaymentMethods = filterEnabledPaymentMethods(
      paymentFormConfiguration.enabledPaymentMethods,
      source?.paymentSource,
      providerType,
    );

    const clientIsCoveringFee = formattedProviderSpecificSettings.clientCoversFeeOnPayments;

    const feeSchedule = clientIsCoveringFee
      ? extractFeeSchedule({
          providerType,
          formattedProviderSpecificSettings,
          bankAccountId,
        })
      : undefined;

    const feeDetails = clientIsCoveringFee
      ? calculateFeeDetails({
          providerType,
          feeSchedule,
          providerSpecificFields: chargeFormData,
          desiredAmountInCents: amount?.value || 0,
        })
      : {};

    const minAmountAllowed = providerType ? getMinChargeAmountInCents({ providerType, region }) : 0;

    const validate = () => {
      dispatch(formOperations.validateForm({ validateFn: getValidateFn({ t, minAmountAllowed }) }));
    };
    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      validate();
    };

    const onHandlePreCharge = async ({ providerType: _providerType, providerSpecificFields }) =>
      preChargeProvider({ fetchPostP, providerType: _providerType, providerSpecificFields });

    return {
      onFieldValueUpdated,
      contactPaymentMethods,
      contactPaymentMethodOptions,
      providerType,
      clientIsCoveringFee,
      feeDetails,
      isCardSavingEnabled,

      // form fields
      amount,
      paymentMethod,
      saveCardDetails,
      source,

      // form
      submitFailed,
      formDisabled: formSubmitting,
      formInitialised,

      // Underlying charge forms props
      paymentFormConfiguration,
      onChargeFormDataChange: (chargeFormDataChange) => {
        setChargeFormData(chargeFormDataChange);
        onChargeFormDataChange(chargeFormDataChange);
      },
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      triggerChargeFormSubmit,
      onPreCharge: onHandlePreCharge,
    };
  },
});

const getContactPaymentMethodOptions = (contactPaymentMethods) => {
  const contactPaymentMethodOptions = contactPaymentMethods.map((card) => {
    const [month, year] = card.expiry.split('/');
    const expirationYear = year.length === 2 ? year : year.slice(-2); // only 2 digits year
    const expirationMonth = month.padStart(2, '0');

    return {
      label: `${card.description || ''} ${card.display} ${expirationMonth}/${expirationYear}`,
      value: card.token,
    };
  });

  // Add None option for manual payment
  contactPaymentMethodOptions.push({ label: 'Enter new card details', value: 'None' });

  return contactPaymentMethodOptions;
};

const getContactPaymentMethods = ({ contactId, activeProvider }) => {
  const cBconfig = getCustomerBillingConfigByContactId(contactId);
  const validPaymentMethods = (cBconfig?.paymentMethods || []).filter((card) => {
    const [expirationMonth, expirationYear] = card.expiry.split('/');
    const isNotExpiredCard = new Date(expirationYear, expirationMonth, 0) > new Date();
    const isCardForActiveProvider = activeProvider === card.provider;

    return isNotExpiredCard && isCardForActiveProvider;
  });
  return validPaymentMethods;
};

const filterEnabledPaymentMethods = (currentPaymentMethods, paymentSource, providerType) =>
  (currentPaymentMethods || []).filter(
    (pm) => paymentSource === getPaymentSource({ providerType, providerSpecificFields: { paymentMethod: pm } }),
  );

export const PaymentDetailsFormContainer = withReduxProvider(composeHooks(hooks)(PaymentDetailsForm));

PaymentDetailsFormContainer.displayName = 'PaymentDetailsFormContainer';

PaymentDetailsFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  getValidateFn: PropTypes.func.isRequired,
  onChargeFormDataChange: PropTypes.func.isRequired,
  onChargeFormSubmit: PropTypes.func,
  onChargeFormReadyForSubmit: PropTypes.func,
  triggerChargeFormSubmit: PropTypes.bool,
  onTotalChargeAmountUpdated: PropTypes.func,
};

PaymentDetailsFormContainer.defaultProps = {
  onChargeFormSubmit: () => {},
  onChargeFormReadyForSubmit: () => {},
  onTotalChargeAmountUpdated: () => {},
  triggerChargeFormSubmit: false,
};
