'use strict';

const { getLocationIds, regionType, states } = require('@sb-itops/region');

const findAddressLineLocationId = (addressLine, region) => {
  if (!addressLine || !region) {
    return undefined;
  }

  const locationIds = getLocationIds(region);
  if (!locationIds) {
    return undefined;
  }

  let firmLocationId;
  for (let i = 0; i < locationIds.length; i += 1) {
    if (addressLine.includes(locationIds[i])) {
      firmLocationId = locationIds[i];
      break;
    }
  }

  return firmLocationId;
};

const findFirmLocationIdForAu = ({ firmDetails, region }) => {
  // in AU, address are stored as address lines, so we don't have access to a field called state
  let firmLocationId = findAddressLineLocationId(firmDetails?.businessAddress?.addressLine2, region);

  // try to find location from address line 1 if it's not found in address line 2
  if (!firmLocationId) {
    firmLocationId = findAddressLineLocationId(firmDetails?.businessAddress?.addressLine1, region);
  }

  return firmLocationId;
};

const findFirmLocationIdForGb = ({ firmDetails, region }) => {
  // in GB, address are stored in businessAddressDetailed.state, but unlike US it's stored in it non-abbreviated form
  const locations = states[region];

  const firmLocationLabelInLowerCase = firmDetails?.businessAddressDetailed?.state?.trim()?.toLowerCase();
  let firmLocationId;
  if (firmLocationLabelInLowerCase) {
    for (let i = 0; i < locations.length; i += 1) {
      if (firmLocationLabelInLowerCase === locations[i].label.toLowerCase()) {
        firmLocationId = locations[i].value;
      }
    }
  }

  return firmLocationId;
};

const findFirmLocationIdForUs = ({ firmDetails }) => firmDetails?.businessAddress?.state;

const findFirmLocationIdStrategies = {
  [regionType.AU]: findFirmLocationIdForAu,
  [regionType.US]: findFirmLocationIdForUs,
  [regionType.GB]: findFirmLocationIdForGb,
};

const findFirmLocationIdForRegion = (firmDetails, region) => {
  const findFirmLocationIdStrategy = findFirmLocationIdStrategies[region];

  if (!findFirmLocationIdStrategy) {
    throw new Error(`Unsupported region ${region}`);
  }

  const firmLocationId = findFirmLocationIdStrategy({ firmDetails, region });
  return firmLocationId;
};

module.exports = {
  findAddressLineLocationId,
  findFirmLocationIdForRegion,
};
