angular.module('sb.billing.webapp').component('sbBasisPointInput', {
  bindings: { basisPoints: '<?', name: '@?', placeholder: '@?', focus: '@?', disabledFlag: '<?', hasErrorFlag: '<?', onBlur: '&?', onPostBlur: '&?', onChange: '&?', maxBasisPoints: '<?', hidePercent: '<', styleClass: '@?' },
  templateUrl: 'ng-components/basis-point-input/basis-point-input.html',
  controller: function($scope, $timeout) {
    const ctrl = this;

    ctrl.$onInit = () => {
      ctrl.maxPercent = basisPointsToPercent(ctrl.maxBasisPoints);

      // Set default CB functions if not present.
      ctrl.onBlurFn     = _.isFunction(ctrl.onBlur)     ? ctrl.onBlur     : _.noop;
      ctrl.onPostBlurFn = _.isFunction(ctrl.onPostBlur) ? ctrl.onPostBlur : _.noop;
      ctrl.onChangeFn   = _.isFunction(ctrl.onChange)   ? ctrl.onChange   : _.noop;
    };

    ctrl.$onChanges = () => {
      ctrl.maxPercent = basisPointsToPercent(ctrl.maxBasisPoints);
    };

    $scope.$watch('$ctrl.basisPoints', () => {
      ctrl.percent = _.isNumber(ctrl.basisPoints) ? ctrl.basisPoints / 100 : undefined;
    });

    $scope.$watch('$ctrl.percent', (newVal) => {
      const basisPoints = _.isNumber(newVal) && newVal >= 0 ? Math.round(newVal * 100) : undefined;
      ctrl.hasErrorFlag = ctrl.hasErrorFlag || newVal < 0;
      ctrl.onChangeFn({basisPoints});
    });

    ctrl.processFocus = (event) => {
      event.target.select();
    }

    ctrl.processBlur = () => {
      ctrl.onBlurFn();
      $timeout(ctrl.onPostBlurFn);
    };

    function basisPointsToPercent(basisPoints, maxNbDecimals = 2) {
      if (Number.isInteger(basisPoints)) {
        return parseFloat(basisPoints / 100).toFixed(maxNbDecimals);
      }
    }
  }
});
