const Big = require('big.js');

/**
 * @enum {MonetaryUnit}
 */
const MONETARY_UNIT = {
  dollar: 'dollar',
  cent: 'cent',
};

/**
 * All money should be represented in data as cents. This class provides a precision safe way of converting
 * between cents and dollars for handling user input and displaying monetary values.
 * 
 * Stores the amount as cents according to the monetary unit (dollars are converted to cents first). The amount is stored 
 * as given to the constructor, up to 20 decimal places. See the function documentation for rounding rules when accessing
 * the amount.
 * 
 * Constructing a Money object with an invalid amount, as determined by Number.isFinite(), will result in undefined being
 * returned from all operations.
 * 
 * @class Money
 * @see http://mikemcl.github.io/big.js/
 */
class Money {

  /**
   * @constructor
   * @param {number} amount integer or float
   * @param {MonetaryUnit} monetaryUnit 
   * @throws if the moneary unit is invalid
   */
  constructor(amount, monetaryUnit) {
    if (!MONETARY_UNIT[monetaryUnit]) {
      throw new Error(`invalid MONETARY_UNIT {dollar|cent}: ${monetaryUnit}`);
    }

    if (Number.isFinite(amount)) {
      // store amount as cents
      const bigAmount = new Big(amount);
      this.amount = monetaryUnit === MONETARY_UNIT.dollar ? bigAmount.times(100) : bigAmount;  
    } else {
      this.amount = undefined;
    }
  }

  /**
   * For manipulating and storing money values
   * @returns {number|undefined} the cents value as an integer with half-cent rounded up, or undefined if invalid
   */
  cents() {
    if (this.amount === undefined) {
      return;
    }
    return parseInt(this.amount.toFixed(0));
  }

  /**
   * NOT TO BE USED FOR CALCULATIONS OR STORING
   * For passing to currency input fields only
   * @return {number|undefined} the dollar value as a float with half-cent rounded up, e.g. 203.5 (cents) becomes 2.04 (dollars), or undefined if invalid
   */
  dollars() {
    if (this.amount === undefined) {
      return;
    }
    return parseFloat(this.amount.div(100).toFixed(2));
  }

  /**
   * NOTE: this is intended for debugging only - do not actively call this function
   * @return {string|undefined} the cents value as dollars with half-cent rounded up, e.g. 2095 (cents) => "2.10" (dollars), or undefined if invalid
   */
  toString() {
    if (this.amount === undefined) {
      return;
    }
    return this.amount.div(100).toFixed(2);
  }

  /**
   * Takes the Money (Cent or Dollar) value and subtracts it from this value, returning a new Money object
   * e.g. new Dollar(10.01).minus(new Cent(500)).toString() => "5.01"
   * @param {Money} money the Money to subtract
   * @returns {Money} the new Money object
   * @throws if money is not an instance of Money
   */
  minus(money) {
    if (!(money instanceof Money)) {
      throw new Error(`money not instance of Money`);
    }
    return new Cent(this.cents() - money.cents());
  }
}

class Cent extends Money {
  constructor(amount) {
    super(amount, MONETARY_UNIT.cent);
  }
}

class Dollar extends Money {
  constructor(amount) {
    super(amount, MONETARY_UNIT.dollar);
  }
}

module.exports = {
  Cent,
  Dollar,
  Money,
  MONETARY_UNIT,
}