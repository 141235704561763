'use strict';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { getActiveProvider, getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { providerNames, feeCoverageModes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { isErrorSmokeballPreChargeError } from '@sb-billing/business-logic/payment-provider/services';
import { createInvoicePaymentChargeRequest } from '@sb-billing/business-logic/payment-provider/requests/create-invoice-payment-charge-request';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { getChargeErrorMessage } from '@sb-billing/business-logic/payment-provider/services';
import { featureActive } from '@sb-itops/feature';
import { CREDIT_CARD_PAYMENT_MODAL_ID, UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID } from 'web/components';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

angular.module('sb.billing.webapp').component('sbCreditCardPaymentModalButton', {
  bindings: { modalScope: '<', invoiceId: '<', matterId: '<', payorId: '<', disabled: '<' },
  templateUrl: 'ng-components/credit-card-payment-modal-button/credit-card-payment-modal-button.html',
  controller: function (sbNotifiedOperationP, sbGenericEndpointService, sbFirmManagementMbService, sbLocalisationService, sbExpenseService) {
    const ctrl = this;
    const log = getLogger('sbCreditCardPaymentModalButton');
    ctrl.onOpenModal = onOpenModal;
    ctrl.onCloseModal = onCloseModal;
    ctrl.onSubmitP = onSubmitP;
    ctrl.setIsSubmitting = setIsSubmitting;

    ctrl.bankAccountId = getOperatingAccount().id;

    ctrl.viewState = {
      modalVisible: false,
      isSubmitting: false,
    };

    ctrl.disableLODCCModal = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-14095');

    function onOpenModal() {
      const isLodCreditCardPaymentModalEnabled = !ctrl.disableLODCCModal;
      const isAnticipatedDisbursementsFeatureEnabled = featureActive('BB-9573');

      // LOD
      if (isLodCreditCardPaymentModalEnabled) {  

        if (isAnticipatedDisbursementsFeatureEnabled) {
          // If an Invoice has an unpaid AD, we need to show an "acknowledge and then proceed" modal, before allowing the user to continue
          setModalDialogVisible({
            modalId: UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID,
            props: {
              invoiceId: ctrl.invoiceId,
              onAcknowledgeAndProceed: onOpenLodCreditCardPaymentModal // Once the user has acknowledged this, we can open the credit card payment modal
            }
          });

        } else {
          // If AD feature is disabled, the check is unneeded
          onOpenLodCreditCardPaymentModal();
        }

      } else {
        // Legacy
        ctrl.viewState.modalVisible = true;
      }
    }

    function onOpenLodCreditCardPaymentModal() {
      setModalDialogVisible({
        modalId: CREDIT_CARD_PAYMENT_MODAL_ID,
        props: {
          invoiceId: ctrl.invoiceId,
          sbNotifiedOperationP,
          scope: 'finalised-invoice-page/credit-card-payment-modal',
        }
      });
    }

    function onCloseModal() {
      ctrl.viewState.modalVisible = false;
    }

    function setIsSubmitting(value) {
      ctrl.viewState.isSubmitting = value;
    }

    // This function is called when the PaymentProviderChargeCompleted notification related to the credit card payment is
    // received. Note that the PaymentProviderChargeCompleted may contain failures, receipt of PaymentProviderChargeCompleted
    // does not necessarily mean that the credit card charge has been processed successfully.
    function onPaymentCompleted({ message, chargeRequest }) {
      const invoice = chargeRequest.invoiceId && getInvoiceById(chargeRequest.invoiceId);
      const invoiceNumber = invoice && invoice.currentVersion && invoice.currentVersion.invoiceNumber;
      const amountWithSymbol = sbLocalisationService.t('cents', { val: chargeRequest.descriptionAmount });
      const paymentProviderName = providerNames[chargeRequest.providerType] || 'your payment integration';

      if (message.status !== 'FAILED') {
        messageDisplay.success(`A payment of ${amountWithSymbol} against invoice #${invoiceNumber} was successfully charged via ${paymentProviderName}.`);
        onCloseModal();
      } else {
        // TODO: LM: This is terrible and needs a heap of cleaning up.
        let failureMessage = '';
        if (message.smokeballResponse) {
          failureMessage = message.smokeballResponse.failure.message;
        } else {
          const defaultMessage = 'An unexpected error occurred during transaction processing';
          const { message: errorMessage } = getChargeErrorMessage({ defaultMessage, chargeStatusResult: message, providerType: chargeRequest.providerType });
          failureMessage = errorMessage || defaultMessage;
        }

        messageDisplay.error(`A payment of ${amountWithSymbol} against invoice #${invoiceNumber} failed - ${failureMessage}`);
      }

      setIsSubmitting(false);
    }

    // This function is called if the notified operation fails. It means one of two things;
    // 1) The PaymentProviderChargeCompleted was not received within the expected time frame
    // 2) The call to postCreditCardChargeP failed.
    function onPaymentError({ error, chargeRequest }) {
      const invoice = chargeRequest.invoiceId && getInvoiceById(chargeRequest.invoiceId);
      const invoiceNumber = invoice && invoice.currentVersion && invoice.currentVersion.invoiceNumber;
      const amountWithSymbol = sbLocalisationService.t('cents', { val: chargeRequest.amountInCents });

      if (error.operationTimedOut) {
        messageDisplay.warn(`Credit card transaction status for payment of ${amountWithSymbol} against invoice #${invoiceNumber} could not be determined. Please check your merchant portal for reference #${chargeRequest.id}.`);
        return;
      }

      log.error('Credit card payment failed', error);
      messageDisplay.error(`Credit card transaction for payment of ${amountWithSymbol} against invoice #${invoiceNumber} failed prior to processing. The credit card was not charged.`);

      setIsSubmitting(false);
    }

    function onSubmitP(paymentFormData) {
      setIsSubmitting(true);

      const matterId = ctrl.matterId;
      const invoiceId = ctrl.invoiceId;
      const invoice = invoiceId && getInvoiceById(invoiceId);
      const invoiceNumber = invoice && invoice.currentVersion && invoice.currentVersion.invoiceNumber;

      try {
        const providerType = getActiveProvider();
        const providerSpecificSettings = getProviderSettings(providerType);
        const staff = sbFirmManagementMbService.getLoggedInStaffMember();
        const staffName = staff.name || 'firm staff member';
        const firmName = sbFirmManagementMbService.getLoggedInStaffFirmName();
        const feeCoverageMode = providerSpecificSettings.clientCoversFeeOnPayments ? feeCoverageModes.CLIENT_PAYS : feeCoverageModes.FIRM_PAYS;
        const targetBankAccountId = getOperatingAccount().id;
        const accountId = getAccountId();
        const requestedByUserId = getUserId();

        const chargeRequest = createInvoicePaymentChargeRequest({ accountId, requestedByUserId, paymentFormData, providerType, staffName, firmName, matterId, invoiceId, feeCoverageMode, targetBankAccountId, t: sbLocalisationService.t });
        const postCreditCardChargeP = () => sbGenericEndpointService.postPayloadP(`/billing/payment-provider/charge/${providerType.toLowerCase()}`, undefined, chargeRequest);

        return sbNotifiedOperationP(postCreditCardChargeP, {
          requestId: chargeRequest.id,
          completionNotification: 'PaymentProviderChargeCompleted',
          completionFilterFn: message => message.id === chargeRequest.id,
          timeoutMs: 45000
        })
          .then(notification => onPaymentCompleted({ message: notification.payload, chargeRequest }))
          .catch(error => onPaymentError(error, chargeRequest));
      } catch (error) {
        log.error(error);
        if (isErrorSmokeballPreChargeError(error)) {
          const errText = `The transaction was declined by the card issuer: ${error.message}`;
          messageDisplay.error(messageDisplay.builder().title("The credit card was not charged").text(errText));
        } else {
          messageDisplay.error(`Credit card transaction for invoice #${invoiceNumber} failed prior to processing. The credit card was not charged.`);
        }
        setIsSubmitting(false);
      }
    }
  }
});
