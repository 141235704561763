import { useEffect, useState } from 'react';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  getById as getSubscriptionByAccountId,
  opdateCache as opdateSubscription,
  getSubscription,
} from '@sb-finance/redux/subscription';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { setDefaultPaymentMethod } from 'web/services/subscription';
import { getAccountId } from 'web/services/user-session-management';
import { UpdatePaymentMethodCompleteRoute } from './UpdatePaymentMethodCompleteRoute';

// this route is intended to handle the unlikely scenario where Stripe redirects upon
// confirmSetup, this route can be tested using a 3DS card for subscription.

const hooks = () => ({
  useSmokeballSubscriptionRecord: () => {
    const subscription = getSubscription();
    return {
      subscription,
    };
  },
  useSetSubscriptionPaymentMethod: () => {
    const [isSubscriptionPaymentMethodUpdating, setIsSubscriptionPaymentMethodUpdating] = useState(false);
    useEffect(() => {
      const setPaymentMethod = async () => {
        setIsSubscriptionPaymentMethodUpdating(true);

        try {
          const urlParams = new URLSearchParams(window.location.search);
          const setupIntentId = urlParams.get('setup_intent');
          if (!setupIntentId) {
            throw new Error('SetupIntentId not found');
          }

          const response = await setDefaultPaymentMethod({ setupIntentId });
          const { paymentMethodId, tierChanged, tierId } = response.body || {};

          // opdate subscription
          const accountId = getAccountId();
          const subscription = getSubscriptionByAccountId(accountId);
          const subscriptionForOpdate = { ...subscription };

          subscriptionForOpdate.externalPaymentMethodIds = [paymentMethodId];
          opdateSubscription({ optimisticEntities: [subscriptionForOpdate] });

          // If the tier has changed, let the backend know to change the firm's product tier.
          if (tierChanged) {
            await dispatchCommand({
              type: 'ProductRegistration.Commands.SetProductIdForFirmProductAccount',
              message: { productTierId: tierId },
            });
          }
        } catch (err) {
          displayErrorToUser('Failed to update subscription payment method');
        } finally {
          setIsSubscriptionPaymentMethodUpdating(false);
        }
      };

      setPaymentMethod();
    }, []);

    return {
      isSubscriptionPaymentMethodUpdating,
    };
  },
  useConfirmPaymentMethodUpdatedHandler: () => {
    const onAcknowledgePaymentMethodUpdated = () => {
      // clear query string params passed by stripe
      const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
      const stripeRedirectUrlParams = ['setup_intent', 'setup_intent_client_secret', 'redirect_status'];
      stripeRedirectUrlParams.forEach((urlParam) => urlParams.delete(urlParam));

      // redirect to billing matters page
      window.location.href = `?${urlParams.toString()}/#/billing/matters`;
    };

    return {
      onAcknowledgePaymentMethodUpdated,
    };
  },
});

export const UpdatePaymentMethodCompleteRouteContainer = composeHooks(hooks)(UpdatePaymentMethodCompleteRoute);

UpdatePaymentMethodCompleteRouteContainer.displayName = 'UpdatePaymentMethodCompleteRouteContainer';

UpdatePaymentMethodCompleteRouteContainer.propTypes = {};

UpdatePaymentMethodCompleteRouteContainer.defaultProps = {};
