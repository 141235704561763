import {
  balanceTypes,
  validAccountTypeForBalanceType,
} from '@sb-billing/business-logic/bank-account-balances/entities/constants';

/**
 * @typedef {object} Allocation
 * @property {matterId} contactId
 * @property {number} available available balance
 * @property {number} amount amount to pay
 */

/**
 * TODO
 */
const createTableRows = (matterContactBalances, allocations = {}) => {
  if (!matterContactBalances) {
    throw new Error(`matterContactBalances cannot be falsy`);
  }

  return matterContactBalances.map((cb) => ({
    ...cb,
    available:
      cb.type.toUpperCase() in validAccountTypeForBalanceType ? cb[balanceTypes.AVAILABLE] : cb[balanceTypes.BALANCE],
    amount: (allocations[cb.contactId] && allocations[cb.contactId].amount) || 0,
  }));
};

export { createTableRows };
