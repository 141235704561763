import { gql } from '@apollo/client';

const query = gql`
  query MatterMemos($ids: [ID], $filter: MemoFilter, $offset: Int, $limit: Int) {
    memos(ids: $ids, filter: $filter, offset: $offset, limit: $limit) {
      id
      accountId
      matterId
      title
      text
      createdDate
      createdByStaff {
        name
        initials
        colorFill
      }
      userStaff {
        name
        initials
        colorFill
      }
      timestamp
      isDeleted
    }
  }
`;

export const MatterMemos = {
  query,
  notificationIds: ['MemoNotifications'],
  onOpdateQuery: ({ queryData, entityOpdateEvent }) => {
    const { eventType, typeName, optimisticEntity } = entityOpdateEvent;
    if (typeName !== 'Memo') {
      return undefined;
    }
    if (eventType === 'ENTITY_ADDED') {
      return {
        memos: [...queryData.memos, { ...optimisticEntity, text: optimisticEntity.rtf }],
      };
    }
    if (eventType === 'ENTITY_UPDATED' || eventType === 'ENTITY_REMOVED') {
      return {
        memos: queryData.memos.map((memo) =>
          memo.id === optimisticEntity.id
            ? { ...memo, ...optimisticEntity, text: optimisticEntity.rtf || memo.text }
            : memo,
        ),
      };
    }
    return undefined;
  },
};
