'use strict';

angular.module('sb.billing.webapp')
  .directive('sbInvoiceSettings', function () {
    return {
      restricte: 'E',
      replace: true,
      controller: 'SbInvoiceSettingsController',
      controllerAs: 'invoiceSettings',
      scope: { templateId : '<?', onSave : "<?" },
      templateUrl: 'ng-components/invoice-settings/invoice-settings.html',
    };
  })
  // this filter is used to reduce the selections of the font selector when someone types in the font selector box
  .filter('propsFilter', function () {
    return function (items, props) {
      if (!angular.isArray(items)) {
        return items;
      }

      const keys = Object.keys(props);

      return items.filter((item) => {
        return keys.some((prop) => {
          const text = props[prop].toLowerCase();
          return item[prop].toLowerCase().includes(text);
        });
      });
    };
  });
