import { subscribeToCommandDispatchEvents } from '@sb-integration/web-client-sdk';
import { opdateLegacyCacheForCommandResult } from 'web/services/legacy-cache-opdaters';
import { opdateApolloCacheForCommandResult } from 'web/services/apollo';
import { sendMetric } from 'web/services/metrics';


export const initialiseCommandDispatchListeners = async ({ log }) => {
  log.info('Initialising legacy cache opdate listener');
  const unsubscribeLegacy = subscribeToCommandDispatchEvents({
    onCommandDispatched: opdateLegacyCacheForCommandResult,
  });

  log.info('Initialising apollo cache opdate listener');
  const unsubscribeApollo = subscribeToCommandDispatchEvents({
    onCommandDispatched: opdateApolloCacheForCommandResult,
  });

  log.info('Initialising metrics listener');
  const unsubscribeMetrics = subscribeToCommandDispatchEvents({
    onCommandDispatched: ({ commandType }) => sendMetric('NSBCommand', { commandType }),
  });

  return () => {
    unsubscribeLegacy();
    unsubscribeApollo();
    unsubscribeMetrics();
  };
};
