import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect } from '@sb-itops/react/select';
import { LawpayHostedFields } from '../lawpay-hosted-fields';
import lawPayLogoSrc from '../affini-pay/lawpay-logo.png';
import { statesInUsa } from '../affini-pay/states-in-usa';

import Styles from './LawpayCreditCardForm.module.scss';

const applyFormFieldErrorClass = ({ isInvalid, isDirty }) => {
  if (isInvalid && isDirty) {
    return Styles.hasError;
  }

  return undefined;
};

export const LawpayCreditCardForm = memo(
  ({
    publicKey,
    yearOptions,
    monthOptions,
    formInitialised,
    formFields,
    isSubmitting,
    hostedFieldsStyle,
    onChange,
    onHostedFieldsApiReady,
  }) => {
    // Return if the form data hasn't yet been initialised.
    if (!formInitialised) {
      return null;
    }

    // get fields from the form data
    const {
      cardholderName,
      expirationMonth,
      expirationYear,
      addressLine1,
      city,
      addressLine2,
      state,
      postalCode,
      email,
      creditCardNumberStatus,
      securityCodeStatus,
    } = formFields;

    const onEventBasedChangeHandler = (e) => {
      const target = e.currentTarget;
      onChange(target.name, target.value);
    };

    const onHostedFieldsChangeHandler = ({ field, value }) => {
      onChange(field, value);
    };

    const onSelectChangeHandler = (field, item) => {
      if (item && item.value) {
        return onChange(field, item.value);
      }

      // Allow the value to be cleared if the select component has isClearable set to true
      return onChange(field, undefined);
    };

    return (
      <div className={classnames(Styles.lawpayCreditCardForm, 'payment-provider-form-fields', 'lawpay-form-fields')}>
        <fieldset disabled={isSubmitting}>
          <div className={Styles.container}>
            {/* Cardholder name and lawpay logo row */}
            <div className={Styles.row}>
              {/* Cardholder name field */}
              <div className={classnames(Styles.cell, Styles.cell75)}>
                <label>Cardholder Name</label>
                <input
                  name="cardholderName"
                  type="text"
                  className={classnames(
                    'form-control',
                    Styles.withLineHeight,
                    applyFormFieldErrorClass(cardholderName),
                  )}
                  value={cardholderName.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>

              {/* Lawpay logo */}
              <div className={classnames(Styles.cell, Styles.cell25)}>
                <img className={Styles.lawPayLogo} src={lawPayLogoSrc} alt="Processing by LawPay.com" />
              </div>
            </div>

            {/* Hosted fields (card number and security code) */}
            {/* We wrap hosted fields to form tag which makes them separate form. Without this, address autofill in browser clears these fields */}
            <form>
              <div className={classnames(Styles.row, 'credit-card-entry-group-mobile')}>
                <LawpayHostedFields
                  disabled={isSubmitting}
                  publicKey={publicKey}
                  creditCardNumberError={creditCardNumberStatus.isDirty && creditCardNumberStatus.isInvalid}
                  securityCodeError={securityCodeStatus.isDirty && securityCodeStatus.isInvalid}
                  style={hostedFieldsStyle}
                  onFieldChange={onHostedFieldsChangeHandler}
                  onHostedFieldsApiReady={onHostedFieldsApiReady}
                />
              </div>
            </form>

            {/* Expiration date row */}
            <div className={Styles.row}>
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>Expiration Date</label>
                <div className={Styles.expiration}>
                  {/* Expiration month field */}
                  <StatelessSelect
                    className={classnames(
                      Styles.cellType,
                      Styles.cell50,
                      Styles.select,
                      applyFormFieldErrorClass(expirationMonth),
                    )}
                    disabled={isSubmitting}
                    isClearable={false}
                    selectedOption={expirationMonth && { value: expirationMonth.value, label: expirationMonth.value }}
                    stateless
                    options={monthOptions}
                    onChange={(selectedOption) => onSelectChangeHandler('expirationMonth', selectedOption)}
                  />

                  <span className={Styles.separator}>/</span>

                  {/* Expiration year field */}
                  <StatelessSelect
                    className={classnames(
                      Styles.cellType,
                      Styles.cell50,
                      Styles.select,
                      applyFormFieldErrorClass(expirationYear),
                    )}
                    disabled={isSubmitting}
                    isClearable={false}
                    selectedOption={expirationYear && { value: expirationYear.value, label: expirationYear.value }}
                    stateless
                    options={yearOptions}
                    onChange={(selectedOption) => onSelectChangeHandler('expirationYear', selectedOption)}
                  />
                </div>
                {expirationMonth.invalidReason && (
                  <div className={classnames(Styles.cellType, Styles.cell50, Styles.errorMessage)}>
                    {expirationMonth.invalidReason}
                  </div>
                )}
              </div>
            </div>

            {/* Address block */}
            <div className={Styles.row}>
              {/* Address line 1 field */}
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>Address Line 1</label>
                <input
                  name="addressLine1"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(addressLine1))}
                  value={addressLine1.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>

              {/* Address line 2 field */}
              <div className={classnames(Styles.cell, Styles.cell50, Styles.showOnLowerRes)}>
                <label>Address Line 2</label>
                <input
                  name="addressLine2"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(addressLine2))}
                  value={addressLine2.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>

              {/* City field */}
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>City</label>
                <input
                  name="city"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(city))}
                  value={city.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>
            </div>
            <div className={Styles.row}>
              {/* Address line 2 field */}
              <div className={classnames(Styles.cell, Styles.cell50, Styles.hideOnLowerRes)}>
                <label>Address Line 2</label>
                <input
                  name="addressLine2"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(addressLine2))}
                  value={addressLine2.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>
              <div className={classnames(Styles.cellGroup, Styles.cell50)}>
                {/* State field */}
                <div className={classnames(Styles.cell, Styles.cell50)}>
                  <label>State</label>
                  <StatelessSelect
                    className={classnames(applyFormFieldErrorClass(state), Styles.states, Styles.select)}
                    selectedOption={state.value}
                    stateless
                    placeholder=" "
                    disabled={isSubmitting}
                    isClearable={false}
                    options={statesInUsa}
                    onChange={(selectedOption) => onSelectChangeHandler('state', selectedOption)}
                  />
                </div>

                <span className={classnames(Styles.separator, Styles.hideOnLowerRes)} />

                {/* Zip / postal code field */}
                <div className={classnames(Styles.cell, Styles.cell50, Styles.zip)}>
                  <label>Zip</label>
                  <input
                    name="postalCode"
                    type="text"
                    placeholder="12345 or 1234-1234"
                    className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(postalCode))}
                    value={postalCode.value}
                    onChange={onEventBasedChangeHandler}
                  />
                </div>
              </div>
            </div>

            {/* Email field */}
            <div className={Styles.row}>
              <div className={classnames(Styles.cell, Styles.cell100)}>
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  className={classnames(
                    'form-control',
                    Styles.withLineHeight,
                    applyFormFieldErrorClass(email),
                    Styles.emailLine,
                  )}
                  value={email.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    );
  },
);

LawpayCreditCardForm.displayName = 'PaymentForm';

LawpayCreditCardForm.propTypes = {
  publicKey: PropTypes.string.isRequired,
  formInitialised: PropTypes.bool,
  formFields: PropTypes.any,
  isSubmitting: PropTypes.bool.isRequired,
  yearOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  monthOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  hostedFieldsStyle: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onHostedFieldsApiReady: PropTypes.func.isRequired,
};

LawpayCreditCardForm.defaultProps = {
  formInitialised: false,
  formFields: undefined,
  hostedFieldsStyle: {},
};
