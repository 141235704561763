/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, useTranslation } from '@sb-itops/react';
import { BankReconciliationTable } from '@sb-billing/react';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import Styles from './BankReconciliationsList.module.scss';

const BankReconciliationsList = memo(
  ({
    trustAccount,
    trustAccountClosed,
    onClickLink,
    bankReconciliationsListData,
    onCreateBankRecon,
    onSetupBankRecon,
    onSort,
    showUpToDateMessage,
    sortDirection,
    buttonStates,
  }) => {
    const { t } = useTranslation();
    return (
      <div className="panel-body">
        <div className="ribbon panel panel-primary">
          {!trustAccountClosed && (
            <>
              {buttonStates?.recon?.show && (
                <Button onClick={buttonStates.recon.isUpToDate ? showUpToDateMessage : onCreateBankRecon}>
                  {buttonStates.recon.label}
                </Button>
              )}
              {buttonStates?.setup?.show && <Button onClick={onSetupBankRecon}>{buttonStates.setup.label}</Button>}
            </>
          )}
          {trustAccountClosed && (
            <div className={classnames(Styles.title, 'capitalise')}>{`${getBankAccountName(
              trustAccount,
              t,
            )} (Closed)`}</div>
          )}
        </div>
        <BankReconciliationTable
          bankReconciliations={bankReconciliationsListData}
          sort={onSort}
          sortDirection={sortDirection}
          onClickLink={onClickLink}
        />
      </div>
    );
  },
);

BankReconciliationsList.displayName = 'BankReconciliationsList';

BankReconciliationsList.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  bankReconciliationsListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreateBankRecon: PropTypes.func.isRequired,
  onSetupBankRecon: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.string.isRequired,
  buttonStates: PropTypes.object.isRequired,
  showUpToDateMessage: PropTypes.func.isRequired,
  trustAccount: PropTypes.object.isRequired,
  trustAccountClosed: PropTypes.bool.isRequired,
};

BankReconciliationsList.defaultProps = {};

export default BankReconciliationsList;
