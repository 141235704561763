'use strict';

/**
 * Localises transaction or payment (P) source which can be any one of the below:
 * AU:
 *  - null                  | P
 *  - Cash                  | P
 *  - Check                 | P
 *  - Bank Transfer (note upper case transfer - this was in live AU reporting db only)
 *  - Bank transfer         | P (note lower case transfer - this was in staging AU reporting db only for transactions, but was in AU Live for payments)
 *  - Bank Check
 *  - Opening Balance (staging reporting DB only)
 *  - Direct Debit
 *  - Credit Card           | P
 *  - Trust Check
 *  - Credit
 *
 * US:
 *  - null                  | P
 *  - Bank transfer         | P
 *  - Check                 | P
 *  - Credit Card           | P
 *  - Cash                  | P
 *  - Credit
 *  - eCheck
 *  - opening balance (note both lower case words)
 *
 * Check and Trust needs to be localised
 * @param {string} source
 * @param {func} t i18next translation
 * @returns {string} localised source
 */
const localiseTransactionSource = ({ source, t }) => {
  if (!source) {
    return source;
  }

  let localisedSource = source;

  if (source.includes('Check')) {
    localisedSource = localisedSource.replace('Check', t('capitalize', { val: 'cheque' }));
  }

  if (source.includes('Trust')) {
    localisedSource = localisedSource.replace('Trust', t('trust'));
  }

  return localisedSource;
};
module.exports = {
  localiseTransactionSource,
};
