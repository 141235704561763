import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react';
import { PlusSquare } from 'web/components/icons';
import * as Styles from './CreateNewButton.module.scss';

// This small component is for an angular page as it does not have easy access to the lucide icons
export const CreateNewButton = ({ onClick, disabled, locked }) => (
  <Button className={Styles.button} onClick={onClick} disabled={disabled} locked={locked} type="warning">
    Create New
    <PlusSquare />
  </Button>
);

CreateNewButton.displayName = 'CreateNewButton';

CreateNewButton.propTypes = {
  onClick: PropTypes.func,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
};
CreateNewButton.defaultProps = {
  onClick: () => {},
  locked: false,
  disabled: false,
};
