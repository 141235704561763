import React from 'react';
import { StatelessSelect } from '@sb-itops/react/select';
import { SlidingToggle, useTranslation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import Styles from '../ReportFilter.module.scss';

const MatterBalancesFilter = ({ onToggleOption, onSelectOption, filters, staff, matterStatuses }) => {
  const { t } = useTranslation();
  if (!filters) {
    return null;
  }

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  const onViewOption = (option) => onSelectOption({ key: 'viewOption', option });

  const {
    includeFirmHeader,
    includeFirmLogo,
    includeFirmName,
    attorneyResponsible,
    matterStatus,
    viewOption,
    excludeZeroBalanceMatters,
    excludeIncompleteMatters,
    excludeExternallyBilled,
    splitUnbilledAmounts,
    showMatterDebt,
  } = filters;

  return (
    <div className={Styles.filters}>
      <div className={Styles.attorney}>
        <label>{t('capitalizeAll', { val: 'personResponsible' })}</label>
        <StatelessSelect
          selectedOption={attorneyResponsible}
          isClearable={false}
          options={staff}
          onValueChange={(option) => onSelectOption({ key: 'attorneyResponsible', option })}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.attorney}>
        <label>Matter status</label>
        <StatelessSelect
          selectedOption={matterStatus}
          isClearable={false}
          options={matterStatuses}
          onValueChange={(option) => onSelectOption({ key: 'matterStatus', option })}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.printOptions}>
        <label>View options</label>
        <div className={Styles.options}>
          <input
            type="radio"
            onChange={() => onViewOption('showAsList')}
            checked={viewOption === 'showAsList'}
            className={Styles.radioOption}
          />
          <span className={Styles.option} onClick={() => onViewOption('showAsList')}>
            Show as list
          </span>
          <input
            type="radio"
            onChange={() => onViewOption('groupByClient')}
            checked={viewOption === 'groupByClient'}
            className={Styles.radioOption}
          />
          <span className={Styles.option} onClick={() => onViewOption('groupByClient')}>
            Group by client
          </span>
          <div className={Styles.separator} />
          <span data-name="excludeZeroBalanceMatters" onClick={toggleOption} className={Styles.option}>
            Exclude matters with {t('currencySymbol')}0 balances
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="excludeZeroBalanceMatters"
                name="excludeZeroBalanceMatters"
                selected={excludeZeroBalanceMatters}
              />
            </div>
          </span>
          <span data-name="excludeIncompleteMatters" onClick={toggleOption} className={Styles.option}>
            Exclude incomplete matters
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="excludeIncompleteMatters"
                name="excludeIncompleteMatters"
                selected={excludeIncompleteMatters}
              />
            </div>
          </span>
          {featureActive('BB-2530') && (
            <span data-name="excludeExternallyBilled" onClick={toggleOption} className={Styles.option}>
              Exclude externally billed
              <div className={Styles.toggleContainer}>
                <SlidingToggle
                  scope="excludeExternallyBilled"
                  name="excludeExternallyBilled"
                  selected={excludeExternallyBilled}
                />
              </div>
            </span>
          )}
          {featureActive('BB-6682') && (
            <span data-name="splitUnbilledAmounts" onClick={toggleOption} className={Styles.option}>
              Split unbilled amounts
              <div className={Styles.toggleContainer}>
                <SlidingToggle
                  scope="splitUnbilledAmounts"
                  name="splitUnbilledAmounts"
                  selected={splitUnbilledAmounts}
                />
              </div>
            </span>
          )}
          <span
            data-name="showMatterDebt"
            title="Debt calculates Total Billed less Paid"
            onClick={toggleOption}
            className={Styles.option}
          >
            Show Matter Debt
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showMatterDebt" name="showMatterDebt" selected={showMatterDebt} />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.separator} />
    </div>
  );
};

MatterBalancesFilter.displayName = 'MatterBalancesFilter';

export default MatterBalancesFilter;
