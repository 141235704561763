import { connect } from 'react-redux';
import React, { useState } from 'react';
import { withReduxStore } from '@sb-itops/react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getLogger } from '@sb-itops/fe-logger';
import PropTypes from 'prop-types';
import { error as displayErrorToUser /* , success as displaySuccessToUser */ } from '@sb-itops/message-display';
import { GenerateLogoModal } from './GenerateLogoModal';

const log = getLogger('GenerateLogoModal.container');

const mapStateToProps = (state, { isVisible, onClose }) => ({
  isVisible,
  onClose,
});

const mapDispatchToProps = () => ({
  onGenerateLogo: async ({ promptText, setImageUrl, setGenerationInProgress }) => {
    try {
      setGenerationInProgress(true);

      const response = await fetchPostP({
        path: `/firm-management/upload-logo/generate-logo/:accountId/`,
        fetchOptions: {
          body: JSON.stringify({
            prompt: promptText,
          }),
        },
      });

      setImageUrl(response.body.url);
    } catch (err) {
      log.error('Failed to generate invoice logo', err);
      displayErrorToUser('Failed to generate invoice logo');
    } finally {
      setGenerationInProgress(false);
    }
  },
});

export const GenerateLogoModalContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const [promptText, setPromptText] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [generationInProgress, setGenerationInProgress] = useState(false);

    return (
      <GenerateLogoModal
        {...props}
        imageUrl={imageUrl}
        onGenerateLogo={() => props.onGenerateLogo({ promptText, setImageUrl, setGenerationInProgress })}
        generationInProgress={generationInProgress}
        promptText={promptText}
        onPromptTextChange={setPromptText}
        onApplyGeneratedImageAsFirmLogo={() => props.onApplyGeneratedImageAsFirmLogo({ logoFileUrl: imageUrl })}
      />
    );
  }),
);

GenerateLogoModalContainer.displayName = 'GenerateLogoModalContainer';

GenerateLogoModalContainer.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onApplyGeneratedImageAsFirmLogo: PropTypes.func.isRequired,
};

GenerateLogoModalContainer.defaultProps = {
  isVisible: false,
};
