import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, Spinner, TemplateMacroSelector, TextEditor, SlidingToggle, useTranslation } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { depositRequestDefaults } from '@sb-billing/business-logic/deposit-request/entities';
import { getLogger } from '@sb-itops/fe-logger';

import Styles from './DepositRequestEmailSettings.module.scss';

const log = getLogger('DepositRequestEmailSettings');

const DepositRequestEmailSettings = React.memo(({ settings, onSaveSettings, depositRequestEmailMacros }) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState(
    () => settings?.depositRequestEmailSubject || depositRequestDefaults.DEPOSIT_REQUEST_EMAIL_SUBJECT,
  );
  const [message, setMessage] = useState(
    () => settings?.depositRequestEmailBody || depositRequestDefaults.DEPOSIT_REQUEST_EMAIL_MESSAGE_FN(t),
  );

  // Nullish coalescing operator returns default "true" only if variable is null or undefined
  const [sendCopy, setSendCopy] = useState(() => settings?.sendDepositRequestEmailCopyToUser ?? true);
  const [saving, setSaving] = useState(false);
  // if the user hasn't save the settings before, default the dirty to false
  // this is so the user can save the default settings straight away
  const [dirty, setDirty] = useState(() => !settings);

  const emailSubjectRef = useRef();
  let emailBodyReactQuillRef;
  const [subjectSelectedMacro, setSubjectSelectedMacro] = useState('');
  const [bodySelectedMacro, setBodySelectedMacro] = useState('');
  const [bodyEditorCursorPosition, setBodyEditorCursorPosition] = useState(0);

  const onChangeSubject = (value) => {
    setSubject(value);
    setDirty(true);
  };
  const onChangeMessage = (value) => {
    setMessage(value);
    setDirty(true);
  };
  const onChangeSendCopy = (field, value) => {
    setSendCopy(value);
    setDirty(true);
  };
  const onClickSave = async () => {
    try {
      setSaving(true);
      await onSaveSettings({
        depositRequestEmailSubject: subject,
        depositRequestEmailBody: message,
        sendDepositRequestEmailCopyToUser: sendCopy,
      });
      setDirty(false);
      messageDisplay.success('Deposit Request settings saved successfully');
    } catch (err) {
      log.error(err);
      messageDisplay.error('Deposit Request settings failed');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className={Styles.depositRequestEmailSettings}>
      <div className="form-group">
        <label>Email Subject</label>
        <input
          className="form-control"
          onChange={(e) => onChangeSubject(e.target.value)}
          value={subject}
          ref={emailSubjectRef}
        />
        <TemplateMacroSelector
          selectedMacroName={subjectSelectedMacro}
          macros={depositRequestEmailMacros.subjectMacros}
          onMacroChanged={setSubjectSelectedMacro}
          onMacroInserted={(macroName) => {
            if (!emailSubjectRef) return;
            const selectionStart = emailSubjectRef.current.selectionStart;
            const selectionEnd = emailSubjectRef.current.selectionEnd;
            // insert the macroName
            const value = subject;
            const newValue =
              value.substring(0, selectionStart) + macroName + value.substring(selectionEnd, value.length);
            onChangeSubject(newValue);
          }}
        />
      </div>
      <div className="form-group">
        <label>Email Body</label>
        <TextEditor
          className="deposit-request-email-template-body-editor"
          onChange={onChangeMessage}
          value={message}
          onBlur={(range) => {
            setBodyEditorCursorPosition(range.index);
          }}
          // eslint-disable-next-line no-return-assign
          reactQuillRef={(rqRef) => (emailBodyReactQuillRef = rqRef)}
        />
        <TemplateMacroSelector
          selectedMacroName={bodySelectedMacro}
          macros={depositRequestEmailMacros.bodyMacros}
          onMacroChanged={setBodySelectedMacro}
          onMacroInserted={(macroName) => {
            if (!emailBodyReactQuillRef || typeof emailBodyReactQuillRef.getEditor !== 'function') return;

            const quill = emailBodyReactQuillRef.getEditor();
            // If quill didn't have focus/selection immediately before this function is triggered, quill.getSelection() returns null
            const cursorPosition = quill.getSelection() ? quill.getSelection().index : bodyEditorCursorPosition || 0;
            quill.insertText(cursorPosition, macroName);
            setBodyEditorCursorPosition(cursorPosition + macroName.length);
          }}
        />
      </div>
      <div className={classnames('form-group', Styles.bccField)}>
        <label className={Styles.sendCopyLabel} htmlFor="sendCopySlider">
          <SlidingToggle
            name="sendCopySlider"
            onChange={onChangeSendCopy}
            scope="deposit-request-email"
            selected={sendCopy}
          />
          <div className={Styles.labelText}>Send a copy of the email to the user</div>
        </label>
      </div>
      <div className="form-group">
        <Button onClick={onClickSave} disabled={saving || !dirty}>
          {saving && <Spinner small />} Save
        </Button>
      </div>
    </div>
  );
});

DepositRequestEmailSettings.displayName = 'DepositRequestEmailSettings';

DepositRequestEmailSettings.propTypes = {
  settings: PropTypes.any,
  onSaveSettings: PropTypes.func.isRequired,
  depositRequestEmailMacros: PropTypes.shape({
    subjectMacros: PropTypes.array.isRequired,
    bodyMacros: PropTypes.array.isRequired,
  }).isRequired,
};

DepositRequestEmailSettings.defaultProps = {
  settings: undefined,
};

export default DepositRequestEmailSettings;
