import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from './CheckboxListPopup.module.scss';

const CheckboxListPopup = memo(({ list, onOptionSelected, onBlur }) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  });

  const onBlurHandler = (event) => {
    let clickedInside = event.target && list.some((item) => item.id === event.target.id);
    clickedInside = clickedInside || (event.relatedTarget && list.some((item) => item.id === event.relatedTarget.id));
    if (clickedInside) {
      return;
    }
    onBlur(event);
  };

  const onChangeHandler = (event) => {
    const { id, checked } = event.target;
    onOptionSelected(id, checked);
  };

  const onClickHandler = (option) => {
    const { id, selected, disabled } = option;
    if (!disabled) {
      onOptionSelected(id, !selected);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={Styles.dropDownCheckboxes} ref={inputRef} tabIndex={0} onBlur={onBlurHandler}>
      <ul>
        {list.map((option, index) => (
          <div
            key={index}
            onClick={() => {
              onClickHandler(option);
            }}
            className={Styles.dropDownCheckboxOption}
          >
            <label>{option.label}</label>
            <input
              id={option.id}
              type="checkbox"
              checked={option.selected}
              disabled={option.disabled}
              onChange={onChangeHandler}
            />
          </div>
        ))}
      </ul>
    </div>
  );
});

CheckboxListPopup.displayName = 'CheckboxListPopup';

CheckboxListPopup.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onOptionSelected: PropTypes.func,
  onBlur: PropTypes.func,
};

CheckboxListPopup.defaultProps = {
  onOptionSelected: () => {},
  onBlur: () => {},
};

export default CheckboxListPopup;
