import { withReduxStore } from '@sb-itops/react';
import { connect } from 'react-redux';
import { getLogger } from '@sb-itops/fe-logger';
import * as forms from '@sb-itops/redux/forms2';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { featureActive } from '@sb-itops/feature';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getAllowedIntervals, getFeeInterval } from '@sb-billing/redux/fee-configuration';
import { setFeeInterval } from '@sb-billing/redux/fee-configuration/set-fee-interval';

import { editBillingUnitFormSchema } from './edit-billing-unit-form-schema';
import { EditBillingUnitForm } from './EditBillingUnitForm';

const log = getLogger('EditBillingUnitFormContainer');
const scope = 'billing-unit-setting';
const customBillingUnitOption = { value: 'Custom', label: 'Custom' };

const mapStateToProps = (state) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const {
    formInitialised,
    fields: formFields,
    formDirty,
    formSubmitting,
    formValid,
  } = formSelectors.getFormState(state);
  const { selectedBillingUnit, customUnit } = formFields;
  const allowedIntervals = getAllowedIntervals();

  return {
    billingUnitOptions: getBillingUnitOptions(allowedIntervals),
    customUnit,
    formDirty,
    formInitialised,
    formSubmitting,
    formValid,
    selectedBillingUnit,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const currentBillingUnit = getFeeInterval();
      const allowedIntervals = getAllowedIntervals();
      const isBillingUnitCustomised = !allowedIntervals.includes(currentBillingUnit);

      const fieldValues = {
        selectedBillingUnit: isBillingUnitCustomised ? 'Custom' : currentBillingUnit,
        customUnit: isBillingUnitCustomised ? currentBillingUnit : null,
      };

      dispatch(formActions.initialiseForm({ fieldValues }));
      dispatch(formOperations.validateSchema({ schema: editBillingUnitFormSchema }));
      const onUnload = () => dispatch(formActions.clearForm());

      return onUnload;
    },

    onFieldValueUpdated: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: editBillingUnitFormSchema }));
    },

    onSave: async ({ selectedBillingUnit, customUnit }) => {
      await dispatch(
        formOperations.submitFormP({
          submitFnP: () => {
            const feeInterval = selectedBillingUnit === 'Custom' ? customUnit : selectedBillingUnit;

            setFeeInterval(feeInterval)
              .then(() => {
                displaySuccessToUser(`Billing units set to ${feeInterval} ${pluraliseMinutes(feeInterval)}.`);
                return undefined;
              })
              .catch((err) => {
                displayErrorToUser('There was a problem saving your new billing units. Please try again.');
                log.error('Failed to save billing unit', err);
              });
          },
        }),
      );
    },
  };
};

const pluraliseMinutes = (mins) => `minute${mins === 1 ? '' : 's'}`;

const getBillingUnitOptions = (allowedIntervals) => {
  const customBillingUnitsEnabled = featureActive('BB-10195');
  const billingUnitOptions = allowedIntervals.map((mins) => {
    const option = { value: mins, label: `${mins} ${pluraliseMinutes(mins)}` };
    return option;
  });

  // Show Custom as the last in the select box list when feature enabled
  if (customBillingUnitsEnabled) {
    billingUnitOptions.push(customBillingUnitOption);
  }

  return billingUnitOptions;
};

export const EditBillingUnitFormContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(EditBillingUnitForm)),
);

EditBillingUnitFormContainer.displayName = `${EditBillingUnitForm.displayName}Container`;
EditBillingUnitFormContainer.propTypes = {};
EditBillingUnitFormContainer.defaultProps = {};
