import React from 'react';
import { store } from '@sb-itops/redux';
import { Provider } from 'react-redux';

export const withReduxProvider = (WrappedComponent) => (props) =>
  (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  );
