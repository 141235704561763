'use strict';

// Only files from interface should be exported here. Common files are used across the interface implementations and should be kept private.
const { getAccountConfiguration } = require('./interface/get-account-configuration');
const { getChargeDescription } = require('./interface/get-charge-description');
const { getPaymentFormConfiguration } = require('./interface/get-payment-form-configuration');
const { isPaymentProviderEnabledForBankAccount } = require('./interface/is-payment-provider-enabled-for-bank-account');
const { getPaymentSource } = require('./interface/get-payment-source');
const { extractFeeSchedule } = require('./interface/extract-fee-schedule');
const { paymentMethods, paymentMethodNames } = require('./interface/payment-methods');
const { getChargeErrorMessage } = require('./interface/get-charge-error-message');
const {
  isClientCardSavingEnabledForBankAccount,
} = require('./interface/is-client-card-saving-enabled-for-bank-account');
const { isFirmCardSavingEnabledForBankAccount } = require('./interface/is-firm-card-saving-enabled-for-bank-account');
const { getMinChargeAmountInCents } = require('./interface/get-min-charge-amount-in-cents');
const { convertSettingsForGQL, convertSettingsFromGQL } = require('./interface/convert-settings-gql');

module.exports = {
  getAccountConfiguration,
  getChargeDescription,
  getPaymentFormConfiguration,
  isPaymentProviderEnabledForBankAccount,
  getPaymentSource,
  extractFeeSchedule,
  paymentMethods,
  paymentMethodNames,
  getChargeErrorMessage,
  isClientCardSavingEnabledForBankAccount,
  isFirmCardSavingEnabledForBankAccount,
  getMinChargeAmountInCents,
  convertSettingsForGQL,
  convertSettingsFromGQL,
};
