import { regionType } from '@sb-itops/region';

export const autofillTestData = ({
  onUpdateFields,
  firmDetails,
  defaultAutofillTrustAccount,
  setBeneficialOwnerDifferent,
  setConfirmationDateTime,
  validateForm,
  region,
}) => {
  // FeeWise now validate on email addresses that have already been used
  const generateRandom12DigitNumber = () => {
    const min = 10 ** 11; // smallest 12-digit number
    const max = 10 ** 12 - 1; // largest 12-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const usTestData = {
    firmDetails: {
      legalCompanyName: firmDetails?.legalCompanyName?.value || 'John Smith Law LLC',
      tradingAs: firmDetails?.legalCompanyName?.value || 'John Smith Law LLC',
      phoneNumber: firmDetails?.phoneNumber?.value || '(323) 467-7124',
      businessStructure: 'PrivateCorporation',
      employerIdNumber: '00-0000000',
      emailAddress: `support${generateRandom12DigitNumber()}@smokeball.com`,
      website: 'https://smokeball.com/',
      streetAddressLine1: firmDetails?.streetAddressLine1?.value || '5969 Melrose Ave',
      streetAddressLine2: firmDetails?.streetAddressLine2?.value || '',
      city: firmDetails?.city?.value || 'Los Angeles',
      state: firmDetails?.state?.value || 'CA',
      zipCode: firmDetails?.zipCode?.value || '90038',
    },
    operatingAccountDetails: {
      accountHolderName: 'John Smith',
      accountName: 'John Smith Office',
      routingNumber: '110000000',
      accountNumber: '000123456789',
      accountType: 'Company',
      nominateForMonthlyBilling: true,
    },
    trustAccountDetails: {
      accountHolderName: defaultAutofillTrustAccount?.accountName || 'John Smith',
      accountName: defaultAutofillTrustAccount?.displayName || 'John Smith Trust',
      routingNumber: '110000000',
      accountNumber: '000111111116',
      accountType: 'Company',
    },
    authorizedRepresentative: {
      firstName: 'John',
      lastName: 'Smith',
      jobTitle: 'CEO',
      phoneNumber: '(323) 467-7125',
      // We should not prefill this email as this email may be used
      // to send email to create Admin user so it should be real email
      // emailAddress: `john.smith${generateRandom12DigitNumber()}@smokeball.com`,
      streetAddressLine1: '5969 Melrose Ave',
      streetAddressLine2: '',
      city: 'Los Angeles',
      state: 'CA',
      zipCode: '90038',
      dateOfBirth: new Date('04/27/1985'),
      last4SocialSecurity: '6789',
      socialSecurityNumber: '000000000',
      businessOwnership: 18,
    },
    beneficialOwner: {
      firstName: 'Owner',
      lastName: 'Man',
      jobTitle: 'Founder',
      phoneNumber: '(323) 467-7126',
      // We should not prefill this email as this email may be used
      // to send email to create Admin user so it should be real email
      // emailAddress: `owner${generateRandom12DigitNumber()}@ownerman.com`,
      streetAddressLine1: '5969 Melrose Ave',
      streetAddressLine2: '',
      city: 'Los Angeles',
      state: 'CA',
      zipCode: '90038',
      dateOfBirth: new Date('01/02/1992'),
      last4SocialSecurity: '6789',
      socialSecurityNumber: '000000000',
      businessOwnership: 82,
    },
    acknowledgementCheckbox: {
      confirmation: true,
    },
  };

  const auTestData = {
    firmDetails: {
      legalCompanyName: firmDetails?.legalCompanyName?.value || 'John Smith Law LLC',
      tradingAs: firmDetails?.legalCompanyName?.value || 'John Smith Law LLC',
      phoneNumber: firmDetails?.phoneNumber?.value || '(08) 4858 6322',
      businessStructure: 'PrivateCorporation',
      employerIdNumber: '000000000', // ACN
      emailAddress: `support${generateRandom12DigitNumber()}@smokeball.com`,
      website: 'https://smokeball.com/',
      streetAddressLine1: firmDetails?.streetAddressLine1?.value || '183 Clarence Street',
      streetAddressLine2: firmDetails?.streetAddressLine2?.value || '',
      city: firmDetails?.city?.value || 'Sydney',
      state: firmDetails?.state?.value || 'NSW',
      zipCode: firmDetails?.zipCode?.value || '2000',
    },
    operatingAccountDetails: {
      accountHolderName: 'John Smith',
      accountName: 'John Smith Office',
      routingNumber: '110000',
      accountNumber: '000123456',
      accountType: 'Company',
      nominateForMonthlyBilling: true,
    },
    trustAccountDetails: {
      accountHolderName: defaultAutofillTrustAccount?.accountName || 'John Smith',
      accountName: defaultAutofillTrustAccount?.displayName || 'John Smith Trust',
      routingNumber: '110000',
      accountNumber: '111111116',
      accountType: 'Company',
    },
    authorizedRepresentative: {
      firstName: 'John',
      lastName: 'Smith',
      jobTitle: 'CEO',
      phoneNumber: '+61 428 250 973',
      // We should not prefill this email as this email may be used
      // to send email to create Admin user so it should be real email
      // emailAddress: `john.smith${generateRandom12DigitNumber()}@smokeball.com`,
      streetAddressLine1: '183 Clarence Street',
      streetAddressLine2: '',
      city: 'Sydney',
      state: 'NSW',
      zipCode: '2000',
      dateOfBirth: new Date('04/27/1985'),
      last4SocialSecurity: '',
      socialSecurityNumber: '00000000000', // Driver's license/TFN
      businessOwnership: 18,
    },
    beneficialOwner: {
      firstName: 'Owner',
      lastName: 'Man',
      jobTitle: 'Founder',
      phoneNumber: '+61 428 250 973',
      // We should not prefill this email as this email may be used
      // to send email to create Admin user so it should be real email
      // emailAddress: `owner${generateRandom12DigitNumber()}@ownerman.com`,
      streetAddressLine1: '183 Clarence Street',
      streetAddressLine2: '',
      city: 'Sydney',
      state: 'NSW',
      zipCode: '2000',
      dateOfBirth: new Date('01/02/1992'),
      last4SocialSecurity: '',
      socialSecurityNumber: '00000000000',
      businessOwnership: 82,
    },
    acknowledgementCheckbox: {
      confirmation: true,
    },
  };

  const gbTestData = {
    firmDetails: {
      legalCompanyName: firmDetails?.legalCompanyName?.value || 'John Smith Law LLC',
      tradingAs: firmDetails?.legalCompanyName?.value || 'John Smith Law LLC',
      phoneNumber: firmDetails?.phoneNumber?.value || '07919833651',
      businessStructure: 'PrivateCorporation',
      employerIdNumber: '00000000', // CRN
      emailAddress: `support${generateRandom12DigitNumber()}@smokeball.com`,
      website: 'https://smokeball.com/',
      streetAddressLine1: firmDetails?.streetAddressLine1?.value || '23 Old Gloucester Street',
      streetAddressLine2: firmDetails?.streetAddressLine2?.value || '',
      city: firmDetails?.city?.value || 'London',
      state: firmDetails?.state?.value || 'London',
      zipCode: firmDetails?.zipCode?.value || 'NW1 4NP',
    },
    operatingAccountDetails: {
      accountHolderName: 'John Smith',
      accountName: 'John Smith Office',
      routingNumber: '108800',
      accountNumber: '00012345',
      accountType: 'Company',
      nominateForMonthlyBilling: true,
    },
    trustAccountDetails: {
      accountHolderName: defaultAutofillTrustAccount?.accountName || 'John Smith',
      accountName: defaultAutofillTrustAccount?.displayName || 'John Smith Trust',
      routingNumber: '108800',
      accountNumber: '22222227',
      accountType: 'Company',
    },
    authorizedRepresentative: {
      firstName: 'John',
      lastName: 'Smith',
      jobTitle: 'CEO',
      phoneNumber: '+44 1632 960357',
      // We should not prefill this email as this email may be used
      // to send email to create Admin user so it should be real email
      // emailAddress: `john.smith${generateRandom12DigitNumber()}@smokeball.com`,
      streetAddressLine1: '23 Old Gloucester Street',
      streetAddressLine2: '',
      city: 'London',
      state: 'London',
      zipCode: 'NW1 4NP',
      dateOfBirth: new Date('04/27/1985'),
      last4SocialSecurity: '',
      socialSecurityNumber: 'AA000000A', // National Insurance Number
      businessOwnership: 18,
    },
    beneficialOwner: {
      firstName: 'Owner',
      lastName: 'Man',
      jobTitle: 'Founder',
      phoneNumber: '+44 1632 960357',
      // We should not prefill this email as this email may be used
      // to send email to create Admin user so it should be real email
      // emailAddress: `owner${generateRandom12DigitNumber()}@ownerman.com`,
      streetAddressLine1: '23 Old Gloucester Street',
      streetAddressLine2: '',
      city: 'London',
      state: 'London',
      zipCode: 'NW1 4NP',
      dateOfBirth: new Date('01/02/1992'),
      last4SocialSecurity: '',
      socialSecurityNumber: 'AA000000A', // National Insurance Number
      businessOwnership: 82,
    },
    acknowledgementCheckbox: {
      confirmation: true,
    },
  };

  const getTestDataStrategy = () => {
    const testDataStrategy = {
      [regionType.US]: usTestData,
      [regionType.AU]: auTestData,
      [regionType.GB]: gbTestData,
    };
    return testDataStrategy[region];
  };

  const localisedTestData = getTestDataStrategy();

  setBeneficialOwnerDifferent(true);
  setConfirmationDateTime(moment().format());
  onUpdateFields(localisedTestData);
  validateForm();
};
