import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import { getProductTier, hasBillingAccess, getOwningCompany } from 'web/services/user-session-management';
import { selectors as commSelectors } from 'web/redux/features/communicate';
import { selectors as appSelectors, actions as appActions } from 'web/redux/features/application-state';
import { isNewTheme } from 'web/services/theme';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { featureActive } from '@sb-itops/feature';
import { useCacheQuery } from 'web/hooks';
import { InitFirmUser, InitStaffSettings } from 'web/graphql/queries';
import { resourceIds } from '@sb-itops/business-logic/authorisation';

import { BillingNav } from './BillingNav';
import { BillingNav2 } from './BillingNav2';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hooks = (props: {
  onGotoOrReset: (path: string) => void;
  navHighlights?: { [key: string]: boolean };
  onClickLink: (options: any) => void;
}) => ({
  useTheme: () => ({
    isNewTheme: isNewTheme(),
  }),
  useTrustAccounts: () => {
    const trustAccounts = useSelector<{}, any[]>(getActiveTrustAccounts);

    return {
      trustAccounts,
    };
  },
  useCommunicate: () => {
    const countState = useSelector<{}, { notified: number | null; polled: number | null }>(
      commSelectors.getUnreadConversationCounts,
    );

    let count: number | string = countState?.notified ?? countState?.polled ?? 0;
    if (count > 9) {
      count = '9+';
    }
    return {
      commUnreads: count,
    };
  },
  useBranding: () => {
    const isTriConveyBranded = getOwningCompany() === 'TriConvey';
    return { isTriConveyBranded };
  },
  useVersion: () => {
    // eslint-disable-next-line no-undef
    const buildVersion = SB_BUILD_VERSION; // global
    const serverVersion: string = useSelector(appSelectors.getServerVersion);

    return {
      buildVersion,
      serverVersion,
    };
  },
  useSavedMenuExpansion: () => {
    let isMainMenuExpanded: boolean | undefined = useSelector(appSelectors.getIsMainMenuExpanded);
    const productTierId: string = useSelector(getProductTier);

    if (isMainMenuExpanded === undefined) {
      isMainMenuExpanded = productTierId === productTierIdsByName.BILL || productTierId === productTierIdsByName.BOOST;
    }

    const dispatch = useDispatch();
    const toggleMainMenuExpanded: () => undefined = () =>
      dispatch(appActions.setIsMainMenuExpanded({ isMainMenuExpanded: !isMainMenuExpanded }));

    return {
      isMainMenuExpanded,
      toggleMainMenuExpanded,
    };
  },
  usePermissions: () => {
    const { data } = useCacheQuery(InitFirmUser.query);
    const loggedInUserPermissions: any[] = data?.loggedInUser?.permissions;

    const currentUserPermissions = useMemo(() => {
      const permissionsByResourceId = (loggedInUserPermissions || []).reduce((acc, permission) => {
        acc[permission.resourceId] = permission.isAuthorized;
        return acc;
      }, {});
      return permissionsByResourceId;
    }, [loggedInUserPermissions]);

    return {
      showGlobalAccounts: !featureActive('BB-13941') || currentUserPermissions[resourceIds.BILLING_DATA_FIRM_ACCOUNTS],
      showGlobalReports: !featureActive('BB-13941') || currentUserPermissions[resourceIds.BILLING_DATA_FIRM_REPORTS],
      hasBillingAccess: hasBillingAccess(),
    };
  },
  useStaffName: () => {
    const { data } = useCacheQuery(InitStaffSettings.query);

    return {
      loggedInStaffName: data?.loggedInStaff?.name,
    };
  },
});

export const BillingNavContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)((props) => {
      if (props.isNewTheme) {
        return <BillingNav2 {...props} />;
      }

      return <BillingNav {...props} />;
    }),
  ),
);
