import { gql } from '@apollo/client';

const query = gql`
  query DraftInvoicePreviewMatterData($matterId: ID!) {
    matter(id: $matterId) {
      id
      clients {
        id
      }
      matterInvoiceSettings {
        id
        addressesOverridden
        overriddenDebtorAddresses {
          debtorId
          addressLines
        }
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.MatterInvoiceSettingsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoicePreviewMatterData = {
  query,
  notificationIds,
};
