import { simpleFetch } from '@sb-itops/fetch-wrapper';
import { getDefaultHeaders } from './get-default-headers';

export const httpGet = async ({ url, headers, throwOnFailure = true }) => {
  const result = await simpleFetch({
    fetch,
    method: 'GET',
    url,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    throwOnFailure,
  });

  return result.body;
};

export const httpPost = async ({ url, headers, body, throwOnFailure = true }) =>
  simpleFetch({
    fetch,
    method: 'POST',
    url,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    body,
    throwOnFailure,
  });
