'use strict';

const applySupplementaryTablesSettingToTemplate = ({
  invoiceSettingsTemplate,
  invoiceSettingsAdditionalOptions,
  invoiceSettingsSectionOptions,
  invoiceSupplementaryTablesPageBreakEnabled,
  timekeeperRoleEnabled,
}) => {
  const updatedTemplateSettings = { ...invoiceSettingsTemplate };

  // Get settings from invoiceSettingsAdditionalOptions when
  // 1. page break feature BB-12385 is OFF, or
  // 2. sectionOptions is falsy (null) i.e. user haven't saved tried saving template settings yet, or
  // 3. sectionOptions is empty (have no settings), this can happen when .net code for SectionOptions
  //    has been deployed but page break feature BB-12385 is not yet turned ON and user tries to save a invoice template.

  // settings to show supplementary tables
  const sectionOptionsHasNoSettings = !invoiceSettingsSectionOptions || !invoiceSettingsSectionOptions.feeSummary; // use feeSummary as the test for no settings
  if (!invoiceSupplementaryTablesPageBreakEnabled || sectionOptionsHasNoSettings) {
    updatedTemplateSettings.showProfessionalFeeSummary = invoiceSettingsAdditionalOptions.showSummaryForTimekeepers;
    if (timekeeperRoleEnabled) {
      updatedTemplateSettings.showTimekeeperRole = invoiceSettingsAdditionalOptions.showSummaryForTimekeepers
        ? invoiceSettingsAdditionalOptions.showTimekeeperRole
        : false;
    }
    updatedTemplateSettings.showPriorBalances = !invoiceSettingsAdditionalOptions.hidePriorBalance;
    updatedTemplateSettings.hidePaymentSummary = !!invoiceSettingsAdditionalOptions.hidePaymentSummary;
    updatedTemplateSettings.showInvoiceSummary = invoiceSettingsAdditionalOptions.showInvoiceSummary;
    updatedTemplateSettings.showAccountSummary = invoiceSettingsAdditionalOptions.showAccountSummary;
    updatedTemplateSettings.showTxnHistoryPerAccount = invoiceSettingsAdditionalOptions.showTransactionHistory;
  } else {
    // Use the sectionOptions values
    updatedTemplateSettings.showProfessionalFeeSummary = invoiceSettingsSectionOptions.feeSummary.showSection;
    if (timekeeperRoleEnabled) {
      updatedTemplateSettings.showTimekeeperRole = invoiceSettingsSectionOptions.feeSummary.showSection
        ? invoiceSettingsSectionOptions.feeSummary.showTimekeeperRole
        : false;
    }
    updatedTemplateSettings.showPriorBalances = invoiceSettingsSectionOptions.priorBalances.showSection;
    updatedTemplateSettings.hidePaymentSummary = !invoiceSettingsSectionOptions.paymentSummary.showSection;
    updatedTemplateSettings.showInvoiceSummary = invoiceSettingsSectionOptions.invoiceSummary.showSection;
    updatedTemplateSettings.showAccountSummary = invoiceSettingsSectionOptions.accountSummary.showSection;
    updatedTemplateSettings.showTxnHistoryPerAccount = invoiceSettingsSectionOptions.transactionHistory.showSection;
  }

  // settings to show supplementary tables on new page
  if (invoiceSupplementaryTablesPageBreakEnabled) {
    if (sectionOptionsHasNoSettings) {
      updatedTemplateSettings.newPageForProfessionalFeeSummary = true;
      updatedTemplateSettings.newPageForPriorBalances = !invoiceSettingsAdditionalOptions.showSummaryForTimekeepers;
      updatedTemplateSettings.newPageForPaymentSummary = false;
      // We need to check if append a list for fee/expense is ticked or not, if one of them are ticked then newPageForInvoiceSummary is false
      const showActivityList = invoiceSettingsTemplate.appendFeeEntries || invoiceSettingsTemplate.appendExpenseEntries;
      updatedTemplateSettings.newPageForInvoiceSummary = !showActivityList;
      updatedTemplateSettings.newPageForAccountSummary = false;
      updatedTemplateSettings.newPageForTxnHistoryPerAccount = false;
    } else {
      // Use the sectionOptions values
      updatedTemplateSettings.newPageForProfessionalFeeSummary =
        invoiceSettingsSectionOptions.feeSummary.lineBreakBefore;
      updatedTemplateSettings.newPageForPriorBalances = invoiceSettingsSectionOptions.priorBalances.lineBreakBefore;
      updatedTemplateSettings.newPageForPaymentSummary = invoiceSettingsSectionOptions.paymentSummary.lineBreakBefore;
      updatedTemplateSettings.newPageForInvoiceSummary = invoiceSettingsSectionOptions.invoiceSummary.lineBreakBefore;
      updatedTemplateSettings.newPageForAccountSummary = invoiceSettingsSectionOptions.accountSummary.lineBreakBefore;
      updatedTemplateSettings.newPageForTxnHistoryPerAccount =
        invoiceSettingsSectionOptions.transactionHistory.lineBreakBefore;
    }
  }

  return updatedTemplateSettings;
};

module.exports = {
  applySupplementaryTablesSettingToTemplate,
};
