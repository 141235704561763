import { gql } from '@apollo/client';

const query = gql`
  query InitFirmFeeConfiguration {
    firmFeeConfiguration {
      billableMinutes
    }
  }
`;

const notificationIds = ['FeesNotifications.FeeConfigurationUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitFirmFeeConfiguration = {
  query,
  notificationIds,
};
