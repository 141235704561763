import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BillingFrequencyTreeSelect } from '@sb-billing/react';
import {
  CollapsibleHeader,
  CurrencyInput2,
  ToggleListFilter,
  SlidingToggle,
  PanelExpander,
  useTranslation,
} from '@sb-itops/react';
import { MatterTypeAccordian, DateListFilter, StaffMultiSelect } from 'web/react-redux';
import { featureActive } from '@sb-itops/feature';
import Styles from './InvoicesFilterPanel.module.scss';

const InvoicesFilterPanel = memo(
  ({
    invoiceListData,
    filterOptions,
    toggleHideFilters,
    isUtbmsEnabledForFirm,
    // generated callbacks
    onFilterInvoiceStatus,
    onChangeIssueDateFilter,
    onChangeFinalizedOnFilter,
    onFilterSentStatus,
    onFilterSendPreference,
    onFilterBillingEvent,
    onFilterBillingType,
    onFilterBillingFrequencySubType,
    onFilterMatterStatus,
    onFilterMatterTypes,
    onFilterByRecent,
    onFilterUtbmsEnabled,
    contactMatterBalancesTitle,
    onFilterAttorneyResponsible,
    onResetFilters,
    personResponsible,
    onFilterTrustBalance,
    onFilterOperatingBalance,
    expanded,
    toggleShowFilters,
    hideFilters,
    filters,
    compactFilters,
    showSendPreferenceFilter,
    showBillingFrequencyFilter,
    showOperatingAccountFilter,
    showUtbmsFilter,
  }) => {
    const { t } = useTranslation();

    if (!filters || !hideFilters) {
      return null;
    }

    return (
      <div className="firm-invoices-side-panel">
        <PanelExpander
          className="nav panel-filter"
          onResetFilters={onResetFilters}
          expanded={expanded}
          toggleExpanded={toggleShowFilters}
        >
          {/* <!-- Invoice Status Filter --> */}
          <CollapsibleHeader
            text="Status"
            onClick={(e) => {
              toggleHideFilters(e);
            }}
            name="invoiceStatus"
            collapsed={hideFilters?.invoiceStatus}
          />
          {!hideFilters?.invoiceStatus && (
            <>
              {featureActive('BB-8047') && (
                <div className={Styles.recentInvoicesButton}>
                  <div onClick={() => onFilterByRecent(!filters?.recent)} className={Styles.recentInvoicesLabel}>
                    Recently viewed
                  </div>
                  <SlidingToggle
                    scope="recent-invoices"
                    onChange={() => onFilterByRecent(!filters?.recent)}
                    selected={filters?.recent}
                  />
                </div>
              )}
              <ToggleListFilter
                showAllLabel="All"
                optionCounts={invoiceListData?.countByInvoiceStatus}
                options={filterOptions?.invoiceStatus}
                selected={filters?.invoiceStatuses}
                onSelect={onFilterInvoiceStatus}
              />
            </>
          )}

          {/* <!-- Issue Date Filter --> */}
          <CollapsibleHeader
            text="Issue Date"
            onClick={toggleHideFilters}
            name="issueDate"
            collapsed={hideFilters?.issueDate}
          />
          {!hideFilters?.issueDate && (
            <DateListFilter className="side-menu-list" onChange={onChangeIssueDateFilter} {...filters.issueDate} />
          )}

          {/* <!-- Sent status filter--> */}
          {!filters?.invoiceStatus?.includes('DRAFT') && (
            <div>
              <CollapsibleHeader
                text="Sent Status"
                onClick={toggleHideFilters}
                name="sentStatus"
                collapsed={hideFilters?.sentStatus}
              />
              {!hideFilters?.sentStatus && (
                <ToggleListFilter
                  showAllLabel="All"
                  options={filterOptions?.sentStatus}
                  selected={filters?.sentStatuses}
                  onSelect={onFilterSentStatus}
                />
              )}
            </div>
          )}

          {/* <!-- Send preference filter--> */}
          {featureActive('BB-11448') && showSendPreferenceFilter && filterOptions?.sendPreferences && (
            <div>
              <CollapsibleHeader
                text="Send Preference"
                onClick={toggleHideFilters}
                name="sendPreference"
                collapsed={hideFilters?.sendPreference}
              />
              {!hideFilters?.sendPreference && (
                <ToggleListFilter
                  showAllLabel="All"
                  options={filterOptions?.sendPreferences}
                  selected={filters?.sendPreferences}
                  onSelect={onFilterSendPreference}
                />
              )}
            </div>
          )}

          {/* <!-- Online invoice filter--> */}
          {featureActive('BB-5725') && (
            <div>
              <CollapsibleHeader
                text="Online Invoice"
                onClick={toggleHideFilters}
                name="billingEvents"
                collapsed={hideFilters?.billingEvents}
              />
              {!hideFilters?.billingEvents && (
                <ToggleListFilter
                  hideShowAllOption
                  options={filterOptions?.billingEventStatus}
                  selected={filters?.billingEvents}
                  onSelect={onFilterBillingEvent}
                />
              )}
            </div>
          )}

          {!compactFilters && (
            <>
              {/* <!-- Billing Type Filter--> */}
              <CollapsibleHeader
                text="Billing Type"
                onClick={toggleHideFilters}
                name="billingType"
                collapsed={hideFilters?.billingType}
              />
              {!hideFilters?.billingType && (
                <ToggleListFilter
                  showAllLabel="All"
                  options={filterOptions?.billingType}
                  selected={filters?.billingTypes?.selections}
                  onSelect={onFilterBillingType}
                />
              )}
            </>
          )}

          {/* <!-- Billing Frequency Filter--> */}
          {featureActive('BB-11704') && showBillingFrequencyFilter && (
            <>
              <CollapsibleHeader
                text="Billing Frequency"
                onClick={toggleHideFilters}
                name="billingFrequency"
                collapsed={hideFilters?.billingFrequency}
              />
              {!hideFilters?.billingFrequency && (
                <BillingFrequencyTreeSelect
                  selectedBillingFrequencySubTypes={filters?.billingFrequencySubTypes}
                  onSelect={onFilterBillingFrequencySubType}
                />
              )}
            </>
          )}

          {!compactFilters && (
            <>
              {/* <!-- Matter Type Filter--> */}
              <CollapsibleHeader
                text="Matter Type"
                onClick={toggleHideFilters}
                name="matterType"
                collapsed={hideFilters?.matterType}
              />
              {!hideFilters?.matterType && (
                <MatterTypeAccordian selectedList={filters?.matterTypes} onSelect={onFilterMatterTypes} />
              )}

              {/* <!-- Matter Status Filter--> */}
              <CollapsibleHeader
                text="Matter Status"
                onClick={toggleHideFilters}
                name="matterStatus"
                collapsed={hideFilters?.matterStatus}
              />
              {!hideFilters?.matterStatus && (
                <ToggleListFilter
                  showAllLabel="All"
                  options={filterOptions?.matterStatus}
                  selected={filters?.matterStatuses}
                  onSelect={onFilterMatterStatus}
                />
              )}

              {/* <!-- Attorney responsible filter--> */}
              <div className="attorney-resposible-container">
                <CollapsibleHeader
                  text={personResponsible}
                  onClick={toggleHideFilters}
                  name="attorneyResponsible"
                  collapsed={hideFilters?.attorneyResponsible}
                />
                {!hideFilters?.attorneyResponsible && (
                  <StaffMultiSelect
                    className=""
                    selectedStaffIds={filters?.attorneysResponsible || []}
                    onStaffChange={onFilterAttorneyResponsible}
                    showUsedStaffOnly
                    showNoneOption
                  />
                )}
              </div>

              {/* <!-- Debtors Balances Filter --> */}
              <CollapsibleHeader
                text={contactMatterBalancesTitle}
                onClick={toggleHideFilters}
                name="debtorBalances"
                collapsed={hideFilters?.debtorBalances}
              />
              {!hideFilters?.debtorBalances && (
                <div className="debtor-balances">
                  <div className="balance-type">
                    <span className="balance-type-label">At least {t('currencySymbol')} </span>
                    <div className="inline-block">
                      <CurrencyInput2
                        className="balance-value inline-block"
                        value={filters?.minimumTrustBalance}
                        hideDollar
                        onChange={(e) => onFilterTrustBalance(Number(e.target.value))}
                      />
                    </div>
                    <span className="balance-type-label"> in {t('trust')}</span>
                  </div>
                  {showOperatingAccountFilter && (
                    <div className="balance-type">
                      <span className="balance-type-label">At least {t('currencySymbol')} </span>
                      <div className="inline-block">
                        <CurrencyInput2
                          className="balance-value"
                          value={filters?.minimumOperatingBalance}
                          hideDollar
                          onChange={(e) => onFilterOperatingBalance(Number(e.target.value))}
                        />
                      </div>
                      <span className="balance-type-label"> in {t('operating')}</span>
                    </div>
                  )}
                </div>
              )}

              {/* <!-- LEDES Billing Filter --> */}
              {featureActive('BB-9243') && showUtbmsFilter && isUtbmsEnabledForFirm && (
                <>
                  <CollapsibleHeader
                    text="LEDES Billing"
                    onClick={(e) => {
                      toggleHideFilters(e);
                    }}
                    name="ledesBilling"
                    collapsed={hideFilters?.ledesBilling}
                  />
                  {!hideFilters?.ledesBilling && (
                    <div className={Styles.utbmsEnabledToggle}>
                      <div
                        onClick={() => onFilterUtbmsEnabled(!filters?.utbmsEnabled)}
                        className={Styles.utbmsEnabledLabel}
                      >
                        UTBMS Enabled
                      </div>
                      <SlidingToggle
                        scope="utbmsEnabled"
                        onChange={() => onFilterUtbmsEnabled(!filters?.utbmsEnabled)}
                        selected={filters?.utbmsEnabled}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {/* <!-- Finalized On Filter --> */}
          <div className="finalized-on">
            <CollapsibleHeader
              text={`${t('capitalize', { val: 'finalised' })} On`}
              onClick={toggleHideFilters}
              name="finalizedOn"
              collapsed={hideFilters?.finalizedOn}
            />
            {!hideFilters?.finalizedOn && (
              <DateListFilter
                className="side-menu-list"
                onChange={onChangeFinalizedOnFilter}
                showToday
                {...filters.finalizedOn}
              />
            )}
          </div>
        </PanelExpander>
      </div>
    );
  },
);

InvoicesFilterPanel.displayName = 'InvoicesFilterPanel';

InvoicesFilterPanel.propTypes = {
  filters: PropTypes.object.isRequired,
  hideFilters: PropTypes.object.isRequired,
  invoiceListData: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  toggleHideFilters: PropTypes.func.isRequired,
  isUtbmsEnabledForFirm: PropTypes.bool,
  onFilterInvoiceStatus: PropTypes.func.isRequired,
  onChangeIssueDateFilter: PropTypes.func.isRequired,
  onChangeFinalizedOnFilter: PropTypes.func.isRequired,
  onFilterSentStatus: PropTypes.func.isRequired,
  onFilterSendPreference: PropTypes.func,
  onFilterBillingEvent: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  contactMatterBalancesTitle: PropTypes.string,
  onFilterBillingType: PropTypes.func,
  onFilterBillingFrequencySubType: PropTypes.func,
  compactFilters: PropTypes.bool,
  onFilterMatterStatus: PropTypes.func,
  onFilterMatterTypes: PropTypes.func,
  onFilterTrustBalance: PropTypes.func,
  onFilterOperatingBalance: PropTypes.func,
  onFilterAttorneyResponsible: PropTypes.func,
  onFilterByRecent: PropTypes.func,
  onFilterUtbmsEnabled: PropTypes.func,
  personResponsible: PropTypes.string,
  showSendPreferenceFilter: PropTypes.bool,
  showBillingFrequencyFilter: PropTypes.bool,
  showOperatingAccountFilter: PropTypes.bool,
  showUtbmsFilter: PropTypes.bool,
};

InvoicesFilterPanel.defaultProps = {
  compactFilters: false,
  contactMatterBalancesTitle: '',
  isUtbmsEnabledForFirm: false,
  onFilterSendPreference: undefined,
  onFilterMatterStatus: undefined,
  onFilterBillingType: undefined,
  onFilterBillingFrequencySubType: undefined,
  onFilterMatterTypes: undefined,
  onFilterTrustBalance: undefined,
  onFilterOperatingBalance: undefined,
  onFilterAttorneyResponsible: undefined,
  onFilterByRecent: undefined,
  onFilterUtbmsEnabled: undefined,
  personResponsible: '',
  showSendPreferenceFilter: false,
  showBillingFrequencyFilter: false,
  showOperatingAccountFilter: false,
  showUtbmsFilter: false,
};

export default InvoicesFilterPanel;
