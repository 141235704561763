import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

const fragment = gql`
  fragment OpdateAppointment on Appointment {
    id
    accountId
    matterId
    startTime
    endTime
    originalStartTime
    originalEndTime
    subject
    status
    label
    description
    location
    allDay
    eventType
    rRULE
    reminderInfo
    resourceIds
    resourceId
    patternAppointmentId
    recurrenceInfoId
    recurrenceInfo
    lastUpdated
    isRemoved
  }
`

export const onCreateEditAppointment = ({ commandMessage }) => {
  const optimisticAppointment = {
    __typename: 'Appointment',
    // Graphql requires all the specified fields, but these are not sent by the frontend yet
    rRULE: '',
    reminderInfo: '',
    // Shouldn't really be blank, but the frontend never checks this anyway
    accountId: '',
    lastUpdated: '',
    status: 0,
    label: 0,
    eventType: 0,
    isRemoved: false,
    originalEndTime: '',
    originalStartTime: '',
    recurrenceInfoId: '',
    recurrenceInfo: '',
    ...commandMessage,
  };

  const apolloClient = getApolloClient();

  const appointmentIdentifier = apolloClient.cache.identify(optimisticAppointment);

  apolloClient.writeFragment({
    id: appointmentIdentifier,
    fragment,
    data: {
      ...optimisticAppointment,
    },
  });

  return {
    eventType: commandMessage.isNewEntity
      ? apolloEntityOpdateEventTypes.ENTITY_ADDED
      : apolloEntityOpdateEventTypes.ENTITY_UPDATED,
    typeName: 'Appointment',
    optimisticEntity: optimisticAppointment,
  };
};
