import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import uuid from '@sb-itops/uuid';
import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { findPaymentPlansByInvoiceIds } from '../payment-plans/selectors';
import { getList as getPaymentPlansList } from '../payment-plans';
import {
  buildPaymentPlanOpdates,
  opdateCache as opdatePaymentPlanCache,
  rollbackOpdateCache as rollbackPaymentPlanOpdateCache,
} from '../payment-plans/payment-plan-opdates';

const getUserId = () => authSelectors.getUserId(store.getState());

const getPaymentPlanOpdates = (invoices = []) => {
  const paymentPlanOpdates = [];
  const paymentPlansForInvoicesList = findPaymentPlansByInvoiceIds(getPaymentPlansList(), {
    invoiceIds: invoices.map((inv) => inv.invoiceId),
    status: paymentPlanStatuses.ACTIVE,
  });

  if (paymentPlansForInvoicesList && paymentPlansForInvoicesList.length > 0) {
    paymentPlansForInvoicesList.forEach((paymentPlan) => {
      paymentPlanOpdates.push(buildPaymentPlanOpdates({ ...paymentPlan }));
    });
  }
  return paymentPlanOpdates;
};

export const addBatchPayment = (payment) => {
  const batchPayment = {
    ...payment,
    userId: getUserId(),
  };

  if (payment.paymentType === 'MATTER') {
    batchPayment.paymentId = payment.paymentId || uuid();
  } else {
    // Ensure only 1 payment per matter, even if it covers more than 1 invoice
    const paymentIds = {};
    batchPayment.invoices = payment.invoices.map((invoice) => {
      if (!paymentIds[invoice.matterId]) {
        paymentIds[invoice.matterId] = uuid();
      }

      return {
        ...invoice,
        paymentId: paymentIds[invoice.matterId],
      };
    });
  }

  const opdateCaches = () => {
    const paymentPlanOpdates = getPaymentPlanOpdates(payment.invoices);
    opdatePaymentPlanCache({ optimisticEntities: paymentPlanOpdates });

    return () => {
      rollbackPaymentPlanOpdateCache({ optimisticEntities: paymentPlanOpdates });
    };
  };

  return fetchPostThunkFactory({
    path: `/billing/payment/:accountId/batch`,
    opdateCaches,
  })(batchPayment);
};
