import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './LoadingPlaceholderElement.module.scss';

// Loads shimmering boxes in place of content
export const LoadingPlaceholderElement = ({ isLine, className, style }) => (
  <div
    style={style}
    className={classNames(isLine && Styles.placeholderContentTitleLine, Styles.placeholderContent, className)}
  >
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
    <div className={Styles.placeholderContentItem} />
  </div>
);

LoadingPlaceholderElement.displayName = 'LoadingPlaceholderElement';

LoadingPlaceholderElement.propTypes = {
  isLine: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
LoadingPlaceholderElement.defaultProps = {
  isLine: undefined,
  className: undefined,
  style: undefined,
};
