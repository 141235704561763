import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from './ConnectedStatus.module.scss';

export const ConnectedStatus = ({ organisationName }) => (
  <div className={Styles.connectedStatusContainer}>
    <i className={classNames('fa fa-check', Styles.connectedIcon)} />
    <p className={Styles.connectionEstablishedMessage}>{`Connection established${
      organisationName && organisationName.length > 0 ? ':' : ''
    }`}</p>
    <p>{organisationName}</p>
  </div>
);

ConnectedStatus.propTypes = {
  organisationName: PropTypes.string,
};

ConnectedStatus.defaultProps = {
  organisationName: undefined,
};

ConnectedStatus.displayName = 'ConnectedStatus';
