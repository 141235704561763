import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onSaveBillingUserAttributes = ({ commandMessage, commandResult }) => {
  const { userBillingAttributesId } = commandResult;

  const optimisticBillingUserAttributes = {
    __typename: 'UserBillingAttributes',
    id: userBillingAttributesId,
    viewedMessages: [commandMessage.viewedMessage],
  };

  const apolloClient = getApolloClient();
  const userBillingAttributesIdentifier = apolloClient.cache.identify(optimisticBillingUserAttributes);

  const existingUserBillingAttributes = apolloClient.readFragment({
    id: userBillingAttributesIdentifier,
    fragment: gql`
      fragment ExistingUserBillingAttributes on UserBillingAttributes {
        viewedMessages
      }
    `,
  });

  if (existingUserBillingAttributes && existingUserBillingAttributes.viewedMessages) {
    optimisticBillingUserAttributes.viewedMessages = [
      ...existingUserBillingAttributes.viewedMessages,
      commandMessage.viewedMessage,
    ];
  }

  apolloClient.writeFragment({
    id: userBillingAttributesIdentifier,
    fragment: gql`
      fragment OpdateUserBillingAttributes on UserBillingAttributes {
        ${Object.keys(optimisticBillingUserAttributes).join('\n')}
      }
    `,
    data: {
      ...optimisticBillingUserAttributes,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_UPDATED,
    typeName: 'UserBillingAttributes',
    optimisticEntity: optimisticBillingUserAttributes,
  };
};
