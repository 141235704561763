/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spinner, Checkbox, useTranslation } from '@sb-itops/react';
import { MatterDisplay } from '@sb-matter-management/react';
import React from 'react';
import { AddTaskModalContainer } from 'web/containers';
import { getStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import classNames from 'classnames';
import moment from 'moment';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './TasksWidget.module.scss';

export const TasksWidget = ({
  matterId,
  isEditing,
  tasks,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  onCompleteTask,
  showTaskModal,
  setShowTaskModal,
}) => {
  const { t } = useTranslation();

  const getOverdueDisplay = (d) => {
    const diff = moment().startOf('day').diff(moment(d), 'days');

    if (diff === 0) {
      return <div className={WidgetStyles.bold}>Today</div>;
    }
    if (diff === 1) {
      return <div className={Styles.overdue}>Yesterday</div>;
    }
    if (diff === -1) {
      return <div>Tomorrow</div>;
    }
    if (diff > 1) {
      return <div className={Styles.overdue}>{moment().startOf('day').diff(moment(d), 'days')} days overdue</div>;
    }
    if (diff < -1) {
      return <div>{t('date', { ts: d })}</div>;
    }
    return null;
  };

  return (
    <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
      <WidgetTitle
        isEditing={isEditing}
        onChange={onChange}
        onRemove={onRemove}
        settings={settings}
        onClick={() => {
          if (matterId) {
            onClickLink({ type: 'matterTasks', id: matterId });
          } else {
            onClickLink({ type: 'productivityTasks' });
          }
        }}
      />
      {loading ? (
        <div className={WidgetStyles.loading}>
          <Spinner small />
        </div>
      ) : (
        <div className={WidgetStyles.content}>
          {tasks.length === 0 && (matterId ? 'No incomplete tasks' : 'No overdue tasks')}
          {tasks.map((task) => (
            <div
              key={task.id}
              className={Styles.task}
              onClick={() => {
                setShowTaskModal(task);
              }}
            >
              <Checkbox onChange={() => onCompleteTask(task)} className={Styles.checkbox} />
              <div className={WidgetStyles.textBox}>
                <div className={classNames(WidgetStyles.overflowText, WidgetStyles.bold)}>{task.subject}</div>
                <div className={WidgetStyles.overflowText}>{task.note}</div>
                {!matterId && <MatterDisplay onClickLink={onClickLink} asLink matterId={task.matterId} />}
              </div>
              <div className={classNames(WidgetStyles.overflowText, WidgetStyles.alignRight, WidgetStyles.noShrink)}>
                {getOverdueDisplay(task.dueDate)}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={WidgetStyles.separator} />
      <a className={WidgetStyles.footerAction} onClick={() => setShowTaskModal({})}>
        ADD TASK
      </a>
      {showTaskModal && (
        <AddTaskModalContainer
          matterId={matterId}
          scope="widgets.task"
          task={showTaskModal}
          completer={showTaskModal.completerId && getStaffByPersonId(showTaskModal.completerId)}
          onClose={() => setShowTaskModal(false)}
        />
      )}
    </div>
  );
};
