import React, { useState } from 'react';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { SavedCardChargeForm } from './SavedCardChargeForm';

export const SavedCardChargeFormContainer = withReduxStore(({ triggerSubmit, ...props }) => {
  const [triggerSubmission, setTriggerSubmission] = useState(triggerSubmit);
  // We will be triggering a submission on this render.
  if (!triggerSubmission && triggerSubmit) {
    setTriggerSubmission(true);
  }

  // It's ok to submit again.
  if (triggerSubmission && !triggerSubmit) {
    setTriggerSubmission(false);
  }

  return <SavedCardChargeForm {...props} triggerSubmit={triggerSubmit} />;
});

SavedCardChargeFormContainer.displayName = 'SavedCardChargeFormContainer';

SavedCardChargeFormContainer.propTypes = {
  providerType: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  savedCard: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
};

SavedCardChargeFormContainer.defaultProps = {};
