import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import { TilesArea, Tile } from '@sb-itops/react/tiles';
import PaymentPlanInformationBox from './PaymentPlanInformationBox';

const getPaymentPlanInformationBoxesText = ({ days, amount, t }) => {
  if (Number.isFinite(days)) {
    if (days < 0) {
      return 'n/a';
    }
    return days.toString();
  }
  if (Number.isFinite(amount)) {
    return t('cents', { val: amount });
  }
  return undefined;
};

const PaymentPlanInformationBoxes = memo(({ paymentPlanInformationList }) => {
  const { t } = useTranslation();
  return (
    <TilesArea>
      {paymentPlanInformationList.map(
        ({ amount, days, description, showWarning, showError, tooltip, id, showInfo }) => (
          <Tile key={id}>
            <PaymentPlanInformationBox
              text={getPaymentPlanInformationBoxesText({ amount, days, t })}
              description={description}
              showWarning={showWarning}
              showError={showError}
              showInfo={showInfo}
              tooltip={tooltip}
            />
          </Tile>
        ),
      )}
    </TilesArea>
  );
});

PaymentPlanInformationBoxes.propTypes = {
  paymentPlanInformationList: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      days: PropTypes.number,
      description: PropTypes.string,
      tooltip: PropTypes.string,
      showWarning: PropTypes.bool,
      showError: PropTypes.bool,
      showInfo: PropTypes.bool,
      id: PropTypes.string.isRequired,
    }),
  ),
};

PaymentPlanInformationBoxes.defaultProps = {
  paymentPlanInformationList: [],
};

export default PaymentPlanInformationBoxes;
