import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import { AddBankFeeInterestForm } from '../bank-fee-interest-add-form';
import Styles from './AddBankFeeInterestFormModal.module.scss';

export const AddBankFeeInterestFormModal = React.memo(
  ({
    onProcess,
    onCloseModal,
    isSubmitDisabled,
    isVisible,
    scope,
    matterId,
    isFee,
    bankAccountId,
    allowFutureDate,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.addFeeInterestModal}
        isVisible={isVisible}
        title={isFee ? 'Bank Fees' : 'Interest Accrued'}
        body={
          <AddBankFeeInterestForm
            scope={scope}
            matterId={matterId}
            isFee={isFee}
            bankAccountId={bankAccountId}
            allowFutureDate={allowFutureDate}
          />
        }
        footer={
          <div className={Styles.buttonContainer}>
            <Button
              className={Styles.processButton}
              onClick={onProcess}
              disabled={isSubmitDisabled}
              form={scope}
              locked={isSubmitDisabled}
              size="full-width"
            >
              Process
            </Button>
          </div>
        }
        onModalClose={onCloseModal}
      />
    );
  },
);

AddBankFeeInterestFormModal.displayName = 'AddBankFeeInterestFormModal';

AddBankFeeInterestFormModal.propTypes = {
  scope: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isFee: PropTypes.bool,
  allowFutureDate: PropTypes.bool,
};

AddBankFeeInterestFormModal.defaultProps = {
  matterId: undefined,
  isSubmitDisabled: false,
  isFee: false,
  allowFutureDate: false,
};
