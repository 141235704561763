'use strict';

const { paymentMethodAccountSuffixes } = require('./payment-methods');

/**
 * getAccountConfiguration
 * Extracts a LawPay account configuration for a specific bankAccountType and paymentMethod combination.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings Formatted Lawpay settings as configured in firm settings.
 * @param {string} params.bankAccountType Along with paymentMethod, forms the id of the configuration to extract.
 * @param {string} params.paymentMethod Along with bankAccountType, forms the id of the configuration to extract.
 * @param {string} [params.targetBankAccountId]
 *
 * @returns {{ id: string, name: string }|undefined} The account configuration if found, otherwise undefined.
 */
function getAccountConfiguration({
  formattedProviderSpecificSettings,
  bankAccountType,
  paymentMethod,
  targetBankAccountId,
}) {
  validateParameters({ formattedProviderSpecificSettings, bankAccountType, paymentMethod });
  if (formattedProviderSpecificSettings.lawpayBankAccounts) {
    return findLawpayAccountMTA({ targetBankAccountId, formattedProviderSpecificSettings, paymentMethod });
  }

  const accountSuffix = paymentMethodAccountSuffixes[paymentMethod];
  const accountConfiguration =
    formattedProviderSpecificSettings[`${bankAccountType.toLowerCase()}BankAccount${accountSuffix}`];
  return accountConfiguration;
}

//
function findLawpayAccountMTA({ targetBankAccountId, formattedProviderSpecificSettings, paymentMethod }) {
  let accountMapping;
  const { cardBankAccountMappings, eCheckBankAccountMappings, lawpayBankAccounts } = formattedProviderSpecificSettings;

  if (paymentMethod === 'CREDIT_CARD') {
    accountMapping = cardBankAccountMappings.find((m) => m.sbbBankAccountId === targetBankAccountId);
  }
  if (paymentMethod === 'ECHEQUE') {
    accountMapping = eCheckBankAccountMappings.find((m) => m.sbbBankAccountId === targetBankAccountId);
  }

  const lawpayAccount = lawpayBankAccounts.find((acct) => acct.id === accountMapping.lawpayBankAccountId);

  return lawpayAccount ? { id: lawpayAccount.id, name: lawpayAccount.name } : undefined;
}

function validateParameters({ formattedProviderSpecificSettings, bankAccountType, paymentMethod }) {
  if (!formattedProviderSpecificSettings) {
    throw new Error('Invalid formattedProviderSpecificSettings provided');
  }

  if (!bankAccountType) {
    throw new Error('Invalid bankAccountType provided');
  }

  if (!paymentMethod) {
    throw new Error('Invlaid paymentMethod provided');
  }
}

module.exports = {
  getAccountConfiguration,
};
