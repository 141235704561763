'use strict';

const { ALL_STATES } = require('@sb-itops/region');

/**
 * Get Bank Accounts By Location
 *
 * @param {Array.<BankAccount>} bankAccounts
 * @param {string} locationId state string, e.g. VIC, OH
 * @param {boolean} includeAllStates whether to include trust accounts with location set to ALL_STATES
 * @returns {Array.<BankAccount>} Bank account with requested locationId
 */
function getBankAccountsByLocation(bankAccounts, locationId, includeAllStates = true) {
  if (!bankAccounts || !locationId) {
    return [];
  }

  const upperCaseLocationId = locationId.toUpperCase();

  const selectedAccounts = bankAccounts.filter(
    (trust) =>
      (trust.location && trust.location.toUpperCase() === upperCaseLocationId) ||
      upperCaseLocationId === 'GENERIC' ||
      upperCaseLocationId === 'FED' ||
      (includeAllStates ? trust.location && trust.location.toUpperCase() === ALL_STATES.value : false),
  );
  return selectedAccounts;
}

module.exports = {
  getBankAccountsByLocation,
};
