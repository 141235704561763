import PropTypes from 'prop-types';
import { withLinkHandler } from '@sb-itops/react/hoc';
import InvoiceTable from './InvoiceTable';

const Container = withLinkHandler('invoice')(withLinkHandler('invoiceProofOfPayment')(InvoiceTable));

Container.propTypes = {
  invoices: PropTypes.array,
  invoiceStatuses: PropTypes.array,
  displayMode: PropTypes.string,
  sort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  summary: PropTypes.object,
  sendEmail: PropTypes.func,
  onSelectInvoice: PropTypes.func,
  onSelectAllInvoices: PropTypes.func,
  selectedInvoices: PropTypes.object,
  onClickLink: PropTypes.func,
  onSendEmailFromIndicatorIcon: PropTypes.func,
  showDebtor: PropTypes.bool,
  showMatter: PropTypes.bool,
};

export default Container;
