'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');
const {
  billingFrequencyMonthlySubType,
  billingFrequencyMonthlySubTypeLabels,
} = require('./billing-frequency-monthly-sub-type');
const {
  billingFrequencyQuarterlySubType,
  billingFrequencyQuarterlySubTypeLabels,
} = require('./billing-frequency-quarterly-sub-type');
const {
  billingFrequencyAnnuallySubType,
  billingFrequencyAnnuallySubTypeLabels,
} = require('./billing-frequency-annually-sub-type');
const { billingFrequencySubType } = require('./billing-frequency-sub-type');

const billingFrequency = Object.freeze({
  NONE: 0,
  ANNUALLY: 1,
  MONTHLY: 2,
  QUARTERLY: 3,
  HOLD: 4,
});

const billingFrequencyLabels = Object.freeze({
  NONE: 'None',
  ANNUALLY: 'Annually',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  HOLD: 'Hold',
});

const billingFrequencyOptions = getTypeAsOptions({
  typeLabels: billingFrequencyLabels,
  typeValues: billingFrequency,
});

const billingFrequencyTree = Object.freeze([
  {
    id: `${billingFrequencyLabels.NONE} Set`,
    display: `${billingFrequencyLabels.NONE} Set`,
    data: [billingFrequencySubType.NONE, undefined],
  },
  {
    id: billingFrequencyLabels.MONTHLY,
    display: billingFrequencyLabels.MONTHLY,
    data: [
      billingFrequencyMonthlySubType.MONTHLY_ALL,
      billingFrequencyMonthlySubType.MONTHLY_ODD,
      billingFrequencyMonthlySubType.MONTHLY_EVEN,
    ],
    leaves: [
      {
        id: billingFrequencyMonthlySubType.MONTHLY_ALL,
        display: billingFrequencyMonthlySubTypeLabels.MONTHLY_ALL,
        data: [billingFrequencyMonthlySubType.MONTHLY_ALL],
      },
      {
        id: billingFrequencyMonthlySubType.MONTHLY_ODD,
        display: billingFrequencyMonthlySubTypeLabels.MONTHLY_ODD,
        data: [billingFrequencyMonthlySubType.MONTHLY_ODD],
      },
      {
        id: billingFrequencyMonthlySubType.MONTHLY_EVEN,
        display: billingFrequencyMonthlySubTypeLabels.MONTHLY_EVEN,
        data: [billingFrequencyMonthlySubType.MONTHLY_EVEN],
      },
    ],
  },
  {
    id: billingFrequencyLabels.QUARTERLY,
    display: billingFrequencyLabels.QUARTERLY,
    data: [
      billingFrequencyQuarterlySubType.QUARTERLY_JAN,
      billingFrequencyQuarterlySubType.QUARTERLY_FEB,
      billingFrequencyQuarterlySubType.QUARTERLY_MAR,
    ],
    leaves: [
      {
        id: billingFrequencyQuarterlySubType.QUARTERLY_JAN,
        display: billingFrequencyQuarterlySubTypeLabels.QUARTERLY_JAN,
        data: [billingFrequencyQuarterlySubType.QUARTERLY_JAN],
      },
      {
        id: billingFrequencyQuarterlySubType.QUARTERLY_FEB,
        display: billingFrequencyQuarterlySubTypeLabels.QUARTERLY_FEB,
        data: [billingFrequencyQuarterlySubType.QUARTERLY_FEB],
      },
      {
        id: billingFrequencyQuarterlySubType.QUARTERLY_MAR,
        display: billingFrequencyQuarterlySubTypeLabels.QUARTERLY_MAR,
        data: [billingFrequencyQuarterlySubType.QUARTERLY_MAR],
      },
    ],
  },
  {
    id: billingFrequencyLabels.ANNUALLY,
    display: billingFrequencyLabels.ANNUALLY,
    data: [
      billingFrequencyAnnuallySubType.ANNUALLY_JAN,
      billingFrequencyAnnuallySubType.ANNUALLY_FEB,
      billingFrequencyAnnuallySubType.ANNUALLY_MAR,
      billingFrequencyAnnuallySubType.ANNUALLY_APR,
      billingFrequencyAnnuallySubType.ANNUALLY_MAY,
      billingFrequencyAnnuallySubType.ANNUALLY_JUN,
      billingFrequencyAnnuallySubType.ANNUALLY_JUL,
      billingFrequencyAnnuallySubType.ANNUALLY_AUG,
      billingFrequencyAnnuallySubType.ANNUALLY_SEP,
      billingFrequencyAnnuallySubType.ANNUALLY_OCT,
      billingFrequencyAnnuallySubType.ANNUALLY_NOV,
      billingFrequencyAnnuallySubType.ANNUALLY_DEC,
    ],
    leaves: [
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_JAN,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_JAN,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_JAN],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_FEB,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_FEB,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_FEB],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_MAR,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_MAR,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_MAR],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_APR,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_APR,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_APR],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_MAY,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_MAY,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_MAY],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_JUN,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_JUN,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_JUN],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_JUL,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_JUL,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_JUL],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_AUG,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_AUG,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_AUG],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_SEP,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_SEP,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_SEP],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_OCT,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_OCT,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_OCT],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_NOV,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_NOV,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_NOV],
      },
      {
        id: billingFrequencyAnnuallySubType.ANNUALLY_DEC,
        display: billingFrequencyAnnuallySubTypeLabels.ANNUALLY_DEC,
        data: [billingFrequencyAnnuallySubType.ANNUALLY_DEC],
      },
    ],
  },
  {
    id: billingFrequencyLabels.HOLD,
    display: billingFrequencyLabels.HOLD,
    data: [billingFrequencySubType.HOLD],
  },
]);

module.exports = {
  billingFrequency,
  billingFrequencyLabels,
  billingFrequencyOptions,
  billingFrequencyTree,
};
