import React from 'react';
import { Button } from '@sb-itops/react/button';
import { Modal } from '@sb-itops/react/modal';
import * as Styles from './DeleteDashboardConfirmationModal.module.scss';

const DeleteDashboardConfirmationModal = ({ onConfirm, onClose }: { onConfirm: () => void; onClose: () => void }) => (
  <Modal
    title="Delete Dashboard"
    body={
      <div>
        <p>Are you sure you want to delete this dashboard? This action cannot be undone.</p>
      </div>
    }
    footer={
      <div className={Styles.footer}>
        <Button className={Styles.footerBtn} onClick={onConfirm}>
          Delete
        </Button>
        <Button className={Styles.footerBtn} type="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    }
    onModalClose={onClose}
    shouldCloseOnOverlayClick={false}
  />
);

export { DeleteDashboardConfirmationModal };
