/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, forms2PropTypes, Icon, Typeahead, useTranslation } from '@sb-itops/react';
import classnames from 'classnames';

import { MatterTypeahead } from '../matter-typeahead';
import Styles from './TrustAccountAddEditForm.module.scss';

export const TrustAccountAddEditForm = ({
  scope,
  bankAccountId,
  existingStatutoryDepositMatterIds,
  // localisation config
  supportsElectronicPayment,
  supportsStatutoryDepositMatter,
  supportsTrustAccountPerState,
  bankNameAndBranchNameRequiredForTrustAccount,
  // form fields
  stateOptions,
  accountName,
  accountNumber,
  bankName,
  branchName,
  branchNumber,
  displayName,
  location,
  statutoryDepositMatterId,
  // form
  formDisabled,
  formInitialised,
  isBankAccountClosed,
  isValidStatutoryDepositMatterId,
  isAuthorised,
  onFieldValueUpdated,
  submitFailed,
  validateForm,
}) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
  const isStatutoryDepositMatterDisabled = () => !!bankAccountId && !isValidStatutoryDepositMatterId;

  return (
    <div className={Styles.container} id={scope}>
      <fieldset disabled={!isAuthorised}>
        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel
              label={`${t('trustAccount')} Name`}
              field={accountName}
              submitFailed={submitFailed}
              uppercase={false}
            />
            <input
              className={classnames('form-control', isErrorClassnames(accountName))}
              disabled={formDisabled || isBankAccountClosed}
              onChange={(e) => {
                onFieldValueUpdated({ accountName: e.target.value });
              }}
              onBlur={validateForm}
              type="text"
              value={accountName?.value || ''}
            />
          </div>

          <div className="col-xs-6 form-group">
            <div className={Styles.labelWithIcon}>
              <FormLabel
                label="Display Name"
                field={displayName}
                optional
                submitFailed={submitFailed}
                uppercase={false}
              />
              <Icon
                className={Styles.infoIcon}
                type="question-mark"
                tooltip={`The Display Name field allows you to optionally set a shortened version of the ${t(
                  'trustAccount',
                )} name for your internal use. This will be used for things like menu items and select boxes, and won't be visible to your clients.`}
              />
            </div>
            <input
              className={classnames('form-control', isErrorClassnames(displayName))}
              disabled={formDisabled || isBankAccountClosed}
              onChange={(e) => {
                onFieldValueUpdated({ displayName: e.target.value });
              }}
              onBlur={validateForm}
              type="text"
              value={displayName?.value || ''}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel
              label="Bank Name"
              field={bankName}
              submitFailed={submitFailed}
              uppercase={false}
              optional={!bankNameAndBranchNameRequiredForTrustAccount}
            />
            <input
              className={classnames('form-control', isErrorClassnames(bankName))}
              disabled={formDisabled || isBankAccountClosed}
              onChange={(e) => {
                onFieldValueUpdated({ bankName: e.target.value });
              }}
              onBlur={validateForm}
              type="text"
              value={bankName?.value || ''}
            />
          </div>

          <div className="col-xs-6 form-group">
            <FormLabel
              label="Branch"
              field={branchName}
              submitFailed={submitFailed}
              uppercase={false}
              optional={!bankNameAndBranchNameRequiredForTrustAccount}
            />
            <input
              className={classnames('form-control', isErrorClassnames(branchName))}
              disabled={formDisabled || isBankAccountClosed}
              onChange={(e) => {
                onFieldValueUpdated({ branchName: e.target.value });
              }}
              onBlur={validateForm}
              type="text"
              value={branchName?.value || ''}
            />
          </div>
        </div>

        {supportsElectronicPayment && (
          <div className="row">
            <div className="col-xs-6 form-group">
              <FormLabel
                label={t('bankBranchNumber')}
                field={branchNumber}
                submitFailed={submitFailed}
                uppercase={false}
              />
              <input
                className={classnames('form-control', isErrorClassnames(branchNumber))}
                disabled={formDisabled || isBankAccountClosed}
                onChange={(e) => {
                  onFieldValueUpdated({ branchNumber: e.target.value });
                }}
                onBlur={validateForm}
                type="text"
                value={branchNumber?.value || ''}
              />
            </div>

            <div className="col-xs-6 form-group">
              <FormLabel label="Account Number" field={accountNumber} submitFailed={submitFailed} uppercase={false} />
              <input
                className={classnames('form-control', isErrorClassnames(accountNumber))}
                disabled={formDisabled || isBankAccountClosed}
                onChange={(e) => {
                  onFieldValueUpdated({ accountNumber: e.target.value });
                }}
                onBlur={validateForm}
                type="text"
                value={accountNumber?.value || ''}
              />
            </div>
          </div>
        )}

        {(supportsTrustAccountPerState || supportsStatutoryDepositMatter) && (
          <div className="row">
            {supportsTrustAccountPerState && (
              <div className="col-xs-6 form-group">
                <FormLabel
                  label={`Available for Matters in ${t('capitalize', { val: 'addressState' })}`}
                  field={location}
                  submitFailed={submitFailed}
                  uppercase={false}
                />
                <Typeahead
                  selectedOption={stateOptions.find((option) => option.value === location?.value)}
                  menuPlacement="top"
                  options={stateOptions}
                  disabled={formDisabled || isBankAccountClosed || !isAuthorised}
                  placeholder="Select a state..."
                  className={classnames(Styles.formSelect, isErrorClassnames(location, Styles.formSelect))}
                  onSelect={(option) => {
                    onFieldValueUpdated({ location: (option && option.value) || undefined });
                    validateForm();
                  }}
                />
              </div>
            )}

            {supportsStatutoryDepositMatter && (
              <div className="col-xs-6 form-group">
                <div className={Styles.labelWithIcon}>
                  <FormLabel
                    label="Statutory Deposit Matter"
                    field={statutoryDepositMatterId}
                    submitFailed={submitFailed}
                    uppercase={false}
                    optional
                  />
                  {isStatutoryDepositMatterDisabled() && (
                    <Icon
                      className={Styles.infoIcon}
                      type="question-mark"
                      tooltip="To change the statutory deposit matter, the existing statutory deposit matter must have a
                    non-negative balance."
                    />
                  )}
                </div>
                <MatterTypeahead
                  onValueChange={(matter) => {
                    onFieldValueUpdated({ statutoryDepositMatterId: matter?.id });
                    validateForm();
                  }}
                  initialId={statutoryDepositMatterId?.value}
                  filter="all"
                  disabled={formDisabled || isStatutoryDepositMatterDisabled() || isBankAccountClosed || !isAuthorised}
                  onBlur={validateForm}
                  hasError={statutoryDepositMatterId && statutoryDepositMatterId.isInvalid}
                  excludeIds={existingStatutoryDepositMatterIds}
                />
              </div>
            )}
            {!isAuthorised && (
              <div className={classnames('col-lg-12', Styles.errorMessage)}>Must be firm owner to make changes</div>
            )}
          </div>
        )}
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

TrustAccountAddEditForm.displayName = 'TrustAccountAddEditForm';

TrustAccountAddEditForm.propTypes = {
  scope: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string,
  stateOptions: PropTypes.array.isRequired,
  existingStatutoryDepositMatterIds: PropTypes.array.isRequired,
  // localisation config
  supportsElectronicPayment: PropTypes.bool.isRequired,
  supportsStatutoryDepositMatter: PropTypes.bool.isRequired,
  supportsTrustAccountPerState: PropTypes.bool.isRequired,
  bankNameAndBranchNameRequiredForTrustAccount: PropTypes.bool.isRequired,
  displayName: Forms2Field,
  accountName: Forms2Field,
  accountNumber: Forms2Field,
  bankName: Forms2Field,
  branchName: Forms2Field,
  branchNumber: Forms2Field,
  location: Forms2Field,
  statutoryDepositMatterId: Forms2Field,
  // form
  formDisabled: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  isBankAccountClosed: PropTypes.bool.isRequired,
  isValidStatutoryDepositMatterId: PropTypes.bool.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  validateForm: PropTypes.func.isRequired,
  isAuthorised: PropTypes.bool.isRequired,
};

TrustAccountAddEditForm.defaultProps = {
  bankAccountId: undefined,
  displayName: undefined,
  accountName: undefined,
  accountNumber: undefined,
  bankName: undefined,
  branchName: undefined,
  branchNumber: undefined,
  location: undefined,
  statutoryDepositMatterId: undefined,
};
