import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@sb-itops/fe-logger';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getById as getBankAccountById, getOperatingAccount } from '@sb-billing/redux/bank-account';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { getRegion, regionType } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import {
  getActiveProvider,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { depositRequestDefaults } from '@sb-billing/business-logic/deposit-request/entities';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { fetchPeopleP, findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/redux/contacts';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getMinChargeAmountInCents } from '@sb-billing/business-logic/payment-provider/services';
import { DepositRequestForm } from './DepositRequestForm';
import { createDepositRequestFormSchema } from './deposit-request-form-schema';
import { getDepositRequestEmailInterpolated } from './deposit-request-interpolated';

const DEPOSIT_REQUEST_FORM_SCHEMA = createDepositRequestFormSchema();

const log = getLogger('DepositRequestFormContainer');
const region = getRegion();
const defaultReasons = Object.freeze({
  [regionType.AU]: 'On account of trusts and disbursements',
  [regionType.GB]: '',
  [regionType.US]: '',
});

const hooks = ({ debtorId, matterId, defaultBankAccountId, scope }) => ({
  useBankAccountSelection: () => {
    const bankAccountOptions = getBankAccountOptionsForMatter(matterId);
    return { bankAccountOptions };
  },
  useReasonField: () => ({ showReasonField: featureActive('BB-10530') }),
  useMinAmount: () => {
    const providerType = getActiveProvider();
    const minAmountAllowed = getMinChargeAmountInCents({ providerType, region });

    return { minAmountAllowed };
  },
  useDepositRequestForm: () => {
    const { t } = useTranslation();
    const [isPreviewMode, setIsPreviewMode] = useState(true);
    const [previewSubject, setPreviewSubject] = useState('');
    const [previewMessage, setPreviewMessage] = useState('');

    const matterClientAddressRequiredForTrustDeposit = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);

    const form = useForm({
      scope,
      schema: DEPOSIT_REQUEST_FORM_SCHEMA,
      validateFn: getValidateFn({ matterClientAddressRequiredForTrustDeposit, matterId }),
    });

    const getPreviewInfo = async () => {
      const { subject, message } = await convertToEmailDepositRequest({
        depositRequestData: form.formValues,
        debtorId,
        matterId,
        interpolate: true,
        t,
      });
      setPreviewSubject(subject);
      setPreviewMessage(message);
    };

    const {
      formFields,
      formSubmitting,
      formInitialised,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFieldValues,
      onValidateForm,
    } = form;

    const { bankAccountId, amount, to, cc, bcc, from, subject, message, reason, sendCopyToUser, staffAddress } =
      formFields;

    useEffect(() => {
      // Get preview info on load/when message changes instead of on toggle to enable defaulting to preview mode
      if (message) {
        getPreviewInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return {
      isPreviewMode,
      previewSubject,
      previewMessage,
      // fields
      bankAccountId,
      amount,
      to,
      cc,
      bcc,
      from,
      subject,
      message,
      reason,
      sendCopyToUser,
      staffAddress,
      // form
      onUpdateFieldValues: (fieldName, value) => {
        onUpdateFieldValues(fieldName, value);
        onValidateForm();
      },

      formInitialised,
      submitFailed,
      formDisabled: formSubmitting,
      // callbacks
      onPreviewToggle: () => setIsPreviewMode(!isPreviewMode),
      onOpenEditContactModal: (clickedContactId) => {
        setModalDialogVisible({ modalId: CONTACT_CREATE_EDIT_MODAL_ID, props: { contactId: clickedContactId } });
      },
      onLoad: () => {
        getInitFormValuesP({ matterId, debtorId, bankAccountId: defaultBankAccountId, t })
          .then((initValues) => {
            onInitialiseForm(initValues);
            return undefined;
          })
          .catch((err) => log.error(err));

        return onClearForm;
      },
    };
  },
});

const getInitFormValuesP = async ({ matterId, debtorId, bankAccountId, t }) => {
  const people = await fetchPeopleP(debtorId).catch(() => []);
  const to = formatContactsEmails(people);
  const { email: from } = getStaffEmailDetails();

  const matterEmailSettings = getMatterEmailSettings(matterId) || {};
  const cc = (matterEmailSettings.cCAddresses || []).join(', ');
  const bcc = (matterEmailSettings.bCCAddresses || []).join(', ');

  const { depositRequestEmailSubject, depositRequestEmailBody, sendDepositRequestEmailCopyToUser } =
    getBankAccountSettings();

  const subject = depositRequestEmailSubject || depositRequestDefaults.DEPOSIT_REQUEST_EMAIL_SUBJECT;
  const message = depositRequestEmailBody || depositRequestDefaults.DEPOSIT_REQUEST_EMAIL_MESSAGE_FN(t);
  const sendCopyToUser = sendDepositRequestEmailCopyToUser || false;
  return {
    bankAccountId,
    amount: 0,
    to,
    cc,
    bcc,
    from,
    subject,
    message,
    reason: defaultReasons[region] || '',
    sendCopyToUser,
    staffAddress: from,
  };
};

const getBankAccountOptionsForMatter = (matterId) => {
  let bankAccounts = [];
  if (!matterId) {
    return bankAccounts;
  }

  const shouldAddOperatingBankAccount = hasFacet(facets.operatingAccount);
  const providerType = getActiveProvider();

  const isValidOption = (bankAccountId) =>
    providerType && isPaymentProviderEnabledForBankAccount({ bankAccountId, providerType });

  // Trust account from filterTrustAccountsByMatter
  // - have label property (from getBankAccountName function)
  // - are sorted alphabetically by label
  const activeTrustAccounts = filterTrustAccountsByMatter(matterId);

  bankAccounts = activeTrustAccounts.reduce((acc, bankAccount) => {
    if (isValidOption(bankAccount.id)) {
      acc.push({ label: bankAccount.label, value: bankAccount.id });
    }
    return acc;
  }, []);

  const operatingAccount = getOperatingAccount();
  const operatingAccountOption =
    shouldAddOperatingBankAccount && isValidOption(operatingAccount.id)
      ? [{ label: 'Operating Retainer', value: operatingAccount.id }]
      : [];

  return bankAccounts.concat(operatingAccountOption);
};

export const convertToEmailDepositRequest = async ({
  depositRequestData,
  debtorId,
  matterId,
  interpolate = true,
  t,
}) => {
  const bankAccount = getBankAccountById(depositRequestData.bankAccountId);
  let bcc = depositRequestData.bcc;

  if (depositRequestData.sendCopyToUser) {
    bcc = depositRequestData.bcc
      ? `${depositRequestData.bcc}, ${depositRequestData.staffAddress}`
      : depositRequestData.staffAddress;
  }

  const depositRequest = {
    sendTo: depositRequestData.to,
    from: depositRequestData.from,
    // empty string would not pass endpoint validation so either email or undefined
    cc: depositRequestData.cc || undefined,
    bcc: bcc || undefined,
    subject: depositRequestData.subject,
    message: depositRequestData.message,
    footer: '',
    accountType: bankAccount.accountType,
    payorId: debtorId,
    matterId,
    // 'amount' is already used in the endpoint so had to use different name
    requestedAmount: depositRequestData.amount,
    bankAccountId: depositRequestData.bankAccountId,
    reason: featureActive('BB-10530') ? depositRequestData.reason : undefined,
  };

  if (interpolate) {
    const { message, subject } = await getDepositRequestEmailInterpolated(depositRequest, t);
    depositRequest.subject = subject;
    depositRequest.message = message;
  }

  return depositRequest;
};

export const getValidateFn =
  ({ matterClientAddressRequiredForTrustDeposit, matterId }) =>
  async () => {
    const formErrors = {};

    if (matterId) {
      const matter = getMatterById(matterId);
      formErrors.subject = [];
      const clientsMissingAddresses = matterClientAddressRequiredForTrustDeposit
        ? await matterClientsMissingAddresses(matter)
        : [];

      if (matterClientAddressRequiredForTrustDeposit && clientsMissingAddresses && clientsMissingAddresses.length > 0) {
        formErrors.subject = [
          ...formErrors.subject,
          ...clientsMissingAddresses.map((client) => `contactId:${client.id}'s address is incomplete.`),
        ];
      }
      if (formErrors.subject.length > 0) {
        formErrors.subject = formErrors.subject.join('\n');
      } else {
        delete formErrors.subject;
      }
    } else {
      formErrors.subject = true;
    }

    return formErrors || {};
  };

async function matterClientsMissingAddresses(matter) {
  const matterClients = matter.clientCustomerIds;
  const clientContactsWithMissingStreetAddress = await findContactsWithMissingStreetOrPOAddress({
    contactIds: matterClients,
    spreadGroupsOfPeople: true,
  });
  return clientContactsWithMissingStreetAddress;
}

export const DepositRequestFormContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(DepositRequestForm)));
DepositRequestFormContainer.displayName = 'DepositRequestFormContainer';

DepositRequestFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  defaultBankAccountId: PropTypes.string.isRequired,
  debtorId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
};
