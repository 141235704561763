import { gql } from '@apollo/client';
// We currently use this only to determine if Xero is connected or not so we don't really care about most of the data
const query = gql`
  query XeroAccessToken {
    xeroAccessToken {
      id
      # This is currently used only to determine if Xero is connected or not so we just check for existence, not the actual token
    }
  }
`;

const notificationIds = ['XeroIntegration.XeroAccessTokenUpdated', 'XeroIntegration.XeroAccessTokenDeleted'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const XeroAccessToken = {
  query,
  notificationIds,
};
