import { states, regionType } from '@sb-itops/region';
import { State } from '@sb-itops/region/types/states';
import { TSchemeValuesFor, schemeMappings } from '@sb-itops/region-schemes';

const getAuStates = () => states[regionType.AU];

const getUsStates = () => states[regionType.US];

const adjustedGbStates: State[] = JSON.parse(JSON.stringify(states[regionType.GB])); // clone before altering
// eslint-disable-next-line no-restricted-syntax
for (const s of adjustedGbStates) {
  // for the purposes of addresses in the UK the id is the same as the name (it's how it's represented dotnet side)
  s.id = s.name;
  s.value = s.name;
}
Object.freeze(adjustedGbStates);
const getGbStates = () => adjustedGbStates;

/**
 * Return a formatted address, like what you could stick on an envelope
 *
 * @param {TAddress} address
 * @param {*} region
 * @return {string[]}
 */
export const getStates = (addressScheme: TSchemeValuesFor<'addressScheme'>): State[] => {
  switch (addressScheme) {
    case schemeMappings.addressScheme.AU:
      return getAuStates();
    case schemeMappings.addressScheme.US:
      return getUsStates();
    case schemeMappings.addressScheme.GB:
      return getGbStates();
    default:
      throw new Error(`Unimplemented addressScheme ${addressScheme}`);
  }
};
