import React, { useState } from 'react';
import { Button, Modal } from '@sb-itops/react';
import { Documents, Expanded } from 'types';
import Styles from './Modal.module.scss';
import { FileTree } from '../file-tree';

export interface IMoveModalProps {
  onClose: () => void;
  onSubmit: (value: string) => void;
  formSubmitting: boolean;
  documents: Documents;
}

export const MoveModal = (props: IMoveModalProps) => {
  const [selectedPath, setSelectedPath] = useState('');
  const [expanded, setExpanded] = useState<Expanded>({ root: true });
  return (
    <Modal
      className={Styles.modal}
      title="Move"
      body={
        <FileTree
          documents={props.documents}
          expanded={expanded}
          setExpanded={setExpanded}
          setSelectedPath={setSelectedPath}
          selectedPath={selectedPath}
        />
      }
      footer={
        <Button
          size="full-width"
          onClick={() => props.onSubmit(selectedPath)}
          disabled={props.formSubmitting}
          locked={props.formSubmitting}
        >
          Move
        </Button>
      }
      onModalClose={props.onClose}
    />
  );
};
