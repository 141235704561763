/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { hasFacet, facets } from '@sb-itops/region-facets';

export const creditCardPaymentFormSchema = Yup.object().shape({
  paymentDate: Yup.number().required(),
  payorId: Yup.string().required(),
  reason: hasFacet(facets.reasonField) ? Yup.string().required() : undefined,
  paymentAmount: Yup.number()
    .min(1)
    .required()
    .test(
      'Payment amount > Due amount',
      'Payment amount is greater than amount due on invoice',
      (paymentAmount, { options }) => {
        const { unpaidAmount } = options.context;

        return paymentAmount <= unpaidAmount;
      },
    ),
});
