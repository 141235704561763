angular.module('sb.billing.webapp').controller('SbPaymentViewModalController', function ($rootScope, $scope, $uibModalInstance, sbPaymentViewModalConfig) {
  'use strict';

  $scope.preFill = sbPaymentViewModalConfig.preFill;
  $scope.features = sbPaymentViewModalConfig.features || {};

  const routeChangeSuccessHandler = $rootScope.$on('$locationChangeSuccess', function() {
    $uibModalInstance.dismiss();
  });

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };

  $scope.onClose = function (dismiss) {
    if (dismiss) {
      $uibModalInstance.dismiss();
    }
  };

  $scope.$on('$destroy', function() {
    routeChangeSuccessHandler();
  });
});
