import { useState, useEffect } from 'react';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import {
  createBankAccount,
  getById as getBankAccountById,
  saveBankAccount,
  ACCOUNT_TYPE,
  getTrustAccounts,
} from '@sb-billing/redux/bank-account';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { getBankAccountBalanceById, getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import {
  getLastCompleted as getLastCompletedBankRec,
  getLatest as getLatestBankReconciliation,
} from '@sb-billing/redux/bank-reconciliations';
import { getByBankAccountId as getTransactionList } from '@sb-billing/redux/transactions';
import { generateTransactionList } from '@sb-billing/business-logic/bank-reconciliation/transaction-list';
import { bankAccountState, bankAccountSaveAction } from '@sb-billing/business-logic/bank-account/entities/constants';
import { saveSettings as saveBankAccountSettings } from '@sb-billing/redux/bank-account-settings/save-settings';
import { getSettings as getBankAccountSettingsFromCache } from '@sb-billing/redux/bank-account-settings';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { isBankAccountDetailsEmpty } from '@sb-billing/business-logic/bank-account/services/is-bank-account-details-empty';
import { needsAuthorisationToChangeBankAccount } from '@sb-billing/business-logic/bank-account/services/need-authorisation-change-bank-account';
import { fetchPermissionsFromWebP } from 'web/services/permissions';
import { resourceIds } from '@sb-itops/business-logic/authorisation';
import { featureActive } from '@sb-itops/feature';
import { useTranslation } from '@sb-itops/react';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch } from 'react-redux';
import * as messageDisplay from '@sb-itops/message-display';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import uuid from '@sb-itops/uuid';
import PropTypes from 'prop-types';
import { createTrustAccountAddEditFormSchema } from '../trust-account-add-edit-form';
import { TrustAccountAddEditCard } from './TrustAccountAddEditCard';

const REGION = getRegion();

const TRUST_ACCOUNT_CLOSE_ACCOUNT_MODAL = 'trust-account-close-account-modal';
const saveTrustAccountDetailsModalId = 'save-trust-account-modal-id';

const scope = 'trust-account-add-edit-card';

const hooks = () => ({
  useConfirmationModal: () => ({
    showConfirmation: () => {
      setModalDialogVisible({ modalId: saveTrustAccountDetailsModalId });
    },
  }),
  useCloseModal: () => {
    const [showCloseAccountModal, setShowCloseAccountModal] = useState('');

    return {
      onCloseModal: () => setShowCloseAccountModal(''),
      onOpenModal: () => setShowCloseAccountModal(TRUST_ACCOUNT_CLOSE_ACCOUNT_MODAL),
      showModal: showCloseAccountModal,
    };
  },
  useCloseCard: ({ onCloseCard }) => ({
    onCancel: async (event) => {
      event.preventDefault();
      onCloseCard();
    },
  }),
  useRegion: () => ({
    region: REGION,
  }),
  usePermissions: ({ bankAccountId, restrictTrust }) => {
    const account = bankAccountId ? getBankAccountById(bankAccountId) : undefined;

    const [isAuthorised, setIsAuthorised] = useState(true);
    useEffect(() => {
      const fetchFirmOwnerPerms = async () => {
        const permissions = await fetchPermissionsFromWebP([resourceIds.BILLING_BANK_ACCOUNT]);
        setIsAuthorised(permissions[resourceIds.BILLING_BANK_ACCOUNT]);
      };
      if (
        featureActive('BB-12792') &&
        needsAuthorisationToChangeBankAccount(getRegion())({
          bankAccount: account,
          restrictTrust,
        })
      ) {
        fetchFirmOwnerPerms();
      }
    }, [account, restrictTrust]);

    return {
      isAuthorised,
    };
  },
  useForms: ({ bankAccountId, onCloseCard }) => {
    const numberOfTrustAccounts = getTrustAccounts().length;
    const bankBranchNumberRegex = getBankAccountStrategy(getRegion(), bankAccountStrategies.BRANCH_NUMBER_REGEX);

    const form = useForm({
      scope,
      schema: createTrustAccountAddEditFormSchema({ numberOfTrustAccounts, bankBranchNumberRegex }),
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { formSubmitting, formInitialised, formValid, onValidateForm, onSubmitForm, onSubmitFormWithValidation } =
      form;

    let canCloseAccount = false;
    let isBankAccountClosed = false;
    // valid by default as it is optional
    let isValidStatutoryDepositMatterId = true;
    let shouldShowConfirmation =
      featureActive('BB-12792') &&
      getBankAccountStrategy(getRegion(), bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES);

    if (bankAccountId) {
      const bankAccount = getBankAccountById(bankAccountId);
      const bankAccountHasNoFunds = getBankAccountBalance(bankAccountId) === 0;
      const bankAccountHasOpenAdjustments = hasOpenAdjustments(bankAccountId);
      const bankAccountIsReconciled =
        !hasUnreconciledTransactions(bankAccountId) && !hasReconciliationInProgess(bankAccountId);
      const canCloseTrustAccountWithWarning = hasFacet(facets.canCloseTrustAccountWithWarning);

      canCloseAccount =
        bankAccountHasNoFunds &&
        ((!canCloseTrustAccountWithWarning && bankAccountIsReconciled && !bankAccountHasOpenAdjustments) ||
          canCloseTrustAccountWithWarning);
      // do not consider opdate to determine if account is closed
      // this is so the buttons don't change labels when opening/closing account
      isBankAccountClosed =
        (bankAccount?.$optimistic ? bankAccount?.lastRemoteVersion?.state : bankAccount?.state) ===
        bankAccountState.CLOSED;

      if (bankAccount.statutoryDepositMatterId) {
        const matterId = bankAccount.statutoryDepositMatterId;
        const matterBalance = getMatterBalance(getBankAccountBalanceState(), {
          matterId,
          bankAccountId: bankAccount.id,
          balanceType: balanceTypes.BALANCE,
        });

        isValidStatutoryDepositMatterId = matterBalance >= 0;
      }

      const bankAccountIsEmpty = isBankAccountDetailsEmpty({ bankAccount });
      shouldShowConfirmation =
        bankAccountIsEmpty &&
        featureActive('BB-12792') &&
        getBankAccountStrategy(getRegion(), bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES);
    }

    const onOpenAccount = async (event) => {
      event.preventDefault();
      try {
        await onSubmitForm({
          submitFnP: async () => {
            await openAccount({ bankAccountId, t });
            onCloseCard();
            messageDisplay.success('Account reopened successfully.');
          },
        });
      } catch (err) {
        messageDisplay.error('Failed to reopen the account');
      }
    };

    const onSave = async () => {
      onValidateForm();

      const isEdit = !!bankAccountId;

      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formFieldValues) => {
            if (isEdit) {
              const bankAccount = getBankAccountById(bankAccountId);
              const originalLocation = bankAccount.location;
              await edit({ formFieldValues, bankAccountId });
              // If location has changed to another state that is not ALL_STATES, we need to remove the account from any default account selections
              if (originalLocation && originalLocation !== formFieldValues.location) {
                const { defaultTrustAccounts = [] } = getBankAccountSettingsFromCache() || {};
                const filteredDefaultTrustAccounts = defaultTrustAccounts.filter(
                  (dta) => dta.defaultTrustAccountId !== bankAccountId,
                );
                await dispatch(saveBankAccountSettings({ defaultTrustAccounts: filteredDefaultTrustAccounts }));
              }
              onCloseCard();
            } else {
              await create({ formFieldValues });
              onCloseCard();
            }

            messageDisplay.success('Account details saved successfully.');
          },
        });
      } catch (err) {
        messageDisplay.error('Failed to save the account');
      }
    };

    return {
      // values
      canCloseAccount,
      formDisabled: formSubmitting,
      formSubmitting,
      formInitialised,
      formValid,
      isBankAccountClosed,
      isValidStatutoryDepositMatterId,
      scope,
      saveTrustAccountDetailsModalId,
      shouldShowConfirmation,
      // callbacks
      onOpenAccount,
      onSave,
    };
  },
});

const hasUnreconciledTransactions = (bankAccountId) => {
  const bankReconciliation = getLatestBankReconciliation(bankAccountId);
  const transactions = generateTransactionList({
    transactions: getTransactionList(bankAccountId),
    filters: {
      bankReconciliation,
    },
    filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
  });

  return transactions.length > 0;
};

const hasReconciliationInProgess = (bankAccountId) => {
  const bankReconciliation = getLatestBankReconciliation(bankAccountId);

  if (!bankReconciliation) {
    return false;
  }

  return bankReconciliation.status === 'InProgress';
};

const hasOpenAdjustments = (bankAccountId) => {
  const lastCompletedBankRec = getLastCompletedBankRec(bankAccountId);
  if (!lastCompletedBankRec) {
    // No last completed bank rec = no open adjustments
    return false;
  }
  // But if there is a last completed bank rec then need to check if there are open adjustments
  return lastCompletedBankRec.adjustments && lastCompletedBankRec.adjustments.some((adj) => !adj.isReconciled);
};

const getBankAccountBalance = (bankAccountId) =>
  getBankAccountBalanceById(getBankAccountBalanceState(), { bankAccountId })?.balance || 0;

const edit = async ({ formFieldValues, bankAccountId }) => {
  const {
    accountName,
    accountNumber,
    bankName,
    branchName,
    branchNumber,
    displayName,
    location,
    statutoryDepositMatterId,
  } = formFieldValues;

  await saveBankAccount({
    id: bankAccountId,
    accountType: ACCOUNT_TYPE.trust.toUpperCase(),
    accountName,
    accountNumber,
    bankName,
    branchName,
    branchNumber,
    displayName,
    location: location || undefined,
    statutoryDepositMatterId,
    action: bankAccountSaveAction.UPDATE,
  });
};
const create = async ({ formFieldValues }) => {
  const {
    accountName,
    accountNumber,
    bankName,
    branchName,
    branchNumber,
    displayName,
    location,
    statutoryDepositMatterId,
  } = formFieldValues;
  const newbankAccountId = uuid();

  await createBankAccount({
    id: newbankAccountId,
    accountType: ACCOUNT_TYPE.trust.toUpperCase(),
    accountName,
    accountNumber,
    bankName,
    branchName,
    branchNumber,
    displayName,
    location,
    statutoryDepositMatterId,
  });
};
const openAccount = async ({ bankAccountId, t }) => {
  const reason = 'Web settings - reopen';
  const bankAccount = getBankAccountById(bankAccountId);
  // Endpoint expect accountName so we need to make sure there is one
  const accountName = bankAccount.accountName || t('trustAccount');

  await saveBankAccount({
    id: bankAccountId,
    accountType: ACCOUNT_TYPE.trust.toUpperCase(),
    state: bankAccountState.OPEN,
    internalNote: reason,
    reason,
    accountName,
    action: bankAccountSaveAction.REOPEN,
  });
};

export const TrustAccountAddEditCardContainer = withReduxProvider(composeHooks(hooks)(TrustAccountAddEditCard));

TrustAccountAddEditCardContainer.displayName = 'TrustAccountAddEditCardContainer';

TrustAccountAddEditCardContainer.propTypes = {
  bankAccountId: PropTypes.string,
};

TrustAccountAddEditCardContainer.defaultProps = {
  bankAccountId: undefined,
  onCloseCard: () => {},
};

export default TrustAccountAddEditCardContainer;
