import { isValidChequeNumber, assignReferenceNumberToCheques } from './assign-reference-number-to-cheques';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').component('sbDuiChequesListSplitPayors', {
  templateUrl: 'ng-composable-components/data-ui/custom/cheque-list-split-payors/dui-cheque-list-split-payors.html',
  bindings: {
    trustCheques: '<',
    firstChequeNumber: '<',
    firstChequeNumberStr: '<',
    onChequeSelected: '&',
    chequeId: '<',
    chequeNumberPadding: '<',
    onChequeMemoUpdated: '<',
    bankAccountId: '<'
  },
  controller: function() {
    const ctrl = this;
    // this will be remove once we add optimistic opdates to the cheque
    let selectedLoaded = false;

    ctrl.onSelectCheque = onSelectCheque;
    ctrl.onMemoFieldUpdated = onMemoFieldUpdated;
    ctrl.onSort = onSort;
    ctrl.onToggleAllCheques = onToggleAllCheques;
    ctrl.showLoadingIndicator = !!ctrl.chequeId;
    ctrl.showChequeMemo = hasFacet(facets.chequeMemo);

    ctrl.model = {
      selected: {},
      cheques: ctrl.trustCheques,
      chequesMemos: {},
    };

    ctrl.$onChanges = (changesObj) => {
      // this is so angular updates the trustCheques reference updated by sb-compose. This is until we migrate this to redux.
      if (changesObj.trustCheques && changesObj.trustCheques.currentValue) {
        // get the new value coming from sb-compose
        const updatedChequesList = changesObj.trustCheques.currentValue;

        // we need this until we add opdates, right now the update comes after the modal is visible, we need to wait to the update to come so we update the angular state and we send
        // the update to react and the parent controller.
        if (ctrl.chequeId && !ctrl.model.selected[ctrl.chequeId] && !selectedLoaded) {
          const cheque = updatedChequesList.find(({ chequeId }) => chequeId === ctrl.chequeId);
          if (cheque) {
            ctrl.model.selected[ctrl.chequeId] = true;
            ctrl.model.chequesMemos[ctrl.chequeId] = cheque.chequeMemo || '';
            selectedLoaded = true;
            ctrl.showLoadingIndicator = false;
          }
        }

        const {cheques, lastChequeNumber} = mapChequesWithReference(updatedChequesList);
        ctrl.model.cheques = cheques;

        // only after the default selected is loaded trigger the on selected callback, after the list was updated
        if (selectedLoaded) {
          ctrl.onChequeSelected({ selected: getSelectedChequesFromState(), lastChequeNumber });
        }
      }

      if (changesObj.firstChequeNumber) {
        validateFirstChequeNumber(ctrl.firstChequeNumber);
      }

      if (changesObj.firstChequeNumber || changesObj.chequeNumberPadding) {
        const {cheques, lastChequeNumber} = mapChequesWithReference(ctrl.model.cheques);
        ctrl.model.cheques = cheques;
        ctrl.onChequeSelected({ selected: getSelectedChequesFromState(), lastChequeNumber });
      }
    };

    ctrl.$onInit = () => {
      onInitializeChequesMemos();
      validateFirstChequeNumber(ctrl.firstChequeNumber);
    };

    function validateFirstChequeNumber(firstChequeNumber) {
      ctrl.isFirstChequeNumberInvalid = !isValidChequeNumber(firstChequeNumber);
    }

    function mapChequesWithReference(cheques) {
      const config = {
        chequesSelected: ctrl.model.selected,
        sortDirection: ctrl.sortDirection,
        firstChequeNumberStr: ctrl.firstChequeNumberStr,
        selectedChequeId: ctrl.chequeId,
        chequeNumberPadding: ctrl.chequeNumberPadding,
        bankAccountId: ctrl.bankAccountId
      };

      return assignReferenceNumberToCheques(cheques, config);
    }

    // this is so we manage the changes memo state since we do not have redux to use we need the angular controller.
    function onInitializeChequesMemos() {
      if (ctrl.model.cheques && ctrl.model.cheques.length > 0 && Object.keys(ctrl.model.chequesMemos).length === 0) {
        const chequesMemos = ctrl.model.cheques.reduce((acc, { chequeId, chequeMemo }) => {
          if (acc) {
            acc[chequeId] = chequeMemo || '';
          }
          return acc;
        }, {});

        if (ctrl.chequeId) {
          chequesMemos[ctrl.chequeId] = '';
        }

        ctrl.model.chequesMemos = chequesMemos;
      }
    }

    function onToggleAllCheques(toggle, cheques) {
      if (!toggle) {
        ctrl.model.selected = {};
      } else {
        ctrl.model.selected = cheques.reduce((acc, cheque) => {
          acc[cheque.chequeId] = cheque;
          return acc;
        }, {});
      }
      const {cheques: updatedCheques, lastChequeNumber} = mapChequesWithReference(ctrl.model.cheques);
      ctrl.model.cheques = updatedCheques;
      ctrl.onChequeSelected({ selected: getSelectedChequesFromState(), lastChequeNumber });
    }

    function onSort({ sortDirection }) {
      ctrl.sortDirection = sortDirection;
    }

    function onMemoFieldUpdated(chequeId, value) {
      const memos = {
        ...ctrl.model.chequesMemos,
      };
      memos[chequeId] = value;
      ctrl.model.chequesMemos = memos;
      const {cheques} = mapChequesWithReference(ctrl.model.cheques);
      ctrl.model.cheques = cheques;
      ctrl.onChequeMemoUpdated(chequeId, value);
    }

    function onSelectCheque(cheque) {
      const selected = { ...ctrl.model.selected };
      selected[cheque.chequeId] = !ctrl.model.selected[cheque.chequeId] ? true : undefined;
      ctrl.model.selected = selected;
      const {cheques, lastChequeNumber} = mapChequesWithReference(ctrl.model.cheques);
      ctrl.model.cheques = cheques;
      ctrl.onChequeSelected({ selected: getSelectedChequesFromState(), lastChequeNumber });
    }

    const getSelectedChequesFromState = () =>
      ctrl.model.cheques.filter(({ chequeId }) => !!ctrl.model.selected[chequeId]);
  },
});
