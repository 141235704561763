import { gql } from '@apollo/client';

const query = gql`
  query AddPaymentModalContactDetails($contactId: ID!) {
    contact(id: $contactId) {
      id
      displayNameFirstLast
      displayName
      firstName
      lastName
      email
      customerBillingConfiguration {
        id
        paymentMethods {
          id
          token
          display
          description
          provider
          providerAccount
          bankAccount {
            id
          }
          expiry
        }
      }
    }
  }
`;

const notificationIds = [
  'CustomerManagementWebQuery.EntityUpdated',
  'BillingSharedNotifications.CustomerBillingConfigurationUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const AddPaymentModalContactDetails = {
  query,
  notificationIds,
};
