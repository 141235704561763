import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withReduxStore } from '@sb-itops/react';
import { getList as getInvoiceSettingsTemplateList } from '@sb-billing/redux/invoice-settings-template';
import InvoiceTemplateDropdown from './InvoiceTemplateDropdown';

const mapStateToProps = (state, { onValueChange, templateId }) => {
  const options = getInvoiceSettingsTemplateList();
  return {
    options,
    onValueChange,
    templateId,
  };
};

const InvoiceTemplateDropdownContainer = withReduxStore(connect(mapStateToProps)(InvoiceTemplateDropdown));

InvoiceTemplateDropdownContainer.displayName = 'InvoiceTemplateDropdownContainer';

InvoiceTemplateDropdownContainer.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  templateId: PropTypes.string,
  disabled: PropTypes.bool,
};

InvoiceTemplateDropdownContainer.defaultProps = {
  templateId: undefined,
  disabled: undefined,
};

export default InvoiceTemplateDropdownContainer;
