angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/preferences/calendar-settings';

  $stateProvider
    .state('preferences.calendar-settings', {
      templateUrl: BASE_DIRECTORY + '/calendar-settings.html',
      url: '/calendar-settings',
      data: {
        navHighlights: ['calendar-settings']
      }
    });
});