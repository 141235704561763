import { operations }  from '@sb-billing/redux/invoice-settings-template-files';

const { updateCache: updateRedux, clearCache: clearRedux } = operations;

angular.module('@sb-billing/services').service('sbInvoiceSettingsTemplateFilesService', function (sbGenericCacheService, sbEndpointType) {
  const endpoint = 'billing/invoice-settings-template-files';

  sbGenericCacheService.setupCache({
    name: 'sbInvoiceSettingsTemplateFilesService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.InvoiceSettingsTemplateFilesUpdated'
    },
    updateRedux,
    clearRedux,
  });
});
