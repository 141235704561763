/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '@sb-itops/nodash';
import moment from 'moment';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  firmFeeConfigurationDefaults,
  entryTypeLabels,
  entryType as entryTypesEnum,
} from '@sb-billing/business-logic/shared/entities';
import { dateToInteger, todayAsInteger } from '@sb-itops/date';
import { sort as sortItems } from '@sb-itops/sort';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  filterUtbmsTaskCodes,
  mapActivitiesByCategory,
  mapTasksByCategory,
} from '@sb-billing/business-logic/activities/services';
import {
  FeeEntriesChartData,
  FeeTableData,
  InitActivityCodes,
  InitFirmFeeConfiguration,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitStaffSettings,
  MatterSummaries,
} from 'web/graphql/queries';
import { useCacheQuery, usePagination, usePrintFeesPdf, useSubscribedLazyQuery, useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { FEE_MODAL_ID } from 'web/components';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import { FirmFeeEntries } from './FirmFeeEntries';

const FETCH_LIMIT = 50;

const SCOPE = 'FirmFeeEntriesRoute';
const feeTableScope = `${SCOPE}/fee-table`;

const hooks = ({ onClickLink, initialFilterDate }) => ({
  useScope: () => ({
    scope: SCOPE,
  }),
  useFilters: () => {
    const [selectedStaffMember, setSelectedStaffMember] = useState(null);
    const [selectedDate, setSelectedDate] = useState(initialFilterDate ? initialFilterDate.toDate() : new Date());
    const [selectedChartMonth, setSelectedChartMonth] = useState((initialFilterDate || moment()).format('YYYYMM'));

    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const { data: firmFeeConfigData } = useCacheQuery(InitFirmFeeConfiguration.query);
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    if (!selectedStaffMember && (staffData?.loggedInStaff?.id || staffData?.staffMembers[0])) {
      setSelectedStaffMember(staffData?.loggedInStaff || staffData?.staffMembers[0]);
    }

    const userPrefersDurationsAsUnits = staffData?.loggedInStaff?.feeConfiguration?.enterTimeAsUnits;
    const billingIncrementsMins =
      firmFeeConfigData?.firmFeeConfiguration?.billableMinutes || firmFeeConfigurationDefaults.BILLABLE_MINUTES;

    const { taxRate: taxRateBasisPoints, registeredForGst } = firmTaxSettingsData?.firmTaxSettings || {};

    const supportsUtbms = hasFacet(facets.utbms);
    const utbmsEnabledForFirm = (supportsUtbms && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled) || false;
    const utbmsCodesRequiredByFirm =
      utbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;

    const utbmsCodeSetsUsedByFirm = firmUtbmsSettingsData?.firmUtbmsSettings?.selectedCodeSets;

    const staffMemberOptions = useMemo(() => {
      const options = !staffData?.staffMembers?.length
        ? []
        : staffData.staffMembers.map((staffMemberEntity) => ({
            value: staffMemberEntity.id,
            label: `${staffMemberEntity.initials} (${staffMemberEntity.name})`,
            entity: {
              ...staffMemberEntity,
              rate: staffMemberEntity.rate || 0,
            },
          }));

      const sortedOptions = sortItems(options, ['label'], ['ASC']);

      return sortedOptions;
    }, [staffData?.staffMembers]);

    return {
      selectedDate,
      onChangeFilterDate: (date) => {
        setSelectedDate(date);
        setSelectedChartMonth(moment(date).format('YYYYMM'));
      },
      selectedChartDay: moment(selectedDate).format('YYYYMMDD'),
      onChartDayChange: (newMomentDate) => {
        setSelectedDate(newMomentDate.toDate());
      },
      selectedChartMonth,
      onChartMonthChange: (newMomentDate) => {
        setSelectedChartMonth(newMomentDate.format('YYYYMM'));
      },
      staffMemberOptions,
      selectedStaffMember,
      onStaffMemberChange: (newStaffMember) => {
        setSelectedStaffMember(newStaffMember);
      },
      userPrefersDurationsAsUnits,
      billingIncrementsMins,
      registeredForGst,
      taxRateBasisPoints,
      utbmsEnabledForFirm,
      utbmsCodeSetsUsedByFirm,
      utbmsCodesRequiredByFirm,
      showTaskColumn: utbmsEnabledForFirm,
    };
  },
  useMatterSummaryData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(
      MatterSummaries,
      {
        context: { skipRequestBatching: true },
        variables: {
          includeMatterHourlyRate: true,
          filter: {
            matterStatus: ['pending', 'open'],
            rateSetDate: todayAsInteger(),
          },
          limit: 25,
          sort: {
            fieldNames: ['statusOpen', 'matterStarted'],
            directions: ['DESC', 'DESC'],
          },
        },
      },
      {
        notificationIds: [
          ...MatterSummaries.notificationIds,
          'WebQueryFeesNotifications.MatterHourlyRateUpdated',
          'FeesNotifications.StaffFeeConfigurationUpdated',
          'FeesNotifications.StaffMemberHourlyRateUpdated',
        ],
      },
    );

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
  useActivityCodes: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    const activities = useMemo(
      () =>
        mapActivitiesByCategory({
          activityCodes: activityCodesResult?.data?.activityCodes,
          utbmsActivityCodes: activityCodesResult?.data?.utbmsActivityCodes,
          filterCustomCodeByTypes: [entryTypesEnum.FIXED, entryTypesEnum.TIME],
        }),
      [activityCodesResult?.data?.activityCodes, activityCodesResult?.data?.utbmsActivityCodes],
    );

    const tasks = useMemo(
      () =>
        mapTasksByCategory({
          utbmsTaskCodes: activityCodesResult?.data?.utbmsTaskCodes,
          utbmsCustomTaskCodes: activityCodesResult?.data?.utbmsCustomTaskCodes,
          filterCustomCodeByTypes: [entryTypeLabels.FIXED, entryTypeLabels.TIME],
        }),
      [activityCodesResult?.data?.utbmsTaskCodes, activityCodesResult?.data?.utbmsCustomTaskCodes],
    );

    return {
      activities,
      activityDataLoading: activities.length === 0 && activityCodesResult.loading,
      tasks,
    };
  },
  usePopoutSelection: () => {
    const [currentFee, setCurrentFee] = useState();
    const [showFeeSidePanel, setShowFeeSidePanel] = useState(false);

    const onRowClick = (fee) => {
      // Clicking a fee row will either:
      //  1. Open the fee modal
      //  2. Changes the fee in the side panel (if open)
      if (!showFeeSidePanel) {
        setModalDialogVisible({
          modalId: FEE_MODAL_ID,
          props: {
            scope: `${SCOPE}/fee-modal`,
            feeId: fee.id,
            onNavigateToInvoice: () => onClickLink({ type: 'invoice', id: fee.invoice?.id }),
          },
        });
      }

      setCurrentFee(fee);
    };

    return {
      showFeeSidePanel,
      currentFee,
      onSetCurrentFee: setCurrentFee,
      onRowClick,
      onSetShowFeeSidePanel: setShowFeeSidePanel,
    };
  },
  useColumnDisplay: () => ({
    showFeeDateColumn: false,
    showStaffInitialsColumn: false,
    showMatterColumn: true,
    showTaxColumns: hasFacet(facets.tax),
  }),
  useFeesSort: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: feeTableScope,
      initialSortBy: 'matter',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
});

// These hooks will be called once the hooks above have completed, and will
// receive their props. This is required as we cannot retrieve values from
// adjacent hooks in composeHooks
const queryHooks = ({
  selectedDate,
  selectedChartMonth,
  selectedStaffMember,
  utbmsEnabledForFirm,
  utbmsCodeSetsUsedByFirm,
  sortBy,
  sortDirection,
  tasks,
  sbAsyncOperationsService,
}) => ({
  useFirmUtbmsTasks: () => {
    // If we haven't yet received the task codes used by the firm, we aren't able to filter from the full list.
    // In this case we won't show any utbms tasks.
    if (!utbmsEnabledForFirm || !utbmsCodeSetsUsedByFirm) {
      return {
        tasks: [],
      };
    }

    const filteredTasks = filterUtbmsTaskCodes({
      utbmsTaskCodes: tasks, // both standard and custom task codes
      utbmsCodeSetsUsedByFirm,
    });

    return {
      tasks: filteredTasks,
    };
  },
  useFeeListData: () => {
    const {
      currentPage: currentFeePage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${SCOPE}-pagination`,
      fetchLimit: FETCH_LIMIT,
    });

    const onFeeListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const feeDate = dateToInteger(selectedDate);
    const staffIds = selectedStaffMember?.id ? [selectedStaffMember.id] : undefined;

    const feesQueryResult = useSubscribedQuery(FeeTableData, {
      skip: !selectedDate || !selectedStaffMember,
      variables: {
        feeFilter: {
          feeDate: {
            from: feeDate,
            to: feeDate,
          },
          feeEarnerStaffIds: staffIds,
          includeStatus: {
            current: true,
          },
        },
        offset: currentFeePage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (feesQueryResult.error) {
      throw new Error(feesQueryResult.error);
    }

    const { data: feeData } = feesQueryResult;
    const feeList = feeData?.feeList?.results || [];

    const {
      totalCount: feeCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfFeePages,
    } = getPagination({ totalCount: feeData?.feeList?.totalCount, loading: feesQueryResult.loading });

    return {
      currentFeePage,
      fees: feeList,
      feeCount,
      feeDataLoading: feeList.length === 0 && feesQueryResult.loading,
      totalNumberOfFeePages,
      hidePagination,
      onFeeListPageChange,
    };
  },
  useFeeChartData: () => {
    const staffIds = selectedStaffMember?.id ? [selectedStaffMember.id] : undefined;

    const chartFeesQueryResult = useSubscribedQuery(FeeEntriesChartData, {
      skip: !selectedDate || !selectedStaffMember,
      variables: {
        feeFilter: {
          feeDate: {
            from: +moment(selectedChartMonth, 'YYYYMM').startOf('month').format('YYYYMMDD'),
            to: +moment(selectedChartMonth, 'YYYYMM').endOf('month').format('YYYYMMDD'),
          },
          feeEarnerStaffIds: staffIds,
          includeStatus: {
            current: true,
          },
        },
        sort: { fieldNames: ['feeDate'], directions: ['ASC'] },
      },
    });

    if (chartFeesQueryResult.error) {
      throw new Error(chartFeesQueryResult.error);
    }

    const chartFees = chartFeesQueryResult.data?.feeList?.results || [];

    return {
      chartFees,
      chartDataLoading: chartFees.length === 0 && chartFeesQueryResult.loading,
    };
  },
  useExportReport: () => {
    const { printFeesPdf, isLoadingFeesPdf } = usePrintFeesPdf();
    const sort = !sortBy
      ? undefined
      : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] };

    const onGeneratePDFReport = async () => {
      await printFeesPdf({
        feeFilters: {
          selectedStaffId: selectedStaffMember.id,
          selectedDate: dateToInteger(selectedDate),
          sort,
          showTaskColumn: utbmsEnabledForFirm,
        },
      });
    };

    const onGenerateCSVReport = async () => {
      try {
        sbAsyncOperationsService.startExportFeeCsv({
          selectedStaffId: selectedStaffMember.id,
          selectedDate: dateToInteger(selectedDate),
          sort,
          showTaskColumn: utbmsEnabledForFirm,
        });
      } catch (error) {
        throw new Error('Failed to export fee. Please try again later.');
      }
    };

    return {
      isGeneratingPDFReport: isLoadingFeesPdf,
      onGeneratePDFReport,
      onGenerateCSVReport,
    };
  },
});

export const FirmFeeEntriesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(FirmFeeEntries))),
);

FirmFeeEntriesContainer.displayName = 'FirmFeeEntriesContainer';

FirmFeeEntriesContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  initialFilterDate: PropTypes.object,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

FirmFeeEntriesContainer.defaultProps = {
  initialFilterDate: undefined,
};
