import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import * as Styles from './MessageModal.module.scss';

export const MessageModal = ({ isVisible, title, children, message, onConfirm }) => (
  <Modal
    title={title}
    body={
      children ? (
        <div>{children}</div>
      ) : (
        <div>
          <p>{message}</p>
        </div>
      )
    }
    footer={
      <div className={Styles.footerContainer}>
        <Button onClick={onConfirm}>Okay</Button>
      </div>
    }
    onModalClose={onConfirm}
    isVisible={isVisible}
    shouldCloseOnOverlayClick={false}
  />
);

MessageModal.displayName = 'MessageModal';

MessageModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
};

MessageModal.defaultProps = {
  children: null,
  message: '',
  title: '',
};
