import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyDisplay } from '@sb-itops/react';
import { InvoicePaymentAllocationTable } from '../invoice-payment-allocation-table';
import Styles from './ApplyPaymentsForm.module.scss';

/**
 * @typedef {object} Allocation
 * @property {matterId} contactId
 * @property {number} available available balance
 * @property {number} amount amount to pay
 */

function updateAllocations(arr, item) {
  const allocs = arr || [];
  const index = allocs.findIndex((a) => a.contactId === item.contactId);

  return [...allocs.slice(0, index), item, ...allocs.slice(index + 1)];
}

function allocateAll(allocations) {
  const isAllAllocated = allocations.every((allocation) => allocation.amount === allocation.available);
  return allocations.map((allocation) => ({
    ...allocation,
    amount: isAllAllocated ? 0 : allocation.available,
  }));
}

const ApplyPaymentsForm = memo(
  ({
    invoiceTotals,
    totalAmount,
    trustAllocations,
    operatingAllocations,
    onChangeTrustAllocations,
    onChangeOperatingAllocations,
    onChangeCreditAllocations,
    creditAllocations,
    onContactLinkClick,
    hasError,
  }) => {
    const totalDue = invoiceTotals.total || 0;
    const invoiceBalance = totalAmount > totalDue ? 0 : totalDue - totalAmount;

    return (
      <div className={Styles.applyPaymentsForm}>
        <div className={Styles.totals}>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Amount</label>
            <CurrencyDisplay amount={totalAmount} hasError={hasError} />
          </div>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Due</label>
            <CurrencyDisplay amount={totalDue} />
          </div>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Invoice Balance</label>
            <CurrencyDisplay amount={invoiceBalance} />
          </div>
        </div>
        {!!trustAllocations.length && (
          <div className={classnames(Styles.invoicePaymentAllocationTable, Styles.marginBottom)}>
            <label className={Styles.uppercaseLabel}>
              {/* All trustAllocations are for same trust account so we can use name of the first one;
              autoAllocatePayments only available in US, it's fine to not localise 'Trust Account' here */}
              {`Trust Account: ${trustAllocations[0].bankAccountName}`}
            </label>
            <InvoicePaymentAllocationTable
              allocations={trustAllocations}
              onChange={(allocation = {}) => onChangeTrustAllocations(updateAllocations(trustAllocations, allocation))}
              onAllocateAll={() => onChangeTrustAllocations(allocateAll(trustAllocations))}
              onContactLinkClick={onContactLinkClick}
            />
          </div>
        )}
        {!!operatingAllocations.length && (
          <div className={classnames(Styles.invoicePaymentAllocationTable, Styles.marginBottom)}>
            <label className={Styles.uppercaseLabel}>Operating Retainer</label>
            <InvoicePaymentAllocationTable
              allocations={operatingAllocations}
              onChange={(allocation) => {
                onChangeOperatingAllocations(updateAllocations(operatingAllocations, allocation));
              }}
              onAllocateAll={() => onChangeOperatingAllocations(allocateAll(operatingAllocations))}
              onContactLinkClick={onContactLinkClick}
            />
          </div>
        )}
        {!!creditAllocations.length && (
          <div className={Styles.invoicePaymentAllocationTable}>
            <label className={Styles.uppercaseLabel}>Credits</label>
            <InvoicePaymentAllocationTable
              allocations={creditAllocations}
              onChange={(allocation) => {
                onChangeCreditAllocations(updateAllocations(creditAllocations, allocation));
              }}
              onAllocateAll={() => onChangeCreditAllocations(allocateAll(creditAllocations))}
              onContactLinkClick={onContactLinkClick}
            />
          </div>
        )}
      </div>
    );
  },
);

ApplyPaymentsForm.displayName = 'ApplyPaymentsForm';

ApplyPaymentsForm.propTypes = {
  trustAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      bankAccountId: PropTypes.string.isRequired,
      bankAccountName: PropTypes.string.isRequired,
    }),
  ),
  operatingAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      bankAccountId: PropTypes.string.isRequired,
    }),
  ),
  creditAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      bankAccountId: PropTypes.string.isRequired,
    }),
  ),
  invoiceTotals: PropTypes.object,
  onContactLinkClick: PropTypes.func.isRequired,
  onChangeTrustAllocations: PropTypes.func.isRequired,
  onChangeOperatingAllocations: PropTypes.func.isRequired,
  onChangeCreditAllocations: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  totalAmount: PropTypes.number,
};

ApplyPaymentsForm.defaultProps = {
  trustAllocations: [],
  operatingAllocations: [],
  creditAllocations: [],
  invoiceTotals: {},
  hasError: false,
  totalAmount: 0,
};

export default ApplyPaymentsForm;
