'use strict';

const { interpolateInvoiceReminderEmail } = require('./interpolate-reminder-email');

// This setting is unitlise for new users that hasn't clicked save on Invoice Reminder Settings (invoice reminder setting not exist DB yet)
const getDefaultInvoiceReminderSettings = ({ allowEmailAttachment }) => ({
  emailSubject: 'Invoice Reminder for [MATTER]',
  emailBody: `<p>Dear [DEBTOR] <br></p>
            <p>Your account is now past due in the amount of [OVERDUE_AMOUNT]. Please refer to ${
              allowEmailAttachment ? 'attached' : 'linked'
            } invoice for your reference.<br>
            <br>
            Please pay the past due amount immediately. <br>
            <p>Kind Regards,<br></p>
            [USER]</p>`,
  notes: '',
  sendCopyToUser: false,
  showAccountSummary: false,
  showTransactionHistory: false,
});

/**
 * Given a list of invoices to be attached to an invoice reminder for the same debtor
 * Return the first valid merchant payment URL if there are no multi-debtor invoices included
 * Otherwise return undefined
 * @param {object} param
 * @param {Array<object>} param.invoices list of invoices
 * @return {string|undefined}
 */
const findMerchantPaymentUrl = ({ invoices }) => {
  const merchantPayment = invoices && invoices.find((inv) => inv.merchantPaymentUrl);
  return merchantPayment ? merchantPayment.merchantPaymentUrl : undefined;
};

module.exports = {
  getDefaultInvoiceReminderSettings,
  findMerchantPaymentUrl,
  interpolateInvoiceReminderEmail,
};
