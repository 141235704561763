import composeHooks from '@sb-itops/react-hooks-compose';
import { categoryOptionMap, getSelectedCategory } from 'web/redux/route/home-billing-contacts';
import { useSelector } from 'react-redux';

const hooks = () => ({
  useSelectedContactCategory: () => {
    // getSelectedCategory accepts no arguments, but without useSelector this component will not be subscribed to the store.
    const selectedCategory = categoryOptionMap[useSelector(getSelectedCategory)];

    return {
      selectedCategory,
    };
  },
});

// Expect the route-level component to be wrapped in a withReduxProvider
export const ContactTableCategory = composeHooks(hooks);

ContactTableCategory.displayName = 'ContactTableCategory.container';
