import { createSelector } from 'reselect';
import { actionGuards } from './actions';
import { autoSelectorFactory } from './redux-helpers';
import { CommIframePosition } from './types';

type TState = {
  spawned: boolean; // true if a communicate tab has been clicked in the browsing session
  initialised: boolean; // true if communicate system has finished init and informed billing via postmessage
  visible: boolean; // true if iframe should be visible at this moment
  position: null | CommIframePosition; // absolute iframe position in pixels
  matterId: string | null; // if we are in matter mode this should be set to the matter uuid
  unreadConversationDetails: {
    polled: { matterId: string; conversationId: string }[] | null; // this comes from billing polling a communicate endpoint
    notified: { matterId: string; conversationId: string }[] | null; // this comes from communicate sending to billing via postmessage
  };
};

const INITIAL_STATE: TState = {
  spawned: false,
  initialised: false,
  visible: false,
  position: null,
  matterId: null,
  unreadConversationDetails: {
    polled: null,
    notified: null,
  },
};

export const reducer = (state = INITIAL_STATE, action?: unknown): TState => {
  if (actionGuards.setInitialised(action)) {
    return {
      ...state,
      initialised: action.payload.value,
    };
  }
  if (actionGuards.setSpawned(action)) {
    return action.payload.value
      ? {
          ...state,
          spawned: true,
        }
      : {
          ...state,
          spawned: false,
          initialised: false,
        };
  }
  if (actionGuards.hideIframe(action)) {
    if (state.matterId === action.payload.matterId) {
      // because of race conditions when switching from tabs we only want to close if it hasn't switched
      return {
        ...state,
        visible: false,
      };
    }
  }
  if (actionGuards.showIframe(action)) {
    return {
      ...state,
      position: action.payload.position || null,
      matterId: action.payload.matterId || null,
      spawned: true,
      visible: true,
    };
  }
  if (actionGuards.setUnreadConvNotify(action)) {
    return {
      ...state,
      unreadConversationDetails: { ...state.unreadConversationDetails, notified: action.payload.value },
    };
  }
  if (actionGuards.setUnreadConvPolling(action)) {
    return {
      ...state,
      unreadConversationDetails: { ...state.unreadConversationDetails, polled: action.payload.value },
    };
  }

  return state;
};

const autoSelectors = autoSelectorFactory<TState>()([
  'matterId',
  'position',
  'spawned',
  'visible',
  'initialised',
  'unreadConversationDetails',
]);

const getUnreadConversationCounts = createSelector(
  autoSelectors.getUnreadConversationDetails,
  (state, matterId?: string) => matterId,
  (data, matterId?: string) => {
    let { polled, notified } = data;

    if (matterId && polled !== null) {
      polled = polled.filter((i) => i.matterId === matterId);
    }

    if (matterId && notified !== null) {
      notified = notified.filter((i) => i.matterId === matterId);
    }

    return {
      polled: polled === null ? null : polled.length,
      notified: notified === null ? null : notified.length,
    };
  },
);

export const selectors = { ...autoSelectors, getUnreadConversationCounts };
