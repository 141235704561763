'use strict';

/**
 * extractFeeSchedule
 *
 * Returns the FeeWise fee schedule to be applied for the passed bankAccountId + region combination.
 *
 * @param {Object} params
 * @param {Object} params.formattedProviderSpecificSettings The amount for the charge.
 * @param {string} params.bankAccountId
 * @param {string} params.region
 * @returns {Object} The resulting fee schedule.
 */
const extractFeeSchedule = () => {
  throw new Error('FeeWise fee calculations are not yet implemented');
};

module.exports = {
  extractFeeSchedule,
};
