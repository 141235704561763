import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query StaffTargetGroupTableData($staffTargetGroupListFilter: StaffTargetGroupListFilter, $sort: ListSort) {
    staffTargetGroupList(filter: $staffTargetGroupListFilter, sort: $sort) {
      totalCount
      results {
        id
        name
        isInactive
      }
    }
  }
`;

const notificationIds = ['WebQueryFeesNotifications.StaffTargetGroupUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const StaffTargetGroupTableData = {
  query,
  notificationIds,
};
