import { getStateIdFromName, regionType } from '@sb-itops/region';
import { TSchemeValuesFor, schemeMappings } from '@sb-itops/region-schemes';

type TGoogleSearchAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

/**
 *
 *
 * @param {TGoogleSearchAddressComponent[]} addressComponents
 * @param {string[]} targetTypes
 *
 * @returns {string} long form of all matching fields, concatenated
 */
const buildGoogleText = (addressComponents: TGoogleSearchAddressComponent[], targetTypes: string[]) =>
  targetTypes.map((target) => addressComponents.find((type) => type.types.includes(target))?.long_name || '').join(' ');

export const mapSearchResultToIntermediateFields = (
  originalTerm: string,
  addressComponents: TGoogleSearchAddressComponent[],
) => {
  const street = buildGoogleText(addressComponents, ['route']).split(' ');
  const streetType = street.length > 1 ? street.pop() : '';
  const streetName = street.join(' ');

  return {
    addressLine1: buildGoogleText(addressComponents, ['street_number', 'route']),
    addressLine2: '',
    buildingLevel: buildGoogleText(addressComponents, ['premise']),
    unitNumber: buildGoogleText(addressComponents, ['subpremise']),
    streetNumber: buildGoogleText(addressComponents, ['street_number']),
    streetName,
    streetType,
    city: buildGoogleText(addressComponents, ['locality']),
    state: buildGoogleText(addressComponents, ['administrative_area_level_1']),
    zipCode: buildGoogleText(addressComponents, ['postal_code']),
    town: buildGoogleText(addressComponents, ['postal_town']),
    ukCounty: buildGoogleText(addressComponents, ['administrative_area_level_2']),
    originalTerm,
  };
};

type TIntermediateFields = ReturnType<typeof mapSearchResultToIntermediateFields>;

const mapAddressFormFieldsForAu = (addressInfo: TIntermediateFields) => ({
  addressLine1: addressInfo.addressLine1 || '',
  addressLine2: addressInfo.addressLine2 || '',
  city: addressInfo.city || '',
  state: getStateIdFromName(addressInfo.state, regionType.AU) || '',
  zipCode: addressInfo.zipCode || '',
  buildingLevel: addressInfo.buildingLevel || '',
  unitNumber: addressInfo.unitNumber || '',
  unitType: '', // this was never returned by the API
  streetNumber: addressInfo.streetNumber || '',
  streetName: addressInfo.streetName || '',
  streetType: addressInfo.streetType || '',
});

const mapAddressFormFieldsForGb = (addressInfo: TIntermediateFields) => {
  let unitNumber = '';
  if (addressInfo.unitNumber && addressInfo.originalTerm) {
    if (addressInfo.originalTerm.toLocaleLowerCase().startsWith('flat')) {
      unitNumber = `Flat ${addressInfo.unitNumber}`;
    } else if (addressInfo.originalTerm.toLocaleLowerCase().startsWith('unit')) {
      unitNumber = `Unit ${addressInfo.unitNumber}`;
    } else {
      unitNumber = addressInfo.unitNumber;
    }
  }

  return {
    city: addressInfo.town || '',
    zipCode: addressInfo.zipCode || '',
    buildingLevel: addressInfo.buildingLevel || '',
    unitNumber,
    // state is generally not used in GB but needed for firm details
    // SB did it different and unlike AU and UK state is a word here, not code
    // this actually causes state to be set in contacts which is also what the desktop does
    // ¯\_(ツ)_/¯
    state: addressInfo.state,
    streetNumber: addressInfo.streetNumber || '',
    streetName: `${addressInfo.streetName || ''} ${addressInfo.streetType || ''}`.trim(),
    county: addressInfo.ukCounty || '',
    locality: addressInfo.city || '',
  };
};

const mapAddressFormFieldsForUs = (addressInfo: TIntermediateFields) => ({
  addressLine1: addressInfo.addressLine1 || '',
  addressLine2: addressInfo.addressLine2 || '',
  city: addressInfo.city || '',
  state: getStateIdFromName(addressInfo.state, regionType.US) || '',
  zipCode: addressInfo.zipCode || '',
  // buildingLevel, streetNumber, streetName etc map to AU && GB Detailed Addresses which are not supported in the US
});

/**
 * Return a formatted address, like what you could stick on an envelope
 *
 * @param {TAddress} address
 * @param {*} region
 * @return {Record<string, string>}
 */
export const mapAddressFormFields = (
  addressInfo: TIntermediateFields,
  addressScheme: TSchemeValuesFor<'addressScheme'>,
) => {
  switch (addressScheme) {
    case schemeMappings.addressScheme.AU:
      return mapAddressFormFieldsForAu(addressInfo);
    case schemeMappings.addressScheme.US:
      return mapAddressFormFieldsForUs(addressInfo);
    case schemeMappings.addressScheme.GB:
      return mapAddressFormFieldsForGb(addressInfo);
    default:
      throw new Error(`Unimplemented addressScheme ${addressScheme}`);
  }
};
