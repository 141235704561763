import {
  SET_TASK_VIEW,
  TOGGLE_EXPANDED,
  SET_SORT,
  TaskViewTypes,
  SET_SHOW_DELETED,
  SET_ASSIGNEE_IDS,
  SET_CATEGORY_IDS,
  TOGGLE_VIEW_EXPANDED,
  TOGGLE_ASSIGNEE_FILTER_EXPANDED,
  TOGGLE_CATEGORY_FILTER_EXPANDED,
} from './types';

const INITIAL_FILTER_STATE = {
  taskView: 'ALL' as TaskViewTypes,
  expanded: true,
  sortBy: 'dueDate',
  sortDirection: 'asc',
  showDeleted: false,
  assigneeIds: [],
  categoryIds: [],
  assigneeFilterExpanded: true,
  categoryFilterExpanded: true,
  viewExpanded: true,
};

const VIEW_TO_SORT_MAPPING: { [key in TaskViewTypes]: string } = {
  ALL: 'dueDate',
  DUE_TODAY: 'dueDate',
  DUE_THIS_WEEK: 'dueDate',
  DUE_LATER: 'dueDate',
  OVERDUE: 'dueDate',
  RECENTLY_COMPLETED: 'completionDate',
  ALL_COMPLETED: 'completionDate',
};

const VIEW_TO_SORT_DIRECTION_MAPPING: { [key in TaskViewTypes]: string } = {
  ALL: 'asc',
  DUE_TODAY: 'asc',
  DUE_THIS_WEEK: 'asc',
  DUE_LATER: 'asc',
  OVERDUE: 'asc',
  RECENTLY_COMPLETED: 'desc',
  ALL_COMPLETED: 'desc',
};

export const reducer = (state = INITIAL_FILTER_STATE, action: { payload?: any; type?: string } = {}) => {
  switch (action.type) {
    case SET_TASK_VIEW: {
      // Also set the sort to the default for that view
      return {
        ...state,
        taskView: action.payload.taskView,
        sortBy: VIEW_TO_SORT_MAPPING[action.payload.taskView] || INITIAL_FILTER_STATE.sortBy,
        sortDirection: VIEW_TO_SORT_DIRECTION_MAPPING[action.payload.taskView] || INITIAL_FILTER_STATE.sortDirection,
      };
    }
    case TOGGLE_EXPANDED: {
      return {
        ...state,
        expanded: !state.expanded,
      };
    }
    case TOGGLE_VIEW_EXPANDED: {
      return {
        ...state,
        viewExpanded: !state.viewExpanded,
      };
    }
    case TOGGLE_ASSIGNEE_FILTER_EXPANDED: {
      return {
        ...state,
        assigneeFilterExpanded: !state.assigneeFilterExpanded,
      };
    }
    case TOGGLE_CATEGORY_FILTER_EXPANDED: {
      return {
        ...state,
        categoryFilterExpanded: !state.categoryFilterExpanded,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case SET_SHOW_DELETED: {
      return {
        ...state,
        showDeleted: action.payload.showDeleted,
      };
    }
    case SET_ASSIGNEE_IDS: {
      return {
        ...state,
        assigneeIds: action.payload.assigneeIds,
      };
    }
    case SET_CATEGORY_IDS: {
      return {
        ...state,
        categoryIds: action.payload.categoryIds,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
