import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import classNames from 'classnames';

const FinancialSummaryItem = memo(({ name, show, total, onClick, redText, tooltip }) => {
  const { t } = useTranslation();
  return (
    show && (
      <div className={classNames({ 'red-text': redText, 'financial-summary-item': true })}>
        <div
          name={name}
          className={classNames({ 'financial-summary-total': true, 'has-link': !!onClick, 'hover-tooltip': !!tooltip })}
          onClick={onClick}
        >
          <div className="total-value" total={total}>
            {t('cents', { val: total })}
          </div>
          <div className="total-name">{name}</div>
          {tooltip}
        </div>
      </div>
    )
  );
});

FinancialSummaryItem.displayName = 'FinancialSummaryItem';

FinancialSummaryItem.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.element,
  onClick: PropTypes.func,
  total: PropTypes.number.isRequired,
  show: PropTypes.bool,
  redText: PropTypes.bool,
};

FinancialSummaryItem.defaultProps = { tooltip: undefined, show: true, redText: false, onClick: undefined };

export default FinancialSummaryItem;
