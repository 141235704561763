angular.module('sb.billing.webapp').directive('sbGenericList', function() {
  'use strict';
  return {
    restrict: 'E',
    replace: true,
    transclude: {
      headers: '?headers',
      rows: '?rows',
      footer: '?footer'
    },
    scope: { provider: '<', config: '<', tableClass: '@?', onSelectRow: '<', onSelectAll: '<', allSelected: '<', onCollapseAll: '<', allCollapsed: '<'},
    controller: 'SbGenericListController',
    controllerAs: 'genericListCtrl',
    templateUrl: 'ng-components/generic-list/generic-list.html'
  };
});

angular.module('sb.billing.webapp').controller('SbGenericListController', function ($scope, $timeout, sbUnsavedChangesService) {
  'use strict';

  const that = this;

  that.selectedRows = {};
  that.allSelected = false;

  that.applySort = applySort;
  that.toggleSelectAll = toggleSelectAll;
  that.toggleSelectedRow = toggleSelectedRow;
  that.isRowSelected = isRowSelected;

  /**
   * Sets up default values and initialises the data source for the list.
   * Also subscribes for changes on the underlying data source.
   */
  that.$onInit = function() {
    // Apply default values.
    that.type = ($scope.config.type === 'static') ? 'static': 'infinite';
    that.currentSortColumn = {};

    that.datasource = $scope.provider.getData(that.type);

    // Default state when none is available from the sbUnsavedChangesService.
    var defaultState = {
      scrollPosition: 1,
      sortColumn: {}
    };

    // Load any saved state.
    that.stateMemory = _.merge(defaultState, sbUnsavedChangesService.loadMemory($scope.config.stateKey) || {});

    // Register a callback with the provider so we get notified of changes to the underlying data structure.
    $scope.provider.onUpdateData(reloadList);

    //NOTE this is a directive...not a component
    //so bindings must be manually moved from scope to controller as
    if ($scope.onCollapseAll) {
      that.toggleCollapseAll = $scope.onCollapseAll; 
    }
  };

  //NOTE this is a directive...not a component
  //so bindings must be manually moved from scope to controller as
  $scope.$watch('allSelected', function(newValue) {
    that.allSelected = newValue;
  });
  $scope.$watch('allCollapsed', function(newValue) {
    that.allCollapsed = newValue;
  });

  /**
   * Used to save the state for the next load of this list.
   */
  that.$onDestroy = function() {
    var stateToSave = {
      scrollPosition: _.get(that, 'clUiScrollAdapter.topVisibleScope.$index', 1),
      sortColumn: that.currentSortColumn
    };

    sbUnsavedChangesService.saveMemory($scope.config.stateKey, stateToSave);
  };

  /**
   * After all the children have linked apply the default sort if present.
   * Also, if we are in infinite mode we need to push a reload into the event queue.
   */
  that.$postLink = function() {
    if (!_.isEmpty(that.stateMemory.sortColumn)) {
      var sortColumn =  _.find($scope.config.columns, {'label': that.stateMemory.sortColumn.label});
      applySort(_.defaults(sortColumn, that.stateMemory.sortColumn), true);
    }
    else {
      var defaultSortColumn = _.find($scope.config.columns, {'defaultSort': true});
      if (!_.isEmpty(defaultSortColumn)) {
        applySort(defaultSortColumn);
      }
    }

    $scope.provider.onUpdateData(() => {
      // if we are managing checkboxes outside this component then
      // don't mess with any selections
      if (!$scope.config.checkbox) {
        return;
      }

      const ids = _.map($scope.provider.getData('static'), $scope.config.itemIdPath);
      const selected = Object
        .entries(that.selectedRows)
        .filter(([, value]) => value)
        .map(([key]) => key);

      const remaining = _.intersection(selected, ids);

      that.selectedRows = {};
      remaining.forEach((id) => {
        that.selectedRows[id] = true;
      });

      that.allSelected = remaining.length === ids.length;

      sendSelectedRows();
    });

    $timeout($scope.provider.startRefreshingData.bind($scope.provider));
  };

  /**
   * Triggered when the underlying data provider's data has changed.
   */
  function reloadList() {
    switch (that.type) {
      case 'infinite':
        // Our current version of ui scroll has an issue where the adapter may not have been bound even after postLink.
        // We will update versions soon and hopefully the issue will go away.
        if (that.clUiScrollAdapter) {
          that.clUiScrollAdapter.reload(that.stateMemory.scrollPosition);
          that.stateMemory.scrollPosition = 1;
        }
        break;

      case 'static':
        that.datasource = $scope.provider.getData('static');
        break;
    }
  }

  /**
   * Applies a sort function to the underlying data provider.
   * @param columnDef The column which is the source of the sort request (i.e. click occurred on column header).
   */
  function applySort(columnDef, isSavedSort) {
    // Don't do anything if sorting is not enabled on the passed column.
    if (!columnDef.sort) {
      return;
    }

    // Toggle the sort order if required.
    if (!isSavedSort) {
      if (!columnDef.toggleSortDisabled && !_.isEmpty(columnDef.sortOrder)) {
        columnDef.sortOrder = (columnDef.sortOrder === 'asc') ? 'desc' : 'asc';
      }
      else {
        columnDef.sortOrder = columnDef.defaultSortOrder || 'asc';
      }
    }

    // If sort is a function, bind in the sort order and send it directly to the provider.
    if (_.isFunction(columnDef.sort)) {
      $scope.provider.setSorter(function (data) {
        return columnDef.sort(data, columnDef.sortOrder);
      });
    }

    // Otherwise, check if sort is a string representing a property and use the default sorter.
    else if (_.isString(columnDef.sort)) {
      // Default sorting function simply assumes that sort contains a string representing a property in the data items.
      var defaultSortFn = function(dataSet) {
        var iteratee = function(dataItem) {
          const columnValue = _.get(dataItem, columnDef.sort);
          return _.isString(columnValue) ? columnValue.toLowerCase() : columnValue;
        };

        return _.sortByOrder(dataSet, iteratee, [columnDef.sortOrder || 'asc']);
      };

      $scope.provider.setSorter(defaultSortFn);
    }

    if (!isSavedSort) {
      // Nuke the sort style applied to the last sorted column, add a new sort style to the new sort column.
      that.currentSortColumn.sortStyle = '';
      that.currentSortColumn = columnDef;
      that.currentSortColumn.sortStyle = (columnDef.sortOrder !== '') ? 'sorted-' + columnDef.sortOrder : '';
    }
    else {
      that.currentSortColumn = columnDef;
    }
  }

  function isRowSelected (item) {
    const key = _.get(item, $scope.config.itemIdPath) || item.id;
    return !!that.selectedRows[key];
  }

  // 'checkbox' config indicates that we need to maintain selected rows inside this component.
  // 'allcheckbox' config indicates that we want to display the ALL checkbox
  // and that we want to allow selecting all rows via the function below.
  // There are cases where we want to display the ALL checkbox but we want to manage
  // the selection code outside this component. In that case checkbox = false and allcheckbox = true.
  function toggleSelectAll () {

    if(_.isFunction($scope.onSelectAll)) {
      $scope.onSelectAll();
    }

    if (!$scope.config.checkbox && $scope.config.allcheckbox) {
      return;
    }

    that.allSelected = !that.allSelected;
    _.map($scope.provider.getData('static'), $scope.config.itemIdPath).forEach((key) => that.selectedRows[key] = that.allSelected);
    sendSelectedRows();
  }

  function updateAllSelected() {
    const items = $scope.provider.getData('static');
    that.allSelected = items.every(item => {
      const key = _.get(item, $scope.config.itemIdPath) || item.id;
      return that.selectedRows[key];
    });
  }

  function toggleSelectedRow (item) {

    if (!$scope.config.checkbox) {
      return;
    }

    const key = _.get(item, $scope.config.itemIdPath) || item.id;
    that.selectedRows[key] = !that.selectedRows[key];
    sendSelectedRows();
    updateAllSelected();
  }

  function sendSelectedRows () {
    const selected = Object
      .entries(that.selectedRows)
      .filter(([, value]) => value)
      .map(([key]) => key);

    if(_.isFunction($scope.onSelectRow)) {
      $scope.onSelectRow(selected);
    }
  }
});
