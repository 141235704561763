import {
  findLastUpdatedChequeNumber as _findLastUpdatedChequeNumber,
  getNextChequeNumberByLastUpdated as _getNextChequeNumberByLastUpdated,

} from '@sb-billing/business-logic/cheques';

const findLastChequeNumber = (cheques) => _findLastUpdatedChequeNumber(cheques);
const getNextChequeNumber = (cheques) => _getNextChequeNumberByLastUpdated(cheques);

export {
  findLastChequeNumber,
  getNextChequeNumber,
}
