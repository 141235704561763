import React from 'react';
import classnames from 'classnames';
import Styles from './ActionList.module.scss';

const ActionList = (props) => {
  const { actionList, noOptions } = props;

  return (
    <>
      {props.children}
      {actionList.map((action, index) => (
        <div
          onClick={action.callback}
          key={index}
          className={classnames(Styles.actionListItem, noOptions ? Styles.noOptions : '')}
        >
          {action.displayComponent}
        </div>
      ))}
    </>
  );
};

export default ActionList;
