'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const staffTargetType = Object.freeze({
  BILLABLE_HOURS: 0,
  BILLABLE_VALUE: 1,
  WORKED_HOURS: 2,
  WORKED_VALUE: 3,
  BILLED_HOURS: 4,
  BILLED_VALUE: 5,
  COLLECTED_VALUE: 6,
});

const staffTargetTypeLabels = Object.freeze({
  BILLABLE_HOURS: 'Billable Hours',
  BILLABLE_VALUE: 'Billable Value',
  WORKED_HOURS: 'Worked Hours',
  WORKED_VALUE: 'Worked Value',
  BILLED_HOURS: 'Billed Hours',
  BILLED_VALUE: 'Billed Value',
  COLLECTED_VALUE: 'Collected Value',
});

const staffTargetTypeOptions = getTypeAsOptions({
  typeLabels: staffTargetTypeLabels,
  typeValues: staffTargetType,
});

module.exports = {
  staffTargetType,
  staffTargetTypeLabels,
  staffTargetTypeOptions,
};
