import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getList as getPaymentPlansList, getById as getPaymentPlanById } from '@sb-billing/redux/payment-plans';
import {
  findActivePaymentPlanByDebtorId,
  findPaymentPlansByInvoiceIds,
  findActivePaymentPlanByMatterId,
} from '@sb-billing/redux/payment-plans/selectors';
import {
  calculatePaymentPlanPosition,
  formatPaymentPlanDescription,
} from '@sb-billing/business-logic/payment-plan/services';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { getPaymentPlanEventsListById } from '@sb-billing/redux/payment-plan-events/selectors';
import { getContactPaymentMethodById } from '@sb-billing/redux/customer-billing-configuration';
import PaymentPlanIndicator from './PaymentPlanIndicator';

export const entityTypes = {
  INVOICE: 'invoice',
  PAYMENT_PLAN: 'payment-plan',
  CONTACT: 'contact',
  MATTER: 'matter',
};

/**
 * @deprecated to be deprecated, please use monorepo/apps/smokeball-billing-web/src/components/payment-plan-indicator/PaymentPlanIndicator.jsx
 */
const mapStateToProps = (state, { entityId, entityType, onClickLink, t }) => {
  let paymentPlan;
  const paymentPlanIndicatorProps = {};

  switch (entityType) {
    case entityTypes.PAYMENT_PLAN: {
      paymentPlan = entityId && getPaymentPlanById(entityId);
      break;
    }
    case entityTypes.INVOICE: {
      const paymentPlanResult =
        entityId &&
        findPaymentPlansByInvoiceIds(getPaymentPlansList(), {
          invoiceIds: [entityId],
          status: paymentPlanStatuses.ACTIVE,
        });

      // this will always be only one payment plan
      paymentPlan = paymentPlanResult && paymentPlanResult[0];
      break;
    }
    case entityTypes.CONTACT: {
      paymentPlan =
        entityId &&
        findActivePaymentPlanByDebtorId(getPaymentPlansList(), {
          debtorId: entityId,
        });

      break;
    }
    case entityTypes.MATTER: {
      paymentPlan =
        entityId &&
        findActivePaymentPlanByMatterId(getPaymentPlansList(), {
          matterId: entityId,
        });

      break;
    }
    default:
      throw new Error(`UNKNOWN entity ${entityType}`);
  }

  paymentPlanIndicatorProps.displayIndicator = !!paymentPlan && paymentPlan.status === paymentPlanStatuses.ACTIVE;
  paymentPlanIndicatorProps.tooltipMessage = formatPaymentPlanDescription(paymentPlan, t);

  if (paymentPlan && !paymentPlan.$optimistic) {
    const { status, paymentMethod } = calculatePaymentPlanPosition({
      paymentPlan,
      expiry: getContactPaymentMethodById({ contactId: entityId, paymentMethodId: paymentPlan.paymentMethodId })
        ?.expiry,
      paymentPlanEvents: getPaymentPlanEventsListById(paymentPlan.id),
    });
    paymentPlanIndicatorProps.status = status;
    if (paymentPlan.autoCharge) {
      paymentPlanIndicatorProps.paymentMethodStatusDisplay = paymentMethod.statusDisplay;
      paymentPlanIndicatorProps.paymentMethodStatus = paymentMethod.status;
      paymentPlanIndicatorProps.autoCharge = paymentPlan.autoCharge;
    }
  }

  paymentPlanIndicatorProps.onClickLink = () => onClickLink({ type: 'paymentPlan', id: paymentPlan?.debtorId });

  return paymentPlanIndicatorProps;
};
const PaymentPlanIndicatorContainer = withTranslation()(withReduxStore(connect(mapStateToProps)(PaymentPlanIndicator)));

PaymentPlanIndicatorContainer.displayName = 'PaymentPlanIndicatorContainer';

PaymentPlanIndicatorContainer.propTypes = {
  entityId: PropTypes.string,
  entityType: PropTypes.oneOf(Object.values(entityTypes)),
  entityTooltipName: PropTypes.string,
  showWarnings: PropTypes.bool,
  onClickLink: PropTypes.func,
};

PaymentPlanIndicatorContainer.defaultProps = {
  entityId: undefined,
  entityType: undefined,
  entityTooltipName: undefined,
  showWarnings: true,
  onClickLink: () => {},
};

export default PaymentPlanIndicatorContainer;
