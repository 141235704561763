import { featureActive } from '@sb-itops/feature';
import { fetchGraphqlData } from 'web/services/network';
import { store } from '@sb-itops/redux';
import { types as appStateTypes, actions as appStateActions } from 'web/redux/features/application-state';
import { getUserId } from 'web/services/user-session-management';

export class NoTwoFactorXeroUserError extends Error {
  constructor(message) {
    super(message);

    this.name = this.constructor.name;
  }
}

const fetch2faSettingsAndXeroTokenForUser = async ({ userId }) => {
  const gqlQuery = [
    {
      operationName: 'Fetch2faSettingsAndXeroTokenForUser',
      query: `query Fetch2faSettingsAndXeroTokenForUser($id: ID!) {
      loggedInStaff {
        id
      }
      xeroAccessToken {
        id
      }
      userTwoFactorStatus(id: $id) {
        id
        twoFactorEnabled
      }
    }`,
      variables: { id: userId },
    },
  ];

  const { data } = await fetchGraphqlData(gqlQuery);

  return {
    isStaffMember: !!(data.loggedInStaff && data.loggedInStaff.id),
    hasXeroConnected: !!(data.xeroAccessToken && data.xeroAccessToken.id),
    hasTwoFactorEnabled: !!(data.userTwoFactorStatus && data.userTwoFactorStatus.twoFactorEnabled),
  };
};

export const checkNo2faXeroUserP = async ({ log, defaultTeardown }) => {
  if (!featureActive('BB-13236')) {
    return defaultTeardown;
  }

  let hasXeroConnected;
  let hasTwoFactorEnabled;
  let isStaffMember;

  try {
    const userId = getUserId();
    const settings = await fetch2faSettingsAndXeroTokenForUser({ userId });
    hasXeroConnected = settings.hasXeroConnected;
    hasTwoFactorEnabled = settings.hasTwoFactorEnabled;
    isStaffMember = settings.isStaffMember;
  } catch (err) {
    // do something here
    log.warn(`Failed to fetch resources ${err}`);
    throw err;
  }

  // We want this only for staff members in order to allow setup accounts login
  if (isStaffMember && hasXeroConnected && !hasTwoFactorEnabled) {
    // set loginBlockedReason so next time we are on login screen, we see the notice instead of login form
    store.dispatch(
      appStateActions.setLoginBlockedReason({ reason: appStateTypes.loginBlockedReasons.NO_2FA_XERO_USER }),
    );
    // throw to cause logout
    throw new NoTwoFactorXeroUserError('2FA is required for the user');
  }

  return defaultTeardown;
};
