angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider.state('home.billing.deposit-receipt', {
    url: '/deposit-receipt/:transactionId/:bulkDepositId',
    template: `<sb-deposit-receipt-view style="width:100%"></sb-deposit-receipt-view>`,
    data: {
      authorized: true,
      navHighlights: ['billing', 'accounts', 'deposit', 'receipt'],
      tab: { type: 'deposit-receipt' },
    },
    params: {
      bulkDepositId: { value: null, squash: true },
    },
  });
});
