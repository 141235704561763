import { SET_EXPORT_STATE } from './types';

const INITIAL_STATE = {
  operatingCheques: {
    reportGenerationInProgress: false,
  },
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_EXPORT_STATE: {
      const { reportGenerationInProgress } = action.payload;
      return {
        ...state,
        operatingCheques: {
          ...state.operatingCheques,
          reportGenerationInProgress,
        },
      };
    }
    default: {
      return state;
    }
  }
};
