import { updateCache as updateRedux } from '@sb-finance/redux/subscription';

angular
  .module('@sb-finance/services')
  .service('sbSubscriptionService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    sbGenericCacheService.setupCache({
      name: 'sbSubscriptionService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'finance/subscription' },
        poll: 60,
        subscriptions: 'notifier.SubscriptionNotifications.SubscriptionUpdated',
      },
      updateRedux,
    });
  });
