/* eslint-disable prettier/prettier */
import React from "react";
import PropTypes from "prop-types";
import { PaymentProviderChargeForm } from "@sb-billing/payment-provider-charge-form";
import Styles from "./CreditCardSaveForm.module.scss";

export const CreditCardSaveForm = (props) => <div className={Styles.modalBody}>
    <div className={Styles.descriptionCell}>
      <label>Description (internal only)</label>
      <input
        name="cardDescription"
        type="text"
        className="form-control"
        value={props.description}
        onChange={(event) => props.setDescription(event.target.value)}
      />
    </div>
    <PaymentProviderChargeForm
      providerType={props.providerType}
      triggerSubmit={props.triggerSubmit}
      isSubmitting={props.isSubmitting}
      paymentFormConfiguration={props.paymentFormConfiguration}
      onSubmit={props.onSubmit}
      onReadyForSubmit={props.onReadyForSubmit}
      onPreSubmitChange={props.onPreSubmitChange}
      onPreCharge={props.onPreCharge}
    />
  </div>

CreditCardSaveForm.displayName = "CreditCardSaveForm";

CreditCardSaveForm.propTypes = {
  description: PropTypes.string,
  setDescription: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  providerType: PropTypes.string.isRequired,
  paymentFormConfiguration: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onPreCharge: PropTypes.func.isRequired,
  // required for PaymentProviderChargeForm but not needed for card saving
  onPreSubmitChange: PropTypes.func,
};

CreditCardSaveForm.defaultProps = {
  description: '',
  isSubmitting: false,
  triggerSubmit: false,
  onPreSubmitChange: () => {},
};
