import { sort as sortItems, sortDirections } from '@sb-itops/sort';

const sortInvoiceReminders = ({remindersAndInvoices, sortBy, sortDirection}) => {
  // For code simplicity, we remove sort on reminder rows only. After the sort is complete, we will re-expand any previously expanded reminders.
  const reminders = [];
  const invoices = {};

  remindersAndInvoices.forEach(reminderOrInvoice => {
    if (reminderOrInvoice.type === 'REMINDER') {
      reminders.push(reminderOrInvoice);
      return;
    }

    const invoice = reminderOrInvoice;
    if (!invoices[invoice.matterDebtorId]) {
      invoices[invoice.matterDebtorId] = [];
    }

    invoices[invoice.matterDebtorId].push(invoice);
  });

  // Now we sort the reminders.
  const sortOrder = sortDirection === sortDirections.ASC ? 'asc' : 'desc';
  let sortedReminders;

  switch (sortBy) {
    case 'debtor': sortedReminders = sortItems(reminders, [reminder => _.get(reminder, 'debtorDisplay', '')], [sortOrder]); break;
    case 'matter': sortedReminders = sortItems(reminders, [reminder => _.get(reminder, 'matterDisplay', '')], [sortOrder]); break;
    case 'overdueInvoices': sortedReminders = sortItems(reminders, [reminder => _.get(reminder, 'invoices.length')], [sortOrder]); break;
    default: sortedReminders = sortItems(reminders, [sortBy], [sortOrder]);
  }

  // At this point the reminders are sorted, add back in their sorted invoices.
  return sortedReminders.reduce((sortedRemindersAndInvoices, reminder) => {
    sortedRemindersAndInvoices.push(reminder);

    if (invoices[reminder.matterDebtorId]) {
      sortedRemindersAndInvoices.splice(sortedRemindersAndInvoices.length, 0, ...(_.sortByOrder(invoices[reminder.matterDebtorId], ['invoiceNumber'], 'asc')));
    }
    return sortedRemindersAndInvoices;
  }, []);
}

export default sortInvoiceReminders;
