import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { WarningList } from '@sb-itops/react/warning-list';
import { getById as getBankAccountById, ACCOUNT_TYPE, getTrustAccounts } from '@sb-billing/redux/bank-account';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';

const { getBankAccountBalanceById } = selectors;

const mapStateToProps = (state, { matterId, amount, bankAccountId, t }) => {
  if (!hasFacet(facets.allowOverdraw) || !matterId || !amount) {
    return {};
  }

  let warningText = '';
  const bankAccount = getBankAccountById(bankAccountId);

  if (bankAccount.accountType === ACCOUNT_TYPE.controlled_money.toUpperCase()) {
    const cmaBalance = getBankAccountBalanceById(getBankAccountBalanceState(), { bankAccountId })?.balance || 0;
    const cmaBalanceAfterReversal = cmaBalance - amount;
    const isNegativeCmaBalanceAfterReversal = cmaBalanceAfterReversal < 0;

    warningText =
      isNegativeCmaBalanceAfterReversal &&
      `Warning: As of the transaction date, the ${t(
        'controlledMoneyAccount',
      ).toLowerCase()} will be overdrawn to a balance of ${t('cents', { val: cmaBalanceAfterReversal })}`;
  } else {
    const matterTrustBalance = getMatterBalance(getBankAccountBalanceState(), {
      bankAccountId,
      matterId,
    });
    const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);
    const isStatutoryMatter = isStatutoryDepositMatter({
      matterId,
      trustBankAccounts: getTrustAccounts(),
      supportsStatutoryDepositMatter,
    });
    const trustBalanceAfterReversal = matterTrustBalance - amount;
    const isNegativeTrustBalanceAfterReversal = trustBalanceAfterReversal < 0 && !isStatutoryMatter;
    const trustBalanceAfterReversalDisplayText = t('cents', { val: trustBalanceAfterReversal });

    warningText =
      isNegativeTrustBalanceAfterReversal &&
      `Warning: As of the transaction date, the ${t(
        'trustAccount',
      ).toLowerCase()} will be overdrawn to a balance of ${trustBalanceAfterReversalDisplayText}`;
  }

  return {
    list: warningText ? [{ text: warningText }] : undefined,
  };
};

const NonPaymentTransactionReversalWarningContainer = withTranslation()(
  withReduxStore(connect(mapStateToProps, null)(WarningList)),
);

NonPaymentTransactionReversalWarningContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  bankAccountId: PropTypes.string.isRequired,
};

export default NonPaymentTransactionReversalWarningContainer;
