import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import * as remindersFilters from 'web/redux/route/home-billing-bills-account-reminders';
import PropTypes from 'prop-types';
import RemindersFilterPanel from './RemindersFilterPanel';

const mapStateToProps = (state) => {
  const { getShowFilters, getFilters, getHideFilters } = remindersFilters.selectors;
  const expanded = getShowFilters(state);
  const filters = getFilters(state);
  const hideFilters = getHideFilters(state);
  return { expanded, hideFilters, ...filters, visible: true };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(remindersFilters.actions.toggleShowFilters()),
  resetFilters: () => dispatch(remindersFilters.actions.resetFilters()),
  setFilter: (key, value) => dispatch(remindersFilters.actions.setFilter(key, value)),
  onFilterChanged: ({ field, value }) => dispatch(remindersFilters.actions.setFilter(field, value)),
  onChangeOverdueAmount: (value) => dispatch(remindersFilters.actions.setFilter('overdueAmount', value)),
  onSelectMatterStatusFilter: (value) =>
    dispatch(remindersFilters.actions.setFilter('matterFilterStatusSelected', value)),
  onSelectSendPreferencesFilter: (value) =>
    dispatch(remindersFilters.actions.setFilter('sendPreferencesSelected', value)),
  onSelectMatterTypeFilter: (values) =>
    dispatch(remindersFilters.actions.setFilter('matterTypeFilterSelected', values)),
  onOverdueByFilterChange: (value) => dispatch(remindersFilters.actions.setFilter('overdueByFilterSelected', value)),
  onResetApplied: (value) => dispatch(remindersFilters.actions.setFilter('reset', value)),
  toggleHideFilters: (value) => dispatch(remindersFilters.actions.toggleHideFilters(value)),
});

const RemindersFilterPanelContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(RemindersFilterPanel),
);

RemindersFilterPanelContainer.propTypes = {
  matterStatusFilterOptions: PropTypes.array.isRequired,
  overdueByFilterOptions: PropTypes.array.isRequired,
  sendPreferencesFilterOptions: PropTypes.array.isRequired,
};

RemindersFilterPanelContainer.defaultProps = {};

export default RemindersFilterPanelContainer;
