class FetchError extends Error {
  constructor({ url, status, statusText, body }) {
    super(`request to ${url} failed: status(${status}), statusText(${statusText})`);

    this.name = this.constructor.name;
    this.url = url;
    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

module.exports = {
  FetchError,
};
