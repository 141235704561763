import React from 'react';
import PropTypes from 'prop-types';

const types = {
  Organisation: 'company',
  Trust: 'trust',
  Person: 'single',
  Staff: 'single',
  GroupOfPeople: 'group',
  organisation: 'company',
  trust: 'trust',
  person: 'single',
  staff: 'single',
  groupOfPeople: 'group',
};

const ContactTypeIcon = ({ contactType }) => {
  const classNameIcon = `contact-type-icon ${types[contactType] || ''}`;
  return <i className={classNameIcon} />;
};

ContactTypeIcon.propTypes = {
  contactType: PropTypes.oneOf([
    'Organisation',
    'Trust',
    'Person',
    'Staff',
    'GroupOfPeople',
    'organisation',
    'trust',
    'person',
    'staff',
    'groupOfPeople',
  ]),
};

ContactTypeIcon.defaultProps = {
  contactType: 'Trust',
};

export default ContactTypeIcon;
