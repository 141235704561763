import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const CardContainer = ({ children }) => <div className={classnames('card-container')}>{children}</div>;

CardContainer.displayName = 'CardContainer';

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

CardContainer.defaultProps = {};
