import { startOfThisMonth, endOfThisMonth, today, dateToInteger as toEffectiveDate } from '@sb-itops/date';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { CORRESPONDENCE_PSEUDO_STATUS, sentViaTypes } from '@sb-billing/business-logic/correspondence-history';

import { TOGGLE_SHOW_FILTERS, RESET_FILTER, SET_FILTER, TOGGLE_HIDE_FILTER } from './types';

const INITIAL_FILTER_STATE = {
  showFilters: true,
  filters: {
    recent: false,
    issueDate: {
      selectedFilter: 'ALL',
      before: toEffectiveDate(today()),
      from: toEffectiveDate(startOfThisMonth()),
      to: toEffectiveDate(endOfThisMonth()),
      startDate: undefined,
      endDate: undefined,
    },
    finalizedOn: {
      selectedFilter: 'ALL',
      before: toEffectiveDate(today()),
      from: toEffectiveDate(startOfThisMonth()),
      to: toEffectiveDate(endOfThisMonth()),
      startDate: undefined,
      endDate: undefined,
    },
    invoiceStatuses: ['DRAFT'],
    sentStatuses: [
      CORRESPONDENCE_PSEUDO_STATUS.sent,
      CORRESPONDENCE_PSEUDO_STATUS.failed,
      CORRESPONDENCE_PSEUDO_STATUS.notSent,
    ],
    sendPreferences: [
      // option values are strings because they are used as keys in the filterOptions object
      String(sentViaTypes.EMAIL),
      String(sentViaTypes.COMMUNICATE),
      String(sentViaTypes.MAIL),
      ...(hasFacet(facets.eBillingSendingPreference) ? [String(sentViaTypes.E_BILLING)] : []),
      String(sentViaTypes.OTHER),
    ],
    includeStatuses: [CORRESPONDENCE_PSEUDO_STATUS.inProgress],
    billingEvents: [],
    contactId: '',
    billingFrequencySubTypes: [],
  },
  hideFilters: {
    invoiceStatus: false,
    issueDate: false,
    sentStatus: false,
    sendPreference: false,
    billingEvents: false,
  },
};

export const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case RESET_FILTER: {
      return {
        ...state,
        filters: { ...INITIAL_FILTER_STATE.filters, contactId: state.filters.contactId },
      };
    }
    case SET_FILTER: {
      const result = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
      return result;
    }
    case TOGGLE_HIDE_FILTER: {
      return {
        ...state,
        hideFilters: {
          ...state.hideFilters,
          [action.payload.filterName]: !state.hideFilters[action.payload.filterName],
        },
      };
    }
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
