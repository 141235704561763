'use strict';

const { CORRESPONDENCE_STATUS } = require('./correspondence-status');
const { CORRESPONDENCE_PSEUDO_STATUS } = require('./correspondence-pseudo-status');
const {
  operationTypes,
  operationTypeLabels,
  operationTypeMap,
  operationTypeLabelToValueMap,
} = require('./operation-type');
const {
  sentViaTypes,
  sentViaTypeLabels,
  sentViaMap,
  sentViaLabelToValueMap,
  getSentViaOptions,
  externalSentViaTypes,
  externalSentViaTypeLabels,
  externallySentViaMap,
  externallySentViaLabelToValueMap,
  getExternalSentViaOptions,
} = require('./sent-via-type');

const { buildClientCommunicationsHistory } = require('./build-client-communications-history');
const { sendStatuses } = require('./send-statuses');
const { indicatorStatuses } = require('./indicator-statuses');
const { determineIndicatorStatus } = require('./determine-indicator-status');
const { determineSendStatus } = require('./determine-send-status');
const { getSentViaText } = require('./get-sent-via-text');
const { isCorrespondenceHistoryRelatedToMatter } = require('./is-correspondence-history-related-to-matter');
const { getLastCorrespondenceHistoryForReminders } = require('./get-last-correspondence-history-for-reminders');
const { relatedItemType } = require('./related-item-type');

module.exports = {
  buildClientCommunicationsHistory,
  CORRESPONDENCE_STATUS,
  CORRESPONDENCE_PSEUDO_STATUS,
  sentViaTypes,
  sentViaTypeLabels,
  sentViaMap,
  sentViaLabelToValueMap,
  getSentViaOptions,
  externalSentViaTypes,
  externalSentViaTypeLabels,
  externallySentViaMap,
  externallySentViaLabelToValueMap,
  getExternalSentViaOptions,
  operationTypes,
  operationTypeLabels,
  operationTypeMap,
  operationTypeLabelToValueMap,
  sendStatuses,
  indicatorStatuses,
  determineIndicatorStatus,
  determineSendStatus,
  getSentViaText,
  isCorrespondenceHistoryRelatedToMatter,
  getLastCorrespondenceHistoryForReminders,
  relatedItemType,
};
