/* eslint-disable import/no-cycle */
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPutP } from '@sb-itops/redux/fetch';
import { fetchById as fetchContactById } from './index';
import { generateContactSummaryOpdate, generateContactEntityOpdate } from './create-contact';

// Contact summary cache
const { opdateCache: opdateSummaryCache, rollbackOpdateCache: rollbackSummaryOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

// Contact entity cache (includes address)
// Yes it's called the 'simple' service, it is actually the more complex one
const { opdateCache: opdateEntityCache, rollbackOpdateCache: rollbackEntityOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbSimpleContactMbService',
  keyPath: 'entityId',
});

export const editCompanyContact = (companyEntity, people = []) =>
  store.dispatch(async () => {
    // minimal opdate entity to provide user with instant feedback on UI
    const opdateSummaryEntity = generateContactSummaryOpdate({ id: companyEntity.id, contact: companyEntity });
    const peopleOpdateEntities = people.map((person) =>
      generateContactSummaryOpdate({ id: person.id, contact: person }),
    );
    opdateSummaryEntity.people = peopleOpdateEntities;
    opdateSummaryCache({ optimisticEntities: [opdateSummaryEntity] });

    const opdateEntity = generateContactEntityOpdate({ id: companyEntity.id, contact: companyEntity });
    opdateEntityCache({ optimisticEntities: [opdateEntity] });

    try {
      const path = `/customer-management/contact/:accountId/company`;
      const message = {
        companyEntity,
        people,
      };

      const fetchOptions = { body: JSON.stringify(message) };
      const res = await fetchPutP({ path, fetchOptions });

      return res.body.id;
    } catch (err) {
      rollbackSummaryOpdateCache({ optimisticEntities: [opdateSummaryEntity] });
      rollbackEntityOpdateCache({ optimisticEntities: [opdateEntity] });
      throw err;
    }
  });

export const editContact = (contactEntity) =>
  store.dispatch(async () => {
    // Need business address to opdate
    let businessEntity;
    if (contactEntity.linkedCompany) {
      businessEntity = await fetchContactById(contactEntity.linkedCompany);
    }

    // minimal opdate entity to provide user with instant feedback on UI
    const opdateSummaryEntity = generateContactSummaryOpdate({ id: contactEntity.id, contact: contactEntity });
    opdateSummaryCache({ optimisticEntities: [opdateSummaryEntity] });

    const opdateEntity = generateContactEntityOpdate({
      id: contactEntity.id,
      contact: contactEntity,
      linkedOrg: businessEntity,
    });
    opdateEntityCache({ optimisticEntities: [opdateEntity] });

    try {
      const path = `/customer-management/contact/:accountId/person`;
      const fetchOptions = { body: JSON.stringify(contactEntity) };
      const res = await fetchPutP({ path, fetchOptions });

      return res.body.id;
    } catch (err) {
      rollbackSummaryOpdateCache({ optimisticEntities: [opdateSummaryEntity] });
      rollbackEntityOpdateCache({ optimisticEntities: [opdateEntity] });
      throw err;
    }
  });
