import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { sendStatuses } from '@sb-billing/business-logic/correspondence-history';

import { ReminderCorrespondenceDetails } from '../reminder-correspondence-details';
import Styles from './ReminderCorrespondenceStatusIndicator.module.scss';

const sendClassesByStatus = {
  [sendStatuses.IN_PROGRESS]: classnames('icon-sync-2', Styles.blueText),
  [sendStatuses.SUCCESS]: classnames('icon-check-circle-2', Styles.greenText),
  [sendStatuses.ERROR]: classnames('icon-alert-2', Styles.redText),
};

export const ReminderCorrespondenceStatusIndicator = (props) => {
  const { sendStatus, lastCorrespondenceHistory, onResendClicked } = props;

  if (sendStatus === sendStatuses.NOT_SENT) {
    return null;
  }

  const messageBody = () => (
    <ReminderCorrespondenceDetails
      lastCorrespondenceHistory={lastCorrespondenceHistory}
      onResendClicked={onResendClicked}
    />
  );

  return (
    <div className={Styles.reminderCorrespondenceStatusIndicator}>
      <div className={Styles.iconContainer}>
        <ContextMenu body={messageBody}>
          <i className={sendClassesByStatus[sendStatus]} />
        </ContextMenu>
      </div>
    </div>
  );
};

ReminderCorrespondenceStatusIndicator.displayName = 'ReminderCorrespondenceStatusIndicator';

ReminderCorrespondenceStatusIndicator.propTypes = {
  sendStatus: PropTypes.number.isRequired,
  lastCorrespondenceHistory: PropTypes.object,
  onResendClicked: () => {},
};

ReminderCorrespondenceStatusIndicator.defaultProps = {
  lastCorrespondenceHistory: undefined,
  onResendClicked: () => {},
};

export default ReminderCorrespondenceStatusIndicator;
