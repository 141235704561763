import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { createComparer } from '@sb-itops/nodash';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'rate-sets',
  keyPath: 'id',
  ngCacheName: 'sbRateSetsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

function getActiveRateSets() {
  return getList()
    .filter((rateSet) => !rateSet.isHidden)
    .sort(createComparer('name', 'asc', undefined, false));
}

export { getActiveRateSets };
