import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import reducerRegistry from './reducer-registry';
import { sbPathMiddleware } from './middleware';

const initialState = {
  'itops/feature': {},
};

let persistor;

// Preserve initial state for not-yet-loaded reducers
const combine = (reducers) => {
  const reducerNames = Object.keys(reducers);
  const reducerMap = { ...reducers };

  Object.keys(initialState).forEach((item) => {
    if (!reducerNames.includes(item)) {
      reducerMap[item] = (state = null) => state;
    }
  });

  return combineReducers(reducerMap);
};

const composeEnhancers = composeWithDevTools({
  shouldHotReload: false,
  serialize: {
    options: undefined,
  },
  // the number of actions to record
  maxAge: 10,
  // whether to record actions
  shouldRecordChanges: false,
});

const reducer = combine(reducerRegistry.getReducers());
const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk, sbPathMiddleware)));

export const rehydratePersistedStateP = () =>
  new Promise((resolve, reject) => {
    persistor = persistStore(store, null, (err) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setChangeListener((reducers) => {
  store.replaceReducer(combine(reducers));
});

export const flushPersistedStateP = () => (persistor && persistor.flush ? persistor.flush() : Promise.resolve());

export default store;
