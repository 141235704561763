angular.module('sb.billing.webapp').component('sbRadioList', {
  'templateUrl': 'ng-components/radio-list/radio-list.html',
  'bindings': { onSelect: '&', pushSelected: '<', id: '<' },
  'controller': function ($scope, sbLoggerService) {
    'use strict';
    var log = sbLoggerService.getLogger('sbRadioListController');

    this.$onInit = function () {
      log.info('options', this.options, this.selected);
    };

    this.$onChanges = function () {
      if (this.selected !== this.pushSelected ) {
        log.info('saw set selected', this.pushSelected);
        this.selected = this.pushSelected;
      }
    };

    this.setOptions = function (options) {
      this.options = options;
    };

    this.select = function (id) {
      log.info('select', id);
      this.selected = id;
      this.onSelect({ selected: id }); // call injected callback
    };
  }
});
