'use strict';

/**
 * Optionally adds the staff member's name with their email
 *
 * @param {object} params
 * @param {boolean} params.showName defaults to true
 * @param {object} [params.staffMember] Normally, we always expect a valid staff member (i.e. the logged in staff member). However, setup user accounts are not valid staff memebrs, so the cache query will return a null value
 * @returns {string}
 */
function formatStaffMemberEmailAddress({ showName = true, staffMember }) {
  if (!staffMember) {
    return '';
  }

  if (showName) {
    const staffName = `"${staffMember.name}"`;
    const staffEmail = `<${staffMember.email}>`;
    return `${staffName} ${staffEmail}`;
  }

  return staffMember.email;
}

module.exports = {
  formatStaffMemberEmailAddress,
};
