import utilsFactory from '../utils-factory';
import { domain } from '../domain';
import name from './name';

const { createActionName } = utilsFactory({ domain, name });

const OPERATION_STATE = Object.freeze({
  started: 'started',
  succeeded: 'succeeded',
  failed: 'failed',
});

const OPERATION_START = createActionName('OPERATION_START');
const OPERATION_SUCCESS = createActionName('OPERATION_SUCCESS');
const OPERATION_FAIL = createActionName('OPERATION_FAIL');
const OPERATION_CLEAR = createActionName('OPERATION_CLEAR');

const operationReducer = (state = {}, action = {}) => {
  let next;
  switch (action.type) {
    case OPERATION_START:
      return {
        ...state,
        [action.payload.id]: {
          state: OPERATION_STATE.started,
        },
      };
    case OPERATION_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          state: OPERATION_STATE.succeeded,
        },
      };
    case OPERATION_FAIL:
      return {
        ...state,
        [action.payload.id]: {
          state: OPERATION_STATE.failed,
          error: action.payload.error,
        },
      };
    case OPERATION_CLEAR:
      if (state[action.payload.id]) {
        next = { ...state };
        delete next[action.payload.id];

        return next;
      }
      return state;
    default:
      return state;
  }
};

export { operationReducer, OPERATION_CLEAR, OPERATION_FAIL, OPERATION_START, OPERATION_SUCCESS, OPERATION_STATE };
