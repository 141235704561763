'use strict';

const { applyEmailTitle } = require('@sb-itops/email');
const { applyInvoiceActionLinks } = require('./apply-invoice-action-links');

const applyInvoiceHeader = ({ emailBody, showPayNowButton = false, showViewInvoiceButton = false }) => {
  const newEmailBody = applyEmailTitle({
    emailTitle: 'INVOICE #[INVOICE_NUMBER]',
    emailBody: showPayNowButton || showViewInvoiceButton ? applyInvoiceActionLinks({ emailBody }) : emailBody,
  });
  return newEmailBody;
};

module.exports = {
  applyInvoiceHeader,
};
