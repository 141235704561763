import { gql } from '@apollo/client';

const query = gql`
  query InitBankAccountSettings {
    bankAccountSettings {
      bankBalanceType #(e.g. 0:MatterContact, 1:Matter)
      defaultTrustAccounts {
        defaultTrustAccountId
        location
      }
      createPDFReceiptOnTrustPayment
      createPDFReceiptOnOperatingPayment
      createPDFReceiptOnMatterTransfer
      createPDFReceiptOnDeposit
      sendReceiptEmailCopyToUser
      doNotSendReceiptAttachment
      sendDepositRequestEmailCopyToUser
      depositRequestEmailSubject
      depositRequestEmailBody
      receiptEmailSubject
      receiptEmailBody
    }
  }
`;

const notificationIds = ['AccountsNotifications.BankAccountSettingsUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitBankAccountSettings = {
  query,
  notificationIds,
};
