import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Table, Column } from '@sb-itops/react/table';
import { StatelessSelect, Button } from '@sb-itops/react';

import Styles from './StaffTargetAssignmentTable.module.scss';

const renderAssignedGroupCell = ({ rowData, activeTargetGroupOptions, onFieldValueUpdated, isDisabled }) => (
  <div className={Styles.assignmentDropdown}>
    <StatelessSelect
      options={activeTargetGroupOptions}
      selectedOption={rowData.staffTargetGroupId}
      onChange={(newSelectedTargetGroup) =>
        onFieldValueUpdated({ [rowData.id]: newSelectedTargetGroup?.value || null })
      }
      disabled={isDisabled}
      isClearable
      showDropdown={false}
    />
  </div>
);

const StaffTargetAssignmentTable = ({
  staffFilter,
  staffFilterOptions,
  setStaffFilter,
  staffTargetAssignmentList,
  isStaffTargetAssignmentsLoading,
  activeTargetGroupOptions,
  // form
  onFieldValueUpdated,
  formSubmitting,
  formDirty,
  onSave,
  // export
  isGeneratingCsv,
  isGeneratingPdf,
  printDialogIframeId,
  onExportAsPdf,
  onExportAsCsv,
}) => {
  const disableSaveButton = formSubmitting || !formDirty;
  return (
    <div className={Styles.staffTargetAssignmentTable}>
      <div className={classnames('row', Styles.headline)}>
        <div className="col-sm-6">
          <h3>Staff</h3>
        </div>
        <div className="col-sm-4">
          <StatelessSelect
            options={staffFilterOptions}
            selectedOption={staffFilter}
            onChange={(newStaffFilter) => setStaffFilter(newStaffFilter.value)}
            disabled={isStaffTargetAssignmentsLoading}
          />
        </div>
        <div className="col-sm-2">
          <div className={Styles.exportContainer}>
            <Button
              className={Styles.download}
              locked={isGeneratingPdf}
              disabled={isGeneratingPdf}
              onClick={onExportAsPdf}
            >
              Print
            </Button>
            <Button
              className={Styles.download}
              locked={isGeneratingCsv}
              disabled={isGeneratingCsv}
              onClick={onExportAsCsv}
            >
              CSV
            </Button>
          </div>
        </div>
      </div>
      <Table
        className="table-shadow"
        style={{
          height: `${Math.min(600, 30 + staffTargetAssignmentList.length * 38)}px`,
        }}
        dataLoading={isStaffTargetAssignmentsLoading}
        list={staffTargetAssignmentList}
      >
        <Column dataKey="name" label="Name" flexGrow={6} className={Styles.nameColumn} disableSort />
        <Column
          dataKey="staffTargetGroupId"
          label="Target Group"
          flexGrow={6}
          className={Styles.nameColumn}
          cellRenderer={({ rowData }) =>
            renderAssignedGroupCell({
              rowData,
              activeTargetGroupOptions,
              onFieldValueUpdated,
              isDisabled: isStaffTargetAssignmentsLoading,
            })
          }
          disableSort
        />
      </Table>
      <div className={Styles.saveButton}>
        <Button
          locked={formSubmitting} // spinner
          disabled={disableSaveButton}
          size="full-width"
          onClick={onSave}
        >
          Save
        </Button>
      </div>

      <div className={Styles.printPdfIframeContainer}>
        <iframe id={printDialogIframeId} title="smokeball-report-viewer" />
      </div>
    </div>
  );
};

StaffTargetAssignmentTable.displayName = 'StaffTargetAssignmentTable';

StaffTargetAssignmentTable.propTypes = {
  staffFilter: PropTypes.string,
  staffFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setStaffFilter: PropTypes.func.isRequired,
  staffTargetAssignmentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      staffTargetGroupId: PropTypes.string,
    }),
  ),
  isStaffTargetAssignmentsLoading: PropTypes.bool,
  activeTargetGroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  // form
  onFieldValueUpdated: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  formDirty: PropTypes.bool.isRequired,
  formValidation: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  // export
  isGeneratingCsv: PropTypes.bool.isRequired,
  isGeneratingPdf: PropTypes.bool.isRequired,
  printDialogIframeId: PropTypes.string.isRequired,
  onExportAsPdf: PropTypes.func.isRequired,
  onExportAsCsv: PropTypes.func.isRequired,
};

StaffTargetAssignmentTable.defaultProps = {
  staffFilter: 'All',
  staffFilterOptions: [],
  staffTargetAssignmentList: [],
  isStaffTargetAssignmentsLoading: false,
  activeTargetGroupOptions: [],
};

export default StaffTargetAssignmentTable;
