import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import store from '@sb-itops/redux/store';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { fetchPostP } from '@sb-itops/redux/fetch';
import {
  opdateCache as opdateTransactionCache,
  getById as getTransactionById,
  getByPaymentId as getTransactionByPaymentId,
} from '@sb-billing/redux/transactions';
import { opdateCache as opdatePaymentCache, getById as getPaymentById } from '@sb-billing/redux/payments';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { createOpdates } from '@sb-billing/business-logic/accounts/payment/reverse-credit-opdates';
import { creditNoteDetailsSchema } from './credit-note-details-schema';
import { CreditNotePaymentDetailsModal } from './CreditNotePaymentDetailsModal';

const log = getLogger('CreditNotePaymentDetailsModal');
const scope = 'credit-note-details-modal';

const mapStateToProps = (state, { isVisible, transactionId, paymentId, t }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, formSubmitting, formValid, fields: formFields } = formSelectors.getFormState(state);
  const { reason } = formFields;
  const transaction = getTransactionById(transactionId) || getTransactionByPaymentId(paymentId).shift();
  const payment = getPaymentById(transaction.paymentId);
  const { amount, effectiveDate, payorId, userId, multiPaymentId } = payment;
  const date = t('date', { yyyymmdd: effectiveDate });
  const contact = getContactDisplay(payorId);
  const processedBy = getPersonByUserId(userId)?.name;
  const invoice = getInvoiceById(payment.invoices[0].invoiceId);
  const showDebug = supportDebugSelectors.getShowDebug(state);

  // for credit applied to an invoice, we'll manually generate a description, i.e. ignoring
  // the description generated by the system (because there's no .net resources to do this work)
  const invoiceIds = payment.invoices.map(({ invoiceId }) => `#invoiceId:${invoiceId}`).join(' ');
  const description = `Credit applied to invoice ${invoiceIds}`;

  // applying same rules as regular payments: payment is reversible if it hasn't already been reversed and if its invoice isn't waived.
  const isReversible = !payment.reversedAt && !invoice.currentVersion.waived;

  const isReversal = !!transaction.reversedFromTransactionId;

  const localisationConfig = {
    invoiceIntegrationAvailable: hasFacet(facets.integrationXeroInvoice),
    canDeleteTransactions: hasFacet(facets.deleteTransaction),
  };

  return {
    formInitialised,
    formValid,
    formSubmitting,
    isVisible,
    amount,
    effectiveDate: date,
    contact,
    processedBy,
    internalNote: transaction.note || transaction.reason || '',
    description: `${isReversal ? 'Reversal: ' : ''}${description}`,
    reason,
    isDeleted: transaction.isHidden,
    showDebug,
    showReverse: isReversible,
    showDelete: localisationConfig.canDeleteTransactions && isReversible,
    transactionId: transaction.id,
    multiPaymentId,
  };
};

const mapDispatchToProps = (dispatch, { onClose, onClickLink }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            reason: '',
          },
        }),
      );
      dispatch(formOperations.validateSchema({ schema: creditNoteDetailsSchema }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onClickLink,
    onFieldValueUpdated: (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateSchema({ schema: creditNoteDetailsSchema }));
    },
    onProcess: async ({ transactionId, isDelete }) => {
      try {
        const { selectors, operations } = withScopedFeature({ scope })(forms);
        const { fields: formFields } = selectors.getFormState(store.getState());
        const { reason } = formFields;
        const transaction = getTransactionById(transactionId);
        const userId = authSelectors.getUserId(store.getState());
        const effectiveDate = Number(moment(new Date()).format('YYYYMMDD'));

        const body = {
          reason: reason.value,
          userId,
          effectiveDate,
          hideTransactions: isDelete,
          allowOverdraw: false,
        };

        await dispatch(
          operations.submitFormP({
            submitFnP: async () =>
              fetchPostP({
                path: `/billing/payment/:accountId/reversal/${transaction.paymentId}`,
                fetchOptions: {
                  body: JSON.stringify(body),
                },
              }),
          }),
        );

        const opdates = createOpdates({
          paymentToReverse: getPaymentById(transaction.paymentId),
          transactionToReverse: transaction,
        });
        opdateTransactionCache({ optimisticEntities: opdates.sbTransactionService });
        opdatePaymentCache({ optimisticEntities: opdates.sbPaymentService });

        const payment = getPaymentById(transaction.paymentId);
        const invoice = getInvoiceById(payment?.invoices[0]?.invoiceId);
        messageDisplay.success(
          `Credit applied to invoice #${invoice?.currentVersion?.invoiceNumber} has been ${
            isDelete ? 'deleted' : 'reversed'
          }.`,
        );
      } catch (err) {
        log.error(err);
        messageDisplay.error(
          messageDisplay
            .builder()
            .text(`Problem ${isDelete ? 'deleting' : 'reversing'} credit applied to invoice.`)
            .conditionalText('{0}', err.message ? `: ${err.message}` : ''),
        );
      } finally {
        onClose();
      }
    },
    onCancel: onClose,
    onClose,
  };
};

export const CreditNotePaymentDetailsModalContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(CreditNotePaymentDetailsModal))),
);

CreditNotePaymentDetailsModalContainer.displayName = 'CreditNotePaymentDetailsModalContainer';

CreditNotePaymentDetailsModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
  isVisible: PropTypes.bool,
  transactionId: PropTypes.string,
  paymentId: PropTypes.string,
};

CreditNotePaymentDetailsModalContainer.defaultProps = {
  isVisible: false,
  transactionId: undefined,
  paymentId: undefined,
  onClickLink: () => {},
};
