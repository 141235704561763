'use strict';

const { getBoostAppEnv } = require('./get-boost-app-env');
const { getRegion, regionType } = require('@sb-itops/region');

/* eslint-env browser */
const isBrowser = () => typeof window !== 'undefined' && typeof window.document !== 'undefined';

const envType = {
  DEV: 'DEV',
  RC: 'RC',
  QA: 'QA',
  PRODUCTION: 'PRODUCTION',
  LIVE: 'LIVE', // APP_ENV has the value `LIVE` in production lambdas
  LOCAL: 'LOCAL'
};

const envByHostName = {
  'local': envType.LOCAL,
  'au.billinglocal': envType.LOCAL,
  'us.billinglocal': envType.LOCAL,
  'dev-': envType.DEV,
  'devpayments-': envType.DEV,
  'rc-': envType.RC,
  'rcpayments-': envType.RC,
  'qa-': envType.QA,
  'qapayments-': envType.QA,
  'app.smokeball': envType.PRODUCTION,
  'payments-billing.smokeball': envType.PRODUCTION,
  'invoicing.smokeball': envType.PRODUCTION,
  'join.smokeball': envType.PRODUCTION,
};

const envPrefixes = Object.keys(envByHostName);

/**
 * Get Billing Application Environment
 * @returns environment (one of LOCAL, DEV, RC, QA, PRODUCTION) as a string, throws error if unknown
 */
const getAppEnv = () => {
  if (isBrowser()) {
    // Determine the frontend environment based on hostname.
    const hostName = window.location.hostname;
    const frontendEnvPrefix = envPrefixes.find((prefix) => hostName.startsWith(prefix));
    let frontendEnv = envByHostName[frontendEnvPrefix];

    if (frontendEnv) {
      return frontendEnv;
    }
    throw new Error('Unknown frontend environment, please add host prefix for any new application in app-env.');
  }

  if (process.env.APP_ENV) {
    return process.env.APP_ENV;
  }
  throw new Error('Unknown backend environment, process.env.APP_ENV is not set');
}

const getLocalRegionQueryString = () => {
  const region = getRegion();
  return getAppEnv() === envType.LOCAL && region !== regionType.US ? `?${region}` : '';
}

module.exports = {
  envType,
  getAppEnv,
  getBoostAppEnv,
  getLocalRegionQueryString
};
