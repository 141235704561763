'use strict';
import { getConfig as getDefaultMatterBillingConfig, saveConfig as saveDefaultMatterBillingConfig } from '@sb-billing/redux/default-matter-billing-configuration';
import * as messageDisplay from '@sb-itops/message-display';
import { getRetainerDefaults } from '@sb-billing/business-logic/evergreen-retainer';

angular.module('sb.billing.webapp').component('sbTrustRetainerSettings', {
  templateUrl: 'ng-components/trust-retainer-settings/trust-retainer-settings.html',
  controller: function (sbLoggerService, sbLocalisationService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbTrustRetainerSettings');
    const retainerDefaults = getRetainerDefaults(sbLocalisationService.t);
    const defaultConfig = {
      minimumTrustRetainerActive: retainerDefaults.MINIMUM_TRUST_RETAINER_ACTIVE,
      minimumTrustRetainerAmount: retainerDefaults.MINIMUM_TRUST_RETAINER_AMOUNT,
      trustRetainerReplenishAmount: retainerDefaults.TRUST_RETAINER_REPLENISH_AMOUNT,
      trustRetainerReplenishEmailSubject: retainerDefaults.TRUST_RETAINER_EMAIL_SUBJECT,
      trustRetainerReplenishEmailBody: retainerDefaults.TRUST_RETAINER_EMAIL_MESSAGE,
      doNotSendReplenishEmailToUser: retainerDefaults.DO_NOT_SEND_REPLENISH_EMAIL_TO_USER,
    };

    ctrl.t = sbLocalisationService.t;
    ctrl.model = {};
    ctrl.errors = {};
    ctrl.isSaving = false;

    ctrl.save = save;
    ctrl.onMinimumAmountUpdated = onMinimumAmountUpdated;
    ctrl.onReplenishAmountUpdated = onReplenishAmountUpdated;

    ctrl.$onInit = () => {
      const billingConfig = getDefaultMatterBillingConfig();
      ctrl.model = {
        ...defaultConfig,
        ...billingConfig,
      };
      log.debug('Loaded model is', ctrl.model);
    };

    async function save() {
      if (isValid()) {
        ctrl.isSaving = true;

        try {
          await saveDefaultMatterBillingConfig(marshal(ctrl.model));
          messageDisplay.success(`${ctrl.t('capitalize', { val: 'trustAccount' })} retainer settings saved`);
        }
        catch(err) {
          log.error('Failed to save default matter billing configuration settings', err);
          messageDisplay.error('Failed to save evergreen retainer settings');
        }
        finally {
          ctrl.isSaving = false;
        }
      } else {
        prepField('minAmount');
        prepField('replenishAmount');
      }
    }

    function marshal (rawData) {
      return {
        accountId:                          rawData.accountId,
        minimumTrustRetainerActive:         rawData.minimumTrustRetainerActive,
        minimumTrustRetainerAmount:         rawData.minimumTrustRetainerAmount,
        trustRetainerReplenishAmount:       rawData.trustRetainerReplenishAmount,
        trustRetainerReplenishEmailSubject: rawData.trustRetainerReplenishEmailSubject,
        trustRetainerReplenishEmailBody:    rawData.trustRetainerReplenishEmailBody,
        trustRetainerReplenishEmailBcc:     rawData.trustRetainerReplenishEmailBcc,
        doNotSendReplenishEmailToUser:      rawData.doNotSendReplenishEmailToUser,
      };
    }

    function onMinimumAmountUpdated(event) {
      ctrl.model.minimumTrustRetainerAmount = event.target.value;
      prepField('minAmount');
    }

    function onReplenishAmountUpdated(event) {
      ctrl.model.trustRetainerReplenishAmount = event.target.value;
      prepField('replenishAmount');
    }

    function prepField (field) {
      switch(field) {
        case 'minAmount': {
          ctrl.errors.minAmount = Number.isNaN(ctrl.model.minimumTrustRetainerAmount) || ctrl.model.minimumTrustRetainerAmount <= 0;
          break
        }
        case 'replenishAmount': {
          ctrl.errors.replenishAmount = Number.isNaN(ctrl.model.trustRetainerReplenishAmount) || ctrl.model.trustRetainerReplenishAmount <= 0;
          break;
        } 
      }
    }
    
    function isValid () {
      return !ctrl.errors.minAmount && !ctrl.errors.replenishAmount
    }
  }
});
