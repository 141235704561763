import * as React from 'react';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

import { FinancialSummaryForTrust } from './FinancialSummaryForTrust';

const { getMatterBalance, getFirmBalance } = selectors;

const FinancialSummaryForTrustContainer = withReduxProvider(({ matterId, trustAccountId }) => {
  const totals = useSelector(() => {
    const trustBankAccountId = trustAccountId;

    let trustBalance = {};
    if (matterId) {
      trustBalance = {
        total:
          getMatterBalance(getBankAccountBalanceState(), {
            bankAccountId: trustBankAccountId,
            matterId,
            balanceType: balanceTypes.BALANCE,
          }) || 0,
        protected:
          getMatterBalance(getBankAccountBalanceState(), {
            bankAccountId: trustBankAccountId,
            matterId,
            balanceType: balanceTypes.PROTECTED,
          }) || 0,
        available:
          getMatterBalance(getBankAccountBalanceState(), {
            bankAccountId: trustBankAccountId,
            matterId,
            balanceType: balanceTypes.AVAILABLE,
          }) || 0,
      };
    } else {
      trustBalance = {
        total:
          getFirmBalance(getBankAccountBalanceState(), {
            bankAccountId: trustBankAccountId,
            balanceType: balanceTypes.BALANCE,
          }) || 0,
        protected:
          getFirmBalance(getBankAccountBalanceState(), {
            bankAccountId: trustBankAccountId,
            balanceType: balanceTypes.PROTECTED,
          }) || 0,
        available:
          getFirmBalance(getBankAccountBalanceState(), {
            bankAccountId: trustBankAccountId,
            balanceType: balanceTypes.AVAILABLE,
          }) || 0,
      };
    }

    return {
      PROTECTED: trustBalance.protected,
      AVAILABLE: trustBalance.available,
      TOTAL: trustBalance.total,
    };
  });

  return <FinancialSummaryForTrust totals={totals} />;
});

FinancialSummaryForTrustContainer.displayName = 'FinancialSummaryForTrustContainer';

FinancialSummaryForTrustContainer.propTypes = {
  matterId: PropTypes.string, // first render from angular does not necessarily have the matterId present
  trustAccountId: PropTypes.string,
};
FinancialSummaryForTrustContainer.defaultProps = { matterId: '' };

export default FinancialSummaryForTrustContainer;
