import { FormLabel, MultiSelectDropdown, SlidingToggle, Spinner, forms2PropTypes } from '@sb-itops/react';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { IconSelector } from 'web/components/documents-tab/icon-selector';
import folderSrc from 'web/assets/Page-1.svg';

import Styles from './SendFilesViaCommunicateForm.module.scss';

const IconRenderer = ({ fileExtension, type }) => (
  <div className={Styles.alignCenter}>
    {type === 'folder' ? (
      <img className="icon" alt="folder" src={folderSrc} />
    ) : (
      <IconSelector isSolid extension={fileExtension} />
    )}
  </div>
);

const SendFilesViaCommunicateForm = ({
  scope,
  selectedContacts,
  isLiving,
  parties,
  partiesLoading,
  selectedDocuments,
  staffIds,
  staffOptions,
  removeSelectedDocument,
  setShowSelectFilesModal,
  // form fields
  isMfaRequired,
  message,
  // form
  formDisabled,
  formInitialised,
  formValidation,
  onContactsChanged,
  onStaffIdsUpdate,
  onUpdateFields,
  onValidateForm,
  submitFailed,
}) => {
  if (!formInitialised) {
    return null;
  }

  const filesPlural = `${selectedDocuments.length === 1 ? 'File' : 'Files'}`;
  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
  const isErrorExists = (fieldName) => Object.keys(formValidation).includes(fieldName);

  return (
    <div className={Styles.container} id={scope}>
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-xs-12 form-group">
            <label className={Styles.isMfaRequired}>
              <SlidingToggle
                id="isMfaRequired"
                name="isMfaRequired"
                onChange={(name, val) => {
                  onUpdateFields({ [name]: val });
                }}
                scope="send-files-via-communicate-form-is-mfa-required"
                selected={isMfaRequired.value}
              />
              <span>Require Multi-Factor Authentication</span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Parties" submitFailed={submitFailed} />
            {partiesLoading ? (
              <div>
                <Spinner small />
              </div>
            ) : (
              <>
                {parties.length === 0 && (
                  <div className={classNames('col-lg-12 form-group')}>No clients found for this matter</div>
                )}
                {parties.length > 0 && (
                  <MultiSelectDropdown
                    options={parties.map((party) => ({
                      label: party.displayName,
                      value: party.id,
                      searchText: party.displayName,
                      disabled: !party.email,
                      disabledText: !party.email ? 'No email provided' : undefined,
                    }))}
                    hasError={!!formValidation.attendeeEntityIds && submitFailed}
                    onSelectedIdsChanged={(selectedPartiesOptions) => {
                      const selectedPartyIds = selectedPartiesOptions.map((item) => item.value);
                      onContactsChanged(selectedPartyIds);
                    }}
                    isOptionDisabled={(option) => option.disabled}
                    value={selectedContacts.map((contact) => contact.id)}
                  />
                )}
              </>
            )}
            {isErrorExists('selectedContacts') && (
              <div className="row">
                <div className={classNames('col-lg-12 form-group', Styles.errorMessage)}>
                  {formValidation.selectedContacts}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Staff" submitFailed={submitFailed} />
            <MultiSelectDropdown
              hasError={!!isErrorClassnames(staffIds)}
              options={staffOptions.map((staffMember) => ({
                label: staffMember.name,
                value: staffMember.id,
                searchText: staffMember.name,
              }))}
              onSelectedIdsChanged={(selectedIds) => {
                onStaffIdsUpdate(selectedIds.map((staffMember) => staffMember.value));
                onValidateForm();
              }}
              value={staffIds ?? []}
            />
            {isErrorExists('staffIds') && (
              <div className="row">
                <div className={classNames('col-lg-12 form-group', Styles.errorMessage)}>{formValidation.staffIds}</div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className={classNames('form-group', 'col-lg-12', Styles.textEditor)}>
            <FormLabel label="Message" field={message} submitFailed={submitFailed} />
            <div>
              <textarea
                className={classNames('form-control', isErrorClassnames(message))}
                rows="5"
                value={message?.value || ''}
                onChange={(e) => {
                  onUpdateFields({ message: e.target.value });
                }}
                onBlur={onValidateForm}
              />
            </div>
            {isErrorExists('message') && submitFailed && (
              <div className="row">
                <div className={classNames('col-lg-12 form-group', Styles.errorMessage)}>{formValidation.message}</div>
              </div>
            )}
          </div>
        </div>
        {(selectedDocuments ?? []).length > 0 && (
          <div className="row">
            <div className="col-xs-12">
              <label>
                {isLiving ? 'Shared' : 'Attached'} {filesPlural}:
              </label>
              <div className={Styles.documentList}>
                {(selectedDocuments ?? []).map((d) => (
                  <div className={Styles.documentItem} key={d.data.id}>
                    <div className={Styles.documentItemIcon}>
                      <IconRenderer fileExtension={d.data.fileExtension} type={d.type} />
                    </div>
                    <div className={Styles.documentItemName}>{d.data.name}</div>
                    <div className={Styles.documentItemRemoveButtonContainer}>
                      <button
                        alt="Remove document from message"
                        className={Styles.documentItemRemoveButton}
                        onClick={() => removeSelectedDocument(d)}
                        type="button"
                      >
                        <i className="fa fa-times fa-fw" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="row" style={{ marginTop: '10px' }}>
          <div className="col-xs-12">
            <button alt="Add more files" onClick={() => setShowSelectFilesModal(true)} type="button">
              Add
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

SendFilesViaCommunicateForm.displayName = 'SendFilesViaCommunicateForm';
SendFilesViaCommunicateForm.propTypes = {
  scope: PropTypes.string.isRequired,
  isLiving: PropTypes.bool.isRequired,
  parties: PropTypes.array.isRequired,
  partiesLoading: PropTypes.bool.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),
  removeSelectedDocument: PropTypes.func.isRequired,
  setShowSelectFilesModal: PropTypes.func.isRequired,
  staffIds: PropTypes.arrayOf(PropTypes.string),
  staffOptions: PropTypes.array.isRequired,
  // fields
  isMfaRequired: Forms2Field,
  message: Forms2Field,
  // form
  onContactsChanged: PropTypes.func.isRequired,
  onStaffIdsUpdate: PropTypes.func.isRequired,
  onUpdateFields: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  formValidation: PropTypes.object.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

SendFilesViaCommunicateForm.defaultProps = {
  isMfaRequired: undefined,
  message: undefined,
  selectedContacts: [],
  selectedDocuments: [],
  staffIds: [],
};

export default SendFilesViaCommunicateForm;
