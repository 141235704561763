import { createSelector } from 'reselect';
import {
  getList as getContactList,
  getById as getContactById,
  getContactDisplay,
} from '@sb-customer-management/redux/contacts-summary';
import { getList as getMatterList } from '@sb-matter-management/redux/matters';
import { getMap as getMatterTypesMap, getMatterTypeNameById } from '@sb-matter-types/redux/matter-types';
import { getMap as getFirmDetailsMap, getStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import { getMap as getMatterTotalsMap, getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { featureActive } from '@sb-itops/feature';
import { createComparer } from '@sb-itops/nodash';
import {
  getMap as getBankAccountBalancesMap,
  getList as getBankAccBalances,
} from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getList as getInvoiceList } from '@sb-billing/redux/invoices';

import { t } from '@sb-itops/localisation-web';
import { filterTrustAccountsByMatter } from './filter-trust-accounts';

export const formatDateItem = (date) => t('date', { ts: date.toISOString(), inUtc: true });

const { getAccountBalancesByMatterId } = selectors;
const caseInsensitiveSortPreProcess = (property) => (property ? property.toLowerCase() : property);

// create a list of entities for the contacts that includes the contact id, the value to display and a number
// of fields to search across, for typeahead input boxes. Sort alphabetically and place deleted contacts at the
// end of the list
const getContactTypeAheadSummariesSelector = createSelector(
  (contacts) => contacts,
  (contacts) => {
    const contactsByState = contacts.reduce(
      (byState, contact) => {
        const typeahead = [getContactDisplay(contact.entityId)];

        if (contact.type === 'Person' || contact.type === 'Staff') {
          const contactFirstAndLastName = `${contact.firstName} ${contact.lastName}`;
          if (contactFirstAndLastName !== getContactDisplay(contact.entityId)) {
            // This is to handle contacts with middle name, Eg. Janet Middle Zhuo, display name is the full name, we need to push this into typeahead so when client search by only first and last name, the contact is able to show as an option
            typeahead.push(contactFirstAndLastName);
          }
        }

        if (contact.representativeOfs && contact.representativeOfs.length) {
          contact.representativeOfs.forEach((id) => {
            const found = getContactById(id);
            if (found) {
              typeahead.push(getContactDisplay(found.entityId));
            }
          });
        } else if (contact.representativeOf) {
          const found = getContactById(contact.representativeOf);
          if (found) {
            typeahead.push(getContactDisplay(found.entityId));
          }
        }

        if (contact.email) {
          typeahead.push(contact.email);
        }

        const people = contact.personIds ? contact.personIds.split(';').map((p) => getContactById(p)) : [];
        const isDeleted = contact.removed || (people.length && people.every((person) => person && person.removed));

        const entry = {
          id: contact.entityId,
          representativeOfs: contact.representativeOfs,
          type: contact.type,
          display: getContactDisplay(contact.entityId, { showLastNameFirst: true }),
          displayFirst: getContactDisplay(contact.entityId, { showLastNameFirst: false }),
          typeahead: typeahead
            .filter((v) => v)
            .join(' ')
            .toLowerCase(),
          isDeleted,
        };

        if (isDeleted) {
          byState.deleted.push(entry);
        } else {
          byState.active.push(entry);
        }

        return byState;
      },
      {
        active: [],
        deleted: [],
      },
    );

    const sortItems = createComparer('display', 'asc', caseInsensitiveSortPreProcess);

    return [...contactsByState.active.sort(sortItems), ...contactsByState.deleted.sort(sortItems)];
  },
);

const getMatterTypeaheadSummariesSelectorCreator = () =>
  createSelector(
    (matters) => matters,
    (matters, matterTypes) => matterTypes,
    (matters, matterTypes, firmDetails) => firmDetails,
    (matters, matterTypes, firmDetails, matterTotals) => matterTotals,
    (matters, matterTypes, firmDetails, matterTotals, bankAccBalances) => bankAccBalances,
    (matters, matterTypes, firmDetails, matterTotals, bankAccBalances, includeLeads) => includeLeads,
    (matters) =>
      matters.map((matter) => {
        const matterTypeName = getMatterTypeNameById(matter.matterTypeId);
        const attorneyResponsible = getStaffByPersonId(matter.attorneyResponsibleId);
        const matterTotals = getMatterTotalsById(matter.matterId);
        const matterAccountBalances = getAccountBalancesByMatterId(getBankAccountBalancesMap(), {
          matterId: matter.matterId,
        });
        const hasFunds = matterAccountBalances && matterAccountBalances.hasFunds;
        const typeahead = [];
        const display = [matter.matterNumber, matter.clientDisplay, matterTypeName];

        typeahead.push(matter.matterNumber, matter.clientDisplay, matterTypeName, matter.otherSideDisplay);

        if (attorneyResponsible) {
          typeahead.push(attorneyResponsible.name, attorneyResponsible.initials);
        }

        typeahead.push(matter.description);

        const dateStarted = new Date(matter.matterStarted);

        return {
          id: matter.matterId,
          display: display.filter((v) => v).join(' - '),
          matterStarted: dateStarted,
          // this is only so we can use it in angular without having to map the whole collection.
          // once we get rid of the global search this needs to be removed
          matterStartedISO: formatDateItem(dateStarted),
          matterClientNames: matter.clientNames,
          status: matter.status,
          typeahead: typeahead.filter((v) => v).join(' '),
          matterTotals,
          hasFunds,
          isLead: matter.isLead,
        };
      }),
  );

// reselect has a cache size of 1. We want to pass in an argument so we use a factory function to create a selector for all leads and just billable ones
const getMatterTypeaheadSummariesSelector = getMatterTypeaheadSummariesSelectorCreator();
const getMatterAndLeadsTypeaheadSummariesSelector = getMatterTypeaheadSummariesSelectorCreator();

const getOpenPendingMatterTypeaheadSummariesSelector = createSelector(
  (matterTypeaheads) => matterTypeaheads,
  (matterTypeaheads) =>
    matterTypeaheads.filter(
      (matterTypeahead) => matterTypeahead.status === 'Open' || matterTypeahead.status === 'Pending',
    ),
);

const getInvoiceTypeaheadSummariesSelector = createSelector(
  (invoices) => invoices,
  (invoices) =>
    invoices.reduce((invoiceTypeaheads, invoice) => {
      if (invoice.currentVersion.status !== 'DELETED') {
        invoiceTypeaheads.push({
          id: invoice.currentVersion.invoiceNumber,
          display: invoice.currentVersion.invoiceNumber,
          typeahead: invoice.currentVersion.invoiceNumber,
          status: invoice.currentVersion.status,
          invoiceId: invoice.currentVersion.invoiceId,
        });
      }

      return invoiceTypeaheads;
    }, []),
);

const getMatterForBankAccountTypeaheadSummariesSelector = createSelector(
  (bankAccountId) => bankAccountId,
  (bankAccountId, matterTypeaheads) => matterTypeaheads,
  (bankAccountId, matterTypeaheads) => {
    if (!bankAccountId) return [];

    return matterTypeaheads.filter((matterTypeahead) => {
      const trustAccountsForMatter = filterTrustAccountsByMatter(matterTypeahead.id, { includeClosed: true });
      return !!trustAccountsForMatter.find((ta) => ta.id === bankAccountId);
    });
  },
);

export const getContactTypeAheadSummaries = (contacts) =>
  getContactTypeAheadSummariesSelector(contacts || getContactList());

/**
 * @returns {Array<MatterTypeaheadSummary>}
 */
export const getMatterTypeaheadSummaries = (showAllLeads = false) => {
  const includeLeads = featureActive('BB-6595');
  return showAllLeads
    ? getMatterAndLeadsTypeaheadSummariesSelector(
        getMatterList(true),
        getMatterTypesMap(),
        getFirmDetailsMap(),
        getMatterTotalsMap(),
        getBankAccBalances(),
        includeLeads,
      )
    : getMatterTypeaheadSummariesSelector(
        getMatterList(false),
        getMatterTypesMap(),
        getFirmDetailsMap(),
        getMatterTotalsMap(),
        getBankAccBalances(),
        includeLeads,
      );
};

export const getOpenPendingMatterTypeaheadSummaries = () =>
  getOpenPendingMatterTypeaheadSummariesSelector(getMatterTypeaheadSummaries());

export const getInvoiceTypeaheadSummaries = () => getInvoiceTypeaheadSummariesSelector(getInvoiceList());

/**
 * @returns {Array<MatterTypeaheadSummary>}
 */
export const getMatterForBankAccountTypeaheadSummaries = (bankAccountId) =>
  getMatterForBankAccountTypeaheadSummariesSelector(bankAccountId, getMatterTypeaheadSummaries());
