import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { getMap } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import {
  getSettings as getBankAccountSettings,
  isMatterContactBalanceType,
} from '@sb-billing/redux/bank-account-settings';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { getContactTypeAheadSummaries, getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getLogger } from '@sb-itops/fe-logger';
import { getById as getContactById, getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import { useTranslation } from '@sb-itops/react';

import { TransferFundsForm } from './TransferFundsForm';
import { TransferFundsFormSchema } from './TransferFundsFormSchema';
import { getTransferFundsValidateFn } from './validation';
import { getDestinationBalance, getSourceBalance } from './balance-calculations';
import { getBankAccountsForMatter } from './get-bank-accounts';

const log = getLogger('TransferFundsForm');
const { getMatterContactBalances } = selectors;

const hooks = (props) => ({
  useConfig: () => {
    const { localisationConfig } = props;

    return {
      showReason: localisationConfig.transferReason,
      supportsElectronicPayment: localisationConfig.electronicPayment,
      showContacts: isMatterContactBalanceType(),
      showAuthorisedBy: localisationConfig.showAuthorisedBy,
      showReference: localisationConfig.autoGeneratedReference,
    };
  },
  useSelectors: ({ isTransferNumberingEnabled, localisationConfig }) => {
    const { t } = useTranslation();
    const {
      matterId: defaultMatterId,
      contactId: defaultContactId,
      bankAccountId: defaultBankAccountId,
      scope,
    } = props;
    const dispatch = useDispatch();

    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const {
      authorisedBy,
      sourceMatterId,
      destinationMatterId,
      sourceContactId,
      destinationContactId,
      sourceBankAccountId,
      destinationBankAccountId,
      amount,
      sourceBalanceAvailable,
      sourceBalanceAvailableAfter,
      destinationBalanceAvailable,
      destinationBalanceAvailableAfter,
      reference, // if transfernumberingenabled
      reason,
      note,
      pdfOnTransfer,
    } = formFields;
    // fields which are objects pass as regular object

    const [sourceContactOptions, setSourceContactOptions] = useState([]);

    let sourceBankAccounts = sourceMatterId?.value ? getBankAccountsForMatter(sourceMatterId?.value, t) : [];
    let destinationBankAccounts = destinationMatterId?.value
      ? getBankAccountsForMatter(destinationMatterId?.value, t)
      : [];

    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      validate();
    };

    const onSelectSourceMatter = (newMatterId) => {
      let fieldsToUpdate = {};
      // If clearing
      if (!newMatterId) {
        fieldsToUpdate.sourceBankAccountId = undefined;
        fieldsToUpdate.sourceMatterId = undefined;
        sourceBankAccounts = [];
        onFieldValueUpdated(fieldsToUpdate);
      } else {
        const newBankAccounts = getBankAccountsForMatter(newMatterId, t);
        sourceBankAccounts = newBankAccounts;
        const bankAccount =
          sourceBankAccounts.find((account) => account.id === defaultBankAccountId) ||
          (sourceBankAccounts.length && sourceBankAccounts[0]);
        fieldsToUpdate.sourceBankAccountId = bankAccount?.id || undefined;
        fieldsToUpdate.sourceMatterId = newMatterId;

        if (fieldsToUpdate.sourceBankAccountId) {
          const newFields = getSourceBalance({
            matterId: newMatterId,
            bankAccountId: fieldsToUpdate.sourceBankAccountId,
            contactId: sourceContactId?.value,
            amount: amount?.value,
          });
          fieldsToUpdate = { ...fieldsToUpdate, ...newFields };
          onSelectSourceBankAccountId({ value: fieldsToUpdate.sourceBankAccountId });

          const contacts = loadContactsForMatter(newMatterId, fieldsToUpdate.sourceBankAccountId);
          if (contacts && contacts.length) {
            setSourceContactOptions(getContactTypeAheadSummaries(contacts));
          } else {
            setSourceContactOptions([]);
          }
        } else {
          setSourceContactOptions([]);
        }
        onFieldValueUpdated(fieldsToUpdate);
      }
    };

    const onSelectDestinationMatter = (newMatterId) => {
      let fieldsToUpdate = {};
      // If clearing
      if (!newMatterId) {
        fieldsToUpdate.destinationBankAccountId = undefined;
        fieldsToUpdate.destinationMatterId = undefined;
        destinationBankAccounts = [];
        onFieldValueUpdated(fieldsToUpdate);
      } else {
        const newBankAccounts = getBankAccountsForMatter(newMatterId, t);
        destinationBankAccounts = newBankAccounts;
        fieldsToUpdate.destinationMatterId = newMatterId;

        const newFields = onSyncDestinationBankAccountId({ newMatterId });
        fieldsToUpdate = { ...fieldsToUpdate, ...newFields };

        onFieldValueUpdated(fieldsToUpdate);
      }
    };

    const onSelectSourceBankAccountId = (newBankAccount) => {
      let fieldsToUpdate = {
        sourceBankAccountId: newBankAccount?.value,
      };
      if (fieldsToUpdate.sourceBankAccountId) {
        const bankAccount = sourceBankAccounts.find((account) => account.id === fieldsToUpdate.sourceBankAccountId);
        const newFields = getSourceBalance({
          matterId: sourceMatterId?.value,
          bankAccountId: fieldsToUpdate.sourceBankAccountId,
          contactId: sourceContactId?.value,
          amount: amount?.value,
        });
        if ([bankAccountTypeEnum.TRUST].includes(bankAccount?.accountType)) {
          const bankAccountSettings = getBankAccountSettings() || {};
          newFields.pdfOnTransfer = bankAccountSettings?.createPDFReceiptOnMatterTransfer;
          fieldsToUpdate = { ...fieldsToUpdate, ...newFields };
        }
        const newReadOnlyReference = isTransferNumberingEnabled(newBankAccount?.value);
        if (reference?.value !== undefined) {
          fieldsToUpdate.reference = newReadOnlyReference ? undefined : reference?.value;
        }
        if (fieldsToUpdate.sourceBankAccountId && destinationMatterId?.value) {
          onSyncDestinationBankAccountId({ newDestinationBankAccountId: fieldsToUpdate.sourceBankAccountId });
        }
      }

      onFieldValueUpdated(fieldsToUpdate);
    };

    const onSyncDestinationBankAccountId = ({ newMatterId, newDestinationBankAccountId, newContactId }) => {
      let fieldsToUpdate = {};
      const destinationBankAccount = destinationBankAccounts.find(
        (ba) => ba?.id === (newDestinationBankAccountId || sourceBankAccountId?.value),
      );
      fieldsToUpdate.destinationBankAccountId = destinationBankAccount?.id;
      if (fieldsToUpdate.destinationBankAccountId) {
        const newFields = getDestinationBalance({
          matterId: newMatterId || destinationMatterId?.value,
          bankAccountId: fieldsToUpdate.destinationBankAccountId,
          contactId: newContactId || destinationContactId?.value,
          amount: amount?.value,
        });
        fieldsToUpdate = { ...fieldsToUpdate, ...newFields };
      } else {
        fieldsToUpdate.destinationBankAccountId = undefined;
        fieldsToUpdate.destinationBalanceAvailable = 0;
        fieldsToUpdate.destinationBalanceAvailableAfter = 0;
      }
      return fieldsToUpdate;
    };

    // onLoad, form is not initialised
    useEffect(() => {
      log.info('initialised');
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            authorisedBy: undefined,
            sourceMatterId: defaultMatterId,
            destinationMatterId: undefined,
            destinationContactId: undefined,
            sourceBankAccountId: defaultMatterId ? defaultBankAccountId : undefined,
            destinationBankAccountId: undefined,
            sourceContactId: defaultContactId,
            note: undefined,
            amount: 0,
            sourceBalanceAvailable: 0,
            sourceBalanceAvailableAfter: 0,
            destinationBalanceAvailable: 0,
            destinationBalanceAvailableAfter: 0,
            reference: undefined,
            reason: undefined,
            pdfOnTransfer: false,
          },
        }),
      );

      if (defaultMatterId) {
        onSelectSourceMatter(defaultMatterId);
        if (defaultBankAccountId) {
          onSelectSourceBankAccountId({ value: defaultBankAccountId });
        }
      }

      const newFields = getSourceBalance({
        matterId: defaultMatterId,
        bankAccountId: defaultMatterId ? defaultBankAccountId : undefined,
        contactId: defaultContactId,
        amount: amount?.value || 0,
      });

      onFieldValueUpdated(newFields);

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validate = async () => {
      const context = {
        ...localisationConfig,
        showReason: localisationConfig.transferReason,
        showContacts: isMatterContactBalanceType(),
        supportsReference: localisationConfig.autoGeneratedReference,
        isTransferNumberingEnabled,
        t,
      };
      dispatch(
        formOperations.validateForm({
          validateFn: getTransferFundsValidateFn(context),
          schema: TransferFundsFormSchema,
          context,
        }),
      );
    };

    return {
      scope,
      sourceContactOptions,
      destinationContactOptions: getContactTypeAheadSummaries(),
      matterOptions: getMatterTypeaheadSummaries(),
      sourceBankAccountOptions: sourceBankAccounts.map((account) => ({ label: account.display, value: account.id })),
      destinationBankAccountOptions: destinationBankAccounts.map((account) => ({
        label: account.display,
        value: account.id,
      })),
      onFieldValueUpdated,
      showPDFOnTransfer: shouldShowPDFOnTransfer(sourceBankAccountId?.value),
      onSelectSourceBankAccountId,
      onSelectSourceMatter,
      onSelectDestinationMatter,
      onSelectSourceContact: (newContactId) => {
        const fieldsToUpdate = {
          sourceContactId: newContactId,
        };
        const newFields = getSourceBalance({
          matterId: sourceMatterId?.value,
          bankAccountId: sourceBankAccountId?.value,
          contactId: newContactId,
          amount: amount?.value,
        });
        onFieldValueUpdated({ ...fieldsToUpdate, ...newFields });
      },
      onSelectDestinationContact: (newContactId) => {
        const fieldsToUpdate = {
          destinationContactId: newContactId,
        };
        const newFields = getDestinationBalance({
          matterId: destinationMatterId?.value,
          bankAccountId: destinationBankAccountId?.value,
          contactId: newContactId,
          amount: amount?.value,
        });
        onFieldValueUpdated({ ...fieldsToUpdate, ...newFields });
      },
      onReasonChange: (newReason) => {
        onFieldValueUpdated({ reason: newReason });
      },
      onAmountChange: (newAmount) => {
        const newSourceBalanceFields = getSourceBalance({
          matterId: sourceMatterId?.value,
          bankAccountId: sourceBankAccountId?.value,
          contactId: sourceContactId?.value,
          amount: newAmount,
        });

        const newDestinationBalanceFields = getDestinationBalance({
          matterId: destinationMatterId?.value,
          bankAccountId: destinationBankAccountId?.value,
          contactId: destinationContactId?.value,
          amount: newAmount,
        });

        const fieldsToUpdate = {
          amount: newAmount,
          ...newSourceBalanceFields,
          ...newDestinationBalanceFields,
        };

        onFieldValueUpdated(fieldsToUpdate);
      },
      onSourceChange: (newSource) => {
        const fieldsToUpdate = { source: newSource?.value };
        onFieldValueUpdated(fieldsToUpdate);
      },
      onOpenEditContactModal: (clickedContactId) => {
        setModalDialogVisible({
          modalId: CONTACT_CREATE_EDIT_MODAL_ID,
          props: { contactId: clickedContactId, onContactEdited: validate },
        });
      },
      // config
      readOnlyReference: isTransferNumberingEnabled(sourceBankAccountId?.value),
      // form fields
      authorisedBy,
      sourceMatterId,
      destinationMatterId,
      sourceContactId,
      destinationContactId,
      sourceBankAccountId,
      destinationBankAccountId,
      note,
      amount,
      reference,
      reason,
      pdfOnTransfer,
      sourceBalanceAvailable,
      sourceBalanceAvailableAfter,
      destinationBalanceAvailable,
      destinationBalanceAvailableAfter,
      // form state
      submitFailed,
      formDisabled: formSubmitting,
      formInitialised,
    };
  },
});

function loadContactsForMatter(matterId, bankAccountId) {
  if (!matterId || !bankAccountId) {
    return [];
  }

  return getMatterContactBalances(getMap(), {
    bankAccountId,
    matterId,
  }).map((balance) => {
    const contact = getContactById(balance.contactId);
    return {
      ...contact,
      display: getContactDisplay(contact.entityId, { showLastNameFirst: true }),
    };
  });
}

function shouldShowPDFOnTransfer(bankAccountId) {
  if (!bankAccountId) {
    return false;
  }
  const bankAccount = getBankAccountById(bankAccountId);
  return [bankAccountTypeEnum.TRUST].includes(bankAccount.accountType);
}

export const TransferFundsFormContainer = withReduxProvider(composeHooks(hooks)(TransferFundsForm));

TransferFundsFormContainer.displayName = 'TransferFundsFormContainer';

TransferFundsFormContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  scope: PropTypes.string.isRequired,
};

TransferFundsFormContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  bankAccountId: undefined,
};
