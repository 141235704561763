import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { store } from '@sb-itops/redux';
import { eInvoiceLayout } from '@sb-billing/business-logic/invoice/entities';
import { getById } from './index';

export const getEInvoiceSettings = () => {
  const accountId = authSelectors.getAccountId(store.getState());
  const settings = getById(accountId);

  return (
    settings || {
      accountId: authSelectors.getAccountId(store.getState()),
      layout: eInvoiceLayout.BY_TIMEKEEPER,
      eInvoiceDisabled: false,
    }
  );
};
