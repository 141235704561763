import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ModalDialog } from '@sb-itops/react/modal-dialog';

const ConfirmationModalDialog = memo(
  ({ onConfirm, modalId, title, body, primaryButtonText, secondaryButtonText, isConfirming, isCancelling }) => (
    <ModalDialog
      modalId={modalId}
      body={
        <div>
          {typeof body === 'string' && <p>{body}</p>}
          {typeof body === 'object' && body}
        </div>
      }
      title={title}
      buttons={{
        rightAlign: true,
        primary: {
          text: primaryButtonText,
          props: {
            disabled: isConfirming,
          },
          submitting: isConfirming,
        },
        secondary: {
          text: secondaryButtonText,
          props: {
            disabled: isCancelling,
          },
          submitting: isCancelling,
        },
      }}
      onPrimaryClick={onConfirm}
    />
  ),
);

ConfirmationModalDialog.displayName = 'ConfirmationModalDialog';

ConfirmationModalDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  isConfirming: PropTypes.bool,
  isCancelling: PropTypes.bool,
};

ConfirmationModalDialog.defaultProps = {
  primaryButtonText: 'Confirm',
  secondaryButtonText: 'Cancel',
  isConfirming: false,
  isCancelling: false,
};

export default ConfirmationModalDialog;
