import React from 'react';
import * as Styles from './StaffColorPicker.module.scss';

type TProps = {
  colorFill: string;
  colorStroke: string;
  style?: any;
};
export const StaffFlair = ({ colorFill, colorStroke, style }: TProps) => (
  <div className={Styles.flair} style={{ backgroundColor: colorFill, borderColor: colorStroke, ...style }} />
);
