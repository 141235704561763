import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Spinner } from '../spinner';
import Styles from './DropdownMenuButton.module.scss';

// this implementation relies on bootstrap to work
export const DropdownMenuButton = ({ label, disabled, menuOptions, showSpinner }) => (
  <div className={classnames('dropdown', Styles.dropdownMenuButtonContainer)}>
    <button
      className={classnames('btn', 'btn-primary', 'dropdown-toggle', Styles.dropdownMenuButton)}
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      disabled={disabled}
    >
      {showSpinner && <Spinner className={Styles.spinner} small />}
      {label} &nbsp; <span className="caret" />
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      {menuOptions.map((option) => (
        <div
          key={option.id}
          className={classnames(Styles.dropdownMenuItem, option.disabled && Styles.disabledDropdownMenuItem)}
          onClick={option.disabled ? () => {} : option.onClick}
        >
          {option.label}
        </div>
      ))}
    </div>
  </div>
);

DropdownMenuButton.displayName = 'DropdownMenuButton';

DropdownMenuButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      // renderItem: can extend this in the future to support custom rendering
    }),
  ).isRequired,
  showSpinner: PropTypes.bool, // Sometimes a menu option will require data to be fetched before proceeding with an action
};

DropdownMenuButton.defaultProps = {
  label: 'Dropdown Menu',
  disabled: false,
  showSpinner: undefined,
};
