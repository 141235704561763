import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { sort as sortItems } from '@sb-itops/sort';
import { debounce } from '@sb-itops/nodash';
import {
  firmFeeConfigurationDefaults,
  entryTypeLabels,
  entryType as entryTypesEnum,
} from '@sb-billing/business-logic/shared/entities';
import { isAutoTimeEntry } from '@sb-billing/business-logic/shared/services';
import {
  filterUtbmsTaskCodes,
  mapActivitiesByCategory,
  mapTasksByCategory,
} from '@sb-billing/business-logic/activities/services';
import { todayAsInteger } from '@sb-itops/date';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { useCacheQuery, useSubscribedQuery, useSubscribedLazyQuery } from 'web/hooks';
import {
  FeeFormData,
  InitActivityCodes,
  InitFirmFeeConfiguration,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitStaffSettings,
  MatterSummaries,
  MatterSummaryData,
} from 'web/graphql/queries';

import { FeeFormsContainer, feePresentationalComponentModes } from './Fee.forms.container';

/**
 * All query related and general data and functionality for fees
 */

const hooks = (props) => ({
  useFeeData: () => {
    const { feeId } = props;
    const isNewFee = !feeId;

    const { data, error, loading } = useSubscribedQuery(FeeFormData, {
      skip: isNewFee,
      variables: {
        id: feeId,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const fee = data?.fee;
    const isAutoTimeFee = !!fee && !!fee.sourceItems && isAutoTimeEntry(fee);
    const feeData = {
      fee,
      isAutoTimeFee,
      isFeeLoading: loading,
      isNewFee,
    };

    return feeData;
  },
  useMatterSummaryData: () => {
    // Fees can be intrinsically linked to a matter (e.g. fees on the matter or draft invoice page)
    // When creating a new fee, the query to fetch the fee entity is skipped (as it doesn't exist yet). Therefore, we need to query for the matter separately.
    const { feeId, matterId } = props;
    const existingFee = !!feeId;
    const newFeeWithoutLinkToAMatter = !existingFee && !matterId;

    const { data, loading, error } = useSubscribedQuery(
      MatterSummaryData,
      {
        skip: existingFee || newFeeWithoutLinkToAMatter,
        variables: {
          id: matterId,
        },
      },
      {
        notificationIds: [
          ...MatterSummaryData.notificationIds,
          'WebQueryFeesNotifications.MatterHourlyRateUpdated',
          'FeesNotifications.StaffFeeConfigurationUpdated',
          'FeesNotifications.StaffMemberHourlyRateUpdated',
        ],
      },
    );

    if (error) {
      throw new Error(error);
    }

    return {
      matter: data?.matter,
      isMatterLoading: loading,
    };
  },
  useActivitiesAndTasksData: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    // Activities
    const activities = React.useMemo(
      () =>
        mapActivitiesByCategory({
          activityCodes: activityCodesResult?.data?.activityCodes,
          utbmsActivityCodes: activityCodesResult?.data?.utbmsActivityCodes,
          filterCustomCodeByTypes: [entryTypesEnum.FIXED, entryTypesEnum.TIME],
        }),
      [activityCodesResult?.data?.activityCodes, activityCodesResult?.data?.utbmsActivityCodes],
    );

    // Tasks
    const tasks = React.useMemo(
      () =>
        mapTasksByCategory({
          utbmsTaskCodes: activityCodesResult?.data?.utbmsTaskCodes,
          utbmsCustomTaskCodes: activityCodesResult?.data?.utbmsCustomTaskCodes,
          filterCustomCodeByTypes: [entryTypeLabels.FIXED, entryTypeLabels.TIME],
        }),
      [activityCodesResult?.data?.utbmsTaskCodes, activityCodesResult?.data?.utbmsCustomTaskCodes],
    );

    return {
      activities,
      tasks,
    };
  },
  useFirmSettings: () => {
    const { data: firmFeeConfigData } = useCacheQuery(InitFirmFeeConfiguration.query);
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    const billingIncrementsMins =
      firmFeeConfigData?.firmFeeConfiguration?.billableMinutes || firmFeeConfigurationDefaults.BILLABLE_MINUTES;
    const isUtbmsEnabledForFirm = firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled;
    const utbmsCodesRequiredByFirm =
      isUtbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;
    const utbmsCodeSetsUsedByFirm = firmUtbmsSettingsData?.firmUtbmsSettings?.selectedCodeSets;
    const { taxRate: taxRateBasisPoints } = firmTaxSettingsData?.firmTaxSettings || {};

    return {
      billingIncrementsMins,
      isUtbmsEnabledForFirm,
      taxRateBasisPoints,
      utbmsCodesRequiredByFirm,
      utbmsCodeSetsUsedByFirm,
    };
  },
  useAutoTimeSummary: () => {
    const [autoTimeSummary, setAutoTimeSummary] = React.useState('');

    return {
      autoTimeSummary,
      onSetAutoTimeSummary: (summary) => setAutoTimeSummary(summary),
    };
  },
  useStaffMemberData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    const userPrefersDurationsAsUnits = staffData?.loggedInStaff?.feeConfiguration?.enterTimeAsUnits;

    const staffMemberOptions = React.useMemo(() => {
      const data = staffData?.staffMembers;

      if (!data) {
        return [];
      }

      const options = data.map((staffMemberEntity) => {
        const { id, initials, name, rate } = staffMemberEntity;

        return {
          value: id,
          label: `${initials} (${name})`,
          entity: {
            ...staffMemberEntity,
            rate: rate || 0,
          },
        };
      });
      const sortedOptions = sortItems(options, ['label'], ['ASC']);

      return sortedOptions;
    }, [staffData?.staffMembers]);

    const getStaffRateConfig = () => {
      const loggedInStaff = staffData?.loggedInStaff;
      const firstStaffMemberInList = staffData?.staffMembers[0];

      if (loggedInStaff) {
        return {
          id: loggedInStaff.id,
          rate: loggedInStaff.rate || 0,
        };
      }

      return firstStaffMemberInList
        ? {
            id: firstStaffMemberInList.id,
            rate: firstStaffMemberInList.rate || 0,
          }
        : undefined;
    };

    return {
      preferDurationAsUnits: userPrefersDurationsAsUnits,
      staffMemberOptions,
      staffRateConfig: getStaffRateConfig(),
    };
  },
  useMatterSummariesData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(
      MatterSummaries,
      {
        context: { skipRequestBatching: true },
        variables: {
          filter: {
            matterStatus: ['pending', 'open'],
            rateSetDate: todayAsInteger(),
          },
          includeMatterHourlyRate: true,
          limit: 25,
          sort: {
            fieldNames: ['statusOpen', 'matterStarted'],
            directions: ['DESC', 'DESC'],
          },
        },
      },
      {
        notificationIds: [
          ...MatterSummaries.notificationIds,
          'WebQueryFeesNotifications.MatterHourlyRateUpdated',
          'FeesNotifications.StaffFeeConfigurationUpdated',
          'FeesNotifications.StaffMemberHourlyRateUpdated',
        ],
      },
    );

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = React.useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
});

const dependentHooks = (props) => ({
  useLoadingStateForQueries: () => {
    const { isFeeLoading, isMatterLoading } = props;

    return {
      loading: isFeeLoading || isMatterLoading,
    };
  },
  useFilteredUtbmsTasks: () => {
    const { isUtbmsEnabledForFirm, tasks, utbmsCodeSetsUsedByFirm } = props;

    // If we haven't yet received the task codes used by the firm, we aren't able to filter from the full list.
    // In this case we won't show any utbms tasks.
    if (!isUtbmsEnabledForFirm || !utbmsCodeSetsUsedByFirm) {
      return {
        tasks: [],
      };
    }

    const filteredTasks = filterUtbmsTaskCodes({
      utbmsTaskCodes: tasks,
      utbmsCodeSetsUsedByFirm,
    });

    return {
      tasks: filteredTasks,
    };
  },
});

export const FeeContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(FeeFormsContainer))),
);

FeeContainer.displayName = 'FeeContainer';

FeeContainer.propTypes = {
  feeId: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(feePresentationalComponentModes)).isRequired,
  onNavigateToInvoice: PropTypes.func,
  scope: PropTypes.string.isRequired,
  /** Fee Pop Out Editor specific */
  /** Fee Modal specific */
  hideSaveAndNewButton: PropTypes.bool,
  preventDurationFieldSyncUpdates: PropTypes.bool,
  matterId: PropTypes.string,
  onFeeSave: PropTypes.func,
  onModalClose: PropTypes.func,
  prefilledDurationInMinutes: PropTypes.number,
  prefilledFeeDescription: PropTypes.string,
  showModal: PropTypes.bool,
};

FeeContainer.defaultProps = {
  feeId: undefined,
  onNavigateToInvoice: undefined,
  /** Fee Pop Out Editor specific */
  /** Fee Modal specific */
  hideSaveAndNewButton: undefined,
  preventDurationFieldSyncUpdates: undefined,
  matterId: undefined,
  onFeeSave: undefined,
  onModalClose: undefined,
  prefilledDurationInMinutes: undefined,
  prefilledFeeDescription: undefined,
  showModal: undefined,
};
