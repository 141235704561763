import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { withOnLoad } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import { useSort } from '@sb-itops/redux/sort/use-sort';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { FeeWiseTransactionTableData } from 'web/graphql/queries';

import { FeeWiseTransactionsRoute } from './FeeWiseTransactionsRoute';
import { getFilterDatesForQuery } from '../get-filter-dates-for-query';

const SCOPE = 'FeeWiseTransactionsRoute';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_TRANSACTION_TABLE = `${SCOPE}/transaction-table`;
const FETCH_LIMIT = 50;

const hooks = ({ selectedStatusIds, statusOptions, dateRangeType, fromDate, toDate, linkedAccountSelected }) => ({
  useFeeWiseTransactionListData: () => {
    const { selectedItem: currentPage, setSelectedItem: setPageNumber } = useSingleItemSelection({
      scope: SCOPE_PAGINATION,
      initialSelection: 0,
    });

    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: SCOPE_TRANSACTION_TABLE,
      initialSortBy: 'date',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    const skipPayoutsQuery = selectedStatusIds.length === 0;
    const status = statusOptions.length === selectedStatusIds.length ? [] : selectedStatusIds;
    const { from, to } = getFilterDatesForQuery({ dateRangeType, fromInt: fromDate, toInt: toDate });
    const feeWiseAccountIds = linkedAccountSelected ? [linkedAccountSelected] : [];

    const fwTransactionsQueryResult = useQuery(FeeWiseTransactionTableData, {
      fetchPolicy: 'cache-and-network',
      skip: skipPayoutsQuery,
      variables: {
        feeWiseTransactionFilter: {
          status,
          from,
          to,
          feeWiseAccountIds,
        },
        // We count pages from 0 but FeeWise counts from 1
        page: currentPage + 1,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (fwTransactionsQueryResult.error) {
      throw new Error(fwTransactionsQueryResult.error);
    }
    const { data: fwTransactionsData } = fwTransactionsQueryResult;
    const fwTransactionList = fwTransactionsData?.feeWiseTransactionList?.results || [];

    const transactionCount = fwTransactionsData?.feeWiseTransactionList?.totalCount || 0;
    const numberOfPages = Math.ceil(transactionCount / FETCH_LIMIT);

    if (!fwTransactionsQueryResult.loading && currentPage > numberOfPages) {
      // Reset page to 0 if we requested page higher than total number of pages
      setPageNumber(0);
    }

    return {
      transactionCount,
      transactions: fwTransactionList,
      transactionsDataLoading: fwTransactionList.length === 0 && fwTransactionsQueryResult.loading,
      // pagination
      hidePagination: !(currentPage > 0 || numberOfPages > 1),
      currentPage,
      numberOfPages: Math.ceil(transactionCount / FETCH_LIMIT),
      onPageChange: ({ selected: pageNumber }) => setPageNumber(pageNumber),

      // sort
      onSort,
      sortBy,
      sortDirection,
    };
  },
});

export const FeeWiseTransactionsRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(withOnLoad(FeeWiseTransactionsRoute))),
);

FeeWiseTransactionsRouteContainer.displayName = 'FeeWiseTransactionsRouteContainer';

FeeWiseTransactionsRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  panelFilterScope: PropTypes.string.isRequired,

  dateRangeType: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  onDateListChange: PropTypes.func.isRequired,

  selectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  linkedAccountSelected: PropTypes.string.isRequired,
  linkedAccountOnSelect: PropTypes.func.isRequired,
  linkedAccountLoading: PropTypes.bool.isRequired,
  linkedAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};
