import { hasFacet, facets } from '@sb-itops/region-facets';
import uuid from '@sb-itops/uuid';

export const marshalData = ({ formData, t }) => {
  const {
    matter,
    amount,
    contact,
    effectiveDate,
    bankAccountId,
    bank,
    bankName,
    bankBranchNumber,
    comment,
    drawer,
    reference,
    reason,
    depositSourceId,
    rows,
  } = formData;
  const matterRows = Object.values(rows || {}).filter((row) => !!row?.matterId); // filter out removed rows
  if (matter?.id && amount) {
    matterRows.push({ matterId: matter?.id, amount });
  }

  const drawerBankString = buildDrawerBankString({ drawer, bank });
  const autoGeneratedReference = hasFacet(facets.autoGeneratedReference);
  const description = `${
    depositSourceId ? t('capitalizeAllWords', { val: depositSourceId.toLowerCase() }) : ''
  } deposit`;

  const transaction = {
    bankAccountId,
    payorId: contact?.id,
    description,
    source: depositSourceId,
    note: comment,
    effectiveDate: effectiveDate || +moment().format('YYYYMMDD'),
    saveReceiptNumberAsReference: autoGeneratedReference || false,
  };

  if (matterRows.length === 1) {
    // single deposit
    transaction.isBulk = false;
    transaction.transactions = [
      {
        matterId: matterRows[0].matterId,
        amount: matterRows[0].amount,
        transactionId: uuid(),
      },
    ];
  } else {
    // bulk deposit
    transaction.isBulk = true;
    transaction.bulkDepositId = uuid();
    transaction.transactions = matterRows.map((row) => ({
      matterId: row.matterId,
      amount: row.amount,
      transactionId: uuid(),
    }));
  }

  if (reason) {
    transaction.reason = reason;
  }

  if (reference) {
    transaction.reference = reference;
  }

  if (bankName || drawerBankString || bankBranchNumber) {
    transaction.bankName = bankName;
    transaction.drawerBank = drawerBankString;
    transaction.bankBranchNumber = bankBranchNumber;
  }

  return transaction;
};

const buildDrawerBankString = ({ drawer, bank }) => {
  // This function looks at the facet bankNameForDepositFunds and generates either
  //  - a string with just the drawer if the facet is true (AU/UK)
  //  - a string with bank / drawer if facet is false (US)
  // The facet is evaluated further up in 189 of DepositFundsModalBody.jsx, and either
  // bank (facet false) or bankName (facet true) is populated accordingly.
  const drawerName = drawer?.displayName || '';
  const separator = bank && drawerName ? ' / ' : '';

  return `${bank || ''}${separator}${drawerName}`;
};
