import { updateState } from '../../../actions/generic/update-state';
import { domain } from '../../../domain';
import name from '../name';

const { updateStateAction, updateStateReducer, ACTION_TYPE } = updateState({
  actionName: `${domain}/${name}/UPDATE_SELECTED_INVOICE_TEMPLATE_CONFIG`,
});

const UPDATE_SELECTED_INVOICE_TEMPLATE_CONFIG = ACTION_TYPE;

export const updateSelectedInvoiceTemplateConfiguration = (state, { uniqueId, ...config }) =>
  updateStateAction({
    ...state,
    [uniqueId]: { ...state[uniqueId], selectedInvoiceTemplateConfiguration: { ...config } },
  });

export const updateSelectedInvoiceTemplateConfigurationReducerEntry = {
  [UPDATE_SELECTED_INVOICE_TEMPLATE_CONFIG]: updateStateReducer,
};
