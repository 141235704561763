import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  getExcludingTaxAmount,
  getIncludingTaxAmount,
  getBillableAmountExcludingTax,
  getWaivedAmount,
  getBilledAmount,
} from '@sb-billing/business-logic/expense/services';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { ExpenseTable } from './ExpenseTable';

/**
 * Calculate additional data for each expense entry
 * @param params
 * @param {Object[]} params.originalExpenses
 */
const calculateExpensesAndSummary = ({ originalExpenses }) => {
  let totalUnits = 0;
  let totalAmountExcTax = 0;
  let totalAmountBillable = 0;
  let totalAmountNonBillable = 0;
  let totalAmountWaived = 0;
  let totalAmountBilled = 0;

  const expenses = originalExpenses.map((originalExpense) => {
    const expense = { ...originalExpense };

    expense.amountIncTax = getIncludingTaxAmount(expense);
    expense.amountExcTax = getExcludingTaxAmount(expense);
    expense.amountBillableExcTax = getBillableAmountExcludingTax(expense);
    expense.amountWaivedExcTax = getWaivedAmount(expense);
    expense.amountBilledExcTax = getBilledAmount(expense);

    if (+expense.quantity) {
      totalUnits += +expense.quantity;
    }

    totalAmountExcTax += expense.amountExcTax;
    totalAmountNonBillable += expense.amountExcTax - expense.amountBillableExcTax;
    totalAmountBillable += expense.amountBillableExcTax;
    totalAmountWaived += expense.amountWaivedExcTax;
    totalAmountBilled += expense.amountBilledExcTax;

    return expense;
  });

  const summary = {
    units: totalUnits,
    amountExcTax: totalAmountExcTax,
    billable: totalAmountBillable,
    nonBillable: totalAmountNonBillable,
    waived: totalAmountWaived,
    billed: totalAmountBilled,
  };

  return { expenses, summary };
};

const hooks = ({ expenses }) => ({
  useCalculateExpenseListValues: () => {
    let expenseList = [];
    let expenseSummary;

    if (expenses.length) {
      const calculatedExpenses = calculateExpensesAndSummary({
        originalExpenses: expenses,
      });
      expenseList = calculatedExpenses.expenses;
      expenseSummary = calculatedExpenses.summary;
    }

    return {
      expenses: expenseList,
      expenseSummary,
    };
  },
});

export const ExpenseTableContainer = withReduxProvider(composeHooks(hooks)(ExpenseTable));
ExpenseTableContainer.displayName = 'ExpenseTableContainer';

ExpenseTableContainer.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ExpenseTableContainer.defaultProps = {};
