/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import classnames from 'classnames';

import Styles from './MatterNavigationPanelItem.module.scss';
import { panelItemType } from '../types';

export const MatterNavigationPanelItem = ({ title, display, isActive, isErrorState, handleScroll }) => {
  if (!display) {
    return null;
  }

  return (
    <div className={classnames(Styles.navItem, isActive && Styles.active)} onClick={handleScroll}>
      <a>{title}</a>
      {isErrorState && <i className={classnames('icon', 'icon-alert-1', Styles.errorAlertIcon)} />}
    </div>
  );
};

MatterNavigationPanelItem.displayName = 'MatterNavigationPanelItem';

MatterNavigationPanelItem.propTypes = panelItemType;
