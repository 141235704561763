import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@sb-itops/react/table';
import { useTranslation, Column } from '@sb-itops/react';
import { Button } from '@sb-itops/react/button';
import { ContactTypeIcon } from '@sb-customer-management/react/contact-type-icon';
import ContactDisplay from '@sb-customer-management/react/contact-display/ContactDisplay';
import { featureActive } from '@sb-itops/feature';
import { isNewTheme } from 'web/services/theme';

import Styles from './ContactTable.module.scss';

export const ContactTable = ({
  contactCount,
  contactCountLabel,
  contacts,
  dataLoading,
  sortBy,
  sortDirection,
  onSelect,
  onSort,
  onAddContactButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!isNewTheme() && (
        <div className={Styles.gridLabelHeader}>
          {contactCountLabel} {dataLoading ? null : <span>({contactCount})</span>}
        </div>
      )}
      {featureActive('BB-7677') && (
        <div className="ribbon panel">
          <Button onClick={onAddContactButtonClick}>Add Contact</Button>
        </div>
      )}
      <Table
        className={Styles.contactTable}
        onRowClick={({ rowData }) => {
          const { id } = rowData;
          onSelect({ id });
        }}
        list={contacts}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        dataLoading={dataLoading}
        resetScrollPositionOnDataChange
      >
        <Column dataKey="displayName" label="name" cellRenderer={displayNameCellRenderer} flexGrow={3} />
        <Column dataKey="representativeOfsDisplayName" label={t('organisation')} flexGrow={3} />
        <Column dataKey="phone" label="phone" flexGrow={1} disableSort />
        <Column dataKey="cell" label={t('mobile')} flexGrow={1} disableSort />
        <Column dataKey="email" label="email" flexGrow={3} disableSort />
      </Table>
    </>
  );
};

function displayNameCellRenderer({ rowData }) {
  return (
    <>
      <ContactTypeIcon contactType={rowData.type} />
      <span />
      <ContactDisplay contactId={rowData.entityId || rowData.id} displayName={rowData.displayName} inline />
    </>
  );
}

ContactTable.propTypes = {
  contacts: PropTypes.array,
  contactCount: PropTypes.number.isRequired,
  contactCountLabel: PropTypes.string,
  dataLoading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSelect: PropTypes.func,
  onSort: PropTypes.func,
  onAddContactButtonClick: PropTypes.func.isRequired,
};

ContactTable.defaultProps = {
  contacts: [],
  contactCountLabel: '',
  dataLoading: false,
  sortBy: 'displayName',
  sortDirection: 'asc',
  onSelect: undefined,
  onSort: undefined,
};
