import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { TPermission, TResourceId } from './types';

/* eslint-disable no-restricted-syntax */
type TCacheEntry = null | { permission: TPermission; ttlMillis: number };
type TCache = Record<TResourceId, TCacheEntry>;

const MATTER_PERMISSION_TTL_SECONDS = 60 * 5;
let permissionsCache: TCache = {};
let cacheForStaff = null;

const staffIsOK = () => {
  const loggedInStaff = getLoggedInStaff();

  // must be valid user
  if (!loggedInStaff?.id) {
    return false;
  }

  // always trusted on first use
  if (!cacheForStaff) {
    return true;
  }

  // only trust if same user
  return cacheForStaff === loggedInStaff?.id;
};

const scrubIfStaffChanged = () => {
  if (!staffIsOK()) {
    permissionsCache = {};
    cacheForStaff = null;
  }
};

// eject entries which have hit their ttl
const preEject = (resourceIds: TResourceId[]) => {
  const nowMillis = Date.now();

  for (const id of resourceIds) {
    const entry = permissionsCache[id];
    if (entry && entry.ttlMillis <= nowMillis) {
      delete permissionsCache[id];
    }
  }
};

export const getCachedPermissions = (
  resourceIds: TResourceId[],
): { hits: Record<TResourceId, TPermission>; misses: TResourceId[] } => {
  scrubIfStaffChanged();
  preEject(resourceIds);
  const misses: TResourceId[] = [];
  const hits: Record<TResourceId, TPermission> = {};

  for (const id of resourceIds) {
    const entry = permissionsCache[id];
    if (entry) {
      hits[id] = entry.permission;
    } else {
      misses.push(id);
    }
  }
  return { hits, misses };
};

export const cachePermissions = (permissions: TPermission[]) => {
  if (!staffIsOK()) {
    return;
  }
  cacheForStaff = getLoggedInStaff()?.id;
  const ttl = Date.now() + MATTER_PERMISSION_TTL_SECONDS * 1000;

  for (const permission of permissions) {
    permissionsCache[permission.resourceId] = { permission, ttlMillis: ttl };
  }
};
