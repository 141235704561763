/* eslint-disable no-unused-vars */
'use strict';

const { getResources } = require('@sb-itops/localisation-resources');

module.exports = {
  type: 'backend',
  // i18next appears to check the parameter list, include all params even if not used
  init: function(services, backendOptions, i18nextOptions) {
    // nothing to do
  },
  // i18next appears to check the parameter list, include all params even if not used
  read: function (language, namespace) {
    console.log('loading module resources', language, namespace);
    return Promise.resolve(getResources(language));
  },
};
