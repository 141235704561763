import { capitalizeAllWords } from '@sb-itops/nodash';
import { featureActive } from '@sb-itops/feature';

angular
  .module('sb.billing.webapp')
  .factory(
    'exportExpenseOp',
    (
      $rootScope,
      $http,
      sbGenericEndpointService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
      sbLocalisationService
    ) => {
      const log = sbLoggerService.getLogger('exportExpenseOp');
      return (expenseFilter) => {
        // Object representing the current state of this operation.
        const operation = {
          label: `${capitalizeAllWords(sbLocalisationService.t('expense'))} Entries`,
          reportFormat: 'csv',
          isComplete: false,
          error: null,
          fileData: null,
        };

        downloadExpenseDataP(operation, expenseFilter);

        return operation;
      };

      async function downloadExpenseDataP(operation, expenseFilter) {
        const requestId = sbUuidService.get();

        try {
          // Kick off the operation
          const { payload } = await sbNotifiedOperationP(() => startExportingExpenseP(requestId, expenseFilter), {
            requestId,
            completionNotification: 'ExpenseExportReadyNotification',
            errorNotification: 'ExpenseExportFailureNotification',
            timeoutMs: 30000,
          });

          const request = {
            method: 'GET',
            url: payload.exportFileUrl || payload.expenseUrl, // expenseUrl can be deprecated after BB-6412 goes live
            responseType: 'arraybuffer'
          };

          const response = await $http(request);
          const file = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});
          operation.fileData = file;
        } catch (err) {
          log.error(`Failed to export expense for requestId: ${requestId}`, err);
          operation.error = err;
        } finally {
          $rootScope.$applyAsync(() => {
            operation.isComplete = true;
          });
        }

        // Returns a promise for exporting expenses into a CSV. Used by sbNotifiedOperation to begin processing.
        function startExportingExpenseP(requestId, expenseFilter) {
          const requestData = {
            requestId,
            fileFormat: 'csv',
            ...expenseFilter,
            includeLeads: featureActive('BB-6595'),
          };

          return sbGenericEndpointService.postPayloadP('/billing/expenses/export', `/${requestId}`, requestData, 'POST');
        }
      }
    }
  );
