import * as React from 'react';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';

import { hasBillingAccess } from 'web/services/user-session-management';

import { sectionRefsType, sectionKeys } from './types';
import { MatterNavigationPanel } from './MatterNavigationPanel';

export const MatterNavigationPanelContainer = ({
  sectionIsActive,
  sectionsWithErrors,
  scrollToSection,
  displayTrustSettings,
  isUtbmsEnabledForFirm,
  sectionRefs,
  isLead,
}) => {
  const { t } = useTranslation();
  const showBillingSettings = hasBillingAccess();

  const navPanelItems = [
    {
      title: 'Client / Contacts',
      display: true,
      isActive: sectionIsActive(sectionRefs[sectionKeys.matterContactsSection]),
      isErrorState: sectionsWithErrors.includes(sectionKeys.matterContactsSection),
      handleScroll: () => scrollToSection(sectionRefs[sectionKeys.matterContactsSection]),
    },
    {
      title: isLead ? 'Lead Details' : 'Matter Details',
      display: true,
      isActive: sectionIsActive(sectionRefs[sectionKeys.matterDetailsSection]),
      isErrorState: sectionsWithErrors.includes(sectionKeys.matterDetailsSection),
      handleScroll: () => scrollToSection(sectionRefs[sectionKeys.matterDetailsSection]),
    },
    {
      title: 'Staff',
      display: true,
      isActive: sectionIsActive(sectionRefs[sectionKeys.matterStaffSection]),
      isErrorState: sectionsWithErrors.includes(sectionKeys.matterStaffSection),
      handleScroll: () => scrollToSection(sectionRefs[sectionKeys.matterStaffSection]),
    },
  ];

  if (showBillingSettings) {
    navPanelItems.push(
      ...[
        {
          title: 'Billing Fees and Rates',
          display: true,
          isActive: sectionIsActive(sectionRefs[sectionKeys.billingFeesAndRatesSection]),
          isErrorState: sectionsWithErrors.includes(sectionKeys.billingFeesAndRatesSection),
          handleScroll: () => scrollToSection(sectionRefs[sectionKeys.billingFeesAndRatesSection]),
        },
        {
          title: `${t('trust')} Settings`,
          display: displayTrustSettings,
          isActive: sectionIsActive(sectionRefs[sectionKeys.trustSettingsSection]),
          isErrorState: sectionsWithErrors.includes(sectionKeys.trustSettingsSection),
          handleScroll: () => scrollToSection(sectionRefs[sectionKeys.trustSettingsSection]),
        },
        {
          title: 'Late Payment',
          display: true,
          isActive: sectionIsActive(sectionRefs[sectionKeys.latePaymentSection]),
          isErrorState: sectionsWithErrors.includes(sectionKeys.latePaymentSection),
          handleScroll: () => scrollToSection(sectionRefs[sectionKeys.latePaymentSection]),
        },
        {
          title: 'UTBMS',
          display: hasFacet(facets.utbms) && isUtbmsEnabledForFirm,
          isActive: sectionIsActive(sectionRefs[sectionKeys.utbmsSection]),
          isErrorState: sectionsWithErrors.includes(sectionKeys.utbmsSection),
          handleScroll: () => scrollToSection(sectionRefs[sectionKeys.utbmsSection]),
        },
      ],
    );
  }

  navPanelItems.push({
    title: featureActive('BB-11448') ? 'Communication' : 'Email Settings',
    display: true,
    isActive: sectionIsActive(sectionRefs[sectionKeys.communicationSettingsSection]),
    isErrorState: sectionsWithErrors.includes(sectionKeys.communicationSettingsSection),
    handleScroll: () => scrollToSection(sectionRefs[sectionKeys.communicationSettingsSection]),
  });

  if (showBillingSettings) {
    navPanelItems.push({
      title: 'Invoice Settings',
      display: true,
      isActive: sectionIsActive(sectionRefs[sectionKeys.invoiceSettingsSection]),
      isErrorState: sectionsWithErrors.includes(sectionKeys.invoiceSettingsSection),
      handleScroll: () => scrollToSection(sectionRefs[sectionKeys.invoiceSettingsSection]),
    });
  }

  return <MatterNavigationPanel navPanelItems={navPanelItems} />;
};

MatterNavigationPanelContainer.displayName = 'MatterNavigationPanelContainer';

MatterNavigationPanelContainer.propTypes = {
  sectionsWithErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  sectionIsActive: PropTypes.func.isRequired,
  scrollToSection: PropTypes.func.isRequired,
  displayTrustSettings: PropTypes.bool.isRequired,
  isUtbmsEnabledForFirm: PropTypes.bool.isRequired,
  isLead: PropTypes.bool.isRequired,
  sectionRefs: sectionRefsType.isRequired,
};
