import { store } from '@sb-itops/redux';
import * as ActionTypes from './action-types';

export const broadcastLogStreamMessage = ({ provider, payload }) => {
  store.dispatch({
    type: ActionTypes.BROADCAST_LOG_STREAM_MESSAGE,
    payload: {
      messageType: provider,
      ...payload,
    },
  });
};

export const updateIsCollecting = ({ isCollectingLogs }) => {
  store.dispatch({
    type: ActionTypes.UPDATE_IS_COLLECTING,
    payload: {
      isCollectingLogs,
    },
  });
};

export const clearLogItems = () => {
  store.dispatch({
    type: ActionTypes.CLEAR_LOG_ITEMS,
    payload: {},
  });
};

export const startLogCollection = () => {
  updateIsCollecting({ isCollectingLogs: true });
};

export const stopLogCollection = () => {
  updateIsCollecting({ isCollectingLogs: false });
};
