/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Modal } from '@sb-itops/react';
import classNames from 'classnames';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { setFileManagerInstalled } from 'web/redux/features/application-state/actions';
import { useDispatch } from 'react-redux';
import { featureActive, featureData } from '@sb-itops/feature';
import Styles from './Modal.module.scss';
import appleLogo from '../../../assets/apple-logo.svg';
import windowsLogo from '../../../assets/windows-logo.svg';

enum OS {
  Unknown = 'Unknown',
  Mac = 'Mac',
  Windows = 'Windows',
}

enum DownloadUrl {
  Mac = 'https://smokeball-installers.s3.ap-southeast-2.amazonaws.com/FileManager-smokeball-macos-production-Setup.pkg',
  Windows = 'https://smokeball-installers.s3.ap-southeast-2.amazonaws.com/FileManager-smokeball-windows-production-Setup.exe',
}

interface FileManagerDownloadModalProps {
  onClose: () => void;
}

const buttonContainer = {
  [OS.Mac]: [
    {
      id: '1',
      osDisplay: OS.Mac,
      downloadUrl: DownloadUrl.Mac,
      logo: appleLogo,
    },
  ],
  [OS.Windows]: [
    {
      id: '1',
      osDisplay: OS.Windows,
      downloadUrl: DownloadUrl.Windows,
      logo: windowsLogo,
    },
  ],
  // if can not tell the OS or possibly Linux.
  // fallback to Show all the download buttons.
  [OS.Unknown]: [
    {
      id: '1',
      osDisplay: OS.Mac,
      downloadUrl: DownloadUrl.Mac,
      logo: appleLogo,
    },
    {
      id: '2',
      osDisplay: OS.Windows,
      downloadUrl: DownloadUrl.Windows,
      logo: windowsLogo,
    },
  ],
};

// TypeScript doesn't ship `navigator.userAgentData`
// Note: `window.navigator.platform` is not duplicate.
// https://github.com/nodejs/node/pull/50385 or https://github.com/mdn/content/issues/14429 or https://github.com/microsoft/TypeScript/issues/49683
const IsMacOs = (): boolean => navigator.platform.toLowerCase().startsWith('mac');
const IsWindowsOS = (): boolean => navigator.platform.toLowerCase().startsWith('win');

const getOs = (): OS => {
  if (IsMacOs()) {
    return OS.Mac;
  }
  if (IsWindowsOS()) {
    return OS.Windows;
  }
  return OS.Unknown;
};

const getDownloadManagerSupportHubUrl = (): string => `${getSupportHubUrl()}/articles/24923388747671`;

export const FileManagerDownloadModal: FC<FileManagerDownloadModalProps> = ({ onClose }) => {
  const [selectedDownloadUrl, setSelectedDownloadUrl] = useState<string>();
  const dispatch = useDispatch();

  const onDownloadClick: (url: string) => void = async (downloadUrl: string) => {
    try {
      // Download the file
      const link = document.createElement('a');
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSelectedDownloadUrl(downloadUrl);
    } catch (error) {
      // Regardless of the auto download result, set the URL and display the next page.
      // The next page will include the link for manual download, allowing the user to retry.
      setSelectedDownloadUrl(downloadUrl);
    }
  };

  return isMobile ? (
    <div />
  ) : (
    <Modal
      className={classNames(Styles.modal, Styles.fileManagerDownloadModal)}
      title="Download File Manager"
      modalBodyClassName={Styles.downloadBody}
      body={
        selectedDownloadUrl ? (
          <>
            <div className={classNames('logo-pale', Styles.logo)} />
            <div className={Styles.content}>
              <h3>
                Thank you for downloading File Manager! <br />
                Just a few steps left
              </h3>
              <p>
                Your download will begin automatically. If it didn’t start, download{' '}
                <a href={selectedDownloadUrl} target="_blank" rel="noreferrer">
                  manually
                </a>
                .
              </p>
            </div>

            <div className={Styles.steps}>
              <div className={Styles.step}>
                <p className={Styles.stepHeading}>Step 1</p>
                <p>
                  Open the File Manager setup package from the downloads list at the top right corner of this window.
                </p>
              </div>
              <div className={Styles.step}>
                <p className={Styles.stepHeading}>Step 2</p>
                <p>
                  If prompted, click “Install anyway” and “Yes” on the system dialogs and follow the installation steps.
                </p>
              </div>
              <div className={Styles.step}>
                <p className={Styles.stepHeading}>Step 3</p>
                <p>Wait for the installation to finish. File Manager will open automatically when it’s done.</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classNames('logo-pale', Styles.logo)} />
            <div className={Styles.content}>
              <p>
                File Manager makes document management seamless. <br />
                Access, edit and sync changes to files back to Smokeball effortlessly. <br />
              </p>
              <p>
                Find out more about File Manager{' '}
                <a href={getDownloadManagerSupportHubUrl()} target="_blank" rel="noreferrer">
                  here
                </a>
                .
              </p>
            </div>

            <div className={Styles.inlineDisplay}>
              {buttonContainer[getOs()]?.map((item) => (
                <Button
                  key={item.id}
                  className={Styles.downloadButton}
                  onClick={() =>
                    onDownloadClick(
                      (featureActive('NUCWEB-787') && featureData('NUCWEB-787')?.[getOs()]) || item.downloadUrl,
                    )
                  }
                  size="full-width"
                >
                  <img className={Styles.icon} alt="icon" src={item.logo} />
                  Download for {item.osDisplay}
                </Button>
              ))}
            </div>

            <a
              onClick={() => {
                dispatch(setFileManagerInstalled({ isFileManagerInstalled: true }));
                onClose();
              }}
            >
              If you already have File Manager installed, click here.
            </a>
          </>
        )
      }
      footer={<> </>}
      onModalClose={onClose}
    />
  );
};
