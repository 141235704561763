import React from 'react';
import classnames from 'classnames';
import { Checkbox, PanelExpander, Spinner, CollapsibleHeader, SlidingToggle } from '@sb-itops/react';
import { Person, EventGroup } from 'types';
import moment from 'moment';
import Styles from './EventFilters.module.scss';
import { Schedule } from '../Schedule';

interface EventFiltersProps {
  loading: boolean;
  expanded: boolean;
  groupHeaderExpanded: boolean;
  staffHeaderExpanded: boolean;
  myHeaderExpanded: boolean;
  formerStaffEnabled: boolean;
  toggleExpanded: () => void;
  toggleStaffHeaderExpanded: () => void;
  toggleMyHeaderExpanded: () => void;
  toggleGroupHeaderExpanded: () => void;
  toggleFormerStaffEnabled: () => void;
  loggedInStaff: Person;
  setStaffFilter: (newFilter: { [key: string]: boolean }) => void;
  staffFilter: { [key: string]: boolean };
  currentUsers: Person[];
  formerUsers: Person[];
  eventGroups: EventGroup[];
  setSelectedCells: (selectedCells: any[]) => void;
  setSelectedView: (view: string) => void;
  selectedView: string;
  fetchMore: (date: moment.Moment, endDate: moment.Moment) => void;
}

export const EventFilters = ({
  expanded,
  myHeaderExpanded,
  groupHeaderExpanded,
  staffHeaderExpanded,
  toggleExpanded,
  toggleMyHeaderExpanded,
  toggleGroupHeaderExpanded,
  toggleStaffHeaderExpanded,
  loggedInStaff,
  setStaffFilter,
  staffFilter,
  currentUsers,
  formerUsers,
  formerStaffEnabled,
  toggleFormerStaffEnabled,
  eventGroups,
  loading,
  setSelectedCells,
  setSelectedView,
  selectedView,
  fetchMore,
}: EventFiltersProps) => (
  <div className={Styles.calendarInfo}>
    <PanelExpander
      className={classnames('panel-filter')}
      containerClassName={Styles.panelHeader}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    >
      <div className={Styles.container}>
        <div className={Styles.filters}>
          <div className={Styles.miniCalendar}>
            <Schedule
              editing={false}
              height={300}
              setSelectedCells={setSelectedCells}
              setSelectedView={setSelectedView}
              selectedView={selectedView}
              fetchMore={fetchMore}
            />
          </div>
          <div className={Styles.eventList}>
            <CollapsibleHeader
              text="My Calendar"
              onClick={toggleMyHeaderExpanded}
              name="myCalendar"
              collapsed={!loading && !myHeaderExpanded}
            >
              {loading ? (
                <div className={Styles.loading}>
                  <Spinner small />
                </div>
              ) : (
                <ul>
                  <li className={Styles.checkbox}>
                    <Checkbox
                      onChange={(value) => {
                        const newFilter = { ...staffFilter };
                        delete newFilter[loggedInStaff?.id];
                        if (!value) {
                          newFilter[loggedInStaff?.id] = true;
                        }
                        setStaffFilter(newFilter);
                      }}
                      className={Styles.checkboxInput}
                      style={{ accentColor: loggedInStaff?.colorFill }}
                      checked={!staffFilter[loggedInStaff?.id]}
                    />
                    <span className={Styles.label}>{loggedInStaff?.name}</span>
                  </li>
                </ul>
              )}
            </CollapsibleHeader>
            {!!eventGroups?.length && (
              <CollapsibleHeader
                text="Group Calendars"
                onClick={toggleGroupHeaderExpanded}
                name="groupCalendars"
                collapsed={!groupHeaderExpanded}
              >
                <ul>
                  <li className={Styles.checkbox}>
                    <Checkbox
                      onChange={(value) => {
                        const newFilter = eventGroups.reduce(
                          (acc, curr) => ({ ...acc, [curr.id]: !value }),
                          staffFilter,
                        );

                        setStaffFilter(newFilter);
                      }}
                      className={Styles.checkboxInput}
                      checked={eventGroups.reduce<boolean>((acc, curr) => {
                        if (staffFilter[curr.id]) {
                          return false;
                        }
                        return acc;
                      }, true)}
                    />
                    <span className={Styles.label}>Select All</span>
                  </li>
                  {eventGroups.map(
                    (group: EventGroup) =>
                      !group.isRemoved && (
                        <li key={group.id} className={Styles.checkbox}>
                          <Checkbox
                            className={Styles.checkboxInput}
                            onChange={(value) => {
                              const newFilter = { ...staffFilter };
                              delete newFilter[group.id];
                              if (!value) {
                                newFilter[group.id] = true;
                              }
                              setStaffFilter(newFilter);
                            }}
                            style={{ accentColor: group.color }}
                            checked={!staffFilter[group.id]}
                          />
                          <span className={Styles.label}>{group.name}</span>
                        </li>
                      ),
                  )}
                </ul>
              </CollapsibleHeader>
            )}
            <CollapsibleHeader
              text="Staff Calendars"
              onClick={toggleStaffHeaderExpanded}
              name="staffCalendars"
              collapsed={!loading && !staffHeaderExpanded}
            >
              {loading ? (
                <div className={Styles.loading}>
                  <Spinner small />
                </div>
              ) : (
                <ul>
                  <li className={Styles.checkbox}>
                    <Checkbox
                      className={Styles.checkboxInput}
                      onChange={(value) => {
                        let newStaffFilter = value
                          ? {}
                          : currentUsers.reduce((acc, curr) => {
                              if (curr.id === loggedInStaff?.id) {
                                return acc;
                              }
                              return { ...acc, [curr.id]: true };
                            }, staffFilter);
                        if (formerStaffEnabled) {
                          newStaffFilter = formerUsers.reduce(
                            (acc, curr) => ({ ...acc, [curr.id]: !value }),
                            newStaffFilter,
                          );
                        }

                        setStaffFilter(newStaffFilter);
                      }}
                      checked={[...formerUsers, ...currentUsers].reduce<boolean>((acc, curr) => {
                        if (curr.id === loggedInStaff?.id) {
                          return acc;
                        }
                        if (staffFilter[curr.id] && (formerStaffEnabled || !curr.isFormerStaff)) {
                          return false;
                        }
                        if (formerStaffEnabled && curr.isFormerStaff && staffFilter[curr.id] === undefined) {
                          return false;
                        }
                        return acc;
                      }, true)}
                    />
                    <span className={Styles.label}>Select All</span>
                  </li>
                  {currentUsers.map((staff: any) =>
                    staff.id === loggedInStaff?.id ? null : (
                      <li key={staff.id} className={Styles.checkbox}>
                        <Checkbox
                          className={Styles.checkboxInput}
                          onChange={(value) => {
                            const newFilter = { ...staffFilter };
                            delete newFilter[staff.id];
                            if (!value) {
                              newFilter[staff.id] = true;
                            }
                            setStaffFilter(newFilter);
                          }}
                          style={{ accentColor: staff.colorFill }}
                          checked={!staffFilter[staff.id]}
                        />
                        <span className={Styles.label}>{staff.name}</span>
                      </li>
                    ),
                  )}
                  {formerStaffEnabled &&
                    formerUsers.map((staff: any) => (
                      <li key={staff.id} className={Styles.checkbox}>
                        <Checkbox
                          className={Styles.checkboxInput}
                          onChange={(value) => {
                            const newFilter = { ...staffFilter };
                            if (newFilter[staff.id] === undefined) {
                              newFilter[staff.id] = false;
                            } else if (!value) {
                              newFilter[staff.id] = true;
                            } else {
                              newFilter[staff.id] = false;
                            }
                            setStaffFilter(newFilter);
                          }}
                          style={{ accentColor: staff.colorFill }}
                          checked={staffFilter[staff.id] === undefined ? false : !staffFilter[staff.id]}
                        />
                        <span className={Styles.label}>{`${staff.name} (Former Staff)`}</span>
                      </li>
                    ))}
                </ul>
              )}
            </CollapsibleHeader>
          </div>
        </div>
        <div className={Styles.formerStaffToggle}>
          <div onClick={toggleFormerStaffEnabled} className={Styles.formerStaffLabel}>
            Show Former Staff Calendars
          </div>
          <SlidingToggle scope="show-former-users" onChange={toggleFormerStaffEnabled} selected={formerStaffEnabled} />
        </div>
      </div>
    </PanelExpander>
  </div>
);

EventFilters.displayName = 'EventFilters';
