import { toggleShowSystemDateApi } from 'web/redux/route/home-billing-matter-transactions';
import { withReduxStore, MultiSelectFilters } from '@sb-itops/react';
import { connect } from 'react-redux';

const mapStateToProps = () => {
  const { showSystemDate } = toggleShowSystemDateApi.getState();

  const mappedProps = {
    filterOptions: [{ id: 'showSystemDate', name: 'Entered Date', selected: !!showSystemDate }],
    onSelect: toggleShowSystemDateApi.toggleShowSystemDate,
    showAllOption: false,
    id: 'showSystemDateMatter',
  };

  return mappedProps;
};

const ShowSystemDateToggleMatter = withReduxStore(connect(mapStateToProps)(MultiSelectFilters));

ShowSystemDateToggleMatter.displayName = 'ShowSystemDateToggleMatter';

export default ShowSystemDateToggleMatter;
