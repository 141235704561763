import React from 'react';
import PropTypes from 'prop-types';
import { SlidingToggle, CurrencyInput2, useTranslation } from '@sb-itops/react';

import Styles from './RemindersGeneralFilterDisplay.module.scss';

const RemindersGeneralFilterDisplay = ({
  sentRemindersOnly,
  overdueAmountChecked,
  overdueAmount,
  setFilter,
  onFilterChanged,
}) => {
  const { t } = useTranslation();
  const filtersValues = {
    overdueAmountChecked,
    overdueAmount,
  };

  const createNumberFilterChangeHandler = ({ field, newValue }) => {
    filtersValues[field] = newValue;
    return onFilterChanged({ value: filtersValues[field], field });
  };

  const createToggleChangeHandler = ({ field, newValue }) => {
    filtersValues[field] = newValue;
    return onFilterChanged({ value: filtersValues[field], field });
  };

  return (
    <div className={Styles.remindersGeneralFilterDisplay}>
      <div className={Styles.sliderRow}>
        {`At least ${t('currencySymbol')}`}
        <CurrencyInput2
          className={Styles.overdueAmountInput}
          value={overdueAmount}
          name="overdueAmount"
          hideDollar
          onChange={(e) =>
            createNumberFilterChangeHandler({
              field: 'overdueAmount',
              newValue: e.target.value,
            })
          }
        />
        overdue
        <SlidingToggle
          scope="overdue"
          onChange={(key, newValue) => {
            createToggleChangeHandler({ field: 'overdueAmountChecked', newValue });
          }}
          selected={overdueAmountChecked}
        />
      </div>
      <div className={Styles.sliderRow}>
        <div className={Styles.slider}>Reminders previously sent </div>
        <SlidingToggle
          scope="sentRemindersOnly"
          onChange={(key, newValue) => {
            setFilter('sentRemindersOnly', newValue);
          }}
          selected={sentRemindersOnly}
        />
      </div>
    </div>
  );
};

RemindersGeneralFilterDisplay.propTypes = {
  overdueAmount: PropTypes.number,
  overdueAmountChecked: PropTypes.bool,
  sentRemindersOnly: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

RemindersGeneralFilterDisplay.defaultProps = {
  overdueAmount: undefined,
  overdueAmountChecked: false,
  sentRemindersOnly: undefined,
};

export default RemindersGeneralFilterDisplay;
