import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as multiItemOrderedSelect from './index';

export const useMultipleItemOrderedSelection = ({ scope }) => {
  const dispatch = useDispatch();
  const { selectors, actions } = useScopedFeature(multiItemOrderedSelect, scope);

  const selectedOrderedItems = useSelector(selectors.getSelectedOrderedItems);
  const deselectAllOrderedItems = () => dispatch(actions.deselectAllOrderedItems());
  const toggleOrderedItems = (itemsToToggle) => dispatch(actions.toggleOrderedItems({ items: itemsToToggle }));

  return {
    selectedOrderedItems,
    deselectAllOrderedItems,
    toggleOrderedItems,
  };
};
