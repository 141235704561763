import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const MultiSelectCreatable = ({
  options,
  value,
  // callbacks and functions
  onSelectedIdsChanged,
  isMulti,
  isClearable,
  autoFocus,
}) => {
  // construct item options map to enable faster lookup
  const optionsMap = options.reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});

  return (
    <CreatableSelect
      isClearable={isClearable}
      autoFocus={autoFocus}
      menuPosition="fixed"
      isMulti={isMulti}
      options={options}
      value={(value || []).map((selectedId) => optionsMap[selectedId]).filter((i) => i)}
      onChange={(values) => {
        onSelectedIdsChanged(values || []);
      }}
    />
  );
};

MultiSelectCreatable.displayName = 'MultiSelectCreatable';

MultiSelectCreatable.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onSelectedIdsChanged: PropTypes.func.isRequired,
};

MultiSelectCreatable.defaultProps = {
  value: [],
  className: undefined,
  hasError: false,
  isMulti: true,
  isClearable: false,
  autoFocus: false,
};

export default MultiSelectCreatable;
