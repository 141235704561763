import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getCurrentConfigurationByMatterId,
  getAllCurrentBillingConfigurations,
} from '@sb-billing/redux/billing-configuration';

angular.module('@sb-billing/services').service('sbBillingConfigurationService', function (sbGenericCacheService, sbEndpointType) {
  'use strict';

  const that = this;
  const basePath = 'billing/billing-configuration';
  sbGenericCacheService.setupCache({
    name: 'sbBillingConfigurationService',
    sync: {
      endpoint: {
        type: sbEndpointType.SYNC_SINCE,
        stub: basePath
      },
      poll: 60,
      subscriptions: 'notifier.BillingMattersNotifications.BillingConfigurationUpdated'
    },
    updateRedux,
    clearRedux,
  });

  that.getAll = getAllCurrentBillingConfigurations;
  that.getByMatterId = getCurrentConfigurationByMatterId;
});
