'use strict';

const { depositSlipTransactionSources } = require('../entities/deposit-slip-transaction-sources');
const { depositSlipPdf } = require('./deposit-slip-pdf');

const isValidDepositSlipTransaction = (tx) =>
  tx.type.toUpperCase() === 'DEPOSIT' && !!tx.source && depositSlipTransactionSources.includes(tx.source);

module.exports = {
  isValidDepositSlipTransaction,
  depositSlipPdf,
};
