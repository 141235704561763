import { createSelector } from 'reselect';
import { broadcastUpdates } from '../cache-update';
import utilsFactory from '../utils-factory';
import { createReducer, syncTypes } from './reducer-factory';
import { optimisticUpdateFactory } from '../optimistic-update';
import { validateConfig } from './validate-config';
import store from '../store';

export const indexTypes = Object.freeze({
  ONE_TO_ONE: 'ONE_TO_ONE',
  ONE_TO_MANY: 'ONE_TO_MANY',
  MANY_TO_ONE: 'MANY_TO_ONE',
  MANY_TO_MANY: 'MANY_TO_MANY',
});

const cacheMap = {};

export default function factory({
  domain,
  name,
  keyPath,
  ngCacheName,
  indexes = [],
  immutable = true,
  syncType,
  changesetProcessor = ({ entities }) => entities,
}) {
  validateConfig({ domain, name, keyPath, ngCacheName, immutable, syncType, changesetProcessor });
  const { createActionName, registerReducer, getState } = utilsFactory({
    domain,
    name,
    immutable,
    autoClearOnLogout: true,
  });

  const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
    ngCacheName,
    keyPath,
  });

  const UPDATE_CACHE = createActionName('UPDATE_CACHE');
  const CLEAR_CACHE = createActionName('CLEAR_CACHE');

  const reducer = createReducer({ UPDATE_CACHE, CLEAR_CACHE, ngCacheName, keyPath, syncType });
  registerReducer(reducer);

  // #region selectors
  const getListSelector = createSelector(
    (state) => state,
    (state) => Object.values(state),
  );

  const getMap = () => getState().entities || {};
  const getLastUpdated = () => getState().lastUpdated;
  const getList = () => getListSelector(getMap());
  const getById = (id) => getMap()[id];

  // reselect the indexes.
  // eslint-disable-next-line no-shadow
  const createIndexSelector = ({ indexer, reducer, type }) => {
    const indexerFn = typeof indexer === 'function' ? indexer : (entity) => entity[indexer];
    const reducerFn = typeof reducer === 'function' ? reducer : undefined;
    return createSelector(
      (state) => state || [],
      (entities) =>
        entities.reduce((entityLookup, entity) => {
          const keys = [indexTypes.MANY_TO_ONE, indexTypes.MANY_TO_MANY].includes(type)
            ? indexerFn(entity)
            : [indexerFn(entity)];
          if (!keys || !keys.length) {
            return entityLookup;
          }

          keys.forEach((key) => {
            if ([indexTypes.ONE_TO_MANY, indexTypes.MANY_TO_MANY].includes(type)) {
              entityLookup[key] = entityLookup[key] || []; // eslint-disable-line no-param-reassign

              // if we are reducing the array we need to let the user do the accumulation of elements
              if (reducerFn) {
                entityLookup[key] = reducerFn(entityLookup[key], entity); // eslint-disable-line no-param-reassign
              } else {
                entityLookup[key].push(entity);
              }
            } else if (reducerFn) {
              entityLookup[key] = reducerFn(entityLookup[key], entity); // eslint-disable-line no-param-reassign
            } else {
              entityLookup[key] = entity; // eslint-disable-line no-param-reassign
            }
          });

          return entityLookup;
        }, {}),
    );
  };

  const indexSelectors = indexes.reduce((indexSelectorByName, indexDefinition) => {
    if (!indexDefinition.name || typeof indexDefinition.name !== 'string') {
      throw new Error(`Invalid index name: '${indexDefinition.name || 'no name'}'`);
    }

    if (typeof indexDefinition.indexer !== 'function' && typeof indexDefinition.indexer !== 'string') {
      throw new Error(`Invalid indexer type '${typeof indexDefinition.indexer}', must be string or function`);
    }

    if (!indexTypes[indexDefinition.type]) {
      throw new Error(
        `Invalid index type '${indexDefinition.type || ''}'. Valid types are: ${Object.values(indexTypes).join(',')}`,
      );
    }

    const createdSelector = createIndexSelector(indexDefinition);
    indexSelectorByName[indexDefinition.name] = () => createdSelector(getList()); // eslint-disable-line no-param-reassign
    return indexSelectorByName;
  }, {});

  const getIndex = (indexName) => {
    if (!indexSelectors[indexName]) {
      throw new Error(`No index named '${indexName || 'undefined'}' is configured for ${domain}/${name}`);
    }

    return indexSelectors[indexName]();
  };

  const getByIndex = ({ indexName, key }) => {
    const index = getIndex(indexName);
    return index[key];
  };
  // #endregion

  // #region actions

  // For use from generic cache to notify redux of new data
  const updateCache = ({ lastUpdated, entities }) => {
    try {
      const payload = changesetProcessor({
        entities,
        stateMap: getState(),
        stateList: getList(),
      });

      store.dispatch({
        type: UPDATE_CACHE,
        payload,
        meta: { lastUpdated },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Problem updating cache`, err);
    }
  };

  // For use when redux initiates the data update
  // Notifies the generic cache that there's new data to broadcast
  const updateCacheAndBroadcast = ({ entities, lastUpdated }) => {
    updateCache({ entities, lastUpdated });
    broadcastUpdates({ entities, ngCacheName, keyPath });
  };

  const clearCache = () => {
    store.dispatch({ type: CLEAR_CACHE });
  };
  // #endregion

  cacheMap[ngCacheName] = { getMap: () => getMap() };

  return {
    UPDATE_CACHE,
    CLEAR_CACHE,
    createActionName,
    getList,
    getMap,
    getById,
    getIndex,
    getByIndex,
    getLastUpdated,
    opdateCache,
    rollbackOpdateCache,
    updateCache,
    updateCacheAndBroadcast,
    clearCache,
    indexTypes,
  };
}

export { cacheMap, syncTypes };
