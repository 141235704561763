import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useTranslation } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { TrustDepositRequestModal } from './TrustDepositRequestModal';
import { createTrustDepositRequestFormSchema, convertToEmailTrustDepositRequest } from '../trust-deposit-request-form';
import { getValidateFn } from '../trust-deposit-request-form/TrustDepositRequestForm.container';

const SCOPE = 'trust-deposit-request-form';
const DEPOSIT_REQUEST_FORM_SCHEMA = createTrustDepositRequestFormSchema();
const LOG = getLogger('TrustDepositRequestModalContainer');
const DEPOSIT_REQUEST_ENDPOINT = `/billing/request-deposit/batch/:accountId/`;

const hooks = ({ onClose, debtorId, matterId, bankAccountId }) => ({
  useDepositRequestForm: () => {
    const { t } = useTranslation();

    const matterClientAddressRequiredForTrustDeposit = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);

    const { formSubmitting, formInitialised, onValidateForm, onSubmitFormWithValidation } = useForm({
      scope: SCOPE,
      schema: DEPOSIT_REQUEST_FORM_SCHEMA,
      validateFn: getValidateFn({ matterClientAddressRequiredForTrustDeposit, matterId }),
    });

    return {
      onSubmit: async () => {
        await onValidateForm(); // await is required as validateFn is async

        await onSubmitFormWithValidation({
          submitFnP: async (finalFormValues) => {
            try {
              await onSubmitDepositRequest({ depositRequestData: finalFormValues, debtorId, matterId, t });
              messageDisplay.success('The request deposit email has been sent');
              onClose();
            } catch (err) {
              messageDisplay.error(
                messageDisplay
                  .builder()
                  .text('Failed to send request for deposit')
                  .conditionalText(': {val}', err.message)
                  .conditionalText(
                    '. {0}',
                    err?.payload?.body?.message === emailMessages.notAllowedToSendEmailsServer
                      ? emailMessages.notAllowedToSendEmailsDisplay
                      : '',
                  ),
              );
              LOG.error(err);
            }
          },
        });
      },
      isSubmitDisabled: !formInitialised,
      isSubmitLocked: formSubmitting,
      scope: SCOPE,
      title: `${t('trust')} Deposit Request`,
      bankAccountId,
      isCreditCardDeposit: false,
    };
  },
});

const onSubmitDepositRequest = async ({ depositRequestData, debtorId, matterId, t }) => {
  const depositRequest = await convertToEmailTrustDepositRequest({
    depositRequestData,
    debtorId,
    matterId,
    interpolate: true,
    t,
  });

  if (featureActive('BB-13500')) {
    // NEW BB-13500
    return dispatchCommand({
      type: 'Integration.RequestDeposit',
      message: { emailRequests: [depositRequest] },
    }).then((responseBody) => {
      // RequestDeposit returns 207 if one or more requests fail.
      // Since we are sending only 1 request, if we get 207 it means failure. We manually throw so user see failure.
      if (responseBody.failureMatterIds && responseBody.failureMatterIds.length > 0) {
        throw new Error();
      }
      return responseBody;
    });
  }
  // OLD pre BB-13500
  return fetchPostP({
    path: DEPOSIT_REQUEST_ENDPOINT,
    fetchOptions: { body: JSON.stringify({ emailRequests: [depositRequest] }) },
  });
};

export const TrustDepositRequestModalContainer = withReduxProvider(composeHooks(hooks)(TrustDepositRequestModal));
TrustDepositRequestModalContainer.displayName = 'TrustDepositRequestModalContainer';

TrustDepositRequestModalContainer.propTypes = {
  bankAccountId: PropTypes.string.isRequired,
  debtorId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
