'use strict';

const { activityCategories } = require('../entities/constants');

function getApplicableActivityCategories({ utbmsEnabledForMatter, utbmsCodesRequiredByFirm }) {
  if (utbmsEnabledForMatter) {
    if (utbmsCodesRequiredByFirm) {
      return [activityCategories.UTBMS];
    }
    return [activityCategories.CUSTOM, activityCategories.UTBMS];
  }
  return [activityCategories.CUSTOM];
}

module.exports = {
  getApplicableActivityCategories,
};
