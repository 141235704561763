import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { capitalize } from '@sb-itops/nodash';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { createSelector } from 'reselect';
import { sort as sortItems } from '@sb-itops/sort';
import { getFirmDetails, getMap } from './firm';

export const getLoggedInUserId = () => authSelectors.getUserId(store.getState());

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbFirmManagementMbService',
  keyPath: 'details',
});

export const generateStaffOpdate = (firm, staff) => {
  const staffMember = {
    ...staff,
    name: [capitalize(staff.firstName), capitalize(staff.lastName)].join(' '),
  };
  const opDate = {
    ...firm,
  };
  if (!staffMember.SBaccess && staffMember.userId) {
    delete opDate.currentUsers[staffMember.userId];
  } else if (staffMember.userId) {
    opDate.currentUsers[staffMember.userId] = { personId: staffMember.id, userId: staffMember.userId };
  }
  // Cant supply an ID so cannot do opDates for new staff, only existing ones
  opDate.people[staffMember.id] = staffMember;
  if (!firm?.currentStaff?.find((staffId) => staffMember.id === staffId)) {
    opDate.currentStaff = [...(firm?.currentStaff || []), staffMember.id];
  }
  delete staffMember.userId;
  return { details: opDate };
};

export const createStaff = async (staffEntity) => {
  const path = `/firm-management/staff/:accountId/create-staff`;
  const fetchOptions = { body: JSON.stringify(staffEntity) };
  const res = await fetchPostP({ path, fetchOptions });

  // minimal opdate entity to provide user with instant feedback on UI
  const opdateEntity = generateStaffOpdate(getFirmDetails() || {}, { ...staffEntity, id: res.body.id });
  opdateCache({ optimisticEntities: [opdateEntity] });

  return res.body.id;
};

export const updateStaff = async (staffEntity) => {
  // minimal opdate entity to provide user with instant feedback on UI
  const opdateEntity = generateStaffOpdate(getFirmDetails() || {}, staffEntity);
  opdateCache({ optimisticEntities: [opdateEntity] });

  try {
    const path = `/firm-management/staff/:accountId/update-staff`;
    const fetchOptions = { body: JSON.stringify(staffEntity) };

    const res = await fetchPostP({ path, fetchOptions });

    return res.body.id;
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: [opdateEntity] });
    throw err;
  }
};

export const getCurrentStaffIds = () => getFirmDetails()?.currentStaff;

const getCurrentStaffSelector = createSelector(
  [
    (state) => (state.details && state.details.currentStaff) || [],
    (state) => (state.details && state.details.people) || {},
  ],
  (currentStaff, people) => currentStaff.map((personId) => people[personId]).filter((p) => !p || !p.isFormerStaff),
);

export const getCurrentStaff = () => getCurrentStaffSelector(getMap());

const getStaffOptionsSelector = createSelector(getCurrentStaffSelector, () => {
  const currentStaff = getCurrentStaff();
  const staffOptions = currentStaff.map((staff) => ({ label: staff.name, value: staff.id }));
  const sortedStaffOptions = sortItems(staffOptions, ['label'], ['ASC']);
  return sortedStaffOptions;
});

export const getStaffOptions = () => getStaffOptionsSelector(getMap());

/**
 * Warning: The setup user is a valid user that has a `userId` but is not a staff member, meaning this function will return `undefined`.
 *
 * @param {uuid} userId
 */
export const getCurrentStaffByUserId = (userId) => {
  if (getFirmDetails()) {
    const user = getFirmDetails().currentUsers[userId];
    return user && getFirmDetails().people[user.personId];
  }

  return undefined;
};

export const getStaffEmailDetails = ({ showDisplayName = true, userId = getLoggedInUserId() } = {}) => {
  const staff = getCurrentStaffByUserId(userId) || {
    name: '',
    email: '',
  };

  return {
    email: showDisplayName ? `"${staff.name}" <${staff.email}>` : staff.email,
    name: staff.name,
  };
};

export const getLoggedInStaff = () => getCurrentStaffByUserId(getLoggedInUserId());

/**
 * Get all staffs including former staff
 */
export const getAllStaff = () => {
  // Adapted from firm management mb angular service

  const firmDetails = getFirmDetails();
  if (firmDetails && firmDetails.currentStaff && firmDetails.currentStaff.length >= 0) {
    return firmDetails.currentStaff.map((personId) => firmDetails.people[personId]);
  }

  return []; // should never happen, but in case there's a data or sync issue this will guard against crashing
};

/**
 * Get all staffs including userIds
 */
export const getAllStaffWithUserIds = () => {
  const firmDetails = getFirmDetails();
  if (firmDetails && firmDetails.currentStaff && firmDetails.currentStaff.length >= 0) {
    const userIdMapping = Object.values(firmDetails.currentUsers || {}).reduce((acc, curr) => {
      acc[curr.personId] = curr.userId;
      return acc;
    }, {});
    return firmDetails.currentStaff.map((personId) => ({
      ...firmDetails.people[personId],
      userId: userIdMapping[personId],
    }));
  }

  return []; // should never happen, but in case there's a data or sync issue this will guard against crashing
};
