import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as forms from '@sb-itops/redux/forms2';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { depositFunds } from '@sb-billing/redux/transactions/create-transaction';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useTranslation } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import uuid from '@sb-itops/uuid';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { DepositFundsModal } from './DepositFundsModal';
import { getValidateFn } from '../deposit-funds-form';
import { DepositFundsFormSchema } from '../deposit-funds-form/DepositFundsFormSchema';

export const DEPOSIT_FUNDS_MODAL_ID = 'deposit-funds-modal';
const DEPOSIT_FUNDS_SCOPE = 'deposit-funds-form';
const localisationConfig = {
  depositReason: hasFacet(facets.reasonField),
  allowOverdraw: hasFacet(facets.allowOverdraw),
  autoGeneratedReference: hasFacet(facets.autoGeneratedReference),
  matterClientAddressRequiredForTrustDeposit: hasFacet(facets.matterClientAddressRequiredForTrustDeposit),
  matterClientRequiredForTrustDeposit: hasFacet(facets.matterClientRequiredForTrustDeposit),
  matterDescriptionRequiredForTrustDeposit: hasFacet(facets.matterDescriptionRequired),
  matterClientOrDescriptionRequired:
    hasFacet(facets.matterClientRequiredForTrustDeposit) || hasFacet(facets.matterDescriptionRequired),
  matterTrustBalanceByDate: hasFacet(facets.matterTrustBalanceByDate),
  bankNameForDepositFunds: hasFacet(facets.bankNameForDepositFunds),
  electronicPayment: hasFacet(facets.electronicPayment),
  supportsStatutoryDepositMatter: hasFacet(facets.statutoryDepositMatter),
};

const hooks = ({ onClose: onCloseProp, contactId, matterId, bankAccountId, isVisible, onClickLink }) => ({
  useSelectors: () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectors: formSelectors, operations: formOperations } = useScopedFeature(forms, DEPOSIT_FUNDS_SCOPE);
    const formState = useSelector(formSelectors.getFormState);

    const isSubmitting = formState.formSubmitting;
    const validate = async () => {
      dispatch(
        formOperations.validateForm({
          validateFn: getValidateFn({ ...localisationConfig }),
          schema: DepositFundsFormSchema,
        }),
      );
    };

    const onClose = onCloseProp || (() => setModalDialogHidden({ modalId: DEPOSIT_FUNDS_MODAL_ID }));

    const onDepositFunds = async () => {
      try {
        await validate();
        dispatch(
          await formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              const data = marshalData(formFieldValues, t);

              await depositFunds(data);

              if (
                data.accountType &&
                [bankAccountTypeEnum.TRUST, bankAccountTypeEnum.CONTROLLEDMONEY].includes(
                  data.accountType.toUpperCase(),
                ) &&
                data.pdfOnDeposit === true
              ) {
                onClickLink({ type: 'receipt', id: data.transactionId });
              } else {
                messageDisplay.success('Funds deposited successfully');
              }
              setModalDialogHidden({ modalId: DEPOSIT_FUNDS_MODAL_ID });
            },
          }),
        );
      } catch (err) {
        messageDisplay.error(messageDisplay.builder().text('Failed to deposit funds'));
      }
    };

    return {
      contactId,
      matterId,
      bankAccountId,
      scope: DEPOSIT_FUNDS_SCOPE,
      isVisible,
      onClose,
      isSubmitLocked: isSubmitting,
      onDepositFunds,
      localisationConfig,
    };
  },
});

const marshalData = (formFieldValues, t) => {
  const {
    matterId,
    contactId,
    effectiveDate,
    bankAccountId,
    bank,
    bankName,
    bankBranchNumber,
    comment,
    amount,
    drawer,
    reference,
    reason,
    source,
    pdfOnDeposit,
  } = formFieldValues;

  const drawerBankString = buildDrawerBankString(drawer, bank);
  const accountType = getBankAccountById(bankAccountId)?.accountType;
  const autoGeneratedReference = hasFacet(facets.autoGeneratedReference);

  const funds = {
    userId: getUserId(),
    accountId: getAccountId(),
    amount,
    bankAccountId,
    contactId,
    accountType,
    description: `${source ? t('capitalizeAllWords', { val: source.toLowerCase() }) : ''} deposit`,
    transactionId: uuid(),
    matterId,
    note: comment,
    reference,
    source,
    reason,
    timestamp: moment().toISOString(),
    effectiveDate: effectiveDate || +moment().format('YYYYMMDD'),
    bankName,
    bankBranchNumber,
    drawerBank: drawerBankString || undefined,
    pdfOnDeposit,
    saveReceiptNumberAsReference: autoGeneratedReference,
  };

  return funds;
};

function buildDrawerBankString(drawerId, bank) {
  const drawerContact = drawerId ? getContactById(drawerId) : null;
  const drawerName = drawerContact?.displayName || '';
  const separator = bank && drawerName ? ' / ' : '';
  return `${bank || ''}${separator}${drawerName}`;
}

export const DepositFundsModalContainer = withReduxProvider(composeHooks(hooks)(DepositFundsModal));

DepositFundsModalContainer.displayName = 'DepositFundsModalContainer';

DepositFundsModalContainer.propTypes = {
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  onClose: PropTypes.func,
  onClickLink: PropTypes.func,
  isVisible: PropTypes.bool,
};

DepositFundsModalContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  isVisible: false,
  onClose: undefined,
  printCheques: () => {},
  onClickLink: () => {},
};
