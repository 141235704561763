/**
 * @typedef {object} Contact
 * @property {string|undefined} salutation
 * @property {string|undefined} email
 */

/**
 * Format emails for use in 'to' address field
 * @param {Array.<Contact>} contacts
 * @returns {string}
 */
const formatContactsEmails = (contacts) =>
  contacts.reduce((emails, c) => {
    if (!c.email) {
      return emails;
    }
    const email = c.salutation ? `"${c.salutation}" <${c.email}>` : `${c.email}`;

    return emails ? `${emails}; ${email}` : email;
  }, '');

module.exports = {
  formatContactsEmails,
};
