import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSelector, useDispatch } from 'react-redux';
import { autoNumberingPrefixes, getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getMap as getAutoNumberingPrefixes } from '@sb-billing/redux/auto-numbering-prefixes';
import * as transactionNumberingTabFeature from 'web/redux/features/transaction-numbering-settings-form';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useState } from 'react';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { sortByProperty } from '@sb-itops/nodash';
import { useTranslation } from '@sb-itops/react';
import { TrustTransactionNumberingTab } from './TrustTransactionNumberingTab';

const { prefixType, isPreviouslyUsedPrefix } = autoNumberingPrefixes;

const SCOPE = 'trust-transaction-numbering';

const getPrefixesWithWarnings = (formState, components) => {
  const { formInitialised, fields } = formState;

  return formInitialised
    ? {
        receipt: isPreviouslyUsedPrefix({
          prefix: fields.receipt.prefix.value,
          type: prefixType.RECEIPT,
          components,
        }),
        transferOfFunds: isPreviouslyUsedPrefix({
          prefix: fields.transferOfFunds.prefix.value,
          type: prefixType.TRANSFER_OF_FUNDS,
          components,
        }),
        electronicPayments: isPreviouslyUsedPrefix({
          prefix: fields.electronicPayments.prefix.value,
          type: prefixType.ELECTRONIC_PAYMENT,
          components,
        }),
        trustToOffice: isPreviouslyUsedPrefix({
          prefix: fields.trustToOffice.prefix.value,
          type: prefixType.TRUST_TO_OFFICE,
          components,
        }),
      }
    : {
        receipt: false,
        transferOfFunds: false,
        electronicPayments: false,
        trustToOffice: true,
      };
};

const hooks = () => ({
  useScope: () => ({
    scope: SCOPE,
  }),
  useSelectors: () => {
    const { t } = useTranslation();
    const [bankAccountId, setBankAccountId] = useState('');
    const dispatch = useDispatch();

    const { selectors, operations } = useScopedFeature(transactionNumberingTabFeature, SCOPE);

    const components =
      (getAutoNumberingPrefixes()[bankAccountId] && getAutoNumberingPrefixes()[bankAccountId].components) || [];

    const activeTrustAccounts = getActiveTrustAccounts();
    const bankAccounts = sortByProperty(
      activeTrustAccounts.map((ba) => ({ value: ba.id, label: getBankAccountName(ba, t) })),
      'label',
      'asc',
      false,
    );

    const onBankAccountSelection = (newBankAccountId) => {
      setBankAccountId(newBankAccountId);
      // reset the form anytime we change bank account
      dispatch(operations.initialiseTrustForm({ bankAccountId: newBankAccountId }));
    };

    // Handle special cases:
    if (!!bankAccounts.length && !bankAccounts.find((ba) => ba.value === bankAccountId)) {
      // Our selected trust account is not in the list --> Select first trust account
      // This happens on first render or for example when we select trust account and then we go and close it
      onBankAccountSelection(bankAccounts?.[0]?.value || '');
    }

    const formState = useSelector(selectors.getFormState);
    const exampleNumbers = useSelector(selectors.getExampleNumbers);
    const prefixesWithWarnings = getPrefixesWithWarnings(formState, components);

    return {
      formState,
      exampleNumbers,
      prefixesWithWarnings,
      bankAccountId,
      bankAccounts,
      onBankAccountSelection,
      onSave: () => dispatch(operations.saveTrustForm({ bankAccountId })),
      onChange: (updatedFields) => dispatch(operations.updateTrustForm({ updatedFields })),
    };
  },
});

export const TrustTransactionNumberingTabContainer = withReduxProvider(
  composeHooks(hooks)(TrustTransactionNumberingTab),
);
