import React from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@sb-itops/react/drop-down-list';

import { printMethods, printMethodsByValue } from '@sb-billing/business-logic/cheques';

const defaultOptions = printMethods.reduce((acc, printMethod) => {
  if (!acc[printMethod]) {
    acc.push({ value: printMethod.value, label: printMethod.display });
  }
  return acc;
}, []);

const ChequePrintMethodsDropDown = ({ printMethodOptions, selectedPrintMethod, onChange, disabled }) => {
  const options = printMethodOptions || defaultOptions;
  const selectedOption = selectedPrintMethod && options.find((option) => option.value === selectedPrintMethod);
  return (
    <DropDownList options={options} selectedOption={selectedOption} onChange={onChange} disabled={disabled} stateless />
  );
};

ChequePrintMethodsDropDown.propTypes = {
  printMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  selectedPrintMethod: PropTypes.oneOf(Object.keys(printMethodsByValue)),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ChequePrintMethodsDropDown.defaultProps = {
  printMethodOptions: undefined,
  selectedPrintMethod: undefined,
  disabled: false,
};

export default ChequePrintMethodsDropDown;
