import React from 'react';
import PropTypes from 'prop-types';

import { Pill, SlidingToggle } from '@sb-itops/react';
import { Table, Column } from '@sb-itops/react/table';
import { staffTargetAssignmentType } from '@sb-billing/business-logic/staff-targets';

import { AddEditTargetGroup } from '../add-edit-target-group';
import { StaffTargetAssignmentTable } from '../staff-target-assignment-table';
import Styles from './StaffTargets.module.scss';

const renderHeaderCell = ({ label }) => label;

const renderNameCell = ({ rowData }) => (
  <div className={Styles.nameCell} key={rowData.id}>
    <span>{rowData.name}</span>
    {rowData.isInactive && <Pill label="Inactive" className={Styles.hiddenPill} />}
  </div>
);

export const StaffTargets = React.memo(
  ({
    // target group
    currentTargetGroup,
    currentTargetGroupId,
    isAddMode,
    targetGroups,
    targetGroupsLoading,
    showInactive,
    // target assignments
    staffFilter,
    staffFilterOptions,
    setStaffFilter,
    staffMembers,
    isStaffTargetAssignmentsLoading,
    activeTargetGroupOptions,
    activeTargetGroupLookup,
    // func & callbacks
    addNewTargetGroup,
    clearCurrentTargetGroup,
    setCurrentTargetGroupId,
    setShowInactive,
  }) => {
    const targetGroupsToDisplay = targetGroups.filter((targetGroup) => showInactive || !targetGroup.isInactive);

    return (
      <div className={Styles.staffTargets}>
        <p className={Styles.description}>
          Targets are used to set performance goals for staff. By creating target groups, you can easily assign the same
          goals to multiple staff members when needed. Creating target groups allows you to easily assign, remove, or
          update goals in one location.
        </p>
        {currentTargetGroupId || isAddMode ? (
          <AddEditTargetGroup
            currentTargetGroup={currentTargetGroup}
            targetGroupId={currentTargetGroupId}
            onClose={() => clearCurrentTargetGroup()}
          />
        ) : (
          <>
            <div className={Styles.targetsSection}>
              <div className={Styles.targetGroupActions}>
                <div className="link-button" onClick={() => addNewTargetGroup()}>
                  <i className="icon icon-add-3" />
                  ADD TARGET GROUP
                </div>
                <div className={Styles.showInactiveToggle}>
                  <SlidingToggle
                    scope="show-hidden-rate-sets-toggle"
                    onChange={(name, value) => setShowInactive(value)}
                    selected={showInactive}
                  />
                  <div onClick={() => setShowInactive(!showInactive)} className={Styles.showInactiveLabel}>
                    Show inactive
                  </div>
                </div>
              </div>
              <Table
                className="table-shadow"
                style={{
                  height: `${Math.min(600, 30 + targetGroupsToDisplay.length * 38)}px`,
                }}
                onRowClick={({ rowData }) => setCurrentTargetGroupId(rowData.id)}
                dataLoading={targetGroupsLoading}
                list={targetGroupsToDisplay}
              >
                <Column
                  dataKey="name"
                  label="Target Groups"
                  flexGrow={6}
                  className={Styles.nameColumn}
                  headerRenderer={renderHeaderCell}
                  cellRenderer={renderNameCell}
                />
              </Table>
            </div>
            <div className={Styles.targetsSection}>
              <StaffTargetAssignmentTable
                staffFilter={staffFilter}
                staffFilterOptions={staffFilterOptions}
                activeTargetGroupOptions={activeTargetGroupOptions}
                activeTargetGroupLookup={activeTargetGroupLookup}
                setStaffFilter={setStaffFilter}
                staffMembers={staffMembers}
                isStaffTargetAssignmentsLoading={isStaffTargetAssignmentsLoading}
              />
            </div>
          </>
        )}
      </div>
    );
  },
);

StaffTargets.displayName = 'StaffTargets';

StaffTargets.propTypes = {
  // target group
  currentTargetGroup: PropTypes.object,
  currentTargetGroupId: PropTypes.string,
  isAddMode: PropTypes.bool,
  targetGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isInactive: PropTypes.bool,
    }),
  ),
  targetGroupsLoading: PropTypes.bool,
  showInactive: PropTypes.bool,
  // target assignments
  staffFilter: PropTypes.string,
  staffFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      feeConfiguration: PropTypes.shape({
        id: PropTypes.string,
        staffTargetGroupId: PropTypes.string,
      }),
    }),
  ),
  isStaffTargetAssignmentsLoading: PropTypes.bool,
  activeTargetGroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  activeTargetGroupLookup: PropTypes.object,
  // function & callbacks
  addNewTargetGroup: PropTypes.func.isRequired,
  clearCurrentTargetGroup: PropTypes.func.isRequired,
  setCurrentTargetGroupId: PropTypes.func.isRequired,
  setShowInactive: PropTypes.func.isRequired,
  setStaffFilter: PropTypes.func.isRequired,
};

StaffTargets.defaultProps = {
  // target group
  currentTargetGroup: undefined,
  currentTargetGroupId: undefined,
  isAddMode: false,
  targetGroups: [],
  targetGroupsLoading: false,
  showInactive: false,
  // target assignments
  staffFilter: staffTargetAssignmentType.ALL,
  staffFilterOptions: [],
  staffMembers: [],
  isStaffTargetAssignmentsLoading: false,
  activeTargetGroupOptions: [],
  activeTargetGroupLookup: {},
};
