angular.module('sb.billing.webapp').directive('sbReduxList', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'transclude': {
      'headers': '?headers',
      'rows': '?rows',
      'footer': '?footer'
    },
    'scope': { data: '<', config: '<', tableClass: '@?'},
    'controller': 'SbReduxListController',
    'controllerAs': 'reduxListCtrl',
    'templateUrl': 'ng-components/redux-list/redux-list.html'
  };
});
