import { gql } from '@apollo/client';

const query = gql`
  query InitTrustChequePrintSettings {
    trustChequePrintSettings {
      id
      printingActive
      style
      printMethod
    }
  }
`;

const notificationIds = ['AccountsNotifications.TrustChequePrintSettingsUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitTrustChequePrintSettings = {
  query,
  notificationIds,
};
