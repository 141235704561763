'use strict';

const { paymentPlanStatuses } = require('../entities/constants');

const getPaymentPlanStatusByLabel = (status) => {
  switch (status) {
    case paymentPlanStatuses.ACTIVE:
      return 0;
    case paymentPlanStatuses.CLOSED:
      return 1;
    default:
      throw new Error(`Unknown status "${status}" for paymentPlan`);
  }
};

module.exports = {
  getPaymentPlanStatusByLabel,
};
