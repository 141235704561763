import { gql } from '@apollo/client';

const query = gql`
  query BankAccountsWithBalances(
    $bankAccountIds: [ID]
    $includeContactBalances: Boolean!
    $includeMatterBalances: Boolean!
    $bankAccountFilter: BankAccountFilter
    $balanceFilter: BankAccountBalanceFilter
  ) {
    bankAccounts(ids: $bankAccountIds, filter: $bankAccountFilter) {
      id
      accountType
      accountTypeDisplay
      accountName
      displayName
      location

      bankAccountBalances(filter: $balanceFilter) {
        id
        balance
        contactBalances @include(if: $includeContactBalances) {
          contactId
          matterId
          balance
          availableBalance
        }
        matterBalances @include(if: $includeMatterBalances) {
          matterId
          adjustment
          balance
          availableBalance
          protectedBalance
        }
      }
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BankAccountsWithBalances = {
  query,
  notificationIds,
};
