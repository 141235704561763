import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';

import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { ReportingNotificationSettingsDocument } from 'web/graphql/types/graphql';
import { DailyDigestPreference } from './DailyDigestPreference';

const Container = () => {
  const [dailyDigestOn, setDailyDigestOn] = useState(false);
  const { loading } = useQuery(ReportingNotificationSettingsDocument, {
    variables: {},
    fetchPolicy: 'network-only',
    onCompleted: async ({ reportingNotificationSettings }) => {
      if (!reportingNotificationSettings) {
        // Jason has said that if there is no record it will send the email
        setDailyDigestOn(true);
      } else {
        setDailyDigestOn(!!reportingNotificationSettings.sendDailySummaryEmail);
      }
    },
  });

  const toggleDigestPreference = async () => {
    const newDailyDigestSetting = !dailyDigestOn;
    try {
      const message = {
        sendDailySummaryEmail: newDailyDigestSetting,
      };

      await dispatchCommand({
        message,
        type: 'ItOps.Reporting.Smokeball.Messages.SaveReportNotificationSettings',
      });
      messageDisplay.success(`Daily digest emails have been turned ${newDailyDigestSetting ? 'on' : 'off'}`);
      setDailyDigestOn(newDailyDigestSetting);
    } catch (e) {
      messageDisplay.error(`Daily digest setting failed to save, please try again later.`);
    }
  };

  const sendNow = async () => {
    try {
      await dispatchCommand({
        message: {},
        type: 'ItOps.Reporting.Smokeball.Messages.SendEmailMessage',
      });
      messageDisplay.success(`Daily digest email has been sent`);
    } catch (e) {
      messageDisplay.error(`Daily digest email failed to send, please try again later.`);
    }
  };

  return loading ? (
    <i className="fa fa-spinner fa-pulse fa-fw" />
  ) : (
    <DailyDigestPreference {...{ dailyDigestOn, toggleDigestPreference, sendNow }} />
  );
};

export const DailyDigestPreferenceContainer = withApolloClient(Container);
