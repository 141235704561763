import React from 'react';

const withBlur = (WrappedComponent) => (props) => {
  const onBlur = () => {
    const onPostBlur = () => {
      if (props.onPostBlur) {
        props.onPostBlur();
      }
    };

    if (props.onBlur) {
      props.onBlur();
    }

    setTimeout(onPostBlur, 0);
  };

  return <WrappedComponent onBlur={onBlur} {...props} />;
};

export default withBlur;
