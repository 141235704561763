import uuid from '@sb-itops/uuid';
import { getById as getOperatingChequeById } from '@sb-billing/redux/operating-cheques';

/**
 * This is based off combine-cheques-op.js but modified to deal with operating cheques
 */
angular.module('sb.billing.webapp').factory('combineOperatingChequesOp', ($rootScope, $http, sbOperatingChequesService, sbNotifiedOperationP, sbLoggerService, sbLocalisationService) => {
  return (chequeIds) => {
    const log = sbLoggerService.getLogger('combineOperatingChequesOp');
    const requestId = uuid();
    const timeoutMs = chequeIds.length * 30000;

    // We could be executing this operation in response to newly created transactions, in which case we should wait for the cache to be updated.
    // The other possibility is that we are reprinting for existing transactions, in which case the entities should already be in the cache.
    const cancelCacheSubscriptionFn = $rootScope.$on('smokeball-data-update-sbOperatingChequesService', verifyChequesAreInCache);
    verifyChequesAreInCache();

    // Object representing the current state of this operation.
    const operation = {
      label: `Generating ${chequeIds.length} ${sbLocalisationService.t('cheque', {count: chequeIds.length})}`,
      isComplete: false,
      error: null,
      fileData: null,
      chequeIds,
      nbCheques: chequeIds.length,
      progress: {
        nbSteps: 0,
        maxSteps: 1,
        percentage: null
      }
    };

    function verifyChequesAreInCache() {
      const chequeEntities = chequeIds.map((chequeId) => getOperatingChequeById(chequeId));

      if (chequeEntities.length !== chequeIds.length) {
        log.info(`Waiting for ${chequeIds.length - chequeEntities.length} more cheque entities to arrive in the cache`);
        return;
      }

      const chequesWithoutNumbers = chequeEntities.reduce((val, cheque) => val && !cheque.chequeNumber, true);
      if (chequesWithoutNumbers) {
        log.info(`Waiting for cheques to be assigned their numbers`);
        return;
      }

      // Now that we've verified that all of the cheques are in the cache, we don't need to listen for updates anymore.
      log.info(`Verified that the required ${chequeIds.length} cheques are present in the cache`);
      cancelCacheSubscriptionFn();

      // Kick off the operation to build the cheque PDF.
      sbNotifiedOperationP(startBuildingChequePdfP, {
        requestId,
        completionNotification: 'ChequeReadyNotification',
        progressNotification: 'ChequeProgressNotification',
        timeoutMs
      })
      .then(onChequePdfComplete, undefined, onChequePdfProgress)
      .catch(onError);
    }

    // Operation object is given back for the async operations service to manage.
    return operation;

    // Returns a promise for combining of invoices into a single PDF. Used by sbNotifiedOperation to begin processing.
    function startBuildingChequePdfP() {
      return sbOperatingChequesService.createChequesPdfP(requestId, chequeIds);
    }

    // Called by sbNotifiedOperation whenever some progress is made during the cheque PDF operation.
    function onChequePdfProgress(msg) {
      const progress = operation.progress;
      ++progress.nbSteps;

      const newMaxProgress = _.get(msg, 'payload.maxProgress') || progress.maxSteps;
      progress.maxSteps = Math.max(newMaxProgress, progress.nbSteps);

      if (progress.maxSteps) {
        progress.percentage = Math.min((progress.nbSteps / progress.maxSteps) * 100, 99.5); // Don't go over 99.5 % for incomplete operation.
      }
    }

    // Called by sbNotifiedOperation when the cheque PDF operation completes.
    // Note that we still have a bit of work to do when the cheque PDF completes, mainly downloading the document content as base64.
    function onChequePdfComplete(msg) {
      const payload = msg.payload;
      const request = {
        method: 'GET',
        url: payload.previewUrl,
        responseType: 'arraybuffer'
      };

      log.info('send request', request);
      return $http(request)
        .then(response => {
          const file = new Blob([response.data], {type: 'application/pdf'});
          operation.isComplete = true;
          operation.progress.percentage = 100;
          operation.fileData = file;
        });
    }

    // The hopefully never called error handler.
    function onError(err) {
      log.error(`Failed to create cheque PDFs for requestId: ${requestId}`, err);
      operation.error = err;
      operation.isComplete = true;
    }
  };
});
