import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { selectors, actions } from 'web/redux/route/billing-support-debug';
import { BillingSupportDebug } from './BillingSupportDebug';

const mapStateToProps = (state) => ({
  showDebug: selectors.getShowDebug(state),
  supportsSaveCreditCard: hasFacet(facets.saveCreditCard),
});

const mapDispatchToProps = (dispatch) => {
  const { toggleShowDebug } = actions;
  return {
    toggleShowDebug: () => dispatch(toggleShowDebug()),
  };
};

export const BillingSupportDebugContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(BillingSupportDebug),
);

BillingSupportDebugContainer.displayName = 'BillingSupportDebugContainer';

BillingSupportDebugContainer.propTypes = {};
BillingSupportDebugContainer.defaultProps = {};
