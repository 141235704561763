import {
  SELECT_DATE_FILTER_TYPE,
  SELECT_BEFORE_DATE,
  SELECT_FROM_DATE,
  SELECT_TO_DATE,
  SELECT_STAFF_IDS,
  SET_HIDE_IF_ON_FINALISED_INVOICE,
  SET_HIDE_IF_ON_A_CHECK,
  TOGGLE_SHOW_FILTERS,
  SET_SHOW_DISBURSEMENTS,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS_PAID,
} from './types';

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});

export const selectDateFilterType = ({ dateFilterType }) => {
  if (!dateFilterType) {
    throw new Error('dateFilterType is required');
  }

  return {
    type: SELECT_DATE_FILTER_TYPE,
    payload: { dateFilterType },
  };
};

export const selectBeforeDate = ({ beforeDate }) => {
  if (!beforeDate) {
    throw new Error('beforeDate is required');
  }
  return {
    type: SELECT_BEFORE_DATE,
    payload: { beforeDate },
  };
};

export const selectFromDate = ({ fromDate }) => {
  if (!fromDate) {
    throw new Error('fromDate is required');
  }
  return {
    type: SELECT_FROM_DATE,
    payload: { fromDate },
  };
};

export const selectToDate = ({ toDate }) => {
  if (!toDate) {
    throw new Error('toDate is required');
  }

  return {
    type: SELECT_TO_DATE,
    payload: { toDate },
  };
};

export const selectStaffIds = ({ staffIds }) => ({
  type: SELECT_STAFF_IDS,
  payload: { staffIds },
});

export const setHideIfOnFinalisedInvoice = ({ hideIfOnFinalisedInvoice }) => ({
  type: SET_HIDE_IF_ON_FINALISED_INVOICE,
  payload: { hideIfOnFinalisedInvoice },
});

export const setHideIfOnACheck = ({ hideIfOnACheck }) => ({
  type: SET_HIDE_IF_ON_A_CHECK,
  payload: { hideIfOnACheck },
});

export const setShowDisbursements = ({ showDisbursements }) => ({
  type: SET_SHOW_DISBURSEMENTS,
  payload: { showDisbursements },
});

export const setShowAnticipatedDisbursements = ({ showAnticipatedDisbursements }) => ({
  type: SET_SHOW_ANTICIPATED_DISBURSEMENTS,
  payload: { showAnticipatedDisbursements },
});

export const setShowAnticipatedDisbursementsUnpaid = ({ showAnticipatedDisbursementsUnpaid }) => ({
  type: SET_SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID,
  payload: { showAnticipatedDisbursementsUnpaid },
});

export const setShowAnticipatedDisbursementsOverdue = ({ showAnticipatedDisbursementsOverdue }) => ({
  type: SET_SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE,
  payload: { showAnticipatedDisbursementsOverdue },
});

export const setShowAnticipatedDisbursementsPaid = ({ showAnticipatedDisbursementsPaid }) => ({
  type: SET_SHOW_ANTICIPATED_DISBURSEMENTS_PAID,
  payload: { showAnticipatedDisbursementsPaid },
});
