import composeHooks from '@sb-itops/react-hooks-compose';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import uuid from '@sb-itops/uuid';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import * as messageDisplay from '@sb-itops/message-display';
import { useState } from 'react';
import { DataBackup } from './DataBackup';

const MESSAGE_GROUP = 'BACKUP_REQUEST';

const hooks = () => ({
  useDataBackupCommand: () => {
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    return {
      saveDisabled,
      loading,
      onRequestBackup: async () => {
        try {
          setSaveDisabled(true);
          setLoading(true);
          await dispatchCommand({
            type: 'ItOps.Backup.CreateAccountBackup',
            message: { backupId: uuid(), email: getLoggedInStaff().email },
          });
          messageDisplay.success(
            messageDisplay
              .builder()
              .title('Request status')
              .text('Backup request initiated, you will be emailed with instructions when the backup is complete.')
              .group(MESSAGE_GROUP),
          );
          // We do not re-enable the save button to prevent multiple requests
        } catch (e: any) {
          messageDisplay.error(
            messageDisplay
              .builder()
              .text('Failed to request data backup, please contact support.')
              .group(MESSAGE_GROUP),
          );
          setSaveDisabled(false);
        }
        setLoading(false);
      },
    };
  },
});

export const DataBackupContainer = composeHooks(hooks)(DataBackup);
