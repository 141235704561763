import { featureActive } from '@sb-itops/feature';

const allowedIntervals = [6, 15];

export const getAllowedIntervals = () => {
  const allowedIntervalsCopy = [...allowedIntervals];
  if (featureActive('BB-4832')) {
    allowedIntervalsCopy.unshift(1);
  }

  return allowedIntervalsCopy;
};
