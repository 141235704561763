import React, { memo, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../spinner';
import Styles from './PreviewPdf.module.scss';

const downloadFileAsBlob = async (url) => {
  const response = await fetch(url);
  if (response.status === 200) {
    return response.blob();
  }
  throw new Error('Failed downloading file');
};

const PreviewPdf = memo(({ url, onDownloadFail }) => {
  const [trustedBlobState, setBlob] = useState();

  useEffect(() => {
    const setBlobInState = async () => {
      try {
        const blob = await downloadFileAsBlob(url);
        setBlob(URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })));
      } catch (ex) {
        onDownloadFail(ex);
      }
    };
    if (url && !trustedBlobState) {
      setBlobInState();
    }
    return () => {
      if (trustedBlobState) {
        URL.revokeObjectURL(trustedBlobState);
      }
    };
  }, [onDownloadFail, trustedBlobState, url]);

  return (
    <>
      {!trustedBlobState && <Spinner message="Loading PDF..." className={Styles.loadingIndicator} />}
      {trustedBlobState && (
        <div className={Styles.billsPreviewPdf}>
          <object
            type="application/pdf"
            className={Styles.bindHtmlSize}
            data={trustedBlobState}
            aria-label="PDF View"
          />
        </div>
      )}
    </>
  );
});

PreviewPdf.propTypes = {
  url: PropTypes.string,
  onDownloadFail: PropTypes.func,
};

PreviewPdf.defaultProps = {
  url: undefined,
  onDownloadFail: () => {},
};

export default PreviewPdf;
