'use strict';

/**
 * @param {object} params
 * @param {boolean} params.isToAddressReadOnly
 * @param {object} params.contact
 *
 * @returns {{
 *  contactFirstName: string,
 *  contactLastName: string,
 * }}
 */
function getContactFirstAndLastNames({ isToAddressReadOnly, contact }) {
  if (isToAddressReadOnly || !contact) {
    return {
      contactFirstName: '',
      contactLastName: '',
    };
  }

  if (contact.type === 'person' || contact.type === 'staff') {
    return {
      contactFirstName: contact.firstName,
      contactLastName: contact.lastName,
    };
  }

  return {
    contactFirstName: contact.displayNameFirstLast,
    contactLastName: '',
  };
}

module.exports = {
  getContactFirstAndLastNames,
};
