import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Styles from './WarningList.module.scss';

const WarningList = memo(({ list }) => {
  if (list.length === 0) {
    return null;
  }

  return (
    <div className={Styles.warning}>
      {list.map(({ text }, index) => (
        <span key={index}>{text}</span>
      ))}
    </div>
  );
});

WarningList.propTypes = {
  // I will set the list to be objects with just text so if we want in the future we can extend the component options
  // without needing to modify any code in the containers that we already have.
  list: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
};

WarningList.defaultProps = {
  list: [],
};

export default WarningList;
