angular.module('sb.billing.webapp').component('sbDataInvoices', {
    'require': { sbComposeCtrl: '^sbCompose' },
    'bindings': {composeKey: '@', invoiceNumbers: '<?'},
    'controller': function ($scope, sbLoggerService, sbInvoicingService) {
      'use strict';

      const ctrl = this;
      const log = sbLoggerService.getLogger('sbDataInvoices');

      $scope.$on('smokeball-data-update-sbInvoicingService', updateData);

      ctrl.$onInit = function () {
        log.info('composeKey', ctrl.composeKey);
        updateData();
      };

      ctrl.$onChanges = function (changesObj) {
        if (changesObj.invoiceNumbers) {
          updateData();
        }
      };

      function updateData() {
        if (_.isArray(ctrl.invoiceNumbers)) {
          ctrl.sbComposeCtrl.setComposeData(sbInvoicingService.getInvoicesByNumber(ctrl.invoiceNumbers), ctrl.composeKey);
        }
      }

    }

  }
);
