import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { getList as getContactList, getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { falsyGuard } from '@sb-itops/type-helpers';
import { AddPhoneMessage, IAddPhoneMessageProps, TContactSummary, TSelectable } from './AddPhoneMessage';

export const AddPhoneMessageContainer = (props: IAddPhoneMessageProps) => {
  const [selectedPersonContact, setSelectedPersonContact] = useState<TContactSummary>();
  const [selectedNonPersonContact, setSelectedNonPersonContact] = useState<TContactSummary>();
  const [message, setMessage] = useState('');
  const [urgent, setUrgent] = useState(false);
  const [pleaseCallBack, setPleaseCallBack] = useState(false);
  const [willCallAgain, setWillCallAgain] = useState(false);
  const [availablePhoneNumbers, setAvailablePhoneNumbers] = useState<TSelectable[]>([]);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<TSelectable[]>([]);

  const { personContacts, nonPersonContacts } = useMemo(() => {
    const persons = getContactList().filter((c) => c.type === 'Person' || c.type === 'Staff');
    const nonPersons = getContactList().filter((c) => c.type !== 'Person' && c.type !== 'Staff');

    const personContactsOut: TContactSummary[] = getContactTypeAheadSummaries(persons).map((s) => ({
      ...s,
      display: s.displayFirst,
    }));

    const nonPersonContactsOut: TContactSummary[] = getContactTypeAheadSummaries(nonPersons).map((s) => ({
      ...s,
      display: s.displayFirst,
    }));

    return { personContacts: personContactsOut, nonPersonContacts: nonPersonContactsOut };
  }, []);

  useEffect(() => {
    if (!props.loading) {
      setSelectedPersonContact(undefined);
      setSelectedNonPersonContact(undefined);
      setMessage('');
      setUrgent(false);
      setPleaseCallBack(false);
      setWillCallAgain(false);
      setAvailablePhoneNumbers([]);
      setSelectedPhoneNumbers([]);
    }
  }, [!!props.loading]);

  useEffect(() => {
    if (pleaseCallBack) {
      setWillCallAgain(false);
    }
  }, [pleaseCallBack]);

  useEffect(() => {
    if (willCallAgain) {
      setPleaseCallBack(false);
    }
  }, [willCallAgain]);

  // when a person selected clear and select phone PhoneNumbers
  // if cleared, clear phone numbers (desktop behaviour)
  useEffect(() => {
    if (selectedPersonContact) {
      const found = getContactById(selectedPersonContact.id);
      if (found) {
        const phoneNumbers: typeof availablePhoneNumbers = [];
        if (found.cell) {
          phoneNumbers.push({ value: 'cell', label: found.cell });
        }
        if (found.phone) {
          phoneNumbers.push({ value: 'home', label: found.phone });
        }
        if (found.phone2) {
          phoneNumbers.push({ value: 'work', label: found.phone2 });
        }
        setAvailablePhoneNumbers(phoneNumbers);
        setSelectedPhoneNumbers(phoneNumbers);
      }
    } else {
      setAvailablePhoneNumbers([]);
      setSelectedPhoneNumbers([]);
    }
  }, [selectedPersonContact]);

  // if we select a person, auto-select their org
  useEffect(() => {
    if (nonPersonContacts && selectedPersonContact) {
      const representativeId = (selectedPersonContact.representativeOfs || [])[0];
      if (!representativeId) {
        return;
      }
      const nonPerson = nonPersonContacts.find((c) => c.id === representativeId);
      if (nonPerson) {
        setSelectedNonPersonContact(nonPerson);
      }
    }
  }, [selectedPersonContact, nonPersonContacts]);

  useEffect(() => {
    /* Message title:
     * [<PersonName> | "Someone"] called[ on <PhoneNumber>]
     *
     * Message format:
     *
     * [Urgent - ][Plese Call Back | Will Call Again]
     *
     * [<PersonName>][ of <Organisation>]
     *
     * [Phone: <PhoneNumbers>, (<PhoneType>)]
     *
     * [<Message>]
     *
     * Call taken by <StaffName>
     */

    const personDisplay = selectedPersonContact?.display || '';
    const nonPersonDisplay = selectedNonPersonContact?.display || '';
    const displayPhones = (selectedPhoneNumbers || [])
      .map((phNo) => {
        if (phNo.label === phNo.value) {
          return phNo.label.trim();
        }
        return `${phNo.label} (${phNo.value})`.trim();
      })
      .filter(falsyGuard);

    const memoSubject = [`${personDisplay || 'Someone'} Called`, selectedPhoneNumbers[0]?.label]
      .filter(falsyGuard)
      .join(' on ');

    const line1 = [
      urgent ? 'Urgent' : '',
      pleaseCallBack ? 'Please Call Back' : '',
      willCallAgain ? 'Will Call Again' : '',
    ]
      .filter(falsyGuard)
      .join(' - ');

    const line2 = [personDisplay, nonPersonDisplay].filter(falsyGuard).join(' of ');

    const line3 = displayPhones.length
      ? [`Phone: ${displayPhones[0]}`, ...displayPhones.slice(1)].filter(falsyGuard).join(', ')
      : '';

    const line4 = message.trim();

    const line5 = `Call taken by ${getLoggedInStaff().name}`;

    const memoDetails = [line1, line2, line3, line4, line5].filter(falsyGuard).join('\n\n');

    const categories = ['Phone Message', urgent ? 'Urgent' : null].filter(falsyGuard);

    props.onFieldValueUpdated('subject', memoSubject);
    props.onFieldValueUpdated('categories', categories);
    props.onFieldValueUpdated('note', memoDetails);
    props.onFieldValueUpdated('dueDate', moment().format('YYYYMMDD'));
  }, [
    selectedPersonContact,
    selectedNonPersonContact,
    message,
    urgent,
    willCallAgain,
    pleaseCallBack,
    selectedPhoneNumbers,
  ]);

  const transitiveProps = {
    urgent,
    setUrgent,
    willCallAgain,
    setWillCallAgain,
    pleaseCallBack,
    setPleaseCallBack,
    personContacts,
    nonPersonContacts,
    selectedPersonContact,
    setSelectedPersonContact,
    selectedNonPersonContact,
    setSelectedNonPersonContact,
    message,
    setMessage,
    availablePhoneNumbers,
    selectedPhoneNumbers,
    setSelectedPhoneNumbers,
  };

  return <AddPhoneMessage {...{ ...props, ...transitiveProps }} />;
};
