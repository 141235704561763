import { useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  calculateFeeDetails,
  extractFeeSchedule,
  getPaymentSource,
  getPaymentFormConfiguration,
  isFirmCardSavingEnabledForBankAccount,
} from '@sb-billing/business-logic/payment-provider/services';
import { PAYMENT_SOURCE } from '@sb-billing/business-logic/payment-source';
import { paymentFormTypes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { preCharge as preChargeProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { TakePaymentNowForm } from './TakePaymentNowForm';

const hooks = (props) => ({
  useSelectors: () => {
    const {
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      triggerChargeFormSubmit,
      onChargeFormDataChange,

      operatingAccountId,
      activeProviderType,
      activeProviderFormattedSettings,
      formData,
      paymentSourceSelected,
      paidByContactDetails,
      onUpdateFieldValues,
      formSubmitting,
    } = props;
    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();

    const { paidById, saveCardDetails } = formData;

    const paymentFormConfiguration = getPaymentFormConfiguration({
      formattedProviderSpecificSettings: activeProviderFormattedSettings,
      providerType: activeProviderType,
      bankAccountId: operatingAccountId,
      bankAccountType: bankAccountTypeEnum.OPERATING,
      providerSpecificFields: {
        paymentFormType: saveCardDetails ? paymentFormTypes.SAVE_DETAILS : paymentFormTypes.CHARGE, // Whether form is used for charging or saving details
        contactSummary: paidById ? paidByContactDetails : undefined,
      },
    });

    const contactPaymentMethodOptions = getContactPaymentMethodOptions({
      contact: paidByContactDetails,
      providerType: activeProviderType,
      bankAccountId: operatingAccountId,
    });

    const isCardSavingEnabled =
      paymentSourceSelected?.paymentSource === PAYMENT_SOURCE.creditCard &&
      isFirmCardSavingEnabledForBankAccount({
        formattedProviderSpecificSettings: activeProviderFormattedSettings,
        providerType: activeProviderType,
        bankAccountId: operatingAccountId,
        bankAccountType: bankAccountTypeEnum.OPERATING,
      });

    // Make sure we show only payment method relevant to selected payment source
    paymentFormConfiguration.enabledPaymentMethods = filterEnabledPaymentMethods({
      paymentMethods: paymentFormConfiguration.enabledPaymentMethods,
      paymentSource: paymentSourceSelected,
      providerType: activeProviderType,
    });

    // Calculate new fees
    const clientIsCoveringFee = activeProviderFormattedSettings.clientCoversFeeOnPayments;

    const feeSchedule = clientIsCoveringFee
      ? extractFeeSchedule({
          providerType: activeProviderType,
          formattedProviderSpecificSettings: activeProviderFormattedSettings,
          bankAccountId: operatingAccountId,
        })
      : undefined;

    const feeDetails = clientIsCoveringFee
      ? calculateFeeDetails({
          providerType: activeProviderType,
          feeSchedule,
          desiredAmountInCents: formData?.amount || 0,
          providerSpecificFields: chargeFormData,
        })
      : {};

    const onHandlePreCharge = async ({ providerType: _providerType, providerSpecificFields }) =>
      preChargeProvider({ fetchPostP, providerType: _providerType, providerSpecificFields });

    return {
      contactPaymentMethodOptions,
      activeProviderType,
      clientIsCoveringFee,
      feeDetails,
      isCardSavingEnabled,
      isReady: true,
      // form fields
      formData,
      paymentSourceSelected,
      onUpdateFieldValues,
      formSubmitting,

      // Underlying charge forms props
      paymentFormConfiguration,
      onChargeFormDataChange: (chargeFormDataChange) => {
        setChargeFormData(chargeFormDataChange); // save locally to calculate correct fees
        onChargeFormDataChange(chargeFormDataChange);
      },
      onChargeFormSubmit,
      onChargeFormReadyForSubmit,
      triggerChargeFormSubmit,
      onPreCharge: onHandlePreCharge,
    };
  },
});

const getContactPaymentMethodOptions = ({ contact, providerType, bankAccountId }) => {
  const contactPaymentMethods = contact?.customerBillingConfiguration?.paymentMethods || [];

  const contactPaymentMethodOptions = contactPaymentMethods.reduce((acc, pm) => {
    const [expirationMonth, expirationYear] = pm.expiry.split('/');
    const isNotExpiredCard = new Date(expirationYear, expirationMonth, 0) > new Date();
    const isCardForActiveProvider = providerType === pm.provider;
    const isCardForBankAccount = bankAccountId === pm.bankAccount?.id;

    if (isNotExpiredCard && isCardForActiveProvider && isCardForBankAccount) {
      const expirationYearFormatted = expirationYear.length === 2 ? expirationYear : expirationYear.slice(-2); // only 2 digits year
      const expirationMonthFormatted = expirationMonth.padStart(2, '0');

      acc.push({
        label: `${pm.description || ''} ${pm.display} ${expirationMonthFormatted}/${expirationYearFormatted}`,
        value: pm.token,
        data: pm,
      });
    }

    return acc;
  }, []);

  // Add None option for manual payment
  contactPaymentMethodOptions.push({ label: 'Enter new card details', value: 'None', data: {} });

  return contactPaymentMethodOptions;
};

// Payment form may support multiple payment methods. We want to hide payment methods other then the payment source selected.
// For example, if we select eCheque as a payment source, we don't want to support credit card in the charge form.
const filterEnabledPaymentMethods = ({ paymentMethods, paymentSource, providerType }) =>
  (paymentMethods || []).filter(
    (pm) =>
      paymentSource?.paymentSource ===
      getPaymentSource({ providerType, providerSpecificFields: { paymentMethod: pm } }),
  );

export const TakePaymentNowFormContainer = composeHooks(hooks)(TakePaymentNowForm);

TakePaymentNowFormContainer.displayName = 'TakePaymentNowFormContainer';

TakePaymentNowFormContainer.propTypes = {
  operatingAccountId: PropTypes.string.isRequired,
  activeProviderType: PropTypes.string.isRequired,
  activeProviderFormattedSettings: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  paymentSourceSelected: PropTypes.object.isRequired,
  onUpdateFieldValues: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  paidByContactDetails: PropTypes.object,
  paidByContactDetailsLoading: PropTypes.bool.isRequired,
  onChargeFormDataChange: PropTypes.func.isRequired,
  onChargeFormSubmit: PropTypes.func.isRequired,
  onChargeFormReadyForSubmit: PropTypes.func.isRequired,
  triggerChargeFormSubmit: PropTypes.bool.isRequired,
};

TakePaymentNowFormContainer.defaultProps = {
  paidByContactDetails: {},
};
