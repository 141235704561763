import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import * as Styles from './Tooltip.module.scss';

export const UnpaidTooltip = ({ unpaid, overdue }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.content} onClick={(e) => e.stopPropagation()}>
      <div>
        <div>Due:</div>
        <div>Overdue:</div>
        <div>Total unpaid:</div>
      </div>
      <div className={Styles.moneyColumn}>
        <div>{t('cents', { val: unpaid - overdue })}</div>
        <div>{t('cents', { val: overdue })}</div>
        <div>{t('cents', { val: unpaid })}</div>
      </div>
    </div>
  );
};

UnpaidTooltip.displayName = 'UnpaidTooltip';

UnpaidTooltip.propTypes = {
  overdue: PropTypes.number.isRequired,
  unpaid: PropTypes.number.isRequired,
};
