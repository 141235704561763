import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, PaginationSelector, Spinner, MessageBar } from '@sb-itops/react';
import { entryType as activityEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import { NoBillingAccessWarning } from '@sb-billing/react';

import { FeeTable, QuickFeeEntry } from 'web/components';
import { FeePopOutEditorContainer } from 'web/containers';
import { BulkActionsButton } from 'web/react-redux';

import Styles from './MatterFeeEntries.module.scss';

export const MatterFeeEntries = ({
  hasBillingAccess,
  activities,
  activityDataLoading,
  billingIncrementsMins,
  bulkActions,
  bulkActionsDisabled,
  currentFee,
  currentFeePage,
  feeDataLoading,
  fees,
  hidePagination,
  isBulkCreateInvoicesInProgress,
  isEditableMatter,
  isGeneratingPDFReport,
  matter,
  matterId,
  onClickLink,
  onCreateInvoice,
  onFeeListPageChange,
  onGenerateCSVReport,
  onGeneratePDFReport,
  onNewEntry,
  onRowClick,
  onRowSelect,
  onSetCurrentFee,
  onSetShowFeeSidePanel,
  onSort,
  onStaffMemberChange,
  registeredForGst,
  scope,
  selectedFeeIds,
  selectedStaffMember,
  showDateField,
  showFeeDateColumn,
  showFeeSidePanel,
  showMatterColumn,
  showMatterField,
  showStaffField,
  showStaffInitialsColumn,
  showTaskColumn,
  showTaxColumns,
  sortBy,
  sortDirection,
  staffMemberOptions,
  tasks,
  taxRateBasisPoints,
  totalNumberOfFeePages,
  userPrefersDurationsAsUnits,
  utbmsEnabledForFirm,
  utbmsCodesRequiredByFirm,
}) => (
  <div className={classnames('master-detail-panel', Styles.matterFeeEntries)}>
    <div className={Styles.feeListAndPopoutSection}>
      <div className={classnames('panel-body', Styles.feeListSection)}>
        {hasBillingAccess && (
          <>
            {/* Action bar */}
            <div className="ribbon panel">
              <Button onClick={onNewEntry} disabled={!isEditableMatter}>
                New Time Entry
              </Button>
              <Button
                onClick={() => onCreateInvoice(matterId)}
                disabled={!isEditableMatter || isBulkCreateInvoicesInProgress}
                title={
                  isBulkCreateInvoicesInProgress
                    ? 'Please wait for the bulk invoices to complete before creating new ones'
                    : undefined
                }
              >
                Create Invoice
              </Button>
              <BulkActionsButton bulkActions={bulkActions} disabled={bulkActionsDisabled} />
              <div className={Styles.btnSpacer} />
              <Button className={Styles.download} onClick={onGeneratePDFReport}>
                {isGeneratingPDFReport && <Spinner small />}
                Print
              </Button>
              <Button className={Styles.download} onClick={onGenerateCSVReport}>
                CSV
              </Button>
            </div>

            {/* Quick fee entry section */}
            <div className={Styles.quickFeeEntrySection}>
              <QuickFeeEntry
                scope={scope}
                activities={activities}
                tasks={tasks}
                quickAddDisabled={!selectedStaffMember || activityDataLoading}
                showDateField={showDateField}
                showMatterField={showMatterField}
                showStaffField={showStaffField}
                billingIncrementsMins={billingIncrementsMins}
                matter={matter}
                showTasksField={utbmsEnabledForFirm}
                utbmsCodesRequiredByFirm={utbmsCodesRequiredByFirm}
                preferDurationAsUnits={userPrefersDurationsAsUnits}
                staffRateConfig={selectedStaffMember}
                staffMemberOptions={staffMemberOptions}
                onStaffMemberChange={onStaffMemberChange}
                registeredForGst={registeredForGst}
                taxRateBasisPoints={taxRateBasisPoints}
              />
            </div>

            {isBulkCreateInvoicesInProgress && (
              <div className={Styles.messageBar}>
                <MessageBar messageType="warn">
                  A bulk invoice request is currently being processed - this table will update with new entries on
                  completion.
                </MessageBar>
              </div>
            )}

            {/* Fee table section */}
            <div className={Styles.feeTableSection}>
              <FeeTable
                selectable
                onToggleFees={onRowSelect}
                selectedFeeIds={selectedFeeIds}
                currentFeeId={currentFee?.id}
                currentFeePage={currentFeePage}
                dataLoading={feeDataLoading}
                fees={fees}
                showFeeDateColumn={showFeeDateColumn}
                showMatterColumn={showMatterColumn}
                showStaffInitialsColumn={showStaffInitialsColumn}
                showTaxColumns={showTaxColumns}
                showTaskColumn={showTaskColumn}
                sortBy={sortBy}
                sortDirection={sortDirection}
                taxRateBasisPoints={taxRateBasisPoints}
                totalNumberOfFeePages={totalNumberOfFeePages}
                onClickLink={onClickLink}
                onClickRow={onRowClick}
                onFeeListPageChange={onFeeListPageChange}
                onSort={onSort}
              />
            </div>
            <PaginationSelector
              name="matterFeeEntries"
              className={Styles.paginationSection}
              hidePagination={hidePagination}
              numberOfPagesDisplayed={10}
              selectedPage={currentFeePage}
              totalNumberOfPages={totalNumberOfFeePages}
              onPageChange={onFeeListPageChange}
            />
          </>
        )}
        {!hasBillingAccess && <NoBillingAccessWarning />}
      </div>
      {hasBillingAccess && (
        <div className={Styles.popoutEditorSection}>
          <FeePopOutEditorContainer
            feeId={currentFee?.id}
            feeList={fees}
            isExpanded={showFeeSidePanel}
            onFeeChange={onSetCurrentFee}
            onNavigateToInvoice={(invoiceId) => onClickLink({ type: 'invoice', id: invoiceId })}
            onSetIsExpanded={onSetShowFeeSidePanel}
            scope={`${scope}/fee-pop-out-editor`}
          />
        </div>
      )}
    </div>
    <div className={Styles.feePrint}>
      <iframe id="feePdf" title="smokeball-report-viewer" />
    </div>
  </div>
);

MatterFeeEntries.displayName = 'MatterFeeEntries';

const FeeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  matter: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
});

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

MatterFeeEntries.propTypes = {
  hasBillingAccess: PropTypes.bool.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isBillable: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(activityEntryTypes)),
      units: PropTypes.number,
      isTaxInclusive: PropTypes.bool,
      isTaxExempt: PropTypes.bool,
      rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
      allStaffRate: PropTypes.number,
      ratesPerStaff: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.string.isRequired,
          rate: PropTypes.number.isRequired,
        }).isRequired,
      ),
      description: PropTypes.string.isRequired,
      category: PropTypes.oneOf(Object.values(activityCategories)),
    }).isRequired,
  ).isRequired,
  activityDataLoading: PropTypes.bool.isRequired, // Temporary hack until we get the activities on init
  matter: PropTypes.shape({
    billingConfiguration: PropTypes.shape({
      isUtbmsEnabled: PropTypes.bool.isRequired,
      billingType: PropTypes.string,
    }).isRequired,
  }),
  billingIncrementsMins: PropTypes.number.isRequired,
  scope: PropTypes.string.isRequired,
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentFeePage: PropTypes.number.isRequired,
  currentFee: FeeType,
  onSetCurrentFee: PropTypes.func.isRequired,
  showFeeSidePanel: PropTypes.bool.isRequired,
  onSetShowFeeSidePanel: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  feeDataLoading: PropTypes.bool,
  onClickLink: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onNewEntry: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onFeeListPageChange: PropTypes.func.isRequired,
  selectedStaffMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rate: PropTypes.number,
  }),
  onStaffMemberChange: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalNumberOfFeePages: PropTypes.number.isRequired,
  showDateField: PropTypes.bool,
  showFeeDateColumn: PropTypes.bool,
  showMatterColumn: PropTypes.bool,
  showMatterField: PropTypes.bool,
  showStaffField: PropTypes.bool,
  showStaffInitialsColumn: PropTypes.bool,
  showTaskColumn: PropTypes.bool,
  showTaxColumns: PropTypes.bool,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  taxRateBasisPoints: PropTypes.number,
  registeredForGst: PropTypes.bool,
  selectedFeeIds: PropTypes.object.isRequired,
  userPrefersDurationsAsUnits: PropTypes.bool,
  utbmsEnabledForFirm: PropTypes.bool.isRequired,
  utbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
  hidePagination: PropTypes.bool,
  matterId: PropTypes.string,
  isBulkCreateInvoicesInProgress: PropTypes.bool.isRequired,
  isEditableMatter: PropTypes.bool,
  bulkActions: PropTypes.any.isRequired,
  bulkActionsDisabled: PropTypes.bool,
  isGeneratingPDFReport: PropTypes.bool.isRequired,
  onGeneratePDFReport: PropTypes.func.isRequired,
  onGenerateCSVReport: PropTypes.func.isRequired,
};

MatterFeeEntries.defaultProps = {
  currentFee: undefined,
  feeDataLoading: false,
  selectedStaffMember: undefined,
  showFeeDateColumn: undefined,
  showDateField: undefined,
  showMatterField: undefined,
  showMatterColumn: undefined,
  showStaffField: undefined,
  showStaffInitialsColumn: undefined,
  showTaskColumn: undefined,
  showTaxColumns: undefined,
  taxRateBasisPoints: undefined,
  hidePagination: false,
  registeredForGst: false,
  userPrefersDurationsAsUnits: undefined,
  matter: undefined,
  matterId: undefined,
  isEditableMatter: undefined,
  bulkActionsDisabled: undefined,
};
