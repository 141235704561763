angular.module('sb.billing.webapp').directive('sbClock', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'scope': { elapsedSeconds: '=', activeSinceUnix: '=' },
    'link': function link(scope, element) {
      var interval,
        hNode = element[0].querySelectorAll('span.hours')[0],
        mNode = element[0].querySelectorAll('span.mins')[0],
        sNode = element[0].querySelectorAll('span.secs')[0];

      function drawClock() {
        var h, m, s;

        s = scope.elapsedSeconds + (scope.activeSinceUnix ? (moment().unix() - scope.activeSinceUnix) : 0);

        h = Math.floor(s / 3600);
        m = Math.floor((s % 3600) / 60);
        s = Math.floor(s % 60);

        hNode.textContent = ''+ _.padLeft(h ? '' + h : '', 2, '0');
        mNode.textContent = ''+ _.padLeft(m ? '' + m : '', 2, '0');
        sNode.textContent = ''+ _.padLeft(s ? '' + s : '', 2, '0');
      }

      interval = setInterval(drawClock, 1000);

      scope.$on('$destroy', function() {
        clearInterval(interval);
      });

      drawClock();
    },
    'templateUrl': 'ng-components/timers/components/clock/clock.html'
  };
});
