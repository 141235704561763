import moment from 'moment';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { subscriptionStatusByType } from '@sb-finance/business-logic/subscription/entities/constants';
import { getSubscription } from '@sb-finance/redux/subscription';
import { getIsFirmOwner } from 'web/services/user-session-management';
import { withReduxStore } from '@sb-itops/react';

import { TrialExpiryIndicator } from './TrialExpiryIndicator';

const mapStateToProps = () => {
  const subscription = getSubscription();

  // Only display the component during the trial phase
  if (!subscription || subscription.status !== subscriptionStatusByType.TRIALING) {
    return {};
  }

  const trialPeriodStart = moment(subscription.trialPeriodStart).toDate();
  const trialPeriodEnd = moment(subscription.trialPeriodEnd).toDate();

  const trialDaysTotal = Math.floor(moment.duration(moment(trialPeriodEnd).diff(trialPeriodStart)).asDays());
  const trialDayCurrent = Math.ceil(moment.duration(moment().diff(trialPeriodStart)).asDays());

  const isSubscribed = !!subscription.externalPaymentMethodIds?.length > 0;
  const tooltipText = isSubscribed
    ? `You have successfully subscribed to Smokeball! We won't bill you until your trial period is complete.`
    : undefined;

  return {
    isSubscribed,
    tooltipText,
    trialDayCurrent,
    trialDaysTotal,
  };
};

export const TrialExpiryIndicatorContainer = withReduxStore(
  connect(
    mapStateToProps,
    null,
  )((props) => {
    const [showContactFirmOwnerModal, setShowContactFirmOwnerModal] = useState(false);

    const onShowContactFirmOwnerModal = () => setShowContactFirmOwnerModal(true);
    const onHideContactFirmOwnerModal = () => setShowContactFirmOwnerModal(false);

    const onUpgradeClick = () => {
      if (getIsFirmOwner()) {
        props.onClickLink({ type: 'manageSubscription' });
      } else {
        onShowContactFirmOwnerModal();
      }
    };

    return (
      <TrialExpiryIndicator
        {...props}
        onUpgradeClick={onUpgradeClick}
        showContactFirmOwnerModal={showContactFirmOwnerModal}
        onCloseContactFirmOwnerModal={onHideContactFirmOwnerModal}
      />
    );
  }),
);

TrialExpiryIndicatorContainer.displayName = 'TrialExpiryIndicatorContainer';
TrialExpiryIndicatorContainer.propTypes = {};
