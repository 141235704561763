import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Styles from './Accordian.module.scss';
import { ExpandCollapseToggler } from '../expand-collapse-toggler';

export const Accordian = React.memo(({ className, onSelect, selected, tree, collapseTreeOnDeselect }) => {
  const [expandedBranches, setExpandedBranches] = useState(() => getExpandedBranches(tree, selected));
  const onClick = (id, data) => {
    if (id === selected) {
      onSelect();
      if (collapseTreeOnDeselect) {
        setExpandedBranches({
          ...expandedBranches,
          [id]: false,
        });
      }
      return;
    }
    onSelect(id, data);
  };
  const onToggle = (id) => {
    setExpandedBranches({
      ...expandedBranches,
      [id]: !expandedBranches[id],
    });
  };

  return (
    <div className={className}>
      {tree.map((branch) => (
        <div key={branch.id || 'no-id'}>
          <div
            className={classnames(Styles.branch, selected === branch.id && Styles.selected)}
            onClick={() => onClick(branch.id, branch.data)}
          >
            {branch.leaves && branch.leaves.length ? (
              <ExpandCollapseToggler
                className={Styles.toggler}
                isExpanded={expandedBranches[branch.id]}
                onToggled={() => onToggle(branch.id)}
              />
            ) : (
              <div className={Styles.spacer} />
            )}
            <div className={Styles.branchTitle}>{branch.display}</div>
          </div>
          {branch.leaves && (
            <div
              // Setting the maxHeight here is a hack to make it possible to animate the
              // child items collapsing/expanding when the branch is selected.
              // This is required as you cannot transition a height value if the value that is being
              // transitioned to isn't hardcoded.
              // The `40` here is as just bigger than the hardcoded height of the leaves (36px).
              // If that value ever changes (the height value in Styles.leaf), this value will need
              // to change too.
              style={{ maxHeight: expandedBranches[branch.id] ? 40 * branch.leaves.length : 0 }}
              className={Styles.leaves}
            >
              {branch.leaves.map((leaf) => (
                <div
                  key={leaf.id}
                  className={classnames(Styles.leaf, selected === leaf.id && Styles.selected)}
                  onClick={() => onClick(leaf.id, leaf.data)}
                >
                  {leaf.display}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
});

function getExpandedBranches(tree, selected) {
  if (!selected) {
    return {};
  }
  const selectedBranch = tree.find((branch) => branch.leaves && branch.leaves.find((leaf) => leaf.id === selected));

  if (selectedBranch) {
    return {
      [selectedBranch.id]: true,
    };
  }

  return {};
}

Accordian.displayName = 'Accordian';

Accordian.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.any,
  tree: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      display: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.string),
      leaves: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          display: PropTypes.string.isRequired,
          data: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    }),
  ),
  collapseTreeOnDeselect: PropTypes.bool,
};

Accordian.defaultProps = {
  className: undefined,
  selected: undefined,
  tree: undefined,
  collapseTreeOnDeselect: false,
};

export default Accordian;
