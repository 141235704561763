import PropTypes from 'prop-types';
import { withOnLoad } from '@sb-itops/react/hoc';
import { todayAsInteger } from '@sb-itops/date';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getRegion } from '@sb-itops/region';
import { getById as getAccountById } from '@sb-billing/redux/bank-account';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useTranslation } from '@sb-itops/react';
import AddBankFeeInterestForm from './AddBankFeeInterestForm';
import { AddBankFeeInterestSchema } from './AddBankFeeInterestSchema';
import { validateFn } from './validation';

const { getMatterBalance } = selectors;
const REGION = getRegion();
const getBalanceLabel = (accountType, t) => {
  const labels = {
    [bankAccountTypeEnum.TRUST]: `${t('trust')} `,
    [bankAccountTypeEnum.CONTROLLEDMONEY]: `${t('CMA')} `,
  };
  return `${labels[accountType] || ''}Balance`;
};

const hooks = (props) => ({
  useState: () => {
    const { t } = useTranslation();
    const { matterId: defaultMatterId, scope, bankAccountId, isFee } = props;
    const {
      formSubmitting,
      formInitialised,
      formFields,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFields,
      onValidateForm,
    } = useForm({ scope, schema: AddBankFeeInterestSchema, validateFn });

    const { accountOpenedDate, accountType } = getAccountById(bankAccountId);
    const { effectiveDate, matterId, amount, internalNote, balanceAfterChange } = formFields;

    const getBalanceAfterCharge = ({ amount: amountLatest, matterId: matterIdSelected }) => {
      const actualAmount = isFee ? -amountLatest : +amountLatest;
      const currentBalance = getBankAccountBalance(bankAccountId, matterIdSelected);
      return currentBalance + actualAmount;
    };

    return {
      scope,
      region: REGION,
      accountOpenedDate,
      matterReadOnly: !!defaultMatterId,
      balanceLabel: getBalanceLabel(accountType, t),
      balanceAfterChange,
      effectiveDate,
      matterId,
      amount,
      internalNote,
      formDisabled: formSubmitting,
      formInitialised,
      submitFailed,
      onLoad: () => {
        const fieldValues = {
          effectiveDate: todayAsInteger(),
          matterId: defaultMatterId,
          amount: undefined,
          internalNote: undefined,
          balanceAfterChange: getBankAccountBalance(bankAccountId, defaultMatterId),
          bankAccountId,
        };

        onInitialiseForm(fieldValues);
        return onClearForm;
      },
      onUpdateFields,
      onValidateForm,
      onMatterUpdate: ({ amount: amountLatest, matterId: matterIdSelected }) => {
        const newBalanceAfterChange = getBalanceAfterCharge({ amount: amountLatest, matterId: matterIdSelected });
        onUpdateFields({ balanceAfterChange: newBalanceAfterChange, matterId: matterIdSelected });
      },
      onAmountUpdate: ({ amount: amountLatest, matterId: matterIdSelected }) => {
        const newBalanceAfterChange = getBalanceAfterCharge({ amount: amountLatest, matterId: matterIdSelected });
        onUpdateFields({ balanceAfterChange: newBalanceAfterChange, amount: amountLatest });
      },
    };
  },
});

const getBankAccountBalance = (bankAccountId, matterId) =>
  bankAccountId && matterId
    ? getMatterBalance(getBankAccountBalanceState(), { bankAccountId, matterId, balanceType: balanceTypes.BALANCE })
    : 0;

export const AddBankFeeInterestFormContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(AddBankFeeInterestForm)),
);

AddBankFeeInterestFormContainer.displayName = 'AddBankFeeInterestFormContainer';

AddBankFeeInterestFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string.isRequired,
  isFee: PropTypes.bool.isRequired,
  allowFutureDate: PropTypes.bool.isRequired,
};
AddBankFeeInterestFormContainer.defaultProps = {
  matterId: undefined,
};

export default AddBankFeeInterestFormContainer;
