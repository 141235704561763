import { gql } from '@apollo/client';

const query = gql`
  query InitialCalendarSyncStatus {
    initialCalendarSyncStatus {
      id
      status
    }
  }
`;

const notificationIds = [
  'CalendarSyncSettingsNotifications.MicrosoftCalendarInitialSyncStatusUpdated',
  'CalendarSyncSettingsNotifications.MicrosoftCalendarSyncSettingsUpdated',
];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitialCalendarSyncStatus = {
  query,
  notificationIds,
};
