angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/preferences/email-settings';

  $stateProvider
    .state('preferences.email-settings', {
      templateUrl: BASE_DIRECTORY + '/email-settings.html',
      url: '/email-settings',
      data: {
        navHighlights: ['email-settings']
      }
    });
});