import { gql } from '@apollo/client';

const query = gql`
  query MatterTypeConfiguration($matterTypeId: ID!) {
    matterTypeConfiguration(matterTypeId: $matterTypeId) {
      matterTypeId
      possibleClients
      items {
        id
        providerId
        name
      }
    }
  }
`;

const notificationIds = [];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterTypeConfiguration = {
  query,
  notificationIds,
};
