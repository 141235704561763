import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getList,
  getById,
} from '@sb-billing/redux/billing-events';

angular
  .module('@sb-billing/services')
  .service('sbBillingEventService', function(
    sbGenericCacheService,
    sbEndpointType
  ) {
    'use strict';

    const that = this;

    sbGenericCacheService.setupCache({
      name: 'sbBillingEventService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/event' },
        poll: 60,
        subscriptions: 'notifier.BillingSharedNotifications.BillingEventUpdated'
      },
      updateRedux,
      clearRedux,
    });

    that.getById = getById;
    that.getList = getList;
  });
