import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { MatterCommunicationSettings } from './MatterCommunicationSettings';

const mapStateToProps = (state, { formInitialised, formFields, formDisabled, formSubmitting, submitFailed }) => {
  const { ccAddresses, bccAddresses, documentSendingPreferences, eBillingSite, otherMethod } = formFields;

  return {
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    submitFailed,
    // form fields
    ccAddresses,
    bccAddresses,
    documentSendingPreferences,
    eBillingSite,
    otherMethod,
    // others
  };
};

const mapDispatchToProps = (dispatch, { onFieldValuesUpdated }) => ({
  onFieldValuesUpdated,
});

export const MatterCommunicationSettingsContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(MatterCommunicationSettings),
);

MatterCommunicationSettingsContainer.displayName = 'MatterCommunicationSettingsContainer';

MatterCommunicationSettingsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
};

MatterCommunicationSettingsContainer.defaultProps = {
  matterId: undefined,
};
