import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import { BANK_BALANCE_TYPE, getBalanceType, isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { validateSingle as validateSingleEmail } from '@sb-itops/email';
import TrustStatementFilter from './TrustStatementFilter';

const { periodOptions, periodOption } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const mapStateToProps = (state, { filters }) => ({
  periodOptions,
  contacts: getContactTypeAheadSummaries(),
  allowEmailReport: hasFacet(facets.allowEmailAttachment),
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      const staff = getLoggedInStaff();
      const defaultState = {
        isMatterContactBalanceType: isMatterContactBalanceType(),
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        includeFirmHeader: false,
        includeFirmLogo: false,
        showInvoiceNumbersForTransactions: hasFacet(facets.showInvoiceNumbersForTransactions),
        showInvoicesFor: 'matter',
        // no UI filter but needed for region behaviour
        hidePaidBy: getBalanceType() !== BANK_BALANCE_TYPE.matterContact,
        errors: {
          matter: true,
        },
        sendTo: staff ? staff.email : undefined,
        runByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
      };

      if (hasFacet(facets.deleteTransaction)) {
        defaultState.showDeletedTransactions = false;
      }

      onFiltersChanged(defaultState);
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onMatterSelected: (matter) =>
    matter
      ? dispatch(
          onFiltersChanged({
            ...filters,
            // in AU, for Law Society compliance reasons, we need to show the matter description
            matter: {
              id: matter.id,
              display: getMatterDisplayById(matter.id, false, hasFacet(facets.includeDescriptionInMatterDisplay)),
            },
            errors: {
              ...filters.errors,
              matter: false,
            },
          }),
        )
      : dispatch(
          onFiltersChanged({
            ...filters,
            matter: undefined,
            errors: {
              ...filters.errors,
              matter: filters.showInvoicesFor === 'matter',
            },
          }),
        ),

  onContactSelected: (option) =>
    option
      ? onFiltersChanged({
          ...filters,
          contact: { id: option.value, display: option.label },
          errors: {
            ...filters.errors,
            contact: false,
          },
        })
      : onFiltersChanged({
          ...filters,
          contact: undefined,
          errors: {
            ...filters.errors,
            contact: filters.showInvoicesFor === 'contact',
          },
        }),

  onShowInvoicesFor: (showInvoicesFor) => {
    const staff = getLoggedInStaff();
    onFiltersChanged({
      ...filters,
      showInvoicesFor,
      errors: {
        ...filters.errors,
        matter: showInvoicesFor === 'matter' && !filters.matter,
        contact: showInvoicesFor === 'contact' && !filters.contact,
        email: showInvoicesFor === 'allMatters' && !validateSingleEmail(staff.email, { allowDisplayName: false }),
      },
    });
  },

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const TrustStatementFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(TrustStatementFilter)),
);

TrustStatementFilterContainer.displayName = `TrustStatementFilterContainer`;

export default TrustStatementFilterContainer;
