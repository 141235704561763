import { store } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { opdateCache, rollbackOpdateCache } from './index';
import { getById as getAutoNumberDefinitionByAccountId } from '../auto-number-definition';

export const createMatter = async ({
  accountId,
  matterTypeId,
  clientRole,
  clientIds,
  otherSideRole,
  otherSideIds,
  matterNumber,
  description,
  attorneyResponsibleId,
  personAssistingId,
  originatingAttorneyId,
  isLead,
  openedDate,
  closedDate,
  leadOpenedDate,
  leadClosedDate,
  leadClosedReason,
  referralType,
  referrerId,
}) => {
  let matterId;
  await store.dispatch(async () => {
    const autoNumberDefinition = getAutoNumberDefinitionByAccountId(accountId);
    const useAutoReference = autoNumberDefinition && autoNumberDefinition.useAutoReference;
    const referenceNumber = useAutoReference ? undefined : matterNumber;
    let matterForOpdate;
    try {
      const body = {
        matterTypeId,
        clientRole,
        clientIds,
        matterNumber: referenceNumber,
        otherSideRole,
        otherSideIds,
        description,
        status: 'Open', // should be 'Open' on matter creation
        personResponsibleStaffId: attorneyResponsibleId,
        personAssistingStaffId: personAssistingId,
        originatingStaffId: originatingAttorneyId,
        isLead,
        openedDate,
        closedDate,
        leadOpenedDate,
        leadClosedDate,
        leadClosedReason,
        referralType,
        referrerId,
      };

      const fetchOptions = { body: JSON.stringify(body) };
      const result = await fetchPostP({
        path: `/matter-management/matter/create/:accountId/`,
        fetchOptions,
      });

      // get matterid from response and perform opdate for matter entity
      // there's a small possibility for race condition here, where the
      // matter is created so fast that by the time this matterId is returned
      // a notification is already sent and a sync is finished before the opdate
      // below is fired off, in which case the opdate will overwrite the actual record
      // if this ever happens then we need to consider removing the opdate here
      matterId = result.body.matterId;
      matterForOpdate = {
        matterId,
        matterTypeId,
        clientCustomerIds: clientIds,
        clientRole,
        accountId,
        matterNumber: referenceNumber,
        status: 'Open',
        description,
        attorneyResponsibleId,
        personAssistingId,
        originatingAttorneyId,
        isLead,
        openedDate,
        closedDate,
        leadOpenedDate,
        leadClosedDate,
        leadClosedReason,
        referralType,
        referrerId,
      };

      opdateCache({ optimisticEntities: [matterForOpdate] });
    } catch (err) {
      if (matterForOpdate) {
        rollbackOpdateCache({ optimisticEntities: [matterForOpdate] });
      }
      throw err;
    }
  });
  return Promise.resolve(matterId);
};
