import * as applicationState from 'web/redux/features/application-state';
import { store } from '@sb-itops/redux';

// testFn verifies the payload received is the one the consumer is listening for
// example: (payloads) => payloads.find((payload) => value.invoiceId === payload.invoiceId && !payload.optimistic)
export async function reduxActionWithTimeout(type, testFn, timeout) {
  let unsubscribe;
  const listenerP = new Promise((resolve) => {
    unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const action = applicationState.selectors.getLastAction(state);

      let payloads = action.payload || [];
      if (!Array.isArray(payloads)) {
        payloads = payloads.entities ? Object.values(payloads.entities) : [payloads];
      }

      if (type === action.type && testFn(payloads)) {
        // unsubscribe after firing the callback
        unsubscribe();
        resolve();
      }
    });
  });
  const timeoutP = new Promise((resolve) => {
    setTimeout(() => {
      unsubscribe();
      resolve();
    }, timeout);
  });

  return Promise.race([listenerP, timeoutP]);
}
