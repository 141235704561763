// LOD compatible version of 'billing/react/delete-invoices-proceed-modal-dialog'
import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import uuid from '@sb-itops/uuid';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getLogger } from '@sb-itops/fe-logger';

import DraftInvoiceDeleteProceedModal from './DraftInvoiceDeleteProceedModal';

export const DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID = 'delete-draft-invoice-modal';

const log = getLogger('DraftInvoiceDeleteProceedModalContainer.container');

const hooks = () => ({
  useProceedHandler: ({ isBulkDeletion, onClose, invoiceIds, onProceedClick, onCompletion }) => ({
    onProceedClick: async () => {
      const invoicesToDelete = invoiceIds.map((invoiceId) => ({
        invoiceId,
        versionId: uuid(),
      }));

      try {
        if (onProceedClick) {
          onProceedClick();
        }

        onClose();
        await dispatchCommand({ type: 'Integration.BulkDeleteDraftInvoices', message: { invoicesToDelete } });
      } catch (error) {
        log.error(`Some or all draft invoices could not be deleted`, error);
      } finally {
        if (onCompletion) {
          onCompletion();
        }
      }
    },
    // Sometimes the "bulk version" (regarding the modal UI) should be used, even if a single invoice is being deleted
    //  * E.g. deleting invoices via bulk actions
    //
    // Therefore, we allow the overriding of this (optional) value
    isBulkDeletion: isBulkDeletion ?? invoiceIds.length > 1,
  }),
});

export const DraftInvoiceDeleteProceedModalContainer = composeHooks(hooks)(DraftInvoiceDeleteProceedModal);

DraftInvoiceDeleteProceedModalContainer.displayName = 'DraftInvoiceDeleteProceedModalContainer';

DraftInvoiceDeleteProceedModalContainer.propTypes = {
  isBulkDeletion: PropTypes.bool,
  isVisible: PropTypes.bool,
  invoiceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  // Callbacks
  onProceedClick: PropTypes.func,
  onClose: PropTypes.func,
  onCompletion: PropTypes.func,
};

DraftInvoiceDeleteProceedModalContainer.defaultProps = {
  isBulkDeletion: undefined,
  isVisible: false,
  // Callbacks
  onProceedClick: undefined,
  onClose: () => {},
  onCompletion: undefined,
};
