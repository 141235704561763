'use strict';

/**
 * Get current users array with userId from firmDetails
 * @param {object} params
 * @param {Object} params.firmDetails
 * @returns {array} array of current users
 */
const getFirmCurrentUsers = ({ firmDetails }) => {
  if (firmDetails) {
    return Object.values(firmDetails.currentUsers).map(({ personId, userId }) => ({
      ...firmDetails.people[personId],
      userId,
    }));
  }

  return [];
};

module.exports = {
  getFirmCurrentUsers,
};
