import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@sb-itops/react';
import classnames from 'classnames';

import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { BankAccountNumberDisplay } from '@sb-billing/react/bank-account-number-display';
import { ProtectedFundsTable } from '../protected-funds-table';
import { FinancialSummaryForTrust } from '../financial-summary';
import { UnprotectFundsModal } from './UnprotectFundsModal';
import Styles from './ProtectedFunds.module.scss';

export const PROTECT_BALANCE_ADD_MODAL_ID = 'protect-balance-add';

export const ProtectedFunds = ({
  onClickLink,
  protectedFunds,
  protectedFundsTitle,
  sortBy,
  sortDirection,
  onSort,
  matterId,
  showUnprotectBalanceModal,
  onUnprotectBalance,
  unprotectBalanceModalId,
  trustAccountId,
  isDownloading,
  downloadReport,
  supportsMatterContactBalance,
  protectFundsIsDisabled,
  protectFundsIsDisabledTooltip,
}) => (
  <div className={classnames('panel-body', Styles.protectedFunds)}>
    <div className={classnames(Styles.ribbon, Styles.header)}>
      <span>
        <div className={classnames(Styles.title, 'capitalise')}>{protectedFundsTitle}</div>
        <span className={Styles.subtitle}>
          <BankAccountNumberDisplay bankAccountId={trustAccountId} />
        </span>
      </span>
      <div>
        <FinancialSummaryForTrust matterId={matterId} trustAccountId={trustAccountId} />
      </div>
    </div>
    <div className={classnames('ribbon', 'panel', 'panel-primary')}>
      <Button
        onClick={() =>
          setModalDialogVisible({
            modalId: PROTECT_BALANCE_ADD_MODAL_ID,
            props: { bankAccountId: trustAccountId, matterId },
          })
        }
        disabled={protectFundsIsDisabled}
        title={protectFundsIsDisabled ? protectFundsIsDisabledTooltip : undefined}
      >
        Protect Funds
      </Button>
      <Button
        locked={isDownloading === 'pdf'}
        disabled={isDownloading === 'pdf'}
        onClick={() => downloadReport({ format: 'pdf', matterId, trustAccountId, sortDirection, sortBy })}
      >
        Print
      </Button>
      <Button
        locked={isDownloading === 'csv'}
        disabled={isDownloading === 'csv'}
        onClick={() => downloadReport({ format: 'csv', matterId, trustAccountId, sortDirection, sortBy })}
      >
        CSV
      </Button>
    </div>
    <ProtectedFundsTable
      protectedFunds={protectedFunds}
      onSort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onClickLink={onClickLink}
      onUnprotectClick={(protectedFundsId) => showUnprotectBalanceModal(protectedFundsId)}
      matterId={matterId}
      supportsMatterContactBalance={supportsMatterContactBalance}
    />
    <UnprotectFundsModal modalId={unprotectBalanceModalId} onYes={onUnprotectBalance} />
  </div>
);

ProtectedFunds.displayName = 'ProtectedFunds';

ProtectedFunds.propTypes = {
  matterId: PropTypes.string,
  trustAccountId: PropTypes.string,
  protectedFunds: PropTypes.array.isRequired,
  protectedFundsTitle: PropTypes.string.isRequired,
  isDownloading: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
  showUnprotectBalanceModal: PropTypes.func.isRequired,
  onUnprotectBalance: PropTypes.func.isRequired,
  unprotectBalanceModalId: PropTypes.string,
  supportsMatterContactBalance: PropTypes.bool.isRequired,
  protectFundsIsDisabled: PropTypes.bool.isRequired,
  protectFundsIsDisabledTooltip: PropTypes.string,
};

ProtectedFunds.defaultProps = {
  matterId: undefined,
  trustAccountId: undefined,
  sortDirection: undefined,
  sortBy: undefined,
  isDownloading: '',
  downloadReport: undefined,
  unprotectBalanceModalId: undefined,
  protectFundsIsDisabledTooltip: '',
};
