import { getPaymentSource } from '@sb-billing/business-logic/payment-provider/services';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';

export const paymentMethodName = ({ paymentMethod }) => {
  if (!paymentMethod) {
    return null;
  }

  try {
    const pm = getPaymentSource({
      providerType: providers.FEE_WISE,
      providerSpecificFields: { paymentMethod },
    });

    return pm;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return null;
};
