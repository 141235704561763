/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Typeahead, FormLabel, useTranslation, IField } from '@sb-itops/react';
import classnames from 'classnames';
import { TSchemeValuesFor } from '@sb-itops/region-schemes';
import { getStates } from '@sb-customer-management/business-logic/contacts/services/get-states';
import { AddressAutocomplete } from '../address-autocomplete';
import Styles from './AddressForm.module.scss';

type AddressFormAuProps = {
  addressScheme: TSchemeValuesFor<'addressScheme'>;
  formDisabled?: boolean;
  formInitialised?: boolean;
  buildingLevel?: IField<string>;
  unitNumber?: IField<string>;
  unitType?: IField<string>;
  streetNumber?: IField<string>;
  streetName?: IField<string>;
  streetType?: IField<string>;
  city?: IField<string>;
  state?: IField<string>;
  zipCode?: IField<string>;
  onFieldValueUpdated: (update: Record<string, string | number | undefined>) => void;
  onAddressAutocompleteSelected: (addressInfo: Record<string, string>) => void;
  submitFailed?: boolean;
  optional?: boolean;
  optionalExceptState?: boolean;
  useManualEntrySwitch?: boolean;
  validateForm: () => void;
};

export const AddressFormAu = ({
  formInitialised,
  addressScheme,
  buildingLevel,
  unitNumber,
  unitType,
  streetNumber,
  streetName,
  streetType,
  city,
  state,
  zipCode,
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  optional,
  optionalExceptState,
  onAddressAutocompleteSelected,
  useManualEntrySwitch,
}: AddressFormAuProps) => {
  const { t } = useTranslation();
  const [manualEntry, setManualEntry] = useState(false);

  if (!formInitialised) {
    return null;
  }

  const stateOptions = getStates(addressScheme);
  const isErrorClassnames = (field, style) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, style) : '';

  const formIsOptional = optional || optionalExceptState;

  return (
    <div className={Styles.addressForm}>
      <fieldset disabled={formDisabled}>
        {(!useManualEntrySwitch || (!manualEntry && !streetName?.value)) && (
          <div className="row">
            <div className="col-xs-12">
              <AddressAutocomplete
                label={
                  useManualEntrySwitch && !streetName?.value && !optional && !optionalExceptState
                    ? 'Address Search *'
                    : 'Address Search'
                }
                onSelect={onAddressAutocompleteSelected}
              />
              {useManualEntrySwitch && (
                <a className={Styles.link} onClick={() => setManualEntry(true)}>
                  Enter Address Manually
                </a>
              )}
            </div>
          </div>
        )}
        {
          // Show the fields automatically if an address is selected
          (!useManualEntrySwitch || manualEntry || streetName?.value) && (
            <>
              <div className="row">
                <div className="col-xs-4">
                  <label>Building / Level</label>
                  <input
                    name="buildingLevel"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(buildingLevel, Styles.formControl))}
                    value={buildingLevel?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ buildingLevel: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
                <div className="col-xs-4">
                  <label>Unit Type</label>
                  <input
                    name="unitType"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(unitType, Styles.formControl))}
                    value={unitType?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ unitType: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
                <div className="col-xs-4">
                  <label>Unit Number</label>
                  <input
                    name="Unit Number"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(unitNumber, Styles.formControl))}
                    value={unitNumber?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ unitNumber: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-2">
                  <FormLabel
                    label="Street No."
                    field={streetNumber}
                    submitFailed={submitFailed}
                    optional={formIsOptional}
                    uppercase={false}
                  />
                  <input
                    name="streetNumber"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(streetNumber, Styles.formControl))}
                    value={streetNumber?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ streetNumber: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
                <div className="col-xs-7">
                  <FormLabel
                    label="Street Name"
                    field={streetName}
                    submitFailed={submitFailed}
                    optional={formIsOptional}
                    uppercase={false}
                  />
                  <input
                    name="streetName"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(streetName, Styles.formControl))}
                    value={streetName?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ streetName: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
                <div className="col-xs-3">
                  <FormLabel
                    label="Street Type"
                    field={streetType}
                    submitFailed={submitFailed}
                    optional={formIsOptional}
                    uppercase={false}
                  />
                  <input
                    name="streetType"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(streetType, Styles.formControl))}
                    value={streetType?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ streetType: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-6">
                  <FormLabel
                    label={t('suburb')}
                    field={city}
                    optional={formIsOptional}
                    submitFailed={submitFailed}
                    uppercase={false}
                  />
                  <input
                    name="city"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(city, Styles.formControl))}
                    value={city?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ city: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
                <div className="col-xs-3">
                  <FormLabel
                    label={t('addressState')}
                    field={state}
                    submitFailed={submitFailed}
                    optional={optional && !optionalExceptState}
                    uppercase={false}
                  />
                  <Typeahead
                    selectedOption={
                      stateOptions.find((option) => option.value === state?.value || option.label === state?.value) ||
                      undefined
                    }
                    menuPlacement="top"
                    options={stateOptions}
                    disabled={formDisabled}
                    placeholder="Select..."
                    className={classnames(Styles.formSelect, isErrorClassnames(state, Styles.formSelect))}
                    onSelect={(option) => {
                      onFieldValueUpdated({ state: (option && option.value) || undefined });
                      validateForm();
                    }}
                  />
                </div>
                <div className="col-xs-3">
                  <FormLabel
                    label={t('postcode')}
                    field={zipCode}
                    submitFailed={submitFailed}
                    optional={formIsOptional}
                    uppercase={false}
                  />
                  <input
                    name="zipCode"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(zipCode, Styles.formControl))}
                    value={zipCode?.value || ''}
                    onBlur={validateForm}
                    onChange={(e) => {
                      onFieldValueUpdated({ zipCode: e.target.value });
                    }}
                  />
                </div>
              </div>
            </>
          )
        }
      </fieldset>
    </div>
  );
};
