angular.module("sb.billing.webapp").config(function ($stateProvider) {
  "use strict";

  const BASE_DIRECTORY = "ng-routes/home/billing/view-matter/infotrack";

  $stateProvider.state("home.billing.view-matter.infotrack", {
    url: "/infotrack",
    templateUrl: `${BASE_DIRECTORY}/view-matter-infotrack.html`,
    controller: "sbViewMatterInfotrackController",
    controllerAs: "$ctrl",
    reloadOnSearch: false,
    data: {
      authorized: true,
      navHighlights: ["billing", "matters", "view-matter", "infotrack"],
      tab: { type: "matter" },
    },
  });
});
