'use strict';

const { surchargeTypeValues } = require('./surcharge-types');
const { minimumSurchargeValues } = require('./minimum-surcharge-values');

/**
 * isSurchargeValid
 *
 * @param {Object} surcharge the surcharge object can be passed. However, the only required props are: type, fixedSurcharge, and percentageBp. Therefore, an object that meets that interface will be fine.
 * @param {number} surcharge.type - The surcharge type (see surchargeTypeValues)
 * @param {number} surcharge.fixedSurcharge - The fixed surcharge amount in cents
 * @param {number} surcharge.percentageBp - The percentage sucharge amount in bp
 * @returns boolean
 */
function isSurchargeValid(surcharge) {
  if (!surcharge) {
    return false;
  }

  let isValid = false;

  switch (surcharge.type) {
    case surchargeTypeValues.NONE:
      isValid = true;
      break;
    case surchargeTypeValues.FIXED:
      isValid = surcharge.fixedSurcharge >= minimumSurchargeValues.FIXED;
      break;
    case surchargeTypeValues.PERCENTAGE:
      isValid = surcharge.percentageBp >= minimumSurchargeValues.PERCENTAGE_BP;
      break;
    default:
      throw new Error('Surcharge validation failed: Unknown surcharge type');
  }

  return isValid;
}

module.exports = {
  isSurchargeValid,
};
