import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';

import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { saveSettings as saveBankAccountSettings } from '@sb-billing/redux/bank-account-settings/save-settings';
import { getDepositRequestEmailMacros } from '@sb-billing/business-logic/invoice-emailing';
import DepositRequestEmailSettings from './DepositRequestEmailSettings';

function mapStateToProps(state, { t }) {
  const depositRequestEmailMacros = {
    subjectMacros: getDepositRequestEmailMacros({
      isForEmailSubject: true,
      t,
    }),
    bodyMacros: getDepositRequestEmailMacros({
      isForEmailSubject: false,
      t,
    }),
  };
  const { depositRequestEmailSubject, depositRequestEmailBody, sendDepositRequestEmailCopyToUser } =
    getBankAccountSettings();
  return {
    settings: {
      depositRequestEmailSubject,
      depositRequestEmailBody,
      sendDepositRequestEmailCopyToUser,
    },
    depositRequestEmailMacros,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSaveSettings: async (settings) => dispatch(saveBankAccountSettings(settings)),
  };
}
const DepositRequestEmailSettingsContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DepositRequestEmailSettings)),
);

DepositRequestEmailSettingsContainer.displayName = 'DepositRequestEmailSettingsContainer';

export default DepositRequestEmailSettingsContainer;
