import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import { useRef } from 'react';

/**
 * @param {Object} params
 * @param {String} params.scope
 * @param {number} params.fetchLimit
 * @returns {{getPagination: (function({ totalCount: number, loading: boolean }): {totalCount: number, totalNumberOfPages: number, hidePagination: boolean}), setPageNumber: ((function(*): *)|*), currentPage: *}}
 */
export const usePagination = ({ scope, fetchLimit }) => {
  const { selectedItem: currentPage, setSelectedItem: setPageNumber } = useSingleItemSelection({
    scope,
    initialSelection: 0,
  });
  const pagination = useRef({
    totalCount: 0,
    totalNumberOfPages: 0,
    hidePagination: true,
  });

  const getPagination = ({ totalCount = 0, loading }) => {
    if (!loading) {
      const totalNumberOfPages = Math.ceil(totalCount / fetchLimit);
      const hidePagination = !(currentPage > 0 || totalNumberOfPages > 1);
      pagination.current = {
        totalCount,
        totalNumberOfPages,
        hidePagination,
      };
    }

    return pagination.current;
  };

  return {
    currentPage,
    setPageNumber,
    getPagination,
  };
};
