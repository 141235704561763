angular.module('sb.billing.webapp').config(function($stateProvider) {
  'use strict';

  $stateProvider.state('home.billing.bills.trust-to-office', {
    url: '/trust-to-office',
    template: '<sb-trust-to-office></sb-trust-to-office>',
    data: {
      authorized: true,
      navHighlights: ['billing', 'bills', 'trust-to-office'],
    },
  });

  $stateProvider.state('home.billing.bills.bulk-trust-payment', {
    url: '/bulk-trust-payment',
    template: '<sb-trust-to-office></sb-trust-to-office>',
    data: {
      authorized: true,
      navHighlights: ['billing', 'bills', 'bulk-trust-payment'],
    },
  });
});
