function isSameAddress({ mailingAddress, businessAddress }) {
  const {
    addressLine1: mailingAddressLine1,
    addressLine2: mailingAddressLine2,
    city: mailingCity,
    state: mailingState,
    zipCode: mailingZipCode,
  } = mailingAddress;

  const {
    addressLine1: businessAddressLine1,
    addressLine2: businessAddressLine2,
    city: businessCity,
    state: businessState,
    zipCode: businessZipCode,
  } = businessAddress;

  const isMailingAddressSameAsBusiness =
    mailingAddressLine1 === businessAddressLine1 &&
    mailingAddressLine2 === businessAddressLine2 &&
    mailingCity === businessCity &&
    mailingState === businessState &&
    mailingZipCode === businessZipCode;

  return isMailingAddressSameAsBusiness;
}

module.exports = {
  isSameAddress,
};
