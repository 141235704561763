import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ModalDialog } from '@sb-itops/react/modal-dialog';

const DisconnectPaymentProviderModalDialog = memo(({ onDisconnectPaymentProvider, disconnectModalId }) => (
  <ModalDialog
    modalId={disconnectModalId}
    body={
      <div>
        <p>
          All saved client payment information will be lost and all payment plans will stop automatic charging. Are you
          sure you want to continue?
        </p>
      </div>
    }
    title="Disconnect Payment Provider"
    buttons={{
      rightAlign: true,
      primary: {
        text: 'Yes',
      },
      secondary: {
        text: 'No',
      },
    }}
    onPrimaryClick={onDisconnectPaymentProvider}
  />
));

DisconnectPaymentProviderModalDialog.displayName = 'DisconnectPaymentProviderModalDialog';

DisconnectPaymentProviderModalDialog.propTypes = {
  onDisconnectPaymentProvider: PropTypes.func.isRequired,
  disconnectModalId: PropTypes.string.isRequired,
};

DisconnectPaymentProviderModalDialog.defaultProps = {};

export default DisconnectPaymentProviderModalDialog;
