'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const billingFrequencyMonthlySubType = Object.freeze({
  MONTHLY_ALL: 20,
  MONTHLY_ODD: 21,
  MONTHLY_EVEN: 22,
});

const billingFrequencyMonthlySubTypeLabels = Object.freeze({
  MONTHLY_ALL: 'All',
  MONTHLY_ODD: 'Odd',
  MONTHLY_EVEN: 'Even',
});

const billingFrequencyMonthlySubTypeOptions = getTypeAsOptions({
  typeLabels: billingFrequencyMonthlySubTypeLabels,
  typeValues: billingFrequencyMonthlySubType,
});

module.exports = {
  billingFrequencyMonthlySubType,
  billingFrequencyMonthlySubTypeLabels,
  billingFrequencyMonthlySubTypeOptions,
};
