import { gql } from '@apollo/client';

const query = gql`
  query MatterBalanceTrustSummariesAsOfDate(
    $matterIds: [ID]!
    $filter: BankAccountBalancesMatterSummaryFilter
    $balanceAsOfDate: Int
  ) {
    matterBalanceTrustSummaries(ids: $matterIds, filter: $filter, balanceAsOfDate: $balanceAsOfDate) {
      matterId
      balance
      availableBalance
      protectedBalance
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterBalanceTrustSummariesAsOfDate = {
  query,
  notificationIds,
};
