import { getMap as getState } from '@sb-billing/redux/bank-account-balances';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import {
  getStatusesFilter,
  getIssuedDateFilter,
  getFinalizedOnFilter,
  getSentStatusFilter,
  getSendPreferenceFilter,
  getBillingEventFilter,
  getBillingTypeFilter,
  getBillingFrequencyFilter,
  getMatterTypeFilter,
  getRecentInvoicesFilter,
  getAttorneyResponsibleFilter,
} from '@sb-billing/redux/invoices';
import {  getStatus as getMatterStatusById } from '@sb-matter-management/redux/matters';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getOperatingAccount, getTrustAccounts } from '@sb-billing/redux/bank-account';
import { getById as getBillingConfigById } from '@sb-billing/redux/billing-configuration';

const { getMatterBalance, getContactBalance } = selectors;

export const createInvoiceFilters = ({
  invoiceStatuses,
  issueDate,
  finalizedOn,
  sentStatuses,
  sendPreferences,
  billingEvents,
  billingTypes,
  billingFrequencySubTypes,
  attorneysResponsible,
  matterTypes,
  matterStatuses,
  minimumTrustBalance,
  minimumOperatingBalance,
  includeStatuses,
  matterId,
  contactId,
  recent,
  utbmsEnabled,
}) => {
  const filterFns = [];
  const operatingBankAccountId = getOperatingAccount().id;
  const totalNumberOfAvailablePreferences = hasFacet(facets.eBillingSendingPreference) ? Object.keys(sentViaTypes).length : Object.keys(sentViaTypes).length - 1;

  if (issueDate) {
    filterFns.push(
      getIssuedDateFilter({
        filter: issueDate.restrictDate,
        startDate: issueDate.startDate,
        endDate: issueDate.endDate,
      }),
    );
  }
  if (finalizedOn) {
    filterFns.push(
      getFinalizedOnFilter({
        filter: finalizedOn.selectedFilter,
        startDate: finalizedOn.startDate,
        endDate: finalizedOn.endDate,
      }),
    );
  }

  if (recent && featureActive('BB-8047')) {
    filterFns.push(
      getRecentInvoicesFilter(),
    );
  }

  if (sentStatuses) {
    const sentStatusFilter = getSentStatusFilter(sentStatuses.concat(includeStatuses));
    filterFns.push(
      (invoiceSummary) => invoiceSummary.currentVersion.status === 'DRAFT' || sentStatusFilter(invoiceSummary),
    );
  }

  if (featureActive('BB-11448') && sendPreferences && sendPreferences.length !== totalNumberOfAvailablePreferences) { // When all option is selected, skip filtering
    const sendPreferencesFilter = getSendPreferenceFilter(sendPreferences);
    filterFns.push((invoiceSummary) => sendPreferencesFilter(invoiceSummary));
  }

  if (billingEvents) {
    const billingEventFilter = getBillingEventFilter(billingEvents);
    filterFns.push(
      (invoiceSummary) => billingEventFilter(invoiceSummary),
    );
  }

  if (matterId) {
    filterFns.push(
      (invoiceSummary) => invoiceSummary.currentVersion.matterId === matterId,
    );
  }

  if (contactId) {
    filterFns.push(
      (invoiceSummary) => {
        return (invoiceSummary.currentVersion.debtors || []).find((debtor) => debtor.id === contactId);
      }
    );
  }

  if (billingTypes && !billingTypes.allSelected) {
    filterFns.push(getBillingTypeFilter(billingTypes.selections));
  }

  if (featureActive('BB-11704') && billingFrequencySubTypes && billingFrequencySubTypes.length) {
    filterFns.push(getBillingFrequencyFilter(billingFrequencySubTypes));
  }

  if (matterTypes && matterTypes.length) {
    filterFns.push(getMatterTypeFilter(matterTypes));
  }

  if (matterStatuses) {
    filterFns.push((invoiceSummary) => {
      const invoiceMatterStatus = getMatterStatusById(invoiceSummary.matterId);
      return matterStatuses.includes(invoiceMatterStatus);
    });
  }

  if (attorneysResponsible && attorneysResponsible.length > 0) {
    filterFns.push(getAttorneyResponsibleFilter(attorneysResponsible));
  }

  if (Number.isFinite(minimumTrustBalance)) {
    const trustAccounts = getTrustAccounts();
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      // Get trust balance across all trust accounts
      const currentTrustBalance = trustAccounts.reduce((totalBalance, trustAccount) => {
        const trustAccountBalance = isMatterContactBalanceType()
          ? getContactBalance(getState(), { contactId: currentVersion.debtors[0].id, bankAccountId: trustAccount.id })
          : getMatterBalance(getState(), { matterId: currentVersion.matterId, bankAccountId: trustAccount.id });
        return totalBalance + trustAccountBalance;
      }, 0);
      
      return currentTrustBalance >= minimumTrustBalance;
    });
  }

  if (minimumOperatingBalance) {
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      const currentOperatingBalance = isMatterContactBalanceType()
        ? getContactBalance(getState(), { bankAccountId: operatingBankAccountId, contactId: currentVersion.debtors[0].id })
        : getMatterBalance(getState(), { matterId: currentVersion.matterId, bankAccountId: operatingBankAccountId });
      return currentOperatingBalance >= minimumOperatingBalance;
    });
  }

  const statusFilterFn = invoiceStatuses ? getStatusesFilter(invoiceStatuses) : () => true;

  if (featureActive('BB-9243') && utbmsEnabled) {
    filterFns.push((invoiceSummary) => {
      const matterId = invoiceSummary.currentVersion.matterId;
      const billingConfig = getBillingConfigById(matterId);
      // in Staging some matters don't have a billing config
      return billingConfig && billingConfig.currentWorkItem && billingConfig.currentWorkItem.isUtbmsEnabled;
    });
  }

  return {
    filterFn: (invoiceSummary) => filterFns.every((filterFn) => filterFn(invoiceSummary)),
    statusFilterFn,
  };
};
