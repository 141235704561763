const { getTypeAsMaps } = require('./get-type-as-maps');
const { getTypeAsOptions } = require('./get-type-as-options');
const { getTypeByLabel } = require('./get-type-by-label');
const { getTypeByValue } = require('./get-type-by-value');

module.exports = {
  getTypeAsMaps,
  getTypeAsOptions,
  getTypeByLabel,
  getTypeByValue,
};
