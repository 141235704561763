import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@sb-itops/react';

import { SubscriptionPaymentForm } from '../subscription-payment-form';
import Styles from './SubscriptionPaymentModal.module.scss';

export const SubscriptionPaymentModal = ({ isVisible, onClose }) => (
  <Modal
    isVisible={isVisible}
    modalBodyClassName={Styles.modalBody}
    body={<SubscriptionPaymentForm onPaymentMethodUpdated={onClose} onClose={onClose} />}
    showFooter={false}
    onModalClose={onClose}
  />
);

SubscriptionPaymentModal.displayName = 'SubscriptionPaymentModal';

SubscriptionPaymentModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SubscriptionPaymentModal.defaultProps = {};
