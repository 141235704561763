import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { featureActive } from '@sb-itops/feature';

export const saveRecentInvoice = (invoiceId) => {
  if (!featureActive('BB-8047')) {
    return Promise.resolve();
  }
  const saveRecentInvoiceThunk = async () => {
    const recentInvoiceMessage = {
      invoiceId,
    };
    // Opdating this is not very valuable

    // Apply the save in the backend.
    const path = `/billing/recent-invoices/:accountId/`;
    const fetchOptions = { body: JSON.stringify(recentInvoiceMessage) };
    await fetchPostP({ path, fetchOptions });
  };

  return store.dispatch(saveRecentInvoiceThunk);
};
