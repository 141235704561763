import React from 'react';
import { components } from 'react-select';

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <i className="fa fa-times fa-fw" />
  </components.ClearIndicator>
);

export default ClearIndicator;
