'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const showRetainerOnInvoiceOptionValues = Object.freeze({
  USE_TEMPLATE_SETTING: 0,
  DISABLED: 1,
  ENABLED: 2,
});

const showRetainerOnInvoiceOptionLabels = Object.freeze({
  USE_TEMPLATE_SETTING: 'Use setting from invoice template',
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
});

const showRetainerOnInvoiceOptions = getTypeAsOptions({
  typeLabels: showRetainerOnInvoiceOptionLabels,
  typeValues: showRetainerOnInvoiceOptionValues,
});

module.exports = {
  showRetainerOnInvoiceOptionLabels,
  showRetainerOnInvoiceOptions,
  showRetainerOnInvoiceOptionValues,
};
