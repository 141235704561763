angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/services';

  $stateProvider
    .state('home.billing.support.services', {
      url: '/services',
      templateUrl: BASE_DIRECTORY + '/services.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});