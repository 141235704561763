import { updateCache as updateRedux } from '@sb-billing/redux/auto-numbering-prefixes';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('@sb-billing/services')
  .service('sbAutoNumberingPrefixesService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {

    if(!hasFacet(facets.autoGeneratedReference)){
      return;
    }

    sbGenericCacheService.setupCache({
      name: 'sbAutoNumberingPrefixesService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'billing/auto-numbering-prefixes' },
        poll: 60,
        subscriptions: 'notifier.BillingSharedNotifications.AutoNumberingPrefixesUpdated'
      },
      updateRedux
    });
  });
