'use strict';

/**
 * @typedef { import('../entities/types.js').SbBillingAuthProviderChallenge } SbBillingAuthProviderChallenge
 * @typedef { import('../entities/types.js').AuthenticationChallenge } AuthenticationChallenge
 */

class AuthenticationChallengeFactory {
  /**
   * fromSbBillingAuthenticationProviderChallenge
   *
   * Creates an {@link AuthenticationChallenge} object from a {@link SbBillingAuthProviderChallenge}.
   *
   * @param {SbBillingAuthProviderChallenge} authenticationChallenge An authentication challenge issued by the SbBillingAuthProvider.
   *
   * @return {AuthenticationChallenge} A valid AuthenticationChallenge object.
   */
  static fromSbBillingAuthenticationProviderChallenge(authenticationChallenge) {
    const { userId, challengeType, challenge } = authenticationChallenge;

    return {
      challengeType,
      userId,
      provider: 'sbBillingAuthProvider',
      custom: challenge,
    };
  }
}

module.exports = {
  AuthenticationChallengeFactory,
};
