/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export const TransferFundsFormSchema = Yup.object().shape({
  authorisedBy: Yup.string().when('$showAuthorisedBy', {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  sourceMatterId: Yup.string().required(),
  destinationMatterId: Yup.string().required(),
  destinationContactId: Yup.string().when('$showContacts', {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  sourceBankAccountId: Yup.string().required(),
  destinationBankAccountId: Yup.string().required(),
  sourceContactId: Yup.string().when('$showContacts', {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  note: Yup.string(),
  amount: Yup.number().positive().nullable().required(),
  sourceBalanceAvailable: Yup.number(),
  sourceBalanceAvailableAfter: Yup.number().min(0),
  destinationBalanceAvailable: Yup.number(),
  destinationBalanceAvailableAfter: Yup.number(),
  reference: Yup.string(),
  reason: Yup.string().when('$showReason', {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  pdfOnTransfer: Yup.boolean(),
});
