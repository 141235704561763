import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { bankAccountTypeEnum  } from '@sb-billing/business-logic/bank-account/entities/constants';

angular.module('sb.billing.webapp').component('sbDataDepositSlips', {
  require: { composer: '^sbCompose' },
  bindings: { accountType: '<?', trustAccountId: '<?', entityDataKey: '@?' },
  controller: function ($scope, sbLoggerService, sbDepositSlipService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataDepositSlips');
    const entityDataKey = ctrl.entityDataKey || 'depositSlips';

    // log.setLogLevel('info');

    ctrl.$onChanges = (changes) => {
      log.info('bindings change', changes);
      updateDepositSlips();
    };

    $scope.$on('smokeball-data-update-sbDepositSlipService', () => {
      log.info('saw data update');
      updateDepositSlips();
    });

    function getBankAccountId() {
      if (ctrl.accountType === bankAccountTypeEnum.TRUST) {
        return (ctrl.trustAccountId);
      }
      if (ctrl.accountType === bankAccountTypeEnum.OPERATING) {
        return getOperatingAccount().id;
      }
    }

    function updateDepositSlips () {
      let depositSlips = sbDepositSlipService.get();
      log.info('depositSlips: ', depositSlips);

      const bankAccountId = getBankAccountId();
      if (bankAccountId) {
        depositSlips = depositSlips.filter((slip) => slip.bankAccountId === bankAccountId);
      }
      ctrl.composer.setComposeData(depositSlips, entityDataKey);
    }
  }
});
