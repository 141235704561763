import { useState } from 'react';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getList as getBankAccounts, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { getProviderSettings, getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { fetchPostP } from '@sb-itops/redux/fetch';
import {
  deactivateProvider,
  disconnect as disconnectProvider,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { formatLawpayAccountOptions } from './format-lawpay-account-options';
import { formatBankAccounts } from './format-bank-accounts';
import { marshallSettings } from './marshall-settings';
import { LawpaySettingsForm } from './LawpaySettingsForm';

const name = 'LawpaySettingsFormContainer';
const scope = 'lawpay-settings-form';
const disconnectModalId = 'disconnect-payment-provider-modal';
const log = getLogger(name);

const selectAccountOption = ({ option, lawpayAccountOptions, bankAccounts, bankAccountId }) => {
  const bankAccount = bankAccounts.find((account) => account.id === bankAccountId);
  let availableOptions = [];
  const prevOption = bankAccount[option.type];

  if (option.value) {
    bankAccount[option.type] = option;
    availableOptions = lawpayAccountOptions.filter((opt) => opt && opt.value !== option.value);
  } else {
    // If None selected
    bankAccount[option.type] = undefined;
    const accountOptions = lawpayAccountOptions
      .filter((opt) => opt && opt.value)
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    availableOptions = [...accountOptions];
  }

  // Previous option is now available again
  if (prevOption && !availableOptions.find((opt) => opt.value === prevOption.value)) {
    availableOptions = [...availableOptions, prevOption];
  }
  return availableOptions;
};

const hooks = ({ isSaving, onSaveSettings }) => ({
  useLawpaySettings: () => {
    const { t } = useTranslation();
    const activeProviderType = getActiveProvider();
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const [activateToggled, setActivateToggled] = useState(activeProviderType === providers.LAWPAY);

    const {
      formValues,
      formSubmitting,
      formInitialised,
      onInitialiseForm,
      onClearForm,
      onFieldValueSet,
      onSubmitForm,
    } = useForm({ scope });

    const settings = getProviderSettings(providers.LAWPAY) || {
      cardBankAccountMappings: [],
      eCheckBankAccountMappings: [],
    };

    return {
      lawpayAccountName: settings.lawpayAccount || '',
      disconnectModalId,
      formInitialised,
      formSubmitting,
      activeProviderType,
      activateToggled,
      filterOptionsByAccountType: featureActive('BB-9596'),
      showInvoiceLink: formValues.showInvoiceLink || false,
      showScanToPay: formValues.showScanToPay || false,
      bankAccounts: formValues.bankAccounts || [],
      lawpayAccountOptions: formValues.lawpayAccountOptions || [],
      isConnected: true, //! !settings.publicKey,
      isSaving,
      isDisconnecting,
      onActivateToggle: setActivateToggled,
      onLoad: () => {
        const accountMappings = [...settings.cardBankAccountMappings, ...settings.eCheckBankAccountMappings];
        onInitialiseForm({
          showInvoiceLink: settings.showInvoiceLink,
          showScanToPay: settings.showScanToPay,
          bankAccounts: formatBankAccounts({
            bankAccounts: getBankAccounts().filter(
              (acct) => acct.accountType === 'OPERATING' || acct.accountType === 'TRUST',
            ),
            lawpayBankAccounts: settings.lawpayBankAccounts,
            accountMappings,
            getBankAccountName,
            isTrustAccountClosed,
            t,
          }),
          lawpayAccountOptions: formatLawpayAccountOptions({
            lawpayBankAccounts: settings.lawpayBankAccounts,
            accountMappings,
          }),
        });
        return onClearForm;
      },
      onToggleField: ({ field }) => onFieldValueSet(field, !formValues[field]),
      onSelectAccount: ({ id, option }) => {
        const availableOptions = selectAccountOption({
          bankAccountId: id,
          bankAccounts: formValues.bankAccounts,
          lawpayAccountOptions: formValues.lawpayAccountOptions,
          option,
        });
        onFieldValueSet('bankAccounts', formValues.bankAccounts);
        onFieldValueSet('lawpayAccountOptions', availableOptions);
      },
      onSaveLawpay: async () => {
        onSubmitForm({
          submitFnP: async () => {
            const providerSpecificSettings = marshallSettings({
              showInvoiceLink: formValues.showInvoiceLink,
              showScanToPay: formValues.showScanToPay,
              bankAccounts: formValues.bankAccounts,
            });
            // delegate saveing
            await onSaveSettings({
              providerType: providers.LAWPAY,
              providerSpecificSettings,
              providerActivateToggled: activateToggled,
            });
          },
        });
      },
      onDisconnectLawpay: async () => {
        onSubmitForm({
          submitFnP: async () => {
            try {
              setIsDisconnecting(true);
              if (activeProviderType === providers.LAWPAY) {
                await deactivateProvider({ fetchPostP, providerType: providers.LAWPAY });

                if (featureActive('BB-13244')) {
                  window?.ChurnZero?.push(['setAttribute', 'account', 'Payment Processor', 'NONE']);
                }
              }
              await disconnectProvider({ fetchPostP, providerType: providers.LAWPAY });
              displaySuccessToUser(`${providerNames[providers.LAWPAY]} account disconnected successfully`);
              onClearForm();
            } catch (err) {
              setIsDisconnecting(false);
              log.error(`Failed to disconnect ${providerNames[providers.LAWPAY]} account`, err);
              displayErrorToUser(`Failed to disconnect ${providerNames[providers.LAWPAY]} account`);
              throw err;
            }
          },
        });
      },
    };
  },
});

export const LawpaySettingsFormContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(LawpaySettingsForm)));

LawpaySettingsFormContainer.displayName = name;
LawpaySettingsFormContainer.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
LawpaySettingsFormContainer.defaultProps = {};
