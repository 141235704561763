import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { remove } from 'lodash';
import { Checkbox } from '../checkbox';
import Styles from './AccordianMultiSelect.module.scss';
import { ExpandCollapseToggler } from '../expand-collapse-toggler';

export const AccordianMultiSelect = memo(
  ({ className, onSelect, selected, tree, collapseTreeOnDeselect, selectedList }) => {
    const [expandedBranches, setExpandedBranches] = useState(() => getExpandedBranches(tree, selected));
    const [renderId, setRenderId] = useState();

    const onClickBranch = (id) => {
      if (id === selected) {
        if (collapseTreeOnDeselect) {
          setExpandedBranches({
            ...expandedBranches,
            [id]: false,
          });
        }
      }
    };
    const onToggleExpanded = (id) => {
      setExpandedBranches({
        ...expandedBranches,
        [id]: !expandedBranches[id],
      });
    };

    const onSelectBranch = (id) => {
      const selectedBranch = tree.find((branch) => branch.id === id);
      const fullyOn = (selectedBranch.data || []).every((leaf) => selectedList.includes(leaf));

      updateSelectedNodes(selectedBranch.data || [], !fullyOn);
    };

    const getAllNodes = () => [...new Set(tree.map((branch) => branch.data).flat())] || [];

    const onSelectAll = () => {
      const allSelected = getAllNodes().length === selectedList.length;
      if (allSelected) {
        updateSelectedNodes(getAllNodes(), false);
      } else {
        updateSelectedNodes(getAllNodes(), true);
      }
    };

    const onSelectLeaf = (selection, on) => {
      updateSelectedNodes(selection, on);
    };

    const updateSelectedNodes = (nodes, on) => {
      const newNodes = [...selectedList];
      nodes.forEach((n) => {
        if (newNodes.includes(n) && !on) remove(newNodes, (s) => s === n);
        if (!newNodes.includes(n) && on) newNodes.push(n);
      });

      onSelect(newNodes);

      setRenderId(Math.random());
    };

    return (
      <div className={classnames(className, Styles.accordianContainer)}>
        <div>
          <div className={Styles.branch}>
            <div className={Styles.toggleContainer}>
              <div className={Styles.spacer} />
            </div>
            <div className={Styles.branchCheckbox}>
              <Checkbox id={renderId} checked={getAllNodes().length === selectedList.length} onChange={onSelectAll} />
            </div>
            <div className={Styles.branchTitle}>Select / De-select All</div>
          </div>
        </div>
        {tree.map((branch) => (
          <div key={branch.id || 'no-id'}>
            <div className={classnames(Styles.branch, selected === branch.id)} onClick={() => onClickBranch(branch.id)}>
              <div className={Styles.toggleContainer}>
                {branch.leaves && branch.leaves.length ? (
                  <ExpandCollapseToggler
                    isExpanded={expandedBranches[branch.id]}
                    onToggled={() => onToggleExpanded(branch.id)}
                  />
                ) : (
                  <div className={Styles.spacer} />
                )}
              </div>
              <div className={Styles.branchCheckbox}>
                <Checkbox
                  name={branch.display}
                  checked={(branch.data || []).every((id) => selectedList.includes(id))}
                  onChange={() => onSelectBranch(branch.id)}
                />
              </div>
              <div className={Styles.branchTitle} onClick={() => onSelectBranch(branch.id)}>
                {branch.display}
              </div>
            </div>
            {branch.leaves && (
              <div
                style={{ maxHeight: expandedBranches[branch.id] ? 40 * branch.leaves.length : 0 }}
                className={Styles.leaves}
              >
                {branch.leaves.map((mt) => (
                  <div key={mt.display || 'no-id'}>
                    <div className={classnames(Styles.branch)}>
                      <div className={Styles.branchCheckbox}>
                        <Checkbox
                          name={mt.display}
                          checked={(mt.data && mt.data.some((mtId) => selectedList.includes(mtId))) || false}
                          onChange={(on) => onSelectLeaf(mt.data, on)}
                        />
                      </div>
                      <div
                        className={Styles.branchTitle}
                        onClick={() =>
                          onSelectLeaf(mt.data, !(mt.data && mt.data.some((mtId) => selectedList.includes(mtId))))
                        }
                      >
                        {mt.display}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  },
);

function getExpandedBranches(tree, selected) {
  if (!selected) {
    return {};
  }
  const selectedBranch = tree.find((branch) => branch.leaves && branch.leaves.find((leaf) => leaf.id === selected));

  if (selectedBranch) {
    return {
      [selectedBranch.id]: true,
    };
  }

  return {};
}

AccordianMultiSelect.displayName = 'AccordianMultiSelect';

const IdPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

AccordianMultiSelect.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.any,
  selectedList: PropTypes.arrayOf(IdPropType),
  tree: PropTypes.arrayOf(
    PropTypes.shape({
      id: IdPropType,
      display: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(IdPropType),
      leaves: PropTypes.arrayOf(
        PropTypes.shape({
          id: IdPropType.isRequired,
          display: PropTypes.string.isRequired,
          data: PropTypes.arrayOf(IdPropType),
          selected: PropTypes.bool,
        }),
      ),
    }),
  ),
  collapseTreeOnDeselect: PropTypes.bool,
};

AccordianMultiSelect.defaultProps = {
  className: undefined,
  selected: undefined,
  tree: undefined,
  collapseTreeOnDeselect: false,
  selectedList: undefined,
};

export default AccordianMultiSelect;
