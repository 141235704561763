'use strict';

const createOpdates = ({ transaction, userId, newTransactionId, reason, effectiveDate, timestamp, isDelete }) => {
  const reversedTx = {
    ...transaction,
    isHidden: isDelete,
    reversed: true,
  };

  const reversalTx = {
    accountId: transaction.accountId,
    userId,
    amount: -transaction.amount,
    bankAccountId: transaction.bankAccountId,
    contactId: transaction.contactId,
    description: `Reversal ${transaction.description}`,
    id: newTransactionId,
    matterId: transaction.matterId,
    note: reason,
    reference: transaction.reference,
    source: transaction.source,
    timestamp,
    effectiveDate,
    type: 'CreditReversal',
    isHidden: isDelete,
    reversedFromTransactionId: transaction.id,
  };

  return [reversedTx, reversalTx];
};

module.exports = {
  createOpdates,
};
