import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import Styles from './MarkAsSentModal.module.scss';
import { MarkAsSentForm } from '../mark-as-sent-form';

export const MarkAsSentModal = React.memo(({ isVisible, isSubmitDisabled, scope, matterIds, onMark, onClose }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      className={Styles.markAsSentModal}
      isVisible={isVisible}
      title="Mark As Sent"
      body={getModalBody({
        scope,
        matterIds,
      })}
      footer={
        <div>
          <Button onClick={onMark} disabled={isSubmitDisabled}>
            Mark as Sent
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  );
});

const getModalBody = ({ scope, matterIds }) => (
  <div className={Styles.standardForm}>
    <MarkAsSentForm scope={scope} matterIds={matterIds} />
  </div>
);

MarkAsSentModal.displayName = 'MarkAsSentModal';

MarkAsSentModal.propTypes = {
  isVisible: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  scope: PropTypes.string,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onMark: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

MarkAsSentModal.defaultProps = {
  isVisible: false,
  isSubmitDisabled: false,
  scope: 'mark-as-sent-modal',
};
