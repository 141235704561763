import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Spinner, buttonTypes, SlidingToggle, StatelessSelect, Icon, useTranslation } from '@sb-itops/react';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { PaymentProviderDisconnectConfirmationModal } from '@sb-billing/react/payment-provider-disconnect-confirmation-modal';
import { PaymentProviderSwitchConfirmationModal } from '@sb-billing/react/payment-provider-switch-confirmation-modal';
import { PaymentProviderDeactivateConfirmationModal } from '@sb-billing/react/payment-provider-deactivate-confirmation-modal';
import { PaymentProviderActivationPanel } from '../payment-provider-activation-panel';

import Styles from './LawpaySettingsForm.module.scss';

const noneOption = { label: 'None', value: '' };

export const LawpaySettingsForm = ({
  lawpayAccountName,

  formInitialised,
  formSubmitting,
  isConnected,
  isSaving,
  isDisconnecting,
  activateToggled,
  activeProviderType,
  filterOptionsByAccountType,
  showInvoiceLink,
  showScanToPay,
  bankAccounts,
  lawpayAccountOptions,
  onToggleField,
  onSelectAccount,
  onSaveLawpay,
  onDisconnectLawpay,
  onActivateToggle,
}) => {
  const { t } = useTranslation();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showClosedCard, onToggleShowClosedCard] = useState(false);
  const [showClosedECheck, onToggleShowClosedECheck] = useState(false);
  const operatingAccount = bankAccounts.find((acct) => acct.accountType === 'OPERATING');
  const trustAccounts = bankAccounts.filter((acct) => acct.accountType === 'TRUST');
  const hasUnmappedCardAccounts = bankAccounts.filter((acct) => !acct.closed && !acct.MerchantAccount).length > 0;
  const hasUnmappedCheckAccounts = bankAccounts.filter((acct) => !acct.closed && !acct.AchAccount).length > 0;
  const hasMappedOperatingAccount = operatingAccount?.MerchantAccount || operatingAccount?.AchAccount;
  const cardAccountOptions = lawpayAccountOptions.filter((opt) => opt.type === 'MerchantAccount');
  const eCheckOptions = lawpayAccountOptions.filter((opt) => opt.type === 'AchAccount');
  const isProcessing = formSubmitting || isSaving || isDisconnecting;

  const cardAccountOperatingOptions = filterOptionsByAccountType
    ? cardAccountOptions.filter((opt) => !opt.isTrust)
    : cardAccountOptions;
  const cardAccountTrustOptions = filterOptionsByAccountType
    ? cardAccountOptions.filter((opt) => opt.isTrust)
    : cardAccountOptions;
  const eCheckAccountOperatingOptions = filterOptionsByAccountType
    ? eCheckOptions.filter((opt) => !opt.isTrust)
    : eCheckOptions;
  const eCheckAccountTrustOptions = filterOptionsByAccountType
    ? eCheckOptions.filter((opt) => opt.isTrust)
    : eCheckOptions;

  if (!formInitialised) {
    return null;
  }

  const handleOnSave = () => {
    if (activeProviderType && activeProviderType !== providers.LAWPAY && activateToggled) {
      setShowSwitchModal(true);
      return;
    }
    if (activeProviderType && activeProviderType === providers.LAWPAY && !activateToggled) {
      setShowDeactivateModal(true);
      return;
    }

    onSaveLawpay();
  };

  const echequeLocalised = t('echeque');

  return (
    <div className={Styles.lawpaySettingsForm}>
      <PaymentProviderActivationPanel
        disabled={isProcessing}
        activeProviderType={activeProviderType}
        isActivateToggled={activateToggled}
        onActivateToggle={onActivateToggle}
        providerType={providers.LAWPAY}
        showButton={false}
      />
      {isConnected && (
        <>
          <form name="lawpayIntegration">
            <fieldset>
              <div className="account-fields">
                <div className="form-group">
                  <label>LawPay Account</label>
                  <input className="form-control" type="text" value={lawpayAccountName} disabled />
                </div>

                <div className="credit-card-accounts">
                  <div className={Styles.sectionHeader}>
                    <div className="h4">Credit Card Payments</div>
                    <div className="form-group">
                      <label>
                        <SlidingToggle
                          id="showClosedCard"
                          scope="showClosedCard"
                          onChange={() => onToggleShowClosedCard(!showClosedCard)}
                          selected={showClosedCard}
                        />
                        <span className={Styles.labelText}>Show Closed</span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Operating Bank Account</label>
                    <div>
                      <StatelessSelect
                        selectedOption={
                          operatingAccount.MerchantAccount || {
                            ...noneOption,
                            type: 'MerchantAccount',
                            isTrust: false,
                          }
                        }
                        options={[
                          {
                            ...noneOption,
                            type: 'MerchantAccount',
                            isTrust: false,
                          },
                          ...cardAccountOperatingOptions,
                        ]}
                        onChange={(option) => onSelectAccount({ id: operatingAccount.id, option })}
                        isClearable={false}
                        disabled={isProcessing}
                      />
                    </div>
                  </div>

                  {trustAccounts
                    .filter((trustAccount) => !trustAccount.closed || showClosedCard)
                    .map((trustAccount) => (
                      <div className="form-group" key={trustAccount.id}>
                        <label>
                          {trustAccount.label}{' '}
                          {showClosedCard && trustAccount.closed && <span className={Styles.closedBadge}>CLOSED</span>}
                        </label>
                        <div>
                          <StatelessSelect
                            selectedOption={
                              trustAccount.MerchantAccount || {
                                ...noneOption,
                                type: 'MerchantAccount',
                                isTrust: true,
                              }
                            }
                            options={[
                              {
                                ...noneOption,
                                type: 'MerchantAccount',
                                isTrust: false,
                              },
                              ...cardAccountTrustOptions,
                            ]}
                            onChange={(option) => onSelectAccount({ id: trustAccount.id, option })}
                            isClearable={false}
                            disabled={isProcessing}
                          />
                        </div>
                      </div>
                    ))}
                </div>

                <div className="echeque-accounts">
                  <div className={Styles.sectionHeader}>
                    <div className="h4">{echequeLocalised} Payments</div>
                    <div className="form-group">
                      <label>
                        <SlidingToggle
                          id="showClosedECheck"
                          scope="showClosedECheck"
                          onChange={() => onToggleShowClosedECheck(!showClosedECheck)}
                          selected={showClosedECheck}
                        />
                        <span className={Styles.labelText}>Show Closed</span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{echequeLocalised} Operating Bank Account</label>
                    <div>
                      <StatelessSelect
                        selectedOption={
                          operatingAccount.AchAccount || {
                            ...noneOption,
                            type: 'AchAccount',
                            isTrust: false,
                          }
                        }
                        options={[
                          {
                            ...noneOption,
                            type: 'AchAccount',
                            isTrust: false,
                          },
                          ...eCheckAccountOperatingOptions,
                        ]}
                        onChange={(option) => onSelectAccount({ id: operatingAccount.id, option })}
                        isClearable={false}
                        disabled={isProcessing}
                      />
                    </div>
                  </div>

                  {trustAccounts
                    .filter((trustAccount) => !trustAccount.closed || showClosedECheck)
                    .map((trustAccount) => (
                      <div className="form-group" key={trustAccount.id}>
                        <label>
                          {echequeLocalised} - {trustAccount.label}{' '}
                          {showClosedECheck && trustAccount.closed && (
                            <span className={Styles.closedBadge}>CLOSED</span>
                          )}
                        </label>
                        <div>
                          <StatelessSelect
                            selectedOption={
                              trustAccount.AchAccount || {
                                ...noneOption,
                                type: 'AchAccount',
                                isTrust: true,
                              }
                            }
                            options={[
                              {
                                ...noneOption,
                                type: 'AchAccount',
                                isTrust: false,
                              },
                              ...eCheckAccountTrustOptions,
                            ]}
                            onChange={(option) => onSelectAccount({ id: trustAccount.id, option })}
                            isClearable={false}
                            disabled={isProcessing}
                          />
                        </div>
                      </div>
                    ))}
                </div>

                {(hasUnmappedCheckAccounts || hasUnmappedCardAccounts) && (
                  <div className="form-group has-warning bg-warning">
                    <div className={Styles.helpBlock}>
                      You have not selected one or more accounts:
                      <ul>
                        {hasUnmappedCardAccounts && (
                          <li>Credit card processing may not be enabled for certain accounts.</li>
                        )}
                        {hasUnmappedCheckAccounts && (
                          <li>{echequeLocalised} processing may not be enabled for certain accounts.</li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group">
                <div className={Styles.includePaymentLinkOptionContainer}>
                  <SlidingToggle
                    id="showInvoiceLink"
                    scope="showInvoiceLink"
                    name="showInvoiceLink"
                    onChange={() => onToggleField({ field: 'showInvoiceLink' })}
                    selected={showInvoiceLink}
                    disabled={isProcessing || !hasMappedOperatingAccount}
                  />
                  <label className={Styles.labelText} htmlFor="showInvoiceLink">
                    Include payment link on invoices and emails/messages
                  </label>
                  <Icon
                    type="information"
                    tooltip="This option applies to the Pay button and QR code on your PDF invoices and invoice reminders, invoice emails and Client Portal messages, and the eInvoice portal"
                    className={Styles.icon}
                  />
                </div>
              </div>

              <div className="form-group">
                <SlidingToggle
                  id="showScanToPay"
                  scope="showScanToPay"
                  name="showScanToPay"
                  onChange={() => onToggleField({ field: 'showScanToPay' })}
                  selected={showScanToPay}
                  disabled={isProcessing || !hasMappedOperatingAccount}
                />
                <label className={Styles.labelText} htmlFor="showScanToPay">
                  Include Scan to Pay QR code on invoices
                </label>
              </div>
            </fieldset>
          </form>
          <div className={Styles.buttonBar}>
            <Button onClick={handleOnSave} disabled={isProcessing}>
              Save {formSubmitting && <Spinner small />}
            </Button>
            <Button
              className={Styles.disconnectButton}
              type={buttonTypes.secondary}
              onClick={() => setShowDisconnectModal(true)}
              disabled={isProcessing}
            >
              Disconnect {isDisconnecting && <Spinner small />}
            </Button>
          </div>
        </>
      )}
      <PaymentProviderDisconnectConfirmationModal
        isVisible={showDisconnectModal}
        onConfirm={() => {
          setShowDisconnectModal(false);
          onDisconnectLawpay();
        }}
        onClose={() => setShowDisconnectModal(false)}
        providerType={providers.LAWPAY}
      />
      {activeProviderType && (
        <PaymentProviderSwitchConfirmationModal
          isVisible={showSwitchModal}
          onConfirm={() => {
            setShowSwitchModal(false);
            onSaveLawpay();
          }}
          onClose={() => setShowSwitchModal(false)}
          activeProviderType={activeProviderType}
          desiredProviderType={providers.LAWPAY}
        />
      )}
      {activeProviderType && (
        <PaymentProviderDeactivateConfirmationModal
          isVisible={showDeactivateModal}
          onConfirm={() => {
            setShowDeactivateModal(false);
            onSaveLawpay();
          }}
          onClose={() => setShowDeactivateModal(false)}
          desiredProviderType={providers.LAWPAY}
        />
      )}
    </div>
  );
};

LawpaySettingsForm.displayName = 'LawpaySettingsForm';

const option = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isTrust: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
});

const options = PropTypes.arrayOf(option);

const bankAccount = PropTypes.shape({
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  closed: PropTypes.bool,
  cardAccountOption: option,
  eCheckAccountOption: option,
});

LawpaySettingsForm.propTypes = {
  lawpayAccountName: PropTypes.string.isRequired,
  disconnectModalId: PropTypes.string.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  filterOptionsByAccountType: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isDisconnecting: PropTypes.bool.isRequired,
  activateToggled: PropTypes.bool.isRequired,
  activeProviderType: PropTypes.string,
  showInvoiceLink: PropTypes.bool.isRequired,
  showScanToPay: PropTypes.bool.isRequired,
  bankAccounts: PropTypes.arrayOf(bankAccount).isRequired,
  lawpayAccountOptions: options.isRequired,
  onToggleField: PropTypes.func.isRequired,
  onSelectAccount: PropTypes.func.isRequired,
  onSaveLawpay: PropTypes.func.isRequired,
  onDisconnectLawpay: PropTypes.func.isRequired,
  onActivateToggle: PropTypes.func.isRequired,
};

LawpaySettingsForm.defaultProps = {
  activeProviderType: undefined,
};
