// Facets are defined here to maintain a single list
module.exports = Object.freeze({
  allowAutoChargeCreditCard: 'allowAutoChargeCreditCard',
  allowDuplicateCheque: 'allowDuplicateCheque', // In US, duplicate operating/office cheque is always not allowed. In AU & GB, this is allowed. This facet should be named allowDuplicateOfficeCheque.
  allowEmailAttachment: 'allowEmailAttachment',
  allowOverdraw: 'allowOverdraw',
  authorisedByField: 'authorisedByField',
  autoAllocatePayments: 'autoAllocatePayments',
  autoGeneratedReference: 'autoGeneratedReference',
  bankDetailsForDirectDebit: 'bankDetailsForDirectDebit', // BB-5948 capture bank details for direct debit, AU/GB? only
  bankNameAndBranchNameRequiredForTrustAccount: 'bankNameAndBranchNameRequiredForTrustAccount', // When add/edit trust account, bank name and branch name are required in AU
  bankNameForDepositFunds: 'bankNameForDepositFunds',
  bankRecCannotReconcileAcrossMultipleMonths: 'bankRecCannotReconcileAcrossMultipleMonths', 
  bankRecCannotReconcileOnToday: 'bankRecCannotReconcileOnToday', // BB-9819 max end date = yesterday
  blockTrustTransactionsOnClosedMatter: 'blockTrustTransactionsOnClosedMatter', // In AU/GB, we don't want to allow transaction on closed matters
  BPAY: 'BPAY',
  bsbAccountNumberForBankAccounts: 'bsbAccountNumberForBankAccounts',
  bulkFinalise: 'bulkFinalise', // bulk finalise with payments
  businessDXAddress: 'businessDXAddress', // In AU/GB, we have DXAddress, which I assume is Document eXchange address
  businessNumber: 'businessNumber', // Show ABN/VAT Number: used for invoice generation etc.
  canCloseTrustAccountWithWarning: 'canCloseTrustAccountWithWarning', //  BB-9363 for more info. For accounts has unreconciled transactions or open adjustments, AU can not close them, US can close them with warning
  checkUnpresentedChequeBalanceOnDeleteMatter: 'checkUnpresentedChequeBalanceOnDeleteMatter', // BB-7676 ticket for more info
  chequeMemo: 'chequeMemo',
  chequeTrustPaymentTypes: 'chequeTrustPaymentTypes', // In AU, we have more trust payment types, Eg.bank cheque, trust cheque
  clientCoversFeeOnDeposits: 'clientCoversFeeOnDeposits', // Used for Stripe/EzyCollect
  CMA: 'CMA',
  combineAttachment: 'combineAttachment', // US only
  companyNumber: 'companyNumber', // Show ACN/CRN: used for invoice generation etc.
  complianceTransactionDescriptionFormatting: 'complianceTransactionDescriptionFormatting', // AU Law Society compliant description field
  complianceTransactionPaidToReversalFormatting: 'complianceTransactionPaidToReversalFormatting', // AU Law Society compliant paid to field for reversals
  complianceTransactionReasonFormatting: 'complianceTransactionReasonFormatting', // AU Law Society compliant reason field
  contactBankBalance: 'contactBankBalance', // GB & AU only
  contingencyBillingType: 'contingencyBillingType', // US & AU only
  cookieConsent: 'cookieConsent',
  createPDFReceiptOnDepositDefaultSettingTrue: 'createPDFReceiptOnDepositDefaultSettingTrue',
  creditMemo: 'creditMemo', // BB-5541 ticket for more info
  creditNotePaymentReference: 'creditNotePaymentReference',
  eBillingSendingPreference: 'eBillingSendingPreference',
  deleteTransaction: 'deleteTransaction',
  echeque: 'echeque',
  eInvoiceUserDefinedSwitch: 'eInvoiceUserDefinedSwitch', // Replaces BB-9182 feature switch: User can on/off eInvoice in firm and matter settings as opposed to Smokeball defined through BB-5725
  eInvoiceRequiresAuthentication: 'eInvoiceRequiresAuthentication',
  electronicPayment: 'electronicPayment',
  endOfMonthReport: 'endOfMonthReport', // end of month report
  finaliseWithPayments: 'finaliseWithPayments',
  forceOriginalInvoice: 'forceOriginalInvoice', // Facet for BB-5913 for US only. Search usage for explanation comment - this is not general "Original Invoice" facet
  includeDescriptionInMatterDisplay: 'includeDescriptionInMatterDisplay',
  integrationFeeWise: 'integrationFeeWise',
  integrationLawpay: 'integrationLawpay',
  integrationMyob: 'integrationMyob',
  integrationMyobInvoiceAndPayment: 'integrationMyobInvoiceAndPayment',
  integrationQuickBooks: 'integrationQuickBooks',
  integrationStripe: 'integrationStripe',
  integrationXero: 'integrationXero',
  integrationXeroInvoice: 'integrationXeroInvoice',
  interest: 'interest',
  invoiceAttachment: 'invoiceAttachment',
  invoiceHideTaxDisplay: 'invoiceHideTaxDisplay', // Used to show 'Invoice' instead of 'Tax Invoice' and hide firm VAT details. This is required for UK when invoicing outside of UK
  leadWithNoReferenceIsBillable: 'leadWithNoReferenceIsBillable',
  matterBalance: 'matterBalance',
  matterBalancesSettings: 'matterBalancesSettings', // In US, firm has settings to choose matter balances instead of contact balances
  matterClientAddressRequiredForTrustDeposit: 'matterClientAddressRequiredForTrustDeposit', // BB-2709 ticket for more info
  matterClientRequiredForTrustDeposit: 'matterClientRequiredForTrustDeposit', // BB-2709 ticket for more info
  matterContactBalance: 'matterContactBalance',
  matterDescriptionRequired: 'matterDescriptionRequired', // BB-6219 ticket for more info
  matterFieldSeparateRow: 'matterFieldSeparateRow', // For cbui-vendor-payment-entry-split-payors modal, matter field position is different. In US, it's in a separate row
  matterToMatterTransferDescription: 'matterToMatterTransferDescription', // BB-4311 AU/GB only
  matterTrustBalanceByDate: 'matterTrustBalanceByDate',
  operatingAccount: 'operatingAccount',
  operatingAccountDetail: 'operatingAccountDetail',
  operatingChequeVendorAddress: 'operatingChequeVendorAddress', //BB-7599 shows checkbox to include vendor address on operating cheques, currently US only
  operatingRetainerByFilters: 'operatingRetainerByFilters',
  originalInvoice: 'originalInvoice', // feature switch BB-1761 is enabled for regions depending on this facet, it cannot be completely replaced due to LD overrides
  overpaymentMustGoToTrust: 'overpaymentMustGoToTrust', // Does the region require overpayments to go to trust
  pdfAttachment: 'pdfAttachment', // Do we support attaching user uploaded PDF to the end of invoices etc.
  printLaterChequeOnly: 'printLaterChequeOnly', // In US, we don't show Print Now and Print Manually as options sometimes. This seems to be related to BB-1203 but it is used for finalising single invoice as well
  protectedFundsListingShowContactColumn: 'protectedFundsListingShowContactColumn', // BB-8741
  reasonField: 'reasonField', // AU Law Society compliance
  reportAccountBalancesShowPayor: 'reportAccountBalancesShowPayor',
  reportAccountBalancesShowReceiptNumber: 'reportAccountBalancesShowReceiptNumber',
  reportCashBookPaymentsShowDescriptionColumn: 'reportCashBookPaymentsShowDescriptionColumn', // AU Law Society compliance
  reportCashBookPaymentsShowPaymentTypeColumn: 'reportCashBookPaymentsShowPaymentTypeColumn', // AU Law Society compliance
  reportHeaderSimple: 'reportHeaderSimple',
  reportHeaderWithClientAddressForMatter: 'reportHeaderWithClientAddressForMatter',
  reportJournalTransfersShowAuthorisedByColumn: 'reportJournalTransfersShowAuthorisedByColumn', // AU Law Society compliance BB-4299
  reportJournalTransfersShowDescriptionColumn: 'reportJournalTransfersShowDescriptionColumn', // For US. In AU, we should reason instead of description
  reportJournalTransfersShowReferenceColumn: 'reportJournalTransfersShowReferenceColumn', // AU Law Society compliance
  reportPreparedBySignature: 'reportPreparedBySignature', // AU Law Society compliance
  reportShowClientAddressesForMatter: 'reportShowClientAddressesForMatter', // AU Law Society compliance BB-4562
  reportShowPaidToColumn: 'reportShowPaidToColumn', // AU Law Society compliance BB-4562, BB-4301
  reportShowReasonAsPartOfDescription: 'reportShowReasonAsPartOfDescription', // For US. In AU, we have to separate these fields for AU Law Society compliance
  reportShowSeparateMatterDetailsColumns: 'reportShowSeparateMatterDetailsColumns', // AU Law Society compliance
  reportShowMatterTypeColumn: 'reportShowMatterTypeColumn', // AU Law Society compliance (QLS) BB-6935
  saveCreditCard: 'saveCreditCard', // Allow saving credit card for payment later
  showInvoiceNumbersForTransactions: 'showInvoiceNumbersForTransactions',
  showPayToFieldForDisbursement: 'showPayToFieldForDisbursement',
  showTrustBalance: 'showTrustBalance',
  sourceAccountDetails: 'sourceAccountDetails',
  stateAbbreviated: 'stateAbbreviated',
  statutoryDepositMatter: 'statutoryDepositMatter',
  tax: 'tax',
  timeKeeperInitials: 'timeKeeperInitials',
  transactionNumberingPrefix: 'transactionNumberingPrefix', // BB-3686 AU/GB Auto-Numbering Options
  transactionReverseWarning: 'transactionReverseWarning', // BB-10856 ticket for more info
  transactionsByEnteredDate: 'transactionsByEnteredDate', // Use entered date instead of transaction date - AU Law Society compliance (Replaces BB-1148 and BB-5066)
  transactionsByFilters: 'transactionsByFilters',
  trustAccountBankFee: 'trustAccountBankFee',
  trustAccountInterest: 'trustAccountInterest',
  trustAccountPerState: 'trustAccountPerState',
  trustContacts: 'trustContacts',
  trustLedger: 'trustLedger',
  trustPaymentBankTransferType: 'trustPaymentBankTransferType',
  trustPaymentReasonField: 'trustPaymentReasonField', // US California Bar association rule BB-13230
  ttoNumbering: 'ttoNumbering', // Does region supports TTO numbering? Used instead of region check with isTrustToOfficeNumberingEnabled
  ttoDefaultDateFilters: 'ttoDefaultDateFilters', // US not set to anything, AU+GB defaults to before date and last 7 days
  utbms: 'utbms',
});
