import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import PropTypes from 'prop-types';
import * as contactTransactionFilters from 'web/redux/route/home-billing-contact-transactions';
import { filterTrustAccountsByContact } from 'web/redux/selectors/filter-trust-accounts';
import { BANK_BALANCE_TYPE, getBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { sortByProperty } from '@sb-itops/nodash';
import ContactTransactionsFilterPanel from './ContactTransactionsFilterPanel';

const mapStateToProps = (state, { trustAccountId, contactId, t }) => {
  const { getShowFilters, getFilters } = contactTransactionFilters.selectors;
  const expanded = getShowFilters(state);
  const { showHidden, dateListFilter } = getFilters(state);

  const matterContactBalanceEnabled = getBalanceType() === BANK_BALANCE_TYPE.matterContact;

  const trustAccounts = filterTrustAccountsByContact(contactId).map((trust) => ({
    label: getBankAccountName(trust, t),
    id: trust.id,
    // trust.state cannot be trusted if multi trust switch is off, this translates legacy isInactive to state so it can now be trusted
    status: isTrustAccountClosed(trust) ? BANK_ACCOUNT_STATE.CLOSED : BANK_ACCOUNT_STATE.OPEN,
    location: trust.location,
  }));
  const activeTrustAccounts = sortByProperty(
    trustAccounts.filter((trust) => trust.status === BANK_ACCOUNT_STATE.OPEN),
    'label',
    'asc',
    false,
  );

  const selectedTrustAccountId = trustAccountId;
  const trustAccountsFilterPanelConfig = {
    showProtectedFunds: false,
    overviewOnClicklinkParams: {
      id: 'trust',
      type: 'contactTransactions',
      params: [{ contactId, trustAccountId: 'all' }],
    },
    parentPageName: 'home.billing.view-contact.transactions',
    getSpecificOnClickLinkParams: (trustId) => ({
      type: 'contactTransactions',
      id: 'trust',
      params: [{ contactId, trustAccountId: trustId }],
    }),
  };

  return {
    expanded,
    showHidden,
    dateListFilter,
    contactId,
    showOperatingAccount: hasFacet(facets.operatingAccount) && matterContactBalanceEnabled,
    showDeletedToggle: hasFacet(facets.deleteTransaction),
    trustAccounts: activeTrustAccounts,
    selectedTrustAccountId: selectedTrustAccountId || '',
    trustAccountsFilterPanelConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(contactTransactionFilters.actions.toggleShowFilters()),
  onDateListChange: (value) => dispatch(contactTransactionFilters.actions.setFilter('dateListFilter', value)),
  onDeletedTransactionsChange: (value) => dispatch(contactTransactionFilters.actions.setFilter('showHidden', value)),
});

const ContactTransactionsFilterPanelContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactTransactionsFilterPanel)),
);

ContactTransactionsFilterPanelContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  contactId: PropTypes.string.isRequired,
  trustAccountId: PropTypes.string,
  scope: PropTypes.string.isRequired,
};

ContactTransactionsFilterPanelContainer.defaultProps = {
  accountType: undefined,
  trustAccountId: undefined,
};

export default ContactTransactionsFilterPanelContainer;
