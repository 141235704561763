'use strict';

/**
 * Sanitize invoice version data to remove unnecessary properties. The version return will be used as part of the Messages sent to the messaging server. This can help keep the message clean and small.
 *
 * @param {object} params
 * @param {object} params.invoiceVersion invoice version data
 * @returns {object}
 */
function sanitizeInvoiceVersionData({ invoiceVersion }) {
  // Safety guard to avoid error in case invoiceVersion is null, undefined, or an empty object {}
  if (!invoiceVersion || Object.keys(invoiceVersion).length === 0) {
    return {};
  }

  // Deep clone the version object to avoid modifying the original object
  const newVersion = JSON.parse(JSON.stringify(invoiceVersion));

  // Remove version.entries[x].expenseEntity and version.entries[x].feeEntity
  if (newVersion.entries) {
    newVersion.entries.forEach((entry) => {
      // eslint-disable-next-line no-param-reassign
      delete entry.expenseEntity;
      // eslint-disable-next-line no-param-reassign
      delete entry.feeEntity;
    });
  }

  // Remove all __typename elements
  removeTypename(newVersion);

  delete newVersion.opdateId;

  delete newVersion.sbFeatures;

  return newVersion;
}

function removeTypename(obj) {
  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (key === '__typename') {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the value associated with the key is an object,
      // recursively call removeTypename on that object
      removeTypename(obj[key]);
    }
  });
}

module.exports = {
  sanitizeInvoiceVersionData,
  removeTypename,
};
