import { schemeMappings } from '@sb-itops/region-schemes';

export const phonePlaceholders = {
  phoneAreaCode: {
    [schemeMappings.phoneNumberScheme.US]: '202',
    [schemeMappings.phoneNumberScheme.AU]: '',
    [schemeMappings.phoneNumberScheme.GB]: '',
  },
  phoneNumber: {
    [schemeMappings.phoneNumberScheme.US]: '555 0152',
    [schemeMappings.phoneNumberScheme.AU]: '02 5550 1234',
    [schemeMappings.phoneNumberScheme.GB]: '020 7946 0000',
  },
  cellAreaCode: {
    [schemeMappings.phoneNumberScheme.US]: '202',
    [schemeMappings.phoneNumberScheme.AU]: '',
    [schemeMappings.phoneNumberScheme.GB]: '',
  },
  cellNumber: {
    [schemeMappings.phoneNumberScheme.US]: '555 0152',
    [schemeMappings.phoneNumberScheme.AU]: '0400 000 000',
    [schemeMappings.phoneNumberScheme.GB]: '0700 000 000',
  },
} as const;
