'use strict';

const { regionType } = require('@sb-itops/region');

const getRoles = (region) => {
  const regionRoles = {
    [regionType.US]: ['Attorney', 'Bookkeeper', 'Paralegal', 'Reception', 'Secretary'],
    [regionType.AU]: [
      'Solicitor',
      'Conveyancer',
      'Licensed Conveyancer',
      'Registered Conveyancer',
      'Bookkeeper',
      'Paralegal',
      'Reception',
      'Secretary',
    ],
    [regionType.GB]: [
      'Solicitor',
      'Conveyancer',
      'Licensed Conveyancer',
      'Registered Conveyancer',
      'Bookkeeper',
      'Paralegal',
      'Reception',
      'Secretary',
    ],
  };

  return regionRoles[region];
};

const timekeeperClassifications = Object.freeze({
  '': { value: '', label: 'None' },
  ADJSTR: { value: 'ADJSTR', label: 'Adjuster' },
  ADJSMT: { value: 'ADJSMT', label: 'Adjustment' },
  ANALST: { value: 'ANALST', label: 'Analyst' },
  ARBITR: { value: 'ARBITR', label: 'Arbitrator' },
  ASSOC: { value: 'ASSOC', label: 'Associate' },
  TRANEE: { value: 'TRANEE', label: 'Associate Trainee' },
  AUDITR: { value: 'AUDITR', label: 'Auditor' },
  CLKSEC: { value: 'CLKSEC', label: 'Clerk or Secretary' },
  CONSLT: { value: 'CONSLT', label: 'Consultant' },
  TEMPAT: { value: 'TEMPAT', label: 'Contract Attorney' },
  TEMPOS: { value: 'TEMPOS', label: 'Contract Other Staff' },
  CSTCNS: { value: 'CSTCNS', label: 'Costs Counsel' },
  CTRPTR: { value: 'CTRPTR', label: 'Court Reporter/Transcriptionist' },
  DSCATT: { value: 'DSCATT', label: 'Discovery Attorney' },
  DSCPMG: { value: 'DSCPMG', label: 'Discovery Project Manager' },
  DSCANJ: { value: 'DSCANJ', label: 'Discovery Technician/Analyst - Junior' },
  DSCANS: { value: 'DSCANS', label: 'Discovery Technician/Analyst - Senior' },
  DOCCDR: { value: 'DOCCDR', label: 'Document Coder' },
  DOCRAT: { value: 'DOCRAT', label: 'Document Reviewer - Attorney' },
  DOCRNA: { value: 'DOCRNA', label: 'Document Reviewer - Non-Attorney' },
  EVDEVL: { value: 'EVDEVL', label: 'Evidentiary Developer' },
  EXPERT: { value: 'EXPERT', label: 'Expert' },
  FLTFEE: { value: 'FLTFEE', label: 'Flat/Fixed Fee' },
  FORANL: { value: 'FORANL', label: 'Forensic Analyst' },
  HLNLPF: { value: 'HLNLPF', label: 'High-Level Non-Lawyer Professional"' },
  INVSTG: { value: 'INVSTG', label: 'Investigator/Private Investigator' },
  IPSVPV: { value: 'IPSVPV', label: 'IP Service Provider' },
  IPAGNT: { value: 'IPAGNT', label: 'IP Agent' },
  LGLAST: { value: 'LGLAST', label: 'Legal Assistant' },
  LGLINT: { value: 'LGLINT', label: 'Legal Intern' },
  LGPRMG: { value: 'LGPRMG', label: 'Legal Project Manager' },
  LIBRRN: { value: 'LIBRRN', label: 'Librarian' },
  MEDITR: { value: 'MEDITR', label: 'Mediator' },
  MDRCRV: { value: 'MDRCRV', label: 'Medical Records Reviewer (Non-Certified Specialist)' },
  OFCOUN: { value: 'OFCOUN', label: 'Of Counsel' },
  NBOTHR: { value: 'NBOTHR', label: 'Other' },
  PARALG: { value: 'PARALG', label: 'Paralegal' },
  PARTNR: { value: 'PARTNR', label: 'Partner' },
  RSRCHR: { value: 'RSRCHR', label: 'Research Specialist/Researcher' },
  RSPROF: { value: 'RSPROF', label: 'Responsible Professional' },
  SECNDE: { value: 'SECNDE', label: 'Secondee' },
  SPAGNT: { value: 'SPAGNT', label: 'Service of Process Agent' },
  SOLDSG: { value: 'SOLDSG', label: 'Solution Designer' },
  STFATT: { value: 'STFATT', label: 'Staff Attorney' },
  STFCNS: { value: 'STFCNS', label: 'Staff Counsel/Attorney' },
  TPADMN: { value: 'TPADMN', label: 'Third Party Administrator' },
  TRAINR: { value: 'TRAINR', label: 'Trainer/Instructor/Educator' },
  TRNDEV: { value: 'TRNDEV', label: 'Training/Education Program Developer' },
  TRNFAG: { value: 'TRNFAG', label: 'Transfer Agent' },
  TRANSL: { value: 'TRANSL', label: 'Translator' },
  VIDOGR: { value: 'VIDOGR', label: 'Videographer' },
});

const timekeeperClassificationOptions = Object.values(timekeeperClassifications);

// [fill, stroke]
const colorTuples = Object.freeze([
  ['#cc8f59', '#a67143'],
  ['#cdb48e', '#a18964'],
  ['#fba45f', '#db8642'],
  ['#ffdc73', '#e3a33d'],
  ['#5bd380', '#3aaf5f'],
  ['#a0cca8', '#699370'],
  ['#4ecdbf', '#3da69b'],
  ['#76d5ff', '#31a4d4'],
  ['#30c2ed', '#3fa1bd'],
  ['#44a4ef', '#3b93d7'],
  ['#83a7f1', '#567ecf'],
  ['#cda2ce', '#b46eb5'],
  ['#cd78bd', '#b05fa1'],
  ['#e39696', '#cf5b5b'],
  ['#e76b6b', '#d74848'],
  ['#92a9a7', '#7d8592'],
  ['#797d85', '#64666a'],
  ['#a0a9b7', '#939ba7'],
]);

module.exports = {
  getRoles,
  timekeeperClassificationOptions,
  timekeeperClassifications,
  colorTuples,
};
