import React from 'react';
import { AddEventModal } from 'web/components';
import PropTypes from 'prop-types';
import { CalendarModalContainer } from './CalendarModal.container';

export const ADD_EVENT_MODAL_ID = 'add-event';

export const AddEventModalContainer = (props) => {
  const WrappedComponent = CalendarModalContainer(AddEventModal);

  return <WrappedComponent scope={ADD_EVENT_MODAL_ID} {...props} />;
};

AddEventModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  matterId: PropTypes.string,
  appointmentData: PropTypes.object,
};

AddEventModalContainer.defaultProps = {
  appointmentData: {},
  matterId: '',
};

AddEventModalContainer.displayName = 'AddEventModal.container';
