import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { AccordianMultiSelect } from '@sb-itops/react/accordian-multi-select';
import { getMap as getMatterTypes } from '@sb-matter-types/redux/matter-types';
import { getList } from '@sb-matter-management/redux/matters';
import { findUsedMatterTypeIds, buildMatterTypeTree } from 'web/redux/selectors/used-matter-types';

function mapStateToProps(state, props) {
  const matterTypes = getMatterTypes();
  const matterTypeIds = props.matterTypes || findUsedMatterTypeIds(getList());
  const selectedMatterTypes = props.selectedList || [];
  const matterTypeTree = buildMatterTypeTree({
    matterTypeIds,
    matterTypes,
    selectedList: selectedMatterTypes,
  });
  // eslint-disable-next-line no-console
  return {
    ...props,
    tree: matterTypeTree,
    selectedList: selectedMatterTypes,
  };
}

const MatterTypeAccordian = withReduxStore(connect(mapStateToProps)(AccordianMultiSelect));

MatterTypeAccordian.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.any,
  collapseTreeOnDeselect: PropTypes.bool,
  matterTypes: PropTypes.arrayOf(PropTypes.string),
  selectedList: PropTypes.arrayOf(PropTypes.string),
};

MatterTypeAccordian.defaultProps = {
  className: undefined,
  selected: undefined,
  collapseTreeOnDeselect: false,
  matterTypes: undefined,
  selectedList: undefined,
};

export default MatterTypeAccordian;
