import React from 'react';
import { components as ReactSelectComponents } from 'react-select';

const InputBuilder = (props) => (innerProps) =>
  (
    <ReactSelectComponents.Input
      {...innerProps}
      focus-key={props.focusKey}
      isDisabled={!!innerProps.selectProps.value}
    />
  );

export default InputBuilder;
