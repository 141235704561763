import { gql } from '@apollo/client';

const query = gql`
  query CalendarSyncSettings {
    calendarSyncSettings {
      id
      microsoftId
      microsoftCalendarId
      microsoftAccountDisplayDetails
      machineInfo
      isSyncEnabled
    }
  }
`;

const notificationIds = ['CalendarSyncSettingsNotifications.MicrosoftCalendarSyncSettingsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const CalendarSyncSettings = {
  query,
  notificationIds,
};
