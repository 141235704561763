import { BANK_BALANCE_TYPE } from '../../bank-account-settings';

export const buildBankAccountBalanceOpdatesForVendorPayment = ({ payment, bankAccountBalances, bankBalanceType }) => {
  const total = payment.payors ? payment.payors.reduce((sum, { amount }) => sum + amount, 0) : payment.amount;

  const matterBalances = bankAccountBalances.matterBalances.map((matterBalance) =>
    matterBalance.matterId === payment.matterId
      ? {
          ...matterBalance,
          balance: matterBalance.balance - total,
          availableBalance: matterBalance.availableBalance - total,
          protectedBalance: matterBalance.protectedBalance,
        }
      : matterBalance,
  );

  const contactBalances =
    bankBalanceType === BANK_BALANCE_TYPE.matter
      ? bankAccountBalances.contactBalances
      : bankAccountBalances.contactBalances.map((contactBalance) => {
          const payor = payment.payors.find((p) => p.payorId === contactBalance.contactId);

          if (!payor) {
            return contactBalance;
          }

          return {
            ...contactBalance,
            balance: contactBalance.balance - payor.amount,
            availableBalance: contactBalance.availableBalance - payor.amount,
            protectedBalance: contactBalance.protectedBalance,
          };
        });

  const bankAccountBalancesOpdate = {
    id: bankAccountBalances.id,
    isDeleted: bankAccountBalances.isDeleted,
    isInactive: bankAccountBalances.isInactive,
    balance: bankAccountBalances.balance - total,
    availableBalance: bankAccountBalances.availableBalance - total,
    protectedBalance: bankAccountBalances.protectedBalance,
    matterBalances,
    contactBalances,
  };

  return [bankAccountBalancesOpdate];
};
