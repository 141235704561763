import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import moment from 'moment';
import { RESPONSE_TYPE } from '@sb-itops/fetch-wrapper-browser';
import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import { getBalanceType } from '../bank-account-settings';
import {
  buildPaymentOpdates,
  opdateCache as opdatePaymentCache,
  rollbackOpdateCache as rollbackPaymentOpdateCache,
} from '../payments';
import {
  getById as getInvoiceTotalsById,
  buildInvoiceTotalsOpdates,
  opdateCache as opdateInvoiceTotalsCache,
  rollbackOpdateCache as rollbackInvoiceTotalsOpdateCache,
} from '../invoice-totals';
import {
  buildInvoiceOpdates,
  opdateCache as opdateInvoiceCache,
  rollbackOpdateCache as rollbackInvoiceOpdateCache,
} from './invoice-opdates';
import {
  buildBankAccountBalanceOpdates,
  opdateCache as opdateBankAccountBalancesCache,
  rollbackOpdateCache as rollbackBankAccountBalancesOpdateCache,
  getMap,
} from '../bank-account-balances';
import { getById as getInvoiceById } from '.';
import { selectors } from '../bank-account-balances.2';
import { getOperatingAccount } from '../bank-account';

const { getFirmBankAccount } = selectors;

const getAccountId = () => authSelectors.getAccountId(store.getState());

export const addInvoicePayment = (payment) => {
  const opdateCaches = () => {
    const invoiceTotals = getInvoiceTotalsById(payment.invoiceId);
    const invoice = getInvoiceById(payment.invoiceId);
    const paymentOpdates = buildPaymentOpdates(payment, getAccountId(), moment());
    const invoiceTotalsOpdates = buildInvoiceTotalsOpdates(invoiceTotals, payment);
    const invoiceOpdates = buildInvoiceOpdates(invoiceTotals, payment, invoice);
    const operatingBankAccountId = getOperatingAccount().id;
    const trustBankAccountId = payment.sourceAccountType === 'Trust' ? payment.sourceBankAccountId : null;
    const bankAccountBalanceOpdates = buildBankAccountBalanceOpdates({
      payment,
      operatingAccount: getFirmBankAccount(getMap(), { bankAccountId: operatingBankAccountId }),
      trustAccount: trustBankAccountId ? getFirmBankAccount(getMap(), { bankAccountId: trustBankAccountId }) : {},
      bankBalanceType: getBalanceType(),
    });

    opdatePaymentCache({ optimisticEntities: paymentOpdates });
    opdateInvoiceTotalsCache({ optimisticEntities: invoiceTotalsOpdates });
    opdateInvoiceCache({ optimisticEntities: invoiceOpdates });
    opdateBankAccountBalancesCache({ optimisticEntities: bankAccountBalanceOpdates });

    return () => {
      rollbackPaymentOpdateCache({ optimisticEntities: paymentOpdates });
      rollbackInvoiceTotalsOpdateCache({ optimisticEntities: invoiceTotalsOpdates });
      rollbackInvoiceOpdateCache({ optimisticEntities: invoiceOpdates });
      rollbackBankAccountBalancesOpdateCache({ optimisticEntities: bankAccountBalanceOpdates });
    };
  };

  return fetchPostThunkFactory({
    path: `/billing/payment/:accountId/invoice/${payment.invoiceId}`,
    opdateCaches,
    responseType: RESPONSE_TYPE.text,
  })(payment);
};
