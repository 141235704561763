'use strict';

/**
 * Validates the given html text line count against the given max value
 * @param html - html text
 * @param maxLines - the maximum amount of lines allowed
 * @returns {Boolean}
 */
function isValidLines(html, maxLines) {
  if (!html) {
    return true;
  }
  return extractLineCount(html) <= maxLines;
}

/**
 * Calculates the number of lines in the given html text
 * @param html - html text
 * @returns {Number}
 */
function extractLineCount(html) {
  const paras = html.querySelectorAll('p').length;
  const divs = html.querySelectorAll('div').length;
  return paras + divs || 0;
}

module.exports = {
  isValidLines,
};
