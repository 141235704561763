angular.module('sb.billing.webapp').component('sbDataUnsavedChanges', {
    require: {
      compose: '^sbCompose'
    },
    bindings: {
      composeKey: '@',
      unsavedKey: '@'
    },
    controller: function (sbLoggerService, sbUnsavedChangesService) {
      'use strict';

      const ctrl = this;
      const log = sbLoggerService.getLogger('sbDataUnsavedChanges');

      ctrl.$onInit = function () {
        log.info(`composeKey: ${ctrl.composeKey}, unsavedKey: ${ctrl.unsavedKey}`);
        sbUnsavedChangesService.onSaveMemory((key) => {
          log.info(`unsaved service update for: ${key}`);
          updateData(key);
        });
        updateData(ctrl.unsavedKey);
      };

      function updateData(key){
        if (key !== ctrl.unsavedKey) {
          return;
        }
        const data = sbUnsavedChangesService.loadMemory(ctrl.unsavedKey) || {};
        log.info(`update data from unsaved service: ${key}`, data);
        ctrl.compose.setComposeData({ dataType: 'unsavedChanges', data }, ctrl.composeKey);
      }
    }
  }
);
