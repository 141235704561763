angular.module('sb.billing.webapp').component('sbDuiBankAccTxnListContextMenu', {
  templateUrl: 'ng-components/account-transaction-list/context-menu/dui-bank-acc-txn-list-context-menu.html',
  bindings: { transactionId: '<', paymentId: '<' },
  controller: function ($state, $timeout, sbTransactionService) {
    'use strict';

    const that = this;
    let isPopoverOpen = false;

    that.isOpen = isOpen;
    that.open = open;
    that.close = close;
    that.mouseOver = mouseOver;
    that.mouseLeave = mouseLeave;
    that.showContextMenu = showContextMenu;
    that.printVendorProofOfPayment = printVendorProofOfPayment;
    that.isVendorPayment = isVendorPayment;

    that.sbData = {};
    that.hasFocus = false;
    that.transaction = sbTransactionService.getById(that.transactionId);

    function isOpen () {
      return isPopoverOpen;
    }

    function open () {
      isPopoverOpen = true;
    }

    function close () {
      isPopoverOpen = false;
    }

    function mouseOver () {
      that.hasFocus = true;
    }

    function mouseLeave () {
      that.hasFocus = false;
      delayedClose();
    }

    function printVendorProofOfPayment () {
      $state.go('home.billing.vendor-proof-of-payment', { transactionId: that.transactionId, paymentId: that.paymentId });
    }

    function isVendorPayment () {
      return that.transaction && that.transaction.type === 'VendorPayment';
    }

    function showContextMenu () {
      return isVendorPayment();
    }

    /*
     we need to close the context menu if the user clicks on another row (using mouseLeave)
     BUT we need to allow enough time so they can move the mouse over the context menu to prevent closing.
     */
    function delayedClose () {
      $timeout(() => {
        if (!that.hasFocus) {
          close();
        }
      }, 400);
    }

  }

});
