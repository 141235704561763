import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withOnLoad } from '@sb-itops/react';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/fee-wise';
import { FeeWiseSavedCardForm } from './FeeWiseSavedCardForm';

export const FeeWiseSavedCardFormContainer = withOnLoad(({ onReadyForSubmit, onSubmit, savedCard, triggerSubmit }) => {
  const [submissionTriggered, setSubmissionTriggered] = useState(false);
  const [expirationMonth, year] = savedCard.expiry.split('/');
  const expirationYear = year.length === 2 ? year : year.slice(-2); // only 2 digits year
  const savedCardWithExpiration = { ...savedCard, ...{ expirationMonth, expirationYear } };

  onReadyForSubmit(true);

  // Trigger the submission
  if (triggerSubmit && !submissionTriggered) {
    setSubmissionTriggered(true);

    // This should match what we get from charge form but we don't have much info here.
    // We really need mainly paymentToken
    const paymentMethodDetails = {
      card: {
        schemePartialNumber: savedCard.display.replace('*', ''),
        cardholderName: savedCard.cardholderName,
        scheme: savedCard.cardType,
      },
      paymentToken: savedCard.token,
    };

    onSubmit({
      paymentMethodDetails,
      paymentMethod: paymentMethods.Card,
      // exposing some fields to help make success/failure message more meaningful
      cardType: savedCard.cardType,
      cardNumberTruncated:
        paymentMethodDetails && paymentMethodDetails.card && paymentMethodDetails.card.schemePartialNumber,
    });
  }

  // We are ok to allow submission again.
  if (!triggerSubmit && submissionTriggered) {
    setSubmissionTriggered(false);
  }

  return <FeeWiseSavedCardForm savedCard={savedCardWithExpiration} />;
});

FeeWiseSavedCardFormContainer.displayName = 'FeeWiseSavedCardFormContainer';

FeeWiseSavedCardFormContainer.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  savedCard: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
};

FeeWiseSavedCardFormContainer.defaultProps = {};
