import React from 'react';
import { SlidingToggle, Button, InputWithAddon, PreviewPdfModal } from '@sb-itops/react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './CreateBankReconciliation.module.scss';
import PaymentsReceiptsTable from './PaymentsReceiptsTable';

const CreateBankReconciliation = ({
  items,
  onClickLink,
  onToggleItem,
  onToggleItems,
  onToggleExpand,
  onToggleUnreconciled,
  showUnreconciled,
  onShowPayments,
  onShowReceipts,
  showPayments,
  showReceipts,
  onShowDeleted,
  showDeleted,
  showDeletedToggle,
  useEnteredDate,
  sortBy,
  sortDirection,
  onSort,
  searchFilter,
  setSearchFilter,
  generatingDraftRecon,
  printDraftPreview,
  showPrintDraft,
  draftReconURL,
  setDraftReconURL,
  onRowClick,
}) => {
  if (!items) {
    return undefined;
  }

  const reduceToggledItems = ({ selected }) =>
    items.reduce(
      (toggled, item) => {
        if (item.selected === selected) {
          if (item.isDepositSlip && !item.reconciled) {
            toggled.depositSlipIds.push(item.id);
          } else if (item.isConsolidatedTrustToOffice || item.isBulkDeposit) {
            toggled.consolidatedIds.push(item.id);
          } else {
            toggled.transactionIds.push(item.id);
          }
        }
        return toggled;
      },
      {
        transactionIds: [],
        depositSlipIds: [],
        consolidatedIds: [],
      },
    );

  const selectAll = () => {
    const { transactionIds, depositSlipIds, consolidatedIds } = reduceToggledItems({ selected: false });
    onToggleItems({
      transactionIds,
      depositSlipIds,
      consolidatedIds,
    });
  };

  const clearAll = () => {
    const { transactionIds, depositSlipIds, consolidatedIds } = reduceToggledItems({ selected: true });

    onToggleItems({
      transactionIds,
      depositSlipIds,
      consolidatedIds,
    });
  };

  return (
    <>
      <div className={Styles.ribbon}>
        <div className={Styles.selectAll}>
          {showPrintDraft && (
            <Button
              type="tertiary"
              className={Styles.printDraft}
              onClick={printDraftPreview}
              disabled={generatingDraftRecon}
            >
              Print
              {generatingDraftRecon && <i className="fa fa-spinner fa-pulse fa-fw" />}
            </Button>
          )}
        </div>
        <div className={Styles.filter}>
          <InputWithAddon
            className={classnames(Styles.search)}
            icon="search-icon"
            placeholder="Search for..."
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
          />
          <div className={Styles.toggle}>
            <SlidingToggle scope="show-unreconciled" onChange={onToggleUnreconciled} selected={showUnreconciled} />
            <span>Show unreconciled items only</span>
          </div>
          <div className={Styles.toggle}>
            <SlidingToggle
              scope="show-payments"
              onChange={() => onShowPayments(!showPayments)}
              selected={showPayments}
            />
            <span>Show payments</span>
          </div>
          <div className={Styles.toggle}>
            <SlidingToggle
              scope="show-receipts"
              onChange={() => onShowReceipts(!showReceipts)}
              selected={showReceipts}
            />
            <span>Show receipts</span>
          </div>
          {showDeletedToggle && (
            <div className={Styles.toggle}>
              <SlidingToggle scope="show-deleted" onChange={() => onShowDeleted(!showDeleted)} selected={showDeleted} />
              <span>Show deleted</span>
            </div>
          )}
        </div>
      </div>
      <PaymentsReceiptsTable
        onRowClick={onRowClick}
        items={items}
        onClickLink={onClickLink}
        onToggleItem={onToggleItem}
        onToggleExpand={onToggleExpand}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
        selectAll={selectAll}
        clearAll={clearAll}
        useEnteredDate={useEnteredDate}
      />
      <PreviewPdfModal isVisible={!!draftReconURL} url={draftReconURL} onClose={() => setDraftReconURL('')} />
    </>
  );
};

CreateBankReconciliation.displayName = 'CreateBankReconciliation';

CreateBankReconciliation.propTypes = {
  items: PropTypes.array.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onToggleItem: PropTypes.func.isRequired,
  onToggleItems: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  onToggleUnreconciled: PropTypes.func.isRequired,
  showUnreconciled: PropTypes.bool,
  onShowPayments: PropTypes.func.isRequired,
  onShowReceipts: PropTypes.func.isRequired,
  showPayments: PropTypes.bool.isRequired,
  showReceipts: PropTypes.bool.isRequired,
  onShowDeleted: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool.isRequired,
  showDeletedToggle: PropTypes.bool.isRequired,
  useEnteredDate: PropTypes.bool.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  searchFilter: PropTypes.string.isRequired,
  setDraftReconURL: PropTypes.func.isRequired,
  draftReconURL: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  generatingDraftRecon: PropTypes.bool,
  printDraftPreview: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  showPrintDraft: PropTypes.bool,
};

CreateBankReconciliation.defaultProps = {
  generatingDraftRecon: false,
  showUnreconciled: false,
  showPrintDraft: true,
  onRowClick: () => {},
};

export default CreateBankReconciliation;
