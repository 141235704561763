'use strict';

import {
  updateCache as updateRedux,
  getConfig,
} from '@sb-billing/redux/default-matter-billing-configuration';

angular.module('@sb-billing/services').service('sbDefaultMatterBillingConfigurationService', function (sbEndpointType, sbGenericCacheService) {
  const svc = this;
  const endpoint = 'billing/default-matter-billing-configuration';
  sbGenericCacheService.setupCache({
    name: 'sbDefaultMatterBillingConfigurationService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: endpoint},
      poll: 60,
      subscriptions: 'notifier.BillingMattersNotifications.DefaultMatterBillingConfigurationUpdated'
    },
    updateRedux
  });

  svc.getConfig = getConfig;
});
