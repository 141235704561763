'use strict';

const getItemIdToResendFromCorrelation = (correlation) => {
  /**
   * From .NET code:
    //we make a couple of assumptions:
    //- we cannot have 2 groups with the same operation and different IsReversal flags both failed 
    //  (because if the original operation failed, there would be nothing to reverse)
    //- if we have 2 groups with different operations (e.g. batch payment and credit payment), they will
    //  both have the same message triggering it (that's how it works at the moment
    //so, we only ever need to send one message from the correlation
   */
  const failedGroup = correlation.groups.find((group) => group.isFailed);
  if (failedGroup) {
    const lastItem = failedGroup.items[0];
    if (lastItem.exceptionMessage || lastItem.exceptionDetail) {
      return lastItem.id;
    }
  }
  return undefined;
};

module.exports = {
  getItemIdToResendFromCorrelation,
};
