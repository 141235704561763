import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ExpandCollapseButton = memo(({ onExpandCollapseClick, open, reverse }) => (
  <button
    title={open ? 'Collapse' : 'Expand'}
    className={classnames({
      'expand-collapse-button': true,
      reverse: !reverse,
    })}
    type="button"
    onClick={onExpandCollapseClick}
  >
    {open !== reverse ? (
      <>
        <i className="fa fa-angle-right" />
        <i className="fa fa-angle-right" />
      </>
    ) : (
      <>
        <i className="fa fa-angle-left" />
        <i className="fa fa-angle-left" />
      </>
    )}
  </button>
));

ExpandCollapseButton.propTypes = {
  open: PropTypes.bool.isRequired,
  reverse: PropTypes.bool,
  onExpandCollapseClick: PropTypes.func,
};

ExpandCollapseButton.defaultProps = {
  onExpandCollapseClick: undefined,
  reverse: false,
};

export default ExpandCollapseButton;
