import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@sb-itops/react';
import { MattersDisplay } from '@sb-matter-management/react/matters-display';
import classnames from 'classnames';
import Styles from './TrustToOfficeReversalWarning.module.scss';

function displayCurrency(trustBalance, t) {
  return t('cents', { val: trustBalance });
}

export const TrustToOfficeReversalWarning = memo(({ warnings }) => {
  const { t } = useTranslation();

  if (!warnings || !warnings.length) {
    return null;
  }

  if (warnings.length === 1) {
    const { matterId, trustBalance } = warnings[0];
    return (
      <div className={classnames(Styles.warning, Styles.warningInline)}>
        Warning: As of the transaction date, the {t('trustAccount').toLowerCase()} for{' '}
        <MattersDisplay matterIds={[matterId]} /> will be overdrawn to a balance of {displayCurrency(trustBalance, t)}
      </div>
    );
  }

  return (
    <div className={Styles.warning}>
      <p>Warning: As of the transaction date, the below {t('trustAccounts').toLowerCase()} will be overdrawn:</p>
      {warnings.map(({ matterId, trustBalance }, index) => (
        <div key={index} className={Styles.warningRow}>
          <div className={Styles.inlineMatter}>
            <MattersDisplay matterIds={[matterId]} />
          </div>
          <div>{displayCurrency(trustBalance, t)}</div>
        </div>
      ))}
    </div>
  );
});

TrustToOfficeReversalWarning.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string,
      trustBalance: PropTypes.number,
    }),
  ).isRequired,
};

TrustToOfficeReversalWarning.defaultProps = {};
