import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { usePrevious } from '../hooks';
import Styles from './LoadingBar.module.scss';

// Fake loading bar when we dont know how long an operation will take
export const LoadingBar = ({
  loading,
  animationDurationMs,
  hideAfterCompletionMs,
  containerClassName,
  containerStyle,
  progressClassName,
  progressStyle,
}) => {
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let loadingTimeout;
    // Allow the bar to complete, then reset state
    if (progress >= 100) {
      loadingTimeout = setTimeout(() => {
        setVisible(false);
        setProgress(0);
      }, hideAfterCompletionMs);
    }

    return () => loadingTimeout && clearTimeout(loadingTimeout);
  }, [progress, hideAfterCompletionMs]);

  const prevLoadingState = usePrevious(loading);
  useEffect(() => {
    if (loading) {
      setVisible(true);
      setProgress(95);
    }

    if (!loading && prevLoadingState === true) {
      setProgress(100);
    }
  }, [loading, prevLoadingState]);

  const mergedContainerStyle = {
    ...containerStyle,
    opacity: visible ? 1 : 0,
  };

  const animationMs = progress >= 100 ? Math.round(animationDurationMs / 5) : animationDurationMs;

  const mergedProgressStyle = {
    ...progressStyle,
    transition: `width ${animationMs}ms ease-out`,
    width: `${progress}%`,
  };

  return (
    <div className={classnames(Styles.loadingBarContainer, containerClassName)} style={mergedContainerStyle}>
      <div className={classnames(Styles.loadingBarProgress, progressClassName)} style={mergedProgressStyle} />
    </div>
  );
};

LoadingBar.displayName = 'LoadingBar';

LoadingBar.propTypes = {
  loading: PropTypes.bool.isRequired,
  animationDurationMs: PropTypes.number,
  hideAfterCompletionMs: PropTypes.number,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  progressClassName: PropTypes.string,
  progressStyle: PropTypes.object,
};
LoadingBar.defaultProps = {
  animationDurationMs: 1000,
  hideAfterCompletionMs: 500,
  containerClassName: undefined,
  containerStyle: {},
  progressClassName: undefined,
  progressStyle: undefined,
};
