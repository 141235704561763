import { gql } from '@apollo/client';
import { feeEntityFragment } from '../fragments/feeEntityFragment.graphql';

const query = gql`
  query UnbilledMatterFees(
    $matterId: ID!
    $includeNonBillableItems: Boolean!
    $feeDate: DateRange
    $entryTypes: [Int]
  ) {
    unbilledFees(
      matterIds: [$matterId]
      filter: { includeNonBillableItems: $includeNonBillableItems, feeDate: $feeDate, entryTypes: $entryTypes }
    ) {
      ...feeEntityFragment
    }
  }

  ${feeEntityFragment}
`;

const notificationIds = ['FeesNotifications.AccountFeesUpdated', 'InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const UnbilledMatterFees = {
  query,
  notificationIds,
};
