import { entryType } from '@sb-billing/business-logic/shared/entities';
import * as Yup from 'yup';

export const AddEditCustomTaskCodeSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  code: Yup.string()
    .required()
    .max(5, 'Max 5 characters')
    .transform((value) => value.toUpperCase()),
  description: Yup.string().required(),
  entryType: Yup.number().required().oneOf(Object.values(entryType)),
  isBillable: Yup.boolean().required(),
});
