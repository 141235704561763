import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Spinner, buttonTypes, SlidingToggle, StatelessSelect, Icon } from '@sb-itops/react';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { PaymentProviderDisconnectConfirmationModal } from '@sb-billing/react/payment-provider-disconnect-confirmation-modal';
import { PaymentProviderSwitchConfirmationModal } from '@sb-billing/react/payment-provider-switch-confirmation-modal';
import { PaymentProviderDeactivateConfirmationModal } from '@sb-billing/react/payment-provider-deactivate-confirmation-modal';
import { PaymentProviderActivationPanel } from '../payment-provider-activation-panel';

import Styles from './FeeWiseSettingsForm.module.scss';

export const FeeWiseSettingsForm = ({
  trustAccounts,
  feeWiseTrustOptions,
  operatingAccount,
  feeWiseOperatingOptions,
  linkedAccountOptions,
  noneOption,
  showInvoiceLink,
  showScanToPay,
  showClosedTrustAccounts,
  activateToggled,
  activeProviderType,
  formInitialised,
  isSendingEmail,
  isSaving,
  isDisconnecting,
  isRefreshing,
  isConnected,
  isNonProdAppEnv,
  onToggleField,
  onSelectAccount,
  onSaveFeeWise,
  onDisconnectFeeWise,
  onReloadBankAccounts,
  onActivateToggle,
  onManageAccountClick,
  onResendWelcomeEmail,
}) => {
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const operatingAccountId = operatingAccount.bankAccountId;
  const operatingAccountReady = !!linkedAccountOptions[operatingAccount.bankAccountId];
  const isPerformingWork = isSaving || isDisconnecting || isRefreshing || isSendingEmail;

  if (!formInitialised || !isConnected) {
    return null;
  }

  const handleOnSave = () => {
    if (activeProviderType && activeProviderType !== providers.FEE_WISE && activateToggled) {
      setShowSwitchModal(true);
      return;
    }
    if (activeProviderType && activeProviderType === providers.FEE_WISE && !activateToggled) {
      setShowDeactivateModal(true);
      return;
    }

    onSaveFeeWise();
  };

  return (
    <div className={Styles.feeWiseSettingsForm}>
      <PaymentProviderActivationPanel
        disabled={isPerformingWork}
        activeProviderType={activeProviderType}
        isActivateToggled={activateToggled}
        onActivateToggle={onActivateToggle}
        providerType={providers.FEE_WISE}
        showButton
        onButtonClick={onManageAccountClick}
      />
      <div className={Styles.sectionTitle}>
        <div className={Styles.title}>Linked Account Setup</div>
        <div className={Styles.toggleSection}>
          <label>
            <SlidingToggle
              id="showClosedTrustAccountsFeeWise"
              scope="showClosedTrustAccountsFeeWise"
              onChange={() => onToggleField({ field: 'showClosedTrustAccounts' })}
              selected={showClosedTrustAccounts}
              disabled={isPerformingWork}
            />
            <span className={Styles.labelText}>Show Closed</span>
          </label>
        </div>
      </div>
      <div className={Styles.bankAccountSection}>
        <div className={Styles.accountTitle}>{operatingAccount.bankAccountName}</div>
        <div>
          <StatelessSelect
            selectedOption={linkedAccountOptions[operatingAccountId] || noneOption}
            options={feeWiseOperatingOptions}
            onChange={(option) =>
              onSelectAccount({
                bankAccountId: operatingAccountId,
                bankAccountType: operatingAccount.bankAccountType,
                option,
              })
            }
            isClearable={false}
            disabled={isPerformingWork}
          />
        </div>
      </div>
      {trustAccounts.map((trustAccount) => (
        <div className={Styles.bankAccountSection} key={trustAccount.bankAccountId}>
          <div className={Styles.accountTitle}>
            {trustAccount.bankAccountName}
            {showClosedTrustAccounts && trustAccount.isClosed && <span className={Styles.closedBadge}>CLOSED</span>}
          </div>
          <div>
            <StatelessSelect
              selectedOption={linkedAccountOptions[trustAccount.bankAccountId] || noneOption}
              options={feeWiseTrustOptions}
              onChange={(option) =>
                onSelectAccount({
                  bankAccountId: trustAccount.bankAccountId,
                  bankAccountType: trustAccount.bankAccountType,
                  option,
                })
              }
              isClearable={false}
              disabled={isPerformingWork}
            />
          </div>
        </div>
      ))}
      <div className={Styles.reloadAccountsSection}>
        <Button
          onClick={onReloadBankAccounts}
          disabled={isPerformingWork}
          type={buttonTypes.link}
          title="Refresh the list of bank accounts you have connected in the transactions portal"
        >
          <Icon type="refresh" className={Styles.reloadIcon} />
          Refresh Accounts {isRefreshing && <Spinner small />}
        </Button>
      </div>

      <div className={Styles.sectionTitle}>Invoice Payment Options</div>
      {!operatingAccountReady && (
        <div className={Styles.userInfoSection}>
          <div className={Styles.helpBlock}>Please select an operating account to allow direct invoice payments</div>
        </div>
      )}

      <div className={Styles.toggleSection}>
        <div className="form-group">
          <div className={Styles.includePaymentLinkOptionContainer}>
            <SlidingToggle
              id="showInvoiceLink"
              scope="showInvoiceLink"
              name="showInvoiceLink"
              onChange={() => onToggleField({ field: 'showInvoiceLink' })}
              selected={showInvoiceLink}
              disabled={isPerformingWork || !operatingAccountReady}
            />
            <label className={Styles.labelText} htmlFor="showInvoiceLink">
              Include payment link on invoices and emails/messages
            </label>
            <Icon
              type="information"
              tooltip="This option applies to the Pay button and QR code on your PDF invoices and invoice reminders, invoice emails and Client Portal messages, and the eInvoice portal"
              className={Styles.icon}
            />
          </div>
        </div>

        <div className="form-group">
          <SlidingToggle
            id="showScanToPay"
            scope="showScanToPay"
            name="showScanToPay"
            onChange={() => onToggleField({ field: 'showScanToPay' })}
            selected={showScanToPay}
            disabled={isPerformingWork || !operatingAccountReady}
          />
          <label className={Styles.labelText} htmlFor="showScanToPay">
            Include Scan to Pay QR code on invoices
          </label>
        </div>
      </div>

      <div className={Styles.buttonSection}>
        <Button className={Styles.saveButton} onClick={handleOnSave} disabled={isPerformingWork} size="full-width">
          Save {isSaving && <Spinner small />}
        </Button>
        {isNonProdAppEnv && (
          <div className={Styles.nonProdActionButtons}>
            <Button
              type={buttonTypes.secondary}
              onClick={onResendWelcomeEmail}
              disabled={isPerformingWork}
              title="This button is displayed in non-prod only"
            >
              Resend welcome email {isSendingEmail && <Spinner small />}
            </Button>
            <Button
              className={Styles.disconnectButton}
              type={buttonTypes.secondary}
              onClick={() => setShowDisconnectModal(true)}
              disabled={isPerformingWork}
              title="This button is displayed in non-prod only"
            >
              Disconnect {isDisconnecting && <Spinner small />}
            </Button>
          </div>
        )}
      </div>
      <PaymentProviderDisconnectConfirmationModal
        isVisible={showDisconnectModal}
        onConfirm={() => {
          setShowDisconnectModal(false);
          onDisconnectFeeWise();
        }}
        onClose={() => setShowDisconnectModal(false)}
        providerType={providers.FEE_WISE}
      />
      {activeProviderType && (
        <PaymentProviderSwitchConfirmationModal
          isVisible={showSwitchModal}
          onConfirm={() => {
            setShowSwitchModal(false);
            onSaveFeeWise();
          }}
          onClose={() => setShowSwitchModal(false)}
          activeProviderType={activeProviderType}
          desiredProviderType={providers.FEE_WISE}
        />
      )}
      {activeProviderType && (
        <PaymentProviderDeactivateConfirmationModal
          isVisible={showDeactivateModal}
          onConfirm={() => {
            setShowDeactivateModal(false);
            onSaveFeeWise();
          }}
          onClose={() => setShowDeactivateModal(false)}
          desiredProviderType={providers.FEE_WISE}
        />
      )}
    </div>
  );
};

FeeWiseSettingsForm.displayName = 'FeeWiseSettingsForm';

FeeWiseSettingsForm.propTypes = {
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      bankAccountId: PropTypes.string.isRequired,
      bankAccountType: PropTypes.string.isRequired,
      bankAccountName: PropTypes.string.isRequired,
      isClosed: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  feeWiseTrustOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  operatingAccount: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
    bankAccountType: PropTypes.string.isRequired,
    bankAccountName: PropTypes.string.isRequired,
  }).isRequired,
  feeWiseOperatingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  linkedAccountOptions: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  noneOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  formInitialised: PropTypes.bool.isRequired,
  activateToggled: PropTypes.bool.isRequired,
  activeProviderType: PropTypes.string,
  isSendingEmail: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isDisconnecting: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isNonProdAppEnv: PropTypes.bool,
  showInvoiceLink: PropTypes.bool.isRequired,
  showScanToPay: PropTypes.bool.isRequired,
  showClosedTrustAccounts: PropTypes.bool.isRequired,

  onToggleField: PropTypes.func.isRequired,
  onSelectAccount: PropTypes.func.isRequired,
  onSaveFeeWise: PropTypes.func.isRequired,
  onDisconnectFeeWise: PropTypes.func.isRequired,
  onReloadBankAccounts: PropTypes.func.isRequired,
  onActivateToggle: PropTypes.func.isRequired,
  onManageAccountClick: PropTypes.func.isRequired,
  onResendWelcomeEmail: PropTypes.func.isRequired,
};

FeeWiseSettingsForm.defaultProps = {
  activeProviderType: undefined,
  isNonProdAppEnv: false,
};

FeeWiseSettingsForm.defaultProps = {
  activeProviderType: undefined,
};
