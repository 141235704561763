import { getApolloClient } from 'web/services/apollo';

/**
 * These notification subscriptions are used for 'cached' GraphQL entities.
 * Cached entities are those retrieved at login time, and updated via
 * notification subscriptions managed here.
 *
 * The purpose of caching the entities is to avoid having to fetch entities
 * that are commonly used and seldom changed, such as list of staff members,
 * or firm tax settings.
 *
 * The main difference in how we handle these cache subscriptions, compared to
 * the standard Load on Demand notification subscriptions, is that we do not
 * evict these queries from cache and instead refetch them immediately when a
 * relevant notification arrives. This way we can avoid states where the data
 * is missing temporarily while being fetched.
 *
 * Subscriptions structure:
 *
 * Map {
 *  'SampleNotification' => Set { 'QueryName1' },
 *  'AnotherNotification' => Set { 'QueryName1', 'QueryName2' },
 * }
 */
const subscriptions = new Map();

/**
 * Clear subscriptions
 * @return void
 */
const clearCacheSubscriptions = () => subscriptions.clear();

/**
 * Check query subscribed to notification
 *
 * @param {import('./types').SubscribeToCacheNotificationsInput} params
 */
const subscribeToCacheNotifications = ({ notificationIds, queryName }) => {
  notificationIds.forEach((notificationId) => {
    if (!subscriptions.get(notificationId)) {
      subscriptions.set(notificationId, new Set());
    }

    const currentSubsForNotificationId = subscriptions.get(notificationId);
    if (currentSubsForNotificationId.has(queryName)) {
      throw new Error(`Query '${queryName}' is already registered for '${notificationId}' notification`);
    }

    currentSubsForNotificationId.add(queryName);
  });
};

/**
 * Check query subscribed to notification
 *
 * @param {import('./types').IsSubscribedToCacheNotificationFunctionInput} params
 */
const isSubscribedToCacheNotification = ({ notificationId, queryName }) => {
  if (!subscriptions.has(notificationId)) {
    return false;
  }
  const currentSubsForNotificationId = subscriptions.get(notificationId);
  if (!currentSubsForNotificationId.has(queryName)) {
    return false;
  }
  return true;
};

/**
 * Check query subscribed to notification
 *
 * @param {import('./types').ProcessCacheNotificationInput} params
 */
const processCacheNotification = async ({ provider, action }) => {
  const notificationId = action ? `${provider}.${action}` : provider;
  const notificationQueryNames = subscriptions.get(notificationId);
  if (!notificationQueryNames || !notificationQueryNames.size) {
    return;
  }

  // Unlike the standard notifications where we evict the queries and let Apollo
  // refetch them when the query becomes active, we need to keep the data in the
  // cache and refetch the specified queries immediately.
  const apolloClient = getApolloClient();
  await apolloClient.refetchQueries({ include: [...notificationQueryNames] });
};

export {
  clearCacheSubscriptions,
  processCacheNotification,
  subscribeToCacheNotifications,
  isSubscribedToCacheNotification,
};
