import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'bank-account-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbBankAccountSettingsService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const BANK_BALANCE_TYPE = Object.freeze({
  matter: 'Matter',
  matterContact: 'MatterContact',
});

export const getSettings = () => getList()[0];

export const getBalanceType = () => {
  const bankAccountSetting = getSettings();
  return bankAccountSetting && bankAccountSetting.bankBalanceType;
};

export const isMatterBalanceType = () => getBalanceType() === BANK_BALANCE_TYPE.matter;

export const isMatterContactBalanceType = () => getBalanceType() === BANK_BALANCE_TYPE.matterContact;
