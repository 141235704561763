import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SlidingToggle } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import Styles from './MatterRecentOnlyFilter.module.scss';

export const MatterRecentOnlyFilter = memo(
  ({ toggleRecentlyViewed, recentlyViewed }) =>
    featureActive('BB-8047') && (
      <div className={Styles.recentMattersButton}>
        <div onClick={toggleRecentlyViewed} className={Styles.recentMattersLabel}>
          Recently viewed
        </div>
        <SlidingToggle scope="recent-matters" onChange={toggleRecentlyViewed} selected={recentlyViewed} />
      </div>
    ),
);

MatterRecentOnlyFilter.displayName = 'MatterRecentOnlyFilter';

MatterRecentOnlyFilter.propTypes = {
  recentlyViewed: PropTypes.bool.isRequired,
  toggleRecentlyViewed: PropTypes.func.isRequired,
};

MatterRecentOnlyFilter.defaultProps = {};
