'use strict';

const { surchargeTypeValues } = require('./surcharge-types');

/**
 * Calculate surcharge
 * @param {*} amountToApplySurchargeOn This should be fee and/or expense total minus any write offs and discounts
 * @param {*} surchargeStruct Surcharge setting object
 * @returns surcharge amount in cents
 */
function calculateSurcharge(amountToApplySurchargeOn, surchargeStruct) {
  let surcharge = 0;

  // % Surcharge is calculated on fee and expense total minus any discount and write offs
  // (This is due to tax being calculated after discount and LawPay giving
  // the percentage part of the transaction fee off of total amount paid)
  // Only apply the flat rate if the billable amount is greater than zero dollars
  if (amountToApplySurchargeOn > 0 && surchargeStruct) {
    // fixed surcharge
    if (surchargeStruct.type === surchargeTypeValues.FIXED && surchargeStruct.fixedSurcharge > 0) {
      surcharge = surchargeStruct.fixedSurcharge;
    }

    // percentage surcharge
    if (surchargeStruct.type === surchargeTypeValues.PERCENTAGE && surchargeStruct.percentageBp > 0) {
      // percentage is stored as basis point
      surcharge = Math.round((surchargeStruct.percentageBp / 10000) * amountToApplySurchargeOn);
    }
  }

  return surcharge;
}

module.exports = {
  calculateSurcharge,
};
