'use strict';

/**
 * Check if cheque printing is enabled for a bank account
 * @param {Object} params
 * @param {Object} params.bankAccountChequePrintSettings Bank account cheque print settings entity
 */
function isChequesEnabledForAccount({ bankAccountChequePrintSettings }) {
  return bankAccountChequePrintSettings.printingActive;
}

module.exports = {
  isChequesEnabledForAccount,
};
