'use strict';

// Note: GB has counties rather than states. To remain consistent, we will refer to them as states in code.

// This is pulled from SB's RegionData and it's what I think
// the states should be. However in practice they are just
// string:string identity mappings.

const statesInGb = [
  { name: 'England', id: 'ENG' },
  { name: 'Wales', id: 'WLS' },
  { name: 'Scotland', id: 'SCT' },
  { name: 'Northern Ireland', id: 'NIR' },
];
/*
const statesInGb = [
  { name: 'England', id: 'England' },
  { name: 'Wales', id: 'Wales' },
  { name: 'Scotland', id: 'Scotland' },
  { name: 'Northern Ireland', id: 'Northern Ireland' },
];
*/
module.exports = {
  statesInGb,
};
