
'use strict';

const { simpleFetch } = require('./src/simple-fetch');
const { FetchError } = require('./src/fetch-error');
const constants = require('./src/constants');

module.exports = {
  simpleFetch,
  FetchError,
  constants,
};
