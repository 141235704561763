import { store } from '@sb-itops/redux';
import * as evergreenRequestsFilters from 'web/redux/route/home-billing-accounts-evergreen-requests';


angular.module('sb.billing.webapp').component('sbEvergreenRequestsView', {
  templateUrl: 'ng-routes/home/billing/accounts/evergreen-requests/evergreen-requests-view.html',
  controller: function ($state, $scope, sbTrustRetainersService, sbLoggerService, sbDefaultMatterBillingConfigurationService, sbLinkService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbEvergreenRequests');

    ctrl.onClickLink = sbLinkService.onClickLink
    ctrl.onClickRow = ({ matterId }) => $state.go('home.billing.view-matter.transactions.trust', { matterId });

    // log.setLogLevel('info');

    if (!sbDefaultMatterBillingConfigurationService.getConfig().minimumTrustRetainerActive) {
      //if evergreen requests have been turned off, redirect to txns
      $state.go('home.billing.accounts.transactions');
    }

    ctrl.sbData = {};

    ctrl.sortData = {};
    ctrl.selectedFilters = {};

    ctrl.emailRequests = emailRequests;
    ctrl.onSort = onSort;
    ctrl.$onInit = () => {
      const viewState = evergreenRequestsFilters.selectors.getState(store.getState());
      ctrl.selectedFilters = viewState.filters;
      onSort(viewState);
    }
    const unsubscribeFromStore = store.subscribe(_.throttle(() => {
      const state = evergreenRequestsFilters.selectors.getState(store.getState());
      ctrl.selectedFilters = state.filters;
      $scope.$applyAsync();
    }, 100));

    $scope.$on('$destroy', () => {
      if (unsubscribeFromStore) {
        unsubscribeFromStore()
      }
    });

    ctrl.dataChangeFunction = () => {
      log.error("dataChangeFunction in evergreen requests is deprecated. Use redux in this component");
    };

    function emailRequests() {
      const { selectedRequests, requests } = evergreenRequestsFilters.selectors.getState(store.getState());
    
      const selectedMatters = requests.reduce((acc, req) => {
        const matterId = req.matter.matterId;
        if (selectedRequests[matterId]) {
          acc.push({
            matterId,
            bankAccountId: req.matter.trustAccountId,
          });
        }
        return acc;
      }, []);
    
      sbTrustRetainersService.batchRequestRetainerP(selectedMatters);
    }   

    function onSort ({sortBy, sortDirection}) {
      ctrl.sortData = {
        sortBy,
        sortDirection,
      };
    }
  }
});
