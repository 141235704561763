import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSelector } from 'react-redux';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { useCorrespondenceHistoryPerContact } from 'web/hooks/useCorrespondenceHistoryPerContact';
import { getInvoiceCorrespondenceHistoryGroupedByDebtor } from '@sb-billing/redux/correspondence-history';
import { MultiContactCorrespondenceDetails } from './MultiContactCorrespondenceDetails';

const hooks = ({ invoiceId }) => ({
  useCorrespondenceHistory: () => {
    const invoiceSummary = useSelector(() => getInvoiceById(invoiceId));
    const debtorIds = invoiceSummary?.currentVersion?.debtors.map(({ id }) => id) || [];
    const correspondenceHistoryByDebtor = getInvoiceCorrespondenceHistoryGroupedByDebtor(invoiceId, debtorIds);

    const { loading, latestCorrespondencePerContact } = useCorrespondenceHistoryPerContact({
      correspondenceHistory: correspondenceHistoryByDebtor,
    });

    return {
      loading,
      latestCorrespondencePerContact,
    };
  },
});

export const MultiContactCorrespondenceDetailsContainer = withReduxProvider(
  composeHooks(hooks)(MultiContactCorrespondenceDetails),
);

MultiContactCorrespondenceDetailsContainer.displayName = 'MultiContactCorrespondenceDetailsContainer';

MultiContactCorrespondenceDetailsContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  allowResend: PropTypes.bool,
  onResendClicked: PropTypes.func,
  onSendViaMenuClicked: PropTypes.func,
};

MultiContactCorrespondenceDetailsContainer.defaultProps = {
  allowResend: false,
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};
