import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query MatterSummaries(
    $filter: MatterSearchFilter
    $offset: Int
    $limit: Int
    $sort: ListSort
    $searchText: String
    $includeMatterHourlyRate: Boolean!
  ) {
    matterSearch(searchFilter: $filter, offset: $offset, limit: $limit, sort: $sort, searchText: $searchText) {
      pageInfo {
        hasNextPage
      }
      results {
        id
        isLead
        matterNumber
        clientNames
        clientDisplay
        otherSideDisplay
        matterStarted
        status
        matterType {
          matterTypeId
          name
        }
        attorneyResponsible {
          id
          initials
          name
        }
        billingConfiguration {
          id
          isUtbmsEnabled
          billingType
        }
        matterHourlyRate @include(if: $includeMatterHourlyRate) {
          id
          allStaffRate
          rateOverrideType
          ratesPerStaff {
            staffId
            rate
          }
          billableMinutes
          rateSet {
            id
            versions {
              versionId
              validFrom
              validTo
              ratesPerStaff {
                staffId
                rate
              }
            }
          }
        }
      }
    }
  }
`;

const notificationIds = [
  'WebQueryBillingSharedNotifications.CustomerBillingConfigurationUpdated',
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterSummaries = {
  query,
  notificationIds,
};
