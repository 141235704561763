import uuid from '@sb-itops/uuid';
import { updateCache as updateRedux } from '@sb-billing/redux/trust-cheque-print-settings';
import {
  chequeStylesByValue,
  printMethodsByValue,
} from '@sb-billing/business-logic/cheques'

angular.module('@sb-billing/services').service('sbTrustChequePrintSettingsService', function (sbGenericEndpointService,
  sbGenericCacheService, sbEndpointType) {

  const that = this;
  const endpoint = 'billing/trust-cheque-print-settings';
  const subscriptions = 'notifier.AccountsNotifications.TrustChequePrintSettingsUpdated';

  sbGenericCacheService.setupCache({
    name: 'sbTrustChequePrintSettingsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: endpoint },
      poll: 60,
      subscriptions
    },
    updateRedux
  });

  that.saveP = saveP;

  function saveP(data) {
    data.versionId = uuid();
    data.style = chequeStylesByValue[data.style].code; // string to int
    data.printMethod = printMethodsByValue[data.printMethod].code; // string to int
    return sbGenericEndpointService.postPayloadP(endpoint, encodeURIComponent(data.bankAccountId), data, 'POST', { changeset: { sbTrustChequePrintSettingsService: [data] } });
  }

});
