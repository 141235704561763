import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';

import { actions as supportDebugActions } from 'web/redux/route/billing-support-debug';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, { entityCacheName, entityId, groupEntityCacheName, groupEntityId }) => ({
  setDebugEntity: () => {
    // this conditional check is to get around race conditions when changing tabs
    // e.g. when moving away from the matter tab to opening the view-bill tab, this gets
    // triggered twice - the matter tab is attempting to set entityId to undefined.
    if (entityCacheName && entityId) {
      dispatch(
        supportDebugActions.setDebugEntity({
          entityCacheName,
          entityId,
          groupEntityCacheName,
          groupEntityId,
        }),
      );
    }
  },
});

const DebugEntityContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    useEffect(() => {
      props.setDebugEntity();
    });
    return null;
  }),
);

DebugEntityContainer.displayName = 'DebugEntityContainer';

DebugEntityContainer.propTypes = {
  entityCacheName: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  groupEntityCacheName: PropTypes.string,
  groupEntityId: PropTypes.string,
};

DebugEntityContainer.defaultProps = {
  entityId: undefined,
};

export default DebugEntityContainer;
