import uuid from '@sb-itops/uuid';
import { getLogger } from '@sb-itops/fe-logger';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { actions } from 'web/redux/features/application-state';

const log = getLogger('build-version');

export const getBuildVersionP = async () => {
  try {
    const response = await fetchPostP({
      path: `/version/:accountId/${uuid()}`,
    });

    const serverVersion = response && response.body && response.body.version;

    if (serverVersion) {
      store.dispatch(actions.setServerVersion({ serverVersion }));
    }

    return serverVersion;
  } catch (err) {
    log.warn('Problem getting build version', err);
    throw err;
  }
};
