'use strict';

const getInvoiceDetailRequestMacros = () => {
  // invoice template settings > eInvoice > description shown when viewing an einvoice line item
  const macros = [
    { name: '[DATE]', tooltip: 'The date of the fee' },
    { name: '[TIMEKEEPER_NAME]', tooltip: 'The name of the Fee Earner' },
  ];
  return macros;
};

module.exports = { getInvoiceDetailRequestMacros };
