import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoginForm, AuthenticatorTokenForm, NewPasswordRequiredForm } from '@sb-itops/react';
import { LoginXeroTwoFactorNotice } from 'web/components';

import Styles from './LoginRoute.module.scss';

export const LoginRoute = React.memo((props) => {
  const {
    username,
    loginInProgress,
    isPrivateComputer,
    forgotPasswordUrl,
    showUpdatePaymentMethod,
    challengeType,
    branding,
    errorMessages,
    onSwitchUser,
    onLogin,
    onUpdatePaymentMethod,
    twoFactorXeroLoginBlock,
    onEnableTwoFactorClick,
    loginFormSubheader,
  } = props;

  const logoClass = branding === 'TriConvey' ? 'tri-convey-logo-pale' : 'logo-pale';
  const systemName = branding === 'TriConvey' ? 'triConvey' : 'Smokeball';

  return (
    <div
      className={classnames(
        Styles.loginRoute,
        branding === 'TriConvey' ? Styles.triConveyBranded : Styles.smokeballBranded,
      )}
    >
      <div className={Styles.sideBox} />
      <div className={Styles.centreBox}>
        <span className={classnames(logoClass, Styles.centreLogo)} />
        {twoFactorXeroLoginBlock ? (
          <LoginXeroTwoFactorNotice branding={branding} onEnableTwoFactorClick={onEnableTwoFactorClick} />
        ) : (
          <>
            <span>
              <h1 className={challengeType ? Styles.challengeMainText : Styles.mainText}>Sign in to {systemName}</h1>
            </span>
            {challengeType && <span className={Styles.challengeUserText}>Logging in as {username}</span>}
            {!challengeType && (
              <>
                {loginFormSubheader && <div className={Styles.subheaderText}>{loginFormSubheader}</div>}
                <LoginForm
                  username={username}
                  loginInProgress={loginInProgress}
                  isPrivateComputer={isPrivateComputer}
                  forgotPasswordUrl={forgotPasswordUrl}
                  showUpdatePaymentMethod={showUpdatePaymentMethod}
                  errorMessages={errorMessages}
                  onSwitchUser={onSwitchUser}
                  onLogin={onLogin}
                  onUpdatePaymentMethod={onUpdatePaymentMethod}
                />
              </>
            )}
            {challengeType && renderChallenge(props)}
          </>
        )}
      </div>
      <div className={Styles.sideBox} />
    </div>
  );
});

function renderChallenge({
  challengeType,
  passwordPolicy,
  challengeResponseInProgress,
  onChallengeAnswered,
  onChallengeCancelled,
  errorMessages,
}) {
  switch (challengeType) {
    case 'SOFTWARE_TOKEN_MFA':
      return (
        <AuthenticatorTokenForm
          verifyInProgress={challengeResponseInProgress}
          onVerify={onChallengeAnswered}
          onCancelVerify={onChallengeCancelled}
          errorMessages={errorMessages}
        />
      );
    case 'NEW_PASSWORD_REQUIRED':
      return (
        <NewPasswordRequiredForm
          passwordPolicy={passwordPolicy}
          passwordChangeInProgress={challengeResponseInProgress}
          onPasswordChange={onChallengeAnswered}
          onCancelPasswordChange={onChallengeCancelled}
          errorMessages={errorMessages}
        />
      );
    default:
      return null;
  }
}

LoginRoute.displayName = 'LoginRoute';

LoginRoute.propTypes = {
  username: PropTypes.string,
  loginInProgress: PropTypes.bool.isRequired,
  isPrivateComputer: PropTypes.bool.isRequired,
  forgotPasswordUrl: PropTypes.string.isRequired,
  showUpdatePaymentMethod: PropTypes.bool.isRequired,
  challengeType: PropTypes.string,
  challengeResponseInProgress: PropTypes.bool,
  passwordPolicy: PropTypes.object,
  branding: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      isVerbose: PropTypes.bool,
    }),
  ),
  onSwitchUser: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onChallengeAnswered: PropTypes.func.isRequired,
  onChallengeCancelled: PropTypes.func,
  onUpdatePaymentMethod: PropTypes.func.isRequired,
  twoFactorXeroLoginBlock: PropTypes.bool.isRequired,
  twoFactorXeroLoginBlockHelpUrl: PropTypes.string,
  onEnableTwoFactorClick: PropTypes.func.isRequired,
  loginFormSubheader: PropTypes.string,
};

LoginRoute.defaultProps = {
  username: undefined,
  challengeType: undefined,
  passwordPolicy: undefined,
  challengeResponseInProgress: false,
  errorMessages: [],
  onChallengeCancelled: () => {},
  twoFactorXeroLoginBlockHelpUrl: undefined,
  loginFormSubheader: undefined,
};

export default LoginRoute;
