import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as forms from '@sb-itops/redux/forms2';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { getAccountId } from 'web/services/user-session-management';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { getById as getBankAccount } from '@sb-billing/redux/bank-account';
import {
  getSettings as getBankReconSetupForAccount,
  removeReconSetup,
  saveReconSetup,
} from '@sb-billing/redux/bank-reconciliation-setup.2';
import * as bankReconciliationsFeature from 'web/redux/route/home-billing-create-bank-reconciliation';
import {
  getById as getTransactionById,
  getByBankAccountId as getTransactionsByBankAccountId,
} from '@sb-billing/redux/transactions';
import {
  getById as getDepositSlipById,
  getByBankAccountId as getDepositSlipsByBankAccountId,
} from '@sb-billing/redux/deposit-slip';
import {
  generateTransactionList,
  generateDepositSlipsList,
} from '@sb-billing/business-logic/bank-reconciliation/transaction-list';
import { isBankAccountClosed } from '@sb-billing/business-logic/bank-account/services';
import { consolidateTrustTransactions } from '@sb-billing/business-logic/transactions/services';
import { getById as getBulkDepositById } from '@sb-billing/redux/bulk-deposit';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getFirmTimezone } from '@sb-firm-management/redux/firm-management/firm';
import { useSelector, useDispatch } from 'react-redux';
import { useReduxActionOnce } from 'web/hooks';
import { BankReconciliationsSetupSchema } from './BankReconciliationsSetupSchema';
import BankReconciliationsSetup from './BankReconciliationsSetup';

const finishSetupModalId = 'finish-setup-modal-id';
const removeSetupModalId = 'remove-setup-modal-id';
const scope = 'bank-reconciliations-setup';

const getDayBefore = (date) => moment(date).add(-1, 'days').startOf('day').toDate();

const hooks = ({ onClickLink, closeCurrentTab, trustAccountId }) => ({
  useSelectors: () => {
    const { t } = useTranslation();
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const { fields: formFields, formValid, formInitialised } = useSelector(formSelectors.getFormState);
    const { reconStartDate, fromFilterDate, toFilterDate, maxToDate } = formFields;

    // Default to system time zone while waiting for fetch
    const [ianaTimezone, setIanaTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
      const fetchTimezone = async () => {
        const { ianaTimeZone } = await getFirmTimezone();
        setIanaTimezone(ianaTimeZone);
      };
      fetchTimezone();
    }, []);

    const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
    const {
      selectors: { getSelectedTransactionIds, getSelectedDepositSlipIds, getSelectedConsolidatedIds },
      actions: { clearState, toggleItems },
      operations: { untoggleInvalidSelectedIds },
    } = useScopedFeature(bankReconciliationsFeature, bankRecScope);

    const selectedTxnIds = useSelector(getSelectedTransactionIds);
    const selectedDepositSlips = useSelector(getSelectedDepositSlipIds);
    const selectedConsolidatedTransactions = useSelector(getSelectedConsolidatedIds);

    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const trustBankAccount = getBankAccount(trustAccountId);
    const isAccountClosed = isBankAccountClosed({
      bankAccount: trustBankAccount,
    });

    const bankReconSetup = getBankReconSetupForAccount(trustAccountId);
    const initialToFilterDate =
      bankReconSetup &&
      !bankReconSetup.isRemoved &&
      bankReconSetup.reconciliationStartDate &&
      dateToInteger(getDayBefore(integerToDate(bankReconSetup.reconciliationStartDate)));

    const setupUnbankedTransactionIds =
      bankReconSetup && !bankReconSetup.isRemoved && bankReconSetup.unbankedTransactionIds
        ? bankReconSetup.unbankedTransactionIds
        : [];

    const transactions = generateTransactionList({
      transactions: getTransactionsByBankAccountId(trustAccountId),
      filters: {
        endDate: toFilterDate?.value || initialToFilterDate || undefined,
        startDate: fromFilterDate?.value,
        showPayments: true,
        showReceipts: true,
        bankReconciliation: undefined,
        ianaTimezone,
      },
      filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
      unbankedTransactionIds: setupUnbankedTransactionIds,
    });

    const consolidatedTransactions = consolidateTrustTransactions({
      transactions: transactions.filter((tx) => !tx.depositSlipId), // Don't want to show bulk deposits that are part of deposit slips
      getPaymentById,
      getInvoiceNumberById,
      getBulkDepositById,
      t,
      consolidatedOnly: true,
    });

    const getConsolidatedTransactionById = (id) =>
      consolidatedTransactions.find((consolidated) => consolidated.id === id);

    // Used to make a reverse mapping from txn => parent consolidated
    const childTxnIdToParentConsolidatedIdMap = consolidatedTransactions.reduce((acc, consolidated) => {
      consolidated.transactionIds.forEach((txId) => {
        acc[txId] = consolidated;
      });
      return acc;
    }, {});

    const depositSlips = generateDepositSlipsList({
      depositSlips: getDepositSlipsByBankAccountId(trustAccountId),
      filters: {
        endDate: toFilterDate?.value || initialToFilterDate || undefined,
        startDate: fromFilterDate?.value,
        showReceipts: true,
        bankReconciliation: undefined,
        ianaTimezone,
      },
      filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
      unbankedTransactionIds: setupUnbankedTransactionIds,
      getTransactionById,
    });

    const dispatch = useDispatch();

    // Clear the form if the tab is closed, otherwise old values will stick around if it is opened again
    useReduxActionOnce('smokeball-tab-closed', ([tab]) => {
      if (tab.type === 'bank-reconciliation-setup') {
        // User will see flicker if form cleared in current tick as react will re-render faster than angular can close the tab
        setTimeout(() => {
          dispatch(formActions.clearForm());
          dispatch(clearState());
        }, 0);
      }
    });

    return {
      scope,
      trustAccountId,
      trustAccountName: getBankAccount(trustAccountId)?.accountName,
      finishSetupModalId,
      removeSetupModalId,
      reconStartDate,
      fromFilterDate,
      toFilterDate,
      maxToDate,
      formInvalid: !formValid,
      selectedTxnIds,
      selectedDepositSlips,
      selectedConsolidatedTransactions,
      transactions: transactions.filter((tx) => !childTxnIdToParentConsolidatedIdMap[tx.id] && !tx.depositSlipId),
      consolidatedTransactions,
      depositSlips,
      isSaving,
      isRemoving,
      isAccountClosed,
      setIsSaving,
      setIsRemoving,
      onLoad: () => {
        if (bankReconSetup && !bankReconSetup.isRemoved && !formInitialised) {
          // Load form
          const fieldValues = {
            reconStartDate: bankReconSetup.reconciliationStartDate,
            toFilterDate:
              bankReconSetup.reconciliationStartDate &&
              dateToInteger(getDayBefore(integerToDate(bankReconSetup.reconciliationStartDate))),
            maxToDate:
              bankReconSetup.reconciliationStartDate &&
              dateToInteger(getDayBefore(integerToDate(bankReconSetup.reconciliationStartDate))),
          };
          dispatch(formActions.initialiseForm({ fieldValues }));

          // Load global redux feature IF it currently isn't initialised (if it is they probably just switched tabs, keep their redux around)
          // Needed to determine if unbankedTransactionIds on the bank recon setup are deposit slips or tto or bulk deposit or reg to reselect if reloading
          const initialSelectedDepositSlips = [];
          const initialSelectedConsolidatedTransactions = [];
          const initialSelectedTransactions = [];
          const unbankedTransactionIds = setupUnbankedTransactionIds;
          unbankedTransactionIds.forEach((txId) => {
            const txn = transactions.find((tx) => tx.id === txId);
            if (txn.depositSlipId) {
              const depositSlip = depositSlips.find((ds) => ds.id === txn.depositSlipId);
              // These checks need to be here as there can be multiple txns with same depositSlipId
              if (depositSlip && !initialSelectedDepositSlips.find((ds) => ds.id === txn.depositSlipId)) {
                initialSelectedDepositSlips.push(depositSlip);
              }
            } else if (childTxnIdToParentConsolidatedIdMap[txId]) {
              // These checks need to be here as there can be multiple txns with same consolidatedId
              if (
                !initialSelectedConsolidatedTransactions.find(
                  (consolidated) => consolidated.id === childTxnIdToParentConsolidatedIdMap[txId].id,
                )
              ) {
                initialSelectedConsolidatedTransactions.push(childTxnIdToParentConsolidatedIdMap[txId]);
              }
            } else {
              initialSelectedTransactions.push(txn);
            }
          });

          dispatch(
            toggleItems({
              transactionIds: initialSelectedTransactions.map((txn) => txn.id),
              depositSlipIds: initialSelectedDepositSlips.map((ds) => ds.id),
              consolidatedIds: initialSelectedConsolidatedTransactions.map((consolidated) => consolidated.id),
            }),
          );
        }

        dispatch(formOperations.validateSchema({ schema: BankReconciliationsSetupSchema }));
      },
      onFieldValueUpdated: (fieldValues) => {
        dispatch(formActions.updateFieldValues({ fieldValues }));
      },
      onReconStartDateChange: (updatedReconStartDate) => {
        if (updatedReconStartDate) {
          const dayBeforeDate = getDayBefore(updatedReconStartDate);
          dispatch(
            untoggleInvalidSelectedIds({
              getTransactionById,
              getDepositSlipById,
              getConsolidatedTransactionById,
              endDate: dateToInteger(dayBeforeDate),
              ianaTimezone,
            }),
          );
        }
        dispatch(
          formActions.updateFieldValues({
            fieldValues: { reconStartDate: updatedReconStartDate ? dateToInteger(updatedReconStartDate) : undefined },
          }),
        );
      },
      validateForm: () => {
        dispatch(formOperations.validateSchema({ schema: BankReconciliationsSetupSchema }));
      },
      showFinishSetupModal: () => {
        setModalDialogVisible({ modalId: finishSetupModalId });
      },
      showRemoveSetupModal: () => {
        setModalDialogVisible({ modalId: removeSetupModalId });
      },
      finishSetup: async () => {
        await dispatch(formOperations.validateSchema({ schema: BankReconciliationsSetupSchema }));
        await dispatch(
          formOperations.submitFormP({
            submitFnP: async () => {
              const setupData = marshalSetup({
                toFilterDate,
                depositSlips,
                depositSlipTransactions: transactions.filter((txn) => !!txn.depositSlipId),
                consolidatedTransactions,
                reconStartDate: reconStartDate?.value,
                selectedDepositSlips,
                selectedTxnIds,
                selectedConsolidatedTransactions,
                trustAccountId,
              });
              await saveReconSetup(setupData, trustAccountId);
              setModalDialogHidden({ modalId: finishSetupModalId });

              closeCurrentTab();
              // Form state is reset after 'smokeball-tab-closed' received
              onClickLink({ type: 'createBankReconciliationSpecificAccount', id: trustAccountId });
            },
          }),
        );
      },
      removeReconciliationSetup: () => {
        removeReconSetup(trustAccountId);
        setModalDialogHidden({ modalId: removeSetupModalId });

        closeCurrentTab();
        // Form state is reset after 'smokeball-tab-closed' received
        onClickLink({ type: 'bankReconciliationHome', id: '' });
      },
      getDayBefore,
      onClickLink,
    };
  },
});

const BankReconciliationsSetupContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(BankReconciliationsSetup)));

function marshalSetup({
  depositSlips,
  depositSlipTransactions,
  consolidatedTransactions,
  reconStartDate,
  selectedDepositSlips,
  selectedTxnIds,
  selectedConsolidatedTransactions,
  trustAccountId,
}) {
  // Unbanked transaction = selected transaction (regular txns, tto txns, bulk deposit txns and deposit slips)
  let unbankedTransactions = [];

  // regular transactions
  const selectedTxns = selectedTxnIds.map((id) => getTransactionById(id));
  unbankedTransactions = [...selectedTxns];

  // consolidated transactions
  consolidatedTransactions.forEach((consolidated) => {
    if (selectedConsolidatedTransactions.includes(consolidated.id)) {
      unbankedTransactions = unbankedTransactions.concat(consolidated.transactions);
    }
  });

  // Add Deposit slip transactions from selected deposit slips
  depositSlips.forEach((ds) => {
    ds.transactions.forEach((dsTxn) => {
      if (selectedDepositSlips.includes(ds.id)) {
        unbankedTransactions.push(dsTxn);
      }
    });
  });
  // We need to check deposit slips in future and add any transactions which falls before reconStartDate
  const depositSlipTransactionsToAdd = findUnbankedDepositSlipTransactionsToInclude({
    depositSlipTransactions,
    depositSlips,
    reconStartDate,
  });

  unbankedTransactions = unbankedTransactions.concat(depositSlipTransactionsToAdd);

  return {
    accountId: getAccountId(),
    bankAccountId: trustAccountId,
    reconciliationStartDate: reconStartDate,
    unbankedTransactionIds: unbankedTransactions.map((tx) => tx.id),
    isRemoved: false,
  };
}

// If a deposit slip falls after the recon start date but
// some of its transactions fall before the recon start date
// then those transactions must be selected as UNBANKED.
const findUnbankedDepositSlipTransactionsToInclude = ({ depositSlipTransactions, depositSlips, reconStartDate }) => {
  const dsIds = new Set();
  depositSlipTransactions.forEach((dsTx) => dsIds.add(dsTx.depositSlipId));
  // deposit slips not included in "depositSlips"
  const missingDepositSlips = [];
  dsIds.forEach((dsId) => {
    if (depositSlips.find((ds) => ds.id === dsId)) {
      return;
    }
    const ds = getDepositSlipById(dsId);
    missingDepositSlips.push({
      ...ds,
      transactions: ds.transactionIds?.map((id) => ({
        ...getTransactionById(id),
        unbanked: true,
      })),
    });
  });

  const startMoment = reconStartDate ? moment(`${reconStartDate}`).startOf('day') : moment();
  const unbankedTransactions = [];

  missingDepositSlips.forEach((ds) => {
    ds.transactions.forEach((dsTxn) => {
      const beforeReconStartDate = hasFacet(facets.transactionsByEnteredDate)
        ? dsTxn.timestamp <= startMoment.toISOString()
        : dsTxn.effectiveDate <= +startMoment.format('YYYYMMDD');

      if (beforeReconStartDate) {
        unbankedTransactions.push(dsTxn);
      }
    });
  });

  return unbankedTransactions;
};

BankReconciliationsSetupContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string,
};

BankReconciliationsSetupContainer.defaultProps = {
  trustAccountId: undefined,
};

export default BankReconciliationsSetupContainer;
