angular.module('sb.billing.webapp').component('sbPdfViewer', {
  templateUrl: 'ng-components/pdf-viewer/pdf-viewer.html',
  bindings: { onPdfLoaded: '&?', onNotificationsP: '&?', getPayloadConfig: '<', onError: '&?', pdfUrl: '<?' },
  controller: function ($scope, $http, $timeout, sbGenericEndpointService) {
    const that = this;
    const noop = () => { };

    const MAX_PDF_DOWNLOAD_RETRIES = 10;

    that.onPdfLoaded = that.onPdfLoaded ? that.onPdfLoaded : noop;
    that.onNotificationsP = that.onNotificationsP ? that.onNotificationsP : Promise.resolve;
    that.onError = that.onError ? that.onError : noop;

    that.$onInit = () => {
      // We could be initialising based on 3 different scenarios;
      // 1. A transaction / payment creation was just performed and we navigated directly to this tab, in which case the data for the PDF might not be ready.
      // 2. A transcation / payment was created in the past, the PDF has already been stored in S3 and we navigated to this tab to view the existing document.
      // 3. This controller is out of scope but the associated tab has not been closed and we are now returning to the tab.

      // If the PDF URL is already present, we have downloaded it previously and stored it in memory, i.e. case 3.
      if (that.pdfUrl) {
        return;
      }

      startPdfDownload(); // Handle cases 1 and 2.
    };

    async function startPdfDownload() {
      // To solve for cases 1 and 2, we start polling the pdf endpoint. If the data for the PDF is not yet available, the endpoint
      // will return a failure. In the case of failure, we will retry after period of time (which increases after each failure).
      // Whilst the polling is in progress, we might receive notifications indicating that the data for the PDF is ready.
      // In this case, we will send a request to the pdf endpoint immediately, because we don't want the user to wait unnecessarily for what
      // could be a long retry period. Eventually, after MAX_PDF_DOWNLOAD_RETRIES, we will consider the process toast and display an error to the user.

      // Start the polling process.
      attemptPdfDownloadP();

      // Wait for notifications indicating data ready.
      try {
        await that.onNotificationsP();

        // We may have already downloaded via polling, ignore.
        if (that.pdfUrl) {
          return;
        }

        await downloadPdfP();

        // Cancel the retry timer as we've successfully downloaded the PDF now.
        if (that.retryTimer) {
          $timeout.cancel(that.retryTimer);
        }
      }
      catch (err) {
        // We don't care about this failure, because the retry logic is still running in attemptPdfDownloadP.
      }
    }

    async function attemptPdfDownloadP(retryCount = 0, retryDelayMs = 500) {
      try {
        if (!that.pdfUrl) {
          await downloadPdfP();
        }
      }
      catch (err) {
        // If the maximum retries have been hit, let the user know that we now consider this a failure.
        if (++retryCount === MAX_PDF_DOWNLOAD_RETRIES) {
          that.onError({ err });
        }

        // Trigger a new download attempt after some delay.
        that.retryTimer = $timeout(() => attemptPdfDownloadP(++retryCount, retryDelayMs * 2), retryDelayMs);
      }
    }

    async function downloadPdfP() {
      // The first http request gets a signed URL for the PDF.
      const { endpoint, additional, extraConfig } = that.getPayloadConfig;
      const signedUrlResponse = await sbGenericEndpointService.getPayloadP(endpoint, additional, extraConfig);

      // The next http request downloads the actual PDF data.
      const request = {
        method: 'GET',
        url: signedUrlResponse.preSignedUrl,
        responseType: 'arraybuffer'
      };

      const pdfResponse = await $http(request);

      // we need to apply changes in the scope since async/await is not natively supported by angular. Unless use $q.
      $scope.$applyAsync(function() {
        const fileDataBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        that.onPdfLoaded({ pdfUrl: window.URL.createObjectURL(fileDataBlob) }); // Lets the higher scope know that the pdf is loaded
      });
    }
  }
});
