import React from 'react';
import PropTypes from 'prop-types';
import RemindersHideDebtorFilterDisplay from './RemindersHideDebtorFilterDisplay';

class RemindersHideDebtorFilter extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    remindedInDaysChecked: !!this.props.remindedInDays,
    remindedInDays: this.props.remindedInDays || 14,
    invoicedInDaysChecked: !!this.props.invoicedInDays,
    invoicedInDays: this.props.invoicedInDays || 7,
    hideMatterDebtorWithNoOverdueInvoices: this.props.hideMatterDebtorWithNoOverdueInvoices,
  };

  onFilterChanged = ({ value, field }) => {
    this.setState(
      (state) => ({
        ...state,
        [field]: value,
      }),
      () => {
        if (field === 'remindedInDaysChecked') {
          this.props.onFilterChanged({
            value: value === false ? undefined : this.state.remindedInDays,
            field: 'remindedInDays',
          });
        } else if (field === 'invoicedInDaysChecked') {
          this.props.onFilterChanged({
            value: value === false ? undefined : this.state.invoicedInDays,
            field: 'invoicedInDays',
          });
        } else if (
          (field === 'remindedInDays' && this.state.remindedInDaysChecked === true) ||
          (field === 'invoicedInDays' && this.state.invoicedInDaysChecked === true)
        ) {
          this.props.onFilterChanged({ value, field });
        }
      },
    );
  };

  propagateChanges = (newValue) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...newValue,
      }),
      () => {
        this.props.onFilterChanged({
          value: this.state.remindedInDaysChecked ? this.state.remindedInDays : undefined,
          field: 'remindedInDays',
        });
        this.props.onFilterChanged({
          value: this.state.invoicedInDaysChecked ? this.state.invoicedInDays : undefined,
          field: 'invoicedInDays',
        });
      },
    );
  };

  // this is only going to be exposed to the parent component
  // eslint-disable-next-line react/no-unused-class-component-methods
  reset = () =>
    this.propagateChanges({
      remindedInDaysChecked: true,
      remindedInDays: 14,
      invoicedInDaysChecked: true,
      invoicedInDays: 7,
    });

  render() {
    return <RemindersHideDebtorFilterDisplay {...this.props} {...this.state} onFilterChanged={this.onFilterChanged} />;
  }
}

RemindersHideDebtorFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  remindedInDays: PropTypes.number,
  invoicedInDays: PropTypes.number,
  hideMatterDebtorWithNoOverdueInvoices: PropTypes.bool,
};

RemindersHideDebtorFilter.defaultProps = {
  remindedInDays: undefined,
  invoicedInDays: undefined,
  hideMatterDebtorWithNoOverdueInvoices: true,
};

export default RemindersHideDebtorFilter;
