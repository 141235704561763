import {
  getRoleOptions,
  getClientRoleOptions,
  getOtherSideRole,
} from '@sb-matter-listing/redux/matter-type-configurations';

import composeHooks from '@sb-itops/react-hooks-compose';
import MatterContacts from './MatterContacts';

interface IProps {
  matterId?: string;
  // form state
  formInitialised: boolean;
  formFields: any;
  formFieldValues: any;
  formDisabled?: boolean;
  formSubmitting?: boolean;
  submitFailed?: boolean;
  // callbacks
  setAddContactCallback: Function;
  onFieldValuesUpdated: Function; // generic method for updating fields
  onFieldValueSet: Function; // generic method for set a field
  onClickLink: Function;
}

const hooks = ({
  formInitialised,
  formFields,
  formFieldValues,
  formDisabled,
  formSubmitting,
  submitFailed,
  onFieldValueSet,
  onFieldValuesUpdated,
}: IProps) => ({
  useForm: () => {
    const { clientCustomerIds, otherSideRole, otherSideCustomerIds } = formFieldValues;

    const { clientCustomerIdsIsRequired, matterTypeId, clientRole, originalMatterTypeId } = formFields;
    const clientRoleOptions = originalMatterTypeId?.value ? getClientRoleOptions(originalMatterTypeId.value) : [];

    const rolesMap = originalMatterTypeId?.value ? getRoleOptions(originalMatterTypeId.value, true) : {};
    const clientRoleLabel = rolesMap[clientRole?.value];
    const otherSideRoleLabel = rolesMap[otherSideRole];

    return {
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields values
      clientCustomerIds,
      otherSideRole,
      otherSideCustomerIds,
      // form fields
      clientRole,
      matterTypeId,
      clientCustomerIdsIsRequired,
      // other
      clientRoleOptions,
      clientRoleLabel,
      otherSideRoleLabel,
      onClientsChanged: async (newSelectedContactIds) => {
        onFieldValueSet('clientCustomerIds', newSelectedContactIds);
      },
      onClientRoleUpdated: (newClientRole) => {
        onFieldValuesUpdated('clientRole', newClientRole);
        onFieldValuesUpdated('otherSideRole', getOtherSideRole(originalMatterTypeId.value, newClientRole));
      },
      onOtherSidesChanged: (newSelectedContactIds) => {
        // onFieldValuesUpdated doesn't work in all scenarios, this is a redux/form2 api
        // bug related to dealing with arrays, I don't have time to chase down now
        onFieldValueSet('otherSideCustomerIds', newSelectedContactIds);
      },
    };
  },
});

export const MatterContactsContainer = composeHooks(hooks)(MatterContacts);
