import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { Spinner } from '@sb-itops/react';
import { FeeWiseSettings } from '../fee-wise-settings';
import { LawpaySettings } from '../lawpay-settings';
import { StripeSettings } from '../stripe-settings';
import { EzyCollectSettingsForm } from '../ezy-collect-settings-form';
import Styles from './PaymentProviderSettings.module.scss';

const providerSpecificSettings = {
  [providers.LAWPAY]: LawpaySettings,
  [providers.STRIPE]: StripeSettings,
  [providers.FEE_WISE]: FeeWiseSettings,
  // old style settings for EZC, it should not be used but for compatibility in case some has EZC active,
  // we should allow them to deactivate so we want to show the settings form
  [providers.EZY_COLLECT]: EzyCollectSettingsForm,
};

export const PaymentProviderSettings = ({
  providerOptions,
  providerTypeSelected,
  onProviderTypeSelected,
  savingProviderType,
  onSaveSettings,
}) => {
  const ProviderSpecificSettings = providerSpecificSettings[providerTypeSelected];
  if (!ProviderSpecificSettings) {
    return null;
  }

  return (
    <div className={Styles.paymentProviderSettings}>
      <h3>Payment Provider Settings</h3>
      <div className="sb-tab-header">
        <ul className={classnames('nav nav-pills', { [Styles.eventsDisabled]: !!savingProviderType })}>
          {providerOptions.map((providerOption) => (
            <li
              key={providerOption.value}
              className={classnames({ active: providerOption.value === providerTypeSelected })}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => onProviderTypeSelected(providerOption.value)} className={Styles.providerSelector}>
                {providerOption.label}
                {savingProviderType === providerOption.value && (
                  <div className={Styles.spinner}>
                    <Spinner small />
                  </div>
                )}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <ProviderSpecificSettings onSaveSettings={onSaveSettings} isSaving={!!savingProviderType} />
      </div>
    </div>
  );
};

PaymentProviderSettings.displayName = 'PaymentProviderSettings';

PaymentProviderSettings.propTypes = {
  providerOptions: PropTypes.array.isRequired,
  providerTypeSelected: PropTypes.string.isRequired,
  onProviderTypeSelected: PropTypes.func.isRequired,
  savingProviderType: PropTypes.string.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
