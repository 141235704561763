import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table, utils } from '@sb-itops/react/table';
import { Button, useTranslation, Column } from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { Icon } from '@sb-itops/react/icon';
import { featureActive } from '@sb-itops/feature';
import { isNewTheme } from 'web/services/theme';

import Styles from './MatterTable.module.scss';

const { balanceCellLocalisedRenderer } = utils;

const leadsBalanceCellRenderer = ({ rowData, cellData, classNames }) => {
  if (!rowData.matterNumber) {
    return '-';
  }
  return balanceCellLocalisedRenderer({ cellData, classNames });
};

export const MatterTable = (props) => {
  const {
    contactId,
    contactsAsLink,
    hideClient,
    onClickLink,
    isLeads,
    matters,
    mattersCount,
    mattersLoading,
    matterStatusOptions,
    matterStatusIds,
    sortDirection,
    sortBy,
    sort,
    showFinancials,
    showStatus,
    allowTrustOverdraw,
    setShowAddLeadsModal,
  } = props;

  const { t } = useTranslation();
  const getTotalMattersCount = () => {
    const totalMattersCount = mattersCount;

    return totalMattersCount ? ` (${totalMattersCount})` : '';
  };

  return (
    <>
      {matterStatusIds && !isNewTheme() && (
        <div className={Styles.gridLabelHeader}>
          {matterStatusIds.length === matterStatusOptions.length ? 'All' : matterStatusIds.join(', ')}{' '}
          {isLeads ? 'Leads' : 'Matters'}
          {getTotalMattersCount()}
        </div>
      )}

      <div className="ribbon panel">
        <Button
          onClick={() => {
            if (isLeads) {
              setShowAddLeadsModal(true);
            } else {
              onClickLink({ type: 'addMatter', id: '', params: [contactId] });
            }
          }}
        >
          Add {isLeads ? 'Lead' : 'Matter'}
        </Button>
        {featureActive('BB-7838') && <span> QA/Test categories enabled</span>}
      </div>

      <Table
        className={Styles.matterTable}
        onRowClick={({ rowData }) => onClickLink({ type: 'matter', id: rowData.id })}
        dataLoading={mattersLoading}
        list={matters}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          className={Styles.matterRef}
          dataKey="matterNumber"
          label="Ref"
          cellRenderer={({ cellData, rowData }) => refRenderer({ cellData, rowData, allowTrustOverdraw, t })}
          flexGrow={2}
        />
        {!hideClient && (
          <Column
            dataKey="clientDisplayName"
            cellRenderer={contactRenderer({ onClickLink, contactsAsLink, renderDataKey: 'clients' })}
            label={isLeads ? 'Prospect(s)' : 'Client(s)'}
            flexGrow={4}
          />
        )}
        <Column
          dataKey="matterType"
          cellDataGetter={({ rowData }) => rowData.matterType?.name}
          label={isLeads ? 'Lead Type' : 'Matter Type'}
          flexGrow={3}
        />
        <Column
          dataKey="otherSideDisplayName"
          cellRenderer={contactRenderer({ onClickLink, contactsAsLink, renderDataKey: 'otherSide' })}
          label="Other Party"
          flexGrow={3}
          disableSort
        />
        <Column className={Styles.description} dataKey="description" label="Description" flexGrow={6} disableSort />
        <Column
          className={Styles.attorneyResponsible}
          dataKey="attorneyResponsible"
          cellDataGetter={({ rowData }) => rowData.attorneyResponsible?.initials}
          label={capitalize(t('attorney'))}
          flexGrow={1}
        />
        {showFinancials && (
          <Column
            className={classnames('right-align', Styles.unbilled)}
            dataKey="unbilled"
            label="Unbilled"
            cellRenderer={isLeads ? leadsBalanceCellRenderer : balanceCellLocalisedRenderer}
            cellDataGetter={getTotalsData}
            flexGrow={1}
            defaultSortDirection="DESC"
          />
        )}
        {showFinancials && (
          <Column
            className={classnames('right-align', Styles.unpaid)}
            dataKey="unpaidExcInterest"
            label="Due"
            cellRenderer={isLeads ? leadsBalanceCellRenderer : balanceCellLocalisedRenderer}
            cellDataGetter={getTotalsData}
            flexGrow={1}
            defaultSortDirection="DESC"
          />
        )}
        {showStatus && <Column dataKey="status" label="Status" width={100} />}
      </Table>
    </>
  );
};

function refRenderer({ cellData, rowData: { matterBalanceTrustSummary }, allowTrustOverdraw, t }) {
  return (
    <div className={Styles.matterRefInner}>
      <div title={cellData} className={Styles.matterRefText}>
        {cellData}
      </div>
      {allowTrustOverdraw && matterBalanceTrustSummary && matterBalanceTrustSummary.isOverdrawn && (
        <div className={Styles.matterRefOverdrawIcon}>
          <Icon type="alert-1" tooltip={`Overdrawn ${t('trustAccount')}`} color="red" />
        </div>
      )}
    </div>
  );
}

/**
 * Contact Renderer
 *
 * @param {Object} param
 * @param {bool} [param.contactsAsLink] Display contacts as links or plain text
 * @param {func} [param.onClickLink] Link routing function
 * @param {string} [param.renderDataKey] Data key used for rendering, specify if different from the `Column` `dataKey` which is used for sorting
 * @returns render function with cell and row data
 */
function contactRenderer({ contactsAsLink, onClickLink, renderDataKey }) {
  return ({ cellData, rowData }) => {
    const cellValue = renderDataKey ? rowData[renderDataKey] : cellData;

    if (!cellValue) {
      return undefined;
    }

    if (typeof cellValue === 'string') {
      return cellValue;
    }

    return cellValue.map(
      (contact, index) =>
        contact && (
          <span key={contact.id}>
            <LinkableText
              // className={Styles.clientDisplay}
              text={contact.displayName || 'unknown'}
              onClickLink={() => onClickLink({ type: 'contact', id: contact.id })}
              asLink={contact.displayName && contactsAsLink && onClickLink} // we dont want to show the display as a link if there is nothing to link to
              inline
            />
            {index !== cellValue.length - 1 && ' | '}
          </span>
        ),
    );
  };
}

function getTotalsData({ rowData, dataKey }) {
  // In case the matterTotals object is null/undefined return 0
  return rowData.matterTotals ? rowData.matterTotals[dataKey] : 0;
}

MatterTable.displayName = 'MatterTable';

MatterTable.propTypes = {
  allowTrustOverdraw: PropTypes.bool.isRequired,
  contactsAsLink: PropTypes.bool,
  contactId: PropTypes.string,
  isLeads: PropTypes.bool,
  hideClient: PropTypes.bool,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  mattersCount: PropTypes.number,
  mattersLoading: PropTypes.bool,
  matterStatusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterStatusIds: PropTypes.array,
  onClickLink: PropTypes.func,
  onClickMatter: PropTypes.func,
  setShowAddLeadsModal: PropTypes.func,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  showFinancials: PropTypes.bool.isRequired,
  showStatus: PropTypes.bool,
  sort: PropTypes.func,
  sortBy: PropTypes.string,
};

MatterTable.defaultProps = {
  contactsAsLink: false,
  isLeads: false,
  contactId: undefined,
  mattersCount: 0,
  mattersLoading: false,
  matterStatusIds: undefined,
  hideClient: false,
  onClickLink: () => {},
  onClickMatter: () => {},
  setShowAddLeadsModal: () => {},
  sort: () => {},
  sortBy: 'clientDisplay',
  sortDirection: 'asc',
  showStatus: false,
};
