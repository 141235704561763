'use strict';
import uuid from '@sb-itops/uuid';

module.exports = {
  get,
  getSessionId,
}

const sessionId = uuid();

function get () {
  return `${uuid()}.${sessionId}`;
}

function getSessionId () {
  return sessionId;
}
