'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import {
  getMap,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/vendor-payments';
import uuid from '@sb-itops/uuid';
import { buildReversalTransactionOpdates } from '@sb-billing/redux/transactions';

const getUserId = () => authSelectors.getAccountId(store.getState());

angular.module('@sb-billing/services').service('sbVendorPaymentsService', function (sbLoggerService, sbGenericCacheService, sbEndpointType, sbGenericEndpointService, sbUuidService, sbTransactionService) {
  const that = this;
  const log = sbLoggerService.getLogger('sbVendorPaymentsService');
  const vendorPaymentEndpoint = 'billing/pay-to-vendor';
  sbGenericCacheService.setupCache({
    name: 'sbVendorPaymentsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/vendor-payments' },
      poll: 60,
      subscriptions: ['notifier.AccountsNotifications.VendorPaymentUpdated']
    },
    updateRedux,
    clearRedux,
  });

  that.reversalPayToVendorP = reversalPayToVendorP;
  that.getById = getById;
  that.getByFilter = getByFilter;

  function getByFilter(filter) {
    const filtered = Object.keys(getMap()).reduce((acc, key) => {
      const p = getById(key);
      if (!p.reversed && filter(p)) {
        acc.push(p);
      }
      return acc;
    }, []);

    log.info(`filtered`, filtered);

    return filtered;
  }

  async function reversalPayToVendorP({ accountType, bankAccountId, transactionId, reason, deleteTransaction = false }) {
    const transactionToReverse = sbTransactionService.getById(transactionId);

    const reversalPayment = {
      accountType: accountType,
      bankAccountId,
      reason,
      newTransactionId: uuid(),
      transactionIdToReverse: transactionId,
      paymentId: transactionToReverse.paymentId,
      userId: getUserId(),
      effectiveDate: +moment().format('YYYYMMDD'),
      hideTransactions: deleteTransaction,
    };

    const opdateReversalTransaction = buildReversalTransactionOpdates({
      originalTransaction: { transactionId, reason, deleteTransaction },
      reversalTransactionId: reversalPayment.newTransactionId,
    });

    await sbGenericEndpointService.postPayloadP(`${vendorPaymentEndpoint}/reversal`, `/transaction/${reversalPayment.transactionIdToReverse}`, reversalPayment, 'POST', { changeset: { sbTransactionService: opdateReversalTransaction } })
    log.info('reversal completed vendor payment', reversalPayment);
  }
});
