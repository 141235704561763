import React from 'react';
import { FormLabel, Typeahead, IField, useTranslation } from '@sb-itops/react';
import classnames from 'classnames';
import { states } from '@sb-itops/region';
import { auPostalBoxTypesList } from '@sb-customer-management/business-logic/contacts/entities/constants';
import Styles from './PostalAddressForm.module.scss';

// AU component only
const stateOptions = states.AU;

type TProps = {
  formDisabled?: boolean;
  formInitialised?: boolean;
  careOf?: IField<string>;
  boxNumber?: IField<string>;
  boxType?: IField<string>;
  city?: IField<string>;
  state?: IField<string>;
  zipCode?: IField<string>;
  onFieldValueUpdated: (update: Record<string, string | number | undefined>) => void;
  submitFailed?: boolean;
  optional?: boolean;
  validateForm: () => void;
};

export const PostalAddressFormAu = ({
  formInitialised,
  careOf,
  boxNumber,
  boxType,
  city,
  state,
  zipCode,
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  optional,
}: TProps) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field, style) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, style) : '';

  return (
    <div className={Styles.postalAddressForm}>
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-xs-4">
            <label>Care of</label>
            <input
              name="careOf"
              type="text"
              className={classnames('form-control', isErrorClassnames(careOf, Styles.formControl))}
              value={careOf?.value || ''}
              onChange={(e) => {
                onFieldValueUpdated({ careOf: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
          <div className="col-xs-4">
            <label>Box Type</label>
            <Typeahead
              selectedOption={
                auPostalBoxTypesList.find(
                  (option) => option.value === boxType?.value || option.label === boxType?.value,
                ) || undefined
              }
              menuPlacement="top"
              options={auPostalBoxTypesList}
              disabled={formDisabled}
              placeholder="Select..."
              className={classnames(Styles.formSelect, isErrorClassnames(boxType, Styles.formSelect))}
              onSelect={(option) => {
                onFieldValueUpdated({ boxType: (option && option.value) || undefined });
                validateForm();
              }}
              stateless
            />
          </div>
          <div className="col-xs-4">
            <label>Box Number</label>
            <input
              name="Box Number"
              type="text"
              pattern="[0-9]+"
              className={classnames('form-control', isErrorClassnames(boxNumber, Styles.formControl))}
              value={boxNumber?.value || ''}
              onChange={(e) => {
                onFieldValueUpdated({ boxNumber: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <FormLabel
              label={t('suburb')}
              field={city}
              submitFailed={submitFailed}
              optional={optional}
              uppercase={false}
            />
            <input
              name="city"
              type="text"
              className={classnames('form-control', isErrorClassnames(city, Styles.formControl))}
              value={city?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onFieldValueUpdated({ city: e.target.value });
              }}
            />
          </div>
          <div className="col-xs-3">
            <FormLabel
              label={t('capitalize', { val: 'addressState' })}
              field={state}
              submitFailed={submitFailed}
              optional={optional}
              uppercase={false}
            />
            <Typeahead
              selectedOption={
                stateOptions.find((option) => option.value === state?.value || option.label === state?.value) ||
                undefined
              }
              menuPlacement="top"
              options={stateOptions}
              disabled={formDisabled}
              placeholder="Select..."
              className={classnames(Styles.formSelect, isErrorClassnames(state, Styles.formSelect))}
              onSelect={(option) => {
                onFieldValueUpdated({ state: (option && option.value) || undefined });
                validateForm();
              }}
              stateless
            />
          </div>
          <div className="col-xs-3">
            <FormLabel
              label="Postcode"
              field={zipCode}
              submitFailed={submitFailed}
              optional={optional}
              uppercase={false}
            />
            <input
              name="zipCode"
              type="text"
              pattern="[0-9]+"
              className={classnames('form-control', isErrorClassnames(zipCode, Styles.formControl))}
              value={zipCode?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onFieldValueUpdated({ zipCode: e.target.value });
              }}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};
