import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withOnLoad } from '@sb-itops/react/hoc';
import { InvoiceEmailTemplateSettings } from '@sb-billing/react/invoice-email-template-settings';
import {
  getSettings as getInvoiceEmailSettings,
  saveSettings as saveInvoiceEmailSettings,
} from '@sb-billing/redux/invoice-email-settings';
import { getConfig as getDefaultMatterBillingConfig } from '@sb-billing/redux/default-matter-billing-configuration';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { featureActive } from '@sb-itops/feature';
import { getInvoiceEmailMacros } from '@sb-billing/business-logic/invoice-emailing';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { invoiceEmailTemplateSettingsSchema } from './invoice-email-template-settings-schema';

const log = getLogger('InvoiceEmailTemplateSettingsContainer');
const scope = 'invoice-email-template-settings';

const mapStateToProps = (state, { t }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formDirty } = formSelectors.getFormState(state);
  const evergreenRetainerEnabled = (getDefaultMatterBillingConfig() || {}).minimumTrustRetainerActive;
  const dodFeatureEnabled = featureActive('BB-5725') && featureActive('BB-6865');
  const allowInvoiceEmailAttachment = hasFacet(facets.allowEmailAttachment) && featureActive('BB-5725');

  const invoiceEmailMacros = {
    subjectMacros: getInvoiceEmailMacros({
      t,
      evergreenRetainerEnabled,
      isForEmailSubject: true,
      dodFeatureEnabled,
    }),
    bodyMacros: getInvoiceEmailMacros({
      t,
      evergreenRetainerEnabled,
      isForEmailSubject: false,
      dodFeatureEnabled,
    }),
  };

  if (!formInitialised) {
    return { isLoading: true, invoiceEmailMacros };
  }

  const { emailSubject, emailBody, sendCopyToUser, doNotSendInvoiceAttachment, isSaving } = formFields;

  return {
    invoiceEmailMacros,
    emailSubject,
    emailBody,
    sendCopyToUser,
    doNotSendInvoiceAttachment,
    allowInvoiceEmailAttachment,
    isSaving,
    formInitialised,
    formDirty,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const { emailSubject, emailBody, sendCopyToUser, doNotSendInvoiceAttachment } = getInvoiceEmailSettings();
      const fieldValues = {
        emailSubject,
        emailBody,
        sendCopyToUser,
        // existing settings may not have this property - coerce undefined to be false
        doNotSendInvoiceAttachment: !!doNotSendInvoiceAttachment,
        isSaving: false,
        formDirty: false,
      };

      dispatch(formActions.initialiseForm({ fieldValues }));
      dispatch(formOperations.validateSchema({ schema: invoiceEmailTemplateSettingsSchema }));
      const onUnload = () => {
        // clear form on unmount to conform with pattern used else where
        dispatch(formActions.clearForm());
      };
      return onUnload;
    },

    onFormDataUpdated: ({ key, value }) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [key]: value } }));
      dispatch(formOperations.validateSchema({ schema: invoiceEmailTemplateSettingsSchema }));
    },

    onSaveButtonClick: async ({ emailSubject, emailBody, sendCopyToUser, doNotSendInvoiceAttachment }) => {
      try {
        await dispatch(
          formOperations.submitFormP({
            submitFnP: () =>
              dispatch(
                saveInvoiceEmailSettings({
                  emailSubject: emailSubject.value,
                  emailBody: emailBody.value,
                  sendCopyToUser: sendCopyToUser.value,
                  doNotSendInvoiceAttachment: featureActive('BB-5725')
                    ? doNotSendInvoiceAttachment.value && hasFacet(facets.allowEmailAttachment)
                    : undefined,
                }),
              ),
          }),
        );
        displaySuccessToUser('Invoice email template settings saved successfully');
      } catch (err) {
        log.error(err);
        displayErrorToUser('Failed to save invoice email template settings');
      }
    },
  };
};

export const InvoiceEmailTemplateSettingsContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(InvoiceEmailTemplateSettings))),
);

InvoiceEmailTemplateSettingsContainer.displayName = 'InvoiceEmailTemplateSettingsContainer';

InvoiceEmailTemplateSettingsContainer.propTypes = {};

InvoiceEmailTemplateSettingsContainer.defaultProps = {};
