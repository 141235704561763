export const getTotalsForInvoiceId = (state, { invoiceId }) => {
  if (!invoiceId || !state) {
    throw new Error(`invoiceId and state cannot be falsey`);
  }

  const invoiceTotal = state && state[invoiceId];
  if (!invoiceTotal) {
    throw new Error(`something went wrong. Invoice total for ${invoiceId} is not present`);
  }

  return {
    ...invoiceTotal,
    // The original code had this in place to get around invoice totals entities not having
    // interest (as interest was a newer feature than the rest of the entity).
    // This still appears to be required, for invoices that came before interest was a feature.
    interest: (invoiceTotal && invoiceTotal.interest) || 0,
  };
};
