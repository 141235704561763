import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { buildPaymentOpdates } from './opdates/for-invoice-payment-opdates';

export { buildBankAccountBalanceOpdatesForVendorPayment } from './opdates/for-vendor-payment-opdates';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankAccountBalancesService',
  keyPath: 'id',
});

export const buildBankAccountBalanceOpdates = ({ payment, operatingAccount, trustAccount, bankBalanceType }) =>
  buildPaymentOpdates({ payment, operatingAccount, trustAccount, bankBalanceType });
