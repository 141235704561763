'use strict';

const { Cent } = require('@sb-itops/money');
const { roundCents } = require('@sb-billing/bankers-rounding');
const { entryType } = require('../../shared/entities');
const { fuzzyTime } = require('../../shared/services');
const { NoTaxStrategy } = require('../../shared/services/tax/no-tax-strategy');

const getMoney = ({ rate, feeType, duration, interval }) => {
  if (rate) {
    if (feeType === entryType.FIXED) {
      return rate;
    }

    const mins = fuzzyTime.getMinutesFuzzy({ duration, interval });
    const amount = interval
      ? (rate.cents() * fuzzyTime.roundToInterval({ mins, interval })) / 60
      : (rate.cents() * mins) / 60;

    return new Cent(roundCents(amount));
  }

  return new Cent(0);
};

const noTaxStrategy = new NoTaxStrategy();

class Fee {
  /**
   * @param {object} param
   * @param {Money} param.rate used to calculate the principle
   * @param {FeeType} param.feeType
   * @param {number|string} [param.duration]
   *   number: Duration is the number of hours, e.g. 1.5 hours
   *   string: Duration is a string in the format: , e.g. #:# (hours:minutes), #h (hours), #m (minutes), #u (intervals), where # is a number
   * @param {number|string} [param.billableDuration]
   *   defaults to duration for safety, in case we haven't pass this somewhere
   * @param {number} [param.interval] the billable interval in mins
   * @param {object} [param.taxStrategy] an instanceof TaxStrategy
   */
  constructor({ rate, feeType, duration = 0, billableDuration, interval, taxStrategy = noTaxStrategy }) {
    if (rate.cents() < 0) {
      // eslint-disable-next-line no-console
      console.error(`rate cannot be negative: ${JSON.stringify(rate)}`);
    }

    if (![entryType.FIXED, entryType.TIME].includes(feeType)) {
      // eslint-disable-next-line no-console
      console.error(`feeType must be ${entryType.FIXED} or ${entryType.TIME}: ${feeType}`);
    }

    // for grouped fees, amount includes both billable and non-billable items
    const amount = getMoney({ rate, feeType, duration, interval });
    const billableAmount = getMoney({
      rate,
      feeType,
      // defaults to duration if not provided, this makes API backwards compatible
      duration: billableDuration === undefined ? duration : billableDuration,
      interval,
    });

    const { amountExcTax, tax } = taxStrategy.calculate(amount);
    const { amountExcTax: billableAmountExcTax, tax: billableTax } = taxStrategy.calculate(billableAmount);

    // CONSIDER REFACTORING
    // 'amount' and 'billableAmount' can be either inclusive or exclusive of tax depending on
    // the tax strategy passed in, it would be good to return 3 additional parameters to
    // make return values more explicit while maintaining existing interface. At the moment
    // it is unclear to new people reading the calling code whether 'amount' includes tax.
    // 1) amountIncTax
    // 2) billableAmountIncTax
    // 3) amountIncludesTax to indicate whether 'amount' and 'billableAmount' includes tax
    const values = {
      amount,
      amountExcTax,
      tax,
      duration,
      billableAmount,
      billableAmountExcTax,
      billableTax,
      billableDuration,
    };

    Object.defineProperty(this, 'values', {
      configurable: false,
      enumerable: false,
      value: values,
      writable: false,
    });
  }

  /**
   * @returns {Money}
   */
  amountExcTax() {
    return this.values.amountExcTax;
  }

  /**
   * @returns {Money}
   */
  tax() {
    return this.values.tax;
  }

  /**
   * @returns {Money}
   */
  amount() {
    return this.values.amount;
  }

  /**
   * @returns duration (what ever that's passed in through duration field)
   */
  duration() {
    return this.values.duration;
  }

  /**
   * @returns {Money}
   */
  billableAmountExcTax() {
    return this.values.billableAmountExcTax;
  }

  /**
   * @returns {Money}
   */
  billableTax() {
    return this.values.billableTax;
  }

  /**
   * @returns {Money}
   */
  billableAmount() {
    return this.values.billableAmount;
  }

  /**
   * @returns billable duration (what ever that's passed in through billableDuration or duration field)
   */
  billableDuration() {
    return this.values.billableDuration;
  }

  toString() {
    // eslint-disable-next-line no-console
    console.log(`amount: ${this.amount()}, amountExcTax: ${this.amountExcTax()}, tax: ${this.tax()}`);
  }
}

module.exports = { Fee, getMoney };
