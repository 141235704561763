angular.module('sb.billing.webapp').directive('sbUniqueValue', function($parse, sbLoggerService) {
  'use strict';

  const log  = sbLoggerService.getLogger('sbUniqueValue');

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, elem, attr, ngModel) {
      const caseSensitive = !_.isString(attr.sbUniqueValueIgnoreCase);
      let values = $parse(attr.sbUniqueValue)(scope);

      function found(list, searchVal) {
        log.info('list', list, 'searchVal', searchVal);

        const index = _.findIndex(list, function(value) {
          let val1 = value;
          let val2 = searchVal;

          if (_.isString(val1)) {
            val1 = val1.trim();
          }

          if (_.isString(val2)) {
            val2 = val2.trim();
          }

          if (!caseSensitive && _.isString(val1) && _.isString(val2)) {
            return val1.toUpperCase() === val2.toUpperCase();
          } else {
            return val1 === val2;
          }
        });

        return index === -1;
      }

      scope.$watch(attr.sbUniqueValue, function () {
        values = $parse(attr.sbUniqueValue)(scope);
      });

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(currValue) {
        const valid = found(values, currValue);
        log.info(`valid? ${valid}`);
        ngModel.$setValidity('sbUniqueValue', valid);
        return valid ? currValue : undefined;
      });

      //For model -> DOM validation
      ngModel.$formatters.unshift(function(currValue) {
        const valid = found(values, currValue);
        log.info(`valid? ${valid}`);
        ngModel.$setValidity('sbUniqueValue', valid);
        return valid ? currValue : undefined;
      });
    }
  };
});
