'use strict';

/**
 * Applies modifications to the email body ("Message" field) when necessary
 *
 * @param {object} params
 * @param {boolean} params.isConsolidatedMode
 * @param {boolean} params.showPayButtons
 * @param {string} params.emailBody
 * @returns {string}
 */
function modifyEmailBody({ isConsolidatedMode, showPayButtons, emailBody }) {
  if (isConsolidatedMode) {
    // Consolidated mode doesn't support full interpolation and therefore no firm templates
    const consolidatedMessage = `<p>Dear [DEBTOR]</p><br/><p>Your latest invoices are attached. If you have any questions, please contact our office.</p><br/><p>Kind regards</p><p>[USER]</p>`;
    return consolidatedMessage;
  }

  if (!showPayButtons) {
    const emailBodyWithoutPaymentButtons = emailBody
      .replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '') // Remove the placeholder - mirror evergreen retainer handling of Quill paragraphs
      .replace(/(:?<p>)?\[PAY_NOW_LINK\](:?<\/p><p><br \/><\/p>)?/g, '');
    return emailBodyWithoutPaymentButtons;
  }

  return emailBody;
}

module.exports = {
  modifyEmailBody,
};
