'use strict';

/**
 * @typedef { import('../types').Person } Person
 * @typedef { import('../types').FileAttachment } FileAttachment
 */

/**
 * build the Communicate automated message notification
 * @param {Object} params
 * @param {String} params.accountId
 * @param {String} params.fromUserId id of an firm active user who send the Communicate message
 * @param {String} params.body the Communicate mesage body
 * @param {String} params.uploadId pdf is renamed with this uploadId in Communicate bucket
 * @param {String} params.invoiceNumber
 * @param {String} params.invoicePdfSize
 * @param {String} params.matterId
 * @param {String} params.matterTitle
 * @param {String} params.matterDescription
 * @param {String} params.matterNumber
 * @param {String} params.requestId uuid
 * @param {String} params.notificationSubject for logging purposes, should be a unique name for what this message doing
 * @param {String} params.messageType Communciate use this to determine whether they will apply optional flairs, it only supports `BILLING_INVOICE` for now
 * @param {Array<FileAttachment>} params.fileAttachments the list of files which will be attached on the Communicate message
 * @param {Array<Person>} params.debtorRoles
 * @param {String} params.dataEnv
 * @param {String} params.awsRegion
 * @returns {Object}} an object of a notification message for sending message and attachments via Communicate automated message
 */
const buildCommunicateMessageNotification = ({
  accountId,
  fromUserId,
  body,
  linksMap,
  matterId,
  matterTitle,
  matterDescription,
  matterNumber,
  requestId,
  notificationSubject,
  messageType,
  fileAttachments,
  receiverRoles,
  dataEnv,
  awsRegion,
}) => {
  // Get topic arn
  const topicArn = `arn:aws:sns:${awsRegion}:264250787828:${dataEnv}-communicate-commands`;
  // Check if there're any embedded links
  const hasEmbededLinks = Object.keys(linksMap).length > 0;

  return {
    topicArn,
    subject: `${accountId}:${notificationSubject}`,
    message: JSON.stringify({
      communicateCommand: 'AUTOMATED_MESSAGE',
      args: {
        teamId: `smokeball:firm:${accountId}`,
        userIds: [`smokeball:user:${fromUserId}`],
        template: body || undefined,
        type: messageType,
        files: fileAttachments,
        exRefType: 'SMOKEBALL_MATTER',
        exRefId: `smokeball:matter:${matterId}`,
        exRefLabel: matterTitle,
        exRefLabelDetail: matterDescription,
        exRefLabelId: matterNumber,
        links: hasEmbededLinks ? linksMap : null,
        roles: receiverRoles,
      },
      auth: 'billing',
      operationId: requestId,
      // Josh: Communicate has multiple processing environments for each SB env:
      // Communicate processing env <=> SB env
      // - DEV <=> STAGING
      // - STAGING <=> STAGING
      // - QA <=> LIVE
      // - LIVE <=> LIVE
      // We are in the process of moving our DEV env to point to the new SB DEV env.
      // As to why (processingEnvironmentId is needed), I believe the reasoning was
      // because the SB STAGING environment did not have “production-like” data so it
      // was not possible to do realistic QA, so we have a QA env that uses SB LIVE data.
      processingEnvironmentId: dataEnv === 'prod' ? 'LIVE' : 'STAGING',
    }),
  };
};

module.exports = {
  buildCommunicateMessageNotification,
};
