import { t } from '@sb-itops/localisation-web';

angular.module('sb.billing.webapp').component('sbPersonEntry', {
  templateUrl: 'ng-components/person-entry/person-entry.html',
  bindings: {callbackFn: '&', onSaveContact: '&?'},
  controller: function ($timeout, $scope, sbSimpleContactMbService, focusService, sbLoggerService, sbUuidService) {
    'use strict';

    const ctrl = this;
    const numberReg = /^[-+]?\d+$/;

    let showErrors;

    $scope.t = t;
    $scope.$watch('addContactForm', (addContactForm) => {
      if (addContactForm) {
        $scope.$watch('addContactForm.$dirty', (dirty) => {
          showErrors = dirty;
        });
      }
    });

    ctrl.$onInit = () => {
      ctrl.view = {
        personTitleOptions: sbSimpleContactMbService.getContactPersonTitle(),
      };
      ctrl.model = {
        title: _.find(ctrl.view.personTitleOptions, {label: 'Title'}),
      };

      ctrl.errors = {};
    };


    ctrl.$postLink = () =>{
      $timeout(() => { focusService.focusOn('add-contact-person-title');}, 100);
    };

    ctrl.marshal = marshal;
    ctrl.saveContact = saveContact;
    ctrl.focusOn = focusOn;
    ctrl.prepField = prepField;

    const numberInvalid = n => !_.isEmpty(n) && !numberReg.test(n);

    function isValid() {
      return !!ctrl.model.firstName && !!ctrl.model.lastName &&
        !numberInvalid(ctrl.model.phone1)  && !numberInvalid(ctrl.model.phone2) &&
        !numberInvalid(ctrl.model.addressZip);
    }

    function saveContact (){
      if (isValid()) {
        const data = ctrl.marshal();
        ctrl.callbackFn({data});

        if (ctrl.onSaveContact) {
          ctrl.onSaveContact({contactId: data.id});
        }
      } else {
        showErrors = true;
        ctrl.prepField('person-firstName');
        ctrl.prepField('person-lastName');
        ctrl.prepField('person-phone1');
        ctrl.prepField('person-phone2');
        ctrl.prepField('person-addressZip');
      }
    }

    function focusOn (key){
      focusService.focusOn(key);
    }

    function prepField (name) {

      switch (name) {
        case 'person-firstName':
          ctrl.errors.firstName = !ctrl.model.firstName && showErrors;
          break;
        case 'person-lastName':
          ctrl.errors.lastName = !ctrl.model.lastName && showErrors;
          break;
        case 'person-phone1':
          ctrl.errors.phone1 = numberInvalid(ctrl.model.phone1);
          break;
        case 'person-phone2':
          ctrl.errors.phone2 = numberInvalid(ctrl.model.phone2);
          break;
        case 'person-addressZip':
          ctrl.errors.addressZip = numberInvalid(ctrl.model.addressZip);
          break;
      }
    }

    function marshal () {
      return {
        id: sbUuidService.get(),
        person: {
          title: ctrl.model.title.value,
          firstName: ctrl.model.firstName,
          lastName: ctrl.model.lastName,
          email: ctrl.model.email,
          phone: {
            areaCode: `${ctrl.model.phone1 || ''}`,
            localNumber: `${ctrl.model.phone2 || ''}`
          },
          residentialAddress: {
            addressLine1: ctrl.model.addressLine1,
            addressLine2: ctrl.model.addressLine2,
            city: ctrl.model.addressCity,
            zipCode: ctrl.model.addressZip && ctrl.model.addressZip.toString(),
            state: ctrl.model.addressState
          },
        }
      };
    }

  }
});
