import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forms2PropTypes, useTranslation } from '@sb-itops/react';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import { DebugEntity } from 'web/react-redux/components/debug-entity';
import { getPaymentsByMultiPaymentId } from '@sb-billing/redux/payments';
import { hasFacet, facets } from '@sb-itops/region-facets';
import Styles from './CreditNoteDetails.module.scss';
import { PaymentInvoicesTable } from '../payment-invoices-table';

export const CreditNoteDetails = ({
  formValid,
  formSubmitting,
  amount,
  effectiveDate,
  contact,
  processedBy,
  internalNote,
  description,
  reason,
  transactionId,
  isDeleted,
  showDebug,
  showReverse,
  showDelete,
  onProcess,
  onCancel,
  onFieldValueUpdated,
  onClickLink,
  multiPaymentId,
}) => {
  const { t } = useTranslation();
  const [showControls, setShowControls] = useState(false);
  const [isDelete, setIsDelete] = useState(undefined);
  const reasonRef = useRef(null);
  useEffect(() => {
    if (showControls) {
      reasonRef.current.focus();
    }
  });

  const showFieldError = (field) => field?.isDirty && field?.isInvalid;
  const showReverseWarning = hasFacet(facets.transactionReverseWarning);
  const hasMultiplePayments = !!multiPaymentId;
  const linkedPaymentsIds = multiPaymentId ? getPaymentsByMultiPaymentId(multiPaymentId).map((p) => p.paymentId) : [];

  return (
    <div className={classnames(Styles.creditNoteDetails, showDebug ? Styles.debug : '')}>
      {showDebug && (
        <div className={classnames(Styles.item, Styles.debug)}>
          <label>Id</label>
          <div>{transactionId}</div>
        </div>
      )}
      <DebugEntity entityCacheName="sbTransactionService" entityId={transactionId} />
      <div className={classnames(Styles.item, Styles.amount)}>
        <label>Amount</label>
        <div>{t('cents', { val: amount })}</div>
      </div>
      <div className={classnames(Styles.item, Styles.effectiveDate)}>
        <label>Date</label>
        <div>{effectiveDate}</div>
      </div>
      <div className={classnames(Styles.item, Styles.person)}>
        <label>Processed by</label>
        <div>{processedBy}</div>
      </div>
      {contact && (
        <div className={classnames(Styles.item, Styles.contact)}>
          <label>Contact</label>
          <div>{contact}</div>
        </div>
      )}
      <div className={classnames(Styles.item, Styles.internal)}>
        <label>Reason</label>
        <div>{internalNote}</div>
      </div>
      <div className={classnames(Styles.item, Styles.description)}>
        <label>Description</label>
        <div>
          {description && (
            <InterpolatedDescription description={description} deleted={isDeleted} onClickLink={onClickLink} />
          )}
        </div>
      </div>
      <div className={Styles.links}>
        {showReverse && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setShowControls(true);
              setIsDelete(false);
            }}
          >
            Reverse
          </button>
        )}
        {showReverse && showDelete && <span className={Styles.spacer}>|</span>}
        {showDelete && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setShowControls(true);
              setIsDelete(true);
            }}
          >
            Delete
          </button>
        )}
      </div>
      {showControls && (
        <>
          <div className={classnames(Styles.reason, showFieldError(reason) ? 'has-error' : '')}>
            <label>Reason</label>
            <input
              type="text"
              className="form-control"
              ref={reasonRef}
              value={reason?.value}
              onChange={(e) => {
                e.preventDefault();
                onFieldValueUpdated({ reason: e.target.value });
              }}
              disabled={formSubmitting}
            />
            {showReverseWarning && !isDelete && (
              <div className={classnames(Styles.warning)}>
                <i
                  className={classnames(Styles.iconIconFatExclamationCircle, 'icon icon-icon-fat-exclamation-circle')}
                />{' '}
                <span>
                  Reversing a transaction leaves the transaction visible on the ledger. To hide the transaction from the
                  ledger, select delete.
                </span>
              </div>
            )}
            {hasMultiplePayments && (
              <>
                <div className={classnames(Styles.warningRed)}>
                  <span>
                    Warning: this action will {isDelete ? 'delete' : 'reverse'} the following payments made as part of
                    the same transaction.
                  </span>
                </div>
                <div className={classnames(Styles.invoiceTable)}>
                  <PaymentInvoicesTable
                    paymentIds={linkedPaymentsIds}
                    onClickLink={onClickLink}
                    showMatter={false}
                    showSource
                  />
                </div>
              </>
            )}
          </div>
          <div className={Styles.controls}>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                onProcess({ transactionId, isDelete });
              }}
              disabled={!formValid || formSubmitting}
            >
              {isDelete ? 'Delete' : 'Reverse'}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
              disabled={formSubmitting}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

CreditNoteDetails.propTypes = {
  amount: PropTypes.number.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  contact: PropTypes.string,
  processedBy: PropTypes.string.isRequired,
  internalNote: PropTypes.string,
  description: PropTypes.string,
  reason: Forms2Field,
  transactionId: PropTypes.string,
  isDeleted: PropTypes.bool,
  showDebug: PropTypes.bool,
  formValid: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  showReverse: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  multiPaymentId: PropTypes.string,
};

CreditNoteDetails.defaultProps = {
  internalNote: '',
  description: '',
  contact: undefined,
  reason: undefined,
  transactionId: '',
  isDeleted: false,
  showDebug: false,
  formValid: true,
  formSubmitting: false,
  multiPaymentId: undefined,
};

CreditNoteDetails.displayName = 'CreditNoteDetails';
