import { SET_SORT_DIRECTION, SET_MONTH, SET_YEAR, TOGGLE_EXPANDED } from './types';

const initState = {
  expanded: true,
};

export const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.payload.sortDirection,
      };

    case SET_MONTH:
      return {
        ...state,
        month: action.payload.month,
      };

    case SET_YEAR:
      return {
        ...state,
        year: action.payload.year,
      };

    case TOGGLE_EXPANDED:
      return {
        ...state,
        expanded: !state.expanded,
      };

    default:
      return state;
  }
};
