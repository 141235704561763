import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PaginationSelector } from '@sb-itops/react';
import { ContactListFilterPanel } from 'web/react-redux';
import { ContactTable } from 'web/components';
import Styles from './BillingContactsRoute.module.scss';

export const BillingContactsRoute = memo((props) => {
  const {
    contactCount,
    contactListFilterScope,
    contactStatusCounts,
    contactStatusSelections,
    hidePagination,
    selectedCategory,
    showCreateModal,
    onClickLink,
    onContactStatusSelect,

    // Contact Page
    contacts,
    contactDataLoading,
    contactStatusCountsLoading,
    currentContactPage,
    sortBy,
    sortDirection,
    totalNumberOfContactPages,
    onContactListPageChange,
    onSelect,
    onSort,
  } = props;

  return (
    <div className={Styles.contactsPanel}>
      <div className="master-detail-panel">
        <div className={Styles.flexCol}>
          <ContactListFilterPanel
            contactListFilterScope={contactListFilterScope}
            contactStatusCounts={contactStatusCounts}
            contactStatusCountsLoading={contactStatusCountsLoading}
            contactStatusSelections={contactStatusSelections}
            onContactStatusSelect={onContactStatusSelect}
          />
        </div>
        <div className={classnames('panel-body', Styles.contactsPanelBody)}>
          <div className={Styles.contactsListMain}>
            <ContactTable
              contactCount={contactCount}
              contactCountLabel={selectedCategory.name}
              contacts={contacts}
              onClickLink={onClickLink}
              dataLoading={contactDataLoading}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onAddContactButtonClick={showCreateModal}
              onSort={onSort}
              onSelect={onSelect}
            />
            <PaginationSelector
              name="contacts"
              className={Styles.paginationSection}
              hidePagination={hidePagination}
              numberOfPagesDisplayed={10}
              selectedPage={currentContactPage}
              totalNumberOfPages={totalNumberOfContactPages}
              onPageChange={onContactListPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

BillingContactsRoute.displayName = 'BillingContactsRoute';

BillingContactsRoute.propTypes = {
  contactCount: PropTypes.number.isRequired,
  contactListFilterScope: PropTypes.string.isRequired,
  contactStatusCounts: PropTypes.shape({
    current: PropTypes.number,
    deleted: PropTypes.number,
  }).isRequired,
  contactStatusCountsLoading: PropTypes.bool,
  contactStatusSelections: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  contactDataLoading: PropTypes.bool,
  currentContactPage: PropTypes.number.isRequired,
  totalNumberOfContactPages: PropTypes.number.isRequired,
  hidePagination: PropTypes.bool,
  showCreateModal: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
  onSort: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onContactListPageChange: PropTypes.func.isRequired,
  onContactStatusSelect: PropTypes.func.isRequired,
};

BillingContactsRoute.defaultProps = {
  contactDataLoading: true,
  contactStatusCountsLoading: false,
  hidePagination: false,
  onClickLink: () => {},
  onSort: () => {},
};
