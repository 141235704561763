import { connect } from 'react-redux';
import { withReduxStore, withOnLoad, withTranslation } from '@sb-itops/react';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { BANK_BALANCE_TYPE, getBalanceType } from '@sb-billing/redux/bank-account-settings';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import regionAccountOptionsStrategy from '../get-account-options';
import JournalTransfersFilter from './JournalTransfersFilter';

const { periodOption, periodOptions } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);
const includeAllOption = true;

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const mapStateToProps = (state, { filters, t }) => ({
  accountOptions: regionAccountOptionsStrategy.getTrustAccountOptions({ includeAllOption, t }),
  periodOptions,
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters, t }) => ({
  onLoad: () => {
    if (!filters) {
      onFiltersChanged({
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        accountSelected: regionAccountOptionsStrategy.getDefaultAccountSelected({ includeAllOption, t }),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        hidePaidBy: getBalanceType() !== BANK_BALANCE_TYPE.matterContact,
        errors: {},
      });
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onSelectAccount: (option) => onFiltersChanged({ ...filters, accountSelected: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const JournalTransfersFilterContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(JournalTransfersFilter))),
);

JournalTransfersFilterContainer.displayName = `JournalTransfersFilterContainer`;

export default JournalTransfersFilterContainer;
