const buildUnexpectedErrorMsg = (ptxId) => `An unexpected error occurred during transaction processing. Please check your merchant portal for reference #${ptxId}.`;
const buildPaymentsDisabledMsg = (ptxId) => `Payments have been disabled. Please check your merchant portal for reference #${ptxId}.`;

const errorMap = {

  // Smokeball Billing internal errors
  exceeded_max_charge_attempts: (ptxId) => `The transaction status cannot be determined. Please check your merchant portal for reference #${ptxId}.`,
  merchant_account_not_available: buildPaymentsDisabledMsg,
  default: buildUnexpectedErrorMsg,

  // Errors that indicate a system fault
  not_authorized: buildUnexpectedErrorMsg,
  not_authorized_country_denied: buildUnexpectedErrorMsg,
  malformed_request: buildUnexpectedErrorMsg,
  invalid_request: buildUnexpectedErrorMsg,
  no_content: buildUnexpectedErrorMsg,
  invalid_data_encryption: buildUnexpectedErrorMsg,
  resource_not_found: buildUnexpectedErrorMsg,
  unavailable_for_current_status: buildUnexpectedErrorMsg,
  no_account_specified: buildUnexpectedErrorMsg,
  incorrect_payment_type: buildUnexpectedErrorMsg,
  payment_method_expired: buildUnexpectedErrorMsg,
  search_failed: buildUnexpectedErrorMsg,
  server_error: buildUnexpectedErrorMsg,
  version_conflict: buildUnexpectedErrorMsg,
  card_processor_not_available: buildUnexpectedErrorMsg,
  card_processing_error: buildUnexpectedErrorMsg,
  settlement_failed: buildUnexpectedErrorMsg,
  no_card_details_or_token_present: buildUnexpectedErrorMsg,
  not_valid_for_transaction_status: buildUnexpectedErrorMsg,
  unavailable_due_to_capture_in_process: buildUnexpectedErrorMsg,

  // Errors that indicate that payments have been manually disabled
  merchant_not_active: buildPaymentsDisabledMsg,
  unavailable_for_merchant_status: buildPaymentsDisabledMsg,
  account_not_active: buildPaymentsDisabledMsg,
  unavailable_for_merchant_mode: buildPaymentsDisabledMsg,
  unavailable_for_merchant_policy: buildPaymentsDisabledMsg,
  no_payment_method: buildPaymentsDisabledMsg,
  no_account_for_payment_method: buildPaymentsDisabledMsg,

  // Errors that can happen during normal processing
  invalid_data: 'Invalid data',
  card_number_invalid: 'The credit card number supplied is invalid. Please try a different card',
  card_number_incorrect: 'The credit card number supplied is incorrect. Please try a different card',
  card_expired: 'The credit card supplied has expired. Please try a different card',
  card_cvv_incorrect: 'The card details supplied are invalid',
  card_avs_rejected: 'The address details supplied are invalid',
  card_declined: 'The card has been declined. Please try a different card',
  card_declined_processing_error: 'The card has been declined. Please try a different card',
  card_declined_insufficient_funds: 'The credit card has insufficient funds. Please try a different card',
  card_declined_limit_exceeded: 'The credit card supplied has exceeded its limit. Please try a different card',
  card_declined_refer_to_issuer: 'The card has been declined. Please try a different card',
  card_declined_hold: 'The card has been declined. Please try a different card',
  card_declined_no_account: 'The card has been declined. Please try a different card',
  card_type_not_accepted: 'The card type is not accepted. Please try a different card',
  merchant_trans_max_amount_exceeded: 'The transaction amount exceeds the maximum limit',
  merchant_trans_daily_count_exceeded: 'The transaction cannot be processed, daily limit exceeded',
  merchant_trans_daily_amount_exceeded: 'The transaction cannot be processed, daily limit exceeded',
  merchant_trans_monthly_count_exceeded: 'The transaction cannot be processed, monthly limit exceeded',
  merchant_trans_monthly_amount_exceeded: 'The transaction cannot be processed, monthly limit exceeded',

  // Errors we don't expect to see
  exceeds_authorized_amount: (ptxId) => `Exceeds authorized amount. Please check your merchant portal for reference #${ptxId}.`,
  refund_exceeds_transaction: (ptxId) => `Refund amount exceeds original amount. Please check your merchant portal for reference #${ptxId}.`,
  currency_mismatch: (ptxId) => `Currency not supported. Please check your merchant portal for reference #${ptxId}.`,
  unsupported_currency: (ptxId) => `Currency not supported. Please check your merchant portal for reference #${ptxId}.`,
};

module.exports = function getErrorMessage(failureCode, ptxId) {
  let subCode;
  if (_.includes(failureCode, ':')) {
    [ failureCode, subCode ] = failureCode.split(':');
  }

  let errorMsg = errorMap[failureCode];

  if (_.isEmpty(errorMsg)) {
    errorMsg = errorMap['default'];
  }

  if (_.isFunction(errorMsg)) {
    errorMsg = errorMsg(ptxId);
  }

  // NB - this means errMap messages will only be used if subCode is not available
  // but the only failure code that will include a subCode is 'invalid_data'
  return _.isEmpty(subCode) ? errorMsg : subCode;
};



// =================================
// List of errors returned by Lawpay
// =================================
//
//
// not_authorized
// ---------------------------------------------
// A not_authorized error is returned if an API is invoked with incorrect or missing 
// credentials, or with credentials that do not permit access to the requested operation 
// or resource.

// not_authorized_country_denied
// ---------------------------------------------
// A not_authorized_country_denied error indicates an operation was attempted from an IP 
// in a country not allowed by the policy in effect. This error is typically recorded on 
// failed transactions, and is not returned directly in an API response for security 
// reasons, to avoid providing additional context to a potential attacker.

// malformed_request
// ---------------------------------------------
// A malformed_request error is returned if an API is invoked with request content that 
// cannot be parsed as a JSON request on the server.

// invalid_request
// ---------------------------------------------
// The Gateway returns an invalid_request error if the request cannot be processed due to 
// factors unrelated to the request's JSON content. Typical scenarios in which this error 
// is returned include requesting an unsupported HTTP method for a REST URL (such as a POST 
// to a URL that only supports GET) and using an unsupported HTTP Content-Type for a 
// REST URL (such as "application/x-www-form-urlencoded" rather than "application/json").

// no_content
// ---------------------------------------------
// A no_content error is returned if an API is invoked that requires JSON request content 
// to be provided (for example, a charge request), but no content was sent in the request.

// invalid_data
// ---------------------------------------------
// The Gateway returns invalid_data if the server is not able to parse the JSON request, 
// the request is missing required keys, or the values of keys is invalid. If the failure 
// is due to a specified key in the request, the message will indicate the problematic 
// field in the context key. Nested properties are indicated using a '.'.

// invalid_data_encryption
// ---------------------------------------------
// An invalid_data_encryption error is returned if an API is invoked that contains encrypted 
// content but decryption could not be performed by the Gateway.

// resource_not_found
// ---------------------------------------------
// A resource, such as a charge or merchant account, was specified in the request, but not 
// found. If available, the type and identity of the resource is included in the context 
// key, in the format "resource-type" [ "identity" ].

// unavailable_for_current_status
// ---------------------------------------------
// An operation was performed on a REST entity that could not be completed due to the 
// entity's current status.

// no_account_specified
// ---------------------------------------------
// No merchant account was specified for a request that requires an account.

// merchant_not_active
// ---------------------------------------------
// An operation was attempted as a merchant, but the merchant has been suspended or made 
// inactive.

// unavailable_for_merchant_status
// ---------------------------------------------
// An operation on a merchant was prohibited due to the status of the merchant.

// account_not_active
// ---------------------------------------------
// An operation was performed on a merchant account for which the status was not ACTIVE.

// unavailable_for_merchant_mode
// ---------------------------------------------
// An operation was attempted in a merchant mode (LIVE or TEST, depending on the credentials 
// provided) that was not permitted on the target resource. For example, attempting to 
// perform a charge in LIVE mode against an account of type TEST.

// unavailable_for_merchant_policy
// ---------------------------------------------
// An operation was attempted by a merchant that is prohibited by the merchant's policy; for 
// example, an attempt to perform a credit when credit operations are not enabled for the 
// target account.

// no_payment_method
// ---------------------------------------------
// The Gateway returns a no_payment_method error code if a payment API is invoked without 
// providing any payment details in the request content.

// no_account_for_payment_method
// ---------------------------------------------
// If a payment API is invoked providing a payment method (a bank or card) for which no 
// supporting account is configured on the merchant, a no_account_for_payment_method error 
// is returned. For example, a bank charge performed on a merchant with no ACH account 
// configured will raise this error.

// incorrect_payment_type
// ---------------------------------------------
// A transaction was performed that supplied payment details that were not applicable for 
// the underlying account.

// payment_method_expired
// ---------------------------------------------
// A payment operation was attempted with a payment object that has expired. This typically 
// occurs if a saved card object is used after it has been marked expired due to the card 
// expiration.

// search_failed
// ---------------------------------------------
// The Gateway was unable to execute a search request using the parameters provided.

// server_error
// ---------------------------------------------
// The Gateway encountered an unexpected error processing the request.

// version_conflict
// ---------------------------------------------
// An attempt was made to update a resource, but another party modified the same resource 
// prior to commit, moving the version ahead.

// card_number_invalid
// ---------------------------------------------
// A card_number_invalid error is returned if a card number is not valid, either due to the 
// Luhn checksum of the number's digits not matching, or as a result of a payment processor 
// indicating invalid status.

// card_number_incorrect
// ---------------------------------------------
// A card_number_incorrect error is returned if the caller provides both a card number and 
// card type (VISA, MASTERCARD, etc), but the card number is not valid for the specified 
// type.

// card_expired
// ---------------------------------------------
// The Gateway returns a card_expired error if card details are provided corresponding to 
// an expired card.

// card_cvv_incorrect
// ---------------------------------------------
// If the policy applied to a merchant account requires the provided card CVV to match, but 
// the payment processor indicates no match was present, the Gateway returns a 
// card_cvv_incorrect and rejects the request.

// card_avs_rejected
// ---------------------------------------------
// If the policy applied to a merchant account requires the provided card address and/or 
// postal code to match, but the payment processor indicates no match was present, the 
// Gateway returns a card_avs_rejected and rejects the request.

// no_card_details_or_token_present
// ---------------------------------------------
// If neither card details nor a valid payment token are provided on a charge or credit 
// request, the Gateway returns a no_card_details_or_token_present error and rejects the 
// request.


// not_valid_for_transaction_status
// ---------------------------------------------
// The Gateway returns a not_valid_for_transaction_status error code if the current state 
// of a transaction does not permit a given request to be performed. For example, a charge 
// cannot be voided once it is in a COMPLETED state.

// unavailable_due_to_capture_in_process
// ---------------------------------------------
// A unavailable_due_to_capture_in_process error is returned if an attempt is made to 
// capture a transaction while other transactions associated with the same merchant 
// account are already in the process of being captured.


// exceeds_authorized_amount
// ---------------------------------------------
// The Gateway returns a exceeds_authorized_amount error code if a capture request is made 
// specifying an amount greater than the amount that was originally authorized.

// refund_exceeds_transaction
// ---------------------------------------------
// If a refund or credit is applied to a charge such that the amount to be refunded exceeds 
// the remaining amount of the charge (having deducted all previous refunds and credits), 
// the Gateway returns a refund_exceeds_transaction error code and rejects the request.

// currency_mismatch
// ---------------------------------------------
// The Gateway returns a currency_mismatch error if the currency specified on a credit or 
// refund (if present) does not match the currency of the target charge.

// unsupported_currency
// ---------------------------------------------
// If a merchant account cannot accept the currency designated by the currency code given on 
// an authorize or charge request, the Gateway returns a unsupported_currency error code 
// and rejects the request.

// card_declined
// ---------------------------------------------
// The Gateway returns a card_declined error when a card number is provided that matches a 
// card in the Gateway's blacklist, or the payment processor returns a decline response.

// card_declined_processing_error
// ---------------------------------------------
// The Gateway returns a card_declined_processing_error error if the payment processor 
// indicates a card has been declined due to a processing error.

// card_declined_insufficient_funds
// ---------------------------------------------
// Attempts to create a charge using a card with insufficient funds available results in a 
// card_declined_insufficient_funds being returned by the Gateway.

// card_declined_limit_exceeded
// ---------------------------------------------
// If a payment processor indicates a card has reached a spending limit, the Gateway returns 
// a card_declined_limit_exceeded error code to the caller and rejects the request.

// card_declined_refer_to_issuer
// ---------------------------------------------
// If a payment processor indicates a card was declined with a referral to the issuer, the 
// Gateway returns a card_declined_refer_to_issuer error.

// card_declined_hold
// ---------------------------------------------
// A payment processor that responds to an authorize or charge request with a "Hold" 
// response (meaning the card has been reported stolen or fraudulently used, and should 
// be physically held and reported to the issuer) will cause the Gateway to return a 
// card_declined_hold error to the caller, allowing the caller to carry out the hold request.

// card_declined_no_account
// ---------------------------------------------
// The Gateway returns a card_declined_no_account error if the payment processor does not 
// find any account associated with a card number submitted in an authorize or charge 
// request.

// card_type_not_accepted
// ---------------------------------------------
// The Gateway returns a card_type_not_accepted error if an unsupported card type 
// (VISA, MASTERCARD, etc) is used in a charge or credit operation. The supported card 
// types are based on the merchant account configuration with the issuing bank.

// merchant_trans_max_amount_exceeded
// ---------------------------------------------
// If the maximum single-transaction amount defined on a merchant account would be exceeded 
// by an authorize or charge request to that account, the Gateway rejects the request with 
// a merchant_trans_max_amount_exceeded error.

// merchant_trans_daily_count_exceeded
// ---------------------------------------------
// If the daily maximum number of transactions defined on a merchant account would be 
// exceeded by an authorize or charge request to that account, the Gateway rejects the 
// request with a merchant_trans_daily_count_exceeded error.

// merchant_trans_daily_amount_exceeded
// ---------------------------------------------
// An authorize or charge request for which the amount, when combined with the total value 
// of all transactions on the same account over the previous 24 hours, exceeds the maximum 
// daily amount defined on the merchant account, is rejected with a 
// merchant_trans_daily_amount_exceeded error.

// merchant_trans_monthly_count_exceeded
// ---------------------------------------------
// If the monthly maximum number of transactions defined on a merchant account would be 
// exceeded by an authorize or charge request to that account, the Gateway rejects the 
// request with a merchant_trans_monthly_count_exceeded error.

// merchant_trans_monthly_amount_exceeded
// ---------------------------------------------
// An authorize or charge request for which the amount, when combined with the total value 
// of all transactions on the same account over the previous 30 days, exceeds the maximum 
// monthly amount defined on the merchant account, is rejected with a 
// merchant_trans_monthly_amount_exceeded error.

// card_processor_not_available
// ---------------------------------------------
// If the Gateway cannot communicate with the payment processor associated with a merchant 
// account to carry out a request, a card_processor_not_available error is returned. 
// This is typically a transient problem and can be retried at a later time. The ID of 
// the failed transaction is returned in the entity_id property.

// card_processing_error
// ---------------------------------------------
// If a payment processor reports a failure to process a request on behalf of the Gateway, 
// a card_processing_error error is returned to the caller. Unlike failures to communicate 
// with the processor, retrying the operation may not resolve the issue. The ID of 
// the failed transaction is returned in the entity_id property.

// settlement_failed
// ---------------------------------------------
// A transaction that cannot be settled is marked as failed, and its failure code is set to 
// settlement_failed.

