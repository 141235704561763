import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MattersDisplay } from '../matters-display';

const MatterDisplay = memo(
  ({ className, asLink, inline, onClickLink, matterId, showStatus, showStatusPill, tooltip }) => {
    if (!matterId) {
      return null;
    }

    // Regression Checklist whenever MatterDisplay is Changed
    // JIRA: https://smokeball.atlassian.net/browse/BB-5943

    return (
      <MattersDisplay
        matterIds={[matterId]}
        className={className}
        onClickLink={onClickLink}
        inline={inline}
        asLink={asLink}
        showStatus={showStatus}
        showStatusPill={showStatusPill}
        tooltip={tooltip}
      />
    );
  },
);

MatterDisplay.propTypes = {
  asLink: PropTypes.bool,
  onClickLink: PropTypes.func,
  showStatus: PropTypes.bool,
  showStatusPill: PropTypes.bool,
  className: PropTypes.string,
  matterId: PropTypes.string,
  inline: PropTypes.bool,
  tooltip: PropTypes.string,
};

MatterDisplay.defaultProps = {
  className: 'matter-line-summary-by-id',
  matterId: undefined,
  asLink: false,
  onClickLink: () => {},
  showStatus: false,
  showStatusPill: false,
  inline: false,
  tooltip: undefined,
};

export default MatterDisplay;
