import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { fetchById as fetchContactById } from '@sb-customer-management/redux/contacts';

angular.module('sb.billing.webapp').component('sbDataDebtorByMatterId', {
  require:  { compose: '^sbCompose' },
  bindings : { matterId: '<', composeDataKey: '=?' },
  controller: function ($scope, sbContactsMbService, sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDebtorByMatterId');
    let initialized;

    ctrl.$onInit = () => {
      log.info(`init matterId`, ctrl.matterId);
      update();
      initialized = true;
    };

    ctrl.$onChanges = () => {
      if (initialized) {
        update();
      }
    };

    $scope.$on('smokeball-data-update-sbMattersMbService', update);
    $scope.$on('smokeball-data-update-sbBillingConfigurationService', update);

    async function update() {
      log.info(`update debtor for matterId: ${ctrl.matterId}`);

      const debtors = await getDebtorsP();
      if (debtors && debtors.length) {
        ctrl.compose.setComposeData({ dataType: 'contact', data: debtors[0] }, ctrl.composeDataKey || 'debtor');
        ctrl.compose.setComposeData({ dataType: 'contact', data: debtors }, 'debtors');
      }
    }

    function getDefaultMatterDebtorId(matterId) {
      const matter = getMatterById(matterId);
      return matter &&
        matter.clientCustomerIds &&
        matter.clientCustomerIds[0];
    }

    function getDefaultDebtorIds(matterId) {
      const defaultMatterDebtorId = getDefaultMatterDebtorId(matterId);
      const billingConfig = getCurrentConfigurationByMatterId(matterId);

      // defaults to billing config debtorEntityId set before multi-debtor is enabled
      // or if that's not set, default to the first customer id found for the matter
      const defaultDebtorId = billingConfig && billingConfig.debtorEntityId || defaultMatterDebtorId;

      // 1) use debtorIds set in billing config if set set
      // 2) otherwise use default debtor id found above
      const debtorIds = billingConfig && billingConfig.debtorIds && billingConfig.debtorIds.length > 0
        ? billingConfig.debtorIds
        : (defaultDebtorId ? [defaultDebtorId] : undefined);

      return debtorIds;
    }

    function formatPhoneNumber(p) {
      if (!p) {
        return ''; 
      }
      let ext = p.extension ? `${p.extension} ` : '';
      let areaCode = p.areaCode ? `(${p.areaCode}) ` : '';
      let localNumber = p.localNumber ? `${p.localNumber}` : '';
      return `${ext}${areaCode}${localNumber}`;
    }

    function transferPersonData(groupContact, person) {
      groupContact.phone = formatPhoneNumber(_.get(person, 'person.phone'));
      groupContact.phone2 = formatPhoneNumber(_.get(person, 'person.phone2'));
      groupContact.cell = formatPhoneNumber(_.get(person, 'person.cell'));
      groupContact.fax = formatPhoneNumber(_.get(person, 'person.fax'));
      groupContact.email = _.get(person, 'person.email', '');
    }

    async function resolveGroupOfPeopleContact(debtor) {
      if (debtor && debtor.type === 'GroupOfPeople') {
        let personId = _.first(debtor.personIds && debtor.personIds.split(';'));
        let person = await fetchContactById(personId);
        transferPersonData(debtor, person);
      }
    }

    async function getDebtorsP() {
      const debtorIds = getDefaultDebtorIds(ctrl.matterId);
      // Avoid a console warning when we are iterating through the undefined value
      if (!debtorIds) {
        return null;
      }

      const debtors = await Promise.all(debtorIds && debtorIds.map(async (debtorId) => {
        const contact = sbContactsMbService.getById(debtorId);
        // We do not overwrite the entity in the redux store
        const debtor = { ...contact };
        if (debtor.entityId) {
          await resolveGroupOfPeopleContact(debtor);
          debtor.displayName = getContactDisplay(debtor.entityId);
          debtor.tooltip = debtor.displayName + 
            (debtor.email ? ' | ' + debtor.email : '') + ' ' +
            (debtor.cell && debtor.cell.trim() || debtor.phone && debtor.phone.trim() || debtor.phone2 && debtor.phone2.trim() || '');
        }
        return debtor;
      }));
      return debtors;
    }
  }
});

