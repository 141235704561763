
'use strict';

angular.module('sb.billing.webapp').component('sbLogoutAll', {
  templateUrl: 'ng-components/logout-all/logout-all.html',
  controller: function ($state) {
    const ctrl = this;

    ctrl.logoutAll = logoutAll;

    function logoutAll () {
      $state.go('logout', { allDevices: true });
    }
  }
});
