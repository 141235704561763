import React from 'react';
import PropTypes from 'prop-types';
import { findPaymentEvents } from '@sb-billing/redux/payment-plan-events/selectors';
import PaymentPlanPaymentsTable from './PaymentPlanPaymentsTable';

const PaymentPlanPaymentsTableContainer = ({
  paymentPlanId,
  paymentPlanEvents,
  onPaymentsSortDirectionChange,
  sortDirection,
}) => (
  <PaymentPlanPaymentsTable
    payments={findPaymentEvents(paymentPlanEvents, {
      paymentPlanId,
    })}
    onPaymentsSortDirectionChange={onPaymentsSortDirectionChange}
    sortDirection={sortDirection}
  />
);

PaymentPlanPaymentsTableContainer.propTypes = {
  paymentPlanId: PropTypes.string.isRequired,
  onPaymentsSortDirectionChange: PropTypes.func.isRequired,
  paymentPlanEvents: PropTypes.array.isRequired,
  sortDirection: PropTypes.string,
};

PaymentPlanPaymentsTableContainer.defaultProps = {
  sortDirection: undefined,
};

export default PaymentPlanPaymentsTableContainer;
