'use strict';

/**
 * @typedef { import('../entities/types.js').AuthenticationChallenge } AuthenticationChallenge
 * @typedef { import('../entities/types.js').SbBillingAuthProviderChallengeAnswer } SbBillingAuthProviderChallengeAnswer
 */

class SbBillingAuthProviderChallengeAnswerFactory {
  /**
   * fromChallengeAndSolution
   *
   * Creates a {@link SbBillingAuthProviderChallengeAnswer} object from a {@link SbBillingAuthProviderChallenge} and a client response to
   * the issued challenge.
   * @param {AuthenticationChallenge} challenge An authentication challenge issued via the SbBillingAuthProvider.
   * @param {Object} solution The caller's solution to the authenticationChallenge.
   * @return {SbBillingAuthProviderChallengeAnswer} A valid SbBillingAuthProviderChallengeAnswer object.
   */
  static fromChallengeAndSolution(challenge, solution) {
    switch (challenge.challengeType) {
      case 'SOFTWARE_TOKEN_MFA':
        return fromSoftwareTokenMfaChallenge(challenge, solution);

      case 'NEW_PASSWORD_REQUIRED':
        return fromNewPasswordRequiredChallenge(challenge, solution);

      default:
        throw new Error(`Unsupported challenge type: ${challenge.challengeType}`);
    }
  }
}

const fromSoftwareTokenMfaChallenge = (challenge, { authenticatorToken }) => {
  const {
    challengeType,
    userId,
    custom: { session },
  } = challenge;
  return {
    challengeType,
    userId,
    session,
    authenticatorToken,
  };
};

const fromNewPasswordRequiredChallenge = (challenge, { newPassword }) => {
  const {
    challengeType,
    userId,
    custom: { session },
  } = challenge;
  return {
    challengeType,
    userId,
    session,
    newPassword,
  };
};

module.exports = {
  SbBillingAuthProviderChallengeAnswerFactory,
};
