angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('home.billing.transfer-receipt', {
      url: '/transfer-receipt/:paymentId',
      template: `<sb-transfer-receipt-view style="width:100%"></sb-transfer-receipt-view>`,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'transfer', 'receipt'],
        tab: { type: 'transfer-receipt' }
      }
    });

});
