import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { forms2PropTypes } from '@sb-itops/react';

import Styles from '../../AdjustmentForm.module.scss';

export const AdjustmentReason = ({ reason, hasError, onReasonChange }) => (
  <div className={classNames('col-lg-12', Styles.adjustmentField)}>
    <label>REASON</label>
    <textarea
      className={classNames('form-control', hasError && Styles.hasError)}
      value={reason ? reason.value : ''}
      maxLength={1000}
      onChange={onReasonChange}
      rows={5}
    />
  </div>
);

const { Forms2Field } = forms2PropTypes;

AdjustmentReason.displayName = 'AdjustmentReason';

AdjustmentReason.propTypes = {
  reason: Forms2Field,
  onReasonChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

AdjustmentReason.defaultProps = {
  reason: undefined,
};
