import React from 'react';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery } from 'web/hooks';
import { featureActive, featureData } from '@sb-itops/feature';
import { owningCompanyBrandNames } from '@sb-itops/business-logic/white-label';
import { getUserId, getOwningCompany } from 'web/services/user-session-management';
import { UserTwoFactorStatus, XeroAccessToken } from 'web/graphql/queries';
import { getMfaSetupUrl } from '@sb-itops/environment-config';
import { AccountSecurity } from './AccountSecurity';

const hooks = () => ({
  useXeroAccessToken: () => {
    const { data: xeroAccessTokenData, loading: xeroAccessTokenLoading } = useSubscribedQuery(XeroAccessToken, {
      skip: !featureActive('BB-6222'), // skip is Xero integration is not supported
    });

    const { id: accountId } = xeroAccessTokenData?.xeroAccessToken || {};

    return {
      xeroIsConnected: !!accountId, // We don't really care about accountId but it will exist only when Xero is connected
      xeroAccessTokenLoading,
    };
  },
  useUserTwoFactorStatus: () => {
    const { data: userTwoFactorStatusData, loading: userTwoFactorStatusLoading } = useSubscribedQuery(
      UserTwoFactorStatus,
      {
        variables: {
          id: getUserId(),
        },
      },
    );

    const { twoFactorEnabled } = userTwoFactorStatusData?.userTwoFactorStatus || {};

    return {
      userHasTwoFactorEnabled: !!twoFactorEnabled,
      userTwoFactorStatusLoading,
    };
  },
  useBrandName: () => {
    const brandName = owningCompanyBrandNames[getOwningCompany()] || owningCompanyBrandNames.Smokeball;

    return {
      brandName,
    };
  },
});

export const AccountSecurityContainer = withApolloClient(
  composeHooks(hooks)((props) => {
    const newProps = {
      brandName: props.brandName,
      loading: props.xeroAccessTokenLoading || props.userTwoFactorStatusLoading,
      userHasTwoFactorEnabled: props.userHasTwoFactorEnabled,
      buttonDisabled: false,
      buttonHoverText: undefined,
      moreInfoLink: featureData('BB-13182-LINK')?.url,
      onOpenSbSecurityUrl: () => {
        const mfaSetupUrl = getMfaSetupUrl();
        const mfaSetupUrlWithBrandName = `${mfaSetupUrl}${props.brandName}`;
        window.open(mfaSetupUrlWithBrandName, '_blank', 'noopener,noreferrer');
      },
    };

    if (props.xeroIsConnected && props.userHasTwoFactorEnabled) {
      newProps.buttonDisabled = true;
      newProps.buttonHoverText = '2FA is required by Xero and so cannot be disabled on your account';
    }

    return <AccountSecurity {...newProps} />;
  }),
);

AccountSecurityContainer.displayName = 'AccountSecurityContainer';
