'use strict';

const { Cent } = require('@sb-itops/money');
const { services } = require('../../shared');
const { Fee } = require('../entities');
const { calculateFeeBillableDuration } = require('./calculate-fee-billable-duration');

const { createTaxStrategy } = services.tax;

const calculateFeeAmountTax = ({ fee, taxRate, region }) => {
  if (!region) {
    throw new Error('Region is required');
  }

  // `invoice` below refers to the load on demand invoice type, not the legacy redux invoice entity
  const { amountIncludesTax, isTaxExempt, rate, duration, feeType, isBillable, waived, invoiceId, invoice } = fee;
  const billableDuration = calculateFeeBillableDuration({ fee });

  const taxStrategy = createTaxStrategy({
    taxRate,
    isTaxInclusive: amountIncludesTax,
    isTaxExempt,
    region,
  });

  const entry = new Fee({
    rate: new Cent(rate || 0),
    feeType,
    duration: duration / 60,
    billableDuration: billableDuration && billableDuration / 60,
    taxStrategy,
  });

  // we want to use the tax on the fee if already specified as it may have been calculated at a different rate to the current tax rate
  const tax = Number.isFinite(fee.tax) ? fee.tax : entry.tax().cents();
  // this is indirect as the amountInclTax isnt directly exposed yet
  const amountInclTax = amountIncludesTax ? entry.amount().cents() : entry.amount().cents() + tax;
  // this cant be taken from fee.amountExcTax() as it doesnt yet respect the pre-existing tax value
  const amountExclTax = amountInclTax - tax;

  // the same tax handling used above has been used to calculate billable amount fields, note the reason for this
  // is because we want to use the billable tax amount calculated at the time the fee is created/updated
  let billableTax = 0;
  let billableAmountInclTax = 0;
  let billableAmountExclTax = 0;
  if (isBillable || isBillable === null) {
    billableTax = Number.isFinite(fee.billableTax) ? fee.billableTax : entry.billableTax().cents();
    billableAmountInclTax = amountIncludesTax
      ? entry.billableAmount().cents()
      : entry.billableAmount().cents() + billableTax;
    billableAmountExclTax = billableAmountInclTax - billableTax;
  }

  return {
    amountExclTax,
    tax,
    amountInclTax,
    billableDuration,
    billableAmountExclTax,
    billableTax,
    billableAmountInclTax,
    // consolidated logic sitting in monorepo/billing/business-logic/fee/services/index.js to this one function
    // as they all need to use the billable amount, which needs to be recalculated each time. The recalculation
    // is required because we don't store the billableAmount in the fee entity. I have suggested storing this value
    // with Tomas but he said it's not that straight forward on the backend (probably due to risky data mirgration
    // that would be required in order to bring old records up in line if the new field is added).
    nonBillableAmountExclTax: amountExclTax - billableAmountExclTax,
    writtenOffAmountExclTax: waived ? billableAmountExclTax : 0,
    writtenOffTax: waived ? billableTax : 0,
    // isBillable === null indicate a grouped fee has both billable and non-billable items
    billedAmountExclTax: (invoiceId || invoice?.id) && (isBillable || isBillable === null) ? billableAmountExclTax : 0,
  };
};

module.exports = {
  calculateFeeAmountTax,
};
