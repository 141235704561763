import moment from 'moment';
import { dateRangeTypes, today as nowDateOnly, integerToDate } from '@sb-itops/date';
import { getAttorneyResponsible, getTypeId as getMatterTypeIdByMatterId } from '@sb-matter-management/redux/matters';
import { billingEventType } from '@sb-billing/business-logic/shared/entities';
import { getRecentInvoicesMap } from '../recent-invoices';
import { getLastPseudoStatusByInvoiceId as getSentStatusByInvoiceId } from '../correspondence-history';
import { getById as getBillingConfigByMatterId } from '../billing-configuration';
import { getById as getMatterCommunicationSettingsById } from '../matter-communication-settings';
import { getByRelatedEntityIdAndEventType } from '../billing-events';

export const getStatusFilter = (status) => (invoiceSummary) => {
  if (!status) {
    return true;
  }
  const { status: state, dueDate } = invoiceSummary.currentVersion;

  return status === 'OVERDUE' ? state === 'FINAL' && integerToDate(dueDate) < nowDateOnly() : state === status;
};

export const getStatusesFilter = (statuses) => {
  const statusLookup = new Set(statuses);

  return (invoiceSummary) => {
    const { status, dueDate } = invoiceSummary.currentVersion;
    if (status === 'FINAL' && integerToDate(dueDate) < nowDateOnly()) {
      return statusLookup.has('OVERDUE');
    }

    return statusLookup.has(status);
  };
};

export const issuedDateFilterTypes = Object.freeze({
  ALL: dateRangeTypes.ALL,
  THIS_MONTH: dateRangeTypes.THIS_MONTH,
  LAST_MONTH: dateRangeTypes.LAST_MONTH,
  BEFORE: dateRangeTypes.BEFORE,
  CUSTOM: dateRangeTypes.CUSTOM,
});

// filter: issuedDateFilterTypes
// startDate: number|string, yyyymmdd
// endDate: number|string, yyyymmdd
export const getIssuedDateFilter = ({ filter, startDate = 0, endDate = Number.MAX_SAFE_INTEGER }) => {
  if (filter === issuedDateFilterTypes.ALL) {
    return () => true;
  }

  // Date has been used to enable testing via mockdate. Do not change unless you have an alternative
  const now = moment(new Date());
  const thisMonth = now.format('YYYYMM');
  const lastMonth = now.add(-1, 'M').format('YYYYMM');

  return (invoiceSummary) => {
    const { issuedDate } = invoiceSummary.currentVersion;

    switch (filter) {
      case issuedDateFilterTypes.THIS_MONTH: {
        return issuedDate ? moment(issuedDate, 'YYYYMMDD').format('YYYYMM') === thisMonth : true;
      }
      case issuedDateFilterTypes.LAST_MONTH: {
        return issuedDate ? moment(issuedDate, 'YYYYMMDD').format('YYYYMM') === lastMonth : true;
      }
      case issuedDateFilterTypes.BEFORE: {
        return +issuedDate <= +endDate;
      }
      case issuedDateFilterTypes.CUSTOM: {
        return +startDate <= +issuedDate && +issuedDate <= +endDate;
      }
      default:
        return true;
    }
  };
};

export const finalizedOnFilterTypes = Object.freeze({
  ALL: dateRangeTypes.ALL,
  TODAY: dateRangeTypes.TODAY,
  THIS_MONTH: dateRangeTypes.THIS_MONTH,
  LAST_MONTH: dateRangeTypes.LAST_MONTH,
  BEFORE: dateRangeTypes.BEFORE,
  CUSTOM: dateRangeTypes.CUSTOM,
});

// filter: finalizedOnFilterTypes
// startDate: number|string, yyyymmdd
// endDate: number|string, yyyymmdd
export const getFinalizedOnFilter = ({ filter, startDate = 0, endDate = Number.MAX_SAFE_INTEGER }) => {
  // If no filter is selected, use the default "ALL" filter. Otherwise non finalized items will be filtered out
  if (filter === finalizedOnFilterTypes.ALL || !filter) {
    return () => true;
  }

  // Date has been used to enable testing via mockdate. Do not change unless you have an alternative
  const now = moment(new Date());
  const thisMonth = now.format('YYYYMM');
  const today = now.format('YYYYMMDD');
  const lastMonth = now.add(-1, 'M').format('YYYYMM');

  return (invoiceSummary) => {
    const { finalizedTimestamp } = invoiceSummary;

    if (!finalizedTimestamp) {
      return false;
    }

    const finalizedOn = moment(finalizedTimestamp).format('YYYYMMDD');

    switch (filter) {
      case finalizedOnFilterTypes.THIS_MONTH: {
        return moment(finalizedOn, 'YYYYMMDD').format('YYYYMM') === thisMonth;
      }
      case finalizedOnFilterTypes.TODAY: {
        return finalizedOn === today;
      }
      case finalizedOnFilterTypes.LAST_MONTH: {
        return moment(finalizedOn, 'YYYYMMDD').format('YYYYMM') === lastMonth;
      }
      case finalizedOnFilterTypes.BEFORE: {
        return +finalizedOn <= +endDate;
      }
      case finalizedOnFilterTypes.CUSTOM: {
        return +startDate <= +finalizedOn && +finalizedOn <= +endDate;
      }
      default:
        return true;
    }
  };
};

export const getSentStatusFilter = (sentStatuses) => {
  const sentStatusLookup = new Set(sentStatuses);
  return (invoiceSummary) => {
    const lastSentStatus = getSentStatusByInvoiceId(invoiceSummary.invoiceId);
    return sentStatusLookup.has(lastSentStatus);
  };
};

export const getSendPreferenceFilter = (sendPreferences) => {
  const sendPreferenceLookup = new Set(sendPreferences);
  return (invoiceSummary) => {
    const matterCommunicationSettings = getMatterCommunicationSettingsById(invoiceSummary.matterId) || {};
    const correspondencePreferences = matterCommunicationSettings.correspondencePreferences || [];
    // Check if any send preference in `sendPreferenceLookup` exists in `correspondencePreferences`
    // worth mentioning that `sendPreferenceLookup` is a set of strings, but `correspondencePreferences` is an array of number, therefore need to convert them to string before comparing
    const hasMatchingPreference = correspondencePreferences.some((preference) =>
      sendPreferenceLookup.has(String(preference)),
    );
    return hasMatchingPreference;
  };
};

export const getBillingEventFilter = (billingEvents) => {
  const billingEventTypes = billingEvents.map((eventType) => billingEventType[eventType]);

  return (invoiceSummary) => {
    if (!billingEvents.length) {
      return true;
    }

    const eventExistsForInvoice = billingEventTypes.some(
      (eventType) => !!getByRelatedEntityIdAndEventType(invoiceSummary.invoiceId, eventType),
    );
    return eventExistsForInvoice;
  };
};

const billingTypes = {
  fixedFee: 'Fixed Fee',
  fixedFeePerAppearance: 'Fixed Fee per Appearance',
  timeBased: 'Time Based',
  contingencyDollars: 'Contingency ($)',
  contingencyPercent: 'Contingency (%)',
  notBillable: 'Not Billable',
};

const mapBillingTypeFilter = {
  [billingTypes.fixedFee]: 'fixed',
  [billingTypes.fixedFeePerAppearance]: 'fixed',
  [billingTypes.timeBased]: 'time',
  [billingTypes.contingencyDollars]: 'contingency',
  [billingTypes.contingencyPercent]: 'contingency',
};

export const getBillingTypeFilter = (billingTypesToInclude) => {
  const billingTypesLookup = new Set(billingTypesToInclude);

  return (invoiceSummary) => {
    const billingConfig = getBillingConfigByMatterId(invoiceSummary.matterId) || {};
    const billingType = billingConfig.currentWorkItem && billingConfig.currentWorkItem.billingType;
    return billingTypesLookup.has(mapBillingTypeFilter[billingType]);
  };
};

export const getBillingFrequencyFilter = (billingFrequencySubTypesToInclude) => {
  const billingFrequencySubTypesLookup = new Set(billingFrequencySubTypesToInclude);

  return (invoiceSummary) => {
    const billingConfig = getBillingConfigByMatterId(invoiceSummary.matterId) || {};
    const billingFrequencySubType =
      billingConfig.currentWorkItem && billingConfig.currentWorkItem.billingFrequencySubType;
    return billingFrequencySubTypesLookup.has(billingFrequencySubType);
  };
};

export const getMatterTypeFilter = (matterTypesToInclude) => {
  const matterTypesLookup = new Set(matterTypesToInclude);

  return (invoiceSummary) => {
    const matterTypeId = getMatterTypeIdByMatterId(invoiceSummary.matterId);
    return matterTypesLookup.has(matterTypeId);
  };
};

export const getRecentInvoicesFilter = () => {
  const recentInvoices = getRecentInvoicesMap();
  return (invoiceSummary) => !!recentInvoices[invoiceSummary.invoiceId];
};

export const getMatterIdsFilter = (matterIds = []) => {
  const mattersToKeep = matterIds.reduce((matterIdsLookup, matterId) => {
    // eslint-disable-next-line no-param-reassign
    matterIdsLookup[matterId] = true;
    return matterIdsLookup;
  }, {});

  return (invoice) => {
    const { matterId, status } = invoice.currentVersion;
    return mattersToKeep[matterId] && status !== 'DELETED';
  };
};

export const getBalanceFilter = (minimumBalance) => (balance) => !minimumBalance || balance >= minimumBalance;

export const getDebtorFilter = (debtorId) => (invoice) => {
  const { status, debtors = [] } = invoice.currentVersion;
  return status !== 'DELETED' && debtors.find((debtor) => debtor.id === debtorId);
};

function isSetsEqual(a, b) {
  return a.size === b.size && [...a].every((value) => b.has(value));
}

export const getDebtorsFilter = (debtorIds) => (invoice) => {
  const debtorIdSetToLookFor = new Set(debtorIds);

  const { status, debtors = [] } = invoice.currentVersion;
  const invoiceDebtorIdSet = new Set(debtors.map((debtor) => debtor.id));
  return status !== 'DELETED' && isSetsEqual(debtorIdSetToLookFor, invoiceDebtorIdSet);
};

export const getNotOverdueFilter = () => (invoice) => {
  const { status, dueDate } = invoice.currentVersion;
  return status === 'FINAL' && integerToDate(dueDate) >= nowDateOnly();
};

export const getOverdueFilter = () => (invoice) => {
  const { status, dueDate } = invoice.currentVersion;
  return status === 'FINAL' && integerToDate(dueDate) < nowDateOnly();
};

export const getInvoiceIdsFilter = (invoiceIds = []) => {
  const invoicesToKeep = invoiceIds.reduce((invoiceIdsLookup, invoiceId) => {
    // eslint-disable-next-line no-param-reassign
    invoiceIdsLookup[invoiceId] = true;
    return invoiceIdsLookup;
  }, {});

  return ({ invoiceId }) => invoicesToKeep[invoiceId];
};

export const getAttorneyResponsibleFilter =
  (attorneyResponsibleIds = []) =>
  ({ matterId }) => {
    if (attorneyResponsibleIds && attorneyResponsibleIds.length > 0) {
      const attorneyResponsibleId = getAttorneyResponsible(matterId);

      if (!attorneyResponsibleIds.includes(attorneyResponsibleId)) {
        return false;
      }
    }
    return true;
  };
