import { gql } from '@apollo/client';

const query = gql`
  query SbClientSdkAllStaffData {
    staffMembers {
      id
      firstName
      middleName
      lastName
      initials
      email
      name
      title
      isCurrentStaff
      isFormerStaff
    }
  }
`;

const notificationIds = ['FirmManagementNotifications'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SbClientSdkAllStaffData = {
  query,
  notificationIds,
};
