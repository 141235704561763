/**
 * get contact status label for contact
 * @param {*} contact contact entity, which should have the 'removed' flag
 * @returns {string} label as either Current or Deleted, default to Current if no removed flag detected
 */
function getContactStatusLabel(contact) {
  if (!contact) {
    return '';
  }
  const statusLabel = contact && contact.removed ? 'Deleted' : 'Current';
  return statusLabel;
}

module.exports = {
  getContactStatusLabel,
};
