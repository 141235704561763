import { store } from '@sb-itops/redux';
import { getOperation, operationClear, OPERATION_STATE } from '@sb-itops/redux/operation';

export const createActionPromise = ({ composeGroup, action }) => {
  if (typeof action !== 'function') {
    throw new Error(`action must be a function`);
  }

  return (...args) => {
    const operationId = action.call(this, ...args);
    let unsubscribe;

    const setLock = (lock) => {
      if (composeGroup) {
        composeGroup.setLock(lock);
      }
    };

    return new Promise((resolve, reject) => {
      unsubscribe = store.subscribe(() => {
        const operation = getOperation(operationId);

        if (!operation) {
          return;
        }

        switch (operation.state) {
          case OPERATION_STATE.started:
            setLock(true);
            break;
          case OPERATION_STATE.succeeded:
            operationClear(operationId);
            setLock(false);
            resolve();
            break;
          case OPERATION_STATE.failed:
            operationClear(operationId);
            setLock(false);
            reject(getOperation(operationId).error);
            break;
          default:
            return undefined;
        }
      });
    }).finally(unsubscribe);
  };
};
