import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './SubscriptionTierSelector.module.scss';

export const SubscriptionTierSelector = ({ selectedTierId, tiers, onTierSelected }) => (
  <div className={Styles.subscriptionTierSelector}>
    {tiers.map((tier) => (
      <div
        key={tier.id}
        className={classnames(Styles.tier, selectedTierId === tier.id && Styles.selected)}
        onClick={() => onTierSelected(tier)}
      >
        {tier.label}
      </div>
    ))}
  </div>
);

SubscriptionTierSelector.displayName = 'SubscriptionTierSelector';

SubscriptionTierSelector.propTypes = {
  selectedTierId: PropTypes.string,
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onTierSelected: PropTypes.func.isRequired,
};

SubscriptionTierSelector.defaultProps = {
  selectedTierId: undefined,
};
