/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */

'use strict';

/**
 * @typedef { import('../../../types/index.js').FeeDetails } FeeDetails
 * @typedef { import('../types.js').EzyCollectProcessingFeeData } EzyCollectProcessingFeeData
 * @typedef { import('../types.js').EzyCollectFormattedSettings } EzyCollectFormattedSettings
 */

/**
 * calculateFeeDetails
 *
 * When using the EzyCollect calculation to pass the EzyCollect fee to the customer,
 * in order to prevent EzyCollect fees being over the agreed value when rounding
 * the tax on the fee, in certain scenarios EzyCollect will reduce their
 * effectiveFeeInCents in their calculations by 1 cent.
 *
 * Without matching their effective fee calculation on our end, we would end up
 * overcharging by 1 cent.
 *
 * @param {Object} params
 * @param {EzyCollectFormattedSettings} params.feeSchedule
 * @param {number} params.desiredAmountInCents The amount for the charge.
 * @param {Object} [params.providerSpecificFields] Contains data related to the current state of the ez collect charge form.
 * @returns {FeeDetails} The calculated fee details object.
 */
const calculateFeeDetails = ({ feeSchedule, desiredAmountInCents, providerSpecificFields = {} }) => {
  const { creditCardBrand } = providerSpecificFields;

  const serviceFee = feeSchedule.serviceFees.find((serviceFeeConfiguration) => serviceFeeConfiguration.feeType === 'SERVICE_FEE');
  const cardFee = feeSchedule.cardFeeConfigurations.find((cardFeeConfiguration) => cardFeeConfiguration.cardType === creditCardBrand);
  const feeTaxPercent = 0.10;

  // If we don't yet know the card type, we can't successfully calculate the ez collect fee for the desired amount.
  // In this case we return no fee and wait for later.
  if (!cardFee) {
    return {
      desiredAmountInCents,
      desiredAmountFeeInCents: 0,
      effectiveAmountInCents: desiredAmountInCents,
      effectiveFeeInCents: 0,
    }
  }

  // Otherwise we calculate the actual fee in effect.
  const fixedFeeCents = Math.round(serviceFee.totalFee * 100);
  const percentageFee = parseFloat((cardFee.totalFee / 100).toFixed(5));

  const desiredAmountFeeInCents = Math.round(desiredAmountInCents * percentageFee + fixedFeeCents);

  const estimatedAmountInCents = Math.round((desiredAmountInCents + fixedFeeCents) / (1 - percentageFee));
  const estimatedFeeInCents = estimatedAmountInCents - desiredAmountInCents;

  // In the below section we match Stripe's logic for fee tax rounding.

  // As the Stripe fee includes tax, we need to remove the tax portion in order
  // to determine if there will be a rounding error in either the base fee or
  // the tax portion of the fee
  const stripeFeePreTax = estimatedFeeInCents / (1 + feeTaxPercent);

  // Use both methods of rounding in order to determine if there is a difference
  const stripeFeeRound = Math.round(stripeFeePreTax);
  const stripeFeeFloor = Math.floor(stripeFeePreTax);
  const stripeFeeRoundTax = Math.round(stripeFeeRound * feeTaxPercent);
  const stripeFeeFloorTax = Math.round(stripeFeeFloor * feeTaxPercent);

  // If there is a difference in the fee tax, use the floor value to prevent overcharging
  const feeTaxChanged = stripeFeeRoundTax !== stripeFeeFloorTax;
  const stripeBaseFee = feeTaxChanged ? stripeFeeFloor : stripeFeeRound;
  const stripeFeeTax = feeTaxChanged ? stripeFeeFloorTax : stripeFeeRoundTax;

  const stripeFeeTotal = stripeBaseFee + stripeFeeTax;

  // Determine the difference from our original estimated amount, which we will
  // use to modify the final effective values
  const differenceFromEstimate = desiredAmountInCents - (estimatedAmountInCents - stripeFeeTotal);

  const effectiveAmountInCents = estimatedAmountInCents + differenceFromEstimate;
  const effectiveFeeInCents = estimatedFeeInCents + differenceFromEstimate;

  return {
    desiredAmountInCents,
    desiredAmountFeeInCents,
    effectiveAmountInCents,
    effectiveFeeInCents,
  };
};

module.exports = {
  calculateFeeDetails,
};
