import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

function onDisableChange(hasDisable, onDisableUpdated = noop) {
  onDisableUpdated(hasDisable);

  return {
    disabledFlag: hasDisable,
  };
}

const WithDisableHandlerEnhancer =
  ({ renderInsideOwnContainer = true }) =>
  (WrappedComponent) => {
    class WithDisableHandlerEnhancerComponent extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          disabledFlag: !!this.props.hasDisableFlag,
        };
      }

      static getDerivedStateFromProps({ hasDisableFlag, onDisableUpdated }, { disabledFlag }) {
        if (!!hasDisableFlag !== disabledFlag) {
          return onDisableChange(hasDisableFlag, onDisableUpdated);
        }
        return null;
      }

      render() {
        const wrapper = (
          <WrappedComponent
            {...this.props}
            className={this.state.disabledFlag ? 'with-disabled' : ''}
            disabled={this.state.disabledFlag}
          />
        );

        // we will have to define how to use css and create a generic css that can be applied everywhere for the case we have a contianer
        return renderInsideOwnContainer ? (
          <fieldset className="hoc-disable-container" disabled={this.state.disabledFlag ? 'disabled' : ''}>
            wrapper
          </fieldset>
        ) : (
          wrapper
        );
      }
    }

    WithDisableHandlerEnhancerComponent.propTypes = {
      hasDisableFlag: PropTypes.bool,
      onDisableUpdated: PropTypes.func,
    };

    // for now I will set all props optional as undefined. The idea is not validating anymore in the component
    // and start using the default props. I wont do it in the components we already have but keep in mind from now
    // if you are working or modifying this component please update the prop types. GP
    WithDisableHandlerEnhancerComponent.defaultProps = {
      hasDisableFlag: false,
      onDisableUpdated: undefined,
    };

    return WithDisableHandlerEnhancerComponent;
  };

export default WithDisableHandlerEnhancer;
