'use strict'

angular.module('sb.billing.webapp').component('sbIntegrationConnectionPanel', {
  templateUrl: 'ng-components/integration-connection-panel/integration-connection-panel.html',
  bindings: {
    integration: '<',
    isConnected: '<',
    logoSrc: '<',
    connectToIntegration: '<',
    integrationUrl: '<',
  },
  transclude: true,
  controller: function() {}
})