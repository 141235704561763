import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes, SlidingToggle, Spinner, StatelessSelect } from '@sb-itops/react';
import Styles from './InvoiceCommunicateForm.module.scss';

export const InvoiceCommunicateForm = React.memo(
  ({
    isLoading,
    isToAddressReadOnly,
    showIncludeDebtor,
    includeDebtor,
    toAddress,
    fromUserId,
    message,
    debtorType,
    debtorDisplayName,
    userOptions,
    showPreviewButton,
    isPreviewMode,
    previewMessage,
    selectedTabId,
    onFieldValueUpdated,
    onPreviewToggled,
  }) => {
    if (isLoading) {
      return (
        <div className={Styles.invoiceCommunicateForm}>
          <Spinner height={500} message="Loading" />
        </div>
      );
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toAddressInputRef = React.useRef();
    const formDisabled = showIncludeDebtor && !includeDebtor?.value;
    const toAddressPlaceHolder =
      debtorType?.value === 'Person'
        ? `Email address for ${debtorDisplayName?.value}`
        : `No email address found for ${debtorType?.value === 'Organisation' ? 'this organisation' : 'these debtors'}`;

    const onChangeMessage = (e) => {
      e.preventDefault();
      onFieldValueUpdated('message', e.target.value);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (toAddress?.isInvalid) {
        toAddressInputRef.current.focus();
      }
    }, [toAddress?.isInvalid, selectedTabId]);

    return (
      <div className={Styles.invoiceCommunicateForm}>
        {showIncludeDebtor && (
          <div className={classnames('form-group', Styles.formGroupInline)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={Styles.sendCopyLabel}
              htmlFor="includeDebtorSlider"
              onClick={(e) => {
                e.preventDefault();
                onFieldValueUpdated('includeDebtor', !includeDebtor?.value);
              }}
            >
              <SlidingToggle id="includeDebtorSlider" scope="includeDebtorSlider" selected={includeDebtor?.value} />
              <div>Send Client Portal message to this debtor</div>
            </label>
          </div>
        )}
        <fieldset disabled={formDisabled}>
          <div className={classnames('form-group', toAddress?.isInvalid && 'has-error')}>
            <label>TO:</label>
            <input
              className="form-control"
              value={toAddress?.value}
              onChange={(e) => onFieldValueUpdated('toAddress', e.target.value)}
              ref={toAddressInputRef}
              placeholder={toAddressPlaceHolder}
              disabled={isToAddressReadOnly?.value}
            />
          </div>
          <div className="form-group">
            <label>FROM:</label>
            <StatelessSelect
              options={userOptions}
              className={fromUserId?.isInvalid ? Styles.hasError : ''}
              selectedOption={fromUserId?.value}
              onChange={(user) => {
                onFieldValueUpdated('fromUserId', user?.value);
              }}
              isClearable
              showDropdown={false}
            />
          </div>

          <div className={classnames('form-group', message?.isInvalid && 'has-error')}>
            <label>MESSAGE:</label>
            {!isPreviewMode && (
              <textarea
                className={classnames('form-control', Styles.textEditor)}
                value={message?.value}
                onChange={onChangeMessage}
                rows={9}
              />
            )}
            {isPreviewMode && (
              <textarea
                className={classnames('form-control', Styles.textEditor)}
                value={previewMessage}
                disabled
                rows={9}
              />
            )}

            {showPreviewButton && (
              <div className={Styles.textEditorButtonPanel} title={isPreviewMode ? `Edit Message` : `Preview Message`}>
                <Button className={Styles.previewButton} type={buttonTypes.secondary} onClick={onPreviewToggled}>
                  {isPreviewMode ? `Edit Message` : `Preview Message`}
                </Button>
              </div>
            )}
          </div>
        </fieldset>
      </div>
    );
  },
);

InvoiceCommunicateForm.displayName = 'InvoiceCommunicateForm';

InvoiceCommunicateForm.propTypes = {
  isLoading: PropTypes.bool,
  isToAddressReadOnly: PropTypes.object,
  debtorDisplayName: PropTypes.object,
  showIncludeDebtor: PropTypes.bool,
  includeDebtor: PropTypes.object,
  toAddress: PropTypes.object,
  fromUserId: PropTypes.object,
  message: PropTypes.object,
  debtorType: PropTypes.object,
  showPreviewButton: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  previewMessage: PropTypes.string,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onFieldValueUpdated: PropTypes.func,
  onPreviewToggled: PropTypes.func,
  selectedTabId: PropTypes.string,
};

InvoiceCommunicateForm.defaultProps = {
  isLoading: false,
  isToAddressReadOnly: undefined,
  debtorDisplayName: undefined,
  showIncludeDebtor: false,
  includeDebtor: undefined,
  toAddress: undefined,
  fromUserId: undefined,
  message: undefined,
  debtorType: undefined,
  showPreviewButton: false,
  isPreviewMode: false,
  previewMessage: '',
  selectedTabId: '',
  userOptions: [],
  onFieldValueUpdated: () => {},
  onPreviewToggled: () => {},
};

export default InvoiceCommunicateForm;
