const moment = require('moment');

const addDays = (date, days) => moment(date).add(days, 'days').toDate();
const subtractDays = (date, days) => addDays(date, -days);
const addWeeks = (date, weeks) => moment(date).add(weeks, 'weeks').toDate();
const subtractWeeks = (date, weeks) => addWeeks(date, -weeks);
const addMonths = (date, months) => moment(date).add(months, 'months').toDate();
const subtractMonths = (date, months) => addMonths(date, -months);
const addYears = (date, years) => moment(date).add(years, 'years').toDate();
const subtractYears = (date, years) => addYears(date, -years);

module.exports = {
  addDays,
  subtractDays,
  addWeeks,
  subtractWeeks,
  addMonths,
  subtractMonths,
  addYears,
  subtractYears,
};
