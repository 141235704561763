import React from 'react';
import { Button, Modal } from '@sb-itops/react';
import Styles from './Modal.module.scss';

export interface IReplaceModalProps {
  onClose: () => void;
  onReplace: () => void;
  onRename: () => void;
  formSubmitting: boolean;
}

export const ReplaceModal = (props: IReplaceModalProps) => (
  <Modal
    className={Styles.modal}
    title="Replace File"
    body={<p>There are already file(s) with the same name in this folder. Would you like to replace them?</p>}
    footer={
      <>
        <Button
          size="full-width"
          onClick={props.onReplace}
          disabled={props.formSubmitting}
          locked={props.formSubmitting}
        >
          Replace
        </Button>
        <Button
          className={Styles.middleButton}
          size="full-width"
          onClick={props.onRename}
          disabled={props.formSubmitting}
          locked={props.formSubmitting}
        >
          Rename
        </Button>
        <Button
          size="full-width"
          onClick={props.onClose}
          disabled={props.formSubmitting}
          locked={props.formSubmitting}
          type="secondary"
        >
          Cancel
        </Button>
      </>
    }
    onModalClose={props.onClose}
  />
);
