import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/correspondence-history';
import {
  CORRESPONDENCE_STATUS,
  sentViaTypes,
  relatedItemType,
} from '@sb-billing/business-logic/correspondence-history';

angular
  .module('@sb-billing/services')
  .service('sbCorrespondenceHistoryService', function(
    sbGenericCacheService,
    sbGenericEndpointService,
    sbEndpointType,
    sbUuidService,
  ) {
    const that = this;
    const correspondenceHistoryEndpoint = `billing/correspondence-history`;
    const operationType = Object.freeze({
      invoice: 1
    });

    const { applyChangesetP } = sbGenericCacheService.setupCache({
      name: 'sbCorrespondenceHistoryService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: correspondenceHistoryEndpoint },
        poll: 60,
        subscriptions: 'notifier.BillingSharedNotifications.CorrespondenceHistoryUpdated'
      },
      updateRedux,
      clearRedux,
    });

    that.saveConsolidatedEmailHistoryP = saveConsolidatedEmailHistoryP;
    that.saveP = saveP;
    that.applyChangesetEmailsByInvoiceIds = applyChangesetEmailsByInvoiceIds;
    that.applyCorrespondenceChangesetByInvoiceSendDetails = applyCorrespondenceChangesetByInvoiceSendDetails;

    // Creates a new correspondence history entity for a consolidated email.
    /**
     * @deprecated moved into sendCorrespondenceHistoryNotification
     */
    function saveConsolidatedEmailHistoryP(consolidatedEmailRequest, invoiceIds = []) {
      const extractCorrespondenceHistoryProps = ({ to, cc, bcc, subject }) => ({ to, cc, bcc, subject });

      const saveCorrespondenceHistoryRequest = {
        from: consolidatedEmailRequest.from,
        requests: [{
          id: consolidatedEmailRequest.correspondenceId,
          relatedIds: invoiceIds,
          relatedItems: createRelatedItems({ invoiceIds }),
          ...extractCorrespondenceHistoryProps(consolidatedEmailRequest),
          operationType: operationType.invoice,
        }],
      };

      return sbGenericEndpointService.postPayloadP(`${correspondenceHistoryEndpoint}/save`, undefined, saveCorrespondenceHistoryRequest);
    }

    // Butchers stuff.
    function saveP(requests) {
      if (requests.length === 0) {
        return Promise.resolve();
      }

      const changes = requests.reduce((acc, request) => {

        const data = {
          id: request.correspondenceId || sbUuidService.get(),
          relatedIds: request.invoiceIds,
          relatedItems: createRelatedItems({ invoiceIds: request.invoiceIds, contactIds: [request.debtorId]}),
          contactIds: [request.debtorId],
          to: request.toAddress,
          cc: request.cc,
          from: request.replyToAddress,
          bcc: request.bcc,
          subject: request.subject,
          errorMessage: request.errorMessage,
          operationType: operationType.invoice,
          sentVia: request.sentVia || sentViaTypes.EMAIL, // default to email
        };

        acc.post.push(data);
        acc.opdate.push({
          ...data,
          from: request.replyToAddress,
          status: request.errorMessage ? CORRESPONDENCE_STATUS.failed : CORRESPONDENCE_STATUS.inProgress
        });

        return acc;
      },
      { opdate: [], post: [] });
    
      const changeset = {
        changeset: {
          sbCorrespondenceHistoryService: changes.opdate,
        },
      };

      return sbGenericEndpointService.postPayloadP(
        `${correspondenceHistoryEndpoint}/save`,
        undefined,
        {
          requests: changes.post,
          from: requests && requests[0] && (requests[0].replyToAddress || requests[0].template.replyToAddress),
        },
        'POST',
        changeset
      );
    }

    async function applyChangesetEmailsByInvoiceIds(invoiceIds, changes, correspondenceIdsByInvoice = {}) {
      const correspondenceIdsByInvoiceResult = { ...correspondenceIdsByInvoice };

      await applyChangesetP(
        invoiceIds.map(invoiceId => {
          correspondenceIdsByInvoiceResult[invoiceId] = sbUuidService.get();
          return {
            ...changes,
            relatedIds: [invoiceId],
            relatedItems: [{ id: invoiceId, type: relatedItemType.INVOICE }],
            id: correspondenceIdsByInvoiceResult[invoiceId],
            lastUpdated: new Date().toISOString()
          };
        })
      );

      return correspondenceIdsByInvoiceResult;
    }

    async function applyCorrespondenceChangesetByInvoiceSendDetails(invoiceSendDetails, changes) {
      await applyChangesetP(
        invoiceSendDetails.map(invoiceSendDetailsItem => {
          return {
            ...changes,
            relatedIds: invoiceSendDetailsItem.invoiceIds,
            relatedItems: createRelatedItems({ invoiceIds: invoiceSendDetailsItem.invoiceIds, contactIds: [invoiceSendDetailsItem.debtorId]}),
            contactIds: [invoiceSendDetailsItem.debtorId],
            id: invoiceSendDetailsItem.correspondenceId,
            sentVia: invoiceSendDetailsItem.sentVia,
            lastUpdated: new Date().toISOString()
          };
        })
      );
    }

    // create array of related item objects for when invoiceIds are assigned as relatedIds
    function createRelatedItems({ invoiceIds = [], contactIds = []}) {
      const relatedItems = [];
      invoiceIds.forEach(invoiceId => relatedItems.push({ id: invoiceId, type: relatedItemType.INVOICE }));
      // relatedItems should include contactIds as well
      contactIds.forEach(contactId => relatedItems.push({ id: contactId, type: relatedItemType.CONTACT }));
      return relatedItems;
    }
  });
