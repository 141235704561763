/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { Checkbox } from '@sb-itops/react/checkbox';
import { useTranslation } from '@sb-itops/react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import { hasFacet, facets } from '@sb-itops/region-facets';

const { balanceCellLocalisedRenderer, timestampLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

const EvergreenRequestTable = (props) => {
  const { t } = useTranslation();
  const { requests, sortBy, sortDirection, summary, sort, onClickRow, selectedRequests, emailRequests } = props;

  return (
    <div className="panel-body">
      <div className="ribbon panel panel-primary">
        <button type="button" className="btn-preview-drafts btn btn-primary" disabled={Object.keys(selectedRequests).length === 0} onClick={emailRequests}>
          {` Email Requests (${Object.keys(selectedRequests).length || 0})`}
        </button>
      </div>
      <Table
        className="evergreen-request-table"
        onRowClick={({ rowData }) => onClickRow(rowData.matter)}
        list={requests}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        summary={summary}
        showFooter
      >
        <Column
          dataKey="UNNEEDED"
          label=""
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(checkboxHeaderCellRenderer(props))}
          cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer(props))}
          disableSort
        />
        <Column dataKey="matter" label="Matter" cellRenderer={matterCellRenderer(props)} flexGrow={3} />
        <Column
          className="right-align unpaid"
          dataKey="unpaid"
          label={hasFacet(facets.interest) ? 'Unpaid (Inc Interest)' : 'Unpaid'}
          cellRenderer={balanceCellLocalisedRenderer}
          cellDataGetter={matterDataGetter}
          flexGrow={1}
          footerRenderer={balanceCellLocalisedRenderer}
        />
        <Column
          className="right-align balance"
          dataKey="minimumThreshold"
          label="Minimum Threshold"
          cellRenderer={minimumThresholdCellRenderer(props)}
          flexGrow={1}
          footerRenderer={balanceCellLocalisedRenderer}
        />
        <Column
          className="right-align balance"
          dataKey="replenishUpTo"
          label="Replenish Up To"
          cellRenderer={balanceCellLocalisedRenderer}
          cellDataGetter={matterDataGetter}
          flexGrow={1}
          footerRenderer={balanceCellLocalisedRenderer}
        />
        <Column
          className="right-align balance"
          dataKey="trustBalance"
          label={`${t('trust')} Balance`}
          cellRenderer={balanceCellLocalisedRenderer}
          cellDataGetter={matterDataGetter}
          flexGrow={1}
          footerRenderer={balanceCellLocalisedRenderer}
        />
        <Column
          className="right-align balance"
          dataKey="replenishAmount"
          label="Replenish Amount"
          cellRenderer={balanceCellLocalisedRenderer}
          cellDataGetter={matterDataGetter}
          flexGrow={1}
          footerRenderer={balanceCellLocalisedRenderer}
        />
        <Column
          className="date"
          dataKey="lastRequestDate"
          label="Last Request Date"
          cellRenderer={lastRequestDateRenderer}
          cellDataGetter={matterDataGetter}
          flexGrow={1}
        />
        <Column
          className="date"
          dataKey="sent"
          label="Sent?"
          width={75}
          cellRenderer={retainerRequestStatusRenderer}
          cellDataGetter={matterDataGetter}
        />
      </Table>
    </div>
  );
}

function checkboxCellRenderer({ onSelectRequest, selectedRequests }) {
  return ({ rowData }) => <Checkbox
      checked={selectedRequests[rowData.matter.matterId]}
      onChange={() => {
        onSelectRequest(rowData);
      }}
      disabled={!rowData.matter.emailRequestEnabled}
    />;
}

function checkboxHeaderCellRenderer({ selectedRequests, requests, onSelectAllRequests }) {
  return () => {
    const selectedCount = Object.keys(selectedRequests).length;

    const allSelected = // all is considered selected when all retainers with emailRequestEnabled set to true is selected
      !!selectedCount && selectedCount === requests.filter((request) => request.matter.emailRequestEnabled).length;

    return <Checkbox checked={allSelected} onChange={onSelectAllRequests} />;
  };
}

function matterCellRenderer({ onClickLink }) {
  return ({ rowData }) => {
    const { matter } = rowData;

    return <MatterDisplay matterId={matter.matterId} onClickLink={onClickLink} asLink />;
  };
}

function minimumThresholdCellRenderer() {
  return ({ rowData }) => {
    const { matter } = rowData;

    return balanceCellLocalisedRenderer({
      cellData: matter.minimumThreshold,
      classNames: matter.isUnderMinimumThreshold ? 'balance-under-minimum-threshold' : undefined,
    });
  };
}

function lastRequestDateRenderer({ cellData }) {
  // lastRequestDate has been localised to browser timezone and stripped of
  // timestamp, don't use timestamp in retainer which is in UTC time.
  const lastRequestDate = cellData;
  if (!lastRequestDate) {
    return '';
  }
  return timestampLocalisedRenderer({ cellData });
}

function onClickRetainerRequestStatusHandler(e) {
  e.stopPropagation();
}

const retainerRequestStatusClasses = Object.freeze({
  NOT_SENT: classNames('icon-mail-1', 'icon '),
  EMAIL_SENT: classNames('icon-mail-checked', 'icon'),
  COMMUNICATE_MESSAGE_SENT: classNames('icon-comm-check', 'icon'),
});

const RetainerRequestStatusIcon = ({ status, tooltip }) => (
  <div className="icon-container" onClick={onClickRetainerRequestStatusHandler}>
    <span className={retainerRequestStatusClasses[status]} title={tooltip} />
  </div>
);

function retainerRequestStatusRenderer({
  cellData,
  rowData: {
    matter: { sentVia },
  },
}) {
  const sent = cellData;
  const statuses = Object.keys(retainerRequestStatusClasses).reduce(
    (acc, status) => {
      acc[status] = status;
      return acc;
    },
    {}
  );

  if (sent) {
    switch (sentVia) {
      case sentViaTypes.EMAIL:
        return (
          <RetainerRequestStatusIcon
            status={statuses.EMAIL_SENT}
            tooltip="Sent via email"
          />
        );
      case sentViaTypes.COMMUNICATE:
        return (
          <RetainerRequestStatusIcon
            status={statuses.COMMUNICATE_MESSAGE_SENT}
            tooltip="Sent via Client Portal"
          />
        );
      default:
        return <span />;
    }
  }

  return <RetainerRequestStatusIcon status={statuses.NOT_SENT} />;
}

function matterDataGetter({ rowData, dataKey }) {
  return rowData.matter[dataKey];
}

EvergreenRequestTable.propTypes = {
  requests: PropTypes.array,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  summary: PropTypes.object,
  sort: PropTypes.func,
  selectedRequests: PropTypes.object,
  onSelectAllRequests: PropTypes.func,
  onSelectRequest: PropTypes.func,
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  emailRequests: PropTypes.func,
};

EvergreenRequestTable.defaultProps = {
  sortBy: 'lastRequestDate',
  sortDirection: 'asc',
  requests: [],
  summary: {},
  selectedRequests: {},
  emailRequests: () => { },
  onSelectAllRequests: () => { },
  onSelectRequest: () => { },
  onClickLink: () => { },
  onClickRow: () => { },
  sort: () => { },
};

export default EvergreenRequestTable;
