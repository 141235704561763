'use strict';

const { sort } = require('@sb-itops/sort');

/**
 * mapActivitiesByCategory
 *
 * Combines custom and utbms activities into a single array
 *
 * During this process, activities can be:
 *  1. Labelled (e.g. custom or utbms)
 *  2. Filtered
 *  3. Sorted
 *  4. Transformed
 *
 * @param {Object} params
 * @param {Array<Object>} props.activityCodes
 * @param {Array<Object>} props.utbsmActivityCodes
 * @param {Array<String>} params.filterCustomCodeByTypes entryTypes = { FIXED: 0, TIME: 1, EXPENSE: 2 }
 *
 * @returns {Array<Object>}
 */
function mapActivitiesByCategory(props) {
  const activityCodes = props?.activityCodes || [];
  const utbmsActivityCodes = props?.utbmsActivityCodes || [];
  const filterCustomCodeByTypes = props?.filterCustomCodeByTypes || [];

  // Custom activities
  const customActivities = activityCodes.length
    ? activityCodes.reduce((acc, activity) => {
        // Skip deleted activities
        if (activity.isDeleted) {
          return acc;
        }

        // Filter activities by entry type
        if (filterCustomCodeByTypes.includes(activity.type)) {
          acc.push({
            ...activity,
            category: 'custom',
          });
        }
        return acc;
      }, [])
    : [];

  const sortedCustomActivities = sort(customActivities, ['code'], ['ASC']);

  // UTBMS activities
  const utbmsActivities = utbmsActivityCodes.length
    ? utbmsActivityCodes.map((activity) => ({
        id: activity.id,
        isBillable: true,
        category: 'utbms',
        code: activity.code,
        description: activity.description,
      }))
    : [];

  return [...sortedCustomActivities, ...utbmsActivities];
}

module.exports = {
  mapActivitiesByCategory,
};
