'use strict';

const { bankAccountTypes } = require('../../../bank-account/entities/constants');

/**
 * Filter out transactions that are not related to controlled money
 *
 * @param {Object} params
 * @param {Array<Transaction>} params.transactions
 * @param {string} [params.bankAccountId] - If specified, only CMA transactions with this bank account id will be returned
 * @return {Array<Transaction>}
 */
function filterControlledMoneyTransactions({ transactions, bankAccountId }) {
  return transactions.filter(
    (tx) =>
      tx.accountType === bankAccountTypes.CONTROLLEDMONEY && (!bankAccountId || tx.bankAccountId === bankAccountId),
  );
}

module.exports = {
  filterControlledMoneyTransactions,
};
