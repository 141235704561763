import { sortByProperty } from '@sb-itops/nodash';
import { cacheFactory, syncTypes, indexTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import moment from 'moment';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { dateToInteger as toYYYYMMDD } from '@sb-itops/date';
import { getFirmName } from '@sb-firm-management/redux/firm-management';
import { getUserId } from 'web/services/user-session-management';
import { getSettings as getBankReconciliationSetup } from '../bank-reconciliation-setup.2';
import { getLatest as getLatestEndOfMonthReport, saveEndOfMonthReport } from '../end-of-month-reports';

import domain from '../domain';

const statusByValue = {
  0: 'InProgress',
  1: 'Completed',
  2: 'Cancelled',
};

const getByMonthYearEndDateBankAccountIndex = (date, status, bankAccountId) =>
  `${moment(date, 'YYYYMMDD').format('YYYYMM')}-${
    status !== statusByValue[2] ? 'NOT-CANCELLED' : 'CANCELLED'
  }-${bankAccountId}`;

const api = cacheFactory({
  domain,
  name: 'bank-reconciliations',
  keyPath: 'id',
  ngCacheName: 'sbBankReconciliationService',
  syncType: syncTypes.SINCE,
  immutable: false,
  indexes: [
    {
      name: 'byMonthYearEndDateBankAccount',
      indexer: (bankRecon) =>
        bankRecon &&
        getByMonthYearEndDateBankAccountIndex(bankRecon.endDate, bankRecon.status, bankRecon.bankAccountId),
      type: indexTypes.ONE_TO_MANY,
    },
  ],
  changesetProcessor: ({ entities }) =>
    entities.map((reconciliation) => ({
      ...reconciliation,
      ...{ status: statusByValue[reconciliation.status] },
    })),
});

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBankReconciliationService',
  keyPath: 'id',
});

export const { getById, getList, getMap, updateCache, clearCache } = api;

export const getByBankAccountId = (bankAccountId) => getList().filter((recon) => recon.bankAccountId === bankAccountId);

export const cancelRecon = async ({ reconciliationId, note = '' }) => {
  if (!reconciliationId) throw new Error('reconciliationId is mandatory');

  const currentRecon = getById(reconciliationId);
  const newRecon = {
    ...currentRecon,
    status: statusByValue[2], // Cancelled
  };
  opdateCache({ optimisticEntities: [newRecon] });

  try {
    const path = `/billing/bank-reconciliation/:accountId/cancel`;
    const fetchOptions = { body: JSON.stringify({ reconciliationId, note }) };
    await fetchPostP({ path, fetchOptions });
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: [newRecon] });
    throw err;
  }
};

export const saveRecon = async ({ data, generateEndOfMonthReport, t }) => {
  const path = `/billing/bank-reconciliation/:accountId/save`;

  const opdateRecon = {
    ...data,
  };
  opdateCache({ optimisticEntities: [opdateRecon] });
  try {
    const fetchOptions = { body: JSON.stringify({ ...data }) };
    await fetchPostP({ path, fetchOptions });

    if (generateEndOfMonthReport) {
      const momentDate = moment(data.endDate, 'YYYYMMDD');
      const latestEndOfMonthReport = getLatestEndOfMonthReport(data.trustAccountId);
      const userId = getUserId();
      const today = new Date();

      await saveEndOfMonthReport({
        date: momentDate.format('YYYYMMDD'),
        createdDate: toYYYYMMDD(today),
        reportPrintingDate: `${t('date', { date: today })} ${moment(today).format('LT')}`,
        bankAccountId: data.trustAccountId,
        lastPageNumbers: latestEndOfMonthReport && latestEndOfMonthReport.lastPageNumbers,
        userId,
        bankReconciliationsIdsCompleted: getReconciliationsForMonth({
          date: momentDate.toDate(),
          trustAccountId: data.trustAccountId,
        }).map((bankRecon) => bankRecon && bankRecon.id),
        firmName: getFirmName(),
      });
    }
  } catch (err) {
    rollbackOpdateCache({ optimisticEntities: [opdateRecon] });
    throw err;
  }
};

const sortByDate = (a, b) => {
  if (a.endDate < b.endDate) {
    return -1;
  }
  if (a.endDate > b.endDate) {
    return 1;
  }
  return 0;
};

export const getLastByStatus = (status, bankAccountId) => {
  const trustAccountId = bankAccountId;
  if (!trustAccountId) {
    throw new Error('trustAccountId must be provided to get bank rec info');
  }
  const completed = getList()
    .filter((recon) => recon.bankAccountId === trustAccountId && recon.status === status)
    .sort(sortByDate);

  return completed[completed.length - 1];
};

export const getLatest = (bankAccountId) => {
  const trustAccountId = bankAccountId;
  if (!trustAccountId) {
    throw new Error('trustAccountId must be provided to get bank rec info');
  }
  const completed = getList()
    .filter((recon) => recon.bankAccountId === trustAccountId && recon.status !== 'Cancelled')
    .sort(sortByDate);

  return completed[completed.length - 1];
};

export const getLastCompleted = (trustAccountId) => getLastByStatus('Completed', trustAccountId);

// this doesn't really belong here, but putting it here is consistent with how we've done it everywhere else
// to check if the specified date is a part of a reconciliation, we also need to check the setup IF there hasnt been a
// completed reconciliation yet
export const isReconciled = ({ yyyymmdd, trustAccountId }) => {
  if (!trustAccountId) {
    throw new Error('trustAccountId must be provided to get bank rec info');
  }
  return (
    isBeforeLastCompletedReconDate({ yyyymmdd, trustAccountId }) ||
    isBeforeReconSetupStartDate({ yyyymmdd, trustAccountId })
  );
};

// recon period includes endDate so <= here
function isBeforeLastCompletedReconDate({ yyyymmdd, trustAccountId }) {
  const lastCompletedReconDate = getLastCompleted(trustAccountId)?.endDate;
  return Number.isFinite(lastCompletedReconDate) && yyyymmdd <= lastCompletedReconDate;
}

// you can create transactions on the same day as the reconciliation start date (hence the '<' and not '<=')
function isBeforeReconSetupStartDate({ yyyymmdd, trustAccountId }) {
  const bankReconSetup = getBankReconciliationSetup(trustAccountId);
  const validBankReconSetup = bankReconSetup && !bankReconSetup.isRemoved;

  const reconSetupStartDate = bankReconSetup?.reconciliationStartDate;
  return validBankReconSetup && Number.isFinite(reconSetupStartDate) && yyyymmdd < reconSetupStartDate;
}

/**
 * will get all the bank reconciliations not cancelled for a given month
 * @param {date} date - the date from which we are going to get the month
 * @returns {Array.<object>} - the bank reconciliation collection for a given month, sorted by endDate asc, or empty if there is nothing
 * @throws if param.date is falsey
 */
export const getReconciliationsForMonth = ({ date, trustAccountId }) => {
  if (date === undefined) {
    throw new Error('date need to be defined');
  }
  if (!trustAccountId) {
    throw new Error('trustAccountId must be provided to get bank rec info');
  }

  const byMonthYearEndDateBankAccount = api.getIndex('byMonthYearEndDateBankAccount');
  const index = getByMonthYearEndDateBankAccountIndex(date, statusByValue[1], trustAccountId);
  const bankRecs = byMonthYearEndDateBankAccount[index] || [];

  return sortByProperty(bankRecs, 'endDate', 'asc');
};

export * from './print-draft-preview';
