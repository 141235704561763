'use strict';

const { bankAccountState } = require('../entities/constants');

/**
 * Determines if a bank account is closed.
 * @param {Object} param
 * @param {Object} param.bankAccount Bank Account entity
 * @returns {boolean}
 */
function isBankAccountClosed({ bankAccount }) {
  return bankAccount.state === bankAccountState.CLOSED;
}

module.exports = {
  isBankAccountClosed,
};
