'use strict';

const moment = require('moment');
const { sort: sortItems } = require('@sb-itops/sort');

const { operationTypes, operationTypeMap } = require('./operation-type');
const { sentViaTypes, externallySentViaMap, sentViaMap } = require('./sent-via-type');
const { isCorrespondenceHistoryRelatedToMatter } = require('./is-correspondence-history-related-to-matter');

/**
 * Build client communication history table data
 * @param {Object} params
 * @param {string} params.matterId required in the context of matter > client communications
 * @param {string[]} params.matterInvoicesIds required in the context of matter > client communications
 * @param {Object[]} params.correspondenceHistoryList
 * @param {boolean} params.showMarkAsSentEntries
 * @param {string} params.sortBy
 * @param {string} params.sortDirection
 * @param {number} params.numberOfDaysToShow show correspondence history for the last x number of days
 * @param {Function} [params.getContactDisplayById] this is not provided when used for CSV/PDF export
 * @param {Function} params.getInvoiceNumberById
 * @param {Function} params.getStaffByUserId
 */
function buildClientCommunicationsHistory({
  matterId,
  matterInvoicesIds,
  correspondenceHistoryList,
  showMarkAsSentEntries,
  sortBy,
  sortDirection,
  numberOfDaysToShow,
  getContactDisplayById,
  getInvoiceNumberById,
  getStaffByUserId,
  t,
}) {
  const operationTypesToBeIncluded = [
    operationTypes.INVOICE,
    operationTypes.INVOICE_REMINDER,
    operationTypes.CREDIT_CARD_DEPOSIT_REQUEST,
    operationTypes.INVOICE_STATEMENT,
  ];
  const numberOfDaysAgo = moment().subtract(numberOfDaysToShow, 'days').startOf('day');

  const correspondenceHistoryData = (correspondenceHistoryList || []).reduce((acc, correspondenceHistory) => {
    // If showMarkAsSentEntries toggle is off, we don't show items manually marked as sent
    if (!showMarkAsSentEntries && !!externallySentViaMap[correspondenceHistory.sentVia]) {
      return acc;
    }

    // Only show last 90 days records in firm level
    const showLast90Days =
      !matterId &&
      !moment(correspondenceHistory.sentTimestamp || correspondenceHistory.lastUpdated).isSameOrAfter(numberOfDaysAgo);
    if (showLast90Days) {
      return acc;
    }

    if (
      matterId &&
      !isCorrespondenceHistoryRelatedToMatter({
        correspondenceHistory,
        invoiceIdsOfTheMatter: matterInvoicesIds,
        matterId,
      })
    ) {
      return acc;
    }

    if (operationTypesToBeIncluded.includes(correspondenceHistory.operationType)) {
      const sentViaLabel = sentViaMap[correspondenceHistory.sentVia || sentViaTypes.EMAIL]; // default to email
      let operationTypeLabel = operationTypeMap[correspondenceHistory.operationType];
      if (correspondenceHistory.operationType === operationTypes.INVOICE_STATEMENT) {
        operationTypeLabel = t('clientStatement');
      }
      const timestamp = correspondenceHistory.sentTimestamp || correspondenceHistory.lastUpdated;

      const item = {
        correspondenceHistoryId: correspondenceHistory.id,
        date: moment(timestamp).format('YYYYMMDD'),
        timestamp,
        contactIds: correspondenceHistory.contactIds || [],
        debtor:
          getContactDisplayById && correspondenceHistory.contactIds && correspondenceHistory.contactIds.length
            ? getContactDisplayById(correspondenceHistory.contactIds[0])
            : null,
        to: correspondenceHistory.to,
        operationTypeLabel,
        relatedIds: correspondenceHistory.relatedIds,
        sentViaLabel,
        sentBy: correspondenceHistory.userId ? getStaffByUserId(correspondenceHistory.userId)?.name : '',
      };

      if (
        correspondenceHistory.operationType === operationTypes.INVOICE ||
        correspondenceHistory.operationType === operationTypes.INVOICE_REMINDER
      ) {
        item.reference = correspondenceHistory.relatedIds
          .map((invoiceId) => `#${getInvoiceNumberById(invoiceId)}`)
          .join(', ');
      }

      acc.push(item);
    }

    return acc;
  }, []);

  const sortedCorrespondenceHistoryData =
    sortBy === 'timestamp'
      ? sortItems(correspondenceHistoryData, ['timestamp'], [sortDirection], false /* case insensitive */)
      : sortItems(
          correspondenceHistoryData,
          [sortBy, 'timestamp'],
          [sortDirection, 'desc'],
          false /* case insensitive */,
        );
  return sortedCorrespondenceHistoryData;
}

module.exports = {
  buildClientCommunicationsHistory,
};
