const isFunction = (obj) => !!(obj && obj.constructor && obj.call && obj.apply);
const isObject = (obj) => typeof obj === 'object' && obj !== null;
const isEmpty = (obj) => Object.keys(obj).length === 0;

function createAccumulator(definitionTree) {
  if (!isObject(definitionTree) || isEmpty(definitionTree)) {
    throw new Error('Accumulator function definition must be an object with at least one property');
  }

  return (acc, dataPoint) => {
    Object.entries(definitionTree).forEach(([name, definition]) => {
      const results = accumulateForNode(acc, dataPoint, { name, definition });
      Object.assign(acc, results);
    });

    return acc;
  };
}

function accumulateForNode(acc, dataPoint, node, context) {
  // The following is used to allow clients to identify an exact problem point
  // in the definition tree if an Error is thrown.
  const newContext = context ? `${context}.${node.name}` : node.name;

  // If the node's name starts with "$", the resulting properties in the accumulated result are
  // derived from the matching property (not including $) in the dataPoint itself.
  const derivedName = node.name.startsWith('$') ? dataPoint[node.name.slice(1)] : node.name;

  // If the node's definition is a function, the node is a leaf.
  // In this case the definition is a 'custom' accumulator function provided by
  // the creator of the definition tree.
  if (isFunction(node.definition)) {
    const customAccumulatorFn = node.definition;
    return { [derivedName]: customAccumulatorFn(dataPoint, acc[derivedName]) };
  }

  // Empty definition objects are meaningless and therefore not allowed.
  if (isObject(node.definition) && isEmpty(node.definition)) {
    throw new Error(`Empty branch objects in the definition are not allowed, empty object found at '${newContext}'`);
  }
  
  // At this point the only remaining valid node definition is an object.
  if (!isObject(node.definition)) {
    throw new Error(`Non-supported branch type provided in definition at ${newContext}. Supported value types are: function, object`);
  }

  // Make the recursive call to accumulateAllocationForNode.
  const childAccumulations = Object.entries(node.definition).reduce((acc = {}, [childName, childDefinition]) => {
    const childNode = { name: childName, definition: childDefinition };
    return Object.assign(acc, accumulateForNode(acc, dataPoint, childNode, newContext));
  }, acc[derivedName]);

  return { [derivedName]: childAccumulations };
}

module.exports = createAccumulator;
