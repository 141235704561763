import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import * as Styles from './InvoiceZeroBalanceModal.module.scss';

const InvoiceZeroBalanceModal = ({ isVisible, invoiceCount, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title="Please Confirm"
      body={
        <div>
          {featureActive('BB-7088') && (
            <>
              <p>
                {`${
                  invoiceCount === 1 ? 'This invoice' : `At least one of the invoices you've selected`
                } has a $0 balance and
                will be marked as paid upon ${t('finalising')}.`}
              </p>
              <p>This status cannot be reversed.</p>
            </>
          )}
          <p>Are you sure you want to proceed?</p>
        </div>
      }
      footer={
        <div className={Styles.footer}>
          <Button className={Styles.footerBtn} onClick={onConfirm}>
            Ok
          </Button>
          <Button className={Styles.footerBtn} type="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      }
      isVisible={isVisible}
      onModalClose={onCancel}
      shouldCloseOnOverlayClick={false}
    />
  );
};

InvoiceZeroBalanceModal.displayName = 'InvoiceZeroBalanceModal';

InvoiceZeroBalanceModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  invoiceCount: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InvoiceZeroBalanceModal.defaultProps = {};

export default InvoiceZeroBalanceModal;
