import { MatterDisplayDetails } from 'web/graphql/queries';
import { Matter } from 'types';
import { useSubscribedQuery } from './use-subscribed-query';

export function useGetMatterById(matterId: string) {
  const result = useSubscribedQuery(MatterDisplayDetails, {
    variables: {
      matterId,
    },
  });

  return {
    ...result,
    matter: result?.data?.matter as Matter,
  };
}
