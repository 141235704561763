'use strict';

const { otherTransactionsFilter, isTransactionBulkDeposit } = require('../../../transactions/services');
const { transactionType } = require('../../../transactions/entities/constants');
const { bankAccountTypes } = require('../../../bank-account/entities/constants');

/**
 * Filters transactions to only include those that are not 'InvoicePayments/Reversals' and are Trust transactions.
 * That is, all transactions that are not 'InvoicePayments/Reversals' (including 'MatterAdjustments' which do not have a ContactId)
 *
 * @param {Object} params
 * @param {Array<Transaction>} params.transactions - Array of transactions to filter
 * @param {Boolean} [params.excludeBulkDeposits] - Whether to exclude bulk deposit transactions
 * @returns {Array<Transaction>} The filtered transactions.
 */
function filterOtherTrustTransactions({ transactions, excludeBulkDeposits }) {
  return transactions.filter(
    (tx) =>
      tx.accountType === bankAccountTypes.TRUST &&
      ((otherTransactionsFilter(tx) && (!excludeBulkDeposits || !isTransactionBulkDeposit({ transaction: tx }))) ||
        (!tx.contactId &&
          (tx.type === transactionType.MatterAdjustment || tx.type === transactionType.MatterAdjustmentReversal))),
  );
}

module.exports = {
  filterOtherTrustTransactions,
};
