import * as React from 'react';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getMap as getBankAccountBalanceMap } from '@sb-billing/redux/bank-account-balances';
import { getByDebtorId, getInvoiceSummariesByFilter } from '@sb-billing/redux/invoices';
import { getById as getInvoiceTotalsById, getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import PropTypes from 'prop-types';
import { BANK_BALANCE_TYPE, getBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getDefaultCreditAccount, getOperatingAccount, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';
import * as viewContactBillsFilters from 'web/redux/route/home-billing-view-contact-bills';
import { featureActive } from '@sb-itops/feature';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { filterTrustAccountsByContact } from 'web/redux/selectors/filter-trust-accounts';
import { FinancialSummaryByContact } from './FinancialSummaryByContact';
import { TrustTooltipSummary } from './TrustTooltipSummary';

const { getContactBalance } = selectors;

const mapStateToProps = (state, { contactId }) => {
  const trustAccounts = filterTrustAccountsByContact(contactId).filter((ta) => !isTrustAccountClosed(ta));

  const creditBankAccountId = getDefaultCreditAccount()?.id;
  const operatingBankAccountId = getOperatingAccount().id;

  const contactTotals = getByDebtorId(contactId)
    .filter(
      (invoice) =>
        // include only FINAL or PAID invoices, i.e. filter out draft, deleted and voided invoices
        invoice.currentVersion.status === 'FINAL' || invoice.currentVersion.status === 'PAID',
    )
    .reduce(
      (totals, v) => {
        const invoiceTotals = getInvoiceTotalsById(v.invoiceId);
        return {
          unbilled: 0, // all invoices are billed, so this will always be zero
          billed: totals.billed + invoiceTotals.billed,
          paid: totals.paid + invoiceTotals.paid,
          unpaid: totals.unpaid + invoiceTotals.unpaid,
          unpaidExcInterest:
            (totals.unpaidExcInterest || 0) +
            (typeof invoiceTotals.unpaidExcInterest === 'number' ? invoiceTotals.unpaidExcInterest : 0),
        };
      },
      {
        unbilled: 0,
        billed: 0,
        paid: 0,
        unpaid: 0,
        unpaidExcInterest: 0,
      },
    );

  const trustBalance = trustAccounts.reduce(
    (acc, trust) => {
      acc.total +=
        getContactBalance(getBankAccountBalanceMap(), {
          bankAccountId: trust.id,
          contactId,
          balanceType: balanceTypes.BALANCE,
        }) || 0;

      acc.protected +=
        getContactBalance(getBankAccountBalanceMap(), {
          bankAccountId: trust.id,
          contactId,
          balanceType: balanceTypes.PROTECTED,
        }) || 0;

      acc.available +=
        getContactBalance(getBankAccountBalanceMap(), {
          bankAccountId: trust.id,
          contactId,
          balanceType: balanceTypes.AVAILABLE,
        }) || 0;

      return acc;
    },
    {
      total: 0,
      protected: 0,
      available: 0,
    },
  );
  const trustTooltip = featureActive('BB-8671') ? <TrustTooltipSummary totals={trustBalance} /> : undefined;

  return {
    showTrustAccount: !!trustAccounts.length,
    matterContactBalanceEnabled: getBalanceType() === BANK_BALANCE_TYPE.matterContact,
    totals: {
      CREDIT:
        hasFacet(facets.creditMemo) && creditBankAccountId
          ? getContactBalance(getBankAccountBalanceMap(), { bankAccountId: creditBankAccountId, contactId })
          : 0,
      TRUST: trustBalance.total,
      OPERATING: getContactBalance(getBankAccountBalanceMap(), { bankAccountId: operatingBankAccountId, contactId }),
      UNBILLED: contactTotals.unbilled,
      UNPAID: contactTotals.unpaidExcInterest,
      OVERDUE: getInvoiceSummariesByFilter({ overdueOnly: true, debtorId: contactId }).reduce((total, v) => {
        const totals = getTotalsForInvoiceId(v.invoiceId);
        return totals ? total + totals.unpaidExcInterest : total;
      }, 0),
    },
    trustTooltip,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFilterByRecent: (value) => {
    dispatch(viewContactBillsFilters.actions.onFilterByRecent(value));
  },
  onFilterInvoiceStatus: (status) => {
    dispatch(viewContactBillsFilters.actions.onFilterInvoiceStatus(status));
  },
});

const FinancialSummaryByContactContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(FinancialSummaryByContact),
);

FinancialSummaryByContactContainer.displayName = 'FinancialSummaryByContactContainer';

FinancialSummaryByContactContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
};
FinancialSummaryByContactContainer.defaultProps = {};

export default FinancialSummaryByContactContainer;
