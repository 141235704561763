'use strict';

/**
 * getFileSignature
 * @param {Buffer} fileBuffer
 * @return {string} File signature (first 4 bytes in hex)
 */
const getFileSignature = (fileBuffer) => {
  const fileSignature = new Uint8Array(fileBuffer).subarray(0, 4).reduce((str, val) => str + val.toString(16), '');
  return fileSignature;
};

/**
 * isValidByFileTypeSignatures
 * @see fileTypeSignature in "itops/business-logic/files/entities/contants.js" for file type signatures
 * @param {object} params
 * @param {string} params.fileSignature
 * @param {Array.<string>} params.validFileTypeSignatures
 * @return {boolean} Is given file signature valid signature from specified signatures
 */
const isValidByFileTypeSignatures = ({ fileSignature, validFileTypeSignatures }) => {
  if (!fileSignature || !Array.isArray(validFileTypeSignatures)) {
    throw new Error('File signature and array of valid file type signatures required');
  }

  return validFileTypeSignatures.some((signature) => fileSignature.startsWith(signature));
};

module.exports = {
  getFileSignature,
  isValidByFileTypeSignatures,
};
