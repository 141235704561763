import { constants as invoiceSettingsConstants } from '@sb-billing/business-logic/invoice-settings';

// TODO make this a module

function isValidFormatP(file) {
  const fileFormats = {
    PNG: '89504e47',
    GIF: '47494638',
    JPEG: 'ffd8ff',
  };

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      const header = (new Uint8Array(e.target.result)).subarray(0, 4).reduce((str, val) => str += val.toString(16), '');

      if ((header === fileFormats.PNG) || (header === fileFormats.GIF) || (header.startsWith(fileFormats.JPEG))) { //PNG, GIF and JPEG
        resolve();
      } else {
        reject('Invalid Logo File Format: Supported formats are PNG, JPEG and GIF');
      }
    };

    fileReader.readAsArrayBuffer(file);
  });
}
function isValidSizeP (file) {
  if (file && (file.size <= invoiceSettingsConstants.maxLogoSize)) {
    return Promise.resolve();
  }

  return Promise.reject('File size must be less than 3MB');
}
function isValidDimensionP(file) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        if (Math.min(image.width, image.height) >= invoiceSettingsConstants.minLogoDimension) {
          resolve();
        }
        else {
          reject('Width/Height must be over 300px');
        }
      };
      image.src = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  });
}

export function isValidLogoFileP(file) {
  return Promise.all([isValidFormatP(file), isValidSizeP(file), isValidDimensionP(file)])
    .then(() => {});
}
angular.module('sb.billing.webapp').service('sbInvoiceSettingsHelpers', function () {
  const that = this;

  that.isValidLogoFileP = isValidLogoFileP;

});
