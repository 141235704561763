import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { chequeStylesByCode, printMethodsByCode } from '@sb-billing/business-logic/cheques';
import domain from '../domain';

export const api = cacheFactory({
  domain,
  name: 'trust-cheque-print-settings',
  keyPath: 'bankAccountId',
  ngCacheName: 'sbTrustChequePrintSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
  changesetProcessor: ({ entities = [], stateList }) => {
    if (entities.length === 0) {
      return entities;
    }

    const currentVersionId = stateList[0] && stateList[0].versionId;

    if (currentVersionId && !entities[0].versionIds.includes(currentVersionId)) {
      return [];
    }

    return entities.map((setting) => ({
      ...setting,
      style: chequeStylesByCode[setting.style].value,
      printMethod: printMethodsByCode[setting.printMethod].value,
    }));
  },
});
