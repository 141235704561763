import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { marshal } from '@sb-itops/email';

import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbMatterEmailSettingsService',
  keyPath: 'matterId',
});

export const saveMatterEmailSettings = async (accountId, emailSettings) => {
  await store.dispatch(async () => {
    const { matterId, ccAddresses, bccAddresses } = emailSettings;
    const ccAddressesArray = ccAddresses
      ? marshal(ccAddresses, { showDisplayName: false }).split(', ').filter(Boolean)
      : [];
    const bccAddressesArray = bccAddresses
      ? marshal(bccAddresses, { showDisplayName: false }).split(', ').filter(Boolean)
      : [];
    const emailSettingsToOpdate = {
      accountId,
      matterId,
      cCAddresses: ccAddressesArray,
      bCCAddresses: bccAddressesArray,
    };

    opdateCache({ optimisticEntities: [emailSettingsToOpdate] });

    try {
      await fetchPostP({
        path: '/billing/matter-email-settings/:accountId/',
        fetchOptions: { body: JSON.stringify(emailSettingsToOpdate) },
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [emailSettingsToOpdate] });
      throw err;
    }
  });
};
