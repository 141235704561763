import { gql } from '@apollo/client';

const query = gql`
  query WebQueryContactGroups($contactIds: [ID]) {
    contactGroupsWq(contactIds: $contactIds) {
      parentGroupId
      customerIds
    }
  }
`;

const notificationIds = ['CustomerManagementWebQuery'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const WebQueryContactGroups = {
  query,
  notificationIds,
};
