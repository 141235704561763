import React from 'react';
import PropTypes from 'prop-types';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Button, buttonTypes, Spinner, useTranslation } from '@sb-itops/react';
import Styles from './SubscriptionPaymentForm.module.scss';

export const SubscriptionPaymentForm = ({
  legalTermsUrl,
  submitLabel,
  title,
  subTitle1,
  subTitle2,
  errorMessage,
  isSubmitting,
  onSubmit,
  onPaymentMethodUpdateAbort,
}) => {
  const { t } = useTranslation();
  const termsAndConditionsText = t('termsAndConditions');
  const paymentElementOptions = {
    terms: {
      card: 'never',
    },
  };

  return (
    <div className={Styles.paymentForm}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.subtitle}>
        <p>{subTitle1}</p>
        <p>{subTitle2}</p>
      </div>
      <div className={Styles.paymentElementSection}>
        <PaymentElement options={paymentElementOptions} />
      </div>
      {legalTermsUrl && (
        <div className={Styles.legalsSection}>
          By entering your credit card details, you are confirming your Smokeball subscription, and you agree to be
          bound by our{' '}
          <a href={legalTermsUrl} rel="noopener noreferrer" target="_blank">
            {termsAndConditionsText}
          </a>
          .
        </div>
      )}
      <div className={Styles.buttonSection}>
        {!!errorMessage && <div className={Styles.errorMessage}>{errorMessage}</div>}
        <div className={Styles.buttonBar}>
          <Button type={buttonTypes.secondary} disabled={isSubmitting} onClick={onPaymentMethodUpdateAbort}>
            Later
          </Button>
          <Button disabled={isSubmitting} onClick={onSubmit}>
            {submitLabel}
            {'  '}
            {isSubmitting && <Spinner small />}
          </Button>
        </div>
      </div>
    </div>
  );
};

SubscriptionPaymentForm.displayName = 'SubscriptionPaymentForm';

SubscriptionPaymentForm.propTypes = {
  subscriptionPlan: PropTypes.object,
  legalTermsUrl: PropTypes.string,
  submitLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle1: PropTypes.string,
  subTitle2: PropTypes.string,
  errorMessage: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPaymentMethodUpdateAbort: PropTypes.func.isRequired,
};

SubscriptionPaymentForm.defaultProps = {
  subscriptionPlan: undefined,
  legalTermsUrl: undefined,
  subTitle1: '',
  subTitle2: '',
  submitLabel: 'Confirm Subscription',
  errorMessage: undefined,
};
