angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/bank-recons';

  $stateProvider
    .state('home.billing.support.bank-recons', {
      url: '/bank-recons',
      templateUrl: BASE_DIRECTORY + '/bank-recons.html',
      controller: 'sbBankReconCancelController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});
