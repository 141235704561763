import { getRegion } from '@sb-itops/region';
import { useLoadScript } from '@react-google-maps/api';
import { getGoogleApiKey } from '@sb-itops/environment-config';
import { mapSearchResultToIntermediateFields } from '@sb-customer-management/business-logic/contacts/services/address-search';
import React from 'react';
import AddressAutocomplete from './AddressAutocomplete';

const region = getRegion();

const mapEl = document.createElement('div');

const libraries = ['places'];

const AddressAutocompleteContainer = ({ label, onSelect }) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: getGoogleApiKey(), libraries });

  const fetchAndMapAddress = async (term) => {
    const googleCountryString = region ? region.toLowerCase() : undefined;
    // eslint-disable-next-line no-undef
    const autocompleteService = new window.google.maps.places.AutocompleteService();

    const addressArr = await new Promise((resolve) => {
      autocompleteService.getPlacePredictions(
        { input: term, componentRestrictions: { country: googleCountryString } },
        (predictions, status) => {
          // eslint-disable-next-line no-undef
          if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return resolve([]);
          }
          return resolve(predictions.map((prediction) => prediction.description));
        },
      );
    });
    return addressArr.map((address) => ({
      label: address,
      value: address,
    }));
  };

  const fetchAddressAutocompleteInfo = (selectedTerm) => {
    // eslint-disable-next-line no-undef
    const placesService = new window.google.maps.places.PlacesService(mapEl);
    return new Promise((resolve) => {
      placesService.findPlaceFromQuery(
        {
          query: selectedTerm,
          fields: ['place_id'],
        },
        (places, status) => {
          // eslint-disable-next-line no-undef
          if (status === window.google.maps.places.PlacesServiceStatus.OK && places?.length) {
            placesService.getDetails(
              {
                placeId: places[0].place_id || '',
                fields: ['address_component'],
                // fields: ['ALL'],
              },
              (place, status2) => {
                // eslint-disable-next-line no-undef
                if (status2 === window.google.maps.places.PlacesServiceStatus.OK && place) {
                  resolve(mapSearchResultToIntermediateFields(selectedTerm, place.address_components));
                }
              },
            );
          }
        },
      );
    });
  };
  return (
    <AddressAutocomplete
      label={label}
      onSelect={onSelect}
      fetchAddressAutocompleteInfo={fetchAddressAutocompleteInfo}
      fetchAndMapAddress={fetchAndMapAddress}
      isLoaded={isLoaded}
    />
  );
};

export default AddressAutocompleteContainer;
