import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';
import { PaginationSelector } from '@sb-itops/react';
import { InvoiceListActionBar, InvoiceTable } from 'web/components';

import Styles from './BillingAdvancedSearchInvoicesRoute.module.scss';

export const BillingAdvancedSearchInvoicesRoute = React.memo(
  ({
    onOpenInvoiceEmailModal,
    onOpenMarkInvoiceAsSentModal,
    /** Invoice list action bar */
    isCombineInPdfInProgress,
    isDownloadLedesInProgress,
    sbAsyncOperationsService,
    sbSaveInvoiceCommand,
    scope,
    selectedInvoicesByAction,
    // Callbacks
    onDeselectDeletedDraftInvoices,
    onFinaliseDraftInvoicesSuccess,
    /** Invoice table */
    invoiceDataLoading,
    invoices,
    invoicesOnSort,
    invoicesSortBy,
    invoicesSortDirection,
    invoiceStatuses,
    latestCorrespondenceLoading,
    latestCorrespondencePerContact,
    selectedInvoices,
    showDebtor,
    showMatter,
    // Callbacks
    onClickLink,
    onOpenInvoiceCommunicateModal,
    onToggleInvoices,
    onFetchLatestCorrespondenceDetailsForInvoice,
    /** Pagination */
    isPaginationHidden,
    invoicesCurrentPage,
    invoicesTotalNumberOfPages,
    invoicesOnPageChange,
  }) => (
    <div className={Styles.billingAdvancedSearchInvoicesRoute}>
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        <div className={classnames('panel-body', Styles.panelContent)}>
          {/* Invoice list action bar */}
          <div className="ribbon panel panel-primary">
            <InvoiceListActionBar
              isCombineInPdfInProgress={isCombineInPdfInProgress}
              isDownloadLedesInProgress={isDownloadLedesInProgress}
              sbAsyncOperationsService={sbAsyncOperationsService}
              sbSaveInvoiceCommand={sbSaveInvoiceCommand}
              scope={scope}
              selectedInvoicesByAction={selectedInvoicesByAction}
              showBulkActions
              showDownloadOptions
              // Callbacks
              onDeselectDeletedDraftInvoices={onDeselectDeletedDraftInvoices}
              onFinaliseDraftInvoicesSuccess={onFinaliseDraftInvoicesSuccess}
              onOpenInvoiceEmailModal={onOpenInvoiceEmailModal}
              onOpenMarkInvoiceAsSentModal={onOpenMarkInvoiceAsSentModal}
            />
          </div>
          {/* Invoice table */}
          <div className={Styles.invoiceTableContainer}>
            <InvoiceTable
              displayMode="SEARCH"
              invoices={invoices}
              invoiceStatuses={invoiceStatuses}
              isLoading={invoiceDataLoading}
              latestCorrespondenceLoading={latestCorrespondenceLoading}
              latestCorrespondencePerContact={latestCorrespondencePerContact}
              selectedInvoices={selectedInvoices}
              showDebtor={showDebtor}
              showMatter={showMatter}
              sort={invoicesOnSort}
              sortBy={invoicesSortBy}
              sortDirection={invoicesSortDirection}
              // Callbacks
              onClickLink={onClickLink}
              onFetchLatestCorrespondenceDetailsForInvoice={onFetchLatestCorrespondenceDetailsForInvoice}
              onMarkInvoiceAsSent={(rowData) => onOpenMarkInvoiceAsSentModal({ invoiceIds: [rowData.invoiceId] })}
              onSendInvoiceCommunicate={(rowData) =>
                onOpenInvoiceCommunicateModal({ invoiceIds: [rowData.invoiceId], contactId: rowData.contactId })
              }
              onSendInvoiceEmail={(rowData) =>
                onOpenInvoiceEmailModal({ invoiceIds: [rowData.invoiceId], contactId: rowData.contactId })
              }
              onToggleInvoices={onToggleInvoices}
            />
          </div>
          {/* Pagination */}
          <PaginationSelector
            name="advanced-search-invoice-list"
            className={Styles.paginationSection}
            hidePagination={isPaginationHidden}
            numberOfPagesDisplayed={10}
            selectedPage={invoicesCurrentPage}
            totalNumberOfPages={invoicesTotalNumberOfPages}
            onPageChange={invoicesOnPageChange}
          />
        </div>
      </div>
    </div>
  ),
);

BillingAdvancedSearchInvoicesRoute.displayName = 'BillingAdvancedSearchInvoicesRoute';

BillingAdvancedSearchInvoicesRoute.propTypes = {
  onOpenInvoiceEmailModal: PropTypes.func.isRequired,
  onOpenMarkInvoiceAsSentModal: PropTypes.func.isRequired,
  /** Invoice list action bar */
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  selectedInvoicesByAction: PropTypes.object.isRequired,
  // Callbacks
  onDeselectDeletedDraftInvoices: PropTypes.func.isRequired,
  onFinaliseDraftInvoicesSuccess: PropTypes.func.isRequired,
  /** Invoice table */
  invoiceDataLoading: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoicesOnSort: PropTypes.func.isRequired,
  invoicesSortBy: PropTypes.string.isRequired,
  invoicesSortDirection: PropTypes.string.isRequired,
  invoiceStatuses: PropTypes.arrayOf(
    PropTypes.oneOf([
      invoiceStatusesMap.DRAFT,
      invoiceStatusesMap.FINAL,
      invoiceStatusesMap.PAID,
      invoiceStatusesMap.VOID,
      'OVERDUE', // Pseudo status based on "FINAL" invoices and their due dates
    ]),
  ).isRequired,
  latestCorrespondenceLoading: PropTypes.bool,
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object), // CorrespondenceHistory entity,
  selectedInvoices: PropTypes.object.isRequired,
  showDebtor: PropTypes.bool.isRequired,
  showMatter: PropTypes.bool.isRequired,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onFetchLatestCorrespondenceDetailsForInvoice: PropTypes.func.isRequired,
  onOpenInvoiceCommunicateModal: PropTypes.func.isRequired,
  onToggleInvoices: PropTypes.func.isRequired,
  /** Pagination */
  invoicesCurrentPage: PropTypes.number.isRequired,
  invoicesOnPageChange: PropTypes.func.isRequired,
  invoicesTotalNumberOfPages: PropTypes.number.isRequired,
  isPaginationHidden: PropTypes.bool.isRequired,
};

BillingAdvancedSearchInvoicesRoute.defaultProps = {
  latestCorrespondenceLoading: undefined,
  latestCorrespondencePerContact: undefined,
};
