import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './StackedItemSelector.module.scss';

export const StackedItemSelector = ({ items, itemCountById, selectedItemId, onItemSelected }) => (
  <div className={Styles.stackedItemSelector}>
    <ul className={Styles.list}>
      {items.map((item) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          key={item.id}
          className={classnames(Styles.item, selectedItemId === item.id && Styles.selected)}
          onClick={() => onItemSelected(item.id)}
        >
          <span className={!item.label ? Styles.capitalCase : undefined}>{item.label || item.id}</span>
          {itemCountById[item.id] && <span className={Styles.badge}>{itemCountById[item.id]}</span>}
        </li>
      ))}
    </ul>
  </div>
);

StackedItemSelector.displayName = 'StackedItemSelector';

StackedItemSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemCountById: PropTypes.object,
  selectedItemId: PropTypes.string,
  onItemSelected: PropTypes.func,
};

StackedItemSelector.defaultProps = {
  selectedItemId: undefined,
  itemCountById: {},
  onItemSelected: () => {},
};
