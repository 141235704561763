export type Action<T> = {
  type: string;
  payload: T;
};

const makeActionCreator =
  <P>(type: string) =>
  (payload: P) => ({
    type,
    payload,
  });

export const makeActionCreators = <ActionPayloads>(actionTypeMapping: {
  [KP in keyof ActionPayloads]: string;
}) =>
  Object.keys(actionTypeMapping).reduce((acc, prettyName) => {
    const actionType = actionTypeMapping[prettyName];

    acc[prettyName] = makeActionCreator(actionType);

    return acc;
  }, {} as { [K in keyof ActionPayloads]: (payload: ActionPayloads[K]) => Action<ActionPayloads[K]> });

export const makeActionGuards = <ActionPayloads>(actionTypeMapping: {
  [KP in keyof ActionPayloads]: string;
}) =>
  Object.entries(actionTypeMapping).reduce(
    (acc, [prettyName]) => {
      const actionType = actionTypeMapping[prettyName];

      acc[prettyName] = (action: Action<unknown>) => {
        if (action && typeof action === 'object' && typeof action.type === 'string' && action.type === actionType) {
          return true;
        }
        return false;
      };

      return acc;
    },
    {} as {
      [KP in keyof ActionPayloads]: (action: unknown) => action is Action<ActionPayloads[KP]>;
    },
  );

export const make = <ActionPayloads>(actionTypeMapping: {
  [KP in keyof ActionPayloads]: string;
}) => ({
  actionCreators: makeActionCreators<ActionPayloads>(actionTypeMapping),
  actionGuards: makeActionGuards<ActionPayloads>(actionTypeMapping),
});

export type TAutoSelectors<State, Keys extends keyof State> = {
  [K in Keys as `get${Capitalize<string & K>}`]: (state: State) => State[K];
};

export const autoSelectorFactory =
  <State>() =>
  <Keys extends keyof State>(keys: Keys[]) => {
    const selectors = {} as TAutoSelectors<State, Keys>;

    keys.forEach((k) => {
      if (typeof k === 'string') {
        const getterName = `get${k[0].toUpperCase()}${k.slice(1, k.length)}`;
        selectors[getterName] = (state: State) => state[k];
      }
    });
    return selectors;
  };
