angular.module('sb.billing.webapp').directive('sbActivityEntry', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'scope': {
      'preFill': '=?',
      'onClose': '&?',
      'isLoadingData': '=?',
      'onDeleteActivity': '&'
    },
    'controller': 'SbActivityEntryController',
    'controllerAs': 'activityEntry',
    'templateUrl': 'ng-components/activity-entry/activity-entry.html'
  };
});
