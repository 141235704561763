import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useClickOutside } from '../hooks';
import Styles from './DurationTypePicker.module.scss';

export const displayModes = Object.freeze({
  RESPONSIVE: 'RESPONSIVE',
  STANDARD: 'STANDARD',
  MINIMAL: 'MINIMAL',
});

const durationTypeButtonLabels = {
  FIXED: 'FIXED',
  HOURS: 'HRS',
  UNITS: 'UNITS',
};

export const DurationTypePicker = ({
  durationType,
  allowFixed,
  allowHours,
  allowUnits,
  onDurationTypeChanged,
  durationTypeDisabled,
  displayMode,
  className,
}) => {
  // Controls whether or not the minimal mode menu is currently being displayed.
  const [showMinimalModeMenu, setShowMinimalModeMenu] = useState(false);

  // For detecting clicks outside the minimal mode menu.
  const minimalModeMenuRef = useRef(null);
  useClickOutside(minimalModeMenuRef, () => setShowMinimalModeMenu(false));

  const handleMinimalModeMenuItemClicked = (itemType) => {
    // Don't trigger events if the already selected duration type is clicked.
    if (durationType === itemType) {
      return;
    }

    onDurationTypeChanged(itemType);
    setShowMinimalModeMenu(false);
  };

  const enableResponsiveness = displayMode === displayModes.RESPONSIVE;
  const enableStandardDisplay = displayMode === displayModes.RESPONSIVE || displayMode === displayModes.STANDARD;
  const enableMinimalDisplay = displayMode === displayModes.RESPONSIVE || displayMode === displayModes.MINIMAL;

  const getButtonClasses = (type) => {
    const activeOrInactiveClass = type === durationType ? Styles.active : Styles.inactive;
    return classnames(activeOrInactiveClass, durationTypeDisabled && Styles.disabled);
  };

  const isDurationTypeAllowed = (type) =>
    (type === 'FIXED' && allowFixed) || (type === 'HOURS' && allowHours) || (type === 'UNITS' && allowUnits);

  return (
    <div className={classnames(Styles.durationTypePicker, className)}>
      {enableStandardDisplay && (
        <div
          className={classnames(
            Styles.standardButtonGroup,
            enableResponsiveness && Styles.responsiveStandardButtonGroup,
          )}
        >
          {allowFixed && (
            <button
              type="button"
              name="fixed"
              className={getButtonClasses('FIXED')}
              onClick={() => onDurationTypeChanged('FIXED')}
              disabled={durationTypeDisabled}
            >
              FIXED
            </button>
          )}
          {allowHours && (
            <button
              type="button"
              name="hours"
              className={getButtonClasses('HOURS')}
              onClick={() => onDurationTypeChanged('HOURS')}
              disabled={durationTypeDisabled}
            >
              HRS
            </button>
          )}
          {allowUnits && (
            <button
              type="button"
              name="units"
              className={getButtonClasses('UNITS')}
              onClick={() => onDurationTypeChanged('UNITS')}
              disabled={durationTypeDisabled}
            >
              UNITS
            </button>
          )}
        </div>
      )}

      {enableMinimalDisplay && (
        <div
          className={classnames(Styles.minimalButtonGroup, enableResponsiveness && Styles.responsiveMinimalButtonGroup)}
        >
          <button
            type="button"
            className={classnames(Styles.active, Styles.dropdownButton, durationTypeDisabled && Styles.disabled)}
            onClick={() => setShowMinimalModeMenu(!showMinimalModeMenu)}
          >
            <span>{durationTypeButtonLabels[durationType]}</span>
            {!durationTypeDisabled && <i className="icon icon-arrow-58" />}
          </button>
          {/* Dropdown list selected */}
          {showMinimalModeMenu && !durationTypeDisabled && (
            <div className={Styles.menu} ref={minimalModeMenuRef}>
              {Object.entries(durationTypeButtonLabels)
                .filter(([itemType]) => isDurationTypeAllowed(itemType))
                .map(([itemType, itemLabel]) => (
                  <div
                    key={itemType}
                    className={classnames(Styles.item, durationType === itemType && Styles.active)}
                    onClick={() => handleMinimalModeMenuItemClicked(itemType)}
                  >
                    {itemLabel}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DurationTypePicker.displayName = 'DurationTypePicker';

DurationTypePicker.propTypes = {
  durationType: PropTypes.oneOf(['HOURS', 'UNITS', 'FIXED', '']),
  allowFixed: PropTypes.bool,
  allowHours: PropTypes.bool,
  allowUnits: PropTypes.bool,
  onDurationTypeChanged: PropTypes.func.isRequired,
  durationTypeDisabled: PropTypes.bool,
  className: PropTypes.string,
  displayMode: PropTypes.oneOf(Object.values(displayModes)),
};

DurationTypePicker.defaultProps = {
  durationType: undefined,
  allowFixed: true,
  allowHours: true,
  allowUnits: true,
  durationTypeDisabled: false,
  className: undefined,
  displayMode: displayModes.RESPONSIVE,
};
