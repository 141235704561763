import { cacheFactory, syncTypes } from '@sb-itops/redux';

import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'default-matter-billing-configuration',
  keyPath: 'accountId',
  ngCacheName: 'sbDefaultMatterBillingConfigurationService',
  syncType: syncTypes.ALL,
  immutable: false,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getConfig = () => getList()[0];

export * from './save-config';
