import { gql } from '@apollo/client';

const query = gql`
  query InitFirmUtbmsSettings {
    firmUtbmsSettings {
      isUtbmsEnabled
      ledesFirmId
      selectedCodeSets
      utbmsCodesRequired
    }
  }
`;

const notificationIds = ['BillingActivitiesNotifications.BillingUtbmsSettingsUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitFirmUtbmsSettings = {
  query,
  notificationIds,
};
