import { capitalize } from '@sb-itops/nodash';

angular.module('sb.billing.webapp').controller('SbActivityListController', function ($scope, sbBillingActivityService, sbLocalisationService) {
  'use strict';
  var that = this;

  that.view = {};
  that.view = { activityTypeInverted: sbBillingActivityService.activityTypesInverted };
  that.getTaxRate = getTaxRate;
  that.getActivityType = getActivityType;
  that.t = sbLocalisationService.t;

  update();

  function update(){
    that.view.activities = sbBillingActivityService.getActivities();
  }

  function getActivityType(rowType) {
    const activity = (that.view.activityTypeInverted[rowType] || '').toLowerCase();

    return capitalize(sbLocalisationService.t(activity));
  }

  function getTaxRate(row) {
    if (sbBillingActivityService.activityTypesInverted[row.type] !== 'Expense') {
      return 'N/A';
    }
    return _.isFinite(row.taxRate) && row.isTaxInclusive ? row.taxRate / 100 : 'N/A'; // tax rate saved in basis points so 1% = 100 basis points
  }

  $scope.$on('smokeball-data-update-sbBillingActivityService', function () {
    update();
  });
});
