'use strict';

const deriveOverriddenInvoiceSettings = ({
  matterInvoiceSettings,
  matterInvoiceSettingsTemplate,
  firmDefaultInvoiceSettingsTemplate,
}) => {
  if (!firmDefaultInvoiceSettingsTemplate) {
    throw new Error('firmDefaultInvoiceSettingsTemplate is required');
  }

  // Both matterInvoiceSettings and matterInvoiceSettingsTemplate can be undefined
  // e.g. when it's a new matter and user has not saved their matter settings yet
  // in which case we default settings
  const templateSettings =
    (matterInvoiceSettingsTemplate && matterInvoiceSettingsTemplate.settings) ||
    firmDefaultInvoiceSettingsTemplate.settings;

  // use matter level overrides if defined, otherwise use template settings
  const titleSettings =
    matterInvoiceSettings && matterInvoiceSettings.titleLine1Overridden ? matterInvoiceSettings : templateSettings;
  const subTitleSettings =
    matterInvoiceSettings && matterInvoiceSettings.titleLine2Overridden ? matterInvoiceSettings : templateSettings;
  const footerSettings =
    matterInvoiceSettings && matterInvoiceSettings.footerOverridden ? matterInvoiceSettings : templateSettings;

  return {
    titleLine1CustomText: titleSettings.titleLine1CustomText,
    titleLine1Option: titleSettings.titleLine1Option,
    titleLine2CustomText: subTitleSettings.titleLine2CustomText,
    titleLine2Option: subTitleSettings.titleLine2Option,
    footer: footerSettings.footer,
  };
};

module.exports = {
  deriveOverriddenInvoiceSettings,
};
