import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@sb-itops/react';
import classnames from 'classnames';

import { CreditNoteModal } from '../credit-note';
import { CreditNoteLedgerTransactionTable } from '../credit-note-ledger-transaction-table';
import Styles from './MatterCreditNoteLedger.module.scss';

export const MatterCreditNoteLedger = ({
  matterId,
  contactId,
  transactions,
  showMatterColumn,
  showContactColumn,
  sortDirection,
  sortBy,
  onSort,
  onClickLink,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onOpenModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={classnames('panel-body', Styles.creditNoteLedger)}>
      <div className={classnames('ribbon', 'panel', 'panel-primary')}>
        <Button onClick={onOpenModal}>Add Credit</Button>
        <CreditNoteModal isVisible={showModal} onClose={onCloseModal} matterId={matterId} contactId={contactId} />
      </div>
      <CreditNoteLedgerTransactionTable
        transactions={transactions}
        showMatterColumn={showMatterColumn}
        showContactColumn={showContactColumn}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSort={onSort}
        onClickLink={onClickLink}
      />
    </div>
  );
};

MatterCreditNoteLedger.displayName = 'MatterCreditNoteLedger';

MatterCreditNoteLedger.propTypes = {
  matterId: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.object),
  showMatterColumn: PropTypes.bool,
  showContactColumn: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

MatterCreditNoteLedger.defaultProps = {
  contactId: undefined,
  transactions: [],
  showMatterColumn: false,
  showContactColumn: true,
  sortDirection: undefined,
  sortBy: undefined,
};
