import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { opdateCache, rollbackOpdateCache } from './index';
// Only to be used for Controlled Money Accounts and Trust account in GB
export const addBankInterest = (interestDetails) => {
  if (!interestDetails?.accountType) {
    throw new Error('Account needs to have accountType specified.');
  }

  const addBankInterestThunk = async () => {
    const newInterest = { ...interestDetails, versionId: uuid() };

    // Apply to save optimistically.
    const interestOpdate = buildOpdate(newInterest);
    opdateCache({ optimisticEntities: [interestOpdate] });

    // Apply the save in the backend.
    try {
      const accountType = newInterest.accountType.toUpperCase();
      const path = `/billing/bank-account/interest/:accountId/${accountType}`;
      const fetchOptions = { body: JSON.stringify(newInterest) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [interestOpdate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(addBankInterestThunk);
};

function buildOpdate(interestDetails) {
  return {
    ...interestDetails,
    id: interestDetails.transactionId,
    type: transactionType.Interest,
    description: 'Interest Accrued',
    note: interestDetails.internalNote,
    timestamp: new Date().toISOString(),
  };
}
