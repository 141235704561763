angular.module('sb.billing.webapp').component('sbAsyncOperationById', {
  'require':  { compose: '^sbCompose' },
  'bindings' : { operationId: '<', composeDataKey: '<?' },
  'controller': function ($scope, sbAsyncOperationsService, sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbAsyncOperationById');
    //log.setLogLevel('info');

    ctrl.$onChanges = function(changes) {
      if (changes.operationId) {
        log.info(`operationId changed from ${changes.operationId.perviousValue} to ${changes.operationId.currentValue}`);
        const asyncOp = sbAsyncOperationsService.getOperationById(changes.operationId.currentValue);
        if (asyncOp) {
          log.info(`Setting asyncOperation sbData to: ${asyncOp.data}`);
          ctrl.compose.setComposeData({dataType: 'asyncOperations', data: asyncOp.data}, ctrl.composeDataKey || 'asyncOperation');
        }
      }
    };
  }
});
