import { dateToInteger } from '@sb-itops/date';

import {
  SELECT_DATE_FILTER_TYPE,
  SELECT_BEFORE_DATE,
  SELECT_FROM_DATE,
  SELECT_TO_DATE,
  SELECT_STAFF_IDS,
  SET_HIDE_IF_ON_FINALISED_INVOICE,
  SET_HIDE_IF_ON_A_CHECK,
  TOGGLE_SHOW_FILTERS,
  SET_SHOW_DISBURSEMENTS,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE,
  SET_SHOW_ANTICIPATED_DISBURSEMENTS_PAID,
} from './types';

const initialState = {
  showFilters: true,
  showDisbursements: true,
  showAnticipatedDisbursements: true,
  showAnticipatedDisbursementsUnpaid: true,
  showAnticipatedDisbursementsOverdue: true,
  showAnticipatedDisbursementsPaid: true,
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case SELECT_DATE_FILTER_TYPE: {
      return {
        ...state,
        selectedDateFilterType: action.payload.dateFilterType,
      };
    }
    case SELECT_BEFORE_DATE: {
      return {
        ...state,
        selectedBeforeDate: dateToInteger(action.payload.beforeDate), // required due to immu
      };
    }
    case SELECT_FROM_DATE: {
      return {
        ...state,
        selectedFromDate: dateToInteger(action.payload.fromDate), // required due to immu
      };
    }
    case SELECT_TO_DATE: {
      return {
        ...state,
        selectedToDate: dateToInteger(action.payload.toDate), // required due to immu
      };
    }
    case SELECT_STAFF_IDS: {
      return {
        ...state,
        selectedStaffIds: action.payload.staffIds.reduce((acc, staffId) => {
          // required due to immu
          acc[staffId] = staffId;
          return acc;
        }, {}),
      };
    }
    case SET_HIDE_IF_ON_FINALISED_INVOICE: {
      return {
        ...state,
        hideIfOnFinalisedInvoice: action.payload.hideIfOnFinalisedInvoice,
      };
    }
    case SET_HIDE_IF_ON_A_CHECK: {
      return {
        ...state,
        hideIfOnACheck: action.payload.hideIfOnACheck,
      };
    }
    case SET_SHOW_DISBURSEMENTS: {
      return {
        ...state,
        showDisbursements: action.payload.showDisbursements,
      };
    }
    case SET_SHOW_ANTICIPATED_DISBURSEMENTS: {
      return {
        ...state,
        showAnticipatedDisbursements: action.payload.showAnticipatedDisbursements,
      };
    }
    case SET_SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID: {
      return {
        ...state,
        showAnticipatedDisbursementsUnpaid: action.payload.showAnticipatedDisbursementsUnpaid,
      };
    }
    case SET_SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE: {
      return {
        ...state,
        showAnticipatedDisbursementsOverdue: action.payload.showAnticipatedDisbursementsOverdue,
      };
    }
    case SET_SHOW_ANTICIPATED_DISBURSEMENTS_PAID: {
      return {
        ...state,
        showAnticipatedDisbursementsPaid: action.payload.showAnticipatedDisbursementsPaid,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
