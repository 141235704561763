const { pick: pickValueFromObject } = require('dot-object');

module.exports = ({ property, includeNull = false, includeUndefined = false }) => {
  if (!property) {
    throw new Error(`'extractLast' requires a property parameter`);
  }

  return (dataPoint, currentValue) => {
    const newValue = pickValueFromObject(property, dataPoint);
    const isNewValueValid = (includeNull || newValue !== null) && (includeUndefined || newValue !== undefined);
    return isNewValueValid ? newValue : currentValue;
  };
};
