export const groupMattersListSummaries = (mattersList) => {
  const accumulatorInitial = [
    {
      label: 'OPEN MATTERS',
      options: [],
    },
    {
      label: 'OTHER MATTERS',
      options: [],
    },
  ];

  return mattersList
    .reduce((acc, matter) => {
      const openAccumulator = acc[0];
      const otherAccumulator = acc[1];
      if (matter.status === 'Open') {
        openAccumulator.options.push(matter);
      } else {
        otherAccumulator.options.push(matter);
      }
      return acc;
    }, accumulatorInitial)
    .map((group) => ({
      label: group.label,
      options: group.options.sort((a, b) => b.matterStarted - a.matterStarted),
    }));
};

export const findInOptions = (list, id) =>
  list
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue.options), [])
    .find((item) => item.id === id);
