'use strict';

/**
 * Takes an array of object of the same shape and turn these into a CSV string
 * @param {object[]} objArray An array of objects to turn into csv string
 * @param {object} options Options to tailor csv output
 * - includeHeaderRow - generates a header row based on object key names if set to true, defaults to true
 * - wrapAllValuesInDoubleQuotes - wraps value in double quotes if set to true, defaults to true
 * @param {object} [options.includeHeaderRow=true]
 * @param {object} [options.wrapAllValuesInDoubleQuotes=true]
 * @return {string} a csv string
 */
const toCsv = (
  objArray,
  { includeHeaderRow = true, wrapAllValuesInDoubleQuotes = true } = {
    includeHeaderRow: true,
    wrapAllValuesInDoubleQuotes: true,
  },
) => {
  // This is based off http://jsfiddle.net/sturtevant/vUnF9/
  const array = objArray;
  if (!(array.length && array.length > 0)) {
    return '';
  }

  let csvString = '';
  const firstRow = array[0];
  const fields = Object.keys(firstRow);

  // create header row if applicable
  if (includeHeaderRow) {
    let line = '';
    if (wrapAllValuesInDoubleQuotes) {
      fields.forEach((field) => {
        const value = `${field}`;
        line += `"${value.replace(/"/g, '""')}",`;
      });
    } else {
      fields.forEach((field) => {
        line += `${field},`;
      });
    }
    line = line.slice(0, -1);
    csvString += `${line}\r\n`;
  }

  // create data rows
  array.forEach((row) => {
    let line = '';
    if (wrapAllValuesInDoubleQuotes) {
      fields.forEach((field) => {
        const value = `${row[field]}`;
        line += `"${value.replace(/"/g, '""')}",`;
      });
    } else {
      fields.forEach((field) => {
        line += `${row[field]},`;
      });
    }

    line = line.slice(0, -1);
    csvString += `${line}\r\n`;
  });

  return csvString;
};

module.exports = {
  toCsv,
};
