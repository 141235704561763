'use strict';

const { fetchAccessAuthorisations } = require('./fetch-access-authorisations');
const { checkAccessToResourceIsAuthorised } = require('./check-access-to-resource-is-authorised');

/**
 * @deprecated after BB-11975 is rolled out
 * Check if user is authorised to access Smokeball web application
 * @param {object} params
 * @param {string} params.accountId
 * @param {string} params.userId
 * @param {'AU'|'US'|'GB'} params.region
 * @param {'LOCAL'|'DEV'|'RC'|'QA'|'LIVE'} params.appEnv
 * @param {'dev'|'staging'|'prod'} params.dataEnv
 * @returns {Promise<Boolean>} boolean indicating if user is authorised
 */
async function checkUserIsAuthorised({ accountId, userId, region, appEnv, dataEnv }) {
  const allowedAppEnvs = ['LOCAL', 'DEV', 'RC', 'QA', 'LIVE'];
  if (!allowedAppEnvs.includes(appEnv)) {
    throw new Error(`Invalid appEnv '${appEnv}' provided. Supported appEnv values: ${allowedAppEnvs.join(',')}`);
  }

  const allowedDataEnvs = ['dev', 'staging', 'prod'];
  if (!allowedDataEnvs.includes(dataEnv)) {
    throw new Error(`Invalid dataEnv '${dataEnv}' provided. Supported dataEnv values: ${allowedDataEnvs.join(',')}`);
  }

  // fetching authorisations for all resourceIds in one go
  const billingAccessResourceId = 'billing/data';
  const resourceIds = [billingAccessResourceId];
  const accessAuthorisations = await fetchAccessAuthorisations({
    accountId,
    userId,
    resourceIds,
    region,
    appEnv,
    dataEnv,
  });

  // check access authorisation for individual resourceId
  const billingAccess = checkAccessToResourceIsAuthorised({
    accessAuthorisations,
    resourceId: billingAccessResourceId,
  });

  // in not too distant future, access to the web app will most likely extend beyond access
  // to billing data, e.g. productivity features will start to be included, so access to the
  // web app is possible if user is granted access to productivity features
  return billingAccess;
}

module.exports = {
  checkUserIsAuthorised,
};
