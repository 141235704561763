'use strict';

const { getIncomeAllocationReportFooter } = require('./get-income-allocation-report-footer');

const generatePdfOptions = ({ interestOnInvoicesEnabled, surchargeEnabled, t }) =>
  Object.freeze({
    'cash-book-payments': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'account-balances': { footerRight: '[pdf-hf-data]', O: 'Landscape' },
    'audit-logs': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'income-by-attorney': {
      O: 'Landscape',
      zoom: '0.9',
      footerFontSize: 7,
      B: '26mm',
      footerSpacing: 16,
      footerLeft: getIncomeAllocationReportFooter({
        interestOnInvoicesEnabled,
        surchargeEnabled,
        t,
      }),
    },
    'ledger-export': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'matter-balances': { O: 'Landscape' },
    'overdraw-trust-account': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'trust-receipts': { footerRight: '[pdf-hf-data]' },
    'receipts-cash-book': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'journal-transfers': { O: 'Landscape' },
    'account-statement': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'trust-statement': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'time-fees-expenses': { O: 'Landscape' },
    'credit-history': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
    'cma-listing': { O: 'Landscape', footerRight: '[pdf-hf-data]' },
  });

const getDownloadPdfOptions = ({ reportId, interestOnInvoicesEnabled, surchargeEnabled, t }) => {
  const generatedPdfOptions = generatePdfOptions({
    interestOnInvoicesEnabled,
    surchargeEnabled,
    t,
  });
  const pdfOptions = generatedPdfOptions[reportId] || {};

  return pdfOptions;
};

module.exports = {
  getDownloadPdfOptions,
};
