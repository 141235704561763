'use strict';

const firmStatuses = {
  PENDING: 'Pending',
  AWAITING: 'Awaiting',
  ACTIVE: 'Active',
  CLOSED: 'Closed',
};

module.exports = {
  firmStatuses,
};
