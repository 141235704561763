import utilsFactory from '../utils-factory';
import { domain } from '../domain';
import store from '../store';

const name = `cache-update`;
// until all caches are immutable, cache-update must be mutable
const { createActionName, registerReducer, getState } = utilsFactory({ domain, name, immutable: false });

export const initState = {};

// action types
export const BROADCAST_UPDATES = createActionName('BROADCAST_UPDATES');
export const CLEAR_UPDATES = createActionName('CLEAR_UPDATES');

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case CLEAR_UPDATES:
      return initState;

    case BROADCAST_UPDATES:
      return { ...state, [action.meta.ngCacheName]: action.payload };

    default:
      return state;
  }
};

registerReducer(reducer);

// selectors
export const getUpdates = () => getState();

// actions
export const broadcastUpdates = ({ payload, ngCacheName, keyPath }) =>
  store.dispatch({
    type: BROADCAST_UPDATES,
    payload,
    meta: {
      ngCacheName,
      keyPath,
    },
  });
export const clearUpdates = () =>
  store.dispatch({
    type: CLEAR_UPDATES,
  });
