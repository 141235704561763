// DISCUSS: before adding more to this file, please consider putting it in business-logic/contacts
// If you really think it should go in this folder, please discuss with Rom first.
//
// James Z: We should consolidate business-logic/contact-summary into business-logic/contacts.
// The only reason why the concept of a "contact summary" exists is because that's a cache we have
// on the frontend. This should not be a domain concept and it's a bit confusing as the next developer
// now needs to decide where to put their generic contacts-related business logic.

const { getDisplayName } = require('./get-display-name');
const { formatContactsEmails } = require('./format-contacts-emails');
const { formatContactSalutation } = require('./format-contact-salutation');

module.exports = {
  getDisplayName,
  formatContactsEmails,
  formatContactSalutation,
};
