import React from 'react';
import { DateRangePicker, SlidingToggle, useTranslation } from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';
import Styles from '../ReportFilter.module.scss';

const IncomeByAttorneyFilter = ({
  onSelectPeriod,
  onEndSelect,
  onStartSelect,
  onToggleOption,
  onAttorneysOnly,
  filters,
  periodOptions,
}) => {
  const { t } = useTranslation();

  if (!filters) {
    return null;
  }

  const {
    periodSelected,
    startDate,
    endDate,
    includeFirmHeader,
    includeFirmLogo,
    includeFirmName,
    showPercentage,
    attorneysOnly,
    showHours,
    errors,
  } = filters;

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.period}>
        <label>Report period</label>
        <DateRangePicker
          onSelect={onSelectPeriod}
          onEndSelect={onEndSelect}
          onStartSelect={onStartSelect}
          options={periodOptions}
          selected={periodSelected}
          endDate={endDate}
          endHasError={errors.endDate}
          startDate={startDate}
          startHasError={errors.startDate}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.printOptions}>
        <label>View options</label>
        <div className={Styles.options}>
          <span className={Styles.option} data-name="showPercentage" onClick={toggleOption}>
            Show percentage
            <div className={Styles.toggleContainer}>
              <SlidingToggle
                scope="showPercentage"
                name="showPercentage"
                selected={showPercentage === undefined ? true : showPercentage}
              />
            </div>
          </span>
          <span className={Styles.option} data-name="showHours" onClick={toggleOption}>
            Show hours
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="showHours" name="showHours" selected={showHours === undefined ? true : showHours} />
            </div>
          </span>
          <span
            className={Styles.option}
            onClick={(e) => {
              e.preventDefault();
              onAttorneysOnly(!attorneysOnly);
            }}
          >
            {capitalize(t('solicitors'))} only
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="attorneysOnly" name="attorneysOnly" selected={attorneysOnly} />
            </div>
          </span>
        </div>
      </div>
      <div className={Styles.separator} />
    </div>
  );
};

IncomeByAttorneyFilter.displayName = 'IncomeByAttorneyFilter';

export default IncomeByAttorneyFilter;
