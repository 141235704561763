'use strict';

/**
 * @typedef { import('../types').Activity } Activity
 * @typedef { import('../types').ActivityFilter } ActivityFilter
 * @typedef { import('../types').ActivityGroup } ActivityGroup
 */

const { groupActivitiesByCategoryLabel } = require('./group-activities-by-category-label');

/**
 * Given a flat array of Activity objects, this function will return an array of {@link ActivityGroup}.
 *
 * NOTE: The expected Activity structure is the GraphQL Activity structure. Passing legacy cache
 * activity entities will lead to unexpected behaviour.
 *
 * @param {Object} params
 * @param {Array<Activity>} params.activities
 * @param {ActivityFilter} [params.filter]
 * @returns {Array<ActivityGroup>}
 */
const convertToActivityGroupArray = ({ activities, filter = {} }) => {
  const activitiesByTypeLabel = groupActivitiesByCategoryLabel({ activities, filter });

  return Object.entries(activitiesByTypeLabel).reduce((acc, [activityGroupName, activitiesInGroup]) => {
    /** @type ActivityGroup */
    const activityGroup = { group: activityGroupName, activities: activitiesInGroup };
    // @ts-ignore
    acc.push(activityGroup);
    return acc;
  }, []);
};

module.exports = {
  convertToActivityGroupArray,
};
