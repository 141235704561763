import { WebQueryMattersDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';

export const clientSdkGetMatter = async (matterId: string, needsReload = false) => {
  const apolloClient = getApolloClient();
  const mattersData = await apolloClient.query({
    query: WebQueryMattersDocument,
    variables: { matterIds: [matterId] },
    fetchPolicy: needsReload ? 'network-only' : 'cache-first',
  });

  const matters = mattersData?.data?.mattersWq;

  if (!matters || !matters[0]) {
    return null;
  }

  const matter = matters[0];

  return {
    id: matter?.id,
    number: matter?.matterNumber,
    status: matter?.status,
    matterTypeId: matter?.matterTypeId,
    // clientIds: (matter?.clients || []).map((c) => c.id), // lost this shifting to webquery
    // otherSideIds: (matter?.otherSide || []).map((o) => o.id),  // lost this shifting to webquery
    isLead: matter?.isLead,
    reLine: matter?.description,
    descriptionAutomation: matter?.descriptionAutomation,
  };
};
