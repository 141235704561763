import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import { BANK_BALANCE_TYPE } from '@sb-billing/redux/bank-account-settings';
import { Button, Spinner, forms2PropTypes } from '@sb-itops/react';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import Styles from './BankAccountSettings.module.scss';

const BankAccountSettings = ({
  bankBalanceType,
  isMatterBalanceType,
  confirmProceed,
  formDirty,
  formInitialised,
  formValid,
  formSubmitting,
  onSave,
  onFieldValueUpdated,
}) => {
  if (!formInitialised) {
    return null;
  }

  const onSaveClick = async (isMatter) => {
    try {
      await onSave(isMatter);
      displaySuccessToUser('Bank Account settings saved successfully');
    } catch (err) {
      displayErrorToUser('Failed to save Bank Account settings');
    }
  };

  return (
    <div className={Styles.bankAccountSetttingsPanel}>
      <h3>Bank Account Settings</h3>
      {bankBalanceType === BANK_BALANCE_TYPE.matterContact ? (
        <>
          <p className="warning">WARNING! This setting cannot be undone once saved</p>
          <p>
            Manage funds as a matter balance only. Funds will not be tracked by contact. Do not use if split payments
            are required.
          </p>
          <form name="BankAccountSettingsForm" className="main-form">
            <div className="form-group">
              <label>
                <input
                  name="isMatterBalanceType"
                  className="row-checkbox"
                  type="checkbox"
                  checked={isMatterBalanceType.value}
                  onChange={(e) => {
                    onFieldValueUpdated('isMatterBalanceType', e.target.checked);
                  }}
                />
                Deposit funds on the matter only
              </label>
            </div>
            <div className="form-group">
              <label>
                <input
                  name="confirmProceed"
                  type="text"
                  value={confirmProceed.value}
                  className={classnames('form-control')}
                  onChange={(e) => onFieldValueUpdated('confirmProceed', e.target.value)}
                />
                Type PROCEED to confirm
              </label>
            </div>
            <div className="button-bar">
              <Button
                onClick={() => onSaveClick(isMatterBalanceType.value)}
                disabled={!formValid || formSubmitting || !formDirty}
              >
                {formSubmitting && <Spinner small />} Save
              </Button>
            </div>
          </form>
        </>
      ) : (
        <div>Funds are being managed as a matter balance. Split payments are not possible.</div>
      )}
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

BankAccountSettings.propTypes = {
  onFieldValueUpdated: PropTypes.func,
  onSave: PropTypes.func,
  isMatterBalanceType: Forms2Field,
  confirmProceed: Forms2Field,
  formSubmitting: PropTypes.bool,
  bankBalanceType: PropTypes.any,
  formDirty: PropTypes.bool,
  formValid: PropTypes.bool,
  formInitialised: PropTypes.bool,
};

BankAccountSettings.defaultProps = {
  onFieldValueUpdated: undefined,
  onSave: undefined,
  isMatterBalanceType: undefined,
  confirmProceed: undefined,
  formSubmitting: false,
  bankBalanceType: undefined,
  formDirty: false,
  formValid: false,
  formInitialised: false,
};

export default BankAccountSettings;
