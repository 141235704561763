export const allItemsExpanded = (state) => state.allItemsExpanded;
export const itemIsExpanded = (state, { id }) => state.expandedItems[id];
export const getNumberOfItemsExpanded = (state) => state.numberItemsExpanded;
export const getNumberOfItems = (state) => state.numberItemsTracked;
export const getExpandedItems = (state) => Object.keys(state.expandedItems).filter((key) => state.expandedItems[key]);
export const getCollapsedItems = (state) => Object.keys(state.expandedItems).filter((key) => !state.expandedItems[key]);
export const getCollapsedItemsMap = (state) =>
  Object.keys(state.expandedItems).reduce((acc, key) => {
    if (!state.expandedItems[key]) {
      acc[key] = true;
    }
    return acc;
  }, {});
