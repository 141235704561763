/**
 * Once firm trust cheques have been changed to LOD, this file and react/redux ChequeTable can be deleted
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Spinner, useTranslation, VerticalSeparator } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';

import { ChequeTable } from '../../components/cheque-table';
import Styles from './BillingAccountsTransactionsTrustCheques.module.scss';

const BillingAccountsTransactionsTrustCheques = React.memo(
  ({
    cheques,
    isGeneratingPdf,
    printDialogIframeId,
    showSystemDate,
    showDeletedTransactions,
    showChequeMemo,
    unprintedCheques,
    // callbacks
    onClickLink,
    onClickRow,
    onExportAsCsv,
    onExportAsPdf,
    onShowPrintChequesModal,
    onPrintCheque,
    // sorting
    onSort,
    sortBy,
    sortDirection,
  }) => {
    const { t } = useTranslation();
    const shownUnprintedCheques = unprintedCheques.filter((cheque) => !cheque.isReversed);
    const shownCheques = cheques.filter((cheque) => (cheque.isDeleted ? showDeletedTransactions : true));

    return (
      <div className={classnames('panel-body', Styles.trustChequeRoute)}>
        <div className="ribbon panel panel-primary">
          <Button onClick={onShowPrintChequesModal} disabled={shownUnprintedCheques.length === 0}>
            Print {t('cheques')} ({shownUnprintedCheques.length})
          </Button>
          {featureActive('BB-6412') && (
            <>
              <VerticalSeparator />
              <Button className={Styles.download} onClick={onExportAsPdf} disabled={isGeneratingPdf}>
                {isGeneratingPdf && <Spinner small />}
                Print
              </Button>
              <Button className={Styles.download} onClick={onExportAsCsv}>
                CSV
              </Button>
            </>
          )}
        </div>
        <ChequeTable
          type="Trust"
          showSystemDate={showSystemDate}
          showDeletedTransactions={showDeletedTransactions}
          showChequeMemo={showChequeMemo}
          onClickLink={onClickLink}
          onClickRow={onClickRow}
          onPrintCheque={onPrintCheque}
          onSort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          cheques={shownCheques}
          showDescription
        />
        <div className={Styles.printPdfIframeContainer}>
          <iframe id={printDialogIframeId} title="smokeball-report-viewer" />
        </div>
      </div>
    );
  },
);

BillingAccountsTransactionsTrustCheques.displayName = 'BillingAccountsTransactionsTrustCheques';

BillingAccountsTransactionsTrustCheques.propTypes = {
  cheques: PropTypes.array,
  isGeneratingPdf: PropTypes.bool,
  printDialogIframeId: PropTypes.string.isRequired,
  showSystemDate: PropTypes.bool,
  showDeletedTransactions: PropTypes.bool,
  showChequeMemo: PropTypes.bool.isRequired,
  unprintedCheques: PropTypes.array,
  // callbacks
  onClickLink: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onExportAsPdf: PropTypes.func.isRequired,
  onExportAsCsv: PropTypes.func.isRequired,
  onPrintCheque: PropTypes.func.isRequired,
  onShowPrintChequesModal: PropTypes.func,
  // sorting
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
};

BillingAccountsTransactionsTrustCheques.defaultProps = {
  cheques: undefined,
  isGeneratingPdf: false,
  showSystemDate: false,
  showDeletedTransactions: false,
  unprintedCheques: undefined,
  // callbacks
  onShowPrintChequesModal: undefined,
  // sorting
  sortBy: undefined,
  sortDirection: undefined,
};

export default BillingAccountsTransactionsTrustCheques;
