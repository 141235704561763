import { getList, getPseudoStatusById } from '@sb-billing/redux/invoices';
import { getInvoiceCorrespondenceHistoryGroupedByDebtor } from '@sb-billing/redux/correspondence-history';
import { getById as getMatterById, getMatterDisplayById } from '@sb-matter-management/redux/matters'
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { findActivePaymentPlanByDebtorId } from '@sb-billing/redux/payment-plans/selectors';
import { getList as getAllPaymentPlans } from '@sb-billing/redux/payment-plans';
import { statusByName as invoiceStatusByName, statusByValue as invoiceStatusByValue } from '@sb-billing/business-logic/invoice/entities';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';

export const loadInvoiceListData = ({ filterFn, statusFilterFn, getInvoiceTotalsFn }) => {

  return getList().reduce((acc, invoiceSummary) => {
    const { currentVersion } = invoiceSummary;
    // If the invoice doesn't pass the filtering criteria, nothing else to do.
    if (!filterFn(invoiceSummary)) {
      return acc;
    }

    // Update count by status.
    const pseudoStatus = getPseudoStatusById(invoiceSummary.invoiceId);
    acc.countByInvoiceStatus[pseudoStatus] = (acc.countByInvoiceStatus[pseudoStatus] || 0) + 1;

    // Invoice status is a special kind of filter that we apply only after the counts have been updated.
    if (!statusFilterFn(invoiceSummary)) {
      return acc;
    }

    const debtorIds = currentVersion.debtors.map(debtor => debtor.id);
    const correspondenceHistoryByDebtor = getInvoiceCorrespondenceHistoryGroupedByDebtor(invoiceSummary.invoiceId, debtorIds) || {};
    const aggregateSentStatus = determineIndicatorStatus(Object.values(correspondenceHistoryByDebtor), currentVersion.debtors.length);

    // payment plan can only be created for invoices with single debtor
    const debtorId = currentVersion.debtors[0].id;
    const paymentPlan = currentVersion.debtors &&
      currentVersion.debtors.length === 1 &&
      findActivePaymentPlanByDebtorId(getAllPaymentPlans(), {
        debtorId,
      });


    const debtorDisplay = debtorIds.map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true })).join(' | ');

    // Add extra fields.
    const invoice = {
      ...invoiceSummary,
      pseudoStatus,
      matter: getMatterById(invoiceSummary.matterId),
      matterDisplay: getMatterDisplayById(invoiceSummary.matterId),
      debtorIds,
      debtorDisplay, // used for sorting & tooltip
      user: getPersonByUserId(currentVersion.userId),
      aggregateSentStatus,
      ...getInvoiceTotalsFn(invoiceSummary.invoiceId),
      paymentPlanId: (paymentPlan && paymentPlan.invoiceIds.includes(invoiceSummary.invoiceId) && paymentPlan.id) || undefined,
      hasUnpaidAD: hasUnpaidAnticipatedDisbursements({ invoice: currentVersion, getExpenseById, getExpensePaymentDetailsById }),
    };

    // Voided invoices should not have any unpaid amount
    if (pseudoStatus === invoiceStatusByValue[invoiceStatusByName.VOID]) {
      invoice.unpaid = 0;
      invoice.unpaidExcInterest = 0;
    }

    // Update the summary fields.
    acc.summary.totalExcInterest += invoice.totalExcInterest;
    acc.summary.interest += invoice.interest;
    acc.summary.paid += invoice.paid;
    acc.summary.unpaid += invoice.unpaid;
    acc.summary.paidByCredit += invoice.paidByCredit;

    // Add the invoice to the result.
    acc.invoices.push(invoice);
    acc.invoicesById[invoice.invoiceId] = invoice;

    return acc;
  }, {
    invoices: [],
    invoicesById: {},
    countByInvoiceStatus: {},
    summary: {
      totalExcInterest: 0,
      interest: 0,
      paid: 0,
      unpaid: 0,
      paidByCredit: 0,
    }
  });
}

// START HASTILY DEVELOPED CODE THAT LIKELY BELONGS IN BUSINESS LOGIC
const emailStatuses = {
  NOT_SENT: -1,
  SUCCESS: 1,
  ERROR: 2,
  IN_PROGRESS: 0,
};

const aggregateStatuses = {
  SUCCESS: 1,
  PARTIALLY_SENT: 2,
  ERROR: 3,
  IN_PROGRESS: 4,
  NOT_SENT: 5,
};

// This function figures out the overall status for the indicator icon
// based on each of the individual email statuses for each debtor.
const determineIndicatorStatus = (debtorEmailStatuses, numberOfContacts) => {
  if (debtorEmailStatuses.length === 0) {
    return aggregateStatuses.NOT_SENT;
  }

  let overallStatus;
  let debtorIndex = 0;

  while (debtorIndex < debtorEmailStatuses.length && overallStatus !== aggregateStatuses.ERROR) {
    const { status } = debtorEmailStatuses[debtorIndex];

    switch (status) {
      case emailStatuses.ERROR:
        // If any of the statuses are an error, the overall is in error.
        // This will break the loop.
        overallStatus = aggregateStatuses.ERROR;
        break;

      case emailStatuses.IN_PROGRESS:
        // If any of the statuses are in progress, the overall status is also in progress.
        // We cannot break the loop based on hitting this overall status, as an error status
        // might follow.
        overallStatus = aggregateStatuses.IN_PROGRESS;
        break;

      case emailStatuses.NOT_SENT:
        // If all of the statuses are not sent, then we treat the overall status as not sent.
        // If statuses until now have been successful, we have an overall status of partial success.
        if (!overallStatus) {
          overallStatus = aggregateStatuses.NOT_SENT;
        } else if (overallStatus === aggregateStatuses.SUCCESS) {
          overallStatus = aggregateStatuses.PARTIALLY_SENT;
        }
        break;

      case emailStatuses.SUCCESS:
        // Other statuses overwrite success, so it's safe to set success as the overall
        // status so long as no status has been set yet.
        if (!overallStatus) {
          overallStatus = aggregateStatuses.SUCCESS;
        }
        break;

      default:
        throw new Error(`Unexpected email status ${status}`);
    }

    debtorIndex += 1;
  }

  return overallStatus === aggregateStatuses.SUCCESS && debtorEmailStatuses.length !== numberOfContacts
    ? aggregateStatuses.PARTIALLY_SENT
    : overallStatus;
};

// END HASTILY DEVELOPLED CODE THAT BELONGS IN BUSINESS LOGIC
