import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyInput2, IconButton } from '@sb-itops/react';
import { Radio, RadioList } from '@sb-itops/react/radio-list';
import { surchargeTypeValues } from '@sb-billing/business-logic/invoice-surcharge';
import { BasisPointInput } from '@sb-billing/react/basis-point-input';

import Styles from './SurchargeOptions.module.scss';

export const SurchargeOptions = ({
  surchargeType,
  fixedSurcharge,
  percentageBp,
  onConfirm,
  onDiscard,
  onSurchargeTypeChange,
  onFixedSurchargeChange,
  onPercentageBpChange,
  disableConfirm,
}) => (
  <div className={Styles.surchargeOptions}>
    <div className={Styles.contentContainer}>
      <RadioList selected={surchargeType} name="surcharge" direction="column" onChange={onSurchargeTypeChange}>
        <Radio value={surchargeTypeValues.FIXED}>
          <CurrencyInput2
            name="amount"
            className={Styles.inputGroup}
            value={fixedSurcharge}
            onChange={onFixedSurchargeChange}
            min={0.01}
          />
        </Radio>
        <Radio value={surchargeTypeValues.PERCENTAGE}>
          <div className={classnames('input-group', Styles.inputGroup)}>
            <BasisPointInput
              basisPoints={percentageBp}
              disabled={surchargeType !== surchargeTypeValues.PERCENTAGE}
              isPerAnnum={false}
              onChange={onPercentageBpChange}
            />
          </div>
        </Radio>
      </RadioList>
    </div>
    <div className={Styles.buttonInlineContainer}>
      <IconButton onClick={onConfirm} icon="icon icon-check-circle-2" round disabled={disableConfirm} />
      <span className={Styles.buttonMargin}>
        <IconButton onClick={onDiscard} icon="icon icon-close-x-circle" round />
      </span>
    </div>
  </div>
);

SurchargeOptions.displayName = 'SurchargeOptions';
SurchargeOptions.propTypes = {
  fixedSurcharge: PropTypes.number,
  percentageBp: PropTypes.number,
  surchargeType: PropTypes.number,
  onConfirm: PropTypes.func,
  onDiscard: PropTypes.func,
  onSurchargeTypeChange: PropTypes.func,
  onFixedSurchargeChange: PropTypes.func,
  onPercentageBpChange: PropTypes.func,
  disableConfirm: PropTypes.bool,
};

SurchargeOptions.defaultProps = {
  fixedSurcharge: 0,
  percentageBp: 0,
  surchargeType: 0,
  onConfirm: () => {},
  onDiscard: () => {},
  onSurchargeTypeChange: () => {},
  onFixedSurchargeChange: () => {},
  onPercentageBpChange: () => {},
  disableConfirm: false,
};
