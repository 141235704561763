'use strict';

const defaultEmailBody = ({ showSummaryBox, allowEmailAttachment }) => `
<p>Dear [DEBTOR_NAME],</p>
<p><br></p>
<p>Please see ${
  allowEmailAttachment ? 'attached' : 'linked'
} invoice, for [MATTER_TITLE]. A total of [AMOUNT_DUE] is now due by [DATE_DUE].</p>
<p><br></p>${showSummaryBox ? '<p>[SUMMARY_BOX]</p><p><br></p>' : ''}
<p>Thanks,</p>
<p>[USER_NAME]</p>
<p>[FIRM_NAME]</p>
<p>[PHONE_NUMBER]</p>`;

const invoiceEmailTemplateDefaults = ({ showSummaryBox, allowEmailAttachment }) => ({
  emailSubject: 'Your New Invoices - [FIRM_NAME]',
  emailBody: defaultEmailBody({ showSummaryBox, allowEmailAttachment }),
  sendCopyToUser: true,
  doNotSendInvoiceAttachment: false,
});

module.exports = {
  invoiceEmailTemplateDefaults,
};
