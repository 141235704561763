'use strict';

const titleLineType = {
  None: 'None',
  MatterDescription: 'MatterDescription',
  MatterTitle: 'MatterTitle',
  Custom: 'Custom',
};

module.exports = {
  fonts: [
    { label: 'Arvo', value: 'Arvo' },
    { label: 'Lato', value: 'Lato' },
    { label: 'Merriweather', value: 'Merriweather' },
    { label: 'Open Sans', value: 'Open Sans' },
    { label: 'Roboto', value: 'Roboto' },
    { label: 'Slabo', value: 'Slabo 27px' },
  ],
  firmNameFontSizes: [
    { label: '8pt', value: '8pt' },
    { label: '10pt', value: '10pt' },
    { label: '12pt', value: '12pt' },
    { label: '14pt', value: '14pt' },
    { label: '16pt', value: '16pt' },
    { label: '18pt', value: '18pt' },
  ],
  defaultPageMargins: { top: 1, right: 1, bottom: 1, left: 1, unit: 'in' },
  maxLogoSize: 3145728, // 3MB
  minLogoDimension: 300, // 300*300 pixel
  entryDisplayOptions: {
    list: 'LIST',
    summary: 'SUMMARY',
    summaryWithList: 'SUMMARYWITHLIST',
  },
  lineItemConfig: {
    0: 'list',
    1: 'summary',
    2: 'summaryWithList',
  },
  titleLine1Type: titleLineType,
  titleLine1TypeByValue: {
    0: titleLineType.None,
    1: titleLineType.MatterDescription,
    2: titleLineType.Custom,
    3: titleLineType.MatterTitle,
  },
  titleLine1TypeById: {
    [titleLineType.None]: 0,
    [titleLineType.MatterDescription]: 1,
    [titleLineType.Custom]: 2,
    [titleLineType.MatterTitle]: 3,
  },
  titleLine2Type: titleLineType,
  titleLine2TypeByValue: {
    0: titleLineType.None,
    1: titleLineType.MatterDescription,
    2: titleLineType.Custom,
    3: titleLineType.MatterTitle,
  },
  titleLine2TypeById: {
    [titleLineType.None]: 0,
    [titleLineType.MatterDescription]: 1,
    [titleLineType.Custom]: 2,
    [titleLineType.MatterTitle]: 3,
  },
  firmDetailsMaxLines: 7,
  footerTextMaxLines: 30,
};
