import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, FloatingCard, useTranslation } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import Styles from '../BillingSupportAccountingTransactions.module.scss';

const log = getLogger('BillingSupportAccountingTransactionsDetail');

export const BillingSupportAccountingTransactionsDetail = React.memo(
  ({ hasResentSingle, transaction, isResending, onCloseDetail, onResend }) => {
    const { t } = useTranslation();
    const transactionJsonFormatted = JSON.stringify(transaction, replacer, 4);
    const copyJsonToClipboard = () => {
      navigator.clipboard
        .writeText(transactionJsonFormatted)
        .then(
          () => {
            messageDisplay.success(`JSON copied to clipboard.`);
            return undefined;
          },
          (error) => {
            messageDisplay.error('Could not copy JSON to clipboard, error:', error);
          },
        )
        .catch((err) => log.error(err));
    };
    return (
      <div>
        <FloatingCard>
          <div className={Styles.headerContainer}>
            <h3>{transaction.correlationId}</h3>
            <button type="button" onClick={copyJsonToClipboard} className={Styles.copyButton}>
              Copy <i className={classnames('icon icon-arrange-2', Styles.copyIcon)} />
            </button>
          </div>
          <div className={classnames('row', Styles.codeBlock)}>
            <p>{transactionJsonFormatted}</p>
          </div>
          {transaction.groups.map((group) =>
            renderGroup({
              group,
              t,
              smokeballId: transaction.correlationId,
              integrationName: transaction.integrationName,
            }),
          )}
          <div className={Styles.headerContainer}>
            <Button className={Styles.closeContainer} onClick={onCloseDetail}>
              Close
            </Button>
            <Button
              className={Styles.closeContainer}
              onClick={() => onResend({ correlation: transaction, isBatch: false })}
              disabled={!transaction.isFailed || isResending || hasResentSingle}
              locked={isResending}
            >
              {hasResentSingle ? 'Sent' : 'Resend'}
            </Button>
          </div>
        </FloatingCard>
      </div>
    );
  },
);

const replacer = (key, value) => {
  if (key === 'exceptionDetails' || key === 'exceptionMessage') {
    if (isJSON(value)) {
      return JSON.parse(value);
    }
  }
  return value;
};

function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

const renderErrorMessage = (exceptionMessage, exceptionDetail) => {
  if (exceptionMessage && exceptionDetail) {
    return (
      <>
        {exceptionMessage} <br /> {exceptionDetail}
      </>
    );
  }
  return exceptionMessage || exceptionDetail || 'None';
};

const renderGroup = ({ group, t, smokeballId, integrationName }) => (
  <React.Fragment key={group.groupIdentifier}>
    <h4>Group</h4>
    <table className={Styles.groupTable}>
      <tbody>
        <tr>
          <td className={Styles.header}>Entity ID</td>
          <td>{group.operationType}</td>
          <td className={Styles.header}>Smokeball ID</td>
          <td>{smokeballId}</td>
        </tr>
        <tr>
          <td className={Styles.header}>Reversal</td>
          <td>{group.isReversal.toString()}</td>
          <td className={Styles.header}>Integration</td>
          <td>{integrationName}</td>
        </tr>
        <tr>
          <td className={Styles.header}>Transaction Date</td>
          <td>{t('date', { yyyymmdd: group.transactionDate })}</td>
          <td className={Styles.header}>Last Updated</td>
          <td>{t('date', { unix: group.lastUpdated, format: 'YYYY-MM-DD hh:mm:ss' })}</td>
        </tr>
        <tr>
          <td colSpan="4" className={Styles.itemHeader}>
            Items
          </td>
        </tr>
        {group.items.map((item) => (
          <React.Fragment key={item.id}>
            <tr>
              <td className={Styles.header}>Timestamp</td>
              <td>{t('date', { ts: item.timestamp, format: 'YYYY-MM-DD hh:mm:ss' })}</td>
              <td className={Styles.header}>Integration ID</td>
              <td>{item.integrationId || 'Null'}</td>
            </tr>
            <tr>
              <td className={Styles.header}>Error</td>
              <td colSpan="4">{renderErrorMessage(item.exceptionMessage, item.exceptionDetail)}</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </React.Fragment>
);

BillingSupportAccountingTransactionsDetail.displayName = 'BillingSupportAccountingTransactions';

BillingSupportAccountingTransactionsDetail.propTypes = {
  transaction: PropTypes.object.isRequired,
  hasResentSingle: PropTypes.bool.isRequired,
  isResending: PropTypes.bool.isRequired,
  onCloseDetail: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
};

BillingSupportAccountingTransactionsDetail.defaultProps = {};
