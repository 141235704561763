import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './NumberInput.module.scss';

const NumberInput = (props) => {
  const { className, disabled, hasError, max, min, name, onBlur, onChange, onFocus, placeholder, step, value } = props;

  const changeHandler = (handler) => (e) => {
    let newValue;
    if (e.target.value === '') {
      // When clearing the input, by default the result will be an empty string
      // This may cause problems with propTypes expecting a number.
      // We may not want to reset the value to 0 either, as the user will likely
      // want to input their own value.
      newValue = undefined;
    } else {
      newValue = parseFloat(e.target.value);

      // Min or max can be undefined as any checks with an undefined argument resolve to false
      if (newValue < min || newValue > max) {
        newValue = newValue < min ? min : max;
      }
    }

    // Update the value
    handler({
      ...e,
      target: {
        name,
        value: newValue,
      },
      currentTarget: {
        name,
        value: newValue,
      },
    });
  };

  const handleChange = changeHandler(onChange);
  const handleBlur = changeHandler(onBlur);

  // Removes the ability to change value using the keyboard up and down keys
  const handleKeyDown = (e) => {
    const key = e.charCode || e.keyCode;
    // Disable Up and Down Arrows on Keyboard
    if (key === 38 || key === 40) {
      e.preventDefault();
    }
  };

  // Removes the ability to change value using the scroll wheel
  const handleMouseWheel = (e) => {
    e.target.blur();
  };

  // Prevent the component from switching between being a controlled and uncontrolled input
  const currentValue = Number.isFinite(value) ? value : '';

  return (
    <input
      className={classnames(Styles.numberInput, className, hasError && Styles.hasError)}
      disabled={disabled}
      max={max}
      min={min}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      onWheel={handleMouseWheel}
      placeholder={placeholder}
      step={step || 0.01}
      type="number"
      value={currentValue}
    />
  );
};

NumberInput.displayName = 'NumberInput';

NumberInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  value: PropTypes.number,
};

NumberInput.defaultProps = {
  className: undefined,
  disabled: false,
  hasError: false,
  max: undefined,
  min: undefined,
  name: undefined,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  placeholder: undefined,
  step: undefined,
  value: undefined,
};

export default NumberInput;
