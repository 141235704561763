import {
  TOGGLE_SHOW_FILTERS,
  SET_FILTER,
  ADD_TO_VISIBLE_CLOSED_ID_MAP,
  REMOVE_FROM_VISIBLE_CLOSED_ID_MAP,
} from './types';

// So we dont break existing links to specific filters:
const url = new URLSearchParams(window.location.search);

const INITIAL_FILTER_STATE = {
  showFilters: true,
  filters: {
    showHidden: url.get('showHidden') || false,
  },
  visibleClosedIdMap: {},
};

const INITIAL_STATE = {
  ...INITIAL_FILTER_STATE,
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
    }
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case ADD_TO_VISIBLE_CLOSED_ID_MAP: {
      return {
        ...state,
        visibleClosedIdMap: { ...state.visibleClosedIdMap, [action.payload.cmaId]: true },
      };
    }
    case REMOVE_FROM_VISIBLE_CLOSED_ID_MAP: {
      // See https://stackoverflow.com/questions/34401098/remove-a-property-in-an-object-immutably
      const keyToRemove = action.payload.cmaId;
      const { [keyToRemove]: value, ...keyRemovedObj } = state.visibleClosedIdMap;
      return {
        ...state,
        visibleClosedIdMap: { ...keyRemovedObj },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
