import { getUsedAttorneyResponsibleIds } from '@sb-matter-management/redux/matters';

angular.module('sb.billing.webapp').controller('SbStaffMultiSelectController', function ($scope, sbMattersMbService, sbFirmManagementMbService) {
  'use strict';
  const that = this;

  that.view = { selected: [] };

  $scope.$on('smokeball-data-update-sbFirmManagementMbService', update);
  $scope.$watch('setSelected', () => {
    if($scope.setSelected) {
      that.view.selected = _.isArray($scope.setSelected) ? $scope.setSelected : [$scope.setSelected];
    }
  });

  update();

  function update() {
    that.view.staff = sbFirmManagementMbService.getStaffSummaries();

    if ($scope.onlyUsed) {
      const restrict = getUsedAttorneyResponsibleIds();
      that.view.staff = _.filter(that.view.staff, (member) => restrict.indexOf(member.id) !== -1);
    }

    that.view.staff.push({id: null, name: 'None', initials: 'None'});
  }

  that.selectAllStaff = () => {
    that.view.selected = _.pluck(that.view.staff, 'id');

    $scope.onSelect({idList: [...that.view.selected]});
  };

  that.clearSelectedStaff = () => {
    that.view.selected = [];
    $scope.onSelect({idList: [...that.view.selected]});
  };

  that.selectStaff = (id) => {
    if (that.view.selected.indexOf(id) !== -1) {
      _.pull(that.view.selected, id);
    } else {
      that.view.selected = [...that.view.selected, id];
    }

    $scope.onSelect({idList: [...that.view.selected]});
  };

});
