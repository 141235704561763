import composeHooks from '@sb-itops/react-hooks-compose';
import { getPersonByUserId, getStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';

interface IDocumentListContainerProps {
  entities?: {
    matterId: string;
    matter: {
      matterNumber: string;
      otherSideDisplay: string;
      description: string;
      status: string;
      clientDisplay: string;
      matterType: {
        name: string;
      };
    };
  }[];
  searchMeetsMinLength: boolean;
  onClickLink: (data: { type: string; id: string }) => void;
}

const hooks = (props: IDocumentListContainerProps) => ({
  useDocumentsTabStore: () => {
    const matterNames = {};

    if (props.entities?.length) {
      props.entities.forEach((e) => {
        matterNames[e.matterId] = getMatterDisplay(e.matter, e.matter?.matterType?.name || '');
      });
    }

    return {
      getPersonByUserId,
      getStaffByPersonId,
      matterNames,
    };
  },
});

export const DocumentListContainer = composeHooks(hooks);
