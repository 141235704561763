'use strict';

const { getAllConsolidatedOperatingTransactions } = require('@sb-billing/redux/transactions');

//TODO change this this file to convert the items into 'ListItem's rather than 'Transactions'

angular.module('sb.billing.webapp').component('sbDataOperatingRetainerByFilters', {
  require: { composer: '^sbCompose' },
  bindings: { entityDataKey: '@', contactId: '<?', matterId: '<?', showHidden: '<?' },
  controller: function ($scope, sbLoggerService, sbPaymentService, sbLocalisationService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDataOperatingRetainerByFilters');
    const entityDataKey = that.entityDataKey || 'transactions';

    that.$onChanges = () => {
      update();
    };

    $scope.$on('smokeball-data-update-sbTransactionService', () => {
      update();
    });

    $scope.$on('smokeball-data-update-sbPaymentService', () => {
      update();
    });

    $scope.$on('funds-deposited', () => {
      update();
    });

    const validTransactionTypes = ['Deposit', 'DepositReversal', 'VendorPayment', 'VendorPaymentReversal', 'Transfer'];

    const rules = [
      txn => that.matterId ? (txn.matterId === that.matterId || txn.matterIds.includes(that.matterId)) : true,
      txn => that.contactId ? (txn.contactId === that.contactId || txn.contactIds.includes(that.contactId)) : true,
      txn => validTransactionTypes.includes(txn.type),
      txn => !/^Payment for Invoices:/.test(txn.description) // dont include these transactions - TODO write documentation why
    ];

    const isOperatingAccountId = (a) => a.toLowerCase().includes('operating');
    const forSelectedMatterId = (p) => that.matterId ? p.matterId === that.matterId : true;
    const forSelectedContactId = (p) => that.contactId ? p.payorId === that.contactId : true;
    const fromOperatingRetainer = (p) => p.sourceAccountId && isOperatingAccountId(p.sourceAccountId);
    const paymentInvoicesCount = (p) => (p.invoices && p.invoices.length) || 0;
    const txnTimestamp = (p, r) => r ? moment(p.timestamp).add(1, 'seconds').toISOString() : p.timestamp;
    const paymentLabel = (p) => isOverPayment(p) ? 'over payment' : 'payment';
    const accountLabel = (p) => isOverPayment(p) ? `accountId:${p.overpaymentAccountId || p.destinationAccountId} Retainer` : `accountId:${p.destinationAccountId}`;

    function getTransactions() {
      const transactions = getAllConsolidatedOperatingTransactions({ showHidden: that.showHidden, matterId: that.matterId, contactId: that.contactId, t: sbLocalisationService.t });
      return transactions.filter(t => rules.every(r => r(t)));
    }

    function getPayments() {
      const f = p => forSelectedMatterId(p) && forSelectedContactId(p) && fromOperatingRetainer(p);
      return sbPaymentService.getPaymentsByFilter(f) || [];
    }

    function getPaymentInvoicesTotal(p) {
      return paymentInvoicesCount(p) && p.invoices.map(i => i.amount).reduce((t, a) => t + a);
    }

    function isOverPayment(p) {
      return (!p.overpaymentAccountId || isOperatingAccountId(p.overpaymentAccountId)) && p.totalAmount > getPaymentInvoicesTotal(p);
    }

    function getOverPayments() {
      const f = p => forSelectedMatterId(p) && forSelectedContactId(p) && isOverPayment(p);
      return sbPaymentService.getPaymentsByFilter(f) || [];
    }

    function getInvoicesDesc(p) {
      const invoiceLabel = () => paymentInvoicesCount(p) > 1 ? 'invoices' : 'invoice';
      const invoiceGuids = () => paymentInvoicesCount(p) && p.invoices.map(i => `#invoiceId:${i.invoiceId}, `);
      return _.trimRight(`${invoiceLabel()} ${invoiceGuids()}`, ', ');
    }

    function getTxnDesc(p) {
      return `Transfer to ${accountLabel(p)} for ${paymentLabel(p)} of ${getInvoicesDesc(p)}`;
    }

    function getTxnAmount(p, reversed = false) {
      const invoicesTotal = getPaymentInvoicesTotal(p);
      const total = isOverPayment(p) ? p.totalAmount - invoicesTotal : -invoicesTotal;
      return reversed ? -1 * total : total;
    }

    function paymentToTransaction(payment, reversed) {
      return {
        generatedFrom:     'PAYMENT',
        isOverpayment:    isOverPayment(payment),
        id:               payment.paymentId,
        accountId:        payment.accountId || (payment.sourceAccountId && payment.sourceAccountId.split('/')[0]),
        bankAccountId:    payment.sourceAccountId || payment.destinationAccountId,
        matterId:         payment.matterId,
        contactId:        payment.payorId,
        paymentId:        payment.paymentId,
        reference:        payment.reference,
        note:             reversed ? payment.reversalReason : payment.note,
        source:           payment.source,
        userId:           reversed ? payment.reversalUserId : payment.userId,
        effectiveDate:    reversed ? moment(payment.reversedAt).format('YYYYMMDD') : payment.effectiveDate,
        lastUpdated:      payment.lastUpdated,
        amount:           getTxnAmount(payment, reversed),
        timestamp:        txnTimestamp(payment, reversed), //make reversed transaction timestamp larger
        description:      `${reversed ? 'Reversal: ' : ''}${getTxnDesc(payment)}`,
        type:             `${reversed ? 'InvoicePaymentReversal' : 'InvoicePayment'}`,
        bankAccountType:  'Operating',
        depositSlipId:    null,
        reconciliationId: null,
        drawerBank:       null,
        reversed,
        isHidden:         payment.isHidden,
      };
    }

    function paymentToTransactions(payment) {
      if (payment.reversedAt) {
        return [
          paymentToTransaction(payment, true),
          paymentToTransaction(payment, false)
        ];
      }

      return [
        paymentToTransaction(payment, false)
      ];
    }

    function update() {
      const showHiddenEnabled = that.showHidden;
      const transactions = getTransactions()
        .concat(_.flatten(getPayments().map(paymentToTransactions)))
        .concat(_.flatten(getOverPayments().map(paymentToTransactions)))
        .filter((tx) => !showHiddenEnabled ? !tx.isHidden : true)
      log.info('transactions : ', transactions);
      that.composer.setComposeData({dataType: entityDataKey, data: transactions}, entityDataKey);
    }
  }
});
