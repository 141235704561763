import { defaultPath } from './default-path';

export const SET_ITEMS = `${defaultPath}/SET_ITEMS`;
export const TOGGLE_ALL_ITEMS = `${defaultPath}/TOGGLE_ALL_ITEMS`;
export const EXPAND_ALL_ITEMS = `${defaultPath}/EXPAND_ALL_ITEMS`;
export const COLLAPSE_ALL_ITEMS = `${defaultPath}/COLLAPSE_ALL_ITEMS`;
export const TOGGLE_ITEM = `${defaultPath}/TOGGLE_ITEM`;
export const EXPAND_ITEM = `${defaultPath}/EXPAND_ITEM`;
export const COLLAPSE_ITEM = `${defaultPath}/COLLAPSE_ITEM`;
export const RESET_STATE = `${defaultPath}/RESET_STATE`;
