'use strict';

const uuid = require('@sb-itops/uuid');
const { validateInvoiceEmailRequest } = require('./validate-invoice-email-request');

const convertInvoiceEmailRequestToEmailDetail = (
  invoiceEmailRequest,
  { requireDebtorId = false, includeCorrespondenceId = true },
) => {
  validateInvoiceEmailRequest(invoiceEmailRequest, { requireDebtorId });

  return {
    invoiceId: invoiceEmailRequest.invoiceIds[0],
    sendTo: invoiceEmailRequest.template.toAddress,
    from: invoiceEmailRequest.template.replyToAddress,
    bcc: invoiceEmailRequest.template.bcc,
    subject: invoiceEmailRequest.template.subject,
    message: invoiceEmailRequest.template.message,
    contactIds: invoiceEmailRequest.debtorId ? [invoiceEmailRequest.debtorId] : [],
    correspondenceId: includeCorrespondenceId ? uuid() : undefined,
  };
};

module.exports = {
  convertInvoiceEmailRequestToEmailDetail,
};
