import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'xero',
  keyPath: 'accountId',
  ngCacheName: 'sbXeroService',
  syncType: syncTypes.ALL,
  immutable: false,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getOrganisationName = () => {
  const xero = getList()[0] || {}; // Only 1 record in this cache
  const accessToken = xero.accessToken || {};
  return accessToken.organisationName || '';
};

export const getIsXeroConnected = () => {
  const list = getList();

  if (list.length > 0) {
    const xero = list[0];

    if (xero && xero.accessToken) {
      return true;
    }
  }

  return false;
};
