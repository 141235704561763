import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes } from '../button';
import { ContextMenu } from '../context-menu';

import Styles from './AuthenticatorTokenForm.module.scss';

export const AuthenticatorTokenForm = memo(
  ({ authCode, tooltip, errorMessages, verifyInProgress, onAuthCodeUpdated, onVerify, onCancelVerify }) => (
    <div className={Styles.authenticatorTokenForm}>
      <fieldset disabled={verifyInProgress}>
        {/* AuthCode field */}
        <div className={Styles.formGroup}>
          <span className={Styles.decorator}>
            <i className="fa fa-fw fa-lock" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Two-Factor Code"
            value={authCode}
            onChange={(e) => onAuthCodeUpdated(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && onVerify({ authCode })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            maxLength={10}
            autoComplete="off"
          />
          <span className={Styles.actionDecorator} title={tooltip}>
            <ContextMenu
              // eslint-disable-next-line react/no-unstable-nested-components
              body={() => <div className={Styles.authenticationCodeTooltip}>{tooltip}</div>}
              position="bottom"
              tabIndex={0}
            >
              <i className="fa fa-question-circle" />
            </ContextMenu>
          </span>
        </div>

        {/* Error message display */}
        {errorMessages.length > 0 && (
          <div className={Styles.errors}>
            {errorMessages.map(({ message, isVerbose }, index) => (
              <div
                key={index}
                className={classnames('error-box', 'alert', 'alert-danger', !isVerbose && Styles.centreText)}
              >
                {message}
              </div>
            ))}
          </div>
        )}

        {/* Verify button */}
        <Button
          className={Styles.loginButton}
          onClick={() => onVerify({ authCode })}
          disabled={verifyInProgress}
          locked={verifyInProgress}
        >
          {!verifyInProgress && 'Verify'}
        </Button>

        {/* Cancel verify link */}
        <Button
          className={Styles.cancelLoginButton}
          type={buttonTypes.link}
          onClick={() => onCancelVerify()}
          disabled={verifyInProgress}
        >
          Cancel Login
        </Button>
      </fieldset>
    </div>
  ),
);

AuthenticatorTokenForm.displayName = 'AuthenticatorTokenForm';

AuthenticatorTokenForm.propTypes = {
  authCode: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  verifyInProgress: PropTypes.bool.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      isVerbose: PropTypes.bool,
    }),
  ),
  onAuthCodeUpdated: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  onCancelVerify: PropTypes.func.isRequired,
};

AuthenticatorTokenForm.defaultProps = {
  errorMessages: [],
};
