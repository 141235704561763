import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import {
  WidgetTasks,
  DashboardCalendarData,
  DashboardRecurringEventData,
  InitStaffSettings,
} from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import uuid from '@sb-itops/uuid';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { rrulestr } from 'rrule';
import { sortByProperty } from '@sb-itops/nodash';
import {
  DashboardCalendarDataDocument,
  DashboardRecurringEventsDocument,
  WidgetTasksDocument,
} from 'web/graphql/types/graphql';
import { nonNullishFieldsGuardFactory } from '@sb-itops/type-helpers';
import { sort as sortItems } from '@sb-itops/sort';
import { DailyDigestWidget } from './DailyDigestWidget';

const log = getLogger('tasks.widget');

interface Props {
  onClickLink: React.MouseEventHandler;
  matterId: string;
}

const useHooks = ({ matterId }: Props) => {
  const [selectedDay, setSelectedDay] = useState(moment().startOf('day'));
  const { data: staffData } = useCacheQuery(InitStaffSettings.query);

  const loggedInStaffId = staffData?.loggedInStaff?.id;
  const [selectedStaffIdRaw, setSelectedStaffId] = useState('');
  const selectedStaffId = selectedStaffIdRaw || loggedInStaffId;
  const [selectedTab, setSelectedTab] = useState(1);

  const staffMap = useMemo(() => {
    const data = staffData?.staffMembers;

    if (!data) {
      return {};
    }

    return data.reduce((acc, staffMemberEntity) => {
      Object.assign(acc, { [staffMemberEntity.id]: staffMemberEntity });

      return acc;
    }, {});
  }, [staffData?.staffMembers]);

  return {
    useActions: () => {
      const staffOptions = useMemo(() => {
        const data = staffData?.staffMembers;

        if (!data) {
          return [];
        }

        const options = data
          .filter((staffMemberEntity) => !staffMemberEntity.isFormerStaff)
          .map((staffMemberEntity) => {
            const { id, name, rate } = staffMemberEntity;

            return {
              value: id,
              label: name,
              entity: {
                ...staffMemberEntity,
                rate: rate || 0,
              },
            };
          });
        const sortedOptions = sortItems(options, ['label'], ['ASC']);

        return sortedOptions;
      }, [staffData?.staffMembers]);

      return {
        selectedTab,
        setSelectedTab,
        selectedStaffId,
        setSelectedStaffId,
        staffOptions,
        staffMap,
      };
    },
    useGraphQLforEvents: () => {
      const digestDay = moment(selectedDay).startOf('day');
      const endOfDay = moment(selectedDay).add(1, 'day').add(-1, 'second');
      const [showEventModal, setShowEventModal] = useState(false);
      const {
        data,
        loading: eventsLoading,
        error,
      } = useSubscribedQuery(
        { ...DashboardCalendarData, query: DashboardCalendarDataDocument },
        {
          variables: {
            eventFilter: {
              matterId,
              min: digestDay.toISOString(),
              max: endOfDay.toISOString(),
            },
            offset: 0,
            limit: 1000,
          },
        },
      );

      if (error) {
        throw new Error(`${error}`);
      }

      const recurringEventQueryResult = useSubscribedQuery(
        { ...DashboardRecurringEventData, query: DashboardRecurringEventsDocument },
        {
          variables: {
            eventFilter: {
              matterId,
              min: digestDay.toISOString(),
              max: endOfDay.toISOString(),
            },
          },
        },
      );

      const recurringEventList = recurringEventQueryResult?.data?.recurringEventList?.results || [];

      const appointmentRef = {};
      const expandedAppointments: any[] = [];

      const event1Guard = nonNullishFieldsGuardFactory(['eventType', 'rRULE', 'startTime', 'endTime', 'id']);

      recurringEventList.filter(event1Guard).forEach((a) => {
        if (a.eventType === 1 && a.rRULE) {
          const rRULEParsed = rrulestr(a.rRULE, { dtstart: moment(a.startTime).toDate() });
          const children = rRULEParsed.between(digestDay.toDate(), endOfDay.toDate(), true).map((d) => {
            const dateString = d.toISOString();
            if (a.startTime === dateString) {
              return a;
            }
            const diff = moment(dateString).add(moment(a.endTime).diff(moment(a.startTime)), 'millisecond');
            const appointmentCopy = {
              ...a,
              patternAppointmentId: a.id,
              originalId: a.id,
              startTime: dateString,
              endTime: diff.toISOString(),
              originalAppointment: a,
              id: undefined,
            };
            return appointmentCopy;
          });
          appointmentRef[a.id] = children;

          expandedAppointments.push(...children);
        }
      });

      const eventNot1Guard = nonNullishFieldsGuardFactory(['patternAppointmentId', 'startTime', 'originalStartTime']);

      recurringEventList.filter(eventNot1Guard).forEach((a) => {
        if (
          a?.eventType !== 1 &&
          appointmentRef[a?.patternAppointmentId] &&
          (moment(a?.startTime).isBetween(digestDay, endOfDay) ||
            moment(a?.originalStartTime).isBetween(digestDay, endOfDay))
        ) {
          const matchingInstance = appointmentRef[a.patternAppointmentId].findIndex(
            (c) => c.startTime === a.originalStartTime,
          );
          if (matchingInstance !== -1) {
            if (a.eventType === 4) {
              // remove instance
              appointmentRef[a.patternAppointmentId].splice(matchingInstance, 1);
            } else {
              // update instance
              Object.assign(appointmentRef[a.patternAppointmentId], a);
            }
          } else if (a.eventType !== 4) {
            appointmentRef[a.patternAppointmentId].push(a);
          }
        }
      });

      const rawEvents = (data?.eventList?.results || []).concat(expandedAppointments);

      const sortedEvents = sortByProperty(
        rawEvents.map((appointment) => ({
          ...appointment,
          startDate: appointment?.startTime,
          endDate: appointment?.endTime,
        })),
        'startTime',
        'asc',
      ).filter((ev) =>
        // Filter out any appointments that are not for the selected user. Cannt filter on the db side as this property is stored in XML, in a BLOB
        (ev?.resourceIds || []).find((staffId) => staffId === selectedStaffId),
      );

      return {
        digestDay,
        showEventModal,
        setShowEventModal,
        events: sortedEvents.filter((ev) => !ev?.allDay),
        allDayEvents: sortedEvents.filter((ev) => ev?.allDay),
        eventsLoading: eventsLoading || recurringEventQueryResult.loading,
        setSelectedDay,
      };
    },
    useGraphQLforTasks: () => {
      const digestDay = moment(selectedDay).endOf('day');

      const [showTaskModal, setShowTaskModal] = useState(false);

      const {
        error: completedTasksError,
        loading: completedTasksLoading,
        data: completedTasks,
      } = useSubscribedQuery(
        { ...WidgetTasks, query: WidgetTasksDocument },
        {
          variables: {
            taskFilter: {
              matterId,
              view: 'COMPLETED_DIGEST',
              digestDate: digestDay.toISOString(),
              assigneeIds: [selectedStaffId],
            },
            limit: 100,
          },
        },
      );
      const {
        error: incompletedTasksError,
        loading: incompletedTasksLoading,
        data: incompletedTasks,
      } = useSubscribedQuery(
        { ...WidgetTasks, query: WidgetTasksDocument },
        {
          variables: {
            taskFilter: {
              matterId,
              view: 'DIGEST',
              digestDate: digestDay.toISOString(),
              assigneeIds: [selectedStaffId],
            },
            limit: 100,
          },
        },
      );

      if (incompletedTasksError || completedTasksError) {
        throw completedTasksError || completedTasksError;
      }

      const incompleteTasks = incompletedTasks?.taskList?.results || [];
      const completeTasks = completedTasks?.taskList?.results || [];
      const tasks = incompleteTasks.concat(completeTasks);

      return {
        showTaskModal,
        completeTasks,
        incompleteTasks,
        setShowTaskModal,
        onSaveTask: async (task) => {
          try {
            const marshalledData = {
              id: task.id,
              versionId: uuid(),
              matterId: task.matterId,
              subject: task.subject,
              note: task.note,
              isRemoved: false,
              assigneeIds: task.assigneeIds,
              dueDate: task.dueDate,
              categories: task.categories,
              subTaskIds: task.subTaskIds,
              creationTimeStamp: task.creationTimeStamp || moment().toISOString(),
              isNewEntity: false,
              lastAssignedById: task?.lastAssignedById || '',
              isCompleted: task.isCompleted,
            };
            await dispatchCommand({
              type: 'Tasking.ManageTasks.Messages.SaveTasks2',
              message: marshalledData,
            });
            displaySuccessToUser('Task completed');
          } catch (err) {
            displayErrorToUser('Failed to complete task. Please try again later');
            log.error(err);
          }
        },
        tasks,
        tasksLoading: incompletedTasksLoading || completedTasksLoading,
      };
    },
  };
};

export const DailyDigestWidgetContainer = withApolloClient(composeHooks(useHooks)(DailyDigestWidget));
