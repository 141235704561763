import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query BillingContactsPaymentPlansRouteData(
    $contactFilter: ContactFilter
    $paymentPlanFilter: PaymentPlanFilter
    $paymentPlanStatusDate: String
    $offset: Int
    $limit: Int
    $sort: ListSort
  ) {
    contactPaymentPlanList(
      contactFilter: $contactFilter
      paymentPlanFilter: $paymentPlanFilter
      paymentPlanStatusDate: $paymentPlanStatusDate
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        paymentPlan {
          id
          status
          autoCharge
          installmentFrequency
          installmentAmount
          lastPaymentDate
          totalAmount
          unpaidAmount
          paymentPlanPosition {
            status
            paymentMethod {
              type
              status
              statusDisplay
            }
          }
        }
        contact {
          id
          displayName
          representativeOfsDisplayName
          type
          phone
          phone2
          cell
          email
        }
      }
    }
  }
`;

const notificationIds = [
  'CustomerManagementWebQuery.EntityUpdated',
  'WebQueryDebtorsNotifications.PaymentPlanUpdated',
  'DebtorsNotifications.PaymentPlanEventUpdated', // Not available as a WebQuery notification
  'WebQueryBillingSharedNotifications.CustomerBillingConfigurationUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BillingContactsPaymentPlansRouteData = {
  query,
  notificationIds,
};
