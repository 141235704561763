'use strict';

const transactionType = Object.freeze({
  Deposit: 'Deposit',
  DepositReversal: 'DepositReversal',
  InvoicePayment: 'InvoicePayment',
  InvoicePaymentReversal: 'InvoicePaymentReversal',
  MatterAdjustment: 'MatterAdjustment',
  MatterAdjustmentReversal: 'MatterAdjustmentReversal',
  Transfer: 'Transfer',
  VendorPayment: 'VendorPayment',
  VendorPaymentReversal: 'VendorPaymentReversal',
  BankFees: 'BankFees',
  BankFeesReversal: 'BankFeesReversal',
  Interest: 'Interest',
  InterestReversal: 'InterestReversal',
});

module.exports = { transactionType };
