import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { getRegion, regionType } from '@sb-itops/region';
import { hasBillingAccess } from 'web/services/user-session-management';

const showContactCreditCardTabStrategyFn = {
  [regionType.US]: () => hasBillingAccess() && hasFacet(facets.saveCreditCard) && featureActive('BB-5109'),
  // In AU/UK, we release "Saving card" feature as part of releasing SB Pay so we show the tab only if SB Pay feature is active
  [regionType.AU]: () => hasBillingAccess() && hasFacet(facets.saveCreditCard) && featureActive('BB-5109') && featureActive('BB-12038'),
  [regionType.GB]: () => hasBillingAccess() && hasFacet(facets.saveCreditCard) && featureActive('BB-5109') && featureActive('BB-12038'),
};

angular.module('sb.billing.webapp').controller('SbViewContactController', function ($scope, $controller) {
  'use strict';

  let that = this;
  angular.extend(this, $controller('SbPartialStateController', { $scope: $scope }));

  that.shouldShowContactCreditCardTab = showContactCreditCardTabStrategyFn[getRegion()];
});