import { connect } from 'react-redux';
import { withReduxStore, withOnLoad, withTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getCurrentStaff } from '@sb-firm-management/redux/firm-management';
import { getMap as getMatterTypes } from '@sb-matter-types/redux/matter-types';
import {
  getList as getMatterList,
  getById as getMatterById,
  getMatterDisplayById,
} from '@sb-matter-management/redux/matters';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { findUsedMatterTypeIds, buildMatterTypeTree } from 'web/redux/selectors/used-matter-types';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import TimeFeesExpensesFilter from './TimeFeesExpensesFilter';

const { periodOption, periodOptions } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);
const defaultSelectOption = { value: 'all', label: 'All' };

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const getAreaOfLawList = (filters = {}) => {
  const matterTypes = getMatterTypes();
  const matters = getMatterList();
  const chosenMatterTypes = findUsedMatterTypeIds(matters);
  const matter = filters.showInvoicesFor === 'matter' && filters.matter && getMatterById(filters.matter.id);

  const matterTypeTree = buildMatterTypeTree({
    matterTypeIds: chosenMatterTypes,
    matterTypes,
    selectedMatterTypes: chosenMatterTypes[0],
  });

  const areasOfLaw = [
    defaultSelectOption,
    ...matterTypeTree.map((matterTypeCategory) => ({
      label: matterTypeCategory.display,
      value: matterTypeCategory.data,
    })),
  ];

  const matterAreasOfLaw = matter
    ? areasOfLaw.filter(({ value }) => {
        const genericMatterTypeId = matter.matterTypeId && matter.matterTypeId.split('_')[0];

        return value.includes(matter.matterTypeId) || value.includes(genericMatterTypeId);
      })
    : [];

  return matterAreasOfLaw.length ? matterAreasOfLaw : areasOfLaw;
};

const getTimeFeesExpenseEntries = (t) => ({
  TIME_EXPENSES: { value: 'all', label: t('timeAndExpenses') },
  TIME: { value: 'time', label: 'Time' },
  EXPENSES: { value: 'expenses', label: t('capitalizeAllWords', { val: 'expenses' }) },
});

const mapStateToProps = (state, { filters, t }) => ({
  contacts: getContactTypeAheadSummaries(),
  staff: [defaultSelectOption, ...getCurrentStaff().map((s) => ({ value: s.id, label: `${s.initials} - ${s.name}` }))],
  areasOfLaw: getAreaOfLawList(filters),
  periodOptions,
  entryTypes: Object.values(getTimeFeesExpenseEntries(t)),
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters, t }) => ({
  onLoad: () => {
    if (!filters) {
      const defaultState = {
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        includeLeadMatters: false,
        excludeExternallyBilled: true,
        showInvoicesFor: 'staff',
        showEntriesList: false,
        showDescriptionField: false,
        attorneyResponsible: defaultSelectOption,
        areaOfLaw: defaultSelectOption,
        entryType: getTimeFeesExpenseEntries(t).TIME_EXPENSES,
        errors: {},
      };

      onFiltersChanged(defaultState);
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        showInvoicesFor: filters.showInvoicesFor,
        areaOfLaw:
          filters.showInvoicesFor === 'matter'
            ? getAreaOfLawList({ showInvoicesFor: filters.showInvoicesFor, matter: filters.matter })[0]
            : defaultSelectOption,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onShowInvoicesFor: (showInvoicesFor) =>
    onFiltersChanged({
      ...filters,
      showInvoicesFor,
      areaOfLaw:
        showInvoicesFor === 'staff'
          ? defaultSelectOption
          : getAreaOfLawList({ showInvoicesFor, matter: filters.matter })[0],
      errors: {
        ...filters.errors,
        matter: showInvoicesFor === 'matter' && !filters.matter,
      },
    }),

  onMatterSelected: (matter) =>
    matter
      ? dispatch(
          onFiltersChanged({
            ...filters,
            // in AU, for Law Society compliance reasons, we need to show the matter description
            matter: {
              id: matter.id,
              display: getMatterDisplayById(matter.id, false, hasFacet(facets.includeDescriptionInMatterDisplay)),
            },
            areaOfLaw: getAreaOfLawList({ showInvoicesFor: filters.showInvoicesFor, matter })[0],
            errors: {
              ...filters.errors,
              matter: false,
            },
          }),
        )
      : dispatch(
          onFiltersChanged({
            ...filters,
            matter: undefined,
            areaOfLaw: defaultSelectOption,
            errors: {
              ...filters.errors,
              matter: filters.showInvoicesFor === 'matter',
            },
          }),
        ),

  onSelectOption: ({ key, option }) => onFiltersChanged({ ...filters, [key]: option }),

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const TimeFeesExpensesFilterContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(TimeFeesExpensesFilter))),
);

TimeFeesExpensesFilterContainer.displayName = `TimeFeesExpensesFilterContainer`;

export default TimeFeesExpensesFilterContainer;
