// @ts-nocheck
import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-cycle
import { isValidMatter } from './index';

/**
 * Valid matters are not deleted and not 'empty'. Empty is pretty vague, I have
 * no idea the scenario in which a matter is 'empty'. It's probably a legacy concept,
 * but we need to handle it in case customers have old matter data.
 *
 * @param {Array} mattersList - matters list. (This will change to be an object with the state and we will get the list inside)
 * @returns {Array} - array of matters that are valid or empty list
 */
export const getValidMatterListSelector = createSelector(
  (matters) => matters,
  (matters, includeLeads) => includeLeads,
  // includeLeads is required in order to return a new list upon it changing
  // eslint-disable-next-line
  (matters, includeLeads) => (matters || []).filter((matter) => isValidMatter(matter, false)),
);

// Includes ALL leads
export const getValidMatterAndLeadListSelector = createSelector(
  (matters) => matters,
  (matters, includeLeads) => includeLeads,
  // includeLeads is required in order to return a new list upon it changing
  // eslint-disable-next-line
  (matters, includeLeads) => (matters || []).filter((matter) => isValidMatter(matter, true)),
);

export const getUsedAttorneyResponsibleIdsSelector = createSelector(
  (matters) => matters,
  (matters) => [...new Set(matters.map((matter) => matter.attorneyResponsibleId))],
);

export const getMapSelector = createSelector(
  (matters) => matters,
  (matters) =>
    matters.reduce((acc, matter) => {
      acc[matter.matterId] = matter;
      return acc;
    }, {}),
);
