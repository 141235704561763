'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import {
  getById,
  updateCache as updateRedux,
} from '@sb-billing/redux/quickbooks';
import { hasFacet, facets } from '@sb-itops/region-facets';

const getAccountId = () => authSelectors.getAccountId(store.getState());

angular.module('@sb-billing/services').service('sbQuickBooksService', function (sbLoggerService, sbGenericEndpointService, sbGenericCacheService, sbEndpointType) {
  const that = this;
  const log = sbLoggerService.getLogger('sbQuickBooksService');
  const ENDPOINT = 'billing/quickbooks';

  const supportsQuickBooksIntegration = hasFacet(facets.integrationQuickBooks);
  if (supportsQuickBooksIntegration) {
    sbGenericCacheService.setupCache({
      name: 'sbQuickBooksService',
      sync: {
        endpoint: {type: sbEndpointType.SYNC_ALL, stub: ENDPOINT},
        poll: 60,
        subscriptions: `notifier.QuickbooksIntegration`,
      },
      updateRedux,
    });
  }

  //log.setLogLevel('info');

  that.isAuthorizedP = isAuthorizedP;
  that.disableP = disableP;
  that.getSettingsP = getSettingsP;
  that.saveSettingsP = saveSettingsP;
  that.getBankAccountsP = getBankAccountsP;

  function get() {
    const accountId = getAccountId();
    const settings = getById(accountId);
    return settings ? settings : {};
  }

  function isAuthorizedP () {
    // Results are wrapped in promise to simulate legacy behaviour when get() was wrapped in a stupid and useless promise.
    const settings = get(); // FFS. get() f'd.
    if (settings && settings.authInfo) {
      return Promise.resolve(!!settings.authInfo);
    }

    return Promise.resolve(false);
  }

  function disableP () {
    return sbGenericEndpointService.getPayloadP(`${ENDPOINT}/disconnect`);
  }

  function getSettingsP () {
    // Results are wrapped in promise to simulate legacy behaviour when get() was wrapped in a stupid and useless promise.
    const settings = get(); 
    
    // Did you think that get() returned settings? You fool, settings.settings are the real settings. Settings merely contains settings.
    // In other words settings is a superset of settings, but settings is a subset of settings, where settings !== settings.
    if (settings.settings) {
      return Promise.resolve(settings.settings);
    }

    return Promise.resolve({});
  }

  function saveSettingsP (settings) {
    log.info('saving QuickBooks settings', settings);
    return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/settings`, undefined, settings);
  }

  function getBankAccountsP () {
    // Results are wrapped in promise to simulate legacy behaviour when get() was wrapped in a stupid and useless promise.
    const settings = get();
    if (settings.accounts) {
      return Promise.resolve(determineBankAccounts(settings.accounts.bankAccounts));
    }

    return Promise.resolve({});
  }

  function determineBankAccounts (bankAccounts) {
    const incomeBankAccounts = filterBankAccounts(bankAccounts, 'Income').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Income',
    }));
    const expenseBankAccounts = filterBankAccounts(bankAccounts, 'Expense').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Expense',
    }));
    const otherCurrentLiabilityAccounts = filterBankAccounts(bankAccounts, 'OtherCurrentLiability').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Liability', // Sam wanted to name this Liability instead of Other Current Liability
    }));
    const otherCurrentAssetAccounts = filterBankAccounts(bankAccounts, 'OtherCurrentAsset').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Asset', // Sam wanted to name this Asset
    }));

    return {
      operatingBankAccounts:      filterBankAccounts(bankAccounts, 'Bank'),
      legalFeesAccounts:          incomeBankAccounts,
      clientExpensesAccounts:     [ ...otherCurrentAssetAccounts, ...incomeBankAccounts ],
      clientSoftCostExpensesAccounts: incomeBankAccounts,
      operatingLiabilityAccounts: otherCurrentLiabilityAccounts,
      trustBankAccounts:          filterBankAccounts(bankAccounts, 'Bank'),
      trustLiabilityAccounts:     otherCurrentLiabilityAccounts,
      operatingChequeAccounts:    [...otherCurrentAssetAccounts, ...expenseBankAccounts],
      operatingChequeSoftCostAccounts: expenseBankAccounts,
      surchargeAccounts:          [ ...incomeBankAccounts, ...otherCurrentLiabilityAccounts ],
    };
  }

  function filterBankAccounts (bankAccounts, ...accountTypes) {
    return bankAccounts.filter((bankAccount) => accountTypes.some((accountType) => bankAccount.type === accountType));
  }
});
