import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { LinkableText } from '@sb-itops/react/linkable-text';
import { useTranslation } from '@sb-itops/react';
import { localiseBankAccountTypeDisplay } from '@sb-billing/business-logic/bank-account/services';

const BankAccountDisplay = (props) => {
  const { t } = useTranslation();
  const { account, className } = props;
  return (
    <LinkableText
      {...props}
      className={classnames('bank-account-display', className)}
      text={localiseBankAccountTypeDisplay(account, t)}
    />
  );
};

BankAccountDisplay.propTypes = {
  account: PropTypes.shape({
    accountTypeDisplay: PropTypes.string,
  }),
  className: PropTypes.func,
  inline: PropTypes.bool,
};

BankAccountDisplay.defaultProps = {
  inline: true,
  className: undefined,
  account: { accountTypeDisplay: '' },
};

export default BankAccountDisplay;
