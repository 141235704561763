'use strict';

const { fuzzyTime } = require('../../shared/services');

const { convertHrsToHHMM } = fuzzyTime;

/**
 * generateTimeInHoursAndMinutes
 *
 * @param {Object} params
 * @param {number} params.durationInMins
 * @returns {string}
 */
function generateTimeInHoursAndMinutes(props) {
  const { durationInMins } = props;

  const validateDurationInMins = () => Number.isInteger(durationInMins) && durationInMins >= 0;

  const validDurationInMins = validateDurationInMins();

  if (!validDurationInMins) {
    throw new Error('Please provide a valid durationInMins value');
  }

  const durationInHoursAndMinutes = convertHrsToHHMM({
    hrs: +(durationInMins / 60).toFixed(5),
  });

  return durationInHoursAndMinutes;
}

module.exports = {
  generateTimeInHoursAndMinutes,
};
