'use strict';

import { getAccountId } from 'web/services/user-session-management';
import { getRegion } from '@sb-itops/region';

angular.module('sb.billing.webapp').component('sbGstTaxSettings', {
  templateUrl: 'ng-components/gst-tax-settings/gst-tax-settings.html',
  controller: function(
    sbLoggerService,
    $scope,
    sbGstTaxSettingsService,
    sbMessageDisplayService,
    sbUuidService,
    sbLocalisationService,
  ) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbGstTaxSettings');

    ctrl.t = sbLocalisationService.t;

    const DEFAULT_TAX_RATES = {
      'GB': 20,
      'AU': 10,
    };

    ctrl.$onInit = () => {
      ctrl.defaultSettings = {
        gstRate: getDefaultTaxRate(),
        registeredForGst: true
      };
      ctrl.isSaving = false;
      ctrl.save = save;
      ctrl.hasChanges = hasChanges;
      ctrl.onChangeRegisterGst = onChangeRegisterGst;
      ctrl.onChangeGstRate = onChangeGstRate;

      $scope.$on('smokeball-data-update-sbGstTaxSettingsService', loadSettings);
      loadSettings();
    };

    function getDefaultTaxRate () {
      const taxRate = DEFAULT_TAX_RATES[getRegion()] || 0;
      return taxRate;
    }

    function getTaxSettings() {
      return sbGstTaxSettingsService.getTaxSettings() || {};
    }

    function loadSettings() {
      const loadedGstSettings = getTaxSettings();
      if (_.isEmpty(loadedGstSettings)) {
        ctrl.gstTaxSettings = Object.assign({}, ctrl.defaultSettings);
      } else {
        ctrl.gstTaxSettings = Object.assign({}, ctrl.defaultSettings, {
          gstRate: loadedGstSettings.taxRate / 100,
          registeredForGst: loadedGstSettings.registeredForGst
        });
      }
    }

    function saveGstTaxSettingsP() {
      return sbGstTaxSettingsService.saveP({
        accountId: getAccountId(),
        versionId: sbUuidService.get(),
        taxRate: ctrl.gstTaxSettings.gstRate * 100 ,// Convert to basis points,
        registeredForGst: !!ctrl.gstTaxSettings.registeredForGst
      });
    }

    function hasChanges() {
      const previous = getTaxSettings();
      const { gstRate, registeredForGst } = ctrl.gstTaxSettings || {};
      return previous.taxRate !== gstRate || previous.registeredForGst !== registeredForGst;
    }
    
    function onChangeRegisterGst() {
      const { gstRate, registeredForGst } = ctrl.gstTaxSettings || {};
      if (registeredForGst && !gstRate) {
        ctrl.gstTaxSettings.gstRate = getDefaultTaxRate();
      }

      if (!registeredForGst) {
        ctrl.gstTaxSettings.gstRate = 0;
      }
    }

    function onChangeGstRate() {
      const { gstRate } = ctrl.gstTaxSettings || {};
      ctrl.gstTaxSettings.registeredForGst = !!gstRate;
    }

    function save() {
      if (hasChanges()) {
        ctrl.isSaving = true;
        let hasError = false;
        saveGstTaxSettingsP()
          .catch(err => {
            log.error(err);
            hasError = true;
            sbMessageDisplayService.error('Failed to save settings.');
          })
          .then(() => {
            loadSettings();
          })
          .finally(() => {
            ctrl.isSaving = false;
            if (!hasError) {
              sbMessageDisplayService.success(`Settings saved.`);
            }
          });
      }
    }
  }
});
