'use strict';

const Big = require('big.js');
const { findLastUpdatedChequeNumber } = require('./find-last-cheque-number');
const { padChequeNumber } = require('./pad-cheque-number');

const getNextChequeNumberByLastUpdated = (allCheques = []) => {
  // cheque reference (chequeNumber) is stored as a string due to number padding
  const lastChequeNumber = findLastUpdatedChequeNumber(allCheques) || '0';
  // There are firms have really big cheque number that exceeds the maximum safe integer limit in JavaScript
  // We need to use Big to handle that situation and avoid endless while loop
  const lastChequeNumberFound = new Big(lastChequeNumber);

  // keep incrementing until we find an available number
  const usedChequeNumbers = allCheques.map((c) => new Big(c.chequeNumber || '0'));
  let nextChequeNumber = lastChequeNumberFound.plus(1);

  const isNumberEqual = (num) => num.eq(nextChequeNumber);
  while (usedChequeNumbers.some(isNumberEqual)) {
    nextChequeNumber = nextChequeNumber.plus(1);
  }

  const chequeNumber = nextChequeNumber.toString()
    ? padChequeNumber(nextChequeNumber.toString(), lastChequeNumber.length) // increment cheque number
    : '1'; // defaults to 1
  return chequeNumber;
};

module.exports = {
  getNextChequeNumberByLastUpdated,
};
