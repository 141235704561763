import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { toggleShowSystemDateApi as toggleShowSystemDateMatterApi } from 'web/redux/route/home-billing-matter-transactions';
import { sortByOrder } from '@sb-itops/nodash';
import { withLinkHandler } from '@sb-itops/react/hoc';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { getTransactionReceiptById } from '@sb-billing/redux/transaction-receipts';
import { localiseDescription } from '@sb-billing/transaction-descriptions';
import ControlledMoneyLedgerTransactionTable from './ControlledMoneyLedgerTransactionTable';

const mapStateToProps = (state, props) => {
  const showEnteredDateColumn = toggleShowSystemDateMatterApi.getState().showSystemDate;

  let balance = 0;

  const transactionsWithBalance = sortByOrder(props.transactions, ['effectiveDate', 'timestamp'], ['asc', 'asc']).map(
    (txn) => {
      // these are use to display kebab menu in the table
      const isVendorPayment = txn.paymentId && txn.type === transactionType.VendorPayment;
      const hasReceipt = !!getTransactionReceiptById(txn.id);

      balance += txn.amount;
      return {
        ...txn,
        balance,
        isVendorPayment,
        hasReceipt,
        description: txn.reason
          ? `${localiseDescription(props.t, txn.description)} - ${txn.reason}`
          : localiseDescription(props.t, txn.description),
      };
    },
  );

  const transactions = sortByOrder(
    transactionsWithBalance,
    [props.sortBy, 'timestamp'],
    [props.sortDirection, props.sortDirection],
  );

  const summary = transactions.reduce(
    (total, txn) => ({
      balance: total.balance + txn.amount,
      credit: total.credit + txn.credit,
      debit: total.debit + Math.abs(txn.debit),
    }),
    {
      balance: 0,
      credit: 0,
      debit: 0,
    },
  );

  return {
    ...props,
    transactions,
    summary,
    showEnteredDateColumn,
  };
};

const ControlledMoneyLedgerTransactionTableContainer = withReduxStore(
  withTranslation()(
    connect(mapStateToProps)(
      withLinkHandler('receipt')(withLinkHandler('vendorProofOfPayment')(ControlledMoneyLedgerTransactionTable)),
    ),
  ),
);

ControlledMoneyLedgerTransactionTableContainer.propTypes = {
  transactions: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  showHidden: PropTypes.bool,
};

ControlledMoneyLedgerTransactionTableContainer.defaultProps = {
  sortBy: 'effectiveDate',
  sortDirection: 'desc',
  transactions: [],
  showHidden: undefined,
};

export default ControlledMoneyLedgerTransactionTableContainer;
