import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onPatchSmokeballContact = ({ commandMessage }) => {
  const actualContact = commandMessage.smokeballContact.person || commandMessage.smokeballContact.company;
  const email = (actualContact && actualContact.email) || null;

  const optimistic = {
    email,
    __typename: 'Contact',
    id: commandMessage.contactId,
  };

  const apolloClient = getApolloClient();

  const identifier = apolloClient.cache.identify(optimistic);

  const frag = {
    id: identifier,
    fragment: gql`
      fragment OpdateContact on Contact {
        ${Object.keys(optimistic).join('\n')}
      }
    `,
    data: {
      ...optimistic,
    },
  };

  apolloClient.writeFragment(frag);

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_UPDATED,
    typeName: 'Contact',
    optimisticEntity: [optimistic],
  };
};

