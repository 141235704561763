import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { sortByOrder } from 'lodash';
import { createSelector } from 'reselect';
import domain from '../domain';

// eslint-disable-next-line import/no-cycle
export { saveEndOfMonthReport } from './save-end-of-month-report';

export const statusMap = {
  inProgress: 'InProgress',
  completed: 'Completed',
  failed: 'Failed',
  cancelled: 'Cancelled',
};

const statusByValue = {
  0: statusMap.inProgress,
  1: statusMap.completed,
  2: statusMap.failed,
  3: statusMap.cancelled,
};

const api = cacheFactory({
  domain,
  name: 'end-of-month',
  keyPath: 'id',
  ngCacheName: 'sbEndOfMonthReportsService',
  syncType: syncTypes.SINCE,
  immutable: false,
  changesetProcessor: ({ entities }) =>
    entities &&
    entities.map((endOfMonth) => ({
      ...endOfMonth,
      ...{ status: statusByValue[endOfMonth.status] },
    })),
});

export const { getById, getList, getMap, updateCache, clearCache } = api;

export const getAllReportsByBankAccountId = (trustAccountId) =>
  getList().filter((eom) => eom.bankAccountId === trustAccountId);

const getLatestSelector = createSelector(
  (state) => state || [],
  (endOfMonthReports) =>
    sortByOrder(endOfMonthReports, ['date'], ['desc']).find(({ status }) => status === statusMap.completed),
);

export const getLatest = (trustAccountId) => getLatestSelector(getAllReportsByBankAccountId(trustAccountId));

export const getAllReports = () => getList();

export const getS3BucketById = (id) => {
  const report = getById(id);
  if (report) {
    return report.url;
  }
  return undefined;
};
