'use strict';

const {
  getSources,
  getDepositSources,
  getDefaultSource,
  getBankTransferSources,
} = require('./payment-deposit-sources');

module.exports = {
  getSources,
  getDepositSources,
  getDefaultSource,
  getBankTransferSources,
};
