import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query InitFirmDetails {
    firm {
      id
      firmName
      creationDate
    }
  }
`;

const notificationIds = ['FirmManagementNotifications.FirmUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitFirmDetails = {
  query,
  notificationIds,
};
