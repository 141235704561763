import { fetchGetP } from '@sb-itops/redux/fetch';

let cache = '';

export const getArchieUrl = async () => {
  if (cache) {
    return cache;
  }

  const response = await fetchGetP({ path: '/integration/endpoint-locator/archie/:accountId/' });

  if (response.status === 200) {
    const result = response.body;
    const url = result.url;

    cache = url;

    return url;
  }

  throw new Error(`unexpected status: ${response.status}`);
};
