import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getMatterTrustBalanceByDate } from '@sb-billing/redux/transactions';
import { dateToInteger as toYYYYMMDD } from '@sb-itops/date';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { WarningList } from '@sb-itops/react/warning-list';
import { matterHasProtectedFundsForBankAccountId } from '@sb-billing/redux/balance-protection';
import { ACCOUNT_TYPE, getTrustAccounts } from '@sb-billing/redux/bank-account';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { featureActive } from '@sb-itops/feature';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';

const { getBankAccountBalanceById } = selectors;

const getControlledMoneyAccountWarning = ({ bankAccountId, amount, t }) => {
  const bankAcctBalanceState = getBankAccountBalanceState();
  const bankAcctBalance = getBankAccountBalanceById(bankAcctBalanceState, { bankAccountId });
  const currentBalance = bankAcctBalance?.balance || 0;
  const newBalance = currentBalance - amount;
  const willOverdraw = newBalance < 0;

  if (!willOverdraw) {
    return undefined;
  }

  const newBalanceDisplay = t('cents', { val: newBalance });

  return `Warning: As of the transaction date, the ${t(
    'controlledMoneyAccount',
  ).toLowerCase()} will be overdrawn to a balance of ${newBalanceDisplay}`;
};

const getTrustAccountWarning = ({ matterId, date, amount, bankAccountId, t }) => {
  const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);
  const isStatutoryMatter = isStatutoryDepositMatter({
    matterId,
    trustBankAccounts: getTrustAccounts(),
    supportsStatutoryDepositMatter,
  });

  if (isStatutoryMatter) {
    return undefined;
  }

  const currentBalance = getMatterTrustBalanceByDate(matterId, toYYYYMMDD(date), bankAccountId);
  const newBalance = currentBalance - amount;
  const willOverdraw = newBalance < 0;

  if (!willOverdraw) {
    return undefined;
  }

  const hasProtectedFunds =
    featureActive('BB-8671') && matterHasProtectedFundsForBankAccountId(matterId, bankAccountId);
  const newBalanceDisplay = t('cents', { val: newBalance });

  return hasProtectedFunds
    ? `There are insufficient available funds - please unprotect funds before use or overdraw.`
    : `Warning: As of the transaction date, the ${t(
        'trustAccount',
      ).toLowerCase()} will be overdrawn to a balance of ${newBalanceDisplay}`;
};

const getOperatingAccountWarning = () => undefined;

const warningHandler = {
  [ACCOUNT_TYPE.controlled_money.toUpperCase()]: getControlledMoneyAccountWarning,
  [ACCOUNT_TYPE.trust.toUpperCase()]: getTrustAccountWarning,
  [ACCOUNT_TYPE.operating.toUpperCase()]: getOperatingAccountWarning,
};

const mapStateToProps = (state, { matterId, amount, date, bankAccountId, accountType, t }) => {
  const allowOverdraw = hasFacet(facets.allowOverdraw);
  if (!matterId || !accountType || !allowOverdraw) {
    return { list: undefined };
  }

  const handler = warningHandler[accountType.toUpperCase()];
  const warningText = handler({ matterId, date, amount, bankAccountId, t });

  if (!warningText) {
    return { list: undefined };
  }

  return { list: [{ text: warningText }] };
};

const OverdrawWarningContainer = withReduxStore(withTranslation()(connect(mapStateToProps, null)(WarningList)));

OverdrawWarningContainer.propTypes = {
  matterId: PropTypes.string,
  amount: PropTypes.number,
  date: PropTypes.objectOf(Date).isRequired,
  bankAccountId: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
};

OverdrawWarningContainer.defaultProps = {
  amount: 0,
  matterId: undefined,
};

export default OverdrawWarningContainer;
