import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FeeWisePaymentsListFilterPanel } from 'web/react-redux';
import { FeeWiseTransactionTable } from 'web/components';
import { PaginationSelector } from '@sb-itops/react';
import Styles from './FeeWiseTransactionsRoute.module.scss';

export const FeeWiseTransactionsRoute = memo((props) => {
  const { panelFilterScope, onClickLink, transactions, transactionsDataLoading, transactionCount } = props;
  const { dateRangeType, fromDate, toDate, onDateListChange } = props;
  const { selectedStatusIds, onSelectStatus, statusOptions } = props;
  const { linkedAccountSelected, linkedAccountOnSelect, linkedAccountLoading, linkedAccountOptions } = props;
  const { hidePagination, currentPage, numberOfPages, onPageChange } = props;
  const { onSort, sortBy, sortDirection } = props;

  return (
    <div className={Styles.fwTransactionsPanel}>
      <div className="master-detail-panel">
        <div className={Styles.flexCol}>
          <FeeWisePaymentsListFilterPanel
            panelFilterScope={panelFilterScope}
            // date filter
            dateFilterLabel="Transaction Date"
            dateRangeType={dateRangeType}
            fromDate={fromDate}
            toDate={toDate}
            onDateListChange={onDateListChange}
            // status filter
            selectedStatusIds={selectedStatusIds}
            onSelectStatus={onSelectStatus}
            statusOptions={statusOptions}
            hideStatusAllOption={false}
            // account filter
            linkedAccountSelected={linkedAccountSelected}
            linkedAccountOnSelect={linkedAccountOnSelect}
            linkedAccountLoading={linkedAccountLoading}
            linkedAccountOptions={linkedAccountOptions}
          />
        </div>
        <div className={classnames('panel-body', Styles.fwTransactionsPanelBody)}>
          <FeeWiseTransactionTable
            onClickLink={onClickLink}
            transactions={transactions}
            dataLoading={transactionsDataLoading}
            transactionCount={transactionCount}
            onSort={onSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
          <PaginationSelector
            name="feewiseTransactions"
            className={Styles.paginationSection}
            hidePagination={hidePagination}
            numberOfPagesDisplayed={10}
            selectedPage={currentPage}
            totalNumberOfPages={numberOfPages}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
});

FeeWiseTransactionsRoute.displayName = 'FeeWiseTransactionsRoute';

FeeWiseTransactionsRoute.propTypes = {
  panelFilterScope: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  transactionsDataLoading: PropTypes.bool.isRequired,
  transactionCount: PropTypes.number.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      reference: PropTypes.string,
      transactionCount: PropTypes.string,
      status: PropTypes.string,
      totalAmount: PropTypes.number,
      account: PropTypes.string,
    }),
  ).isRequired,

  // pagination
  hidePagination: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  // sort
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func,

  dateRangeType: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  onDateListChange: PropTypes.func.isRequired,

  selectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  linkedAccountSelected: PropTypes.string.isRequired,
  linkedAccountOnSelect: PropTypes.func.isRequired,
  linkedAccountLoading: PropTypes.bool.isRequired,
  linkedAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};

FeeWiseTransactionsRoute.defaultProps = {
  hidePagination: false,
  onSort: () => {},
};
