import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withReduxStore } from '@sb-itops/react';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import ContactDisplay from './ContactDisplay';

const mapStateToProps = (state, { contactId, showLastNameFirst, hideDeletedStatus }) => ({
  displayName: getContactDisplay(contactId, { showLastNameFirst, hideDeletedStatus }),
});

const ContactDisplayContainer = withReduxStore(connect(mapStateToProps)(ContactDisplay));

ContactDisplayContainer.propTypes = {
  contactId: PropTypes.string,
  onClickLink: PropTypes.func,
  className: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  showLastNameFirst: PropTypes.bool,
  hideDeletedStatus: PropTypes.bool,
};

ContactDisplayContainer.defaultProps = {
  hideDeletedStatus: false, // false by default to maintain existing behaviour
};

export default ContactDisplayContainer;
