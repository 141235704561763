import { getAuthToken } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').component('sbDataTokenDetails', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: {composeKey: '@'},
  controller: function (sbLoggerService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataTokenDetails');

    ctrl.$onInit = () => {
      if (_.isEmpty(ctrl.composeKey)) {
        throw new Error('Cannot create sbDataTokenDetails with invalid compose key');
      }

      log.info('composeKey', ctrl.composeKey);
      updateData();
    };

    function updateData(){
      ctrl.sbComposeCtrl.setComposeData(getAuthToken(), ctrl.composeKey);
    }
  }
});
