import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TabList } from '@sb-itops/react/tab-list';
import { useTranslation } from '@sb-itops/react';
import { installmentFrequencyOptions } from '@sb-billing/business-logic/payment-plan/services';
import Styles from './PaymentPlanForm.module.scss';
import { PaymentPlanDetails } from '../payment-plan-details';
import { PaymentPlanInclusions } from '../payment-plan-inclusions';

export const PAYMENT_PLAN_TABS = {
  planDetails: 'plan-details',
  inclusions: 'inclusions',
};

const tabsListOptions = [
  { text: 'Plan Details', id: PAYMENT_PLAN_TABS.planDetails },
  { text: 'Inclusions', id: PAYMENT_PLAN_TABS.inclusions },
];

const getFormattedDate = (date, t) => t('date', { date, format: 'DD/MM/YYYY' });

const PaymentPlanForm = memo(
  ({
    selectedTab,
    selectedPaymentPlanId,
    startingDate,
    candidateMatters,
    formFields,
    fieldValues,
    nextButtonEnabled,
    relatedContacts,
    paymentMethodOptions,
    creditCardFormProps,
    // callbacks
    onFieldValueChange,
    onPlanDetailsTabClick,
    onInclusionsTabClick,
    onAddCreditCard,
  }) => {
    const { t } = useTranslation();
    const onTabSelectionChange = (tabId) => {
      switch (tabId) {
        case PAYMENT_PLAN_TABS.planDetails: {
          onPlanDetailsTabClick();
          break;
        }
        case PAYMENT_PLAN_TABS.inclusions: {
          onInclusionsTabClick();
          break;
        }
        default: {
          onPlanDetailsTabClick();
        }
      }
    };

    const isPlanDetailsTabSelected = selectedTab === PAYMENT_PLAN_TABS.planDetails;
    const isInclusionsTabSelected = selectedTab === PAYMENT_PLAN_TABS.inclusions;

    const tabsListOptionsWithSelection = tabsListOptions.map((option) => ({
      ...option,
      selected: option.id === selectedTab,
      disabled: option.id === PAYMENT_PLAN_TABS.inclusions ? !nextButtonEnabled : false,
    }));

    return (
      <form className={Styles.formContainer}>
        <TabList list={tabsListOptionsWithSelection} onTabSelectionChange={onTabSelectionChange} />
        <div className={Styles.fieldsContainer}>
          {isPlanDetailsTabSelected && (
            <PaymentPlanDetails
              relatedContacts={relatedContacts}
              selectedPaymentPlanId={selectedPaymentPlanId}
              debtor={formFields.debtor}
              installmentAmount={formFields.installmentAmount}
              installmentFrequency={formFields.installmentFrequency}
              paymentMethod={formFields.paymentMethod}
              nextInstallmentDate={formFields.nextInstallmentDate}
              onValueChange={onFieldValueChange}
              installmentFrequencyOptions={installmentFrequencyOptions}
              paymentMethodOptions={paymentMethodOptions}
              isDebtorDisabled={!!selectedPaymentPlanId}
              onAddCreditCard={onAddCreditCard}
              creditCardFormProps={creditCardFormProps}
            />
          )}
          {isInclusionsTabSelected && (
            <PaymentPlanInclusions
              matters={candidateMatters}
              inclusions={fieldValues.inclusions}
              onValueChange={onFieldValueChange}
            />
          )}
          {selectedPaymentPlanId && (
            <div className={Styles.footer}>
              {startingDate && (
                <span className={Styles.startingDate}>{`Plan started on ${getFormattedDate(startingDate, t)}`}</span>
              )}
            </div>
          )}
        </div>
      </form>
    );
  },
);

PaymentPlanForm.propTypes = {
  candidateMatters: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string.isRequired,
      due: PropTypes.number.isRequired,
    }),
  ),
  relatedContacts: PropTypes.array,
  startingDate: PropTypes.instanceOf(Date),
  selectedTab: PropTypes.oneOf(Object.values(PAYMENT_PLAN_TABS)),
  onFieldValueChange: PropTypes.func.isRequired,
  formFields: PropTypes.object,
  fieldValues: PropTypes.object,
  selectedPaymentPlanId: PropTypes.string,
  nextButtonEnabled: PropTypes.bool,
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  creditCardFormProps: PropTypes.shape({
    isNewCreditCardEnabled: PropTypes.bool,
    showCreditCardForm: PropTypes.bool,
    setShowCreditCardForm: PropTypes.func.isRequired,
    isSubmittingCreditCard: PropTypes.bool.isRequired,
    setIsSubmittingCreditCard: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.bool.isRequired,
    setTriggerSubmit: PropTypes.func.isRequired,
    isReadyForSubmit: PropTypes.bool.isRequired,
    setIsReadyForSubmit: PropTypes.func.isRequired,
    bankAccountId: PropTypes.string.isRequired,
  }),
  // callbacks & functions
  onPlanDetailsTabClick: PropTypes.func.isRequired,
  onInclusionsTabClick: PropTypes.func.isRequired,
  onAddCreditCard: PropTypes.func.isRequired,
};

PaymentPlanForm.defaultProps = {
  candidateMatters: [],
  relatedContacts: [],
  paymentMethodOptions: [],
  selectedTab: PAYMENT_PLAN_TABS.planDetails,
  startingDate: undefined,
  formFields: {},
  fieldValues: {},
  selectedPaymentPlanId: undefined,
  nextButtonEnabled: false,
  creditCardFormProps: {
    showCreditCardForm: false,
  },
};

export default PaymentPlanForm;
