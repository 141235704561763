import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { DropDownList } from '@sb-itops/react/drop-down-list';
import { getPaymentSources } from 'web/redux/selectors/payment-source';
import { dateToInteger } from '@sb-itops/date';
import { hasFacet, facets } from '@sb-itops/region-facets';

/**
 * This container mainly sets the default props for the DropDownList common to all PaymentSourceDropdown components.
 * Whilst it has the means to get the payment sources via the matterId and accountType, as well as the default option
 * to use, they arguably could be deferred to a higher level container. Hence the later provision of the paymentSources
 * prop. Support for fetching payment sources may be removed in the future.
 */
const mapStateToProps = (state, props) => {
  const {
    onChange,
    selectedOption,
    matterId,
    accountType,
    disabled,
    paymentSources,
    includeCombined,
    effectiveDate,
    t,
  } = props;

  const date = dateToInteger(effectiveDate);
  const options =
    paymentSources ||
    getPaymentSources({
      matterId,
      accountType,
      includeCombined,
      effectiveDate: date,
      t,
      allowOverdraw: hasFacet(facets.allowOverdraw),
    });

  /* commenting out the below to fix https://smokeball.atlassian.net/browse/BB-4499
   * where a cascades of different side effects flowing from Angular to React (this file)
   * and back causes app to exceed the maximum JavaScript call stack. The below commented
   * code can be removed once BB-4499 is regression tested. Keeping it here in case we
   * need to revisit this during testing period.
   *
   * For discussion: below is bad practice as onChange should be triggered by users
   * and not by some automatically detected condition. It's a bit hard to explain why as
   * this is heuristics I've picked up over the years.
   *
   * The best way to explain what I mean is perhaps drawing parallel with Angular.
   * This code basically introduces the same effect as two way data-binding in Angular
   * under a specific condition where selectedOption is not passed in, null or undefined.

  // only use the selected option if it is a current option
  const selectedOption =
    (selected && options.find((opt) => opt.value === selected.value)) || getDefaultPaymentSource(options);

  // select the default option if none provided
  if (!selected) {
    onChange(selectedOption);
  }
    */

  return {
    options,
    onChange,
    selectedOption,
    isClearable: false,
    stateless: true,
    disabled,
  };
};

const PaymentSourceDropdownContainer = withReduxStore(withTranslation()(connect(mapStateToProps)(DropDownList)));

PaymentSourceDropdownContainer.displayName = 'PaymentSourceDropdownContainer';

PaymentSourceDropdownContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.oneOf([null])]),
  matterId: PropTypes.string,
  accountType: PropTypes.string,
  disabled: PropTypes.bool,
  paymentSources: PropTypes.array,
  includeCombined: PropTypes.bool,
  hasError: PropTypes.bool,
  effectiveDate: PropTypes.object,
};

PaymentSourceDropdownContainer.defaultProps = {
  selectedOption: undefined,
  matterId: undefined,
  hasError: undefined,
  accountType: undefined,
  disabled: false,
  paymentSources: undefined,
  includeCombined: true,
  effectiveDate: undefined,
};

export default PaymentSourceDropdownContainer;
