'use strict';

const invoiceHistoryFilters = ({
  startDate,
  endDate,
  showInvoicesFor,
  matter,
  contact,
  attorneyResponsible,
  areaOfLaw,
  invoiceStatus,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
}) => ({
  reportType: 'invoice-history',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  matterContactRadio: showInvoicesFor,
  matterOrContactInfo:
    (showInvoicesFor === 'matter' && { id: matter.id, display: matter.display }) ||
    (showInvoicesFor === 'contact' && { id: contact.id, display: contact.display }) ||
    {},
  attorney: {
    id: attorneyResponsible.value,
    display: attorneyResponsible.value === 'all' ? 'all staff' : attorneyResponsible.label,
  },
  areaOfLaw: { ids: areaOfLaw.value, display: areaOfLaw.label },
  invoiceStatus: invoiceStatus.value,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const cashBookPaymentsFilters = ({
  startDate,
  endDate,
  accountSelected,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showDeletedTransactions,
  hidePaidBy,
  runByEnteredDate,
  region,
}) => ({
  reportType: 'cash-book-payments',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  bankAccount: { id: accountSelected.value, display: accountSelected.label },
  transactionType: { id: 'payment', display: 'transactionType' },
  showTransactionsList: true,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  showDeletedTransactions,
  showComplianceDescriptions: true,
  hidePaidBy,
  runByEnteredDate,
  region,
});

const accountBalancesFilter = ({
  startDate,
  endDate,
  accountSelected,
  showTransactionsList,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showDeletedTransactions,
  hidePaidBy,
  region,
}) => ({
  reportType: 'account-balances',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  bankAccount: { id: accountSelected.value, display: accountSelected.label },
  transactionType: { id: 'all', display: 'transactionType' },
  showTransactionsList,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  showDeletedTransactions,
  showComplianceDescriptions: true,
  hidePaidBy,
  region,
});

const accountsReceivableFilter = ({
  startDate,
  endDate,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showInvoicesFor,
  attorneyResponsible,
  matter,
  contact,
}) => ({
  reportType: 'accounts-receivable',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  matterContactRadio: showInvoicesFor,
  matterOrContactInfo:
    (showInvoicesFor === 'matter' && { id: matter.id, display: matter.display }) ||
    (showInvoicesFor === 'contact' && { id: contact.id, display: contact.display }) ||
    {},
  attorney: {
    id: attorneyResponsible.value,
    display: attorneyResponsible.value === 'all' ? 'all staff' : attorneyResponsible.label,
  },
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const agingSummaryFilter = ({ attorneyResponsible, includeFirmHeader, includeFirmLogo, includeFirmName }) => ({
  reportType: 'aging-summary',
  attorney: {
    id: attorneyResponsible.value,
    display: attorneyResponsible.value === 'all' ? 'all staff' : attorneyResponsible.label,
  },
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const auditLogsFilter = ({ startDate, endDate, includeFirmHeader, includeFirmLogo, includeFirmName }) => ({
  reportType: 'audit-logs',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const incomeByAttorneyFilter = ({
  startDate,
  endDate,
  includedStaffIds,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showPercentage,
  showHours,
}) => ({
  reportType: 'income-by-attorney',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  includedStaffIds,
  showHours,
  showPercentage,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const ledgerExportFilter = ({
  startDate,
  endDate,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showInvoicesFor,
  matter,
  contact,
  runByEnteredDate,
}) => ({
  reportType: 'ledger-export',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  matterContactRadio: showInvoicesFor,
  matterOrContactInfo:
    (showInvoicesFor === 'matter' && { id: matter.id, display: matter.display }) ||
    (showInvoicesFor === 'contact' && { id: contact.id, display: contact.display }) ||
    {},
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  runByEnteredDate,
});

const matterBalancesFilter = ({
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  attorneyResponsible,
  matterStatus,
  viewOption,
  excludeZeroBalanceMatters,
  excludeIncompleteMatters,
  showMatterDebt,
  excludeExternallyBilled,
  splitUnbilledAmounts,
}) => ({
  reportType: 'matter-balances',
  attorney: {
    id: attorneyResponsible.value,
    display: attorneyResponsible.value === 'all' ? 'all staff' : attorneyResponsible.label,
  },
  matterStatus: { id: matterStatus.value, display: matterStatus.label },
  matterBalancesViewOption: viewOption,
  filterMattersWoBalances: excludeZeroBalanceMatters,
  filterIncompleteMatters: excludeIncompleteMatters,
  excludeExternallyBilled,
  showMatterDebt,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  splitUnbilledAmounts,
});

const overdrawTrustAccountFilter = ({
  startDate,
  endDate,
  accountSelected,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  firmName,
  runByEnteredDate,
}) => ({
  reportType: 'overdraw-trust-account',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  bankAccount: { id: accountSelected.value, display: accountSelected.accountName },
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  reportFirm: firmName,
  runByEnteredDate,
});

const trialBalanceFilter = ({
  endDate,
  mattersWithNoBalance,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  runByEnteredDate,
  bankAccountId,
}) => ({
  reportType: 'trial-balance',
  toDate: endDate && String(endDate),
  showZeroBalance: mattersWithNoBalance,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  runByEnteredDate,
  bankAccountId,
});

const trustReceiptsFilter = ({
  startDate,
  endDate,
  accountSelected,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
}) => ({
  reportType: 'trust-receipts',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  bankAccount: { id: accountSelected.value, display: accountSelected.accountName },
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const receiptsCashBookFilters = ({
  startDate,
  endDate,
  accountSelected,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showDeletedTransactions,
  runByEnteredDate,
  region,
}) => ({
  reportType: 'receipts-cash-book',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  bankAccount: { id: accountSelected.value, display: accountSelected.label },
  transactionType: {
    id: 'receipt',
    display: 'transactionType',
  },
  showTransactionsList: true,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  showDeletedTransactions,
  runByEnteredDate,
  region,
});

const journalTransfersFilters = ({
  startDate,
  endDate,
  accountSelected,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showDeletedTransactions,
  hidePaidBy,
  region,
}) => ({
  reportType: 'journal-transfers',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  bankAccount: { id: accountSelected.value, display: accountSelected.label },
  transactionType: { id: 'transfer', display: 'transactionType' },
  showTransactionsList: true,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  showDeletedTransactions,
  showComplianceDescriptions: true,
  hidePaidBy,
  region,
});

const accountStatementFilter = ({
  startDate,
  endDate,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showInvoiceHistory,
  showInvoiceSummary,
  showAccountSummary,
  showTransactionHistory,
  showInvoiceNumbersForTransactions,
  showFeesExpenses,
  showTimeKeeperInitials,
  showDeletedTransactions,
  showLedger,
  showTrustAccountName,
  showInvoicesFor,
  matter,
  contact,
  hidePaidBy,
  runByEnteredDate,
}) => ({
  reportType: 'account-statement',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  matterContactRadio: showInvoicesFor,
  matterOrContactInfo: (showInvoicesFor === 'matter' && { id: matter.id, display: matter.display }) ||
    (showInvoicesFor === 'contact' && { id: contact.id, display: contact.display }) || {
      id: null,
      display: '',
    },
  showInvoiceHistory,
  showInvoiceSummary,
  showAccountSummary,
  showTransactionHistory,
  showDeletedTransactions,
  showTimeKeeperInitials,
  showTrustAccountName,
  showLedger,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  showTransactionHistoryWithInvoiceNumbers: showInvoiceNumbersForTransactions,
  showFeesAndExpenses: showFeesExpenses,
  showExpenseTimeKeeperInitials: false,
  hidePaidBy,
  runByEnteredDate,
});

const trustStatementFilter = ({
  startDate,
  endDate,
  includeFirmHeader,
  includeFirmLogo,
  showInvoiceNumbersForTransactions,
  showInvoicesFor,
  matter,
  contact,
  hidePaidBy,
  sendTo,
  runByEnteredDate,
}) => ({
  reportType: 'trust-statement',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  matterContactRadio: showInvoicesFor,
  sendTo: showInvoicesFor === 'allMatters' ? sendTo : undefined,
  matterOrContactInfo: (showInvoicesFor === 'matter' && { id: matter.id, display: matter.display }) ||
    (showInvoicesFor === 'contact' && { id: contact.id, display: contact.display }) || {
      id: null,
      display: '',
    },
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  showTransactionHistoryWithInvoiceNumbers: showInvoiceNumbersForTransactions,
  hidePaidBy,
  runByEnteredDate,
});

const timeFeesExpensesFilter = ({
  startDate,
  endDate,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  includeLeadMatters,
  excludeExternallyBilled,
  showInvoicesFor,
  showEntriesList,
  showDescriptionField,
  attorneyResponsible,
  areaOfLaw,
  entryType,
  matter,
}) => ({
  reportType: 'time-fees-expenses',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  staffMatterRadio: showInvoicesFor,
  staffOrMatterInfo:
    (showInvoicesFor === 'matter' && { id: matter.id, display: matter.display, type: showInvoicesFor }) ||
    (showInvoicesFor === 'staff' && {
      id: attorneyResponsible.value,
      display: attorneyResponsible.label,
      type: showInvoicesFor,
    }),
  areaOfLaw: { ids: areaOfLaw.value, display: areaOfLaw.label },
  typeOfEntries: { id: entryType.value, display: entryType.label },
  showEntriesList,
  showDescriptionField,
  showLeadMatters: includeLeadMatters,
  excludeExternallyBilled,
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const creditHistoryFilter = ({
  startDate,
  endDate,
  includeFirmHeader,
  includeFirmLogo,
  includeFirmName,
  showEntriesFor,
  matter,
  contact,
}) => ({
  reportType: 'credit-history',
  fromDate: startDate && String(startDate),
  toDate: endDate && String(endDate),
  matterContactRadio: showEntriesFor,
  matterOrContactInfo:
    (showEntriesFor === 'matter' && { id: matter.id, display: matter.display }) ||
    (showEntriesFor === 'contact' && { id: contact.id, display: contact.display }) ||
    {},
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
});

const cmaListingFilter = ({ endDate, includeFirmHeader, includeFirmLogo, includeFirmName, firmName }) => ({
  reportType: 'cma-listing',
  toDate: endDate && String(endDate),
  printOnLetterhead: includeFirmHeader,
  printFirmLogo: includeFirmLogo,
  printFirmName: includeFirmName,
  reportFirm: firmName,
});

const mapSmokeballFilters = {
  'invoice-history': invoiceHistoryFilters,
  'cash-book-payments': cashBookPaymentsFilters,
  'account-balances': accountBalancesFilter,
  'accounts-receivable': accountsReceivableFilter,
  'aging-summary': agingSummaryFilter,
  'audit-logs': auditLogsFilter,
  'income-by-attorney': incomeByAttorneyFilter,
  'ledger-export': ledgerExportFilter,
  'matter-balances': matterBalancesFilter,
  'overdraw-trust-account': overdrawTrustAccountFilter,
  'trial-balance': trialBalanceFilter,
  'trust-receipts': trustReceiptsFilter,
  'receipts-cash-book': receiptsCashBookFilters,
  'journal-transfers': journalTransfersFilters,
  'account-statement': accountStatementFilter,
  'trust-statement': trustStatementFilter,
  'time-fees-expenses': timeFeesExpensesFilter,
  'credit-history': creditHistoryFilter,
  'cma-listing': cmaListingFilter,
};

module.exports = {
  mapSmokeballFilters,
};
