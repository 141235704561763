angular.module('sb.billing.webapp').component('sbHookCloseTab',
  {
    bindings: { },
    require: { group: '^sbComposeGroup' },
    controller: function ($state, sbLoggerService, sbTabService) {
      'use strict';
      var ctrl = this, tab,
        log = sbLoggerService.getLogger('sbHookStateGo');

      ctrl.$onInit = function() {
        ctrl.group.setActionPostHook(closeTab);
        tab = sbTabService.deriveTab($state.current, $state.params);
        log.info('derived tab: ', tab);
      };


      function closeTab() {
        sbTabService.close(tab);
      }
    }
  }
);
