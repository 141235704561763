import { isEditable as isInvoiceEditable } from '@sb-billing/business-logic/invoice/services';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';

angular.module('@sb-billing/services').service('sbInvoiceEditService', function (sbGenericEndpointService, sbLoggerService, sbUuidService) {
  'use strict';
  const that = this;
  const endpoint = '/billing/invoice/edit';
  const log = sbLoggerService.getLogger('sbInvoiceEditService');

  that.editInvoiceP = editInvoiceP;

  function isEditable(id) {
    return isInvoiceEditable(getInvoiceById(id), getTotalsForInvoiceId(id));
  }

  function getOpdates (id) {
    const cacheInvoice = getInvoiceById(id);
    const opdates = { sbInvoicingService: [cacheInvoice] };

    cacheInvoice.currentVersion.status = 'DRAFT';

    return opdates;
  }

  function editInvoiceP (invoiceId, matterId) {
    if (!isEditable(invoiceId)) {
      throw new Error(`Invoice is not editable`);
    }

    const changeset = getOpdates(invoiceId);

    const handleErr = function (err) {
      log.warn('error editing invoice %s', invoiceId);
      log.warn(err);
      throw err;
    };

    const versionId = sbUuidService.get();
    return sbGenericEndpointService
      .postPayloadP(endpoint, `${invoiceId}/${versionId}`, { matterId }, 'POST', { changeset })
      .catch(handleErr);
  }
});
