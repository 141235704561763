'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/external-reports';
  $stateProvider
    .state('home.billing.external-reports', {
      url: '/reporting?reportId',
      templateUrl: BASE_DIRECTORY + '/external-reports.html',
      controller: 'ExternalReportsController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'external-reports'],
      },
      params: {
        reportId: undefined
      }
    });
});
