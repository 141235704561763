import { regionType } from '@sb-itops/region';
import { billingType } from '@sb-billing/business-logic/matters/billing-config';

export const billingTypes = {
  EMPTY: '',
  ...billingType,
};

export const billingTypeOptions = [
  { label: 'Select ...', value: billingTypes.EMPTY },
  { label: billingTypes.FIXED_FEE, value: billingTypes.FIXED_FEE },
  { label: billingTypes.FIXED_FEE_PER_APPEARANCE, value: billingTypes.FIXED_FEE_PER_APPEARANCE },
  { label: billingTypes.TIME_BASED, value: billingTypes.TIME_BASED },
  { label: billingTypes.CONTINGENCY_$, value: billingTypes.CONTINGENCY_$ },
  { label: billingTypes.CONTINGENCY_P, value: billingTypes.CONTINGENCY_P },
  { label: billingTypes.NOT_BILLABLE, value: billingTypes.NOT_BILLABLE },
];

export const gbBillingTypeOptions = [
  { label: 'Select ...', value: billingTypes.EMPTY },
  { label: billingTypes.FIXED_FEE, value: billingTypes.FIXED_FEE },
  { label: billingTypes.TIME_BASED, value: billingTypes.TIME_BASED },
  { label: billingTypes.CONDITIONAL_FEE_AGREEMENT, value: billingTypes.CONDITIONAL_FEE_AGREEMENT },
  { label: billingTypes.NOT_BILLABLE, value: billingTypes.NOT_BILLABLE },
];

export const getBillingTypeOptions = (region) => {
  const billingTypeOptionsByRegion = {
    [regionType.US]: billingTypeOptions,
    [regionType.AU]: billingTypeOptions,
    [regionType.GB]: gbBillingTypeOptions,
  };

  return billingTypeOptionsByRegion[region];
};
