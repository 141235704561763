'use strict';

angular.module('sb.billing.webapp').directive('sbAccountTransactionList', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: { listId: '<', provider: '<', showMatter: '<', showContact: '<', listType: '<', showHidden: '<' },
    controller: 'SbAccountTransactionListController',
    controllerAs: 'transactionList',
    templateUrl: 'ng-components/account-transaction-list/account-transaction-list.html'
  };
});
