import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').factory('generateReportOp', ($http, sbReportingService, sbNotifiedOperationP, sbLoggerService, sbUuidService, sbLocalisationService) => {
  return (reportFormat, reportData) => {
    const log = sbLoggerService.getLogger('generateReportOp');
    const requestId = sbUuidService.get();
    const timeoutMs = 60000;

    const t = sbLocalisationService.t;

    // These are used for the downloaded file name in bottom bar in generate-report-box.js
    const reportTypeToLabel = {
      'invoice-history': 'Invoice History',
      'accounts-receivable': 'Account Receivable',
      'aging-summary': 'Aging Summary',
      'matter-balances': 'Matter Balances',
      'account-statement': 'Account Statements',
      'trust-statement': t('report.trustStatements.label'),
      'time-fees-expenses': t('report.timeFeesExpenses.label'),
      'account-balances': 'Account Balances',
      'journal-transfers': t('report.journalTransfers.label'),
      'trust-receipts': t('report.trustReceipts.label'),
      'trial-balance': t('report.trialBalance.label'),
      'income-by-attorney': 'Income Allocation',
      'receipts-cash-book': t('report.receiptsCashBook.label'),
      'cash-book-payments': t('report.cashBookPayments.label'),
      'ledger-export': 'Ledger Export',
      'trust-ledger': getLedgerLabel(t),
      'audit-logs': 'Audit Logs',
      'overdraw-trust-account': 'Overdrawn',
      'operating-cheques': t('capitalizeAllWords', { val: 'operatingCheques' }),
      'credit-history': 'Credit History',
      'protected-funds-listing': 'Protected Funds Listing',
      ...((hasFacet(facets.CMA) && {'cma-listing': t('report.cmaListing.label')}) || {})
    };

    // Object representing the current state of this operation.
    const operation = {
      reportType: `${reportData.reportType}`,
      reportFormat,
      label: `${reportTypeToLabel[reportData.reportType]}`,
      isComplete: false,
      error: null,
      fileData: null,
    };

    // Kick off the operation
    sbNotifiedOperationP(startGenerateReport, {
      requestId,
      completionNotification: 'ReportReadyNotification',
      progressNotification: 'ReportProgressNotification',
      errorNotification: 'ReportFailureNotification',
      timeoutMs
    })
      .then(onGenerateReportComplete, undefined, onGenerateReportProgress)
      .catch(onError);

    return operation;

    // Returns a promise for creating a file containing the report data (pdf or csv). Used by sbNotifiedOperation to begin processing.
    function startGenerateReport() {
      return sbReportingService.downloadReportFileP(requestId, reportFormat, reportData);
    }

    // Called by sbNotifiedOperation whenever some progress is made during the generate report operation.
    function onGenerateReportProgress(/*msg*/) {
      // noop atm.
    }

    // Called by sbNotifiedOperation when the report generation operation completes.
    // Note that we still have a bit of work to do when the report file is ready,
    // mainly downloading the document content from S3 as base64.
    function onGenerateReportComplete(msg) {
      const payload = msg.payload;
      const request = {
        method: 'GET',
        url: payload.reportUrl,
        responseType: 'arraybuffer'
      };

      log.info('send request', request);
      return $http(request)
        .then(response => {
          const file = new Blob([response.data], {type: reportFormat === 'pdf' ? 'application/pdf' : 'text/csv;charset=utf-8;'});
          operation.isComplete = true;
          operation.fileData = file; 
        });
    }

    // The hopefully never called error handler.
    function onError(err) {
      log.error(`Failed to generate ${reportFormat} report for ${reportData.reportType} with requestId: ${requestId}`, err);
      operation.error = err;
      operation.isComplete = true;
    }

    function getLedgerLabel(t) {
      const bankAccount = getBankAccountById(reportData.bankAccountId) || {};
      if (bankAccount.accountType === bankAccountTypeEnum.CONTROLLEDMONEY) {
        return `${t('controlledMoney')} Movements Record`;
      }
      
      return t('report.trustLedger.label');
    }
  };
});
