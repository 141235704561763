import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
// getContactSummaryById has contacts in the manipuated/flattened summary format like billing uses
import { getById as getContactSummaryById } from '@sb-customer-management/redux/contacts-summary';
// getContactById has the contacts in (or smilar to) the DB / SB format
import { getById as getContactById } from '@sb-customer-management/redux/contacts';

// sbContactsMbService AKA contact summary
const { opdateCache: opdateContactsMbService } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

// sbSimpleContactMbService AKA ironically the complex contact entity cache (includes address)
const { opdateCache: opdateSimpleContactMbService } = optimisticUpdateFactory({
  ngCacheName: 'sbSimpleContactMbService',
  keyPath: 'entityId',
});

export const onPatchSmokeballContact = async ({ commandMessage }) => {
  const contactTarget = commandMessage.smokeballContact.person || commandMessage.smokeballContact.company;
  const email = (contactTarget && contactTarget.email) || null;

  const id = commandMessage.contactId;

  if (!id) {
    return;
  }

  // this section deals with updating the "summary"
  const cachedSummary = getContactSummaryById(id);
  if (cachedSummary) {
    opdateContactsMbService({ optimisticEntities: [{...cachedSummary, email}] });
  }
  
  // this section deals with updating the "simple" 
  const cachedContact = getContactById(id);
  if (cachedContact) {
    if (cachedContact.company) {
      cachedContact.company.email  = email;
    }
    if (cachedContact.person) {
      cachedContact.person.email  = email;
    }
    opdateSimpleContactMbService({ optimisticEntities: [cachedContact] });
  }
};
