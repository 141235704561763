import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './OrderedList.module.scss';

const OrderedList = ({ list, idKey, displayKey, disabled, onListChanged }) => {
  const moveItemIndex = (indexToMove, targetIndex) => {
    const reorderedList = [...list];
    reorderedList.splice(indexToMove, 1);
    reorderedList.splice(targetIndex, 0, list[indexToMove]);
    onListChanged(reorderedList);
  };

  const enableReordering = !disabled;

  return (
    <>
      {list.map((listItem, index) => (
        <div
          className={styles.itemContainer}
          key={`ordered-list-item-${index}-${listItem[idKey]}`}
          id={`ordered-list-item-${index}-${listItem[idKey]}`}
        >
          {enableReordering && (
            <div
              onClick={index > 0 ? () => moveItemIndex(index, index - 1) : () => {}}
              className={classnames(
                styles.itemHandle,
                index > 0 && enableReordering ? styles.showPointerAsCursor : undefined,
                'icon',
                index > 0 ? 'icon-arrow-3' : undefined, // show up icon if not first item
                enableReordering ? undefined : styles.showGreyedOutIcon,
              )}
            />
          )}
          <div className={styles.itemDisplay}>
            {' '}
            {listItem[displayKey] || listItem[idKey] || `Unamed item ${index}`}{' '}
          </div>
        </div>
      ))}
    </>
  );
};

OrderedList.displayName = 'OrderedList';

OrderedList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  idKey: PropTypes.string,
  displayKey: PropTypes.string,
  disabled: PropTypes.bool,
  onListChanged: PropTypes.func.isRequired,
};

OrderedList.defaultProps = {
  idKey: 'id',
  displayKey: 'display',
  disabled: false,
};

export default OrderedList;
