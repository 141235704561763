angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/debug';

  $stateProvider
    .state('home.billing.support.debug', {
      url: '/debug',
      templateUrl: BASE_DIRECTORY + '/debug.html',
      data: {
        authorized: true,
      }
    });

});