import * as React from 'react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { getAccountId } from 'web/services/user-session-management';

const log = getLogger('useUploadFile');

function getFileExtension({ fileName }) {
  if (!fileName) {
    return undefined;
  }

  const parts = fileName.split('.');
  if (parts.length < 2) {
    return undefined;
  }

  const fileExtension = parts[parts.length - 1];
  return fileExtension && fileExtension.toLowerCase();
}

async function convertFileToBase64({ file }) {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (event) => {
      const fileInBase64 = event.target.result.split(',')[1]; // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
      resolve(fileInBase64);
    };
    reader.readAsDataURL(file);
  });
}

export function useUploadFile() {
  const accountId = getAccountId();
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);

  async function uploadFile({ entityFileType, entityId, entityType, file, fileId }) {
    const fileName = file.name;
    const fileInBase64 = await convertFileToBase64({ file });
    const fileExtension = getFileExtension({ fileName });
    const path = `/itops/files/${accountId}/FileUploads`;
    const body = {
      entityType,
      entityId,
      entityFileType,
      fileId,
      fileName,
      fileExtension,
      fileInBase64,
    };
    const fetchOptions = {
      dataType: 'json',
      body: JSON.stringify(body),
    };

    try {
      setIsUploadingFile(true);
      const response = await fetchPostP({ path, fetchOptions });
      return response && response.body && response.body.filePath;
    } catch (error) {
      log.error('Error occurred while uploading file', error);
      // there not much we can do to help the user other than letting them know
      displayErrorToUser(`Failed to upload file. Please try uploading a different file.`);
    } finally {
      setIsUploadingFile(false);
    }

    return undefined;
  }

  return {
    isUploadingFile,
    uploadFile,
  };
}
