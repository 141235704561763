/* eslint-disable no-param-reassign */
import moment from 'moment';
import store from '../store';
import { domain } from '../domain';
import utilsFactory from '../utils-factory';
import { broadcastUpdates } from '../cache-update';

const { createActionName } = utilsFactory({ domain, name: 'optimistic-update' });

export const OPDATE_CACHE = createActionName('OPDATE_CACHE');
export const ROLLBACK_OPDATE_CACHE = createActionName('ROLLBACK_OPDATE_CACHE');

export const optimisticUpdateFactory = ({ ngCacheName, keyPath }) => {
  const opdateCache = ({ optimisticEntities = [] }) => {
    store.dispatch({ type: OPDATE_CACHE, payload: optimisticEntities, meta: { ngCacheName, keyPath } });
    broadcastUpdates({ payload: optimisticEntities, ngCacheName, keyPath });
  };

  const rollbackOpdateCache = ({ optimisticEntities = [] }) => {
    store.dispatch({ type: ROLLBACK_OPDATE_CACHE, payload: optimisticEntities, meta: { ngCacheName, keyPath } });
    broadcastUpdates({ payload: optimisticEntities, ngCacheName, keyPath });
  };

  const opdateReducer = (state, action) => {
    if (action.meta.ngCacheName !== ngCacheName) {
      return state;
    }

    // Action payload is expected to be an array of objects containing the changed props for one or more entities.
    // Minimum expected is the entity version ID which is used to locate the existing entity version in redux.
    // If no entity exists with the matching ID, a new optimistic one is created.
    const opdatedEntities = action.payload.reduce((accOpdatedEntities, changesToEntity) => {
      const entityId = changesToEntity[keyPath];
      const currentEntity = state.entities && state.entities[entityId];
      const $optimistic = moment().toISOString();

      // Optimistically creating a new entity.
      if (!currentEntity) {
        accOpdatedEntities[entityId] = { ...changesToEntity, $optimistic };
        return accOpdatedEntities;
      }

      // If the current entity is a remote version, i.e. not an optimistic version, we will store
      // the remote version in the new optimistic version to facilitate rollback of opdates.
      // The $optimistic property always gets update to reflect optimistic timestamp changes.
      const additionalProps = currentEntity.$optimistic
        ? { $optimistic }
        : { $optimistic, lastRemoteVersion: { ...currentEntity } };

      accOpdatedEntities[entityId] = { ...currentEntity, ...changesToEntity, ...additionalProps };
      return accOpdatedEntities;
    }, {});

    const entities = { ...state.entities, ...opdatedEntities };
    return { ...state, entities };
  };

  const rollbackOpdateReducer = (state, action) => {
    if (action.meta.ngCacheName !== ngCacheName) {
      return state;
    }

    const nextEntities = { ...state.entities };
    action.payload.forEach(({ [keyPath]: entityId }) => {
      const entityInCache = state.entities[entityId];
      if (!entityInCache || !entityInCache.$optimistic) {
        return;
      }

      // If the entity in the cache was created optimistically, we need to remove it from the cache.
      // Otherwise replace it with the last good remote entity version.
      if (!entityInCache.lastRemoteVersion) {
        delete nextEntities[entityId];
      } else {
        nextEntities[entityId] = entityInCache.lastRemoteVersion;
      }
    });

    return { ...state, entities: nextEntities };
  };

  return {
    opdateCache,
    opdateReducer,
    rollbackOpdateCache,
    rollbackOpdateReducer,
    OPDATE_CACHE,
    ROLLBACK_OPDATE_CACHE,
  };
};
