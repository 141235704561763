import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select, Translation } from '@sb-itops/react';
import Styles from './MatterTypeahead.module.scss';

const dictionary = {
  open: 'Opened',
};

export const formatDateItem = ({ date, t }) => {
  if (!date) {
    return '';
  }
  // Format date already in ISO string format.
  const dateAsIsoString = typeof date === 'string' ? date : date.toISOString();
  return t('date', { ts: dateAsIsoString, inUtc: true });
};

export const getStatusLabel = (status) => {
  const matterStatus = status?.toLowerCase();
  return !matterStatus || matterStatus === 'open' ? '' : `(${status})`;
};

export const getMatterLabel = (display, status) => [display, getStatusLabel(status)].join(' ');

export const labelFormatHandler = ({ data }, { context }) => {
  const { display, status, matterStarted, matterClientNames, clientDisplay, isLead } = data;
  const matterLabel = getMatterLabel(display, status);
  const statusLabel = getStatusLabel(status);
  const getFormattedDate = (t) => formatDateItem({ date: matterStarted, t });
  const getSelectLabel = (t) => `${matterLabel} - ${dictionary.open} ${getFormattedDate(t)}`;

  if (context === 'value') {
    return (
      <Translation>
        {(t) => (
          <div className="matter-typeahead-text" title={getSelectLabel(t)}>
            {getSelectLabel(t)}
          </div>
        )}
      </Translation>
    );
  }

  return (
    <Translation>
      {(t) => (
        <div style={{ display: 'flex' }}>
          <div className={Styles.groupDisplayIcon} title={matterLabel}>
            <i className={isLead ? 'icon-lead' : 'icon-matter'} />
          </div>
          <div className={Styles.groupDisplay} title={matterLabel}>
            <div className={Styles.groupLabel}>{matterLabel}</div>
            <div className={Styles.groupClient}>Client: {matterClientNames || clientDisplay}</div>
            <div className={Styles.groupDisplayDate}>
              {dictionary.open} {getFormattedDate(t)} {statusLabel}
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const MatterTypeahead = ({
  actionList,
  className,
  containerClassName,
  defaultMatters,
  disabled,
  hasError,
  isClearable,
  isLoading,
  matters,
  maxMenuHeight,
  onInputChange,
  onMatterSelected,
  placeholder,
  selectedMatterId,
}) => {
  const generateOptions = (_matters) => {
    if (_matters.length === 0) {
      return [];
    }
    return _matters.map((matter) => ({
      label: matter.display || matter.displayName,
      value: matter.id,
      data: matter,
    }));
  };

  const getOption = ({ matters: _matters, matterId }) => {
    if (_matters.length === 0 || !matterId) {
      return null; // null is required to clear matter select, undefined will not
    }

    return _matters.find((option) => option.value === matterId);
  };

  const options = useMemo(() => generateOptions(matters), [matters]);

  const selectedOption = useMemo(
    () => getOption({ matters: options, matterId: selectedMatterId }),
    [options, selectedMatterId],
  );

  const defaultOptions = useMemo(() => generateOptions(defaultMatters), [defaultMatters]);

  const defaultOption = useMemo(
    () => getOption({ matters: defaultOptions, matterId: selectedMatterId }),
    [defaultOptions, selectedMatterId],
  );

  return (
    <div className={classnames(Styles.container, containerClassName)}>
      <Select
        options={options}
        optionLabelHeight={70}
        formatOptionLabel={labelFormatHandler}
        disabled={disabled}
        defaultValue={selectedOption ?? defaultOption}
        onValueChange={onMatterSelected}
        placeholder={placeholder}
        className={className}
        hasError={hasError}
        isClearable={isClearable}
        maxMenuHeight={maxMenuHeight}
        actionList={actionList}
        onInputChange={(newValue) => onInputChange(newValue)}
        isLoading={isLoading}
        noOptionsMessage={() => 'Start typing to get a list of matters...'}
        isSearchable
        virtualizeList
        filterOption={() => true} // Show all values, we use isSearchable to send requests to the back-end and need to show all the options provided
      />
    </div>
  );
};

MatterTypeahead.displayName = 'MatterTypeahead';
const actions = PropTypes.shape({
  displayComponent: PropTypes.any,
  callback: PropTypes.func,
});

const MatterType = PropTypes.shape({
  display: PropTypes.any,
  displayName: PropTypes.any,
  id: PropTypes.string.isRequired,
});

MatterTypeahead.propTypes = {
  actionList: PropTypes.arrayOf(actions),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  defaultMatters: PropTypes.arrayOf(MatterType),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  matters: PropTypes.arrayOf(MatterType),
  maxMenuHeight: PropTypes.number,
  name: PropTypes.string,
  onClear: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  onMatterSelected: PropTypes.func,
  placeholder: PropTypes.string,
  selectedMatterId: PropTypes.string,
};

MatterTypeahead.defaultProps = {
  actionList: [],
  className: undefined,
  containerClassName: undefined,
  defaultMatters: [],
  disabled: false,
  hasError: false,
  isClearable: true,
  matters: [],
  maxMenuHeight: undefined,
  name: undefined,
  onClear: undefined,
  onMatterSelected: () => {},
  placeholder: 'Select a matter...',
  selectedMatterId: undefined,
};

export default MatterTypeahead;
