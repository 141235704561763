angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/preferences/my-details';

  $stateProvider
    .state('preferences.my-details', {
      templateUrl: BASE_DIRECTORY + '/my-details.html',
      url: '/my-details',
      data: {
        navHighlights: ['my-details']
      }
    });
});
