import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes, Spinner, useTranslation } from '@sb-itops/react';
import Styles from './ProductTier.module.scss';

export const ProductTier = ({
  className,
  thumbnail,
  tierName,
  priceInCents,
  pricingInterval,
  priceDescription,
  hidePriceInterval,
  highlighted,
  active,
  activeLabel,
  tierBlurb,
  tierBulletPointColour,
  tierBulletPoints,
  isPendingCancel,
  currentPeriodEnd,
  switchToTierLabel,
  switchToTierInProgress,
  onSwitchToTier,
  cancelSubscriptionInProgress,
  onCancelSubscription,
  cancelSubscriptionLabel,
  managePaymentMethodLabel,
  managePaymentMethodInProgress,
  hideManagePaymentMethod,
  onManagePaymentMethod,
}) => {
  const { t } = useTranslation();
  const formattedPrice = t('cents', { val: priceInCents });

  const discStyle = {
    background: tierBulletPointColour,
  };

  return (
    <div className={classnames(Styles.productTier, className, highlighted && Styles.highlighted)}>
      <div className={Styles.frame}>
        <div className={Styles.centeredContent}>
          <div className={Styles.titleSection}>
            <div className={Styles.nameAndPrice}>
              <div className={Styles.tierNameContainer}>
                <div className={Styles.labelContainer}>
                  <div className={Styles.label}>{tierName}</div>
                </div>
                {active && (
                  <div className={Styles.activeBadgeContainer}>
                    <div className={Styles.activeBadge}>{activeLabel}</div>
                  </div>
                )}
              </div>

              <div className={Styles.tierPrice}>
                <div className={Styles.priceContainer}>
                  <div className={Styles.price}>{formattedPrice}</div>
                </div>
                {!hidePriceInterval && (
                  <div className={Styles.unitContainer}>
                    <span className={Styles.unit}>Per user</span>
                    <span className={Styles.unit}>{pricingInterval}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={Styles.tierImage}>
              <img src={thumbnail} alt={tierName} />
            </div>
          </div>
          {priceDescription && (
            <div className={Styles.priceDescriptionContainer}>
              <span className={Styles.priceDescription}>{priceDescription}</span>
            </div>
          )}
          {!active && !isPendingCancel && (
            <Button
              onClick={onSwitchToTier}
              disabled={switchToTierInProgress}
              className={Styles.button}
              size="full-width"
            >
              {switchToTierLabel}
              {'  '}
              {switchToTierInProgress && <Spinner small />}
            </Button>
          )}
          {active && <div className={Styles.sectionSeparator} />}
          <div className={Styles.bodySection}>
            <div className={Styles.tierBlurb}>{tierBlurb}</div>
            <div className={Styles.tierBulletPoints}>
              {tierBulletPoints.map((tierBulletPoint, key) => (
                <div key={key} className={Styles.tierBulletPointItem}>
                  <div className={Styles.disc} style={discStyle} />
                  <div className={Styles.label}>{tierBulletPoint}</div>
                </div>
              ))}
            </div>
          </div>
          {active && <div className={Styles.sectionSeparator} />}
          {active && (
            <div className={Styles.managementButtons}>
              {!hideManagePaymentMethod && !isPendingCancel && (
                <Button
                  onClick={onManagePaymentMethod}
                  disabled={managePaymentMethodInProgress}
                  size="full-width"
                  type={buttonTypes.link}
                >
                  {managePaymentMethodLabel}
                  {'  '}
                  {managePaymentMethodInProgress && <Spinner small />}
                </Button>
              )}
              {!isPendingCancel && (
                <Button
                  onClick={onCancelSubscription}
                  disabled={cancelSubscriptionInProgress}
                  size="full-width"
                  type={buttonTypes.link}
                >
                  {cancelSubscriptionLabel}
                  {'  '}
                  {cancelSubscriptionInProgress && <Spinner small />}
                </Button>
              )}
              {isPendingCancel && (
                <div>
                  <p>Your subscription will expire on {t('date', { ts: currentPeriodEnd })}.</p>
                  <p>Please contact Smokeball support to continue your subscription.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProductTier.displayName = 'ProductTier';

ProductTier.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  tierName: PropTypes.string.isRequired,
  priceInCents: PropTypes.number.isRequired,
  pricingInterval: PropTypes.string,
  priceDescription: PropTypes.string,
  hidePriceInterval: PropTypes.bool,
  className: PropTypes.string,
  tierBlurb: PropTypes.string.isRequired,
  tierBulletPointColour: PropTypes.string.isRequired,
  tierBulletPoints: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isPendingCancel: PropTypes.bool,
  currentPeriodEnd: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  active: PropTypes.bool,
  activeLabel: PropTypes.string,
  switchToTierLabel: PropTypes.string,
  switchToTierInProgress: PropTypes.bool,
  onSwitchToTier: PropTypes.func.isRequired,
  cancelSubscriptionInProgress: PropTypes.bool,
  onCancelSubscription: PropTypes.func.isRequired,
  hideManagePaymentMethod: PropTypes.bool,
  managePaymentMethodLabel: PropTypes.string,
  cancelSubscriptionLabel: PropTypes.string,
  managePaymentMethodInProgress: PropTypes.bool,
  onManagePaymentMethod: PropTypes.func.isRequired,
};

ProductTier.defaultProps = {
  className: undefined,
  highlighted: false,
  active: false,
  activeLabel: 'Current plan',
  pricingInterval: 'per month',
  priceDescription: undefined,
  isPendingCancel: false,
  switchToTierLabel: 'Upgrade Now',
  managePaymentMethodLabel: 'Update Credit Card',
  cancelSubscriptionLabel: 'Cancel Subscription',
  hideManagePaymentMethod: false,
  switchToTierInProgress: false,
  cancelSubscriptionInProgress: false,
  managePaymentMethodInProgress: false,
  hidePriceInterval: false,
};
