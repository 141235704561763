import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { services } from '@sb-billing/business-logic/report';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import ReportRunnerSmokeballBilling from './ReportRunnerSmokeballBilling';

const { mapSmokeballFilters, getDownloadPdfOptions } = services;

const defaultReportState = {
  cachedHtml: '',
};

const mapStateToProps = (state, { reportConfig, reportState: currentReportState }) => {
  const reportState = currentReportState || defaultReportState;
  const { cachedHtml, filters, reportGenerationInProgress } = reportState;

  return {
    reportConfig,
    cachedHtml,
    filters,
    showLoadingSpinner: reportGenerationInProgress,
  };
};

const mapDispatchToProps = (
  dispatch,
  { setReportState, reportConfig, reportState, sbReportingService, sbAsyncOperationsService, t },
) => ({
  onFiltersChanged: (filters) => dispatch(setReportState({ filters })),
  onGenerateReport: async () => {
    const submitForm = mapSmokeballFilters[reportConfig.reportId](reportState.filters);
    const reportGenerationId = uuid();
    dispatch(setReportState({ reportGenerationInProgress: true, cachedHtml: undefined, reportGenerationId }));

    try {
      const { template: cachedHtml } = await sbReportingService.generateReportP({ ...submitForm, reportGenerationId });
      dispatch(setReportState({ cachedHtml }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      messageDisplay.error(
        messageDisplay
          .builder()
          .text('Failed to generate report')
          .conditionalText('{0}', err.message ? `: ${err.message}` : ''),
      );
    } finally {
      dispatch(setReportState({ reportGenerationInProgress: false }));
    }
  },
  onDownloadCsv: () =>
    sbAsyncOperationsService.startGenerateReport('csv', {
      reportType: reportConfig.reportId,
      reportGenerationId: reportState.reportGenerationId,
    }),
  onDownloadPdf: () =>
    sbAsyncOperationsService.startGenerateReport('pdf', {
      reportType: reportConfig.reportId,
      reportGenerationId: reportState.reportGenerationId,
      pdfOptions: getDownloadPdfOptions({
        reportId: reportConfig.reportId,
        interestOnInvoicesEnabled: hasFacet(facets.interest),
        surchargeEnabled: featureActive('BB-7270'),
        t,
      }),
    }),
  onEmailReport: async () => {
    const submitForm = mapSmokeballFilters[reportConfig.reportId](reportState.filters);
    dispatch(setReportState({ reportGenerationInProgress: true, cachedHtml: undefined }));

    try {
      const doDownload = false;
      await sbReportingService.generateReportP(submitForm, doDownload);
      messageDisplay.success(messageDisplay.builder().text('We will email you a link when your report is ready'));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      messageDisplay.error(
        messageDisplay
          .builder()
          .text('Failed to submit')
          .conditionalText('{0}', err.data && err.data.message ? `: ${err.data.message}` : ''),
      );
    } finally {
      dispatch(setReportState({ reportGenerationInProgress: false }));
    }
  },
});
export const ReportRunnerSmokeballBillingContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportRunnerSmokeballBilling)),
);

ReportRunnerSmokeballBillingContainer.displayName = 'ReportRunnerSmokeballBillingContainer';

ReportRunnerSmokeballBillingContainer.propTypes = {
  reportConfig: PropTypes.object.isRequired,
  reportState: PropTypes.object,
  setReportState: PropTypes.func.isRequired,
};

ReportRunnerSmokeballBillingContainer.defaultProps = {
  reportState: undefined,
};

export default ReportRunnerSmokeballBillingContainer;
