'use strict';

const sbbEnvironment = Object.freeze({
  DEV: 'DEV',
  RC: 'RC',
  QA: 'QA',
  PRODUCTION: 'PRODUCTION',
  LOCAL: 'LOCAL',
});

module.exports = {
  sbbEnvironment,
};
