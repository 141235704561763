import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery } from 'web/hooks';
import { WidgetTasks } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import uuid from '@sb-itops/uuid';
import { useState } from 'react';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { TasksWidget } from './TasksWidget';

const log = getLogger('tasks.widget');

const hooks = ({ matterId }) => ({
  useGraphQLforTasks: () => {
    const [showTaskModal, setShowTaskModal] = useState(false);

    const { error, loading, data } = useSubscribedQuery(WidgetTasks, {
      variables: {
        taskFilter: {
          matterId,
          view: matterId ? 'ALL' : 'OVERDUE',
          assigneeIds: matterId ? undefined : [getLoggedInStaff()?.id],
        },
        offset: 0,
        limit: 20,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const tasks = data?.taskList?.results || [];

    return {
      showTaskModal,
      setShowTaskModal,
      onCompleteTask: async (task) => {
        try {
          const marshalledData = {
            id: task.id,
            versionId: uuid(),
            matterId: task.matterId,
            subject: task.subject,
            note: task.note,
            isRemoved: false,
            assigneeIds: task.assigneeIds,
            dueDate: task.dueDate,
            categories: task.categories,
            subTaskIds: task.subTaskIds,
            creationTimeStamp: task.creationTimeStamp || moment().toISOString(),
            isNewEntity: false,
            lastAssignedById: task?.lastAssignedById || '',
            isCompleted: true,
          };
          await dispatchCommand({
            type: 'Tasking.ManageTasks.Messages.SaveTasks2',
            message: marshalledData,
          });
          displaySuccessToUser('Task completed');
        } catch (err) {
          displayErrorToUser('Failed to complete task. Please try again later');
          log.error(err);
        }
      },
      tasks,
      loading,
    };
  },
});

export const TasksWidgetContainer = withApolloClient(composeHooks(hooks)(TasksWidget));

TasksWidgetContainer.displayName = 'TasksWidget.container';
