'use strict';

const getChargeErrorMessage = ({ defaultMessage, chargeStatusResult }) => {
  const { providerResponse: feeWiseResponse } = chargeStatusResult;

  const message = feeWiseResponse.type === 'card_error' ? feeWiseResponse.detail : defaultMessage;

  return { message };
};

module.exports = {
  getChargeErrorMessage,
};
