import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TransactionNumberingSettings } from './TransactionNumberingSettings';

const mapStateToProps = (state, { scope, formState, prefixesWithWarnings, exampleNumbers, onChange, onSave }) => ({
  ...formState,
  scope,
  prefixesWithWarnings,
  exampleNumbers,
  onChange,
  onSave,
});

export const TransactionNumberingSettingsContainer = connect(mapStateToProps)(TransactionNumberingSettings);

TransactionNumberingSettingsContainer.propTypes = {
  formState: PropTypes.object.isRequired,
  prefixesWithWarnings: PropTypes.shape({
    receipt: PropTypes.bool,
    transferOfFunds: PropTypes.bool,
    electronicPayments: PropTypes.bool,
    trustToOffice: PropTypes.bool,
  }).isRequired,
  exampleNumbers: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
};
