import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { todayAsInteger } from '@sb-itops/date';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { getRegion } from '@sb-itops/region';
import { connect } from 'react-redux';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';

import { getById as getAccountById } from '@sb-billing/redux/bank-account';
import { AddEditControlledMoneyAccountForm } from './AddEditControlledMoneyAccountForm';
import { AddEditControlledMoneyAccountSchema } from './AddEditControlledMoneyAccountSchema';

const mapStateToProps = (state, { scope }) => {
  const bankBranchNumberRegex = getBankAccountStrategy(getRegion(), bankAccountStrategies.BRANCH_NUMBER_REGEX);

  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formSubmitting, submitFailed } = formSelectors.getFormState(state);
  const formFieldValues = formSelectors.getFieldValues(state);

  const { accountOpenedDate, bankName, accountName, branchNumber, accountNumber } = formFields;

  const contactOptions = getContactTypeAheadSummaries().map(({ display: label, id: value, typeahead: searchText }) => ({
    value,
    label,
    searchText,
  }));

  return {
    scope,
    contactOptions,
    beneficiaryIds: formFieldValues?.beneficiaryIds || [],
    accountOpenedDate,
    bankName,
    accountName,
    branchNumber,
    accountNumber,
    formDisabled: formSubmitting,
    formInitialised,
    submitFailed,
    bankBranchNumberRegex,
  };
};

const mapDispatchToProps = (dispatch, { scope, clientCustomerIds, accountId }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);
  const bankBranchNumberRegex = getBankAccountStrategy(getRegion(), bankAccountStrategies.BRANCH_NUMBER_REGEX);

  return {
    onLoad: () => {
      const account = accountId ? getAccountById(accountId) : undefined;

      const fieldValues = {
        beneficiaryIds: account ? account.beneficiaryIds : clientCustomerIds,
        accountOpenedDate: account ? account.accountOpenedDate : todayAsInteger(),
        bankName: account ? account.bankName : undefined,
        accountName: account ? account.accountName : undefined,
        branchNumber: account ? account.branchNumber : undefined,
        accountNumber: account ? account.accountNumber : undefined,
      };
      dispatch(formActions.initialiseForm({ fieldValues }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
    },
    onBeneficiaryChange: (values) => {
      dispatch(formActions.setFieldValue({ field: 'beneficiaryIds', value: values }));
    },
    validateForm: () => {
      dispatch(
        formOperations.validateSchema({ schema: AddEditControlledMoneyAccountSchema({ bankBranchNumberRegex }) }),
      );
    },
  };
};

const AddEditControlledMoneyAccountFormContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(AddEditControlledMoneyAccountForm)),
);

AddEditControlledMoneyAccountFormContainer.displayName = 'AddEditControlledMoneyAccountFormContainer';

AddEditControlledMoneyAccountFormContainer.propTypes = {
  clientCustomerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  scope: PropTypes.string.isRequired,
  accountId: PropTypes.string,
};
AddEditControlledMoneyAccountFormContainer.defaultProps = {
  accountId: undefined,
};

export default AddEditControlledMoneyAccountFormContainer;
