import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, ConfirmModal, useTranslation } from '@sb-itops/react';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
// Temporary to handle disabling the expense cache - do not copy this pattern
import { getList as getExpenseList } from '@sb-billing/redux/expenses';

import { DebugInvoiceStatementPdfButton } from 'web/react-redux';
import { INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID } from 'web/react-redux/components/invoice-statement-payment-add-modal';
import { INVOICE_STATEMENT_EMAIL_MODAL_ID, INVOICE_STATEMENT_ADD_PAYMENT_MODAL_ID } from 'web/components';

import { featureActive } from '@sb-itops/feature';
import Styles from './InvoiceStatementActionBar.module.scss';

export const InvoiceStatementActionBar = ({
  invoiceStatementLoading,
  invoiceStatementId,
  openVoidConfirmationModal,
  closeVoidConfirmationModal,
  voidInvoiceStatement,
  showVoidConfirmationModal,
  showDebug,
  isDownloading,
  isVoid,
  downloadPdf,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames('ribbon panel', Styles.invoiceStatementActionBar)}>
      <Button
        disabled={invoiceStatementLoading || isVoid}
        onClick={() =>
          getExpenseList().length > 0 && !featureActive('BB-14708') && !featureActive('BB-14929')
            ? setModalDialogVisible({
                modalId: INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID, // Legacy modal
                props: { invoiceStatementId },
              })
            : setModalDialogVisible({
                modalId: INVOICE_STATEMENT_ADD_PAYMENT_MODAL_ID, // LOD modal
                props: { invoiceStatementId, scope: 'invoice-statement-payment-add-modal' },
              })
        }
      >
        Add Payment
      </Button>
      <Button
        disabled={invoiceStatementLoading || isVoid}
        onClick={() =>
          setModalDialogVisible({ modalId: INVOICE_STATEMENT_EMAIL_MODAL_ID, props: { invoiceStatementId } })
        }
      >
        Email
      </Button>
      <Button disabled={invoiceStatementLoading || isVoid} onClick={() => downloadPdf()} locked={isDownloading}>
        Download
      </Button>
      <div className={Styles.btnSpacer} />
      <Button disabled={invoiceStatementLoading || isVoid} onClick={() => openVoidConfirmationModal()}>
        Void
      </Button>
      <ConfirmModal
        title="Void this statement"
        isVisible={showVoidConfirmationModal}
        confirmBtnText="Void This Statement"
        onConfirm={() => voidInvoiceStatement(invoiceStatementId)}
        onCancel={() => closeVoidConfirmationModal()}
        message={`All invoices included on this statement will be removed and become available to add to new ${t(
          'clientStatements',
        ).toLowerCase()}.`}
      />
      {showDebug && (
        <>
          <div className={Styles.btnSpacer} />
          <DebugInvoiceStatementPdfButton invoiceStatementId={invoiceStatementId} />
        </>
      )}
    </div>
  );
};

InvoiceStatementActionBar.propTypes = {
  invoiceStatementLoading: PropTypes.bool.isRequired,
  invoiceStatementId: PropTypes.string.isRequired,
  openVoidConfirmationModal: PropTypes.func.isRequired,
  closeVoidConfirmationModal: PropTypes.func.isRequired,
  voidInvoiceStatement: PropTypes.func.isRequired,
  showVoidConfirmationModal: PropTypes.bool,
  showDebug: PropTypes.bool,
  isDownloading: PropTypes.bool,
  isVoid: PropTypes.bool,
  downloadPdf: PropTypes.func.isRequired,
};

InvoiceStatementActionBar.defaultProps = {
  showVoidConfirmationModal: false,
  showDebug: false,
  isDownloading: false,
  isVoid: false,
};

InvoiceStatementActionBar.displayName = 'InvoiceStatementActionBar';
