import { ValueOf } from 'type-fest';

import { getRegion, regionType } from '@sb-itops/region';

import { schemeKeys } from './schemes';

// eslint-disable-next-line no-restricted-syntax
for (const s of schemeKeys) {
  if (!s.match(/^[a-zA-Z]+Scheme$/)) {
    throw new Error(`Bad scheme kwy: ${s}`);
  }
}

type TRegions = keyof typeof regionType;
type TSchemeKey = typeof schemeKeys[number];

type TRegionMap<K extends string> = {
  [R in TRegions]: `${K}${R}`;
};

export type TSchemeValuesFor<K extends TSchemeKey> = ValueOf<TRegionMap<K>>;

export const schemeMappings = Object.freeze(
  schemeKeys.reduce(
    (acc, k) => {
      const entry = Object.freeze({
        AU: `${k}AU`,
        GB: `${k}GB`,
        US: `${k}US`,
      }) as TRegionMap<typeof k>;

      // eslint-disable-next-line no-param-reassign
      (acc[k] as TRegionMap<typeof k>) = entry;
      return acc;
    },
    {} as {
      [K in TSchemeKey]: TRegionMap<K>;
    },
  ),
);

type TOptions = undefined | { forcedRegion: TRegions };

const validateSchemeKey = <T extends TSchemeKey>(scheme: T) => {
  const isValidSchemeKey = schemeKeys.includes(scheme) && scheme.endsWith('Scheme');

  if (!isValidSchemeKey) {
    throw new Error(`SchemeKey ${scheme} not supported`);
  }
};

export const getSchemeValue = <T extends TSchemeKey>(scheme: T, options?: TOptions): ValueOf<TRegionMap<T>> => {
  validateSchemeKey(scheme);

  const region: TRegions = options?.forcedRegion || getRegion();

  if (!region) {
    throw new Error(`No region supplied`);
  }

  const regionMap = schemeMappings[scheme] as TRegionMap<T>;

  return regionMap[region];
};

export const testingGetAllValuesForKey = <T extends TSchemeKey>(scheme: T) => {
  validateSchemeKey(scheme);
  return Object.values(schemeMappings[scheme]);
};
