import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { getTotalsCents } from '@sb-billing/redux/invoice-totals';
import { getInvoiceCorrespondenceHistoryGroupedByDebtor } from '@sb-billing/redux/correspondence-history';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { statusByName as invoiceStatusByName, statusByValue as invoiceStatusByValue } from '@sb-billing/business-logic/invoice/entities';

/**
 * TODO: This file can be deleted as part of BB-14223 cleanup
 */
angular.module('sb.billing.webapp').component('sbDataInvoiceSummariesByNumbers', {
  require: {
    sbComposeCtrl: '^sbCompose'
  },
  bindings: { invoiceNumbers: '<', composeKey: '@' },
  controller: function($scope, sbLoggerService, sbInvoicingService) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbDataInvoiceSummariesByNumbers');
    const DEFAULT_COMPOSE_KEY = 'invoiceSummaries';
    let invoiceIds;

    $scope.$on('smokeball-data-update-sbInvoiceTotalsService', updateData);
    $scope.$on('smokeball-data-update-sbInvoicingService', updateData);
    $scope.$on('smokeball-data-update-sbCorrespondenceHistoryService', updateData);
    $scope.$on('smokeball-saved-invoices', updateData);

    that.$onChanges = function(changesObj) {
      const invoiceNumbers = _.isArray(changesObj.invoiceNumbers.currentValue)
        ? changesObj.invoiceNumbers.currentValue
        : [];
      invoiceIds = invoiceNumbers.map(invoiceNumber => sbInvoicingService.getInvoiceIDByNumber(invoiceNumber));
      updateData();
    };

    function updateData() {
      log.info('fetching invoice summaries for invoice ids : ', invoiceIds);
      const invoiceSummaries = (invoiceIds || []).reduce((acc, invoiceId) => {
        const invoiceSummary = getInvoiceById(invoiceId);
        const debtorIds = invoiceSummary.currentVersion.debtors.map(({ id }) => id);
        const correspondenceHistoryByDebtor = getInvoiceCorrespondenceHistoryGroupedByDebtor(invoiceSummary.invoiceId, debtorIds) || {};
        const aggregateSentStatus = determineIndicatorStatus(Object.values(correspondenceHistoryByDebtor), invoiceSummary.currentVersion.debtors.length);
        const debtorId = invoiceSummary.currentVersion.debtors[0].id;

        const invoice = {
          ...invoiceSummary,
          aggregateSentStatus,
          ...getTotalsCents(invoiceId),
          debtorDisplay: getContactDisplay(debtorId, { showLastNameFirst: true }), // used only for sorting
        };

        // Voided invoices should not have any unpaid amount
        if (invoiceSummary.currentVersion.status === invoiceStatusByValue[invoiceStatusByName.VOID]) {
          invoice.unpaid = 0;
          invoice.unpaidExcInterest = 0;
        }

        acc.invoices.push(invoice);
        acc.invoicesById[invoiceId] = invoice;
        return acc;
      }, {
        invoices: [],
        invoicesById: {},
      });

      that.sbComposeCtrl.setComposeData(
        invoiceSummaries,
        _.isString(that.composeKey) ? that.composeKey : DEFAULT_COMPOSE_KEY
      );
    }

    // START HASTILY DEVELOPED CODE THAT LIKELY BELONGS IN BUSINESS LOGIC

    // This function figures out the overall status for the indicator icon
    // based on each of the individual email statuses for each debtor.
    function determineIndicatorStatus(debtorEmailStatuses, numberOfContacts) {
      const emailStatuses = {
        NOT_SENT: -1,
        SUCCESS: 1,
        ERROR: 2,
        IN_PROGRESS: 0,
      };
  
      const aggregateStatuses = {
        SUCCESS: 1,
        PARTIALLY_SENT: 2,
        ERROR: 3,
        IN_PROGRESS: 4,
        NOT_SENT: 5,
      };

      if (debtorEmailStatuses.length === 0) {
        return aggregateStatuses.NOT_SENT;
      }

      let overallStatus;
      let debtorIndex = 0;

      while (debtorIndex < debtorEmailStatuses.length && overallStatus !== aggregateStatuses.ERROR) {
        const { status } = debtorEmailStatuses[debtorIndex];

        switch (status) {
          case emailStatuses.ERROR:
            // If any of the statuses are an error, the overall is in error.
            // This will break the loop.
            overallStatus = aggregateStatuses.ERROR;
            break;

          case emailStatuses.IN_PROGRESS:
            // If any of the statuses are in progress, the overall status is also in progress.
            // We cannot break the loop based on hitting this overall status, as an error status
            // might follow.
            overallStatus = aggregateStatuses.IN_PROGRESS;
            break;

          case emailStatuses.NOT_SENT:
            // If all of the statuses are not sent, then we treat the overall status as not sent.
            // If statuses until now have been successful, we have an overall status of partial success.
            if (!overallStatus) {
              overallStatus = aggregateStatuses.NOT_SENT;
            } else if (overallStatus === aggregateStatuses.SUCCESS) {
              overallStatus = aggregateStatuses.PARTIALLY_SENT;
            }
            break;

          case emailStatuses.SUCCESS:
            // Other statuses overwrite success, so it's safe to set success as the overall
            // status so long as no status has been set yet.
            if (!overallStatus) {
              overallStatus = aggregateStatuses.SUCCESS;
            }
            break;

          default:
            throw new Error(`Unexpected email status ${status}`);
        }

        debtorIndex += 1;
      }

      return overallStatus === aggregateStatuses.SUCCESS && debtorEmailStatuses.length !== numberOfContacts
        ? aggregateStatuses.PARTIALLY_SENT
        : overallStatus;
    }

    // END HASTILY DEVELOPLED CODE THAT BELONGS IN BUSINESS LOGIC
  }
});
