import React, { useEffect } from 'react';

const getDisplayName = (WrappedComponent) => WrappedComponent?.displayName || WrappedComponent?.name || 'Component';

export const withOnLoad = (WrappedComponent) => {
  const WrappedComponentWithOnLoad = ({ onLoad, ...props }) => {
    useEffect(() => {
      if (!onLoad) {
        return undefined;
      }

      const cleanUpFn = onLoad();
      if (cleanUpFn) {
        // eslint-disable-next-line consistent-return
        return cleanUpFn;
      }

      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <WrappedComponent {...props} />;
  };

  WrappedComponentWithOnLoad.displayName = `WrappedComponentWithOnLoad(${getDisplayName(WrappedComponent)})`;

  return WrappedComponentWithOnLoad;
};

export default withOnLoad;
