import { sortByProperty } from '@sb-itops/nodash';

export const formatLawpayAccountOptions = ({ lawpayBankAccounts = [], accountMappings = [] }) => {
  const mappedAccountIds = accountMappings.map((m) => m.lawpayBankAccountId);
  const options = lawpayBankAccounts
    .filter((acct) => !mappedAccountIds.includes(acct.id))
    .map((acct) => ({
      label: acct.name,
      value: acct.id,
      isTrust: acct.trustAccount,
      type: acct.type,
    }));

  const caseSensitive = false;

  return sortByProperty(options, 'label', 'asc', caseSensitive);
};
