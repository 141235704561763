import React, { useEffect, useRef } from 'react';

import Styles from '../ConfigureMatterNumbering.module.scss';
import { TRelativePosition, TWidget } from '../types';
import { DraggableWidget } from './DraggableWidget';

type TDraggableWidgetContainerProps = {
  widget: TWidget;
  dragoverX?: number;
  setRelativePosition?: (relativePosition: TRelativePosition) => void;
  setDragInstanceId?: (instanceId?: string) => void;
  onClick?: () => void;
  selected?: boolean;
};

export const DraggableWidgetContainer = ({
  widget,
  onClick,
  selected,
  dragoverX,
  setRelativePosition,
  setDragInstanceId,
}: TDraggableWidgetContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setRelativePosition && ref.current && dragoverX) {
      setRelativePosition(dragoverX < ref.current.offsetLeft + ref.current.offsetWidth / 2 ? 'left' : 'right');
    }
  }, [dragoverX]);

  const isPlaceholder = () => widget.widgetId === 'placeholder';

  const onDragStart: React.DragEventHandler<HTMLDivElement> = (ev) => {
    ev.dataTransfer.setData('ConfigureMatterNumbering/Widget', JSON.stringify(widget));
    if (setDragInstanceId && widget.instanceId) {
      setDragInstanceId(widget.instanceId);
    }
  };

  const getLabel = () => {
    const label = widget.label;
    if (typeof label === 'string') {
      return label;
    }

    try {
      const field = widget.editableFields!.find((f) => f.name === label.reference);

      if (field) {
        if (typeof field.stringValue === 'string' && field.stringValue.trim()) {
          return field.stringValue;
        }
        if (field.placeHolder) {
          return field.placeHolder;
        }
      }
    } catch (e) {
      // pass
    }

    return 'UNKNOWN';
  };

  return (
    <DraggableWidget
      ref={ref}
      {...{
        onDragStart,
        onClick,
        selected,
        label: getLabel(),
        className: isPlaceholder() ? Styles.placeholder : undefined,
        disablePointerEvents: !!dragoverX,
      }}
    />
  );
};
