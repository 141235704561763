import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes } from '@sb-itops/react/button';
import { Modal } from '@sb-itops/react/modal';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import * as Styles from './PaymentProviderDeactivateConfirmationModal.module.scss';

export const PaymentProviderDeactivateConfirmationModal = ({ isVisible, onConfirm, onClose, desiredProviderType }) => {
  const desiredName = providerNames[desiredProviderType] || '';

  return (
    <Modal
      title="Deactivate Payment Processors?"
      body={
        <div>
          <p>
            Payment plans that use a credit card linked to a deactivated payment provider will need to have their card
            reauthenticated and updated for installments to continue.
          </p>
          <p>Do you wish to continue?</p>
        </div>
      }
      footer={
        <div className={Styles.footer}>
          <Button className={Styles.footerBtn} onClick={onConfirm}>
            Deactivate {desiredName}
          </Button>
          <Button className={Styles.footerBtn} type={buttonTypes.secondary} onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
      isVisible={isVisible}
      onModalClose={onClose}
      shouldCloseOnOverlayClick={false}
    />
  );
};

PaymentProviderDeactivateConfirmationModal.displayName = 'PaymentProviderDeactivateConfirmationModal';

PaymentProviderDeactivateConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  desiredProviderType: PropTypes.string.isRequired,
};

PaymentProviderDeactivateConfirmationModal.defaultProps = {};
