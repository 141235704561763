
'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/update-payment-method';

  $stateProvider
    .state('update-payment-method', {
      url: '/update-payment-method',
      templateUrl: BASE_DIRECTORY + '/update-payment-method.html',
      controllerAs: '$ctrl',
      controller: function($state, sbLinkService) {
        const ctrl = this;
        ctrl.onClickLink = sbLinkService.onClickLink;
      }
    });
});
