const { buildContactToAddressSalutation } = require('./build-contact-to-address-salutation');
const { isAbbreviatedTitle } = require('./is-abbreviated-title');
const { formatContactsEmails } = require('../../contacts-summary/services');

/**
 * Prepares and formats contact data to build the "toAddress" value
 *
 * Source: fetchPeopleP from monorepo/customer-management/redux/contacts/index.js (modifications were made to suit LOD)
 *
 * @param {object} params
 * @param {object} params.contact
 * @param {string} params.region
 * @returns {string}
 */
function buildContactToAddress({ contact, region }) {
  if (!contact) {
    throw new Error(`Contact is required (given: ${contact})`);
  }

  if (!region) {
    throw new Error(`Region is required (given: ${region})`);
  }

  const { email, type, title } = contact;

  let contacts;

  switch (type) {
    case 'person':
      contacts = [
        {
          salutation: buildContactToAddressSalutation({
            contact,
            isAbbreviatedTitle: title && isAbbreviatedTitle(title),
            region,
          }),
          email,
        },
      ];
      break;
    case 'groupOfPeople':
      contacts = contact.people.map((person) => ({
        salutation: buildContactToAddressSalutation({
          contact: person,
          isAbbreviatedTitle: person.title && isAbbreviatedTitle(person.title),
          region,
        }),
        email: person.email,
      }));
      break;
    case 'organisation':
    default:
      contacts = [
        {
          salutation: contact.displayNameFirstLast,
          email,
        },
      ];
      break;
  }

  return formatContactsEmails(contacts);
}

module.exports = {
  buildContactToAddress,
};
