import { useState, useCallback, useEffect } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchSmokeballSubscriptionRecord } from 'web/services/subscription';
import { getAccountId, getIsFirmOwner } from 'web/services/user-session-management';
import { UpdatePaymentMethodRoute } from './UpdatePaymentMethodRoute';

// #update-payment-method route is special as it by passes most of the post-authentication steps.
// This is to allow users with a valid login but no current subscription to update their payment details.

const hooks = () => ({
  useRedirectNonFirmOwners: ({ onClickLink }) => {
    if (!getIsFirmOwner()) {
      onClickLink({ type: 'dashboard' });
    }
  },
  useSubscriptionRecord: () => {
    const [subscriptionRecord, setSubscriptionRecord] = useState(undefined);

    const downloadSubscriptionData = useCallback(async () => {
      const downloadedSubscriptionRecord = await fetchSmokeballSubscriptionRecord({ accountId: getAccountId() });
      setSubscriptionRecord(downloadedSubscriptionRecord);
    }, []);

    useEffect(() => {
      downloadSubscriptionData();
    }, [downloadSubscriptionData]);

    return {
      subscriptionRecord,
    };
  },
  useOnAcknowledgePaymentMethodUpdated: () => ({
    onPaymentMethodUpdated: () => {
      const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
      // setting url param to force a browser refresh so the full post-authentication
      // bootstrap process can complete itself, e.g. loading all caches, launch darkly
      urlParams.append('payment-method-updated', 1);
      window.location.href = `?${urlParams.toString()}/#/billing/matters`;
    },
    onPaymentMethodUpdateAbort: () => {
      const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
      // setting url param to force a browser refresh so the full post-authentication
      // bootstrap process can complete itself, e.g. loading all caches, launch darkly
      urlParams.append('payment-method-updated', 1);
      window.location.href = `?${urlParams.toString()}/#/logout`;
    },
  }),
});

export const UpdatePaymentMethodRouteContainer = composeHooks(hooks)(UpdatePaymentMethodRoute);

UpdatePaymentMethodRouteContainer.displayName = 'UpdatePaymentMethodRouteContainer';

UpdatePaymentMethodRouteContainer.propTypes = {};

UpdatePaymentMethodRouteContainer.defaultProps = {};
