import PropTypes from 'prop-types';
import React from 'react';
import { Button, CardContainer, FloatingCard, Spinner } from '@sb-itops/react';
import Styles from './StripeGetStartedCard.module.scss';

export const StripeGetStartedCard = ({ isLoading, onPrimaryClick, onSecondaryClick }) => (
  <div className={Styles.stripeGetStartedCardContainer}>
    <CardContainer>
      <FloatingCard>
        <div className={Styles.stripeGetStartedCard}>
          <div className={Styles.heading}>Getting Started</div>
          <div className={Styles.content}>Accept secure credit card payments for invoice and</div>
          <div className={Styles.content}>Client Account deposits through your Stripe account.</div>
          <div className={Styles.buttonBar}>
            <Button className={Styles.applyNowButton} size="full-width" onClick={onPrimaryClick} disabled={isLoading}>
              Get Started {isLoading && <Spinner small />}
            </Button>
            <Button className={Styles.applyNowButton} size="full-width" type="secondary" onClick={onSecondaryClick}>
              Find out more
            </Button>
          </div>
        </div>
      </FloatingCard>
    </CardContainer>
  </div>
);

StripeGetStartedCard.displayName = 'StripeGetStartedCard';

StripeGetStartedCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
