import React from 'react';
import PropTypes from 'prop-types';
import { StatelessSelect } from '@sb-itops/react';

export const BankAccountSelect = ({
  bankAccountOptions,
  bankAccountId,
  onBankAccountSelect,
  isClearable,
  disabled,
}) => {
  const selectedBankAccountOption = bankAccountOptions.find((option) => option.value === bankAccountId);
  const onBankAccountSelected = (option) => {
    onBankAccountSelect(option.value);
  };

  return (
    <StatelessSelect
      selectedOption={selectedBankAccountOption}
      options={bankAccountOptions}
      onChange={onBankAccountSelected}
      isClearable={isClearable}
      disabled={disabled}
    />
  );
};

BankAccountSelect.displayName = 'BankAccountSelect';

BankAccountSelect.propTypes = {
  bankAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ),
  bankAccountId: PropTypes.string,
  onBankAccountSelect: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

BankAccountSelect.defaultProps = {
  bankAccountOptions: [],
  bankAccountId: undefined,
  isClearable: undefined,
  disabled: undefined,
};
