import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query StaffMembersList($filter: StaffMemberFilter) {
    staffMembersList(filter: $filter) {
      results {
        id
        firstName
        lastName
        initials
      }
    }
  }
`;

const notificationIds = ['FirmManagementWebQuery.PersonUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const StaffMembersList = {
  query,
  notificationIds,
};
