import { fetchGetP } from '@sb-itops/redux/fetch';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('integration-history');

// thunks
const ENDPOINT = '/billing/integration-history/:accountId/';

export const fetchIntegrationHistory = async () => {
  try {
    const response = await fetchGetP({ path: ENDPOINT });
    if (response?.status === 200 && response?.body) {
      return response?.body;
    }
    log.error('Error occurred getting integration history', response);
  } catch (err) {
    log.error('Error occurred getting integration history', err);
  }

  return [];
};
