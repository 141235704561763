'use strict';

function getLogoUrl({ sbDomain, env, path = '' }) {
  if (!sbDomain) {
    throw new Error('sbDomain is required');
  }

  switch (env) {
    case 'dev':
      return `https://dev-assets.billing.${sbDomain}/${path}`;

    case 'rc':
      return `https://rc-assets.billing.${sbDomain}/${path}`;

    case 'qa':
      return `https://qa-assets.billing.${sbDomain}/${path}`;

    case 'live':
      return `https://assets.billing.${sbDomain}/${path}`;

    default:
      // using dev route for local testing as local lambda simulator don't support images currently
      return `https://dev-assets.billing.${sbDomain}/${path}`;
  }
}

module.exports = {
  getLogoUrl,
};
