import { TSchemeValuesFor, schemeMappings } from '@sb-itops/region-schemes';

import { auCompanyTypes, usCompanyTypes, gbCompanyTypes } from '../entities/constants';

/**
 * Get a list of company types for a region
 *
 * @export
 * @param {TSchemeValuesFor<'companyScheme'>} companyScheme
 * @return {{ value: string; label: string; }[]}
 */
export function getCompanyTypes(companyScheme: TSchemeValuesFor<'companyScheme'>) {
  if (!companyScheme) {
    throw new Error('Region must be provided for company types list.');
  }

  switch (companyScheme) {
    case schemeMappings.companyScheme.AU:
      return [...auCompanyTypes];
    case schemeMappings.companyScheme.US:
      return [...usCompanyTypes];
    case schemeMappings.companyScheme.GB:
      return [...gbCompanyTypes];
    default:
      throw new Error(`${companyScheme} is not recognised`);
  }
}
