import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from '@sb-itops/react/modal-dialog';
import { useTranslation } from '@sb-itops/react';

const DeleteInvoicesProceedModalDialog = memo(({ onProceedClick, confirmationModalId }) => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      modalId={confirmationModalId}
      body={
        <div>
          <p>Are you sure you want to batch delete the selected draft invoice(s)?</p>
          <p>
            {`If you proceed, all Time, Fee, and ${t('capitalizeAllWords', {
              val: 'expense',
            })} entries associated with the deleted draft invoice(s) will be unassigned and you will be able to add them to another invoice.`}
          </p>
        </div>
      }
      title="Bulk Delete Draft Invoices"
      buttons={{
        rightAlign: true,
        primary: {
          text: 'Delete Drafts',
          props: {
            type: 'warning',
          },
        },
        secondary: {
          text: 'Cancel',
        },
      }}
      onPrimaryClick={onProceedClick}
    />
  );
});

DeleteInvoicesProceedModalDialog.displayName = 'DeleteInvoicesProceedModalDialog';

DeleteInvoicesProceedModalDialog.propTypes = {
  onProceedClick: PropTypes.func.isRequired,
  confirmationModalId: PropTypes.string.isRequired,
};

export default DeleteInvoicesProceedModalDialog;
