'use strict';

const paymentPlanEventTypes = Object.freeze({
  INVOICE_PAYMENT: 'INVOICE_PAYMENT',
  INVOICE_PAYMENT_REVERSAL: 'INVOICE_PAYMENT_REVERSAL',
  INVOICE_PAYMENT_FAILED: 'INVOICE_PAYMENT_FAILED',
});

module.exports = {
  paymentPlanEventTypes,
};
