'use strict';

const { regionType } = require('@sb-itops/region');
const { SimpleTaxRateStrategy } = require('./simple-tax-rate-strategy');
const { NoTaxStrategy } = require('./no-tax-strategy');

const taxStrategyByRegion = {
  [regionType.AU]: ({ taxRate, isTaxInclusive, isTaxExempt }) =>
    new SimpleTaxRateStrategy({
      taxRate,
      isTaxInclusive,
      isTaxExempt,
    }),
  [regionType.GB]: ({ taxRate, isTaxInclusive, isTaxExempt }) =>
    new SimpleTaxRateStrategy({
      taxRate,
      isTaxInclusive,
      isTaxExempt,
    }),
  [regionType.US]: () => new NoTaxStrategy(),
};

const createTaxStrategy = ({ taxRate, isTaxInclusive, isTaxExempt, region }) => {
  const strategy = taxStrategyByRegion[region]({ taxRate, isTaxInclusive, isTaxExempt });

  if (!strategy) {
    throw new Error(`Unable to determine tax strategy for region ${region}`);
  }

  return strategy;
};

module.exports = {
  createTaxStrategy,
};
