import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Documents, Expanded, Document, Folder } from 'types';
import { FolderIcon } from 'web/components/icons/custom';
import Styles from './FileTree.module.scss';

export interface IFileTree {
  documents: Documents;
  ActionsMenu?: ({ item }: { item: Folder }) => JSX.Element;
  selectedPath: string;
  expanded: Expanded;
  setExpanded: (expanded: Expanded) => void;
  setSelectedPath: (folder: string) => void;
  dragDataRef?: React.MutableRefObject<any>;
  moveFromTo?: (doc: Document, folderPath: string) => Promise<void>;
}

type ILineItem = Omit<IFileTree, 'documents' | 'selectedPath'> & {
  selectedFolderId: string;
  parentFolderPath: string;
};

const LineItem =
  ({
    expanded,
    setExpanded,
    parentFolderPath,
    setSelectedPath,
    selectedFolderId,
    ActionsMenu,
    dragDataRef,
    moveFromTo,
  }: ILineItem) =>
  (props: Document) => {
    const isFile = props.type === 'file';
    const hasItems = !isFile && !!Object.values(props.contents || {}).filter((item) => item.type === 'folder').length;
    const isExpanded = expanded[props.data.id];

    const folderPath = `${parentFolderPath}${parentFolderPath ? '.' : ''}${props.data.id}`;
    const folderDepth = folderPath.split('.').length;

    if (isFile) {
      return null;
    }

    return (
      <div key={props.data.id} className={Styles.lineItem}>
        <div
          onClick={() => setSelectedPath(folderPath)}
          onDrop={() => {
            if (moveFromTo && dragDataRef) {
              moveFromTo(dragDataRef.current, folderPath);
            }
          }}
          onDragStart={(event) => {
            if (dragDataRef && moveFromTo) {
              // eslint-disable-next-line no-param-reassign
              dragDataRef.current = props;
              event.stopPropagation();
              // eslint-disable-next-line no-param-reassign
              event.dataTransfer.effectAllowed = 'move';
            }
          }}
          onDragOver={(event) => {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.effectAllowed = 'move';
          }}
          style={{ paddingLeft: `${Math.min(folderDepth * 10 + 10, 150)}px` }}
          className={classNames(Styles.lineItemContainer, selectedFolderId === props.data.id && Styles.selected)}
        >
          <div
            className={Styles.expander}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setExpanded({ ...expanded, [props.data.id]: !isExpanded });
              if (!props.fetched) {
                setSelectedPath(folderPath);
              }
            }}
          >
            {(hasItems || !props.fetched) && <i className={isExpanded ? 'icon icon-arrow-37' : 'icon icon-arrow-40'} />}
          </div>
          <div className={Styles.icon}>
            <FolderIcon />
          </div>
          <div className={Styles.name} title={props.data.name}>
            {props.data.name}
          </div>
          {ActionsMenu && (
            <div className={Styles.actionsMenu}>
              <ActionsMenu item={props} />
            </div>
          )}
        </div>
        {!isFile &&
          isExpanded &&
          Object.values(props.contents || {}).map(
            LineItem({
              expanded,
              setExpanded,
              parentFolderPath: folderPath,
              setSelectedPath,
              selectedFolderId,
              ActionsMenu,
              dragDataRef,
              moveFromTo,
            }),
          )}
      </div>
    );
  };

export const FileTree = ({
  documents,
  expanded,
  setExpanded,
  setSelectedPath,
  selectedPath,
  ActionsMenu,
  dragDataRef,
  moveFromTo,
}: IFileTree) => {
  const hasItems = Object.values(documents.contents).filter((item) => item.type === 'folder').length;
  const isExpanded = expanded.root;

  const selectedFolderId = selectedPath.split('.')[selectedPath.split('.').length - 1];

  // Make sure the current folder is visible
  useEffect(() => {
    const newExpanded = { ...expanded };
    const idsToProcess = selectedPath.split('.');
    // Dont process the last id, just make sure all the items above the current folder are selected
    idsToProcess.pop();
    idsToProcess.forEach((id) => {
      newExpanded[id] = true;
    });
    setExpanded(newExpanded);
  }, [selectedPath]);

  return (
    <div className={classNames('panel-filter', Styles.fileTree)}>
      <div className={Styles.lineItem}>
        <div
          onClick={() => setSelectedPath('')}
          className={classNames(Styles.lineItemContainer, !selectedPath && Styles.selected)}
        >
          <div
            className={Styles.expander}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setExpanded({ ...expanded, root: !isExpanded });
            }}
          >
            {hasItems ? <i className={isExpanded ? 'icon icon-arrow-37' : 'icon icon-arrow-40'} /> : null}
          </div>
          <div className={Styles.icon}>
            <FolderIcon />
          </div>
          <div className={Styles.name}>Documents</div>
          <div className={Styles.actions} />
        </div>
        {isExpanded &&
          Object.values(documents.contents).map(
            LineItem({
              expanded,
              setExpanded,
              parentFolderPath: '',
              setSelectedPath,
              selectedFolderId,
              ActionsMenu,
              dragDataRef,
              moveFromTo,
            }),
          )}
      </div>
    </div>
  );
};
