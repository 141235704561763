import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Spinner, SlidingToggle, Icon } from '@sb-itops/react';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { PaymentProviderSwitchConfirmationModal } from '@sb-billing/react/payment-provider-switch-confirmation-modal';
import { PaymentProviderDeactivateConfirmationModal } from '@sb-billing/react/payment-provider-deactivate-confirmation-modal';
import { featureActive } from '@sb-itops/feature';
import { StripeAccountManagementPanelContainer as StripeAccountManagementPanel } from './StripeAccountManagementPanel.container';
import { PaymentProviderActivationPanel } from '../payment-provider-activation-panel';
import Styles from './StripeSettingsForm.module.scss';

export const StripeSettingsForm = ({
  trustAccounts,
  operatingAccount,
  showInvoiceLink,
  showScanToPay,
  showClosedTrustAccounts,
  supportClientCoversFeeOnPaymentsSelection,
  clientCoversFeeOnPayments,
  isSaving,
  accountConnecting,
  accountDisconnecting,

  activateToggled,
  activeProviderType,
  onActivateToggle,

  onConnectAccount,
  onContinueOnboarding,
  onSave,
  onDisconnectStripeAccount,
  onShowInvoiceLinkChange,
  onShowScanToPayChange,
  onClientCoversFeeOnPaymentsChange,
  onShowClosedTrustAccountsChange,
}) => {
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  // We want to show switch and deactivate modal only if SB Payments is enabled for the firm
  const useSwitchAndDeactivateModal = featureActive('BB-12038') && activeProviderType;

  const linkedOperatingAccount = operatingAccount.linkedOperatingAccount;
  const operatingAccountReady = linkedOperatingAccount && linkedOperatingAccount.onboardingComplete;
  const hasConnectedAccount = !!(linkedOperatingAccount || trustAccounts.some((ta) => ta.linkedTrustAccount));
  const isPerformingWork = isSaving || accountConnecting !== '' || accountDisconnecting !== '';
  // We want to allow deactivation even if no Stripe account is connected. Once deactivated, user can't activate again until an account is connected.
  const disableActivation = !hasConnectedAccount && activeProviderType !== providers.STRIPE;

  const handleOnConnectAccount = ({ bankAccountId, bankAccountType }) =>
    onConnectAccount({ bankAccountId: bankAccountId.toLowerCase(), bankAccountType });

  const onSaveStripe = () => {
    onSave({ updatedProviderSettings: { showInvoiceLink, showScanToPay, clientCoversFeeOnPayments }, activateToggled });
  };

  const handleOnSave = () => {
    if (useSwitchAndDeactivateModal && activeProviderType !== providers.STRIPE && activateToggled) {
      setShowSwitchModal(true);
      return;
    }
    if (useSwitchAndDeactivateModal && activeProviderType === providers.STRIPE && !activateToggled) {
      setShowDeactivateModal(true);
      return;
    }

    onSaveStripe();
  };

  return (
    <div className={Styles.stripeSettingsForm}>
      <PaymentProviderActivationPanel
        disabled={disableActivation || isPerformingWork}
        activeProviderType={activeProviderType}
        isActivateToggled={activateToggled}
        onActivateToggle={onActivateToggle}
        providerType={providers.STRIPE}
        showButton={false}
        activateTooltip="Activate your payment processor to start taking payments"
      />

      <div className={Styles.sectionTitle}>
        <div className={Styles.title}>Linked Account Setup</div>
        <div className={Styles.toggleSection}>
          <label>
            <SlidingToggle
              id="showClosedTrustAccountsStripe"
              scope="showClosedTrustAccountsStripe"
              onChange={() => onShowClosedTrustAccountsChange(!showClosedTrustAccounts)}
              selected={showClosedTrustAccounts}
              disabled={isPerformingWork}
            />
            <span className={Styles.labelText}>Show Closed</span>
          </label>
        </div>
      </div>
      <div className={Styles.bankAccountSection}>
        <div className={Styles.accountTitle}>{operatingAccount.bankAccountName}</div>
        <StripeAccountManagementPanel
          linkedAccount={operatingAccount.linkedOperatingAccount}
          bankAccountType={operatingAccount.bankAccountType}
          bankAccountId={operatingAccount.bankAccountId}
          disabled={isPerformingWork}
          isConnecting={accountConnecting === operatingAccount.bankAccountId}
          isDisconnecting={accountDisconnecting === operatingAccount.bankAccountId}
          onConnectAccount={handleOnConnectAccount}
          onContinueOnboarding={onContinueOnboarding}
          onDisconnectAccount={onDisconnectStripeAccount}
        />
      </div>
      {trustAccounts.map((trustAccount) => (
        <div className={Styles.bankAccountSection} key={trustAccount.bankAccountId}>
          <div className={Styles.accountTitle}>
            {trustAccount.bankAccountName}
            {trustAccount.isClosed && <span className={Styles.closedBadge}>CLOSED</span>}
          </div>
          <StripeAccountManagementPanel
            linkedAccount={trustAccount.linkedTrustAccount}
            bankAccountType={trustAccount.bankAccountType}
            bankAccountId={trustAccount.bankAccountId}
            isConnecting={accountConnecting === trustAccount.bankAccountId}
            isDisconnecting={accountDisconnecting === trustAccount.bankAccountId}
            disabled={isPerformingWork || (!trustAccount.linkedTrustAccount && trustAccount.isClosed)}
            onConnectAccount={handleOnConnectAccount}
            onContinueOnboarding={onContinueOnboarding}
            onDisconnectAccount={onDisconnectStripeAccount}
          />
        </div>
      ))}

      <div className={Styles.sectionSeparator} />

      <div className={Styles.sectionTitle}>Invoice Payment Options</div>
      {/* Operating account not ready, hide toggles. */}
      {!operatingAccountReady && (
        <div className={Styles.userInfoSection}>
          <div className={Styles.helpBlock}>
            A set-up Stripe account must be linked to your operating bank account to enable invoice payment options.
          </div>
        </div>
      )}
      {/* Operating account ready, show toggles */}
      {operatingAccountReady && (
        <div className={Styles.toggleSection}>
          <div className="form-group">
            <div className={Styles.paymentOptionContainer}>
              <SlidingToggle
                id="showInvoiceLink"
                scope="showInvoiceLink"
                name="showInvoiceLink"
                onChange={() => onShowInvoiceLinkChange(!showInvoiceLink)}
                selected={showInvoiceLink}
                disabled={isPerformingWork}
              />
              <label className={Styles.labelText} htmlFor="showInvoiceLink">
                Include payment link on invoices and emails/messages
              </label>
              <Icon
                type="information"
                tooltip="This option applies to the Pay button and QR code on your PDF invoices and invoice reminders, invoice emails and Client Portal messages, and the eInvoice portal"
                className={Styles.icon}
              />
            </div>
          </div>

          <div className="form-group">
            <SlidingToggle
              id="showScanToPay"
              scope="showScanToPay"
              name="showScanToPay"
              onChange={() => onShowScanToPayChange(!showScanToPay)}
              selected={showScanToPay}
              disabled={isPerformingWork}
            />
            <label className={Styles.labelText} htmlFor="showScanToPay">
              Include Scan to Pay QR code on invoices
            </label>
          </div>

          {supportClientCoversFeeOnPaymentsSelection && (
            <div className="form-group">
              <div className={Styles.paymentOptionContainer}>
                <SlidingToggle
                  id="clientCoversFeeOnPayments"
                  scope="clientCoversFeeOnPayments"
                  name="clientCoversFeeOnPayments"
                  onChange={() => onClientCoversFeeOnPaymentsChange(!clientCoversFeeOnPayments)}
                  selected={clientCoversFeeOnPayments}
                  disabled={isPerformingWork}
                />
                <label className={Styles.labelText} htmlFor="clientCoversFeeOnPayments">
                  Request Stripe fee from client for invoice payments
                </label>
                <Icon
                  type="information"
                  tooltip="For invoices that include a retainer request, fees will always be passed to the client to allow accurate trust accounting"
                  className={Styles.icon}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div className={Styles.sectionSeparator} />

      <div className={Styles.buttonSection}>
        <Button onClick={handleOnSave} disabled={isPerformingWork}>
          Save {isSaving && <Spinner small />}
        </Button>
      </div>
      {useSwitchAndDeactivateModal && (
        <PaymentProviderSwitchConfirmationModal
          isVisible={showSwitchModal}
          onConfirm={() => {
            setShowSwitchModal(false);
            onSaveStripe();
          }}
          onClose={() => setShowSwitchModal(false)}
          activeProviderType={activeProviderType}
          desiredProviderType={providers.STRIPE}
        />
      )}
      {useSwitchAndDeactivateModal && (
        <PaymentProviderDeactivateConfirmationModal
          isVisible={showDeactivateModal}
          onConfirm={() => {
            setShowDeactivateModal(false);
            onSaveStripe();
          }}
          onClose={() => setShowDeactivateModal(false)}
          desiredProviderType={providers.STRIPE}
        />
      )}
    </div>
  );
};

StripeSettingsForm.displayName = 'StripeSettingsForm';

StripeSettingsForm.propTypes = {
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      bankAccountId: PropTypes.string.isRequired,
      bankAccountType: PropTypes.string.isRequired,
      bankAccountName: PropTypes.string.isRequired,
      isClosed: PropTypes.bool.isRequired,
      linkedTrustAccount: PropTypes.object,
    }).isRequired,
  ).isRequired,
  operatingAccount: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
    bankAccountType: PropTypes.string.isRequired,
    bankAccountName: PropTypes.string.isRequired,
    linkedOperatingAccount: PropTypes.object,
  }).isRequired,
  isSaving: PropTypes.bool.isRequired,
  showInvoiceLink: PropTypes.bool.isRequired,
  showScanToPay: PropTypes.bool.isRequired,
  showClosedTrustAccounts: PropTypes.bool.isRequired,
  supportClientCoversFeeOnPaymentsSelection: PropTypes.bool.isRequired,
  clientCoversFeeOnPayments: PropTypes.bool.isRequired,
  accountConnecting: PropTypes.string.isRequired,
  accountDisconnecting: PropTypes.string.isRequired,

  activateToggled: PropTypes.bool.isRequired,
  activeProviderType: PropTypes.string,
  onActivateToggle: PropTypes.func.isRequired,

  onConnectAccount: PropTypes.func,
  onContinueOnboarding: PropTypes.func,
  onSave: PropTypes.func,
  onDisconnectStripeAccount: PropTypes.func,
  onShowInvoiceLinkChange: PropTypes.func.isRequired,
  onShowScanToPayChange: PropTypes.func.isRequired,
  onClientCoversFeeOnPaymentsChange: PropTypes.func.isRequired,
  onShowClosedTrustAccountsChange: PropTypes.func.isRequired,
};

StripeSettingsForm.defaultProps = {
  activeProviderType: undefined,
  onConnectAccount: () => {},
  onContinueOnboarding: () => {},
  onSave: () => {},
  onDisconnectStripeAccount: () => {},
};
