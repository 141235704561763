import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/entity-bank-details';

angular
  .module('@sb-billing/services')
  .service('sbEntityBankDetailsService', function(sbGenericCacheService, sbEndpointType) {
    const endpoint = 'billing/entity-bank-details';

    sbGenericCacheService.setupCache({
      name: 'sbEntityBankDetailsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
        poll: 60,
        subscriptions: 'notifier.AccountsNotifications.EntityBankDetailsUpdated',
      },
      updateRedux,
      clearRedux,
    });
  });
