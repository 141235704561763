'use strict';

const momenttz = require('moment-timezone');
const { isPayment } = require('../is-payment');
const { isDeposit } = require('../is-deposit');
const { isReconciled } = require('../is-reconciled');
const { reconcileableTypes } = require('../constants');

// filterByEnteredDate is an alias for hasFacet(facets.transactionsByEnteredDate)
// since this is a business logic function which should be pure the caller needs to fetch and pass this through
/**
 * @param {object} param
 * @param {Array[object]} param.transactions
 * @param {object} param.filters
 * @param {boolean} param.filterByEnteredDate
 * @param {Array<string>|undefined} param.unbankedTransactionIds
 * @param {string|undefined} param.ianaTimezone
 * @returns {Array<object>}
 */
function generateTransactionList({ transactions, filters, filterByEnteredDate, unbankedTransactionIds = [] }) {
  const unbankedTxnsSet = new Set(unbankedTransactionIds);

  return filterByEnteredDate
    ? findTransactionsByEnteredDate(transactions, filters, unbankedTxnsSet)
    : findTransactionsByEffectiveDate(transactions, filters, unbankedTxnsSet);
}

/**
 * @param {object} param
 * @param {Array<object>} param.depositSlips
 * @param {function} param.getTransactionById
 * @param {object} param.filters
 * @param {boolean} param.filterByEnteredDate
 * @param {Array<string>|undefined} param.unbankedTransactionIds
 * @returns {Array<object>}
 */
function generateDepositSlipsList({
  depositSlips,
  filters,
  filterByEnteredDate,
  getTransactionById,
  unbankedTransactionIds = [],
}) {
  const unbankedTxns = unbankedTransactionIds.map((id) => getTransactionById(id));
  const unbankedDepositSlipTransactions = new Set(unbankedTxns.filter((tx) => tx.depositSlipId).map((tx) => tx.id));

  const unbankedDepositSlips = unbankedTxns.reduce((acc, txn) => {
    if (txn.depositSlipId) {
      acc.add(txn.depositSlipId);
    }
    return acc;
  }, new Set());

  const { showReceipts = true, startDate, endDate, bankReconciliation, ianaTimezone } = filters;

  if (!showReceipts) {
    return [];
  }

  const filter = depositSlipFilter({
    startDate, // as YYYYMMDD
    endDate, // as YYYYMMDD
    bankReconciliation,
    filterByEnteredDate,
    unbankedDepositSlips,
    ianaTimezone,
  });

  return depositSlips.filter(filter).map((ds) => ({
    ...ds,
    transactions: ds.transactionIds?.map((id) => ({
      ...getTransactionById(id),
      unbanked: unbankedDepositSlipTransactions.has(id),
    })),
  }));
}

/**
 * @param {object} param
 * @param {string|undefined} param.startDate YYYYMMDD
 * @param {string|undefined} param.endDate YYYYMMDD
 * @param {boolean} param.showPayments
 * @param {boolean} param.showReceipts
 * @param {object|undefined} param.bankReconciliation
 * @param {string} param.ianaTimezone
 * @param {boolean} filterByEnteredDate
 * @param {Set<string>} unbankedTxnsSet
 * @returns {Function}
 */
const transactionFilter =
  (
    { startDate, endDate, showPayments = true, showReceipts = true, bankReconciliation, ianaTimezone },
    filterByEnteredDate,
    unbankedTxnsSet = new Set(),
  ) =>
  (tx) => {
    // If the start/end date hasn't been passed in, set start/endFilterDate to true
    const endFilterDate = () => {
      if (!endDate) {
        return true;
      }
      const endDateISO = momenttz.tz(`${endDate}`, ianaTimezone).startOf('day').add(1, 'days').toISOString();

      return filterByEnteredDate ? tx.timestamp < endDateISO : tx.effectiveDate <= +endDate;
    };

    const startFilterDate = () => {
      if (!startDate) {
        return true;
      }
      const startDateISO = momenttz.tz(`${startDate}`, ianaTimezone).startOf('day').toISOString();

      return filterByEnteredDate ? tx.timestamp > startDateISO : tx.effectiveDate >= +startDate;
    };

    if (!ianaTimezone && (endDate || startDate)) {
      throw Error('timezone must be passed in if end date or start date specified');
    }

    return (
      tx.bankAccountType === 'Trust' &&
      // Show all, show payments or show receipts
      ((showPayments && showReceipts) || (showPayments && isPayment(tx)) || (showReceipts && isDeposit(tx))) &&
      reconcileableTypes.includes(tx.type) &&
      !isReconciled(tx, bankReconciliation) &&
      // If txn is unbanked, we don't care if it doesn't fall in the date filter
      (unbankedTxnsSet.has(tx.id) || (startFilterDate() && endFilterDate()))
    );
  };

/**
 * @param {array} transactions
 * @param {object} param
 * @param {string|undefined} param.startDate YYYYMMDD
 * @param {string|undefined} param.endDate YYYYMMDD
 * @param {boolean} param.showPayments
 * @param {boolean} param.showReceipts
 * @param {object|undefined} param.bankReconciliation
 * @param {string|undefined} param.ianaTimezone
 * @param {Set<string>} unbankedTxnsSet
 * @returns {Function}
 */
const findTransactionsByEffectiveDate = (
  transactions,
  { startDate, endDate, showPayments, showReceipts, bankReconciliation, ianaTimezone },
  unbankedTxnsSet,
) =>
  transactions
    .filter(
      transactionFilter(
        {
          startDate, // as YYYYMMDD
          endDate, // as YYYYMMDD
          showPayments,
          showReceipts,
          bankReconciliation,
          ianaTimezone,
        },
        false,
        unbankedTxnsSet,
      ),
    )
    // the unbanked property is needed when calculating payments / receipts (excluding unbanked)
    .map((txn) => ({ ...txn, unbanked: unbankedTxnsSet.has(txn.id) }));

/**
 * @param {array} transactions
 * @param {object} param
 * @param {string|undefined} param.startDate YYYYMMDD
 * @param {string|undefined} param.endDate YYYYMMDD
 * @param {boolean} param.showPayments
 * @param {boolean} param.showReceipts
 * @param {object|undefined} param.bankReconciliation
 * @param {string} param.ianaTimezone
 * @param {Set<string>} unbankedTxnsSet
 * @returns {Function}
 */
const findTransactionsByEnteredDate = (
  transactions,
  { startDate, endDate, showPayments, showReceipts, bankReconciliation, ianaTimezone },
  unbankedTxnsSet,
) =>
  transactions
    .filter(
      transactionFilter(
        {
          startDate, // as YYYYMMDD
          endDate, // as YYYYMMDD
          showPayments,
          showReceipts,
          bankReconciliation,
          ianaTimezone,
        },
        true,
        unbankedTxnsSet,
      ),
    )
    // the unbanked property is needed when calculating payments / receipts (excluding unbanked)
    .map((txn) => ({ ...txn, unbanked: unbankedTxnsSet.has(txn.id) }));

/**
 * @param {object} param
 * @param {string|undefined} param.startDate YYYYMMDD
 * @param {string|undefined} param.endDate YYYYMMDD
 * @param {object|undefined} param.bankReconciliation
 * @param {boolean} param.filterByEnteredDate
 * @param {Set<string>} param.unbankedDepositSlips
 * @returns {Function}
 */
const depositSlipFilter =
  ({ startDate, endDate, bankReconciliation, filterByEnteredDate, ianaTimezone, unbankedDepositSlips = new Set() }) =>
  (ds) => {
    const getEnteredDateStartFilter = () => {
      const startDateISO = momenttz.tz(`${startDate}`, ianaTimezone).startOf('day').toISOString();
      return ds.timestamp ? ds.timestamp > startDateISO : ds.lastUpdated > startDateISO;
    };
    const getEnteredDateEndFilter = () => {
      const endDateISO = momenttz.tz(`${endDate}`, ianaTimezone).startOf('day').add(1, 'day').toISOString();
      return ds.timestamp ? ds.timestamp < endDateISO : ds.lastUpdated < endDateISO;
    };

    const getStartDateFilter = () => {
      if (!startDate) {
        return true;
      }
      return filterByEnteredDate ? getEnteredDateStartFilter() : ds.depositDate >= Number.parseInt(startDate, 10);
    };

    const getEndDateFilter = () => {
      if (!endDate) {
        return true;
      }
      return filterByEnteredDate ? getEnteredDateEndFilter() : ds.depositDate <= Number.parseInt(endDate, 10);
    };

    if (!ianaTimezone && (endDate || startDate)) {
      throw Error('timezone must be passed in if end date or start date specified');
    }

    // for legacy data, fallback to lastUpdated if timestamp not set
    const startDateFilter = getStartDateFilter();
    const endDateFilter = getEndDateFilter();

    return (
      ds.bankAccountType === 'Trust' &&
      !ds.isRemoved &&
      !isReconciled(ds, bankReconciliation) &&
      // If ds is unbanked, we don't care if it doesn't fall in the date filter
      ((unbankedDepositSlips.has(ds.id) && endDateFilter) || (startDateFilter && endDateFilter))
    );
  };

module.exports = {
  generateTransactionList,
  generateDepositSlipsList,
  transactionFilter,
  depositSlipFilter,
};
