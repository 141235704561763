import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { store } from '@sb-itops/redux';
import { featureActive } from '@sb-itops/feature';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import uuid from '@sb-itops/uuid';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getById as getFeeConfiguration } from './index';
import { getAllowedIntervals } from './get-allowed-intervals';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbFeeConfigurationService',
  keyPath: 'accountId',
});

export const setFeeInterval = async (newBillableMinutes) => {
  const customBillingUnitsEnabled = featureActive('BB-10195');
  const getAccountId = () => authSelectors.getAccountId(store.getState());
  const accountId = getAccountId();
  const allowedIntervals = getAllowedIntervals();

  const currentFeeConfiguration = getFeeConfiguration(accountId);
  const newFeeConfiguration = { ...currentFeeConfiguration, billableMinutes: newBillableMinutes };

  if (allowedIntervals.includes(newBillableMinutes) || customBillingUnitsEnabled) {
    const data = {
      accountId,
      billableMinutes: newBillableMinutes,
      versionId: uuid(),
    };
    opdateCache({ optimisticEntities: [newFeeConfiguration] });

    try {
      await fetchPostP({
        path: `/billing/fee-configuration/${accountId}/`,
        fetchOptions: { body: JSON.stringify(data) },
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [currentFeeConfiguration] });
      throw err;
    }
  } else {
    throw new Error(`invalid interval ${newBillableMinutes}`);
  }
};
