import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { sort as sortItems } from '@sb-itops/sort';
import { createSelector } from 'reselect';
import { filterUtbmsTaskCodes, filterUtbmsTaskCodesExpenses } from '@sb-billing/business-logic/activities/services';

import { getList as getUtbmsSettings } from '../utbms-settings';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'utbms-task-codes',
  keyPath: 'code',
  ngCacheName: 'sbUtbmsTaskCodeService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

const getUtbmsTasksSelector = createSelector(
  (list) => list || [],
  (list) => list.filter((v) => !v.deleted),
);

const getFirmUtbmsTasksSelector = createSelector(
  (list) => list || [],
  (list, firmSettings) => firmSettings,
  (list, firmSettings) => {
    const filteredTasks = filterUtbmsTaskCodes({
      utbmsTaskCodes: list,
      utbmsCodeSetsUsedByFirm: firmSettings.selectedCodeSets,
      useCustomTaskCodes: true,
    });

    return sortItems(filteredTasks, ['code'], ['ASC']);
  },
);

const getFirmUtbmsTasksExpensesSelector = createSelector(
  (list) => list || [],
  (list, firmSettings) => firmSettings,
  (list, firmSettings) => {
    const filteredTasks = filterUtbmsTaskCodesExpenses({
      utbmsTaskCodes: list,
      utbmsCodeSetsUsedByFirm: firmSettings.selectedCodeSets,
      useCustomTaskCodes: true,
    });

    return sortItems(filteredTasks, ['code'], ['ASC']);
  },
);

export function getUtbmsTasks() {
  return getUtbmsTasksSelector(getList());
}

export function getFirmUtbmsTasks() {
  const firmSettings = getUtbmsSettings()[0];
  if (!firmSettings) return [];

  return getFirmUtbmsTasksSelector(getUtbmsTasks(), firmSettings);
}

export function getFirmUtbmsTasksExpense() {
  const firmSettings = getUtbmsSettings()[0];
  if (!firmSettings) return [];

  return getFirmUtbmsTasksExpensesSelector(getUtbmsTasks(), firmSettings);
}
