/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { bankAccountTypes } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getById as getBankAccountById } from '../bank-account';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'bulk-deposit',
  keyPath: 'id',
  ngCacheName: 'sbBulkDepositService',
  syncType: syncTypes.SINCE,
  immutable: false,
  indexes: [],
  changesetProcessor: ({ entities = [] }) =>
    entities.map((bulkDeposit) => {
      const bankAccount = getBankAccountById(bulkDeposit.bankAccountId);
      const bankAccountType = bankAccountTypes[bankAccount?.accountType];
      const transformed = { ...bulkDeposit, bankAccountType };

      return transformed;
    }),
});

export const { getMap, updateCache, clearCache, UPDATE_CACHE, getIndex } = api;

// selectors
export const getById = (id) => getMap()[id];
export const getByIds = (ids) => ids.map((id) => getMap()[id]);
export const getList = () => api.getList();
