/**
 * Calls the focus-service to register a particular focus key.
 * Cleans up the registration of the key once the directive scope is destroyed.
 */
angular.module('sb.billing.webapp').directive('focusKey', function (focusService, sbReactHelpers) {
  'use strict';
  return function (scope, element, attr) {
    if (sbReactHelpers.isReactComponent(element[0])) {
      focusService.register(scope.$eval(attr.focusKey), element);
    }

    focusService.register(attr.focusKey, element);

    scope.$on('$destroy', function () {
      focusService.unregister(attr.focusKey, element);
    });
  };
});
