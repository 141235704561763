import * as React from 'react';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { responseTypes } from '@sb-itops/fetch-wrapper/src/constants';

const log = getLogger('useOpenMatterDocumentOnWeb');

export function useOpenMatterDocumentOnWeb() {
  const [isOpeningDocumentOnWeb, setIsOpeningDocumentOnWeb] = React.useState(false);

  async function openDocumentOnWeb(fileId) {
    try {
      setIsOpeningDocumentOnWeb(true);
      const documentUrlResponse = await fetchGetP({
        path: `/integration/endpoint-locator/matter-document/:accountId/get-document-url/${fileId}`,
        responseType: responseTypes.text,
        skipCamelCase: true,
      });

      window.open(documentUrlResponse.body, '_blank');
    } catch (error) {
      log.error(`Failed to open document with file ID: ${fileId}`, error);
      displayErrorToUser(`Failed to open document. Please try again later.`);
    } finally {
      setIsOpeningDocumentOnWeb(false);
    }

    return undefined;
  }

  return {
    isOpeningDocumentOnWeb,
    openDocumentOnWeb,
  };
}
