'use strict';

const { surchargeTypeValues } = require('./surcharge-types');
const { surchargeApplyToValues } = require('./surcharge-apply-to');

const defaultSurchargeSettings = Object.freeze({
  type: surchargeTypeValues.NONE,
  percentageBp: 0,
  fixedSurcharge: 0,
  applyTo: surchargeApplyToValues.FEES_AND_EXPENSES,
  description: 'Surcharge',
});

module.exports = {
  defaultSurchargeSettings,
};
