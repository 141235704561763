
const { allocators } = require('@sb-billing/allocation');
const { sort: sortItems } = require('@sb-itops/sort');

const { targetsFromSources: allocateTargetsFromSources } = allocators;

module.exports = {
  allocateQuickPaymentsForInvoices,
};

// for a set of invoices, but a single account, get the payments in terms of contacts
function allocateQuickPaymentsForInvoices({ invoicePayments, contactBalances, bankAccountId, transformFn = (a) => a }) {
  if (!contactBalances.length) {
    return {};
  }

  let paymentsByInvoiceId = {};
  allocateTargetsFromSources({
    targets: invoicePayments.map(({ invoiceId, payments }) => ({
      targetId: invoiceId,
      amount: payments[bankAccountId] || 0,
    })),
    sources: sortItems(
      contactBalances.map((contactBalance) => ({
        sourceId: contactBalance.contactId,
        amount: contactBalance.balance,
      })),
      ['amount'],
      ['ASC'],
    ),
    zeroAllocate: false,
    accumulate: (allocation) => {
      const invoiceId = allocation.invoiceId;
      const quickPayment = transformFn(allocation);
      if(paymentsByInvoiceId[invoiceId]) {
        paymentsByInvoiceId[invoiceId].push(quickPayment);
      }
      else {
        paymentsByInvoiceId[invoiceId] = [quickPayment];
      }
    },
    transform: (allocation) => {
      return {
        ...allocation,
        invoiceId: allocation.targetId,
        payorId: allocation.sourceId,
        sourceAccountId: bankAccountId,
      };
    },
  });

  return paymentsByInvoiceId;
}