/**
 * Get sort direction for relevant scope
 * @returns {'asc'|'desc'|undefined}.
 */
export const getSortDirection = (state, { defaultSortDirection }) => state.sortDirection || defaultSortDirection;

/**
 * Get sort by field for relevant scope
 * @returns Return undefined if sort by field is not set for scope
 */
export const getSortBy = (state) => state && state.sortBy;
