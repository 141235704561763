import React from 'react';
import { PanelExpander, Spinner, CollapsibleHeader, SlidingToggle, MultiSelectDropdown } from '@sb-itops/react';
import classnames from 'classnames';
import { Staff } from 'types';
import { FirmTaskCategories } from 'web/graphql/types/graphql';
import { nonNullishFieldsGuardFactory } from '@sb-itops/type-helpers';
import Styles from './TaskFilters.module.scss';

interface TaskFiltersProps {
  taskViewCounts: any;
  taskView: string;
  setTaskView: (view: string) => void;
  expanded: boolean;
  loading: boolean;
  toggleExpanded: () => void;
  isMatterPage: boolean;
  toggleAssigneeFilterExpanded: () => void;
  assigneeFilterExpanded: boolean;
  toggleCategoryFilterExpanded: () => void;
  categoryFilterExpanded: boolean;
  toggleViewExpanded: () => void;
  viewExpanded: boolean;
  setShowDeleted: (showDeleted: boolean) => void;
  showDeleted: boolean;
  assigneeIds: string[];
  setAssigneeIds: (assigneeIds: string[]) => void;
  categoryIds: string[];
  setCategoryIds: (categoryIds: string[]) => void;
  staff: Staff[];
  categories?: FirmTaskCategories[];
}

const currencySeparatorPattern = /(\d)(?=(\d{3})+$)/g; // splits value into 1000's groups

const formatCommaSeparated = (count: number) => `${count}`.replace(currencySeparatorPattern, '$1,');

export const TaskFilters = ({
  expanded,
  toggleExpanded,
  taskView,
  setTaskView,
  taskViewCounts,
  loading,
  isMatterPage,
  toggleAssigneeFilterExpanded,
  assigneeFilterExpanded,
  toggleCategoryFilterExpanded,
  categoryFilterExpanded,
  toggleViewExpanded,
  viewExpanded,
  setShowDeleted,
  showDeleted,
  assigneeIds,
  setAssigneeIds,
  categoryIds,
  setCategoryIds,
  staff,
  categories,
}: TaskFiltersProps) => (
  <div className={Styles.taskFilters}>
    <PanelExpander
      className={classnames('panel-filter', Styles.taskFiltersNav)}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    >
      <div className={Styles.sideMenu}>
        <CollapsibleHeader
          text="Assignees"
          onClick={toggleAssigneeFilterExpanded}
          name="filters"
          collapsed={!assigneeFilterExpanded}
        >
          <div className={Styles.assignedTo}>
            <MultiSelectDropdown
              options={[
                { label: 'Unassigned', value: '', searchText: '' },
                ...staff.map((s) => ({ label: s.name, value: s.id, searchText: s.name })),
              ]}
              onSelectedIdsChanged={(selectedStaffIds) => {
                setAssigneeIds(selectedStaffIds.map((item) => item.value));
              }}
              value={assigneeIds}
            />
          </div>
        </CollapsibleHeader>
        <CollapsibleHeader
          text="Categories"
          onClick={toggleCategoryFilterExpanded}
          name="filters2"
          collapsed={!categoryFilterExpanded}
        >
          <div className={Styles.assignedTo}>
            <MultiSelectDropdown
              options={[
                { label: 'Unassigned', value: '__SB_INTERNAL_UNASSIGNED__', searchText: '' },
                ...(categories || [])
                  .filter(nonNullishFieldsGuardFactory(['id']))
                  .map((s) => ({ label: s.id, value: s.id, searchText: s.id })),
              ]}
              onSelectedIdsChanged={(selectedCategoryIds) => {
                setCategoryIds(selectedCategoryIds.map((item) => item.value));
              }}
              value={categoryIds}
            />
          </div>
        </CollapsibleHeader>
        <CollapsibleHeader text="View" onClick={toggleViewExpanded} name="view" collapsed={!viewExpanded}>
          <ul className="side-menu-list capitalize">
            <li
              role="presentation"
              className={classnames({ active: taskView === 'ALL', [Styles.flex]: true })}
              onClick={() => setTaskView('ALL')}
            >
              To Do - All
              <div className={Styles.counterBadge}>
                {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.allCount)}
              </div>
            </li>
            <li
              role="presentation"
              className={classnames({ active: taskView === 'OVERDUE', [Styles.flex]: true, [Styles.indent]: true })}
              onClick={() => setTaskView('OVERDUE')}
            >
              Overdue
              <div className={Styles.counterBadge}>
                {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.overdueCount)}
              </div>
            </li>
            <li
              role="presentation"
              className={classnames({ active: taskView === 'DUE_TODAY', [Styles.flex]: true, [Styles.indent]: true })}
              onClick={() => setTaskView('DUE_TODAY')}
            >
              Due today
              <div className={Styles.counterBadge}>
                {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.dueTodayCount)}
              </div>
            </li>
            <li
              role="presentation"
              className={classnames({
                active: taskView === 'DUE_THIS_WEEK',
                [Styles.flex]: true,
                [Styles.indent]: true,
              })}
              onClick={() => setTaskView('DUE_THIS_WEEK')}
            >
              Due this week
              <div className={Styles.counterBadge}>
                {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.dueThisWeekCount)}
              </div>
            </li>
            <li
              role="presentation"
              className={classnames({
                active: taskView === 'DUE_LATER',
                [Styles.flex]: true,
                [Styles.indent]: true,
              })}
              onClick={() => setTaskView('DUE_LATER')}
            >
              Due later
              <div className={Styles.counterBadge}>
                {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.dueLaterCount)}
              </div>
            </li>
            {isMatterPage && (
              <li
                role="presentation"
                className={classnames({ active: taskView === 'ALL_COMPLETED', [Styles.flex]: true })}
                onClick={() => setTaskView('ALL_COMPLETED')}
              >
                Completed
                <div className={Styles.counterBadge}>
                  {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.allCompletedCount)}
                </div>
              </li>
            )}
            {!isMatterPage && (
              <li
                role="presentation"
                className={classnames({ active: taskView === 'RECENTLY_COMPLETED', [Styles.flex]: true })}
                onClick={() => setTaskView('RECENTLY_COMPLETED')}
                title="Showing tasks completed by you in the past 30 days. To see all completed tasks please review within the related matter."
              >
                Completed - Last 30 days
                <div className={Styles.counterBadge}>
                  {loading ? <Spinner small /> : formatCommaSeparated(taskViewCounts.recentlyCompletedCount)}
                </div>
              </li>
            )}
          </ul>
        </CollapsibleHeader>
        <div className={Styles.deletedTasks}>
          <div onClick={() => setShowDeleted(!showDeleted)} className={Styles.deletedTasksLabel}>
            Show Deleted Tasks
          </div>
          <SlidingToggle
            scope="show-deleted-tasks"
            onChange={() => setShowDeleted(!showDeleted)}
            selected={showDeleted}
          />
        </div>
      </div>
    </PanelExpander>
  </div>
);

TaskFilters.displayName = 'TaskFilters';
