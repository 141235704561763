import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { getDefaultInvoiceReminderSettings } from '@sb-billing/business-logic/invoice-reminder';
import { hasFacet, facets } from '@sb-itops/region-facets';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'invoice-reminder-settings',
  keyPath: 'accountId',
  ngCacheName: 'sbAccountRemindersSettingsService',
  syncType: syncTypes.ALL,
  immutable: false,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getSettings = () => getList()[0] || defaultInvoiceReminderSettings;

export { getPreviewData } from './get-preview-data';
export { saveInvoiceReminderSettings } from './save-settings';

const defaultInvoiceReminderSettings = getDefaultInvoiceReminderSettings({
  allowEmailAttachment: hasFacet(facets.allowEmailAttachment),
});
