'use strict';

const { regionType } = require('@sb-itops/region');

const chequeStyles = [
  { display: 'Standard', code: 0, value: 'Standard', region: regionType.US },
  { display: 'Voucher', code: 1, value: 'Voucher', region: regionType.US },
  { display: 'A4 Standard', code: 2, value: 'A4Standard', region: regionType.AU },
  { display: 'A4 Standard', code: 2, value: 'A4Standard', region: regionType.GB },
];

const chequeStylesByRegion = {
  [regionType.US]: chequeStyles.filter((style) => style.region === regionType.US),
  [regionType.AU]: chequeStyles.filter((style) => style.region === regionType.AU),
  [regionType.GB]: chequeStyles.filter((style) => style.region === regionType.GB),
};

const chequeStylesByValue = chequeStyles.reduce((stylesByValue, style) => {
  // eslint-disable-next-line no-param-reassign
  stylesByValue[style.value] = style;
  return stylesByValue;
}, {});

const chequeStylesByCode = chequeStyles.reduce((stylesByCode, style) => {
  // eslint-disable-next-line no-param-reassign
  stylesByCode[style.code] = style;
  return stylesByCode;
}, {});

module.exports = {
  chequeStyles,
  chequeStylesByRegion,
  chequeStylesByValue,
  chequeStylesByCode,
};
