import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ModalDialog } from '@sb-itops/react/modal-dialog';

const DeleteContactModalDialog = memo(({ onDeleteContact, deleteContactModalId }) => (
  <ModalDialog
    modalId={deleteContactModalId}
    body={
      <div>
        <p>Are you sure you want to delete this contact?</p>
      </div>
    }
    title="Delete Contact"
    buttons={{
      rightAlign: true,
      primary: {
        text: 'Yes',
      },
      secondary: {
        text: 'No',
      },
    }}
    onPrimaryClick={onDeleteContact}
  />
));

DeleteContactModalDialog.displayName = 'DeleteContactModalDialog';

DeleteContactModalDialog.propTypes = {
  onDeleteContact: PropTypes.func.isRequired,
  deleteContactModalId: PropTypes.string.isRequired,
};

DeleteContactModalDialog.defaultProps = {};

export default DeleteContactModalDialog;
