'use strict';

angular.module('sb.billing.webapp').controller('SupportResetModalsController', function ($scope, sbUserBillingAttributesService) {
  let that = this;
  that.resetUserBillingAttributes = resetUserBillingAttributes;
  that.resetted = false;
  that.loading = false;

  const listeners = [
    $scope.$on('$destroy', () => {
      for (let unregister of listeners) unregister();
    }),
    $scope.$on('smokeball-data-update-sbUserBillingAttributesService', () => {
      that.resetted = true;
      that.loading = false;
    }),
  ];

  function resetUserBillingAttributes () {
    that.loading = true;
    sbUserBillingAttributesService.resetUserBillingAttributes(() => that.loading = false);
  }
});
