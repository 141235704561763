import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from '@sb-itops/react';

import { InvoiceStatementEmailForm } from '../invoice-statement-email-form';
import Styles from './InvoiceStatementEmailModal.module.scss';

export const INVOICE_STATEMENT_EMAIL_MODAL_ID = 'invoice-statement-email-modal';

export const InvoiceStatementEmailModal = ({
  /** Modal */
  showModal,
  onModalClose,
  /** Form */
  bccAddress,
  ccAddress,
  formInitialised,
  formSubmitting,
  fromAddress,
  isLoading,
  isPreviewMode,
  isSubmitDisabled,
  message,
  previewMessage,
  previewSubject,
  sendCopyToMe,
  staffAddress,
  subject,
  toAddress,
  // functions
  onFieldValueUpdated,
  onPreviewToggled,
  onSend,
}) => (
  <Modal
    title="Send Email"
    className={Styles.invoiceStatementEmailModal}
    modalBodyClassName={Styles.invoiceStatementEmailModalBody}
    isLoading={isLoading || !formInitialised}
    body={
      <div className={Styles.invoiceStatementEmailFormContainer}>
        <InvoiceStatementEmailForm
          bccAddress={bccAddress}
          ccAddress={ccAddress}
          fromAddress={fromAddress}
          isLoading={isLoading}
          isPreviewMode={isPreviewMode}
          message={message}
          onFieldValueUpdated={onFieldValueUpdated}
          onPreviewToggled={onPreviewToggled}
          previewMessage={previewMessage}
          previewSubject={previewSubject}
          sendCopyToMe={sendCopyToMe}
          staffAddress={staffAddress}
          subject={subject}
          toAddress={toAddress}
        />
      </div>
    }
    footer={
      <Button onClick={onSend} disabled={isSubmitDisabled}>
        {formSubmitting && <Spinner small />} Send Now
      </Button>
    }
    isVisible={showModal}
    onModalClose={onModalClose}
  />
);

InvoiceStatementEmailModal.displayName = 'InvoiceStatementEmailModal';

InvoiceStatementEmailModal.propTypes = {
  /** Modal */
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  /** Form */
  bccAddress: PropTypes.object,
  ccAddress: PropTypes.object,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  fromAddress: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool.isRequired,
  message: PropTypes.object,
  previewMessage: PropTypes.string,
  previewSubject: PropTypes.string,
  sendCopyToMe: PropTypes.object,
  staffAddress: PropTypes.object,
  subject: PropTypes.object,
  toAddress: PropTypes.object,
  // functions
  onFieldValueUpdated: PropTypes.func.isRequired,
  onPreviewToggled: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

InvoiceStatementEmailModal.defaultProps = {
  /** Form */
  bccAddress: undefined,
  ccAddress: undefined,
  fromAddress: undefined,
  isPreviewMode: undefined,
  message: undefined,
  previewMessage: undefined,
  previewSubject: undefined,
  sendCopyToMe: undefined,
  staffAddress: undefined,
  subject: undefined,
  toAddress: undefined,
};
