import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { BankReconciliationAdjustments } from './BankReconciliationAdjustments';

const BankReconciliationAdjustmentsContainer = withReduxStore(BankReconciliationAdjustments);

BankReconciliationAdjustmentsContainer.propTypes = {
  route: PropTypes.string,
  id: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

BankReconciliationAdjustmentsContainer.defaultProps = {
  route: 'billing.bank-reconciliation',
  id: 'reconciliation',
};

export default BankReconciliationAdjustmentsContainer;
