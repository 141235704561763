'use strict';

const { bankAccountTypes } = require('../../bank-account/entities/constants');

const transactionGroupAccountOrder = [
  bankAccountTypes.OPERATING,
  bankAccountTypes.TRUST,
  bankAccountTypes.CONTROLLEDMONEY,
];

module.exports = {
  transactionGroupAccountOrder,
};
