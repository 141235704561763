import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect } from '@sb-itops/react/select';
import lawPayLogoSrc from '../affini-pay/lawpay-logo.png';
import { statesInUsa } from '../affini-pay/states-in-usa';
import { ElectronicChequeHostedFields } from '../electronic-cheque-hosted-fields';

import Styles from './LawpayElectronicChequeForm.module.scss';

const applyFormFieldErrorClass = ({ isInvalid, isDirty }) => {
  if (isInvalid && isDirty) {
    return Styles.hasError;
  }

  return undefined;
};

export const LawpayElectronicChequeForm = memo(
  ({
    publicKey,
    accountHolderTypes,
    accountTypes,
    formInitialised,
    formFields,
    isSubmitting,
    hostedFieldsStyle,
    onChange,
    onHostedFieldsApiReady,
  }) => {
    // Return if the form data hasn't yet been initialised.
    if (!formInitialised) {
      return null;
    }

    // get fields from the form data
    const {
      accountHolderType,
      accountType,
      bankAccountNumberStatus,
      routingNumberStatus,
      firstName,
      lastName,
      businessName,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      email,
    } = formFields;

    const isPersonalAccount = accountHolderType.value === 'Individual';
    const isBusinessAccount = accountHolderType.value === 'Business';

    const onEventBasedChangeHandler = (e) => {
      const target = e.currentTarget;
      onChange(target.name, target.value);
    };

    const onHostedFieldsChangeHandler = ({ field, value }) => {
      onChange(field, value);
    };

    const onSelectChangeHandler = (field, item) => {
      if (item && item.value) {
        return onChange(field, item.value);
      }

      // Allow the value to be cleared if the select component has isClearable set to true
      return onChange(field, undefined);
    };

    return (
      <div
        className={classnames(Styles.lawpayElectronicChequeForm, 'payment-provider-form-fields', 'lawpay-form-fields')}
      >
        <fieldset disabled={isSubmitting}>
          <div className={Styles.container}>
            {/* Account holder type and account type row */}
            <div className={Styles.row}>
              {/* Account holder type field */}
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>Account Holder Type</label>
                <div className={Styles.accountHolderType}>
                  <StatelessSelect
                    className={classnames(
                      applyFormFieldErrorClass(accountHolderType),
                      Styles.accountDropdown,
                      Styles.select,
                    )}
                    disabled={isSubmitting}
                    isClearable={false}
                    selectedOption={
                      accountHolderType && { value: accountHolderType.value, label: accountHolderType.value }
                    }
                    stateless
                    options={accountHolderTypes}
                    onChange={(selectedOption) => onSelectChangeHandler('accountHolderType', selectedOption)}
                  />
                </div>
              </div>

              {/* Account holder type field */}
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>Account Type</label>
                <div className={Styles.accountType}>
                  <StatelessSelect
                    className={classnames(Styles.select, Styles.accountDropdown, applyFormFieldErrorClass(accountType))}
                    disabled={isSubmitting}
                    isClearable={false}
                    selectedOption={accountType && { value: accountType.value, label: accountType.value }}
                    stateless
                    options={accountTypes}
                    onChange={(selectedOption) => onSelectChangeHandler('accountType', selectedOption)}
                  />
                </div>
              </div>
            </div>

            {/* Personal account name and lawpay logo row */}
            {isPersonalAccount && (
              <div className={Styles.row}>
                {/* Name container */}
                <div className={classnames(Styles.cell, Styles.cell75, Styles.cellNoMargin)}>
                  <div className={Styles.row}>
                    {/* First name field */}
                    <div className={classnames(Styles.cell, Styles.cell50)}>
                      <label>First Name</label>
                      <input
                        name="firstName"
                        type="text"
                        className={classnames(
                          'form-control',
                          Styles.withLineHeight,
                          applyFormFieldErrorClass(firstName),
                        )}
                        value={firstName.value}
                        onChange={onEventBasedChangeHandler}
                      />
                    </div>

                    {/* Last name field */}
                    <div className={classnames(Styles.cell, Styles.cell50)}>
                      <label>Last Name</label>
                      <input
                        name="lastName"
                        type="text"
                        className={classnames(
                          'form-control',
                          Styles.withLineHeight,
                          applyFormFieldErrorClass(lastName),
                        )}
                        value={lastName.value}
                        onChange={onEventBasedChangeHandler}
                      />
                    </div>
                  </div>
                </div>

                {/* Lawpay logo */}
                <div className={classnames(Styles.cell, Styles.cell25)}>
                  <img className={Styles.lawPayLogo} src={lawPayLogoSrc} alt="Processing by LawPay.com" />
                </div>
              </div>
            )}

            {/* Business account name and lawpay logo row */}
            {isBusinessAccount && (
              <div className={Styles.row}>
                {/* Business name field */}
                <div className={classnames(Styles.cell, Styles.cell75)}>
                  <label>Business Name</label>
                  <input
                    name="businessName"
                    type="text"
                    className={classnames(
                      'form-control',
                      Styles.withLineHeight,
                      applyFormFieldErrorClass(businessName),
                    )}
                    value={businessName.value}
                    onChange={onEventBasedChangeHandler}
                  />
                </div>

                {/* Lawpay logo */}
                <div className={classnames(Styles.cell, Styles.cell25)}>
                  <img className={Styles.lawPayLogo} src={lawPayLogoSrc} alt="Processing by LawPay.com" />
                </div>
              </div>
            )}

            {/* Hosted fields (card number and security code) */}
            {/* We wrap hosted fields to form tag which makes them separate form. Without this, address autofill in browser clears these fields */}
            <form>
              <div className={classnames(Styles.row, 'credit-card-entry-group-mobile')}>
                <ElectronicChequeHostedFields
                  disabled={isSubmitting}
                  publicKey={publicKey}
                  bankAccountNumberError={bankAccountNumberStatus.isDirty && bankAccountNumberStatus.isInvalid}
                  routingNumberError={routingNumberStatus.isDirty && routingNumberStatus.isInvalid}
                  style={hostedFieldsStyle}
                  onFieldChange={onHostedFieldsChangeHandler}
                  onHostedFieldsApiReady={onHostedFieldsApiReady}
                />
              </div>
            </form>

            {/* Address block */}
            <div className={Styles.row}>
              {/* Address line 1 field */}
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>Address Line 1</label>
                <input
                  name="addressLine1"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(addressLine1))}
                  value={addressLine1.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>

              {/* Address line 2 field */}
              <div className={classnames(Styles.cell, Styles.cell50, Styles.showOnLowerRes)}>
                <label>Address Line 2</label>
                <input
                  name="addressLine2"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(addressLine2))}
                  value={addressLine2.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>

              {/* City field */}
              <div className={classnames(Styles.cell, Styles.cell50)}>
                <label>City</label>
                <input
                  name="city"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(city))}
                  value={city.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>
            </div>
            <div className={Styles.row}>
              {/* Address line 2 field */}
              <div className={classnames(Styles.cell, Styles.cell50, Styles.hideOnLowerRes)}>
                <label>Address Line 2</label>
                <input
                  name="addressLine2"
                  type="text"
                  className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(addressLine2))}
                  value={addressLine2.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>
              <div className={classnames(Styles.cellGroup, Styles.cell50)}>
                {/* State field */}
                <div className={classnames(Styles.cell, Styles.cell50)}>
                  <label>State</label>
                  <StatelessSelect
                    className={classnames(applyFormFieldErrorClass(state), Styles.states, Styles.select)}
                    selectedOption={state.value}
                    stateless
                    placeholder=" "
                    disabled={isSubmitting}
                    isClearable={false}
                    options={statesInUsa}
                    onChange={(selectedOption) => onSelectChangeHandler('state', selectedOption)}
                  />
                </div>

                <span className={classnames(Styles.separator, Styles.hideOnLowerRes)} />

                {/* Zip / postal code field */}
                <div className={classnames(Styles.cell, Styles.cell50, Styles.zip)}>
                  <label>Zip</label>
                  <input
                    name="postalCode"
                    type="text"
                    placeholder="12345 or 1234-1234"
                    className={classnames('form-control', Styles.withLineHeight, applyFormFieldErrorClass(postalCode))}
                    value={postalCode.value}
                    onChange={onEventBasedChangeHandler}
                  />
                </div>
              </div>
            </div>

            {/* Email field */}
            <div className={Styles.row}>
              <div className={classnames(Styles.cell, Styles.cell100)}>
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  className={classnames(
                    'form-control',
                    Styles.withLineHeight,
                    applyFormFieldErrorClass(email),
                    Styles.emailLine,
                  )}
                  value={email.value}
                  onChange={onEventBasedChangeHandler}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    );
  },
);

LawpayElectronicChequeForm.displayName = 'LawpayElectronicChequeForm';

LawpayElectronicChequeForm.propTypes = {
  publicKey: PropTypes.string.isRequired,
  formInitialised: PropTypes.bool,
  formFields: PropTypes.any,
  isSubmitting: PropTypes.bool,
  accountHolderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  accountTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  hostedFieldsStyle: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onHostedFieldsApiReady: PropTypes.func.isRequired,
};

LawpayElectronicChequeForm.defaultProps = {
  formInitialised: false,
  formFields: undefined,
  isSubmitting: false,
  hostedFieldsStyle: {},
};
