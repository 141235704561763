import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchDeleteP } from '@sb-itops/redux/fetch';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

export const deleteContact = (contactId) =>
  store.dispatch(async () => {
    // minimal opdate entity to provide user with instant feedback on UI
    const opDate = { entityId: contactId, removed: true };
    opdateCache({ optimisticEntities: [opDate] });

    try {
      const path = `/customer-management/contact/:accountId/person/${contactId}`;
      const fetchOptions = {};
      const res = await fetchDeleteP({ path, fetchOptions });

      return res.body.id;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [opDate] });
      throw err;
    }
  });
