// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMessages, dismissMessage } from '@sb-itops/redux/message';
import { withReduxStore } from '../with-redux-store';
import MessageList from './MessageList';

const mapStateToProps = (state) => ({
  messages: getMessages(state),
  onClickDismiss: dismissMessage,
});

const MessageListContainer = withReduxStore(connect(mapStateToProps)(MessageList));

MessageListContainer.propTypes = {};

MessageListContainer.defaultProps = {};

export default MessageListContainer;
