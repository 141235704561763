'use strict';

module.exports = (string, { debtorName, staffName, phoneNumber, firmName, showAddCardButton, requestReason }) =>
  [
    replaceDebtor(handleNullishValue(debtorName)),
    replaceUsername(handleNullishValue(staffName)),
    replacePhoneNumber(handleNullishValue(phoneNumber)),
    replaceRequestReason(handleNullishValue(requestReason)),
    replaceFirmName(handleNullishValue(firmName)),
    replaceAddCardButton(showAddCardButton),
  ].reduce((str, fn) => fn(str), string);

function replaceDebtor(debtorName = '') {
  return (string) => string.replace(/\[CLIENT_NAME\]|\[DEBTOR_NAME\]/g, debtorName);
}

function replaceUsername(staffName = '') {
  return (string) => string.replace(/\[USER_NAME\]/g, staffName);
}

function replacePhoneNumber(phoneNumber = '') {
  return (string) => string.replace(/\[PHONE_NUMBER\]/g, phoneNumber);
}

function replaceRequestReason(requestReason = '') {
  return (string) => string.replace(/\[REQUEST_REASON\]/g, requestReason);
}

function replaceFirmName(firmName = '') {
  return (string) => string.replace(/\[FIRM_NAME\]/g, firmName);
}

// the implementation here is unfortunately specific to the component we use to allow the user
// to write formatted text (Quill).
// This code will remove the button placeholder in addition to the blank line below it (if it exists)
function replaceAddCardButton(showButton) {
  return (string) =>
    showButton ? string : string.replace(/(:?<p>)?\[ADD_CARD_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '');
}

function handleNullishValue(value) {
  // eslint-disable-next-line eqeqeq
  return value == undefined ? undefined : value;
}
