/**
 * The sbCompose comonent is a container for sharing data amongst the composeable components that it wraps.
 * 
 * Composeable components declare the compose container as a parent in the component `require` definition. 
 * For example:
 * 
 * angular.module('foo').component('bar', {
 *   require:  { compose: '^sbCompose' },
 *   bindings : { composeDataKey: '@?' },
 *   controller: () => {}
 * }
 * 
 * They then push data into the container using the `setComposeData` function, storing their data under a key. 
 * Composeable components can register to be notified of changes to one of the keys in the container via the 
 * `onUpdateData` function.
 * 
 * The component that is the parent of the compose container can register to be notified when data is added to
 * the container via the `changeFunction` function. 
 */
angular.module('sb.billing.webapp').component('sbCompose',
  {
    template: '<div class="sb-compose"><ng-transclude></ng-transclude></div>',
    transclude: true,
    bindings: { changeFunction: '&?' },
    controller: function ($scope, sbLoggerService) {
      'use strict';
      var data = {};
      var updateHandlers = {};
      var defaultKey = '__default__';
      var log = sbLoggerService.getLogger('sbCompose', $scope.$id);
      //log.setLogLevel('info');

      this.setComposeData = function (doc, key) {
        log.info('set data', key, doc);
        if (key === undefined) {
          key = defaultKey;
        }

        _.set(data, key, doc);

        if (_.isFunction(this.changeFunction)) {
          log.info('call change function');
          this.changeFunction({key: key, doc: doc});
        }
        callUpdateHandlers(key);
      };

      this.onUpdateData = function (key, handler) {
        log.debug('data handler registered, key', key);

        if (key === undefined) {
          key = defaultKey;
        }

        if (!updateHandlers[key]) {
          updateHandlers[key] = [];
        }

        updateHandlers[key].push(handler);
        handlerInitData(key, handler);
      };

      /*
      This call is required in case data has already been set for a key
      at the time that a handler is registered.
      */
      function handlerInitData(key, handler) {
        if (!_.isEmpty(data[key])) {
          if (_.isFunction(handler)) {
            handler(data[key]);
          }
        }
      }

      function callUpdateHandlers(key) {
        log.debug('calling handlers');
        if (!_.isEmpty(updateHandlers[key])) {
          _.each(updateHandlers[key], function (handler) {
            if (_.isFunction(handler)) {
              handler(data[key]);
            }
          });
        }
      }
    }
  }
);
