import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as singleItemSelect from './index';

/**
 * useSingleItemSelection - hook for managing single item selection state in redux
 *
 * @param {Object} param
 * @param {string} param.scope Scope ID for the feature
 * @param {string} [param.initialSelection] Initial/default item
 * @returns {Object} Object containing the `selectedItem` value and the updater function
 */
export const useSingleItemSelection = ({ scope, initialSelection }) => {
  const dispatch = useDispatch();
  const { selectors, actions } = useScopedFeature(singleItemSelect, scope);

  let selectedItem = useSelector(selectors.getSelectedItem);
  const hasChanged = useSelector(selectors.getHasChanged);

  if (!hasChanged && initialSelection !== undefined) {
    selectedItem = initialSelection; // Don't wait for the dispatch to return the custom initial value
    dispatch(actions.setSelectedItem({ item: initialSelection }));
  }

  const setSelectedItem = (newSelectedItem) => dispatch(actions.setSelectedItem({ item: newSelectedItem }));

  const clearSelectedItem = () => dispatch(actions.clearSelectedItem());

  return {
    selectedItem,
    setSelectedItem,
    clearSelectedItem,
  };
};
