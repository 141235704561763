import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { useExportAndPrintPdf } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import * as billingAccountsTransactions from 'web/redux/route/home-billing-accounts-transactions';
import * as billingTrustCheques from 'web/redux/route/billing-accounts-transactions-trust-cheques/feature';
import BillingAccountsTransactionsTrustCheques from './BillingAccountsTransactionsTrustCheques';

const { getTrustChequeData } = billingTrustCheques.selectors;
const { sortRows } = billingTrustCheques.actions;

const hooks = (props) => ({
  useTrustChequesData: () => {
    const { t } = useTranslation();

    const { showSystemDate } = billingAccountsTransactions.toggleShowSystemDateApi.getState();
    const { unprintedCheques, cheques, sortBy, sortDirection } = useSelector((state) => getTrustChequeData(state, t));
    const { showHidden: showDeletedTransactions, dateListFilter } = useSelector((state) =>
      billingAccountsTransactions.selectors.getFilters(state),
    );

    return {
      ...props,
      showDeletedTransactions,
      showSystemDate,
      showChequeMemo: hasFacet(facets.chequeMemo),
      showAllTime: !dateListFilter.filterType || dateListFilter.filterType === 'ALL',
      startDate: dateListFilter.startDate,
      endDate: dateListFilter.endDate,
      unprintedCheques,
      cheques: cheques.filter(
        (cheque) =>
          (!dateListFilter.startDate && !dateListFilter.endDate) ||
          (cheque.effectiveDate >= dateListFilter.startDate && cheque.effectiveDate <= dateListFilter.endDate),
      ),
      sortBy,
      sortDirection,
    };
  },
  useOnSort: () => {
    const dispatch = useDispatch();
    const onSort = ({ sortBy, sortDirection }) => {
      dispatch(sortRows({ sortBy, sortDirection }));
    };

    return {
      onSort,
    };
  },
});

const dependentHooks = ({
  sbAsyncOperationsService,
  sortBy,
  sortDirection,
  showDeletedTransactions,
  showSystemDate,
  showAllTime,
  startDate,
  endDate,
}) => ({
  useExportOperations: () => {
    const { t } = useTranslation();
    const printDialogIframeId = 'trustChequesExportPdf';
    const { exportAndPrintPdf, isGeneratingPdf } = useExportAndPrintPdf({
      printDialogIframeId,
      exportTypeLabel: `${t('trustCheque')}s`,
      exportUrl: '/billing/trust-cheques/export/:accountId/',
    });

    const sort = { fieldNames: [sortBy || 'effectiveDate'], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] };

    const onExportAsPdf = async () => {
      await exportAndPrintPdf({
        filters: { sort, showDeletedTransactions, showEnteredDate: showSystemDate, showAllTime, startDate, endDate },
      });
    };

    const onExportAsCsv = async () => {
      try {
        sbAsyncOperationsService.startExportTrustChequesCsv({
          sort,
          showDeletedTransactions,
          showEnteredDate: showSystemDate,
          showAllTime,
          startDate,
          endDate,
        });
      } catch (error) {
        throw new Error('Failed to export client communications history. Please try again later.');
      }
    };

    return {
      onExportAsCsv,
      onExportAsPdf,
      printDialogIframeId,
      isGeneratingPdf,
    };
  },
});

const BillingAccountsTransactionsTrustChequesContainer = withReduxProvider(
  composeHooks(hooks)(composeHooks(dependentHooks)(BillingAccountsTransactionsTrustCheques)),
);

BillingAccountsTransactionsTrustChequesContainer.displayName = 'BillingAccountsTransactionsTrustChequesContainer';

BillingAccountsTransactionsTrustChequesContainer.propTypes = {
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  onPrintCheque: PropTypes.func,
  onShowPrintChequesModal: PropTypes.func,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};
BillingAccountsTransactionsTrustChequesContainer.defaultProps = {
  onClickLink: undefined,
  onClickRow: undefined,
  onPrintCheque: undefined,
  onShowPrintChequesModal: undefined,
};

export default BillingAccountsTransactionsTrustChequesContainer;
