import { useState, useRef } from 'react';
import uuid from '@sb-itops/uuid';
import { getLogger } from '@sb-itops/fe-logger';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import * as messageDisplay from '@sb-itops/message-display';
import { useTranslation, withOnLoad } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchPostP, fetchDeleteP } from '@sb-itops/redux/fetch';
import { getAppEnv, envType } from '@sb-itops/app-env';
import {
  providers,
  providerNames,
  tempItemTypes,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { saveTempItem, deleteTempItem } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getFirmName, getPhoneNumber, getFirmDetails } from '@sb-firm-management/redux/firm-management';
import { getRegion } from '@sb-itops/region';
import { pick } from 'dot-object';
import {
  legalStructuresOptions,
  accountTypeOptions,
} from '@sb-billing/business-logic/payment-provider/services/fee-wise';
import {
  getFeewiseStrategy,
  feewiseStrategies,
} from '@sb-billing/business-logic/payment-provider/services/fee-wise/strategies';
import { getAccountId } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';
import { feeWiseApplicationFormSchema } from './FeeWiseApplicationFormSchema.yup';
import { FeeWiseApplicationForm } from './FeeWiseApplicationForm';
import { marshalSubmit } from './marshal-submit';
import { marshalSave } from './marshal-save';
import { addExtraDataToMarshalled } from './add-extra-data-to-marshalled';
import { autofillTestData } from './autofill-test-data';
import { getDefaultAutofillTrustAccount } from './get-autofill-trust-account';

const scope = 'fee-wise-application-form';
const log = getLogger('FeeWiseApplicationFormContainer');
const region = getRegion();

const hooks = ({ applicationFormData }) => ({
  useFeeWiseApplication: () => {
    const { t } = useTranslation();
    const isNonProdAppEnv = getAppEnv() !== envType.PRODUCTION;
    // In non-prod env we use random uuid. This lets us create a firm, disconnect and create new one again repeatedly.
    // In prod env, we use accountId and therefore we can create only 1 firm, disconnection is not possible.
    const externalFirmId = isNonProdAppEnv ? uuid() : getAccountId();

    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [beneficialOwnerDifferent, setBeneficialOwnerDifferent] = useState(
      applicationFormData.beneficialOwnerDifferent || false,
    );
    const [confirmationDateTime, setConfirmationDateTime] = useState(
      applicationFormData.confirmationDateTime || undefined,
    );

    const localisationConfig = {
      showBusinessStructure: getFeewiseStrategy(region, feewiseStrategies.SHOULD_SHOW_BUSINESS_STRUCTURE),
      populateACN: getFeewiseStrategy(region, feewiseStrategies.SHOULD_POPULATE_ACN),
      socialSecurityField: getFeewiseStrategy(region, feewiseStrategies.SOCIAL_SECURITY_FIELD),
      routingNumberField: getFeewiseStrategy(region, feewiseStrategies.ROUTING_NUMBER_FIELD),
      zipCodeField: getFeewiseStrategy(region, feewiseStrategies.ZIP_CODE_FIELD),
      nationalIdField: getFeewiseStrategy(region, feewiseStrategies.NATIONAL_ID_FIELD),
    };

    const businessStructureOptions = legalStructuresOptions;
    const countryCode = region;
    const defaultAutofillTrustAccount = getDefaultAutofillTrustAccount(
      getBankAccountSettings()?.defaultTrustAccounts || [],
      getActiveTrustAccounts(),
    );
    const firmDetailsSectionRef = useRef(null);
    const acknowledgementCheckboxSectionRef = useRef(null);
    const authorizedRepresentativeSectionRef = useRef(null);
    const beneficialOwnerSectionRef = useRef(null);
    const operatingAccountDetailsSectionRef = useRef(null);
    const trustAccountDetailsSectionRef = useRef(null);
    const sectionNamesToRefs = {
      firmDetails: firmDetailsSectionRef,
      operatingAccountDetails: operatingAccountDetailsSectionRef,
      trustAccountDetails: trustAccountDetailsSectionRef,
      authorizedRepresentative: authorizedRepresentativeSectionRef,
      beneficialOwner: beneficialOwnerSectionRef,
      acknowledgementCheckbox: acknowledgementCheckboxSectionRef,
    };

    const {
      formValues,
      formFields,
      formValidation,
      formInitialised,
      submitFailed,
      formDisabled,
      formValid,
      onInitialiseForm,
      onClearForm,
      onUpdateFields,
      onUpdateFieldValues,
      onSubmitFormWithValidation,
      onValidateForm,
    } = useForm({
      scope,
      schema: feeWiseApplicationFormSchema,
    });

    const {
      firmDetails,
      operatingAccountDetails,
      trustAccountDetails,
      authorizedRepresentative,
      beneficialOwner,
      acknowledgementCheckbox,
      testAndDebug,
    } = formFields;
    const firmDetailsData = getFirmDetails();
    const shouldValidateTrustAccount = trustAccountDetails
      ? !!Object.values(trustAccountDetails).find((field) => Boolean(field.value))
      : false;

    const validationContext = {
      shouldValidateTrustAccount,
      beneficialOwnerDifferent,
      formValues,
      newFormFields: featureActive('BB-14112'),
      localisationConfig,
      t,
    };
    const validateForm = () => onValidateForm(validationContext);

    return {
      // Form sections
      firmDetails,
      operatingAccountDetails,
      trustAccountDetails,
      authorizedRepresentative,
      beneficialOwner,
      acknowledgementCheckbox,
      testAndDebug,
      // Refs
      firmDetailsSectionRef,
      operatingAccountDetailsSectionRef,
      trustAccountDetailsSectionRef,
      authorizedRepresentativeSectionRef,
      beneficialOwnerSectionRef,
      acknowledgementCheckboxSectionRef,
      // Options
      businessStructureOptions,
      accountTypeOptions,
      // Other
      today: new Date(),
      isSaving,
      isSubmitting,
      beneficialOwnerDifferent,
      localisationConfig,
      setBeneficialOwnerDifferent,
      setConfirmationDateTime,
      submitFailed,
      formInitialised,
      formDisabled,
      isNonProdAppEnv,
      autofillFormData: () =>
        autofillTestData({
          onUpdateFields,
          firmDetails,
          defaultAutofillTrustAccount,
          setBeneficialOwnerDifferent,
          setConfirmationDateTime,
          validateForm,
          region,
        }),
      onLoad: () => {
        const {
          firmDetails: savedFirmDetails,
          operatingAccountDetails: savedOperatingAccountDetails,
          trustAccountDetails: savedTrustAccountDetails,
          authorizedRepresentative: savedAuthorizedRepresentative,
          beneficialOwner: savedBeneficialOwner,
          acknowledgementCheckbox: savedAcknowledgementCheckbox,
        } = applicationFormData;
        onInitialiseForm({
          firmDetails: {
            legalCompanyName: savedFirmDetails?.legalCompanyName || getFirmName() || '',
            tradingAs: savedFirmDetails?.tradingAs || getFirmName() || '',
            businessStructure: savedFirmDetails?.businessStructure || '',
            employerIdNumber:
              savedFirmDetails?.employerIdNumber || (localisationConfig.populateACN && firmDetailsData?.acn) || '',
            phoneNumber: savedFirmDetails?.phoneNumber || getPhoneNumber() || '',
            emailAddress: savedFirmDetails?.emailAddress || '',
            website: savedFirmDetails?.website || '',
            streetAddressLine1:
              savedFirmDetails?.streetAddressLine1 ||
              getFeewiseStrategy(region, feewiseStrategies.GET_FIRM_ADDRESS_LINE_1, firmDetailsData) ||
              '',
            streetAddressLine2:
              savedFirmDetails?.streetAddressLine2 ||
              getFeewiseStrategy(region, feewiseStrategies.GET_FIRM_ADDRESS_LINE_2, firmDetailsData) ||
              '',
            city:
              savedFirmDetails?.city ||
              getFeewiseStrategy(region, feewiseStrategies.GET_FIRM_ADDRESS_CITY, firmDetailsData) ||
              '',
            state:
              savedFirmDetails?.state ||
              getFeewiseStrategy(region, feewiseStrategies.GET_FIRM_ADDRESS_STATE, firmDetailsData) ||
              undefined,
            zipCode:
              savedFirmDetails?.zipCode ||
              getFeewiseStrategy(region, feewiseStrategies.GET_FIRM_ADDRESS_ZIPCODE, firmDetailsData) ||
              '',
          },
          operatingAccountDetails: {
            accountHolderName: savedOperatingAccountDetails?.accountHolderName || '',
            accountName: savedOperatingAccountDetails?.accountName || '',
            routingNumber: savedOperatingAccountDetails?.routingNumber || '',
            accountNumber: savedOperatingAccountDetails?.accountNumber || '',
            accountType: savedOperatingAccountDetails?.accountType || '',
            nominateForMonthlyBilling:
              savedOperatingAccountDetails && 'nominateForMonthlyBilling' in savedOperatingAccountDetails
                ? savedOperatingAccountDetails.nominateForMonthlyBilling
                : true,
          },
          trustAccountDetails: {
            accountHolderName:
              savedTrustAccountDetails?.accountHolderName || defaultAutofillTrustAccount?.accountName || '',
            accountName: savedTrustAccountDetails?.accountName || defaultAutofillTrustAccount?.displayName || '',
            routingNumber: savedTrustAccountDetails?.routingNumber || '',
            accountNumber: savedTrustAccountDetails?.accountNumber || '',
            accountType: savedTrustAccountDetails?.accountType || '',
          },
          authorizedRepresentative: {
            firstName: savedAuthorizedRepresentative?.firstName || '',
            lastName: savedAuthorizedRepresentative?.lastName || '',
            jobTitle: savedAuthorizedRepresentative?.jobTitle || '',
            phoneNumber: savedAuthorizedRepresentative?.phoneNumber || '',
            emailAddress: savedAuthorizedRepresentative?.emailAddress || '',
            streetAddressLine1: savedAuthorizedRepresentative?.streetAddressLine1 || '',
            streetAddressLine2: savedAuthorizedRepresentative?.streetAddressLine2 || '',
            city: savedAuthorizedRepresentative?.city || '',
            state: savedAuthorizedRepresentative?.state || undefined,
            zipCode: savedAuthorizedRepresentative?.zipCode || '',
            dateOfBirth: savedAuthorizedRepresentative?.dateOfBirth || undefined,
            last4SocialSecurity: savedAuthorizedRepresentative?.last4SocialSecurity || '',
            socialSecurityNumber: savedAuthorizedRepresentative?.socialSecurityNumber || '',
            businessOwnership: savedAuthorizedRepresentative?.businessOwnership || undefined,
          },
          beneficialOwner: {
            firstName: savedBeneficialOwner?.firstName || '',
            lastName: savedBeneficialOwner?.lastName || '',
            jobTitle: savedBeneficialOwner?.jobTitle || '',
            phoneNumber: savedBeneficialOwner?.phoneNumber || '',
            emailAddress: savedBeneficialOwner?.emailAddress || '',
            streetAddressLine1: savedBeneficialOwner?.streetAddressLine1 || '',
            streetAddressLine2: savedBeneficialOwner?.streetAddressLine2 || '',
            city: savedBeneficialOwner?.city || '',
            state: savedBeneficialOwner?.state || undefined,
            zipCode: savedBeneficialOwner?.zipCode || '',
            dateOfBirth: savedBeneficialOwner?.dateOfBirth || undefined,
            last4SocialSecurity: savedBeneficialOwner?.last4SocialSecurity || '',
            socialSecurityNumber: savedBeneficialOwner?.socialSecurityNumber || '',
            businessOwnership: savedBeneficialOwner?.businessOwnership || undefined,
          },
          acknowledgementCheckbox: {
            confirmation: savedAcknowledgementCheckbox?.confirmation || false,
          },
          testAndDebug: {
            createFirmOwnerAccount: true,
          },
        });
        validateForm();
        return onClearForm;
      },
      onValidateForm: validateForm,
      onToggleField: ({ field }) => {
        onUpdateFields({ [field]: !pick(field, formValues) });
      },
      onFieldUpdated: (fieldValues) => {
        onUpdateFields(fieldValues);
        validateForm();
      },
      onUpdateBusinessOwnership: ({ percent, max, min, fieldName }) => {
        // auto-round any decimals
        if (percent !== 'NaN') {
          if (percent > max) {
            onUpdateFields({ [fieldName]: 100 });
          } else if (percent < min) {
            onUpdateFields({ [fieldName]: 0 });
          } else {
            onUpdateFields({ [fieldName]: Math.round(parseFloat(percent)) });
          }
        } else {
          onUpdateFields({ [fieldName]: '' });
        }
        validateForm();
      },
      onUpdateFieldValues,
      onSubmit: async () => {
        validateForm();
        if (!formValid) {
          // Scroll to first error section
          const errorFields = Object.keys(formValidation);
          if (errorFields.length > 0) {
            // formValidation keys looks like ['firmDetails.emailAddress', 'operatingAccountDetails.accountNumber']
            const sectionName = errorFields[0].split('.')[0];
            const ref = sectionNamesToRefs[sectionName];
            ref.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
        onSubmitFormWithValidation({
          submitFnP: async () => {
            try {
              setIsSubmitting(true);
              const marshalledData = marshalSubmit({
                formValues,
                beneficialOwnerDifferent,
                countryCode,
                shouldValidateTrustAccount,
                confirmationDateTime,
                externalFirmId,
                newFormFields: featureActive('BB-14112'),
                localisationConfig,
              });

              const requestInfo = {
                connectionStep: 'CREATE_PAYMENT_APPLICATION',
                data: addExtraDataToMarshalled({ marshalledData, formValues, isNonProdAppEnv }),
                createFirmOwnerAccount: isNonProdAppEnv
                  ? formValues && formValues.testAndDebug && formValues.testAndDebug.createFirmOwnerAccount
                  : true,
              };

              try {
                const path = `/billing/payment-provider/connect/${providers.FEE_WISE.toLowerCase()}/:accountId/`;
                const fetchOptions = { body: JSON.stringify(requestInfo) };
                const response = await fetchPostP({ path, fetchOptions });
                // If there was problem with creating firm owner account, we wanna show message even when firm was successfully created
                if (response.status === 200 && !response?.body?.account) {
                  messageDisplay.error('Problem activating dashboard. Please contact support.');
                }

                // Delete saved application if there is any
                await deleteTempItem({
                  fetchDeleteP,
                  providerType: providers.FEE_WISE,
                  itemType: tempItemTypes.APPLICATION_FORM,
                });
              } catch (err) {
                log.error(`Failed to create ${providerNames.FEE_WISE} account`, err);
                messageDisplay.error(`Failed to create ${providerNames.FEE_WISE} account`);
              }
            } catch (err) {
              log.error(err);
              messageDisplay.error('Failed to submit application form');
              throw err;
            } finally {
              setIsSubmitting(false);
            }
          },
        });
      },
      onSave: async () => {
        setIsSaving(true);
        try {
          const saveData = marshalSave({
            formValues,
            beneficialOwnerDifferent,
            confirmationDateTime,
          });

          await saveTempItem({
            fetchPostP,
            providerType: providers.FEE_WISE,
            itemType: tempItemTypes.APPLICATION_FORM,
            data: saveData,
          });
          messageDisplay.success('Successfully saved application form');
          setIsSaving(false);
        } catch (err) {
          log.error(err);
          messageDisplay.error('Failed to save application form');
          throw err;
        } finally {
          setIsSaving(false);
        }
      },
    };
  },
});

export const FeeWiseApplicationFormContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(FeeWiseApplicationForm)),
);

FeeWiseApplicationFormContainer.displayName = 'FeeWiseApplicationFormContainer';
FeeWiseApplicationFormContainer.propTypes = {};
FeeWiseApplicationFormContainer.defaultProps = {};
