import * as types from './types';

/**
 * Creates an action which, when dispatched, will cause the state of all the passed {@link items} to be 'toggled' in order.
 * That is, selected items will become deselected, deselected items will become selected.
 *
 * @param  {Array} items
 *   Array of items to be toggled in order.
 *
 * @return {object} Dispatchable action.
 */
export const toggleOrderedItems = ({ items }) => ({
  type: types.TOGGLE_ORDERED_ITEMS,
  payload: {
    items,
  },
});

/**
 * Creates an action which, when dispatched, will cause all of the currently selected ordered items to be deselected.
 *
 * @return {object} Dispatchable action.
 */
export const deselectAllOrderedItems = () => ({
  type: types.DESELECT_ALL_ORDERED_ITEMS,
  payload: {},
});
