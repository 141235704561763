'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/logout';

  $stateProvider
    .state('logout', {
      url: '/logout',
      controller: 'SbLogoutController',
      controllerAs: '$ctrl',
      templateUrl: BASE_DIRECTORY + '/logout.html',
      params: {
        allDevices: false,
        unregisteredUser: false,
      }
    });
});
