'use strict';
import { isEmpty, isEqual } from 'lodash';
import { constants as invoiceSettingsConstants } from '@sb-billing/business-logic/invoice-settings';

// TODO change this to be a generic typeahead style component - TH

angular.module('sb.billing.webapp').component('sbFontSelection', {
  templateUrl: 'ng-components/invoice-settings/font-selection.html',
  bindings: {initFont: '<', syncUpdate: '&'},
  controller: function ($scope) {
    const ctrl = this;

    $scope.$watch('$ctrl.initFont', (newVal, oldVal) => {
      if (!isEmpty(oldVal) && !isEmpty(newVal) && !isEqual(oldVal, newVal)) {
        ctrl.selectedFont = ctrl.initFont;
      }
    });

    ctrl.onSelectFont = onSelectFont;

    ctrl.$onInit = () => {
      ctrl.fonts = invoiceSettingsConstants.fonts;
      ctrl.selectedFont = ctrl.initFont;
    };

    function onSelectFont ({label, value}) {
      ctrl.syncUpdate({selectedFont: {label, value}});
    }
  }
});
