import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'balance-protection',
  keyPath: 'id',
  ngCacheName: 'sbBalanceProtectionService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, updateCache, UPDATE_CACHE, getIndex, getList, clearCache } = api;

export const getById = (id) => getMap()[id];

export const getByContactId = (contactId) => getList().filter((p) => p.contactId === contactId);
export const getByMatterId = (matterId) => getList().filter((p) => p.matterId === matterId);
export const getByAccountId = (accountId) => getList().filter((p) => p.accountId === accountId);

export const getActiveAll = () => getList().filter((p) => p.isProtected);
export const getActiveByContactId = (contactId) => getList().filter((p) => p.isProtected && p.contactId === contactId);
export const getActiveByMatterId = (matterId) => getList().filter((p) => p.isProtected && p.matterId === matterId);
export const getActiveByAccountId = (accountId) => getList().filter((p) => p.isProtected && p.accountId === accountId);
export const getActiveByBankAccountId = (bankAccountId) =>
  getList().filter((p) => p.isProtected && p.bankAccountId === bankAccountId);
export const getActiveByMatterBankAccountId = (matterId, bankAccountId) =>
  getList().filter((p) => p.isProtected && p.bankAccountId === bankAccountId && p.matterId === matterId);
export const getActiveByMatterIdByDate = (matterId, date) =>
  getList().filter((p) => p.isProtected && p.matterId === matterId && p.effectiveDate <= date);
export const matterHasProtectedFundsForBankAccountId = (matterId, bankAccountId) =>
  !!getActiveByMatterBankAccountId(matterId, bankAccountId).length;
export const getTotalProtectedAmountByMatter = (matterId) =>
  getActiveByMatterId(matterId).reduce((acc, pf) => acc + pf.amount, 0);

export * from './protect-balance';
// eslint-disable-next-line import/no-cycle
export * from './unprotect-balance';
