'use strict';

/**
 * @function formatDollarAmountForDisplay
 * @param {number} dollarAmount
 *
 * Displays the dollar amount in a human readable format
 * E.g. -100 => -$100
 *
 * @returns {string}
 */

function formatDollarAmountForDisplay(dollarAmount) {
  const sign = Math.sign(dollarAmount);
  const negative = -1;

  const displayAmount = `$${Math.abs(dollarAmount).toFixed(2)}`;

  if (sign === negative) {
    return `-${displayAmount}`;
  }

  return displayAmount;
}

module.exports = {
  formatDollarAmountForDisplay,
};
