'use strict';

/**
 * @param {string} id
 * @returns {boolean}
 */
const isBulkDepositReversalId = (id) => {
  if (id?.includes('_reversal')) {
    return true;
  }
  return false;
};

/**
 * @param {string} id
 * @returns {boolean}
 */
const getBulkDepositReversalIdFromId = (id) => `${id}_reversal`;

/**
 * @param {string} id
 * @returns {boolean}
 */
const getBulkDepositIdFromReversalId = (id) => id?.replace('_reversal', '');

module.exports = {
  isBulkDepositReversalId,
  getBulkDepositReversalIdFromId,
  getBulkDepositIdFromReversalId,
};
