import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, buttonTypes, LoadingBarInfinite, Modal, useTranslation } from '@sb-itops/react';

import Styles from './UnpaidAnticipatedDisbursementAcknowledgementModal.module.scss';

export const UnpaidAnticipatedDisbursementAcknowledgementModal = ({
  isLoading,
  onAcknowledgeAndProceed,
  onModalClose,
  showModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`Unpaid Anticipated ${t('capitalizeAllWords', { val: 'expense' })}`}
      className={Styles.unpaidAdAcknowledgementModal}
      modalHeaderClassName={classNames(Styles.modalHeaderContainer, isLoading && Styles.hide)}
      modalBodyClassName={Styles.modalBodyContainer}
      modalFooterClassName={Styles.modalFooterContainer}
      body={
        isLoading ? (
          <div className={Styles.loadingBarContainer}>
            <LoadingBarInfinite loading={isLoading} />
          </div>
        ) : (
          <div className={Styles.modalBody}>
            <p>{`This invoice contains an anticipated ${t(
              'expense',
            )} that has not yet been paid to the supplier. Are you sure you want to pay it?`}</p>
          </div>
        )
      }
      footer={
        <div className={Styles.modalFooter}>
          <div className={classNames(Styles.buttonGroup, isLoading && Styles.hide)}>
            <Button type={buttonTypes.default} onClick={onAcknowledgeAndProceed} disabled={isLoading}>
              Add Payment
            </Button>
            <Button className={Styles.secondaryButton} type={buttonTypes.secondary} onClick={onModalClose}>
              Cancel
            </Button>
          </div>
        </div>
      }
      isVisible={showModal}
      onModalClose={onModalClose}
    />
  );
};

UnpaidAnticipatedDisbursementAcknowledgementModal.displayName = 'UnpaidAnticipatedDisbursementAcknowledgementModal';

UnpaidAnticipatedDisbursementAcknowledgementModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onAcknowledgeAndProceed: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

UnpaidAnticipatedDisbursementAcknowledgementModal.defaultProps = {};
