class RTFGroup {
  constructor(parent) {
    this.parent = parent;
    this.content = [];
    this.fonts = [];
    this.colors = [];
    this.style = {};
    this.calculatedStyle = {};
    this.ignorable = null;
  }

  get(name) {
    return this[name] != null ? this[name] : this.parent.get(name);
  }

  getFont(num) {
    return this.fonts[num] != null ? this.fonts[num] : this.parent.getFont(num);
  }

  getColor(num) {
    return this.colors[num] != null ? this.colors[num] : this.parent.getColor(num);
  }

  getStyle(name) {
    if (!name) return { ...this.parent.getStyle(), list: false, ...this.style };
    return this.style[name] != null ? this.style[name] : this.parent.getStyle(name);
  }

  getNodeStyle(node) {
    if (!node.parent) return { ...node.calculatedStyle, ...node.style };
    return { ...node.parent.getStyle(), list: false, ...node.style };
  }

  resetStyle() {
    this.style = {};
  }

  addContent(node) {
    node.calculatedStyle = { ...this.getStyle(), list: false, ...this.getNodeStyle(node) };
    node.calculatedStyle.font = this.getFont(node.calculatedStyle.fontMapping);
    node.calculatedStyle.foreground = this.getColor(node.calculatedStyle.foregroundMapping);
    node.calculatedStyle.background = this.getColor(node.calculatedStyle.backgroundMapping);
    this.content.push(node);
  }
}

module.exports = RTFGroup;
