import {
  getList,
  updateCache as updateRedux,
} from '@sb-billing/redux/xero';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { featureActive } from '@sb-itops/feature';

angular.module('@sb-billing/services').service('sbXeroService', function(
  sbLoggerService,
  sbGenericEndpointService,
  sbGenericCacheService,
  sbEndpointType,
  FileSaver,
) {
  'use strict';
  const that = this;
  const log = sbLoggerService.getLogger('sbXeroService');
  const ENDPOINT = 'billing/xero';

  sbGenericCacheService.setupCache({
    name: 'sbXeroService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: ENDPOINT },
      poll: 60,
      subscriptions: [
        'notifier.XeroIntegration.XeroSettingsUpdated',
        'notifier.XeroIntegration.XeroAccountsUpdated',
        'notifier.XeroIntegration.XeroAccessTokenUpdated',
        'notifier.XeroIntegration.XeroAccessTokenDeleted',
      ]
    },
    updateRedux
  });

  that.isAuthorized = isAuthorized;
  that.disableP = disableP;
  that.getSettings = getSettings;
  that.saveSettingsP = saveSettingsP;
  that.refreshAccounts = refreshAccounts;
  that.getBankAccounts = getBankAccounts;
  that.downloadInvoiceReport = downloadInvoiceReport;
  that.generateOutstandingInvoiceReport = generateOutstandingInvoiceReport;
  that.getPreSignedUrlOfOutstandingInvoiceReport = getPreSignedUrlOfOutstandingInvoiceReport;

  function get() {
    return _.get(getList(), '[0]', {}); // Will only ever be 1 record in this cache
  }

  function isAuthorized () {
    return _.get(get(), 'accessToken', false);
  }

  function disableP () {
    log.info('diconnecting Xero');
    return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/disconnect`);
  }

  function getSettings () {
    return get().settings || {};
  }

  function saveSettingsP (settings) {
    log.info('saving Xero settings', settings);
    return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/settings`, undefined, settings);
  }

  function generateOutstandingInvoiceReport ({ timestamp }) {
    const reportDate = moment(timestamp).format('YYYYMMDD');
    return sbGenericEndpointService.postPayloadP(`/billing/report-outstanding-invoices/generate`, undefined, { reportDate });
  }

  function getPreSignedUrlOfOutstandingInvoiceReport ({ accountId, timestamp }) {
    const reportDate = moment(timestamp).format('YYYYMMDD');
    return fetchGetP({ path: `/billing/report-outstanding-invoices/${accountId}/${reportDate}` });
  }

  async function downloadInvoiceReport ({ url, timestamp }) {
    let blob = await fetch(url).then(r => r.blob());
    FileSaver.saveAs(blob, `${moment(timestamp).format('YYYY-MM-DD')}-Outstanding-Invoices-Report.csv`);
  }

  function refreshAccounts() {
    log.info('refreshing Xero accounts');
    return sbGenericEndpointService.postPayloadP(`${ENDPOINT}/refresh`);
  }

  function getBankAccounts () {
    return get().accounts ? determineBankAccounts(get().accounts.accounts) : {};
  }

  function determineBankAccounts (bankAccounts) {
    const sortedBankAccounts = _.sortBy(bankAccounts, 'name');
    const revenueAccounts = sortedBankAccounts.filter(item => item.class && item.class.toUpperCase() === 'REVENUE').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Income',
    }));
    const expenseAccounts = sortedBankAccounts.filter(item => item.class && item.class.toUpperCase() === 'EXPENSE').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Expense',
    }));
    const assetAccounts = sortedBankAccounts.filter(item => item.class && item.class.toUpperCase() === 'ASSET').map((bankAccount) => ({
      ...bankAccount,
      typeLabel: 'Asset',
    }));

    return {
      operatingBankAccounts: filterBankAccounts(sortedBankAccounts, 'BANK'),
      legalFeesAccounts: revenueAccounts,
      clientExpensesAccounts: featureActive('BB-13352') ? [...assetAccounts, ...revenueAccounts] : revenueAccounts,
      clientSoftCostExpensesAccounts: revenueAccounts,
      officePaymentsAccounts: featureActive('BB-13352') ? [...assetAccounts, ...expenseAccounts] : expenseAccounts,
      officeSoftCostPaymentsAccounts: expenseAccounts,
      surchargeAccounts: revenueAccounts,
      badDebtAccounts: [...revenueAccounts, ...expenseAccounts],
      interestAccounts: revenueAccounts,
    };
  }

  function filterBankAccounts(bankAccounts, ...accountTypes) {
    const accountTypesUppercase = accountTypes.map(accountType => accountType && accountType.toUpperCase());

    return bankAccounts.filter((bankAccount) => accountTypesUppercase.some((accountType) => bankAccount.type && bankAccount.type.toUpperCase() === accountType));
  }
});
