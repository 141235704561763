angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-matter/expenses';

  $stateProvider

  .state('home.billing.view-matter.expenses', {
    url: '/expenses',
    templateUrl: BASE_DIRECTORY + '/view-matter-expenses.html',
    controller: 'sbViewMatterExpensesController',
    controllerAs: 'matterExpenses',
    data: {
      authorized: true,
      navHighlights: ['billing', 'matters', 'view-matter'],
      tab: { type: 'matter' }
    }
  });

});
