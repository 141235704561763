import { updateState } from '../../../actions/generic/update-state';
import { domain } from '../../../domain';
import name from '../name';

const defaultState = {
  selectedInvoiceTemplate: null,
  selectedInvoiceTemplateConfiguration: null,
};

const { updateStateAction, updateStateReducer, ACTION_TYPE } = updateState({
  actionName: `${domain}/${name}/CLEAR_SELECTED_INVOICE_TEMPLATE_CONFIG`,
});

const CLEAR_SELECTED_INVOICE_TEMPLATE_CONFIG = ACTION_TYPE;

export const clearSelectedInvoiceTemplateConfiguration = (state, { uniqueId }) =>
  updateStateAction({ ...state, [uniqueId]: defaultState });

export const clearSelectedInvoiceTemplateConfigurationReducerEntry = {
  [CLEAR_SELECTED_INVOICE_TEMPLATE_CONFIG]: updateStateReducer,
};
