'use strict';

const { StaffSchema } = require('./staff-schema');
const { StaffFeeConfigSchema } = require('./staff-fee-config-schema');
const { StaffHourlyRateSchema } = require('./staff-hourly-rate-schema');
const { FirmSchema } = require('./firm-schema');
const { CustomerBillingConfigurationSchema } = require('./customer-billing-configuration-schema');

module.exports = {
  StaffSchema,
  StaffFeeConfigSchema,
  StaffHourlyRateSchema,
  FirmSchema,
  CustomerBillingConfigurationSchema,
};
