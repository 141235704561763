'use strict';

const { transactionType } = require('../entities/constants');

const { Deposit, DepositReversal } = transactionType;

/**
 * @param {object} param
 * @param {{ type: string, reversed: boolean|undefined }} param.transaction
 * @param {string} [param.bankAccountType]
 * @returns {boolean}
 */
const isTransactionBulkDeposit = ({ transaction, bankAccountType }) => {
  if (bankAccountType && bankAccountType !== transaction.bankAccountType) {
    return false;
  }
  return !!([Deposit, DepositReversal].includes(transaction.type) && transaction.paymentId);
};

module.exports = {
  isTransactionBulkDeposit,
};
