import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onRestoreMemo = ({ commandMessage }) => {
  const optimisticMemo = {
    __typename: 'Memo',
    ...commandMessage,
    isDeleted: false,
  };

  const apolloClient = getApolloClient();

  const memoIdentifier = apolloClient.cache.identify(optimisticMemo);

  apolloClient.writeFragment({
    id: memoIdentifier,
    fragment: gql`
      fragment OpdateRestoreMemo on Memo {
        ${Object.keys(optimisticMemo).join('\n')}
      }
    `,
    data: {
      ...optimisticMemo,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_UPDATED,
    typeName: 'Memo',
    optimisticEntity: optimisticMemo,
  };
};
