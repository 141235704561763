import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { store } from '@sb-itops/redux';
import { PARENT_PAGE_NAME } from '../routes';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions/protected-funds';
  $stateProvider
    .state('home.billing.accounts.transactions.protected-funds', {
      url: '/protected-funds/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/protected-funds.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'PROTECTED_FUNDS',
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.accounts.transactions.protected-funds', { ...$stateParams }], parentPageName: PARENT_PAGE_NAME }));
      },    
    });
});
 