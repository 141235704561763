import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withOnLoad } from '@sb-itops/react/hoc';
import uuid from '@sb-itops/uuid';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as formsFeature from '@sb-itops/redux/forms2';
import * as messageDisplay from '@sb-itops/message-display';
import { getRegion } from '@sb-itops/region';
import { getCurrentStaff, getFirmLocationId } from '@sb-firm-management/redux/firm-management';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import composeHooks from '@sb-itops/react-hooks-compose';
import { createMatter } from '@sb-matter-management/redux/matters';
import { getAccountId } from 'web/services/user-session-management';
import { getTrustAccount } from '@sb-billing/redux/bank-account';
import { saveBillingConfiguration } from '@sb-billing/redux/billing-configuration/save-billing-configuration';
import { getClientRoleOptions } from '@sb-matter-listing/redux/matter-type-configurations';
import { useState, useRef } from 'react';
import { sendMetric } from 'web/services/metrics';
import { AddLeadModal } from './AddLeadModal';
import { billingTypes } from '../add-edit-matter/matter-fee-and-rate-settings/billing-types';

const REGION = getRegion();

export const ADD_LEAD_MODAL_ID = 'add-lead-modal';

// validate form
const validateFn = (formFields) => {
  const errors = {};

  if (!formFields.leadContactId) {
    errors.leadContactId = true;
  }

  if (!formFields.matterTypeId) {
    errors.matterTypeId = true;
  }

  return errors;
};

const hooks = ({ onClose }) => ({
  useFormData: () => {
    const {
      selectors: { getFormState, getFieldValues },
      actions: { initialiseForm, updateFieldValues, clearForm, resetForm },
      operations: { submitFormWithValidationP, validateForm },
    } = useScopedFeature(formsFeature, ADD_LEAD_MODAL_ID);

    const dispatch = useDispatch();

    const startEditingLeadTime = useRef();
    if (!startEditingLeadTime.current) {
      startEditingLeadTime.current = window.performance.now();
    }

    // get form state
    const formState = useSelector(getFormState);

    const preInitFieldValues = useSelector(getFieldValues);

    // get form fields
    const fieldValues = formState.formInitialised ? preInitFieldValues : {};

    const attorneyResponsibleId = fieldValues && fieldValues.attorneyResponsibleId;
    const referralType = fieldValues && fieldValues.referralType;
    const billingType = fieldValues && fieldValues.billingType;
    const referrerId = fieldValues && fieldValues.referrerId;
    const leadContactId = fieldValues && fieldValues.leadContactId;
    const matterTypeId = fieldValues && fieldValues.matterTypeId;
    const locationId = fieldValues && fieldValues.locationId;
    const categoryId = fieldValues && fieldValues.categoryId;
    const amount = fieldValues && fieldValues.amount;
    const contingencyAmount = fieldValues && fieldValues.contingencyAmount;

    const disableSubmit = (!formState.formValid && formState.submitFailed) || formState.formSubmitting;

    const [showAddLeadContactForm, setShowAddLeadContactForm] = useState(false);
    const [showAddReferrerContactForm, setShowAddReferrerContactForm] = useState(false);

    return {
      scope: ADD_LEAD_MODAL_ID,
      onLoad: () => {
        dispatch(
          initialiseForm({
            fieldValues: {
              attorneyResponsibleId: undefined,
              referralType: undefined,
              billingType: undefined,
              referrerId: undefined,
              leadContactId: undefined,
              matterTypeId: undefined,
              locationId: getFirmLocationId(),
              categoryId: undefined,
              contingencyAmount: undefined,
              amount: undefined,
            },
          }),
        );
        return () => {
          dispatch(clearForm());
        };
      },
      onClose: () => {
        onClose();
        dispatch(resetForm());
      },
      onSubmit: async () => {
        dispatch(validateForm({ validateFn }));
        await dispatch(
          submitFormWithValidationP({
            submitFnP: async (formData) => {
              try {
                const accountId = getAccountId();
                // Create the lead next and attach contact
                const clientRole = getClientRoleOptions(formData.originalMatterTypeId)[0]?.value;
                const leadToCreate = {
                  accountId,
                  matterTypeId: formData.matterTypeId,
                  clientIds: [leadContactId],
                  clientRole,
                  attorneyResponsibleId: formData.attorneyResponsibleId,
                  isLead: true,
                  referralType: formData.referralType,
                  referrerId: formData.referrerId,
                };
                const newMatterId = await createMatter(leadToCreate);
                const trustAccountId = getTrustAccount()?.id;

                // Lastly create a billing config for the lead
                const billingConfigurationWorkItemToSave = {
                  matterId: newMatterId,
                  accountId,
                  workItemId: uuid(),
                  billingType: formData.billingType,
                  amount:
                    formData.billingType && formData.billingType !== billingTypes.NOT_BILLABLE && formData.amount
                      ? formData.amount / 100
                      : 0, // convert back to dollars when saving as currency input returns value in cents
                  contingencyAmount:
                    (formData.billingType === billingTypes.CONTINGENCY_P && formData.contingencyAmount) || 0, // by default save as 0 for Contingency % similar to system default
                  defaultTrustAccountId: null,
                };
                await saveBillingConfiguration({
                  matterId: newMatterId,
                  accountId,
                  trustAccountId,
                  billingConfigurationWorkItemToSave,
                });

                onClose();
                dispatch(resetForm());
                sendMetric('AddLead');
                const endEditMatterTime = window.performance.now();

                sendMetric('TimeEditingLead', {
                  EditingLeadDuration: Math.round(endEditMatterTime - startEditingLeadTime.current),
                });
              } catch (err) {
                messageDisplay.error('Error saving lead');
                throw err;
              }
            },
          }),
        );
      },
      disableSubmit,
      formSubmitting: formState.formSubmitting,
      formProps: {
        assignableStaff: getCurrentStaff(),
        region: REGION,
        errors: formState.formValidation,
        submitFailed: formState.submitFailed,
        attorneyResponsibleId,
        referralType,
        billingType,
        referrerId,
        leadContactId,
        matterTypeId,
        locationId,
        categoryId,
        amount,
        contingencyAmount,
        contactOptions: getContactTypeAheadSummaries(),
        showAddLeadContactForm,
        showAddReferrerContactForm,
        setShowAddReferrerContactForm,
        setShowAddLeadContactForm,
        onFieldValueChanged: (field, value) => {
          dispatch(updateFieldValues({ fieldValues: { [field]: value } }));
          dispatch(validateForm({ validateFn }));
        },
      },
    };
  },
});

export const AddLeadModalContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(AddLeadModal)));

AddLeadModalContainer.displayName = 'AddLeadModalContainer';

AddLeadModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

AddLeadModalContainer.defaultProps = {};
