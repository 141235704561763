'use strict';

const { entryType } = require('../../shared/entities');

/** @typedef {import('./types').UtbmsTaskCode} UtbmsTaskCode */

/**
 * Filter UTBMS task codes for expenses (disbursements)
 *
 * @param {Object} param
 * @param {UtbmsTaskCode[]} param.utbmsTaskCodes UTBMS task codes including custom task codes
 * @param {String[]} param.utbmsCodeSetsUsedByFirm Firm UTBMS selectedCodeSets array
 * @returns {Object} Applicable UTBMS task codes
 */
const filterUtbmsTaskCodesExpenses = ({ utbmsTaskCodes, utbmsCodeSetsUsedByFirm } = {}) => {
  if (utbmsCodeSetsUsedByFirm === undefined) {
    return [];
  }

  const filteredTaskCodes = utbmsTaskCodes.filter(
    (taskCode) =>
      taskCode.type === 'Expense' || // standard utbms task codes
      taskCode.entryType === entryType.EXPENSE, // custom utbms task codes
  );

  return filteredTaskCodes;
};

module.exports = {
  filterUtbmsTaskCodesExpenses,
};
