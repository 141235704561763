import { cacheFactory, syncTypes, store } from '@sb-itops/redux';
import {
  bankAccountTypeDisplayByValue,
  bankAccountTypeByValue,
  bankAccountState,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import {
  getBankAccountsByLocation,
  isBankAccountClosed,
  shouldShowOriginalTrust,
} from '@sb-billing/business-logic/bank-account/services';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { t } from '@sb-itops/localisation-web';
import { saveNumberingSettingsP } from './save-numbering-settings';
// eslint-disable-next-line import/no-cycle
import { saveBankAccount } from './save-bank-account';
import { createBankAccount } from './create-bank-account';
import domain from '../domain';

const getAccountId = () => authSelectors.getAccountId(store.getState());

export const ACCOUNT_TYPE = Object.freeze({
  trust: 'trust',
  operating: 'operating',
  credit: 'credit',
  controlled_money: 'controlledmoney',
});

const api = cacheFactory({
  domain,
  name: 'bank-account',
  keyPath: 'id',
  ngCacheName: 'sbBankAccountService',
  syncType: syncTypes.ALL,
  immutable: false,
  changesetProcessor: ({ entities }) => {
    const accounts = entities.reduce((all, account) => {
      if (account) {
        all.push({
          ...account,
          ...{
            accountType: bankAccountTypeByValue[account.accountType],
            accountTypeDisplay: bankAccountTypeDisplayByValue[account.accountType],
          },
        });
      }
      return all;
    }, []);

    return accounts;
  },
});

/**
 * @param {'TRUST'|'OPERATING'|'CREDIT'|'CONTROLLEDMONEY'} type
 * @returns {Array<BankAccount>}
 */
export const getByType = (type) =>
  getList().filter((account) => !account.isDeleted && (!type || account.accountType === type.toUpperCase()));

export const getActive = (types) => {
  const activeBankAccounts = getList().filter(
    (bankAccount) => !bankAccount.isInactive && (!types || types.includes(bankAccount.accountType)),
  );
  return activeBankAccounts;
};

// We expect this to return default trust ("accountId/Trust") but with
// more trust accounts the first one may be any and not the default one
//
// This was changed so any existing code gets "accountId/Trust" as expected.
// With multitrust, we should not use this function anymore
// but get trust account based on business rules (default per matter, per state etc.)
export const getTrustAccount = () => getById(`${getAccountId()}/Trust`);

export const getTrustAccounts = () => getByType('TRUST');
export const getActiveTrustAccounts = () => getByType('TRUST').filter((trust) => !isTrustAccountClosed(trust));
export const getTrustAccountsByLocation = ({ locationId, includeClosed = false, includeAllStates = true }) =>
  getBankAccountsByLocation(
    includeClosed ? getTrustAccounts() : getActiveTrustAccounts(),
    locationId,
    includeAllStates,
  );

export const isTrustAccountClosed = (trust) => isBankAccountClosed({ bankAccount: trust });

export const shouldShowOriginalTrustOnly = () => shouldShowOriginalTrust(getActiveTrustAccounts());

export const getOperatingAccount = () => getByType('OPERATING')[0];

export const getDefaultCreditAccount = () => getByType('CREDIT')[0];

export const getControlledMoneyAccounts = () => getByType('CONTROLLEDMONEY');

export const getActiveControlledMoneyAccounts = () =>
  getList().filter((account) => account.state === bankAccountState.OPEN && account.accountType === 'CONTROLLEDMONEY');

export const getActiveControlledMoneyAccountsByMatter = (matterId) =>
  getList().filter(
    (account) =>
      account.state === bankAccountState.OPEN &&
      account.accountType === 'CONTROLLEDMONEY' &&
      matterId === account.associatedMatterId,
  );

export const getDefaultTransferNumberingSettings = () => ({
  initialNumber: 1,
  padding: 7,
  prefix: 'T',
});

export const getTransferNumberingSettings = ({ bankAccountId, defaultIfEmpty }) => {
  const bankAccount = getById(bankAccountId) || {};
  return bankAccount.transferNumberingSettings || (defaultIfEmpty && getDefaultTransferNumberingSettings());
};

export const getDefaultElectronicPaymentNumberingSettings = () => ({
  initialNumber: 1,
  padding: 7,
  prefix: 'E',
});

export const getElectronicPaymentNumberingSettings = ({ bankAccountId, defaultIfEmpty }) => {
  const bankAccount = getById(bankAccountId) || {};
  return (
    bankAccount.electronicPaymentNumberingSettings || (defaultIfEmpty && getDefaultElectronicPaymentNumberingSettings())
  );
};

export const getDefaultTrustToOfficeNumberingSettings = () => ({
  initialNumber: 1,
  padding: 7,
  prefix: t('trustToOfficeNumberingDefaultPrefix'),
});

export const getTrustToOfficeNumberingSettings = ({ bankAccountId, defaultIfEmpty }) => {
  const bankAccount = getById(bankAccountId) || {};
  return bankAccount.trustToOfficeNumberingSettings || (defaultIfEmpty && getDefaultTrustToOfficeNumberingSettings());
};

export const getNumberingSettings = ({ bankAccountId, defaultIfEmpty }) => ({
  transferNumberingSettings: getTransferNumberingSettings({ bankAccountId, defaultIfEmpty }),
  electronicPaymentNumberingSettings: getElectronicPaymentNumberingSettings({ bankAccountId, defaultIfEmpty }),
  trustToOfficeNumberingSettings: getTrustToOfficeNumberingSettings({ bankAccountId, defaultIfEmpty }),
});

export const getNumberingPrefixes = ({ bankAccountId }) => ({
  transferNumberingPrefix: getTransferNumberingSettings({ bankAccountId, defaultIfEmpty: true }).prefix,
  electronicPaymentNumberingPrefix: getElectronicPaymentNumberingSettings({ bankAccountId, defaultIfEmpty: true })
    .prefix,
  trustToOfficeTransferNumberingPrefix: getDefaultTrustToOfficeNumberingSettings().prefix,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;

// This selector is created for the bank recon cancel page so support can reverse MTA bank recs without having to fiddle with launch darkly
// It really shouldn't be used for anything else
export const getActiveTrustAccountsWithoutCheckingMTAFeature = () =>
  getList().filter(
    (bankAccount) =>
      isTrustAccount(bankAccount.accountType) &&
      !(bankAccount.state === bankAccountState.CLOSED || bankAccount.isInactive),
  );

export { saveNumberingSettingsP, saveBankAccount, createBankAccount };

export const isTrustAccount = (accountType) => accountType.toLowerCase() === ACCOUNT_TYPE.trust;

export const isOperatingAccount = (accountType) => accountType.toLowerCase() === ACCOUNT_TYPE.operating;

export const isCreditAccount = (accountType) => accountType.toLowerCase() === ACCOUNT_TYPE.credit;

export const isControlledMoniesAccount = (accountType) => accountType.toLowerCase() === ACCOUNT_TYPE.controlled_money;
