import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

/**
 * This query is specific for filtering Matters by MatterType,
 * it does not contain pagination or sorting for that reason.
 */
const query = gql`
  query MatterTypesFilter($filter: MatterTypeFilter) {
    matterTypeList(filter: $filter) {
      results {
        matterTypeId
        matterTypeIdWithoutLocation
        name
        categories
      }
    }
  }
`;

const notificationIds = ['MatterManagementWebQuery.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterTypesFilter = {
  query,
  notificationIds,
};
