import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { featureActive } from '@sb-itops/feature';
import { SlidingToggle } from '@sb-itops/react';

import Styles from './FeeSourceItemsEntries.module.scss';

export const FeeSourceItemEntry = (props) => {
  const {
    descriptionFieldMaxLength,
    index,
    isDisabled,
    sourceItem,
    sourceItemMappedDurationBilled,
    sourceItemMappedDurationWorked,
    // Callbacks
    onBillableChange,
    onChangeSourceItemDescription,
    onRemoveSourceItem,
    onSourceItemDurationBilledBlur,
    onSourceItemDurationBilledValueChange,
    onSourceItemDurationWorkedBlur,
    onSourceItemDurationWorkedValueChange,
  } = props;

  const { billable, description, id } = sourceItem;
  const isTimeWorkedEnabled = featureActive('BB-13563');
  const isDurationBilledGreaterThanZero = sourceItemMappedDurationBilled > 0;
  const isDurationWorkedGreaterThanZero = sourceItemMappedDurationWorked > 0;

  return (
    <div className={classNames(Styles.feeSourceItem, isTimeWorkedEnabled && Styles.timeWorkedEnabled)}>
      <div className={Styles.descriptionContainer}>
        <textarea
          className={classNames(Styles.description, isDisabled && Styles.disabled)}
          value={description || ''}
          onChange={(e) => onChangeSourceItemDescription({ id, description: e.target.value })}
          rows={4}
          disabled={isDisabled}
          maxLength={descriptionFieldMaxLength}
        />
        {!isTimeWorkedEnabled && !isDisabled && (
          <i
            role="button"
            aria-label={`remove activity ${index + 1}`}
            className="fa fa-trash icon"
            onClick={onRemoveSourceItem}
            tabIndex={0}
          />
        )}
      </div>
      {/* time worked field */}
      {isTimeWorkedEnabled && (
        <div className={classNames(Styles.durationWorkedContainer, isDisabled && Styles.disabled)}>
          <input
            type="number"
            className={classNames(Styles.durationWorked, !isDurationWorkedGreaterThanZero && Styles.hasError)}
            value={sourceItemMappedDurationWorked}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              onSourceItemDurationWorkedValueChange({ sourceItemDurationWorked: e.target.valueAsNumber || 0 })
            }
            onBlur={onSourceItemDurationWorkedBlur}
            min={0}
            disabled={isDisabled}
          />
        </div>
      )}
      <div className={classNames(Styles.durationContainer, isDisabled && Styles.disabled)}>
        <input
          type="number"
          className={classNames(Styles.duration, !isDurationBilledGreaterThanZero && Styles.hasError)}
          value={sourceItemMappedDurationBilled}
          onFocus={(e) => e.target.select()}
          onChange={(e) =>
            onSourceItemDurationBilledValueChange({ sourceItemDurationBilled: e.target.valueAsNumber || 0 })
          }
          onBlur={onSourceItemDurationBilledBlur}
          min={0}
          disabled={isDisabled}
        />
      </div>
      <div className={classNames(Styles.billableContainer, isDisabled && Styles.disabled)}>
        <SlidingToggle
          id={id}
          name={`auto-time-fee-is-billable-${id}`}
          scope="auto-time-fee-source-item"
          onChange={(name, newValue) => onBillableChange({ id, isBillable: newValue })}
          selected={billable}
          disabled={isDisabled}
        />
      </div>
      {isTimeWorkedEnabled && (
        <div className={classNames(Styles.trashBinContainer, isDisabled && Styles.disabled)}>
          {!isDisabled && (
            <i
              role="button"
              aria-label={`remove activity ${index + 1}`}
              className="fa fa-trash icon"
              onClick={onRemoveSourceItem}
              tabIndex={0}
            />
          )}
        </div>
      )}
    </div>
  );
};

FeeSourceItemEntry.displayName = 'FeeSourceItemEntry';

const SourceItemType = PropTypes.shape({
  activityCount: PropTypes.number,
  activityRelatedId: PropTypes.string,
  activityType: PropTypes.number,
  billable: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  durationBilled: PropTypes.number.isRequired,
  durationWorked: PropTypes.number.isRequired,
  id: PropTypes.string, // Added (not part of entity)
  originalBillable: PropTypes.bool,
  sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

FeeSourceItemEntry.propTypes = {
  descriptionFieldMaxLength: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  sourceItem: SourceItemType.isRequired,
  // Duration billed in hours/units
  sourceItemMappedDurationBilled: PropTypes.string.isRequired,
  // Duration Worked in hours/units
  sourceItemMappedDurationWorked: PropTypes.string.isRequired,
  // Callbacks
  onBillableChange: PropTypes.func.isRequired,
  onChangeSourceItemDescription: PropTypes.func.isRequired,
  onRemoveSourceItem: PropTypes.func.isRequired,
  onSourceItemDurationBilledBlur: PropTypes.func.isRequired,
  onSourceItemDurationBilledValueChange: PropTypes.func.isRequired,
  onSourceItemDurationWorkedBlur: PropTypes.func.isRequired,
  onSourceItemDurationWorkedValueChange: PropTypes.func.isRequired,
};

FeeSourceItemEntry.defaultProps = {};
