import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import Styles from './FeeWisePayoutTable.module.scss';
import { FeeWisePayoutDetailsModal } from './FeeWisePayoutDetailsModal';
import { bankAccountName } from './bank-account-name';

const { balanceCellLocalisedRenderer, timestampLocalisedRenderer } = utils;

const transactionCountHeaderRenderer = ({ label }) => (
  <div className="header-cell-inner non-sortable-column">
    <span className="header-label" title="Number of transactions included in this payout">
      {label}
    </span>
  </div>
);

export const FeeWisePayoutTable = ({ payouts, dataLoading, payoutCount, onSort, sortBy, sortDirection }) => {
  const { t } = useTranslation();
  const [payoutDetail, setPayoutDetail] = useState(undefined);

  return (
    <>
      <div className={Styles.gridLabelHeader}>Payouts {dataLoading ? null : <span>({payoutCount})</span>}</div>
      {!!payoutDetail && (
        <FeeWisePayoutDetailsModal isVisible onClose={() => setPayoutDetail(undefined)} payoutDetail={payoutDetail} />
      )}
      <Table
        className={Styles.fwPayoutTable}
        onRowClick={({ rowData }) => setPayoutDetail(rowData)}
        dataLoading={dataLoading}
        list={payouts}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column dataKey="date" label="Date" cellRenderer={timestampLocalisedRenderer} flexGrow={1} />
        <Column dataKey="reference" label="Reference" flexGrow={1} />
        <Column
          dataKey="transactionCount"
          label="Inc. Transactions"
          flexGrow={1}
          disableSort
          headerRenderer={transactionCountHeaderRenderer}
        />
        <Column
          dataKey="bankAccount"
          label="Account"
          flexGrow={1}
          disableSort
          cellRenderer={({ rowData, cellData }) =>
            bankAccountName({ bankAccount: cellData, feeWiseBankAccount: rowData.account, t })
          }
        />
        <Column dataKey="status" label="Status" flexGrow={1} />
        <Column
          className="right-align"
          dataKey="amount"
          label="Amount"
          flexGrow={1}
          cellRenderer={balanceCellLocalisedRenderer}
        />
      </Table>
    </>
  );
};

FeeWisePayoutTable.propTypes = {
  dataLoading: PropTypes.bool.isRequired,
  payoutCount: PropTypes.number.isRequired,
  payouts: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      reference: PropTypes.string,
      transactionCount: PropTypes.string,
      status: PropTypes.string,
      totalAmount: PropTypes.number,
      account: PropTypes.shape({
        accountName: PropTypes.string,
        accountType: PropTypes.string,
        routingNumber: PropTypes.string,
        accountNumber: PropTypes.string,
        countryCode: PropTypes.string,
      }),
    }),
  ).isRequired,
  // sort
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
};

FeeWisePayoutTable.defaultProps = {
  sortBy: 'date',
  sortDirection: 'desc',
  onSort: undefined,
};
