import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').controller('sbSettingsActivitiesListController', function ($state, sbBillingActivityService, sbLocalisationService) {
  'use strict';
  var that = this;
  that.t = sbLocalisationService.t;

  that.onSelect = function (activityId) {
    $state.go('settings.activities.edit', {activityId: activityId});
  };

  that.showUtbmsTab = function () {
    return hasFacet(facets.utbms);
  }

  function updateActivities() {
    that.count = _.size(sbBillingActivityService.getAllActivites());
  }

  updateActivities();
});
