'use strict';

const { operationTypes } = require('./operation-type');

/**
 * Checks if the correspondence history is related to a specific matter.
 * @param {Object} params
 * @param {Object} params.correspondenceHistory - The correspondence history object
 * @param {string[]} params.invoiceIdsOfTheMatter - The invoice IDs of the matter
 * @param {string} params.matterId - The ID of the matter.
 * @returns {boolean} - True if the correspondence is related to the matter, false otherwise.
 */

const isCorrespondenceHistoryRelatedToMatter = ({ correspondenceHistory, invoiceIdsOfTheMatter, matterId }) => {
  switch (correspondenceHistory.operationType) {
    // Invoice reminder histories has a matterId field
    case operationTypes.INVOICE_REMINDER:
      return correspondenceHistory.matterId === matterId;

    // Deposit request history stores matterId in relatedIds
    case operationTypes.CREDIT_CARD_DEPOSIT_REQUEST:
      return correspondenceHistory.relatedIds && correspondenceHistory.relatedIds[0] === matterId;

    // Invoices histories stores invoicesIds in relatedIds
    case operationTypes.INVOICE:
      return (
        correspondenceHistory.relatedIds &&
        correspondenceHistory.relatedIds.some((invoiceId) => invoiceIdsOfTheMatter.includes(invoiceId))
      );

    default:
      return false;
  }
};

module.exports = {
  isCorrespondenceHistoryRelatedToMatter,
};
