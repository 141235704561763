import PropTypes from 'prop-types';
import React from 'react';
import Styles from './PaymentProviderIntegration.module.scss';
import { PaymentProviderActivationForm } from '../payment-provider-activation-form';
import { PaymentProviderSettingsForm } from '../payment-provider-settings-form';
import { PaymentProviderSettings } from '../payment-provider-settings';

export const PaymentProviderIntegration = ({ activeProvider, showNewUIProviderSettings }) => {
  if (showNewUIProviderSettings) {
    // new style payment provider settings
    return <PaymentProviderSettings />;
  }

  return (
    <div className={Styles.paymentProviderIntegration}>
      <h3>Payment Provider Settings</h3>
      {!activeProvider && <PaymentProviderActivationForm />}
      {activeProvider && <PaymentProviderSettingsForm />}
    </div>
  );
};

PaymentProviderIntegration.displayName = 'PaymentProviderIntegration';

PaymentProviderIntegration.propTypes = {
  activeProvider: PropTypes.string,
  showNewUIProviderSettings: PropTypes.bool,
};

PaymentProviderIntegration.defaultProps = {
  activeProvider: undefined,
  showNewUIProviderSettings: false,
};

export default PaymentProviderIntegration;
