'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  var BASE_DIRECTORY = 'ng-routes/settings';

  // Settings (provides ui-view)
  $stateProvider

  .state('settings', {
    url: '/settings',
    templateUrl: BASE_DIRECTORY + '/settings.html',
    controller: 'FirmSettingController',
    controllerAs: 'firmSettings',
    data: {
      authorized: true
    },
  });
});
