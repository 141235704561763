/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { sentViaTypes, operationTypes } from '@sb-billing/business-logic/correspondence-history';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ExpandCollapseToggler } from '@sb-itops/react/expand-collapse-toggler';
import { getRegion, regionType } from '@sb-itops/environment-config';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { Translation, DropdownMenuButton } from '@sb-itops/react';
import { ReminderCorrespondenceStatusIndicator } from 'web/react-redux/components/reminder-correspondence-status-indicator';

const { balanceCellLocalisedRenderer, checkboxCellWrapperRenderer, dateLocalisedRenderer } = utils;
const REGION = getRegion();

class InvoiceReminderTable extends React.Component {
  constructor(props) {
    super(props);

    this.expanderCellRenderer = this.expanderCellRenderer.bind(this);
    this.expandAllRowsRenderer = this.expandAllRowsRenderer.bind(this);
    this.toggleAllSelectionRenderer = this.toggleAllSelectionRenderer.bind(this);
    this.checkboxDebtorCellRenderer = this.checkboxDebtorCellRenderer.bind(this);
    this.checkboxInvoiceCellRenderer = this.checkboxInvoiceCellRenderer.bind(this);
    this.debtorOrInvoiceNumberCellRenderer = this.debtorOrInvoiceNumberCellRenderer.bind(this);
    this.matterCellRenderer = this.matterCellRenderer.bind(this);
    this.overdueInvoicesCellRenderer = this.overdueInvoicesCellRenderer.bind(this);
  }

  renderForUsRegion(props) {
    return [
      <Column
        key="rowExpander"
        dataKey="rowExpander"
        label=""
        width={25}
        headerRenderer={this.expandAllRowsRenderer}
        cellRenderer={this.expanderCellRenderer}
        disableSort
        className="row-expander"
      />,
      <Column
        key="rowSelector"
        dataKey="rowSelector"
        label=""
        width={34}
        headerRenderer={checkboxCellWrapperRenderer(this.toggleAllSelectionRenderer)}
        cellRenderer={checkboxCellWrapperRenderer(this.checkboxDebtorCellRenderer)}
        disableSort
      />,
      <Column
        key="debtor"
        dataKey="debtor"
        label="Debtor"
        flexGrow={4}
        cellRenderer={this.debtorOrInvoiceNumberCellRenderer}
        className="debtor-invoice-column"
      />,
      <Column key="matter" dataKey="matter" label="Matter" flexGrow={6} cellRenderer={this.matterCellRenderer} />,
      <Column
        key="overdueInvoices"
        dataKey="overdueInvoices"
        label="# Invoices"
        width={80}
        cellRenderer={this.overdueInvoicesCellRenderer}
      />,
      <Column
        key="totalUnpaidExcInterest"
        className="right-align"
        dataKey="totalUnpaidExcInterest"
        label="Overdue Amt"
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="totalInterest"
        className="right-align interest"
        dataKey="totalInterest"
        label="Interest"
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="totalUnpaid"
        className="right-align"
        dataKey="totalUnpaid"
        label="Overdue (Inc. Interest)"
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="lastReminderDate"
        dataKey="lastReminderDate"
        label="Last Reminder"
        flexGrow={2}
        cellRenderer={timestampRenderer}
      />,
      ...(featureActive('BB-11448')
        ? [
            <Column
              key="Sent"
              dataKey="aggregateSentStatus"
              label="Sent"
              labelTooltip="Email status"
              width={65}
              cellRenderer={emailStatusCellRenderer({ props })}
            />,
          ]
        : []),
      <Column
        key="contextMenu"
        dataKey="contextMenu"
        label=""
        width={40}
        cellRenderer={contextMenuCellRenderer({ props })}
        disableSort
      />,
    ];
  }

  renderForAuRegion(props, t) {
    return [
      <Column
        key="rowExpander"
        dataKey="rowExpander"
        label=""
        width={25}
        headerRenderer={this.expandAllRowsRenderer}
        cellRenderer={this.expanderCellRenderer}
        disableSort
        className="row-expander"
      />,
      <Column
        key="rowSelector"
        dataKey="rowSelector"
        label=""
        width={34}
        headerRenderer={checkboxCellWrapperRenderer(this.toggleAllSelectionRenderer)}
        cellRenderer={checkboxCellWrapperRenderer(this.checkboxDebtorCellRenderer)}
        disableSort
      />,
      <Column
        key="debtor"
        dataKey="debtor"
        label="Debtor"
        flexGrow={4}
        cellRenderer={this.debtorOrInvoiceNumberCellRenderer}
        className="debtor-invoice-column"
      />,
      <Column key="matter" dataKey="matter" label="Matter" flexGrow={6} cellRenderer={this.matterCellRenderer} />,
      <Column
        key="overdueInvoices"
        dataKey="overdueInvoices"
        label="Overdue Invoices"
        flexGrow={1}
        cellRenderer={this.overdueInvoicesCellRenderer}
      />,
      <Column
        key="totalUnpaid"
        dataKey="totalUnpaid"
        label={`Overdue Amt (Inc. ${t('tax')})`}
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="lastReminderDate"
        dataKey="lastReminderDate"
        label="Last Reminder"
        flexGrow={2}
        cellRenderer={timestampRenderer}
      />,
      ...(featureActive('BB-11448')
        ? [
            <Column
              key="Sent"
              dataKey="aggregateSentStatus"
              label="Sent"
              labelTooltip="Email status"
              width={65}
              cellRenderer={emailStatusCellRenderer({ props })}
            />,
          ]
        : []),
      <Column
        key="contextMenu"
        dataKey="contextMenu"
        label=""
        width={40}
        cellRenderer={contextMenuCellRenderer({ props })}
        disableSort
      />,
    ];
  }

  renderForAuRegionWithInterest(props) {
    return [
      <Column
        key="rowExpander"
        dataKey="rowExpander"
        label=""
        width={25}
        headerRenderer={this.expandAllRowsRenderer}
        cellRenderer={this.expanderCellRenderer}
        disableSort
        className="row-expander"
      />,
      <Column
        key="rowSelector"
        dataKey="rowSelector"
        label=""
        width={34}
        headerRenderer={checkboxCellWrapperRenderer(this.toggleAllSelectionRenderer)}
        cellRenderer={checkboxCellWrapperRenderer(this.checkboxDebtorCellRenderer)}
        disableSort
      />,
      <Column
        key="debtor"
        dataKey="debtor"
        label="Debtor"
        flexGrow={4}
        cellRenderer={this.debtorOrInvoiceNumberCellRenderer}
        className="debtor-invoice-column"
      />,
      <Column key="matter" dataKey="matter" label="Matter" flexGrow={6} cellRenderer={this.matterCellRenderer} />,
      <Column
        key="overdueInvoices"
        dataKey="overdueInvoices"
        label="Overdue Invoices"
        flexGrow={1}
        cellRenderer={this.overdueInvoicesCellRenderer}
      />,
      <Column
        key="totalUnpaidExcInterest"
        className="right-align"
        dataKey="totalUnpaidExcInterest"
        label="Overdue Amt"
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="totalInterest"
        className="right-align interest"
        dataKey="totalInterest"
        label="Interest"
        flexGrow={2}
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="totalUnpaid"
        dataKey="totalUnpaid"
        label="Overdue (Inc. Interest)"
        flexGrow={2}
        className="right-align"
        cellRenderer={balanceCellLocalisedRenderer}
      />,
      <Column
        key="lastReminderDate"
        dataKey="lastReminderDate"
        label="Last Reminder"
        flexGrow={2}
        cellRenderer={timestampRenderer}
      />,
      ...(featureActive('BB-11448')
        ? [
            <Column
              key="Sent"
              dataKey="aggregateSentStatus"
              label="Sent"
              labelTooltip="Email status"
              width={65}
              cellRenderer={emailStatusCellRenderer({ props })}
            />,
          ]
        : []),
      <Column
        key="contextMenu"
        dataKey="contextMenu"
        label=""
        width={40}
        cellRenderer={contextMenuCellRenderer({ props })}
        disableSort
      />,
    ];
  }

  expandAllRowsRenderer() {
    const { allRemindersExpanded, onExpandAllToggled } = this.props;
    return (
      <ExpandCollapseToggler
        isExpanded={allRemindersExpanded}
        onToggled={() => {
          onExpandAllToggled();
        }}
      />
    );
  }

  expanderCellRenderer({ rowData }) {
    if (rowData.type === 'INVOICE') {
      return <div />; // Spacing.
    }

    const { expandedReminders, onReminderCollapsed, onReminderExpanded } = this.props;
    const reminderExpanded = expandedReminders[rowData.matterDebtorId];
    return (
      <ExpandCollapseToggler
        isExpanded={reminderExpanded}
        onToggled={() => {
          if (reminderExpanded) {
            onReminderCollapsed(rowData);
          } else {
            onReminderExpanded(rowData);
          }
        }}
      />
    );
  }

  toggleAllSelectionRenderer() {
    const { allRemindersSelected, onSelectAllToggled } = this.props;
    return (
      <input
        className="row-checkbox"
        type="checkbox"
        checked={allRemindersSelected}
        onChange={(e) => {
          e.stopPropagation();
          onSelectAllToggled(allRemindersSelected);
        }}
      />
    );
  }

  checkboxInvoiceCellRenderer({ rowData }) {
    if (rowData.type !== 'INVOICE') {
      return null;
    }

    const { selectedRows } = this.props;
    const invoiceSelected = selectedRows[rowData.debtorInvoiceId] || false;
    return (
      <input
        className="row-checkbox"
        type="checkbox"
        checked={invoiceSelected}
        onChange={(e) => {
          e.stopPropagation();
          this.props.onInvoiceSelectionChanged({ invoice: rowData });
        }}
      />
    );
  }

  checkboxDebtorCellRenderer({ rowData }) {
    if (rowData.type !== 'REMINDER') {
      return null;
    }

    const { selectedRows } = this.props;

    const reminderSelected = selectedRows[rowData.matterDebtorId] || false;
    return (
      <input
        className="row-checkbox"
        type="checkbox"
        checked={reminderSelected}
        onChange={(e) => {
          e.stopPropagation();
          this.props.onReminderSelectionChanged({ reminder: rowData });
        }}
      />
    );
  }

  overdueInvoicesCellRenderer({ rowData }) {
    if (rowData.type === 'INVOICE') {
      return null;
    }

    return (
      <LinkableText
        onClickLink={() => {
          this.props.onInvoiceCountClicked(rowData.debtorId);
        }}
        text={`${rowData.invoices.length}`}
        asLink
        inline
      />
    );
  }

  matterCellRenderer({ rowData }) {
    if (rowData.type === 'INVOICE') {
      return null;
    }

    return <MatterDisplay matterId={rowData.matter.matterId} onClickLink={this.props.onClickLink} asLink />;
  }

  debtorOrInvoiceNumberCellRenderer({ rowData }) {
    if (rowData.type === 'INVOICE') {
      const invoiceText = `Invoice #${rowData.invoiceNumber}`;

      // list debtors behind invoice number when invoice is a shared debt
      const debtorsText =
        rowData.debtorIds.length > 1
          ? ` (${rowData.debtorIds
              .map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true }))
              .join(' | ')})`
          : '';

      const tooltip = `${invoiceText} ${debtorsText}`;
      return (
        <div className="invoice-with-checkbox-wrapper">
          <div className="checkbox-container">
            {checkboxCellWrapperRenderer(this.checkboxInvoiceCellRenderer)({ rowData })}
          </div>

          <div className="invoice-cell" title={tooltip}>
            <LinkableText
              onClickLink={() => {
                this.props.onInvoiceClicked(rowData);
              }}
              text={invoiceText}
              tooltip={tooltip}
              asLink
              inline
            />
            {rowData.debtorIds.length > 1 && <i className="icon icon-contacts-1 multi-debtors-icon" />}
          </div>
        </div>
      );
    }

    return (
      <ContactDisplay
        contactId={rowData.debtor.entityId}
        onClickLink={this.props.onClickLink}
        asLink
        showLastNameFirst
      />
    );
  }

  onRowClicked({ event, rowData }) {
    if (event.target.className.indexOf('row-checkbox') !== -1) {
      return;
    }

    event.stopPropagation();

    switch (rowData.type) {
      case 'REMINDER':
        {
          const { expandedReminders, onReminderCollapsed, onReminderExpanded } = this.props;
          const reminderExpanded = expandedReminders[rowData.matterDebtorId];
          if (reminderExpanded) {
            onReminderCollapsed(rowData);
          } else {
            onReminderExpanded(rowData);
          }
        }
        break;

      case 'INVOICE':
        this.props.onInvoiceSelectionChanged({ invoice: rowData });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      invoiceReminders,
      selectedRows,
      onSortTriggered = () => {},
      sortDirection,
      sortBy,
      bulkEmailReminders,
      bulkMarkAsSentReminders,
      nbInvoicesSelected,
      combineInPdf,
      isCombineInProgress,
    } = this.props;

    const reminders = invoiceReminders.filter((reminderOrInvoice) => reminderOrInvoice.type !== 'INVOICE');
    const selectedRemindersCount = reminders.filter((reminder) =>
      reminder.invoices.some((invoice) => selectedRows[`${invoice.debtorId}|${invoice.invoiceId}`]),
    ).length;
    const noSelectedReminders = selectedRemindersCount === 0;

    const showInterest = hasFacet(facets.interest);

    return (
      <div className="panel-body">
        <div className="ribbon panel panel-primary">
          <button
            className="btn btn-primary"
            onClick={combineInPdf}
            type="button"
            title={noSelectedReminders ? 'Please select at least one invoice reminder' : ''}
            disabled={isCombineInProgress || noSelectedReminders}
          >
            {`Combine IN PDF (${selectedRemindersCount})`}
          </button>
          {!featureActive('BB-11448') && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={bulkEmailReminders}
              disabled={nbInvoicesSelected === 0}
            >
              Email Reminder
            </button>
          )}
          {featureActive('BB-11448') && (
            <DropdownMenuButton
              label="Bulk Actions"
              menuOptions={[
                {
                  id: 'sendViaEmail',
                  label: `Send via Email (${selectedRemindersCount})`,
                  onClick: () => bulkEmailReminders(),
                  disabled: noSelectedReminders,
                },
                {
                  id: 'markAsSent',
                  label: `Mark as Sent (${selectedRemindersCount})`,
                  onClick: () => bulkMarkAsSentReminders(),
                  disabled: noSelectedReminders,
                },
              ]}
              disabled={noSelectedReminders}
            />
          )}
        </div>
        <Translation>
          {(t) => (
            <Table
              className="invoice-reminder-table"
              rowClassName={({ rowData }) =>
                (rowData && (rowData.type === 'INVOICE' ? 'invoice-row' : 'reminder-row')) || ''
              }
              list={invoiceReminders}
              sort={onSortTriggered}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onRowClick={(...args) => this.onRowClicked(...args)}
            >
              {(() => {
                switch (REGION) {
                  case regionType.US:
                    return this.renderForUsRegion(this.props);
                  case regionType.AU:
                    return showInterest
                      ? this.renderForAuRegionWithInterest(this.props)
                      : this.renderForAuRegion(this.props, t);
                  case regionType.GB:
                    // TODO replace with GB specific table
                    return showInterest
                      ? this.renderForAuRegionWithInterest(this.props)
                      : this.renderForAuRegion(this.props, t);
                  default:
                    throw new Error(`Invoice reminder table does not support region: ${REGION}`);
                }
              })()}
            </Table>
          )}
        </Translation>
      </div>
    );
  }
}

function timestampRenderer({ cellData }) {
  if (!cellData) {
    return '';
  }
  // cellData is Date object
  // dateLocalisedRenderer passes parameters directly to t('date')
  return dateLocalisedRenderer({ date: cellData });
}

function contextMenuCellRenderer({ props }) {
  return ({ rowData }) => {
    const { onPreviewReminder, onViewLastReminder, onEmailReminder } = props;

    if (rowData.type === 'INVOICE') {
      return null;
    }

    const onSendViaMenuClicked = ({ sendVia }) => {
      if (sendVia === sentViaTypes.EMAIL) {
        props.onEmailReminder(rowData);
      } else {
        // Sent via Communicate is not supported for reminders
        // Default is manual mark as sent, sentVia type will be selected inside the modal
        props.onMarkAsSentClicked({
          reminder: rowData,
          operationType: operationTypes.INVOICE_REMINDER,
        });
      }
    };

    const sendOptionsMenu = featureActive('BB-11448') ? (
      <>
        <button
          type="button"
          className="list-group-item"
          onClick={() => {
            onSendViaMenuClicked({ sendVia: sentViaTypes.EMAIL });
          }}
        >
          Send via Email
        </button>
        {featureActive('BB-11448') && (
          <button type="button" className="list-group-item" onClick={onSendViaMenuClicked}>
            Mark as Sent
          </button>
        )}
      </>
    ) : null;

    return (
      <ContextMenu
        body={({ close }) => (
          <div className="context-menu-body list-group">
            {sendOptionsMenu}
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                onPreviewReminder(rowData);
                close();
              }}
            >
              {hasFacet(facets.allowEmailAttachment) ? 'Preview Reminder' : 'Generate Reminder PDF'}
            </button>
            {hasFacet(facets.allowEmailAttachment) &&
              // `View Last Reminder` should only be shown if the last sent was via email
              // When BB-11448 is off, we keep the old logic to check lastReminderDate.
              (featureActive('BB-11448')
                ? rowData.lastCorrespondenceHistory?.sentVia === sentViaTypes.EMAIL
                : rowData.lastReminderDate) && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => {
                    onViewLastReminder(rowData);
                    close();
                  }}
                >
                  View Last Reminder
                </button>
              )}
            {!featureActive('BB-11448') && (
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onEmailReminder(rowData);
                  close();
                }}
              >
                Email Reminder
              </button>
            )}
          </div>
        )}
      >
        <div className="context-menu-cell">...</div>
      </ContextMenu>
    );
  };
}

function emailStatusCellRenderer({ props }) {
  return ({ rowData }) => {
    if (rowData.type === 'INVOICE') {
      return <div />; // Spacing.
    }

    // eslint-disable-next-line no-unused-vars
    const onResendClicked = ({ sentVia }) => {
      props.onEmailReminder(rowData);
    };

    return (
      <ReminderCorrespondenceStatusIndicator
        lastCorrespondenceHistory={rowData.lastCorrespondenceHistory}
        onResendClicked={onResendClicked}
      />
    );
  };
}

InvoiceReminderTable.propTypes = {
  invoiceReminders: PropTypes.array,
  allRemindersExpanded: PropTypes.bool,
  expandedReminders: PropTypes.object,
  allRemindersSelected: PropTypes.bool,
  selectedRows: PropTypes.object,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  isCombineInProgress: PropTypes.bool,
  onSortTriggered: PropTypes.func,
  onInvoiceClicked: PropTypes.func,
  onReminderCollapsed: PropTypes.func,
  onReminderExpanded: PropTypes.func,
  onExpandAllToggled: PropTypes.func,
  onSelectAllToggled: PropTypes.func,
  onReminderSelectionChanged: PropTypes.func,
  onInvoiceSelectionChanged: PropTypes.func,
  onClickLink: PropTypes.func,
  onPreviewReminder: PropTypes.func,
  onViewLastReminder: PropTypes.func,
  onEmailReminder: PropTypes.func,
  onMarkAsSentClicked: PropTypes.func,
  onInvoiceCountClicked: PropTypes.func,
  bulkEmailReminders: PropTypes.func,
  bulkMarkAsSentReminders: PropTypes.func,
  nbInvoicesSelected: PropTypes.array,
  combineInPdf: PropTypes.func,
};

InvoiceReminderTable.defaultProps = {
  sortBy: 'debtor',
  sortDirection: 'asc',
  invoiceReminders: [],
  allRemindersExpanded: false,
  expandedReminders: {},
  allRemindersSelected: false,
  selectedRows: {},
  nbInvoicesSelected: [],
  isCombineInProgress: false,
  onSortTriggered: () => {},
  onInvoiceClicked: () => {},
  onReminderCollapsed: () => {},
  onReminderExpanded: () => {},
  onExpandAllToggled: () => {},
  onSelectAllToggled: () => {},
  onReminderSelectionChanged: () => {},
  onInvoiceSelectionChanged: () => {},
  onClickLink: () => {},
  onPreviewReminder: () => {},
  onViewLastReminder: () => {},
  onEmailReminder: () => {},
  onMarkAsSentClicked: () => {},
  onInvoiceCountClicked: () => {},
  bulkEmailReminders: () => {},
  bulkMarkAsSentReminders: () => {},
  combineInPdf: () => {},
};

export default InvoiceReminderTable;
