'use strict';

import { featureActive, featureData } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getProductTier, getAccountId } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').component('sbXeroIntegration', {
  templateUrl: 'ng-components/xero/xero-integration.html',
  controller: function (sbLoggerService, $scope, $timeout, sbMessageDisplayService, sbXeroService, sbLocalisationService, $window) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbXeroIntegration');

    // Yes, this looks a bit whack, see BB-11523
    // We want to change None to Select... for the mandatory accounts
    // Office Payments is technically not mandatory, but we want to force them to select "Do not integrate" instead of defaulting to None
    // as some clients were missing this and getting confused
    // basically Select..., None and Do not integrate all do the same thing, which is nothing
    // We can't just change the labels as we need Select AND Do not integrate for Office Payments accounts
    const DEFAULT_EMPTY_ACCOUNT = { name: 'None', id: -1 };
    const DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS = { name: 'Select...', id: -1 };
    const DO_NOT_INTEGRATE = { name: `Do not integrate ${sbLocalisationService.t('expense')} payments`, id: -2 };
    const accountId = getAccountId();

    let refreshAccountsTimer;

    ctrl.onLoginStarted = onLoginStarted;
    ctrl.onLoginSuccess = onLoginSuccess;
    ctrl.onLoginFailure = onLoginFailure;

    ctrl.save = save;
    ctrl.prepField = prepField;
    ctrl.disconnectXero = disconnectXero;
    ctrl.onChangeIntegrateGeneral = onChangeIntegrateGeneral;
    ctrl.onChangeInvoiceIntegration = onChangeInvoiceIntegration;
    ctrl.onConfirmEnableInvoiceIntegration = onConfirmEnableInvoiceIntegration;
    ctrl.onCancelEnableInvoiceIntegration = onCancelEnableInvoiceIntegration;
    ctrl.onClickHelpLink = onClickHelpLink;
    ctrl.onDownloadButtonClick = onDownloadButtonClick;
    ctrl.getOfficePaymentsTooltipText = getOfficePaymentsTooltipText;

    ctrl.waitingForNewAccountInfo = false;
    ctrl.waitingForLoginResult = false;
    ctrl.featureActive = featureActive;
    ctrl.t = sbLocalisationService.t;
    ctrl.hardAndSoftCostFeatureEnabled = featureActive('BB-13352');

    /**
     * $onInit
     * 
     * Controller initialisation.
     */
    ctrl.$onInit = () => {
      ctrl.view = {};
      ctrl.view.xeroInvoiceIntegrationFeatureEnabled = hasFacet(facets.integrationXeroInvoice) && featureActive('BB-9111');
      ctrl.view.showDownloadButton = false;
      ctrl.view.showButtonSpinner = false;
      // setting isInvoiceIntegrationEnabledOnLoad to true on init to hide the toggle before the xero setting loads. This is to avoid showing the toggle to new firms that haven't connected to Xero yet. The invoice integration toggle should only show for user who aleady connected to Xero but not having this enabled yet.
      ctrl.view.isInvoiceIntegrationEnabledOnLoad = true;
      ctrl.errors = {};

      ctrl.view.showInvoiceIntegrationConfirmationModal = false;

      ctrl.model = {
        integrateGeneral: false,
        integrateTrust: false,
        officePaymentsAccount: DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS,
      };

      // If xero is already connected, ask the backend to update xero account information.
      // See refreshAccountInformation() below for more info.
      if (isAuthed()) {
        refreshAccountInformation();
      }
    };

    const listeners = [
      $scope.$on('$destroy', () => listeners.forEach((unregisterFn) => unregisterFn())),
      $scope.$on('smokeball-data-update-sbXeroService', updateAccounts),
    ];

    /**
     * onLoginStarted
     * Called by the SbXeroLogin component.
     */
    function onLoginStarted() {
      ctrl.waitingForLoginResult = true;
    }

    /**
     * onLoginSuccess
     * Called by the SbXeroLogin component.
     */
    function onLoginSuccess() {
      ctrl.waitingForLoginResult = false;
      refreshAccountInformation();
    }

    /**
     * onLoginFailure
     * Called by the SbXeroLogin component.
     */
    function onLoginFailure() {
      ctrl.waitingForLoginResult = false;
      sbMessageDisplayService.warn(
        sbMessageDisplayService
        .builder()
        .text('Xero authorisation is taking longer than expected.')
        .group('CONNECT-WARNING')
      );
    }

    /**
     * updateAccounts
     * 
     * Updates xero account information in this controller based on the contents of the currently cached data.
     * waitingForNewAccountInfo is set to false after this function executes, causing the data loading spinner
     * to be hidden from the customer.
     */
     async function updateAccounts() {
      if (ctrl.waitingForLoginResult) {
        return;
      }
      else {
        sbMessageDisplayService.dismissGroup('CONNECT-WARNING');
      }

      if (isAuthed()) {
        loadAccounts();
        await loadSelectedAccounts();
      }

      ctrl.waitingForNewAccountInfo = false;

      // If there is a pending failure timer running for a refreshAccountInformation call, 
      // cancel it here as we can assume that the refreshAccountInformation was a success.
      if (refreshAccountsTimer) {
        $timeout.cancel(refreshAccountsTimer);
        refreshAccountsTimer = undefined;
      }
    }

    /**
     * refreshAccountInformation3
     * 
     * Asks the back-end to check xero for any new account information and to update the query DB accordingly.
     * After this function call, one of two things will happen at some point in the future:
     *   - sbXeroService cache will be updated, which we capture in $scope.$on('smokeball-data-update-sbXeroService', ...) or,
     *   - Back-end command failed (maybe xero token expired), which we have no way of knowing about. We use a timer to assume
     *     failure after REFRESH_ACCOUNTS_TIMEOUT_MS.
     */
    function refreshAccountInformation() {
      if (ctrl.waitingForNewAccountInfo) {
        return;
      }

      // Show the user a loading spinner.
      ctrl.waitingForNewAccountInfo = true;

      // Start a timer to handle a situation where the accounts failed to load.
      const REFRESH_ACCOUNTS_TIMEOUT_MS = 10000;

      refreshAccountsTimer = $timeout(async () => {
        if (ctrl.waitingForNewAccountInfo) {
          log.warn(`Failed to receive new xero account information within ${REFRESH_ACCOUNTS_TIMEOUT_MS}ms, using currently cached data`);
          await updateAccounts();
        }

      }, REFRESH_ACCOUNTS_TIMEOUT_MS);

      // Ask the back-end to refresh customer specific accounts from Xero.
      sbXeroService.refreshAccounts();
    }

    async function disconnectXero() {
      try {
        await sbXeroService.disableP();
        sbMessageDisplayService.success('Disconnected from Xero');
      } catch (err) {
        log.info('Failed to disconnect from Xero');
      }
    }

    // validation helpers
    const isExpenseAccountSameAsLegalFeeAccount = () => isSameAsLegalFeeAccount(ctrl.model.clientExpensesAccount);
    const isSoftCostExpenseAccountSameAsLegalFeeAccount = () => ctrl.hardAndSoftCostFeatureEnabled && isSameAsLegalFeeAccount(ctrl.model.clientSoftCostExpensesAccount);
    const feeAccountValid = () => accountPresent(ctrl.model.legalFeesAccount) && !isExpenseAccountSameAsLegalFeeAccount() && !isSoftCostExpenseAccountSameAsLegalFeeAccount();
    const expenseAccountValid = () => accountPresent(ctrl.model.clientExpensesAccount) && !isExpenseAccountSameAsLegalFeeAccount();
    const expenseSoftCostAccountValid = () => accountPresent(ctrl.model.clientSoftCostExpensesAccount) && !isSoftCostExpenseAccountSameAsLegalFeeAccount();
    const operatingAccountValid = () => accountPresent(ctrl.model.operatingBankAccount);
    const officePaymentsAccountValid = () => accountPresent(ctrl.model.officePaymentsAccount) || ctrl.model.officePaymentsAccount && (ctrl.model.officePaymentsAccount.id === DO_NOT_INTEGRATE.id);
    const officeSoftCostPaymentsAccountValid = () => accountPresent(ctrl.model.officeSoftCostPaymentsAccount) || ctrl.model.officeSoftCostPaymentsAccount && (ctrl.model.officeSoftCostPaymentsAccount.id === DO_NOT_INTEGRATE.id);
    // id can be string or in here
    // This blows up if account is undefined, assuming undefined is equivalent to DEFAULT_EMPTY_ACCOUNT in the absence of any other plan
    const isDefaultEmptyAccount = account => !account || account.id === -1;
    const mapsToNoAccount = account => !account || account.id == DEFAULT_EMPTY_ACCOUNT.id || account.id == DO_NOT_INTEGRATE.id;

    /***
     * Check if the account passed in is the same account as legal fee account
     *
     * @returns {boolean}
     */
    function isSameAsLegalFeeAccount(accountToCheck) {
      const isSameAccount = accountToCheck && accountToCheck.id === ctrl.model.legalFeesAccount.id;
      return isSameAccount;
    }

    function loadAccounts () {
      const accounts = sbXeroService.getBankAccounts();
      ctrl.view.operatingBankAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.operatingBankAccounts || [])];
      ctrl.view.legalFeesAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.legalFeesAccounts || [])];
      ctrl.view.clientExpensesAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.clientExpensesAccounts || [])];
      ctrl.view.clientSoftCostExpensesAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, ...(accounts.clientSoftCostExpensesAccounts || [])];
      ctrl.view.officePaymentsAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, DO_NOT_INTEGRATE, ...(accounts.officePaymentsAccounts || [])];
      ctrl.view.officeSoftCostPaymentsAccounts = [DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS, DO_NOT_INTEGRATE, ...(accounts.officeSoftCostPaymentsAccounts || [])];
      ctrl.view.surchargeAccounts = [DEFAULT_EMPTY_ACCOUNT, ...(accounts.surchargeAccounts || [])];
      ctrl.view.badDebtAccounts = [DEFAULT_EMPTY_ACCOUNT, ...(accounts.badDebtAccounts || [])];
      ctrl.view.interestAccounts = [DEFAULT_EMPTY_ACCOUNT, ...(accounts.interestAccounts || [])];
    }

    // here we can also make sure any selected account matches the latest
    // loaded accounts
    function replaceWithDefaultEmpty(account) {
      return accountPresent(account) ? findAccountById(account) : DEFAULT_EMPTY_ACCOUNT;
    }

    function replaceWithMandatoryDefaultEmpty(account) {
      return accountPresent(account) ? findAccountById(account) : DEFAULT_EMPTY_ACCOUNT_FOR_MANDATORY_ACCOUNTS;
    }

    function findAccountById(account) {
      return [
        ...ctrl.view.operatingBankAccounts,
        ...ctrl.view.legalFeesAccounts,
        ...ctrl.view.clientExpensesAccounts,
        ...ctrl.view.clientSoftCostExpensesAccounts,
        ...ctrl.view.officePaymentsAccounts,
        ...ctrl.view.officeSoftCostPaymentsAccounts,
        ...ctrl.view.surchargeAccounts,
        ...ctrl.view.badDebtAccounts,
        ...ctrl.view.interestAccounts,
      ].find(a => a.id === account.id);
    }

    async function loadSelectedAccounts () {
      const settings = sbXeroService.getSettings();
      ctrl.view.isInvoiceIntegrationEnabledOnLoad = settings.invoiceIntegrationEnabled;
      ctrl.model.integrateGeneral = settings.generalLedgerTransactionsEnabled;
      ctrl.model.invoiceIntegrationEnabled = settings.invoiceIntegrationEnabled;
      ctrl.model.invoiceIntegrationEnabledAt = settings.invoiceIntegrationEnabledAt;
      ctrl.model.operatingBankAccount = replaceWithMandatoryDefaultEmpty(settings.operatingBankAccount);
      ctrl.model.legalFeesAccount = replaceWithMandatoryDefaultEmpty(settings.legalFeesAccount);
      ctrl.model.clientExpensesAccount = replaceWithMandatoryDefaultEmpty(settings.clientExpensesAccount);
      // If there is no value yet set (haven't saved with soft cost feature on), show account based on hard cost
      ctrl.model.clientSoftCostExpensesAccount = settings.clientSoftCostExpensesAccount ? replaceWithMandatoryDefaultEmpty(settings.clientSoftCostExpensesAccount) : ctrl.model.clientExpensesAccount;
      ctrl.model.officePaymentsAccount = hasSelectedDoNotIntegrateOfficePayments(settings) ? DO_NOT_INTEGRATE : replaceWithMandatoryDefaultEmpty(settings.officePaymentsAccount);
      ctrl.model.officeSoftCostPaymentsAccount = loadSelectedOfficeSoftCostPaymentsAccount(settings);
      ctrl.model.surchargeAccount = replaceWithDefaultEmpty(settings.surchargeAccount);
      ctrl.model.badDebtAccount = replaceWithDefaultEmpty(settings.badDebtAccount);
      ctrl.model.interestAccount = replaceWithDefaultEmpty(settings.interestAccount);
      if (featureActive('BB-9111') && ctrl.view.isInvoiceIntegrationEnabledOnLoad && ctrl.model.invoiceIntegrationEnabledAt) {
        try {
          const res = await sbXeroService.getPreSignedUrlOfOutstandingInvoiceReport({ accountId, timestamp: ctrl.model.invoiceIntegrationEnabledAt });
          if (res.body.signedUrl) {
            ctrl.view.downloadLink = res.body.signedUrl;
            ctrl.view.showDownloadButton = true;
          } else {
            ctrl.view.showDownloadButton = false;
          }

        } catch (err) {
          ctrl.errors.downloadLink = err;
          log.error('Failed to fetch outstanding invoice report:', err);
        }
      }
    }

    function loadSelectedOfficeSoftCostPaymentsAccount(xeroSettings) {
      // if officeSoftCostPaymentsAccount has account stored, we show it
      if (xeroSettings.officeSoftCostPaymentsAccount) {
        return replaceWithMandatoryDefaultEmpty(xeroSettings.officeSoftCostPaymentsAccount);
      } else {
        // if officeSoftCostPaymentsAccount is null, it could be two situations:
        // 1. User has saved with feature on but selected DO_NOT_INTEGRATE, in this case clientSoftCostExpensesAccount would have account stored
        // 2. User haven't saved with soft cost feature BB-13352 on, then we show account based on hard cost
        if (xeroSettings.clientSoftCostExpensesAccount) {
          return DO_NOT_INTEGRATE;
        } else {
          return ctrl.model.officePaymentsAccount;
        }
      }
    }

    function hasSelectedDoNotIntegrateOfficePayments(xeroSettings) {
      if ('officePaymentsIntegrationEnabled' in xeroSettings) {
        if (xeroSettings.officePaymentsIntegrationEnabled === false) {
          return true;
        }
      }
      return false;
    }

    function isAuthed () {
      const authed = sbXeroService.isAuthorized();
      ctrl.view.integrationEnabled = authed;
      return authed;
    }

    // Need to do this as the backend returns an object for an unselected account
    // rather than not returning anything for that account
    function accountPresent (account) {
      return !!(account && account.name && !isDefaultEmptyAccount(account));
    }

    function prepField (name) {
      switch (name) {
        case 'operatingBankAccount':
          ctrl.errors.operatingBankAccount = ctrl.model.integrateGeneral && !operatingAccountValid();
          break;
        case 'legalFeesAccount':
          ctrl.errors.legalFeesAccount = ctrl.model.integrateGeneral && !feeAccountValid();
          break;
        case 'clientExpensesAccount':
          ctrl.errors.clientExpensesAccount = ctrl.model.integrateGeneral && !expenseAccountValid();
          break;
        case 'clientSoftCostExpensesAccount':
          ctrl.errors.clientSoftCostExpensesAccount = ctrl.model.integrateGeneral && !expenseSoftCostAccountValid();
          break;
        case 'officePaymentsAccount':
          ctrl.errors.officePaymentsAccount = ctrl.model.integrateGeneral && !officePaymentsAccountValid();
          break;
        case 'officeSoftCostPaymentsAccount':
          ctrl.errors.officeSoftCostPaymentsAccount = ctrl.model.integrateGeneral && !officeSoftCostPaymentsAccountValid();
          break;
      }
    }

    function unsetIfDefaultEmpty(account) {
      return mapsToNoAccount(account) ? undefined : account;
    }

    function marshal (invoiceIntegrationEnabledTimestamp) {
      const settings = {
        generalLedgerTransactionsEnabled: ctrl.model.integrateGeneral,
        trustTransactionsEnabled: ctrl.model.integrateTrust,
        invoiceIntegrationEnabled: featureActive('BB-9111') ? ctrl.model.invoiceIntegrationEnabled : undefined,
        // invoiceIntegrationEnabledAt is set to local timestamp when xero invoice integration is first enabled
        // at which point the outstanding invoice CSV report is generated once and stored permanently. This field
        // is not used by .net and is used as a way to find the outstanding invoice CSV report already generated.
        invoiceIntegrationEnabledAt: featureActive('BB-9111') ? invoiceIntegrationEnabledTimestamp || ctrl.model.invoiceIntegrationEnabledAt : undefined,
      };

      if (ctrl.model.integrateGeneral) {
        settings.officePaymentsIntegrationEnabled = ctrl.model.officePaymentsAccount.id !== DO_NOT_INTEGRATE.id;
        settings.operatingBankAccount = unsetIfDefaultEmpty(ctrl.model.operatingBankAccount);
        settings.legalFeesAccount = unsetIfDefaultEmpty(ctrl.model.legalFeesAccount);
        settings.clientExpensesAccount = unsetIfDefaultEmpty(ctrl.model.clientExpensesAccount);
        settings.clientSoftCostExpensesAccount = ctrl.hardAndSoftCostFeatureEnabled ? unsetIfDefaultEmpty(ctrl.model.clientSoftCostExpensesAccount) : undefined;
        settings.officePaymentsAccount = unsetIfDefaultEmpty(ctrl.model.officePaymentsAccount);
        settings.officeSoftCostPaymentsAccount = ctrl.hardAndSoftCostFeatureEnabled ? unsetIfDefaultEmpty(ctrl.model.officeSoftCostPaymentsAccount) : undefined;
        settings.surchargeAccount = featureActive('BB-7270') ? unsetIfDefaultEmpty(ctrl.model.surchargeAccount) : undefined;
        settings.badDebtAccount = featureActive('BB-9111') ? unsetIfDefaultEmpty(ctrl.model.badDebtAccount) : undefined;
        settings.interestAccount = featureActive('BB-9111') ? unsetIfDefaultEmpty(ctrl.model.interestAccount) : undefined;
      }

      return settings;
    }

    function isValid () {
      if (ctrl.model.integrateGeneral && (
        !operatingAccountValid() || !feeAccountValid() || !expenseAccountValid() || !officePaymentsAccountValid())
      ) {
        return false;
      }

      if (ctrl.model.integrateGeneral && ctrl.hardAndSoftCostFeatureEnabled && (
        !expenseSoftCostAccountValid() || !officeSoftCostPaymentsAccountValid())
      ) {
        return false;
      }

      return true;
    }

    // We need to clear any accounts that are not being used anymore
    // as this is what the backend does
    function clearUnusedAccounts () {
      if (!ctrl.model.integrateGeneral) {
        ctrl.model.operatingBankAccount = undefined;
        ctrl.model.legalFeesAccount = undefined;
        ctrl.model.clientExpensesAccount = undefined;
        ctrl.model.clientSoftCostExpensesAccount = undefined;
        ctrl.model.officePaymentsAccount = undefined;
        ctrl.model.officeSoftCostPaymentsAccount = undefined;
        ctrl.model.surchargeAccount = undefined;
        ctrl.model.badDebtAccount = undefined;
        ctrl.model.interestAccount = undefined;
      }
    }

    function save () {
      if (isValid()) {
        //need to prep these fields as their error states depend on each other
        prepField('legalFeesAccount');
        prepField('clientExpensesAccount');
        if (ctrl.hardAndSoftCostFeatureEnabled) {
          prepField('clientSoftCostExpensesAccount');
        }

        const isEnablingInvoiceIntegration = featureActive('BB-9111') && !ctrl.view.isInvoiceIntegrationEnabledOnLoad && ctrl.model.invoiceIntegrationEnabled;
        let settings;
        let invoiceIntegrationEnabledTimestamp;
        // get timestamp on enabling invoice integration
        if (isEnablingInvoiceIntegration) {
          invoiceIntegrationEnabledTimestamp = new Date();
          ctrl.model.invoiceIntegrationEnabledAt = invoiceIntegrationEnabledTimestamp;
          settings = marshal(invoiceIntegrationEnabledTimestamp);

          // show button with spinner, generating invoice report
          ctrl.view.showDownloadButton = true;
          ctrl.view.showButtonSpinner = true;
        } else {
          settings = marshal()
        }

        sbXeroService.saveSettingsP(settings)
          .then(clearUnusedAccounts)
          .then(async () => {
            sbMessageDisplayService.success(
              sbMessageDisplayService
              .builder()
              .text('Xero integration settings saved')
            );
            ctrl.view.isSaved = true;
            if (isEnablingInvoiceIntegration) {
              try {
                const res = await sbXeroService.generateOutstandingInvoiceReport(invoiceIntegrationEnabledTimestamp)
                ctrl.view.downloadLink = res.signedUrl;
                ctrl.view.showButtonSpinner = false;
              } catch (err) {
                ctrl.errors.downloadLink = err;
                ctrl.view.showButtonSpinner = false;
                sbMessageDisplayService.error(
                  sbMessageDisplayService
                  .builder()
                  .text('Failed to generate outstanding invoice report')
                  .conditionalText(': {0}', err.message)
                );
              }
            }
          })
          .catch((err) => {
            log.info('error saving xero settings', err);
            sbMessageDisplayService.error(
              sbMessageDisplayService
              .builder()
              .text('Failed to save integration settings')
              .conditionalText(': {0}', err.message)
            );
          });
      } else {
        prepField('operatingBankAccount');
        prepField('legalFeesAccount');
        prepField('clientExpensesAccount');
        prepField('officePaymentsAccount');
        prepField('trustBankAccount');
        prepField('trustLiabilityAccount');
        if (ctrl.hardAndSoftCostFeatureEnabled) {
          prepField('clientSoftCostExpensesAccount');
          prepField('officeSoftCostPaymentsAccount');
        }
        log.info('error validating xero settings')
        log.info(ctrl.errors);
      }
    }

    async function onDownloadButtonClick() {
      if (ctrl.errors.downloadLink) {
        sbMessageDisplayService.error('Failed to generate report file');
      } else {
        const url = ctrl.view.downloadLink;
        sbXeroService.downloadInvoiceReport({ url, timestamp: ctrl.model.invoiceIntegrationEnabledAt })
      }
    }

    function onChangeIntegrateGeneral (name, value) {
      ctrl.model.integrateGeneral = value;
    }

    function onChangeInvoiceIntegration (name, value) {
      if (value) {
        ctrl.view.showInvoiceIntegrationConfirmationModal = true;
      } else {
        ctrl.model.invoiceIntegrationEnabled = false;
      }
    }

    function onConfirmEnableInvoiceIntegration () {
      ctrl.view.showInvoiceIntegrationConfirmationModal = false;
      ctrl.model.invoiceIntegrationEnabled = true;
    }

    function onCancelEnableInvoiceIntegration () {
      ctrl.view.showInvoiceIntegrationConfirmationModal = false;
    }

    function onClickHelpLink() {
      const tier = getProductTier();
      const helpLink = featureActive('BB-9475')
        ? featureData('BB-9475')[tier || 'SMK001']
        : 'https://support.smokeball.com/hc/en-au';
      $window.open(helpLink, '_blank');
    }

    function getOfficePaymentsTooltipText() {
      return `When a ${ctrl.t('expense')} is marked as paid, the payment details will be applied to this account`;
    }
  }
});
