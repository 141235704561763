'use strict';

const moment = require('moment');
const { formatPaymentFrequency } = require('./format-payment-frequency');

/**
 * Create a payment plan description from the graphql type PaymentPlan
 * @param {*} paymentPlan payment plan with nextPaymentDate, installmentAmount, installmentFrequency
 * @returns { paymentPlanDescription }
 */
const formatPaymentPlanDescriptionGraphql = ({ paymentPlan, todayAsInteger, t }) => {
  if (!paymentPlan) {
    return '';
  }

  const formatCurrency = (cents) => t('cents', { val: cents });
  const formatDate = (date) => moment(date, 'YYYYMMDD').format('MMMM Do YYYY');

  // The next payment date is the day before the plan is behind/overdue (i.e. one day before behindOnDate).
  const nextPaymentDate = paymentPlan.nextPaymentDate;
  let nextPaymentDue;
  if (nextPaymentDate === todayAsInteger) {
    nextPaymentDue = `Next payment due today.`;
  } else if (nextPaymentDate > todayAsInteger) {
    nextPaymentDue = `Next payment due ${formatDate(nextPaymentDate)}.`;
  } else {
    nextPaymentDue = `Last payment was missed ${formatDate(nextPaymentDate)}.`;
  }
  const paymentAmount = formatCurrency(paymentPlan.installmentAmount);
  const paymentFrequency = formatPaymentFrequency(paymentPlan.installmentFrequency).toLowerCase();

  return `Payments of ${paymentAmount} ${paymentFrequency}. ${nextPaymentDue}`;
};

module.exports = {
  formatPaymentPlanDescriptionGraphql,
};
