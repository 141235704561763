'use strict';

const validator = require('validator');

const isValidBankAccountId = (bankAccountId) => {
  const accountTypes = ['Operating', 'Trust'];
  const parts = bankAccountId.split('/');
  // Controlled Money Accounts (and multiple trusts eventually) no longer have the /AccountType suffix
  // Just a UUID is now a valid bank account ID
  return (
    validator.isUUID(bankAccountId) ||
    (parts.length === 2 && validator.isUUID(parts[0]) && accountTypes.includes(parts[1]))
  );
};

module.exports = {
  isValidBankAccountId,
};
