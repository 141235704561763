import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  updateSelectedInvoiceTemplate,
  clearSelectedInvoiceTemplate,
  getCurrentInvoiceTemplate,
  updateSelectedInvoiceTemplateConfiguration,
  getSelectedInvoiceTemplateConfiguration,
  initSelectedInvoiceTemplate,
} from 'web/redux/route/home-billing-invoice-template';
import { getInvoiceLatestVersion } from '@sb-billing/redux/invoices';
import { DraftInvoiceTemplateSettings } from '@sb-billing/react/draft-invoice-template-settings';
import { interpolateCustomTextTitle } from '@sb-billing/business-logic/invoice-settings';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getMatterTypeNameById } from '@sb-matter-types/redux/matter-types';
import { getByMatterIdWithStringAsEnums as getMatterInvoiceSettings } from '@sb-billing/redux/matter-invoice-settings';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { getTemplateById } from '@sb-billing/redux/invoice-settings-template';
import { withReduxStore, useTranslation } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { decodeHtml, encodeHtml } from '@sb-billing/encode-decode-html-invoice-settings';
import { featureActive } from '@sb-itops/feature';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';

const getInitialCustomTextTitles = ({ matterId, titleText, subtitleText, t }) => {
  const matter = getMatterById(matterId) || {};
  const { matterNumber, matterTypeId, description: matterDescription } = matter;
  const matterClientString = matter.clientDisplayName || matter.clientDisplay;
  const matterTypeName = getMatterTypeNameById(matterTypeId);

  const interpolatedTitleText = interpolateCustomTextTitle({
    text: titleText,
    matterNumber,
    matterClientString,
    matterTypeName,
    matterDescription,
    t,
  });

  const interpolatedSubtitleText = interpolateCustomTextTitle({
    text: subtitleText,
    matterNumber,
    matterClientString,
    matterTypeName,
    matterDescription,
    t,
  });

  return { interpolatedTitleText, interpolatedSubtitleText };
};

const mapStateToProps = (state, { matterId, invoiceId, onSettingsChanged, isValidFooter, validateFooter }) => {
  initSelectedInvoiceTemplate({ matterId, invoiceId });
  const matterBillingConfiguration = getCurrentConfigurationByMatterId(matterId) || {};

  const currentSettings = getCurrentInvoiceTemplate({ matterId, invoiceId });
  const {
    titleLine1CustomText,
    titleLine2CustomText,
    isTemplateWithDefaults,
    templateId,
    invoiceAdditionalOptions,
    eInvoiceOptions,
    footer,
  } = currentSettings;

  const selectedTemplate = templateId && getTemplateById(templateId);
  const selectedTemplateDeleted = selectedTemplate && selectedTemplate.isDeleted;
  // If the invoice is generated from the bulk create, take the footer value from the settings.
  const selectedFooter = !footer ? selectedTemplate && selectedTemplate.settings.footer : footer;
  const { footer: decodedFooter } = decodeHtml({ footer: selectedFooter });

  // For the first render validate the footer
  let isValidFooterNewValue = isValidFooter;
  if (isValidFooter === null || isValidFooter === undefined) {
    isValidFooterNewValue = validateFooter(decodedFooter);
  }

  const hasOpenTrustAccountsForMatter =
    filterTrustAccountsByMatter(matterId).filter((ta) => !isTrustAccountClosed(ta)).length !== 0;

  return {
    supportsTax: hasFacet(facets.tax),
    templateId,
    selectedTemplateDeleted,
    showResetTemplateButton: !isTemplateWithDefaults,
    invoiceAdditionalOptions: invoiceAdditionalOptions.options,
    provideShowRetainerOption: featureActive('BB-6908') && !!matterBillingConfiguration?.minimumTrustRetainerActive,
    hasOpenTrustAccountsForMatter,
    eInvoiceOptions: eInvoiceOptions && eInvoiceOptions.options,
    onTemplateChange: (template) => {
      const tmpId = template && template.value;
      if (templateId !== tmpId) {
        // changing the template clear everything but the invoice collapsed option
        clearSelectedInvoiceTemplate({ matterId, invoiceId });
        updateSelectedInvoiceTemplateConfiguration({
          matterId,
          invoiceId,
          isTemplateWithDefaults: false,
        });
        updateSelectedInvoiceTemplate({ matterId, invoiceId, templateId: tmpId });
        onSettingsChanged(tmpId);
      }
    },
    onTitleChangeText: (value) => {
      if (titleLine1CustomText !== value) {
        const selectedTemplateConfiguration = getSelectedInvoiceTemplateConfiguration({ matterId, invoiceId });
        updateSelectedInvoiceTemplateConfiguration({
          ...selectedTemplateConfiguration,
          matterId,
          invoiceId,
          titleLine1Option: 'Custom',
          titleLine1CustomText: value,
          titleLine1Overridden: true,
          isTemplateWithDefaults: false,
        });
        onSettingsChanged();
      }
    },
    onSubtitleChangeText: (value) => {
      if (titleLine2CustomText !== value) {
        const selectedTemplateConfiguration = getSelectedInvoiceTemplateConfiguration({ matterId, invoiceId });
        updateSelectedInvoiceTemplateConfiguration({
          ...selectedTemplateConfiguration,
          matterId,
          invoiceId,
          titleLine2Option: 'Custom',
          titleLine2CustomText: value,
          titleLine2Overridden: true,
          isTemplateWithDefaults: false,
        });
        onSettingsChanged();
      }
    },
    onFooterChange: (value, delta, source) => {
      if (source !== 'user') {
        // Ignore any changes made by react-quill itself instead of user input
        return;
      }

      if (value !== footer) {
        const selectedTemplateConfiguration = getSelectedInvoiceTemplateConfiguration({ matterId, invoiceId });
        const { footer: encodedFooter } = encodeHtml({ footer: value });
        updateSelectedInvoiceTemplateConfiguration({
          ...selectedTemplateConfiguration,
          matterId,
          invoiceId,
          footer: encodedFooter,
          footerOverridden: true,
          isTemplateWithDefaults: false,
        });
        onSettingsChanged();
      }
    },
    isValidFooter: isValidFooterNewValue,
    onSetDefaultTemplate: () => {
      const latestInvoiceVersion = getInvoiceLatestVersion(invoiceId) || {};
      const defaultMatterSettings = getMatterInvoiceSettings(matterId) || {};
      const tmpId = latestInvoiceVersion.templateId || defaultMatterSettings.templateId;

      updateSelectedInvoiceTemplate({
        matterId,
        invoiceId,
        templateId: tmpId,
      });

      updateSelectedInvoiceTemplateConfiguration({
        matterId,
        invoiceId,
        isTemplateWithDefaults: true,
      });
      onSettingsChanged(tmpId);
    },
    onInvoiceConfigurationOptionChanged: (optionName, value) => {
      const defaultInvoiceTemplate = getSelectedInvoiceTemplateConfiguration({ matterId, invoiceId }) || {};
      updateSelectedInvoiceTemplateConfiguration({
        ...defaultInvoiceTemplate,
        matterId,
        invoiceId,
        [optionName]: value,
        isTemplateWithDefaults: false,
      });
      onSettingsChanged();
    },
    currentSettings,
    footer: decodedFooter,
  };
};

const DraftInvoiceTemplateSettingsWrapper = withReduxStore(
  connect(mapStateToProps)(
    withOnLoad(
      ({ matterId, invoiceId, currentSettings, onTitleChangeText, onSubtitleChangeText, templateId, ...props }) => {
        const { t } = useTranslation();
        const {
          titleLine1CustomText,
          titleLine2CustomText,
          titleLine1Overridden,
          titleLine2Overridden,
          titleLine1DefaultText,
          titleLine2DefaultText,
        } = currentSettings;

        const rawTitleText = titleLine1Overridden ? titleLine1CustomText : titleLine1DefaultText;
        const rawSubtitleText = titleLine2Overridden ? titleLine2CustomText : titleLine2DefaultText;

        const { interpolatedTitleText, interpolatedSubtitleText } = getInitialCustomTextTitles({
          matterId,
          titleText: rawTitleText,
          subtitleText: rawSubtitleText,
          t,
        });

        useEffect(() => {
          const selectedTemplateConfiguration = getSelectedInvoiceTemplateConfiguration({ matterId, invoiceId });
          updateSelectedInvoiceTemplateConfiguration({
            ...selectedTemplateConfiguration,
            matterId,
            invoiceId,
            titleLine1Option: 'Custom',
            titleLine1CustomText: interpolatedTitleText,
            titleLine1Overridden: true,
            titleLine2Option: 'Custom',
            titleLine2CustomText: interpolatedSubtitleText,
            titleLine2Overridden: true,
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [templateId]);

        return (
          <DraftInvoiceTemplateSettings
            {...props}
            templateId={templateId}
            onTitleChangeText={onTitleChangeText}
            onSubtitleChangeText={onSubtitleChangeText}
            titleText={interpolatedTitleText}
            subtitleText={interpolatedSubtitleText}
          />
        );
      },
    ),
  ),
);

DraftInvoiceTemplateSettingsWrapper.displayName = 'DraftInvoiceTemplateSettingsWrapper';

DraftInvoiceTemplateSettingsWrapper.propTypes = {
  matterId: PropTypes.string.isRequired,
  invoiceId: PropTypes.string,
  validateFooter: PropTypes.func.isRequired,
  // The parent often needs to be informed when the settings change
  onSettingsChanged: PropTypes.func,
  isValidFooter: PropTypes.bool,
};

DraftInvoiceTemplateSettingsWrapper.defaultProps = {
  // The parent often needs to be informed when the settings change
  onSettingsChanged: () => {},
  invoiceId: 'draft-default',
  isValidFooter: undefined,
};

export default DraftInvoiceTemplateSettingsWrapper;
