import { getDebtorFilter } from '@sb-matter-management/business-logic/matters/services';
import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';
import {
  getById as getBankAccountById,
  getTrustAccount,
  getTrustAccounts,
  getTrustAccountsByLocation,
  getActiveTrustAccounts,
  shouldShowOriginalTrustOnly,
  isTrustAccountClosed,
} from '@sb-billing/redux/bank-account';
import { getLocationFromMatterTypeId } from '@sb-matter-types/business-logic/matter-types/services';
import { getById as getMatterById, getList as getMatters } from '@sb-matter-management/redux/matters';
import {
  getByContactId as getTransactionsByContactId,
  getByMatterId as getTransactionsByMatterId,
} from '@sb-billing/redux/transactions';
import { getUniqueTrustAccountsFromTransactions } from '@sb-billing/business-logic/transactions/services';
import { ALL_STATES } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getAvailableTrustAccountsForMatter } from '@sb-billing/business-logic/bank-account/services';
import { t } from '@sb-itops/localisation-web';

/**
 * For a given contact id returns the array of trust accounts that should be displayed in contacts > transactions side panel for that contact:
 * - The list of trust accounts should match the current state of all matters this contact is listed against AND
 *   any trust accounts the contact has transacted in AND any trust accounts that are set to ALL_STATES, or
 * - the original trust if there is only one active trust account and it doesn't have a location set
 * @param {string} contactId
 * @param {object} options
 * @param {boolean} options.includeClosed Include closed trust accounts
 * @returns array of trust accounts
 */
export const filterTrustAccountsByContact = (contactId, options = {}) => {
  if (!hasFacet(facets.matterContactBalance)) {
    return [];
  }

  if (!contactId) {
    return [];
  }

  const { includeClosed = false } = options;

  // 1. Get all locations for all matters the contact is involved in
  const matterLocationsSet = getMatters().reduce((acc, matter) => {
    // If the debtorId is provided ensure the first debtor in the
    // matter matches the specified ID
    if (contactId && !getDebtorFilter([contactId])(matter)) {
      return acc;
    }
    const matterTypeId = matter.matterTypeId;
    const locationId = getLocationFromMatterTypeId(matterTypeId, getMatterTypeById);
    acc.add(locationId);
    return acc;
  }, new Set());

  if (matterLocationsSet.has('GENERIC' || 'FED')) {
    return includeClosed ? getTrustAccounts() : getActiveTrustAccounts();
  }

  // 2. Get all accounts with transactions the contact has transacted
  const allTransactionsByContact = getTransactionsByContactId(contactId);
  const transactedTrustAccounts = getUniqueTrustAccountsFromTransactions({
    transactions: allTransactionsByContact,
    getBankAccountById,
  }).filter((ta) => includeClosed || !isTrustAccountClosed(ta));

  // 3. Get all trust accounts that match any matter from 1)
  const contactTrustAccountsByLocation = Array.from(matterLocationsSet).reduce((acc, locationId) => {
    const trustAccounts = getTrustAccountsByLocation({ locationId, includeClosed });
    acc.push(...trustAccounts);
    return acc;
  }, []);

  // 4. Get all trust accounts that have ALL_STATE as location
  const trustAccountsForAllStates = getTrustAccountsByLocation({
    locationId: ALL_STATES.value,
    includeClosed,
  });

  const originalTrustAccounts = [];
  if (shouldShowOriginalTrustOnly()) {
    originalTrustAccounts.push(getTrustAccount());
  }

  const trustAccountsByContact = originalTrustAccounts
    .concat(transactedTrustAccounts)
    .concat(contactTrustAccountsByLocation)
    .concat(trustAccountsForAllStates);

  return Array.from(new Set(trustAccountsByContact));
};

/**
 * For a given matterId, returns the list of all trust accounts to be displayed under Matter > transactions:
 * [Show all open Trust accounts that match the current matter's state AND any trust account the matter has transacted in AND any trust accounts that are set to ALL_STATES] OR
 * the original trust if there is only one trust account and it doesn't have a state set
 * @param {string} matterId
 * @param {object} options
 * @param {boolean} options.includeClosed Include closed trust accounts
 * @returns an array of trust accounts
 */
export const filterTrustAccountsByMatter = (matterId, options = {}) => {
  const { includeClosed = false } = options;

  if (!matterId) {
    return [];
  }

  const matterTypeId = getMatterById(matterId)?.matterTypeId;
  const locationId = getLocationFromMatterTypeId(matterTypeId, getMatterTypeById);

  const availableTrustAccountsForMatter = getAvailableTrustAccountsForMatter({
    legacyTrustAccount: getTrustAccount(),
    trustAccounts: getTrustAccounts(),
    locationId,
    matterId,
    matterTransactions: getTransactionsByMatterId(matterId),
    getBankAccountById,
    includeClosed,
    t,
  });

  return availableTrustAccountsForMatter;
};

/**
 * Returns the list of all trust accounts
 * @param {string} matterId
 * @param {object} options
 * @param {boolean} options.includeClosed Include closed trust accounts
 * @returns an array of trust accounts
 */

export const filterTrustAccounts = (options = {}) => {
  const { includeClosed = false } = options;
  return includeClosed ? getTrustAccounts() : getActiveTrustAccounts();
};

/**
 * Returns all trust accounts with state in specified location, OR
 * the original trust if there is only one trust account and it doesn't have a state set
 * @param {*} locationId
 * @param {object} options
 * @param {boolean} options.includeClosed Include closed trust accounts
 * @returns an array of trust accounts
 */
export const filterTrustAccountsByLocation = (locationId, options = {}) => {
  if (!locationId) {
    return [];
  }

  const { includeClosed = false, includeAllStates = true } = options;

  const trustAccountsForLocation = getTrustAccountsByLocation({ locationId, includeClosed, includeAllStates }); //

  const originalTrustAccounts = [];
  if (shouldShowOriginalTrustOnly()) {
    originalTrustAccounts.push(getTrustAccount());
  }

  const trustAccountsByLocation = originalTrustAccounts.concat(trustAccountsForLocation);

  return Array.from(new Set(trustAccountsByLocation));
};
