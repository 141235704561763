import * as Yup from 'yup';

export const FirmUtbmsSettingsSchema = Yup.object().shape({
  isUtbmsEnabled: Yup.boolean().required(),
  ledesFirmId: Yup.string()
    .max(256, 'Should not exceed 256 characters')
    .when('isUtbmsEnabled', {
      is: true,
      then: Yup.string().required('Should not be empty').nullable().trim(),
    })
    .nullable(),
});
