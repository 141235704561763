import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react/button';
import { CheckboxLabelInline, Modal } from '@sb-itops/react';
import * as Styles from './SendViaCommunicateConfirmModal.module.scss';

const SendViaCommunicateConfirmModal = ({
  isVisible,
  onClose,
  onSend,
  handleDoNotShowAgainCheckbox,
  doNotShowAgainChecked,
}) => (
  <Modal
    title="Please Confirm"
    body={
      <div>
        <p>
          When sending invoices via Client Portal, the invoice will also be viewable by your firm’s staff members that
          have access to this matter. The invoice may contain fee earner’s rates and billed hours.{' '}
        </p>
        <p>Do you still wish to send?</p>
      </div>
    }
    footer={
      <div className={Styles.footer}>
        <CheckboxLabelInline
          checked={doNotShowAgainChecked}
          onChange={handleDoNotShowAgainCheckbox}
          className={Styles.checkbox}
        >
          Don’t show this message again
        </CheckboxLabelInline>

        <Button className={Styles.footerBtn} onClick={onSend}>
          Send
        </Button>
        <Button className={Styles.footerBtn} type="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    }
    isVisible={isVisible}
    onModalClose={onClose}
    shouldCloseOnOverlayClick={false}
  />
);

SendViaCommunicateConfirmModal.displayName = 'SendViaCommunicateConfirmModal';

SendViaCommunicateConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  doNotShowAgainChecked: PropTypes.bool.isRequired,
  handleDoNotShowAgainCheckbox: PropTypes.func.isRequired,
};

SendViaCommunicateConfirmModal.defaultProps = {};

export default SendViaCommunicateConfirmModal;
