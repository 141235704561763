angular.module('sb.billing.webapp').component('sbAuiNoneWithParams', {
  templateUrl: 'ng-composable-components/action-ui/none-with-params/aui-none-with-params.html',
  require: { group: '^sbComposeGroup' },
  bindings: { actionBindContext: '<?', actionBindName: '@' },
  controller: function() {
    const that = this;

    that.$onInit = function() {
      that.actionBindContext[that.actionBindName] = function(actionData) {
        that.group.doAction(actionData);
      };
    };
  }
});
