import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes } from '../button';
import { SlidingToggle } from '../sliding-toggle';
import { ContextMenu } from '../context-menu';

import Styles from './LoginForm.module.scss';

function loginButtonText({ loginInProgress, userSwitchInProgress }) {
  if (loginInProgress) {
    return '';
  }
  if (userSwitchInProgress) {
    return 'Preparing to switch user...please wait';
  }

  return 'Log in';
}

export const LoginForm = memo(
  ({
    username,
    password,
    isPrivateComputer,
    privateComputerTooltip,
    disableUserField,
    enableUserSwitching,
    formDisabled,
    loginInProgress,
    forgotPasswordUrl,
    showUpdatePaymentMethod,
    userSwitchInProgress,
    errorMessages,
    onSwitchUser,
    onFieldValueUpdated,
    onLogin,
    onUpdatePaymentMethod,
  }) => {
    const autoFocusRef = useCallback((inputElement) => {
      setTimeout(() => inputElement && inputElement.focus());
    }, []);

    return (
      <div className={Styles.loginForm}>
        <fieldset disabled={formDisabled || loginInProgress}>
          {/* Username field */}
          <div className={Styles.formGroup}>
            <span className={Styles.decorator}>
              <i className="fa fa-fw fa-envelope-o" />
            </span>
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              value={username}
              disabled={username && disableUserField}
              ref={autoFocusRef}
              onChange={(e) => onFieldValueUpdated('username', e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && onLogin({ username, password, isPrivateComputer })}
            />
            {username && enableUserSwitching && !userSwitchInProgress && (
              <span className={Styles.actionDecorator} onClick={() => onSwitchUser()}>
                <i className="fa fa-times-circle" />
              </span>
            )}
          </div>

          {/* Password field */}
          <div className={Styles.formGroup}>
            <span className={Styles.decorator}>
              <i className="fa fa-fw fa-lock" />
            </span>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => onFieldValueUpdated('password', e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && onLogin({ username, password, isPrivateComputer })}
            />
          </div>

          {/* Private computer field */}
          <div className={Styles.privateComputerFormGroup}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor="isPrivateComputer"
              onClick={(e) => {
                e.preventDefault();
                if (!formDisabled && !loginInProgress) {
                  onFieldValueUpdated('isPrivateComputer', !isPrivateComputer);
                }
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  if (!formDisabled && !loginInProgress) {
                    onFieldValueUpdated('isPrivateComputer', !isPrivateComputer);
                  }
                }
              }}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
            >
              <SlidingToggle
                id="isPrivateComputer"
                scope="isPrivateComputer"
                selected={isPrivateComputer}
                disabled={formDisabled || loginInProgress}
              />
              <div className={Styles.privateComputerText}>This is a private computer</div>
            </label>
            <span className={Styles.actionDecorator} title={privateComputerTooltip}>
              <ContextMenu
                // eslint-disable-next-line react/no-unstable-nested-components
                body={() => <div className={Styles.privateComputerTooltip}>{privateComputerTooltip}</div>}
                position="bottom"
                tabIndex={0}
              >
                <i className="fa fa-question-circle" />
              </ContextMenu>
            </span>
          </div>

          {/* Error message display */}
          {errorMessages.length > 0 && (
            <div className={Styles.errors}>
              {errorMessages.map(({ message, isVerbose }, index) => (
                <div
                  key={index}
                  className={classnames(
                    'error-box',
                    'alert',
                    'alert-danger',
                    isVerbose ? Styles.leftText : Styles.centreText,
                  )}
                >
                  {message}
                </div>
              ))}
            </div>
          )}

          {/* Login button */}
          <Button
            className={Styles.loginButton}
            onClick={() => onLogin({ username, password, isPrivateComputer })}
            disabled={formDisabled || loginInProgress || userSwitchInProgress}
            locked={loginInProgress}
          >
            {loginButtonText({ loginInProgress, userSwitchInProgress })}
          </Button>

          {/* Links section */}
          <div className={Styles.linksSection}>
            {/* Forgot password link */}
            {showUpdatePaymentMethod && (
              <div className={Styles.showUpdatePaymentMethod}>
                <Button type={buttonTypes.link} onClick={() => onUpdatePaymentMethod()}>
                  Update Payment Details
                </Button>
              </div>
            )}

            {/* Forgot password link */}
            <div className={Styles.forgotPassword}>
              <a href={forgotPasswordUrl}>Forgot Password?</a>
            </div>
          </div>
        </fieldset>
      </div>
    );
  },
);

LoginForm.displayName = 'LoginForm';

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isPrivateComputer: PropTypes.bool.isRequired,
  privateComputerTooltip: PropTypes.string.isRequired,
  disableUserField: PropTypes.bool,
  enableUserSwitching: PropTypes.bool,
  formDisabled: PropTypes.bool,
  loginInProgress: PropTypes.bool.isRequired,
  forgotPasswordUrl: PropTypes.string.isRequired,
  showUpdatePaymentMethod: PropTypes.bool.isRequired,
  userSwitchInProgress: PropTypes.bool.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      isVerbose: PropTypes.bool,
    }),
  ),
  onSwitchUser: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onUpdatePaymentMethod: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  disableUserField: false,
  enableUserSwitching: false,
  formDisabled: false,
  errorMessages: [],
};
