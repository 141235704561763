import React from 'react';
import PropTypes from 'prop-types';
import { entryTypeMap } from '@sb-billing/business-logic/shared/entities';
import { SlidingToggle, forms2PropTypes, Button, Spinner, useTranslation } from '@sb-itops/react';
import { Table, Column } from '@sb-itops/react/table';
import classnames from 'classnames';
import Styles from './FirmUtbmsSettings.module.scss';
import { AddEditCustomTaskCode } from '../add-edit-custom-task-code';

const mapABACodesLabel = {
  isABALitigation: 'ABA Litigation',
  isABABankruptcy: 'ABA Bankruptcy',
  isABAProject: 'ABA Project',
  isABACounseling: 'ABA Counseling',
};

export const FirmUtbmsSettings = React.memo(
  ({
    // state
    currentCustomTaskCode,
    showAddEditCustomTaskCodeCard,
    editCustomTaskCodeId,
    // func
    closeAddEditCustomTaskCodeCard,
    addCustomTaskCode,
    editCustomTaskCode,
    // form fields
    utbmsCodesRequired,
    isUtbmsEnabled,
    ledesFirmId,
    utbmsCodesets,
    // form
    formInitialised,
    submitFailed,
    formSubmitting,
    onFieldValueUpdated,
    onSave,
    // custom task codes list
    customTaskCodes,
  }) => {
    const { t } = useTranslation();

    mapABACodesLabel.isABAWorkersCompensation = "ABA Workers' Compensation";

    if (!formInitialised) {
      return null;
    }

    const isError = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
    const disableUtbmsFields = !isUtbmsEnabled?.value || formSubmitting;

    return (
      <div className={Styles.firmUtbmsSettings}>
        {showAddEditCustomTaskCodeCard ? (
          <AddEditCustomTaskCode
            currentCustomTaskCode={currentCustomTaskCode}
            customTaskCodeId={editCustomTaskCodeId}
            onClose={closeAddEditCustomTaskCodeCard}
          />
        ) : (
          <>
            <div className={Styles.utbmsSettingsToggleSection}>
              <label className={Styles.toggle}>
                <SlidingToggle
                  scope="show-closed-trust-accounts"
                  onChange={(name, value) => onFieldValueUpdated({ isUtbmsEnabled: value })}
                  disabled={formSubmitting}
                  selected={isUtbmsEnabled?.value}
                />
                <h4 className={Styles.toggleLabel}>Enable UTBMS Billing</h4>
              </label>
            </div>

            <div className={Styles.utbmsSettingsSection}>
              <h4>General Settings</h4>
              <label>LEDES Firm ID</label>
              <input
                name="ledesFirmId"
                type="text"
                className={classnames('form-control', Styles.ledesFirmIdInput, isError(ledesFirmId))}
                value={ledesFirmId.value || ''}
                disabled={disableUtbmsFields}
                onChange={(e) => {
                  onFieldValueUpdated({ ledesFirmId: e.target.value });
                }}
              />
              {ledesFirmId.isInvalid && <div className={Styles.errorMessage}>{ledesFirmId.invalidReason}</div>}

              <div className={Styles.requireUtbmsCodeSwitch}>
                <label className={Styles.toggle}>
                  <SlidingToggle
                    scope="require-utbms-code"
                    onChange={(name, value) => onFieldValueUpdated({ utbmsCodesRequired: value })}
                    selected={utbmsCodesRequired?.value}
                    disabled={disableUtbmsFields}
                  />
                  <span className={Styles.toggleLabel}>
                    Require Activity/Task codes for fees and expenses on UTBMS matters &nbsp;
                  </span>
                </label>
                <i
                  title="Applies to manually entered time only. Does not affect automatically created time entries."
                  className="icon icon-question-mark"
                  color="lightgrey"
                />
              </div>
            </div>

            <div className={Styles.utbmsSettingsSection}>
              <h4>Code Sets</h4>
              {Object.entries(mapABACodesLabel).map(([key, label]) => {
                const formFieldKey = `utbmsCodesets.${key}`;
                return (
                  <div className={Styles.codeSetsSwitches} key={key}>
                    <label className={Styles.toggle}>
                      <SlidingToggle
                        scope={key}
                        onChange={(name, value) => onFieldValueUpdated({ [formFieldKey]: value })}
                        selected={utbmsCodesets[key]?.value}
                        disabled={disableUtbmsFields}
                      />
                      <span className={Styles.toggleLabel}>{label}</span>
                    </label>
                  </div>
                );
              })}
              {isUtbmsEnabled.isInvalid && <div className={Styles.errorMessage}>{isUtbmsEnabled.invalidReason}</div>}
            </div>

            <div className={Styles.utbmsSettingsSection}>
              <h4>Custom Task Codes</h4>
              <div className={Styles.addTaskCode}>
                <div
                  className={classnames('link-button', disableUtbmsFields && Styles.disabledLink)}
                  onClick={isUtbmsEnabled?.value ? () => addCustomTaskCode() : () => {}}
                >
                  <i className="icon icon-add-3" />
                  Add Task Code
                </div>
              </div>
              <div className={classnames(Styles.taskCodeWrapper, 'form-group')}>
                <Table
                  style={{
                    height: `${30 + (isUtbmsEnabled?.value ? customTaskCodes.length * 38 : 0)}px`,
                  }}
                  onRowClick={({ rowData }) =>
                    disableUtbmsFields ? () => {} : editCustomTaskCode({ id: rowData.id, customTaskCodes })
                  }
                  list={isUtbmsEnabled?.value ? customTaskCodes : []}
                >
                  <Column dataKey="code" label="Code" flexGrow={2} />
                  <Column dataKey="description" label="Description" flexGrow={6} />
                  <Column
                    dataKey="type"
                    label="Type"
                    flexGrow={2}
                    cellRenderer={({ rowData }) => t(entryTypeMap[rowData.entryType])}
                  />
                  <Column dataKey="isBillable" label="Billable?" flexGrow={1} cellRenderer={isBillableRenderer} />
                </Table>
                {!isUtbmsEnabled?.value && (
                  <div className={Styles.noCustomTaskCodes}>
                    <span>Enable UTBMS billing to use custom task codes</span>
                  </div>
                )}
                {isUtbmsEnabled?.value && (!customTaskCodes || customTaskCodes.length < 1) && (
                  <div className={Styles.noCustomTaskCodes}>
                    <span>No codes have been added</span>
                  </div>
                )}
              </div>
            </div>
            <Button disabled={formSubmitting} onClick={onSave}>
              Save {formSubmitting && <Spinner small />}
            </Button>
          </>
        )}
      </div>
    );
  },
);

const isBillableRenderer = ({ rowData }) =>
  rowData.isBillable ? (
    <div className={Styles.isBillable}>
      <i className="billable-icon" />
    </div>
  ) : null;

FirmUtbmsSettings.displayName = 'FirmUtbmsSettings';

const { Forms2Field } = forms2PropTypes;

FirmUtbmsSettings.propTypes = {
  // state
  currentCustomTaskCode: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    isBillable: PropTypes.bool,
  }),
  showAddEditCustomTaskCodeCard: PropTypes.bool,
  editCustomTaskCodeId: PropTypes.string,
  closeAddEditCustomTaskCodeCard: PropTypes.func.isRequired,
  addCustomTaskCode: PropTypes.func.isRequired,
  editCustomTaskCode: PropTypes.func.isRequired,
  // form fields
  isUtbmsEnabled: Forms2Field,
  ledesFirmId: Forms2Field,
  utbmsCodesets: Forms2Field,
  utbmsCodesRequired: Forms2Field,
  // form
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // custom task codes list
  customTaskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      isBillable: PropTypes.bool,
    }),
  ),
};

FirmUtbmsSettings.defaultProps = {
  // state
  currentCustomTaskCode: undefined,
  showAddEditCustomTaskCodeCard: false,
  editCustomTaskCodeId: undefined,
  // form fields
  isUtbmsEnabled: undefined,
  ledesFirmId: undefined,
  utbmsCodesets: {},
  customTaskCodes: [],
  utbmsCodesRequired: undefined,
};
