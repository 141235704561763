import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '../spinner';
import Styles from './InputWithLoadingState.module.scss';

const InputWithLoadingState = ({
  className,
  containerClassName,
  disabled,
  hasError,
  isLoading,
  maxLength,
  onBlur,
  onChange,
  placeholder,
  value,
}) => (
  <div className={classnames(Styles.container, containerClassName)}>
    <input
      className={classnames('form-control', className, hasError && Styles.hasError)}
      disabled={disabled}
      maxLength={maxLength}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      value={value}
    />
    {isLoading && (
      <span className={Styles.inputSpinner}>
        <Spinner small />
      </span>
    )}
  </div>
);

InputWithLoadingState.displayName = 'InputWithLoadingState';

InputWithLoadingState.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

InputWithLoadingState.defaultProps = {
  className: undefined,
  containerClassName: undefined,
  disabled: undefined,
  hasError: undefined,
  maxLength: undefined,
  onBlur: undefined,
  placeholder: undefined,
};

export { InputWithLoadingState };
