import composeHooks from '@sb-itops/react-hooks-compose';
import { FeeEntriesChartData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { useState } from 'react';
import moment from 'moment';
import { FeeEntriesChartDataDocument } from 'web/graphql/types/graphql';
import { BillableGraphWidget } from './BillableGraphWidget';

interface Props {
  isEditing: boolean;
  onChange: Function;
  onRemove: Function;
  onClickLink: React.MouseEventHandler;
  settings: any;
  matterId: string;
}

const hooks = ({ matterId }: Props) => ({
  useGraphQLforRecentMatters: () => {
    const staffIds = [getLoggedInStaff()?.id];
    const [selectedMonth, onMonthChange] = useState(moment());

    const chartFeesQueryResult = useSubscribedQuery(
      { ...FeeEntriesChartData, query: FeeEntriesChartDataDocument },
      {
        variables: {
          feeFilter: {
            feeDate: {
              from: +selectedMonth.startOf('month').format('YYYYMMDD'),
              to: +selectedMonth.endOf('month').format('YYYYMMDD'),
            },
            matterIds: matterId ? [matterId] : undefined,
            feeEarnerStaffIds: staffIds,
            includeStatus: {
              current: true,
            },
          },
          sort: { fieldNames: ['feeDate'], directions: ['ASC'] },
        },
      },
    );

    if (chartFeesQueryResult.error) {
      throw new Error(`${chartFeesQueryResult.error}`);
    }

    const chartFees = chartFeesQueryResult.data?.feeList?.results || [];

    return {
      selectedMonth,
      onMonthChange,
      chartFees,
      loading: chartFees.length === 0 && chartFeesQueryResult.loading,
    };
  },
});

export const BillableGraphWidgetContainer = withApolloClient(composeHooks(hooks)(BillableGraphWidget));
