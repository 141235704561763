import { useState } from 'react';

export const useInvoiceZeroBalance = () => {
  const [isZeroBalanceInvoiceVisible, setIsZeroBalanceInvoiceVisible] = useState(false);
  const [zeroBalanceInvoiceCount, setZeroBalanceInvoiceCount] = useState(1);
  const hideZeroBalanceModal = () => setIsZeroBalanceInvoiceVisible(false);
  const showZeroBalanceModal = (invoiceCount = 1) => {
    setIsZeroBalanceInvoiceVisible(true);
    setZeroBalanceInvoiceCount(invoiceCount);
  };

  return {
    isZeroBalanceInvoiceVisible,
    zeroBalanceInvoiceCount,
    hideZeroBalanceModal,
    showZeroBalanceModal,
  };
};
