'use strict';

const { sortByOrder } = require('@sb-itops/nodash');

/**
 * Returns the most recently used cheque number
 * @param {Array.<object>} cheques
 * @returns {string|undefined}
 */
const findLastUpdatedChequeNumber = (cheques = []) => {
  const chequesWithNumbers = cheques.filter((c) => c.chequeNumber);
  if (!cheques.length) {
    return undefined;
  }
  const sorted = sortByOrder(chequesWithNumbers, ['lastUpdated', 'chequeNumber'], ['asc', 'asc']);

  return sorted[sorted.length - 1]?.chequeNumber;
};

module.exports = {
  findLastUpdatedChequeNumber,
};
