import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu } from '@sb-itops/react/context-menu';
import Styles from './InvoiceViewedOnlineStatusIndicator.module.scss';

export const InvoiceViewedOnlineStatusIndicator = ({
  hasBeenViewedOnline,
  viewedPerContactMessage,
  onClickInvoiceViewedOnlineIcon,
}) => {
  if (!hasBeenViewedOnline) {
    return null;
  }

  return (
    <div className={Styles.invoiceViewedOnlineStatusIndicator}>
      <ContextMenu body={() => viewedPerContactMessage}>
        <i className="icon icon-check-1" onClick={onClickInvoiceViewedOnlineIcon} />
      </ContextMenu>
    </div>
  );
};

InvoiceViewedOnlineStatusIndicator.displayName = 'InvoiceViewedOnlineStatusIndicator';

InvoiceViewedOnlineStatusIndicator.propTypes = {
  hasBeenViewedOnline: PropTypes.bool.isRequired,
  viewedPerContactMessage: PropTypes.string.isRequired,
  onClickInvoiceViewedOnlineIcon: PropTypes.func.isRequired,
};

InvoiceViewedOnlineStatusIndicator.defaultProps = {};
