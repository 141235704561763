import { fetchAccessAuthorisationsFromWebP } from '@sb-integration/web-client-sdk';
import { cachePermissions, getCachedPermissions } from './permissions-cache';
import { TPermission } from './types';
import { makePermissionsLookup } from './make-permissions-lookup';

/**
 * Calls permissions to check the resource ids, returns a boolean lookup
 * keyed to the resourceIds.
 *
 * @param { string[] } resourceIds
 * @return { Record<Id, boolean> }
 */
export const fetchPermissionsFromWebP = async (resourceIds): Promise<Record<string, boolean>> => {
  const cached = getCachedPermissions(resourceIds);
  if (!cached.misses.length) {
    return makePermissionsLookup(Object.values(cached.hits));
  }

  const permissions: TPermission[] = await fetchAccessAuthorisationsFromWebP(resourceIds);
  cachePermissions(permissions);

  return makePermissionsLookup(permissions);
};
