/* eslint-disable no-param-reassign */

'use strict';

/**
 * These expense total are output totals for an invoice (used on the draft invoice screen)
 */
const calculateExpenseTotals = (expenses = []) => {
  const expenseTotals = expenses.reduce(
    (total, expense) => {
      const { isBillable, amountIncludesTax, amount, tax = 0, outputTax, waived } = expense;
      if (isBillable) {
        const finalOutputTax = outputTax ?? tax;
        const amountExclTax = amountIncludesTax ? amount - tax : amount;

        total.amountExclTax += waived ? 0 : amountExclTax;
        total.tax += waived ? 0 : finalOutputTax;
        total.writtenOff += waived ? amountExclTax : 0;
      }
      return total;
    },
    {
      amountExclTax: 0,
      writtenOff: 0,
      tax: 0,
    },
  );
  return expenseTotals;
};

module.exports = { calculateExpenseTotals };
