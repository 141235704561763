import * as React from 'react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('usePrintCorrespondenceHistoryPdf');

export function usePrintCorrespondenceHistoryPdf() {
  const [isLoadingCorrespondenceHistoryPdf, setIsLoadingCorrespondenceHistoryPdf] = React.useState(false);

  async function printCorrespondenceHistoryPdf({ filters }) {
    try {
      setIsLoadingCorrespondenceHistoryPdf(true);
      const response = await fetchPostP({
        path: '/billing/correspondence-history/export/:accountId/',
        fetchOptions: { body: JSON.stringify({ fileFormat: 'pdf', ...filters }) },
      });
      onCorrespondenceHistoryPrintDialogOpen(response.body.exportFile);
    } catch (error) {
      log.error('Failed to print client communications history PDF due to error: ', error);
      displayErrorToUser('Failed to print client communications history PDF. Please try again later.');
    } finally {
      setIsLoadingCorrespondenceHistoryPdf(false);
    }
  }

  return {
    isLoadingCorrespondenceHistoryPdf,
    printCorrespondenceHistoryPdf,
  };
}

function onCorrespondenceHistoryPrintDialogOpen(exportFile) {
  const id = 'correspondenceHistoryPdf';
  const iframe = document.frames ? document.frames[id] : document.getElementById(id);
  if (iframe) {
    const iframeDocument = iframe.contentWindow.document;
    iframeDocument.open('text/html', 'replace');
    iframeDocument.write(exportFile);
    iframeDocument.close();
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 0);
  }
}
