'use strict';

const { isPaymentProviderEnabledForBankAccount } = require('./is-payment-provider-enabled-for-bank-account');
const { paymentMethods } = require('./payment-methods');

/**
 * isFirmCardSavingEnabledForBankAccount
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings Lawpay settings as configured in firm settings
 * @param {string} params.bankAccountId The bank account ID to check for Lawpay card saving availability.
 * @param {string} params.bankAccountType The bank account type to check for Lawpay payment availability.
 * @param {('CREDIT_CARD'|'ECHEQUE')} [params.paymentMethod] Specific payment method
 * @returns {boolean}
 */
const isFirmCardSavingEnabledForBankAccount = ({ formattedProviderSpecificSettings, bankAccountId, bankAccountType }) =>
  isPaymentProviderEnabledForBankAccount({
    formattedProviderSpecificSettings,
    bankAccountId,
    bankAccountType,
    providerSpecificFields: {
      paymentMethod: paymentMethods.CREDIT_CARD,
    },
  });

module.exports = {
  isFirmCardSavingEnabledForBankAccount,
};
