'use strict';

const { validateTypeLabelsAndValues } = require('./validate-type-labels-and-values');

const getTypeByValue = ({ typeLabels, typeValues }) => {
  validateTypeLabelsAndValues({ typeLabels, typeValues });
  const keys = Object.keys(typeValues);
  const typeByValue = keys.reduce((acc, key) => {
    acc[typeValues[key]] = typeLabels[key];
    return acc;
  }, {});
  return typeByValue;
};

module.exports = {
  getTypeByValue,
};
