'use strict';


import {
  getList,
  updateCache as updateRedux,
} from '@sb-billing/redux/trust-receipt-settings';
import { getTrustAccount } from '@sb-billing/redux/bank-account';


angular.module('@sb-billing/services').service('sbTrustReceiptSettingsService', function (sbLoggerService, sbGenericEndpointService, sbGenericCacheService, sbEndpointType, sbUuidService) {
  const that = this,
    log = sbLoggerService.getLogger('sbTrustReceiptSettingsService'),
    endpoint = 'billing/trust-receipt/settings',
    subscriptions = 'notifier.AccountsNotifications.ReceiptSettingsUpdated';

  sbGenericCacheService.setupCache({
    name: 'sbTrustReceiptSettingsService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: endpoint},
      poll: 60,
      subscriptions
    },
    updateRedux,
  });

  that.get = function () {
    return getList();
  };

  that.getTrustReceiptSettings = function() {
    const bankAccountId = getTrustAccount().id;
    return _.find(that.get(), 'bankAccountId', bankAccountId);
  };

  that.saveP = saveP;

  that.getLastReceiptNumberP = getLastReceiptNumberP;

  function getLastReceiptNumberP(bankAccountId, nextReceiptNumber) {
    return sbGenericEndpointService.getPayloadP(`${endpoint}/next-number`, `${encodeURIComponent(bankAccountId)}/${nextReceiptNumber}`, {skipCamelCase: true})
      .then(receiptNumbers => {
        if (_.isEmpty(receiptNumbers)) {
          return null;
        }
        return _.chain(receiptNumbers).sortBy(['receiptNumber']).last().value();
      });
  }

  function saveP(data) {
    data.versionId = sbUuidService.get();
    log.info(`saving trust receipt settings: ${JSON.stringify(data, null, 2)} `);
    return sbGenericEndpointService.postPayloadP(endpoint, encodeURIComponent(data.bankAccountId), data, 'POST', {changeset: {sbTrustReceiptSettingsService: [data]}});
  }

});
