import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { MattersDisplayFromMatters } from '@sb-matter-management/react/matters-display';
import { useTranslation } from '@sb-itops/react';
import { transactionTypes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

import { FeeWiseTransactionDetailsModalContainer } from './FeeWiseTransactionDetailsModal.container';
import Styles from './FeeWiseTransactionTable.module.scss';
import { bankAccountName } from './bank-account-name';
import { paymentMethodName } from './payment-method-name';

const { balanceCellLocalisedRenderer, timestampLocalisedRenderer } = utils;

export const FeeWiseTransactionTable = ({
  onClickLink,
  transactions,
  dataLoading,
  transactionCount,

  // sort
  onSort,
  sortBy,
  sortDirection,
}) => {
  const { t } = useTranslation();
  const [transactionDetail, setTransactionDetail] = useState(undefined);

  const onMatterClick =
    ({ rowData }) =>
    (args) => {
      if (rowData.bankAccount && rowData.bankAccount.accountType === bankAccountTypeEnum.TRUST) {
        onClickLink({
          type: 'matterTransactions',
          id: 'trust',
          params: [{ matterId: args.id, trustAccountId: rowData.bankAccount.id }],
        });
      } else if (rowData.bankAccount && rowData.bankAccount.accountType === bankAccountTypeEnum.OPERATING) {
        const isDeposit = rowData.transactionType === transactionTypes.DEPOSIT;
        onClickLink({
          type: 'matterTransactions',
          id: isDeposit ? 'operating' : 'ledger',
          params: [{ matterId: args.id }],
        });
      } else {
        // pass through if we don't have bank account Id
        onClickLink(args);
      }
    };

  const mattersCellRenderer = ({ cellData, rowData }) => {
    const matters = cellData;
    if (!Array.isArray(matters) || !matters.length) {
      return undefined;
    }

    return (
      <MattersDisplayFromMatters
        matters={matters}
        asLink
        onClickLink={onMatterClick({ rowData })}
        className={Styles.flex}
      />
    );
  };

  return (
    <>
      <div className={Styles.gridLabelHeader}>
        Transactions {dataLoading ? null : <span>({transactionCount})</span>}
      </div>
      {!!transactionDetail && (
        <FeeWiseTransactionDetailsModalContainer
          isVisible
          onClose={() => setTransactionDetail(undefined)}
          transactionDetail={transactionDetail}
          onMatterClick={onMatterClick({ rowData: transactionDetail })}
        />
      )}
      <Table
        className={Styles.fwTransactionTable}
        onRowClick={({ rowData }) => setTransactionDetail(rowData)}
        dataLoading={dataLoading}
        list={transactions}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column dataKey="date" label="Date" cellRenderer={timestampLocalisedRenderer} flexGrow={1} />
        <Column dataKey="reference" label="Reference" flexGrow={1} />
        <Column dataKey="matters" label="Matter" disableSort flexGrow={2} cellRenderer={mattersCellRenderer} />
        {/* We allow sort by payment method because even though we show the values "Card" and "DirectDebit"
          as "Credit Card" and "eCheck", alphabetically it is same so sort still works */}
        <Column
          dataKey="customerPaymentMethod"
          label="Method"
          flexGrow={0.5}
          cellRenderer={({ cellData }) => paymentMethodName({ paymentMethod: cellData.paymentMethod })}
        />

        <Column
          dataKey="bankAccount"
          label="Account"
          disableSort
          flexGrow={1}
          cellRenderer={({ cellData }) => bankAccountName({ bankAccount: cellData, t })}
        />
        <Column dataKey="status" label="Status" flexGrow={0.5} />
        <Column
          className="right-align"
          dataKey="amount"
          label="Amount"
          flexGrow={0.5}
          cellRenderer={balanceCellLocalisedRenderer}
        />
      </Table>
    </>
  );
};

FeeWiseTransactionTable.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  transactionCount: PropTypes.number.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      reference: PropTypes.string,
      transactionCount: PropTypes.string,
      status: PropTypes.string,
      totalAmount: PropTypes.number,
      account: PropTypes.string,
    }),
  ).isRequired,

  // sort
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
};

FeeWiseTransactionTable.defaultProps = {
  sortBy: 'date',
  sortDirection: 'desc',
  onSort: undefined,
};
