angular.module('sb.billing.webapp').directive('sbActionDeleteDepositSlip', function (sbLoggerService, sbDepositSlipService, sbMessageDisplayService) {
  'use strict';

  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      const log = sbLoggerService.getLogger('sbActionDeleteDepositSlip');

      ctrl.group.setAction(deleteDepositSlip);
      // ctrl.group.setAction(deleteDepositSlip, isDeleteable);

      function deleteDepositSlip(id) {
        log.info('delete deposit slip id:', id);
        ctrl.group.setLock(true);

        return sbDepositSlipService.deleteP(id)
          .then(function () {
            ctrl.group.setLock(false);
          })
          .catch(function (err) {
            ctrl.group.setLock(false);
            log.error('Failed to delete deposit slip', err);

            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text('Failed to delete deposit slip')
                .conditionalText(': {0}', err.message)
            );

            throw err;
          });
      }

      // function isDeleteable() {
      //   return draft && draft.invoiceVersion && draft.invoiceId; // invoiceId indicates it's saved and is not just in memory
      // }
    }
  };
});
