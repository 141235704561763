import moment from 'moment';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import { getBalanceType } from '../bank-account-settings';
import {
  buildOpdates as buildVendorPaymentOpdates,
  opdateCache as opdateVendorPaymentCache,
  rollbackOpdateCache as rollbackVendorPaymentOpdateCache,
} from '../vendor-payments';
import {
  buildBankAccountBalanceOpdatesForVendorPayment,
  opdateCache as opdateBankAccountBalancesCache,
  rollbackOpdateCache as rollbackBankAccountBalancesOpdateCache,
  getMap,
} from '../bank-account-balances';
import { selectors } from '../bank-account-balances.2';
import { getOperatingAccount, getTrustAccount } from '.';

const { getFirmBankAccount } = selectors;

const getAccountId = () => authSelectors.getAccountId(store.getState());

export const payToVendor = (payment) => {
  const path = `/billing/pay-to-vendor/:accountId/`;

  const opdateCaches = () => {
    const vendorPaymentOpdates = buildVendorPaymentOpdates(payment, getAccountId(), moment());

    // BankAccountBalances records are only created upon the first successful transaction, getFirmBankAccount
    // selector will return a default BankAccountBalances record if none exists yet
    let bankAccountBalanceOpdates = [];
    let bankAccountId;
    // Preferred way is to specify bankAccountId directly but we use fallback if it doesnt exist
    // Should be always passed for CMA and MTA transactions
    if (payment.bankAccountId) {
      bankAccountId = payment.bankAccountId;
    } else if (payment.accountType === 'Trust') {
      bankAccountId = getTrustAccount().id;
    } else if (payment.accountType === 'Operating') {
      bankAccountId = getOperatingAccount().id;
    }

    if (bankAccountId) {
      const bankAccountBalances = getFirmBankAccount(getMap(), { bankAccountId });
      bankAccountBalanceOpdates = buildBankAccountBalanceOpdatesForVendorPayment({
        payment,
        bankAccountBalances,
        bankBalanceType: getBalanceType(),
      });
    }

    opdateVendorPaymentCache({ optimisticEntities: vendorPaymentOpdates });
    opdateBankAccountBalancesCache({ optimisticEntities: bankAccountBalanceOpdates });

    return () => {
      rollbackVendorPaymentOpdateCache({ optimisticEntities: vendorPaymentOpdates });
      rollbackBankAccountBalancesOpdateCache({ optimisticEntities: bankAccountBalanceOpdates });
    };
  };

  return fetchPostThunkFactory({
    path,
    opdateCaches,
  })(payment);
};
