import PropTypes from 'prop-types';
import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withOnLoad } from '@sb-itops/react/hoc';
import { getLogger } from '@sb-itops/fe-logger';
import { dateToInteger } from '@sb-itops/date';
import { useSelector, useDispatch } from 'react-redux';
import {
  isFirmCardSavingEnabledForBankAccount,
  getActiveProvider,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import * as messageDisplay from '@sb-itops/message-display';
import { getMap as getAllInvoicesMap } from '@sb-billing/redux/invoices';
import { getList as getAllInvoiceTotals } from '@sb-billing/redux/invoice-totals';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import {
  savePaymentPlan as savePaymentPlanP,
  getById as getPaymentPlanById,
  getList as getAllPaymentPlans,
} from '@sb-billing/redux/payment-plans';
import {
  findMatterIdsWithUnpaidBalanceForDebtorAsMap,
  findDebtorIdsWithUnpaidBalanceAndNotOnPaymentPlan,
} from 'web/redux/selectors/payment-plans';
import { getById as getCustomerBillingConfigByContactId } from '@sb-billing/redux/customer-billing-configuration';
import { saveCard } from '@sb-billing/redux/payment-provider';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { getRegion, regionType } from '@sb-itops/region';
import { featureActive } from '@sb-itops/feature';
import { paymentPlanModalDialogFeature } from './payment-plan-modal-dialog-feature';
import PaymentPlanModalDialog from './PaymentPlanModalDialog';
import { paymentPlanSchema } from './payment-plan-schema';
import { buildInclusionsList } from './build-inclusions-list';

const logger = getLogger('web/react-redux/PaymentPlanModalDialogContainer');
logger.setLogLevel('info');

const cardAutoChargeAllowedStrategyFn = {
  [regionType.US]: () => hasFacet(facets.allowAutoChargeCreditCard),
  // In AU/UK we check FeeWise (SP Pay) feature switch as well to allow for gradual release of SB Pay AU/UK.
  // Card autocharge will be introduced to AU/UK together with SB Pay so it depends on it
  [regionType.AU]: () => hasFacet(facets.allowAutoChargeCreditCard) && featureActive('BB-12038'),
  [regionType.GB]: () => hasFacet(facets.allowAutoChargeCreditCard) && featureActive('BB-12038'),
};

const isNextButtonEnabled = (
  { debtor, installmentAmount, installmentFrequency, nextInstallmentDate, paymentMethod },
  { showCreditCardForm },
) =>
  debtor &&
  debtor.isValid &&
  installmentAmount &&
  installmentAmount.isValid &&
  installmentFrequency &&
  installmentFrequency.isValid &&
  nextInstallmentDate &&
  nextInstallmentDate.isValid &&
  paymentMethod &&
  paymentMethod.isValid &&
  paymentMethod.value &&
  !showCreditCardForm;

const marshallNewPaymentPlan = (paymentPlan = {}) => ({
  id: paymentPlan.id,
  debtorId: paymentPlan.debtor,
  matterIds:
    paymentPlan.inclusions &&
    Object.entries(paymentPlan.inclusions).reduce((acc, [matterId, selected]) => {
      if (selected) {
        acc.push(matterId);
      }
      return acc;
    }, []),
  nextInstallmentDate: +paymentPlan.nextInstallmentDate,
  installmentFrequency: paymentPlan.installmentFrequency,
  installmentAmount: paymentPlan.installmentAmount,
  paymentMethodId: paymentPlan.paymentMethod,
});

const getNewPaymentPlanCandidateMatters = ({ debtorId }) => {
  // get a map of matters with unpaid balance for specified debtor, this could include
  // 1) single debtor invoices (which could be included in payment plan)
  // 2) multi-debtor invoices (which can't be included in payment plans but needs to be returned)
  const paymentPlanMatterIdMap = findMatterIdsWithUnpaidBalanceForDebtorAsMap(
    {
      allInvoicesMap: getAllInvoicesMap(),
      allInvoiceTotals: getAllInvoiceTotals(),
    },
    { debtorId },
  );

  // return full list of candidate matters for payment plan
  const paymentPlanMatters = Object.keys(paymentPlanMatterIdMap).map((matterId) => {
    const canIncludeInPaymentPlan = paymentPlanMatterIdMap[matterId];
    const matter = getMatterById(matterId);
    return {
      ...matter,
      canIncludeInPaymentPlan,
    };
  });
  return paymentPlanMatters;
};

const getExistingPaymentPlanCandidateMatters = ({ paymentPlan }) => {
  const debtorId = paymentPlan.debtorId;

  // get a map of matters with unpaid balance for specified debtor, this could include
  // 1) single debtor invoices (which could be included in payment plan)
  // 2) multi-debtor invoices (which can't be included in payment plans but needs to be returned)
  const paymentPlanMatterIdMap = findMatterIdsWithUnpaidBalanceForDebtorAsMap(
    {
      allInvoicesMap: getAllInvoicesMap(),
      allInvoiceTotals: getAllInvoiceTotals(),
    },
    { debtorId },
  );

  // make sure matters that were in original payment plan inclusions is also included
  paymentPlan.matterIds.forEach((matterId) => {
    paymentPlanMatterIdMap[matterId] = true; // mark matter as could be included in payment plan
  });

  // return full list of candidate matters for payment plan
  const paymentPlanMatters = Object.keys(paymentPlanMatterIdMap).map((matterId) => {
    const canIncludeInPaymentPlan = paymentPlanMatterIdMap[matterId];
    const matter = getMatterById(matterId);
    return {
      ...matter,
      canIncludeInPaymentPlan,
    };
  });
  return paymentPlanMatters;
};

const findNextInstallmentDate = (installments) => {
  const todayAsInteger = dateToInteger(new Date());
  const next = installments.find((installment) => installment.date >= todayAsInteger);
  return next ? String(next.date) : `${todayAsInteger}`;
};

const debtorIdsOwingMoneyButNotOnPaymentPlanTypeAhead = (selectedDebtorId) => {
  const debtorIdsOwingMoneyButNotOnPaymentPlan = findDebtorIdsWithUnpaidBalanceAndNotOnPaymentPlan(
    {
      allInvoicesMap: getAllInvoicesMap(),
      allInvoiceTotals: getAllInvoiceTotals(),
      allPaymentPlans: getAllPaymentPlans(),
    },
    {
      exclude: {
        debtorIds: (selectedDebtorId && [selectedDebtorId]) || [],
      },
    },
  );

  // also include the selected debtor id to handle edit payment plan scenario
  // selectedDebtorId need to go first in the list since there is a bug in the select component
  // that do not show the selected option in the list. We will treat that bug separately.
  // once this is fixed, remove the exclude condition passed into findDebtorIdsWithUnpaidBalanceAndNotOnPaymentPlan
  const debtorIdsToShowInList = selectedDebtorId
    ? [selectedDebtorId, ...debtorIdsOwingMoneyButNotOnPaymentPlan]
    : debtorIdsOwingMoneyButNotOnPaymentPlan;

  // fetch debtors and filter out any debtors that are not found in cache due to old test data
  const contacts = getContactTypeAheadSummaries().filter(
    (contact) => contact !== undefined && debtorIdsToShowInList.includes(contact.id),
  );

  return contacts;
};

const getPaymentMethods = ({ debtorId, activeProvider, selectedPaymentMethodId }) => {
  const cBconfig = getCustomerBillingConfigByContactId(debtorId);
  // We display only cards relevant to active provider AND card for inactive provider if it currently selected
  return (cBconfig?.paymentMethods || []).reduce((acc, card) => {
    if (card.provider === activeProvider || card.token === selectedPaymentMethodId) {
      const [month, year] = card.expiry.split('/');
      const expirationYear = year.length === 2 ? year : year.slice(-2); // only 2 digits year
      const expirationMonth = month.padStart(2, '0');

      const label = `${card.description || ''} ${card.display} ${expirationMonth}/${expirationYear} ${
        card.provider !== activeProvider ? '(Deactivated)' : ''
      }`;
      acc.push({
        label,
        value: card.token,
      });
    }

    return acc;
  }, []);
};

const hooks = ({
  paymentPlanModalId,
  modalDialogTitle,
  selectedPaymentPlanId,
  contactId,
  includedMatterIds,
  onNewPaymentPlanCreated,
}) => ({
  useRedux: () => {
    const {
      tabs: {
        selectors: { getSelectedTab },
        actions: { setSelectedTab, clearSelectedTab },
      },
      forms: {
        selectors: { getFieldValues, getFormFields, getFormState },
        actions: { initialiseForm, updateFieldValues, clearForm },
        operations: { validateSchema, submitFormP },
      },
    } = useSelector(paymentPlanModalDialogFeature);

    const dispatch = useDispatch();

    const currentTabName = getSelectedTab();

    const formState = useSelector(getFormState);
    const formFields = getFormFields();
    const fieldValues = getFieldValues();

    const backButtonVisible = currentTabName !== 'plan-details' && currentTabName !== undefined;
    const nextButtonVisible = currentTabName !== 'inclusions';

    // debtor id selected in form if user changed dropdown selection, otherwise default
    // to the contact passed into the component, there may not be a debtor passed e.g.
    // when this modal is opened from Debtors with Payment Plans page under Contacts
    const debtorId = fieldValues.debtor || contactId;

    const selectedPaymentPlan = selectedPaymentPlanId ? getPaymentPlanById(selectedPaymentPlanId) : null;
    const candidateMattersForPaymentPlan = selectedPaymentPlanId
      ? getExistingPaymentPlanCandidateMatters({ paymentPlan: selectedPaymentPlan })
      : getNewPaymentPlanCandidateMatters({ debtorId });

    const selectedTab = getSelectedTab();
    const startingDate =
      selectedPaymentPlan && selectedPaymentPlan.startDate
        ? moment(selectedPaymentPlan.startDate, 'YYYYMMDD').toDate()
        : new Date();
    const candidateMatters = debtorId ? buildInclusionsList(candidateMattersForPaymentPlan, { debtorId }) : [];

    const relatedContacts = debtorIdsOwingMoneyButNotOnPaymentPlanTypeAhead(debtorId);

    const activeProvider = useSelector(getActiveProvider);
    const paymentMethodOptions = getPaymentMethods({
      debtorId,
      activeProvider,
      selectedPaymentMethodId: selectedPaymentPlan && selectedPaymentPlan.paymentMethodId,
    });
    // Add None option for manual payment
    paymentMethodOptions.push({ label: 'None (Manual Payment)', value: 'None' });

    const bankAccountId = getOperatingAccount()?.id;

    // All these fields are needed for the credit card form
    // It's a different complicated pattern because the fields are hosted and not controlled by us
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);
    const [isSubmittingCreditCard, setIsSubmittingCreditCard] = useState(false);
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [isReadyForSubmit, setIsReadyForSubmit] = useState(false);

    const isNewCreditCardEnabled = isFirmCardSavingEnabledForBankAccount({
      providerType: activeProvider,
      bankAccountId,
    });

    return {
      nextButtonEnabled: isNextButtonEnabled(formFields, { showCreditCardForm }),
      creditCardFormProps: {
        isNewCreditCardEnabled,
        allowAutoChargeCreditCard: !!cardAutoChargeAllowedStrategyFn[getRegion()]?.(),
        showCreditCardForm,
        setShowCreditCardForm,
        isSubmittingCreditCard,
        setIsSubmittingCreditCard,
        triggerSubmit,
        setTriggerSubmit,
        isReadyForSubmit,
        setIsReadyForSubmit,
        bankAccountId,
      },
      relatedContacts,
      modalId: paymentPlanModalId,
      backButtonVisible,
      nextButtonVisible,
      saveButtonEnabled: formState.formValid && !formState.formSubmitting,
      modalDialogTitle,
      selectedPaymentPlanId,
      includedMatterIds,
      selectedTab,
      formFields,
      fieldValues,
      startingDate,
      candidateMatters,
      paymentMethodOptions,
      onLoad: () => {
        if (selectedPaymentPlanId) {
          const paymentPlan = getPaymentPlanById(selectedPaymentPlanId);
          const initFieldValues = {
            id: selectedPaymentPlanId,
            debtor: paymentPlan.debtorId,
            installmentAmount: paymentPlan.installmentAmount,
            installmentFrequency: paymentPlan.installmentFrequency,
            nextInstallmentDate: findNextInstallmentDate(paymentPlan.installments),
            inclusions: paymentPlan.matterIds.reduce((acc, matterId) => {
              acc[matterId] = true;
              return acc;
            }, {}),
            paymentMethod: paymentPlan.paymentMethodId || 'None',
          };

          dispatch(initialiseForm({ fieldValues: initFieldValues }));
        } else {
          const initFieldValues = {
            debtor: contactId,
            inclusions:
              includedMatterIds.length > 0
                ? includedMatterIds.reduce((acc, matterId) => {
                    acc[matterId] = true;
                    return acc;
                  }, {})
                : undefined,
            nextInstallmentDate: String(dateToInteger(new Date())),
            // Force users to specify which payment method to use, or none at all
            paymentMethod: hasFacet(facets.allowAutoChargeCreditCard) && isNewCreditCardEnabled ? undefined : 'None',
          };
          const initialiseFormAction = initialiseForm({ fieldValues: initFieldValues });
          dispatch(initialiseFormAction);
        }

        return () => {
          dispatch(clearForm());
          dispatch(clearSelectedTab());
        };
      },
      onPlanDetailsTabClick: () => {
        dispatch(setSelectedTab({ tab: 'plan-details' }));
      },
      onInclusionsTabClick: () => {
        dispatch(setSelectedTab({ tab: 'inclusions' }));
      },
      onFieldValueChange: (field, value) => {
        dispatch(updateFieldValues({ fieldValues: { [field]: value } }));
        dispatch(validateSchema({ schema: paymentPlanSchema }));
      },
      onSaveClick: async () => {
        try {
          const paymentPlan = await dispatch(
            submitFormP({
              submitFnP: (paymentPlanFormData) => savePaymentPlanP(marshallNewPaymentPlan(paymentPlanFormData)),
            }),
          );
          messageDisplay.success('Payment plan was saved successfully.');
          if (!paymentPlan.id) {
            onNewPaymentPlanCreated(paymentPlan.id);
          }
        } catch (ex) {
          messageDisplay.error('Failed to save the payment plan.');
        }
      },
      onAddCreditCard: async (saveCardFormData) => {
        const newCard = await saveCard({
          saveCardFormData,
          providerType: activeProvider,
          contactId: debtorId,
          bankAccountId,
        });
        dispatch(updateFieldValues({ fieldValues: { paymentMethod: newCard.token } }));
        dispatch(validateSchema({ schema: paymentPlanSchema }));
      },
    };
  },
});

const PaymentPlanModalDialogContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(PaymentPlanModalDialog)));

PaymentPlanModalDialogContainer.displayName = 'PaymentPlanModalDialogContainer';

PaymentPlanModalDialogContainer.propTypes = {
  selectedPaymentPlanId: PropTypes.string,
  paymentPlanModalId: PropTypes.string.isRequired,
  modalDialogTitle: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  includedMatterIds: PropTypes.arrayOf(PropTypes.string),
  // callbacks
  onNewPaymentPlanCreated: PropTypes.func.isRequired,
};

PaymentPlanModalDialogContainer.defaultProps = {
  selectedPaymentPlanId: undefined,
  contactId: undefined,
  includedMatterIds: [],
};

export default PaymentPlanModalDialogContainer;
