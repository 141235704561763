import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, StatelessSelect, useTranslation } from '@sb-itops/react';
import { DebugEntityHistory } from 'web/react-redux/components/debug-entity-history';
import { DiffViewer } from 'web/components/diff-viewer';
import Styles from './BillingSupportDataExplorer.module.scss';

export const BillingSupportDataExplorer = ({
  cacheOptions,
  compatibleCachesMap,
  entityData,
  formValues,
  loading,
  onClickLookup,
  onGoToEntity,
  onSetFormValues,
}) => {
  const { t } = useTranslation();
  const dateFormat = t('dateFormat_ddd DD/MM/YYYY hhmm A');

  return (
    <div className={Styles.billingSupportDataExplorer}>
      <div className={Styles.panel}>
        <div>
          <h2>Search</h2>
          <div className="search-inputs">
            <div className="row">
              <div className="col-sm-6">
                <label>Cache</label>
                <StatelessSelect
                  options={cacheOptions}
                  selectedOption={formValues.entityCacheName}
                  onChange={({ value }) => onSetFormValues({ entityCacheName: value })}
                />
              </div>
              <div className="col-sm-6">
                <label>Key</label>
                <input
                  className="form-control"
                  type="text"
                  name="entityId"
                  value={formValues.entityId}
                  onChange={(e) => onSetFormValues({ entityId: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className={Styles.lookupButtonSection}>
            <Button onClick={onClickLookup} type="secondary">
              Lookup
            </Button>
          </div>
        </div>
        {entityData?.versions.map((version, index) => (
          <React.Fragment key={index}>
            <div className={Styles.versionHeader}>
              {moment(version.validFrom).format(dateFormat)} - {version.createdByStaff?.name}
            </div>
            <div className={Styles.versionDiffContainer}>
              <DiffViewer
                className={Styles.diffViewerContainer}
                oldVersion={JSON.stringify(entityData.versions[index + 1], null, 2)}
                newVersion={JSON.stringify(version, null, 2)}
              />
            </div>
          </React.Fragment>
        ))}
        {!entityData && !loading && <div className={Styles.versionHeader}>No data to display</div>}
      </div>
      <div className={Styles.panel}>
        <h3> Browse History </h3>
        <DebugEntityHistory
          onSelect={onGoToEntity}
          selectedDebugEntity={formValues}
          compatibleCachesMap={compatibleCachesMap}
        />
      </div>
    </div>
  );
};

BillingSupportDataExplorer.displayName = 'BillingSupportDataExplorer';

BillingSupportDataExplorer.propTypes = {
  cacheOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  compatibleCachesMap: PropTypes.object.isRequired,
  entityData: PropTypes.shape({
    id: PropTypes.string,
    versions: PropTypes.arrayOf(PropTypes.object),
  }),
  formValues: PropTypes.shape({
    entityCacheName: PropTypes.string,
    entityId: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onClickLookup: PropTypes.func.isRequired,
  onGoToEntity: PropTypes.func.isRequired,
  onSetFormValues: PropTypes.func.isRequired,
};

BillingSupportDataExplorer.defaultProps = {
  entityData: undefined,
};
