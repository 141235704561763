import { defaultPath } from './default-path';

export const INITIALISE = `${defaultPath}/INITIALISE`;
export const SELECT_ALL = `${defaultPath}/SELECT_ALL`;
export const DESELECT_ALL = `${defaultPath}/DESELECT_ALL`;
export const TOGGLE_SELECT_ALL = `${defaultPath}/TOGGLE_SELECT_ALL`;
export const SELECT_PARENT = `${defaultPath}/SELECT_PARENT`;
export const DESELECT_PARENT = `${defaultPath}/DESELECT_PARENT`;
export const TOGGLE_SELECT_PARENT = `${defaultPath}/TOGGLE_SELECT_PARENT`;
export const SELECT_CHILD = `${defaultPath}/SELECT_CHILD`;
export const DESELECT_CHILD = `${defaultPath}/DESELECT_CHILD`;
export const TOGGLE_SELECT_CHILD = `${defaultPath}/TOGGLE_SELECT_CHILD`;
