'use strict';

/**
 * @typedef { import('../types').Activity } Activity
 * @typedef { import('../types').ActivityFilter } ActivityFilter
 */

const { constants } = require('../entities');

const { activityCategoryLabels } = constants;

/**
 * Given a flat array of Activity objects, this function will return an object keyed by activity type
 * labels with array values containing all of the activities of the corresponding activity type.
 *
 * NOTE: The expected Activity structure is the GraphQL Activity structure. Passing legacy cache
 * activity entities will lead to unexpected behaviour.
 *
 * @param {Object} params
 * @param {Array<Activity>} params.activities
 * @param {ActivityFilter} [params.filter]
 * @returns {Object<string, Array<Activity>>}
 */
const groupActivitiesByCategoryLabel = ({ activities, filter = {} }) => {
  if (!Array.isArray(activities)) {
    throw new Error(`The passed 'activities' must be an array`);
  }

  const activityTypesToInclude = new Set(filter.types || []);

  return activities.reduce((acc, activity) => {
    if (!activity || !activity.category) {
      throw new Error('Cannot group malformed activity objects');
    }

    // Apply filters.
    if (activityTypesToInclude.size !== 0 && !activityTypesToInclude.has(activity.category)) {
      return acc;
    }

    // Lookup the label and group accordingly.
    const activityCategoryLabel = activityCategoryLabels[activity.category];
    if (!activityCategoryLabel) {
      throw new Error(`Cannot group unsupported activity type '${activity.category}'`);
    }

    if (!acc[activityCategoryLabel]) {
      acc[activityCategoryLabel] = [];
    }

    acc[activityCategoryLabel].push(activity);
    return acc;
  }, {});
};

module.exports = {
  groupActivitiesByCategoryLabel,
};
