import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import {
  useCacheQuery,
  useInvoiceCorrespondenceDetailsData,
  useInvoiceTableData,
  useInvoiceTableSelections,
  useSubscribedQuery,
} from 'web/hooks';
import {
  BillingBulkActions,
  InitBankAccountSettings,
  InitFirmUtbmsSettings,
  MatterTypesFilter,
  StaffMembersList,
} from 'web/graphql/queries';
import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import { MARK_AS_SENT_MODAL_ID } from 'web/react-redux';
import { operationTypes } from '@sb-billing/business-logic/correspondence-history';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as firmInvoiceListReduxState from 'web/redux/route/home-billing-bills-list';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INVOICE_COMMUNICATE_MODAL_ID, INVOICE_EMAIL_MODAL_ID } from 'web/components';
import { getLogger } from '@sb-itops/fe-logger';
import { featureActive } from '@sb-itops/feature';

import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { BillingInvoicesListRoute } from './BillingInvoicesListRoute';

const log = getLogger('BillingInvoicesListRoute.container');

const FETCH_LIMIT = 50;
const SCOPE = 'BillingInvoicesListRoute';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_SORTING = `${SCOPE}/sorting`;

const hooks = () => ({
  useScope: () => ({
    scope: SCOPE,
  }),
  useBillingBulkActionsData: () => {
    const { data } = useSubscribedQuery(BillingBulkActions, {
      variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES },
    });

    const isBulkCreateInvoicesInProgress = data?.billingBulkActionList?.totalCount > 0;
    return {
      isBulkCreateInvoicesInProgress,
    };
  },
  useDisplayOptions: () => ({
    excludeFormerStaff: true,
    excludeStaffWithoutMatter: true,
  }),
  useFilters: () => {
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    const supportsUtbms = hasFacet(facets.utbms);
    const utbmsEnabledForFirm = (supportsUtbms && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled) || false;

    return {
      utbmsEnabledForFirm,
    };
  },
  usePromoData: () => {
    const showGetPaidPromo = getActiveProvider() || featureActive('NUCWEB-799-DEBUG');
    return {
      showGetPaidPromo,
    };
  },
  useTableData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const { bankBalanceType } = bankAccountSettingsData?.bankAccountSettings || {};
    const isUsingContactMatterBalance = bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];

    const showDebtor = true;
    const showMatter = true;
    const filters = useSelector(firmInvoiceListReduxState.selectors.getFilters);

    const tableData = useInvoiceTableData({
      scopePagination: SCOPE_PAGINATION,
      scopeSorting: SCOPE_SORTING,
      fetchLimit: FETCH_LIMIT,
      showDebtor,
      filters,
      bankBalanceType,
    });

    return {
      ...tableData,
      invoiceListReduxState: firmInvoiceListReduxState,
      contactMatterBalancesTitle: isUsingContactMatterBalance ? 'Debtor Balances' : 'Matter Balances',
      invoiceStatuses: filters.invoiceStatuses,
      showDebtor,
      showMatter,
    };
  },
  useCorrespondenceHistory: () => {
    const latestCorrespondenceHistory = useInvoiceCorrespondenceDetailsData();

    return latestCorrespondenceHistory;
  },
  useSendInvoiceEmails: ({ sbInvoiceSendService }) => {
    // Invoice emails can be sent via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenInvoiceEmailModal({ invoiceIds, contactId }) {
      setModalDialogVisible({
        modalId: INVOICE_EMAIL_MODAL_ID,
        props: {
          // Normally, we get our contactId(s) from the invoice but when resending an email from the Sent column, we specify a contactId so we can resend the email to that specific contact (this is to account for cases when there are multiple contacts on an invoice)
          debtorId: contactId,
          invoiceIds,
          scope: `${SCOPE}/invoice-email-modal`,
          onPreview: ({ invoiceEmailRequest }) =>
            sbInvoiceSendService.createInvoiceEmailPreviewP({
              invoiceEmailRequest,
            }),
          onSendEmails: async ({ invoiceEmailRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
            } catch (err) {
              log.error('Failed to send invoice emails', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceEmailModal,
    };
  },
  useSendInvoiceCommunicates: ({ sbInvoiceSendService }) => {
    // Invoice Communicates are currently only sent via:
    //  1. Invoice row menu (single invoice)
    const isInvoiceViaCommunicateFeatureEnabled = featureActive('BB-9097');

    function onOpenInvoiceCommunicateModal({ invoiceIds, contactId }) {
      if (!isInvoiceViaCommunicateFeatureEnabled) {
        return;
      }

      setModalDialogVisible({
        modalId: INVOICE_COMMUNICATE_MODAL_ID,
        props: {
          // See comment above in "onOpenInvoiceEmailModal" for further context
          debtorId: contactId,
          invoiceIds,
          scope: `${SCOPE}/invoice-communicate-modal`,
          onPreview: ({ invoiceCommunicateRequest }) =>
            sbInvoiceSendService.createInvoiceCommunicatePreviewP({
              invoiceCommunicateRequest,
            }),
          onSend: async ({ invoiceCommunicateRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
            } catch (err) {
              log.error('Failed to send invoice communicate requests', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceCommunicateModal,
    };
  },
  useMarkAsSent: () => {
    // Invoices can be "marked as sent" via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenMarkInvoiceAsSentModal({ invoiceIds }) {
      // This modal is not LOD yet (conversion will occur in the near future)
      setModalDialogVisible({
        modalId: MARK_AS_SENT_MODAL_ID,
        props: {
          selectedItems: invoiceIds,
          scope: `${SCOPE}/mark-as-sent-modal`,
          operationType: operationTypes.INVOICE,
        },
      });
    }

    return {
      onOpenMarkInvoiceAsSentModal,
    };
  },
});

const dependentHooks = () => ({
  useMatterTypesFilterData: () => {
    const matterTypes = React.useRef();
    const { data, error, loading } = useSubscribedQuery(MatterTypesFilter, {
      variables: {
        filter: {
          excludeMatterTypeWithoutMatter: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    if (!loading && data?.matterTypeList?.results) {
      matterTypes.current = data?.matterTypeList?.results;
    }

    return { matterTypes: matterTypes.current };
  },
  useStaffMemberFilterData: ({ excludeFormerStaff, excludeStaffWithoutMatter }) => {
    const { data, error } = useSubscribedQuery(
      StaffMembersList,
      {
        variables: { filter: { excludeFormerStaff, excludeStaffWithoutMatter } },
      },
      {
        notificationIds: [...StaffMembersList.notificationIds, 'MatterManagementWebQuery.MatterUpdated'],
      },
    );

    if (error) {
      throw new Error(error);
    }

    const staffMembers =
      data?.staffMembersList?.results.map((staff) => ({ ...staff, name: `${staff.firstName} ${staff.lastName}` })) ||
      [];

    return { staffMembers };
  },
  useInvoiceSelection: () => {
    const invoiceTableSelectionDataAndLogic = useInvoiceTableSelections({ scope: SCOPE });

    return invoiceTableSelectionDataAndLogic;
  },
});

export const BillingInvoicesListRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(BillingInvoicesListRoute))),
);

BillingInvoicesListRouteContainer.displayName = 'BillingInvoicesListRouteContainer';

BillingInvoicesListRouteContainer.propTypes = {
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbAsyncOperationsService: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbInvoiceSendService: PropTypes.object.isRequired, // Required to send invoice emails/communicates
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
};

BillingInvoicesListRouteContainer.defaultProps = {};
