import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { sortByProperty } from '@sb-itops/nodash';

import Styles from './ControlledMoneyAccountsTable.module.scss';

const { balanceCellLocalisedRenderer } = utils;

const cellRenderer = ({ cellData }) => {
  if (!cellData) {
    return null;
  }
  return cellData;
};

const statusCellRenderer = ({ cellData }) => (cellData === BANK_ACCOUNT_STATE.OPEN ? 'Open' : 'Closed');

export const ControlledMoneyAccountsTable = memo(
  ({ cmAccounts, sortBy, sortDirection, onSort, onClickLink, addToVisibleClosedIdMap }) => {
    const onRowClickHandler = (row) => {
      if (row.rowData.state === BANK_ACCOUNT_STATE.CLOSED) {
        addToVisibleClosedIdMap({ cmaId: row.rowData.id });
      }
      onClickLink({ type: 'controlledMoneyAccount', id: row.rowData.id });
    };

    const { t } = useTranslation();

    let sortedCmAccounts = cmAccounts;

    if (sortBy && sortDirection) {
      sortedCmAccounts = sortByProperty(cmAccounts, sortBy, sortDirection, false);
    }

    return (
      <Table
        list={sortedCmAccounts}
        onRowClick={onRowClickHandler}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sort={onSort}
      >
        <Column
          className="capitalize"
          dataKey="accountName"
          label="Account Name"
          flexGrow={1}
          cellRenderer={cellRenderer}
        />
        <Column dataKey="branchNumber" label={t('bankBranchNumber')} flexGrow={0.6} cellRenderer={cellRenderer} />
        <Column dataKey="accountNumber" label="Account Number" cellRenderer={cellRenderer} flexGrow={1} />
        <Column dataKey="state" label="Status" cellRenderer={statusCellRenderer} flexGrow={0.6} />
        <Column
          dataKey="balance"
          className={Styles.numericColumn}
          label="Balance"
          cellRenderer={balanceCellLocalisedRenderer}
          flexGrow={0.6}
          disableSort
        />
      </Table>
    );
  },
);

ControlledMoneyAccountsTable.propTypes = {
  cmAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      bsb: PropTypes.string,
      accountNumber: PropTypes.string,
      balance: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
  addToVisibleClosedIdMap: PropTypes.func,
};

ControlledMoneyAccountsTable.defaultProps = {
  sortBy: 'accountName',
  sortDirection: 'asc',
  onClickLink: () => {},
  addToVisibleClosedIdMap: undefined,
};
