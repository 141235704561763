'use strict';

import { store } from '@sb-itops/redux';
import { toCamelCase } from '@sb-itops/camel-case';
import * as authFeature from '@sb-itops/redux/auth.2';
import { getNotificationServerHostUrl, envType, getFrontendEnv } from '@sb-itops/environment-config';
import { io } from 'socket.io-client';

// The relevant package.json entry for Smokeball web should also be removed at that time.
const notifierHostMap = {
  'wss://devnotifier-aus.smokeball.com': 'wss://devnotifier-aus-2.smokeball.com',
  'wss://testnotifier-aus.smokeball.com': 'wss://testnotifier-aus-2.smokeball.com',
  'wss://notifier-aus.smokeball.com': 'wss://notifier-aus-2.smokeball.com',
  'wss://devnotifier-nova.smokeball.com': 'wss://devnotifier-nova-2.smokeball.com',
  'wss://testnotifier-nova.smokeball.com': 'wss://testnotifier-nova-2.smokeball.com',
  'wss://notifier-nova.smokeball.com': 'wss://notifier-nova-2.smokeball.com',
  'wss://devnotifier-lon.smokeball.com': 'wss://devnotifier-lon-2.smokeball.com',
  'wss://testnotifier-lon.smokeball.com': 'wss://testnotifier-lon-2.smokeball.com',
  'wss://notifier-lon.smokeball.com': 'wss://notifier-lon-2.smokeball.com',
};
// End of code to be removed after feature switch removed.

import { handleNotification as handleNotificationManager } from 'web/services/notification-manager';

angular
  .module('sb.billing.webapp')
  .service('sbNsbNotificationService', function(sbLoggerService, sbDispatcherService, sbRestErrorService) {
    const that = this;

    const log = sbLoggerService.getLogger('sbNsbNotificationService');

    let socket;
    let connectInProgress = false;
    let connectionEstablished = false;

    that.connect = connectP;
    that.disconnect = disconnect;

    let notificationHandler;

    async function connectP() {
      try {
        if (connectInProgress) {
          throw new Error('Failed to connect notification service - connection is already in progress');
        }

        if (connectionEstablished) {
          throw new Error('Failed to connect notification service - connection is already established');
        }

        connectInProgress = true;

        const notificationServerHost = notifierHostMap[getNotificationServerHostUrl()];
        const accountId = authFeature.selectors.getAccountId(store.getState());
        const userId = authFeature.selectors.getUserId(store.getState());

        log.info('notification server set to: ', notificationServerHost);
        log.info(`connecting to notification server for accountId: ${accountId}`);

        socket = io(notificationServerHost, { transports: ['websocket'] });

        // Set the handler to GraphQL AND Redux
        notificationHandler = (...args) => {
          handleNotificationManager({ log, userId })(...args)
          handleNotification(...args);
        }

        // Add global debugging function
        if (getFrontendEnv() !== envType.PRODUCTION) {
          window.Smokeball.receiveNotification = notificationHandler;
        }

        return new Promise((resolve, reject) => {
          socket.on('connect', () => {
            connectInProgress = false;
            connectionEstablished = true;

            socket.emit('subscribe', accountId);
            resolve();
          });

          socket.on('notification', (notification) => {
            notificationHandler(notification);
          });

          socket.on('connect_error', (err) => {
            connectInProgress = false;
            connectionEstablished = false;
            log.error('Failed to connect to notification server', err);
            reject(err);
          });

          socket.on('error', (err) => {
            connectInProgress = false;
            connectionEstablished = false;
            log.error('Failed to connect to notification server', err);
            reject(err);
          });
        });
      } catch (err) {
        log.error('Failed to connect to notification server');
        throw err;
      }
    }

    function disconnect() {
      if (!connectionEstablished) {
        throw new Error('Cannot disconnect from notification server when already disconnected');
      }

      log.info('disconnecting from notification server');
      socket.close();
      connectionEstablished = false;
    }

    function handleNotification(notification) {
      let action, provider, message, payload;
      const userId = authFeature.selectors.getUserId(store.getState());

      log.debug('saw notification: ', notification);

      if (!notification.providerId || !notification.message) {
        log.warn('Malformed notification received: ', notification);
        return;
      }

      provider = notification.providerId.replace(
        /Notfications/,
        'Notifications'
      );
      message = notification.message;
      action = 'notifier.' + provider;

      switch (provider) {
        case 'FeesNotifications': {
          // winston style messages
          message = toCamelCase(JSON.parse(message));
          action = action + '.' + message.messageId;

          let postDispatchEvent = {
            event: 'smokeball-data-update-sbFeeIntervalEdit',
            type: 'BILLING_UNIT_SYNC'
          };
          _.extend(message, { postDispatchEvent });

          log.info('dispatch', { action: action, payload: message });
          sbDispatcherService.dispatch({ action: action, payload: message });
          break;
        }
        case 'ReportLinkUpdates':
          // Noel style client report API notifications
          sbDispatcherService.dispatch({
            action,
            payload: message,
          });
          break;

        case 'ExpensesNotifications':
        case 'BillingMattersNotifications':
        case 'InvoicingNotifications':
        case 'AccountsNotifications':
        case 'DebtorsNotifications':
        case 'TaxesNotifications': {
          // winston style messages
          message = toCamelCase(JSON.parse(message));
          // used for PDF rendering
          sbDispatcherService.dispatchSyncAction({
            provider: provider,
            action: action,
            payload: message
          });
          // Used for cache update
          action = action + '.' + message.messageId;
          sbDispatcherService.dispatch({ action: action, payload: message });
          break;
        }
        case 'Authorization':
        case 'Security':
        case 'FirmManagementNotifications': {
          // message is a string, e.g. "FirmUpdated"
          sbDispatcherService.dispatch({ action: action, payload: message });
          break;
        }
        case 'SubscriptionNotifications':
        case 'ProductRegistrationNotifications': {
          // there is no message ID in this case
          // message is a string "ProductRegistrationUpdated"
          action = action + '.' + message;
          sbDispatcherService.dispatch({ action: action, payload: message });
          break;
        }
        case 'BrowseMattersNotifications': {
          // Weirdly shit '<ID>|<ENTITY>' separated format.
          const indexMap = ['messageId', 'entityId'];
          const msgData = message.split('|');

          message = {};
          msgData.forEach((value, index) => {
            if (index >= indexMap.length) {
              return false;
            }
            message[indexMap[index]] = value;
          });

          action = action + '.' + message.messageId;

          log.info('dispatch', { action, payload: message });
          sbDispatcherService.dispatch({ action, payload: message });
          break;
        }
        case 'DataCollectionProgress':
        case 'DataCollectionFinished':
        case 'PDFSyncNotification':
        case 'ReportGenerationFinished':
        case 'ReconciliationPDFSyncNotification':
        case 'TrustChequePDFSyncNotification':
        case 'PreviewReadyNotification':
        case 'TransactionDetailsPdfComplete':
        case 'TransactionDetailsPdfFailure':
        case 'PdfCombinedErrorNotification':
        case 'PreviewProgressNotification':
        case 'ReportReadyNotification':
        case 'ReportProgressNotification':
        case 'BatchLedesExportErrorNotification':
        case 'BatchLedesExportFailedNotification':
        case 'BatchLedesExportProgressNotification':
        case 'BatchLedesExportReadyNotification':
        case 'BatchLedesExportHasNonUbtmsEntriesNotification':
        case 'ChequeReadyNotification':
        case 'ChequeProgressNotification':
        case 'RemindersPdfReadyNotification':
        case 'RemindersPdfProgressNotification':
        case 'LedesExportErrorNotification':
        case 'LedesExportCriticalErrorNotification':
        case 'Ledes1998bExportNotificationComplete':
        case 'Ledes1998bExportNotificationFailed':
        case 'CorrespondenceHistoryExportReadyNotification':
        case 'CorrespondenceHistoryExportFailureNotification':
        case 'ExpenseExportReadyNotification':
        case 'ExpenseExportFailureNotification':
        case 'FeeExportReadyNotification':
        case 'FeeExportFailureNotification': 
        case 'TrustChequesExportReadyNotification':
        case 'TrustChequesExportFailureNotification': {
          message = toCamelCase(JSON.parse(message));
          sbDispatcherService.dispatchSyncAction({
            provider: provider,
            action: action,
            payload: message
          });
          break;
        }
        case 'XeroIntegration': {
          message = message.includes('MessageId') ? toCamelCase(JSON.parse(message)) : message;
          action = action + '.' + message.messageId || message;
          sbDispatcherService.dispatch({ action });
          sbDispatcherService.dispatchSyncAction({
            provider: provider,
            action: action,
            payload: message
          });
          break;
        }
        case 'MyobIntegration': {
          action = action + '.' + (message.includes('MessageId') ? toCamelCase(JSON.parse(message)).messageId : message);
          sbDispatcherService.dispatch({ action });
          sbDispatcherService.dispatchSyncAction({
            provider,
            action,
            payload: message
          });
          break;
        }
        case 'QuickbooksIntegration': {
          log.info('dispatch', { action });
          sbDispatcherService.dispatch({ action });
          break;
        }
        case 'CustomerManagementNotifications': {
          log.info('dispatch', { action });
          sbDispatcherService.dispatch({ action });
          break;
        }
        case 'BillingActivitiesNotifications': {

          // we do not want to udpate fees and expenses when we are not utbms billing
          if (['BillingUtbmsSettingsUpdated', 'CustomTaskCodeUpdated'].includes(message)) {
            // this is for updating sbUtbmsSettingsService and sbCustomTaskCodeService cache
            action = action + '.' + message;
            sbDispatcherService.dispatch({ action: action, payload: message });
          } else {
            log.info('dispatch', { action });
            sbDispatcherService.dispatch({ action });
          }
          break;
        }
        case 'LPAccountSettingsUpdatedNotification':
        case 'PaymentProviderSettingsUpdated': {
          sbDispatcherService.dispatch({ action });
          break;
        }
        case 'PaymentProviderChargeCompleted': {
          payload = JSON.parse(message);

          if (payload.requestedByUserId === userId) {
            sbDispatcherService.dispatchSyncAction({
              provider,
              action,
              payload
            });
          }
          break;
        }
        /*
        // The below notifications can't be found in our codebase and I don't think these would
        // come from .net either as we don't have any handlers for them anyway
        case 'LPTxnCompleteNotification': {
          payload = JSON.parse(message);

          if (payload.userId === userId) {
            sbDispatcherService.dispatchSyncAction({
              provider,
              action,
              payload
            });
          }
          break;
        }
        case 'LPConnectNotification':
        case 'PaymentProviderConnect':
        case 'PaymentProviderDisconnect': {
          payload = JSON.parse(message);

          if (payload.userId === userId) {
            sbDispatcherService.dispatchSyncAction({
              provider,
              action,
              payload
            });
          }
          break;
        }
        */
        case 'RestErrorNotification': {
          payload = JSON.parse(message);
          sbRestErrorService.store(payload);
          sbDispatcherService.dispatch({ action });
          break;
        }
        case 'BillingSharedNotifications': {
          action = action + '.' + (message.includes('MessageId') ? toCamelCase(JSON.parse(message)).messageId : message);
          sbDispatcherService.dispatch({ action });
          break;
        }
      }
    }
  });
