import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, FormLabel, forms2PropTypes, useTranslation } from '@sb-itops/react';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import classnames from 'classnames';
import Styles from './AddEditControlledMoneyAccountForm.module.scss';
import { ContactMultiSelect } from '../contact-multi-select';

export const AddEditControlledMoneyAccountForm = ({
  scope,
  // form fields
  contactOptions,
  beneficiaryIds,
  accountOpenedDate,
  bankName,
  accountName,
  branchNumber,
  accountNumber,
  // form
  onFieldValueUpdated,
  onBeneficiaryChange,
  formDisabled,
  submitFailed,
  validateForm,
  formInitialised,
  // config
  bankBranchNumberRegex,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <div className={Styles.container} id={scope}>
      <fieldset>
        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel label="Account opened" field={accountOpenedDate} submitFailed={submitFailed} />
            <DatePicker
              onSelect={(date) => {
                onFieldValueUpdated({ accountOpenedDate: date ? dateToInteger(date) : undefined });
                validateForm();
              }}
              value={accountOpenedDate && accountOpenedDate.value && integerToDate(accountOpenedDate.value)}
              hasError={accountOpenedDate && accountOpenedDate.isInvalid}
              format="DD/MM/YYYY"
              disabled={formDisabled}
            />
          </div>

          <div className="col-xs-6 form-group">
            <FormLabel label="Financial institution" field={bankName} submitFailed={submitFailed} />
            <input
              className={classnames('form-control', isErrorClassnames(bankName))}
              type="text"
              value={bankName?.value || ''}
              disabled={formDisabled}
              onChange={(e) => {
                onFieldValueUpdated({ bankName: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Account name" field={accountName} submitFailed={submitFailed} />
            <input
              className={classnames('form-control', isErrorClassnames(accountName))}
              type="text"
              value={accountName?.value || ''}
              disabled={formDisabled}
              onChange={(e) => {
                onFieldValueUpdated({ accountName: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel label={t('bankBranchNumber')} field={branchNumber} submitFailed={submitFailed} />
            <input
              className={classnames('form-control', isErrorClassnames(branchNumber))}
              type="text"
              maxLength={bankBranchNumberRegex.test('12-34-56') ? 8 : 7}
              value={branchNumber?.value || ''}
              disabled={formDisabled}
              onChange={(e) => {
                onFieldValueUpdated({ branchNumber: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
          <div className="col-xs-6 form-group">
            <FormLabel label="Account number" field={accountNumber} submitFailed={submitFailed} />
            <input
              className={classnames('form-control', isErrorClassnames(accountNumber))}
              type="text"
              value={accountNumber?.value || ''}
              disabled={formDisabled}
              onChange={(e) => {
                onFieldValueUpdated({ accountNumber: e.target.value });
              }}
              onBlur={validateForm}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 form-group">
            <label>Beneficiaries *</label>
            <div className={Styles.beneficiaries}>
              <ContactMultiSelect
                selectedContactIds={beneficiaryIds || []}
                contactOptions={contactOptions}
                max={20}
                isRequired
                disabled={formDisabled}
                menuPlacement="top"
                maxMenuHeight={200}
                onContactsChanged={(contactIds) => {
                  // remove empty entries
                  const newContactIds = contactIds.filter((id) => !!id);
                  onBeneficiaryChange(newContactIds);
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

AddEditControlledMoneyAccountForm.displayName = 'AddEditControlledMoneyAccountForm';

AddEditControlledMoneyAccountForm.propTypes = {
  scope: PropTypes.string.isRequired,
  contactOptions: PropTypes.array.isRequired,
  beneficiaryIds: PropTypes.arrayOf(PropTypes.string),
  accountOpenedDate: Forms2Field,
  bankName: Forms2Field,
  accountName: Forms2Field,
  branchNumber: Forms2Field,
  accountNumber: Forms2Field,
  // form
  onFieldValueUpdated: PropTypes.func.isRequired,
  onBeneficiaryChange: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  // config
  bankBranchNumberRegex: PropTypes.instanceOf(RegExp).isRequired,
};

AddEditControlledMoneyAccountForm.defaultProps = {
  beneficiaryIds: [],
  accountOpenedDate: undefined,
  bankName: undefined,
  accountName: undefined,
  branchNumber: undefined,
  accountNumber: undefined,
};
