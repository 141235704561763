import { shared } from '@sb-billing/business-logic';
import { featureActive } from '@sb-itops/feature';
import { selectors as feeConfigSelectors } from '@sb-billing/redux/fee-configuration/feature';
import { getMap as getFeeConfigState } from '@sb-billing/redux/fee-configuration';
import { getAllStaff, getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { getById as getStaffHourlyRateById } from '@sb-billing/redux/staff-hourly-rate';
import { getById as getStaffFeeConfigById } from '@sb-billing/redux/staff-fee-configuration';
import { convertHoursToUnits } from '@sb-billing/business-logic/shared/services/fuzzy-time';


const { getMinutesFuzzy, roundToInterval, convertUnitsToHours } = shared.services.fuzzyTime;

/**
 * Time based activities store their units as minutes. Users may elect to have their activities displayed as hours or units, as determined
 * by the firm billing interval. Since the billing interval may change over time, we need to check whether the activities time can be 
 * represented by the current billing interval. For example, an activity that would be billed at 1 minute, could not be menaingfully be
 * represented if the firm billing interval is 15 minutes.
 */
function doesDurationMatchInterval({ mins, interval }) {
  if (!mins) {
    return true;
  }
  return !(mins % interval);
}

angular.module('sb.billing.webapp').component('sbActivityTimeEntry',
  {
    'templateUrl': 'ng-components/activity-entry/activity-time-entry.html',
    'bindings': {processing: '<', activity: '<', rateTypeRadio: '<', activityForm: '<', syncUpdate: '&', syncView: '&'},
    'controller': function ($scope, sbLocalisationService) {
      'use strict';

      const ctrl = this;
      ctrl.t = sbLocalisationService.t;
      const loggedInStaff = getLoggedInStaff();
      const staffConfig = loggedInStaff && loggedInStaff.id && getStaffFeeConfigById(loggedInStaff.id);
      const interval = feeConfigSelectors.getBillableMinutes(getFeeConfigState());

      // activity time entry's 'units' field is stored as minutes, this is required below to help
      // work out whether or not user should enter default duration as units or hours, e.g. even
      // when staff configuration is to enter time as units, if the number of minutes don't convert
      // to exact number of units (of specified interval of 1min, 6mins or 15mins) then user will
      // be shown the hours field. this helps deal with existing activity time entry in the system.
      const mins = +ctrl.activity.units;
      ctrl.model = {
        isUnits: featureActive('BB-5345') &&
          staffConfig && 
          staffConfig.enterTimeAsUnits && 
          // if the activities time cannot be represented by the current billing interval, then we must show it in hours
          doesDurationMatchInterval({ mins, interval }),
      };
      ctrl.model.isHrs = !ctrl.model.isUnits

      // From here on wards, ctrl.activity.units should be interpretted as hours, the conversion
      // is done here because all other logic on this UI seem to rely on this. Probably should have used a
      // different variable, but limiting the change in this fix given how late it is in the testing cycle
      // NB: the extra withRounding param for convertHoursToUnits seem to be the code that's dealing with
      // 1min billing intervals where back and forth conversions results in rounding issues e.g. when 1 unit
      // or 5 units are specified.
      ctrl.activity.units = +(ctrl.activity.units / 60).toFixed(5)
      ctrl.view = {
        // On first initialise assigning undefined will show the place holder
        units: ctrl.model.isUnits 
          ? convertHoursToUnits({ hrs: ctrl.activity.units, interval, withRounding: featureActive('BB-4832') }) || undefined 
          : ctrl.activity.units,
      };

      ctrl.onSelectTaxExempt = onSelectTaxExempt;
      ctrl.onSelecAmountIncludesTax = onSelecAmountIncludesTax;
      ctrl.onStaffRateOverride = onStaffRateOverride;
      ctrl.onBlurDuration = onBlurDuration;
      ctrl.onUnitTypechange = onUnitTypechange;
      ctrl.onUnitschange = onUnitschange;
      ctrl.onFocusNumberInput = onFocusNumberInput;
      ctrl.applyTimeRefinements = featureActive('BB-5345');

      const NO_OVERRIDE = 'Do not override rate';
      const CUSTOM_RATE = 'Override rate for all staff';
      const OVERRIDE_PER_STAFF_RATE = 'Override default rate per staff member';

      ctrl.rateTypes = { NO_OVERRIDE,  CUSTOM_RATE, OVERRIDE_PER_STAFF_RATE };

      $scope.$on('smokeball-data-update-sbFirmManagementMbService', loadStaffMembers);

      ctrl.$onInit = () => {
        $scope.$watch('$ctrl.activity', function(activity) {
          ctrl.syncUpdate({activity});
        });

        $scope.$watch('$ctrl.rateTypeRadio', function(rateTypeRadio) {
          ctrl.syncView({rateTypeRadio});
        });

        loadStaffMembers();
      };

      function loadStaffMembers() {
        ctrl.view.staffMembers = getAllStaff().map((staffMember) => {
          const staffHourlyRate = getStaffHourlyRateById(staffMember.id);
          return {
            ...staffMember,
            rate: staffHourlyRate && staffHourlyRate.rate,
          };
        });
      }

      function onSelectTaxExempt(newValue) {
        if (ctrl.activity.amountIncludesTax && newValue) {
          ctrl.activity.amountIncludesTax = !newValue;
        }        
      }

      function onSelecAmountIncludesTax(newValue) {
        if (ctrl.activity.taxExempt && newValue) {
          ctrl.activity.taxExempt = !newValue;
        }
      }

      function onStaffRateOverride(overrides) {
        ctrl.activity.customActivityRatesPerStaff = overrides;
      }

      function onBlurDuration() {
        if (!ctrl.activity.units) {
          ctrl.activity.errors.units = true;
          return;
        }
        const mins = getMinutesFuzzy({ duration: ctrl.activity.units, interval, withRounding: featureActive('BB-4832') });
        const durationRounded = +(roundToInterval({ mins, interval }) / 60).toFixed(5);
        ctrl.activity.units = durationRounded;
        ctrl.activity.errors.units = mins <= 0;
      }

      function onUnitTypechange(unitType) {
        if (unitType === 'hrs' && ctrl.model.isUnits) {
          ctrl.view.units = convertUnitsToHours({ units: ctrl.view.units, interval });
        } else if (unitType === 'units' && ctrl.model.isHrs) {
          ctrl.view.units = convertHoursToUnits({ hrs: ctrl.view.units, interval, withRounding: featureActive('BB-4832') });
        }

        updateUnitTypeSelection(unitType);
      }

      function updateUnitTypeSelection(unitType) {
        ctrl.model.isHrs = unitType === 'hrs';
        ctrl.model.isUnits = unitType === 'units';
      }

      function onUnitschange(units) {
        ctrl.view.units = units;
        ctrl.activity.units = ctrl.model.isUnits 
          ? convertUnitsToHours({ units, interval }) 
          : units;
      }

      function onFocusNumberInput(event) {
        event.target.select();
      }
    }
  }
);

