'use strict';

const legalStructuresOptions = [
  { label: 'Multi Member LLC', value: 'MultiMemberLLC' },
  { label: 'Private Corporation', value: 'PrivateCorporation' },
  { label: 'Private Partnership', value: 'PrivatePartnership' },
  { label: 'Public Corporation', value: 'PublicCorporation' },
  { label: 'Public Partnership', value: 'PublicPartnership' },
  { label: 'Single Member LLC', value: 'SingleMemberLLC' },
  { label: 'Sole Proprietorship', value: 'SoleProprietorship' },
  { label: 'Unincorporated Association', value: 'UnincorporatedAssociation' },
  { label: 'Individual', value: 'Individual' },
];

const legalStructures = legalStructuresOptions.map((option) => option.value);

module.exports = {
  legalStructuresOptions,
  legalStructures,
};
