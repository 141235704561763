'use strict';

const type = Object.freeze({
  matterContact: 'MatterContact',
  matter: 'Matter',
});

module.exports = {
  type,
  byName: {
    [type.matterContact]: 0,
    [type.matter]: 1,
  },
  byValue: {
    0: type.matterContact,
    1: type.matter,
  },
};
