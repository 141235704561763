import PropTypes from 'prop-types';
import { getMatterTypeNameById } from '@sb-matter-types/redux/matter-types';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';

const mapStateToProps = (state, { id }) => ({
  text: getMatterTypeNameById(id),
});

const MatterTypeDisplay = withReduxStore(connect(mapStateToProps)(LinkableText));

MatterTypeDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  className: PropTypes.string,
};

MatterTypeDisplay.defaultProps = {
  inline: true,
  className: 'matter-type-display',
};

export default MatterTypeDisplay;
