import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { sort as sortItems } from '@sb-itops/sort';
import className from 'classnames';

import styles from './PaymentPlanPaymentsTable.module.scss';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

const sortBy = 'date';

const PaymentPlanPaymentsTable = ({ payments, sortDirection, onPaymentsSortDirectionChange }) => {
  const onSort = useCallback(
    (sortProps) => {
      onPaymentsSortDirectionChange(sortProps.sortDirection);
    },
    [onPaymentsSortDirectionChange],
  );

  const paymentsSorted = sortItems(payments, [sortBy, 'timestamp'], [sortDirection, sortDirection]);

  return (
    <Table
      className={styles.container}
      list={paymentsSorted}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      style={{
        height: `${Math.max(200, 30 + paymentsSorted.length * 38)}px`,
      }}
    >
      <Column
        dataKey="date"
        label="Date"
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer={({ cellData, rowData }) => {
          if (rowData.type === 'INVOICE_PAYMENT_FAILED') {
            return <div className={styles.failedPayment}>{yyyymmddLocalisedRenderer({ cellData })}</div>;
          }
          return yyyymmddLocalisedRenderer({ cellData });
        }}
        width={160}
      />
      <Column
        dataKey="amount"
        label="Payment Received"
        className="right-align"
        flexGrow={1}
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer={({ cellData, classNames, rowData }) => {
          if (rowData.type === 'INVOICE_PAYMENT_FAILED') {
            return (
              <div title={rowData.errorMessage} className={className(classNames, styles.failedPayment)}>
                Payment Failed
              </div>
            );
          }
          return balanceCellLocalisedRenderer({ cellData, classNames });
        }}
        disableSort
      />
    </Table>
  );
};

PaymentPlanPaymentsTable.displayName = 'PaymentPlanPaymentsTable';

PaymentPlanPaymentsTable.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  sortDirection: PropTypes.string,
  onPaymentsSortDirectionChange: PropTypes.func.isRequired,
};

PaymentPlanPaymentsTable.defaultProps = {
  payments: [],
  sortDirection: 'asc',
};

export default PaymentPlanPaymentsTable;
