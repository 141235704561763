import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';

angular.module('sb.billing.webapp').component('sbMatterPartiesDisplay', {
  bindings: { matterParties: '<', goToContact: '&' },
  templateUrl: 'ng-components/matter-parties-display/matter-parties-display.html',
  controller: function () {
    const ctrl = this;

    ctrl.getDisplay = (contact) => contact ? getContactDisplay(contact.entityId) : '';
  },
});
