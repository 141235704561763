import { getList as getPayments } from '@sb-billing/redux/payments';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';

angular.module('sb.billing.webapp').component('sbDataPaymentsByInvoiceIds',
  {
    require: { composer: '^sbCompose' },
    bindings: {invoiceIds: '<', entityDataKey: '@', dateTo: '<', dateFrom:'<'},
    controller: function ($scope, sbLoggerService, sbDateService) {
      const ctrl = this;
      const log = sbLoggerService.getLogger('sbDataPaymentsByInvoiceIds');
      const entityDataKey = ctrl.entityDataKey || 'payments';
      ctrl.invoiceIds = ctrl.invoiceIds || [];
      ctrl.invoiceIdsHash = ctrl.invoiceIds.reduce((acc, id) => {
        acc[id] = true
        return acc
      }, {});
      
      ctrl.$onInit = () => {
        log.info('invoiceIds', ctrl.invoiceIds);
        log.info('entityDataKey', ctrl.entityDataKey);
        updatePayments();
      };

      ctrl.$onChanges = updatePayments;

      $scope.$on('smokeball-data-update-sbPaymentService', () => {
        log.info('saw data update');
        updatePayments();
      });

      $scope.$on('smokeball-data-update-sbInvoicingService', () => {
        log.info('saw data update');
        updatePayments();
      });

      function imbueInvoiceNumbers(payment) {
        payment.invoices.forEach(invoice => {
          const fullInvoice = getInvoiceById(invoice.invoiceId);
          invoice.invoiceNumber = fullInvoice && fullInvoice.currentVersion && fullInvoice.currentVersion.invoiceNumber;
        });

        return payment;
      }

      function filterByDate(data, dateProp, dateFrom, dateTo) {
        if(dateFrom && dateTo){
          const from = sbDateService.from(dateFrom);
          const to   = sbDateService.from(dateTo);
          return data.filter(item => moment(sbDateService.from(_.get(item, dateProp))).isBetween(from, to, undefined, '[]'))
        }
        return data
      }

      function updatePayments() {
        // Streamlined here for performance reasons
        const payments = getPayments().reduce((acc, payment) => {
          if ((payment.invoices || []).some(({ invoiceId }) => ctrl.invoiceIdsHash[invoiceId])) {
            acc.push(imbueInvoiceNumbers(payment))
          }
          return acc
        },[])
        log.info('payments for invoice ids %s', ctrl.invoiceIds.join(', '), payments);
        const filteredData = filterByDate(payments, 'effectiveDate', ctrl.dateFrom, ctrl.dateTo);
        ctrl.composer.setComposeData({dataType: entityDataKey, data: filteredData}, entityDataKey);
      }
    }
  }
);
