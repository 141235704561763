'use strict';

const { showRetainerOnInvoiceOptionValues } = require('./show-retainer-on-invoice-options');

/**
 * Determines whether any (trust) retainer amounts due should be shown on the invoice.
 *
 * There are three levels of configuration:
 *  1. Invoice template settings
 *  2. Matter settings
 *  3. Invoice settings
 *
 * Configuration priority: Invoice > Matter > Template
 *
 * However, if the "Activate Minimum Trust Retainer" is OFF (in the matter trust retainer settings), the setting is overridden to be false
 *
 * @param {Object} params
 * @param {Object} params.invoiceTemplateSettings
 * @param {Object} params.matterInvoiceSettings
 * @param {Object} [params.invoiceVersion]
 * @param {Object} params.matterBillingConfiguration
 * @returns {boolean}
 */
function determineShowRetainerSetting({
  invoiceTemplateSettings,
  matterInvoiceSettings,
  invoiceVersion,
  matterBillingConfiguration,
}) {
  if (!matterBillingConfiguration.minimumTrustRetainerActive) {
    return false;
  }

  if (invoiceVersion) {
    return invoiceVersion?.additionalOptions?.showRetainer || false;
  }

  const showRetainerMatterSetting = matterInvoiceSettings?.showRetainerOption;
  const showRetainerTemplateSetting = invoiceTemplateSettings?.invoiceAdditionalOptions?.showRetainer;

  if (
    !showRetainerMatterSetting ||
    showRetainerMatterSetting === showRetainerOnInvoiceOptionValues.USE_TEMPLATE_SETTING
  ) {
    return showRetainerTemplateSetting || false;
  }

  if (showRetainerMatterSetting === showRetainerOnInvoiceOptionValues.DISABLED) {
    return false;
  }

  if (showRetainerMatterSetting === showRetainerOnInvoiceOptionValues.ENABLED) {
    return true;
  }

  return false;
}

module.exports = {
  determineShowRetainerSetting,
};
