'use strict';

// Any changes to these will need to be handled in monorepo/billing/business-logic/invoice-reminder/interpolate-reminder-email.js
const getInvoiceReminderEmailMacros = ({ isForEmailSubject, inclPayNowButton }) => {
  const macros = [
    // Invoice Details
    { name: '[DEBTOR_NAME]', tooltip: 'Debtor name' },
    { name: '[OVERDUE_AMOUNT]', tooltip: 'Amount overdue' },
    // Matter Details
    { name: '[MATTER_AMOUNT_DUE]', tooltip: 'Amount due (matter total)' },
    { name: '[MATTER_NAME]', tooltip: 'Matter name' },
    // Firm Details
    { name: '[USER_NAME]', tooltip: 'Current user' },
    { name: '[FIRM_NAME]', tooltip: 'Firm name' },
    { name: '[PHONE_NUMBER]', tooltip: 'Phone number' },
  ];

  if (!isForEmailSubject && inclPayNowButton) {
    // Invoice Details
    macros.push({ name: '[PAY_NOW_BUTTON]', tooltip: 'Pay Now button' });
  }

  return macros;
};

module.exports = { getInvoiceReminderEmailMacros };
