import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InputNumberFilter } from '@sb-itops/react/input-number-filter';
import { CheckboxLabelInline } from '@sb-itops/react/checkbox';

const RemindersRecommendedFilterDisplay = ({
  visible,
  remindedInDaysChecked,
  remindedInDays,
  invoicedInDaysChecked,
  invoicedInDays,
  hideMatterDebtorWithNoOverdueInvoices,
  onFilterChanged,
}) => {
  const filtersValues = {
    remindedInDaysChecked,
    remindedInDays,
    invoicedInDaysChecked,
    invoicedInDays,
    hideMatterDebtorWithNoOverdueInvoices,
  };

  const createCheckboxFilterChangeHandler =
    ({ field }) =>
    (newValue) => {
      filtersValues[field] = newValue;
      return onFilterChanged({ value: filtersValues[field], field });
    };

  const createNumberFilterChangeHandler =
    ({ field }) =>
    (newValue) => {
      filtersValues[field] = newValue;
      return onFilterChanged({ value: filtersValues[field], field });
    };

  return (
    visible && (
      <li className="reminders-recommended">
        <span>
          <span className="selection-text" title="Hide matters for debtors that have:">
            Hide matters for debtors that have:
          </span>
          <div title={`Reminded in the last ${remindedInDays} day(s)`}>
            <CheckboxLabelInline
              checked={remindedInDaysChecked}
              onChange={createCheckboxFilterChangeHandler({ field: 'remindedInDaysChecked' })}
            >
              <>
                A reminder in the last
                <InputNumberFilter
                  min={1}
                  max={999}
                  value={remindedInDays}
                  onChange={createNumberFilterChangeHandler({ field: 'remindedInDays' })}
                />
                day(s)
              </>
            </CheckboxLabelInline>
          </div>
          <div title={`Invoiced in the last ${invoicedInDays} day(s)`}>
            <CheckboxLabelInline
              checked={invoicedInDaysChecked}
              onChange={createCheckboxFilterChangeHandler({ field: 'invoicedInDaysChecked' })}
            >
              <>
                Invoiced in the last
                <InputNumberFilter
                  min={1}
                  max={999}
                  value={invoicedInDays}
                  onChange={createNumberFilterChangeHandler({ field: 'invoicedInDays' })}
                />
                day(s)
              </>
            </CheckboxLabelInline>
          </div>
        </span>
      </li>
    )
  );
};

RemindersRecommendedFilterDisplay.propTypes = {
  visible: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
  remindedInDaysChecked: PropTypes.bool,
  remindedInDays: PropTypes.number,
  invoicedInDaysChecked: PropTypes.bool,
  invoicedInDays: PropTypes.number,
  hideMatterDebtorWithNoOverdueInvoices: PropTypes.bool,
};

RemindersRecommendedFilterDisplay.defaultProps = {
  visible: true,
  remindedInDays: undefined,
  remindedInDaysChecked: true,
  invoicedInDays: undefined,
  invoicedInDaysChecked: true,
  hideMatterDebtorWithNoOverdueInvoices: true,
};

export default React.memo(RemindersRecommendedFilterDisplay);
