import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { filterOptions } from '@sb-billing/business-logic/invoice-filters';
import { withReduxStore } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';

import * as viewContactBillsFilters from 'web/redux/route/home-billing-view-contact-bills';

import InvoicesFilterPanel from './InvoicesFilterPanel';

const mapStateToProps = (state) => {
  const { getShowFilters, getFilters, getHideFilters } = viewContactBillsFilters.selectors;
  const expanded = getShowFilters(state);
  const hideFilters = getHideFilters(state);
  const filters = getFilters(state);
  return {
    expanded,
    filters,
    hideFilters,
    filterOptions,
    showSendPreferenceFilter: true,
    showBillingFrequencyFilter: true,
    showOperatingAccountFilter: hasFacet(facets.operatingAccount),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(viewContactBillsFilters.actions, dispatch),
});

const ViewContactInvoicesFilterPanelContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(InvoicesFilterPanel),
);

ViewContactInvoicesFilterPanelContainer.propTypes = {
  invoiceListData: PropTypes.object.isRequired,
  compactFilters: PropTypes.bool,
};

ViewContactInvoicesFilterPanelContainer.defaultProps = {
  compactFilters: true,
};

export default ViewContactInvoicesFilterPanelContainer;
