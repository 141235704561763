import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon, useTranslation } from '@sb-itops/react';
import { AutoSizer } from 'react-virtualized';
import { BarChart, Bar, Tooltip } from 'recharts';
import Styles from './FeeSummaryChart.module.scss';

export const displayModes = Object.freeze({
  TIME: 'TIME',
  MONEY: 'MONEY',
});

export const FeeSummaryChart = (props) => {
  const { chartTitle, chartData, timeLoggedLabel, billableAmountLabel, collapsed } = props;
  const { currentMonthLabel, onPreviousMonth, onCurrentMonth, onNextMonth } = props;
  const { selectedDisplayMode, onDisplayModeChange, onToggleCollapse } = props;
  const { daysOfMonthData, onDayChange, isWidget } = props;
  const { t } = useTranslation();

  const moneyMode = selectedDisplayMode === displayModes.MONEY;

  const handleDisplayModeChange = (newMode) => {
    if (selectedDisplayMode === newMode) {
      return;
    }

    onDisplayModeChange({ displayMode: newMode });
  };

  const handleDayChange = (newDayOfMonth) => {
    if (newDayOfMonth.isSelectedDay) {
      return;
    }

    onDayChange({ dayNumber: newDayOfMonth.dayNumber });
  };

  const handleBarClick = (evt) => {
    handleDayChange(daysOfMonthData[evt.index]);
  };

  return (
    <div className={classnames({ [Styles.feeSummaryChart]: true, [Styles.widget]: isWidget })}>
      {/* Collapsed */}
      {collapsed && (
        <div className={Styles.collapsedPanel}>
          <div className={Styles.expandSection} onClick={onToggleCollapse}>
            <i className={classnames('fa', 'fa-fw', 'fa-chevron-up', Styles.icon)} />
            <span className={Styles.label}>Show Chart</span>
          </div>
        </div>
      )}

      {/* Expanded */}
      {!collapsed && (
        <div className={Styles.expandedPanel}>
          <div className={Styles.collapseSection} onClick={onToggleCollapse}>
            <i className={classnames('fa', 'fa-fw', 'fa-chevron-down', Styles.icon)} />
          </div>
          <div className={Styles.chartAndLabelsSection}>
            <div className={Styles.chartHeader}>
              <span className={Styles.title}>{chartTitle}</span>
              <div className={Styles.controls}>
                {/* time / money selector */}
                <div className={Styles.timeMoneySelector}>
                  <Icon
                    className={classnames(
                      Styles.iconButton,
                      Styles.iconSelect,
                      selectedDisplayMode === displayModes.TIME && Styles.selected,
                    )}
                    type="clock-o"
                    onClick={() => handleDisplayModeChange(displayModes.TIME)}
                    isFA
                  />
                  <Icon
                    className={classnames(
                      Styles.iconButton,
                      Styles.iconSelect,
                      selectedDisplayMode === displayModes.MONEY && Styles.selected,
                    )}
                    type="currency-icon"
                    onClick={() => handleDisplayModeChange(displayModes.MONEY)}
                    isFA
                  />
                </div>

                {/* month changer */}
                <div className={Styles.monthChanger}>
                  <Icon className={Styles.iconButton} type="chevron-left" onClick={() => onPreviousMonth()} isFA />
                  <span className={Styles.labelBlock} onClick={onCurrentMonth}>
                    {currentMonthLabel}
                  </span>
                  <Icon className={Styles.iconButton} type="chevron-right" onClick={() => onNextMonth()} isFA />
                </div>
              </div>
            </div>

            {/* The actual chart */}
            {/* See https://celiaongsl.medium.com/2-ways-to-hack-the-bar-chart-on-recharts-more-tricks-hacking-recharts-2-1387f21ecb72 for ideas on hover styles for the bars */}
            <div className={Styles.chart}>
              <AutoSizer>
                {({ height, width }) => (
                  <BarChart width={width} height={height} margin={{ top: 0, left: 0, right: 0 }} data={chartData}>
                    <Bar
                      dataKey={moneyMode ? 'amountInCents' : 'timeInMinutes'}
                      fill="rgba(75,180,249,.8)"
                      onClick={(evt) => handleBarClick(evt)}
                    />
                    <Tooltip
                      labelStyle={{ color: 'pink' }}
                      itemStyle={{ color: '#fff' }}
                      contentStyle={{
                        backgroundColor: '#000',
                        border: 'none',
                        opacity: '0.9',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '0 5px',
                      }}
                      separator=""
                      labelFormatter={() => ''}
                      formatter={(value) => [
                        moneyMode ? `${t('cents', { val: +value })}` : `${(+value / 60).toFixed(1)} hours`,
                        '',
                      ]}
                      cursor={false}
                      isAnimationActive={false}
                    />
                  </BarChart>
                )}
              </AutoSizer>
            </div>

            {/* The days along the x axis */}
            <div className={Styles.chartDays}>
              {daysOfMonthData.map((dayOfMonth, index) => (
                <div
                  key={index}
                  onClick={() => handleDayChange(dayOfMonth)}
                  className={classnames(
                    Styles.dayLabel,
                    dayOfMonth.isSelectedDay && Styles.selected,
                    !dayOfMonth.isSelectedDay && dayOfMonth.isCurrentDay && Styles.today,
                  )}
                >
                  <span className={Styles.dayNumber}>{dayOfMonth.dayNumber}</span>
                  <span className={Styles.dayInitial}>{dayOfMonth.dayInitial}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Totals panel to the right of the component */}
          <div className={Styles.totalsSection}>
            {/* Total time logged */}
            <div className={Styles.totalItem}>
              <div className={Styles.title}>Time Logged</div>
              <div className={Styles.value}>{timeLoggedLabel}</div>
            </div>

            {/* Total billable amount */}
            <div className={Styles.totalItem}>
              <div className={Styles.title}>Billable Amount</div>
              <div className={Styles.value}>{billableAmountLabel}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FeeSummaryChart.displayName = 'FeeSummaryChart';

FeeSummaryChart.propTypes = {
  isWidget: PropTypes.bool,
  chartTitle: PropTypes.string.isRequired,
  currentMonthLabel: PropTypes.string.isRequired,
  onPreviousMonth: PropTypes.func.isRequired,
  onCurrentMonth: PropTypes.func.isRequired,
  onNextMonth: PropTypes.func.isRequired,
  timeLoggedLabel: PropTypes.string.isRequired,
  billableAmountLabel: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      amountInCents: PropTypes.number.isRequired,
      timeInMinutes: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  daysOfMonthData: PropTypes.arrayOf(
    PropTypes.shape({
      dayInitial: PropTypes.string.isRequired,
      dayNumber: PropTypes.number.isRequired,
      isCurrentDay: PropTypes.bool.isRequired,
      isSelectedDay: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  collapsed: PropTypes.bool,
  onToggleCollapse: PropTypes.func.isRequired,
  selectedDisplayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  onDisplayModeChange: PropTypes.func.isRequired,
  onDayChange: PropTypes.func.isRequired,
};

FeeSummaryChart.defaultProps = {
  collapsed: false,
  isWidget: false,
};
