'use strict';

/**
 * The Invoice Communicate Modal has the below default message
 * @returns string
 */
function getDefaultCommunicateMessage() {
  const defaultMessage = `Dear [DEBTOR_NAME],

Please see attached invoice, for [MATTER_TITLE]. A total of [AMOUNT_DUE] is now due by [DATE_DUE].`;

  return defaultMessage;
}

module.exports = {
  getDefaultCommunicateMessage,
};
