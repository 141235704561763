import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { featureActive, featureData } from '@sb-itops/feature';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { StripeGetStartedCard } from './StripeGetStartedCard';

const hooks = () => ({
  useStripeSettings: () => {
    const onSecondaryClick = () => {
      const defaultHelpUrl = getSupportHubUrl(); // default region specific support url
      const helpLink = featureActive('BB-12757') ? featureData('BB-12757')?.url : defaultHelpUrl;

      window.open(helpLink, '_blank', 'noopener');
    };

    return {
      onSecondaryClick,
    };
  },
});

export const StripeGetStartedCardContainer = composeHooks(hooks)(StripeGetStartedCard);

StripeGetStartedCardContainer.displayName = 'StripeGetStartedCardContainer';

StripeGetStartedCardContainer.propTypes = {
  onPrimaryClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
