import { asFormikField } from '../as-formik-field';
import ActivityDropdownContainer from './ActivityDropdown.container';

const mapFormikToActivityDropdown = ({ field, form, onChange }) => ({
  selectedActivityCode: field.value,
  onSelectionChange: (activity) => {
    form.setFieldValue(field.name, activity && activity.code, true);
    if (onChange) {
      onChange(activity);
    }
  },
});

const ActivityDropdownFormField = asFormikField(mapFormikToActivityDropdown)(ActivityDropdownContainer);
export default ActivityDropdownFormField;
