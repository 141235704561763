import PropTypes from 'prop-types';
import { withLinkHandler } from '@sb-itops/react/hoc';
import MatterTable from './MatterTable';

const Container = withLinkHandler('matter')(MatterTable);

Container.propTypes = {
  matters: PropTypes.array,
  showStatus: PropTypes.bool,
  showFinancials: PropTypes.bool,
  hideDescription: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  sort: PropTypes.func,
  hideClient: PropTypes.bool,
  onClickLink: PropTypes.func,
};

export default Container;
