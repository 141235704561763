import { featureActive } from '@sb-itops/feature';
import { refreshTokenP, getIsAuthRefreshInProgress, getIsAuthenticated, getUserIdentity } from 'web/services/user-session-management';


const checkAuthTokenExpiry = async (log) => {
  if (!featureActive('BB-11038')) {
    return;
  }

  try {
    // only check if user is authenticated, otherwise we are too late to proactively refresh
    if (getIsAuthenticated()) {
      const identity = getUserIdentity();

      const issuedAt = identity.tokenIssuedAt;
      const expiresAt = identity.tokenExpiresAt;
      const browserTimeAtIssue = identity.browserTimeAtTokenIssue;
      const browserTimeNow = Math.floor(Date.now() / 1000);

      if (issuedAt && expiresAt && browserTimeAtIssue && browserTimeNow) {
        const tokenDelta = expiresAt - issuedAt;
        const browserDelta = browserTimeNow - browserTimeAtIssue;

        // if we have past more than half the lifetime of the token, then refresh
        if (browserDelta > (tokenDelta / 2) && !getIsAuthRefreshInProgress()) {
          refreshTokenP().catch(e => log.warn(`error proactively refreshing token ${e}`));
        }
      }
    }
  } catch (e) {
    log.warn(`Error polling communicate: ${e}`);
  }
}

const ONE_MIN_MILLIS = 1000 * 60 * 1;

let intervalId = null;

export const initialiseProactiveTokenRefresh = async ({ log }) => {
  if (intervalId !== null) {
    throw new Error("Already proactively polling");
  }

  intervalId = setInterval(async () => {
    await checkAuthTokenExpiry(log);
  }, ONE_MIN_MILLIS);

  return () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      intervalId = null;
      log.info("stopped proactive polling");
    }
  }
};
