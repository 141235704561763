import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getList as getTransactions } from '@sb-billing/redux/transactions';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortDirectionFeature from '@sb-itops/redux/sort';

import * as billingContactTransactions from 'web/redux/route/home-billing-contact-transactions';

import { ContactCreditNoteLedger } from './ContactCreditNoteLedger';

const findCreditTransactions = ({ contactId, showDeletedTransactions }) => {
  const creditTransactions = getTransactions().filter((transaction) => {
    const bankAccount = getBankAccountById(transaction.bankAccountId);

    const isCreditTransactionForMatter =
      transaction.contactId === contactId && bankAccount && bankAccount.accountType === 'CREDIT';

    if (showDeletedTransactions) {
      return isCreditTransactionForMatter;
    }

    return isCreditTransactionForMatter && !transaction.isHidden;
  });
  return creditTransactions;
};

const getContactCreditNoteLedgerFeature = (state) => {
  const FEATURE_SCOPE = 'contact-credit-note-ledger';
  return withScopedFeatures({ state, scope: FEATURE_SCOPE })({
    sortDirection: sortDirectionFeature,
  });
};

const mapStateToProps = (state, { contactId, onClickLink }) => {
  const { sortDirection: firmCreditNotesSortDirection } = getContactCreditNoteLedgerFeature(state);
  const sortBy = firmCreditNotesSortDirection.selectors.getSortBy() || undefined;
  const sortDirection = firmCreditNotesSortDirection.selectors.getSortDirection() || undefined;
  const { showHidden } = billingContactTransactions.selectors.getFilters(state);
  const showDeletedTransactions = showHidden;

  const transactions = findCreditTransactions({ contactId, showDeletedTransactions });

  return {
    contactId,
    transactions,
    sortDirection,
    sortBy,
    onClickLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { sortDirection: firmCreditNotesSortDirection } = getContactCreditNoteLedgerFeature();
  return {
    onSort: ({ sortDirection, sortBy }) => {
      dispatch(firmCreditNotesSortDirection.actions.setSortDirection({ sortDirection }));
      dispatch(firmCreditNotesSortDirection.actions.setSortBy({ sortBy }));
    },
  };
};

export const ContactCreditNoteLedgerContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(ContactCreditNoteLedger),
);

ContactCreditNoteLedgerContainer.displayName = 'ContactCreditNoteLedgerContainer';

ContactCreditNoteLedgerContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

ContactCreditNoteLedgerContainer.defaultProps = {};
