angular.module('sb.billing.webapp').service('sbUpgradeService', function ($interval, sbLoggerService, sbAppVersionService, sbBuildVersion, $uibModal) {
  'use strict';

  const log = sbLoggerService.getLogger('sbUpgradeService'),
    twoMinutes = 2 * 60 * 1000;
  let notified = false;

  checkVersionP();
  $interval(checkVersionP, twoMinutes);

  function notify() {
    if (!notified) {
      notified = true;
      log.info(`notify new app version`);
      $uibModal.open({
        backdrop: 'static', // disable click outside modal
        keyboard: false, // disable close on ESC
        templateUrl: 'ng-components/upgrade/upgrade-modal.html',
      });
    }
  }

  function checkVersionP() {
    sbAppVersionService.versionP()
      .then((appVersion) => {
        log.info(`app version: ${appVersion}`);

        if (hasBreakingChanges(appVersion)) {
          notify();
        } else {
          log.debug('no change detected');
        }
        return appVersion;
      });
  }

  function hasBreakingChanges(appVersion) {
    return getMajor(sbBuildVersion.SB_BUILD_VERSION) < getMajor(appVersion);
  }

  function getMajor(version) {
    return version ? version.split('.')[0] : 0;
  }
});
