import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import uuid from '@sb-itops/uuid';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbVendorPaymentsService',
  keyPath: 'paymentId',
});

/**
 * TODO This file was create to fix the RC bounce https://smokeball.atlassian.net/browse/BB-3261.
 * The missing fields & opdate structure will need to be addressed.
 * This has only been tested at a surface level and unit testing/integration testing should be implemented
 */
export const buildOpdates = (payment, accountId, momentNow) => {
  const opdatePartial = {
    accountId,
    description: payment.description,
    effectiveDate: +momentNow.format('YYYYMMDD'),
    matterId: payment.matterId,
    multiPaymentId: payment.payors ? uuid() : undefined,
    note: payment.note,
    payeeId: payment.payeeId,
    reference: payment.reference,
    reversed: false,
    source: payment.source,
    sourceAccountId: `${accountId}/${payment.accountType}`,
    userId: payment.userId,

    // TODO evaluate if these missing fields are required
    // BankAccountName: null,
    // BankAccountNumber: null,
    // BankBranch: null,
    // BankBranchNumber: null,
    // BankName: null,
  };

  return payment.payors
    ? payment.payors.map(({ paymentId, payorId, amount }) => ({
        ...opdatePartial,
        paymentId,
        payorId,
        amount,
      }))
    : [
        {
          ...opdatePartial,
          paymentId: payment.paymentId,
          payorId: payment.payorId,
          amount: payment.amount,
        },
      ];
};
