/**
 * NOTICE:
 *
 * This is a Load on Demand compatible container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { MattersDisplay } from './MattersDisplay';

const mapStateToProps = (
  state,
  {
    className,
    matters,
    allowDuplicates,
    showStatus,
    tooltip,
    asLink,
    inline,
    onClickLink,
    showDescription,
    showOtherSide,
    showMatterNumber,
    showClient,
  },
) => {
  const processedMatterIds = new Set();
  const matterDisplays = matters.reduce((acc, matter) => {
    if (!allowDuplicates && processedMatterIds.has(matter.id)) {
      return acc;
    }

    processedMatterIds.add(matter.id);
    acc.push({
      matterId: matter.id || matter.matterId,
      display: getMatterDisplay(
        matter,
        matter.matterType?.name || '',
        showStatus,
        showDescription,
        showOtherSide,
        showMatterNumber,
        showClient,
      ),
    });
    return acc;
  }, []);

  return {
    className,
    asLink,
    inline,
    matterDisplays,
    tooltip: tooltip || matterDisplays.map(({ display }) => `${display}`).join('\n\n'),
    onClickLink: ({ matterId }) => onClickLink({ type: 'matter', id: matterId }),
  };
};

export const MattersDisplayFromMattersContainer = withReduxStore(connect(mapStateToProps)(MattersDisplay));

MattersDisplayFromMattersContainer.displayName = 'MattersDisplayFromMattersContainer';

MattersDisplayFromMattersContainer.propTypes = {
  className: PropTypes.string,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  allowDuplicates: PropTypes.bool,
  showStatus: PropTypes.bool,
  showDescription: PropTypes.bool,
  showOtherSide: PropTypes.bool,
  showMatterNumber: PropTypes.bool,
  showClient: PropTypes.bool,
  tooltip: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  onClickLink: PropTypes.func,
};

MattersDisplayFromMattersContainer.defaultProps = {
  className: undefined,
  allowDuplicates: false,
  showStatus: false,
  showDescription: false,
  showOtherSide: true,
  showMatterNumber: true,
  showClient: true,
  tooltip: undefined,
  asLink: false,
  inline: true,
  onClickLink: () => {},
};
