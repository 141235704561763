import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { toggleShowFilters, getShowFilters } from 'web/redux/route/home-billing-contacts-payment-plans';
import ContactListFilterPanel from './ContactPaymentPlanListFilterPanel';

const contactStatusOptions = [
  { id: 'current', label: 'Current' },
  { id: 'deleted', label: 'Deleted' },
];

const mapStateToProps = (state, { contactListFilterScope, contactStatusSelections, onContactStatusSelect }) => {
  const expanded = getShowFilters(state);

  return {
    contactListFilterScope,
    expanded,
    toggleExpanded: toggleShowFilters,

    // Statuses
    contactStatusOptions,
    selectedContactStatuses: Object.keys(contactStatusSelections),
    onContactStatusSelect,
  };
};

const ContactPaymentPlanListFilterPanelContainer = withReduxStore(connect(mapStateToProps, {})(ContactListFilterPanel));

ContactPaymentPlanListFilterPanelContainer.displayName = 'ContactPaymentPlanListFilterPanelContainer';

ContactPaymentPlanListFilterPanelContainer.propTypes = {
  contactListFilterScope: PropTypes.string.isRequired,
  contactStatusCounts: PropTypes.object.isRequired,
  contactStatusCountsLoading: PropTypes.bool.isRequired,
  contactStatusSelections: PropTypes.object.isRequired,
  onContactStatusSelect: PropTypes.func.isRequired,
};

export default ContactPaymentPlanListFilterPanelContainer;
