import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { utilsFactory } from '@sb-itops/redux';
import { sort } from '@sb-itops/sort';
import { getList as getInvoices, getStatusesFilter } from '@sb-billing/redux/invoices';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import {
  getSettings as getBulkFinaliseSettings,
  mapBankAccountTypesToBankAccounts,
  getPreferredBankAccountTypes,
} from '@sb-billing/redux/bulk-finalize-settings';
import * as bankAccountBalances from '@sb-billing/redux/bank-account-balances.2';
import { startOfThisMonth, endOfThisMonth, dateToInteger as toEffectiveDate } from '@sb-itops/date';
import { getMap as getMatters } from '@sb-matter-management/redux/matters';

import { wrappedPersistReducer } from 'web/services/bootstrapper/register-redux-reducers';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { domain } from '../../domain';
import { createInvoiceFilter } from './create-invoice-filter';
import { allocateMatterPaymentsForInvoices } from './allocator';

const { getAllMatterBalances } = bankAccountBalances.selectors;

const reduxPath = `${domain}/billing-bills-finalise-with-payments`;
const { registerReducer, getState: getPageState } = utilsFactory({ reduxPath });

const draftInvoiceFilter = getStatusesFilter(['DRAFT']);

const filterInvoices = (invoices, filters) => {
  const filterFn = createInvoiceFilter(filters);
  return invoices.reduce((all, invoice) => {
    if (filterFn(invoice)) {
      all.push(invoice.currentVersion);
    }
    return all;
  }, []);
};

const filteredInvoicesSelector = createSelector(
  (state) => getInvoices(state),
  (state) => getState(state).filters,
  // the following are not actually used in `filterInvoices` - this is due to
  // `createInvoiceFilter` doing its own data fetching
  (state) => getAllMatterBalances(state),
  () => getMatters(),
  filterInvoices,
);
export const getFilteredInvoices = (state) => filteredInvoicesSelector(state);
export const getShowFilters = (state) => getState(state).showFilters;

const shownMatterTypeIdsSelector = createSelector(
  (state) => getInvoices(state),
  () => getMatters(),
  (invoices, matters) => [
    ...new Set(
      invoices.reduce((matterTypeIds, invoice) => {
        // get the matterTypes associated with draftInvoices on matters
        // that are not deleted
        if (draftInvoiceFilter(invoice) && matters[invoice.matterId]) {
          matterTypeIds.push(matters[invoice.matterId].matterTypeId);
        }
        return matterTypeIds;
      }, []),
    ),
  ],
);
export const getShownMatterTypeIds = (state) => shownMatterTypeIdsSelector(state);

const selectedInvoiceIdsSelector = createSelector(
  (state) => getFilteredInvoices(state),
  (state) => getState(state).selected,
  (shownInvoices, selected) =>
    shownInvoices.reduce((acc, invoice) => {
      if (selected[invoice.invoiceId]) {
        acc.push(invoice.invoiceId);
      }
      return acc;
    }, []),
);
export const getSelectedInvoiceIds = (state) => selectedInvoiceIdsSelector(state);

const paymentsSelector = createSelector(
  (state) => getState(state).payments,
  (state) => getState(state).selected,
  (state) => getState(state).autoAllocations,
  (state) => getState(state).filters?.trustBankAccountId,
  (state) => getBulkFinaliseSettings(state),
  (state) => getAllMatterBalances(state),
  (state) => getFilteredInvoices(state),
  (payments, selected, autoAllocations, trustBankAccountId, bulkFinaliseSettings, matterBalances, filteredInvoices) => {
    const matterIdToInvoices = filteredInvoices.reduce((matterMap, invoice) => {
      if (matterMap[invoice.matterId]) {
        matterMap[invoice.matterId].push(invoice);
      } else {
        // eslint-disable-next-line no-param-reassign
        matterMap[invoice.matterId] = [invoice];
      }
      return matterMap;
    }, {});

    const preferredBankAccounts = mapBankAccountTypesToBankAccounts({
      accountTypes: getPreferredBankAccountTypes(bulkFinaliseSettings),
      bankAccountsByType: {
        TRUST: getBankAccountById(trustBankAccountId),
        // We want OPERATING and CREDIT default acccounts so don't need to specify
      },
    });

    const autoAllocatedPayments = Object.keys(matterIdToInvoices).reduce((autoPayments, matterId) => {
      const matterBalance = matterBalances[matterId] || 0;
      // business rule: Matters added to the list after its already loaded should come in with the auto allocate toggle on
      const isAutoAllocated = autoAllocations[matterId] === undefined || autoAllocations[matterId];

      if (!isAutoAllocated || !matterBalance) {
        return autoPayments;
      }

      const invoiceIds = sort(matterIdToInvoices[matterId], ['issuedDate', 'validFrom']).reduce((acc, invoice) => {
        const invoiceId = invoice.invoiceId;
        if (selected[invoiceId]) {
          acc.push(invoiceId);
        }
        return acc;
      }, []);
      const invoiceAmounts = invoiceIds.reduce((acc, invoiceId) => {
        acc[invoiceId] = getTotalsForInvoiceId(invoiceId).unpaidExcInterest;
        return acc;
      }, {});

      return {
        ...autoPayments,
        ...allocateMatterPaymentsForInvoices({ invoiceIds, matterBalance, invoiceAmounts, preferredBankAccounts }),
      };
    }, {});

    return {
      ...payments,
      ...autoAllocatedPayments,
    };
  },
);
export const getPayments = (state) => paymentsSelector(state);

export const getState = (state) => getPageState(state).finaliseWithPayments;

const types = {
  TOGGLE_SHOW_FILTERS: `${reduxPath}/TOGGLE_SHOW_FILTERS`,
  SET_FILTER: `${reduxPath}/SET_FILTER`,
  SET_VISIBILITY: `${reduxPath}/SET_VISIBILITY`,
  RESET_FILTERS: `${reduxPath}/RESET_FILTERS`,
  SORT_ROWS: `${reduxPath}/SORT_ROWS`,
  SET_EXPAND: `${reduxPath}/SET_EXPAND`,
  SET_EXPAND_ALL: `${reduxPath}/SET_EXPAND_ALL`,
  SET_SELECTED: `${reduxPath}/SET_SELECTED`,
  SET_SELECTED_ALL: `${reduxPath}/SET_SELECTED_ALL`,
  SET_SELECTED_MANY: `${reduxPath}/SET_SELECTED_MANY`,
  SET_INITIALISED: `${reduxPath}/SET_INITIALISED`,
  SET_CHEQUE_PRINTING_METHOD: `${reduxPath}/SET_CHEQUE_PRINTING_METHOD`,
  SET_PAYMENT_AMOUNT: `${reduxPath}/SET_PAYMENT_AMOUNT`,
  SET_PAYMENT_AMOUNT_MANY: `${reduxPath}/SET_PAYMENT_AMOUNT_MANY`,
  SET_PAYMENT_AMOUNT_ALL: `${reduxPath}/SET_PAYMENT_AMOUNT_ALL`,
  SET_AUTO_ALLOCATE: `${reduxPath}/SET_AUTO_ALLOCATE`,
  SET_AUTO_ALLOCATE_ALL: `${reduxPath}/SET_AUTO_ALLOCATE_ALL`,
};

// These options don't belong here, but have been put here to
// get around a problem with local code + immu, as a live patch is being
// worked on.
const MATTER_STATUS_FILTER_OPTIONS = [
  { id: 'Open', label: 'Open' },
  { id: 'Pending', label: 'Pending' },
  { id: 'Closed', label: 'Closed' },
];

const INITIAL_FILTER_STATE = {
  filters: {
    billingTypes: {
      allSelected: true,
    },
    issueDate: {
      before: toEffectiveDate(new Date()),
      from: toEffectiveDate(startOfThisMonth()),
      to: toEffectiveDate(endOfThisMonth()),
      filterType: 'ALL',
      startDate: undefined,
      endDate: undefined,
    },
    matterTypes: [],
    matterStatuses: MATTER_STATUS_FILTER_OPTIONS.map((option) => option.id),
  },
  showFilters: true,
  expanded: {},
  selected: {},
  payments: {},
  autoAllocations: {},
  visible: {
    billingTypes: true,
    matterStatuses: true,
    attorneysResponsible: true,
    matterBalances: true,
    matterTypes: true,
    issueDate: true,
  },
  sort: {
    sortDirection: 'asc',
    sortBy: 'matterDisplay',
  },
  initialised: false,
  chequePrintingMethod: undefined,
};

const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_INITIALISED: {
      return {
        ...state,
        initialised: action.payload.initialised,
      };
    }
    case types.SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
    }
    case types.SET_VISIBILITY: {
      return {
        ...state,
        visible: {
          ...state.visible,
          [action.payload.filterName]: action.payload.visible,
        },
      };
    }
    case types.SORT_ROWS: {
      const { sortDirection, sortBy } = action.payload;
      return {
        ...state,
        sort: {
          ...state.sort,
          sortDirection,
          sortBy,
        },
      };
    }
    case types.TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case types.RESET_FILTERS: {
      return {
        ...state,
        filters: INITIAL_FILTER_STATE.filters,
      };
    }
    case types.SET_EXPAND: {
      const { matterId, expanded } = action.payload;
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [matterId]: expanded,
        },
      };
    }
    case types.SET_EXPAND_ALL: {
      const { matterIds, expanded } = action.payload;

      return {
        ...state,
        expanded: matterIds.reduce((all, matterId) => {
          // eslint-disable-next-line no-param-reassign
          all[matterId] = expanded;
          return all;
        }, {}),
      };
    }
    case types.SET_SELECTED: {
      const { invoiceId, selected } = action.payload;
      return {
        ...state,
        selected: {
          ...state.selected,
          [invoiceId]: selected,
        },
      };
    }
    case types.SET_SELECTED_ALL: {
      const { invoiceIds, selected } = action.payload;
      return {
        ...state,
        selected: invoiceIds.reduce((acc, invoiceId) => {
          acc[invoiceId] = selected;
          return acc;
        }, {}),
      };
    }
    case types.SET_SELECTED_MANY: {
      const { invoiceIds, selected } = action.payload;
      return {
        ...state,
        selected: {
          ...state.selected,
          ...invoiceIds.reduce((acc, invoiceId) => {
            acc[invoiceId] = selected;
            return acc;
          }, {}),
        },
      };
    }
    case types.SET_CHEQUE_PRINTING_METHOD: {
      return {
        ...state,
        chequePrintingMethod: action.payload.chequePrintingMethod,
      };
    }
    case types.SET_PAYMENT_AMOUNT: {
      const { invoiceId, bankAccountId, amount } = action.payload;
      return {
        ...state,
        payments: {
          ...state.payments,
          [invoiceId]: {
            ...(state.payments[invoiceId] || {}),
            [bankAccountId]: amount,
          },
        },
      };
    }
    case types.SET_PAYMENT_AMOUNT_MANY: {
      const { payments } = action.payload;
      return {
        ...state,
        payments: {
          ...state.payments,
          ...payments,
        },
      };
    }
    case types.SET_PAYMENT_AMOUNT_ALL: {
      const { payments } = action.payload;
      return {
        ...state,
        payments,
      };
    }
    case types.SET_AUTO_ALLOCATE: {
      const { matterId, autoAllocate } = action.payload;
      return {
        ...state,
        autoAllocations: {
          ...state.autoAllocations,
          [matterId]: autoAllocate,
        },
      };
    }
    case types.SET_AUTO_ALLOCATE_ALL: {
      const { matterIds, autoAllocate } = action.payload;
      return {
        ...state,
        autoAllocations: matterIds.reduce((acc, matterId) => {
          acc[matterId] = autoAllocate;
          return acc;
        }, {}),
      };
    }
    default: {
      return state;
    }
  }
};

// As a part of the requirements for BB-1203: when a selected invoice is filtered out and then shown
// again, it should not be selected.
export const changeFilter = (filterName, filterValue) => async (dispatch, getGlobalState) => {
  // update the filters
  await dispatch(setFilter(filterName, filterValue));

  // the new shown invoices
  const filteredInvoices = getFilteredInvoices(getGlobalState());
  // the old selected invoices
  const selected = getState(getGlobalState()).selected;

  // in SQL terms:
  // new selected invoices = old selected invoices JOIN new shown invoices
  const newSelectedInvoices = filteredInvoices.reduce((acc, invoice) => {
    if (selected[invoice.invoiceId]) {
      acc.push(invoice.invoiceId);
    }
    return acc;
  }, []);
  dispatch(setSelectedAll(newSelectedInvoices, true));
};

// only used internally - see explaination for `changeFilter`
const setFilter = (filterName, filterValue) => ({
  type: types.SET_FILTER,
  payload: {
    filterName,
    filterValue,
  },
});

export const toggleShowFilters = () => ({
  type: types.TOGGLE_SHOW_FILTERS,
});

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});

export const setVisibility = (filterName, visible) => ({
  type: types.SET_VISIBILITY,
  payload: {
    filterName,
    visible,
  },
});

export const sortRows = ({ sortBy, sortDirection }) => ({
  type: types.SORT_ROWS,
  payload: {
    sortDirection,
    sortBy,
  },
});

export const setExpand = (matterId, expanded) => ({
  type: types.SET_EXPAND,
  payload: {
    matterId,
    expanded,
  },
});

export const setExpandAll = (matterIds, expanded) => ({
  type: types.SET_EXPAND_ALL,
  payload: {
    matterIds,
    expanded,
  },
});

const setSelected = (invoiceId, selected) => ({
  type: types.SET_SELECTED,
  payload: {
    invoiceId,
    selected,
  },
});

const setSelectedMany = (invoiceIds, selected) => ({
  type: types.SET_SELECTED_MANY,
  payload: {
    invoiceIds,
    selected,
  },
});

const setSelectedAll = (invoiceIds, selected) => ({
  type: types.SET_SELECTED_ALL,
  payload: {
    invoiceIds,
    selected,
  },
});

export const changeSelected = (invoiceId, selected) => async (dispatch, getGlobalState) => {
  await dispatch(setSelected(invoiceId, selected));
  return dispatch(setPaymentAmountAll(getPayments(getGlobalState())));
};

export const changeSelectedMany = (invoiceIds, selected) => async (dispatch, getGlobalState) => {
  await dispatch(setSelectedMany(invoiceIds, selected));
  return dispatch(setPaymentAmountAll(getPayments(getGlobalState())));
};

export const changeSelectedAll = (invoiceIds, selected) => async (dispatch, getGlobalState) => {
  await dispatch(setSelectedAll(invoiceIds, selected));
  return dispatch(setPaymentAmountAll(getPayments(getGlobalState())));
};

export const setInitialised = (initialised) => ({
  type: types.SET_INITIALISED,
  payload: {
    initialised,
  },
});

export const setChequePrintingMethod = (chequePrintingMethod) => ({
  type: types.SET_CHEQUE_PRINTING_METHOD,
  payload: {
    chequePrintingMethod,
  },
});

export const setPaymentAmount = (bankAccountId, invoiceId, amount) => ({
  type: types.SET_PAYMENT_AMOUNT,
  payload: {
    bankAccountId,
    invoiceId,
    amount,
  },
});

export const setPaymentAmountMany = (payments) => ({
  type: types.SET_PAYMENT_AMOUNT_MANY,
  payload: {
    payments,
  },
});

export const setPaymentAmountAll = (payments) => ({
  type: types.SET_PAYMENT_AMOUNT_ALL,
  payload: {
    payments,
  },
});

const setAutoAllocate = (matterId, autoAllocate) => ({
  type: types.SET_AUTO_ALLOCATE,
  payload: {
    matterId,
    autoAllocate,
  },
});

const setAutoAllocateAll = (matterIds, autoAllocate) => ({
  type: types.SET_AUTO_ALLOCATE_ALL,
  payload: {
    matterIds,
    autoAllocate,
  },
});

export const changeAutoAllocateAll = (matterIds, autoAllocate) => async (dispatch, getGlobalState) => {
  await dispatch(setAutoAllocateAll(matterIds, autoAllocate));
  return dispatch(setPaymentAmountAll(getPayments(getGlobalState())));
};

export const changeAutoAllocate = (matterId, autoAllocate) => async (dispatch, getGlobalState) => {
  await dispatch(setAutoAllocate(matterId, autoAllocate));
  return dispatch(setPaymentAmountAll(getPayments(getGlobalState())));
};

registerReducer(
  combineReducers({
    finaliseWithPayments: wrappedPersistReducer(
      { defaultPath: `${reduxPath}/finaliseWithPayments`, reducer },
      { persist: true, whitelist: ['showFilters'] },
    ),
  }),
);
