'use strict';

/**
 * @function calculateFeeDescriptionMaxLength
 * @param {string} feeDescription
 *
 * Dynamically determines the max length of the description field (NB: maps to the notes property of the fee entity)
 *
 * The default max length should be 1000. However, if the existing data is already >1000, that value will be the max length.
 *
 * Pass in fee entity data, not form data, so the max length remains constant when editing the field value (prior to saving).
 * The user should be able to make unsaved edits and revert back to original value
 * E.g. 1100 characters -> 900 characters -> 1100 characters
 *
 * @returns {number}
 */
function calculateFeeDescriptionMaxLength(feeDescription) {
  if (!feeDescription) {
    return 1000;
  }

  return feeDescription.length > 1000 ? feeDescription.length : 1000;
}

module.exports = {
  calculateFeeDescriptionMaxLength,
};
