import { gql } from '@apollo/client';

const query = gql`
  query OperatingChequeAvailableNumbers($filter: OperatingChequeAvailableNumbersFilter) {
    operatingChequeAvailableNumbers(filter: $filter) {
      availableChequeNumbers
      lastChequeNumber
      nextChequeNumber
    }
  }
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpensePaymentDetailsUpdated',
  'AccountsNotifications.OperatingChequeUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const OperatingChequeAvailableNumbers = {
  query,
  notificationIds,
};
