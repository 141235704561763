import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, FormLabel, forms2PropTypes, CurrencyInput2, CurrencyDisplay } from '@sb-itops/react';
import { dateToInteger, integerToDate, today } from '@sb-itops/date';
import classnames from 'classnames';
import Styles from './AddBankFeeInterestForm.module.scss';
import { MatterTypeahead } from '../matter-typeahead';

const AddBankFeeInterestForm = ({
  scope,
  isFee,
  accountOpenedDate,
  allowFutureDate,
  balanceLabel,
  matterReadOnly,
  // form fields
  balanceAfterChange,
  effectiveDate,
  matterId,
  amount,
  internalNote,
  // form
  onUpdateFields,
  onAmountUpdate,
  onMatterUpdate,
  formDisabled,
  submitFailed,
  onValidateForm,
  formInitialised,
}) => {
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <div className={Styles.container} id={scope}>
      <fieldset>
        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel label="Date Processed" field={effectiveDate} submitFailed={submitFailed} />
            <DatePicker
              onSelect={(date) => {
                onUpdateFields({ effectiveDate: date ? dateToInteger(date) : undefined });
                onValidateForm();
              }}
              value={effectiveDate && effectiveDate.value && integerToDate(effectiveDate.value)}
              hasError={effectiveDate && effectiveDate.isInvalid}
              format="DD/MM/YYYY"
              minDate={integerToDate(accountOpenedDate)}
              maxDate={allowFutureDate ? undefined : today()}
              disabled={formDisabled}
            />
          </div>

          <div className="col-xs-6 form-group">
            <label>Matter</label>
            <MatterTypeahead
              onValueChange={(newMatter) => {
                onMatterUpdate({ amount: amount?.value || 0, matterId: newMatter?.id });
                onValidateForm();
              }}
              initialId={matterId?.value}
              filter="open"
              disabled={matterReadOnly}
              hasError={!!isErrorClassnames(matterId)}
            />
          </div>
        </div>
        {isErrorClassnames(effectiveDate) && effectiveDate.invalidReason && effectiveDate.value && (
          <div className="row">
            <div className={classnames('col-lg-12 form-group', Styles.errorMessage)}>{effectiveDate.invalidReason}</div>
          </div>
        )}

        <div className="row">
          <div className="col-xs-6 form-group">
            <FormLabel label="Amount" field={amount} submitFailed={submitFailed} />
            <CurrencyInput2
              name="amount"
              hasError={!!isErrorClassnames(amount)}
              value={amount?.value || undefined}
              disabled={formDisabled}
              onChange={(e) => {
                onAmountUpdate({ amount: e.target.value, matterId: matterId?.value });
                onValidateForm();
              }}
            />
          </div>
          <div className="col-xs-6 form-group">
            <label>{`${balanceLabel} After ${isFee ? 'Fees' : 'Interest'}`}</label>
            <CurrencyDisplay amount={balanceAfterChange?.value || 0} />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 form-group">
            <FormLabel label="Internal Note" field={internalNote} submitFailed={submitFailed} optional />
            <div>
              <textarea
                className={classnames('form-control', isErrorClassnames(internalNote))}
                rows="5"
                value={internalNote?.value || ''}
                disabled={formDisabled}
                onChange={(e) => {
                  onUpdateFields({ internalNote: e.target.value });
                }}
                onBlur={onValidateForm}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

AddBankFeeInterestForm.displayName = 'AddBankFeeInterestForm';
AddBankFeeInterestForm.propTypes = {
  scope: PropTypes.string.isRequired,
  isFee: PropTypes.bool.isRequired,
  accountOpenedDate: PropTypes.number.isRequired,
  allowFutureDate: PropTypes.bool.isRequired,
  matterReadOnly: PropTypes.bool.isRequired,
  balanceLabel: PropTypes.string.isRequired,
  // fields
  balanceAfterChange: Forms2Field,
  effectiveDate: Forms2Field,
  matterId: Forms2Field,
  amount: Forms2Field,
  internalNote: Forms2Field,
  // form
  onUpdateFields: PropTypes.func.isRequired,
  onAmountUpdate: PropTypes.func.isRequired,
  onMatterUpdate: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

AddBankFeeInterestForm.defaultProps = {
  balanceAfterChange: undefined,
  effectiveDate: undefined,
  matterId: undefined,
  amount: undefined,
  internalNote: undefined,
};

export default AddBankFeeInterestForm;
