/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { payButtonEnabledOptionValues } from '@sb-billing/business-logic/matters/invoice-settings';

import { canIncludePaymentLinks } from '../payment-provider-settings/selectors';
import domain from '../domain';

export const titleLineOptionByValue = {
  0: 'None',
  1: 'MatterDescription',
  2: 'Custom',
  3: 'MatterTitle',
};

export const titleLineOptionsStringEnums = {
  none: 'None',
  custom: 'Custom',
  matterDescription: 'MatterDescription',
  matterTitle: 'MatterTitle',
};

const api = cacheFactory({
  domain,
  name: 'matter-invoice-settings',
  keyPath: 'matterId',
  ngCacheName: 'sbMatterInvoiceSettingsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbMatterInvoiceSettingsService',
  keyPath: 'matterId',
});

const withStringAsEnums = createSelector(
  (state) => state || {},
  (matterInvoiceSetting) => ({
    ...matterInvoiceSetting,
    titleLine1Option:
      matterInvoiceSetting.titleLine1Option !== undefined &&
      titleLineOptionByValue[matterInvoiceSetting.titleLine1Option],
    titleLine2Option:
      matterInvoiceSetting.titleLine2Option !== undefined &&
      titleLineOptionByValue[matterInvoiceSetting.titleLine2Option],
    footer: matterInvoiceSetting.footer,
  }),
);

export const { getById, getMap, getList, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;

export const getByMatterIdWithStringAsEnums = (matterId) => {
  const valueById = getById(matterId);
  if (valueById) {
    return withStringAsEnums(valueById);
  }
  return undefined;
};

export const getByMatterId = (matterId) => getById(matterId);

export * from './save-matter-invoice-settings';

/**
 * isPayButtonEnabledForFirmAndMatter
 *
 * This check is required to determine whether or not payment buttons (and QR codes) should be displayed.
 *
 * The default value should be set to true, unless set otherwise.
 *
 * @param {object} params
 * @param {string} params.matterId
 * @returns boolean
 */
export function isPayButtonEnabledForFirmAndMatter({ matterId }) {
  if (!matterId) {
    return false;
  }

  const isEnabledForFirm = canIncludePaymentLinks();
  const matterInvoiceSetting = getById(matterId);

  if (!matterInvoiceSetting) {
    return isEnabledForFirm;
  }

  const isEnabledForMatter = !(matterInvoiceSetting.payButtonEnabledOption === payButtonEnabledOptionValues.DISABLED);
  const result = isEnabledForFirm && isEnabledForMatter;
  return result;
}
