angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/trust-statement';

  $stateProvider
    .state('home.billing.support.trust-statement', {
      url: '/trust-statement',
      templateUrl: BASE_DIRECTORY + '/trust-statement.html',
      controller: 'sbTrustStatementCancelController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});