'use strict';

/**
 * Derive section options from invoice/template additional options
 *
 * Context: section Options is only made available as part of BB-12385.
 * All past invoice settings template and invoices uses additionalOptions
 * to store supplementary table settings.
 *
 * This is needed in one scenarios so far
 * 1. Users want to preview draft invoice PDF but the template they selected for the invoice doesn't have sectionOptions setting
 *    i.e. firm haven't made any changes to invoice settings template since BB-12385 is released
 *
 * @param {object} invoiceSettingsTemplate - invoice/template additional options
 * @returns {object} - sectionOptions derived from additionalOptions
 */
const deriveSectionOptionsFromAdditionalOptions = ({ invoiceSettingsTemplate }) => {
  const invoiceAdditionalOptions = invoiceSettingsTemplate.invoiceAdditionalOptions;
  const { feeLineItemConfiguration, expenseLineItemConfiguration } = invoiceSettingsTemplate.defaultLayout;
  const showActivityList = feeLineItemConfiguration === 2 || expenseLineItemConfiguration === 2;

  const sectionOptions = {
    feeSummary: {
      showSection: invoiceAdditionalOptions.showSummaryForTimekeepers,
      lineBreakBefore: true,
      showTimekeeperRole: invoiceAdditionalOptions.showSummaryForTimekeepers
        ? !!invoiceAdditionalOptions.showTimekeeperRole
        : false,
    },
    priorBalances: {
      showSection: !invoiceAdditionalOptions.hidePriorBalance,
      lineBreakBefore: !invoiceAdditionalOptions.showSummaryForTimekeepers,
    },
    paymentSummary: {
      showSection: !invoiceAdditionalOptions.hidePaymentSummary,
      lineBreakBefore: false,
    },
    invoiceSummary: {
      showSection: invoiceAdditionalOptions.showInvoiceSummary,
      lineBreakBefore: !showActivityList,
    },
    accountSummary: {
      showSection: invoiceAdditionalOptions.showAccountSummary,
      lineBreakBefore: false,
    },
    transactionHistory: {
      showSection: invoiceAdditionalOptions.showTransactionHistory,
      lineBreakBefore: false,
    },
  };

  return sectionOptions;
};

module.exports = {
  deriveSectionOptionsFromAdditionalOptions,
};
