angular.module('sb.billing.webapp').component('sbHookPostActionSetData',
  {
    bindings: { composeKey: '@?', entityDataType: '@?' },
    require: { group: '^sbComposeGroup',  compose: '^sbCompose' },
    controller: function () {
      'use strict';
      var ctrl = this;

      ctrl.$onInit = function() {
        ctrl.group.setActionPostHook(setData);
      };

      function setData(postActionData) {
        var dataType = ctrl.entityDataType || ctrl.composeKey || 'post-action-data';

        ctrl.compose.setComposeData({ dataType: dataType, data: postActionData }, ctrl.composeKey);
      }
    }
  }
);
