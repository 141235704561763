import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { InvoiceDisplay } from '@sb-billing/react/invoice-display';
import { useTranslation } from '@sb-itops/react';

export const PaymentInvoicesTable = memo(({ invoicePayments, showPayor, onClickLink, showMatter, showSource }) => {
  const { t } = useTranslation();
  if (!invoicePayments || !invoicePayments.length) {
    return null;
  }

  const totalAmount = invoicePayments.reduce((acc, { amount }) => acc + amount, 0);
  const extraColumnsCount = [showPayor, showMatter, showSource].filter(Boolean).length;
  const columns = 2 + extraColumnsCount;
  const columnClass = `col-${columns}`;

  return (
    <div className="transaction-invoice-table">
      <div className="transaction-list sb-table">
        <div className={classnames('sb-table-header', columnClass, showSource ? 'with-source' : '')}>
          <div className="sb-table-cell invoice">Invoice</div>
          {showMatter && <div className="sb-table-cell matter">Matter</div>}
          {showPayor && <div className="sb-table-cell payor">Paid By</div>}
          {showSource && <div className="sb-table-cell source">Source</div>}
          <div className="sb-table-cell amount">Amount</div>
        </div>

        <div className="sb-table-content list" style={{ maxHeight: '200px' }}>
          {invoicePayments.map(({ paymentId, invoiceId, matterId, payorId, amount, source }) => (
            <div
              className={classnames('item', columnClass, showSource ? 'with-source' : '')}
              key={`${paymentId}_${invoiceId}`}
            >
              <div className="sb-table-cell invoice">
                <InvoiceDisplay onClickLink={onClickLink} invoiceId={invoiceId} asLink inline />
              </div>
              {showMatter && (
                <div className="sb-table-cell matter">
                  <MatterDisplay onClickLink={onClickLink} matterId={matterId} asLink inline />
                </div>
              )}
              {showPayor && (
                <div className="sb-table-cell payor">
                  <ContactDisplay onClickLink={onClickLink} contactId={payorId} showLastNameFirst asLink inline />
                </div>
              )}
              {showSource && <div className="sb-table-cell source">{source}</div>}
              <div className="sb-table-cell amount">{t('cents', { val: amount || 0 })}</div>
            </div>
          ))}
        </div>

        <div className={classnames('sb-table-footer', 'item', columnClass, showSource ? 'with-source' : '')}>
          <div className="sb-table-cell invoice" />
          {showMatter && <div className="sb-table-cell matter" />}
          {showPayor && <div className="sb-table-cell payor" />}
          {showSource && <div className="sb-table-cell source" />}
          <div className="sb-table-cell amount">{t('cents', { val: totalAmount || 0 })}</div>
        </div>
      </div>
    </div>
  );
});

PaymentInvoicesTable.propTypes = {
  invoicePayments: PropTypes.arrayOf(
    PropTypes.shape({
      paymentId: PropTypes.string,
      invoiceId: PropTypes.string,
      matterId: PropTypes.string,
      amount: PropTypes.number,
      payorId: PropTypes.string,
      source: PropTypes.string,
    }),
  ).isRequired,
  onClickLink: PropTypes.func.isRequired,
  showPayor: PropTypes.bool,
  showMatter: PropTypes.bool.isRequired,
  showSource: PropTypes.bool.isRequired,
};

PaymentInvoicesTable.defaultProps = {
  showPayor: false,
};
