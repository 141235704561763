import React, { memo } from 'react';
import { MattersDisplayFromMatters } from '@sb-matter-management/react/matters-display';
import ContactDisplay from '@sb-customer-management/react/contact-display/ContactDisplay';
import PropTypes from 'prop-types';
import { CollapsibleHeader } from '@sb-itops/react';

import Styles from './ExpandableEntityList.module.scss';

export const entityTypes = {
  MATTER: 'MATTER',
  CONTACT: 'CONTACT',
};

const ExpandableEntityList = memo(({ label, expanded, list, onClickLink, onToggleClick }) => (
  <div className={Styles.container}>
    {label && (
      <div className={Styles.header}>
        <CollapsibleHeader text={label} onClick={onToggleClick} name={label} collapsed={!expanded} />
      </div>
    )}
    {expanded &&
      list.map(({ entityType, entity }) => (
        <div className={Styles.label} key={entity.matterId || entity.id}>
          <div className={Styles.link}>
            {entityType === entityTypes.MATTER && (
              <MattersDisplayFromMatters matters={[entity]} onClickLink={onClickLink} asLink />
            )}
            {entityType === entityTypes.CONTACT && (
              <ContactDisplay contactId={entity.id} displayName={entity.displayName} onClickLink={onClickLink} asLink />
            )}
          </div>
        </div>
      ))}
  </div>
));

ExpandableEntityList.propTypes = {
  label: PropTypes.string,
  expanded: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      entityType: PropTypes.string.isRequired,
      entity: PropTypes.object.isRequired,
    }),
  ),
  onClickLink: PropTypes.func.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};

ExpandableEntityList.defaultProps = {
  label: undefined,
  list: [],
  expanded: false,
};

export default ExpandableEntityList;
