angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/dashboard';

  $stateProvider

  .state('home.billing.dashboard', {
    url: '/dashboard',
    reloadOnSearch: false,
    templateUrl: BASE_DIRECTORY + '/dashboard.html',
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
    data: {
      authorized: true,
      navHighlights: ['billing', 'dashboard']
    }
  });

});
