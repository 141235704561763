import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

import { CreditCardSaveModal } from './CreditCardSaveModal';

export const CreditCardSaveModalContainer = withReduxProvider(({ onSubmit, selectedCard, ...props }) => {
  const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
  const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
  const isSubmitting = triggerChargeFormSubmit || props.isSubmitting;

  const onHandleSubmit = async (cardFormData) => {
    await onSubmit(cardFormData);
    setTriggerChargeFormSubmit(false);
  };

  const setTriggerChargeFormSubmitWrapper = () => setTriggerChargeFormSubmit(true);
  const setChargeFormReadyForSubmitWrapper = (isReady) => setChargeFormReadyForSubmit(!!isReady);

  return (
    <CreditCardSaveModal
      {...props}
      isSubmitting={isSubmitting}
      triggerChargeFormSubmit={triggerChargeFormSubmit}
      onTriggerSubmit={setTriggerChargeFormSubmitWrapper}
      readyForSubmit={chargeFormReadyForSubmit}
      onChargeFormReadyForSubmit={setChargeFormReadyForSubmitWrapper}
      onChargeFormSubmit={onHandleSubmit}
      selectedCard={selectedCard}
    />
  );
});

CreditCardSaveModalContainer.displayName = 'CreditCardSaveModalContainer';

CreditCardSaveModalContainer.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  selectedCard: PropTypes.object,
  contactId: PropTypes.string.isRequired,
};

CreditCardSaveModalContainer.defaultProps = {
  selectedCard: undefined,
};
