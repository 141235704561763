import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DatePicker } from '@sb-itops/react/date-picker.2';
import {
  startOfThisMonth,
  startOfLastMonth,
  endOfThisMonth,
  endOfLastMonth,
  startOfToday,
  endOfToday,
  dateToInteger,
  integerToDate,
  today,
  dateRangeTypes,
} from '@sb-itops/date';
import { getLogger } from '@sb-itops/fe-logger';

import Styles from './DateListFilter.module.scss';

const log = getLogger('DateListFilter');
const DATE_FORMAT = 'DD/MM/YY';

const DateListFilter = memo(
  ({
    className,
    onChange,
    selectedFilter,
    from,
    to,
    before,
    showToday,
    showThisMonth,
    showLastMonth,
    showBefore,
    showAll,
  }) => {
    const isSelected = (option) => selectedFilter === option;
    const onFilterChanged = (filterType, filterValue) => {
      onChange({
        ...getNewFilter(filterType, filterValue),
        selectedFilter: filterType, // Interop with redux filter stores so the props can be passed in without modification
        restrictDate: filterType, // Interop with angular filter functions
        before,
        from,
        to,
        ...(filterValue || {}),
      });
    };

    return (
      <ul className={classnames(Styles.dateListFilter, className)}>
        {showAll && (
          <div
            onClick={() => onFilterChanged(dateRangeTypes.ALL)}
            className={classnames(Styles.lineItem, isSelected(dateRangeTypes.ALL) && Styles.selected)}
          >
            All
          </div>
        )}
        {showToday && (
          <div
            onClick={() => onFilterChanged(dateRangeTypes.TODAY)}
            className={classnames(Styles.lineItem, isSelected(dateRangeTypes.TODAY) && Styles.selected)}
          >
            Today
          </div>
        )}
        {showThisMonth && (
          <div
            onClick={() => onFilterChanged(dateRangeTypes.THIS_MONTH)}
            className={classnames(Styles.lineItem, isSelected(dateRangeTypes.THIS_MONTH) && Styles.selected)}
          >
            This Month
          </div>
        )}
        {showLastMonth && (
          <div
            onClick={() => onFilterChanged(dateRangeTypes.LAST_MONTH)}
            className={classnames(Styles.lineItem, isSelected(dateRangeTypes.LAST_MONTH) && Styles.selected)}
          >
            Last Month
          </div>
        )}
        {showBefore && (
          <div
            onClick={() => {
              if (!isSelected(dateRangeTypes.BEFORE)) {
                onFilterChanged(dateRangeTypes.BEFORE, { before });
              }
            }}
            className={classnames(Styles.lineItem, isSelected(dateRangeTypes.BEFORE) && Styles.selected)}
          >
            <span className={Styles.customDateLabel}>Before</span>
            <DatePicker
              className={Styles.dateValue}
              value={integerToDate(before)}
              format={DATE_FORMAT}
              onSelect={(date) => {
                onFilterChanged(dateRangeTypes.BEFORE, { before: dateToInteger(date) || dateToInteger(today()) });
              }}
              size="XS"
              minimal
            />
          </div>
        )}
        <div
          onClick={() => {
            if (!isSelected(dateRangeTypes.CUSTOM)) {
              onFilterChanged(dateRangeTypes.CUSTOM, { from, to });
            }
          }}
          className={classnames(Styles.lineItem, isSelected(dateRangeTypes.CUSTOM) && Styles.selected)}
        >
          <span className={Styles.customDateLabel}>From</span>
          <DatePicker
            className={Styles.dateValue}
            format={DATE_FORMAT}
            value={integerToDate(from)}
            onSelect={(date) => {
              onFilterChanged(dateRangeTypes.CUSTOM, {
                from: dateToInteger(date) || dateToInteger(startOfThisMonth()),
                to,
              });
            }}
            size="XS"
            minimal
          />
          <span className={Styles.customDateLabel}>To</span>
          <DatePicker
            className={Styles.dateValue}
            format={DATE_FORMAT}
            value={integerToDate(to)}
            onSelect={(date) => {
              onFilterChanged(dateRangeTypes.CUSTOM, {
                from,
                to: dateToInteger(date) || dateToInteger(endOfThisMonth()),
              });
            }}
            size="XS"
            minimal
          />
        </div>
      </ul>
    );
  },
);

function getNewFilter(filterType, newValue) {
  const filters = { filterType };

  switch (filterType) {
    case dateRangeTypes.ALL:
      filters.startDate = 0;
      filters.endDate = 99991231;
      break;
    case dateRangeTypes.TODAY:
      filters.startDate = dateToInteger(startOfToday());
      filters.endDate = dateToInteger(endOfToday());
      break;
    case dateRangeTypes.BEFORE:
      filters.startDate = 0;
      filters.endDate = dateToInteger(moment(newValue.before, 'YYYYMMDD').add(-1, 'day').toDate());
      break;
    case dateRangeTypes.CUSTOM:
      filters.startDate = newValue.from;
      filters.endDate = newValue.to;
      break;
    case dateRangeTypes.THIS_MONTH:
      filters.startDate = dateToInteger(startOfThisMonth());
      filters.endDate = dateToInteger(endOfThisMonth());
      break;
    case dateRangeTypes.LAST_MONTH:
      filters.startDate = dateToInteger(startOfLastMonth());
      filters.endDate = dateToInteger(endOfLastMonth());
      break;
    default: {
      log.error(`Unexpected filter type encountered ${filterType}`);
    }
  }

  return filters;
}

DateListFilter.displayName = 'DateListFilter';
DateListFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedFilter: PropTypes.string.isRequired,
  showAll: PropTypes.bool,
  showBefore: PropTypes.bool,
  showLastMonth: PropTypes.bool,
  showToday: PropTypes.bool,
  showThisMonth: PropTypes.bool,
  before: PropTypes.number, // effective date YYYYMMDD
  from: PropTypes.number.isRequired, // effective date YYYYMMDD
  to: PropTypes.number.isRequired, // effective date YYYYMMDD
};
DateListFilter.defaultProps = {
  before: undefined,
  className: undefined,
  showAll: true,
  showBefore: true,
  showLastMonth: true,
  showToday: false,
  showThisMonth: true,
};

export default DateListFilter;
