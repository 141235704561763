const moment = require('moment');

const {
  addDays,
  subtractDays,
  addWeeks,
  subtractWeeks,
  addMonths,
  subtractMonths,
  addYears,
  subtractYears,
} = require('./add-subtract-date');

const {
  dateRangeTypes,
  dateRangeTypeLabels,
  dateRangeTypeOptions,
} = require('./date-range-types');

/**
 * 
 * @returns the current date and time in UTC in ISO string form
 */
const now = () =>
  // Date has been used to enable testing via mockdate. Do not change unless you have an alternative
  moment(new Date()).utc().toISOString();


/**
 * @param {string} isoDateTime iso date time
 * @param {string} ianaTimezone iana timezone
 * @param {string} format output format, default YYYYMMDD
 * @returns {string} YYYYMMDD
 */
const formatIsoDateTime = (isoDateTime, ianaTimezone, format = 'YYYYMMDD') => {
  if (!isoDateTime) {
    throw new Error(`Missing isoDateTime`);
  }
  
  if (!ianaTimezone) {
    throw new Error('Missing ianaTimezone');
  }

  // hack to convert an ISO timestamp into a US formatted date string so that it can be converted to YYYYMMDD format
  const localDate = new Date(isoDateTime).toLocaleString('en-us', { timeZone: ianaTimezone });

  return  moment(localDate).format(format);
};

/**
 * @param {number} yyyymmdd
 * @returns {object} date
 */
const integerToDate = (yyyymmdd) => {
  if (!yyyymmdd) {
    return new Date('Invalid date');
  }
  return moment(yyyymmdd, 'YYYYMMDD').toDate();
};

/**
 * @param {object} inDate
 * @returns {number} yyyymmdd
 */
const dateToInteger = (inDate) =>
  Object.prototype.toString.call(inDate) === '[object Date]'
    ? parseInt(moment(inDate).format('YYYYMMDD')) 
    : NaN;

const today = () => moment()
  .startOf('day')
  .toDate();

const todayAsInteger = () =>
  dateToInteger(today());

const yesterday = () => moment()
  .add(-1, 'days')
  .startOf('day')
  .toDate();

const yesterdayAsInteger = () =>
  dateToInteger(yesterday());

const tomorrow = () => moment()
  .add(1, 'days')
  .startOf('day')
  .toDate();

const tomorrowAsInteger = () =>
  dateToInteger(tomorrow());

const startOfToday = () => moment()
  .startOf('day')
  .toDate();

const endOfToday = () => moment()
  .endOf('day')
  .toDate();

const startOfThisWeek = () => moment()
  .startOf('week')
  .toDate();

const endOfThisWeek = () => moment()
  .endOf('week')
  .toDate();

  const startOfLastWeek = () => moment()
  .subtract(1, 'weeks')
  .startOf('week')
  .toDate();

const endOfLastWeek = () => moment()
  .subtract(1, 'weeks')
  .endOf('week')
  .toDate();

const startOfThisMonth = () => moment()
  .startOf('month')
  .toDate();

const endOfThisMonth = () => moment()
  .endOf('month')
  .toDate();

const startOfLastMonth = () => moment()
  .subtract(1, 'months')
  .startOf('month')
  .toDate();

const endOfLastMonth = () => moment()
  .subtract(1, 'months')
  .endOf('month')
  .toDate();

const daysAgo = (numberOfDaysAgo) => moment()
  .subtract(numberOfDaysAgo, 'days')
  .startOf('day')
  .toDate();

const isValidDate = (dateToCheck) => moment(dateToCheck).isValid();
const isValidIntegerDate = (dateString) => moment(dateString, 'YYYYMMDD').isValid();

const startOfDay = (date) => moment(date).startOf('day').toDate();
const endOfDay = (date) => moment(date).endOf('day').toDate();

const periods = Object.freeze({
  ALL_TIME: 'ALL_TIME',
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  CUSTOM: 'CUSTOM',
});

const dateCalculator = {
  [periods.ALL_TIME]: () => ({ startDate: undefined, endDate: undefined }),

  [periods.THIS_WEEK]: () => ({
    startDate: startOfThisWeek(),
    endDate: endOfThisWeek(),
  }),

  [periods.LAST_WEEK]: () => ({
    startDate: startOfLastWeek(),
    endDate: endOfLastWeek(),
  }),

  [periods.THIS_MONTH]: () => ({
    startDate: startOfThisMonth(),
    endDate: endOfThisMonth(),
  }),

  [periods.LAST_MONTH]: () => ({
    startDate: startOfLastMonth(),
    endDate: endOfLastMonth(),
  }),

  [periods.CUSTOM]: () => ({
    startDate: startOfThisMonth(),
    endDate: endOfThisMonth(),
  }),
};

const calculateDatesForPeriod = (period) => dateCalculator[period]();

module.exports = {
  // add/subtract
  addDays,
  subtractDays,
  addWeeks,
  subtractWeeks,
  addMonths,
  subtractMonths,
  addYears,
  subtractYears,
  // formatting
  formatIsoDateTime,
  integerToDate,
  dateToInteger,
  // short cut dates
  daysAgo,
  today,
  todayAsInteger,
  tomorrow,
  tomorrowAsInteger,
  yesterday,
  yesterdayAsInteger,
  startOfToday,
  endOfToday,
  startOfThisMonth,
  endOfThisMonth,
  startOfLastMonth,
  endOfLastMonth,
  startOfDay,
  endOfDay,
  // validators
  isValidDate,
  isValidIntegerDate,
  // others
  dateRangeTypes,
  dateRangeTypeLabels,
  dateRangeTypeOptions,
  periods,
  calculateDatesForPeriod,
  now,
};
