import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PaginationSelector } from '@sb-itops/react';
import { MatterTable } from 'web/components';
import Styles from './BillingContactMattersRoute.module.scss';

export const BillingContactMattersRoute = memo((props) => {
  const { matters, loading, allowTrustOverdraw } = props;
  const { debtorId, onClickLink } = props;
  const { sortBy, sortDirection, onSort } = props;
  const { hidePagination, selectedPage, totalNumberOfPages, onPageChange } = props;
  const { hasBillingAccess } = props;

  return (
    <div className="master-detail-panel">
      <div className={classnames('panel-body', Styles.billingContactMattersRoute)}>
        <MatterTable
          contactId={debtorId}
          matters={matters}
          mattersLoading={loading}
          contactsAsLink={false}
          onClickLink={onClickLink}
          sort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          showStatus
          allowTrustOverdraw={allowTrustOverdraw}
          showFinancials={hasBillingAccess}
        />
        <PaginationSelector
          name="contactMatters"
          className={Styles.paginationSection}
          hidePagination={hidePagination}
          numberOfPagesDisplayed={10}
          selectedPage={selectedPage}
          totalNumberOfPages={totalNumberOfPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
});

BillingContactMattersRoute.displayName = 'BillingContactMattersRoute';
BillingContactMattersRoute.propTypes = {
  allowTrustOverdraw: PropTypes.bool.isRequired,
  debtorId: PropTypes.string,
  matters: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  error: PropTypes.object,
  hasBillingAccess: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func,
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  hidePagination: PropTypes.bool.isRequired,
  selectedPage: PropTypes.number.isRequired,
  totalNumberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

BillingContactMattersRoute.defaultProps = {
  debtorId: undefined,
  matters: [],
  loading: false,
  error: {},
  onClickLink: () => {},
};
