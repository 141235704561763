import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { toggleShowSystemDateApi as toggleShowSystemDateAccountsApi } from 'web/redux/route/home-billing-accounts-transactions';
import { toggleShowSystemDateApi as toggleShowSystemDateMatterApi } from 'web/redux/route/home-billing-matter-transactions';
import { toggleShowSystemDateApi as toggleShowSystemDateContactApi } from 'web/redux/route/home-billing-contact-transactions';
import LedgerTransactionTable from './LedgerTransactionTable';

const mapStateToProps = (state, props) => {
  let showEnteredDateColumn;
  if (props.showContactColumn && props.showMatterColumn) {
    showEnteredDateColumn = toggleShowSystemDateAccountsApi.getState().showSystemDate;
  } else if (props.showContactColumn) {
    // its a matter list
    showEnteredDateColumn = toggleShowSystemDateMatterApi.getState().showSystemDate;
  } else {
    // its a contact list
    showEnteredDateColumn = toggleShowSystemDateContactApi.getState().showSystemDate;
  }

  return {
    ...props,
    showEnteredDateColumn,
  };
};

const Wrapper = withReduxStore(connect(mapStateToProps)(LedgerTransactionTable));

Wrapper.propTypes = {
  transactions: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  showMatterColumn: PropTypes.bool,
  showContactColumn: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
};

Wrapper.defaultProps = {
  sortBy: 'effectiveDateDisplay',
  sortDirection: 'desc',
  transactions: [],
  summary: {},
};

export default Wrapper;
