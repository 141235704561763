import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '@sb-itops/redux/store';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { getDefaultCreditAccount } from '@sb-billing/redux/bank-account';
import { getMatterTypeaheadSummaries, getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { getMatterBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getMap as getMatterBalanceState } from '@sb-billing/redux/bank-account-balances';
import { AddContactInlineForm } from '../add-contact-inline-form.2';
import { creditNoteFormSchema } from './credit-note-form-schema';
import CreditNoteForm from './CreditNoteForm';

const getCreditBalance = ({ matterId, bankAccountId }) => {
  if (matterId && bankAccountId) {
    return getMatterBalance(getMatterBalanceState(), {
      matterId,
      bankAccountId,
    });
  }

  return 0;
};

const mapStateToProps = (state, { scope }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formSubmitting, submitFailed } = formSelectors.getFormState(state);
  const { amount, balance, selectedDate, selectedMatterId, selectedContactId } = formFields;

  return {
    formDisabled: formSubmitting || !getDefaultCreditAccount(),
    formInitialised,
    amount,
    balance,
    selectedDate,
    selectedMatterId,
    selectedContactId,
    matterOptions: getMatterTypeaheadSummaries(),
    contactOptions: getContactTypeAheadSummaries(),
    submitFailed,
  };
};

const mapDispatchToProps = (dispatch, { scope, matterId, contactId }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const creditBalance = getCreditBalance({
        matterId,
        bankAccountId: getDefaultCreditAccount().id,
      });

      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            amount: 0,
            balance: creditBalance,
            selectedDate: new Date().toISOString(),
            selectedMatterId: matterId,
            selectedContactId: contactId,
          },
        }),
      );
      dispatch(formOperations.validateSchema({ schema: creditNoteFormSchema }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (fieldValues) => {
      if (Object.prototype.hasOwnProperty.call(fieldValues, 'selectedMatterId')) {
        const balance = fieldValues.selectedMatterId
          ? getCreditBalance({
              matterId: fieldValues.selectedMatterId,
              bankAccountId: getDefaultCreditAccount().id,
            })
          : 0;
        dispatch(
          formActions.updateFieldValues({
            fieldValues: { balance },
          }),
        );
      }
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateSchema({ schema: creditNoteFormSchema }));
    },
    onAddContact: () => dispatch(),
  };
};

export const CreditNoteFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ onFieldValueUpdated, formDisabled, ...props }) => {
      const [showInlineContactForm, setShowInlineContactForm] = useState(false);

      const toggleAddContact = () => {
        setShowInlineContactForm(!showInlineContactForm);
      };

      return (
        <CreditNoteForm
          {...props}
          onFieldValueUpdated={onFieldValueUpdated}
          onAddContact={toggleAddContact}
          inlineContactForm={
            <AddContactInlineForm
              onClose={toggleAddContact}
              onContactCreated={(selectedContactId) => {
                onFieldValueUpdated({ selectedContactId });
                setShowInlineContactForm(false);
              }}
            />
          }
          showInlineContactForm={showInlineContactForm}
          formDisabled={formDisabled}
        />
      );
    }),
  ),
);

export const formDisabled = ({ scope }) => {
  const { selectors } = withScopedFeature({ scope })(forms);
  const { formSubmitting } = selectors.getFormState(store.getState());

  return formSubmitting;
};

CreditNoteFormContainer.displayName = 'CreditNoteFormContainer';

CreditNoteFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  contactId: PropTypes.string,
};

CreditNoteFormContainer.defaultProps = {
  matterId: undefined,
  contactId: undefined,
};

export default CreditNoteFormContainer;
