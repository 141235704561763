import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { Button } from '@sb-itops/react';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { selectors } from 'web/redux/route/billing-support-debug';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

export const DebugInvoicePdfButtonContainer = withReduxProvider(({ invoiceId }) => {
  const showDebug = useSelector(selectors.getShowDebug);
  const [isFetchingDebugData, setIsFetchingDebugData] = useState(false);
  const onClick = async () => {
    try {
      setIsFetchingDebugData(true);
      await fetchPostP({
        path: `/billing/invoice-pdf/debug/:accountId/`,
        fetchOptions: {
          body: JSON.stringify({
            invoiceId,
          }),
        },
      });
      displaySuccessToUser(
        'Debug data for invoice PDF fetched successfully, look for invoice-pdf/debug in Chrome DevTools Network tab.',
      );
    } catch (error) {
      displayErrorToUser('Failed to fetch debug data for invoice PDF.');
    } finally {
      setIsFetchingDebugData(false);
    }
  };

  if (!showDebug) {
    return null;
  }

  return (
    <Button onClick={onClick} disabled={isFetchingDebugData} locked={isFetchingDebugData}>
      Debug: PDF
    </Button>
  );
});

DebugInvoicePdfButtonContainer.displayName = 'DebugInvoicePdfButton';

DebugInvoicePdfButtonContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

DebugInvoicePdfButtonContainer.defaultProps = {};
