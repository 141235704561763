import { gql } from '@apollo/client';
import { expenseEntityFragment } from '../fragments/expenseEntityFragment.graphql';

const query = gql`
  query UnbilledMatterExpenses($matterId: ID!, $includeNonBillableItems: Boolean!, $expenseDate: DateRange) {
    unbilledExpenses(
      matterIds: [$matterId]
      filter: { includeNonBillableItems: $includeNonBillableItems, expenseDate: $expenseDate }
    ) {
      ...expenseEntityFragment
    }
  }

  ${expenseEntityFragment}
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpenseDeleted',
  'InvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const UnbilledMatterExpenses = {
  query,
  notificationIds,
};
