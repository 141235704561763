'use strict';

const { AuthenticationChallengeFactory } = require('./authentication-challenge-factory');
const { UserIdentityFactory } = require('./user-identity-factory');
const { authenticationResultTypes } = require('../entities/constants');

/**
 * @typedef { import('../entities/types.js').SbBillingAuthProviderResult } SbBillingAuthProviderResult
 * @typedef { import('../entities/types.js').AuthenticationResult } AuthenticationResult
 * @typedef { import('../entities/types.js').SbBillingAuthProviderChallenge } SbBillingAuthProviderChallenge
 * @typedef { import('../entities/types.js').SbBillingAuthProviderIdentity } SbBillingAuthProviderIdentity
 */

class AuthenticationResultFactory {
  /**
   * fromSbBillingAuthenticationProviderResult
   * Creates a {@link AuthenticationResult} object from a {@link SbBillingAuthProviderResult}.
   * @param {SbBillingAuthProviderResult} authenticationProviderResult
   * @return {AuthenticationResult}
   */
  static fromSbBillingAuthenticationProviderResult(authenticationProviderResult) {
    // If the challenge property is present in the authenticationProviderResult, we assume the authenticationProviderResult represents a new challenge
    // and convert the authenticationProviderResult into the AuthenticationChallenge data structure.

    /** @type SbBillingAuthProviderChallenge */
    const potentialChallenge = authenticationProviderResult.message;

    if (potentialChallenge.challenge) {
      return {
        type: authenticationResultTypes.CHALLENGE,
        challenge: AuthenticationChallengeFactory.fromSbBillingAuthenticationProviderChallenge(potentialChallenge),
      };
    }

    // Otherwise we can assume that the authenticationProviderResult contains an actual user identity and we convert accordingly.

    /** @type SbBillingAuthProviderIdentity */
    const sbBillingAuthProviderIdentity = authenticationProviderResult.message;
    return {
      type: authenticationResultTypes.USER_IDENTITY,
      userIdentity: UserIdentityFactory.fromSbBillingAuthenticationProviderIdentity(sbBillingAuthProviderIdentity),
    };
  }
}

module.exports = {
  AuthenticationResultFactory,
  authenticationResultTypes,
};
