import { updateCache as updateRedux } from '@sb-billing/redux/bulk-finalize-settings';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('@sb-billing/services')
  .service('sbBulkFinalizeSettingsService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {

    const supportsBulkFinalise = hasFacet(facets.bulkFinalise);
    if (!supportsBulkFinalise) {
      return;
    }

    const bulkFinalizeSettingsEndpoint = 'billing/bulk-finalize-settings';

    sbGenericCacheService.setupCache({
      name:'sbBulkFinalizeSettingsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: bulkFinalizeSettingsEndpoint },
        poll: 60,
        subscriptions: 'notifier.InvoicingNotifications.BulkFinalizeSettingsUpdated',
      },
      updateRedux,
    });
  });
