import cacheReducer from './cache-reducer';
import { OPDATE_CACHE, ROLLBACK_OPDATE_CACHE, optimisticUpdateFactory } from '../optimistic-update';

export const syncTypes = Object.freeze({
  NONE: 'NONE',
  SINCE: 'SINCE',
  ALL: 'ALL',
});

export const createReducer = ({ UPDATE_CACHE, CLEAR_CACHE, ngCacheName, keyPath, syncType }) => {
  const { opdateReducer, rollbackOpdateReducer } = optimisticUpdateFactory({ ngCacheName, keyPath });
  const isSyncAll = syncType === syncTypes.ALL;

  return (state = {}, action = {}) => {
    switch (action.type) {
      case UPDATE_CACHE:
        return {
          ...state,
          ...cacheReducer(state, action.payload, keyPath, action.meta.lastUpdated, isSyncAll),
        };

      case OPDATE_CACHE:
        return opdateReducer(state, action);

      case ROLLBACK_OPDATE_CACHE:
        return rollbackOpdateReducer(state, action);

      case CLEAR_CACHE: // This is for unit test support.
        return {};

      default:
        return state;
    }
  };
};
