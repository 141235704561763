'use strict';

const { hasPayments } = require('./has-payments');

function isVoidable(invoice, invoiceTotals) {
  return (
    !!invoice &&
    !!invoiceTotals &&
    invoice.currentVersion.status !== 'VOID' &&
    !hasPayments(invoiceTotals) &&
    !invoice.currentVersion.waived &&
    !invoice.invoiceStatementId // not part of an invoice statement
  );
}

module.exports = {
  isVoidable,
};
