import * as messageDisplay from '@sb-itops/message-display';

angular.module('sb.billing.webapp').directive('sbActionGeneratePaymentDetailsInvoice', function ($state) {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      let invoice;

      ctrl.group.onInvoiceData(data => invoice = data);
      ctrl.group.setAction(generatePaymentDetails);

      function generatePaymentDetails() {
        // Invoice ID must be present.
        const invoiceId = _.get(invoice, 'invoiceId');

        if (!invoiceId) {
          messageDisplay.warn('Failed to generate payment detail due to missing invoice ID.');
          throw new Error('Could not find invoice ID for generating payment details');
        }

        $state.go('home.billing.invoice-proof-of-payment', { invoiceId });
      }
    }
  };
});
