'use strict';

const { determinePaymentMethodExpiry } = require('./determine-payment-method-expiry');

// unforunately we can't reuse calculatePaymentPlanPosition here because
// we simply don't want to be loading all the payment plan installments for
// every single invoice
const calculatePaymentPlanPositionPaymentMethodStatus = ({
  isAutoCharge,
  paymentMethodExpiry,
  lastPaymentSuccessful,
}) => {
  let paymentMethodStatus = '';

  if (isAutoCharge) {
    const paymentMethodExpiryStatus = determinePaymentMethodExpiry({ expiry: paymentMethodExpiry });
    if (paymentMethodExpiryStatus) {
      paymentMethodStatus = paymentMethodExpiryStatus;
    }

    // lastPaymentSuccessful could be null or undefined
    if (lastPaymentSuccessful === 0) {
      paymentMethodStatus = 'failed';
    }
  }

  return paymentMethodStatus;
};

module.exports = {
  calculatePaymentPlanPositionPaymentMethodStatus,
};
