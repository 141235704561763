import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';

// Some other receipt-view controllers have onNotificationsP function to listen for notifications
// to know that transaction was recorded. This is used in sb-pdf-viewer to trigger fetch PDF immidiately instead of polling.
//
// I removed onNotificationsP here because:
// - As it was, onNotificationsP didn't work here anyway.
// - The "ctrl.sbParams.paymentId" is multiPaymentId for which there is no notification sent. Notifications are sent for transaction/payments
//   and we don't have these Ids here.
// - The "sb-pdf-viewer" load/retry logic relies on receipt endpoint throwing error when transaction doesn't exist so we can use this to
//   actually generate receipt only once the transaction exists in DB. This logic works for single transactions (eg. deposit) but not for TTO as TTO is 
//   only pseudo-transaction. In TTO endpoint we try to construct this pseudo-transaction by getting payments with given multiPaymentId.
//   The issue is we don't know how many payments we should get so getting any triggers PDF generation which may not include all payments.
//
// To make sure we don't trigger receipt generation before all payments are processed/saved (which could cause missing payments in receipt) I delay
// redirection to the receipt (to this component) in TrustToOfficeModal until I get notification for all payments in the TTO.
// See comment in TrustToOfficeModal.container for more info.

angular.module('sb.billing.webapp').component('sbTrustToOfficeTransferReceiptView', {
  templateUrl: 'ng-components/trust-to-office-transfer-receipt-view/trust-to-office-transfer-receipt-view.html',
  controller: function ($rootScope, $stateParams, sbNotifiedOperationP, sbUnsavedChangesService) {
    'use strict';
    const ctrl = this;
    const log = getLogger('sbTrustToOfficeTransferReceiptView');

    ctrl.$onInit = () => {
      ctrl.sbParams = _.cloneDeep($stateParams); // need to do this while we have the stone age ui-router
      ctrl.sbData = {};
      ctrl.view = {
        receiptLoaded: false,
      };

      ctrl.savedChangesKey = `trust-to-office-transfer-receipt_${ctrl.sbParams.paymentId}`;

      ctrl.getPayloadConfig = {
        endpoint: 'billing/trust-to-office-transfer-receipt-pdf',
        additional: `${ctrl.sbParams.paymentId}/presigned-url`,
      };

      ctrl.tabCloseListenerDeregister = $rootScope.$on('smokeball-tab-closed', onTabClosed);
      ctrl.pdfUrl = sbUnsavedChangesService.loadMemory(ctrl.savedChangesKey)
    };

    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.onPdfLoaded = onReceiptLoaded;
    ctrl.onError = onError;

    function onTabClosed(e, { type, transactionId }) {
      // Check if the tab containing this component is being closed.
      if (type === 'trust-to-office-transfer-receipt' && transactionId === ctrl.sbParams.paymentId) {
        ctrl.tabCloseListenerDeregister();
        URL.revokeObjectURL(ctrl.pdfUrl);
        ctrl.pdfUrl = undefined;
        sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey);
      }
    }

    function onReceiptLoaded (pdfUrl) {
      ctrl.pdfUrl = pdfUrl;
      sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey, pdfUrl);
      ctrl.view.receiptLoaded = true;
    }

    function dataChangeFunction (key, doc) {
      log.info('data change', key, doc);

      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }
    }

    function onError(err) {
      log.error(`Failed to download tto transfer receipt pdf for paymentId: ${ctrl.sbParams.paymentId}`, err);
      messageDisplay.error(`Failed to download tto transfer receipt pdf`);
    }
  }
});
