import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query FirmDetails {
    firm {
      id
      firmName
      creationDate
      businessAddress {
        buildingLevel
        unitNumber
        unitType
        streetNumber
        streetName
        streetType
        addressLine1
        addressLine2
        city
        state
        zipCode
        postcode
        suburbTown
        county
        locality
        country
      }
      acn
      abn
      phoneNumber
      phoneAreaCode
      faxNumber
      faxAreaCode
      mailingAddress {
        addressLine1
        addressLine2
        city
        state
        county
        locality
        country
        zipCode
        careOf
        boxType
        boxNumber
      }
    }
  }
`;

const notificationIds = ['FirmManagementNotifications.FirmUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const FirmDetails = {
  query,
  notificationIds,
};
