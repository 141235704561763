import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { localiseBankAccountTypeDisplay } from '@sb-billing/business-logic/bank-account/services';

angular.module('sb.billing.webapp').service('sbGuidToLinkService', function ($state, sbLoggerService, sbInvoicingService, sbBankAccountService, sbMattersMbService, sbContactsMbService, sbLocalisationService) {
  const that = this;
  const log = sbLoggerService.getLogger('sbGuidToLink');
  const guidRe = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  const accountIdGuidRe = /accountId:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(\/Trust|\/Operating)?/gi;
  const contactIdGuidRe = /contactId:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  const matterIdGuidRe = /matterId:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  const invoiceIdGuidRe = /invoiceId:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

  that.getUpdateData = getUpdateData;

  function getUpdateData(text) {
    /*
    We expect that once the full description has been split into multiple <spans> elements
    each <spans> elements text be passed here in turn. So we expect that a single match MAY be found
    per match type. Also we assume that no phrase will match multiple types, if so the first match wins.
    */
    let updateData = replaceMatches(invoiceIdGuidRe, buildInvoiceIdLink, text);
    if (updateData.match) {
      return updateData;
    }

    updateData = replaceMatches(accountIdGuidRe, resolveAccountName, text);
    if (updateData.match) {
      return updateData;
    }

    updateData = replaceMatches(matterIdGuidRe, buildMatterIdLink, text);
    if (updateData.match) {
      return updateData;
    }

    updateData = replaceMatches(contactIdGuidRe, buildContactIdLink, text);
    return updateData;
  }

  function replaceMatches(re, replacementFunct, input) {
    let updateData = {match: false};
    const matches = input.match(re);
    if (!_.isEmpty(matches)) {
      if (_.size(matches) > 1) {
        log.error('expected a single match per phrase / span : ' + input);
      }
      log.info('match found for : ' + input + ' : ' + matches[0]);
      //extract only the GUID part of the match to pass as first arg, pass the full match as second arg
      updateData = replacementFunct(matches[0].match(guidRe)[0], matches[0]);
    }
    return updateData;
  }

  function buildInvoiceIdLink(matchedInvoiceId) {
    const updateData = {match: false};
    const invoice = sbInvoicingService.getInvoice(matchedInvoiceId);
    if (invoice && invoice.invoiceNumber && invoice.matterId) {
      updateData.match = true;
      updateData.type = 'link';
      updateData.hrefUrl = $state.href('home.billing.goto-invoice', {invoiceId: matchedInvoiceId});
      updateData.text = '#' + invoice.invoiceNumber;
      updateData.class = 'invoice-link';
    } else {
      log.error('failed to link invoice ID GUID : ' + matchedInvoiceId);
    }
    return updateData;
  }

  function resolveAccountName(notApplicable, matchedAccountId) {
    // matchedAccountId = 'accountId:aaaaaaaa-2578-4a04-a4a0-9b9172217922/Trust' or 'accountId:aaaaaaaa-2578-4a04-a4a0-9b9172217922'
    // returns the localised account type, e.g. Trust / Client Account
    const bankAccountId = matchedAccountId.split(':')[1];
    const bankAccount = sbBankAccountService.get(bankAccountId);
    const updateData = {match: true};
    updateData.type = 'span';
    updateData.text = localiseBankAccountTypeDisplay(bankAccount, sbLocalisationService.t);
    updateData.class = 'account-type';
    return updateData;
  }

  function buildMatterIdLink(matchedMatterId) {
    const updateData = {match: false};
    const matter = sbMattersMbService.getById(matchedMatterId);
    const display = getMatterDisplayById(matter.matterId);
    if (matter && display) {
      updateData.match = true;
      updateData.type = 'link';
      updateData.hrefUrl = $state.href('home.billing.view-matter.fees', {matterId: matchedMatterId});
      updateData.text = display;
      updateData.class = 'matter-link';
    } else {
      log.error('failed to link matter ID GUID : ' + matchedMatterId);
    }
    return updateData;
  }

  function buildContactIdLink(matchedContactId) {
    const updateData = {match: false};
    const contact = sbContactsMbService.getById(matchedContactId);
    if (contact && contact.displayName) {
      updateData.match = true;
      updateData.type = 'link';
      updateData.hrefUrl = $state.href('home.billing.view-contact.details', {contactId: matchedContactId});
      updateData.text = getContactDisplay(contact.entityId);
      updateData.class = 'contact-link';
    } else {
      log.error('failed to link contact ID GUID : ' + matchedContactId);
    }
    return updateData;
  }


});
