import { gql } from '@apollo/client';

const query = gql`
  query RecurringEvents($eventFilter: EventFilter, $offset: Int, $limit: Int) {
    recurringEventList(filter: $eventFilter, offset: $offset, limit: $limit) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        id
        attendeeEntityIds
        attendeeEntities {
          id
          displayName
        }
        accountId
        matterId
        startTime
        endTime
        originalStartTime
        originalEndTime
        subject
        label
        description
        location
        allDay
        eventType
        rRULE
        patternAppointmentId
        recurrenceInfoId
        recurrenceInfo
        reminderInfo
        resourceIds
        resourceId
        lastUpdated
        isRemoved
      }
    }
  }
`;

const notificationIds = [
  'CalendarSyncSettingsNotifications.AppointmentUpdatedEvent',
  'CalendarSyncSettingsNotifications.ResourceUpdated',
];

const onOpdateQuery = ({ queryData, queryVariables, entityOpdateEvent }) => {
  const { eventType, typeName, optimisticEntity } = entityOpdateEvent;

  // If editing we need to filter the item from view if any key variables changed, but if
  // adding we can simply leave the view unchanged
  const onBlankReturn =
    `${eventType}-${typeName}` !== 'ENTITY_ADDED-Appointment'
      ? {
          ...queryData,
          recurringEventList: {
            ...queryData?.recurringEventList,
            results: (queryData?.recurringEventList?.results || []).filter((item) => item?.id !== optimisticEntity.id),
          },
        }
      : undefined;

  // Ignore optimistic entities which do not fit within the current filter set.
  if (queryVariables.eventFilter.matterId && queryVariables.eventFilter.matterId !== !optimisticEntity.matterId) {
    return onBlankReturn;
  }

  if (optimisticEntity.isRemoved) {
    return onBlankReturn;
  }

  // Only update recurring appointments
  if (optimisticEntity.eventType === 0) {
    return onBlankReturn;
  }

  // Replaces/inserts the optimistic entity in the right position
  let addedOpdate = false;
  const updatedResults = (queryData?.recurringEventList?.results || []).reduce((acc, val) => {
    if (val?.startTime > optimisticEntity.startTime && !addedOpdate) {
      acc.push(optimisticEntity);
      addedOpdate = true;
    }
    if (val?.id !== optimisticEntity.id) {
      acc.push(val);
    }
    return acc;
  }, []);
  if (!addedOpdate) {
    updatedResults.push(optimisticEntity);
  }

  // Return the new opdated data for the query.
  return {
    ...queryData,
    recurringEventList: {
      ...queryData?.recurringEventList,
      results: updatedResults,
    },
  };
};

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const RecurringEventData = {
  query,
  notificationIds,
  onOpdateQuery,
};
