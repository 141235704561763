import React, { useEffect, useState } from 'react';
import { SbClientSdk } from 'web/react-redux/components/sb-client-sdk';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getIntakeUrl } from 'web/services/intake-urls';

// hey I hear you like containers so I put your container in a container
const Container = (props) => {
  const [url, setUrl] = useState<string>();

  const { onClickLink, matterId, mode } = props;

  useEffect(() => {
    getIntakeUrl(mode).then((v) => {
      setUrl(v);
    });
  }, []);

  return url ? <SbClientSdk sdkAppUrl={url} onClickLink={onClickLink} matterId={matterId} /> : null;
};

export const IntakeIframeContainer = withReduxProvider(Container);
