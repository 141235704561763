'use strict';

/**
 * @typedef {object} EXPENSE_PSEUDO_STATUS
 * @property {string} UNPAID 'Unpaid'
 * @property {string} OVERDUE 'Overdue'
 * @property {string} PAID 'Paid'
 */
const EXPENSE_PSEUDO_STATUS = Object.freeze({
  UNPAID: 'Unpaid',
  OVERDUE: 'Overdue',
  PAID: 'Paid',
});

module.exports = { EXPENSE_PSEUDO_STATUS };
