'use strict';
import { getMap as getBankAccountBalances } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import uuid from '@sb-itops/uuid';
import { PrintNotApplicable, PrintManually } from '@sb-billing/business-logic/cheques';
import { dateToInteger } from '@sb-itops/date';
import { getLogger } from '@sb-itops/fe-logger';
import { sort } from '@sb-itops/sort';
import * as messageDisplay from '@sb-itops/message-display';

import { allocateQuickPaymentsForInvoices } from './allocator';

const { getMatterContactBalances } = selectors;
const log = getLogger('finalise-with-payments-route');

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  $stateProvider
    .state('home.billing.bills.finalise-with-payments', {
      // we need to americanise the URL as this it is client facing
      url: '/finalize-with-payments',
      template: '<sbb-billing-bills-finalise-with-payments on-finalise="$ctrl.onFinalise" on-click-link="$ctrl.onClickLink"/>',
      controller: function (sbLinkService, sbSaveInvoiceCommand) {
        const ctrl = this;
        ctrl.onClickLink = sbLinkService.onClickLink;
        ctrl.onFinalise = onFinalise;

        async function onFinalise({ selectedInvoiceIds, matterPaymentGroups, chequePrintingMethod, bankAccountIds }) {
          // console.log(getQuickPayments(matterPaymentGroups, chequePrintingMethod, bankAccountIds)); return;
          try {
            const isBulkOperation = true;
            const result = await sbSaveInvoiceCommand.executeBulkP(selectedInvoiceIds, getQuickPayments(matterPaymentGroups, chequePrintingMethod, bankAccountIds), isBulkOperation);
            // `executeBulkP` returns an array of the results of finalising the invoices (with errors NOT being thrown)
            // Show the user a nice message for the success/failures, and log the failures
            const { successes, errors } = result.reduce((acc, {success, result, error}) => {
              if (success) {
                acc.successes.push(result);
              } else {
                acc.errors.push(error);
              }
              return acc;
            }, { successes: [], errors: []});

            if (successes.length) {
              messageDisplay.success(`${successes.length} Invoice(s) successfully finalized`);
            }

            if (errors.length) {
              messageDisplay.error(`${errors.length} Draft invoice(s) could not be finalized`);
              log.error('Finalise with payments errors', errors)
            }
          } catch(err) {
            // if we get here, something has drastically gone wrong as `executeBulkP` swallows errors.
            // Show the user a nice message and log the exception
            log.error('Finalise with payments error', err);
            messageDisplay.error('The invoices have failed to finalize');
          }
        }
      },
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills', 'finalise-with-payments']
      }
    });
});

// convert the payments object from the page into quickpayments
function getQuickPayments (matterIdToInvoicePayments, trustChequePrintingMethod, bankAccountIds) {

  const chequePrintActive = !!trustChequePrintingMethod && trustChequePrintingMethod !== PrintNotApplicable;
  const effectiveDate = dateToInteger(new Date());
  return Object.entries(matterIdToInvoicePayments).reduce((quickPayments, [matterId, invoicePayments]) => {
    let matterPayments = [];

    const creditPayments = allocateQuickPaymentsForInvoices({
      invoicePayments,
      contactBalances: sort(getMatterContactBalances(getBankAccountBalances(), { matterId, bankAccountId: bankAccountIds.CREDIT }), ['balance'], ['ASC']),
      bankAccountId: bankAccountIds.CREDIT,
      transformFn: (allocation) => {
        return {
          paymentId: uuid(),
          sourceAccountId: allocation.sourceAccountId,
          amount: allocation.amount,
          effectiveDate,
          payorId: allocation.payorId,
          matterId, // required for opdate
          sourceAccountType: 'Credit', // required for opdate
        };
      },
    });        
    matterPayments = creditPayments;

    const trustPayments = allocateQuickPaymentsForInvoices({
      invoicePayments,
      contactBalances: sort(getMatterContactBalances(getBankAccountBalances(), { matterId, bankAccountId: bankAccountIds.TRUST }), ['balance'], ['ASC']),
      bankAccountId: bankAccountIds.TRUST,
      transformFn: (allocation) => {
        return {
          paymentId: uuid(),
          sourceAccountId: allocation.sourceAccountId,
          amount: allocation.amount,
          effectiveDate,
          payorId: allocation.payorId,
          chequePrintActive,
          chequePrintMethod: chequePrintActive ? trustChequePrintingMethod : PrintManually,
          matterId, // required for opdate
          sourceAccountType: 'Trust', // required for opdate
          source: 'Trust',
        };
      },
    });

    matterPayments = Object.keys(trustPayments).reduce((acc, invoiceId) => {
      if(acc[invoiceId]) {
        acc[invoiceId] = acc[invoiceId].concat(trustPayments[invoiceId]);
      } else {
        acc[invoiceId] = trustPayments[invoiceId];
      }

      return acc;
    }, matterPayments)

    const operatingPayments = allocateQuickPaymentsForInvoices({
      invoicePayments,
      contactBalances: sort(getMatterContactBalances(getBankAccountBalances(), { matterId, bankAccountId: bankAccountIds.OPERATING }), ['balance'], ['ASC']),
      bankAccountId: bankAccountIds.OPERATING,
      transformFn: (allocation) => {
        return {
          paymentId: uuid(),
          sourceAccountId: allocation.sourceAccountId,
          amount: allocation.amount,
          effectiveDate,
          payorId: allocation.payorId,
          matterId, // required for opdate
          sourceAccountType: 'Operating', // required for opdate
        };
      },
    });

    matterPayments = Object.keys(operatingPayments).reduce((acc, invoiceId) => {
      if(acc[invoiceId]) {
        acc[invoiceId] = acc[invoiceId].concat(operatingPayments[invoiceId]);
      } else {
        acc[invoiceId] = operatingPayments[invoiceId];
      }

      return acc;
    }, matterPayments)

    return {
      ...quickPayments,
      ...matterPayments,
    };
  }, {});
}
