angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/preferences/account-security';

  $stateProvider
    .state('preferences.account-security', {
      templateUrl: BASE_DIRECTORY + '/account-security.html',
      url: '/account-security',
      data: {
        navHighlights: ['account-security']
      }
    });
});