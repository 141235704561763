import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, buttonTypes, SlidingToggle, Spinner, TextEditor } from '@sb-itops/react';
import Styles from './InvoiceStatementEmailForm.module.scss';

export const InvoiceStatementEmailForm = React.memo(
  ({
    isLoading,
    toAddress,
    staffAddress,
    fromAddress,
    ccAddress,
    bccAddress,
    sendCopyToMe,
    subject,
    message,
    isPreviewMode,
    previewSubject,
    previewMessage,
    // functions
    onFieldValueUpdated,
    onPreviewToggled,
  }) => {
    if (isLoading) {
      return (
        <div className={Styles.invoiceEmailForm}>
          <Spinner height={500} message="Loading" />
        </div>
      );
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toAddressInputRef = React.useRef();

    const onChangeMessage = (value, delta, source) => {
      if (source !== 'user') {
        return;
      }
      onFieldValueUpdated('message', value);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (toAddress?.isInvalid) {
        toAddressInputRef.current.focus();
      }
    }, [toAddress?.isInvalid]);

    return (
      <div className={Styles.invoiceStatementEmailForm}>
        <fieldset>
          <div className={classnames('form-group', toAddress?.isInvalid && 'has-error')}>
            <label>TO:</label>
            <input
              className="form-control"
              value={toAddress?.value}
              onChange={(e) => onFieldValueUpdated('toAddress', e.target.value)}
              ref={toAddressInputRef}
            />
            {toAddress?.isInvalid && <div className={Styles.errorMessage}>{toAddress?.invalidReason}</div>}
          </div>
          <div className="row">
            <div className={classnames('form-group col-sm-6', ccAddress?.isInvalid && 'has-error')}>
              <label>CC:</label>
              <input
                className="form-control"
                value={ccAddress?.value}
                onChange={(e) => onFieldValueUpdated('ccAddress', e.target.value)}
              />
              {ccAddress?.isInvalid && <div className={Styles.errorMessage}>{ccAddress?.invalidReason}</div>}
            </div>
            <div className={classnames('form-group col-sm-6', bccAddress?.isInvalid && 'has-error')}>
              <label>BCC:</label>
              <input
                className="form-control"
                value={bccAddress?.value}
                onChange={(e) => onFieldValueUpdated('bccAddress', e.target.value)}
              />
              {bccAddress?.isInvalid && <div className={Styles.errorMessage}>{bccAddress?.invalidReason}</div>}
            </div>
          </div>
          <div className={classnames('form-group', Styles.formGroupInline)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={Styles.sendCopyLabel}
              htmlFor="sendCopySlider"
              onClick={(e) => {
                e.preventDefault();
                onFieldValueUpdated('sendCopyToMe', !sendCopyToMe?.value);
              }}
            >
              <SlidingToggle id="sendCopySlider" scope="sendCopySlider" selected={sendCopyToMe?.value} />
              <div>Send a copy of the email to me ({staffAddress?.value})</div>
            </label>
          </div>

          <div className={classnames('form-group', fromAddress?.isInvalid && 'has-error')}>
            <label>FROM:</label>
            <input
              className="form-control"
              value={fromAddress?.value}
              onChange={(e) => onFieldValueUpdated('fromAddress', e.target.value)}
            />
          </div>

          <div className={classnames('form-group', subject?.isInvalid && 'has-error')}>
            <label>SUBJECT:</label>
            <input
              className="form-control"
              value={isPreviewMode ? previewSubject : subject?.value}
              onChange={(e) => onFieldValueUpdated('subject', e.target.value)}
              disabled={isPreviewMode}
            />
          </div>

          <div className={classnames('form-group', Styles.textEditor, message?.isInvalid && 'has-error')}>
            <label>MESSAGE:</label>
            {/* Input mode text editor */}
            {!isPreviewMode && <TextEditor value={message?.value} onChange={onChangeMessage} />}

            {/* Preview mode text editor */}
            {isPreviewMode && <TextEditor value={previewMessage} disabled />}

            <div className={Styles.textEditorButtonPanel} title={isPreviewMode ? `Edit Email` : `Preview Email`}>
              <Button className={Styles.previewButton} type={buttonTypes.secondary} onClick={onPreviewToggled}>
                {isPreviewMode ? `Edit Email` : `Preview Email`}
              </Button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  },
);

InvoiceStatementEmailForm.displayName = 'InvoiceStatementEmailForm';

InvoiceStatementEmailForm.propTypes = {
  bccAddress: PropTypes.object,
  ccAddress: PropTypes.object,
  fromAddress: PropTypes.object,
  isLoading: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  message: PropTypes.object,
  previewSubject: PropTypes.string,
  previewMessage: PropTypes.string,
  staffAddress: PropTypes.object,
  sendCopyToMe: PropTypes.object,
  subject: PropTypes.object,
  toAddress: PropTypes.object,
  // functions
  onFieldValueUpdated: PropTypes.func.isRequired,
  onPreviewToggled: PropTypes.func.isRequired,
};

InvoiceStatementEmailForm.defaultProps = {
  bccAddress: undefined,
  ccAddress: undefined,
  fromAddress: undefined,
  isLoading: undefined,
  isPreviewMode: undefined,
  message: undefined,
  previewMessage: undefined,
  previewSubject: undefined,
  sendCopyToMe: undefined,
  staffAddress: undefined,
  subject: undefined,
  toAddress: undefined,
};
