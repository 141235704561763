import uuid from '@sb-itops/uuid';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { featureActive } from '@sb-itops/feature';

import { getLatest } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbEndOfMonthReportsService',
  keyPath: 'id',
});

const requiredEndOfMonthReportProps = ['accountId', 'bankAccountId', 'date', 'createdDate', 'id', 'userId', 'firmName'];

const getOptimisticEntity = (newEntry, previousEntity, requiredProps = requiredEndOfMonthReportProps) => {
  const newEntryOptimistic = {
    ...newEntry,
    id: newEntry.id || uuid(),
    includeLeads: featureActive('BB-6595'),
  };

  if (previousEntity) {
    return requiredProps.reduce((acc, prop) => {
      if (!Object.prototype.hasOwnProperty.call(acc, prop)) {
        Object.assign(acc, {
          [prop]: previousEntity[prop],
        });
      }
      return acc;
    }, newEntryOptimistic);
  }

  return newEntryOptimistic;
};

export const saveEndOfMonthReport = (endOfMonthReport) => {
  const saveEndOfMonthReportThunk = async () => {
    const endOfMonthReportToSave = getOptimisticEntity(endOfMonthReport, getLatest(endOfMonthReport.bankAccountId));

    opdateCache({ optimisticEntities: [endOfMonthReportToSave] });

    // Apply the save in the backend.
    try {
      const path = `/billing/end-of-month-reports/:accountId/${endOfMonthReportToSave.id}`;
      const fetchOptions = { body: JSON.stringify(endOfMonthReportToSave) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [endOfMonthReportToSave] });
    }
  };

  store.dispatch(saveEndOfMonthReportThunk);
};
