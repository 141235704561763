import { connect } from 'react-redux';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import * as forms from '@sb-itops/redux/forms2';
import PropTypes from 'prop-types';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { addBankFee, addBankInterest } from '@sb-billing/redux/transactions';
import { getUserId, getAccountId } from 'web/services/user-session-management';
import uuid from '@sb-itops/uuid';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { AddBankFeeInterestFormModal } from './AddBankFeeInterestFormModal';
import { AddBankFeeInterestSchema, validateFn } from '../bank-fee-interest-add-form';

const scope = 'add-bank-fee-interest';

const mapStateToProps = (state, { isVisible }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formSubmitting } = formSelectors.getFormState(state);

  return {
    scope,
    isVisible,
    isSubmitDisabled: formSubmitting,
  };
};

const mapDispatchToProps = (dispatch, { onClose, bankAccountId, isFee, modalId }) => {
  const { operations: formOperations } = withScopedFeature({ scope })(forms);

  const onCloseModal = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    if (modalId) {
      setModalDialogHidden({ modalId });
    }
  };

  return {
    onProcess: async (event) => {
      event.preventDefault();
      await dispatch(formOperations.validateForm({ schema: AddBankFeeInterestSchema, validateFn }));

      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              await sendPayload({ isFee, formFieldValues, bankAccountId });

              onCloseModal();
              messageDisplay.success(`${isFee ? 'Bank fee' : 'Bank interest'} saved successfully.`);
            },
          }),
        );
      } catch (err) {
        messageDisplay.error(`Failed to save the ${isFee ? 'bank fee' : 'bank interest'}`);
      }
    },
    onCloseModal,
  };
};

const sendPayload = async ({ isFee, formFieldValues, bankAccountId }) => {
  const { amount, internalNote, effectiveDate, matterId } = formFieldValues;
  const bankAccount = getBankAccountById(bankAccountId);

  const message = {
    bankAccountId,
    accountType: bankAccount.accountType.toUpperCase(),
    accountId: getAccountId(),
    userId: getUserId(),
    matterId,
    transactionId: uuid(),
    amount,
    internalNote,
    effectiveDate,
    allowOverdraw: true,
  };

  if (isFee) {
    await addBankFee(message);
  } else {
    await addBankInterest(message);
  }
};

export const AddBankFeeInterestFormModalContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(AddBankFeeInterestFormModal),
);

AddBankFeeInterestFormModalContainer.displayName = 'AddBankFeeInterestFormModalContainer';

AddBankFeeInterestFormModalContainer.propTypes = {
  matterId: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  modalId: PropTypes.string,
  onClose: PropTypes.func,
  bankAccountId: PropTypes.string.isRequired,
  isFee: PropTypes.bool,
  allowFutureDate: PropTypes.bool,
};
AddBankFeeInterestFormModal.defaultProps = {
  matterId: undefined,
  modalId: undefined,
  onClose: () => {},
  isFee: false,
  allowFutureDate: false,
};

export default AddBankFeeInterestFormModalContainer;
