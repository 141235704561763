'use strict';

const paymentSource = Object.freeze({
  BANK_CHECK: 'Bank Check',
  TRUST_CHECK: 'Trust Check',
  CHECK: 'Check',
  BANK_TRANSFER: 'Bank transfer',
  CASH: 'Cash',
  CREDIT_CARD: 'Credit Card',
  OPENING_BALANCE: 'Opening Balance',
  DIRECT_DEBIT: 'Direct Debit',
});

const bankTransferSource = Object.freeze({
  BPAY: 'BPAY',
  DIRECT_DEPOSIT: 'Direct Deposit',
});

module.exports = {
  paymentSource,
  bankTransferSource,
};
