import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getById as getAutoNumberDefinitionByAccountId } from '@sb-matter-management/redux/auto-number-definition';
import { getClientRoleOptions, getOtherSideRole } from '@sb-matter-listing/redux/matter-type-configurations';
import { isDuplicateMatterNumber } from '@sb-matter-management/redux/matters';

import { getAccountId } from 'web/services/user-session-management';
import { MatterDetails } from './MatterDetails';

const mapStateToProps = (
  state,
  { formInitialised, formFields, formDisabled, formSubmitting, submitFailed, matterId, t },
) => {
  const {
    locationId,
    matterTypeCategory,
    matterTypeId,
    // originalMatterTypeId is required to remember the original (real) matter type id associated with the matter type
    // selected by the user on the UI. This is because the list of matter type options may contain fake ids generated
    // to represent a particular GENERIC or FED matter type for a particular location, e.g. [GUID]_[LocationId].
    // It's worth keeping in mind we do have real matter types with Id in the same format [GUID]_[LocationId] as well.
    //
    // All these means when a matter is saved, the matter type id stored against the Matter entity may be a fake id
    // doesn't exist in the database. According to Tomas, the reason why this is the case is because our Content team
    // may decide to create location specific content for the given matter type. And this is just a way that someone
    // has decided to store that location information, essentially encoding it inside the matterTypeId
    //
    // originalMatterTypeId is needed below to look up possible client roles from our matter type configurations, there's
    // usually a matter type configuration which corresponds to each matter type, but this is not always the case.
    originalMatterTypeId,
    clientRole,
    matterNumber,
    status,
    description,
    openedDate,
    closedDate,
    leadOpenedDate,
    leadClosedDate,
    leadClosedReason,
    leadMatterTypeId,
  } = formFields;

  const clientRoleOptions = originalMatterTypeId ? getClientRoleOptions(originalMatterTypeId.value) : [];

  // get auto number definition settings for account
  const autoNumberDefinition = getAutoNumberDefinitionByAccountId(getAccountId());

  let matterNumberExistsWarning = '';
  if (isDuplicateMatterNumber(matterNumber.value, matterId)) {
    const matterNumberLabelForRegion = t('matterNumber');
    if (matterNumber.value === matterNumber.originalValue) {
      matterNumberExistsWarning = `This ${matterNumberLabelForRegion} is also used in another matter, please consider allocating a different ${matterNumberLabelForRegion} for each matter.`;
    } else {
      matterNumberExistsWarning = `The ${matterNumberLabelForRegion} already exists. This will create a duplicate ${matterNumberLabelForRegion}.`;
    }
  }

  return {
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    submitFailed,
    // form fields
    locationId,
    matterTypeCategory,
    matterTypeId,
    originalMatterTypeId,
    clientRole,
    matterNumber,
    status,
    description,
    openedDate,
    closedDate,
    leadOpenedDate,
    leadClosedDate,
    leadClosedReason,
    leadMatterTypeId,
    // select options
    clientRoleOptions,
    // other
    autoNumberDefinition,
    matterNumberExistsWarning,
  };
};

const mapDispatchToProps = (dispatch, { formFields, onFieldValuesUpdated }) => ({
  onLocationUpdated: (newLocationId) => {
    onFieldValuesUpdated({
      locationId: newLocationId,
      matterTypeCategory: undefined,
      matterTypeId: undefined,
      clientRole: undefined,
    });
  },
  onCategoryUpdated: (newCategory) => {
    onFieldValuesUpdated({
      matterTypeCategory: newCategory,
      matterTypeId: undefined,
      clientRole: undefined,
    });
  },
  onMatterTypeUpdated: (newMatterType) => {
    // newMatterType has the shape of { label, value, originalMatterTypeId }
    const newMatterTypeId = newMatterType && newMatterType.value; // selected matter type id always has location suffix, e.g. [GUID]_[NSW]

    // pre-select client role for user if there's only one to choose from
    const originalMatterTypeId = newMatterType && newMatterType.originalMatterTypeId; // could look like [GUID] or [GUID]_[NSW]
    const clientRoleOptions = getClientRoleOptions(originalMatterTypeId);
    const clientRole = clientRoleOptions && clientRoleOptions.length === 1 ? clientRoleOptions[0].value : undefined;
    const otherSideRole = getOtherSideRole(originalMatterTypeId, clientRole);
    onFieldValuesUpdated({
      matterTypeId: newMatterTypeId,
      originalMatterTypeId,
      clientRole,
      otherSideRole,
    });
  },
  onClientRoleUpdated: (newClientRole) => {
    const { originalMatterTypeId } = formFields;
    const otherSideRole = getOtherSideRole(originalMatterTypeId.value, newClientRole);
    onFieldValuesUpdated({
      clientRole: newClientRole,
      otherSideRole,
    });
  },
});

export const MatterDetailsContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MatterDetails)),
);

MatterDetailsContainer.displayName = 'MatterDetailsContainer';

MatterDetailsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterDetailsContainer.defaultProps = {
  matterId: undefined,
};
