import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@sb-itops/fe-logger';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import uuid from '@sb-itops/uuid';
import composeHooks from '@sb-itops/react-hooks-compose';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import { featureActive } from '@sb-itops/feature';
import {
  entryType as activityEntryTypes,
  durationType as activityDurationTypes,
} from '@sb-billing/business-logic/shared/entities';
import { billingType } from '@sb-billing/business-logic/matters/billing-config';
import { saveFeeSchema } from './QuickFeeEntry.yup';
import { QuickFeeEntryContainer } from './QuickFeeEntry.container';

const log = getLogger('QuickFeeEntry.forms.container');

const hooks = ({
  scope,
  preferDurationAsUnits,
  matterSummaries,
  activities,
  tasks,
  quickAddDisabled,
  matter,
  utbmsCodesRequiredByFirm,
}) => ({
  // Controls the various form fields state.
  useQuickFeeEntryForm: ({ staffRateConfig, feeDateYYYYMMDD, showMatterField, showStaffField, showDateField }) => {
    const quickFeeEntryForm = useForm({ scope, schema: saveFeeSchema });
    const [isSubjectOverridable, setIsSubjectOverridable] = useState(true);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
      return () => {
        quickFeeEntryForm.onClearForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const feeDate = feeDateYYYYMMDD ? moment(feeDateYYYYMMDD, 'YYYYMMDD') : moment();

    useEffect(() => {
      if (
        !showDateField &&
        moment(quickFeeEntryForm.formValues.feeDate).format('YYYYMMDD') !== feeDate.format('YYYYMMDD') &&
        quickFeeEntryForm.formInitialised
      ) {
        quickFeeEntryForm.onUpdateFields({ feeDate: feeDate.toDate() });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feeDate]);

    useEffect(() => {
      if (quickFeeEntryForm.formInitialised) {
        quickFeeEntryForm.onUpdateFields({
          isBillable: matter?.billingConfiguration?.billingType !== billingType.NOT_BILLABLE,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matter?.billingConfiguration?.billingType]);

    const utbmsCodesRequiredForMatter =
      (utbmsCodesRequiredByFirm && matter?.billingConfiguration?.isUtbmsEnabled) || false;

    // Initialise the form.
    const getDefaultFieldValues = () => ({
      feeDate: feeDate.toDate(),
      staffId: staffRateConfig?.id,
      matterId: matter?.id || '',
      activityId: '',
      isUtbmsActivity: false,
      taskId: '',
      subject: '',
      subjectActivityId: '',
      duration: '1',
      durationInMins: '60',
      durationType: preferDurationAsUnits ? activityDurationTypes.UNITS : activityDurationTypes.HOURS,
      rateInCents: staffRateConfig?.rate,
      isBillable: matter?.billingConfiguration?.billingType !== billingType.NOT_BILLABLE,
      isTaxInclusive: false,
      isTaxExempt: false,
      // Data that is derived from form and prop data to show the user in the UI.
      // The following data is not directly editable by the user.
      time: '',
      roundedDuration: '',
      amountInCents: undefined,
      taxAmountInCents: undefined,
      derivedFieldsCalculated: false,
      // for correct form validation
      showMatterField,
      showStaffField,
      showDateField,
      utbmsCodesRequiredForMatter,
    });

    if (!quickFeeEntryForm.formInitialised && (showMatterField || matter)) {
      quickFeeEntryForm.onInitialiseForm(getDefaultFieldValues());
    }

    // This is a bit hacky. In the form state we store id's where the underlying container expects objects.
    // Forms2 doesn't like changing object instances due to imutability. On the plus side, working with objects
    // makes the underlying container simpler. However, it means that we need to map between ids and objects when
    // we pass props down into the container and when the underlying container updates the object fields.
    // The actual mapping takes place in the returned props from this hook.
    const objectFields = new Set(['matter', 'activity', 'task', 'subjectActivity']);
    const currentMatter = matter || matterSummaries?.find(({ id }) => id === quickFeeEntryForm.formValues.matterId);
    const currentActivity = activities.find((activity) => activity.id === quickFeeEntryForm.formValues.activityId);
    const currentTask = tasks.find(
      (task) => task.id === quickFeeEntryForm.formValues.taskId || task.code === quickFeeEntryForm.formValues.taskId, // taskId is task.code of custom task code
    );
    const currentSubjectActivity = activities.find(
      (activity) => activity.id === quickFeeEntryForm.formValues.subjectActivityId,
    );

    const saveFee = async (formData) => {
      const marshalledData = {
        feeId: uuid(),
        feeVersionId: uuid(),
        matterId: formData.matterId,
        isBillable: formData.isBillable,
        feeEarnerStaffId: formData.staffId,
        feeActivityId: currentActivity?.category === activityCategories.CUSTOM ? currentActivity?.code : null,
        utbmsActivityCode: currentActivity?.category === activityCategories.UTBMS ? currentActivity?.code : null,
        utbmsTaskCode: currentTask?.code,
        description: formData.subject,
        feeDate: moment(formData.feeDate).format('YYYYMMDD'),
        duration: formData.durationInMins,
        durationWorked: featureActive('BB-13563') ? formData.durationInMins : null,
        rate: formData.rateInCents,
        amountIncludesTax: formData.isTaxInclusive,
        isTaxExempt: formData.isTaxExempt,
        feeType:
          formData.durationType === activityDurationTypes.FIXED ? activityEntryTypes.FIXED : activityEntryTypes.TIME,
      };

      await dispatchCommand({ type: 'Billing.Fees.Commands.SaveFee', message: marshalledData });
    };

    const onClearForm = ({ clearFeeDateField = true }) => {
      const defaultFieldValues = getDefaultFieldValues();
      const currentMatterId = quickFeeEntryForm.formValues.matterId;
      const currentUtbmsCodesRequiredForMatter = quickFeeEntryForm.formValues.utbmsCodesRequiredForMatter;

      quickFeeEntryForm.onResetForm({
        ...defaultFieldValues,
        matterId: currentMatterId || defaultFieldValues.matterId,
        feeDate: clearFeeDateField ? feeDate.toDate() : quickFeeEntryForm.formValues.feeDate,
        utbmsCodesRequiredForMatter: currentUtbmsCodesRequiredForMatter,
      });
      setIsSubjectOverridable(true);
    };

    const onSubmitForm = async () => {
      try {
        // Validate and submit the form.
        quickFeeEntryForm.onValidateForm();

        const isValid = await quickFeeEntryForm.onSubmitFormWithValidation({ submitFnP: saveFee });
        if (!isValid) {
          log.warn('Quick fee entry form validation failed');
          return;
        }

        displaySuccessToUser('Time/Fee added successfully');
        // Do not reset feeDate field on Matter level
        onClearForm({ clearFeeDateField: !showDateField });
      } catch (err) {
        log.error('Failed to save quick fee', err);
        displayErrorToUser('Failed to save fee - please check your connection and try again.');
      }
    };

    return {
      quickAddDisabled: quickAddDisabled || quickFeeEntryForm.formSubmitting,
      formReady: quickFeeEntryForm.formInitialised,
      formData: {
        ...quickFeeEntryForm.formValues,
        // Map ID fields into objects for use by the underlying container.
        matter: currentMatter,
        activity: currentActivity,
        task: currentTask,
        subjectActivity: currentSubjectActivity,
      },
      formErrors: {
        ...quickFeeEntryForm.formFields,
        // Map ID fields into objects for use by the underlying container.
        matter: { isInvalid: quickFeeEntryForm.formFields.matterId?.isInvalid },
        activity: { isInvalid: quickFeeEntryForm.formFields.activityId?.isInvalid },
        task: { isInvalid: quickFeeEntryForm.formFields.taskId?.isInvalid },
        subjectActivity: { isInvalid: quickFeeEntryForm.formFields.subjectActivityId?.isInvalid },
        staffId: { isInvalid: quickFeeEntryForm.formFields.staffId?.isInvalid },
      },
      isSubjectOverridable,
      setIsSubjectOverridable,
      onUpdateFormData: (fieldUpdates) => {
        // Map object fields used in the underlying container back into ID's for storage in forms2 state.
        const formFieldUpdates = Object.entries(fieldUpdates).reduce((acc, [fieldName, fieldValue]) => {
          if (objectFields.has(fieldName)) {
            acc[`${fieldName}Id`] = fieldValue?.id;
          } else {
            acc[fieldName] = fieldValue;
          }

          return acc;
        }, {});

        // When the activity has changed, keep the isUtbmsActivity form field in sync for accurate validation of task field.
        if (fieldUpdates.activity) {
          formFieldUpdates.isUtbmsActivity = fieldUpdates.activity.category === activityCategories.UTBMS;
        }

        quickFeeEntryForm.onUpdateFields(formFieldUpdates);

        if (quickFeeEntryForm.submitFailed) {
          quickFeeEntryForm.onValidateForm();
        }
      },
      onAddFeeClicked: onSubmitForm,
      onClearForm,
    };
  },
});

export const QuickFeeEntryFormsContainer = composeHooks(hooks)(({ formReady, ...containerProps }) => {
  if (!formReady) {
    return null;
  }

  return <QuickFeeEntryContainer {...containerProps} />;
});

QuickFeeEntryFormsContainer.displayName = 'QuickFeeEntryFormsContainer';

QuickFeeEntryFormsContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  feeDateYYYYMMDD: PropTypes.string,
  billingIncrementsMins: PropTypes.number.isRequired,
  showMatterField: PropTypes.bool,
  showTasksField: PropTypes.bool,
  quickAddDisabled: PropTypes.bool.isRequired,
  preferDurationAsUnits: PropTypes.bool,
  registeredForGst: PropTypes.bool,
  taxRateBasisPoints: PropTypes.number,
  staffRateConfig: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rate: PropTypes.number,
  }),
  matter: PropTypes.shape({
    billingConfiguration: PropTypes.shape({
      isUtbmsEnabled: PropTypes.bool.isRequired,
      billingType: PropTypes.string,
    }).isRequired,
  }),
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create a fee
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterHourlyRate: PropTypes.shape({
        rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
        allStaffRate: PropTypes.number,
        ratesPerStaff: PropTypes.arrayOf(
          PropTypes.shape({
            staffId: PropTypes.string.isRequired,
            rate: PropTypes.number.isRequired,
          }).isRequired,
        ),
        billableMinutes: PropTypes.number,
      }),
      matterTotals: PropTypes.shape({
        // For legacy typeahead using cache entities
        unbilled: PropTypes.number,
        unpaid: PropTypes.number,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ),
  matterSummariesDataLoading: PropTypes.bool,
  onFetchMatterSummaries: PropTypes.func,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isBillable: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(activityEntryTypes)),
      units: PropTypes.number,
      rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
      allStaffRate: PropTypes.number,
      ratesPerStaff: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.string.isRequired,
          rate: PropTypes.number.isRequired,
        }).isRequired,
      ),
      description: PropTypes.string.isRequired,
      category: PropTypes.oneOf(Object.values(activityCategories)),
    }).isRequired,
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ),
  utbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
};

QuickFeeEntryFormsContainer.defaultProps = {
  showMatterField: false,
  showTasksField: false,
  durationAsUnits: false,
  registeredForGst: false,
  taxRateBasisPoints: undefined,
  matterSummariesDataLoading: undefined,
  onFetchMatterSummaries: undefined,
  tasks: [],
  feeDateYYYYMMDD: undefined,
};
