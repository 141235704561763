import { dot as nestedObjectToFlattened } from 'dot-object';

export const getValidateFn = (selectedContacts) => () => {
  const formErrors = {};

  let contactMissingEmail = false;

  // Error if all contacts don't have an email address
  selectedContacts.forEach((contact) => {
    if (!contact.email) {
      contactMissingEmail = true;
    }
  });

  if (contactMissingEmail) {
    formErrors.selectedContacts = `All contacts selected must have an email address`;
  }

  // Forms 2 expects errors to be reported as flattened dot object notation.
  return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
};
