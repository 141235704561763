import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getAppEnv, envType } from '@sb-itops/app-env';
import * as messageDisplay from '@sb-itops/message-display';
import {
  firmStatuses as feeWiseFirmStatus,
  applicationStatuses as feeWiseApplicationStatuses,
} from '@sb-billing/business-logic/payment-provider/services/fee-wise';
import { fetchGetP, fetchPostP } from '@sb-itops/redux/fetch';
import { getLogger } from '@sb-itops/fe-logger';
import { getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { providers, tempItemTypes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import {
  disconnect as disconnectProvider,
  loadTempItem,
  manage as manageProvider,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getRegion, regionType } from '@sb-itops/region';

// import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { useSelector } from 'react-redux';

import { FeeWiseSettings } from './FeeWiseSettings';

const log = getLogger('FeeWiseSettingsContainer');

const getApplicationStatus = ({ current: feeWiseStatus, previous: previousFeeWiseStatus }) => {
  if (
    previousFeeWiseStatus === feeWiseApplicationStatuses.NO_APPLICATION_OR_ACCOUNT &&
    feeWiseStatus === feeWiseFirmStatus.PENDING
  ) {
    // Special case when we want to show submitted info box until user goes away
    // even though the actual status in feeWise is PENDING.
    return feeWiseApplicationStatuses.APPLICATION_SUBMITTED;
  }

  switch (feeWiseStatus) {
    case feeWiseFirmStatus.PENDING:
    case feeWiseFirmStatus.AWAITING:
      return feeWiseApplicationStatuses.APPLICATION_PROCESSING;
    case feeWiseFirmStatus.ACTIVE:
      return feeWiseApplicationStatuses.APPLICATION_SUCCESSFUL;
    case feeWiseFirmStatus.CLOSED:
      return feeWiseApplicationStatuses.APPLICATION_UNSUCCESSFUL;
    default:
      return feeWiseApplicationStatuses.NO_APPLICATION_OR_ACCOUNT;
  }
};

const hooks = () => ({
  useFeeWiseSettings: () => {
    const isNonProdAppEnv = getAppEnv() !== envType.PRODUCTION;
    const feeWiseSettings = useSelector(() => getProviderSettings(providers.FEE_WISE) || {});

    const [applicationFormData, setApplicationFormData] = useState(undefined);
    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState(() =>
      getApplicationStatus({ current: feeWiseSettings.status }),
    );
    const [isUploading, setIsUploading] = useState(false);
    const [isDisconnecting, setIsDisconnecting] = useState(false);

    useEffect(() => {
      const status = getApplicationStatus({ current: feeWiseSettings.status, previous: applicationStatus });
      setApplicationStatus(status);

      if (status !== feeWiseApplicationStatuses.NO_APPLICATION_OR_ACCOUNT) {
        return;
      }

      // Fetching makes sense only when status is NO_APPLICATION_OR_ACCOUNT
      const fetchSavedForm = async () => {
        const res =
          (await loadTempItem({
            fetchGetP,
            providerType: providers.FEE_WISE,
            itemType: tempItemTypes.APPLICATION_FORM,
          })) || {};

        const savedForm = res.data;
        if (savedForm) {
          setApplicationFormData(savedForm);
          setShowApplicationForm(true);
        } else {
          setApplicationFormData(false);
        }
      };

      try {
        fetchSavedForm();
      } catch (err) {
        log.error(err);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feeWiseSettings.status]);

    return {
      applicationStatus,
      feeWiseApplicationStatuses,
      applicationFormData,
      showApplicationForm,
      setShowApplicationForm,
      isNonProdAppEnv,
      isDisconnecting,
      isUploading,
      onDisconnect: async () => {
        try {
          setIsDisconnecting(true);
          await disconnectProvider({ fetchPostP, providerType: providers.FEE_WISE, providerSpecificPayload: {} });
          messageDisplay.success(`Successfully disconnected`);
        } catch (err) {
          log.error(err);
          messageDisplay.error(`Failed to disconnect`);
          throw err;
        } finally {
          setIsDisconnecting(false);
        }
      },
      onUploadSampleDocument: async () => {
        let success = false;
        try {
          setIsUploading(true);
          const { uploaded } = await manageProvider({
            fetchPostP,
            providerType: providers.FEE_WISE,
            providerSpecificFields: { actionType: 'UPLOAD_SAMPLE_ID_DOCUMENT' },
          });
          success = !!uploaded;
        } catch (err) {
          success = false;
        } finally {
          if (success) {
            messageDisplay.success(`ID Document uploaded, please wait until firm is active.`);
          } else {
            messageDisplay.error(`Failed to upload sample document. Please try again in few minutes.`);
          }
          setIsUploading(false);
        }
      },
    };
  },
  useSupportedPaymentMethods: () => {
    const [acceptedPaymentsText, setAcceptedPaymentsText] = useState([]);

    useEffect(() => {
      const regionStrategy = {
        [regionType.AU]: 'credit card',
        [regionType.GB]: 'credit card',
        [regionType.US]: 'credit card and eCheck',
      };

      setAcceptedPaymentsText([
        `Accept secure ${regionStrategy[getRegion()] || ''} payments through your`,
        `fully integrated Smokeball payments account.`,
      ]);
    }, []);

    return { getStartedContextText: acceptedPaymentsText };
  },
});

export const FeeWiseSettingsContainer = withReduxProvider(composeHooks(hooks)(FeeWiseSettings));

FeeWiseSettingsContainer.displayName = 'FeeWiseSettingsContainer';
FeeWiseSettingsContainer.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
FeeWiseSettingsContainer.defaultProps = {};
