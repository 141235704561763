import React from 'react';
import PropTypes from 'prop-types';
import { LinkableText } from '@sb-itops/react/linkable-text';

const ContactDisplay = (props) => {
  const { contactId, onClickLink, displayName, className, asLink, inline, tooltip, isError, onClick } = props;

  const onClickContact = () => (onClick ? onClick(contactId) : onClickLink({ type: 'contact', id: contactId }));

  return (
    <LinkableText
      className={className}
      text={displayName || 'unknown'}
      tooltip={tooltip}
      onClickLink={onClickContact}
      asLink={displayName && asLink} // we dont want to show the display as a link if there is nothing to link to
      inline={inline}
      isError={isError}
    />
  );
};

ContactDisplay.propTypes = {
  contactId: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  onClickLink: PropTypes.func,
  onClick: PropTypes.func, // Used for overriing default behaviour
  className: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  isError: PropTypes.bool,
  tooltip: PropTypes.string,
};

ContactDisplay.defaultProps = {
  displayName: '',
  className: 'contact-display',
  onClickLink: () => {},
  onClick: undefined,
  asLink: false,
  inline: false,
  isError: false,
  tooltip: undefined,
};

export default ContactDisplay;
