import React from 'react';
import PropTypes from 'prop-types';
import {
  forms2PropTypes,
  Button,
  CurrencyInput2,
  FloatingCard,
  FormLabel,
  InputWithLoadingState,
  NumberInput,
  RadioButtons,
  SlidingToggle,
  Spinner,
} from '@sb-itops/react';
import {
  defaultStaffTargetFrequency,
  staffTargetFrequency,
  staffTargetFrequencyLabels,
} from '@sb-billing/business-logic/staff-targets';

import { targetGroupNameMustBeUniqueErrorMessage } from './AddEditTargetGroup.schema';

import Styles from './AddEditTargetGroup.module.scss';

const getTargetFrequencyOptions = (targetFrequency) => [
  {
    id: staffTargetFrequency.WEEKLY,
    label: staffTargetFrequencyLabels.WEEKLY,
    active:
      targetFrequency === staffTargetFrequency.WEEKLY ||
      (!targetFrequency && defaultStaffTargetFrequency === staffTargetFrequency.WEEKLY), // set default if target frequency is not set, e.g. when new target type is introduced
  },
  {
    id: staffTargetFrequency.MONTHLY,
    label: staffTargetFrequencyLabels.MONTHLY,
    active:
      targetFrequency === staffTargetFrequency.MONTHLY ||
      // @ts-ignore
      (!targetFrequency && defaultStaffTargetFrequency === staffTargetFrequency.MONTHLY), // set default if target frequency is not set
  },
];

function toOneDecimalPlace(number) {
  return Number(Number(number).toFixed(1));
}

const StaffTarget = ({
  targetLabel,
  targetValueField,
  targetFrequencyField,
  onFieldValueUpdated,
  submitFailed,
  validateForm,
}) => {
  const onHoursValueChange = (e) => {
    onFieldValueUpdated({ [targetValueField.key]: toOneDecimalPlace(e.target.value) });
  };

  const onDollarValueChange = (e) => {
    onFieldValueUpdated({ [targetValueField.key]: e.target.value });
  };
  const onTargetFrequencyChange = ({ id: newTargetFrequency }) => {
    onFieldValueUpdated({ [targetFrequencyField.key]: newTargetFrequency });
  };

  return (
    <div className="row">
      <div className="col-xs-6">
        <FormLabel
          label={targetLabel}
          field={targetValueField}
          submitFailed={submitFailed}
          uppercase={false}
          optional
        />
        {targetLabel.includes('Hours') ? (
          <NumberInput
            className="form-control"
            name={targetValueField.key}
            value={targetValueField.value}
            min={0}
            onChange={onHoursValueChange}
            onFocus={(e) => e.target.select()}
          />
        ) : (
          <CurrencyInput2
            name={targetValueField.key}
            value={targetValueField.value}
            onChange={onDollarValueChange}
            min={0}
            onBlur={validateForm}
          />
        )}
      </div>
      <div className="col-xs-6">
        <FormLabel label="&nbsp;" field={targetFrequencyField} submitFailed={submitFailed} uppercase={false} optional />
        <RadioButtons
          className="input-group"
          buttonList={getTargetFrequencyOptions(targetFrequencyField.value)}
          disabled={false}
          size="full-width"
          onRadioChange={onTargetFrequencyChange}
        />
      </div>
    </div>
  );
};

export const AddEditTargetGroup = React.memo(
  ({
    targetGroupId,
    // form fields
    targetGroupName,
    billableHours,
    billableHoursFrequency,
    billableValue,
    billableValueFrequency,
    workedHours,
    workedHoursFrequency,
    workedValue,
    workedValueFrequency,
    billedHours,
    billedHoursFrequency,
    billedValue,
    billedValueFrequency,
    collectedValue,
    collectedValueFrequency,
    isInactive,
    // form state
    formDirty,
    formInitialised,
    formSubmitting,
    formValidation,
    isStaffTargetGroupNameUnique,
    isCheckingStaffTargetGroupName,
    submitFailed,
    // func
    onClose,
    onFieldValueUpdated,
    onSave,
    onTargetGroupNameChange,
    validateForm,
  }) => {
    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');
    const disableSaveButton =
      !formDirty || formSubmitting || isCheckingStaffTargetGroupName || !isStaffTargetGroupNameUnique;

    return (
      <div className={Styles.addEditTargetGroup}>
        {!formInitialised ? (
          <Spinner small />
        ) : (
          <FloatingCard applyMargin={false}>
            <fieldset disabled={formSubmitting}>
              <h3 className={Styles.title}>{targetGroupId ? 'Edit Target Group' : 'Add Target Group'}</h3>
              <div className="row">
                <div className="col-xs-12">
                  <FormLabel label="Name" field={targetGroupName} submitFailed={submitFailed} uppercase={false} />
                  <InputWithLoadingState
                    hasError={!!isErrorClassnames(targetGroupName) || !isStaffTargetGroupNameUnique}
                    value={targetGroupName.value}
                    isLoading={isCheckingStaffTargetGroupName}
                    maxLength="200"
                    onChange={onTargetGroupNameChange}
                    onBlur={validateForm}
                  />
                </div>
              </div>
              {!isStaffTargetGroupNameUnique && (
                <div className="row">
                  <div className="col-xs-12">
                    <span className={Styles.errorMessage}>{targetGroupNameMustBeUniqueErrorMessage}</span>
                  </div>
                </div>
              )}
              <StaffTarget
                targetLabel="Billable Hours"
                targetValueField={billableHours}
                targetFrequencyField={billableHoursFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <StaffTarget
                targetLabel="Billable Value"
                targetValueField={billableValue}
                targetFrequencyField={billableValueFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <StaffTarget
                targetLabel="Worked Hours"
                targetValueField={workedHours}
                targetFrequencyField={workedHoursFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <StaffTarget
                targetLabel="Worked Value"
                targetValueField={workedValue}
                targetFrequencyField={workedValueFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <StaffTarget
                targetLabel="Billed Hours"
                targetValueField={billedHours}
                targetFrequencyField={billedHoursFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <StaffTarget
                targetLabel="Billed Value"
                targetValueField={billedValue}
                targetFrequencyField={billedValueFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <StaffTarget
                targetLabel="Collected Value"
                targetValueField={collectedValue}
                targetFrequencyField={collectedValueFrequency}
                onFieldValueUpdated={onFieldValueUpdated}
                submitFailed={submitFailed}
                validateForm={validateForm}
              />
              <br />
              {targetGroupId && ( // show in edit mode only
                <div className="row">
                  <div className="col-xs-12">
                    <div className={Styles.showTargetGroupToggle}>
                      <label>
                        <SlidingToggle
                          scope="activate-target-group-sliding-toggle"
                          onChange={() => {
                            onFieldValueUpdated({ isInactive: !isInactive.value });
                          }}
                          selected={!isInactive.value}
                        />
                        <span>Active</span>
                      </label>
                      {!isInactive.value && (
                        <i
                          title="Setting the target group to inactive will remove all targets from the staff in this group."
                          className="icon icon-question-mark"
                          color="lightgrey"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {isInactive.value && isInactive.isDirty && (
                <div className="row">
                  <div className="col-xs-12">
                    <span className={Styles.errorMessage}>
                      This will remove the targets from all staff in this group.
                    </span>
                  </div>
                </div>
              )}
              {formValidation?.atLeastOneTargetRequired && (
                <div className="row">
                  <div className="col-xs-12">
                    <span className={Styles.errorMessage}>At least one target value must be greater than zero.</span>
                  </div>
                </div>
              )}
            </fieldset>
            <div className={Styles.formFooter}>
              <Button
                locked={formSubmitting} // spinner
                disabled={disableSaveButton}
                className={Styles.saveButton}
                size="full-width"
                onClick={onSave}
              >
                Save
              </Button>
              <Button
                disabled={formSubmitting}
                className={Styles.cancelButton}
                size="full-width"
                type="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </FloatingCard>
        )}
      </div>
    );
  },
);

AddEditTargetGroup.displayName = 'AddEditTargetGroup';

const { Forms2Field } = forms2PropTypes;

AddEditTargetGroup.propTypes = {
  // form fields
  targetGroupId: PropTypes.string,
  targetGroupName: Forms2Field,
  billableHours: Forms2Field,
  billableHoursFrequency: Forms2Field,
  billableValue: Forms2Field,
  billableValueFrequency: Forms2Field,
  workedHours: Forms2Field,
  workedHoursFrequency: Forms2Field,
  workedValue: Forms2Field,
  workedValueFrequency: Forms2Field,
  billedHours: Forms2Field,
  billedHoursFrequency: Forms2Field,
  billedValue: Forms2Field,
  billedValueFrequency: Forms2Field,
  collectedValue: Forms2Field,
  collectedValueFrequency: Forms2Field,
  isInactive: Forms2Field,
  // form state
  formDirty: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  formValidation: PropTypes.object.isRequired,
  isStaffTargetGroupNameUnique: PropTypes.bool.isRequired,
  isCheckingStaffTargetGroupName: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  // func & callbacks
  onClose: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTargetGroupNameChange: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
};

AddEditTargetGroup.defaultProps = {
  targetGroupId: undefined,
  targetGroupName: undefined,
  billableHours: undefined,
  billableHoursFrequency: undefined,
  billableValue: undefined,
  billableValueFrequency: undefined,
  workedHours: undefined,
  workedHoursFrequency: undefined,
  workedValue: undefined,
  workedValueFrequency: undefined,
  billedHours: undefined,
  billedHoursFrequency: undefined,
  billedValue: undefined,
  billedValueFrequency: undefined,
  collectedValue: undefined,
  collectedValueFrequency: undefined,
  isInactive: undefined,
};
