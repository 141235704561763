import { integerToDate } from '@sb-itops/date';

export const getSelectedDateFilterType = (state) => state && state.selectedDateFilterType;
export const getSelectedBeforeDate = (state) =>
  state && state.selectedBeforeDate && integerToDate(state.selectedBeforeDate);
export const getSelectedFromDate = (state) => state && state.selectedFromDate && integerToDate(state.selectedFromDate);
export const getSelectedToDate = (state) => state && state.selectedToDate && integerToDate(state.selectedToDate);
export const getSelectedStaffIds = (state) => state && state.selectedStaffIds && Object.keys(state.selectedStaffIds);
export const getHideIfOnFinalisedInvoice = (state) => state && state.hideIfOnFinalisedInvoice;
export const getHideIfOnACheck = (state) => state && state.hideIfOnACheck;
export const getShowDisbursements = (state) => state && state.showDisbursements;
export const getShowAnticipatedDisbursements = (state) => state && state.showAnticipatedDisbursements;
export const getShowAnticipatedDisbursementsUnpaid = (state) => state && state.showAnticipatedDisbursementsUnpaid;
export const getShowAnticipatedDisbursementsOverdue = (state) => state && state.showAnticipatedDisbursementsOverdue;
export const getShowAnticipatedDisbursementsPaid = (state) => state && state.showAnticipatedDisbursementsPaid;

export const getShowFilters = (state) => state.showFilters;
