import { getHostedField } from './hosted-fields';

export const getToken = (publicKey, tokenData) => {
  const hostedFields = getHostedField(publicKey);
  if (!hostedFields) {
    return undefined;
  }

  return hostedFields.getPaymentToken(tokenData);
};
