angular.module('sb.billing.webapp').component('sbDataDynamicUpdater', {
  template: '<ng-transclude></ng-transclude>',
  bindings: { composeKey: '@' },
  require: { compose: '^sbCompose' },
  transclude: true,
  controller: function () {
    'use strict';
    var ctrl = this;

    ctrl.updateData = function (data) {
      ctrl.compose.setComposeData({dataType: ctrl.composeKey, data: data}, ctrl.composeKey);
    };
  }
});