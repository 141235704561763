import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class CheckboxInlineWithChildren extends React.PureComponent {
  checkboxRef = React.createRef();

  onLabelClick = (e) => {
    e.stopPropagation();
    this.props.onChange(!this.checkboxRef.current.checked);
  };

  handleChange = (e) => {
    e.stopPropagation();
    this.props.onChange(e.target.checked);
  };

  // eslint-disable-next-line class-methods-use-this
  stopPropagationCheckbox = (e) => {
    e.stopPropagation();
  };

  render() {
    return (
      <div className={classnames('input-checkbox-label', this.props.parentClassName)}>
        <input
          ref={this.checkboxRef}
          type="checkbox"
          className={classnames(
            'input-checkbox-inline',
            this.props.checkboxClassName,
            this.props.hasError ? 'has-error' : '',
          )}
          onChange={this.handleChange}
          checked={this.props.checked}
          disabled={this.props.disabled}
          onClick={this.stopPropagationCheckbox}
        />
        <span
          className="input-checkbox-child"
          disabled={this.props.disabled}
          onClick={this.onLabelClick}
          role="button"
          tabIndex={0}
        >
          {this.props.children}
        </span>
      </div>
    );
  }
}

const CheckboxLabelInline = ({
  checked,
  disabled,
  onChange,
  children,
  parentClassName,
  checkboxClassName,
  hasError,
}) => {
  if (children) {
    return (
      <CheckboxInlineWithChildren
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        parentClassName={parentClassName}
        checkboxClassName={checkboxClassName}
        hasError={hasError}
      >
        {children}
      </CheckboxInlineWithChildren>
    );
  }

  const handleChange = (e) => {
    e.stopPropagation();
    onChange(e.target.checked);
  };

  return (
    <input
      type="checkbox"
      className={classnames('input-checkbox-inline', checkboxClassName)}
      checked={checked}
      onChange={handleChange}
    />
  );
};

CheckboxLabelInline.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  parentClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  hasError: PropTypes.bool,
};

CheckboxLabelInline.defaultProps = {
  checked: undefined,
  children: undefined,
  disabled: false,
  parentClassName: '',
  checkboxClassName: '',
  hasError: false,
};

CheckboxLabelInline.displayName = 'CheckboxLabelInline';

export default CheckboxLabelInline;
