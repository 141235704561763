import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { withOnLoad } from '@sb-itops/react';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { LawpayElectronicChequeForm } from './LawpayElectronicChequeForm';
import { LawpayElectronicChequeFormSchema } from './LawpayElectronicChequeFormSchema';
import { accountTypes, accountHolderTypes } from './echeque-types';

const mapFormFieldsToToken = (formFields) => ({
  account_type: formFields.accountType.toLowerCase(),
  account_holder_type: formFields.accountHolderType.toLowerCase(),
  given_name: formFields.accountHolderType === 'Individual' ? formFields.firstName : '',
  surname: formFields.accountHolderType === 'Individual' ? formFields.lastName : '',
  name: formFields.accountHolderType === 'Business' ? formFields.businessName : '',
  address1: formFields.addressLine1,
  address2: formFields.addressLine2,
  city: formFields.city,
  state: formFields.state,
  postal_code: formFields.postalCode,
  country: 'US',
  email: formFields.email,
});

const mapStateToProps = (state, { scope, isSubmitting }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);
  const { formInitialised, fields, formDirty, formValid } = formSelectors.getFormState(state);
  const formFieldValues = formSelectors.getFieldValues(state);

  return {
    accountHolderTypes,
    accountTypes,
    formFields: fields,
    formFieldValues,
    isSubmitting,
    formInitialised,
    formDirty,
    formValid,
  };
};

const mapDispatchToProps = (dispatch, { scope }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const fieldValues = {
        firstName: '',
        lastName: '',
        businessName: '',
        accountType: 'Checking',
        accountHolderType: 'Individual',
        addressLine1: '',
        city: '',
        addressLine2: '',
        country: 'US',
        state: undefined,
        postalCode: '',
        email: '',
        bankAccountNumberStatus: false,
        routingNumberStatus: false,
      };

      dispatch(formActions.initialiseForm({ fieldValues }));

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onChange: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: LawpayElectronicChequeFormSchema }));
    },
  };
};

const onSubmitTriggered = async ({ hostedFieldsApi, formFieldValues, onSubmit }) => {
  let paymentToken;
  try {
    const mappedFields = mapFormFieldsToToken(formFieldValues);
    paymentToken = await hostedFieldsApi.getPaymentToken(mappedFields);
  } catch (err) {
    // This can happen if the form data is baked, e.g. a credit card is placed in the name field.
    // In that situation, the tokenizer will detect it and fail. It's a PITA to manage it correctly,
    // as the affinipay API doesn't give us any useful information about which field is backed. It's
    // a bit hacky, but we will need to consume the error here and signify to sendPaymentP that an
    // error in tokenization has occured by sending an undefined payment token.

    // Affinipay hosted fields API should have already set this to be undefined already at this point,
    // but we set it anyway just to be sure we signify error.
    paymentToken = undefined;
  }

  onSubmit({
    paymentMethod: paymentMethods.ECHEQUE,
    paymentToken,
    // exposing some fields to help make success/failure message more meaningful
    accountType: formFieldValues.accountType,
    accountHolderName:
      formFieldValues.accountHolderType === 'Business'
        ? formFieldValues.businessName
        : `${formFieldValues.firstName} ${formFieldValues.lastName}`.trim(),
    accountNumberTruncated: paymentToken && paymentToken.bank_account_number,
  });
};

export const LawpayElectronicChequeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withOnLoad(({ triggerSubmit, formFieldValues, formValid, onReadyForSubmit, onSubmit, ...props }) => {
    // Hosted fields API handling.
    // When the hosted fields are initialised, an API will be provided which allows for tokenization of the hosted fields and the form data.
    // We hijack the `onSubmit` function of the overall form here and inject the hosted fields API into it.
    const [hostedFieldsApi, setHostedFieldsApi] = useState();
    const [submissionTriggered, setSubmissionTriggered] = useState(false);

    onReadyForSubmit(!!(hostedFieldsApi && formValid));

    // Trigger the submission.
    if (triggerSubmit && !submissionTriggered) {
      if (!hostedFieldsApi || !formValid) {
        throw new Error('Lawpay credit card form submission should not be triggered when in non-ready state');
      }

      setSubmissionTriggered(true);
      onSubmitTriggered({ hostedFieldsApi, formFieldValues, onSubmit });
    }

    // We are ok to allow submission again.
    if (!triggerSubmit && submissionTriggered) {
      setSubmissionTriggered(false);
    }

    return <LawpayElectronicChequeForm onHostedFieldsApiReady={setHostedFieldsApi} {...props} />;
  }),
);

LawpayElectronicChequeFormContainer.displayName = 'LawpayElectronicChequeFormContainer';

LawpayElectronicChequeFormContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  publicKey: PropTypes.string.isRequired,
  hostedFieldsStyle: PropTypes.object.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

LawpayElectronicChequeFormContainer.defaultProps = {};
