import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Pill } from '../pill';

import Styles from './StatusPill.module.scss';

/**
 * A component to show the status of an entity in standardised way
 * statusLabel can be any arbitrary text, deleted will be shown in special colour
 * className can be provided to override the default colour scheme used
 */
export const StatusPill = memo(({ statusLabel, className }) => (
  <Pill
    label={statusLabel}
    className={classNames(statusLabel.toUpperCase() === 'DELETED' && Styles.deletedStatus, className)}
  />
));

StatusPill.displayName = 'StatusPill';

StatusPill.propTypes = {
  statusLabel: PropTypes.string.isRequired,
  className: PropTypes.string, // use className to override default status pill colour and/or styling
};

StatusPill.defaultProps = {
  className: '',
};
