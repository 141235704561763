import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { getPseudoStatusById } from '@sb-billing/redux/invoices';
import { InvoiceStatusIndicator } from './InvoiceStatusIndicator';

const mapStateToProps = (state, { invoiceId }) => ({
  status: getPseudoStatusById(invoiceId),
});

/**
 * @deprecated
 * Once the Invoice lists (specifically the tables) are permanently converted to LOD, this container will no longer be needed as we can just use the component directly
 */
export const InvoiceStatusIndicatorContainer = withReduxStore(connect(mapStateToProps)(InvoiceStatusIndicator));

InvoiceStatusIndicatorContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

InvoiceStatusIndicatorContainer.defaultProps = {};

export default InvoiceStatusIndicatorContainer;
