import * as types from './types';

const { authenticationStatuses } = types;

export const initState = {
  authStatus: authenticationStatuses.NOT_AUTHENTICATED,
  loginInProgress: false,
  refreshInProgress: false,
  logoutInProgress: false,
  loginChallenged: false,
  challengeResponseInProgress: false,
  logoutFailed: false,
  userIdentity: {},
  challenge: {},
  failureDetails: {},
};

export const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case types.ASSUME_USER_IS_AUTHENTICATED:
      return {
        ...state,
        authStatus: authenticationStatuses.AUTHENTICATED,
      };

    case types.FORCE_USER_IDENTITY:
      return {
        ...state,
        userIdentity: action.payload.userIdentity,
      };

    case types.LOGIN_INITIATED:
      return {
        ...state,
        loginInProgress: true,
        failureDetails: {},
      };

    case types.LOGIN_CHALLENGED:
      return {
        ...state,
        isLoginChallenged: true,
        challenge: action.payload.challenge,
      };

    case types.CHALLENGE_RESPONSE_INITIATED:
      return {
        ...state,
        challengeResponseInProgress: true,
      };

    case types.CHALLENGE_RESPONSE_FAILURE:
      return {
        ...state,
        challengeResponseInProgress: false,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authStatus: authenticationStatuses.AUTHENTICATED,
        loginInProgress: false,
        userIdentity: action.payload.userIdentity,
        isLoginChallenged: false,
        challengeResponseInProgress: false,
        challenge: {},
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        loginInProgress: false,
        isLoginChallenged: false,
        challengeResponseInProgress: false,
        challenge: {},
        failureDetails: action.payload.failureDetails || {},
      };

    case types.REFRESH_TOKEN_INITIATED:
      return {
        ...state,
        authStatus: authenticationStatuses.NOT_AUTHENTICATED,
        refreshInProgress: true,
      };

    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        authStatus: authenticationStatuses.AUTHENTICATED,
        refreshInProgress: false,
        userIdentity: action.payload.userIdentity,
      };

    case types.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        refreshInProgress: false,
        userIdentity: {},
      };

    case types.LOGOUT_INITIATED:
      return {
        ...state,
        authStatus: authenticationStatuses.NOT_AUTHENTICATED,
        logoutInProgress: true,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutInProgress: false,
        logoutFailed: false,
        userIdentity: {},
      };

    case types.LOGOUT_FAILURE:
      return {
        ...state,
        logoutInProgress: false,
        logoutFailed: true,
        userIdentity: {},
      };

    default:
      return state;
  }
};
