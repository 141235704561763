import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';

import UserNameDisplay from './UserNameDisplay';

const getDisplay = (contact) => {
  if (!contact) {
    return '';
  }

  return contact.name || contact.initials;
};

const hooks = () => ({
  useUserNameDisplay: ({ user }) => ({
    display: getDisplay(user),
  }),
});

const UserNameDisplayContainer = composeHooks(hooks)(UserNameDisplay);

UserNameDisplayContainer.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    person: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        initials: PropTypes.string,
      }),
    ),
  }),
  inline: PropTypes.bool,
};

UserNameDisplayContainer.defaultProps = {
  user: undefined,
  inline: false,
};

export default UserNameDisplayContainer;
