import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu } from '@sb-itops/react/context-menu';
import Styles from './InvoiceViewedOnlineStatusIndicator.module.scss';

export const InvoiceViewedOnlineStatusIndicator = ({ viewedPerContactMessage }) => {
  if (!viewedPerContactMessage) {
    return null;
  }

  return (
    <div className={Styles.invoiceViewedOnlineStatusIndicator}>
      <ContextMenu body={() => viewedPerContactMessage}>
        <i className="icon icon-check-1" />
      </ContextMenu>
    </div>
  );
};

InvoiceViewedOnlineStatusIndicator.displayName = 'InvoiceViewedOnlineStatusIndicator';

InvoiceViewedOnlineStatusIndicator.propTypes = {
  viewedPerContactMessage: PropTypes.string.isRequired,
};

InvoiceViewedOnlineStatusIndicator.defaultProps = {};
