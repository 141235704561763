import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import uuid from '@sb-itops/uuid';
import { calculateFeeDetails, extractFeeSchedule } from '@sb-billing/business-logic/payment-provider/services';

export const marshalData = ({
  formData,
  paymentSourceSelected,
  operatingAccount,
  invoiceSummaries,
  activeProviderType,
  activeProviderFormattedSettings,
  providerSpecificChargeData,
}) => {
  const {
    paidById,
    reference,
    comment,
    payments: paymentAmountsByInvoiceId,
    reason,
    effectiveDate,
    amount,
    paymentType,
    takePaymentNow,
    saveCardDetails,
  } = formData;

  let totalPayments = 0;
  const payments = (invoiceSummaries || []).reduce((acc, invSummary) => {
    const paymentAmount = paymentAmountsByInvoiceId[invSummary.id];
    if (Number.isFinite(paymentAmount) && paymentAmount > 0) {
      totalPayments += paymentAmount;
      const payment = {
        invoiceId: invSummary?.id,
        matterId: invSummary?.matter?.id,
        invoiceNumber: invSummary?.invoiceNumber,
        amount: paymentAmount,
      };
      acc.push(payment);
    }
    return acc;
  }, []);

  const msg = {
    paymentId: uuid(), // need to generate for opdating and sending to print trust pdf
    payorId: paidById,
    source: paymentSourceSelected.value,
    invoices: payments,
    reference,
    note: comment,
    effectiveDate,
    totalAmount: amount,
    paymentType, // used by backend to determine which message to send
    isElectronicPayment: false,
  };

  let reasonValue = null;
  // reasonField is AU/GB facet
  if (featureActive('BB-5508') && hasFacet(facets.reasonField)) {
    reasonValue = reason;
  }
  msg.reason = reasonValue;

  msg.allowOverdraw = hasFacet(facets.allowOverdraw);

  // These values are needed for handling charges in onTakePayment function
  if (takePaymentNow) {
    const bankAccountId = operatingAccount?.id;
    msg.bankAccountId = bankAccountId;
    msg.saveCardDetails = saveCardDetails;

    // Handle fees
    // Calculate the fee details if the fee is being passed on to the client.
    const clientIsCoveringFee = activeProviderFormattedSettings.clientCoversFeeOnPayments;

    if (clientIsCoveringFee) {
      const feeSchedule = clientIsCoveringFee
        ? extractFeeSchedule({
            providerType: activeProviderType,
            formattedProviderSpecificSettings: activeProviderFormattedSettings,
            bankAccountId,
          })
        : undefined;

      const feeDetails = clientIsCoveringFee
        ? calculateFeeDetails({
            providerType: activeProviderType,
            feeSchedule,
            providerSpecificFields: providerSpecificChargeData,
            desiredAmountInCents: amount || 0,
          })
        : {};

      msg.amountInCents = feeDetails.effectiveAmountInCents;
      msg.amountLessFees = feeDetails.desiredAmountInCents;
    } else {
      msg.amountInCents = amount;
      msg.amountLessFees = amount;
    }

    // If amount is less than invoices totalPayments then apply to oldest invoice first
    if (amount !== totalPayments) {
      let remaining = amount;
      const invoices = [];

      for (let i = 0; i <= payments.length; i += 1) {
        let invoice = payments[i];

        if (remaining < invoice.amount) {
          invoice = { ...invoice, amount: remaining };
          invoices.push(invoice);
          break;
        }

        invoices.push({ ...invoice });

        remaining -= invoice.amount;
      }
      msg.invoices = invoices;
    }
  }

  // We always sends client batch payment for invoice statement payment as it's all client payment
  // Ensure only 1 payment per matter, even if it covers more than 1 invoice
  const paymentIds = {};
  msg.invoices = msg.invoices.map((invoice) => {
    if (!paymentIds[invoice.matterId]) {
      paymentIds[invoice.matterId] = uuid();
    }

    return {
      ...invoice,
      paymentId: paymentIds[invoice.matterId],
    };
  });

  return msg;
};
