import React from 'react';
import PropTypes from 'prop-types';
import Styles from './FeeWisePaymentForm.module.scss';
import { FeeWiseLoader } from './loader/FeeWiseLoader';

export const FeeWisePaymentForm = ({ isLoading, isError, termsText, isFeeWiseFormLoaded }) => (
  <div className={Styles.feeWisePaymentForm}>
    <fieldset>
      <div id="feewise-iframe-wrapper">
        {isError && <div className={Styles.error}>Cannot load the payment form, please try later</div>}
        <div className={Styles.loading}>{isLoading && <FeeWiseLoader />}</div>
      </div>
      {!isError && !isLoading && isFeeWiseFormLoaded && termsText && (
        <div className={Styles.termsText}>{termsText}</div>
      )}
    </fieldset>
  </div>
);

FeeWisePaymentForm.displayName = 'FeeWisePaymentForm';

FeeWisePaymentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isFeeWiseFormLoaded: PropTypes.bool.isRequired,
  termsText: PropTypes.string,
};

FeeWisePaymentForm.defaultProps = {
  termsText: '',
};
