import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from '@sb-itops/react';
import { sortByProperty } from '@sb-itops/nodash';
import { getFeeDaySummaries } from '@sb-billing/business-logic/fee/services';
import { entryType as feeEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { displayModes, FeeSummaryChart } from './FeeSummaryChart';

export const FeeSummaryChartContainer = (props) => {
  const { fees, selectedDayYYYYMMDD, selectedMonthYYYYMM, onDayChange, onMonthChange, isWidget } = props;

  const [selectedDisplayMode, setSelectedDisplayMode] = useState(displayModes.TIME);
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();

  // Dates based on the time on the user machine.
  const currentDate = moment(moment().format('YYYYMMDD'), 'YYYYMMDD');
  const currentMonth = moment(currentDate.format('YYYYMM'), 'YYYYMM'); // zeros out the day component.

  // Dates based on what the user has selected in the UI.
  const selectedDay = moment(selectedDayYYYYMMDD, 'YYYYMMDD');
  const selectedMonth = moment(selectedMonthYYYYMM, 'YYYYMM');

  // Provides the data for the actual chart and the aggregate tiles to the right of the chart.
  // This data is memoised to prevent animated re-render of chart when non-chart data props change.
  const formattedChartData = useMemo(() => {
    // Get an array of fee groups by day and convert into an efficently searchable object for the month.
    const feeDaySummaries = sortByProperty(getFeeDaySummaries(fees), 'formattedDateString');

    const formattedData = {
      chartDataPoints: [],
      daysOfMonthData: [],
      totals: {
        timeLoggedMinutes: 0,
        amountBillableCents: 0,
      },
    };

    const nbDaysInMonth = selectedMonth.daysInMonth();
    const dayOfMonth = moment(selectedMonth);

    for (let i = 0; i < nbDaysInMonth; i += 1) {
      if (feeDaySummaries[0] && feeDaySummaries[0].formattedDateString === dayOfMonth.format('YYYYMMDD')) {
        // This data is used to populate the bar chart.
        const { totalCents, totalMinutes } = feeDaySummaries.shift();
        formattedData.chartDataPoints.push({ index: i, amountInCents: totalCents, timeInMinutes: totalMinutes });

        // This data is used to populate the aggregate tiles to the right of the charts.
        formattedData.totals.amountBillableCents += totalCents;
        formattedData.totals.timeLoggedMinutes += totalMinutes;
      } else {
        // This data is used to populate the bar chart.
        formattedData.chartDataPoints.push({ index: i, amountInCents: 0, timeInMinutes: 0 });
      }

      dayOfMonth.add(1, 'day');
    }

    return formattedData;
  }, [fees, selectedMonth]);

  // This data is used to populate the day tiles below the chart.
  const dayOfMonth = moment(selectedMonth);
  const nbDaysInMonth = selectedMonth.daysInMonth();
  const daysOfMonthData = [];

  for (let i = 0; i < nbDaysInMonth; i += 1) {
    daysOfMonthData.push({
      dayInitial: dayOfMonth.format('dd').at(0),
      dayNumber: i + 1,
      isCurrentDay: dayOfMonth.isSame(currentDate),
      isSelectedDay: dayOfMonth.isSame(selectedDay),
    });

    dayOfMonth.add(1, 'day');
  }

  const componentProps = {
    isWidget,
    chartTitle: `${selectedMonth.format('MMMM')} Activity`,
    currentMonthLabel: `${selectedMonth.format('MMMM YYYY')}`,
    timeLoggedLabel: `${(formattedChartData.totals.timeLoggedMinutes / 60).toFixed(2)} hours`,
    billableAmountLabel: `${t('cents', { val: formattedChartData.totals.amountBillableCents })}`,
    selectedDisplayMode,
    chartData: formattedChartData.chartDataPoints,
    daysOfMonthData,
    collapsed,
    onToggleCollapse: () => setCollapsed(!collapsed),
    onDisplayModeChange: ({ displayMode }) => setSelectedDisplayMode(displayMode),
    onPreviousMonth: () => onMonthChange(selectedMonth.clone().subtract(1, 'month')),
    onCurrentMonth: () => {
      if (!currentMonth.isSame(selectedMonth)) {
        onMonthChange(currentMonth);
      }
    },
    onNextMonth: () => onMonthChange(selectedMonth.clone().add(1, 'month')),
    onDayChange: ({ dayNumber }) => onDayChange(selectedMonth.clone().add(dayNumber - 1, 'day')),
  };

  return <FeeSummaryChart {...componentProps} />;
};

FeeSummaryChartContainer.displayName = 'FeeSummaryChartContainer';

FeeSummaryChartContainer.propTypes = {
  isWidget: PropTypes.bool,
  selectedDayYYYYMMDD: PropTypes.string.isRequired,
  onDayChange: PropTypes.func.isRequired,
  selectedMonthYYYYMM: PropTypes.string.isRequired,
  onMonthChange: PropTypes.func.isRequired, // Gets passed a moment date object
  dataLoading: PropTypes.bool,
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      // An apollo Fee entity subset.
      feeDate: PropTypes.number.isRequired, // YYYYMMDD
      feeType: PropTypes.oneOf(Object.values(feeEntryTypes)).isRequired,
      isBillable: PropTypes.oneOf([true, false, null]),
      rate: PropTypes.number.isRequired,
      tax: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
      billableDuration: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

FeeSummaryChartContainer.defaultProps = {
  dataLoading: false,
  isWidget: false,
};
