import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DatePicker, Spinner, StatelessSelect, FormLabel } from '@sb-itops/react';
import { dateToInteger, integerToDate } from '@sb-itops/date';

import Styles from './MarkAsSentForm.module.scss';

export const MarkAsSentForm = React.memo(
  ({
    isLoading,
    dateSent,
    sentVia,
    internalNote,
    externalSentViaOptions,
    isSendViaNotMatchMatterPreference,
    onFieldValueUpdated,
  }) => {
    if (isLoading) {
      return (
        <div className={Styles.markAsSentForm}>
          <Spinner height={500} message="Loading" />
        </div>
      );
    }
    return (
      <div className={Styles.markAsSentForm}>
        <fieldset>
          <div className="row">
            <div className={classnames('form-group', 'col-sm-6', dateSent.isInvalid && 'has-error')}>
              <FormLabel label="Date Sent" field={dateSent} />
              <DatePicker
                onSelect={(newDate) => onFieldValueUpdated('dateSent', newDate ? dateToInteger(newDate) : undefined)}
                format="DD/MM/YYYY"
                value={dateSent?.value && integerToDate(dateSent.value)}
                hasError={dateSent.isInvalid}
              />
            </div>
          </div>

          <div className="row">
            <div className={classnames('form-group', 'col-sm-6', sentVia.isInvalid && 'has-error')}>
              <FormLabel label="Sent Via" field={sentVia} />
              <StatelessSelect
                options={externalSentViaOptions}
                className={sentVia.isInvalid && sentVia.isDirty ? Styles.hasError : ''}
                selectedOption={sentVia.value}
                onChange={(option) => {
                  onFieldValueUpdated('sentVia', option?.value);
                }}
                isClearable={false}
                placeholder="Select..."
              />
              {isSendViaNotMatchMatterPreference && (
                <div className={Styles.sentViaWarning}>
                  <i className="icon-information" color="lightgrey" />
                  <span className={Styles.sentViaWarningText}>
                    Send method selected is different to the matter preferences
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-12">
              <FormLabel label="Internal Notes" field={internalNote} optional />
              <textarea
                className={classnames('form-control', internalNote.isInvalid ? Styles.hasError : '')}
                rows="5"
                value={internalNote?.value || ''}
                onChange={(e) => onFieldValueUpdated('internalNote', e.target.value)}
              />
            </div>
          </div>
        </fieldset>
      </div>
    );
  },
);

MarkAsSentForm.displayName = 'MarkAsSentForm';

MarkAsSentForm.propTypes = {
  isLoading: PropTypes.bool,
  dateSent: PropTypes.object,
  sentVia: PropTypes.object,
  internalNote: PropTypes.object,
  isSendViaNotMatchMatterPreference: PropTypes.bool,
  externalSentViaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onFieldValueUpdated: PropTypes.func,
};

MarkAsSentForm.defaultProps = {
  isLoading: false,
  dateSent: undefined,
  sentVia: undefined,
  internalNote: undefined,
  isSendViaNotMatchMatterPreference: false,
  onFieldValueUpdated: () => {},
};

export default MarkAsSentForm;
