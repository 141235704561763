import { gql } from '@apollo/client';
import { contactTableFragment } from '../fragments/contactTableFragment.graphql';

const query = gql`
  query SbClientSdkContactData($contactId: ID!) {
    contact(id: $contactId) {
      ...contactTableFragment
      firstName
      lastName
      lastUpdated

      residentialAddress {
        city
        state
        zipCode
        country
      }
      businessAddress {
        city
        state
        zipCode
        country
      }
      mailingAddress {
        city
        state
        zipCode
        country
      }
    }
  }

  ${contactTableFragment}
`;

const notificationIds = ['CustomerManagementWebQuery.EntityUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SbClientSdkContactData = {
  query,
  notificationIds,
};
