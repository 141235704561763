import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingBarInfinite, Modal, Spinner, StatelessSelect } from '@sb-itops/react';
import classNames from 'classnames';
import { MatterTypeSelect } from '../matter-type-select';

import Styles from './ConvertLeadModal.module.scss';

export const ConvertLeadModal = ({
  onSubmit,
  locationId,
  categoryId,
  matterTypeId,
  setCategoryId,
  setLocationId,
  setMatterTypeId,
  clientRoleId,
  setClientRoleId,
  clientRoleOptions,
  loading,
  saving,
  onClose,
}) => (
  <Modal
    className={Styles.overflowOverride}
    modalBodyClassName={Styles.overflowOverride}
    title="Convert Lead to Matter"
    body={
      loading ? (
        <div className={Styles.loading}>
          <LoadingBarInfinite loading={loading} />
          <Spinner small />
        </div>
      ) : (
        <div className={Styles.container}>
          <div className="col-lg-12 form-group">
            <label>Matter Type *</label>
            <MatterTypeSelect
              locationId={locationId}
              matterTypeCategory={categoryId}
              matterTypeId={matterTypeId}
              onLocationUpdated={(value) => {
                setLocationId(value);
                setMatterTypeId('');
                setClientRoleId();
              }}
              onCategoryUpdated={(value) => {
                setCategoryId(value);
                setMatterTypeId('');
                setClientRoleId();
              }}
              onMatterTypeUpdated={(option) => {
                setMatterTypeId(option?.value);
                setClientRoleId();
              }}
              isRequired
            />
          </div>
          <div className="col-lg-12 form-group">
            <label>Client Role *</label>
            <div>
              <StatelessSelect
                options={clientRoleOptions}
                disabled={!matterTypeId}
                selectedOption={clientRoleId}
                onChange={(newClientRole) => setClientRoleId(newClientRole.value)}
              />
            </div>
          </div>
          <div className="col-lg-12 form-group">
            <i className={classNames(Styles.warning, 'fa', 'fa-warning')} /> Converting a lead to a matter is not
            reversible
          </div>
        </div>
      )
    }
    footer={
      <div>
        <Button locked={saving} disabled={!matterTypeId || !clientRoleId} onClick={onSubmit}>
          CONVERT TO MATTER
        </Button>
      </div>
    }
    onModalClose={onClose}
  />
);

ConvertLeadModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clientRoleOptions: PropTypes.array,
  clientRoleId: PropTypes.string,
  locationId: PropTypes.string,
  categoryId: PropTypes.string,
  matterTypeId: PropTypes.string,
  setClientRoleId: PropTypes.func.isRequired,
  setCategoryId: PropTypes.func.isRequired,
  setLocationId: PropTypes.func.isRequired,
  setMatterTypeId: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ConvertLeadModal.defaultProps = {
  clientRoleOptions: [],
  clientRoleId: undefined,
  locationId: undefined,
  categoryId: undefined,
  matterTypeId: undefined,
  loading: undefined,
  saving: undefined,
};

ConvertLeadModal.displayName = 'ConvertLeadModal';
