import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation, LinkableText } from '@sb-itops/react';
import { ContactDisplay2 } from '@sb-customer-management/react';
import Styles from './InvoiceStatementSummaryPanel.module.scss';

export const InvoiceStatementSummaryPanel = memo(
  ({
    debtor,
    invoices,
    interest,
    totalExcInterest,
    waived,
    paidByCredit,
    paid,
    unpaid,
    isVoid,
    voidOnDate,
    // config
    showInterest,
    showTrustBalance,
    // function
    onClickLink,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={Styles.invoiceStatementSummaryPanel}>
        <div className={classnames('row', Styles.row, Styles.summaryHeading)}>
          <ContactDisplay2 contact={debtor} onClickLink={onClickLink} />
        </div>

        {isVoid && (
          <div className={classnames('row', Styles.row, Styles.summaryVoidOn)}>
            <div className={classnames('col-sm-6', Styles.summaryLabel)}>VOID ON</div>
            <div className={classnames('col-sm-6', Styles.summaryValue)}>
              <span>{t('date', { ts: voidOnDate })}</span>
            </div>
          </div>
        )}

        <div className={classnames('row', Styles.row, Styles.summaryInvoices)}>
          <div className={classnames('col-sm-12', Styles.summaryLabel)}>INVOICES INCLUDED</div>
          <div className={classnames('col-sm-12', Styles.summaryInvoiceLink)}>
            {invoices.map((invoice) => (
              <LinkableText
                key={invoice.id}
                text={`Invoice #${invoice.invoiceNumber || 'unknown'}`}
                asLink
                onClickLink={() => onClickLink({ type: 'invoice', id: invoice.id })}
              />
            ))}
          </div>
        </div>

        {!isVoid && (
          <div>
            {showInterest && (
              <div className={classnames('row', Styles.row, Styles.summaryInterest)}>
                <div className={classnames('col-sm-6', Styles.summaryLabel)}>Interest</div>
                <div className={classnames('col-sm-6', Styles.summaryValue)}>
                  <span>{t('cents', { val: interest })}</span>
                </div>
              </div>
            )}

            <div className={classnames('row', Styles.row, Styles.summaryTotal)}>
              <div className={classnames('col-sm-6', Styles.summaryLabel)}>TOTAL</div>
              <div className={classnames('col-sm-6', Styles.summaryValue)}>
                <span>{t('cents', { val: totalExcInterest })}</span>
              </div>
            </div>

            <div className={classnames('row', Styles.row, Styles.summaryPayments)}>
              <div className={classnames('col-sm-6', Styles.summaryLabel)}>Payments</div>
              <div className={classnames('col-sm-6', Styles.summaryValue)}>
                <span>{t('cents', { val: paid })}</span>
              </div>
            </div>

            <div className={classnames('row', Styles.row, Styles.summaryPayments)}>
              <div className={classnames('col-sm-6', Styles.summaryLabel)}>Credits</div>
              <div className={classnames('col-sm-6', Styles.summaryValue)}>
                <span>{t('cents', { val: paidByCredit })}</span>
              </div>
            </div>

            <div className={classnames('row', Styles.row, Styles.summaryWaived)}>
              <div className={classnames('col-sm-6', Styles.summaryLabel)}>Waived</div>
              <div className={classnames('col-sm-6', Styles.summaryValue)}>
                <span>{t('cents', { val: waived })}</span>
              </div>
            </div>

            <div className={classnames('row', Styles.row, Styles.summaryTotalDue)}>
              <div className={classnames('col-sm-6', Styles.summaryLabel)}>TOTAL DUE</div>
              <div className={classnames('col-sm-6', Styles.summaryValue)}>
                <span>{t('cents', { val: unpaid })}</span>
              </div>
            </div>
          </div>
        )}

        {debtor?.trustBalance && (showTrustBalance || debtor.trustBalance !== 0) && (
          <div className={classnames('row', Styles.row, Styles.summaryBalance)}>
            <div className={classnames('col-sm-6', Styles.summaryLabel)}>{t('trust')}</div>
            <div className={classnames('col-sm-6', Styles.summaryValue)}>
              {t('cents', { val: debtor.trustBalance })}
            </div>
          </div>
        )}
      </div>
    );
  },
);

InvoiceStatementSummaryPanel.displayName = 'InvoiceStatementSummaryPanel';
InvoiceStatementSummaryPanel.propTypes = {
  debtor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    displayNameFirstLast: PropTypes.string,
    trustBalance: PropTypes.number,
  }),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.number.isRequired,
    }),
  ),
  interest: PropTypes.number,
  totalExcInterest: PropTypes.number,
  waived: PropTypes.number,
  paidByCredit: PropTypes.number,
  paid: PropTypes.number,
  unpaid: PropTypes.number,
  isVoid: PropTypes.bool,
  voidOnDate: PropTypes.string,
  // config
  showInterest: PropTypes.bool,
  showTrustBalance: PropTypes.bool,
  // function
  onClickLink: PropTypes.func.isRequired,
};

InvoiceStatementSummaryPanel.defaultProps = {
  debtor: undefined,
  invoices: [],
  interest: 0,
  totalExcInterest: 0,
  waived: 0,
  paidByCredit: 0,
  paid: 0,
  unpaid: 0,
  isVoid: false,
  voidOnDate: '',
  // config
  showInterest: false,
  showTrustBalance: false,
};
