import { gql } from '@apollo/client';

const query = gql`
  query InitFirmTaxSettings {
    firmTaxSettings {
      registeredForGst
      taxRate
    }
  }
`;

const notificationIds = ['TaxesNotifications.TaxSettingsUpdated'];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitFirmTaxSettings = {
  query,
  notificationIds,
};
