import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { t } from '@sb-itops/localisation-web';
import { Table, Column, utils } from '@sb-itops/react/table';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { ALL_STATES } from '@sb-itops/region';
import { sortByProperty } from '@sb-itops/nodash';

import Styles from './TrustAccountsOverviewTable.module.scss';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

const cellRenderer = ({ cellData }) => {
  if (!cellData) {
    return null;
  }
  return cellData;
};

const statusCellRenderer = ({ cellData }) => (cellData === BANK_ACCOUNT_STATE.OPEN ? 'Open' : 'Closed');
const locationCellRenderer = ({ cellData }) => (cellData === ALL_STATES.value ? ALL_STATES.label : cellData);

export const TrustAccountsOverviewTable = memo(
  ({ trustAccounts, sortBy, sortDirection, onSort, onClickRow, displayColumns, supportsBsbAccountNumber }) => {
    const onRowClickHandler = (row) => {
      const trustAccountId = row.rowData.id;
      onClickRow(trustAccountId);
    };

    let sortedTrustAccounts = trustAccounts;

    if (sortBy && sortDirection) {
      sortedTrustAccounts = sortByProperty(trustAccounts, sortBy, sortDirection, false);
    }

    return (
      <Table
        list={sortedTrustAccounts}
        onRowClick={onRowClickHandler}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sort={onSort}
      >
        {displayColumns.accountName && (
          <Column
            className="capitalize"
            dataKey="accountName"
            label="Account Name"
            flexGrow={1}
            cellRenderer={cellRenderer}
          />
        )}
        {displayColumns.displayName && (
          <Column
            className="capitalize"
            dataKey="displayName"
            label="Display Name"
            flexGrow={1}
            cellRenderer={cellRenderer}
          />
        )}
        {displayColumns.name && (
          <Column className="capitalize" dataKey="name" label="Name" flexGrow={1} cellRenderer={cellRenderer} />
        )}
        {displayColumns.bankName && (
          <Column
            className="capitalize"
            dataKey="bankName"
            label="Bank Name"
            flexGrow={1}
            cellRenderer={cellRenderer}
          />
        )}
        {displayColumns.branch && <Column dataKey="branch" label="Branch" flexGrow={0.6} cellRenderer={cellRenderer} />}
        {displayColumns.bsb && supportsBsbAccountNumber && (
          <Column dataKey="bsb" label={t('bankBranchNumber')} flexGrow={0.6} cellRenderer={cellRenderer} />
        )}
        {displayColumns.accountNumber && supportsBsbAccountNumber && (
          <Column dataKey="accountNumber" label="Account Number" cellRenderer={cellRenderer} flexGrow={1} />
        )}
        {displayColumns.location && (
          <Column
            dataKey="location"
            label={t('capitalize', { val: 'addressState' })}
            cellRenderer={locationCellRenderer}
            flexGrow={0.6}
          />
        )}
        {displayColumns.status && (
          <Column dataKey="status" label="Status" cellRenderer={statusCellRenderer} flexGrow={0.6} />
        )}
        {displayColumns.balance && (
          <Column
            dataKey="balance"
            className={Styles.numericColumn}
            label="Balance"
            cellRenderer={balanceCellLocalisedRenderer}
            flexGrow={0.6}
            disableSort
          />
        )}

        {displayColumns.lastReconciled && (
          <Column
            dataKey="lastReconciled"
            label="Last Reconciled"
            cellRenderer={yyyymmddLocalisedRenderer}
            flexGrow={0.6}
          />
        )}
        {displayColumns.lastRun && (
          <Column dataKey="lastRun" label="Last Run" cellRenderer={yyyymmddLocalisedRenderer} flexGrow={0.6} />
        )}
      </Table>
    );
  },
);

TrustAccountsOverviewTable.propTypes = {
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      displayName: PropTypes.string,
      bankName: PropTypes.string,
      branch: PropTypes.string,
      bsb: PropTypes.string,
      accountNumber: PropTypes.string,
      status: PropTypes.number,
      balance: PropTypes.number,
    }),
  ).isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func,
  onClickRow: PropTypes.func.isRequired,
  displayColumns: PropTypes.object,
  supportsBsbAccountNumber: PropTypes.bool.isRequired,
};

TrustAccountsOverviewTable.defaultProps = {
  sortBy: 'accountName',
  sortDirection: 'asc',
  onSort: () => {},
  displayColumns: {
    accountName: true,
    displayName: true,
    bankName: true,
    branch: true,
    bsb: true,
    accountNumber: true,
    status: true,
    balance: true,
  },
};
