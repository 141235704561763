'use strict';

const { sendStatuses } = require('./send-statuses');

/**
 * This function figures out the overall status for the send icon
 * based on each of the individual statuses for each debtor.
 * @param {array} debtorsCorrespondenceHistory only the latest CorrespondenceHistory for each debtor
 * @returns
 */
function determineSendStatus(debtorsCorrespondenceHistory) {
  if (debtorsCorrespondenceHistory.length === 0) {
    return sendStatuses.NOT_SENT;
  }

  let overallStatus;
  let debtorIndex = 0;

  while (debtorIndex < debtorsCorrespondenceHistory.length && overallStatus !== sendStatuses.ERROR) {
    const { status } = debtorsCorrespondenceHistory[debtorIndex];

    switch (status) {
      case sendStatuses.ERROR:
        // If any of the statuses are an error, the overall is in error.
        // This will break the loop.
        overallStatus = sendStatuses.ERROR;
        break;

      case sendStatuses.IN_PROGRESS:
        // If any of the statuses are in progress, the overall status is also in progress.
        // We cannot break the loop based on hitting this overall status, as an error status
        // might follow.
        overallStatus = sendStatuses.IN_PROGRESS;
        break;

      case sendStatuses.NOT_SENT:
        // If all of the statuses are not sent, then we treat the overall status as not sent.
        if (!overallStatus) {
          overallStatus = sendStatuses.NOT_SENT;
        }
        break;

      case sendStatuses.SUCCESS:
        // Other statuses overwrite success, so it's safe to set success as the overall
        // status so long as no status has been set yet.
        // sendStatuses.IN_PROGRESS is 0, !0 is true so we need to add the check for in progress
        if (!overallStatus && overallStatus !== sendStatuses.IN_PROGRESS) {
          overallStatus = sendStatuses.SUCCESS;
        }
        break;

      default:
        throw new Error(`Unexpected email status ${status}`);
    }

    debtorIndex += 1;
  }

  return overallStatus;
}

module.exports = {
  determineSendStatus,
};
