import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { PrintNotApplicable, PrintLater } from '@sb-billing/business-logic/cheques';
import {
  getFilteredInvoices,
  getShownMatterTypeIds,
  getSelectedInvoiceIds,
  getState,
  changeFilter,
  resetFilters,
  setVisibility,
  sortRows,
  setExpand,
  setExpandAll,
  changeSelected,
  changeSelectedMany,
  changeSelectedAll,
  setInitialised,
  setChequePrintingMethod,
  setPaymentAmount,
  changeAutoAllocate,
  changeAutoAllocateAll,
  getPayments,
  toggleShowFilters,
  getShowFilters,
} from 'web/redux/route/billing-bills-finalise-with-payments';
import { sortByProperty } from '@sb-itops/nodash';
import {
  getActiveTrustAccounts,
  getTrustAccount,
  getOperatingAccount,
  getDefaultCreditAccount,
} from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import BillingBillsFinaliseWithPayments from './BillingBillsFinaliseWithPayments';

const mapStateToProps = (state, { t }) => {
  const routeState = getState(state);
  const invoices = getFilteredInvoices(state);
  const panelExpanded = getShowFilters(state);

  const activeTrustAccounts = sortByProperty(
    getActiveTrustAccounts().map((ta) => ({ value: ta.id, label: getBankAccountName(ta, t) })),
    'label',
    'asc',
    false,
  );

  const bankAccountIds = {
    CREDIT: getDefaultCreditAccount()?.id,
    // We default to legacy trust account because we don't have trustBankAccountId only when there is no active trust account.
    // This means the legacy trust account is closed as well but we don't mind as the balance for closed account is 0
    TRUST: routeState.filters.trustBankAccountId || getTrustAccount()?.id,
    OPERATING: getOperatingAccount()?.id,
  };

  const trustChequePrintSettings = getTrustChequePrintSettingsById(bankAccountIds.TRUST);

  return {
    panelExpanded,
    invoices,
    matterTypes: getShownMatterTypeIds(state),
    selectedMatterTypes: [...new Set(routeState.filters.matterTypes)],
    filters: routeState.filters,
    visible: routeState.visible,
    sortBy: routeState.sort.sortBy,
    sortDirection: routeState.sort.sortDirection,
    expanded: routeState.expanded,
    selected: routeState.selected,
    selectedInvoiceIds: getSelectedInvoiceIds(state),
    initialised: routeState.initialised,
    chequePrintingMethod: getChequePrintingMethod(routeState.chequePrintingMethod, trustChequePrintSettings),
    payments: getPayments(state),
    trustChequesEnabled: !!trustChequePrintSettings && trustChequePrintSettings.printingActive,
    autoAllocations: routeState.autoAllocations,
    trustAccounts: activeTrustAccounts,
    bankAccountIds,
  };
};

function getChequePrintingMethod(chequePrintingMethod, trustChequePrintSettings) {
  // if the user hasn't saved any trust cheque print settings, then its not enabled
  if (!trustChequePrintSettings) {
    return null;
  }

  // if the user has selected the print settings manually, use that
  if (chequePrintingMethod) {
    return chequePrintingMethod;
  }

  // otherwise use the global settings
  const printMethod = trustChequePrintSettings.printMethod;

  if (printMethod !== PrintLater) {
    return PrintNotApplicable;
  }

  return PrintLater;
}

const mapDispatchToProps = (dispatch) => ({
  onFilterTrustAccount: (trustAccount) => dispatch(changeFilter('trustBankAccountId', trustAccount.value)),
  onFilterMatterStatus: (matterStatus) => dispatch(changeFilter('matterStatuses', matterStatus)),
  onFilterBillingType: (billingTypes, allSelected) =>
    dispatch(
      changeFilter('billingTypes', {
        selections: billingTypes,
        allSelected,
      }),
    ),
  toggleExpanded: () => dispatch(toggleShowFilters()),
  onResetFilters: () => dispatch(resetFilters()),
  onFilterOperatingBalance: (e) => dispatch(changeFilter('minimumOperatingBalance', e.target.value)),
  onFilterTrustBalance: (e) => dispatch(changeFilter('minimumTrustBalance', e.target.value)),
  onFilterCreditBalance: (e) => dispatch(changeFilter('minimumCreditBalance', e.target.value)),
  onFilterMatterType: (matterTypes) => {
    dispatch(changeFilter('matterTypes', matterTypes));
  },
  onFilterIssueDate: (selectedFilter) => dispatch(changeFilter('issueDate', selectedFilter)),
  onStaffChange: (attorneyResponsible) => dispatch(changeFilter('attorneysResponsible', attorneyResponsible)),
  onVisibilityChange: (filterName, visible) => dispatch(setVisibility(filterName, visible)),
  onChangeChequePrintingMethod: (e) => dispatch(setChequePrintingMethod(e.target.value)),
  sort: ({ sortBy, sortDirection }) => dispatch(sortRows({ sortBy, sortDirection })),
  onToggleMatterExpanded: (matterId, expanded) => dispatch(setExpand(matterId, expanded)),
  onToggleAllMattersExpanded: (matterIds, expanded) => dispatch(setExpandAll(matterIds, expanded)),
  onToggleInvoiceSelected: (invoiceId, selected) => dispatch(changeSelected(invoiceId, selected)),
  onToggleMatterSelected: (invoiceIds, selected) => dispatch(changeSelectedMany(invoiceIds, selected)),
  onToggleSelectAll: (invoiceIds, selected) => dispatch(changeSelectedAll(invoiceIds, selected)),
  setInitialised: (initialised) => dispatch(setInitialised(initialised)),
  onToggleAutoAllocate: (matterId, autoAllocate) => dispatch(changeAutoAllocate(matterId, autoAllocate)),
  onToggleAutoAllocateAll: (matterIds, autoAllocate) => dispatch(changeAutoAllocateAll(matterIds, autoAllocate)),
  onChangePaymentAmount: (bankAccountId, invoiceId, amount) =>
    dispatch(setPaymentAmount(bankAccountId, invoiceId, amount)),
});

const BillingBillsFinaliseWithPaymentsComponent = ({ onFinalise, ...props }) => {
  const { bankAccountIds } = props;
  const onFinaliseWithBankAccountIds = ({ selectedInvoiceIds, matterPaymentGroups, chequePrintingMethod }) => {
    onFinalise({ selectedInvoiceIds, matterPaymentGroups, chequePrintingMethod, bankAccountIds });
  };

  return <BillingBillsFinaliseWithPayments {...props} onFinalise={onFinaliseWithBankAccountIds} />;
};

const BillingBillsFinaliseWithPaymentsContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BillingBillsFinaliseWithPaymentsComponent)),
);

BillingBillsFinaliseWithPaymentsContainer.displayName = 'BillingBillsFinaliseWithPaymentsContainer';

BillingBillsFinaliseWithPaymentsContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onFinalise: PropTypes.func.isRequired,
};
BillingBillsFinaliseWithPaymentsContainer.defaultProps = {};

export default BillingBillsFinaliseWithPaymentsContainer;
