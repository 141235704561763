import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { shared as sharedBl } from '@sb-billing/business-logic';
import { calculateInvoiceTotals as calcInvoiceTotals } from '@sb-billing/business-logic/invoice-totals';
import { featureActive } from '@sb-itops/feature';

import { getTotalsForInvoiceId as getTotalsForInvoiceIdSelector } from './selectors';

import domain from '../domain';

const { EXPENSE } = sharedBl.entities.entryType;

const api = cacheFactory({
  domain,
  name: 'invoice-totals',
  keyPath: 'invoiceId',
  ngCacheName: 'sbInvoiceTotalsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

// this is DEPRECATED use getTotalsForInvoiceId from selectors instead
export const getTotalsForInvoiceId = (id) => getTotalsForInvoiceIdSelector(getMap(), { invoiceId: id });

// this code has come from angular - TODO (significant) extra work is required to unify the usages of `getTotalsCents` and `getTotalsForInvoiceId`.
export const getTotalsCents = (invoiceId) => {
  let invoiceTotals;
  try {
    invoiceTotals = getTotalsForInvoiceId(invoiceId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(
      'getTotalsCents: (this often errors out due to a race condition between syncing of sbInvoicingService and sbInvoiceTotalsService)',
      err,
    );
  }
  const finalInvoiceTotals = getTotals(invoiceTotals);
  return finalInvoiceTotals;
};

function getTotals(invoiceTotals) {
  if (invoiceTotals) {
    const interest = invoiceTotals.interest || 0;
    const total = invoiceTotals.total || 0;
    return {
      billed: invoiceTotals.billed || 0,
      unpaid: invoiceTotals.unpaid || 0,
      unpaidExcInterest: invoiceTotals.unpaidExcInterest || 0,
      paid: invoiceTotals.paid || 0,
      paidByCredit: invoiceTotals.paidByCredit || 0,
      waived: invoiceTotals.waived || 0,
      total,
      interest,
      totalExcInterest: total - interest,
    };
  }

  return {
    billed: 0,
    unpaid: 0,
    unpaidExcInterest: 0,
    paid: 0,
    paidByCredit: 0,
    waived: 0,
    total: 0,
    interest: 0,
    totalExcInterest: 0,
  };
}

export const calculateInvoiceTotals = (invoice) => {
  const fees = invoice.currentVersion.entries.filter((entry) => entry.type !== EXPENSE).map((fee) => fee.feeEntity);
  const expenses = invoice.currentVersion.entries
    .filter((entry) => entry.type === EXPENSE)
    .map((expense) => expense.expenseEntity);
  const discount = invoice.currentVersion.discount;
  const surcharge = invoice.currentVersion.surcharge;
  return calcInvoiceTotals({
    fees,
    expenses,
    discount,
    surcharge,
    surchargeEnabled: featureActive('BB-7270'),
    taxRate: invoice.currentVersion.feeTaxRate,
  });
};

export * from './invoice-totals-opdates';
