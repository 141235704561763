angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.firm-details', {
      template: '<sbb-firm-details></sbb-firm-details>',
      url: '/firm-details',
      data: {
        navHighlights: ['firm-details']
      }
    });
});