import { getAccountId, getUserId, getProductTier } from 'web/services/user-session-management';
import { productTierLabelByTierId } from '@sb-finance/business-logic/subscription/entities/constants';

angular.module('sb.billing.webapp').component('sbDataUserDetails', {
    require: {sbComposeCtrl: '^sbCompose'},
    bindings: {composeKey: '@'},
    controller: function (sbLoggerService) {
      'use strict';

      const that = this;
      const log = sbLoggerService.getLogger('sbDataUserDetails');

      that.$onInit = function () {
        if (_.isEmpty(that.composeKey)) {
          throw new Error('Cannot create sbDataUserDetails with invalid compose key');
        }

        log.info('composeKey', that.composeKey);
        updateData();
      };

      const productTier = getProductTier();

      function updateData(){
        const userData = {
          userId: getUserId(),
          accountId: getAccountId(),
          productTier,
          productTierLabel: productTierLabelByTierId[productTier]
        };

        that.sbComposeCtrl.setComposeData(userData, that.composeKey);
      }
    }
  }
);
