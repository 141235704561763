import { utilsFactory, createSbPathReducer, createSbPathSelector } from '@sb-itops/redux';
import { reducer } from './reducer';
import * as ActionCreators from './action-creators';

const reduxPath = `billing/support/rest-logs`;

const { registerReducer } = utilsFactory({ reduxPath });
registerReducer(createSbPathReducer({ reduxPath, reducer }));

// export selectors
export const getState = createSbPathSelector({ reduxPath });
export const getLogItems = () => getState().logItems;
export const getIsCollectingLogs = () => getState().isCollectingLogs;

export const Actions = ActionCreators;
