import {
  getList,
  updateCache as updateRedux,
} from '@sb-billing/redux/gst-tax-settings';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('@sb-billing/services').service('sbGstTaxSettingsService', function(
    sbGenericEndpointService,
    sbGenericCacheService,
    sbEndpointType,
  ) {
    const that = this;
    const endpoint = 'billing/gst-tax-settings';
    const subscriptions = 'notifier.TaxesNotifications.TaxSettingsUpdated';

    const supportsTax = hasFacet(facets.tax)
    if (supportsTax) {
      sbGenericCacheService.setupCache({
        name: 'sbGstTaxSettingsService',
        sync: {
          endpoint: { type: sbEndpointType.SYNC_ALL, stub: endpoint },
          poll: 60,
          subscriptions
        },
        updateRedux
      });
    }

    const getTaxSettings = () => {
      if (getList().length === 0) {
        return null;
      }

      const results = getList();
      return results[results.length - 1] || null;
    };

    const getTaxRate = () => {
      const taxSettings = getTaxSettings();
      return _.get(taxSettings, 'taxRate', 0);
    };

    const saveP = data =>
      sbGenericEndpointService.postPayloadP(endpoint, null, data, 'POST', {
        changeset: { sbGstTaxSettingsService: [data] }
      });

    // ---------------------------------------------------------------------------
    that.getTaxSettings = getTaxSettings;
    that.getTaxRate = getTaxRate;
    that.saveP = saveP;
  });
