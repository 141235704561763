import React from 'react';
import PropTypes from 'prop-types';

import { formatPaymentPlanDescriptionGraphql } from '@sb-billing/business-logic/payment-plan/services';
import { todayAsInteger } from '@sb-itops/date';
import { Icon, useTranslation } from '@sb-itops/react';

import Styles from './PaymentPlanIndicator.module.scss';

// Payment plan position status indicators
// Indicator keys were copied across from previous graphql implementation
// and we can't really change it now as these are baked into the graphql API
const indicatorsColors = {
  Behind: '#dd4442',
  Ahead: '#408be5',
  'On Track': '#408be5',
};

// Payment method status indicators for when auto charge is used
const paymentMethodStatusIndicatorColors = {
  expired: '#dd4442',
  failed: '#dd4442',
  expiryWarning: '#feb566',
};

const paymentMethodStatusIcons = {
  expired: 'icon-fat-exclamation-circle-filled',
  failed: 'icon-fat-exclamation-circle-filled',
  expiryWarning: 'warning', // FA icon
};

// Possible Improvement: modify the PaymentPlanIndicator to be contextual to where it's used
// only do this if this doesn't degrade DB query performance
// 1) debtor - as per current behaviour
// 2) invoice/matter - e.g. the debtor may have a payment plan but not for the invoice/matter concerned
//    in this case, it would be ideal to show a greyed out icon with a tooltip along the line of
//    "This debtor's payment plan does not include this matter/invoice."
export const PaymentPlanIndicator = ({ paymentPlan, tooltipMessage, onClickLink, showWarnings }) => {
  const { t } = useTranslation();
  const status = paymentPlan.paymentPlanPosition?.status;
  const defaultTooltipMessage = formatPaymentPlanDescriptionGraphql({
    paymentPlan,
    todayAsInteger: todayAsInteger(),
    t,
  });
  const finalTooltipMessage = tooltipMessage || defaultTooltipMessage;
  const autoCharge = paymentPlan.autoCharge;
  const paymentMethodStatus = paymentPlan.paymentPlanPosition?.paymentMethod?.status;

  const indicatorColor = indicatorsColors[status];
  const paymentMethodStatusIndicatorColor = paymentMethodStatusIndicatorColors[paymentMethodStatus];
  const paymentMethodStatusIcon = paymentMethodStatusIcons[paymentMethodStatus];

  const onClickPaymentPlan = () => onClickLink({ type: 'paymentPlan', id: paymentPlan.debtorId });
  return (
    <>
      <Icon
        className={Styles.icon}
        type={autoCharge ? 'calplan' : 'cal-currency'}
        tooltip={finalTooltipMessage}
        color={indicatorColor}
        onClick={onClickPaymentPlan}
      />
      {showWarnings && paymentMethodStatusIcon && (
        <Icon
          isFA={paymentMethodStatusIcon === 'warning'}
          type={paymentMethodStatusIcon}
          tooltip={paymentPlan.paymentPlanPosition?.paymentMethod?.statusDisplay}
          color={paymentMethodStatusIndicatorColor}
          onClick={onClickPaymentPlan}
        />
      )}
    </>
  );
};

PaymentPlanIndicator.displayName = 'PaymentPlanIndicator';

PaymentPlanIndicator.propTypes = {
  paymentPlan: PropTypes.object.isRequired,
  tooltipMessage: PropTypes.string,
  showWarnings: PropTypes.bool,
  onClickLink: PropTypes.func.isRequired,
};

PaymentPlanIndicator.defaultProps = {
  tooltipMessage: undefined,
  showWarnings: true,
};
