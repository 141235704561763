angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/contacts';

  $stateProvider
    .state('home.billing.contacts', {
      url: '/contacts?cr&sel',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/contacts.html',
      controller: 'ContactsController',
      controllerAs: 'ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts']
      },
      onEnter: function($timeout, $state) {
        $timeout(function() {
          // In case any legacy links point to the default contact page,
          // redirect them to the new list route
          if ($state.current.name === "home.billing.contacts") {
            $state.go('home.billing.contacts.list');
          }
        });
      }
    });

});
