'use strict';

/**
 * @param {Object} params
 * @param {number|string} params.duration
 *  number: Duration is the number of hours, e.g. 1.5 hours
 *  string: Duration is a string in the format: , e.g. #:# (hours:minutes), #h (hours), #m (minutes), #u (intervals), where # is a number
 * @param {number|undefined} params.interval the number of minutes billable as a unit, for use with the #u duration format
 * @param {boolean|undefined} params.withRounding if the rounding should be applied
 *
 * @returns {number|undefined} the number of minutes represented by the duration, or undefined if it cant be calculated
 */
const getMinutesFuzzy = ({ duration, interval, withRounding = false }) => {
  // To accomodate the rounding of the units in the 1 minute interval case, we are fixing the decimal points to 3
  // Eg, when user enters 0.01667 which as per requirement should be treated as 1 min, but when user enters 0.01667
  // it is technically 1.0002 minutes and will be rounded to next unit that is 2 minutes.
  // To avoid this case we are rounding of the minutes to 2 decimal points.
  // 2 decimal points because if rouded to 3 decimal points 0.05001 hours is being rounded to 3.001 secs which ideally should be 3 secs
  if (Number.isFinite(duration)) {
    if (duration < 0) {
      // eslint-disable-next-line no-console
      console.error(`invalid duration: ${duration}`);
    }
    return withRounding ? Math.round(+(+duration * 60).toFixed(5)) : +duration * 60;
  }

  if (typeof duration !== 'string') {
    return undefined;
  }

  if (+duration >= 0) {
    return withRounding ? Math.round(+(+duration * 60).toFixed(5)) : +duration * 60;
  }

  // match for #:# format
  let match = duration.match(/([.0-9]*)\s*[:]\s*([.0-9]{0,2})/);

  if (match) {
    return withRounding ? Math.round(+(+match[1] * 60 + +match[2]).toFixed(5)) : +match[1] * 60 + +match[2];
  }

  // match for #h# format
  match = duration.match(/([.0-9]+)\s*[Hh]\s*([.0-9]+)/);

  if (match) {
    return withRounding ? Math.round(+(+match[1] * 60 + +match[2]).toFixed(5)) : +match[1] * 60 + +match[2];
  }

  // match for #h format
  match = duration.match(/([.0-9]+)\s*[Hh]/);

  if (match) {
    return withRounding ? Math.round(+(+match[1] * 60).toFixed(5)) : +match[1] * 60;
  }

  // match for #m format
  match = duration.match(/([.0-9]+)\s*[Mm]/);

  if (match) {
    return withRounding ? Math.round(+parseFloat(match[1]).toFixed(5)) : +match[1];
  }

  // match for #u format
  match = duration.match(/([.0-9]+)\s*[Uu]/);

  if (match) {
    return withRounding ? Math.round(+(+match[1] * interval).toFixed(5)) : +match[1] * interval;
  }

  return undefined;
};

/**
 * @param {Object} params
 * @param {number} params.mins
 * @param {number} params.interval
 * @returns {number} mins rounded up to the next interval, e.g. roundToInterval(7, 6) => 12
 */
const roundToInterval = ({ mins, interval }) => Math.ceil(mins / interval) * interval;

/**
 *
 * @param {number} hrs
 * @returns {number} decimal hours to hh:mm format
 */
const convertHrsToHHMM = ({ hrs }) => {
  if (!hrs) {
    return '00:00';
  }

  const roundedHrs = Math.floor(hrs);
  const mins = (hrs - roundedHrs) * 60;
  const roundedMinutes = Math.round(mins);
  return `${roundedHrs.toString().padStart(2, '0')}:${roundedMinutes.toString().padStart(2, '0')}`;
};

/**
 * @param {Object} params
 * @param {number} params.units
 * @param {number} params.interval
 * @returns {number} units of provided interval are converted to decimal hours.
 */
const convertUnitsToHours = ({ units, interval }) => {
  if (!units || !interval) {
    return 0;
  }
  return +((Math.ceil(units) * interval) / 60).toFixed(5);
};

/**
 * @param {Object} params
 * @param {number} params.hrs
 * @param {number} params.interval
 * @param {boolean} params.withRounding
 * @returns {number} decimal hours are converted to units of provided interval.
 */
const convertHoursToUnits = ({ hrs, interval, withRounding }) => {
  if (!hrs || !interval) {
    return 0;
  }
  const mins = getMinutesFuzzy({ duration: hrs, interval, withRounding });
  const roundedHrs = +(roundToInterval({ mins, interval }) / 60).toFixed(5);
  return +((roundedHrs * 60) / interval).toFixed(2);
};

/**
 * @param {Object} params
 * @param {number} params.mins
 * @param {number} params.interval
 * @returns {number} minutes are converted to units of provided interval.
 */
const convertMinsToUnits = ({ mins, interval }) => {
  if (!mins || !interval) {
    return 0;
  }
  const roundedHrs = +(roundToInterval({ mins, interval }) / 60).toFixed(5);
  return +((roundedHrs * 60) / interval).toFixed(2);
};

/**
 *
 * @param {number} isHrs
 * @param {number} isUnits
 * @param {number} units
 * @param {number} interval
 * @returns {number} time in HH and MM format.
 */
const convertUnitsToHoursAndMinutes = ({ isHrs, isUnits, interval, units }) => {
  if (isHrs) {
    return convertHrsToHHMM({ hrs: units });
  }
  if (isUnits) {
    const hrs = convertUnitsToHours({ units, interval });
    return convertHrsToHHMM({ hrs });
  }
  // Fixed fee
  return 'N/A';
};

/**
 *
 * @param {number} isHrs
 * @param {number} isUnits
 * @param {number} isFixed
 * @param {number} units
 * @param {number} interval
 * @returns {number} time in hours decimal format.
 */
const convertToHours = ({ isHrs, isUnits, interval, units }) => {
  if (!interval || !units) {
    return 0;
  }

  if (isHrs) {
    return units;
  }
  if (isUnits) {
    return convertUnitsToHours({ units, interval });
  }

  // for fixed or default value if this function is called with irrelevant data
  return 0;
};

module.exports = {
  getMinutesFuzzy,
  roundToInterval,
  convertHrsToHHMM,
  convertUnitsToHours,
  convertHoursToUnits,
  convertMinsToUnits,
  convertUnitsToHoursAndMinutes,
  convertToHours,
};
