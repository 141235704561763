angular.module('@sb-billing/services').service('sbWaiveInvoiceCommand', function (sbInvoicingCacheManager, sbUuidService, sbInvoicingService, sbInvoiceTotalsService) {
  'use strict';

  const that = this;
  that.executeP = executeP;
  that.isWaivable = isWaivable;

  function isWaivable(id) {
    const invoice = sbInvoicingCacheManager.getById(id);
    return invoice && invoice.currentVersion.status === 'FINAL';
  }

  function getOpdates(id, versionId) {
    const cacheInvoice = _.cloneDeep(sbInvoicingCacheManager.getById(id));
    cacheInvoice.currentVersion.versionId = versionId;
    cacheInvoice.versionIds = [versionId];
    cacheInvoice.currentVersion.status = 'PAID';
    cacheInvoice.optimistic = true;

    const invoiceTotals = sbInvoiceTotalsService.getTotalsForInvoiceId(id);
    const adjustedInvoiceTotals = sbInvoiceTotalsService.adjustTotalsForWaive(invoiceTotals);

    return {sbInvoicingService: [cacheInvoice], sbInvoiceTotalsService: [adjustedInvoiceTotals]};
  }

  function executeP(id) {
    const versionId = sbUuidService.get();
    return sbInvoicingService.waiveInvoiceP(id, versionId, getOpdates(id, versionId));
  }

});
