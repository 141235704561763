import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useTranslation } from '@sb-itops/react';
import { getRegion } from '@sb-itops/region';
import { Cent } from '@sb-itops/money';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import { Fee } from '@sb-billing/business-logic/fee/entities';
import {
  entryType as activityEntryTypes,
  durationType as activityDurationTypes,
} from '@sb-billing/business-logic/shared/entities';
import { fuzzyTime, convertDurationToType, tax } from '@sb-billing/business-logic/shared/services';
import {
  getApplicableActivityCategories,
  getApplicableActivityCodes,
  convertToActivityGroupArray,
  convertToTaskGroupArray,
  getPreferredActivityDurationType,
  getPreferredTaskDurationType,
  deriveActivityRate,
} from '@sb-billing/business-logic/activities/services';
import { deriveRate } from '@sb-billing/business-logic/rates';
import { deriveNewFeeBillableStatus } from '@sb-billing/business-logic/fee/services';
import { getMatterBillableMinutes } from '@sb-billing/business-logic/matters/billing-config';
import { featureActive } from '@sb-itops/feature';
import { QuickFeeEntry } from './QuickFeeEntry';

const region = getRegion();

const { getMinutesFuzzy, roundToInterval, convertUnitsToHours, convertHoursToUnits, convertUnitsToHoursAndMinutes } =
  fuzzyTime;

const { createTaxStrategy } = tax;

// This is messy and crap, but effectively we use this function to create a "fee" object from the passed activity data.
// It's used to calculate certain derived fields such as amount and tax.
const getFee = ({
  rateInCents,
  registeredForGst,
  taxRateBasisPoints,
  durationInMins,
  isTaxInclusive,
  isTaxExempt,
  durationType,
  isBillable,
}) => {
  const taxStrategy =
    registeredForGst && taxRateBasisPoints !== undefined
      ? createTaxStrategy({
          taxRate: taxRateBasisPoints,
          isTaxInclusive,
          isTaxExempt,
          region,
        })
      : undefined;

  return new Fee({
    rate: new Cent(rateInCents || 0),
    feeType: durationType === activityDurationTypes.FIXED ? activityEntryTypes.FIXED : activityEntryTypes.TIME,
    duration: `${durationInMins}m`,
    billableDuration: isBillable ? `${durationInMins}m` : 0,
    taxStrategy,
  });
};

const deriveFeeRate = ({ activity, staffRateConfig, matterHourlyRate }) => {
  // Setup users will not have the staffRateConfig, and therefore staffId
  // which is required in deriveRate
  const rateInCents =
    staffRateConfig?.id && featureActive('BB-10835')
      ? deriveRate({
          staffId: staffRateConfig.id,
          activity,
          staffRate: staffRateConfig.rate,
          matterHourlyRate,
        })
      : deriveActivityRate({
          activity,
          staffRateConfig,
          matterRateConfig: matterHourlyRate,
        });
  return rateInCents;
};

const hooks = ({
  billingIncrementsMins,
  showMatterField,
  showTasksField,
  preferDurationAsUnits,
  registeredForGst,
  taxRateBasisPoints,
  activities,
  tasks,
  quickAddDisabled,
  matter: currentMatter,
  isSubjectOverridable,
  utbmsCodesRequiredByFirm,
  setIsSubjectOverridable,
}) => ({
  useRegion: () => ({
    region,
  }),
  // Controls the options available in the activity selector drop-down
  useAvailableActivityGroups: () => {
    const [activityGroupArray, setActivityGroupArray] = useState([]);

    useEffect(() => {
      updateAvailableActivityGroupsForMatter(currentMatter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities, currentMatter?.billingConfiguration?.isUtbmsEnabled]);

    const updateAvailableActivityGroupsForMatter = (matter) => {
      const utbmsEnabledForMatter = matter?.billingConfiguration?.isUtbmsEnabled || false;
      const availableActivityCategories = getApplicableActivityCategories({
        utbmsEnabledForMatter,
        utbmsCodesRequiredByFirm,
      });

      setActivityGroupArray(
        convertToActivityGroupArray({ activities, filter: { types: availableActivityCategories } }),
      );
    };

    return {
      activities: activityGroupArray,
      updateAvailableActivityGroupsForMatter,
    };
  },
  useTaskGroups: () => {
    const [taskGroupArray, setTaskGroupArray] = useState([]);
    useEffect(() => {
      updateAvailableTaskGroupsForMatter(currentMatter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks, currentMatter?.billingConfiguration?.isUtbmsEnabled]);

    const updateAvailableTaskGroupsForMatter = (matter) => {
      if (matter?.billingConfiguration?.isUtbmsEnabled) {
        setTaskGroupArray(convertToTaskGroupArray({ tasks, filter: {} }));
      }
    };

    return {
      tasks: taskGroupArray,
      updateAvailableTaskGroupsForMatter,
    };
  },
  // Controls the options available in the subject typeahead.
  useAvailableSubjects: (props) => {
    const [subjects, setSubjects] = useState([]);

    const filterActivitiesForMatter = (matter) => {
      // For UTBMS matter, if UTBMS codes required we only show subjects for UTBMS activities
      const utbmsEnabledForMatter = matter?.billingConfiguration?.isUtbmsEnabled || false;
      const relevantActivities = getApplicableActivityCodes({
        activities,
        utbmsEnabledForMatter,
        utbmsCodesRequiredByFirm,
      });

      return relevantActivities;
    };

    const updateAvailableSubjectsForMatter = (matter) => {
      setSubjects(filterActivitiesForMatter(matter));
    };

    useEffect(() => {
      setSubjects(filterActivitiesForMatter(props.matter));
    }, [props.matter]);

    return {
      subjects,
      updateAvailableSubjectsForMatter,
    };
  },

  // Controls the various form fields state.
  useQuickFeeEntryForm: ({ staffRateConfig, formData, formErrors, onUpdateFormData }) => {
    // Controls whether or not tax options dropdown is currently displayed.
    const [showTaxOptions, setShowTaxOptions] = useState(false);
    const { t } = useTranslation();

    // Derived fields, i.e. fields which are calculated based on user input become
    // a PITA to manage due to all of the cross field dependencies. Instead of trying
    // to cleveryly maintain the derived calculations within event callbacks, this
    // function is used within the form event callbacks to calculate derived values and
    // to set the latest form state.
    const applyNewFormData = (newFormData) => {
      const { duration, durationType, rateInCents, isBillable, matter } = { ...formData, ...newFormData };

      const matterBillableMinutes = getMatterBillableMinutes({
        matterHourlyRate: matter?.matterHourlyRate,
        firmBillableMinutes: billingIncrementsMins,
      });
      const durationIsInUnits = durationType === activityDurationTypes.UNITS;

      const hours = durationIsInUnits
        ? convertUnitsToHours({ units: +duration, interval: matterBillableMinutes })
        : duration;
      const rawMins = getMinutesFuzzy({ duration: hours, interval: matterBillableMinutes, withRounding: true });

      // This happens when the user enters an unsupported fuzzy minutes value in "HOURS" time entry mode.
      // We just ignore the input and not change the state in this case.
      if (
        formData.durationType === activityDurationTypes.HOURS && // We are in hours mode
        durationType === activityDurationTypes.HOURS && // and are staying in hours mode
        rawMins === undefined
      ) {
        return;
      }

      const mins = rawMins === undefined ? 0 : rawMins;

      const roundedHours = +(roundToInterval({ mins, interval: matterBillableMinutes }) / 60).toFixed(5);
      const durationInHoursAndMinutes = convertUnitsToHoursAndMinutes({
        isUnits: false,
        isHrs: true,
        units: roundedHours,
        interval: matterBillableMinutes,
      });

      const roundedDuration = durationIsInUnits ? `${Math.ceil(+duration || 1)}` : `${roundedHours}`;

      const fee = getFee({
        rateInCents,
        durationInMins: mins || 0,
        durationType,
        isBillable,
        registeredForGst,
        taxRateBasisPoints,
        isTaxExempt: newFormData.isTaxExempt,
        isTaxInclusive: newFormData.isTaxInclusive,
      });

      onUpdateFormData({
        ...newFormData,
        time: durationInHoursAndMinutes,
        durationInMins: mins || 0,
        roundedDuration,
        amountInCents: fee.amountExcTax()?.cents() || 0,
        taxAmountInCents: fee.tax()?.cents() || 0,
        derivedFieldsCalculated: true,
      });
    };

    // Some external prop changes trigger form data value changes.
    // Recalculations based of external prop changes are managed in this effect.
    useEffect(() => {
      applyNewFormData({
        staffId: staffRateConfig?.id,
        rateInCents: deriveFeeRate({
          activity: formData.activity,
          staffRateConfig,
          matterHourlyRate: formData.matter?.matterHourlyRate,
        }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffRateConfig]);

    // Handles matter change events.
    const onUpdateMatterField = (selectedMatter) => {
      const utbmsCodesRequiredForMatter =
        utbmsCodesRequiredByFirm && selectedMatter?.billingConfiguration?.isUtbmsEnabled;

      // Handle matter billingType defaults per BB-7588
      const isBillable = deriveNewFeeBillableStatus({
        matterBillingType: selectedMatter?.billingConfiguration?.billingType,
        durationType: formData.durationType,
      });

      setIsSubjectOverridable(true);

      applyNewFormData({
        matter: selectedMatter,
        activity: undefined,
        task: undefined,
        subject: undefined,
        subjectActivity: undefined,
        isBillable,
        rateInCents: deriveFeeRate({
          activity: formData.activity,
          staffRateConfig,
          matterHourlyRate: selectedMatter?.matterHourlyRate,
        }),
        utbmsCodesRequiredForMatter,
      });
    };

    // Handles activity change events.
    const onUpdateActivityField = ({ selectedActivity, isSelectedFromSubjectDropdown }) => {
      if (!selectedActivity) {
        applyNewFormData({ subjectActivity: undefined });
        return;
      }

      const utbmsActivitySelected = selectedActivity.category === activityCategories.UTBMS;

      const matterBillableMinutes = getMatterBillableMinutes({
        matterHourlyRate: formData.matter?.matterHourlyRate,
        firmBillableMinutes: billingIncrementsMins,
      });

      // Tasks only make sense for UTBMS activities, clear task if activity not UTBMS
      const task = utbmsActivitySelected ? formData.task : undefined;
      const taskDescription = task?.description;
      const activitySubject = taskDescription
        ? `${selectedActivity.description} ${taskDescription}`
        : selectedActivity.description;

      const durationType = utbmsActivitySelected
        ? formData.durationType
        : getPreferredActivityDurationType({
            activity: selectedActivity,
            preferDurationAsUnits,
            billingIncrementsMins: matterBillableMinutes,
          });

      // Calculate the pre-defined duration for a custom activity code.
      // UTBMS activities do not specify a duration, so we leave the duration unchanged for UTBMS.
      let durationAsNumber;
      if (!utbmsActivitySelected) {
        const durationInHours = +(selectedActivity.durationMins / 60).toFixed(5);

        durationAsNumber =
          durationType === activityDurationTypes.HOURS
            ? durationInHours
            : convertHoursToUnits({ hrs: durationInHours, interval: matterBillableMinutes, withRounding: true });
      }

      const isBillable = deriveNewFeeBillableStatus({
        matterBillingType: formData.matter?.billingConfiguration?.billingType,
        activity: selectedActivity,
        taskCode: task,
        durationType,
      });

      applyNewFormData({
        activity: selectedActivity,
        task,
        subject: isSubjectOverridable || isSelectedFromSubjectDropdown ? activitySubject : formData.subject,
        subjectActivity: selectedActivity,
        isBillable,
        duration: utbmsActivitySelected ? formData.duration : `${durationAsNumber}`,
        durationType,
        isTaxInclusive: selectedActivity.isTaxInclusive,
        isTaxExempt: selectedActivity.isTaxExempt,
        rateInCents: deriveFeeRate({
          activity: selectedActivity,
          staffRateConfig,
          matterHourlyRate: formData.matter?.matterHourlyRate,
        }),
      });
    };

    // Handles task change events.
    const onUpdateTaskField = (selectedTask) => {
      const selectedActivity = formData.activity;
      const isUtbmsActivity = selectedActivity?.category === activityCategories.UTBMS;

      // Tasks only make sense for UTBMS activities, clear activity if activity not UTBMS
      const activity = isUtbmsActivity ? selectedActivity : undefined;
      const activityDescription = activity?.description;
      const activitySubject = activityDescription
        ? `${activityDescription} ${selectedTask.description}`
        : selectedTask.description;

      // Custom UTBMS task codes has `entryType` property, which helps determine the duration type.
      // Standard UTBMS task codes does not have `entryType`, duration type should remain unchanged
      const newDurationType =
        selectedTask.entryType !== undefined
          ? getPreferredTaskDurationType({
              taskCode: selectedTask,
              preferDurationAsUnits,
            })
          : formData.durationType;

      // If moving from HOUR/UNIT to FIXED, set default duration to 0 similar to onUpdateDurationTypeField
      let newDuration = formData.duration;
      if (formData.durationType !== activityDurationTypes.FIXED && newDurationType === activityDurationTypes.FIXED) {
        newDuration = '0';
      }

      // If moving from fixed duration, for user safety, we wipe out the 0 duration to ensure
      // they need to re-enter a duration similar to quick entry form
      if (formData.durationType === activityDurationTypes.FIXED && newDurationType !== activityDurationTypes.FIXED) {
        newDuration = undefined;
      }

      // UTBMS tasks are always (initially) set to billable, custom tasks will have `isBillable` property
      const isBillable = selectedTask.isBillable ?? true;

      applyNewFormData({
        activity,
        subjectActivity: isUtbmsActivity ? selectedActivity : undefined,
        task: selectedTask,
        subject: isSubjectOverridable ? activitySubject : formData.subject,
        isBillable,
        durationType: newDurationType,
        duration: newDuration,
      });
    };

    // Handles duration change events.
    const onUpdateDurationField = (newDuration) => {
      if (formData.durationType === activityDurationTypes.UNITS && !Number.isInteger(+newDuration)) {
        return;
      }

      applyNewFormData({
        duration: newDuration,
      });
    };

    // Handles blurring away from the duration.
    const onDurationFieldBlur = () => {
      // If the user leaves the duration field and are currently in duration type of hours,
      // we need to replace the currently entered duration with the rounded and fuzzy time
      // decoded value if present.
      if (formData.durationType === activityDurationTypes.HOURS) {
        applyNewFormData({
          duration: formData.duration ? formData.roundedDuration || formData.duration : undefined,
        });
      }
    };

    // Handles duration type change events.
    const onUpdateDurationTypeField = (newDurationType) => {
      // Handle matter billingType defaults per BB-7588
      const isBillable = deriveNewFeeBillableStatus({
        matterBillingType: formData.matter?.billingConfiguration?.billingType,
        activity: formData.activity,
        taskCode: formData.task,
        durationType: newDurationType,
      });

      // We are moving to FIXED duration, durations don't hold any meaning so we can set to 0.
      if (newDurationType === activityDurationTypes.FIXED) {
        applyNewFormData({
          duration: '0',
          durationType: newDurationType,
          isBillable,
        });

        return;
      }

      // We are moving from fixed duration, for user safety, we wipe out the 0 duration to ensure
      // they need to re-enter a duration.
      if (formData.durationType === activityDurationTypes.FIXED) {
        applyNewFormData({
          duration: undefined,
          durationType: newDurationType,
          isBillable,
        });

        return;
      }

      const matterBillableMinutes = getMatterBillableMinutes({
        matterHourlyRate: formData.matter?.matterHourlyRate,
        firmBillableMinutes: billingIncrementsMins,
      });

      // Otherwise convert the duration from one duration type to another.
      const newDuration =
        formData.duration === undefined
          ? undefined
          : convertDurationToType({
              duration: formData.duration,
              durationType: formData.durationType,
              targetDurationType: newDurationType,
              interval: matterBillableMinutes,
            });

      applyNewFormData({
        duration: newDuration,
        durationType: newDurationType,
        isBillable,
      });
    };

    const onUpdateTaxOption = (optionId, isEnabled) => {
      const isTaxInclusive = optionId === 'isTaxInclusive' && isEnabled;
      const isTaxExempt = optionId === 'isTaxExempt' && isEnabled;

      applyNewFormData({
        isTaxInclusive,
        isTaxExempt,
      });
    };

    // Handles all other form update events.
    const onUpdateField = (field, newValue) => {
      applyNewFormData({
        [field]: newValue,
      });
    };

    const taxOptions = registeredForGst
      ? [
          {
            id: 'isTaxInclusive',
            label: t('activityEntryTaxOptions.taxIncLabel'),
            selected: formData.isTaxInclusive || false,
            disabled: quickAddDisabled,
          },
          {
            id: 'isTaxExempt',
            label: t('activityEntryTaxOptions.taxExemptLabel'),
            selected: formData.isTaxExempt || false,
            disabled: quickAddDisabled,
          },
        ]
      : [];

    return {
      quickAddDisabled,
      showMatterField,
      showTasksField,
      tasksFieldDisabled: !formData.matter?.billingConfiguration?.isUtbmsEnabled,
      showGstField: registeredForGst,
      taxOptions,
      onUpdateTaxOption,
      showTaxOptions,
      onTaxOptionsDisplayChange: (shouldDisplay) => setShowTaxOptions(shouldDisplay),
      formData,
      formErrors,
      onUpdateMatterField,
      onUpdateActivityField,
      onUpdateTaskField,
      onUpdateDurationField,
      onDurationFieldBlur,
      onUpdateDurationTypeField,
      onUpdateField,
      applyNewFormData,
      setIsSubjectOverridable,
    };
  },
});

export const QuickFeeEntryContainer = composeHooks(hooks)((props) => {
  const { formData } = props;

  if (!formData.derivedFieldsCalculated) {
    const isBillable = deriveNewFeeBillableStatus({
      matterBillingType: formData.matter?.billingConfiguration?.billingType,
      activity: formData.activity,
      taskCode: formData.task,
      durationType: formData.durationType,
    });
    const rateInCents = deriveFeeRate({
      activity: formData.activity,
      staffRateConfig: props.staffRateConfig,
      matterHourlyRate: formData.matter?.matterHourlyRate,
    });
    props.applyNewFormData({ ...formData, isBillable, rateInCents });

    return null;
  }

  // Selecting a new matter (or clearing a selected matter) needs to not only update form state,
  // it also need to update the available options in the activity drop-down and subject typeahead.
  // Rather than poluting the component with intimate knowledge of state, we combine all three operations
  // into a new matter field update function here.
  const onUpdateMatterField = (matter) => {
    props.onUpdateMatterField(matter);
    props.updateAvailableActivityGroupsForMatter(matter);
    props.updateAvailableTaskGroupsForMatter(matter);
    props.updateAvailableSubjectsForMatter(matter);
  };

  return <QuickFeeEntry {...props} onUpdateMatterField={onUpdateMatterField} />;
});

QuickFeeEntryContainer.displayName = 'QuickFeeEntryContainer';

QuickFeeEntryContainer.propTypes = {
  billingIncrementsMins: PropTypes.number.isRequired,
  showMatterField: PropTypes.bool,
  showTasksField: PropTypes.bool,
  quickAddDisabled: PropTypes.bool.isRequired,
  preferDurationAsUnits: PropTypes.bool,
  registeredForGst: PropTypes.bool,
  taxRateBasisPoints: PropTypes.number,
  staffRateConfig: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rate: PropTypes.number,
  }),
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create a fee
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterHourlyRate: PropTypes.shape({
        rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
        allStaffRate: PropTypes.number,
        ratesPerStaff: PropTypes.arrayOf(
          PropTypes.shape({
            staffId: PropTypes.string.isRequired,
            rate: PropTypes.number.isRequired,
          }).isRequired,
        ),
        billableMinutes: PropTypes.number,
      }),
      matterTotals: PropTypes.shape({
        // For legacy typeahead using cache entities
        unbilled: PropTypes.number,
        unpaid: PropTypes.number,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ),
  matterSummariesDataLoading: PropTypes.bool,
  onFetchMatterSummaries: PropTypes.func,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      isBillable: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(activityEntryTypes)),
      units: PropTypes.number,
      isTaxInclusive: PropTypes.bool,
      isTaxExempt: PropTypes.bool,
      rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
      allStaffRate: PropTypes.number,
      ratesPerStaff: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.string.isRequired,
          rate: PropTypes.number.isRequired,
        }).isRequired,
      ),
      description: PropTypes.string.isRequired,
      category: PropTypes.oneOf(Object.values(activityCategories)),
    }).isRequired,
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ),
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  utbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
  onUpdateFormData: PropTypes.func.isRequired,
  onAddFeeClicked: PropTypes.func.isRequired,
  onClearForm: PropTypes.func.isRequired,
};

QuickFeeEntryContainer.defaultProps = {
  showMatterField: false,
  showTasksField: false,
  durationAsUnits: false,
  registeredForGst: false,
  taxRateBasisPoints: undefined,
  matterSummariesDataLoading: undefined,
  onFetchMatterSummaries: undefined,
  tasks: [],
};
