/**
 * Shape of sources drawn upon for allocation.
 *
 * @typedef  {Object} Source
 * @property {string} sourceId - The id of the source
 * @property {number} amount   - The amount available to be drawn in this source.
 */

/**
 * Shape of target amount to be allocated.
 *
 * @typedef  {Object} Target
 * @property {string} targetId - The id of the target
 * @property {number} amount   - The amount to be allocated.
 */

/**
 * Shape of allocations created by this allocator.
 *
 * @typedef  {Object} Allocation
 * @property {string} sourceId  - The id of the source drawn upon for this allocation
 * @property {string} targetId  - The id of the target amount being allocated
 * @property {number} amount    - The amount of this allocation.
 * @property {number} remainingOnTarget - The amount remaining to be allocated for the target after this allocation has been applied - i.e. running remaining amount of the target.
 * @property {number} remainingOnSource - The amount remaining on the source used for this allocation - i.e. running remaining amount of the source.
 */

/**
 * allocateTargetFromSources
 *
 * Creates Allocations against the target from an array of Sources.
 * The order of allocation creation is dictated by the ordering of the Sources - the first source is drawn upon first.
 *
 * The strategy behaves as follows;
 *
 * Loop over each source in the order they are provided in the sources array
 *   For each source, draw the maximum amount possible to satisfy the target's amount (up to the remaining target amount).
 *
 * @param {object} param
 * @param {Array<Source>} param.sources      The sources which will be drawn upon to satisfy target amount.
 * @param {Target} param.target       The target amount to be allocated.
 * @param {boolean} param.zeroAllocate Flag indicating whether sources will receive a 0 amount allocation once the remaining target amount has hit 0.
 *                                True: All sources will have an entry in the allocations for the target, some may have 0 amount allocations.
 *                                False: Some sources may not be present in the final allocations for the target, all allocation amounts will be > 0.
 * @param {Function} param.transform    Function which receives each allocation in order and returns a transformed allocation. Can be used by the caller to reshape the allocation objects.
 * @param {Function} param.accumulate   Function which receives each allocation in order which can be used by the caller to efficiently calculate accumulation data across the allocations.
 *
 * @return {Array<Allocation>}         The calculated allocations.
 */
module.exports = ({
  sources,
  target,
  zeroAllocate = true,
  transform = (allocation) => allocation,
  accumulate = () => {},
}) => {
  validateParameters({ sources, target });

  let remainingOnTarget = target.amount;
  return sources.reduce((acc, { sourceId, amount: sourceAmount }) => {
    // Skip the source if it has no amount remaining.
    if (!zeroAllocate && sourceAmount <= 0) {
      return acc;
    }

    // Check whether zero amount allocations should be applied against sources.
    if (!zeroAllocate && remainingOnTarget === 0) {
      return acc;
    }

    const amountToAllocateFromSource = Math.min(sourceAmount, remainingOnTarget) || 0;
    remainingOnTarget -= amountToAllocateFromSource;

    // Create the allocation for this source.
    const allocation = {
      sourceId,
      remainingOnTarget,
      remainingOnSource: sourceAmount - amountToAllocateFromSource,
      targetId: target.targetId,
      amount: amountToAllocateFromSource,
    };

    const transformedAllocation = transform(allocation);
    accumulate(transformedAllocation, allocation);
    acc.push(transformedAllocation);

    return acc;
  }, []);
};

const validateParameters = ({ sources, target }) => {
  // Perform parameter validation.
  if (!Array.isArray(sources) || !sources.length) {
    throw new Error('sources parameter must be an array of at least one source object');
  }

  sources.forEach((source) => {
    if (!source || !source.sourceId || source.amount === undefined || Number.isNaN(source.amount)) {
      throw new Error('sources parameter must contain valid source objects');
    }
  });

  if (!target || typeof target !== 'object') {
    throw new Error('target parameter must be a valid target object');
  }

  if (!target.targetId || target.amount === undefined || Number.isNaN(target.amount)) {
    throw new Error('target parameter must be a valid target object');
  }
};
