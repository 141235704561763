import { StatelessSelect } from '@sb-itops/react';
import React from 'react';
import classNames from 'classnames';
import * as Styles from './WidgetSettings.module.scss';
import { WIDGET_DEFAULTS } from './WidgetDefaults';

interface Props {
  onChange: Function;
  settings: any;
  children?: React.ReactNode;
}

export const WidgetSettings = ({ onChange, settings, children }: Props) => (
  <div className={Styles.container}>
    <div className={Styles.form}>
      {WIDGET_DEFAULTS[settings.type]?.styleOptions && (
        <div className={Styles.item}>
          <label>Style</label>
          <div>
            <StatelessSelect
              options={WIDGET_DEFAULTS[settings.type]?.styleOptions}
              selectedOption={settings.style}
              onChange={(style) => onChange({ style })}
              className={Styles.selectWidthOverride}
            />
          </div>
        </div>
      )}
      {WIDGET_DEFAULTS[settings.type]?.timeFrameUnitsOptions && (
        <div className={Styles.item}>
          <label>Range</label>
          <input
            type="number"
            className={classNames(Styles.smallInput, Styles.paddingRight)}
            value={settings.timeFrame}
            onChange={(event) => onChange({ timeFrame: event.target.value })}
          />
          <div>
            <StatelessSelect
              options={WIDGET_DEFAULTS.MattersNoActivity.timeFrameUnitsOptions}
              selectedOption={settings.timeFrameUnits}
              onChange={(timeFrameUnits) => onChange({ timeFrameUnits })}
              className={Styles.selectWidthOverride}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  </div>
);
