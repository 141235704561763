import PropTypes from 'prop-types';
import React from 'react';
import { Spinner, Modal, Button, buttonTypes, useTranslation } from '@sb-itops/react';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import Styles from './StripeAccountManagementPanel.module.scss';

export const StripeAccountManagementPanel = (props) => {
  const { t } = useTranslation();
  const {
    showConfirmDisconnect,
    linkedAccount,
    bankAccountType,
    bankAccountId,
    disabled,
    isConnecting,
    isDisconnecting,
    onConnectAccount,
    onContinueOnboarding,
    onRequestDisconnectAccount,
  } = props;

  const bankAccountTypeLocalised =
    bankAccountType.toUpperCase() === bankAccountTypeEnum.OPERATING ? t('operatingAccountType') : t('trustAccountType');

  const onManageAccount = () => {
    window.open(`https://dashboard.stripe.com/${linkedAccount.stripeAccountId}`);
  };

  // No stripe account has been created for this bank account yet, show button to create new account.
  if (!linkedAccount) {
    return (
      <div className={Styles.stripeAccountManagementPanel}>
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onConnectAccount({ bankAccountId, bankAccountType })}
          disabled={disabled}
        >
          Connect with Stripe
          {'  '}
          {isConnecting && <Spinner small />}
        </Button>
      </div>
    );
  }

  // Stripe account has been created, but the user hasn't completed the on-boarding process for the Stripe account.
  // Provide them with a button to continue with the on-boarding process.
  if (!linkedAccount.onboardingComplete) {
    return (
      <div className={Styles.stripeAccountManagementPanel}>
        {showConfirmDisconnect && getConfirmDisconnectModal({ ...props, bankAccountType: bankAccountTypeLocalised })}
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onContinueOnboarding(linkedAccount)}
          disabled={disabled}
        >
          Continue Stripe Account Setup
        </Button>
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onRequestDisconnectAccount()}
          disabled={disabled}
        >
          Disconnect Stripe Account
          {'  '}
          {isDisconnecting && <Spinner small />}
        </Button>
      </div>
    );
  }

  // Otherwise, allow the user to access the dashboard to manage other shenanigans.
  if (linkedAccount.onboardingComplete) {
    return (
      <div className={Styles.stripeAccountManagementPanel}>
        {showConfirmDisconnect && getConfirmDisconnectModal({ ...props, bankAccountType: bankAccountTypeLocalised })}
        <Button className={Styles.linkButton} type={buttonTypes.link} onClick={onManageAccount} disabled={disabled}>
          Manage Stripe Account
        </Button>
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onRequestDisconnectAccount()}
          disabled={disabled}
        >
          Disconnect Stripe Account
          {'  '}
          {isDisconnecting && <Spinner small />}
        </Button>
      </div>
    );
  }

  return null;
};

function getConfirmDisconnectModal(props) {
  const { showConfirmDisconnect, bankAccountType, linkedAccount } = props;
  const { onDisconnectAccount, onCancelDisconnectAccount } = props;
  return (
    <Modal
      className={Styles.accountDisconnectModal}
      isVisible={showConfirmDisconnect}
      title="Confirm Stripe Account Disconnection"
      body={
        <div>
          <p>Are you sure you want to disconnect your {bankAccountType} Stripe account from Smokeball?</p>
          <p>
            If you disconnect this account, you will need to create a new Stripe account to reconnect with Smokeball.
          </p>
        </div>
      }
      footer={
        <div className={Styles.buttonPanel}>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.secondary}
            onClick={() => onCancelDisconnectAccount()}
          >
            Cancel
          </Button>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.warning}
            onClick={() => onDisconnectAccount(linkedAccount)}
          >
            Confirm Disconnect
          </Button>
        </div>
      }
      onModalClose={onCancelDisconnectAccount}
    />
  );
}

StripeAccountManagementPanel.displayName = 'StripeAccountManagementPanel';

StripeAccountManagementPanel.propTypes = {
  showConfirmDisconnect: PropTypes.bool.isRequired,
  linkedAccount: PropTypes.object,
  bankAccountType: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  isDisconnecting: PropTypes.bool.isRequired,
  onConnectAccount: PropTypes.func,
  onContinueOnboarding: PropTypes.func,
  onRequestDisconnectAccount: PropTypes.func,
  onCancelDisconnectAccount: PropTypes.func,
  onDisconnectAccount: PropTypes.func,
};

StripeAccountManagementPanel.defaultProps = {
  linkedAccount: undefined,
  onConnectAccount: () => {},
  onContinueOnboarding: () => {},
  onRequestDisconnectAccount: () => {},
  onCancelDisconnectAccount: () => {},
  onDisconnectAccount: () => {},
};
