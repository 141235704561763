/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import classnames from 'classnames';
import { useTranslation, CollapsibleHeader, PanelExpander, DatePicker, MultiSelectFilters } from '@sb-itops/react';
import { StaffSelectorListContainer as StaffSelectorList } from '@sb-firm-management/react';
import { FilterExpandCollapse } from 'web/containers';
import { dateFilterTypes } from './constants';
import Styles from './ExpenseFilter.module.scss';

interface ToggleOption {
  id: string;
  name: string;
  selected: boolean;
  count: number;
}

interface IProps {
  filterScope: string;
  expanded: boolean;
  toggleExpanded: () => void;
  onResetFilters: () => void;
  // Date filter
  beforeDateIsValid: boolean;
  fromDateIsValid: boolean;
  toDateIsValid: boolean;
  selectedDateFilterType: keyof typeof dateFilterTypes;
  selectedBeforeDate: Date;
  selectedFromDate: Date;
  selectedToDate: Date;
  onUpdateDateField: (field: string, value: any) => void;
  // Staff Filter
  staffMembers: { id: string; name: string; initials: string }[];
  selectedStaffIds: string[];
  onStaffSelectionChange: (value: any) => void;
  // Status + Other filter
  anticipatedDisbursementsEnabled: boolean;
  statusToggleOptions?: ToggleOption[];
  expenseStatusCountsLoading?: boolean;
  onStatusOptionsChange: (field: any, value: any) => void;
  otherOptions: ToggleOption[];
  onOtherOptionsChange: (field: any, value: any) => void;
}

export const ExpenseFilter = ({
  filterScope,
  expanded,
  toggleExpanded,
  onResetFilters,
  // Date filter
  beforeDateIsValid,
  fromDateIsValid,
  toDateIsValid,
  selectedDateFilterType,
  selectedBeforeDate,
  selectedFromDate,
  selectedToDate,
  onUpdateDateField,
  // Staff filter
  staffMembers,
  selectedStaffIds,
  onStaffSelectionChange,
  // Status + Other filter
  anticipatedDisbursementsEnabled,
  statusToggleOptions,
  expenseStatusCountsLoading,
  onStatusOptionsChange,
  otherOptions,
  onOtherOptionsChange,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={Styles.filterContainer}>
      <FilterExpandCollapse className="panel-filter" scope={filterScope}>
        {({ hiddenFilters, onToggleFilterVisibility }) => (
          <PanelExpander
            className={classnames('panel-filter', Styles.expenseFilter)}
            expanded={expanded}
            toggleExpanded={toggleExpanded}
            onResetFilters={onResetFilters}
          >
            {/* Expense/Disbursement Date Filter */}
            <CollapsibleHeader
              text={`${t('capitalizeAllWords', { val: 'expense' })} Date`}
              onClick={onToggleFilterVisibility}
              name="expenseDateFilters"
              collapsed={hiddenFilters?.expenseDateFilters}
            >
              <ul className="side-menu-list">
                <li
                  className={classnames(selectedDateFilterType === dateFilterTypes.TODAY && 'active')}
                  onClick={() => onUpdateDateField('selectedDateFilterType', dateFilterTypes.TODAY)}
                >
                  Today
                </li>
                <li
                  className={classnames(selectedDateFilterType === dateFilterTypes.THIS_MONTH && 'active')}
                  onClick={() => onUpdateDateField('selectedDateFilterType', dateFilterTypes.THIS_MONTH)}
                >
                  This Month
                </li>
                <li
                  className={classnames(selectedDateFilterType === dateFilterTypes.LAST_MONTH && 'active')}
                  onClick={() => onUpdateDateField('selectedDateFilterType', dateFilterTypes.LAST_MONTH)}
                >
                  Last Month
                </li>
                <li
                  className={classnames(selectedDateFilterType === dateFilterTypes.BEFORE && 'active')}
                  onClick={() => onUpdateDateField('selectedDateFilterType', dateFilterTypes.BEFORE)}
                >
                  <div className={Styles.datePickerContainer}>
                    <div className={Styles.datePickerLabel}>Before</div>
                    <div className={classnames(Styles.datePickerInput)}>
                      <DatePicker
                        value={beforeDateIsValid ? selectedBeforeDate : null}
                        onSelect={(value) => onUpdateDateField('selectedBeforeDate', value)}
                        hasError={!beforeDateIsValid}
                        format="DD/MM/YYYY"
                        size="XS"
                        minimal
                      />
                    </div>
                  </div>
                </li>
                <li
                  className={classnames(selectedDateFilterType === dateFilterTypes.CUSTOM && 'active')}
                  onClick={() => onUpdateDateField('selectedDateFilterType', dateFilterTypes.CUSTOM)}
                >
                  <div className={Styles.datePickerContainer}>
                    <div className={Styles.datePickerLabel}>From</div>
                    <div className={classnames(Styles.datePickerInput)}>
                      <DatePicker
                        value={fromDateIsValid ? selectedFromDate : null}
                        onSelect={(value) => onUpdateDateField('selectedFromDate', value)}
                        hasError={!fromDateIsValid}
                        format="DD/MM/YYYY"
                        size="XS"
                        minimal
                      />
                    </div>
                  </div>
                  <div>
                    <div className={Styles.datePickerContainer}>
                      <div className={Styles.datePickerLabel}>To</div>
                      <div className={classnames(Styles.datePickerInput)}>
                        <DatePicker
                          value={toDateIsValid ? selectedToDate : null}
                          onSelect={(value) => onUpdateDateField('selectedToDate', value)}
                          hasError={!toDateIsValid}
                          format="DD/MM/YYYY"
                          size="XS"
                          minimal
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </CollapsibleHeader>

            {/* Staff Filter */}
            <CollapsibleHeader
              text="Staff"
              onClick={onToggleFilterVisibility}
              name="expenseStaffFilters"
              collapsed={hiddenFilters?.expenseStaffFilters}
            >
              <StaffSelectorList
                staff={staffMembers}
                forceSelectedStaffIds={selectedStaffIds}
                onStaffSelectionChange={onStaffSelectionChange}
              />
            </CollapsibleHeader>

            {/* Status Filter */}
            <CollapsibleHeader
              text="Status"
              name="expenseOtherAndStatusFilters"
              onClick={onToggleFilterVisibility}
              collapsed={hiddenFilters.expenseOtherAndStatusFilters}
            >
              {anticipatedDisbursementsEnabled && statusToggleOptions && (
                <MultiSelectFilters
                  className={Styles.expenseStatusFilters}
                  id="expenseStatusFilters"
                  filterOptions={statusToggleOptions}
                  onSelect={onStatusOptionsChange}
                  showCounter
                  counterDataLoading={expenseStatusCountsLoading}
                />
              )}
              <MultiSelectFilters
                className={Styles.expenseOtherFilters}
                id="expenseOtherFilters"
                filterOptions={otherOptions}
                onSelect={onOtherOptionsChange}
              />
            </CollapsibleHeader>
          </PanelExpander>
        )}
      </FilterExpandCollapse>
    </div>
  );
};
