'use strict';

const uuid = require('@sb-itops/uuid');
const { providers } = require('../entities/constants');
const { extractChargeFields: extractFeeWiseSpecificChargeFields } = require('../services/fee-wise');

const providerSpecificSaveCardExtractors = {
  [providers.LAWPAY]: extractLawpaySpecificSaveCardFields,
  [providers.FEE_WISE]: extractFeeWiseSpecificChargeFields,
};

const createSaveCardRequest = ({ accountId, saveCardFormData, providerType, contactId, bankAccountId }) => {
  if (!providerType) {
    throw new Error('Provider type must be provided');
  }

  if (!contactId) {
    throw new Error('Contact Id must be provided');
  }

  // This could indicate an error actually processing a tokenize request, network failure etc.
  if (!saveCardFormData || !saveCardFormData.providerFormData) {
    throw new Error('Invalid payment form data');
  }

  const extractProviderSpecificChargeFields = providerSpecificSaveCardExtractors[providerType];
  if (!extractProviderSpecificChargeFields) {
    throw new Error(`Cannot create save card request for unsupported provider type '${providerType}'`);
  }

  // WARNING
  // The only data derived from paymentFormData that is included in the below returned object must
  // be extracting using an extractor and passed in providerSpecificFields. Referring to provider
  // specific fields directly in a top level property below is a huge no-no as it will break the genericity
  // of the payment provider API.
  // Seriously. If you don't heed this warning, there will be endless frowns cast upon you.
  const providerSpecificFields = extractProviderSpecificChargeFields({ paymentFormData: saveCardFormData });

  return {
    versionId: uuid(),
    entityId: contactId,
    providerType,
    providerSpecificFields,
    accountId,
    bankAccountId,
    description: saveCardFormData.description,
  };
};

function extractLawpaySpecificSaveCardFields({ paymentFormData }) {
  const { providerFormData } = paymentFormData;

  if (!providerFormData.paymentToken) {
    throw new Error('Failed to extract lawpay specific charge fields - payment token is required');
  }

  if (!providerFormData.paymentMethod) {
    throw new Error('Failed to extract lawpay specific charge fields - payment method is required');
  }

  return {
    paymentToken: providerFormData.paymentToken,
    paymentMethod: providerFormData.paymentMethod,
    cardType: providerFormData.cardType,
  };
}

module.exports = {
  createSaveCardRequest,
};
