import PropTypes from 'prop-types';
import { useState } from 'react';
import { getLogger } from '@sb-itops/fe-logger';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { sortByProperty } from '@sb-itops/nodash';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { isTrustAccountClosed, getTrustAccounts, getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getProviderSettings, getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { featureActive } from '@sb-itops/feature';
import {
  deactivateProvider,
  disconnect as disconnectProvider,
  manage as manageProvider,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { useSelector } from 'react-redux';
import { getAccountId } from 'web/services/user-session-management';
import { bankAccountTypeEnum as bankAccountTypes } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getAppEnv, envType } from '@sb-itops/app-env';
import { FeeWiseSettingsForm } from './FeeWiseSettingsForm';
import { formatFeeWiseAccountOptions } from './format-fee-wise-account-options';

const scope = 'fee-wise-settings-form';
const log = getLogger('FeeWiseSettingsFormContainer');
const noneOption = { label: 'None', value: '' };

/**
 * @typedef { import('./format-fee-wise-account-options').FeeWiseAccountOptions } FeeWiseAccountOptions
 */

const hooks = ({ isSaving, onSaveSettings }) => ({
  useFeeWiseSettings: () => {
    const isNonProdAppEnv = getAppEnv() !== envType.PRODUCTION;
    const activeProviderType = getActiveProvider();

    const { t } = useTranslation();
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [activateToggled, setActivateToggled] = useState(activeProviderType === providers.FEE_WISE);

    const { formValues, formInitialised, onInitialiseForm, onClearForm, onFieldValueSet, onSubmitForm } = useForm({
      scope,
    });

    const feeWiseSettings = useSelector(() => getProviderSettings(providers.FEE_WISE) || {});
    const linkedAccountsSettings = feeWiseSettings.linkedAccounts || {};
    const feeWiseAccountsSettings = feeWiseSettings.feeWiseAccounts || [];
    const accountId = getAccountId();

    const trustAccounts = getTrustAccounts().map((trustAccount) => {
      const trustAccountId = trustAccount.id;
      return {
        bankAccountId: trustAccountId,
        bankAccountType: bankAccountTypes.TRUST,
        bankAccountName: getBankAccountName(trustAccount, t),
        isClosed: isTrustAccountClosed(trustAccount),
      };
    });
    const trustAccountsSorted = sortByProperty(
      trustAccounts.filter((ta) => formValues.showClosedTrustAccounts || !ta.isClosed),
      'bankAccountName',
      'asc',
      false /* caseSensitive */,
    );

    const operatingAccountId = getOperatingAccount()?.id || `${accountId}/Operating`;
    const operatingAccount = {
      bankAccountId: operatingAccountId,
      bankAccountType: bankAccountTypes.OPERATING,
      bankAccountName: t('operatingBankAccount'),
    };

    /** @type FeeWiseAccountOptions */
    const feeWiseOptionsMap = formatFeeWiseAccountOptions({
      feeWiseAccounts: feeWiseAccountsSettings,
      linkedAccounts: formValues.linkedAccounts,
    });

    return {
      trustAccounts: trustAccountsSorted,
      operatingAccount,
      feeWiseTrustOptions: [noneOption, ...feeWiseOptionsMap[bankAccountTypes.TRUST]],
      feeWiseOperatingOptions: [noneOption, ...feeWiseOptionsMap[bankAccountTypes.OPERATING]],
      // We need this to display linked accounts in selectbox as they are missing in selectbox options list
      linkedAccountOptions: feeWiseOptionsMap.LINKED,
      noneOption,
      showInvoiceLink: formValues.showInvoiceLink || false,
      showScanToPay: formValues.showScanToPay || false,
      showClosedTrustAccounts: formValues.showClosedTrustAccounts || false,
      formInitialised,
      isSendingEmail,
      isSaving,
      isDisconnecting,
      isRefreshing,
      activateToggled,
      activeProviderType,
      isConnected: !!feeWiseSettings.firmId,
      isNonProdAppEnv,
      onActivateToggle: setActivateToggled,
      onLoad: () => {
        const linkedAccountsEmpty = Object.keys(linkedAccountsSettings).length === 0;
        onInitialiseForm({
          showInvoiceLink: feeWiseSettings.showInvoiceLink,
          showScanToPay: feeWiseSettings.showScanToPay,
          showClosedTrustAccounts: false,
          // we can't initialise form with empty object, we have to use undefined instead
          linkedAccounts: linkedAccountsEmpty ? undefined : linkedAccountsSettings,
        });
        return onClearForm;
      },
      onToggleField: ({ field }) => onFieldValueSet(field, !formValues[field]),
      onSelectAccount: ({ bankAccountId, bankAccountType, option }) => {
        const currentLinkedAccounts = formValues.linkedAccounts || {};
        let updatedLinkedAccounts;

        if (option.value) {
          updatedLinkedAccounts = {
            ...currentLinkedAccounts,
            [bankAccountId]: {
              feeWiseAccountId: option.value,
              bankAccountId,
              bankAccountType,
            },
          };
        } else {
          // None option, remove linked account
          updatedLinkedAccounts = { ...currentLinkedAccounts };
          delete updatedLinkedAccounts[bankAccountId];
        }

        onFieldValueSet('linkedAccounts', updatedLinkedAccounts);
      },
      onSaveFeeWise: async () => {
        onSubmitForm({
          submitFnP: async () => {
            const providerSpecificSettings = {
              showInvoiceLink: formValues.showInvoiceLink,
              showScanToPay: formValues.showScanToPay,
              linkedAccounts: formValues.linkedAccounts,
            };
            // delegate saveing
            await onSaveSettings({
              providerType: providers.FEE_WISE,
              providerSpecificSettings,
              providerActivateToggled: activateToggled,
            });
          },
        });
      },
      onDisconnectFeeWise: async () => {
        onSubmitForm({
          submitFnP: async () => {
            try {
              setIsDisconnecting(true);
              if (activeProviderType === providers.FEE_WISE) {
                await deactivateProvider({ fetchPostP, providerType: providers.FEE_WISE });

                if (featureActive('BB-13244')) {
                  window?.ChurnZero?.push(['setAttribute', 'account', 'Payment Processor', 'NONE']);
                }
              }
              await disconnectProvider({ fetchPostP, providerType: providers.FEE_WISE });
              displaySuccessToUser(`${providerNames[providers.FEE_WISE]} account disconnected successfully`);
              onClearForm();
            } catch (err) {
              setIsDisconnecting(false);
              log.error(`Failed to disconnect ${providerNames[providers.FEE_WISE]} account`, err);
              displayErrorToUser(`Failed to disconnect ${providerNames[providers.FEE_WISE]} account`);
              throw err;
            }
          },
        });
      },
      onReloadBankAccounts: async () => {
        try {
          setIsRefreshing(true);
          await manageProvider({
            fetchPostP,
            providerType: providers.FEE_WISE,
            providerSpecificFields: { actionType: 'RELOAD_BANK_ACCOUNTS' },
          });
        } catch (err) {
          log.error(`Failed to reload bank accounts from ${providerNames.FEE_WISE} account`, err);
          displayErrorToUser(`Failed to reload bank accounts from ${providerNames.FEE_WISE} account`);
        } finally {
          setIsRefreshing(false);
        }
      },
      onManageAccountClick: async () => {
        try {
          const response = await manageProvider({
            fetchPostP,
            providerType: providers.FEE_WISE,
            providerSpecificFields: { actionType: 'GET_MAGIC_LINK' },
          });
          const feeWiseDashboardUrl = response.url;
          window.open(feeWiseDashboardUrl, '_blank', 'noopener');
        } catch (err) {
          log.error(`Failed to get management URL for ${providerNames.FEE_WISE} account`, err);
          if (err.payload?.status === 404) {
            displayErrorToUser(`User account for ${providerNames.FEE_WISE} not found. Please contact support.`);
          } else {
            displayErrorToUser(`Failed to get management URL for ${providerNames.FEE_WISE} account`);
          }
        }
      },
      onResendWelcomeEmail: async () => {
        if (!isNonProdAppEnv) {
          return;
        }

        try {
          setIsSendingEmail(true);
          const requestInfo = {
            connectionStep: 'SEND_WELCOME_EMAIL_TEST',
          };
          const path = `/billing/payment-provider/connect/${providers.FEE_WISE.toLowerCase()}/:accountId/`;
          const fetchOptions = { body: JSON.stringify(requestInfo) };
          const response = await fetchPostP({ path, fetchOptions });
          if (response.status === 200 && response?.body?.emailSent) {
            displaySuccessToUser(`Email sent to ${response?.body?.emailSent}`);
          }
        } catch (err) {
          log.error(`Failed to send email`, err);
          displayErrorToUser(`Failed to send email`);
        } finally {
          setIsSendingEmail(false);
        }
      },
    };
  },
});

export const FeeWiseSettingsFormContainer = withReduxProvider(composeHooks(hooks)(withOnLoad(FeeWiseSettingsForm)));

FeeWiseSettingsFormContainer.displayName = 'FeeWiseSettingsFormContainer';
FeeWiseSettingsFormContainer.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
FeeWiseSettingsFormContainer.defaultProps = {};
