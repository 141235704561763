'use strict';

const { feeCanBeModified } = require('./fee-can-be-modified');

/**
 * Determines if feeVersion can be marked as billable
 *
 * A fee version can be marked as billable if
 * 1. it can be modified
 * 2. is not billable
 * 3. is partially billable
 * @param {object} feeVersion
 * @returns {boolean}
 */
const feeCanBeMarkedAsNonBillable = (feeVersion) =>
  feeCanBeModified(feeVersion) && (feeVersion.isBillable || feeVersion.isBillable === null);

module.exports = {
  feeCanBeMarkedAsNonBillable,
};
