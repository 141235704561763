/**

========================
WARNING - PLEASE READ
=======================
Developer beware! This service is complicated and critical. Before making changes:

1. Confirm they are necessary
2. Make sure you know what you are doing
3. Check with someone else who knows

DO NOT REFACTOR! This service will be removed. Until then, dont anger the code gods.

Thanks!
*/

import { toCamelCase } from '@sb-itops/camel-case';
import { getPayload, getEnabledFeatures } from '@sb-itops/feature';
import { store } from '@sb-itops/redux';

// This is a bit naughty, because we are importing a web directory into itops.
// However, technically this whole service either shouldn't exist, or should exist in the web app.
// There is zero chance of this service ever being used anywhere other than the web-app and it will
// be nuked in the future. Importing from the web app directory does mean that this service cannot
// be used anywhere else, which, overall is a good thing.
import { refreshTokenP, getAuthToken, getAccountId, getIsAuthenticated, getIsAuthRefreshInProgress } from 'web/services/user-session-management';

/*
 * DON'T USE log.error IN THIS SERVICE OR YOU RISK FALLING INTO AN INFINITE LOOP
 */
require('./endpoint-type');

angular.module('@sb-itops/services').service('sbGenericEndpointService', function ($q, $http, $rootScope, sbEnvironmentConfigService, sbLoggerService, sbCorrelationService, sbUuidService) {
  'use strict';
  const that = this;
  const log  = sbLoggerService.getLogger('sbGenericEndpointService');
  //const versionHeaderName = 'Smokeball-Client';
  const logErrorEvent = 'log-error';

  let requestQueue = [];

  that.requestConstructorFactory = requestConstructorFactory;
  that.getPayloadP = getPayloadP;
  that.postPayloadP = postPayloadP;
  that.deletePayloadP = deletePayloadP;
  that.payloadP = payloadP;
  that.http = http;
  that.cancelRequest = cancelRequest;

  $rootScope.$on(logErrorEvent, logError);

  store.subscribe(() => {
    // If no requests are queued, we can ignore any state updates as it means no requests have yet failed
    // due to bad auth.
    if (requestQueue.length === 0) {
      return;
    }

    // If the user is currently in the process of re-authenticating via refresh token, then we are only interested 
    // in what eventually happens. So we can skip this.
    if (getIsAuthRefreshInProgress()) {
      return; 
    }

    // We aren't refreshing and we aren't authenticated, reject anything in the queue and return.
    if (!getIsAuthenticated()) {
      rejectQueue();
      return;
    }

    // We are authenticated again, resubmit all the queued requests.
    submitQueue();
  });

  function combineAdditional(additional) {
    if (_.isArray(additional)) {
      // combine array of strings
      return _.map(additional, (part) => part.replace(/^\//, '').replace(/\/$/, '')) // strip leading/trailing slashes
        .join('/');
    }

    return additional;
  }

  function getDefaultHeaders () {
    const token = getAuthToken();
    return {
      'Authorization': `Bearer ${token}`,
      'x-correlation-id': sbCorrelationService.get(),
      'x-features': getEnabledFeatures().join(','),
      'Cache-Control': 'no-store', // Prevents weird chrome issue where in network failure scenarios, caching of corrupt responses may occur.
    };
  }

  function requestConstructorFactory(namespace, additional) {
    return (lastUpdated) => {
      log.debug(`construct request, lastUpdated ${lastUpdated}`);

      const url = getUrl(namespace, additional, lastUpdated);
      if (!url) {
        log.warn('Url required, url: %s', url);
        throw new Error('Url required, url: ' + url);
      }
          
      const headers = getDefaultHeaders();
      log.debug(`headers`, headers);
      return Promise.resolve({ method: 'GET', url, headers }); // Wrapped in promise resolve to play nice with whatever was previously requiring a promise.
    };
  }

  function getPayloadP(namespace, additional, extraConfig) {
    const payload = { method: 'GET', namespace, additional };
    angular.extend(payload, extraConfig);
    return payloadP(payload);
  }

  /**
   * @param {*} namespace     the path for the HTTP request e.g. '/billing/entries'
   * @param {*} additional    string or array, slugs appended to the namespace to form part if the url for the HTTP request
   * @param {*} data          the HTTP request payload
   * @param {*} method        the HTTP method, default POST
   * @param {*} extraConfig   Arbitrary data to be added to the payload, common uses include changeset
   */
  function postPayloadP(namespace, additional, data, method, extraConfig) {
    const payload = { method: method || 'POST', namespace, additional, data };
    angular.extend(payload, extraConfig);
    return payloadP(payload);
  }

  function deletePayloadP(namespace, additional, data, extraConfig) {
    const payload = { method: 'DELETE', namespace, additional, data, headers: { 'Content-Type': 'application/json;charset=utf-8' } };
    angular.extend(payload, extraConfig);
    return payloadP(payload);
  }

  function getUrl(namespace, additional, lastUpdated) {
    additional = '' + (combineAdditional(additional) || '');
    lastUpdated = (lastUpdated === undefined) ? '' : '' + (lastUpdated || '');

    const accountId = getAccountId();
    
    if (_.first(namespace) !== '/') {
      namespace = `/${namespace}`;
    }

    if (_.last(namespace) !== '/') {
      namespace = `${namespace}/`;
    }

    if (!additional || _.first(additional) !== '/') {
      additional = `/${additional}`;
    }

    if (lastUpdated) {
      if (_.last(additional) !== '/') {
        additional = `${additional}/`;
      }
    }

    const apiHost = sbEnvironmentConfigService.getRestApiUrl();
    return `${apiHost}/v2${namespace}${accountId}${additional}${lastUpdated}`;
  }

  function http(config) {
    log.debug(`http...`, config);
    const deferred = $q.defer();

    if (getIsAuthRefreshInProgress()) {
      requestQueue.push({ config, deferred });
      return deferred.promise;
    }

    if (!getIsAuthenticated()) {
      deferred.reject(new Error('Unauthorized'));
      return deferred.promise;
    }
    
    log.debug(`call http`, config);
    $http(config)
      .then((res) => {
        log.debug(`http resolve`, res);
        deferred.resolve(res);
      })
      .catch((err) => {
        if (err && err.status === 401) {
          // requests are queued pending a token refresh, once completed, they are eventually resolved or rejected
          log.info(`http unauthed`);
          requestQueue.push({ config, deferred });
          refreshTokenP();
        } else {
          log.warn(`unexpected error calling http`, config, err);
          deferred.reject(err);
        }
      });

    return deferred.promise;
  }

  function payloadP(payload) {
    // Hack in the ability to cancel requests 'in flight'.
    const deferred = $q.defer();   
    const httpTimeout = $q.defer();
    deferred.promise._boostHttpTimeout = httpTimeout;
    
    if (getIsAuthRefreshInProgress()) {
      requestQueue.push({ payload, deferred });
      return deferred.promise;
    }

    if (!getIsAuthenticated()) {
      deferred.reject(new Error('Unauthorized'));
      return deferred.promise;
    }

    const url = getUrl(payload.namespace, payload.additional);
    log.debug(`method: ${payload.method}, URL: ${url}, changeset`, payload.changeset);

    if (!url) {
      log.warn('URL required');
      deferred.reject(new Error('URL required'));
      return deferred.promise;
    }

    const headers = getDefaultHeaders();
    _.merge(headers, payload.headers);

    // When angular sees Content-Type: undefined in the request header, it assumes multipart and
    // populates the boundaries for us. We cannot pass in Content-Type: undefined directly via
    // payload.headers, because the above _.merge will nuke it away completely. So weirdly, we
    // need the caller to be able to specify headers which should be forcibly set to undefined.
    _.each(payload.undefinedHeaders || [], (undefinedHeader) => {
      headers[undefinedHeader] = undefined;
    });

    if (payload.changeset && payload.method !== 'GET') {
      payload.data.opdateId = sbUuidService.get();
    }

    const config = {
      method: payload.method,
      
      data: Array.isArray(payload.data) ? payload.data : { ...payload.data, sbFeatures: getPayload() },
      responseType: payload.responseType ? payload.responseType: undefined,
      url,
      headers,
      timeout: httpTimeout.promise
    };

    $http(config)
      .then((res) => {
        if (res.status === 200 || res.status === 204 || res.status === 207) {
          // We only resolve the promise with the request promise with the response data, which is not useful in all cases
          // In the event that the response is an object, we can patch the status. This wont wrok for primitives, including
          // string
          const data = successHandler(payload, res);
          if (typeof data === 'object') {
            data.$sbStatus = res.status;
          }                
          deferred.resolve(data);
        } else {
          // eslint-disable-next-line no-console
          console.error(`Failed to ${payload.method} data, failure: ${res.statusText}`);
          deferred.reject(new Error(`Failed to ${payload.method} data, failure: ${res.statusText}`));
        }
      })
      .catch((err) => {
        if (err && err.status === 401) {
          // requests are queued pending a token refresh, once completed, they are eventually resolved or rejected
          requestQueue.push({ payload, deferred });
          refreshTokenP();
        } else if (err && err.status === -1) { // -1 indicates aborted,
          log.debug(`request aborted while ${payload.method}'ing payload: `, JSON.stringify(payload));
          deferred.reject(err);
        } else {
          if (payload.method === 'HEAD') {
            log.info(`HEAD request on ${config.url} failed, status: ${err.status}`);
          } else {
            log.warn(`unexpected error ${payload.method}'ing payload: `, JSON.stringify(err));
          }
          deferred.reject(err);
        }
      });

    return deferred.promise;
  }

  function cancelRequest(httpRequestPromise) {
    const timeoutResolver = _.get(httpRequestPromise, '_boostHttpTimeout.resolve');
    if (_.isFunction(timeoutResolver)) {
      timeoutResolver();
    }
  }

  function successHandler(payload, response) {
    if (payload.data && payload.data.opdateId) {
      $rootScope.$broadcast('opdate-posted', payload.changeset);
    }

    if (payload.skipCamelCase || _.isEmpty(response.data)) {
      return response.data;
    }

    return toCamelCase(response.data);
  }

  function submitQueue() {
    log.warn(`submitting ${requestQueue.length} queued requests`);

    const token = getAuthToken();
    _.each(requestQueue, (request) => {
      log.debug(`submit request`, request);
      const submitter = request.payload ? payloadP : http;
      const toSubmit = request.payload ? request.payload : request.config;
      log.debug(`toSubmit`, toSubmit);

      if (_.get(toSubmit, 'headers.Authorization')) {
        toSubmit.headers.Authorization = `Bearer ${token}`;
      }

      submitter(toSubmit)
        .then((response) => {
          request.deferred.resolve(response);
        })
        .catch((err) => {
          request.deferred.reject(err);
        });
    });

    requestQueue = [];
  }

  function rejectQueue() {
    log.warn(`rejecting ${requestQueue.length} queued requests`);
    _.each(requestQueue, (request) => {
      request.deferred.reject(new Error('Unauthorized'));
    });

    requestQueue = [];
  }

  function logError(evt, args) {
    log.info('saw error event', args);
    that.postPayloadP('log', null, { level: 'error', message: args.message });
  }
});