'use strict';

/**
 * @typedef { import('../types.js').LawpayChargeFormData } LawpayChargeFormData
 */

const { capitalize } = require('@sb-itops/nodash');
const { Money, MONETARY_UNIT } = require('@sb-itops/money');
const { paymentMethods } = require('./payment-methods');

const centsToDollars = (cents) => {
  const money = new Money(cents, MONETARY_UNIT.cent);
  return money.dollars();
};

/**
 * getChargeDescription
 *
 * @param {Object} params
 * @param {number} params.amountInCents The amount for the charge.
 * @param {boolean} params.includeStaffDetails Flag to indicate whether to include staff details in the description.
 * @param {string} [params.staffName] Staff name to be used in the staff details portion of description. Ignored if includeStaffDetails is false.
 * @param {LawpayChargeFormData} params.providerSpecificFields The data received from the lawpay charge form

 * @returns {string} A human readable description of the charge.
 */
const getChargeDescription = ({ amountInCents, staffName, providerSpecificFields }) => {
  const {
    cardType,
    cardholderName,
    cardNumberTruncated,
    accountType,
    accountHolderName,
    accountNumberTruncated,
    paymentMethod,
  } = providerSpecificFields;

  if (!paymentMethod) {
    throw new Error('missing paymentMethod from providerSpecificChargeData');
  }

  const dollarAmount = centsToDollars(amountInCents);
  const staffDetails = staffName ? ` (Processed by ${staffName})` : '';

  switch (paymentMethod) {
    case paymentMethods.CREDIT_CARD: {
      const cardholderDisplayName = cardholderName || '';
      const creditCardLabel = cardType ? `${capitalize(cardType)} credit card` : 'Credit card'; // Amex, visa, mastercard etc.
      return `${creditCardLabel} charge from ${cardholderDisplayName} (${cardNumberTruncated}) of $${dollarAmount}${staffDetails} via LawPay`;
    }

    case paymentMethods.ECHEQUE:
      return `eCheck charge from ${accountHolderName} (${accountType} account ${accountNumberTruncated}) of $${dollarAmount}${staffDetails} via LawPay`;

    default:
      throw new Error(`Failed to build charge description due to unsupported payment method of ${paymentMethod}`);
  }
};

module.exports = {
  getChargeDescription,
};
