import { t } from '@sb-itops/localisation-web';

angular
  .module('sb.billing.webapp')
  .factory(
    'exportTrustChequesOp',
    (
      $rootScope,
      $http,
      sbGenericEndpointService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
    ) => {
      const log = sbLoggerService.getLogger('exportTrustChequesOp');
      return (exportFilters) => {
        // Object representing the current state of this operation.
        const operation = {
          label: `${t('trustCheque')}s`,
          reportFormat: 'csv',
          isComplete: false,
          error: null,
          fileData: null,
        };

        downloadTrustChequesDataP(operation, exportFilters);

        return operation;
      };

      async function downloadTrustChequesDataP(operation, exportFilters) {
        const requestId = sbUuidService.get();

        try {
          // Kick off the operation
          const { payload } = await sbNotifiedOperationP(() => startExportingP(requestId, exportFilters), {
            requestId,
            completionNotification: 'TrustChequesExportReadyNotification',
            errorNotification: 'TrustChequesExportFailureNotification',
            timeoutMs: 180000,
          });

          const request = {
            method: 'GET',
            url: payload.exportFileUrl,
            responseType: 'arraybuffer'
          };

          const response = await $http(request);
          const file = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});
          operation.fileData = file;
        } catch (err) {
          log.error(`Failed to export ${t('trustCheque').toLowerCase()}s for requestId: ${requestId}`, err);
          operation.error = err;
        } finally {
          $rootScope.$applyAsync(() => {
            operation.isComplete = true;
          });
        }

        function startExportingP(requestId, exportFilters) {
          const requestData = {
            requestId,
            fileFormat: 'csv',
            ...exportFilters,
          };

          return sbGenericEndpointService.postPayloadP('/billing/trust-cheques/export', undefined, requestData, 'POST');
        }
      }
    }
  );
