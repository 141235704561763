import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { withReduxStore } from '@sb-itops/react';
import { getLogger } from '@sb-itops/fe-logger';
import { dateToInteger } from '@sb-itops/date';

import * as messageDisplay from '@sb-itops/message-display';
import { savePaymentPlan as savePaymentPlanP } from '@sb-billing/redux/payment-plans';
import { useQuery } from '@apollo/client';

import { BillingPaymentPlanDialogData } from 'web/graphql/queries';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useEffect } from 'react';
import { paymentPlanModalDialogFeature } from './payment-plan-modal-dialog-feature';
import PaymentPlanModalDialog from './PaymentPlanModalDialog';
import { paymentPlanSchema } from './payment-plan-schema';
import { withApolloClient } from '../../hocs/withApolloClient';

const logger = getLogger('web/react-redux/PaymentPlanModalDialogContainer');
logger.setLogLevel('info');

const isNextButtonEnabled = ({ debtor, installmentAmount, installmentFrequency, nextInstallmentDate }) =>
  debtor &&
  debtor.isValid &&
  installmentAmount &&
  installmentAmount.isValid &&
  installmentFrequency &&
  installmentFrequency.isValid &&
  nextInstallmentDate &&
  nextInstallmentDate.isValid;

const marshallNewPaymentPlan = (paymentPlan = {}) => ({
  id: paymentPlan.id,
  debtorId: paymentPlan.debtor,
  matterIds:
    paymentPlan.inclusions &&
    Object.entries(paymentPlan.inclusions).reduce((acc, [matterId, selected]) => {
      if (selected) {
        acc.push(matterId);
      }
      return acc;
    }, []),
  nextInstallmentDate: +paymentPlan.nextInstallmentDate,
  installmentFrequency: paymentPlan.installmentFrequency,
  installmentAmount: paymentPlan.installmentAmount,
});

const getExistingPaymentPlanCandidateMatters = ({ paymentPlan, unpaidMatters }) => {
  const candidateMattersMap = {};
  // Ensure no duplicates in the array
  unpaidMatters.forEach((item) => {
    if (!candidateMattersMap[item.matterId]) {
      candidateMattersMap[item.matterId] = { ...item.matter, ...item };
    }
  });

  // make sure matters that were in original payment plan inclusions is also included
  paymentPlan.matters.forEach((matter) => {
    if (candidateMattersMap[matter.id]) {
      candidateMattersMap[matter.id].canIncludeInPaymentPlan = true;
    } else {
      // For the cases where the matter is on the payment plan, but no longer
      // meets the criteria (ie, if the debtor is no longer on any outstanding
      // invoices for the matter). We still need to keep them on the payment plan
      candidateMattersMap[matter.id] = {
        ...matter,
        matterId: matter.id,
        canIncludeInPaymentPlan: true,
        due: 0,
        dueForThisDebtorOnly: 0,
      };
    }
  });

  return Object.values(candidateMattersMap);
};

const findNextInstallmentDate = (installments) => {
  const todayAsInteger = dateToInteger(new Date());
  const next = installments.find((installment) => installment.date >= todayAsInteger);
  return next ? String(next.date) : `${todayAsInteger}`;
};

const hooks = ({
  paymentPlanModalId,
  modalDialogTitle,
  selectedPaymentPlanId,
  contactId,
  includedMatterIds,
  onNewPaymentPlanCreated,
}) => ({
  useGraphQL: () => {
    const dispatch = useDispatch();

    const {
      tabs: {
        selectors: { getSelectedTab },
        actions: { setSelectedTab, clearSelectedTab },
      },
      forms: {
        selectors: { getFieldValues, getFormFields, getFormState },
        actions: { initialiseForm, updateFieldValues, clearForm },
        operations: { validateSchema, submitFormP },
      },
    } = useSelector(paymentPlanModalDialogFeature);

    const currentTabName = getSelectedTab();

    const formState = useSelector(getFormState);
    const formFields = getFormFields();
    const fieldValues = getFieldValues();

    const backButtonVisible = currentTabName !== 'plan-details' && currentTabName !== undefined;
    const nextButtonVisible = currentTabName !== 'inclusions';

    // debtor id selected in form if user changed dropdown selection, otherwise default
    // to the contact passed into the component, there may not be a debtor passed e.g.
    // when this modal is opened from Debtors with Payment Plans page under Contacts
    const debtorId = fieldValues.debtor || contactId;

    let selectedPaymentPlan = null;

    // TODO get payment method data
    const { error, loading, data } = useQuery(BillingPaymentPlanDialogData, {
      variables: {
        paymentPlanFilter: contactId ? { contactIds: [contactId] } : {},
        unpaidMatterFilter: contactId ? { debtorIds: [contactId] } : {},
        matterFilter: { includeDeleted: true },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const paymentPlans = data?.paymentPlans || [];
    const unpaidMatters = data?.unpaidMatters || [];

    const relatedContactsMap = unpaidMatters.reduce((acc, unpaidMatter) => {
      unpaidMatter.debtors.forEach((debtor) => {
        if (!acc[debtor.id]) {
          acc[debtor.id] = debtor;
        }
      });
      return acc;
    }, {});

    const relatedContacts = Object.values(relatedContactsMap);

    if (selectedPaymentPlanId) {
      selectedPaymentPlan = paymentPlans.find((pp) => pp.id === selectedPaymentPlanId);
    }

    const candidateMattersForPaymentPlan = selectedPaymentPlan
      ? getExistingPaymentPlanCandidateMatters({ paymentPlan: selectedPaymentPlan, unpaidMatters })
      : // Flattens the data structure as the components expect the 'unpaid matter' properties to be on the matter object itself
        unpaidMatters.map(({ matter, ...unpaidMatter }) => ({ ...matter, ...unpaidMatter }));

    const startingDate =
      selectedPaymentPlan && selectedPaymentPlan.startDate
        ? moment(selectedPaymentPlan.startDate, 'YYYYMMDD').toDate()
        : new Date();
    const candidateMatters = debtorId ? candidateMattersForPaymentPlan : [];

    // Cant use onLoad because we need to wait for the graphQL query to finish before setting the initial values
    useEffect(
      () => {
        if (loading) {
          return;
        }
        if (selectedPaymentPlanId) {
          const initialFieldValues = {
            id: selectedPaymentPlanId,
            debtor: selectedPaymentPlan.debtorId,
            installmentAmount: selectedPaymentPlan.installmentAmount,
            installmentFrequency: selectedPaymentPlan.installmentFrequency,
            nextInstallmentDate: findNextInstallmentDate(selectedPaymentPlan.installments),
            inclusions: selectedPaymentPlan.matters.reduce((acc, matter) => {
              acc[matter.id] = true;
              return acc;
            }, {}),
          };

          dispatch(initialiseForm({ fieldValues: initialFieldValues }));
        } else {
          const initialFieldValues = {
            debtor: contactId,
            inclusions:
              includedMatterIds.length > 0
                ? includedMatterIds.reduce((acc, matterId) => {
                    acc[matterId] = true;
                    return acc;
                  }, {})
                : undefined,
            nextInstallmentDate: String(dateToInteger(new Date())),
          };
          const initialiseFormAction = initialiseForm({ fieldValues: initialFieldValues });
          dispatch(initialiseFormAction);
        }

        // eslint-disable-next-line consistent-return
        return () => {
          dispatch(clearForm());
          dispatch(clearSelectedTab());
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loading],
    );

    return {
      onPlanDetailsTabClick: () => {
        dispatch(setSelectedTab({ tab: 'plan-details' }));
      },
      onInclusionsTabClick: () => {
        dispatch(setSelectedTab({ tab: 'inclusions' }));
      },
      onFieldValueChange: (field, value) => {
        dispatch(updateFieldValues({ fieldValues: { [field]: value } }));
        dispatch(validateSchema({ schema: paymentPlanSchema }));
      },
      onSaveClick: async () => {
        try {
          const paymentPlan = await dispatch(
            submitFormP({
              submitFnP: (paymentPlanFormData) => savePaymentPlanP(marshallNewPaymentPlan(paymentPlanFormData)),
            }),
          );
          messageDisplay.success('Payment plan was saved successfully.');
          if (!paymentPlan.id) {
            onNewPaymentPlanCreated(paymentPlan.id);
          }
        } catch (ex) {
          messageDisplay.error('Failed to save the payment plan.');
        }
      },
      modalId: paymentPlanModalId,
      backButtonVisible,
      nextButtonVisible,
      nextButtonEnabled: isNextButtonEnabled(formFields),
      saveButtonEnabled: formState.formValid && !formState.formSubmitting,
      modalDialogTitle,
      selectedPaymentPlanId,
      contactId,
      includedMatterIds,
      selectedTab: getSelectedTab(),
      formFields,
      fieldValues,
      startingDate,
      candidateMatters,
      relatedContacts,
    };
  },
});

const PaymentPlanModalDialogContainer = withReduxStore(withApolloClient(composeHooks(hooks)(PaymentPlanModalDialog)));

PaymentPlanModalDialogContainer.displayName = 'PaymentPlanModalDialogContainer';

PaymentPlanModalDialogContainer.propTypes = {
  selectedPaymentPlanId: PropTypes.string,
  paymentPlanModalId: PropTypes.string.isRequired,
  modalDialogTitle: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  includedMatterIds: PropTypes.arrayOf(PropTypes.string),
  // callbacks
  onNewPaymentPlanCreated: PropTypes.func.isRequired,
};

PaymentPlanModalDialogContainer.defaultProps = {
  selectedPaymentPlanId: undefined,
  contactId: undefined,
  includedMatterIds: [],
};

export default PaymentPlanModalDialogContainer;
