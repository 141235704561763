import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { getActiveTrustAccounts, getById as getBankAccountById, getTrustAccount } from '@sb-billing/redux/bank-account';
import { chequeExistsForBankAccount, getByBankAccountId as getTrustChequesByBankAccountId } from '@sb-billing/redux/trust-cheques';
import { findLastChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { sortByOrder } from '@sb-itops/nodash';

angular.module('sb.billing.webapp').component('sbCbuiPrintCheques', {
  templateUrl: 'ng-composable-components/callback-ui/print-cheques/cbui-print-cheques.html',
  bindings: { bankAccountId: '<?', chequeId: '<?', callbackFn: '&' },
  controller: function ($scope, sbLocalisationService) {
    const ctrl = this;
    const log = getLogger('sbCbuiPrintCheques');
    const MESSAGE_DISPLAY_GROUP = 'CBUI_PRINT_CHEQUES';
    let showErrors;
    let dataChanged = false;

    ctrl.t = sbLocalisationService.t;
    ctrl.prepField = prepField;
    ctrl.printCheques = printCheques;
    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.onChequeSelected = onChequeSelected;
    ctrl.firstChequeNumberChange = firstChequeNumberChange;
    ctrl.getBankAccountId = getBankAccountId;
    ctrl.getLastChequeNumber = () => findLastChequeNumber(getTrustChequesByBankAccountId(getBankAccountId()));
    ctrl.onChequeMemoUpdated = onChequeMemoUpdated;
    ctrl.onFocusNumberInput = onFocusNumberInput;
    ctrl.onSelectBankAccount = onSelectBankAccount;

    ctrl.$onInit = () => {
      log.info(`initialised, chequeId ${ctrl.chequeId}`);
      ctrl.processing = false;
      ctrl.errors = {
        bankAccount: false,
        overrideDate: false,
        firstCheque: false,
        chequeNumberInUse: false,
        chequeNumberNumeric: false,
      };

      let bankAccount = getTrustAccount();
      if (ctrl.bankAccountId) {
        bankAccount = getBankAccountById(ctrl.bankAccountId);
      }

      ctrl.sbData = {};

      ctrl.view = {
        bankAccounts: [],
        bankAccountOptions: [],
        trustCheques: [],
      }

      ctrl.model = {
        bankAccount,
        firstChequeNumberStr: '',
        firstChequeNumber: undefined,
        lastChequeNumber: '',
        selectedCheques: [],
        overrideDate: undefined,
        padding: 0,
        chequesMemosChanges: {}
      };

      updateChequeNumbering(bankAccount.id)

      // if there is a selected cheque, we need to make sure the first cheque # either has a value (or shows an error)
      if (ctrl.chequeId) {
        prepField('firstChequeNumber');
      }

      log.info('initial model', ctrl.model);
    };

    $scope.$watch('printChequesForm', (printChequesForm) => {
      if (printChequesForm) {
        $scope.$watch('printChequesForm.$dirty', (dirty) => {
          showErrors = dirty;
        });
      }
    });
    
    function updateChequeNumbering(bankAccountId = null) {
      let firstChequeNumberStr = getNextChequeNumber(getTrustChequesByBankAccountId(bankAccountId));
      const chequeNumberPadding = firstChequeNumberStr.length;

      ctrl.model.firstChequeNumberStr = firstChequeNumberStr;
      ctrl.model.firstChequeNumber = +firstChequeNumberStr;
      ctrl.model.lastChequeNumber = firstChequeNumberStr;
      ctrl.model.padding = chequeNumberPadding || 0;
    }

    function getBankAccounts() {
      const trustAccounts = getActiveTrustAccounts();
      return [...formatAccounts(trustAccounts)];
    }

    function getBankAccountId() {
      return ctrl.model.bankAccount && ctrl.model.bankAccount.id ? ctrl.model.bankAccount.id : null
    }

    function getTrustCheques(bankAccountId) {
      const trustCheques = ctrl.sbData && ctrl.sbData.trustCheques ? ctrl.sbData.trustCheques : [];
      const filters = {
        bankAccountId,
      };

      return _.filter(trustCheques, filters);
    }

    function formatAccounts(accounts) {
      const formattedAccounts = accounts.map((account) => {
        const chequeCount = getTrustCheques(account.id).length;
        return {
          ...account,
          display: getBankAccountName(account, sbLocalisationService.t),
          displayWithChequeCount: `${getBankAccountName(account, sbLocalisationService.t)} (${chequeCount} ${sbLocalisationService.t('cheque', {count: chequeCount})})`,
          chequeCount,
        };
      });

      return sortByOrder(formattedAccounts, ['display'], ['asc']);
    }

    function onChequeMemoUpdated(chequeId, chequeMemo) {
      ctrl.model.chequesMemosChanges[chequeId] = chequeMemo;
    }

    function onFocusNumberInput(event) {
      event.target.select();
    }

    function onSelectBankAccount(bankAccountOption) {
      const bankAccountId = bankAccountOption && bankAccountOption.value;
      ctrl.model.bankAccount = bankAccountId ? ctrl.view.bankAccounts.find((ba) => ba.id === bankAccountId) : null;

      ctrl.view.trustCheques = getTrustCheques(getBankAccountId());
      updateChequeNumbering(getBankAccountId());
    }

    function dataChangeFunction (key, doc) {
      log.info('data change', key, doc);

      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }

      const bankAccounts = getBankAccounts();
      
      ctrl.view.bankAccounts = bankAccounts;
      ctrl.view.bankAccountOptions = bankAccounts.map((account) => ({ label: account.displayWithChequeCount, value: account.id }));
      ctrl.view.trustCheques = getTrustCheques(getBankAccountId());

      // When we open modal from "Transactions>Trust Cheques" and not from specific trust account, we want to select
      // first trust account with cheques. We can't do it in $onInit as in that time ctrl.sbData.trustCheques is still empty array.
      // 
      // The code below is workaround which runs first time dataChangeFunction is called and selects first trust account with cheques.
      if (!dataChanged) {
        dataChanged = true;
        if (!ctrl.bankAccountId) {
          let firstBankAccountWithCheques = bankAccounts.find((ba) => ba.chequeCount > 0);
          if (firstBankAccountWithCheques) {
            onSelectBankAccount({value: firstBankAccountWithCheques.id});
          }
        }
      }

    }

    function isChequeNumberInUse (chequeNumberStr) {
      const bankAccountId = getBankAccountId();
      const lastUsedChequeNumber = findLastChequeNumber(getTrustChequesByBankAccountId(bankAccountId));
      if (chequeNumberStr && lastUsedChequeNumber) {
        return isFinite(+chequeNumberStr) && chequeExistsForBankAccount(chequeNumberStr, bankAccountId);
      }
      return false;
    }

    function firstChequeNumberChange () {
      showErrors = true;

      if (ctrl.model.firstChequeNumberStr && !isChequeNumberInUse(ctrl.model.firstChequeNumberStr)) {
        ctrl.model.firstChequeNumber = +ctrl.model.firstChequeNumberStr;
        ctrl.model.padding = ctrl.model.firstChequeNumberStr.length;
      } else {
        ctrl.model.firstChequeNumber = undefined;
        ctrl.model.lastChequeNumber = undefined;
      }

      prepField(`FIRSTCHEQUENUMBER`);
    }

    function prepField(name) {
      log.debug('prepField', name);

      switch (name.toUpperCase()) {
        case 'BANK_ACCOUNT':
          ctrl.errors.bankAccount = !ctrl.model.bankAccount;
          break;
        case 'OVERRIDEDATE':
          ctrl.errors.overrideDate = false;
          break;
        case 'FIRSTCHEQUENUMBER':
          ctrl.errors.chequeNumberIsRequired = !ctrl.model.firstChequeNumberStr;
          ctrl.errors.chequeNumberInUse = isChequeNumberInUse(ctrl.model.firstChequeNumberStr) && showErrors;
          ctrl.errors.chequeNumberNumeric = !isFinite(ctrl.model.firstChequeNumberStr) && showErrors;
          ctrl.errors.firstChequeNumber = ctrl.errors.chequeNumberIsRequired || ctrl.errors.chequeNumberNumeric || ctrl.errors.chequeNumberInUse && showErrors;
          break;
      }

      log.info('errors', ctrl.errors);
    }

    function onChequeSelected ({selected, lastChequeNumber}) {
      log.info('selected cheques', selected);
      ctrl.model.selectedCheques = selected;
      ctrl.model.lastChequeNumber = lastChequeNumber;
    }

    function isValid() {
      prepField('BANK_ACCOUNT');
      prepField('OVERRIDEDATE');
      prepField('FIRSTCHEQUENUMBER');

      if (ctrl.model.selectedCheques.length === 0) {
        messageDisplay.warn(
          messageDisplay.builder()
          .text('Please select at least one cheque to print')
          .group(MESSAGE_DISPLAY_GROUP)
        );

        return false;
      }
      return Object.keys(ctrl.errors).every((key) => !ctrl.errors[key]);
    }

    function printCheques() {
      if (isValid()) {
        ctrl.callbackFn({ data: marshal(ctrl.model) });
        messageDisplay.dismissGroup(MESSAGE_DISPLAY_GROUP);
      }
    }

    function marshal(model) {
      return {
        bankAccountId: getBankAccountId(),
        chequeDateOverride: model.overrideDate ? moment(model.overrideDate).format('YYYYMMDD') : undefined,
        // we pass string format to endpoint - if the number is too big it can exceed the maximum safe integer limit in JavaScript
        firstChequeNumber: model.firstChequeNumberStr,
        padding: model.padding,
        trustCheques: model.selectedCheques.map(({ chequeId, chequeMemo }) => ({
          chequeId,
          // If the memo has changed, use that instead of the memo on the cheque entity.
          // chequesMemosChanges[chequeId] will be undefined if the memo hasn't changed.
          // Note: we cant use truthiness checking as a deleted memo will be ''
          chequeMemo: model.chequesMemosChanges[chequeId] !== undefined ? model.chequesMemosChanges[chequeId] : chequeMemo,
        })),
      };
    }
  }
});
