import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getMap as getBankAccountBalancesState } from '@sb-billing/redux/bank-account-balances';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { store } from '@sb-itops/redux';
import { getMatterTrustBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import moment from 'moment';
import * as evergreenRequestsFilters from 'web/redux/route/home-billing-accounts-evergreen-requests';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { findDefaultTrustAccountForMatter } from 'web/redux/selectors/find-trust-account';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';

angular.module('sb.billing.webapp').component('sbDataFullEvergreenRequestsByFilters', {
  require:  { compose: '^sbCompose' },
  bindings : { filters: '<', sortData: '<' },
  controller: function ($scope, sbMattersMbService, sbTrustRetainersService, sbBillingConfigurationService) {
    const ctrl = this;

    let requests;

    function setData () {
      const sortBy = ctrl.sortData.sortBy || 'lastRequestDate';
      const sortDirection = ctrl.sortData.sortDirection && ctrl.sortData.sortDirection.toLowerCase() || 'desc';

      store.dispatch(evergreenRequestsFilters.actions.setRequests({
        requests: sort(requests, sortBy, sortDirection),
        sortBy,
        sortDirection,
        summary: summarise(requests),
      }));

      $scope.$applyAsync();
    }

    function sanitizeMatter(obj) {
      delete obj.$sbEntityType;
      delete obj.$sbSyncValue;

      return obj;
    }

    function sort (requests, sortBy, sortDirection) {
      return sortFunctions[sortBy](requests, sortDirection);
    }

    function meetsEvergreenRequestFilterCriteria ({ matter }) {
      if (!matter.matterId) return false;

      const {
        unpaid,
        request,
        hideMattersAboveMinimumThreshold,
        enableRequestOlderThanFilter,
        hideIfLastRequestOlderThanDays,
        enableReplenishAmountFilter,
        showIfReplenishAmountGreaterThanAmount,
      } = ctrl.filters;

      if (request) {
        const filterValue = request.toLowerCase();

        if (filterValue === 'requested' && !matter.sent) {
          return false;
        }

        if (filterValue === 'unrequested' && matter.sent) {
          return false;
        }
      }

      if (unpaid && unpaid.toLowerCase() === 'above' && matter.unpaid <= 0) {
        return false;
      }

      if (hideMattersAboveMinimumThreshold && matter.isAboveMinimumThreshold){
        return false;
      }

      if (enableRequestOlderThanFilter &&           // if filter is enabled
        !isNaN(hideIfLastRequestOlderThanDays) &&   // filter value is a number
        hideIfLastRequestOlderThanDays !== '' &&    // filter is not empty
        matter.daysSinceLastRequest &&
        matter.daysSinceLastRequest > parseInt(hideIfLastRequestOlderThanDays)
      ){
        return false;
      }

      if (enableReplenishAmountFilter &&
        !isNaN(showIfReplenishAmountGreaterThanAmount) &&
        showIfReplenishAmountGreaterThanAmount !== '' &&
        matter.replenishAmount <= showIfReplenishAmountGreaterThanAmount*100
      ){
        return false;
      }

      return true;
    }

    const sortFunctions = {
      lastRequestDate: sortByDate,
      matter: sortByMatter,
      trustBalance: sortByBalance,
      unpaid: sortByUnpaid,
      minimumThreshold: sortByMinimumThreshold,
      replenishUpTo: sortByReplenishUpTo,
      replenishAmount: sortByReplenishAmount,
      sent: sortBySent,
    }

    function sortByDate (entries, sortOrder) {
      return _.sortByOrder(entries, ['lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc']);
    }

    function sortByMatter (entries, sortOrder) {
      return _.sortByOrder(entries, ['matterDisplay', 'lastRequestTimeStampForSorting', 'matter.matterStarted'], [sortOrder, 'asc', 'asc']);
    }

    function sortByBalance (entries, sortOrder) {
      return _.sortByOrder(entries, ['matter.trustBalance', 'lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc', 'asc']);
    }

    function sortByUnpaid (entries, sortOrder) {
      return _.sortByOrder(entries, ['matter.unpaid', 'lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc', 'asc']);
    }

    function sortByMinimumThreshold(entries, sortOrder) {
      return _.sortByOrder(entries, ['matter.minimumThreshold', 'lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc', 'asc']);
    }

    function sortByReplenishUpTo(entries, sortOrder) {
      return _.sortByOrder(entries, ['matter.replenishUpTo', 'lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc', 'asc']);
    }

    function sortByReplenishAmount(entries, sortOrder) {
      return _.sortByOrder(entries, ['matter.replenishAmount', 'lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc', 'asc']);
    }

    function sortBySent(entries, sortOrder) {
      return _.sortByOrder(entries, ['matter.sent', 'lastRequestTimeStampForSorting', 'matterDisplay', 'matter.matterStarted'], [sortOrder, 'asc', 'asc', 'asc']);
    }

    function summarise (entries = []) {
      return entries.reduce((summary, entry) => ({
        unpaid:  summary.unpaid  += entry.matter.unpaid,
        minimumThreshold: summary.minimumThreshold += entry.matter.minimumThreshold,
        replenishUpTo: summary.replenishUpTo += entry.matter.replenishUpTo,
        trustBalance: summary.trustBalance += entry.matter.trustBalance,
        replenishAmount: summary.replenishAmount += entry.matter.replenishAmount,
      }), {unpaid: 0, minimumThreshold: 0, replenishUpTo: 0, trustBalance: 0, replenishAmount: 0});
    }

    const update = _.throttle(() => {
      const billingConfigs = sbBillingConfigurationService.getAll();
      
      const today = moment().startOf('day');
      requests = billingConfigs.reduce((acc, billingConfiguration) => {

        // include only active evergreen retainers
        if (!billingConfiguration.minimumTrustRetainerActive) {
          return acc;
        }

        const matterId = billingConfiguration.matterId;
        const matterEntity = getMatterById(matterId) || {};
        const matterTotals = getMatterTotalsById(matterId) || {};
        const unpaidTotal = matterTotals.unpaid || 0; // required due to invalid test data

        // include only retainers where matter is open or has an unpaid balance
        if (!(matterEntity.status === 'Open' || unpaidTotal > 0)) {
          return acc;
        }

        // get RetainerReplenishRequest record for matter
        const retainer = sbTrustRetainersService.getById(matterId);

        // proceed with calculating retain request fields
        const trustBalance = getMatterTrustBalance(getBankAccountBalancesState(), {
          matterId,
          balanceType: balanceTypes.BALANCE,
        });

        const trustAccountId = determineTrustAccountIdForMatter(matterId);

        let daysSinceLastRequest;
        let lastRequestDateInMoment;
        if (retainer && retainer.sentTimestamp && retainer.sentTimestamp !== '0001-01-01T00:00:00.0000000'
        ){
          // Day since last request
          // 1) considers local time
          // 2) calculated based off the date only, ignores timestamp
          lastRequestDateInMoment = moment(retainer.sentTimestamp);
          daysSinceLastRequest = today.diff(lastRequestDateInMoment.startOf('day'), 'days');
        }

        // Sent Status
        // 1) not sent if there's not retain request record yet
        // 2) not sent if retainer is replenished
        // 3) retainer has no valid last sent time stamp
        const notSent = (!retainer || retainer.replenished || !retainer.sentTimestamp || retainer.sentTimestamp === '0001-01-01T00:00:00.0000000');
        const sent = !notSent // inverse of notSent, could have done this in one line, but this is far easier to read and understand

        const replenishUpTo = billingConfiguration.trustRetainerReplenishAmount;
        const replenishAmount = (replenishUpTo - trustBalance > 0) ? replenishUpTo - trustBalance : 0;
        const lastRequestDateInLocalTime = lastRequestDateInMoment ? lastRequestDateInMoment.local().format() : undefined;

        const isUnderMinimumThreshold = trustBalance < billingConfiguration.minimumTrustRetainerAmount;
        const isAboveMinimumThreshold = !isUnderMinimumThreshold;

        // email request is only allowed when trust balance is below minimum threshold
        // in the future we may want to change this to allow sending of evergreen request
        // when replenishAmount is greater than zero, which probably makes more sense
        const emailRequestEnabled = isUnderMinimumThreshold;

        const matter = {
          ...sbMattersMbService.getById(matterId),
          status: matterEntity.status,
          unpaid: unpaidTotal,
          minimumThreshold: billingConfiguration.minimumTrustRetainerAmount,
          isUnderMinimumThreshold,
          isAboveMinimumThreshold,
          emailRequestEnabled,
          replenishUpTo,
          trustBalance,
          trustAccountId,
          replenishAmount,
          lastRequestDate: lastRequestDateInLocalTime,
          daysSinceLastRequest,
          sent,
          sentVia: retainer && retainer.sentVia,
        };

        // this is required for correct sorting by date as
        // 1) .net saves no value as '0001-01-01T00:00:00.0000000'
        // 2) the lodash sort function will put undefined up to top when sort in desc order
        const lastRequestTimeStampForSorting = retainer && retainer.sentTimestamp || '0001-01-01T00:00:00.0000000';

        const retainerRequest = {
          matter: sanitizeMatter(matter),
          matterDisplay: getMatterDisplayById(matter.matterId),
          lastRequestTimeStampForSorting,
        };

        // apply users filters specified through UI
        if (meetsEvergreenRequestFilterCriteria(retainerRequest)) {
          acc.push(retainerRequest);
        }

        return acc;
      }, []);

      setData();
    }, 500);

    ctrl.$onInit = () => {
      update();
    };

    ctrl.$onChanges = (changes) => {
      if (changes.filters) {
        update();
      }
      else if (changes.sortData) {
        setData();
      }
    };

    // listens for changes in the following caches in order to update UI with new data
    // this is especially important for multi-user scenarios where another user maybe doing
    // any of the following and thus changing one of the fields in the evergreen request table
    // 1) adding an invoice and thus changing the matter totals
    // 2) paying an invoice
    // 3) changing matter retainer billing configuration
    // 4) depositing trust payment
    // 5) making a vendor payment
    // 6) changing the matter meta data
    // 7) changing default trust account for location in trust account settings
    const listeners = [
      $scope.$on('smokeball-data-update-sbMattersMbService', update),
      $scope.$on('smokeball-data-update-sbMatterTotalsService', update),
      $scope.$on('smokeball-data-update-sbTrustRetainersService', update),
      $scope.$on('smokeball-data-update-sbBankAccountBalancesService', update),
      $scope.$on('smokeball-data-update-sbBillingConfigurationService', update),
      $scope.$on('smokeball-data-update-sbBankAccountSettingsService', update),
    ];

    ctrl.$onDestroy = () => {
      for (let unregister of listeners) {
        unregister();
      }
    }

    function determineTrustAccountIdForMatter(matterId) {
      const defaultTrustAccount = findDefaultTrustAccountForMatter(matterId);
      if (defaultTrustAccount) {
        return defaultTrustAccount.id;
      }

      // Fallback logic if no default trust account found
      // Returns trust accounts for matter in same order as we use in the Matter>Transactions filter panel
      const activeTrustAccounts = filterTrustAccountsByMatter(matterId);
      // pick first
      return activeTrustAccounts.length ? activeTrustAccounts[0].id : undefined;
    }
  }
});
