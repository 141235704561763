import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InvoiceAdditionalOptions } from '@sb-billing/react';
import { featureActive } from '@sb-itops/feature';
import { Button, DatePicker, LinkableText, LoadingBarInfinite, Select, TextEditor } from '@sb-itops/react';
import { ContactMultiSelect } from 'web/components';
import Styles from './DraftInvoiceSettings.module.scss';

export const DraftInvoiceSettings = ({
  expandedOptions,
  formData,
  formErrors,
  hasOpenTrustAccountsForMatter,
  onResetDefaultTemplate,
  onUpdateContacts,
  onUpdateField,
  onUpdateInvoiceConfigurationField,
  onUpdateInvoiceConfigurationFooterField,
  onUpdateInvoiceConfigurationSubtitleField,
  onUpdateInvoiceConfigurationTitleField,
  onUpdateTemplate,
  provideShowRetainerOption,
  setExpandedOptions,
  supportsTax,

  // Debtor dropdowns
  contactOptions,
  contactOptionsDataLoading,
  contactOptionsHasMore,
  onFetchContactOptions,
  onFetchMoreContactOptions,

  // Invoice Template dropdown
  invoiceSettingsTemplateEntityLoading,
  invoiceSettingsTemplateOptions,
  invoiceSettingsTemplateOptionsDataLoading,
  invoiceSettingsTemplateOptionsHasMore,
  onFetchInvoiceSettingsTemplateOptions,
  onFetchMoreInvoiceSettingsTemplateOptions,
}) => (
  <div className={Styles.draftInvoiceSettings}>
    <div className={Styles.formField}>
      <label>ISSUE DATE</label>
      <DatePicker
        value={formData.issueDate}
        hasError={formErrors.issueDate?.isInvalid}
        onSelect={(date) => {
          onUpdateField('issueDate', date);
          onUpdateField('dueDate', moment(date).add(formData.config.paymentDueDays, 'day').toDate());
        }}
      />
    </div>
    <div className={Styles.formField}>
      <label className={Styles.alignRight}>DUE DATE</label>
      <DatePicker
        value={formData.dueDate}
        hasError={formErrors.dueDate?.isInvalid}
        onSelect={(date) => onUpdateField('dueDate', date)}
      />
    </div>
    <div className={classnames(Styles.formField, Styles.fullWidth)}>
      <label>DEBTORS/CLIENTS</label>
      <div className={Styles.remainingWidth}>
        <ContactMultiSelect
          contactOptions={contactOptions}
          contactOptionsDataLoading={contactOptionsDataLoading}
          contactOptionsHasMore={contactOptionsHasMore}
          isRequired
          onContactsChanged={onUpdateContacts}
          onFetchContactOptions={onFetchContactOptions}
          onFetchMoreContactOptions={onFetchMoreContactOptions}
          selectedContacts={formData.selectedContacts}
        />
      </div>
    </div>
    <div className={classnames(Styles.fullWidth, Styles.invoiceOptions)}>
      <Button onClick={() => setExpandedOptions(!expandedOptions)} type="link" className={Styles.expandOptionsButton}>
        EDIT INVOICE OPTIONS <span className={expandedOptions ? 'icon icon-arrow-65' : 'icon icon-arrow-68'} />
      </Button>
      {expandedOptions && (
        <div className={Styles.invoiceOptions}>
          <div className={classnames(Styles.formField, Styles.fullWidth)}>
            <label>TEMPLATE</label>
            <div className={Styles.remainingWidth}>
              <Select
                className={classnames(formData.isTemplateWithDefaults && Styles.templateSelect)}
                options={invoiceSettingsTemplateOptions}
                onValueChange={(selectedTemplate) => onUpdateTemplate(selectedTemplate?.value)}
                forceValue={formData.invoiceSettingsTemplateOptionValue}
                isLoading={invoiceSettingsTemplateOptionsDataLoading || invoiceSettingsTemplateEntityLoading}
                onFocus={() => !invoiceSettingsTemplateOptions?.length && onFetchInvoiceSettingsTemplateOptions('')}
                onInputChange={(searchText) => onFetchInvoiceSettingsTemplateOptions(searchText)}
                onLoadMore={onFetchMoreInvoiceSettingsTemplateOptions}
                isClearable={false}
                noOptionsMessage={({ inputValue }) =>
                  inputValue?.length ? 'No options' : 'Start typing to get a list of templates...'
                }
                actionList={
                  invoiceSettingsTemplateOptionsHasMore === true
                    ? [
                        {
                          displayComponent: (
                            <span>
                              <i className="fa fa-plus" /> &emsp;Show more results
                            </span>
                          ),
                          callback: () => {
                            if (invoiceSettingsTemplateOptionsDataLoading) {
                              return;
                            }
                            onFetchMoreInvoiceSettingsTemplateOptions();
                          },
                        },
                      ]
                    : []
                }
              />
              <LoadingBarInfinite
                loading={invoiceSettingsTemplateEntityLoading}
                containerStyle={{ position: 'relative' }}
              />
              <div className={Styles.templateNotices}>
                {formData.config?.template?.isDeleted && (
                  <div>
                    The selected invoice template has been deleted from firm settings, but will continue to be applied
                    until&nbsp;changed.
                  </div>
                )}
                <div
                  className={Styles.resetTemplate}
                  style={formData.isTemplateWithDefaults ? { visibility: 'hidden' } : undefined}
                >
                  <LinkableText asLink text="Reset to default" onClickLink={onResetDefaultTemplate} />
                </div>
              </div>
            </div>
          </div>

          <div className={classnames(Styles.formField, Styles.fullWidth)}>
            <label>TITLE</label>
            <input
              className="form-control"
              type="text"
              value={formData.config.titleText || ''}
              onChange={(event) => onUpdateInvoiceConfigurationTitleField(event.target.value)}
            />
          </div>
          <div className={classnames(Styles.formField, Styles.fullWidth)}>
            <label>SUBTITLE</label>
            <input
              className="form-control"
              type="text"
              value={formData.config.subtitleText || ''}
              onChange={(event) => onUpdateInvoiceConfigurationSubtitleField(event.target.value)}
            />
          </div>
          {featureActive('BB-4706') && (
            <div className={classnames(Styles.formField, Styles.fullWidth)}>
              <label>NOTES</label>
              <div className={Styles.remainingWidth}>
                <TextEditor
                  className={classnames(formErrors.configOverrides?.footer?.isInvalid && 'text-editor-error')}
                  fixedHeight
                  onChange={onUpdateInvoiceConfigurationFooterField}
                  value={formData.config.footer}
                />
                {formErrors.configOverrides?.footer?.isInvalid && (
                  <div className={Styles.errorMessage}>Maximum 30 lines allowed</div>
                )}
              </div>
            </div>
          )}

          <div className={classnames(Styles.formField, Styles.fullWidth)}>
            <label />
            <div className={classnames(Styles.remainingWidth, Styles.invoiceAdditionalOptions)}>
              <InvoiceAdditionalOptions
                {...formData.config.invoiceAdditionalOptions}
                hasOpenTrustAccountsForMatter={hasOpenTrustAccountsForMatter}
                onOptionChanged={onUpdateInvoiceConfigurationField}
                provideShowRetainerOption={provideShowRetainerOption}
                supportsTax={supportsTax}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

DraftInvoiceSettings.displayName = 'DraftInvoiceSettings';

DraftInvoiceSettings.propTypes = {
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      data: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
      }),
    }),
  ).isRequired,
  contactOptionsDataLoading: PropTypes.bool.isRequired,
  contactOptionsHasMore: PropTypes.bool.isRequired,
  expandedOptions: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  hasOpenTrustAccountsForMatter: PropTypes.bool.isRequired,
  onFetchContactOptions: PropTypes.func.isRequired,
  onFetchMoreContactOptions: PropTypes.func.isRequired,
  onResetDefaultTemplate: PropTypes.func.isRequired,
  onUpdateContacts: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationFooterField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationSubtitleField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationTitleField: PropTypes.func.isRequired,
  onUpdateTemplate: PropTypes.func.isRequired,
  provideShowRetainerOption: PropTypes.bool.isRequired,
  setExpandedOptions: PropTypes.func.isRequired,
  supportsTax: PropTypes.bool.isRequired,

  // Invoice Template dropdown
  invoiceSettingsTemplateEntityLoading: PropTypes.bool.isRequired,
  invoiceSettingsTemplateOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  invoiceSettingsTemplateOptionsDataLoading: PropTypes.bool.isRequired,
  invoiceSettingsTemplateOptionsHasMore: PropTypes.bool.isRequired,
  onFetchInvoiceSettingsTemplateOptions: PropTypes.func.isRequired,
  onFetchMoreInvoiceSettingsTemplateOptions: PropTypes.func.isRequired,
};

DraftInvoiceSettings.defaultProps = {};
