'use strict';

const { chequeStylesByRegion } = require('./cheque-styles');
const { printMethodsByValue } = require('./print-methods');

/**
 * Get Default Trust Cheque Print Settings
 * @param {Object} params
 * @param {String} params.region
 * @returns Object with default print settings
 */
const getDefaultTrustChequePrintSettings = ({ region } = {}) => ({
  printingActive: false,
  alwaysPrintOnPayment: false,
  printMethod: printMethodsByValue.PrintLater.value,
  style: chequeStylesByRegion[region][0].value,
});

module.exports = {
  getDefaultTrustChequePrintSettings,
};
