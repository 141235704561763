import { fetchPostP } from '@sb-itops/redux/fetch';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import uuid from '@sb-itops/uuid';
import { store } from '@sb-itops/redux';
import { api } from './cache';
import { getDefaultTrustChequePrintSettings } from './default-settings';

const { getById: getTrustChequePrintSettingsById } = api;

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbTrustChequePrintSettingsService',
  keyPath: 'bankAccountId',
});

export const saveTrustChequePrintSettings = (trustChequeSettings) => {
  const bankAccountId = trustChequeSettings.bankAccountId;
  if (!bankAccountId) {
    throw new Error('Settings needs to have bankAccountId specified.');
  }

  const currentSettings = getTrustChequePrintSettingsById(bankAccountId) || getDefaultTrustChequePrintSettings();
  const newSettings = { ...currentSettings, ...trustChequeSettings };

  const saveTrustChequePrintSettingsThunk = async () => {
    opdateCache({ optimisticEntities: [newSettings] });

    try {
      const path = `/billing/trust-cheque-print-settings/:accountId/`;
      const payload = { versionId: uuid(), trustChequePrintSettings: newSettings };
      const fetchOptions = { body: JSON.stringify(payload) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [newSettings] });
      throw err;
    }
  };
  return store.dispatch(saveTrustChequePrintSettingsThunk);
};
