import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

class BankReconciliationTable extends React.Component {
  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick({ rowData }) {
    if (!rowData.isSetupRow) {
      this.props.onBankReconciliationLink({ bankReconciliationId: rowData.id });
    }
  }

  render() {
    const { bankReconciliations, sortDirection, sortBy } = this.props;

    return (
      <Table
        onRowClick={this.onRowClick}
        list={bankReconciliations}
        sort={this.props.sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          dataKey="endDate"
          label="Date"
          cellRenderer={yyyymmddLocalisedRenderer}
          flexGrow={2}
          disableSort={false}
        />
        <Column dataKey="description" label="Description" flexGrow={6} disableSort />
        <Column dataKey="userName" label="Created By" flexGrow={2} disableSort />
        <Column
          dataKey="closingCashBookBalance"
          className="right-align"
          label="Amount"
          flexGrow={1}
          disableSort
          cellRenderer={balanceCellLocalisedRenderer}
        />
      </Table>
    );
  }
}

BankReconciliationTable.propTypes = {
  /**
   * Data to be rendered in the table
   */
  bankReconciliations: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.number.isRequired,
      description: PropTypes.string,
      closingCashBookBalance: PropTypes.number.isRequired,
      userName: PropTypes.string,
    }),
  ).isRequired,

  /**
   * The callback to be invoked when user clicks on sort
   */
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string,

  // provided by withLinkHandler
  onBankReconciliationLink: PropTypes.func.isRequired,
  /**
   * The sort direction to be considered when sorting the column
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
};

BankReconciliationTable.defaultProps = {
  sortBy: 'endDate',
  sortDirection: 'desc',
};

BankReconciliationTable.displayName = 'BankReconciliationTable';

export default BankReconciliationTable;
