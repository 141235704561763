import React from 'react';
import PropTypes from 'prop-types';
import { getRegion } from '@sb-itops/region';
import { SubscriptionPaymentModal } from './SubscriptionPaymentModal';

const region = getRegion();

export const SubscriptionPaymentModalContainer = ({ isVisible, onClose }) => (
  <SubscriptionPaymentModal region={region} isVisible={isVisible} onClose={onClose} />
);

SubscriptionPaymentModalContainer.displayName = 'SubscriptionPaymentModalContainer';

SubscriptionPaymentModalContainer.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SubscriptionPaymentModalContainer.defaultProps = {
  isVisible: true, // set to false by default
};
