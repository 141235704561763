import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './TimeConversionInput.module.scss';

export const TimeConversionInput = ({
  className,
  convertedValue,
  disabled,
  duration,
  durationType,
  durationDisabled,
  durationHasError,
  onDurationBlur,
  onDurationChanged,
  showHrs,
  smallerInput,
}) => {
  const onInputChange = (e) => {
    if (durationType === 'HOURS') {
      return onDurationChanged(e.target.value === '.' ? '0.' : e.target.value);
    }

    if (durationType === 'UNITS') {
      return onDurationChanged(`${parseInt(e.target.value, 10)}`);
    }

    return e.target.value;
  };

  return (
    <div className={Styles.timeConversionInput}>
      <input
        type="text"
        className={classNames(
          'form-control',
          Styles.durationInput,
          durationHasError && Styles.hasError,
          smallerInput ? Styles.smallerInput : '',
        )}
        value={duration}
        name="Duration"
        disabled={durationDisabled}
        onChange={onInputChange}
        onFocus={(e) => e.target.select()}
        onBlur={() => onDurationBlur()}
      />
      <input
        className={className}
        type="text"
        value={getConvertedValueDisplay({ convertedValue, showHrs })}
        disabled={disabled}
      />
    </div>
  );
};

const getConvertedValueDisplay = ({ convertedValue, showHrs }) => {
  if (convertedValue === 'N/A' || !showHrs) {
    return convertedValue;
  }
  return `${convertedValue} hrs`;
};

TimeConversionInput.displayName = 'TimeConversionInput';

TimeConversionInput.propTypes = {
  className: PropTypes.string,
  convertedValue: PropTypes.string,
  disabled: PropTypes.bool,
  duration: PropTypes.string,
  durationType: PropTypes.oneOf(['HOURS', 'UNITS', 'FIXED', '']),
  durationDisabled: PropTypes.bool,
  durationHasError: PropTypes.bool,
  onDurationBlur: PropTypes.func,
  onDurationChanged: PropTypes.func.isRequired,
  showHrs: PropTypes.bool,
  smallerInput: PropTypes.bool,
};

TimeConversionInput.defaultProps = {
  className: '',
  convertedValue: '',
  disabled: false,
  duration: undefined,
  durationType: undefined,
  durationDisabled: false,
  durationHasError: false,
  onDurationBlur: () => {},
  showHrs: false,
  smallerInput: false,
};
