angular.module('sb.billing.webapp').directive('sbActionEntriesInvoicedExternally', function (sbLoggerService, sbInvoiceEntriesService, sbMessageDisplayService) {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      var log = sbLoggerService.getLogger('sbActionEntriesInvoicedExternally');

      ctrl.group.setAction(entriesInvoicedExternally);

      function entriesInvoicedExternally(data) {
        log.info(`data ${JSON.stringify(data)}`);
        ctrl.group.setLock(true);

        return sbInvoiceEntriesService.entriesInvoicedExternally(data)
          .then(() => {
            sbMessageDisplayService.success('Entries updated');
          })
          .catch((err) => {
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text(`Failed to update entries: ${err.message}`)
            );

            throw err;
          })
          .finally(function () {
            ctrl.group.setLock(false);
          });
      }
    }
  };
});
