'use strict';

const { getRegion } = require('@sb-itops/region');
const { getPaymentFormConfiguration: getLawpayPaymentFormConfiguration } = require('./lawpay');
const { getPaymentFormConfiguration: getStripePaymentFormConfiguration } = require('./stripe');
const { getPaymentFormConfiguration: getEzyCollectPaymentFormConfiguration } = require('./ezy-collect');
const { getPaymentFormConfiguration: getFeeWisePaymentFormConfiguration } = require('./fee-wise');
const { providers } = require('../entities/constants');

const providerSpecificPaymentFormConfigurationFns = {
  [providers.LAWPAY]: getLawpayPaymentFormConfiguration,
  [providers.STRIPE]: getStripePaymentFormConfiguration,
  [providers.EZY_COLLECT]: getEzyCollectPaymentFormConfiguration,
  [providers.FEE_WISE]: getFeeWisePaymentFormConfiguration,
};

/**
 * getPaymentFormConfiguration
 *
 * The different payment providers require different information to successfully display a payment form, i.e. the UI to make a charge.
 * This function builds and returns the payment form configuration object for the passed provider type.
 * It's assumed that such information is derived from the formatted provider specific settings per Smokeball Billing bank account.
 *
 * @param {Object} params
 * @param {Object} params.formattedProviderSpecificSettings The settings for the provider type to be used to build the configuration
 * @param {string} params.providerType The provider type for which to retrieve payment form configuration
 * @param {string} params.bankAccountId The bank account id for which to retrieve payment form configuration
 * @param {string} params.bankAccountType The bank account type for which to retrieve payment form configuration
 * @param {Object} [params.providerSpecificFields] Any provider specific fields which provider specific implementation may need
 *
 * @returns {Object} An object containing all of the configuration necessary to display a charge UI for the provider type specified.
 */
const getPaymentFormConfiguration = ({
  formattedProviderSpecificSettings,
  providerType,
  bankAccountId,
  bankAccountType,
  providerSpecificFields,
}) => {
  if (!formattedProviderSpecificSettings) {
    throw new Error('formattedProviderSpecificSettings must be provided');
  }

  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!bankAccountId) {
    throw new Error('bankAccountId must be provided');
  }

  const providerSpecificPaymentFormConfigurationFn = providerSpecificPaymentFormConfigurationFns[providerType];
  if (!providerSpecificPaymentFormConfigurationFn) {
    throw new Error(`Failed to retrieve payment form configuration for provider type '${providerType}'`);
  }

  return (
    providerSpecificPaymentFormConfigurationFn({
      formattedProviderSpecificSettings,
      bankAccountId,
      bankAccountType,
      providerSpecificFields,
      region: getRegion(),
    }) || {}
  );
};

module.exports = {
  getPaymentFormConfiguration,
};
