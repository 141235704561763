import { PAYMENT_TYPE } from 'web/redux/selectors/payment-source';
import { matterHasProtectedFundsForBankAccountId } from '@sb-billing/redux/balance-protection';
import { isMatterContactBalanceType, isMatterBalanceType } from '@sb-billing/redux/bank-account-settings';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { hasFacet, facets } from '@sb-itops/region-facets';

export const getValidateFn =
  ({ t, minAmountAllowed }) =>
  (formFields) => {
    const isTakingPaymentNow = formFields.takePaymentNow === true;
    const totalPayments = (formFields.payments || []).reduce(
      (runningTotal, invoicePayment) => runningTotal + invoicePayment.amount,
      0,
    );
    const formErrors = {};

    // amount
    const validAmount = Number.isFinite(formFields.amount) && formFields.amount > 0;
    if (!validAmount) {
      formErrors.amount = true;
    } else if (isTakingPaymentNow && formFields.amount < minAmountAllowed) {
      formErrors.amount = true;
      formErrors.amount = `Minimal amount allowed by your payment processor is ${t('cents', {
        val: minAmountAllowed,
      })}`;
    } else if (isTakingPaymentNow && (formFields.amount <= 0 || formFields.amount > totalPayments)) {
      formErrors.amount = true;
      formErrors.amount = 'Amount should be equal or less than the invoice totals selected';
    } else if (!isTakingPaymentNow && formFields.amount !== totalPayments) {
      formErrors.amount = true;
      formErrors.amount = 'Amount should be equal to the invoice totals selected';
    }

    const overdrawsAccount = validAmount && amountExceedsBalance(formFields);
    if (overdrawsAccount) {
      formErrors.amount = true;
      formErrors.amount = 'Amount exceeds available balance';
    }

    // contact
    // the contact has an error if the contactId is not present AND:
    //   1. its matter balances AND from a direct source OR
    //   2. its contact balances from any non-combined source
    if (
      !formFields.contactId &&
      ((isMatterContactBalanceType() && !formFields.source?.isCombinedBalace) ||
        (isMatterBalanceType() && formFields.source?.paymentType === PAYMENT_TYPE.direct))
    ) {
      formErrors.contactId = true;
    }

    // effective date
    if (!formFields.effectiveDate) {
      formErrors.effectiveDate = true;
    }

    // reference
    const referenceEmpty = false;
    if (referenceEmpty) {
      formErrors.reference = 'Reference is required';
    }
    // Forms 2 expects errors to be reported as flattened dot object notation.
    return Object.keys(formErrors).length ? nestedObjectToFlattened(formErrors) : {};
  };

/**
 * Returns `true` if the source is an account (trust or operating) and the account has less the amount specified by the user.
 *
 * Returns `false` if the source is not an account or the amount specified by the user is less than the account amount.
 * */
function amountExceedsBalance({ source, matterId, amount }) {
  if (source?.paymentType === PAYMENT_TYPE.direct) {
    return false;
  }

  // we allow overdraw on the balance in AU for compliance.
  if (hasFacet(facets.allowOverdraw) && !matterHasProtectedFundsForBankAccountId(matterId, source.bankAccountId)) {
    return false;
  }

  return amount > source.balance;
}
