import * as types from './types';

export const setItems = ({ itemIds, expandByDefault = false } = {}) => ({
  type: types.SET_ITEMS,
  payload: {
    itemIds,
    expandByDefault,
  },
});

export const toggleAllItems = () => ({
  type: types.TOGGLE_ALL_ITEMS,
  payload: {},
});

export const expandAllItems = () => ({
  type: types.EXPAND_ALL_ITEMS,
  payload: {
    expand: true,
  },
});

export const collapseAllItems = () => ({
  type: types.COLLAPSE_ALL_ITEMS,
  payload: {
    expand: false,
  },
});

export const toggleItem = ({ id, expandByDefault = true }) => ({
  type: types.TOGGLE_ITEM,
  payload: {
    id,
    expandByDefault,
  },
});

export const expandItem = ({ id }) => ({
  type: types.EXPAND_ITEM,
  payload: {
    id,
    expand: true,
  },
});

export const collapseItem = ({ id }) => ({
  type: types.COLLAPSE_ITEM,
  payload: {
    id,
    expand: false,
  },
});

export const reset = () => ({
  type: types.RESET_STATE,
  payload: {},
});
