import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './TabList.module.scss';
import { Button, buttonTypes } from '../button';

const TabList = memo(({ className, list, selectedTabId, onTabSelectionChange }) => (
  <div className={classnames(Styles.listContainer, className)}>
    {list.map(({ text, id, selected, disabled }) => (
      <Button
        key={id}
        type={buttonTypes.tab}
        highlight={selectedTabId === id || selected}
        onClick={() => onTabSelectionChange(id)}
        disabled={!!disabled}
      >
        {text}
      </Button>
    ))}
  </div>
));

TabList.propTypes = {
  className: PropTypes.string,
  selectedTabId: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onTabSelectionChange: PropTypes.func.isRequired,
};

TabList.defaultProps = {
  className: undefined,
  selectedTabId: undefined,
};

export default TabList;
