import React from 'react';
import PropTypes from 'prop-types';
import { LinkableText } from '@sb-itops/react/linkable-text';

const UserNameDisplay = ({ display, inline }) => <LinkableText text={display} asLink={false} inline={inline} />;

UserNameDisplay.propTypes = {
  display: PropTypes.string.isRequired,
  inline: PropTypes.bool,
};

UserNameDisplay.defaultProps = {
  inline: false,
};

export default React.memo(UserNameDisplay);
