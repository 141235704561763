import React from 'react';
import PropTypes from 'prop-types';
import { forms2PropTypes, FormLabel } from '@sb-itops/react';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { ContactMultiSelect } from '../../contact-multi-select';

// setting this to an unlikely high limit as clients and other sides has no limits technically
const NO_MAXIMUM = 1000000;

const MatterContacts = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields values
  clientCustomerIds,
  debtorIds,
  otherSideRole,
  otherSideCustomerIds,
  // form fields
  clientCustomerIdsIsRequired,
  // other
  clientRoleLabel,
  otherSideRoleLabel,
  // callbacks
  onClientsChanged,
  onDebtorsChanged,
  onOtherSidesChanged,
  onClickLink,
}) => {
  if (!formInitialised) {
    return null;
  }

  const contactOptions = getContactTypeAheadSummaries().reduce(
    (acc, { display: label, id: value, typeahead: searchText, isDeleted }) => {
      if (!isDeleted) {
        acc.push({
          value,
          label,
          searchText,
        });
      }
      return acc;
    },
    [],
  );

  // This variable is for hiding inline action link (VIEW | EDIT) space
  // so field width consistent with other sections when first load Add Matter page
  const showInlineActions = clientCustomerIds.length > 0 || debtorIds.length > 0 || otherSideCustomerIds.length > 0;

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={`Client ${clientRoleLabel && clientRoleLabel !== 'Client' ? `(${clientRoleLabel})` : ``}`}
            field={clientCustomerIdsIsRequired}
            submitFailed={submitFailed}
          />
          <div>
            <ContactMultiSelect
              selectedContactIds={clientCustomerIds}
              contactOptions={contactOptions}
              onContactsChanged={onClientsChanged}
              max={NO_MAXIMUM} // there's technically no maximum of clients allowed, so just setting this to an unlikely high number
              disabled={formDisabled}
              isRequired={submitFailed && clientCustomerIdsIsRequired && clientCustomerIdsIsRequired.value}
              addContactFormType="modal"
              onClickLink={onClickLink}
              showInlineActions={showInlineActions}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label="Debtors"
            explainerText="If left blank, the first client will be used as the debtor on this matter"
            submitFailed={submitFailed}
            optional
          />
          <div>
            <ContactMultiSelect
              selectedContactIds={debtorIds}
              contactOptions={contactOptions}
              onContactsChanged={onDebtorsChanged}
              max={3}
              isRequired={false}
              disabled={formDisabled}
              addContactFormType="modal"
              onClickLink={onClickLink}
              showInlineActions={showInlineActions}
            />
          </div>
        </div>
      </div>

      {otherSideRole && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel label={otherSideRoleLabel || 'Other Side'} submitFailed={submitFailed} optional />
            <div>
              <ContactMultiSelect
                selectedContactIds={otherSideCustomerIds}
                contactOptions={contactOptions}
                onContactsChanged={onOtherSidesChanged}
                max={NO_MAXIMUM} // there's technically no maximum of other sides allowed, so just setting this to an unlikely high number
                disabled={formDisabled}
                isRequired={false}
                addContactFormType="modal"
                onClickLink={onClickLink}
                showInlineActions={showInlineActions}
              />
            </div>
          </div>
        </div>
      )}
    </fieldset>
  );
};

MatterContacts.displayName = 'MatterContacts';

const { Forms2Field } = forms2PropTypes;

MatterContacts.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  clientCustomerIds: PropTypes.arrayOf(PropTypes.string),
  debtorIds: PropTypes.arrayOf(PropTypes.string),
  otherSideRole: PropTypes.string,
  otherSideCustomerIds: PropTypes.arrayOf(PropTypes.string),
  // form fields
  clientCustomerIdsIsRequired: Forms2Field,
  // other
  clientRoleLabel: PropTypes.string,
  otherSideRoleLabel: PropTypes.string,
  // callbacks
  onClientsChanged: PropTypes.func.isRequired,
  onDebtorsChanged: PropTypes.func.isRequired,
  onOtherSidesChanged: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

MatterContacts.defaultProps = {
  // form state
  formDisabled: false,
  formSubmitting: false,
  submitFailed: false,
  // form fields values
  clientCustomerIds: [],
  debtorIds: [],
  otherSideRole: undefined,
  otherSideCustomerIds: [],
  // form fields
  clientCustomerIdsIsRequired: undefined,
  // other
  clientRoleLabel: undefined,
  otherSideRoleLabel: undefined,
};

export default MatterContacts;
