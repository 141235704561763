'use strict';

/**
 * @typedef { import('../activities/types').Activity } Activity
 * @typedef { import('./types').StaffRateConfig } StaffRateConfig
 * @typedef { import('./types').MatterHourlyRate } MatterHourlyRate
 */

const { rateOverrideTypes } = require('./rate-override-types');
const { findCurrentAndFutureVersions } = require('../rate-sets');

/**
 * Derive activity rate based on activity config
 *
 * Semantically fee and time entries are different things, but as the setting for both types
 * are stored in the same data structure, it makes sense to keep this in one place
 * @returns {number|undefined} The applicable activity rate, or undefined if no rate can be found.
 */
const deriveActivityRate = ({ staffId, activity }) => {
  // Notes
  // 1. NO_OVERRIDE is an option for time entries only, not for fixed fees
  // 2. rate returned for time entries are hourly rates
  // 3. rate returned for fixed fee entries are flat rates (fixed amount)
  const { rateOverrideType, allStaffRate, ratesPerStaff } = activity;

  if (staffId && rateOverrideType === rateOverrideTypes.CUSTOM_STAFF_OVERRIDE) {
    const overrideForStaffMember = ratesPerStaff?.find((staffRateOverride) => staffRateOverride.staffId === staffId);

    if (overrideForStaffMember?.rate || overrideForStaffMember?.rate === 0) {
      return overrideForStaffMember.rate;
    }
  }

  // For backward compatibility, rateOverrideType (rateType in DB) may not be defined
  // Before this field was introduced, only allStaffRate (rate in DB) was available
  if (Number.isFinite(allStaffRate) || rateOverrideType === rateOverrideTypes.ALL_STAFF_OVERRIDE) {
    return allStaffRate || allStaffRate === 0 ? allStaffRate : undefined;
  }

  return undefined;
};

const deriveMatterRate = ({ staffId, matterHourlyRate }) => {
  const { rateOverrideType, allStaffRate, ratesPerStaff, rateSet } = matterHourlyRate;

  if (staffId && rateOverrideType === rateOverrideTypes.CUSTOM_STAFF_OVERRIDE) {
    const overrideForStaffMember = ratesPerStaff?.find((staffRateOverride) => staffRateOverride.staffId === staffId);

    if (overrideForStaffMember?.rate || overrideForStaffMember?.rate === 0) {
      return overrideForStaffMember.rate;
    }
  }

  // For backward compatibility, rateOverrideType (rateType in DB) may not be defined
  // Before this field was introduced, only allStaffRate (rate in DB) was available
  if (Number.isFinite(allStaffRate) || rateOverrideType === rateOverrideTypes.ALL_STAFF_OVERRIDE) {
    return allStaffRate || allStaffRate === 0 ? allStaffRate : undefined;
  }

  if (rateOverrideType === rateOverrideTypes.RATE_SET_OVERRIDE) {
    if (rateSet) {
      const { currentVersion } = findCurrentAndFutureVersions({ rateSet });
      return currentVersion?.ratesPerStaff // customRatesPerStaff in DB
        ? currentVersion.ratesPerStaff.find((staffRate) => staffRate.staffId === staffId)?.rate
        : undefined;
    }
  }

  return undefined;
};

/**
 * Derive rate using the following order of precendence
 * 1. Matter/specific staff member override in the matter billing config
 * 2. Matter/all staff override in the matter billing config
 * 3. Activity/specific staff member override in the activity config
 * 4. Activity/all staff override in the activity config
 * 5. Staff member default rate in the staff config
 *
 * Adapted from monorepo/billing/business-logic/activities/services/derive-activity-rate.js
 *
 * @param {object} params
 * @param {string} params.staffId
 * @param {Activity} [params.activity]
 * @param {number} [params.staffRate]
 * @param {MatterHourlyRate} [params.matterHourlyRate]
 */
const deriveRate = ({ staffId, activity, staffRate, matterHourlyRate }) => {
  if (!staffId) {
    throw new Error('staffId is required');
  }

  // Derive rate using activity rate configuration.
  if (activity) {
    const activityRate = deriveActivityRate({
      activity,
      staffId,
    });

    if (activityRate !== undefined) {
      return activityRate;
    }
  }

  // Derive rate using matter rate configuration.
  if (matterHourlyRate) {
    const matterRate = deriveMatterRate({
      matterHourlyRate,
      staffId,
    });

    if (matterRate !== undefined) {
      return matterRate;
    }
  }

  // Otherwise use staff default rate.
  if (staffRate || staffRate === 0) {
    return staffRate;
  }

  return undefined;
};

module.exports = {
  deriveRate,
};
