import React from 'react';
// import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { Button, Spinner } from '@sb-itops/react';
import Styles from './StaffPermissions.module.scss';

const { checkboxCellWrapperRenderer } = utils;

const checkboxCellRenderer =
  ({ permissionId, onPermissionsChange, permissionIds, isLoading, permissions }) =>
  ({ rowData }) => {
    if (!rowData.userId || (!isLoading && rowData.userId && !permissions[rowData.userId]?.permissions)) {
      // Staff without access do SBB have no userId so we can't set the permission. They should be
      // filtered out already but just in case. We also don't want to display the checkbox if we don't have
      // permissions object after loading - which probably means we weren't able to fetch them for some reason.
      return null;
    }

    const userPermissions = permissions[rowData.userId]?.permissions || {};
    if (permissionId === permissionIds.BILLING_DATA_FIRM_ACCOUNTS) {
      return (
        <Checkbox
          checked={userPermissions[permissionIds.BILLING_DATA_FIRM_ACCOUNTS]}
          disabled={rowData.isOwner || !rowData.userId || isLoading}
          onChange={(value) => {
            onPermissionsChange({
              [rowData.userId]: {
                ...userPermissions,
                [permissionIds.BILLING_DATA_FIRM_ACCOUNTS]: value,
              },
            });
          }}
        />
      );
    }
    if (permissionId === permissionIds.BILLING_DATA_FIRM_REPORTS) {
      return (
        <Checkbox
          checked={userPermissions[permissionIds.BILLING_DATA_FIRM_REPORTS]}
          disabled={rowData.isOwner || !rowData.userId || isLoading}
          onChange={(value) => {
            onPermissionsChange({
              [rowData.userId]: {
                ...userPermissions,
                [permissionIds.BILLING_DATA_FIRM_REPORTS]: value,
              },
            });
          }}
        />
      );
    }

    return null;
  };

const checkboxHeaderCellRenderer =
  ({ permissionId, isLoading, permissionIds, permissions, onPermissionsChange }) =>
  ({ label }) => {
    const userIds = Object.keys(permissions);
    const allSelected = userIds.every(
      (userId) =>
        permissions[userId].permissions[permissionIds.BILLING_FIRM_OWNER] ||
        permissions[userId].permissions[permissionId],
    );

    const toggleAll = (targetValue) => {
      const permissionsToToggle = userIds.reduce((acc, userId) => {
        if (permissions[userId].permissions[permissionIds.BILLING_FIRM_OWNER]) {
          return acc; // we can't change owner's permissions
        }

        const userPermissions = permissions[userId].permissions || {};
        if (userPermissions[permissionId] !== targetValue) {
          acc[userId] = {
            ...userPermissions,
            [permissionId]: targetValue,
          };
        }

        return acc;
      }, {});

      onPermissionsChange(permissionsToToggle);
    };

    if (permissionId === permissionIds.BILLING_DATA_FIRM_ACCOUNTS) {
      return (
        <>
          {checkboxCellWrapperRenderer(Checkbox)({
            disabled: isLoading,
            checked: !isLoading && allSelected,
            onChange: () => {
              toggleAll(!allSelected);
            },
          })}
          <div>{label}</div>
        </>
      );
    }
    if (permissionId === permissionIds.BILLING_DATA_FIRM_REPORTS) {
      return (
        <>
          {checkboxCellWrapperRenderer(Checkbox)({
            disabled: isLoading,
            checked: !isLoading && allSelected,
            onChange: () => {
              toggleAll(!allSelected);
            },
          })}
          <div>{label}</div>
        </>
      );
    }

    return null;
  };

const StaffPermissions = React.memo(
  ({
    userList,
    isSaving,
    isLoading,
    onPermissionsChange,
    onSavePermissions,
    permissionIds,
    permissions,
    savingAllowed,
  }) => (
    <div className={Styles.staffPermissions}>
      <Table
        className="table-shadow"
        style={{
          height: `${Math.min(600, 30 + userList.length * 38)}px`,
        }}
        list={userList}
        dataLoading={isLoading}
      >
        <Column
          dataKey="name"
          label="Name"
          flexGrow={6}
          disableSort
          // eslint-disable-next-line react/no-unstable-nested-components
          cellRenderer={({ rowData }) =>
            rowData.isOwner ? (
              <>
                {rowData.name} <span className={Styles.nameBadge}>FIRM OWNER</span>
              </>
            ) : (
              rowData.name
            )
          }
        />
        <Column flexGrow={6} dataKey="email" label="Email" disableSort />
        <Column
          width={150}
          dataKey=""
          label="Global Accounts"
          headerRenderer={checkboxHeaderCellRenderer({
            permissionId: permissionIds.BILLING_DATA_FIRM_ACCOUNTS,
            isLoading,
            permissionIds,
            permissions,
            onPermissionsChange,
          })}
          cellRenderer={checkboxCellWrapperRenderer(
            checkboxCellRenderer({
              permissionId: permissionIds.BILLING_DATA_FIRM_ACCOUNTS,
              onPermissionsChange,
              permissionIds,
              isLoading,
              permissions,
            }),
          )}
          className={Styles.checkboxColumn}
          disableSort
        />
        <Column
          width={150}
          dataKey=""
          label="Reports"
          headerRenderer={checkboxHeaderCellRenderer({
            permissionId: permissionIds.BILLING_DATA_FIRM_REPORTS,
            isLoading,
            permissionIds,
            permissions,
            onPermissionsChange,
          })}
          cellRenderer={checkboxCellWrapperRenderer(
            checkboxCellRenderer({
              permissionId: permissionIds.BILLING_DATA_FIRM_REPORTS,
              onPermissionsChange,
              permissionIds,
              isLoading,
              permissions,
            }),
          )}
          className={Styles.checkboxColumn}
          disableSort
        />
      </Table>

      <Button
        className={Styles.saveButton}
        onClick={onSavePermissions}
        disabled={isSaving || isLoading || !savingAllowed}
        size="full-width"
      >
        Save {isSaving && <Spinner small />}
      </Button>
    </div>
  ),
);

export default StaffPermissions;
