'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const titleLineTypes = Object.freeze({
  NONE: 0,
  MATTER_DESCRIPTION: 1,
  CUSTOM: 2,
  MATTER_TITLE: 3,
});

const titleLineTypeLabels = Object.freeze({
  NONE: 'None',
  MATTER_DESCRIPTION: 'Matter Description',
  CUSTOM: 'Custom Text',
  MATTER_TITLE: 'Matter Title',
});

const titleLineTypeOptions = getTypeAsOptions({
  typeLabels: titleLineTypeLabels,
  typeValues: titleLineTypes,
});

module.exports = {
  titleLineTypes,
  titleLineTypeLabels,
  titleLineTypeOptions,
};
