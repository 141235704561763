import {
  TOGGLE_SHOW_FILTERS,
  SET_FILTER,
  RESET_FILTER,
  SELECT_REQUEST,
  SET_REQUESTS,
  SELECT_ALL_REQUEST,
} from './types';

const INITIAL_STATE = {
  showFilters: true,
  filters: {
    request: 'all',
    unpaid: 'all',
    hideMattersAboveMinimumThreshold: true,
    enableRequestOlderThanFilter: true,
    hideIfLastRequestOlderThanDays: 30,
    enableReplenishAmountFilter: true,
    showIfReplenishAmountGreaterThanAmount: 0,
  },
  filterOptions: {
    request: [
      {
        display: 'All',
        value: 'all',
      },
      {
        display: 'Requested',
        value: 'requested',
      },
      {
        display: 'Unrequested',
        value: 'unrequested',
      },
    ],
    unpaid: [
      {
        display: () => 'All',
        value: 'all',
      },
      {
        display: (t) => `Greater than ${t('currencySymbol')}0`,
        value: 'above',
      },
    ],
  },
  selectedRequests: {},
  sortBy: 'lastRequestDate',
  sortDirection: 'desc',
  requests: [],
  sortData: {},
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case RESET_FILTER: {
      return {
        ...state,
        filters: INITIAL_STATE.filters,
      };
    }
    case SET_FILTER: {
      const result = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
      return result;
    }
    case SELECT_REQUEST: {
      const selected = !state.selectedRequests[action.payload.request.matter.matterId];
      const selectedRequests = {
        ...state.selectedRequests,
        [action.payload.request.matter.matterId]: selected,
      };

      if (!selected) {
        delete selectedRequests[action.payload.request.matter.matterId];
      }

      return {
        ...state,
        selectedRequests,
      };
    }
    case SELECT_ALL_REQUEST: {
      const selectedRequests = action.payload.selected
        ? state.requests.reduce((all, request) => {
            // select only those retainers where email request is allowed
            if (request.matter.emailRequestEnabled) {
              // eslint-disable-next-line no-param-reassign
              all[request.matter.matterId] = action.payload.selected;
            }
            return all;
          }, {})
        : {};

      return {
        ...state,
        selectedRequests,
      };
    }
    case SET_REQUESTS: {
      const result = {
        ...state,
        ...action.payload,
      };

      /**
       * This is necessary to handle multi-user scenarios where another user may be
       * 1) depositing more funds into a matter
       * 2) modifying retainer min threshold setting
       *
       * In these scenarios, it could result in a matter already selected for email
       * now no longer needs to be emailed because the matter is above min threshold.
       *
       * This will ensure other users already on the retainer requests tab
       * who has selected a matter that was under min threshold for emailling would
       * have that matter automatically deselected for them.
       */
      const selectedRequests = result.selectedRequests;
      result.requests.forEach((request) => {
        // make sure to unselected requests for matter which has gone above min threshold
        if (request.matter.isAboveMinimumThreshold && selectedRequests[request.matter.matterId]) {
          delete selectedRequests[request.matter.matterId];
        }
      });

      result.selectedRequests = selectedRequests;

      return result;
    }
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
