import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { dateToInteger, integerToDate } from '@sb-itops/date';

import CmaListingFilter from './CmaListingFilter';

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const mapStateToProps = (state, { filters }) => ({
  filters: filters
    ? {
        ...filters,
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      onFiltersChanged({
        endDate: dateToYYYYMMDD(new Date()),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        errors: {},
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        endDate,
        errors: {
          ...filters.errors,
          endDate: !endDate,
        },
      });
    }
  },

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const CmaListingFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(CmaListingFilter)),
);

CmaListingFilterContainer.displayName = `CmaListingFilterContainer`;

export default CmaListingFilterContainer;
