import { gql } from '@apollo/client';

const query = gql`
  query InvoiceCommunicateModalData($ids: [ID]) {
    invoices(ids: $ids) {
      id
      debtors {
        id
        contact {
          id
          customLetterName
          displayNameFirstLast
          email
          firstName
          lastName
          letterNameFormat
          people {
            id
            email
            firstName
            lastName
            letterNameFormat
            title
            type
          }
          title
          type
        }
      }
      entries {
        id
        feeEntity {
          id
          duration
          feeType
        }
      }
      matter {
        id
        matterEmailSettings {
          id
          bccAddresses
          ccAddresses
        }
        matterInvoiceSettings {
          id
          eInvoiceEnabledOption
          payButtonEnabledOption
        }
      }
    }
    firmEInvoiceSettings {
      id
      eInvoiceDisabled
    }
    firmUsersList {
      id
      users {
        id
        person {
          name
        }
      }
    }
  }
`;

const notificationIds = [
  'FirmManagementNotifications.FirmUsersUpdated', // Adding firm user
  'FirmManagementNotifications.PersonUpdated', // Updating firm user
  'InvoicingNotifications.EInvoiceSettingsUpdated',
  'InvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceCommunicateModalData = {
  query,
  notificationIds,
};
