/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { LinkableText } from '@sb-itops/react';
import Styles from './ProtectedFundsTable.module.scss';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

const cellRenderer = ({ cellData }) => {
  if (!cellData) {
    return null;
  }
  return cellData;
};

export const ProtectedFundsTable = memo(
  ({
    protectedFunds,
    sortBy,
    sortDirection,
    onSort,
    onClickLink,
    onUnprotectClick,
    matterId,
    supportsMatterContactBalance,
  }) => (
    <Table list={protectedFunds} sortBy={sortBy} sortDirection={sortDirection} sort={onSort}>
      <Column dataKey="effectiveDate" width={100} label="Date" cellRenderer={yyyymmddLocalisedRenderer} />
      {!matterId && (
        <Column dataKey="matterDisplay" label="Matter" flexGrow={3} cellRenderer={matterRenderer({ onClickLink })} />
      )}
      {supportsMatterContactBalance && (
        <Column dataKey="contactDisplay" label="Contact" flexGrow={3} cellRenderer={contactRenderer({ onClickLink })} />
      )}
      <Column dataKey="reason" label="Reason" cellRenderer={cellRenderer} flexGrow={3} />
      <Column
        dataKey="amount"
        className={Styles.numericColumn}
        label="Amount"
        cellRenderer={balanceCellLocalisedRenderer}
        flexGrow={1}
      />
      <Column
        dataKey="actions"
        label="Actions"
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer={({ rowData }) => (
          <div className="link-button" onClick={() => onUnprotectClick(rowData.id)}>
            Unprotect
          </div>
        )}
        width={100}
        disableSort
      />
    </Table>
  ),
);

const matterRenderer =
  ({ onClickLink }) =>
  ({ rowData }) =>
    (
      <LinkableText
        key={rowData.matterId}
        text={rowData.matterDisplay}
        asLink
        inline
        onClickLink={() => onClickLink({ type: 'matter', id: rowData.matterId })}
        tooltip={rowData.matterDisplay}
      />
    );

const contactRenderer =
  ({ onClickLink }) =>
  ({ rowData }) =>
    (
      <LinkableText
        key={rowData.contactId}
        text={rowData.contactDisplay}
        asLink
        inline
        onClickLink={() => onClickLink({ type: 'contact', id: rowData.contactId })}
      />
    );

ProtectedFundsTable.propTypes = {
  protectedFunds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      contactId: PropTypes.string,
      matterId: PropTypes.string,
      reason: PropTypes.string,
      amount: PropTypes.number,
      timestamp: PropTypes.string,
    }),
  ).isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
  onUnprotectClick: PropTypes.func.isRequired,
  matterId: PropTypes.string,
  supportsMatterContactBalance: PropTypes.bool.isRequired,
};

ProtectedFundsTable.defaultProps = {
  sortBy: 'timestamp',
  sortDirection: 'asc',
  onClickLink: () => {},
  matterId: undefined,
};
