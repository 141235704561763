import { HTTP_METHOD } from '@sb-itops/fetch-wrapper-browser';
import uuid from '@sb-itops/uuid';
import { operationStart, operationSuccess, operationFail, operationClear } from '../operation';
import store from '../store';
import { fetchPostP, fetchGetP, fetchDeleteP, RESPONSE_TYPE } from '.';

/**
 * @typedef {object} Thunk_Options
 * @property {string} path the path to the resoure. The token :accountId wil be replaced with the firm account id.
 * @property {HTTP_METHOD} method
 * @property {object} fetchOptions proxy for init https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters
 * @property {RESPONSE_TYPE} responseType
 * @property {boolean} skipCamelCase boolean
 * @property {function} opdateCaches runs opdate logic and returns a function to rollback the opdates on error
 */

/**
 * Generic pattern for fetch operations.
 * @param {Thunk_Options} options
 * @returns A function that accepts an optional body to fetch. When invoked, it dispatches a thunk to fetch using the specified
 * HTTP_METHOD and returns immediately with a unique operation id. The oeration id can be used to determine the outcome of the fetch
 * via @sb-itops/redux/operation.
 * @see module:@sb-itops/redux/fetch~doFetch
 * @see module:@sb-itops/redux/operation
 */
export const fetchThunkFactory =
  ({
    path,
    method,
    fetchOptions: options = {},
    responseType = RESPONSE_TYPE.json,
    skipCamelCase = false,
    opdateCaches = noop,
  }) =>
  (body) => {
    const operationId = uuid();

    const thunk = async () => {
      operationStart(operationId);
      const rollbackOpdates = opdateCaches(body) || noop;

      try {
        const fetchOptions = { ...options };

        if (body) {
          fetchOptions.body = options.body || JSON.stringify(body);
        }

        await fetch[method]({
          path,
          fetchOptions,
          responseType,
          skipCamelCase,
        });

        operationSuccess(operationId);
      } catch (err) {
        operationFail(operationId);
        rollbackOpdates();
      }

      // clients have 1 tick to access operation state
      setTimeout(() => operationClear(operationId));
    };

    store.dispatch(thunk);

    return operationId;
  };

/**
 * Convenience method to set the HTTP_METHOD
 * @see fetchThunkFactory
 */
export const fetchGetThunkFactory = ({ path, fetchOptions, responseType, skipCamelCase, opdateCaches }) =>
  fetchThunkFactory({ path, method: HTTP_METHOD.get, fetchOptions, responseType, skipCamelCase, opdateCaches });

/**
 * Convenience method to set the HTTP_METHOD
 * @see fetchThunkFactory
 */
export const fetchPostThunkFactory = ({ path, fetchOptions, responseType, skipCamelCase, opdateCaches }) =>
  fetchThunkFactory({ path, method: HTTP_METHOD.post, fetchOptions, responseType, skipCamelCase, opdateCaches });

/**
 * Convenience method to set the HTTP_METHOD
 * @see fetchThunkFactory
 */
export const fetchDeleteThunkFactory = ({ path, fetchOptions, responseType, skipCamelCase, opdateCaches }) =>
  fetchThunkFactory({ path, method: HTTP_METHOD.delete, fetchOptions, responseType, skipCamelCase, opdateCaches });

const noop = () => () => {};

const fetch = {
  [HTTP_METHOD.get]: fetchGetP,
  [HTTP_METHOD.post]: fetchPostP,
  [HTTP_METHOD.delete]: fetchDeleteP,
};
