import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from '@sb-itops/react/modal-dialog';

export const DeleteCardModal = ({ onProceed, onClose, modalId, cardInUse }) => (
  <ModalDialog
    modalId={modalId}
    body={getModalBody({ cardInUse })}
    title="Delete credit card"
    buttons={{
      primary: {
        text: 'Delete',
        props: {
          type: 'danger',
        },
      },
      secondary: {
        text: 'Cancel',
      },
    }}
    onPrimaryClick={onProceed}
    onSecondaryClick={onClose}
    onCloseClick={onClose}
  />
);

const getModalBody = ({ cardInUse }) =>
  cardInUse ? (
    <div>
      This card is currently linked to an active payment plan. If you remove this card then the payment plan will be set
      back to manual payments. Do you wish to delete the card?
    </div>
  ) : (
    <div>Are you sure you want to delete this card?</div>
  );

DeleteCardModal.propTypes = {
  onProceed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  cardInUse: PropTypes.bool.isRequired,
};
