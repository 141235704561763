import { getSubscriptionServiceUrl } from '@sb-itops/environment-config';
import { httpPost } from '../network/http';

export const setDefaultPaymentMethod = async ({ setupIntentId, tierId, stripeProductId }) => {
  const getSmokeballSubscriptionUrl = `${getSubscriptionServiceUrl()}/sign-up/set-default-payment-method`;
  const body = { setupIntentId, tierId, stripeProductId };
  const response = await httpPost({ url: getSmokeballSubscriptionUrl, body });
  // do not add any opdate here as cache may not be initialised yet when
  // this is used in the update payment method scenario
  return response;
};
