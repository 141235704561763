import { bankAccountTypeEnum as bankAccountTypes } from '@sb-billing/business-logic/bank-account/entities/constants';
import { sortByProperty } from '@sb-itops/nodash';

/**
 *
 * @typedef {Object} FeeWiseAccountOptions
 * @property {Array.<{label: string, value: string}>} TRUST
 * @property {Array.<{label: string, value: string}>} OPERATING
 * @property {Object.<string, {label: string, value: string}>} LINKED
 *
 * @param {Object} params
 * @param {Array.<{id: string, name: string, accountType: ('Trust'|'Office')}>} params.feeWiseAccounts
 * @param {Object.<string, {feeWiseAccountId: string, bankAccountId: string, bankAccountType: string}>} params.linkedAccounts
 * @returns {FeeWiseAccountOptions}
 */
export const formatFeeWiseAccountOptions = ({ feeWiseAccounts, linkedAccounts }) => {
  const linkedAccountsValues = Object.values(linkedAccounts || {});
  const accountsMap = (feeWiseAccounts || []).reduce(
    (acc, feeWiseAccount) => {
      const found = linkedAccountsValues.find((accountLink) => accountLink.feeWiseAccountId === feeWiseAccount.id);
      if (found) {
        acc.LINKED[found.bankAccountId] = {
          label: feeWiseAccount.name,
          value: feeWiseAccount.id,
        };
        return acc;
      }
      // FeeWise has 2 types: "Trust" and "Operating"
      const accountType = feeWiseAccount.accountType === 'Trust' ? bankAccountTypes.TRUST : bankAccountTypes.OPERATING;
      acc[accountType].push({
        label: feeWiseAccount.name,
        value: feeWiseAccount.id,
      });
      return acc;
    },
    {
      [bankAccountTypes.TRUST]: [],
      [bankAccountTypes.OPERATING]: [],
      LINKED: {},
    },
  );

  const trustOptionsSorted = sortByProperty(
    accountsMap[bankAccountTypes.TRUST],
    'label',
    'asc',
    false /* caseSensitive */,
  );

  return {
    ...accountsMap,
    [bankAccountTypes.TRUST]: trustOptionsSorted,
  };
};
