'use strict';

const { billingType } = require('../../matters/billing-config');
const { durationType: activityDurationTypes } = require('../../shared/entities');

/**
 * Determines the default billable / not billable status based on a matter and duration type per BB-7588.
 * https://smokeball.atlassian.net/browse/BB-7588
    Non Billable Matters: always Non Billable
    Time Based Matters: billable if Activity.Billable == TRUE (explicit settings)
    Non Time Based Billable Matters (FF, Contingency): billable if Activity.EntryType == Fixed
    Only applicable for new entry, skip for editing
 * @param {Object} param
 * @param {Object} param.matterBillingType
 * @param {Object} param.activity
 * @param {bool} param.durationType 
 */
const deriveNewFeeBillableStatus = ({ matterBillingType, activity, taskCode, durationType }) => {
  if (matterBillingType) {
    if (matterBillingType === billingType.NOT_BILLABLE) {
      return false;
    }

    if (
      [
        billingType.FIXED_FEE,
        billingType.FIXED_FEE_PER_APPEARANCE,
        billingType.CONTINGENCY_$,
        billingType.CONTINGENCY_P,
        billingType.CONDITIONAL_FEE_AGREEMENT,
      ].includes(matterBillingType)
    ) {
      return durationType === activityDurationTypes.FIXED;
    }
  }

  const hasBillableActivity = activity && !!activity.isBillable;
  const hasBillableTaskCode = taskCode && taskCode.isBillable !== false; // Standard utbms task codes don't have `isBillable`, they are billable by default

  // Fee is billable when:
  // - no activity and no task code
  // - no activity and task code is billable
  // - activity is billable and no task code
  // - activity is billable and task code is billable
  const isFeeBillable =
    (!activity && !taskCode) ||
    (!activity && hasBillableTaskCode) ||
    (!taskCode && hasBillableActivity) ||
    (hasBillableActivity && hasBillableTaskCode);

  return isFeeBillable;
};

module.exports = {
  deriveNewFeeBillableStatus,
};
