const { getRegion, regionType } = require('@sb-itops/region');
// const { schemeMappings } = require('@sb-itops/region-schemes');
const { isAbbreviatedTitle } = require('./is-abbreviated-title');

const getContactSalutationGeneric = (contact) => {
  if (!contact) {
    return null;
  }

  if (!contact.person) {
    return contact.entityName;
  }

  const person = contact.person;

  switch (person.letterNameFormat) {
    case 0: {
      const title = contact.person.title;
      return `${title ? `${title} ` : ''}${!title ? `${person.firstName} ` : ''}${person.lastName}`;
    }
    case 1: {
      return person.firstName;
    }
    case 2: {
      return person.customLetterName;
    }
    default: {
      return contact.entityName;
    }
  }
};

const getContactSalutationUS = (contact) => {
  if (!contact) {
    return null;
  }

  if (!contact.person) {
    return contact.entityName;
  }

  const person = contact.person;

  switch (person.letterNameFormat) {
    case 0: {
      let title = contact.person.title;
      if (title && !title.endsWith('.') && isAbbreviatedTitle(title)) {
        title += '.';
      }
      return `${title ? `${title} ` : ''}${!title ? `${person.firstName} ` : ''}${person.lastName}`;
    }
    case 1: {
      return person.firstName;
    }
    case 2: {
      return person.customLetterName;
    }
    default: {
      return contact.entityName;
    }
  }
};

const getContactSalutationStrategy = {
  [regionType.AU]: getContactSalutationGeneric,
  [regionType.GB]: getContactSalutationGeneric,
  [regionType.US]: getContactSalutationUS,
};

function getContactSalutation(contact) {
  if (!getContactSalutationStrategy[getRegion()]) {
    throw new Error('Unsupported region');
  }
  return getContactSalutationStrategy[getRegion()](contact);
}

module.exports = {
  getContactSalutation,
};
