import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getById as getMatterById, getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getLogger } from '@sb-itops/fe-logger';

import { MattersDisplay } from './MattersDisplay';

const log = getLogger('MattersDisplay');

const mapStateToProps = (
  state,
  { className, matterIds, allowDuplicates, showStatus, showStatusPill, tooltip, asLink, inline, onClickLink, t },
) => {
  const processedMatterIds = new Set();
  const matterDisplays = matterIds.reduce((acc, matterId) => {
    if (!allowDuplicates && processedMatterIds.has(matterId)) {
      return acc;
    }

    processedMatterIds.add(matterId);

    const matter = getMatterById(matterId);
    const status = matter ? t((matter.status || '').toLowerCase()) : '';

    if (!status) {
      log.warn(`Unable to get status for matterId ${matterId}`);
    }

    acc.push({
      matterId,
      display: getMatterDisplayById(matterId, showStatus),
      status,
    });
    return acc;
  }, []);

  return {
    className,
    asLink,
    inline,
    showStatusPill,
    matterDisplays,
    tooltip: tooltip || matterDisplays.map(({ display }) => `${display}`).join('\n\n'),
    onClickLink: ({ matterId }) => onClickLink({ type: 'matter', id: matterId }),
  };
};

export const MattersDisplayContainer = withReduxStore(withTranslation()(connect(mapStateToProps)(MattersDisplay)));

MattersDisplayContainer.displayName = 'MattersDisplayContainer';

MattersDisplayContainer.propTypes = {
  className: PropTypes.string,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowDuplicates: PropTypes.bool,
  showStatus: PropTypes.bool,
  showStatusPill: PropTypes.bool,
  tooltip: PropTypes.string,
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  onClickLink: PropTypes.func,
};

MattersDisplayContainer.defaultProps = {
  className: undefined,
  allowDuplicates: false,
  showStatus: false,
  showStatusPill: false,
  tooltip: undefined,
  asLink: false,
  inline: true,
  onClickLink: () => {},
};
