import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyInput2, Button, forms2PropTypes } from '@sb-itops/react';
import { DropDownList } from '@sb-itops/react/drop-down-list';
import { DatePicker } from '@sb-itops/react/date-picker.2';
import moment from 'moment';
import { ContactTypeahead } from '@sb-customer-management/react';
import { CreditCardSaveForm } from 'web/react-redux/components/credit-card-save-form';
import * as messageDisplay from '@sb-itops/message-display';

import { featureActive } from '@sb-itops/feature';
import Styles from './PaymentPlanDetails.module.scss';

const getNextInstallmentDue = (yyyymmdd) => {
  if (!yyyymmdd) {
    return moment().startOf('day').toDate();
  }
  return moment(yyyymmdd, 'YYYYMMDD').toDate();
};

const PaymentPlanDetails = memo(
  ({
    selectedPaymentPlanId,
    debtor,
    installmentAmount,
    installmentFrequency,
    paymentMethod,
    nextInstallmentDate,
    installmentFrequencyOptions,
    paymentMethodOptions,
    onValueChange,
    isDebtorDisabled,
    relatedContacts,
    creditCardFormProps,
    onAddCreditCard,
  }) => {
    const nextInstallmentDue = nextInstallmentDate && getNextInstallmentDue(nextInstallmentDate.value);

    const onDebtorChangeHandler = useCallback(
      (selectedDebtor) => {
        onValueChange('debtor', selectedDebtor && selectedDebtor.value);
      },
      [onValueChange],
    );

    const onIndividualPaymentChangeHandler = useCallback(
      (event) => {
        if (event.preventDefault) event.preventDefault();
        onValueChange('installmentAmount', event.target.value);
      },
      [onValueChange],
    );

    const onFrequencyChangeHandler = useCallback(
      (selectedFrequency) => {
        onValueChange('installmentFrequency', selectedFrequency.value);
      },
      [onValueChange],
    );

    const onNextInstallmentDateChangeHandler = useCallback(
      (date) => {
        onValueChange('nextInstallmentDate', moment(date).format('YYYYMMDD')); // string YYYYMMDD
      },
      [onValueChange],
    );

    const onPaymentMethodChangeHandler = useCallback(
      (newPaymentMethod) => {
        onValueChange('paymentMethod', newPaymentMethod.value);
      },
      [onValueChange],
    );

    const onSubmitCreditCard = useCallback(
      async (saveFormData) => {
        const resetSubmit = () => {
          creditCardFormProps.setIsSubmittingCreditCard(false);
          creditCardFormProps.setTriggerSubmit(false);
        };
        creditCardFormProps.setIsSubmittingCreditCard(true);
        try {
          await onAddCreditCard(saveFormData);
          creditCardFormProps.setShowCreditCardForm(false);
          messageDisplay.success('Card saved successfully.');
          resetSubmit();
        } catch (err) {
          resetSubmit();
          if (err?.payload?.body?.message) {
            messageDisplay.error(err.payload.body.message);
          } else {
            messageDisplay.error('Failed to save the card; please contact support for assistance.');
          }
        }
      },
      [creditCardFormProps, onAddCreditCard],
    );

    const onIsReadyForSubmit = (ready) => {
      creditCardFormProps.setIsReadyForSubmit(ready);
    };

    const onTriggerSubmit = () => {
      creditCardFormProps.setIsSubmittingCreditCard(true);
      creditCardFormProps.setTriggerSubmit(true);
    };

    const selectedPaymentMethod = paymentMethod && paymentMethodOptions.find((op) => op.value === paymentMethod.value);

    return (
      <fieldset name="PaymentPlanDetails" className={Styles.paymentPlanDetails}>
        <div className={Styles.container}>
          <div className={Styles.row}>
            <div
              className={classnames(
                Styles.cell,
                Styles.cell50,
                debtor && debtor.isDirty && debtor.isInvalid && Styles.hasError,
              )}
            >
              <label>Debtor</label>
              <ContactTypeahead
                onContactSelected={onDebtorChangeHandler}
                selectedContactId={debtor && debtor.value}
                placeholder="Select a debtor..."
                disabled={isDebtorDisabled}
                contacts={relatedContacts}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <div
              className={classnames(
                Styles.cell,
                Styles.cell50,
                installmentAmount && installmentAmount.isDirty && installmentAmount.isInvalid && Styles.hasError,
              )}
            >
              <label>Individual payments of</label>
              <CurrencyInput2
                name="installmentAmount"
                min={0}
                value={installmentAmount && installmentAmount.value}
                onChange={onIndividualPaymentChangeHandler}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <div
              className={classnames(
                Styles.cell,
                Styles.cell50,
                installmentFrequency &&
                  installmentFrequency.isDirty &&
                  installmentFrequency.isInvalid &&
                  Styles.hasError,
              )}
            >
              <label>Frequency</label>
              <DropDownList
                options={installmentFrequencyOptions}
                selectedOption={
                  installmentFrequency &&
                  installmentFrequencyOptions.find((op) => op.value === installmentFrequency.value)
                }
                onValueChange={onFrequencyChangeHandler}
                stateless
              />
            </div>
            <div className={classnames(Styles.cell, Styles.cell50)}>
              <label>{selectedPaymentPlanId ? 'Next' : 'First'} payment due on</label>
              <DatePicker
                value={nextInstallmentDue}
                onSelect={onNextInstallmentDateChangeHandler}
                format="DD/MM/YYYY"
                hasError={
                  nextInstallmentDate && nextInstallmentDate.isDirty && nextInstallmentDate.isInvalid && Styles.hasError
                }
              />
            </div>
          </div>
          {!creditCardFormProps.showCreditCardForm &&
            featureActive('BB-5109') &&
            creditCardFormProps.allowAutoChargeCreditCard && (
              <div className={Styles.row}>
                <div
                  className={classnames(
                    Styles.cell,
                    Styles.cell50,
                    paymentMethod && paymentMethod.isDirty && paymentMethod.isInvalid && Styles.hasError,
                  )}
                >
                  <label>Payment Method</label>
                  <DropDownList
                    options={paymentMethodOptions}
                    selectedOption={selectedPaymentMethod}
                    placeholder="Select or Add New..."
                    onValueChange={onPaymentMethodChangeHandler}
                    menuPlacement="top"
                    actionList={
                      creditCardFormProps.isNewCreditCardEnabled
                        ? [
                            {
                              displayComponent: (
                                <span>
                                  <i className="fa fa-plus" /> &emsp;Add New Credit Card
                                </span>
                              ),
                              callback: () => {
                                creditCardFormProps.setShowCreditCardForm(true);
                              },
                            },
                          ]
                        : []
                    }
                    stateless
                  />
                  {selectedPaymentMethod?.value !== 'None' && (
                    <span className={Styles.helpText}>
                      This card will be automatically charged for each installment until the end of the payment plan.
                    </span>
                  )}
                </div>
              </div>
            )}

          {creditCardFormProps.showCreditCardForm && (
            <div className={Styles.creditCardFormContainer}>
              <h3>Add New Credit Card</h3>
              <CreditCardSaveForm
                triggerSubmit={creditCardFormProps.triggerSubmit}
                isSubmitting={creditCardFormProps.isSubmittingCreditCard}
                onSubmit={onSubmitCreditCard}
                onReadyForSubmit={onIsReadyForSubmit}
                bankAccountId={creditCardFormProps.bankAccountId}
                contactId={debtor?.value}
              />
              <div className={Styles.buttonContainer}>
                <Button
                  disabled={!creditCardFormProps.isReadyForSubmit || creditCardFormProps.isSubmittingCreditCard}
                  onClick={onTriggerSubmit}
                  locked={creditCardFormProps.isSubmittingCreditCard}
                  className={Styles.buttonMargin}
                >
                  Save Card Details
                </Button>
                <Button
                  type="secondary"
                  onClick={() => creditCardFormProps.setShowCreditCardForm(false)}
                  disabled={creditCardFormProps.isSubmittingCreditCard}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </fieldset>
    );
  },
);

const { Forms2Field } = forms2PropTypes;

PaymentPlanDetails.propTypes = {
  selectedPaymentPlanId: PropTypes.string,
  relatedContacts: PropTypes.array,
  paymentMethod: Forms2Field,
  debtor: Forms2Field,
  installmentAmount: Forms2Field,
  installmentFrequency: Forms2Field,
  nextInstallmentDate: Forms2Field,
  installmentFrequencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  isDebtorDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  creditCardFormProps: PropTypes.shape({
    allowAutoChargeCreditCard: PropTypes.bool.isRequired,
    isNewCreditCardEnabled: PropTypes.bool,
    showCreditCardForm: PropTypes.bool,
    setShowCreditCardForm: PropTypes.func.isRequired,
    isSubmittingCreditCard: PropTypes.bool.isRequired,
    setIsSubmittingCreditCard: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.bool.isRequired,
    setTriggerSubmit: PropTypes.func.isRequired,
    isReadyForSubmit: PropTypes.bool.isRequired,
    setIsReadyForSubmit: PropTypes.func.isRequired,
    bankAccountId: PropTypes.string.isRequired,
  }).isRequired,
  onAddCreditCard: PropTypes.func.isRequired,
};

PaymentPlanDetails.defaultProps = {
  selectedPaymentPlanId: undefined,
  paymentMethod: undefined,
  debtor: undefined,
  installmentAmount: undefined,
  installmentFrequency: undefined,
  nextInstallmentDate: undefined,
  relatedContacts: [],
  paymentMethodOptions: [],
  installmentFrequencyOptions: [],
  isDebtorDisabled: false,
  onValueChange: () => {},
};

PaymentPlanDetails.displayName = 'PaymentPlanDetails';

export default PaymentPlanDetails;
