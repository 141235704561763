import { gql } from '@apollo/client';

const query = gql`
  query MatterStatusCounts($filter: MatterStatusCountsFilter) {
    matterStatusCounts(filter: $filter) {
      status
      count
    }
  }
`;

const notificationIds = ['MatterManagementWebQuery.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterStatusCounts = {
  query,
  notificationIds,
};
