/* eslint-disable import/no-cycle */
import { store } from '@sb-itops/redux';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { isMatterContactBalanceType } from '../bank-account-settings';

import {
  buildReceivePaymentOpdateEntities,
  opdateReceivePayment,
  rollbackReceivePaymentOpdate,
} from './receive-payment-opdates';

function receivePayment(receivePaymentMessage) {
  const receivePaymentThunk = async () => {
    const isContactBalanceFirm = isMatterContactBalanceType();
    const accountId = getAccountId();
    const userId = getUserId();
    const opdateEntities = buildReceivePaymentOpdateEntities({
      accountId,
      userId,
      isContactBalanceFirm,
      receivePaymentMessage,
    });

    opdateReceivePayment(opdateEntities);

    try {
      const path = `/billing/payment/:accountId/`;
      const fetchOptions = { body: JSON.stringify(receivePaymentMessage) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackReceivePaymentOpdate(opdateEntities);
      throw err;
    }
  };

  return store.dispatch(receivePaymentThunk);
}

export { receivePayment };
