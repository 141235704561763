import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SlidingToggle } from '@sb-itops/react';
import { errors } from 'web/services/apollo';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import moment from 'moment';
import { getRegion } from '@sb-itops/region';
import { getAppEnv } from '@sb-itops/app-env';
import * as messageDisplay from '@sb-itops/message-display';
import { consoleErrors } from '@sb-itops/fe-logger';
import Styles from './BillingSupportDebug.module.scss';

// If you are reading this and need to use the onerror callback as well, feel free to move this to a central location
window.onerror = (message, url, lineNumber, column, errorObj) => {
  // keep first ten errors as initial failures often cause subsequent ones
  let errorLine = `Message: ${message}, Line:${lineNumber}, Column: ${column}`;
  if (errorObj) {
    errorLine = `${errorObj.stack}`;
  }
  if (consoleErrors.length >= 100) {
    consoleErrors.splice(10, 1, '... (items omitted)');
    consoleErrors.splice(11, 1);
    consoleErrors.push(errorLine);
  } else {
    consoleErrors.push(errorLine);
  }
  return false;
};

export const BillingSupportDebug = ({ showDebug, toggleShowDebug, supportsSaveCreditCard }) => (
  <div className={Styles.billingSupportDebug}>
    {/* debug */}
    <div className={Styles.debugMode}>
      <h3>Debug Mode</h3>
      <div className={classnames('form-group', Styles.formGroupInline)}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <label
          htmlFor="showDebug"
          onClick={(e) => {
            e.preventDefault();
            toggleShowDebug();
          }}
        >
          <SlidingToggle id="showDebug" scope="showDebug" selected={showDebug} />
          <div>
            Enable Debug Mode &nbsp; <kbd>Ctrl+Alt+d</kbd>
          </div>
        </label>
      </div>
      <p>The debug toggle controls many debug functionalities around the app. These include:</p>
      <ul className={Styles.list}>
        <li className={Styles.bulletPoints}>
          Showing transaction ID on transaction details modals (transactions, bank rec transactions, payments, & credit
          credit notes)
        </li>
        <li className={Styles.bulletPoints}>Showing staff id (person & user) on the Staff & Users page</li>
        <li className={Styles.bulletPoints}>
          <kbd>Debug: Pdf Data</kbd> button on Invoice View
        </li>
        <li className={Styles.bulletPoints}>
          <kbd>Debug: Refresh Payment Link</kbd> button on Invoice View
        </li>
        <li className={Styles.bulletPoints}>
          <kbd>Debug: Expire Payment Link</kbd> button on Invoice View
        </li>
        <li className={Styles.bulletPoints}>
          <kbd>Debug: Show Payment Link Expiration</kbd> button on Invoice View
        </li>
        <li className={Styles.bulletPoints}>
          <kbd>Debug: Set Payment Link Expiry In 1 Day</kbd> button on Invoice View
        </li>
        <li className={Styles.bulletPoints}>
          <kbd>Debug: Refresh Expired Payment Links for Unpaid Invoices</kbd> on Firm Invoices page
        </li>
        {supportsSaveCreditCard && (
          <li className={Styles.bulletPoints}>
            <kbd>trigger next auto charge installment</kbd> button for Contacts &gt; Payment Plans
          </li>
        )}
      </ul>
    </div>
    <div className={Styles.logs}>
      <h3>Support Logs</h3>
      <p>
        This will generate all the logs you need to attach to an escalation or bug ticket. This will put all the
        information in your clipboard as well as download a file with a copy of the information.
      </p>
      <button
        className="btn btn-primary"
        type="button"
        onClick={async () => {
          const clientLogs = `Web client logs:
  Account Id: ${getAccountId()}
  User Id: ${getUserId()}

  Client time at generation: ${moment().toISOString()}
  Client timezone: UTC${Math.sign(moment().utcOffset()) === 1 ? '+' : '-'}${moment().utcOffset() / 60}

  Environment: ${getAppEnv()}
  Region: ${getRegion()}
  
  These console errors do not have source mapping applied. To navigate to the correct line in the source for console errors, load up the dev tools for the appropriate environment, press \`Ctrl + o \` and paste in the source file and line numbers separated by colons (e.g. sb.billingwebapp-353e8a015f0e9dd42afd.js:302468:13)
  
  Console errors (oldest ten + most recent 90):
    ${consoleErrors.join('\n\n    ')}

  GraphQL errors (oldest ten + most recent 90):
    ${errors.join('\n\n    ')}
`;
          try {
            await navigator.clipboard.writeText(clientLogs);
            messageDisplay.success('Logs copied to clipboard');
          } catch (err) {
            messageDisplay.error('Failed to copy logs to clipboard, please attach the downloaded file');
          }

          // make and click a temporary link to download the Blob
          const link = document.createElement('a');
          link.href = URL.createObjectURL(new Blob([clientLogs], { type: 'plain/text' }));
          link.download = `Web Client Logs - ${getAccountId()} - ${moment().toISOString()}.txt`;
          link.click();
          link.remove();
          URL.revokeObjectURL(link.href);
        }}
      >
        Download
      </button>
    </div>
  </div>
);

BillingSupportDebug.displayName = 'BillingSupportDebug';

BillingSupportDebug.propTypes = {
  showDebug: PropTypes.bool.isRequired,
  toggleShowDebug: PropTypes.func.isRequired,
  supportsSaveCreditCard: PropTypes.bool.isRequired,
};

BillingSupportDebug.defaultProps = {};
