angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('home.billing.view-deposit-slip', {
      url: '/view-deposit-slip/:depositSlipId/:waitForNotification',
      template: `<sb-deposit-slip-view style="width:100%" deposit-slip-id="$ctrl.sbParams.depositSlipId"></sb-deposit-slip-view>`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'deposit'],
        tab: { type: 'deposit-slip-pdf' }
      }
    });

});
