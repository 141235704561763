const RTFSpan = require('./rtf-span.js');

class RTFParagraph {
  constructor(opts, parent) {
    if (!opts) opts = {};
    this.style = opts.style || {};
    this.parent = parent;
    this.content = [new RTFSpan({ value: '\n' }, this)];
  }

  getStyle() {
    return { ...this.parent.getStyle(), list: false, ...this.style };
  }
}

module.exports = RTFParagraph;
