import { dateToInteger } from '@sb-itops/date';
import PropTypes from 'prop-types';
import { findPaymentEvents } from '@sb-billing/redux/payment-plan-events/selectors';
import React from 'react';
import PaymentPlanChart from './PaymentPlanChart';

const PaymentPlanChartContainer = ({ paymentPlan, paymentPlanEvents }) => {
  const payments = findPaymentEvents(paymentPlanEvents, {
    paymentPlanId: paymentPlan.id,
  });

  const paymentsMap = payments.reduce((acc, paymentPlanEvent) => {
    if (!acc[paymentPlanEvent.date]) {
      acc[paymentPlanEvent.date] = paymentPlanEvent;
      return acc;
    }

    const currentPaymentPlanEvent = acc[paymentPlanEvent.date];
    acc[paymentPlanEvent.date] = {
      amount: paymentPlanEvent.amount + currentPaymentPlanEvent.amount,
      date: paymentPlanEvent.date,
    };

    return acc;
  }, {});

  return (
    <PaymentPlanChart
      paymentPlan={paymentPlan}
      payments={Object.values(paymentsMap)}
      today={dateToInteger(new Date())}
    />
  );
};

PaymentPlanChartContainer.propTypes = {
  paymentPlan: PropTypes.object.isRequired,
  paymentPlanEvents: PropTypes.array.isRequired,
};

export default PaymentPlanChartContainer;
