import { BANK_BALANCE_TYPE } from '../../bank-account-settings';

export const buildPaymentOpdates = ({ payment, operatingAccount, trustAccount, bankBalanceType }) => {
  switch (payment.sourceAccountType) {
    case 'Trust':
      return buildTrustPaymentOpdates({ payment, operatingAccount, trustAccount, bankBalanceType });
    case 'Operating':
      return buildOperatingPaymentOpdates({ payment, operatingAccount, bankBalanceType });
    default:
      return buildDirectPaymentOpdates({ payment, operatingAccount });
  }
};

const buildDirectPaymentOpdates = ({ payment, operatingAccount }) => [
  {
    ...operatingAccount,
    balance: operatingAccount.balance + payment.amount,
  },
];

const buildOperatingPaymentOpdates = ({ payment, operatingAccount, bankBalanceType }) => {
  const total = payment.payors ? payment.payors.reduce((sum, { amount }) => sum + amount, 0) : payment.amount;

  const matterBalances = operatingAccount.matterBalances.map((matterBalance) =>
    matterBalance.matterId === payment.matterId
      ? {
          ...matterBalance,
          balance: matterBalance.balance - total,
        }
      : matterBalance,
  );

  const contactBalances =
    bankBalanceType === BANK_BALANCE_TYPE.matter
      ? operatingAccount.contactBalances
      : operatingAccount.contactBalances.map((contactBalance) => {
          const payor = payment.payors.find((p) => p);

          if (!payor) {
            return contactBalance;
          }

          return {
            ...contactBalance,
            balance: contactBalance.balance - payor.amount,
          };
        });

  const operatingOpdate = {
    id: operatingAccount.id,
    isDeleted: operatingAccount.isDeleted,
    isInactive: operatingAccount.isInactive,
    balance: operatingAccount.balance + total,
    matterBalances,
    contactBalances,
  };

  return [operatingOpdate];
};

const buildTrustPaymentOpdates = ({ payment, operatingAccount, trustAccount, bankBalanceType }) => {
  const total = payment.payors ? payment.payors.reduce((sum, { amount }) => sum + amount, 0) : payment.amount;

  const matterBalances = trustAccount.matterBalances.map((matterBalance) =>
    matterBalance.matterId === payment.matterId
      ? {
          ...matterBalance,
          balance: matterBalance.balance - total,
          availableBalance: matterBalance.availableBalance - total,
        }
      : matterBalance,
  );

  const contactBalances =
    bankBalanceType === BANK_BALANCE_TYPE.matter
      ? trustAccount.contactBalances
      : trustAccount.contactBalances.map((contactBalance) => {
          const payor = payment.payors.find(
            (p) => p.matterId === contactBalance.matterId && p.payorId === contactBalance.contactId,
          );

          if (!payor) {
            return contactBalance;
          }

          return {
            ...contactBalance,
            balance: contactBalance.balance - payor.amount,
            availableBalance: contactBalance.availableBalance - payor.amount,
          };
        });

  const trustOpdate = {
    id: trustAccount.id,
    isDeleted: trustAccount.isDeleted,
    isInactive: trustAccount.isInactive,
    balance: trustAccount.balance - total,
    availableBalance: trustAccount.availableBalance - total,
    protectedBalance: trustAccount.protectedBalance,
    matterBalances,
    contactBalances,
  };

  const operatingOpdate = {
    ...operatingAccount,
    balance: operatingAccount.balance + total,
  };

  return [trustOpdate, operatingOpdate];
};
