import { defaultPath } from './default-path';

/**
 * @deprecated remove SELECT_ALL once BB-14037 - Load on Demand - Bulk Trust to Office is released and stable
 */
export const SELECT_ALL = `${defaultPath}/SELECT_ALL`;

export const EXPAND_ALL = `${defaultPath}/EXPAND_ALL`;
export const TOGGLE_SELECT_INVOICES = `${defaultPath}/TOGGLE_INVOICES`;
export const TOGGLE_EXPAND_MATTER = `${defaultPath}/TOGGLE_EXPAND_MATTER`;
export const TOGGLE_AUTO_ALLOCATE_MATTER = `${defaultPath}/TOGGLE_AUTO_ALLOCATE_MATTER`;
export const SET_PAYMENTS = `${defaultPath}/SET_PAYMENTS`;
export const CLEAR_STATE = `${defaultPath}/CLEAR_STATE`;
export const SORT_ROWS = `${defaultPath}/SORT_ROWS`;
export const RESET_FILTERS = `${defaultPath}/RESET_FILTERS`;
export const SET_FILTER = `${defaultPath}/SET_FILTER`;
export const TOGGLE_SHOW_FILTERS = `${defaultPath}/TOGGLE_SHOW_FILTERS`;
export const SET_FILTER_GROUP_VISIBILITY = `${defaultPath}/SET_FILTER_GROUP_VISIBILITY`;
