import * as types from './types';

/**
 * Creates an action which, when dispatched, will cause the passed item to become selected.
 *
 * @param  {String} item
 *   Item to be selected.
 * @return {object} Dispatchable action.
 */
export const setSelectedItem = ({ item } = {}) => ({
  type: types.SET_SELECTED_ITEM,
  payload: {
    item,
  },
});

/**
 * Creates an action which, when dispatched, will cause no item to be selected
 * @return {object} Dispatchable action.
 */
export const clearSelectedItem = () => ({
  type: types.CLEAR_SELECTED_ITEM,
  payload: {},
});
