import { createSelector } from 'reselect';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'activities',
  keyPath: 'activityId',
  ngCacheName: 'sbBillingActivityService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getById, getList, getMap, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;

export const activityTypes = {
  FIXED: 0,
  TIME: 1,
  EXPENSE: 2,
};

const getFeeActivitiesSelector = createSelector(
  (state) => state || [],
  (state) =>
    state.filter(
      (activity) => !activity.isDeleted && [activityTypes.FIXED, activityTypes.TIME].includes(activity.type),
    ),
);

const getExpenseActivitiesSelector = createSelector(
  (state) => state || [],
  (state) => state.filter((activity) => !activity.isDeleted && activity.type === activityTypes.EXPENSE),
);

export const getFeeActivitiesNotDeleted = () => getFeeActivitiesSelector(getList());
export const getExpenseActivitiesNotDeleted = () => getExpenseActivitiesSelector(getList());
