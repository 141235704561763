'use strict';

const { otherTransactionsFilter, isTransactionBulkDeposit } = require('../../../transactions/services');
const { bankAccountTypes } = require('../../../bank-account/entities/constants');

/**
 * Filters transactions to only include other operating transactions.
 *
 * @param {Object} params
 * @param {Array<Transaction>} params.transactions - Array of transactions to filter.
 * @param {Boolean} [params.excludeBulkDeposits] - Whether to exclude bulk deposit transactions.
 * @returns {Array<Transaction>} The filtered transactions.
 */
function filterOtherOperatingTransactions({ transactions, excludeBulkDeposits }) {
  return transactions.filter(
    (tx) =>
      (tx.accountType === bankAccountTypes.OPERATING || tx.bankAccountId.endsWith('/Operating')) &&
      otherTransactionsFilter(tx) &&
      (!excludeBulkDeposits || !isTransactionBulkDeposit({ transaction: tx })),
  );
}

module.exports = {
  filterOtherOperatingTransactions,
};
