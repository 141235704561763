import { getLogger } from '@sb-itops/fe-logger';
import { cacheUpgrades } from './cache-upgrades';

const CONFIG_KEY = 'config';``
let $indexedDb;
let $q;
const log = getLogger('upgrade-caches');

export const DICT_STORE = 'dict';

/**
 * The purpose of the upgrade caches process is to provide a mechanism to clear the local state for one or more caches
 * before it is loaded from disk, forcing it to be re-synced from the server. This is useful for times when the structure
 * of a locally cached entity has changed.
 * 
 * The process is managed via a cache version stored in the user's dict store. The version is compared to the cache-upgrade.js
 * script, where the script is a map of versions to a list of one or more caches to clear. All stores referenced in versions
 * greater than the current dict are purged. The order in which stores are purged is unimportant.
 * 
 * A list of the purged/upgraded stores is sent back to the generic cache. Purging may fail as it involves writing to disk. The
 * list sent back to the generic cache includes a flag, hasStorageError, to indicate any errors.
 * @param {*} indexedDb 
 * @param {*} q 
 * @returns {Promise<{ upgrades: Array<{ name: string, hasStorageError: boolean }>, dictVersion: number }>}
 */
export const upgradeCachesP = async (indexedDb, q) => {
  $indexedDb = indexedDb;
  $q = q; 
  log.setLogLevel('info');

  const dictVersion = await getDictVersionP($indexedDb);
  const latestVersion = getLatestVersion(cacheUpgrades);
  log.info(`latest version: ${latestVersion}, dict: ${dictVersion}`);

  if (latestVersion === dictVersion) {
    log.info('Returning empty table set for upgrade');
    return {
      upgrades: [],
      dictVersion,
    };
  }

  const storesToPurge = getStoresToPurge({ cacheUpgrades, dictVersion });
  log.info(`stores to purge:`, JSON.stringify(storesToPurge.map(store => store.name)));
  const upgrades = await purgeStoresP(storesToPurge);
  const storesWithError = upgrades.filter((upgrade) => upgrade.hasStorageError);

  if (storesWithError.length == 0) {
    await upsertDictStoreP(latestVersion);
  } else {
    log.info(`the following store have a storage error and will be in-memory only:`, JSON.stringify(storesWithError, null, 2));
  }

  return {
    upgrades,
    dictVersion,
  };
};

const purgeStoresP = (stores) => 
  Promise.all(stores.map(async (store) => {
    try {
      await purgeStoreP(store.name);
      log.info(`purged ${store.name}`);
      store.hasStorageError = false;
    } catch (err) {
      log.error(`problem purging store ${store.name}:`, err);
      store.hasStorageError = true;
    }

    return store;
  }));

const getLatestVersion = (cacheUpgrades = {}) => {
  const versions = Object.keys(cacheUpgrades).sort((a, b) => +a - +b);

  return versions[versions.length - 1] || 0;
}

const getStoresToPurge = ({ cacheUpgrades = {}, dictVersion = 0 }) => {
  const stores = Object.keys(cacheUpgrades).reduce((toPurge, version) => {   
    if (+version > dictVersion) {
      cacheUpgrades[version].forEach((name) => toPurge[name] = { name });
    }
    return toPurge;
  }, {});

  return Object.values(stores);
};

// Read the angular-indexed-db readme on $indexedDB.openStore.
// We need to pass in an async callback (which is the scope of our transaction)
// and the result of openStore is a chained promise of the DB transaction AND our async callback.
const getDictVersionP = async ($indexedDb) => $indexedDb.openStore(
  DICT_STORE,
  (dictStore) => 
    dictStore
      .find(CONFIG_KEY)
      .then((config) => config.version)
      .catch((err) => {
        // handle any error and assume that everything needs upgrading if the version wasnt loaded
        log.warn(`problem getting dict version`, err);
        
        return 0
      })
);

const purgeStoreP = async (name) => $indexedDb.openStores(
  ['config', name],
  (configStore, store) => $q.all([
    configStore.delete(name),
    store.clear()
  ])
);

const upsertDictStoreP = (version) => $indexedDb.openStore(DICT_STORE, (dictStore) => {
    return dictStore.upsert({
      key: CONFIG_KEY,
      version
    })
    .then(() => {
      log.info(`dict updated to version ${version}`);
    })
    .catch((err) => {
      log.warn(`problem writing dict version ${version}`, err);
    });
  });
