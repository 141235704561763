/**
 * Find all possible client role options for the given matter type configuration
 * @param {object} matterTypeConfig
 * @returns {object} An object with a 'list' and a 'map' of all possible client role options
 */
function findClientRoleOptions (matterTypeConfig) {
  const defaultOptions = { list: [], map: {} };
  if (
    !matterTypeConfig ||
    !(matterTypeConfig.possibleClients && matterTypeConfig.possibleClients.length > 0) ||
    !(matterTypeConfig.items && matterTypeConfig.items.length > 0)
  ) {
    return defaultOptions;
  }

  const clientRoleOptions = matterTypeConfig.items.reduce(
    (acc, item) => {
      if (item.providerId === 'roles_provider' && matterTypeConfig.possibleClients.includes(item.id)) {
        acc.list.push({ label: item.name, value: item.id });
        acc.map[item.id] = item.name;
      }
      return acc;
    },
    { list: [], map: {} },
  );
  return clientRoleOptions;
}

module.exports = {
  findClientRoleOptions,
};
