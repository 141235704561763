'use strict';

/**
 * Get client role name from roles
 * @param {Object} params
 * @param {Object[]} params.roles all matter roles (or just client roles)
 * @return client role name
 */
function getClientRoleName({ roles }) {
  const firstClientRoleFound = roles.find((role) => role.isClient);
  const clientRoleName = firstClientRoleFound && firstClientRoleFound.name;
  return clientRoleName;
}

module.exports = {
  getClientRoleName,
};
