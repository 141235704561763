angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/payments';

  $stateProvider
    .state('home.billing.accounts.payment-provider-payments', {
      url: '/payments',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/payment-provider-payments.html',
      controller: 'PaymentProviderPaymentsController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'payment-provider-payments'],
      },
    })

});
