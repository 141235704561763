const totalsByDate = {
  trust: {
    firm: {}
  },
  operating: {
    firm: {}
  }
};

export const getAccountType = (bankAccountId) => bankAccountId.split('/')[1].toLowerCase();

export function reset () {
  totalsByDate.trust = { firm: {} };
  totalsByDate.operating = { firm: {} };
}

export function getTotals(accountType, matterId, contactId) {
  validateAccountType(accountType);
  if (matterId && contactId) {
    return getMatterContactTotals(accountType, matterId, contactId);
  }
  if (matterId) {
    return getMatterTotals(accountType, matterId);
  }
  return getFirmTotals(accountType);
}

// NOTE: this function will only be called ONCE per transaction
// check sbTransactionService
export function addTransaction({ id, bankAccountId, effectiveDate, amount, contactId, matterId } = {}) {
  validateTransaction(id, bankAccountId, effectiveDate);
  const accountType = getAccountType(bankAccountId);
  const accumulate = total => (total[effectiveDate] = (total[effectiveDate] || 0) + amount);
  // NOTE: adjustment transactions are included in the firm level totals
  // but NOT in the matter-contact level
  accumulate(getFirmTotals(accountType));
  if (matterId && contactId) {
    accumulate(getMatterContactTotals(accountType, matterId, contactId));
  }
  if (matterId) {
    accumulate(getMatterTotals(accountType, matterId));
  }
}

function getFirmTotals(accountType) {
  return totalsByDate[accountType].firm;
}

function getMatterContactTotals(accountType, matterId, contactId) {
  const matterContactKey = `${matterId}_${contactId}`;
  totalsByDate[accountType][matterContactKey] = totalsByDate[accountType][matterContactKey] || {};
  return totalsByDate[accountType][matterContactKey];
}

function getMatterTotals(accountType, matterId) {
  if (!matterId) {
    throw new Error(`matterId is required: ${matterId}`);
  }
  totalsByDate[accountType][matterId] = totalsByDate[accountType][matterId] || {};
  return totalsByDate[accountType][matterId];
}

function validateTransaction(id, bankAccountId, effectiveDate) {
  if (!bankAccountId || !effectiveDate) {
    throw new Error(`invalid transaction: id:${id}, bankAccountId:${bankAccountId}, effectiveDate:${effectiveDate}`);
  }
}

function validateAccountType(accountType) {
  if (
    !accountType ||
    typeof accountType !== 'string' ||
    (accountType.toLowerCase() !== 'trust' && accountType.toLowerCase() !== 'operating')
  ) {
    throw new Error(`invalid account type: ${accountType}`);
  }
}
