'use strict';

/**
 * BB-9211 Show all open Trust accounts that match the current matter’s state OR
 * the original trust if there is only one trust account and it doesn't have a state set
 *
 * this function determines if the second conditional is valid
 * @param {Array.<BankAccount>} activeTrustAccounts
 * @returns a boolean on whether there is only a legacy/original trust account
 */
const shouldShowOriginalTrust = (activeTrustAccounts) => {
  if (activeTrustAccounts.length !== 1) {
    return false;
  }
  if (activeTrustAccounts[0].location) {
    return false;
  }
  return true;
};

module.exports = {
  shouldShowOriginalTrust,
};
