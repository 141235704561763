'use strict';

angular.module('sb.billing.webapp').controller('FinalisePayInvoicesController', function ($state, sbLinkService, sbSaveInvoiceCommand) {
  const ctrl = this;
  
  ctrl.invoiceIds = [
    '61eaff34-17d9-4639-b422-56606f7b806b',
    'fbd5192f-1ca4-4b80-992e-635428662e7d',
    'f1c30f23-7dbf-4c80-8407-dffe2e896a16',
    'ddd8dcf0-c5f7-4cb0-99d2-1d5459380622',
    '9139e8e2-35b1-4b12-8db4-b73928431a6e',
    '52abd420-f502-47e6-9a50-640c01a58978',
  ];

  ctrl.onClickLink = sbLinkService.onClickLink;

  ctrl.onFinaliseInvoicesP = async (invoiceFinalisations) => {
   /* const invoiceFinalisationData = invoiceFinalisations.reduce((acc, invoiceFinalisation) => {
      const { invoiceId, payments } = invoiceFinalisation;
      acc.invoiceIds.push(invoiceId);
      acc.quickPaymentsByInvoiceId[invoiceId] = 
      return acc;

    }, {
      invoiceIds: [],
      quickPaymentsByInvoiceId: {},
    });*/

    await sbSaveInvoiceCommand.executeBulkP(invoiceFinalisations);
  };
});

/*
{
  "amount":500,
  "source":"Trust",
  "sourceAccountType":"Trust",
  "sourceAccountId":"f9fa549d-97a3-4d3e-a582-1c0286edff3d/Trust",
  "matterId":"24480e92-920b-4228-89b9-084cfae3cd9b",
  "hasErrors":false,
  "chequePrintActive":false,
  "chequePrintMethod":0,
  "isElectronicPayment":false,
  "effectiveDate":20191031,
  "allowOverdraw":false,
  "paymentId":"95aba63e-ce75-463f-9597-0249539b9cdb"
}
*/
/*
{
  "version":{
    "invoiceId":"14189a9f-c8c2-4dbb-9125-43d569f0156f",
    "matterId":"24480e92-920b-4228-89b9-084cfae3cd9b",
    "templateId":null,
    "invoiceTitle":"",
    "invoiceTitleLine2":"",
    "debtors":[
      {
        "id":"ff4fb359-bda5-421b-b83c-26b7f205d4f4",
        "ratio":1
      }
    ],
    "invoiceNumber":812,
    "issuedDate":20191031,
    "isOriginalInvoice":true,
    "dueDate":20191107,
    "entries":[
      {
        "type":1,
        "id":"011bcd5e-7ad3-45bb-9000-5dc5fc707353"
      }
    ],
    "hasBeenFinalized":false,
    "layout":{
      "expenseLineItemConfiguration":0,
      "feeLineItemConfiguration":0,
      "showStaffNameOnEntries":false,
      "feeSummaryLineDescription":"",
      "expenseSummaryLineDescription":"",
      "showNonBillableFees":false,
      "showNonBillableExpenses":false,
      "showDescriptionForEntries":false,
      "showRateOnEntries":false,
      "showDurationOnEntries":false
    },
    "additionalOptions":{
      "showInvoiceSummary":false,
      "showAccountSummary":false,
      "showSummaryForTimekeepers":false,
      "showTransactionHistory":false,
      "hidePriorBalance":false,
      "hidePaymentSummary":false
    },
    "status":1,
    "feeTaxRate":0,
    "versionId":"d4725c21-a1e0-4201-995d-d635cc2d941a",
    "userId":"355ab2cd-0e22-4a2a-a8f2-de2204a6229a",
    "merchantPaymentReference":"ca1b43f0-8804-4101-9a9a-cb2fac77b02c"
  },
  "quickPayments":[
    {
      "amount":500,
      "source":"Trust",
      "sourceAccountType":"Trust",
      "sourceAccountId":"f9fa549d-97a3-4d3e-a582-1c0286edff3d/Trust",
      "matterId":"24480e92-920b-4228-89b9-084cfae3cd9b",
      "hasErrors":false,
      "chequePrintActive":false,
      "chequePrintMethod":0,
      "isElectronicPayment":false,
      "effectiveDate":20191031,
      "allowOverdraw":false,
      "paymentId":"95aba63e-ce75-463f-9597-0249539b9cdb"
    },
    {
      "amount":500,
      "source":"Operating",
      "sourceAccountType":"Operating",
      "sourceAccountId":"f9fa549d-97a3-4d3e-a582-1c0286edff3d/Operating",
      "matterId":"24480e92-920b-4228-89b9-084cfae3cd9b",
      "hasErrors":false,
      "chequePrintActive":false,
      "chequePrintMethod":0,
      "isElectronicPayment":false,
      "effectiveDate":20191031,
      "allowOverdraw":false,
      "paymentId":"ce3476c6-4ef2-4549-80e8-3dcdad6c2c38"
    }
  ],
  "opdateId":"56d24f09-4cb3-4570-9875-e6690e003839"
}*/
