import React, { useEffect, useState } from 'react';
import uuid from '@sb-itops/uuid';
import { getLogger } from '@sb-itops/fe-logger';
import { useQuery } from '@apollo/client';
import { AutoNumberDefinition } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { t } from '@sb-itops/localisation-web';

import { TWidget } from './types';
import { ConfigureMatterNumbering } from './ConfigureMatterNumbering';
import {
  autoNumberDefinitionFieldGqlToWidget,
  cloneWidget,
  clonify,
  getBaseWidgets,
  widgetToAutoNumberDefinitionField,
  widgetFieldValidators,
  TAutoNumberDefinitionFieldGql,
  getAutoNumberingCounterStartValue,
} from './widget';

const baseWidgets = getBaseWidgets();
const log = getLogger('ConfigureMatterNumbering');

const Container = () => {
  const [installedWidgets, setInstalledWidgets] = useState<TWidget[]>([]);
  const [selectedWidget, setSelectedWidget] = useState<TWidget>();
  const [assignToLeads, setAssignToLeads] = useState(false);
  const [useAutoReference, setUseAutoReference] = useState(false);
  const [saving, setSaving] = useState(false);
  const [originalAutoNumberingValue, setOriginalAutoNumberingValue] = useState<number | null>(null);

  const { loading } = useQuery(AutoNumberDefinition.query, {
    variables: {},
    fetchPolicy: 'network-only',
    onCompleted: async ({ autoNumberDefinition }) => {
      setAssignToLeads(!!autoNumberDefinition.assignToLeads);
      setUseAutoReference(!!autoNumberDefinition.useAutoReference);
      const widgets = (autoNumberDefinition.fields as TAutoNumberDefinitionFieldGql[])
        .map(autoNumberDefinitionFieldGqlToWidget)
        .filter(Boolean) as TWidget[];
      if (!widgets.find((w) => w?.widgetId === 'autoCounter')) {
        widgets.push(cloneWidget(getBaseWidgets().find((w) => w.widgetId === 'autoCounter') as TWidget));
      }

      setOriginalAutoNumberingValue(getAutoNumberingCounterStartValue(widgets));
      setInstalledWidgets(widgets);
    },
  });

  useEffect(() => {
    if (installedWidgets.length === 0) {
      const requiredWidgets = baseWidgets.filter((w) => w.permanent).map(cloneWidget);
      setInstalledWidgets(requiredWidgets);
    }
  }, []);

  const save = async () => {
    const currentAutoNumberingValue = getAutoNumberingCounterStartValue(installedWidgets);
    const resetAutoNumberCount = currentAutoNumberingValue !== originalAutoNumberingValue;
    const message = {
      assignToLeads,
      useAutoReference,
      resetAutoNumberCount,
      fields: installedWidgets.map(widgetToAutoNumberDefinitionField),
    };

    setSaving(true);
    try {
      await dispatchCommand({
        message,
        type: 'MatterManagement.Messages.SaveAutoNumberDefinition',
      });
      messageDisplay.success(`The ${t('matterNumber')} details have successfully been saved`);
      if (resetAutoNumberCount) {
        setOriginalAutoNumberingValue(currentAutoNumberingValue);
      }
    } catch (e) {
      messageDisplay.error(`Failed to save the ${t('matterNumber')} details`);
    }
    setSaving(false);
  };

  const addWidget = (widget: TWidget, index: number) => {
    if (widget.instanceId) {
      throw new Error(`Can't add widget, already has instanceId: ${widget}`);
    }

    const newWidgets = [...installedWidgets];
    const addedWidget = { ...widget, instanceId: uuid() };
    newWidgets.splice(index, 0, addedWidget);

    setInstalledWidgets(newWidgets);
    setSelectedWidget(addedWidget);
  };

  const moveWidget = (widget: TWidget, index: number) => {
    const oldPos = installedWidgets.findIndex((w) => w.instanceId === widget.instanceId);
    const newWidgets = [...installedWidgets.filter((w) => w.instanceId !== widget.instanceId)];

    if (oldPos !== -1 && oldPos < index) {
      newWidgets.splice(index - 1, 0, widget); // needs to be adjusted as array length changed when we filtered the original out
    } else {
      newWidgets.splice(index, 0, widget);
    }

    setInstalledWidgets(newWidgets);
    setSelectedWidget(widget);
  };

  const deleteWidget = (widget: TWidget) => {
    if (widget.permanent) {
      return;
    }
    if (widget.instanceId === selectedWidget?.instanceId) {
      setSelectedWidget(undefined);
    }
    const newWidgets = [...installedWidgets.filter((w) => w.instanceId !== widget.instanceId)];

    setInstalledWidgets(newWidgets);
  };

  const getInstallableWidgets = () => baseWidgets.filter((w) => !w.permanent);

  const selectWidget = (instanceId?: string) => {
    if (instanceId && instanceId === selectedWidget?.instanceId) {
      setSelectedWidget(undefined);
    } else {
      const found = installedWidgets.find((i) => i.instanceId === instanceId);
      if (found) {
        setSelectedWidget(found);
      }
    }
  };

  const setSelectedWidgetValue = (arrayPos: number, value: string) => {
    const selectedInstanceId = selectedWidget?.instanceId;

    if (!selectedInstanceId) {
      return;
    }

    // as the widget edit is deeply buried this was the only reliable way I could find
    // to make sure all the react components updated on a change.
    const newWidgets: typeof installedWidgets = clonify(installedWidgets);
    const idx = newWidgets.findIndex((w) => w.instanceId === selectedInstanceId);

    if (idx === -1) {
      return;
    }

    const widget = newWidgets[idx];
    const field = widget.editableFields?.[arrayPos];

    if (!field) {
      return;
    }

    const validator = widgetFieldValidators[widget.widgetId][field.name];

    if (!validator || !validator(value)) {
      log.warn(`not setting field due to validation failure ${widget.widgetId}.${field.name} = ${value}`);
      return;
    }

    field.stringValue = value;
    setInstalledWidgets(newWidgets);
    setSelectedWidget(newWidgets[idx]);
  };

  return loading ? null : (
    <ConfigureMatterNumbering
      {...{
        installedWidgets,
        addWidget,
        moveWidget,
        deleteWidget,
        selectWidget,
        selectedWidget,
        setSelectedWidgetValue,
        assignToLeads,
        setAssignToLeads,
        useAutoReference,
        setUseAutoReference,
        save,
        saving,
        installableWidgets: getInstallableWidgets(),
      }}
    />
  );
};

export const ConfigureMatterNumberingContainer = withApolloClient(Container);
