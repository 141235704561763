import { getOperationName } from '@apollo/client/utilities';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  InitActivityCodes,
  InitBankAccountSettings,
  InitFirmDetails,
  InitFirmFeeConfiguration,
  InitFirmInvoiceEmailSettings,
  InitFirmUser,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitOperatingBankAccount,
  InitOperatingChequePrintSettings,
  InitPaymentProviderSettings,
  InitStaffSettings,
  InitTrustChequePrintSettings,
  InitUserBillingAttributes,
  InitTimers,
  InitTodaysFeeDuration,
} from 'web/graphql/queries';
import { destroyApolloClient, getApolloClient, setInitCache } from 'web/services/apollo';
import { subscribeToCacheNotifications, isSubscribedToCacheNotification } from 'web/services/apollo/subscription-manager';
import { startPolling } from 'web/services/apollo/polling-manager';
import { isNewTheme } from '../theme';

/**
 * Initialise GraphQL Caches
 *
 * IMPORTANT NOTE: Any changes to this file need to be reviewed by Luke.
 *
 * The intent of this file is to pre-load certain data in order to optimise
 * query fetching within route and modal-level containers. In order for entities
 * to be considered before adding to here, they must meet at least the minimum
 * constraints:
 *
 * - The entities need to be either firm-level or global
 * - The entities are seldom-changing
 * - The entities are not filtered, and will need to be filtered when consumed
 */
export const initialiseGraphQLCacheP = async ({ log }) => {
  try {
    // As a precautionary measure, we attempt to destroy any existing Apollo
    // Client instances, in case they were not cleaned up correctly at logout.
    //
    // This can sometimes happen when a container using useSubscribedQuery
    // dismounts after logout. In those instances the Apollo Links contain the
    // previous firm's accountId, which is only an issue if logging in as a user
    // of another firm. This would then throw an error as the accountId in the
    // URL would not match the accountId in the new token.
    await destroyApolloClient();
  } catch (err) {
    // Swallow
  }

  try {
    await Promise.all([
      initialiseQuery(InitStaffSettings),
      initialiseQuery(InitFirmUser),
      initialiseQuery(InitFirmDetails),
      initialiseQuery(InitFirmFeeConfiguration),
      initialiseQuery(InitFirmInvoiceEmailSettings),
      initialiseQuery(InitFirmTaxSettings),
      initialiseQuery(InitFirmUtbmsSettings),
      initialiseQuery({
        query: InitActivityCodes.query,
        variables: {
          // IMPORTANT: The variables and their values must match all usages of
          // this query via useCacheQuery
          includeUtbmsCodes: hasFacet(facets.utbms),
          isUtbmsEnabledCheck: true,
        },
        notificationIds: InitActivityCodes.notificationIds,
      }),
      initialiseQuery(InitUserBillingAttributes),
      initialiseQuery(InitOperatingChequePrintSettings),
      initialiseQuery(InitBankAccountSettings),
      initialiseQuery(InitTrustChequePrintSettings),
      initialiseQuery(InitPaymentProviderSettings),
      initialiseQuery(InitOperatingBankAccount),
      // NOTE: Do not replicate this pattern.
      // This is used to subscribe to data for the timers that appear on most
      // pages to prevent unnecessarily re-fetching the same query repeatedly.
      
      // Used on nearly every page in the timer sidebar
      isNewTheme() ? initialiseQuery(InitTimers) : Promise.resolve(),
      isNewTheme() ? initialiseQuery(InitTodaysFeeDuration) : Promise.resolve(),
    ]);
  } catch (err) {
    log.error(err);
    throw new Error('Failed to initialise the GraphQL cache');
  }

  return async () => {
    try {
      await destroyApolloClient();
    } catch (e) {
      log.warn('Failed to teardown apollo client in bootstrap teardown');
    }
  };
};

/**
 * Initialise Query - Register and await fetched queries
 *
 * @param {Object} params
 * @param {*} params.query
 * @param {Object} [params.variables]
 * @param {String[]} params.notificationIds
 * @param {import('web/graphql/queries/types').PollFunction} params.poll
 * @returns
 * 
 */
const initialiseQuery = async ({ query, variables, notificationIds, poll }) => {
  if (!Array.isArray(notificationIds) || !notificationIds.length) {
    throw new Error('initialiseQuery requires notificationIds to be provided');
  }

  const queryName = getOperationName(query);
  if (!queryName) {
    throw new Error('Must use named queries in initialiseQuery');
  }

  // We batch GQL requests according to the settings defined in monorepo/apps/smokeball-billing-web/src/services/apollo/links.js:51 
  // (up to 10 queries, in 50ms - anything outside of that will be a separate request) to prevent hammering the GQL endpoint,
  // de-dupe requests and optimise the usage of data-loaders. We initialise subscriptions/polling first so that they are more 
  // likely to fire in the same batch
  subscribeToCacheNotifications({
    notificationIds,
    queryName,
  });

  if (poll) {
    startPolling({
      queryName,
      poll,
      isSubscribedToCacheNotification,
    });
  }

  const apolloClient = getApolloClient();

  let querySubscription;

  const queryResult = await new Promise((resolve, reject) => {
    querySubscription = apolloClient
      .watchQuery({
        query,
        variables,
        // To prevent cache updates triggering a refetch, we are setting the
        // fetchPolicy to 'cache-first'. apolloClient.refetchQueries (triggered
        // by relevant notifications) will still send a request via network.
        fetchPolicy: 'cache-first',
      })
      .subscribe({
        next: (next) => {
          // Store the result in sbInitCache to be used as a fallback value
          // should the entity be evicted from cache by another query
          setInitCache({ queryName, data: next });
          resolve(next);
        },
        error: (err) => reject(err),
      });
  });

  return { queryResult, querySubscription };
};
