import { gql } from '@apollo/client';

const query = gql`
  query MatterTrustBankAccountsData($matterId: ID!, $filter: MatterBankAccountFilter) {
    matterTrustBankAccounts(matterId: $matterId, filter: $filter) {
      id
    }
  }
`;

const notificationIds = ['WebQueryAccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterTrustBankAccountsData = {
  query,
  notificationIds,
};
