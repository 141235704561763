import React from 'react';
import PropTypes from 'prop-types';
import { FloatingCard, Button, CheckboxLabelInline } from '@sb-itops/react';

import Styles from '../FeeWiseApplicationForm.module.scss';

export const TestingAndDebug = ({ autofillFormData, testAndDebug, onFieldUpdated }) => (
  <FloatingCard applyMargin={false} classname={Styles.marginBottom}>
    <div className={Styles.sectionTitle}>Testing and Debug</div>
    <Button
      size="full-width"
      onClick={() => {
        autofillFormData();
      }}
    >
      Autofill Testing Data
    </Button>
    <br />
    <CheckboxLabelInline
      checked={testAndDebug?.createFirmOwnerAccount?.value}
      onChange={(checked) => {
        onFieldUpdated({ 'testAndDebug.createFirmOwnerAccount': checked });
      }}
    >
      Create FeeWise Firm Owner User account to access FeeWise dashboard (always true in production)
    </CheckboxLabelInline>
  </FloatingCard>
);

TestingAndDebug.displayName = 'TestingAndDebug';

TestingAndDebug.propTypes = {
  autofillFormData: PropTypes.func.isRequired,
  testAndDebug: PropTypes.object,
  onFieldUpdated: PropTypes.func,
};

TestingAndDebug.defaultProps = {
  testAndDebug: {},
  onFieldUpdated: () => {},
};
