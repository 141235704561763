import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, ReferenceLine, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import moment from 'moment';
import { Cent } from '@sb-itops/money';
import { AutoSizer } from 'react-virtualized';
import { useTranslation } from '@sb-itops/react';

const YEARLY_DATE_FORMAT = 'YYYY';
const DATE_FORMAT = 'YYYYMMDD';

/**
 * Convert the data from a form that is convient for the rest of the application to a form that is usable by Recharts.
 * @param {[{date: number, amount: number}]} expected
 * @param {[{date: number, amount: number}]} payments
 * @returns {[{date: number, expected?: number, payment?: number}]}
 */
function mapDataForGraph(expected, payments) {
  // we need to merge the expected and payment arrays into a single array with
  // any data points for the same day being in the same object.
  // This makes the tooltip/hover functionality in a reasonable way
  const dataMap = new Map();

  let lastAmount = 0;
  expected.forEach((exp) => {
    const date = moment(exp.date, DATE_FORMAT).unix();
    const dateEntry = dataMap.get(date);

    if (dateEntry) {
      dataMap.set(date, {
        ...dateEntry,
        expected: lastAmount + exp.amount,
      });
    } else {
      dataMap.set(date, {
        expected: lastAmount + exp.amount,
        date,
      });
    }
    lastAmount += exp.amount;
  });

  let acc = 0;
  payments.forEach((payment) => {
    // dates are needed in unix timestamp form
    const date = moment(payment.date, DATE_FORMAT).unix();
    const dateEntry = dataMap.get(date);

    if (dateEntry) {
      dataMap.set(date, {
        ...dateEntry,
        payment: acc + payment.amount,
      });
    } else {
      dataMap.set(date, {
        payment: acc + payment.amount,
        date,
      });
    }
    acc += payment.amount;
  });

  // cast the result to an array and return in ascending order
  return [...dataMap.values()].sort((a, b) => a.date - b.date);
}

/**
 * Pad installments and payments at start and end of payment plan. This is to improve visual cues
 * for a) when the first payment is made, and b) when the first and last installment is expected.
 *
 * The padding applied are
 * 1) an extra installment at the start of the installments (-5 days) list and one at the end (+ 5).
 * 2) an extra zero dollar payment on the same date as the first installment entry padded above
 * @param {[{ date: number, amount: number }]} installments - the array of installments
 * @param {[{ date: number, amount: number }]} payments - the array of payments
 * @returns {[{ date: number, amount: number }]} - the new installments list
 */
const padExtraDataForGraph = ({ installments, payments }) => {
  if (!installments || installments.length === 0) {
    return {
      expected: [],
      progress: [],
    };
  }

  const firstInstallment = installments[0];
  const lastInstallment = installments[installments.length - 1];
  const expected = [
    {
      amount: 0,
      date: moment(moment(firstInstallment.date, DATE_FORMAT).subtract(5, 'days'), DATE_FORMAT),
    },
    ...installments,
    {
      amount: 0,
      date: moment(moment(lastInstallment.date, DATE_FORMAT).add(5, 'days'), DATE_FORMAT),
    },
  ];

  const progress = [
    {
      amount: 0,
      date: moment(moment(firstInstallment.date, DATE_FORMAT).subtract(5, 'days'), DATE_FORMAT),
    },
    ...payments,
  ];

  return {
    expected,
    progress,
  };
};

/**
 * Returns the dates of the supplied expected payments
 * @param {*} expected the array of expected payment objects
 * @returns {number[]} the dates of the expected payments
 */
function getTicks(expectedPayments) {
  return expectedPayments.map((expectedPayment) => moment(expectedPayment.date, DATE_FORMAT).unix());
}
function getYearlyTicks(expectedPayments) {
  const used = {};
  // Don't show the first year, this is unnecessary and not handled well when the plan starts in Dec
  used[moment(expectedPayments[0].date, DATE_FORMAT).format(YEARLY_DATE_FORMAT)] = true;

  const results = [];
  expectedPayments.forEach((expectedPayment) => {
    const year = moment(expectedPayment.date, DATE_FORMAT).format(YEARLY_DATE_FORMAT);
    if (!used[year]) {
      used[year] = true;
      results.push(moment(expectedPayment.date, DATE_FORMAT).unix());
    }
  });
  return results;
}

/**
 * Component for the ($) label
 * @param {*} props
 * @returns {React.Component} ($) svg component that is placed above the points on the payment line
 */
const paymentLabel = () => <g />;

const PaymentPlanChart = React.memo(({ paymentPlan, payments, today }) => {
  const { t } = useTranslation();
  const SHORT_DATE_FORMAT = t('dateFormat_DD/MM');
  const LONG_DATE_FORMAT = t('dateFormat_DD/MM/YYYY');
  const { expected, progress } = padExtraDataForGraph({
    installments: paymentPlan.installments,
    payments,
  });
  const data = mapDataForGraph(expected, progress) || [];
  const ticks = getTicks(expected);

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="payment-plan-chart-wrapper">
      <AutoSizer>
        {({ height, width }) => (
          <LineChart height={height} width={width} data={data} margin={{ top: 20, right: 0, left: -15, bottom: 0 }}>
            <CartesianGrid vertical={false} />
            <XAxis
              type="number"
              dataKey="date"
              scale="time"
              domain={['dataMin', 'dataMax']}
              ticks={ticks}
              tickFormatter={(unixDate) => moment.unix(unixDate).format(SHORT_DATE_FORMAT)}
              tickLine={false}
              padding={{ left: 5, right: 5 }}
            />
            {!!moment.unix(ticks[ticks.length - 1]).diff(moment.unix(ticks[0]), 'year') && (
              <XAxis
                type="number"
                dataKey="date"
                domain={['dataMin', 'dataMax']}
                ticks={getYearlyTicks(expected)}
                // eslint-disable-next-line react/no-unstable-nested-components
                tick={({ x, y, payload }) => (
                  <>
                    <path d={`M${x},${y}v${-15}`} stroke="rgb(102, 102, 102)" />
                    <text x={x + 5} y={y} dy={-4} fill="rgb(102, 102, 102)" textAnchor="start">
                      {moment.unix(payload.value).format(YEARLY_DATE_FORMAT)}
                    </text>
                  </>
                )}
                scale="time"
                interval="preserveStart"
                height={10}
                axisLine={false}
                padding={{ left: 5, right: 5 }}
                xAxisId="year"
                tickLine={false}
              />
            )}
            <YAxis
              tickLine={false}
              axisLine={false}
              type="number"
              tickFormatter={(value) => `  ${t('currencySymbol')}${new Cent(value).toString()}`}
              width={90}
              tickCount={10}
            />
            <Tooltip
              cursor={false}
              formatter={(value) => `${t('currencySymbol')}${new Cent(value).toString()}`}
              labelFormatter={(unixDate) => moment.unix(unixDate).format(LONG_DATE_FORMAT)}
              isAnimationActive={false}
            />
            <Legend iconType="square" />
            {/* WARNING only change the order of *Line children if you are sure of what you are doing.
             * The draw order of the lines is the same as their definition order.
             */}
            <Line
              dot={false}
              type="stepAfter"
              dataKey="expected"
              name="Expected"
              stroke="#DE6767"
              strokeWidth={3}
              isAnimationActive={false}
              connectNulls
            />
            <Line
              dot={false}
              type="stepAfter"
              dataKey="payment"
              name="Progress"
              stroke="#89E174"
              strokeWidth={3}
              isAnimationActive={false}
              connectNulls
            >
              <LabelList content={paymentLabel} position="top" offset={5} />
            </Line>
            <ReferenceLine
              x={moment(today, DATE_FORMAT).unix()}
              label={{ position: 'top', value: 'Today', fill: 'rgb(0,0,0, 0.4)', fontSize: 14 }}
              stroke="rgb(0,0,0, 0.4)"
              strokeWidth={1}
            />
          </LineChart>
        )}
      </AutoSizer>
    </div>
  );
});

PaymentPlanChart.displayName = 'PaymentPlanChart';

PaymentPlanChart.propTypes = {
  paymentPlan: PropTypes.shape({
    installments: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ).isRequired,
  today: PropTypes.number.isRequired,
};

PaymentPlanChart.defaultProps = {};

export default PaymentPlanChart;
