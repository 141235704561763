'use strict';

const { regionType } = require('@sb-itops/region');

const shouldShowBusinessStructure = {
  [regionType.AU]: false,
  [regionType.GB]: false,
  [regionType.US]: true,
};

const shouldPopulateAcn = {
  [regionType.AU]: true,
  [regionType.GB]: true,
  [regionType.US]: false,
};

const getAddressLine1Detailed = (firmDetails) => {
  const { unitNumber, streetNumber, streetName, streetType } = firmDetails.businessAddressDetailed;
  const addressLine1Array = [];
  if (unitNumber) addressLine1Array.push(unitNumber);
  const streetArray = [];
  if (streetNumber) streetArray.push(streetNumber);
  if (streetName) streetArray.push(streetName);
  if (streetType) streetArray.push(streetType);
  const streetString = streetArray.join(' ');
  if (streetString) addressLine1Array.push(streetString);
  return addressLine1Array.join(' / ');
};

const getStateDetailed = (firmDetails) => {
  const { state } = firmDetails.businessAddressDetailed;
  return state;
};

const getCountyDetailed = (firmDetails) => {
  const { county } = firmDetails.businessAddressDetailed;
  return county;
};

const getCityDetailed = (firmDetails) => {
  const { suburbTown } = firmDetails.businessAddressDetailed;
  return suburbTown;
};

const getPostcodeDetailed = (firmDetails) => {
  const { postcode } = firmDetails.businessAddressDetailed;
  return postcode;
};

const getFirmAddressLine1 = {
  [regionType.AU]: getAddressLine1Detailed,
  [regionType.GB]: getAddressLine1Detailed,
  [regionType.US]: (firmDetails) => firmDetails?.businessAddress?.addressLine1,
};

const getFirmAddressLine2 = {
  [regionType.AU]: () => '', // AU/GB may have random junk values in address line 2, e.g. a random NSW
  [regionType.GB]: () => '', // So we just ignore the field and leave line 2 blank
  [regionType.US]: (firmDetails) => firmDetails?.businessAddress?.addressLine2,
};

const getFirmAddressCity = {
  [regionType.AU]: getCityDetailed,
  [regionType.GB]: getCityDetailed,
  [regionType.US]: (firmDetails) => firmDetails?.businessAddress?.city,
};

const getFirmAddressState = {
  [regionType.AU]: getStateDetailed,
  [regionType.GB]: getCountyDetailed,
  [regionType.US]: (firmDetails) => firmDetails?.businessAddress?.state,
};

const getFirmAddressZipcode = {
  [regionType.AU]: getPostcodeDetailed,
  [regionType.GB]: getPostcodeDetailed,
  [regionType.US]: (firmDetails) => firmDetails?.businessAddress?.zipCode,
};

/**
 * The validation for these field enums are stored in monorepo/apps/smokeball-billing-web/src/react-redux/components/fee-wise-application-form/schemas/strategySchemas.yup.js
 * The labels for these field enums are stored in settings.feewise in the respective en-AU/en-GB/en-US files
 */

const socialSecurityFieldValues = {
  DRIVERS_LICENSE_OR_TFN: 'DRIVERS_LICENSE_OR_TFN',
  NATIONAL_INSURANCE_NUMBER: 'NATIONAL_INSURANCE_NUMBER',
  SOCIAL_SECURITY: 'SOCIAL_SECURITY',
};

const socialSecurityField = {
  [regionType.AU]: socialSecurityFieldValues.DRIVERS_LICENSE_OR_TFN,
  [regionType.GB]: socialSecurityFieldValues.NATIONAL_INSURANCE_NUMBER,
  [regionType.US]: socialSecurityFieldValues.SOCIAL_SECURITY,
};

const routingNumberFieldValues = {
  BSB: 'BSB',
  SORT_CODE: 'SORT_CODE',
  ROUTING_NUMBER: 'ROUTING_NUMBER',
};

const routingNumberField = {
  [regionType.AU]: routingNumberFieldValues.BSB,
  [regionType.GB]: routingNumberFieldValues.SORT_CODE,
  [regionType.US]: routingNumberFieldValues.ROUTING_NUMBER,
};

const zipCodeFieldValues = {
  FOUR_DIGIT_POSTCODE: 'FOUR_DIGIT_POSTCODE',
  GB_POST_CODE: 'GB_POST_CODE',
  ZIP_CODE: 'ZIP_CODE',
};

const zipCodeField = {
  [regionType.AU]: zipCodeFieldValues.FOUR_DIGIT_POSTCODE,
  [regionType.GB]: zipCodeFieldValues.GB_POST_CODE,
  [regionType.US]: zipCodeFieldValues.ZIP_CODE,
};

const nationalIdFieldValues = {
  ACN: 'ACN',
  CRN: 'CRN',
  EIN: 'EIN',
};

const nationalIdField = {
  [regionType.AU]: nationalIdFieldValues.ACN,
  [regionType.GB]: nationalIdFieldValues.CRN,
  [regionType.US]: nationalIdFieldValues.EIN,
};

const feewiseStrategies = {
  SHOULD_SHOW_BUSINESS_STRUCTURE: 'SHOULD_SHOW_BUSINESS_STRUCTURE',
  SHOULD_POPULATE_ACN: 'SHOULD_POPULATE_ACN',
  GET_FIRM_ADDRESS_LINE_1: 'GET_FIRM_ADDRESS_LINE_1',
  GET_FIRM_ADDRESS_LINE_2: 'GET_FIRM_ADDRESS_LINE_2',
  GET_FIRM_ADDRESS_STATE: 'GET_FIRM_ADDRESS_STATE',
  GET_FIRM_ADDRESS_CITY: 'GET_FIRM_ADDRESS_CITY',
  GET_FIRM_ADDRESS_ZIPCODE: 'GET_FIRM_ADDRESS_ZIPCODE',
  SOCIAL_SECURITY_FIELD: 'SOCIAL_SECURITY_FIELD',
  ROUTING_NUMBER_FIELD: 'ROUTING_NUMBER_FIELD',
  ZIP_CODE_FIELD: 'ZIP_CODE_FIELD',
  NATIONAL_ID_FIELD: 'NATIONAL_ID_FIELD',
};

const feewiseStrategiesMap = {
  SHOULD_POPULATE_ACN: shouldPopulateAcn,
  SHOULD_SHOW_BUSINESS_STRUCTURE: shouldShowBusinessStructure,
  GET_FIRM_ADDRESS_LINE_1: getFirmAddressLine1,
  GET_FIRM_ADDRESS_LINE_2: getFirmAddressLine2,
  GET_FIRM_ADDRESS_STATE: getFirmAddressState,
  GET_FIRM_ADDRESS_CITY: getFirmAddressCity,
  GET_FIRM_ADDRESS_ZIPCODE: getFirmAddressZipcode,
  SOCIAL_SECURITY_FIELD: socialSecurityField,
  ROUTING_NUMBER_FIELD: routingNumberField,
  ZIP_CODE_FIELD: zipCodeField,
  NATIONAL_ID_FIELD: nationalIdField,
};

const getFeewiseStrategy = (region, strategy, functionArgs) => {
  if (!Object.keys(feewiseStrategies).includes(strategy)) {
    throw Error(`Strategy ${strategy} not defined in strategies.js, needs to be added before it can be called`);
  }

  const boolOrFunc = feewiseStrategiesMap[strategy][region];
  if (typeof boolOrFunc === 'function') {
    return boolOrFunc(functionArgs);
  }

  return boolOrFunc;
};

module.exports = {
  feewiseStrategies,
  getFeewiseStrategy,
  socialSecurityFieldValues,
  routingNumberFieldValues,
  zipCodeFieldValues,
  nationalIdFieldValues,
};
