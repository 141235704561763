import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, SlidingToggle, Spinner, forms2PropTypes } from '@sb-itops/react';
import { eInvoiceLayout as layouts } from '@sb-billing/business-logic/invoice/entities';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';

import Styles from './EInvoiceSettingsForm.module.scss';

const { BY_TIMEKEEPER, AS_LIST } = layouts;

export const EInvoiceSettingsForm = ({
  eInvoiceSwitchEnabled,
  eInvoiceLayout,
  eInvoiceEnabled,
  formDirty,
  formInitialised,
  isSaving,
  // callbacks
  onSave,
  onEInvoiceLayout,
  onEInvoiceToggleChange,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <>
      <h3>eInvoice Settings</h3>
      <fieldset className={classnames(Styles.einvoiceSettingsForm)}>
        {eInvoiceSwitchEnabled && (
          <div className={Styles.einvoiceSwitch}>
            <label className={Styles.einvoiceToggle}>
              <SlidingToggle
                id="eInvoiceToggle"
                onChange={(name, val) => {
                  onEInvoiceToggleChange(val);
                }}
                scope="firm-settings-einvoice-toggle"
                selected={eInvoiceEnabled.value}
              />
              <span className={Styles.einvoiceToggleLabel}>Enable eInvoices &nbsp;</span>
            </label>
            <i
              className="icon-information"
              color="lightgrey"
              title="eInvoice settings can be overridden per matter on your Edit Matter page"
            />
          </div>
        )}
        <div className={Styles.einvoiceLayout}>
          <label>eInvoice Line Items</label>
          <div className={Styles.optionsLayout}>
            <div className={Styles.options}>
              <label className={Styles.option}>
                <input
                  type="radio"
                  onChange={() => onEInvoiceLayout({ layout: BY_TIMEKEEPER })}
                  checked={eInvoiceLayout.value === BY_TIMEKEEPER}
                />
                Group by Staff
              </label>
              <label className={Styles.option}>
                <input
                  type="radio"
                  onChange={() => onEInvoiceLayout({ layout: AS_LIST })}
                  checked={eInvoiceLayout.value === AS_LIST}
                />
                Display as list
              </label>
            </div>
            <div className={Styles.dummyDiv}>{/* Dummy div to help with box alignment when resizing window */}</div>
          </div>
        </div>
        <div>
          <Button
            onClick={async () => {
              try {
                await onSave({ layout: eInvoiceLayout.value, eInvoiceEnabled: eInvoiceEnabled.value });
                displaySuccessToUser('eInvoice settings saved successfully');
              } catch (err) {
                displayErrorToUser('Failed to save eInvoice settings');
              }
            }}
            disabled={isSaving.value || !formDirty}
          >
            {isSaving.value && <Spinner small />} Save
          </Button>
        </div>
      </fieldset>
    </>
  );
};

const { Forms2Field } = forms2PropTypes;

EInvoiceSettingsForm.propTypes = {
  eInvoiceSwitchEnabled: PropTypes.bool.isRequired,
  eInvoiceEnabled: Forms2Field,
  eInvoiceLayout: Forms2Field,
  isSaving: PropTypes.any,
  formDirty: PropTypes.any,
  formInitialised: PropTypes.bool,
  // callbacks
  onEInvoiceLayout: PropTypes.func,
  onEInvoiceToggleChange: PropTypes.func,
  onSave: PropTypes.func,
};

EInvoiceSettingsForm.defaultProps = {
  eInvoiceEnabled: undefined,
  eInvoiceLayout: undefined,
  isSaving: undefined,
  formDirty: undefined,
  formInitialised: false,
  // callbacks
  onEInvoiceLayout: undefined,
  onEInvoiceToggleChange: undefined,
  onSave: undefined,
};

EInvoiceSettingsForm.displayName = 'EInvoiceSettingsForm';
