import {
  balanceTypes,
  validAccountTypeForBalanceType,
} from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

const initAllocations = ({ balances, multiPayments, accountType }) => {
  const accountTypeAllocations = multiPayments.reduce((acc, mp) => {
    if (mp.source.toUpperCase() === accountType) {
      acc[mp.payorId] = mp;
    }
    return acc;
  }, {});

  const accountTypeBalances = balances[accountType].contactBalances.map((cb) => ({
    ...cb,
    available: accountType in validAccountTypeForBalanceType ? cb[balanceTypes.AVAILABLE] : cb[balanceTypes.BALANCE],
    amount: (accountTypeAllocations[cb.contactId] && accountTypeAllocations[cb.contactId].amount) || 0,
    bankAccountName: accountType === bankAccountTypeEnum.TRUST ? balances[accountType].name : undefined, // Specifically for trust we need its name
  }));

  return accountTypeBalances.sort((a, b) => a.available - b.available);
};

export { initAllocations };
