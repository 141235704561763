import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import Styles from './CreditNoteModal.module.scss';
import CreditNoteForm from './CreditNoteForm.container';

export const CreditNoteModal = React.memo(
  ({ scope, isVisible, isSaveDisabled, contactId, matterId, onSave, onClose }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.modal}
        isVisible={isVisible}
        title="Add Credit"
        body={<CreditNoteForm scope={scope} contactId={contactId} matterId={matterId} />}
        footer={
          <div>
            <Button onClick={onSave} disabled={isSaveDisabled}>
              Save
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

CreditNoteModal.displayName = 'CreditNoteModal';

CreditNoteModal.propTypes = {
  scope: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  contactId: PropTypes.string,
  matterId: PropTypes.string,
};

CreditNoteModal.defaultProps = {
  isVisible: false,
  isSaveDisabled: false,
  contactId: undefined,
  matterId: undefined,
};
