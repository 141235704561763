import {
  getMap,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/trust-retainers';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { validateMultiple as isEmailValid } from '@sb-itops/email';
import { interpolateText } from '@sb-billing/business-logic/evergreen-retainer';
import { featureActive } from '@sb-itops/feature';
import { getPhoneNumber, getFirmName } from '@sb-firm-management/redux/firm-management';
import { getMap as getBankAccountBalancesState  } from '@sb-billing/redux/bank-account-balances';
import { getMatterTrustBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';
import {
  getDebtorId as getMatterDebtorId,
  getLineSummary as getMatterLineSummary,
} from '@sb-matter-management/redux/matters';
import {
  getActiveProvider,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { balanceTypes } from '../../../business-logic/bank-account-balances/entities/constants';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';


angular
  .module('@sb-billing/services')
  .service('sbTrustRetainersService', function (
      sbLoggerService, sbGenericEndpointService, sbFirmManagementMbService, sbGenericCacheService, sbEndpointType, sbSimpleContactMbService,
      sbMessageDisplayService, sbRequestDepositService, sbDefaultMatterBillingConfigurationService, sbBillingConfigurationService, sbLocalisationService,
    ) {
      const that = this;
      const log = sbLoggerService.getLogger('sbTrustRetainersService');
      const endpoint = 'billing/evergreen-requests';
      const requestRetainerEndpoint = `billing/request-deposit/batch`;
      sbGenericCacheService.setupCache({
        name: 'sbTrustRetainersService',
        sync: {
          endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
          poll: 60,
          subscriptions: ['notifier.BillingMattersNotifications.RetainerReplenishRequestUpdated'],
        },
        updateRedux,
        clearRedux,
      });

      that.getById = getById;
      that.get = getMap;
      that.requestRetainersP = requestRetainersP;
      that.batchRequestRetainerP = batchRequestRetainerP;
      that.getByIdActive = getByIdActive;
      // a convenience method - returns the trustRetainer object only if retainers are enabled for the firm and matter.
      // otherwise returns undefined;
      function getByIdActive(matterId) {
        const defaultMatterBillingConf = sbDefaultMatterBillingConfigurationService.getConfig() || {};
        const matterBillingConf = sbBillingConfigurationService.getByMatterId(matterId) || {};

        if (defaultMatterBillingConf.minimumTrustRetainerActive && matterBillingConf.minimumTrustRetainerActive) {
          return getById(matterId);
        }
      }

      function requestRetainersP(emailRequests) {
        return sbGenericEndpointService
          .postPayloadP(requestRetainerEndpoint, undefined, { emailRequests })
          .catch((err) => {
            const errMsg = 'ERROR' || err || err.message;
            log.error('failed to send request for retainer', errMsg);
            throw err;
          });
      }

      /**
       * batchRequestRetainerP
       *
       * @param {Array<{ matterId: string, bankAccountId: string }>} matterBankAccountRequests
       */
      async function batchRequestRetainerP(matterBankAccountRequests) {
        try {
          const staffDetails = await sbFirmManagementMbService.getStaffEmailDetails({ showDisplayName: false });

          const providerType = getActiveProvider();
          const retainerRequests = matterBankAccountRequests.map(({ matterId, bankAccountId }) => {
            const paymentProviderEnabled =
              providerType &&
              bankAccountId &&
              isPaymentProviderEnabledForBankAccount({ bankAccountId: bankAccountId, providerType });
            return {
              matterId,
              bankAccountId,
              paymentProviderEnabled,
            };
          });

          const emailRequests = await createEmailRequests({ staffDetails, retainerRequests });

          if (emailRequests.invalid.length) {
            const uniqueBadRequests = _.unique(emailRequests.invalid, (badRequest) => badRequest.customerInfo.id);
            const warningMessage = uniqueBadRequests.reduce((msg, badRequest) => {
              if (msg.length) {
                msg += ', ';
              }

              return `${msg}${badRequest.customerInfo.salutation}`;
            }, '');

            sbMessageDisplayService.warn(
              `Warning: Cannot send ${emailRequests.invalid.length} retainer request email(s) due to missing contact email addresses for: ${warningMessage}`
            );
          }

          // we need separate requests as different endpoint is called for each
          const validEmailRequests = emailRequests.valid;
          const validEmailRequestsWithPaymentProvider = emailRequests.validWithPaymentProvider;

          if (validEmailRequests.length === 0 && validEmailRequestsWithPaymentProvider.length === 0) {
            return;
          }

          if (featureActive('BB-13500')) {
            // NEW BB-13500
            try {
              const [response, responseWithPaymentProvider] = await sendEmailRequests(
                validEmailRequests,
                validEmailRequestsWithPaymentProvider,
              );

              if (
                (response.failureMatterIds && response.failureMatterIds.length > 0) ||
                (responseWithPaymentProvider.failureMatterIds && responseWithPaymentProvider.failureMatterIds.length > 0)
              ) {
                sbMessageDisplayService.warn('Failed to send one or more retainer requests');
              } else {
                const total = validEmailRequests.length + validEmailRequestsWithPaymentProvider.length;
                sbMessageDisplayService.success(`${total} retainer request email(s) sent successfully`);
              }
            } catch (reqErr) {
              log.error('Failed to send retainer requests', reqErr);
              if (
                reqErr.payload &&
                reqErr.payload.body &&
                reqErr.payload.body.message === emailMessages.notAllowedToSendEmailsServer
              ) {
                sbMessageDisplayService.error(emailMessages.notAllowedToSendEmailsDisplay);
              } else {
                sbMessageDisplayService.error(`Failed to send retainer request email(s), please try again later`);
              }
            }
          } else {
            // OLD pre BB-13500
            try {
              const [response, responseWithPaymentProvider] = await sendEmailRequests(
                validEmailRequests,
                validEmailRequestsWithPaymentProvider
              );
  
              if (response.$sbStatus === 207 || responseWithPaymentProvider.$sbStatus === 207) {
                sbMessageDisplayService.warn('Failed to send one or more retainer requests');
              } else {
                const total = validEmailRequests.length + validEmailRequestsWithPaymentProvider.length;
                sbMessageDisplayService.success(`${total} retainer request email(s) sent successfully`);
              }
            } catch (reqErr) {
              log.error('Failed to send retainer requests', reqErr);
              if (reqErr.data && reqErr.data.message === emailMessages.notAllowedToSendEmailsServer) {
                sbMessageDisplayService.error(emailMessages.notAllowedToSendEmailsDisplay);
              } else {
                sbMessageDisplayService.error(`Failed to send retainer request email(s), please try again later`);
              }
            }
          }
        } catch (err) {
          log.error(err);
        }
      }

      function sendEmailRequests(validEmailRequests, validEmailRequestsWithPaymentProvider) {
        let emailRequests;
        let emailRequestsWithPaymentProvider;

        if (featureActive('BB-13500')) {
          // NEW BB-13500
          emailRequests = validEmailRequests.length
            ? dispatchCommand({
                type: 'Integration.RequestDeposit',
                message: { emailRequests: validEmailRequests },
              })
            : {};

            // To standartise the email fields, we have to rename some of them
            const finalValidEmailRequestsWithPaymentProvider = validEmailRequestsWithPaymentProvider.map((request) => ({
              ...request,
              sendTo: undefined,
              message: undefined,
              to: request.sendTo,
              body: request.message,
            }));

            emailRequestsWithPaymentProvider = finalValidEmailRequestsWithPaymentProvider.length
              ? dispatchCommand({
                  type: 'Integration.RequestCreditCardDeposit',
                  message: { emailRequests: finalValidEmailRequestsWithPaymentProvider },
                })
              : {};
        } else {
          // OLD pre BB-13500
          emailRequests = validEmailRequests.length ? requestRetainersP(validEmailRequests) : {};
          emailRequestsWithPaymentProvider = validEmailRequestsWithPaymentProvider.length
            ? sbRequestDepositService.requestDepositP(validEmailRequestsWithPaymentProvider)
            : {};
        }

        return Promise.all([emailRequests, emailRequestsWithPaymentProvider]);
      }

      /**
       * createEmailRequests
       *
       * @param {Object} params
       * @param {Object} params.staffDetails
       * @param {Array<{ matterId: string, bankAccountId: string, paymentProviderEnabled: bool }>} params.retainerRequests
       */
      async function createEmailRequests({ staffDetails, retainerRequests }) {
        const requests = {
          valid: [],
          validWithPaymentProvider: [],
          invalid: [],
        };

        const customerInfos = await getCustomerInfo(retainerRequests);

        customerInfos.forEach(({ customerInfo, matterId, bankAccountId, paymentProviderEnabled }) => {
          // customerInfo will be an array that has at least 1 contact (could be more due to joined contacts)
          // contactEmailData is an object of {to, salutation} that collates all the contacts information into one long email & salutation
          const contactEmailData = sbSimpleContactMbService.getEmails(customerInfo);
          if (!contactEmailData || (contactEmailData && !isEmailValid(contactEmailData.to))) {
            // the email from the entity is invalid
            requests.invalid.push({ customerInfo: customerInfo[0], matterId });
          } else {
            const req = createEmailRequest({
              staffDetails,
              matterId,
              contactEmailData,
              bankAccountId,
              paymentProviderEnabled,
            });
            paymentProviderEnabled ? requests.validWithPaymentProvider.push(req) : requests.valid.push(req);
          }
        });

        return requests;
      }

      /**
       * getCustomerInfo
       *
       * @param {Array<{ matterId: string, bankAccountId: string, paymentProviderEnabled: bool }>} retainerRequests
       */
      async function getCustomerInfo(retainerRequests) {
        return Promise.all(
          retainerRequests.map(async ({ matterId, bankAccountId, paymentProviderEnabled }) => {
            const debtorId = getMatterDebtorId(matterId);
            const customerInfo = await sbSimpleContactMbService.getPeopleP(debtorId);

            return {
              matterId,
              customerInfo,
              bankAccountId,
              paymentProviderEnabled,
            };
          })
        );
      }

      function createEmailRequest({ staffDetails, matterId, contactEmailData, bankAccountId, paymentProviderEnabled }) {
        const matterBillingConfig = sbBillingConfigurationService.getByMatterId(matterId) || {};
        const { bCCAddresses = [], cCAddresses = [] } = getMatterEmailSettings(matterId) || {};

        const defaultMatterBillingConfig = sbDefaultMatterBillingConfigurationService.getConfig();
        const replenishUpToAmount = matterBillingConfig.trustRetainerSettingsOverridden
          ? matterBillingConfig.trustRetainerReplenishAmount
          : defaultMatterBillingConfig.trustRetainerReplenishAmount;
        const minimumTrustRetainerAmount = matterBillingConfig.trustRetainerSettingsOverridden
          ? matterBillingConfig.minimumTrustRetainerAmount
          : defaultMatterBillingConfig.minimumTrustRetainerAmount;
        // Get trust balance across all trust accounts
        const matterTrustBalance = getMatterTrustBalance(getBankAccountBalancesState(), {
          matterId,
          balanceType: balanceTypes.BALANCE,
        });
        const matterTotals = getMatterTotalsById(matterId);
        const amountRequested = replenishUpToAmount - matterTrustBalance;
        const emailData = {
          staffName: staffDetails.name,
          matterLineSummary: getMatterLineSummary(matterId),
          replenishUpToCents: replenishUpToAmount,
          amountRequestedCents: amountRequested,
          debtorName: contactEmailData.salutation,
          showPaymentButton: paymentProviderEnabled,
          outstandingBalance: matterTotals && matterTotals.unpaid,
          phoneNumber: getPhoneNumber(),
          firmName: getFirmName(),
          trustBalance: matterTrustBalance,
          minimumThreshold: minimumTrustRetainerAmount,
        };

        const subject = interpolateText(
          defaultMatterBillingConfig.trustRetainerReplenishEmailSubject,
          emailData,
          sbLocalisationService.t,
        );
        let emailBody = interpolateText(defaultMatterBillingConfig.trustRetainerReplenishEmailBody, emailData, sbLocalisationService.t);
        const from = getSender(staffDetails);
        const cc = cCAddresses.join(', ');
        let bcc = '';
        if (defaultMatterBillingConfig.doNotSendReplenishEmailToUser) {
          bcc = bCCAddresses.join(', ');
        } else {
          const uniqueAddresses = new Set(bCCAddresses);
          uniqueAddresses.add(from);
          bcc = Array.from(uniqueAddresses).join(', ');
        }

        if (paymentProviderEnabled) {
          const trustRetainer = getByIdActive(matterId) || {};

          return {
            accountType: 'TRUST',
            bankAccountId,
            // We do not want to pass empty string as it fails email validation in the endpoint
            bcc: bcc ? bcc : undefined,
            cc: cc ? cc : undefined,
            matterId,
            sendTo: contactEmailData.to,
            from,
            subject,
            message: emailBody,
            amount: trustRetainer.replenished ? undefined : replenishUpToAmount,
            emailType: 'RETAINER',
            payorId: getMatterDebtorId(matterId),
            footer: '',
          };
        }

        return {
          matterId,
          subject,
          body: emailBody,
          to: contactEmailData.to,
          from,
          // We do not want to pass empty string as it fails email validation in the endpoint
          bcc: bcc ? bcc : undefined,
          cc: cc ? cc : undefined,
        };
      }

      function getSender(staff) {
        return staff.email;
      }
    }
  );
