import React from 'react';
import PropTypes from 'prop-types';
import { SubscriptionPaymentComplete } from '../../components/subscription-payment-complete';
import Styles from './SubscriptionCompleteRoute.module.scss';

export const SubscriptionCompleteRoute = React.memo((props) => {
  const { onPaymentMethodUpdated } = props;

  return (
    <div className={Styles.subscriptionCompleteRoute}>
      <div className={Styles.sideBox} />
      <div className={Styles.centreBox}>
        <div className={Styles.displayBox}>
          <SubscriptionPaymentComplete
            onPaymentMethodUpdated={onPaymentMethodUpdated}
            acknowledgeButtonLabel="Continue"
          />
        </div>
      </div>
      <div className={Styles.sideBox} />
    </div>
  );
});

SubscriptionCompleteRoute.displayName = 'SubscriptionCompleteRoute';

SubscriptionCompleteRoute.propTypes = {
  onPaymentMethodUpdated: PropTypes.func.isRequired,
};

SubscriptionCompleteRoute.defaultProps = {};
