import { gql } from '@apollo/client';

const query = gql`
  query WidgetTasks($ids: [ID], $taskFilter: TaskFilter, $limit: Int) {
    taskList(ids: $ids, filter: $taskFilter, limit: $limit) {
      results {
        id
        accountId
        assigneeId
        isCompleted
        isRemoved
        parentTaskId
        creatorId
        lastUpdatedById
        assigneeIds
        completerId
        taskDefinitionId
        subject
        note
        subTaskIds
        assigneeType
        priority
        reminderTime
        creationTimeStamp
        matterId
        matter {
          id
          matterType {
            matterTypeId
            name
          }
          clients {
            id
            displayName
          }
          clientDisplayName @client
        }
        categories
        dueDate
        completionDate
        duration
        durationString
        durationSetByWorkflow
        nonRemovedSubTasksCount
        completedSubTasksCount
        lastAssignedById
      }
    }
  }
`;

const notificationIds = ['TaskingNotifications'];

export const WidgetTasks = {
  query,
  notificationIds,
};
