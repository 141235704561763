import { featureActive } from "@sb-itops/feature";

angular.module('sb.billing.webapp').factory('sbMyobValidator', DataValidator => {
  class Validator extends DataValidator {
    constructor(modelFn, viewFn) {
      super(modelFn);

      this._viewFn = viewFn;

      this._addValidation('companyFile',  this._conditionalValidator('companyFile', 'integrationEnabled', this._standardValidator('companyFile')));

      // add the custom validations to the data validator
      this._addValidation('legalFeesAccount', this.validateFeeAccountValid.bind(this));
      this._addValidation('clientExpensesAccount', this.validateExpenseAccountValid.bind(this));
      this._addValidation('clientSoftCostExpensesAccount', this.validateSoftCostExpenseAccountValid.bind(this));
      this._addValidation('operatingBankAccount', this.validateOperatingAccountValid.bind(this));
      this._addValidation('clientCostLiabilityAccount', this.validateClientCostLiabilityAccountValid.bind(this));
      this._addValidation('clientSoftCostLiabilityAccount', this.validateClientSoftCostLiabilityAccountValid.bind(this));

      // start fresh
      this.clearAllErrors();
    }

    accountPresent(acc) {
      return acc && acc.id !== -1;
    } 

    // validation helpers
    isExpenseAccountSameAsLegalFeeAccount() {
      const model = this._modelFn();
      return (
        model &&
        model.clientExpensesAccount &&
        model.legalFeesAccount &&
        model.legalFeesAccount.id === model.clientExpensesAccount.id
      );
    }

    isSoftCostExpenseAccountSameAsLegalFeeAccount() {
      const model = this._modelFn();
      return (
        featureActive('BB-13352') &&
        model &&
        model.clientSoftCostExpensesAccount &&
        model.legalFeesAccount &&
        model.legalFeesAccount.id === model.clientSoftCostExpensesAccount.id
      );
    }

    validateFeeAccountValid(currentModel) {
      const model = currentModel || this._modelFn();
      this.errors.legalFeesAccount = this._isModelDirty() && model.integrateGeneral && (!this.accountPresent(model.legalFeesAccount) || this.isExpenseAccountSameAsLegalFeeAccount() || this.isSoftCostExpenseAccountSameAsLegalFeeAccount());
      return !this.errors.legalFeesAccount;
    }

    validateExpenseAccountValid(currentModel){
      const model = currentModel || this._modelFn();
      this.errors.clientExpensesAccount = model.integrateGeneral && (!this.accountPresent(model.clientExpensesAccount) || this.isExpenseAccountSameAsLegalFeeAccount());
      return !this.errors.clientExpensesAccount;
    }

    validateSoftCostExpenseAccountValid(currentModel){
      const model = currentModel || this._modelFn();
      this.errors.clientSoftCostExpensesAccount = featureActive('BB-13352') && model.integrateGeneral && (!this.accountPresent(model.clientSoftCostExpensesAccount) || this.isSoftCostExpenseAccountSameAsLegalFeeAccount());
      return !this.errors.clientSoftCostExpensesAccount;
    }

    validateClientCostLiabilityAccountValid(currentModel){
      const model = currentModel || this._modelFn();
      this.errors.clientCostLiabilityAccount = model.integrateGeneral && !this.accountPresent(model.clientCostLiabilityAccount);
      return !this.errors.clientCostLiabilityAccount;
    }

    validateClientSoftCostLiabilityAccountValid(currentModel){
      const model = currentModel || this._modelFn();
      this.errors.clientSoftCostLiabilityAccount = featureActive('BB-13352') && model.integrateGeneral && !this.accountPresent(model.clientSoftCostLiabilityAccount);
      return !this.errors.clientSoftCostLiabilityAccount;
    }

    validateOperatingAccountValid(currentModel){
      const model = currentModel || this._modelFn();
      this.errors.operatingBankAccount = this._isModelDirty() && model.integrateGeneral && !this.accountPresent(model.operatingBankAccount);
      return !this.errors.operatingBankAccount;
    }
  }

  return Validator;
});
