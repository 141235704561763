import { sortBy, flatten, pluck } from 'lodash';
import { featureActive } from '@sb-itops/feature';

const LEAD_MATTER_TYPE = 1;

/**
 * @typedef {object} MatterTypeTreeLeaf
 * @property {string} id <category_name>/<matter_type_name>
 * @property {string} display matter type name
 * @property {Array.<string>} data matter type ids
 */

/**
 * @typedef {object} MatterTypeTree
 * @property {string} id category name
 * @property {string} display category name
 * @property {Array.<string>}  data list of matter type ids
 * @property {Array.<MatterTypeTreeLeaf>} leaves matter types
 */

/**
 * find and return the matter type categories needed for the tree.
 * @param {object} param
 * @param {Array.<string>} param.matterTypeIds - Matters for the firm
 * @param {object} param.matterTypes matter types keyed by matter type id
 * @returns {Array.<MatterTypeTree>} matterTypeCategories - Matter type categories
 */
export const buildMatterTypeTree = ({ matterTypeIds, matterTypes, selectedList }) => {
  let matterTypeTree = matterTypeIds.reduce((matterTypeByCategory, matterTypeId) => {
    const genericMatterTypeId = matterTypeId && matterTypeId.split('_')[0];
    const matterType = matterTypes[matterTypeId] || matterTypes[genericMatterTypeId];

    // Feature BB-6595: Display billable leads in the matter table
    if (matterType && (matterType.typeCategory !== LEAD_MATTER_TYPE || featureActive('BB-6595'))) {
      const { name, categories = [] } = matterType;

      categories.forEach((category) => {
        // eslint-disable-next-line no-param-reassign
        matterTypeByCategory[category] = matterTypeByCategory[category] || {
          [name]: [matterTypeId],
        };

        // eslint-disable-next-line no-param-reassign
        matterTypeByCategory[category][name] = matterTypeByCategory[category][name] || [];

        if (!matterTypeByCategory[category][name].includes(matterTypeId)) {
          matterTypeByCategory[category][name].push(matterTypeId);
        }
      });
    }

    return matterTypeByCategory;
  }, {});

  matterTypeTree = sortBy(
    Object.entries(matterTypeTree).map(([category, item]) => {
      const leaves = sortBy(
        Object.entries(item).map(([display, data]) => ({
          id: `${category}/${display}`,
          display,
          data,
          selected: selectedList && selectedList.includes(data[0]),
        })),
        'display',
      );

      const data = flatten(pluck(leaves, 'data'));

      return {
        id: category,
        display: category,
        data,
        leaves,
      };
    }),
    'display',
  );

  return matterTypeTree;
};

/**
 * finds and returns used matterType Ids from list of matters
 * @param {Array.<object>} matters matter entities
 * @returns {Array.<string>} matterTypeIds - list of used matter type ids
 */
export const findUsedMatterTypeIds = (matters) => {
  const usedMatterTypeIds = matters.map((matter) => matter.matterTypeId).filter((matterTypeId) => matterTypeId);

  // return only unique matter type ids;
  return [...new Set(usedMatterTypeIds)];
};
