import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@sb-itops/react';
import classNames from 'classnames';
import { AddInvoicePaymentModalBody } from './AddInvoicePaymentModalBody';
import Styles from './AddInvoicePaymentModal.module.scss';

export const ADD_INVOICE_PAYMENT_MODAL_ID = 'add-invoice-payment-modal';

export const AddInvoicePaymentModal = ({
  isModalLoading,
  onModalClose,
  invoice,
  // form
  formData,
  formErrors,
  submitFailed,
  formSubmitting,
  invoiceDueInCents,
  balance,
  printMethodOptions,
  paymentSourceOptions,
  paymentSourceOptionsLoading,
  trustChequeNumberLoading,
  footerErrorMessages,
  // UI
  showReasonField,
  isChequeMemoVisible,
  isReferenceReadOnly,
  isDirectPayment,
  isTrustPayment,
  isPdfOnTrustPaymentDisabled,
  isTrustChequePrintingActive,
  isWaiveRemainingBalanceDisabled,
  isSubmitDisabled,
  isSubmitLocked,
  overdrawWarningProps,
  overpaymentAmount,
  overpaymentAccountLabel,
  // callbacks
  onUpdateFieldValues,
  onChangePrintingMethod,
  onReasonChange,
  onSelectPaidBy,
  onPaymentSourceChange,
  onEffectiveDateChange,
  onReferenceChange,
  onModalButtonClick,
  // paid by typeahead
  defaultPaidByContactOptions,
  paidByContactOptions,
  paidByContactOptionsDataLoading,
  paidByContactOptionsHasMore,
  onFetchPaidByContactOptions,
  onFetchMorePaidByContactOptions,
}) => (
  <Modal
    className={classNames(Styles.addInvoicePaymentModal, isModalLoading && Styles.hideHeader)}
    modalHeaderClassName={Styles.modalHeaderContainer}
    modalBodyClassName={Styles.modalBodyContainer}
    modalFooterClassName={Styles.modalFooterContainer}
    isVisible
    title={`Add Payment for Invoice #${invoice?.invoiceNumber}`}
    isLoading={isModalLoading}
    body={
      <AddInvoicePaymentModalBody
        showReasonField={showReasonField}
        isChequeMemoVisible={isChequeMemoVisible}
        isReferenceReadOnly={isReferenceReadOnly}
        isDirectPayment={isDirectPayment}
        isTrustPayment={isTrustPayment}
        isPdfOnTrustPaymentDisabled={isPdfOnTrustPaymentDisabled}
        isTrustChequePrintingActive={isTrustChequePrintingActive}
        isWaiveRemainingBalanceDisabled={isWaiveRemainingBalanceDisabled}
        overdrawWarningProps={overdrawWarningProps}
        overpaymentAccountLabel={overpaymentAccountLabel}
        // form
        formData={formData}
        formErrors={formErrors}
        submitFailed={submitFailed}
        formDisabled={formSubmitting}
        invoiceDueInCents={invoiceDueInCents}
        balance={balance}
        printMethodOptions={printMethodOptions}
        paymentSourceOptions={paymentSourceOptions}
        paymentSourceOptionsLoading={paymentSourceOptionsLoading}
        trustChequeNumberLoading={trustChequeNumberLoading}
        overpaymentAmount={overpaymentAmount}
        // callbacks
        onUpdateFieldValues={onUpdateFieldValues}
        onChangePrintingMethod={onChangePrintingMethod}
        onReasonChange={onReasonChange}
        onSelectPaidBy={onSelectPaidBy}
        onPaymentSourceChange={onPaymentSourceChange}
        onEffectiveDateChange={onEffectiveDateChange}
        onReferenceChange={onReferenceChange}
        // paid by typeahead
        defaultPaidByContactOptions={defaultPaidByContactOptions}
        paidByContactOptions={paidByContactOptions}
        paidByContactOptionsDataLoading={paidByContactOptionsDataLoading}
        paidByContactOptionsHasMore={paidByContactOptionsHasMore}
        onFetchPaidByContactOptions={onFetchPaidByContactOptions}
        onFetchMorePaidByContactOptions={onFetchMorePaidByContactOptions}
      />
    }
    footer={
      <div className={Styles.modalFooter}>
        {!!footerErrorMessages?.length && (
          <div className={Styles.errorContainer}>
            {footerErrorMessages.map((err) =>
              typeof err === 'string' ? (
                <div key={err} className={Styles.errorRow}>
                  {err}
                </div>
              ) : null,
            )}
          </div>
        )}

        <Button
          className={Styles.processButton}
          onClick={onModalButtonClick}
          disabled={isSubmitDisabled} // Standard disabled behaviour and UI
          locked={isSubmitLocked} // Adds a spinner and prevents submission (but does not have the disabled UI)
          size="full-width"
        >
          Process
        </Button>
      </div>
    }
    onModalClose={onModalClose}
  />
);

AddInvoicePaymentModal.propTypes = {
  /** Modal */
  isModalLoading: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  invoice: PropTypes.object,
  // form
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  invoiceDueInCents: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  printMethodOptions: PropTypes.array.isRequired,
  paymentSourceOptions: PropTypes.array.isRequired,
  paymentSourceOptionsLoading: PropTypes.bool.isRequired,
  trustChequeNumberLoading: PropTypes.bool.isRequired,
  footerErrorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  overpaymentAmount: PropTypes.number.isRequired,
  // ui control
  showReasonField: PropTypes.bool.isRequired,
  isChequeMemoVisible: PropTypes.bool.isRequired,
  isReferenceReadOnly: PropTypes.bool.isRequired,
  isDirectPayment: PropTypes.bool.isRequired,
  isTrustPayment: PropTypes.bool.isRequired,
  isPdfOnTrustPaymentDisabled: PropTypes.bool.isRequired,
  isTrustChequePrintingActive: PropTypes.bool.isRequired,
  isWaiveRemainingBalanceDisabled: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isSubmitLocked: PropTypes.bool.isRequired,
  overdrawWarningProps: PropTypes.object.isRequired,
  overpaymentAccountLabel: PropTypes.string.isRequired,
  // callbacks
  onUpdateFieldValues: PropTypes.func.isRequired,
  onChangePrintingMethod: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  onSelectPaidBy: PropTypes.func.isRequired,
  onPaymentSourceChange: PropTypes.func.isRequired,
  onEffectiveDateChange: PropTypes.func.isRequired,
  onReferenceChange: PropTypes.func.isRequired,
  onModalButtonClick: PropTypes.func.isRequired,
  // paid by typeahead
  defaultPaidByContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  paidByContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  paidByContactOptionsDataLoading: PropTypes.bool.isRequired,
  paidByContactOptionsHasMore: PropTypes.bool.isRequired,
  onFetchPaidByContactOptions: PropTypes.func.isRequired,
  onFetchMorePaidByContactOptions: PropTypes.func.isRequired,
};

AddInvoicePaymentModal.defaultProps = {
  invoice: undefined,
};

AddInvoicePaymentModal.displayName = 'AddInvoicePaymentModal';
