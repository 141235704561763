angular.module('sb.billing.webapp').component('sbDataInvoiceTotalsByInvoice',
  {
    'require': {
      sbComposeCtrl: '^sbCompose'
    },
    'bindings': {invoiceId: '<', entityDataKey: '@'},
    'controller': function ($scope, sbLoggerService, sbInvoiceTotalsService) {
      'use strict';
      var that = this;
      var log = sbLoggerService.getLogger('sbDataInvoiceTotalsByInvoice');
      that.entityDataKey = 'invoiceTotals';

      $scope.$on('smokeball-data-update-sbInvoicingService', updateInvoiceTotals);
      $scope.$on('smokeball-data-update-sbInvoiceTotalsService', updateInvoiceTotals);
      $scope.$on('smokeball-saved-invoices', updateInvoiceTotals);

      that.$onChanges = function (changesObj) {
        extractBindingsChange('invoiceId', changesObj);
        extractBindingsChange('entityDataKey', changesObj);
        updateInvoiceTotals();
      };

      function extractBindingsChange(key, changesObj) {
        if (changesObj && changesObj[key] && changesObj[key].currentValue) {
          that[key] = changesObj[key].currentValue;
        }
      }

      function updateInvoiceTotals() {
        var invoiceTotals = sbInvoiceTotalsService.getTotalsForInvoiceId(that.invoiceId);
        log.info('updating invoice totals : ', invoiceTotals);
        that.sbComposeCtrl.setComposeData({dataType: that.entityDataKey, data: invoiceTotals}, that.entityDataKey);
      }

    }

  }
);
