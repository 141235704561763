import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import { getUniqueDebtorIdsForInvoices } from '@sb-billing/business-logic/invoice/services';
import { getInvoiceLatestVersion as getInvoiceById } from '@sb-billing/redux/invoices';
import { selectors } from '@sb-billing/redux/invoice-pre-draft';
import { InvoiceEmailModal } from './InvoiceEmailModal';
import { convertToInvoiceEmailRequests as marshallNoTabForm } from '../invoice-email-form';
import { convertToInvoiceEmailRequests as marshallMultiTabForm } from '../invoice-email-tab-per-debtor-form';

const { getVersionById: getPreDraftInvoiceById } = selectors;
const getInvoiceOrPreDraftInvoiceById = (state, preDraftMode) => (invoiceId) =>
  (preDraftMode && getPreDraftInvoiceById(state, { invoiceId })) || getInvoiceById(invoiceId);

const mapStateToProps = (
  state,
  {
    scope,
    isVisible,
    invoiceIds,
    debtorId,
    consolidateEmails,
    preDraftMode,
    quickPaymentsTotalAmount,
    onSend,
    onClose,
    sbInvoiceSendService,
  },
) => {
  const debtorIds = debtorId
    ? [debtorId]
    : getUniqueDebtorIdsForInvoices({
        invoiceIds,
        getInvoiceById: getInvoiceOrPreDraftInvoiceById(state, preDraftMode),
      });

  // get matterIds now and pass down as the invoiceIds we pass may not exist in cache for preDraft
  const matterIds = getUniqueMatterIdsFromInvoiceIds({
    invoiceIds,
    getInvoiceById: getInvoiceOrPreDraftInvoiceById(state, preDraftMode),
  });

  const useMultiTabForm = invoiceIds.length === 1 && debtorIds.length > 1;

  // The following form selectors effectively create a tight coupling with the InvoiceEmailForm and InvoiceEmailTabPerDebtorForm
  // containers. It's not ideal, but duplicating this logic into the containers gets weird an messy.
  const { selectors: formSelectors } = withScopedFeature({ scope, state })(forms);

  const atLeastOneTabInUse =
    !formSelectors.getIsInitialised() ||
    !useMultiTabForm ||
    Object.values(formSelectors.getFormFields().emailData).some(
      (debtorEmailData) => debtorEmailData.includeDebtor.value,
    );

  const isSubmitDisabled = !formSelectors.getIsInitialised() || !formSelectors.getIsValid() || !atLeastOneTabInUse;

  return {
    scope,
    isVisible,
    isSubmitDisabled,
    invoiceIds,
    debtorIds,
    matterIds,
    useMultiTabForm,
    consolidateEmails: consolidateEmails && !!debtorId && invoiceIds.length > 1,
    onSend: () => {
      const marshallFn = useMultiTabForm ? marshallMultiTabForm : marshallNoTabForm;
      const invoiceEmailRequests = marshallFn({
        state,
        scope,
        invoiceIds,
        debtorIds,
        consolidate: consolidateEmails && !!debtorId && invoiceIds.length > 1,
      });
      onSend(invoiceEmailRequests);
    },
    onPreview: (invoiceEmailRequest) =>
      sbInvoiceSendService.createInvoiceEmailPreviewP({
        invoiceEmailRequest,
        preDraftMode,
        quickPaymentsTotalAmount,
      }),
    onClose,
  };
};

const getUniqueMatterIdsFromInvoiceIds = ({ invoiceIds, getInvoiceById: getInvoiceByIdFn }) => {
  if (!Array.isArray(invoiceIds)) return [];

  const matterIds = new Set();
  invoiceIds.forEach((invoiceId) => {
    const { matterId } = getInvoiceByIdFn(invoiceId) || {};
    if (matterId) {
      matterIds.add(matterId);
    }
  });

  return Array.from(matterIds);
};

export const InvoiceEmailModalContainer = withReduxStore(connect(mapStateToProps, undefined)(InvoiceEmailModal));

InvoiceEmailModalContainer.displayName = 'InvoiceEmailModalContainer';

InvoiceEmailModalContainer.propTypes = {
  scope: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  debtorId: PropTypes.string, // Forces 'single debtor mode', i.e. components will ignore different debtors in invoice ids.
  consolidateEmails: PropTypes.bool, // Generated InvoiceEmailRequests will contain the consolidate flag if there is more than one invoice id.
  preDraftMode: PropTypes.bool, // Includes an invoice that has unsaved changes (create/edit invoice)
  quickPaymentsTotalAmount: PropTypes.number, // Total amount in quickPayments, to be subtracted from total owing for the invoice/debtor in preview
  warningMessage: PropTypes.string, // If present, displays a warning message above the send button if present.
  onSend: PropTypes.func.isRequired,
  sbInvoiceSendService: PropTypes.object.isRequired, // Need this until functionality moved to business logic.
  onClose: PropTypes.func.isRequired,
};

InvoiceEmailModalContainer.defaultProps = {
  scope: 'invoice-email-modal',
  debtorId: undefined,
  consolidateEmails: false,
  preDraftMode: false,
  quickPaymentsTotalAmount: undefined,
  warningMessage: undefined,
};

export default InvoiceEmailModalContainer;
