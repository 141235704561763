angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/accounting-transactions';

  $stateProvider
    .state('home.billing.support.accounting-transactions', {
      url: '/accounting-transactions',
      templateUrl: BASE_DIRECTORY + '/accounting-transactions.html',
      controller: 'SupportAccountingTransactionsController',
      controllerAs: '$ctrl',
      data: {
        authorized: true
      }
    });

});
