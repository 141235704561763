import { createActionPromise } from 'web/ng-composable-components/actions/action-factory';
import { payToVendor } from '@sb-billing/redux/bank-account/pay-to-vendor';
import * as messageDisplay from '@sb-itops/message-display';
import { printMethodsByValue } from '@sb-billing/business-logic/cheques';

angular.module('sb.billing.webapp').directive('sbActionPayToVendor', function () {
  'use strict';

  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      ctrl.group.setAction(payToVendorAction);

      function payToVendorAction(payment) {
        const actionP = createActionPromise({
          composeGroup: ctrl.group,
          action: payToVendor,
        })

        // clone the payment and mutate as the backend requires the chequePrintMethod to be a number, but 
        // cbui-vendor-payment-entry-split-payors relies on it remaining a string post save.
        const vendorPayment = { ...payment, chequePrintMethod: printMethodsByValue[payment.chequePrintMethod].code} 
        
        return actionP(vendorPayment)
          .catch(() => {
            messageDisplay.error(
              messageDisplay.builder()
              .text('Failed to process vendor payment')
            );
          });
      }

    }
  };
});
