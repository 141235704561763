'use strict';

const consolidateChequeTransactions = ({ transactions = [] }) => {
  const txToChequeId = {};
  const unprocessedReversalTransactions = {};

  return transactions.reduce((acc, transaction) => {
    // We are only interested in transactions without cheque ids if they are reversals,
    // which is indicated by the presence of reversedToTransactionId.
    const isReversalTransaction = !transaction.chequeId && transaction.reversedFromTransactionId;
    if (isReversalTransaction) {
      // The consolidateChequeTransactions() function makes no assumptions about the ordering of the transactions passed to it.
      // Therefore, it is possible that reversal transactions will be iterated prior to the original transaction.
      // If that happens, we save the reversal for handling after the initiating transaction has been processed.
      const consolidatedTransaction = acc[txToChequeId[transaction.reversedFromTransactionId]];
      if (!consolidatedTransaction) {
        unprocessedReversalTransactions[transaction.id] = transaction;
        return acc;
      }

      // Consolidate the reversal transaction into the consolidated transaction.
      consolidatedTransaction.description = transaction.description;
      consolidatedTransaction.reason = transaction.reason;
      consolidatedTransaction.isReversed = true;
      consolidatedTransaction.isDeleted = transaction.isHidden;
      consolidatedTransaction.note = transaction.note;

      return acc;
    }

    // Since we've already handled reversals at this point, we are not interested in a transaction if it contains
    // no cheque id. Filter it out.
    if (!transaction.chequeId) {
      return acc;
    }

    const unprocessedReversal = unprocessedReversalTransactions[transaction.reversedToTransactionId] || {};

    const consolidatedTransaction = acc[transaction.chequeId] || {
      chequeId: transaction.chequeId,
      amount: 0,
      transactionIds: [],
      contactIds: [],
      paymentIds: [],
      matterIds: [],
      description: unprocessedReversal.description || transaction.description,
      reason: unprocessedReversal.reason || transaction.reason,
      isReversed: !!unprocessedReversal.reversedFromTransactionId,
      isDeleted: unprocessedReversal.isHidden,
      bankAccountType: transaction.bankAccountType,
      bankAccountId: transaction.bankAccountId,
      userId: transaction.userId,
      source: transaction.source,
      transactionType: transaction.type,
      note: unprocessedReversalTransactions.note || transaction.note,
    };

    consolidatedTransaction.amount += Math.abs(transaction.amount);
    consolidatedTransaction.transactionIds.push(transaction.id);

    if (transaction.contactId) {
      consolidatedTransaction.contactIds.push(transaction.contactId);
    }

    if (transaction.paymentId) {
      consolidatedTransaction.paymentIds.push(transaction.paymentId);
    }

    if (transaction.matterId && !consolidatedTransaction.matterIds.includes(transaction.matterId)) {
      consolidatedTransaction.matterIds.push(transaction.matterId);
    }

    delete unprocessedReversalTransactions[transaction.reversedToTransactionId];

    txToChequeId[transaction.id] = transaction.chequeId;
    acc[transaction.chequeId] = consolidatedTransaction;

    return acc;
  }, {});
};

module.exports = {
  consolidateChequeTransactions,
};
