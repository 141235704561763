import {
  decodeHtml,
  encodeHtml,
  stringsToEnums,
  enumsToStrings,
} from '@sb-billing/encode-decode-html-invoice-settings';

/*
  Reuse of the InvoiceTemplateSettings component requires the marshalling of certain fields for
  compatibility with the data store and the component.

  Existing methods for the marshalling found in the @sb-billing/encode-decode-html-invoice-settings package
  are decorated for local use in this MatterInvoiceSettings component.
 */

/**
 * Encodes the footer input field for use with the data store.
 * @param {string} rawInput - The raw footer input field.
 * @returns {string} The encoded footer input field.
 */
const encodeFooter = (rawInput) => encodeHtml({ footer: rawInput }).footer;

/**
 * Decodes the footer input field for use with the component.
 * @param {string} encodedInput - The encoded footer input field.
 * @returns {string} The raw footer input field.
 */
const decodeFooter = (encodedInput) => decodeHtml({ footer: encodedInput }).footer;

/**
 * Converts the title line 1 option from a string to a numeric enum value.
 * @param {string} enumString - The string representation of the enum.
 * @returns {number} The enum value.
 */
const titleLine1StringToEnum = (enumString) =>
  stringsToEnums({ titleLine1Option: enumString || 'None' }).titleLine1Option;

/**
 * Converts the title line 2 option from a string to a numeric enum value.
 * @param {string} enumString - The string representation of the enum.
 * @returns {number} The enum value.
 */
const titleLine2StringToEnum = (enumString) =>
  stringsToEnums({ titleLine2Option: enumString || 'None' }).titleLine2Option;

/**
 * Converts the title line 1 option from a numeric enum value to an enum string.
 * @param {number} enumValue - The enum value.
 * @returns {string} The string representation of the enum.
 */
const titleLine1EnumToString = (enumValue) => enumsToStrings({ titleLine1Option: enumValue || 0 }).titleLine1Option;

/**
 * Converts the title line 2 option from a numeric enum value to an enum string.
 * @param {number} enumValue - The enum value.
 * @returns {string} The string representation of the enum.
 */
const titleLine2EnumToString = (enumValue) => enumsToStrings({ titleLine2Option: enumValue || 0 }).titleLine2Option;

export {
  encodeFooter,
  decodeFooter,
  titleLine1StringToEnum,
  titleLine2StringToEnum,
  titleLine1EnumToString,
  titleLine2EnumToString,
};
