'use strict';

/**
 * @typedef { import('../types.js').EzyCollectChargeFormData } EzyCollectChargeFormData
 */

/**
 * getPaymentSource
 *
 * @param {Object} params
 * @returns {string} A human readable payment source.
 */
const getPaymentSource = () => 'Credit Card';

module.exports = {
  getPaymentSource,
};
