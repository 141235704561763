angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/bank-reconciliation-pdf';

  $stateProvider
    .state('home.billing.bank-reconciliation-pdf', {
      url: '/bank-reconciliation-pdf/:bankReconciliationId/:waitingForNotification',
      templateUrl: BASE_DIRECTORY + '/bank-reconciliation-pdf.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'reconciliation'],
        accountType: 'TRUST',
        tab: { type: 'bank-reconciliation-pdf' }
      }
    });

});
