angular.module('sb.billing.webapp').controller('SbWatcherWatchCtrl', function (sbWatcherNotificationService, sbMessageDisplayService) {
  'use strict';
  const that = this;

  const colourRanges = [
    {count: 8000, colour: '#FF0000'},
    {count: 4000, colour: '#FF6100'},
    {count: 2000, colour: '#FFA100'},
    {color: '#00FF00'}
  ];

  that.$onInit = onInit;
  that.displayWatcherCount = displayWatcherCount;
  that.currentWatcherDetails = null;
  that.currentColour = '#FF0000';

  function onInit() {
    sbWatcherNotificationService.registerListener(onNewWatcherDetails);
  }

  function onNewWatcherDetails(watcherDetails) {
    that.currentWatcherDetails = watcherDetails;
    updateColour(watcherDetails.count || 0);
  }

  function displayWatcherCount() {
    if (that.currentWatcherDetails) {
      sbMessageDisplayService.info(
        sbMessageDisplayService
          .builder()
          .text(`The watcher count for ${that.currentWatcherDetails.path} is: ${that.currentWatcherDetails.count}`)
          .group(`${that.currentWatcherDetails.path}-watch`)
          .timeout(-1) //Disable
      );
    }
  }

  function updateColour(watcherCount) {
    colourRanges.some(colourRange => {
      if (!colourRange.count || watcherCount >= colourRange.count) {
        that.currentColour = colourRange.colour;
        return true;
      }
    });
  }
});
