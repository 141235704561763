angular.module('sb.billing.webapp').factory('sbCbuiSendTransactionReceiptValidator', (DataValidator) => {
  class Validator extends DataValidator{
    constructor(modelFn) {
      super(modelFn);
  
      // add validations for this instance
      this._addValidation('from', this._singleEmailValidator('from'));
      this._addValidation('to', this._multipleEmailValidator('to'));
      this._addValidation('cc', this._multipleEmailValidator('cc'));
      this._addValidation('bcc', this._multipleEmailValidator('bcc'));
      
      // start fresh
      this.clearAllErrors();
    }
  }

  return Validator;
})
 