import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner, Button, buttonTypes } from '@sb-itops/react';

import Styles from './InvoiceEmailTabPerDebtorForm.module.scss';

import { InvoiceEmailForm } from '../../../components/invoice-email-form';

export const InvoiceEmailTabPerDebtorForm = React.memo((props) => {
  const { isLoading, selectedTabId, tabs, onSelectedDebtorChanged, formData, onFormDataUpdated } = props;
  const { previewSubject, previewMessage, onPreviewToggled, isPreviewMode } = props;

  if (isLoading) {
    return (
      <div className={Styles.invoiceEmailTabPerDebtorForm}>
        <Spinner height={500} message="Loading" />
      </div>
    );
  }

  const onFieldValueUpdated = (field, value) => onFormDataUpdated(selectedTabId, field, value);

  const getTabIndicatorClasses = ({ tabValid, debtorIncluded }) => {
    if (!debtorIncluded) {
      return classnames('icon-mail-block', Styles.tabIndicator);
    }

    if (!tabValid) {
      return classnames('icon-alert-1', Styles.tabIndicator, Styles.redText);
    }

    return classnames('icon-check-circle-2', Styles.tabIndicator, Styles.blueText);
  };

  return (
    <div className={Styles.invoiceEmailTabPerDebtorForm}>
      <div className={Styles.tabList}>
        {tabs.map(({ id, text, tabValid, debtorIncluded }) => (
          <Button
            key={id}
            type={buttonTypes.tab}
            highlight={selectedTabId === id}
            onClick={() => onSelectedDebtorChanged(id)}
          >
            <div className={getTabIndicatorClasses({ tabValid, debtorIncluded })} />
            <span title={text} className={Styles.tabText}>
              {text}
            </span>
          </Button>
        ))}
      </div>
      <div className={Styles.formFields}>
        <InvoiceEmailForm
          {...formData}
          onFieldValueUpdated={onFieldValueUpdated}
          previewSubject={previewSubject}
          previewMessage={previewMessage}
          onPreviewToggled={onPreviewToggled}
          isPreviewMode={isPreviewMode}
          selectedTabId={selectedTabId}
          showIncludeDebtor
          showPreviewButton
        />
      </div>
    </div>
  );
});

InvoiceEmailTabPerDebtorForm.displayName = 'InvoiceEmailTabPerDebtorForm';

InvoiceEmailTabPerDebtorForm.propTypes = {
  isLoading: PropTypes.bool,
  selectedTabId: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  onSelectedDebtorChanged: PropTypes.func,
  formData: PropTypes.object,
  isPreviewMode: PropTypes.bool,
  previewSubject: PropTypes.string,
  previewMessage: PropTypes.string,
  onFormDataUpdated: PropTypes.func,
  onPreviewToggled: PropTypes.func,
};

InvoiceEmailTabPerDebtorForm.defaultProps = {
  isLoading: false,
  selectedTabId: undefined,
  tabs: undefined,
  formData: undefined,
  isPreviewMode: false,
  previewSubject: '',
  previewMessage: '',
  onSelectedDebtorChanged: () => {},
  onFormDataUpdated: () => {},
  onPreviewToggled: () => {},
};

export default InvoiceEmailTabPerDebtorForm;
