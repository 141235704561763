import { gql } from '@apollo/client';

const query = gql`
  query RateSetEntities($ids: [ID]) {
    rateSets(ids: $ids) {
      id
      name
      isHidden
      versions {
        versionId
        validFrom
        validTo
        ratesPerStaff {
          staffId
          rate
        }
      }
    }
  }
`;

const notificationIds = ['FeesNotifications.RateSetUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const RateSetEntities = {
  query,
  notificationIds,
};
