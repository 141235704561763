import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { getById, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import BankAccountNameDisplay from './BankAccountNameDisplay';

const mapStateToProps = (state, { bankAccountId, t }) => {
  const decodedBankAccountId = bankAccountId;
  const bankAccount = getById(decodedBankAccountId);
  const bankAccountLabel = getBankAccountName(bankAccount, t);

  return {
    name: bankAccountLabel,
    closed: isTrustAccountClosed(bankAccount),
  };
};

const BankAccountNameDisplayContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps)(BankAccountNameDisplay)),
);

BankAccountNameDisplayContainer.displayName = 'BankAccountNameDisplay';

BankAccountNameDisplayContainer.propTypes = {
  bankAccountId: PropTypes.string,
};

BankAccountNameDisplayContainer.defaultProps = {
  bankAccountId: '',
};

export default BankAccountNameDisplayContainer;
