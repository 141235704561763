'use strict';

const logFactory = require('@sb-itops/fe-logger');
const log = logFactory.getLogger('sbUnsavedChanges');

const onSaveCbs = [];
let unsavedChanges = {};

module.exports = {
  onSaveMemory,
  saveMemory,
  loadMemory,
  clearMemory,
  changesExist,
};

function onSaveMemory (cb) {
  log.info(`registering callback`);
  onSaveCbs.push(cb);
}

function saveMemory (key, value) {
  log.info(`save: ${key}`, value);
  if (!key) {
    throw new Error('sbUnsavedChangesService: key must be specified');
  }

  if (value === undefined) {
    delete unsavedChanges[key];
  } else {
    unsavedChanges[key] = value;
  }

  _.each(onSaveCbs, (cb) => {
    cb(key);
  });
}

function loadMemory (key) {
  log.info(`load: ${key}`, unsavedChanges[key]);
  if (!key) {
    throw new Error('sbUnsavedChangesService: key must be specified');
  }

  return unsavedChanges[key];
}

function clearMemory () {
  unsavedChanges = {};
}

function changesExist (key) {
  return !!unsavedChanges[key];
}
