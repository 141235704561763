import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { MultiContactCorrespondenceDetails } from '../multi-contact-correspondence-details';
import Styles from './MultiContactCorrespondenceStatusIndicator.module.scss';

const indicatorClassesByStatus = {
  EMAIL_ERROR: classnames('icon-mail-delete', Styles.redText),
  COMMUNICATE_ERROR: classnames('icon-comm-cross', Styles.redText),
  NOT_SENT: classnames('icon-mail-1', Styles.greyText),
  IN_PROGRESS: classnames('icon-sync-2', Styles.blueText),
  EMAIL_PARTIALLY_SENT: classnames('icon-mail-attention', Styles.greenText),
  COMMUNICATE_PARTIALLY_SENT: classnames('icon-comm-exclamation', Styles.greenText),
  EMAIL_SUCCESS: classnames('icon-mail-checked', Styles.greenText),
  COMMUNICATE_SUCCESS: classnames('icon-comm-check', Styles.greenText),
};

export const MultiContactCorrespondenceStatusIndicator = ({
  invoiceId,
  indicatorStatus,
  invoiceIsNotDraftOrVoid,
  allowContextMenu,
  onIconClicked,
  allowResend,
  onResendClicked,
  onSendViaMenuClicked,
}) => {
  const handleOnIndicatorIconClicked = (evt) => {
    evt.stopPropagation();
    if (invoiceIsNotDraftOrVoid) {
      onIconClicked(evt);
    }
  };

  const messageBody = () => (
    <MultiContactCorrespondenceDetails
      invoiceId={invoiceId}
      allowResend={allowResend}
      onResendClicked={onResendClicked}
      onSendViaMenuClicked={onSendViaMenuClicked}
    />
  );

  return (
    <div className={Styles.multiContactCorrespondenceStatusIndicator} onClick={handleOnIndicatorIconClicked}>
      <div className={Styles.iconContainer}>
        {allowContextMenu && (
          <ContextMenu body={messageBody}>
            <i className={indicatorClassesByStatus[indicatorStatus]} />
          </ContextMenu>
        )}
        {!allowContextMenu && <i className={indicatorClassesByStatus[indicatorStatus]} />}
      </div>
    </div>
  );
};

MultiContactCorrespondenceStatusIndicator.displayName = 'MultiContactCorrespondenceStatusIndicator';

MultiContactCorrespondenceStatusIndicator.propTypes = {
  invoiceId: PropTypes.string,
  indicatorStatus: PropTypes.string.isRequired,
  invoiceIsNotDraftOrVoid: PropTypes.bool.isRequired,
  allowContextMenu: PropTypes.bool.isRequired,
  onIconClicked: PropTypes.func,
  allowResend: PropTypes.bool,
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};

MultiContactCorrespondenceStatusIndicator.defaultProps = {
  invoiceId: undefined,
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
  allowResend: false,
  onIconClicked: () => {},
};

export default MultiContactCorrespondenceStatusIndicator;
