import PropTypes from 'prop-types';
import React from 'react';
import { Button, CardContainer, FloatingCard } from '@sb-itops/react';
import Styles from './LawpayGetStartedCard.module.scss';

export const LawpayGetStartedCard = ({ onConnect }) => (
  <div className={Styles.lawpayGetStartedCardContainer}>
    <CardContainer>
      <FloatingCard>
        <div className={Styles.lawpayGetStartedCard}>
          <div className={Styles.heading}>Getting Started</div>
          <div className={Styles.content}>Accept secure credit card and eCheck payments through LawPay. </div>
          <div className={Styles.content}>
            Once you have a LawPay account, click below to connect your account with Smokeball.
          </div>
          <div className={Styles.buttonBar}>
            <Button className={Styles.applyNowButton} onClick={onConnect}>
              Connect To LawPay
            </Button>
          </div>
        </div>
      </FloatingCard>
    </CardContainer>
  </div>
);

LawpayGetStartedCard.displayName = 'LawpayGetStartedCard';

LawpayGetStartedCard.propTypes = {
  onConnect: PropTypes.func.isRequired,
};
