'use strict';

const { bankAccountTypeTranslationKeyByTypeWithAccount } = require('../entities/constants');

/**
 * @param {Object} bankAccount Bank Account entity
 * @param {function} t Translate function
 * @param {Object} options.display return display name before account name
 * @returns {string}
 */
function getBankAccountName(bankAccount, t, { display = true } = {}) {
  if (!bankAccount) {
    return undefined;
  }

  return (
    (display && bankAccount.displayName) ||
    bankAccount.accountName ||
    getAccountNameFromType(bankAccount.accountType, t)
  );
}

function getAccountNameFromType(accountType, t) {
  if (typeof t !== 'function') {
    // eslint-disable-next-line no-console
    console.error(`Missing t function`);
    return '';
  }

  const translationKey = bankAccountTypeTranslationKeyByTypeWithAccount[accountType];
  if (translationKey) {
    return t(translationKey);
  }

  return 'Account';
}

module.exports = {
  getBankAccountName,
};
