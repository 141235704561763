import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').directive('sbFeature', function () {
  'use strict';
  return {
    link: function link(scope, element, attrs) {
      if (!featureActive(attrs.sbFeature)) {
        element.hide();
      }
    }
  };
});

// inspiration taken from https://stackoverflow.com/questions/20325480/angularjs-whats-the-best-practice-to-add-ngif-to-a-directive-programmatically

angular.module('sb.billing.webapp').directive('sbFeatureIf', function (ngIfDirective) {
  const ngIf = ngIfDirective[0];

  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority - 1,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    // this is how ng-if *doesnt* mess up multi-directive transclusion.
    // its a part of the private angular API (hence the $$), but since angular is not under active development
    // it should be safe to use without fear of it changing :)
    $$tlb: true,
    link: function (scope, element, attrs) {
      const initialNgIf = attrs.ngIf;
      attrs.ngIf = () => featureActive(attrs.sbFeatureIf) && (initialNgIf ? scope.$eval(initialNgIf) : true);
      ngIf.link.apply(ngIf, arguments);
    }
  };
});

angular.module('sb.billing.webapp').directive('sbFeatureNotIf', function (ngIfDirective) {
  const ngIf = ngIfDirective[0];

  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority - 1,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    $$tlb: true,
    link: function (scope, element, attrs) {
      const initialNgIf = attrs.ngIf;
      attrs.ngIf = () => !featureActive(attrs.sbFeatureNotIf) && (initialNgIf ? scope.$eval(initialNgIf) : true);
      ngIf.link.apply(ngIf, arguments);
    }
  };
});
