import uuid from '@sb-itops/uuid';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { timekeeperClassifications } from '@sb-firm-management/business-logic/firms';
import { getById as getStaffFeeConfigById } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbStaffFeeConfigurationService',
  keyPath: 'staffId',
});

const buildStaffFeeConfigOpdate = (staffFeeConfig) => {
  const timekeeperMapping = timekeeperClassifications[staffFeeConfig.timekeeperClassificationCode];

  return {
    ...staffFeeConfig,
    timekeeperClassificationCode: {
      name: timekeeperMapping?.label || '',
      value: timekeeperMapping?.value || '',
    },
  };
};

export const saveStaffFeeConfiguration = (staffFeeConfig) => {
  const saveStaffFeeConfigurationThunk = async () => {
    let newStaffFeeConfig = { ...staffFeeConfig, versionId: uuid() };

    const existingStaffFeeConfig = getStaffFeeConfigById(staffFeeConfig.staffId);
    if (existingStaffFeeConfig) {
      newStaffFeeConfig = { ...existingStaffFeeConfig, ...newStaffFeeConfig };
    }

    const opdate = buildStaffFeeConfigOpdate(newStaffFeeConfig);

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: [opdate] });

    // Apply the save in the backend.
    try {
      const path = `/billing/staff-fee-configuration/post/:accountId/staff/${newStaffFeeConfig.staffId}`;
      const fetchOptions = { body: JSON.stringify(newStaffFeeConfig) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [opdate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveStaffFeeConfigurationThunk);
};
