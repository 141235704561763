import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import domain from '../domain';

const api = cacheFactory({
  domain,
  immutable: false,
  name: 'billing-configuration',
  keyPath: 'matterId',
  ngCacheName: 'sbBillingConfigurationService',
  syncType: syncTypes.SINCE,
});

export const { getById, getList, getMap, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbBillingConfigurationService',
  keyPath: 'matterId',
});

export const getAllCurrentBillingConfigurations = () => {
  const configs = getList();
  return configs.map((config) => config.currentWorkItem);
};

export const getCurrentConfigurationByMatterId = (matterId) => {
  const config = getById(matterId) || {};
  return config.currentWorkItem;
};

export const getDebtorId = (matterId) => {
  const billingConfig = getCurrentConfigurationByMatterId(matterId);
  return billingConfig && billingConfig.debtorEntityId;
};
