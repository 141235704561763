import * as messageDisplay from '@sb-itops/message-display';
import { debounce } from '@sb-itops/nodash';
import uuid from '@sb-itops/uuid';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

const DEBOUNCE_WAIT_MS = 10_000;
const CLEAR_UPDATED_VALUES_DELAY_MS = 3_000;

export async function onSaveExpense({ _currentItem, ...updatedExpense }) {
  const marshalledData = {
    // Mandatory properties
    description: _currentItem.description,
    expenseId: updatedExpense.id,
    expenseVersionId: uuid(),
    price: _currentItem.price,
    quantity: _currentItem.quantity,

    // Updated properties
    ...updatedExpense,
  };

  // If needed, we can use the response ({ expenseId, version }) from the web
  // command dispatcher to perform additional tasks
  await dispatchCommand({ type: 'Billing.Expenses.Commands.SaveExpense', message: marshalledData });
}

export async function onSaveFee({ _currentItem, ...updatedFee }) {
  const marshalledData = {
    // Mandatory properties
    description: _currentItem.description,
    duration: _currentItem.duration,
    feeId: updatedFee.id,
    feeType: _currentItem.feeType,
    feeVersionId: uuid(),
    rate: _currentItem.rate,

    // Updated properties
    ...updatedFee,
  };

  // If needed, we can use the response ({ feeId, version }) from the web
  // command dispatcher to perform additional tasks
  await dispatchCommand({ type: 'Billing.Fees.Commands.SaveFee', message: marshalledData });
}

/**
 * Debounced Save
 *
 * @param {Object} params
 * @param {function} params.setEditedFields Set/reset edited fields value in parent container
 * @param {function} params.onSave Entry type specific save function to execute web command processor dispatch
 * @returns {{flush: function}}
 */
export function debouncedSave({ setEditedFields, onSave }) {
  // Keeps track of all the fees/expenses and the fields we have yet to save for each
  let editedFields = {};

  // While a fee/expense is being saved, this tracks those changes so any
  // changes made while a save is in progress are properly queued up and not
  // wiped out when the save finishes
  let fieldsBeingSaved = {};

  const debounced = debounce(async () => {
    const entriesWithUpdates = Object.values(editedFields);
    Object.assign(fieldsBeingSaved, editedFields);
    editedFields = {};
    let errorOnSave = 0;

    for (let index = 0; index < entriesWithUpdates.length; index += 1) {
      try {
        // Wait in loop to prevent a deluge of save commands swamping the backend
        // eslint-disable-next-line no-await-in-loop
        await onSave(entriesWithUpdates[index]);
      } catch (err) {
        errorOnSave += 1;
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    fieldsBeingSaved = {};

    // Artificial delay before clearing the edited fields values to give the
    // route a chance to fetch newly saved entities, otherwise there may be
    // a flash of old values
    await new Promise((resolve) => {
      setTimeout(resolve, CLEAR_UPDATED_VALUES_DELAY_MS);
    });

    // Set/reset edited fields
    setEditedFields(editedFields);

    if (errorOnSave) {
      messageDisplay.error(
        'Something went wrong saving a fee or expense and your changes have been rolled back. Please check your changes carefully and try again',
      );
    }
  }, DEBOUNCE_WAIT_MS);

  const saveFunc = (id, field, value) => {
    editedFields[id] = { ...(editedFields[id] || {}), [field]: value, id };

    // If a save is in progress, we would like to display the changes being
    // saved without also marking those fields to be saved
    setEditedFields({
      ...fieldsBeingSaved,
      ...editedFields,
      [id]: { ...(fieldsBeingSaved[id] || {}), ...editedFields[id] },
    });

    debounced();
  };

  saveFunc.flush = debounced.flush;

  return saveFunc;
}
