'use strict';

const { sortByOrder } = require('@sb-itops/nodash');
const { hasFacet, facets } = require('@sb-itops/region-facets');
const { isTransactionBulkDeposit } = require('../../../transactions/services');

/**
 * Filters and groups bulk deposit transactions from a list of transactions.
 *
 * @param {Object} params
 * @param {Array<Transaction>} params.transactions - Array of transactions
 * @param {boolean} params.runByEnteredDate - Whether to filter by entered date
 * @returns {Array<Transaction>} The filtered transactions.
 */

function filterBulkDepositTransactions({ transactions, runByEnteredDate }) {
  const bulkDepositTransactions = transactions.reduce((acc, tx) => {
    if (isTransactionBulkDeposit({ transaction: tx })) {
      const bulkGroupId = `${tx.paymentId}_${tx.type}`;
      if (acc[bulkGroupId]) {
        acc[bulkGroupId].push({ ...tx, bulkGroupId });
      } else {
        acc[bulkGroupId] = [{ ...tx, bulkGroupId }];
      }
    }

    return acc;
  }, {});

  const groupedBulkDepositTransactions = groupBulkDepositTransactions({ bulkDepositTransactions, runByEnteredDate });

  return groupedBulkDepositTransactions;
}

// The logic is similar to groupTrustToOfficeTransactions where:
// All deposits within bulk deposit should have 0 value the credit/debits until the last one
// Move the credit/debits into MultiAmount column
function groupBulkDepositTransactions({ bulkDepositTransactions, runByEnteredDate }) {
  const removedValues = {
    credit: 0,
    debit: 0,
    balance: 0,
  };

  const totals = {};

  const trackTotals = (tx) => {
    if (!totals[tx.bulkGroupId]) {
      totals[tx.bulkGroupId] = {};
    }
    if (!totals[tx.bulkGroupId][tx.transactionId]) {
      totals[tx.bulkGroupId][tx.transactionId] = {};
    }
    totals[tx.bulkGroupId][tx.transactionId] = {
      credit: tx.credit,
      debit: tx.debit,
      balance: tx.balance,
    };
  };

  const getTotals = (bulkGroupId) =>
    Object.values(totals[bulkGroupId]).reduce(
      (acc, transaction) => ({
        credit: acc.credit + transaction.credit,
        debit: acc.debit + transaction.debit,
        balance: acc.balance + transaction.balance,
      }),
      removedValues,
    );

  const primarySortColumn =
    hasFacet(facets.transactionsByEnteredDate) && runByEnteredDate ? 'creationDate' : 'effectiveDate';
  const secondarySortColumn =
    hasFacet(facets.transactionsByEnteredDate) && runByEnteredDate ? 'effectiveDate' : 'creationDate';

  const txns = Object.values(bulkDepositTransactions)
    // Sorting the transactions to match the final sort, otherwise the credit/debit/balance
    // values may not appear on the last line
    .map((childTransactions) =>
      sortByOrder(
        childTransactions,
        [primarySortColumn, secondarySortColumn, 'debtorMatterRef'],
        ['asc', 'asc', 'asc'],
      ).reduce((acc, tx, index) => {
        trackTotals(tx);

        const isLastChild = index === childTransactions.length - 1;

        const newTx = {
          ...tx,
          multiAmount: childTransactions.length !== 1 ? Math.abs(tx.balance) : undefined,
          ...(!isLastChild ? removedValues : getTotals(tx.bulkGroupId)), // remove credit/debit values until the last row of this group
          hideValuesInTemplate: !isLastChild,
        };
        acc.push(newTx);

        return acc;
      }, []),
    )
    .reduce((all, ops) => all.concat(ops), []); // flatten the transactions;

  return txns;
}

module.exports = {
  filterBulkDepositTransactions,
};
