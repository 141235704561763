import { withScopedFeature } from './with-scoped-feature';

/**
 * Applies {@link withScopedFeature} to all passed features and returns an object with all of the new features.
 *
 * @param  {Object} features The features upon which to apply withScopedFeature.
 *
 * @return {Object} An object with the same keys as features, but with associated values equivalent to calling
 *                  withScopedFeature on the original value.
 */
export const withScopedFeatures =
  ({ state, scope }) =>
  (features) =>
    Object.entries(features || {}).reduce((acc, [featureName, feature]) => {
      acc[featureName] = withScopedFeature({ state, scope })(feature);
      return acc;
    }, {});
