import * as React from 'react';

import { CollapsedFeePopOutEditor } from './CollapsedFeePopOutEditor';
import { ExpandedFeePopOutEditor } from './ExpandedFeePopOutEditor';

export const FeePopOutEditor = (props) => {
  const { isExpanded, onCollapseFeePopOutEditor, onExpandFeePopOutEditor } = props;

  if (!isExpanded) {
    return <CollapsedFeePopOutEditor onExpandFeePopOutEditor={onExpandFeePopOutEditor} />;
  }

  return <ExpandedFeePopOutEditor {...props} onCollapseFeePopOutEditor={onCollapseFeePopOutEditor} />;
};

FeePopOutEditor.displayName = 'FeePopOutEditor';
