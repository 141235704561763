import { print as printGqlString } from 'graphql/language/printer'

import { featureActive } from '@sb-itops/feature';
import { warn as displayWarningToUser } from '@sb-itops/message-display';
import { store } from '@sb-itops/redux';
import { getTotalsCents } from '@sb-billing/redux/invoice-totals';
import { hasPendinginvoices } from '@sb-billing/redux/invoices';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import * as warningMessages from '@sb-billing/business-logic/invoice/entities/warning-messages';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';

import { INVOICE_COMMUNICATE_MODAL_ID, INVOICE_EMAIL_MODAL_ID, ADD_PAYMENT_MODAL_ID } from 'web/components';
import { BillingBulkActions } from 'web/graphql/queries';
import { fetchGraphqlData } from 'web/services/network';
import * as billsListFilters from 'web/redux/route/home-billing-bills-list';
import { subscribeToNotifications } from 'web/services/subscription-manager';

import { createInvoiceFilters } from './create-invoice-filters';
import { loadInvoiceListData } from './load-invoice-list-data';
import { sortInvoices } from './sort-invoices';

angular.module('sb.billing.webapp').controller('ListBillsController', function ($scope, $rootScope, $location, sbUnsavedChangesService, sbSaveInvoiceCommand, sbAsyncOperationsService,
  sbInvoiceSendService, sbMessageDisplayService, sbLocalisationService, sbLinkService, sbExpenseService, sbLoggerService
) {
  const ctrl = this;
  const log = sbLoggerService.getLogger('ListBillsController');

  // We cannot disable the LOD invoice list feature if the expense cache is disabled
  // as it will cause the Unpaid AD functionality to break. If the invoice list
  // needs to be disabled, we need to make sure BB-14929 is disabled as well.
  ctrl.disableLODInvoiceList = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-13599');
  ctrl.disableLODPaymentModal = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-13936');

  ctrl.invoiceIdsForEmailToSend = [];

  ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
  ctrl.sbInvoiceSendService = sbInvoiceSendService;
  ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;

  // Command handlers.
  ctrl.onOpenSendEmailsModal = onOpenSendEmailsModal;
  ctrl.onOpenSendCommunicatesModal = onOpenSendCommunicatesModal;
  ctrl.onOpenMarkAsSentModal = onOpenMarkAsSentModal;
  ctrl.onSendEmails = onSendEmails;
  ctrl.onSendCommunicates = onSendCommunicates;
  ctrl.onSendEmailFromIndicatorIcon = onSendEmailFromIndicatorIcon;
  ctrl.onSendCommunicateFromIndicatorIcon = onSendCommunicateFromIndicatorIcon;
  ctrl.onMarkAsSentFromIndicatorIcon = onMarkAsSentFromIndicatorIcon;
  ctrl.onCloseSendEmailsModal = onCloseSendEmailsModal;
  ctrl.onCloseSendCommunicatesModal = onCloseSendCommunicatesModal;
  ctrl.onCloseMarkAsSentModal = onCloseMarkAsSentModal;
  ctrl.onOpenModal = onOpenModal;
  ctrl.closeModal = closeModal;
  ctrl.setModalDialogVisible = setModalDialogVisible;
  ctrl.onOpenAddPaymentModal = onOpenAddPaymentModal;

  // Finalize invoice with zero balance or/and with unpaid AD
  ctrl.onInvoiceProceedConfirm = onInvoiceProceedConfirm;
  ctrl.onInvoiceProceedCancel = onInvoiceProceedCancel;

  ctrl.onCombineInPdf = onCombineInPdf;
  ctrl.onCombineInPdfWithCover = onCombineInPdfWithCover;
  ctrl.onDownloadLedes = onDownloadLedes;
  ctrl.onBulkFinalise = onBulkFinalise;

  // List callbacks.
  ctrl.onSelectInvoice = onSelectInvoice;
  ctrl.onSortInvoices = onSortInvoices;
  ctrl.onSelectAllInvoices = onSelectAllInvoices;
  ctrl.onClickLink = sbLinkService.onClickLink;

  // Bulk action handling
  ctrl.isBulkCreateInvoicesInProgress = false;
  ctrl.unsubscribeToBulkActionNotifications = undefined;

  // Misc.
  ctrl.contactMatterBalancesEnabled = isMatterContactBalanceType;
  ctrl.isCombineInProgress = isCombineInProgress;
  ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;

  // Initialise controller.
  ctrl.supportsUtbms = hasFacet(facets.utbms);
  ctrl.t = sbLocalisationService.t;
  ctrl.personResponsible = ctrl.t('capitalizeAll', { val: 'personResponsible' });
  ctrl.finalise = ctrl.t('capitalize', { val: 'finalise' });

  loadViewState();
  updateInvoiceListData();
  checkBulkCreateInvoicesInProgress();
  subscribeToBulkActionNotifications();

  // All of our data comes from redux, until all of this logic gets moved to react, we will need to manually subscribe to changes and update data.
  const unsubscribeFromStore = store.subscribe(_.debounce(() => {
    updateInvoiceListData();
    updateInvoiceActionAvailability();
    $scope.$applyAsync();
  }, 100));
  
  async function checkBulkCreateInvoicesInProgress () {
    try {
      const bulkActionsQuery = [{
        operationName: "BillingBulkActions",
        query: printGqlString(BillingBulkActions.query),
        variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES },
      }];
  
      const { data } = await fetchGraphqlData(bulkActionsQuery);

      ctrl.isBulkCreateInvoicesInProgress = data.billingBulkActionList && data.billingBulkActionList.totalCount > 0;
     } catch (err) {
      log.error('Failed to fetch billingBulkActionList for bulk create invoices in global invoices list:', err);
    }
  }

  function subscribeToBulkActionNotifications () {
    ctrl.unsubscribeToBulkActionNotifications = subscribeToNotifications({
      notificationIds: BillingBulkActions.notificationIds,
      callback: () => {
        checkBulkCreateInvoicesInProgress();
      },
    });
  }

  // Prepares all of the "view" state.
  // The view state manages selected invoices, sort orderings, filter values and other UI related state.
  function loadViewState() {
    const defaultViewState = {
      selectedInvoices: {},
      invoiceActions: {
        combineInPdf: new Set(),
        combineInPdfWithCoverLetter: new Set(),
        downloadLedes: new Set(),
        email: new Set(),
        markAsSent: new Set(),
        finalise: new Set(),
        delete: new Set(),
      },
      showEmailModal: false,
      showCommunicateModal: false,
      showMarkAsSentModal: false,
      showInvoiceConfirmProceedModal: false,
      hasZeroBalance: false,
      hasUnpaidAD: false,
      zeroBalanceInvoiceCount: 1,
      sorting: {
        column: 'invoiceNumber',
        direction: 'desc',
      },
    };

    const memory = sbUnsavedChangesService.loadMemory('ListBillsController') || {};
    ctrl.viewState = { ...defaultViewState, ...memory };
  }

  // Reloads the invoice data which powers the invoice list.
  function updateInvoiceListData() {
    if (!ctrl.disableLODInvoiceList) {
      return;
    }

    const { filterFn, statusFilterFn } = createInvoiceFilters(billsListFilters.selectors.getFilters(store.getState()));
    ctrl.invoiceListData = loadInvoiceListData({ filterFn, statusFilterFn, getInvoiceTotalsFn: getTotalsCents });

    const { invoices } = ctrl.invoiceListData;
    const { column, direction } = ctrl.viewState.sorting;
    ctrl.invoiceListData.invoices = sortInvoices({ invoices, sortBy: column, sortDirection: direction });
  }

  // Updates the tracking of which actions are available to be performed on the invoice list selections.
  function updateInvoiceActionAvailability() {
    // Clear current invoice IDs for each invoice action.
    const { invoiceActions, selectedInvoices } = ctrl.viewState;

    if (!invoiceActions) {
      // depending on when a digest occurs, this function can be called after the $destroy event occurs
      // meaning that an exception gets thrown as invoiceActions is undefined.
      // this doesn't affect the UX (as the component is already destroyed), but shows up as an exception
      // on the console
      return;
    }

    Object.values(invoiceActions).forEach((setOfInvoiceIdsForAction) => setOfInvoiceIdsForAction.clear());

    // Determine new invoice IDs for each invoice action based on the currently selected invoices.
    Object.keys(selectedInvoices).forEach((invoiceId) => {
      // If the selected invoice is not currently being displayed in the invoice list (e.g. due to filtering),
      // then we remove it from the selected invoices to prevent user surprise by it re-appearing on filter change.
      const invoice = ctrl.invoiceListData.invoicesById[invoiceId];
      if (!invoice) {
        delete selectedInvoices[invoiceId];
        return;
      }

      const { pseudoStatus } = ctrl.invoiceListData.invoicesById[invoiceId];

      // Finalise.
      if (pseudoStatus === 'DRAFT') {
        invoiceActions.finalise.add(invoiceId);
        invoiceActions.delete.add(invoiceId);
      }

      // Combine in PDF.
      if (['DRAFT', 'FINAL', 'OVERDUE', 'PAID'].includes(pseudoStatus)) {
        invoiceActions.combineInPdf.add(invoiceId);
      }

      // Combine in PDF with Cover Letter
      if (['DRAFT', 'FINAL', 'OVERDUE'].includes(pseudoStatus)) {
        invoiceActions.combineInPdfWithCoverLetter.add(invoiceId);
      }

      // Email and Mark as Sent
      if (['FINAL', 'OVERDUE', 'PAID'].includes(pseudoStatus)) {
        invoiceActions.email.add(invoiceId);
        invoiceActions.markAsSent.add(invoiceId);
      }

      // Download LEDES
      const matterBillingConfiguration = getCurrentConfigurationByMatterId(invoice.matterId);
      if (matterBillingConfiguration && matterBillingConfiguration.isUtbmsEnabled) {
        invoiceActions.downloadLedes.add(invoiceId);
      }
    });

    ctrl.invoiceIdsForEmailToSend = Array.from(invoiceActions.email);
    ctrl.invoiceIdsForMarkAsSent = Array.from(invoiceActions.markAsSent);
    ctrl.invoiceIdsForDeletion = Array.from(invoiceActions.delete);
    ctrl.isInvoiceBulkActionable = ctrl.viewState.invoiceActions.finalise.size ||
      ctrl.invoiceIdsForDeletion.length ||
      ctrl.invoiceIdsForEmailToSend.length ||
      ctrl.invoiceIdsForMarkAsSent.length
  }

  // Destroy controller.
  $scope.$on('$destroy', () => {
    // IinvoiceIdsForEmailToSendnvoice selections and actions are not to be remembered across controller visits.
    delete ctrl.viewState.selectedInvoices;
    delete ctrl.viewState.invoiceActions;
    delete ctrl.viewState.showEmailModal;
    delete ctrl.viewState.showCommunicateModal;
    delete ctrl.viewState.showMarkAsSentModal;

    sbUnsavedChangesService.saveMemory('ListBillsController', ctrl.viewState);
    unsubscribeFromStore();

    if (this.unsubscribeToBulkActionNotifications) {
      this.unsubscribeToBulkActionNotifications();
    }
  });

  // Called when a single invoice selection checkbox is toggled in the invoice list.
  function onSelectInvoice({ invoice }) {
    const { selectedInvoices } = ctrl.viewState;
    const selected = !selectedInvoices[invoice.invoiceId];

    if (!selected) {
      delete selectedInvoices[invoice.invoiceId];
    } else {
      selectedInvoices[invoice.invoiceId] = true;
    }

    ctrl.viewState.selectedInvoices = { ...selectedInvoices };

    updateInvoiceActionAvailability();
  }

  // Called when the "select all" invoice checkbox is toggled in the invoice list.
  function onSelectAllInvoices(selected) {
    ctrl.viewState.selectedInvoices = {};

    if (!selected) {
      return updateInvoiceActionAvailability();
    }

    ctrl.invoiceListData.invoices.forEach(({ invoiceId, currentVersion: { status } }) => {
      if (status !== "VOID") {
        ctrl.viewState.selectedInvoices[invoiceId] = true;
      }
    });

    return updateInvoiceActionAvailability();
  }

  // Called when a new sort column/direction is applied to the invoice list.
  function onSortInvoices({ sortBy, sortDirection }) {
    // Remember the sort order
    const newSortOrder = { column: sortBy, direction: sortDirection };
    ctrl.viewState.sorting = newSortOrder;

    // Perform the sort.
    ctrl.invoiceListData.invoices = sortInvoices({ invoices: ctrl.invoiceListData.invoices, sortBy, sortDirection });
  }

  //////////////////////////////
  // Start action handlers
  //////////////////////////////
  function onOpenSendEmailsModal() {
    // LOD 
    if (featureActive('BB-12983')) {
      setModalDialogVisible({
        modalId: INVOICE_EMAIL_MODAL_ID,
        props: {
          debtorId: ctrl.indicatorStatusEmailDebtorId,
          invoiceIds: ctrl.indicatorStatusEmailInvoiceIds || ctrl.invoiceIdsForEmailToSend,
          scope: 'firm-invoices-invoice-email-modal',
          onPreview: ({
            invoiceEmailRequest,
          }) => sbInvoiceSendService.createInvoiceEmailPreviewP({
            invoiceEmailRequest,
          }),
          onSendEmails: async ({ invoiceEmailRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
            } catch (err) {
              log.error('Failed to send emails', err);
            }
          }
        }
      });
    } else {
      // Legacy
      ctrl.viewState.showEmailModal = true;
    }
  }

  function onOpenSendCommunicatesModal() {
    // Invoice via Communicate feature switch
    if (!featureActive('BB-9097')) {
      return;
    }

    // LOD
    if (featureActive('BB-13005')) {
      setModalDialogVisible({
        modalId: INVOICE_COMMUNICATE_MODAL_ID,
        props: {
          debtorId: ctrl.indicatorStatusCommunicateDebtorId,
          invoiceIds: ctrl.indicatorStatusCommunicateInvoiceIds,
          scope: 'bills-list-invoices-communicate-modal',
          onPreview: ({ invoiceCommunicateRequest }) =>
            sbInvoiceSendService.createInvoiceCommunicatePreviewP({
              invoiceCommunicateRequest,
            }),
          onSend: async ({ invoiceCommunicateRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
            } catch (err) {
              log.error('Failed to send invoice communicate requests: ', err);
            }
          },
        },
      });
    } else {
      // Legacy
      ctrl.viewState.showCommunicateModal = true;
    }
  }

  function onOpenMarkAsSentModal() {
    ctrl.viewState.showMarkAsSentModal = true;
  }

  function onOpenModal(id) {
    ctrl.showModal = id;
  }

  function closeModal() {
    ctrl.showModal = undefined;
  }

  function onSendEmails(invoiceEmailRequest) {
    sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequest);
    onCloseSendEmailsModal();
  }

  function onSendCommunicates(invoiceCommunicateRequest) {
    sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequest);
    onCloseSendCommunicatesModal();
  }

  function onSendEmailFromIndicatorIcon({ invoiceId, contactId }) {
    ctrl.indicatorStatusEmailInvoiceIds = [invoiceId];
    ctrl.indicatorStatusEmailDebtorId = contactId;

    onOpenSendEmailsModal()
  }

  function onSendCommunicateFromIndicatorIcon({ invoiceId, contactId }) {
    ctrl.indicatorStatusCommunicateInvoiceIds = [invoiceId];
    ctrl.indicatorStatusCommunicateDebtorId = contactId;
    
    onOpenSendCommunicatesModal();
  }

  function onMarkAsSentFromIndicatorIcon({ invoiceId, operationType }) {
    ctrl.indicatorStatusMarkAsSentInvoiceIds = [ invoiceId ];
    ctrl.operationType = operationType;
    ctrl.viewState.showMarkAsSentModal = true;
  }

  function onCloseSendEmailsModal() {
    ctrl.indicatorStatusEmailInvoiceIds = undefined;
    ctrl.indicatorStatusEmailDebtorId = undefined;
    ctrl.viewState.showEmailModal = false;
  }

  function onCloseSendCommunicatesModal() {
    ctrl.indicatorStatusCommunicateInvoiceIds = undefined;
    ctrl.indicatorStatusCommunicateDebtorId = undefined;
    ctrl.viewState.showCommunicateModal = false;
  }

  function onCloseMarkAsSentModal() {
    ctrl.indicatorStatusMarkAsSentInvoiceIds = undefined;
    ctrl.viewState.showMarkAsSentModal = false;
  }

  async function onInvoiceProceedConfirm() {
    ctrl.viewState.showInvoiceConfirmProceedModal = false;
    await bulkFinalise();
  }

  function onInvoiceProceedCancel() {
    ctrl.viewState.showInvoiceConfirmProceedModal = false;
    ctrl.viewState.hasZeroBalance = false;
    ctrl.viewState.hasUnpaidAD = false;
  }

  function onCombineInPdf() {
    // Cannot disable non-interactive elements so we double check here that the operation is valid.
    if (!ctrl.viewState.invoiceActions.combineInPdf.size) {
      return;
    }

    const invoiceIdsToCombine = [...ctrl.viewState.invoiceActions.combineInPdf];
    const someInvoicesPendingCreation = hasPendinginvoices(invoiceIdsToCombine);
    if (someInvoicesPendingCreation) {
      displayWarningToUser(warningMessages.WARNING_UNABLE_TO_COMBINE_PENDING_INVOICES);
      return;
    }

    sbAsyncOperationsService.startCombineInvoices(invoiceIdsToCombine);
  }

  function onCombineInPdfWithCover() {
    // Cannot disable non-interactive elements so we double check here that the operation is valid.
    if (!ctrl.viewState.invoiceActions.combineInPdfWithCoverLetter.size) {
      return;
    }

    const invoiceIdsToCombine = [...ctrl.viewState.invoiceActions.combineInPdfWithCoverLetter];
    const someInvoicesPendingCreation = hasPendinginvoices(invoiceIdsToCombine);
    if (someInvoicesPendingCreation) {
      displayWarningToUser(warningMessages.WARNING_UNABLE_TO_COMBINE_PENDING_INVOICES);
      return;
    }

    sbAsyncOperationsService.startCombineInvoices(invoiceIdsToCombine, true);
  }

  function onDownloadLedes() {
    // Cannot disable non-interactive elements so we double check here that the operation is valid.
    if (!ctrl.viewState.invoiceActions.downloadLedes.size) {
      return;
    }

    const invoiceIdsToDownloadLedes = [...ctrl.viewState.invoiceActions.downloadLedes];
    const someInvoicesPendingCreation = hasPendinginvoices(invoiceIdsToDownloadLedes);
    if (someInvoicesPendingCreation) {
      displayWarningToUser(warningMessages.WARNING_UNABLE_TO_COMBINE_PENDING_INVOICES);
      return;
    }

    sbAsyncOperationsService.startDownloadLedes(invoiceIdsToDownloadLedes);
  }

  async function onBulkFinalise() {
    const invoiceIdsToFinalise = [...ctrl.viewState.invoiceActions.finalise];

    let hasUnpaidAD = false;
    let hasZeroBalance = false;
    const supportsAD = featureActive("BB-9573");
    const supportsZeroBalance = featureActive("BB-7088");

    for (const invoiceId of invoiceIdsToFinalise) {
      const invoice = ctrl.invoiceListData.invoicesById[invoiceId];
      if (supportsZeroBalance && invoice && invoice.total === 0) {
        hasZeroBalance = true;
      }
      if (
        supportsAD &&
        invoice &&
        hasUnpaidAnticipatedDisbursements({
          invoice: invoice.currentVersion,
          getExpenseById,
          getExpensePaymentDetailsById,
        })
      ) {
        hasUnpaidAD = true;
      }
    }

    ctrl.viewState.hasZeroBalance = hasZeroBalance;
    ctrl.viewState.hasUnpaidAD = hasUnpaidAD;

    if (hasZeroBalance || hasUnpaidAD) {
      ctrl.viewState.showInvoiceConfirmProceedModal = true;
      ctrl.viewState.zeroBalanceInvoiceCount = invoiceIdsToFinalise.length;
    } else {
      await bulkFinalise();
    }
  }

  async function bulkFinalise() {
    try {
      ctrl.isFinaliseInProgress = true;
      const invoiceIdsToFinalise = [...ctrl.viewState.invoiceActions.finalise];
      const bulk = await sbSaveInvoiceCommand.executeBulkP(invoiceIdsToFinalise);
      const finalisedDraftCount = bulk.filter((i) => i.success).length;
      const draftErrorCount = bulk.filter((i) => !i.success).length;

      if (finalisedDraftCount) {
        sbMessageDisplayService.success(
          sbMessageDisplayService
            .builder()
            .text(`{0} Draft invoice(s) ${ctrl.t('finalise')}d`, finalisedDraftCount)
        );
      }
      if (draftErrorCount) {
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text(`{0} Draft invoice(s) could not be ${ctrl.t('finalised')}`, draftErrorCount)
        );
        log.error('bulk finalise error', bulk);
      }

    } catch (ex) {
      sbMessageDisplayService.error(
        sbMessageDisplayService
          .builder()
          .text(`Draft invoice could not be ${ctrl.t('finalised')}`)
      );
      log.error(ex);
    } finally {
      ctrl.isFinaliseInProgress = false;
    }
  }

  function onOpenAddPaymentModal() {
    if (!ctrl.disableLODPaymentModal) {
      setModalDialogVisible({
        modalId: ADD_PAYMENT_MODAL_ID,
        props: {
          scope: 'ListBills/add-payment-modal',
        }
      });
    } else {
      // Non-LOD version
      ctrl.onOpenModal('add-payment-modal');
    }
  }

  //////////////////////////////
  // End action handlers
  //////////////////////////////

  function isCombineInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0;
  }

  function isDownloadLedesInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
  }
});
