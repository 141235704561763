import React from 'react';
import { DateRangePicker, SlidingToggle } from '@sb-itops/react';
import Styles from '../ReportFilter.module.scss';

const AuditLogsFilter = ({ onSelectPeriod, onEndSelect, onStartSelect, onToggleOption, filters, periodOptions }) => {
  if (!filters) {
    return null;
  }

  const { periodSelected, startDate, endDate, includeFirmHeader, includeFirmLogo, includeFirmName, errors } = filters;

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.period}>
        <label>Report period</label>
        <DateRangePicker
          onSelect={onSelectPeriod}
          onEndSelect={onEndSelect}
          onStartSelect={onStartSelect}
          options={periodOptions}
          selected={periodSelected}
          endDate={endDate}
          endHasError={errors.endDate}
          startDate={startDate}
          startHasError={errors.startDate}
        />
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

AuditLogsFilter.displayName = 'AuditLogsFilter';

export default AuditLogsFilter;
