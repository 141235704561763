import PropTypes from 'prop-types';
import React from 'react';
import { Button, DropdownMenuButton, Spinner } from '@sb-itops/react';
import classnames from 'classnames';

import { ClientCommunicationTable } from '../client-communication-table';
import Styles from './ClientCommunications.module.scss';

export const ClientCommunications = ({
  matterId,
  numberOfDaysToShow,
  correspondenceHistoryData,
  exportMenuOptions,
  isGeneratingPDFReport,
  sortDirection,
  sortBy,
  onSort,
  onClickLink,
}) => (
  <div className={classnames('panel-body', Styles.clientCommunications)}>
    <div className={classnames('ribbon', 'panel', 'panel-primary')}>
      {isGeneratingPDFReport && (
        <Button disabled>
          Export <Spinner small />
        </Button>
      )}
      {!isGeneratingPDFReport && <DropdownMenuButton label="Export" menuOptions={exportMenuOptions} />}
    </div>
    <ClientCommunicationTable
      matterId={matterId}
      numberOfDaysToShow={numberOfDaysToShow}
      correspondenceHistoryData={correspondenceHistoryData}
      sortDirection={sortDirection}
      sortBy={sortBy}
      onSort={onSort}
      onClickLink={onClickLink}
    />
    <div className={Styles.correspondenceHistoryPrint}>
      <iframe id="correspondenceHistoryPdf" title="smokeball-report-viewer" />
    </div>
  </div>
);

ClientCommunications.displayName = 'ClientCommunications';

ClientCommunications.propTypes = {
  matterId: PropTypes.string, // if not provided, the table will show correspondence for the last numberOfDaysToShow days for the firm
  numberOfDaysToShow: PropTypes.number,
  correspondenceHistoryData: PropTypes.arrayOf(
    PropTypes.shape({
      correspondenceHistoryId: PropTypes.string,
      timestamp: PropTypes.string,
      contactIds: PropTypes.array,
      to: PropTypes.string,
      operationTypeLabel: PropTypes.string,
      relatedIds: PropTypes.array,
      reference: PropTypes.string,
      sentViaLabel: PropTypes.string,
      sentBy: PropTypes.string,
    }),
  ),
  exportMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  isGeneratingPDFReport: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

ClientCommunications.defaultProps = {
  matterId: undefined,
  numberOfDaysToShow: undefined,
  correspondenceHistoryData: [],
  isGeneratingPDFReport: false,
  sortDirection: undefined,
  sortBy: undefined,
};
