import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import * as forms from '@sb-itops/redux/forms2';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withOnLoad } from '@sb-itops/react/hoc';
import { chequeStylesByRegion } from '@sb-billing/business-logic/cheques';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import ChequeSettingsSection from './ChequeSettingsSection';

const REGION = getRegion();

const mapStateToProps = (
  state,
  { scope, bankAccountType, allowBankAccountChange, bankAccounts, activateLabel, disabled },
) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields, formSubmitting } = formSelectors.getFormState(state);

  const { printMethod, chequeStyle, printingActive, includeVendorAddress, bankAccountId } = formFields;
  const chequeStyleOptions = chequeStylesByRegion[REGION].map((style) => ({
    label: style.display,
    value: style.value,
  }));

  return {
    showIncludeVendorAddress: hasFacet(facets.operatingChequeVendorAddress),
    allowBankAccountChange,
    chequeStyleOptions,
    bankAccounts,
    bankAccountType,
    activateLabel,
    // form
    formDisabled: formSubmitting || disabled,
    formInitialised,
    // form fields
    printMethod,
    chequeStyle,
    printingActive,
    includeVendorAddress,
    bankAccountId,
  };
};

const mapDispatchToProps = (dispatch, { scope, getInitValues, bankAccounts }) => {
  const { actions: formActions } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      // on init, select first account if there is any
      const initBankAccountId = bankAccounts?.[0]?.value;
      dispatch(
        formActions.initialiseForm({
          fieldValues: getInitValues({ bankAccountId: initBankAccountId }),
        }),
      );
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
    },
    onBankAccountChange: (newBankAccountId) => {
      dispatch(
        formActions.initialiseForm({
          fieldValues: getInitValues({ bankAccountId: newBankAccountId }),
        }),
      );
    },
  };
};

const ChequeSettingsSectionContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(ChequeSettingsSection)),
);

ChequeSettingsSectionContainer.displayName = 'ChequeSettingsSectionContainer';

export default ChequeSettingsSectionContainer;

ChequeSettingsSectionContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  bankAccountType: PropTypes.oneOf([bankAccountTypeEnum.TRUST, bankAccountTypeEnum.OPERATING]).isRequired,
  allowBankAccountChange: PropTypes.bool.isRequired,
  bankAccounts: PropTypes.array.isRequired,
  activateLabel: PropTypes.string.isRequired,
  getInitValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
ChequeSettingsSectionContainer.defaultProps = {
  disabled: false,
};
