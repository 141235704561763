import React from 'react';

import Styles from './ConfigureMatterNumbering.module.scss';
import { TWidget } from './types';

type WidgetEditorProps = {
  widget?: TWidget;
  setWidgetValue: (pos: number, stringValue: string) => void;
  deleteWidget: () => void;
};

export const WidgetEditor = ({ widget, setWidgetValue, deleteWidget }: WidgetEditorProps) =>
  widget ? (
    <div className={Styles.widgetControls}>
      {(widget.editableFields || []).map((f, i) => (
        <div key={f.name}>
          <label>{f.labelDisplay}</label>
          <input
            className="form-control"
            type={f.valueType}
            value={f.stringValue || ''}
            onChange={(ev) => setWidgetValue(i, ev.target.value)}
          />
        </div>
      ))}
      {widget && !widget.permanent && <div onClick={deleteWidget} className={`fa fa-trash icon ${Styles.trashcan}`} />}
    </div>
  ) : null;
