/**
 * build a display name readable by .net to send emails. It removes any problematic character.
 *
 * @param {string} displayName  A string representing the display name to use in the email.
 */
function marshalDisplayName(displayName) {
  return displayName ? `"${displayName.replace(/"/g, '')}"` : '';
}

module.exports = {
  marshalDisplayName,
};
