import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';

import DebugEntityHistory from './DebugEntityHistory';

const mapStateToProps = (state, { compatibleCachesMap, selectedDebugEntity }) => {
  let debugEntityHistory = supportDebugSelectors.getDebugEntityHistory(state);

  if (compatibleCachesMap) {
    debugEntityHistory = debugEntityHistory.filter((entity) => compatibleCachesMap[entity.entityCacheName]);
  }
  return {
    selectedDebugEntity,
    debugEntityHistory,
  };
};

const DebugEntityHistoryContainer = withReduxStore(
  connect(
    mapStateToProps,
    // mapDispatchToProps,
  )(DebugEntityHistory),
);

DebugEntityHistoryContainer.displayName = 'DebugEntityHistoryContainer';

DebugEntityHistoryContainer.propTypes = {
  compatibleCachesMap: PropTypes.object,
  selectedDebugEntity: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

DebugEntityHistoryContainer.defaultProps = {
  compatibleCachesMap: undefined,
  selectedDebugEntity: undefined,
};

export default DebugEntityHistoryContainer;
