import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@sb-itops/react/button';
import { RestLogsTable } from 'web/react-redux/components/rest-logs-table';

import Styles from './BillingSupportRestLogs.module.scss';

export const BillingSupportRestLogs = React.memo(
  ({ logItems, isCollectingLogs, clearLogItems, startLogCollection, stopLogCollection }) => (
    <div className={Styles.billingSupportRestLogs}>
      <div className="ribbon panel panel-primary">
        <Button
          disabled={false}
          className={Styles.button}
          onClick={isCollectingLogs ? stopLogCollection : startLogCollection}
          size="full-width"
        >
          {isCollectingLogs ? 'Stop' : 'Start'}
        </Button>
        <Button disabled={logItems.length === 0} onClick={clearLogItems} size="full-width">
          Clear
        </Button>
      </div>
      <RestLogsTable logItems={logItems} />
    </div>
  ),
);

BillingSupportRestLogs.displayName = 'BillingSupportRestLogs';

BillingSupportRestLogs.propTypes = {
  logItems: PropTypes.array.isRequired,
  startLogCollection: PropTypes.func.isRequired,
  stopLogCollection: PropTypes.func.isRequired,
  clearLogItems: PropTypes.func.isRequired,
  isCollectingLogs: PropTypes.bool.isRequired,
};

BillingSupportRestLogs.defaultProps = {};
