import { getMatterDisplayById, getById as getMatterById } from '@sb-matter-management/redux/matters';
import { fetchById as fetchContactById } from '@sb-customer-management/redux/contacts';
import { getReportTypes, buildSubmitForm, getPdfOptionsByReportType } from '@sb-billing/redux/report-config';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useState } from 'react';
import * as messageDisplay from '@sb-itops/message-display';

const DISPLAY_REMOVAL_GROUP = 'sbActionGerateAndDownloadReport';

export const useTrustLedgerReportDownload = ({ sbReportingService, sbAsyncOperationsService, t }) => {
  const [isDownloading, setDownloading] = useState(false);

  const allowOverdraw = hasFacet(facets.allowOverdraw);
  const supportsEnteredDate = hasFacet(facets.transactionsByEnteredDate);
  const supportsCMA = hasFacet(facets.CMA);

  const trustLedgerOptions = {
    reportType: 'trust-ledger',
    bankAccountId: undefined,
    fromDate: undefined,
    toDate: undefined,
    matterContactRadio: '',
    matterOrContactInfo: { id: '', display: '' },
    showInvoiceHistory: false,
    showInvoiceSummary: false,
    showAccountSummary: false,
    showFeesAndExpenses: false,
    showDeletedTransactions: false,
    showTransactionHistory: true,
    showTransactionHistoryWithInvoiceNumbers: true,
    printFirmName: true,
    printOnLetterhead: false,
    showLedger: false,
    showTrustAccountName: true,
    showTimeKeeperInitials: false,
    showExpenseTimeKeeperInitials: !featureActive('BB-2738'),
    showMatterDescriptionInRows: true,
    showMatterLevelColumn: true,
    runByEnteredDate: supportsEnteredDate,
  };

  const isTrustLedgerEnabled = hasFacet(facets.trustLedger);

  async function downloadReport({ matter, contact, showHidden, ledger, bankAccountId }) {
    if (isDownloading) {
      return;
    }

    setDownloading(true);

    try {
      const contactInfo = contact?.entityId && (await fetchContactById(contact.entityId));
      const matterDisplay = matter && getMatterDisplayById(matter.matterId);
      const matterInfo = matter && getMatterById(matter.matterId);

      const form = buildFilterForm({
        matter: matter && { ...matter, matterInfo, matterDisplay },
        contact: contact && { ...contact, contactInfo },
        showHidden,
        ledger,
        bankAccountId,
      });

      const filterData = await sbReportingService.generateReportP(
        buildSubmitForm(form, t, { supportsEnteredDate, allowOverdraw, supportsCMA }),
      );
      const format = 'pdf';
      const requestData = {
        reportGenerationId: filterData.reportGenerationId,
        reportType: filterData.data.reportType,
        bankAccountId: filterData.data.bankAccountId,
        pdfOptions: getPdfOptionsByReportType(filterData.data.reportType, t, {
          supportsEnteredDate,
          allowOverdraw,
          supportsCMA,
        }),
      };

      sbAsyncOperationsService.startGenerateReport(format, requestData);
    } catch (e) {
      messageDisplay.error(
        messageDisplay
          .builder()
          .text('Failed to download report. Please try again later.')
          .group(DISPLAY_REMOVAL_GROUP),
      );
    }

    setDownloading(false);
  }

  function buildFilterForm({ matter, contact, showHidden, ledger, bankAccountId }) {
    const reportOptions =
      isTrustLedgerEnabled && !ledger
        ? trustLedgerOptions
        : getReportTypes(t, { supportsEnteredDate, allowOverdraw, supportsCMA }).find(
            (reportConfig) => reportConfig.type === 'account-statement',
          ).filterForm;

    if (matter) {
      const filterForm = {
        ...reportOptions,
        bankAccountId,
        showDeletedTransactions: showHidden,
        matterContactRadio: 'matter',
        matterOrContactInfo: {
          id: matter.matterId,
          display: matter.matterDisplay,
        },
      };

      if (isTrustLedgerEnabled) {
        filterForm.matterOrContactInfo.display = `${filterForm.matterOrContactInfo.display} ${
          matter.description ? `- ${matter.description}` : ''
        }`;
      }

      return filterForm;
    }

    const filterForm = {
      ...reportOptions,
      bankAccountId,
      showDeletedTransactions: showHidden,
      matterContactRadio: 'contact',
      matterOrContactInfo: {
        id: contact.entityId,
        display: contact.display,
      },
    };

    return filterForm;
  }

  return { isDownloading, downloadReport };
};
