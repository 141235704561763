import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';

import { getConfig, saveConfig } from '@sb-billing/redux/default-matter-billing-configuration';
import { getEvergreenRetainerEmailMacros } from '@sb-billing/business-logic/invoice-emailing';
import EvergreenEmailSettings from './EvergreenEmailSettings';

function mapStateToProps() {
  return {
    settings: getConfig(),
  };
}

function mapDispatchToProps(dispatch, { t }) {
  const evergreenRetainerEmailMacros = {
    subjectMacros: getEvergreenRetainerEmailMacros({
      isForEmailSubject: true,
      t,
      showPayNowButton: true,
    }),
    bodyMacros: getEvergreenRetainerEmailMacros({
      isForEmailSubject: false,
      t,
      showPayNowButton: true,
    }),
  };

  return {
    // saveConfig uses opdateThunkFactory, which calls dispatch internally
    onSaveSettings: (settings) => saveConfig(settings),
    evergreenRetainerEmailMacros,
  };
}
const EvergreenEmailSettingsContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EvergreenEmailSettings)),
);

EvergreenEmailSettingsContainer.displayName = 'EvergreenEmailSettingsContainer';

export default EvergreenEmailSettingsContainer;
