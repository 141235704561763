'use strict';

/**
 * @typedef {object} CORRESPONDENCE_PSEUDO_STATUS
 * @property {string} inProgress 'inProgress'
 * @property {string} sent 'sent'
 * @property {string} failed 'failed'
 * @property {string} notSent 'notSent'
 */
const CORRESPONDENCE_PSEUDO_STATUS = Object.freeze({
  inProgress: 'inProgress',
  sent: 'sent',
  failed: 'failed',
  notSent: 'notSent',
});

module.exports = { CORRESPONDENCE_PSEUDO_STATUS };
