import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import * as Styles from './Dashboard.module.scss';
import { MenuContainer as Menu } from './Menu.container';
import {
  NewLeadsWidget,
  NewMattersWidget,
  MattersWithNoActivityWidget,
  LeadsWithNoActivityWidget,
  RecentDocumentsWidget,
  RecentlyViewedWidget,
  BillableHoursWidget,
  BillableGraphWidget,
  TasksWidget,
  EventsWidget,
  DailyDigestWidget,
  AddWidgets,
} from '../widgets';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const HEIGHT = 10;
export const WIDTH = 10;

const gridDots: React.ReactNode[] = [];
for (let r = 1; r < HEIGHT; r += 1) {
  for (let c = 1; c < WIDTH; c += 1) {
    gridDots.push(
      <div
        key={String(r) + String(c)}
        style={{
          gridColumnStart: c,
          gridColumnEnd: c + 1,
          gridRowStart: r,
          gridRowEnd: r + 1,
        }}
        className={Styles.dot}
      />,
    );
  }
}

const renderItem = ({ id, type, isEditing, matterId, onClickLink, onChange, onRemove, settings }) => {
  switch (type) {
    case 'NewLeads':
      return (
        <NewLeadsWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'NewMatters':
      return (
        <NewMattersWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'MattersNoActivity':
      return featureActive('NUCWEB-505') ? (
        <MattersWithNoActivityWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      ) : null;
    case 'LeadsNoActivity':
      return featureActive('NUCWEB-504') ? (
        <LeadsWithNoActivityWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      ) : null;
    case 'RecentDocuments':
      return (
        <RecentDocumentsWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'DailyDigest':
      return (
        <DailyDigestWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'RecentlyViewed':
      return (
        <RecentlyViewedWidget
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'BillableHours':
      return (
        <BillableHoursWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'BillableGraph':
      return (
        <BillableGraphWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'Tasks':
      return (
        <TasksWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    case 'Events':
      return (
        <EventsWidget
          matterId={matterId}
          onClickLink={onClickLink}
          isEditing={isEditing}
          onChange={onChange(id)}
          onRemove={onRemove(id)}
          settings={settings[id]}
        />
      );
    default:
      return () => null;
  }
};

interface Props {
  isEditing: boolean;
  onChange: Function;
  onRemove: Function;
  onAddWidget: Function;
  onAddDashboard: Function;
  setCurrentDashboard: Function;
  setDashboards: Function;
  setSettings: Function;
  showAddWidget: boolean;
  setShowAddWidget: Function;
  setEditState: Function;
  onExit: React.MouseEventHandler;
  currentDashboard: string;
  firmName: string;
  onClickLink: React.MouseEventHandler;
  dashboards: any;
  settings: {
    [key: string]: {
      id: string;
      type: string;
      show: boolean;
      dashboard: string;
      position: {
        x: number;
        y: number;
      };
      size: {
        x: number;
        y: number;
      };
      minX: number;
      minY: number;
    };
  };
  matterId: string;
}

export const Dashboard = ({
  onAddWidget,
  onAddDashboard,
  settings,
  matterId,
  currentDashboard,
  setCurrentDashboard,
  dashboards,
  setDashboards,
  onChange,
  onRemove,
  setSettings,
  onClickLink,
  showAddWidget,
  setShowAddWidget,
  onExit,
  isEditing,
  setEditState,
  firmName,
}: Props) => {
  const filteredComponents = Object.values(settings).filter((component) => component.dashboard === currentDashboard);
  const children = filteredComponents.map(({ id, type }) => (
    <div className={settings[id].show ? Styles.isEditing : ''} key={id}>
      {renderItem({
        id,
        type,
        isEditing,
        matterId,
        onChange,
        onRemove,
        settings,
        onClickLink,
      })}
    </div>
  ));
  return (
    <div className={classnames('panel-body', Styles.container)}>
      <div className="page-name">
        <div className="page-name-icon icon-house-2" />
        <div>
          <h1 className="page-name-heading">Dashboard</h1>
          {firmName && <div>{firmName} Overview</div>}
        </div>
      </div>
      <Menu
        isEditing={isEditing}
        dashboards={dashboards}
        setEditState={setEditState}
        onAddWidget={() => setShowAddWidget(true)}
        onAddDashboard={onAddDashboard}
        currentDashboard={currentDashboard}
        setCurrentDashboard={setCurrentDashboard}
        setDashboards={setDashboards}
        onExit={onExit}
      />
      <div className={classnames(Styles.gridContainer)}>
        <ResponsiveGridLayout
          measureBeforeMount
          className={Styles.gridStyles}
          compactType={null}
          maxRows={HEIGHT}
          layouts={{
            lg: filteredComponents.map(({ position, size, id, minX, minY }) => ({
              i: id,
              x: position.x,
              y: position.y,
              w: size.x,
              h: size.y,
              minW: minX,
              minH: minY,
            })),
          }}
          margin={[10, 10]}
          padding={[0, 0]}
          resizeHandle={
            isEditing && (
              <div className={`${Styles.resizer} .react-resizable-handle react-resizable-handle`}>
                <i className={`${Styles.resizeIcon} icon icon-arrow-up-down-fat`} />
              </div>
            )
          }
          isBounded
          preventCollision
          onLayoutChange={(layout) => {
            const newSettings = { ...settings };
            layout.forEach(({ i, x, y, h, w }) => {
              newSettings[i] = { ...settings[i], position: { x, y }, size: { x: w, y: h } };
            });
            setSettings(newSettings);
          }}
          isDraggable={isEditing}
          isResizable={isEditing}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: WIDTH, md: WIDTH, sm: WIDTH, xs: WIDTH, xxs: WIDTH }}
          // Height minus menus 204 minus container padding (20) minus row padding (currently 10px per row)
          rowHeight={(document.body.scrollHeight - 204 - 20 - (HEIGHT - 1) * 10) / HEIGHT}
        >
          {children}
        </ResponsiveGridLayout>
        {isEditing && (
          <div className={Styles.gridDots}>
            <div
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2,
                position: 'relative',
              }}
            />
            {gridDots}
          </div>
        )}
      </div>
      {showAddWidget && (
        <AddWidgets matterId={matterId} onAddWidget={onAddWidget} onClose={() => setShowAddWidget(false)} />
      )}
    </div>
  );
};

Dashboard.propTypes = {
  matterId: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  setEditState: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  onAddWidget: PropTypes.func.isRequired,
  onAddDashboard: PropTypes.func.isRequired,
  setSettings: PropTypes.func.isRequired,
  showAddWidget: PropTypes.bool.isRequired,
  setShowAddWidget: PropTypes.func.isRequired,
  currentDashboard: PropTypes.string.isRequired,
  setCurrentDashboard: PropTypes.func.isRequired,
  dashboards: PropTypes.object.isRequired,
  setDashboards: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  matterId: '',
};
