'use strict';

const { transactionGroupAccountOrder } = require('../entities');

/**
 * This function takes an array of transaction groups from different bank accounts
 * and orders the groups into the UI display order for the trust statement
 *
 * The order being defined in transactionGroupAccountOrder array in trust-statement-account-order-config.js
 *
 * @param {Array<object>} transactionGroups
 * @returns {Array<object>}
 */

const sortTransactionsGroupsAccountsOrder = ({ transactionGroups }) => {
  transactionGroups.sort(
    (a, b) => transactionGroupAccountOrder.indexOf(a.id) - transactionGroupAccountOrder.indexOf(b.id),
  );
  return transactionGroups;
};

module.exports = {
  sortTransactionsGroupsAccountsOrder,
};
