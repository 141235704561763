import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { INVOICE_STATEMENT_EMAIL_MODAL_ID } from 'web/components';
import { InvoiceStatementData, InitStaffSettings } from 'web/graphql/queries';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import { invoiceStatementEmailTemplateDefaults } from '@sb-billing/business-logic/invoice-statement/invoice-statement-email-template-defaults';
import { InvoiceStatementEmailModalFormsContainer } from './InvoiceStatementEmailModal.forms.container';

const INVOICE_STATEMENT_EMAIL_MODAL_SCOPE = 'invoice-statement-email-modal';

const hooks = (props) => ({
  useInvoiceEmailModalData: () => {
    const { invoiceStatementId } = props;
    const onModalClose = () => setModalDialogHidden({ modalId: INVOICE_STATEMENT_EMAIL_MODAL_ID });

    const { data: invoiceStatementData, loading: invoiceStatementLoading } = useSubscribedQuery(InvoiceStatementData, {
      variables: {
        invoiceStatementId,
      },
    });

    const {
      merchantPaymentReference,
      debtor,
      number: invoiceStatementNumber,
    } = invoiceStatementData?.invoiceStatement || {};

    return {
      scope: INVOICE_STATEMENT_EMAIL_MODAL_SCOPE,
      debtorId: debtor?.id,
      debtorName: debtor?.displayNameFirstLast,
      invoiceStatementId,
      invoiceStatementLoading,
      invoiceStatementNumber,
      merchantPaymentReference,
      onModalClose,
    };
  },
  useCacheQueryData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const invoiceStatementEmailTemplate = invoiceStatementEmailTemplateDefaults();

    const invoiceStatementEmailSettings = {
      emailBody: invoiceStatementEmailTemplate.emailBody,
      emailSubject: invoiceStatementEmailTemplate.emailSubject,
      sendCopyToUser: invoiceStatementEmailTemplate.sendCopyToUser,
    };

    return {
      loggedInStaffMember: staffData?.loggedInStaff,
      invoiceStatementEmailSettings,
    };
  },
});

export const InvoiceStatementEmailModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)((props) => <InvoiceStatementEmailModalFormsContainer {...props} />)),
);

InvoiceStatementEmailModalContainer.displayName = 'InvoiceStatementEmailModalContainer';

InvoiceStatementEmailModalContainer.propTypes = {
  invoiceStatementId: PropTypes.string.isRequired,
};

InvoiceStatementEmailModalContainer.defaultProps = {};
