'use strict';

angular.module('@sb-billing/services').service('sbRequestDepositService', function ($rootScope, sbLoggerService, sbGenericEndpointService) {
  const that = this;
  const requestDepositEndpoint = `billing/request-deposit/batch-payment`;
  const log = sbLoggerService.getLogger('sbRequestDepositService');

  that.requestDepositP = requestDepositP;

  function requestDepositP(depositRequests) {
    return sbGenericEndpointService.postPayloadP(requestDepositEndpoint, undefined, {emailRequests: depositRequests})
      .then((resp) => {
        log.debug('sending deposit request done: ', JSON.stringify(resp));
        return resp;
      })
      .catch((err) => {
        const errMsg = 'ERROR' || err || err.message;
        log.error('failed to send requests for deposits', errMsg);
        log.debug('failed to send requests for deposits', depositRequests, 'ERROR:', err);
        throw err;
      });
  }

});

