import * as types from './types';

/* State Shape
{
  allItemsExpanded    : Boolean             : Flag indicating whether all of the items being managed are currently expanded. 
  numberItemsTracked  : Integer             : The number of items being tracked. Default: 0.
  numberItemsExpanded : Integer             : The number of items that are currently expanded. Default: 0.
  expandedItems       : Object<id, Boolean> : A lookup indiciating the items which are currently expanded. True means item is expanded, falsey value means not expanded.
}
*/

// Defines the initial values set in state.
const initialState = {
  allItemsExpanded: false,
  numberItemsTracked: 0,
  numberItemsExpanded: 0,
  expandedItems: {},
};

// Action -> Handler function lookup.
const reducerLookup = {
  [types.SET_ITEMS]: setItems,
  [types.TOGGLE_ALL_ITEMS]: toggleAllItems,
  [types.EXPAND_ALL_ITEMS]: expandOrCollapseAllItems,
  [types.COLLAPSE_ALL_ITEMS]: expandOrCollapseAllItems,
  [types.TOGGLE_ITEM]: toggleItem,
  [types.EXPAND_ITEM]: expandOrCollapseItem,
  [types.COLLAPSE_ITEM]: expandOrCollapseItem,
  [types.RESET_STATE]: () => initialState,
};

/**
 * The main reducer function for the feature.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggering a potential update to the store.
 * @return {object} The new store state.
 */
export const reducer = (state = initialState, action = {}) => {
  const reducerFn = reducerLookup[action.type];
  return reducerFn ? reducerFn(state, action) : state;
};

/**
 * Action handler for types.SET_ITEMS.
 *
 * This function is intended to be used primarily upon initialisation of this redux feature.
 * For expand/collapse all behaviour to function correctly, the expand/collapse feature must
 * be aware of all of the items which will be tracked by the feature.
 *
 * The passed action must provide an array of ids of all of the items to be managed by this feature.
 * If called more than once, this handler ensures that existing state is respected.
 *
 * E.g. if expandedItems is { 1: false, 2: true} and setItems is called with { itemIds: [1,2,3], expandByDefault: true}
 * then the new expanded items state will be { 1: false, 2: true, 3: true} NOT { 1: true, 2: true, 3: true},
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function setItems(state, action) {
  const { itemIds, expandByDefault } = action.payload;

  const stateUpdates = itemIds.reduce(
    (acc, itemId) => {
      const currentExpandedState = state && state.expandedItems && state.expandedItems[itemId];
      acc.expandedItems[itemId] = currentExpandedState !== undefined ? currentExpandedState : expandByDefault;
      acc.numberItemsExpanded += acc.expandedItems[itemId] ? 1 : 0;
      return acc;
    },
    {
      numberItemsTracked: itemIds.length,
      numberItemsExpanded: 0,
      expandedItems: {},
    },
  );

  const allItemsExpanded =
    stateUpdates.numberItemsTracked > 0 && stateUpdates.numberItemsExpanded === stateUpdates.numberItemsTracked;

  return {
    ...state,
    ...stateUpdates,
    allItemsExpanded,
  };
}

/**
 * Action handler for types.TOGGLE_ALL_ITEMS.
 *
 * This function takes the current value of allItemsExpanded and flips it.
 * The expandedItems object and numberItemsExpanded counter are updated accordingly.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function toggleAllItems(state) {
  const allItemsExpanded = !state.allItemsExpanded;
  return updateAllItemsState(state, allItemsExpanded);
}

/**
 * Action handler for types.EXPAND_ALL_ITEMS and types.COLLAPSE_ALL_ITEMS.
 *
 * This function sets the value of allItemsExpanded.
 * The expandedItems object and numberItemsExpanded counter are updated accordingly.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function expandOrCollapseAllItems(state, action) {
  const expand = action.payload.expand;
  return updateAllItemsState(state, expand);
}

/**
 * Helper function used by toggleAllItems and expandOrCollapseAllItems
 *
 * Ensures that all required aspects of state are updated when performing an allItems action.
 *
 * @param  {object}  state  - The current state in the store.
 * @param  {boolean} expand - If true, all items will be expanded. If false, all items will be collapsed.
 * @return {object} New store state.
 */
function updateAllItemsState(state, expand) {
  const expandedItems = Object.keys(state.expandedItems).reduce((acc, itemId) => {
    acc[itemId] = expand;
    return acc;
  }, {});

  const numberItemsExpanded = expand ? state.numberItemsTracked : 0;

  return {
    ...state,
    allItemsExpanded: expand,
    expandedItems,
    numberItemsExpanded,
  };
}

/**
 * Action handler for types.TOGGLE_ITEM.
 *
 * This function takes the current expand/collapse state for an item and flips it.
 * The expandedItems object and numberItemsExpanded counter are updated accordingly.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function toggleItem(state, action) {
  const id = action.payload.id;
  let expand = state.expandedItems[id];
  if (expand === undefined) {
    expand = action.payload.expandByDefault;
  } else {
    expand = !expand;
  }
  return updateSingleItemState(state, id, expand);
}

/**
 * Action handler for types.EXPAND_ITEM and types.COLLAPSE_ITEM.
 *
 * This function updates the expand/collapse state for a particular item.
 * The expandedItems object and numberItemsExpanded counter are updated accordingly.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function expandOrCollapseItem(state, action) {
  const { id, expand } = action.payload;
  return updateSingleItemState(state, id, expand);
}

/**
 * Helper function used by toggleItem and expandOrCollapseItem
 *
 * Ensures that all required aspects of state are updated when performing a single item action.
 *
 * @param  {object}  state  - The current state in the store.
 * @param  {string}  id     - The id of the item to be updated.
 * @param  {boolean} expand - If true, the item will be expanded. If false, the item will be collapsed.
 * @return {object} New store state.
 */
function updateSingleItemState(state, id, expand) {
  const expandedItems = {
    ...state.expandedItems,
    [id]: expand,
  };

  let numberItemsExpanded;
  if (expand) {
    numberItemsExpanded =
      state.numberItemsExpanded === state.numberItemsTracked ? state.numberItemsTracked : state.numberItemsExpanded + 1;
  } else {
    numberItemsExpanded = state.numberItemsExpanded === 0 ? 0 : state.numberItemsExpanded - 1;
  }

  const allItemsExpanded = state.numberItemsTracked === numberItemsExpanded;

  return {
    ...state,
    allItemsExpanded,
    numberItemsExpanded,
    expandedItems,
  };
}
