import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, useTranslation } from '@sb-itops/react';
import { AddPaymentModalBody } from './AddPaymentModalBody';
import { TakePaymentNowForm } from '../take-payment-now-form';
import Styles from './AddPaymentModal.module.scss';

export const ADD_PAYMENT_MODAL_ID = 'add-payment-new-modal';
export const MODAL_STEP = { ADDING_PAYMENT: 'ADDING_PAYMENT', TAKING_PAYMENT: 'TAKING_PAYMENT' };

export const AddPaymentModal = ({
  isModalLoading,
  showModal,
  onModalClose,
  currentModalStep,
  // form
  formData,
  formErrors,
  submitFailed,
  formSubmitting,

  balance,
  printMethodOptions,
  paymentSourceOptions,
  paymentSourceOptionsLoading,
  trustChequeNumberLoading,
  footerErrorMessages,
  totalChargeAmount,
  clientIsCoveringFee,
  // UI
  supportsEcheque,
  showReasonField,
  showTakePaymentNowField,
  showPaymentPlanPaymentsWithInterestWarning,
  isChequeMemoVisible,
  isReferenceReadOnly,
  isTakePaymentNowDisabled,
  isDirectPayment,
  isTrustPayment,
  isPdfOnTrustPaymentDisabled,
  isTrustChequePrintingActive,
  isSubmitDisabled,
  isSubmitLocked,
  overdrawWarningProps,
  // callbacks
  onUpdateFieldValues,
  onPaymentTypeChange,
  onTakePaymentNowToggled,
  onChangePrintingMethod,
  onReasonChange,
  onSelectMatter,
  onSelectClient,
  onSelectPaidBy,
  onPaymentSourceChange,
  onEffectiveDateChange,
  onPaymentsListChange,
  onReferenceChange,
  onModalButtonClick,
  // matter typeahead
  defaultMatterSummaries,
  matterSummaries,
  matterSummariesDataLoading,
  matterSummariesHasMore,
  onFetchMatterSummaries,
  onFetchMoreMatterSummaries,
  // debtor typeahead
  defaultDebtorContactOptions,
  debtorContactOptions,
  debtorContactOptionsDataLoading,
  debtorContactOptionsHasMore,
  onFetchDebtorContactOptions,
  onFetchMoreDebtorContactOptions,
  // paid by typeahead
  defaultPaidByContactOptions,
  paidByContactOptions,
  paidByContactOptionsDataLoading,
  paidByContactOptionsHasMore,
  onFetchPaidByContactOptions,
  onFetchMorePaidByContactOptions,
  // invoice summaries
  invoiceSummariesLoading,
  invoiceSummaries,
  // take payment now
  operatingAccount,
  activeProviderType,
  activeProviderFormattedSettings,
  paymentSourceSelected,
  paidByContactDetails,
  paidByContactDetailsLoading,
  onChargeFormDataChange,
  onChargeFormSubmit,
  onChargeFormReadyForSubmit,
  triggerChargeFormSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={Styles.addPaymentModal}
      modalHeaderClassName={Styles.modalHeaderContainer}
      modalBodyClassName={Styles.modalBodyContainer}
      modalFooterClassName={Styles.modalFooterContainer}
      isVisible={showModal}
      title="Add a Payment"
      isLoading={isModalLoading}
      body={
        currentModalStep === MODAL_STEP.TAKING_PAYMENT ? (
          <TakePaymentNowForm
            operatingAccountId={operatingAccount?.id}
            activeProviderType={activeProviderType}
            activeProviderFormattedSettings={activeProviderFormattedSettings}
            formData={formData}
            paymentSourceSelected={paymentSourceSelected}
            onUpdateFieldValues={onUpdateFieldValues}
            formSubmitting={formSubmitting}
            paidByContactDetailsLoading={paidByContactDetailsLoading}
            paidByContactDetails={paidByContactDetails}
            onChargeFormSubmit={onChargeFormSubmit}
            onChargeFormReadyForSubmit={onChargeFormReadyForSubmit}
            triggerChargeFormSubmit={triggerChargeFormSubmit}
            onChargeFormDataChange={onChargeFormDataChange}
          />
        ) : (
          <AddPaymentModalBody
            supportsEcheque={supportsEcheque}
            showReasonField={showReasonField}
            showTakePaymentNowField={showTakePaymentNowField}
            showPaymentPlanPaymentsWithInterestWarning={showPaymentPlanPaymentsWithInterestWarning}
            isChequeMemoVisible={isChequeMemoVisible}
            isReferenceReadOnly={isReferenceReadOnly}
            isTakePaymentNowDisabled={isTakePaymentNowDisabled}
            isDirectPayment={isDirectPayment}
            isTrustPayment={isTrustPayment}
            isPdfOnTrustPaymentDisabled={isPdfOnTrustPaymentDisabled}
            isTrustChequePrintingActive={isTrustChequePrintingActive}
            overdrawWarningProps={overdrawWarningProps}
            // form
            formData={formData}
            formErrors={formErrors}
            submitFailed={submitFailed}
            formDisabled={formSubmitting}
            balance={balance}
            printMethodOptions={printMethodOptions}
            paymentSourceOptions={paymentSourceOptions}
            paymentSourceOptionsLoading={paymentSourceOptionsLoading}
            trustChequeNumberLoading={trustChequeNumberLoading}
            // callbacks
            onUpdateFieldValues={onUpdateFieldValues}
            onPaymentTypeChange={onPaymentTypeChange}
            onTakePaymentNowToggled={onTakePaymentNowToggled}
            onChangePrintingMethod={onChangePrintingMethod}
            onReasonChange={onReasonChange}
            onSelectMatter={onSelectMatter}
            onSelectClient={onSelectClient}
            onSelectPaidBy={onSelectPaidBy}
            onPaymentSourceChange={onPaymentSourceChange}
            onEffectiveDateChange={onEffectiveDateChange}
            onPaymentsListChange={onPaymentsListChange}
            onReferenceChange={onReferenceChange}
            // matter typeahead
            defaultMatterSummaries={defaultMatterSummaries}
            matterSummaries={matterSummaries}
            matterSummariesDataLoading={matterSummariesDataLoading}
            matterSummariesHasMore={matterSummariesHasMore}
            onFetchMatterSummaries={onFetchMatterSummaries}
            onFetchMoreMatterSummaries={onFetchMoreMatterSummaries}
            // debtor typeahead
            defaultDebtorContactOptions={defaultDebtorContactOptions}
            debtorContactOptions={debtorContactOptions}
            debtorContactOptionsDataLoading={debtorContactOptionsDataLoading}
            debtorContactOptionsHasMore={debtorContactOptionsHasMore}
            onFetchDebtorContactOptions={onFetchDebtorContactOptions}
            onFetchMoreDebtorContactOptions={onFetchMoreDebtorContactOptions}
            // paid by typeahead
            defaultPaidByContactOptions={defaultPaidByContactOptions}
            paidByContactOptions={paidByContactOptions}
            paidByContactOptionsDataLoading={paidByContactOptionsDataLoading}
            paidByContactOptionsHasMore={paidByContactOptionsHasMore}
            onFetchPaidByContactOptions={onFetchPaidByContactOptions}
            onFetchMorePaidByContactOptions={onFetchMorePaidByContactOptions}
            // invoice summaries
            invoiceSummariesLoading={invoiceSummariesLoading}
            invoiceSummaries={invoiceSummaries}
          />
        )
      }
      footer={
        <div className={Styles.modalFooter}>
          {!!footerErrorMessages?.length && (
            <div className={Styles.errorContainer}>
              {footerErrorMessages.map((err) =>
                typeof err === 'string' ? (
                  <div key={err} className={Styles.errorRow}>
                    {err}
                  </div>
                ) : null,
              )}
            </div>
          )}
          {clientIsCoveringFee && currentModalStep === MODAL_STEP.TAKING_PAYMENT && totalChargeAmount > 0 && (
            <div className={Styles.totalChargeContainer}>
              Total charge <span className={Styles.amount}>{t('cents', { val: totalChargeAmount })}</span>
            </div>
          )}
          <Button
            className={Styles.processButton}
            onClick={onModalButtonClick}
            disabled={isSubmitDisabled}
            locked={isSubmitLocked}
            size="full-width"
          >
            {formData?.takePaymentNow && currentModalStep === MODAL_STEP.ADDING_PAYMENT
              ? 'Enter Payment Details'
              : 'Process'}
          </Button>
        </div>
      }
      onModalClose={onModalClose}
    />
  );
};

AddPaymentModal.propTypes = {
  /** Modal */
  isModalLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  currentModalStep: PropTypes.oneOf([MODAL_STEP.ADDING_PAYMENT, MODAL_STEP.TAKING_PAYMENT]).isRequired,
  // form
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  balance: PropTypes.number.isRequired,
  printMethodOptions: PropTypes.array.isRequired,
  paymentSourceOptions: PropTypes.array.isRequired,
  paymentSourceOptionsLoading: PropTypes.bool.isRequired,
  trustChequeNumberLoading: PropTypes.bool.isRequired,
  footerErrorMessages: PropTypes.array.isRequired,
  totalChargeAmount: PropTypes.number.isRequired,
  clientIsCoveringFee: PropTypes.bool.isRequired,
  // ui control
  supportsEcheque: PropTypes.bool.isRequired,
  showReasonField: PropTypes.bool.isRequired,
  showTakePaymentNowField: PropTypes.bool.isRequired,
  showPaymentPlanPaymentsWithInterestWarning: PropTypes.bool.isRequired,
  isChequeMemoVisible: PropTypes.bool.isRequired,
  isReferenceReadOnly: PropTypes.bool.isRequired,
  isTakePaymentNowDisabled: PropTypes.bool.isRequired,
  isTrustPayment: PropTypes.bool.isRequired,
  isDirectPayment: PropTypes.bool.isRequired,
  isPdfOnTrustPaymentDisabled: PropTypes.bool.isRequired,
  isTrustChequePrintingActive: PropTypes.bool.isRequired,
  overdrawWarningProps: PropTypes.object.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isSubmitLocked: PropTypes.bool.isRequired,
  // callbacks
  onUpdateFieldValues: PropTypes.func.isRequired,
  onPaymentTypeChange: PropTypes.func.isRequired,
  onTakePaymentNowToggled: PropTypes.func.isRequired,
  onChangePrintingMethod: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  onSelectMatter: PropTypes.func.isRequired,
  onSelectClient: PropTypes.func.isRequired,
  onSelectPaidBy: PropTypes.func.isRequired,
  onPaymentSourceChange: PropTypes.func.isRequired,
  onEffectiveDateChange: PropTypes.func.isRequired,
  onPaymentsListChange: PropTypes.func.isRequired,
  onReferenceChange: PropTypes.func.isRequired,
  onModalButtonClick: PropTypes.func.isRequired,
  // matter typeahead
  defaultMatterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterSummariesDataLoading: PropTypes.bool.isRequired,
  matterSummariesHasMore: PropTypes.bool.isRequired,
  onFetchMatterSummaries: PropTypes.func.isRequired,
  onFetchMoreMatterSummaries: PropTypes.func.isRequired,
  // debtor typeahead
  defaultDebtorContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  debtorContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  debtorContactOptionsDataLoading: PropTypes.bool.isRequired,
  debtorContactOptionsHasMore: PropTypes.bool.isRequired,
  onFetchDebtorContactOptions: PropTypes.func.isRequired,
  onFetchMoreDebtorContactOptions: PropTypes.func.isRequired,
  // paid by typeahead
  defaultPaidByContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  paidByContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  paidByContactOptionsDataLoading: PropTypes.bool.isRequired,
  paidByContactOptionsHasMore: PropTypes.bool.isRequired,
  onFetchPaidByContactOptions: PropTypes.func.isRequired,
  onFetchMorePaidByContactOptions: PropTypes.func.isRequired,
  // invoice summaries
  invoiceSummariesLoading: PropTypes.bool.isRequired,
  invoiceSummaries: PropTypes.arrayOf(PropTypes.object),
  onFetchInvoiceSummaries: PropTypes.func.isRequired,
  // take payment now
  operatingAccount: PropTypes.object.isRequired,
  activeProviderType: PropTypes.string,
  activeProviderFormattedSettings: PropTypes.object,
  paymentSourceSelected: PropTypes.object,
  paidByContactDetails: PropTypes.object,
  paidByContactDetailsLoading: PropTypes.bool.isRequired,
  onChargeFormDataChange: PropTypes.func.isRequired,
  onChargeFormSubmit: PropTypes.func.isRequired,
  onChargeFormReadyForSubmit: PropTypes.func.isRequired,
  triggerChargeFormSubmit: PropTypes.bool.isRequired,
};

AddPaymentModal.defaultProps = {
  paymentSourceSelected: undefined,
  invoiceSummaries: undefined,
  activeProviderType: undefined,
  activeProviderFormattedSettings: {},
  paidByContactDetails: {},
};

AddPaymentModal.displayName = 'AddPaymentModal';
