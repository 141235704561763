'use strict';

const { firmStatuses } = require('./firm-statuses');

/**
 * isPaymentProviderEnabledForBankAccount
 * Returns true if FeeWise payments are enabled for the passed bank account ID.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings FeeWise settings as configured in firm settings
 * @param {string} params.bankAccountId The bank account ID to check for FeeWise payment availability.
 * @returns {boolean}
 */
function isPaymentProviderEnabledForBankAccount({ formattedProviderSpecificSettings, bankAccountId }) {
  if (!bankAccountId) {
    return false;
  }

  if (!formattedProviderSpecificSettings || !formattedProviderSpecificSettings.linkedAccounts) {
    return false;
  }

  // FeeWise application is approved
  if (formattedProviderSpecificSettings.status !== firmStatuses.ACTIVE) {
    return false;
  }

  const linkedAccount = formattedProviderSpecificSettings.linkedAccounts[bankAccountId];

  return !!linkedAccount;
}

module.exports = {
  isPaymentProviderEnabledForBankAccount,
};
