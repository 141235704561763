import { hasFacet, facets } from '@sb-itops/region-facets';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { capitalize, sortByOrder } from '@sb-itops/nodash';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { getControlledMoneyAccounts, getOperatingAccount, isOperatingAccount } from '@sb-billing/redux/bank-account';
import { bankAccountState } from '@sb-billing/business-logic/bank-account/entities/constants';

export function getBankAccountsForMatter(matterId, t) {
  let accounts = [];

  // Add Trust Accounts
  const trustAccounts = filterTrustAccountsByMatter(matterId);
  accounts = [...formatAccounts(trustAccounts, t)];

  // Add CMAs
  const controlledMoneyAccounts = getControlledMoneyAccounts().filter(
    (account) => account.associatedMatterId === matterId && account.state !== bankAccountState.CLOSED,
  );
  accounts = [...accounts, ...formatAccounts(controlledMoneyAccounts, t)];

  // Add Operating Account
  const operatingTransfersAllowed = hasFacet(facets.operatingAccount);
  if (operatingTransfersAllowed) {
    const operatingAccount = getOperatingAccount();
    accounts = [...accounts, ...formatAccounts([operatingAccount], t)];
  }

  return accounts;
}

export function formatAccounts(accounts, t) {
  const formattedAccounts = accounts.reduce((acc, account) => {
    acc.push(formatAccountDisplay(account, t));
    return acc;
  }, []);

  return sortByOrder(formattedAccounts, ['display'], ['asc']);
}

export function formatAccountDisplay(account, t) {
  const formatted = {
    ...account,
  };
  formatted.display = capitalize(account.accountType.toLowerCase());
  if (isOperatingAccount(account.accountType)) {
    formatted.display = t('operatingRetainer');
  } else {
    formatted.display = getBankAccountName(account, t);
  }
  return formatted;
}
