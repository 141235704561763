'use strict';

const Yup = require('yup');

const CustomerBillingConfigurationSchema = Yup.object().shape({
  entityId: Yup.string().required(),
  ledesClientId: Yup.string(),
});

module.exports = {
  CustomerBillingConfigurationSchema,
};
