// LOD compatible
import React from 'react';
import PropTypes from 'prop-types';
import { featureActive, featureData } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { store } from '@sb-itops/redux';
import { types as appStateTypes, actions as appStateActions } from 'web/redux/features/application-state';
import { UserTwoFactorStatus, XeroAccessToken, InitStaffSettings } from 'web/graphql/queries';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import { getUserId } from 'web/services/user-session-management';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { PageBannerXero } from './PageBannerXero';

const hooks = () => ({
  useXeroAccessToken: () => {
    const { data: xeroAccessTokenData } = useSubscribedQuery(XeroAccessToken, {
      skip: !featureActive('BB-13237'),
    });

    const { id: accountId } = xeroAccessTokenData?.xeroAccessToken || {};

    return {
      xeroIsConnected: !!accountId, // We don't really care about accountId but it will exist only when Xero is connected
    };
  },
  useUserTwoFactorStatus: () => {
    const { data: userTwoFactorStatusData } = useSubscribedQuery(UserTwoFactorStatus, {
      skip: !featureActive('BB-13237'),
      variables: {
        id: getUserId(),
      },
    });

    const { twoFactorEnabled } = userTwoFactorStatusData?.userTwoFactorStatus || {};

    return {
      userHasTwoFactorEnabled: !!twoFactorEnabled,
    };
  },
  useStaffSettingsData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query, {
      skip: !featureActive('BB-13237'),
    });
    const userIsStaffMember = !!staffData?.loggedInStaff;

    return {
      userIsStaffMember,
    };
  },
});

// Show banner if all conditions are met:
// - feature is active and at least "text" is set
// - User is a staff member (this is so we don't show it for setup accounts)
// - Xero is connected
// - 2FA is not enabled for user
const dependentHooks = () => ({
  useMessage: ({ userHasTwoFactorEnabled, xeroIsConnected, userIsStaffMember }) => {
    const bannerIsEnabled = featureActive('BB-13237') && featureData('BB-13237')?.text;

    let finalMessage = null;

    if (bannerIsEnabled && userIsStaffMember && xeroIsConnected && !userHasTwoFactorEnabled) {
      if (featureActive('BB-14377')) {
        // set loginBlockedReason so next time we are on login screen, we see the notice instead of login form
        store.dispatch(
          appStateActions.setLoginBlockedReason({ reason: appStateTypes.loginBlockedReasons.NO_2FA_XERO_USER }),
        );
        const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
        urlParams.append('2fa-error', 'true');
        // setting url param which forces a browser refresh. This will trigger post authentication
        // to run if this route was loaded as part of a cancelled subscription
        // bootstrap process can complete itself, e.g. 2fa xero check with logout
        window.location.href = `?${urlParams.toString()}/#/billing/matters`;
      }
      const data = featureData('BB-13237');
      finalMessage = [data.text];

      if (data?.url) {
        finalMessage.push(
          <React.Fragment key="1">
            &nbsp;-&nbsp;
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              Find Out More
            </a>
            !
          </React.Fragment>,
        );
      }
    }

    return {
      message: finalMessage,
    };
  },
});

export const PageBannerXeroContainer = withApolloClient(
  composeHooks(hooks)(composeHooks(dependentHooks)(PageBannerXero)),
);

PageBannerXeroContainer.displayName = 'PageBannerXeroContainer';

PageBannerXeroContainer.propTypes = {
  navHighlights: PropTypes.object, // Used to trigger a re-render when route changes
};

PageBannerXeroContainer.defaultProps = {
  navHighlights: undefined,
};
