'use strict';

/**
 * A transaction filter function that determines whether the transaction falls into the "Other" transactions bucket
 * "Other" transactions may be in Trust or Operating accounts
 * "Other" transactions include Transfers and Vendor Payments
 * @param {Object} transaction

 */
function otherTransactionsFilter(tx) {
  return !tx.paymentId || tx.type === 'Transfer' || tx.type === 'VendorPayment' || tx.type === 'VendorPaymentReversal';
}

module.exports = {
  otherTransactionsFilter,
};
