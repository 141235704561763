'use strict';

const reportAccountsAllMapping = {
  US: {
    default: ['Operating', 'Trust'],
  },
  AU: {
    default: ['Trust'],
  },
  GB: {
    default: ['Trust'],
  },
};

module.exports = {
  reportAccountsAllMapping,
};
