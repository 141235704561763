import React from 'react';
import {
  Envelope,
  FileDoc,
  FileLines,
  FilePDF,
  FilePPT,
  FileVideo,
  FileXLS,
  FileImage,
  FileImageRegular,
  FileVideoRegular,
  FileDocRegular,
  FileXLSRegular,
  FilePPTRegular,
  FileLinesRegular,
  FilePDFRegular,
  EnvelopeRegular,
} from 'web/components/icons';

// A solid icon is a font awesome style of icon that is mostly filled. The alternative regular style has more white space
export const IconSelector = ({ extension, isSolid, fill }: { extension: string; isSolid?: boolean; fill?: string }) => {
  switch (extension) {
    case '.png':
    case '.jpg':
    case '.jpeg':
      return isSolid ? <FileImage fill={fill} /> : <FileImageRegular fill={fill} />;
    case '.wav':
    case '.mp4':
      return isSolid ? <FileVideo fill={fill} /> : <FileVideoRegular fill={fill} />;
    case '.docx':
    case '.doc':
      return isSolid ? <FileDoc fill={fill} /> : <FileDocRegular fill={fill} />;
    case '.csv':
    case '.xlsx':
    case '.xls':
      return isSolid ? <FileXLS fill={fill} /> : <FileXLSRegular fill={fill} />;
    case '.ppt':
      return isSolid ? <FilePPT fill={fill} /> : <FilePPTRegular fill={fill} />;
    case '.txt':
      return isSolid ? <FileLines fill={fill} /> : <FileLinesRegular fill={fill} />;
    case '.pdf':
      return isSolid ? <FilePDF fill={fill} /> : <FilePDFRegular fill={fill} />;
    case '.eml':
    case '.msg':
      return isSolid ? <Envelope fill={fill} /> : <EnvelopeRegular fill={fill} />;
    default:
      return isSolid ? <FileLines fill={fill} /> : <FileLinesRegular fill={fill} />;
  }
};
