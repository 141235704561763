import * as React from 'react';

import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { useMultipleItemOrderedSelection } from '@sb-itops/redux/multi-item-ordered-select/use-multiple-item-ordered-selection';
import { featureActive } from '@sb-itops/feature';
import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';

// The invoice table selection logic is used in multiple places: Firm Invoices, Contact
// Invoices, Matter Invoices and Advanced Search Results Invoices. Instead of
// duplicating the same logic in those containers, it's consolidated in this hook

export function useInvoiceTableSelections({ scope }) {
  // All invoices
  const {
    selectedItems,
    deselectAllItems: deselectAllInvoices,
    toggleItems,
  } = useMultipleItemSelection({
    scope: `${scope}/all-selected-invoices`,
  });

  // Combine in PDF
  //  * Selection order is required for this action
  const {
    selectedOrderedItems: selectedInvoicesToCombineInPdf,
    deselectAllOrderedItems: deselectInvoicesToCombineInPdf,
    toggleOrderedItems: toggleInvoicesToCombineInPdf,
  } = useMultipleItemOrderedSelection({
    scope: `${scope}/combine-in-pdf`,
  });

  // Combine In PDF With Cover Letter
  //  * Selection order is required for this action
  const {
    selectedOrderedItems: selectedInvoicesToCombineInPdfWithCoverLetter,
    deselectAllOrderedItems: deselectInvoicesToCombineInPdfWithCoverLetter,
    toggleOrderedItems: toggleInvoicesToCombineInPdfWithCoverLetter,
  } = useMultipleItemOrderedSelection({
    scope: `${scope}/combine-in-pdf-with-cover-letter`,
  });

  // Download LEDES
  const {
    selectedItems: selectedInvoicesToDownloadLedes,
    deselectAllItems: deselectInvoicesToDownloadLedes,
    toggleItems: toggleInvoicesToDownloadLedes,
  } = useMultipleItemSelection({
    scope: `${scope}/download-ledes`,
  });

  // Delete draft invoices
  const {
    selectedItems: selectedDraftInvoicesToDelete,
    deselectAllItems: deselectDraftInvoicesToDelete,
    toggleItems: toggleDraftInvoicesToDelete,
  } = useMultipleItemSelection({
    scope: `${scope}/delete-draft-invoices`,
  });

  // Finalise draft invoices
  const {
    selectedItems: selectedDraftInvoicesToFinalise,
    deselectAllItems: deselectDraftInvoicesToFinalise,
    toggleItems: toggleDraftInvoicesToFinalise,
  } = useMultipleItemSelection({
    scope: `${scope}/finalise-draft-invoices`,
  });

  // Email
  const {
    selectedItems: selectedInvoicesToEmail,
    deselectAllItems: deselectInvoicesToEmail,
    toggleItems: toggleInvoicesToEmail,
  } = useMultipleItemSelection({
    scope: `${scope}/email`,
  });

  // Mark As Sent
  const {
    selectedItems: selectedInvoicesToMarkAsSent,
    deselectAllItems: deselectInvoicesToMarkAsSent,
    toggleItems: toggleInvoicesToMarkAsSent,
  } = useMultipleItemSelection({
    scope: `${scope}/mark-as-sent`,
  });

  // Create Statement
  const {
    selectedItems: selectedInvoicesToCreateStatement,
    deselectAllItems: deselectInvoicesToCreateStatement,
    toggleItems: toggleInvoicesToCreateStatement,
  } = useMultipleItemSelection({
    scope: `${scope}/create-statement`,
  });

  // When selecting/deselecting invoices, we need to:
  //  1. Group/ungroup them by what actions are available to them (based on their status)
  //    * When performing an "action" (e.g. finalising), it should only apply the action to the subset of invoices that can be actioned by the operation
  //    * E.g. Only DRAFT invoices should be able to be "finalised"
  //  2. Keep track of all selections/deselections
  //    * This is handled by toggleItems
  const onToggleInvoices = React.useCallback(
    ({ invoices }) => {
      const { DRAFT, FINAL, PAID } = invoiceStatusesMap;

      const toggleInvoices = invoices.reduce(
        (acc, invoice) => {
          const {
            id: invoiceId,
            pseudoStatus,
            isUtbmsEnabledForInvoiceMatter,
            invoiceStatementId,
            debtorCount,
            showRetainer,
          } = invoice;

          // Finalise or delete draft
          if ([DRAFT].includes(pseudoStatus)) {
            acc.draftInvoicesToDelete.push(invoiceId);
            acc.draftInvoicesToFinalise.push(invoiceId);
          }

          // Combine in PDF
          if ([DRAFT, FINAL, 'OVERDUE', PAID].includes(pseudoStatus)) {
            acc.invoicesToCombineInPdf.push(invoiceId);
          }

          // Combine in PDF with Cover Letter
          if ([DRAFT, FINAL, 'OVERDUE'].includes(pseudoStatus)) {
            acc.invoicesToCombineInPdfWithCoverLetter.push(invoiceId);
          }

          // Email and Mark as Sent
          if ([FINAL, 'OVERDUE', PAID].includes(pseudoStatus)) {
            acc.invoicesToEmail.push(invoiceId);
            acc.invoicesToMarkAsSent.push(invoiceId);
          }

          // Create Statement
          // Statement does not allow invoices with multiple debtors or retainer requests
          if (
            featureActive('BB-14011') &&
            [FINAL, 'OVERDUE', PAID].includes(pseudoStatus) &&
            !invoiceStatementId && // not on an invoice statement already
            debtorCount === 1 &&
            !showRetainer
          ) {
            acc.invoicesToCreateStatement.push(invoiceId);
          }

          // Download LEDES
          if (isUtbmsEnabledForInvoiceMatter) {
            acc.invoicesToDownloadLedes.push(invoiceId);
          }

          acc.allInvoiceIds.push(invoiceId);
          return acc;
        },
        {
          draftInvoicesToDelete: [],
          draftInvoicesToFinalise: [],
          invoicesToCombineInPdf: [],
          invoicesToCombineInPdfWithCoverLetter: [],
          invoicesToEmail: [],
          invoicesToMarkAsSent: [],
          invoicesToCreateStatement: [],
          invoicesToDownloadLedes: [],
          allInvoiceIds: [],
        },
      );

      if (toggleInvoices.draftInvoicesToDelete.length) {
        toggleDraftInvoicesToDelete(toggleInvoices.draftInvoicesToDelete);
      }

      if (toggleInvoices.draftInvoicesToFinalise.length) {
        toggleDraftInvoicesToFinalise(toggleInvoices.draftInvoicesToFinalise);
      }

      if (toggleInvoices.invoicesToCombineInPdf.length) {
        toggleInvoicesToCombineInPdf(toggleInvoices.invoicesToCombineInPdf);
      }

      if (toggleInvoices.invoicesToCombineInPdfWithCoverLetter.length) {
        toggleInvoicesToCombineInPdfWithCoverLetter(toggleInvoices.invoicesToCombineInPdfWithCoverLetter);
      }

      if (toggleInvoices.invoicesToEmail.length) {
        toggleInvoicesToEmail(toggleInvoices.invoicesToEmail);
      }

      if (toggleInvoices.invoicesToMarkAsSent.length) {
        toggleInvoicesToMarkAsSent(toggleInvoices.invoicesToMarkAsSent);
      }

      if (toggleInvoices.invoicesToCreateStatement.length) {
        toggleInvoicesToCreateStatement(toggleInvoices.invoicesToCreateStatement);
      }

      if (toggleInvoices.invoicesToDownloadLedes.length) {
        toggleInvoicesToDownloadLedes(toggleInvoices.invoicesToDownloadLedes);
      }

      if (toggleInvoices.allInvoiceIds.length) {
        toggleItems(toggleInvoices.allInvoiceIds); // All invoice selections
      }
    },
    [
      toggleDraftInvoicesToDelete,
      toggleDraftInvoicesToFinalise,
      toggleInvoicesToCombineInPdf,
      toggleInvoicesToCombineInPdfWithCoverLetter,
      toggleInvoicesToDownloadLedes,
      toggleInvoicesToEmail,
      toggleInvoicesToMarkAsSent,
      toggleInvoicesToCreateStatement,
      toggleItems,
    ],
  );

  const allSelectedInvoices = React.useMemo(() => selectedItems, [selectedItems]);

  const selectedInvoicesByAction = React.useMemo(
    () => ({
      // Objects
      deleteDraft: Object.keys(selectedDraftInvoicesToDelete),
      downloadLedes: Object.keys(selectedInvoicesToDownloadLedes),
      email: Object.keys(selectedInvoicesToEmail),
      finalise: Object.keys(selectedDraftInvoicesToFinalise),
      markAsSent: Object.keys(selectedInvoicesToMarkAsSent),
      createStatement: Object.keys(selectedInvoicesToCreateStatement),
      // Maps
      combineInPdf: Array.from(selectedInvoicesToCombineInPdf.keys()),
      combineInPdfWithCoverLetter: Array.from(selectedInvoicesToCombineInPdfWithCoverLetter.keys()),
    }),
    [
      selectedDraftInvoicesToDelete,
      selectedDraftInvoicesToFinalise,
      selectedInvoicesToCombineInPdf,
      selectedInvoicesToCombineInPdfWithCoverLetter,
      selectedInvoicesToDownloadLedes,
      selectedInvoicesToEmail,
      selectedInvoicesToMarkAsSent,
      selectedInvoicesToCreateStatement,
    ],
  );

  // Deleted draft invoices need to be deselected as they are no longer on the list
  const onDeselectDeletedDraftInvoices = React.useCallback(
    ({ invoiceIds }) => {
      toggleDraftInvoicesToDelete(invoiceIds);
      toggleDraftInvoicesToFinalise(invoiceIds);
      toggleInvoicesToCombineInPdf(invoiceIds);
      toggleInvoicesToCombineInPdfWithCoverLetter(invoiceIds);
      toggleItems(invoiceIds);
    },
    [
      toggleDraftInvoicesToDelete,
      toggleDraftInvoicesToFinalise,
      toggleInvoicesToCombineInPdf,
      toggleInvoicesToCombineInPdfWithCoverLetter,
      toggleItems,
    ],
  );

  // When finalising a draft invoice we need to update available actions
  const onFinaliseDraftInvoicesSuccess = React.useCallback(
    ({ invoiceIds }) => {
      //  1. Remove "draft invoice" exclusive actions available to them
      toggleDraftInvoicesToDelete(invoiceIds);
      toggleDraftInvoicesToFinalise(invoiceIds);
      //  2. Add "finalised" exclusive invoice actions to them
      toggleInvoicesToEmail(invoiceIds);
      toggleInvoicesToMarkAsSent(invoiceIds);
      toggleInvoicesToCreateStatement(invoiceIds);
    },
    [
      toggleDraftInvoicesToDelete,
      toggleDraftInvoicesToFinalise,
      toggleInvoicesToEmail,
      toggleInvoicesToMarkAsSent,
      toggleInvoicesToCreateStatement,
    ],
  );

  // In order to ensure accurate invoice statuses and their available actions, we reset the selections if the user leaves the invoice list
  //
  // This is because, events outside of the invoice list can impact what invoice actions are available
  //  * E.g. A draft invoice was finalised via the invoice list, but it can be "edited" on the finalised invoice page (reverting it back to a draft invoice)
  //    * This behaviour can be an issue if invoice selections are persisted beyond leaving the list
  function onResetAllInvoiceSelections() {
    deselectAllInvoices();
    deselectInvoicesToCombineInPdf();
    deselectInvoicesToCombineInPdfWithCoverLetter();
    deselectInvoicesToDownloadLedes();
    deselectDraftInvoicesToDelete();
    deselectDraftInvoicesToFinalise();
    deselectInvoicesToEmail();
    deselectInvoicesToMarkAsSent();
    deselectInvoicesToCreateStatement();
  }

  React.useEffect(
    () => () => onResetAllInvoiceSelections(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    selectedInvoicesByAction,
    selectedInvoices: allSelectedInvoices,
    onDeselectDeletedDraftInvoices,
    onFinaliseDraftInvoicesSuccess,
    toggleInvoicesToCreateStatement,
    onToggleInvoices,
  };
}
