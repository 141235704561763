'use strict';

/**
 * Derive supplementary tables related additional options from template/invoice section options - in case they are not consistent (safety guard)
 *
 * @param {object} sectionOptions - template/invoice section options
 * @returns {object} - invoiceAdditionalOptions derived from additionalOptions
 */
const deriveSupplementaryTablesAdditionalOptionsFromSectionOptions = ({ sectionOptions }) => {
  if (!sectionOptions) {
    throw new Error('sectionOptions is required');
  }

  const invoiceAdditionalOptions = {
    showSummaryForTimekeepers: sectionOptions.feeSummary && sectionOptions.feeSummary.showSection,
    showTimekeeperRole: sectionOptions.feeSummary && sectionOptions.feeSummary.showTimekeeperRole,
    hidePriorBalance: !(sectionOptions.priorBalances && sectionOptions.priorBalances.showSection),
    hidePaymentSummary: !(sectionOptions.paymentSummary && sectionOptions.paymentSummary.showSection),
    showInvoiceSummary: sectionOptions.invoiceSummary && sectionOptions.invoiceSummary.showSection,
    showAccountSummary: sectionOptions.accountSummary && sectionOptions.accountSummary.showSection,
    showTransactionHistory: sectionOptions.transactionHistory && sectionOptions.transactionHistory.showSection,
  };

  return invoiceAdditionalOptions;
};

module.exports = {
  deriveSupplementaryTablesAdditionalOptionsFromSectionOptions,
};
