'use strict';

const { PersonSchema, CompanySchema, TrustSchema, AddressSchema, OptionalAddressSchema } = require('./contact-schema');

module.exports = {
  OptionalAddressSchema,
  AddressSchema,
  CompanySchema,
  PersonSchema,
  TrustSchema,
};
