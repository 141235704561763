'use strict';

const createErrorHandler = ({ throwOnFail }) =>
  !throwOnFail
    ? () => false
    : (msg) => {
        throw new Error(msg);
      };

const validateInvoiceEmailRequest = (invoiceEmailRequest, { requireDebtorId = false, throwOnFail = true }) => {
  const handleError = createErrorHandler({ throwOnFail });

  if (!invoiceEmailRequest.template) {
    return handleError('Invoice email request missing template property');
  }

  if (!invoiceEmailRequest.invoiceIds) {
    return handleError('Invoice email request is missing invoice ids property');
  }

  if (!invoiceEmailRequest.invoiceIds.length) {
    return handleError('Invoice email request must conain at least one invoice id');
  }

  if (requireDebtorId && !invoiceEmailRequest.debtorId) {
    return handleError('Invoice email request missing debtorId proeprty');
  }

  return true;
};

module.exports = {
  validateInvoiceEmailRequest,
};
