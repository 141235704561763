'use strict';

const lowerFirst = (str) => str.charAt(0).toLowerCase() + str.slice(1);

const toCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(arrayItem => toCamelCase(arrayItem));
  }

  if (obj === null || typeof obj !== 'object' || obj instanceof Date) {
    return obj;
  }

  return Object.entries(obj).reduce((camelCasedObj, [key, val]) => {
    if (val !== undefined) {
      // special case for the object key that fixes bug where $type from .net 
      // was causing confusion with the $type used by the indexedDB -> webSql wrapper
      const newKey = key === '$type' ? '$sbEntityType' : lowerFirst(key);
      camelCasedObj[newKey] = toCamelCase(val);
    }

    return camelCasedObj;
  }, {});
};

module.exports.toCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => toCamelCase(item));
  }

  return toCamelCase(obj);
};
