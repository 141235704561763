import PropTypes from 'prop-types';
import { hasFacet, facets } from '@sb-itops/region-facets';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { invoiceStatementStatusLabels } from '@sb-billing/business-logic/invoice-statement';

import { InvoiceStatementSummaryPanel } from './InvoiceStatementSummaryPanel';

const hooks = () => ({
  useInvoicesData: ({ invoices }) => {
    const invoiceStatementSummary = invoices.reduce(
      (acc, invoice) => {
        acc.totalExcInterest += invoice.totals.totalExcInterest || 0;
        acc.interest += invoice.totals.interest || 0;
        acc.waived += invoice.totals.waived || 0;
        acc.paidByCredit += invoice.totals.paidByCredit || 0;
        acc.paid += invoice.totals.paid || 0;
        acc.unpaid += invoice.totals.unpaid || 0;
        return acc;
      },
      {
        totalExcInterest: 0,
        interest: 0,
        waived: 0,
        paidByCredit: 0,
        paid: 0,
        unpaid: 0,
      },
    );
    return {
      invoices,
      interest: invoiceStatementSummary.interest,
      totalExcInterest: invoiceStatementSummary.totalExcInterest,
      waived: invoiceStatementSummary.waived,
      paidByCredit: invoiceStatementSummary.paidByCredit,
      paid: invoiceStatementSummary.paid,
      unpaid: invoiceStatementSummary.unpaid,
    };
  },
  useSummaryPanel: ({ invoiceStatementStatus, invoiceStatementLastUpdated }) => ({
    isVoid: invoiceStatementStatus === invoiceStatementStatusLabels.VOID.toUpperCase(),
    voidOnDate: invoiceStatementLastUpdated,
    showInterest: hasFacet(facets.interest),
    showTrustBalance: hasFacet(facets.showTrustBalance),
  }),
});

export const InvoiceStatementSummaryPanelContainer = withReduxProvider(
  composeHooks(hooks)(InvoiceStatementSummaryPanel),
);

InvoiceStatementSummaryPanelContainer.displayName = 'InvoiceStatementSummaryPanelContainer';

InvoiceStatementSummaryPanelContainer.propTypes = {
  debtor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    displayNameFirstLast: PropTypes.string,
    trustBalance: PropTypes.number,
  }),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.number.isRequired,
      totals: PropTypes.shape({
        interest: PropTypes.number.isRequired,
        totalExcInterest: PropTypes.number.isRequired,
        waived: PropTypes.number.isRequired,
        paidByCredit: PropTypes.number.isRequired,
        paid: PropTypes.number.isRequired,
        unpaid: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
  invoiceStatementStatus: PropTypes.string,
  invoiceStatementLastUpdated: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
};

InvoiceStatementSummaryPanelContainer.defaultProps = {
  debtor: undefined,
  invoices: [],
  invoiceStatementStatus: undefined,
  invoiceStatementLastUpdated: undefined,
};
