export const getTaskView = (state) => state.taskView;
export const getExpanded = (state) => state.expanded;
export const getSortBy = (state) => state.sortBy;
export const getSortDirection = (state) => state.sortDirection;
export const getAssigneeFilterExpanded = (state) => state.assigneeFilterExpanded;
export const getCategoryFilterExpanded = (state) => state.categoryFilterExpanded;
export const getViewExpanded = (state) => state.viewExpanded;
export const getShowDeleted = (state) => state.showDeleted;
export const getAssigneeIds = (state) => state.assigneeIds || [];
export const getCategoryIds = (state) => state.categoryIds || [];
