import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { AddAdjustmentModalButton } from '@sb-billing/react';
import * as bankReconciliationsFeature from 'web/redux/route/home-billing-create-bank-reconciliation';
import { withScopedFeature } from '@sb-itops/redux/hofs';

const mapDispatchToProps = (dispatch, { onUpdate, trustAccountId }) => {
  const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
  const {
    actions: { addAdjustment },
  } = withScopedFeature({ scope: bankRecScope })(bankReconciliationsFeature);

  return {
    onSubmitHandler: (adjustment) => {
      dispatch(addAdjustment({ adjustment }));
      onUpdate();
    },
  };
};

const AddAdjustmentModalButtonWrapper = withReduxStore(connect(null, mapDispatchToProps)(AddAdjustmentModalButton));

AddAdjustmentModalButtonWrapper.displayName = 'AddAdjustmentModalButtonWrapper';

AddAdjustmentModalButtonWrapper.propTypes = {
  route: PropTypes.string.isRequired,
  id: PropTypes.string,
  trustAccountId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

AddAdjustmentModalButtonWrapper.defaultProps = {
  id: '',
};

export default AddAdjustmentModalButtonWrapper;
