import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { setDefaultPaymentMethod } from 'web/services/subscription';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getAccountId } from 'web/services/user-session-management';
import {
  getById as getSubscriptionByAccountId,
  opdateCache as opdateSubscription,
} from '@sb-finance/redux/subscription';

import { SubscriptionCompleteRoute } from './SubscriptionCompleteRoute';

// This component is required in the unlikely scenario that Stripe decides a
// url redirection is required after confirming trial subscription (confirmSetup)
// This scenario can be tested by using a 3DS card for subscription.
const hooks = () => ({
  useSetSubscriptionPaymentMethod: () => {
    const [isSubscriptionPaymentMethodUpdating, setIsSubscriptionPaymentMethodUpdating] = useState(false);

    useEffect(() => {
      const setPaymentMethod = async () => {
        setIsSubscriptionPaymentMethodUpdating(true);

        try {
          // get setup intent id from redirect url parameter
          const urlParams = new URLSearchParams(window.location.search);
          const setupIntentId = urlParams.get('setup_intent');

          if (!setupIntentId || typeof setupIntentId === 'string' || setupIntentId.length > 0) {
            throw new Error('SetupIntentId not found');
          }

          const response = await setDefaultPaymentMethod({ setupIntentId });
          const { paymentMethodId, tierChanged, tierId } = response.body || {};

          // opdate subscription
          const accountId = getAccountId();
          const subscription = getSubscriptionByAccountId(accountId);
          const subscriptionForOpdate = { ...subscription };

          subscriptionForOpdate.externalPaymentMethodIds = [paymentMethodId];
          opdateSubscription({ optimisticEntities: [subscriptionForOpdate] });

          // If the tier has changed, let the backend know to change the firm's product tier.
          if (tierChanged) {
            await dispatchCommand({
              type: 'ProductRegistration.Commands.SetProductIdForFirmProductAccount',
              message: { productTierId: tierId },
            });
          }
        } catch (err) {
          displayErrorToUser('Failed to update subscription payment method');
        } finally {
          setIsSubscriptionPaymentMethodUpdating(false);
        }
      };

      setPaymentMethod();
    }, []);

    return {
      isSubscriptionPaymentMethodUpdating,
    };
  },
  useOnPaymentMethodUpdated: ({ onClickLink }) => {
    const onPaymentMethodUpdated = () => onClickLink({ type: 'matterList' });
    return {
      onPaymentMethodUpdated,
    };
  },
});

export const SubscriptionCompleteRouteContainer = composeHooks(hooks)(SubscriptionCompleteRoute);

SubscriptionCompleteRouteContainer.displayName = 'SubscriptionCompleteRouteContainer';

SubscriptionCompleteRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};

SubscriptionCompleteRouteContainer.defaultProps = {};
