import React from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
  Button,
  CurrencyDisplay,
  CurrencyInput2,
  DatePicker,
  EditableTypeahead,
  NumberInput,
  SlidingToggle,
} from '@sb-itops/react';
import {
  activityCategoryLabels,
  activityCategories,
  taskCategoryLabels,
  taskCategories,
} from '@sb-billing/business-logic/activities/entities/constants';
import { ActivityDropdown } from '@sb-billing/react/activity-dropdown';
import { StaffDropdown, TaskDropdown } from '@sb-billing/react';
import { MatterTypeahead2 } from '@sb-matter-management/react';
import * as Styles from './QuickExpenseEntry.module.scss';

export const QuickExpenseEntry = (props) => {
  const { quickAddDisabled, region, showMatterField } = props;
  const { staffMemberOptions, onUpdateStaffMemberField } = props;
  const { matterSummaries, matterSummariesDataLoading, matterSummariesHasMore } = props;
  const { onFetchMatterSummaries, onFetchMoreMatterSummaries, onUpdateMatterField } = props;
  const { activities, onUpdateActivityField, showTasksField, tasksFieldDisabled, tasks, onUpdateTaskField } = props;
  const { subjects, showTaxField, onUpdateInputTaxAmountField, setIsSubjectOverridable } = props;
  const { formData, formErrors, onUpdateField, onAddExpenseClicked, onClearForm } = props;
  const { t } = useTranslation();

  return (
    <div className={Styles.quickExpenseEntry}>
      {/* Date picker */}
      <div className={Styles.dateInputSection}>
        <label>Date</label>
        <DatePicker
          onSelect={(date) => onUpdateField('expenseDate', date)}
          value={formData.expenseDate}
          disabled={quickAddDisabled}
          hasError={formErrors.expenseDate.isInvalid}
          hideDelete
        />
      </div>

      {/* Staff drop down */}
      <div className={Styles.staffInputSection}>
        <label>Staff</label>
        <StaffDropdown
          disabled={quickAddDisabled}
          hasError={formErrors.staffId.isInvalid}
          onSelectionChange={onUpdateStaffMemberField}
          placeholder=""
          selectedStaffId={formData.staffId}
          staffMemberOptions={staffMemberOptions}
          selectClassName={Styles.selectContainerStyling}
        />
      </div>

      {/* Matter drop down */}
      {showMatterField && matterSummaries && (
        <div className={Styles.matterInputSection}>
          <label>Matter</label>
          <MatterTypeahead2
            disabled={quickAddDisabled}
            hasError={formErrors.matter.isInvalid}
            isLoading={matterSummariesDataLoading}
            matters={matterSummaries}
            maxMenuHeight={250} // Default is 300 and was making the dropdown overflow
            onInputChange={onFetchMatterSummaries}
            onLoadMore={onFetchMoreMatterSummaries}
            onMatterSelected={(matter) => onUpdateMatterField(matter?.data)}
            placeholder="Select a matter..."
            selectedMatterId={formData.matter?.id}
            actionList={
              matterSummariesHasMore === true
                ? [
                    {
                      displayComponent: (
                        <span>
                          <i className="fa fa-plus" /> &emsp;Show more results
                        </span>
                      ),
                      callback: () => {
                        if (matterSummariesDataLoading) {
                          return;
                        }

                        onFetchMoreMatterSummaries();
                      },
                    },
                  ]
                : []
            }
          />
        </div>
      )}

      {/* Activity drop down */}
      <div
        className={Styles.activityInputSection}
        title={
          formData.utbmsCodesRequiredForMatter
            ? 'Activities are not enabled for expenses on this matter. Please choose an Expense code instead.'
            : ''
        }
      >
        <label>Activity</label>
        <ActivityDropdown
          placeholder=""
          className={Styles.activityDropdown}
          disabled={quickAddDisabled || formData.utbmsCodesRequiredForMatter}
          activities={activities}
          selectedActivityCode={formData.activity?.code}
          onSelectionChange={(selectedOption) =>
            onUpdateActivityField({ selectedActivity: selectedOption, isSelectedFromSubjectDropdown: false })
          }
          groupLabelHeight={26}
          optionLabelHeight={26}
          hideSelectedOptionDescription
          noDefaultStyling
          selectClassName={Styles.selectContainerStyling}
        />
      </div>

      {/* Task drop down */}
      {showTasksField && (
        <div className={Styles.taskInputSection}>
          <label>Expense</label>
          <TaskDropdown
            placeholder=""
            disabled={quickAddDisabled || tasksFieldDisabled}
            tasks={tasks}
            selectedTaskCode={formData.task?.code}
            onSelectionChange={onUpdateTaskField}
            hasError={formErrors.task.isInvalid}
            groupLabelHeight={26}
            optionLabelHeight={26}
            noDefaultStyling
            selectClassName={Styles.selectContainerStyling}
          />
        </div>
      )}

      {/* Subject typeahead */}
      <div className={Styles.subjectInputSection}>
        <label>Subject</label>
        <EditableTypeahead
          id="quick-expense-entry-subject"
          disabled={quickAddDisabled}
          options={subjects}
          // Don't allow input value to differ from the selected option.
          // Free text input (not matching options) is fine if no selectedOption is present (undefined)
          // It will result in weird UI artifacts like text over text otherwise.
          inputValue={formData.subject || ''}
          selectedOption={formData.subjectActivity}
          labelKey="description"
          menuClassName={Styles.widthOverride}
          hasError={formErrors.subject.isInvalid}
          onValueChange={(newSubject) => {
            onUpdateField('subject', newSubject);
            // We don't overwrite if the user has modified the subject line AND the subject line is not blank
            setIsSubjectOverridable(newSubject.trim() === '');
          }}
          onOptionSelected={(selectedOption) => {
            onUpdateActivityField({ selectedActivity: selectedOption, isSelectedFromSubjectDropdown: true });
          }}
        />
      </div>

      {/* Quantity input */}
      <div className={Styles.quantityInputSection}>
        <label>Quantity</label>
        <NumberInput
          className="form-control"
          disabled={quickAddDisabled}
          hasError={formErrors.quantity.isInvalid}
          value={formData.quantity}
          onChange={(e) => onUpdateField('quantity', e.target.value)}
        />
      </div>

      {/* Price input */}
      <div className={Styles.priceInputSection}>
        <label>Price</label>
        <CurrencyInput2
          name="price"
          disabled={quickAddDisabled}
          hasError={formErrors.priceInCents.isInvalid}
          value={formData.priceInCents}
          onChange={(e) => onUpdateField('priceInCents', e.target.value)}
        />
      </div>

      {/* Amount display */}
      <div className={Styles.amountDisplaySection}>
        <label>Amount</label>
        <CurrencyDisplay name="amount" amount={formData.amountInCents} region={region} hideDollar formatAmount />
      </div>

      {/* Tax input */}
      {showTaxField && (
        <div className={Styles.taxInputSection}>
          <label>{t('tax')}</label>
          <CurrencyInput2
            name="tax"
            disabled={quickAddDisabled}
            hasError={formErrors.inputTaxAmountInCents.isInvalid}
            value={formData.inputTaxAmountInCents}
            onChange={(e) => onUpdateInputTaxAmountField(e.target.value)}
          />
        </div>
      )}

      {/* Amount includes tax slider */}
      {showTaxField && (
        <div className={Styles.isTaxInclusiveInputSection}>
          <label>{t('tax')} Inc.</label>
          <div className={Styles.toggleContainer}>
            <SlidingToggle
              id="isTaxInclusiveToggle"
              name="isTaxInclusiveToggle"
              scope="quick-expense-entry"
              disabled={quickAddDisabled}
              onChange={(id, newValue) => onUpdateField('isTaxInclusive', newValue)}
              selected={formData.isTaxInclusive}
            />
          </div>
        </div>
      )}

      {/* Billable slider */}
      <div className={Styles.billableInputSection}>
        <label>Billable</label>
        <div className={Styles.toggleContainer}>
          <SlidingToggle
            id="isBillableToggle"
            name="isBillableToggle"
            scope="quick-expense-entry"
            disabled={quickAddDisabled}
            onChange={(id, newValue) => onUpdateField('isBillable', newValue)}
            selected={formData.isBillable}
          />
        </div>
      </div>

      {/* Add button */}
      <div className={Styles.addButtonSection}>
        <Button onClick={onAddExpenseClicked} size="full-width" disabled={quickAddDisabled}>
          Add
        </Button>
      </div>

      {/* Clear button */}
      <div className={Styles.clearButtonSection}>
        <div className={Styles.clearButtonContainer}>
          <i onClick={onClearForm} className="fa fa-times fa-fw" />
        </div>
      </div>
    </div>
  );
};

QuickExpenseEntry.displayName = 'QuickExpenseEntry';

const ActivityPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.oneOf(Object.values(activityCategories)),
});

const TaskPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.oneOf(Object.values(taskCategories)),
});

const FormError = PropTypes.shape({
  isInvalid: PropTypes.bool.isRequired,
});

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

QuickExpenseEntry.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(activityCategoryLabels)),
      activities: PropTypes.arrayOf(ActivityPropType.isRequired).isRequired,
    }),
  ).isRequired,
  formData: PropTypes.shape({
    expenseDate: PropTypes.instanceOf(Date),
    staffId: PropTypes.string,
    matter: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    activity: ActivityPropType,
    task: TaskPropType,
    subject: PropTypes.string,
    subjectActivity: PropTypes.object,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priceInCents: PropTypes.number,
    amountInCents: PropTypes.number,
    inputTaxAmountInCents: PropTypes.number,
    isTaxExempt: PropTypes.bool,
    isTaxInclusive: PropTypes.bool,
    isBillable: PropTypes.bool.isRequired,
    utbmsCodesRequiredForMatter: PropTypes.bool.isRequired,
  }).isRequired,
  formErrors: PropTypes.shape({
    expenseDate: FormError.isRequired,
    staffId: FormError.isRequired,
    matter: FormError.isRequired,
    task: FormError.isRequired,
    subject: FormError.isRequired,
    quantity: FormError.isRequired,
    priceInCents: FormError.isRequired,
    inputTaxAmountInCents: FormError.isRequired,
  }).isRequired,
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create an expense
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ),
  matterSummariesDataLoading: PropTypes.bool,
  matterSummariesHasMore: PropTypes.bool,
  quickAddDisabled: PropTypes.bool.isRequired,
  region: PropTypes.string.isRequired,
  showMatterField: PropTypes.bool.isRequired,
  showTasksField: PropTypes.bool.isRequired,
  showTaxField: PropTypes.bool.isRequired,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType),
  subjects: PropTypes.arrayOf(ActivityPropType.isRequired).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(taskCategoryLabels)),
      tasks: PropTypes.arrayOf(TaskPropType).isRequired,
    }),
  ).isRequired,
  tasksFieldDisabled: PropTypes.bool.isRequired,
  onAddExpenseClicked: PropTypes.func.isRequired,
  onClearForm: PropTypes.func.isRequired,
  onFetchMatterSummaries: PropTypes.func,
  onFetchMoreMatterSummaries: PropTypes.func,
  onUpdateStaffMemberField: PropTypes.func,
  onUpdateActivityField: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateMatterField: PropTypes.func.isRequired,
  onUpdateTaskField: PropTypes.func.isRequired,
  onUpdateInputTaxAmountField: PropTypes.func.isRequired,
  setIsSubjectOverridable: PropTypes.func.isRequired,
};

QuickExpenseEntry.defaultProps = {
  matterSummaries: undefined,
  matterSummariesDataLoading: undefined,
  matterSummariesHasMore: undefined,
  staffMemberOptions: undefined,
  onFetchMatterSummaries: undefined,
  onFetchMoreMatterSummaries: undefined,
  onUpdateStaffMemberField: undefined,
};
