import React from 'react';
import { Button, Modal, useTranslation } from '@sb-itops/react';
import classNames from 'classnames';
import { Person } from 'types';
import Styles from './AddTaskModal.module.scss';
import { AddTask, IAddTaskProps } from './AddTask';

export enum AddTaskModalMode {
  create,
  edit,
}

export interface IAddTaskModalProps extends IAddTaskProps {
  mode: AddTaskModalMode;
  onClose: () => void;
  onSubmit: () => void;
  onDelete: () => void;
  onSubmitSaveAndNew: () => void;
  completer?: Person;
  isRemoved?: boolean;
}

const getFormattedDate = (date, t) => t('date', { date });

export const AddTaskModal = (props: IAddTaskModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      className={Styles.addTaskModal}
      title={props.mode === AddTaskModalMode.edit ? 'Edit Task' : 'Add Task'}
      body={
        <>
          <AddTask {...props} />
          {props.mode === AddTaskModalMode.edit && (
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={classNames(Styles.link, props.loading && Styles.disable)} onClick={props.onDelete}>
                {props.isRemoved ? 'Restore' : 'Delete'}
              </a>
            </div>
          )}
        </>
      }
      footer={
        <div className={Styles.footer}>
          <div className={Styles.saveButtons}>
            <div className="row">
              <div className="col-lg-4">
                <Button size="full-width" onClick={props.onSubmit} disabled={props.loading} locked={props.loading}>
                  Save
                </Button>
              </div>
              {props.mode === AddTaskModalMode.create && (
                <div className="col-lg-4">
                  <Button
                    size="full-width"
                    onClick={props.onSubmitSaveAndNew}
                    disabled={props.loading}
                    locked={props.loading}
                    type="secondary"
                  >
                    Save & New
                  </Button>
                </div>
              )}
            </div>
          </div>
          {props.mode === AddTaskModalMode.edit && (
            <div className={Styles.disclaimer}>
              {!!props.fields?.creationTimeStamp?.value && (
                <div>Created on: {getFormattedDate(props.fields?.creationTimeStamp?.value, t)}</div>
              )}
              {!!props.fields?.completionDate?.value && (
                <div>Completed Date: {getFormattedDate(props.fields?.completionDate?.value, t)}</div>
              )}
              {!!props.completer && <div>Completed By: {props.completer.initials}</div>}
            </div>
          )}
        </div>
      }
      onModalClose={props.onClose}
    />
  );
};
