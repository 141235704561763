import React, { useState, useEffect } from 'react';
import { DateRangePicker, SlidingToggle } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
// import { featureActive } from '@sb-itops/feature';
import { MatterTypeahead } from 'web/react-redux/components/matter-typeahead';
import Styles from '../ReportFilter.module.scss';

const LedgerExportFilter = ({
  onSelectPeriod,
  onEndSelect,
  onStartSelect,
  onToggleOption,
  onMatterSelected,
  onContactSelected,
  onShowInvoicesFor,
  filters,
  periodOptions,
  contacts,
}) => {
  const {
    periodSelected,
    startDate,
    endDate,
    includeFirmHeader,
    includeFirmLogo,
    includeFirmName,
    showInvoicesFor,
    errors,
    isMatterContactBalanceType,
    matter,
    contact,
    // runByEnteredDate,
  } = filters || {};

  const [matterFocus, setMatterFocus] = useState(false);
  const [contactFocus, setContactFocus] = useState(false);

  useEffect(() => {
    setMatterFocus(showInvoicesFor === 'matter');
    setContactFocus(showInvoicesFor === 'contact');
  }, [showInvoicesFor]);

  if (!filters) {
    return null;
  }

  const contactOptions = contacts.map(({ display: label, id: value }) => ({ value, label }));

  const toggleOption = (e) => {
    e.preventDefault();
    onToggleOption(e.target.dataset.name);
  };

  return (
    <div className={Styles.filters}>
      <div className={Styles.period}>
        <label>Report period</label>
        <DateRangePicker
          onSelect={onSelectPeriod}
          onEndSelect={onEndSelect}
          onStartSelect={onStartSelect}
          options={periodOptions}
          selected={periodSelected}
          endDate={endDate}
          endHasError={errors.endDate}
          startDate={startDate}
          startHasError={errors.startDate}
        />
      </div>
      <div className={Styles.separator} />
      {/* {hasFacet(facets.transactionsByEnteredDate) && (
        <div className={Styles.runByEnteredDateFilter}>
          <span data-name="runByEnteredDate" onClick={toggleOption} className={Styles.option}>
            Filter by Entered Date
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="runByEnteredDate" name="runByEnteredDate" selected={runByEnteredDate} />
            </div>
          </span>
          <div className={Styles.separator} />
        </div>
      )} */}
      <div className={Styles.printOptions}>
        <label>Show for</label>
        <div className={Styles.options}>
          <input
            type="radio"
            onChange={() => onShowInvoicesFor('matter')}
            checked={showInvoicesFor === 'matter'}
            className={Styles.radioOption}
          />
          <MatterTypeahead
            disabled={showInvoicesFor !== 'matter'}
            onValueChange={onMatterSelected}
            className={Styles.typeahead}
            onClear={onMatterSelected}
            hasError={errors.matter}
            initialId={matter ? matter.id : undefined}
            focus={matterFocus}
            filter="all"
          />
          {isMatterContactBalanceType && (
            <>
              <input
                type="radio"
                onChange={() => onShowInvoicesFor('contact')}
                checked={showInvoicesFor === 'contact'}
                className={Styles.radioOption}
              />
              <StatelessSelect
                disabled={showInvoicesFor !== 'contact'}
                options={contactOptions}
                onChange={onContactSelected}
                placeholder="Select a contact ..."
                isClearable
                className={Styles.typeahead}
                clearValue={onContactSelected}
                hasError={errors.contact}
                selectedOption={contact ? contactOptions.find((c) => c.value === contact.id) : undefined}
                focus={contactFocus}
              />
            </>
          )}
          <input
            type="radio"
            onChange={() => onShowInvoicesFor('allMatters')}
            checked={showInvoicesFor === 'allMatters'}
            className={Styles.radioOption}
          />
          <span className={Styles.option} onClick={() => onShowInvoicesFor('allMatters')}>
            All matters
          </span>
        </div>
      </div>
      <div className={Styles.separator} />
      <div className={Styles.printOptions}>
        <label>Print view</label>
        <div className={Styles.options}>
          <span data-name="includeFirmHeader" onClick={toggleOption} className={Styles.option}>
            Include firm header
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmHeader" name="includeFirmHeader" selected={includeFirmHeader} />
            </div>
          </span>
          <span data-name="includeFirmLogo" onClick={toggleOption} className={Styles.option}>
            Include firm logo
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmLogo" name="includeFirmLogo" selected={includeFirmLogo} />
            </div>
          </span>
          <span data-name="includeFirmName" onClick={toggleOption} className={Styles.option}>
            Include firm name
            <div className={Styles.toggleContainer}>
              <SlidingToggle scope="includeFirmName" name="includeFirmName" selected={includeFirmName} />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

LedgerExportFilter.displayName = 'LedgerExportFilter';

export default LedgerExportFilter;
