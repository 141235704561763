import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { parseQueryString } from '@sb-itops/region';
import { withOnLoad } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import composeHooks from '@sb-itops/react-hooks-compose';
import { TasksTab } from 'web/components';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import uuid from '@sb-itops/uuid';
import { envType, getFrontendEnv } from '@sb-itops/environment-config';
import { ProductivityFirmTasks } from 'web/graphql/queries';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as taskFiltersFeature from 'web/redux/features/task-filters';
import { useSubscribedQuery } from 'web/hooks';
import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import { sortByOrder } from '@sb-itops/nodash';
import { checkMatterPermissionsAndDisplayErrorPopupP } from 'web/services/matter-permissions';
import { useMetric } from 'web/services/metrics';
import { withApolloClient } from '../react-redux/hocs/withApolloClient';

// Performance testing - adding ability to customise the fetch limits

// NOTE: as we use a modulo to determine if we should fetch subsequent pages,
const queryString = getFrontendEnv() !== envType.PRODUCTION && parseQueryString(window.location.search);
const FETCH_LIMIT = (queryString?.INITIALFETCHLIMIT && parseInt(queryString.INITIALFETCHLIMIT, 10)) || 150;

const taskViewMapping = {
  ALL: 'allCount',
  RECENTLY_COMPLETED: 'recentlyCompletedCount',
  DUE_TODAY: 'dueTodayCount',
  DUE_THIS_WEEK: 'dueThisWeekCount',
  OVERDUE: 'overdueCount',
  DUE_LATER: 'dueLaterCount',
};

const hooks = ({ scope, matterId, onClickLink, allowMatterSwitching }) => ({
  useGraphQL: () => {
    const { selectors, actions } = useScopedFeature(taskFiltersFeature, scope);
    const taskView = useSelector(selectors.getTaskView);
    const showDeleted = useSelector(selectors.getShowDeleted);
    const assigneeIds = useSelector(selectors.getAssigneeIds);
    const categoryIds = useSelector(selectors.getCategoryIds);
    const sortBy = useSelector(selectors.getSortBy);
    const sortDirection = useSelector(selectors.getSortDirection);

    const dispatch = useDispatch();

    const { selectedItem: currentTaskPage, setSelectedItem: setPageNumber } = useSingleItemSelection({
      scope,
      initialSelection: 0,
    });

    const onTaskListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const taskQueryResult = useSubscribedQuery(
      ProductivityFirmTasks,
      {
        variables: {
          taskFilter: {
            matterId,
            assigneeIds,
            categoryIds,
            showDeleted,
            view: taskView,
            sortBy,
            sortDirection: sortDirection.toUpperCase(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
          },
          viewFilter: {
            matterId,
            assigneeIds,
            showDeleted,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
          },
          offset: currentTaskPage * FETCH_LIMIT,
          limit: FETCH_LIMIT,
        },
      },
      {
        notificationIds: ['TaskingNotifications'],
        onOpdateQuery: ({ queryData, queryVariables, entityOpdateEvent }) => {
          const { eventType, typeName, optimisticEntity } = entityOpdateEvent;
          const isRemovedEntity = `${eventType}-${typeName}` === 'ENTITY_REMOVED-Task';

          // If editing or deleting we need to filter the item from view if any key variables changed, but if
          // adding we can simply leave the view unchanged
          const isNewEntity = `${eventType}-${typeName}` === 'ENTITY_ADDED-Task';
          const onBlankReturn = !isNewEntity
            ? {
                ...queryData,
                taskList: {
                  ...queryData.taskList,
                  results: queryData.taskList.results.filter((item) => item.id !== optimisticEntity.id),
                },
              }
            : undefined;

          if (optimisticEntity.isRemoved && !queryVariables.taskFilter.showDeleted) {
            return onBlankReturn;
          }

          if (isRemovedEntity) {
            return {
              ...queryData,
              taskList: {
                ...queryData.taskList,
                results: queryData.taskList.results.map((item) =>
                  item.id === optimisticEntity.id ? { ...item, isRemoved: optimisticEntity.isRemoved } : item,
                ),
              },
            };
          }

          // Ignore optimistic entities which do not fit within the current filter set.
          if (queryVariables.taskFilter.matterId && queryVariables.taskFilter.matterId !== !optimisticEntity.matterId) {
            return onBlankReturn;
          }

          switch (queryVariables.taskFilter.view) {
            case 'DUE_TODAY': {
              if (optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              if (!moment(optimisticEntity.dueDate).startOf('day').isSame(moment().startOf('day'))) {
                return onBlankReturn;
              }
              break;
            }
            case 'DUE_THIS_WEEK': {
              if (optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              if (!moment(optimisticEntity.dueDate).startOf('week').isSame(moment().startOf('week'))) {
                return onBlankReturn;
              }
              break;
            }
            case 'OVERDUE': {
              if (optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              if (!moment(optimisticEntity.dueDate).endOf('day').isBefore(moment())) {
                return onBlankReturn;
              }
              break;
            }
            case 'DUE_LATER': {
              if (optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              if (!moment(optimisticEntity.dueDate).endOf('day').isBefore(moment().add(7, 'days'))) {
                return onBlankReturn;
              }
              break;
            }
            case 'ALL_COMPLETED': {
              if (!optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              break;
            }
            case 'RECENTLY_COMPLETED': {
              if (!optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              if (moment(optimisticEntity.completionDate).isBefore(moment().subtract(30, 'days'))) {
                return onBlankReturn;
              }
              break;
            }
            case 'ALL': {
              if (optimisticEntity.isCompleted) {
                return onBlankReturn;
              }
              break;
            }
            default: {
              break;
            }
          }

          if (queryVariables.taskFilter.matterId && queryVariables.taskFilter.matterId !== !optimisticEntity.matterId) {
            return onBlankReturn;
          }

          // Ignore optimistic entities which do not sit within the currently viewed page of data.
          const updatedListItems = isNewEntity
            ? [optimisticEntity, ...queryData.taskList.results]
            : [optimisticEntity, ...queryData.taskList.results.filter((item) => item.id !== optimisticEntity.id)];
          const sortedListItems = sortByOrder(
            updatedListItems,
            [sortBy],
            [sortDirection],
            false /* case insensitive */,
          );

          if (
            isNewEntity &&
            sortedListItems.length === FETCH_LIMIT &&
            (sortedListItems[0] === optimisticEntity ||
              sortedListItems[sortedListItems.length - 1] === optimisticEntity)
          ) {
            return onBlankReturn;
          }

          // Return the new opdated data for the query.
          return {
            ...queryData,
            taskList: {
              ...queryData.taskList,
              results: sortedListItems,
            },
          };
        },
      },
    );

    if (taskQueryResult.error) {
      throw new Error(taskQueryResult.error);
    }

    const { data: taskData } = taskQueryResult;
    const taskList = taskData?.taskList?.results || [];
    const taskCount = taskData?.taskViewCounts?.[taskViewMapping[taskView]] || 0;
    const totalNumberOfTaskPages = Math.ceil(taskCount / FETCH_LIMIT);

    const taskViewCounts = taskData?.taskViewCounts || {};

    // Only the tasks cache is in graphQL, we will use existing caches to map the other data until they are all converted
    const tasks = taskList.reduce((prev, task) => {
      const assignees = (task.assigneeIds || []).map((staffId) => getStaffByPersonId(staffId)?.initials || '');
      const lastAssignedBy = getStaffByPersonId(task.lastAssignedById)?.initials || '';
      prev.push({
        ...task,
        assignees,
        lastAssignedBy,
      });
      return prev;
    }, []);

    return {
      sortBy,
      sortDirection,
      setSort: (payload) => dispatch(actions.setSort(payload)),
      view: taskView,
      totalNumberOfTaskPages,
      currentTaskPage,
      onTaskListPageChange,
      loading: taskQueryResult.loading,
      tasks,
      taskViewCounts,
      allowMatterSwitching,
      showMattersInList: !matterId,
      onClickLink,
    };
  },
  useDispatch: () => {
    useMetric('NavMatterTasks');
    const [showCreateEditModal, setShowCreateEditModal] = useState({
      show: false,
      task: {},
    });

    const [showAddPhoneMessageModal, setShowAddPhoneMessageModal] = useState({
      show: false,
      task: {},
    });

    const onCloseAddPhoneMessageModal = () => {
      setShowAddPhoneMessageModal({ show: false, task: {} });
    };

    const onCloseCreateEditModal = () => {
      setShowCreateEditModal({ show: false, task: {} });
    };

    return {
      showCreateEditModal,
      showAddPhoneMessageModal,
      onCloseAddPhoneMessageModal,
      onOpenCreateTask: () => setShowCreateEditModal({ show: true, task: {} }),
      onOpenAddPhoneMessage: () => setShowAddPhoneMessageModal({ show: true, task: {} }),
      onOpenEditTask: async ({ task }) => {
        const allowed = await checkMatterPermissionsAndDisplayErrorPopupP(task && task.matterId, {
          group: 'matter-permissions-tasks',
        });
        if (allowed) {
          setShowCreateEditModal({
            show: true,
            task,
            completer: task.completerId && getStaffByPersonId(task.completerId),
          });
        }
      },
      onCloseCreateEditModal,
      onSaveTask: async (task, isNewEntity) => {
        const allowed = await checkMatterPermissionsAndDisplayErrorPopupP(task && task.matterId, {
          group: 'matter-permissions-tasks',
        });
        if (allowed) {
          const marshalledData = {
            id: task.id,
            versionId: uuid(),
            matterId: task.matterId,
            subject: task.subject,
            note: task.note,
            isCompleted: task.isCompleted,
            isRemoved: false,
            assigneeIds: task.assigneeIds,
            dueDate: task.dueDate,
            categories: task.categories || [],
            subTaskIds: [],
            creationTimeStamp: task.creationTimeStamp,
            isNewEntity,
            lastAssignedById: task.lastAssignedById || '',
          };

          await dispatchCommand({ type: 'Tasking.ManageTasks.Messages.SaveTasks2', message: marshalledData });
        }
      },
    };
  },
});

export const TasksTabContainer = withApolloClient(withReduxProvider(composeHooks(hooks)(withOnLoad(TasksTab))));

TasksTabContainer.displayName = 'TasksTabContainer';

TasksTabContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  matterId: PropTypes.string,
  onClickLink: PropTypes.func,
  allowMatterSwitching: PropTypes.bool,
};

TasksTabContainer.defaultProps = {
  allowMatterSwitching: true,
  matterId: undefined,
  onClickLink: () => {},
};
