import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Modal, buttonTypes } from '@sb-itops/react';
import Styles from './TrialExpiryIndicator.module.scss';

export const TrialExpiryIndicator = (props) => {
  const { isSubscribed, tooltipText, showContactFirmOwnerModal, trialDayCurrent, trialDaysTotal, onUpgradeClick } =
    props;

  if (!trialDayCurrent || !trialDaysTotal) {
    return null;
  }

  return (
    <div className={Styles.trialExpiryContainer} title={tooltipText}>
      {showContactFirmOwnerModal && contactFirmOwnerModal(props)}
      <div className={classnames(!isSubscribed && trialDayCurrent >= trialDaysTotal && Styles.trialExpiryWarning)}>
        Trial Day {trialDayCurrent} of {trialDaysTotal}
      </div>
      <div>
        {isSubscribed ? (
          <div className={Styles.subscribed}>
            <i className={classnames('icon-check-circle-2', Styles.icon)} title={tooltipText} />
            Subscribed
          </div>
        ) : (
          <button className={Styles.button} onClick={onUpgradeClick} type="button">
            Upgrade Now
          </button>
        )}
      </div>
    </div>
  );
};

function contactFirmOwnerModal(props) {
  const { showContactFirmOwnerModal, onCloseContactFirmOwnerModal } = props;

  return (
    <Modal
      isVisible={showContactFirmOwnerModal}
      title="Upgrading Your Subscription"
      body={
        <div>
          <p>
            To upgrade your subscription, please contact your firm owner. This is the user who initially signed up for
            this trial.
          </p>
        </div>
      }
      footer={
        <div className={Styles.buttonPanel}>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.secondary}
            onClick={() => onCloseContactFirmOwnerModal()}
          >
            Ok
          </Button>
        </div>
      }
      onModalClose={onCloseContactFirmOwnerModal}
    />
  );
}

TrialExpiryIndicator.displayName = 'TrialExpiryIndicator';

TrialExpiryIndicator.propTypes = {
  isSubscribed: PropTypes.bool,
  tooltipText: PropTypes.string,
  trialDayCurrent: PropTypes.number,
  trialDaysTotal: PropTypes.number,
  onUpgradeClick: PropTypes.func,
  showContactFirmOwnerModal: PropTypes.bool.isRequired,
  onCloseContactFirmOwnerModal: PropTypes.func.isRequired,
};

TrialExpiryIndicator.defaultProps = {
  isSubscribed: false,
  tooltipText: undefined,
  trialDayCurrent: undefined,
  trialDaysTotal: undefined,
  onUpgradeClick: undefined,
};
