import { getById as getOperatingChequeById } from '@sb-billing/redux/operating-cheques';
angular.module('sb.billing.webapp').component('sbDataOperatingChequeById', {
  require: { composer: '^sbCompose' },
  bindings: { chequeId: '<?', composeDataKey: '@?' },
  controller: function ($scope, sbLoggerService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataOperatingChequeById');
    const entityDataKey = ctrl.composeDataKey || 'operatingCheque';

    ctrl.$onChanges = () => {
      updateOperatingCheque();
    };

    $scope.$on('smokeball-data-update-sbOperatingChequesService', () => {
      updateOperatingCheque();
    });

    function updateOperatingCheque () {
      const operatingCheque = getOperatingChequeById(ctrl.chequeId);

      log.info('operatingCheque: ', operatingCheque);

      if (operatingCheque) {
        ctrl.composer.setComposeData({ data: operatingCheque, dataType: entityDataKey }, entityDataKey);
      }
    }
  }
});
