import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import InvoiceZeroBalanceModal from './InvoiceZeroBalanceModal';

export const InvoiceZeroBalanceModalContainer = withReduxProvider(InvoiceZeroBalanceModal);

InvoiceZeroBalanceModalContainer.displayName = 'InvoiceZeroBalanceModalContainer';

InvoiceZeroBalanceModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  invoiceCount: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InvoiceZeroBalanceModalContainer.defaultProps = {};
