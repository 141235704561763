import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getAppEnv, envType } from '@sb-itops/app-env';
import {
  error as displayErrorToUser,
  success as displaySuccessToUser,
  warn as displayWarningToUser,
} from '@sb-itops/message-display';
import { Button } from '@sb-itops/react';
import { fetchPostP } from '@sb-itops/redux/fetch';

import { selectors } from 'web/redux/route/billing-support-debug';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

export const DebugSetPaymentLinkExpiryTo1DayButtonContainer = withReduxProvider(({ invoiceIds }) => {
  const showDebug = useSelector(selectors.getShowDebug);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const expirationTime = 60 * 60 * 24; // 1 day in seconds

  const onClick = async () => {
    try {
      setIsProcessingRequest(true);
      const response = await fetchPostP({
        path: `/billing/invoices/set-payment-links-expiration-date/:accountId/`,
        fetchOptions: {
          body: JSON.stringify({ invoiceIds: invoiceIds.length > 0 ? invoiceIds : undefined, expirationTime }),
        },
      });
      displaySuccessToUser('Successfully sent request for expiring invoice in 1 day.');
      displayWarningToUser(
        `set payment link expiration date for invoice ids: ${JSON.stringify(response.body?.invoiceIdsUpdated)}`,
      );
    } catch (error) {
      displayErrorToUser('Failed to set invoice payment link expiration date.');
    } finally {
      setIsProcessingRequest(false);
    }
  };

  // this debug functionality is not available in production environment
  if (!showDebug || getAppEnv() === envType.PRODUCTION) {
    return null;
  }

  return (
    <Button onClick={onClick} disabled={isProcessingRequest || !invoiceIds?.length} locked={isProcessingRequest}>
      {invoiceIds && invoiceIds.length > 0
        ? 'Debug: Set Payment Link Expiry In 1 Day'
        : 'Debug: Set Payment Link Expiry In 1 Day (No Invoice Ids Provided)'}
    </Button>
  );
});

DebugSetPaymentLinkExpiryTo1DayButtonContainer.displayName = 'DebugSetPaymentLinkExpiryTo1DayButtonContainer';

DebugSetPaymentLinkExpiryTo1DayButtonContainer.propTypes = {
  invoiceIds: PropTypes.arrayOf(PropTypes.string),
};

DebugSetPaymentLinkExpiryTo1DayButtonContainer.defaultProps = {
  invoiceIds: [],
};
