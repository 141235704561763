import { Spinner } from '@sb-itops/react';
import React from 'react';
import classNames from 'classnames';
import { WidgetTitle, getTextSize } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './BillableHoursWidget.module.scss';

export const BillableHoursWidget = ({
  isEditing,
  // staff,
  matterId,
  billableHours,
  nonBillableHours,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
}) => (
  <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
    <WidgetTitle
      onChange={onChange}
      onRemove={onRemove}
      settings={settings}
      isEditing={isEditing}
      onClick={() => {
        if (matterId) {
          onClickLink({ id: matterId, type: 'matterFees' });
        } else {
          onClickLink({ type: 'timeAndFees' });
        }
      }}
    />
    {loading ? (
      <div className={WidgetStyles.loading}>
        <Spinner small />
      </div>
    ) : (
      <div className={Styles.content}>
        {/* <div className={Styles.header}>
          {staff?.name}
          <i className="icon icon-information" title="Does not include pending fees" />
        </div> */}
        <div className={`${Styles.hours} ${getTextSize(settings.size)}`}>
          <div className={Styles.billable}>
            <h1 className={Styles.hOne}>{billableHours}</h1>
            BILLABLE HOURS
          </div>
          <div className={Styles.nonBillable}>
            <h1 className={Styles.hOne}>{nonBillableHours}</h1>
            NON-BILLABLE HOURS
          </div>
        </div>
      </div>
    )}
  </div>
);
