import reducerRegistry from './reducer-registry';
import store from './store';

/**
 * Factory to create boilerplate functions and data
 *
 * @param { domain, name }
 *  domain    The domain, e.g. cm, mm, billing
 *  name      The name used for the reducer, e.g. contacts. Relates to the data stored
 *  immutable boolean, default true
 *  reducers: [{ [action_name] : reducer }]
 */
export default function utils({ domain, name, reduxPath, immutable = true, reducers = [], autoClearOnLogout = false }) {
  if (!domain && !reduxPath) {
    throw new Error(`itops/utils-factory missing domain`);
  }

  if (!name && !reduxPath) {
    throw new Error(`itops/utils-factory missing reducer name`);
  }

  const reducerName = reduxPath || `${domain}/${name}`;

  const registerReducer = (reducer) =>
    reducerRegistry.register(`${reducerName}`, reducer, immutable, autoClearOnLogout);

  if (reducers && reducers.length > 0) {
    const reducersMap = reducers.reduce((acc, reducer) => {
      Object.entries(reducer).forEach(([actionName, actionReducer]) => {
        if (actionName && actionReducer) {
          acc[actionName] = actionReducer;
        }
      });
      return acc;
    }, {});

    registerReducer((state = {}, action = {}) => {
      const reducer = reducersMap[action.type];
      return reducer ? reducer(state, action) : state;
    });
  }

  return {
    createActionName: (type) => `${reducerName}/${type}`,
    registerReducer,
    getState: () => store.getState()[reducerName],
  };
}
