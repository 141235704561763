import { gql } from '@apollo/client';

const query = gql`
  query UserTwoFactorStatus($id: ID!) {
    userTwoFactorStatus(id: $id) {
      id # userId
      # accountId
      twoFactorEnabled
      # lastUpdatedUtc
    }
  }
`;

const notificationIds = ['Security.UserTwoFactorStatusChanged'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const UserTwoFactorStatus = {
  query,
  notificationIds,
};
