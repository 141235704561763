import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getInvoiceCorrespondenceHistoryGroupedByDebtor } from '@sb-billing/redux/correspondence-history';
import { determineSendStatus } from '@sb-billing/business-logic/correspondence-history';
import { InvoiceCorrespondenceSendStatus } from './InvoiceCorrespondenceSendStatus';

const hooks = ({ invoiceId, contactIds, onResendClicked, invoiceStatus }) => ({
  useSendStatusData: () => {
    const correspondenceHistoryByDebtor = getInvoiceCorrespondenceHistoryGroupedByDebtor(invoiceId, contactIds) || {};
    const debtorsCorrespondenceHistory = Object.values(correspondenceHistoryByDebtor);
    const sendStatus = determineSendStatus(debtorsCorrespondenceHistory);

    return {
      invoiceId,
      sendStatus,
      allowResend: !['DRAFT', 'VOID', 'DELETED'].includes(invoiceStatus),
      onResendClicked: ({ contactId, sentVia }) => onResendClicked({ invoiceId, contactId, sentVia }),
    };
  },
});

/**
 * @deprecated This container has been deprecated, for LOD use the InvoiceCorrespondenceSendStatus directly
 * Remove after BB-13599, BB-14220, BB-14222 and BB-14223 are released
 */
export const InvoiceCorrespondenceSendStatusContainer = withReduxProvider(
  composeHooks(hooks)(InvoiceCorrespondenceSendStatus),
);

InvoiceCorrespondenceSendStatusContainer.displayName = 'InvoiceCorrespondenceSendStatusContainer';

InvoiceCorrespondenceSendStatusContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  contactIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onIconClicked: PropTypes.func,
  onResendClicked: PropTypes.func,
  onSendViaMenuClicked: PropTypes.func,
};

InvoiceCorrespondenceSendStatusContainer.defaultProps = {
  onIconClicked: () => {},
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};

export default InvoiceCorrespondenceSendStatusContainer;
