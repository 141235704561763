'use strict';

const { frequencyTypes, installmentFrequencies } = require('../entities/constants');

/**
 * The server stores the installmentFrequency as type + interval when the UI only has an enum.
 * This will transform from enum to type+interval.
 * @param {'Weekly'|'BiWeekly'|'Monthly'|'BiMonthly'} frequency - the frequency.
 * @returns {{type: 0|1, interval: 1|2}}. the type and interval
 * @throws if frequency is not one of 'weekly'|'bi-weekly'|'monthly'|'bi-monthly'.
 */
const getInstallmentFrequencyTypeAndInterval = (frequency) => {
  switch (frequency) {
    case installmentFrequencies.WEEKLY: {
      return {
        type: frequencyTypes.WEEKLY,
        interval: 1,
      };
    }
    case installmentFrequencies.BI_WEEKLY: {
      return {
        type: frequencyTypes.WEEKLY,
        interval: 2,
      };
    }
    case installmentFrequencies.MONTHLY: {
      return {
        type: frequencyTypes.MONTHLY,
        interval: 1,
      };
    }
    case installmentFrequencies.BI_MONTHLY: {
      return {
        type: frequencyTypes.MONTHLY,
        interval: 2,
      };
    }
    default: {
      throw new Error(
        `Invalid frequency to get type and interval ${frequency}, you need one of ${Object.values(
          installmentFrequencies,
        ).join(',')}`,
      );
    }
  }
};

module.exports = {
  getInstallmentFrequencyTypeAndInterval,
};
