angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('home.billing.trust-to-office-transfer-receipt', {
      url: '/trust-to-office-transfer-receipt/:paymentId',
      template: `<sb-trust-to-office-transfer-receipt-view style="width:100%"></sb-trust-to-office-transfer-receipt-view>`,
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts', 'transfer', 'receipt'],
        tab: { type: 'trust-to-office-transfer-receipt' }
      }
    });

});
