// [fill, stroke]
// const defaultColors = [
//   ['#cc8f59', '#a67143'],
//   ['#cdb48e', '#a18964'],
//   ['#fba45f', '#db8642'],
//   ['#ffdc73', '#e3a33d'],
//   ['#5bd380', '#3aaf5f'],
//   ['#a0cca8', '#699370'],
//   ['#4ecdbf', '#3da69b'],
//   ['#76d5ff', '#31a4d4'],
//   ['#30c2ed', '#3fa1bd'],
//   ['#44a4ef', '#3b93d7'],
//   ['#83a7f1', '#567ecf'],
//   ['#cda2ce', '#b46eb5'],
//   ['#cd78bd', '#b05fa1'],
//   ['#e39696', '#cf5b5b'],
//   ['#e76b6b', '#d74848'],
//   ['#92a9a7', '#7d8592'],
//   ['#797d85', '#64666a'],
//   ['#a0a9b7', '#939ba7'],
// ];

/* Randomized array */
const randomizedColors = [
  ['#cd78bd', '#b05fa1'],
  ['#cda2ce', '#b46eb5'],
  ['#e39696', '#cf5b5b'],
  ['#83a7f1', '#567ecf'],
  ['#797d85', '#64666a'],
  ['#ffdc73', '#e3a33d'],
  ['#cc8f59', '#a67143'],
  ['#92a9a7', '#7d8592'],
  ['#30c2ed', '#3fa1bd'],
  ['#44a4ef', '#3b93d7'],
  ['#a0a9b7', '#939ba7'],
  ['#4ecdbf', '#3da69b'],
  ['#e76b6b', '#d74848'],
  ['#5bd380', '#3aaf5f'],
  ['#fba45f', '#db8642'],
  ['#76d5ff', '#31a4d4'],
  ['#cdb48e', '#a18964'],
  ['#a0cca8', '#699370'],
];

export const getUniqueColors = (list: { [key: string]: any }) => {
  let index = 0;
  return list.map((i: { [key: string]: any }) => {
    if (i.color || i.colorFill) {
      return i;
    }
    index += 1;
    if (index >= randomizedColors.length) {
      index = 0;
    }
    return { ...i, colorFill: randomizedColors[index][0], colorStroke: randomizedColors[index][1] };
  });
};
