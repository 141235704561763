import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DropDownList } from '@sb-itops/react/drop-down-list';

const InvoiceTemplateDropdown = memo(({ onValueChange, options, templateId, disabled }) => {
  const dropdownOptions = useMemo(
    () =>
      options
        .filter(({ isDeleted }) => !isDeleted)
        .sort((a, b) => {
          if (a.isDefault) {
            return -1;
          }
          if (b.isDefault) {
            return 1;
          }

          return (a.name || '').localeCompare(b.name);
        })
        .map(({ name, id, isDefault }) => ({
          label: name && name.charAt(0).toUpperCase() + name.slice(1),
          name,
          value: id,
          isDefault,
        })),
    [options],
  );

  const defaultValue = useMemo(() => {
    // Find the selected template in active templates first
    const defaultTemplate = dropdownOptions.find(({ value }) => value === templateId);
    if (defaultTemplate) {
      return defaultTemplate;
    }

    // Find if the selected template is deleted
    const deletedTemplate = options.find(({ id }) => id === templateId);
    if (deletedTemplate) {
      return {
        label:
          deletedTemplate.name &&
          `${deletedTemplate.name.charAt(0).toUpperCase() + deletedTemplate.name.slice(1)} (Deleted)`,
        name: deletedTemplate.name,
        value: deletedTemplate.id,
      };
    }

    // If there is no selected template, set template to firm default
    return dropdownOptions.find(({ isDefault }) => !!isDefault);
  }, [dropdownOptions, options, templateId]);

  return (
    <DropDownList
      disabled={disabled}
      onValueChange={onValueChange}
      stateless
      options={dropdownOptions}
      defaultValue={defaultValue}
    />
  );
});

InvoiceTemplateDropdown.displayName = 'InvoiceTemplateDropdown';

InvoiceTemplateDropdown.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  templateId: PropTypes.string,
  disabled: PropTypes.bool,
};

InvoiceTemplateDropdown.defaultProps = {
  templateId: undefined,
  disabled: undefined,
};

export default InvoiceTemplateDropdown;
