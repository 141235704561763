import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@sb-itops/react';
import { Info } from 'web/components/icons';
import { isNewTheme } from 'web/services/theme';
import Style from './PageBannerXero.module.scss';

export const PageBannerXero = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div className={Style.pageBanner}>
      <div className={Style.bannerContainer}>
        {isNewTheme() ? (
          <Info className={Style.infoIcon} />
        ) : (
          <Icon className={classnames('info-icon', Style.infoIcon)} type="information" />
        )}
        <div className={Style.bannerText}>{message}</div>
      </div>
    </div>
  );
};

PageBannerXero.propTypes = {
  message: PropTypes.any,
};

PageBannerXero.defaultProps = {
  message: null,
};

PageBannerXero.displayName = 'PageBannerXero';
