/* eslint-disable import/no-cycle */
import { store } from '@sb-itops/redux';
import moment from 'moment';
import uuid from '@sb-itops/uuid';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { buildReversalTransactionOpdates, opdateCache, rollbackOpdateCache } from './reversal-transaction-opdates';

const buildReversalDepositTransaction = (transaction) => {
  const { transactionIds, accountType, bankAccountId, reason, deleteTransaction, checkMatterBalance, allowOverdraw } =
    transaction;

  const reversalDepositTransaction = {
    transactions: transactionIds.map((id) => ({
      transactionIdToReverse: id,
      newTransactionId: uuid(),
      // All these are needed for the opdate only...
      deleteTransaction,
      allowOverdraw,
      checkMatterBalance,
      transactionId: id,
    })),
    accountType,
    bankAccountId,
    effectiveDate: +moment().format('YYYYMMDD'),
    reason,
    hideTransactions: !!deleteTransaction,
    checkMatterBalance: !!checkMatterBalance,
    allowOverdraw: !!allowOverdraw,
  };

  return reversalDepositTransaction;
};

/**
 * Reverse a transaction that is a deposit
 * @param {transaction like { transactionIds, bankAccountId, accountType, transactionId, reason, checkMatterBalance, deleteTransaction, allowOverdraw }} transaction
 * @returns {thunk} thunk
 */
export const reverseDeposit = (transaction) => {
  const { transactionIds } = transaction;

  if (!transactionIds) {
    throw new Error('Invalid Transaction, you need transaction ids for reversing a transaction');
  }

  const reversalDepositTransactions = buildReversalDepositTransaction(transaction);

  const opdateReversalDepositTransaction = reversalDepositTransactions.transactions.reduce((acc, tx) => {
    const reversalTransactions = buildReversalTransactionOpdates({
      originalTransaction: tx,
      reversalTransactionId: tx.newTransactionId,
    });
    acc.push(...reversalTransactions);
    return acc;
  }, []);

  // Apply to save optimistically.
  opdateCache({ optimisticEntities: opdateReversalDepositTransaction });

  const reverseDepositThunk = async () => {
    try {
      await dispatchCommand({
        type: 'Billing.Accounts.Messages.Commands.ReverseDeposit',
        message: reversalDepositTransactions,
      });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: opdateReversalDepositTransaction });
      throw err;
    }
  };
  return store.dispatch(reverseDepositThunk);
};
