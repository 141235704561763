import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { withOnLoad } from '@sb-itops/react';
import { getAppEnv, envType } from '@sb-itops/app-env';
import { StripeCreditCardFormSchema } from './StripeCreditCardFormSchema';
import { StripePaymentForm } from './StripePaymentForm';

const mapFormFieldsToToken = (formFields) => {
  const name = (formFields.billingDetails && formFields.billingDetails.name) || '';
  const address = (formFields.billingDetails && formFields.billingDetails.address) || {};

  return {
    // we need to make sure we don't pass null as Stripe API errors when null is passed
    name: name || undefined,
    address_line1: address.line1 || undefined,
    address_line2: address.line2 || undefined,
    address_city: address.city || undefined,
    address_state: address.state || undefined,
    address_zip: address.postal_code || undefined,
    address_country: address.country || undefined,
  };
};

const scope = 'stripe-payment-form';

const mapStateToProps = (state, { useSmokeballLogo, isSubmitting }) => {
  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);
  const { formInitialised, fields, formDirty, formValid } = formSelectors.getFormState(state);
  const formFieldValues = formSelectors.getFieldValues(state);

  return {
    formFields: fields,
    formFieldValues,
    isSubmitting,
    formInitialised,
    formDirty,
    formValid,
    useSmokeballLogo,
  };
};

const mapDispatchToProps = (dispatch, { onPreSubmitChange }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      const fieldValues = {
        email: '',
        billingDetails: undefined,
        creditCardAddressStatus: false,
        creditCardNumberStatus: false,
        creditCardExpiryStatus: false,
        creditCardCvcStatus: false,
        creditCardBrand: '',
      };

      dispatch(formActions.initialiseForm({ fieldValues }));

      // Send feeScheduleCurrentTsOverride to parent so we can use correct fee schedule
      const feeScheduleCurrentTsOverride = getFeeScheduleCurrentTsOverride();
      onPreSubmitChange({ feeScheduleCurrentTsOverride });

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onChange: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: StripeCreditCardFormSchema }));
    },
  };
};

const onSubmitTriggered = async ({ stripeApi, stripeElements, formFieldValues, onSubmit }) => {
  let paymentToken;
  try {
    const nonSecureFields = mapFormFieldsToToken(formFieldValues);
    const cardNumberElement = stripeElements.getElement('cardNumber');
    const stripePaymentTokenObject = await stripeApi.createToken(cardNumberElement, nonSecureFields);

    paymentToken = stripePaymentTokenObject.token;
  } catch (err) {
    // There are different reasons that tokenization can fail. A common one amongst providers is when a card number is placed
    // accidentally into an insecure field (e.g. name). To signify this type of failure, the handler of onsubmit must treat a
    // missing token as failure.
    paymentToken = undefined;
  }

  onSubmit({
    paymentToken,
    email: formFieldValues.email,
    // exposing some fields to help make success/failure message more meaningful
    cardType: paymentToken && paymentToken.card.brand,
    cardholderName: formFieldValues.billingDetails && formFieldValues.billingDetails.name,
    cardNumberTruncated: paymentToken && paymentToken.card.last4,
  });
};

const withStripeElements = (WrappedComponent) => {
  const WrappedComponentWithStripe = ({ publicKey, hostedFieldsStyle, isSubmitting, ...props }) => {
    const [loadStripePromise] = useState(loadStripe(publicKey));

    return (
      <Elements stripe={loadStripePromise} options={hostedFieldsStyle}>
        <WrappedComponent {...props} hostedFieldsStyle={hostedFieldsStyle} isSubmitting={isSubmitting} />
      </Elements>
    );
  };

  WrappedComponentWithStripe.displayName = `WrappedComponentWithStripe(StripePaymentFormContainer)`;
  return WrappedComponentWithStripe;
};

export const StripePaymentFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStripeElements(
    withOnLoad(({ triggerSubmit, formFieldValues, formValid, onReadyForSubmit, onSubmit, ...props }) => {
      const [submissionTriggered, setSubmissionTriggered] = useState(false);
      const [preSubmitFields, setPreSubmitFields] = useState({});

      const stripeApi = useStripe();
      const stripeElements = useElements();

      onReadyForSubmit(!!(stripeElements && formValid));

      // Trigger the submission.
      if (triggerSubmit && !submissionTriggered) {
        if (!stripeElements || !formValid) {
          throw new Error('Stripe credit card form submission should not be triggered when in non-ready state');
        }

        setSubmissionTriggered(true);
        onSubmitTriggered({ stripeApi, stripeElements, formFieldValues, onSubmit });
      }

      // We are ok to allow submission again.
      if (!triggerSubmit && submissionTriggered) {
        setSubmissionTriggered(false);
      }

      const onChange = (field, value) => {
        props.onChange(field, value);

        const feeScheduleCurrentTsOverride = getFeeScheduleCurrentTsOverride();

        // Notify the parent of changes prior to submit that may be useful for calculating things like indicative fees.
        //
        // Please note: onPreSubmitChange should be called with all fields used for fee calculation if we know them, not just the one changed.
        // The reason is that for example creditCardBrand update could overwritten cardRegion in parent component so we would lose this info
        // until billingDetails is updated. Instead of relying on each component to merge previous and new state to have all values,
        // we just make sure we keep track of state ourselves here.
        if (field === 'creditCardBrand') {
          const updatedPreSubmitFields = { ...preSubmitFields, [field]: value, feeScheduleCurrentTsOverride };
          props.onPreSubmitChange(updatedPreSubmitFields);
          setPreSubmitFields(updatedPreSubmitFields);
        } else if (field === 'billingDetails') {
          const address = (value && value.address) || {};
          // We don't know card's country until form is submitted and token generated. Therefore, we use billing country
          // as card country and before we execute charge, we error if billing country is different than card's country.
          const cardRegion = address.country === props.countryCode ? 'domestic' : 'international';

          const updatedPreSubmitFields = { ...preSubmitFields, cardRegion, feeScheduleCurrentTsOverride };
          props.onPreSubmitChange(updatedPreSubmitFields);
          setPreSubmitFields(updatedPreSubmitFields);
        }
      };

      return <StripePaymentForm {...props} onChange={onChange} />;
    }),
  ),
);

const getFeeScheduleCurrentTsOverride = () => {
  // We don't support featureActive in payment portal so this is using qs directly for testing
  let feeScheduleCurrentTsOverride;
  if (getAppEnv() !== envType.PRODUCTION) {
    const qs = new URLSearchParams(window.location.search);
    const tsOverride = qs.get('feeScheduleTs');
    if (tsOverride) {
      // We don't check correct date format as this is for testing only
      feeScheduleCurrentTsOverride = tsOverride;
    }
  }

  return feeScheduleCurrentTsOverride;
};

StripePaymentFormContainer.displayName = 'StripePaymentFormContainer';

StripePaymentFormContainer.propTypes = {
  // array of paymentMethods keys
  publicKey: PropTypes.string.isRequired,
  hostedFieldsStyle: PropTypes.object,
  countryCode: PropTypes.string.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  useSmokeballLogo: PropTypes.bool,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPreSubmitChange: PropTypes.func,
};

StripePaymentFormContainer.defaultProps = {
  hostedFieldsStyle: {},
  useSmokeballLogo: false,
  onPreSubmitChange: () => {},
};
