angular.module('sb.billing.webapp').component('sbAsyncOperationBar', {
  bindings: {  },
  templateUrl: 'ng-components/async-operation-bar/async-operation-bar.html',
  controller: function($scope, $state, sbAsyncOperationsService) {
    const ctrl = this;

    ctrl.toggleCollapse = toggleCollapse;
    ctrl.dismiss = dismiss;

    ctrl.$onInit = () => {
      ctrl.operations = sbAsyncOperationsService.getOperations();
      ctrl.isCollapsed = false;
    };

    function dismiss(operation) {
      sbAsyncOperationsService.archiveOperation(operation.id);
    }

    function toggleCollapse() {
      ctrl.isCollapsed = !ctrl.isCollapsed;
    }
  }
});
