import { fetchGetP } from '@sb-itops/redux/fetch';

import { store } from '@sb-itops/redux';

export const debugAutoPayment = (accountId, paymentPlanId) => {
  const debugAutoPaymentThunk = async () => {
    const path = `/billing/payment-provider/debug-auto-payment/${accountId}/${paymentPlanId}`;
    await fetchGetP({ path });
  };

  return store.dispatch(debugAutoPaymentThunk);
};
