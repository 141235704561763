import * as Yup from 'yup';

export const LawpayElectronicChequeFormSchema = Yup.object().shape({
  firstName: Yup.string().when('accountHolderType', {
    is: (accountHolderType) => accountHolderType === 'Individual',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  lastName: Yup.string().when('accountHolderType', {
    is: (accountHolderType) => accountHolderType === 'Individual',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  businessName: Yup.string().when('accountHolderType', {
    is: (accountHolderType) => accountHolderType === 'Business',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  accountHolderType: Yup.string().oneOf(['Individual', 'Business']).required(),
  accountType: Yup.string().oneOf(['Checking', 'Savings']).required(),
  addressLine1: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  postalCode: Yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/)
    .required(),
  email: Yup.string().email(),
  bankAccountNumberStatus: Yup.boolean().oneOf([true]).required(),
  routingNumberStatus: Yup.boolean().oneOf([true]).required(),
});
