'use strict';

// Any changes to these will need to be handled in monorepo/billing/business-logic/evergreen-retainer/services/interpolate-text.js
// when isForEmailSubject is true, the placeholder for html based content should not be included.
const getDepositRequestEmailMacros = ({ t, isForEmailSubject = false }) => {
  const macros = [
    // Invoice Details
    { name: '[DEBTOR_NAME]', tooltip: 'Debtor name' },
    { name: '[AMOUNT_REQUESTED]', tooltip: 'Amount requested' },
    // Matter Details
    { name: '[MATTER_AMOUNT_DUE]', tooltip: 'Amount due (matter total)' },
    { name: '[MATTER_NAME]', tooltip: 'Matter name' },
    // Request Details
    { name: '[REQUEST_REASON]', tooltip: 'Reason for the deposit request' },
    // Account details
    { name: `[${t('macros.trustBalance')}]`, tooltip: `Current ${t('trust')} balance` },
    // Firm Details
    { name: '[USER_NAME]', tooltip: 'Current user' },
    { name: '[FIRM_NAME]', tooltip: 'Firm name' },
    { name: '[PHONE_NUMBER]', tooltip: 'Phone number' },
  ];

  // Including [PAY_NOW_BUTTON] only when get macros for email body, as it's html based content
  if (!isForEmailSubject) {
    macros.push(
      ...[
        // Invoice Details
        { name: '[PAY_NOW_BUTTON]', tooltip: 'Pay now button' },
      ],
    );
  }

  return macros;
};

module.exports = { getDepositRequestEmailMacros };
