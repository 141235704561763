import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@sb-itops/react/modal';
import { Button } from '@sb-itops/react/button';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { ApplyPaymentsForm } from '../apply-payments-form';
import Styles from './InvoicePaymentAllocationModal.module.scss';
import { applyAutoPayments } from './apply-auto-payments';

const InvoicePaymentAllocationModal = memo(
  ({
    onChangeAllocations,
    trustAllocations: initialTrustAllocations,
    operatingAllocations: initialOperatingAllocations,
    creditAllocations: initialCreditAllocations,
    onModalClose,
    onContactLinkClick,
    invoiceTotals,
    preferredBankAccountTypes,
  }) => {
    const [trustAllocations, setTrustAllocations] = useState(initialTrustAllocations);
    const [operatingAllocations, setOperatingAllocations] = useState(initialOperatingAllocations);
    const [creditAllocations, setCreditAllocations] = useState(initialCreditAllocations);

    const totalAmount = trustAllocations
      .concat(operatingAllocations)
      .concat(creditAllocations)
      .reduce((acc, allocation) => acc + allocation.amount, 0);
    const hasError = invoiceTotals.total < totalAmount;

    return (
      <Modal
        onModalClose={onModalClose}
        body={
          <ApplyPaymentsForm
            hasError={hasError}
            totalAmount={totalAmount}
            trustAllocations={trustAllocations}
            operatingAllocations={operatingAllocations}
            creditAllocations={creditAllocations}
            onContactLinkClick={onContactLinkClick}
            onChangeTrustAllocations={setTrustAllocations}
            onChangeOperatingAllocations={setOperatingAllocations}
            onChangeCreditAllocations={setCreditAllocations}
            invoiceTotals={invoiceTotals}
          />
        }
        title="Apply Payments"
        footer={
          <div className={Styles.footerRow}>
            <Button
              className={Styles.saveButton}
              onClick={() => {
                onChangeAllocations(trustAllocations, operatingAllocations, creditAllocations);
                onModalClose();
              }}
              disabled={hasError}
            >
              Apply Allocations
            </Button>
            <LinkableText
              className={Styles.clearButton}
              text="Clear Values"
              onClickLink={() => {
                setTrustAllocations(clearAllocations(trustAllocations));
                setOperatingAllocations(clearAllocations(operatingAllocations));
                setCreditAllocations(clearAllocations(creditAllocations));
              }}
              asLink
            />

            <LinkableText
              className={Styles.autoAllocateButton}
              text="Apply Auto-Allocation Values"
              onClickLink={() => {
                const { trust, operating, credit } = applyAutoPayments(
                  invoiceTotals,
                  trustAllocations,
                  operatingAllocations,
                  creditAllocations,
                  preferredBankAccountTypes,
                );
                setTrustAllocations(trust);
                setOperatingAllocations(operating);
                setCreditAllocations(credit);
              }}
              asLink
            />
          </div>
        }
      />
    );
  },
);

function clearAllocations(allocations) {
  return allocations.map((allocation) => ({
    ...allocation,
    amount: 0,
  }));
}

InvoicePaymentAllocationModal.displayName = 'InvoicePaymentAllocationModal';

InvoicePaymentAllocationModal.propTypes = {
  onChangeAllocations: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  trustAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  operatingAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  creditAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  invoiceTotals: PropTypes.object,
  preferredBankAccountTypes: PropTypes.array.isRequired,
};

InvoicePaymentAllocationModal.defaultProps = {
  trustAllocations: [],
  operatingAllocations: [],
  creditAllocations: [],
  invoiceTotals: undefined,
};

export default InvoicePaymentAllocationModal;
