/**
 * Load on Demand compatible ContactMultiSelect
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select } from '@sb-itops/react';
import uuid from '@sb-itops/uuid';
import styles from './ContactMultiSelect.module.scss';

let unprotectedDragIndex = 0;

// global object workaround for trash DnD spec that doesn't allow you to identify the origin of your DnD events
const dragData = {};

const generateKeys = (quantity) => {
  const keyMap = [];

  for (let index = 0; index < quantity; index += 1) {
    keyMap[index] = uuid();
  }

  return keyMap;
};

const ContactMultiSelect = ({
  className,
  contactOptions,
  contactOptionsDataLoading,
  contactOptionsHasMore,
  disabled,
  enableReordering,
  isRequired,
  max,
  maxMenuHeight,
  menuPlacement,
  placeholder,
  preventDuplicates,
  selectedContacts,
  // callbacks and functions
  onContactsChanged,
  onFetchContactOptions,
  onFetchMoreContactOptions,
}) => {
  // Used to prevent dragover affecting other elements
  const [elementId] = useState(uuid());

  // Used to manage each Select's key prop, and keep it the same after reordering
  const keyMapRef = useRef(generateKeys(max));

  // When a contact select box is cleared, we destroy it in order to clear the
  // options list that it references as well as any internal state. The problem
  // this creates is that the input loses focus. We only want to re-focus the
  // input once to prevent focus stealing from other elements.
  const refocusIndex = useRef();
  const contactIndexToFocus = refocusIndex.current;

  const divRef = React.useRef([]);
  const selectBoxRef = React.useRef([]);
  const inputId = selectBoxRef.current[contactIndexToFocus]?.props.inputId;

  // Absolute hackfest - react-select v3 does not provide access to `.focus()`
  // on the Input component, nor does it allow for the ref to be passed in
  // even with a custom Input component
  useEffect(() => {
    if (
      (contactIndexToFocus || contactIndexToFocus === 0) &&
      inputId === `contact-select-${keyMapRef.current[contactIndexToFocus]}`
    ) {
      // Couldn't set the focus using useLayoutEffect, so we need to make sure
      // it runs after the element has been created
      setTimeout(() => {
        document.querySelector(`#${inputId}`).focus();
      }, 0);
      refocusIndex.current = undefined;
    }
  }, [contactIndexToFocus, inputId]);

  const defaultOptions = selectedContacts?.length
    ? selectedContacts.map((contact) => ({
        label: contact.displayName,
        value: contact.id,
        data: contact,
      }))
    : [];

  const finalContactOptions = contactOptions;
  // Load Testing: comment above and uncomment below to simulate large number of contacts
  // const numberToMultiply = 3001;
  // const finalContactOptions = contactOptions.reduce((acc, contactOption) => {
  //   [...Array(numberToMultiply).keys()].forEach((key) =>
  //     acc.push({
  //       value: `${contactOption.value} ${key}`,
  //       label: `${contactOption.label} ${key}`,
  //       data: { id: `${contactOption.value} ${key}`, displayName: `${contactOption.label} ${key}` },
  //     }),
  //   );
  //   return acc;
  // }, []);

  const updateSelectedContactsIfChanged = (newSelectedContacts, forceUpdate) => {
    if (
      forceUpdate ||
      newSelectedContacts.length !== selectedContacts.length ||
      !newSelectedContacts.every((contact, index) => contact?.id === selectedContacts[index]?.id)
    ) {
      onContactsChanged(newSelectedContacts);
    }
  };

  // one of the subtle use case here is user can clear a contact selection, when they do we
  // still need to render this cleared selection, thus selectedContacts can be undefined
  const selectedDebtorsNonEmpty = selectedContacts.filter((contact) => contact);
  const noContactSelected = selectedDebtorsNonEmpty.length === 0;

  const onContactChanged = (selectedIndex, selectedContactOption) => {
    let newSelectedContacts = [...selectedContacts];

    newSelectedContacts[selectedIndex] = !selectedContactOption
      ? selectedContactOption
      : selectedContactOption.data || {
          id: selectedContactOption.value,
          displayName: selectedContactOption.label,
        };

    let forceUpdate;

    // Optionally de-duplicate selections
    if (preventDuplicates) {
      const { newContacts } = newSelectedContacts.reduce(
        (acc, contact, index) => {
          if (!contact) {
            return acc;
          }

          if (!acc.newContactsMap[contact.id]) {
            acc.newContacts.push(contact);
            acc.newContactsMap[contact.id] = contact;
          } else {
            // Reset key for duplicated contact in order
            // to re-render the Select component
            keyMapRef.current[index] = uuid();

            // We need to force-update the values in order to trigger a
            // re-render, otherwise the duplicate value will remain visible
            forceUpdate = true;
          }

          return acc;
        },
        { newContacts: [], newContactsMap: {} },
      );

      newSelectedContacts = newContacts;
    }

    // Update keyMap to ensure we are rendering the correct components
    if (!selectedContactOption) {
      const newKeyMap = [...keyMapRef.current];
      newKeyMap.splice(selectedIndex, 1);
      newKeyMap.push(uuid());
      keyMapRef.current = newKeyMap;

      // Once a field is cleared, we want to refocus on it
      refocusIndex.current = selectedIndex;
    }

    updateSelectedContactsIfChanged(newSelectedContacts, forceUpdate);
  };

  const moveContactIndex = (indexToMove, targetIndex) => {
    const reorderedContacts = [...selectedContacts];

    // In the case where an item is moved to replace the pseudo item at the end of the list, do nothing
    if (targetIndex + 1 > reorderedContacts.length) {
      return;
    }
    reorderedContacts.splice(indexToMove, 1);
    reorderedContacts.splice(targetIndex, 0, selectedContacts[indexToMove]);

    // Update keyMap to ensure we are rendering the correct components
    const reorderedKeyMap = [...keyMapRef.current];
    reorderedKeyMap.splice(indexToMove, 1);
    reorderedKeyMap.splice(targetIndex, 0, keyMapRef.current[indexToMove]);
    keyMapRef.current = reorderedKeyMap;

    updateSelectedContactsIfChanged(reorderedContacts);
    unprotectedDragIndex = targetIndex;
  };

  const activeTypeahead = React.useRef(undefined);

  return (
    <>
      {selectedContacts
        .concat([''])
        .slice(0, max)
        .map((selectedContactId, index) => {
          const canReorder = enableReordering && max > 1 && selectedContacts[index];

          return (
            <div
              className={styles.contactWrapper}
              key={`contact-select-${keyMapRef.current[index]}`}
              id={`contact-select-${index}`}
              ref={(el) => {
                divRef.current[index] = el;
              }}
              onDragOver={(event) => {
                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'move';
                if (index !== unprotectedDragIndex && dragData[elementId]) {
                  moveContactIndex(unprotectedDragIndex, index);
                }
              }}
              onDragStart={(event) => {
                // Unprotected var used as event data is not available during dragover
                unprotectedDragIndex = index;
                event.stopPropagation();
                dragData[elementId] = true;
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'move';
              }}
              onDragEnd={() => {
                if (dragData[elementId]) {
                  divRef.current[index].setAttribute('draggable', 'false');
                  dragData[elementId] = false;
                }
              }}
            >
              <div className={classnames(styles.contactField, canReorder && styles.draggable, className)}>
                {canReorder && (
                  <i
                    onMouseDown={() => {
                      // Required to prevent other children triggering drag events
                      divRef.current[index].setAttribute('draggable', 'true');
                    }}
                    onMouseUp={() => {
                      // Required to prevent other children triggering drag events
                      divRef.current[index].setAttribute('draggable', 'false');
                    }}
                    className={classnames(styles.dragElement, 'icon', 'icon-grab-handle')}
                  />
                )}
                <Select
                  ref={(el) => {
                    selectBoxRef.current[index] = el;
                  }}
                  inputId={`contact-select-${keyMapRef.current[index]}`}
                  className={classnames(isRequired && noContactSelected && index === 0 && styles.hasError)}
                  disabled={disabled}
                  options={activeTypeahead.current === keyMapRef.current[index] ? finalContactOptions : []}
                  backspaceRemovesValue={!!selectedContacts[index]}
                  isSearchable
                  filterOption={() => true} // Show all values, we use isSearchable to send requests to the back-end and need to show all the options provided
                  onValueChange={(contactOption) => onContactChanged(index, contactOption)}
                  selectedOption={selectedContacts[index]}
                  defaultValue={defaultOptions[index]}
                  isLoading={activeTypeahead.current === keyMapRef.current[index] && contactOptionsDataLoading}
                  maxMenuHeight={maxMenuHeight}
                  menuPlacement={menuPlacement}
                  onInputChange={(searchText) => {
                    activeTypeahead.current = keyMapRef.current[index];
                    onFetchContactOptions(searchText);
                  }}
                  onLoadMore={onFetchMoreContactOptions}
                  placeholder={placeholder}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue?.length ? 'No options' : 'Start typing to get a list of contacts...'
                  }
                  actionList={
                    contactOptionsHasMore === true
                      ? [
                          {
                            displayComponent: (
                              <span>
                                <i className="fa fa-plus" /> &emsp;Show more results
                              </span>
                            ),
                            callback: () => {
                              if (contactOptionsDataLoading) {
                                return;
                              }
                              onFetchMoreContactOptions();
                            },
                          },
                        ]
                      : []
                  }
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

ContactMultiSelect.displayName = 'ContactMultiSelect';

ContactMultiSelect.propTypes = {
  className: PropTypes.string,
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      data: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
      }),
    }),
  ).isRequired,
  contactOptionsDataLoading: PropTypes.bool.isRequired,
  contactOptionsHasMore: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  enableReordering: PropTypes.bool,
  isRequired: PropTypes.bool,
  max: PropTypes.number,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  placeholder: PropTypes.string,
  preventDuplicates: PropTypes.bool,
  selectedContacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
  // callbacks & functions
  onContactsChanged: PropTypes.func.isRequired,
  onFetchContactOptions: PropTypes.func.isRequired,
  onFetchMoreContactOptions: PropTypes.func.isRequired,
};

ContactMultiSelect.defaultProps = {
  className: undefined,
  disabled: false,
  enableReordering: true,
  isRequired: true,
  max: 3,
  maxMenuHeight: undefined,
  menuPlacement: 'bottom',
  placeholder: 'Select a contact ...',
  preventDuplicates: true,
  selectedContacts: [],
};

export default ContactMultiSelect;
