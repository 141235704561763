import React from 'react';
import PropTypes from 'prop-types';
import {
  EditableTypeahead,
  DurationPicker,
  CurrencyInput2,
  CurrencyDisplay,
  SlidingToggle,
  Button,
  Icon,
  DatePicker,
  useTranslation,
} from '@sb-itops/react';
import { MatterTypeahead2 } from '@sb-matter-management/react';
import { ActivityDropdown } from '@sb-billing/react/activity-dropdown';
import { TaskDropdown } from '@sb-billing/react/task-dropdown';
import { CheckboxListPopup } from '@sb-billing/react/checkbox-list-popup';
import {
  activityCategoryLabels,
  activityCategories,
  taskCategoryLabels,
  taskCategories,
} from '@sb-billing/business-logic/activities/entities/constants';
import { durationType as activityDurationTypes } from '@sb-billing/business-logic/shared/entities';
import { StaffDropdown } from '@sb-billing/react';
import * as Styles from './QuickFeeEntry.module.scss';

export const QuickFeeEntry = (props) => {
  const { t } = useTranslation();
  const { region, quickAddDisabled, tasksFieldDisabled, showMatterField, showTasksField } = props;
  const { showDateField, showStaffField, staffMemberOptions, onStaffMemberChange } = props;
  const { showGstField, showTaxOptions, taxOptions, onUpdateTaxOption, onTaxOptionsDisplayChange } = props;
  const {
    matterSummaries,
    matterSummariesDataLoading,
    onFetchMatterSummaries,
    onFetchMoreMatterSummaries,
    matterSummariesHasMore,
  } = props;
  const { activities, tasks, subjects } = props;
  const { onUpdateMatterField, onUpdateTaskField, onUpdateActivityField, setIsSubjectOverridable } = props;
  const { onUpdateDurationField, onDurationFieldBlur, onUpdateDurationTypeField } = props;
  const { formData, formErrors, onUpdateField, onAddFeeClicked, onClearForm } = props;

  // This blur handler gets called when the tax options dropdown is shown and focus is subsequently
  // given to an element outside the dropdown. If the focus is given to the Icon which shows the
  // dropdown, there will be a weird flicker as the dropdown is blured and closed, then the expand
  // button is clicked and the dropdown is expanded once more. We need to ignore the blur event in
  // the case of the show button being clicked, as it will handle hiding the dropdown.
  const handleTaxOptionsBlur = (event) => {
    // Ignore tax options blur if show tax options button is clicked.
    // The show tax options button will toggle.
    if (event?.relatedTarget?.parentNode?.id === 'tax-option-dropdown-group') {
      return;
    }

    onTaxOptionsDisplayChange(false);
  };

  return (
    <div className={Styles.quickFeeEntry}>
      {showDateField && (
        <div className={Styles.dateInputSection}>
          <label>Date</label>
          <DatePicker
            onSelect={(date) => onUpdateField('feeDate', date)}
            value={formData.feeDate}
            disabled={quickAddDisabled}
            hasError={formErrors.feeDate.isInvalid}
            hideDelete
          />
        </div>
      )}

      {/* Matter drop down */}
      {showMatterField && matterSummaries && (
        <div className={Styles.matterInputSection}>
          <label>Matter</label>
          <MatterTypeahead2
            disabled={quickAddDisabled}
            hasError={formErrors.matter.isInvalid}
            isLoading={matterSummariesDataLoading}
            matters={matterSummaries}
            maxMenuHeight={250} // Default is 300 and was making the dropdown overflow
            onInputChange={onFetchMatterSummaries}
            onLoadMore={onFetchMoreMatterSummaries}
            onMatterSelected={(matter) => onUpdateMatterField(matter?.data)}
            placeholder="Select a matter..."
            selectedMatterId={formData.matter?.id}
            actionList={
              matterSummariesHasMore === true
                ? [
                    {
                      displayComponent: (
                        <span>
                          <i className="fa fa-plus" /> &emsp;Show more results
                        </span>
                      ),
                      callback: () => {
                        if (matterSummariesDataLoading) {
                          return;
                        }

                        onFetchMoreMatterSummaries();
                      },
                    },
                  ]
                : []
            }
          />
        </div>
      )}

      {/* Staff drop down */}
      {showStaffField && staffMemberOptions && (
        <div className={Styles.staffInputSelection}>
          <label>Staff</label>
          <StaffDropdown
            staffMemberOptions={staffMemberOptions}
            placeholder=""
            disabled={quickAddDisabled}
            hasError={formErrors.staffId.isInvalid}
            selectedStaffId={formData.staffId}
            onSelectionChange={onStaffMemberChange}
            selectClassName={Styles.selectContainerStyling}
          />
        </div>
      )}

      {/* Activity drop down */}
      <div className={Styles.activityInputSection}>
        <label>Activity</label>
        <ActivityDropdown
          placeholder=""
          className={Styles.activityDropdown}
          disabled={quickAddDisabled}
          activities={activities}
          selectedActivityCode={formData.activity?.code}
          onSelectionChange={(selectedOption) =>
            onUpdateActivityField({ selectedActivity: selectedOption, isSelectedFromSubjectDropdown: false })
          }
          groupLabelHeight={26}
          optionLabelHeight={26}
          hideSelectedOptionDescription
          noDefaultStyling
          hasError={formErrors.activity.isInvalid}
          selectClassName={Styles.selectContainerStyling}
        />
      </div>

      {/* Task drop down */}
      {showTasksField && (
        <div className={Styles.taskInputSection}>
          <label>Tasks</label>
          <TaskDropdown
            placeholder=""
            disabled={quickAddDisabled || tasksFieldDisabled}
            tasks={tasks}
            selectedTaskCode={formData.task?.code}
            groupLabelHeight={26}
            optionLabelHeight={26}
            noDefaultStyling
            onSelectionChange={onUpdateTaskField}
            hasError={formErrors.task.isInvalid}
            selectClassName={Styles.selectContainerStyling}
          />
        </div>
      )}

      {/* Subject typeahead */}
      <div className={Styles.subjectInputSection}>
        <label>Subject</label>
        <EditableTypeahead
          id="quick-fee-entry-subject"
          disabled={quickAddDisabled}
          options={subjects}
          // Don't allow input value to differ from the selected option.
          // Free text input (not matching options) is fine if no selectedOption is present (undefined)
          // It will result in weird UI artifacts like text over text otherwise.
          inputValue={formData.subject || ''}
          selectedOption={formData.subjectActivity}
          labelKey="description"
          menuClassName={Styles.widthOverride}
          hasError={formErrors.subject.isInvalid}
          onValueChange={(newSubject) => {
            onUpdateField('subject', newSubject);
            // We don't overwrite if the user has modified the subject line AND the subject line is not blank
            setIsSubjectOverridable(newSubject.trim() === '');
          }}
          onOptionSelected={(selectedOption) => {
            onUpdateActivityField({ selectedActivity: selectedOption, isSelectedFromSubjectDropdown: true });
          }}
        />
      </div>

      {/* Duration picker */}
      <div className={Styles.durationInputSection}>
        <label>Duration</label>
        <DurationPicker
          className={Styles.durationPicker}
          duration={formData.durationType === activityDurationTypes.FIXED ? 'N/A' : formData.duration || ''}
          durationHasError={formErrors.duration.isInvalid}
          durationType={formData.durationType}
          onDurationChanged={(newDuration) => onUpdateDurationField(newDuration)}
          onDurationBlur={() => onDurationFieldBlur()}
          onDurationTypeChanged={(newDurationType) => onUpdateDurationTypeField(newDurationType)}
          durationDisabled={quickAddDisabled || formData.durationType === activityDurationTypes.FIXED}
          durationTypeDisabled={quickAddDisabled}
          clearFormGroupStyle
        />
      </div>

      {/* Time display */}
      <div className={Styles.timeInputSection}>
        <label>Time</label>
        <input
          className="form-control"
          type="text"
          value={formData.durationType === activityDurationTypes.FIXED ? 'N/A' : formData.time}
          disabled
        />
      </div>

      {/* Rate input */}
      <div className={Styles.rateInputSection}>
        <label>Rate</label>
        <CurrencyInput2
          name="rate"
          hasError={formErrors.rateInCents.isInvalid}
          value={formData.rateInCents}
          onChange={(e) => onUpdateField('rateInCents', e.target.value)}
        />
      </div>

      {/* Amount display */}
      <div className={Styles.amountDisplaySection}>
        <label>Amount</label>
        <CurrencyDisplay name="amount" amount={formData.amountInCents} region={region} hideDollar formatAmount />
      </div>

      {/* GST display */}
      {showGstField && (
        <div className={Styles.gstDisplaySection}>
          <label>{t('tax')}</label>
          <div className={Styles.inputDropdownCombo}>
            <CurrencyDisplay name="gst" amount={formData.taxAmountInCents} region={region} hideDollar formatAmount />
            <div id="tax-option-dropdown-group" className={Styles.iconDropdownGroup}>
              <Icon
                type="ellipsis-v"
                className={Styles.showDropdownIcon}
                onClick={() => onTaxOptionsDisplayChange(!showTaxOptions)}
                isRelatable
              />
              {showTaxOptions && (
                <CheckboxListPopup
                  list={taxOptions}
                  onOptionSelected={onUpdateTaxOption}
                  onBlur={handleTaxOptionsBlur}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Billable slider */}
      <div className={Styles.billableInputSection}>
        <label>Billable</label>
        <div className={Styles.toggleContainer}>
          <SlidingToggle
            id="isBillableToggle"
            name="isBillableToggle"
            scope="quick-fee-entry"
            onChange={(id, newValue) => onUpdateField('isBillable', newValue)}
            selected={formData.isBillable}
          />
        </div>
      </div>

      {/* Add button */}
      <div className={Styles.addButtonSection}>
        <Button onClick={onAddFeeClicked} size="full-width">
          Add
        </Button>
      </div>

      {/* Clear button */}
      <div className={Styles.clearButtonSection}>
        <div className={Styles.clearButtonContainer}>
          <i onClick={onClearForm} className="fa fa-times fa-fw" />
        </div>
      </div>
    </div>
  );
};

QuickFeeEntry.displayName = 'QuickFeeEntry';

const ActivityPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.oneOf(Object.values(activityCategories)),
});

const TaskPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.oneOf(Object.values(taskCategories)),
});

const FormError = PropTypes.shape({
  isInvalid: PropTypes.bool.isRequired,
});

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

QuickFeeEntry.propTypes = {
  region: PropTypes.string.isRequired,
  quickAddDisabled: PropTypes.bool.isRequired,
  showMatterField: PropTypes.bool.isRequired,
  showTasksField: PropTypes.bool.isRequired,
  tasksFieldDisabled: PropTypes.bool.isRequired,
  showGstField: PropTypes.bool.isRequired,
  showTaxOptions: PropTypes.bool.isRequired,
  showDateField: PropTypes.bool,
  showStaffField: PropTypes.bool,
  taxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  onUpdateTaxOption: PropTypes.func.isRequired,
  onTaxOptionsDisplayChange: PropTypes.func.isRequired,
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create a fee
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterHourlyRate: PropTypes.shape({
        rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
        allStaffRate: PropTypes.number,
        ratesPerStaff: PropTypes.arrayOf(
          PropTypes.shape({
            staffId: PropTypes.string.isRequired,
            rate: PropTypes.number.isRequired,
          }).isRequired,
        ),
        billableMinutes: PropTypes.number,
      }),
      matterTotals: PropTypes.shape({
        // For legacy typeahead using cache entities
        unbilled: PropTypes.number,
        unpaid: PropTypes.number,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ),
  matterSummariesDataLoading: PropTypes.bool,
  matterSummariesHasMore: PropTypes.bool,
  onFetchMatterSummaries: PropTypes.func,
  onFetchMoreMatterSummaries: PropTypes.func,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(activityCategoryLabels)),
      activities: PropTypes.arrayOf(ActivityPropType.isRequired).isRequired,
    }),
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(taskCategoryLabels)),
      tasks: PropTypes.arrayOf(TaskPropType).isRequired,
    }),
  ).isRequired,
  subjects: PropTypes.arrayOf(ActivityPropType.isRequired).isRequired,
  formData: PropTypes.shape({
    feeDate: PropTypes.instanceOf(Date),
    matter: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    activity: ActivityPropType,
    task: TaskPropType,
    subject: PropTypes.string,
    subjectActivity: PropTypes.object,
    staff: PropTypes.string,
    staffId: PropTypes.string,
    duration: PropTypes.string,
    durationType: PropTypes.oneOf(Object.values(activityDurationTypes)).isRequired,
    rateInCents: PropTypes.number,
    isBillable: PropTypes.bool.isRequired,
    time: PropTypes.string,
    amountInCents: PropTypes.number,
    taxAmountInCents: PropTypes.number,
  }).isRequired,
  formErrors: PropTypes.shape({
    activity: FormError.isRequired,
    feeDate: FormError.isRequired,
    matter: FormError.isRequired,
    subject: FormError.isRequired,
    duration: FormError.isRequired,
    task: FormError.isRequired,
    rateInCents: FormError.isRequired,
    staffId: FormError.isRequired,
  }).isRequired,
  onUpdateMatterField: PropTypes.func.isRequired,
  onUpdateActivityField: PropTypes.func.isRequired,
  onUpdateTaskField: PropTypes.func.isRequired,
  onUpdateDurationField: PropTypes.func.isRequired,
  onDurationFieldBlur: PropTypes.func.isRequired,
  onUpdateDurationTypeField: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onAddFeeClicked: PropTypes.func.isRequired,
  onClearForm: PropTypes.func.isRequired,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType),
  selectedStaffMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rate: PropTypes.number,
  }),
  onStaffMemberChange: PropTypes.func,
  setIsSubjectOverridable: PropTypes.func.isRequired,
};

QuickFeeEntry.defaultProps = {
  matterSummaries: undefined,
  matterSummariesDataLoading: undefined,
  matterSummariesHasMore: undefined,
  onFetchMatterSummaries: undefined,
  onFetchMoreMatterSummaries: undefined,
  showDateField: undefined,
  showStaffField: undefined,
  selectedStaffMember: undefined,
  staffMemberOptions: undefined,
  onStaffMemberChange: undefined,
};
