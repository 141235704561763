import { cacheMap } from '@sb-itops/redux';
import { pick } from 'dot-object';

let _caches = {};

export const setCaches = (caches) => {
  _caches = caches;
};

export const names = () => {
  const names = new Set([...Object.keys(_caches), ...Object.keys(cacheMap)]);
  return [...names];
};

export const query = (name, key, subquery) => {
  if (_caches[name]) {
    // use a noop for caches not in redux
    const getMap = cacheMap[name] ? cacheMap[name].getMap : () => ({});
    let result = key ? getMap()[key] : getMap();

    if (result && subquery) {
      result = pick(subquery, result);
    }
    try {
      return JSON.parse(JSON.stringify(result || {}));
    } catch (err) {
      console.error("Too much data for JSON stringify to deal with - please use key and subquery to reduce search space")
      return {};
    }
  }  
};

export const count = (name) => {
  if (_caches[name]) {
    // use a noop for caches not in redux
    const getMap = cacheMap[name] ? cacheMap[name].getMap : () => ({});
    const result = getMap();

    return Object.keys(result).length;
  }  
};

export const getState = (name) => _caches[name].getState();

export const api = {
  names,
  query,
  getState,
  count,
};
