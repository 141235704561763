import { dateToInteger as toEffectiveDate } from '@sb-itops/date';
import moment from 'moment';
import { TOGGLE_SHOW_FILTERS, SET_FILTER } from './types';

// So we dont break existing links to specific filters:
const url = new URLSearchParams(window.location.search);

const INITIAL_FILTER_STATE = {
  showFilters: true,
  filters: {
    showHidden: url.get('showHidden') || false,
    dateListFilter: {
      selectedFilter: 'ALL',
      before: toEffectiveDate(moment().toDate()), // effective date YYYYMMDD
      from: url.get('endDate') || toEffectiveDate(moment().startOf('month').toDate()), // effective date YYYYMMDD
      to: url.get('startDate') || toEffectiveDate(moment().startOf('month').add(1, 'month').add(-1, 'day').toDate()),
    },
  },
};

export const reducer = (state = INITIAL_FILTER_STATE, action = {}) => {
  switch (action.type) {
    case SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
    }
    case TOGGLE_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
