'use strict';

const moment = require('moment');
const { integerToDate, dateToInteger } = require('@sb-itops/date');
const { sortByProperty } = require('@sb-itops/nodash');
const { depositTypes, paymentTypes } = require('../constants');

function isAdjustmentsInvalid({ adjustments, startDateAsInt, endDateAsInt }) {
  if (!Array.isArray(adjustments)) {
    return false;
  }
  // all date are as YYYYMMDD integer
  const newAdjustments = sortByProperty(
    adjustments.filter((a) => a.isNew && !a.isDeleted),
    'date',
  );
  const firstAdjDateAsInt = newAdjustments[0] && newAdjustments[0].date;
  const lastAdjDateAsInt = newAdjustments[newAdjustments.length - 1] && newAdjustments[newAdjustments.length - 1].date;

  if (!firstAdjDateAsInt || !lastAdjDateAsInt) {
    return false;
  }

  if (firstAdjDateAsInt && lastAdjDateAsInt && startDateAsInt && endDateAsInt) {
    return firstAdjDateAsInt < startDateAsInt || lastAdjDateAsInt > endDateAsInt;
  }

  return false;
}

function getAdjustments(summaryData) {
  return (summaryData && summaryData.adjustments) || [];
}

function getAdjustmentsTotal(summaryData) {
  const adjustments = getAdjustments(summaryData);

  return adjustments.reduce((acc, curr) => (curr.isReconciled || curr.isDeleted ? acc : acc - curr.amount), 0);
}

function calcCashBookBalanceAsAtBsd(summaryData) {
  const openingCashBookBalance = (summaryData && summaryData.openingCashBookBalance) || 0;
  const addReceipts = (summaryData && summaryData.addReceipts) || 0;
  const lessPayments = (summaryData && summaryData.lessPayments) || 0;

  return openingCashBookBalance + addReceipts + lessPayments;
}

function calcReconBalance(summaryData, bankStatementBalance = 0) {
  const addUnbankedReceipts = (summaryData && summaryData.addUnbankedReceipts) || 0;
  const lessUnpresentedPayments = (summaryData && summaryData.lessUnpresentedPayments) || 0;

  return bankStatementBalance + addUnbankedReceipts + lessUnpresentedPayments + getAdjustmentsTotal(summaryData);
}

function calcOffset(summaryData, bankStatementBalance = 0) {
  return calcReconBalance(summaryData, bankStatementBalance) - calcCashBookBalanceAsAtBsd(summaryData);
}

function isBalancesInvalid({ summaryData, bankStatementBalance = 0 }) {
  return calcOffset(summaryData, bankStatementBalance) !== 0;
}

function calculateMaxDate({
  startDate,
  cannotReconcileAcrossMultipleMonth,
  cannotReconcileOnToday,
  isFinalise = false,
}) {
  const today = new Date();
  const currentMaxDate =
    // If we are validating to finalise and the facet is on, we don't allow current day reconciliations BB-9819
    isFinalise && cannotReconcileOnToday ? moment(today).subtract(1, 'days').toDate() : today;

  if (!startDate || !cannotReconcileAcrossMultipleMonth) {
    return currentMaxDate;
  }

  const endOfMonthDate = moment(startDate).endOf('month');
  if (moment(today).isBefore(endOfMonthDate)) {
    return currentMaxDate;
  }

  return endOfMonthDate.toDate();
}

function isEndDateInvalid({
  startDateAsInt,
  endDateAsInt,
  cannotReconcileAcrossMultipleMonth,
  cannotReconcileOnToday,
  isFinalise = false,
}) {
  if (!endDateAsInt) return { reconcileDate: true };

  const maxDateAsInt = dateToInteger(
    calculateMaxDate({
      startDate: integerToDate(startDateAsInt),
      cannotReconcileAcrossMultipleMonth,
      cannotReconcileOnToday,
      isFinalise,
    }),
  );

  const endDateError = endDateAsInt > maxDateAsInt;
  let sameMonthEndOfMonthError = false;

  if (cannotReconcileAcrossMultipleMonth) {
    const endDate = moment(endDateAsInt, 'YYYYMMDD').toDate();
    const startDate = moment(startDateAsInt, 'YYYYMMDD').toDate();

    // we need to be in the same month in AU always to reconcile a bank-rec
    sameMonthEndOfMonthError = !endDateError && moment(endDate).format('YYYYMM') !== moment(startDate).format('YYYYMM');
  }

  return {
    endDate: endDateError,
    sameMonthEndOfMonth: sameMonthEndOfMonthError,
  };
}

function calculateReceiptsTotal({ transactions, depositSlips, bulkDeposits }) {
  const totalReceiptTransactions = transactions.reduce((total, tx) => {
    if (depositTypes.includes(tx.type) && !tx.unbanked) {
      return total + tx.amount;
    }
    return total;
  }, 0);

  const totalDepositSlips = depositSlips.reduce((total, ds) => {
    // Some transactions of a DS may be unbanked, while others aren't so we can't use the totalDepositSlip amount
    const totalBankedDepositSlipAmount = ds.transactions.reduce((sum, tx) => (tx.unbanked ? sum : sum + tx.amount), 0);
    return total + totalBankedDepositSlipAmount;
  }, 0);

  const totalBulkDeposits = bulkDeposits.reduce((total, bulkDeposit) => {
    if (bulkDeposit.transactions && !bulkDeposit.transactions[0].unbanked) {
      return total + bulkDeposit.amount;
    }
    return total;
  }, 0);

  return totalReceiptTransactions + totalDepositSlips + totalBulkDeposits;
}

function calculatePaymentsTotal({ transactions, ttoTransactions }) {
  const txnsPayments = transactions.reduce((total, tx) => {
    if (paymentTypes.includes(tx.type) && !tx.unbanked) {
      return total + tx.amount;
    }
    return total;
  }, 0);

  const ttoPayments = ttoTransactions.reduce((total, tto) => {
    if (tto.transactions && !tto.transactions[0].unbanked) {
      return total + tto.amount;
    }
    return total;
  }, 0);
  return txnsPayments + ttoPayments;
}

function getSummaryData({
  summaryData,
  endDate: endDateAsInt,
  startDate: startDateAsInt,
  bankStatementBalance,
  cannotReconcileOnToday,
  cannotReconcileAcrossMultipleMonth,
}) {
  const startDate = startDateAsInt ? moment(startDateAsInt, 'YYYYMMDD').toDate() : null;
  const endDate = endDateAsInt ? moment(endDateAsInt, 'YYYYMMDD').toDate() : null;

  const cashBookBalanceAsAtBsd = calcCashBookBalanceAsAtBsd(summaryData);
  const reconciliationBalance = calcReconBalance(summaryData, bankStatementBalance);
  const maxDate = calculateMaxDate({ startDate, cannotReconcileOnToday, cannotReconcileAcrossMultipleMonth });

  return {
    bankStatementBalance,
    startDate,
    endDate,
    maxDate,
    cashBookBalanceAsAtBsd,
    reconciliationBalance,
    adjustmentsTotal: getAdjustmentsTotal(summaryData),
    adjustments: summaryData.adjustments,
    offset: calcOffset(summaryData, bankStatementBalance),
    openingCashBookBalance: summaryData.openingCashBookBalance,
    addReceipts: summaryData.addReceipts,
    lessPayments: summaryData.lessPayments,
    ledgerBalance: summaryData.ledgerBalance,
    addUnbankedReceipts: summaryData.addUnbankedReceipts,
    lessUnpresentedPayments: summaryData.lessUnpresentedPayments,
  };
}

// receipts that have not been selected in the current reconciliation
function calculateUnbankedReceipts({
  transactions,
  depositSlips,
  bulkDeposits,
  unbankedTransactions = [],
  selectedTransactionIds,
  selectedConsolidatedIds,
  selectedDepositSlipIds,
}) {
  const totalUnbankedDepositSlips = depositSlips.reduce((total, ds) => {
    if (!selectedDepositSlipIds.includes(ds.id)) {
      return total + ds.totalDepositSlip;
    }
    return total;
  }, 0);
  const totalUnbankedReceiptTransactions = transactions.reduce((total, tx) => {
    if (depositTypes.includes(tx.type) && !selectedTransactionIds.includes(tx.id)) {
      return total + tx.amount;
    }
    return total;
  }, 0);
  const totalUnbankedBulkDeposits = bulkDeposits.reduce((total, bulkDeposit) => {
    if (!selectedConsolidatedIds.includes(bulkDeposit.id)) {
      return total + bulkDeposit.amount;
    }
    return total;
  }, 0);

  // If we have unbanked transactions which are part of deposit slip, but the deposit slip itself does not
  // fall in the date range, we need to add this amount to totals.
  //
  // Main use case is between setup and first bank recs.
  // In setup, we have to add to unbanked any deposit slip transaction which falls before recon start date but its
  // deposit slip date is after recon start date. The code below makes sure we add this amount to unbanked receipts
  // if the transaction's deposit slip is not shown in this bank recs.
  const totalUnbankedNotShownTransactions = unbankedTransactions.reduce((total, tx) => {
    if (tx.depositSlipId && !depositSlips.find((ds) => ds.id === tx.depositSlipId)) {
      return total + tx.amount;
    }
    return total;
  }, 0);

  return (
    totalUnbankedDepositSlips +
    totalUnbankedReceiptTransactions +
    totalUnbankedBulkDeposits +
    totalUnbankedNotShownTransactions
  );
}

// payments that have not been selected in the current reconciliation
function calculateUnpresentedPayments({
  transactions,
  ttoTransactions,
  selectedTransactionIds,
  selectedConsolidatedIds,
}) {
  const totalTtoUnpresentedPaymentTransactions = ttoTransactions.reduce((total, tto) => {
    // If parent tto is NOT selected, check how many of its children are receipts, if any
    // Only add those
    if (!selectedConsolidatedIds.includes(tto.id)) {
      return total + tto.amount;
    }
    return total;
  }, 0);

  const totalUnpresentedPaymentTransactions = transactions.reduce((total, tx) => {
    if (paymentTypes.includes(tx.type) && !selectedTransactionIds.includes(tx.id)) {
      return total + tx.amount;
    }
    return total;
  }, 0);

  return totalTtoUnpresentedPaymentTransactions + totalUnpresentedPaymentTransactions;
}

module.exports = {
  getSummaryData,
  calcOffset,
  isAdjustmentsInvalid,
  isBalancesInvalid,
  isEndDateInvalid,
  getAdjustments,
  calculateMaxDate,
  calcReconBalance,
  calculatePaymentsTotal,
  calculateReceiptsTotal,
  calculateUnbankedReceipts,
  calculateUnpresentedPayments,
};
