'use strict';

const { sortByProperty } = require('@sb-itops/nodash');

const createChequeDescription = ({
  cheque,
  consolidatedTx,
  hasReasonFieldFacet,
  excludeReason = false,
  getPaymentById,
  getInvoiceNumberById,
  t,
}) => {
  if (!cheque.isTrustToOffice) {
    return hasReasonFieldFacet && !excludeReason && consolidatedTx.reason
      ? `${consolidatedTx.description} - ${consolidatedTx.reason}`
      : consolidatedTx.description;
  }

  const trustToOfficeLabel = `${t('trustToOfficeTransferLabel')} for`;

  const processedInvoices = new Set();
  const invoicesOnCheque = consolidatedTx.paymentIds.reduce((acc, paymentId) => {
    const payment = getPaymentById(paymentId);
    payment.invoices.forEach(({ invoiceId }) => {
      // Ensure we don't duplicate invoice ids in the description.
      if (!processedInvoices.has(invoiceId)) {
        acc.push({ invoiceId, invoiceNumber: getInvoiceNumberById(invoiceId) });
        processedInvoices.add(invoiceId);
      }
    });

    return acc;
  }, []);

  const sortedInvoicesOnCheque = sortByProperty(invoicesOnCheque, 'invoiceNumber');

  const placeholders = sortedInvoicesOnCheque.reduce((acc, { invoiceId }) => {
    let newPlaceholder = acc ? ', ' : '';
    newPlaceholder += `#invoiceId:${invoiceId}`;
    return acc + newPlaceholder;
  }, '');

  const reversalPrefix = consolidatedTx.isReversed ? 'Reversal: ' : '';
  const invoicePlurality = `${sortedInvoicesOnCheque.length === 1 ? ' invoice ' : ' invoices '}`;
  const reasonSuffix = hasReasonFieldFacet && !excludeReason ? ` - ${consolidatedTx.reason}` : '';
  return `${reversalPrefix}${trustToOfficeLabel}${invoicePlurality}${placeholders}${reasonSuffix}`;
};

module.exports = {
  createChequeDescription,
};
