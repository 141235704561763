import { gql } from '@apollo/client';

const query = gql`
  query InvoicesWithUnpaidAmount($invoiceIds: [ID]) {
    invoices(ids: $invoiceIds) {
      id
      totals {
        unpaid
      }
    }
  }
`;

const notificationIds = ['WebQueryBillingMattersNotifications.BillingInvoiceTotalsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoicesWithUnpaidAmount = {
  query,
  notificationIds,
};
