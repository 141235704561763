import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-matter-management/redux/relate-contact-to-matters';

angular.module('@sb-matter-management/services').service('sbRelateContactToMattersMbService', function (sbGenericCacheService, sbGenericEndpointService, sbEndpointType) {
  const stub = 'matter-management/relate-contact-to-matters/';
  const requestConstructor = sbGenericEndpointService.requestConstructorFactory(stub + 'sync', 'since')
  
  sbGenericCacheService.setupCache({
    name: 'sbRelateContactToMattersMbService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub },
      poll: 15,
      subscriptions: 'notifier.RoleEntityMappingNotifications',
      requestConstructor: async (lastUpdated = '') => lastUpdated.startsWith('presync')
        ? await requestConstructor(lastUpdated)
        : await requestConstructor(`sync$${lastUpdated}`),
    },
    updateRedux,
    clearRedux,
  });
});
