import { featureActive } from '@sb-itops/feature';

const marshalGracePeriod = (rawInterestSettings) => {
  const gracePeriodData = {
    gracePeriodDays: rawInterestSettings.hasGracePeriod ? rawInterestSettings.gracePeriodDays : 0,
  };

  return gracePeriodData;
};

const marshalRatePA = (rawInterestSettings) => {
  const gracePeriodData = {
    ratePa: rawInterestSettings.hasInterestRate ? rawInterestSettings.ratePa : 0,
  };

  return gracePeriodData;
};

const marshalInterestApplyMode = (rawInterestSettings) => {
  let interestApplyMode = 1;

  if (rawInterestSettings) {
    switch (rawInterestSettings.interestApplyMode) {
      case 'AfterDueDate': {
        interestApplyMode = 1;
        break;
      }
      case 'AfterGracePeriod': {
        interestApplyMode = rawInterestSettings.hasGracePeriod ? 0 : 1;
        break;
      }
      default: {
        break;
      }
    }
  }

  return {
    interestApplyMode,
  };
};

export const marshalInterestSettings = (matterId, rawInterestSettings) => {
  const interestSettings = {};

  if (rawInterestSettings) {
    Object.assign(
      interestSettings,
      marshalRatePA(rawInterestSettings),
      marshalGracePeriod(rawInterestSettings),
      marshalInterestApplyMode(rawInterestSettings),
    );

    if (featureActive('BB-1986')) {
      Object.assign(interestSettings, {
        applyInterestToUnpaidInvoices: rawInterestSettings.applyInterestToUnpaidInvoices,
      });
    }

    return {
      matterId,
      invoiceInterestSettings: interestSettings,
    };
  }

  Object.assign(interestSettings, marshalGracePeriod(rawInterestSettings));

  if (featureActive('BB-1986')) {
    Object.assign(interestSettings, {
      applyInterestToUnpaidInvoices: rawInterestSettings.applyInterestToUnpaidInvoices,
    });
  }

  return {
    matterId,
    invoiceInterestSettings: interestSettings,
  };
};
