'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { cleanAndEncode64Html } from '@sb-itops/html';
import { featureActive } from '@sb-itops/feature';
import { getTemplateById } from '@sb-billing/redux/invoice-settings-template';
import * as invoiceEntities from '@sb-billing/business-logic/invoice/entities'; 
import { getByType as getBankAccountByType } from '@sb-billing/redux/bank-account';

const rateLimit = require('async-sema/rate-limit');
const MAX_NB_CONCURRENT_FINALISE_REQUESTS = 5;

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

angular.module('@sb-billing/services').service('sbSaveInvoiceCommand', function (sbLoggerService, sbUuidService, sbInvoiceCreateService, sbPaymentService, sbInvoicingService, sbInvoiceTotalsService, sbMattersMbService, sbInvoiceSettingsService, sbDateService, sbLocalisationService) {
  const that = this;
  const log = sbLoggerService.getLogger('sbSaveInvoiceCommand');

  that.executeP = executeP;
  that.executeBulkP = executeBulkP;

  // TODO clean up this interface
  async function executeP (invoiceId, invoiceVersion, quickPayments, sendInfo, isFinal, isBulkOperation) {
    const matterId = _.get(invoiceVersion, 'matterId');
    if (_.isEmpty(_.get(invoiceVersion, 'matterId'))) {
      log.error('Error while saving invoice. No matter specified');
      throw new Error('Error while saving invoice. No matter specified');
    }

    if (!sbMattersMbService.isEditableMatter(matterId)) {
      log.error('Error while saving invoice. Matter is not editable');
      throw new Error('Error while saving invoice. Matter is not editable');
    }

    if (featureActive('BB-162')) {
      updateIssuedDate(isFinal, invoiceVersion);
    }

    invoiceVersion = buildInvoiceToSave(invoiceVersion, isFinal, invoiceId);
    const quickPaymentsToSave = buildQuickPaymentsToSave(quickPayments, invoiceVersion.invoiceId);
    const opdates = generateOpdates(invoiceVersion, isFinal, quickPaymentsToSave);
    log.warn('posting OPDATES for invoice create:', opdates);

    await sbInvoiceCreateService.saveInvoiceP(invoiceVersion, quickPaymentsToSave, sendInfo, isFinal, opdates, isBulkOperation);
    return { invoiceVersion, quickPayments: quickPaymentsToSave, sendInfo, opdates };
  }

  function buildBulkFinalizeRequest(invoice) {
    return {
      ...invoice,
      discount: (invoice.discount && invoice.discount.type !== undefined) ? invoice.discount : null,
    }
  }

  async function executeBulkP(invoiceIds, quickPayments = {}, isBulkOperation) {
    const invoices = invoiceIds.map(invoiceId => sbInvoicingService.getInvoice(invoiceId));
    const bulkFinaliseThrottle = rateLimit(MAX_NB_CONCURRENT_FINALISE_REQUESTS);
    const IS_FINAL = true;

    const finalisePromises = invoices.map(async (invoice) => {
      const invoiceCopy = buildBulkFinalizeRequest(invoice);

      // if there are no entries, the invoice cannot be finalised
      if (_.isEmpty(invoice.entries)) {
        log.error('Error while bulk finalising invoice. No items on invoice');
        return {
          success: false, 
          error: `Error while bulk ${sbLocalisationService.t('finalising')} invoice. No items on invoice`,
        };
      }

      await bulkFinaliseThrottle();
      return promiseToResult(executeP(invoiceCopy.invoiceId, invoiceCopy, quickPayments[invoice.invoiceId], undefined, IS_FINAL, isBulkOperation), invoice.invoiceId);
    });

    return Promise.all(finalisePromises);
  }

  // Used to override promise all standard 'fail quickly' behaviour of Promise.all
  function promiseToResult (promise, invoiceId) {
    return promise
      .then((result) => ({success: true, invoiceId, result}))
      .catch((error) => ({success: false, invoiceId, error}));
  }

  function buildQuickPaymentsToSave(quickPayments, invoiceId) {
    const finalQuickPayments = quickPayments && quickPayments.map((quickPayment) => {
      const newQuickPayment = { ...quickPayment };
      newQuickPayment.paymentId = newQuickPayment.paymentId || sbUuidService.get();
      // Add the operating account detail for the quick payment
      const includeOperatingAccountDetailInPayments = hasFacet(facets.operatingAccountDetail);
      if (featureActive('BB-5509') && includeOperatingAccountDetailInPayments) {
        const operatingAccounts = getBankAccountByType('OPERATING');
        const bankAccountDetails = operatingAccounts && JSON.parse(JSON.stringify(operatingAccounts[0]));
        newQuickPayment.destinationBankAccountName = bankAccountDetails.accountName;
        newQuickPayment.destinationBankAccountNumber = bankAccountDetails.accountNumber;
        newQuickPayment.destinationBankBranchNumber = bankAccountDetails.branchNumber;
      }

      let reasonValue = null;
      if (quickPayment.source === 'Trust') {
        if (hasFacet(facets.reasonField) && featureActive('BB-5508')) { // reasonField is AU/GB facet
          reasonValue = `Transfer to ${sbLocalisationService.t('operating')} for payment of invoice #invoiceId:${invoiceId}`;
        } else if (hasFacet(facets.trustPaymentReasonField)) {  // trustPaymentReasonField is US facet
          reasonValue = `Legal Fees on Invoice #invoiceId:${invoiceId}`;
        }
      }
      newQuickPayment.reason = reasonValue;

      return newQuickPayment;
    });

    return finalQuickPayments;
  }

  // We allow users to save draft invoices without an issued date
  // so when saving a finalized invoice we need to make sure the 
  // issued date defaults to TODAY and the due date is calculated.
  function updateIssuedDate(isFinal, invoiceVersion) {
    if (!isFinal || invoiceVersion.issuedDate) {
      return;
    }
    const loadedSettings = getTemplateById(invoiceVersion.templateId).settings || sbInvoiceSettingsService.get();
    invoiceVersion.dueDate = sbDateService.to(moment(sbDateService.nowDateOnly()).add(loadedSettings.paymentDueDays, 'day').toDate());
    invoiceVersion.issuedDate = sbDateService.to(sbDateService.nowDateOnly());
  }

  function buildInvoiceToSave (invoiceVersion, isFinal, invoiceId) {
    return {
      ..._.cloneDeep(invoiceVersion),
      invoiceId: invoiceId || sbUuidService.get(),
      versionId: sbUuidService.get(),
      userId: getUserId(),
      status: isFinal ? 1 : 0, // Billing.InvoiceStatus.Final : Billing.InvoiceStatus.Draft
      invoiceNumber: invoiceVersion.invoiceNumber && !_.isNumber(invoiceVersion.invoiceNumber) ? undefined : invoiceVersion.invoiceNumber, // could be 'PENDING';
      summary: cleanAndEncode64Html({ dirtyHtml: invoiceVersion.summary })
    }
  }

  function generateOpdates (invoiceVersion, isFinal, quickPayments) {
    const cacheInvoice = getInvoiceToCache(invoiceVersion);
    const opdates = {sbInvoicingService: [cacheInvoice]};

    // Update invoice totals if an `entity` object is provided for invoice entries, even if the Fee cache is disabled.
    // This is for draft invoices where we fetch all the relevant data from LOD.
    if (cacheInvoice.currentVersion.entries.filter(entry => !!entry.feeEntity || !!entry.expenseEntity).length > 0) {
      opdates.sbInvoiceTotalsService = sbInvoiceTotalsService.getTotalsChangesetForInvoice(cacheInvoice);
    }
    optimisticallyUpdatePayments(invoiceVersion.invoiceId, quickPayments, opdates);
    return opdates;
  }

  function getInvoiceToCache (invoiceVersion) {
    const currentVersion = {
      ..._.cloneDeep(invoiceVersion),
      status: invoiceEntities.statusByValue[invoiceVersion.status], // change number to string
      invoiceNumber: invoiceVersion.invoiceNumber === undefined ? 'PENDING' : invoiceVersion.invoiceNumber,
    }

    return {
      accountId: getAccountId(),
      invoiceId: currentVersion.invoiceId,
      matterId: currentVersion.matterId,
      versionIds: [currentVersion.versionId],
      currentVersion,
      optimistic: true,
    };
  }

  function optimisticallyUpdatePayments (invoiceId, quickPayments, opdates) {
    log.info('processing payments : ' + JSON.stringify(quickPayments) + 'for invoice ID : ' + invoiceId);

    _.each(quickPayments, (qp) => {
      const payment = {
        invoiceId,
        userId: getUserId(),
        paymentId : qp.paymentId,
        source: qp.source,
        sourceAccountId: qp.sourceAccountId,
        sourceAccountType: qp.sourceAccountType,
        sourceAccountBalanceType: sbPaymentService.balanceType.MATTER,
        amount: qp.amount,
        payorId: qp.payorId,
        matterId: qp.matterId,
        allowOverdraw: qp.allowOverdraw,
      };

      log.info('produced payment : ' + JSON.stringify(payment));
      sbPaymentService.optimisticUpdate(opdates, payment);
    });
  }

});
