'use strict';

const displayPaymentMethodStatus = (expiry) => ({
  expired: 'Card is expired',
  failed: 'Last payment failed; contact the cardholder to update their payment information',
  expiryWarning: `Card expires ${expiry}`,
});

module.exports = {
  displayPaymentMethodStatus,
};
