angular.module('sb.billing.webapp').component('sbDataRestErrorSummaries', {
  require: {sbComposeCtrl: '^sbCompose'},
  controller: function (sbRestErrorService, sbDispatcherService) {

    const that = this;
    this.$onInit = update;

    sbDispatcherService.register('sbDataRestErrorSummaries', msg => {
      if (msg.action === 'notifier.RestErrorNotification') {
        update(); 
      }
    });

    function update() {
      that.sbComposeCtrl.setComposeData(sbRestErrorService.getErrorSummaries(), 'restErrorSummaries');
    }
  }
});
