import React from 'react';
import PropTypes from 'prop-types';
import { LoadingBarInfinite, Button, Modal } from '@sb-itops/react';
import { PrintChequesForm } from '../print-cheques-form';

import styles from './PrintOperatingChequeModal.module.scss';

const PrintOperatingChequeModal = ({
  modalId,
  modalDialogTitle,
  isLoading,
  formInitialised,
  unprintedCheques,
  sortDirection,
  preselectedChequeIds,
  overrideChequeDate,
  availableChequeNumbers,
  lastChequeNumberFound,
  firstChequeNumber,
  chequeNumberPadding,
  selectedChequeIds,
  overrideChequeMemos,
  showLoadingIndicator,
  disableSubmit,
  errors,
  showDuplicateChequeNumberToggle,
  allowDuplicateChequeNumbers,
  accountName,
  // callbacks & functions
  onFieldValueChanged,
  onFieldValuesChanged,
  onSortDirectionChanged,
  onSubmit,
  onCloseModal,
  // config
  showChequeMemo,
}) => (
  <Modal
    modalId={modalId}
    className={styles.printOperatingChequeModal}
    modalHeaderClassName={styles.modalHeader}
    modalBodyClassName={styles.modalBody}
    modalFooterClassName={styles.modalFooter}
    title={modalDialogTitle}
    body={
      isLoading || !formInitialised ? (
        <div className={styles.loadingBarContainer}>
          <LoadingBarInfinite loading={isLoading} />
        </div>
      ) : (
        <PrintChequesForm
          formInitialised={formInitialised}
          accountName={accountName}
          unprintedCheques={unprintedCheques}
          sortDirection={sortDirection}
          preselectedChequeIds={preselectedChequeIds}
          overrideChequeDate={overrideChequeDate}
          availableChequeNumbers={availableChequeNumbers}
          lastChequeNumberFound={lastChequeNumberFound}
          firstChequeNumber={firstChequeNumber}
          chequeNumberPadding={chequeNumberPadding}
          selectedChequeIds={selectedChequeIds}
          overrideChequeMemos={overrideChequeMemos}
          showLoadingIndicator={showLoadingIndicator}
          errors={errors}
          onFieldValueChanged={onFieldValueChanged}
          onFieldValuesChanged={onFieldValuesChanged}
          onSortDirectionChanged={onSortDirectionChanged}
          allowDuplicateChequeNumbers={allowDuplicateChequeNumbers}
          showDuplicateChequeNumberToggle={showDuplicateChequeNumberToggle}
          showChequeMemo={showChequeMemo}
        />
      )
    }
    footer={
      <div className={styles.buttonsContainer}>
        <Button className={styles.buttonContainer} size="full-width" disabled={disableSubmit} onClick={onSubmit}>
          Process
        </Button>
      </div>
    }
    onModalClose={onCloseModal}
  />
);

PrintOperatingChequeModal.displayName = 'PrintOperatingChequeModal';

PrintOperatingChequeModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  modalDialogTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  formInitialised: PropTypes.bool.isRequired,
  unprintedCheques: PropTypes.arrayOf(
    PropTypes.shape({
      chequeId: PropTypes.string.isRequired,
      effectiveDate: PropTypes.number.isRequired,
      matters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          matterNumber: PropTypes.string,
          clientDisplay: PropTypes.string,
          otherSideDisplay: PropTypes.string,
          matterType: PropTypes.shape({
            matterTypeId: PropTypes.string,
            name: PropTypes.string,
          }),
        }),
      ).isRequired,
      payee: PropTypes.string,
      reference: PropTypes.string,
      amount: PropTypes.number.isRequired,
      chequeMemo: PropTypes.string,
    }),
  ),
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  accountName: PropTypes.string.isRequired,
  preselectedChequeIds: PropTypes.array,
  overrideChequeDate: PropTypes.instanceOf(Date),
  availableChequeNumbers: PropTypes.array,
  lastChequeNumberFound: PropTypes.string,
  firstChequeNumber: PropTypes.string.isRequired,
  chequeNumberPadding: PropTypes.number.isRequired,
  selectedChequeIds: PropTypes.object,
  overrideChequeMemos: PropTypes.object,
  showLoadingIndicator: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  errors: PropTypes.object,
  showDuplicateChequeNumberToggle: PropTypes.bool,
  allowDuplicateChequeNumbers: PropTypes.bool,
  // callbacks & functions
  onFieldValueChanged: PropTypes.func.isRequired,
  onFieldValuesChanged: PropTypes.func.isRequired,
  onSortDirectionChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  // config
  showChequeMemo: PropTypes.bool.isRequired,
};

PrintOperatingChequeModal.defaultProps = {
  isLoading: false,
  availableChequeNumbers: [],
  lastChequeNumberFound: undefined,
  unprintedCheques: [],
  sortDirection: 'asc',
  preselectedChequeIds: [],
  overrideChequeDate: undefined,
  selectedChequeIds: {},
  overrideChequeMemos: {},
  showLoadingIndicator: false,
  disableSubmit: true,
  errors: {},
  showDuplicateChequeNumberToggle: false,
  allowDuplicateChequeNumbers: false,
};

export default PrintOperatingChequeModal;
