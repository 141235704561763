import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { entryTypeOptions } from '@sb-billing/business-logic/shared/entities';
import {
  forms2PropTypes,
  Button,
  FloatingCard,
  SlidingToggle,
  Spinner,
  FormLabel,
  StatelessSelect,
} from '@sb-itops/react';
import { CustomTaskCodeDeleteModal } from '../custom-task-code-delete-modal';
import Styles from './AddEditCustomTaskCode.module.scss';

const AddEditCustomTaskCode = React.memo(
  ({
    // form fields
    id,
    code,
    description,
    entryType,
    isBillable,
    // form state
    formDirty,
    formInitialised,
    formSubmitting,
    submitFailed,
    // func & callbacks
    onClose,
    onFieldValueUpdated,
    onDelete,
    onSave,
    // state
    showDeleteModal,
    setShowDeleteModal,
    isDeleting,
  }) => {
    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

    const isProcessing = formSubmitting || isDeleting;
    return (
      <div className={Styles.addEditCustomTaskCode}>
        {!formInitialised ? (
          <Spinner small />
        ) : (
          <>
            <FloatingCard applyMargin={false}>
              <fieldset disabled={isProcessing}>
                <h4>{id?.value ? 'Edit Custom Task Code' : 'Add Custom Task Code'}</h4>
                <div className="row">
                  <div className="col-xs-3 form-group">
                    <FormLabel label="Code" field={code} submitFailed={submitFailed} uppercase={false} />
                    <input
                      name="code"
                      type="text"
                      className={classnames('form-control', isErrorClassnames(code))}
                      value={code?.value}
                      onChange={(e) => {
                        onFieldValueUpdated({ code: e.target.value.toUpperCase() });
                      }}
                      disabled={isProcessing}
                    />
                    {code.isInvalid &&
                      code.invalidReason !== 'code is a required field' && ( // we don't need to show required error message, red border is enough
                        <div className={Styles.errorMessage}>{code.invalidReason}</div>
                      )}
                  </div>
                  <div className="col-xs-7 form-group">
                    <FormLabel label="Subject" field={code} submitFailed={submitFailed} uppercase={false} />
                    <input
                      name="description"
                      type="text"
                      className={classnames('form-control', isErrorClassnames(description))}
                      value={description?.value}
                      onChange={(e) => {
                        onFieldValueUpdated({ description: e.target.value });
                      }}
                      disabled={isProcessing}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-3 form-group">
                    <FormLabel label="Type" field={entryType} submitFailed={submitFailed} uppercase={false} />
                    <StatelessSelect
                      className={classnames(isErrorClassnames(entryType))}
                      selectedOption={entryType?.value}
                      options={entryTypeOptions}
                      onChange={(selectedType) => {
                        onFieldValueUpdated({ entryType: selectedType.value });
                      }}
                      disabled={isProcessing}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6 form-group">
                    <div className={Styles.toggleWrapper}>
                      <SlidingToggle
                        scope={isBillable?.key}
                        onChange={(name, value) => {
                          onFieldValueUpdated({ isBillable: value });
                        }}
                        selected={isBillable?.value}
                      />
                      <div onClick={() => onFieldValueUpdated({ isBillable: !isBillable?.value })}>
                        This task code is billable
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className={Styles.formFooter}>
                <div>
                  <Button
                    locked={formSubmitting} // spinner
                    disabled={!formDirty || isProcessing}
                    className={Styles.saveButton}
                    size="full-width"
                    onClick={onSave}
                  >
                    Save
                  </Button>
                  <Button
                    disabled={isProcessing}
                    className={Styles.cancelButton}
                    size="full-width"
                    type="secondary"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  {id?.value && (
                    <Button
                      disabled={isProcessing}
                      className={Styles.deleteButton}
                      size="full-width"
                      type="danger-alt"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              </div>
            </FloatingCard>
            {showDeleteModal && (
              <CustomTaskCodeDeleteModal
                codeId={id.value}
                onDelete={onDelete}
                onCloseModal={() => setShowDeleteModal(false)}
                isLocked={isDeleting}
              />
            )}
          </>
        )}
      </div>
    );
  },
);

AddEditCustomTaskCode.displayName = 'AddEditCustomTaskCode';

const { Forms2Field } = forms2PropTypes;

AddEditCustomTaskCode.propTypes = {
  // form fields
  id: Forms2Field,
  code: Forms2Field,
  description: Forms2Field,
  entryType: Forms2Field,
  isBillable: Forms2Field,
  // form state
  formDirty: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  // func & callbacks
  onClose: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // state
  isDeleting: PropTypes.bool,
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func.isRequired,
};

AddEditCustomTaskCode.defaultProps = {
  id: undefined,
  code: undefined,
  description: undefined,
  entryType: undefined,
  isBillable: undefined,
  isDeleting: false,
  showDeleteModal: false,
};

export { AddEditCustomTaskCode };
