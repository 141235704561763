import { gql } from '@apollo/client';
import { invoiceTemplateFragment } from '../fragments/invoiceTemplateFragment.graphql';

const query = gql`
  query DraftInvoice($matterId: ID!, $filter: MatterFilter) {
    matter(id: $matterId, filter: $filter) {
      id
      matterTypeId
      matterNumber
      description
      status
      clientDisplay
      clients {
        id
        displayName
      }
      billingConfiguration {
        id
        debtors {
          id
          displayName
        }
        isUtbmsEnabled
        defaultTrustAccountId
        minimumTrustRetainerActive
        minimumTrustRetainerAmount
        trustRetainerReplenishAmount
      }
      otherSideDisplay
      matterType {
        matterTypeId
        name
        locationId
      }
    }
    matterInvoiceSettings(ids: [$matterId]) {
      id
      titleLine1Option
      titleLine1CustomText
      titleLine1Overridden
      titleLine2Option
      titleLine2CustomText
      titleLine2Overridden
      footer
      footerOverridden
      surcharge {
        type
        fixedSurcharge
        percentageBp
        description
        applyTo
      }
      surchargeOverridden
      eInvoiceEnabledOption
      payButtonEnabledOption
      showRetainerOption
      template {
        ...invoiceTemplateFragment
      }
      lastUpdated
    }
  }

  ${invoiceTemplateFragment}
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoice = {
  query,
  notificationIds,
};
