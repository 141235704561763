import { getAllStaffWithUserIds } from '@sb-firm-management/redux/firm-management';
import { mapStaffMember } from '../mappers';

const sortStaff = (a, b) => {
  const textA = `${a.firstName || ''}${a.middleName || ''}${a.lastName || ''}`;
  const textB = `${b.firstName || ''}${b.middleName || ''}${b.lastName || ''}`;

  if (textA < textB) {
    return -1;
  }

  if (textA > textB) {
    return 1;
  }

  return 0;
};

export const clientSdkGetAllStaff = async () => {
  const allStaff = getAllStaffWithUserIds();
  const mapped = (allStaff || []).map(mapStaffMember);

  return mapped.sort(sortStaff);
};
