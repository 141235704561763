export { opdateCache, rollbackOpdateCache, updateCache, clearCache } from './cache';

/**
 * Saves the attachment files in InvoiceSettingsTemplateFiles entity
 * @param {object} param -- function parameters as object
 * @param {object} param.store - scoped redux store
 * @param {string} param.templateId - template id
 * @param {object} param.attachment - attachment details
 * @param {string} param.attachment.location - aws s3 path for the attachment
 * @param {string} param.attachment.fileName - name of the attachment
 * @param {function} param.fetchPostP - to make fetch call to the endpoint
 * @param {function} param.opdateCache - to be able to make optimistic updates
 * @param {function} param.rollbackOpdateCache - to be able to rollback optimistic updates
 */
export const saveInvoiceSettingsTemplateFiles = ({
  store,
  templateId,
  attachment,
  fetchPostP,
  opdateCache,
  rollbackOpdateCache,
}) => {
  const thunk = async () => {
    const templateFiles = {
      id: templateId,
      attachment,
    };

    opdateCache({ optimisticEntities: [{ ...templateFiles }] });

    try {
      const path = `/billing/invoice-settings-template-files/:accountId/${templateId}`;
      const fetchOptions = { body: JSON.stringify(templateFiles) };
      const response = await fetchPostP({ path, fetchOptions });
      return response;
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: [{ ...templateFiles }] });
      throw err;
    }
  };

  return store.dispatch(thunk);
};

/**
 * Uploads the pdf file to AWS S3 and saves the settings.
 * @param {object} param -- function parameters as object
 * @param {object} param.store - scoped redux store
 * @param {string} param.templateId - template id
 * @param {string} param.pdfFile - pdf file to upload
 * @param {string} param.fileName - pdf file name that is being uploaded
 * @param {function} param.fetchPostP - to make fetch call to the endpoint
 * @param {function} param.opdateCache - to be able to make optimistic updates
 * @param {function} param.rollbackOpdateCache - to be able to rollback optimistic updates
 */
export const uploadAttachmentP = ({
  store,
  templateId,
  pdfFile,
  fileName,
  fetchPostP,
  opdateCache,
  rollbackOpdateCache,
}) => {
  const thunk = async () => {
    const path = `/billing/invoice-pdf-attachment/:accountId/${templateId}`;
    const options = { pdfFile };
    const fetchOptions = { dataType: 'json', body: JSON.stringify(options) };

    // Uploading pdf file
    const res = await fetchPostP({ path, fetchOptions });
    const location = res && res.body && res.body.pdfS3Key;
    const attachment = {
      location,
      fileName,
    };

    // Saving the file details in settings
    await saveInvoiceSettingsTemplateFiles({
      store,
      templateId,
      attachment,
      fetchPostP,
      opdateCache,
      rollbackOpdateCache,
    });

    return attachment;
  };

  return store.dispatch(thunk);
};
