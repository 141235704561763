/* eslint-disable no-restricted-syntax */
import { TRoleMapping, TContact } from './raw-communicate-types';

type TContactId = string;
type TMatterId = string;
type TRoleDisplay = string;

const roleCache: Record<TContactId, Record<TMatterId, TRoleDisplay[]>> = {};
const contactCache: Record<TContactId, TContact> = {};
const groupCache: Record<TContactId, TContactId[]> = {};

// replaces all TRoleMapping for a matter in the cache, so should contain all roles for matter
export const setMatterRoles = (matterId: TMatterId, roles: TRoleMapping[]) => {
  // Every time this is called we need to clear the distinct matter cache on the role
  // exactly one time, below can be optimised but it loses clarity.
  // Note the same contact can have multiple roles on a matter.

  // for each contactId in roles set tracked matterRoles to be empty
  for (const r of roles) {
    roleCache[r.contactId] ??= {};
    roleCache[r.contactId][matterId] = [];
  }

  // now populate all matterRoles for the contact
  for (const r of roles) {
    roleCache[r.contactId][matterId].push(r.role);
  }
};

export const getMatterRolesForContact = (contactId: TContactId) => {
  if (!roleCache[contactId]) {
    return {};
  }
  const result: Record<TMatterId, Record<TContactId, TRoleDisplay[]>> = {};

  const matterIds = Object.keys(roleCache[contactId]);
  for (const matterId of matterIds) {
    result[matterId] ??= {};
    result[matterId][contactId] ??= [];
    const roles = roleCache[contactId][matterId];
    if (roles && roles.length) {
      result[matterId][contactId].push(...roles);
    }
  }

  return result;
};

export const updateContacts = (...contacts: TContact[]) => {
  for (const c of contacts) {
    contactCache[c.id] = c;
  }
};

export const getContacts = (...contactIds: TContactId[]) =>
  contactIds.map((contactId) => contactCache[contactId]).map(Boolean);

export const addSubContacts = (groupOfPeopleId: TContactId, customers: TContactId[]) => {
  groupCache[groupOfPeopleId] = customers;
};

export const getSubContacts = (contactId: TContactId) => groupCache[contactId] || null;

export const isContactRequested = (contactId: string) => !!contactCache[contactId];
