/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spinner, useTranslation } from '@sb-itops/react';
import { MattersDisplayFromMatters } from '@sb-matter-management/react';
import React, { useRef } from 'react';
import classNames from 'classnames';
import iconMatter from 'web/assets/icon-matter.svg';
import { WidgetTitle, getTextSize, useOutsideAlerter } from './libs';
import * as WidgetStyles from './Widgets.module.scss';

export const NewMattersWidget = ({
  isEditing,
  matters,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  count,
  setShowMatterList,
  showMatterList,
}) => {
  const widgetRef = useRef(null);
  const { t } = useTranslation();
  const alerterId = `widget-matters-list-open-${settings.id}`;
  const counterId = `widget-matters-list-counter-id-${settings.id}`;
  useOutsideAlerter(widgetRef, () => setShowMatterList(false), [alerterId, counterId]);
  const renderMatter = (matter) => (
    <div
      key={matter.id}
      className={WidgetStyles.itemCard}
      onClick={() => onClickLink({ type: 'matter', id: matter.id })}
    >
      <div>
        <img src={iconMatter} alt="matter icon" className={`${WidgetStyles.largeIcon}`} />
      </div>
      <div className={WidgetStyles.textBox}>
        <div className={classNames(WidgetStyles.overflowText)}>{matter.clientDisplayName}</div>
        <MattersDisplayFromMatters
          onClickLink={onClickLink}
          className={WidgetStyles.blueGray}
          showClient={false}
          matters={[matter]}
        />
      </div>
      <div
        className={classNames(
          WidgetStyles.overflowText,
          WidgetStyles.alignRight,
          WidgetStyles.noShrink,
          WidgetStyles.blueGray,
        )}
      >
        {t('date', { ts: matter.matterStarted })}
      </div>
    </div>
  );
  return (
    <div className={classNames(WidgetStyles.container, isEditing && WidgetStyles.isEditing)}>
      <WidgetTitle
        isEditing={isEditing}
        onClick={() => onClickLink({ type: 'matterList' })}
        onChange={onChange}
        onRemove={onRemove}
        settings={settings}
      />
      {loading ? (
        <div className={WidgetStyles.loading}>
          <Spinner small />
        </div>
      ) : (
        [
          (!settings.style?.value || settings.style?.value === 'list') && (
            <div key="list" className={WidgetStyles.content}>
              {matters.length === 0 && 'No matters to display'}
              {matters.map(renderMatter)}
            </div>
          ),
          settings.style?.value === 'number' && (
            <div
              key="count"
              onClick={(e) => {
                e.stopPropagation();
                setShowMatterList(!showMatterList);
              }}
              id={alerterId}
              className={classNames(WidgetStyles.countContent, WidgetStyles.hoverTooltip, getTextSize(settings.size))}
            >
              <h1 id={counterId} className={WidgetStyles.hOne}>
                {count}
              </h1>
              NEW MATTERS
              <div
                className={classNames(
                  settings.position.x > 5 ? WidgetStyles.left : WidgetStyles.right,
                  showMatterList && WidgetStyles.display,
                )}
                ref={widgetRef}
              >
                <div className={WidgetStyles.content}>
                  {matters.length === 0 && 'No matters to display'}
                  {matters.map(renderMatter)}
                </div>
                <i className={WidgetStyles.tab} />
              </div>
            </div>
          ),
        ]
      )}
      <div className={WidgetStyles.separator} />
      <a className={WidgetStyles.footerAction} onClick={() => onClickLink({ type: 'addMatter' })}>
        ADD MATTER
      </a>
    </div>
  );
};
