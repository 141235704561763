'use strict';

const depositRequestDefaults = {
  DEPOSIT_REQUEST_EMAIL_SUBJECT: 'Deposit for [MATTER_NAME]',
  DEPOSIT_REQUEST_EMAIL_MESSAGE_FN: (t) =>
    '<p>Dear [DEBTOR_NAME],</p>' +
    '<p><br></p>' +
    `<p>Please use the below link to make a payment of [AMOUNT_REQUESTED] into our ${t(
      'trustAccount',
    )} which will be applied as a retainer on your account.</p>` +
    '<p><br></p>' +
    '<p>Amount: [AMOUNT_REQUESTED]</p>' +
    '<p>Reason: [REQUEST_REASON]</p>' +
    '<p><br></p>' +
    '<p>[PAY_NOW_BUTTON]</p>' +
    '<p><br></p>' +
    '<p>Kind regards,</p>' +
    '<p>[USER_NAME]</p>' +
    '<p>[FIRM_NAME]</p>' +
    '<p>[PHONE_NUMBER]</p>',
  DO_NOT_SEND_REPLENISH_EMAIL_TO_USER: false,
};

module.exports = {
  depositRequestDefaults,
};
