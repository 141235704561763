import { getById as getInvoiceTotalsById } from '@sb-billing/redux/invoice-totals';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';

function getInvoicesThatCanBePaidByTrust(invoices, allNonZeroTrustMatterBalancesMap) {
  const invoicesThatCanBePaidByTrust = invoices.reduce((acc, invoice) => {
    if (allNonZeroTrustMatterBalancesMap[invoice.matterId]) {
      const currentVersion = invoice.currentVersion;
      if (currentVersion && currentVersion.status === 'FINAL') {
        const invoiceTotals = getInvoiceTotalsById(invoice.invoiceId);
        if (invoiceTotals && invoiceTotals.unpaidExcInterest > 0) {
          acc.push({
            invoiceId: invoice.invoiceId,
            matterId: invoice.matterId,
            accountId: invoice.accountId,
            debtorIds: currentVersion.debtors.map((debtor) => debtor.id),
            issuedDate: currentVersion.issuedDate,
            dueDate: currentVersion.dueDate,
            totalDue: invoiceTotals.unpaid,
            trustBalance: allNonZeroTrustMatterBalancesMap[invoice.matterId][balanceTypes.AVAILABLE],
            status: currentVersion.status,
            currentVersion, // this is used for filtering, allows us to reuse existing invoice filter functions
          });
        }
      }
    }
    return acc;
  }, []);
  return invoicesThatCanBePaidByTrust;
}

export { getInvoicesThatCanBePaidByTrust };
