import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useInvoiceZeroBalance, withReduxStore } from '@sb-itops/react';
import { sort } from '@sb-itops/sort';
import { getInvoiceDebtorIds } from '@sb-billing/redux/invoices';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getMap as getBankAccountMap } from '@sb-billing/redux/bank-account-balances';
import * as bankAccountBalances from '@sb-billing/redux/bank-account-balances.2';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import FinaliseWithPaymentsTable from './FinaliseWithPaymentsTable';

const { getMatterContactBalances, getAllMatterBalances } = bankAccountBalances.selectors;

const hooks = () => ({
  useInvoiceZeroBalance,
  useMattersWithPayments: () => {
    const [mattersWithPayments, setMattersWithPayments] = useState(undefined);

    return {
      mattersWithPayments,
      setMattersWithPayments,
    };
  },
});

const mapStateToProps = (state, props) => getState(state, props);

// NOTE: the majority of this file shouldnt exist - ive done the data collection too low in the tree and realised it
// too late into the project.
// Fix is to move the data collection upwards and to be a part of a selector, but this takes time that i dont
// have at the moment

function getState(
  state,
  { invoices, sortBy, sortDirection, expanded, selected, payments, autoAllocations, bankAccountIds },
) {
  const matterBalancesByMatterId = getAllMatterBalances(getBankAccountMap());
  const matterIdToInvoices = invoices.reduce((matterMap, invoice) => {
    if (matterMap[invoice.matterId]) {
      matterMap[invoice.matterId].push(invoice);
    } else {
      // eslint-disable-next-line no-param-reassign
      matterMap[invoice.matterId] = [invoice];
    }
    return matterMap;
  }, {});

  const matterIdToInvoiceRows = Object.entries(matterIdToInvoices).reduce((acc, [matterId, matterInvoices]) => {
    // we always generate the invoice rows as a selected, hidden invoice row
    // with an error should block finalisation
    const invoiceRows = getInvoiceRows(
      sort(matterInvoices, ['issuedDate', 'validFrom'], ['asc', 'asc']),
      selected,
      bankAccountIds,
      payments,
      // a matter wont have a balance if a transaction hasnt been made for it
      matterBalancesByMatterId[matterId] || {},
      // if a matter has no autoAllocation value it is a new matter, which we default to being auto allocated.
      // otherwise, take the user specified autoAllocation value
      autoAllocations[matterId] === undefined || autoAllocations[matterId],
    );

    acc[matterId] = invoiceRows;
    return acc;
  }, {});

  const matterRows = getMatterRows(
    matterIdToInvoiceRows,
    expanded,
    matterBalancesByMatterId,
    bankAccountIds,
    autoAllocations,
  );
  const sortedMatterRows = sort(matterRows, [sortBy], [sortDirection]);
  const rows = sortedMatterRows.reduce((acc, matterRow) => {
    acc.push(matterRow);
    if (matterRow.isExpanded) {
      acc.push(...matterIdToInvoiceRows[matterRow.id]);
    }
    return acc;
  }, []);

  return {
    rows,
    bankAccountIds,
    // TODO this is only here as i have this container doing data collection, when it shouldnt be
    matterRows,
    matterIds: Object.keys(matterIdToInvoices),
    invoiceIds: Object.values(matterIdToInvoices)
      .flat()
      .map((invoice) => invoice.invoiceId),
    allExpanded: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isExpanded),
    allSelected: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isSelected),
    isError: matterRows.some((matterRow) => matterRow.trustIsError || matterRow.operatingIsError),
  };
}

function getMatterRows(matterIdToInvoiceRows, expanded, matterBalances, bankAccountIds, autoAllocations) {
  return Object.entries(matterIdToInvoiceRows).map(([matterId, invoiceRows]) => {
    const matterBalance = matterBalances[matterId] || {};
    const invoiceIds = invoiceRows.map((invoiceRow) => invoiceRow.id);
    const isExpanded = expanded[matterId] || false;
    return {
      type: 'MATTER',
      id: matterId,
      isExpanded,
      matterDisplay: getMatterDisplayById(matterId),
      isSelected: invoiceRows.every((invoiceRow) => invoiceRow.isSelected),
      isMultipleContactBalances:
        getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId: bankAccountIds.CREDIT }).length > 1 ||
        getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId: bankAccountIds.TRUST }).length > 1 ||
        getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId: bankAccountIds.OPERATING }).length > 1,
      trustIsError: invoiceRows.some((invoiceRow) => invoiceRow.trustError),
      operatingIsError: invoiceRows.some((invoiceRow) => invoiceRow.operatingError),
      // business rule: Matters added to the list after its already loaded should come in with the auto allocate toggle on
      isAutoAllocated: autoAllocations[matterId] === undefined || autoAllocations[matterId],
      invoiceIds, // these are in order of oldest to newest
      matterBalance,
      // these balance fields are for the view only - they are there only due to lack of time
      creditBalance: matterBalance[bankAccountIds.CREDIT] || 0,
      trustBalance: matterBalance[bankAccountIds.TRUST] || 0,
      operatingBalance: matterBalance[bankAccountIds.OPERATING] || 0,
      totalDue: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.totalDue, 0),
      trustAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.trustAmount, 0),
      operatingAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.operatingAmount, 0, 0),
      creditAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.creditAmount, 0),
    };
  });
}

function getInvoiceRows(invoices, selected, bankAccountIds, payments, matterBalance, isAutoAllocated) {
  let runningMatterCreditBalance = matterBalance[bankAccountIds.CREDIT] || 0;
  let runningMatterTrustBalance = matterBalance[bankAccountIds.TRUST] || 0;
  let runningMatterOperatingBalance = matterBalance[bankAccountIds.OPERATING] || 0;

  return invoices.map((invoice) => {
    const isSelected = selected[invoice.invoiceId];
    const totalDue = getInvoiceDue(invoice.invoiceId);
    const creditAmount = isSelected ? getPayment(payments, bankAccountIds.CREDIT, invoice.invoiceId) : 0;
    const trustAmount = isSelected ? getPayment(payments, bankAccountIds.TRUST, invoice.invoiceId) : 0;
    const operatingAmount = isSelected ? getPayment(payments, bankAccountIds.OPERATING, invoice.invoiceId) : 0;

    const creditError =
      isSelected &&
      (getIsPaymentError(creditAmount, runningMatterCreditBalance, totalDue) ||
        creditAmount + trustAmount + operatingAmount > totalDue);
    const trustError =
      isSelected &&
      (getIsPaymentError(trustAmount, runningMatterTrustBalance, totalDue) ||
        creditAmount + trustAmount + operatingAmount > totalDue);
    const operatingError =
      isSelected &&
      (getIsPaymentError(operatingAmount, runningMatterOperatingBalance, totalDue) ||
        creditAmount + trustAmount + operatingAmount > totalDue);

    const creditBalance = runningMatterCreditBalance;
    const trustBalance = runningMatterTrustBalance;
    const operatingBalance = runningMatterOperatingBalance;
    runningMatterCreditBalance -= isSelected ? creditAmount : 0;
    runningMatterTrustBalance -= isSelected ? trustAmount : 0;
    runningMatterOperatingBalance -= isSelected ? operatingAmount : 0;
    const debtorIds = getInvoiceDebtorIds(invoice);

    return {
      type: 'INVOICE',
      id: invoice.invoiceId,
      debtorIds,
      totalDue,
      issueDate: invoice.issueDate,
      isSelected,
      matterId: invoice.matterId,
      creditAmount,
      trustAmount,
      operatingAmount,
      creditBalance,
      trustBalance,
      operatingBalance,
      creditError,
      trustError,
      operatingError,
      isAutoAllocated,
      debtorDisplay: debtorIds.map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true })).join(' | '),
    };
  });
}

function getIsPaymentError(paymentAmount, matterBalance, invoiceDue) {
  return !!paymentAmount && (paymentAmount < 0 || paymentAmount > matterBalance || paymentAmount > invoiceDue);
}

function getPayment(payments, bankAccountId, invoiceId) {
  const paymentsByInvoiceId = payments[invoiceId];

  if (!paymentsByInvoiceId) {
    return 0;
  }

  return paymentsByInvoiceId[bankAccountId] || 0;
}

// while there is a business rule that every invoice has an invoiceTotals entity,
// in practise this is not the case due to race conditions
function getInvoiceDue(invoiceId) {
  try {
    return getTotalsForInvoiceId(invoiceId).unpaidExcInterest;
  } catch (err) {
    // log the error.message as a normal log as its normal flow that getting the totals will fail with an
    // error thrown. Not using the log module as we dont want to swamp the server with this kind of error
    // messaging
    // eslint-disable-next-line no-console
    console.log('finalise with payments table: getting invoice due', err.message);
    return 0;
  }
}

const FinaliseWithPaymentsTableContainer = withReduxStore(
  composeHooks(hooks)(connect(mapStateToProps)(FinaliseWithPaymentsTable)),
);

FinaliseWithPaymentsTableContainer.displayName = 'FinaliseWithPaymentsTableContainer';

FinaliseWithPaymentsTableContainer.propTypes = {
  invoices: PropTypes.any.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  expanded: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  payments: PropTypes.any.isRequired,
  autoAllocations: PropTypes.any.isRequired,
  bankAccountIds: PropTypes.shape({
    TRUST: PropTypes.string,
    OPERATING: PropTypes.string,
    CREDIT: PropTypes.string,
  }).isRequired,
};
FinaliseWithPaymentsTableContainer.defaultProps = {};

export default FinaliseWithPaymentsTableContainer;
