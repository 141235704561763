'use strict';

const { getMatterStatusOptions } = require('./get-matter-status-options');

function isEditableMatter(matter) {
  if (!matter) {
    return false;
  }

  return ['Pending', 'Open', 'Closed'].includes(matter.status);
}

function isEmptyMatter(matter) {
  if (!matter) {
    return true;
  }

  return !matter.matterTypeId || !(matter.entityIdsOnMatter && matter.entityIdsOnMatter.length);
}
/**
 * Note:
 * For purpose of this function, matter is closed if it has status of 'Closed', 'Cancelled' or 'Deleted'.
 *
 * @param {Object} matter
 * @returns Boolean
 */
function isClosedMatter(matter) {
  if (!matter) {
    return false;
  }

  return ['Closed', 'Cancelled', 'Deleted'].includes(matter.status);
}

function getMatterDisplay(
  matter,
  matterTypeName,
  showStatus = false,
  showDescription = false,
  showOtherSide = true,
  showMatterNumber = true,
  showClient = true,
) {
  if (!matter) {
    return '';
  }

  const matterStatus = showStatus && matter.status ? matter.status : null;
  const matterDescription = showDescription && matter.description ? matter.description : null;
  const otherSide = showOtherSide ? matter.otherSideDisplayName || matter.otherSideDisplay : null;
  const matterNumber = showMatterNumber && matter.matterNumber ? matter.matterNumber : null;
  const client = showClient ? matter.clientDisplayName || matter.clientDisplay : null;
  const segments = [matterNumber, client, matterTypeName, otherSide, matterStatus, matterDescription];

  return segments.filter((segment) => segment).join(' - ');
}

/**
 * The debtor id to use when no other debtor exists
 * @param {*} matter
 * @returns {string|undefined}
 */
function getDefaultDebtorId(matter) {
  return getDebtorId(matter);
}

/**
 * @deprecated use getDefaultDebtorId
 * @param {*} matter
 * @returns {string|undefined}
 */
function getDebtorId(matter) {
  if (!matter) {
    return undefined;
  }

  return matter.clientCustomerIds && matter.clientCustomerIds[0];
}

function getDebtors(matter) {
  if (!matter) {
    throw new Error('no matter supplied');
  }

  const customerId = getDebtorId(matter);
  if (customerId) {
    return [{ id: customerId, ratio: 1 }];
  }

  return [];
}

function getStatusFilter(statuses) {
  const statusesToKeep = statuses.reduce((statusMap, status) => {
    // eslint-disable-next-line no-param-reassign
    statusMap[status.toLowerCase()] = true;
    return statusMap;
  }, {});

  return (matter) => {
    if (!matter || !matter.status) {
      return false;
    }

    return !!statusesToKeep[matter.status.toLowerCase()];
  };
}

function getMatterTypeFilter(matterTypeIds) {
  const matterTypeIdsToKeep = matterTypeIds.reduce((matterTypeIdMap, matterTypeId) => {
    // eslint-disable-next-line no-param-reassign
    matterTypeIdMap[matterTypeId] = true;
    return matterTypeIdMap;
  }, {});

  return (matter) => {
    if (!matter || !matter.matterTypeId) {
      return false;
    }

    return !!matterTypeIdsToKeep[matter.matterTypeId];
  };
}

function getDebtorFilter(debtorIds) {
  const debtorIdsToKeep = debtorIds.reduce((debtorIdMap, debtorId) => {
    // eslint-disable-next-line no-param-reassign
    debtorIdMap[debtorId] = true;
    return debtorIdMap;
  }, {});

  return (matter) => {
    if (!matter) {
      return false;
    }

    const debtorId = getDebtorId(matter);

    if (!debtorId) {
      return false;
    }

    return !!debtorIdsToKeep[debtorId];
  };
}

function getAttorneyResponsibleFilter(staffIds) {
  const staffIdsToKeep = staffIds.reduce((staffIdMap, staffId) => {
    // eslint-disable-next-line no-param-reassign
    staffIdMap[staffId] = true;
    return staffIdMap;
  }, {});

  return (matter) => {
    if (!matter || !matter.attorneyResponsibleId) {
      return false;
    }

    return !!staffIdsToKeep[matter.attorneyResponsibleId];
  };
}

module.exports = {
  isEditableMatter,
  isEmptyMatter,
  isClosedMatter,
  getMatterDisplay,
  getDebtors,
  getDebtorId,
  getDefaultDebtorId,
  getStatusFilter,
  getMatterStatusOptions,
  getMatterTypeFilter,
  getDebtorFilter,
  getAttorneyResponsibleFilter,
};
