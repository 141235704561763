import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { createSelector } from 'reselect';

import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'expenses',
  keyPath: 'expenseId',
  ngCacheName: 'sbExpenseService',
  syncType: syncTypes.SINCE,
  immutable: false,
  changesetProcessor: ({ entities }) =>
    entities.filter((newExpense) => {
      const existingExpense = getById(newExpense.expenseId);

      // Fee entities use similar logic

      return (
        !existingExpense || // always accept an expense not cached locally
        newExpense.versionIds.includes(existingExpense.expenseVersionId) || // server has seen our version
        newExpense?.rejectedVersionIds?.includes(existingExpense.expenseVersionId) // server has seen our version
      );
    }),
});

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbExpenseService',
  keyPath: 'expenseId',
});

export const { getById, getMap, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;

// selectors
const getListSelector = createSelector(
  (state) => state || {},
  (state) => Object.values(state).filter((expense) => !expense.isDeleted),
);

export const getList = () => getListSelector(getMap());

// TODO: Check if we need any version other than the latest on the frontend
// if not retire, the issue with using this is the version doesn't have
// some information like operatingChequeId
export const getExpenseByIdAndVersion = (id, versionId) => {
  const expense = getById(id);
  if (versionId && expense && expense.versionHistory) {
    return expense.versionHistory.find((expenseVersion) => expenseVersion.expenseVersionId === versionId);
  }
  return expense;
};

export const getExpensesByMatterId = (matterId) => getList().filter((expenses) => expenses.matterId === matterId);
