// This is extracted to own function as it is likely this may be region dependent in future

const SAMPLE_NATIONAL_ID = '123456789';

export const addExtraDataToMarshalled = ({ marshalledData, isNonProdAppEnv }) => {
  if (!isNonProdAppEnv) {
    return marshalledData;
  }

  const data = JSON.parse(JSON.stringify(marshalledData));

  const roles = (data.roles || []).map((role) =>
    // We don't provide natinal_id as a field so it should not exist but we test for it just in case
    role.national_id ? role : Object.assign(role, { national_id: SAMPLE_NATIONAL_ID }),
  );
  data.roles = roles;

  return data;
};
