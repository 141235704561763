angular.module('sb.billing.webapp').directive('sbActionPrint', function () {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    'link': function (scope, element, attrs, ctrl) {
      ctrl.group.setAction(print);

      function print(selector) {
        var printElement = angular.element(selector)[0];
        if (printElement) {
          printElement.contentWindow.print();
        }
      }
    }
  };
});
