angular.module('sb.billing.webapp').directive('sbActionAddContacts', function ($timeout, sbLoggerService, sbSimpleContactMbService, sbMessageDisplayService) {
  'use strict';

  return {
    'restrict': 'A',
    'require': {group: 'sbComposeGroup'},
    link: function (scope, element, attrs, ctrl) {
      const log = sbLoggerService.getLogger('sbActionAddContacts');

      ctrl.group.setAction(addContacts);

      function addContacts(contact) {
        log.info('add contacts', contact);
        ctrl.group.setLock(true);

        return sbSimpleContactMbService.saveContactP(contact)
          .then(() => {
            log.info('saved contact');
            $timeout(() => {
              ctrl.group.setLock(false);
            }, 3000);
          })
          .catch((err) => {
            log.error('Failed to add contact', err);
            ctrl.group.setLock(false);

            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text('Failed to add contact')
                .conditionalText(': {0}', err && err.message)
            );

            throw err;
          });
      }
    }
  };
});
