'use strict';

const productionPublicAssetsHostUrl = 'https://prod-boost-data-public.s3.amazonaws.com';
const stagingPublicAssetsHostUrl = 'https://staging-boost-data-public.s3.amazonaws.com';
const devPublicAssetsHostUrl = 'https://dev-boost-data-public.s3.amazonaws.com';

function getPublicAssetsHostUrl({ appEnv }) {
  switch (appEnv) {
    case 'QA':
    case 'PRODUCTION':
      return productionPublicAssetsHostUrl;
    case 'LOCAL':
      return `https://${process.env.DATA_ENV}-boost-data-public.s3.amazonaws.com`
    case 'RC':
      return stagingPublicAssetsHostUrl;
    case 'DEV':
    default:
      return devPublicAssetsHostUrl;
  }
}

module.exports = {
  getPublicAssetsHostUrl,
};
