import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { capitalizeAllWords } from '@sb-itops/nodash';
import { LinkableText, LoadingBarInfinite, Modal, useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { ContactDisplay2 } from '@sb-customer-management/react';
import { UserNameDisplay } from 'web/components';
import { DebugEntity, OperatingChequeExpenseTable } from 'web/react-redux';

import * as Styles from './OperatingChequeDetailsModal.module.scss';

export const OperatingChequeDetailsModal = ({
  chequeId,
  amount,
  effectiveDate,
  payTo,
  createdBy,
  systemDate,
  reversalReason,
  memo,
  expenses,
  isReversed,
  // other
  isLoading,
  isVisible,
  isReverseCollapsed,
  reasonInputRef,
  // form fields, for reason input
  reasonField,
  submitFailed,
  formInitialised,
  // function/callbacks,
  onReasonChangedHandler,
  toggleReversalUI,
  onClose,
  onProcessReversal,
  onValidateForm,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      className={Styles.operatingChequeDetailsModal}
      modalHeaderClassName={Styles.modalHeader}
      modalBodyClassName={Styles.modalBody}
      isVisible={isVisible}
      title={capitalizeAllWords(`${t('cheque')} Details`)}
      body={
        isLoading || !formInitialised ? (
          <div className={Styles.loadingBarContainer}>
            <LoadingBarInfinite loading={isLoading} />
          </div>
        ) : (
          <div className={Styles.formWrapper}>
            <div className={Styles.operatingChequeDetails}>
              <DebugEntity entityCacheName="sbOperatingChequesService" entityId={chequeId} />

              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Amount</div>
                <div className="col-md-7">
                  <span>{t('cents', { val: amount })}</span>
                </div>
              </div>

              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Date</div>
                <div className="col-md-7">{t('date', { yyyymmdd: effectiveDate })}</div>
              </div>

              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Paid To</div>
                <div className="col-md-7">
                  <ContactDisplay2 contact={payTo} />
                </div>
              </div>

              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Processed by</div>
                <div className="col-md-7">
                  <UserNameDisplay user={createdBy?.person} inline /> on{' '}
                  {t('date', { ts: systemDate, format: 'YYYY-MM-DD' })} at{' '}
                  <span className="uppercase">{t('date', { ts: systemDate, format: 'hhmmss a' })}</span>
                </div>
              </div>

              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Source</div>
                <div className="capitalise col-md-7">{t('operatingCheque')}</div>
              </div>

              {isReversed && (
                <div className={classnames(Styles.columnLeft, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Reason</div>
                  <div className="col-md-7">{reversalReason}</div>
                </div>
              )}

              {hasFacet(facets.chequeMemo) && (
                <div className={classnames(Styles.columnRight, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Check Memo</div>
                  <span className="col-md-7">{memo}</span>
                </div>
              )}

              <div className={classnames(Styles.columnBoth, Styles.expenseTable)}>
                <OperatingChequeExpenseTable expenses={expenses} />
              </div>

              {!isReversed && (
                <div className={Styles.columnBoth}>
                  <div className={classnames('row', Styles.row)}>
                    <div className="col-md-12">
                      <LinkableText
                        text="Reverse"
                        onClickLink={() => toggleReversalUI({ isDelete: false })}
                        asLink
                        inline
                      />
                      {hasFacet(facets.deleteTransaction) && (
                        <span className={Styles.reversalSpacer}>
                          <span className={Styles.reversalSpacer}>|</span>
                          <LinkableText
                            text="Delete"
                            onClickLink={() => toggleReversalUI({ isDelete: true })}
                            asLink
                            inline
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  {!isReverseCollapsed && (
                    <div>
                      <form name="reversalForm">
                        <div className={classnames('row', Styles.row)}>
                          <div className="col-md-12">
                            <label>Reason</label>
                            <div>
                              <input
                                type="text"
                                name="reason"
                                className={classnames(
                                  'form-control',
                                  (reasonField?.isDirty || submitFailed) && reasonField?.isInvalid && Styles.hasError,
                                )}
                                value={reasonField?.value}
                                onBlur={onValidateForm}
                                onChange={onReasonChangedHandler}
                                ref={reasonInputRef}
                              />
                            </div>
                          </div>
                        </div>

                        <div className={classnames('row', Styles.row)}>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className={classnames('btn btn-primary', Styles.reverseButtons)}
                              onClick={onProcessReversal}
                            >
                              Process
                            </button>
                          </div>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className={classnames('btn btn-primary', Styles.reverseButtons)}
                              onClick={onClose}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }
      showFooter={false}
      onModalClose={onClose}
    />
  );
};

OperatingChequeDetailsModal.displayName = 'OperatingChequeDetailsModal';

OperatingChequeDetailsModal.propTypes = {
  chequeId: PropTypes.string,
  amount: PropTypes.number,
  effectiveDate: PropTypes.number,
  payTo: PropTypes.object,
  createdBy: PropTypes.object,
  systemDate: PropTypes.string,
  reversalReason: PropTypes.string,
  memo: PropTypes.string,
  expenses: PropTypes.array,
  isReversed: PropTypes.bool,
  // other
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  isReverseCollapsed: PropTypes.bool,
  reasonInputRef: PropTypes.object.isRequired,
  reasonField: PropTypes.object,
  submitFailed: PropTypes.bool,
  formInitialised: PropTypes.bool,
  // function/callbacks,
  onReasonChangedHandler: PropTypes.func.isRequired,
  toggleReversalUI: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onProcessReversal: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
};

OperatingChequeDetailsModal.defaultProps = {
  chequeId: undefined,
  amount: undefined,
  effectiveDate: undefined,
  payTo: undefined,
  createdBy: undefined,
  systemDate: undefined,
  reversalReason: undefined,
  memo: undefined,
  expenses: undefined,
  isReversed: false,
  isLoading: false,
  isVisible: false,
  isReverseCollapsed: true,
  reasonField: undefined,
  submitFailed: false,
  formInitialised: false,
};
