import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withReduxStore } from '@sb-itops/react';
import { MatterTypeahead } from '@sb-matter-management/react/matter-typeahead';
import { getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';

const mapStateToProps = (state, { excludeIds, ...restOfProps }) => {
  const matterSummaries = getMatterTypeaheadSummaries();

  const matterSummariesFiltered =
    Array.isArray(excludeIds) && excludeIds.length
      ? matterSummaries.filter((item) => !excludeIds.includes(item.id))
      : matterSummaries;

  return {
    ...restOfProps, // unfortunately we have to do this for the bridge
    matterSummaries: matterSummariesFiltered,
  };
};

const MatterTypeaheadWrapper = withReduxStore(connect(mapStateToProps)(MatterTypeahead));

MatterTypeaheadWrapper.displayName = 'MatterTypeaheadWrapper';

MatterTypeaheadWrapper.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onPostBlur: PropTypes.func,
  onClear: PropTypes.func,
  onDisableUpdated: PropTypes.func,
  initialId: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  focusKey: PropTypes.string,
  filter: PropTypes.string,
  hasDisableFlag: PropTypes.bool,
  hasErrorFlag: PropTypes.bool,
  empty: PropTypes.bool,
  hasError: PropTypes.bool,
  excludeIds: PropTypes.array,
};

MatterTypeaheadWrapper.defaultProps = {
  onBlur: undefined,
  onPostBlur: undefined,
  onValueChange: undefined,
  onClear: undefined,
  onDisableUpdated: undefined,
  initialId: undefined,
  placeholder: undefined,
  name: undefined,
  focusKey: undefined,
  filter: undefined,
  hasDisableFlag: false,
  hasErrorFlag: false,
  empty: undefined,
  hasError: false,
  excludeIds: [],
};

export default MatterTypeaheadWrapper;
