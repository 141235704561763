import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { getDotNetHostUrl } from '@sb-itops/environment-config';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';

import quickbooksLogo from './integration-quickbooks.png';

import { IntegrationConnectionPanel } from '../integration-connection-panel';

const getAccountId = () => authSelectors.getAccountId(store.getState());

const QuickbooksLoginButton = memo(({ isConnected }) => {
  const integrationUrl = `${getDotNetHostUrl()}/Integration/quickbooks/QuickbooksHome?accountId=${getAccountId()}`;

  return (
    <IntegrationConnectionPanel
      integration="quickbooks"
      isConnected={isConnected}
      url={integrationUrl}
      connectToIntegration={() => {}}
      logoSrc={quickbooksLogo}
    />
  );
});

QuickbooksLoginButton.propTypes = {
  isConnected: PropTypes.bool.isRequired,
};

QuickbooksLoginButton.displayName = 'QuickbooksLoginButton';

export default QuickbooksLoginButton;
