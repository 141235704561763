import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '@sb-itops/react';
import Styles from './ReportRunnerPeriscope.module.scss';

const ReportRunnerPeriscope = React.memo(({ reportId, periscopeUrl, showLoadingSpinner, onPeriscopeContentReady }) => (
  <div className={Styles.columnFlex}>
    <div className={Styles.columnFlex}>
      {showLoadingSpinner && <Spinner className={Styles.loadingIndicator} />}
      <iframe
        key={reportId}
        title="periscope-report-viewer"
        className={classnames(Styles.columnFlex, showLoadingSpinner ? Styles.hidden : '')}
        src={periscopeUrl}
        onLoad={onPeriscopeContentReady}
      />
    </div>
  </div>
));

ReportRunnerPeriscope.displayName = 'ReportRunnerPeriscope';

ReportRunnerPeriscope.propTypes = {
  reportId: PropTypes.string.isRequired,
  periscopeUrl: PropTypes.string,
  showLoadingSpinner: PropTypes.bool,
  onPeriscopeContentReady: PropTypes.func.isRequired,
};

ReportRunnerPeriscope.defaultProps = {
  showLoadingSpinner: false,
  periscopeUrl: undefined,
};

export default ReportRunnerPeriscope;
