import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getLogger } from '@sb-itops/fe-logger';
import { Spinner } from '../spinner';
import Styles from './ModalDialog.module.scss';
import { Modal } from '../modal';
import { Button, buttonTypes } from '../button';

export const modalDialogButtonsTypes = {
  primary: 'primary',
  secondary: 'secondary',
};

export const modalDialogButtonSizes = {
  medium: Styles.medium,
};

const log = getLogger('@sb-itops/react/modal-dialog');

const ModalDialog = memo(
  ({ visible, body, className, title, buttons, onCloseClick, onPrimaryClick, onSecondaryClick }) =>
    !!visible && (
      <Modal
        title={title}
        className={className}
        body={body}
        footer={
          <div
            className={classNames((buttons.rightAlign && Styles.buttonsRightAlignContainer) || Styles.buttonsContainer)}
          >
            {buttons.customList &&
              buttons.customList.map((customButton, index) => (
                <Button
                  key={`${customButton.text}-${index}`}
                  className={classNames(Styles.button, customButton.size)}
                  {...customButton.props}
                >
                  {customButton.text}
                </Button>
              ))}
            {(buttons.primary.show === undefined || buttons.primary.show === true) && (
              <Button
                className={classNames(Styles.button, buttons.primary.size)}
                {...buttons.primary.props}
                onClick={onPrimaryClick}
              >
                {buttons.primary.submitting && (
                  <>
                    <Spinner small />{' '}
                  </>
                )}
                {buttons.primary.text}
              </Button>
            )}
            {(buttons.secondary.show === undefined || buttons.secondary.show === true) && (
              <Button
                className={classNames(Styles.button, buttons.secondary.size)}
                type={buttonTypes.secondary}
                {...buttons.secondary.props}
                onClick={onSecondaryClick}
              >
                {buttons.secondary.submitting && (
                  <>
                    <Spinner small />{' '}
                  </>
                )}
                {buttons.secondary.text}
              </Button>
            )}
          </div>
        }
        onModalClose={onCloseClick}
      />
    ),
);

ModalDialog.displayName = 'ModalDialog';

export const ButtonPropTypes = PropTypes.shape({
  show: PropTypes.bool,
  text: PropTypes.string,
  size: PropTypes.oneOf(Object.values(modalDialogButtonSizes)),
  props: PropTypes.object,
  submitting: PropTypes.bool,
});

ModalDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.shape({
    rightAlign: PropTypes.bool,
    primary: ButtonPropTypes,
    secondary: ButtonPropTypes,
    customList: PropTypes.arrayOf(ButtonPropTypes),
  }),
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  onCloseClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ModalDialog.defaultProps = {
  buttons: {
    rightAlign: false,
    primary: {
      show: true,
      text: 'Yes',
      props: {},
    },
    secondary: {
      show: true,
      text: 'No',
      props: {},
    },
    customList: [],
  },
  className: undefined,
  onPrimaryClick: undefined,
  onSecondaryClick: () => {
    log.error('onSecondaryClick need to be set if it visible in the MessageDialog');
  },
};

export default ModalDialog;
