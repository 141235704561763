'use strict';

const Yup = require('yup');

const FirmSchema = Yup.object().shape({
  name: Yup.string().required(),
  acn: Yup.string(),
  abn: Yup.string(),
  phoneNumber: Yup.string(),
  phoneAreaCode: Yup.string(),
  faxNumber: Yup.string(),
  faxAreaCode: Yup.string(),
  mailingAddress: Yup.object().shape({
    addressLine1: Yup.string().nullable(true),
    addressLine2: Yup.string().nullable(true),
    city: Yup.string().nullable(true),
    state: Yup.string().nullable(true),
    county: Yup.string().nullable(true),
    locality: Yup.string().nullable(true),
    country: Yup.string().nullable(true),
    zipCode: Yup.string().nullable(true),
    careOf: Yup.string().nullable(true),
    boxType: Yup.string().nullable(true),
    boxNumber: Yup.string().nullable(true),
  }),
  businessAddress: Yup.object().shape({
    buildingLevel: Yup.string().nullable(true),
    unitNumber: Yup.string().nullable(true),
    unitType: Yup.string().nullable(true),
    streetNumber: Yup.string().nullable(true),
    streetName: Yup.string().nullable(true),
    streetType: Yup.string().nullable(true),
    addressLine1: Yup.string().nullable(true),
    addressLine2: Yup.string().nullable(true),
    city: Yup.string().nullable(true),
    state: Yup.string().required(),
    zipCode: Yup.string().nullable(true),
    county: Yup.string().nullable(true),
    locality: Yup.string().nullable(true),
    country: Yup.string().nullable(true),
  }),
});

module.exports = {
  FirmSchema,
};
