'use strict';

/**
 * @typedef { import('../types').Task } Task
 * @typedef { import('../types').TaskFilter } TaskFilter
 * @typedef { import('../types').TaskGroup } TaskGroup
 */

const { groupTasksByCategoryLabel } = require('./group-tasks-by-category-label');

/**
 * Given a flat array of Task objects, this function will return an array of {@link TaskGroup}.
 *
 * NOTE: The expected Task structure is the GraphQL Task structure. Passing legacy cache
 * task entities will lead to unexpected behaviour.
 *
 * @param {Object} params
 * @param {Array<Task>} params.tasks
 * @param {TaskFilter} [params.filter]
 * @returns {Array<TaskGroup>}
 */
const convertToTaskGroupArray = ({ tasks, filter = {} }) => {
  const tasksByTypeLabel = groupTasksByCategoryLabel({ tasks, filter });

  return Object.entries(tasksByTypeLabel).reduce((acc, [taskGroupName, tasksInGroup]) => {
    /** @type TaskGroup */
    const taskGroup = { group: taskGroupName, tasks: tasksInGroup };
    // @ts-ignore
    acc.push(taskGroup);
    return acc;
  }, []);
};

module.exports = {
  convertToTaskGroupArray,
};
