'use strict';

/**
 * Get debtor ids for invoice address override purpose based on selected debtors and clients
 *
 * @param {Array} clientCustomerIds - An array of client ids from CLIENT / CONTACTS section.
 * @param {Array} debtorIds - An array of debtor ids from CLIENT / CONTACTS section.
 * @returns {Array} - An array of debtor ids that used for address override section in edit/add matter page.
 */
const getMatterDebtorIdsForAddressOverride = ({ debtorIds, clientCustomerIds }) => {
  let debtorIdsForAddressOverride;
  //  exclude any nulls/undefined
  const filteredDebtorIds = debtorIds.filter((debtorId) => debtorId);

  // Check if debtorIds has items after filtering
  if (filteredDebtorIds.length > 0) {
    debtorIdsForAddressOverride = filteredDebtorIds;
  } else {
    // If filteredDebtorIds is empty, the first client will be used as the debtor for the matter
    const filteredClientCustomerIds = clientCustomerIds.filter((clientCustomerId) => clientCustomerId);
    if (filteredClientCustomerIds.length > 0) {
      debtorIdsForAddressOverride = [filteredClientCustomerIds[0]];
    } else {
      debtorIdsForAddressOverride = [];
    }
  }

  return debtorIdsForAddressOverride || [];
};

module.exports = {
  getMatterDebtorIdsForAddressOverride,
};
