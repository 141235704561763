import { gql } from '@apollo/client';
import { matterBillingConfiguration } from '../fragments/matterBillingConfiguration.graphql';
import { matterHourlyRateFragment } from '../fragments/matterHourlyRate.graphql';

// Always include relevant ID fields to enable caching
const query = gql`
  query MatterDetails(
    $matterId: ID!
    $matterFilter: MatterFilter
    $includeBillingConfiguration: Boolean!
    $includeMatterHourlyRate: Boolean!
  ) {
    matter(id: $matterId, filter: $matterFilter) {
      id
      matterNumber
      description
      status
      isLead
      clientDisplay
      clientNames
      otherSideDisplay
      matterStarted
      matterType {
        matterTypeId
        name
      }
      otherSide {
        id
      }
      attorneyResponsible {
        id
        name
        initials
        isFormerStaff
      }
      personAssisting {
        id
        name
        initials
        isFormerStaff
      }
      clients {
        id
      }
      billingConfiguration @include(if: $includeBillingConfiguration) {
        ...matterBillingConfiguration
      }
      matterHourlyRate @include(if: $includeMatterHourlyRate) {
        ...matterHourlyRateFragment
      }
    }
  }

  ${matterBillingConfiguration}
  ${matterHourlyRateFragment}
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterDetails = {
  query,
  notificationIds,
};
