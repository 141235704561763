'use strict';

/**
 * @param {object} param
 * @param {string} param.str=''
 * @param {number} param.length
 * @param {boolean} [param.useWordBoundary=true]
 * @param {string} [param.appendStr]
 * @returns {string}
 */
const truncate = ({ str = '', length, useWordBoundary = true, appendStr = '' }) => {
  if (typeof str !== 'string') {
    return str;
  }

  if (str.length <= length) {
    return str;
  }
  
  const subString = str.slice(0, length - 1);
  
  return (useWordBoundary 
    ? subString.slice(0, subString.lastIndexOf(' ')) 
    : subString) + appendStr;
};

module.exports = {
  truncate,
};
