/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-danger */

import rtfToHTML from '@sb-billing/rtf-to-html';
import moment from 'moment';
import { t } from '@sb-itops/localisation-web';
import { TextEditor } from '@sb-itops/react';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HtmlToRtfBrowser from '@sb-billing/html-to-rtf-browser';
import { Memo, Person } from 'types';
import { useReactToPrint } from 'react-to-print';
import { cleanHtml } from '@sb-itops/html';
import * as Styles from '../MemosTab.module.scss';

const htmlToRtf = new HtmlToRtfBrowser();

const formatCreatedBy = (memo: Memo) => {
  if (!memo || !memo.createdDate || !memo.createdByStaff?.name) {
    return null;
  }

  const datePortion = t('date', { ts: memo.createdDate });
  const timePortion = moment(memo.createdDate).format('h:mm A');
  return (
    <p>
      CREATED ON {datePortion} AT {timePortion} BY {memo.createdByStaff.name}
    </p>
  );
};

const formatLastUpdatedBy = (memo: Memo) => {
  if (!memo || !memo.timestamp || !memo.userStaff?.name) {
    return null;
  }

  const datePortion = t('date', { ts: memo.timestamp });
  const timePortion = moment(memo.timestamp).format('h:mm A');
  return (
    <p>
      LAST EDIT ON {datePortion} AT {timePortion} BY {memo.userStaff.name}
    </p>
  );
};

type TPrintableMemoProps = {
  printTitle: string;
  rawHtml: string;
  memo: Memo;
};

const PrintableMemo = React.forwardRef<HTMLDivElement, TPrintableMemoProps>((props, ref) => (
  <div className={`${Styles.printView}`}>
    <div ref={ref} className={`${Styles.printViewContent}`}>
      <h3>{props.printTitle}</h3>
      <h1>{props.memo.title}</h1>
      {formatCreatedBy(props.memo)}
      {formatLastUpdatedBy(props.memo)}
      <div
        dangerouslySetInnerHTML={{ __html: cleanHtml({ dirtyHtml: props.rawHtml, ensureAllowedTags: ['u', 'span'] }) }}
      />
    </div>
  </div>
));
// font-family: Arial, Helvetica, sans-serif;

const defaultPrintStyles = `
  @media print {
    body {
      font-family: "Segoe UI", sans-serif;
      -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
      color-adjust: exact; /* Firefox */
    }
  }
  @page {
    margin: 2cm;
  }
`;

export const ViewMemo = (props: {
  selected: boolean;
  memo: Memo;
  loggedInStaff: Person;
  printTitle: string;
  onChange: (memo: Memo, loggedInStaff: Person) => void;
}) => {
  const componentRef = useRef(null);

  const printProps = useMemo(
    () => ({
      content: () => componentRef.current,
      documentTitle: props.printTitle,
      copyStyles: false,
      removeAfterPrint: true,
      pageStyle: defaultPrintStyles,
    }),
    [props.printTitle],
  );

  const handlePrint = useReactToPrint(printProps);

  const { selected, onChange, memo, loggedInStaff } = props;
  const textRef = useRef();
  const titleRef = useRef<HTMLInputElement>(null);
  const [html, setHtml] = useState('');

  useEffect(() => {
    if (!memo.text) {
      setHtml('');
    } else if (!selected || !html) {
      // Only update the displayed html if this memo not selected OR it is the first render, otherwise the current cursor position will be lost
      rtfToHTML.fromString(memo.text, (err, output) => {
        const newValue = cleanHtml({ dirtyHtml: output, ensureAllowedTags: ['u', 'span'] });
        setHtml(newValue);
      });
    }
    // selected is not part of the dependencies, otherwise we might populate stale data after switching memos
  }, [memo.text]);

  useEffect(() => {
    if (titleRef.current && !memo.title) {
      titleRef.current.focus();
    }
  }, []);

  const refFunc = useCallback((rqRef) => {
    textRef.current = rqRef;
  }, []);
  const onChangeTitleFunc = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...memo, title: event.target.value }, loggedInStaff);
    },
    [memo, loggedInStaff],
  );
  const onChangeTextFunc = useCallback(
    (value, delta, source) => {
      if (source === 'user') {
        // Set current HTML as input to avoid losing the current cursor position
        setHtml(value);
        // Clean up HTML before converting to RTF for saving
        const newValue = cleanHtml({ dirtyHtml: value, ensureAllowedTags: ['u', 'span'] });
        onChange({ ...memo, newRTF: htmlToRtf.convertHtmlToRtf(newValue) }, loggedInStaff);
      }
    },
    [memo, loggedInStaff],
  );

  const formatLastSavedTimeStamp = (ts) => {
    const monthAndDay = t('date', { ts, format: 'D MMM' });
    const hourAndMinute = t('date', { ts, format: 'hhmm A' });

    const formattedTimeStamp = `${monthAndDay} at ${hourAndMinute}`;
    return formattedTimeStamp;
  };

  return (
    <div className={classnames(selected && Styles.selected, Styles.memo, memo.isDeleted && Styles.deletedMemo)}>
      {selected && !memo.isDeleted ? (
        <input
          ref={titleRef}
          placeholder="Title"
          className={classnames(Styles.title)}
          value={memo.title}
          onChange={onChangeTitleFunc}
        />
      ) : (
        <div className={Styles.title}>{memo.title}</div>
      )}

      {selected && (
        <div id="toolbar" style={{ border: 'none' }}>
          <button className="ql-print" tabIndex={-1} title="Print" />
          <button className="ql-timestamp" tabIndex={-1} title="Insert Timestamp" />

          <select className="ql-size" tabIndex={-1} title="Font Size">
            <option value="10px" tabIndex={-1} />
            <option value="12px" tabIndex={-1} />
            <option value="14px" tabIndex={-1} />
            <option value="18px" tabIndex={-1} />
            <option value="24px" tabIndex={-1} />
            <option value="36px" tabIndex={-1} />
          </select>

          <button className="ql-bold" tabIndex={-1} title="Bold" />
          <button className="ql-italic" tabIndex={-1} title="Italic" />
          <button className="ql-underline" tabIndex={-1} title="Underline" />
          <button className="ql-clean" tabIndex={-1} title="Remove Styling" />

          <button className="ql-list" value="ordered" tabIndex={-1} title="Ordered List" />
          <button className="ql-list" value="bullet" tabIndex={-1} title="Bullet List" />

          <button className="ql-undo" tabIndex={-1} title="Undo" />
          <button className="ql-redo" tabIndex={-1} title="Redo" />

          <select className="ql-color" tabIndex={-1} title={`Font ${t('color')}`} />
          <select className="ql-background" tabIndex={-1} title={`Highlight ${t('color')}`} />

          <button className="ql-align" value="" tabIndex={-1} title="Align to Left" />
          <button className="ql-align" value="center" tabIndex={-1} title={`${t('center')} Text`} />
          <button className="ql-align" value="right" tabIndex={-1} title="Align to Right" />

          <button className="ql-indent" value="-1" tabIndex={-1} title="Decrease Indent" />
          <button className="ql-indent" value="+1" tabIndex={-1} title="Increase Indent" />
        </div>
      )}
      <TextEditor
        className={Styles.editor}
        value={html}
        disabled={!selected || memo.isDeleted}
        hideToolBar={!selected}
        memoToolBar
        onChange={onChangeTextFunc}
        reactQuillRef={refFunc}
        initials={loggedInStaff?.initials}
        print={handlePrint}
      />
      {selected && <PrintableMemo memo={memo} printTitle={props.printTitle} rawHtml={html} ref={componentRef} />}

      {selected && memo.timestamp && (
        <div className={Styles.lastUpdated}>Last saved {formatLastSavedTimeStamp(memo.timestamp)}</div>
      )}
    </div>
  );
};
