'use strict';

const { billingUnitOptionValues } = require('./billing-unit-options');

// Get billable minutes for saving when add/edit a matter
// billableMinutes returned in this function will be saved in the matter's MatterHourlyRate entity
const getBillableMinutesForSaving = ({ billingUnitOption, customUnit }) => {
  let billableMinutes;
  if (billingUnitOption !== billingUnitOptionValues.FIRM_DEFAULT) {
    if (billingUnitOption === billingUnitOptionValues.CUSTOM) {
      billableMinutes = customUnit;
    } else {
      billableMinutes = billingUnitOption;
    }
  } else {
    billableMinutes = null;
  }

  return billableMinutes;
};

module.exports = {
  getBillableMinutesForSaving,
};
