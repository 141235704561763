'use strict';

const { sortByOrder } = require('@sb-itops/nodash');
const { hasFacet, facets } = require('@sb-itops/region-facets');
const { bankAccountTypes } = require('../../../bank-account/entities/constants');
const { transactionType } = require('../../../transactions/entities/constants');

// this function calls _.uniq because of split payments (which will have several transactions), which functionally duplicates

/**
 * Filter trust invoice payments.
 *
 * N.B. This function was extracted to BL but it is using lodash uniq. Since we don't want lodash dependency in BL and we don't have
 * an alternative, I pass it as dependency for now.
 *
 * @param {Object} params
 * @param {Array<Transaction>} params.transactions - Array of transactions to filter
 * @param {Function} params.uniqFn - Lodash uniq or equivalent
 * @param {Boolean} params.runByEnteredDate - Whether to filter by entered date
 * @param {Function} params.t - Translate function
 * @returns {Array<Transaction>} The filtered transactions.
 */
function filterTrustInvoicePayments({ transactions, uniqFn, runByEnteredDate, t }) {
  const trustToOfficeTransactions = {};

  const transactionsByType = transactions.reduce(
    (acc, tx) => {
      if (tx.accountType !== bankAccountTypes.TRUST) {
        return acc;
      }

      if (
        tx.isTrustToOffice &&
        (tx.type === transactionType.InvoicePayment || tx.type === transactionType.InvoicePaymentReversal)
      ) {
        const ttoGroupId = `${tx.multiPaymentId || tx.paymentId}_${tx.type}`;
        if (trustToOfficeTransactions[ttoGroupId]) {
          trustToOfficeTransactions[ttoGroupId].push({ ...tx, ttoGroupId });
        } else {
          trustToOfficeTransactions[ttoGroupId] = [{ ...tx, ttoGroupId }];
        }
        return acc;
      }

      if (tx.type === transactionType.InvoicePayment) {
        acc.invPayments.push(tx);
      }
      if (tx.type === transactionType.InvoicePaymentReversal) {
        acc.reversals.push(tx);
      }
      return acc;
    },
    {
      invPayments: [],
      reversals: [],
    },
  );
  const { invPayments, reversals } = transactionsByType;

  const groupedTrustToOffice = groupTrustToOfficeTransactions({ trustToOfficeTransactions, runByEnteredDate, t });

  return [...uniqFn(invPayments, 'paymentId'), ...uniqFn(reversals, 'paymentId'), ...groupedTrustToOffice];
}

// All invoices should have 0 value the credit/debits until the last one
// Move the credit/debits into MultiAmount column
// InvoicePaymentAmount is always positive - need to convert to negative for debits
function groupTrustToOfficeTransactions({ trustToOfficeTransactions, runByEnteredDate, t }) {
  const removedValues = {
    credit: 0,
    debit: 0,
    balance: 0,
  };

  const totals = {};

  function trackTotals(tx) {
    if (!totals[tx.ttoGroupId]) {
      totals[tx.ttoGroupId] = {};
    }
    if (!totals[tx.ttoGroupId][tx.paymentId]) {
      totals[tx.ttoGroupId][tx.paymentId] = {};
    }
    totals[tx.ttoGroupId][tx.paymentId] = {
      credit: tx.credit,
      debit: tx.debit,
      balance: tx.balance,
    };
  }

  function getTotals(ttoGroupId) {
    return Object.values(totals[ttoGroupId]).reduce(
      (acc, payment) => ({
        credit: acc.credit + payment.credit,
        debit: acc.debit + payment.debit,
        balance: acc.balance + payment.balance,
      }),
      removedValues,
    );
  }

  const primarySortColumn =
    hasFacet(facets.transactionsByEnteredDate) && runByEnteredDate ? 'creationDate' : 'effectiveDate';
  const secondarySortColumn =
    hasFacet(facets.transactionsByEnteredDate) && runByEnteredDate ? 'effectiveDate' : 'creationDate';

  const txns = Object.values(trustToOfficeTransactions)
    // Sorting the transactions to match the final sort, otherwise the credit/debit/balance
    // values may not appear on the last line
    .map((transactions) =>
      sortByOrder(
        transactions,
        [primarySortColumn, secondarySortColumn, 'debtorMatterRef'],
        ['asc', 'asc', 'asc'],
      ).reduce((acc, tx, index) => {
        trackTotals(tx);

        const newTx = {
          ...tx,
          multiAmount: transactions.length !== 1 && tx.invoicePaymentAmount, // Display if there is more than one invoice
          ...(transactions.length - 1 !== index ? removedValues : getTotals(tx.ttoGroupId)), // remove credit/debit values until the last row of this TTO transaction
          hideValuesInTemplate: transactions.length - 1 !== index,
          description: `${tx.type === transactionType.InvoicePaymentReversal ? 'Reversal: ' : ''}Transfer to ${t(
            'operating',
          )} for payment of invoice #invoiceId:${tx.invoiceId}`,
        };
        acc.push(newTx);

        return acc;
      }, []),
    )
    .reduce((all, ops) => all.concat(ops), []); // flatten the transactions;

  return txns;
}

module.exports = {
  filterTrustInvoicePayments,
};
