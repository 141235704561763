import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Typeahead, FloatingCard, forms2PropTypes, FormLabel, useTranslation } from '@sb-itops/react';

import Styles from '../FeeWiseApplicationForm.module.scss';

export const TrustAccountDetails = ({
  trustAccountDetails,
  formInitialised,
  accountTypeOptions,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  sectionRef,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <FloatingCard applyMargin={false} classname={Styles.marginBottom} ref={sectionRef}>
      <div className={Styles.sectionTitle}>Trust Account</div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Holder Name" optional />
          <input
            type="text"
            value={trustAccountDetails?.accountHolderName?.value}
            className={classnames('form-control', isErrorClassnames(trustAccountDetails?.accountHolderName))}
            onChange={(e) => onFieldUpdated({ 'trustAccountDetails.accountHolderName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Name" optional />
          <input
            type="text"
            value={trustAccountDetails?.accountName?.value}
            className={classnames('form-control', isErrorClassnames(trustAccountDetails?.accountName))}
            onChange={(e) => onFieldUpdated({ 'trustAccountDetails.accountName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label={t('bankBranchNumber')} optional />
          <input
            type="text"
            value={trustAccountDetails?.routingNumber?.value}
            className={classnames('form-control', isErrorClassnames(trustAccountDetails?.routingNumber))}
            onChange={(e) => onFieldUpdated({ 'trustAccountDetails.routingNumber': e.target.value })}
            disabled={formDisabled}
          />
          {!!isErrorClassnames(trustAccountDetails?.routingNumber) &&
            trustAccountDetails?.routingNumber?.invalidReason.includes('Must be exactly') && (
              <span className={Styles.errorMsg}>{trustAccountDetails?.routingNumber?.invalidReason}</span>
            )}
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Number" optional />
          <input
            type="text"
            value={trustAccountDetails?.accountNumber?.value}
            className={classnames('form-control', isErrorClassnames(trustAccountDetails?.accountNumber))}
            onChange={(e) => onFieldUpdated({ 'trustAccountDetails.accountNumber': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Type" optional />
          <Typeahead
            selectedOption={accountTypeOptions.find(
              (option) => option.value === trustAccountDetails?.accountType?.value,
            )}
            options={accountTypeOptions}
            disabled={formDisabled}
            hasError={!!isErrorClassnames(trustAccountDetails?.accountType)}
            placeholder="Select..."
            onSelect={(option) => {
              onFieldUpdated({ 'trustAccountDetails.accountType': option?.value || undefined });
            }}
          />
        </div>
      </div>
    </FloatingCard>
  );
};

TrustAccountDetails.displayName = 'TrustAccountDetails';

const { Forms2Field } = forms2PropTypes;

TrustAccountDetails.propTypes = {
  trustAccountDetails: PropTypes.shape({
    accountHolderName: Forms2Field,
    accountName: Forms2Field,
    routingNumber: Forms2Field,
    accountNumber: Forms2Field,
    accountType: Forms2Field,
  }),
  accountTypeOptions: PropTypes.array.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

TrustAccountDetails.defaultProps = {
  formDisabled: false,
  trustAccountDetails: {},
};
