import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { featureActive } from '@sb-itops/feature';
import { shared } from '@sb-billing/business-logic';

const { convertHoursToUnits, convertUnitsToHours } = shared.services.fuzzyTime;
const parseValueWithDecimals = (number) => parseFloat(number.toFixed(5));
const DEFAULT_INTERVAL = 6; // 6mins is the default interval
const withRounding = featureActive('BB-4832');

const ActivityDuration = memo(
  ({ className, max, onChange, onBlur, name, value, interval, unitType, onUnitTypeChange, isDisabled }) => {
    const durationInUnits = convertHoursToUnits({ hrs: value, interval, withRounding });
    const min = unitType === 'hrs' ? +(interval / 60).toFixed(5) : 1;
    const isFixedRate = unitType === 'fixed';
    const isHrs = unitType === 'hrs';
    const isUnits = unitType === 'units';

    const fixedClassNames = classnames('btn', 'un-toggle', isFixedRate ? 'active btn-primary' : 'btn-default');
    const hrsClassNames = classnames('btn', 'toggled', isHrs ? 'active btn-primary' : 'btn-default');
    const unitsClassNames = classnames('btn', 'toggled', isUnits ? 'active btn-primary' : 'btn-default');

    const onUnitTypeChangeHandler = useCallback(
      (event) => {
        const newFeeUnitType = event.target.name;
        if (newFeeUnitType === unitType) {
          return;
        }
        onUnitTypeChange(newFeeUnitType);
      },
      [unitType, onUnitTypeChange],
    );

    const getDurationValueInHours = useCallback(
      (duration) => {
        const newValue = parseValueWithDecimals(parseFloat(duration));
        return unitType === 'units' ? convertUnitsToHours({ units: newValue, interval }) : newValue;
      },
      [interval, unitType],
    );

    const onFocusHandler = useCallback((e) => {
      e.target.select();
    }, []);

    const onValueChangedHandler = useCallback(
      (e) => {
        const durationInHours = getDurationValueInHours(e.target.value || 0);
        onChange(durationInHours);
      },
      [getDurationValueInHours, onChange],
    );

    const onBlurHandler = useCallback(
      (e) => {
        // on blur we cannot be 0, we need to set the minimum value
        const durationInHours = getDurationValueInHours(e.target.value);
        onBlur(durationInHours);
      },
      [getDurationValueInHours, onBlur],
    );

    // Disabling the step increment controls as it does not work right for 1 minute intervals with rounding
    const onKeyDownHandler = useCallback((event) => {
      if ((event.keyCode === 38 || event.keyCode === 40) && featureActive('BB-4832')) {
        event.preventDefault();
      }
    }, []);

    return (
      <div className={classnames('activity-duration-input-toggle', className)}>
        <div className="input-group currency-input">
          {isFixedRate && (
            <div className="input-group currency-input">
              <input className="form-control currency" type="text" value="N/A" disabled />
            </div>
          )}
          {!isFixedRate && (
            <div className="input-group currency-input">
              <input
                type="number"
                disabled={isDisabled}
                min={min}
                max={max}
                className={classnames('form-control', 'currency', {
                  'duration-input-field': featureActive('BB-4832'),
                })}
                value={unitType === 'hrs' ? value : durationInUnits}
                step={min}
                name={name}
                onFocus={onFocusHandler}
                onChange={onValueChangedHandler}
                onBlur={onBlurHandler}
                onKeyDown={onKeyDownHandler}
              />
            </div>
          )}
        </div>
        <div className="btn-group">
          <button
            type="button"
            name="fixed"
            className={fixedClassNames}
            disabled={isDisabled}
            onClick={onUnitTypeChangeHandler}
          >
            FIXED
          </button>
          <button
            type="button"
            name="hrs"
            className={hrsClassNames}
            disabled={isDisabled}
            onClick={onUnitTypeChangeHandler}
          >
            HRS
          </button>
          {featureActive('BB-5345') && (
            <button
              type="button"
              name="units"
              className={unitsClassNames}
              disabled={isDisabled}
              onClick={onUnitTypeChangeHandler}
            >
              UNITS
            </button>
          )}
        </div>
      </div>
    );
  },
);

ActivityDuration.displayName = 'ActivityDuration';

ActivityDuration.propTypes = {
  className: PropTypes.string,
  interval: PropTypes.number,
  isDisabled: PropTypes.bool,
  max: PropTypes.number,
  name: PropTypes.string.isRequired,
  // values for unitType are actually expected oneOf(['hrs', 'units', fixed])
  // But when PropTypes.oneOf is used formik throws a propType console error.
  // Hence went with the string type.
  unitType: PropTypes.string,
  value: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onUnitTypeChange: PropTypes.func,
};

ActivityDuration.defaultProps = {
  className: undefined,
  interval: DEFAULT_INTERVAL,
  isDisabled: false,
  max: 99999,
  unitType: 'hrs',
  value: undefined,
  onBlur: () => {},
  onUnitTypeChange: () => {},
};

export default ActivityDuration;
