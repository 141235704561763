angular.module('sb.billing.webapp').component('sbTransactionsOperatingCheques', {
  templateUrl: 'ng-routes/home/billing/accounts/transactions/operating-cheques/transactions-operating-cheques.html',
  controller: function($scope, $location, $uibModal, sbLinkService, sbAsyncOperationsService) {
    const ctrl = this;

    ctrl.printCheque = (chequeId) => sbAsyncOperationsService.startOperatingChequeCreation([chequeId]);
    ctrl.onClickLink = sbLinkService.onClickLink;

    $scope.$on('$locationChangeSuccess', () => {
      ctrl.sbQuery = _.cloneDeep($location.search());
    });
  }
});
