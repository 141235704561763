import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'matter-communication-settings',
  keyPath: 'matterId',
  ngCacheName: 'sbMatterCommunicationSettingsService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getMap, updateCache, UPDATE_CACHE, getIndex, getList, clearCache } = api;

export const getById = (id) => getMap()[id];

export * from './save-matter-communication-settings';
