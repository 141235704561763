angular.module('sb.billing.webapp').service('sbUserSettingsService', function (sbGenericCacheService) {
  'use strict';
  const that = this;
  // noop to satisfy generic cache, as this state is not in redux
  const updateRedux = () => { };
  const cache = sbGenericCacheService.setupCache({
    name: 'sbUserSettingsService',
    updateRedux,
  });

  that.get = get;
  that.saveP = saveP;

  function get () {
    var cached = cache.get();

    if (!cached || Object.getOwnPropertyNames(cached).length === 0) {
      return {};
    }

    return cached.userSettings.val;
  }

  function saveP(name, value) {
    var settings = get();

    //update the specified setting
    settings[name] = value;

    return cache.applyChangesetP([{name: 'userSettings', val: settings}]);
  }
});
