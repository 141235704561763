/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes, indexTypes } from '@sb-itops/redux';
import { getMatterTypeNameById, isLeadMatterType } from '@sb-matter-types/redux/matter-types';
import { getDebtorId as getDebtorIdFromBillingConfiguration } from '@sb-billing/redux/billing-configuration';
import * as services from '@sb-matter-management/business-logic/matters/services';
import { featureActive } from '@sb-itops/feature';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

import {
  getValidMatterListSelector,
  getMapSelector,
  getUsedAttorneyResponsibleIdsSelector,
  getValidMatterAndLeadListSelector,
} from './selectors';

import domain from '../domain';

const {
  UPDATE_CACHE,
  getList: _getList,
  getById,
  updateCache,
  clearCache,
  getIndex,
} = cacheFactory({
  domain,
  name: 'matters',
  keyPath: 'matterId',
  ngCacheName: 'sbMattersMbService',
  syncType: syncTypes.SINCE,
  immutable: false, // removed due to immu bug
  indexes: [
    {
      name: 'byMatterNumber',
      indexer: (matter) => matter.matterNumber,
      type: indexTypes.ONE_TO_MANY,
    },
  ],
});

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbMattersMbService',
  keyPath: 'matterId',
});

const getList = (showAllLeads) =>
  showAllLeads
    ? getValidMatterAndLeadListSelector(_getList(), featureActive('BB-6595'))
    : getValidMatterListSelector(_getList(), featureActive('BB-6595'));

const getMap = () => getMapSelector(getList(true));

const getByIds = (ids) => {
  const matterMap = getMap();
  return ids.map((id) => matterMap[id]);
};

export const getUsedAttorneyResponsibleIds = () => getUsedAttorneyResponsibleIdsSelector(getList());

export const getMatterDisplayById = (id, showStatus, includeDescription) =>
  getMatterDisplay(getById(id), showStatus, includeDescription);

export const getAttorneyResponsible = (matterId) => getById(matterId).attorneyResponsibleId;

export const getStatus = (matterId) => {
  if (getById(matterId)) {
    return getById(matterId).status;
  }
  // eslint-disable-next-line no-console
  console.warn(`cant get status of missing matter ${matterId}, returning undefined`);

  return undefined;
};

export const getTypeId = (matterId) => {
  if (getById(matterId)) {
    return getById(matterId).matterTypeId;
  }
  // eslint-disable-next-line no-console
  console.warn(`cant get typeId of missing matter ${matterId}, returning undefined`);

  return undefined;
};

export const getByMatterNumber = (matterNumber) => getIndex('byMatterNumber')[matterNumber];

export const getMatterDisplay = (matter, showStatus, showDescription, showOtherSide, showMatterNumber) => {
  if (!matter) {
    return '';
  }
  const matterTypeName = getMatterTypeNameById(matter.matterTypeId);

  return services.getMatterDisplay(
    matter,
    matterTypeName,
    showStatus,
    showDescription,
    showOtherSide,
    showMatterNumber,
  );
};

export const getDebtors = (matter) => services.getDebtors(matter);

export const isDuplicateMatterNumber = (matterNumber, excludeMatterId) => {
  if (!matterNumber) {
    return false; // empty matter number is not duplicate
  }
  const existingMattersWithMatterNumber = getByMatterNumber(matterNumber) || [];

  if (!excludeMatterId && existingMattersWithMatterNumber.length > 0) {
    return true; // if no matter id exclusions defined and records found, consider it a duplicate
  }

  const isDuplicate = existingMattersWithMatterNumber.some((matter) => matter.matterId !== excludeMatterId);
  return isDuplicate;
};

export const isDeleted = (matterId) => {
  const matter = getById(matterId);

  if (matter) {
    return matter.status === 'Deleted';
  }

  return false;
};

export const isLeadMatter = (matterId) => {
  const matter = getById(matterId);

  if (matter) {
    return isLeadMatterType(matter.matterTypeId);
  }

  return false;
};

export const isBillableLeadMatter = (matter) => {
  const billableLeadMatter =
    featureActive('BB-6595') &&
    !services.isEmptyMatter(matter) &&
    isLeadMatterType(matter.matterTypeId) &&
    typeof matter.matterNumber === 'string' &&
    matter.matterNumber.trim() !== '';
  return billableLeadMatter;
};

export const isBillableLeadMatterId = (matterId) => {
  const matter = getById(matterId);
  return isBillableLeadMatter(matter);
};

export const isValidMatter = (matter, showAllLeads) => {
  const billableLeadMatter = isBillableLeadMatter(matter);
  const showDeletedMatters = featureActive('BB-7911');
  return (
    !services.isEmptyMatter(matter) &&
    (matter.status !== 'Deleted' || showDeletedMatters) &&
    (!isLeadMatterType(matter.matterTypeId) || billableLeadMatter || showAllLeads)
  );
};

export const getMattersByStatuses = (statuses) => {
  const statusFilter = services.getStatusFilter(statuses);
  return getList().reduce((filteredMatters, matter) => {
    if (statusFilter(matter)) {
      // eslint-disable-next-line no-param-reassign
      filteredMatters[matter.matterId] = matter;
    }
    return filteredMatters;
  }, {});
};

export const isEditable = (matterId) => services.isEditableMatter(getById(matterId));

export const isSaving = (matterId) => !!getById(matterId)?.$optimistic;

export const getLineSummary = (matterId, showStatus) => {
  const matter = getById(matterId);

  if (!matter) {
    return '';
  }

  const matterTypeName = getMatterTypeNameById(matter.matterTypeId);
  return services.getMatterDisplay(matter, matterTypeName, showStatus);
};

// gets the default debtor on the matter or gets the first clientCustomerId
export const getDebtorId = (matterId) =>
  getDebtorIdFromBillingConfiguration(matterId) || services.getDebtorId(getById(matterId));

export { UPDATE_CACHE, updateCache, clearCache, getMap, getList, getById, getByIds };

export { createMatter } from './create-matter';
export { saveMatter } from './save-matter';
export { deleteMatter } from './delete-matter';
export { restoreDeletedMatter } from './restore-deleted-matter';
