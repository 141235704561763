'use strict';

const yup = require('yup');
const moment = require('moment');
const { guidRe } = require('./uuid-regex');

const integerDate = (errorMessage = 'Invalid integer date, date must be YYYYMMDD') =>
  yup
    .number()
    .nullable()
    .test('is-valid-integer-date', errorMessage, (value) => {
      if (value === null || value === undefined) {
        return true;
      }
      return moment(value, 'YYYYMMDD', true).isValid();
    });

const uuid = (errorMessage = 'Invalid UUID') => yup.string().matches(guidRe, errorMessage);

const customYupValidators = {
  integerDate,
  uuid,
};

module.exports = {
  customYupValidators,
};
