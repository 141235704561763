import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { StatelessSelect } from '@sb-itops/react/select';
import { buildGroupedDropDownOptions, mapItemToOption } from '@sb-billing/business-logic/activities/services';
import classNames from 'classnames';

const TaskDropdown = React.memo(
  ({
    onSelectionChange,
    tasks,
    selectedTaskCode,
    originalSelectedTask,
    disabled,
    placeholder,
    groupLabelHeight,
    optionLabelHeight,
    noDefaultStyling,
    hasError,
    selectClassName,
  }) => {
    const isGrouped = tasks && tasks[0] && tasks[0].group !== undefined && tasks.length > 1; // only show group header if there is more than one group

    // Convert the task objects to option objects accepted by DropDownList.
    const [options, optionsMap] = useMemo(() => buildGroupedDropDownOptions(tasks, 'tasks'), [tasks]);

    // Stateless select expects an object reference, not an string id.
    let selectedOption = useMemo(
      () => (!selectedTaskCode ? null : optionsMap[selectedTaskCode]),
      [selectedTaskCode, optionsMap],
    );

    // it's unclear why the following block is needed, can't seem to find
    // a case where it's used, even on the expanded right hand side entry form
    if (!selectedOption && !!originalSelectedTask) {
      selectedOption = mapItemToOption(originalSelectedTask);
    }

    // Create a thin wrapper around the selectionChange callback to extract out the original tasks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onValueChange = useCallback(onSelectionChange ? (selection) => onSelectionChange(selection.data) : () => {});

    const groupFormat = useCallback(({ label }) => <div className="task-grouped-field-header">{label}</div>, []);

    // this interface is coming from react-select
    const formatOptionLabel = useCallback(({ label }, { context }) => {
      // this can be any JSX
      if (context === 'menu') return <span title={label}>{label}</span>;
      // this can be any JSX
      return label;
    }, []);

    return (
      <StatelessSelect
        className={classNames(noDefaultStyling ? '' : 'task-dropdown', disabled && 'disabled', selectClassName)}
        styleClass="task-dropdown"
        onValueChange={onValueChange}
        options={options}
        selectedOption={selectedOption}
        isClearable={false}
        formatGroupLabel={(isGrouped && groupFormat) || undefined}
        formatOptionLabel={formatOptionLabel}
        groupLabelHeight={(isGrouped && groupLabelHeight) || undefined}
        optionLabelHeight={optionLabelHeight}
        disabled={disabled}
        placeholder={placeholder}
        hasError={hasError}
        isGrouped={isGrouped}
      />
    );
  },
);

TaskDropdown.displayName = 'TaskDropdown';

TaskDropdown.propTypes = {
  selectClassName: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      tasks: PropTypes.array,
    }),
  ),
  onSelectionChange: PropTypes.func,
  selectedTaskCode: PropTypes.string,
  originalSelectedTask: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  groupLabelHeight: PropTypes.number,
  optionLabelHeight: PropTypes.number,
  noDefaultStyling: PropTypes.bool,
  hasError: PropTypes.bool,
};

TaskDropdown.defaultProps = {
  selectClassName: undefined,
  tasks: [],
  onSelectionChange: undefined,
  selectedTaskCode: undefined,
  originalSelectedTask: undefined,
  disabled: false,
  placeholder: '',
  groupLabelHeight: undefined,
  optionLabelHeight: undefined,
  noDefaultStyling: false,
  hasError: false,
};

export default TaskDropdown;
