import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { isPayButtonEnabledForFirmAndMatter } from '@sb-billing/redux/matter-invoice-settings';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { getSettings as getInvoiceReminderEmailSettings } from '@sb-billing/redux/invoice-reminder-settings';
import { formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { invoiceReminderEmailFormSchema } from './invoice-reminder-email-form-schema';
import { InvoiceReminderEmailForm } from './InvoiceReminderEmailForm';

const mapStateToProps = (state, { scope, matterIds, remindersCount }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formInitialised, fields: formFields } = formSelectors.getFormState(state);
  const {
    toAddress,
    readOnlyToAddress,
    readOnlyCcBcc,
    fromAddress,
    ccAddress,
    bccAddress,
    staffAddress,
    sendCopyToMe,
    subject,
    message,
  } = formFields;

  const payButtonSettingForMatters = matterIds.reduce(
    (acc, matterId) => {
      if (isPayButtonEnabledForFirmAndMatter({ matterId })) {
        acc.oneMatterHasPayButtonEnabled = true;
      } else {
        acc.oneMatterHasPayButtonDisabled = true;
      }
      return acc;
    },
    {
      oneMatterHasPayButtonEnabled: null,
      oneMatterHasPayButtonDisabled: null,
    },
  );

  const mappedState = {
    isLoading: !formInitialised,
    readOnlyToAddress,
    readOnlyCcBcc,
    toAddress,
    fromAddress,
    ccAddress,
    bccAddress,
    staffAddress,
    sendCopyToMe,
    subject,
    message,
    showPreviewButton: remindersCount === 1,
    // If at least one of the selected invoice's matters have enabled pay buttons, we show the pay button in the modal message. However, if one of the selected invoices have disabled pay buttons, we inform them that these invoice emails will not show the pay button.
    showDisabledPayButtonsMessage: !!(
      payButtonSettingForMatters.oneMatterHasPayButtonEnabled &&
      payButtonSettingForMatters.oneMatterHasPayButtonDisabled
    ),
  };

  return mappedState;
};

const mapDispatchToProps = (dispatch, { scope, debtorIds, matterIds, remindersCount }) => {
  const { actions: formActions, operations: formOperations } = withScopedFeature({ scope })(forms);

  return {
    onLoad: () => {
      dispatch(prepareFormData({ remindersCount, matterIds, debtorIds, formActions, formOperations }));
      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
    },
    onFieldValueUpdated: (field, value) => {
      dispatch(formActions.updateFieldValues({ fieldValues: { [field]: value } }));
      dispatch(formOperations.validateSchema({ schema: invoiceReminderEmailFormSchema }));
    },
  };
};

const buildAddressInformationP = async ({ remindersCount, debtorIds }) => {
  // If there is more than one debtor being emailed, we want to show the to address as a read only summary.
  if (debtorIds.length !== 1) {
    const readOnlyToAddress = `${remindersCount} reminders will be sent to ${debtorIds.length} debtors`;
    return { readOnlyToAddress };
  }

  // If there is a single debtor, we attempt to figure out the email address for the debtor so that we can pre-populate the
  // toAddress field. We swallow any failure here because the debtor might not have an email address, but we still want to
  // give the customer a chance to enter one manually.
  try {
    const people = await fetchPeopleP(debtorIds[0]);
    return { toAddress: formatContactsEmails(people) };
  } catch (err) {
    return {};
  }
};

const getUniqueCcBccFromMatterIds = (matterIds) => {
  if (!Array.isArray(matterIds)) return {};

  const cc = new Set();
  const bcc = new Set();
  matterIds.forEach((matterId) => {
    const settings = getMatterEmailSettings(matterId);
    (settings?.cCAddresses || []).forEach((item) => cc.add(item));
    (settings?.bCCAddresses || []).forEach((item) => bcc.add(item));
  });

  return { cCAddresses: Array.from(cc), bCCAddresses: Array.from(bcc) };
};

const prepareFormData =
  ({ remindersCount, matterIds, debtorIds, formActions, formOperations }) =>
  async (dispatch) => {
    const { readOnlyToAddress, toAddress } = await buildAddressInformationP({ remindersCount, debtorIds });
    const { email: staffEmailAddress } = getStaffEmailDetails() || {};
    const { emailSubject, emailBody, sendCopyToUser } = getInvoiceReminderEmailSettings();

    // Remove the pay button from send email modal only if all the selected reminder's matters have disabled pay buttons in their settings.
    //
    // If at least one of the selected invoice's matters have enabled pay buttons, show it on the modal.
    const showPayButtons = matterIds.some((matterId) => isPayButtonEnabledForFirmAndMatter({ matterId }));

    const getMessage = () => {
      if (!showPayButtons) {
        const emailBodyWithoutPaymentButtons = emailBody
          .replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '') // Remove the placeholder - mirror evergreen retainer handling of Quill paragraphs
          .replace(/(:?<p>)?\[PAY_NOW_LINK\](:?<\/p><p><br \/><\/p>)?/g, '');
        return emailBodyWithoutPaymentButtons;
      }

      return emailBody;
    };

    // merge all CC/BCC across all matters
    const readOnlyCcBcc = matterIds.length > 1 && debtorIds.length > 1;
    const { bCCAddresses = [], cCAddresses = [] } = readOnlyCcBcc ? {} : getUniqueCcBccFromMatterIds(matterIds);

    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          readOnlyToAddress,
          readOnlyCcBcc,
          toAddress,
          ccAddress: cCAddresses.join(', '),
          bccAddress: bCCAddresses.join(', '),
          fromAddress: staffEmailAddress,
          staffAddress: staffEmailAddress,
          sendCopyToMe: sendCopyToUser,
          subject: emailSubject,
          message: getMessage(),
        },
      }),
    );

    dispatch(formOperations.validateSchema({ schema: invoiceReminderEmailFormSchema }));
  };

export const InvoiceReminderEmailFormContainer = withReduxStore(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withOnLoad(({ onPreview, ...props }) => {
      const [isPreviewMode, setIsPreviewMode] = useState(props.showPreviewButton);
      const [previewSubject, setPreviewSubject] = useState('');
      const [previewMessage, setPreviewMessage] = useState('');

      const onPreviewToggled = async () => {
        setIsPreviewMode(!isPreviewMode);
      };

      const getPreviewInfo = async () => {
        const { subject, message } = await onPreview();
        setPreviewSubject(subject);
        setPreviewMessage(message);
      };

      useEffect(
        () => {
          // Get preview info on load/when message changes instead of on toggle to enable defaulting to preview mode
          // onPreview fails when props.message is undefined (on first load before fetch)

          // Only need to fetch if the preview button is enabled
          if (props.message && props.showPreviewButton) {
            getPreviewInfo();
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.message],
      );

      return (
        <InvoiceReminderEmailForm
          {...props}
          previewSubject={previewSubject}
          previewMessage={previewMessage}
          onPreviewToggled={onPreviewToggled}
          isPreviewMode={isPreviewMode}
        />
      );
    }),
  ),
);

InvoiceReminderEmailFormContainer.displayName = 'InvoiceReminderEmailFormContainer';

InvoiceReminderEmailFormContainer.propTypes = {
  debtorIds: PropTypes.arrayOf(PropTypes.string),
  // invoiceIds: PropTypes.arrayOf(PropTypes.string),
  matterIds: PropTypes.arrayOf(PropTypes.string),
  showPreviewButton: PropTypes.bool,
  onPreview: PropTypes.func,
};

InvoiceReminderEmailFormContainer.defaultProps = {
  debtorIds: [],
  // invoiceIds: [],
  matterIds: [],
  showPreviewButton: false,
  onPreview: undefined,
};

export default InvoiceReminderEmailFormContainer;
