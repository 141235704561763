/* eslint-disable */

//https://hf.us.int.getfeewise.com/lib/feewise.js
const h = "formValidChange";
let e = document.getElementById("feewise-iframe");
function f(a) {
  var r;
  if (e && ((r = a == null ? void 0 : a.data) != null && r.resizeFrameHeight)) {
    const m = (a.data.resizeFrameHeight).toString() + "px";
    e.style.height = m;
  }
}
const u = (a, r, l, m) => {
  e || (e = document.createElement("iframe"), e.id = "feewise-iframe"), e.src = a, e.style.overflow = "hidden", e.scrolling = "no";
  let c;
  e.onload = () => {
    const n = window.setInterval(() => {
      var t;
      (t = e.contentWindow) == null || t.postMessage({ feeWiseOptions: { collectBillingDetails: l, appearance: m } }, e.src);
    }, 250);
    c = new Promise((t) => {
      const s = (o) => {
        o.data === "feeWiseReceivedOptions" && (window.removeEventListener("message", s), clearInterval(n), t());
      };
      window.addEventListener("message", s);
    });
  };
  const d = {};
  return window.onmessage = function(t) {
    var i;
    const s = t;
    if (!((i = s == null ? void 0 : s.data) != null && i.topic))
      return;
    const o = d[s.data.topic];
    o && o.forEach((w) => {
      w(s.data.payload);
    });
  }, {
    mount(n) {
      const t = document.querySelector(n);
      if (t)
        t.appendChild(e), console.log("mounted FeeWise!");
      else
        throw console.log(`Selector ${n} not found. Please make sure the div element exists in the document`), `Selector ${n} not found. Please make sure the div element exists in the document`;
      return window.removeEventListener("message", f), window.addEventListener("message", f), c;
    },
    submit() {
      var n;
      return (n = e.contentWindow) == null || n.postMessage("submitSavePaymentMethod", e.src), new Promise((t, s) => {
        const o = (i) => {
          typeof i.data.savePaymentMethodResponse < "u" ? (window.removeEventListener("message", o), r ? e.remove() : e.tabIndex = -1, t({ response: i.data.savePaymentMethodResponse })) : typeof i.data.savePaymentMethodError < "u" && s({ error: i.data.savePaymentMethodError });
        };
        window.addEventListener("message", o);
      });
    },
    on(n, t) {
      if (!d[n]) {
        d[n] = [t];
        return;
      }
      d[n].push(t);
    }
  };
};
window.setupFeewise = u;
export {
  h as EVENT_FORM_VALID_CHANGE,
  u as setupFeewise
};