const boostEnvs = {
  dev: 'dev',
  rc: 'rc',
  qa: 'qa',
  live: 'live',
};

const featureLevels = Object.freeze({
  OFF : 'OFF',
  LOCAL : 'LOCAL',
  DEV : 'DEV',
  RC: 'RC',
  PRODUCTION : 'PRODUCTION',
});

/**
 * @enum {string} FeatureLevel
 * @property {string} OFF OFF
 * @property {string} LOCAL LOCAL
 * @property {string} DEV DEV
 * @property {string} PRODUCTION PRODUCTION
 */

/**
 * @typedef { Object.<string, string|boolean> } Features
 */

/**
 * until all uses of sbFeatures has been migrated we need to support 2 style of features.
 * Legacy maps a feature to a feature level. Preferred is to map a feature to a boolean
 * @deprecated clients should use req.SB.features
 * @param {object} options
 * @param {string} options.feature the feature to test for
 * @param {Features} [options.features] map of features, e.g. { 'BB-1234': PRODUCTION }, or { 'BB-1234': true }
 * @param {string} [options.boostEnv] one of dev, rc, qa, live
 * @param {function} [options.logFn] function for logging instead of console.log
 */
const requestFeatureActive = ({ feature, features, boostEnv, logFn = () => {} }) => {

  if (features && typeof features[feature] === 'boolean') {
    logFn(`Using new feature ${feature}`);
    return features[feature];
  }

  const featureLevel = features ? features[feature] : featureLevels.OFF;
  const env = boostEnv || (process.env.SB_IS_RUNNING_LOCALLY ? process.env.DEV_ID : process.env.BOOST_ENV);
  
  let enabled = false;

  if (featureLevel === featureLevels.OFF) {
    return enabled;
  }

  switch (env) {
    case boostEnvs.dev:
      enabled = featureLevel === featureLevels.DEV
        || featureLevel === featureLevels.RC
        || featureLevel === featureLevels.PRODUCTION;
      break;
    
    case boostEnvs.rc:
      enabled = featureLevel === featureLevels.RC
        || featureLevel === featureLevels.PRODUCTION;
      break;
    
    case boostEnvs.qa:
    case boostEnvs.live:
      enabled = featureLevel === featureLevels.PRODUCTION;
      break;
    
    default:
      // assumed to be local
      enabled = featureLevel === featureLevels.LOCAL
        || featureLevel === featureLevels.DEV
        || featureLevel === featureLevels.RC
        || featureLevel === featureLevels.PRODUCTION;
  }

  if (enabled) {
      logFn(`Using legacy feature ${feature}`);
  }

  return enabled;
}
  
module.exports = {
  requestFeatureActive,
  boostEnvs,
  featureLevels,
};