'use strict';

const minimumSurchargeValues = Object.freeze({
  FIXED: 1, // 1 cent
  PERCENTAGE_BP: 10, // 0.1%
});

module.exports = {
  minimumSurchargeValues,
};
