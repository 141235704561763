angular.module('sb.billing.webapp').component('sbComposeGroup',
  {
    template: '<div class="sb-compose-group" ng-show="$ctrl.isActionable()"><ng-transclude></ng-transclude></div>',
    transclude: true,
    require: { composer: '^^sbCompose' },
    bindings: { keyMappings: '@?'}, // optional: map the {'value-asked-by-sbaction' : 'value-set-in-sbdata'}
    controller: function($scope, sbLoggerService) {
      'use strict';

      var that = this, action, actionPerformableTestFunction, preHook, postHook,
        log = sbLoggerService.getLogger('sbComposeGroup', $scope.$id);

      //log.setLogLevel('info');

      this.$onInit = function () {
        if (that.keyMappings) {
          try {
            that.parsedKeyMappings = JSON.parse(that.keyMappings);
          } catch (err) {
            throw new Error('Cannot parse sbComposeGroup keyMappings: ' + that.keyMappings);
          }
        }
      };

      this.setAction = function (act, testFunc) {
        action = act;
        actionPerformableTestFunction = testFunc;
      };

      this.isActionable = function () {
        return !_.isFunction(actionPerformableTestFunction) || actionPerformableTestFunction();
      };

      this.setActionPreHook = function (hk) {
        log.info('set pre-hook');
        preHook = hk;
      };

      this.setActionPostHook = function (hk) {
        log.info('set post-hook');
        postHook = hk;
      };

      this.onData = function (actionKey, cb) {
        var composeKey = actionKey;
        log.info('data handler registered, key', actionKey);

        if (that.parsedKeyMappings) {
          composeKey = that.parsedKeyMappings[actionKey];
        }

        return that.composer.onUpdateData(composeKey, cb);
      };

      this.doAction = function (params) {
        log.info('do action, params', params);

        function wrapActionP(actionParams) {
          log.info('run action');
          return Promise.resolve(_.isFunction(action) && action.apply(undefined, arrayify(actionParams)))
            .then(function (res) {
              log.info('action done', res);
              if (postHook) {
                log.info('run post-hook');
                postHook.apply(undefined, arrayify(res));
              }
            })
            .then(() => actionParams)
        }

        if (_.isFunction(preHook)) {
          log.info('run pre-hook');
          return preHook.apply(undefined, arrayify(params))
            .then(function (newParams) {
              log.info('pre-hook done', newParams);
              return wrapActionP(newParams);
            })
            .catch(function (err) {
              return Promise.reject(err);
            });
        } else {
          return wrapActionP(params);
        }
      };

      this.setLock = function (lockStatus) {
        this.locked = lockStatus;
      };

      this.onInvoiceData = function (cb, key) {
        this.onData(key || 'invoice', function (dataWrapper) {
          var dataType = dataWrapper && dataWrapper.dataType,
            invoice = dataWrapper && dataWrapper.data;

          if (dataType === 'invoices' && invoice) {
            return cb(invoice);
          }
        });
      };

      function arrayify(something) {
        if (something === undefined) {
          return [];
        }
        return _.isArray(something) ? something : [something];
      }
    }
  }
);
