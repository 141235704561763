import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NewPasswordRequiredForm } from './NewPasswordRequiredForm';

export const createPasswordPolicyValidators = ({ passwordPolicy }) => {
  const { minimumLength, requireUppercase, requireLowercase, requireNumbers, requireSymbols } = passwordPolicy;

  return [
    (password) => password.length >= minimumLength,
    (password) => !requireUppercase || /[A-Z]/.test(password),
    (password) => !requireLowercase || /[a-z]/.test(password),
    (password) => !requireNumbers || /\d/.test(password),
    (password) => !requireSymbols || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
  ];
};

export const NewPasswordRequiredFormContainer = (props) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCopy, setNewPasswordCopy] = useState('');

  const { minimumLength, requireUppercase, requireLowercase, requireNumbers, requireSymbols } = props.passwordPolicy;

  const passwordContentMessages = [
    requireUppercase && 'one uppercase character',
    requireLowercase && 'one lowercase character',
    requireNumbers && 'one number',
    requireSymbols && 'one special character',
  ]
    .filter((message) => !!message)
    .join(', ');

  const contentRequirementPrefix = passwordContentMessages.length ? ' contain at least ' : '';
  const lengthRequirementPrefix = passwordContentMessages.length ? ' and be at least ' : ' be at least ';

  const passwordContentMessage = `Passwords must${contentRequirementPrefix}${passwordContentMessages}${lengthRequirementPrefix}${minimumLength} characters in length.`;

  const onPasswordUpdated = (password) => {
    setNewPassword(password);
    setErrorMessages([]);
  };

  const onPasswordCopyUpdated = (password) => {
    setNewPasswordCopy(password);
    setErrorMessages([]);
  };

  const onValidateAndChangePassword = () => {
    if (!newPassword) {
      setErrorMessages([{ message: 'Please enter a valid password' }]);
      return;
    }

    if (!newPasswordCopy) {
      setErrorMessages([{ message: 'Please enter your password a second time' }]);
      return;
    }

    if (newPassword !== newPasswordCopy) {
      setErrorMessages([{ message: 'The entered passwords must match' }]);
      return;
    }

    const passwordPolicyValidators = createPasswordPolicyValidators({ passwordPolicy: props.passwordPolicy });
    const passwordMeetsPolicy = passwordPolicyValidators.every((validate) => validate(newPassword));
    if (!passwordMeetsPolicy) {
      setErrorMessages([{ message: passwordContentMessage, isVerbose: true }]);
      return;
    }

    props.onPasswordChange({ newPassword });
  };

  return (
    <NewPasswordRequiredForm
      passwordContentMessage={passwordContentMessage}
      password={newPassword}
      passwordCopy={newPasswordCopy}
      errorMessages={errorMessages}
      passwordChangeInProgress={props.passwordChangeInProgress}
      onPasswordUpdated={onPasswordUpdated}
      onPasswordCopyUpdated={onPasswordCopyUpdated}
      onConfirmNewPassword={onValidateAndChangePassword}
      onCancelPasswordChange={props.onCancelPasswordChange}
    />
  );
};

NewPasswordRequiredFormContainer.displayName = 'NewPasswordRequiredFormContainer';

NewPasswordRequiredFormContainer.propTypes = {
  passwordChangeInProgress: PropTypes.bool.isRequired,
  passwordPolicy: PropTypes.object.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onCancelPasswordChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      isVerbose: PropTypes.bool,
    }),
  ),
};

NewPasswordRequiredFormContainer.defaultProps = {
  errorMessages: undefined,
};

NewPasswordRequiredFormContainer.defaultProps = {};
