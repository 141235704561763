import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query MatterDisplayDetails($matterId: ID!, $matterFilter: MatterFilter) {
    matter(id: $matterId, filter: $matterFilter) {
      id
      matterNumber
      description
      status
      isLead
      clientDisplay
      otherSideDisplay
      matterType {
        matterTypeId
        name
      }
      clients {
        id
        displayName
      }
      otherSide {
        id
        displayName
      }
      matterTypeId
      clientDisplayName @client
      otherSideDisplayName @client
    }
  }
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterDisplayDetails = {
  query,
  notificationIds,
};
