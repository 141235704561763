import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onUpdateExpenseBulkIsBillable = ({ commandResult }) => {
  const { versions } = commandResult;
  const apolloClient = getApolloClient();

  return versions.map(({ expenseId, isBillable }) => {
    const optimisticEntity = {
      __typename: 'Expense',
      id: expenseId,
      isBillable,
    };

    const expenseIdentifier = apolloClient.cache.identify(optimisticEntity);

    apolloClient.writeFragment({
      id: expenseIdentifier,
      fragment: gql`
          fragment OpdateExpense on Expense {
              ${Object.keys(optimisticEntity).join('\n')}
          }
      `,
      data: {
        ...optimisticEntity,
      },
    });

    return {
      eventType: apolloEntityOpdateEventTypes.ENTITY_UPDATED,
      typeName: 'Expense',
      optimisticEntity,
    };
  });
};
