/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import domain from '../domain';

export { setDefaultPaymentMethod } from './set-default-payment-method';

const api = cacheFactory({
  domain,
  name: 'subscription',
  keyPath: 'accountId',
  ngCacheName: 'sbSubscriptionService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const getSubscription = () => getList()[0];

export const isBarUser = () => {
  const sub = getSubscription();
  if (!sub) {
    return false;
  }

  const couponData = (sub?.externalCoupon?.metadata || []).reduce(
    (acc, metadataItem) => {
      if (metadataItem.key === 'couponFreeDescription') {
        acc.couponFreeDescription = metadataItem.value;
        return acc;
      }
      if (metadataItem.key === 'noTrialPeriod') {
        acc.noTrialPeriod = metadataItem.value === 'true';
        return acc;
      }

      if (metadataItem.key === 'freeTiers') {
        metadataItem.value.split(',').forEach((freeTier) => {
          acc.freeCouponTiers[freeTier] = true;
        });
      }

      return acc;
    },
    {
      freeCouponTiers: {},
    },
  );

  const isFree = couponData.freeCouponTiers[productTierIdsByName.BILL];

  const noTrialPeriod = couponData.noTrialPeriod;

  if (isFree && noTrialPeriod) {
    return true;
  }
  return false;
};

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbSubscriptionService',
  keyPath: 'accountId',
});
