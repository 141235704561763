import React from 'react';

import Styles from './ConfigureMatterNumbering.module.scss';
import { TWidget } from './types';
import { TExampleParams, widgetRenderer } from './widget';

type TMatterNumberPreviewProps = {
  widgets: TWidget[];
  previewParams: TExampleParams[];
};

export const MatterNumberPreview = ({ widgets, previewParams }: TMatterNumberPreviewProps) => (
  <div className={Styles.matterNumberPreviews}>
    <label>Matter number previews:</label>
    {previewParams.map((params, i) => (
      <div key={`mnprev${i}`} className={Styles.matterNumberPreview}>
        <div key={`mnprevlab${i}`} className={Styles.matterNumberPreviewLabel}>
          {params.label}
        </div>
        <div key={`mnprevmn${i}`} className={Styles.matterNumber}>
          {widgets.map((widget, j) => (
            <div key={`mnprev${i}-${j}`} className={Styles.matterNumberPreviewWidget}>
              {widgetRenderer(widget, params)}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);
