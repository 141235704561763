angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-invoice-statement/pdf';

  $stateProvider
    .state('home.billing.view-invoice-statement.pdf', {
      url: '/pdf',
      templateUrl: BASE_DIRECTORY + '/view-invoice-statement-pdf.html',
      data: {
        authorized: true,
        navHighlights: ['billing', 'invoice-statement'],
        tab: { type: 'invoice-statement' }
      }
    });

});
