import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputNumberFilter = ({ min, max, value, step, onChange, classNames, noScroll }) => {
  const onFocusHandler = (e) => {
    e.target.select();
  };

  const onChangeHandler = (e) => {
    if (e.target.value === '') {
      onChange('');
    } else {
      onChange(parseInt(e.target.value, 10) || 0);
    }
  };

  const stopInputClickPropagation = (e) => {
    e.stopPropagation();
  };

  // Removes the ability to change amounts using the scroll wheel
  const handleMouseWheel = (e) => {
    e.target.blur();
  };

  return (
    <input
      type="number"
      className={classnames('input-number-filter', classNames)}
      min={min}
      max={max}
      value={value}
      step={step}
      onFocus={onFocusHandler}
      onChange={onChangeHandler}
      onClick={stopInputClickPropagation}
      onWheel={noScroll ? handleMouseWheel : () => {}}
    />
  );
};

InputNumberFilter.displayName = 'InputNumberFilter';

InputNumberFilter.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([''])]),
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  classNames: PropTypes.string,
  noScroll: PropTypes.bool,
};

InputNumberFilter.defaultProps = {
  min: undefined,
  max: undefined,
  value: undefined,
  step: 1,
  classNames: undefined,
  noScroll: false,
};

export default InputNumberFilter;
