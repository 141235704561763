import { connect } from 'react-redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { calculateDatesForPeriod, dateToInteger, integerToDate } from '@sb-itops/date';
import { getAllStaff } from '@sb-firm-management/redux/firm-management';
import { report as reportBusinessLogic } from '@sb-billing/business-logic';
import IncomeByAttorneyFilter from './IncomeByAttorneyFilter';

const ROLES_TO_INCLUDE = ['attorney', 'barrister', 'lawyer', 'solicitor'];
const { periodOption, periodOptions } = reportBusinessLogic.entities;
const { validateDateRange } = reportBusinessLogic.services;
const { startDate: defaultStartDate, endDate: defaultEndDate } = calculateDatesForPeriod(periodOption.THIS_MONTH.value);

const dateFromYYYYMMDD = (yyyymmdd) => (yyyymmdd ? integerToDate(yyyymmdd) : undefined);
const dateToYYYYMMDD = (date) => (date ? dateToInteger(date) : undefined);

const getIncludedTimekeepers = (limitToRoles) =>
  getAllStaff()
    .filter((person) => {
      if (!limitToRoles) {
        return true;
      }
      return person.role && limitToRoles.includes(person.role.toLowerCase());
    })
    .map((person) => person.id)
    .concat(['nobody']);

const mapStateToProps = (state, { filters }) => ({
  periodOptions,
  filters: filters
    ? {
        ...filters,
        startDate: dateFromYYYYMMDD(filters.startDate),
        endDate: dateFromYYYYMMDD(filters.endDate),
      }
    : undefined,
});

const mapDispatchToProps = (dispatch, { onFiltersChanged, filters }) => ({
  onLoad: () => {
    if (!filters) {
      onFiltersChanged({
        periodSelected: periodOption.THIS_MONTH,
        startDate: dateToYYYYMMDD(defaultStartDate),
        endDate: dateToYYYYMMDD(defaultEndDate),
        includeFirmHeader: false,
        includeFirmLogo: false,
        includeFirmName: false,
        showPercentage: true,
        attorneysOnly: false,
        showHours: true,
        includedStaffIds: getIncludedTimekeepers(),
        errors: {},
      });
    }
  },

  onSelectPeriod: (periodSelected) => {
    const { startDate, endDate } = calculateDatesForPeriod(periodSelected.value);
    onFiltersChanged({
      ...filters,
      periodSelected,
      startDate: startDate ? dateToYYYYMMDD(startDate) : undefined,
      endDate: endDate ? dateToYYYYMMDD(endDate) : undefined,
      errors: {
        ...filters.errors,
        ...validateDateRange({ periodSelected, startDate, endDate }),
      },
    });
  },

  onStartSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.startDate) {
      const startDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        startDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: date,
            endDate: dateFromYYYYMMDD(filters.endDate),
          }),
        },
      });
    }
  },

  onEndSelect: (date) => {
    if (dateToYYYYMMDD(date) !== filters.endDate) {
      const endDate = dateToYYYYMMDD(date);
      onFiltersChanged({
        ...filters,
        periodSelected: periodOption.CUSTOM,
        endDate,
        errors: {
          ...filters.errors,
          ...validateDateRange({
            periodSelected: periodOption.CUSTOM,
            startDate: dateFromYYYYMMDD(filters.startDate),
            endDate: date,
          }),
        },
      });
    }
  },

  onAttorneysOnly: (attorneysOnly) => {
    onFiltersChanged({
      ...filters,
      attorneysOnly,
      includedStaffIds: attorneysOnly ? getIncludedTimekeepers(ROLES_TO_INCLUDE) : getIncludedTimekeepers(),
    });
  },

  onToggleOption: (option) =>
    onFiltersChanged({
      ...filters,
      [option]: !filters[option],
    }),
});

const IncomeByAttorneyFilterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(withOnLoad(IncomeByAttorneyFilter)),
);

IncomeByAttorneyFilterContainer.displayName = `IncomeByAttorneyFilterContainer`;

export default IncomeByAttorneyFilterContainer;
