'use strict';

const { getRegion, regionType } = require('@sb-itops/region');

// Any changes to these will need to be handled in invoice-send-service
// dodFeatureEnabled is an alias for featureActive('BB-6865')
// since this is a business logic function which should be pure the caller needs to fetch and pass this through
// when isForEmailSubject is true, the placeholder for html based content should not be included.
/**
 * @param {object} param
 * @param {function} param.t
 * @param {boolean} param.evergreenRetainerEnabled
 * @param {boolean} param.isForEmailSubject
 * @param {boolean} param.dodFeatureEnabled
 * @param {string} [param.region] will get from runtime, or can be passed for testing
 * @returns {Array<{ name: string, tooltip: string }>}
 */
const getInvoiceEmailMacros = ({ t, evergreenRetainerEnabled, isForEmailSubject, dodFeatureEnabled, region = '' }) => {
  const regionMacrosStrategy = {
    [regionType.GB]: [
      { name: '[PERSON_RESPONSIBLE]', tooltip: t('capitalize', { val: 'personResponsible' }) },
      { name: '[INTRODUCER]', tooltip: t('capitalize', { val: 'introducer' }) },
    ],
    [regionType.AU]: [
      { name: '[PERSON_RESPONSIBLE]', tooltip: t('capitalize', { val: 'personResponsible' }) },
      { name: '[INTRODUCER]', tooltip: t('capitalize', { val: 'introducer' }) },
    ],
    [regionType.US]: [
      { name: '[ATTORNEY_RESPONSIBLE]', tooltip: t('capitalize', { val: 'personResponsible' }) },
      { name: '[ORIGINATING_ATTORNEY]', tooltip: t('capitalize', { val: 'introducer' }) },
    ],
  };

  if (!regionMacrosStrategy[region || getRegion()]) {
    throw new Error(`No email macros for region ${getRegion()}`);
  }

  const macros = [
    // Invoice Details
    { name: '[DEBTOR_NAME]', tooltip: 'Debtor name' },
    { name: '[DEBTOR_AMOUNT_DUE]', tooltip: 'Amount due (debtor total)' },
    { name: '[INVOICE_AMOUNT_DUE]', tooltip: 'Amount due (this invoice)' },
    { name: '[MATTER_AMOUNT_DUE]', tooltip: 'Amount due (matter total)' },
    { name: '[PAST_DUE]', tooltip: 'Amount past due' },
    { name: '[DATE_DUE]', tooltip: 'Invoice due date' },
    { name: '[HOURS]', tooltip: 'Total hours invoiced' },
    // Matter Details
    { name: '[MATTER_NAME]', tooltip: 'Matter name' },
    { name: '[PERSON_ASSISTING]', tooltip: 'Person assisting' },
    // Firm Details
    { name: '[USER_NAME]', tooltip: 'Current user' },
    { name: '[FIRM_NAME]', tooltip: 'Firm name' },
    { name: '[PHONE_NUMBER]', tooltip: 'Phone number' },
  ];

  regionMacrosStrategy[region || getRegion()].forEach((macro) => macros.push(macro));

  if (evergreenRetainerEnabled) {
    // Invoice Details
    macros.push({ name: '[EVERGREEN_AMOUNT_REQUESTED]', tooltip: 'Evergreen retainer amount' });
  }

  if (dodFeatureEnabled && !isForEmailSubject) {
    // Invoice Details
    macros.push({ name: '[SUMMARY_BOX]', tooltip: 'Includes invoice summary, payment details and buttons' });
  }

  if (!isForEmailSubject) {
    // Invoice Details
    macros.push({ name: '[PAY_NOW_BUTTON]', tooltip: 'Pay Now button' });
  }

  return macros;
};

module.exports = { getInvoiceEmailMacros };
