'use strict';

const { getApplicableActivityCategories } = require('./get-applicable-activity-categories');
const { activityCategoryLabels } = require('../entities/constants');

// This function is used in LOD fee component, the query gets all activities together
function getApplicableActivityCodes({ activities, utbmsEnabledForMatter, utbmsCodesRequiredByFirm }) {
  const applicableCategories = getApplicableActivityCategories({ utbmsEnabledForMatter, utbmsCodesRequiredByFirm });
  const applicableActivityCodes = activities.filter((activity) => applicableCategories.includes(activity.category));
  return applicableActivityCodes;
}

// This function is used in RHS fee (monorepo/billing/react/fee-entry-popout-editor)
// Activities are get separately from redux function
function getApplicableActivityCodesForRHSFeeModal({
  utbmsActivities,
  customActivities,
  utbmsEnabledForMatter,
  utbmsCodesRequiredByFirm,
}) {
  if (utbmsEnabledForMatter) {
    if (utbmsCodesRequiredByFirm) {
      return [
        {
          group: activityCategoryLabels.utbms,
          activities: utbmsActivities,
        },
      ];
    }
    return [
      { group: activityCategoryLabels.custom, activities: customActivities },
      {
        group: activityCategoryLabels.utbms,
        activities: utbmsActivities,
      },
    ];
  }
  return [{ group: activityCategoryLabels.custom, activities: customActivities }];
}

module.exports = {
  getApplicableActivityCodes,
  getApplicableActivityCodesForRHSFeeModal,
};
