'use strict';

const { ALL_STATES } = require('@sb-itops/region');
const { shouldShowOriginalTrust } = require('../bank-account/services');

/**
 * For a given matterId, returns the default (and active) payment trust account according to these rules:
 * 1. If there is only 1 trust account and it has no location set
 * 2. If a matter has default payment trust set
 * 3. Check default trust account for the matter location
 * Based on: smokeball-billing-web/src/redux/selectors/find-trust-account.js
 *
 * @param {object} params
 * @param {string} params.matterLocationId
 * @param {object} [params.matterDefaultTrustAccountId]
 * @param {Array<object>} params.activeTrustAccounts
 * @param {Array<{defaultTrustAccountId: string, location: string}>} params.defaultTrustAccountForLocations
 * @returns an object of BankAccount or undefined
 */
const findDefaultTrustAccountForMatter = ({
  matterLocationId,
  matterDefaultTrustAccountId,
  activeTrustAccounts,
  defaultTrustAccountForLocations,
}) => {
  if (!matterLocationId || !Array.isArray(activeTrustAccounts)) {
    return undefined;
  }

  if (shouldShowOriginalTrust(activeTrustAccounts)) {
    // if we get here, we have only 1 active account and it has no location
    return activeTrustAccounts[0];
  }

  const trustAccountsMap = activeTrustAccounts.reduce((acc, trustAccount) => {
    acc[trustAccount.id] = trustAccount;
    return acc;
  }, {});

  // 2. Check if a matter has default payment trust set
  const trustAccountForMatter = trustAccountsMap[matterDefaultTrustAccountId];
  if (trustAccountForMatter) {
    return trustAccountForMatter;
  }

  // fall through, if no default trust account for matter set or the default trust account is closed
  // 3. Check default trust account for general location
  const locationDefaultTrustAccountId = matterLocationId
    ? (defaultTrustAccountForLocations || []).find((account) => account.location === matterLocationId)
        ?.defaultTrustAccountId
    : undefined;

  const locationDefaultTrustAccount = trustAccountsMap[locationDefaultTrustAccountId];
  if (locationDefaultTrustAccount) {
    return locationDefaultTrustAccount;
  }

  // 4. Check default trust account for ALL_STATES
  const allStatesDefaultTrustAccountId = (defaultTrustAccountForLocations || []).find(
    (account) => account.location === ALL_STATES.value,
  )?.defaultTrustAccountId;

  const allStatesDefaultTrustAccount = trustAccountsMap[allStatesDefaultTrustAccountId];
  if (allStatesDefaultTrustAccount) {
    return allStatesDefaultTrustAccount;
  }

  return undefined;
};

module.exports = {
  findDefaultTrustAccountForMatter,
};
