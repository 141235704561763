'use strict';

const dateFilterTypes = {
  TODAY: 'TODAY',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  BEFORE: 'BEFORE',
  CUSTOM: 'CUSTOM',
};

module.exports = {
  dateFilterTypes,
};
