import React from 'react';

import { SlidingToggle } from '@sb-itops/react';

type TProps = { dailyDigestOn: boolean; toggleDigestPreference: () => void; sendNow: () => void };

export const DailyDigestPreference = ({ dailyDigestOn, toggleDigestPreference, sendNow }: TProps) => (
  <div>
    <label>Daily Digest</label>
    <p>The Daily Digest email is a summary of your activity for the day, sent to your inbox every morning.</p>
    <SlidingToggle scope="daily-digest-preference-toggle" onChange={toggleDigestPreference} selected={dailyDigestOn} />
    <div>
      <button className="link-button" type="button" onClick={sendNow}>
        Send email now
      </button>
    </div>
  </div>
);
