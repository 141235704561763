/* eslint-disable import/no-cycle */
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { capitalize } from '@sb-itops/nodash';
import { getSchemeValue } from '@sb-itops/region-schemes';
import { hasDetailedAddresses } from '@sb-customer-management/business-logic/contacts/services/has-detailed-addresses';
import { companyContactsUseOrgKey } from '@sb-customer-management/business-logic/contacts/services/company-contacts-use-org-key';
import { fetchById as fetchContactById } from './index';

// Contact summary cache
const { opdateCache: opdateSummaryCache } = optimisticUpdateFactory({
  ngCacheName: 'sbContactsMbService',
  keyPath: 'entityId',
});

// Contact entity cache (includes address)
// Yes it's called the 'simple' service, it is actually the more complex one
const { opdateCache: opdateEntityCache } = optimisticUpdateFactory({
  ngCacheName: 'sbSimpleContactMbService',
  keyPath: 'entityId',
});

const formatPhoneNumber = (areaCode, number) => {
  if (!areaCode && !number) return '';
  if (areaCode && !number) return areaCode;
  if (!areaCode && number) return number;
  return `(${areaCode}) ${number}`;
};

const getDisplayName = (contact) =>
  contact.entityName || `${capitalize(contact.firstName)} ${capitalize(contact.lastName)}`;

export const generateContactSummaryOpdate = ({ id, contact }) => {
  if (contact.contactType === contactTypes.PERSON) {
    return {
      entityId: id,
      removed: false,
      displayName: `${contact.firstName} ${contact.lastName}`,
      type: contactTypes.PERSON,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: formatPhoneNumber(contact.phoneAreaCode, contact.phoneNumber),
      cell: formatPhoneNumber(contact.cellAreaCode, contact.cellNumber),
      representativeOfs: (contact.linkedCompany && [contact.linkedCompany]) || [],
    };
  }
  if (contact.contactType === contactTypes.COMPANY) {
    return {
      entityId: id,
      removed: false,
      displayName: contact.companyName,
      type: contactTypes.COMPANY,
      email: contact.companyEmail,
      phone: formatPhoneNumber(contact.companyPhoneAreaCode, contact.companyPhoneNumber),
      representativeOfs: [],
    };
  }
  if (contact.contactType === contactTypes.TRUST) {
    return {
      entityId: id,
      removed: false,
      displayName: contact.trustName,
      type: contactTypes.TRUST,
      phone: formatPhoneNumber(contact.trustPhoneAreaCode, contact.trustPhoneNumber),
      representativeOfs: [],
    };
  }
  return {};
};

export const generateContactEntityOpdate = ({ id, contact, linkedOrg = undefined, peopleArr = undefined }) => {
  const addressScheme = getSchemeValue('addressScheme');
  const supportsDetailedAddress = hasDetailedAddresses(addressScheme);
  let detailedStreetAddress;
  if (supportsDetailedAddress) {
    detailedStreetAddress = {
      streetNumber: contact.streetNumber,
      streetName: contact.streetName,
      streetType: contact.streetType,
    };
  }

  const address = {
    addressLine1: supportsDetailedAddress ? undefined : contact.addressLine1,
    addressLine2: supportsDetailedAddress ? undefined : contact.addressLine2,
    city: contact.city,
    state: contact.state,
    zipCode: contact.zipCode,
    detailedStreetAddress,
  };

  let entity = {
    id,
  };

  if (contact.contactType === contactTypes.PERSON) {
    entity = {
      ...entity,
      entityName: getDisplayName(contact), // for entity opdate
      displayName: getDisplayName(contact), // for related entity opdate
      removed: false,
      person: {
        firstName: contact.firstName,
        lastName: contact.lastName,
        birthDate: contact.birthDate,
        residentialAddress: address,
        phone: {
          areaCode: contact.phoneAreaCode,
          localNumber: contact.phoneNumber,
        },
        cell: {
          areaCode: contact.cellAreaCode,
          localNumber: contact.cellNumber,
        },
        email: contact.email,
      },
    };
    if (linkedOrg) {
      // Note: it is entity.businessAddress NOT entity.person.businessAddress, that is what the contacts page reads from
      entity.businessAddress = linkedOrg.organisation?.businessAddress || linkedOrg.company?.businessAddress;
    }
  } else if (contact.contactType === contactTypes.COMPANY) {
    let peopleEntities = [];
    if (peopleArr && peopleArr.length) {
      peopleEntities = peopleArr.map((person) => ({
        entity: generateContactEntityOpdate({ id: person.id, contact: person }),
      }));
    }
    const company = {
      name: contact.entityName || contact.companyName,
      businessAddress: address,
    };

    const useOrgKey = companyContactsUseOrgKey(addressScheme);

    if (useOrgKey) {
      entity = {
        ...entity,
        people: peopleEntities,
        organisation: company,
      };
    } else {
      entity = {
        ...entity,
        people: peopleEntities,
        company,
      };
    }
  } else if (contact.contactType === contactTypes.TRUST) {
    let peopleEntities = [];
    if (peopleArr && peopleArr.length) {
      peopleEntities = peopleArr.map((person) => ({
        entity: generateContactEntityOpdate({ id: person.id, contact: person }),
      }));
    }

    entity = {
      ...entity,
      entityName: contact.trustName, // for entity opdate
      displayName: contact.trustName, // for related entity opdate
      people: peopleEntities,
      trust: {
        name: contact.trustName,
        address,
        phone: {
          areaCode: contact.phoneAreaCode,
          localNumber: contact.phoneNumber,
        },
        cell: {
          areaCode: contact.cellAreaCode,
          localNumber: contact.cellNumber,
        },
      },
    };
  }

  return entity;
};

export const createCompanyContact = (companyEntity, people = []) =>
  store.dispatch(async () => {
    const path = `/customer-management/contact/:accountId/company`;
    const message = {
      companyEntity,
      people,
    };
    const fetchOptions = { body: JSON.stringify(message) };
    const res = await fetchPostP({ path, fetchOptions, responseType: 'text' });

    const resBody = JSON.parse(res?.body);
    const companyId = resBody?.id;
    const peopleArr = resBody?.people || [];

    // minimal opdate entity to provide user with instant feedback on UI
    const opdateSummaryEntity = generateContactSummaryOpdate({ id: companyId, contact: companyEntity });
    const peopleOpdateEntities = people.map((person) =>
      generateContactSummaryOpdate({ id: person.id, contact: person }),
    );
    opdateSummaryEntity.people = peopleOpdateEntities;
    opdateSummaryCache({ optimisticEntities: [opdateSummaryEntity] });

    const opdateEntity = generateContactEntityOpdate({ id: companyId, contact: companyEntity, peopleArr });
    opdateEntityCache({ optimisticEntities: [opdateEntity] });

    // Need to return an array to also get ids of any created people
    return [companyId, ...peopleArr.map((contact) => contact.id)];
  });

export const createContact = (contactEntity) =>
  store.dispatch(async () => {
    const path = `/customer-management/contact/:accountId/person`;
    const fetchOptions = { body: JSON.stringify(contactEntity) };
    const res = await fetchPostP({ path, fetchOptions });

    // minimal opdate entity to provide user with instant feedback on UI
    const opdateSummaryEntity = generateContactSummaryOpdate({ id: res.body.id, contact: contactEntity });
    opdateSummaryCache({ optimisticEntities: [opdateSummaryEntity] });

    const opdateEntity = generateContactEntityOpdate({ id: res.body.id, contact: contactEntity });
    const opdateEntities = [opdateEntity];
    if (contactEntity.linkedCompany) {
      // May be added through a company contact page to a company - need to opdate contact cards which reads entity.people
      const cachedCompanyEntity = await fetchContactById(contactEntity.linkedCompany);
      if (cachedCompanyEntity.people && Array.isArray(cachedCompanyEntity.people)) {
        cachedCompanyEntity.people.push(opdateEntity);
      } else {
        cachedCompanyEntity.people = [opdateEntity];
      }
      opdateEntities.push(cachedCompanyEntity);
    }
    opdateEntityCache({ optimisticEntities: opdateEntities });

    return res.body.id;
  });
