'use strict';

const paymentMethodByName = Object.freeze({
  NONE: 0,
  CHEQUE: 1,
  BANK_TRANSFER: 2,
  DIRECT_DEBIT: 3,
});

const paymentMethodByValue = Object.freeze({ 0: 'NONE', 1: 'CHEQUE', 2: 'BANK_TRANSFER', 3: 'DIRECT_DEBIT' });

const paymentMethodLabel = Object.freeze({ 0: 'None', 1: 'Cheque', 2: 'Bank Transfer', 3: 'Direct Debit' });

module.exports = { paymentMethodByName, paymentMethodByValue, paymentMethodLabel };
