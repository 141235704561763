angular.module('sb.billing.webapp').component('sbCombinedPdfBox', {
  bindings: {operation: '<', dismissFn: '&?'},
  templateUrl: 'ng-components/async-operation-bar/combined-pdf-box/combined-pdf-box.html',
  controller: function($state, sbMessageDisplayService) {
    const ctrl = this;
    ctrl.onClick = onClick;

    ctrl.$onInit = () => {
      ctrl.dismissFn = _.isFunction(ctrl.dismissFn) ? ctrl.dismissFn : _.noop;
    };

    function onClick() {
      if (!ctrl.operation.data.isComplete) {
        return;
      }

      if (ctrl.operation.data.error) {
        sbMessageDisplayService.error('Failed to combine PDF');
      }
      else {
        $state.go('home.billing.view-combined-pdf', {
          operationId: ctrl.operation.id
        });
      }

      ctrl.dismissFn(ctrl.operation);
    }
  }
});
