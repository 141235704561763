import { cacheFactory, syncTypes, indexTypes } from '@sb-itops/redux';
import Big from 'big.js';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'trust-cheques',
  keyPath: 'chequeId',
  ngCacheName: 'sbTrustChequeService',
  syncType: syncTypes.SINCE,
  immutable: false,
  indexes: [
    {
      name: 'byChequeNumber',
      indexer: (trustCheque) =>
        Number.isNaN(+trustCheque.chequeNumber) ? trustCheque.chequeNumber : +trustCheque.chequeNumber,
      type: indexTypes.ONE_TO_ONE,
    },
    {
      name: 'byBankAccount',
      indexer: (trustCheque) => trustCheque.bankAccountId,
      type: indexTypes.ONE_TO_MANY,
      reducer: (accumulator, trustCheque) => {
        const chequeNumber = Number.isNaN(+trustCheque.chequeNumber)
          ? trustCheque.chequeNumber
          : +trustCheque.chequeNumber;

        const newEntity = {
          ...accumulator,
          [chequeNumber]: trustCheque,
        };

        return newEntity;
      },
    },
  ],
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getByBankAccountId = (bankAccountId) => getList().filter((ch) => ch.bankAccountId === bankAccountId);

export const getByChequeNumberBankAccountId = (chequeNumber, bankAccountId) => {
  const dataByBankAccountId = api.getIndex('byBankAccount');
  const dataByChequeNumber = dataByBankAccountId[bankAccountId];

  // If chequeNumber is a Big.js object, convert it to string
  if (chequeNumber instanceof Big) {
    return dataByChequeNumber && dataByChequeNumber[chequeNumber.toString()];
  }

  return dataByChequeNumber && dataByChequeNumber[+chequeNumber];
};

export const chequeExistsForBankAccount = (chequeNumber, bankAccountId) =>
  !!getByChequeNumberBankAccountId(chequeNumber, bankAccountId);
