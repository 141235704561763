/**
 * LOD contact address checker
 *
 * Legacy version here: monorepo/customer-management/modules/contact-address-checker/src/gb-contact-address-checker.js
 */

function contactHasStreetOrPOAddress(contact) {
  if (contact?.type === 'person' || contact?.type === 'groupOfPeople') {
    return personOrGroupOfPeopleHasStreetAddress(contact) || personOrGroupOfPeopleHasPoBoxAddress(contact);
  }

  if (contact?.type === 'company') {
    return companyHasStreetAddress(contact) || companyHasPOBoxAddress(contact);
  }

  return false;
}

function findContactsWithMissingStreetOrPOAddress(contacts) {
  const contactsWithMissingStreetAddress = (contacts || []).filter((contact) => !contactHasStreetOrPOAddress(contact));
  return contactsWithMissingStreetAddress;
}

/**
 * Helper functions
 */

const isNotEmptyField = (addressComponent) => addressComponent && addressComponent.trim();

/** Company */
// Street address
function companyHasStreetAddress(company) {
  const { city, zipCode } = company?.businessAddress ?? {};
  const { streetName } = company?.businessAddress?.detailedStreetAddress ?? {};

  const result = [city, zipCode, streetName].every((addressComponent) => isNotEmptyField(addressComponent));

  return result;
}

// PO box
function companyHasPOBoxAddress(company) {
  // For GB, pOBoxType and state aren't needed (like in AU)
  const { pOBoxNumber, city, zipCode } = company?.businessAddress?.pOBoxAddress ?? {};

  const result = [city, zipCode, pOBoxNumber].every((addressComponent) => isNotEmptyField(addressComponent));

  return result;
}

/** Person or GroupOfPeople contact */
// Street address
function personOrGroupOfPeopleHasStreetAddress(personOrGroupOfPeople) {
  const { city, zipCode } = personOrGroupOfPeople?.residentialAddress ?? {};
  const { streetName } = personOrGroupOfPeople?.residentialAddress?.detailedStreetAddress ?? {};

  const result = [city, zipCode, streetName].every((addressComponent) => isNotEmptyField(addressComponent));

  return result;
}

// PO box
function personOrGroupOfPeopleHasPoBoxAddress(personOrGroupOfPeople) {
  // For GB, pOBoxType and state aren't needed (like in AU)
  const { pOBoxNumber, city, zipCode } = personOrGroupOfPeople?.residentialAddress?.pOBoxAddress ?? {};

  const result = [city, zipCode, pOBoxNumber].every((addressComponent) => isNotEmptyField(addressComponent));

  return result;
}

module.exports = {
  contactHasStreetOrPOAddress,
  findContactsWithMissingStreetOrPOAddress,
};
