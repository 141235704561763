angular.module('sb.billing.webapp').component('sbDataCaches', {
    'require': {
      sbComposeCtrl: '^sbCompose'
    },
    'bindings': {composeKey: '@'},
    'controller': function ($scope, sbLoggerService, sbGenericCacheService) {
      'use strict';

      const that = this;
      const log = sbLoggerService.getLogger('sbDataCaches');
      const { names, query } = sbGenericCacheService.cacheQuery;

      that.$onInit = function () {
        if (_.isEmpty(that.composeKey)) {
          throw new Error('Cannot create sbDataFees with invalid compose key');
        }

        log.info('composeKey', that.composeKey);
        updateData();
      };

      function updateData(){
        const caches = [];

        _.each(_.sortBy(names()), (cache) => {
          caches.push({
            name: cache,
            display: `${cache} (${_.size(query(cache))})`
          });
        });

        that.sbComposeCtrl.setComposeData(caches, that.composeKey);
      }
    }
  }
);
