'use strict';

/**
 * Derive transaction ids from consolidated trust transactions so these can be used for
 * 1) bank rec setup - SaveBankReconciliationSetup
 * 2) saving bank rec - SaveBankReconciliation
 * @param {string[]} consolidatedTrustTransactionIds
 * @param {Object[]} consolidatedTrustTransactions
 */
function deriveTransactionIdsFromConsolidatedTrustTransactions(
  consolidatedTrustTransactionIds,
  consolidatedTrustTransactions,
) {
  // Trust to office transfer transactions created for each matter as part of the same transfer
  // are grouped into a pseudo transaction on the UI to help user identify the corresponding
  // bank transaction that they'll be reconciling against. These transactions don't actually exist
  // and should be replaced with the actual transaction ids. This function will pass through any ids
  // not found in allConsolidatedTrustTransactions.

  const txnMap = consolidatedTrustTransactions.reduce((map, txn) => {
    // eslint-disable-next-line no-param-reassign
    map[txn.id] = txn;
    return map;
  }, {});

  // NB: selected transaction ids for deposit slip may not exist in the transactions
  // list passed in to this function, these needs to be passed through
  const sanitisedIds = consolidatedTrustTransactionIds.reduce((acc, id) => {
    const txn = txnMap[id];
    const ids = txn && txn.isConsolidatedTrustToOffice ? txn.transactionIds : [id];
    acc.push(...ids);
    return acc;
  }, []);

  return sanitisedIds;
}

module.exports = {
  deriveTransactionIdsFromConsolidatedTrustTransactions,
};
