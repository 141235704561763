import Styles from './AddEditMatterForm.module.scss';

// All dirty field should show errors instantly, fields not changed should show error upon submission
//
// Example from Sam: for required fields, we don't want them to be shown as an error if they field is still clean, but there are two ways it'll error:
// 1. If you edit the field, and then remove all of your text - in this case it'll be dirty and empty, so can show an instant error
// 2. If you never touch the field, it'll be clean and so no chance to show an instant error. In this case, the submit button will capture and trigger it.
export const shouldShowFieldError = (field, submitFailed) => field.isInvalid && (field.isDirty || submitFailed);
export const includeErrorClassnameIfInvalid = (field, submitFailed) =>
  shouldShowFieldError(field, submitFailed) ? Styles.hasError : undefined;
