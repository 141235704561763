import { TOGGLE_SHOW_FILTERS, SET_FILTER, RESET_FILTER, TOGGLE_HIDE_FILTERS } from './types';

export const setFilter = (filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { filterName, filterValue },
});

export const toggleShowFilters = () => ({
  type: TOGGLE_SHOW_FILTERS,
  payload: {},
});

export const toggleHideFilters = (event) => ({
  type: TOGGLE_HIDE_FILTERS,
  payload: { key: event.currentTarget.id },
});

export const resetFilters = () => ({
  type: RESET_FILTER,
  payload: {},
});
