const HtmlTags = require('./html-tags.module');

// eslint-disable-next-line no-unused-vars
const DEFAULT_TAG = { openingRtf: '{\\pard', closingRtf: '\\sb70\\par}' };

class AllowedHtmlTags {
  static getRtfReferenceTag(tagName, parentTagName) {
    let allowedTag;

    tagName = tagName.toLowerCase();
    allowedTag = this.getAllowedTag(tagName);

    if (allowedTag) {
      if (tagName == allowedTag.opening) {
        return allowedTag[parentTagName] || allowedTag.openingRtf;
      }
      return allowedTag[`${parentTagName}Closing`] || allowedTag.closingRtf;
    }
    return undefined;
  }

  static isKnowedTag(tag) {
    const isKnowedTag = this.getAllowedTag(tag);
    return Boolean(isKnowedTag);
  }

  static getAllowedTag(tag) {
    tag = tag.toLowerCase();
    return HtmlTags.find((knowedTag) => knowedTag.opening == tag || knowedTag.closing == tag);
  }
}
module.exports = AllowedHtmlTags;
