'use strict';

import { resourceIds } from "@sb-itops/business-logic/authorisation";

angular.module('sb.billing.webapp').controller('ExternalReportsController', function ($location) {
  const ctrl = this;

  ctrl.onStateOverrideChange = onStateOverrideChange;
  ctrl.authorizationResourceIds = resourceIds;
  ctrl.stateOverride = $location.search() || {};

  function onStateOverrideChange(newState) {
    $location.url($location.path());
    Object.entries(newState).forEach(([key, value]) => $location.search(key, value));
  }
});
