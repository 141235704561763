import { gql } from '@apollo/client';

const query = gql`
  query MicrosoftIdentity {
    microsoftIdentity {
      id
      microsoftId
      authorizedScopes
    }
  }
`;

const notificationIds = ['CalendarSyncSettingsNotifications.MicrosoftIdentityUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const MicrosoftIdentity = {
  query,
  notificationIds,
};
