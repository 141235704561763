/* eslint-disable import/no-cycle */
import { fetchPostThunkFactory } from '@sb-itops/redux/fetch/thunk-factory';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';

import { getById as getOperatingChequeById } from './index';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbOperatingChequesService',
  keyPath: 'id',
});

export const printOperatingCheques = ({
  bankAccountId,
  firstChequeNumber,
  chequeDateOverride,
  padding,
  operatingCheques,
  allowChequeNumberDuplication,
}) => {
  const printOperatingChequesMessage = {
    bankAccountId,
    firstChequeNumber,
    chequeDateOverride,
    padding,
    operatingCheques,
    allowChequeNumberDuplication,
  };

  // This is the only thing special with each opdates
  const operatingChequesOptimisticEntities = operatingCheques.map((operatingCheque) => {
    const storedOperatingCheque = getOperatingChequeById(operatingCheque.chequeId);
    return {
      ...storedOperatingCheque,
      chequeMemo: operatingCheque.chequeMemo,
      isPrinted: true,
    };
  });

  // DISCUSS: The code below looks like it could be generalised into something more developer
  // friendly, e.g. postAndOpdate({path, body, ngCacheName, ngCacheKeyPath, optimisticEntities})
  // the underlying implementation can be as below, this is unless if there are other use cases.
  const opdateCaches = () => {
    opdateCache({ optimisticEntities: operatingChequesOptimisticEntities });

    return () => {
      rollbackOpdateCache({ optimisticEntities: operatingChequesOptimisticEntities });
    };
  };

  fetchPostThunkFactory({
    path: '/billing/operating-cheques/print/:accountId/',
    opdateCaches,
  })(printOperatingChequesMessage);

  return operatingChequesOptimisticEntities;
};
