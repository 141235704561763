'use strict';

const { entryType } = require('../entities/entry-type');

function hasUnpaidAnticipatedDisbursements({ invoice, getExpenseById, getExpensePaymentDetailsById }) {
  const entries = (invoice && invoice.entries) || [];
  const expenseEntries = entries.filter((entry) => entry.type === entryType.EXPENSE);

  return expenseEntries.some((expenseEntry) => {
    const expense = getExpenseById(expenseEntry.id);
    const expensePaymentDetails = getExpensePaymentDetailsById(expenseEntry.id);
    return expense.isAnticipated && !expensePaymentDetails?.isPaid;
  });
}

module.exports = {
  hasUnpaidAnticipatedDisbursements,
};
