import { NewLeadsWidgetContainer as NewLeadsWidget } from './NewLeadsWidget.container';
import { NewMattersWidgetContainer as NewMattersWidget } from './NewMattersWidget.container';
import { MattersWithNoActivityWidgetContainer as MattersWithNoActivityWidget } from './MattersWithNoActivityWidget.container';
import { LeadsWithNoActivityWidgetContainer as LeadsWithNoActivityWidget } from './LeadsWithNoActivityWidget.container';
import { RecentDocumentsWidgetContainer as RecentDocumentsWidget } from './RecentDocumentsWidget.container';
import { RecentlyViewedWidgetContainer as RecentlyViewedWidget } from './RecentlyViewedWidget.container';
import { BillableHoursWidgetContainer as BillableHoursWidget } from './BillableHoursWidget.container';
import { EventsWidgetContainer as EventsWidget } from './EventsWidget.container';
import { TasksWidgetContainer as TasksWidget } from './TasksWidget.container';
import { BillableGraphWidgetContainer as BillableGraphWidget } from './BillableGraphWidget.container';
import { DailyDigestWidgetContainer as DailyDigestWidget } from './DailyDigestWidget.container';
import { BillableOverviewWidgetContainer as BillableOverviewWidget } from './BillableOverviewWidget.container';
import { MemosWidgetContainer as MemosWidget } from './MemosWidget.container';
import { ClientInfoWidgetContainer as ClientInfoWidget } from './ClientInfoWidget.container';
import { AddWidgets } from './AddWidgets';

export {
  NewLeadsWidget,
  NewMattersWidget,
  MattersWithNoActivityWidget,
  LeadsWithNoActivityWidget,
  RecentDocumentsWidget,
  RecentlyViewedWidget,
  BillableHoursWidget,
  EventsWidget,
  TasksWidget,
  BillableGraphWidget,
  DailyDigestWidget,
  BillableOverviewWidget,
  MemosWidget,
  ClientInfoWidget,
  AddWidgets,
};
