'use strict';

const { isFirmCardSavingEnabledForBankAccount: isLawpayFirmCardSavingEnabledForBankAccount } = require('./lawpay');
const { isFirmCardSavingEnabledForBankAccount: isStripeFirmCardSavingEnabledForBankAccount } = require('./stripe');
const {
  isFirmCardSavingEnabledForBankAccount: isEzyCollectFirmCardSavingEnabledForBankAccount,
} = require('./ezy-collect');
const { isFirmCardSavingEnabledForBankAccount: isFeeWiseFirmCardSavingEnabledForBankAccount } = require('./fee-wise');
const { providers } = require('../entities/constants');

const firmCardSavingEnabledFns = {
  [providers.LAWPAY]: isLawpayFirmCardSavingEnabledForBankAccount,
  [providers.STRIPE]: isStripeFirmCardSavingEnabledForBankAccount,
  [providers.EZY_COLLECT]: isEzyCollectFirmCardSavingEnabledForBankAccount,
  [providers.FEE_WISE]: isFeeWiseFirmCardSavingEnabledForBankAccount,
};

/**
 * isFirmCardSavingEnabledForBankAccount
 * Returns true if the payment provider supports firm saving card for the specified bank account, false otherwise.
 * This can be used for example to allow firm save card on clien't behalf using SBB.
 *
 * @param {object} params
 * @param {object} params.formattedProviderSpecificSettings The current provider settings for the provider type being checked.
 * @param {object} params.providerType The provider type to check for payment provider availability
 * @param {string} params.bankAccountId The bank account id to check for payment provider availability
 * @param {string} params.bankAccountType The bank account type to check for payment provider availability
 * @returns {boolean}
 */
const isFirmCardSavingEnabledForBankAccount = ({
  formattedProviderSpecificSettings,
  providerType,
  bankAccountId,
  bankAccountType,
}) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!bankAccountId) {
    throw new Error('bankAccountId must be provided');
  }

  if (!formattedProviderSpecificSettings) {
    return false;
  }

  const firmCardSavingEnabledForBankAccountFn = firmCardSavingEnabledFns[providerType];
  if (!firmCardSavingEnabledForBankAccountFn) {
    throw new Error(`Cannot determine if firm card saving is enabled for provider type ${providerType}`);
  }

  return firmCardSavingEnabledForBankAccountFn({
    formattedProviderSpecificSettings,
    bankAccountId,
    bankAccountType,
  });
};

module.exports = {
  isFirmCardSavingEnabledForBankAccount,
};
