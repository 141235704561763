'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const billingUnitOptionValues = Object.freeze({
  FIRM_DEFAULT: 0,
  ONE_MINUTE: 1,
  SIX_MINUTES: 6,
  FIFTEEN_MINUTES: 15,
  CUSTOM: 999,
});

const billingUnitOptionLabels = Object.freeze({
  FIRM_DEFAULT: 'Firm default',
  ONE_MINUTE: '1 minute',
  SIX_MINUTES: '6 minutes',
  FIFTEEN_MINUTES: '15 minutes',
  CUSTOM: 'Custom',
});

const billingUnitOptions = getTypeAsOptions({
  typeLabels: billingUnitOptionLabels,
  typeValues: billingUnitOptionValues,
});

module.exports = {
  billingUnitOptionLabels,
  billingUnitOptionValues,
  billingUnitOptions,
};
