/* eslint-disable import/no-cycle */
import MenuListWithGroup from '../builders/GroupedMenuListVirtualizedBuilder';
import SelectMenuListBuilder from '../builders/SelectMenuListBuilder';
import InputBuilder from '../builders/InputBuilder';
import ClearIndicator from '../builders/ClearIndicator';
import MenuPortal from '../builders/MenuPortal';

const offset = 4;

export const menuListItemHeight = 40;

export const calculateMenuListHeight = ({
  maxHeight = 0,
  totalLength = 0,
  groupLength = 0,
  optionLabelHeight = 1,
  groupLabelHeight = 1,
}) => {
  const calculatedHeight =
    totalLength * optionLabelHeight - groupLength * Math.abs(optionLabelHeight - groupLabelHeight);
  return calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
};

export const calculateScrollIndex = ({ children, selected, valueGetter }) => {
  if (children && selected && valueGetter)
    return children.findIndex(
      (child) => (valueGetter(child) || valueGetter(child.props.data)) === valueGetter(selected),
    );
  return undefined;
};

export const calculateNextRowIndex = (prevFocusIndex = 0, nextIndex = 0, options = []) => {
  const goingDown = prevFocusIndex < nextIndex;
  const toRow = nextIndex + (goingDown ? offset : -offset);
  const listSize = options.length;
  const nextOffsetItem = toRow < listSize ? toRow : listSize;
  const prevOffsetItem = toRow > 0 ? toRow : 0;
  return goingDown ? nextOffsetItem : prevOffsetItem;
};

export const buildCustomizableComponents = (props) => {
  const components = {
    ClearIndicator,
    MenuPortal,
  };

  if (!props.showDropdown) {
    components.DropdownIndicator = () => null;
  }

  if (props.virtualizeList) {
    components.MenuList = props.formatGroupLabel
      ? MenuListWithGroup({
          formatGroup: props.formatGroupLabel,
          groupLabelHeight: props.groupLabelHeight,
          optionLabelHeight: props.optionLabelHeight,
          defaultValue: props.defaultValue,
          valueGetter: props.getOptionValue,
          listItemClassName: props.listItemClassName,
        })
      : SelectMenuListBuilder({
          optionLabelHeight: props.optionLabelHeight,
          defaultValue: props.defaultValue,
          valueGetter: props.getOptionValue,
          listItemClassName: props.listItemClassName,
          actionList: props.actionList,
        });
  }
  if (props.disableInputOnSelection || props.focusKey) {
    components.Input = InputBuilder(props);
  }
  if (props.optionRenderer) {
    components.Option = props.optionRenderer;
  }
  return components;
};

export const buildCustomStyles = (props) => {
  // taken from $gray-border-light
  const grayBorderLight = '#d8d8d8';
  const defaultStyles = {
    clearIndicator: (provided) => ({
      ...provided,
      color: '#222',
      padding: '7px',
      ':hover': {
        cursor: 'pointer',
        color: '#f22',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorContainer: () => ({
      padding: '7px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#222',
    }),
    control: (provided) => {
      const styles = {
        ...provided,
        '&:hover': {},
        borderRadius: props.showAdd ? 0 : '0.15em',
        borderColor: grayBorderLight,
        borderTopLeftRadius: '0.15em',
        borderBottomLeftRadius: '0.15em',
      };

      if (props.showAdd) {
        styles.borderRight = 'none';
        styles.flexGrow = '1';
      }

      return styles;
    },
    container: (provided) => {
      const styles = {
        ...provided,
      };

      styles.flexGrow = '1';

      return styles;
    },
  };

  return props.disableInputOnSelection
    ? {
        ...defaultStyles,
        dropdownIndicator: () => ({
          display: 'none',
        }),
      }
    : defaultStyles;
};
