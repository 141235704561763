import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner, useTranslation } from '@sb-itops/react';
import Styles from './ChequeSettings.module.scss';
import { TrustChequeSettingsSection, OperatingChequeSettingsSection } from './cheque-settings-section';

const ChequeSettings = React.memo((props) => {
  const { t } = useTranslation();
  const { scopeOperating, scopeTrust, onSave, formSubmitting, blockSending } = props;

  return (
    <div className={Styles.chequeSettings}>
      <h3>{t('trustAndOfficePaymentCheques')}</h3>
      <TrustChequeSettingsSection scope={scopeTrust} disabled={formSubmitting} />
      <OperatingChequeSettingsSection scope={scopeOperating} disabled={formSubmitting} />
      <div className="button-bar">
        <Button disabled={blockSending || formSubmitting} onClick={onSave}>
          Save {formSubmitting && <Spinner small />}
        </Button>
      </div>
    </div>
  );
});

ChequeSettings.displayName = 'ChequeSettings';

ChequeSettings.propTypes = {
  scopeOperating: PropTypes.string.isRequired,
  scopeTrust: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  blockSending: PropTypes.bool.isRequired,
};

export default ChequeSettings;
