import React from 'react';
import PropTypes from 'prop-types';
import { InputNumberFilter } from '@sb-itops/react/input-number-filter';
import { SlidingToggle } from '@sb-itops/react';

import Styles from './RemindersHideDebtorFilterDisplay.module.scss';

const RemindersHideDebtorFilterDisplay = ({
  remindedInDaysChecked,
  remindedInDays,
  invoicedInDaysChecked,
  invoicedInDays,
  hideMatterDebtorWithNoOverdueInvoices,
  onFilterChanged,
}) => {
  const filtersValues = {
    remindedInDaysChecked,
    remindedInDays,
    invoicedInDaysChecked,
    invoicedInDays,
    hideMatterDebtorWithNoOverdueInvoices,
  };

  const createNumberFilterChangeHandler =
    ({ field }) =>
    (newValue) => {
      filtersValues[field] = newValue;
      return onFilterChanged({ value: filtersValues[field], field });
    };

  const createToggleChangeHandler = ({ field, newValue }) => {
    filtersValues[field] = newValue;
    return onFilterChanged({ value: filtersValues[field], field });
  };

  return (
    <div className={Styles.hideDebtorFilterDisplay}>
      <div className={Styles.sliderRow}>
        <div className={Styles.slider}>
          Reminded within
          <InputNumberFilter
            classNames={Styles.numberFilter}
            min={1}
            max={999}
            value={remindedInDays}
            onChange={createNumberFilterChangeHandler({ field: 'remindedInDays' })}
          />
          day(s)
        </div>
        <SlidingToggle
          scope="remindedWithin"
          onChange={(key, newValue) => {
            createToggleChangeHandler({ field: 'remindedInDaysChecked', newValue });
          }}
          selected={remindedInDaysChecked}
        />
      </div>

      <div className={Styles.sliderRow}>
        <div className={Styles.slider}>
          Invoiced within
          <InputNumberFilter
            classNames={Styles.numberFilter}
            min={1}
            max={999}
            value={invoicedInDays}
            onChange={createNumberFilterChangeHandler({ field: 'invoicedInDays' })}
          />
          day(s)
        </div>
        <SlidingToggle
          scope="invoicedWithin"
          onChange={(key, newValue) => {
            createToggleChangeHandler({ field: 'invoicedInDaysChecked', newValue });
          }}
          selected={invoicedInDaysChecked}
        />
      </div>
    </div>
  );
};

RemindersHideDebtorFilterDisplay.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  remindedInDaysChecked: PropTypes.bool,
  remindedInDays: PropTypes.number,
  invoicedInDaysChecked: PropTypes.bool,
  invoicedInDays: PropTypes.number,
  hideMatterDebtorWithNoOverdueInvoices: PropTypes.bool,
};

RemindersHideDebtorFilterDisplay.defaultProps = {
  remindedInDays: undefined,
  remindedInDaysChecked: true,
  invoicedInDays: undefined,
  invoicedInDaysChecked: true,
  hideMatterDebtorWithNoOverdueInvoices: true,
};

export default React.memo(RemindersHideDebtorFilterDisplay);
