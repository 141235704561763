import { defaultPath } from './default-path';

export const SELECT_DATE_FILTER_TYPE = `${defaultPath}/SELECT_DATE_FILTER_TYPE`;
export const SELECT_BEFORE_DATE = `${defaultPath}/SELECT_BEFORE_DATE`;
export const SELECT_FROM_DATE = `${defaultPath}/SELECT_FROM_DATE`;
export const SELECT_TO_DATE = `${defaultPath}/SELECT_TO_DATE`;
export const SELECT_STAFF_IDS = `${defaultPath}/SELECT_STAFF_IDS`;
export const SET_HIDE_IF_ON_FINALISED_INVOICE = `${defaultPath}/SET_HIDE_IF_ON_FINALISED_INVOICE`;
export const SET_HIDE_IF_ON_A_CHECK = `${defaultPath}/SET_HIDE_IF_ON_A_CHECK`;
export const TOGGLE_SHOW_FILTERS = `${defaultPath}/TOGGLE_SHOW_FILTERS`;
export const SET_SHOW_DISBURSEMENTS = `${defaultPath}/SET_SHOW_DISBURSEMENTS`;
export const SET_SHOW_ANTICIPATED_DISBURSEMENTS = `${defaultPath}/SET_SHOW_ANTICIPATED_DISBURSEMENTS`;
export const SET_SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID = `${defaultPath}/SET_SHOW_ANTICIPATED_DISBURSEMENTS_UNPAID`;
export const SET_SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE = `${defaultPath}/SET_SHOW_ANTICIPATED_DISBURSEMENTS_OVERDUE`;
export const SET_SHOW_ANTICIPATED_DISBURSEMENTS_PAID = `${defaultPath}/SET_SHOW_ANTICIPATED_DISBURSEMENTS_PAID`;
