import React, { memo, useState } from 'react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import { Checkbox } from '@sb-itops/react/checkbox';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { sort as sortItems } from '@sb-itops/sort';
import { MattersDisplay } from '@sb-matter-management/react/matters-display';
import Styles from './UnprintedChequeTable.module.scss';

const {
  yyyymmddLocalisedRenderer,
  amountCellLocalisedRenderer,
  balanceCellLocalisedRenderer,
  checkboxCellWrapperRenderer,
} = utils;

const sortBy = ['effectiveDate', 'timestamp'];

const UnprintedChequeTable = memo(
  ({
    cheques,
    selectedChequeIds,
    overrideChequeMemos,
    onChequeSelected,
    onToggleAllCheques,
    onChequeMemoUpdated,
    onSortDirectionChanged,
    sortDirection,
    isFirstChequeNumberInvalid,
    // config
    showChequeMemo,
  }) => {
    const { t } = useTranslation();
    const sortedCheques = sortDirection ? sortItems(cheques, sortBy, [sortDirection, sortDirection]) : cheques;
    // We need to handle the memo state locally as the state is managed externally in
    // both angular (for trust cheques) and react/redux (for operating cheques).
    // Controlled react elements *need* to involve react state management, which react-redux does internally.
    // Angularjs, does not.
    const [memos, setMemos] = useState(overrideChequeMemos);

    const checkboxRenderer = ({ rowData }) => (
      <Checkbox
        checked={!!selectedChequeIds[rowData.chequeId]}
        disabled={isFirstChequeNumberInvalid}
        onChange={() => onChequeSelected(rowData, selectedChequeIds[rowData.chequeId])}
      />
    );

    const toggleAllSelectionRenderer = () => {
      const checked =
        cheques.length > 0 &&
        cheques.length ===
          Object.keys(selectedChequeIds).filter((selectedChequeId) => !!selectedChequeIds[selectedChequeId]).length;

      return (
        <input
          className="row-checkbox"
          type="checkbox"
          checked={checked}
          disabled={isFirstChequeNumberInvalid}
          onChange={(e) => {
            e.stopPropagation();
            onToggleAllCheques(!checked, cheques);
          }}
        />
      );
    };

    const mattersRenderer = ({ cellData }) => <MattersDisplay matterIds={cellData} />;

    const memoRenderer = ({ rowData }) => {
      // if the cheque memo has been overwritten, use the overwritten value
      // otherwise, use the value from the cheque
      const chequeMemo = memos[rowData.chequeId] !== undefined ? memos[rowData.chequeId] : rowData.chequeMemo || '';
      return (
        <input
          type="text"
          className={Styles.chequeMemoInput}
          value={chequeMemo}
          maxLength={87}
          onChange={(e) => {
            onChequeMemoUpdated(rowData.chequeId, e.target && e.target.value);
            setMemos({
              ...memos,
              [rowData.chequeId]: e.target && e.target.value,
            });
          }}
        />
      );
    };
    const footerSelected = ({ footerData }) => (
      <div className={Styles.selectedFooter}>{`${footerData.selected}/${footerData.size} SELECTED`}</div>
    );

    const summary = sortedCheques.reduce(
      (acc, cheque) => {
        if (selectedChequeIds[cheque.chequeId]) {
          acc.amount += cheque.amount;
          acc.selected += 1;
        }
        acc.size += 1;
        return acc;
      },
      { amount: 0, selected: 0, size: 0 },
    );

    return (
      <Table
        list={sortedCheques}
        className={Styles.chequeTable}
        sort={onSortDirectionChanged}
        summary={summary}
        sortBy="effectiveDate"
        sortDirection={sortDirection}
        showFooter
      >
        <Column
          key="selected"
          dataKey="selected"
          cellRenderer={checkboxCellWrapperRenderer(checkboxRenderer)}
          headerRenderer={checkboxCellWrapperRenderer(toggleAllSelectionRenderer)}
          width={34}
          footerRenderer={footerSelected}
          disableSort
        />
        <Column
          className=""
          dataKey="effectiveDate"
          label={`${capitalize(t('cheque'))} Date`}
          flexGrow={2}
          cellRenderer={yyyymmddLocalisedRenderer}
        />
        <Column dataKey="matterIds" label="Matter(s)" flexGrow={4} cellRenderer={mattersRenderer} disableSort />
        <Column dataKey="payee" label="Payee(s)" flexGrow={2} disableSort />
        <Column dataKey="reference" label="Reference" flexGrow={2} disableSort />
        {showChequeMemo && <Column dataKey="memo" label="Memo" flexGrow={2} cellRenderer={memoRenderer} disableSort />}
        <Column
          dataKey="amount"
          label="Amount"
          width={100}
          className={Styles.amountColumn}
          cellRenderer={amountCellLocalisedRenderer}
          footerRenderer={balanceCellLocalisedRenderer}
          disableSort
        />
      </Table>
    );
  },
);

UnprintedChequeTable.displayName = 'UnprintedChequeTable';

UnprintedChequeTable.propTypes = {
  cheques: PropTypes.arrayOf(
    PropTypes.shape({
      chequeId: PropTypes.string.isRequired,
      effectiveDate: PropTypes.number.isRequired,
      matterIds: PropTypes.array.isRequired,
      payee: PropTypes.string,
      reference: PropTypes.string,
      amount: PropTypes.number.isRequired,
      chequeMemo: PropTypes.string,
    }),
  ),
  selectedChequeIds: PropTypes.object,
  overrideChequeMemos: PropTypes.object,
  onChequeSelected: PropTypes.func.isRequired,
  onToggleAllCheques: PropTypes.func.isRequired,
  onChequeMemoUpdated: PropTypes.func.isRequired,
  onSortDirectionChanged: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  isFirstChequeNumberInvalid: PropTypes.bool,
  // This flag is in place as we are late in the release cycle and there
  // is a mismatch between the react & angular usages of this component with respect
  // to state management
  // Do not replicate this pattern.
  showChequeMemo: PropTypes.bool.isRequired,
};

UnprintedChequeTable.defaultProps = {
  cheques: [],
  selectedChequeIds: {},
  overrideChequeMemos: {},
  sortDirection: 'asc',
  isFirstChequeNumberInvalid: false,
};

export default UnprintedChequeTable;
