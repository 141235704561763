import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ModalDialog } from '@sb-itops/react/modal-dialog';

export const RestoreContactModalDialog = memo(({ onRestoreContact, restoreContactModalId }) => (
  <ModalDialog
    modalId={restoreContactModalId}
    body={
      <div>
        <p>This will restore this deleted contact. Do you wish to continue?</p>
      </div>
    }
    title="Restore Contact"
    buttons={{
      rightAlign: true,
      primary: {
        text: 'Yes',
      },
      secondary: {
        text: 'No',
      },
    }}
    onPrimaryClick={onRestoreContact}
  />
));

RestoreContactModalDialog.displayName = 'RestoreContactModalDialog';

RestoreContactModalDialog.propTypes = {
  onRestoreContact: PropTypes.func.isRequired,
  restoreContactModalId: PropTypes.string.isRequired,
};

RestoreContactModalDialog.defaultProps = {};
