import { validateMultiple as isMultipleEmailValidator, validateSingle as isSingleEmailValidator } from '@sb-itops/email';

// TODO for now, add it as an angular service, so we can require the validator without the absolute path. When angular is gone/lerna is added, we can remove this line and just require the class.
angular.module('sb.billing.webapp').factory('DataValidator', () => 

  // generic class to handle validations in the UI. we can create custom one or re-use the pre-existing ones. We just need to extend the class, create the validations we want.
  // remember to initialize the class after the model is initialized. Otherwise it will recognize the model dirty as default.
  class DataValidator {
    constructor(modelFn) {
      if (!_.isFunction(modelFn)) {
        throw new Error('Cannot create validator without valid model function');
      }

      this._modelDirty = false;

      this._modelFn = modelFn;
      this.errors = {};
      this.fields = [];
      this.validations = {};

      this._initialState = JSON.stringify(modelFn());

      this.clearAllErrors();
    }

    _getFieldNameFromPath(fieldPath){
      const fields = (fieldPath || '').split('.');
      return fields[fields.length - 1];
    }

    _getValidationName(fieldName){
      return `validate${_.capitalize(fieldName)}`;
    }

    _addValidation(fieldName, validationF){
        if(fieldName && validationF){
          this.fields = [...this.fields, fieldName]
          this.validations[this._getValidationName(fieldName)] = validationF;
        }
    }

    _standardValidator(fieldPath) {
      const fieldName = this._getFieldNameFromPath(fieldPath);
      this.errors[fieldName] = false;
      return (model, skipDirtyChecking) => {
        model = model || this._modelFn();
        this.errors[fieldName] = (skipDirtyChecking || this._isModelDirty()) && !this.exists(_.get(model, fieldPath));
        return !this.errors[fieldName];
      };
    }

    _conditionalValidator(fieldPath, fieldName, validator) {
      return (model, skipDirtyChecking) => {
        model = model || this._modelFn();
        const valid = _.get(model, fieldPath) ? validator(model, skipDirtyChecking) : true;
        this.errors[fieldName] = !valid;
        return valid;
      };
    }

    _singleEmailValidator(fieldPath) {
      const fieldName = this._getFieldNameFromPath(fieldPath);
      this.errors[fieldName] = false;
      return (model, skipDirtyChecking) => {
        model = model || this._modelFn();

        const email = (_.get(model, fieldPath) || '');
        const isValid = (skipDirtyChecking || this._isModelDirty()) && DataValidator.isSingleEmail(email);
        this.errors[fieldName] =  !isValid;
        return !this.errors[fieldName];
      };
    }

    _multipleEmailValidator(fieldPath) {
      const fieldName = this._getFieldNameFromPath(fieldPath);
      this.errors[fieldName] = false;
      return (model, skipDirtyChecking) => {
        model = model || this._modelFn();

        const emails = (_.get(model, fieldPath) || '');
        const isValid = (skipDirtyChecking || this._isModelDirty()) && DataValidator.isMultipleEmail(emails);
        this.errors[fieldName] =  !isValid;
        return !this.errors[fieldName];
      };
    }

    _conditionalExpressionValidator(fieldPath, expression, validator, clearer) {
      return (model, skipDirtyChecking) => {
        clearer();
        var fieldValue = _.get(model, fieldPath);
        return expression(fieldValue) ? validator(model, skipDirtyChecking) : true;
      };
    }

    _clearErrorExecutor(field) {
      return () => this.clearError(field);
    }

    // we do not use the dirty checking from the model anymore (is set by angular). We do it ourselves so we do not depends on the digest cycle.
    _isModelDirty(model) {
      model = model || this._modelFn();

      if(this._modelDirty){
        return true;
      }

      this._modelDirty = this._initialState != JSON.stringify(model);

      return this._modelDirty;
    }

    exists (value) {
      return value !== undefined && value !== null
    }

    clearAllErrors() {
      this.fields.forEach(fieldName => this.clearError(fieldName));
    }

    clearError(field) {
      this.errors[field] = false;
    }

    getValidatorFunction(fieldName){
      return this.validations[this._getValidationName(fieldName)]
    }

    validateField(fieldName, skipDirtyChecking) {
      const model = this._modelFn();
      const validatorF = this.getValidatorFunction(fieldName);
      if(validatorF){
        return validatorF(model, skipDirtyChecking)
      }
      return true;
    }

    validateAll(skipDirtyChecking) {
      const model = this._modelFn();
      let firstFailedField;

      this.fields.forEach(fieldName => {
        const isValid = this.getValidatorFunction(fieldName)(model, skipDirtyChecking);
        if (!firstFailedField && !isValid) {
          firstFailedField = fieldName;
        }
      });

      return firstFailedField;
    }

    static isSingleEmail(email){
      return isSingleEmailValidator(email);
    }

    static isMultipleEmail(emails){
      return isMultipleEmailValidator(emails);
    }
  }
);