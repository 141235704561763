import { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { todayAsInteger } from '@sb-itops/date';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import {
  printMethods as printMethodsList,
  notApplicablePrintMethodLocalised,
  PrintNow,
  PrintLater,
  PrintNotApplicable,
  PrintManually,
} from '@sb-billing/business-logic/cheques';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useTranslation } from '@sb-itops/react';
import { PAYMENT_TYPE, getDefaultPaymentSource, getPaymentSource } from '@sb-billing/business-logic/payment-source';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { AddInvoicePaymentModal } from './AddInvoicePaymentModal';
import { addInvoicePaymentFormSchema } from './AddInvoicePaymentForm.yup';
import { marshalData } from './marshal-data';

const log = getLogger('AddInvoicePaymentModal.forms.container');

const hooks = () => ({
  useConfig: () => ({
    showReasonField: hasFacet(facets.reasonField) && featureActive('BB-5508'),
  }),
  useAddPaymentForm: ({
    scope,
    bankAccountType,
    printCheques,
    onClickLink,
    onModalClose,

    createPDFReceiptOnTrustPayment,
    isTrustChequePrintingActiveForBankAccountId,
    isMatterContactBalanceFirm,
    operatingAccount,
    invoice,
    isInvoiceLoading,
    isMatterClientRequiredForTrustDeposit,
    isMatterDescriptionRequiredForTrustDeposit,

    paymentSourceOptions,
    paymentSourceOptionsLoading,
    onGetPaymentSourceOptions,

    lastTrustChequeNumber,
    nextTrustChequeNumber,
    trustChequeNumberBankAccountId,
    trustChequeNumberLoading,
    onFetchAvailableTrustChequeNumbers,

    bankReconciliationLatestCompletedByBankAccount,
    bankReconciliationSetup,
    bankReconciliationLoading,
    onFetchBankRecLatestCompleted,
  }) => {
    const { t } = useTranslation();
    const [reasonOverridden, setReasonOverridden] = useState(false);
    const [contactFallback, setContactFallback] = useState(undefined);
    const paymentSourceResetRef = useRef();

    const addPaymentForm = useForm({
      scope,
      schema: addInvoicePaymentFormSchema,
    });
    const {
      formFields,
      formInitialised,
      formSubmitting,
      formValues,
      submitFailed,
      formValid,
      onClearForm,
      onInitialiseForm,
      onValidateForm,
      onSubmitFormWithValidation,
    } = addPaymentForm;

    // Clear form on unload
    useEffect(
      () => () => onClearForm(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const printMethodOptions = useMemo(
      () =>
        [notApplicablePrintMethodLocalised(t), ...printMethodsList].map((pm) => ({
          label: pm.display,
          value: pm.value,
        })),
      [t],
    );

    const paymentSourceSelected = paymentSourceOptions.find((pso) => pso?.value === formValues?.paymentSourceId);

    const invoiceDueInCents = invoice?.totals?.unpaid || 0;

    const userId = getUserId();
    const allowOverdraw = hasFacet(facets.allowOverdraw);
    const supportsTtoNumbering = hasFacet(facets.ttoNumbering);
    const isChequeMemoVisible = deriveIsChequeMemoVisible(formValues?.printingMethodId);
    const isTrustChequePrintingActive = isTrustChequePrintingActiveForBankAccountId(
      paymentSourceSelected?.bankAccountId,
    );
    const isOverpaymentGoingToTrustAccount = !hasFacet(facets.operatingAccount);

    const amount = formValues?.amount || 0; // what the user is paying
    const balance = invoiceDueInCents - amount;
    const overpaymentAmount = Math.max(amount - invoiceDueInCents, 0);
    const isDirectPayment = paymentSourceSelected?.paymentType === PAYMENT_TYPE.direct;

    const validateForm = () => {
      // We can't use paymentSourceSelected here because it may have stale value at the time we call validateForm.
      // Therefore, we create map of paymentSourceOptions and pass it to the validation where the formValues are always up to date.
      const paymentSourceOptionsMap = paymentSourceOptions.reduce(
        (acc, paymentSource) => ({
          ...acc,
          [paymentSource.value]: paymentSource,
        }),
        {},
      );

      const validateCtx = {
        t,
        invoiceDueInCents,
        paymentSourceOptionsMap,
        allowOverdraw,
        isMatterContactBalanceFirm,
        reasonOnInvoicePaymentsEnabled: featureActive('BB-5508'),
        isTrustChequePrintingActiveForBankAccountId,
        isTrustToOfficeNumberingEnabled,
        supportsTtoNumbering,
        nextTrustChequeNumber,
        lastTrustChequeNumber,
        // both bank recs and bank recs setup are expected to be for specific (and same) trust account
        bankReconciliationLatestCompleted: bankReconciliationLatestCompletedByBankAccount,
        bankReconciliationSetup,
        matterClients: invoice?.matter?.clients ?? [],
        matterDescription: invoice?.matter?.description,
        overpaymentAmount,
        isMatterClientRequiredForTrustDeposit,
        isMatterDescriptionRequiredForTrustDeposit,
        isOverpaymentGoingToTrustAccount,
      };

      onValidateForm(validateCtx);
    };

    const onSetFieldValue = ({ field, value }) => {
      addPaymentForm.onFieldValueSet(field, value);
      if (submitFailed) {
        validateForm();
      }
    };
    const onUpdateFieldValues = (fieldValues) => {
      addPaymentForm.onUpdateFields(fieldValues);
      if (submitFailed) {
        validateForm();
      }
    };

    /**
     * Form initialisation
     */
    // Fetch payment sources after we have the matterId, before the form is initialised
    if (invoice?.matter?.id && !formInitialised && !paymentSourceOptionsLoading && !paymentSourceResetRef.current) {
      reloadPaymentSources({
        matterId: invoice?.matter?.id,
        effectiveDate: todayAsInteger(),
        shouldResetSelection: true,
        bankAccountType,
      });
    }

    const isReadyToInitialiseForm = !isInvoiceLoading && !formInitialised && !paymentSourceResetRef.current;

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultValues();
      onInitialiseForm(defaultValues);
      reloadPaymentSources({
        matterId: invoice?.matter?.id,
        effectiveDate: defaultValues.effectiveDate,
        shouldResetSelection: true,
        bankAccountType,
      });
    }

    // Some default values are assigned after this function executes
    //  * Specifically reloadPaymentSources is executed straight after
    //  * Check the above "isReadyToInitialiseForm" if block
    function getDefaultValues() {
      const debtor = invoice?.debtors?.length === 1 ? invoice.debtors[0]?.contact : undefined;
      setContactFallback(debtor);

      return {
        matterId: invoice?.matter?.id,
        paidById: undefined, // contactId. The default "paid by" contact is re-determined when the default payment source is assigned
        // We need to store both contact id and contact itself. This is so we can run validation against the id fields and error them,
        // but still have the contact object itself so we can add it in default contact options. When we set an object in the forms,
        // it doesn't keep the field properties (such as isInvalid, invalidReason etc.) so it is not the best for validation purposes.
        paidByContact: undefined,
        effectiveDate: todayAsInteger(),
        comment: undefined,
        amount: invoiceDueInCents, // The default amount is re-determined when the default payment source is assigned
        reference: undefined,
        reason: undefined,
        chequeMemo: undefined,
        paymentSourceId: undefined,
        paymentSource: undefined, // Similar to above - storing the object for easier access to data, but the paymentSourceId is used for validation
        printingMethodId: printMethodOptions?.[0]?.value,
        pdfOnTrustPayment: createPDFReceiptOnTrustPayment,
        waiveRemainingBalance: false,
        payorAmounts: undefined, // When a combined payment source is used, we need to track how much each payor contributes to the invoice (an object mapped by contactId and amount)
        // Form state specifically for validation
        matterClientMissingAddressWhenRequired: undefined,
        matterClientMissingWhenRequired: undefined,
        matterDescriptionMissingWhenRequired: undefined,
      };
    }

    const updateDefaultReason = ({ paymentSource }) => {
      if (reasonOverridden) {
        return;
      }

      let newReason = '';

      switch (paymentSource?.paymentType) {
        case PAYMENT_TYPE.trust:
          newReason = `${t('capitalize', { val: 'trustToOfficeTransferLabel' })} for costs and outlays`;
          break;
        case PAYMENT_TYPE.credit:
          newReason = `Credit applied to invoice #${invoice.invoiceNumber}`;
          break;
        default:
          break;
      }
      onUpdateFieldValues({ reason: newReason });
    };

    const defaultPaidByContactOptions = getContactTypeaheadDefaultOptions({
      contact: formValues.paidByContact,
      fallbackContact: contactFallback,
    });

    // Callbacks
    function reloadPaymentSources({ matterId, effectiveDate, shouldResetSelection = true }) {
      paymentSourceResetRef.current = shouldResetSelection;
      onGetPaymentSourceOptions({ matterId, effectiveDate });
    }

    /**
     * Form updates
     */

    const onChangePrintingMethod = (newPrintingMethod) => {
      const newPrintingMethodId = newPrintingMethod?.value;
      onUpdateFieldValues({ printingMethodId: newPrintingMethodId });

      if (paymentSourceSelected?.paymentType !== PAYMENT_TYPE.trust) {
        return;
      }

      if (
        isReferenceReadonly({
          paymentSource: paymentSourceSelected,
          printingMethodId: newPrintingMethodId,
          takePaymentNow: formValues?.takePaymentNow,
          isTrustChequePrintingActiveForBankAccountId,
        })
      ) {
        onUpdateFieldValues({ reference: '' });
      } else if (newPrintingMethodId === PrintManually) {
        onUpdateFieldValues({ reference: undefined }); // set to undefined so we know to set it to next number available

        // Fetch the cheque numbers if we don't already have the value for the selected trust account
        // Scenario: Set Print Manually and increment the default cheque number, then
        // change to Not Applicable, then change back to Print Manually
        if (
          !!paymentSourceSelected?.bankAccountId &&
          paymentSourceSelected.bankAccountId !== trustChequeNumberBankAccountId
        ) {
          onFetchAvailableTrustChequeNumbers({
            bankAccountId: paymentSourceSelected.bankAccountId,
            trustChequeReference: undefined,
          });
        }
      }
      // when readonlyReference is false and it is printNotApplicable we need to clear the reference, since this means that
      // the numbering is disabled
      else if (newPrintingMethodId === PrintNotApplicable) {
        onUpdateFieldValues({ reference: '' });
      }

      // "Print Now" should take priority over "open TTO pdf" action
      //  * If the "Print Now" option is selected, the "open TTO pdf" checkbox is disabled
      //  * If the "open TTO pdf" was previously selected, and the above occurs, the checkbox would still be selected
      //  * Uncheck the checkbox to avoid the misunderstanding that both actions will occur (only the "Print Now" will)
      const openTrustToOfficePdfIsChecked = formValues.pdfOnTrustPayment;
      if (openTrustToOfficePdfIsChecked && newPrintingMethodId === PrintNow) {
        onUpdateFieldValues({ pdfOnTrustPayment: false });
      }
    };

    const onReferenceChange = (newReference) => {
      // Skip validation as we need to perform it once we have fetched the
      // nextTrustChequeNumber value. This is provided via validateForm as
      // context to yup schema
      addPaymentForm.onUpdateFields({ reference: newReference });
      onFetchAvailableTrustChequeNumbers({
        bankAccountId: paymentSourceSelected.bankAccountId,
        trustChequeReference: newReference,
      });
    };

    // Changing the form value will trigger immediate validation before we can
    // fetch the new nextTrustChequeNumber value. Instead we wait until loading
    // is complete. Can't rely on nextTrustChequeNumber changing because if the
    // user enters any used value, nextTrustChequeNumber is likely going to stay
    // the same
    useEffect(() => {
      if (nextTrustChequeNumber && trustChequeNumberLoading === false) {
        validateForm();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextTrustChequeNumber, trustChequeNumberLoading, formValues?.reference]);

    useEffect(() => {
      if (bankReconciliationLoading === false) {
        validateForm();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankReconciliationLoading, bankReconciliationLatestCompletedByBankAccount, bankReconciliationSetup]);

    const onReasonChange = (newReason) => {
      setReasonOverridden(true);
      onUpdateFieldValues({ reason: newReason });
    };

    const onPaymentSourceChange = (newPaymentSource) => {
      const fieldsToUpdate = {
        paymentSourceId: newPaymentSource?.value,
      };
      // Cannot use fieldsToUpdate in cases where an attribute (e.g. payors) does not exist in the newPaymentSource, as the previous value will persist instead of being updated/overridden
      onSetFieldValue({ field: 'paymentSource', value: newPaymentSource });

      // [1] Cheque
      if (
        isReferenceReadonly({
          paymentSource: newPaymentSource,
          printingMethodId: formValues?.printingMethodId,
          // takePaymentNow: !!(activeProviderType && formValues?.takePaymentNow),
          isTrustChequePrintingActiveForBankAccountId,
        })
      ) {
        fieldsToUpdate.reference = '';
      } else if (
        newPaymentSource?.paymentType === PAYMENT_TYPE.trust &&
        formValues?.printingMethodId === PrintManually
      ) {
        onUpdateFieldValues({ reference: undefined }); // set to undefined so we know to set it to next number available
        onFetchAvailableTrustChequeNumbers({
          bankAccountId: newPaymentSource.bankAccountId,
          trustChequeReference: undefined,
        });
      }

      // [2] Trust accounts
      if (newPaymentSource?.paymentType === PAYMENT_TYPE.trust) {
        onFetchBankRecLatestCompleted({ bankAccountId: newPaymentSource?.bankAccountId });
      }

      // [3a] Matter contact balance
      // if the firm has matter contact balances, change the contactId if the
      // user has selected a non-direct source (trust or operating)
      if (isMatterContactBalanceFirm) {
        const paidByContact = newPaymentSource?.contactId
          ? { id: newPaymentSource.contactId, displayName: newPaymentSource.contactDisplayName }
          : contactFallback; // E.g. direct payment sources don't have a contactId
        fieldsToUpdate.paidById = paidByContact?.id;
        onSetFieldValue({ field: 'paidByContact', value: paidByContact });

        if (newPaymentSource.isCombinedBalance) {
          // Split payment mode enabled
          //  * The allocation determined by user
          //  * Reset any previous state (e.g. if switching to/back)
          onSetFieldValue({ field: 'amount', value: 0 });
          onSetFieldValue({ field: 'payorAmounts', value: undefined });
        }
      }
      // [3b] Matter balance
      else {
        const paidByContact = newPaymentSource?.paymentType === PAYMENT_TYPE.direct ? contactFallback : undefined;
        fieldsToUpdate.paidById = paidByContact?.id;
        onSetFieldValue({ field: 'paidByContact', value: paidByContact });
      }

      onUpdateFieldValues(fieldsToUpdate);
      updateDefaultReason({ paymentSource: newPaymentSource });
    };

    const onEffectiveDateChange = (newEffectiveDate) => {
      onUpdateFieldValues({ effectiveDate: newEffectiveDate });
      reloadPaymentSources({
        matterId: invoice?.matter?.id,
        effectiveDate: newEffectiveDate,
        shouldResetSelection: false,
      });
    };

    const onSelectPaidBy = (newPaidByOption) => {
      setContactFallback({ id: newPaidByOption?.value, displayName: newPaidByOption?.label });
      onUpdateFieldValues({ paidById: newPaidByOption?.value });
      onSetFieldValue({
        field: 'paidByContact',
        value: contactOptionToContact(newPaidByOption),
      });
    };

    // Reset payment sources selection when needed
    useEffect(() => {
      if (paymentSourceResetRef.current) {
        paymentSourceResetRef.current = false;

        // Add Invoice Payment logic is different from Add Payment modal logic
        const paymentSourceDefault =
          // if trust/operating account is specified (e.g. "pay now" link on RHS of view invoice screen)
          (bankAccountType && getPaymentSource(paymentSourceOptions, bankAccountType)) ||
          // gets default payment source if bank account is not specified as input to this component
          getDefaultPaymentSource(paymentSourceOptions);

        onPaymentSourceChange(paymentSourceDefault);

        // Set default amount
        onSetFieldValue({
          field: 'amount',
          value: deriveDefaultAmount({ paymentSource: paymentSourceDefault, invoice }),
        });

        // Set default payor when relevant
        const defaultDebtor = deriveDefaultPayor({ paymentSource: paymentSourceDefault, invoice });
        onSetFieldValue({
          field: 'paidById',
          value: defaultDebtor?.id,
        });
        onSetFieldValue({
          field: 'paidByContact',
          value: defaultDebtor,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentSourceOptions]);

    // Set reference to next available number. This is done here so we are sure we have new data already
    if (
      formValues?.printingMethodId === PrintManually &&
      !trustChequeNumberLoading &&
      formValues.reference === undefined &&
      !!nextTrustChequeNumber &&
      !!paymentSourceSelected?.bankAccountId &&
      paymentSourceSelected.bankAccountId === trustChequeNumberBankAccountId
    ) {
      addPaymentForm.onUpdateFields({ reference: nextTrustChequeNumber });
    }

    const onAddPayment = async () => {
      validateForm();

      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            // [1] Marshal data
            const data = marshalData({
              allowOverdraw,
              formData,
              invoice,
              isChequeMemoVisible,
              isMatterContactBalanceFirm,
              isTrustChequePrintingActive,
              operatingAccount,
              supportsTtoNumbering,
              userId,
            });

            // [2] Payment
            await addPayment(data);

            // [3] Post payment actions
            const trustPayment = data.sourceAccountType === PAYMENT_TYPE.trust;
            const isChequePrintNow = data.chequePrintActive && trustPayment && formData.printingMethodId === PrintNow;
            const openTrustPaymentPdf = trustPayment && data.pdfOnTrustPayment === true;

            // [3a] Print cheque
            //  * This takes priority over the "open TTO pdf" action
            if (isChequePrintNow) {
              const printChequeData = {
                chequeId: data.chequeId,
                bankAccountId: data.sourceBankAccountId,
              };

              printCheques(printChequeData);
            }
            // [3b] Open Trust Payment PDF
            else if (openTrustPaymentPdf) {
              if (data.payors?.length > 0) {
                // Matter Contact balance
                for (let i = 0; i < data.payors.length; i += 1) {
                  // await/setTimeout is needed otherwise only the TTO receipt of the last payor will be opened, instead of all of them
                  // eslint-disable-next-line no-await-in-loop
                  await new Promise((resolve) => {
                    setTimeout(() => {
                      onClickLink({
                        type: 'trustToOfficeTransferReceipt',
                        id: { transactionId: data.transactionId, paymentId: data.payors[i].paymentId },
                      });
                      resolve();
                    }, 0);
                  });
                }
              } else {
                // Matter balance
                onClickLink({
                  type: 'trustToOfficeTransferReceipt',
                  id: { transactionId: data.transactionId, paymentId: data.paymentId },
                });
              }
            }
            // [3c] Display success
            else {
              messageDisplay.success('Payment added successfully');
            }

            // [4] Close modal
            onModalClose();
          },
        });
      } catch (error) {
        log.error('Failed to process invoice payment', error);
        messageDisplay.error('Failed to process invoice payment');
      }
    };

    return {
      isSubmitDisabled: formSubmitting || bankReconciliationLoading || isInvoiceLoading || !formInitialised,
      isSubmitLocked: formSubmitting,
      isModalLoading: isInvoiceLoading || !formInitialised,
      isChequeMemoVisible,
      isReferenceReadOnly: isReferenceReadonly({
        paymentSource: paymentSourceSelected,
        printingMethodId: formValues?.printingMethodId,
        isTrustChequePrintingActiveForBankAccountId,
      }),
      isDirectPayment,
      isTrustPayment: paymentSourceSelected?.paymentType === PAYMENT_TYPE.trust,
      isPdfOnTrustPaymentDisabled: isPdfOnTrustPaymentDisabled(formValues?.printingMethodId),
      isTrustChequePrintingActive,
      isWaiveRemainingBalanceDisabled: balance <= 0,
      overdrawWarningProps: getOverdrawWarningProps({
        paymentSource: paymentSourceSelected,
        amount: formValues?.amount,
        matterId: formValues?.matterId,
      }),
      overpaymentAmount,
      overpaymentAccountLabel: isOverpaymentGoingToTrustAccount ? t('trustAccount') : t('operatingAccount'),
      paymentSourceSelected,
      // form
      formData: formValues,
      formErrors: formFields, // Contains data related to form errors
      submitFailed,
      formSubmitting,
      formValid,
      validateForm,
      onSubmitFormWithValidation,
      printMethodOptions,
      invoiceDueInCents,
      balance,
      footerErrorMessages: getFooterErrorMessages({
        formValues,
        formFields,
        submitFailed,
        t,
      }),
      // callbacks
      onUpdateFieldValues,
      onChangePrintingMethod,
      onReasonChange,
      onPaymentSourceChange,
      onEffectiveDateChange,
      onReferenceChange,
      onSelectPaidBy,
      onModalButtonClick: onAddPayment,
      // typeaheads
      defaultPaidByContactOptions,
    };
  },
});

const addPayment = async (data) => {
  const accountId = getAccountId();
  const payload = { ...data, userId: getUserId() };
  const path = `/billing/payment/${accountId}/invoice/${data.invoiceId}`;
  const fetchOptions = { body: JSON.stringify(payload) };

  await fetchPostP({ path, fetchOptions });
};

// Removed checkIsStatutoryDepositMatter check as SDMs cannot have invoices
const getOverdrawWarningProps = ({ paymentSource, amount }) => {
  if (paymentSource?.paymentType !== PAYMENT_TYPE.trust) {
    return {};
  }

  return {
    accountType: bankAccountTypeEnum.TRUST,
    amount,
    matterBankAccountBalance: paymentSource?.balanceAtDate || paymentSource?.balance,
    hasProtectedFunds: (paymentSource?.protectedBalance || 0) > 0,
  };
};

const getFooterErrorMessages = ({ formValues, formFields, submitFailed, t }) => {
  const footerErrorMessages = [];
  // We want to show reference error message even before submitFailed is true
  if (formValues?.reference !== undefined && formFields?.reference?.invalidReason) {
    footerErrorMessages.push(formFields?.reference?.invalidReason);
  }
  // We want to avoid default error message when field is empty or 0, we just error it out without a message
  if (
    submitFailed &&
    Number.isFinite(formValues?.amount) &&
    formValues?.amount > 0 &&
    formFields?.amount?.invalidReason
  ) {
    footerErrorMessages.push(formFields?.amount?.invalidReason);
  }
  // We want to avoid default error message when field is empty so we show error message only when it is not
  if (submitFailed && formValues?.effectiveDate && formFields?.effectiveDate?.invalidReason) {
    footerErrorMessages.push(formFields?.effectiveDate?.invalidReason);
  }

  const isMatterDescriptionMissingWhenRequired = formFields?.matterDescriptionMissingWhenRequired?.invalidReason;
  const isMatterClientMissingWhenRequired = formFields?.matterClientMissingWhenRequired?.invalidReason;

  // Error messages regarding overpayments from direct sources to a trust account
  // Combined error message
  if (submitFailed && isMatterDescriptionMissingWhenRequired && isMatterClientMissingWhenRequired) {
    footerErrorMessages.push(
      `A matter description and client has not been added to the matter. Please add before making a ${t(
        'trustAccount',
      ).toLowerCase()} deposit.`,
    );
  } else {
    // Individual error messages
    if (submitFailed && isMatterDescriptionMissingWhenRequired) {
      footerErrorMessages.push(formFields?.matterDescriptionMissingWhenRequired?.invalidReason);
    }

    if (submitFailed && isMatterClientMissingWhenRequired) {
      footerErrorMessages.push(formFields?.matterClientMissingWhenRequired?.invalidReason);
    }
  }

  if (submitFailed && formFields?.matterClientMissingAddressWhenRequired?.invalidReason) {
    footerErrorMessages.push(formFields?.matterClientMissingAddressWhenRequired?.invalidReason);
    footerErrorMessages.push(`Please ensure the street name, suburb/town, and postcode have been entered`);
  }

  return footerErrorMessages;
};

/**
 * The reference is read-only, if;
 * - The region uses TTO auto numbering, selected account is a trust account, print method is PrintNotApplicable OR
 * - The selected account is a trust account, trust cheque printing is enabled, and the printing method selected is 'Print Now' or 'Print Later' OR
 *
 * We double down on the "selected account is a trust account" just to be sure that the two concepts are independent.
 */
const isReferenceReadonly = ({ paymentSource, printingMethodId, isTrustChequePrintingActiveForBankAccountId }) => {
  const isReadOnlyCheque =
    paymentSource?.paymentType === PAYMENT_TYPE.trust &&
    isTrustChequePrintingActiveForBankAccountId(paymentSource.bankAccountId) &&
    (printingMethodId === PrintNow || printingMethodId === PrintLater);

  const isReadOnlyElectronicPayment =
    paymentSource?.paymentType === PAYMENT_TYPE.trust &&
    printingMethodId === PrintNotApplicable &&
    hasFacet(facets.ttoNumbering) &&
    isTrustToOfficeNumberingEnabled(paymentSource?.trustToOfficeNumberingSettings);

  return isReadOnlyCheque || isReadOnlyElectronicPayment;
};

const isTrustToOfficeNumberingEnabled = (trustToOfficeNumberingSettings) =>
  !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering;

const deriveIsChequeMemoVisible = (printingMethodId) =>
  hasFacet(facets.chequeMemo) && (printingMethodId === PrintNow || printingMethodId === PrintManually);

const isPdfOnTrustPaymentDisabled = (printingMethodId) =>
  // If Print Now trust cheques is selected, we disable the TTO receipt option as cheques take priority over the receipt
  // The receipt can be generated from accounts > trust > print detail if needed
  printingMethodId === PrintNow;

const contactOptionToContact = (contactOption) => {
  if (!contactOption) {
    return undefined;
  }

  return { id: contactOption.value, displayName: contactOption.label };
};

const getContactTypeaheadDefaultOptions = ({ contact, fallbackContact }) => {
  const options = [];

  if (contact) {
    options.push({ data: contact, label: contact.displayName, value: contact.id });
  }
  if (fallbackContact && contact?.id !== fallbackContact?.id) {
    options.push({ data: fallbackContact, label: fallbackContact.displayName, value: fallbackContact.id });
  }

  return options;
};

const deriveDefaultAmount = ({ paymentSource, invoice }) => {
  const isTrustOrOperatingAccount = [PAYMENT_TYPE.trust, PAYMENT_TYPE.operating].includes(paymentSource.paymentType);
  const invoiceDueAmount = invoice.totals.unpaid;

  if (!isTrustOrOperatingAccount) {
    // E.g. for direct payments
    return invoiceDueAmount;
  }

  if (paymentSource.isCombinedBalance) {
    // The user will explicitly allocate the amounts via split payments
    return 0;
  }

  // The lower of the two is set as the amount
  return Math.min(invoiceDueAmount, paymentSource.balance);
};

const deriveDefaultPayor = ({ paymentSource, invoice }) => {
  // For payment sources connected to a contact, assign them
  //  * E.g. for matter-contact type accounts
  if (paymentSource.contactId && !paymentSource.isCombinedBalance) {
    return {
      id: paymentSource.contactId,
      displayName: paymentSource.contactDisplayName,
    };
  }

  // For direct payment sources:
  //  * If single debtor invoice - assign the debtor
  //  * If multi debtor invoice - leave blank for the user to assign
  if (paymentSource.paymentType === PAYMENT_TYPE.direct) {
    const invoiceDefaultDebtor = invoice.debtors.length === 1 ? invoice.debtors[0].contact : undefined;
    return invoiceDefaultDebtor;
  }

  // In the remaining cases, they will either be a:
  //  1. Combined source (matter-contact type account) or
  //  2. Matter balance source
  // For such payment source types, the user cannot assign a payor
  return undefined;
};

export const AddInvoicePaymentModalFormsContainer = composeHooks(hooks)(AddInvoicePaymentModal);

AddInvoicePaymentModalFormsContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  printCheques: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,

  createPDFReceiptOnTrustPayment: PropTypes.bool.isRequired,
  isTrustChequePrintingActiveForBankAccountId: PropTypes.func.isRequired,
  isMatterContactBalanceFirm: PropTypes.bool.isRequired,
  operatingAccount: PropTypes.object.isRequired,
  invoice: PropTypes.object, // Invoice as per AddInvoicePaymentModalData query
  isInvoiceLoading: PropTypes.bool.isRequired,
  isMatterClientRequiredForTrustDeposit: PropTypes.bool.isRequired,
  isMatterDescriptionRequiredForTrustDeposit: PropTypes.bool.isRequired,

  // payment source
  paymentSourceOptions: PropTypes.arrayOf(PropTypes.object),
  onGetPaymentSourceOptions: PropTypes.func.isRequired,

  // trust cheques
  lastTrustChequeNumber: PropTypes.string,
  nextTrustChequeNumber: PropTypes.string,
  trustChequeNumberBankAccountId: PropTypes.string,
  trustChequeNumberLoading: PropTypes.bool.isRequired,
  onFetchAvailableTrustChequeNumbers: PropTypes.func.isRequired,

  // bank recs
  bankReconciliationLatestCompletedByBankAccount: PropTypes.object,
  bankReconciliationSetup: PropTypes.object,
  bankReconciliationLoading: PropTypes.bool.isRequired,
  onFetchBankRecLatestCompleted: PropTypes.func.isRequired,
};

AddInvoicePaymentModalFormsContainer.defaultProps = {
  bankReconciliationLatestCompletedByBankAccount: undefined,
  bankReconciliationSetup: undefined,
  lastTrustChequeNumber: undefined,
  nextTrustChequeNumber: undefined,
};

AddInvoicePaymentModalFormsContainer.displayName = 'AddInvoicePaymentModalFormsContainer';
