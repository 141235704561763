import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, Spinner, TemplateMacroSelector, TextEditor, SlidingToggle, useTranslation } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { getRetainerDefaults } from '@sb-billing/business-logic/evergreen-retainer';
import { getLogger } from '@sb-itops/fe-logger';

import Styles from './EvergreenEmailSettings.module.scss';

const log = getLogger('EvergreenEmailSettings');

const EvergreenEmailSettings = React.memo(({ settings, onSaveSettings, evergreenRetainerEmailMacros }) => {
  const { t } = useTranslation();
  const retainerDefaults = getRetainerDefaults(t);
  const [subject, setSubject] = useState(() =>
    settings ? settings.trustRetainerReplenishEmailSubject : retainerDefaults.TRUST_RETAINER_EMAIL_SUBJECT,
  );
  const [message, setMessage] = useState(() =>
    settings
      ? getEmailMessage(settings.trustRetainerReplenishEmailBody)
      : retainerDefaults.TRUST_RETAINER_EMAIL_MESSAGE,
  );

  const [sendCopy, setSendCopy] = useState(() => (settings ? !settings.doNotSendReplenishEmailToUser : true));
  const [saving, setSaving] = useState(false);
  // if the user hasn't save the settings before, default the dirty to false
  // this is so the user can save the default settings straight away
  const [dirty, setDirty] = useState(() => !settings);

  const emailSubjectRef = useRef();
  let emailBodyReactQuillRef;
  const [subjectSelectedMacro, setSubjectSelectedMacro] = useState('');
  const [bodySelectedMacro, setBodySelectedMacro] = useState('');
  const [bodyEditorCursorPosition, setBodyEditorCursorPosition] = useState(0);

  const onChangeSubject = (value) => {
    setSubject(value);
    setDirty(true);
  };
  const onChangeMessage = (value) => {
    setMessage(value);
    setDirty(true);
  };
  const onChangeSendCopy = (field, value) => {
    setSendCopy(value);
    setDirty(true);
  };
  const onClickSave = async () => {
    try {
      setSaving(true);
      await onSaveSettings({
        trustRetainerReplenishEmailSubject: subject,
        trustRetainerReplenishEmailBody: message,
        minimumTrustRetainerActive: settings
          ? settings.minimumTrustRetainerActive
          : retainerDefaults.MINIMUM_TRUST_RETAINER_ACTIVE,
        minimumTrustRetainerAmount: settings
          ? settings.minimumTrustRetainerAmount
          : retainerDefaults.MINIMUM_TRUST_RETAINER_AMOUNT,
        trustRetainerReplenishAmount: settings
          ? settings.trustRetainerReplenishAmount
          : retainerDefaults.TRUST_RETAINER_REPLENISH_AMOUNT,
        doNotSendReplenishEmailToUser: !sendCopy,
      });
      setDirty(false);
      messageDisplay.success('Evergreen Retainer settings saved successfully');
    } catch (err) {
      log.error(err);
      messageDisplay.error('Evergreen Retainer settings failed');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className={Styles.evergreenEmailSettings}>
      <div className="form-group">
        <label>Email Subject</label>
        <input
          className="form-control"
          onChange={(e) => onChangeSubject(e.target.value)}
          value={subject}
          ref={emailSubjectRef}
        />
        <TemplateMacroSelector
          selectedMacroName={subjectSelectedMacro}
          macros={evergreenRetainerEmailMacros.subjectMacros}
          onMacroChanged={setSubjectSelectedMacro}
          onMacroInserted={(macroName) => {
            if (!emailSubjectRef) return;
            const selectionStart = emailSubjectRef.current.selectionStart;
            const selectionEnd = emailSubjectRef.current.selectionEnd;
            // insert the macroName
            const value = subject;
            const newValue =
              value.substring(0, selectionStart) + macroName + value.substring(selectionEnd, value.length);
            onChangeSubject(newValue);
          }}
        />
      </div>
      <div className="form-group">
        <label>Email Body</label>
        <TextEditor
          className="evergreen-email-template-body-editor"
          onChange={onChangeMessage}
          value={message}
          onBlur={(range) => {
            setBodyEditorCursorPosition(range.index);
          }}
          // eslint-disable-next-line no-return-assign
          reactQuillRef={(rqRef) => (emailBodyReactQuillRef = rqRef)}
        />
        <TemplateMacroSelector
          selectedMacroName={bodySelectedMacro}
          macros={evergreenRetainerEmailMacros.bodyMacros}
          onMacroChanged={setBodySelectedMacro}
          onMacroInserted={(macroName) => {
            if (!emailBodyReactQuillRef || typeof emailBodyReactQuillRef.getEditor !== 'function') return;

            const quill = emailBodyReactQuillRef.getEditor();
            // If quill didn't have focus/selection immediately before this function is triggered, quill.getSelection() returns null
            const cursorPosition = quill.getSelection() ? quill.getSelection().index : bodyEditorCursorPosition || 0;
            quill.insertText(cursorPosition, macroName);
            setBodyEditorCursorPosition(cursorPosition + macroName.length);
          }}
        />
      </div>
      <div className={classnames('form-group', Styles.bccField)}>
        <label className={Styles.sendCopyLabel} htmlFor="sendCopySlider">
          <SlidingToggle
            name="sendCopySlider"
            onChange={onChangeSendCopy}
            scope="evergreen-retainer-email"
            selected={sendCopy}
          />
          <div className={Styles.labelText}>Send a copy of the email to the user</div>
        </label>
      </div>
      <div className="form-group">
        <Button onClick={onClickSave} disabled={saving || !dirty}>
          {saving && <Spinner small />} Save
        </Button>
      </div>
    </div>
  );
});

function getEmailMessage(message) {
  // [REPLENISH_AMOUNT] is an old placeholder that has been deprecated.
  // we aren't willing to change the exisiting users data, so we have to
  // handle it here - making it look as if the user never used REPLENISH_AMOUNT at all.
  // Same goes for CLIENT_NAME.
  // Same goes for LAWPAY_PAYMENT_BUTTON
  return (message || '')
    .replace(/\[REPLENISH_AMOUNT\]/g, '[REPLENISH_UP_TO]')
    .replace(/\[CLIENT_NAME\]/g, '[DEBTOR_NAME]')
    .replace(/\[LAWPAY_PAYMENT_BUTTON\]/g, '[PAYMENT_BUTTON]');
}

EvergreenEmailSettings.displayName = 'EvergreenEmailSettings';

EvergreenEmailSettings.propTypes = {
  settings: PropTypes.any,
  onSaveSettings: PropTypes.func.isRequired,
  evergreenRetainerEmailMacros: PropTypes.shape({
    subjectMacros: PropTypes.array.isRequired,
    bodyMacros: PropTypes.array.isRequired,
  }).isRequired,
};

EvergreenEmailSettings.defaultProps = {
  settings: undefined,
};

export default EvergreenEmailSettings;
