import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useReminderCorrespondenceHistory } from 'web/hooks/useReminderCorrespondenceHistory';
import { ReminderCorrespondenceDetails } from './ReminderCorrespondenceDetails';

const hooks = ({ lastCorrespondenceHistory }) => ({
  useCorrespondenceHistory: () => {
    const { loading, latestFullCorrespondenceHistory } = useReminderCorrespondenceHistory({
      lastCorrespondenceHistory,
    });

    return {
      loading,
      latestFullCorrespondenceHistory,
    };
  },
});

export const ReminderCorrespondenceDetailsContainer = withReduxProvider(
  composeHooks(hooks)(ReminderCorrespondenceDetails),
);

ReminderCorrespondenceDetailsContainer.displayName = 'ReminderCorrespondenceDetailsContainer';

ReminderCorrespondenceDetailsContainer.propTypes = {
  lastCorrespondenceHistory: PropTypes.object,
  onResendClicked: PropTypes.func,
  onSendViaMenuClicked: PropTypes.func,
};

ReminderCorrespondenceDetailsContainer.defaultProps = {
  lastCorrespondenceHistory: undefined,
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};
