import { getRegion } from '@sb-itops/environment-config';
const { depositSlipPdf } = require('@sb-billing/business-logic/deposit-slips/services');

const region = getRegion();

// this will be removed when we migrate US and enable this feature globally.
const depositSlipPdfEndpointLatestVersion = '2'

angular.module('sb.billing.webapp').component('sbDepositSlipPdf', {
  templateUrl: 'ng-components/deposit-slip-pdf/deposit-slip-pdf.html',
  bindings: { depositSlipId: '<', onSlipLoaded: '&?' },
  controller: function($state, sbLoggerService, sbGenericEndpointService, sbMessageDisplayService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDepositSlipPdf');

    that.isReloading = false;

    //log.setLogLevel('info');

    that.$onInit = () => {
      that.isReloading = true;
      refreshPdf();
    };

    function refreshPdf() {
      log.info('refreshing deposit slip PDF');

      downloadPdfP()
        .then(renderPdf)
        .catch(handleError);
    }

    function downloadPdfP() {
      const docStreamEndpoint = 'billing/deposit-slips-pdf';
      const docStreamConfig = {
        skipCamelCase: true,
      };

      const depositSlipRoute = depositSlipPdf[region].getRoute({ depositSlipId: that.depositSlipId, version: depositSlipPdfEndpointLatestVersion });

      return sbGenericEndpointService
        .getPayloadP(
          docStreamEndpoint,
          depositSlipRoute,
          docStreamConfig,
        )
        .then((resp) => {
          if (_.isFunction(that.onSlipLoaded)) {
            that.onSlipLoaded();
          }

          return resp;
        });
    }

    function handleError(e) {
      that.isReloading = false;
      log.error(`Failed to download deposit slip pdf for id: '${that.depositSlipId}'`, e);
      sbMessageDisplayService.error(
        sbMessageDisplayService.builder().text(`Failed to download deposit slip pdf for id: '${that.depositSlipId}'`),
      );
    }

    function renderPdf(pdfB64) {
      log.info(`Loaded PDF data for deposit slip ID '${that.depositSlipId}'`);
      that.pdfDataUrl = `data:application/pdf;base64,${pdfB64}`;
      that.isReloading = false;
      that.retryPdfPromise = null;
      that.isReloading = false;
    }
  },
});
