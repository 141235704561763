import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import {
  categoryOptionMap,
  toggleShowFilters,
  getShowFilters,
  selectCategory,
  getSelectedCategory,
} from 'web/redux/route/home-billing-accounts-payment-provider-payments-fee-wise';
import FeeWisePaymentsListFilterPanel from './FeeWisePaymentsListFilterPanel';

const mapStateToProps = (state) => {
  const expanded = getShowFilters(state);

  return {
    expanded,
    toggleExpanded: toggleShowFilters,

    // Categories
    categoryOptions: Object.values(categoryOptionMap),
    selectedCategory: getSelectedCategory(),
    selectCategory,
  };
};

export const FeeWisePaymentsListFilterPanelContainer = withReduxStore(
  connect(mapStateToProps, {})(FeeWisePaymentsListFilterPanel),
);

FeeWisePaymentsListFilterPanelContainer.displayName = 'FeeWisePaymentsListFilterPanelContainer';

FeeWisePaymentsListFilterPanelContainer.propTypes = {
  panelFilterScope: PropTypes.string.isRequired,

  dateFilterLabel: PropTypes.string.isRequired,
  dateRangeType: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  onDateListChange: PropTypes.func.isRequired,

  selectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  hideStatusAllOption: PropTypes.bool.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  linkedAccountSelected: PropTypes.string.isRequired,
  linkedAccountOnSelect: PropTypes.func.isRequired,
  linkedAccountLoading: PropTypes.bool.isRequired,
  linkedAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};
