const { getRegion, regionType } = require('@sb-itops/region');
const { getAppEnv } = require('@sb-itops/app-env');
const { simpleFetch } = require('@sb-itops/fetch-wrapper-node');

const commHosts = {
  [regionType.AU]: {
    local: 'https://embedded.staging.communicate.smokeball.com.au',
    dev: 'https://embedded.dev.communicate.smokeball.com.au',
    rc: 'https://embedded.staging.communicate.smokeball.com.au',
    qa: 'https://embedded.qa.communicate.smokeball.com.au',
    live: 'https://embedded.live.communicate.smokeball.com.au', // lambdas?
    production: 'https://embedded.live.communicate.smokeball.com.au',
  },
  [regionType.US]: {
    local: 'https://embedded.staging.communicate.smokeball.com',
    dev: 'https://embedded.dev.communicate.smokeball.com',
    rc: 'https://embedded.staging.communicate.smokeball.com',
    qa: 'https://embedded.qa.communicate.smokeball.com',
    live: 'https://embedded.live.communicate.smokeball.com', // lambdas?
    production: 'https://embedded.live.communicate.smokeball.com',
  },
  [regionType.GB]: {
    local: 'https://embedded.staging.communicate.smokeball.co.uk',
    dev: 'https://embedded.dev.communicate.smokeball.co.uk',
    rc: 'https://embedded.staging.communicate.smokeball.co.uk',
    qa: 'https://embedded.qa.communicate.smokeball.co.uk',
    live: 'https://embedded.live.communicate.smokeball.co.uk', // lambdas?
    production: 'https://embedded.live.communicate.smokeball.co.uk',
  },
};

const getCommunicateHost = () => {
  const region = getRegion(); // AU, US, GB
  let appEnv = getAppEnv().toLowerCase();

  const host = process.env.COMMUNICATE_HOST || commHosts[region] && commHosts[region][appEnv];

  if (!host) {
    throw new Error(`cannot determine communicate host for ${region}/${appEnv}`)
  }

  return host;
}

const getCommunicateEndpoint = (endpointName) => {
  const host = getCommunicateHost();

  return `${host}/v1/public/${endpointName}`
}

const fetchFromCommunicateEndpointP = async (endpointName, tokenIfNeeded, optionalBodyObject) => {
  const endpoint = getCommunicateEndpoint(endpointName);

  const token = tokenIfNeeded ? tokenIfNeeded.replace(/^Bearer\s*/, '') : tokenIfNeeded;

  const headers = { authorization: token };
  const response = await simpleFetch({ url: endpoint, method: 'POST', headers, body: optionalBodyObject, throwOnFailure: true });

  return response.body;
}

const getCommunicateUnreadCount = async (bearerToken) => {
  if (!bearerToken) {
    throw new Error("missing token", bearerToken);
  }

  return await fetchFromCommunicateEndpointP("unread", bearerToken, '{}');
}

module.exports = {
  getCommunicateUnreadCount,
  getCommunicateHost,
};