/* eslint-disable promise/always-return */
/**
 * @module @sb-itops/redux/fetch
 */
import { toCamelCase } from '@sb-itops/camel-case';
import { getRestApiUrl } from '@sb-itops/environment-config';
import { getEnabledFeatures } from '@sb-itops/feature';
import { fetch, HTTP_METHOD, RESPONSE_TYPE, responseHandler } from '@sb-itops/fetch-wrapper-browser';
import uuid from '@sb-itops/uuid';

import {
  getIsAuthenticated,
  getIsAuthRefreshInProgress,
  getAuthToken,
  getAccountId,
  refreshTokenP,
} from 'web/services/user-session-management';

import store from '../store';

const requestBuffer = [];

// cant do this in a reducer as we will be dispatching actions
store.subscribe(() => {
  // If no requests are queued, we can ignore any state updates as it means no requests have yet failed
  // due to bad auth.
  if (requestBuffer.length === 0) {
    return;
  }

  // If the user is currently in the process of re-authenticating via refresh token, we are only interested
  // in what eventually happens after refresh, so we can skip this.
  if (getIsAuthRefreshInProgress()) {
    return;
  }

  // We aren't refreshing and we aren't authenticated, reject anything in the queue and return.
  if (!getIsAuthenticated()) {
    rejectQueue();
    return;
  }

  // We are authenticated again, resubmit all the queued requests.
  submitQueue();
});

export { HTTP_METHOD, RESPONSE_TYPE };

/**
 * rejectQueue
 * If an attempt to refresh the auth token fails, any queued requests are removed from the buffer,
 * using their original failed response details as the final response to the request.
 */
const rejectQueue = () => {
  // eslint-disable-next-line no-console
  console.warn(`rejecting ${requestBuffer.length} queued requests`);

  while (requestBuffer.length > 0) {
    const { onFail, originalResponse: payload } = requestBuffer.shift();
    onFail({ payload });
  }
};

/**
 * submitQueue
 * If an attempt to refresh the auth token succeeds, any queued requests are removed from the buffer,
 * with the original request details re-sent with the updated token. The response of the retried request
 * is used as the final response to the queued request.
 */
const submitQueue = () => {
  // eslint-disable-next-line no-console
  console.warn(`submitting ${requestBuffer.length} queued requests`);

  while (requestBuffer.length > 0) {
    const request = requestBuffer.shift();
    doFetch({ ...request });
  }
};

/**
 * @typedef {object} Fetch_Config
 * @property {string} path the path to the resoure. The token :accountId wil be replaced with the firm account id.
 * @property {object} fetchOptions proxy for init https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters
 * @property {RESPONSE_TYPE} [responseType]
 * @property {boolean} [skipCamelCase]
 * @property {function} [onStart]
 * @property {function} [onSuccess]
 * @property {function} [onFail]
 */

/**
 * The generic pattern for calling fetch is the same. Adds default headers to the request and uses callbacks to notify progress. Queues requests while the auth token is refreshing.
 * @param {Fetch_Config} config
 * @async
 */
const doFetch = async ({
  path,
  fetchOptions,
  onStart = () => {},
  onSuccess = () => {},
  onFail = () => {},
  responseType = RESPONSE_TYPE.json,
  skipCamelCase = false,
}) => {
  onStart();

  if (!getIsAuthenticated() && !getIsAuthRefreshInProgress()) {
    onFail({ error: new Error('User is not authenticated') });
    return;
  }

  const url = `${getRestApiUrl()}/v2${path.replace(':accountId', getAccountId())}`;
  const init = {
    ...fetchOptions,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store', // Prevents weird chrome issue where in network failure scenarios, caching of corrupt responses may occur.
      ...fetchOptions.headers,
      Authorization: `Bearer ${getAuthToken()}`,
      'x-correlation-id': uuid(),
      'x-features': getEnabledFeatures().join(','),
    },
  };

  if (getIsAuthRefreshInProgress()) {
    requestBuffer.push({
      path,
      fetchOptions,
      onStart: () => {}, // noop as buffered requests are always started
      onSuccess,
      onFail,
      responseType,
      skipCamelCase,
      originalResponse: {
        status: 401,
        statusText: 'Queued pending token refresh',
        headers: fetchOptions.headers || {},
      },
    });

    return;
  }

  fetch(url, init)
    .then((response) => {
      const headers = [...response.headers.entries()].reduce((acc, curr) => {
        acc[curr[0]] = curr[1];
        return acc;
      }, {});

      // If we get back an unauthorised response, we assume that it's due to auth token requiring a refresh.
      // In that case we just queue the request and wait for our auth status to change before proceeding.
      if (response.status === 401) {
        requestBuffer.push({
          path,
          fetchOptions,
          onStart: () => {}, // noop as buffered requests are always started
          onSuccess,
          onFail,
          responseType,
          skipCamelCase,
          originalResponse: {
            status: response.status,
            statusText: response.statusText,
            headers,
          },
        });
        refreshTokenP();

        return;
      }

      if (!response.ok) {
        // eslint-disable-next-line promise/catch-or-return
        responseHandler[responseType](response)
          // eslint-disable-next-line promise/no-nesting
          .catch(() => {})
          .then((body) => {
            onFail({
              payload: {
                status: response.status,
                statusText: response.statusText,
                headers,
                body,
              },
            });
          });
        return;
      }

      switch (response.status) {
        // 204 means that the response has `No Content` so we do not need to parse the body in this case.
        case 204: {
          onSuccess({
            payload: {
              body: undefined,
              status: response.status,
              headers,
            },
          });
          break;
        }
        default: {
          // eslint-disable-next-line promise/catch-or-return
          responseHandler[responseType](response).then((body) => {
            onSuccess({
              payload: {
                body: skipCamelCase ? body : toCamelCase(body),
                status: response.status,
                statusText: response.statusText,
                headers,
              },
            });
          });
        }
      }
    })
    .catch((error) => {
      onFail({
        error: error.message,
      });
    });
};

/**
 * @param {Fetch_Config} config
 * @async
 */
export const fetchPostP = ({ path, fetchOptions, responseType, skipCamelCase }) =>
  new Promise((resolve, reject) => {
    const options = { ...fetchOptions, method: HTTP_METHOD.post };

    doFetch({
      path,
      fetchOptions: options,
      responseType,
      skipCamelCase,
      onSuccess: (result) => resolve(result && result.payload),
      onFail: (ex) => reject(ex),
    });
  });

/**
 * @param {Fetch_Config} config
 * @async
 */
export const fetchPutP = ({ path, fetchOptions, responseType, skipCamelCase }) =>
  new Promise((resolve, reject) => {
    const options = { ...fetchOptions, method: HTTP_METHOD.put };

    doFetch({
      path,
      fetchOptions: options,
      responseType,
      skipCamelCase,
      onSuccess: (result) => resolve(result && result.payload),
      onFail: (ex) => reject(ex),
    });
  });

/**
 * @param {Fetch_Config} config
 * @async
 */
export const fetchPatchP = ({ path, fetchOptions, responseType, skipCamelCase }) =>
  new Promise((resolve, reject) => {
    const options = { ...fetchOptions, method: HTTP_METHOD.patch };

    doFetch({
      path,
      fetchOptions: options,
      responseType,
      skipCamelCase,
      onSuccess: (result) => resolve(result && result.payload),
      onFail: (ex) => reject(ex),
    });
  });

/**
 * @param {Fetch_Config} config
 * @async
 */
export const fetchDeleteP = ({ path, fetchOptions, responseType, skipCamelCase }) =>
  new Promise((resolve, reject) => {
    const options = { ...fetchOptions, method: HTTP_METHOD.delete };

    doFetch({
      path,
      fetchOptions: options,
      responseType,
      skipCamelCase,
      onSuccess: (result) => resolve(result && result.payload),
      onFail: (ex) => reject(ex),
    });
  });

/**
 * @param {Fetch_Config} config
 * @async
 */
export const fetchGetP = ({ path, fetchOptions, responseType, skipCamelCase }) =>
  new Promise((resolve, reject) => {
    const options = { ...fetchOptions, method: HTTP_METHOD.get };

    doFetch({
      path,
      fetchOptions: options,
      responseType,
      skipCamelCase,
      onSuccess: (result) => resolve(result && result.payload),
      onFail: (ex) => reject(ex),
    });
  });
