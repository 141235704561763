'use strict';

const { mapItemToOption, buildGroupedDropDownOptions } = require('./build-grouped-drop-down-options');
const { convertToActivityGroupArray } = require('./convert-to-activity-group-array');
const { convertToTaskGroupArray } = require('./convert-to-task-group-array');
const { deriveActivityRate } = require('./derive-activity-rate');
const { filterUtbmsTaskCodes } = require('./filter-utbms-task-codes');
const { filterUtbmsTaskCodesExpenses } = require('./filter-utbms-task-codes-expenses');
const { getActivityDurationType } = require('./get-activity-duration-type');
const { getApplicableActivityCategories } = require('./get-applicable-activity-categories');
const {
  getApplicableActivityCodes,
  getApplicableActivityCodesForRHSFeeModal,
} = require('./get-applicable-activity-codes');
const { getPreferredActivityDurationType } = require('./get-preferred-activity-duration-type');
const { getPreferredTaskDurationType } = require('./get-preferred-task-duration-type');
const { groupActivitiesByCategoryLabel } = require('./group-activities-by-category-label');
const { mapActivitiesByCategory } = require('./map-activities-by-category');
const { mapTasksByCategory } = require('./map-tasks-by-category');

module.exports = {
  buildGroupedDropDownOptions,
  convertToActivityGroupArray,
  convertToTaskGroupArray,
  deriveActivityRate,
  filterUtbmsTaskCodes,
  filterUtbmsTaskCodesExpenses,
  getActivityDurationType,
  getApplicableActivityCategories,
  getApplicableActivityCodes,
  getApplicableActivityCodesForRHSFeeModal,
  getPreferredActivityDurationType,
  getPreferredTaskDurationType,
  groupActivitiesByCategoryLabel,
  mapActivitiesByCategory,
  mapItemToOption,
  mapTasksByCategory,
};
