import * as React from 'react';
import PropTypes from 'prop-types';

import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { todayAsInteger } from '@sb-itops/date';
import { getLogger } from '@sb-itops/fe-logger';
import { featureActive } from '@sb-itops/feature';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { hasFacet, facets } from '@sb-itops/region-facets';
import uuid from '@sb-itops/uuid';
import { getList as getExpenseList } from '@sb-billing/redux/expenses';

import {
  DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID,
  FINALISE_INVOICE_CONFIRMATION_MODAL_ID,
  ADD_PAYMENT_MODAL_ID as ADD_PAYMENT_NEW_MODAL_ID,
} from 'web/components';
import { ADD_PAYMENT_MODAL_ID, CREDIT_NOTE_MODAL_ID } from 'web/react-redux';

import { InvoiceListActionBar } from './InvoiceListActionBar';

const log = getLogger('InvoiceListActionBar.container');

const hooks = () => ({
  useFacets: () => ({
    isUtbmsFacetEnabled: hasFacet(facets.utbms),
  }),
  useAddCreditModal: ({ assignedOrDefaultMatterDebtorId, contactId, matterId, scope, showAddCredit }) => {
    if (!showAddCredit) {
      return {};
    }

    // This modal is not LOD yet (conversion will occur in the near future)
    function onOpenAddCreditModal() {
      setModalDialogVisible({
        modalId: CREDIT_NOTE_MODAL_ID,
        props: {
          scope: `${scope}/credit-note-modal`,
          // The Matter invoices page will pass both the matter's id and assigned/default debtor
          contactId: matterId ? assignedOrDefaultMatterDebtorId : contactId,
          matterId,
        },
      });
    }

    return {
      onOpenAddCreditModal,
    };
  },
  useAddPaymentModal: ({ contactId, matterId, scope, showAddPayment }) => {
    if (!showAddPayment) {
      return {};
    }

    function onOpenAddPaymentModal() {
      // If on the:
      //  1. Contact invoices page -> pass in contactId
      //  2. Matter invoices page -> pass in matterId (only)
      //    * We don't pass in assignedOrDefaultMatterDebtorId because the modal has its own logic for assigning the "Paid By"

      const disableLODPaymentModal =
        getExpenseList().length > 0 && !featureActive('BB-14929') && !featureActive('BB-13936');

      if (!disableLODPaymentModal) {
        setModalDialogVisible({
          modalId: ADD_PAYMENT_NEW_MODAL_ID,
          props: {
            scope: `${scope}/add-payment-modal`,
            contactId,
            matterId,
          },
        });
      } else {
        // Non-LOD version - can de deleted once BB-13936 is tested and released
        setModalDialogVisible({
          modalId: ADD_PAYMENT_MODAL_ID,
          props: {
            scope: `${scope}/add-payment-modal`,
            contactId,
            matterId,
          },
        });
      }
    }

    return {
      onOpenAddPaymentModal,
    };
  },
  useSendViaEmailAction: ({ onOpenInvoiceEmailModal, selectedInvoicesByAction }) => {
    function onSendViaEmail() {
      const invoiceIdsForSendViaEmailAction = selectedInvoicesByAction.email;
      const cannotPerformOperation = !invoiceIdsForSendViaEmailAction.length;

      if (cannotPerformOperation) {
        return;
      }

      onOpenInvoiceEmailModal({ invoiceIds: invoiceIdsForSendViaEmailAction });
    }

    return {
      onSendViaEmail,
    };
  },
  useMarkAsSentAction: ({ onOpenMarkInvoiceAsSentModal, selectedInvoicesByAction }) => {
    function onMarkAsSent() {
      const invoiceIdsForMarkAsSentAction = selectedInvoicesByAction.markAsSent;
      const cannotPerformOperation = !invoiceIdsForMarkAsSentAction.length;

      if (cannotPerformOperation) {
        return;
      }

      onOpenMarkInvoiceAsSentModal({ invoiceIds: invoiceIdsForMarkAsSentAction });
    }

    return {
      onMarkAsSent,
    };
  },
  useCreateStatementAction: ({
    selectedInvoicesByAction,
    toggleInvoicesToCreateStatement,
    showCreateStatement,
    contactId,
    onClickLink,
  }) => {
    async function onCreateStatement() {
      if (!showCreateStatement) {
        return;
      }
      const invoiceIdsForCreateStatementAction = selectedInvoicesByAction.createStatement;
      const cannotPerformOperation = !invoiceIdsForCreateStatementAction.length;

      if (cannotPerformOperation) {
        return;
      }

      const newInvoiceStatementId = uuid();

      try {
        const CreateInvoiceStatementMessage = {
          id: newInvoiceStatementId,
          debtorId: contactId,
          invoiceIds: invoiceIdsForCreateStatementAction,
          issuedDate: todayAsInteger(),
        };

        await dispatchCommand({
          type: 'Integration.CreateInvoiceStatement',
          message: CreateInvoiceStatementMessage,
        });
        // Update available "actions"
        //  * Invoices are no longer available for "create statement"
        toggleInvoicesToCreateStatement(invoiceIdsForCreateStatementAction);
      } catch (error) {
        displayErrorToUser('Failed to save the statement');
      }

      onClickLink({ type: 'invoiceStatementView', id: newInvoiceStatementId });
    }

    return {
      onCreateStatement,
    };
  },
  useFinaliseAction: ({ selectedInvoicesByAction, sbSaveInvoiceCommand, onFinaliseDraftInvoicesSuccess }) => {
    const [isLoadingFinaliseInvoiceConfirmationModalData, setIsLoadingFinaliseInvoiceConfirmationModalData] =
      React.useState(false);
    const { t } = useTranslation();
    const [isFinalisingDraftInvoices, setIsFinalisingDraftInvoices] = React.useState(false);

    async function onFinaliseDraftInvoices({ invoiceIds }) {
      try {
        setIsFinalisingDraftInvoices(true);
        const results = await sbSaveInvoiceCommand.executeBulkP(invoiceIds);

        const { successCount, errorCount, finalisedInvoiceIds } = results.reduce(
          (acc, result) => {
            if (result.success) {
              acc.successCount += 1;
              acc.finalisedInvoiceIds.push(result.invoiceId);
            } else {
              acc.errorCount += 1;
            }

            return acc;
          },
          {
            successCount: 0,
            errorCount: 0,
            finalisedInvoiceIds: [],
          },
        );

        if (successCount) {
          displaySuccessToUser(`${successCount} Draft invoice(s) ${t('finalise')}d`);
        }

        if (errorCount) {
          displayErrorToUser(`${errorCount} Draft invoice(s) could not be ${t('finalise')}d`);
          log.error(`${errorCount} draft invoices could not be finalised`, results);
        }

        // Update available "actions"
        //  * Invoices are changing from "draft" to "finalised"
        onFinaliseDraftInvoicesSuccess({ invoiceIds: finalisedInvoiceIds });
      } catch (error) {
        displayErrorToUser(`Draft invoices could not be ${t('finalise')}d`);
        log.error(`Draft invoices could not be ${t('finalise')}d`, error);
      } finally {
        setIsFinalisingDraftInvoices(false);
      }
    }

    // Checks if any of the selected draft invoices require a confirmation before finalising
    //  * If so, the FinaliseInvoiceConfirmationModal pops up
    //  * If not, the finalisation process continues
    function onCheckForFinaliseInvoiceConfirmation() {
      const invoiceIdsForFinalising = selectedInvoicesByAction.finalise;
      const cannotPerformOperation = !invoiceIdsForFinalising.length;

      if (cannotPerformOperation) {
        return;
      }

      setIsLoadingFinaliseInvoiceConfirmationModalData(true);

      setModalDialogVisible({
        modalId: FINALISE_INVOICE_CONFIRMATION_MODAL_ID,
        props: {
          invoiceIds: invoiceIdsForFinalising,
          onFinaliseDraftInvoices,
          onHasFetchedData: () => setIsLoadingFinaliseInvoiceConfirmationModalData(false),
          t,
        },
      });
    }

    return {
      isFinalisingDraftInvoices,
      isLoadingFinaliseInvoiceConfirmationModalData,
      onFinalise: onCheckForFinaliseInvoiceConfirmation,
    };
  },
  useDeleteDraftInvoicesAction: ({ selectedInvoicesByAction, onDeselectDeletedDraftInvoices }) => {
    const [isDeletingDraftInvoices, setIsDeletingDraftInvoices] = React.useState(false);

    function onDeleteDraftInvoices() {
      const invoiceIdsForDeletingDrafts = selectedInvoicesByAction.deleteDraft;

      setModalDialogVisible({
        modalId: DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID,
        props: {
          invoiceIds: invoiceIdsForDeletingDrafts,
          isBulkDeletion: true,
          onProceedClick: () => {
            onDeselectDeletedDraftInvoices({ invoiceIds: invoiceIdsForDeletingDrafts });
            setIsDeletingDraftInvoices(true);
          },
          onCompletion: () => setIsDeletingDraftInvoices(false),
        },
      });
    }

    return {
      isDeletingDraftInvoices,
      onDeleteDraftInvoices,
    };
  },
  useCreateInvoiceAction: ({ isBulkCreateInvoicesInProgress, isEditableMatter, matterId, onNavigateTo }) => {
    const isOnMatterInvoicesPage = !!matterId;
    const isCreateInvoiceDisabled = !isEditableMatter || isBulkCreateInvoicesInProgress;

    function onCreateInvoice() {
      if (isCreateInvoiceDisabled || !isOnMatterInvoicesPage) {
        return;
      }

      onNavigateTo('home.billing.create-bill', { matterId });
    }

    return {
      isCreateInvoiceDisabled,
      onCreateInvoice,
    };
  },
});

const dependentHooks = () => ({
  useDownloadActions: ({
    isCombineInPdfInProgress,
    isDownloadLedesInProgress,
    isUtbmsFacetEnabled,
    sbAsyncOperationsService,
    selectedInvoicesByAction,
  }) => {
    function onCombineInPdf() {
      const invoiceIdsForCombineInPdfAction = selectedInvoicesByAction.combineInPdf;

      const cannotPerformOperation = !invoiceIdsForCombineInPdfAction.length || isCombineInPdfInProgress;

      if (cannotPerformOperation) {
        return;
      }

      sbAsyncOperationsService.startCombineInvoices(invoiceIdsForCombineInPdfAction);
    }

    function onCombineInPdfWithCoverLetter() {
      const invoiceIdsForCombineInPdfWithCoverLetterAction = selectedInvoicesByAction.combineInPdfWithCoverLetter;

      const cannotPerformAction =
        !isUtbmsFacetEnabled || !invoiceIdsForCombineInPdfWithCoverLetterAction.length || isCombineInPdfInProgress;

      if (cannotPerformAction) {
        return;
      }

      sbAsyncOperationsService.startCombineInvoices(invoiceIdsForCombineInPdfWithCoverLetterAction, true);
    }

    function onDownloadLedes() {
      const invoicesIdsForDownloadLedes = selectedInvoicesByAction.downloadLedes;

      const cannotPerformAction =
        !isUtbmsFacetEnabled || !invoicesIdsForDownloadLedes.length || isDownloadLedesInProgress;

      if (cannotPerformAction) {
        return;
      }

      sbAsyncOperationsService.startDownloadLedes(invoicesIdsForDownloadLedes);
    }

    return {
      onCombineInPdf,
      onCombineInPdfWithCoverLetter,
      onDownloadLedes,
    };
  },
  useIsBulkActionsLoading: ({
    isLoadingFinaliseInvoiceConfirmationModalData,
    isFinalisingDraftInvoices,
    isDeletingDraftInvoices,
  }) => ({
    isBulkActionsLoading:
      isLoadingFinaliseInvoiceConfirmationModalData || isFinalisingDraftInvoices || isDeletingDraftInvoices,
  }),
});

export const InvoiceListActionBarContainer = composeHooks(hooks)(composeHooks(dependentHooks)(InvoiceListActionBar));

InvoiceListActionBarContainer.propTypes = {
  /** General invoice list action bar props */
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  showAddCredit: PropTypes.bool,
  showAddPayment: PropTypes.bool,
  showBulkActions: PropTypes.bool,
  showDownloadOptions: PropTypes.bool,
  showCreateStatement: PropTypes.bool,
  selectedInvoicesByAction: PropTypes.shape({
    combineInPdf: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    combineInPdfWithCoverLetter: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    deleteDraft: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    downloadLedes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    email: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    finalise: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    markAsSent: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    createStatement: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  // Callbacks
  onDeselectDeletedDraftInvoices: PropTypes.func,
  onFinaliseDraftInvoicesSuccess: PropTypes.func,
  onOpenInvoiceEmailModal: PropTypes.func.isRequired,
  /** Contact invoices page specific props */
  contactId: PropTypes.string,
  /** Matter invoices page specific props */
  assignedOrDefaultMatterDebtorId: PropTypes.string,
  isBulkCreateInvoicesInProgress: PropTypes.bool,
  isEditableMatter: PropTypes.bool,
  isLoading: PropTypes.bool,
  matterId: PropTypes.string,
  showCreateInvoice: PropTypes.bool,
  // Callbacks
  onNavigateTo: PropTypes.func,
  onClickLink: PropTypes.func,
};

InvoiceListActionBarContainer.defaultProps = {
  /** General invoice list action bar props */
  showAddCredit: undefined,
  showAddPayment: undefined,
  showBulkActions: undefined,
  showDownloadOptions: undefined,
  showCreateStatement: false,
  // Callbacks
  onDeselectDeletedDraftInvoices: undefined,
  onFinaliseDraftInvoicesSuccess: undefined,
  /** Contact invoices page specific props */
  contactId: undefined, // scope to contact when required
  /** Matter invoices page specific props */
  assignedOrDefaultMatterDebtorId: undefined, // scope to matter debtor when required
  isBulkCreateInvoicesInProgress: undefined,
  isEditableMatter: undefined,
  isLoading: undefined, // Sometimes, we need to load some data before making certain actions available (e.g. Getting the assigned/default matter debtor for the "Add Credit" modal on the Matter Invoices page)
  matterId: undefined, // scope to matter when required
  showCreateInvoice: undefined,
  // Callbacks
  onNavigateTo: undefined,
  onClickLink: () => {},
};

InvoiceListActionBarContainer.displayName = 'InvoiceListActionBarContainer';
