import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes } from '@sb-itops/react/button';
import { Modal } from '@sb-itops/react/modal';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import * as Styles from './PaymentProviderSwitchConfirmationModal.module.scss';

export const PaymentProviderSwitchConfirmationModal = ({
  isVisible,
  onConfirm,
  onClose,
  activeProviderType,
  desiredProviderType,
}) => {
  const activeName = providerNames[activeProviderType] || '';
  const desiredName = providerNames[desiredProviderType] || '';

  return (
    <Modal
      title="Switch Payment Processors?"
      body={
        <div>
          <p>
            {activeName} is already active. Activating {desiredName} will also deactivate {activeName}.
          </p>
          <p>
            Payment plans that use a credit card linked to a deactivated payment provider will need to have their card
            reauthenticated and updated for installments to continue.
          </p>
          <p>Do you wish to continue?</p>
        </div>
      }
      footer={
        <div className={Styles.footer}>
          <Button className={Styles.footerBtn} onClick={onConfirm}>
            Activate {desiredName}
          </Button>
          <Button className={Styles.footerBtn} type={buttonTypes.secondary} onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
      isVisible={isVisible}
      onModalClose={onClose}
      shouldCloseOnOverlayClick={false}
    />
  );
};

PaymentProviderSwitchConfirmationModal.displayName = 'PaymentProviderSwitchConfirmationModal';

PaymentProviderSwitchConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  activeProviderType: PropTypes.string.isRequired,
  desiredProviderType: PropTypes.string.isRequired,
};

PaymentProviderSwitchConfirmationModal.defaultProps = {};
