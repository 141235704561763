import { TPermission } from './types';

/**
 * Converts matter permissions result into a boolean lookup
 * keyed to the matterIds.
 *
 * note this strips the matters/ in the resourceId
 *
 * @param { { resourceId: string, userId: string, accountId: string, isAuthorized: boolean, error?: string }[] } rawPermissions
 * @return { Record<string, boolean> }
 */
export const makePermissionsLookup = (rawPermissions: TPermission[]): Record<string, boolean> => {
  let userId: undefined | string;
  let accountId: undefined | string;

  return rawPermissions.reduce((acc, i) => {
    // we do not expect multiple users/accounts as the endpoint does not cater for it, it would be an error to make a lookup from them
    if (userId && userId !== i.userId) {
      throw new Error('Found multiple users building permissions lookup. This is invalid a lookup cannot be built.');
    }
    if (accountId && accountId !== i.accountId) {
      throw new Error('Found multiple accounts building permissions lookup. This is invalid a lookup cannot be built.');
    }
    userId = i.userId;
    accountId = i.accountId;
    // safety case. refusing to overwrite false with true although we do not expect multiple identical resourceId
    if (acc[i.resourceId] !== false) {
      acc[i.resourceId.replace(/^matters\//, '')] = i.isAuthorized;
    }

    return acc;
  }, {});
};
