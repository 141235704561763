angular
  .module('sb.billing.webapp')
  .controller('BulkBillController', function (
    $uibModal,
    $state,
    sbLinkService,
  ) {
    const that = this;

    that.onNavigateTo = onNavigateTo;
    that.onClickLink = sbLinkService.onClickLink;

    function onNavigateTo(route, params) {
      $state.go(route, params);
    }
  });
