'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-contact';

  $stateProvider
    .state('home.billing.view-contact', {
      url: '/view-contact/:contactId?selectedInvoiceStatus',
      templateUrl: BASE_DIRECTORY + '/view-contact.html',
      controller: 'SbViewContactController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts', 'view-contact'],
      },
    });
});
