/**
 * globalFeature() accepts a Redux Feature and returns a modified Redux Feature which is "global".
 *
 * @description
 * The main benefit of globalFeature() is that programmers will not need to worry about slicing the root state down to the
 * part of the state tree that is managed by the feature's reducer.
 *
 * globalFeature() assumes that the part of the state tree managed by the feature is under rootState[defaultPath], where default
 * path is the path specified in a compliant feature's default-path file.
 *
 * For example, suppose we are coding a Redux Feature to manage authentication state. In our particular application, we know that
 * the auth Feature will never be scoped (see scope-feature.js). It will get very annoying for users of the global feature to have
 * to remember to call selectors like authFeature.selector(getState()['defaultPathOfAuthFeature']);
 *
 * This annoyance becomes a more serious issue when the code calling the selector does not (or at least should not) have any knowledge
 * of where or how the feature's reducer is registered.
 *
 * @param {{ actions: object, selectors: object, operations: object, types: object, reducer: function, defaultPath: string }} featureToBeGlobal - A conforming Redux Feature
 * @return {{ actions: object, selectors: object, operations: object, types: object, reducer: function, defaultPath: string }} The modified Redux Feature that handles state slicing on the default path.
 * @throws if featureToBeGlobal is not defined
 * @throws if featureToBeGlobal.reducer is not defined
 *
 * @example
 *
 * import { globalFeature } from '@sb-itops/redux/hofs';
 * import * as yourFeature from './some-feature';
 *
 * const { reducer, selectors, actions, types, operations, defaultPath } = globalFeature(yourFeature);
 *
 */
export const globalFeature = (featureToBeGlobal) => {
  if (!featureToBeGlobal) {
    throw new Error('feature object must be provided');
  }

  if (!featureToBeGlobal.reducer) {
    throw new Error('globalFeature() must be passed a valid feature - reducer is missing');
  }

  const feature = {
    selectors: {},
    actions: {},
    operations: {},
    types: {},
    ...featureToBeGlobal,
  };

  // Add root state slicing by default path to selectors.
  const selectors = Object.entries(feature.selectors).reduce((acc, [selectorName, selectorFn]) => {
    acc[selectorName] = (state, args) => {
      const slicedState = state[feature.defaultPath];
      return selectorFn(slicedState, args);
    };
    return acc;
  }, {});

  // Add root state slicing by default path to operations.
  const operations = Object.entries(feature.operations).reduce((acc, [operationName, operationFn]) => {
    acc[operationName] = (args) => (dispatch, getState) => {
      const { scope, ...argsWithoutScope } = args;

      const operationThunk = operationFn(argsWithoutScope);

      // This is the get state wrapper function, is going to be sent to the thunk that will have access to the sliced state.
      const slicedGetStateFunction = () => {
        const slicedState = getState()[feature.defaultPath];
        return slicedState;
      };

      return operationThunk(dispatch, slicedGetStateFunction);
    };

    return acc;
  }, {});

  return {
    ...feature,
    selectors,
    operations,
  };
};
