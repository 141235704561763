import * as React from 'react';

import { search } from '@sb-itops/services/src/generic-search';
import { falsyGuard } from '@sb-itops/type-helpers';
import { MatterDisplay } from '@sb-matter-management/react';
import { firmManagement } from '@sb-firm-management/redux';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { getInvoiceTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { hasBillingAccess } from 'web/services/user-session-management';
import { sendMetric } from 'web/services/metrics';

import { MatterSearchProductivityDocument, ContactSearchProductivityDocument } from 'web/graphql/queries';

import { useApolloClient } from '@apollo/client';
import { StaffFlair } from 'web/components/staff-color-picker/StaffFlair';
import { TopnavSearch, TResolvedOptionElements } from './TopnavSearch';

import { TStyleOverrides } from './TopnavTypeahead';

const StaffFlairById = (props: { staffId?: string | undefined | null }) => {
  if (!props.staffId) {
    return null;
  }

  const member = firmManagement.getCurrentStaff().find((s) => s.id === props.staffId);
  if (!member) {
    return null;
  }

  return (
    <span>
      &nbsp;-&nbsp;
      <StaffFlair {...member} />
      &nbsp;{member.initials}
    </span>
  );
};

type TProps = { onClickLink: (nav: any) => void };

export const TopnavSearchLoadOnDemandContainer = (props: TProps) => {
  const client = useApolloClient();

  type TSearchInput = {
    term: string;
    invoiceSearch?: boolean;
  };

  const preProcessSearchTerm = (rawTerm: string): TSearchInput => {
    const term = (rawTerm && rawTerm.trim()) || '';

    // We make searching sane, by only searching when we have 3 or more characters in our search term, with the exception of invoices.
    // We use the # symbol to indicate that a search should be for an invoice only, in which case there is no length restriction as
    // the number of potential matches is much smaller.
    if (term.length > 1 && term[0] === '#') {
      return { term, invoiceSearch: true };
    }

    if (term.length > 2) {
      return { term };
    }

    return { term: '' };
  };

  const joinWithDash = (parts: (string | number | null | undefined)[]) => {
    const combined = parts
      .map((p) => p && `${p}`.trim())
      .filter(falsyGuard)
      .join(' - ');

    return combined;
  };

  const optionResolver = async (rawTerm: string): Promise<TResolvedOptionElements> => {
    const searchInput = preProcessSearchTerm(rawTerm);

    const outputs: TResolvedOptionElements = {
      sectionOrder: ['Leads', 'Matters', 'Contacts', 'Invoices'],
      matters: [],
      leads: [],
      contacts: [],
      invoices: [],
    };

    if (!searchInput.term) {
      return outputs;
    }

    if (searchInput.invoiceSearch) {
      if (hasBillingAccess()) {
        const invoiceIds = search(getInvoiceTypeaheadSummaries(), 'invoiceId', ['typeahead'], searchInput.term);

        invoiceIds.sort((a, b) => +a - +b);

        outputs.invoicesHeader = `Invoices (${invoiceIds.length})`;

        outputs.invoices = invoiceIds.slice(0, 10).map((value) => {
          const invoice = getInvoiceById(value);

          return {
            value,
            element: (
              <span>
                #{invoice.currentVersion.invoiceNumber} - <MatterDisplay matterId={invoice.currentVersion.matterId} />
              </span>
            ),
          };
        });
      }

      return outputs;
    }

    sendMetric('QuicksearchQuery');

    const matterSearchPromise = client.query({
      query: MatterSearchProductivityDocument,
      variables: { searchText: rawTerm, limit: 5, offset: 0, filter: { isLead: false, matterStatus: ['open'] } },
      fetchPolicy: 'network-only',
    });

    const leadSearchPromise = client.query({
      query: MatterSearchProductivityDocument,
      variables: { searchText: rawTerm, limit: 5, offset: 0, filter: { isLead: true, matterStatus: ['open'] } },
      fetchPolicy: 'network-only',
    });

    const contactSearchPromise = client.query({
      query: ContactSearchProductivityDocument,
      variables: { searchText: rawTerm, limit: 5, offset: 0 },
      fetchPolicy: 'network-only',
    });

    const [
      {
        data: { matterSearchProductivity: matterSearchData },
      },
      {
        data: { matterSearchProductivity: leadSearchData },
      },
      {
        data: { contactSearchProductivity: contactSearchData },
      },
    ] = await Promise.all([matterSearchPromise, leadSearchPromise, contactSearchPromise]);

    if (matterSearchData && matterSearchData.results?.length) {
      const countVal = matterSearchData.results.length === 100 ? '99+' : matterSearchData.results.length;
      outputs.mattersHeader = `Open Matters (${countVal})`;
      const matters = matterSearchData.results.slice(0, 5);
      // eslint-disable-next-line no-restricted-syntax
      for (const matter of matters) {
        if (matter && matter.id) {
          outputs.matters.push({
            value: matter.id,
            element: (
              <>
                <span>
                  {joinWithDash([
                    matter.matterNumber,
                    matter.clientDisplay,
                    matter.matterType?.name,
                    matter.otherSideDisplay,
                    matter.descriptionAutomation,
                  ])}
                </span>
                <StaffFlairById staffId={matter.attorneyResponsible?.id} />
              </>
            ),
          });
        }
      }
    }

    if (leadSearchData && leadSearchData.results?.length) {
      const countVal = leadSearchData.results.length === 100 ? '99+' : leadSearchData.results.length;
      outputs.leadsHeader = `Open Leads (${countVal})`;
      const leads = leadSearchData.results.slice(0, 5);
      // eslint-disable-next-line no-restricted-syntax
      for (const matter of leads) {
        if (matter && matter.id) {
          outputs.leads.push({
            value: matter.id,
            element: (
              <>
                <span>
                  {joinWithDash([
                    matter.matterNumber,
                    matter.clientDisplay,
                    matter.matterType?.name,
                    matter.otherSideDisplay,
                    matter.descriptionAutomation,
                  ])}
                </span>
                <StaffFlairById staffId={matter.attorneyResponsible?.id} />
              </>
            ),
          });
        }
      }
    }

    if (contactSearchData && contactSearchData.results?.length) {
      const countVal = contactSearchData.results.length === 100 ? '99+' : contactSearchData.results.length;
      outputs.leadsHeader = `Contacts (${countVal})`;
      const contacts = contactSearchData.results.slice(0, 5);
      // eslint-disable-next-line no-restricted-syntax
      for (const contact of contacts) {
        if (contact && contact.id) {
          const phone = contact.cell || contact.phone || contact.phone2;
          const email = contact.email?.toLowerCase();

          outputs.contacts.push({
            value: contact.id,
            element: <span>{joinWithDash([contact.displayName, email, phone])}</span>,
          });
        }
      }
    }

    return outputs;
  };
  const styleOverrides: TStyleOverrides = {
    inputWidth: '800px',
    dropdownWidth: '800px',
    dropdownHeight: '600px',
  };

  return (
    <TopnavSearch onClickLink={props.onClickLink} optionResolver={optionResolver} styleOverrides={styleOverrides} />
  );
};
