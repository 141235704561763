import { PAYMENT_TYPE } from '@sb-billing/business-logic/payment-source';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import {
  PrintNow,
  PrintNotApplicable,
  PrintManually,
  printMethodsByCode,
  printMethodsByValue,
} from '@sb-billing/business-logic/cheques';
import uuid from '@sb-itops/uuid';
import { calculateFeeDetails, extractFeeSchedule } from '@sb-billing/business-logic/payment-provider/services';

export const marshalData = ({
  formData,
  paymentSourceSelected,
  operatingAccount,
  invoiceSummaries,
  activeProviderType,
  activeProviderFormattedSettings,
  providerSpecificChargeData,
}) => {
  const {
    paidById,
    reference,
    comment,
    payments: paymentAmountsByInvoiceId,
    reason,
    effectiveDate,
    amount,
    paymentType,
    printingMethodId,
    pdfOnTrustPayment,
    chequeMemo,
    takePaymentNow,
    saveCardDetails,
  } = formData;

  const isBankAccount = paymentSourceSelected.paymentType !== PAYMENT_TYPE.direct;
  const isTrustPayment = paymentSourceSelected.paymentType === PAYMENT_TYPE.trust;

  let totalPayments = 0;
  const payments = (invoiceSummaries || []).reduce((acc, invSummary) => {
    const paymentAmount = paymentAmountsByInvoiceId[invSummary.id];
    if (Number.isFinite(paymentAmount) && paymentAmount > 0) {
      totalPayments += paymentAmount;
      const payment = {
        invoiceId: invSummary?.id,
        matterId: invSummary?.matter?.id,
        invoiceNumber: invSummary?.invoiceNumber,
        amount: paymentAmount,
      };
      acc.push(payment);
    }
    return acc;
  }, []);

  const msg = {
    paymentId: uuid(), // need to generate for opdating and sending to print trust pdf
    payorId: paidById,
    source: isBankAccount ? undefined : paymentSourceSelected.value,
    sourceAccountType: isBankAccount ? paymentSourceSelected.paymentType.toUpperCase() : undefined, // TRUST|OPERATING
    sourceBankAccountId: isBankAccount ? paymentSourceSelected.bankAccountId : undefined,
    invoices: payments,
    reference,
    note: comment,
    effectiveDate,
    totalAmount: amount,
    paymentType, // used by backend to determine which message to send
  };

  let reasonValue = null;
  // reasonField is AU/GB facet
  if (featureActive('BB-5508') && hasFacet(facets.reasonField)) {
    reasonValue = reason;
  } else if (hasFacet(facets.trustPaymentReasonField) && isTrustPayment) {
    // trustPaymentReasonField is US facet
    // For US, we keep reason field hidden from UI, but set the reason value when submitted if the invoice payment source is trust
    const invoiceIds = payments.map((invSummary) => `#${invSummary.invoiceNumber}`).join(', ');
    reasonValue = `Legal Fees on Invoice ${invoiceIds}`;
  }
  msg.reason = reasonValue;

  msg.isElectronicPayment =
    hasFacet(facets.electronicPayment) &&
    paymentSourceSelected.paymentType === PAYMENT_TYPE.trust &&
    printingMethodId === PrintNotApplicable;

  msg.allowOverdraw = hasFacet(facets.allowOverdraw);

  // These values are needed for handling charges in onTakePayment function
  if (takePaymentNow) {
    const bankAccountId = operatingAccount?.id;
    msg.bankAccountId = bankAccountId;
    msg.saveCardDetails = saveCardDetails;

    // Handle fees
    // Calculate the fee details if the fee is being passed on to the client.
    const clientIsCoveringFee = activeProviderFormattedSettings.clientCoversFeeOnPayments;

    if (clientIsCoveringFee) {
      const feeSchedule = clientIsCoveringFee
        ? extractFeeSchedule({
            providerType: activeProviderType,
            formattedProviderSpecificSettings: activeProviderFormattedSettings,
            bankAccountId,
          })
        : undefined;

      const feeDetails = clientIsCoveringFee
        ? calculateFeeDetails({
            providerType: activeProviderType,
            feeSchedule,
            providerSpecificFields: providerSpecificChargeData,
            desiredAmountInCents: amount || 0,
          })
        : {};

      msg.amountInCents = feeDetails.effectiveAmountInCents;
      msg.amountLessFees = feeDetails.desiredAmountInCents;
    } else {
      msg.amountInCents = amount;
      msg.amountLessFees = amount;
    }

    // If amount is less than invoices totalPayments then apply to oldest invoice first
    if (amount !== totalPayments) {
      let remaining = amount;
      const invoices = [];

      for (let i = 0; i <= payments.length; i += 1) {
        let invoice = payments[i];

        if (remaining < invoice.amount) {
          invoice = { ...invoice, amount: remaining };
          invoices.push(invoice);
          break;
        }

        invoices.push({ ...invoice });

        remaining -= invoice.amount;
      }
      msg.invoices = invoices;
    }
  }

  msg.chequeMemo = printingMethodId === PrintNow || printingMethodId === PrintManually ? chequeMemo : undefined;

  if (paymentSourceSelected.paymentType === PAYMENT_TYPE.trust && pdfOnTrustPayment === true) {
    msg.pdfOnTrustPayment = true;
  }

  if (paymentSourceSelected.paymentType === PAYMENT_TYPE.trust && printingMethodId) {
    const chequePrintActive = printingMethodId !== PrintNotApplicable;
    // if chequePrintActive is false, set chequePrintMethod to the first print method options
    const chequePrintMethod = chequePrintActive
      ? printMethodsByValue[printingMethodId].code
      : printMethodsByCode[0].code;
    // need to generate transaction Id (for the trust -> operating transaction) now so that it can be passed to Print Cheque Modal if necessary.
    const transferBetweenAccountsTransactionId = uuid();

    msg.transferBetweenAccountsTransactionId = transferBetweenAccountsTransactionId;
    msg.chequePrintActive = chequePrintActive;
    msg.chequePrintMethod = chequePrintMethod;
  }

  // Add the operating account detail for payment from trust
  if (featureActive('BB-5509') && hasFacet(facets.operatingAccountDetail) && isTrustPayment) {
    msg.destinationBankAccountName = operatingAccount?.accountName;
    msg.destinationBankAccountNumber = operatingAccount?.accountNumber;
    msg.destinationBankBranchNumber = operatingAccount?.branchNumber;
  }

  // When using 'takePaymentNow', we always sends client batch payment, so we treat it as Client payment
  if (msg.paymentType === 'CLIENT' || takePaymentNow) {
    // Ensure only 1 payment per matter, even if it covers more than 1 invoice
    const paymentIds = {};
    msg.invoices = msg.invoices.map((invoice) => {
      if (!paymentIds[invoice.matterId]) {
        paymentIds[invoice.matterId] = uuid();
      }

      return {
        ...invoice,
        paymentId: paymentIds[invoice.matterId],
      };
    });
  }

  return msg;
};
