import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Styles from './DownloadLedesDropdownOption.module.scss';

const DownloadLedesDropdownOption = ({ downloadLedes }) => (
  /* eslint-disable-next-line */
    <a className={classnames(Styles.dropdownMenuItem, "dropdown-cursor")} onClick={downloadLedes}>
    Download LEDES1998B
  </a>
);

DownloadLedesDropdownOption.propTypes = {
  downloadLedes: PropTypes.func.isRequired,
};

DownloadLedesDropdownOption.defaultProps = {};

export default DownloadLedesDropdownOption;
