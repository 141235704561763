'use strict';

/**
 * @readonly
 * @enum {number}
 */
const documentType = {
  INVOICE: 0,
};

/**
 * @readonly
 * @enum {string}
 */
const documentTypeFromValue = {
  0: 'Invoice',
};

const fileSize = {
  THREE_MB: 3145728,
};

const uploadFileType = {
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PNG: 'image/png',
};

// Magic bytes used to determine file type
// https://www.garykessler.net/library/file_sigs.html
/**
 * @readonly
 * @enum {string}
 */
const fileTypeSignature = Object.freeze({
  PNG: '89504e47',
  JPEG: 'ffd8ff', // for jpeg we compare only 3 bytes as 4th byte can be several options
  PDF: '25504446',
});

module.exports = {
  documentType,
  documentTypeFromValue,
  fileSize,
  uploadFileType,
  fileTypeSignature,
};
