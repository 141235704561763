import { defaultPath } from './default-path';

export const SET_AUTHORISATION_RESULT = `${defaultPath}/SET_AUTHORISATION_RESULT`;
export const SET_STORAGE_QUOTA_CALCULATED = `${defaultPath}/SET_STORAGE_QUOTA_CALCULATED`;
export const SET_SERVER_VERSION = `${defaultPath}/SET_SERVER_VERSION`;
export const SET_PRIVATE_COMPUTER = `${defaultPath}/SET_PRIVATE_COMPUTER`;
export const SET_LAST_LOGGED_IN_USER = `${defaultPath}/SET_LAST_LOGGED_IN_USER`;
export const SET_USER_DATA_CLEARING = `${defaultPath}/SET_USER_DATA_CLEARING`;
export const SET_LOGIN_FAILURE_MESSAGE = `${defaultPath}/SET_LOGIN_FAILURE_MESSAGE`;
export const SET_OWNING_COMPANY_INSECURE = `${defaultPath}/SET_OWNING_COMPANY_INSECURE`;
export const SET_LOGIN_BLOCKED_REASON = `${defaultPath}/SET_LOGIN_BLOCKED_REASON`;
export const SET_LOGIN_FORM_SUBHEADER = `${defaultPath}/SET_LOGIN_FORM_SUBHEADER`;
export const SET_FILE_MANAGER_INSTALLED = `${defaultPath}/SET_FILE_MANAGER_INSTALLED`;
export const SET_MAIN_MENU_EXPANSION_STATE = `${defaultPath}/SET_MAIN_MENU_EXPANSION_STATE`;
export const CLEAR_MAIN_MENU_EXPANSION_STATE = `${defaultPath}/CLEAR_MAIN_MENU_EXPANSION_STATE`;

export const bootstrapStatuses = {
  PRE_AUTH_REQUIRED: 'PRE_AUTH_REQUIRED',
  PRE_AUTH_IN_PROGRESS: 'PRE_AUTH_IN_PROGRESS',
  PRE_AUTH_COMPLETE: 'PRE_AUTH_COMPLETE',
  POST_AUTH_IN_PROGRESS: 'POST_AUTH_IN_PROGRESS',
  POST_AUTH_COMPLETE: 'POST_AUTH_COMPLETE',
};

export const authorisationFailures = {
  UNKNOWN_FAILURE: 'UNKNOWN_FAILURE',
  NO_ACCESS: 'NO_ACCESS',
};

export const loginBlockedReasons = {
  NO_2FA_XERO_USER: 'NO_2FA_XERO_USER',
};

export const mainMenuExpansionStates = {
  NEVER_EXPANDED: 'NEVER_EXPANDED',
  EXPANDED: 'EXPANDED',
  COLLAPSED: 'COLLAPSED',
};
