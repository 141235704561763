angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/advanced-search';

  $stateProvider

    .state('home.billing.advanced-search', {
      url: '/advanced-search',
      abstract: true,
      templateUrl: BASE_DIRECTORY + '/advanced-search.html',
      data: {
        authorized: true,
        tab: { type: 'advanced-search' }
      },
      controller: 'SbAdvancedSearchController',
      controllerAs: 'searchCtrl',
      reloadOnSearch: false,
    });

});
