import { getIssuedDateFilter, getBillingTypeFilter, getAttorneyResponsibleFilter } from '@sb-billing/redux/invoices';
import {
  getTypeId as getMatterTypeIdByMatterId,
  getStatus as getMatterStatusById,
} from '@sb-matter-management/redux/matters';
import { getMatterBalance, getBankAccountMap } from '@sb-billing/redux/bank-account-balances.2/selectors';

function createInvoiceFilter({
  issueDate,
  billingTypes,
  attorneysResponsible,
  matterTypes,
  matterStatuses,
  minimumTrustBalance,
  bankAccountId,
}) {
  const filterFns = [];

  if (issueDate) {
    filterFns.push(
      getIssuedDateFilter({
        filter: issueDate.filterType,
        startDate: issueDate.startDate,
        endDate: issueDate.endDate,
      }),
    );
  }

  if (billingTypes && !billingTypes.allSelected) {
    filterFns.push(getBillingTypeFilter(billingTypes.selections));
  }

  if (matterTypes && matterTypes.length) {
    const matterTypeLookup = new Set(matterTypes);
    filterFns.push((invoiceSummary) => {
      const matterTypeId = getMatterTypeIdByMatterId(invoiceSummary.matterId);
      return matterTypeLookup.has(matterTypeId);
    });
  }

  if (matterStatuses) {
    filterFns.push((invoiceSummary) => {
      const invoiceMatterStatus = getMatterStatusById(invoiceSummary.matterId);
      return invoiceMatterStatus !== 'Deleted' && matterStatuses.includes(invoiceMatterStatus);
    });
  }

  if (attorneysResponsible && attorneysResponsible.length > 0) {
    filterFns.push(getAttorneyResponsibleFilter(attorneysResponsible));
  }

  if (Number.isFinite(minimumTrustBalance)) {
    filterFns.push((invoiceSummary) => {
      const { currentVersion } = invoiceSummary;
      const currentTrustBalance = bankAccountId
        ? getMatterBalance(getBankAccountMap(), {
            matterId: currentVersion.matterId,
            bankAccountId,
          })
        : 0;
      return currentTrustBalance >= minimumTrustBalance;
    });
  }

  return (invoiceSummary) => filterFns.every((filterFn) => filterFn(invoiceSummary));
}

function filterTrustToOfficeInvoices(invoices, filters) {
  const filterFn = createInvoiceFilter(filters);
  return invoices.reduce((all, invoice) => {
    if (filterFn(invoice)) {
      all.push(invoice);
    }
    return all;
  }, []);
}

export { filterTrustToOfficeInvoices };
