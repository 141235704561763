import {
  getList,
  getById as getEntityById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/trust-cheques';

import { getLogger } from '@sb-itops/fe-logger';

angular.module('@sb-billing/services').service('sbTrustChequeService', function (sbGenericEndpointService, sbGenericCacheService, sbEndpointType) {
  const that = this;
  const log = getLogger('sbTrustChequeService');
  const endpoint = 'billing/trust-cheques';

  sbGenericCacheService.setupCache({
    name: 'sbTrustChequeService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.TrustChequeUpdated'
    },
    updateRedux,
    clearRedux,
  });

  that.getById = getEntityById;
  that.getByIds = getByIds;
  that.getByTransactionId = getByTransactionId;
  that.getByFilter = getByFilter;
  that.createChequesPdfP = createChequesPdfP;
  that.flagChequesAsPrintedP = flagChequesAsPrintedP;

  function getByIds(ids) {
    return ids.map(id => getEntityById(id)).filter(c => c);
  }

  function getByTransactionId(transactionid) {
    return getList().filter(c => c.payments && c.payments.find(p => p.transactionId === transactionid))[0];
  }

  function getByFilter(filter) {
    return _.filter(getList(), filter);
  }

  function flagChequesAsPrintedP (payload) {
    return sbGenericEndpointService.postPayloadP(`${endpoint}/print`, undefined, payload)
      .then((resp) => {
        log.debug('sending print request done: ', JSON.stringify(resp));
      })
      .catch((err) => {
        const errMsg = 'ERROR' || err || err.message;
        log.error('failed to print trust cheque', errMsg);
        throw err;
      });
  }

  function createChequesPdfP(requestId, chequeIds) {
    return sbGenericEndpointService.postPayloadP('billing/trust-cheques-pdf', undefined, { requestId, chequeIds })
      .then((resp) => {
        log.debug('sending reprint cheque request done: ', JSON.stringify(resp));
      })
      .catch((err) => {
        const errMsg = 'ERROR' || err || err.message;
        log.error('failed to reprint trust cheque', errMsg);
        throw err;
      });
  }
});
