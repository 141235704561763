import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SlidingToggle } from '@sb-itops/react/sliding-toggle';
import { WarningList } from '@sb-itops/react/warning-list';
import { hasFacet, facets } from '@sb-itops/region-facets';

import Styles from './UniqueNumberingSettings.module.scss';

export const UniqueNumberingSettings = memo(
  ({
    name,
    nextNumber,
    leadingZeros,
    exampleNumber,
    useManualNumbering,
    prefix,
    prefixWarnings,
    showPrefixError,
    onChange,
  }) => {
    // Handler for selecting all text when an input field is focused.
    const onFieldFocus = (event) => event.target.select();

    // Handler for field change events.
    const onNumericFieldValueChange = (event, oldValue) => {
      const target = event.currentTarget;
      const newVal = Number(target.value);
      const isValid = Number.isInteger(newVal) && Number.isFinite(newVal);
      onChange({ [target.name]: isValid ? newVal : oldValue });
    };

    const manualNumberingField = {
      originalName: 'useManualNumbering',
      scopedName: `${name}-useManualNumbering`,
    };

    const onUseManualNumberingFieldChange = (field, value) => {
      // we need to negate the value since it is displayed inverted from what is saved.
      // the onChange will be executed with the field and the new value from the SlidingToggle component
      if (field === manualNumberingField.scopedName) {
        onChange({ [manualNumberingField.originalName]: !value });
      } else {
        onChange({ [field]: !value });
      }
    };

    const onStringFieldChange = (event) => {
      const target = event.currentTarget;
      const newVal = target.value;
      onChange({ [target.name]: newVal });
    };

    return (
      <div className={Styles.uniqueNumberingSettings}>
        {useManualNumbering && (
          <div className={classnames(Styles.autoNumbering, 'flex', 'flex-row')}>
            Use Auto Numbering
            <SlidingToggle
              scope={name}
              name="useManualNumbering"
              id={manualNumberingField.scopedName}
              selected={!useManualNumbering.value}
              onChange={onUseManualNumberingFieldChange}
            />
          </div>
        )}

        {(!useManualNumbering || useManualNumbering.value === false) && (
          <>
            <div className={classnames('flex', 'flex-row')}>
              <div className={classnames(Styles.nextNumber, 'form-group', !nextNumber.isValid && 'has-error')}>
                <label>Starting Number</label>
                <input
                  type="text"
                  name="nextNumber"
                  className="form-control"
                  pattern="[0-9]"
                  maxLength="10"
                  required
                  value={nextNumber.value}
                  onFocus={onFieldFocus}
                  onChange={(evt) => onNumericFieldValueChange(evt, nextNumber.value)}
                />
              </div>

              {hasFacet(facets.transactionNumberingPrefix) && prefix && (
                <div
                  className={classnames(
                    Styles.prefix,
                    'form-group',
                    (showPrefixError || (prefix.isDirty && !prefix.isValid)) && 'has-error',
                  )}
                >
                  <label>Prefix</label>
                  <input
                    type="text"
                    name="prefix"
                    className="form-control"
                    maxLength="10"
                    required
                    value={prefix.value}
                    onFocus={onFieldFocus}
                    onChange={(evt) => onStringFieldChange(evt)}
                  />
                  {!showPrefixError && <WarningList list={prefixWarnings.map((text) => ({ text }))} />}
                </div>
              )}
            </div>

            <div className={classnames('flex', 'flex-row')}>
              <div className={classnames(Styles.leadingZeros, 'form-group', !leadingZeros.isValid && 'has-error')}>
                <label>Number of Leading Zeros</label>
                <input
                  type="text"
                  name="leadingZeros"
                  className="form-control"
                  pattern="[0-9]"
                  maxLength="2"
                  required
                  value={leadingZeros.value}
                  onFocus={onFieldFocus}
                  onChange={(evt) => onNumericFieldValueChange(evt, leadingZeros.value)}
                />
              </div>

              <div className={classnames(Styles.exampleNumber, 'form-group')}>
                <label>Example Number</label>
                <input className="form-control" value={exampleNumber} disabled />
              </div>
            </div>
          </>
        )}
      </div>
    );
  },
);

UniqueNumberingSettings.displayName = 'UniqueNumberingSettings';

UniqueNumberingSettings.propTypes = {
  nextNumber: PropTypes.object.isRequired,
  leadingZeros: PropTypes.object.isRequired,
  exampleNumber: PropTypes.string.isRequired,
  useManualNumbering: PropTypes.object,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.object,
  onChange: PropTypes.func,
  prefixWarnings: PropTypes.arrayOf(PropTypes.string),
  showPrefixError: PropTypes.bool,
};

UniqueNumberingSettings.defaultProps = {
  useManualNumbering: undefined,
  prefix: undefined,
  onChange: () => {},
  prefixWarnings: [],
  showPrefixError: false,
};

export default UniqueNumberingSettings;
