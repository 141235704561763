import { gql } from '@apollo/client';

const query = gql`
  query DashboardInvoicesCount($pivotDate: Int) {
    invoiceCount(pivotDate: $pivotDate) {
      final
      overdue
      count1to15
      count15to30
      count30to60
      count60plus
    }
  }
`;

const notificationIds = ['WebQueryInvoicingNotifications.InvoiceUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const DashboardInvoicesCount = {
  query,
  notificationIds,
};
