import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { InvoiceDisplay, transformInvoiceNumber as invoiceTransform } from '@sb-billing/react/invoice-display';
import { BankAccountDisplay } from '@sb-billing/react/bank-account-display';
import { useTranslation } from '@sb-itops/react';
import { localiseBankAccountTypeDisplay } from '@sb-billing/business-logic/bank-account/services';

const InterpolatedDescription = (props) => {
  const { t } = useTranslation();
  const { parts, deleted } = props;

  if (!parts) {
    return null;
  }

  const title = getTitle({ ...props, t });

  return (
    <div className="interpolated-description" title={title}>
      {deleted && <span className="deleted">(Deleted) </span>}
      {getComponents(props)}
    </div>
  );
};

const getComponents = ({ parts, onClickLink, isError, onClickDisplay }) =>
  parts &&
  parts.map((part, index) => {
    switch (part.type) {
      case 'matter':
        return (
          <Fragment key={`i_d_${index}`}>
            {' '}
            <MatterDisplay onClickLink={onClickLink} matterId={part.value.matterId} asLink inline />
          </Fragment>
        );
      case 'contact':
        return (
          <Fragment key={`i_d_${index}`}>
            {' '}
            <ContactDisplay
              onClickLink={onClickLink}
              contactId={part.value.entityId}
              asLink
              inline
              isError={isError}
              onClick={onClickDisplay}
            />
          </Fragment>
        );
      case 'invoice':
        return (
          <Fragment key={`i_d_${index}`}>
            {' '}
            <InvoiceDisplay onClickLink={onClickLink} invoiceId={part.value.invoiceId} asLink inline />
          </Fragment>
        );
      case 'account':
        return (
          <Fragment key={`i_d_${index}`}>
            {' '}
            <BankAccountDisplay account={part.value} inline />
          </Fragment>
        );
      default:
        return `${index === 0 || part.value === ',' ? '' : ' '}${part.value}`;
    }
  });

const getTitle = ({ parts, t }) =>
  parts &&
  parts
    .map((part) => {
      switch (part.type) {
        case 'matter':
          return getMatterDisplayById(part.value.matterId);
        case 'contact':
          return getContactDisplay(part.value.entityId) || 'unknown';
        case 'invoice':
          return invoiceTransform(part.value);
        case 'account':
          return localiseBankAccountTypeDisplay(part.value, t);
        default:
          return part.value;
      }
    })
    .join(' ')
    .replace(/ ,/g, ',');

InterpolatedDescription.propTypes = {
  parts: PropTypes.array,
  deleted: PropTypes.any,
  onClickLink: PropTypes.func,
  onClickDisplay: PropTypes.func,
};

// for now I will set all props optional as undefined. The idea is not validating anymore in the component
// and start using the default props. I wont do it in the components we already have but keep in mind from now
// if you are working or modifying this component please update the prop types. GP
InterpolatedDescription.defaultProps = {
  parts: undefined,
  deleted: undefined,
  onClickLink: undefined,
  onClickDisplay: undefined,
};

export default InterpolatedDescription;
