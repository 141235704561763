/**
 * Returns true if the passed {@link item} is currently selected.
 *
 * @param {string|number|*} item The item to check.
 * @return {boolean} True if the item is currently selected, falsey otherwise.
 */
export const isItemSelected = (state, { item }) => state.allSelected || state.itemSelectionState[item];

/**
 * Returns true if the passed {@link item} is currently deselected.
 *
 * @param {string|number|*} item The item to check.
 * @return {boolean} True if item is currently deselected, falsey otherwise.
 */
export const isItemDeselected = (state, { item }) => !state.allSelected && !state.itemSelectionState[item];

/**
 * Returns all of the currently selected items.
 * @return {object} An object with keys that are the selected items and all values fixed as boolean true.
 */
export const getSelectedItems = (state) =>
  Object.entries(state.itemSelectionState).reduce((acc, [item, isSelected]) => {
    if (state.allSelected || isSelected) {
      acc[item] = true;
    }

    return acc;
  }, {});

/**
 * Returns all of the currently deselected items.
 * @return {object} An object with keys that are the deselected items and all values fixed as boolean true.
 */
export const getDeselectedItems = (state) => {
  if (state.allSelected) {
    return {};
  }

  return Object.entries(state.itemSelectionState).reduce((acc, [item, isSelected]) => {
    if (!isSelected) {
      acc[item] = true;
    }

    return acc;
  }, {});
};

/**
 * Returns whether or not all of the items in state are currently selected.
 * @return {boolean} True if all items are currently selected, false otherwise.
 */
export const getAllItemsSelected = (state) => state.allSelected;

/**
 * Returns whether or not all of the items in state are currently deselected.
 * @return {boolean} True if all items are currently deselected, false otherwise.
 */
export const getAllItemsDeselected = (state) => {
  if (state.allSelected) {
    return false;
  }

  return Object.keys(getSelectedItems(state)).length === 0;
};
