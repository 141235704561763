import { getUserId } from 'web/services/user-session-management';

// A problem exists where the currently logged in desktop user's firm may differ from the currently logged in
// web app user's firm. This can lead to weird situations where a user performs operations on another
// firm's entities. To prevent he issue, whenever the desktop opens the web application, it will pass a query
// parameter "uid" equal to the user ID of the currently logged in desktop app user. If the user ID doesn't
// match the user ID of the currently logged in webapp user, we force a logout.
// Notes:
//   1. We only perform the check if uid is present to support backwards compatibility with older desktop versions.
export const verifyDesktopUserP = async ({ defaultTeardown }) => {
  const urlParams = new URLSearchParams(window.location.search.replace('/', ''));

  // The desktop user may be using an older version of desktop that does not support uid.
  // Or the request to load the app may not be originating from the desktop application.
  if (!urlParams.has('uid')) {
    return defaultTeardown;
  }

  const desktopUserId = urlParams.get('uid');
  const webAppUserId = getUserId();

  // We are done with the uid parameter now.
  urlParams.delete('uid');

  // If the desktop user's id matches the web app user's id, we can proceed as usual.
  if (desktopUserId !== webAppUserId) {
    throw new Error('Desktop user does not match currently logged in Billing user');
  }

  // Remove the uid parameter.
  window.location.search = `${urlParams.toString()}/`;

  return defaultTeardown;
};
