import { useRef, useEffect } from 'react';
import * as applicationState from 'web/redux/features/application-state';
import { store } from '@sb-itops/redux';

/**
 * Subscribes to redux store events to serve as a drop in replacement for the angular event system while we transition away from angular
 *
 * This should be relatively easy to remove once all the angular events are stripped out
 *
 * This is designed for use with the 'UPDATE_CACHE' events.
 *
 * @param type {string}
 * @param effect {function}
 * @param deps
 */
export function useReduxAction(type, effect) {
  const currentValue = useRef(null);

  const handleChange = () => {
    const state = store.getState();
    const action = applicationState.selectors.getLastAction(state);
    const previousValue = currentValue.current;
    currentValue.current = action.type;

    if (previousValue !== action.type && type === action.type) {
      let payloads = action.payload;
      if (!Array.isArray(payloads)) {
        payloads = payloads.entities ? Object.values(payloads.entities) : [payloads];
      }
      effect(payloads);
    }
  };

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
