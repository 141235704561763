import React from 'react';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { useDispatch, useSelector } from 'react-redux';
import store from '@sb-itops/redux/store';
import { getLogger } from '@sb-itops/fe-logger';
import { withOnLoad } from '@sb-itops/react/hoc';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { getRegion } from '@sb-itops/region';
import { getSchemeValue } from '@sb-itops/region-schemes';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { mapAddressFormFields } from '@sb-customer-management/business-logic/contacts/services/address-search';
import moment from 'moment';
import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { getApolloClient } from 'web/services/apollo';
import { EditContact } from 'web/graphql/queries';
import { useContactTypeaheadData } from 'web/hooks';
import { CreateEditContactSchema } from './CreateEditContactSchema';
import CreateEditContactForm from './CreateEditContactForm';

const region = getRegion();
const log = getLogger('CreateEditContactFormContainer');

export const formDisabled = ({ scope }) => {
  const { selectors } = withScopedFeature({ scope })(forms);
  const { formSubmitting } = selectors.getFormState(store.getState());

  return formSubmitting;
};

const mapContactToForm = (entity, initialOrg) => {
  let addressEntity = entity?.residentialAddress;
  switch (entity?.type) {
    case contactTypes.PERSON:
      addressEntity = entity?.residentialAddress;
      break;
    case contactTypes.COMPANY:
      addressEntity = entity?.businessAddress;
      break;
    case contactTypes.TRUST:
      addressEntity = entity?.trustAddress;
      break;
    default:
  }

  return {
    // contactType is used to determine which form to show and so isnt the raw value from the DB
    contactType: entity?.type || contactTypes.PERSON,
    bankAccountFields: {
      accountName: '',
      accountNumber: '',
      bankBranchNumber: '',
      bankName: '',
    },
    personFields: {
      title: entity?.title || '',
      firstName: entity?.firstName || '',
      lastName: entity?.lastName || '',
      phoneAreaCode: entity?.phoneAreaCode || '',
      phoneNumber: entity?.phoneLocalNumber || '',
      email: entity?.email || '',
      cellAreaCode: entity?.cellAreaCode || '',
      cellNumber: entity?.cellLocalNumber || '',
      birthDate: featureActive('NUCWEB-675') ? entity?.birthDate || '' : undefined,
      linkedCompany: entity?.representativeOfs?.[0]?.id || initialOrg || undefined,
      linkedCompanyList: entity?.representativeOfs,
      ledesClientId: entity?.customerBillingConfiguration?.ledesClientId || '',
    },
    companyFields: {
      companyName: entity?.name || '',
      // entity?.contactType is the raw value from the DB
      companyType: entity?.contactType || '',
      companyPhoneAreaCode: entity?.phoneAreaCode || '',
      companyPhoneNumber: entity?.phoneLocalNumber || '',
      companyEmail: entity?.email || '',
      companyFax: entity?.faxLocalNumber || '',
      companyFaxAreaCode: entity?.faxAreaCode || '',
      companyLedesClientId: entity?.customerBillingConfiguration?.ledesClientId || '',
    },
    trustFields: {
      trustName: entity?.name || '',
      trustStatus: entity?.status || '',
      trustPhoneAreaCode: entity?.phoneAreaCode || '',
      trustPhoneNumber: entity?.phoneLocalNumber || '',
      trustNumber: entity?.trustNumber || '',
      trustDate: (entity?.trustDate && moment(entity?.trustDate).toDate()) || '',
      trustees: (entity?.trustees?.length && entity?.trustees) || [{ name: '', type: '' }],
      trustFax: entity?.faxLocalNumber || '',
      trustFaxAreaCode: entity?.faxAreaCode || '',
      trustLedesClientId: entity?.customerBillingConfiguration?.ledesClientId || '',
    },
    addressFields: {
      buildingLevel: addressEntity?.detailedStreetAddress?.buildingLevel || '',
      unitNumber: addressEntity?.detailedStreetAddress?.unitNumber || '',
      unitType: addressEntity?.detailedStreetAddress?.unitType || '',
      streetNumber: addressEntity?.detailedStreetAddress?.streetNumber || '',
      streetName: addressEntity?.detailedStreetAddress?.streetName || '',
      streetType: addressEntity?.detailedStreetAddress?.streetType || '',
      addressLine1: addressEntity?.addressLine1 || '',
      addressLine2: addressEntity?.addressLine2 || '',
      city: addressEntity?.city || '',
      state: addressEntity?.state || '',
      zipCode: addressEntity?.zipCode || '',
      county: addressEntity?.county || '',
      locality: addressEntity?.locality || '',
      country: addressEntity?.country || '',
    },
  };
};

const hooks = ({ scope, contactId, initialOrg }) => ({
  useFormData: () => {
    const dispatch = useDispatch();
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);
    const {
      formInitialised,
      fields: formFields,
      formSubmitting,
      submitFailed,
    } = useSelector(formSelectors.getFormState);

    const formValues = useSelector(formSelectors.getFieldValues);

    const { contactType, personFields, addressFields, companyFields, trustFields, bankAccountFields } = formFields;

    const {
      contactOptions: peopleOptions,
      contactOptionsDataLoading: peopleOptionsDataLoading,
      contactOptionsHasMore: peopleOptionsHasMore,
      onFetchContactOptions: onFetchPeopleOptions,
      onFetchMoreContactOptions: onFetchMorePeopleOptions,
    } = useContactTypeaheadData({ includeTypes: ['Person'] });
    const {
      contactOptions: companyOptions,
      contactOptionsDataLoading: companyOptionsDataLoading,
      contactOptionsHasMore: companyOptionsHasMore,
      onFetchContactOptions: onFetchCompanyOptions,
      onFetchMoreContactOptions: onFetchMoreCompanyOptions,
    } = useContactTypeaheadData({ includeTypes: ['Organisation'] });

    return {
      linkedCompanyList: formValues?.personFields?.linkedCompanyList || [],
      peopleOptionsDataLoading,
      peopleOptionsHasMore,
      onFetchPeopleOptions,
      onFetchMorePeopleOptions,
      companyOptionsDataLoading,
      companyOptionsHasMore,
      onFetchCompanyOptions,
      onFetchMoreCompanyOptions,
      onLoad: () => {
        const apolloClient = getApolloClient();

        // If no contactId is supplied this is a create form so simply initialise with empty strings
        const query = contactId
          ? apolloClient.query({
              query: EditContact,
              variables: { id: contactId },
              fetchPolicy: 'network-only',
            })
          : Promise.resolve();
        query
          .then((results) => {
            const contact = results?.data?.contact;

            const contactFormValues = mapContactToForm(contact, initialOrg);
            dispatch(
              formActions.initialiseForm({
                fieldValues: {
                  region,
                  ...contactFormValues,
                },
              }),
            );
            return undefined;
          })
          .catch((err) => log.error(err));

        const onUnload = () => dispatch(formActions.clearForm());
        return onUnload;
      },
      onFieldValueUpdated: (fieldValues) => {
        dispatch(formActions.updateFieldValues({ fieldValues }));
      },
      onFieldValueSet: (field, value) => {
        dispatch(formActions.setFieldValue({ field, value }));
      },
      deleteField: (field) => {
        dispatch(formActions.deleteField({ field }));
      },
      validateForm: () => {
        dispatch(formOperations.validateSchema({ schema: CreateEditContactSchema(getRegion()) }));
      },
      onRadioChange: (clickedButton) => {
        dispatch(formActions.updateFieldValues({ fieldValues: { contactType: clickedButton.id } }));
      },
      onAddressAutocompleteSelected: (addressInfo) => {
        const newAddressFields = mapAddressFormFields(addressInfo, getSchemeValue('addressScheme'));
        dispatch(formActions.updateFieldValues({ fieldValues: { addressFields: newAddressFields } }));
      },
      scope,
      contactType,
      formDisabled: formSubmitting,
      formInitialised,
      region,
      personFields,
      addressFields,
      companyFields,
      trustFields,
      bankAccountFields,
      submitFailed,
      companyOptions,
      peopleOptions,
    };
  },
});

const CreateEditContactFormContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(
      withOnLoad(
        ({
          onFieldValueUpdated,
          onRadioChange,
          contactType,
          submitFailed,
          formInitialised,
          validateForm,
          deleteField,
          ...props
        }) => (
          <CreateEditContactForm
            {...props}
            phoneNumberScheme={getSchemeValue('phoneNumberScheme')}
            addressScheme={getSchemeValue('addressScheme')}
            contactType={contactType?.value}
            onFieldValueUpdated={onFieldValueUpdated}
            deleteField={deleteField}
            onRadioChange={onRadioChange}
            submitFailed={submitFailed}
            formInitialised={formInitialised}
            validateForm={validateForm}
          />
        ),
      ),
    ),
  ),
);

CreateEditContactFormContainer.displayName = 'CreateEditContactFormContainer';

CreateEditContactFormContainer.propTypes = {
  contactId: PropTypes.string,
  initialOrg: PropTypes.string,
  isUtbmsEnabled: PropTypes.bool,
  showBankDetailsTab: PropTypes.bool,
  isNewUI: PropTypes.bool,
};

CreateEditContactFormContainer.defaultProps = {
  contactId: undefined,
  initialOrg: undefined,
  isUtbmsEnabled: undefined,
  showBankDetailsTab: undefined,
  isNewUI: undefined,
};

export default CreateEditContactFormContainer;
