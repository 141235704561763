const { useMultipleItemSelection } = require('../multi-item-select/use-multiple-item-selection');

export const usePaginatedMultipleItemSelection = ({ scope, currentPage, currentPageItems }) => {
  const {
    selectedItems: selectedPages,
    toggleItems: toggleSelectPages,
    selectAllItems: selectAllPages,
    deselectAllItems: deselectAllPages,
  } = useMultipleItemSelection({
    scope: `${scope}/pageSelection`,
  });

  const { isAllItemsSelected, selectedItems, toggleItems, selectAllItems, deselectAllItems } = useMultipleItemSelection(
    {
      scope: `${scope}/itemSelection`,
    },
  );

  const toggleSelectItems = (toggledItems) => {
    let additionalSelectedItemCount = 0;
    const itemIdsToToggle = toggledItems.reduce((acc, item) => {
      if (!selectedItems[item.id]) {
        // i.e. if it's undefined or false
        additionalSelectedItemCount += 1;
      }
      acc.push(item.id);

      return acc;
    }, []);

    // toggle item selection
    toggleItems(itemIdsToToggle);

    // ensure page selection is consistent item selection
    const isAllItemsOnPageSelected = selectedPages[currentPage] === true;
    if (isAllItemsOnPageSelected) {
      // toggle off page selection
      toggleSelectPages([currentPage]);
    } else {
      // toggle on page selection
      const selectedItemCountOnPage = currentPageItems.filter((item) => selectedItems[item.id]).length;
      if (selectedItemCountOnPage + additionalSelectedItemCount === currentPageItems.length) {
        toggleSelectPages([currentPage]);
      }
    }

    // even when all items are selected, we won't trigger a "select all"
    // this is because we need to differentiate between when user explicitly
    // selects all items vs the "selected all matching filters" action
  };

  const toggleSelectAll = () => {
    if (isAllItemsSelected) {
      deselectAll();
    } else {
      selectAll();
    }
  };

  const selectAll = () => {
    selectAllItems();
    selectAllPages();
  };

  const deselectAll = () => {
    deselectAllItems();
    deselectAllPages();
  };

  const toggleSelectAllOnCurrentPage = () => {
    const currentPageSelectedCount = currentPageItems.filter((item) => selectedItems[item.id]).length;
    const isAllSelectedForCurrentPage =
      currentPageItems.length > 0 && currentPageSelectedCount === currentPageItems.length;

    const itemsToToggle = isAllSelectedForCurrentPage
      ? currentPageItems // if all selected: deselect all items on current page
      : currentPageItems.filter((item) => !selectedItems[item.id]); // if some not selected: toggle those not already selected
    toggleSelectItems(itemsToToggle);
  };

  return {
    isAllItemsSelected, // when all items are selected, ignore selectedItems as it may not have all the items
    selectedItems,
    selectedPages,
    deselectAll,
    selectAll,
    toggleSelectAll, // set select all to true, does not update selected items as we don't have items from all pages
    toggleSelectAllOnCurrentPage, // toggle page selection, and ensures selected items are updated accordingly
    toggleSelectItems, // toggle select specified items
  };
};
