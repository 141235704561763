angular.module('sb.billing.webapp').component('sbDataDepositSlipsByFilter', {
  require:  { compose: '^sbCompose' },
  bindings : { composeDataKey: '=?', filter: '<' },
  controller: function ($scope, sbDepositSlipService, sbLoggerService) {
    'use strict';

    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDataDepositSlipsByFilter');
    let initialized;
    ctrl.composeDataKey = ctrl.composeDataKey || 'depositSlips';

    ctrl.$onInit = function () {
      update();
      initialized = true;
    };

    ctrl.$onChanges = function () {
      if (initialized) {
        update();
      }
    };

    function update() {
      let depositSlips = [];
      log.info('fetching depositSlips using filter function', ctrl.filter);
      depositSlips = sbDepositSlipService.getByFilter(ctrl.filter) || [];
      log.info('depositSlips : ', depositSlips);
      ctrl.compose.setComposeData({ dataType: ctrl.composeDataKey, data: depositSlips }, ctrl.composeDataKey);
    }

    $scope.$on('smokeball-data-update-sbDepositSlipService', update);
  }
});
