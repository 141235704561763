import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typeahead } from '@sb-itops/react';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { ContactCreateEditModal } from 'web/react-redux/components/contact-create-edit-modal';
import uuid from '@sb-itops/uuid';
import { AddContactInlineForm } from '../add-contact-inline-form.2';
import styles from './ContactMultiSelect.module.scss';

let unprotectedDragIndex = 0;

// global object workaround for trash DnD spec that doesnt allow you to identify the origin of your DnD events
const dragData = {};

const ContactMultiSelect = ({
  selectedContactIds,
  contactOptions,
  max,
  className,
  placeholder,
  enableReordering,
  disabled,
  isRequired,
  menuPlacement,
  maxMenuHeight,
  addContactFormType,
  showInlineActions,
  // callbacks and functions
  onContactsChanged,
  onClickLink,
}) => {
  // Used to prevent dragover affecting other elements
  const [elementId] = useState(uuid());
  const finalContactOptions = contactOptions;
  // Load Testing: comment above and uncomment below to simulate large number of contacts
  // const numberToMultiply = 3001;
  // const finalContactOptions = contactOptions.reduce((acc, contactOption) => {
  //   [...Array(numberToMultiply).keys()].forEach((key) =>
  //     acc.push({
  //       value: `${contactOption.value} ${key}`,
  //       label: `${contactOption.label} ${key}`,
  //       searchText: `${contactOption.searchText} ${key}`,
  //     }),
  //   );
  //   return acc;
  // }, []);

  // internal state to keep track of cleared contact that should be auto focused on
  const [contactIndexToFocus, setContactIndexToFocus] = useState(undefined);

  // show only one inline/modal add contact form
  const [showAddContactForm, setShowAddContactForm] = useState(undefined);

  // show only one modal edit contact form
  const [showEditContactForm, setShowEditContactForm] = useState(undefined);

  // construct contact options map to enable faster lookup
  const contactOptionsMap = finalContactOptions.reduce((acc, contactOption) => {
    acc[contactOption.value] = contactOption;
    return acc;
  }, {});

  const isContactDeleted = (contactId) => getContactById(contactId)?.removed;

  const isGroupOfPeopleContact = (contactId) => getContactById(contactId)?.type === 'GroupOfPeople';

  const getTooltipForDisabledLink = (contactId) => {
    if (isContactDeleted(contactId)) {
      return 'Unable to edit deleted contacts. Select view to restore this contact.';
    }
    if (isGroupOfPeopleContact(contactId)) {
      return 'Unable to edit contact groups. Please create a new group to change members.';
    }
    return '';
  };

  const updateSelectedContactIdsIfChanged = (newSelectedContactIds) => {
    if (
      newSelectedContactIds.length !== selectedContactIds.length ||
      !newSelectedContactIds.every((contactId, index) => contactId === selectedContactIds[index])
    ) {
      onContactsChanged(newSelectedContactIds);
    }
  };

  // one of the subtle use case here is user can clear a contact selection, when they do we
  // still need to render this cleared selection, thus selectedContactIds can be undefined
  const selectedDebtorIdsNonEmpty = selectedContactIds.filter((contactId) => contactId);
  const noContactSelected = selectedDebtorIdsNonEmpty.length === 0;

  const onContactCreated = (selectedIndex, selectedContactId) => {
    const newSelectedContactIds = [...selectedContactIds];
    newSelectedContactIds[selectedIndex] = selectedContactId;
    updateSelectedContactIdsIfChanged(newSelectedContactIds);
  };

  const onContactChanged = (selectedIndex, selectedContactOption) => {
    const newSelectedContactIds = [...selectedContactIds];

    // The selection of a duplicate contacts should update the position of the contact rather than insert a duplicate
    const existingContactIdIndex = newSelectedContactIds.indexOf(selectedContactOption?.value);
    if (selectedContactOption && existingContactIdIndex !== -1) {
      newSelectedContactIds[existingContactIdIndex] = '';
    }
    newSelectedContactIds[selectedIndex] = selectedContactOption?.value;

    // improves UX by focusing on cleared contact so user can start typing to find the right contact
    if (!selectedContactOption) {
      setContactIndexToFocus(selectedIndex);
    }
    updateSelectedContactIdsIfChanged(newSelectedContactIds);
  };

  const openAddContactForm = (contactIndex) => {
    setShowAddContactForm(contactIndex);
  };

  const closeAddContactForm = () => {
    setShowAddContactForm(undefined);
  };

  const openEditContactForm = (contactIndex) => {
    setShowEditContactForm(contactIndex);
  };

  const closeEditContactForm = () => {
    setShowEditContactForm(undefined);
  };

  const moveContactIndex = (indexToMove, targetIndex) => {
    const reorderedContactIds = [...selectedContactIds];

    // In the case where an item is moved to replace the pseudo item at the end of the list, do nothing
    if (targetIndex + 1 > reorderedContactIds.length) {
      return;
    }
    reorderedContactIds.splice(indexToMove, 1);
    reorderedContactIds.splice(targetIndex, 0, selectedContactIds[indexToMove]);
    updateSelectedContactIdsIfChanged(reorderedContactIds);
    unprotectedDragIndex = targetIndex;
  };

  // can reorder item only if the add contact form is not open
  const canReorderItem = showAddContactForm === undefined;

  const divRef = React.useRef([]);

  return (
    <>
      {selectedContactIds
        .concat([''])
        .slice(0, max)
        .map((selectedContactId, index) => {
          // this look up is required in order to set a default selection
          const selectedContactOption = contactOptionsMap[selectedContactId];

          return (
            <div
              className={styles.contactWrapper}
              key={`contact-select-${index}`}
              id={`contact-select-${index}`}
              ref={(el) => {
                divRef.current[index] = el;
              }}
              onDragOver={(event) => {
                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'move';
                if (index !== unprotectedDragIndex && dragData[elementId]) {
                  moveContactIndex(unprotectedDragIndex, index);
                }
              }}
              onDragStart={(event) => {
                // Unprotected var used as event data is not availabile during dragover
                unprotectedDragIndex = index;
                event.stopPropagation();
                dragData[elementId] = true;
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'move';
              }}
              onDragEnd={() => {
                if (dragData[elementId]) {
                  divRef.current[index].setAttribute('draggable', 'false');
                  dragData[elementId] = false;
                }
              }}
            >
              <div className={classnames(styles.contactField, className)}>
                {enableReordering && max > 1 && (
                  <i
                    onMouseDown={() => {
                      // Required to prevent other children triggering drag events
                      divRef.current[index].setAttribute('draggable', 'true');
                    }}
                    onMouseUp={() => {
                      // Required to prevent other children triggering drag events
                      divRef.current[index].setAttribute('draggable', 'false');
                    }}
                    className={classnames(
                      styles.dragElement,
                      'icon',
                      'icon-grab-handle',
                      canReorderItem ? undefined : styles.showGreyedOutIcon,
                    )}
                  />
                )}
                {!(showAddContactForm === index && addContactFormType === 'inline') && ( // hide the Typehead when a inline contact form is displaying for this Typehead.
                  <Typeahead
                    options={finalContactOptions}
                    disabled={disabled}
                    selectedOption={selectedContactOption}
                    onSelect={(contactOption) => onContactChanged(index, contactOption)}
                    placeholder={placeholder}
                    className={styles.contactSelect}
                    containerClassName={isRequired && noContactSelected && index === 0 ? styles.hasError : undefined}
                    menuPlacement={menuPlacement}
                    maxMenuHeight={maxMenuHeight}
                    autoFocus={index === contactIndexToFocus}
                    actionList={[
                      {
                        displayComponent: (
                          <span className={styles.typeaheadAction}>
                            <i className="fa fa-plus" /> &emsp;Add New Contact
                          </span>
                        ),
                        callback: () => {
                          if (showAddContactForm === index) {
                            closeAddContactForm(index);
                          } else {
                            /*  Remove focus on the contact input when open the modal.
                            Otherwise, if we close the modal without creating a new contact and click on this control again, the dropdown don’t appear as would be expected. */
                            document.activeElement.blur();
                            openAddContactForm(index);
                          }
                        },
                      },
                    ]}
                  />
                )}
                {/* only display the action link when contactFormType is set to modal.
                this is for UX consistency purpose, as EDIT link only opens contact with modal at the moment,
                which should not be available for use cases that expect inline form */}
                {showInlineActions && addContactFormType === 'modal' && (
                  <div className={styles.inlineActionLinks}>
                    {selectedContactId && (
                      <>
                        <div className={classnames(styles.inlineActionLink)}>
                          <LinkableText
                            className={styles.item}
                            text="VIEW"
                            onClickLink={() => onClickLink({ type: 'contact', id: selectedContactId })}
                            asLink
                            inline
                          />
                        </div>
                        <div className={classnames(styles.inlineActionLink, styles.divider)}>
                          <LinkableText
                            className={styles.item}
                            text="EDIT"
                            onClickLink={() =>
                              showEditContactForm === index ? closeEditContactForm(index) : openEditContactForm(index)
                            }
                            tooltip={getTooltipForDisabledLink(selectedContactId)}
                            disabled={isContactDeleted(selectedContactId) || isGroupOfPeopleContact(selectedContactId)}
                            asLink
                            inline
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              {/* Add contact form for different types ('inline' or 'modal') */}
              {showAddContactForm === index && addContactFormType === 'inline' && (
                <AddContactInlineForm
                  onClose={() => closeAddContactForm(index)}
                  onContactCreated={(contactId) => {
                    onContactCreated(index, contactId);
                    closeAddContactForm(index);
                  }}
                />
              )}
              {showAddContactForm === index && addContactFormType === 'modal' && (
                <ContactCreateEditModal
                  onClickLink={() => {}}
                  isVisible={showAddContactForm !== undefined}
                  onClose={() => closeAddContactForm(index)}
                  onContactCreated={(contactId) => {
                    onContactCreated(index, contactId);
                    closeAddContactForm(index);
                  }}
                  onContactCreatedWhenSaveAndNew={(contactId) => {
                    onContactCreated(index, contactId);
                    // When click on 'SAVE & NEW', show the modal of next Typeahead if not exceed the max number, otherwise close the modal
                    if (index + 1 < max) {
                      setShowAddContactForm(index + 1);
                    } else {
                      closeAddContactForm(index);
                    }
                  }}
                />
              )}
              {/* Edit contact form for 'EDIT' action link */}
              {showInlineActions && showEditContactForm === index && (
                <ContactCreateEditModal
                  onClickLink={() => {}}
                  isVisible={showEditContactForm !== undefined}
                  contactId={selectedContactId}
                  onClose={() => closeEditContactForm(index)}
                />
              )}
            </div>
          );
        })}
    </>
  );
};

ContactMultiSelect.displayName = 'ContactMultiSelect';

ContactMultiSelect.propTypes = {
  selectedContactIds: PropTypes.arrayOf(PropTypes.string),
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ).isRequired,
  max: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  enableReordering: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  // callbacks & functions
  onContactsChanged: PropTypes.func.isRequired,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  maxMenuHeight: PropTypes.number,
  addContactFormType: PropTypes.oneOf(['inline', 'modal']),
  showInlineActions: PropTypes.bool,
  onClickLink: PropTypes.func,
};

ContactMultiSelect.defaultProps = {
  selectedContactIds: [],
  max: 3,
  className: undefined,
  placeholder: 'Select a contact ...',
  enableReordering: true,
  disabled: false,
  isRequired: true,
  menuPlacement: 'bottom',
  maxMenuHeight: undefined,
  addContactFormType: 'inline',
  showInlineActions: false,
  onClickLink: () => {},
};

export default ContactMultiSelect;
