import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query MatterDetailsBar($matterId: ID!, $matterFilter: MatterFilter) {
    matter(id: $matterId, filter: $matterFilter) {
      id
      matterNumber
      description
      status
      isLead
      clientDisplay
      clientNames
      otherSideDisplay
      matterStarted
      matterType {
        matterTypeId
        name
      }
      clients {
        id
        displayNameFirstLast
        displayName
      }
    }
  }
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterDetailsBar = {
  query,
  notificationIds,
};
