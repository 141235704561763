import { scopeFeature } from '@sb-itops/redux/hofs';
import * as unscopedForms2 from '@sb-itops/redux/forms2/feature';
import * as customOperations from './operations';
import * as customSelectors from './selectors';

// The transaction-numbering-settings-form feature presents an almost identical interface to the forms dependency.
// To simplify the feature, we export most of the dependency forms interface as the feature for the forms tab.
// And simply add any specialised operations and selectors code.
export const { actions, operations, selectors, types, reducer, defaultPath } = scopeFeature({
  ...unscopedForms2,
  operations: {
    ...unscopedForms2.operations,
    ...customOperations,
  },
  selectors: {
    ...unscopedForms2.selectors,
    ...customSelectors,
  },
});
