'use strict';

import {
  getById,
  getList as getInvoiceList,
  getInvoiceSummariesByFilter,
  getLatestInvoice,
} from '@sb-billing/redux/invoices';
import { integerToDate as from, today as nowDateOnly } from '@sb-itops/date';

angular.module('@sb-billing/services').service('sbInvoicingService', function ($rootScope, sbLoggerService, sbGenericEndpointService, sbInvoicingCacheManager, sbUuidService, sbMattersMbService) {
  const that = this;
  const delDraftInvoiceEndpoint = '/billing/invoice';
  const waiveInvoiceEndpoint = '/billing/invoice/waive';
  const voidInvoiceEndpoint = '/billing/invoice/void';
  const reverseWaiveInvoiceEndpoint = '/billing/invoice/reverse';
  const log = sbLoggerService.getLogger('sbInvoicingService');

  that.getInvoiceP = getInvoiceP;
  that.getInvoice = getInvoice;
  that.getInvoiceStatuses = getInvoiceStatuses;
  that.getLineItemConfiguration = getLineItemConfiguration;
  that.getDiscounts = getDiscounts;
  that.getDefaultInvoiceIssueDate = getDefaultInvoiceIssueDate;
  that.getDefaultInvoiceDueDate = getDefaultInvoiceDueDate;
  that.getInvoiceDueDate = getInvoiceDueDate;
  that.getOriginalInvoiceVersionId = getOriginalInvoiceVersionId;
  that.getClassicInvoiceVersionId = getClassicInvoiceVersionId;
  that.getInvoiceSummaries = getInvoiceSummariesByFilter;
  that.getAllInvoices = getAllInvoices;
  that.getInvoiceIDByNumber = getInvoiceIDByNumber;
  that.getInvoicesByNumber = getInvoicesByNumber;
  that.getOverdueInvoices = getOverdueInvoices;
  that.getFullInvoice = getFullInvoice;

  that.isInvoiceVoided = isInvoiceVoided;
  that.isInvoiceWaived = isInvoiceWaived;
  that.isInvoicePaid = isInvoicePaid;
  that.delDraftInvoiceP = delDraftInvoiceP;
  that.filterOverdueSummaries = filterOverdueSummaries;
  that.isInvoiceSummaryOverdue = isInvoiceSummaryOverdue;
  that.filterInvoicesByAttorneys = filterInvoicesByAttorneys;
  that.waiveInvoiceP = waiveInvoiceP;
  that.reverseWaiveInvoiceP = reverseWaiveInvoiceP;
  that.voidInvoiceP = voidInvoiceP;
  that.getInvoiceNeighbours = getInvoiceNeighbours;
  that.getPreviousInvoice = getPreviousInvoice;

  const invoiceStatuses = [
    {value: 'DRAFT', display: 'Draft'},
    {value: 'FINAL', display: 'Unpaid'},
    {value: 'PAID', display: 'Paid'},
    {value: 'VOID', display: 'Void'},
  ];
  const lineItemConfiguration = {list: 0, summary: 1, summaryWithList: 2};
  const discounts = {NONE: 0, CASH: 1, PERCENT: 2};

  function getInvoiceStatuses() {
    return invoiceStatuses;
  }

  /**
   * Gets the neighbouring invoices of the given invoice. This is with
   * respect to invoice issueDate.
   *
   * A neighbour will be represented by its id if it exists
   * A neighbour will be undefined if they do not exist.
   *
   * returns {post: {}, prior: {}} if there are no neighbours
   *
   * TODO this codes returns return value and implementation should be simplified to
   * return either {} or undefined if there are no neighbours, rather than the nested object
   *
   **/
  function getInvoiceNeighbours (invoiceId, matterId, debtorIds) {
    const invoiceVersion = getInvoice(invoiceId);
    if (!invoiceVersion) {
      return {post: {}, prior: {}};
    }

    const previousIssueDate = invoiceVersion.issuedDate;

    // find all invoices for same matter & debtor(s)
    const allInvoicesForThisMatterDebtorsGroup = getInvoiceSummariesByFilter({
      matterId,
      debtorIds,
    });

    // find neighbouring invoice by issuedDate
    const invoiceNeighbours = allInvoicesForThisMatterDebtorsGroup.reduce(
      (neighbours, fullInvoice) => {
        const invoice = fullInvoice.currentVersion;

        // ignore the current invoice
        if (invoice.invoiceId === invoiceVersion.invoiceId) {
          return neighbours;
        }

        if (invoice.status !== 'FINAL' && invoice.status !== 'PAID') {
          return neighbours;
        }

        // Moving an invoice from an issue date that is the same as another will show an error
        // with the similar issue date as prior condition
        if (
          invoice.issuedDate <= previousIssueDate &&
          (invoice.issuedDate > neighbours.prior.date || !neighbours.prior.date)
        ) {
          neighbours.prior.date = invoice.issuedDate;
          neighbours.prior.invoiceId = invoice.invoiceId;
        }
        else if (
          invoice.issuedDate >= previousIssueDate &&
          (invoice.issuedDate < neighbours.post.date || !neighbours.post.date)
        ) {
          neighbours.post.date = invoice.issuedDate;
          neighbours.post.invoiceId = invoice.invoiceId;
        }

        return neighbours;
      },
      {
        prior: {
        //   date, invoiceId,
        },
        post: {
        //   date, invoiceId,
        },
      },
    );

    return invoiceNeighbours;
  }

  /**
   * Gets the previous invoice, based off of issue date.
   * 
   * If no invoiceId is specified, get the latest invoice for the matter/debtor.
   * Otherwise, get the invoice that is before the specifed invoice (for matter/debtor of that invoice)
   *
   */
  function getPreviousInvoice (invoiceId, matterId, debtorIds) {
    const currentInvoice = getInvoice(invoiceId);

    if (!currentInvoice) {
      return getLatestInvoice({matterId, debtorIds});
    }

    const neighbours = getInvoiceNeighbours(invoiceId, matterId, debtorIds);
    if (!neighbours) {
      return;
    }
    const previousId = neighbours.prior.invoiceId;

    if (!previousId) {
      return;
    }

    return getInvoice(previousId);
  }

  function getLineItemConfiguration() {
    return lineItemConfiguration;
  }

  function getDiscounts() {
    return discounts;
  }

  function getDefaultInvoiceIssueDate() {
    return moment(moment().format('YYYYMMDD'), 'YYYYMMDD').toDate();
  }

  function getDefaultInvoiceDueDate() {
    return moment(moment().format('YYYYMMDD'), 'YYYYMMDD').add(1, 'month').toDate();
  }

  function getInvoiceDueDate(days) {
    return moment(moment().format('YYYYMMDD'), 'YYYYMMDD').add(days, 'day').toDate();
  }

  function isInvoiceVoided(invoiceId) {
    const invoice = getById(invoiceId);
    return _.get(invoice, 'currentVersion.status') === 'VOID';
  }

  function isInvoicePaid(invoiceId) {
    const invoice = getById(invoiceId);
    return _.get(invoice, 'currentVersion.status') === 'PAID';
  }

  function isInvoiceWaived(invoiceId) {
    const invoice = getById(invoiceId);
    return _.get(invoice, 'currentVersion.waived') === true;
  }

  function voidInvoiceP (id, versionId, matterId, opdates) {
    return sbGenericEndpointService
      .postPayloadP(voidInvoiceEndpoint, id, {versionId, matterId}, 'POST', { changeset: opdates })
      .catch((err) => {
        log.warn('error voiding invoice %s', id);
        log.warn(err);
        throw err;
      });
  }

  function getInvoiceP(id) {    
    return sbInvoicingCacheManager.updateP().then(() => getById[id]);
  }

  function getInvoice(id) {
    const invoice = getFullInvoice(id);
    return invoice && invoice.currentVersion;
  }

  function getFullInvoice (id) {
    return getById(id);
  }

  function getOriginalInvoiceVersionId(invoiceId) {
    const invoice = getById(invoiceId);

    if (invoice && invoice.currentVersion && invoice.currentVersion.isOriginalInvoice) {
      return invoice.invoicePdfVersionId;
    }
    
    return invoice && invoice.versionIds && _.first(_.takeRight(invoice.versionIds, 2));
  }

  function getClassicInvoiceVersionId(invoiceId) {
    const invoice = getById(invoiceId);
    
    return invoice && invoice.versionIds && _.first(_.takeRight(invoice.versionIds, 2));
  }

  function delDraftInvoiceP(id) {
    const invoice = getById(id);
    invoice.currentVersion.status = 'DELETED';

    return sbGenericEndpointService
      .postPayloadP(delDraftInvoiceEndpoint, id + '/' + sbUuidService.get(), {}, 'DELETE', {changeset: { sbInvoicingService: [invoice]}})
      .then(() => {
        $rootScope.$broadcast('smokeball-data-update-invoice-deleted', invoice.currentVersion);
        return id;
      });
  }

  function getAllInvoices() {
    const invoices = getInvoiceList();
    const invoicesCurrentVersion = [];
    if (invoices) {
      _.each(invoices, function (invoice) {
        invoicesCurrentVersion.push(invoice.currentVersion);
      });
    }
    return invoicesCurrentVersion;
  }

  function filterOverdueSummaries(invoiceSummaries) {
    const nowDate = nowDateOnly();
    return _.filter(invoiceSummaries, function (v) {
      return (v.currentVersion.status === 'FINAL' && from(v.currentVersion.dueDate) < nowDate);
    });
  }

  function isInvoiceSummaryOverdue(invoiceSummary) {
    const nowDate = nowDateOnly();
    return (invoiceSummary.currentVersion.status === 'FINAL' && from(invoiceSummary.currentVersion.dueDate) < nowDate);
  }

  function isInvoiceOverdue(invoiceSummary) {
    const nowDate = nowDateOnly();
    return (invoiceSummary.status === 'FINAL' && from(invoiceSummary.dueDate) < nowDate);
  }

  function getInvoiceIDByNumber(number) {
    var invoices = getInvoiceList();
    if (invoices) {
      var invoice = _.find(invoices, function (invoice) {
        return invoice.currentVersion.invoiceNumber === number;
      });

      if (invoice) {
        return invoice.invoiceId;
      }
    }
    return 0;
  }

  function getInvoicesByNumber (numbers) {
    const invoices = getAllInvoices();
    if (invoices) {
      return invoices.filter((invoice) => numbers.some((number) => number === invoice.invoiceNumber));
    }
  }

  function getOverdueInvoices () {
    const invoices = getAllInvoices() || [];
    return invoices.filter(isInvoiceOverdue);
  }

  // TODO fix up this code
  function filterInvoicesByAttorneys(invoices, attorneys){
    let nullIndex;

    if (attorneys === null || !_.isEmpty(attorneys)) {
      attorneys = _.isArray(attorneys) ? attorneys : [attorneys];

      nullIndex = _.indexOf(attorneys, 'null');

      if (nullIndex > -1) {
        attorneys[nullIndex] = null;
      }

      return  _.filter(invoices, (invoice) => {
        const matterDetail = sbMattersMbService.getById(invoice.matterId);
        return matterDetail && _.has(matterDetail, 'attorneyResponsibleId') &&  _.includes(attorneys, matterDetail.attorneyResponsibleId);
      });
    }

    return invoices;
  }

  function waiveInvoiceP(id, versionId, opdates) {
    return sbGenericEndpointService
      .postPayloadP(waiveInvoiceEndpoint, id, {versionId}, 'POST', {changeset: opdates})
      .catch((err) => {
        log.warn('error waiving invoice %s', id);
        log.warn(err);
        throw err;
      });
  }

  function reverseWaiveInvoiceP(id, versionId, opdates) {
    return sbGenericEndpointService
      .postPayloadP(reverseWaiveInvoiceEndpoint, id, {versionId}, 'POST', {changeset: opdates})
      .catch((err) => {
        log.warn('error reversing waived invoice %s', id);
        log.warn(err);
        throw err;
      });
  }
});
