import composeHooks from '@sb-itops/react-hooks-compose';
import { getLogger } from '@sb-itops/fe-logger';
import { useSubscribedQuery } from 'web/hooks';
import { MatterMemos } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useEffect, useState } from 'react';
import rtfToHTML from '@sb-billing/rtf-to-html';
import { cleanHtml } from '@sb-itops/html';
import { MemosWidget } from './MemosWidget';

const log = getLogger('MemosWidgetContainer');

const hooks = ({ matterId }) => ({
  useGraphQLforMemos: () => {
    const [memos, setMemos] = useState([]);
    const memoQueryResult = useSubscribedQuery(MatterMemos, {
      variables: {
        filter: {
          matterId,
        },
        limit: 20,
      },
    });

    useEffect(() => {
      async function convertMemos() {
        try {
          const result = await Promise.all(
            (memoQueryResult?.data?.memos || [])
              .filter((memo) => !memo.isDeleted)
              .map(
                (m) =>
                  new Promise((resolve) => {
                    rtfToHTML.fromString(m.text || '', (err, output) => {
                      const newValue = cleanHtml({ dirtyHtml: output, ensureAllowedTags: ['u', 'span'] });
                      resolve({ ...m, html: newValue });
                    });
                  }),
              ),
          );
          setMemos(result);
        } catch (error) {
          log.error(error);
        }
      }

      convertMemos();
    }, [memoQueryResult?.data?.memos]);

    return {
      memos,
      loading: memoQueryResult?.loading,
    };
  },
});

export const MemosWidgetContainer = withApolloClient(composeHooks(hooks)(MemosWidget));

MemosWidgetContainer.displayName = 'MemosWidget.container';
