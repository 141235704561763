import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { deleteTemplate, getDefaultTemplate } from '@sb-billing/redux/invoice-settings-template';
import { withReduxStore } from '@sb-itops/react';
import { ButtonGroupContainer } from 'web/react-redux/components/button-group';
import { getSelectedTemplateId, addNewTemplate } from 'web/redux/route/home-settings-invoice';

const mapStateToProps = (state, props) => {
  const mappedProps = {
    className: props.className,
    onDiscardCancel: props.onDiscardCancel,
    onDiscardConfirm: props.onDiscardConfirm,
    type: props.type,
    unsavedChanges: props.unsavedChanges,
    showDiscardDialog: props.showDiscardDialog,
    selectedButtonId: getSelectedTemplateId(),
    onDelete: (id) => {
      if (getSelectedTemplateId() === id) {
        props.onSelect(getDefaultTemplate().id);
      }
      deleteTemplate(id);
    },
    onSelect: props.onSelect,
    onEdit: (value) => {
      addNewTemplate({ id: props.generatedId, name: value });
    },
  };

  return mappedProps;
};

const InvoiceSettingsTemplateButtons = withReduxStore(connect(mapStateToProps)(ButtonGroupContainer));

InvoiceSettingsTemplateButtons.propTypes = {
  // Add new types when needed to this list
  generatedId: PropTypes.string,
  onDiscardCancel: PropTypes.func,
  onDiscardConfirm: PropTypes.func,
  showDiscardDialog: PropTypes.bool,
  type: PropTypes.oneOf(['invoiceTemplates']).isRequired,
  unsavedChanges: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

InvoiceSettingsTemplateButtons.defaultProps = {
  className: undefined,
  onDiscardCancel: () => {},
  onDiscardConfirm: () => {},
  showDiscardDialog: false,
  unsavedChanges: false,
};

InvoiceSettingsTemplateButtons.displayName = 'InvoiceSettingsTemplateButtons';

export default InvoiceSettingsTemplateButtons;
