'use strict';

// TODO: LM: this is effectively duplicated from '../../entities/constants.js.
// As the constants are specific to lawpay, the dependencies on the entity constants need to be removed (along with the constants from that file).
const paymentMethods = {
  CREDIT_CARD: 'CREDIT_CARD',
  ECHEQUE: 'ECHEQUE',
};

const paymentMethodNames = {
  CREDIT_CARD: 'Credit Card',
  ECHEQUE: 'eCheque',
};

const paymentMethodAccountSuffixes = {
  [paymentMethods.CREDIT_CARD]: '',
  [paymentMethods.ECHEQUE]: 'ECheque',
};

module.exports = {
  paymentMethods,
  paymentMethodNames,
  paymentMethodAccountSuffixes,
};
