import { asFormikField } from '../as-formik-field';
import ActivityDuration from './ActivityDuration';

const mapFormikToActivityDuration = ({ field, onChange, onBlur, interval, unitType, onUnitTypeChange }) => ({
  value: field.value ? +field.value.toFixed(5) : 0,
  onChange,
  onBlur,
  unitType,
  interval,
  onUnitTypeChange,
});

const ActivityDurationFormField = asFormikField(mapFormikToActivityDuration)(ActivityDuration);

ActivityDurationFormField.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  intervalInHours: 0,
  unitType: 'hrs',
  onUnitTypeChange: () => {},
};

export default ActivityDurationFormField;
