'use strict';

const { isValidIntegerDate } = require('@sb-itops/date');

/**
 *
 * @param {object} params
 * @param {number} params.yyyymmdd Integer date in format YYYYMMDD we want to compare
 * @param {object} params.bankReconciliationLatestCompleted Latest bank reconciliation for specific trust account
 * @param {object} params.bankReconciliationSetup Bank reconciliation setup for specific trust account
 * @returns Boolean
 */
const isDateReconciled = ({ yyyymmdd, bankReconciliationLatestCompleted, bankReconciliationSetup }) => {
  if (!isValidIntegerDate(yyyymmdd)) {
    return false;
  }

  /** Bank reconciliation */
  const lastCompletedReconDate = bankReconciliationLatestCompleted && bankReconciliationLatestCompleted.endDate;
  // recon period includes endDate so <= here
  const isBeforeLastCompletedReconDate = Number.isFinite(lastCompletedReconDate) && yyyymmdd <= lastCompletedReconDate;

  /** Bank reconciliation setup */
  const validBankReconSetup = bankReconciliationSetup && !bankReconciliationSetup.isRemoved;
  const reconSetupStartDate = bankReconciliationSetup && bankReconciliationSetup.reconciliationStartDate;
  // you can create transactions on the same day as the reconciliation start date (hence the '<' and not '<=')
  const isBeforeReconSetupStartDate =
    validBankReconSetup && Number.isFinite(reconSetupStartDate) && yyyymmdd < reconSetupStartDate;

  return isBeforeLastCompletedReconDate || isBeforeReconSetupStartDate;
};

module.exports = {
  isDateReconciled,
};
