angular.module('sb.billing.webapp').component('sbGenericOptionSelector', {
  templateUrl: 'ng-components/generic-option-selector/generic-option-selector.html',
  bindings: { options: '<', initialSelection: '<', onChange: '&' },
  controller: function (sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbGenericOptionSelector');

    // log.setLogLevel('info');

    ctrl.select = select;

    ctrl.$onInit = () => {
      log.info('options', ctrl.options);
      log.info('initialSelection', ctrl.initialSelection);

      select(makeInitialSelection(ctrl.initialSelection));
    };

    ctrl.$onChanges = () => {
      select(makeInitialSelection(ctrl.initialSelection));
    }

    function makeInitialSelection(selection) {
      if (!ctrl.options.length) {
        throw new Error('Cannot create sbGenericOptionSelector with no options');
      }

      if (_.isEmpty(selection)) {
        return ctrl.options[0];
      }

      const initialSelection = ctrl.options.find(option => option === selection || option.value === selection);
      log.info('initialSelection', initialSelection);
      return initialSelection;
    }

    function select(item) {
      ctrl.currentSelection = item;
      ctrl.onChange({selectedItem: item.value || item});
    }
  }
});
