import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { getLogger } from '@sb-itops/fe-logger';
import { fetchGetP } from '@sb-itops/redux/fetch';
import composeHooks from '@sb-itops/react-hooks-compose';

import { InvoiceStatementData } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { hasBillingAccess } from 'web/services/user-session-management';

import { BillingViewInvoiceStatementPdfRoute } from './BillingViewInvoiceStatementPdfRoute';

const log = getLogger('BillingViewInvoiceStatementPdfRoute.container');

const hooks = () => ({
  usePermissions: () => ({
    hasBillingAccess: hasBillingAccess(),
  }),
  useInvoiceStatementData: ({ invoiceStatementId, sbTabService }) => {
    const { data: invoiceStatementData, loading: invoiceStatementLoading } = useSubscribedQuery(InvoiceStatementData, {
      variables: {
        invoiceStatementId,
      },
    });

    const [previewUrl, setPreviewUrl] = useState(null);

    const fetchInvoiceStatementPdfSignedUrl = useCallback(async () => {
      try {
        const response = await fetchGetP({
          path: `/billing/invoice-statement-pdf/:accountId/${invoiceStatementId}/presigned-url/`,
        });

        setPreviewUrl(response.body?.preSignedUrl);
      } catch (error) {
        log.error('Error occurred while fetching invoice statement PDF preview', error);
      }
    }, [invoiceStatementId]);

    useEffect(() => {
      if (invoiceStatementData?.invoiceStatement?.id) {
        fetchInvoiceStatementPdfSignedUrl();
      }

      const unsub = subscribeToNotifications({
        notificationIds: ['InvoiceStatementPDFSyncNotification'],
        callback: (payloadString) => {
          const payload = JSON.parse(payloadString);
          if (payload.invoiceStatementId === invoiceStatementId) {
            fetchInvoiceStatementPdfSignedUrl();
          }
        },
      });
      return unsub;
    }, [fetchInvoiceStatementPdfSignedUrl, invoiceStatementData?.invoiceStatement?.id, invoiceStatementId]);

    const {
      number: invoiceStatementNumber,
      debtor,
      invoices,
      status: invoiceStatementStatus,
      lastUpdated: invoiceStatementLastUpdated,
    } = invoiceStatementData?.invoiceStatement || {};

    useEffect(() => {
      if (invoiceStatementNumber || debtor?.displayNameFirstLast) {
        sbTabService.updateTabInfo(
          { type: 'invoice-statement', id: invoiceStatementId },
          {
            primaryLabel: `Statement #${invoiceStatementNumber || ''}`,
            secondaryLabel: debtor?.displayNameFirstLast,
          },
        );
      }
    }, [debtor?.displayNameFirstLast, invoiceStatementId, invoiceStatementNumber, sbTabService]);

    return {
      invoiceStatementLoading,
      invoiceStatementNumber,
      debtor,
      invoices,
      previewUrl,
      invoiceStatementStatus,
      invoiceStatementLastUpdated,
    };
  },
  usePageNavItems: () => ({
    pageNavItems: [
      {
        path: 'invoiceStatementView',
        display: 'View',
        className: 'dash-active',
        order: 1,
      },
      {
        path: 'invoiceStatementPayments',
        display: 'Payments',
        order: 2,
      },
    ],
  }),
});

export const BillingViewInvoiceStatementPdfRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(BillingViewInvoiceStatementPdfRoute)),
);

BillingViewInvoiceStatementPdfRouteContainer.displayName = 'BillingViewInvoiceStatementPdfRouteContainer';

BillingViewInvoiceStatementPdfRouteContainer.propTypes = {
  invoiceStatementId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  sbTabService: PropTypes.object.isRequired,
};

BillingViewInvoiceStatementPdfRouteContainer.defaultProps = {};
