import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ShowDeletedTransactionsToggle from './ShowDeletedTransactionsToggle';

const ShowDeletedTransactionsToggleBridge = memo(({ label, selected, $state, className }) => {
  const onSelect = (id, value) => {
    $state.go($state.current, { showHidden: value }, {});
  };

  return <ShowDeletedTransactionsToggle className={className} label={label} selected={selected} onSelect={onSelect} />;
});

ShowDeletedTransactionsToggleBridge.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  $state: PropTypes.any.isRequired, // this is passed implicitly through react-2-angular
  className: PropTypes.string,
};

ShowDeletedTransactionsToggleBridge.defaultProps = {
  label: undefined,
  className: undefined,
};

export default ShowDeletedTransactionsToggleBridge;
