import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BankReconciliationSummaryPanel } from '../../components/bank-reconciliation-summary-panel';
import { CreateBankReconciliation } from '../../components/bank-reconciliation';
import { BankReconciliationAdjustments } from '../../components/bank-reconciliation-adjustments';

import Styles from './BillingCreateBankReconcilationRoute.module.scss';

export const BANK_RECON_TABS = {
  reconciliation: 'reconciliation',
  adjustments: 'adjustments',
};
const tabsListOptions = [
  { text: 'Payments and Receipts', id: BANK_RECON_TABS.reconciliation },
  { text: 'Adjustments', id: BANK_RECON_TABS.adjustments },
];

export const BillingCreateBankReconcilationRoute = memo(
  ({
    trustAccountId,
    trustAccountName,
    selectedTab,
    onChangeSelectedTab,
    transactions,
    depositSlips,
    consolidatedTransactions,
    summaryData,
    finalisingRecon,
    onClickLink,
    onSave,
    onRecon,
    onCancel,
    onValidateFinalise,
    validateFn,
    getConsolidatedTransactionById,
    setTriggerValidate,
    formScope,
    onTransactionClick,
    ianaTimezone,
  }) => (
    <div className={Styles.pageContainer}>
      <h1 className="page-title">Bank Reconciliation: {trustAccountName}</h1>

      <div className="sb-tab-header">
        <ul className="nav nav-pills">
          {tabsListOptions.map((tab) => (
            <li className={classnames({ active: tab.id === selectedTab })} key={tab.id}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => onChangeSelectedTab(tab.id)}>{tab.text}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="master-detail-panel">
        <div className={classnames('panel-body', Styles.contentContainer)}>
          {selectedTab === BANK_RECON_TABS.reconciliation && (
            <CreateBankReconciliation
              trustAccountId={trustAccountId}
              transactions={transactions}
              consolidatedTransactions={consolidatedTransactions}
              depositSlips={depositSlips}
              onRowClick={onTransactionClick}
              onClickLink={onClickLink}
              summaryData={summaryData}
              scope={formScope}
              validateForm={() => setTriggerValidate(true)}
            />
          )}
          {selectedTab === BANK_RECON_TABS.adjustments && (
            <BankReconciliationAdjustments onUpdate={() => setTriggerValidate(true)} trustAccountId={trustAccountId} />
          )}
        </div>
        <div className={Styles.summaryContainer}>
          <BankReconciliationSummaryPanel
            trustAccountId={trustAccountId}
            scope={formScope}
            onCancel={onCancel}
            onRecon={onRecon}
            onSave={onSave}
            onValidateFinalise={onValidateFinalise}
            summaryData={summaryData}
            validateFn={validateFn}
            finalisingRecon={finalisingRecon}
            getConsolidatedTransactionById={getConsolidatedTransactionById}
            ianaTimezone={ianaTimezone}
          />
        </div>
      </div>
    </div>
  ),
);

BillingCreateBankReconcilationRoute.propTypes = {
  trustAccountId: PropTypes.string.isRequired,
  trustAccountName: PropTypes.string.isRequired,
  formScope: PropTypes.string.isRequired,
  finalisingRecon: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired,
  ianaTimezone: PropTypes.string.isRequired,
  onChangeSelectedTab: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  consolidatedTransactions: PropTypes.array.isRequired,
  depositSlips: PropTypes.array.isRequired,
  summaryData: PropTypes.shape({
    openingCashBookBalance: PropTypes.number.isRequired,
    addReceipts: PropTypes.number.isRequired,
    lessPayments: PropTypes.number.isRequired,
    ledgerBalance: PropTypes.number.isRequired,
    addUnbankedReceipts: PropTypes.number.isRequired,
    lessUnpresentedPayments: PropTypes.number.isRequired,
  }).isRequired,
  getConsolidatedTransactionById: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTransactionClick: PropTypes.func.isRequired,
  onValidateFinalise: PropTypes.func.isRequired,
  onRecon: PropTypes.func.isRequired,
  setTriggerValidate: PropTypes.func.isRequired,
  validateFn: PropTypes.func.isRequired,
};
