/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, ContextMenu } from '@sb-itops/react';
import classNames from 'classnames';
import tilesIcon from 'web/assets/icon-tiles.png';
import { isNewTheme } from 'web/services/theme';
import * as Styles from './Menu.module.scss';
import { DeleteDashboardConfirmationModal } from './DeleteDashboardConfirmationModal';

interface IDashboard {
  id: string;
  name: string;
  edit: boolean;
}

export interface IMenu {
  isEditing: boolean;
  dashboards: IDashboard[];
  setEditState: Function;
  onAddWidget: Function;
  onAddDashboard: Function;
  setCurrentDashboard: Function;
  currentDashboard: string;
  setDashboards: Function;
  onExit: React.MouseEventHandler;
  setShowDeleteDashboardModal: Function;
  showDeleteDashboardModal: string | false;
}

export const Menu = ({
  isEditing,
  dashboards,
  setEditState,
  onAddWidget,
  onAddDashboard,
  setCurrentDashboard,
  currentDashboard,
  setDashboards,
  onExit,
  setShowDeleteDashboardModal,
  showDeleteDashboardModal,
}: IMenu) => (
  <div className={Styles.menu}>
    <div className={Styles.dashboards}>
      <div className="sb-tab-header">
        <ul className="nav nav-pills nav-tabs-align">
          {Object.values(dashboards).map((dashboard) => (
            <li
              key={dashboard.id}
              onClick={() => setCurrentDashboard(dashboard.id)}
              className={currentDashboard === dashboard.id ? 'active' : ''}
            >
              {dashboard.edit ? (
                <input
                  className={Styles.input}
                  value={(dashboard.name || '').toUpperCase()}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  onBlur={() => {
                    setDashboards({
                      ...dashboards,
                      [dashboard.id]: {
                        ...dashboard,
                        edit: false,
                      },
                    });
                  }}
                  onChange={(e) => {
                    setDashboards({
                      ...dashboards,
                      [dashboard.id]: {
                        ...dashboard,
                        name: e.target.value,
                      },
                    });
                  }}
                />
              ) : (
                <a className={Styles.menuItem}>
                  {dashboard.name}
                  {isEditing && (
                    <ContextMenu
                      distance={5}
                      position="top-right"
                      arrow={false}
                      className={Styles.dropdown}
                      render={({ open }) =>
                        open ? <div className="icon icon-arrow-38" /> : <div className="icon icon-arrow-37" />
                      }
                      // eslint-disable-next-line react/no-unstable-nested-components
                      body={({ close }) => (
                        <div className="create-bill-context-menu-body list-group">
                          <div
                            className="list-group-item"
                            onClick={() => {
                              setDashboards({
                                ...dashboards,
                                [dashboard.id]: {
                                  ...dashboard,
                                  edit: true,
                                },
                              });
                              close();
                            }}
                          >
                            Rename
                          </div>
                          {dashboard.id !== 'default' && (
                            <div
                              className="list-group-item"
                              onClick={() => {
                                setShowDeleteDashboardModal(dashboard.id);
                                close();
                              }}
                            >
                              Delete
                            </div>
                          )}
                        </div>
                      )}
                    />
                  )}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
    {isEditing && (
      <div className={Styles.editing}>
        <Button className={Styles.button} onClick={() => onAddWidget()}>
          Add Widget <img className={Styles.tilesIcon} alt="Tiles icon" src={tilesIcon} />
        </Button>
        <Button className={Styles.button} type={isNewTheme() ? 'tertiary' : 'info'} onClick={() => onAddDashboard()}>
          Add Dashboard <i className={classNames(Styles.houseIcon, 'icon icon-house-2')} />
        </Button>
        <button type="button" className="close-icon" onClick={onExit} />
      </div>
    )}
    {!isEditing && (
      <div onClick={() => setEditState(true)}>
        <i className={classNames(Styles.customize, 'icon icon-pencil-2')} />
      </div>
    )}
    {!!showDeleteDashboardModal && (
      <DeleteDashboardConfirmationModal
        onClose={() => setShowDeleteDashboardModal(false)}
        onConfirm={() => {
          const newDashboards = { ...dashboards };
          delete newDashboards[showDeleteDashboardModal];
          setDashboards(newDashboards);
          setCurrentDashboard('default');
          setShowDeleteDashboardModal(false);
        }}
      />
    )}
  </div>
);
