import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import { Cent, Dollar } from '@sb-itops/money';

const handleFocus = ({ target }) => {
  target.select();
};
const CurrencyInput = (props) => {
  const {
    hideDollar,
    className,
    max,
    min,
    onChange,
    value,
    hasError,
    disabled,
    name,
    onBlur,
    placeholder,
    step,
    focusKey,
    label, // right hand side label
  } = props;

  const changeHandler = (handler, type) => (e) => {
    // convert the dollar amount to cents
    const amount = parseFloat(e.target.value);
    // We prefer to default to an empty box rather than 0 as the default
    let cents = Number.isFinite(amount) ? new Dollar(amount).cents() : undefined;

    // Min or max can be undefined as any checks with an undefined argument resolve to false
    if (cents < min || cents > max) {
      cents = cents < min ? min : max;
    }

    if (type === 'onBlur') {
      setFormattedAmount(cents !== undefined && cents !== null ? (cents / 100).toFixed(2) : ''); // using cents here to round up to 2 decimal, cents is already the round up value.
      isUserTyping.current = false;
    } else {
      setFormattedAmount(Number.isFinite(amount) ? amount : ''); // using amount here to allow user freely typing without formatting
      isUserTyping.current = true;
    }

    // Maintain interface with old currency component
    handler({
      ...e,
      target: {
        name,
        value: cents,
      },
      currentTarget: {
        name,
        value: cents,
      },
    });
  };

  const handleChange = changeHandler(onChange, 'onChange');
  const handleBlur = changeHandler(onBlur, 'onBlur');

  // Removes the ability to change amounts using the keyboard up and down keys
  const handleKeyDown = (e) => {
    const key = e.charCode || e.keyCode;
    // Disable Up and Down Arrows on Keyboard
    if (key === 38 || key === 40) {
      e.preventDefault();
    }
  };

  // Removes the ability to change amounts using the scroll wheel
  const handleMouseWheel = (e) => {
    e.target.blur();
  };

  const groupClassName = classnames(
    'input-group',
    'currency-input',
    hasError && 'has-error',
    disabled && 'with-disabled',
    className,
  );
  // This tenary is not strictly required, but it prevents react thinking our component is uncontrolled
  const amount = Number.isFinite(value) ? new Cent(value).dollars() : '';
  const maxDollars = Number.isFinite(max) ? new Cent(max).toString() : undefined;
  const minDollars = Number.isFinite(min) ? new Cent(min).toString() : undefined;
  const [formattedAmount, setFormattedAmount] = useState(amount);
  const isUserTyping = useRef(false);

  useEffect(() => {
    // When the amount is changed but not due to user typing, format it with 2 decimal
    if (amount !== '' && !isUserTyping.current) {
      setFormattedAmount(amount.toFixed(2));
    }
    // When the amount is set to '', reset the formatted amount as well
    if (amount === '') {
      setFormattedAmount('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <div className={groupClassName}>
      {!hideDollar && (
        <div className="input-group-addon">
          <i className={classnames('fa-currency-icon', 'fa', 'fa-icon')} />
        </div>
      )}
      <input
        name={name}
        type="number"
        max={maxDollars}
        min={minDollars}
        step={step || 0.01}
        onFocus={handleFocus}
        className={classnames('form-control', 'currency')}
        value={formattedAmount}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        onWheel={handleMouseWheel}
        onKeyDown={handleKeyDown}
        // Used in angular controllers for focusing components
        focus-key={focusKey}
      />
      {label && <div className="input-group-addon">{label}</div>}
    </div>
  );
};

CurrencyInput.displayName = 'CurrencyInput';

CurrencyInput.propTypes = {
  hideDollar: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  max: PropTypes.number, // cents
  min: PropTypes.number, // cents
  step: PropTypes.number, // cents
  placeholder: PropTypes.string,
  focusKey: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.number, // cents
  hasError: PropTypes.bool,
  label: PropTypes.string, // label text on the right hand side of currency input
};

CurrencyInput.defaultProps = {
  hideDollar: false,
  disabled: false,
  className: undefined,
  focusKey: undefined,
  name: undefined,
  max: undefined,
  min: undefined,
  step: undefined,
  placeholder: undefined,
  onBlur: noop,
  onChange: noop,
  value: undefined,
  hasError: false,
  label: undefined,
};

export default CurrencyInput;
