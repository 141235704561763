import { fetchPostP } from '@sb-itops/redux/fetch';

export const uploadSignatureFile = async ({ accountId, templateId, signatureImage, fileExtension }) => {
  const path = `/billing/invoice-pdf-signature/upload/${accountId}/${templateId}`;
  const options = { signatureImage, fileExtension };
  const fetchOptions = { dataType: 'json', body: JSON.stringify(options) };
  // Uploading signature file
  const response = await fetchPostP({ path, fetchOptions });
  const { preSignedUrl } = response.body;
  return preSignedUrl;
};
