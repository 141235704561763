/**
 * This component is intended to be used as a render prop component, eg:
 * <FilterExpandCollapse scope={matterFilterScope}>
 *   {({ hiddenFilters, onToggleFilterVisibility }) => (
 *     ...
 *   )}
 * </FilterExpandCollapse>
 */
import composeHooks from '@sb-itops/react-hooks-compose';
import { useExpandCollapse } from '@sb-itops/redux/expand-collapse/use-expand-collapse';

const hooks = ({ scope }: { scope: string; children: any; className?: string }) => ({
  useToggleFilters: () => {
    const { collapsedItemsMap: hiddenFilters, toggleItem } = useExpandCollapse({ scope });

    const onToggleFilterVisibility = (val) => {
      if (typeof val === 'string') {
        return toggleItem(val);
      }

      // CollapsibleHeader component returns a button click event
      return toggleItem(val.currentTarget.id || val.currentTarget.name);
    };

    return { hiddenFilters, onToggleFilterVisibility };
  },
});

export const FilterExpandCollapse = composeHooks(hooks)((props) => props.children(props));
