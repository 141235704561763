import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as dateRangeSelect from './index';

export const useDateRangeSelect = ({ scope }) => {
  const dispatch = useDispatch();
  const { selectors, actions } = useScopedFeature(dateRangeSelect, scope);

  const fromDate = useSelector(selectors.getFromDate);
  const toDate = useSelector(selectors.getToDate);
  const selectedDateRangeType = useSelector(selectors.getSelectedDateRangeType);

  const selectDateRangeType = ({ dateRangeType }) => {
    dispatch(actions.selectDateRangeType({ dateRangeType }));
  };
  const selectFromDate = ({ from }) => dispatch(actions.selectFromDate({ fromDate: from }));
  const selectToDate = ({ to }) => dispatch(actions.selectToDate({ toDate: to }));

  return {
    fromDate,
    toDate,
    dateRangeType: selectedDateRangeType,
    selectDateRangeType,
    selectFromDate,
    selectToDate,
  };
};
