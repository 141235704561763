'use strict';

const interestOnInvoicesNote = '\n* Interest income is not included';
const surchargeNote = '\n* This report excludes surcharge totals';
const getDefaultNotes = (t) => `Notes:
* Partial payments for an invoice are applied pro-rata across fees and ${t('expenses')}
* Waived amounts simply leave invoices partially paid (which then follows the above rule)
* Discounts are applied to fees only`;

function getIncomeAllocationReportFooter(props) {
  const { interestOnInvoicesEnabled, surchargeEnabled, t } = props || {};

  let notes = getDefaultNotes(t);

  if (interestOnInvoicesEnabled) {
    notes = notes.concat(interestOnInvoicesNote);
  }

  if (surchargeEnabled) {
    notes = notes.concat(surchargeNote);
  }

  return notes;
}

module.exports = {
  getDefaultNotes,
  interestOnInvoicesNote,
  surchargeNote,
  getIncomeAllocationReportFooter,
};
