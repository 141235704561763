import { useState } from 'react';
import PropTypes from 'prop-types';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { todayAsInteger, integerToDate } from '@sb-itops/date';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { getBankAccountName, isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { sortByOrder, capitalize } from '@sb-itops/nodash';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import { featureActive } from '@sb-itops/feature';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { getById as getEntityBankDetailsById } from '@sb-billing/redux/entity-bank-details';
import * as forms from '@sb-itops/redux/forms2';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { store } from '@sb-itops/redux';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { matterHasProtectedFundsForBankAccountId } from '@sb-billing/redux/balance-protection';
import {
  isMatterContactBalanceType,
  getSettings as getBankAccountSettings,
} from '@sb-billing/redux/bank-account-settings';
import {
  getNumberingSettings as getTransactionNumberingSettings,
  getById as getBankAccountById,
  isControlledMoniesAccount,
  getTrustAccounts,
} from '@sb-billing/redux/bank-account';
import {
  getDefaultPaymentSource,
  getPaymentSources as getPaymentSourcesFromRedux,
} from 'web/redux/selectors/payment-source';
import {
  getByBankAccountId as getTrustChequesByBankAccountId,
  chequeExistsForBankAccount,
} from '@sb-billing/redux/trust-cheques';
import {
  printMethods as printMethodsList,
  printMethodsByValue,
  PrintNow,
  PrintManually,
} from '@sb-billing/business-logic/cheques';
import { getMatterTrustBalanceByDate } from '@sb-billing/redux/transactions';
import { findLastChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import { getContactTypeAheadSummaries, getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { getSources, getBankTransferSources, getDefaultSource } from 'web/redux/selectors/payment-deposit-source';
import { getSettings as getCMASettings } from '@sb-billing/redux/controlled-money-account-settings';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getMap as getBankAccountBalancesState } from '@sb-billing/redux/bank-account-balances';
import Big from 'big.js';
import uuid from '@sb-itops/uuid';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { VendorPaymentEntrySplitPayorsSchema } from './VendorPaymentEntrySplitPayorsForm.yup';
import { VendorPaymentEntrySplitPayorsForm } from './VendorPaymentEntrySplitPayorsForm';

const { getMatterBalance } = selectors;

const accountTypeToSourceName = {
  TRUST: 'trustPaymentSource',
  CONTROLLEDMONEY: 'cmaPaymentSource',
  OPERATING: 'paymentDepositSource',
};

const hooks = () => ({
  useSelectors: ({
    matterId: defaultMatterId,
    bankAccountId: defaultBankAccountId,
    scope,
    onBankAccountIdChange,
    onMatterIdChange,
  }) => {
    const { t } = useTranslation();
    const [showAddContactForm, setShowAddContactForm] = useState(false);

    const {
      formFields,
      formValues,
      formInitialised,
      formSubmitting,
      submitFailed,
      onInitialiseForm,
      onClearForm,
      onUpdateFields,
      onFieldValueSet,
      onValidateForm,
    } = useForm({ scope, schema: VendorPaymentEntrySplitPayorsSchema });

    const {
      effectiveDate,
      matterId,
      bankAccountId,
      paidToId,
      amount,

      reference,
      orgCompanyName,
      bankAccountName,
      billerCode,
      bpayReference,
      bankBranchNumber,
      bankAccountNumber,
      chequePayableToBank,
      payeeName,
      bankName,
      bankBranch,
      chequeMemo,
      reason,
      note,
      generatePaymentPdfNow,
      generateOperatingPaymentPdfNow,
    } = formFields;

    const { bankAccountType, referenceReadOnly, sourceTypeOptions, transferTypeOptions, printMethodOptions } =
      formValues;

    // facets
    const supportsChequeMemo = hasFacet(facets.chequeMemo);
    const supportsReasonField = hasFacet(facets.reasonField);
    const supportsElectronicPayment = hasFacet(facets.electronicPayment);
    const supportsTrustPaymentBankTransferType = hasFacet(facets.trustPaymentBankTransferType);
    const supportsBPAY = hasFacet(facets.BPAY);
    const supportsCMA = hasFacet(facets.CMA);
    const supportsMatterTrustBalanceByDate = hasFacet(facets.matterTrustBalanceByDate);
    const supportsAllowOverdraw = hasFacet(facets.allowOverdraw);
    const matterFieldSeparateRow = hasFacet(facets.matterFieldSeparateRow);
    const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);

    const onUpdateFieldValues = (fieldValues) => {
      onUpdateFields(fieldValues);
      validateForm({
        scope,
        t,
        onValidateForm,
        supportsElectronicPayment,
        supportsReasonField,
        supportsTrustPaymentBankTransferType,
        supportsAllowOverdraw,
        supportsBPAY,
      });
    };
    const onSetFieldValue = (field, value) => {
      onFieldValueSet(field, value);
      validateForm({
        scope,
        t,
        onValidateForm,
        supportsElectronicPayment,
        supportsReasonField,
        supportsTrustPaymentBankTransferType,
        supportsAllowOverdraw,
        supportsBPAY,
      });
    };

    const bankAccountOptions = getBankAccountOptions({ matterId: matterId?.value, t });

    const balance = formInitialised
      ? calculateBalance({
          amount: formValues.amount,
          matterId: formValues.matterId,
          bankAccountId: formValues.bankAccountId,
          effectiveDate: formValues.effectiveDate,
          availableBalance: getAvailableBalance({
            matterId: formValues.matterId,
            bankAccountId: formValues.bankAccountId,
          }),
          bankAccountType,
          supportsCMA,
          supportsMatterTrustBalanceByDate,
        })
      : 0;

    const onPaymentSourceChange = (option) => {
      let newPaymentSource;
      if (option) {
        newPaymentSource = option;
        onUpdateFieldValues({ amount: 0 });
      } else {
        newPaymentSource = undefined;
      }
      onSetFieldValue('paymentSource', newPaymentSource);
    };

    const resetPaymentSources = ({
      matterId: _matterId,
      bankAccountId: _bankAccountId,
      bankAccountType: _bankAccountType,
    }) => {
      const printMethodDefault = (getTrustChequePrintSettingsById(_bankAccountId) || {}).printMethod;
      const printingMethodDefault = printMethodsByValue[printMethodDefault]?.value || printMethodsList?.[2]?.value;
      onUpdateFieldValues({
        printingMethod: printingMethodDefault,
      });
      const defaultPaymentSource = getDefaultPaymentSource(
        getPaymentSources({
          accountType: _bankAccountType,
          matterId: _matterId,
          bankAccountId: _bankAccountId,
          t,
          allowOverdraw: supportsAllowOverdraw,
        }),
      );
      if (_matterId && _bankAccountId) {
        onPaymentSourceChange(defaultPaymentSource);
      } else {
        onPaymentSourceChange();
      }
    };

    const onPayorsUpdate = (payors) => {
      const totalAmount = Object.values(payors).reduce(
        (acc, payorAmount) => acc + (Number.isFinite(payorAmount) ? payorAmount : 0),
        0,
      );
      if (totalAmount !== formValues.amount) {
        onUpdateFieldValues({ amount: totalAmount });
      }
      onSetFieldValue('payors', payors);
    };

    const onBankAccountChange = (bankAccountOption) => {
      const newBankAccountId = bankAccountOption?.value?.id;
      onUpdateFieldValues({ bankAccountId: newBankAccountId });
      resetPaymentSources({
        matterId: formValues.matterId,
        bankAccountId: newBankAccountId,
        bankAccountType,
      });
      onBankAccountIdChange(newBankAccountId); // propagate to parent
    };

    const onMatterChange = (newMatterId) => {
      const bankAccountOptionsUpdated = getBankAccountOptions({ matterId: newMatterId, t });

      // reset bank account if it is trust:
      // - Matter is not selected (is cleared)
      // - OR Currently selected bank account is not in bank accounts for this matter
      if (
        bankAccountType === 'TRUST' &&
        (!newMatterId ||
          (newMatterId &&
            formValues.bankAccountId &&
            bankAccountOptionsUpdated?.length &&
            !bankAccountOptionsUpdated.find((acc) => acc?.value?.id === formValues.bankAccountId)))
      ) {
        onUpdateFieldValues({ bankAccountId: undefined });
      }

      onUpdateFieldValues({ matterId: newMatterId });
      resetPaymentSources({ matterId: newMatterId, bankAccountId: formValues.bankAccountId, bankAccountType });
      onMatterIdChange(newMatterId); // propagate to parent
    };

    const onChangePrintingMethod = ({
      transferType: newTransferType,
      printingMethod: newPrintingMethod,
      sourceType: newSourceType,
    }) => {
      const fieldVals = { transferType: newTransferType, printingMethod: newPrintingMethod, sourceType: newSourceType };

      fieldVals.referenceReadOnly = isReferenceReadOnly({
        bankAccountId: formValues.bankAccountId,
        bankAccountType,
        supportsElectronicPayment,
        sourceType: newSourceType,
        printingMethod: newPrintingMethod,
      });

      fieldVals.reference =
        fieldVals.referenceReadOnly ||
        !shouldGenerateReference({
          bankAccountId: formValues.bankAccountId,
          bankAccountType,
          sourceType: newSourceType,
          printingMethod: newPrintingMethod,
        })
          ? undefined
          : getNextChequeNumber(getTrustChequesByBankAccountId(formValues.paymentSource?.bankAccountId)) || '';

      const paidToRelatedFields = getDefaultPaidToRelatedFields({
        supportsElectronicPayment,
        paidToId: formValues.paidToId,
        sourceType: newSourceType,
        showBpay: shouldShowBpay({
          sourceType: newSourceType,
          transferType: newTransferType,
          bankAccountType,
          supportsBPAY,
          supportsTrustPaymentBankTransferType,
          supportsElectronicPayment,
        }),
        directDebitIsActive: isDirectDebitActive({ sourceType: newSourceType }),
      });

      onUpdateFieldValues({ ...fieldVals, ...paidToRelatedFields });
    };

    const onSelectPaidTo = (newContactId) => {
      const paidToRelatedFields = getDefaultPaidToRelatedFields({
        supportsElectronicPayment,
        paidToId: newContactId,
        sourceType: formValues.sourceType,
        showBpay: shouldShowBpay({
          sourceType: formValues.sourceType,
          transferType: formValues.transferType,
          bankAccountType,
          supportsBPAY,
          supportsTrustPaymentBankTransferType,
          supportsElectronicPayment,
        }),
        directDebitIsActive: isDirectDebitActive({ sourceType: formValues.sourceType }),
      });

      onUpdateFieldValues({ paidToId: newContactId, ...paidToRelatedFields });
    };

    const isChequeMemoVisible = formValues.printingMethod === PrintNow || formValues.printingMethod === PrintManually;
    const matterIsStatutoryDepositMatter = isStatutoryDepositMatter({
      matterId: formValues.matterId,
      trustBankAccounts: getTrustAccounts(),
      supportsStatutoryDepositMatter,
    });

    let minPaymentDate;
    if (formValues.bankAccountId && isControlledMoniesAccount(bankAccountType)) {
      const cmaBankAccount = getBankAccountById(formValues.bankAccountId);
      minPaymentDate = integerToDate(cmaBankAccount.accountOpenedDate);
    }

    const trustChequeIsEnabled = isTrustChequesEnabled({ bankAccountId: formValues.bankAccountId, bankAccountType });
    const paymentTypeIsCheque = !!formValues.sourceType?.endsWith('Check');

    const isTrustPayment = bankAccountType === 'TRUST';
    // We show reason field in US for trust payment form and it's optional
    const isShowPaymentReason =
      hasFacet(facets.reasonField) || (hasFacet(facets.trustPaymentReasonField) && isTrustPayment);

    return {
      // form
      formDisabled: formSubmitting,
      submitFailed,
      formInitialised,
      onUpdateFieldValues,
      // form fields
      effectiveDate,
      matterId,
      bankAccountId,
      bankAccountType, // separate as we may get into situation where there is no bank account but we still need to know the type of last one to correctly keep UI displayed
      paidToId,
      amount,

      reference,
      referenceReadOnly,
      orgCompanyName,
      bankAccountName,
      billerCode,
      bpayReference,
      bankBranchNumber,
      bankAccountNumber,
      chequePayableToBank,
      payeeName,
      bankName,
      bankBranch,
      chequeMemo,
      reason,
      note,
      sourceType: formValues.sourceType,
      paymentSource: formValues.paymentSource,
      generatePaymentPdfNow,
      generateOperatingPaymentPdfNow,
      transferType: formValues.transferType,
      printingMethod: formValues.printingMethod,
      // form related
      balance,
      minPaymentDate,
      readOnlyReference: referenceReadOnly,
      contactOptions: getContactTypeAheadSummaries(),
      matterOptions: getMatterTypeaheadSummaries(),
      sourceTypeOptions,
      transferTypeOptions,
      bankAccountOptions,
      paymentSources: formInitialised
        ? getPaymentSources({
            bankAccountType,
            matterId: formValues.matterId,
            bankAccountId: formValues.bankAccountId,
            t,
            supportsAllowOverdraw,
          })
        : [],
      printMethodOptions,
      defaultMatterId,
      shouldDisablePdfPrint: trustChequeIsEnabled && paymentTypeIsCheque && formValues.printingMethod === PrintNow,
      isShowPaymentReason,
      // inline contact
      showAddContactForm,
      setShowAddContactForm,
      // config & ui
      matterFieldSeparateRow,
      isContactMatterBalance: isMatterContactBalanceType(),
      showBpay: shouldShowBpay({
        sourceType: formValues.sourceType,
        transferType: formValues.transferType,
        bankAccountType,
        supportsBPAY,
        supportsTrustPaymentBankTransferType,
        supportsElectronicPayment,
      }),
      showBankDetails: shouldShowBankDetails({
        sourceType: formValues.sourceType,
        bankAccountType,
        supportsElectronicPayment,
      }),
      showTransferType: shouldShowTransferType({
        sourceType: formValues.sourceType,
        bankAccountType,
        supportsTrustPaymentBankTransferType,
        supportsElectronicPayment,
      }),
      isChequeMemoVisible,
      isTrustChequesEnabled: trustChequeIsEnabled,
      isChequePaymentType: paymentTypeIsCheque,
      isStatutoryDepositMatter: matterIsStatutoryDepositMatter,
      // facets
      supportsChequeMemo,
      supportsReasonField,
      supportsElectronicPayment,
      supportsTrustPaymentBankTransferType,
      // callbacks
      onPayorsUpdate,
      onBankAccountChange,
      onMatterChange,
      onChangePrintingMethod,
      onSelectPaidTo,
      onPaymentSourceChange,

      onLoad: () => {
        const ba = getBankAccountById(defaultBankAccountId) || {};
        const accountTypeDefault = ba.accountType;

        const cmaSettings = getCMASettings() || {};
        const bankAccountSettings = getBankAccountSettings() || {};

        const generatePaymentPdfNowDefault =
          accountTypeDefault === 'TRUST'
            ? bankAccountSettings.createPDFReceiptOnTrustPayment
            : cmaSettings.createPDFReceiptOnPayment;
        const generateOperatingPaymentPdfNowDefault = bankAccountSettings.createPDFReceiptOnOperatingPayment;

        // We can do this on init as we can't select bank account of different accountType once modal opened
        const sourceTypeOptionsDefault = getSources(accountTypeToSourceName[accountTypeDefault]);
        const transferTypeOptionsDefault = getBankTransferSources(accountTypeToSourceName[accountTypeDefault]);
        const sourceTypeDefault = getDefaultSource(sourceTypeOptionsDefault) || sourceTypeOptionsDefault?.[0];

        const printMethodOptionsDefault = printMethodsList.map((pm) => ({ label: pm.display, value: pm.value }));

        const printMethodDefault = (getTrustChequePrintSettingsById(ba.id) || {}).printMethod;
        const printingMethodDefault = printMethodsByValue[printMethodDefault]?.value || printMethodsList?.[2]?.value;

        const defaultPaymentSource = getDefaultPaymentSource(
          getPaymentSources({
            accountType: accountTypeDefault,
            matterId: defaultMatterId,
            bankAccountId: ba.id,
            t,
            allowOverdraw: supportsAllowOverdraw,
          }),
        );

        let referenceDefault;
        const referenceReadOnlyDefault = isReferenceReadOnly({
          bankAccountId: ba.id,
          bankAccountType: accountTypeDefault,
          supportsElectronicPayment,
          sourceType: sourceTypeDefault?.value,
          printingMethod: printMethodDefault,
        });

        if (!referenceReadOnlyDefault) {
          referenceDefault = shouldGenerateReference({
            bankAccountId: ba.id,
            bankAccountType: accountTypeDefault,
            sourceType: sourceTypeDefault?.value,
            printingMethod: printMethodDefault,
          })
            ? getNextChequeNumber(getTrustChequesByBankAccountId(ba?.id))
            : undefined;
        }

        onInitialiseForm({
          effectiveDate: todayAsInteger(),
          matterId: defaultMatterId,
          bankAccountId: ba.id,
          bankAccountType: accountTypeDefault,
          paidToId: undefined,
          amount: 0,
          reference: referenceDefault,
          referenceReadOnly: referenceReadOnlyDefault,
          orgCompanyName: undefined,
          bankAccountName: undefined,
          billerCode: undefined,
          bpayReference: undefined,
          bankBranchNumber: undefined,
          bankAccountNumber: undefined,
          chequePayableToBank: undefined,
          payeeName: undefined,
          bankName: undefined,
          bankBranch: undefined,
          chequeMemo: undefined,
          reason: undefined,
          note: undefined,
          generatePaymentPdfNow: generatePaymentPdfNowDefault,
          generateOperatingPaymentPdfNow: generateOperatingPaymentPdfNowDefault,
          sourceTypeOptions: sourceTypeOptionsDefault,
          transferTypeOptions: transferTypeOptionsDefault,
          printMethodOptions: printMethodOptionsDefault,
          transferType: transferTypeOptionsDefault?.[0]?.value,
          printingMethod: printingMethodDefault,
          sourceType: sourceTypeDefault?.value,
          paymentSource: defaultPaymentSource,
        });

        return onClearForm;
      },
    };
  },
});

const isDirectDebitActive = ({ sourceType }) => featureActive('BB-5948') && sourceType === 'Direct Debit';

const shouldShowTransferType = ({
  sourceType,
  bankAccountType,
  supportsTrustPaymentBankTransferType,
  supportsElectronicPayment,
}) =>
  supportsTrustPaymentBankTransferType &&
  supportsElectronicPayment &&
  bankAccountType === 'TRUST' &&
  sourceType === 'Bank transfer';

const shouldShowBpay = ({
  sourceType,
  transferType,
  bankAccountType,
  supportsBPAY,
  supportsTrustPaymentBankTransferType,
  supportsElectronicPayment,
}) =>
  supportsBPAY &&
  shouldShowTransferType({
    sourceType,
    bankAccountType,
    supportsTrustPaymentBankTransferType,
    supportsElectronicPayment,
  }) &&
  transferType === 'BPAY';

const shouldShowBankDetails = ({ sourceType, bankAccountType, supportsElectronicPayment }) =>
  supportsElectronicPayment &&
  ['TRUST', 'CONTROLLEDMONEY'].includes(bankAccountType) &&
  (sourceType === 'Bank transfer' || isDirectDebitActive({ sourceType }));

const getAvailableBalance = ({ matterId, bankAccountId }) =>
  matterId && bankAccountId ? getMatterBalance(getBankAccountBalancesState(), { matterId, bankAccountId }) : 0;

const isTrustElectronicPaymentEnabled = ({ bankAccountId, bankAccountType, supportsElectronicPayment, sourceType }) => {
  const isAUBankTransfer = bankAccountType === 'TRUST' && supportsElectronicPayment && sourceType === 'Bank transfer';

  if (!isAUBankTransfer) {
    return false;
  }

  if (!bankAccountId) {
    return false;
  }

  const { electronicPaymentNumberingSettings } = getTransactionNumberingSettings({
    bankAccountId,
  });
  return !electronicPaymentNumberingSettings || !electronicPaymentNumberingSettings.useManualNumbering;
};

const isTrustChequesEnabled = ({ bankAccountId, bankAccountType }) => {
  if (bankAccountType !== 'TRUST' || !bankAccountId) {
    return false;
  }

  return !!getTrustChequePrintSettingsById(bankAccountId)?.printingActive;
};

const isCMAElectronicPaymentEnabled = ({ bankAccountType, supportsElectronicPayment, sourceType }) => {
  const isAUBankTransfer =
    bankAccountType === 'CONTROLLEDMONEY' && supportsElectronicPayment && sourceType === 'Bank transfer';

  if (!isAUBankTransfer) {
    return false;
  }

  const { electronicPaymentNumberingSettings } = getCMASettings() || {};

  return !electronicPaymentNumberingSettings || !electronicPaymentNumberingSettings.useManualNumbering;
};
const shouldGenerateReference = ({ bankAccountId, bankAccountType, sourceType, printingMethod }) =>
  isTrustChequesEnabled({ bankAccountId, bankAccountType }) &&
  sourceType === 'Trust Check' &&
  printingMethod === PrintManually;

const isReferenceReadOnly = ({
  bankAccountId,
  bankAccountType,
  supportsElectronicPayment,
  sourceType,
  printingMethod,
}) => {
  if (bankAccountType === 'CONTROLLEDMONEY') {
    return isCMAElectronicPaymentEnabled({ bankAccountType, supportsElectronicPayment, sourceType });
  }

  const isReadyOnlyCheque =
    bankAccountType === 'TRUST' &&
    sourceType?.endsWith('Check') &&
    printingMethod !== PrintManually &&
    isTrustChequesEnabled({ bankAccountId, bankAccountType });

  return (
    isReadyOnlyCheque ||
    isTrustElectronicPaymentEnabled({
      bankAccountId,
      bankAccountType,
      supportsElectronicPayment,
      sourceType,
    })
  );
};

const getPaymentSources = ({ bankAccountType, matterId, bankAccountId, t, supportsAllowOverdraw }) => {
  const sources = getPaymentSourcesFromRedux({
    accountType: bankAccountType,
    matterId,
    bankAccountId,
    t,
    allowOverdraw: supportsAllowOverdraw,
  });

  return sources;
};

const calculateBalance = ({
  amount: _amount,
  matterId,
  bankAccountId,
  effectiveDate,
  availableBalance,
  bankAccountType,
  supportsCMA,
  supportsMatterTrustBalanceByDate,
}) => {
  const amount = Math.abs(_amount || 0);

  if (supportsCMA && isControlledMoniesAccount(bankAccountType)) {
    return availableBalance - amount;
  }

  if (!matterId || (supportsMatterTrustBalanceByDate && !effectiveDate)) {
    return undefined;
  }

  if (supportsMatterTrustBalanceByDate && effectiveDate && bankAccountId) {
    const matterTrustBalanceByDate = getMatterTrustBalanceByDate(matterId, effectiveDate, bankAccountId);
    return matterTrustBalanceByDate - amount;
  }

  return availableBalance - amount;
};

const isChequeNumberUsed = ({ bankAccountId, printingMethod, sourceType, reference }) => {
  if (bankAccountId && printingMethod === PrintManually && sourceType.endsWith('Check')) {
    let chequeNumberBig;
    try {
      chequeNumberBig = new Big(reference);
      return chequeExistsForBankAccount(chequeNumberBig, bankAccountId);
    } catch (e) {
      return false;
    }
  }

  return false;
};

const getLastChequeNumber = ({ bankAccountId }) => {
  if (!bankAccountId) {
    return undefined;
  }
  return findLastChequeNumber(getTrustChequesByBankAccountId(bankAccountId));
};

const getDefaultPaidToRelatedFields = ({
  supportsElectronicPayment,
  paidToId,
  sourceType,
  showBpay,
  directDebitIsActive,
}) => {
  if (!supportsElectronicPayment) {
    return {};
  }

  const fieldsToUpdate = {};

  const paidToContact = getContactById(paidToId);
  const paidToContactBankDetails = getEntityBankDetailsById(paidToId) || {};

  const paidToContactName = paidToContact ? paidToContact.displayName : '';

  // Can't destructure into object as value may be null, which doesn't fall back to empty string
  const accountName = paidToContactBankDetails?.accountName || '';
  const bankName = paidToContactBankDetails?.bankName || '';
  const bankBranchNumber = paidToContactBankDetails?.bankBranchNumber || '';
  const accountNumber = paidToContactBankDetails?.accountNumber || '';

  if ((sourceType === 'Bank transfer' && !showBpay) || directDebitIsActive) {
    fieldsToUpdate.bankAccountName = accountName;
    fieldsToUpdate.bankBranchNumber = bankBranchNumber;
    fieldsToUpdate.bankAccountNumber = accountNumber;
  } else if (sourceType.endsWith('Check')) {
    fieldsToUpdate.payeeName = paidToContactName;
    fieldsToUpdate.bankName = bankName;
    fieldsToUpdate.bankBranch = bankBranchNumber;
  }

  return fieldsToUpdate;
};

const getBankAccountOptions = ({ matterId, t }) => {
  const accounts = [];
  if (!matterId) {
    return accounts;
  }

  // Add Trust Accounts
  const trustAccounts = filterTrustAccountsByMatter(matterId);
  const formattedAccounts = trustAccounts.map((ta) => ({ label: getBankAccountName(ta, t), value: ta }));

  return sortByOrder(formattedAccounts, ['label'], ['asc']);
};

export const validateForm = ({
  scope,
  t,
  onValidateForm,
  supportsElectronicPayment,
  supportsReasonField,
  supportsTrustPaymentBankTransferType,
  supportsAllowOverdraw,
  supportsBPAY,
}) => {
  // while onValidateForm uses latest state internally, we need to use latest state for our validation context values
  const latestFieldValues = withScopedFeature({
    scope,
  })(forms).selectors.getFieldValues(store.getState());

  const hasProtectedFundsForBankAccount =
    latestFieldValues?.bankAccountId &&
    latestFieldValues?.matterId &&
    matterHasProtectedFundsForBankAccountId(latestFieldValues.matterId, latestFieldValues.bankAccountId);

  const validateCtx = {
    isMatterContactBalance: isMatterContactBalanceType(),
    isCMAElectronicPaymentEnabled: isCMAElectronicPaymentEnabled({
      bankAccountType: latestFieldValues.bankAccountType,
      supportsElectronicPayment,
      sourceType: latestFieldValues.sourceType,
    }),
    supportsReasonField,
    supportsElectronicPayment,
    supportsTrustPaymentBankTransferType,
    showBpay: shouldShowBpay({
      sourceType: latestFieldValues.sourceType,
      transferType: latestFieldValues.transferType,
      bankAccountType: latestFieldValues.bankAccountType,
      supportsBPAY,
      supportsTrustPaymentBankTransferType,
      supportsElectronicPayment,
    }),
    showBankDetails: shouldShowBankDetails({
      sourceType: latestFieldValues.sourceType,
      bankAccountType: latestFieldValues.bankAccountType,
      supportsElectronicPayment,
    }),
    t,
    trustChequesIsEnabled: isTrustChequesEnabled({
      bankAccountId: latestFieldValues.bankAccountId,
      bankAccountType: latestFieldValues.bankAccountType,
    }),
    lastChequeNumber: getLastChequeNumber({ bankAccountId: latestFieldValues.bankAccountId }),
    chequeNumberIsUsed: isChequeNumberUsed({
      bankAccountId: latestFieldValues.bankAccountId,
      printingMethod: latestFieldValues.printingMethod,
      sourceType: latestFieldValues.sourceType,
      reference: latestFieldValues.reference,
    }),
    hasProtectedFunds: featureActive('BB-8671') && hasProtectedFundsForBankAccount,
    availableBalance: getAvailableBalance({
      matterId: latestFieldValues.matterId,
      bankAccountId: latestFieldValues.bankAccountId,
    }),
    canOverdrawBalance: supportsAllowOverdraw && (!featureActive('BB-8671') || !hasProtectedFundsForBankAccount),
    dateIsReconciled:
      latestFieldValues.effectiveDate &&
      latestFieldValues.bankAccountId &&
      isReconciled({ yyyymmdd: latestFieldValues.effectiveDate, trustAccountId: latestFieldValues.bankAccountId }),
  };

  onValidateForm(validateCtx);
};

const shouldPrintCheques = ({ payment, bankAccountType, bankAccountId, sourceType }) =>
  bankAccountType === 'TRUST' &&
  sourceType.endsWith('Check') &&
  isTrustChequesEnabled({ bankAccountId, bankAccountType }) &&
  payment.chequePrintMethod === printMethodsByValue[PrintNow].code;

export const marshalData = ({
  formValues,
  supportsElectronicPayment,
  supportsAllowOverdraw,
  supportsBPAY,
  supportsTrustPaymentBankTransferType,
}) => {
  const isElectronicPayment =
    isTrustElectronicPaymentEnabled({
      bankAccountId: formValues.bankAccountId,
      bankAccountType: formValues.bankAccountType,
      sourceType: formValues.sourceType,
      supportsElectronicPayment,
    }) ||
    isCMAElectronicPaymentEnabled({
      bankAccountType: formValues.bankAccountType,
      sourceType: formValues.sourceType,
      supportsElectronicPayment,
    });

  const isMatterContactBalance = isMatterContactBalanceType();

  const showBpay = shouldShowBpay({
    sourceType: formValues.sourceType,
    transferType: formValues.transferType,
    bankAccountType: formValues.bankAccountType,
    supportsBPAY,
    supportsTrustPaymentBankTransferType,
    supportsElectronicPayment,
  });

  const getAmount = () => {
    if (isMatterContactBalance) {
      const isCombinedBalance = formValues.paymentSource?.isCombinedBalance;
      return isCombinedBalance
        ? Object.values(formValues.payors).reduce((sum, amount) => sum + (Number.isFinite(amount) ? amount : 0), 0)
        : formValues.amount;
    }

    return formValues.amount;
  };

  const getPayors = () => {
    const isCombinedBalance = formValues.paymentSource?.isCombinedBalance;

    return isCombinedBalance
      ? Object.keys(formValues.payors) // { [payorId]: amountInCents }
          .reduce((payors, payorId) => {
            if (formValues.payors[payorId]) {
              payors.push({
                payorId,
                amount: formValues.payors[payorId],
                paymentId: uuid(),
                transactionId: uuid(),
              });
            }
            return payors;
          }, [])
      : [
          {
            payorId: formValues.paymentSource.contactId,
            amount: formValues.amount,
            paymentId: uuid(),
            transactionId: uuid(),
          },
        ];
  };

  const payment = {
    accountType: capitalize(formValues.bankAccountType),
    bankAccountId: formValues.bankAccountId,
    amount: getAmount(),
    payeeId: formValues.paidToId,
    matterId: formValues.matterId,
    description: `${formValues.sourceType} vendor payment`,
    note: formValues.note,
    source: formValues.sourceType,
    reason: formValues.reason,
    reference: isElectronicPayment ? '' : formValues.reference,
    chequePrintMethod: printMethodsByValue[formValues.printingMethod].code,
    effectiveDate: formValues.effectiveDate,
    isElectronicPayment,
    // For contact balances the paymentId and transaction Id are picked from the payors.
    paymentId: !isMatterContactBalance ? uuid() : null,
    transactionId: !isMatterContactBalance ? uuid() : null,
  };

  if (isMatterContactBalance) {
    payment.payors = getPayors();
  }

  const printCheques = shouldPrintCheques({
    payment,
    bankAccountType: formValues.bankAccountType,
    bankAccountId: formValues.bankAccountId,
    sourceType: formValues.sourceType,
  });

  if (printCheques) {
    payment.shouldPrintCheques = true;
    payment.chequeId = uuid();
  }

  if (supportsElectronicPayment) {
    if (formValues.sourceType === 'Bank transfer' || isDirectDebitActive({ sourceType: formValues.sourceType })) {
      if (showBpay) {
        payment.billerCode = formValues.billerCode;
        payment.orgCompanyName = formValues.orgCompanyName;
        payment.bpayReference = formValues.bpayReference;
        // Enum DirectDeposit = 0, Bpay = 1
        payment.bankTransferType = formValues.transferTypeOptions.findIndex(
          (transferTypeOption) => transferTypeOption.value === formValues.transferType,
        );
      } else {
        payment.bankAccountName = formValues.bankAccountName;
        payment.bankBranchNumber = formValues.bankBranchNumber;
        payment.bankAccountNumber = formValues.bankAccountNumber;
      }
    }

    if (formValues.sourceType.endsWith('Check') && formValues.chequePayableToBank) {
      payment.bankName = formValues.bankName;
      payment.bankBranch = formValues.bankBranch;
      payment.payeeName = formValues.payeeName;
    }
  }

  if (supportsAllowOverdraw) {
    payment.allowOverdraw = true;
  }

  payment.chequeMemo =
    formValues.printingMethod === PrintNow || formValues.printingMethod === PrintManually
      ? formValues.chequeMemo
      : undefined;

  payment.printPaymentProof = ['TRUST', 'CONTROLLEDMONEY'].includes(formValues.bankAccountType)
    ? formValues.generatePaymentPdfNow
    : formValues.generateOperatingPaymentPdfNow;

  return payment;
};

export const VendorPaymentEntrySplitPayorsFormContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(VendorPaymentEntrySplitPayorsForm)),
);
VendorPaymentEntrySplitPayorsFormContainer.displayName = 'VendorPaymentEntrySplitPayorsFormContainer';

VendorPaymentEntrySplitPayorsFormContainer.propTypes = {
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  scope: PropTypes.string.isRequired,
  onBankAccountIdChange: PropTypes.func.isRequired,
  onMatterIdChange: PropTypes.func.isRequired,
};

VendorPaymentEntrySplitPayorsFormContainer.defaultProps = {
  matterId: undefined,
  bankAccountId: undefined,
};
