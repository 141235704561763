/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  DatePicker,
  CurrencyInput2,
  Button,
  LinkableText,
  forms2PropTypes,
  PanelExpander,
  useTranslation,
} from '@sb-itops/react';
import { integerToDate, dateToInteger } from '@sb-itops/date';
import { CheckboxLabelInline } from '@sb-itops/react/checkbox';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import Styles from './BankReconciliationSummaryPanel.module.scss';

export const BankReconciliationSummaryPanel = memo(
  ({
    bankStatementBalance,
    adjustments,
    reconciliationBalance,
    offset,
    adjustmentsTotal,
    lessUnpresentedPayments,
    addUnbankedReceipts,
    ledgerBalance,
    cashBookBalanceAsAtBsd,
    lessPayments,
    addReceipts,
    openingCashBookBalance,
    startDate,
    endDate,
    maxDate,
    onFieldValueUpdated,
    formInitialised,
    showRunEndOfMonthReporting,
    runEndOfMonthReporting,
    onEndDateUpdated,
    formValid,
    expanded,
    onToggleExpanded,
    onCancel,
    onSave,
    onRecon,
    finaliseReconModalId,
    validateFinalise,
    onRunEndOfMonthReportingChange,
    finalisingRecon,
    isAccountClosed,
  }) => {
    const { t } = useTranslation();
    if (!formInitialised) {
      return null;
    }

    // According to BB-8187:
    // https://git.cd.nova.int.boostbilling.com/boost/sb-billing/merge_requests/8028
    // The error highlighting for the draft bank reconciliation is to work as follows:
    // When opening a new bank reconciliation, the Bank Balance should be highlighted
    // When opening a draft bank reconciliation and the reconciliation is not balanced, the Bank Balance should be highlighted
    // When opening a draft bank reconciliation and the reconciliation is balanced, the Bank Balance should not be highlighted
    // After changing the value of the Bank Balance the field should not be highlighted, even if the reconciliation is not balanced.
    // The Save Draft button should be available unless there is an empty value in the Bank Balance
    // The Reconcile button should be available only if the reconciliation balances.

    let bankStatementBalancesError = false;
    if (!bankStatementBalance.isDirty && bankStatementBalance.value === undefined) {
      // always highligh new bank rec
      bankStatementBalancesError = true;
    } else if (!bankStatementBalance.isDirty && bankStatementBalance.value !== undefined) {
      // opening draft bank rec, highlight only if not balanced
      bankStatementBalancesError = bankStatementBalance.isInvalid;
    }

    return (
      <PanelExpander
        className={Styles.panelReconciliation}
        title="Reconciliation summary"
        expanded={expanded}
        toggleExpanded={onToggleExpanded}
        reverse={false}
        fullWidth
      >
        <div className={Styles.bankReconSummary}>
          <div className={classnames(Styles.reconSummary, 'form-horizontal')}>
            <div className={classnames(Styles.formGroup, 'form-group')}>
              <div className="col-sm-12">
                <span className={Styles.reconHeading}>Reconciliation for</span>
              </div>
            </div>

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className="col-sm-6">
                <DatePicker
                  onSelect={() => {}}
                  disabled
                  format="DD/MM/YYYY"
                  value={startDate?.value && integerToDate(startDate.value)}
                />
              </span>
              <span className={Styles.leftDown}>to</span>
              <span className="col-sm-6">
                <DatePicker
                  onSelect={(date) => {
                    onEndDateUpdated({ endDate: date ? dateToInteger(date) : undefined });
                  }}
                  value={endDate?.value && integerToDate(endDate.value)}
                  hasError={!endDate || (endDate && endDate.isInvalid)}
                  format="DD/MM/YYYY"
                  disabled={false}
                  minDate={startDate?.value && integerToDate(startDate.value)}
                  maxDate={maxDate}
                />
              </span>
            </div>

            {endDate.isInvalid && <div className={Styles.errorMsg}>{endDate.invalidReason}</div>}
            {adjustments.isInvalid && <div className={Styles.errorMsg}>{adjustments.invalidReason}</div>}

            <hr />

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className="col-sm-8 col-form-label">Opening Cash Book Balance</span>
              <span className={classnames(Styles.alignRight, 'col-sm-4')}>
                {t('cents', { val: openingCashBookBalance })}
              </span>
            </div>

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className="col-sm-8 col-form-label">Add Receipts</span>
              <span className={classnames(Styles.alignRight, 'col-sm-4')}>{t('cents', { val: addReceipts })}</span>
            </div>

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className="col-sm-8 col-form-label">Less Payments</span>
              <span className={classnames(Styles.alignRight, 'col-sm-4')}>{t('cents', { val: lessPayments })}</span>
            </div>

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className={classnames(Styles.bold, 'col-sm-8 col-form-label')}>Cash Book Balance</span>
              <span className={classnames(Styles.alignRight, Styles.bold, 'col-sm-4')}>
                {t('cents', { val: cashBookBalanceAsAtBsd })}
              </span>
            </div>

            <hr />

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className={classnames(Styles.bold, 'col-sm-8 col-form-label')}>Ledger Balance</span>
              <span className={classnames(Styles.alignRight, Styles.bold, 'col-sm-4')}>
                {t('cents', { val: ledgerBalance })}
              </span>
            </div>

            <hr />

            <div className={classnames(Styles.formGroup, 'form-group', 'input-row')}>
              <span className={classnames(Styles.bold, Styles.alignItemsCenter, 'col-sm-7 col-form-label flex')}>
                Bank Statement Balance
              </span>
              <span className={classnames('col-sm-5')}>
                <CurrencyInput2
                  className={classnames(Styles.alignRight)}
                  name="amount"
                  value={bankStatementBalance && bankStatementBalance.value}
                  disabled={false}
                  hasError={bankStatementBalancesError}
                  onChange={(e) => {
                    onFieldValueUpdated({ bankStatementBalance: e.target.value });
                  }}
                />
              </span>
            </div>

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className="col-sm-8 col-form-label">Add Unbanked Receipts</span>
              <span className={classnames(Styles.alignRight, 'col-sm-4')}>
                {t('cents', { val: addUnbankedReceipts })}
              </span>
            </div>

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className="col-sm-8 col-form-label">
                Less Unpresented <span className="capitalise">{t('cheques')}</span>
              </span>
              <span className={classnames(Styles.alignRight, 'col-sm-4')}>
                {t('cents', { val: lessUnpresentedPayments })}
              </span>
            </div>

            {adjustmentsTotal !== 0 && (
              <div className={classnames(Styles.formGroup, 'form-group')}>
                <span className="col-sm-8 col-form-label">Adjustments</span>
                <span className={classnames(Styles.alignRight, 'col-sm-4')}>
                  {t('cents', { val: adjustmentsTotal })}
                </span>
              </div>
            )}

            <div
              className={classnames(
                Styles.formGroup,
                'form-group',
                `${
                  bankStatementBalancesError || bankStatementBalance.isInvalid
                    ? Styles.balanceInvalid
                    : Styles.balanceValid
                }`,
              )}
            >
              <span className={classnames(Styles.bold, 'col-sm-6 col-form-label')}>Reconciliation Balance</span>
              <div className={classnames(Styles.alignRight, 'col-sm-6')}>
                <div className={Styles.bold}>{t('cents', { val: reconciliationBalance })}</div>
                <div>( {t('cents', { val: offset })} )</div>
              </div>
            </div>

            <hr />
            <div>
              <Button
                className={Styles.button}
                onClick={validateFinalise}
                size="full-width"
                disabled={!formValid || bankStatementBalance?.value === undefined || isAccountClosed}
              >
                Reconcile
              </Button>
              <Button
                className={classnames(Styles.button, Styles.mt5)}
                onClick={onSave}
                size="full-width"
                disabled={bankStatementBalance?.value === undefined || isAccountClosed}
              >
                Save Draft
              </Button>
            </div>

            {((!isAccountClosed && bankStatementBalancesError) || bankStatementBalance?.isInvalid) && (
              <span className={classnames(Styles.errorMsg, Styles.pt20)}>
                Please ensure balances reconcile before continuing
              </span>
            )}
            {isAccountClosed && (
              <span className={classnames(Styles.errorMsg, Styles.pt20)}>
                Cannot complete reconciliation - {t('trustAccount').toLowerCase()} is closed
              </span>
            )}

            <hr />

            <div className={classnames(Styles.formGroup, 'form-group')}>
              <span className={classnames(Styles.alignRight, 'col-sm-12')}>
                <LinkableText className={Styles.actionLink} text="Cancel Draft" onClickLink={onCancel} asLink inline />
              </span>
            </div>
          </div>
        </div>
        <ConfirmationModalDialog
          onConfirm={() => onRecon({ runEndOfMonthReporting })}
          modalId={finaliseReconModalId}
          title="Complete Reconciliation"
          body={
            <>
              Warning: {t('finalising')} this bank reconciliation will lock all of the transactions within that
              reconciliation date period
              {showRunEndOfMonthReporting && (
                <CheckboxLabelInline checked={runEndOfMonthReporting} onChange={onRunEndOfMonthReportingChange}>
                  Run End of Month reporting
                </CheckboxLabelInline>
              )}
            </>
          }
          primaryButtonText="Complete"
          isConfirming={finalisingRecon}
        />
      </PanelExpander>
    );
  },
);

const { Forms2Field } = forms2PropTypes;

BankReconciliationSummaryPanel.displayName = 'BankReconciliationSummaryPanel';

BankReconciliationSummaryPanel.propTypes = {
  finaliseReconModalId: PropTypes.string.isRequired,
  finalisingRecon: PropTypes.bool.isRequired,
  showRunEndOfMonthReporting: PropTypes.bool.isRequired,
  runEndOfMonthReporting: PropTypes.bool.isRequired,
  isAccountClosed: PropTypes.bool,
  // fn
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRecon: PropTypes.func.isRequired,
  validateFinalise: PropTypes.func.isRequired,
  onRunEndOfMonthReportingChange: PropTypes.func.isRequired,
  // numbers
  reconciliationBalance: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  adjustmentsTotal: PropTypes.number.isRequired,
  lessUnpresentedPayments: PropTypes.number.isRequired,
  addUnbankedReceipts: PropTypes.number.isRequired,
  ledgerBalance: PropTypes.number.isRequired,
  cashBookBalanceAsAtBsd: PropTypes.number.isRequired,
  lessPayments: PropTypes.number.isRequired,
  addReceipts: PropTypes.number.isRequired,
  openingCashBookBalance: PropTypes.number.isRequired,
  // form
  onFieldValueUpdated: PropTypes.func.isRequired,
  onEndDateUpdated: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  bankStatementBalance: Forms2Field,
  startDate: Forms2Field,
  endDate: Forms2Field,
  maxDate: Forms2Field,
  adjustments: Forms2Field,

  // panel
  expanded: PropTypes.bool.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
};

BankReconciliationSummaryPanel.defaultProps = {
  bankStatementBalance: undefined,
  startDate: undefined,
  endDate: undefined,
  maxDate: undefined,
  adjustments: undefined,
  isAccountClosed: false,
};
