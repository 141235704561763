angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/view-matter/memos';

  $stateProvider
    .state('home.billing.view-matter.memos', {
      url: '/memos',
      templateUrl: `${BASE_DIRECTORY}/view-matter-memos.html`,
      controller: 'sbViewMatterMemosController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      params: {
        selectedMemoId: '',
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'memos'],
        tab: { type: 'matter' }
      }
    });
});
