import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Button, Modal } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
import * as Styles from './RateSetBulkApplyModal.module.scss';

const RateSetBulkApplyModal = ({
  isSubmitting,
  isVisible,
  rateSetOptions,
  selectedBulkApplyRateSetOption,
  rateSetsLoading,
  onCloseModal,
  onRateSetChange,
  onSave,
}) => (
  <Modal
    className={Styles.modal}
    isVisible={isVisible}
    title="Apply Rate Set"
    body={
      <div className={Styles.selectionWrapper}>
        <label>RATE SET</label>
        <StatelessSelect
          selectedOption={selectedBulkApplyRateSetOption}
          isLoading={rateSetsLoading}
          isClearable={false}
          options={rateSetOptions}
          onValueChange={onRateSetChange}
          menuPosition="fixed"
        />
      </div>
    }
    footer={
      <div>
        <Button disabled={!selectedBulkApplyRateSetOption?.value || isSubmitting} onClick={onSave}>
          Save {isSubmitting && <Spinner small />}
        </Button>
      </div>
    }
    onModalClose={onCloseModal}
  />
);

RateSetBulkApplyModal.displayName = 'RateSetBulkApplyModal';

RateSetBulkApplyModal.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  rateSetsLoading: PropTypes.bool.isRequired,
  rateSetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
  selectedBulkApplyRateSetOption: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
  onRateSetChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

RateSetBulkApplyModal.defaultProps = {
  selectedBulkApplyRateSetOption: undefined,
};

export default RateSetBulkApplyModal;
