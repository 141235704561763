import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyInput2, LinkableText, useTranslation } from '@sb-itops/react';
import Styles from './MatterTrustBalanceFilter.module.scss';

export const MatterTrustBalanceFilter = (props) => {
  const { t } = useTranslation();
  const { formValues, onUpdateFieldValues, onClearForm } = props;

  return (
    <div className={Styles.trustBalance}>
      <div className={Styles.trustLabel}>From {t('currencySymbol')} </div>
      <div className={classnames(Styles.trustBalanceValueContainer, Styles.whitespaceRight)}>
        <CurrencyInput2
          className={classnames(Styles.trustBalanceValue, Styles.currencyInput)}
          name="minimumTrustBalance"
          value={formValues.minimumTrustBalance}
          hideDollar
          onChange={(e) => onUpdateFieldValues(e.target.name, e.target.value)}
        />
      </div>
      <div className={Styles.trustLabel}> to {t('currencySymbol')} </div>
      <div className={Styles.trustBalanceValueContainer}>
        <CurrencyInput2
          className={classnames(Styles.trustBalanceValue, Styles.currencyInput)}
          name="maximumTrustBalance"
          value={formValues.maximumTrustBalance}
          hideDollar
          onChange={(e) => onUpdateFieldValues(e.target.name, e.target.value)}
        />
      </div>
      <LinkableText text="Clear" onClickLink={onClearForm} asLink inline />
    </div>
  );
};

MatterTrustBalanceFilter.displayName = 'MatterTrustBalanceFilter';

MatterTrustBalanceFilter.propTypes = {
  formValues: PropTypes.object.isRequired,
  onUpdateFieldValues: PropTypes.func.isRequired,
  onClearForm: PropTypes.func.isRequired,
};

MatterTrustBalanceFilter.defaultProps = {};
