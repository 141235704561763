angular
  .module('sb.billing.webapp')
  .factory(
    'exportCorrespondenceHistoryOp',
    (
      $rootScope,
      $http,
      sbGenericEndpointService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
    ) => {
      const log = sbLoggerService.getLogger('exportCorrespondenceHistoryOp');
      return (exportFilters) => {
        // Object representing the current state of this operation.
        const operation = {
          label: `Client Communications`,
          reportFormat: 'csv',
          isComplete: false,
          error: null,
          fileData: null,
        };

        downloadCorrespondenceHistoryDataP(operation, exportFilters);

        return operation;
      };

      async function downloadCorrespondenceHistoryDataP(operation, exportFilters) {
        const requestId = sbUuidService.get();

        try {
          // Kick off the operation
          const { payload } = await sbNotifiedOperationP(() => startExportingP(requestId, exportFilters), {
            requestId,
            completionNotification: 'CorrespondenceHistoryExportReadyNotification',
            errorNotification: 'CorrespondenceHistoryExportFailureNotification',
            timeoutMs: 30000,
          });

          const request = {
            method: 'GET',
            url: payload.exportFileUrl,
            responseType: 'arraybuffer'
          };

          const response = await $http(request);
          const file = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});
          operation.fileData = file;
        } catch (err) {
          log.error(`Failed to export correspondence history for requestId: ${requestId}`, err);
          operation.error = err;
        } finally {
          $rootScope.$applyAsync(() => {
            operation.isComplete = true;
          });
        }

        function startExportingP(requestId, exportFilters) {
          const requestData = {
            requestId,
            fileFormat: 'csv',
            ...exportFilters,
          };

          return sbGenericEndpointService.postPayloadP('/billing/correspondence-history/export', undefined, requestData, 'POST');
        }
      }
    }
  );
