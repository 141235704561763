const cheerio = require('cheerio');
const juice = require('juice');
const Alignment = require('../alignment/alignment.class');
const Color = require('../color/color.class');
const Sources = require('../sources/sources.class');
const Style = require('../style/style.class');
const AllowedHtmlTags = require('../allowed-html-tags/allowed-html-tags.class');
const Table = require('../table/table.class');
const MyString = require('../string/my-string.class');
const charset = require('./charset.module');

class Rtf {
  constructor() {
    this.rtfHeaderOpening =
      '{\\rtf1\\ansi\\ansicpg1252\\deff0\\nouicompat{\\fonttbl{\\f0\\fnil\\fcharset0 Arial;}{\\f1\\fnil\\fcharset0 Arial Black;}{\\f2\\fnil\\fcharset0 Courier New;}{\\f3\\fnil\\fcharset0 Georgia;}{\\f4\\fnil\\fcharset0 Tahoma;}{\\f5\\fnil\\fcharset0 Times New Roman;}{\\f6\\fnil\\fcharset0 Verdana;}}';
    this.rtfHeaderContent = '';
    this.rtfClosing = '}';
    this.rtfContentReferences = [];
    this.Table = new Table();
  }

  convertHtmlToRtf(html) {
    charset.forEach((c) => (html = html.replace(new RegExp(c.htmlEntity, 'g'), c.rtfEscapeChar)));
    // eslint-disable-next-line no-control-regex
    html = html.replace(/[^\u0000-\u007F]/g, (element) => {
      // handle based on https://www.zopatista.com/python/2012/06/06/rtf-and-unicode/
      const char = element.charCodeAt(0);
      return `\\u${char}?`;
    });
    let htmlWithoutStrangerTags;
    let $;
    let treeOfTags;

    htmlWithoutStrangerTags = this.swapHtmlStrangerTags(html, 'p');
    $ = cheerio.load(juice(htmlWithoutStrangerTags));
    treeOfTags = $('html').children();

    Array.from(treeOfTags).forEach((tag) => this.readAllChildsInTag(tag));

    return this.buildRtf();
  }

  swapHtmlStrangerTags(html, dafaultTag) {
    return html.replace(/<(\/?[a-z-_]+[a-z0-9-_]*)( *[^>]*)?>/gi, (match, tagName, options) => {
      const newTag = !tagName.includes('/') ? `<${dafaultTag}${options || ''}>` : `</${dafaultTag}>`;
      return AllowedHtmlTags.isKnowedTag(tagName) ? match : `${newTag}`;
    });
  }

  buildRtf() {
    this.rtfHeaderContent += Style.getRtfColorTable();

    const content = this.rtfHeaderOpening + this.rtfHeaderContent + this.getRtfContentReferences() + this.rtfClosing;

    this.clearCacheContent();

    return content;
  }

  getRtfContentReferences() {
    let rtfReference = '';

    this.rtfContentReferences.forEach((value) => (rtfReference += value.content));

    return rtfReference;
  }

  // Don't has a test
  readAllChildsInTag(childTag, parentTag) {
    if (childTag.children !== undefined) {
      this.addOpeningTagInRtfCode(childTag.name, parentTag && parentTag.name);
      this.ifExistsAttributesAddAllReferencesInRtfCode(childTag.attribs);

      if (childTag.name.toLowerCase() === 'table') {
        this.Table.setAmountOfColumns(this.getAmountOfColumnThroughOfFirstChildOfTbodyTag(childTag.children));
      }

      if (childTag.name.toLowerCase() === 'tr') {
        this.addReferenceTagInRtfCode(this.Table.buildCellsLengthOfEachColumn());
      }

      if (childTag.name.toLowerCase() === 'mark') {
        this.setHighlightInRtf();
      }
      if (childTag.name.toLowerCase() === 'a') {
        this.setHrefInRtf(childTag);
        this.setOpenLinkFrameInRtf();
      }

      childTag.children.forEach((child) => {
        if (child.type !== 'text') {
          this.readAllChildsInTag(child, childTag);
        } else {
          this.addContentOfTagInRtfCode(child.data);
        }
      });
    }

    if (childTag.name.toLowerCase() === 'a') {
      this.setCloseLinkFrameInRtf();
    }
    this.addClosingFatherTagInRtfCode(childTag.name, parentTag && parentTag.name);
  }

  getAmountOfColumnThroughOfFirstChildOfTbodyTag(tableChildren) {
    let count = 0;
    const tbodyIndex = tableChildren.findIndex((value) => value.name === 'tbody');

    for (let i = 0; i < tableChildren[tbodyIndex].children.length; i++) {
      if (tableChildren[tbodyIndex].children[i].type !== 'text') {
        tableChildren[tbodyIndex].children[i].children.forEach((child) => {
          if (child.type !== 'text') {
            count++;
          }
        });

        break;
      }
    }

    return count;
  }

  ifExistsAttributesAddAllReferencesInRtfCode(attributes) {
    if (attributes.style !== undefined) {
      this.addReferenceTagInRtfCode(Style.getRtfReferencesInStyleProperty(attributes.style));
    }

    if (attributes.align !== undefined) {
      this.addReferenceTagInRtfCode(Alignment.getRtfAlignmentReference(attributes.align));
    }
    if (attributes.src !== undefined) {
      this.addReferenceTagInRtfCode(Sources.getRtfSourcesReference(attributes.src, attributes.style));
    }
  }

  addReferenceTagInRtfCode(referenceTag) {
    if (referenceTag !== undefined) {
      this.rtfContentReferences.push({
        content: referenceTag,
        tag: true,
      });
    }
  }

  addOpeningTagInRtfCode(tag, parentTag) {
    this.addReferenceTagInRtfCode(AllowedHtmlTags.getRtfReferenceTag(tag, parentTag));
  }

  addClosingFatherTagInRtfCode(closingFatherTag, parentTag) {
    this.addReferenceTagInRtfCode(AllowedHtmlTags.getRtfReferenceTag(`/${closingFatherTag}`, parentTag));
  }

  addContentOfTagInRtfCode(content) {
    // Removes special chars and replaces < and > to prevent unescaped html tags
    // Cheerio will unfortunately decode already escaped tags so we need to re-escape them
    content = MyString.removeCharacterOfEscapeInAllString(content, '\n\t');

    if (content !== undefined && !MyString.hasOnlyWhiteSpace(content)) {
      this.rtfContentReferences.push({
        content: `{\\lang1033\\ltrch ${content}}`,
        tag: false,
      });
    }
  }

  setHighlightInRtf() {
    const rtfReferenceColor = Color.getRtfReferenceColor('rgb(255, 255, 0)');
    const referenceColorNumber = rtfReferenceColor.match(/[0-9]+/);

    this.addReferenceTagInRtfCode(`\\highlight${referenceColorNumber.toString()}`);
  }

  setHrefInRtf(tag) {
    if (tag.attribs.href) {
      this.addReferenceTagInRtfCode(`{\\*\\fldinst HYPERLINK "${tag.attribs.href}"}`);
    }
  }

  setOpenLinkFrameInRtf() {
    this.addReferenceTagInRtfCode('{\\fldrslt');
  }

  setCloseLinkFrameInRtf() {
    this.addReferenceTagInRtfCode('}');
  }

  clearCacheContent() {
    this.rtfHeaderContent = '';
    this.rtfContentReferences = [];
  }
}

module.exports = Rtf;
