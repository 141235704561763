'use strict';

const { convertInvoiceEmailRequestToEmailDetail } = require('./convert-invoice-email-request-to-email-detail');

const convertInvoiceEmailRequestsToEmailDetails = (invoiceEmailRequests, options = {}) => {
  if (!invoiceEmailRequests || !Array.isArray(invoiceEmailRequests)) {
    throw new Error('Argument must be an array of InvoiceEmailRequests');
  }

  return invoiceEmailRequests.map((request) => convertInvoiceEmailRequestToEmailDetail(request, options));
};

module.exports = {
  convertInvoiceEmailRequestsToEmailDetails,
};
