'use strict';

import { store } from '@sb-itops/redux';
import { selectors as filterSelectors } from 'web/redux/route/home-billing-contact-transactions';
import { filterTrustAccountsByContact } from "web/redux/selectors/filter-trust-accounts";
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { getRememberedParams } from "web/redux/features/transactions-recent-account-selection/selectors";

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/view-contact/transactions';
  const PARENT_PAGE_NAME = 'home.billing.view-contact.transactions';

  $stateProvider
    .state('home.billing.view-contact.transactions', {
      url: '/transactions?showHidden&startDate&endDate',
      templateUrl: BASE_DIRECTORY + '/transactions.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      abstract: true,
      onEnter: function($timeout, $stateParams, $state) {
        // If entering trust overview and not from a child page of transactions
        // Load the previously remembered page if there is one
        // $state.current.name is the state we are coming from, $stateParams is the params of the state we are going to
        if (!$state.current.name.includes(PARENT_PAGE_NAME) ) {
          const rememberedAccountParams = getRememberedParams(store.getState()['web/transactions-recent-account-selection'], { pageName: PARENT_PAGE_NAME, entityId: $stateParams.contactId });
          if (rememberedAccountParams) {
            $timeout(() => {
              $state.go(...rememberedAccountParams);
            })
          } else {
            // If account only has one trust account, go straight to the account page
            if (filterTrustAccountsByContact($stateParams.contactId).length === 1) {
              $timeout(function(){
                $state.go('home.billing.view-contact.transactions.trust', { trustAccountId: filterTrustAccountsByContact($stateParams.contactId)[0].id });
              })
            }
          }
        }
      }
    })
    .state('home.billing.view-contact.transactions.trust', {
      url: '/trust/:trustAccountId',
      templateUrl: `${BASE_DIRECTORY}/trust-transactions.html`,
      reloadOnSearch: false,
      params: {
        trustAccountId: 'all',
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts', 'view-contact', 'contact-transactions', 'trust'],
        tab: {type: 'contact'},
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-contact.transactions.trust', { ...$stateParams }], entityId: $stateParams.contactId, parentPageName: PARENT_PAGE_NAME }));
      }
    })
    .state('home.billing.view-contact.transactions.operating', {
      url: '/operating',
      templateUrl: `${BASE_DIRECTORY}/operating-transactions.html`,
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts', 'view-contact', 'contact-transactions', 'operating'],
        tab: {type: 'contact'},
        accountType: 'OPERATING',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-contact.transactions.operating', { ...$stateParams }], entityId: $stateParams.contactId, parentPageName: PARENT_PAGE_NAME 
      
      }));
      },
    })
    .state('home.billing.view-contact.transactions.ledger', {
      url: '/ledger',
      templateUrl: `${BASE_DIRECTORY}/ledger.html`,
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts', 'view-contact', 'ledger'],
        tab: {type: 'contact'},
        accountType: 'LEDGER',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          startDate: filterSelectors.getFilters(store.getState()).dateListFilter.startDate,
          endDate: filterSelectors.getFilters(store.getState()).dateListFilter.endDate,
        })
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-contact.transactions.ledger', { ...$stateParams }], entityId: $stateParams.contactId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-contact.transactions.credit-note-ledger', {
      url: '/credit-note-ledger',
      templateUrl: `${BASE_DIRECTORY}/contact-credit-note-ledger.html`,
      reloadOnSearch: false,
      params: {
        showHidden: null,
        startDate: null,
        endDate: null
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'contacts', 'view-contact', 'contact-transactions', 'credit-note-ledger'],
        tab: {type: 'contact'},
        accountType: 'CREDIT-NOTE-LEDGER',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-contact.transactions.credit-note-ledger', { ...$stateParams }], entityId: $stateParams.contactId, parentPageName: PARENT_PAGE_NAME }));
      },
    });
});
