'use strict';

const { discountType } = require('./discount-type');
const { entryType } = require('./entry-type');
const { statusByName, statusByValue, status } = require('./status');
const { eInvoiceLayout } = require('./einvoice-layout');
const warningMessages = require('./warning-messages');

module.exports = {
  discountType,
  eInvoiceLayout,
  entryType,
  statusByName,
  statusByValue,
  status,
  warningMessages,
};
