import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import { getProductTier, refreshTokenP } from 'web/services/user-session-management';
import { subscribeToNotifications } from 'web/services/subscription-manager';

const waitForProductIdUpdatedNotification = async ({ timeoutMs = 2500 } = {}) =>
  new Promise((resolve) => {
    const unsub = subscribeToNotifications({
      notificationIds: ['ProductRegistrationNotifications.ProductIdUpdated'],
      callback: () => {
        const timeoutId = setTimeout(() => {
          unsub();
          resolve();
        }, timeoutMs);

        clearTimeout(timeoutId);
        unsub();
        resolve();
      },
    });
  });

const waitForCognitoTokenUpdate = async ({ expectedProductTier, attempts = 1, tryAgainTimeMs, maxAttempts = 5 }) => {
  await refreshTokenP();
  const tokenProductTier = getProductTier();

  // Check if the current product tier matches the expected one
  if (tokenProductTier === expectedProductTier) {
    return;
  }

  if (attempts >= maxAttempts) {
    throw new Error('Max retries reached. Token product tier did not match the expected value.');
  }

  // Retry logic: wait, double the retry time, and retry again
  await new Promise((resolve) => {
    setTimeout(resolve, tryAgainTimeMs);
  });

  await waitForCognitoTokenUpdate({
    expectedProductTier,
    attempts: attempts + 1,
    tryAgainTimeMs: tryAgainTimeMs ? 2000 : tryAgainTimeMs * 2, // Double the retry time
    maxAttempts,
  });
};

const updateSmokeballProductTier = async ({ smokeballProductId }) => {
  const waitForNotificationsPromise = waitForProductIdUpdatedNotification();

  // Let the backend know to change the firm's product tier.
  await dispatchCommand({
    type: 'ProductRegistration.Commands.SetProductIdForFirmProductAccount',
    message: { productTierId: smokeballProductId },
  });

  await waitForNotificationsPromise;
  await waitForCognitoTokenUpdate({ expectedProductTier: smokeballProductId });
  // LD toggles and all integrations will be out of sync. Forcing a refresh will fix all the bugs
  window.location.reload();
};

export const revertToBillNow = () => updateSmokeballProductTier({ smokeballProductId: productTierIdsByName.BILL });
export const trialBoostNow = () => updateSmokeballProductTier({ smokeballProductId: productTierIdsByName.BOOST });
