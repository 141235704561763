/**
 * withSelectorParameterInjections behaves like {@link withParameterInjections} with the exception
 * that it has special handling for 'state' which is always passed as the first parameter into a
 * selector.
 *
 * @param  {Object}   argsToInject Parameter names and associated values to inject.
 * @return {Function} Function accepting a collection of selectors to have injections applied.
 *
 *  @example:
 *
 *  const mySelector = (state, { someArg }) => {};
 *  const myOtherSelector = (state, { someOtherArg }) => {};
 *  const someSelectors = { mySelector, myOtherSelector };
 *
 *  const argToInject = 5;
 *  const state = {};
 *  const selectorsWithInjections = withSelectorParameterInjections({ state, argToInject })(someSelectors);
 *
 *  // Now
 *  //  selectorsWithInjections.mySelector can be called like selectorsWithInjections.mySelector({ someArg }), but behaves like
 *  //  selectorsWithInjections.mySelector(state, { someArg, argToInject = 5 });
 */
export const withSelectorParameterInjections = (argsToInject) => (selectorsByName) =>
  Object.entries(selectorsByName || {}).reduce((acc, [selectorName, selectorFn]) => {
    // 'state' is a special argument that gets passed as the first parameter to a selector.
    // The remaining args are passed into the second parameter's object.
    if (argsToInject.state) {
      const { state, ...newArgsToInject } = argsToInject;
      acc[selectorName] = (passedArgs) => selectorFn(argsToInject.state, { ...newArgsToInject, ...passedArgs });
    } else {
      acc[selectorName] = (state, passedArgs) => selectorFn(state, { ...argsToInject, ...passedArgs });
    }

    return acc;
  }, {});
