'use strict';

/**
 * Applies modifications to the email subject when necessary
 *
 * @param {object} params
 * @param {string} params.emailSubject
 * @param {boolean} params.isConsolidatedMode
 * @returns {string}
 */
function modifyEmailSubject({ isConsolidatedMode, emailSubject }) {
  if (isConsolidatedMode) {
    const consolidatedSubject = `Your New Invoices - [FIRM_NAME]`;
    return consolidatedSubject;
  }

  return emailSubject;
}

module.exports = {
  modifyEmailSubject,
};
