import { Button, Modal } from '@sb-itops/react';
import React from 'react';
import PropTypes from 'prop-types';

import Styles from './CustomTaskCodeDeleteModal.module.scss';

export const CustomTaskCodeDeleteModal = React.memo(({ codeId, onDelete, onCloseModal, isLocked }) => (
  <Modal
    className={Styles.customTaskCodeDeleteModal}
    title="Delete Task Code"
    body={<p>This will permanently delete this task code. Are you sure you want to delete it?</p>}
    footer={
      <div className={Styles.buttonContainer}>
        <Button
          className={Styles.submitButton}
          onClick={() => {
            onDelete(codeId);
            onCloseModal();
          }}
          size="full-width"
          disabled={isLocked}
        >
          Delete
        </Button>
        <Button
          className={Styles.cancelButton}
          onClick={onCloseModal}
          size="full-width"
          type="secondary"
          disabled={isLocked}
        >
          Cancel
        </Button>
      </div>
    }
    onModalClose={onCloseModal}
  />
));

CustomTaskCodeDeleteModal.displayName = 'CustomTaskCodeDeleteModal';

CustomTaskCodeDeleteModal.propTypes = {
  codeId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
};

CustomTaskCodeDeleteModal.defaultProps = {
  isLocked: false,
};
