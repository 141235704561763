'use strict';

angular.module('sb.billing.webapp').component('sbStaffSelector', {
  bindings: { 
    selectedId: '<?',
    dropdownDisabled: '<?',
    name: '@?',
    focus: '@focusKey',
    disabledFlag: '<ngDisabled',
    hasErrorFlag: '<?',
    onBlur: '&?ngBlur',
    onPostBlur: '&?',
    onSelect: '&?'
  },
  templateUrl: 'ng-components/staff-selector/staff-selector.html',
  controller: function ($scope, $timeout, focusService, sbFirmManagementMbService) {
    const ctrl = this;

    let onBlurFn     = _.noop;
    let onPostBlurFn = _.noop;
    let onSelectFn   = _.noop;

    ctrl.view = {
      staffItems: [],
    };
    ctrl.model = {
      selectedStaff: undefined,
    };
    ctrl.processSelection = processSelection;
    ctrl.processBlur = processBlur;
    ctrl.processClick = processClick;

    // this is needed for the typeahead stuff.
    // thanks NG
    $scope.view = ctrl.view;

    ctrl.$onInit = () => {
      ctrl.view.staffItems = sbFirmManagementMbService.getAllStaffSummaries()
        .map(staff => ({
          id: staff.id,
          label: `${staff.initials} (${staff.name})`,
          name: staff.name,
          initials: staff.initials,
          isFormerStaff: staff.isFormerStaff,
        }))
        .sort(((a, b) => (a.label || '').toLowerCase().localeCompare((b.label || '').toLowerCase()))); //a -> z

      // Set default CB functions if not present.
      onBlurFn     = _.isFunction(ctrl.onBlur)     ? ctrl.onBlur     : _.noop;
      onPostBlurFn = _.isFunction(ctrl.onPostBlur) ? ctrl.onPostBlur : _.noop;
      onSelectFn   = _.isFunction(ctrl.onSelect)   ? ctrl.onSelect   : _.noop;

      setSelectedStaffById(ctrl.selectedId);

      $scope.view = ctrl.view;
    };

    ctrl.$onChanges = (changes) => {
      if (changes.selectedId) {
        setSelectedStaffById(ctrl.selectedId);
      }
    }


    function processSelection (item) {
      onSelectFn({item});
    }

    function processBlur () {
      if (_.isEmpty(ctrl.model.selectedStaff)) {
        setSelectedStaffById(ctrl.selectedId);
      }
  
      onBlurFn();
      $timeout(onPostBlurFn);
    }

    function processClick () {
      if (ctrl.focus) {
        focusService.focusOn(ctrl.focus, true);
      }
    }

    function setSelectedStaffById(staffId) {
      ctrl.model.selectedStaff = _.find(
        ctrl.view.staffItems,
        (staff) => staffId === staff.id
      );
      processSelection(ctrl.model.selectedStaff);
    } 
  },
});
