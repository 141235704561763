'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const dateRangeTypes = Object.freeze({
  ALL: 'ALL',
  TODAY: 'TODAY',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  BEFORE: 'BEFORE',
  CUSTOM: 'CUSTOM',
});

const dateRangeTypeLabels = Object.freeze({
  ALL: 'All', 
  TODAY: 'Today',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  BEFORE: 'Before',
  CUSTOM: 'Custom',
});

const dateRangeTypeOptions = getTypeAsOptions({
  typeLabels: dateRangeTypeLabels,
  typeValues: dateRangeTypes,
});

module.exports = {
  dateRangeTypes,
  dateRangeTypeLabels,
  dateRangeTypeOptions,
};
