'use strict';

const isReconciled = (tx, bankReconciliation) => {
  if (!bankReconciliation) {
    return false;
  }

  const { id, status } = bankReconciliation;

  if (!tx.reconciliationId) {
    return false;
  }

  if (tx.reconciliationId === id && status === 'InProgress') {
    return false;
  }

  return true;
};

module.exports = {
  isReconciled,
};
