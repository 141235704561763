
import { getDefaultTemplate, getTemplateById }  from '@sb-billing/redux/invoice-settings-template'; 

angular.module('sb.billing.webapp').component('sbInvoiceLogo',
  {
    templateUrl: 'ng-components/invoice-settings/invoice-logo.html',
    bindings: { invoiceLogo: '<', onStartUpload: '&', syncUpdate: '&', templateId: '<?' },
    controller: function ($scope, sbInvoiceSettingsHelpers, sbFirmManagementMbService, sbMessageDisplayService, sbUuidService, sbLoggerService) {
      const ctrl = this;
      const log = sbLoggerService.getLogger('SbInvoiceSettingsController');
      const DEFAULT_IMAGE_URL = '';
      let defaultTemplateId = null;

      defaultTemplateId = getDefaultTemplate().id;

      ctrl.uploadFile = uploadFile;
      ctrl.deleteFile = deleteFile;
      ctrl.disableLogoUpload = disableLogoUpload;
      ctrl.disableLogoDelete = disableLogoDelete;
      ctrl.onOpenGenerateLogoDialog = onOpenGenerateLogoDialog;
      ctrl.onCloseGenerateLogoDialog = onCloseGenerateLogoDialog;
      ctrl.onApplyGeneratedImageAsFirmLogo = onApplyGeneratedImageAsFirmLogo;

      ctrl.$onInit = () => {
        $scope.uploadedFiles = {};

        $scope.$watch('$ctrl.templateId', () => {
          loadLogo();
        });

        ctrl.generateLogoModalVisible = false;
      };

      function onOpenGenerateLogoDialog() {
        ctrl.generateLogoModalVisible = true;
      }

      function onCloseGenerateLogoDialog() {
        ctrl.generateLogoModalVisible = false;
      }

      async function onApplyGeneratedImageAsFirmLogo({ logoFileUrl }) {
        try {
          const mimeType = 'image/png';
          const response = await fetch(logoFileUrl);
          const responseBlob = await response.blob();
          const fileBlob = new File([responseBlob], `generated-image-${sbUuidService}.png`, { type: mimeType });

          $scope.uploadedFiles.theFile = fileBlob;

          uploadFile();

        } catch (err) {
          sbMessageDisplayService.error('Failed to apply generated image as invoice logo');
          log.error('Failed to set generated image as invoice logo', err);
        } finally {
          ctrl.generateLogoModalVisible = false;
        }
      }

      function loadLogo() {
        const template = getTemplateById(ctrl.templateId);
        const templateId = template.settings.hasLogo && ctrl.templateId;
        ctrl.uploadingOrFetchingFile = true;
        ctrl.invoiceLogo = DEFAULT_IMAGE_URL;
        sbFirmManagementMbService.getLogoUrlP(templateId)
          .then((dataURL) => {
            ctrl.logoNotFound = false;
            ctrl.invoiceLogo = dataURL;
          })
          .catch((err) => {
            log.error(`Failed to get logo Url for the template ${templateId}. Error: ${err}`);
            ctrl.logoNotFound = (err && err.code === 404);
            ctrl.invoiceLogo = DEFAULT_IMAGE_URL;
          })
          .finally(() => {
            ctrl.uploadingOrFetchingFile = false;
          });
      }

      async function uploadFile() {
        const saveSettings = await ctrl.onStartUpload()
        if (!saveSettings.success) {
          return false;
        }

        let templateId = null;

        if (ctrl.templateId !== defaultTemplateId) {
          templateId = saveSettings.templateId;
        }

        sbInvoiceSettingsHelpers.isValidLogoFileP($scope.uploadedFiles.theFile)
          .then(() => {
            ctrl.uploadingOrFetchingFile = true;
            return sbFirmManagementMbService.uploadLogoP($scope.uploadedFiles.theFile, templateId);
          })
          .then(() => {
            return sbFirmManagementMbService.getLogoUrlP(templateId);
          })
          .then(dataURL => {
            ctrl.syncUpdate({invoiceLogo: dataURL});
          })
          .catch((err) => {
            sbMessageDisplayService.error(err || 'Upload logo file failed.');
          })
          .finally(() => {
            $scope.uploadedFiles = {};
            ctrl.uploadingOrFetchingFile = false;
            angular.element('.logo-upload').val('');
          });
        return true;
      }

      /**
       * To do with uploading the file, we make it .png no matter what they upload - see sbFirmManagement.getLogoP
       */
      function deleteFile() {
        ctrl.deletingFile = true;
        let templateId = null;
        if (ctrl.templateId !== defaultTemplateId) {
          templateId = ctrl.templateId;
        }

        return sbFirmManagementMbService.deleteLogoP({ fileExt: 'png', templateId })
          .then(() => {
            sbMessageDisplayService.success('Invoice logo deleted');
            ctrl.syncUpdate({invoiceLogo: DEFAULT_IMAGE_URL});
            ctrl.deletingFile = false;
          });
      }

      /**
       * upload the button if the user is in the process of uploading a new logo, in the process of deleting a logo or
       * there isnt a logo to upload
       * @returns {boolean}
       */
      function disableLogoUpload() {
        return ctrl.uploadingOrFetchingFile || ctrl.deletingFile || _.isEmpty($scope.uploadedFiles);
      }

      /**
       * disable the button if the user is in the process of uploading a new logo, in the process of deleting a logo or
       * there isnt a logo to delete
       * @returns {boolean}
       */
      function disableLogoDelete() {
        if (ctrl.templateId !== defaultTemplateId) {
          const template = getTemplateById(ctrl.templateId);
          return !template.settings.hasLogo;
        }
        return ctrl.uploadingOrFetchingFile || ctrl.deletingFile || _.isEmpty(ctrl.invoiceLogo) || ctrl.invoiceLogo === DEFAULT_IMAGE_URL;
      }

    }
  }
);
