import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { entryType } from '@sb-billing/business-logic/shared/entities';
import { sort } from '@sb-itops/sort';
import domain from '../domain';

// we are not going to have opdates so far on the cache. We will see if we need some sort of indicator at the end of the feature.
const api = cacheFactory({
  domain,
  name: 'custom-task-code',
  keyPath: 'id',
  ngCacheName: 'sbCustomTaskCodeService',
  syncType: syncTypes.SINCE,
});

const getSortedList = (list) => sort(list, ['code'], ['ASC']);

export const getExpenseCustomTaskCodes = () => {
  const list = getList().filter((v) => !v.isDeleted && v.entryType === entryType.EXPENSE);
  return getSortedList(list);
};

export const getFeeCustomTaskCodes = () => {
  const list = getList().filter((v) => !v.isDeleted && [entryType.FIXED, entryType.TIME].includes(v.entryType));
  return getSortedList(list);
};

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;
