import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'myob-integration',
  keyPath: 'id',
  ngCacheName: 'sbMyobIntegrationService',
  syncType: syncTypes.ALL,
  immutable: false,
});

export const { getMap, getList, updateCache, getById, UPDATE_CACHE, getLastUpdated } = api;
