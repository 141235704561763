const joinArrayWithLast = (arr = [], seperator = ', ', last = ' and ') => {
  if (arr.length <= 2) {
    return arr.join(last);
  }

  const [head, ...tail] = arr;
  return `${tail.join(seperator)}${last}${head}`;
};

/**
 * @typedef {object} Contact
 * @property {string|undefined} salutation
 */

/**
 * Format emails for use in to address field
 * @param {Array.<Contact>} contacts
 * @returns {string}
 */
const formatContactSalutation = (contacts) => {
  const salutations = contacts.reduce((all, c) => {
    if (c.salutation) {
      all.push(c.salutation);
    }
    return all;
  }, []);

  return joinArrayWithLast(salutations, ', ', ' and ');
};

module.exports = {
  formatContactSalutation,
};
