import React from 'react';
import PropTypes from 'prop-types';
import { AccordianMultiSelect } from '@sb-itops/react';
import Styles from './MatterTypeAccordion.module.scss';

export const MatterTypeAccordion = ({ matterTypeTree, selectedMatterTypeIds, onSelectMatterType }) => (
  <div className={Styles.matterTypeAccordion}>
    <AccordianMultiSelect
      tree={matterTypeTree}
      selectedList={selectedMatterTypeIds}
      onSelect={(matterTypeIds) => onSelectMatterType(matterTypeIds)}
    />
  </div>
);

MatterTypeAccordion.displayName = 'MatterTypeAccordion';

MatterTypeAccordion.propTypes = {
  matterTypeTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMatterTypeIds: PropTypes.array,
  onSelectMatterType: PropTypes.func,
};

MatterTypeAccordion.defaultProps = {
  selectedMatterTypeIds: [],
  onSelectMatterType: () => {},
};
