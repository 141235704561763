'use strict';

/**
 * @typedef {object} Option
 * @property {string} value
 * @property {string} label
 */

/**
 * Get a list of matter status options
 * @param {object} params
 * @param {boolean} [params.showDeleted] flag to include Deleted as an option
 * @returns {Option[]}
 */
const getMatterStatusOptions = ({ showDeleted } = { showDeleted: false }) => {
  const matterStatusOptions = [
    { value: 'Open', label: 'Open' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Closed', label: 'Closed' },
  ];

  if (showDeleted) {
    matterStatusOptions.push({ value: 'Deleted', label: 'Deleted' });
  }
  return matterStatusOptions;
};

module.exports = {
  getMatterStatusOptions,
};
