import { formatAddress } from '@sb-customer-management/business-logic/contacts/services/format-address';
import { getSchemeValue } from '@sb-itops/region-schemes';

function getDescendantProp(obj, desc) {
  const arr = desc.split('.');
  // eslint-disable-next-line
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
}

const transformAddressDataToSimple = (address) => formatAddress(address, getSchemeValue('addressScheme'));

export function extractAddresses(contactRelatedEntities) {
  const addresses = [];
  function addAddress(type, name) {
    const address = getDescendantProp(contactRelatedEntities, type);
    if (address) {
      const simpleAddress = transformAddressDataToSimple(address);
      addresses.push({
        type,
        name,
        address: simpleAddress,
      });
    }
  }

  // GraphQL format
  function extractAddress() {
    addAddress('residentialAddress', 'Residential');
    addAddress('mailingAddress', 'Mailing');
    addAddress('businessAddress', 'Business');
    addAddress('forwardingAddress', 'Forwarding');
    addAddress('trustAddress', 'Trust');
  }

  // Legacy formats
  function extractPersonAddresses() {
    addAddress('person.residentialAddress', 'Residential');
    addAddress('person.mailingAddress', 'Mailing');
    addAddress('person.forwardingAddress', 'Forwarding');
  }

  function extractCompanyAddresses() {
    addAddress('company.businessAddress', 'Business');
    addAddress('company.forwardingAddress', 'Forwarding');
    addAddress('company.registeredAddress', 'Registered');
  }

  function extractOrganisationAddresses() {
    addAddress('organisation.businessAddress', 'Postal Address');
  }

  function extractPartnershipAddresses() {
    addAddress('partnership.businessAddress', 'Business');
    addAddress('partnership.mailingAddress', 'Mailing');
    addAddress('partnership.registeredAddress', 'Registered');
  }

  function extractSoleProprietorAddresses() {
    addAddress('soleProprietor.businessAddress', 'Business');
    addAddress('soleProprietor.mailingAddress', 'Mailing');
  }

  function extractTrustAddresses() {
    addAddress('trust.address', 'Trust');
  }
  // NOTE: we assume here that an entity can only be one type at a time
  // so not a company AND a trust for example, so we don't need to check the type...
  // if its there, just use it.
  extractAddress();
  extractPersonAddresses();
  extractCompanyAddresses();
  extractOrganisationAddresses();
  extractPartnershipAddresses();
  extractSoleProprietorAddresses();
  extractTrustAddresses();

  return addresses;
}
