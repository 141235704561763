import { getByChequeNumberBankAccountId } from '@sb-billing/redux/trust-cheques';
import { sort as sortItems } from '@sb-itops/sort';
import Big from 'big.js'

// this do not need to be a parameters since it will be handled by the function. If this changes the whole behaviour would change
const sortBy = ['effectiveDate', 'timestamp'];

/**
 * validate if the cheque number provided is invalid
 * @param {string} chequeNumber - a cheque number
 * @return {bool} if valid or invalid
 */
export const isValidChequeNumber = (chequeNumber) => Number.isFinite(chequeNumber) && chequeNumber > 0;

/**
 * will calculate the next cheque number available based on the cheque number passed
 * @param {string} chequeNumber - cheque number
 * @param {string} bankAccountId - bank account ID
 * @return {number} the next cheque number available
 */
const calculateNextChequeNumber = (chequeNumber, bankAccountId) => {
  let resultNum = chequeNumber;
  while (getByChequeNumberBankAccountId(resultNum, bankAccountId)) {
    resultNum = resultNum.plus(1); // Increment resultNum by 1
  }

  return resultNum;
};

/**
 * A Configuration
 * @typedef {Object} Configuration
 * @property {Object} chequesSelected - object with uuid as props that identifies the selected cheques.
 * @property {string} sortDirection - asc or dsc. default to asc
 * @property {string} firstChequeNumber - the cheque number from which to start. default to 0
 * @property {string} selectedChequeId - uuid for any selected pre-loaded cheque (this will be removed when opdates are added in the trust cheque)
 * @property {number} chequeNumberPadding - the number of padding to add to the cheque number
 * @property {string} bankAccountId - bank account ID we want to check cheque number for
 */

/**
 * generate the cheque number and creates a new array with the cheques and the reference assign based on the cheque configuration.
 * @param {Array.<Cheque>} list of cheques unsorted
 * @param {Configuration} configuration for generating the cheque number
 * @return {Array.<Cheque>} array of cheques with reference value populated
 */
export const assignReferenceNumberToCheques = (
  cheques,
  {
    chequesSelected = {},
    sortDirection = 'asc',
    firstChequeNumberStr = '0',
    selectedChequeId = undefined,
    chequeNumberPadding = undefined,
    bankAccountId = undefined
  },
) => {
  const firstChequeNumberIsInvalid = !isValidChequeNumber(+firstChequeNumberStr);
  const firstChequeNumber = new Big(firstChequeNumberStr);
  if (firstChequeNumberIsInvalid) {
    return {
      cheques: cheques.map((cheque) => ({
        ...cheque,
        reference: undefined,
      })),
      lastChequeNumber: "",
    };
  }

  let lastChequeNumber = firstChequeNumber.minus(1);
  const sortedChequesByOrder = sortItems(cheques, sortBy, [sortDirection, sortDirection]);

  const referencesMap = sortedChequesByOrder.reduce((acc, cheque) => {
    if (
      chequesSelected[cheque.chequeId] ||
      // only select the value if we do not have an entry in the chequesSelected object
      (cheque.chequeId === selectedChequeId && !Object.prototype.hasOwnProperty.call(chequesSelected, cheque.chequeId))
    ) {
      lastChequeNumber = calculateNextChequeNumber(lastChequeNumber.plus(1), bankAccountId);
      acc[cheque.chequeId] = `${lastChequeNumber}`.padStart(chequeNumberPadding, '0');
    }
    return acc;
  }, {});

  // If there are no selected cheques, this should be equal to the firstChequeNumber
  if (firstChequeNumber.minus(1).eq(lastChequeNumber)) {
    lastChequeNumber = firstChequeNumber;
  }

  return {
    cheques: cheques.map((cheque) => ({
      ...cheque,
      reference: referencesMap[cheque.chequeId],
    })),
    lastChequeNumber: `${lastChequeNumber.toString()}`.padStart(chequeNumberPadding, '0'),
  };
};
