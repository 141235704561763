import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { forms2PropTypes, FormLabel } from '@sb-itops/react';
import classnames from 'classnames';
import { BasisPointInput } from '@sb-billing/react/basis-point-input';
import { includeErrorClassnameIfInvalid } from '../include-error-classname-if-invalid';
import styles from './MatterLatePaymentSettings.module.scss';

const interestApplyModeFieldValues = {
  AFTERDUEDATE: 'AfterDueDate',
  AFTERGRACEPERIOD: 'AfterGracePeriod',
};

export const MatterLatePaymentSettings = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form2 fields
  hasInterestRate,
  ratePa,
  interestApplyMode,
  hasGracePeriod,
  gracePeriodDays,
  // callbacks
  onRatePaChange,
  onFieldValuesUpdated,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row form-group">
        <div className="col-sm-6">
          <label className={styles.radioLabel}>
            <div className="input-group">
              <span className="input-group-addon">
                <input
                  type="radio"
                  className={styles.radio}
                  checked={!hasInterestRate.value}
                  value={false}
                  onChange={() => {
                    onFieldValuesUpdated({ 'interestSettings.hasInterestRate': false });
                    onFieldValuesUpdated({ 'interestSettings.hasGracePeriod': false });
                  }}
                />
              </span>
              <span className={classnames('form-control', styles.radioLabel)}>Not Applicable</span>
            </div>
          </label>
        </div>
        <div className="col-sm-6">
          <div className={classnames('input-group', includeErrorClassnameIfInvalid(ratePa, submitFailed))}>
            <span className="input-group-addon">
              <input
                type="radio"
                checked={hasInterestRate.value}
                value
                onChange={() => onFieldValuesUpdated({ 'interestSettings.hasInterestRate': true })}
              />
            </span>
            <BasisPointInput basisPoints={ratePa.value} disabled={!hasInterestRate.value} onChange={onRatePaChange} />
          </div>
        </div>
      </div>
      {hasInterestRate.value && (
        <>
          <FormLabel label="Grace Period" optional />
          <div className="row form-group">
            <div className="col-sm-6">
              <label className={styles.radioLabel}>
                <div className="input-group">
                  <span className="input-group-addon">
                    <input
                      type="radio"
                      className={styles.radio}
                      checked={!hasGracePeriod.value}
                      value={false}
                      onChange={() => onFieldValuesUpdated({ 'interestSettings.hasGracePeriod': false })}
                    />
                  </span>
                  <span className={classnames('form-control', styles.radioLabel)}>None</span>
                </div>
              </label>
            </div>
            <div className="col-sm-6">
              <div className={classnames('input-group', includeErrorClassnameIfInvalid(gracePeriodDays, submitFailed))}>
                <span className="input-group-addon">
                  <input
                    type="radio"
                    checked={hasGracePeriod.value}
                    value
                    onChange={() => onFieldValuesUpdated({ 'interestSettings.hasGracePeriod': true })}
                  />
                </span>
                <input
                  type="number"
                  className={classnames('form-control')}
                  placeholder="Example: 10, 20"
                  value={gracePeriodDays.value || ''}
                  disabled={!hasGracePeriod.value}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    onFieldValuesUpdated({
                      'interestSettings.gracePeriodDays': Number.isFinite(parseInt(e.target.value, 10))
                        ? parseInt(e.target.value, 10)
                        : undefined,
                    })
                  }
                />
                <span className="input-group-addon">Days</span>
              </div>
            </div>
          </div>
          {hasGracePeriod.value && (
            <>
              <FormLabel label="Calculate Interest From" optional />
              <div className="row form-group">
                <div className="col-sm-6">
                  <label className={styles.radioLabel}>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <input
                          type="radio"
                          className={styles.radio}
                          checked={interestApplyMode.value === interestApplyModeFieldValues.AFTERDUEDATE}
                          value={interestApplyModeFieldValues.AFTERDUEDATE}
                          onChange={(e) =>
                            onFieldValuesUpdated({ 'interestSettings.interestApplyMode': e.target.value })
                          }
                        />
                      </span>
                      <span className={classnames('form-control', styles.radioLabel)}>Invoice Due Date</span>
                    </div>
                  </label>
                </div>
                <div className="col-sm-6">
                  <label className={styles.radioLabel}>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <input
                          type="radio"
                          className={styles.radio}
                          checked={interestApplyMode.value === interestApplyModeFieldValues.AFTERGRACEPERIOD}
                          value={interestApplyModeFieldValues.AFTERGRACEPERIOD}
                          onChange={(e) =>
                            onFieldValuesUpdated({ 'interestSettings.interestApplyMode': e.target.value })
                          }
                        />
                      </span>
                      <span className={classnames('form-control', styles.radioLabel)}>After Grace Period</span>
                    </div>
                  </label>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterLatePaymentSettings.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form2 fields,
  hasInterestRate: Forms2Field,
  ratePa: Forms2Field,
  interestApplyMode: Forms2Field,
  hasGracePeriod: Forms2Field,
  gracePeriodDays: Forms2Field,
  // callbacks
  onRatePaChange: PropTypes.func.isRequired,
  onFieldValuesUpdated: PropTypes.func.isRequired,
};

MatterLatePaymentSettings.defaultProps = {
  // form state
  formDisabled: false,
  formSubmitting: false,
  submitFailed: false,
  // form2 fields,
  hasInterestRate: undefined,
  ratePa: undefined,
  interestApplyMode: undefined,
  hasGracePeriod: undefined,
  gracePeriodDays: undefined,
};

MatterLatePaymentSettings.displayName = 'MatterLatePaymentSettings';
