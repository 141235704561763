import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import PropTypes from 'prop-types';
import * as depositSlipsFilters from 'web/redux/route/home-billing-accounts-deposit-slips';
import { getTrustAccounts, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { sortByProperty } from '@sb-itops/nodash';
import DepositSlipsFilterPanel from './DepositSlipsFilterPanel';

const mapStateToProps = (state, { trustAccountId, t }) => {
  const { getShowFilters } = depositSlipsFilters.selectors;
  const expanded = getShowFilters(state);

  const trustAccounts = getTrustAccounts().map((trust) => ({
    label: getBankAccountName(trust, t),
    id: trust.id,
    // trust.state cannot be trusted if multi trust switch is off, this translates legacy isInactive to state so it can now be trusted
    status: isTrustAccountClosed(trust) ? BANK_ACCOUNT_STATE.CLOSED : BANK_ACCOUNT_STATE.OPEN,
  }));
  const activeTrustAccounts = sortByProperty(
    trustAccounts.filter((trust) => trust.status === BANK_ACCOUNT_STATE.OPEN),
    'label',
    'asc',
    false,
  );

  const selectedTrustAccountId = trustAccountId;

  const trustAccountsFilterPanelConfig = {
    overviewOnClicklinkParams: { id: 'trust', type: 'depositSlips' },
    showProtectedFunds: false,
    parentPageName: 'home.billing.accounts.deposit-slips',
    getSpecificOnClickLinkParams: (trustId) => ({
      type: 'depositSlipsSpecificAccount',
      id: trustId,
    }),
  };

  return {
    expanded,
    showOperatingAccount: hasFacet(facets.operatingAccount),
    trustAccounts: activeTrustAccounts,
    selectedTrustAccountId,
    panelHeader: 'View',
    trustAccountsFilterPanelConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(depositSlipsFilters.actions.toggleShowFilters()),
});

const DepositSlipsFilterPanelContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DepositSlipsFilterPanel)),
);

DepositSlipsFilterPanelContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  trustAccountId: PropTypes.string,
};

DepositSlipsFilterPanelContainer.defaultProps = {
  accountType: undefined,
  trustAccountId: undefined,
};

export default DepositSlipsFilterPanelContainer;
