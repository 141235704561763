function findOtherSideRole (matterTypeConfiguration, clientRole) {
  // To determine the "Other side" role:
  // First you need to know which role will be the client.
  // Look at MatterTypeConfiguration, DefaultOtherSide field:
  // If DefaultOtherSide is a different role than the client, use it.
  // If DefaultOtherSide is the same role as the client, use the first role from the PossibleClients collection which is not the client.
  // If no role exists matching the above, there will be no "other side" role.
  const { possibleClients, defaultOtherSide } = matterTypeConfiguration;
  if (defaultOtherSide && defaultOtherSide !== clientRole) {
    return defaultOtherSide;
  }

  const possibleClientsExcludingClientRole = possibleClients
    && possibleClients.length
    && possibleClients.filter((possibleClient) => possibleClient !== clientRole);

  // return first non client role
  return possibleClientsExcludingClientRole && possibleClientsExcludingClientRole.length
    ? possibleClientsExcludingClientRole[0]
    : undefined;
}

module.exports = {
  findOtherSideRole,
};
