import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@sb-itops/react/modal';
import { Button } from '@sb-itops/react/button';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { ApplyPaymentsForm } from 'web/react-redux/components/apply-payments-form';
import Styles from './ContactBalanceAllocationModal.module.scss';
import { applyAutoPayments } from './apply-auto-payments';

export const ContactBalanceAllocationModal = memo(
  ({
    trustAllocations,
    onChangeTrustAllocations,
    operatingAllocations,
    onChangeOperatingAllocations,
    creditAllocations,
    onChangeCreditAllocations,
    totalAmount,
    hasError,

    onChangeAllocations,
    onModalClose,
    onContactLinkClick,
    invoiceTotals,
    preferredBankAccountTypes,
  }) => (
    <Modal
      onModalClose={onModalClose}
      body={
        <ApplyPaymentsForm
          hasError={hasError}
          totalAmount={totalAmount}
          trustAllocations={trustAllocations}
          operatingAllocations={operatingAllocations}
          creditAllocations={creditAllocations}
          onContactLinkClick={onContactLinkClick}
          onChangeTrustAllocations={onChangeTrustAllocations}
          onChangeOperatingAllocations={onChangeOperatingAllocations}
          onChangeCreditAllocations={onChangeCreditAllocations}
          invoiceTotals={invoiceTotals}
        />
      }
      title="Apply Payments"
      footer={
        <div className={Styles.footerRow}>
          <Button
            className={Styles.saveButton}
            onClick={() => {
              onChangeAllocations(trustAllocations, operatingAllocations, creditAllocations);
              onModalClose();
            }}
            disabled={hasError}
          >
            Apply Allocations
          </Button>
          <div className={Styles.footerLinkButtons}>
            <LinkableText
              className={Styles.autoAllocateButton}
              text="Apply Auto-Allocation Values"
              onClickLink={() => {
                const { trust, operating, credit } = applyAutoPayments(
                  invoiceTotals,
                  trustAllocations,
                  operatingAllocations,
                  creditAllocations,
                  preferredBankAccountTypes,
                );
                onChangeTrustAllocations(trust);
                onChangeOperatingAllocations(operating);
                onChangeCreditAllocations(credit);
              }}
              asLink
            />
            <LinkableText
              className={Styles.clearButton}
              text="Clear Values"
              onClickLink={() => {
                onChangeTrustAllocations(clearAllocations(trustAllocations));
                onChangeOperatingAllocations(clearAllocations(operatingAllocations));
                onChangeCreditAllocations(clearAllocations(creditAllocations));
              }}
              asLink
            />
          </div>
        </div>
      }
    />
  ),
);

const clearAllocations = (allocations) =>
  allocations.map((allocation) => ({
    ...allocation,
    amount: 0,
  }));

ContactBalanceAllocationModal.displayName = 'ContactBalanceAllocationModal';

ContactBalanceAllocationModal.propTypes = {
  onChangeAllocations: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  invoiceTotals: PropTypes.object,
  preferredBankAccountTypes: PropTypes.array.isRequired,
  totalAmount: PropTypes.number.isRequired,
  hasError: PropTypes.bool.isRequired,

  trustAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  operatingAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  creditAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  onChangeTrustAllocations: PropTypes.func.isRequired,
  onChangeOperatingAllocations: PropTypes.func.isRequired,
  onChangeCreditAllocations: PropTypes.func.isRequired,
};

ContactBalanceAllocationModal.defaultProps = {
  trustAllocations: [],
  operatingAllocations: [],
  creditAllocations: [],
  invoiceTotals: undefined,
};
