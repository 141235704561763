angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-invoice-statement/payments';

  $stateProvider
    .state('home.billing.view-invoice-statement.payments', {
      url: '/payments',
      templateUrl: BASE_DIRECTORY + '/view-invoice-statement-payments.html',
      data: {
        authorized: true,
        navHighlights: ['billing', 'invoice-statement'],
        tab: { type: 'invoice-statement' }
      }
    });

});
