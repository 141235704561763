'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import {
  updateCache as updateRedux,
  clearCache as clearRedux,
  getList,
  getById,
  getLatest,
  getLastCompleted,
  isReconciled,
} from '@sb-billing/redux/bank-reconciliations';

const getAccountId = () => authSelectors.getAccountId(store.getState());

angular.module('@sb-billing/services').service('sbBankReconciliationService', function (sbGenericEndpointService, sbGenericCacheService, sbEndpointType,
  sbUuidService) {
  const that = this;
  const endpointMultiple = 'billing/bank-reconciliations';
  const endpointSingle = 'billing/bank-reconciliation';
  const endpointPreview = 'billing/bank-reconciliation-pdf-preview'

  sbGenericCacheService.setupCache({
    name: 'sbBankReconciliationService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpointMultiple },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.BankReconciliationUpdated',
    },
    updateRedux,
    clearRedux,
  });

  that.filterBy = filterBy;
  that.get = getList;
  that.getById = getById;
  that.getLatest = getLatest;
  that.getLastCompleted = getLastCompleted;
  that.isReconciled = isReconciled;
  that.saveP = saveP;
  that.cancelP = cancelP;
  that.getDraftPreviewP = getDraftPreviewP;

  function filterBy(filters) {
    const jsonFilters = typeof filters === 'string' ? JSON.parse(filters) : filters; //TODO doesnt feel right here
    return _.filter(that.get(), jsonFilters);
  }

  // ReconciliationId, StartDate, EndDate, BankStatementBalance, FinalizeReconciliation,
  // FinalizeReconciliation, ReconciledTimestamp, TransactionIds, DepositSlipIds
  function saveP(data) {
    // TODO change in endpoint that - make it expect id rather than reconciliationId - TH
    data.reconciliationId = data.reconciliationId || sbUuidService.get();

    data.id = data.reconciliationId;

    if (data.finalizeReconciliation) {
      data.reconciledTimestamp = moment().toISOString();
    }
    data.reconciledDate = moment().format('YYYYMMDD');

    return sbGenericEndpointService.postPayloadP(endpointSingle, 'save', data, 'POST', { changeset: { sbBankReconciliationService: [{ ...data, status: getStatus(data) }] } });
  }

  function getStatus (bankRec) {
    if (bankRec.finalizeReconciliation) {
      return 'Completed';
    }

    return 'InProgress';
  }

  function cancelP(reconciliationId, note = '') {
    if (_.isEmpty(reconciliationId)) {
      return Promise.resolve();
    }
    const changeset = { sbBankReconciliationService: [{ accountId: getAccountId(), id: reconciliationId, status: 'Cancelled' }] };
    return sbGenericEndpointService.postPayloadP(endpointSingle, 'cancel', { reconciliationId, note }, 'POST', { changeset });
  }

  function getDraftPreviewP(data) {
    const previewId = sbUuidService.get();
    data.previewId = previewId;
    return sbGenericEndpointService.postPayloadP(endpointPreview, previewId, data, 'POST', {});
  }
});
