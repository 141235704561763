import PropTypes from 'prop-types';
import React from 'react';
import { Spinner, Modal, Button, buttonTypes } from '@sb-itops/react';
import { providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import Styles from './EzyCollectAccountManagementPanel.module.scss';

export const EzyCollectAccountManagementPanel = (props) => {
  const {
    showConfirmDisconnect,
    linkedAccount,
    bankAccountType,
    bankAccountId,
    disabled,
    isConnecting,
    isDisconnecting,
    isManagementPortalLoading,
    onConnectAccount,
    onContinueOnboarding,
    onManageAccount,
    onRequestDisconnectAccount,
  } = props;

  // No ezy collect account has been created for this bank account yet, show button to create new account.
  if (!linkedAccount) {
    return (
      <div className={Styles.ezyCollectAccountManagementPanel}>
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onConnectAccount({ bankAccountId, bankAccountType })}
          disabled={disabled}
        >
          Connect with {providerNames.EZY_COLLECT}
          {'  '}
          {isConnecting && <Spinner small />}
        </Button>
      </div>
    );
  }

  // Ezy collect onboarding has been started for this bank account, but not completed.
  // Provide them with a button to continue with the on-boarding process.
  if (!linkedAccount?.onBoarding?.isComplete) {
    return (
      <div className={Styles.ezyCollectAccountManagementPanel}>
        {showConfirmDisconnect && getConfirmDisconnectModal(props)}
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onContinueOnboarding(linkedAccount)}
          disabled={disabled}
        >
          Continue {providerNames.EZY_COLLECT} Account Setup
        </Button>
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onRequestDisconnectAccount()}
          disabled={disabled}
        >
          Disconnect {providerNames.EZY_COLLECT} Account
          {'  '}
          {isDisconnecting && <Spinner small />}
        </Button>
      </div>
    );
  }

  // Otherwise, allow the user to access the dashboard to manage other shenanigans.
  if (linkedAccount?.onBoarding?.isComplete) {
    return (
      <div className={Styles.ezyCollectAccountManagementPanel}>
        {showConfirmDisconnect && getConfirmDisconnectModal(props)}
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onManageAccount({ bankAccountId })}
          disabled={disabled || isManagementPortalLoading}
        >
          Manage {providerNames.EZY_COLLECT} Account
          {'  '}
          {isManagementPortalLoading && <Spinner small />}
        </Button>
        <Button
          className={Styles.linkButton}
          type={buttonTypes.link}
          onClick={() => onRequestDisconnectAccount()}
          disabled={disabled || isManagementPortalLoading}
        >
          Disconnect {providerNames.EZY_COLLECT} Account
          {'  '}
          {isDisconnecting && <Spinner small />}
        </Button>
      </div>
    );
  }

  return null;
};

function getConfirmDisconnectModal(props) {
  const { showConfirmDisconnect, bankAccountType, linkedAccount } = props;
  const { onDisconnectAccount, onCancelDisconnectAccount } = props;
  return (
    <Modal
      className={Styles.accountDisconnectModal}
      isVisible={showConfirmDisconnect}
      title={`Confirm ${providerNames.EZY_COLLECT} Account Disconnection`}
      body={
        <div>
          <p>
            Are you sure you want to disconnect your {bankAccountType} {providerNames.EZY_COLLECT} account from
            Smokeball?
          </p>
          <p>
            If you disconnect this account, you will need to create a new {providerNames.EZY_COLLECT} account to
            reconnect with Smokeball.
          </p>
        </div>
      }
      footer={
        <div className={Styles.buttonPanel}>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.secondary}
            onClick={() => onCancelDisconnectAccount()}
          >
            Cancel
          </Button>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.warning}
            onClick={() => onDisconnectAccount(linkedAccount)}
          >
            Confirm Disconnect
          </Button>
        </div>
      }
      onModalClose={onCancelDisconnectAccount}
    />
  );
}

EzyCollectAccountManagementPanel.displayName = 'EzyCollectAccountManagementPanel';

EzyCollectAccountManagementPanel.propTypes = {
  showConfirmDisconnect: PropTypes.bool.isRequired,
  linkedAccount: PropTypes.object,
  bankAccountType: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  isDisconnecting: PropTypes.bool.isRequired,
  isManagementPortalLoading: PropTypes.bool.isRequired,
  onConnectAccount: PropTypes.func,
  onContinueOnboarding: PropTypes.func,
  onManageAccount: PropTypes.func,
  onRequestDisconnectAccount: PropTypes.func,
  onCancelDisconnectAccount: PropTypes.func,
  onDisconnectAccount: PropTypes.func,
};

EzyCollectAccountManagementPanel.defaultProps = {
  linkedAccount: undefined,
  onConnectAccount: () => {},
  onContinueOnboarding: () => {},
  onManageAccount: () => {},
  onRequestDisconnectAccount: () => {},
  onCancelDisconnectAccount: () => {},
  onDisconnectAccount: () => {},
};
