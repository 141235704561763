'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const billingFrequencyHoldSubType = Object.freeze({
  HOLD: 40,
});

const billingFrequencyHoldSubTypeLabels = Object.freeze({
  HOLD: 'Hold',
});

const billingFrequencyHoldSubTypeOptions = getTypeAsOptions({
  typeLabels: billingFrequencyHoldSubTypeLabels,
  typeValues: billingFrequencyHoldSubType,
});

module.exports = {
  billingFrequencyHoldSubType,
  billingFrequencyHoldSubTypeLabels,
  billingFrequencyHoldSubTypeOptions,
};
