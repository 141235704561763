import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './LoadingBarInfinite.module.scss';

// Loading bar for when we don't know how long an operation will take
export const LoadingBarInfinite = ({
  loading,
  containerClassName,
  containerStyle,
  progressClassName,
  progressStyle,
}) => {
  const mergedContainerStyle = {
    ...containerStyle,
    display: loading ? 'block' : 'none',
  };

  return (
    <div className={classnames(Styles.loadingBarInfiniteContainer, containerClassName)} style={mergedContainerStyle}>
      <div className={classnames(Styles.loadingBarInfiniteProgress, progressClassName)} style={progressStyle} />
    </div>
  );
};

LoadingBarInfinite.displayName = 'LoadingBarInfinite';

LoadingBarInfinite.propTypes = {
  loading: PropTypes.bool.isRequired,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  progressClassName: PropTypes.string,
  progressStyle: PropTypes.object,
};
LoadingBarInfinite.defaultProps = {
  containerClassName: undefined,
  containerStyle: {},
  progressClassName: undefined,
  progressStyle: undefined,
};
