import { connect } from 'react-redux';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { withReduxStore } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import * as forms from '@sb-itops/redux/forms2';
import PropTypes from 'prop-types';
import { createBankAccount, saveBankAccount, ACCOUNT_TYPE } from '@sb-billing/redux/bank-account';
import uuid from '@sb-itops/uuid';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { getRegion } from '@sb-itops/region';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { bankAccountSaveAction } from '@sb-billing/business-logic/bank-account/entities/constants';
import { ControlledMoneyAccountAddEditModal } from './ControlledMoneyAccountAddEditModal';
import { AddEditControlledMoneyAccountSchema } from '../controlled-money-account-add-edit';

const scope = 'add-edit-controlled-money-account';

const mapStateToProps = (state, { isVisible, matterId }) => {
  const { selectors: formSelectors } = withScopedFeature({ state, scope })(forms);
  const { formSubmitting } = formSelectors.getFormState(state);
  const { clientCustomerIds = [] } = getMatterById(matterId) || {};

  return {
    clientCustomerIds,
    scope,
    isVisible,
    isSubmitDisabled: formSubmitting,
  };
};

const mapDispatchToProps = (dispatch, { onClose, onAccountClose, onClickLink, matterId, accountId }) => {
  const { operations: formOperations } = withScopedFeature({ scope })(forms);
  const bankBranchNumberRegex = getBankAccountStrategy(getRegion(), bankAccountStrategies.BRANCH_NUMBER_REGEX);

  return {
    onAccountClose: async (event) => {
      event.preventDefault();
      onAccountClose();
    },
    onSave: async (event) => {
      event.preventDefault();
      await dispatch(
        formOperations.validateSchema({ schema: AddEditControlledMoneyAccountSchema({ bankBranchNumberRegex }) }),
      );

      const isEdit = !!accountId;

      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formFieldValues) => {
              if (isEdit) {
                await edit({ formFieldValues, matterId, accountId });
              } else {
                const newAccountId = await create({ formFieldValues, matterId });
                onClickLink({ type: 'controlledMoneyAccount', id: newAccountId });
              }

              onClose();
              messageDisplay.success('Account details saved successfully.');
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to save the account');
      }
    },
  };
};

const edit = async ({ formFieldValues, matterId, accountId }) => {
  const { accountOpenedDate, bankName, accountName, branchNumber, accountNumber, beneficiaryIds } = formFieldValues;

  await saveBankAccount({
    id: accountId,
    accountType: ACCOUNT_TYPE.controlled_money.toUpperCase(),
    accountOpenedDate,
    bankName,
    accountName,
    branchNumber,
    accountNumber,
    beneficiaryIds,
    associatedMatterId: matterId,
    action: bankAccountSaveAction.UPDATE,
  });
};
const create = async ({ formFieldValues, matterId }) => {
  const { accountOpenedDate, bankName, accountName, branchNumber, accountNumber, beneficiaryIds } = formFieldValues;
  const newAccountId = uuid();

  await createBankAccount({
    id: newAccountId,
    accountType: ACCOUNT_TYPE.controlled_money.toUpperCase(),
    accountOpenedDate,
    bankName,
    accountName,
    branchNumber,
    accountNumber,
    beneficiaryIds,
    associatedMatterId: matterId,
  });

  return newAccountId;
};

export const ControlledMoneyAccountAddEditModalContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(ControlledMoneyAccountAddEditModal),
);

ControlledMoneyAccountAddEditModalContainer.displayName = 'ControlledMoneyAccountAddEditModalContainer';

ControlledMoneyAccountAddEditModalContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  accountId: PropTypes.string,
  onAccountClose: PropTypes.func,
  onClickLink: PropTypes.func,
};

ControlledMoneyAccountAddEditModalContainer.defaultProps = {
  accountId: undefined,
  onAccountClose: () => {},
  onClickLink: () => {},
};

export default ControlledMoneyAccountAddEditModalContainer;
