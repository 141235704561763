import { SELECT_DATE_RANGE_TYPE, SELECT_BEFORE_DATE, SELECT_FROM_DATE, SELECT_TO_DATE } from './types';

export const selectDateRangeType = ({ dateRangeType }) => {
  if (!dateRangeType) {
    throw new Error('dateRangeType is required');
  }

  return {
    type: SELECT_DATE_RANGE_TYPE,
    payload: { dateRangeType },
  };
};

export const selectBeforeDate = ({ beforeDate }) => {
  if (!beforeDate) {
    throw new Error('beforeDate is required');
  }
  return {
    type: SELECT_BEFORE_DATE,
    payload: { beforeDate },
  };
};

export const selectFromDate = ({ fromDate }) => {
  if (!fromDate) {
    throw new Error('fromDate is required');
  }
  return {
    type: SELECT_FROM_DATE,
    payload: { fromDate },
  };
};

export const selectToDate = ({ toDate }) => {
  if (!toDate) {
    throw new Error('toDate is required');
  }

  return {
    type: SELECT_TO_DATE,
    payload: { toDate },
  };
};
