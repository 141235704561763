import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ModalDialog } from '@sb-itops/react/modal-dialog';

const ClosePaymentPlanModalDialog = memo(({ onClosePaymentPlan, closePaymentPlanModalId }) => (
  <ModalDialog
    modalId={closePaymentPlanModalId}
    body={
      <div>
        <p>Are you sure you want to close this payment plan?</p>
      </div>
    }
    title="Close Plan"
    buttons={{
      rightAlign: true,
      primary: {
        text: 'Yes',
      },
      secondary: {
        text: 'No',
      },
    }}
    onPrimaryClick={onClosePaymentPlan}
  />
));

ClosePaymentPlanModalDialog.displayName = 'ClosePaymentPlanModalDialog';

ClosePaymentPlanModalDialog.propTypes = {
  onClosePaymentPlan: PropTypes.func.isRequired,
  closePaymentPlanModalId: PropTypes.string.isRequired,
};

ClosePaymentPlanModalDialog.defaultProps = {};

export default ClosePaymentPlanModalDialog;
