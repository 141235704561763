import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './PercentageInput.module.scss';

export const PercentageInput = ({
  name,
  percent,
  maxPercent,
  placeholder,
  disabled,
  hasError,
  showPercent,
  isPercentPerAnnum,
  step,
  // callbacks
  onChange,
  onFocus,
  onBlur,
}) => (
  <div className={classnames('input-group', styles.percentageInput, hasError && styles.hasError)}>
    <input
      name={name}
      className="form-control currency"
      type="number"
      value={percent}
      min="0"
      max={maxPercent}
      step={step}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      onFocus={(e) => onFocus(e)}
      onBlur={() => onBlur()}
    />
    {showPercent && (
      <div className="input-group-addon">
        <i className={styles.percentIcon} />
        {isPercentPerAnnum && <span className={styles.percentText}>p.a</span>}
      </div>
    )}
  </div>
);

PercentageInput.propTypes = {
  name: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([''])]),
  maxPercent: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  showPercent: PropTypes.bool,
  isPercentPerAnnum: PropTypes.bool,
  step: PropTypes.string,
  // callbacks
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

PercentageInput.defaultProps = {
  name: undefined,
  percent: '',
  maxPercent: undefined,
  placeholder: undefined,
  disabled: false,
  hasError: false,
  showPercent: true,
  isPercentPerAnnum: false,
  step: '0.01',
  onFocus: () => {},
  onBlur: () => {},
};
