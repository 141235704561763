import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@sb-itops/react';
import Styles from './InvoiceCommunicateModal.module.scss';
import { InvoiceCommunicateTabPerDebtorForm } from '../invoice-communicate-tab-per-debtor-form';
import { InvoiceCommunicateForm } from '../invoice-communicate-form';

export const InvoiceCommunicateModal = React.memo(
  ({
    isVisible,
    isSubmitDisabled,
    scope,
    useMultiTabForm,
    invoiceIds,
    debtorIds,
    matterIds,
    onSend,
    onPreview,
    onClose,
    isMultiDebtorInvoice,
    paymentProviderEnabledForInvoicePayment,
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        className={Styles.invoiceCommunicateModal}
        isVisible={isVisible}
        title="Send Secure Client Portal Message"
        body={getModalBody({
          scope,
          useMultiTabForm,
          invoiceIds,
          debtorIds,
          matterIds,
          onPreview,
          isMultiDebtorInvoice,
          paymentProviderEnabledForInvoicePayment,
        })}
        footer={
          <div>
            <Button onClick={onSend} disabled={isSubmitDisabled}>
              Send Now
            </Button>
          </div>
        }
        onModalClose={onClose}
      />
    );
  },
);

const getModalBody = ({
  scope,
  useMultiTabForm,
  invoiceIds,
  debtorIds,
  onPreview,
  matterIds,
  isMultiDebtorInvoice,
  paymentProviderEnabledForInvoicePayment,
}) => {
  if (useMultiTabForm) {
    return (
      <div className={Styles.multiTabForm}>
        <InvoiceCommunicateTabPerDebtorForm
          scope={scope}
          invoiceId={invoiceIds[0]}
          matterId={matterIds[0]}
          debtorIds={debtorIds}
          onPreview={onPreview}
        />
      </div>
    );
  }

  return (
    <div className={Styles.standardForm}>
      <InvoiceCommunicateForm
        scope={scope}
        invoiceIds={invoiceIds}
        debtorIds={debtorIds}
        matterIds={matterIds}
        onPreview={onPreview}
        isMultiDebtorInvoice={isMultiDebtorInvoice}
        paymentProviderEnabledForInvoicePayment={paymentProviderEnabledForInvoicePayment}
      />
    </div>
  );
};

InvoiceCommunicateModal.displayName = 'InvoiceCommunicateModal';

InvoiceCommunicateModal.propTypes = {
  isVisible: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  scope: PropTypes.string,
  useMultiTabForm: PropTypes.bool,
  invoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  debtorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSend: PropTypes.func.isRequired,
  onPreview: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isMultiDebtorInvoice: PropTypes.bool.isRequired,
  paymentProviderEnabledForInvoicePayment: PropTypes.bool.isRequired,
};

InvoiceCommunicateModal.defaultProps = {
  isVisible: false,
  isSubmitDisabled: false,
  scope: 'invoice-communicate-modal',
  useMultiTabForm: false,
  onPreview: undefined,
};
