import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { sendStatuses } from '@sb-billing/business-logic/correspondence-history';
import { MultiContactCorrespondenceDetails } from 'web/components/multi-contact-correspondence-details';
import Styles from './InvoiceCorrespondenceSendStatus.module.scss';

const sendClassesByStatus = {
  [sendStatuses.IN_PROGRESS]: classnames('icon-sync-2', Styles.blueText),
  [sendStatuses.SUCCESS]: classnames('icon-check-circle-2', Styles.greenText),
  [sendStatuses.ERROR]: classnames('icon-alert-2', Styles.redText),
};

export const InvoiceCorrespondenceSendStatus = ({
  invoiceId,
  sendStatus,
  allowResend,
  onResendClicked,
  latestCorrespondencePerContact,
  latestCorrespondenceLoading,
  onFetchLatestCorrespondenceDetailsForInvoice,
}) => {
  if (sendStatus === sendStatuses.NOT_SENT) {
    return null;
  }

  const onClick = () => onFetchLatestCorrespondenceDetailsForInvoice({ invoiceId });

  const messageBody = () => (
    <MultiContactCorrespondenceDetails
      invoiceId={invoiceId}
      allowResend={allowResend}
      onResendClicked={onResendClicked}
      latestCorrespondencePerContact={latestCorrespondencePerContact}
      loading={latestCorrespondenceLoading}
    />
  );

  return (
    <div className={Styles.invoiceCorrespondenceSendStatus}>
      <div className={Styles.iconContainer}>
        <ContextMenu body={messageBody}>
          <i className={sendClassesByStatus[sendStatus]} onClick={onClick} />
        </ContextMenu>
      </div>
    </div>
  );
};

InvoiceCorrespondenceSendStatus.displayName = 'InvoiceCorrespondenceSendStatus';

InvoiceCorrespondenceSendStatus.propTypes = {
  invoiceId: PropTypes.string,
  sendStatus: PropTypes.number.isRequired,
  allowResend: PropTypes.bool,
  latestCorrespondenceLoading: PropTypes.bool,
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object), // CorrespondenceHistory entity,
  onFetchLatestCorrespondenceDetailsForInvoice: PropTypes.func.isRequired,
  onResendClicked: () => {},
};

InvoiceCorrespondenceSendStatus.defaultProps = {
  invoiceId: undefined,
  onResendClicked: () => {},
  allowResend: false,
  latestCorrespondenceLoading: undefined,
  latestCorrespondencePerContact: undefined,
};

export default InvoiceCorrespondenceSendStatus;
