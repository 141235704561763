import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyInput2, IconButton } from '@sb-itops/react';
import { Radio, RadioList } from '@sb-itops/react/radio-list';

const DiscountOptions = ({
  absoluteDiscount,
  percentageDiscount,
  discountType,
  maxValue,
  onConfirm,
  onDiscard,
  onDiscountTypeChange,
  onAbsoluteDiscountChange,
  onPercentageDiscountChange,
  disableConfirm,
}) => (
  <div className="discount-options">
    <div className="content-container">
      <RadioList selected={String(discountType)} name="discount" direction="column" onChange={onDiscountTypeChange}>
        <Radio value="0">
          <CurrencyInput2
            name="amount"
            value={absoluteDiscount}
            onChange={onAbsoluteDiscountChange}
            min={0}
            max={maxValue}
          />
        </Radio>
        <Radio value="1">
          <div className="input-group">
            <input
              type="number"
              min={0}
              max={100}
              step={1}
              value={percentageDiscount}
              className="form-control"
              onFocus={(e) => e.target.select()}
              onChange={onPercentageDiscountChange}
            />
            <span className="input-group-addon bold">%</span>
          </div>
        </Radio>
      </RadioList>
    </div>
    <div className="button-inline-container">
      <IconButton onClick={onConfirm} icon="icon icon-check-circle-2" round disabled={disableConfirm} />
      <span className="button-margin">
        <IconButton onClick={onDiscard} icon="icon icon-close-x-circle" round />
      </span>
    </div>
  </div>
);

DiscountOptions.displayName = 'DiscountOptions';
DiscountOptions.propTypes = {
  absoluteDiscount: PropTypes.number,
  percentageDiscount: PropTypes.number,
  discountType: PropTypes.number,
  maxValue: PropTypes.number,
  onConfirm: PropTypes.func,
  onDiscard: PropTypes.func,
  onDiscountTypeChange: PropTypes.func,
  onAbsoluteDiscountChange: PropTypes.func,
  onPercentageDiscountChange: PropTypes.func,
  disableConfirm: PropTypes.bool,
};

DiscountOptions.defaultProps = {
  absoluteDiscount: 0,
  percentageDiscount: 0,
  discountType: 0,
  maxValue: 0,
  onConfirm: () => {},
  onDiscard: () => {},
  onDiscountTypeChange: () => {},
  onAbsoluteDiscountChange: () => {},
  onPercentageDiscountChange: () => {},
  disableConfirm: false,
};

export default DiscountOptions;
