/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PanelExpander } from '@sb-itops/react';
import { TrustAccountsForFilterPanels } from '@sb-billing/react/trust-accounts-filter-panel';

const EndOfMonthFilterPanel = memo(
  ({
    onClickLink,
    accountType,
    expanded,
    toggleExpanded,
    activeTrustAccounts,
    selectedTrustAccountId,
    trustAccountsFilterPanelConfig,
  }) => (
    <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
      <div className="side-menu-block">
        <h3>View</h3>
        <ul className="side-menu-list capitalize">
          <TrustAccountsForFilterPanels
            trustAccounts={activeTrustAccounts}
            onClickLink={onClickLink}
            selectedTrustAccountId={selectedTrustAccountId}
            selectedAccountType={accountType}
            {...trustAccountsFilterPanelConfig}
          />
        </ul>
      </div>
    </PanelExpander>
  ),
);

EndOfMonthFilterPanel.displayName = 'EndOfMonthFilterPanel';

EndOfMonthFilterPanel.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  activeTrustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedTrustAccountId: PropTypes.string,
  accountType: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  trustAccountsFilterPanelConfig: PropTypes.shape({
    showProtectedFunds: PropTypes.bool.isRequired,
    overviewOnClicklinkParams: PropTypes.object.isRequired,
    getSpecificOnClickLinkParams: PropTypes.func.isRequired,
  }).isRequired,
};

EndOfMonthFilterPanel.defaultProps = {
  accountType: undefined,
  selectedTrustAccountId: undefined,
};

export default EndOfMonthFilterPanel;
