
angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts';

  $stateProvider
    .state('home.billing.accounts', {
      url: '/accounts',
      templateUrl: BASE_DIRECTORY + '/accounts.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
      },
    });
});
