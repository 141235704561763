import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Styles from './SmokeballSpinner.module.scss';

export const SmokeballSpinner = memo(({ message }) => (
  <div className={Styles.smokeballSpinner}>
    <div className={Styles.spinnerCircle}>
      <svg id={Styles.sbSpinnerAnimation} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 356.5 335.2">
        <g id={Styles.sbSpinnerGroupSvg}>
          <path
            d="M70.1,301.9C27.5,269.8,0,219.2,0,162.2,0,97.5,35.4,41,88.1,10.4A119.49,119.49,0,0,0,80,28c-15.3,44.4,8.5,100.9,82,126.8,55.8,20.1,86.2,67.9,86.2,100.9,0,35.6-12.5,72.8-69.8,78.8C132.3,339.3,95.9,318.2,70.1,301.9ZM25.2,162.2c0,47.2,22.1,90.7,59.2,119.1,35.8,22.4,60.6,31.4,91.3,28.2,35.6-3.7,47.2-20.2,47.2-53.8,0-26.4-28.3-62.4-69.4-77.2C97.3,158.6,61.3,119.6,52.4,76.3A148.66,148.66,0,0,0,25.2,162.2Z"
            fill="#fff"
          />
          <path
            d="M323.6,60.4a173.82,173.82,0,0,1-2.2,206.5c.4-4.3.6-8.7.6-13.1,0-62.8-36.7-101-113.5-129.5-59.1-22.7-84.6-38.2-84.6-71.9,0-25.9,21.7-52.5,72.8-52.5a204.39,204.39,0,0,1,42.7,4.5c16.3,3.5,27,8.6,42.6,17.8A147.54,147.54,0,0,1,323.6,60.4Zm7.7,101.8a149.75,149.75,0,0,0-28.1-87.1,122,122,0,0,0-34-31.2c-14.7-8.7-22.8-12.2-35-14.8a178.22,178.22,0,0,0-37.3-3.9c-33.1,0-47.6,12.2-47.6,27.3,0,16.9,12.8,27.1,68.2,48.3,55.3,20.5,92.6,46.9,112.5,82A176.63,176.63,0,0,0,331.3,162.2Z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
    {message}
  </div>
));

SmokeballSpinner.displayName = 'SmokeballSpinner';

SmokeballSpinner.propTypes = {
  message: PropTypes.string,
};

SmokeballSpinner.defaultProps = {
  message: undefined,
};

export default SmokeballSpinner;
