import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { hasFacet, facets } from '@sb-itops/region-facets';
import PaymentTable from './PaymentTable';

const PaymentTableBridge = memo(({ onClickLink, $uibModal, payments, $state }) => {
  const onRowClick = openPaymentModal($uibModal, $state);

  return (
    <PaymentTable
      payments={payments}
      onRowClick={onRowClick}
      onClickLink={onClickLink}
      showReferenceColumn={hasFacet(facets.creditNotePaymentReference)}
    />
  );
});

function openPaymentModal($uibModal, $state) {
  return ({ rowData }) => {
    $uibModal.open({
      templateUrl: 'ng-components/payment-entry/modal/payment-view-modal.html',
      controller: 'SbPaymentViewModalController',
      resolve: {
        sbPaymentViewModalConfig() {
          return {
            preFill: rowData,
            features: {
              update: false,
              saveAndNew: false,
              state: $state.$current.name,
            },
          };
        },
      },
      backdrop: 'static',
    });
  };
}

PaymentTableBridge.propTypes = {
  $uibModal: PropTypes.any.isRequired,
  $state: PropTypes.any.isRequired,
  payments: PropTypes.array.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

export default PaymentTableBridge;
