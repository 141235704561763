angular.module("sb.billing.webapp").config(function ($stateProvider) {
  "use strict";

  $stateProvider.state("home.billing.communicate", {
    template: "<sbb-communicate on-click-link='comm.onClickLink' style='flex: 1;'></sbb-communicate>",
    url: "/communicate",
    data: {
      authorized: true,
      navHighlights: ["billing", "communicate", "productivity"],
    },
    controller: function ($scope, sbLinkService) {
      'use strict';

      var that = this;
      that.onClickLink = sbLinkService.onClickLink;
    },
    controllerAs: 'comm',
  });
});
