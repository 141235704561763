import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import PropTypes from 'prop-types';
import {
  bankAccountState as BANK_ACCOUNT_STATE,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import * as endOfMonthFeature from 'web/redux/route/home-billing-accounts-end-of-month/feature';
import { sortByProperty } from '@sb-itops/nodash';
import { getTrustAccounts, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import EndOfMonthFilterPanel from './EndOfMonthFilterPanel';

const mapStateToProps = (state, { trustAccountId, t }) => {
  const accountType = bankAccountTypeEnum.TRUST;
  const trustAccounts = getTrustAccounts();
  const mappedTrustAccounts = trustAccounts.map((trust) => ({
    label: getBankAccountName(trust, t),
    id: trust.id,
    // trust.state cannot be trusted if multi trust switch is off, this translates legacy isInactive to state so it can now be trusted
    status: isTrustAccountClosed(trust) ? BANK_ACCOUNT_STATE.CLOSED : BANK_ACCOUNT_STATE.OPEN,
  }));
  const activeTrustAccounts = sortByProperty(
    mappedTrustAccounts.filter((trust) => trust.status === BANK_ACCOUNT_STATE.OPEN),
    'label',
    'asc',
    false,
  );

  const selectedTrustAccountId = trustAccountId;

  const trustAccountsFilterPanelConfig = {
    showProtectedFunds: false,
    overviewOnClicklinkParams: { type: 'endOfMonthPage' },
    parentPageName: 'home.billing.accounts.end-of-month',
    getSpecificOnClickLinkParams: (trustId) => ({
      type: 'endOfMonthPageSpecificAccount',
      id: trustId,
    }),
  };

  const path = endOfMonthFeature.defaultPath;
  const { getExpanded } = endOfMonthFeature.selectors;

  const expanded = getExpanded(state[path]);

  return { activeTrustAccounts, selectedTrustAccountId, trustAccountsFilterPanelConfig, expanded, accountType };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(endOfMonthFeature.actions.toggleExpanded()),
});

const EndOfMonthFilterPanelContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EndOfMonthFilterPanel)),
);

EndOfMonthFilterPanelContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  trustAccountId: PropTypes.string,
};

EndOfMonthFilterPanelContainer.defaultProps = {
  accountType: undefined,
  trustAccountId: undefined,
};

export default EndOfMonthFilterPanelContainer;
