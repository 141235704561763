angular.module('sb.billing.webapp').component('sbViewPdf', {
  templateUrl: 'ng-components/view-pdf/view-pdf.html',
  bindings: { fileObject: '<', fileWarn: '<' },
  controller: function($sce, sbMessageDisplayService) {
    const ctrl = this;
    let fileUrl = null;
    let initialised = false;

    ctrl.$onInit = () => {
      loadContent();
      initialised = true;
    };

    ctrl.$onChanges = () => {
      if (initialised) {
        loadContent();
      }
    };

    function loadContent() {
      fileUrl = ctrl.fileObject ? URL.createObjectURL(ctrl.fileObject) : null;
      ctrl.content = $sce.trustAsResourceUrl(fileUrl);

      if (ctrl.fileWarn) {
        sbMessageDisplayService.warn(sbMessageDisplayService.builder().text(ctrl.fileWarn));
      }
    }

    ctrl.$onDestroy = () => {
      URL.revokeObjectURL(fileUrl);
    };
  }
});
