import { updateCache as updateRedux } from '@sb-matter-listing/redux/matter-type-configurations';

angular.module('@sb-matter-listing/services').service('sbMatterTypeConfigurationsService', function(sbGenericEndpointService, sbGenericCacheService, sbEndpointType) {

  sbGenericCacheService.setupCache({
    name: 'sbMatterTypeConfigurationsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'matter-listing/matter-type-configurations' },
      poll: 15,
    },
    updateRedux,
  });
});
