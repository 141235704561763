/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { MultiContactCorrespondenceStatusIndicator } from 'web/react-redux/components/multi-contact-correspondence-status-indicator';
import { InvoiceCorrespondenceSendStatus } from 'web/react-redux/components/invoice-correspondence-send-status';
import { InvoiceViewedOnlineStatusIndicator } from 'web/react-redux/components/invoice-viewed-online-status-indicator';
import { PaymentPlanIndicator, entityTypes } from 'web/react-redux/components/payment-plan-indicator';
import { InvoiceStatusIndicator } from '@sb-billing/react/invoice-status-indicator';
import { sentViaTypes, operationTypes } from '@sb-billing/business-logic/correspondence-history';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { featureActive } from '@sb-itops/feature';
import { status as INVOICE_STATUS } from '@sb-billing/business-logic/invoice/entities/status';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import { getLabel, userDataGetter, currentVersionDataGetter } from './helpers';

import styles from './InvoiceTable.module.scss';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer, timestampLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

// This InvoiceTable is the legacy implementation
//  * This file/directory can be deleted as part of LOD invoice list cleanup
//    * BB-13599 - Firm invoice list
//    * BB-14220 - Contact invoice list
//    * BB-14222 - Matter invoice list
//    * BB-14223 - Adv search invoice list
// 
// For the LOD implementation, please see:
//  * File: monorepo/apps/smokeball-billing-web/src/components/invoice-table
const InvoiceTable = (props) => {
  const { invoices, sort, sortBy, sortDirection, summary } = props;
  const { t } = useTranslation();
  
  // It's possible that the currently set sortBy column is not present in the columns to be rendered.
  // If that's the case, we will set sortBy to be a column that's always present (invoiceNumber)
  const columns = getColumns(props, t);
  const sortConfig = updateSortIfNecessary({ sort, sortBy, sortDirection, columns });

  return (
    <Table
      className="invoice-table"
      onRowClick={({ rowData }) => props.onInvoiceLink(rowData.invoiceId)}
      list={invoices}
      sort={sort}
      sortBy={sortConfig.sortBy}
      sortDirection={sortConfig.sortDirection}
      summary={summary}
      showFooter
    >
      {columns}
    </Table>
  );
}

const updateSortIfNecessary = ({ sort, sortBy, sortDirection, columns }) => {
  const currentSortColumnValid = columns.some((column) => column && column.props && column.props.dataKey === sortBy);

  if (columns.length && !currentSortColumnValid) {
    const newSortConfig = { sortBy: 'invoiceNumber', sortDirection: 'desc' };
    sort(newSortConfig);
    return newSortConfig;
  }

  return { sortBy, sortDirection };
};

const Columns = {
  CHECKBOX: ({ props }) => (
    <Column
      key="UNNEEDED"
      dataKey="UNNEEDED"
      label=""
      width={34}
      headerRenderer={checkboxCellWrapperRenderer(checkboxHeaderCellRenderer({ props }))}
      cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer({ props }))}
      disableSort
    />
  ),
  PAID_ON: () => (
    <Column
      key="paidDate"
      dataKey="paidDate"
      label="Date Paid On"
      cellDataGetter={currentVersionDataGetter}
      cellRenderer={yyyymmddLocalisedRenderer}
      width={100}
    />
  ),
  VOIDED_ON: () => (
    <Column
      key="validFrom"
      dataKey="validFrom"
      label="Voided On"
      cellDataGetter={currentVersionDataGetter}
      cellRenderer={timestampLocalisedRenderer}
      width={100}
    />
  ),
  VOIDED_BY: () => (
    <Column key="name" dataKey="name" label="Voided By" cellDataGetter={userDataGetter} flexGrow={3} />
  ),
  ISSUED: () => (
    <Column
      key="issuedDate"
      dataKey="issuedDate"
      label="Issued"
      cellDataGetter={currentVersionDataGetter}
      cellRenderer={yyyymmddLocalisedRenderer}
      width={100}
    />
  ),
  DUE: () => (
    <Column
      key="dueDate"
      dataKey="dueDate"
      label="Due"
      cellDataGetter={currentVersionDataGetter}
      cellRenderer={yyyymmddLocalisedRenderer}
      width={100}
    />
  ),
  INVOICE_NUMBER: ({ t }) => (
    <Column
      key="invoiceNumber"
      dataKey="invoiceNumber"
      label="Invoice #"
      cellDataGetter={currentVersionDataGetter}
      cellRenderer={invoiceNumberCellRenderer({ t })}
      width={85}
    />
  ),
  DEBTOR: ({ props }) =>
    props.showDebtor && (
      <Column
        key="debtor"
        dataKey="debtor"
        label={featureActive('BB-4416') ? 'Debtor(s)' : 'Debtor'}
        cellRenderer={debtorCellRenderer({ props })}
        flexGrow={15}
      />
    ),
  MATTER: ({ props }) =>
    props.showMatter && (
      <Column
        key="matter"
        dataKey="matter"
        label="Matter"
        cellRenderer={matterCellRenderer({ props })}
        flexGrow={15}
      />
    ),
  AMOUNT: ({ props }) => (
    <Column
      key="totalExcInterest"
      className="right-align"
      dataKey="totalExcInterest"
      label={getLabel({ ...props, label: 'amount' })}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={60}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  INTEREST: () =>
    hasFacet(facets.interest) ? (
      <Column
        key="interest"
        className="interest right-align"
        dataKey="interest"
        label="Interest"
        cellRenderer={balanceCellLocalisedRenderer}
        flexGrow={2}
        width={70}
        footerRenderer={balanceCellLocalisedRenderer}
      />
    ) : null,
  PAID: () => (
    <Column
      key="paid"
      className="right-align"
      dataKey="paid"
      label={hasFacet(facets.interest) ? 'Paid (Inc Interest)' : 'Paid'}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={70}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  CREDIT: () => (
    <Column
      key="paidByCredit"
      className="right-align"
      dataKey="paidByCredit"
      label="Credit"
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={70}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  UNPAID: () => (
    <Column
      key="unpaid"
      className="right-align"
      dataKey="unpaid"
      label={hasFacet(facets.interest) ? 'Unpaid (Inc Interest)' : 'Unpaid'}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={70}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  EMAIL_STATUS: ({ props }) =>
    !featureActive('BB-11448') && (<Column
      key="Sent?"
      dataKey="aggregateSentStatus"
      label="Sent?"
      labelTooltip="Email status"
      width={65}
      cellRenderer={emailStatusCellRenderer({ props })}
    />
  ),
  SEND_STATUS: ({ props }) => 
    featureActive('BB-11448') && (
      <Column
      key="Sent"
      dataKey="aggregateSentStatus"
      label="Sent"
      labelTooltip="Send status"
      width={65}
      cellRenderer={sentStatusCellRenderer({ props })}
    />
  ),
  VIEWED_ONLINE: ({ props }) =>
    featureActive('BB-5725') && (
      <Column
        key="Viewed"
        dataKey="viewedOnline"
        label="Viewed"
        labelTooltip="Viewed Online"
        width={65}
        cellRenderer={viewdOnlineCellRenderer({ props })}
      />
    ),
  STATUS: () => (
    <Column key="status" dataKey="status" label="Status" cellRenderer={statusCellRenderer} width={80} />
  ),
  CONTEXT_MENU: ({ props, t }) => (
    <Column
      key="contextMenu"
      dataKey="contextMenu"
      label=""
      width={40}
      cellRenderer={contextMenuCellRenderer({ props, t })}
      disableSort
    />
  ),
};

function checkboxCellRenderer({ props }) {
  return ({ rowData }) => {
    const { onSelectInvoice, selectedInvoices } = props;
    return (
      <Checkbox
        checked={selectedInvoices[rowData.invoiceId]}
        disabled={rowData.currentVersion.status === "VOID"}
        onChange={() => {
          onSelectInvoice({ invoice: rowData });
        }}
        />
    );
  };
}

const invoiceNumberCellRenderer = ({ t }) => ({ rowData, cellData }) => (
    <div className='icon-container'>
      {cellData}
      {featureActive('BB-9573') && rowData.hasUnpaidAD && (
        <>
          {rowData.currentVersion.status === INVOICE_STATUS.DRAFT && (
            <div className='warning-icon orange'>
              <i
                title={`This invoice contains an anticipated ${t('expense')} that has not yet been paid to the supplier.`}
                className='icon-alert-1'
              />
            </div>
          )}
          {rowData.currentVersion.status === INVOICE_STATUS.FINAL && (
            <div className='warning-icon'>
              <i
                title={`This invoice contains an anticipated ${t('expense')} that has not yet been paid to the supplier.`}
                className='icon-alert-1'
              />
            </div>
          )}
          {rowData.currentVersion.status === INVOICE_STATUS.PAID && (
            <div className='warning-icon'>
              <i
                title={`This invoice contains an anticipated ${t('expense')} that has not yet been paid to the supplier. This invoice has been paid by the client.`}
                className='icon-icon-fat-exclamation-circle-filled'
              />
            </div>
          )}
        </>
      )}
    </div>
  )


function checkboxHeaderCellRenderer({ props }) {
  return () => {
    const { selectedInvoices, invoices, onSelectAllInvoices } = props;
    const selectedCount = Object.keys(selectedInvoices).length;
    const allSelected = !!selectedCount && selectedCount === invoices.filter(invoice => invoice.currentVersion.status !== "VOID").length;

    return <Checkbox checked={allSelected} onChange={onSelectAllInvoices} />;
  };
}

function matterCellRenderer({ props }) {
  return ({ cellData }) => <MatterDisplay onClickLink={props.onClickLink} asLink matterId={cellData?.matterId} />;
}

function debtorCellRenderer({ props }) {
  return ({ rowData }) => rowData &&
    rowData.debtorIds && (
      <div className={styles.invoiceDebtors}>
        <span className={styles.debtorsDisplay}>
          {rowData.debtorIds
            .map((debtorId) => (
              <ContactDisplay
                key={debtorId}
                onClickLink={props.onClickLink}
                asLink
                contactId={debtorId}
                inline
                className={styles.debtor}
                showLastNameFirst
                tooltip={rowData.debtorDisplay}
              />
            ))
            .reduce((acc, elem) => {
              if (acc === null) {
                return [elem];
              }
              return [...acc, ' | ', elem];
            }, null)}
        </span>
        {rowData.debtorIds.length > 1 && (
          <span className={styles.multiDebtorsIcon}>
            <i title={rowData.debtorDisplay} className={classnames('icon', 'icon-contacts-1')} />
          </span>
        )}
        <PaymentPlanIndicator
          entityId={rowData.paymentPlanId}
          entityType={entityTypes.PAYMENT_PLAN}
          entityTooltipName="invoice-debtor"
        />
      </div>
    );
}

function statusCellRenderer({ rowData }) {
  return <InvoiceStatusIndicator invoiceId={rowData.invoiceId} />;
}

// This function can be removed once BB-11448 turns on
function emailStatusCellRenderer({ props }) {
  return ({ rowData }) => {
    const onIconClicked = ({ invoiceId, indicatorStatus }) => {
      const sendViaCommunicateEnabled = featureActive('BB-9097');
      const clientCommunicationEnabled =featureActive('BB-11448');

      if (indicatorStatus === 'NOT_SENT' && !sendViaCommunicateEnabled && !clientCommunicationEnabled) {
          props.onSendEmailFromIndicatorIcon({ invoiceId });
      }
    };

    const onSendViaMenuClicked = ({ invoiceId, sendVia }) => {
      if (sendVia === sentViaTypes.EMAIL) {
        props.onSendEmailFromIndicatorIcon({ invoiceId });
      } else if (sendVia === sentViaTypes.COMMUNICATE) {
        props.onSendCommunicateFromIndicatorIcon({ invoiceId });
      } else {
        props.onMarkAsSentFromIndicatorIcon({ invoiceId, operationType: operationTypes.INVOICE });
      }
    }

    const onResendClicked = ({ invoiceId, contactId, sentVia }) => {
      const sendViaCommunicateEnabled = featureActive('BB-9097');
      // If the feature is not enabled, show send email modal when clicking resend even it sent via communicate previously
      if (sentVia === sentViaTypes.COMMUNICATE && sendViaCommunicateEnabled) {
        props.onSendCommunicateFromIndicatorIcon({ invoiceId, contactId });
      } else  {
        props.onSendEmailFromIndicatorIcon({ invoiceId, contactId });
      }
    };

    return (
      <MultiContactCorrespondenceStatusIndicator
        invoiceId={rowData.invoiceId}
        invoiceStatus={rowData.currentVersion.status}
        contactIds={rowData.debtorIds}
        onIconClicked={onIconClicked}
        onResendClicked={onResendClicked}
        onSendViaMenuClicked={onSendViaMenuClicked}
      />
    );
  };
}

function sentStatusCellRenderer({ props }) {
  return ({ rowData }) => {
    const onResendClicked = ({ invoiceId, contactId, sentVia }) => {
      const sendViaCommunicateEnabled = featureActive('BB-9097');
      // If the feature is not enabled, show send email modal when clicking resend even it sent via communicate previously
      if (sentVia === sentViaTypes.COMMUNICATE && sendViaCommunicateEnabled) {
        props.onSendCommunicateFromIndicatorIcon({ invoiceId, contactId });
      } else  {
        props.onSendEmailFromIndicatorIcon({ invoiceId, contactId });
      }
    };

    return (
      <InvoiceCorrespondenceSendStatus
        invoiceId={rowData.invoiceId}
        invoiceStatus={rowData.currentVersion.status}
        contactIds={rowData.debtorIds}
        onResendClicked={onResendClicked}
      />
    );
  };
}


function viewdOnlineCellRenderer() {
  return ({ rowData }) => <InvoiceViewedOnlineStatusIndicator invoiceId={rowData.invoiceId} />;
}

function getColumns(props, t) {
  const columnsToDisplay = getColumnsToDisplay(props);

  // Show invoice status column when invoices with different statuses are being displayed.
  if (props.invoiceStatuses.length > 1) {
    columnsToDisplay.splice(1, 0, Columns.STATUS);
  }

  return columnsToDisplay.map((columnFunc) => columnFunc({ props, t }));
}

function getColumnsToDisplay({ displayMode, invoiceStatuses }) {
  if (displayMode === 'SEARCH') {
    return [
      Columns.CHECKBOX,
      Columns.STATUS,
      Columns.ISSUED,
      Columns.DUE,
      Columns.INVOICE_NUMBER,
      Columns.DEBTOR,
      Columns.MATTER,
      Columns.AMOUNT,
      Columns.INTEREST,
      Columns.CREDIT,
      Columns.PAID,
      Columns.UNPAID,
      Columns.SEND_STATUS,
      Columns.EMAIL_STATUS,
      Columns.VIEWED_ONLINE,
      Columns.CONTEXT_MENU,
    ];
  }

  const columnsToShowPerStatus = invoiceStatuses.map((invoiceStatus) => TableColumns[invoiceStatus]);
  return _.intersection(...columnsToShowPerStatus);
}

function contextMenuCellRenderer({ props, t }) {
  return ({ rowData }) => {
    const invoiceId = rowData.invoiceId;
    const { status } = rowData.currentVersion;
    const { onInvoiceProofOfPaymentLink } = props;
    const printReceiptText = t('printDetail');

    const onSendViaMenuClicked = ({ sendVia }) => {
      if (sendVia === sentViaTypes.EMAIL) {
        props.onSendEmailFromIndicatorIcon({ invoiceId });
      } else if (sendVia === sentViaTypes.COMMUNICATE) {
        props.onSendCommunicateFromIndicatorIcon({ invoiceId });
      } else {
        props.onMarkAsSentFromIndicatorIcon({ invoiceId, operationType: operationTypes.INVOICE });
      }
    }

    const sendOptionsMenu = featureActive('BB-11448') ? (
      <>
        <button
          type="button"
          className="list-group-item"
          onClick={() => {
            onSendViaMenuClicked({ sendVia: sentViaTypes.EMAIL });
          }}
        >
          Send via Email
        </button>
        {featureActive('BB-9097') && (
          <button
            type="button"
            className="list-group-item"
            onClick={() => {
              onSendViaMenuClicked({ sendVia: sentViaTypes.COMMUNICATE });
            }}
          >
            Send via Client Portal
          </button>
        )}
        <button type="button" className="list-group-item" onClick={onSendViaMenuClicked}>
          Mark as Sent
        </button>
      </>
    )
    : null;


    return (
      (status === 'FINAL' || status === 'PAID') && (
        <ContextMenu
          body={() => (
            <div className="context-menu-body list-group">
              {sendOptionsMenu}
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onInvoiceProofOfPaymentLink(invoiceId);
                }}
              >
                {printReceiptText}
              </button>
            </div>
          )}
        >
          <div className="context-menu-cell">...</div>
        </ContextMenu>
      )
    );
  };
}

const TableColumns = {
  DRAFT: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.EMAIL_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.CONTEXT_MENU,
  ],
  FINAL: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.INTEREST,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.EMAIL_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.CONTEXT_MENU,
  ],
  OVERDUE: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.INTEREST,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.EMAIL_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.CONTEXT_MENU,
  ],
  PAID: [
    Columns.CHECKBOX,
    Columns.PAID_ON,
    Columns.ISSUED,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.EMAIL_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.CONTEXT_MENU,
  ],
  VOID: [
    Columns.CHECKBOX,
    Columns.VOIDED_ON,
    Columns.VOIDED_BY,
    Columns.ISSUED,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.EMAIL_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.CONTEXT_MENU,
  ],
  SEARCH: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.INTEREST,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.STATUS,
    Columns.SEND_STATUS,
    Columns.EMAIL_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.CONTEXT_MENU,
  ],
};

InvoiceTable.displayName = 'InvoiceTable';

// NOTE you will need to have either a value for displayMode or a value from invoiceMode
// this was done like this to match the pre-react implementation of the table.
// we will be improving this components interface in the future - TH
InvoiceTable.propTypes = {
  invoices: PropTypes.array.isRequired,
  invoiceStatuses: PropTypes.array,
  displayMode: PropTypes.oneOf(['SEARCH']),
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired,
  selectedInvoices: PropTypes.object.isRequired,
  onSelectAllInvoices: PropTypes.func.isRequired,
  onSelectInvoice: PropTypes.func.isRequired,
  showDebtor: PropTypes.bool,
  showMatter: PropTypes.bool,
  onInvoiceLink: PropTypes.func.isRequired,
  onInvoiceProofOfPaymentLink: PropTypes.func,
  onSendEmailFromIndicatorIcon: PropTypes.func,
  onSendCommunicateFromIndicatorIcon: PropTypes.func,
  onMarkAsSentFromIndicatorIcon: PropTypes.func,
};

InvoiceTable.defaultProps = {
  displayMode: undefined,
  invoiceStatuses: [],
  showDebtor: false,
  showMatter: false,
  onInvoiceProofOfPaymentLink: () => { },
  onSendEmailFromIndicatorIcon: () => { },
  onSendCommunicateFromIndicatorIcon: () => { },
  onMarkAsSentFromIndicatorIcon: () => { },
};

export default InvoiceTable;
