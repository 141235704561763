'use strict';

const billingEventType = Object.freeze({
  INVOICE_VIEWED_ONLINE: 1, // An invoice has been viewed on the eInvoice portal.
});

const billingEventPseudoType = Object.freeze({
  INVOICE_VIEWED_ONLINE: 'INVOICE_VIEWED_ONLINE',
});

module.exports = { billingEventType, billingEventPseudoType };
