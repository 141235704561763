/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ShowSystemDateToggleAccounts,
  ShowDeletedTransactionsToggle,
  DateListFilter,
  ClientCommunicationsToggle,
} from 'web/react-redux';
import { TrustAccountsForFilterPanels } from '@sb-billing/react/trust-accounts-filter-panel';
import { PanelExpander, useTranslation } from '@sb-itops/react';
import { capitalizeAllWords } from '@sb-itops/nodash';
import { featureActive } from '@sb-itops/feature';

import classnames from 'classnames';

const AccountsTransactionsFilterPanel = memo(
  ({
    onClickLink,
    accountType,
    entity,
    trustAccounts,
    selectedTrustAccountId,
    showHidden,
    showMarkAsSentEntries,
    scope,
    onDateListChange,
    onDeletedTransactionsChange,
    onClientCommunicationToggleChange,
    expanded,
    toggleExpanded,
    dateListFilter,
    showTrustCheques,
    showOperatingAccount,
    showDeletedToggle,
    trustAccountsFilterPanelConfig,
  }) => {
    const { t } = useTranslation();
    return (
      <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
        <div className="side-menu-block">
          <h3>Accounts</h3>
          <ul className="side-menu-list capitalize">
            <TrustAccountsForFilterPanels
              trustAccounts={trustAccounts}
              onClickLink={onClickLink}
              selectedTrustAccountId={selectedTrustAccountId}
              selectedAccountType={accountType}
              {...trustAccountsFilterPanelConfig}
            />
            {showOperatingAccount && (
              <li
                onClick={() => onClickLink({ id: 'operating', type: 'transactions' })}
                className={classnames({ active: accountType === 'OPERATING' && !entity })}
              >
                {t('operatingRetainer')} Account
              </li>
            )}
          </ul>
          <div>
            <h3>Ledger</h3>
            <ul className="side-menu-list capitalize">
              <li
                onClick={() => onClickLink({ id: 'ledger', type: 'transactions' })}
                className={classnames({ active: accountType === 'LEDGER' })}
              >
                Invoice Ledger
              </li>
              <li
                onClick={() => onClickLink({ id: 'credit-note-ledger', type: 'transactions' })}
                className={classnames({ active: accountType === 'CREDIT-NOTE-LEDGER' })}
              >
                {capitalizeAllWords(t('creditNote'))} Ledger
              </li>
            </ul>
          </div>
          {featureActive('BB-3331') && (
            <div>
              <h3>
                <span className="uppercase">{t('cheques')}</span>
              </h3>
              <ul className="side-menu-list">
                {showTrustCheques && (
                  <li
                    onClick={() => onClickLink({ id: 'trust-cheques', type: 'transactions' })}
                    className={classnames({ active: accountType === 'TRUST-CHEQUES' && entity === 'CHEQUE' })}
                  >
                    <span>{capitalizeAllWords(`${t('trust')} ${t('cheques')}`)}</span>
                  </li>
                )}
                <li
                  onClick={() => onClickLink({ id: 'operating-cheques', type: 'transactions' })}
                  className={classnames({ active: accountType === 'OPERATING' && entity === 'CHEQUE' })}
                >
                  <span>{capitalizeAllWords(t('operatingCheques'))}</span>
                </li>
              </ul>
            </div>
          )}
          {featureActive('BB-11448') && (
            <div>
              <h3>History</h3>
              <ul className="side-menu-list capitalize">
                <li
                  onClick={() => onClickLink({ id: 'client-communications', type: 'transactions' })}
                  className={classnames({ active: accountType === 'CLIENT-COMMUNICATIONS' })}
                >
                  <span>Client Communications</span>
                </li>
              </ul>
              <br />
            </div>
          )}
          <div>
            <h3>Show</h3>
            {accountType !== 'LEDGER' && accountType !== 'CLIENT-COMMUNICATIONS' && showDeletedToggle && (
              <ShowDeletedTransactionsToggle
                onSelect={(id, value) => {
                  onDeletedTransactionsChange(value);
                }}
                label={
                  accountType === 'CREDIT-NOTE-LEDGER' ? `Deleted ${capitalizeAllWords(t('creditNotes'))}` : undefined
                }
                selected={showHidden}
                className="deleted-transactions-toggle"
              />
            )}
            {accountType !== 'CLIENT-COMMUNICATIONS' && <ShowSystemDateToggleAccounts />}
            {accountType === 'CLIENT-COMMUNICATIONS' && (
              <ClientCommunicationsToggle
                showMarkAsSentEntries={showMarkAsSentEntries}
                onClientCommunicationToggleChange={onClientCommunicationToggleChange}
              />
            )}
          </div>
          {(accountType === 'LEDGER' || entity === 'CHEQUE') && (
            <div>
              <h3>For Entries Dated</h3>
              <DateListFilter
                onChange={onDateListChange}
                className="side-menu-list"
                scope={scope}
                {...dateListFilter}
              />
            </div>
          )}
        </div>
      </PanelExpander>
    );
  },
);

AccountsTransactionsFilterPanel.displayName = 'AccountsTransactionsFilterPanel';

AccountsTransactionsFilterPanel.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onClientCommunicationToggleChange: PropTypes.func.isRequired,
  onDateListChange: PropTypes.func.isRequired,
  onDeletedTransactionsChange: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  entity: PropTypes.string,
  showHidden: PropTypes.bool,
  showMarkAsSentEntries: PropTypes.bool,
  trustAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedTrustAccountId: PropTypes.string,
  showTrustCheques: PropTypes.bool.isRequired,
  showOperatingAccount: PropTypes.bool.isRequired,
  showDeletedToggle: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  dateListFilter: PropTypes.shape({
    selectedFilter: PropTypes.string,
    before: PropTypes.number, // effective date YYYYMMDD
    from: PropTypes.number, // effective date YYYYMMDD
    to: PropTypes.number, // effective date YYYYMMDD
  }),
  trustAccountsFilterPanelConfig: PropTypes.shape({
    showProtectedFunds: PropTypes.bool.isRequired,
    overviewOnClicklinkParams: PropTypes.object.isRequired,
    getSpecificOnClickLinkParams: PropTypes.func.isRequired,
    getProtectedFundsOnClickLinkParams: PropTypes.func.isRequired,
  }).isRequired,
};

AccountsTransactionsFilterPanel.defaultProps = {
  entity: undefined,
  accountType: undefined,
  showHidden: undefined,
  showMarkAsSentEntries: undefined,
  dateListFilter: {},
  selectedTrustAccountId: undefined,
};

export default AccountsTransactionsFilterPanel;
