'use strict';

const { calculateFeeDetails } = require('./calculate-fee-details');
const { clientCoversFeeStrategy } = require('./client-covers-fee-strategy');
const { convertSettingsForGQL, convertSettingsFromGQL } = require('./convert-settings-gql');
const { extractFeeSchedule } = require('./extract-fee-schedule');
const { getChargeDescription } = require('./get-charge-description');
const { getChargeErrorMessage } = require('./get-charge-error-message');
const { getFirmPaymentProviderPaymentSettings } = require('./get-firm-payment-provider-payment-settings');
const { getMerchantPaymentUrl } = require('./get-merchant-payment-url');
const { getMinChargeAmountInCents } = require('./get-min-charge-amount-in-cents');
const { getPaymentFormConfiguration } = require('./get-payment-form-configuration');
const { getPaymentSource } = require('./get-payment-source');
const { isClientCardSavingEnabledForBankAccount } = require('./is-client-card-saving-enabled-for-bank-account');
const { isErrorSmokeballChargeError, isErrorSmokeballPreChargeError } = require('./is-smokeball-error');
const { isFirmCardSavingEnabledForBankAccount } = require('./is-firm-card-saving-enabled-for-bank-account');
const { isPaymentProviderEnabledForBankAccount } = require('./is-payment-provider-enabled-for-bank-account');

module.exports = {
  calculateFeeDetails,
  clientCoversFeeStrategy,
  convertSettingsForGQL,
  convertSettingsFromGQL,
  extractFeeSchedule,
  getChargeDescription,
  getChargeErrorMessage,
  getFirmPaymentProviderPaymentSettings,
  getMerchantPaymentUrl,
  getMinChargeAmountInCents,
  getPaymentFormConfiguration,
  getPaymentSource,
  isClientCardSavingEnabledForBankAccount,
  isErrorSmokeballChargeError,
  isErrorSmokeballPreChargeError,
  isFirmCardSavingEnabledForBankAccount,
  isPaymentProviderEnabledForBankAccount,
};
