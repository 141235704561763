import { pluck } from 'lodash';
import { updateCache as updateRedux, getList } from '@sb-billing/redux/utbms-activity-codes';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('@sb-billing/services')
  .service('sbUtbmsActivityCodeService', function(sbGenericCacheService, sbEndpointType) {
    if (!hasFacet(facets.utbms)) {
      return;
    }

    const that = this;
    const utbmsActivityCodesEndpoint = 'billing/utbms-codes/activity-code';

    sbGenericCacheService.setupCache({
      name: 'sbUtbmsActivityCodeService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: utbmsActivityCodesEndpoint },
        poll: 60
      },
      updateRedux
    });

    that.getUtbmsActivities = getUtbmsActivities;
    that.getFeeCodes = getFeeCodes;
    that.getFeeUtbmsActivities = getFeeUtbmsActivities;

    function getUtbmsActivities() {
      return getList().filter(v => !v.deleted);
    }

    function getFeeUtbmsActivities() {
      const utbmsActivities = getUtbmsActivities();
      if (!utbmsActivities) return [];
      return utbmsActivities.filter(act => act.type === 'Activity');
    }

    function getFeeCodes() {
      return pluck(getFeeUtbmsActivities(), 'code');
    }
  });
