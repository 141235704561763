import { createSelector } from 'reselect';
import { cacheFactory, indexTypes, syncTypes } from '@sb-itops/redux';
import {
  CORRESPONDENCE_STATUS,
  CORRESPONDENCE_PSEUDO_STATUS,
  externalSentViaTypes,
  operationTypes,
} from '@sb-billing/business-logic/correspondence-history';
import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'correspondence-history',
  keyPath: 'id',
  ngCacheName: 'sbCorrespondenceHistoryService',
  indexes: [{ name: 'byInvoiceId', indexer: (entity) => entity.relatedIds, type: indexTypes.MANY_TO_MANY }],
  syncType: syncTypes.SINCE,
  immutable: false,
});

const getInvoiceCorrespondenceByIndexSelector = createSelector(
  (list) => list || [],
  (correspondenceHistory) =>
    correspondenceHistory.reduce((prev, current) => {
      if (current.operationType !== operationTypes.INVOICE) {
        return prev;
      }
      const prevTimestamp = prev && (prev.sentTimestamp || prev.lastUpdated);
      const currentTimestamp = current.sentTimestamp || current.lastUpdated;
      return prevTimestamp > currentTimestamp ? prev : current || prev;
    }, {}),
);

export const getInvoiceCorrespondenceHistory = (invoiceId) => {
  const historyByInvoiceId = api.getIndex('byInvoiceId');
  return getInvoiceCorrespondenceByIndexSelector(historyByInvoiceId[invoiceId]) || {};
};

const groupInvoiceCorrespondenceHistoryByDebtorSelector = createSelector(
  (list) => list || [],
  (correspondenceHistory) =>
    correspondenceHistory.reduce((acc, correspondenceItem) => {
      if (correspondenceItem.operationType !== operationTypes.INVOICE) {
        return acc;
      }
      // Logic for below code:
      // 1. See if the correspondenceItem is manually sent, if so we add a manual entry to acc (if there is more than one manual sent history we get the latest one by comparing the sentTimestamp). When mark as sent for invoices, contactIds is empty [], and no matter how many debtors we have on the matter, we only show one entry.
      // 2. If not manually sent, then use sentTimestamp to get the latest correspondence history for each contact (if sentTimestamp does not exist we use lastUpdated)
      const isManuallySent = Object.values(externalSentViaTypes).includes(correspondenceItem.sentVia);
      if (isManuallySent) {
        if (!acc.manual || acc.manual.sentTimestamp < correspondenceItem.sentTimestamp) {
          acc.manual = correspondenceItem;
        }
      } else {
        (correspondenceItem.contactIds || []).forEach((contactId) => {
          const existingItem = acc[contactId];
          const existingItemTimestamp =
            (existingItem && existingItem.sentTimestamp) || (existingItem && existingItem.lastUpdated);
          const currentItemTimestamp = correspondenceItem.sentTimestamp || correspondenceItem.lastUpdated;

          const isCurrentItemMoreRecent = existingItemTimestamp < currentItemTimestamp;

          if (!existingItem || isCurrentItemMoreRecent) {
            acc[contactId] = correspondenceItem;
          }
        });
      }

      return acc;
    }, {}),
);

export const getInvoiceCorrespondenceHistoryGroupedByDebtor = (invoiceId, contactIds) => {
  const historyByInvoiceId = api.getIndex('byInvoiceId');
  if (!historyByInvoiceId[invoiceId]) {
    return undefined;
  }

  const correspondenceHistory = groupInvoiceCorrespondenceHistoryByDebtorSelector(historyByInvoiceId[invoiceId]);
  // If we didn't find any correspondence history for contacts on the invoice, it's possible to be because of
  // old style correspondence history records that lacked a contactIds property. In other words, we might have
  // correspondence history for the invoice, but not know to which contact it belongs. If that happens, we will
  // search for the most recent record (even those without contact ids) and if found, assume that it belongs to
  // the passed contact ids.
  if (!Object.keys(correspondenceHistory).length) {
    const latestOldStyleCorrespondence = getInvoiceCorrespondenceHistory(invoiceId);
    if (!latestOldStyleCorrespondence || !Object.keys(latestOldStyleCorrespondence).length) {
      return undefined;
    }

    return contactIds.reduce((acc, contactId) => {
      acc[contactId] = { ...latestOldStyleCorrespondence, isOldStyle: true };
      return acc;
    }, {});
  }

  return correspondenceHistory;
};

// This function is only used to get correspondence for INVOICE type
export const getLastPseudoStatusByInvoiceId = (invoiceId) => {
  const lastCorrespondenceRecord = getInvoiceCorrespondenceHistory(invoiceId);
  const lastCorrespondenceStatus = lastCorrespondenceRecord && lastCorrespondenceRecord.status;

  // lastSentStatus will be falsy if the invoice has never been sent.
  // Unfortunately, CORRESPONDENCE_STATUS.inProgress is 0, so we need to do a negative check against in progress
  // to determine if the invoice has not been sent.
  if (!lastCorrespondenceStatus && lastCorrespondenceStatus !== CORRESPONDENCE_STATUS.inProgress) {
    return CORRESPONDENCE_PSEUDO_STATUS.notSent;
  }

  switch (lastCorrespondenceStatus) {
    case CORRESPONDENCE_STATUS.inProgress:
      return CORRESPONDENCE_PSEUDO_STATUS.inProgress;
    case CORRESPONDENCE_STATUS.succeeded:
      return CORRESPONDENCE_PSEUDO_STATUS.sent;
    case CORRESPONDENCE_STATUS.failed:
      return CORRESPONDENCE_PSEUDO_STATUS.failed;
    default:
      return lastCorrespondenceStatus;
  }
};

export const { getById, getMap, getList, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;
