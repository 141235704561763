import * as ActionTypes from './action-types';

const defaultState = {
  logItems: [],
  isCollectingLogs: false,
};

export const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ActionTypes.BROADCAST_LOG_STREAM_MESSAGE:
      if (!state.isCollectingLogs) {
        return state;
      }

      return {
        ...state,
        logItems: [...state.logItems, action.payload.meta],
      };

    case ActionTypes.CLEAR_LOG_ITEMS:
      return {
        ...state,
        logItems: [],
      };

    case ActionTypes.UPDATE_IS_COLLECTING:
      return {
        ...state,
        isCollectingLogs: action.payload.isCollectingLogs,
      };

    default:
      return state;
  }
};
