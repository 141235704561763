import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').component('sbBillDownloadDropdown', {
  templateUrl: 'ng-components/bill-download-dropdown/bill-download-dropdown.html',
  bindings: { matterId: '<' },
  controller: function($stateParams, sbLoggerService, sbUtbmsSettingsService, sbBillingConfigurationService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbBillDownloadDropdown');
    log.debug('matterId: ', that.matterId);

    that.buttonLoading = false;
    that.setButtonLoading = setButtonLoading;

    that.invoiceIds = [$stateParams.invoiceId];
    that.showDownloadDropdown = showDownloadDropdown;

    function showDownloadDropdown() {
      if (!hasFacet(facets.utbms)) {
        return false;
      }       

      const firmUtbmsSettings = sbUtbmsSettingsService.get();
      const matterUtbmsSettings = sbBillingConfigurationService.getByMatterId(that.matterId);
      return (
        firmUtbmsSettings &&
        firmUtbmsSettings.isUtbmsEnabled &&
        matterUtbmsSettings &&
        matterUtbmsSettings.isUtbmsEnabled
      );
    }

    function setButtonLoading(isLoading) {
      that.buttonLoading = isLoading;
    }
  }
});
