angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/settings/tax';

  $stateProvider.state('settings.tax', {
    templateUrl: BASE_DIRECTORY + '/tax.html',
    url: '/tax',
    data: {
      navHighlights: ['tax']
    }
  });

});
