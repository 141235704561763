export const getNumberOfParents = (state) => state.numberOfParents;
export const getNumberOfParentsSelected = (state) => state.numberOfParentsSelected;
export const getNumberOfChildren = (state, { parentId }) => state.parents[parentId].numberOfChildren;
export const getNumberOfChildrenSelected = (state, { parentId }) => state.parents[parentId].numberOfChildrenSelected;
export const parentIsSelected = (state, { parentId }) => state.parents[parentId] && state.parents[parentId].selected;
export const allSelected = (state) => state.allSelected;
export const childIsSelected = (state, { childId }) => {
  const parentId = state.parentLookup[childId];
  return state.parents[parentId] && state.parents[parentId].children[childId];
};
