'use strict';

const createIconImage = ({ iconImageUrl, sizeInPixels, alt }) => {
  const icon = `
  <img  class="summary-box-icon" width=${sizeInPixels}" height=${sizeInPixels} style="height: ${sizeInPixels}px; vertical-align: middle" src=${iconImageUrl} alt=${alt} />
`;
  return icon;
};

module.exports = {
  createIconImage,
};
