import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { getById as getInvoiceById } from './index';

const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbInvoicingService',
  keyPath: 'invoiceId',
});

export const deleteInvoices = (invoicesToDelete) => {
  if (invoicesToDelete.some(({ invoiceId, versionId }) => !invoiceId && !versionId)) {
    throw new Error('invalid schema of invoicesToDelete, need to have invoiceId and versionId in each entry');
  }

  const deleteInvoicesThunk = async () => {
    opdateCache({
      optimisticEntities: invoicesToDelete.map(({ invoiceId, versionId }) => {
        const invoice = getInvoiceById(invoiceId);
        return {
          ...invoice,
          versionIds: [...invoice.versionIds, versionId],
          invoiceId,
          currentVersion: {
            ...invoice.currentVersion,
            status: 'DELETED',
            versionId,
          },
        };
      }),
    });

    // Apply the save in the backend.
    try {
      const path = `/billing/invoice-bulk-bill/:accountId/delete`;
      const data = { invoicesToDelete };
      const fetchOptions = { body: JSON.stringify(data) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the whole collection. The bulk delete has a queue that internally will delete invoices as a batch. this will only cover the case for when
      // the server is unreachable or there is some sort of problem in the backend that the queue is nor working.
      rollbackOpdateCache({
        optimisticEntities: invoicesToDelete.map((invoiceToDelete) => ({ invoiceId: invoiceToDelete.invoiceId })),
      });
    }
  };

  store.dispatch(deleteInvoicesThunk);
};
