import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortFeature from '@sb-itops/redux/sort';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import {
  getByBankAccountId as getBankReconciliationsByAccountId,
  getLatest as getLatestBankRecByAccountId,
} from '@sb-billing/redux/bank-reconciliations';
import { sortByOrder } from '@sb-itops/nodash';
import * as messageDisplay from '@sb-itops/message-display';
import { getById as getBankAccountById, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { getSettings as getBankReconSetupForAccount } from '@sb-billing/redux/bank-reconciliation-setup.2';
import BankReconciliationsList from './BankReconciliationsList';

const FEATURE_SCOPE = 'bank-reconciliation-list';
const defaultSortBy = 'endDate';
const defaultSortDirection = 'desc';
const getScopedFeatures = (state) =>
  withScopedFeatures({ state, scope: FEATURE_SCOPE })({
    sort: sortFeature,
  });

const getButtonStates = (bankReconciliationSetup, latestBankRec) => {
  if (!bankReconciliationSetup) {
    return {
      setup: {
        show: true,
        label: 'Setup Reconciliation',
      },
      recon: {
        show: false,
      },
    };
  }
  if (bankReconciliationSetup && !latestBankRec) {
    return {
      setup: {
        show: true,
        label: 'Edit Reconciliation Setup',
      },
      recon: {
        show: true,
        label: 'Create Reconciliation',
      },
    };
  }
  if (latestBankRec && latestBankRec.status === 'InProgress') {
    return {
      setup: {
        show: false,
      },
      recon: {
        show: true,
        label: 'Update Reconciliation',
      },
    };
  }
  if (latestBankRec && latestBankRec.status === 'Completed') {
    return {
      setup: {
        show: false,
      },
      recon: {
        show: true,
        label: 'Create Reconciliation',
        isUpToDate: latestBankRec.endDate >= moment().format('YYYYMMDD'),
      },
    };
  }
  return {
    setup: {
      show: false,
    },
    recon: {
      show: false,
    },
  };
};

const getUserNameFromId = (userId) => {
  const user = getPersonByUserId(userId);
  const userName = user ? user.name || user.initials : 'N/A';
  return userName;
};

const mapStateToProps = (state, { trustAccountId, t }) => {
  const { sort } = getScopedFeatures(state);
  const sortBy = sort.selectors.getSortBy() || defaultSortBy;
  const sortDirection = sort.selectors.getSortDirection() || defaultSortDirection;
  let bankReconciliations = [];

  const trustAccount = getBankAccountById(trustAccountId);
  const trustAccountClosed = isTrustAccountClosed(getBankAccountById(trustAccountId));
  const bankReconciliationSetup = getBankReconSetupForAccount(trustAccountId);
  const latestBankRec = getLatestBankRecByAccountId(trustAccountId);

  // Generate button states
  const buttonStates = getButtonStates(bankReconciliationSetup, latestBankRec);

  if (bankReconciliationSetup) {
    // Generate Bank Recon list data for display
    bankReconciliations = getBankReconciliationsByAccountId(trustAccountId)
      .filter((recon) => recon.status === 'Completed')
      .map((recon) => {
        const description = `Bank Reconciliation for ${t('date', { yyyymmdd: recon.startDate })} to ${t('date', {
          yyyymmdd: recon.endDate,
        })}`;
        return {
          userName: getUserNameFromId(recon.userId),
          description,
          ...recon,
        };
      });

    if (bankReconciliationSetup && !bankReconciliationSetup.isRemoved) {
      bankReconciliations.unshift({
        endDate: bankReconciliationSetup.reconciliationStartDate,
        description: 'Bank Reconciliation Setup',
        userName: getUserNameFromId(bankReconciliationSetup.userId),
        closingCashBookBalance: bankReconciliationSetup.initialLedgerBalance,
        isSetupRow: true,
      });
    }
    sortByOrder(bankReconciliations, [sortBy], [sortDirection]);
  }

  return {
    trustAccount,
    trustAccountClosed,
    bankReconciliationsListData: bankReconciliations,
    sortBy,
    sortDirection,
    buttonStates,
  };
};

const mapDispatchToProps = (dispatch, { onClickLink, trustAccountId }) => {
  const {
    sort: {
      actions: { setSortDirection, setSortBy },
    },
  } = getScopedFeatures();

  return {
    onCreateBankRecon: () => onClickLink({ type: 'createBankReconciliationSpecificAccount', id: trustAccountId }),
    onSetupBankRecon: () => onClickLink({ type: 'bankReconciliationSetupSpecificAccount', id: trustAccountId }),
    onSort: ({ sortBy, sortDirection }) => {
      dispatch(setSortDirection({ sortDirection }));
      dispatch(setSortBy({ sortBy }));
    },
    showUpToDateMessage: () => {
      messageDisplay.success('Your bank reconciliations are up to date');
    },
  };
};

const BankReconciliationsListContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BankReconciliationsList)),
);

BankReconciliationsListContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string,
};

BankReconciliationsListContainer.defaultProps = {
  trustAccountId: undefined,
};

export default BankReconciliationsListContainer;
