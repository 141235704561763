import { gql } from '@apollo/client';

const query = gql`
  query RateSetUniqueNameCheck($name: String!, $excludeRateSetIds: [ID]) {
    rateSetUniqueNameCheck(name: $name, excludeRateSetIds: $excludeRateSetIds) {
      isUnique
    }
  }
`;

const notificationIds = ['WebQueryFeesNotifications.RateSetUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const RateSetUniqueNameCheck = {
  query,
  notificationIds,
};
