const Yup = require('yup');
const { customYupValidators } = require('@sb-itops/business-logic/validation/services');

const rateSetNameMustBeUniqueErrorMessage = 'Rate set name must be unique.';

const AddEditRateSetSchema = Yup.object().shape({
  rateSetName: Yup.string().required(),
  currentStaffRates: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        staffId: customYupValidators.uuid().required(),
        rate: Yup.number().min(0).required(),
      }),
    ),
  updateRatesOn: Yup.number().integer(),
  futureStaffRates: Yup.array().when('updateRatesOn', {
    is: (updateRatesOn) => Number.isFinite(updateRatesOn),
    then: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          staffId: customYupValidators.uuid().required(),
          rate: Yup.number().min(0).required(),
        }),
      ),
    otherwise: Yup.array().nullable(),
  }),
});

module.exports = {
  AddEditRateSetSchema,
  rateSetNameMustBeUniqueErrorMessage,
};
