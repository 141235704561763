import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortDirectionFeature from '@sb-itops/redux/sort';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import {
  getMatterBalance,
  getBankAccountBalanceById,
  getContactBalance,
} from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getTrustAccounts } from '@sb-billing/redux/bank-account';
import { sortByOrder } from '@sb-itops/nodash';
import { getLastCompleted as getLastCompletedBankRecon } from '@sb-billing/redux/bank-reconciliations';
import { getLatest as getLatestEOMReport } from '@sb-billing/redux/end-of-month-reports';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { filterTrustAccountsByContact, filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import { TrustAccountsOverview } from './TrustAccountsOverview';

const FEATURE_SCOPE = 'trust-accounts-overview';

const sortTrustAccounts = ({ sortBy, sortDirection, trustAccounts }) => {
  if (sortBy && sortDirection) {
    return sortByOrder(trustAccounts, [sortBy, 'timestamp'], [sortDirection, sortDirection], false, '');
  }
  return trustAccounts;
};

const getRelevantTrustAccounts = ({ matterId, contactId }) => {
  // always include closed accounts for matter/contact as we filter them out later if needed
  if (matterId) {
    return filterTrustAccountsByMatter(matterId, { includeClosed: true });
  }
  if (contactId) {
    return filterTrustAccountsByContact(contactId, { includeClosed: true });
  }
  return getTrustAccounts();
};

const getRelevantTrustBalance = ({ trustAccountId, matterId, contactId }) => {
  if (!trustAccountId) {
    return 0;
  }

  const bankAccountBalanceState = getBankAccountBalanceState();
  if (matterId) {
    return (
      getMatterBalance(bankAccountBalanceState, {
        bankAccountId: trustAccountId,
        matterId,
        balanceType: balanceTypes.BALANCE,
      }) || 0
    );
  }
  if (contactId) {
    return (
      getContactBalance(bankAccountBalanceState, {
        bankAccountId: trustAccountId,
        contactId,
        balanceType: balanceTypes.BALANCE,
      }) || 0
    );
  }
  return getBankAccountBalanceById(bankAccountBalanceState, { bankAccountId: trustAccountId })?.balance || 0;
};

const getLastReconciledDate = (trustAccountId) => getLastCompletedBankRecon(trustAccountId)?.reconciledDate;

const transactionDisplayColumns = {
  accountName: true,
  displayName: true,
  bankName: true,
  branch: true,
  bsb: true,
  accountNumber: true,
  status: true,
  balance: true,
};

export const TrustAccountsOverviewContainer = withReduxProvider((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onClickLink, matterId, contactId, parentPage } = props;
  const [showClosedAccounts, setShowClosedAccounts] = useState(false);

  const { sortFeature } = useSelector((state) =>
    withScopedFeatures({ state, scope: FEATURE_SCOPE })({
      sortFeature: sortDirectionFeature,
    }),
  );
  const sortBy = sortFeature.selectors.getSortBy() || 'name';
  const sortDirection = sortFeature.selectors.getSortDirection() || 'asc';

  const config = {
    CONTACTS: {
      displayColumns: transactionDisplayColumns,
      onClickRow: (trustAccountId) =>
        onClickLink({
          type: 'contactTransactions',
          id: 'trust',
          params: [{ contactId, trustAccountId }],
        }),
    },
    MATTERS: {
      displayColumns: transactionDisplayColumns,
      onClickRow: (trustAccountId) =>
        onClickLink({
          type: 'matterTransactions',
          id: 'trust',
          params: [{ matterId, trustAccountId }],
        }),
    },
    ACCOUNT: {
      displayColumns: transactionDisplayColumns,
      onClickRow: (trustAccountId) =>
        onClickLink({
          type: 'transactions',
          id: 'trust',
          params: [trustAccountId],
        }),
    },
    BANKRECS: {
      displayColumns: {
        name: true,
        bankName: true,
        branch: true,
        location: hasFacet(facets.trustAccountPerState),
        status: true,
        lastReconciled: true,
      },
      onClickRow: (trustAccountId) =>
        onClickLink({
          type: 'bankReconciliationHomeSpecificAccount',
          id: trustAccountId,
        }),
    },
    DEPOSIT_SLIPS: {
      displayColumns: {
        name: true,
        bankName: true,
        branch: true,
        location: hasFacet(facets.trustAccountPerState),
        status: true,
      },
      onClickRow: (trustAccountId) =>
        onClickLink({
          type: 'depositSlipsSpecificAccount',
          id: trustAccountId,
        }),
    },
    END_OF_MONTH: {
      displayColumns: {
        name: true,
        bankName: true,
        branch: true,
        location: hasFacet(facets.trustAccountPerState),
        status: true,
        lastRun: true,
      },
      onClickRow: (trustAccountId) =>
        onClickLink({
          type: 'endOfMonthPageSpecificAccount',
          id: trustAccountId,
        }),
    },
  };

  /**
   * To add a new column:
   *  - Add the data fetcher
   *  - Add columns to displayColumns in config above (only need to specify columns wanted)
   *  - Go to TrustAccountsOverviewTable and add the conditional column with the renderer needed (e.g. dateRenderer for dates)
   */

  const displayColumnDataFetchers = {
    id: (trust) => trust.id,
    accountName: (trust) => trust.accountName,
    displayName: (trust) => trust.displayName,
    name: (trust) => getBankAccountName(trust, t),
    bankName: (trust) => trust.bankName,
    branch: (trust) => trust.branchName,
    bsb: (trust) => trust.branchNumber,
    location: (trust) => trust.location,
    accountNumber: (trust) => trust.accountNumber,
    status: (trust) => trust.state,
    balance: (trust) => getRelevantTrustBalance({ trustAccountId: trust.id, matterId, contactId }),
    lastReconciled: (trust) => getLastReconciledDate(trust.id),
    lastRun: (trust) => getLatestEOMReport(trust.id)?.date,
  };

  if (!config[parentPage]) {
    throw new Error('Parent page details must be added to config object before it can be used');
  }

  const displayColumnsArr = Object.keys(config[parentPage].displayColumns);
  const trustAccounts = getRelevantTrustAccounts({ matterId, contactId })
    .map((trust) => {
      const mappedTrust = {
        id: trust.id,
        name: getBankAccountName(trust, t),
      };
      displayColumnsArr.forEach((col) => {
        mappedTrust[col] = displayColumnDataFetchers[col](trust);
      });
      return mappedTrust;
    })
    .filter((trust) => (showClosedAccounts ? true : trust.status === BANK_ACCOUNT_STATE.OPEN));

  const sortedTrustAccounts = sortTrustAccounts({ sortBy, sortDirection, trustAccounts });

  return (
    <TrustAccountsOverview
      {...{
        ...props,
        showClosedAccounts,
        onClickLink,
        trustAccounts: sortedTrustAccounts,
        matterId,
        contactId,
        sortBy,
        sortDirection,
        ...config[parentPage],
        supportsBsbAccountNumber: hasFacet(facets.bsbAccountNumberForBankAccounts),
        onAccountsSort: ({ sortBy: newSortBy, sortDirection: newSortDirection }) => {
          dispatch(sortFeature.actions.setSortDirection({ sortDirection: newSortDirection }));
          dispatch(sortFeature.actions.setSortBy({ sortBy: newSortBy }));
        },
        toggleClosedAccounts: (name, checked) => {
          setShowClosedAccounts(checked);
        },
      }}
    />
  );
});

TrustAccountsOverviewContainer.displayName = 'TrustAccountsOverviewContainer';

TrustAccountsOverviewContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  matterId: PropTypes.string,
  contactId: PropTypes.string,
  parentPage: PropTypes.string.isRequired,
};

TrustAccountsOverviewContainer.defaultProps = {
  matterId: undefined,
  contactId: undefined,
};
