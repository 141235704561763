const {
  personTitle,
  personTitles,
  contactTypes,
  auCompanyTypes,
  usCompanyTypes,
  auPostalBoxTypes,
  auPostalBoxTypesList,
  companyType,
  contactRoleStrategy,
} = require('./constants');

module.exports = {
  personTitle,
  personTitles,
  contactTypes,
  auCompanyTypes,
  usCompanyTypes,
  auPostalBoxTypes,
  auPostalBoxTypesList,
  companyType,
  contactRoleStrategy,
};
