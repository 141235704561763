import PropTypes from 'prop-types';
import { sortByProperty } from '@sb-itops/nodash';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import * as accountTransactionFilters from 'web/redux/route/home-billing-accounts-transactions';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getTrustAccounts, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import AccountsTransactionsFilterPanel from './AccountsTransactionsFilterPanel';

const mapStateToProps = (state, { trustAccountId, t }) => {
  const { getShowFilters, getFilters } = accountTransactionFilters.selectors;
  const expanded = getShowFilters(state);
  const showHidden = getFilters(state).showHidden;
  const dateListFilter = getFilters(state).dateListFilter;
  const showMarkAsSentEntries = getFilters(state).showMarkAsSentEntries;

  const trustAccounts = getTrustAccounts().map((trust) => ({
    label: getBankAccountName(trust, t),
    id: trust.id,
    // trust.state cannot be trusted if multi trust switch is off, this translates legacy isInactive to state so it can now be trusted
    status: isTrustAccountClosed(trust) ? BANK_ACCOUNT_STATE.CLOSED : BANK_ACCOUNT_STATE.OPEN,
  }));
  const activeTrustAccounts = sortByProperty(
    trustAccounts.filter((trust) => trust.status === BANK_ACCOUNT_STATE.OPEN),
    'label',
    'asc',
    false,
  );

  const selectedTrustAccountId = trustAccountId;

  const showTrustCheques = activeTrustAccounts.length > 0;

  const trustAccountsFilterPanelConfig = {
    parentPageName: 'home.billing.accounts.transactions',
    overviewOnClicklinkParams: { id: 'trust', type: 'transactions', params: ['all'] },
    getSpecificOnClickLinkParams: (trustId) => ({
      type: 'transactions',
      id: 'trust',
      params: [trustId],
    }),
    getProtectedFundsOnClickLinkParams: (trustId) => ({
      id: 'protected-funds',
      type: 'transactions',
      params: [trustId],
    }),
    showProtectedFunds: true,
  };

  return {
    expanded,
    showHidden,
    dateListFilter,
    showMarkAsSentEntries,
    trustAccounts: activeTrustAccounts,
    selectedTrustAccountId: selectedTrustAccountId || '',
    showTrustCheques,
    showOperatingAccount: hasFacet(facets.operatingAccount),
    showDeletedToggle: hasFacet(facets.deleteTransaction),
    trustAccountsFilterPanelConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleExpanded: () => dispatch(accountTransactionFilters.actions.toggleShowFilters()),
  onDateListChange: (value) => dispatch(accountTransactionFilters.actions.setFilter('dateListFilter', value)),
  onDeletedTransactionsChange: (value) => dispatch(accountTransactionFilters.actions.setFilter('showHidden', value)),
  onClientCommunicationToggleChange: (field, value) =>
    dispatch(accountTransactionFilters.actions.setFilter('showMarkAsSentEntries', value)),
});

const AccountsTransactionsFilterPanelContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccountsTransactionsFilterPanel)),
);

AccountsTransactionsFilterPanelContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  accountType: PropTypes.string,
  entity: PropTypes.string,
  scope: PropTypes.string.isRequired,
  trustAccountId: PropTypes.string,
};

AccountsTransactionsFilterPanelContainer.defaultProps = {
  entity: undefined,
  accountType: undefined,
  trustAccountId: undefined,
};

export default AccountsTransactionsFilterPanelContainer;
