/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { Cent } from '@sb-itops/money';
import { CurrencyInput2 } from '@sb-itops/react';
import { ContactDisplay } from '@sb-customer-management/react';
import classnames from 'classnames';

import Styles from './InvoicePaymentAllocationTable.module.scss';

const { balanceCellLocalisedRenderer } = utils;

const checkboxHeaderCellRenderer =
  ({ allocations, onAllocateAll }) =>
  () => {
    const selected =
      allocations.length > 0 && allocations.every((allocation) => allocation.amount === allocation.available);

    return (
      <div className={Styles.allocateCheckbox}>
        <Checkbox checked={selected} onChange={() => onAllocateAll()} />
      </div>
    );
  };

const checkboxCellRenderer =
  ({ onChange }) =>
  ({ rowData }) =>
    (
      <div className={Styles.inclusionCheckbox}>
        <Checkbox
          checked={rowData.amount === rowData.available}
          onChange={() => {
            onChange({ ...rowData, amount: rowData.amount === rowData.available ? 0 : rowData.available });
          }}
        />
      </div>
    );

const footer = ({ cellData }) => (
  <div className={classnames(Styles.rightAlign, Styles.footer)}>{`$${new Cent(cellData.total)}`}</div>
);

const amountCellRenderer =
  ({ onChange }) =>
  ({ rowData, cellData }) =>
    (
      <div className={Styles.rightAlign}>
        <CurrencyInput2
          min={0}
          value={cellData}
          onChange={(e) => {
            const amount = Math.min(e.target.value || 0, rowData.available);
            onChange({ ...rowData, amount });
          }}
        />
      </div>
    );

const amountHeaderRenderer = () => (
  <div className={Styles.rightAlign}>
    <span className="header-label">Amount</span>
  </div>
);

const balanceHeaderRenderer = () => (
  <div className={Styles.availableBalanceColumn}>
    <span className="header-label">Available</span>
  </div>
);

const contactCellRenderer =
  ({ onContactLinkClick }) =>
  ({ cellData }) =>
    <ContactDisplay contactId={cellData} onClickLink={onContactLinkClick} asLink showLastNameFirst />;

const InvoicePaymentAllocationTable = memo(({ allocations, onChange, onContactLinkClick, onAllocateAll }) => {
  // build the summary based on the matters
  const summary = allocations.reduce(
    (acc, allocation) => {
      acc.amount.total += Number.isFinite(allocation.amount) ? allocation.amount : 0;
      return acc;
    },
    {
      amount: {
        total: 0,
      },
    },
  );

  return (
    <Table className={Styles.allocationsTableContainer} list={allocations} summary={summary} showFooter>
      <Column
        dataKey="contactId"
        label="Contact"
        cellRenderer={contactCellRenderer({ onContactLinkClick })}
        flexGrow={14}
        disableSort
      />
      <Column
        dataKey="available"
        label="Available"
        flexGrow={2}
        width={150}
        headerRenderer={balanceHeaderRenderer}
        cellRenderer={({ cellData }) =>
          balanceCellLocalisedRenderer({ cellData, classNames: Styles.availableBalanceColumn })
        }
        disableSort
      />
      <Column
        dataKey="amount"
        label="Amount"
        flexGrow={2}
        width={150}
        cellRenderer={amountCellRenderer({ onChange })}
        disableSort
        headerRenderer={amountHeaderRenderer}
        footerRenderer={footer}
      />
      <Column
        dataKey="UNNEEDED"
        label=""
        width={34}
        cellRenderer={checkboxCellRenderer({ onChange })}
        headerRenderer={checkboxHeaderCellRenderer({ allocations, onAllocateAll })}
        disableSort
      />
    </Table>
  );
});

InvoicePaymentAllocationTable.displayName = 'InvoicePaymentAllocationTable';

InvoicePaymentAllocationTable.propTypes = {
  allocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  onAllocateAll: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func,
};

InvoicePaymentAllocationTable.defaultProps = {
  allocations: [],
  onContactLinkClick: undefined,
};

export default InvoicePaymentAllocationTable;
