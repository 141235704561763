/* eslint-disable no-throw-literal */
const { allocators } = require('@sb-billing/allocation');
const { createAccumulator, extractors } = require('@sb-itops/data-accumulation');

const {
  balanceTypes,
  validAccountTypeForBalanceType,
} = require('@sb-billing/business-logic/bank-account-balances/entities/constants');

const { targetFromSources: allocateFromSources } = allocators;

module.exports = {
  matterBalanceAutoAllocator,
  matterContactBalanceAutoAllocator,
};

/**
 * An invoice totals entity - should come from `redux/invoice-totals`.
 *
 * @typedef  {Object} InvoiceTotals
 * @property {number} total the total amount due on an invoice (cents)
 */

/**
 * @typedef  {object} MatterBalance
 * @property {string} matterId
 * @property {number} balance the balance in the matter for the account (cents)
 * @property {number} [availableBalance] the available balance in the matter for the account (cents)
 * @property {number} [protectedBalance] the protected balance in the matter for the account (cents)
 * @property {'Trust'|'Operating'|'Credit'} type
 */

/**
 * @typedef  {MatterBalance & { contactId: string }} MatterContactBalance
 */

/**
 * The allocations to each account, when in a Matter balance firm
 *
 * @typedef  {Object} MatterAllocations
 * @property {number} [trust] the amount allocated to the trust account (cents)
 * @property {number} [operating] the amount allocated to the operating account (cents)
 * @property {number} [credit] the amount allocated to the credit account (cents)
 */

/**
 * The allocations to each account, when in a Matter-Contact balance firm
 *
 * @typedef  {Object} MatterContactAllocations
 * @property {number} [credit] the amount allocated to the trust account (cents)
 * @property {number} [trust] the amount allocated to the trust account (cents)
 * @property {number} [operating] the amount allocated to the operating account (cents)
 * @property {Object} payments
 */

const matterContactBalanceAllocationAccumulator = createAccumulator({
  $type: extractors.extractTotal({ property: 'amount' }),
  payments: {
    $sourceId: {
      contactId: extractors.extractValue({ property: 'contactId' }),
      type: extractors.extractValue({ property: 'type' }),
      amount: extractors.extractValue({ property: 'amount' }),
    },
  },
});

const getBalanceType = (accountType) =>
  accountType.toUpperCase() in validAccountTypeForBalanceType ? balanceTypes.AVAILABLE : balanceTypes.BALANCE;

/**
 * Get the allocations for the invoice (represented by its totals), from its matter-contact balances
 *
 * @param {InvoiceTotals} invoiceTotals
 * @param {Array<MatterContactBalance} matterContactBalances
 * @param {Array<string>} preferredBankAccountTypes
 * @throws if `invoiceTotals` is falsy
 * @throws if `matterContactBalances` is falsy
 * @returns {MatterContactAllocations}
 */
function matterContactBalanceAutoAllocator(invoiceTotals, matterContactBalances, preferredBankAccountTypes) {
  if (!invoiceTotals || !matterContactBalances) {
    throw new Error('no invoice totals or matter-contact balances specifed for allocation');
  }

  const sorted = preferredBankAccountTypes.flatMap((accountType) => {
    const balanceType = getBalanceType(accountType);
    return matterContactBalances
      .filter((mcb) => mcb.type.toUpperCase() === accountType.toUpperCase())
      .sort((mcba, mcbb) => mcba[balanceType] - mcbb[balanceType]);
  });

  const sources = sorted.map((matterContactBalance) => ({
    amount: matterContactBalance[getBalanceType(matterContactBalance.type)],
    sourceId: `${matterContactBalance.type.toLowerCase()}_${matterContactBalance.contactId}`,
  }));

  const target = {
    targetId: 'unused',
    amount: invoiceTotals.total,
  };

  let accumulatedAllocations = { payments: {} };
  allocateFromSources({
    target,
    sources,
    zeroAllocate: true,
    transform: (allocation) => {
      const [type, contactId] = allocation.sourceId.split('_');
      return {
        ...allocation,
        type,
        contactId,
      };
    },
    accumulate: (allocation) => {
      accumulatedAllocations = matterContactBalanceAllocationAccumulator(accumulatedAllocations, allocation);
    },
  });

  return accumulatedAllocations;
}

const matterBalanceAllocationAccumulator = createAccumulator({
  $accountType: extractors.extractTotal({ property: 'amount' }),
});

/**
 * Get the allocations for the invoice (represented by its totals), from its matter balances
 * @param {InvoiceTotals} invoiceTotals
 * @param {Array<MatterBalance>} matterBalances
 * @param {Array<string>} preferredBankAccountTypes
 * @throws if `invoiceTotals` is falsy
 * @throws if `matterBalances` is falsy
 * @returns {MatterAllocations}
 */
function matterBalanceAutoAllocator(invoiceTotals, matterBalances, preferredBankAccountTypes) {
  if (!invoiceTotals || !matterBalances) {
    throw 'no invoice totals or matter balances specifed for allocation';
  }

  const sorted = preferredBankAccountTypes.flatMap((accountType) =>
    matterBalances.filter((mcb) => mcb.type.toUpperCase() === accountType.toUpperCase()),
  );

  const sources = sorted.map((balance) => ({
    sourceId: balance.type.toLowerCase(),
    amount: balance[getBalanceType(balance.type)],
  }));
  const target = {
    targetId: 'unused',
    amount: invoiceTotals.total,
  };

  let accumulatedAllocations = {};
  allocateFromSources({
    target,
    sources,
    zeroAllocate: true,
    transform: (allocation) => ({
      ...allocation,
      accountType: allocation.sourceId,
    }),
    accumulate: (allocation) => {
      accumulatedAllocations = matterBalanceAllocationAccumulator(accumulatedAllocations, allocation);
    },
  });

  return accumulatedAllocations;
}
