angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/create-deposit-slip';

  $stateProvider
    .state('home.billing.create-deposit-slip', {
      url: '/create-deposit-slip',
      templateUrl: BASE_DIRECTORY + '/create-deposit-slip.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        tab: { type: 'create-deposit-slip' }
      }
    })
    .state('home.billing.create-deposit-slip.trust', {
      url: '/trust/:trustAccountId',
      templateUrl: BASE_DIRECTORY + '/create-deposit-slip.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        accountType: 'TRUST',
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        tab: { type: 'create-deposit-slip' }
      }
    })
    .state('home.billing.create-deposit-slip.operating', {
      url: '/operating',
      templateUrl: BASE_DIRECTORY + '/create-deposit-slip.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      data: {
        accountType: 'OPERATING',
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        tab: { type: 'create-deposit-slip' }
      }
    })
    
});
