import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query RateSetsMatterTableData($matterListFilter: MatterListFilter, $sort: ListSort, $limit: Int, $offset: Int) {
    matterList(filter: $matterListFilter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      sortOrder {
        fieldNames
        directions
      }
      results {
        id
        matterNumber
        matterStarted
        clients {
          id
          displayName
        }
        matterType {
          matterTypeId
          matterTypeIdWithoutLocation
          name
        }
        description
        attorneyResponsible {
          id
          initials
        }
        matterHourlyRate {
          id
          rateSet {
            id
            name
          }
        }
      }
    }
  }
`;

const notificationIds = [
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
  'WebQueryFeesNotifications.MatterHourlyRateUpdated',
  'WebQueryFeesNotifications.RateSetUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const RateSetsMatterTableData = {
  query,
  notificationIds,
};
