const { envType, getAppEnv } = require('@sb-itops/app-env');

const launchDarklyKeys = {
  DEV: '5c5a4dad23fa0d33c8164a1b',
  RC: '5cf9ec43114bcf075bebe594',
  QA: '5c5a4d5f6bb1e233e2a7263e',
  PROD: '5c5a4d5f6bb1e233e2a7263f',
};

const launchDarklyEnvConfig = {
  [envType.LOCAL]: launchDarklyKeys.DEV,
  [envType.DEV]: launchDarklyKeys.DEV,
  [envType.RC]: launchDarklyKeys.RC,
  [envType.QA]: launchDarklyKeys.QA,
  [envType.PRODUCTION]: launchDarklyKeys.PROD,
  [envType.LIVE]: launchDarklyKeys.PROD, // APP_ENV has the value `LIVE` in production lambdas
};

const getLaunchDarklyKey = () => {
  const appEnv = getAppEnv();
  return launchDarklyEnvConfig[appEnv];
}

module.exports = {
  getLaunchDarklyKey,
};

