import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query InvoiceSettingsTemplateTypeahead(
    $filter: InvoiceSettingsTemplateListFilter
    $offset: Int
    $limit: Int
    $searchText: String
  ) {
    invoiceSettingsTemplateSearch(filter: $filter, offset: $offset, limit: $limit, searchText: $searchText) {
      pageInfo {
        hasNextPage
      }
      results {
        id
        name
        isDefault
        isDeleted
      }
    }
  }
`;

const notificationIds = ['WebQueryInvoicingNotifications.InvoiceSettingsTemplateUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceSettingsTemplateTypeahead = {
  query,
  notificationIds,
};
