import composeHooks from '@sb-itops/react-hooks-compose';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useSelector, useDispatch } from 'react-redux';
import * as calendarFiltersFeature from 'web/redux/features/calendar-filters';
import { Person, EventGroup } from 'types';
import moment from 'moment';
import { EventFilters } from './EventFilters';

interface IEventFiltersContainerProps {
  loading: boolean;
  scope: string;
  loggedInStaff: Person;
  setStaffFilter: (newFilter: { [key: string]: boolean }) => void;
  staffFilter: { [key: string]: boolean };
  currentUsers: Person[];
  formerUsers: Person[];
  eventGroups: EventGroup[];
  setSelectedCells: (selectedCells: any[]) => void;
  setSelectedView: (view: string) => void;
  selectedView: string;
  fetchMore: (date: moment.Moment, endDate: moment.Moment) => void;
}

const hooks = ({ scope }: IEventFiltersContainerProps) => ({
  useEventFiltersStore: () => {
    const dispatch = useDispatch();

    const { selectors, actions } = useScopedFeature(calendarFiltersFeature, scope);
    const expanded = useSelector(selectors.getExpanded) as boolean;
    const groupHeaderExpanded = useSelector(selectors.getGroupHeaderExpanded) as boolean;
    const staffHeaderExpanded = useSelector(selectors.getStaffHeaderExpanded) as boolean;
    const myHeaderExpanded = useSelector(selectors.getMyHeaderExpanded) as boolean;
    const formerStaffEnabled = useSelector(selectors.getFormerStaffEnabled) as boolean;

    return {
      toggleExpanded: () => {
        dispatch(actions.toggleExpanded());
      },
      toggleGroupHeaderExpanded: () => {
        dispatch(actions.toggleGroupHeaderExpanded());
      },
      toggleStaffHeaderExpanded: () => {
        dispatch(actions.toggleStaffHeaderExpanded());
      },
      toggleMyHeaderExpanded: () => {
        dispatch(actions.toggleMyHeaderExpanded());
      },
      toggleFormerStaffEnabled: () => {
        dispatch(actions.toggleFormerStaffEnabled());
      },
      expanded,
      groupHeaderExpanded,
      staffHeaderExpanded,
      myHeaderExpanded,
      formerStaffEnabled,
    };
  },
});

export const EventFiltersContainer = composeHooks(hooks)(EventFilters);
