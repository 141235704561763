import { useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';

import {
  useInvoiceCorrespondenceDetailsData,
  useInvoiceTableData,
  useInvoiceTableSelections,
  useSubscribedQuery,
} from 'web/hooks';
import { BillingBulkActions } from 'web/graphql/queries';
import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INVOICE_COMMUNICATE_MODAL_ID, INVOICE_EMAIL_MODAL_ID } from 'web/components';
import { MARK_AS_SENT_MODAL_ID } from 'web/react-redux';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as contactInvoicesReduxState from 'web/redux/route/home-billing-view-contact-bills';
import { featureActive } from '@sb-itops/feature';
import { operationTypes } from '@sb-billing/business-logic/correspondence-history';
import { getLogger } from '@sb-itops/fe-logger';

import { BillingContactInvoicesRoute } from './BillingContactInvoicesRoute';

const log = getLogger('BillingContactInvoicesRoute.container');

const FETCH_LIMIT = 50;

const hooks = () => ({
  useScope: ({ contactId }) => ({
    scope: `BillingContactInvoicesRoute/${contactId}`,
  }),
  useBillingBulkActionsData: ({ contactId }) => {
    const { data } = useSubscribedQuery(BillingBulkActions, {
      variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES, contactIds: [contactId] },
    });

    const isBulkCreateInvoicesInProgress = data?.billingBulkActionList?.totalCount > 0;
    return {
      isBulkCreateInvoicesInProgress,
    };
  },
  useCorrespondenceHistory: () => {
    const latestCorrespondenceHistory = useInvoiceCorrespondenceDetailsData();

    return latestCorrespondenceHistory;
  },
});

const dependentHooks = () => ({
  useTableData: ({ scope }) => {
    const SCOPE_PAGINATION = `${scope}/pagination`;
    const SCOPE_SORTING = `${scope}/sorting`;

    const showDebtor = false;
    const showMatter = true;
    const showStatement = featureActive('BB-14011');
    const filters = useSelector(contactInvoicesReduxState.selectors.getFilters);

    const tableData = useInvoiceTableData({
      scopePagination: SCOPE_PAGINATION,
      scopeSorting: SCOPE_SORTING,
      fetchLimit: FETCH_LIMIT,
      showStatement,
      filters,
    });

    return {
      ...tableData,
      invoiceListReduxState: contactInvoicesReduxState,
      invoiceStatuses: filters.invoiceStatuses,
      showDebtor,
      showMatter,
      showStatement,
    };
  },
  useInvoiceSelection: ({ scope }) => {
    const invoiceTableSelectionDataAndLogic = useInvoiceTableSelections({ scope });

    return invoiceTableSelectionDataAndLogic;
  },
  useSendInvoiceCommunicates: ({ contactId, sbInvoiceSendService, scope }) => {
    // Invoice Communicates are currently only sent via:
    //  1. Invoice row menu (single invoice)
    const isInvoiceViaCommunicateFeatureEnabled = featureActive('BB-9097');

    function onOpenInvoiceCommunicateModal({ invoiceIds, contactId: contactIdFromResendLink }) {
      if (!isInvoiceViaCommunicateFeatureEnabled) {
        return;
      }

      setModalDialogVisible({
        modalId: INVOICE_COMMUNICATE_MODAL_ID,
        props: {
          // If a communicate is being resent from the Sent column icon we pass that
          //  * This is because it can contain other contacts too (from invoices with multiple contacts)
          debtorId: contactIdFromResendLink || contactId,
          invoiceIds,
          scope: `${scope}/invoice-communicate-modal`,
          onPreview: ({ invoiceCommunicateRequest }) =>
            sbInvoiceSendService.createInvoiceCommunicatePreviewP({
              invoiceCommunicateRequest,
            }),
          onSend: async ({ invoiceCommunicateRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
            } catch (err) {
              log.error('Failed to send invoice communicate requests', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceCommunicateModal,
    };
  },
  useSendInvoiceEmails: ({ contactId, sbInvoiceSendService, scope }) => {
    // Invoice emails can be sent via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenInvoiceEmailModal({ invoiceIds, contactId: contactIdFromResendLink }) {
      setModalDialogVisible({
        modalId: INVOICE_EMAIL_MODAL_ID,
        props: {
          consolidateEmails: true,
          // If an email is being resent from the Sent column icon we pass that
          //  * This is because it can contain other contacts too (from invoices with multiple contacts)
          debtorId: contactIdFromResendLink || contactId,
          invoiceIds,
          scope: `${scope}/invoice-email-modal`,
          onPreview: ({ invoiceEmailRequest }) =>
            sbInvoiceSendService.createInvoiceEmailPreviewP({
              invoiceEmailRequest,
            }),
          onSendEmails: async ({ invoiceEmailRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
            } catch (err) {
              log.error('Failed to send invoice emails', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceEmailModal,
    };
  },
  useMarkAsSent: ({ scope }) => {
    // Invoices can be "marked as sent" via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenMarkInvoiceAsSentModal({ invoiceIds }) {
      // This modal is not LOD yet (conversion will occur in the near future)
      setModalDialogVisible({
        modalId: MARK_AS_SENT_MODAL_ID,
        props: {
          selectedItems: invoiceIds,
          scope: `${scope}/mark-as-sent-modal`,
          operationType: operationTypes.INVOICE,
        },
      });
    }

    return {
      onOpenMarkInvoiceAsSentModal,
    };
  },
});

export const BillingContactInvoicesRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(BillingContactInvoicesRoute))),
);

BillingContactInvoicesRouteContainer.displayName = 'BillingContactInvoicesRouteContainer';

BillingContactInvoicesRouteContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbAsyncOperationsService: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbInvoiceSendService: PropTypes.object.isRequired, // Required to send invoice emails/communicates
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
};

BillingContactInvoicesRouteContainer.defaultProps = {};
