import React from 'react';
import PropTypes from 'prop-types';
import { withReduxStore, DropDownList } from '@sb-itops/react';
import Styles from './BankAccountDropDown.module.scss';
// This is just DropDownList wrapper which handles:
// - autoselect of first option when nothing is selected
// - autoselect of first option when selected option is missing in options
const BankAccountDropDownComponent = ({ selectedOptionValue, ...props }) => {
  // The reason we use selectedOptionValue instead of selectedOption is we need to distingush
  // between no option (undefined) and option which is or isn't in the options list
  const { options, onValueChange } = props;

  if (!selectedOptionValue && options.length) {
    // If we don't have any bank account selected, select first one in the list
    // This covers first render when we do not have any bank account selected
    onValueChange(options[0]);
  } else if (selectedOptionValue && !options.find((ta) => ta.value === selectedOptionValue)) {
    // If our selected bank account is not in the list, select first one in the list or none
    // This covers situations such as switching between MTA ON/OFF and closing selected account
    onValueChange(options?.[0] || {});
  }

  const selectedOption = options.find((ta) => ta.value === selectedOptionValue);

  return (
    <div className={Styles.bankAccountSelection}>
      <DropDownList stateless {...props} selectedOption={selectedOption} />
    </div>
  );
};

const BankAccountDropDown = withReduxStore(BankAccountDropDownComponent);

BankAccountDropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    }),
  ),
  selectedOptionValue: PropTypes.string,
  onValueChange: PropTypes.func,
};

BankAccountDropDown.defaultProps = {
  onValueChange: () => {},
  selectedOptionValue: undefined,
};

export default BankAccountDropDown;
