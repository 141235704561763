
angular.module('sb.billing.webapp').component('sbDuiBillingUtilities', {
  require: {group: '^sbCompose'},
    bindings: { },
    templateUrl: 'ng-composable-components/data-ui/custom/billing-utilities/dui-billing-utilities.html',
    controller: function (sbLoggerService, sbEnvironmentConfigService, sbLocalisationService) {
      const ctrl = this;
      const now = moment().toDate();
      const log = sbLoggerService.getLogger('sbDuiBillingUtilities');

      ctrl.onSelectDate = onSelectDate;
      ctrl.onChangeFromDate = onChangeFromDate;
      ctrl.onChangeToDate = onChangeToDate;
      ctrl.t = sbLocalisationService.t;

      ctrl.$onInit = () => {
        ctrl.fromDate = now;
        ctrl.toDate = now;
        ctrl.format = 'DD/MM/YYYY';
        ctrl.region = sbEnvironmentConfigService.getRegion();
        ctrl.errors = {};
        ctrl.disableDateRangePickerSelect = true;
        ctrl.hasLabels = true
      }

      function onSelectDate (doc, key, date) {
        log.info('deposit date range selected', key, date);

        if (ctrl[key].getTime() !== date.getTime()) {
          ctrl[key] = date;
          log.info('date changed');
        }
      }

      const fromDateHasError = (fromDate, toDate) => !fromDate || (toDate && fromDate ? fromDate.getTime() > toDate.getTime() || fromDate.getTime() > Date.now() : false);
      const toDateHasError = (fromDate, toDate) => !toDate || (toDate && fromDate ? toDate.getTime() < fromDate.getTime() : false);

      function onChangeFromDate(fromDate){
        log.info(`from date: ${fromDate}`);
        ctrl.errors.fromDate = fromDateHasError(fromDate, ctrl.toDate);
        fromDate = moment(fromDate).startOf('day').toDate();
        ctrl.onSelectDate(undefined, 'fromDate', fromDate);
      }

      function onChangeToDate(toDate){
        log.info(`to date: ${toDate}`);
        ctrl.errors.toDate = toDateHasError(ctrl.fromDate, toDate);
        toDate = moment(toDate).startOf('day').toDate();
        ctrl.onSelectDate(undefined, 'toDate', toDate);
      }
    }
});