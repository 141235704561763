import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/rate-sets';

angular.module('@sb-billing/services').service('sbRateSetsService', function(sbGenericCacheService, sbEndpointType) {    
    const basePath = 'billing/rate-sets';

    sbGenericCacheService.setupCache({
      name: 'sbRateSetsService',
      sync: {
        endpoint: {
          type: sbEndpointType.SYNC_SINCE,
          stub: basePath,
        },
        poll: 60,
        subscriptions: 'notifier.FeesNotifications.RateSetUpdated',
      },
      updateRedux,
      clearRedux,
    });
  });