import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import {
  getActiveProvider,
  getPaymentFormConfiguration,
  getProviderSettings,
} from '@sb-billing/redux/payment-provider-settings/selectors';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { preCharge as preChargeProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { CreditCardDepositModal } from './CreditCardDepositModal';

const mapStateToProps = (state, { isVisible, isSubmitting, payorId, matterId, bankAccountId }) => {
  const providerType = getActiveProvider();
  const providerSettings = getProviderSettings(providerType);
  const paymentFormConfiguration = getPaymentFormConfiguration({ bankAccountId, providerType });

  return {
    providerType,
    paymentFormConfiguration,
    isVisible,
    isSubmitting,
    payorId,
    matterId,
    bankAccountId,
    clientIsCoveringFee: providerSettings.clientCoversFeeOnDeposits || false,
  };
};

export const CreditCardDepositModalContainer = withReduxProvider(
  connect(mapStateToProps)(({ onSubmit, ...props }) => {
    const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
    const [depositFormReadyForSubmit, setDepositFormReadyForSubmit] = useState(false);
    const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
    const [depositFormData, setDepositFormData] = useState({});

    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();

    const readyForSubmit = depositFormReadyForSubmit && chargeFormReadyForSubmit;
    const isSubmitting = triggerChargeFormSubmit || props.isSubmitting;

    const onHandleSubmit = async (providerFormData) => {
      const paymentFormData = { smokeballFormData: depositFormData, providerFormData };
      await onSubmit(paymentFormData);
      setTriggerChargeFormSubmit(false);
    };

    const onHandlePreCharge = async ({ providerType, providerSpecificFields }) =>
      preChargeProvider({ fetchPostP, providerType, providerSpecificFields });

    return (
      <CreditCardDepositModal
        {...props}
        triggerChargeFormSubmit={triggerChargeFormSubmit}
        onTriggerSubmit={() => setTriggerChargeFormSubmit(true)}
        readyForSubmit={readyForSubmit}
        isSubmitting={isSubmitting}
        chargeFormData={chargeFormData}
        onDepositFormDataChange={setDepositFormData}
        onDepositFormReadyForSubmit={(isReady) => setDepositFormReadyForSubmit(!!isReady)}
        onChargeFormReadyForSubmit={(isReady) => setChargeFormReadyForSubmit(!!isReady)}
        onChargeFormSubmit={onHandleSubmit}
        onChargeFormDataChange={setChargeFormData}
        onPreCharge={onHandlePreCharge}
        totalChargeAmount={depositFormData?.depositAmount}
      />
    );
  }),
);

CreditCardDepositModalContainer.displayName = 'CreditCardDepositModalContainer';

CreditCardDepositModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  payorId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
};

CreditCardDepositModalContainer.defaultProps = {
  payorId: undefined,
  matterId: undefined,
  bankAccountId: undefined,
};

export default CreditCardDepositModalContainer;
