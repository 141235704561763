// An payment could be made for multiple invoices, but only the invoices on this statement should be included
const getInvoiceStatementPayments = ({
  payments,
  statementBalance,
  statementInvoiceMap,
  sort,
  getContactById,
  decodePaymentSource,
}) => {
  let balance = statementBalance; // initial balance is the total of the statement

  const filteredPayments = [];

  payments.forEach((payment) => {
    // Filter out reversed payments
    if (payment.reversedAt !== null) {
      return;
    }

    let paymentAmount = 0;
    const paymentInvoices = [];

    payment.invoices.forEach((invoice) => {
      // Only include amount and invoices on this statement, and imbue the invoice number
      if (statementInvoiceMap[invoice.invoiceId]) {
        paymentAmount += invoice.amount;
        paymentInvoices.push({ ...invoice, invoiceNumber: statementInvoiceMap[invoice.invoiceId].invoiceNumber });
      }
    });

    // If there are no relevant invoices, skip this payment
    if (paymentInvoices.length === 0) {
      return;
    }

    balance = computeRunningBalances({ paymentAmount, remainingBalance: balance });

    filteredPayments.push({
      ...payment,
      amount: paymentAmount,
      invoices: paymentInvoices,
      payor: getContactById(payment.payorId),
      source: decodePaymentSource(payment),
      balance,
    });
  });

  // Sort the filtered payments based on effectiveDate and timestamp
  const sortedPayments = sort(filteredPayments, ['effectiveDate', 'lastUpdated'], ['asc', 'asc']);

  return sortedPayments;
};

const computeRunningBalances = ({ paymentAmount, remainingBalance }) => {
  let balance = 0;
  if (remainingBalance === 0) {
    balance = 0;
  } else {
    balance = remainingBalance - paymentAmount;
  }
  return balance;
};

module.exports = {
  getInvoiceStatementPayments,
};
