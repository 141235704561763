import { getTrustAccount } from '@sb-billing/redux/bank-account';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').component('sbTrustSettings', {
  templateUrl: 'ng-components/trust-settings/trust-settings.html',
  controller: function ($scope, sbLocalisationService) {
    'use strict';

    const ctrl = this;
    ctrl.t = sbLocalisationService.t;
    ctrl.view = {};
    ctrl.view.trustAccountHeading =
      hasFacet(facets.CMA) && featureActive("BB-6381")
        ? `${ctrl.t("trustAccount")} & ${ctrl.t("CMA")} Settings`
        : `${ctrl.t("trustAccount")} Settings`;
        
    const listeners = [
      $scope.$on('$destroy', () => {
        for (let unregister of listeners) unregister();
      }),
      $scope.$on('smokeball-data-update-sbBankAccountService', () => {
        ctrl.trustAccount = JSON.parse(JSON.stringify(getTrustAccount()));
      }),
    ];

    ctrl.$onInit = () => {
      ctrl.trustAccount = JSON.parse(JSON.stringify(getTrustAccount()));
    };
  }
});
