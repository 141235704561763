import { gql } from '@apollo/client';

const query = gql`
  query CalendarEventGroupsData {
    eventGroups {
      id
      name
      color
      isRemoved
    }
  }
`;

const notificationIds = [
  'CalendarSyncSettingsNotifications.AppointmentUpdatedEvent',
  'CalendarSyncSettingsNotifications.ResourceUpdated',
];
// const notificationIds = ['CalendarSyncSettingsNotifications'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const CalendarEventGroupsData = {
  query,
  notificationIds,
};
