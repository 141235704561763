import { store } from '@sb-itops/redux';
import { actionCreators } from 'web/redux/features/communicate/actions';

import { getCommunicateHost } from '@sb-itops/communicate-config';
import { getLogger } from '@sb-itops/fe-logger';
import {
  WebQueryContactGroupsDocument,
  WebQueryContactsDocument,
  WebQueryMattersDocument,
  WebQueryRolesDocument,
  ContactWq,
} from 'web/graphql/types/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TMandatory, nonNullishFieldsGuardFactory, falsyGuard, nonNullishIdGuard } from '@sb-itops/type-helpers';
import { ICommunicateToBillingApi, IBillingToCommunicateApi, TRoleMapping, TMatter } from './api-types';
import { requestMatter, isMatterRequested } from './incoming-subscriptions';
import { getMatterRolesForContact, isContactRequested, setMatterRoles, updateContacts } from './communicate-cache';
import { getApolloClient } from '../apollo';

type TWithType<T> = T & { type: Maybe<string> };
type TTypedRoleMapping = TWithType<TRoleMapping>;

const log = getLogger('COMMUNICATE_HANDSHAKING');

let iframeApiSetup = false;

const sleep = async (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

/*
  It can take a while for the iframe and contentWindow to become available, we need to wait.
  */
const awaitIframe = async (): Promise<Window> => {
  let comm: HTMLIFrameElement | null = null;

  while (!comm || !comm.contentWindow) {
    comm = document.getElementById('CommIframe') as HTMLIFrameElement | null;
    if (!comm || !comm.contentWindow) {
      /* eslint-disable no-await-in-loop */
      await sleep(1000);
    }
  }

  return comm.contentWindow;
};

/*
  If the API is not set up, we should send a handshake ping to the Iframe's window until it is.
  */
export const awaitApiSetup = async () => {
  const contentWindow = await awaitIframe();

  while (!iframeApiSetup) {
    try {
      // this is weird but if this succeeds then we are not cross-origin and should not ping
      contentWindow.location.toString();
    } catch (e) {
      // if this fails we are cross-origin in the iframe and we can send the ping
      contentWindow.postMessage(
        { communicateHandshakePing: { host: `${document.location.protocol}//${document.location.host}` } },
        getCommunicateHost(),
      );
    }

    /* eslint-disable no-await-in-loop */
    await sleep(1000);
  }

  return contentWindow;
};

const billingToCommunicateApi = new Proxy(
  {},
  {
    get(target, prop) {
      return async (...args: any[]) => {
        const contentWindow = await awaitApiSetup();
        log.debug(`billing -> communicate ${prop as any}\n`, args);
        contentWindow.postMessage({ apiCall: { args, func: prop } }, getCommunicateHost());
      };
    },
  },
) as IBillingToCommunicateApi;

const simplifyRolesForCommunicate = (roles: TTypedRoleMapping[]): TTypedRoleMapping[] => {
  const isGenericRole = (roleMapping: TTypedRoleMapping) => {
    const roleName = roleMapping.role.toLocaleLowerCase();

    // holy special cases batman
    return roleName === 'client' || roleName === 'other side' || roleName === 'otherside';
  };

  const genericRoles = roles.filter(isGenericRole);
  const nonGenericRoles = roles.filter((r) => !isGenericRole(r));

  const nonGenericIds = nonGenericRoles.map((r) => r.contactId);

  // eslint-disable-next-line no-restricted-syntax
  for (const r of genericRoles) {
    if (!nonGenericIds.includes(r.contactId)) {
      nonGenericRoles.push(r);
      nonGenericIds.push(r.contactId);
    }
  }

  // finally remove group of people because communicate doesn't understand them
  return nonGenericRoles.filter((r) => r.type !== 'GroupOfPeople');
};

export const notifyCommunicateOfMatterUpdate = async (matterIds: string[]) => {
  const requestedMatterIds = matterIds.filter(isMatterRequested);
  if (requestedMatterIds.length) {
    const apolloClient = getApolloClient();
    const wqMatters = await apolloClient.query({
      query: WebQueryMattersDocument,
      variables: { matterIds },
      fetchPolicy: 'network-only',
    });

    const wqRoles = await apolloClient.query({
      query: WebQueryRolesDocument,
      variables: { matterIds },
      fetchPolicy: 'network-only',
    });

    // before returning roles we need to check for GoP and unroll them, this flattens out all contacts needed
    const directContactsFromRoles = [
      ...(wqRoles?.data?.rolesWq || [])
        .flat()
        .filter(nonNullishFieldsGuardFactory(['entityId']))
        .reduce((acc, i) => {
          acc.add(i.entityId);
          return acc;
        }, new Set<string>()),
    ].filter(Boolean);

    // load any sub-contacts for GroupOfPeople
    const wqContactGroups = await apolloClient.query({
      query: WebQueryContactGroupsDocument,
      variables: { contactIds: directContactsFromRoles },
      fetchPolicy: 'network-only',
    });

    // make a GoP lookup based on parent id
    const groupLookup = (wqContactGroups?.data?.contactGroupsWq || [])
      .filter(nonNullishFieldsGuardFactory(['customerIds', 'parentGroupId']))
      .reduce((acc, i) => {
        if (i.customerIds.length) {
          acc[i.parentGroupId] = i.customerIds.filter(falsyGuard);
        }
        return acc;
      }, {} as Record<string, string[]>);

    // get all sub-contact ids
    const subContacts = Object.values(groupLookup).flat();
    // sub-contacts may be duplicated or also in uniqueContacts so we need to de-dupe and filter out MyFirm
    const totalContacts = [...new Set([...directContactsFromRoles, ...subContacts])].filter((c) => c !== 'MyFirm');

    const wqContacts = await apolloClient.query({
      query: WebQueryContactsDocument,
      variables: { contactIds: totalContacts },
      fetchPolicy: 'network-only',
    });

    // make a lookup so we can pull out contacts as we need them
    const contactLookup = (wqContacts?.data?.contactsWq || []).filter(nonNullishIdGuard).reduce((acc, c) => {
      acc[c.id] = c;
      return acc;
    }, {} as Record<string, TMandatory<ContactWq, 'id'>>);

    if (wqMatters?.data?.mattersWq) {
      const matters = wqMatters.data.mattersWq.filter(nonNullishIdGuard).map((m) => {
        const matter: TMatter = {
          id: m.id,
          descriptionAutomation: m.descriptionAutomation || '',
          description: m.description || '.',
          matterNumber: m.matterNumber || '',
          attorneysResponsibleIds: m.attorneyResponsibleId ? [m.attorneyResponsibleId] : [],
        };

        return matter;
      });

      billingToCommunicateApi.updateMatters({ matters });

      // for each role we need to check if it's a GoP and if it is replace it with the members of the group

      const contactIds: string[] = [];
      // push role updates for all matters

      wqMatters.data.mattersWq.filter(nonNullishIdGuard).forEach((m, matterIdx) => {
        const rawRoles = ((wqRoles?.data?.rolesWq || [])[matterIdx] || []).filter(
          nonNullishFieldsGuardFactory(['entityId']),
        );

        const accumulatedRoles = rawRoles
          .filter((r) => r.entityId !== 'MyFirm')
          .reduce((acc, r) => {
            const roleContact = contactLookup[r.entityId];
            if (roleContact) {
              if (roleContact.type === 'GroupOfPeople') {
                contactIds.push(r.entityId);
                acc.push({ contactId: r.entityId, role: r.roleId || '', type: roleContact.type });
                // fan out role mapping to group
                const customerIds = groupLookup[r.entityId];
                if (customerIds) {
                  contactIds.push(...customerIds);
                  acc.push(
                    ...customerIds
                      .map((entityId) => contactLookup[entityId])
                      .filter(falsyGuard)
                      .map((c) => ({
                        contactId: c.id,
                        role: r.roleId || '',
                        type: c.type,
                      })),
                  );
                }
              } else {
                // accumulate role mapping directly
                contactIds.push(r.entityId);
                acc.push({ contactId: r.entityId, role: r.roleId || '', type: roleContact.type });
              }
            }
            return acc;
          }, [] as TTypedRoleMapping[]);
        setMatterRoles(m.id, accumulatedRoles);
        billingToCommunicateApi.updateRoles({
          roles: simplifyRolesForCommunicate(accumulatedRoles),
          matterId: m.id,
        });
      });

      // push contacts discovered in role updates
      if (contactIds.length) {
        const contacts = contactIds
          .map((id) => {
            const c = contactLookup[id];

            if (!c) {
              return null;
            }

            return {
              id: c.id,
              firstName: c.firstName,
              lastName: c.lastName,
              initials: '',
              email: c.email,
              mobilePhone: c.cell,
              type: c.type,
            };
          })
          .filter(Boolean) as TContact[];

        updateContacts(...contacts);
        billingToCommunicateApi.updateContacts({ contacts });
      }
    }
  }
};

export const notifyCommunicateOfContactUpdate = async (contactIdsRaw: string[], checkCache = false) => {
  const contactIds = checkCache ? contactIdsRaw.filter(isContactRequested) : contactIdsRaw;
  if (contactIds.length) {
    const apolloClient = getApolloClient();
    const wqContacts = await apolloClient.query({
      query: WebQueryContactsDocument,
      variables: { contactIds },
      fetchPolicy: 'network-only',
    });
    if (wqContacts?.data?.contactsWq) {
      const contacts = wqContacts.data.contactsWq.filter(nonNullishIdGuard).map((c) => {
        const contact: TWithType<TContact> = {
          id: c.id,
          firstName: c.firstName || '',
          lastName: c.lastName || '',
          initials: '',
          email: c.email || '',
          mobilePhone: c.cell,
          type: c.type,
        };

        return contact;
      });
      billingToCommunicateApi.updateContacts({ contacts });
      // ok so here we need to find all matters affected by gop contacts
      const gopMatterIds = new Set<string>(
        wqContacts.data.contactsWq
          .filter(nonNullishIdGuard)
          .filter((c) => c.type === 'GroupOfPeople')
          .reduce((acc, c) => {
            const matterIds = Object.keys(getMatterRolesForContact(c.id));
            if (matterIds.length) {
              acc.push(...matterIds);
            }
            return acc;
          }, [] as string[]),
      );

      if (gopMatterIds.size) {
        await notifyCommunicateOfMatterUpdate([...gopMatterIds]);
      }
    }
  }
};

export const init = (
  initOptions: Parameters<IBillingToCommunicateApi['init']>,
  apiDependencies: {
    onClickLink: (options: { type: string; id?: string }) => void;
    setShowAttachFilesModal: ICommunicateToBillingApi['openAttachFilesDialog'];
    setShowAddTaskModal: ICommunicateToBillingApi['createTask'];
    setShowContactModal: ICommunicateToBillingApi['openEditContactDialog'];
  },
) => {
  billingToCommunicateApi.init(...initOptions);

  const communicateToBillingApi: Partial<ICommunicateToBillingApi> = {
    init: () => {
      store.dispatch(actionCreators.setInitialised({ value: true }));
    },
    setUnreadConversationState: ({ unreadConversations }) => {
      store.dispatch(actionCreators.setUnreadConvNotify({ value: unreadConversations }));
    },
    reload: () => {
      iframeApiSetup = false;
      store.dispatch(actionCreators.setSpawned({ value: false }));
      // the CommunicateIframe should detect we are unspawned but visible and respawn
    },
    openMatter: ({ matterId, tab }) => {
      const getMatterClickLinkType = (matterTab: string | null | undefined): string => {
        switch (matterTab) {
          case 'Intake':
            return 'matterIntake';
          default:
            return 'matter';
        }
      };

      apiDependencies.onClickLink({ type: getMatterClickLinkType(tab), id: matterId });
    },
    createTask: ({ matterId, message }) => {
      apiDependencies.setShowAddTaskModal({ message, matterId });
    },
    openEditContactDialog: ({ conversationId, contactId }) => {
      apiDependencies.setShowContactModal({ conversationId, contactId });
    },
    openAttachFilesDialog: apiDependencies.setShowAttachFilesModal,
    refreshMatters: ({ matterIds }) => {
      if (matterIds && matterIds.length) {
        matterIds.forEach(requestMatter);
        notifyCommunicateOfMatterUpdate(matterIds);
      }
    },
    refreshContacts: ({ contactIds }) => {
      if (contactIds && contactIds.length) {
        notifyCommunicateOfContactUpdate(contactIds);
      }
    },
  };

  const listener = async (e) => {
    // pong indicates remote window has responded to ping and is ready for setup
    if (e.data && e.data.communicateHandshakePong) {
      // only want to do this once
      if (!iframeApiSetup) {
        iframeApiSetup = true;
        const contentWindow = await awaitIframe();

        contentWindow.postMessage({ setupIframeApi: true }, getCommunicateHost());
        iframeApiSetup = true;
      }
    }

    // indicates call from remote window to us
    if (e.data && e.data.communicateIframeCall) {
      const funcName = e.data.communicateIframeCall.func as keyof ICommunicateToBillingApi;
      const args = e.data.communicateIframeCall.args;

      if (e.origin === getCommunicateHost()) {
        log.debug(`communicate -> billing ${funcName}\n`, args);
        const possibleFunc = (communicateToBillingApi as any)[funcName];
        if (possibleFunc && typeof possibleFunc === 'function') {
          possibleFunc(...args);
        } else {
          const prettyArgs = (args || []).map(JSON.stringify).join(', ');
          log.info(`unimplemented communicateToBillingApi call: ${funcName}(${prettyArgs})`);
        }
      } else {
        log.error(`dropping communicateToBillingApi call as origin is ${e.origin} expected ${getCommunicateHost()}`);
      }
    }
  };

  window.addEventListener('message', listener);

  const cleanUp = () => {
    window.removeEventListener('message', listener);
  };

  return {
    billingToCommunicateApi,
    cleanUp,
  };
};

export type TContact = {
  id: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string | null;
  mobilePhone?: string | null | undefined;
};
