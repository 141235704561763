angular.module('@sb-billing/services').service('sbReverseWaiveInvoiceCommand', function (sbInvoicingCacheManager, sbUuidService, sbInvoicingService, sbInvoiceTotalsService) {
  'use strict';

  const that = this;
  that.executeP = executeP;

  function getOpdates (id, versionId) {
    const cacheInvoice = _.cloneDeep(sbInvoicingCacheManager.getById(id));
    cacheInvoice.currentVersion.versionId = versionId;
    cacheInvoice.versionIds = [versionId];
    cacheInvoice.currentVersion.status = 'UNPAID';
    cacheInvoice.optimistic = true;
    
    const invoiceTotals = sbInvoiceTotalsService.getTotalsForInvoiceId(id);
    const adjustedInvoiceTotals = sbInvoiceTotalsService.adjustTotalsForReversal(invoiceTotals);

    return { sbInvoicingService: [cacheInvoice], sbInvoiceTotalsService: [adjustedInvoiceTotals]};
  }

  function executeP(id) {
    const versionId = sbUuidService.get();
    return sbInvoicingService.reverseWaiveInvoiceP(id, versionId, getOpdates(id, versionId));
  }

});
