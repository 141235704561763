angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.staff', {
      template: '<sbb-staff-settings></sbb-staff-settings>',
      url: '/staff',
      data: {
        navHighlights: ['staff']
      }
    });
});
