import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { rememberAccountStateParams } from 'web/redux/features/transactions-recent-account-selection/actions';
import PropTypes from 'prop-types';
import { TrustAccountsForFilterPanels } from './TrustAccountsForFilterPanels';

const mapStateToProps = (state, props) => ({
  ...props,
});

const mapDispatchToProps = (dispatch, { onClickLink, overviewOnClicklinkParams, parentPageName }) => ({
  onNavigateOverview: () => {
    dispatch(rememberAccountStateParams({ stateGoParams: undefined, parentPageName }));
    onClickLink(overviewOnClicklinkParams);
  },
});
const TrustAccountsForFilterPanelsContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(TrustAccountsForFilterPanels),
);

TrustAccountsForFilterPanelsContainer.displayName = 'TrustAccountsForFilterPanelsContainer';

TrustAccountsForFilterPanelsContainer.propTypes = {
  showProtectedFunds: PropTypes.bool.isRequired,
  overviewOnClicklinkParams: PropTypes.object.isRequired,
  getSpecificOnClickLinkParams: PropTypes.func.isRequired,
  getProtectedFundsOnClickLinkParams: PropTypes.func,
};

TrustAccountsForFilterPanelsContainer.defaultProps = {
  getProtectedFundsOnClickLinkParams: () => {},
};

export default TrustAccountsForFilterPanelsContainer;
