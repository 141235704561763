import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getLogger } from '@sb-itops/fe-logger';
import { getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { StripeSettings } from './StripeSettings';

const log = getLogger('StripeSettingsContainer');

const hooks = () => ({
  useStripeSettings: () => {
    const stripeSettings = useSelector(() => getProviderSettings(providers.STRIPE) || {});
    const [showSettingsForm, setShowSettingsForm] = useState(() => !!stripeSettings.publicKey);
    const [isLoading, setIsLoading] = useState(false);

    return {
      showSettingsForm,
      isLoading,
      onGetStarted: async () => {
        try {
          setIsLoading(true);
          const requestInfo = {
            connectionStep: 'INIT_PROVIDER_SETTINGS',
          };
          const path = `/billing/payment-provider/connect/stripe/:accountId/`;
          const fetchOptions = { body: JSON.stringify(requestInfo) };
          await fetchPostP({ path, fetchOptions });
          setShowSettingsForm(true);
        } catch (err) {
          log.error('Failed to create Stripe account', err);
          displayErrorToUser('Failed to create Stripe account');
        } finally {
          setIsLoading(false);
        }
      },
    };
  },
});

export const StripeSettingsContainer = withReduxProvider(composeHooks(hooks)(StripeSettings));

StripeSettingsContainer.displayName = 'StripeSettingsContainer';
StripeSettingsContainer.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};
StripeSettingsContainer.defaultProps = {};
