import store from './store';

/**
 * A Higher-Order function that passes the relevant part of the store to the specified `selector`.
 * This allows us to write selectors such that they have no idea that they have been wrapped in a subsection of the state tree.
 *
 * For use with conjunction with `sbPathMiddleware` & `createSbPathReducer`.
 *
 * @param {String} reduxPath A string representing the location of the code that uses the section of the redux store.
 * @param {function()} selector The selector function in the form (state) => <a subsection of the state>.
 */
export const createSbPathSelector =
  ({ reduxPath, selector = (state) => state }) =>
  () =>
    selector(store.getState()[reduxPath]);
