import React, { memo, useCallback, useState, Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { invoiceSettingTitleOptions } from './helpers';

// TODO THIS IS DEPRECATED. with the invoice template feature (BB-2394) the state is not mantain anymore in the component
// so InvoiceSettingTitle is the one in change of this now. I'm leaving for now this component so it is  backwards compatible
const InvoiceSettingTitle2 = memo(
  ({
    selectedOption,
    selectedText,
    onChangeOption,
    onChangeText,
    showFirmOption,
    showResetButton,
    defaultText,
    defaultSelectedOption,
  }) => {
    const customTextRef = useRef(null);
    const selectedOptionsProp = selectedOption;
    const [selectionState, setSelection] = useState(selectedOptionsProp);
    const [inputState, setInput] = useState(selectedText);

    useEffect(() => {
      onChangeOption(selectionState);
      onChangeText(inputState);
    });

    const onTitleClickHandler = useCallback(
      (e) => {
        setSelection(e.target.parentElement.id);
        onChangeOption(e.target.parentElement.id);
      },
      [onChangeOption],
    );

    const checkedHandler = useCallback(
      (e) => {
        e.stopPropagation();
        setSelection(e.target.value);
        onChangeOption(e.target.value);
      },
      [onChangeOption],
    );

    useEffect(() => {
      if (selectionState === invoiceSettingTitleOptions.custom) {
        customTextRef.current.focus();
      }
    }, [selectionState]);

    const textChangeHandler = useCallback(
      (e) => {
        setInput(e.target.value);
        onChangeText(e.target.value);
      },
      [onChangeText],
    );

    const resetHandler = useCallback(() => {
      const resetValueOption = showFirmOption ? invoiceSettingTitleOptions.firm : defaultSelectedOption;
      setSelection(resetValueOption);
      setInput(defaultText);
      onChangeOption(resetValueOption);
      onChangeText(inputState);
    }, [defaultSelectedOption, defaultText, inputState, onChangeOption, onChangeText, showFirmOption]);

    return (
      // TODO create a radio button component in react so we can reuse this code
      /* eslint-disable */
      <Fragment>
        <div className="title-radio list-v-summary invoice-setting-row">
          {showFirmOption && (
            <span
              className="single-line"
              id={invoiceSettingTitleOptions.firm}
              role="button"
              tabIndex={0}
              onClick={onTitleClickHandler}
            >
              <input
                type="radio"
                checked={selectionState === invoiceSettingTitleOptions.firm}
                onChange={checkedHandler}
                value={invoiceSettingTitleOptions.firm}
              />
              <label>{"Firm"}</label>
            </span>
          )}
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.none}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectionState === invoiceSettingTitleOptions.none}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.none}
            />
            <label>{"None"}</label>
          </span>
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.matterTitle}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectionState === invoiceSettingTitleOptions.matterTitle}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.matterTitle}
            />
            <label>{`Matter Title`}</label>
          </span>
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.matterDescription}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectionState === invoiceSettingTitleOptions.matterDescription}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.matterDescription}
            />
            <label>{`Matter Description`}</label>
          </span>
          <span
            className="single-line"
            id={invoiceSettingTitleOptions.custom}
            role="button"
            tabIndex={0}
            onClick={onTitleClickHandler}
          >
            <input
              type="radio"
              checked={selectionState === invoiceSettingTitleOptions.custom}
              onChange={checkedHandler}
              value={invoiceSettingTitleOptions.custom}
            />
            <label>{"Custom text"}</label>
          </span>
          {selectionState === invoiceSettingTitleOptions.custom && (
            <div className="input-group">
              <input
                ref={customTextRef}
                type="text"
                className="form-control title-line-text"
                onChange={textChangeHandler}
                onBlur={textChangeHandler}
                name="titleLine2CustomText"
                value={inputState}
              />
            </div>
          )}
        </div>
        {showResetButton && selectionState !== invoiceSettingTitleOptions.firm && (
          <LinkableText asLink text="Reset" onClickLink={resetHandler} inline />
        )}
      </Fragment>
      /* eslint-enable */
    );
  },
);

InvoiceSettingTitle2.displayName = 'InvoiceSettingTitle2';

InvoiceSettingTitle2.propTypes = {
  selectedOption: PropTypes.string,
  selectedText: PropTypes.string,
  selectedMatterDescriptionText: PropTypes.string,
  defaultSelectedOption: PropTypes.string,
  defaultText: PropTypes.string,
  onChangeOption: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  showFirmOption: PropTypes.bool,
  showResetButton: PropTypes.bool,
};

InvoiceSettingTitle2.defaultProps = {
  selectedOption: undefined,
  selectedText: undefined,
  selectedMatterDescriptionText: undefined,
  defaultSelectedOption: undefined,
  defaultText: undefined,
  showFirmOption: false,
  showResetButton: false,
};

export default InvoiceSettingTitle2;
