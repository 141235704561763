import React from 'react';
import PropTypes from 'prop-types';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { useTranslation } from '@sb-itops/react';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { sortByProperty } from '@sb-itops/nodash';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import {
  getById as getTrustChequePrintSettingsById,
  getDefaultTrustChequePrintSettings,
} from '@sb-billing/redux/trust-cheque-print-settings';
import ChequeSettingsSection from './ChequeSettingsSection.container';

const TrustChequeSettingsSection = ({ scope, disabled }) => {
  const { t } = useTranslation();

  const bankAccounts = sortByProperty(
    getActiveTrustAccounts().map((ba) => ({ value: ba.id, label: getBankAccountName(ba, t) })),
    'label',
    'asc',
    false,
  );
  const activateLabel = `Activate ${t('trustCheque')} Printing`;

  const getInitValues = ({ bankAccountId }) => {
    const chequeSettings = getTrustChequePrintSettingsById(bankAccountId) || getDefaultTrustChequePrintSettings();
    return {
      printMethod: chequeSettings.printMethod,
      chequeStyle: chequeSettings.style,
      printingActive: chequeSettings.printingActive,
      bankAccountId, // id or undefined
    };
  };

  return (
    <ChequeSettingsSection
      scope={scope}
      disabled={disabled}
      bankAccountType={bankAccountTypeEnum.TRUST}
      allowBankAccountChange
      bankAccounts={bankAccounts}
      activateLabel={activateLabel}
      getInitValues={getInitValues}
    />
  );
};

TrustChequeSettingsSection.displayName = 'TrustChequeSettingsSection';

export default TrustChequeSettingsSection;

TrustChequeSettingsSection.propTypes = {
  scope: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
TrustChequeSettingsSection.defaultProps = {
  disabled: false,
};
