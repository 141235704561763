import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '../with-redux-store';
import { withOnLoad } from '../hoc';
import { AuthenticatorTokenForm } from './AuthenticatorTokenForm';

const defaultTooltip =
  'This is the 6 digit two-factor authentication code that is generated by an authenticator application.';

const mapStateToProps = () => ({});

export const AuthenticatorTokenFormContainer = withReduxStore(
  connect(mapStateToProps)(
    withOnLoad((props) => {
      const [errorMessages, setErrorMessages] = useState([]);
      const [authCode, setAuthCode] = useState('');

      const onAuthCodeUpdated = (newAuthCode) => {
        setAuthCode(newAuthCode);
        setErrorMessages([]); // Clear form error messages if any
      };

      const onValidateAndVerify = () => {
        if (!authCode || authCode.trim() === '') {
          setErrorMessages([{ message: 'Please enter a valid authentication code' }]);
          return;
        }

        props.onVerify({ authenticatorToken: authCode });
      };

      return (
        <AuthenticatorTokenForm
          authCode={authCode}
          tooltip={props.tooltip}
          errorMessages={props.errorMessages.length ? props.errorMessages : errorMessages}
          verifyInProgress={props.verifyInProgress}
          onAuthCodeUpdated={onAuthCodeUpdated}
          onVerify={onValidateAndVerify}
          onCancelVerify={props.onCancelVerify}
        />
      );
    }),
  ),
);

AuthenticatorTokenFormContainer.displayName = 'AuthenticatorTokenFormContainer';

AuthenticatorTokenFormContainer.propTypes = {
  tooltip: PropTypes.string,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      isVerbose: PropTypes.bool,
    }),
  ),
  verifyInProgress: PropTypes.bool,
  onVerify: PropTypes.func.isRequired,
  onCancelVerify: PropTypes.func.isRequired,
};

AuthenticatorTokenFormContainer.defaultProps = {
  tooltip: defaultTooltip,
  errorMessages: [],
  verifyInProgress: false,
};
