'use strict';

const moment = require('moment');

module.exports = (value, lng, options) => {
  // in future, we can use lng to correctly use non-english locale
  let format;
  if (options.interpolationkey === value) {
    // Format wasn't interpolated - use whatever format was passed directly
    // This is so we can pass for example "YYYY" and not specify it in dictionary
    format = options.format;
  } else {
    format = value;
  }
  const { ts, yyyymmdd, date, unix, inUtc } = options;
  let momentDate;

  if (ts) {
    momentDate = inUtc ? moment.utc(ts).local() : moment(ts);
  } else if (date) {
   // As date objects are always local, doesn't make sense to convert from utc either
    momentDate = moment(date);
  } else if (yyyymmdd) {
    // As yyyymmdd has no time info, it doesn't make sense to convert from utc
    momentDate = moment(yyyymmdd, 'YYYYMMDD');
  } else if (unix) {
    // tick time
    momentDate = moment.unix(unix);
  } else {
    console.error(`One of ts|date|yyyymmdd|unix must be specified.`);
    return '';
  }

  if (!momentDate.isValid()) {
    console.error(`Not a valid moment date`, ts || yyyymmdd || date);
    return '';
  }

  return momentDate.format(format);
};
