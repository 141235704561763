'use strict';

/**
 * @typedef { import('../types.js').LawpayChargeFormData } LawpayChargeFormData
 */
const { paymentMethods } = require('./payment-methods');

/**
 * getPaymentSource
 *
 * @param {Object} params
 * @param {LawpayChargeFormData} params.providerSpecificFields The data received from the lawpay charge form

 * @returns {string} A human readable payment source.
 */
const getPaymentSource = ({ providerSpecificFields }) => {
  const { paymentMethod } = providerSpecificFields;

  if (!paymentMethod) {
    throw new Error('providerSpecificChargeData must contain a valid paymentMethod');
  }

  switch (paymentMethod) {
    case paymentMethods.CREDIT_CARD:
      return 'Credit Card';

    case paymentMethods.ECHEQUE:
      return 'eCheck';

    default:
      throw new Error(`Failed to build payment source due to unsupported payment method of ${paymentMethod}`);
  }
};

module.exports = {
  getPaymentSource,
};
