import uuid from '@sb-itops/uuid';
import { PAYMENT_TYPE, getSplitPayors, getPaymentSources, getPaymentSource, getDefaultPaymentSource } from 'web/redux/selectors/payment-source';
import { isMatterBalanceType, isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { dateToInteger, today } from '@sb-itops/date';
import { featureActive } from '@sb-itops/feature';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import {
  getNumberingSettings as getTransactionNumberingSettings,
} from '@sb-billing/redux/bank-account';
import { getById as getTrustChequePrintSettingsById } from '@sb-billing/redux/trust-cheque-print-settings';
import { roundCents } from '@sb-billing/bankers-rounding';
import { getByBankAccountId as getTrustChequesByBankAccountId, chequeExistsForBankAccount } from '@sb-billing/redux/trust-cheques';
import { getUserId } from 'web/services/user-session-management';
import { matterHasProtectedFundsForBankAccountId } from '@sb-billing/redux/balance-protection';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import {
  printMethods as printMethodsList,
  notApplicablePrintMethodLocalised,
  PrintManually,
  PrintNow,
  PrintLater,
  PrintNotApplicable,
  printMethodsByCode,
} from '@sb-billing/business-logic/cheques';
import { findLastChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular.module('sb.billing.webapp').component('sbCbuiInvoicePaymentEntrySplitPayors', {
  bindings: {
    onClose: '&?',
    invoiceId: '=',
    callbackFn : '&',
    bankAccountType: '<?',  // 'Operating' | 'Trust'
    printCheque: '&'
  },
  templateUrl: 'ng-composable-components/callback-ui/invoice-payment-entry-split-payors/cbui-invoice-payment-entry-split-payors.html',
  controller: function ($scope, $timeout, sbLoggerService, focusService, sbInvoicingService,
    sbInvoiceTotalsService, sbMessageDisplayService, sbTrustChequePrintSettingsService, 
    sbTrustChequeService, sbMattersMbService, sbSimpleContactMbService, sbLocalisationService
  ) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbCbuiInvoicePaymentEntrySplitPayors');

    // config
    ctrl.matterDescriptionRequiredForTrustDeposit = hasFacet(facets.matterDescriptionRequired);
    ctrl.matterClientRequiredForTrustDeposit = hasFacet(facets.matterClientRequiredForTrustDeposit);
    ctrl.matterClientOrDescriptionRequired = ctrl.matterDescriptionRequiredForTrustDeposit || ctrl.matterClientRequiredForTrustDeposit;
    ctrl.matterClientAddressRequiredForTrustDeposit = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);
    ctrl.showPaymentReason = hasFacet(facets.reasonField);
    ctrl.showChequeMemo = hasFacet(facets.chequeMemo);
    ctrl.allowOverdraw = hasFacet(facets.allowOverdraw);
    ctrl.supportsTtoNumbering = hasFacet(facets.ttoNumbering);
    ctrl.operatingAccountForOverpayment = hasFacet(facets.operatingAccount);

    let isTrustPayment;
    let reasonOverridden = false;

    ctrl.showErrors = false;
    let defaultPayorId;
    ctrl.t = sbLocalisationService.t;
    ctrl.prepField = prepField;
    ctrl.getOutstandingBalance = getOutstandingBalance;
    ctrl.getOutstandingWithoutOverpay = getOutstandingWithoutOverpay;
    ctrl.getLastChequeNumber = getLastChequeNumber;
    ctrl.addPayment = addPayment;
    ctrl.toggleAddContact = toggleAddContact;
    ctrl.readonlyReference = readonlyReference;
    ctrl.isOverpaymentFromDirectPaymentSource = isOverpaymentFromDirectPaymentSource;
    ctrl.matterHasDescription = matterHasDescription;
    ctrl.matterHasClients = matterHasClients;
    ctrl.isMissingSomeMatterClientAddresses = isMissingSomeMatterClientAddresses;
    ctrl.disableProcessButton = disableProcessButton;

    ctrl.onSelectPayor = onSelectPayor;
    ctrl.onSaveContact = onSaveContact;
    ctrl.onAmountChange = onAmountChange;
    ctrl.onAmountChangeHandler = onAmountChangeHandler;
    ctrl.onPayorsUpdate = onPayorsUpdate;
    ctrl.onChangePrintMethod = onChangePrintMethod;
    ctrl.onDateChange = onDateChange;
    ctrl.onPaymentSource = onPaymentSource;
    ctrl.PAYMENT_TYPE = PAYMENT_TYPE;
    ctrl.onReasonChange = onReasonChange;
    ctrl.shouldDisableTtoPdf = shouldDisableTtoPdf; 

    $scope.$watch('invoicePaymentForm', (invoicePaymentForm) => {
      if (invoicePaymentForm) {
        $scope.$watch('invoicePaymentForm.$dirty', (dirty) => {
          ctrl.showErrors = dirty;
        });
      }
    });

    ctrl.$postLink = () => {
      focusService.focusOn('invoice-payment-amount', true);
    };

    ctrl.$onInit = async () => {
      log.info(`init, invoiceId ${ctrl.invoiceId}, bankAccount ${ctrl.bankAccountType}`);
      ctrl.printMethods = [notApplicablePrintMethodLocalised(sbLocalisationService.t), ...printMethodsList];

      const invoiceTotals = sbInvoiceTotalsService.getTotalsForInvoiceId(ctrl.invoiceId);
      if (!invoiceTotals) {
        sbMessageDisplayService.warn('Failed to load invoice totals found for this invoice');
      }

      ctrl.addContactsOpen = false;
      const invoice = sbInvoicingService.getInvoice(ctrl.invoiceId);

      const paymentSources = getPaymentSources({ matterId: invoice.matterId, t: sbLocalisationService.t, allowOverdraw: ctrl.allowOverdraw });
      const source =
        (ctrl.bankAccountType && getPaymentSource(paymentSources, ctrl.bankAccountType)) // if trust/operating account is specified (e.g. "pay now" link on RHS of view invoice screen)
        || getDefaultPaymentSource(paymentSources); // gets default payment source if bank account is not specified as input to this component

      defaultPayorId = invoice.debtors.length === 1 ? invoice.debtors[0].id : undefined;
      const overpaymentAccountLabel = ctrl.operatingAccountForOverpayment ? ctrl.t('operatingAccount') : ctrl.t('trustAccount');
      
      ctrl.view = {
        overpaymentAccountLabel: overpaymentAccountLabel.toLowerCase(),
        invoiceTotals,
        trustChequesEnabled: isTrustChequePrintingActive(),
        paymentSources,
        isMatterBalanceType: isMatterBalanceType(),
        isMatterContactBalanceType: isMatterContactBalanceType(),
        isDirectPayment: !!source && source.paymentType === PAYMENT_TYPE.direct,
        isTrustPayment: !!source && source.paymentType === PAYMENT_TYPE.trust,
        matter: null,
        matterClientContactsWithMissingStreetAddress: null,
      };

      const bankAccountSettings = getBankAccountSettings() || {};
      
      ctrl.model = {
        pdfOnTrustPayment: bankAccountSettings.createPDFReceiptOnTrustPayment,
        printingMethod: ctrl.printMethods[0],
        payorId: getDefaultPayorId(source),
        invoice,
        date: new Date(),
        effectiveDate: today(),
        amount: getDefaultAmount(source, invoiceTotals),
        source,
      };
      ctrl.errors = {};
      updateDefaultReason();

      log.info('invoiceId %s, invoice', ctrl.invoiceId, ctrl.model.invoice);
      payorSelected();

      isTrustPayment = ctrl.model && ctrl.model.source && ctrl.model.source.paymentType === PAYMENT_TYPE.trust;
      ctrl.view.trustChequesEnabled = isTrustChequePrintingActive();
      await checkMatterClientAddressesIfAu();
    };

    function chequeExists(num) {
      if (!isTrustPayment) {
        return false;
      }

      return chequeExistsForBankAccount(num, ctrl.model.source.bankAccountId)
    }

    function getLastChequeNumber () {
      if (!isTrustPayment) {
        return undefined;
      }

      return findLastChequeNumber(getTrustChequesByBankAccountId(ctrl.model.source.bankAccountId));
    }

    function isTrustToOfficeNumberingEnabled () {
      if (!isTrustPayment) {
        return false;
      }

      const { trustToOfficeNumberingSettings } = getTransactionNumberingSettings({
        bankAccountId: ctrl.model.source.bankAccountId,
      });
      return !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering
    }

    function isTrustChequePrintingActive() {
      if (!isTrustPayment) {
        return false;
      }

      return !!(getTrustChequePrintSettingsById(ctrl.model.source.bankAccountId) || {}).printingActive;
    }
    
    function getDefaultPayorId (source) {
      // if there is no source selected, dont provide a default payor
      // (no source selected would likely be a timing issue/error state)
      if (!source) {
        return;
      }

      // if the source is a contact source, use that contact. (only possible in matter-contact balances)
      if (source.contactId) {
        return source.contactId;
      }

      // if the source is a direct payment source, use the default debtor (invoice debtor). (applicable to both CMB and MB).
      if (source.paymentType === PAYMENT_TYPE.direct) {
        return defaultPayorId;
      }

      // otherwise its a combined source or a matter balance source - no payorId allowed.
    }

    async function checkMatterClientAddressesIfAu() {
      if (ctrl.matterClientAddressRequiredForTrustDeposit) {
        ctrl.view.matterClientContactsWithMissingStreetAddress = null; // reset and check again
        if (ctrl.model.invoice) {
          ctrl.view.matter = await sbMattersMbService.getById(ctrl.model.invoice.matterId);
          ctrl.view.matterClientContactsWithMissingStreetAddress = await sbSimpleContactMbService.findAuContactsWithMissingStreetAddress(ctrl.view.matter.clientCustomerIds);  
        }
      }
    }

    function matterHasClients() {
      if (!ctrl.matterClientRequiredForTrustDeposit) {
        // this function is used to display correct error so if matter client is not required
        // we treat it as that matter has clients
        return true;
      }
      return ctrl.view.matter && ctrl.view.matter.clientCustomerIds && ctrl.view.matter.clientCustomerIds.length > 0;
    }

    function isMissingSomeMatterClientAddresses() {
      return ctrl.view.matterClientContactsWithMissingStreetAddress && 
        ctrl.view.matterClientContactsWithMissingStreetAddress.length > 0;
    }

    function getDefaultAmount (source, invoiceTotals) {
      if (!source) {
        return invoiceTotals.unpaid;
      }

      if (source.isCombinedBalance) {
        return 0;
      }

      return source.balance ? Math.min(invoiceTotals.unpaid, source.balance || 0) : invoiceTotals.unpaid;
    }

    function onPaymentSource(option) {
      const oldSource = ctrl.model.source;
      ctrl.model.source = option;
      ctrl.view.isDirectPayment = ctrl.model.source.paymentType === PAYMENT_TYPE.direct;
      isTrustPayment = ctrl.model.source.paymentType === PAYMENT_TYPE.trust;
      ctrl.view.isTrustPayment = isTrustPayment;
      ctrl.view.trustChequesEnabled = isTrustChequePrintingActive();

      if (ctrl.view.isMatterBalanceType) {
        if (ctrl.view.isDirectPayment) {
          if (!ctrl.model.payorId) {
            ctrl.model.payorId = defaultPayorId;
          }
        } else {
          ctrl.model.payorId = undefined
        }

        onAmountChange(ctrl.model.amount);

      } else if (ctrl.view.isMatterContactBalanceType) {
        if (ctrl.model.source.contactId) {
          // set model.payorId and do select payor logic
          ctrl.model.payorId = ctrl.model.source.contactId;
          payorSelected();
        }

        if (ctrl.view.isDirectPayment) {
          ctrl.model.payorId = defaultPayorId;
        }

        // if the user has changed to a combined source
        if (ctrl.model.source.isCombinedBalance) {
          ctrl.view.accountType = ctrl.model.source.paymentType;
          ctrl.model.payorId = null;

          // if they have re-selected the same source (possible due to the component that calls this callback)
          if (oldSource && ctrl.model.source.paymentType === oldSource.paymentType && oldSource.isCombinedBalance) {
            // update the payment amount
            onAmountChange(ctrl.model.amount);
          }
          // otherwise they have selected a new combined payment source
          else {
            // update the payors and clear everything
            ctrl.view.splitPayors = getSplitPayors(ctrl.model.source.paymentType, ctrl.model.invoice.matterId);
            onAmountChange(0);
          }
        } else {
          // the user has changed to a source that requires the split payors list to be cleared BUT keep the amount the same
          // Ex: selecting a non-combined source
          onAmountChange(ctrl.model.amount);
        }
      }

      ctrl.model.reference = readonlyReference() ? '' : ctrl.model.reference;
      updateDefaultReason();

      prepField('effectiveDate');
      prepField('reference');
      prepField('payor');
      prepField('amount');
      prepField('reason');
    }

    function readonlyReference() {
      const isReadOnlyChequePayment = isTrustPayment
        && ctrl.model.printingMethod 
        && (isTrustChequePrintingActive() && ctrl.model.printingMethod.value === PrintNow || ctrl.model.printingMethod.value === PrintLater);

      const isReadOnlyNonChequePayment = isTrustPayment
        && ctrl.supportsTtoNumbering
        && isTrustToOfficeNumberingEnabled()
        && (ctrl.model.printingMethod && ctrl.model.printingMethod.value === PrintNotApplicable);

      return isReadOnlyChequePayment || isReadOnlyNonChequePayment;
    }

    function onAmountChangeHandler(event) {
      // Angular is managing this form using events
      // It doesnt know about the react input so wont mark the form as dirty, we will manually do this
      ctrl.showErrors = true;
      onAmountChange(event.target.value);
    }

    function onAmountChange (newAmount) {
      if (ctrl.model.amount !== newAmount) {
        ctrl.model.amount = newAmount;
        prepField('amount');
        prepField('effectiveDate');
      }
    }

    function onSaveContact(contactId) {
      ctrl.model.payorId = contactId;
      // user selections become the new default
      defaultPayorId = ctrl.model.payorId;
      $timeout(payorSelected);
      ctrl.addContactsOpen = false;
    }

    function onDateChange () {
      prepField('effectiveDate');
      prepField('amount');
    }

    function toggleAddContact() {
      ctrl.addContactsOpen = !ctrl.addContactsOpen;
    }

    function onSelectPayor() {
      // user selections become the new default
      defaultPayorId = ctrl.model.payorId;
      payorSelected();
    }

    function payorSelected() {
      prepField('payor');
      focusService.focusOn('invoice-payment-reference');
    }

    function onPayorsUpdate(payors) { //map of ids and amount
      log.info(`payors updated`, payors);
      ctrl.model.payors = payors;
      ctrl.showErrors = true;
      const amount = Object.values(payors).reduce((total, amount) => Number.isInteger(amount) ? amount + total : total, 0);
      onAmountChange(amount);
    }

    function getOutstandingBalance() {
      return roundCents(ctrl.view.invoiceTotals.unpaid - (ctrl.model.amount || 0));
    }

    /**
     * If it is not an overpayment, returns the outstanding balance.
     * If it is an overpayment, returns 0;
     */
    function getOutstandingWithoutOverpay() {
      const outstandingBalance = getOutstandingBalance();
      
      return outstandingBalance < 0 ? 0 : outstandingBalance;
    }

    /**
     * Overpayments are allowed from direct payment source such as
     * Check, Cash, Credit Card and Bank Transfers
     */
    function isOverpaymentFromDirectPaymentSource() {
      return ctrl.model.source && ctrl.model.source.paymentType === ctrl.PAYMENT_TYPE.direct && ctrl.getOutstandingBalance() < 0;
    }

    function matterHasDescription() {
      if (!ctrl.matterDescriptionRequiredForTrustDeposit) {
        // this function is used to display correct error so if description is not required
        // we treat it as that matter has description
        return true;
      }
      return (ctrl.view.matter && ctrl.view.matter.description && ctrl.view.matter.description.length > 0) || false;
    }

    /** 
     * Overpayments are NOT allowed from FIRM bank accounts such as "Operating" and "Trust" accounts
     * It's only an overpayment if the amount they are paying is more than what they owe
     */
    function isAccountOverpayment() {
      return !ctrl.view.isDirectPayment && (ctrl.model.amount > ctrl.view.invoiceTotals.unpaid);
    }

    function hasAvailableBalance() {
      return ctrl.model.source && _.isNumber(ctrl.model.source.balance);
    }

    function amountExceedsAvailableBalance() {
      const hasProtectedFunds = matterHasProtectedFundsForBankAccountId(ctrl.model.invoice.matterId, ctrl.model.source.bankAccountId);
      // we allow overdraw on the balance in AU for compliance.
      if (ctrl.allowOverdraw && ctrl.model.source.paymentType !== 'Credit' && !hasProtectedFunds) {
        return false;
      }
            
      return hasAvailableBalance() && ctrl.model.amount > ctrl.model.source.balance;
    }

    function isUsingTrustCheque() {
      return isTrustChequePrintingActive()
        && ctrl.model.printingMethod.value !== PrintNotApplicable;
    }

    // trust cheque printing is not applicable when
    // 1. trust cheque is not enabled for firm
    // 2. Not Applicable is selected as trust cheque print method
    // 3. print method is not selected (for some reason, we defensively assume it's Not Applicable)
    function isTrustChequeNotApplicable() {
      return !isTrustChequePrintingActive()
        || ctrl.model.printingMethod.value === PrintNotApplicable
        || !ctrl.model.printingMethod || !ctrl.model.printingMethod.value
    }

    function isTrustChequePrintManually() {
      return isTrustChequePrintingActive()
        && ctrl.model.printingMethod
        && ctrl.model.printingMethod.value === PrintManually;
    }

    // For trust payments, reference seems to be used for two separate purposes
    // 1. When Trust Cheque is used for the invoice payment: Reference is treated like a cheque number,
    //    and has to be numeric. This same reference number is used in the 1) cheque 2) payment & 3) trust
    //    transaction records associated with this invoice payment.
    //    a) In the PrintManually scenario, this reference field is required
    //    b) In non-PrintManually scenarios, this reference number won’t be allocated until the cheque is printed.
    // 2. When trust payment is made with bank transfer: i.e. either
    //    a) trust cheque is turned off for the firm or 
    //    b) print method is “Not Applicable (Bank Transfer)”
    //    Reference here can be non-numeric and is used as the reference number for 1) payment and 2) transaction
    //    record associated with the invoice payment. This field is now required when auto numbering for
    //    Trust to Office Transfer is turned OFF in AU.
    // 
    // For non-trust payment types, it seems this is a manually allocated reference number and is not required.
    //
    // History (AU only)
    // Before BB-8425 was implemented, transaction number used to use auto numbering created for Electronic Payments.
    // It is now using auto numbering for Trust to Office Transfer. One notable difference with this change is that
    // when Trust to Office Transfer auto numbering is turned OFF, the transaction reference field is now a required field.
    //
    // Technical Notes
    // When making changes to reference or invoice payment in general, please consider both
    // 1. Matter > Invoices > Add Payment
    // 2. Invoice > Payments > Add Payment (this file)
    function isReferenceRequired() {
      return isTrustPayment && (
        isTrustChequePrintManually() || (ctrl.supportsTtoNumbering && isTrustChequeNotApplicable() && !isTrustToOfficeNumberingEnabled())
      );
    }

    function prepField(name) {
      log.info('prepField', name);


      switch(name.toUpperCase()) {
        case 'PAYOR': {
          // the payor has an error if the payorId is not present AND:
          //   1. its matter balances AND from a direct source OR
          //   2. its contact balances from any non-combined source 
          ctrl.errors.payor = ctrl.showErrors && !ctrl.model.payorId && (
            (ctrl.view.isMatterContactBalanceType && !ctrl.model.source.isCombinedBalance) ||
            (ctrl.view.isMatterBalanceType && ctrl.view.isDirectPayment)
          );
          break;
        }
        case 'AMOUNT': {
          const validAmount = ctrl.model.amount > 0;

          const exceedsAvailableBalance  = ctrl.showErrors && validAmount && amountExceedsAvailableBalance();
          const isOverpaymentFromAccount = ctrl.showErrors && validAmount && isAccountOverpayment();

          ctrl.errors.amount = ctrl.showErrors && (!validAmount || exceedsAvailableBalance || isOverpaymentFromAccount);

          // Priority of us showing these errors:
          // overpayment from account
          // overdrawing firm
          // exceeding available balance

          ctrl.errors.isOverpaymentFromAccount = false;
          ctrl.errors.exceedsAvailableBalance = false;
          ctrl.errors.overdrawsFirmBalance = false;

          if (isOverpaymentFromAccount) {
            ctrl.errors.isOverpaymentFromAccount = isOverpaymentFromAccount;
          } else if (exceedsAvailableBalance) {
            ctrl.errors.exceedsAvailableBalance = exceedsAvailableBalance;
          }

          break;
        }
        case 'EFFECTIVEDATE': {
          ctrl.errors.effectiveDate = !ctrl.model.effectiveDate
          delete ctrl.errors.reconciled;
          // as per BB-9656, if overpayment goes to trust, we have make sure it is not reconciled yet
          if (isTrustPayment || (!ctrl.operatingAccountForOverpayment && isOverpaymentFromDirectPaymentSource())) {
            ctrl.errors.reconciled = ctrl.model.source.bankAccountId && isReconciled({ yyyymmdd: dateToInteger(ctrl.model.effectiveDate), trustAccountId: ctrl.model.source.bankAccountId });
          }

          break;
        }
        case 'REFERENCE': {
          const referenceRequired   = isReferenceRequired();
          const referenceEmpty      = ctrl.showErrors && referenceRequired && (ctrl.model.reference === '' || ctrl.model.reference === undefined);
          const referenceIsntNumber = ctrl.showErrors && referenceRequired && !referenceEmpty && !isFinite(ctrl.model.reference);
          const chequeNumberInUse   = ctrl.showErrors && referenceRequired && !referenceEmpty && !referenceIsntNumber && chequeNumberUsed();

          ctrl.errors.reference = ctrl.showErrors && (
            referenceEmpty || (isUsingTrustCheque() && (referenceIsntNumber || chequeNumberInUse))
          );

          ctrl.errors.referenceEmpty = false;
          ctrl.errors.referenceIsntNumber = false;
          ctrl.errors.chequeNumberInUse = false;

          if (referenceEmpty) {
            ctrl.errors.referenceEmpty = referenceEmpty;
          }

          // validate reference (i.e. cheque number) if using trust cheque, otherwise
          // reference can be non-numeric, this is applicable for both AU and US
          if (isUsingTrustCheque()) {
            if (referenceIsntNumber) {
              ctrl.errors.referenceIsntNumber = referenceIsntNumber;
            }
            else if (chequeNumberInUse) {
              ctrl.errors.chequeNumberInUse = chequeNumberInUse;
            }
          }

          break;
        }

        case 'REASON': {
          // For the invoice payment from trust reason is mandatory
          ctrl.errors.reason = featureActive('BB-5508') && ctrl.model.source.paymentType === 'Trust' && !ctrl.model.reason;
          break;
        }
      }

      log.debug('errors', ctrl.errors);
    }

    function chequeNumberUsed() {
      // if the user is printing the cheque manually and the source they have selected is a trust account, validate whether the cheque number is used or not/*  */
      if (_.get(ctrl, 'model.printingMethod.value') === PrintManually && _.get(ctrl, 'model.source.paymentType') === PAYMENT_TYPE.trust) {
        const num = +ctrl.model.reference;
        return chequeExists(num);
      }

      return false;
    }

    function shouldDisableTtoPdf () {
      // If Print Now trust cheques is selected, we disable the TTO receipt option as cheques take priority over the receipt
      // The receipt can be generated from accounts > trust > print detail if needed
      return ctrl.model.printingMethod && ctrl.model.printingMethod.value === PrintNow;
    }

    function onChangePrintMethod () {
      if (isTrustPayment) {
        const printMethod = _.get(ctrl, 'model.printingMethod.value');

        if (printMethod === PrintNow || printMethod === PrintLater || readonlyReference()) {
          ctrl.model.reference = '';
        }
        else if (printMethod === PrintManually) {
          ctrl.model.reference = getNextChequeNumber(getTrustChequesByBankAccountId(ctrl.model.source.bankAccountId)) || '';
        }
      }

      const printMethod = ctrl.model.printingMethod.value;
      ctrl.isChequeMemoVisible = printMethod === PrintNow || printMethod === PrintManually;

      prepField('reference');
    }

    function focus() {
      if (!ctrl.model.payorId) {
        log.info('focus payor');
        focusService.focusOn('invoice-payment-payor');
      }
      else if (!ctrl.model.amount) {
        log.info('focus amount');
        focusService.focusOn('invoice-payment-amount');
      }
    }

    function isValid() {
      prepField('payor');
      prepField('amount');
      prepField('effectiveDate');

      if (isReferenceRequired()) {
        prepField('reference');
      }
      prepField('reason');

      let valid = !Object.values(ctrl.errors).some((e) => e);

      log.info('is valid?', valid);

      return valid;
    }

    function getPayors() {
      return ctrl.model.source.isCombinedBalance
        ? Object.keys(ctrl.model.payors) // { [payorId]: amountInCents }
            .reduce((payors, payorId) => {
              if (ctrl.model.payors[payorId]) {
                payors.push({
                  payorId,
                  amount: ctrl.model.payors[payorId],
                  paymentId: uuid(),
                  transactionId: uuid(),
                });
              }

              return payors;
            }, [])
        : [{
            payorId: (ctrl.view.isDirectPayment && ctrl.model.payorId) || ctrl.model.source.contactId,
            amount: ctrl.model.amount,
            paymentId: uuid(),
            transactionId: uuid(),
          }];
    }

    function marshal() {
      const data = {
        invoiceId: ctrl.invoiceId,
        matterId: ctrl.model.invoice.matterId,
        reference: ctrl.model.reference,
        userId: getUserId(),
        note: ctrl.model.note,
        waiveBalance: ctrl.model.waiveBalance && getOutstandingBalance() > 0,
        effectiveDate: dateToInteger(ctrl.model.effectiveDate),
        matterBalanceType: ctrl.view.isMatterBalanceType ? 'MATTER' : 'CONTACT',
      };

      let reasonValue = null;
      // reasonField is AU/GB facet
      if (featureActive('BB-5508') && hasFacet(facets.reasonField)) {
        reasonValue = ctrl.model.reason;
      } else if (hasFacet(facets.trustPaymentReasonField) && isTrustPayment) { 
        // trustPaymentReasonField is US facet
        // For US, we keep reason field hidden from UI, but set the reason value when submitted if the invoice payment source is trust
        reasonValue = `Legal Fees on Invoice #${ctrl.model.invoice.invoiceNumber}`;
      }
      data.reason = reasonValue;

      if (ctrl.view.isDirectPayment) {
        data.source = ctrl.model.source.paymentSource; // e.g. cash, cheque, etc
      } else {
        data.sourceAccountType = ctrl.model.source.paymentType; // Trust|Operating
        data.sourceBankAccountId = ctrl.model.source.bankAccountId;
        data.sourceAccountBalanceType ='MATTER'; // legacy, set to Matter always
      }
      
      if (ctrl.view.isDirectPayment || ctrl.view.isMatterBalanceType) {
        data.payorId = ctrl.view.isDirectPayment ? ctrl.model.payorId : undefined;
        data.amount = ctrl.model.amount;
        data.transactionId = isTrustPayment && ctrl.view.isMatterBalanceType ? uuid() : null;
      }
      
      if (!ctrl.view.isDirectPayment && ctrl.view.isMatterContactBalanceType) {
        data.payors = getPayors();
      } else {
        data.paymentId = uuid(); // the endpoint requires the paymentId if payors is not present
      }

      if (ctrl.model.printingMethod) {
        const chequePrintActive = ctrl.model.printingMethod.value !== PrintNotApplicable;
        // if chequePrintActive is false, set chequePrintMethod to the first print method options
        const chequePrintMethod = chequePrintActive ? ctrl.model.printingMethod.code : printMethodsByCode[0].code;
        // need to generate id (for the trust -> operating transaction) now so that it can be passed to Print Cheque Modal if necessary.
        data.chequeId = uuid(); 
        data.chequePrintActive = chequePrintActive;
        data.chequePrintMethod = chequePrintMethod;
      }

      data.chequeMemo = ctrl.isChequeMemoVisible ? ctrl.model.chequeMemo : undefined;

      data.isElectronicPayment = isTrustPayment
        && ctrl.supportsTtoNumbering
        && (ctrl.model.printingMethod && ctrl.model.printingMethod.value === PrintNotApplicable);

      // moved here from payment service, why we do this is a mystery :(
      if (data.printingMethod) {
        data.trustChequePrintMethod = data.printingMethod.code;
        delete data.printingMethod;
      }

      data.allowOverdraw = ctrl.allowOverdraw;

      // need to generate transaction Id (for the trust -> operating transaction) now so that it can be passed to the TTO Transfer pdf
      if (ctrl.view.isTrustPayment && ctrl.model.pdfOnTrustPayment) {
        data.transferBetweenAccountsTransactionId = uuid();
        data.pdfOnTrustPayment = true;
      }  

      return data;
    }

    function addPayment() {
      ctrl.showErrors = true;
      if (isValid()) {
        const data = marshal();
        log.info(`add payment`, JSON.stringify(data));
        ctrl.callbackFn({ data });
        if (ctrl.model.printingMethod.value === PrintNow) {
          ctrl.printCheque({ data: { chequeId: data.chequeId, bankAccountId: data.sourceBankAccountId } });
        }
      } else {
        focus();
      }
    }

    function disableProcessButton() {
      const disableButton = ctrl.matterClientOrDescriptionRequired && isOverpaymentFromDirectPaymentSource() && (
        !matterHasClients() || isMissingSomeMatterClientAddresses() || !matterHasDescription() || ctrl.errors.reconciled
      );
      return disableButton;
    }

    function updateDefaultReason() {
      if (reasonOverridden || !ctrl.model.invoice) {
        return;
      }

      switch (ctrl.model.source.paymentType) {
        case 'Trust': {
          ctrl.model.reason = `${ctrl.t('capitalize', { val: 'trustToOfficeTransferLabel' })} for costs and outlays`;
          break;
        }
        case 'Credit': {
          ctrl.model.reason = `Credit applied to invoice #${ctrl.model.invoice.invoiceNumber}`;
          break;
        }
        default: {
          // operating
          ctrl.model.reason = '';
        }
      }
    }

    function onReasonChange() {
      reasonOverridden = true;
      prepField('reason');
    }
  }
});