import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { todayAsInteger } from '@sb-itops/date';
import * as messageDisplay from '@sb-itops/message-display';
import { capitalize, debounce } from '@sb-itops/nodash';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import { getRegion } from '@sb-itops/region';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as formsFeature from '@sb-itops/redux/forms2';
import { isModalVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import uuid from '@sb-itops/uuid';

import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import {
  getDefaultTrustChequePrintSettings,
  PrintNow,
  PrintManually,
  PrintNotApplicable,
} from '@sb-billing/business-logic/cheques';

import { toCamelCase } from '@sb-itops/camel-case';
import logFactory from '@sb-itops/fe-logger';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';

import {
  BankAccountDetails,
  InitBankAccountSettings,
  InitOperatingBankAccount,
  InitTrustChequePrintSettings,
  MatterBalanceTrustSummariesAsOfDate,
  TrustChequeAvailableNumbers,
} from 'web/graphql/queries';
import { useCacheQuery, useSubscribedLazyQuery, useSubscribedQuery } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { subscribeToNotifications } from 'web/services/subscription-manager';

import { allocateContactBalancePayments } from './allocate-contact-balance-payments';
import BulkTrustToOfficeModal from './BulkTrustToOfficeModal';

const log = logFactory.getLogger('BulkTrustToOfficeModalContainer');
const PROCESS_TRUST_TO_OFFICE_MODAL_ID = 'process-trust-to-office-modal';
const REDUX_DATE_FORMAT = 'YYYYMMDD';
const REGION = getRegion();

function getScopedBulkTrustToOfficeModalFeature(state) {
  const scope = PROCESS_TRUST_TO_OFFICE_MODAL_ID;
  return withScopedFeatures({ state, scope })({
    formsFeature,
  });
}

function getDefaultPrintMethod() {
  // This is not fetching default print method from trust cheque settings. This is matching
  // behaviour in receive payment modal. I think the reasoning for that is in settings there's
  // no print not applicable options, so in areas where we need the print not applicable option
  // we would not default the print method to what's specified in trust cheque settings.
  return PrintNotApplicable;
}

function isChequeNumberAvailable({ chequeNumber, availableChequeNumbers }) {
  const availableNumberSet = new Set(availableChequeNumbers.map(Number));
  return availableNumberSet.has(chequeNumber);
}

function isTrustToOfficeTransferNumberingEnabled(trustAccount) {
  const { trustToOfficeNumberingSettings } = trustAccount || {};
  return !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering;
}

function isReferenceEditable({ printMethod, isTrustToOfficeTransferNumberingEnabledForTrustAccount }) {
  // The reference field is really the transaction reference, but in the case of a cheque payment, this is also used as the cheque number
  // 1. For US eletronic payments (Print Not Applicable), this field is always editable
  // 2. For AU eletronic payments, this field is only editable when Trust to Office auto transaction numbering is off.
  // 3. This field is also editable for Print Manually scenario for cheque payments
  const ttoNumbering = hasFacet(facets.ttoNumbering);
  return (
    printMethod === PrintManually ||
    (printMethod === PrintNotApplicable &&
      (!ttoNumbering || (ttoNumbering && !isTrustToOfficeTransferNumberingEnabledForTrustAccount)))
  );
}

function buildReceivePaymentMessage({
  formData,
  paymentSummary,
  bankAccountId,
  operatingAccount,
  isMatterContactBalanceFirm,
  isTrustToOfficeTransferNumberingEnabledForTrustAccount,
  nextTrustChequeNumber,
}) {
  const printMethod = formData.printMethod;
  const isPrintManually = printMethod === PrintManually;

  const isCheque = printMethod !== PrintNotApplicable;
  const chequeId = isCheque ? uuid() : undefined;

  let reference;
  if (isPrintManually) {
    // assign reference (cheque number) provided by user, or get the next cheque number
    reference = formData.reference || nextTrustChequeNumber;
  } else if (isReferenceEditable({ printMethod, isTrustToOfficeTransferNumberingEnabledForTrustAccount })) {
    reference = formData.reference;
  }

  // auto/manual allocation in trust to office screen only decides how much to pay
  // for each matter, for contact balance firms (US), we need to decide which contact
  // in the matter to allocate the funds from. funds are automatically taken from the
  // contact with the smallest balance first.
  let payments;
  if (isMatterContactBalanceFirm) {
    payments = allocateContactBalancePayments(paymentSummary.paymentsForEachMatter, bankAccountId);
    // Contact Balance Firms: payments is an array of items with the
    // following shape for each matter and payor
    //   {
    //     matterId: 'baa0a463-ebec-444c-94f1-6bd077546729',
    //     payorId: 'payor1id-ebec-444c-94f1-6bd077546729',
    //     invoices: [
    //       {
    //         invoiceId: '577ea959-8477-4dd6-9160-58125f16b4cd',
    //         amount: 17600,
    //       },
    //       {
    //         invoiceId: '44992a87-179d-416b-af0a-bcaaba8a2c68',
    //         amount: 16500,
    //       },
    //       {
    //         invoiceId: '75b2bde3-2260-46c4-85b9-a41be0a6b2b7',
    //         amount: 2200,
    //       },
    //     ],
    //   },
  } else {
    payments = paymentSummary.paymentsForEachMatter.map((receivePaymentForMatter) => {
      const transferBetweenAccountsTransactionId = uuid();
      return {
        ...receivePaymentForMatter,
        transferBetweenAccountsTransactionId,
      };
    });
    // Matter Balance Firms: payments is an array of items with the
    // following shape for each matter
    //   {
    //     matterId: 'baa0a463-ebec-444c-94f1-6bd077546729',
    //     invoices: [
    //       {
    //         invoiceId: '577ea959-8477-4dd6-9160-58125f16b4cd',
    //         amount: 17600,
    //       },
    //       {
    //         invoiceId: '44992a87-179d-416b-af0a-bcaaba8a2c68',
    //         amount: 16500,
    //       },
    //       {
    //         invoiceId: '75b2bde3-2260-46c4-85b9-a41be0a6b2b7',
    //         amount: 2200,
    //       },
    //     ],
    //   },
  }

  const destinationBank = hasFacet(facets.operatingAccountDetail)
    ? {
        destinationBankAccountName: operatingAccount.accountName,
        destinationBankAccountNumber: operatingAccount.accountNumber,
        destinationBankBranchNumber: operatingAccount.branchNumber,
      }
    : {};

  let reasonValue;
  // reasonField is AU/GB facet
  if (hasFacet(facets.reasonField)) {
    reasonValue = formData.reason;
  } else if (hasFacet(facets.trustPaymentReasonField)) {
    // trustPaymentReasonField is US facet
    // For US, we keep reason field hidden from UI, but set the reason value when submitted if the invoice payment source is trust
    const paymentsForEachMatter = paymentSummary.paymentsForEachMatter || [];
    const invoiceNumbersArray = paymentsForEachMatter.flatMap((payment) =>
      (payment.invoices || []).map((invoice) => {
        const invoiceNumber = invoice.invoiceNumber;
        return invoiceNumber;
      }),
    );
    // Sort the invoice numbers numerically
    invoiceNumbersArray.sort((a, b) => a - b);
    // Convert sorted numbers back to strings prefixed with '#'
    const sortedInvoiceNumbers = invoiceNumbersArray.map((invoiceNumber) => `#${invoiceNumber}`);
    const invoiceNumbers = sortedInvoiceNumbers.join(', ');
    reasonValue = `Legal Fees on ${invoiceNumbersArray.length === 1 ? 'Invoice' : 'Invoices'} ${invoiceNumbers}`;
  }

  const receivePaymentMessage = {
    totalAmount: paymentSummary.totalAmount,
    effectiveDate: todayAsInteger(),
    isTrustToOffice: true,
    reason: reasonValue,
    multiPaymentId: uuid(),
    accountPayments: [
      {
        sourceAccountId: bankAccountId,
        destinationAccountId: operatingAccount.id,
        isElectronicPayment: !isCheque,
        isCheque,
        chequeId,
        chequePrintActive: isCheque,
        chequePrintMethod: isCheque && printMethod,
        reference,
        chequeMemo: isCheque && hasFacet(facets.chequeMemo) ? formData.memo : undefined,
        payments,
        ...destinationBank,
      },
    ],
  };
  // receipt id is used to redirect to PDF before the PDF exists
  // it is either multiPaymentId if there are multiple payments
  // or paymentId if it 1 payment (multiple invoices on same matter is still 1 payment as long as it is for same payor)
  let receiptId;
  if (payments.length) {
    receiptId = payments.length === 1 ? payments[0].paymentId : receivePaymentMessage.multiPaymentId;
  }
  return { receivePaymentMessage, chequeId, receiptId, payments };
}

const queryHooks = () => ({
  useBankAccountDetails: ({ bankAccountId }) => {
    const { data: trustBankAccountsData, loading: trustAccountLoading } = useSubscribedQuery(BankAccountDetails, {
      variables: {
        bankAccountIds: [bankAccountId],
        includeBeneficiaries: false, // We don't need beneficiary data for Trust accounts
      },
    });
    const trustAccount = trustBankAccountsData?.bankAccounts?.[0];
    const isTrustToOfficeTransferNumberingEnabledForTrustAccount =
      isTrustToOfficeTransferNumberingEnabled(trustAccount);
    return {
      trustAccount,
      trustAccountLoading,
      isTrustToOfficeTransferNumberingEnabledForTrustAccount,
    };
  },
  useBankAccountSettingsData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const isMatterContactBalanceFirm =
      bankAccountSettingsData?.bankAccountSettings?.bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];
    const createPDFReceiptOnTrustPayment = bankAccountSettingsData?.bankAccountSettings?.createPDFReceiptOnTrustPayment;
    return {
      isMatterContactBalanceFirm,
      createPDFReceiptOnTrustPayment,
    };
  },
  useMatterBalanceTrustSummariesAsOfDate: ({ paymentSummary, bankAccountId }) => {
    const { data: matterBalanceTrustSummariesData, loading: matterBalanceTrustSummariesLoading } = useSubscribedQuery(
      MatterBalanceTrustSummariesAsOfDate,
      {
        variables: {
          matterIds: paymentSummary.paymentsForEachMatter.map((row) => row.matterId),
          filter: {
            bankAccountIds: [bankAccountId],
          },
          balanceAsOfDate: todayAsInteger(),
        },
      },
    );

    const matterBalanceTrustSummariesMap = useMemo(
      () =>
        (matterBalanceTrustSummariesData?.matterBalanceTrustSummaries || []).reduce(
          (acc, matterBalanceTrustSummary) => {
            acc[matterBalanceTrustSummary.matterId] = matterBalanceTrustSummary;
            return acc;
          },
          {},
        ),
      [matterBalanceTrustSummariesData?.matterBalanceTrustSummaries],
    );

    return {
      matterBalanceTrustSummariesMap,
      matterBalanceTrustSummariesLoading,
    };
  },
  useOperatingBankAccount: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);
    const operatingAccount = operatingBankAccountData?.bankAccounts?.[0];
    return {
      operatingAccount,
    };
  },
  useTrustChequeData: ({ bankAccountId }) => {
    const [getAvailableTrustChequeNumber, trustChequeAvailableNumbersResult] = useSubscribedLazyQuery(
      TrustChequeAvailableNumbers,
      {},
    );

    const lastTrustChequeNumber = trustChequeAvailableNumbersResult.data?.trustChequeAvailableNumbers?.lastChequeNumber;
    const nextTrustChequeNumber = trustChequeAvailableNumbersResult.data?.trustChequeAvailableNumbers?.nextChequeNumber;
    const availableTrustChequeNumbers =
      trustChequeAvailableNumbersResult.data?.trustChequeAvailableNumbers?.availableChequeNumbers || [];

    // available trust cheque number needs to be checked based on what the user enters in the reference field
    const onGetAvailableTrustChequeNumber = debounce(
      ({ chequeNumberFrom }) => {
        getAvailableTrustChequeNumber({
          variables: {
            filter: {
              bankAccountId,
              chequeNumberFrom,
              quantity: 1,
            },
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    return {
      lastTrustChequeNumber,
      nextTrustChequeNumber,
      availableTrustChequeNumbers,
      onGetAvailableTrustChequeNumber,
      trustChequeAvailableNumbersLoading: trustChequeAvailableNumbersResult.loading,
    };
  },
  useTrustChequePrintSettings: ({ bankAccountId }) => {
    const { data: trustChequePrintSettingsData } = useCacheQuery(InitTrustChequePrintSettings.query);
    const allTrustChequePrintSettings = trustChequePrintSettingsData?.trustChequePrintSettings || [];

    const trustAccountChequeSettings =
      bankAccountId && allTrustChequePrintSettings.find((settings) => settings.id === bankAccountId);
    const isTrustChequePrintingActive = (
      trustAccountChequeSettings || getDefaultTrustChequePrintSettings({ region: REGION })
    ).printingActive;

    return {
      isTrustChequePrintingActive,
    };
  },
});

const dependentHooks = () => ({
  useIsLoading: ({
    trustAccountLoading,
    matterBalanceTrustSummariesLoading,
    trustChequeNumbersLoading,
    trustChequeAvailableNumbersLoading,
  }) => ({
    isLoading: trustAccountLoading || matterBalanceTrustSummariesLoading,
    trustChequeNumbersLoading,
    trustChequeAvailableNumbersLoading,
  }),
  useFormFields: ({
    paymentSummary,
    matterBalanceTrustSummariesMap,
    trustAccount,
    isTrustToOfficeTransferNumberingEnabledForTrustAccount,
    lastTrustChequeNumber,
    nextTrustChequeNumber,
    availableTrustChequeNumbers,
    trustChequeAvailableNumbersLoading,
  }) => {
    const { t } = useTranslation();
    const state = useSelector((reduxState) => reduxState);
    const {
      formsFeature: {
        selectors: { getFormState, getFieldValues },
      },
    } = getScopedBulkTrustToOfficeModalFeature(state);

    const trustAccountName = getBankAccountName(trustAccount, t);

    // get modal visibility
    const visible = isModalVisible({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID });

    // get form fields
    const formState = getFormState();
    const fieldValues = formState.formInitialised ? getFieldValues() : {};
    const { printMethod, memo, reason, createPDFReceiptOnTrustPayment } = fieldValues;
    const formSubmitting = formState.formSubmitting;

    // The reference field is really the transaction reference, but in the case of a cheque payment, this is also used as the cheque number
    const referenceIsEditable = isReferenceEditable({
      printMethod,
      isTrustToOfficeTransferNumberingEnabledForTrustAccount,
    });
    let reference;
    if (printMethod === PrintManually) {
      // In the PrimtManually scenario, the reference assigned will be the last cheque reference found + 1.
      // This is assigned for display purpose only here (when it's not overridden by the user aleady). The
      // actual reference will be determined at point of form submission so to ensure no cheque number overlaps.
      reference = !fieldValues || fieldValues.reference === undefined ? nextTrustChequeNumber : fieldValues.reference;
    } else if (referenceIsEditable) {
      // If reference field is editable, propagate edited estate if any
      reference = fieldValues && fieldValues.reference;
    }

    // validate form fields
    const errors = {};

    // stops submission without showing any errors, this is a guard against incorrect use of modal
    if (!paymentSummary || !paymentSummary.totalAmount || paymentSummary.totalAmount <= 0) {
      errors.paymentSummary = true;
    }

    if (fieldValues.printMethod === PrintManually) {
      const CHEQUE_LABEL = capitalize(t('cheque'));
      // validate reference only when it's overridden by user which is possible only when they print manually
      if (fieldValues.reference !== undefined) {
        if (fieldValues.reference === '') {
          errors.reference = true;
          errors.referenceIsRequired = `Warning: ${CHEQUE_LABEL} reference is required.`;
        } else if (!/^[0-9]+$/.test(fieldValues.reference)) {
          errors.reference = true;
          errors.referenceMustBeNumeric = `Warning: ${CHEQUE_LABEL} reference must be numeric.`;
        } else if (
          !trustChequeAvailableNumbersLoading &&
          !isChequeNumberAvailable({
            chequeNumber: +fieldValues.reference,
            availableChequeNumbers: availableTrustChequeNumbers,
          })
        ) {
          errors.reference = true;
          errors.referenceIsAlreadyInUse = `Warning: ${CHEQUE_LABEL} reference is already in use. Last ${CHEQUE_LABEL.toLowerCase()} reference printed was ${lastTrustChequeNumber}.`;
        }
      }
    }

    if (hasFacet(facets.reasonField) && !fieldValues.reason) {
      errors.reason = true;
    }

    const matterOverdrawnWarnings = getMatterOverdrawnWarnings({
      paymentSummary,
      matterBalanceTrustSummariesMap,
      modalIsVisible: visible,
      formSubmitting,
      t,
    });

    return {
      modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID,
      visible,
      paymentSummary,
      printMethod,
      referenceIsEditable,
      reference,
      memo,
      reason,
      createPDFReceiptOnTrustPayment,
      trustAccountName,
      errors,
      matterOverdrawnWarnings,
      formSubmitting,
      showChequeMemo: hasFacet(facets.chequeMemo),
      showReason: hasFacet(facets.reasonField),
    };
  },
  useFormActions: ({
    bankAccountId,
    createPDFReceiptOnTrustPayment,
    isMatterContactBalanceFirm,
    isTrustToOfficeTransferNumberingEnabledForTrustAccount,
    nextTrustChequeNumber,
    operatingAccount,
    paymentSummary,
    // callbacks
    onClickLink,
    onGetAvailableTrustChequeNumber,
    onOpenTrustChequesModal,
    onPaymentsSubmitted,
  }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
      formsFeature: {
        actions: { initialiseForm, updateFieldValues, clearForm },
        operations: { submitFormP },
      },
    } = getScopedBulkTrustToOfficeModalFeature();

    const updateFieldValue = (field, value) => {
      dispatch(updateFieldValues({ fieldValues: { [field]: value } }));
    };

    const getInitialFieldValues = () => ({
      checkDate: moment().format(REDUX_DATE_FORMAT),
      payToContactId: undefined,
      memo: undefined,
      reason: hasFacet(facets.reasonField)
        ? `${t('capitalize', { val: 'trustToOfficeTransferLabel' })} for costs and outlays`
        : undefined,
      printMethod: getDefaultPrintMethod(),
      reference: undefined,
      createPDFReceiptOnTrustPayment,
    });

    const resetForm = () => {
      dispatch(
        updateFieldValues({
          fieldValues: getInitialFieldValues(),
        }),
      );
    };

    return {
      onLoad: () => {
        dispatch(
          initialiseForm({
            fieldValues: getInitialFieldValues(),
          }),
        );
        return () => {
          dispatch(clearForm());
        };
      },
      onPrintMethodSelected: (selectedOption) => {
        if (selectedOption?.value === PrintManually) {
          onGetAvailableTrustChequeNumber({});
        }
        updateFieldValue('printMethod', selectedOption && selectedOption.value);
        updateFieldValue('reference', undefined); // clear reference field to assume default
      },
      onReferenceChange: (newReference) => {
        onGetAvailableTrustChequeNumber({ chequeNumberFrom: newReference });
        updateFieldValue('reference', newReference);
      },
      onMemoChange: (newMemo) => {
        updateFieldValue('memo', newMemo);
      },
      onReasonChange: (newReason) => {
        updateFieldValue('reason', newReason);
      },
      onCreatePDFReceiptOnTrustPaymentChange: (newCreatePDFReceiptOnTrustPayment) => {
        updateFieldValue('createPDFReceiptOnTrustPayment', newCreatePDFReceiptOnTrustPayment);
      },

      onSubmit: async () => {
        await dispatch(
          submitFormP({
            submitFnP: async (formData) => {
              const successFailMessagePrefix = `${paymentSummary.invoiceCount} invoice(s) on ${paymentSummary.matterCount} matter(s)`;

              try {
                // build receive payment message from form data and payment summary passed from trust to office screen
                const { receivePaymentMessage, chequeId, receiptId, payments } = buildReceivePaymentMessage({
                  formData,
                  paymentSummary,
                  bankAccountId,
                  operatingAccount,
                  isMatterContactBalanceFirm,
                  isTrustToOfficeTransferNumberingEnabledForTrustAccount,
                  nextTrustChequeNumber,
                });
                const openReceipt =
                  formData.printMethod !== PrintNow && formData.createPDFReceiptOnTrustPayment && receiptId;

                if (openReceipt) {
                  const paymentIds = payments.reduce((acc, item) => {
                    acc[item.paymentId] = true;
                    return acc;
                  }, {});

                  // Keep in "submitting" state until we get notifications all payments were saved with 20s timeout
                  await receivePaymentAndWaitForNotifications({ receivePaymentMessage, paymentIds, timeout: 20000 });
                } else {
                  // submit receive payment message
                  await receivePayment(receivePaymentMessage);
                }

                // reset this modal form as well as any selection/payment amount entered in trust to office screen
                resetForm();
                onPaymentsSubmitted();
                setModalDialogHidden({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID });

                // open trust cheque printing modal if applicable
                if (formData.printMethod === PrintNow) {
                  onOpenTrustChequesModal({ chequeId, bankAccountId });
                } else if (openReceipt) {
                  // redirect to PDF
                  onClickLink({
                    type: 'trustToOfficeTransferReceipt',
                    id: { paymentId: receiptId },
                  });
                } else {
                  const successMessage = `${successFailMessagePrefix} ${t('trustToOfficeSuccessMessage')}.`;
                  messageDisplay.success(successMessage);
                }
              } catch (err) {
                const failMessage = `${successFailMessagePrefix} ${t('trustToOfficeFailureMessage')}.`;
                messageDisplay.error(failMessage);
                throw err;
              }
            },
          }),
        );
      },
      onCloseModal: async () => {
        setModalDialogHidden({ modalId: PROCESS_TRUST_TO_OFFICE_MODAL_ID });
        resetForm();
      },
    };
  },
});

async function receivePayment(receivePaymentMessage) {
  await dispatchCommand({ type: 'Billing.Invoicing.Messages.Commands.ReceivePayment', message: receivePaymentMessage });
}

// If user chooses redirection to TTO receipt, we want to delay it by using "submitting" state until we are sure all payments are saved.
//
// The logic is as follows:
// - Since TTO payment is only pseudo-transaction, we don't get any notification that it has been finalised.
// - By sending TTO payment command we create multiple invoice payments and those send "PaymentReceivedOnInvoice" notification
// - We have all paymentIds available here before sending them to endpoint, therefore we can subscribe to these notifications before command
//   is sent cross check the Ids (fallback is timeout just in case notification is missed for any reason)
const receivePaymentAndWaitForNotifications = async ({ receivePaymentMessage, paymentIds, timeout }) => {
  const expectedPaymentIds = { ...paymentIds };
  let unsubscribe;

  const listenerP = new Promise((resolve) => {
    unsubscribe = subscribeToNotifications({
      notificationIds: ['InvoicingNotifications'],
      callback: (notificationPayload) => {
        let payload;
        try {
          payload = toCamelCase(JSON.parse(notificationPayload));
        } catch (err) {
          payload = {};
        }

        if (payload.messageId === 'PaymentReceivedOnInvoice' && payload.entityId) {
          delete expectedPaymentIds[payload.entityId];
        }

        if (!Object.keys(expectedPaymentIds).length) {
          log.info('Received notifications for all payments');
          resolve();
        }
      },
    });
  });

  // submit receive payment message
  await receivePayment(receivePaymentMessage);

  const timeoutP = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

  return Promise.race([listenerP, timeoutP]).finally(() => unsubscribe());
};

const getMatterOverdrawnWarnings = ({
  paymentSummary,
  matterBalanceTrustSummariesMap,
  modalIsVisible,
  formSubmitting,
  t,
}) => {
  if (
    !modalIsVisible ||
    formSubmitting ||
    !featureActive('BB-13792') ||
    !hasFacet(facets.allowOverdraw) ||
    !matterBalanceTrustSummariesMap ||
    !Object.keys(matterBalanceTrustSummariesMap).length
  ) {
    return [];
  }

  const warnings = [];

  paymentSummary.paymentsForEachMatter.forEach((payment) => {
    const matterId = payment.matterId;
    const matterTotalPayment = payment.matterTotalPayment;
    const matterBalanceAtDate = matterBalanceTrustSummariesMap[matterId]?.availableBalance;

    if (matterTotalPayment > matterBalanceAtDate) {
      warnings.push(`${payment.matterDisplay} (${t('cents', { val: matterTotalPayment - matterBalanceAtDate })})`);
    }
  });

  return warnings;
};

export const BulkTrustToOfficeModalContainer = withApolloClient(
  withReduxProvider(composeHooks(queryHooks)(composeHooks(dependentHooks)(withOnLoad(BulkTrustToOfficeModal)))),
);

BulkTrustToOfficeModalContainer.displayName = 'BulkTrustToOfficeModalContainer';

BulkTrustToOfficeModalContainer.propTypes = {
  paymentSummary: PropTypes.shape({
    totalAmount: PropTypes.number.isRequired,
    matterCount: PropTypes.number.isRequired,
    invoiceCount: PropTypes.number.isRequired,
    hasUnpaidAD: PropTypes.bool.isRequired,
    paymentsForEachMatter: PropTypes.arrayOf(
      PropTypes.shape({
        paymentId: PropTypes.string.isRequired,
        matterId: PropTypes.string.isRequired,
        matterDisplay: PropTypes.string.isRequired,
        matterTotalPayment: PropTypes.number.isRequired,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            hasUnpaidAD: PropTypes.bool.isRequired,
            invoiceNumber: PropTypes.number.isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  bankAccountId: PropTypes.string.isRequired,
  onPaymentsSubmitted: PropTypes.func.isRequired,
  onOpenTrustChequesModal: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
};

BulkTrustToOfficeModalContainer.defaultProps = {
  onClickLink: () => {},
};
