import { shared } from '@sb-billing/business-logic';
import { costTypes } from '@sb-billing/business-logic/expense/services';
import { selectors as feeConfigSelectors } from '@sb-billing/redux/fee-configuration/feature';
import { getMap as getFeeConfigState } from '@sb-billing/redux/fee-configuration';
import { getTaxRate } from '@sb-billing/redux/gst-tax-settings';
import { featureActive } from '@sb-itops/feature';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { hasFacet, facets } from '@sb-itops/region-facets';

angular
  .module('sb.billing.webapp')
  .controller('SbActivityEntryController', function($timeout, $scope, sbBillingActivityService, focusService, sbLocalisationService) {
    'use strict';
    const that = this,
      log = getLogger('SbActivityEntryController'),
      noOverride = 'Do not override rate',
      customRate = 'Override rate for all staff',
      overridePerStaffRate = 'Override default rate per staff member',
      activityTypes = sbBillingActivityService.activityTypes,
      defaultActivityType = activityTypes.Time;

    that.rateTypes = [noOverride, customRate, overridePerStaffRate];
    that.t = sbLocalisationService.t;
    const { getMinutesFuzzy, roundToInterval } = shared.services.fuzzyTime;
    const getBillableMinutes = feeConfigSelectors.getBillableMinutes;
    const interval = getBillableMinutes(getFeeConfigState());
    const firmTaxRate = Math.round(getTaxRate() / 100 * 10) / 10; // Round it to one decimal

    that.edit = !!$scope.preFill;
    that.processing = false;

    that.view = {
      activityDisplayOrder: ['Fee', 'Time', 'Expense'],
      activityTypes: activityTypes,
      selectedActivityType: defaultActivityType,
      codePattern: /^[A-Z0-9]*$/i,
      activityCodes: {},
      rateTypeRadio: defaultActivityType === activityTypes.Time ? noOverride : customRate,
    };

    that.activity = {
      errors: {},

      amountIncludesTax: false,
      rateType: noOverride,
      taxExempt: false,
      billable: 'Y',
    };

    that.focusOn = focusOn;
    that.saveP = saveP;
    that.prepField = prepField;
    that.onDeleteActivity = () => $scope.onDeleteActivity();

    $scope.isLoadingData = false;
    $scope.onClose = _.isFunction($scope.onClose) ? $scope.onClose : _.noop;

    $scope.$watch('activityEntry.view.selectedActivityType', () => {
      if (that.view.selectedActivityType === that.view.activityTypes.Fee && that.view.rateTypeRadio === noOverride) {
        // Use matter or staff member default rate is not a valid option for Fee type 
        that.view.rateTypeRadio = customRate;
      }

      if (that.edit) {
        return;
      }

      if (that.view.selectedActivityType === that.view.activityTypes.Expense) {
        that.activity.units = 1;
        // This is for the case when user select tax exempt under other tab and then navigates to Expense tab (when click add activity, Fee tab opens by default)
        if (that.activity.taxExempt) {
          that.activity.inputTaxRate = 0;
          that.activity.outputTaxRate = 0;
        } else {
          that.activity.inputTaxRate = firmTaxRate;
          that.activity.outputTaxRate = firmTaxRate;
        }
        // Default cost type to HARD for expense activity
        that.activity.expenseCostType = costTypes.HARD;
      } else {
        that.activity.units = that.activity.units || undefined;
      }

      $timeout(() => {
        // make sure fields are all available
        that.activity.errors = {};
        $scope.activityForm.$pristine = true;
        $scope.activityForm.code.$setUntouched();
        $scope.activityForm.description.$setUntouched();
        if (that.view.selectedActivityType !== that.view.activityTypes.Fee) {
          $scope.activityForm.units.$setUntouched();
        }
        if (featureActive('BB-12987') && hasFacet(facets.tax) && that.view.selectedActivityType === that.view.activityTypes.Expense) {
          $scope.activityForm.inputTaxRate.$setUntouched();
          $scope.activityForm.outputTaxRate.$setUntouched();
        }
        $scope.activityForm.rate.$setUntouched();
        $scope.activityForm.$submitted = false;
      });
    });

    if (!preFill($scope.preFill)) {
      that.view.activityCodes = sbBillingActivityService.getCodes();
    }

    function zeroUsTax(activity) {
      if (!hasFacet(facets.tax)) {
        activity.amountIncludesTax = false;
        activity.taxExempt = false;
        return;
      }
    }

    function preFill(activityId) {
      log.info('prefill', activityId);

      // No-op if there is no activity from which to pre-fill.
      if (!activityId) {
        return false;
      }

      // Attempt to load the activity with the passed ID. We need to wait for the cache to be ready
      // as this pre-fill could be the result of a page refresh.
      const activity = sbBillingActivityService.getById(activityId);
      that.activity = _.cloneDeep(activity) || {};
      zeroUsTax(that.activity);

      that.activity.billable = that.activity.billable ? 'Y' : 'N';
      that.activity.errors = {};
      that.activity.rate =
        (that.activity.rate && that.activity.rate / 100) || that.activity.rate;
      if (that.activity.units) {
        that.activity.units = that.activity.type === that.view.activityTypes.Time 
          ? that.activity.units // units already stored as minutes for activity time entry
          : that.activity.units / 100;
      }

      if (that.activity.type === that.view.activityTypes.Expense) {
        // if we don't have input/output tax rates for existing expense, default them based on the exempt property and firm tax rate
        const hasInputOutputTaxRate = (that.activity.inputTaxRate || that.activity.inputTaxRate === 0) && (that.activity.outputTaxRate || that.activity.outputTaxRate === 0);

        that.activity.inputTaxRate = hasInputOutputTaxRate ? Math.round(that.activity.inputTaxRate / 100 * 10) / 10 : (that.activity.taxExempt ? 0 : firmTaxRate); // Round activity.inputTaxRate to one decimal
        that.activity.outputTaxRate = hasInputOutputTaxRate ? Math.round(that.activity.outputTaxRate / 100 * 10) / 10 : (that.activity.taxExempt ? 0 : firmTaxRate); // Round activity.outputTaxRate to one decimal

        // if we don't have cost type for existing expense, default it to HARD
        that.activity.expenseCostType = that.activity.expenseCostType || costTypes.HARD;
      }

      // If no activity matches the passed activity id, it doesn't make sense to stay on the activity entry
      // controller in edit mode. In this case, we close the activity entry with a failure state.
      if (_.isEmpty(that.activity)) {
        $scope.onClose({ success: false });
      } else {
        populateViewFromActivity();
      }

      return true;
    }

    function populateViewFromActivity() {
      that.view.activityCodes = _.filter(
        sbBillingActivityService.getCodes(),
        v => {
          return v !== that.activity.code;
        }
      );

      if (that.activity.rateType === 2) {
        that.view.rateTypeRadio = overridePerStaffRate;
      } else if (
        that.activity.useDefaultUserRate ||
        that.activity.rateType === 0
      ) {
        that.view.rateTypeRadio = noOverride;
      } else if (
        that.activity.useDefaultUserRate === false ||
        that.activity.rateType === 1
      ) {
        that.view.rateTypeRadio = customRate;
      }

      that.view.selectedActivityType =
        that.activity.type === undefined ? defaultActivityType : that.activity.type;
    }

    function focusOn($event, target) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      $timeout(function() {
        focusService.focusOn(target);
      });
    }

    function applyDefaultRate() {
      // not apply to expense
      if (that.view.selectedActivityType === activityTypes.Expense) {
        return;
      }

      if (that.view.rateTypeRadio === customRate) {
        that.activity.useDefaultUserRate = false;
        that.activity.rateType = 1;
        that.activity.rate = that.activity.rate || 0;
      } else if (that.view.rateTypeRadio === overridePerStaffRate) {
        that.activity.useDefaultUserRate = false;
        that.activity.rateType = 2;
        that.activity.rate = undefined;
      } else {
        that.activity.useDefaultUserRate = true;
        that.activity.rateType = 0;
        that.activity.rate = undefined;
      }
    }

    function saveP(activity) {
      applyDefaultRate();

      if (!isValid(activity)) {
        return Promise.resolve();
      }

      that.processing = true;
      let item = getItem(activity);

      if (item.type === 0) {
        if (item.rateType !== 1 && item.rateType !== 2) {
          item.rateType = 1;
        }
      }

      if (item.type === 2) {
        item.rateType = 1;
      }

      return sbBillingActivityService
        .saveP(item)
        .then(() => {
          $scope.onClose({ success: true });
        })
        .catch(e => {
          messageDisplay.error(
            'Failed to save activity. Please check your connection and try again.'
          );
          log.warn('Problem saving activity', e);
        })
        .finally(() => {
          that.processing = false;
        });
    }

    function prepField(name) {
      switch (name) {
        case 'units':
          switch (that.view.selectedActivityType) {
            case that.view.activityTypes.Time: {
              let mins = getMinutesFuzzy({ duration: that.activity.units, interval, withRounding: featureActive('BB-4832') });
              if (mins) {
                that.activity.errors.units = mins <= 0;
                that.activity.units = +(roundToInterval({ mins, interval }) / 60).toFixed(5);
              } else {
                that.activity.errors.units = true;
              }
              break;
            }
            case that.view.activityTypes.Fee: {
              that.activity.errors.units = false;
              break;
            }
            case that.view.activityTypes.Expense: {
              that.activity.errors.units = that.activity.units <= 0;
              break;
            }
          }
      }
    }

    function isUniqueCode(code) {
      return (
        _.findIndex(
          that.view.activityCodes,
          value => value.toUpperCase() === code.toUpperCase()
        ) === -1
      );
    }

    function isValid() {
      let activityTypeName = sbBillingActivityService.activityTypesInverted[that.view.selectedActivityType];

      prepField('units');

      const formInvalid = $scope.activityForm.$invalid;
      let valid = !(formInvalid || !isUniqueCode(that.activity.code) || that.activity.errors.units);

      if (formInvalid) {
        const errors = $scope.activityForm.$error;
        console.error(`Form validation error:`);
        for (const [key, value] of Object.entries(errors)) {
          console.error(`${key}: ${_.pluck(value, "$name")}`);
        }
      }

      if ($scope.activityForm.code.$invalid) {
        that.focusOn(null, 'code-field');
      } else if ($scope.activityForm.description.$invalid) {
        that.focusOn(null, 'description-field');
      } else if (that.activity.errors.units) {
        that.focusOn(null, activityTypeName + '-units-field'); // time default duration
      } else if ($scope.activityForm.rate.$invalid) {
        that.focusOn(null, activityTypeName + '-rate-field');
      } else if (
        $scope.activityForm.units &&
        $scope.activityForm.units.$invalid
      ) {
        that.focusOn(null, activityTypeName + '-units-field'); // fee default quantity
      } else if (
        $scope.activityForm.inputTaxRate &&
        $scope.activityForm.inputTaxRate.$invalid
      ) {
        that.focusOn(null, 'input-tax-rate-field');
      } else if (
        $scope.activityForm.outputTaxRate &&
        $scope.activityForm.outputTaxRate.$invalid
      ) {
        that.focusOn(null, 'output-tax-rate-field');
      }

      return valid;
    }

    function getItem(activity) {
      // remove unwanted field when doing fee entry
      if (that.view.selectedActivityType === that.view.activityTypes.Fee) {
        delete activity.units;
      }

      zeroUsTax(activity);

      let item = {
        activityId: activity.activityId,
        amountIncludesTax: activity.amountIncludesTax,
        taxExempt: activity.taxExempt,
        type: that.view.selectedActivityType,
        code: activity.code.toUpperCase(),
        description: activity.description,
        billable: activity.billable === 'Y',
        useDefaultUserRate: activity.useDefaultUserRate,
        rateType: activity.rateType,
        customActivityRatesPerStaff: _.clone(
          activity.customActivityRatesPerStaff,
          true,
          value => {
            if (_.isNumber(value)) {
              return value;
            }
          }
        )
      };

      if (activity.units !== undefined && activity.units !== '') {
        item.units =
          that.view.selectedActivityType === that.view.activityTypes.Time 
            ? +getMinutesFuzzy({ duration: activity.units, interval, withRounding: featureActive('BB-4832') }) 
            : Math.round(+activity.units * 100);
      }

      if (_.isNumber(activity.rate)) {
        item.rate = Math.round(+activity.rate * 100);
      }

      if (that.view.selectedActivityType === that.view.activityTypes.Expense) {
        if (featureActive('BB-12987')) {
          item.inputTaxRate = Math.round(+activity.inputTaxRate * 100);
          item.outputTaxRate = Math.round(+activity.outputTaxRate * 100);
        }
        if (featureActive('BB-13352')) {
          item.expenseCostType = activity.expenseCostType || costTypes.HARD;
        }
      }


      return item;
    }
  });
