import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { FloatingCard, forms2PropTypes, FormLabel, DatePicker, useTranslation } from '@sb-itops/react';
import { PercentageInput } from '@sb-itops/react/percentage-input';

import { featureActive } from '@sb-itops/feature';
import { AddressFields } from './AddressFields/AddressFields.container';
import Styles from '../FeeWiseApplicationForm.module.scss';
import { getMajorityOwnerDifferentErrorMessage } from '../FeeWiseApplicationFormSchema.yup';

export const AuthorizedRepresentative = ({
  authorizedRepresentative,
  setBeneficialOwnerDifferent,
  formInitialised,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  onUpdateBusinessOwnership,
  onValidateForm,
  sectionRef,
  today,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <FloatingCard applyMargin={false} ref={sectionRef}>
      <div className={Styles.sectionTitle}>{t('capitalize', { val: 'authorised' })} Representative</div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="First Name" />
          <input
            type="text"
            value={authorizedRepresentative?.firstName?.value}
            className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.firstName))}
            onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.firstName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Last Name" />
          <input
            type="text"
            value={authorizedRepresentative?.lastName?.value}
            className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.lastName))}
            onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.lastName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Job Title" />
          <input
            type="text"
            value={authorizedRepresentative?.jobTitle?.value}
            className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.jobTitle))}
            onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.jobTitle': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Phone Number" />
          <input
            type="text"
            value={authorizedRepresentative?.phoneNumber?.value}
            className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.phoneNumber))}
            onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.phoneNumber': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Email Address" />
          <input
            type="email"
            value={authorizedRepresentative?.emailAddress?.value}
            className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.emailAddress))}
            onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.emailAddress': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <AddressFields
        addressFields={authorizedRepresentative}
        parentPath="authorizedRepresentative"
        formInitialised={formInitialised}
        submitFailed={submitFailed}
        formDisabled={formDisabled}
        onFieldUpdated={onFieldUpdated}
      />
      <div className="row">
        <div className={classnames('form-group', 'col-sm-4')}>
          <FormLabel uppercase={false} label="Date of Birth" />
          <DatePicker
            disabled={formDisabled}
            onSelect={(date) =>
              onFieldUpdated({
                'authorizedRepresentative.dateOfBirth': date || undefined,
              })
            }
            maxDate={today}
            hasError={!!isErrorClassnames(authorizedRepresentative?.dateOfBirth)}
            value={
              authorizedRepresentative?.dateOfBirth?.value
                ? new Date(authorizedRepresentative?.dateOfBirth?.value)
                : undefined
            }
          />
        </div>
        {!featureActive('BB-14112') && (
          <div className={classnames('form-group', 'col-sm-4')}>
            <FormLabel uppercase={false} label="Last 4 digits Social Security" />
            <input
              type="text"
              value={authorizedRepresentative?.last4SocialSecurity?.value}
              className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.last4SocialSecurity))}
              onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.last4SocialSecurity': e.target.value })}
              disabled={formDisabled}
            />
            {!!isErrorClassnames(authorizedRepresentative?.last4SocialSecurity) &&
              authorizedRepresentative?.last4SocialSecurity?.invalidReason.includes('Must be exactly') && (
                <span className={Styles.errorMsg}>{authorizedRepresentative?.last4SocialSecurity?.invalidReason}</span>
              )}
          </div>
        )}
        {featureActive('BB-14112') && (
          <div className={classnames('form-group', 'col-sm-4')}>
            <FormLabel uppercase={false} label={t('settings.feewise.nationalIdLabel')} />
            <input
              type="text"
              value={authorizedRepresentative?.socialSecurityNumber?.value}
              className={classnames('form-control', isErrorClassnames(authorizedRepresentative?.socialSecurityNumber))}
              onChange={(e) => onFieldUpdated({ 'authorizedRepresentative.socialSecurityNumber': e.target.value })}
              disabled={formDisabled}
            />
            {!!isErrorClassnames(authorizedRepresentative?.socialSecurityNumber) &&
              authorizedRepresentative?.socialSecurityNumber?.invalidReason.includes('Must be') && (
                <span className={Styles.errorMsg}>{authorizedRepresentative?.socialSecurityNumber?.invalidReason}</span>
              )}
          </div>
        )}
        <div className={classnames('form-group', 'col-sm-4')}>
          <FormLabel uppercase={false} label="Business Ownership" />
          <div className={classnames('input-group', Styles.inputGroup)}>
            <PercentageInput
              step="1"
              percent={authorizedRepresentative?.businessOwnership?.value}
              disabled={formDisabled}
              hasError={!!isErrorClassnames(authorizedRepresentative?.businessOwnership)}
              onChange={(percent) =>
                onUpdateBusinessOwnership({
                  percent,
                  max: 100,
                  min: 0,
                  fieldName: 'authorizedRepresentative.businessOwnership',
                })
              }
              onBlur={() => {
                if (authorizedRepresentative?.businessOwnership?.value < 25) {
                  setBeneficialOwnerDifferent(true);
                  onValidateForm();
                }
              }}
            />
            {!!isErrorClassnames(authorizedRepresentative?.businessOwnership) &&
              authorizedRepresentative?.businessOwnership?.invalidReason ===
                getMajorityOwnerDifferentErrorMessage(t) && (
                <span className={Styles.errorMsg}>{authorizedRepresentative?.businessOwnership?.invalidReason}</span>
              )}
          </div>
        </div>
      </div>
    </FloatingCard>
  );
};

AuthorizedRepresentative.displayName = 'AuthorizedRepresentative';

const { Forms2Field } = forms2PropTypes;

AuthorizedRepresentative.propTypes = {
  authorizedRepresentative: PropTypes.shape({
    firstName: Forms2Field,
    middleName: Forms2Field,
    lastName: Forms2Field,
    maidenName: Forms2Field,
    jobTitle: Forms2Field,
    phoneNumber: Forms2Field,
    emailAddress: Forms2Field,
    streetAddressLine1: Forms2Field,
    streetAddressLine2: Forms2Field,
    city: Forms2Field,
    state: Forms2Field,
    zipCode: Forms2Field,
    dateOfBirth: Forms2Field,
    last4SocialSecurity: Forms2Field,
    socialSecurityNumber: Forms2Field,
    businessOwnership: Forms2Field,
  }),
  today: PropTypes.object.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
  onUpdateBusinessOwnership: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
  setBeneficialOwnerDifferent: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

AuthorizedRepresentative.defaultProps = {
  formDisabled: false,
  authorizedRepresentative: {},
};
