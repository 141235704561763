'use strict';

const statusByName = Object.freeze({
  DRAFT: 0,
  FINAL: 1,
  PAID: 2,
  DELETED: 3,
  VOID: 4,
});

const statusByValue = Object.freeze({ 0: 'DRAFT', 1: 'FINAL', 2: 'PAID', 3: 'DELETED', 4: 'VOID' });

const status = Object.freeze({ DRAFT: 'DRAFT', FINAL: 'FINAL', PAID: 'PAID', DELETED: 'DELETED', VOID: 'VOID' });

module.exports = { statusByName, statusByValue, status };
