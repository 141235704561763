import {
  getById as getBankAccountById,
  getActiveTrustAccounts,
  shouldShowOriginalTrustOnly,
} from '@sb-billing/redux/bank-account';
import { getCurrentConfigurationByMatterId as getBillingConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { bankAccountState } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getLocationFromMatterTypeId } from '@sb-matter-types/business-logic/matter-types/services';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { ALL_STATES } from '@sb-itops/region';

/**
 * For a given matterId, returns the default (and active) payment trust account according to these rules:
 * 1. If there is only 1 trust account and it has no location set
 * 2. If a matter has default payment trust set
 * 3. Check default trust account for the matter location
 * @param {string} matterId
 * @returns an object of BankAccount or undefined
 */
export const findDefaultTrustAccountForMatter = (matterId) => {
  if (!matterId) return undefined;
  if (shouldShowOriginalTrustOnly()) {
    // if we get here, we have only 1 active account and it has no location
    return getActiveTrustAccounts()?.[0];
  }

  // 2. Check if a matter has default payment trust set
  const { defaultTrustAccountId } = getBillingConfigurationByMatterId(matterId) || {};
  const trustAccountForMatter = getBankAccountById(defaultTrustAccountId);
  if (trustAccountForMatter?.state === bankAccountState.OPEN) {
    return trustAccountForMatter;
  }

  // fall through if no default trust account for matter set or the default trust account is closed
  const matterTypeId = getMatterById(matterId)?.matterTypeId;
  const locationId = getLocationFromMatterTypeId(matterTypeId, getMatterTypeById);

  // 3. Check default trust account for general location
  const locationDefaultTrustAccountId = locationId
    ? (getBankAccountSettings()?.defaultTrustAccounts || []).find((account) => account.location === locationId)
        ?.defaultTrustAccountId
    : undefined;

  if (locationDefaultTrustAccountId) {
    const trustAccount = getBankAccountById(locationDefaultTrustAccountId);
    if (trustAccount?.state === bankAccountState.OPEN) {
      return trustAccount;
    }
  }

  // 4. Check default trust account for ALL_STATES
  const allStatesDefaultTrustAccountId = (getBankAccountSettings()?.defaultTrustAccounts || []).find(
    (account) => account.location === ALL_STATES.value,
  )?.defaultTrustAccountId;

  if (allStatesDefaultTrustAccountId) {
    const trustAccount = getBankAccountById(allStatesDefaultTrustAccountId);
    if (trustAccount?.state === bankAccountState.OPEN) {
      return trustAccount;
    }
  }

  return undefined;
};
