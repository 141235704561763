import * as React from 'react';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getMap as getBankAccountBalanceState } from '@sb-billing/redux/bank-account-balances';
import { getInvoiceSummariesByFilter } from '@sb-billing/redux/invoices';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  getDefaultCreditAccount,
  getActiveControlledMoneyAccountsByMatter,
  getOperatingAccount,
  getTrustAccounts,
  isTrustAccountClosed,
} from '@sb-billing/redux/bank-account';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import PropTypes from 'prop-types';
import { getById } from '@sb-billing/redux/matter-totals';
import * as viewMatterBillsFilters from 'web/redux/route/home-billing-view-matter-bills';
import { getMatterContactBalances } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';
import { TrustTooltipSummary } from './TrustTooltipSummary';
import { FinancialSummaryByMatter } from './FinancialSummaryByMatter';
import { CreditTooltipSummary } from './CreditTooltipSummary';

const { getMatterBalance, getBankAccountBalanceById, getMatterTrustBalanceAllTypes } = selectors;

const mapStateToProps = (state, { matterId }) => {
  if (!matterId) {
    return {};
  }

  const creditBankAccountId = getDefaultCreditAccount()?.id;
  const operatingBankAccountId = getOperatingAccount().id;
  const trustAccounts = filterTrustAccountsByMatter(matterId).filter((ta) => !isTrustAccountClosed(ta));

  const matterTotals = getById(matterId) || {};

  // If billing type is 'Time Based', the amount is the fee estimate.
  const { billingType, amount } = getCurrentConfigurationByMatterId(matterId) || {};
  const showFeeEstimate =
    featureActive('BB-6801') && billingType === 'Time Based' && Number.isFinite(amount) && amount !== 0;
  const feeEstimate = showFeeEstimate ? amount * 100 : 0; // underlying component expects cents.

  const controlledMoniesAccounts = getActiveControlledMoneyAccountsByMatter(matterId);

  const showControlledMoneyAccount =
    featureActive('BB-6381') && hasFacet(facets.CMA) && !!controlledMoniesAccounts.length;

  // sum balance from all CMAs for specific matterId
  const controlledMoneyBalance =
    showControlledMoneyAccount &&
    controlledMoniesAccounts.reduce(
      (acc, item) =>
        acc + (getBankAccountBalanceById(getBankAccountBalanceState(), { bankAccountId: item.id })?.balance || 0),
      0,
    );

  const matterTrustBalance = getMatterTrustBalanceAllTypes(getBankAccountBalanceState(), { matterId });
  const trustBalance = {
    total: matterTrustBalance[balanceTypes.BALANCE],
    protected: matterTrustBalance[balanceTypes.PROTECTED],
    available: matterTrustBalance[balanceTypes.AVAILABLE],
  };

  const trustTooltip = featureActive('BB-8671') ? <TrustTooltipSummary totals={trustBalance} /> : undefined;

  const creditContactBalances = getMatterContactBalances(getBankAccountBalanceState(), {
    bankAccountId: creditBankAccountId,
    matterId,
  }).map((contactBalance) => ({
    ...contactBalance,
    contactName: getContactDisplay(contactBalance.contactId),
  }));
  const creditTooltip = hasFacet(facets.creditMemo) ? (
    <CreditTooltipSummary creditContactBalances={creditContactBalances} />
  ) : null;
  const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);
  const isItemStatutoryDepositMatter = isStatutoryDepositMatter({
    matterId,
    trustBankAccounts: getTrustAccounts(),
    supportsStatutoryDepositMatter,
  });

  return {
    creditTooltip,
    isStatutoryDepositMatter: isItemStatutoryDepositMatter,
    showTrustAccount: !!trustAccounts.length,
    showFeeEstimate,
    showControlledMoneyAccount,
    totals: {
      CREDIT: creditBankAccountId
        ? getMatterBalance(getBankAccountBalanceState(), { bankAccountId: creditBankAccountId, matterId }) || 0
        : 0,
      TRUST: trustBalance.total,
      CONTROLLEDMONEY: controlledMoneyBalance || 0,
      OPERATING:
        getMatterBalance(getBankAccountBalanceState(), { bankAccountId: operatingBankAccountId, matterId }) || 0,
      UNBILLED: matterTotals.unbilled || 0,
      UNPAID: matterTotals.unpaidExcInterest || 0,
      OVERDUE: getInvoiceSummariesByFilter({ overdueOnly: true, matterId }).reduce((total, v) => {
        const totals = getTotalsForInvoiceId(v.invoiceId);
        return totals ? total + totals.unpaidExcInterest : total;
      }, 0),
      FEE_ESTIMATE: feeEstimate,
    },
    trustTooltip,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFilterByRecent: (value) => {
    dispatch(viewMatterBillsFilters.actions.onFilterByRecent(value));
  },
  onFilterInvoiceStatus: (status) => {
    dispatch(viewMatterBillsFilters.actions.onFilterInvoiceStatus(status));
  },
});

const FinancialSummaryByMatterContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(FinancialSummaryByMatter),
);

FinancialSummaryByMatterContainer.displayName = 'FinancialSummaryByMatterContainer';

FinancialSummaryByMatterContainer.propTypes = {
  matterId: PropTypes.string, // first render from angular does not necessarily have the matterId present
  onClickLink: PropTypes.func.isRequired,
  trustRetainerBelowLimit: PropTypes.bool.isRequired,
};
FinancialSummaryByMatterContainer.defaultProps = { matterId: '' };

export default FinancialSummaryByMatterContainer;
