import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, forms2PropTypes, CurrencyInput2, CurrencyDisplay } from '@sb-itops/react';
import classnames from 'classnames';
import { StatelessSelect } from '@sb-itops/react/select';
import { MatterTypeahead } from '@sb-matter-management/react';
import Styles from './ProtectBalanceAddForm.module.scss';

export const ProtectBalanceAddForm = ({
  scope,
  matterReadOnly,
  contactOptions,
  matterOptions,
  // fields
  matterId,
  contactId,
  reasonRows,
  fundsAvailable,
  totalProtected,
  // form
  onFieldValueUpdated,
  onReasonRowUpdated,
  refreshFundsAvailable,
  formDisabled,
  submitFailed,
  validateForm,
  formInitialised,
  // config
  supportsMatterBalance,
  supportsMatterContactBalance,
}) => {
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <div className={Styles.container} id={scope}>
      <fieldset>
        <div className="row">
          <div className={classnames('form-group', `col-xs-${supportsMatterBalance ? '8' : '12'}`)}>
            <label>Matter</label>
            <MatterTypeahead
              matterSummaries={matterOptions}
              onValueChange={(matter) => {
                onFieldValueUpdated({ contactId: undefined, matterId: matter?.id });
                refreshFundsAvailable({ matterId: matter?.id });
                validateForm();
              }}
              initialId={matterId?.value}
              filter="open"
              disabled={matterReadOnly || formDisabled}
              onBlur={validateForm}
              hasError={matterId && matterId.isInvalid}
            />
          </div>
          {supportsMatterBalance && (
            <div className="col-xs-4 form-group">
              <label>Available Funds</label>
              <CurrencyDisplay amount={fundsAvailable?.value || 0} />
            </div>
          )}
        </div>

        {supportsMatterContactBalance && (
          <div className="row">
            <div className="col-xs-8 form-group">
              <label>Contact</label>
              <StatelessSelect
                className={classnames(isErrorClassnames(contactId))}
                disabled={formDisabled}
                selectedOption={contactId?.value}
                stateless
                options={contactOptions}
                onChange={(contactIdSelected) => {
                  onFieldValueUpdated({ contactId: contactIdSelected?.value });
                  refreshFundsAvailable({ matterId: matterId?.value, contactId: contactIdSelected?.value });
                  validateForm();
                }}
                placeholder="Select a contact"
              />
            </div>
            <div className="col-xs-4 form-group">
              <label>Available Funds</label>
              <CurrencyDisplay amount={fundsAvailable?.value || 0} />
            </div>
          </div>
        )}

        <div className={Styles.separator} />

        {Object.keys(reasonRows || {}).map((key, index) => {
          const row = reasonRows[key];
          return (
            <div className="row" key={key}>
              <div className="col-xs-8 form-group">
                {index === 0 && <label>Protected funds reason</label>}
                <input
                  className={classnames('form-control', isErrorClassnames(row.reason))}
                  type="text"
                  maxLength="120"
                  value={row.reason?.value || ''}
                  disabled={formDisabled}
                  onChange={(e) => {
                    onReasonRowUpdated({
                      reasonRows: {
                        [key]: {
                          reason: e.target.value,
                        },
                      },
                    });
                    validateForm();
                  }}
                  placeholder="Add new protected funds"
                />
              </div>
              <div className="col-xs-4 form-group">
                {index === 0 && <label>Amount</label>}
                <CurrencyInput2
                  className={classnames(isErrorClassnames(row.amount))}
                  name="amount"
                  value={row.amount?.value || undefined}
                  disabled={formDisabled}
                  onChange={(e) => {
                    onReasonRowUpdated({
                      reasonRows: {
                        [key]: {
                          amount: e.target.value && Math.abs(e.target.value),
                        },
                      },
                    });
                    validateForm();
                  }}
                />
              </div>
            </div>
          );
        })}

        <div className="row">
          <div className="col-xs-8 form-group" />
          <div className="col-xs-4 form-group">
            <FormLabel label="Total amount" field={totalProtected} submitFailed={submitFailed} optional />
            <CurrencyDisplay amount={totalProtected?.value || 0} hasError={totalProtected?.isInvalid} />
            {totalProtected?.isInvalid && totalProtected?.invalidReason && (
              <div className={classnames(Styles.errorMessage)}>{totalProtected.invalidReason}</div>
            )}
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

ProtectBalanceAddForm.displayName = 'ProtectBalanceAddForm';

ProtectBalanceAddForm.propTypes = {
  supportsMatterBalance: PropTypes.bool.isRequired,
  supportsMatterContactBalance: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  matterReadOnly: PropTypes.bool.isRequired,
  contactOptions: PropTypes.array.isRequired,
  matterOptions: PropTypes.array.isRequired,
  // fields
  matterId: Forms2Field,
  contactId: Forms2Field,
  reasonRows: PropTypes.object,
  fundsAvailable: Forms2Field,
  totalProtected: Forms2Field,
  // form
  onFieldValueUpdated: PropTypes.func.isRequired,
  onReasonRowUpdated: PropTypes.func.isRequired,
  refreshFundsAvailable: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

ProtectBalanceAddForm.defaultProps = {
  matterId: undefined,
  contactId: undefined,
  fundsAvailable: undefined,
  totalProtected: undefined,
  reasonRows: { reason: undefined, amount: undefined },
};
