/**
 * @param {object} params
 * @param {function} params.fn the function to execute with retries
 * @param {number} params.retries number of times to retry
 * @param {function} [params.onError] callback function that takes error as param
 * @param {function} [params.onLoadingChange] callback function that takes loading state as param
 * @param {function} [params.onData] callback function that takes data as param
 * @param {function} [params.onRetriesExhausted] callback function that is called when all retries are exhausted
 *
 * @returns
 */
export function useAsyncWithRetries({
  fn,
  retries,
  onError = () => {},
  onLoadingChange = () => {},
  onData = () => {},
  onRetriesExhausted = () => {},
}) {
  const executeFunction = async () => {
    try {
      onLoadingChange(true);
      const result = await fn();
      onData(result);
      onLoadingChange(false);
    } catch (err) {
      onError(err);
      if (retries > 0) {
        setTimeout(() => {
          executeFunction();
        }, 1000); // Retry after 1 second
      } else {
        onLoadingChange(false);
        onRetriesExhausted();
      }
    }
  };

  return { executeFunction };
}
