// When an invoice debtor is associated with an organisation/company that is also a client
// for the same matter, use the matter organisation/company's address for that debtor
function getContactOrganisationIdsForMatter(contact, matterClientCustomerIds) {
  if (contact.person && contact.person.assosciatedOrganizations) {
    // person.assosciatedOrganizations is the actual property name (it's a spelling mistake)
    const associatedOrganizationIds = contact.person.assosciatedOrganizations.map((ao) => ao.organizationId);
    return matterClientCustomerIds.filter((value) => associatedOrganizationIds.includes(value));
  }

  return [];
}

module.exports = {
  getContactOrganisationIdsForMatter,
};
