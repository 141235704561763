import {
  getAccountType,
  getTotals,
  addTransaction,
  reset
} from './transaction-totals-by-day';

// This function expects a simple object that
// maps STRING date keys to totals for example:
//
//  { '20180101': 100, '20180102': 200 }
//
// NOTE:
//
//  > The date map is expected to be fairly small, for example 10 years worth of transactions it would be MAX 3650
//  > The balance is accumulated for each day including those PAST the target date.
//  > We want to add the amount passed in to the target dates total
//  > We only perform the validation if the date key matches or is greater that the target date
//  > The validation is performed for each date key including those PAST the target date.
const overdrawsAccount = (date, amount, dateMap) => {
  let balance = 0;
  const stringDate = date.toString();
  const result = { overdraws: false, balance: 0, date };

  // It is possbile that the date passed in .... is NOT in the date map
  // so to cater for that just insert a ZERO total for that date
  if (!dateMap[stringDate]) {
    dateMap[stringDate] = 0;
  }

  //Sort the keys to calculate running balance
  const sortedKeys = Object.keys(dateMap).sort();

  for (let dateKey of sortedKeys) {
    balance += dateMap[dateKey];
    if (stringDate === dateKey) {
      balance += amount;
    }
    if (dateKey >= stringDate && balance < 0) {
      result.overdraws = true;
      result.date = dateKey;
      result.balance = balance;
      break;
    }
  }

  return result;
};

export const overdrawsFirmBalance = ({ effectiveDate, amount, bankAccountId, bankAccountType }) => {
  const accountType = bankAccountType ? bankAccountType.toLowerCase() : getAccountType(bankAccountId);

  return amount < 0 && overdrawsAccount(effectiveDate, amount, getTotals(accountType)).overdraws;
}

export const overdrawsMatterContactBalance = ({ effectiveDate, amount, matterId, contactId, bankAccountId }) => {
  return (
    amount < 0 &&
    overdrawsAccount(effectiveDate, amount, getTotals(getAccountType(bankAccountId), matterId, contactId)).overdraws
  );
};

export const overdrawsFirmTrustBalance = (effectiveDate, amount) => {
  return amount < 0 && overdrawsAccount(effectiveDate, amount, getTotals('trust')).overdraws;
};

export const overdrawsMatterTrustBalance = (effectiveDate, amount, matterId) => {
  return amount < 0 && overdrawsAccount(effectiveDate, amount, getTotals('trust', matterId)).overdraws;
};

export function getBalance(date, accountType, matterId, contactId) {
  const totals = getTotals(accountType, matterId, contactId);
  
  return Object.keys(totals).sort() 
    .filter(effectiveDate => effectiveDate <= date.toString())
    .reduce((acc, effectiveDate) => (acc + totals[effectiveDate]), 0);
}

export {
  addTransaction,
  reset,
};