/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes, Spinner, ModalDialog, FloatingCard, useTranslation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';

import { isNewTheme } from 'web/services/theme';
import { MatterDebugEntities } from 'web/components/debug-entities/MatterDebugEntities';

import { MatterDetails } from './matter-details';
import { MatterStaff } from './matter-staff';
import { MatterContacts } from './matter-contacts';
import { MatterUTBMS } from './matter-utbms';
import { MatterFeeAndRateSettings } from './matter-fee-and-rate-settings';
import { MatterTrustSettings } from './matter-trust-settings';
import { MatterLatePaymentSettings } from './matter-late-payment-settings';
import { MatterCommunicationSettings } from './matter-communication-settings';
import { MatterInvoiceSettings } from './matter-invoice-settings';
import { MatterNavigationPanelContainer, sectionRefsType } from './matter-navigation-panel';

import Styles from './AddEditMatterForm.module.scss';

const MAX_FORM_WIDTH = 600;

const AddEditMatterForm = ({
  confirmationModalId,
  deletionModalId,
  firmDefaultLedesFirmId,
  supportsUtbms,
  hasBillingAccess,
  isEditMode,
  isMatterDeleted,
  isMinimumTrustRetainerEnabledForFirm,
  isUtbmsEnabledForFirm,
  matterId,
  originalMatterTypeId,
  isLead,
  // form state
  formDisabled,
  formFields,
  formFieldValues,
  formInitialised,
  formSubmitting,
  sectionsWithErrors,
  submitFailed,
  // callbacks
  doNotApplyInterestToUnpaidInvoices,
  doNotDeleteMatter,
  onApplyInterestToUnpaidInvoices,
  onCancel,
  onClickLink,
  onConfirmDeleteMatter,
  onDeleteMatter,
  onFieldValueSet,
  onFieldValuesUpdated,
  onSubmit,
  // refs
  scrollToSection,
  sectionIsActive,
  sectionRefs,
}) => {
  const { t } = useTranslation();

  if (!formInitialised) {
    return (
      <div className={Styles.addEditMatterFormLoading}>
        <Spinner />
      </div>
    );
  }

  // Uncomment to see form validation issues, leaving it here as this form is super long so
  // it could still help when we are modifying this form. Please note errors for array and
  // complex object fields won't appear here.
  // if (formFields) {
  //   const fieldValues = Object.values(formFields);
  //   const invalidFields = fieldValues.filter((field) => field.isInvalid);
  //   if (invalidFields.length > 0) {
  //     console.log('invalid fields', invalidFields);
  //   }
  // }

  const {
    matterDetailsSection: matterDetailsRef,
    matterStaffSection: matterStaffRef,
    matterContactsSection: matterContactsRef,
    billingFeesAndRatesSection: billingFeesAndRatesRef,
    trustSettingsSection: trustSettingsRef,
    latePaymentSection: latePaymentRef,
    utbmsSection: utbmsRef,
    communicationSettingsSection: communicationSettingsRef,
    invoiceSettingsSection: invoiceSettingsRef,
  } = sectionRefs;

  const communicationSettingsSectionHeading = featureActive('BB-11448') ? 'Communication' : 'Email Settings';

  return (
    <div className={Styles.addEditMatterForm}>
      {isEditMode && <MatterDebugEntities matterId={matterId} matterTypeId={originalMatterTypeId} />}
      <div className={Styles.titleBar}>
        {!isNewTheme() && <i className={isLead ? 'icon-lead' : 'icon-matter'} />}
        {isEditMode ? <h3>Edit {isLead ? 'Lead' : 'Matter'}</h3> : <h3>Add {isLead ? 'Lead' : 'Matter'}</h3>}
      </div>
      <div className={Styles.mainForm}>
        <MatterNavigationPanelContainer
          displayTrustSettings={!isLead}
          isUtbmsEnabledForFirm={isUtbmsEnabledForFirm && !isLead}
          sectionIsActive={sectionIsActive}
          sectionsWithErrors={sectionsWithErrors}
          scrollToSection={scrollToSection}
          sectionRefs={sectionRefs}
          isLead={isLead}
        />
        <div className={Styles.formPanel}>
          <FloatingCard
            heading="Client / Contacts"
            ref={matterContactsRef}
            maxWidth={MAX_FORM_WIDTH}
            collapsible
            expandIfCollapsed={sectionIsActive(matterContactsRef)}
          >
            <MatterContacts
              matterId={matterId}
              formInitialised={formInitialised}
              formFields={formFields}
              formFieldValues={formFieldValues}
              formDisabled={formDisabled}
              formSubmitting={formSubmitting}
              submitFailed={submitFailed}
              onFieldValuesUpdated={onFieldValuesUpdated}
              onFieldValueSet={onFieldValueSet}
              onClickLink={onClickLink}
            />
          </FloatingCard>

          <FloatingCard
            heading={isLead ? 'Lead Details' : 'Matter Details'}
            ref={matterDetailsRef}
            maxWidth={MAX_FORM_WIDTH}
            collapsible
            expandIfCollapsed={sectionIsActive(matterDetailsRef)}
          >
            <MatterDetails
              matterId={matterId}
              formInitialised={formInitialised}
              formFields={formFields}
              formFieldValues={formFieldValues}
              formDisabled={formDisabled}
              formSubmitting={formSubmitting}
              submitFailed={submitFailed}
              onFieldValuesUpdated={onFieldValuesUpdated}
              isLead={isLead}
            />
          </FloatingCard>

          <FloatingCard
            heading="Staff"
            ref={matterStaffRef}
            maxWidth={MAX_FORM_WIDTH}
            collapsible
            expandIfCollapsed={sectionIsActive(matterStaffRef)}
          >
            <MatterStaff
              matterId={matterId}
              formInitialised={formInitialised}
              formFields={formFields}
              formFieldValues={formFieldValues}
              formDisabled={formDisabled}
              formSubmitting={formSubmitting}
              submitFailed={submitFailed}
              onFieldValuesUpdated={onFieldValuesUpdated}
            />
          </FloatingCard>

          {hasBillingAccess && (
            <FloatingCard
              heading="Billing Fees and Rates"
              ref={billingFeesAndRatesRef}
              maxWidth={MAX_FORM_WIDTH}
              collapsible
              expandIfCollapsed={sectionIsActive(billingFeesAndRatesRef)}
              initiallyCollapsed={!isEditMode}
            >
              <MatterFeeAndRateSettings
                formInitialised={formInitialised}
                formFields={formFields}
                formFieldValues={formFieldValues}
                formDisabled={formDisabled}
                formSubmitting={formSubmitting}
                submitFailed={submitFailed}
                onFieldValuesUpdated={onFieldValuesUpdated}
                onFieldValueSet={onFieldValueSet}
              />
            </FloatingCard>
          )}

          {hasBillingAccess && !isLead && (
            <FloatingCard
              heading={`${t('trust')} Settings`}
              ref={trustSettingsRef}
              maxWidth={MAX_FORM_WIDTH}
              collapsible
              expandIfCollapsed={sectionIsActive(trustSettingsRef)}
              initiallyCollapsed={!isEditMode}
            >
              <MatterTrustSettings
                matterId={matterId}
                isMinimumTrustRetainerEnabledForFirm={isMinimumTrustRetainerEnabledForFirm}
                formInitialised={formInitialised}
                formFields={formFields}
                formFieldValues={formFieldValues}
                formDisabled={formDisabled}
                formSubmitting={formSubmitting}
                submitFailed={submitFailed}
                onFieldValuesUpdated={onFieldValuesUpdated}
              />
            </FloatingCard>
          )}

          {hasBillingAccess && (
            <FloatingCard
              heading="Late Payment"
              ref={latePaymentRef}
              maxWidth={MAX_FORM_WIDTH}
              collapsible
              expandIfCollapsed={sectionIsActive(latePaymentRef)}
              initiallyCollapsed={!isEditMode}
            >
              <MatterLatePaymentSettings
                matterId={matterId}
                formInitialised={formInitialised}
                formFields={formFields}
                formFieldValues={formFieldValues}
                formDisabled={formDisabled}
                formSubmitting={formSubmitting}
                submitFailed={submitFailed}
                onFieldValuesUpdated={onFieldValuesUpdated}
              />
            </FloatingCard>
          )}

          {hasBillingAccess && supportsUtbms && isUtbmsEnabledForFirm && !isLead && (
            <FloatingCard
              heading="UTBMS"
              ref={utbmsRef}
              maxWidth={MAX_FORM_WIDTH}
              collapsible
              expandIfCollapsed={sectionIsActive(utbmsRef)}
              initiallyCollapsed={!isEditMode}
            >
              <MatterUTBMS
                matterId={matterId}
                firmDefaultLedesFirmId={firmDefaultLedesFirmId}
                formInitialised={formInitialised}
                formFields={formFields}
                formFieldValues={formFieldValues}
                formDisabled={formDisabled}
                formSubmitting={formSubmitting}
                submitFailed={submitFailed}
                onFieldValuesUpdated={onFieldValuesUpdated}
              />
            </FloatingCard>
          )}

          <FloatingCard
            heading={communicationSettingsSectionHeading}
            ref={communicationSettingsRef}
            maxWidth={MAX_FORM_WIDTH}
            collapsible
            expandIfCollapsed={sectionIsActive(communicationSettingsRef)}
            initiallyCollapsed={!isEditMode}
          >
            <MatterCommunicationSettings
              matterId={matterId}
              formInitialised={formInitialised}
              formFields={formFields}
              formFieldValues={formFieldValues}
              formDisabled={formDisabled}
              formSubmitting={formSubmitting}
              submitFailed={submitFailed}
              onFieldValuesUpdated={onFieldValuesUpdated}
              onFieldValueSet={onFieldValueSet}
            />
          </FloatingCard>

          {hasBillingAccess && (
            <FloatingCard
              heading="Invoice Settings"
              ref={invoiceSettingsRef}
              maxWidth={MAX_FORM_WIDTH}
              collapsible
              expandIfCollapsed={sectionIsActive(invoiceSettingsRef)}
              initiallyCollapsed={!isEditMode}
            >
              <MatterInvoiceSettings
                matterId={matterId}
                formInitialised={formInitialised}
                formFields={formFields}
                formFieldValues={formFieldValues}
                formDisabled={formDisabled}
                formSubmitting={formSubmitting}
                submitFailed={submitFailed}
                onFieldValuesUpdated={onFieldValuesUpdated}
                onFieldValueSet={onFieldValueSet}
              />
            </FloatingCard>
          )}
        </div>
      </div>
      <div className={Styles.buttonBar}>
        <div className={Styles.belowNav} />
        <div className={Styles.belowForm}>
          <div className={Styles.submitButton}>
            <Button disabled={formDisabled} onClick={onSubmit}>
              {isEditMode && !isLead && 'Save Matter'}
              {isEditMode && isLead && 'Save Lead'}
              {!isEditMode && 'Create Matter'}
              {formSubmitting && (
                <>
                  {' '}
                  <Spinner small />
                </>
              )}
            </Button>
          </div>
          <div className={Styles.cancelButton}>
            <Button disabled={formDisabled} type={buttonTypes.secondary} onClick={onCancel}>
              Cancel
            </Button>
          </div>
          {featureActive('BB-7676') && isEditMode && !isMatterDeleted && (
            <div className={Styles.deleteButton}>
              <Button disabled={formDisabled} type={buttonTypes.dangerAlt} onClick={onDeleteMatter}>
                Delete {isLead ? 'Lead' : 'Matter'}
              </Button>
            </div>
          )}
        </div>
      </div>
      <ModalDialog
        modalId={confirmationModalId}
        body="Do you want to apply interest on existing past due balances?"
        title=""
        onPrimaryClick={onApplyInterestToUnpaidInvoices}
        onSecondaryClick={doNotApplyInterestToUnpaidInvoices}
      />
      <ModalDialog
        modalId={deletionModalId}
        body={`This will delete your ${isLead ? 'lead' : 'matter'}. Are you sure?`}
        title={isLead ? 'Delete Lead' : 'Delete Matter'}
        buttons={{
          rightAlign: true,
          primary: {
            text: 'Delete',
          },
          secondary: {
            text: 'Keep',
          },
        }}
        onPrimaryClick={onConfirmDeleteMatter}
        onSecondaryClick={doNotDeleteMatter}
      />
    </div>
  );
};

AddEditMatterForm.propTypes = {
  confirmationModalId: PropTypes.string.isRequired,
  deletionModalId: PropTypes.string.isRequired,
  firmDefaultLedesFirmId: PropTypes.string,
  hasBillingAccess: PropTypes.bool.isRequired,
  supportsUtbms: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool,
  isMatterDeleted: PropTypes.bool,
  isMinimumTrustRetainerEnabledForFirm: PropTypes.bool,
  isLead: PropTypes.bool,
  isUtbmsEnabledForFirm: PropTypes.bool,
  matterId: PropTypes.string,
  originalMatterTypeId: PropTypes.string,
  // form state
  formDisabled: PropTypes.bool,
  formFields: PropTypes.object,
  formFieldValues: PropTypes.object,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool,
  sectionsWithErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  submitFailed: PropTypes.bool,
  // callbacks
  doNotApplyInterestToUnpaidInvoices: PropTypes.func.isRequired,
  doNotDeleteMatter: PropTypes.func.isRequired,
  onApplyInterestToUnpaidInvoices: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onConfirmDeleteMatter: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired,
  onFieldValuesUpdated: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // refs
  sectionRefs: sectionRefsType.isRequired,
  // scroll function
  onDeleteMatter: PropTypes.func.isRequired,
  scrollToSection: PropTypes.func.isRequired,
  sectionIsActive: PropTypes.func.isRequired,
};

AddEditMatterForm.defaultProps = {
  firmDefaultLedesFirmId: undefined,
  isLead: false,
  isEditMode: true,
  isMatterDeleted: false,
  isMinimumTrustRetainerEnabledForFirm: undefined,
  isUtbmsEnabledForFirm: undefined,
  matterId: undefined,
  originalMatterTypeId: undefined,
  // form state
  formDisabled: false,
  formFields: undefined,
  formFieldValues: undefined,
  formSubmitting: false,
  submitFailed: false,
};

AddEditMatterForm.displayName = 'AddEditMatterForm';

export default AddEditMatterForm;
