'use strict';

const { getChargeDescription: getLawpayChargeDescription } = require('./lawpay');
const { getChargeDescription: getStripeChargeDescription } = require('./stripe');
const { getChargeDescription: getEzyCollectChargeDescription } = require('./ezy-collect');
const { getChargeDescription: getFeeWiseChargeDescription } = require('./fee-wise');
const { providers } = require('../entities/constants');

const providerSpecificChargeDescriptionFns = {
  [providers.LAWPAY]: getLawpayChargeDescription,
  [providers.STRIPE]: getStripeChargeDescription,
  [providers.EZY_COLLECT]: getEzyCollectChargeDescription,
  [providers.FEE_WISE]: getFeeWiseChargeDescription,
};

/**
 * getChargeDescription
 * Returns the provider specific details that the Payment Portal requires to build the UI needed for a user to transact via a specific provider.
 *
 * @param {Object} params
 * @param {string} params.providerType The provider type that was used to create the charge data.
 * @param {number} params.amountInCents The amount for the charge
 * @param {string} params.firmName The name of the firm to which the charge for the description belongs.
 * @param {string} [params.staffName] Staff name to be used in the staff details portion of description. Ignored if not present.
 * @param {Object} params.providerSpecificFields The provider specific data received from a charge form.
 * @param {Object} params.t Translate function.
 *
 * @returns {string} The charge description.
 */
const getChargeDescription = ({ providerType, amountInCents, firmName, staffName, providerSpecificFields, t }) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!providerSpecificFields) {
    throw new Error('providerSpecificFields must be provided');
  }

  if (!amountInCents) {
    throw new Error('amountInCents must be provided');
  }

  const providerSpecificChargeDescriptionFn = providerSpecificChargeDescriptionFns[providerType];
  if (!providerSpecificChargeDescriptionFn) {
    throw new Error(`Failed to retrieve charge description for unsupported provider type '${providerType}'`);
  }

  return providerSpecificChargeDescriptionFn({
    amountInCents,
    firmName,
    staffName,
    providerSpecificFields,
    t,
  });
};

module.exports = {
  getChargeDescription,
};
