/* eslint-disable import/no-cycle */
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { getById as getPaymentPlanById } from './index';

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbPaymentPlansService',
  keyPath: 'id',
});

export const buildPaymentPlanOpdates = ({ id, ...editedPaymentPlan }) => {
  const storedPaymentPlan = getPaymentPlanById(id) || {};
  return {
    ...storedPaymentPlan,
    ...editedPaymentPlan,
    status: storedPaymentPlan.status || editedPaymentPlan.status || paymentPlanStatuses.ACTIVE,
    installments: storedPaymentPlan.installments || editedPaymentPlan.installments || [],
    id,
  };
};
