import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes } from '../button';
import { Modal } from '../modal';

// eslint-disable-next-line
const DEPRECATED_ProceedDialog = memo(({ title, bodyText, yesCaption, noCaption, onNoClick, onYesClick }) => (
  <Modal
    title={title}
    body={<div>{bodyText}</div>}
    footer={
      <div className="proceed-dialog-container">
        <Button className="col-lg-2" size="full-width" onClick={onYesClick}>
          {yesCaption}
        </Button>
        <Button className="col-lg-2" size="full-width" type={buttonTypes.secondary} onClick={onNoClick}>
          {noCaption}
        </Button>
      </div>
    }
    onModalClose={onNoClick}
  />
));

// eslint-disable-next-line camelcase
DEPRECATED_ProceedDialog.displayName = 'DEPRECATED_ProceedDialog';

// eslint-disable-next-line camelcase
DEPRECATED_ProceedDialog.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  yesCaption: PropTypes.string,
  noCaption: PropTypes.string,
  onYesClick: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
};

// eslint-disable-next-line camelcase
DEPRECATED_ProceedDialog.defaultProps = {
  yesCaption: 'YES',
  noCaption: 'NO',
};

// eslint-disable-next-line
export default DEPRECATED_ProceedDialog;
