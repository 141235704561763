import { createActionPromise } from "web/ng-composable-components/actions/action-factory";
import * as messageDisplay from "@sb-itops/message-display";
import { featureActive } from "@sb-itops/feature";
import { getRegion } from "@sb-itops/region";
import { getByType as getBankAccountByType } from "@sb-billing/redux/bank-account";
import { addBatchPayment } from "@sb-billing/redux/invoices/batch-pay-invoice";

angular
  .module("sb.billing.webapp")
  .directive("sbActionSplitPayment", function($state) {
    "use strict";

    return {
      restrict: "A",
      require: { group: "sbComposeGroup" },
      link: function(scope, element, attrs, ctrl) {
        ctrl.group.setAction(payInvoices, isPayable);

        function payInvoices(payment) {
          // Add the operating account detail for payment from trust
          if (
            featureActive("BB-5509") &&
            getRegion() === "AU" &&
            payment.sourceAccountType &&
            payment.sourceAccountType.toLowerCase() === "trust"
          ) {
            const operatingAccounts = getBankAccountByType("Operating");
            const bankAccountDetails =
              operatingAccounts &&
              JSON.parse(JSON.stringify(operatingAccounts[0]));
            payment.destinationBankAccountName = bankAccountDetails.accountName;
            payment.destinationBankAccountNumber =
              bankAccountDetails.accountNumber;
            payment.destinationBankBranchNumber =
              bankAccountDetails.branchNumber;
          }

          const actionP = createActionPromise({
            composeGroup: ctrl.group,
            action: addBatchPayment
          });

          return actionP(payment)
            .then(() => {
              if (
                payment.sourceAccountType &&
                payment.sourceAccountType.toUpperCase() === "TRUST" &&
                payment.pdfOnTrustPayment === true &&
                // if also printing trust cheque NOW, trust cheque wins and we don't navigate to TTO PDF
                // printing method 1 = Print Now
                !(payment.chequePrintActive && payment.chequePrintMethod === 1)
              ) {
                $state.go('home.billing.trust-to-office-transfer-receipt', {
                  transactionId: payment.transferBetweenAccountsTransactionId,
                  paymentId: payment.paymentId,
                });
              } else if (!(payment.chequePrintActive && payment.chequePrintMethod === 1)) {
                messageDisplay.success('Payment added successfully');
              }
            })
            .catch(() => {
              messageDisplay.error(
                messageDisplay
                  .builder()
                  .text("Failed to process batch payments")
              );
            });
        }

        function isPayable() {
          return true;
        }
      }
    };
  });
