import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyInput2, StatelessSelect } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { rateOverrideTypes } from '@sb-billing/business-logic/rates';

import { StaffRateOverrider } from '../staff-rate-overrider';
import styles from './HourlyRateConfig.module.scss';

const HourlyRateConfig = ({
  // form fields
  rateOverrideType,
  rate,
  customRatePerStaff,
  rateSetId,
  // func & callbacks
  onChange,
  // form state & others
  rateSets,
  staffMembers,
  hasError,
  disabled,
}) => {
  const rateSetOptions = rateSets.map((rateSet) => ({ value: rateSet.id, label: rateSet.name }));

  return (
    <div className={classnames('form-group', styles.hourlyRateConfigV2)}>
      {/* Do not override */}
      <div className={classnames('radio', styles.radioLabel)}>
        <label className={styles.rateTypeOption}>
          <input
            type="radio"
            className={styles.radioInput}
            value={rateOverrideTypes.NO_OVERRIDE}
            checked={rateOverrideType === rateOverrideTypes.NO_OVERRIDE}
            onChange={(e) =>
              onChange({
                rateType: parseInt(e.target.value, 10),
                rate,
                customRatePerStaff,
                rateSetId,
              })
            }
            disabled={disabled}
          />
          Do not override rate
          <i
            title="Staff rates will be used when creating time entries."
            className="icon icon-question-mark"
            color="lightgrey"
          />
        </label>
      </div>

      {/* use rate sets */}
      {featureActive('BB-10835') && (
        <div className={classnames('radio', styles.radioLabel)}>
          <label className={styles.rateTypeOption}>
            <input
              type="radio"
              className={styles.radioInput}
              value={rateOverrideTypes.RATE_SET_OVERRIDE}
              checked={rateOverrideType === rateOverrideTypes.RATE_SET_OVERRIDE}
              onChange={(e) =>
                onChange({
                  rateType: parseInt(e.target.value, 10),
                  rate,
                  customRatePerStaff,
                  rateSetId,
                })
              }
              disabled={disabled}
            />
            Use rate sets
          </label>
          <br />
          <div className={styles.rateTypeSettings}>
            <div className={styles.label}>Rate</div>
            <StatelessSelect
              options={rateSetOptions}
              selectedOption={rateSetId}
              placeholder="Select Rate"
              hasError={rateOverrideType === rateOverrideTypes.RATE_SET_OVERRIDE && hasError}
              onChange={(option) =>
                onChange({
                  rateType: rateOverrideTypes.RATE_SET_OVERRIDE,
                  rate,
                  customRatePerStaff,
                  rateSetId: option.value,
                })
              }
            />
          </div>
        </div>
      )}

      {/* override rate for all staff */}
      <div className={classnames('radio', styles.radioLabel, 'form-inline')}>
        <label className={styles.rateTypeOption}>
          <input
            type="radio"
            className={styles.radioInput}
            value={rateOverrideTypes.ALL_STAFF_OVERRIDE}
            checked={rateOverrideType === rateOverrideTypes.ALL_STAFF_OVERRIDE}
            onChange={(e) =>
              onChange({
                rateType: parseInt(e.target.value, 10),
                rate,
                customRatePerStaff,
                rateSetId,
              })
            }
            disabled={disabled}
          />
          Override rate for all staff
        </label>
        <br />
        <div className={styles.rateTypeSettings}>
          <div className={styles.label}>Rate</div>
          <CurrencyInput2
            value={rate}
            onChange={(e) =>
              onChange({
                rateType: rateOverrideTypes.ALL_STAFF_OVERRIDE,
                rate: parseFloat(e.target.value),
                customRatePerStaff,
                rateSetId,
              })
            }
            className={styles.inputGroup}
            placeholder="0.00 Per Hour"
            hasError={rateOverrideType === rateOverrideTypes.ALL_STAFF_OVERRIDE && hasError}
            useValue
          />
        </div>
      </div>

      {/* override default rate per staff member */}
      <div className={classnames('radio', styles.radioLabel)}>
        <label className={styles.rateTypeOption}>
          <input
            type="radio"
            className={styles.radioInput}
            value={rateOverrideTypes.CUSTOM_STAFF_OVERRIDE}
            checked={rateOverrideType === rateOverrideTypes.CUSTOM_STAFF_OVERRIDE}
            onChange={(e) =>
              onChange({
                rateType: parseInt(e.target.value, 10),
                rate,
                customRatePerStaff,
                rateSetId,
              })
            }
            disabled={disabled}
          />
          Override default rate per staff member
        </label>
        <br />
        <div className={styles.rateTypeSettings}>
          <StaffRateOverrider
            staffMembers={staffMembers}
            overrides={customRatePerStaff || []}
            hasError={rateOverrideType === rateOverrideTypes.CUSTOM_STAFF_OVERRIDE && hasError}
            onUpdate={(overrides) =>
              onChange({
                rateType: rateOverrideTypes.CUSTOM_STAFF_OVERRIDE,
                rate,
                customRatePerStaff: overrides,
                rateSetId,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

HourlyRateConfig.propTypes = {
  // form fields
  rateOverrideType: PropTypes.number,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  customRatePerStaff: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  rateSetId: PropTypes.string,
  // func & callbacks
  onChange: PropTypes.func.isRequired, //  takes params { rateType, rate, customRatePerStaff, rateSetId }
  // form state & others
  rateSets: PropTypes.arrayOf(PropTypes.object),
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      initials: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      name: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

HourlyRateConfig.defaultProps = {
  // form fields
  rateOverrideType: rateOverrideTypes.NO_OVERRIDE,
  rate: '',
  customRatePerStaff: [],
  rateSetId: undefined,
  // form state & others
  rateSets: [],
  staffMembers: [],
  hasError: false,
  disabled: false,
};

export default HourlyRateConfig;
