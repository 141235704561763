import { featureActive } from '@sb-itops/feature';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { fetchGetP } from '@sb-itops/redux/fetch';

const initialiseChurnZero = async ({ log, defaultTeardown }) => {
  if (!featureActive('BB-13244')) {
    return defaultTeardown;
  }

  const czScriptUrl = 'https://smokeball.us1app.churnzero.net/churnzero.js';
  const czApiKey = '1!dbajfvkmzcuKRxyQ7I1gybINluDwLukU28sYn6U2ILQtBA0';
  // If there is no Churn Zero instance, we can use regular array to queue commands which Churn Zero runs after its initialisation
  window.ChurnZero = window.ChurnZero || [];

  try {
    (function () {
      // Since this functions runs in postAuthBootstrap, it runs after every login.
      // We want to make sure we only include the script once even after repeated logins.
      if (document.getElementById('sb-churn-zero')) {
        return;
      }
      // Official ChurnZero code to add script
      var cz = document.createElement('script');
      cz.id = 'sb-churn-zero';
      cz.type = 'text/javascript';
      cz.async = true;
      cz.src = czScriptUrl;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(cz, s);
    })();

    window.ChurnZero.push(['setAppKey', czApiKey]);
    window.ChurnZero.push(['setContact', getAccountId(), getUserId()]);

    const activeProviderType = await getActivePaymentProviderType({ log });
    if (activeProviderType) {
      // If there was an error, we rather don't report anything than report wrong provider type.
      // If none provider is set, the function above retruns NONE which we want to report.
      window.ChurnZero.push(['setAttribute', 'account', 'Payment Processor', activeProviderType]);
    }
  } catch (err) {
    // We shouldn't stop the user from using the web app if Churn Zero fails, just log it.
    log.warn('Failed to initialise Churn Zero', err);
  }

  return () => {
    // Teardown
    // Reset ChurnZero instance and remove parameters pushed before.
    // This allows us to re-initialise Churn Zero with adifferent user without app refresh
    window.ChurnZero.push(['stop']);
  };
};

const getActivePaymentProviderType = async ({ log }) => {
  try {
    const response = await fetchGetP({ path: '/billing/payment-provider/settings/sync/:accountId/all' });

    if (response.status === 200 && response.body) {
      return response.body.activeProvider || 'NONE';
    }
  } catch (err) {
    log.warn('Failed to get active provider during ChurnZero initialisation', err);
  }
};

export { initialiseChurnZero };
