import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { AdjustmentsTable } from '@sb-billing/react';
import * as bankReconciliationsFeature from 'web/redux/route/home-billing-create-bank-reconciliation';
import { withScopedFeature } from '@sb-itops/redux/hofs';

const mapStateToProps = (state, { trustAccountId }) => {
  const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
  const {
    selectors: { getAdjustments },
  } = withScopedFeature({ state, scope: bankRecScope })(bankReconciliationsFeature);

  let adjustments = [];
  adjustments = getAdjustments(state);

  return {
    adjustments,
  };
};

const mapDispatchToProps = (dispatch, { onUpdate, trustAccountId }) => {
  const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
  const {
    actions: { toggleRemoveAdjustment, toggleReconcileAdjustment },
  } = withScopedFeature({ scope: bankRecScope })(bankReconciliationsFeature);

  return {
    onToggleReconcile: (adjustmentId) => {
      dispatch(toggleReconcileAdjustment({ adjustmentId }));
      onUpdate();
    },
    onToggleDelete: (adjustmentId) => {
      dispatch(toggleRemoveAdjustment({ adjustmentId }));
      onUpdate();
    },
  };
};

const AdjustmentsTableWrapper = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(AdjustmentsTable));

AdjustmentsTableWrapper.displayName = 'AdjustmentsTableWrapper';

AdjustmentsTableWrapper.propTypes = {
  route: PropTypes.string,
  id: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

AdjustmentsTableWrapper.defaultProps = {
  route: '',
  id: '',
};

export default AdjustmentsTableWrapper;
