import { getByMatterIdWithStringAsEnums as getMatterInvoiceSettingsWithStringAsEnums } from '@sb-billing/redux/matter-invoice-settings';
import { getDetails as getFirmDefaultSettingsWithStringAsEnums } from '@sb-billing/redux/invoice-settings';
import { getByTemplateIdSettingWithStringAsEnums, getFirmDefault } from '@sb-billing/redux/invoice-settings-template';
import { getInvoiceLatestVersion } from '@sb-billing/redux/invoices';
import { determineShowRetainerSetting } from '@sb-billing/business-logic/evergreen-retainer';
import { featureActive } from '@sb-itops/feature';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import {
  determineFinalSectionOptions,
  determineFinalInvoiceAdditionalOptionsAndLayout,
} from '@sb-billing/business-logic/invoice-settings';

import { getDefaultTitleLine1FromSetting, getDefaultTitleLine2FromSetting } from 'web/redux/selectors/invoice-title';

const defaultMatterSetting = {};

const getChangesFromStoredInvoice = (invoiceId, { titleLine1CustomText, titleLine2CustomText, footer }) => {
  const storedInvoice = getInvoiceLatestVersion(invoiceId);
  // this mean it is not draft
  if (storedInvoice) {
    const { invoiceTitle, invoiceTitleLine2, templateId, footer: footerStored } = storedInvoice;
    const newSettings = { templateId };

    if (invoiceTitle !== undefined && titleLine1CustomText !== invoiceTitle) {
      newSettings.titleLine1CustomText = invoiceTitle;
      newSettings.titleLine1Overridden = true;
    }

    if (invoiceTitleLine2 !== undefined && titleLine2CustomText !== invoiceTitleLine2) {
      newSettings.titleLine2CustomText = invoiceTitleLine2;
      newSettings.titleLine2Overridden = true;
    }

    if (footer !== footerStored) {
      newSettings.footer = footerStored;
      newSettings.footerOverridden = true;
    }

    const additionalOptionsFromInvoice = storedInvoice.additionalOptions;
    if (additionalOptionsFromInvoice) {
      newSettings.additionalOptionsFromInvoice = additionalOptionsFromInvoice;
    }

    const eInvoiceOptionsFromInvoice = storedInvoice.eInvoiceOptions;
    if (eInvoiceOptionsFromInvoice) {
      newSettings.eInvoiceOptionsFromInvoice = eInvoiceOptionsFromInvoice;
    }

    const layoutFromInvoice = storedInvoice.layout;
    if (layoutFromInvoice) {
      newSettings.layoutFromInvoice = layoutFromInvoice;
    }

    return newSettings;
  }

  return undefined;
};

const getEInvoiceOptionsFromCurrentSettings = (originalTemplate, invoiceSetting, selectedTemplateConfiguration) => {
  const newOptions = {
    ...originalTemplate.eInvoiceOptions,
    ...(invoiceSetting.eInvoiceOptionsFromInvoice || {}),
    ...(selectedTemplateConfiguration || {}),
  };
  return { options: newOptions };
};

const getIsTemplateWithDefaults = (hasDefaults, currentSettings, originalTemplate) => {
  if (hasDefaults === undefined) {
    return Object.entries(currentSettings)
      .filter(
        ([key]) =>
          key !== 'invoiceAdditionalOptions' &&
          key !== 'eInvoiceOptions' &&
          key !== 'templateId' &&
          key !== 'isTemplateWithDefaults',
      )
      .every(([key, value]) => originalTemplate[key] === value);
  }

  return hasDefaults;
};

const getOriginalTemplateById = (templateId) =>
  (templateId && getByTemplateIdSettingWithStringAsEnums(templateId)) || getFirmDefaultSettingsWithStringAsEnums();

const getCurrentOverriddenTemplate = (templateId, matterId) => {
  const matterSettings = getMatterInvoiceSettingsWithStringAsEnums(matterId);

  if (matterSettings) {
    // in some cases the template id in the matter setting can be undefined since it was not previously saved
    if (templateId === (matterSettings.templateId || getFirmDefault().id)) {
      return matterSettings;
    }
  }

  return getOriginalTemplateById(templateId);
};

// Invoice options are resolved using the following precedent hierarchy in the matter settings:
// Matter level overrides > Matter Template > Firm Template.
const getCurrentMatterTemplate = (matterId, selectedTemplate = null, selectedTemplateConfiguration = null) => {
  let settings = getMatterInvoiceSettingsWithStringAsEnums(matterId) || defaultMatterSetting;

  const currentTemplateId = (selectedTemplate && selectedTemplate.templateId) || settings.templateId;
  const originalTemplate = getOriginalTemplateById(currentTemplateId);

  const templateIdChanged = selectedTemplate && settings && selectedTemplate.templateId !== settings.templateId;
  if (templateIdChanged) {
    settings = originalTemplate;
  }

  if (selectedTemplateConfiguration) {
    settings = { ...settings, ...selectedTemplateConfiguration };
  }

  const {
    titleLine1Overridden,
    titleLine2Overridden,
    titleLine1Option,
    titleLine1CustomText,
    titleLine2Option,
    titleLine2CustomText,
    footer,
    footerOverridden,
  } = settings || {};

  const { isTemplateWithDefaults } = selectedTemplateConfiguration || {};

  const currentLine1Overridden = templateIdChanged || titleLine1Overridden;
  const currentLine2Overridden = templateIdChanged || titleLine2Overridden;
  const currentFooterOverridden = templateIdChanged || footerOverridden;

  const currentSettings = {
    titleLine1Option: currentLine1Overridden ? titleLine1Option : originalTemplate.titleLine1Option,
    titleLine1CustomText: currentLine1Overridden ? titleLine1CustomText : originalTemplate.titleLine1CustomText,
    titleLine2Option: currentLine2Overridden ? titleLine2Option : originalTemplate.titleLine2Option,
    titleLine2CustomText: currentLine2Overridden ? titleLine2CustomText : originalTemplate.titleLine2CustomText,
    footer: currentFooterOverridden ? footer : originalTemplate.footer,
    paymentDueDays: originalTemplate.paymentDueDays,
    templateId: currentTemplateId,
  };

  currentSettings.isTemplateWithDefaults = getIsTemplateWithDefaults(
    isTemplateWithDefaults,
    currentSettings,
    originalTemplate,
  );

  currentSettings.titleLine1Overridden = false;
  currentSettings.titleLine2Overridden = false;
  currentSettings.footerOverridden = false;

  if (!currentSettings.isTemplateWithDefaults) {
    currentSettings.titleLine1Overridden =
      (!!selectedTemplateConfiguration &&
        !!isTemplateWithDefaults &&
        (selectedTemplateConfiguration.titleLine1Option || selectedTemplateConfiguration.titleLine1CustomText)) ||
      titleLine1Overridden;

    currentSettings.titleLine2Overridden =
      (!!selectedTemplateConfiguration &&
        !!isTemplateWithDefaults &&
        (selectedTemplateConfiguration.titleLine2Option || selectedTemplateConfiguration.titleLine2CustomText)) ||
      titleLine2Overridden;

    currentSettings.footerOverridden =
      (!!selectedTemplateConfiguration && !!isTemplateWithDefaults && selectedTemplateConfiguration.footer) ||
      footerOverridden;
  }

  return currentSettings;
};

// Invoice options are resolved using the following precedent hierarchy in the invoice configuration:
// Invoice level template overrides > Invoice template > Matter level overrides > Matter Template > Firm Template.
const getCurrentMatterInvoiceTemplate = (
  matterId,
  invoiceId,
  selectedTemplate = null,
  selectedTemplateConfiguration = null, // An object that contains any setting changes made by the user
) => {
  const matterBillingConfiguration = getCurrentConfigurationByMatterId(matterId) || {};
  const matterInvoiceSettings = getMatterInvoiceSettingsWithStringAsEnums(matterId);
  let currentDraftInvoiceSettings = matterInvoiceSettings || defaultMatterSetting;

  const storedResults = getChangesFromStoredInvoice(invoiceId, currentDraftInvoiceSettings);
  currentDraftInvoiceSettings = { ...currentDraftInvoiceSettings, ...storedResults };
  const isDraftInvoice = !storedResults;

  const currentTemplateId = (selectedTemplate && selectedTemplate.templateId) || currentDraftInvoiceSettings.templateId;
  const originalTemplate = getOriginalTemplateById(currentTemplateId);

  if (
    selectedTemplate &&
    currentDraftInvoiceSettings &&
    selectedTemplate.templateId !== currentDraftInvoiceSettings.templateId
  ) {
    currentDraftInvoiceSettings = getCurrentOverriddenTemplate(currentTemplateId, matterId);
  }

  if (selectedTemplateConfiguration) {
    currentDraftInvoiceSettings = { ...currentDraftInvoiceSettings, ...selectedTemplateConfiguration };
  }

  const {
    titleLine1Overridden,
    titleLine2Overridden,
    titleLine1Option,
    titleLine1CustomText,
    titleLine2Option,
    titleLine2CustomText,
    footer,
    footerOverridden,
  } = currentDraftInvoiceSettings || {};

  const { isTemplateWithDefaults } = selectedTemplateConfiguration || {};

  const sectionOptions = featureActive('BB-12385')
    ? determineFinalSectionOptions({
        originalInvoiceSettingsTemplate: originalTemplate,
        invoiceAdditionalOptions: currentDraftInvoiceSettings.additionalOptionsFromInvoice,
        overriddenDraftInvoiceSettings: selectedTemplateConfiguration,
      })
    : undefined;

  const invoiceAdditionalOptions = determineFinalInvoiceAdditionalOptionsAndLayout({
    originalInvoiceSettingsTemplate: originalTemplate,
    currentDraftInvoiceSettings,
    overriddenDraftInvoiceSettings: selectedTemplateConfiguration,
    sectionOptions,
    invoiceSupplementaryTablesPageBreakEnabled: featureActive('BB-12385'),
    showItemNumbersEnabled: featureActive('BB-12394'),
  });

  if (featureActive('BB-6908')) {
    const invoiceVersion = getInvoiceLatestVersion(invoiceId);

    invoiceAdditionalOptions.options.showRetainer = Object.hasOwn(selectedTemplateConfiguration || {}, 'showRetainer')
      ? currentDraftInvoiceSettings.showRetainer
      : determineShowRetainerSetting({
          matterBillingConfiguration,
          invoiceTemplateSettings: originalTemplate,
          matterInvoiceSettings,
          invoiceVersion,
        });
  } else {
    invoiceAdditionalOptions.options.showRetainer = false;
  }

  const currentSettings = {
    titleLine1Option: titleLine1Overridden ? titleLine1Option : originalTemplate.titleLine1Option,
    titleLine1CustomText: titleLine1Overridden ? titleLine1CustomText : originalTemplate.titleLine1CustomText,
    titleLine2Option: titleLine2Overridden ? titleLine2Option : originalTemplate.titleLine2Option,
    titleLine2CustomText: titleLine2Overridden ? titleLine2CustomText : originalTemplate.titleLine2CustomText,
    footer: footerOverridden ? footer : originalTemplate.footer,
    templateId: currentTemplateId,
    invoiceAdditionalOptions,
    sectionOptions,
    eInvoiceOptions: getEInvoiceOptionsFromCurrentSettings(
      originalTemplate,
      currentDraftInvoiceSettings,
      selectedTemplateConfiguration,
    ),
    isTemplateWithDefaults: isTemplateWithDefaults === undefined || isTemplateWithDefaults,
    titleLine1Overridden,
    titleLine2Overridden,
    footerOverridden,
    paymentDueDays: originalTemplate.paymentDueDays,
  };

  currentSettings.titleLine1DefaultText = getDefaultTitleLine1FromSetting(currentSettings, matterId);
  currentSettings.titleLine2DefaultText = getDefaultTitleLine2FromSetting(currentSettings, matterId);

  if (isDraftInvoice) {
    // when we have a draft invoice we have to clear any previous saved custom text and retain the matter settings selection.
    // this is for cases we have pre saved data
    currentSettings.titleLine1CustomText =
      titleLine1Option === 'Custom' ? titleLine1CustomText : currentSettings.titleLine1DefaultText;
    currentSettings.titleLine2CustomText =
      titleLine2Option === 'Custom' ? titleLine2CustomText : currentSettings.titleLine2DefaultText;
  }

  return currentSettings;
};

/**
 * Gets the effective invoice template with all the overrides in place
 *
 * Please note: This is no longer being used for Create Invoice after it was
 * converted to Load on Demand. It has been replaced by:
 * billing/business-logic/invoice/services/get-current-invoice-template
 *
 * @param {*} matterId for getting the saved matter invoice settings
 * @param {*} invoiceId for getting the saved invoice settings
 * @param {*} selectedTemplate the unsaved template selection, i.e. in matter settings ~~or create invoice~~
 * @param {*} selectedTemplateConfiguration the unsaved template overrides, i.e. in matter settings ~~or create invoice~~
 */
export const getCurrentInvoiceTemplate = (
  matterId,
  invoiceId,
  selectedTemplate = null,
  selectedTemplateConfiguration = null,
) => {
  if (invoiceId) {
    return getCurrentMatterInvoiceTemplate(matterId, invoiceId, selectedTemplate, selectedTemplateConfiguration);
  }
  return getCurrentMatterTemplate(matterId, selectedTemplate, selectedTemplateConfiguration);
};
