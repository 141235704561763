import { getContactMailingAddress, getContactName } from '@sb-customer-management/business-logic/contacts/services';
import { getRegion, regionType } from '@sb-itops/region';
import { fetchById as fetchContactById } from './index';
import { getContactDisplay } from '../contacts-summary';

export const getDebtorDefaultNameAndAddress = async ({ id }) => {
  const debtor = await fetchContactById(id);
  const debtorName = getContactName(debtor);
  const mailingAddress = getContactMailingAddress(debtor) || {};
  const { specialInstructions, addressLine0, addressLine1, addressLine2, city, state, zipCode } = mailingAddress || {};

  /*
   *  any changes here need to be reflected in:
   *  - monorepo/itops/modules/handlebars-helpers/partials/firm-header.hbs
   *  - monorepo/billing/endpoints/template/templates/einvoices/einvoice-summary.hbs
   *  - monorepo/billing/endpoints/template/templates/invoices/invoice.hbs
   */
  const addressLines =
    `${debtorName}\n` +
    `${specialInstructions ? `${specialInstructions.trim()}\n` : ''}` +
    `${addressLine0 ? `${addressLine0.trim()}\n` : ''}` +
    `${getFormattedAddressStrategy({
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    })}`;

  return {
    debtorId: id,
    debtorDisplayName: getContactDisplay(id, { showLastNameFirst: true }),
    addressLines,
  };
};

const getFormattedAddressStrategy = ({ addressLine1, addressLine2, city, state, zipCode }) => {
  const formattedAddressStrategy = {
    [regionType.AU]:
      // eslint-disable-next-line prettier/prettier
      `${addressLine1 ? `${addressLine1.trim()}\n` : ''}` + 
      `${addressLine2 ? `${addressLine2.trim()}\n` : ''}`,
    [regionType.GB]:
      `${addressLine1 ? `${addressLine1.trim()}\n` : ''}` +
      `${city ? `${city}\n` : ''}` +
      `${zipCode ? `${zipCode}\n` : ''}`,
    [regionType.US]:
      `${addressLine1 ? `${addressLine1.trim()}\n` : ''}` +
      `${addressLine2 ? `${addressLine2.trim()}\n` : ''}` +
      `${`${city ? `${city}, ` : ''}${state || ''} ${zipCode || ''}\n`}`,
  };

  return formattedAddressStrategy[getRegion()];
};
