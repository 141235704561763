angular.module('sb.billing.webapp').component('sbDepositSlipList', {
  templateUrl: 'ng-components/deposit-slip-list/deposit-slip-list.html',
  bindings: { accountType: '<?', trustAccountId: '<?' },
  controller: function ($state, sbLoggerService, sbUnsavedChangesService, sbLinkService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbDepositSlipList');

    // log.setLogLevel('info');

    ctrl.dataChangeFunction  = dataChangeFunction;
    ctrl.viewDepositSlip = viewDepositSlip;
    ctrl.onSort = onSort;
    ctrl.editDepositSlip = editDepositSlip;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.deleteSlip = () => {}; //no-op will get overwritten by action

    ctrl.viewState = {
      sortBy: 'depositDate',
      sortDirection: 'desc',
    };

    ctrl.sbData = {};

    const loadSortStateFromMemory = () => {
      const loadMemory = sbUnsavedChangesService.loadMemory('sbDepositSlipList_filters');
      if (loadMemory) {
        const { sortState } = loadMemory;
        if (Object.keys(sortState).length > 0) {
          ctrl.viewState = {
            ...ctrl.viewState,
            sortBy: sortState.sortBy,
            sortDirection: sortState.sortDirection,
          };
        }
      }
    };

    loadSortStateFromMemory();

    ctrl.$onDestroy = () => {
      sbUnsavedChangesService.saveMemory('sbDepositSlipList_filters', {
        sortState: {
          sortBy: ctrl.viewState.sortBy,
          sortDirection: ctrl.viewState.sortDirection,
        },
      });
    };

    function dataChangeFunction(key, doc) {
      if (doc && doc.data) {
        _.set(ctrl.sbData, key, doc.data);
      } else {
        _.set(ctrl.sbData, key, doc);
      }
      log.info('depositSlips: ', ctrl.sbData);

      onSort({ sortBy: ctrl.viewState.sortBy, sortDirection: ctrl.viewState.sortDirection });
    }

    function onSort({ sortBy, sortDirection }) {
      const newDirection = sortDirection.toLowerCase();
      ctrl.viewState = {
        sortBy,
        sortDirection: newDirection,
      };

      ctrl.sbData.depositSlips = _.sortByOrder(ctrl.sbData.depositSlips, [sortBy], [newDirection]);
    }

    function viewDepositSlip (depositSlip) {
      $state.go('home.billing.view-deposit-slip', { depositSlipId: depositSlip.id });
    }

    function editDepositSlip (depositSlip) {
      $state.go('home.billing.edit-deposit-slip', { depositSlipId: depositSlip.id });
    }
  }
});
