angular.module('sb.billing.webapp').component('sbDuiStoreInsert', {
  templateUrl: 'ng-composable-components/data-ui/custom/store-insert/dui-store-insert.html',
  controllerAs: 'ctrl',
  controller: function ($scope, sbLoggerService, sbMessageDisplayService, $indexedDB, $window) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbDuiStoreInsert');
    const DISPLAY_REMOVAL_GROUP ='indexeddb';

    //log.setLogLevel('info');

    that.upsert = upsert;
    that.edit = edit;
    that.remove = remove;
    that.clear = clear;

    $window.Smokeball = $window.Smokeball || {};

    $scope.$watch('ctrl.model.store', updateView);

    that.$onInit = () => {
      that.view = {
        stores: []
      };
      that.model = {};

      $indexedDB.databaseInfo()
        .then(info => {
          log.info('dbInfo', info);
          that.view.stores = _.sortBy(info.objectStores, 'name');
          that.model.store = that.view.stores[0];
        });
    };

    that.$onDestroy = () => {
      delete $window.Smokeball.indexedDbEntries;
    };

    function updateView() {
      log.info('store', that.model.store);

      if (that.model.store) {
        that.model.data = JSON.stringify({
          [that.model.store.keyPath]: ''
        }, undefined, 4);

        $indexedDB.openStore(that.model.store.name, function(store){
          store.getAll()
            .then(function(entries) {
              that.view.entries = _.sortBy(entries, that.model.store.keyPath);
              $window.Smokeball.indexedDbEntries = that.view.entries;
            });
        });
      }
    }

    function edit(entry) {
      log.info('edit', entry[that.model.store.keyPath]);
      that.model.data = JSON.stringify(entry, undefined, 4);
    }

    function remove(entry) {
      log.info('remove', entry[that.model.store.keyPath]);
      $indexedDB.openStore(that.model.store.name, function(store){
        store.delete(entry[that.model.store.keyPath])
          .then(function(){
            log.info('deleted', entry);
            updateView();
          })
          .catch((e) => {
            log.error(e);
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text(e.message)
                .group(DISPLAY_REMOVAL_GROUP)
            );
          });
      });
    }

    function clear() {
      $indexedDB.openStore(that.model.store.name, function(store){
        store.clear().then(function(){
          sbMessageDisplayService.success(
            sbMessageDisplayService
              .builder()
              .text('Store cleared!')
              .group(DISPLAY_REMOVAL_GROUP)
          );
          updateView();
        })
        .catch((e) => {
          log.error(e);
          sbMessageDisplayService.error(
            sbMessageDisplayService
              .builder()
              .text(e.message)
              .group(DISPLAY_REMOVAL_GROUP)
          );
        });
      });
    }

    function upsert() {
      let data;
      log.info(`insert ${that.model.store.name}`, that.model.data);

      try {
        data = JSON.parse(that.model.data);
      } catch (e) {
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text('Not valid JSON')
            .group(DISPLAY_REMOVAL_GROUP)
        );
      }

      $indexedDB.openStore(that.model.store.name, function (store) {
        store.upsert(data)
          .then((res) => {
            log.info(res);
            sbMessageDisplayService.success(
              sbMessageDisplayService
                .builder()
                .text('Saved!')
                .group(DISPLAY_REMOVAL_GROUP)
            );
            updateView();
          })
          .catch((e) => {
            log.error(e);
            sbMessageDisplayService.error(
              sbMessageDisplayService
                .builder()
                .text(e.message)
                .group(DISPLAY_REMOVAL_GROUP)
            );
          });
      });
    }
  }
});
