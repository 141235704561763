import utilsFactory from '../utils-factory';
import { domain } from '../domain';
import reducer from './reducer';

// `immutable: false` required as immu + react dont play well together - See https://github.com/facebook/react/issues/16058
const { registerReducer, getState } = utilsFactory({
  reduxPath: `${domain}/pre-sign-url`,
  immutable: false,
});

registerReducer(reducer);

export { getState };
