import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sb-itops/react/icon';
import classnames from 'classnames';
import Styles from './PaymentPlanInformationBox.module.scss';

const PaymentPlanInformationBox = memo(({ text, showWarning, showError, tooltip, description, showInfo }) => (
  <div className={classnames(Styles.paymentPlanInformationBox, Styles.container)}>
    <div className={Styles.row}>
      <div className={Styles.row}>
        <label>{text}</label>
      </div>
      <div className={classnames(Styles.row, Styles.description)}>{description}</div>
    </div>
    {showError && (
      <div className={classnames(Styles.column10, Styles.warningIcon)}>
        <Icon type="icon-fat-exclamation-circle-filled" color="#dd4442" tooltip={tooltip || description} />
      </div>
    )}
    {showWarning && (
      <div className={classnames(Styles.column10, Styles.warningIcon)}>
        <Icon isFA type="warning" color="#dd4442" tooltip={tooltip || description} />
      </div>
    )}
    {showInfo && (
      <div className={classnames(Styles.column10, Styles.warningIcon)}>
        <Icon type="information" color="darkslategrey" tooltip={tooltip || description} />
      </div>
    )}
  </div>
));

PaymentPlanInformationBox.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.string,
  showWarning: PropTypes.bool,
  showError: PropTypes.bool,
  showInfo: PropTypes.bool,
};

PaymentPlanInformationBox.defaultProps = {
  text: '',
  description: '',
  tooltip: undefined,
  showWarning: false,
  showError: false,
  showInfo: false,
};

export default PaymentPlanInformationBox;
