import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { AccordianMultiSelect } from '@sb-itops/react/accordian-multi-select';
import { billingFrequencyTree } from '@sb-billing/business-logic/matters/billing-config';

function mapStateToProps(state, props) {
  return {
    className: props.className,
    collapseTreeOnDeselect: props.collapseTreeOnDeselect,
    tree: billingFrequencyTree,
    selectedList: props.selectedBillingFrequencySubTypes,
    onSelect: props.onSelect,
  };
}

const BillingFrequencyTreeSelect = withReduxStore(connect(mapStateToProps)(AccordianMultiSelect));

BillingFrequencyTreeSelect.propTypes = {
  className: PropTypes.string,
  collapseTreeOnDeselect: PropTypes.bool,
  selectedBillingFrequencySubTypes: PropTypes.arrayOf(PropTypes.number),
  // func & callback
  onSelect: PropTypes.func.isRequired,
};

BillingFrequencyTreeSelect.defaultProps = {
  className: undefined,
  collapseTreeOnDeselect: false,
  selectedBillingFrequencySubTypes: [],
};

export { BillingFrequencyTreeSelect };
