'use strict';

const entities = require('./entities');

const { applyInvoiceActionLinks } = require('./apply-invoice-action-links');
const { applyInvoiceHeader } = require('./apply-invoice-header');
const { buildInvoiceEmailWithSummaryText } = require('./build-invoice-email-with-summary-text');
const { convertInvoiceEmailRequestsToEmailDetails } = require('./convert-invoice-email-requests-to-email-details');
const { convertInvoiceEmailRequestToEmailDetail } = require('./convert-invoice-email-request-to-email-detail');
const { createPayNowButton } = require('./create-pay-now-button');
const { createPayNowLink } = require('./create-pay-now-link');
const { createSummaryBox } = require('./create-summary-box');
const { createViewInvoiceButton } = require('./create-view-invoice-button');
const { formatStaffMemberEmailAddress } = require('./format-staff-member-email-address');
const { getDepositRequestEmailMacros } = require('./get-deposit-request-email-macros');
const { getEvergreenRetainerEmailMacros } = require('./get-evergreen-retainer-email-macros');
const { getInvoiceEmailMacros } = require('./get-invoice-email-macros');
const { getInvoiceLogoUrl } = require('./get-invoice-logo-url');
const { getInvoiceReminderEmailMacros } = require('./get-invoice-reminder-email-macros');
const { getPayButtonOptionForMatters } = require('./get-pay-button-option-for-matters');
const { modifyEmailBody } = require('./modify-email-body');
const { modifyEmailSubject } = require('./modify-email-subject');
const { validateInvoiceEmailRequest } = require('./validate-invoice-email-request');

module.exports = {
  entities,
  applyInvoiceActionLinks,
  applyInvoiceHeader,
  buildInvoiceEmailWithSummaryText,
  convertInvoiceEmailRequestsToEmailDetails,
  convertInvoiceEmailRequestToEmailDetail,
  createPayNowButton,
  createPayNowLink,
  createSummaryBox,
  createViewInvoiceButton,
  formatStaffMemberEmailAddress,
  getDepositRequestEmailMacros,
  getEvergreenRetainerEmailMacros,
  getInvoiceEmailMacros,
  getInvoiceLogoUrl,
  getInvoiceReminderEmailMacros,
  getPayButtonOptionForMatters,
  modifyEmailBody,
  modifyEmailSubject,
  validateInvoiceEmailRequest,
};
