import React from 'react';
import PropTypes from 'prop-types';

export const InvoiceStatusIndicator = React.memo(({ status }) => (
  <div className="billing-invoice-status-indicator">
    <span className="invoice-status-indicator">
      <i className={`fa fa-circle ${status.toLowerCase()} `} />
    </span>
    <span className="invoice-status-label">{status === 'FINAL' ? 'Unpaid' : status.toLowerCase()}</span>
  </div>
));

InvoiceStatusIndicator.displayName = 'InvoiceStatusIndicator';

InvoiceStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
};

InvoiceStatusIndicator.defaultProps = {};

export default InvoiceStatusIndicator;
