'use strict';

const { durationType: durationTypeEnum } = require('../../shared/entities');
const { fuzzyTime } = require('../../shared/services');

const { convertMinsToUnits } = fuzzyTime;

/**
 * calculateDurationFieldValue
 *
 * Determines the duration field value for a given duration type
 *
 * @param {Object} params
 * @param {durationTypeEnum} params.durationType
 * @param {number} params.durationInMins
 * @param {number} interval
 * @returns {string}
 */
function calculateDurationFieldValue(props) {
  const { durationType, durationInMins, interval } = props;

  const validDurationType = Object.keys(durationTypeEnum).includes(durationType);
  const validDurationInMins = Number.isInteger(durationInMins) && durationInMins >= 0;
  const validInterval = Number.isInteger(interval) && interval > 0 && interval <= 60;

  if (!validDurationType) {
    throw new Error('Please provide a valid durationType value');
  }

  if (!validDurationInMins) {
    throw new Error('Please provide a valid durationInMins value');
  }

  if (!validInterval) {
    throw new Error('Please provide a valid interval value');
  }

  let duration;

  switch (durationType) {
    case durationTypeEnum.FIXED:
      duration = 0;
      break;
    case durationTypeEnum.UNITS:
      duration = convertMinsToUnits({
        mins: durationInMins,
        interval,
      });
      break;
    case durationTypeEnum.HOURS:
      duration = +(durationInMins / 60).toFixed(5);
      break;
    default:
      throw new Error(`Unknown duration type: ${durationType}`);
  }

  return duration.toString();
}

module.exports = {
  calculateDurationFieldValue,
};
