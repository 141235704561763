import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortFeature from '@sb-itops/redux/sort';
import * as expandCollapseFeature from '@sb-itops/redux/expand-collapse';
import * as contactPaymentPlanRouteFeature from 'web/redux/route/home-billing-contact-payment-plan';
import { buildFeatureScope } from '@sb-itops/build-feature-scope';

export const contactPaymentPlanFeature =
  (state) =>
  ({ contactId }) => {
    const scope = buildFeatureScope('payment-plan', { contactId });

    return withScopedFeatures({ state, scope })({
      sort: sortFeature,
      expandableLists: expandCollapseFeature,
      selectedPaymentPlan: contactPaymentPlanRouteFeature,
    });
  };
