import { defaultPath } from './default-path';

export const INITIALISE_FORM = `${defaultPath}/INITIALISE_FORM`;
export const START_VALIDATE_FORM = `${defaultPath}/START_VALIDATE_FORM`;
export const END_VALIDATE_FORM = `${defaultPath}/END_VALIDATE_FORM`;
export const UPDATE_FIELD_VALUES = `${defaultPath}/UPDATE_FIELD_VALUES`;
export const SET_FIELD_VALUE = `${defaultPath}/SET_FIELD_VALUE`;
export const START_SUBMIT = `${defaultPath}/START_SUBMIT`;
export const END_SUBMIT = `${defaultPath}/END_SUBMIT`;
export const CLEAR_FORM = `${defaultPath}/CLEAR_FORM`;
export const RESET_FORM = `${defaultPath}/RESET_FORM`;
export const CLEAR_SUBMIT_FAILED = `${defaultPath}/CLEAR_SUBMIT_FAILED`;
