'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const staffTargetAssignmentType = Object.freeze({
  ALL: 'ALL',
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
});

const staffTargetAssignmentTypeLabels = Object.freeze({
  ALL: 'All',
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
});

const staffTargetAssignmentTypeOptions = getTypeAsOptions({
  typeLabels: staffTargetAssignmentTypeLabels,
  typeValues: staffTargetAssignmentType,
});

module.exports = {
  staffTargetAssignmentType,
  staffTargetAssignmentTypeLabels,
  staffTargetAssignmentTypeOptions,
};
