/**
 * Entry point for this file is the getInvoiceConfiguration function
 */

import { determineShowRetainerSetting } from '@sb-billing/business-logic/evergreen-retainer';
import {
  constants as invoiceSettingsConstants,
  determineFinalSectionOptions,
  determineFinalInvoiceAdditionalOptionsAndLayout,
} from '@sb-billing/business-logic/invoice-settings';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';

const generateDefaultMatterTitle = (matter) => {
  if (!matter || !matter.matterType) {
    return undefined;
  }

  return getMatterDisplay(matter, matter.matterType.name, false, false, false);
};

const generateDefaultMatterDescription = (matter) => {
  if (!matter) {
    return undefined;
  }

  return matter.description || generateDefaultMatterTitle(matter);
};

const getDefaultTitleLine1FromSetting = (settings, matter) => {
  switch (settings.titleLine1Option) {
    case invoiceSettingsConstants.titleLine1TypeById.None:
      return '';

    case invoiceSettingsConstants.titleLine1TypeById.Custom:
      return settings.titleLine1CustomText || '';

    case invoiceSettingsConstants.titleLine1TypeById.MatterDescription:
      return generateDefaultMatterDescription(matter);

    case invoiceSettingsConstants.titleLine1TypeById.MatterTitle:
    default:
      return generateDefaultMatterTitle(matter);
  }
};

const getDefaultTitleLine2FromSetting = (settings, matter) => {
  switch (settings.titleLine2Option) {
    case invoiceSettingsConstants.titleLine2TypeById.None:
      return '';

    case invoiceSettingsConstants.titleLine2TypeById.Custom:
      return settings.titleLine2CustomText || '';

    case invoiceSettingsConstants.titleLine2TypeById.MatterDescription:
      return generateDefaultMatterDescription(matter);

    case invoiceSettingsConstants.titleLine2TypeById.MatterTitle:
      return generateDefaultMatterTitle(matter);

    default:
      return '';
  }
};

/**
 * If an existing (saved) invoice is supplied, retrieve the relevant settings
 *
 * @param {Invoice} [invoice]
 * @param {MatterInvoiceSettings} [params]
 * @param {String} [params.titleLine1CustomText]
 * @param {String} [params.titleLine2CustomText]
 * @param {String} [params.footer]
 * @returns {Object}
 */
const getChangesFromStoredInvoice = (invoice, { titleLine1CustomText, titleLine2CustomText, footer }) => {
  if (!invoice) {
    return undefined;
  }

  // Existing invoice means that it is not a pre-draft
  const { invoiceTitle, invoiceTitleLine2, template, footer: footerStored } = invoice;
  const newSettings = { template };

  if (invoiceTitle !== undefined && invoiceTitle !== null && titleLine1CustomText !== invoiceTitle) {
    newSettings.titleLine1CustomText = invoiceTitle;
    newSettings.titleLine1Overridden = true;
  }

  if (invoiceTitleLine2 !== undefined && invoiceTitleLine2 !== null && titleLine2CustomText !== invoiceTitleLine2) {
    newSettings.titleLine2CustomText = invoiceTitleLine2;
    newSettings.titleLine2Overridden = true;
  }

  if (footer !== footerStored) {
    newSettings.footer = footerStored;
    newSettings.footerOverridden = true;
  }

  const additionalOptionsFromInvoice = invoice.additionalOptions;
  if (additionalOptionsFromInvoice) {
    newSettings.additionalOptionsFromInvoice = additionalOptionsFromInvoice;
  }

  const eInvoiceOptionsFromInvoice = invoice.eInvoiceOptions;
  if (eInvoiceOptionsFromInvoice) {
    newSettings.eInvoiceOptionsFromInvoice = eInvoiceOptionsFromInvoice;
  }

  const layoutFromInvoice = invoice.layout;
  if (layoutFromInvoice) {
    newSettings.layoutFromInvoice = layoutFromInvoice;
  }

  return newSettings;
};

/**
 * Get current invoice template based on the Load on Demand compatible data structures.
 *
 * Invoice options are resolved using the following precedent hierarchy in the invoice configuration:
 * Invoice level template overrides > Invoice template > Matter level overrides > Matter Template > Firm Template.
 * The form values are applied over the top of this configuration as final overrides.
 *
 * For clarity, draft invoice means that it is an existing (saved) invoice with the status of 'DRAFT'.
 * Pre-draft invoice is an invoice that has not yet been saved to an entity.
 *
 * @param {Object} params
 * @param {Invoice} [params.invoice] - Expects invoice.template to be present
 * @param {InvoiceSettingsTemplate} params.invoiceSettingsTemplateFirmDefault - Firm default invoice settings template
 * @param {Matter} params.matter - Used to derive the invoice title/subtitle in certain scenarios
 * @param {MatterInvoiceSettings} params.matterInvoiceSettings - Expects matterInvoiceSettings.template
 * @param {MatterBillingConfiguration} params.matterBillingConfiguration - Required to determine the showRetainer option
 * @param {InvoiceSettingsTemplate} [params.preferredTemplate] User-selected template, overriding the matter/firm template
 * @param {Object} [params.configOverrides] An object that contains any setting changes made by the user
 * @param {boolean} params.isEvergreenRetainerOnInvoiceEnabled
 * @param {boolean} params.isEntryLineNumbersEnabled
 * @param {boolean} params.isSupplementaryTablesPageBreakEnabled
 * @returns {InvoiceSettingsTemplate}
 */
export const getInvoiceConfiguration = ({
  invoice,
  invoiceSettingsTemplateFirmDefault,
  matter,
  matterInvoiceSettings,
  matterBillingConfiguration,
  preferredTemplate,
  configOverrides,
  isEvergreenRetainerOnInvoiceEnabled,
  isEntryLineNumbersEnabled,
  isSupplementaryTablesPageBreakEnabled,
}) => {
  let currentDraftInvoiceSettings = matterInvoiceSettings ? { ...matterInvoiceSettings } : {};

  // If an existing (saved) invoice is supplied, retrieve the relevant settings
  if (invoice) {
    currentDraftInvoiceSettings = {
      ...currentDraftInvoiceSettings,
      ...getChangesFromStoredInvoice(invoice, currentDraftInvoiceSettings),
    };
  }
  const isPreDraftInvoice = !invoice; // Equivalent of isDraftInvoice in old function

  // Form template, invoice/matter template or firm default template as fallback
  const currentTemplate =
    preferredTemplate || currentDraftInvoiceSettings.template || invoiceSettingsTemplateFirmDefault;
  const currentTemplateSettings = currentTemplate?.settings;

  // If the user has selected a template it is provided here as preferredTemplate
  if (preferredTemplate && preferredTemplate.id !== currentDraftInvoiceSettings.template?.id) {
    // If the selected template matches the matter template (or firm default fallback), use the matter one
    if (currentTemplate && currentTemplate.id === matterInvoiceSettings?.template?.id) {
      currentDraftInvoiceSettings = matterInvoiceSettings || invoiceSettingsTemplateFirmDefault?.settings;
    } else {
      currentDraftInvoiceSettings = currentTemplateSettings;
    }
  }

  if (configOverrides) {
    currentDraftInvoiceSettings = { ...currentDraftInvoiceSettings, ...configOverrides };
  }

  const {
    titleLine1Overridden,
    titleLine2Overridden,
    titleLine1Option,
    titleLine1CustomText,
    titleLine2Option,
    titleLine2CustomText,
    footer,
    footerOverridden,
  } = currentDraftInvoiceSettings || {};
  const sectionOptions = isSupplementaryTablesPageBreakEnabled
    ? determineFinalSectionOptions({
        originalInvoiceSettingsTemplate: currentTemplateSettings,
        invoiceAdditionalOptions: currentDraftInvoiceSettings.additionalOptionsFromInvoice,
        overriddenDraftInvoiceSettings: configOverrides?.invoiceAdditionalOptions,
      })
    : undefined;

  const { options: invoiceAdditionalOptions } = determineFinalInvoiceAdditionalOptionsAndLayout({
    originalInvoiceSettingsTemplate: currentTemplateSettings,
    currentDraftInvoiceSettings,
    overriddenDraftInvoiceSettings: configOverrides?.invoiceAdditionalOptions,
    sectionOptions,
    invoiceSupplementaryTablesPageBreakEnabled: isSupplementaryTablesPageBreakEnabled,
    showItemNumbersEnabled: isEntryLineNumbersEnabled,
  });

  // If the minimum trust retainer on invoices is enabled (matter setting)
  // we need to show the option and set the default value here
  if (isEvergreenRetainerOnInvoiceEnabled) {
    // We want to set to default only if it hasn't been overwritten yet
    invoiceAdditionalOptions.showRetainer = Object.hasOwn(
      configOverrides?.invoiceAdditionalOptions || {},
      'showRetainer',
    )
      ? invoiceAdditionalOptions.showRetainer
      : determineShowRetainerSetting({
          matterBillingConfiguration: matterBillingConfiguration || {},
          invoiceTemplateSettings: currentTemplateSettings,
          matterInvoiceSettings,
          invoiceVersion: invoice,
        });
  } else {
    invoiceAdditionalOptions.showRetainer = false;
  }

  // eInvoices are managed through BB-6865
  const eInvoiceOptions = {
    ...currentTemplateSettings.eInvoiceOptions,
    ...(currentDraftInvoiceSettings.eInvoiceOptionsFromInvoice || {}),
  };

  const currentSettings = {
    titleLine1Option: titleLine1Overridden ? titleLine1Option : currentTemplateSettings.titleLine1Option,
    titleLine1CustomText: titleLine1Overridden ? titleLine1CustomText : currentTemplateSettings.titleLine1CustomText,
    titleLine2Option: titleLine2Overridden ? titleLine2Option : currentTemplateSettings.titleLine2Option,
    titleLine2CustomText: titleLine2Overridden ? titleLine2CustomText : currentTemplateSettings.titleLine2CustomText,
    footer: footerOverridden ? footer : currentTemplateSettings.footer,
    template: currentTemplate,
    sectionOptions,
    invoiceAdditionalOptions,
    eInvoiceOptions,
    titleLine1Overridden,
    titleLine2Overridden,
    footerOverridden,
    paymentDueDays: currentTemplateSettings.paymentDueDays,
  };

  currentSettings.titleLine1DefaultText = getDefaultTitleLine1FromSetting(currentSettings, matter);
  currentSettings.titleLine2DefaultText = getDefaultTitleLine2FromSetting(currentSettings, matter);

  if (isPreDraftInvoice) {
    // When we have a pre-draft invoice we have to clear any previous saved
    // custom text and retain the matter settings selection.
    // this is for cases we have pre saved data
    currentSettings.titleLine1CustomText =
      titleLine1Option === invoiceSettingsConstants.titleLine1TypeById.Custom
        ? titleLine1CustomText
        : currentSettings.titleLine1DefaultText;
    currentSettings.titleLine2CustomText =
      titleLine2Option === invoiceSettingsConstants.titleLine2TypeById.Custom
        ? titleLine2CustomText
        : currentSettings.titleLine2DefaultText;
  }

  return currentSettings;
};
