import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/operating-cheques';
import { featureActive } from '@sb-itops/feature';

import { getLogger } from '@sb-itops/fe-logger';

angular
  .module('@sb-billing/services')
  .service('sbOperatingChequesService', function(sbGenericEndpointService, sbGenericCacheService, sbEndpointType) {
    const that = this;
    const log = getLogger('sbOperatingChequesService');
    const endpoint = 'billing/operating-cheques';

    if (!featureActive('BB-3331')) {
      return undefined;
    }

    sbGenericCacheService.setupCache({
      name: 'sbOperatingChequesService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
        poll: 60,
        subscriptions: 'notifier.AccountsNotifications.OperatingChequeUpdated',
      },
      updateRedux,
      clearRedux,
    });

    that.createCheque = createCheque;
    that.createChequesPdfP = createChequesPdfP;
    that.reverseChequeP = reverseChequeP;

    function createCheque(operatingCheque) {
      return sbGenericEndpointService.postPayloadP('billing/operating-cheques/create', undefined, operatingCheque)
        .catch((err) => {
          const errMsg = 'ERROR' || err || err.message;
          log.error('failed to create operating cheque', errMsg);
          throw err;
        });
    }

    function createChequesPdfP(requestId, chequeIds) {
      return sbGenericEndpointService.postPayloadP('billing/operating-cheques-pdf', undefined, { requestId, chequeIds })
        .then((resp) => {
          log.debug('sending reprint cheque request done: ', JSON.stringify(resp));
        })
        .catch((err) => {
          const errMsg = 'ERROR' || err || err.message;
          log.error('failed to reprint operating cheque', errMsg);
          throw err;
        });
    }

    function reverseChequeP (chequeIdToReverse, hideCheque, reason) {
      return sbGenericEndpointService.postPayloadP('billing/operating-cheques/reversal', chequeIdToReverse, { chequeIdToReverse, hideCheque, reason })
        .catch((err) => {
          const errMsg = 'ERROR' || err || err.message;
          log.error('failed to reverse operating cheque', errMsg);
          throw err;
        });
    }
  });
