'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/bills';

  $stateProvider
    .state('home.billing.bills', {
      url: '/bills',
      templateUrl: `${BASE_DIRECTORY}/bills.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      abstract: true,
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills']
      }
    });
});
