class RTFSpan {
  constructor(opts, parent) {
    if (!opts) opts = {};
    this.value = opts.value;
    this.style = opts.style || {};
    this.parent = parent;
  }
}

module.exports = RTFSpan;
