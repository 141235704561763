import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterTypeAccordion } from '@sb-matter-types/react/matter-type-accordion';
import { sortBy, flatten, pluck } from 'lodash';
import { featureActive, featureData } from '@sb-itops/feature';

const hooks = ({ matterTypes }) => ({
  useMatterTypeTree: () => {
    const shouldIncludeTestCategories = featureActive('BB-7838');
    const testCategories = ['QA', 'Test'];

    const matterTypeRegex = (featureActive('NUCWEB-490') && featureData('NUCWEB-490')) || '.*';

    const matterTypesByCategory = matterTypes.reduce((acc, matterType) => {
      if (!matterType) {
        return acc;
      }

      const { matterTypeId, name: matterTypeName } = matterType;

      (matterType.categories || []).forEach((category) => {
        const matterTypeIdsByMatterTypeName = acc[category] || {};
        const matterTypeIdsForMatterTypeName = matterTypeIdsByMatterTypeName[matterTypeName] || [matterTypeId];

        if (!matterTypeIdsForMatterTypeName.includes(matterTypeId)) {
          matterTypeIdsForMatterTypeName.push(matterTypeId);
        }

        matterTypeIdsByMatterTypeName[matterTypeName] = matterTypeIdsForMatterTypeName;
        if (category.match(matterTypeRegex)) {
          if (shouldIncludeTestCategories || !testCategories.includes(category)) {
            acc[category] = matterTypeIdsByMatterTypeName;
          }
        }
      });

      return acc;
    }, {});

    const matterTypeTree = sortBy(
      Object.entries(matterTypesByCategory).map(([matterTypeCategory, matterTypesByName]) => {
        const leaves = sortBy(
          Object.entries(matterTypesByName).map(([matterTypeName, matterTypeIds]) => ({
            id: `${matterTypeCategory}/${matterTypeName}`,
            display: matterTypeName,
            data: matterTypeIds,
          })),
          'display',
        );

        const matterTypeIdsForCategory = flatten(pluck(leaves, 'data'));

        return {
          id: matterTypeCategory,
          display: matterTypeCategory,
          data: matterTypeIdsForCategory,
          leaves,
        };
      }),
      'display',
    );

    return { matterTypeTree };
  },
});

export const MatterTypeSelector = composeHooks(hooks)(MatterTypeAccordion);

MatterTypeSelector.displayName = 'MatterTypeSelector';

MatterTypeSelector.propTypes = {
  scope: PropTypes.string,
  matterTypes: PropTypes.arrayOf(PropTypes.object),
  excludeUnused: PropTypes.bool,
  usedBaseMatterTypeIdsMap: PropTypes.object,
  onSelectMatterType: PropTypes.func,
  selectedMatterTypeIds: PropTypes.arrayOf(PropTypes.string),
};

MatterTypeSelector.defaultProps = {
  matterTypes: [],
  excludeUnused: true,
  usedBaseMatterTypeIdsMap: {},
  onSelectMatterType: undefined,
  scope: undefined,
  selectedMatterTypeIds: [],
};
