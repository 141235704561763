import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import PropTypes from 'prop-types';
import { getInvoiceCorrespondenceHistoryGroupedByDebtor } from '@sb-billing/redux/correspondence-history';
import { indicatorStatuses, determineIndicatorStatus } from '@sb-billing/business-logic/correspondence-history';
import { MultiContactCorrespondenceStatusIndicator } from './MultiContactCorrespondenceStatusIndicator';

const mapStateToProps = (
  state,
  { invoiceId, contactIds, onIconClicked, onResendClicked, onSendViaMenuClicked, invoiceStatus },
) => {
  const sendViaCommunicateEnabled = featureActive('BB-9097');
  const clientCommunicationEnabled = featureActive('BB-11448');
  const correspondenceHistoryByDebtor = getInvoiceCorrespondenceHistoryGroupedByDebtor(invoiceId, contactIds) || {};
  const debtorsCorrespondenceHistory = Object.values(correspondenceHistoryByDebtor);
  const indicatorStatus = determineIndicatorStatus(debtorsCorrespondenceHistory, contactIds.length);

  // attempted to send correspondence means EMAIL_ERROR, COMMUNICATE_ERROR, IN_PROGRESS, EMAIL_PARTIALLY_SENT, COMMUNICATE_PARTIALLY_SENT, EMAIL_SUCCESS, COMMUNICATE_SUCCESS
  const hasAttemptedToSendCorrespondence = indicatorStatus !== indicatorStatuses.NOT_SENT;
  const invoiceIsNotDraftOrVoid = !['DRAFT', 'VOID'].includes(invoiceStatus);

  const allowContextMenu =
    sendViaCommunicateEnabled || clientCommunicationEnabled
      ? invoiceIsNotDraftOrVoid || hasAttemptedToSendCorrespondence // context menu is used to show both buttons and also status popup
      : hasAttemptedToSendCorrespondence; // context menu is used to show status popup only

  return {
    invoiceId,
    indicatorStatus,
    invoiceIsNotDraftOrVoid,
    allowContextMenu,
    onIconClicked: () => onIconClicked({ invoiceId, contactIds, indicatorStatus }),
    allowResend: !['DRAFT', 'VOID', 'DELETED'].includes(invoiceStatus),
    onResendClicked: ({ contactId, sentVia }) => onResendClicked({ invoiceId, contactId, sentVia }),
    onSendViaMenuClicked: ({ sendVia }) => onSendViaMenuClicked({ invoiceId, sendVia }),
  };
};

/**
 * @deprecated This container has been deprecated, for LOD use the InvoiceCorrespondenceSendStatus directly
 * Remove after BB-13599, BB-14220, BB-14222 and BB-14223 are released
 */
export const MultiContactCorrespondenceStatusIndicatorContainer = withReduxStore(
  connect(mapStateToProps)(MultiContactCorrespondenceStatusIndicator),
);

MultiContactCorrespondenceStatusIndicatorContainer.displayName = 'MultiContactCorrespondenceStatusIndicatorContainer';

MultiContactCorrespondenceStatusIndicatorContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  contactIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onIconClicked: PropTypes.func,
  onResendClicked: PropTypes.func,
  onSendViaMenuClicked: PropTypes.func,
};

MultiContactCorrespondenceStatusIndicatorContainer.defaultProps = {
  onIconClicked: () => {},
  onResendClicked: () => {},
  onSendViaMenuClicked: () => {},
};

export default MultiContactCorrespondenceStatusIndicatorContainer;
