'use strict';

const decodeBase64 = (str) =>
  typeof process !== 'undefined' && process.release && process.release.name === 'node'
    ? Buffer.from(str, 'base64').toString('binary')
    : window.atob(str); // eslint-disable-line no-undef

const parseJwt = ({ jwt }) => {
  const rawToken = jwt.split('.')[1];

  let token = decodeURIComponent(rawToken);

  if (token.length % 4 === 3) {
    token += '=';
  }

  if (token.length % 4 === 2) {
    token += '==';
  }

  return JSON.parse(decodeBase64(token));
};

module.exports = {
  parseJwt,
};
