import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import Styles from './ContactPaymentPlanFilters.module.scss';

const destDateFormat = 'DD MMMM YYYY';
const originalDateFormat = 'YYYYMMDD';

const isPaymentPlanSelected = (selectedPaymentPlanId, currentPaymentPlanId) =>
  selectedPaymentPlanId === currentPaymentPlanId;

const ContactPaymentPlanFilters = memo(
  ({ selectedPaymentPlanId, pastPaymentPlans, activePaymentPlanId, onFilterChanged }) => (
    <div className="side-menu-block">
      {activePaymentPlanId && (
        <ul className="side-menu-list">
          <h3 className={Styles.filterTitle}>view</h3>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li
            className={classnames(
              isPaymentPlanSelected(selectedPaymentPlanId, activePaymentPlanId) && 'active',
              Styles.filterOption,
            )}
            onClick={() => onFilterChanged(activePaymentPlanId)}
          >
            Current plan
          </li>
        </ul>
      )}
      <ul className="side-menu-list">
        {pastPaymentPlans.length > 0 && <h3 className={Styles.filterTitle}>past plans</h3>}
        {pastPaymentPlans.map(({ id, startDate, endDate }) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            className={classnames(isPaymentPlanSelected(selectedPaymentPlanId, id) && 'active', Styles.filterOption)}
            key={id}
            onClick={() => onFilterChanged(id)}
          >
            {`${moment(startDate, originalDateFormat).format(destDateFormat)} - ${moment(
              startDate > endDate ? startDate : endDate,
              originalDateFormat,
            ).format(destDateFormat)}`}
          </li>
        ))}
      </ul>
    </div>
  ),
);

ContactPaymentPlanFilters.propTypes = {
  selectedPaymentPlanId: PropTypes.string,
  pastPaymentPlans: PropTypes.array,
  activePaymentPlanId: PropTypes.string,
  onFilterChanged: PropTypes.func.isRequired,
};

ContactPaymentPlanFilters.defaultProps = {
  selectedPaymentPlanId: undefined,
  pastPaymentPlans: [],
  activePaymentPlanId: undefined,
};

ContactPaymentPlanFilters.displayName = 'ContactPaymentPlanFilters';

export default ContactPaymentPlanFilters;
