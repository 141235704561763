import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Typeahead, FloatingCard, forms2PropTypes, FormLabel, SlidingToggle, useTranslation } from '@sb-itops/react';

import Styles from '../FeeWiseApplicationForm.module.scss';

export const OperatingAccountDetails = ({
  operatingAccountDetails,
  formInitialised,
  accountTypeOptions,
  submitFailed,
  formDisabled,
  onFieldUpdated,
  onToggleField,
  sectionRef,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

  return (
    <FloatingCard applyMargin={false} classname={Styles.marginBottom} ref={sectionRef}>
      <div className={Styles.sectionTitle}>Operating Account</div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Holder Name" />
          <input
            type="text"
            value={operatingAccountDetails?.accountHolderName?.value}
            className={classnames('form-control', isErrorClassnames(operatingAccountDetails?.accountHolderName))}
            onChange={(e) => onFieldUpdated({ 'operatingAccountDetails.accountHolderName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Name" />
          <input
            type="text"
            value={operatingAccountDetails?.accountName?.value}
            className={classnames('form-control', isErrorClassnames(operatingAccountDetails?.accountName))}
            onChange={(e) => onFieldUpdated({ 'operatingAccountDetails.accountName': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label={t('bankBranchNumber')} />
          <input
            type="text"
            value={operatingAccountDetails?.routingNumber?.value}
            className={classnames('form-control', isErrorClassnames(operatingAccountDetails?.routingNumber))}
            onChange={(e) => onFieldUpdated({ 'operatingAccountDetails.routingNumber': e.target.value })}
            disabled={formDisabled}
          />
          {!!isErrorClassnames(operatingAccountDetails?.routingNumber) &&
            operatingAccountDetails?.routingNumber?.invalidReason.includes('Must be exactly') && (
              <span className={Styles.errorMsg}>{operatingAccountDetails?.routingNumber?.invalidReason}</span>
            )}
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Number" />
          <input
            type="text"
            value={operatingAccountDetails?.accountNumber?.value}
            className={classnames('form-control', isErrorClassnames(operatingAccountDetails?.accountNumber))}
            onChange={(e) => onFieldUpdated({ 'operatingAccountDetails.accountNumber': e.target.value })}
            disabled={formDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames('form-group', 'col-sm-6')}>
          <FormLabel uppercase={false} label="Account Type" />
          <Typeahead
            selectedOption={accountTypeOptions.find(
              (option) => option.value === operatingAccountDetails?.accountType?.value,
            )}
            options={accountTypeOptions}
            disabled={formDisabled}
            placeholder="Select..."
            hasError={!!isErrorClassnames(operatingAccountDetails?.accountType)}
            onSelect={(option) => {
              onFieldUpdated({ 'operatingAccountDetails.accountType': option?.value || undefined });
            }}
          />
        </div>
        <div className={classnames('form-group', 'col-sm-6')}>
          <div className={Styles.toggleContainer}>
            <SlidingToggle
              id="nominateForMonthlyBilling"
              scope="nominateForMonthlyBilling"
              name="nominateForMonthlyBilling"
              onChange={() => onToggleField({ field: 'operatingAccountDetails.nominateForMonthlyBilling' })}
              selected={operatingAccountDetails?.nominateForMonthlyBilling?.value}
              disabled={formDisabled}
            />
            <span
              className={Styles.labelText}
              htmlFor="nominateForMonthlyBilling"
              onClick={() => onToggleField({ field: 'operatingAccountDetails.nominateForMonthlyBilling' })}
            >
              Nominate this account for monthly billing (e.g. for fees)
            </span>
          </div>
        </div>
      </div>
    </FloatingCard>
  );
};

OperatingAccountDetails.displayName = 'OperatingAccountDetails';

const { Forms2Field } = forms2PropTypes;

OperatingAccountDetails.propTypes = {
  operatingAccountDetails: PropTypes.shape({
    accountHolderName: Forms2Field,
    accountName: Forms2Field,
    routingNumber: Forms2Field,
    accountNumber: Forms2Field,
    accountType: Forms2Field,
    nominateForMonthlyBilling: Forms2Field,
  }),
  accountTypeOptions: PropTypes.array.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  onFieldUpdated: PropTypes.func.isRequired,
  onToggleField: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

OperatingAccountDetails.defaultProps = {
  formDisabled: false,
  operatingAccountDetails: {},
};
