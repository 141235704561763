import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import PropTypes from 'prop-types';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { AddEditCustomTaskCodeSchema } from './AddEditCustomTaskCodeSchema';
import { AddEditCustomTaskCode } from './AddEditCustomTaskCode';

const hooks = (props) => ({
  useDeleteModal: () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    return {
      showDeleteModal,
      setShowDeleteModal,
    };
  },
  useForm: () => {
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, 'add-edit-custom-task-code-form');

    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
      formDirty,
    } = useSelector(formSelectors.getFormState);

    const { id, code, description, entryType, isBillable } = formFields;

    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState(false);

    if (!formInitialised && props.currentCustomTaskCode && props.currentCustomTaskCode.id) {
      // edit mode form initialisation
      const currentCustomTaskCode = props.currentCustomTaskCode;
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            id: currentCustomTaskCode.id,
            code: currentCustomTaskCode.code,
            description: currentCustomTaskCode.description,
            entryType: currentCustomTaskCode.entryType,
            isBillable: currentCustomTaskCode.isBillable,
          },
        }),
      );
    }

    useEffect(() => {
      // add mode form initialisation
      if (!props.customTaskCodeId) {
        dispatch(
          formActions.initialiseForm({
            fieldValues: {
              id: undefined,
              code: '',
              description: '',
              entryType: undefined,
              isBillable: true,
            },
          }),
        );
      }
      const onUnload = () => {
        dispatch(formActions.clearForm());
      };
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateForm({ schema: AddEditCustomTaskCodeSchema }));
    };

    const onDelete = async (codeId) => {
      try {
        setIsDeleting(true);
        await dispatchCommand({
          type: 'Billing.Activities.Messages.Commands.DeleteCustomTaskCode',
          message: {
            id: codeId,
          },
        });

        props.onClose();
        await dispatch(formActions.clearForm());
        setIsDeleting(false);
        messageDisplay.success('Custom task code has been deleted');
      } catch (err) {
        setIsDeleting(false);
        messageDisplay.error('Failed to delete custom task code');
      }
    };

    const onSave = async () => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formData) => {
              const customTaskCode = props.customTaskCodeId ? props.currentCustomTaskCode : undefined;
              const saveCustomTaskCodeMessage = {
                customTaskCode: {
                  id: customTaskCode?.id || uuid(),
                  code: formData.code.trim(),
                  description: formData.description.trim(),
                  entryType: formData.entryType,
                  isBillable: formData.isBillable,
                },
              };

              await dispatchCommand({
                type: 'Billing.Activities.Messages.Commands.SaveCustomTaskCode',
                message: saveCustomTaskCodeMessage,
              });

              props.onClose();
              await dispatch(formActions.clearForm());
              messageDisplay.success('Custom task code has been saved');
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to save custom task code');
      }
    };

    return {
      // form fields
      id,
      code,
      description,
      entryType,
      isBillable,
      // form state
      formDirty,
      formInitialised,
      formSubmitting,
      submitFailed,
      // func & callbacks
      onClose: props.onClose,
      onFieldValueUpdated,
      onDelete,
      onSave,
      // state
      isDeleting,
    };
  },
});

export const AddEditCustomTaskCodeContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(AddEditCustomTaskCode)),
);

AddEditCustomTaskCodeContainer.displayName = 'AddEditCustomTaskCodeContainer';

AddEditCustomTaskCodeContainer.propTypes = {
  currentCustomTaskCode: PropTypes.object,
  customTaskCodeId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

AddEditCustomTaskCodeContainer.defaultProps = {
  currentCustomTaskCode: undefined,
  customTaskCodeId: undefined,
};
