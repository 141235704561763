'use strict';
import { sort as sortItems } from '@sb-itops/sort';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';

// Legacy behaviour was to only support split payments (1 payor many invoices) by a showing a list of affected invoiceids. 
// Feature BB-580 introduced split payors (1 invoice many payors). Showing a list of affected invoices no longer makes 
// sense, so we will know display summary data of the affected transactions:
//
// e.g.
// Invoice  Paid by     Source    Amount
// #123     Sam Smith   Trust     $100.00

angular.module('sb.billing.webapp').component('sbSplitPaymentReversalWarning', {
  bindings: {
    paymentId: '<',
    hideTable: '<',
    onInvoiceClick: '&'
  },
  templateUrl: 'ng-components/split-payment-reversal-warning/split-payment-reversal-warning.html',
  controller: function ($scope, sbLoggerService, sbPaymentService, sbGuidToLinkService, sbVendorPaymentsService, sbLocalisationService) {
    const destroyables = new Set();
    const ctrl = this;
    ctrl.t = sbLocalisationService.t;
    const log = sbLoggerService.getLogger('sbSplitPaymentReversalWarning');

    // log.setLogLevel('info');

    ctrl.$onDestroy = () => destroyables.forEach(destroy => destroy());
    ctrl.isSplitPayment = isSplitPayment;
    ctrl.onInvoiceClick = ctrl.onInvoiceClick || _.noop;
    ctrl.isMatterContactBalanceType = isMatterContactBalanceType();

    destroyables.add($scope.$watch(() => _.get(ctrl, 'paymentId'), updatePayment));
    destroyables.add($scope.$on('smokeball-data-update-sbPaymentService', updatePayment));

    function updatePayment() {
      ctrl.payment = sbPaymentService.getPaymentsById(ctrl.paymentId) || sbVendorPaymentsService.getById(ctrl.paymentId);
      ctrl.reversals = getPaymentReversals();

      if (ctrl.payment && ctrl.payment.isTrustToOffice && ctrl.reversals.length) { // single invoice payments an empty reversals array, and the message is not relevant
        const trustToOfficeLabel = ctrl.t('trustToOfficeTransferLabel');
        const reversalAmount = ctrl.t('cents', { val: ctrl.reversals.reduce((acc, reversal) => acc + reversal.amount, 0)});
        ctrl.warningMessage = `Warning: This action will reverse all transactions that are a part of the same ${trustToOfficeLabel}. The total amount to be reversed is ${reversalAmount}.`;
      } else if (isSplitPayment()) {
        ctrl.warningMessage = 'Warning: this action will reverse the following payments made as part of the same transaction.';
      }
      log.info(`payment ${ctrl.paymentId}, reversals`, ctrl.reversals);
    }

    function getPaymentReversals() {
      if (!isSplitPayment()) {
        return [];
      }

      const multiPaymentId = ctrl.payment.multiPaymentId;
      const reversals = multiPaymentId ? getMultiPaymentReversals(multiPaymentId) : getSplitPaymentReversals(multiPaymentId);
      
      return reversals;
    }

    function getSplitPaymentReversals() {
      return ctrl.payment.invoices.map(i => ({
        invoice : sbGuidToLinkService.getUpdateData(`invoiceId:${i.invoiceId}`),
        payor : sbGuidToLinkService.getUpdateData(`contactId:${ctrl.payment.payorId}`),
        source : sbPaymentService.decodePaymentSource(ctrl.payment),
        amount : i.amount,
        id : i.invoiceId
      }));
    }
    
    function getMultiPaymentReversals(multiPaymentId) {
      let payments = sbPaymentService.getPaymentsByFilter(p => p.multiPaymentId === multiPaymentId) || [];

      if (payments.length === 0) {
        payments = sbVendorPaymentsService.getByFilter(p => p.multiPaymentId === multiPaymentId) || [];
        ctrl.vendorPayment = true;
      }

      const paymentInvoices = payments.reduce((acc, payment) => {
        if (payment.invoices) {
          payment.invoices.forEach(invoice => {
            const invoiceNumber = getInvoiceNumberById(invoice.invoiceId);

            acc.push({
              invoice : sbGuidToLinkService.getUpdateData(`invoiceId:${invoice.invoiceId}`),
              payor : sbGuidToLinkService.getUpdateData(`contactId:${payment.payorId}`),
              source : sbPaymentService.decodePaymentSource(payment),
              amount : invoice.amount,
              id : invoice.invoiceId,
              invoiceNumber,
            })
          });
        } else {
          acc.push({
            invoice : payment.invoices && sbGuidToLinkService.getUpdateData(`invoiceId:${payment.invoices[0].invoiceId}`),
            payor : sbGuidToLinkService.getUpdateData(`contactId:${payment.payorId}`),
            source : sbPaymentService.decodePaymentSource(payment),
            amount : payment.totalAmount !== undefined ? payment.totalAmount : payment.amount,
            id : payment.paymentId
          });
        }

        return acc;
      }, []);

      return sortItems(paymentInvoices, ['invoiceNumber'], ['ASC']);
    }

    // split payments come in two forms:
    // a payment with a multiPaymentId (if the payment is a part of a client-based split payment)
    // a payment with multiple invoices.
    function isSplitPayment() {
      return _.size(_.get(ctrl, 'payment.invoices')) > 1 || !!_.get(ctrl, 'payment.multiPaymentId');
    }
  },
});