import { featureActive } from '@sb-itops/feature';

angular.module('sb.billing.webapp').component('sbTrustToOffice', {
  templateUrl: 'ng-routes/home/billing/bills/trust-to-office/trust-to-office.html',
  controller: function($scope, $location, sbLinkService, sbLocalisationService, sbExpenseService) {
    const ctrl = this;

    // We cannot disable the LOD TTO list feature if the expense cache is disabled
    // as it will cause the Unpaid AD functionality to break. If the TTO list
    // needs to be disabled, we need to make sure BB-14929 is disabled as well.
    ctrl.disableLODTTOList = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-14037');

    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.t = sbLocalisationService.t;
    // this is to pass through query strings, which can be used to help remember filter selection
    $scope.$on('$locationChangeSuccess', () => {
      ctrl.sbQuery = _.cloneDeep($location.search());
    });
  }
});
