angular.module('sb.billing.webapp').component('sbGenerateReportBox', {
  bindings: {operation: '<', dismissFn: '&?'},
  templateUrl: 'ng-components/async-operation-bar/generate-report-box/generate-report-box.html',
  controller: function($state, sbMessageDisplayService, FileSaver) {
    const ctrl = this;
    ctrl.onClick = onClick;

    ctrl.$onInit = () => {
      ctrl.dismissFn = _.isFunction(ctrl.dismissFn) ? ctrl.dismissFn : _.noop;
    };

    function onClick() {
      if (!ctrl.operation.data.isComplete) {
        return;
      }

      if (ctrl.operation.data.error) {
        sbMessageDisplayService.error('Failed to generate report file');
      }
      else {
        FileSaver.saveAs(ctrl.operation.data.fileData, `${moment().utc().format('YYYY-MM-DD')}-${ctrl.operation.data.label.replace(" ", "-").toLowerCase()}.${ctrl.operation.data.reportFormat}`);
      }

      ctrl.dismissFn(ctrl.operation);
    }
  }
});
