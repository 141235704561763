angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.data-backup', {
      template: '<sbb-data-backup></sbb-data-backup>',
      url: '/data-backup',
      data: {
        navHighlights: ['data-backup']
      }
    });
});
