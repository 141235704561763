'use strict';

const yupSchemaValidator = (objToValidate, schema, context = {}) => {
  try {
    schema.validateSync(objToValidate, { abortEarly: false, context });
    return {};
  } catch (err) {
    // This is what happens when you use exceptions for non-exceptional conditions.
    // Dammit yup, you're better than this.
    if (err.name !== 'ValidationError') {
      throw err;
    }

    const errors = err.inner.reduce((acc, validationError) => {
      acc[validationError.path] = validationError.message;
      return acc;
    }, {});

    return errors;
  }
};

module.exports = {
  yupSchemaValidator,
};
