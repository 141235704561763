'use strict';

const getUniqueDebtorIdsForInvoices = ({ invoiceIds = [], getInvoiceById }) => {
  const uniqueDebtorIds = invoiceIds.reduce((acc, invoiceId) => {
    const invoice = getInvoiceById(invoiceId);
    const debtors = (invoice && invoice.debtors) || (invoice.debtorId && [{ id: invoice.debtorId }]) || [];
    debtors
      .filter((debtor) => debtor.id)
      .map(({ id }) => id)
      .forEach(acc.add.bind(acc));

    return acc;
  }, new Set());

  return [...uniqueDebtorIds];
};

module.exports = {
  getUniqueDebtorIdsForInvoices,
};
