import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { InitStaffSettings, CalendarEventGroupsData } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import uuid from '@sb-itops/uuid';
import { getMatterTypeaheadSummaries } from 'web/redux/selectors/typeahead';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import moment from 'moment';
import { sortByProperty } from '@sb-itops/nodash';
import { getUniqueColors } from 'web/business-logic/calendar';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';

const hooks = ({ scope }) => ({
  useModalState: () => {
    const [modalOpen, setModalOpen] = useState(false);
    return {
      modalOpen,
      onCloseModal: () => {
        setModalOpen(false);
      },
      onOpenModal: setModalOpen,
    };
  },
  useGraphQL: () => {
    const { t } = useTranslation();
    const { data: staffData, staffDataLoading } = useCacheQuery(InitStaffSettings.query);
    const { data: eventGroupData, loading: groupDataLoading } = useSubscribedQuery(CalendarEventGroupsData);
    const filtersLoading = groupDataLoading || staffDataLoading;

    const staffMembers = getUniqueColors(staffData?.staffMembers || []);

    const entityMap = staffMembers.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
    const eventGroups = (eventGroupData?.eventGroups || []).filter((group) => !group.isRemoved);
    eventGroups.forEach((group) => {
      entityMap[group.id] = group;
    });

    const matterSummaries = getMatterTypeaheadSummaries(true);

    const currentUsers = sortByProperty(
      staffMembers.filter((s) => !s.isFormerStaff),
      'name',
    );
    const formerUsers = sortByProperty(
      staffMembers.filter((s) => s.isFormerStaff),
      'name',
    );

    const attendees = sortByProperty([...currentUsers, ...(eventGroups || [])], 'name');

    return {
      scope,
      eventGroups,
      filtersLoading,
      attendees,
      matterSummaries,
      currentUsers,
      formerUsers,
      loggedInStaff: staffData?.loggedInStaff,
      onDeleteAppointment: async ({ appointmentData }) => {
        const marshalledData = {
          id: appointmentData.id,
          versionId: uuid(),
          machineName: navigator.userAgent,
        };

        await dispatchCommand({
          type: 'Calendaring.ManageCalendars.Messages.RemoveAppointment',
          message: marshalledData,
        });
      },
      onSaveAppointment: async ({ appointmentData }) => {
        const marshalledData = {
          staffId: getLoggedInStaff()?.id,
          location: '',
          machineName: navigator.userAgent,
          attendeeEntities: [],
          ...appointmentData,
          originalStartTime: appointmentData.originalStartTime
            ? moment(appointmentData.originalStartTime).toISOString()
            : '',
          originalEndTime: appointmentData.originalEndTime ? moment(appointmentData.originalEndTime).toISOString() : '',
          startTime: moment(appointmentData.startTime).toISOString(),
          endTime: moment(appointmentData.endTime).toISOString(),
          id: appointmentData.id || uuid(),
        };

        marshalledData.versionId = uuid();
        // Data is not safe to send
        delete marshalledData.lastUpdated;
        delete marshalledData.accountId;
        delete marshalledData.userId;

        if ((marshalledData.rRULE || '').includes('RRULE:')) {
          marshalledData.rRULE = marshalledData.rRULE.split('RRULE:')[1];
        }

        marshalledData.timeZoneId = t('timezone');

        await dispatchCommand({
          type: 'Calendaring.ManageCalendars.Messages.SaveAppointment',
          message: marshalledData,
        });
      },
    };
  },
});

export const CalendarModalContainer = (WrappedCalendarRoute) => {
  const WrappedComponent = withApolloClient(withReduxProvider(composeHooks(hooks)(WrappedCalendarRoute)));
  WrappedComponent.displayName = 'CalendarModalContainer';

  WrappedComponent.propTypes = {
    scope: PropTypes.string.isRequired,
    matterId: PropTypes.string,
    onClickLink: PropTypes.func,
  };

  WrappedComponent.defaultProps = {
    matterId: undefined,
    onClickLink: () => {},
  };
  return WrappedComponent;
};
