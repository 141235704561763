import { cacheFactory, syncTypes } from '@sb-itops/redux';
import domain from '../domain';

export const { UPDATE_CACHE, getById, getMap, getList, updateCache } = cacheFactory({
  domain,
  name: 'auto-number-definition',
  keyPath: 'accountId',
  ngCacheName: 'sbMatterAutoNumberDefinitionService',
  syncType: syncTypes.ALL,
});
