import React, { memo, useCallback } from 'react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ClickableIcon } from '@sb-itops/react/clickable-icon';
import { Checkbox } from '@sb-itops/react/checkbox';
import PropTypes from 'prop-types';

const { yyyymmddLocalisedRenderer, amountCellLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

const AdjustmentsTable = memo((props) => {
  const { adjustments, onToggleReconcile, onToggleDelete } = props;

  const footerRenderer = useCallback(() => {}, []);

  const removeButtonRenderer = useCallback(
    ({ rowData }) => {
      if (rowData.isNew) {
        if (rowData.isDeleted) {
          return (
            <div role="button" tabIndex={0} className="undo-link" onClick={() => onToggleDelete(rowData.id)}>
              undo
            </div>
          );
        }
        return <ClickableIcon className="icon-bin-2" onClick={() => onToggleDelete(rowData.id)} />;
      }
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    },
    [onToggleDelete],
  );

  const checkboxRenderer = ({ rowData }) =>
    !rowData.isNew && <Checkbox checked={rowData.isReconciled} onChange={() => onToggleReconcile(rowData.id)} />;

  const dateRenderer = ({ cellData, rowData }) => (
    <div className={rowData.isDeleted ? 'deleted' : ''}>{yyyymmddLocalisedRenderer({ cellData })}</div>
  );

  const descriptionRenderer = ({ cellData, rowData }) => (
    <span title={cellData} className={rowData.isDeleted ? 'deleted' : ''}>
      {cellData}
    </span>
  );

  const depositRenderer = ({ cellData, rowData }) =>
    cellData > 0 && (
      <div className={rowData.isDeleted ? 'deleted' : ''}>{amountCellLocalisedRenderer({ cellData })}</div>
    );

  const withdrawalRenderer = ({ cellData, rowData }) =>
    cellData < 0 && (
      <div className={rowData.isDeleted ? 'deleted' : ''}>{amountCellLocalisedRenderer({ cellData })}</div>
    );

  return (
    <Table
      list={adjustments}
      // showFooter={!!summary}
      footerRenderer={footerRenderer}
      className="adjustments-table"
    >
      <Column
        className="reconcile-checkbox"
        key="selected"
        dataKey="selected"
        label="Reconcile"
        width={75}
        cellRenderer={checkboxCellWrapperRenderer(checkboxRenderer)}
        disableSort
      />
      <Column className="" key="date" dataKey="date" label="Date" flexGrow={2} cellRenderer={dateRenderer} />
      <Column
        className=""
        key="description"
        dataKey="description"
        label="Reason"
        flexGrow={4}
        disableSort
        cellRenderer={descriptionRenderer}
      />
      <Column
        className="adjustment-number"
        key="debit"
        dataKey="amount"
        label="Withdrawal"
        flexGrow={1}
        width={100}
        cellRenderer={withdrawalRenderer}
        disableSort
      />
      <Column
        className="adjustment-number"
        key="credit"
        dataKey="amount"
        label="Deposit"
        flexGrow={1}
        width={100}
        cellRenderer={depositRenderer}
        disableSort
      />
      <Column
        className=""
        key="removeButton"
        dataKey="removeButton"
        flexGrow={0.5}
        cellRenderer={removeButtonRenderer}
        disableSort
      />
    </Table>
  );
});

AdjustmentsTable.displayName = 'AdjustmentsTable';

AdjustmentsTable.propTypes = {
  adjustments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isReconciled: PropTypes.bool.isRequired,
      date: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      debit: PropTypes.number,
      credit: PropTypes.number,
      isDeleted: PropTypes.bool,
      isNew: PropTypes.bool.isRequired,
    }),
  ),
  onToggleReconcile: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
};

AdjustmentsTable.defaultProps = {
  adjustments: [],
};

export default AdjustmentsTable;
