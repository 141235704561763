import { isVoidable } from '@sb-billing/business-logic/invoice/services';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';

angular.module('sb.billing.webapp').directive('sbActionVoidInvoice', function (sbMessageDisplayService, sbLoggerService, sbVoidInvoiceCommand) {
  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      var invoice;
      var log = sbLoggerService.getLogger('sbActionVoidInvoice');

      ctrl.group.onInvoiceData(function (data) { invoice = data; });
      ctrl.group.setAction(voidInvoice, () => !!invoice && isVoidable(getInvoiceById(invoice.invoiceId), getTotalsForInvoiceId(invoice.invoiceId)));

      function voidInvoice() {
        if (invoice) {
          ctrl.group.setLock(true);
          return sbVoidInvoiceCommand.executeP(invoice.invoiceId, invoice.matterId)
            .then(function () {
              ctrl.group.setLock();
            })
            .catch(function (err) {
              sbMessageDisplayService.error(
                sbMessageDisplayService
                  .builder()
                  .text('Failed to void invoice')
                  .conditionalText(' #{0}', invoice.invoiceNumber)
                  .text('. Please check your connection and try again.')
              );

              log.error('Failed to void invoice', err);
              ctrl.group.setLock();
            });
        } else {
          throw new Error('no invoice found to void');
        }
      }
    }
  };
});
