import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ContextMenu } from '@sb-itops/react/context-menu';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer, timestampLocalisedRenderer } = utils;

class DepositSlipsTable extends React.Component {
  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
    this.contextMenuCellRenderer = this.contextMenuCellRenderer.bind(this);
    this.idRenderer = this.idRenderer.bind(this);
  }

  onRowClick({ rowData }) {
    this.props.onClickLink({ type: 'depositSlip', id: rowData.id });
  }

  contextMenuCellRenderer({ rowData }) {
    const { reconciliationId } = rowData;

    return (
      <ContextMenu
        // eslint-disable-next-line react/no-unstable-nested-components
        body={() => (
          <div className="context-menu-body list-group">
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                this.onRowClick({ rowData });
              }}
            >
              View PDF
            </button>
            {!reconciliationId && (
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  this.props.onEditSlip(rowData);
                }}
              >
                Edit Deposit Slip
              </button>
            )}
            {!reconciliationId && (
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  this.props.onDeleteSlip({ depositId: rowData.id });
                }}
              >
                Delete Deposit Slip
              </button>
            )}
          </div>
        )}
      >
        <div className="context-menu-cell">...</div>
      </ContextMenu>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  idRenderer({ cellData }) {
    return cellData || '##';
  }

  render() {
    const { depositSlips, sort, sortBy, sortDirection } = this.props;

    return (
      <Table onRowClick={this.onRowClick} list={depositSlips} sort={sort} sortBy={sortBy} sortDirection={sortDirection}>
        <Column dataKey="depositSlipNumber" label="Id" cellRenderer={this.idRenderer} disableSort width={40} />
        <Column dataKey="depositDate" label="Transaction Date" cellRenderer={yyyymmddLocalisedRenderer} width={130} />
        <Column dataKey="timestamp" label="Entered Date" cellRenderer={timestampLocalisedRenderer} width={100} />
        <Column dataKey="reference" label="Reference" flexGrow={12} />
        <Column
          dataKey="totalDepositSlip"
          className="right-align"
          label="Amount"
          flexGrow={2}
          width={100}
          cellRenderer={balanceCellLocalisedRenderer}
        />
        <Column dataKey="" label="" cellRenderer={this.contextMenuCellRenderer} width={40} disableSort />
      </Table>
    );
  }
}

DepositSlipsTable.propTypes = {
  /**
   * Data to be rendered in the table
   */
  depositSlips: PropTypes.arrayOf(
    PropTypes.shape({
      depositSlipNumber: PropTypes.string,
      depositDate: PropTypes.number.isRequired,
      reference: PropTypes.string,
      totalDepositSlip: PropTypes.number.isRequired,
      reconciliationId: PropTypes.string,
    }),
  ).isRequired,

  /**
   * The callback to be invoked when user clicks on sort
   */
  sort: PropTypes.func.isRequired,

  /**
   * The column to be sorted on.
   */
  sortBy: PropTypes.oneOf(['depositDate', 'timestamp', 'reference', 'totalDepositSlip']),

  /**
   * The sort direction to be considered when sorting the column
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc']),

  /**
   * The callback to be invoked when user clicks on the row
   */
  onClickLink: PropTypes.func.isRequired,

  /**
   * The callback to be invoked when user clicks on the EditSlip link in the context menu
   */
  onEditSlip: PropTypes.func.isRequired,

  /**
   * The callback to be invoked when user clicks on the DeleteSlip link in the context menu
   */
  onDeleteSlip: PropTypes.func.isRequired,
};

DepositSlipsTable.defaultProps = {
  sortBy: 'depositDate',
  sortDirection: 'desc',
};

DepositSlipsTable.displayName = 'DepositSlipsTable';

export default DepositSlipsTable;
