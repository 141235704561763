import * as types from './types';

// sets up the relationships in each group consisting of parent -> child
// parentId to child ids is an object keyed by parent id. The values of parentIdToChildIds is an array of child ids.
export const initialise = ({ parentIdToChildIds, selectedByDefault = false } = {}) => ({
  type: types.INITIALISE,
  payload: {
    parentIdToChildIds,
    selectedByDefault,
  },
});

// selects everything
export const selectAll = () => ({
  type: types.SELECT_ALL,
  payload: {},
});

// deselects everything
export const deselectAll = () => ({
  type: types.DESELECT_ALL,
  payload: {},
});

// flips whether the root is selected. new flipped state is applied to every parent and child.
export const toggleSelectAll = () => ({
  type: types.TOGGLE_SELECT_ALL,
  payload: {},
});

// selects parent and all of its children
export const selectParent = ({ parentId }) => ({
  type: types.SELECT_PARENT,
  payload: { parentId },
});

// deselects parent and all of its children
export const deselectParent = ({ parentId }) => ({
  type: types.DESELECT_PARENT,
  payload: { parentId },
});

// // flips whether the parent is selected. new flipped state is applied to every child
export const toggleSelectParent = ({ parentId }) => ({
  type: types.TOGGLE_SELECT_PARENT,
  payload: { parentId },
});

// selects a child
export const selectChild = ({ childId }) => ({
  type: types.SELECT_CHILD,
  payload: { childId },
});

// deselects a child
export const deselectChild = ({ childId }) => ({
  type: types.DESELECT_CHILD,
  payload: { childId },
});

// flips whether a child is selected
export const toggleSelectChild = ({ childId }) => ({
  type: types.TOGGLE_SELECT_CHILD,
  payload: { childId },
});

// sets state back to initial state
export const reset = () => ({
  type: types.RESET,
  payload: {},
});
