import React from 'react';
import PropTypes from 'prop-types';
import RemindersHideDebtorFilter from './RemindersHideDebtorFilter';

class RemindersHideDebtorFilterBridge extends React.PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    reset: this.props.reset,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.reset !== prevState.reset && nextProps.reset === true) {
      return { reset: true };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.reset === true) {
      this.resetState();
    }
  }

  resetState() {
    this.setState({ reset: false }, () => {
      this.filterRef.current.reset();
      if (this.props.onResetApplied) {
        this.props.onResetApplied();
      }
    });
  }

  filterRef = React.createRef();

  render() {
    return <RemindersHideDebtorFilter ref={this.filterRef} {...this.props} />;
  }
}

RemindersHideDebtorFilterBridge.propTypes = {
  reset: PropTypes.bool,
  onResetApplied: PropTypes.func,
  onFilterChanged: PropTypes.func.isRequired,
  remindedInDays: PropTypes.number,
  invoicedInDays: PropTypes.number,
  setFilter: PropTypes.func.isRequired,
};

RemindersHideDebtorFilterBridge.defaultProps = {
  reset: false,
  onResetApplied: undefined,
  remindedInDays: undefined,
  invoicedInDays: undefined,
};

export default RemindersHideDebtorFilterBridge;
