import * as React from 'react';

import { search } from '@sb-itops/services/src/generic-search';
import { MatterDisplay } from '@sb-matter-management/react';
import { ContactDisplay } from '@sb-customer-management/react';
import { getContactStatusLabelById } from '@sb-customer-management/redux/contacts-summary';
import { StatusPill } from '@sb-itops/react';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import {
  getMatterTypeaheadSummaries,
  getInvoiceTypeaheadSummaries,
  getContactTypeAheadSummaries,
} from 'web/redux/selectors/typeahead';
import { hasBillingAccess } from 'web/services/user-session-management';

import { TopnavSearch, TResolvedOptionElements } from './TopnavSearch';

import Styles from './TopnavSearch.module.scss';

type TSearchInput = {
  term: string;
  invoiceSearch?: boolean;
};

type TSearchOutput = {
  matterIds: string[];
  leadIds: string[];
  contactIds: string[];
  invoiceIds: string[];
};

const optionResolver = async (inputValue: string): Promise<TResolvedOptionElements> => {
  const results = searchForResults(inputValue);

  const limit = 10;

  // slice all results down to max limit
  const limitedResults: TSearchOutput = {
    matterIds: results.matterIds.slice(Math.max(results.matterIds.length - limit, 0)),
    leadIds: results.leadIds.slice(Math.max(results.leadIds.length - limit, 0)),
    contactIds: results.contactIds.slice(Math.max(results.contactIds.length - limit, 0)),
    invoiceIds: results.invoiceIds.slice(Math.max(results.invoiceIds.length - limit, 0)),
  };

  // helper func to calculate combined list length
  const getCurrentCount = () =>
    limitedResults.matterIds.length +
    limitedResults.leadIds.length +
    limitedResults.contactIds.length +
    limitedResults.invoiceIds.length;

  // keep reducing array sizes until we hit the limit
  while (getCurrentCount() > limit) {
    if (getCurrentCount() > limit && limitedResults.matterIds.length > Math.floor(limit / 3))
      limitedResults.matterIds.shift();
    if (getCurrentCount() > limit && limitedResults.leadIds.length > Math.floor(limit / 3))
      limitedResults.leadIds.shift();
    if (getCurrentCount() > limit && limitedResults.contactIds.length > Math.floor(limit / 3))
      limitedResults.contactIds.shift();
    if (getCurrentCount() > limit) limitedResults.invoiceIds.shift();
  }

  return {
    matters: limitedResults.matterIds
      .map((value) => ({
        value,
        element: <MatterDisplay matterId={value} showStatusPill />,
      }))
      .reverse(),
    leads: limitedResults.leadIds
      .map((value) => ({
        value,
        element: <MatterDisplay matterId={value} showStatusPill />,
      }))
      .reverse(),
    contacts: limitedResults.contactIds
      .map((value) => ({
        value,
        element: (
          <>
            <StatusPill statusLabel={getContactStatusLabelById(value)} className={Styles.statusPill} />
            <ContactDisplay contactId={value} showLastNameFirst inline hideDeletedStatus />
          </>
        ),
      }))
      .reverse(),
    invoices: limitedResults.invoiceIds.map((value) => {
      const invoice = getInvoiceById(value);

      return {
        value,
        element: (
          <span>
            #{invoice.currentVersion.invoiceNumber} - <MatterDisplay matterId={invoice.currentVersion.matterId} />
          </span>
        ),
      };
    }),
  };
};

const preProcessSearchTerm = (rawTerm: string): TSearchInput => {
  const term = (rawTerm && rawTerm.trim()) || '';

  // We make searching sane, by only searching when we have 3 or more characters in our search term, with the exception of invoices.
  // We use the # symbol to indicate that a search should be for an invoice only, in which case there is no length restriction as
  // the number of potential matches is much smaller.
  if (term.length > 0 && term[0] === '#') {
    return { term, invoiceSearch: true };
  }

  if (term.length > 2) {
    return { term };
  }

  return { term: '' };
};

const searchForResults = (rawTerm: string): TSearchOutput => {
  const searchInput = preProcessSearchTerm(rawTerm);

  const outputs: TSearchOutput = {
    matterIds: [],
    leadIds: [],
    contactIds: [],
    invoiceIds: [],
  };

  if (!searchInput.term) {
    return outputs;
  }

  if (searchInput.invoiceSearch) {
    if (hasBillingAccess()) {
      outputs.invoiceIds = search(getInvoiceTypeaheadSummaries(), 'invoiceId', ['typeahead'], searchInput.term);
    }
    return outputs;
  }

  outputs.matterIds = search(
    getMatterTypeaheadSummaries().filter((m) => !m.isLead),
    'id',
    ['typeahead', 'matterClientNames', 'clientDisplay', 'matterStartedISO'],
    searchInput.term,
  );

  outputs.leadIds = search(
    getMatterTypeaheadSummaries(true).filter((m) => m.isLead),
    'id',
    ['typeahead', 'matterClientNames', 'clientDisplay', 'matterStartedISO'],
    searchInput.term,
  );

  outputs.contactIds = search(getContactTypeAheadSummaries(), 'id', ['typeahead'], searchInput.term);

  return outputs;
};

type TProps = { onClickLink: (nav: any) => void };

export const TopnavSearchBigLoadUpFrontContainer = (props: TProps) => (
  <TopnavSearch onClickLink={props.onClickLink} optionResolver={optionResolver} />
);
