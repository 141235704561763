'use strict';

const { validateTypeLabelsAndValues } = require('./validate-type-labels-and-values');

const getTypeAsMaps = ({ typeLabels, typeValues }) => {
  validateTypeLabelsAndValues({ typeLabels, typeValues });
  const keys = Object.keys(typeValues);
  const { valueToLabelMap, labelToValueMap } = keys.reduce((acc, key) => {
    acc.valueToLabelMap[typeValues[key]] = typeLabels[key];
    acc.labelToValueMap[typeLabels[key]] = typeValues[key];
    return acc;
  }, { valueToLabelMap: {}, labelToValueMap: {} });
  return [valueToLabelMap, labelToValueMap];
};

module.exports = {
  getTypeAsMaps,
};
