import { getFirmName, getPhoneNumber, getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { getLineSummary as getMatterLineSummary } from '@sb-matter-management/redux/matters';
import { formatContactSalutation } from '@sb-customer-management/business-logic/contacts-summary/services';
import { fetchPeopleP } from '@sb-customer-management/redux/contacts';
import { getMap as getBankAccountBalances } from '@sb-billing/redux/bank-account-balances';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { interpolateText } from '@sb-billing/business-logic/evergreen-retainer';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getMatterTrustBalance } from '@sb-billing/redux/bank-account-balances.2/selectors';

export const getTrustDepositRequestEmailInterpolated = async ({
  depositRequest,
  t,
  defaultMatterBillingConfig,
  matterBillingConfig,
}) => {
  const people = await fetchPeopleP(depositRequest.payorId).catch(() => []);

  const matterTrustBalance = getMatterTrustBalance(getBankAccountBalances(), {
    matterId: depositRequest.matterId,
    balanceType: balanceTypes.BALANCE,
  });
  const matterTotals = getMatterTotalsById(depositRequest.matterId);

  const replenishUpToAmount = matterBillingConfig.trustRetainerSettingsOverridden
    ? matterBillingConfig.trustRetainerReplenishAmount
    : defaultMatterBillingConfig.trustRetainerReplenishAmount;
  const minimumTrustRetainerAmount = matterBillingConfig.trustRetainerSettingsOverridden
    ? matterBillingConfig.minimumTrustRetainerAmount
    : defaultMatterBillingConfig.minimumTrustRetainerAmount;
  // Get trust balance across all trust accounts
  const amountRequested = replenishUpToAmount - matterTrustBalance;

  const data = {
    accountType: 'Trust', // Always trust for evergreen retainer.
    debtorName: formatContactSalutation(people),
    replenishUpToCents: replenishUpToAmount,
    staffName: getStaffEmailDetails().name,
    amountRequestedCents: amountRequested,
    matterLineSummary: getMatterLineSummary(depositRequest.matterId),
    outstandingBalance: matterTotals && matterTotals.unpaid,
    phoneNumber: getPhoneNumber(),
    firmName: getFirmName(),
    showPaymentButton: false,
    trustBalance: matterTrustBalance,
    minimumThreshold: minimumTrustRetainerAmount,
  };

  const subject = interpolateText(depositRequest.subject, data, t);
  const message = interpolateText(depositRequest.message, data, t);

  return { subject, message };
};
