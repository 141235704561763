import { TSchemeValuesFor, schemeMappings } from '@sb-itops/region-schemes';

type TDetailedStreetAddress = {
  unitNumber?: string;
  buildingLevel?: string;
  streetNumber?: string;
  streetName?: string;
  streetType?: string; // AU only this is in streetName in the UK
};

type TUsAddressProperties = {
  city?: string;
  state?: string;
  zipCode?: string;
  addressLine0: string;
  addressLine1: string;
  addressLine2: string;
};

type TAuAddressProperties = {
  city?: string;
  zipCode?: string;
  state?: string;
  detailedStreetAddress?: TDetailedStreetAddress;
};

type TUkAddressProperties = {
  locality?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  detailedStreetAddress?: TDetailedStreetAddress;
};

type TAddress = TAuAddressProperties & TUkAddressProperties & TUsAddressProperties;

const formatAuAddress = (address: TAuAddressProperties) => {
  const addressLines: string[] = [];

  if (address.detailedStreetAddress) {
    addressLines.push(
      [
        address.detailedStreetAddress.streetNumber,
        address.detailedStreetAddress.streetName,
        address.detailedStreetAddress.streetType,
      ]
        .filter(Boolean)
        .join(' '),
    );
    addressLines.push(`${address.city || ''} ${address.state || ''} ${address.zipCode || ''}`);
  }

  return addressLines;
};

const formatUsAddress = (address: TUsAddressProperties) => {
  const addressLines: string[] = [];

  if (address.addressLine0) {
    addressLines.push(address.addressLine0);
  }
  if (address.addressLine1) {
    addressLines.push(address.addressLine1);
  }
  if (address.addressLine2) {
    addressLines.push(address.addressLine2);
  }

  addressLines.push([address.city ? `${address.city},` : '', address.state, address.zipCode].filter(Boolean).join(' '));

  return addressLines;
};

const formatGbAddress = (address: TUkAddressProperties) => {
  const addressLines: string[] = [];

  // looks like it has a detailedStreetAddress if it comes from redux but not from GQL
  if (address.detailedStreetAddress) {
    const flat = (address.detailedStreetAddress?.unitNumber || '').trim();
    const flatIsNumberOnly = !!flat.match(/^[0-9]+$/);
    const street = (
      address.detailedStreetAddress.streetName
        ? `${address.detailedStreetAddress.streetNumber || ''} ${address.detailedStreetAddress.streetName || ''}`
        : ''
    ).trim();
    const propertyName = (address.detailedStreetAddress.buildingLevel || '').trim();

    // property name line
    if (propertyName && flat) {
      addressLines.push(`${flat}, ${propertyName}`);
    } else if (propertyName && !flat) {
      addressLines.push(`${propertyName}`);
    }

    // flat line
    if (flat && !propertyName && !flatIsNumberOnly) {
      addressLines.push(`${flat}`);
    }

    // street line
    if (street) {
      if (flat && flatIsNumberOnly && !propertyName) {
        addressLines.push(`${flat} ${street}`);
      } else {
        addressLines.push(`${street}`);
      }
    }
  }

  if (address.locality) {
    addressLines.push(address.locality);
  }

  if (address.city) {
    addressLines.push(address.city);
  }

  if (address.zipCode) {
    addressLines.push(address.zipCode);
  }

  let country = address.country?.toUpperCase();
  if (country === 'UNITED KINGDOM') {
    // dif country is UNITED KINGDOM it should be omitted
    country = '';
  }
  if (country) {
    addressLines.push(country);
  }

  return addressLines;
};

/**
 * Return a formatted address, like what you could stick on an envelope
 *
 * @param {TAddress} address
 * @param {*} region
 * @return {string[]}
 */
export const formatAddress = (address: TAddress, addressScheme: TSchemeValuesFor<'addressScheme'>): string[] => {
  switch (addressScheme) {
    case schemeMappings.addressScheme.AU:
      return formatAuAddress(address);
    case schemeMappings.addressScheme.US:
      return formatUsAddress(address);
    case schemeMappings.addressScheme.GB:
      return formatGbAddress(address);
    default:
      throw new Error(`Unimplemented addressScheme ${addressScheme}`);
  }
};
