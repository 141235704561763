class MyString {
  static removeCharacterOfEscapeInAllString(stringValue, stringOfEscape) {
    const listOfEscape = this.removeCharacterOfEscapeNotAllowed(stringOfEscape);
    let newstringValue = '';

    if (listOfEscape === undefined) {
      return stringValue;
    }

    Array.from(stringValue).forEach((element) => {
      if (!listOfEscape.includes(element)) {
        if (element === '>') {
          newstringValue += '&gt;';
        } else if (element === '<') {
          newstringValue += '&lt;';
        } else {
          newstringValue += element;
        }
      }
    });

    return newstringValue;
  }

  static convertOneCharInHexToDec(value) {
    if (value.length !== 1) {
      return undefined;
    }

    if (value >= '0' && value <= '9') {
      return value;
    }

    if (value.toUpperCase() >= 'A' && value.toUpperCase() <= 'F') {
      value = value.toUpperCase();
      let number;

      switch (value) {
        case 'A':
          number = '10';
          break;
        case 'B':
          number = '11';
          break;
        case 'C':
          number = '12';
          break;
        case 'D':
          number = '13';
          break;
        case 'E':
          number = '14';
          break;
        case 'F':
          number = '15';
          break;
        default:
          break;
      }

      return number;
    }
    return undefined;
  }

  static removeCharacterOfEscapeNotAllowed(stringOfEscape) {
    const listOfCharacterOfEscape = ['\n', '\r', '\t', '\f'];
    const newStringOfEscape = [];

    Array.from(stringOfEscape).forEach((elem) => {
      if (listOfCharacterOfEscape.includes(elem)) {
        newStringOfEscape.push(elem);
      }
    });
    return newStringOfEscape.length > 0 ? newStringOfEscape : undefined;
  }

  static hasOnlyWhiteSpace(content) {
    return content.replace(/\s/g, '').length === 0;
  }

  static findTextBetween(fullString, first, second) {
    const preIndex = fullString.indexOf(first);
    const firstIndex = preIndex + first.length;
    const endIndex = firstIndex + fullString.substring(firstIndex).indexOf(second);
    return fullString.substring(firstIndex, endIndex);
  }
}

module.exports = MyString;
