import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';

angular.module('sb.billing.webapp').component('sbDataContacts', {
  'require': {
    sbComposeCtrl: '^sbCompose'
  },
  'bindings': {
    composeKey: '@',
    filterIds: '<?',
    asMap: '<?'
  },
  'controller': function ($scope, sbLoggerService, sbContactsMbService) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbDataContacts');

    // log.setLogLevel('info');

    $scope.$on('smokeball-data-update-sbContactsMbService', updateData);

    that.$onInit = function () {
      log.info(`composeKey: ${that.composeKey}, asMap: ${that.asMap}`);
    };

    that.$onChanges = function (changesObj) {
      if (changesObj.filterIds) {
        //if the filters have actually changed - not just copy of the old filters
        if (!_.isEqual(changesObj.filterIds.currentValue, changesObj.filterIds.previousValue)) {
          updateData();
        }
      } else {
        updateData();
      }
    };

    function updateData() {
      let contacts = _.isArray(that.filterIds) ? sbContactsMbService.getByIds(that.filterIds) : sbContactsMbService.getCurrentContacts();
      const data = that.asMap ? asMap(contacts) : asList(contacts);
      that.sbComposeCtrl.setComposeData(data, that.composeKey);
    }

    function asMap(contacts) {
      return contacts.reduce((acc, contact) => {
        acc[contact.entityId] = {
          ...contact,
          representativeOfs: getRepresentativeOfs(contact),
        };

        return acc;
      }, {});
    }

    function asList(contacts) {
      return contacts.map(contact => {
        return {
          ...contact,
          representativeOfs: getRepresentativeOfs(contact),
          contactDisplay: getContactDisplay(contact.entityId, { showLastNameFirst: true }), // used for sorting
        };
      });
    }

    function getRepresentativeOfs(contact) {
      if (!contact.representativeOfs || contact.representativeOfs.length === 0) {
        return [];
      }

      return contact.representativeOfs.reduce((representativeOfs, repContactId) => {
        const repContact = sbContactsMbService.getById(repContactId);
        if (repContact) {
          representativeOfs.push(repContact);
        }
        return representativeOfs;
      }, []);
    }
  }
});
