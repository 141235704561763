import { updateCache as updateRedux } from '@sb-matter-types/redux/matter-types';

angular.module('@sb-matter-types/services').service('sbMatterTypesMbService', function(sbGenericCacheService, sbEndpointType) {

  sbGenericCacheService.setupCache({
    name: 'sbMatterTypesMbService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'matter-types' },
      poll: 15,
    },
    updateRedux
  });
});
