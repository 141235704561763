'use strict';

const getTransparentFallbackLogoInBase64 = () => {
  const transparentFallbackLogoInBase64 =
    'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  return transparentFallbackLogoInBase64;
};

module.exports = {
  getTransparentFallbackLogoInBase64,
};
