/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { Button, Modal } from '@sb-itops/react';
import classNames from 'classnames';
import * as messageDisplay from '@sb-itops/message-display';
import Styles from './Modal.module.scss';

export interface ISingleInputModalProps {
  onClose: () => void;
  onSubmit: (value: string) => void;
  formSubmitting: boolean;
  title: string;
  label: string;
  initial: string;
}

const illegalChars = /[\\|/|:|*|?|<|>|"]/g;

export const SingleInputModal = (props: ISingleInputModalProps) => {
  const [text, setText] = useState(props.initial || '');
  return (
    <Modal
      className={Styles.modal}
      title={props.title}
      body={
        <>
          <label htmlFor="new-name">{props.label}</label>
          <input
            className={classNames(!text && Styles.error, 'form-control')}
            autoFocus
            value={text}
            onChange={(e) => {
              if (illegalChars.test(e.target.value as string)) {
                messageDisplay.warn(
                  messageDisplay
                    .builder()
                    .text('Illegal character detected and changed to _')
                    .group('illegal-character'),
                );
              }
              setText((e.target.value as string).replace(illegalChars, '_'));
            }}
            type="text"
          />
        </>
      }
      footer={
        <Button
          size="full-width"
          onClick={() => props.onSubmit(text || '')}
          disabled={props.formSubmitting || !text}
          locked={props.formSubmitting}
        >
          Save
        </Button>
      }
      onModalClose={props.onClose}
    />
  );
};
