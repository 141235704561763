angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/leads';

  $stateProvider

  .state('home.billing.leads', {
    url: '/leads?matterType&matterStatus&attorneyResponsible&minBalance&maxBalance',
    reloadOnSearch: false,
    templateUrl: BASE_DIRECTORY + '/leads.html',
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
    data: {
      authorized: true,
      navHighlights: ['billing', 'leads']
    }
  });

});
