'use strict';

const INVOICE_ACTION_LINKS_HTML = `[PAY_NOW_BUTTON][VIEW_INVOICE_BUTTON]
<br />
<br />
<br />
`;

const applyInvoiceActionLinks = ({ emailBody }) => `${INVOICE_ACTION_LINKS_HTML} ${emailBody}`;

module.exports = {
  applyInvoiceActionLinks,
};
