import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@sb-itops/react';

import { DraftInvoicePreviewContainer } from './DraftInvoicePreview.container.jsx';
import Styles from './DraftInvoicePreviewModal.module.scss';

export const DraftInvoicePreviewModal = ({ isVisible, invoiceId, invoiceNumber, draftInvoiceOverrides, onClose }) => {
  if (!isVisible || !invoiceId) {
    return null;
  }

  return (
    <Modal
      isVisible={isVisible}
      title={invoiceNumber ? `Draft Invoice #${invoiceNumber}` : 'New Invoice'}
      body={
        <div className={Styles.draftInvoicePreviewModalBody}>
          <DraftInvoicePreviewContainer invoiceId={invoiceId} draftInvoiceOverrides={draftInvoiceOverrides} />
        </div>
      }
      onModalClose={onClose}
      showFooter={false}
      showModalBodyPadding={false}
      isModalBodyScrollable={false}
    />
  );
};

DraftInvoicePreviewModal.displayName = 'DraftInvoicePreviewModal';

DraftInvoicePreviewModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string,
  invoiceNumber: PropTypes.number,
  draftInvoiceOverrides: PropTypes.shape({
    invoiceVersion: PropTypes.object,
    invoiceTotals: PropTypes.object,
    quickPayments: PropTypes.shape({
      operating: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        sourceAccountType: PropTypes.string.isRequired,
        sourceAccountId: PropTypes.string,
        matterId: PropTypes.string.isRequired,
      }),
      trust: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        sourceAccountType: PropTypes.string.isRequired,
        matterId: PropTypes.string.isRequired,
      }),
      credit: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        sourceAccountType: PropTypes.string.isRequired,
        sourceAccountId: PropTypes.string,
        matterId: PropTypes.string.isRequired,
      }),
    }),
  }),
  onClose: PropTypes.func,
};

DraftInvoicePreviewModal.defaultProps = {
  invoiceId: undefined,
  invoiceNumber: undefined,
  draftInvoiceOverrides: undefined,
  onClose: () => {},
};
