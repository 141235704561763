import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CollapsibleHeader, PanelExpander, ToggleListFilter } from '@sb-itops/react';
import { FilterExpandCollapse } from 'web/containers';
import { DateListFilter } from 'web/react-redux';
import { StatelessSelect } from '@sb-itops/react/select';
import Styles from './FeeWisePaymentsListFilterPanel.module.scss';

const FeeWisePaymentsListFilterPanel = memo((props) => {
  const { categoryOptions, selectCategory, selectedCategory } = props;
  const { expanded, toggleExpanded, panelFilterScope } = props;

  const { dateFilterLabel, dateRangeType, fromDate, toDate, onDateListChange } = props;
  const { selectedStatusIds, onSelectStatus, statusOptions, hideStatusAllOption } = props;
  const { linkedAccountSelected, linkedAccountOnSelect, linkedAccountLoading, linkedAccountOptions } = props;

  return (
    <FilterExpandCollapse className="panel-filter" scope={panelFilterScope}>
      {({ hiddenFilters, onToggleFilterVisibility }) => (
        <PanelExpander
          className={classnames('contacts-list-nav panel-filter', Styles.feeWisePaymentsListFilterPanel)}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        >
          {/* View menu */}
          <div className="side-menu-block">
            <h3>View</h3>
            <ul className="side-menu-list">
              {categoryOptions.map((option) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  className={classnames({ active: selectedCategory === option.key })}
                  onClick={() => selectCategory(option.key)}
                  key={option.key}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          </div>

          {/* Account Filter */}
          <CollapsibleHeader
            text="Account"
            onClick={onToggleFilterVisibility}
            name="fwAccount"
            collapsed={hiddenFilters?.fwAccount}
          >
            <StatelessSelect
              className={Styles.filterSelection}
              isLoading={linkedAccountLoading}
              selectedOption={linkedAccountSelected}
              options={linkedAccountOptions}
              onValueChange={(option) => {
                linkedAccountOnSelect(option.value);
              }}
            />
          </CollapsibleHeader>

          {/* Transaction date Filter */}
          <CollapsibleHeader
            text={dateFilterLabel}
            onClick={onToggleFilterVisibility}
            name="fwDate"
            collapsed={hiddenFilters?.fwDate}
          >
            <DateListFilter
              onChange={(dateFilter) => {
                onDateListChange(dateFilter);
              }}
              className="side-menu-list"
              selectedFilter={dateRangeType}
              from={fromDate}
              to={toDate}
              showAll={false}
              showBefore={false}
              showThisMonth
              showLastMonth
            />
          </CollapsibleHeader>

          {/* Status Filter */}
          <CollapsibleHeader
            text="Status"
            onClick={onToggleFilterVisibility}
            name="fwStatus"
            collapsed={hiddenFilters?.fwStatus}
          >
            <ToggleListFilter
              onSelect={(status) => {
                onSelectStatus(status);
              }}
              options={statusOptions}
              selected={selectedStatusIds}
              hideShowAllOption={hideStatusAllOption}
              showCount={false}
              countDeleted={false}
              showAllLabel="All"
            />
          </CollapsibleHeader>
        </PanelExpander>
      )}
    </FilterExpandCollapse>
  );
});

FeeWisePaymentsListFilterPanel.displayName = 'FeeWisePaymentsListFilterPanel';

FeeWisePaymentsListFilterPanel.propTypes = {
  categoryOptions: PropTypes.array.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,

  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  panelFilterScope: PropTypes.string.isRequired,

  dateFilterLabel: PropTypes.string.isRequired,
  dateRangeType: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  onDateListChange: PropTypes.func.isRequired,

  selectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  hideStatusAllOption: PropTypes.bool.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  linkedAccountSelected: PropTypes.string.isRequired,
  linkedAccountOnSelect: PropTypes.func.isRequired,
  linkedAccountLoading: PropTypes.bool.isRequired,
  linkedAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
};

FeeWisePaymentsListFilterPanel.defaultProps = {};

export default FeeWisePaymentsListFilterPanel;
