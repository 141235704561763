import { gql } from '@apollo/client';

const query = gql`
  query BillingContactsPaymentPlansRouteAdditionalData($unpaidMatterFilter: UnpaidMatterFilter) {
    unpaidMatters(filter: $unpaidMatterFilter) {
      id
      debtorIds
    }
  }
`;

const notificationIds = [
  'CustomerManagementNotifications',
  'DebtorsNotifications.PaymentPlanUpdated',
  'DebtorsNotifications.PaymentPlanEventUpdated',
  // May need others, eg Matters
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BillingContactsPaymentPlansRouteAdditionalData = {
  query,
  notificationIds,
};
