import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { LinkableText } from '@sb-itops/react/linkable-text';
import transform from './transform-invoice-number';

const InvoiceDisplay = memo(({ invoice, asLink, inline, onClickLink, className }) => {
  const text = transform(invoice);
  return <LinkableText text={text} className={className} asLink={asLink} inline={inline} onClickLink={onClickLink} />;
});

InvoiceDisplay.propTypes = {
  invoice: PropTypes.shape({
    invoiceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  asLink: PropTypes.bool,
  inline: PropTypes.bool,
  onClickLink: PropTypes.func,
  className: PropTypes.string,
};

InvoiceDisplay.defaultProps = {
  invoice: {},
  asLink: false,
  inline: false,
  onClickLink: undefined,
  className: 'invoice-display',
};

export default InvoiceDisplay;
