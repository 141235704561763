'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/support/upcoming';
  $stateProvider
    .state('home.billing.support.upcoming', {
      url: '/upcoming',
      templateUrl: BASE_DIRECTORY + '/upcoming.html',
      controller: 'SupportUpcomingController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
      }
    });
});
