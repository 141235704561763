/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes, indexTypes } from '@sb-itops/redux';
import domain from '../domain';

// we are not going to have opdates so far on the cache. We will see if we need some sort of indicator at the end of the feature.
const api = cacheFactory({
  domain,
  name: 'operating-cheques',
  keyPath: 'chequeId',
  ngCacheName: 'sbOperatingChequesService',
  syncType: syncTypes.SINCE,
  immutable: false,
  indexes: [
    {
      name: 'byChequeNumber',
      indexer: (cheque) => (Number.isNaN(+cheque.chequeNumber) ? cheque.chequeNumber : +cheque.chequeNumber),
      type: indexTypes.ONE_TO_ONE,
    },
  ],
});

export const { getMap, getList, updateCache, clearCache, getById, UPDATE_CACHE, getLastUpdated } = api;

export const getByChequeNumber = (chequeNumber) => {
  const dataByChequeNumber = api.getIndex('byChequeNumber');
  return dataByChequeNumber && dataByChequeNumber[+chequeNumber];
};

export const chequeExists = (chequeNumber) => !!getByChequeNumber(chequeNumber);

export * from './print-operating-cheques';

export * from './create-operating-cheque';

export * from './selectors';
