import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// this is needed SOLEY so we can attach the redux store to the container
import { withReduxStore } from '@sb-itops/react';
import { getCurrentStaffByUserId, getStaffByPersonId } from '@sb-firm-management/redux/firm-management';

import UserNameDisplay from '../../../components/user-name-display/UserNameDisplay';

const getDisplay = (userId, personId, initials) => {
  const user = getCurrentStaffByUserId(userId) || getStaffByPersonId(personId);

  if (!user) {
    return '';
  }

  return initials ? user.initials : user.name || user.initials;
};

const mapStateToProps = (state, { userId, personId, initials, ...otherProps }) => ({
  ...otherProps,
  display: getDisplay(userId, personId, initials),
});

const UserNameByIdContainer = withReduxStore(connect(mapStateToProps)(UserNameDisplay));

UserNameByIdContainer.propTypes = {
  userId: PropTypes.string,
  personId: PropTypes.string,
  inline: PropTypes.bool,
  initials: PropTypes.bool,
};

UserNameByIdContainer.defaultProps = {
  userId: undefined,
  personId: undefined,
  inline: false,
  initials: false,
};

export default UserNameByIdContainer;
