import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query MatterTypeDetails($matterId: ID!, $filter: MatterFilter) {
    matter(id: $matterId, filter: $filter) {
      id
      matterTypeId
      matterType {
        matterTypeId
        name
        locationId
        categories
        typeCategory
      }
    }
  }
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterTypeDetails = {
  query,
  notificationIds,
};
