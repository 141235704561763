import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withReduxStore } from '@sb-itops/react';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import { ButtonWithCount } from '../button-with-count';

const mapStateToProps = (state, { selectedInvoicesIds, buttonText }) => ({
  count: selectedInvoicesIds.length,
  buttonText,
});
const mapDispatchToProps = (dispatch, { confirmationModalId }) => ({
  onClick: () => {
    setModalDialogVisible({ modalId: confirmationModalId });
  },
});

const DeleteInvoicesContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(ButtonWithCount));

DeleteInvoicesContainer.displayName = 'DeleteInvoicesContainer';

DeleteInvoicesContainer.propTypes = {
  selectedInvoicesIds: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string.isRequired,
  confirmationModalId: PropTypes.string.isRequired,
};

DeleteInvoicesContainer.defaultProps = {
  selectedInvoicesIds: [],
};

export default DeleteInvoicesContainer;
