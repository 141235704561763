import { updateCache as updateRedux } from '@sb-billing/redux/controlled-money-account-settings';

angular
  .module('@sb-billing/services')
  .service('sbControlledMoneyAccountSettingsService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    sbGenericCacheService.setupCache({
      name: 'sbControlledMoneyAccountSettingsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_ALL, stub: 'billing/controlled-money-account-settings' },
        poll: 60,
        subscriptions: 'notifier.AccountsNotifications.ControlledMoneyAccountSettingsUpdated'
      },
      updateRedux
    });
  });
