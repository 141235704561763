'use strict';

/**
 * Get payee UUID from transaction
 * @param {Object} txn Transaction entity
 * @returns {string} UUID
 */
function getPayeeContactId(txn) {
  if (!txn || typeof txn !== 'object') {
    return undefined;
  }

  if (txn.type === 'InvoicePayment') {
    return txn.contactId;
  }

  const matched = (txn.description || '').match(/contactId:(........-....-....-....-............)/);
  return Array.isArray(matched) ? matched[1] : undefined;
}

module.exports = {
  getPayeeContactId,
};
