import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import { PreviewPdf } from '../preview-pdf';
import Styles from './PreviewPdfModal.module.scss';

const PreviewPdfModal = (props) => {
  const { onClose, isVisible, url } = props;
  const onModalClose = (event) => onClose(event);

  return (
    <ReactModal
      overlayClassName="overlay"
      className={Styles.previewPdfModal}
      ariaHideApp={false}
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick
      isOpen={!!isVisible}
    >
      <div className={Styles.previewModalContainer}>
        <i className={classnames(Styles.closeIcon, 'close-icon')} onClick={onModalClose} />
        <PreviewPdf url={url} />
      </div>
    </ReactModal>
  );
};

PreviewPdfModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  isVisible: PropTypes.bool,
};

PreviewPdfModal.defaultProps = {
  url: '',
  className: undefined,
  modalBodyClassName: undefined,
  isVisible: undefined,
};

export default PreviewPdfModal;
