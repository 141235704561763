import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import {
  getList,
  getFeeActivitiesNotDeleted,
  getExpenseActivitiesNotDeleted,
  getById as getActivityById,
} from '@sb-billing/redux/activities';
import { getFeeUtbmsActivities, getById as getUtbmsActivityById } from '@sb-billing/redux/utbms-activity-codes';
import { getApplicableActivityCodesForRHSFeeModal } from '@sb-billing/business-logic/activities/services';
import ActivityDropdown from './ActivityDropdown';

const mapStateToProps = (
  state,
  {
    selectedActivityCode,
    onSelectionChange,
    excludeFees,
    excludeExpenses,
    isUtbmsEnabled,
    hasError,
    utbmsCodesRequiredByFirm,
  },
) => {
  if (!excludeFees && !excludeExpenses) {
    throw new Error('Excluding both fee and expense activities is not allowed');
  }

  let customActivities = excludeFees ? getExpenseActivitiesNotDeleted() : null;
  customActivities = excludeExpenses ? getFeeActivitiesNotDeleted() : null;
  customActivities = customActivities || getList();

  let utbmsActivities;
  if (isUtbmsEnabled && !excludeFees) {
    utbmsActivities = getFeeUtbmsActivities();
  }
  // TODO we will add expenses here

  const activities = getApplicableActivityCodesForRHSFeeModal({
    utbmsActivities,
    customActivities,
    utbmsEnabledForMatter: isUtbmsEnabled,
    utbmsCodesRequiredByFirm,
  });

  const originalSelectedActivity =
    selectedActivityCode && (getActivityById(selectedActivityCode) || getUtbmsActivityById(selectedActivityCode));

  return {
    activities,
    hasError,
    selectedActivityCode,
    originalSelectedActivity,
    onSelectionChange,
  };
};

const ActivityDropdownContainer = withReduxStore(connect(mapStateToProps)(ActivityDropdown));

ActivityDropdownContainer.propTypes = {
  excludeFees: PropTypes.bool,
  excludeExpenses: PropTypes.bool,
  hasError: PropTypes.bool,
  selectedActivityCode: PropTypes.string,
  utbmsCodesRequiredByFirm: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  activityFilter: PropTypes.func,
  isUtbmsEnabled: PropTypes.bool,
};

ActivityDropdownContainer.defaultProps = {
  excludeFees: false,
  excludeExpenses: false,
  hasError: false,
  selectedActivityCode: undefined,
  utbmsCodesRequiredByFirm: false,
  onSelectionChange: undefined,
  activityFilter: undefined,
  isUtbmsEnabled: false,
};

export default ActivityDropdownContainer;
