import { getMatterDisplayById, getById } from '@sb-matter-management/redux/matters';
import { get } from 'lodash';
import { findActivePaymentPlanByDebtorId } from '@sb-billing/redux/payment-plans/selectors';
import { selectors, actions } from 'web/redux/route/billing-advanced-search';
import { sort } from '@sb-itops/sort';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { getList as getAllPaymentPlans } from '@sb-billing/redux/payment-plans';
import { InvoiceTable } from '../invoice-table';

/**
 * TODO: This component and directory can be deleted as part of BB-14223 cleanup
 */

const fieldMap = {
  issuedDate: 'currentVersion.issuedDate',
  dueDate: 'currentVersion.dueDate',
  invoiceNumber: 'currentVersion.invoiceNumber',
  debtor: 'debtorDisplay',
  matter: 'matterDisplay',
  totalExcInterest: 'totalExcInterest',
  interest: 'interest',
  paid: 'paid',
  unpaid: 'unpaid',
  status: 'status',
};

function onSortInvoices({ sortBy, sortDirection, invoices }) {
  const newDirection = sortDirection.toLowerCase();
  const sortField = fieldMap[sortBy] ? fieldMap[sortBy] : sortBy;
  const sortIteree = (summary) => get(summary, sortField);
  const sortedInvoices = sort(invoices, [sortIteree], [newDirection]);

  return {
    sortBy,
    sortDirection: newDirection,
    invoices: sortedInvoices,
  };
}

const mapStateToProps = (state, { selectedInvoices, invoices }) => {
  const summary = {
    total: 0,
    paid: 0,
    unpaid: 0,
    interest: 0,
    totalExcInterest: 0,
  };
  const invoicesDisplay = invoices.map((invoice) => {
    summary.total += invoice.total;
    summary.paid += invoice.paid;
    summary.unpaid += invoice.unpaid;
    summary.interest += invoice.interest;
    summary.totalExcInterest += invoice.totalExcInterest;
    const paymentPlan =
      invoice.currentVersion.debtors &&
      invoice.currentVersion.debtors.length > 0 &&
      findActivePaymentPlanByDebtorId(getAllPaymentPlans(), {
        debtorId: invoice.currentVersion.debtors[0].id,
      });

    const debtorIds = invoice.currentVersion.debtors.map((debtor) => debtor.id);
    const debtorDisplay = debtorIds
      .map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true }))
      .join(' | ');

    return {
      ...invoice,
      debtorIds,
      debtorDisplay,
      matter: getById(invoice.currentVersion.matterId),
      status: invoice.currentVersion.status,
      matterDisplay: getMatterDisplayById(invoice.currentVersion.matterId),
      paymentPlanId: (paymentPlan && paymentPlan.invoiceIds.includes(invoice.invoiceId) && paymentPlan.id) || undefined,
    };
  });

  const { sortBy, sortDirection } = selectors.getState(state);
  return {
    invoiceStatuses: [],
    displayMode: 'SEARCH',
    showMatter: true,
    showDebtor: true,
    selectedInvoices: selectedInvoices.reduce((acc, invoiceId) => {
      acc[invoiceId] = true;
      return acc;
    }, {}),
    ...onSortInvoices({ sortBy, sortDirection, invoices: invoicesDisplay }),
    summary,
  };
};

const mapDispatchToProps = (dispatch, { onInvoicesSelected, selectedInvoices, invoices }) => ({
  sort: (sortBy) => {
    dispatch(actions.onSort(sortBy));
  },
  onSelectInvoice: ({ invoice }) => {
    const newSelectedInvoices = selectedInvoices.filter((invoiceId) => invoiceId !== invoice.invoiceId);
    if (newSelectedInvoices.length === selectedInvoices.length) {
      newSelectedInvoices.push(invoice.invoiceId);
    }
    onInvoicesSelected(newSelectedInvoices);
  },
  onSelectAllInvoices: (selected) => {
    const result = selected
      ? invoices.reduce((acc, invoice) => {
          if (invoice.currentVersion.status !== 'VOID') {
            acc.push(invoice.invoiceId);
          }
          return acc;
        }, [])
      : [];

    onInvoicesSelected(result);
  },
});

export const AdvancedSearchInvoiceTableContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceTable),
);

AdvancedSearchInvoiceTableContainer.displayName = 'AdvancedSearchInvoiceTableContainer';

AdvancedSearchInvoiceTableContainer.propTypes = {
  invoices: PropTypes.array.isRequired,
  onInvoicesSelected: PropTypes.func.isRequired,
  onSendEmailFromIndicatorIcon: PropTypes.func.isRequired,
  onSendCommunicateFromIndicatorIcon: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

AdvancedSearchInvoiceTableContainer.defaultProps = {};
