import { gql } from '@apollo/client';

const query = gql`
  query TrustChequeAvailableNumbers($filter: TrustChequeAvailableNumbersFilter!) {
    trustChequeAvailableNumbers(filter: $filter) {
      bankAccountId
      availableChequeNumbers
      lastChequeNumber
      nextChequeNumber
    }
  }
`;

const notificationIds = ['AccountsNotifications.TrustChequeUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const TrustChequeAvailableNumbers = {
  query,
  notificationIds,
};
