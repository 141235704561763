import { useEffect, useRef } from 'react';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { saveAs } from 'file-saver';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getAccountId } from 'web/services/user-session-management';
import * as messageDisplay from '@sb-itops/message-display';
import { featureActive } from '@sb-itops/feature';
import uuid from '@sb-itops/uuid';
import { useDeferredPromise } from './useDeferredPromise';

/**
 * Creates notification listeners for notifications sent from route-export-ledes (single download only)
 * Will block until either successful file download or error
 */
export function useSingleLedesDownload() {
  const deferRef = useRef(null);
  const { defer } = useDeferredPromise(deferRef);

  useEffect(() => {
    const saveFile = async ({ url, invoiceNumber }) => {
      const blob = await fetch(url).then((r) => r.blob());
      saveAs(blob, `ledes-1998b-invoice-${invoiceNumber}.txt`);
      deferRef.current.resolve();
    };

    // --------------------- Non-ubtms Notification management -----------------------
    const infoCallback = (response) => {
      const message = JSON.parse(response);
      const { hasNonUtbmsEntries } = message;

      if (hasNonUtbmsEntries) {
        messageDisplay.info('This Invoice has non-UTBMS entries.');
      }
    };

    const unsubUbtmsNotifications = subscribeToNotifications({
      notificationIds: ['Ledes1998bExportNotificationHasNonUbtmsEntries'],
      callback: infoCallback,
    });

    // --------------------- Success Notification management ------------------------
    const successCallback = (response) => {
      const message = JSON.parse(response);
      const { preSignedUrl, invoiceNumber, hasProblems } = message;

      saveFile({ url: preSignedUrl, invoiceNumber });
      if (!hasProblems) {
        messageDisplay.success(`LEDES file generated for invoice #${invoiceNumber}.`);
      }
    };

    const unsubSuccessNotifications = subscribeToNotifications({
      notificationIds: ['Ledes1998bExportNotificationComplete'],
      callback: successCallback,
    });

    // ----------------- Success with Problems (Error) Notification management -----------------
    const errorCallback = (response) => {
      const message = JSON.parse(response);
      const { LedesErrors: ledesErrors, invoiceNumber } = message;

      let warnText = `LEDES file generated with problems for invoice #${invoiceNumber}. 
      `;
      warnText += ledesErrors.reduce(
        (acc, row) => `${acc} 
      ${row}
      `,
        '',
      );
      messageDisplay.warn(warnText);
    };

    const unsubErrorNotifications = subscribeToNotifications({
      notificationIds: ['LedesExportErrorNotification'],
      callback: errorCallback,
    });

    // --------------------- Critical Error Notification management ------------------------
    const criticalErrorCallback = (response) => {
      const message = JSON.parse(response);
      messageDisplay.error(`LEDES file generated with problems for invoice #${message.invoiceNumber}.`);
    };

    const unsubCriticalErrorNotifications = subscribeToNotifications({
      notificationIds: ['LedesExportCriticalErrorNotification'],
      callback: criticalErrorCallback,
    });

    // --------------------- Notification failed notification management ------------------------
    const notifFailedCallback = () => {
      deferRef.current.reject();
    };

    const unsubNotifFailedNotifications = subscribeToNotifications({
      notificationIds: ['Ledes1998bExportNotificationFailed'],
      callback: notifFailedCallback,
    });

    const unsubAllNotifs = () => {
      unsubSuccessNotifications();
      unsubErrorNotifications();
      unsubCriticalErrorNotifications();
      unsubNotifFailedNotifications();
      unsubUbtmsNotifications();
    };

    return () => {
      unsubAllNotifs();
    };
  }, []);

  return {
    downloadLedes: async (invoiceIds) => {
      const requestId = uuid();
      const accountId = getAccountId();

      // Set up new promise to track request and block execution until fully resolved
      // Needed as on draft invoice page we don't want to navigate to invoices list from
      // until after the ledes file has been downloaded
      const deferredPromise = defer().promise;

      try {
        const path = `/billing/invoice-export/ledes-1998b/${accountId}/${requestId}/`;
        const fetchOptions = {
          body: JSON.stringify({
            invoiceIds: [...invoiceIds],
            requestId,
            exportNotesAsDescription: featureActive('BB-11888'),
          }),
        };

        await fetchPostP({ path, fetchOptions, skipCamelCase: true });

        // Block until notification received
        await deferredPromise;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      return requestId;
    },
  };
}
