import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/user-billing-attributes';

angular.module('@sb-billing/services').service('sbUserBillingAttributesService', function(sbGenericCacheService, sbEndpointType, sbGenericEndpointService, sbLoggerService) {
  'use strict';

  const that = this;
  const log = sbLoggerService.getLogger('sbUserBillingAttributesService');
  const resetUserBillingAttributesEndpoint = `billing/user-billing-attributes/reset`;
  that.resetUserBillingAttributes = resetUserBillingAttributes;

  sbGenericCacheService.setupCache({
    name: 'sbUserBillingAttributesService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/user-billing-attributes' },
      poll: 60,
      subscriptions: 'notifier.BillingSharedNotifications.BillingUserAttributesUpdated'
    },
    updateRedux,
    clearRedux,
  });

  function resetUserBillingAttributes (errorCallback) {
    return sbGenericEndpointService.postPayloadP(resetUserBillingAttributesEndpoint)
      .catch((err) => {
        const errMsg = err && err.message || 'ERROR';
        log.error('failed to send request for resetting billing user attributes', errMsg);
        errorCallback();
        throw err;
      });
  }
});
