import { optimisticUpdateFactory } from "@sb-itops/redux/optimistic-update";

const { opdateCache: opdateExpenseCache } = optimisticUpdateFactory({
  ngCacheName: "sbTransactionService",
  keyPath: "id",
});

export const onDepositFunds = ({  commandMessage }) => {
  const timestamp = moment().toISOString();
  const opdateTransactions = commandMessage.transactions.map((transaction) => {
    return {
      ...commandMessage,
      transactions: undefined,
      id: transaction.transactionId,
      matterId: transaction.matterId,
      contactId: commandMessage.payorId,
      amount: transaction.amount,
      type: "Deposit",
      timestamp,
      paymentId: commandMessage.bulkDepositId,
    };
  });

  opdateExpenseCache({ optimisticEntities: opdateTransactions });
};
