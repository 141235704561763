'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/bills/account-reminders';

  $stateProvider
    .state('home.billing.bills.account-reminders', {
      url: '/invoice-reminders',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/account-reminders-view.html',
      controller: 'sbAccountRemindersViewController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills', 'account-reminders']
      }
    });

});
