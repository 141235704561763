import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useSelector, useDispatch } from 'react-redux';
import * as forms from '@sb-itops/redux/forms2';
import { ALL_STATES, getRegion } from '@sb-itops/region';
import { useEffect, useState } from 'react';
import * as messageDisplay from '@sb-itops/message-display';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { getActiveTrustAccounts, getTrustAccounts } from '@sb-billing/redux/bank-account';
import { sortByProperty } from '@sb-itops/nodash';
import {
  getSettings as getCMASettings,
  saveControlledMoneyAccountSettings,
} from '@sb-billing/redux/controlled-money-account-settings';
import { getSettings as getBankAccountSettingsFromCache } from '@sb-billing/redux/bank-account-settings';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { fetchPermissionsFromWebP } from 'web/services/permissions';
import { resourceIds } from '@sb-itops/business-logic/authorisation';
import { saveSettings as saveBankAccountSettings } from '@sb-billing/redux/bank-account-settings/save-settings';
import { store } from '@sb-itops/redux';
import { bankAccountState } from '@sb-billing/business-logic/bank-account/entities/constants';
import { useReduxAction } from 'web/hooks';
import { TrustAccountSettings } from './TrustAccountSettings';

const REGION = getRegion();
const scope = 'trust-accounts-settings';
const log = getLogger('trust-accounts-settings');

const hooks = () => ({
  useRegion: () => ({
    region: REGION,
  }),
  usePermissions: () => {
    const [isAuthorised, setIsAuthorised] = useState(true);
    useEffect(() => {
      const fetchFirmOwnerPerms = async () => {
        const permissions = await fetchPermissionsFromWebP([resourceIds.BILLING_BANK_ACCOUNT]);
        setIsAuthorised(permissions[resourceIds.BILLING_BANK_ACCOUNT]);
      };
      if (
        featureActive('BB-12792') &&
        getBankAccountStrategy(getRegion(), bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES)
      ) {
        fetchFirmOwnerPerms();
      }
    }, []);

    return {
      isAuthorised,
      supportsFirmOwnerBankDetailChanges:
        featureActive('BB-12792') &&
        getBankAccountStrategy(getRegion(), bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES),
    };
  },
  useSelectors: () => {
    const [showClosedAccounts, setShowClosedAccount] = useState(false);
    const [addEditTrustAccount, setAddEditTrustAccount] = useState({ show: false, id: undefined });
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, scope);

    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
    } = useSelector(formSelectors.getFormState);

    const {
      allowOnlyFirmOwnersEditTrustAccountDetails,
      createPDFReceiptOnDeposit,
      createPDFReceiptOnTrustPayment,
      createPDFReceiptOnOperatingPayment,
      createPDFReceiptOnMatterTransfer,
      createPDFReceiptOnDepositCMA,
      createPDFReceiptOnPaymentCMA,
      locationsDefault,
    } = formFields;
    // we use both trustAccountsListAll and trustAccountsListOpen so sort them both
    const trustAccountsListAll = sortByProperty(getTrustAccounts(), 'accountName', 'asc', false, t('trustAccount'));
    const trustAccountsListOpen = sortByProperty(
      getActiveTrustAccounts(),
      'accountName',
      'asc',
      false,
      t('trustAccount'),
    );
    const trustAccountsList = showClosedAccounts ? trustAccountsListAll : trustAccountsListOpen;

    const activeTrustAccountsByLocation = getActiveTrustAccountsByLocation(trustAccountsListOpen);

    useEffect(() => {
      const cmaAccountSettings = getCMASettings() || {
        createPDFReceiptOnDeposit: false,
        createPDFReceiptOnPayment: false,
      };

      const bankAccountSettings = getBankAccountSettings();

      const defaults = bankAccountSettings.defaultTrustAccounts || [];

      const locationsDefaultInit = Object.keys(activeTrustAccountsByLocation).reduce((acc, location) => {
        acc[location] = defaults.find((account) => account.location === location)?.defaultTrustAccountId;
        return acc;
      }, {});

      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            allowOnlyFirmOwnersEditTrustAccountDetails: bankAccountSettings.restrictTrustAccountEdit,
            createPDFReceiptOnDeposit: bankAccountSettings.createPDFReceiptOnDeposit,
            createPDFReceiptOnTrustPayment: bankAccountSettings.createPDFReceiptOnTrustPayment,
            createPDFReceiptOnOperatingPayment: bankAccountSettings.createPDFReceiptOnOperatingPayment,
            createPDFReceiptOnMatterTransfer: bankAccountSettings.createPDFReceiptOnMatterTransfer,
            createPDFReceiptOnDepositCMA: cmaAccountSettings.createPDFReceiptOnDeposit,
            createPDFReceiptOnPaymentCMA: cmaAccountSettings.createPDFReceiptOnPayment,
            locationsDefault: Object.keys(locationsDefaultInit).length ? locationsDefaultInit : undefined,
          },
        }),
      );

      const onUnload = () => dispatch(formActions.clearForm());
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateLocations = () => {
      const activeTrustAccountsByStateUpdated = getActiveTrustAccountsByLocation(getTrustAccounts());
      const bankAccountSettings = getBankAccountSettings();

      const defaults = bankAccountSettings.defaultTrustAccounts || [];
      const locationsDefaultUpdated = Object.keys(activeTrustAccountsByStateUpdated).reduce((acc, location) => {
        acc[location] = defaults.find((account) => account.location === location)?.defaultTrustAccountId;
        return acc;
      }, {});

      const value = Object.keys(locationsDefaultUpdated).length ? locationsDefaultUpdated : undefined;

      dispatch(formActions.setFieldValue({ field: 'locationsDefault', value }));
    };
    // update the locations when bank account or settings updated
    // as account could have been closed or location added/removed
    useReduxAction('billing/bank-account/UPDATE_CACHE', () => {
      updateLocations();
    });
    useReduxAction('billing/bank-account-settings/UPDATE_CACHE', () => {
      updateLocations();
    });

    const validateFn = (fieldValues) => {
      const defaultsErrors = {};
      const locationDefaultsNotRequired = trustAccountsListAll.length === 1 && !trustAccountsListAll[0].location;

      if (!locationDefaultsNotRequired) {
        const locations = Object.keys(activeTrustAccountsByLocation);
        locations.forEach((location) => {
          const defaultLocationTrustAccountId = fieldValues.locationsDefault[location];
          // error field if default do not exist or
          // if exist but it is not in list to select it (account may have been deactivated)
          if (
            !defaultLocationTrustAccountId ||
            !activeTrustAccountsByLocation[location].find((a) => a.value === defaultLocationTrustAccountId)
          ) {
            defaultsErrors[location] = 'error'; // text not used
          }
        });
      }

      // Forms 2 expects errors to be reported as flattened dot object notation.
      return Object.keys(defaultsErrors).length ? nestedObjectToFlattened({ locationsDefault: defaultsErrors }) : {};
    };

    const hasMissingTrustAccountLocation = !!trustAccountsListOpen.find((a) => !a.location);
    const bankAccountSettings = getBankAccountSettings();

    return {
      // config
      supportsOperatingAccount: hasFacet(facets.operatingAccount),
      supportsCMA: hasFacet(facets.CMA),
      supportsTrustAccountPerState: hasFacet(facets.trustAccountPerState),
      abbrState: hasFacet(facets.stateAbbreviated),
      setShowClosedAccount,
      showClosedAccounts,
      setAddEditTrustAccount,
      addEditTrustAccount,
      trustAccountsList,
      activeTrustAccountsByLocation,
      hasMissingTrustAccountLocation,
      restrictTrustAccountEdit: bankAccountSettings.restrictTrustAccountEdit,
      // form fields
      allowOnlyFirmOwnersEditTrustAccountDetails,
      createPDFReceiptOnDeposit,
      createPDFReceiptOnTrustPayment,
      createPDFReceiptOnOperatingPayment,
      createPDFReceiptOnMatterTransfer,
      createPDFReceiptOnDepositCMA,
      createPDFReceiptOnPaymentCMA,
      locationsDefault,
      // form
      formInitialised,
      submitFailed,
      formSubmitting,
      onFieldValueUpdated: (fieldValues) => dispatch(formActions.updateFieldValues({ fieldValues })),
      onSave: async (event) => {
        event.preventDefault();
        await dispatch(formOperations.validateForm({ validateFn }));

        try {
          await dispatch(
            formOperations.submitFormWithValidationP({
              submitFnP: async (formFieldValues) => {
                await saveSettings(formFieldValues, t);
                messageDisplay.success('Bank account settings saved.');
              },
            }),
          );
        } catch (err) {
          messageDisplay.error('Failed to save bank account settings');
        }
      },
    };
  },
});

const getBankAccountSettings = () => {
  // Move "Create PDF" flags to bank account settings feature
  const {
    createPDFReceiptOnTrustPayment = false,
    createPDFReceiptOnOperatingPayment = false,
    createPDFReceiptOnMatterTransfer = false,
    // default the createPDFReceiptOnDeposit option to false if its in the US, true if its in AU
    createPDFReceiptOnDeposit = hasFacet(facets.createPDFReceiptOnDepositDefaultSettingTrue),
    // default the restrictTrustAccountEdit option to false if its in the US, true if its in AU/UK
    restrictTrustAccountEdit = getBankAccountStrategy(
      getRegion(),
      bankAccountStrategies.FIRM_OWNER_BANK_DETAIL_CHANGES,
    ),

    defaultTrustAccounts = [],
  } = getBankAccountSettingsFromCache() || {};

  return {
    restrictTrustAccountEdit,
    createPDFReceiptOnTrustPayment,
    createPDFReceiptOnOperatingPayment,
    createPDFReceiptOnMatterTransfer,
    createPDFReceiptOnDeposit,
    defaultTrustAccounts,
  };
};

const getActiveTrustAccountsByLocation = (trustAccountsList) => {
  const locationTrustAccounts = trustAccountsList.reduce((acc, item) => {
    if (item.state === bankAccountState.OPEN && item.location) {
      const option = { label: item.accountName, value: item.id };
      if (Array.isArray(acc[item.location])) {
        acc[item.location].push(option);
      } else {
        acc[item.location] = [option];
      }
    }

    return acc;
  }, {});

  if (locationTrustAccounts[ALL_STATES.value]) {
    const allStatesAccounts = locationTrustAccounts[ALL_STATES.value];
    const locationTrustAccountsWithAllStatesAccountInOptions = Object.keys(locationTrustAccounts).reduce(
      (acc, location) => {
        if (location !== ALL_STATES.value) {
          acc[location] = locationTrustAccounts[location].concat(allStatesAccounts);
        } else {
          acc[location] = allStatesAccounts;
        }
        return acc;
      },
      {},
    );

    return locationTrustAccountsWithAllStatesAccountInOptions;
  }
  return locationTrustAccounts;
};

const saveSettings = async (formFieldValues, t) => {
  // cma
  if (hasFacet(facets.CMA) && featureActive('BB-6381') && hasControlledMoneyAccountChanges(formFieldValues)) {
    await saveControlledMoneyAccountSettings({
      createPDFReceiptOnDeposit: formFieldValues.createPDFReceiptOnDepositCMA,
      createPDFReceiptOnPayment: formFieldValues.createPDFReceiptOnPaymentCMA,
    }).catch((err) => {
      log.error(err);
      messageDisplay.error(`Failed to save ${t('controlledMoneyAccount')} settings.`);
    });
  }

  // convert the object to array of objects
  const defaultTrustAccounts = Object.entries(formFieldValues.locationsDefault || {}).reduce(
    (acc, [location, defaultTrustAccountId]) => {
      if (location && defaultTrustAccountId) {
        acc.push({ location, defaultTrustAccountId });
      }
      return acc;
    },
    [],
  );

  // Always save these to sbBankAccountSettingsService
  await store.dispatch(
    saveBankAccountSettings({
      restrictTrustAccountEdit: formFieldValues.allowOnlyFirmOwnersEditTrustAccountDetails,
      createPDFReceiptOnTrustPayment: formFieldValues.createPDFReceiptOnTrustPayment,
      createPDFReceiptOnOperatingPayment: formFieldValues.createPDFReceiptOnOperatingPayment,
      createPDFReceiptOnMatterTransfer: formFieldValues.createPDFReceiptOnMatterTransfer,
      createPDFReceiptOnDeposit: formFieldValues.createPDFReceiptOnDeposit,
      defaultTrustAccounts,
    }),
  );
};

const hasControlledMoneyAccountChanges = (formFieldValues) => {
  if (!hasFacet(facets.CMA)) {
    return false;
  }

  const CMASettings = getCMASettings();

  if (!CMASettings) {
    return true;
  }

  return (
    formFieldValues.createPDFReceiptOnDepositCMA !== CMASettings.createPDFReceiptOnDeposit ||
    formFieldValues.createPDFReceiptOnPaymentCMA !== CMASettings.createPDFReceiptOnPayment
  );
};

export const TrustAccountSettingsContainer = withReduxProvider(composeHooks(hooks)(TrustAccountSettings));

TrustAccountSettingsContainer.displayName = 'TrustAccountSettingsContainer';
