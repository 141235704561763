import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { withReduxStore, Translation } from '@sb-itops/react';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { featureActive } from '@sb-itops/feature';

const mapStateToProps = (state, { invoiceId, totalFor }) => {
  let totals = getTotalsForInvoiceId(invoiceId);

  // To show retainer request amounts for finalised invoices (RHS panel) when required
  if (featureActive('BB-6908')) {
    const invoice = getInvoiceById(invoiceId);
    const invoiceVersion = invoice?.currentVersion;

    if (['FINAL', 'PAID'].includes(invoiceVersion?.status)) {
      const showRetainer = invoiceVersion?.additionalOptions?.showRetainer;

      if (showRetainer) {
        const retainerRequestAmount = invoiceVersion?.retainerRequestAmount || 0;
        const totalPaymentDue = totals.unpaid + retainerRequestAmount || 0;

        totals = {
          ...totals,
          retainerRequestAmount,
          totalPaymentDue,
        };
      }
    }
  }

  const amount = totals && totals[totalFor];

  return {
    amount: Number.isFinite(amount) ? amount : 0,
  };
};

const InvoiceTotalDisplayContainer = withReduxStore(
  connect(mapStateToProps, undefined)(({ amount }) => <Translation>{(t) => t('cents', { val: amount })}</Translation>),
);

InvoiceTotalDisplayContainer.propTypes = {
  invoiceId: PropTypes.string,
  totalFor: PropTypes.string,
};

InvoiceTotalDisplayContainer.defaultProps = {
  invoiceId: undefined,
  totalFor: 'billed',
};

export default InvoiceTotalDisplayContainer;
