/**
 * Get billing interval in minutes for firm. If FeeConfiguration or billableMinutes don't
 * exist for the firm, default to 6mins.
 */
export const getBillableMinutes = (state) => {
  const DEFAULT_BILLING_MINUTES = 6;
  return Object.values(state).length > 0
    ? Object.values(state)[0].billableMinutes || DEFAULT_BILLING_MINUTES
    : DEFAULT_BILLING_MINUTES;
};
