'use strict';

const { dateToInteger } = require('@sb-itops/date');

/**
 * Calculates and return the total installment amounts due on specified date
 * Amount returned also includes the amount due on the date specified.
 * @param {*} paymentPlan payment plan with installments
 * @param {*} date
 */
const calculateInstallmentAmountDue = ({ installments }, date) => {
  if (!installments) {
    throw new Error('paymentPlan with installments is required.');
  }

  const dateAsInteger = dateToInteger(date);
  return installments.reduce((acc, installment) => {
    if (installment.date <= dateAsInteger) {
      return acc + installment.amount;
    }
    return acc;
  }, 0);
};

module.exports = {
  calculateInstallmentAmountDue,
};
