import PropTypes from 'prop-types';

const BankAccountNameDisplay = ({ name, closed }) => `${name || ''}${closed ? ' (Closed)' : ''}`;

BankAccountNameDisplay.propTypes = {
  name: PropTypes.string,
};

BankAccountNameDisplay.defaultProps = {
  name: undefined,
};

export default BankAccountNameDisplay;
