/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const StaffMultiSelect = ({
  allStaffs,
  selectedStaffIds,
  className,
  onSelectStaff,
  onSelectAllStaff,
  onClearAllStaff,
}) => (
  <div className={classnames('staff-multi-select', className)}>
    {allStaffs.map((staff) => {
      const staffIsSelected = selectedStaffIds.indexOf(staff.id) !== -1;
      return (
        <span
          key={`staff-multi-select-${staff.id}`}
          className={classnames('staff-label', 'label', staffIsSelected ? 'label-primary' : 'label-default')}
          onClick={() => onSelectStaff(staff.id)}
          title={staff.name}
        >
          {staff.initials}
        </span>
      );
    })}
    <ul className="links">
      <li onClick={onSelectAllStaff}>Select All</li>
      <li onClick={onClearAllStaff}>Clear All</li>
    </ul>
  </div>
);

StaffMultiSelect.propTypes = {
  allStaffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.string,
      isFormerStaff: PropTypes.bool,
    }),
  ).isRequired,
  selectedStaffIds: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onSelectStaff: PropTypes.func.isRequired,
  onSelectAllStaff: PropTypes.func.isRequired,
  onClearAllStaff: PropTypes.func.isRequired,
};

StaffMultiSelect.defaultProps = {
  selectedStaffIds: [],
  className: undefined,
};

StaffMultiSelect.displayName = 'StaffMultiSelect';

export default StaffMultiSelect;
