import {
  bankAccountTypeDisplayByValue,
  bankAccountEntityTypeEnum,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';

export const constructBalances = ({
  bankAccountsWithBalances,
  defaultTrustAccountId,
  isMatterContactBalanceType,
  t,
}) => {
  let matterTrustBalance = 0;
  let trustAccountsDisabled = true;
  const balances = {
    [bankAccountTypeEnum.TRUST]: {
      type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.TRUST],
      balance: 0,
      contactBalances: [],
    },
    [bankAccountTypeEnum.OPERATING]: {
      type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.OPERATING],
      balance: 0,
      contactBalances: [],
    },
    [bankAccountTypeEnum.CREDIT]: {
      type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.CREDIT],
      balance: 0,
      contactBalances: [],
    },
  };

  bankAccountsWithBalances.forEach((bankAccount) => {
    switch (bankAccount.accountType) {
      case bankAccountTypeEnum.TRUST:
        trustAccountsDisabled = false; // we have at least 1 active trust account
        matterTrustBalance += bankAccount.bankAccountBalances?.matterBalances?.[0]?.balance || 0;

        if (defaultTrustAccountId && bankAccount.id === defaultTrustAccountId) {
          balances[bankAccountTypeEnum.TRUST] = {
            type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.TRUST],
            name: getBankAccountName(bankAccount, t),
            bankAccountId: bankAccount?.id,
            // for trust, we use available balance, which is balance minus protected funds
            balance: bankAccount?.bankAccountBalances?.matterBalances?.[0]?.availableBalance || 0,
            contactBalances: isMatterContactBalanceType
              ? (bankAccount?.bankAccountBalances?.contactBalances || []).map((contactBalance) => ({
                  ...contactBalance,
                  // add the (account) type to the balance, as this makes it easier for consumers of this data to interact with the balance entities
                  type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.TRUST],
                  bankAccountId: bankAccount?.id,
                }))
              : [],
          };
        }
        break;
      case bankAccountTypeEnum.OPERATING:
        balances[bankAccountTypeEnum.OPERATING] = {
          type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.OPERATING],
          bankAccountId: bankAccount?.id,
          balance: bankAccount?.bankAccountBalances?.matterBalances?.[0]?.balance || 0,
          contactBalances: isMatterContactBalanceType
            ? (bankAccount?.bankAccountBalances?.contactBalances || []).map((contactBalance) => ({
                ...contactBalance,
                // add the (account) type to the balance, as this makes it easier for consumers of this data to interact with the balance entities
                type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.OPERATING],
                bankAccountId: bankAccount?.id,
              }))
            : [],
        };
        break;
      case bankAccountTypeEnum.CREDIT:
        balances[bankAccountTypeEnum.CREDIT] = {
          type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.CREDIT],
          bankAccountId: bankAccount?.id,
          balance: bankAccount?.bankAccountBalances?.matterBalances?.[0]?.balance || 0,
          contactBalances: isMatterContactBalanceType
            ? (bankAccount?.bankAccountBalances?.contactBalances || []).map((contactBalance) => ({
                ...contactBalance,
                // add the (account) type to the balance, as this makes it easier for consumers of this data to interact with the balance entities
                type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.CREDIT],
                bankAccountId: bankAccount?.id,
              }))
            : [],
        };
        break;

      default:
        break;
    }
  });

  return {
    balances,
    trustAccountsDisabled,
    matterTrustBalance, // matter balance of all active trust accounts
    isBalanceAvailable: Object.values(balances).some((balance) => balance.balance > 0),
  };
};
