import { hasFacet, facets } from '@sb-itops/region-facets';
import { utilsFactory } from '@sb-itops/redux';
import { domain } from '../../domain';
import { toggleState } from '../../actions/generic/toggle-state';

export const toggleShowSystemDateFactory = ({ reduxPath }) => {
  // Create the feature behaviours for the selected redux path.
  const {
    toggleStateAction,
    toggleStateReducer: toggleShowSystemDateReducer,
    ACTION_TYPE: TOGGLE_SHOW_SYSTEM_DATE,
  } = toggleState({ actionName: `${reduxPath}/TOGGLE_SHOW_SYSTEM_DATE` });

  // Setup the feature reducer.
  const { getState, registerReducer } = utilsFactory({ domain, name: reduxPath });

  const reducers = {
    [TOGGLE_SHOW_SYSTEM_DATE]: toggleShowSystemDateReducer,
  };

  const defaultState = { showSystemDate: hasFacet(facets.transactionsByEnteredDate) };
  const toggleShowSystemDate = () => toggleStateAction('showSystemDate');

  registerReducer((state = defaultState, action = {}) => {
    const reducer = reducers[action.type];
    return reducer ? reducer(state, action) : state;
  });

  return { getState, toggleShowSystemDate };
};
