import {
  split as splitDescription,
  interpolateValues as interpolateDescription,
} from '../../../modules/interpolate-description';

angular.module('@sb-billing/services').service('sbInterpolatorService', function (sbInvoicingService, sbMattersMbService, sbContactsMbService, sbBankAccountService) {
  const svc = this;

  svc.interpolate = interpolate;

  const getterMap = {
    account: sbBankAccountService.get,
    contact: sbContactsMbService.getById,
    invoice: sbInvoicingService.getInvoice,
    matter: sbMattersMbService.getById,
  };

  function interpolate (description) {
    return interpolateDescription(splitDescription(description), (type, id) => getterMap[type](id));
  }
});
