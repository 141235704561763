import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getSplitPayors } from 'web/redux/selectors/payment-source';
import SplitPayorsList from './SplitPayorsList';

const mapStateToProps = (_state, { matterId, bankAccountId }) => ({
  payors: getSplitPayors(bankAccountId, matterId),
});

const SplitPayorsListContainer = withReduxStore(
  connect(mapStateToProps)(({ payors, matterId, onChange, bankAccountId }) => {
    const defaultFormData = { payors: {} };
    const [formData, setFormData] = useState(defaultFormData);
    useEffect(
      () => {
        // Clear state on unmount
        onChange({});
        return () => setFormData(defaultFormData);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [matterId],
    );

    const onChangeHandler = (payor, amount) => {
      let newAmount = amount;
      if (!Number.isFinite(amount) || amount < 0) {
        newAmount = 0;
      }

      if (amount > payor.available) {
        newAmount = payor.available;
      }

      const newPayors = Object.assign(formData.payors, { [payor.id]: newAmount });
      setFormData({ payors: newPayors });
      onChange(formData.payors);
    };

    const onSelectHandler = (payor, checked) => {
      const newPayors = Object.assign(formData.payors, { [payor.id]: checked ? payor.available : 0 });
      setFormData({ payors: newPayors });
      onChange(formData.payors);
    };

    return (
      <SplitPayorsList
        payors={payors}
        formData={formData}
        onChange={onChangeHandler}
        onSelect={onSelectHandler}
        bankAccountId={bankAccountId}
      />
    );
  }),
);

SplitPayorsListContainer.displayName = 'SplitPayorsListContainer';

SplitPayorsListContainer.propTypes = {
  bankAccountId: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SplitPayorsListContainer;
