angular.module('sb.billing.webapp').service('sbDpTransactions', function(sbDpHelpers, sbTransactionService) {
  'use strict';

  const that = this;
  that.byId = byId;
  that.byIds = byIds;
  that.byFilterFunction = byFilterFunction;

  function byId(destroyables, getId, onUpdate) {
    const dataProvider = sbDpHelpers.provideDataById(getId, sbTransactionService.getById, onUpdate);
    sbDpHelpers.registerWatcher(destroyables, getId, dataProvider);
    sbDpHelpers.registerCacheWatcher(destroyables, 'sbTransactionService', dataProvider);
  }

  function byIds(destroyables, getIds, onUpdate) {
    const dataProvider = sbDpHelpers.provideDataByIds(getIds, sbTransactionService.getById, onUpdate);
    sbDpHelpers.registerWatcher(destroyables, getIds, dataProvider);
    sbDpHelpers.registerCacheWatcher(destroyables, 'sbTransactionService', dataProvider);
  }

  function byFilterFunction(destroyables, getFilterFunction, onUpdate) {
    const dataProvider = sbDpHelpers.provideDataByFilterFunction(getFilterFunction, sbTransactionService.getByFilter, onUpdate);
    sbDpHelpers.registerWatcher(destroyables, getFilterFunction, dataProvider);
    sbDpHelpers.registerCacheWatcher(destroyables, 'sbTransactionService', dataProvider);
  }

});
