'use strict';

const { regionType } = require('../region-type');
const { statesInAustralia } = require('./states-in-australia');
const { statesInUsa } = require('./states-in-usa');
const { statesInGb } = require('./states-in-gb');
const { ALL_STATES } = require('./all-states');

/**
 * @typedef {Object} State
 * @property {string} id Unique id code for the state
 * @property {string} name Full name of the state
 * @property {string} value Alias for id
 * @property {string} label Alias for name
 */

/**
 * addDerivedProperties
 *
 * Adds additional/alias properties dervied from the properties in the base state objects.
 * @param {Object} states A raw imported state object
 * @returns {Array<State>}
 */
const addDerivedProperties = ({ states, useAbbr }) =>
  states.map(state => ({
    ...state,
    value: state.id,
    label: useAbbr ? state.id : state.name,
  }));

/** @type {Object<string, Array<State>} */
const states = {
  [regionType.AU]: addDerivedProperties({ states: statesInAustralia, useAbbr: true }),
  [regionType.GB]: addDerivedProperties({ states: statesInGb, useAbbr: false }),
  [regionType.US]: addDerivedProperties({ states: statesInUsa, useAbbr: false }),
};

const getStateNameFromId = (stateId, region) => {
  switch (region) {
    case regionType.AU:
      return statesInAustralia.find((state) => state.id === stateId)?.name;
    case regionType.US:
      return statesInUsa.find((state) => state.id === stateId)?.name;
    case regionType.GB:
      return statesInGb.find((state) => state.id === stateId)?.name;
    default:
      return stateId;
  }
};

const getStateIdFromName = (stateName, region) => {
  switch (region) {
    case regionType.AU:
      return statesInAustralia.find((state) => state.name === stateName)?.id;
    case regionType.US:
      return statesInUsa.find((state) => state.name === stateName)?.id;
    case regionType.GB:
      return statesInGb.find((state) => state.name === stateName)?.id;
    default:
      return stateName;
  }
};

module.exports = {
  states,
  getStateNameFromId,
  getStateIdFromName,
  ALL_STATES,
};
