'use strict';

/**
 * Validates the given html text against the given number of characters
 * @param html - html text
 * @returns {Boolean}
 */
function isValidCharacters(html, noOfCharacters) {
  const valueTextOnly = html.replace(/(<([^>]+)>)/gi, '');
  return valueTextOnly.length <= noOfCharacters;
}

module.exports = {
  isValidCharacters,
};
