'use strict';

module.exports = (
  string,
  {
    accountType,
    debtorName,
    staffName,
    amountRequestedCents,
    matterLineSummary,
    matterAmountDue,
    phoneNumber,
    firmName,
    showPaymentButton,
    trustBalance,
    requestReason,
  },
  t,
) =>
  [
    replaceAccountType(t, handleNullishValue(accountType)),
    replaceDebtor(handleNullishValue(debtorName)),
    replaceUsername(handleNullishValue(staffName)),
    replaceAmountRequested(handleNullishValue(getCurrencyString(amountRequestedCents, t))),
    replaceMatter(handleNullishValue(matterLineSummary)),
    replaceMatterAmountDue(handleNullishValue(getCurrencyString(matterAmountDue, t))),
    replacePhoneNumber(handleNullishValue(phoneNumber)),
    replaceRequestReason(handleNullishValue(requestReason)),
    replaceFirmName(handleNullishValue(firmName)),
    replacePayNowButton(showPaymentButton),
    replacePaymentButton(showPaymentButton),
    replaceTrustBalance(t, handleNullishValue(getCurrencyString(trustBalance, t))),
  ].reduce((str, fn) => fn(str), string);

function getCurrencyString(cents, t) {
  if (cents !== 0 && !cents) {
    return undefined;
  }
  return t('cents', { val: cents });
}

function replaceAccountType(t, accountType = '') {
  let formattedAccountType = accountType;
  if (accountType) {
    const lowerCaseAccountType = accountType.toLowerCase();
    const localisedAccountType = t(lowerCaseAccountType);
    formattedAccountType = `${localisedAccountType[0].toUpperCase()}${localisedAccountType.slice(1)}`;
  }

  return (string) => string.replace(/\[ACCOUNT_TYPE\]/g, () => formattedAccountType);
}

function replaceDebtor(debtorName = '') {
  return (string) => string.replace(/\[CLIENT_NAME\]|\[DEBTOR_NAME\]/g, debtorName);
}

function replaceAmountRequested(amountRequestedCents = '') {
  return (string) => string.replace(/\[AMOUNT_REQUESTED\]/g, () => amountRequestedCents);
}

function replaceMatter(matterLineSummary = '') {
  return (string) => string.replace(/\[MATTER_NAME\]/g, matterLineSummary);
}

function replaceUsername(staffName = '') {
  return (string) => string.replace(/\[USER_NAME\]/g, staffName);
}

function replaceMatterAmountDue(matterAmountDue = '') {
  return (string) => string.replace(/\[MATTER_AMOUNT_DUE\]/g, matterAmountDue);
}

function replacePhoneNumber(phoneNumber = '') {
  return (string) => string.replace(/\[PHONE_NUMBER\]/g, phoneNumber);
}

function replaceRequestReason(requestReason = '') {
  return (string) => string.replace(/\[REQUEST_REASON\]/g, requestReason);
}

function replaceFirmName(firmName = '') {
  return (string) => string.replace(/\[FIRM_NAME\]/g, firmName);
}

// the implementation here is unfortunately specific to the component we use to allow the user
// to write formatted text (Quill).
// This code will remove the button placeholder in addition to the blank line below it (if it exists)
function replacePayNowButton(showButton) {
  return (string) => (showButton ? string : string.replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, ''));
}

function replacePaymentButton(showButton) {
  // PAYMENT_BUTTON a deprecated placeholder, it is replaced by PAY_NOW_BUTTON, but we cannot remove as users may have used it in their templates
  return (string) => (showButton ? string : string.replace(/(:?<p>)?\[PAYMENT_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '')); //
}

function replaceTrustBalance(t, trustBalance = '') {
  return (string) => string.replace(new RegExp(`\\[${t('macros.trustBalance')}\\]`, 'g'), () => trustBalance);
}

function handleNullishValue(value) {
  // eslint-disable-next-line eqeqeq
  return value == undefined ? undefined : value;
}
