angular.module('sb.billing.webapp').component('sbDataFullPaymentList',{
  require: { composer: '^sbCompose' },
  controller: function ($scope, sbLoggerService, sbContactsMbService, sbPaymentService) {
    'use strict';

    const that = this;
    const log = sbLoggerService.getLogger('sbDataFullPaymentList');
    const paymentListData = {};

    //These don't need to be configurable for now
    const paymentListDataKey = 'paymentListData';
    const invoiceTotalsKey = 'invoiceTotals';
    const paymentListKey = 'payments';

    that.$onInit = () => {
      that.composer.onUpdateData(invoiceTotalsKey, invoiceTotalsUpdateHandler);
      that.composer.onUpdateData(paymentListKey, paymentsUpdateHandler);
    };

    function paymentsUpdateHandler(dataWrapper) {
      if (!dataWrapper || !dataWrapper.data) {
        return;
      }

      const filteredPayments = _.cloneDeep(_.filter(dataWrapper.data, payment => payment.reversedAt === null));
      paymentListData.payments = _.sortByOrder(filteredPayments, ['effectiveDate', 'timestamp'], ['asc', 'asc']);

      processPayments();
      exportPaymentList();
    }

    function invoiceTotalsUpdateHandler(dataWrapper) {
      if (!dataWrapper || !dataWrapper.data) {
        return;
      }

      paymentListData.invoiceTotal = dataWrapper.data;
      processPayments();
      exportPaymentList();
    }

    function processPayments() {
      if (!paymentListData.payments) {
        return; 
      }
      let balance = (paymentListData.invoiceTotal && paymentListData.invoiceTotal.total) || 0;
      paymentListData.payments.forEach(payment => {
        payment.amount = payment.invoices[0].amount;
        payment.payor = sbContactsMbService.getById(payment.payorId);
        payment.source = sbPaymentService.decodePaymentSource(payment);
        balance = computeRunningBalances(payment, balance);
      });
    }

    //NOTE sbDataPaymentsByInvoiceId filters invoices array
    //so we should only ever have a single invoice
    function computeRunningBalances(payment, remainingBalance) {
      if (remainingBalance === 0) {
        payment.balance = 0;
      } else {
        payment.balance = remainingBalance - payment.invoices[0].amount;
      }
      log.info('balance payment', JSON.stringify(payment));
      return payment.balance;
    }

    function exportPaymentList() {
      log.info('payment list : ', JSON.stringify(paymentListData.payments));
      that.composer.setComposeData({ dataType: paymentListDataKey, data: paymentListData.payments }, paymentListDataKey);
    }
  }
});
