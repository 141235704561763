import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconButton = ({ onClick = () => {}, icon = '', disabled = false, round = false }) => (
  <div
    className={classNames('sb-icon-button', round ? 'round' : '')}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    <i className={classNames(icon, round ? 'sb-icon-content-round' : 'sb-icon-content')} />
  </div>
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  highlight: PropTypes.bool,
  icon: PropTypes.string,
  round: PropTypes.bool,
};
IconButton.defaultProps = {
  disabled: undefined,
  onClick: undefined,
  highlight: undefined,
  icon: undefined,
  round: false,
};

export default IconButton;
