import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Styles from './IntegrationConnectionPanel.module.scss';

import { ConnectToIntegration } from './connect-to-integration';
import { ConnectedStatus } from './connected-status';
import { LoadingStatus } from './loading-status';

export const IntegrationConnectionPanel = ({
  connectToIntegration,
  integration,
  isConnected,
  isConnecting,
  isDisconnecting,
  logoSrc,
  organisationName,
  url,
}) => (
  <div
    className={classnames(Styles.integrationConnectionContainer, isConnected ? Styles.connected : Styles.disconnected)}
  >
    <div>
      {isConnected && !isDisconnecting && <ConnectedStatus organisationName={organisationName} />}
      {(isConnecting || isDisconnecting) && <LoadingStatus message={isConnecting ? 'Connecting' : 'Disconnecting'} />}
      {!isConnected && !isConnecting && !isDisconnecting && (
        <ConnectToIntegration integration={integration} connectToIntegration={connectToIntegration} url={url} />
      )}
    </div>
    <img alt={`${integration} logo`} src={logoSrc} />
  </div>
);

IntegrationConnectionPanel.propTypes = {
  connectToIntegration: PropTypes.func.isRequired,
  integration: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool,
  isDisconnecting: PropTypes.bool,
  logoSrc: PropTypes.string.isRequired,
  organisationName: PropTypes.string,
  url: PropTypes.string,
};

IntegrationConnectionPanel.defaultProps = {
  isConnecting: false,
  isDisconnecting: false,
  organisationName: undefined,
  url: undefined,
};

IntegrationConnectionPanel.displayName = 'IntegrationConnectionPanel';
