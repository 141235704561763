import logFactory from '@sb-itops/fe-logger';
import { getState } from './redux-api-factory';
import store from '../store';
import { SET_MODAL_DIALOG_VISIBLE, SET_MODAL_DIALOG_HIDDEN } from './types';
import { modalStatuses } from './reducer';

const log = logFactory.getLogger('@sb-itops/redux/modal-dialog');

/**
 * DEPRECATED - use `getModalDialogInfo` for access to modal props, or `isModalVisible` to check
 * for modal visibility
 *  */
export const getModalDialogState = ({ modalId }) => {
  if (!modalId) {
    log.error('isModalVisible, modalId is required');
    return undefined;
  }

  const state = getState();
  const modalStatus = state.modalDialogs && state.modalDialogs[modalId] && state.modalDialogs[modalId].status;
  return modalStatus === modalStatuses.visible;
};

export const getModalDialogInfo = ({ modalId }) => {
  if (!modalId) {
    log.error('getModalDialogInfo, modalId is required');
    return undefined;
  }

  const state = getState();
  return state.modalDialogs && state.modalDialogs[modalId];
};

export const isModalVisible = ({ modalId }) => {
  if (!modalId) {
    log.error('isModalVisible, modalId is required');
    return undefined;
  }

  const state = getState();
  const modalStatus = state.modalDialogs && state.modalDialogs[modalId] && state.modalDialogs[modalId].status;
  return modalStatus === modalStatuses.visible;
};

// actions
export const setModalDialogVisible = ({ modalId, props }) => {
  if (!modalId) {
    log.error('setModalDialogVisible, modalId is required');
  } else {
    store.dispatch({
      type: SET_MODAL_DIALOG_VISIBLE,
      modalId,
      props,
    });
  }
};

export const setModalDialogHidden = ({ modalId }) => {
  if (!modalId) {
    log.error('setModalDialogHidden, modalId is required');
  } else {
    store.dispatch({
      type: SET_MODAL_DIALOG_HIDDEN,
      modalId,
    });
  }
};
