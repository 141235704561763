import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query DashboardActivity($filter: InvoiceActivityFilter, $limit: Int) {
    invoiceActivity(filter: $filter, limit: $limit) {
      pastActivity {
        date
        type
        invoiceId
        invoiceNumber
      }
      futureActivity {
        date
        type
        invoiceId
        invoiceNumber
      }
    }
  }
`;

const notificationIds = ['WebQueryInvoicingNotifications.InvoiceUpdated', 'MatterManagementWebQuery.MatterUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DashboardActivity = {
  query,
  notificationIds,
};
