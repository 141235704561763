import * as types from './types';

// Defines the initial values set in state.
const initialState = {
  // When selection order is important, we use Maps
  //  * The keys in Maps are explicitly ordered (by entry insertion)
  //  * E.g. We want this behaviour when generating a combined invoice PDF by order of selection
  //
  // Relying on the order of keys in an object is not recommended when the ordering is important
  //  * "Although the keys of an ordinary Object are ordered now, this was not always the case, and the order is complex. As a result, it's best not to rely on property order." (Source: MDN)
  itemSelectionOrderedState: new Map(),
};

// Action -> Handler function lookup.
const reducerLookup = {
  [types.TOGGLE_ORDERED_ITEMS]: toggleOrderedItems,
  [types.DESELECT_ALL_ORDERED_ITEMS]: deselectAllOrderedItems,
};

/**
 * The main reducer function for the feature.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggering a potential update to the store.
 * @return {object} The new store state.
 */
export const reducer = (state = initialState, action = {}) => {
  const reducerFn = reducerLookup[action.type];
  return reducerFn ? reducerFn(state, action) : state;
};

/**
 * Action handler for types.TOGGLE_ORDERED_ITEMS.
 *
 * For each id passed in the ids payload property, if the id is selected, it become deselected,
 * if the item is deselected, it becomes selected.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function toggleOrderedItems(state, action) {
  const { items } = action.payload;
  const updatedMap = new Map(state.itemSelectionOrderedState);

  items.forEach((item) => {
    if (updatedMap.has(item)) {
      updatedMap.delete(item);
    } else {
      updatedMap.set(item, true);
    }
  });

  return {
    ...state,
    itemSelectionOrderedState: updatedMap,
  };
}

/**
 * Action handler for types.DESELECT_ALL_ORDERED_ITEMS.
 *
 * Sets all selected items as deselected.
 *
 * @param  {object} state  - The current state in the store.
 * @param  {object} action - The action which is triggered this function call.
 * @return {object} The new store state.
 */
function deselectAllOrderedItems(state) {
  const resetMap = new Map();

  return {
    ...state,
    itemSelectionOrderedState: resetMap,
  };
}
