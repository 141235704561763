import {
  SET_CALENDAR_VIEW,
  TOGGLE_EXPANDED,
  CalendarViewTypes,
  SET_SORT,
  SET_SELECTED_VIEW,
  SET_STAFF_FILTER,
  TOGGLE_GROUP_HEADER_EXPANDED,
  TOGGLE_STAFF_HEADER_EXPANDED,
  TOGGLE_MY_HEADER_EXPANDED,
  TOGGLE_FORMER_STAFF_ENABLED,
} from './types';

export const setCalendarView = (payload: { calendarView: CalendarViewTypes }) => ({
  type: SET_CALENDAR_VIEW,
  payload,
});

export const toggleExpanded = () => ({
  type: TOGGLE_EXPANDED,
  payload: {},
});

export const toggleStaffHeaderExpanded = () => ({
  type: TOGGLE_STAFF_HEADER_EXPANDED,
  payload: {},
});

export const toggleGroupHeaderExpanded = () => ({
  type: TOGGLE_GROUP_HEADER_EXPANDED,
  payload: {},
});

export const toggleMyHeaderExpanded = () => ({
  type: TOGGLE_MY_HEADER_EXPANDED,
  payload: {},
});

export const toggleFormerStaffEnabled = () => ({
  type: TOGGLE_FORMER_STAFF_ENABLED,
  payload: {},
});

export const setSort = (payload) => ({
  type: SET_SORT,
  payload,
});

export const setSelectedView = (payload) => ({
  type: SET_SELECTED_VIEW,
  payload,
});

export const setStaffFilter = (payload) => ({
  type: SET_STAFF_FILTER,
  payload,
});
