import { createSelector } from 'reselect';
import { featureActive } from '@sb-itops/feature';
import {
  balanceTypes,
  validAccountTypeForBalanceType,
} from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getMap as getBankAccountMap } from '../bank-account-balances';
import { getById as getBankAccountById, getTrustAccounts } from '../bank-account';

/**
 * There is a BankAccount record for each account type; operating and trust. Each record has a matterBalances and
 * contactBalances property each with a list of MatterBalance and ContactBalance entities respectively. Matter balances
 * are always correct, whereas contactBalances are only for firms managing funds at a matter contact level.
 * @typedef {object} BankAccount
 * @property {number} balance the value in cents, only relevant for the trust balance (cannot be used for the operating balance)
 * @property {number} protectedBalance total for any protected trust funds (cannot be used for the operating balance)
 * @property {number} availableBalance total for any available trust funds (cannot be used for the operating balance)
 * @property {Array.<ContactBalance>} contactBalances
 * @property {Array.<MatterBalance>} matterBalances
 */

/**
 * @typedef {object} MatterBalance
 * @property {matterId} matterId
 * @property {number} adjustment deprecated and to be ignored
 * @property {number|undefined} balance the value in cents (bad data may be undefined)
 * @property {number} protectedBalance for any protected trust funds
 * @property {number} availableBalance total balance available to be spent
 */

/**
 * @typedef {object} ContactBalance
 * @property {matterId} matterId
 * @property {contactId} contactId
 * @property {number|undefined} balance the value in cents (bad data may be undefined)
 * @property {number} protectedBalance for any protected trust funds
 * @property {number} availableBalance total balance available to be spent
 */

/**
 * Helper method to determine if bankAccountId is a Trust bank account in order to determine whether to respect trustBankAccountType param
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string} param.balanceType
 * @returns balanceType if Trust account, 'balance' otherwise
 */
const getBankAccountBalanceType = ({ bankAccountId, balanceType }) => {
  if (!featureActive('BB-8671')) {
    return balanceTypes.BALANCE;
  }

  const bankAccountType = getBankAccountById(bankAccountId)?.accountType;
  if (!bankAccountType) {
    throw new Error('bankAccountType cannot be falsey');
  }

  if (bankAccountType === bankAccountTypeEnum.TRUST) {
    if (!balanceType) {
      // If account is trust and no account balance type specified, default to available balance
      return balanceTypes.AVAILABLE;
    }
    return balanceType;
  }

  // If not trust account, and invalid balanceType specified, throw error
  if (balanceType && balanceType !== balanceTypes.BALANCE) {
    throw new Error(`balanceType ${balanceType} not supported on account type ${bankAccountType}`);
  }
  // just return balance
  return balanceTypes.BALANCE;
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string|undefined} param.balanceType balanceType enum to return if trust account, e.g. available, protected, balance
 * @returns {number} the firm balance in cents
 * @throws if param.bankAccountId is falsey
 */
const getFirmBalance = (state, { bankAccountId, balanceType }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }

  const matterBalances = state[bankAccountId] ? state[bankAccountId].matterBalances : [];
  const accountBalanceType = getBankAccountBalanceType({ bankAccountId, balanceType });

  return matterBalances.reduce((sum, matterBalance) => {
    const chosenBalanceType = matterBalance[accountBalanceType];
    if (chosenBalanceType && Number.isFinite(chosenBalanceType)) {
      return chosenBalanceType + sum;
    }
    return sum;
  }, 0);
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string} param.contactId
 * @param {string|undefined} param.balanceType balanceType enum to return if trust account, e.g. available, protected, balance
 * @returns {number} the contacts trust balance in cents
 * @throws if param.bankAccountId is falsey
 * @throws if param.contactId is falsey
 */
const getContactBalance = (state, { bankAccountId, contactId, balanceType }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }
  if (!contactId) {
    throw new Error('contactId cannot be falsey');
  }

  const contactBalances = state[bankAccountId] ? state[bankAccountId].contactBalances : [];
  const accountBalanceType = getBankAccountBalanceType({ bankAccountId, balanceType });

  return contactBalances.reduce((sum, cb) => {
    if (cb.contactId === contactId && cb[accountBalanceType] && Number.isFinite(cb[accountBalanceType])) {
      return sum + cb[accountBalanceType];
    }
    return sum;
  }, 0);
};

/**
 * Helper method to locate matter balance entry in bank account balance entity
 * @param {string} bankAccountId
 * @param {string} matterId
 * @param {object} state bank account balances state
 * @returns matter
 */
const findMatterBalanceEntryInBankAccountBalance = (bankAccountId, matterId, state) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }
  if (!matterId) {
    throw new Error('matterId cannot be falsey');
  }

  const matterBalances = state[bankAccountId] ? state[bankAccountId].matterBalances : [];
  const entry = matterBalances.find((mb) => mb.matterId === matterId);
  return entry;
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string} param.matterId
 * @param {string|undefined} param.balanceType balanceType enum to return if trust account, e.g. available, protected, balance
 * @returns {number} the matter balance in cents
 * @throws if param.bankAccountId is falsey
 * @throws if param.matterId is falsey
 */
const getMatterBalance = (state, { bankAccountId, matterId, balanceType }) => {
  const entry = findMatterBalanceEntryInBankAccountBalance(bankAccountId, matterId, state);
  const accountBalanceType = getBankAccountBalanceType({ bankAccountId, balanceType });

  if (entry && entry[accountBalanceType] && Number.isFinite(entry[accountBalanceType])) {
    return entry[accountBalanceType];
  }

  return 0;
};

/**
 * Get total trust balance for matter (from all trust accounts)
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.matterId
 * @returns {object} the matter trust balance in cents keyed by balance type
 * @throws if param.matterId is falsey
 */
const getMatterTrustBalanceAllTypes = createSelector(
  (state) => state,
  (state, param) => param.matterId,
  (state, matterId) => {
    const trustAccounts = getTrustAccounts();
    const matterTrustBalance = trustAccounts.reduce(
      (acc, trustAccount) => {
        acc[balanceTypes.BALANCE] += getMatterBalance(state, {
          matterId,
          bankAccountId: trustAccount.id,
          balanceType: balanceTypes.BALANCE,
        });
        acc[balanceTypes.AVAILABLE] += getMatterBalance(state, {
          matterId,
          bankAccountId: trustAccount.id,
          balanceType: balanceTypes.AVAILABLE,
        });
        acc[balanceTypes.PROTECTED] += getMatterBalance(state, {
          matterId,
          bankAccountId: trustAccount.id,
          balanceType: balanceTypes.PROTECTED,
        });

        return acc;
      },
      { [balanceTypes.BALANCE]: 0, [balanceTypes.AVAILABLE]: 0, [balanceTypes.PROTECTED]: 0 },
    );
    return matterTrustBalance;
  },
);

/**
 * Get total trust balance for matter (from all trust accounts)
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.matterId
 * @param {string} param.balanceType balanceType enum to return if trust account, e.g. available, protected, balance
 * @returns {number} the matter trust balance in cents
 * @throws if param.matterId or param.balanceType is falsey
 */
const getMatterTrustBalance = (state, { matterId, balanceType }) => {
  if (!balanceType) {
    throw new Error('balanceType cannot be falsey');
  }
  return getMatterTrustBalanceAllTypes(state, { matterId })[balanceType];
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string} param.matterId
 * @returns {number} the matter's unpresented cheques balance in cents
 * @throws if param.bankAccountId is falsey
 * @throws if param.matterId is falsey
 */
const getMatterUnpresentedChequesBalance = (state, { bankAccountId, matterId }) => {
  const entry = findMatterBalanceEntryInBankAccountBalance(bankAccountId, matterId, state);

  if (entry && Number.isFinite(entry.unpresentedChequesBalance)) {
    return entry.unpresentedChequesBalance;
  }

  return 0;
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @returns {Array.<MatterBalance>} the matter balances
 * @throws if param.bankAccountId is falsey
 */
const getMatterBalances = (state, { bankAccountId }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }

  return state[bankAccountId] ? state[bankAccountId].matterBalances : [];
};

const getAllMatterBalances = createSelector(
  (state) => getBankAccountMap(state),
  (state) =>
    Object.entries(state).reduce((acc, [bankAccountId, bankAccountEntity]) => {
      const accountType = getBankAccountById(bankAccountId)?.accountType;
      const matterBalances = bankAccountEntity.matterBalances || [];
      const accountBalanceType = getBankAccountBalanceType({
        bankAccountId,
        balanceType:
          accountType && accountType.toUpperCase() in validAccountTypeForBalanceType
            ? balanceTypes.AVAILABLE
            : undefined,
      });

      matterBalances.forEach((matterBalance) => {
        acc[matterBalance.matterId] = acc[matterBalance.matterId] || {};
        acc[matterBalance.matterId][bankAccountId] = matterBalance[accountBalanceType];
      });
      return acc;
    }, {}),
);

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string} param.matterId
 * @param {string} param.contactId
 * @param {string} param.balanceType
 * @returns {number} the balance in cents
 * @throws if param.bankAccountId is falsey
 * @throws if param.matterId is falsey
 * @throws if param.contactId is falsey
 */
const getMatterContactBalance = (state, { bankAccountId, matterId, contactId, balanceType }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }
  if (!matterId) {
    throw new Error('matterId cannot be falsey');
  }
  if (!contactId) {
    throw new Error('contactId cannot be falsey');
  }

  const contactBalances = state[bankAccountId] ? state[bankAccountId].contactBalances : [];
  const accountBalanceType = getBankAccountBalanceType({ bankAccountId, balanceType });

  return contactBalances.reduce((sum, cb) => {
    if (
      matterId === cb.matterId &&
      contactId === cb.contactId &&
      cb[accountBalanceType] &&
      Number.isFinite(cb[accountBalanceType])
    ) {
      return sum + cb[accountBalanceType];
    }
    return sum;
  }, 0);
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @param {string} param.matterId
 * @returns {Array.<ContactBalance>}
 * @throws if param.bankAccountId is falsey
 * @throws if param.matterId is falsey
 */
const getMatterContactBalances = (state, { bankAccountId, matterId }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }
  if (!matterId) {
    throw new Error('matterId cannot be falsey');
  }

  const contactBalances = state[bankAccountId] ? state[bankAccountId].contactBalances : [];

  return contactBalances.filter((cb) => cb.matterId === matterId);
};

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.bankAccountId
 * @returns {BankAccount}
 * @throws if param.bankAccountId is falsey
 */
const getFirmBankAccount = (state, { bankAccountId }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }

  return (
    state[bankAccountId] || {
      id: bankAccountId,
      balance: 0,
      protectedBalance: 0,
      availableBalance: 0,
      isDeleted: false,
      isInactive: false,
      contactBalances: [],
      matterBalances: [],
    }
  );
};

/**
 * returns a memoized funtion that, in turn, iterates the matterBalances in each bank account balance and
 * returns a map of matter ids to bank account balances, e.g.:
 * {
 *   <matterId> : {
 *     hasFunds: true,
 *     <trust_account>: cents,
 *     <operating_account>: cents,
 *   }
 * }
 */
const accountBalancesByMatterIdSelector = createSelector(
  (state) => state || [],
  (state) =>
    Object.keys(state).reduce((acc, bankAccountId) => {
      const bankAccountBalance = state[bankAccountId];
      const accountBalanceType = getBankAccountBalanceType({
        bankAccountId,
        balanceType: balanceTypes.BALANCE,
      });
      if (bankAccountBalance.matterBalances) {
        bankAccountBalance.matterBalances.forEach((matterBalance) => {
          const { matterId } = matterBalance;
          const balance = matterBalance[accountBalanceType];
          if (!acc[matterId]) {
            acc[matterId] = {};
          }
          acc[matterId][bankAccountBalance.id] = balance;
          acc[matterId].hasFunds = acc[matterId].hasFunds || balance !== 0;
        });
      }
      return acc;
    }, {}),
);

/**
 * @param {object} state bank account balances state
 * @param {object} param
 * @param {string} param.matterId
 * @returns {object|undefined}
 *   undefined if there is no matter balance in any of the bank accounts, or
 *   a map of the balances in each account for the given matter with a top level covenience property, hasFunds, e.g.:
 *   {
 *     hasFunds: true, // if any balance is not zero
 *     <trust_account>: cents,
 *     <operating_account>: cents,
 *   }
 * @throws if param.matterId is falsey
 */
const getAccountBalancesByMatterId = (state, { matterId }) => {
  if (!matterId) {
    throw new Error('matterId cannot be falsey');
  }
  return accountBalancesByMatterIdSelector(state)[matterId];
};

const getBankAccountBalanceById = (state, { bankAccountId }) => {
  if (!bankAccountId) {
    throw new Error('bankAccountId cannot be falsey');
  }
  return state[bankAccountId];
};

export {
  getAllMatterBalances,
  getMatterBalances,
  getMatterBalance,
  getMatterTrustBalance,
  getMatterTrustBalanceAllTypes,
  getMatterUnpresentedChequesBalance,
  getContactBalance,
  getMatterContactBalances,
  getMatterContactBalance,
  getFirmBalance,
  getFirmBankAccount,
  getAccountBalancesByMatterId,
  getBankAccountBalanceById,
  getBankAccountMap,
};
