import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CardNumberElement, CardCvcElement, CardExpiryElement, AddressElement } from '@stripe/react-stripe-js';
import stripeLogoSrc from './stripe-logo.svg';
import smokeballLogoSrc from './smokeball-logo.svg';
import Styles from './StripePaymentForm.module.scss';

const applyFormFieldErrorClass = ({ isInvalid, isDirty }) => {
  if (isInvalid && isDirty) {
    return Styles.hasError;
  }

  return undefined;
};

export const StripePaymentForm = ({
  hostedFieldsStyle,
  formInitialised,
  formFields,
  isSubmitting,
  useSmokeballLogo,
  onChange,
  countryCode,
}) => {
  // Return if the form data hasn't yet been initialised.
  if (!formInitialised) {
    return null;
  }

  const { email } = formFields;
  const options = {
    showIcon: true, // used for card number field
    style: hostedFieldsStyle,
  };

  const onEventBasedChangeHandler = (e) => {
    const target = e.currentTarget;
    onChange(target.name, target.value);
  };

  const onStripeElementChangeHandler = (field, event) => {
    const fieldUpdateValid = event.complete && !event.error;
    if (field === 'creditCardNumberStatus' && fieldUpdateValid) {
      onChange('creditCardBrand', event.brand);
    } else if (field === 'creditCardAddressStatus') {
      // contains name and address
      onChange('billingDetails', event.value);
    }
    onChange(field, fieldUpdateValid);
  };

  return (
    <div className={Styles.stripePaymentForm}>
      <fieldset disabled={isSubmitting}>
        <div className={Styles.container}>
          {/* Hosted fields (card number, expiry, security code) */}
          <div className={classnames(Styles.row)}>
            <div className={classnames(Styles.cell, Styles.cell40)}>
              <label>Card Number</label>
              <CardNumberElement
                className={classnames('form-control', Styles.stripeElement)}
                options={options}
                onChange={(event) => onStripeElementChangeHandler('creditCardNumberStatus', event)}
              />
            </div>
            <div className={classnames(Styles.cell, Styles.cell20)}>
              <label>CVC</label>
              <CardCvcElement
                className={classnames('form-control', Styles.stripeElement)}
                options={options}
                onChange={(event) => onStripeElementChangeHandler('creditCardCvcStatus', event)}
              />
            </div>
            <div className={classnames(Styles.cell, Styles.cell20)}>
              <label>Expiry</label>
              <CardExpiryElement
                className={classnames('form-control', Styles.stripeElement)}
                options={options}
                onChange={(event) => onStripeElementChangeHandler('creditCardExpiryStatus', event)}
              />
            </div>
            {/* Stripe/Smokeball logo */}
            <div className={classnames(Styles.cell, Styles.cell20)}>
              <div className={classnames(Styles.logoContainer)}>
                {!useSmokeballLogo && (
                  <img className={Styles.stripeLogo} src={stripeLogoSrc} alt="Processing by stripe.com" />
                )}
                {useSmokeballLogo && (
                  <img className={Styles.smokeballLogo} src={smokeballLogoSrc} alt="Processing by Smokeball" />
                )}
              </div>
            </div>
          </div>

          {/* Address fields */}
          <div className={Styles.row}>
            <div className={classnames(Styles.cell, Styles.cell100, Styles.addressForm)}>
              <AddressElement
                options={{
                  mode: 'billing',
                  defaultValues: { address: { country: countryCode } },
                }}
                onChange={(event) => onStripeElementChangeHandler('creditCardAddressStatus', event)}
              />
            </div>
          </div>

          {/* Email field */}
          <div className={Styles.row}>
            <div className={classnames(Styles.cell, Styles.cell100)}>
              <label>Email</label>
              <input
                name="email"
                type="email"
                className={classnames(
                  'form-control',
                  Styles.withLineHeight,
                  applyFormFieldErrorClass(email),
                  Styles.emailLine,
                )}
                value={email.value}
                onChange={onEventBasedChangeHandler}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

StripePaymentForm.displayName = 'StripePaymentForm';

StripePaymentForm.propTypes = {
  hostedFieldsStyle: PropTypes.object.isRequired,
  formInitialised: PropTypes.bool,
  formFields: PropTypes.any,
  isSubmitting: PropTypes.bool.isRequired,
  useSmokeballLogo: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
};

StripePaymentForm.defaultProps = {
  formInitialised: false,
  formFields: undefined,
  useSmokeballLogo: false,
};
