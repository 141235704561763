'use strict';

const sendStatuses = Object.freeze({
  NOT_SENT: -1,
  SUCCESS: 1,
  ERROR: 2,
  IN_PROGRESS: 0,
});

module.exports = { sendStatuses };
