import { updateState } from '../../../actions/generic/update-state';
import { domain } from '../../../domain';
import name from '../name';

const defaultState = { selectedInvoiceTemplateConfiguration: null };

const { updateStateAction, updateStateReducer, ACTION_TYPE } = updateState({
  actionName: `${domain}/${name}/CLEAR_SELECTED_INVOICE_TEMPLATE`,
});

const CLEAR_SELECTED_INVOICE_TEMPLATE = ACTION_TYPE;

export const clearSelectedInvoiceTemplate = (state, { uniqueId }) =>
  updateStateAction({ ...state, [uniqueId]: defaultState });

export const clearSelectedInvoiceTemplateReducerEntry = {
  [CLEAR_SELECTED_INVOICE_TEMPLATE]: updateStateReducer,
};
