import * as yup from 'yup';

export const TaskSchema = yup.object().shape({
  assigneeIds: yup.array(yup.string()),
  matterId: yup.string(),
  subject: yup.string().required(),
  note: yup.string().nullable(),
  isCompleted: yup.boolean(),
  dueDate: yup.date().nullable(),
});

export const PhoneMessageSchema = yup.object().shape({
  assigneeIds: yup.array(yup.string()).min(1),
  matterId: yup.string(),
  subject: yup.string().required(),
  note: yup.string().nullable(),
  isCompleted: yup.boolean(),
  dueDate: yup.date().nullable(),
});
