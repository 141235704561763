import { asFormikField } from '../as-formik-field';
import TaskDropdownContainer from './TaskDropdown.container';

const mapFormikToTaskDropdown = ({ field, form, onChange }) => ({
  selectedTaskCode: field.value,
  onSelectionChange: (task) => {
    form.setFieldValue(field.name, task && task.code, true);
    if (onChange) {
      onChange(task);
    }
  },
});

const TaskDropdownFormField = asFormikField(mapFormikToTaskDropdown)(TaskDropdownContainer);
export default TaskDropdownFormField;
