import React from 'react';

import Styles from './ConfigureMatterNumbering.module.scss';
import { DraggableWidget } from './draggable-widget';
import { TWidget } from './types';

type TAvailableWidgetsProps = {
  installableWidgets: TWidget[];
};

export const InstallableWidgets = ({ installableWidgets }: TAvailableWidgetsProps) => (
  <div className={Styles.labelContainer}>
    {installableWidgets.map((w) => (
      <DraggableWidget key={`widget-${w.widgetId}`} widget={w} />
    ))}
  </div>
);
