import {
  updateCache as updateRedux,
  clearCache as clearRedux
} from '@sb-billing/redux/bank-reconciliation-setup.2';

angular.module('@sb-billing/services').service('sbBankReconciliationSetupService2', function (sbGenericCacheService, sbEndpointType) {
  'use strict';

  const subscriptions = 'notifier.AccountsNotifications.BankReconciliationSetupUpdated';
  const endpoint = 'billing/bank-reconciliation-setup';

  sbGenericCacheService.setupCache({
    name: 'sbBankReconciliationSetupService2',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: endpoint },
      poll: 60,
      subscriptions
    },
    updateRedux,
    clearRedux,
  });
});
