angular.module('sb.billing.webapp').directive('sbInvoiceLineSummaryByInvoiceNumber', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'scope': { invoiceNumber: '=', matterId: '=', invoiceId: '='},
    'controller': 'SbInvoiceLineSummaryByInvoiceNumberController',
    'controllerAs': 'invoiceLineSummaryByInvoiceNumber',
    'templateUrl': 'ng-components/invoice-line-summary-by-invoice-number/invoice-line-summary-by-invoice-number.html'
  };
});
