'use strict';

const { surchargeApplyToValues, calculateSurcharge } = require('../invoice-surcharge');

const {
  calculateExpenseTotals,
  calculateFeeTotals,
  calculateFeeTaxTotal,
  calculateDiscount,
} = require('../invoice/services');

/**
 * calculate invoice totals
 * @param {Object} params
 * @param {Object} [params.fees] invoice fees (as per .net entity)
 * @param {Object} [params.expenses] invoice expenses (as per .net entity)
 * @param {Object} params.discount discount settings on invoice
 * @param {boolean} params.surchargeEnabled whether surcharge feature is enabled
 * @param {Object} params.surcharge surcharge settings on invoice
 * @param {number} params.taxRate this should either be the taxRate saved on invoice when it was created or the firm tax rate
 * @returns {Object} a summary of invoice totals
 */
function calculateInvoiceTotals({ fees = [], expenses = [], discount, surchargeEnabled, surcharge, taxRate }) {
  const totals = {};

  // fees
  const {
    amountExclTax: feesExclTaxAndWrittenOffBeforeDiscount,
    tax: feesTaxBeforeDiscount,
    writtenOff: feesWrittenOff,
  } = calculateFeeTotals(fees);
  const feesTotalExclTax = Math.round(feesExclTaxAndWrittenOffBeforeDiscount + feesWrittenOff) || 0;
  const feesTax = calculateFeeTaxTotal({
    discount,
    amountExclTax: feesExclTaxAndWrittenOffBeforeDiscount,
    tax: feesTaxBeforeDiscount,
  });

  // add fee total to totals
  totals.feeTotal = feesTotalExclTax; // fees total, excluding tax, and before write off and discount are applied
  totals.writtenOffFeeTotal = feesWrittenOff;

  // expenses
  const {
    amountExclTax: expenseTotalExclTaxAndWrittenOff,
    tax: expensesTax,
    writtenOff: expensesWrittenOff,
  } = calculateExpenseTotals(expenses);
  const expensesTotalExclTax = Math.round(expenseTotalExclTaxAndWrittenOff + expensesWrittenOff) || 0;

  // add expense total to totals
  totals.expenseTotal = expensesTotalExclTax;
  totals.writtenOffExpenseTotal = expensesWrittenOff;

  // total written off on invoice
  const writtenOff = Math.round(feesWrittenOff + expensesWrittenOff);
  totals.writtenOff = writtenOff;

  // discounts are only applicable for fees and not expenses
  // the below makes sure discount applied don't exceed total fee
  const discountAmount = Math.round(
    calculateDiscount({ totalFeeAmountExclTax: feesExclTaxAndWrittenOffBeforeDiscount, discount }) || 0,
  );
  totals.discount = discountAmount;

  const feesExclTaxMinusWrittenOffAndDiscount = Math.round(feesTotalExclTax - feesWrittenOff - discountAmount);
  const feesAndExpensesExclTax = feesTotalExclTax + expensesTotalExclTax;
  const feesAndExpensesExclTaxMinusWrittenOffAndDiscount = Math.round(
    feesAndExpensesExclTax - feesWrittenOff - discountAmount - expensesWrittenOff,
  );

  // surcharge - this is calculated for display purpose only, the backend will repeat this process
  let surchargeTotal = 0;
  let surchargeTax = 0;
  if (surchargeEnabled) {
    if (surcharge?.applyTo === surchargeApplyToValues.FEES && feesExclTaxMinusWrittenOffAndDiscount > 0) {
      surchargeTotal = Math.round(calculateSurcharge(feesExclTaxMinusWrittenOffAndDiscount, surcharge)) || 0;

      // If there is GST on ANY items on the invoice, GST should also be applied to the Surcharge
      // If there is no GST on ANY items on the invoice, GST should NOT be applied to the Surcharge
      if (feesTax > 0) {
        surchargeTax = Math.round((surchargeTotal * taxRate) / 10000);
      }
    }
    if (
      surcharge?.applyTo === surchargeApplyToValues.FEES_AND_EXPENSES &&
      feesAndExpensesExclTaxMinusWrittenOffAndDiscount > 0
    ) {
      surchargeTotal = Math.round(calculateSurcharge(feesAndExpensesExclTaxMinusWrittenOffAndDiscount, surcharge)) || 0;

      // If there is GST on ANY items on the invoice, GST should also be applied to the Surcharge
      // If there is no GST on ANY items on the invoice, GST should NOT be applied to the Surcharge
      if (feesTax + expensesTax > 0) {
        surchargeTax = Math.round((surchargeTotal * taxRate) / 10000);
      }
    }
  }
  totals.surcharge = surchargeTotal;
  totals.surchargeTax = surchargeTax;

  // NB: at each stage of the calculation, any amounts that results from % calculuation has
  // already been rounded, so there should be no need to do further rounding below

  // tax
  const taxTotal = feesTax + expensesTax + surchargeTax;
  totals.tax = taxTotal;

  // billed (also called subtotal on invoice)
  totals.billed = feesAndExpensesExclTax;

  // total (balance due before payments)
  const totalBalanceDue = feesAndExpensesExclTaxMinusWrittenOffAndDiscount + surchargeTotal + taxTotal;
  totals.total = totalBalanceDue;

  // payment related fields (placeholders for now for completeness)
  // 1. paidByCredit
  const paidByCredit = 0;
  totals.paidByCredit = paidByCredit;
  // 2. paid
  const paid = 0;
  totals.paid = paid;
  // 3. unpaid
  totals.unpaid = totalBalanceDue - paid - paidByCredit;
  // 4. waived
  totals.waived = 0;

  return totals;
}

module.exports = {
  calculateInvoiceTotals,
};
