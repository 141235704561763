import { getRememberedParams } from "web/redux/features/transactions-recent-account-selection/selectors";
import { store } from '@sb-itops/redux';
import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';

export const PARENT_PAGE_NAME = 'home.billing.accounts.transactions';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/accounts/transactions';

  $stateProvider
    .state('home.billing.accounts.transactions', {
      url: '/transactions?showHidden&startDate&endDate',
      templateUrl: BASE_DIRECTORY + '/transactions.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      reloadOnSearch: false,
      params: {
        trustAccountId: 'all',
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'accounts'],
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'remember' feature
        remember: true,
        rememberParent: PARENT_PAGE_NAME,
      },
      onEnter: function(sbOverrideRedirectService, $timeout, $stateParams, $state) {
        if (sbOverrideRedirectService.override) {
          sbOverrideRedirectService.resetOverride();
          return;
        }

        // If entering trust overview and not from a child page of transactions
        // Load the previously remembered page if there is one
        // $state.current.name is the state we are coming from, $stateParams is the params of the state we are going to
        if ($stateParams.trustAccountId === 'all' && !$state.current.name.includes(PARENT_PAGE_NAME) ) {
          const rememberedAccountParams = getRememberedParams(store.getState()['web/transactions-recent-account-selection'], { pageName: PARENT_PAGE_NAME });
          if (rememberedAccountParams) {
            $timeout(() => {
              $state.go(...rememberedAccountParams);
            })
          } else {
            // If account only has one trust account
            if (getActiveTrustAccounts().length === 1) {
              $timeout(function(){
                $state.go('home.billing.accounts.transactions.trust', { trustAccountId: getActiveTrustAccounts()[0].id });
              })
            }
          }
        }
      },    
    });
});
