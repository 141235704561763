'use strict';

/**
 * @typedef { import('../types/index.js').FeeDetails } FeeDetails
 */

const { getRegion } = require('@sb-itops/region');

const { extractFeeSchedule: extractLawpayFeeSchedule } = require('./lawpay');
const { extractFeeSchedule: extractStripeFeeSchedule } = require('./stripe');
const { extractFeeSchedule: extractEzyCollectFeeSchedule } = require('./ezy-collect');
const { extractFeeSchedule: extractFeeWiseFeeSchedule } = require('./fee-wise');

const { providers } = require('../entities/constants');

const providerSpecificFeeScheduleExtractors = {
  [providers.LAWPAY]: extractLawpayFeeSchedule,
  [providers.STRIPE]: extractStripeFeeSchedule,
  [providers.EZY_COLLECT]: extractEzyCollectFeeSchedule,
  [providers.FEE_WISE]: extractFeeWiseFeeSchedule,
};

/**
 * extractFeeSchedule
 *
 * A 'fee schedule' is data utilised by the calculateFeeDetails function in its indiciative fee calculations.
 *
 * Depending on the provider implementation, the fee schedule might not exist at all (if we don't handle fees), be global (same fee structure across all firms),
 * per firm or per bank account(i.e potentially multiple fees per firm). Note "fees" in this context refers to charge fees (e.g. credit card processing fee).
 *
 * Fee schedules should be provided within formattedProviderSpecificSettings for every payment provider implementation.
 *
 * This begs the question; why don't we just pass the formattedProviderSpecificSettings and bank account id to calculateFeeDetails?
 * The reason is that we don't want to be sending the entire formattedProviderSpecificSettings structure to the payment portal application.
 * Indeed, we don't even want to send information about available firm bank account ids to the payment portal.
 *
 * To overcome that problem, in both the web app (for consistency) and payment portal lambda, we pass the formattedProviderSpecificSettings
 * into this function to extract only the fee schedule information relevant for the specific bank account. That might always be the same
 * data structure for global fee schedules, but it could differ per bank account depending on implementation.
 *
 * Whatever object is returned from this function should be passed without manipulation as the fee schedule param in calculateFeeDetails.
 * Failing to follow this rule could lead to introducing provider specific implementation assumptions.
 *
 * @param {Object} params
 * @param {string} params.providerType
 * @param {Object} params.formattedProviderSpecificSettings
 * @param {string} params.bankAccountId Bank account id for which to extract the fee schedule.
 *
 * @returns {Object} The fee schedule information for the passed bank account id.
 */
const extractFeeSchedule = ({ providerType, formattedProviderSpecificSettings, bankAccountId }) => {
  if (!providerType) {
    throw new Error('providerType must be provided');
  }

  if (!formattedProviderSpecificSettings) {
    throw new Error('formattedProviderSpecificSettings must be provided');
  }

  if (!bankAccountId) {
    throw new Error('bankAccountId must be provided');
  }

  const feeScheduleExtractorFn = providerSpecificFeeScheduleExtractors[providerType];
  if (!feeScheduleExtractorFn) {
    throw new Error(`Failed to extract fee schedule for unsupported provider type '${providerType}'`);
  }

  return feeScheduleExtractorFn({
    formattedProviderSpecificSettings,
    bankAccountId,
    region: getRegion(),
  });
};

module.exports = {
  extractFeeSchedule,
};
