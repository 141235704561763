import { useSelector, useDispatch } from 'react-redux';
import * as forms from '@sb-itops/redux/forms2';
import { useTranslation } from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';
import * as messageDisplay from '@sb-itops/message-display';
import { saveTrustChequePrintSettings } from '@sb-billing/redux/trust-cheque-print-settings';
import { saveOperatingChequePrintSettings } from '@sb-billing/redux/operating-cheque-print-settings';
import { getAccountId } from 'web/services/user-session-management';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getLogger } from '@sb-itops/fe-logger';
import ChequeSettings from './ChequeSettings';

const SCOPE_TRUST = 'trust-cheque-settings';
const SCOPE_OPERATING = 'operating-cheque-settings';
const log = getLogger('ChequeSettingsContainer');

// Looks like lot of duplicate code but I used separate forms for Trust and Operating
// This is so I can reset Trust form anytime different bankAccount is selected
// while keeping Operating form initialised
const hooks = () => ({
  useSelectors: () => {
    // trust
    const { selectors: formSelectorsTrust, operations: formOperationsTrust } = useScopedFeature(forms, SCOPE_TRUST);
    const { formDirty: formDirtyTrust, formSubmitting: formSubmittingTrust } = useSelector(
      formSelectorsTrust.getFormState,
    );
    // operating
    const { selectors: formSelectorsOperating, operations: formOperationsOperating } = useScopedFeature(
      forms,
      SCOPE_OPERATING,
    );
    const { formDirty: formDirtyOperating, formSubmitting: formSubmittingOperating } = useSelector(
      formSelectorsOperating.getFormState,
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return {
      scopeTrust: SCOPE_TRUST,
      scopeOperating: SCOPE_OPERATING,
      formSubmitting: formSubmittingTrust || formSubmittingOperating,
      blockSending: !formDirtyTrust && !formDirtyOperating,
      onSave: async () => {
        const chequeLocalised = `${capitalize(t('cheque'))}`;
        try {
          await Promise.all([
            dispatch(
              formOperationsTrust.submitFormP({ submitFnP: getSubmitFn(saveTrustChequePrintSettings, formDirtyTrust) }),
            ),
            dispatch(
              formOperationsOperating.submitFormP({
                submitFnP: getSubmitFn(saveOperatingChequePrintSettings, formDirtyOperating),
              }),
            ),
          ]);
          messageDisplay.success(`${chequeLocalised} Settings changes saved.`);
        } catch (err) {
          log.error(err);
          messageDisplay.error(`Failed to save ${chequeLocalised} Settings.`);
        }
      },
    };
  },
});

const getSubmitFn = (submitFn, isDirty) => async (fieldValues) => {
  // This is to skip Operating account in AU as it is hidden and can't be dirty
  if (!isDirty) return;
  const { printMethod, chequeStyle, printingActive, includeVendorAddress, bankAccountId } = fieldValues;
  // transform to correct shape
  const settings = {
    printMethod,
    printingActive,
    includeVendorAddress,
    style: chequeStyle,
    bankAccountId,
    accountId: getAccountId(),
  };
  await submitFn(settings);
};

const ChequeSettingsContainer = withReduxProvider(composeHooks(hooks)(ChequeSettings));
ChequeSettingsContainer.displayName = 'ChequeSettingsContainer';

export default ChequeSettingsContainer;
